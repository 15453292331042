import { LoaderFunctionArgs } from 'react-router';
import { parameter } from 'services';

export const su09Loader = async () => {
  const response = await parameter.getParameterListAsync(1, 10);
  const parameterDropDownList = await parameter.getDropdownParameterAsync();
  const parameterGroupDropDownList = await parameter.getParameterGroupDropdownParameterAsync();

  return {
    parameterLists: response.data.data,
    parameterTotalRecord: response.data.totalRecords,
    dropDownLists: parameterDropDownList.data,
    parameterGroupDropDown: parameterGroupDropDownList.data,
  };
};

export const su09DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const parameterDropDownList = await parameter.getDropdownParameterAsync();

  if (id) {
    const response = await parameter.getParameterDetailAsync(id);

    return {
      parameterDetail: response.data.data,
      dropDownLists: parameterDropDownList.data,
    };
  }

  return {
    dropDownLists: parameterDropDownList.data,
  };
};