import {
  Card,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  Table,
  TorStatus,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import {
  HistoryModal,
  Modal,
} from 'components/Modal';
import { TraderListModal } from 'components/Modal/Procurement';
import { ItemModel } from 'models';
import { useState } from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { BsArrowReturnLeft } from 'react-icons/bs';
import {
  FaHistory,
  FaSave,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { useLoaderData } from 'react-router';
import { dataHistory } from '../data';
import { useAppContext } from 'utils';

type loader = { departmentDDL: ItemModel[], supplyMethodDDL: ItemModel[], supplyMethodTypeDDL: ItemModel[] };

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step1({ onClickNext, onClickBack }: Props) {
  const { departmentDDL, supplyMethodDDL, supplyMethodTypeDDL } = useLoaderData() as loader;
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showCoiWatchList, setShowCoiWatchList] = useState(false);
  const [showTraderModal, setShowTraderModal] = useState(false);
  const [showBidPriceModal, setShowBidPriceModal] = useState(false);
  const [isConsider, setIsConsider] = useState<boolean>(false);
  const [aLength, setALength] = useState<number>(3);
  const [typeModal, setTypeModal] = useState<string>('');
  const [titleModal, setTitleModal] = useState<string>();
  const { userId } = useAppContext();

  const onShowCoiWatchList = (typeModal: string, title: string) => {
    setShowCoiWatchList(true);
    setTypeModal(typeModal);
    setTitleModal(title);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <Title text='ข้อมูลร่างขอบเขตของงาน (TOR)'
            className='fs-5 text-primary' />
          <div className='d-flex gap-2'>
            <TorStatus
              value='Draft' />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'>
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='เลขที่เอกสารรายการจัดซื่อจัดจ้าง'
              value='P0001'
              disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Selector items={departmentDDL}
              label='ฝ่าย/สำนัก'
              value='a32e2280-7819-426f-8ab1-0277e82d6605'
              disabled />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='ปีงบประมาณ'
              value='2566'
              disabled
              rule={{ required: true }} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={6}
            xl={3}>
            <Selector
              label='วิธีการจัดหา'
              value='a21603e4-338d-486c-85ba-5f1cac8f6ace'
              items={supplyMethodDDL}
              rule={{ required: true }}
              disabled />
          </Col>
          <Col sm={12}
            md={6}
            xl={3}>
            <Selector
              value='37381528-e37c-46d0-b55c-8762c1ce0141'
              items={supplyMethodTypeDDL}
              className='no-label'
              disabled />
          </Col>
          <Col sm={12}
            md={6}
            xl={3}>
            <Selector
              className='no-label'
              disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='วงเงิน (บาท)'
              value='20,000.00'
              disabled
              rule={{ required: true }}
              textEnd />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <DatePicker
              disabled
              monthYearOnly
              rule={{ required: true }}
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              value={new Date()} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='วันที่เอกสารบันทึกร่างขอบเขตของงาน'
              value='25 เมษายน 2565'
              disabled />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='เลขที่เอกสารร่างขอบเขตของงาน'
              value='2059 / 2565'
              disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            xl={9}>
            <InputTextArea label='ชื่อโครงการ (เรื่อง)'
              disabled
              value='จ้างพัฒนาระบบ S1' />
          </Col>
          <Col sm={12}
            xl={9}>
            <Input
              label='หัวเรื่องเอกสาร'
              value='โครงการองค์กร'
              disabled />
          </Col>
          <Col sm={12}
            xl={9}>
            <InputTextArea
              label='ที่มาและเหตุผล'
              value='เพื่อพัฒนาองค์กรให้มีความก้าวหน้าทางเทคโนโลยี'
              disabled />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='ผู้ชนะ'
              className='fs-5 text-primary' />
          </div>
        </div>
        <Row className='gap-5'>
          <Col sm={12}
            className='ps-5'>
            <Table hidePagination>
              <thead>
                <tr>
                  <th style={{ width: 10 }}>ลำดับ</th>
                  <th style={{ minWidth: 50 }}>เลขประจำตัวผู้เสียภาษี</th>
                  <th style={{ minWidth: 50 }}>ประเภทผู้ประกอบการ</th>
                  <th style={{ minWidth: 50 }}>ชื่อสถานประกอบการ</th>
                  <th style={{ minWidth: 50 }}>อีเมล</th>
                  <th style={{ minWidth: 50 }}>ราคา<br />
                    ที่เสนอ
                  </th>
                  <th style={{ minWidth: 50 }}>COI</th>
                  <th style={{ minWidth: 50 }}>Watchlist</th>
                  <th style={{ minWidth: 50 }}>ผลเสนอราคา</th>
                  <th style={{ minWidth: 50 }}>ผลพิจารณา</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 3 })
                  .map((_, index) => (
                    <tr className='text-center'
                      key={index}>
                      <td>{index + 1}</td>
                      <td>8765432119861</td>
                      <td>บริษัทจำกัด</td>
                      <td>เครื่องไฟฟ้าไทย</td>
                      <td>egpmail@gmail.com</td>
                      <td>1,000.00</td>
                      <td><Button onClick={() => onShowCoiWatchList('Coi', 'COI')}>ผลบันทึก</Button></td>
                      <td><Button onClick={() => onShowCoiWatchList('WatchList', 'WatchList')}>ผลบันทึก</Button></td>
                      <td><Button onClick={() => setShowBidPriceModal(!showBidPriceModal)}>ผลเสนอราคา</Button></td>
                      <td>
                        <Button
                          onClick={() => {
                            setIsConsider(true);
                            setShowBidPriceModal(!showBidPriceModal);
                          }}>ผลพิจารณา</Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile userId={userId} />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          variant='outline-primary'
          onClick={onClickBack}
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          กลับหน้าแรก
        </Button>
        <div>
          {/* <Button
            variant="danger"
            onClick={() => setShow(show)}
            className="me-2 px-3"
          >
            <BsArrowReturnLeft className="mx-2 pb-1 fs-4" />
            ส่งกลับแก้ไข
          </Button> */}
          <Button
            variant='success'
            className='me-2 px-3'
          >
            <FaSave className='ms-2 pb-1 fs-5' />
            บันทึก
          </Button>
          {/* <Button
            className="me-2 px-3"
          >
            <FaRegCheckCircle className="me-2 pb-1 fs-5" />
            เห็นชอบ
          </Button> */}
        </div>
        <Button
          variant='outline-primary'
          onClick={onClickNext}
          className='me-2 px-3'
        >
          ถัดไป
          <MdArrowForward className='ms-2 pb-1 fs-5' />
        </Button>
      </div>
      <HistoryModal
        title='ประวัติการใช้งานประกาศผู้ชนะการเสนอราคา'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        data={dataHistory}
      />

      <Modal
        show={show}
        size='lg'
        onHide={() => setShow(!show)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-center'>
              <Button
                variant='light'
                onClick={() => setShow(!show)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                className='me-2 px-3'
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <TraderListModal
        show={showTraderModal}
        onHide={() => setShowTraderModal(!showTraderModal)}
        onSubmit={val => setALength(aLength + val)}
      />
    </div>
  );
}

export default Step1;
