import {
  Card,
  InputTextArea,
  ListFile,
} from 'components';
import Title from 'components/Controls/Title';
import { SearchNameModal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { FaUndo } from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="บันทึกความเห็น" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12}>
            <InputTextArea label="ความเห็น" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <ListFile InameFile="อัตราค่าใช้จ่ายอื่นๆ ปี 65/2.doc" IsizeFile="123KB" />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-center gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          variant="danger"
          className="me-2 px-3"
        >
          <FaUndo className="mx-2 pb-1 fs-4" />
          ส่งกลับแก้ไข
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>

      <SearchNameModal
        show={show}
        onHide={() => setShow(!show)}
        data={[]}
      />
    </div>
  );
}

export default Step2;
