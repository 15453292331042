export enum SharedvalueGroup {
  ContractType = 'ContractType',
  ContractManagementType = 'ContractManagementType',
  ContractFormat = 'ContractFormat',
  PeriodCondition = 'PeriodCondition',
  WarrantyCondition = 'WarrantyCondition',
  PerformanceBondType = 'PerformanceBondType',
  ContractAttachment = 'ContractAttachment',
  PerformanceBondReturn = 'PerformanceBondReturn',
  PeriodType = 'PeriodType',
  ReturnCollateralConsiderResultType = 'ReturnCollateralConsiderResultType',
  ReturnCollateralTransferDocumentType = 'ReturnCollateralTransferDocumentType',
  Bank = 'Bank',
  MedianPriceConsiderInformation = 'MedianPriceConsiderInformation',
  CriteriaConsideration = 'CriteriaConsideration',
  PositionOnBoard = 'positionOnBoard',
  MedianPriceTemplate = 'MedianPriceTemplate',
  DeliveryCountUnit = 'DeliveryCountUnit',
  DefectsWarrantyCountUnit = 'DefectsWarrantyCountUnit',
  PeriodTimeType = 'PeriodTimeType',
  PmFineType = 'PmFineType',
  CmFineType = 'CmFineType',
  TrainingUnitType = 'TrainingUnitType',
  DayOfWeek = 'DayOfWeek',
  Quarter = 'Quarter',
  DocumentTemplate = 'DocumentTemplate',
  DocumentTemplateTor = 'TOR',
  UnitOfMeasure = 'UnitOfMeasure',
  MaintenancePeriodType = 'maintenancePeriodType',
  ProcessType = "ProcessType",
  BiddingResultConsiderPeriodType = "BiddingResultConsiderPeriodType",
  SectionContractFormat = "SectionContractFormat",
  PaymentType = "PaymentType",
  AdvanceDeductionType = "AdvanceDeductionType",
  FineType = "FineType",
  VatType = "VatType",
  ContractAppendix = 'ContractAppendix',
  ContractFormatLevel2 = 'ContractFormatLevel2',
  ContractStartDatePeriodCondition = 'ContractStartDatePeriodCondition',
  RepairPeriodType = 'RepairPeriodType',
  ProcurementCommerce = 'ProcurementCommerce',
  JorPorDirectorPosition = 'JorPorDirectorPosition',
}

export enum ContractFormatCode {
  ContractTemplateHireConstruction = 'CF01',
  ContractTemplateTrade = 'CF02',
  ContractTemplateTradeFixedPrice = 'CF03',
  ContractTemplateTradeComputer = 'CF04',
  ContractTemplateTradeLicenseSoftware = 'CF05',
  ContractTemplateLeaseComputer = 'CF06',
  ContractTemplateHireMAComputer = 'CF07',
  ContractTemplateLeaseCar = 'CF08',
  ContractTemplateHireCleaning = 'CF09',
  ContractTemplateHireSecurity = 'CF10',
  ContractTemplateLeaseXerox = 'CF11',
  ContractTemplateExchange = 'CF12',
  ContractTemplateHireWork = 'CF13',
  ContractTemplateHireControlConstruction = 'CF14',
  ContractTemplateHireExpert = 'CF15'
}

export enum SectionContractFormatCode {
  CMS001 = 'CMS001',
  CMS002 = 'CMS002',
  CMS003 = 'CMS003',
  CMS004 = 'CMS004',
  CMS005 = 'CMS005',
  CMS006 = 'CMS006',
  CMS007 = 'CMS007',
  CMS008 = 'CMS008',
  CMS009 = 'CMS009',
  CMS010 = 'CMS010',
  CMS011 = 'CMS011',
  CMS012 = 'CMS012',
  CMS013 = 'CMS013',
  CMS014 = 'CMS014',
  CMS015 = 'CMS015',
  CMS016 = 'CMS016',
  CMS017 = 'CMS017',
}

export enum QuarterCodeEnum {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export enum ContractTypeEnum {
  PurchaseId = 'c6e41af3-8b42-a48d-f3c1-e20d21a8fa6f',
  HireId = 'caf81f5e-dcbb-6cfd-e48a-b08a839a1439',
  LeaseId = '46017c41-1b46-8dd3-df6e-eca565e82526'
}

export enum SubGroup {
  PositionOnBoardCreator = 'positionOnBoardCreator',
  PositionOnBoardProcurement = 'positionOnBoardProcurement',
  PositionOnBoardInSpection = 'positionOnBoardInSpection',
  PositionOnBoardMA = 'positionOnBoardMA',
  PositionOnBoardSupervisor = 'positionOnBoardSupervisor',
  MaintenancePeriodType = 'maintenancePeriodType',
  ContractAppendixOther = 'contractAppendixOther',
  SubContractManagementType = 'SubContractManagementType',
}

export enum PaymentTypeIdEnum {
  PayByWorkProgress = '0eed885d-12c8-3366-ba87-1a3e2f65dbdc', // จ่ายตามความก้าวหน้าของงาน
  PayByPhaseAssign = '59dc7cf8-bba7-dc09-ea73-746a1ec73a86', // จ่ายตามงวดที่กำหนด
}

export enum PerformanceBondType {
  cash = '0c1d6674-9282-8c27-7d04-4c22280b6baf',
  guaranteeElectronics = '474c0cbb-0496-6331-07c8-91f84a68e510',
  guaranteeBank = '02b37b8a-bbec-705f-8ec4-05074b3af398',
  guaranteeCompany = 'c55a61cf-e00d-ae94-930a-c3013fd73ca0',
  governmentBonds = '23b43be8-d1a4-a64f-fe2c-0025acda6ad9',
  cheque = '03101d8f-d472-d51c-0aed-b173c45073e6',
}

export enum ProvincesEnum {
  provinces = 'provinces',
  district = 'district',
  subDistrict = 'Q3',
}

export enum SharedvalueId {
  jorPorDirectorPositionId = '2a902850-e751-433c-a2a4-e11fc50c61ad',
  budgetConstantJorPro06 = 'ea16d6c7-8127-48a1-bb11-82388c6583cc',
}