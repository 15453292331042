import { useEffect, useMemo, useState } from 'react';
import { MdCircle } from 'react-icons/md';

interface Props {
  systemStatue?: string;
  outline?: boolean;
  text?: string;
  systemName?: string;
  value: string;
}

function Gettext(system: string, systemStatue: string) {
  switch (system) {
    case 'cm-03':
      if (systemStatue === 'None') {
        return 'รอดำเนินการ'
      }

      if (systemStatue === 'Draft') {
        return 'ร่าง'
      }

      if (systemStatue === 'WaitingForCommitteeApprove') {
        return 'อยู่ระหว่างคณะกรรมการราคากลางเห็นชอบ';
      }

      if (systemStatue === 'CommitteeRejected') {
        return 'คณะกรรมการราคากลางส่งกลับแก้ไข';
      }

      if (systemStatue === 'CommitteeApproved') {
        return 'คณะกรรมการราคากลางเห็นชอบ/อนุมัติ';
      }

      if (systemStatue === 'WaitingForApprove') {
        return 'อยู่ระหว่างห็นชอบ/อนุมัติ';
      }

      if (systemStatue === 'Approved') {
        return 'เห็นชอบ/อนุมัติ';
      }

      if (systemStatue === 'Rejected') {
        return 'ส่งกลับแก้ไข';
      }

      if (systemStatue === 'Cancelled') {
        return 'ยกเลิก';
      }

      if (systemStatue === 'WaitingForAssignResponsible') {
        return 'อยู่ระหว่างมอบหมายผู้รับผิดชอบ';
      }

      if (systemStatue === 'DraftSectionApprover') {
        return 'อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ';
      }

      if (systemStatue === 'WaitingForJorPorDirector') {
        return 'อยู่ระหว่างรอ ผอ.จพ. มอบหมาย';
      }

      break;
    default:
      return 'รอดำเนินการ'
  }
}

const STATUS_DETAIL = [
  {
    value: 'None',
    color: '#BEBEBE',
    label: 'รอดำเนินการ',
  },
  {
    value: 'Draft',
    color: '#BEBEBE',
    label: 'ร่าง',
  },
  {
    value: 'WaitingForCommitteeApprove',
    color: '#FBBD01',
    label: 'อยู่ระหว่างคณะกรรมการราคากลางเห็นชอบ',
  }, {
    value: 'CommitteeRejected',
    color: '#DE3E44',
    label: 'คณะกรรมการราคากลางส่งกลับแก้ไข',
  }, {
    value: 'CommitteeApproved',
    color: '#1BA345',
    label: 'คณะกรรมการราคากลางเห็นชอบ/อนุมัติ',
  }, {
    value: 'WaitingForApprove',
    color: '#FBBD01',
    label: 'อยู่ระหว่างห็นชอบ/อนุมัติ',
  }, {
    value: 'Approved',
    color: '#1BA345',
    label: 'เห็นชอบ/อนุมัติ',
  }, {
    value: 'Rejected',
    color: '#DE3E44',
    label: 'ส่งกลับแก้ไข',
  }, {
    value: 'Cancelled',
    color: '#DE3E44',
    label: 'ยกเลิก',
  }, {
    value: 'WaitingForAssignResponsible',
    color: '#FBBD01',
    label: 'อยู่ระหว่างมอบหมายผู้รับผิดชอบ',
  },
  {
    value: 'DraftSectionApprover',
    color: '#FBBD01',
    label: 'อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ',
  }, {
    value: 'WaitingForJorPorDirector',
    color: '#FBBD01',
    label: 'อยู่ระหว่างรอ ผอ.จพ. มอบหมาย',

  },
];

export function StatusMedianPrice(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS_DETAIL.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);
  return (
    <>
      {props.value
        ? (
          <div className='tor-status'
            style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

// export function StatusMedianPrice({ systemStatue, outline, text, systemName }: Props) {
//   const status = useMemo(() => {
//     if (systemStatue === 'None') {
//       return {
//         color: 'bg-dark text-muted'
//       };
//     }

//     if (systemStatue === 'Draft') {
//       return {
//         color: 'bg-dark text-muted'
//       };
//     }

//     if (systemStatue === 'WaitingForCommitteeApprove') {
//       return {
//         color: 'bg-yellow text-yellow'
//       };
//     }

//     if (systemStatue === 'CommitteeRejected') {
//       return {
//         color: 'bg-danger text-danger'
//       };
//     }

//     if (systemStatue === 'CommitteeApproved') {
//       return {
//         color: 'bg-success text-success'
//       };
//     }

//     if (systemStatue === 'WaitingForApprove') {
//       return {
//         color: 'bg-yellow text-yellow'
//       };
//     }

//     if (systemStatue === 'Approved') {
//       return {
//         color: 'bg-success text-success'
//       };
//     }

//     if (systemStatue === 'Rejected') {
//       return {
//         color: 'bg-danger text-danger'
//       }
//     }

//     if (systemStatue === 'Cancelled') {
//       return {
//         color: 'bg-danger text-danger'
//       }
//     }

//     if (systemStatue === 'WaitingForAssignResponsible') {
//       return {
//         color: 'bg-yellow text-yellow'
//       };
//     }

//     if (systemStatue === 'DraftSectionApprover') {
//       return {
//         color: 'bg-yellow text-yellow'
//       };
//     }

//     if (systemStatue === 'WaitingForJorPorDirector') {
//       return {
//         color: 'bg-yellow text-yellow'
//       };
//     }
//     return { color: 'bg-dark text-muted' };
//   }, [systemStatue]);

//   return (
//     <>
//       {status ?
//         <div className={`d-flex align-items-center px-3 py-1 bg-opacity-10 rounded-pill ${status.color}`}> {systemName ? Gettext(systemName, systemStatue) : 'text noting'}</div> : null}
//     </>
//   );
// }