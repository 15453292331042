export const enum CriteriaSupplyMethodId {
  MethodId60 = '342e4e0b-7682-48c0-b46a-97a066908c9a',
  MethodId80 = 'a21603e4-338d-486c-85ba-5f1cac8f6ace',
}

export const enum CriteriaSupplyMethod {
  Method60 = 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560',
  Method80 = 'ข้อบังคับธนาคาร 80',
}

export const enum ContractStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
}

export enum ApproverContractStatus {
  Draft = 'Draft',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum Quarter {
  Q1 = '0b9dfad0-7080-4ab2-a17a-96e5cd999b54',
  Q2 = 'f49c5f68-0327-4702-afef-2362d9d2abc5',
  Q3 = 'f2a56620-774a-4b24-8e87-e991359e37cc',
  Q4 = '002bc7b9-976c-4e89-9d29-5db0ca6edb9e',
}

export enum ContractAgreementFlag {
  IsEditing = 'IsEditing',
  IsCanceling = 'IsCanceling',
}

export enum ContractAgreementTypeAcceptor {
  JorPorDirector = 'JorPorDirector',
  Responsible = 'Responsible',
  Assignee = 'Assignee',
  AcceptorSign = 'AcceptorSign',
  AcceptorApprover = 'AcceptorApprover'
}