export const enum PurchaseOrderApprovalStatusConstant {
  WaitingForAssign = 'WaitingForAssign',
  WaitingForSign = 'WaitingForSign',
  Assigned = 'Assigned',
  Rejected = 'Rejected',
  Draft = 'Draft',
  Pending = 'Pending',
  Signed = 'Signed',
  None = 'None',
}

export const enum TraderStatusConstant {
  WaitingForAssign = 'WaitingForAssign',
  Assigned = 'Assigned',
  Rejected = 'Rejected',
  Draft = 'Draft',
}

export const enum ContractType {
  NonContract = '01d60c68-a489-443e-b104-e8fe8263d0a0',
  Contract = '9e74a4b9-7acc-4c1c-9ee7-ede29292ed53',
}

export const enum AssigneesLevel {
  Level0 = 0,
  Level1 = 1,
}
