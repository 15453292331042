import { Rule } from 'models';
import {
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { generateUniqueId } from '../../utils/helper';

interface Props {
  label?: string;
  subLabel?: string | JSX.Element;
  value?: string | number | undefined;
  onChange?: (value: string | number) => void;
  name: string;
  className?: string;
  labelClassName?: string;
  items: { label: string | JSX.Element, value: string | number }[];
  rule?: Rule;
  inline?: boolean;
  dflex?: boolean;
  disabled?: boolean;
}

export function Radio(props: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [value, setValue] = useState<string | number | undefined>(props.value);

  document.addEventListener('onFormSubmit', () => {
    validate(value);
  });

  useEffect(() => {
    setValue(props.value);
  }, [props.value, props.name]);

  const getRequired = () => {
    if (props.rule?.required) {
      return <span className='text-danger'>*</span>;
    }

    return null;
  };

  const validate = (value: string | number | undefined) => {
    if (!checkRequired(value)) {
      return;
    }

    setErrorMessage('');
  };

  const checkRequired = (value: string | number | undefined) => {
    if (props.rule?.required && !value) {
      setErrorMessage('กรุณาเลือกข้อมูล');

      return false;
    }

    return true;
  };

  const handlerOnChange = (event: HTMLInputElement) => {
    if (props.onChange) {
      props.onChange(event.value);
    }

    setValue(event.value);

    validate(event.value);
  };

  return (
    <Form.Group className={`${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
      {props.label
        && (<Form.Label className={`m-0 ${props.inline ? 'me-3' : ''} ${props.labelClassName ?? ''}`}>{props.label} {getRequired()}</Form.Label>)}
      {props.subLabel && <Form.Label>{props.subLabel}</Form.Label>}
      <div className={`m-0 ${props.dflex ? 'd-flex gap-3' : ''}`}>
        {props.items.map((item) => (
          <Form.Check>
            <Form.Check
              checked={value === item.value}
              type="radio"
              id={`item-${props.name}-${item.label}`}
              name={props.name}
              value={item.value}
              onChange={(event) => handlerOnChange(event.target as HTMLInputElement)}
              disabled={props.disabled} />
            <Form.Check.Label htmlFor={`item-${props.name}-${item.label}`}>{item.label}</Form.Check.Label>
          </Form.Check>
        ))}
      </div>
      {
        errorMessage
          ? (
            <span className='d-flex invalid-feedback'>
              {errorMessage}
            </span>
          ) : null
      }
    </Form.Group>
  );
}
