import { HttpStatusCode } from 'axios';
import {
  Card,
  Input,
  InputTextArea,
  Modal,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  UserListSelectionParams,
  UserListSelectionResponse,
  UserResponse,
} from 'models';
import { PL0204ModelSave } from 'models/PL';
import {
  ItemModel,
  Pagination,
} from 'models/systemUtility';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaAngleLeft,
  FaEraser,
  FaPlus,
  FaSearch,
  FaTimes,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { PL0204Service } from 'services/PL';
import shareValue from 'services/shareValue';
import {
  fullDatetimeTH,
  submitForm,
} from 'utils';
import toast from 'utils/toast';
import { Context } from './Detail';

interface Props {
  onClickNext?: () => void;
  onClickBack?: () => void;
}

type Loader = {
  departmentDDL: ItemModel[],
  positionDDL: ItemModel[],
}

export default function PL0204Approver({ onClickBack }: Props) {
  const { data } = useContext(Context);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [onDisable, setDisable] = useState<boolean>(false);
  const { departmentDDL, positionDDL } = useLoaderData() as Loader;
  const [confirmCancelModal, setConfirmCancelModal] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    size: 10,
    totalRecords: 0,
  } as Pagination);

  useEffect(() => {
    if (data.isCancel) {
      setDisable(true);
    }
  }, []);

  useEffect(() => { if (data.approvers.length > 0) setUsers(data.approvers); }, []);
  const onSelect = (user: UserListSelectionResponse) => {
    const userFromModal: UserResponse = {
      id: user.id,
      name: user.fullName,
      tel: '',
      email: '',
      position: user.positionName,
      department: user.departmentName,
      role: '',
      isActive: false,
      updatedBy: '',
      updatedAt: '',
      approveStatus: '',
      remark: '',
      workGroupName: '',
    };
    users.push(userFromModal);
    setPagination({ ...pagination, totalRecords: users.length });
  };

  const remove = (index: number) => {
    const temp: UserResponse[] = [...users];
    temp.splice(index, 1);
    setUsers(temp);
    setPagination({ ...pagination, totalRecords: temp.length });
  };

  const onSumbitCancel = () => {
    if (users.length <= 0) {
      toast.warn('ต้องผู้อนุมัติอย่างน้อย 1 คน');
      return;
    }
    setConfirmCancelModal(true);
  };

  return (
    <>
      <Card className="mt-3">
        <div className="d-flex align-items-center gap-2">
          <div className="parallelogram" />
          <h4 className="text-primary m-0">อำนาจอนุมัติ</h4>
        </div>
        <Table
          className="mt-3"
          total={pagination.totalRecords}
          onChange={(size, page) => (setPagination({ ...pagination, size }), setPagination({ ...pagination, page }))}
        >
          <thead>
            <tr>
              <th style={{ minWidth: 75 }}>ลำดับ</th>
              <th style={{ minWidth: 75 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 75 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 200 }}>ตำแหน่ง</th>
              {!onDisable && <th />}
              {onDisable && <th style={{ minWidth: 75 }}>สถานะ</th>}
              {onDisable && <th style={{ minWidth: 75 }}>วันที่อนุมัติ</th>}

            </tr>
          </thead>
          <tbody>
            {users.map((u, i) => (
              <tr key={u.id}>
                <td className="text-center">{i + 1}</td>
                <td className="text-start">{u.name}</td>
                <td className="text-start">{u.department}</td>
                <td className="text-center">{u.position}</td>
                {!onDisable && (
                  <td>
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="danger"
                        className="d-flex align-items-center gap-2"
                        onClick={() => remove(i)}
                      >
                        <FaTrashAlt />
                      </Button>
                    </div>
                  </td>
                )}
                {onDisable
                  && (
                    <td className="text-center">
                      <Status
                        type={StatusType.PROCESS}
                        value={u.approveStatus ?? ''}
                      />
                    </td>
                  )}
                {onDisable
                  && (
                    <td className="text-center">
                      {u.updatedAt ? fullDatetimeTH(u.updatedAt) : ''}
                    </td>
                  )}
              </tr>
            ))}
          </tbody>
        </Table>

        {!onDisable
          ? (
            <div className="mt-3">
              <Button
                variant="outline-primary"
                className="d-flex align-items-center gap-2"
                onClick={() => setShowModal(!showModal)}
              >
                <FaPlus />เพิ่มข้อมูล
              </Button>
            </div>
          ) : <></>}
      </Card>
      {!onDisable
        ? (
          <div className="d-flex justify-content-center gap-3 mt-3">
            <Button
              variant="outline-primary"
              className="d-flex align-items-center gap-2"
              onClick={onClickBack}
            >
              <FaAngleLeft />ย้อนกลับ
            </Button>
            <Button
              variant="danger"
              className="d-flex align-items-center gap-2"
              onClick={() => onSumbitCancel()}
            >
              <FaAlignLeft className="me-2 pb-1 fs-5" /> ขอยกเลิกแผน
            </Button>
          </div>
        )
        : (
          <div className="d-flex justify-content-start gap-3 mt-3">
            <Button
              variant="outline-primary"
              className="d-flex align-items-center gap-2"
              onClick={onClickBack}
            >
              <FaAngleLeft />ย้อนกลับ
            </Button>
          </div>
        )}
      <ApproverModal
        show={showModal}
        onSelect={onSelect}
        onHide={() => setShowModal(false)}
        userSelected={users}
        departmentDDL={departmentDDL}
        positionList={positionDDL}
      />
      <div className="ModalEject">
        <PL0204ModalEjectPlan
          onHide={() => setConfirmCancelModal(false)}
          show={confirmCancelModal}
          approverData={users.map((i) => i.id)}
          id={data.id}
        />
      </div>
    </>
  );
}

function ApproverModal(props: {
  show: boolean,
  onHide: () => void,
  onSelect: (value: UserListSelectionResponse) => void,
  userSelected: UserResponse[],
  departmentDDL: ItemModel[],
  positionList: ItemModel[],
}) {
  const [show, setShow] = useState<boolean>(false);
  const [criteriaModal, setCriteriaModal] = useState<UserListSelectionParams>({} as UserListSelectionParams);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    size: 10,
    totalRecords: 0,
  } as Pagination);

  useEffect(() => {
    const getDataAsync = async () => {
      if (props.show) {
        await getUsersAsync();
        setShow(true);
      }
    };
    getDataAsync();
  }, [props.show]);

  useEffect(() => {
    getUsersAsync();
  }, [pagination.size, pagination.page]);

  const getUsersAsync = async () => {
    const res = await shareValue.getUserListSelectionAsync(
      pagination.page,
      pagination.size,
      criteriaModal,
    );
    if (res.status === HttpStatusCode.Ok) {
      const usersData: UserListSelectionResponse[] = res.data.data;
      if (props.userSelected.length > 0) {
        props.userSelected.forEach((value) => {
          usersData.forEach((v, i) => {
            if (v.id === value.id) {
              usersData.splice(i, 1);
            }
          });
        });
      }
      setUsers(usersData);
      setPagination({ ...pagination, totalRecords: usersData.length });
    }
  };

  const SearchData = () => {
    getUsersAsync();
  };

  const onSelect = (user: UserListSelectionResponse) => {
    props.onSelect(user);
    onHideModal();
  };

  const onHideModal = () => {
    setCriteriaModal({} as UserListSelectionParams);
    setUsers([]);
    setShow(false);
    props.onHide();
  };

  const onClear = useCallback(async () => {
    setCriteriaModal({} as UserListSelectionParams);
    await getUsersAsync();
  }, [props.show]);

  return (
    <Modal show={show} size="xl">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h5 className="mb-1">ค้นหารายชื่อ</h5>
        <FaTimes onClick={() => onHideModal()} />
      </div>
      <Card className="mb-3">
        <p>ค้นหา</p>
        <Row>
          <Col md={12} lg={4}>
            <Input
              label="ชื่อ-นามสกุล"
              value={criteriaModal.fullName}
              onChange={(val) => setCriteriaModal({ ...criteriaModal, fullName: val })}
            />
          </Col>
          <Col md={12} lg={4}>
            <Selector
              label="ฝ่าย/สำนัก"
              items={props.departmentDDL}
              value={criteriaModal.departmentId}
              onChange={(val) => setCriteriaModal({ ...criteriaModal, departmentId: val })}
            />
          </Col>
          <Col md={12} lg={4}>
            <Selector
              label="ตำแหน่ง"
              items={props.positionList}
              value={criteriaModal.positionId}
              onChange={(val) => setCriteriaModal({ ...criteriaModal, positionId: val })}
            />
          </Col>
        </Row>
        <div className="d-flex gap-2">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2"
            onClick={SearchData}
          >
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant="outline-primary"
            className="d-flex align-items-center gap-2"
            onClick={onClear}
          >
            <FaEraser />ล้าง
          </Button>
        </div>
      </Card>
      <Table total={pagination.totalRecords} onChange={(size, page) => (setPagination({ ...pagination, size }), setPagination({ ...pagination, page }))}>
        <thead>
          <tr>
            <th style={{ minWidth: 85 }}>ลำดับ</th>
            <th style={{ minWidth: 200 }}>ชื่อ-นามสกุล</th>
            <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
            <th style={{ minWidth: 80 }} />
          </tr>
        </thead>
        <tbody>
          {users.map((u, i) => (
            <tr key={u.id}>
              <td className="text-center">{i + 1}</td>
              <td>{u.fullName}</td>
              <td className="text-center">{u.departmentName}</td>
              <td className="text-center">{u.positionName}</td>
              <td className="d-flex justify-content-center">
                <Button
                  onClick={() => onSelect(u)}
                  variant="primary"
                  className="d-flex align-items-center gap-2"
                >
                  เลือก
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  );
}

function PL0204ModalEjectPlan(props: {
  show: boolean;
  onHide: () => void;
  approverData: string[];
  id: string;
}) {
  const [saveData, setSaveData] = useState<PL0204ModelSave>({} as PL0204ModelSave);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.show) {
      setSaveData({ ...saveData, userIds: props.approverData, planId: props.id });
    }
  }, [props.show]);

  const handleOnCancelPlan = (usersData: PL0204ModelSave) => {
    submitForm();
    if (!usersData.cancelDescription) {
      toast.warn('กรุณาระบุเหตุผลที่ยกเลิก');
      return;
    }
    (async () => {
      const res = await PL0204Service.createCancelPlanRequest(usersData);
      if (res.status === HttpStatusCode.Created) {
        toast.success('ขอยกเลิกแผนสำเร็จ');
        navigate('/pl/pl0204');
        props.onHide();
      }
    })();
  };

  const clearValueAndHide = () => {
    setSaveData({} as PL0204ModelSave);
    props.onHide();
  };

  return (
    <Modal show={props.show} size="xl">
      <div className="d-flex align-items-center gap-2">
        <div className="parallelogram" />
        <h4 className="mt-2 text-primary">ขอยกเลิกแผน</h4>
      </div>

      <div className="container">
        <Row className="criteria mt-2">
          <Col sm={12}>
            <InputTextArea
              label="เหตุผลที่ยกเลิก"
              rule={{ required: true }}
              value={saveData.cancelDescription}
              onChange={(val) => setSaveData({ ...saveData, cancelDescription: val })}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end align-items-center">
          <div className="d-flex gap-2">
            <Button className="d-flex align-items-center gap-2" variant="light" onClick={() => clearValueAndHide()}>
              ยกเลิก
            </Button>
            <Button className="d-flex align-items-center gap-2" variant="primary" onClick={() => { handleOnCancelPlan(saveData); }}>
              ยืนยัน
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
