import {
  Card,
  DatePicker,
  Input,
  InputTextArea,
  Modal,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { PublicProcurementRejectModal } from 'components/Modal/RejectPublicPlanProcurementModal';
import { onActionAnnoucementModel } from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaCheckCircle,
  FaRegCheckCircle,
  FaSave,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdRefresh,
} from 'react-icons/md';
import { publicPlanAnnouncement as publicService } from 'services';
import {
  AcceptorStatus,
  AnnouncementStatus as AnnouncementCons,
  AssignLevel,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import Collabora, { CollaboraRef } from '../../../components/Document/Collabora';
import publicYearDetailService from '../../../services/PP/PublicYearDetailService';
import {
  HttpStatusCode,
  submitForm,
  useAppContext,
} from '../../../utils';
import { Context } from './Detail';

interface Props {
  onClickBack: () => void;
}

function Step3({ onClickBack }: Props) {
  const [showReject, setOnShowReject] = useState(false);
  const { currentStep, publicPlanProcurement, setPublicPlanProcurement, reGetDetail, readonly } = useContext(Context);
  const { userId } = useAppContext();
  const [rejectRemark, setRejectRemark] = useState<string>();
  const [docId, setDocId] = useState<string | null>(null);
  const [showPendingModal, setShowPendingModal] = useState<boolean>(false);
  const [showRecallModal, setShowRecallModal] = useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
  const [remarkApprove, setRemarkApprove] = useState('');
  const [isLastApprover, setIsLastApprover] = useState(false);
  const collaboraRef = useRef<CollaboraRef>(null);


  const onCheckLastApproverPending = () => {
    const approver = publicPlanProcurement.approvers?.filter(a => a.status === AcceptorStatus.PENDING && !a.isAvailable);

    if (approver.length === 1) {
      setIsLastApprover(true);
      return;
    }

    setIsLastApprover(false);
  };

  useEffect(() => {
    onCheckLastApproverPending();
  }, []);

  const createMainDocument = useCallback(async (
    planProcurementAnnouncementId: string
  ) => {
    const {
      data: docId,
      status
    } = await publicYearDetailService.getDocumentPlanAnnouncementAsync(
      planProcurementAnnouncementId);

    if (status === HttpStatusCode.OK) {
      setDocId(docId);
    }
  }, [setDocId]);

  useEffect(() => {
    if (publicPlanProcurement.id) {
      createMainDocument(publicPlanProcurement.id);
    }
  }, [publicPlanProcurement.id, createMainDocument]);

  const onSubmitAsync = async (AnnouncementStatus: string) => {
    collaboraRef.current?.clickSave();
    submitForm();

    if (AnnouncementStatus === AnnouncementCons.WaitingAccept.toString()) {
      if (!publicPlanProcurement.announcementTitle) {
        toast.warn('กรุณาระบุชื่อประกาศสำหรับเผยแพร่');

        return;
      }

      if ((!publicPlanProcurement.approvers
        || publicPlanProcurement.approvers.length === 0)) {
        toast.warn('ต้องมีผู้มีอำนาจ อย่างน้อย 1 คน');

        return;
      }
    }
    const checkRequestField = publicPlanProcurement.year
      && publicPlanProcurement.supplyMethod
      && publicPlanProcurement.remark;

    if (!checkRequestField) {
      return;
    }
    publicPlanProcurement.status = AnnouncementStatus;
    publicPlanProcurement.documentId = docId;

    collaboraRef.current?.clickSave();

    setTimeout(async () => {
      const { data, status } = await publicService.updateAnnouncementAsync(publicPlanProcurement);

      if (status === HttpStatusCode.OK || status === HttpStatusCode.ACCEPTED) {
        publicPlanProcurement.id = data.id;
        reGetDetail(data.id);
        if (AnnouncementStatus === AnnouncementCons.WaitingAccept.toString()) {
          toast.success('ส่งเห็นชอบ/อนุมัติสำเร็จ');
          setShowPendingModal(false);
        } else {
          toast.success('บันทึกสำเร็จ');
        }
      }

      createMainDocument(publicPlanProcurement.id);
    }, 500);
  };

  const isJorporDirectorAssign = useMemo(() => {
    return !!publicPlanProcurement?.jorporDirectorAssign?.filter((x) => x.level === AssignLevel.Level1).some((d) => isNull(d.delegateUserId, d.userId) === userId);
  }, [publicPlanProcurement]);

  const isApprover = useMemo(() => {
    return !!(publicPlanProcurement.approvers?.some((s) => isNull(s.delegateUserId, s.userId) === userId));
  }, [publicPlanProcurement]);

  const isFinalApprover = useMemo(() => {
    const i = publicPlanProcurement.approvers.findIndex(s => isNull(s.delegateUserId, s.userId) === userId);

    return (publicPlanProcurement.approvers.length - 1) === i;
  }, [publicPlanProcurement]);

  const sectionCode = useMemo(() => {
    if (publicPlanProcurement.approvers?.some((s) => isNull(s.delegateUserId, s.userId) === userId)) {
      return publicPlanProcurement.approvers?.find((s) => isNull(s.delegateUserId, s.userId) === userId)?.sectionCode;
    }
  }, [publicPlanProcurement]);

  const onToggleModal = (state: boolean) => {
    if (!state) {
      setRejectRemark('');
    }
    setOnShowReject(state);
  };

  function isNull(oldValue?: string, newValue?: string) {
    if (oldValue) {
      return oldValue;
    }

    return newValue;
  }

  const onApproveorReject = async (action: string) => {
    if (action === AcceptorStatus.REJECT) {
      if (!rejectRemark) {
        submitForm();
        toast.warn('กรุณาระบุหมายเหตุการส่งกลับแก้ไข');
        return;
      }
    }
    if (!isApprover && action !== AnnouncementCons.Recall) {
      toast.warn('คุณไม่มีสิทธิ์ เห็นชอบ/อนุมัติ หรือ ส่งกลับแก้ไข');
      return;
    }
    const payload = {
      act: action,
      remark: action === AcceptorStatus.REJECT ? rejectRemark : remarkApprove,
      sectionCode
    } as onActionAnnoucementModel;

    const { data, status } = await publicService.onActionAnnoucement(publicPlanProcurement.id, payload);
    if (status === HttpStatusCode.ACCEPTED) {
      reGetDetail(data.id);

      if (action === AnnouncementCons.Recall) {
        setShowRecallModal(false);
        toast.success('เรียกคืนแก้ไขสำเร็จ');
      } else {
        toast.success(`${isLastApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
        setShowApproveModal(false);
        if (action === AcceptorStatus.REJECT) {
          setRejectRemark('');
          setOnShowReject(false);
        }
      }
    }

    createMainDocument(publicPlanProcurement.id);
  };

  const conditionCanAction = useMemo(() => {
    if (!Object.keys(publicPlanProcurement).length || currentStep !== 3 || !userId) {
      return false;
    }

    if (publicPlanProcurement.status !== AnnouncementCons.WaitingAccept) {
      return false;
    }

    const minSection = Math.min(...publicPlanProcurement.approvers
      .filter(d => d.status === AcceptorStatus.PENDING
        && !d.isAvailable)
      .map(o => o.sectionSequence));

    const sectionData = publicPlanProcurement.approvers
      .filter(d => d.sectionSequence === minSection
        && d.status === AcceptorStatus.PENDING
        && !d.isAvailable)
      .sort((a, b) => a.sequence - b.sequence)[0];

    if (sectionData) {
      return isNull(sectionData.delegateUserId, sectionData.userId) === userId;
    }

    return false;
  }, [userId, publicPlanProcurement]);

  const isRecall = useMemo(() => {

    if (publicPlanProcurement.approvers) {
      const queueReCall = publicPlanProcurement.approvers?.filter(a => a.status === AcceptorStatus.APPROVE).length === 0;

      if (queueReCall) {
        return true;
      }
    }

    return false;
  }, [userId, publicPlanProcurement]);

  return (
    <>
      <Card className='mt-4'>
        <Title
          text='เผยแพร่'
          className='fs-5 text-primary'
        />
        <Row className='mt-3'>
          <Col sm={12}
            lg={6}>
            <Input
              label='ชื่อประกาศ'
              value={publicPlanProcurement?.announcementTitle}
              placeholder='ระบุชื่อประกาศเผยแพร่'
              onChange={(announcementTitle) => setPublicPlanProcurement({
                ...publicPlanProcurement,
                announcementTitle
              })}
              rule={{ required: true }}
              disabled={publicPlanProcurement?.status !== AnnouncementCons.AssignJorPor
                || !isJorporDirectorAssign}
            />
          </Col>
          <Col sm={12}
            lg={3}>
            <DatePicker
              label='วันที่เผยแพร่'
              value={publicPlanProcurement?.announcementDate}
              disabled
            />
          </Col>
        </Row>
      </Card>
      <div className='document'>
        <Card className='mt-3'>
          <Title
            text='ตัวอย่างเอกสาร'
            className='fs-5 text-primary'
          />
          <div className='mt-3'>
            <Collabora
              docId={docId ?? ''}
              docName='doc-01'
              ref={collaboraRef}
              readonly={readonly} />
          </div>
        </Card>

        {currentStep === 3
          ? (
            <div className='d-flex justify-content-between pt-3'>
              <ButtonCustom
                onClick={onClickBack}
                text="ย้อนกลับ"
              />
              <div>
                {(publicPlanProcurement?.status === AnnouncementCons.WaitingAccept && isJorporDirectorAssign && isRecall)
                  && (
                    <ButtonCustom
                      text="เรียกคืนแก้ไข"
                      icon='undo'
                      variant='danger'
                      onClick={() => setShowRecallModal(true)}
                    />
                  )}

                {publicPlanProcurement?.status === AnnouncementCons.WaitingAccept && conditionCanAction
                  ? (
                    <>
                      <ButtonCustom
                        text="ส่งกลับแก้ไข"
                        icon='undo'
                        className='me-2'
                        variant='danger'
                        onClick={() => onToggleModal(true)}
                      />
                      <ButtonCustom
                        variant='success'
                        onClick={() => setShowApproveModal(true)}
                        icon='checked'
                        text={isFinalApprover ? 'อนุมัติ' : 'เห็นชอบ'}
                      />
                    </>
                  )
                  : null}
                {(publicPlanProcurement?.status === AnnouncementCons.AssignJorPor || publicPlanProcurement?.status === AnnouncementCons.Reject) && isJorporDirectorAssign
                  ? (
                    <div>
                      <ButtonCustom
                        onClick={() => onSubmitAsync(AnnouncementCons.AssignJorPor)}
                        text="บันทึก"
                      />
                      <ButtonCustom
                        onClick={() => setShowPendingModal(true)}
                        icon='checked'
                        text="ส่งอนุมัติเห็นชอบ"
                      />
                    </div>
                  )
                  : null}
              </div>
              <div />
            </div>
          )
          : <div style={{ width: '190px' }} />}

        <PublicProcurementRejectModal
          show={showReject}
          onReject={(val) => onApproveorReject(val)}
          remark={rejectRemark}
          setRejectRemark={setRejectRemark}
          onHide={() => onToggleModal(false)}
        />
        <Modal
          show={showPendingModal}
          size='lg'
          onHide={() => setShowPendingModal(!showPendingModal)}
          children={(
            <>
              <ModalBT.Header closeButton>
                <ModalBT.Title>ยืนยัน</ModalBT.Title>
              </ModalBT.Header>
              <ModalBT.Body className='p-0 ps-4'>
                คุณต้องการส่งอนุมัติ/เห็นชอบ ?
              </ModalBT.Body>
              <ModalBT.Footer>
                <Button variant='outline-primary'
                  onClick={() => setShowPendingModal(!showPendingModal)}>
                  ยกเลิก
                </Button>
                <Button
                  variant='primary'
                  onClick={() => onSubmitAsync(AnnouncementCons.WaitingAccept)}
                >
                  ยืนยัน
                </Button>
              </ModalBT.Footer>
            </>
          )}
        />
        <Modal
          show={showRecallModal}
          size='lg'
          onHide={() => setShowRecallModal(!showRecallModal)}
          children={(
            <>
              <ModalBT.Header closeButton>
                <ModalBT.Title>ยืนยัน</ModalBT.Title>
              </ModalBT.Header>
              <ModalBT.Body className='p-0 ps-4'>
                คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
              </ModalBT.Body>
              <ModalBT.Footer>
                <Button variant='outline-primary'
                  onClick={() => setShowRecallModal(!showRecallModal)}>
                  ยกเลิก
                </Button>
                <Button
                  variant='primary'
                  onClick={() => onApproveorReject(AnnouncementCons.Recall)}
                >
                  ยืนยัน
                </Button>
              </ModalBT.Footer>
            </>
          )}
        />
        <Modal
          show={showApproveModal}
          size='lg'
          onHide={() => setShowApproveModal(!showApproveModal)}
          children={(
            <>
              <ModalBT.Header closeButton>
                <ModalBT.Title>
                  {isLastApprover ? 'อนุมัติ' : 'เห็นชอบ'}
                </ModalBT.Title>
              </ModalBT.Header>
              <ModalBT.Body className='p-0 ps-4'>
                <InputTextArea
                  label='ความเห็นเพิ่มเติม (ถ้ามี)'
                  value={remarkApprove}
                  onChange={(value) => setRemarkApprove(value)}
                />
              </ModalBT.Body>
              <ModalBT.Footer>
                <Button variant='outline-primary'
                  onClick={() => setShowApproveModal(!showApproveModal)}>
                  ยกเลิก
                </Button>
                <Button
                  variant='primary'
                  onClick={() => onApproveorReject(AcceptorStatus.APPROVE)}
                >
                  ยืนยัน
                </Button>
              </ModalBT.Footer>
            </>
          )}
        />
      </div>
    </>
  );
}

export default Step3;
