import { IFile } from 'models/P/FileUploadModel';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import {
  FaCloudUploadAlt,
  FaFileAlt,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaTrashAlt,
} from 'react-icons/fa';
import PR0202Services from 'services/PR/PR0202Services';
import { HttpStatusCode } from 'utils';

interface Props {
    appointProcuremntId: string;
    files: IFile[];
    handleFileChange: (files: File[]) => void;
    // removeItem: (index: number, docId: string, docName: string) => void;
    removeItem: (docName: string) => void;
    disabled?: boolean;
}

const fileTypes = ['PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PNG', 'JPG', 'JPEG', 'mp3'];

function UploadFile({ files, handleFileChange, removeItem, appointProcuremntId, disabled = false }: Props) {
  const [dragEnter, setDragEnter] = useState<boolean>(false);

  return (
    <div className="upload-file" draggable>
      <FileUploader
        className="browse"
        disabled={disabled}
        multiple
        handleChange={handleFileChange}
        name="file"
        types={fileTypes}
        onDraggingStateChange={setDragEnter}
        hoverTitle="-"
      >
        <div
          className={`browse ${dragEnter ? 'drag-enter' : ''}`}
        >
          <FaCloudUploadAlt />
          <span>Drag and drop or <span className="text-primary">browse</span>.</span>
          <span>รองรับไฟล์ที่มีนามสกุล .doc, .docx, .xls, .xlsx, .csv, .pdf, .png, .jpg, .jpeg และมีขนาดไฟล์ไม่เกิน 10 MB</span>
        </div>
      </FileUploader>
      <div className="file-list">
        {
                            Array.from(files).map((f, index) => (
                              <RenderFile key={index} appointProcuremntId={appointProcuremntId} file={f} removeItem={() => removeItem(f.name)} disabled={disabled} />))
                        }
      </div>
    </div>
  );
}

function RenderFile(props: { appointProcuremntId: string, file: IFile, removeItem: () => void, disabled: boolean }) {
  const handleOpenFileAsync = async (appointProcuremntId: string, file: IFile) => {
    if (!file.file) {
      const { data, status } = await PR0202Services.getAppointmentProcurementDocumentAsync(appointProcuremntId, file.id);

      if (status !== HttpStatusCode.OK) {
        return;
      }

      const fileUrl = URL.createObjectURL(data);
      window.open(fileUrl, '_blank');

      return;
    }

    const fileUrl = URL.createObjectURL(file.file);
    window.open(fileUrl, '_blank');
  };

  const fileIcon = (name: string) => {
    switch (true) {
      case name.includes('.pdf'):
        return (<FaFilePdf />);
      case name.includes('.doc') || name.includes('.docx'):
        return (<FaFileWord />);
      case name.includes('.xls') || name.includes('.xlsx'):
        return (<FaFileExcel />);
      case name.includes('.csv'):
        return (<FaFileCsv />);
      case name.includes('.png') || name.includes('jpg') || name.includes('jpeg'):
        return (<FaFileImage />);
      default:
        return (<FaFileAlt />);
    }
  };

  return (
    <div className="file">
      <div className="file-icon">
        {fileIcon(props.file.name)}
      </div>
      <div className="detail">
        <a
          onClick={() => handleOpenFileAsync(props.appointProcuremntId, props.file)}
          className="filename"
        >
          {props.file.name}
        </a>
      </div>
      {!props.disabled
        ? (
          <div className="trash">
            <Button
              variant="danger"
              className="d-flex align-items-center gap-2"
              onClick={() => props.removeItem()}
            >
              <FaTrashAlt />
            </Button>
          </div>
        ) : null}
    </div>
  );
}

export default UploadFile;
