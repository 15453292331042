import { ProgressBarRefType } from 'components';
import StepProgress from 'components/StepProgress';
import { PlanProcurement } from 'models';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { planProcurement as planProcurementService } from 'services';
import {
  HttpStatusCode,
  useAppContext,
} from 'utils';
import { PlanStatus as PlanStatusCons } from 'utils/constants/PlanEnum';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

type AnnualDetailContext = {
  currentStep: number;
  planProcurement: PlanProcurement;
  setPlanProcurement: Dispatch<SetStateAction<PlanProcurement>>;
  reGetDetail: (id: string) => void;
  checkOverLimitPrice: () => void;
  readonly: boolean;
};

export const Context = createContext({} as AnnualDetailContext);

type Loader = {
  planProcurement: PlanProcurement;
}

const LIMIT_PRICE = 500000;

export default function AnnualDetail() {
  const {
    userId, tel, departmentCode, departmentId,
  } = useAppContext();
  const loader = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [stepStatus, setStepStatus] = useState<number>(0);
  const stepRef = useRef<ProgressBarRefType>({} as ProgressBarRefType);
  const [planProcurement, setPlanProcurement] = useState<PlanProcurement>({} as PlanProcurement);
  const [overLimitPrice, setOverLimitPrice] = useState<boolean>();
  const location = useLocation();

  const steps = useMemo(() => [
    {
      title: <p>รายการจัดซื้อจัดจ้าง</p>,
      displayIndex: '1',
    },
    {
      title: <p>ผู้อำนวยการฝ่ายเห็นชอบ</p>,
      displayIndex: '2',
    },
    {
      title: <p>ผอ.จพ. รับทราบ</p>,
      displayIndex: '3',
    },
  ], [overLimitPrice]);

  // // find step
  useEffect(() => {
    if (userId && Object.keys(planProcurement).length) {
      findStepFromStatus(planProcurement.status);

      switch (planProcurement.status) {
        case PlanStatusCons.DraftPlan: setCurrentStep(1); return;
        case PlanStatusCons.CancelPlan: setCurrentStep(3); return;
        case PlanStatusCons.RejectPlan: setCurrentStep(1); return;
        case PlanStatusCons.WaitingApprovePlan: setCurrentStep(2); return;
        case PlanStatusCons.ApprovePlan: setCurrentStep(3); return;
        case PlanStatusCons.Announcement: setCurrentStep(3); return;
      }
    }
  }, [userId, planProcurement]);

  useEffect(() => {
    if (loader.planProcurement) {
      setPlanProcurement(loader.planProcurement);
    } else {
      const date = new Date();
      const budgetYear = (date.getUTCFullYear() + 543) + 1;

      setPlanProcurement({
        ...planProcurement,
        status: PlanStatusCons.DraftPlan,
        budgetYear,
        tel,
        departmentId,
      });
    }
  }, [loader, tel]);

  const findStepFromStatus = (status: string) => {
    switch (status) {
      case PlanStatusCons.DraftPlan: setStepStatus(1); break;
      case PlanStatusCons.CancelPlan: setCurrentStep(3); return;
      case PlanStatusCons.RejectPlan: setStepStatus(1); break;
      case PlanStatusCons.WaitingApprovePlan: setStepStatus(2); break;
      case PlanStatusCons.ApprovePlan: setStepStatus(3); break;
      case PlanStatusCons.Announcement: setStepStatus(3); break;
    }
  };

  const backToIndex = () => {
    if (planProcurement.isCanceled) {
      navigate('/procurement-plan-cancel');
    } else if (planProcurement.isChanged) {
      navigate('/procurement-plan-adjust');
    } else {
      navigate('/procurement-plan-summary');
    }
  };

  const checkOverLimitPrice = () => {
    if (planProcurement.budget > LIMIT_PRICE) {
      setOverLimitPrice(true);

      return;
    }

    setOverLimitPrice(false);
  };

  const progressBar = useCallback(() => (
    <StepProgress
      progressBarData={steps}
      ref={stepRef}
      step={stepStatus}
    />
  ), [currentStep, overLimitPrice, stepStatus]);

  const reGetDetail = (id: string) => {
    getPlanProcurementAsync(id);
  };

  const getPlanProcurementAsync = async (id: string) => {
    const res = await planProcurementService
      .getDetailAsync(id);

    if (res.status === HttpStatusCode.OK) {
      setPlanProcurement(res.data.planProcurement);
      findStepFromStatus(res.data.planProcurement.status);
    }
  };

  const readonly = useMemo(() =>
    planProcurement.status === PlanStatusCons.WaitingAcceptor
    || planProcurement.status === PlanStatusCons.ApproveAcceptor,
    [planProcurement]);

  const contextValue = {
    currentStep,
    planProcurement,
    setPlanProcurement,
    reGetDetail,
    checkOverLimitPrice,
    readonly,
  };

  const programName = useMemo(() => {
    const path = location.pathname;

    if (path.includes('procurement-plan-inyear')) {
      return 'รายการจัดซื้อจัดจ้าง (รวมปี)';
    }

    if (path.includes('procurement-plan-adjust')) {
      return 'เปลี่ยนแปลงรายการจัดซื้อจัดจ้าง (รวมปี)';
    }

    if (path.includes('procurement-plan-cancel')) {
      return 'ยกเลิกรายการจัดซื้อจัดจ้าง (รวมปี)';
    }
  }, []);

  return (
    <Context.Provider value={contextValue}>
      <h4 className='text-primary'>{programName}</h4>
      {progressBar()}
      {currentStep >= 1
        ? (
          <Step1
            key={1}
            onClickBack={() => backToIndex()}
          />
        ) : null}
      {currentStep >= 2
        ? (
          <Step2
            key={2}
            onClickBack={() => backToIndex()}
          />
        ) : null}
      {currentStep === 3
        ? (
          <Step3
            key={3}
            onClickBack={() => backToIndex()}
          />
        ) : null}
    </Context.Provider>
  );
}
