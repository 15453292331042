import {
  Selector,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import {
  useContext,
  useState,
} from 'react';
import {
  Button,
  Card,
  Modal as ModalBT,
} from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { Context } from './index';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

export default function Step3({ onClickNext, onClickBack }: Props) {
  const [modalState, setModalState] = useState(false);
  const [addData, setAddData] = useState(false);
  const context = useContext(Context);
  return (
    <div className="document">
      <Card className="mt-3">
        <Card.Body>
          <Title text="คณะกรรมการกำหนดราคากลาง" className="fs-5 text-primary" />
          <Table>
            <thead>
              <tr>
                <th style={{ minWidth: 10 }}>ลำดับ</th>
                <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
                <th style={{ minWidth: 150 }}>ประเภทกรรมการ</th>
                <th style={{ minWidth: 150 }}>ตำแหน่งในคณะกรรมการ</th>
              </tr>
            </thead>
            <tbody>
              {context.appointmentApprovalData.committeeMiddlePrices?.map((data, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-start">{data.committeeUserName}</td>
                  <td className="text-start">{data.position}</td>
                  <td className="text-start">{data.positionOnBoardName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Title text="อำนาจหน้าที่" className="fs-5 text-primary" />

          <Table>
            <thead>
              <tr>
                <th style={{ minWidth: 10 }}>ลำดับ</th>
                <th style={{ minWidth: 350 }}>รายละเอียด</th>
              </tr>
            </thead>
            <tbody>
              {context.appointmentApprovalData.dutyCommitteeMiddlePrices?.map((data, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-start">{data.duty}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="outline-primary"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
      <Modal
        show={modalState}
        onHide={() => setModalState(false)}
        size="xl"
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>เปลี่ยนแปลง</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              <p>คณะกรรมการเดิม</p>
              <div className="d-flex flex-wrap justify-content-center gap-4">
                <p className="text-center"> รหัสพนักงาน : <span>5900010</span></p>
                <p className="text-center"> ชื่อ - นามสกุล : <span>สมศรี แดนไทย</span></p>
                <p className="text-center"> ประเภทกรรมการ : <span>ประธานกรรมการ</span></p>
                <p className="text-center"> ตำแหน่ง : <span>ประธานกรรมการ</span></p>
              </div>
              <p>คณะกรรมการเดิม</p>
              <Button className="px-4" variant="light" onClick={() => setAddData(!addData)}>
                <FaPlus className="me-2 pb-1 fs-5" />
                เพิ่มข้อมูล
              </Button>
              {addData
                && (
                <Table className="my-3">
                  <thead>
                    <tr>
                      <th style={{ minWidth: 20 }}>ลำดับ</th>
                      <th style={{ minWidth: 50 }}>รหัสพนักงาน</th>
                      <th style={{ minWidth: 50 }}>ชื่อ-นามสกุล</th>
                      <th style={{ minWidth: 100 }}>ประเภทกรรมการ</th>
                      <th style={{ minWidth: 100 }}>ตำแหน่งในคณะกรรมการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">1</td>
                      <td className="text-center">5900010</td>
                      <td className="text-center">สมศรี แดนไทย</td>
                      <td className="text-center"> <Selector /></td>
                      <td className="text-center"> <Selector /></td>
                    </tr>
                  </tbody>
                </Table>
                )}
            </ModalBT.Body>
            <ModalBT.Footer>
              <div className="d-flex gap-2 justify-content-end">
                <Button className="px-4" variant="light" onClick={() => setModalState(false)}>
                  ยกเลิก
                </Button>
                <Button variant="primary" className="px-3" onClick={() => setModalState(false)}>
                  ยืนยัน
                </Button>
              </div>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

// export default Step3;
