import {
  Check,
  Currency,
  DatePicker,
  Input,
  Modal,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  ItemModel,
  Pagination,
} from 'models';
import {
  InYearPlanDataTable,
  PL0202Criteria,
} from 'models/PL';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaPlus,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import { useLoaderData } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PL0202Service from 'services/PL/PL0202Service';
import {
  GetMonthYearBC,
  HttpStatusCode,
  thaiFormatDateWithSlash,
  THCurrency,
} from 'utils';
import {
  PlanStatusName,
  PlanStatusText,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { generateUniqueId } from '../../../utils/helper';

type Criteria = {
  planNumber: string,
  departmentId?: string,
  planName: string,
  planBudget?: number,
  expectingProcurementAt: Date,
  planAnnouncementDate: Date,
  organizer: string,
  supplyMethodType: string[],
  status: string[],
}

enum CriteriaStatus {
  DraftPlan = 'DraftPlan',
  WaitingApprovePlan = 'WaitingApprovePlan',
  ApprovePlan = 'ApprovePlan',
  RejectPlan = 'RejectPlan',
  Announcement = 'Announcement',
}

type Loader = { supplyMethodTypeList: ItemModel[], supplyMethodList: ItemModel[], departmentList: ItemModel[], loaderDataTable: InYearPlanDataTable };

export default function PL0202() {
  const navigate = useNavigate();
  const { departmentList, supplyMethodTypeList, loaderDataTable } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<Criteria>({ supplyMethodType: supplyMethodTypeList.map((i) => i.value), status: Object.values(CriteriaStatus) } as Criteria);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, size: 10 } as Pagination);
  const [supplyMethodAll, setSupplyMethodAll] = useState<boolean>(true);
  const [statusAll, setStatusAll] = useState<boolean>(true);
  const [tableData, setTableData] = useState<InYearPlanDataTable>({} as InYearPlanDataTable);
  const [show, setShow] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');

  useEffect(() => {
    if (loaderDataTable) {
      setTableData(loaderDataTable);
      setPagination({ ...pagination, totalRecords: loaderDataTable.totalRecords });
    }
  }, []);

  const getInYearPlanListAsync = useCallback(async (criteriaData: Criteria, statusList: string[], allStatus: boolean, allSupplyMethod: boolean) => {
    const requestData = {
      planNumber: criteriaData.planNumber,
      departmentId: criteriaData.departmentId,
      planName: criteriaData.planName,
      planBudget: criteriaData.planBudget,
      organizer: criteriaData.organizer,
      expectingProcurementAt: criteriaData.expectingProcurementAt,
      planAnnouncementDate: criteriaData.planAnnouncementDate,
      statusDraftPlan: comparePlanStatus(CriteriaStatus.DraftPlan, statusList),
      statusWaitingApprovePlan: comparePlanStatus(CriteriaStatus.WaitingApprovePlan, statusList),
      statusApprovePlan: comparePlanStatus(CriteriaStatus.ApprovePlan, statusList),
      statusRejectPlan: comparePlanStatus(CriteriaStatus.RejectPlan, statusList),
      statusAnnouncement: comparePlanStatus(CriteriaStatus.Announcement, statusList),
      statusAll: allStatus,
      supplyMethodType: !allSupplyMethod ? criteriaData.supplyMethodType[0] : '',
      supplyMethodAll: allSupplyMethod,
    } as PL0202Criteria;

    const { data, status } = await PL0202Service.getInYearPlanListAsync(pagination.page, pagination.size, requestData);
    if (status === HttpStatusCode.OK) {
      setTableData(data);
      setPagination({ ...pagination, totalRecords: data.totalRecords });
    }
  }, [pagination.page, pagination.size]);

  useEffect(() => {
    getInYearPlanListAsync(criteria, criteria.status, statusAll, supplyMethodAll);
  }, [getInYearPlanListAsync]);

  const handlerOnSearchAsync = async (criteriaData: Criteria) => {
    getInYearPlanListAsync(criteriaData, criteriaData.status, statusAll, supplyMethodAll);
  };

  const comparePlanStatus = (status: string, ListData: string[]): boolean => {
    let result = false;
    ListData.forEach((i) => {
      if (status === i) {
        result = true;
      }
    });

    return result;
  };

  const clearCriteria = () => {
    setCriteria({
      supplyMethodType: supplyMethodTypeList.map((i) => i.value),
      status: Object.values(CriteriaStatus),
    } as Criteria);
    setStatusAll(true);
    setSupplyMethodAll(true);
    getInYearPlanListAsync({} as Criteria, [], true, true);
  };

  const supplyMethodOnChange = (value: string, result: boolean) => {
    if (result) {
      if (!criteria.supplyMethodType) {
        criteria.supplyMethodType = [];
      }

      criteria.supplyMethodType.push(value);

      setCriteria({ ...criteria });

      if (criteria.supplyMethodType.length === 2) {
        setSupplyMethodAll(true);
      }
    } else {
      const supplyMethod = criteria.supplyMethodType;
      const i = supplyMethod.findIndex((s) => s === value);

      supplyMethod.splice(i, 1);
      setCriteria({ ...criteria, supplyMethodType: [...supplyMethod] });
    }

    if (criteria.supplyMethodType.length < 2) {
      setSupplyMethodAll(false);
    }
  };

  const supplyMethodAllOnChange = (result: boolean) => {
    if (result) {
      setSupplyMethodAll(true);
      setCriteria({ ...criteria, supplyMethodType: supplyMethodTypeList.map((i) => i.value) });
    } else {
      setSupplyMethodAll(false);
      setCriteria({ ...criteria, supplyMethodType: [] });
    }
  };

  const statusOnChange = (value: string, result: boolean) => {
    if (result) {
      if (!criteria.status) {
        criteria.status = [];
      }

      criteria.status.push(value);

      setCriteria({ ...criteria });

      if (criteria.status.length === Object.values(CriteriaStatus).length) {
        setStatusAll(true);
      }
    } else {
      const { status } = criteria;
      const i = status.findIndex((s) => s === value);

      status.splice(i, 1);
      setCriteria({ ...criteria, status: [...status] });
    }

    if (criteria.status.length < Object.values(CriteriaStatus).length) {
      setStatusAll(false);
    }
  };

  const statusAllOnChange = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setCriteria({ ...criteria, status: Object.values(CriteriaStatus) });
    } else {
      setStatusAll(false);
      setCriteria({ ...criteria, status: [] });
    }
  };

  const openModalDelete = (id: string) => {
    setDeleteId(id);
    setShow(true);
  };

  const onDeletePlan = async (id: string) => {
    if (id) {
      const { data, status } = await PL0202Service.deleteInYearPlan(id);
      if (status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบข้อมูลสำเร็จ');
      } else {
        toast.error('เกิดข้อผิดพลาด');
      }
    } else {
      toast.error('เกิดข้อผิดพลาด');
    }
    setShow(false);
    clearCriteria();
  };

  return (
    <div className='pl0202'>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>จัดทำแผนระหว่างปี</h4>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => navigate('detail')}
        >
          <FaPlus />สร้างแผน
        </Button>
      </div>
      <hr />
      <div className='criteria'>
        <Row>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='เลขที่แผน'
              value={criteria.planNumber}
              onChange={(val) => setCriteria({ ...criteria, planNumber: val })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Selector
              label='ฝ่าย/สำนัก'
              value={criteria.departmentId}
              items={departmentList}
              onChange={(val) => setCriteria({ ...criteria, departmentId: val })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='ชื่อโครงการ'
              value={criteria.planName}
              onChange={(val) => setCriteria({ ...criteria, planName: val })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Currency
              label='งบประมาณโครงการ'
              value={criteria.planBudget}
              onChange={(val) => setCriteria({ ...criteria, planBudget: val })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <DatePicker
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              monthYearOnly
              onChange={(val) => setCriteria({ ...criteria, expectingProcurementAt: val })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <DatePicker
              label='วันที่ประกาศแผน'
              onChange={(val) => setCriteria({ ...criteria, planAnnouncementDate: val })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='ผู้จัดทำ'
              value={criteria.organizer}
              onChange={(val) => setCriteria({ ...criteria, organizer: val })}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Form.Label>วิธีจัดหา</Form.Label>
            <div className='d-flex gap-2'>
              <Check
                value={supplyMethodAll}
                onChange={supplyMethodAllOnChange}
                label='ทั้งหมด'
              />
              {
                supplyMethodTypeList.map((data, index) => (
                  <Check
                    key={generateUniqueId(index)}
                    value={criteria.supplyMethodType?.some((s) => s === data.value)}
                    onChange={(val) => supplyMethodOnChange(data.value, val)}
                    label={<>{data.label} </>}
                  />
                ))
              }
            </div>
          </Col>
          <Col>
            <Form.Label>สถานะ</Form.Label>
            <div className='d-flex gap-2'>
              <Check
                value={statusAll}
                onChange={statusAllOnChange}
                label='ทั้งหมด'
              />
              <Check
                value={criteria.status?.some((s) => s === CriteriaStatus.DraftPlan)}
                onChange={(val) => statusOnChange(CriteriaStatus.DraftPlan, val)}
                label={
                  <>{PlanStatusName.DraftPlan} </>
                }
              />
              <Check
                value={criteria.status?.some((s) => s === CriteriaStatus.WaitingApprovePlan)}
                onChange={(val) => statusOnChange(CriteriaStatus.WaitingApprovePlan, val)}
                label={<>{PlanStatusName.WaitingApprovePlan}</>}
              />
              <Check
                value={criteria.status?.some((s) => s === CriteriaStatus.ApprovePlan)}
                onChange={(val) => statusOnChange(CriteriaStatus.ApprovePlan, val)}
                label={<>{PlanStatusName.ApprovePlan}</>}
              />
              <Check
                value={criteria.status?.some((s) => s === CriteriaStatus.RejectPlan)}
                onChange={(val) => statusOnChange(CriteriaStatus.RejectPlan, val)}
                label={<>{PlanStatusName.RejectPlan}</>}
              />
              <Check
                value={criteria.status?.some((s) => s === CriteriaStatus.Announcement)}
                onChange={(val) => statusOnChange(CriteriaStatus.Announcement, val)}
                label={<>{PlanStatusName.Announcement}</>}
              />
            </div>
          </Col>
        </Row>
        <div className='d-flex gap-2 mt-3'>
          <Button
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={() => handlerOnSearchAsync(criteria)}
          >
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={() => clearCriteria()}
          >
            <FaEraser />ล้าง
          </Button>
        </div>
      </div>
      <Table
        className='mt-4'
        total={pagination.totalRecords}
        onChange={(size, page) => (setPagination({ ...pagination, size, page }))}
      >
        <thead>
        <tr>
          <th style={{ minWidth: 135 }}>เลขที่แผน</th>
          <th style={{ minWidth: 135 }}>ฝ่าย/สำนัก</th>
          <th style={{ minWidth: 300, width: 500 }}>ชื่อแผน</th>
          <th style={{ minWidth: 150 }}>งบประมาณ (บาท)</th>
          <th style={{ minWidth: 100 }}>วิธีจัดหา</th>
          <th style={{ minWidth: 50 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
          <th style={{ minWidth: 120 }}>วันที่ประกาศแผน</th>
          <th style={{ minWidth: 120 }}>ผู้จัดทำ</th>
          <th style={{ minWidth: 120 }}>วันที่จัดทำ</th>
          <th style={{ minWidth: 100 }}>สถานะโครงการ</th>
          <th style={{ minWidth: 150 }}>สถานะ</th>
          <th style={{ minWidth: 50 }} />
        </tr>
        </thead>
        <tbody>
        {tableData.data?.map((value, index) => (
          <tr key={value.id}>
            <td className='text-center'>
              <Button
                variant='link'
                onClick={() => navigate(`detail/${value.id}`)}
              >  {value.planNumber}
              </Button>
            </td>
            <td>{value.department}</td>
            <td className='text-wrap'>{value.planName}</td>
            <td className='text-end'>{THCurrency(value.planBudget)}</td>
            <td className='text-center'>{value.supplyMethod}</td>
            <td className='text-center'>{GetMonthYearBC(value.expectingProcurementAt)}</td>
            <td className='text-center'>{thaiFormatDateWithSlash(value.planAnnouncementDate)}</td>
            <td>{value.createdFullName}</td>
            <td className='text-center'>{thaiFormatDateWithSlash(value.createdAt)}</td>
            <td className='text-center'><Status type={StatusType.PROJECT}
              value={value.planStatus} /></td>
            <td className='text-center'><Status type={StatusType.PROCESS}
              value={value.status} /></td>
            <td className='d-flex text-center gap-2'>
              <Button
                className='d-flex align-items-center gap-2'
                variant='danger'
                onClick={() => {
                  openModalDelete(value.id);
                }}
                disabled={value.status !== PlanStatusText.DraftPlan}
              ><FaTrashAlt />
              </Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบข้อมูล</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>คุณต้องการลบข้อมูลหรือไม่ ?</ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='light'
                onClick={() => setShow(!show)}>
                ยกเลิก
              </Button>
              <Button variant='primary'
                onClick={() => {
                  onDeletePlan(deleteId);
                }}>
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}
