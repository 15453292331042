import { AppointmentStatus, ProcessStatus } from 'components';
import StepProgress from 'components/StepProgress';
import { AppointmentDetailModel } from 'models/PP/PreProcurementCreateDirectiveModels';
import {
  createContext,
  Dispatch,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import PreProcurementServices from 'services/PP/PreProcurementServices';
import { HttpStatusCode, useAppContext } from 'utils';
import Step1 from './step1';
import Step2 from './step2';
import { CreatorLevel, JorPorCode } from 'constant/basicInformation';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type PR0202Context = {
  data: AppointmentDetailModel
  setData: Dispatch<React.SetStateAction<AppointmentDetailModel>>,
  readonly: boolean;
  isCancel: boolean;
};

export const Context = createContext({} as PR0202Context);

function AddPR0204() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id } = useParams();
  const [data, setData] = useState<AppointmentDetailModel>({} as AppointmentDetailModel);
  const [disabled, setDisabled] = useState(false);
  const [step, setStep] = useState(1);
  const [isHasMiddlePrice, setIsHasMiddlePrice] = useState(false);
  const [defualtStep, setDefualtStep] = useState(1);
  const { userId, departmentCode, inRefLevel, departmentId } = useAppContext();
  const location = useLocation();
  const isCancel = location.pathname.includes('cancel-preprocurement-directive');

  useEffect(() => {
    if (id) {
      getAppointmentByIdAsynce(id);

      setStep(2);
    }
  }, [id]);

  const isVisable = useMemo(() => {
    if (data.departmentId
      && data.departmentId !== departmentId
      && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [data, userId]);

  const getAppointmentByIdAsynce = async (id: string) => {
    const response = await PreProcurementServices.getAppointmentByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);

      if (response.data.status === null || response.data.status === undefined || response.data.status === '' || response.data.status === ProcessStatus.DRAFT || response.data.status === ProcessStatus.REJECTED) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }

      if (response.data.planBudget <= 100000) {
        setIsHasMiddlePrice(false);
      } else {
        setIsHasMiddlePrice(true);
      }

      if (response.data.status === ProcessStatus.PENDING || response.data.status === ProcessStatus.APPROVED) {
        setDefualtStep(2)
      }
    }
  };

  const obj = [
    {
      title: <p>ข้อมูลแบบขอแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง</p>,
      displayIndex: '1',
      child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} onDisabled={disabled} onHasMiddlePrice={isHasMiddlePrice} isVisable={isVisable} />,
    },
    {
      title: <p>ผู้มีอำนาจเห็นชอบ/อนุมัติ</p>,
      displayIndex: '2',
      child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} onDisabled={disabled} isVisable={isVisable} />,
    },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    if (data.isChange) {
      navigate('/edit-preprocurement-directive');
    } else if (data.isCancel) {
      navigate('/cancel-preprocurement-directive');
    } else {
      navigate('/preprocurement-directive');
    }
  };

  const readonly = useMemo(() =>
    data.status === AppointmentStatus.APPROVED
    || data.status === AppointmentStatus.APPROVE
    || data.status === AppointmentStatus.PENDING,
    [data]);

  return (
    <div className="document">
      {
        (data && data.isActive && data.isCancel) && (
          <h4 className="mt-2 mb-0 text-primary text-start">ขอยกเลิกแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง</h4>
        )
      }
      {
        (data && data.isActive && data.isChange) && (
          <h4 className="mt-2 mb-0 text-primary text-start">ขอแก้ไขแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง</h4>
        )
      }
      {
        (data && data.isActive && data.isChange === false && data.isCancel === false) && (
          <h4 className="mt-2 mb-0 text-primary text-start">ขอแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง</h4>
        )
      }
      {
        (data.status === null || data.status === undefined || data.status === '') && (
          <h4 className="mt-2 mb-0 text-primary text-start">ขอแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง</h4>
        )
      }
      <Row>
        <Col xs={12} className="mb-2">
          <Context.Provider value={{ data, setData, readonly, isCancel }}>
            <StepProgress progressBarData={obj} ref={childRef} step={defualtStep} />
          </Context.Provider> <></>
        </Col>
      </Row>
    </div>
  );
}

export default AddPR0204;
