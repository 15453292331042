import {
  Card,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { SearchNameModal } from 'components/Modal';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  FaAlignLeft,
  FaPlus,
  FaSave,
  FaTrashAlt,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step3({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ผู้มีอำนาจอนุมัติ" className="fs-5 text-primary" />
        <div className="d-flex justify-content-between my-3">
          <p>ข้อมูล</p>
          <Button
            variant="outline-primary"
            onClick={() => setShow(true)}
          >
            <FaPlus />เพิ่มข้อมูล
          </Button>
        </div>
        <Table total={1}>
          <thead>
            <tr>
              <th style={{ minWidth: 20 }}>ลำดับ</th>
              <th style={{ minWidth: 150 }}>รหัสพนักงาน</th>
              <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
              <th style={{ minWidth: 25 }} />
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td>1</td>
              <td>5900010</td>
              <td>สมศรี แดนไทย</td>
              <td>รักษาการผู้อำนวยการสำนักงบประมาณ</td>
              <td>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="outline-primary"
                    className="d-flex align-items-center gap-2"
                  >
                    <FaTrashAlt />ลบ
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
      <div className="d-flex justify-content-center gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          variant="light"
          className="me-2 px-3"
        >
          <FaSave className="ms-2 pb-1 fs-5" />
          บันทึก
        </Button>
        <Button
          className="me-2 px-3"
        >
          <FaAlignLeft className="me-2 pb-1 fs-5" />
          ยืนยัน
        </Button>
      </div>

      <SearchNameModal
        show={show}
        onHide={() => setShow(!show)}
        data={[]}
      />
    </div>
  );
}

export default Step3;
