import { LoaderFunctionArgs } from 'react-router-dom';
import {
  program,
  role,
} from 'services';

export const su06Loader = async () => {
  const rolesRes = await role.getRolesAsync(10, 1);

  return {
    roles: rolesRes.data,
  };
};

export const su06DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  const programsRes = await program.getProgramsAsync(100, 1);

  if (id) {
    const roleRes = await role.getRoleByIdAsync(id);

    return {
      role: roleRes.data,
      programs: programsRes.data.data,
    };
  }

  return {
    programs: programsRes.data.data,
  };
};
