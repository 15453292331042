import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Currency,
  Readonly,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ConfessionModel, ServiceDetailModel } from '../../../../../../models/CM/CM02Models';
import { ItemModel } from 'models';

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

interface IProps {
  confession: ConfessionModel;
  onConfessionChange: (value: ConfessionModel) => void;
  periodTypeDDL: ItemModel[];
  isDisabled?: boolean;
}

export function ConfessionDetail(props: IProps) {
  const [confession, setConfession] = useState<ConfessionModel>({} as ConfessionModel);

  useEffect(() => {
    if (props.confession) {
      setConfession(props.confession);
    }
  }, [props.confession]);

  useEffect(() => {
    props.onConfessionChange(confession);
  }, [confession]);

  return (
    <Card className='mt-3'>
      <Title text='ความรับผิดของผู้รับจ้าง' className='fs-5 text-primary' />
      <Row className='mt-3'>
        <Col sm={4} md={3}>
          <Currency
            label='ผู้รับจ้างยินยอมให้ปรับเป็นรายวันในอัตราร้อยละ'
            value={confession?.finePercent}
            onChange={(val) => setConfession({ ...confession, finePercent: Number(val) })}
            disabled={props.isDisabled}
          />
        </Col>
        <Col sm={4} md={3}>
          <Currency
            label='คิดเป็นเงิน (บาท) ต่อวัน'
            value={confession?.fineAmount}
            onChange={(val) => setConfession({ ...confession, fineAmount: Number(val) })}
            disabled={props.isDisabled}
          />
        </Col>
      </Row>
    </Card>
  );
}
