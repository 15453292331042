import { ButtonCustom } from 'components';
import Heading from './Controls/Heading';

interface Props {
  title: string;
  iconBtn?: string;
  textBtn?: string;
  onClick?: () => void;
}

function Headingform({ title, iconBtn, textBtn = 'add', onClick }: Props) {
  return (
    <>
      <div className="d-flex justify-content-between">
        <Heading text={title} />
        {onClick
          && <ButtonCustom text={textBtn} icon={iconBtn} onClick={onClick} />}
      </div>
      <hr className="my-2" />
    </>
  );
}

export default Headingform;
