import {
  Card,
  DatePicker,
  Input,
  Radio,
  Selector,
  Table,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { generateUniqueId } from '../../../../utils/helper';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const INFO = [
  { label: 'ละเว้น', value: '1' },
  { label: 'ไม่ละเว้น', value: '2' },
];

function Step1({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลโครงการ" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={6}>
            <Input label="เลขที่เอกสาร PR" placeholder="เลขที่เอกสาร PR" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Input label="โครงการ" placeholder="โครงการ" disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="งบประมาณโครงการ (บาท)" placeholder="1,000,000.00" disabled textEnd />
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="วิธีจัดหา" value="80" disabled />
          </Col>
          <Col sm={12} md={4} className="pt-4">
            <Input value="เจาะจง" disabled />
          </Col>
          <Col sm={12} md={4}>
            <Input label="เลขที่ PR" value="1,000,000.00" disabled textCenter />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="จัดทำสัญญาหรือข้อตกลง" className="fs-5 text-primary" />
        <Row>
          <Col sm={12} md={6}>
            <Input label="คู่ค้า" placeholder="คู่ค้า" disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="เลขที่ PR" placeholder="เลขที่ PR" disabled />
          </Col>
          <Col sm={12}>
            <Input label="ชื่อสัญญา" placeholder="ชื่อสัญญา" disabled />
          </Col>
          <Col sm={12}>
            <Radio
              className="mt-3"
              inline
              items={INFO}
              label="จำนวนเงินหลักประกัน"
              name='name1'
              rule={{ required: true }}
            />
          </Col>
          <Col sm={12} md={3}>
            <Selector label="ประเภทหลักประกันสัญญา" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="จำนวนเงินหลักประกัน" placeholder="จำนวนเงินหลักประกัน" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="ร้อยละของราคาค่าจ้างตามสัญญา" placeholder="ร้อยละของราคาค่าจ้างตามสัญญา" rule={{ required: true }} disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            <Selector label="ประเภทวันทำสัญญา" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <DatePicker label="วันที่" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <DatePicker label="ถึงวันที่" rule={{ required: true }} disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            <Selector label="ค่าปรับราย" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="ค่าปรับ (%)" placeholder="0.00" rule={{ required: true }} textCenter disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            <Selector label="รูปแบบสัญญา" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <Selector label="เงื่อนไขการรับประกัน" rule={{ required: true }} disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="การจ่ายเงิน" className="fs-5 text-primary" />
        <Row className="mt-3">
          <Col sm={12}>
            <Table>
              <thead>
                <tr className="text-center">
                  <th style={{ width: 100 }}>งวดที่</th>
                  <th style={{ width: 100 }}>จำนวน ( % )</th>
                  <th style={{ minWidth: 150 }}>รายละเอียด</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 5 }).map((_, index) => (
                  <tr key={generateUniqueId(index)} className="text-center">
                    <td>{index + 1}</td>
                    <td><Input value="10" textCenter disabled /></td>
                    <td>
                      <Input value="ส่งแผนการดำเนินโครงการและส่งมอบ Software Requirements Specification : SRS ภายใน 60 วัน นับถัดจากวันลงนามในสัญญาจ้าง" disabled />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          className="me-2 px-3"
          onClick={onClickBack}
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default Step1;
