import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  ButtonCustom,
  Card,
  Currency,
  Input,
  InputTextArea,
  Radio,
  Selector,
  Table,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import { ContractAgreementWorkPhaseModel, PaymentModel, RetentionModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';
import { RetentionDetail } from './RetentionDetail';
import { fullDate } from 'utils';

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const INFO = [
  {
    label: 'จ่ายเงินล่วงหน้า',
    value: 'true',
  },
  {
    label: 'ไม่จ่ายเงินล่วงหน้า',
    value: 'false',
  },
];

const RetentionINFO = [
  {
    label: 'ต้องการ',
    value: 'true',
  },
  {
    label: 'ไม่ต้องการ',
    value: 'false',
  },
];

const fields = [
  {
    code: 'CF01',
    hasAdvance: 'การชำระเงินให้แก่ผู้ซื้อมีการจ่ายเงินล่วงหน้าหรือไม่',
    advanceAmount: 'จ่ายเงินล่วงค่าจ้างล่วงหน้า',
    advancePercent: 'อัตราร้อยละ (ของราคาค่าจ้าง)',
  },
  {
    code: 'CF02',
  },
  {
    code: 'CF03',
  },
  {
    code: 'CF04',
  },
  {
    code: 'CF05',
  },
  {
    code: 'CF06',
  },
  {
    code: 'CF07',
  },
  {
    code: 'CF08',
  },
  {
    code: 'CF09',
  },
  {
    code: 'CF10',
  },
  {
    code: 'CF11',
  },
  {
    code: 'CF12',
  },
  {
    code: 'CF13',
    hasAdvance: 'การชำระเงินให้แก่ผู้ซื้อมีการจ่ายเงินล่วงหน้าหรือไม่',
    advanceAmount: 'จ่ายเงินล่วงค่าจ้างล่วงหน้า',
    advancePercent: 'อัตราร้อยละ (ของราคาค่าจ้าง)',
  },
  {
    code: 'CF14',
  },
  {
    code: 'CF15',
    advanceTitle: '(1) เงินค่าจ้างล่วงหน้า',
    hasAdvance: 'การชำระเงินให้แก่ผู้ซื้อมีการจ่ายเงินล่วงหน้าหรือไม่',
    advanceAmount: 'จ่ายเงินล่วงค่าจ้างล่วงหน้า',
    advancePercent: 'อัตราร้อยละ (ของราคาค่าจ้าง)',
    advanceDeductionPercent: 'ผู้ว่าจ้างจะหักเงินค่าจ้างในแต่ละเดือนเพื่อชดใช้คืนเงินค่าจ้างล่วงหน้าไว้จำนวนร้อยละ (ของจำนวนเงินค่าจ้างในแต่ละเดือน)',

    retentionTitle: 'สัญญาข้อ 12 (ก) การหักเงินประกันผลงาน',
    // hasRetention: 'ต้องการหักเงินประกันผลงานหรือไม่',
    // retentionPercent: 'จำนวนร้อยละ ของเงินที่ต้องจ่ายในงวดนั้น',
    // retentionAmount: 'จำนวนเงิน ของเงินที่ต้องจ่ายในงวดนั้น',

    // hasWagePayment: 'สัญญาข้อ 3 ค่าจ้างและการจ่ายเงิน',
    // paymentType: 'ประเภทการจ่ายเงิน',
  },
];

interface IAdvancePaymentProps {
  title?: string;
  contractTemplateCode: string;
  advancePayment: PaymentModel;
  retention?: RetentionModel;
  onAdvancePaymentChange: (value: PaymentModel) => void;
  periodTypeDDL: ItemModel[];
  advanceDeductionTypeDDL: ItemModel[];
  performanceBondTypeDDL: ItemModel[];
  isDisabled?: boolean;
}

export function AdvancePayment(props: IAdvancePaymentProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [advancePayment, setAdvancePayment] = useState<PaymentModel>(props.advancePayment);
  const [retention, setRetention] = useState<RetentionModel>(props.retention!);
  const [isDisabledPayment, setIsDisabledPayment] = useState<boolean>(true);
  const [isDisabledRetention, setIsDisabledRetention] = useState<boolean>(true);

  useEffect(() => {
    if (advancePayment) {
      props.onAdvancePaymentChange(advancePayment);
    }
  }, [advancePayment]);

  useEffect(() => {
    if (props.advancePayment) {
      setIsDisabledPayment(!props.advancePayment.hasAdvance);
      setAdvancePayment(props.advancePayment);
    } else {
      setIsDisabledPayment(true);
    }
  }, [props.advancePayment]);

  useEffect(() => {
    if (props.retention) {
      setIsDisabledRetention(!props.retention.hasRetention);
      setRetention(props.retention);
    } else {
      setIsDisabledRetention(true);
    }
  }, [props.retention]);

  const onNumberChange = useCallback((key: string, value: number) => {
    setAdvancePayment(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onChangePaymentActive = useCallback((val: string) => {
    setAdvancePayment(prevState => ({
      ...prevState,
      hasAdvance: val === 'true',
    }));

    if (val === 'false') {
      setIsDisabledPayment(true);
      const payment = {
        hasAdvance: false,
        advanceAmount: undefined,
        advanceInDay: undefined,
        collateralType: undefined,
        remainAmount: undefined,
        bankName: undefined,
        bankBranch: undefined,
        bankAccountName: undefined,
        bankAccountNumber: undefined,
      } as PaymentModel;

      setAdvancePayment(prevState => ({
        ...prevState,
        payment,
      }));
    } else {
      setIsDisabledPayment(false);
    }
  }, []);

  return (
    <Card className='mt-3'>
      <Title text={`สัญญา${props.title ? props.title : 'เงินค่าจ้างล่วงหน้า'}`}
        className='fs-5 text-primary' />
      <>
        {
          fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
            <>
              {
                data.hasAdvance &&
                (
                  <>
                    <h5 className='mt-3'>{data.advanceTitle}</h5>
                    <Row className='mt-3'>
                      <Col sm={8}
                        md={12}>
                        <Radio
                          dflex
                          label={data.hasAdvance}
                          labelClassName='text-wrap'
                          items={INFO}
                          value={advancePayment ? advancePayment.hasAdvance?.toString() : 'false'}
                          rule={{ required: true }}
                          onChange={(val) => onChangePaymentActive(val?.toString())}
                          disabled={props.isDisabled}
                          name='hasAdvance' />
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                data.advanceAmount && (
                  <Row className='mt-3'>
                    <Col sm={8}
                      md={4}>
                      <Currency
                        label={data.advanceAmount}
                        value={advancePayment?.advanceAmount}
                        onChange={(value) => onNumberChange('advanceAmount', Number(value))}
                        disabled={props.isDisabled || isDisabledPayment} />
                    </Col>
                    <Col sm={8}
                      md={4}>
                      <Currency
                        label={data.advancePercent}
                        value={advancePayment?.advancePercent}
                        onChange={(value) => onNumberChange('advancePercent', Number(value))}
                        disabled={props.isDisabled || isDisabledPayment} />
                    </Col>
                    {/* <Col sm={8}
                      md={6}>
                      <Selector
                        label='การหักเงินล่วงหน้า'
                        items={props.advanceDeductionTypeDDL}
                        value={advancePayment?.advanceDedutionPhaseType}
                        onChange={(value) => onTextChange('advanceDedutionPhaseType', value)}
                        disabled={props.isDisabled || isDisabledPayment} />
                    </Col> */}
                  </Row>
                )
              }
              {
                data.advanceDeductionPercent && (
                  <Row>
                    <Col sm={8}
                      md={6}>
                      <InputNumber
                        label={data.advanceDeductionPercent}
                        labelClassName='text-wrap'
                        value={advancePayment?.advancePercent}
                        onChange={(value) => onNumberChange('advanceDeductionPercent', Number(value))}
                        disabled={props.isDisabled || isDisabledPayment} />
                    </Col>
                  </Row>
                )
              }
              {/* {
                data.hasRetention && (
                  <>
                    <h5 className='mt-3'>{data.retentionTitle}</h5>
                    <Row className='mt-3'>
                      <Col sm={8}
                        md={3}>
                        <Radio
                          dflex
                          label={data.hasRetention}
                          items={RetentionINFO}
                          value={retention ? retention.hasRetention?.toString() : 'false'}
                          rule={{ required: true }}
                          onChange={(val) => onChangeRetentionActive(val?.toString())}
                          disabled={props.isDisabled}
                          name='hasRetention' />
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                data.retentionPercent &&
                (
                  <>
                    <Row>
                      <Col sm={8}
                        md={3}>
                        <Currency
                          label={data.retentionPercent}
                          value={retention?.retentionPercent}
                          onChange={(value) => setRetention(prevState => ({
                            ...prevState,
                            retentionPercent: Number(value),
                          }))}
                          disabled={props.isDisabled || isDisabledRetention} />
                      </Col>
                      <Col sm={8}
                        md={3}>
                        <InputNumber
                          label={data.retentionAmount}
                          value={retention?.retentionAmount}
                          onChange={(value) => setRetention(prevState => ({
                            ...prevState,
                            retentionAmount: Number(value),
                          }))}
                          disabled={props.isDisabled || isDisabledRetention} />
                      </Col>
                      <Col sm={8}
                        md={6}>
                        <Selector
                          label='การหักเงินประกันผลงาน'
                          items={props.deductionPhaseDDL}
                          value={retention?.retentionDeductionType}
                          onChange={(value) => setRetention(prevState => ({
                            ...prevState,
                            retentionDeductionType: value,
                          }))}
                          disabled={props.isDisabled || isDisabledRetention} />
                      </Col>
                    </Row>
                  </>
                )
              } */}
              {/* {
                data.hasWagePayment &&
                (
                  <>
                    <h5 className='mt-3'>{data.hasWagePayment}</h5>
                    {data.workPhases && (
                      <Row className='mt-3'>
                        <Col sm={8}
                          md={3}>
                          <InputNumber
                            label={data.workPhases}
                            value={advancePayment?.workPhases}
                            onChange={(value) => onNumberChange('workPhases', Number(value))}
                            disabled={props.isDisabled} />
                        </Col>
                        <Col sm={8}
                          md={3}>
                          <InputNumber
                            label={data.paidPhase}
                            value={advancePayment?.paidPhase}
                            onChange={(value) => onNumberChange('paidPhase', Number(value))}
                            disabled={props.isDisabled} />
                        </Col>
                      </Row>
                    )}
                    <Row className='mt-5 d-flex justify-content-between'>
                      <Col sm={2}
                        md={2}>
                        <p>โดยมีรายละเอียดการส่งมอบ ดังนี้</p>
                      </Col>
                      <Col sm={2}
                        md={2}
                        className='text-end'>
                        <ButtonCustom
                          text='แก้ไขรายการ'
                          onClick={() => setShowEditDeliveryModal(true)}
                          disabled={props.isDisabled} />
                      </Col>
                    </Row>
                    <Row>
                      <Table hidePagination>
                        <thead>
                          <tr className='text-center'>
                            <th style={{ width: 20 }}>ลำดับ</th>
                            <th style={{ width: 20 }}>งวดที่</th>
                            <th style={{ width: 50 }}>ระยะเวลา (วัน)</th>
                            <th style={{ width: 80 }}>วันที่ต้องส่งมอบ</th>
                            <th style={{ width: 80 }}>มูลค่า (%)</th>
                            <th style={{ width: '50%' }}>รายเอียด</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            contractAgreementWorkPhases?.map((data, index) => (
                              <tr className='text-center'
                                key={index}>
                                <td>{index + 1}</td>
                                <td>{data.sequence}</td>
                                <td>{data.periodDay}</td>
                                <td>{data.deliveryDueDate !== undefined ? fullDate(data.deliveryDueDate) : ''}</td>
                                <td>{formatter.format(data.amount)} ({formatter.format(data.percent)}%)</td>
                                <td><InputTextArea value={data.detail}
                                  disabled />
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </Row>
                  </>
                )
              } */}
            </>
          )
        }
      </>
    </Card >
  );
}