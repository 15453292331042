import {
  Input,
  Modal,
  Table,
} from 'components';
import { useState } from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaRegEye } from 'react-icons/fa';
import {
  MdArrowBack,
  MdSave,
} from 'react-icons/md';

interface Props {
  show: boolean;
  onHide: () => void;
}


export function ModalEditPO({ show, onHide }: Props) {
  const [showComment, setShowComment] = useState(false);

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => onHide()}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>เลขที่ PO </ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body>
            <form>
              <Row>
                <Col sm={12} md={5}>
                  <Input label="เลขที่ PO ปัจจุบัน" placeholder='เลขที่ PO ปัจจุบัน' disabled />
                </Col>
                <Col sm={12} />
                <Col sm={12} md={5}>
                  <Input label="เลขที่ PO ใหม่" placeholder='เลขที่ PO ใหม่' />
                </Col>
                <Col sm={12} md={7}>
                  <Input label="หมายเหตุ" placeholder='หมายเหตุ' />
                </Col>
                <Col sm={12} className="d-flex justify-content-center gap-3">
                  <Button
                    variant="light"
                    onClick={() => onHide()}
                    className="me-2 px-3"
                  >
                    <MdArrowBack className="mx-2 pb-1 fs-4" />
                    ย้อนกลับ
                  </Button>
                  <Button
                    className="me-2 px-3"
                  >
                    <MdSave className="mx-2 pb-1 fs-4" />
                    บันทึก
                  </Button>
                </Col>
                <Col sm={12} className='mt-4'>
                  <p className='fs-5 text-primary border-bottom border-primary'>ประวัติการแก้ไข </p>
                  <Table hidePagination notResponse>
                    <thead>
                      <tr>
                        <th className='bg-white text-muted fw-normal text-start' style={{ minWidth: '10%' }}>วันที่ เวลา</th>
                        <th className='bg-white text-muted fw-normal' style={{ minWidth: '25%' }}>เลขที่ PO เดิม</th>
                        <th className='bg-white text-muted fw-normal' style={{ minWidth: '25%' }}>เลขที่ PO ใหม่</th>
                        <th className='bg-white text-muted fw-normal' style={{ minWidth: '25%' }}>ผู้บันทึก/แก้ไข</th>
                        <th className='bg-white text-muted fw-normal' />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-start"><p className='text-primary mb-0'>28-05-2566 </p><p className='text-muted mb-0'>16:24:22</p></td>
                        <td className="text-start">PO410007</td>
                        <td className="text-start">PO410012</td>
                        <td className="text-start">DP_Admin-001</td>
                        <td>
                          <Button
                            variant="primary"
                            className="d-flex align-items-center gap-2"
                            onClick={() => setShowComment(!showComment)}
                          >
                            <FaRegEye />{showComment ? 'ปิดความเห็น' : 'ดูความเห็น'}
                          </Button>
                        </td>
                      </tr>
                      {
                        showComment &&
                        <tr className='bg-opacity-10 bg-light'>
                          <td colSpan={5}>
                            <p>ความเห็น อนุมัติครับ</p>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </form>
          </ModalBT.Body>
        </>
      )}
    />
  );
}
