import {
  Card,
  FileValue,
  InputTextArea,
  Modal,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  CreatorLevel,
  JorPorCode,
} from 'constant/basicInformation';
import { PlanProcurement } from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaCheckCircle,
  FaSave,
  FaTimesCircle,
  FaUndo,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { planProcurement as planProcurementService } from 'services';
import {
  downloadFile,
  HttpStatusCode,
  showConfirmModalAsync,
  submitForm,
  useAppContext,
} from 'utils';
import {
  AcceptorStatus,
  PlanStatus as PlanStatusCons,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { Context } from '.';
import { UploadFile } from './UploadFile';

interface Props {
  onClickNext?: () => void;
  onClickBack?: () => void;
}

export default function Step5(props: Props) {
  const { currentStep, planProcurement, reGetDetailAsync, setPlanProcurement, readonly } = useContext(Context);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [showChangeModal, setShowChangeModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
  const { userId, positionCode, departmentId, departmentCode, inRefLevel } = useAppContext();
  const [remarkApprove, setRemarkApprove] = useState('');
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const canRemoveFile = useMemo(() => {

    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  useEffect(() => {

    if (planProcurement) {
      const queueApprover = onCheckQueueApprover(planProcurement);
      setIsQueueApprover(queueApprover);
    }
  }, [planProcurement, userId]);

  const onCheckQueueApprover = (data: PlanProcurement) => {
    const unApproved = data.approvers
      .filter(d => d.status === AcceptorStatus.PENDING
        && !d.isAvailable);

    if (unApproved.length === 1) {
      return true;
    }

    return false;
  };

  const isMD = useMemo(() => {
    return positionCode === 'MD';
  }, [positionCode]);

  const approveAsync = async () => {
    let approverId = '';

    const sectionApprovers = planProcurement.approvers.find((d) => (d.userId === userId && !d.delegateUserId) || (d.delegateUserId === userId));

    if (!approverId && sectionApprovers) {
      approverId = sectionApprovers.id!;
    }

    const res = await planProcurementService
      .approveAsync(
        planProcurement.id,
        approverId,
        remarkApprove,
      );

    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
      reGetDetailAsync(planProcurement.id);
      toast.success(`${isQueueApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
      setShowApproveModal(false);
      window.location.reload();
    }
  };

  const uploadFileOnChangeAsync = async (file: File) => {
    if (planProcurement.id) {
      const res = await planProcurementService.uploadFileAsync(
        planProcurement.id,
        file,
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        setPlanProcurement({
          ...planProcurement,
          documents: [
            ...planProcurement.documents,
            {
              file,
            },
          ],
        });

        toast.success('อัปโหลดไฟล์สำเร็จ');

        reGetDetailAsync(planProcurement.id);
      }
    } else {
      if (!planProcurement.documents) {
        planProcurement.documents = [];
      }

      setPlanProcurement({
        ...planProcurement,
        documents: [
          ...planProcurement.documents,
          {
            file,
          },
        ],
      });
    }
  };

  const files = useCallback(() => {
    const files = planProcurement.documents?.filter((d) => d.id && d.name);

    return files?.map((f) => ({
      id: f.id,
      name: f.name,
      attachmentBy: f.byUserId,
    })) as FileValue[];
  }, [planProcurement.documents]);

  const removeFileAsync = async (i: number, id: string | undefined) => {
    if (id) {
      const res = await planProcurementService.removeFileAsync(planProcurement.id, id);

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
      }
    }

    planProcurement.documents.splice(i, 1);

    setPlanProcurement({
      ...planProcurement,
      documents: [...planProcurement.documents],
    });
  };

  const downloadFileAsync = async (index: number, documentId?: string) => {
    if (documentId) {
      const res = await planProcurementService
        .downloadFileAsync(planProcurement.id, documentId);

      if (res.status === HttpStatusCode.OK) {
        const file = planProcurement.documents[index];

        downloadFile(res.data);
      }
    } else {
      const file = planProcurement.documents[index];

      if (file.file) {
        downloadFile(file.file);
      }
    }
  };

  const handlerOnSubmitAsync = async () => {
    if (planProcurement.status === PlanStatusCons.WaitingAcceptor) {
      if (!planProcurement.approvers?.length) {
        toast.warn('ต้องมีผู้มีอำนาจเห็นชอบอย่างน้อย 1 คน');

        return;
      }

      if (!planProcurement.approvers.some(d => !d.isAvailable && d.status === AcceptorStatus.PENDING)) {
        toast.warn('ต้องผู้มีอำนาจเห็นชอบที่สามารถปฏิบัติงานได้อย่างน้อย 1 คน');

        return;
      }
    }

    const res = await planProcurementService
      .updateAsync(planProcurement);

    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
      reGetDetailAsync(planProcurement.id);

      toast.success('บันทึกสำเร็จ');
    }
  };

  const canSave = useMemo(() => {
    if (!planProcurement || currentStep !== 5 || !userId) {
      return false;
    }

    if (planProcurement.status === PlanStatusCons.WaitingAcceptor
      || planProcurement.status === PlanStatusCons.Announcement
      || planProcurement.status === PlanStatusCons.ApproveAcceptor
      || planProcurement.status === PlanStatusCons.CancelPlan) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canRejectAndApprove = useMemo(() => {
    if (!Object.keys(planProcurement).length || currentStep !== 5 || !userId) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.WaitingAcceptor) {
      return false;
    }

    const minSection = Math.min(...planProcurement.approvers
      .filter(d => d.status === AcceptorStatus.PENDING
        && !d.isAvailable)
      .map(o => o.sectionSequence));

    const sectionData = planProcurement.approvers
      .filter(d => d.sectionSequence === minSection
        && d.status === AcceptorStatus.PENDING
        && !d.isAvailable)
      .sort((a, b) => a.sequence - b.sequence)[0];

    if (sectionData) {
      return (!sectionData.delegateUserId &&
        sectionData.userId === userId) ||
        (sectionData.delegateUserId === userId);
    }

    return false;
  }, [userId, planProcurement]);

  const canChangeAndCancel = useMemo(() => {
    if (!Object.keys(planProcurement).length || !userId || currentStep !== 5) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.Announcement) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (planProcurement.isCanceled) {
      return false;
    }

    if (planProcurement.isRefChange || planProcurement.isRefCancel) {
      return false;
    }

    return true;
  }, [planProcurement, userId, currentStep]);

  const canRecall = useMemo(() => {
    if (!planProcurement || !userId || currentStep !== 5) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.WaitingAcceptor) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    if (planProcurement.approvers?.some(d =>
      d.status !== AcceptorStatus.PENDING)) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const recallAsync = async () => {
    const confirmRes = await showConfirmModalAsync('คุณต้องการเรียกคืนแก้ไขหรือไม่ ?');

    if (confirmRes) {
      const res = await planProcurementService.updateStatusAsync(
        planProcurement.id,
        PlanStatusCons.DraftRecordDocument,
        'เรียกคืนแก้ไข',
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        reGetDetailAsync(planProcurement.id);
        toast.success('เรียกคืนแก้ไขสำเร็จสำเร็จ');
      }
    }
  };

  return (
    <>
      {/* Upload File */}
      {currentStep === 5
        ? (
          <Card className='mt-3'>
            <Title text='เอกสารแนบ'
              className='fs-5 text-primary' />
            <Row className='justify-content-center'>
              <Col sm={12}
                lg={6}>
                <UploadFile
                  onChange={uploadFileOnChangeAsync}
                  value={files()}
                  onRemove={removeFileAsync}
                  onDownload={downloadFileAsync}
                  canRemoveFile={canRemoveFile}
                  disabled={readonly}
                />
              </Col>
            </Row>
          </Card>
        ) : null}

      {/* Button */}
      {currentStep === 5
        ? (
          <Row className='mt-3'>
            <Col xs={12}
              md={3}>
              <ButtonCustom
                onClick={props.onClickBack}
                text="กลับหน้าแรก"
              />
            </Col>
            <Col xs={12}
              md={6}
              className='d-flex justify-content-center gap-2'>
              {canRecall
                ? (
                  <ButtonCustom
                    text="เรียกคืนแก้ไข"
                    icon='undo'
                    variant='danger'
                    onClick={recallAsync}
                  />
                ) : null}
              {canSave ? (
                <ButtonCustom
                  onClick={handlerOnSubmitAsync}
                  text="บันทึก"
                />
              ) : null}
              {canRejectAndApprove ? (
                <div className='d-flex gap-2'>
                  <ButtonCustom
                    text="ส่งกลับแก้ไข"
                    icon='undo'
                    variant='danger'
                    onClick={() => setShowRejectModal(true)}
                  />
                  <ButtonCustom
                    variant='success'
                    onClick={() => setShowApproveModal(true)}
                    icon='checked'
                    text={isQueueApprover ? 'อนุมัติ' : 'เห็นชอบ'}
                  />
                </div>
              ) : null}
              {canChangeAndCancel
                ? (
                  <div className='d-flex gap-2'>
                    <ButtonCustom
                      text="ขอเปลี่ยนแปลง"
                      icon='undo'
                      onClick={() => setShowChangeModal(true)}
                    />
                    <ButtonCustom
                      text="ขอยกเลิก"
                      icon='cancel'
                      variant='danger'
                      onClick={() => setShowCancelModal(true)}
                    />
                  </div>
                ) : null}
            </Col>
          </Row>
        ) : null}

      {/* Modal */}
      <RejectModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(false)}
      />
      <ChangeModal
        show={showChangeModal}
        onHide={() => setShowChangeModal(false)}
      />
      <CancelModal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
      />
      <Modal
        show={showApproveModal}
        size='lg'
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>
                {isQueueApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              {/* คุณต้องการ{isQueueApprover ? 'อนุมัติ' : 'เห็นชอบ'} ? */}
              <InputTextArea
                label='ความเห็นเพิ่มเติม (ถ้ามี)'
                value={remarkApprove}
                onChange={(value) => setRemarkApprove(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApproveModal(!showApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => approveAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

function RejectModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement, reGetDetailAsync } = useContext(Context);
  const [remark, setRemark] = useState<string>('');
  const { userId } = useAppContext();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (!remark) {
      return;
    }

    let approverId = '';

    const sectionApprovers = planProcurement.approvers.find((d) => (d.userId === userId && !d.delegateUserId) || (d.delegateUserId === userId));

    if (!approverId && sectionApprovers) {
      approverId = sectionApprovers.id!;
    }

    const res = await planProcurementService.rejectAsync(
      planProcurement.id,
      approverId,
      PlanStatusCons.DraftRecordDocument,
      remark,
    );

    if (res.status === HttpStatusCode.ACCEPTED) {
      reGetDetailAsync(planProcurement.id);
      toast.success('ส่งกลับแก้ไขสำเร็จ');

      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
    >
      <Modal.Header>
        <Title
          text='ส่งกลับแก้ไข'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label='เหตุผล'
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name='remark'
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-end gap-2'>
        <Button
          variant='outline-primary'
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant='primary'
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function ChangeModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement } = useContext(Context);
  const [remark, setRemark] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (!remark) {
      return;
    }

    const res = await planProcurementService.changeAsync(
      planProcurement,
      remark,
    );

    if (res.status === HttpStatusCode.CREATED) {
      toast.success('เปลี่ยนแปลงสำเร็จ');
      navigate('/procurement-plan-adjust');

      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
    >
      <Modal.Header>
        <Title
          text='ขอเปลี่ยนแปลง'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label='เหตุผล'
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name='remark'
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-end gap-2'>
        <Button
          variant='outline-primary'
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant='primary'
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function CancelModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement } = useContext(Context);
  const [remark, setRemark] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (!remark) {
      return;
    }

    const res = await planProcurementService.cancelAsync(
      planProcurement,
      remark,
    );

    if (res.status === HttpStatusCode.CREATED) {
      toast.success('ยกเลิกสำเร็จ');
      navigate('/procurement-plan-cancel');

      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
    >
      <Modal.Header>
        <Title
          text='ขอยกเลิก'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label='เหตุผล'
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name='remark'
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-end gap-2'>
        <Button
          variant='outline-primary'
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant='primary'
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}
