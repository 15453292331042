import { ContractReturnCollateralConsiderStatus } from 'components';
import StepProgress from 'components/StepProgress';
import {
  ContractReturnCollateralConsiderModel,
  ContractReturnCollateralResultModel,
} from 'models/CM/CM06Model';
import {
  createContext,
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import CM06Service from 'services/CM/CM06Service';
import { HttpStatusCode } from 'utils';
import toast from 'utils/toast';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type ContractContext = {
  data: ContractReturnCollateralConsiderModel
  setData: Dispatch<React.SetStateAction<ContractReturnCollateralConsiderModel>>,
};

export const Context = createContext({} as ContractContext);

function CreateContractMangementDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id } = useParams();
  const [data, setData] = useState<ContractReturnCollateralConsiderModel>({} as ContractReturnCollateralConsiderModel);
  const [defaultStep, setDefaultStep] = useState(1);
  const [dataCollateral, setCollateralData] = useState<ContractReturnCollateralResultModel>({} as ContractReturnCollateralResultModel);

  useEffect(() => {
    if (id) {
      getReturnCollateralAsync(id);
    }

  }, [id]);

  const getReturnCollateralAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.getContractReturnCollateralConsiderByIdAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');
      return;
    }

    await getCollateralByIdAsync(data?.contractAgreementId);
    setData(data);
    setStepByStatus(data.status);
  }, []);

  const setStepByStatus = (status: string) => {
    switch (status) {
      case ContractReturnCollateralConsiderStatus.DRAFT:
        setDefaultStep(1);
        break;
      case ContractReturnCollateralConsiderStatus.WAITFORINSPECTORACCEPT:
        setDefaultStep(2);
        break;
      case ContractReturnCollateralConsiderStatus.INSPECTORREJECTED:
        setDefaultStep(2);
        break;
      case ContractReturnCollateralConsiderStatus.WAITFORJORPORASSIGN:
        setDefaultStep(3);
        break;
      case ContractReturnCollateralConsiderStatus.JORPORASSIGNED:
        setDefaultStep(3);
        break;
      default:
        break;
    }
  };

  const getCollateralByIdAsync = useCallback(async (id: string) => {

    const { data, status } = await CM06Service.getCollateralByIdAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');
      return;
    }
    setCollateralData(data);
  }, []);

  const obj = [{
    title: <p>บันทึกผลการพิจารณาคืนหลักประกันสัญญา</p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()}
      onClickBack={() => backToDetail()}
      dataCollateral={dataCollateral!} />,
  },
  {
    title: <p> คณะกรรมการตรวจรับเห็นชอบ </p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()}
      onClickBack={() => clickBack()}
      dataCollateral={dataCollateral!} />,
  },
  {
    title: <p>ผอ.จพ.มอบหมาย</p>,
    displayIndex: '3',
    child: <Step3 onClickNext={() => clickNext()}
      onClickBack={() => clickBack()}
      dataCollateral={dataCollateral!} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/cm/cm06/detail');
  };

  const backToDetail = () => {
    navigate(`/cm/cm06/detail/${dataCollateral.contractReturnCollateralId ? dataCollateral.contractReturnCollateralId : dataCollateral.contractAgreementId}`);
  };

  return (
    <div className='document'>
      <Row>
        <Col xs={12}
          className='mb-2'>
          <Context.Provider value={{ data, setData }}>
            <StepProgress progressBarData={obj}
              ref={childRef}
              step={defaultStep} />
          </Context.Provider> <></>
        </Col>
      </Row>
    </div>
  );
}

export default CreateContractMangementDetail;
