import {
  Card,
  Input,
  InputTextArea,
  Table,
  ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import {
  InstallmentModel,
  ParcelsListModel,
} from 'models/CM/CreateContracModels';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Row,
} from 'react-bootstrap';
import {
  FaPlus,
  FaSave,
  FaTrashAlt,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import toast from 'utils/toast';
import {
  installmentData1,
  parcels,
} from './Detail/data';
import { ParcelsModal } from './Modal/ParcelsModal';

function ParcelsList() {
  const [showHistory, setShowHistory] = useState(false);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [showParcelsModal, setParcelsModal] = useState(false);
  const [parcelsList, setParcelsList] = useState<ParcelsListModel[]>([]);
  const [installment, setInstallment] = useState<InstallmentModel>({} as InstallmentModel);

  const navigate = useNavigate();
  const goToPage = (id: string) => {
    navigate(`/create-contractMangement/detail/${id}`);
  };

  useEffect(() => {
    setParcelsList(parcels);

    setInstallment(installmentData1);
  }, []);

  const onSelectParcels = (id: string, details: string, quantity: string, unit: string, typeVat: string, offerPrice: string, quotedPriceTogether: string, agreedPrice: string, agreedPriceTogether: string) => {
    if (parcelsList && parcelsList.filter(x => x.id === id).length > 0) {
      toast.warn('ไม่สามารถเลือกรายการซ้ำได้');
    } else {
      const newData: ParcelsListModel = {
        id: undefined,
        details: details,
        quantity: quantity,
        unit: unit,
        typeVat: typeVat,
        offerPrice: offerPrice,
        quotedPriceTogether: quotedPriceTogether,
        agreedPrice: agreedPrice,
        agreedPriceTogether: agreedPriceTogether,
        amountDelivery: '',
        unitDelivery: '',
        deliveryDays: '',
        deliveryDate: '',
      };
      setParcelsList((committeesMiddlePrices) => [...committeesMiddlePrices, newData]);
    }
  };

  const removeParcels = (i: number) => {
    const temp: ParcelsListModel[] = [...parcelsList];
    temp.splice(i, 1);
    setParcelsList(temp);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <Title text='เงื่อนไขการชำระเงิน'
            className='fs-5 text-primary' />
        </div>
        <h5>งวด : {installment.Installment} </h5>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={4}>
            <Input label='จำนวน (%)'
              placeholder='จำนวน (%)'
              value={installment.percent}
              disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={4}>
            <InputTextArea label='รายละเอียด'
              placeholder='รายละเอียด'
              rule={{ required: true }}
              value={installment.details1}
              disabled />
          </Col>
          <Col sm={12}
            md={4}
            xl={4}>
            <Input
              label='จำนวนเงิน (บาท)'
              rule={{ required: true }}
              value={installment.amount}
              placeholder='วงเงิน (บาท)'
              disabled
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={4}>
            <Input label='จำนวนวัน'
              placeholder='จำนวนวัน'
              rule={{ required: true }}
              disabled
              value={installment.day} />
          </Col>
          <Col sm={12}
            md={4}
            xl={4}>
            <Input label='วันที่ต้องส่งมอบ'
              placeholder='วันที่ต้องส่งมอบ'
              rule={{ required: true }}
              disabled
              value={installment.deliveryDate} />
            {/*
            <DatePicker label='วันที่ต้องส่งมอบ' placeholder="วันที่ต้องส่งมอบ" disabled value={installment.deliveryDate} /> */}
          </Col>
        </Row>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='รายการของพัสดุ'
              className='fs-5 text-primary' />
            {openCollapse1 ? (
              <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />)}
          </div>
          <Button
            variant='outline-primary'
            onClick={() => setParcelsModal(!showParcelsModal)}>
            <FaPlus className='me-1' />เพิ่มรายการ
          </Button>
        </div>
        <Collapse in={openCollapse1}>
          <div>
            <Table total={1}
              hidePagination>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>ลำดับ</th>
                  <th style={{ width: 400 }}>รายละเอียด</th>
                  <th style={{ width: 150 }}>จำนวน</th>
                  <th style={{ width: 150 }}>หน่วย</th>
                  <th style={{ width: 150 }}>ประเภท VAT</th>
                  <th style={{ width: 150 }}>ราคาที่ตกลง/<br />ต่อหน่วย</th>
                  <th style={{ width: 150 }}>ราคาที่ตกลง<br />รวม</th>
                  <th style={{ width: 150 }}>จำนวน<br />ที่ต้องส่ง</th>
                  <th style={{ width: 150 }}>จำนวนวัน<br />ส่งมอบ</th>
                  <th style={{ width: 150 }}>หน่วย</th>
                  <th style={{ width: 150 }}>วันที่ต้องส่งมอบ</th>
                  <th style={{ width: 150 }} />
                </tr>
              </thead>
              <tbody>
                {
                  parcelsList.map((data, index) => (
                    <tr className='text-center'
                      key={index}>
                      <td>{index + 1}</td>
                      <td className='text-start'>{data.details}</td>
                      <td>{data.quantity}</td>
                      <td>{data.unit}</td>
                      <td>{data.typeVat}</td>
                      <td>{data.agreedPrice}</td>
                      <td>{data.agreedPriceTogether}</td>
                      <td><Input value={data.amountDelivery}
                        textEnd /></td>
                      <td><Input value={data.unitDelivery} /></td>
                      <td><Input value={data.deliveryDays} /></td>
                      <td><Input value={data.deliveryDate} /></td>
                      <td className='d-flex justify-content-end'>
                        <Button
                          variant='danger'
                          className='d-flex align-items-center gap-2'
                          onClick={() => removeParcels(index)}
                          disabled={false}
                        >
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
        </Collapse>
      </Card>
      <div className='d-flex justify-content-between gap-3 pt-3'>
        <Button
          onClick={() => goToPage('')}
          className='me-2 px-3'
          variant='outline-primary'
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          <Button
            variant='success'
            className='me-2 px-3'
          >
            <FaSave className='ms-2 pb-1 fs-5' />
            บันทึก
          </Button>
        </div>
        <div />
      </div>
      <ParcelsModal
        show={showParcelsModal}
        onHide={() => setParcelsModal(!showParcelsModal)}
        onSelectItem={onSelectParcels}
      />
    </div>
  );
}

export default ParcelsList;
