import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLoaderData } from 'react-router';
import { ItemModel } from '../../../../../models';
import { ContractAgreementDocumentModel, ContractAgreementModel, UpdateAcceptorStep4Model, UpdateContractAgreementStep2Model } from '../../../../../models/CM/CM02Models';
import CM02Service from '../../../../../services/CM/CM02Service';
import { HttpStatusCode } from '../../../../../utils';
import toast from '../../../../../utils/toast';
import { Context } from '../index';
import { shareValue } from 'services';
import { PaymentTypeIdEnum, SectionContractFormatCode, SharedvalueGroup } from 'utils/constants/ContractEnum';
import { AdvancePayment, ContractAttachment, DefectWarranty, EmploymentAgreement, EntrepreneurDetail, PaymentManager, WagePaymentDetail } from './Components';
import { CollateralDetail } from './Components/CollateralDetail';
import { FineDetail } from './Components/FineDetail';
import { ButtonCustom, Card, ContractAgreementStatus, Modal, Readonly } from 'components';
import { RetentionDetail } from './Components/RetentionDetail';
import { DeliveryLeaseDetail } from './Components/DeliveryLeaseDetail';
import { RentalPeriodDetail } from './Components/RentalPeriodDetail';
import { ServicePeriodDetail } from './Components/ServicePeriodDetail';
import { RentPayment } from './Components/RentPayment';
import { ReceiveAgreement } from './Components/ReceiveAgreement';
import { DefectWarrantyAgainst } from './Components/DefectWarrantyAgainst';
import { ProcessingTime2 } from './Components/ProcessingTime2';
import { ProcessingTime } from './Components/ProcessingTime';
import {
  Col,
  Modal as ModalBT, Row,
} from 'react-bootstrap';
import { EmployerDetail } from './Components/EmployerDetail';


type Loader = {
  performanceBondTypeDDL: ItemModel[],
  periodConditionDDL: ItemModel[],
  periodTypeDDL: ItemModel[],
  vatTypeDDL: ItemModel[],
  unitOfMeasureDDL: ItemModel[],
  periodTimeTypeDDL: ItemModel[],
  bankDDL: ItemModel[],
  fineTypeDDL: ItemModel[],
  deductionPhaseTypeDDL: ItemModel[],
  repairPeriodTypeDDL: ItemModel[],
  paymentWithinTypeDDL: ItemModel[],
  paymentTypeDDL: ItemModel[],
  advanceDeductionTypeDDL: ItemModel[],
  warrantyConditionDDL: ItemModel[],
  provincesDDL: ItemModel[],
};

interface IProps {
  templateId: string;
  templateCode: string;
  onClickNext: () => void;
  onClickBack: () => void;
}

function MainSectionContractAgreementDetail(props: IProps) {
  const { performanceBondTypeDDL, periodConditionDDL, periodTypeDDL, vatTypeDDL, unitOfMeasureDDL, periodTimeTypeDDL, bankDDL, fineTypeDDL, repairPeriodTypeDDL, paymentWithinTypeDDL, paymentTypeDDL, advanceDeductionTypeDDL, warrantyConditionDDL, provincesDDL } = useLoaderData() as Loader;
  const { dataContext, sectionContractContext, setDataContext, setSectionContractContext } = useContext(Context);
  const [isDisabled, setIsDisabled] = useState(false);
  const [contractAgreementData, setContractAgreementData] = useState<ContractAgreementModel>(dataContext);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);

  useEffect(() => {
    if (dataContext) {
      setContractAgreementData(dataContext);
    }
  }, [dataContext]);

  useEffect(() => {
    const contractTemplate = dataContext.contractTemplate ? dataContext.contractTemplate : props.templateId;

    if (sectionContractContext.length === 0
      && contractTemplate) {
      getSectionContractFormat(contractTemplate);
    }
  }, [contractAgreementData]);

  const getSectionContractFormat = async (contractTemplate: string) => {

    const {
      data,
      status,
    } = await shareValue.getListSharedValueAsync(SharedvalueGroup.SectionContractFormat, contractTemplate);

    if (status === HttpStatusCode.OK) {
      const items: ItemModel[] = data;

      setSectionContractContext(items);
    }
  };

  const getContractByIdAsync = useCallback(async (id: string) => {
    const { data, status } = await CM02Service.getContractByIdAsync(id);

    if (status === HttpStatusCode.OK) {
      setDataContext(data);
    }
  }, [setDataContext]);

  interface IValidator {
    fnContractData: () => boolean;
    message: string;
  }

  const onSave = async () => {
    const validators: IValidator[] = [
      {
        fnContractData: () =>
          contractAgreementData.collateral &&
          contractAgreementData.collateral.active === true &&
          !contractAgreementData.collateral.type,
        message: 'กรุณาระบุประเภทหลักประกันสัญญา',
      },
      {
        fnContractData: () =>
          contractAgreementData.defectsWarranty &&
          contractAgreementData.defectsWarranty.isDefectsWarranty === true &&
          (!contractAgreementData.defectsWarranty.periodDay &&
            !contractAgreementData.defectsWarranty.periodMonth &&
            !contractAgreementData.defectsWarranty.periodYear),
        message: 'กรุณาระบุข้อมูลรับประกันความชำรุดบกพร่อง',
      },
      {
        fnContractData: () =>
          contractAgreementData.payment &&
          (contractAgreementData.payment.type === PaymentTypeIdEnum.PayByPhaseAssign && contractAgreementData.contractAgreementWorkPhases.length === 0),
        message: 'กรุณาระบุรายละเอียดการส่งมอบ',
      },
    ];

    for (const key in validators) {
      const validator = validators[key];

      if (validator.fnContractData()) {
        toast.warn(validator.message);

        return;
      }
    }

    if (contractAgreementData.documents) {
      contractAgreementData.documents.map((x, index) => {
        x.sequence = index + 1;
        return x.sequence;
      })
    }

    const { data, status } = await CM02Service.updateContractStep2Async(dataContext.id, contractAgreementData);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');
      return;
    }

    //DefaultAppendixFile
    // const response = await CM02Service.updateDefaultUploadAppendixFileAsync(dataContext.id);

    if (dataContext.contractAgreementAcceptors?.length > 0) {
      const updateAcceptor: UpdateAcceptorStep4Model = {
        acceptors: dataContext.contractAgreementAcceptors,
        sendToApprove: false,
      }

      const res = await CM02Service.updateAcceptorStep4Async(dataContext.id, updateAcceptor);
    }

    toast.success('บันทึกข้อมูลสัญญาสำเร็จ');

    if (dataContext.id) {
      getContractByIdAsync(dataContext.id);
    }

    setShowSaveConfirmModal(false);
  }

  const switchTemplate = () => {
    const sectionContracts = sectionContractContext;
    const sectionContractFormat: JSX.Element[] = [];
    sectionContractFormat.push(
      <Card className='mt-3'>
        <Row>
          <Col sm={12}
            lg={12}>
            <Readonly label='ประเภทสัญญา'
              textReadonly={`${contractAgreementData.contractTemplateValue} ${contractAgreementData.subContractTemplateValue ? ` ,${contractAgreementData.subContractTemplateValue}` : ''}`} />
          </Col>
        </Row>
      </Card>
    )

    sectionContractFormat.push(
      <EmployerDetail
        employerData={dataContext.employer}
        contractTemplateCode={props.templateCode}
        onChange={(obj) => setContractAgreementData(pre => ({ ...pre, employer: obj }))}
        acceptorSigned={dataContext.contractAgreementAcceptors}
        onChangeAcceptorSigned={(obj) => setDataContext(pre => ({ ...pre, contractAgreementAcceptors: obj }))}
        sumOfferTotalAmountVat={dataContext.sumOfferTotalAmountVat}
        supplyMethod={dataContext.supplyMethodId}
        isDisabled={isDisabled}
        isResponsible={dataContext.isResponsible}
        provincesDDL={provincesDDL} />
    )

    sectionContractFormat.push(
      <EntrepreneurDetail
        data={dataContext.entrepreneur}
        onPartnerDataChange={(obj) => setContractAgreementData(pre => ({ ...pre, entrepreneur: obj }))}
        isDisabled={isDisabled}
        provincesDDL={provincesDDL} />
    )

    sectionContracts?.sort((a, b) => a.sequence! - b.sequence!).forEach((item) => {
      switch (item.code) {
        case SectionContractFormatCode.CMS001: //ข้อตกลง
          sectionContractFormat.push(
            <EmploymentAgreement
              isResponsible={dataContext.isResponsible}
              title={item.label}
              contractTemplateCode={props.templateCode}
              contractAgreement={dataContext}
              employmentAgreement={dataContext.employmentAgreement}
              onEmploymentAgreementChange={(obj) => setContractAgreementData(pre => ({
                ...pre,
                employmentAgreement: obj,
              }))}
              onContractAgreementChange={(obj) => setContractAgreementData(obj)}
              onSubmitContractAgreementWorkPhase={(models) =>
                setContractAgreementData({
                  ...contractAgreementData,
                  contractAgreementWorkPhases: models,
                })}
              fine={dataContext.fine}
              onFineChange={(obj) => setContractAgreementData(pre => ({
                ...pre,
                fine: obj
              }))}
              fineTypeDDL={fineTypeDDL}
              isDisabled={isDisabled}
              periodTypeDDL={periodTypeDDL}
              vatTypeDDL={vatTypeDDL}
              unitTypeDDL={unitOfMeasureDDL}
              paymentTypeDDL={paymentTypeDDL}
              provincesDDL={provincesDDL}
              contractId={dataContext.id}
              contractAgreementWorkPhaseData={dataContext.contractAgreementWorkPhases}
              contractOfferTotalAmountVat={dataContext.offerTotalAmountVat} />
          )
          break;
        case SectionContractFormatCode.CMS002: //เอกสารอันเป็นส่วนหนึ่งของสัญญา
          sectionContractFormat.push(
            <ContractAttachment
              isResponsible={dataContext.isResponsible}
              title={item.label}
              context={dataContext}
              contractId={dataContext.id}
              isDisabled={isDisabled}
              onDocumentChange={(obj) => setContractAgreementData(pre => ({
                ...pre,
                documents: obj,
              }))} />
          )
          break;
        case SectionContractFormatCode.CMS003: //หลักประกันการปฏิบัติตามสัญญา
          sectionContractFormat.push(
            <CollateralDetail
              title={item.label}
              contractTemplateCode={props.templateCode}
              offerTotalAmountVat={dataContext.offerTotalAmountVat}
              collateral={dataContext.collateral}
              bankDDL={bankDDL}
              periodTypeDDL={periodTypeDDL}
              periodConditionDDL={periodConditionDDL}
              performanceBondTypeDDL={performanceBondTypeDDL}
              isDisabled={isDisabled}
              onCollateralChange={(obj) => setContractAgreementData(pre => ({
                ...pre,
                collateral: obj,
              }))}
            />
          )
          break;
        case SectionContractFormatCode.CMS004: //ค่าจ้างและการจ่ายเงิน
          sectionContractFormat.push(
            <WagePaymentDetail
              isResponsible={dataContext.isResponsible}
              title={item.label}
              data={dataContext.wagePayment}
              contractId={dataContext.id}
              contractOfferTotalAmountVat={dataContext.offerTotalAmountVat}
              context={dataContext}
              isDisabled={isDisabled}
              onEarningsAndPaymentsChange={(obj) => setContractAgreementData(pre => ({
                ...pre,
                wagePayment: obj,
              }))}
              onSubmitContractAgreementWorkPhase={(models) =>
                setContractAgreementData({
                  ...contractAgreementData,
                  contractAgreementWorkPhases: models,
                })}
              onSubmitMaintenanceInformations={(models) =>
                setContractAgreementData({
                  ...contractAgreementData,
                  maintenanceInformation: models,
                })}
              onPaymentChange={(obj) => setContractAgreementData(pre => ({ ...pre, payment: obj }))}
              periodTypeDDL={periodTypeDDL}
              paymentTypeDDL={paymentTypeDDL}
              advanceDeductionTypeDDL={advanceDeductionTypeDDL}
              contractTemplateCode={props.templateCode}
              advancePayment={dataContext.payment}
              contractAgreementWorkPhaseData={dataContext.contractAgreementWorkPhases} />
          )
          break;
        case SectionContractFormatCode.CMS005: //เงินค่าจ้างล่วงหน้า
          sectionContractFormat.push(
            <AdvancePayment
              title={item.label}
              contractTemplateCode={props.templateCode}
              periodTypeDDL={periodTypeDDL}
              advanceDeductionTypeDDL={advanceDeductionTypeDDL}
              performanceBondTypeDDL={performanceBondTypeDDL}
              advancePayment={dataContext.payment}
              onAdvancePaymentChange={(obj) => setContractAgreementData(pre => ({
                ...pre,
                payment: obj,
              }))}
              isDisabled={isDisabled} />
          )
          break;
        case SectionContractFormatCode.CMS006: //การหักเงินประกันผลงาน
          sectionContractFormat.push(
            <RetentionDetail
              title={item.label}
              contractTemplateCode={props.templateCode}
              retention={dataContext.retention}
              advanceDeductionTypeDDL={advanceDeductionTypeDDL}
              onRetentionChange={(obj) => setContractAgreementData(pre => ({ ...pre, retention: obj }))}
              isDisabled={isDisabled}
            />
          )
          break;
        case SectionContractFormatCode.CMS007: //ค่าปรับ
          sectionContractFormat.push(
            <FineDetail
              title={item.label}
              contractTemplateCode={props.templateCode}
              offerTotalAmountVat={dataContext.offerTotalAmountVat}
              fine={dataContext.fine}
              onFineChange={(obj) => setContractAgreementData(pre => ({ ...pre, fine: obj }))}
              periodTypeDDL={periodTypeDDL}
              fineTypeDDL={fineTypeDDL}
              isDisabled={isDisabled}
              supplyMethodId={dataContext.supplyMethodId}
              supplyMethodTypeId={dataContext.supplyMethodTypeId}
            />
          )
          break;
        case SectionContractFormatCode.CMS008: //การส่งมอบ
          sectionContractFormat.push(
            <DeliveryLeaseDetail
              isResponsible={dataContext.isResponsible}
              title={item.label}
              contractTemplateCode={props.templateCode}
              deliveryData={dataContext.delivery}
              employmentAgreement={dataContext.employmentAgreement}
              contractAgreementWorkPhaseData={dataContext.contractAgreementWorkPhases}
              periodTypeDDL={periodTypeDDL}
              periodConditionDDL={periodConditionDDL}
              contractId={dataContext.id}
              contractOfferTotalAmountVat={dataContext.offerTotalAmountVat}
              context={dataContext}
              isDisabled={isDisabled}
              onDeliveryChange={(obj) => setContractAgreementData(pre => ({ ...pre, delivery: obj }))}
              onSubmitDeliverWorkPhaseModal={(models) =>
                setContractAgreementData({ ...contractAgreementData, contractAgreementWorkPhases: models })}
              onSubmitMaintenanceInformations={(models) =>
                setContractAgreementData({ ...contractAgreementData, maintenanceInformation: models })}
              onEmploymentAgreementChange={(obj) => setContractAgreementData(pre => ({
                ...pre,
                employmentAgreement: obj,
              }))} />
          )
          break;
        case SectionContractFormatCode.CMS009: //การชำระเงิน
          sectionContractFormat.push(
            <PaymentManager
              isResponsible={dataContext.isResponsible}
              contractTemplateCode={props.templateCode}
              title={item.label}
              periodTypeDDL={periodTypeDDL}
              advanceDeductionTypeDDL={advanceDeductionTypeDDL}
              periodConditionDDL={periodConditionDDL}
              paymentWithinTypeDDL={paymentWithinTypeDDL}
              contractId={dataContext.id}
              payment={dataContext.payment}
              contractAgreementWorkPhaseData={dataContext.contractAgreementWorkPhases}
              paymentTypeDDL={paymentTypeDDL}
              isDisabled={isDisabled}
              contractOfferTotalAmountVat={dataContext.offerTotalAmountVat}
              onPaymentChange={(obj) => setContractAgreementData(pre => ({ ...pre, payment: obj }))}
              onSubmitContractAgreementWorkPhase={(models) =>
                setContractAgreementData({
                  ...contractAgreementData,
                  contractAgreementWorkPhases: models,
                })}
            />
          )
          break;
        case SectionContractFormatCode.CMS010: //การตรวจรับ
          sectionContractFormat.push(
            <ReceiveAgreement
              title={item.label}
              contractTemplateCode={props.templateCode}
              defectsReceive={dataContext.receive}
              periodTypeDDL={periodTypeDDL}
              onDefectReceiveChange={(models) =>
                setContractAgreementData({ ...contractAgreementData, receive: models })}
              isDisabled={isDisabled}
            />
          )
          break;
        case SectionContractFormatCode.CMS011: //ระยะเวลาการเช่า
          sectionContractFormat.push(
            <RentalPeriodDetail
              title={item.label}
              contractTemplateCode={props.templateCode}
              employmentAgreement={dataContext.employmentAgreement}
              onEmploymentAgreementChange={(obj) => setContractAgreementData(pre => ({
                ...pre,
                employmentAgreement: obj,
              }))}
              isDisabled={isDisabled}
            />
          )
          break;
        case SectionContractFormatCode.CMS012: //ระยะเวลาให้บริการ
          sectionContractFormat.push(
            <ServicePeriodDetail
              title={item.label}
              contractTemplateCode={props.templateCode}
              contractData={dataContext}
              employmentAgreement={dataContext.employmentAgreement}
              onServiceDetailChange={(obj) => setContractAgreementData(pre => ({
                ...pre,
                employmentAgreement: obj,
              }))}
              isDisabled={isDisabled}
            />
          )
          break;
        case SectionContractFormatCode.CMS013: //ค่าเช่ารถยนต์ // ค่าเช่าเครื่องถ่ายเอกสาร
          sectionContractFormat.push(
            <RentPayment
              rentPayment={dataContext.rentPayment}
              title={item.label}
              contractTemplateCode={props.templateCode}
              onRentPaymentChange={(models) =>
                setContractAgreementData({ ...contractAgreementData, rentPayment: models })}
              isDisabled={isDisabled}
              periodTypeDDL={periodTypeDDL}
            />
          )
          break;
        case SectionContractFormatCode.CMS014: //ความรับผิดชอบในความชำรุดบกพร่องของงานจ้าง
          sectionContractFormat.push(
            <DefectWarranty
              title={item.label}
              defectsWarranty={dataContext.defectsWarranty}
              contractTemplateCode={props.templateCode}
              periodTypeDDL={periodTypeDDL}
              periodConditionDDL={periodConditionDDL}
              warrantyConditionDDL={warrantyConditionDDL}
              periodConditionOtherDDL={periodConditionDDL}
              repairPeriodTypeDDL={repairPeriodTypeDDL}
              onDefectWarrantyChange={(obj) => setContractAgreementData(pre => ({ ...pre, defectsWarranty: obj }))}
              isDisabled={isDisabled} />
          )
          break;
        case SectionContractFormatCode.CMS015: //(ข) การรับประกันความชำรุดบกพร่อง
          sectionContractFormat.push(
            <DefectWarrantyAgainst
              title={item.label}
              defectsWarranty={dataContext.defectsWarranty}
              contractTemplateCode={props.templateCode}
              periodTypeDDL={periodTypeDDL}
              periodConditionDDL={periodConditionDDL}
              repairPeriodTypeDDL={repairPeriodTypeDDL}
              onDefectWarrantyChange={(obj) => setContractAgreementData(pre => ({ ...pre, defectsWarranty: obj }))}
              isDisabled={isDisabled} />
          )
          break;
        case SectionContractFormatCode.CMS016: //ระยะเวลาดำเนินการ
          sectionContractFormat.push(
            <ProcessingTime
              title={item.label}
              contractTemplateCode={props.templateCode}
              contractData={dataContext}
              employmentAgreement={dataContext.employmentAgreement}
              onEmploymentAgreementChange={(obj) => setContractAgreementData(pre => ({ ...pre, employmentAgreement: obj }))}
              isDisabled={isDisabled} />
          )
          break;
        case SectionContractFormatCode.CMS017: //ระยะเวลาดำเนินการ
          sectionContractFormat.push(
            <ProcessingTime2
              title={item.label}
              contractTemplateCode={props.templateCode}
              contractData={dataContext}
              employmentAgreement={dataContext.employmentAgreement}
              onEmploymentAgreementChange={(obj) => setContractAgreementData(pre => ({ ...pre, employmentAgreement: obj }))}
              isDisabled={isDisabled} />
          )
          break;
      }
    });

    sectionContractFormat.push(
      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={props.onClickBack}
          text="ย้อนกลับ"
        />
        {
          (dataContext.isResponsible && (dataContext.status === ContractAgreementStatus.Draft
            || dataContext.status === ContractAgreementStatus.Rejected)) && (
            <ButtonCustom
              onClick={() => setShowSaveConfirmModal(true)}
              text="บันทึก" />
          )
        }
        <ButtonCustom
          onClick={props.onClickNext}
          text="ถัดไป"
          disabled={dataContext.employmentAgreement === null} />
        <Modal
          show={showSaveConfirmModal}
          size='lg'
          onHide={() => setShowSaveConfirmModal(!showSaveConfirmModal)}
          children={(
            <>
              <ModalBT.Header closeButton>
                <ModalBT.Title>ยืนยัน</ModalBT.Title>
              </ModalBT.Header>
              <ModalBT.Body className='p-0 ps-4'>
                คุณต้องการบันทึกข้อมูลหรือไม่ ?
              </ModalBT.Body>
              <ModalBT.Footer>
                <ButtonCustom
                  variant='danger'
                  onClick={() => setShowSaveConfirmModal(!showSaveConfirmModal)}
                  text="ยกเลิก"
                  icon="cancel"
                />
                <ButtonCustom
                  onClick={() => onSave()}
                  text="ยืนยัน"
                  icon="save"
                />
              </ModalBT.Footer>
            </>
          )}
        />
      </div>
    );

    return sectionContractFormat;
  };

  return (
    <>
      {
        switchTemplate()
      }
    </>
  );
}

export default MainSectionContractAgreementDetail;
