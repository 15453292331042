import { BusinessUnitRequest } from 'models';
import http from 'utils/axios';

const createBusinessUnitAsync = async (body: BusinessUnitRequest) => await http.post('/businessunit', body);

const getBusinessUnitListAsync = async (page: number, size: number, keyword?: string) => {
  const params = {
    page,
    size,
    keyword,
  };

  return await http.get('/businessunit', { params });
};

const getBusinessUnitListByActiveStatus = async (activeStatus: boolean) => {
  const params = {
    activeStatus,
  };

  return await http.get('/businessunit/active-status', { params });
};

const getBusinessUnitDetailAsync = async (id: string) => await http.get(`/businessunit/${id}`);

const updateBusinessUnitAsync = async (id: string, body: BusinessUnitRequest) => await http.put(`/businessunit/${id}`, body);

const deleteBusinessUnitAsync = async (id: string) => await http.delete(`/business/${id}`);

export default {
  createBusinessUnitAsync,
  getBusinessUnitListAsync,
  getBusinessUnitDetailAsync,
  updateBusinessUnitAsync,
  deleteBusinessUnitAsync,
  getBusinessUnitListByActiveStatus,
};