import {
  Check,
  ContractReturnCollateralStatus,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  DatePicker,
  Input,
  Selector,
} from 'components/Controls';
import Title from 'components/Controls/Title';
import { StatusCMReturnCollateral } from 'components/StatusCMReturnCollateral';
import { ItemModel } from 'models';
import {
  CollateralListModel,
  CountStatusModel,
  SearchCollateralListModel,
} from 'models/CM/CM06Model';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { FaEraser, FaSearch } from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import CM06Service from 'services/CM/CM06Service';
import {
  HttpStatusCode,
  THCurrency,
} from 'utils';

interface StatusItemModel {
  label: string;
  value: boolean;
}

type Loader = { departmentDDL: ItemModel[] };

const defaultStatus = [ContractReturnCollateralStatus.NONE, ContractReturnCollateralStatus.WAITINGFORAPPROVE, ContractReturnCollateralStatus.WAITINGFORCONSIDER, ContractReturnCollateralStatus.WAITINGFORRECEIVECOLLATERAL, ContractReturnCollateralStatus.COMPLETED,
];

export default function CM06() {
  const goToPage = (contractAgreementId: string, id: string) => {
    navigate(`/cm/cm06/detail/${id || contractAgreementId}`);
  };
  const { departmentDDL } = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [dataList, setDataList] = useState<CollateralListModel[]>([]);
  const [criteria, setCriteria] = useState<SearchCollateralListModel>({} as SearchCollateralListModel);
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [statusNone, setStatusNone] = useState<boolean>(false);
  // const [statusDraft, setStatusDraft] = useState<boolean>(false);
  const [statusWaitingForConsider, setStatusWaitingForConsider] = useState<boolean>(false);
  const [statusWaitingForApprove, setStatusWaitingForApprove] = useState<boolean>(false);
  const [statusWaitingForReceiveCollateral, setStatusWaitingForReceiveCollateral] = useState<boolean>(false);
  const [statusCompleted, setStatusCompleted] = useState<boolean>(false);
  const [departmentId, setDepartmentId] = useState('');
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    { value: false, label: ContractReturnCollateralStatus.ALL },
    { value: false, label: ContractReturnCollateralStatus.NONE },
    // { value: false, label: ContractReturnCollateralStatus.DRAFT },
    { value: false, label: ContractReturnCollateralStatus.WAITINGFORAPPROVE },
    { value: false, label: ContractReturnCollateralStatus.WAITINGFORCONSIDER },
    { value: false, label: ContractReturnCollateralStatus.WAITINGFORRECEIVECOLLATERAL },
    { value: false, label: ContractReturnCollateralStatus.COMPLETED },
  ]);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);

  useEffect(() => {
    setIsResponsible(true);
    setStatusAll(true);
    setStatusNone(true);
    // setStatusDraft(true);
    setStatusWaitingForConsider(true);
    setStatusWaitingForApprove(true);
    setStatusWaitingForReceiveCollateral(true);
    setStatusCompleted(true);
    getListDataAsync(criteria);
  }, []);

  const getListDataAsync = async (searchModel: SearchCollateralListModel) => {

    if (searchModel.isResponsible === undefined || searchModel.isResponsible === null) {
      searchModel.isResponsible = true;
    }

    if (!searchModel.page) {
      searchModel.page = page;
    }

    if (!searchModel.size) {
      searchModel.size = size;
    }

    if (searchModel.status === undefined || searchModel.status.length === 0) {
      searchModel.status = defaultStatus;
    }
    const res = await CM06Service.getListAsync(
      searchModel,
    );

    if (res.status === HttpStatusCode.OK) {
      setDataList(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }
    getCountStatus(searchModel);
  };

  const getCountStatus = useCallback(async (searchModel: SearchCollateralListModel) => {
    const countStatusRes = await CM06Service.getCountStatusAsync(
      searchModel,
    );

    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data);
    }
  }, []);

  const onChangeStatusAll = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setStatusNone(true);
      // setStatusDraft(true);
      setStatusWaitingForConsider(true);
      setStatusWaitingForApprove(true);
      setStatusWaitingForReceiveCollateral(true);
      setStatusCompleted(true);
    } else {
      setStatusAll(false);
      setStatusNone(false);
      // setStatusDraft(false);
      setStatusWaitingForConsider(false);
      setStatusWaitingForApprove(false);
      setStatusWaitingForReceiveCollateral(false);
      setStatusCompleted(false);
    }
  };

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex(s => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter(s => s.value === false).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case ContractReturnCollateralStatus.NONE:
        return setStatusNone(value);
      case ContractReturnCollateralStatus.DRAFT:
        return setStatusNone(value);
      case ContractReturnCollateralStatus.WAITINGFORAPPROVE:
        return setStatusWaitingForApprove(value);
      case ContractReturnCollateralStatus.WAITINGFORCONSIDER:
        return setStatusWaitingForConsider(value);
      case ContractReturnCollateralStatus.WAITINGFORRECEIVECOLLATERAL:
        return setStatusWaitingForReceiveCollateral(value);
      case ContractReturnCollateralStatus.COMPLETED:
        return setStatusCompleted(value);
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);
    criteria.page = page;
    criteria.size = size;
    onSearch(size, page);
  };

  const onSearch = (size: number, page: number) => {
    const status: string[] = [];

    if (statusNone) {
      status.push(ContractReturnCollateralStatus.NONE);
    }

    // if (statusDraft) {
    //   status.push(ContractReturnCollateralStatus.DRAFT);
    // }

    if (statusWaitingForApprove) {
      status.push(ContractReturnCollateralStatus.WAITINGFORAPPROVE);
    }

    if (statusWaitingForConsider) {
      status.push(ContractReturnCollateralStatus.WAITINGFORCONSIDER);
    }

    if (statusWaitingForReceiveCollateral) {
      status.push(ContractReturnCollateralStatus.WAITINGFORRECEIVECOLLATERAL);
    }

    if (statusCompleted) {
      status.push(ContractReturnCollateralStatus.COMPLETED);
    }

    const searchCriteria = {
      contractStartDate: criteria.contractStartDate,
      contractEndDate: criteria.contractEndDate,
      status,
      page: page,
      size: size,
      contractNumber: criteria.contractNumber,
      departmentId: departmentId,
      planName: criteria.planName,
      planNumber: criteria.planNumber,
      entrepreneursName: criteria.entrepreneursName,
      isResponsible: isResponsible
    } as SearchCollateralListModel;
    getListDataAsync(searchCriteria);
  };

  const onClearSearch = () => {
    const status: string[] = [];
    const isSize = 10;
    const isPage = 1;
    setPage(isPage);
    setSize(isSize);
    setStatusAll(true);
    setStatusNone(true);
    // setStatusDraft(true);
    setStatusWaitingForConsider(true);
    setStatusWaitingForApprove(true);
    setStatusWaitingForReceiveCollateral(true);
    setStatusCompleted(true);
    setDepartmentId('');
    setIsResponsible(true);
    onPaginationChange(isSize, isPage);
    setCriteria({
      isAssigneeOnly: true,
      page: isPage,
      size: isSize,
      isResponsible: true,
    } as SearchCollateralListModel);
    const searchCriteria = {
      page: isPage,
      size: isSize,
      isAssigneeOnly: true,
      status,
      isResponsible: true,
    } as SearchCollateralListModel;
    getListDataAsync(searchCriteria);
  };

  return (
    <div className='m01'>
      <Title text='คืนหลักประกันสัญญา' />
      <hr />
      <Row className='criteria'>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่รายการจัดซื้อจัดจ้าง'
            placeholder='เลขที่รายการจัดซื้อจัดจ้าง'
            onChange={planNumber => setCriteria({ ...criteria, planNumber })}
            value={criteria.planNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่สัญญา'
            placeholder='เลขที่สัญญา'
            onChange={contractNumber => setCriteria({ ...criteria, contractNumber })}
            value={criteria.contractNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='ชื่อโครงการ'
            placeholder='ชื่อโครงการ'
            onChange={planName => setCriteria({ ...criteria, planName })}
            value={criteria.planName} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='ผู้ประกอบการ/คู่ค้า'
            placeholder='ผู้ประกอบการ/คู่ค้า'
            onChange={entrepreneursName => setCriteria({ ...criteria, entrepreneursName })}
            value={criteria.entrepreneursName} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='ฝ่าย/สำนัก'
            placeholder='ฝ่าย/สำนัก'
            items={departmentDDL}
            value={`${departmentId}`}
            onChange={(value) => (setDepartmentId(value.toString()))}
          />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker label='วันที่เริ่มสัญญา'
            placeholder='วันที่เริ่มสัญญา'
            onChangeHasNullable={contractStartDate => setCriteria({ ...criteria, contractStartDate })}
            value={criteria.contractStartDate} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker label='วันที่สิ้นสุด'
            placeholder='วันที่สิ้นสุด'
            onChangeHasNullable={contractEndDate => setCriteria({ ...criteria, contractEndDate })}
            value={criteria.contractEndDate} />
        </Col>
        <Col sm={12}>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={(
                  <div className='align-items-center d-flex'>ทั้งหมด
                    <Badge className='h-50 mx-2'
                      bg='light'>{countStatus ? countStatus.reduce((a, v) => a += v.count, 0) : '0'}</Badge>
                  </div>
                )}
                value={statusAll}
                onChange={(val) => onChangeStatusAll(val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>รอดำเนินการพิจารณาคืนหลักประกัน<Badge className='h-50 mx-2'
                    bg='secondary'>
                    {countStatus.filter((x) => x.status === ContractReturnCollateralStatus.NONE).length > 0 ? countStatus.filter((x) => x.status === ContractReturnCollateralStatus.NONE)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusNone}
                onChange={(val) => onChangeCheckStatus(ContractReturnCollateralStatus.NONE, val)}
              />
              {/* <Check
                label={(
                  <div className='align-items-center d-flex'>แบบร่าง<Badge className='h-50 mx-2'
                    bg='secondary'>
                    {countStatus.filter((x) => x.status === ContractReturnCollateralStatus.DRAFT).length > 0 ? countStatus.filter((x) => x.status === ContractReturnCollateralStatus.DRAFT)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusNone}
                onChange={(val) => onChangeCheckStatus(ContractReturnCollateralStatus.DRAFT, val)}
              /> */}
              <Check
                label={(
                  <div className='align-items-center d-flex'>อยู่ระหว่างพิจารณาคืนหลักประกันสัญญา<Badge className='h-50 mx-2'
                    bg='warning'>
                    {countStatus.filter((x) => x.status === ContractReturnCollateralStatus.WAITINGFORCONSIDER).length > 0 ? countStatus.filter((x) => x.status === ContractReturnCollateralStatus.WAITINGFORCONSIDER)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusWaitingForConsider}
                onChange={(val) => onChangeCheckStatus(ContractReturnCollateralStatus.WAITINGFORCONSIDER, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>อยู่ระหว่างขออนุมัติคืนหลักประกันสัญญา<Badge className='h-50 mx-2'
                    bg='warning'>
                    {countStatus.filter((x) => x.status === ContractReturnCollateralStatus.WAITINGFORAPPROVE).length > 0 ? countStatus.filter((x) => x.status === ContractReturnCollateralStatus.WAITINGFORAPPROVE)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusWaitingForApprove}
                onChange={(val) => onChangeCheckStatus(ContractReturnCollateralStatus.WAITINGFORAPPROVE, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>อยู่ระหว่างการบันทึกรับหลักประกัน<Badge className='h-50 mx-2'
                    bg='warning'>
                    {countStatus.filter((x) => x.status === ContractReturnCollateralStatus.WAITINGFORRECEIVECOLLATERAL).length > 0 ? countStatus.filter((x) => x.status === ContractReturnCollateralStatus.WAITINGFORRECEIVECOLLATERAL)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusWaitingForReceiveCollateral}
                onChange={(val) => onChangeCheckStatus(ContractReturnCollateralStatus.WAITINGFORRECEIVECOLLATERAL, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>คืนหลักประกันสัญญา<Badge className='h-50 mx-2'
                    bg='success'>
                    {countStatus.filter((x) => x.status === ContractReturnCollateralStatus.COMPLETED).length > 0 ? countStatus.filter((x) => x.status === ContractReturnCollateralStatus.COMPLETED)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusCompleted}
                onChange={(val) => onChangeCheckStatus(ContractReturnCollateralStatus.COMPLETED, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Row>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Check
              label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
              value={isResponsible}
              onChange={(val) => setIsResponsible(val)}
            />
          </Col>
        </Row>
        <div className='d-flex gap-2'>
          <Button form='formPL01'
            type='submit'
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={() => onSearch(size, page)}>
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={onClearSearch}
          >
            <FaEraser /> ล้าง
          </Button>
        </div>
      </Row>
      <Row className='mt-5'>
        <Col sm={12}>
          <Table total={totalRecords}
            onChange={(size, page) => {
              onPaginationChange(size, page);
            }}>
            <thead>
              <tr className='text-center'>
                <th style={{ width: '10%' }}>เลขที่รายการ <br />จัดซื้อจัดจ้าง</th>
                <th style={{ width: '10%' }}>เลขที่สัญญา</th>
                <th style={{ width: '20%' }}>ชื่อโครงการ</th>
                <th style={{ width: '10%' }}>เลขที่ PO</th>
                <th style={{ width: '20%' }}>เลขที่เอกสาร</th>
                <th style={{ width: 100 }}>สถานะสัญญา</th>
                <th style={{ width: 100 }}>สถานะคืนหลักประกัน</th>
                <th style={{ width: '20%' }}>ผู้ประกอบการ/คู่ค้า</th>
                {/* <th style={{ width: '10%' }}>มูลค่า <br />หลักประกันสัญญารวม (บาท)</th> */}
              </tr>
            </thead>
            <tbody>
              {dataList?.map((data, index) => (
                <tr className='text-center'
                  key={index}>
                  <td className='text-start'>{data.planNumber}</td>
                  <td className='text-start'><Button variant='link'
                    onClick={() => goToPage(data.contractAgreementId, data.id)}>{data.contractNumber}
                  </Button>
                  </td>
                  <td className='text-start'>{data.planName}</td>
                  <td className='text-start'>{data.poNumber}</td>
                  <td>{data.collateralNumber}</td>
                  <td className='text-start'>
                    <Status value={data.contractStatus}
                      type={StatusType.CollateralContractStatus} />
                  </td>
                  <td className='text-start'>
                    {/* <StatusCMReturnCollateral systemStatue={data.status}
                    systemName='cm-03' /> */}
                    <Status value={data.status}
                      type={StatusType.ContractReturnCollateral} />
                  </td>
                  <td>{data.entrepreneurName}</td>
                  {/* <td className='text-end'>{THCurrency(data.offerTotalAmount)}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
