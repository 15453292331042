import {
  Card,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  Status,
  StatusType,
  UploadFile,
  ButtonCustom,
} from 'components';
import { InYearPlanDocumentModel } from 'models/PL';
import {
  IFile,
  ItemModel,
} from 'models/systemUtility';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaAngleRight,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import {
  fullDatetimeTH,
  submitForm,
} from 'utils';
import {
  PlanStatusText,
  PlanTypeText,
} from 'utils/constants/PlanEnum';
import { Context } from './Detail';

interface Props {
  back: () => void;
  next: () => void;
}

type Loader = {
  departmentList: ItemModel[],
  supplyMethodTypeList: ItemModel[],
  supplyMethodTypeNameList: ItemModel[],
};

export default function Step1({ next, back }: Props) {
  const { data, setData, files, setFiles } = useContext(Context);
  const [onDisable, setOnDisable] = useState<boolean>(false);
  const navigate = useNavigate();
  const { departmentList, supplyMethodTypeList, supplyMethodTypeNameList } = useLoaderData() as Loader;

  useEffect(() => {
    if (data.documentInit) { handleSetFile(data.documentInit); }
    setData({ ...data, docNameforDelete: [] });
    if (data.planStatus) {
      if (data.planStatus === PlanTypeText.InYearPlan) {
        if (data.status !== PlanStatusText.DraftPlan) {
          setOnDisable(true);
        } else {
          setOnDisable(false);
        }
      } else {
        setOnDisable(false);
      }
    }
  }, []);

  const onNext = () => {
    submitForm();
    if (data.departmentId
      && data.supplyMethodName
      && data.supplyMethod
      && data.planName
      && data.planBudget
      && data.expectingProcurementAt) {
      next();
    }
  };

  const handleSetFile = (documents: InYearPlanDocumentModel[]) => {
    documents.forEach((value, index) => {
      const document = documents[index];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    });
  };

  const handleFileChange = (files: File) => {
    const newFile: IFile = {
      id: '',
      name: files.name,
      file: files,
    };

    setFiles((pre) => [...pre, newFile]);
  };

  return (
    <Card>
      <div className="d-flex gap-2">
        <p>ข้อมูลแผน</p>
        <h5>
          <Status badge type={StatusType.PROJECT} value={data.planStatus} />
        </h5>
        <h5>
          <Status badge type={StatusType.PROCESS} value={data.status} />
        </h5>
      </div>
      <Row>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Input
            label="เลขที่แผน"
            value={data.planNumber}
            disabled
          />
        </Col>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Selector
            label="ฝ่าย/สำนัก"
            rule={{ required: true }}
            value={data.departmentId}
            onChange={(val) => setData({ ...data, departmentId: val.toString() })}
            items={departmentList}
            disabled={onDisable}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Selector
            label="วิธีการจัดหา"
            rule={{ required: true }}
            value={data.supplyMethod}
            onChange={(val) => setData({ ...data, supplyMethod: val.toString() })}
            items={supplyMethodTypeList}
            disabled={onDisable}
          />
        </Col>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Selector
            className="no-label"
            value={data.supplyMethodName}
            onChange={(val) => setData({ ...data, supplyMethodName: val.toString() })}
            items={supplyMethodTypeNameList}
            disabled={onDisable}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8} xl={6}>
          <InputTextArea
            label="ชื่อโครงการ"
            rule={{ required: true }}
            value={data.planName}
            onChange={(val) => setData({ ...data, planName: val.toString() })}
            disabled={onDisable}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Input
            label="งบประมาณโครงการ (บาท)"
            rule={{ required: true }}
            value={data.planBudget}
            onChange={(val) => setData({ ...data, planBudget: +val })}
            type="number"
            disabled={onDisable}
          />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <DatePicker
            label="ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง"
            value={data.expectingProcurementAt}
            onChange={(val) => setData({ ...data, expectingProcurementAt: val })}
            monthYearOnly
            rule={{ required: true }}
            disabled={onDisable}
          />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <DatePicker
            label="วันที่ประกาศแผน"
            value={data.planAnnouncementDate}
            onChange={(val) => setData({ ...data, planAnnouncementDate: val })}
            disabled={onDisable}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Input
            label="เลขที่แผน e-GP"
            value={data.planEgpNumber}
            onChange={(val) => setData({ ...data, planEgpNumber: val })}
          />
        </Col>
      </Row>

      <p className="mb-0">เอกสารแนบ</p>
      <Card>
        <div className="d-flex justify-content-center">
          <Row>
            <Col sm={12}>
              <UploadFile
                // value={files}
                onChange={handleFileChange}
                // onRemove={removeItemAsync}
                disabled={onDisable}
              />
            </Col>
          </Row>
        </div>
      </Card>

      {
        onDisable
          ? (
            <>
              <Row className="criteria">
                <Col sm={12} xl={3}>
                  <Input
                    label="สร้างโดย"
                    value={data.createdByFullName}
                    disabled={onDisable}
                  />
                </Col>
                <Col sm={12} xl={3}>
                  <Input
                    label="วันที่สร้าง"
                    value={fullDatetimeTH(data.createdDate)}
                    disabled={onDisable}
                  />
                </Col>
              </Row>
              <Row className="criteria">
                <Col sm={12} xl={3}>
                  <Input
                    label="ผู้ส่งอนุมัติ"
                    value={data.updatedByUserFullName}
                    disabled={onDisable}
                  />
                </Col>
                <Col sm={12} xl={3}>
                  <Input
                    label="วันที่ส่งอนุมัติ"
                    value={data.updatedDate !== null ? fullDatetimeTH(data.updatedDate?.toString()) : ''}
                    disabled={onDisable}
                  />
                </Col>
              </Row>
            </>
          )
          : (
            <>
            </>
          )
      }
      <div className="d-flex justify-content-between mt-4">
        <ButtonCustom
          onClick={() => navigate('/pl/pl0202')}
          text="ย้อนกลับ"
        />
        <ButtonCustom
          onClick={onNext}
          text="ถัดไป"
        />
      </div>
    </Card>
  );
}
