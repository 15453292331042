import {
  Breadcrumb,
  Card,
  DatePicker,
  HistoryModal,
  Input,
  InputTextArea,
  Radio,
  SearchNameModal,
  Selector,
  Table,
  TorStatus,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import { StatusDeliver } from 'components/StatusDeliver';
import StepProgress from 'components/StepProgress';
import {
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaHistory,
  FaSave,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdCircle,
  MdOutlineArrowBack,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { generateUniqueId } from '../../../../utils/helper';
import { dataHistory } from './data';

const INFO = [
  {
    label: 'ละเว้น',
    value: '1',
  },
  {
    label: 'ไม่ละเว้น',
    value: '2',
  },
];

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

const BREADCRUMB_INFO = [
  {
    routerName: '/cm/cm0209',
    label: 'ร่างข้อมูลใบสั่ง',
  },
  {
    routerName: '',
    label: 'รายละเอียดร่างข้อมูลใบสั่ง',
  },
];

function AnnouncingWinnersDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const [showHistory, setShowHistory] = useState(false);
  const [showSearchNameModal, setModalSearchNameModal] = useState(false);

  const obj = [
    {
      title: <p>บริหารสัญญา</p>,
      displayIndex: '1',
    },
    {
      title: <p>รับประกันสัญญา</p>,
      displayIndex: '2',
    },
    {
      title: <p>คืนหลักประกันสัญญา</p>,
      displayIndex: '3',
    },
    {
      title: <p>สิ้นสุดสัญญา</p>,
      displayIndex: '4',
    },
  ];

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/cm/cm0209');
  };

  const goToPage = () => {
    navigate('/cm/cm0201/Detail');
  };

  return (
    <div className='document'>
      <h4 className='mt-2 mb-0 text-primary text-start'>
        <Button variant='link'
          onClick={backToIndex}>
          <MdOutlineArrowBack className='fs-4 text-primary mb-1' />
        </Button>บริหารสัญญา</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12}
          className='mb-2'>
          <StepProgress progressBarData={obj}
            ref={childRef} />
        </Col>
      </Row>
      <div className='document'>
        <Card className='mt-3'>
          <Title text='ข้อมูลโครงการ'
            className='fs-5 text-primary' />
          <div className='d-flex align-items-center'>
            <p className='mb-0 me-4'>เลขที่โครงการ </p>
            <Button onClick={goToPage}
              variant='link'>T0001</Button>
          </div>
          <div className='d-flex align-items-center'>
            <p className='mb-0 me-4'>ชื่อโครงการ </p>
            <p className='mb-0 ms-4'>จ้างที่ปรึกษา </p>
          </div>
        </Card>

        <Card className='mt-3'>
          <div className='d-flex mb-4 align-items-center justify-content-between'>
            <Title text='ข้อมูลใบสั่ง/สัญญา'
              className='fs-5 text-primary' />
            <div className='d-flex gap-2'>
              <TorStatus
                value='Draft' />
              <Button
                onClick={() => setShowHistory(!showHistory)}
                variant='outline-primary'>
                <FaHistory className='me-1' />
                ประวัติการใช้งาน
              </Button>
            </div>
          </div>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              xl={6}>
              <Input label='ชื่อสัญญา'
                value='สัญญาจ้างบริการบำรุงรักษาและซ่อมแซมแก้ไขอุปกรณ์สื่อสารระบบ ITMX'
                disabled />
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input label='เลขที่สัญญา PO'
                textCenter
                rule={{ required: true }}
                value='410007'
                disabled />
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input label='เลขที่สัญญา จพ.(สบส.)'
                textCenter
                rule={{ required: true }}
                value='001/2566'
                disabled />
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input label='วงเงินตามสัญญา'
                textEnd
                rule={{ required: true }}
                value='1,000,000.00'
                disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              md={2}>
              <Input label='กำหนดส่งมอบภายใน'
                placeholder='กำหนดส่งมอบภายใน'
                textCenter
                rule={{ required: true }}
                disabled />
            </Col>
            <Col sm={12}
              md={1}>
              <Selector className='no-label'
                placeholder='วัน'
                disabled />
            </Col>
            <Col sm={12}
              md={3}>
              <Selector className='no-label'
                placeholder='นับถัดจากวันที่ลงนามใมสัญญา'
                disabled />
            </Col>
            <Col sm={12}
              md={3}>
              <DatePicker label='ครบกำหนดส่งมอบวันที่'
                disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              md={2}>
              <Input label='ระยะเวลารับประกัน'
                placeholder='ระยะเวลารับประกัน '
                textCenter
                rule={{ required: true }}
                disabled />
            </Col>
            <Col sm={12}
              md={1}>
              <Selector className='no-label'
                placeholder='วัน'
                disabled />
            </Col>
            <Col sm={12}
              md={3}>
              <DatePicker label='ครบกำหนดส่งมอบวันที่'
                disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              md={3}>
              <Selector label='เงื่อนไขการรับประกัน'
                placeholder='ถัดจากวันสิ้นสุด'
                rule={{ required: true }}
                disabled />
            </Col>
          </Row>
        </Card>

        <Card className='mt-3'>
          <Title text='ข้อมูลคู่ค้า'
            className='fs-5 text-primary' />
          <Row className='mt-3'>
            <Col sm={12}
              xl={4}>
              <p className='mb-0 me-4 text-start'>สัญชาติของผู้ประกอบการ : <span className='ms-2'>ไทย</span></p>
            </Col>
            <Col sm={12}
              xl={4}>
              <p className='mb-0 me-4 text-start'>ประเภท : <span className='ms-2'>นิติบุคคล</span></p>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col sm={12}
              xl={4}>
              <p className='mb-0 me-4 text-start'>เลขประจำตัวผู้เสียภาษี : <span className='ms-2'>1234567890123</span>
              </p>
            </Col>
            <Col sm={12}
              xl={4}>
              <p className='mb-0 me-4 text-start'>ประเภทผู้ประกอบการ : <span className='ms-2'>นิติบุคคล</span></p>
            </Col>

            <Col sm={12}
              className='mt-2'>
              <p className='mb-0 me-4 text-start'>ชื่อสถานประกอบการ
                : <span className='ms-2'>บริษัท โค้ดฮาร์ด ดอท คอม</span></p>
            </Col>
            <Col sm={12}
              xl={4}
              className='mt-2'>
              <p className='mb-0 me-4 text-start'>หมายเลขโทรศัพท์สำหรับติดต่อ
                : <span className='ms-2'>08731333333</span></p>
            </Col>
            <Col sm={12}
              xl={4}>
              <p className='mb-0 me-4 text-start'>อีเมล : <span className='ms-2'>xxx@xxx.xxx</span></p>
            </Col>
          </Row>
        </Card>

        <Card className='mt-3'>
          <div className='d-flex mb-4 align-items-center justify-content-between'>
            <Title text='ข้อมูลใบสั่ง/สัญญา'
              className='fs-5 text-primary' />
          </div>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input label='เลขที่สัญญา PO'
                textCenter
                rule={{ required: true }}
                value='410007'
                disabled />
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input label='เลขที่สัญญา จพ.(สบส.)'
                textCenter
                rule={{ required: true }}
                value='001/2566'
                disabled />
            </Col>
            <Col sm={12}
              md={4}
              xl={3}>
              <DatePicker label='วันที่ลงนามในสัญญา'
                disabled
                value={new Date()} />
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input label='วงเงินตามสัญญา'
                textEnd
                rule={{ required: true }}
                value='1,000,000.00'
                disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              md={2}>
              <Input label='กำหนดส่งมอบภายใน'
                placeholder='กำหนดส่งมอบภายใน'
                textCenter
                rule={{ required: true }}
                disabled
                value={45} />
            </Col>
            <Col sm={12}
              md={1}>
              <Selector className='no-label'
                placeholder='วัน'
                disabled />
            </Col>
            <Col sm={12}
              md={3}>
              <Selector className='no-label'
                placeholder='นับถัดจากวันที่ลงนามใมสัญญา'
                disabled />
            </Col>
            <Col sm={12}
              md={3}>
              <DatePicker label='ครบกำหนดส่งมอบวันที่'
                disabled
                value={new Date()} />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              md={2}>
              <Input label='ระยะเวลารับประกัน'
                placeholder='ระยะเวลารับประกัน '
                textCenter
                rule={{ required: true }}
                disabled
                value={45} />
            </Col>
            <Col sm={12}
              md={1}>
              <Selector className='no-label'
                placeholder='วัน'
                disabled />
            </Col>
            <Col sm={12}
              md={3}>
              <DatePicker label='ครบกำหนดส่งมอบวันที่'
                disabled
                value={new Date()} />
            </Col>
          </Row>
        </Card>

        <Card className='mt-3'>
          <Title text='งวด'
            className='fs-5 text-primary' />
          <Table total={1}
            hidePagination
            className='mt-3'>
            <thead>
              <tr className='text-center'>
                <th style={{ width: 10 }}>งวดที่</th>
                <th style={{ width: 70 }}>จำนวน (%)</th>
                <th style={{ width: 150 }}
                  className='text-end'>จำนวนเงิน
                </th>
                <th style={{ minWidth: 50 }}>จำนวนเงิน<br />
                  ค่าปรับ
                </th>
                <th style={{ minWidth: 50 }}>จำนวน<br />
                  ค่าปรับ
                </th>
                <th style={{ width: 250 }}>สถานะงวดเงิน</th>
                <th style={{ width: 250 }}>รายละเอียดส่งมอบ</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 2 })
                .map((_, index) => (
                  <tr className='text-center'
                    key={generateUniqueId(index)}>
                    <td style={{ width: 10 }}>{index + 1}</td>
                    <td style={{ minWidth: 150 }}>50 %</td>
                    <td className='text-end'>500,000.00</td>
                    <td />
                    <td />
                    <td><StatusDeliver Istatus='warning' /></td>
                    <td><MdCircle className='text-dark me-2'
                      style={{ fontSize: '8px' }} />ค่าใช้จ่ายในการฝึกอบรมและ 1 งาน ตามเอกสารแนบ
                    </td>
                    <td>
                      <Button onClick={() => navigate('inspect')}>บันทึกส่งมอบ/ตรวจรับ</Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>

        <Card className='mt-3'>
          <Title text='บันทึกรับหลักประกัน'
            className='fs-5 text-primary' />
          <Row>
            <Col sm={12}>
              <Radio
                className='mt-3'
                inline
                dflex
                items={INFO}
                label='จำนวนเงินหลักประกัน'
                name='name1'
                rule={{ required: true }}
              />
            </Col>
            <Col sm={12}
              xl={3}>
              <Selector label='ประเภทหลักประกันสัญญา'
                placeholder='เงินโอน'
                rule={{ required: true }}
                disabled />
            </Col>
            <Col sm={12}
              xl={3}>
              <Input label='จำนวนเงินหลักประกัน'
                placeholder='จำนวนเงินหลักประกัน'
                rule={{ required: true }}
                disabled />
            </Col>
            <Col sm={12}
              xl={3}>
              <Input label='ร้อยละของราคาค่าจ้างตามสัญญา'
                placeholder='ร้อยละของราคาค่าจ้างตามสัญญา'
                rule={{ required: true }}
                disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              xl={3}>
              <Input label='เลขที่หลักประกัน'
                placeholder='เลขที่หลักประกัน'
                disabled />
            </Col>
            <Col sm={12}
              xl={3}>
              <DatePicker label='เลขที่หลักประกันลงวันที่'
                disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              xl={3}>
              <Selector label='ประเภทวันทำสัญญา'
                placeholder='ตามวันที่'
                disabled />
            </Col>
            <Col sm={12}
              xl={3}>
              <DatePicker label='วันที่'
                rule={{ required: true }}
                disabled />
            </Col>
            <Col sm={12}
              xl={3}>
              <DatePicker label='ถึงวันที่'
                disabled />
            </Col>
            <Col sm={12}
              xl={8}>
              <InputTextArea label='หมายเหตุ'
                disabled />
            </Col>
          </Row>
        </Card>

        <Card className='mt-3'>
          <Title text='คืนหลักประกันสัญญา'
            className='fs-5 text-primary' />
          <Row>
            <Col sm={12}
              lg={6}>
              <InputTextArea label='เหตุผลการพิจารณาอนุมัติให้คืนหลักประกัน' />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              lg={3}>
              <Input label='บันทึกหักหลักประกัน'
                value='0'
                textEnd />
            </Col>
            <Col sm={12}
              lg={3}>
              <Input label='จำนวนเงิน'
                disabled
                value='54,000.00'
                textEnd />
            </Col>
          </Row>
        </Card>

        <Card className='mt-3'>
          <Title text='เอกสารแนบ'
            className='fs-5 text-primary' />
          <Row className='justify-content-center'>
            <Col sm={12}
              lg={6}>
              <UploadFile />
            </Col>
          </Row>
        </Card>

        <div className='d-flex justify-content-between pt-3'>
          <Button
            className='me-2 px-3'
            variant='outline-primary'
            onClick={backToIndex}
          >
            <MdArrowBack className='me-2 pb-1 fs-5' />
            ย้อนกลับ
          </Button>
          <div>
            <Button
              variant='outline-primary'
              className='me-2 px-3'
            >
              <FaSave className='ms-2 pb-1 fs-5' />
              บันทึก
            </Button>
          </div>
          <div style={{ width: 100 }} />
        </div>
        <HistoryModal
          title='ประวัติการใช้งานประกาศผู้ชนะการเสนอราคา'
          show={showHistory}
          onHide={() => setShowHistory(!showHistory)}
          data={dataHistory}
        />
        <SearchNameModal
          show={showSearchNameModal}
          onHide={() => setModalSearchNameModal(!showSearchNameModal)}
          data={[]}
        />
      </div>
    </div>
  );
}

export default AnnouncingWinnersDetail;
