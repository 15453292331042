import {
  Input,
  Selector,
} from 'components';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import { IFile } from 'models/P/FileUploadModel';
import { AppointmentDocumentModel } from 'models/P/PP0201Model';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Row,
} from 'react-bootstrap';
import { MdArrowForward } from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';
import { useLoaderData } from 'react-router';
import { shareValue } from 'services';
import {
  fullDatetime,
  HttpStatusCode,
  thaiFormatDate,
} from 'utils';
import { Context } from './index';
import ListFile from './ListFile';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type Loader = {
  planDDL: ItemModel[]
  supplyMethodDDL: ItemModel[]
  supplyMethodTypeDDL: ItemModel[]
};

function Step1({ onClickNext, onClickBack }: Props) {
  const [files, setFiles] = useState<IFile[]>([]);
  const context = useContext(Context);
  const { planDDL, supplyMethodDDL, supplyMethodTypeDDL } = useLoaderData() as Loader;
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);

  useEffect(() => {
    if (context.appointmentApprovalData && context.appointmentApprovalData.documents) {
      handleSetFile(context.appointmentApprovalData.documents);
    }
    getSupplyMethodSpecialType(context.appointmentApprovalData.supplyMethod);
  }, [context]);

  const handleSetFile = (documents: AppointmentDocumentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const getSupplyMethodSpecialType = useCallback(async (supplyMethodId: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(supplyMethodId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setSupplyMethodSpecialDDL(data);
  }, [context.appointmentApprovalData.supplyMethod]);

  return (
    <div className="document">
      <Card className="mt-3">
        <Card.Body>
          <Title text="ข้อมูลเอกสารบันทึกข้อความแต่งตั้ง" className="fs-5 text-primary" />
          <Row className="mt-3 align-items-center">
            <Col sm={12} md={4} xl={3}>
              <Input
                label="วันที่เอกสารบันทึกข้อความแต่งตั้ง"
                value={thaiFormatDate(context.appointmentApprovalData.memorandumDate)}
                disabled
              />
            </Col>
            <Col sm={12} md={4}>
              <Selector
                label="โครงการ"
                items={planDDL}
                value={`${context.appointmentApprovalData.planId}`}
                disabled
              />
            </Col>
            <Col sm={12} md={3}>
              <Button disabled={!context.appointmentApprovalData.planId} variant="link" href={`/pl/pl01/detail-pl01/${context.appointmentApprovalData.planId}`}><TbListSearch
                className="fs-4"
              /> ไปยังแผน
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} xl={3}>
              <Selector
                label="วิธีการจัดหา"
                items={supplyMethodDDL}
                rule={{ required: true }}
                value={`${context.appointmentApprovalData.supplyMethod}`}
                disabled
              />
            </Col>
            <Col sm={12} md={6} xl={3}>
              <Selector
                className="no-label"
                items={supplyMethodTypeDDL}
                rule={{ required: true }}
                value={`${context.appointmentApprovalData.supplyMethodType}`}
                disabled
              />
            </Col>
            <Col sm={12} md={6} xl={3}>
              <Selector
                className="no-label"
                items={supplyMethodSpecialDDL}
                rule={{ required: true }}
                value={`${context.appointmentApprovalData.supplyMethodSpecialType}`}
                disabled
              />
            </Col>
          </Row>
          <Row className="mt-3 align-items-center">
            <Col sm={12} md={4} xl={3}>
              <Input label="งบประมาณโครงการ (บาท)" value={context.appointmentApprovalData.planBudget} disabled textEnd rule={{ required: true }} />
            </Col>
            <Col sm={12}>
              <Input label="ตามคำสั่ง" value={context.appointmentApprovalData.byOrderFrom} disabled rule={{ required: true }} />
            </Col>
            <Col sm={12}>
              <Input label="เรื่อง" value={context.appointmentApprovalData.subject} disabled rule={{ required: true }} />
            </Col>
          </Row>
          <p className="mb-0">เอกสารแนบ</p>
          <Card>
            <Card.Body>
              <Row className="justify-content-center">
                <Col sm={12} lg={6}>
                  <ListFile
                    files={files}
                    appointmentId={context.appointmentApprovalData.id}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row className="mt-3">
            <Col sm={12} md={4} xl={3}>
              <Input label="สร้างโดย" value={context.appointmentApprovalData.createdByUserFullName} disabled />
            </Col>
            <Col sm={12} md={4} xl={3}>
              <Input label="วันที่สร้าง" value={fullDatetime(context.appointmentApprovalData.createdInfoAt)} disabled />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-end pt-3">
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default Step1;
