import {
  ButtonCustom,
  Check,
  ContractReceiveCollateral,
  Readonly,
  StatusCM,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import {
  ReturnCollateralStatus,
  StatusCMConsider,
  StatusCMReceiveCollateral,
  StatusCMRequestApproval,
  StatusCMReturnCollateral,
} from 'components/StatusCMReturnCollateral';
import { IFile } from 'models';
import {
  AttachmentFileModel,
  ContractReturnCollateralResultModel,
} from 'models/CM/CM06Model';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import {
  useNavigate,
  useParams,
} from 'react-router';
import CM06Service from 'services/CM/CM06Service';
import {
  HttpStatusCode,
  thaiFormatDateWithSlash,
  THCurrency,
} from 'utils';
import toast from 'utils/toast';
import { generateUniqueId } from '../../../../utils/helper';
import { HistoryModal } from '../Modal/HistoryModal';
import UploadFile from './UploadFile';
import { SendMailToEntrepreneurAsyncModal } from 'components/Modal/SendMailToVendorModal';

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default function CM06Detail() {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showModelCheck, setShowModelCheck] = useState(false);
  const [showModelCheck1, setShowModelCheck1] = useState(false);
  const [data, setData] = useState<ContractReturnCollateralResultModel>({} as ContractReturnCollateralResultModel);
  const [contractId, setContractId] = useState<string>('');
  const { id, contractAgreementId } = useParams();
  const navigate = useNavigate();
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showSendEmail, setshowSendEmail] = useState<boolean[]>([]);

  const onOpenSendEmailModal = (index: number) => {
    setshowSendEmail((showSendEmail) => {
      const newModal = [...showSendEmail];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideSendEmailModal = (index: number) => {
    setshowSendEmail((showSendEmail) => {
      const newModal = [...showSendEmail];
      newModal[index] = false;
      return newModal;
    });
  };

  const goToBack = () => {
    navigate('/cm/cm06');
  };
  const goTo = (id: string) => {
    navigate(`/cm/cm06/detail/considers/${id}`);
  };
  const goTo2 = (id: string) => {
    navigate(`/cm/cm06/detail/requests/${id}`);
  };
  const goTo3 = (id: string) => {
    navigate(`/cm/cm06/detail/receives/${id}`);
  };

  const getFilesAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.getAttachmentReturnCollateralAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    handleSetFile(data.attachments);

  }, []);

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await CM06Service.uploadAttachmentReturnCollateralAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getFilesAsync(data.id);
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {

    if (data && data.id) {
      const res = await CM06Service.removeAttachmentFileReturnCollateralAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getFilesAsync(data.id);

      }
    }

  }, [files, data.id]);

  const getCollateralByIdAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.getCollateralByIdAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }
    setData(data);
  }, []);


  useEffect(() => {
    const contractId = id || contractAgreementId;

    if (contractId) {
      getCollateralByIdAsync(contractId);
      // setContractId(contractId);
    }

  }, [id, contractAgreementId]);

  useEffect(() => {
    if (data) {

      if (data.id) {

        getFilesAsync(data.id);
        setIsDisabled(false);

      } else {
        setIsDisabled(true);
      }

    }

  }, [data.id]);

  const onSubmitSendEmail = async (id: string, email: string) => {

    if ((!email)) {
      toast.warn('กรูณาระบุ E-mail');
      return;
    }

    const res = await CM06Service.sendEmailToEntrepreneurAsync(id, email);

    if (res.status === HttpStatusCode.OK) {

      toast.success('ส่งอีเมลสำเร็จ');
    } else {
      toast.error(res.statusText);
    }
  };

  function TabsComponent() {
    return (
      <>
        <Tabs
          defaultActiveKey='1'
          id='justify-tab-example'
          className='mb-2'
          justify
        >
          <Tab eventKey='1'
            title={<p>พิจารณาคืนหลักประกันสัญญา </p>}>
            <Row className='mt-3'>
              <h5 className='fw-bold'>พิจารณาคืนหลักประกันสัญญา</h5>
              <Col sm={12}>
                <Table hidePagination>
                  <thead>
                    <tr className='text-center'>
                      <th style={{ width: '10%' }}>เลขที่เอกสาร</th>
                      <th style={{ width: '10%' }}>วันที่บันทึกผลการพิจารณา</th>
                      <th style={{ width: 100 }}>สถานะ</th>
                      <th style={{ width: '20%' }}>ประเภทหลักประกันสัญญา</th>
                      <th style={{ width: '20%' }}>จำนวนเงิน (บาท)</th>
                      <th style={{ width: '20%' }}>หักค่าปรับจากเงินหลักประกันสัญญา (บาท)</th>
                      <th style={{ width: '20%' }}>คืนหลักประกันสัญญาคงเหลือ (บาท)</th>
                      <th style={{ width: 100 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {data.considers?.map((item, index) => (
                      <tr key={item.id}
                        className='text-center'>
                        <td>{item.collateralNumber}</td>
                        <td>{thaiFormatDateWithSlash(item.date)}</td>
                        <td>
                          <div className='d-flex'>
                            <StatusCMConsider systemStatue={item.status}
                              systemName='cm-03' />
                          </div>
                        </td>
                        <td className='text-start'>
                          {/* <p className='mb-0'>1. หนังสือค้ำประกันของธนาคารภายในประเทศ</p>
                          <p className='mb-0'>2. เงินโอน</p> */}
                          <ul className='inside'>
                            <li>{item.collateralTypes}</li>
                          </ul>
                        </td>
                        <td>{formatter.format(item.amount)}</td>
                        <td>{formatter.format(item.fineAmount)}</td>
                        <td>{formatter.format(item.amount - item.fineAmount)} </td>
                        <td><ButtonCustom
                          variant='outline-primary'
                          onClick={() => goTo(item.id ? item.id : item.contractManagementFinancialAmountId)}
                          iconAlignRight
                          icon='arrowForward'
                          text='ไปยังรายการบันทึกคืนหลักประกันสัญญา'
                        /></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey='2'
            title={<p>ขออนุมัติคืนหลักประกันสัญญา </p>}>
            <Row className='mt-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <h5 className='fw-bold mb-0'>ขออนุมัติคืนหลักประกันสัญญา</h5>
              </div>
              <Col sm={12}>
                <Table hidePagination>
                  <thead>
                    <tr className='text-center'>
                      <th style={{ width: '10%' }}>เลขที่เอกสาร</th>
                      <th style={{ width: '10%' }}>วันที่บันทึกผลการพิจารณา</th>
                      <th style={{ width: 100 }}>สถานะ</th>
                      <th style={{ width: '20%' }}>ประเภทหลักประกันสัญญา</th>
                      <th style={{ width: '20%' }}>จำนวนเงิน (บาท)</th>
                      <th style={{ width: '20%' }}>หักค่าปรับจากเงินหลักประกันสัญญา (บาท)</th>
                      <th style={{ width: '20%' }}>คืนหลักประกันสัญญาคงเหลือ (บาท)</th>
                      <th style={{ width: 100 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {data.requests?.map((item, index) => (
                      <>

                        <tr key={item.id}
                          className='text-center'>
                          <td>{item.collateralNumber}</td>
                          <td>{thaiFormatDateWithSlash(item.date)}</td>
                          <td>
                            <div className='d-flex'>
                              <StatusCMRequestApproval systemStatue={item.status}
                                systemName='cm-03' />
                            </div>
                          </td>
                          <td className='text-start'>
                            <ul className='inside'>
                              <li>{item.collateralTypes}</li>
                            </ul>
                          </td>
                          <td>{formatter.format(item.amount)}</td>
                          <td>{formatter.format(item.fineAmount)}</td>
                          <td>{formatter.format(item.amount - item.fineAmount)} </td>
                          <td>
                            <div className='d-flex ps-2'>
                              <ButtonCustom
                                icon='arrowForward'
                                variant='outline-primary'
                                onClick={() => goTo2(item.contractReturnCollateralConsiderId)}
                                iconAlignRight
                                text='ไปยังรายการขออนุมัติคืนหลักประกันสัญญา'
                                disabled={isDisabled}
                              />
                              <ButtonCustom
                                icon='send-mail'
                                variant='outline-primary'
                                text='ส่งเมลให้ทางบริษัทมารับหลักประกันฯ'
                                disabled={isDisabled}
                                onClick={() => onOpenSendEmailModal(index)}
                              />
                            </div>
                          </td>
                        </tr>
                        <SendMailToEntrepreneurAsyncModal
                          title='ส่งเมลแจ้งคืนหลักประกันสัญญา'
                          show={showSendEmail[index]}
                          onHide={() => onHideSendEmailModal(index)}
                          entrepreneurEmail={data.contractInfo?.entrepreneur?.email}
                          entrepreneurName={data.contractInfo?.entrepreneur?.name}
                          onData={(email) => onSubmitSendEmail(item.contractReturnCollateralConsiderId, email)}
                        />
                      </>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey='3'
            title={<p>บันทึกผู้ค้ารับหลักประกันสัญญา</p>}>
            <Row className='mt-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <h5 className='fw-bold mb-0'>บันทึกผู้ค้ารับหลักประกันสัญญา</h5>
              </div>
              <Col sm={12}>
                <Table hidePagination>
                  <thead>
                    <tr className='text-center'>
                      <th style={{ width: '10%' }}>เลขที่เอกสาร</th>
                      <th style={{ width: '10%' }}>วันที่บันทึกผลการพิจารณา</th>
                      <th style={{ width: 100 }}>สถานะ</th>
                      <th style={{ width: '20%' }}>ประเภทหลักประกันสัญญา</th>
                      <th style={{ width: '20%' }}>จำนวนเงิน (บาท)</th>
                      <th style={{ width: '20%' }}>หักค่าปรับจากเงินหลักประกันสัญญา (บาท)</th>
                      <th style={{ width: '20%' }}>คืนหลักประกันสัญญาคงเหลือ (บาท)</th>
                      <th style={{ width: 100 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {data.receives?.map((item, index) => (
                      <tr key={item.id}
                        className='text-center'>
                        <td>{item.collateralNumber}</td>
                        <td>{thaiFormatDateWithSlash(item.date)}</td>
                        <td>
                          <div className='d-flex'>
                            <StatusCMReceiveCollateral systemStatue={item.status}
                              systemName='cm-03' />
                          </div>
                        </td>
                        <td className='text-start'>
                          {/* <p className='mb-0'>1. หนังสือค้ำประกันของธนาคารภายในประเทศ</p>
                          <p className='mb-0'>2. เงินโอน</p> */}
                          <ul className='inside'>
                            <li>{item.collateralTypes}</li>
                          </ul>
                        </td>
                        <td>{formatter.format(item.amount)}</td>
                        <td>{formatter.format(item.fineAmount)}</td>
                        <td>{formatter.format(item.amount - item.fineAmount)} </td>
                        <td><ButtonCustom
                          icon='arrowForward'
                          variant='outline-primary'
                          onClick={() => goTo3(item.contractReturnCollateralConsiderId)}
                          iconAlignRight
                          text='ไปยังรายการบันทึกคืนหลักประกันสัญญา'
                          disabled={isDisabled}
                        /></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </>
    );
  }

  return (
    <>
      <Title text='คืนหลักประกันสัญญา'
        extraElement={
          <div className='d-flex gap-3'>
            {data.status ?
              <ReturnCollateralStatus systemStatue={data.status}
                systemName='' /> :
              <ReturnCollateralStatus systemStatue='Draft'
                systemName='' />}
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </div>
        } />
      <Row className='my-3 '>
        <Col xs={12}>
          <Accordion defaultActiveKey='0'
            className='info'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <div className='header'>
                  <p>ข้อมูลโครงการ</p>
                </div>
                <div>
                  <Row>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='เลขที่รายการจัดซื้อจัดจ้าง'
                        textReadonly={data.planInfo?.planNumber} />
                    </Col>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='โครงการ'
                        textReadonly={data.planInfo?.planName} />
                    </Col>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='คู่ค้า'
                        textReadonly={data.contractInfo?.entrepreneur?.name ?? '-'} />
                    </Col>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='เลขที่สัญญา PO *'
                        textReadonly={data.contractInfo?.poNumber}
                        className='show-header-info' />
                      <Readonly label='วิธีจัดหา'
                        textReadonly={data.planInfo?.supplyMethodName}
                        className='show-hide-info' />
                    </Col>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='เลขที่สัญญา จพ.(สบส.) *'
                        textReadonly={data.contractInfo?.contractNumber}
                        className='show-header-info' />
                      <Readonly label='วิธี'
                        textReadonly={data.planInfo?.supplyMethodTypeName}
                        className='show-hide-info' />
                    </Col>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='ลักษะณะการจ้าง'
                        textReadonly={data.planInfo?.supplyMethodSpecialTypeName}
                        className='show-hide-info' />
                    </Col>
                  </Row>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className='fs-5 fw-bold border-top pt-2'>ข้อมูลสัญญา</p>
                <Row>
                  <Col sm={12}
                    md={6}>
                    <Readonly label='คู่ค้า'
                      textReadonly={data.contractInfo?.entrepreneur?.name ?? '-'} />
                  </Col>
                  <Col sm={12}
                    md={6}>
                    <Readonly label='Email (คู่ค้า) *'
                      textReadonly={data.contractInfo?.entrepreneur?.email ?? '-'} />
                  </Col>
                  <Col sm={12}>
                    <Readonly label='ชื่อสัญญา'
                      textReadonly={data.contractInfo?.contractName} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}
                    md={6}
                    lg={4}>
                    <Readonly label='เลขที่สัญญา PO *'
                      textReadonly={data.contractInfo?.poNumber} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={4}>
                    <Readonly label='เลขที่สัญญา จพ.(สบส.) *'
                      textReadonly={data.contractInfo?.contractNumber} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='วงเงินตามสัญญา *'
                      textReadonly={THCurrency(data.contractInfo?.offerTotalAmount)} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='วันที่ลงนามในสัญญา'
                      textReadonly={thaiFormatDateWithSlash(data.contractInfo?.contractDate) ?? '-'} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='ประเภทสัญญา *'
                      textReadonly={data.contractInfo?.contractType} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='รูปแบบสัญญา *'
                      textReadonly={data.contractInfo?.contractTemplate} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='กำหนดส่งมอบภายใน *'
                      textReadonly={<>{data.contractInfo?.deliveryPeriod?.count}
                        <span className='mx-2'>{data.contractInfo?.deliveryPeriod?.unit}</span> {data.contractInfo?.deliveryPeriod?.type}</>} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='ครบกำหนดส่งมอบวันที่'
                      textReadonly={thaiFormatDateWithSlash(data.contractInfo?.deliveryPeriod?.dueDate) ?? '-'} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Check label='นับเฉพาะวันทำการ'
                      className='mt-3'
                      value={data.contractInfo?.deliveryPeriod?.workDayOnly}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='ระยะเวลารับประกัน *'
                      textReadonly={<>{data.contractInfo?.warranty?.count}
                        <span className='mx-2'>{data.contractInfo?.warranty?.unit}</span>{data.contractInfo?.warranty?.type}</>} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='ครบกำหนดรับประกันวันที่'
                      textReadonly={thaiFormatDateWithSlash(data.contractInfo?.warranty?.dueDate) ?? '-'} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Check label='นับเฉพาะวันทำการ'
                      className='mt-3'
                      value={data.contractInfo?.deliveryPeriod?.workDayOnly}
                      disabled />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <Row>
        <Col className='tab-border-active'>
          {TabsComponent()}
        </Col>
      </Row>
      <Row>
        <Card className='mt-3 py-3'>
          <Title text='เอกสารแนบ'
            className='fs-5' />
          <Row className='justify-content-center'>
            <Col sm={12}
              lg={6}>
              <UploadFile
                disabled={!data.id}
                files={files}
                handleFileChange={handleFileChange}
                removeItem={removeItemAsync}
                id={data.id!}
              />
            </Col>
          </Row>
        </Card>
      </Row>
      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          {/* <ButtonCustom text="ย้อนกลับ" onClick={() => goToBack()} icon='arrowback' variant='outline-primary' /> */}
          <Button
            className='me-2 px-3'
            onClick={() => goToBack()}
            variant='light'
          >
            <MdArrowBack className='me-2 pb-1 fs-5' />
            กลับหน้าแรก
          </Button>
        </div>
        {/* <div>
          <ButtonCustom text="บันทึก" icon='save' variant='outline-primary' />
        </div>
        <div>
          <ButtonCustom text="ถัดไป" icon='arrowforward' variant='outline-primary' iconAlignRight />
        </div> */}
      </div>

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={data.id}
        title='คืนหลักประกันสัญญา'
        status={ContractReceiveCollateral.Collateral}
      />

      <Modal
        show={showModelCheck}
        onHide={() => setShowModelCheck(!showModelCheck)}
        size='xl'
      >
        <Modal.Header>
          <h5 className='fs-5 fw-bold'>รายการตรวจรับ</h5>
        </Modal.Header>
        <Table hidePagination>
          <thead>
            <tr className='text-center'>
              <th style={{ width: '10%' }}>งวด</th>
              <th style={{ width: '10%' }}>มูลค่า (%)</th>
              <th style={{ width: '10%' }}>จำนวนเงินค่าปรับ</th>
              <th style={{ width: '10%' }}>จำนวนคงเหลือ</th>
              <th style={{ width: '40%' }}>รายละเอียดส่งมอบ</th>
              <th style={{ width: 100 }}>สถานะ</th>
              <th style={{ width: 50 }}>รายการตรวจรับ</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 5 })
              .map((_, index) => (
                <tr className='text-center'
                  key={generateUniqueId(index)}>
                  <td>{index + 1}</td>
                  <td>2,000,000 (50%)</td>
                  <td></td>
                  <td></td>
                  <td className='text-start'>
                    <ul className='inside'>
                      <li> เบิกจ่ายถูกต้องครบถ้วน</li>
                    </ul>
                  </td>
                  <td>
                    <div className='d-flex'>
                      {index === 1 ? <StatusCM systemStatue='Draft'
                        systemName='cm-03' /> :
                        <StatusCM systemStatue='Warning'
                          systemName='cm-03' />}
                    </div>
                  </td>
                  <td>
                    <Check className='d-inline-block' />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className='d-flex justify-content-end gap-4'>

          <ButtonCustom variant='outline-primary'
            onClick={() => {
              setShowModelCheck(!showModelCheck);
            }}
            text='ยกเลิก' />

          <ButtonCustom
            onClick={() => {
              setShowModelCheck(!showModelCheck);
            }}
            text='บันทึกรายการตรวจรับ' />
        </div>
      </Modal>

      <Modal
        show={showModelCheck1}
        onHide={() => setShowModelCheck1(!showModelCheck1)}
        size='xl'
      >
        <Modal.Header>
          <h5 className='fs-5 fw-bold'>รายการตรวจรับ</h5>
        </Modal.Header>
        <Table hidePagination>
          <thead>
            <tr className='text-center'>
              <th style={{ width: '10%' }}>ลำดับ</th>
              <th style={{ width: '10%' }}>วันที่ตรวจรับ</th>
              <th style={{ width: '10%' }}>เลขที่ตรวจรับ</th>
              <th style={{ width: '40%' }}>รายละเอียดส่งมอบ</th>
              <th style={{ width: 100 }}>การตรวจรับ</th>
              <th style={{ width: 100 }}>สถานะตั้งหนี้ตั้งเบิก</th>
              <th style={{ width: '10%' }}>หมายเหตุการตรวจรับ</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 5 })
              .map((_, index) => (
                <tr className='text-center'
                  key={generateUniqueId(index)}>
                  <td>{index + 1}</td>
                  <td>10/05/2566</td>
                  <td>66000001</td>
                  <td className='text-start'>
                    <ul className='inside'>
                      <li> เบิกจ่ายถูกต้องครบถ้วน</li>
                    </ul>
                  </td>
                  <td>
                    <div className='d-flex'>
                      {index === 1 ? <StatusCM systemStatue='Draft'
                        systemName='cm-03' /> :
                        <StatusCM systemStatue='Warning'
                          systemName='cm-03' />}
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      {index === 1 ? <StatusCM systemStatue='Draft'
                        systemName='cm-03' /> :
                        <StatusCM systemStatue='Warning'
                          systemName='cm-03' />}
                    </div>
                  </td>
                  <td>ตรวจรับงานครบถ้วน</td>
                  <td>
                    <Check className='d-inline-block' />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className='d-flex justify-content-end gap-4'>

          <ButtonCustom variant='outline-primary'
            onClick={() => {
              setShowModelCheck1(!showModelCheck1);
            }}
            text='ยกเลิก' />

          <ButtonCustom
            onClick={() => {
              setShowModelCheck1(!showModelCheck1);
            }}
            text='บันทึกรายการตรวจรับ' />
        </div>
      </Modal>

    </>
  );
}
