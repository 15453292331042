import {
  Card,
  Input,
  Modal,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  Assign,
  DepartmentResponse,
  ItemModel,
  UserListSelectionParams,
  UserListSelectionResponse,
} from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaEraser,
  FaPlus,
  FaSave,
  FaSearch,
  FaTimes,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useNavigate,
  useParams,
} from 'react-router';
import {
  department,
  shareValue,
} from 'services';
import assign from 'services/assign';
import {
  HttpStatusCode,
  thaiFormatDateWithSlash,
} from 'utils';
import toast from 'utils/toast';
import { generateUniqueId } from '../../../../utils/helper';
import { DetailContext } from './index';

interface Props {
  onBack: () => void;
}

interface ModalProps {
  show: boolean;
  onHide: () => void;
  userDataListSelect: UserListSelectionResponse[];
  sendIdUser: (value: UserListSelectionResponse) => void;
}

export default function Step2(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [userListData, setUserListData] = useState<UserListSelectionResponse[]>([]);
  const [hasData, setHasData] = useState(false);
  const detail = useContext(DetailContext);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (detail.data.assign.length > 0) {
      setHasData(true);
      setUserListFormDetail(detail.data.assign);
    }
  }, [detail]);

  const setUserListFormDetail = (data: Assign[]) => {
    const userListSelect: UserListSelectionResponse[] = data.map((data) => ({ id: data.id, departmentName: data.department, fullName: data.name, positionName: data.position, approveStatus: data.approveStatus, updatedAt: data.updatedAt }));

    setUserListData(userListSelect);
  };

  const onShowModalAssignWork = () => {
    setShowModal(true);
  };

  const addUserDataList = (userData: UserListSelectionResponse) => {
    if (userData) {
      userListData.push(userData);
    }

    setTotalRecords(userListData.length);
  };

  const removeUserDataInList = (index: number) => {
    const rowArray: UserListSelectionResponse[] = [...userListData];
    rowArray.splice(index, 1);

    setUserListData(rowArray);
  };

  const onSubmit = () => {
    const assignId = userListData.map((data) => data.id);

    if (assignId.length > 0 && id) {
      assignAsync(id, assignId);

      return;
    }

    toast.warn('ต้องมอบหมายงานอย่างน้อย 1 คน');
  };

  const assignAsync = async (planId: string, assignId: string[]) => {
    const response = await assign.updateAssignAsync(planId, assignId);

    if (response.status === HttpStatusCode.OK) {
      toast.success('มอบหมายงานสำเร็จ');

      navigate(-1);
    }
  };

  return (
    <>
      <Card className="mt-3">
        <div className="d-flex align-items-center gap-2">
          <div className="parallelogram" />
          <h4 className="text-primary m-0">มอยหมายงาน</h4>
        </div>
        <Table className="mt-3" total={totalRecords} onChange={(size, page) => (setPage(page), setSize(size))}>
          <thead>
            <tr>
              <th style={{ minWidth: 100 }}>ลำดับ</th>
              <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
              {hasData && <th style={{ minWidth: 100 }}>สถานะ</th>}
              {hasData && <th style={{ minWidth: 100 }}>วันที่อนุมัติ</th>}
              {!hasData && <th />}
            </tr>
          </thead>
          <tbody>
            {userListData.map((data, index) => (
              <tr key={data.id}>
                <td className="text-center">{index + 1}</td>
                <td className="text-start">{data.fullName}</td>
                <td className="text-start">{data.departmentName}</td>
                <td className="text-center">{data.positionName}</td>
                {hasData && <td className="text-center"><Status type={StatusType.PROCESS} value={data.approveStatus ?? ''} /></td>}
                {hasData && <td className="text-center">{thaiFormatDateWithSlash(data.updatedAt)}</td>}
                {!hasData
                  && (
                  <td>
                    <Button
                      className="d-flex align-items-center gap-2"
                      variant="danger"
                      onClick={() => removeUserDataInList(index)}
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                  )}
              </tr>
            ))}
          </tbody>
        </Table>
        {!hasData
          ? (
            <div className="mt-3">
              <Button
                variant="outline-primary"
                className="d-flex align-items-center gap-2"
                onClick={onShowModalAssignWork}
              >
                <FaPlus />เพิ่มข้อมูล
              </Button>
            </div>
          )
          : <></>}
        <div className="d-flex justify-content-center gap-3 mt-3">
          <Button
            variant="outline-primary"
            className="d-flex align-items-center gap-2"
            onClick={props.onBack}
          >
            <FaAngleLeft />ย้อนกลับ
          </Button>
          {!hasData && (
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2"
            onClick={onSubmit}
          >
            <FaSave />มอบหมายงาน
          </Button>
          )}
        </div>
      </Card>
      <AssignModal show={showModal} onHide={() => setShowModal(false)} userDataListSelect={userListData} sendIdUser={(value) => addUserDataList(value)} />
    </>
  );
}

function AssignModal(props: ModalProps) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [userList, setUserList] = useState<UserListSelectionResponse[]>([]);
  const [criteriaData, setCriteriaData] = useState<UserListSelectionParams>({} as UserListSelectionParams);
  const [departmentItems, setDepartmentItems] = useState<ItemModel[]>([]);
  const [postionItems, setPositionItems] = useState<ItemModel[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const getUserListAsync = useCallback(async () => {
    const response = await shareValue.getUserListSelectionAsync(page, size, criteriaData);

    if (response.status === HttpStatusCode.OK) {
      if (props.userDataListSelect.length > 0) {
        const data = checkUserSelect(response.data.data);

        setTotalRecords(data.length);
        setUserList(data);
      } else {
        setUserList(response.data.data);
        setTotalRecords(response.data.totalRecords);
      }
    }
  }, [criteriaData, props.show]);

  useEffect(() => {
    if (props.show) {
      getUserListAsync();
      getDepartmentItemsAsync();
      getPositionItemsAsync();
    }
  }, [page, size, props.show]);

  const getDepartmentItemsAsync = async () => {
    const response = await department.getDepartmentsAsync();
    const departmentData: DepartmentResponse[] = response.data;
    const items: ItemModel[] = [];

    departmentData.forEach((data) => items.push({ label: data.name, value: data.id ? data.id : '' }));

    setDepartmentItems(items);
  };

  const getPositionItemsAsync = async () => {
    const response = await shareValue.getPostionListSelectionAsync();
    const positionData: ItemModel[] = response.data;
    const items: ItemModel[] = [];

    positionData.forEach((data) => items.push({ label: data.label, value: data.value }));

    setPositionItems(items);
  };

  const checkUserSelect = (userData: UserListSelectionResponse[]) => {
    const userListRowData: UserListSelectionResponse[] = [...userData];
    const userListSelectId = props.userDataListSelect.map((data) => data.id);

    userListSelectId.forEach((x) => {
      userListRowData.forEach((y, index) => {
        if (x === y.id) {
          userListRowData.splice(index, 1);
        }
      });
    });

    return userListRowData;
  };

  const chooseUser = (userData: UserListSelectionResponse) => {
    props.sendIdUser(userData);
    props.onHide();
  };

  const searchData = () => {
    getUserListAsync();
  };

  const clearData = useCallback(() => {
    setCriteriaData({} as UserListSelectionParams);

    getUserListAsync();
  }, [props.show]);

  return (
    <Modal show={props.show} size="xl">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h5 className="mb-1">ค้นหารายชื่อ</h5>
        <FaTimes onClick={props.onHide} />
      </div>
      <Card className="mb-3">
        <p>ค้นหา</p>
        <Row>
          <Col md={12} lg={4}>
            <Input value={criteriaData.fullName} label="ชื่อ-นามสกุล" onChange={(value) => setCriteriaData({ ...criteriaData, fullName: value })} />
          </Col>
          <Col lg={4}>
            <Selector
              value={criteriaData.departmentId}
              items={departmentItems}
              label="ฝ่าย/สำนัก"
              onChange={(value) => setCriteriaData({ ...criteriaData, departmentId: value === '' ? undefined : value })}
            />
          </Col>
          <Col lg={4}>
            <Selector
              label="ตำแหน่ง"
              value={criteriaData.positionId}
              onChange={(value) => setCriteriaData({ ...criteriaData, positionId: value })}
              items={postionItems}
            />
          </Col>
        </Row>
        <div className="d-flex gap-2">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2"
            onClick={searchData}
          >
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant="outline-primary"
            className="d-flex align-items-center gap-2"
            onClick={clearData}
          >
            <FaEraser />ล้าง
          </Button>
        </div>
      </Card>
      <Table total={totalRecords} onChange={(size, page) => (setPage(page), setSize(size))}>
        <thead>
          <tr>
            <th style={{ minWidth: 85 }}>ลำดับ</th>
            <th style={{ minWidth: 200 }}>ชื่อ-นามสกุล</th>
            <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
            <th style={{ minWidth: 80 }} />
          </tr>
        </thead>
        <tbody>
          {userList.map((data, index) => (
            <tr key={generateUniqueId(data.id)}>
              <td className="text-center">{index + 1}</td>
              <td>{data.fullName}</td>
              <td className="text-center">{data.departmentName}</td>
              <td className="text-center">{data.positionName}</td>
              <td className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  className="d-flex align-items-center gap-2"
                  onClick={() => chooseUser(data)}
                >
                  เลือก
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  );
}
