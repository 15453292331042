import { Table } from 'components';
import { Input } from 'components/Controls';
import { BadgeStatus } from 'components/Controls/BadgeisActive';
import { BusinessUnitResponse } from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEdit,
  FaEraser,
  FaPlus,
  FaSearch,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';
import businessUnit from 'services/businessUnit';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';

interface CriteriaProps {
  setKeyWord: (data: string) => void;
}

export default function SU02() {
  const [keyword, setKeyword] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [businessUnitList, setBusinessUnitList] = useState<BusinessUnitResponse[]>([]);
  const navigate = useNavigate();

  const getBusinessUnitListAsync = useCallback(async () => {
    const response = await businessUnit.getBusinessUnitListAsync(page, size, keyword);

    setBusinessUnitList(response.data.data);
    setTotalRecords(response.data.totalRecords);
  }, [page, size, keyword]);

  useEffect(() => {
    getBusinessUnitListAsync();
  }, [getBusinessUnitListAsync]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">สายงาน</h4>
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={() => navigate('detail')}
        >
          <FaPlus />เพิ่มสายงาน
        </Button>
      </div>
      <hr />
      <Criteria setKeyWord={(data) => setKeyword(data)} />
      <Table className="mt-4" total={totalRecords} onChange={(size, page) => (setSize(size), setPage(page))}>
        <thead>
          <tr>
            <th style={{ minWidth: 80 }}>ลำดับ</th>
            <th style={{ minWidth: 100 }}>รหัสสายงาน</th>
            <th style={{ minWidth: 300 }}>สายงาน</th>
            <th style={{ minWidth: 200 }}>สถานะ</th>
            <th style={{ minWidth: 60 }} />
          </tr>
        </thead>
        <tbody>
          {businessUnitList.map((data, index) => (
            <tr key={index}>
              <td className="text-center">{calculateRowNumber(index, page, size)}</td>
              <td className="text-center">{data.code}</td>
              <td className="text-wrap">{data.name}</td>
              <td className="text-center"><BadgeStatus status={data.active} /></td>
              <td className="text-end">
                <Button
                  variant="outline-primary"
                  className="gap-2"
                  onClick={() => navigate(`detail/${data.id}`)}
                >
                  <FaEdit className="me-2 mb-1" />แก้ไข
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

    </>
  );
}

function Criteria(props: CriteriaProps) {
  const [keyword, setKeyword] = useState<string>('');

  const onSearch = () => {
    props.setKeyWord(keyword);
  };

  const onClearKeyWord = () => {
    setKeyword('');

    props.setKeyWord('');
  };

  return (
    <Row className="criteria">
      <Col lg={8} xl={6} className="d-flex gap-2">
        <Input label="ค้นหา" value={keyword} onChange={setKeyword} placeholder="รหัส/ชื่อ สายงาน" />
        <div className="d-flex gap-2 button-inline">
          <Button variant="primary" className="d-flex align-items-center gap-2" onClick={onSearch}>
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant="outline-primary"
            className="d-flex align-items-center gap-2"
            onClick={onClearKeyWord}
          >
            <FaEraser />ล้าง
          </Button>
        </div>
      </Col>
    </Row>
  );
}
