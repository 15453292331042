import {
  Check,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  ItemModel,
  JorPor05Criteria,
  JorPor05ListData,
} from 'models';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { jorPor05 } from 'services';
import {
  GetFormatDateBC,
  HttpStatusCode,
  THCurrency,
  useAppContext,
} from 'utils';
import { generateUniqueId } from '../../../utils/helper';

type loader = {
  departmentItems: ItemModel[],
  basicInfoLists: JorPor05ListData[],
  basicInfoListTotal: number,
  supplyMethodDDL: ItemModel[],
};

interface CriteriaContext {
  page: number;
  size: number;
  totalRecords: number;
  criteria: JorPor05Criteria;
  basicInfoListData: JorPor05ListData[];
  setPage: Dispatch<SetStateAction<number>>;
  setSize: Dispatch<SetStateAction<number>>;
  setCriteria: Dispatch<SetStateAction<JorPor05Criteria>>;
  setBasicInfoListData: Dispatch<SetStateAction<JorPor05ListData[]>>;
}

interface StatusModel {
  label: string;
  value: string;
  count: number;
}

interface CountStatus {
  allSupplyMethod: number,
  supplyMethod60: number,
  supplyMethod80: number,
  all: number,
  notYet: number,
  draft: number,
  waitingAcceptor: number,
  accepted: number,
  reject: number,
  assignJorPor: number,
}

const Context = createContext({} as CriteriaContext);

export default function JorPor005() {
  const { basicInfoLists, basicInfoListTotal } = useLoaderData() as loader;
  const { userId } = useAppContext();
  const [supplyMethod, setSupplyMethod] = useState<StatusModel[]>([
    { label: 'ทั้งหมด', value: 'All', count: 0 },
    { label: 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560', value: '342e4e0b-7682-48c0-b46a-97a066908c9a', count: 0 },
    { label: 'ข้อบังคับธนาคาร 80', value: 'a21603e4-338d-486c-85ba-5f1cac8f6ace', count: 0 },
  ]);
  const [planStatusNameArray, setPlanStatusNameArray] = useState<StatusModel[]>(
    [
      { label: 'ทั้งหมด', value: 'All', count: 0 },
      { label: 'ยังไม่ดำเนินการ', value: 'NotYet', count: 0 },
      { label: 'แบบร่าง', value: 'Draft', count: 0 },
      { label: 'รออนุมัติ', value: 'WaitingAccept', count: 0 },
      { label: 'เห็นชอบ/อนุมัติ', value: 'Accepted', count: 0 },
      { label: 'ส่งกลับแก้ไข', value: 'Reject', count: 0 },
      { label: 'มอบหมายสำเร็จ', value: 'AssignJorPor', count: 0 },
    ]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [onInit, setOnInit] = useState(true);
  const [criteria, setCriteria] = useState<JorPor05Criteria>({
    supplyMethod: supplyMethod.map(i => i.value),
    status: planStatusNameArray.map(i => i.value),
    isPermission: true,
  } as JorPor05Criteria);
  const [basicInfoListData, setBasicInfoListData] = useState<JorPor05ListData[]>([]);

  const contextValue: CriteriaContext = {
    page,
    size,
    totalRecords,
    criteria,
    basicInfoListData,
    setPage,
    setSize,
    setCriteria,
    setBasicInfoListData,
  };

  useEffect(() => {
    if (basicInfoLists) {
      setBasicInfoListData(basicInfoLists);
      setTotalRecords(basicInfoListTotal);
      getStatusCountAsync(criteria);
    }
  }, []);

  useEffect(() => {
    if (!onInit) {
      criteria.size = size;
      criteria.page = page;
      getBasicInfoListsAsync(criteria);
    }
  }, [page, size]);

  const getStatusCountAsync = async (criteriaSearch?: JorPor05Criteria) => {
    const { data, status } = await jorPor05.getCountStatusAsync(criteriaSearch);

    if (status === HttpStatusCode.OK) {
      const countStatus: CountStatus = data.data;

      const supplyMethod = [
        { label: 'ทั้งหมด', value: 'All', count: countStatus.allSupplyMethod || 0 },
        { label: 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560', value: '342e4e0b-7682-48c0-b46a-97a066908c9a', count: countStatus.supplyMethod60 || 0 },
        { label: 'ข้อบังคับธนาคาร 80', value: 'a21603e4-338d-486c-85ba-5f1cac8f6ace', count: countStatus.supplyMethod80 || 0 },
      ];

      const planStatusNameArray = [
        { label: 'ทั้งหมด', value: 'All', count: countStatus.all || 0 },
        { label: 'ยังไม่ดำเนินการ', value: 'NotYet', count: countStatus.notYet || 0 },
        { label: 'แบบร่าง', value: 'Draft', count: countStatus.draft || 0 },
        { label: 'รออนุมัติ', value: 'WaitingAccept', count: countStatus.waitingAcceptor || 0 },
        { label: 'เห็นชอบ/อนุมัติ', value: 'Accepted', count: countStatus.accepted || 0 },
        { label: 'ส่งกลับแก้ไข', value: 'Reject', count: countStatus.reject || 0 },
        { label: 'มอบหมายสำเร็จ', value: 'AssignJorPor', count: countStatus.assignJorPor || 0 },
      ];

      setSupplyMethod(supplyMethod);
      setPlanStatusNameArray(planStatusNameArray);
      setOnInit(false);
    }
  };

  const getBasicInfoListsAsync = async (criteriaData: JorPor05Criteria) => {

    if (!criteriaData.page) {
      criteriaData.page = page;
    }

    if (!criteriaData.size) {
      criteriaData.size = size;
    }

    const { data, status } = await jorPor05.getListDataAsync(criteriaData.size, criteriaData.page, criteriaData);

    if (status === HttpStatusCode.OK) {
      setBasicInfoListData(data.data);
      setTotalRecords(data.totalRecords);
    }
  };

  const onSearch = (data: JorPor05Criteria) => {
    getBasicInfoListsAsync(data);
    getStatusCountAsync(data);
  };

  return (
    <Context.Provider value={contextValue}>
      <div className="m01">
        {!onInit &&
          <>
            < JorPor05Header />
            <Criteria onSearch={(data) => onSearch(data)} planStatusNameArray={planStatusNameArray} supplyMethodArray={supplyMethod} />
            <DataTable />
          </>
        }
      </div>

    </Context.Provider>
  );
}

function JorPor05Header() {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">จัดทำรายงานขอซื้อขอจ้าง (จพ.005)</h4>
      </div>
      <hr />
    </>
  );
}

function Criteria(props: {
  onSearch: (data: JorPor05Criteria) => void,
  planStatusNameArray: StatusModel[],
  supplyMethodArray: StatusModel[],
}) {
  const { departmentItems, supplyMethodDDL } = useLoaderData() as loader;
  const { criteria, setCriteria } = useContext(Context);
  const handleChangeCriteria = (value: string | number | Date | boolean, prop: string) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onSubmit = () => {
    criteria.page = 1;
    criteria.size = 10;
    props.onSearch(criteria);
  };

  const onClearCriteria = () => {
    const criteria = {
      supplyMethod: supplyMethodDDL.map((i) => i.value),
      status: props.planStatusNameArray.map((i) => i.value),
      isPermission: true,
    } as JorPor05Criteria

    setCriteria(criteria);

    props.onSearch(criteria);
  };

  const supplyMethodOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const supplyMethod = [...supplyMethodDDL.map((s) => s.value)];
        setCriteria({ ...criteria, supplyMethod });
        return;
      }
      setCriteria({ ...criteria, supplyMethod: [] });
      return;
    }
    if (result) {
      if ((criteria.supplyMethod.length + 1) === (supplyMethodDDL.length - 1)) {
        setCriteria({
          ...criteria,
          supplyMethod: [
            ...criteria.supplyMethod,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        supplyMethod: [
          ...criteria.supplyMethod,
          value,
        ],
      });
    } else {
      const supplyMethod = [...criteria.supplyMethod];
      const index = supplyMethod.findIndex((ps) => ps === value);

      if (index >= 0) {
        supplyMethod.splice(index, 1);
        const allIndex = supplyMethod.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          supplyMethod.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, supplyMethod });
      }
    }
  };

  const statusOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const status = [...props.planStatusNameArray.map((s) => s.value)];
        setCriteria({ ...criteria, status });
        return;
      }
      setCriteria({ ...criteria, status: [] });
      return;
    }
    if (result) {
      if ((criteria.status.length + 1) === (props.planStatusNameArray.length - 1)) {
        setCriteria({
          ...criteria,
          status: [
            ...criteria.status,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        status: [
          ...criteria.status,
          value,
        ],
      });
    } else {
      const status = [...criteria.status];
      const index = status.findIndex((ps) => ps === value);

      if (index >= 0) {
        status.splice(index, 1);
        const allIndex = status.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          status.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, status });
      }
    }
  };

  return (
    <>
      <Row className="criteria align-items-center">
        <Col sm={12} md={4} xl={3}>
          <Input
            label="เลขที่รายงานขอซื้อขอจ้าง (จพ.005)"
            value={criteria.jorPor05Number}
            onChange={(value) => handleChangeCriteria(value, 'jorPor05Number')} />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Input
            label="เลขที่รายงานขอซื้อขอจ้าง (จพ.004)"
            value={criteria.basicDocumentNumber}
            onChange={(value) => handleChangeCriteria(value, 'basicDocumentNumber')} />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Input
            label="เลขที่รายการจัดซื้อจัดจ้าง"
            value={criteria.planNumber}
            onChange={(value) => handleChangeCriteria(value, 'planNumber')} />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Selector
            items={departmentItems}
            label="ฝ่าย/สำนัก"
            value={criteria.departmentId}
            onChange={(value) => handleChangeCriteria(value, 'departmentId')} />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Input
            label="ชื่อโครงการ"
            value={criteria.planName}
            onChange={(value) => handleChangeCriteria(value, 'planName')} />
        </Col>
        {/* <Col sm={12} md={4} xl={3}>
          <Input
            label="เลขที่เอกสาร PR"
            value={criteria.prNumber}
            onChange={(value) => handleChangeCriteria(value, 'prNumber')} />
        </Col> */}
      </Row>
      <Row>
        <Col sm={6}
          className='Type'>
          วิธีจัดหา
          <div className='d-flex gap-2'>
            {props.supplyMethodArray?.map((v, i) => (
              <Check
                key={generateUniqueId(i)}
                onChange={(val) => supplyMethodOnChange(v.value, val)}
                value={criteria.supplyMethod?.some((i) => i === v.value)}
                label={(
                  <>
                    {v.label} <Status type={StatusType.SUPPLYMETHOD}
                      value={v.value}
                      couting={v.count}
                      badge />
                  </>
                )}
              />
            ))}
          </div>
        </Col>
        <Col md={6} className="Status">
          สถานะ
          <div className="d-flex gap-2 flex-wrap">
            {props.planStatusNameArray?.map((v, i) => (
              <div key={generateUniqueId(i)}>
                <Check
                  value={criteria.status?.some(i => i === v.value)}
                  onChange={val => statusOnChange(v.value, val)}
                  label={<>{v.label} <Status type={StatusType.PROCESS} value={v.value} couting={v.count} badge /></>} />
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4} xl={3}>
          <Check
            label='แสดงเฉพาะรายการที่ได้รับมอบหมาย'
            value={criteria.isPermission}
            onChange={(value) => handleChangeCriteria(value, 'isPermission')} />
        </Col>
      </Row>
      <Button
        variant="primary"
        className="me-2"
        onClick={onSubmit}>
        <FaSearch className="me-2" />ค้นหา
      </Button>
      <Button variant="outline-primary" type="button" onClick={onClearCriteria}>
        <FaEraser className="me-2" />ล้าง
      </Button>
    </>
  );
}

function DataTable() {
  const { basicInfoListData, totalRecords, setPage, setSize } = useContext(Context);
  const navigate = useNavigate();

  const goToPage = (id: string) => {
    navigate(`/pr/pr0101/detail/${id}`);
  };

  return (
    <Table className="mt-4" total={totalRecords} onChange={(size, page) => (setPage(page), setSize(size))}>
      <thead>
        <tr className="text-center">
          <th>เลขที่รายงานขอซื้อขอจ้าง<br />(จพ.005)</th>
          <th>เลขที่รายงานขอซื้อขอจ้าง<br />(จพ.004)</th>
          <th>เลขที่รายการ<br />จัดซื้อจัดจ้าง</th>
          <th>สถานะ</th>
          <th>ฝ่าย/สำนัก</th>
          <th style={{ minWidth: 150, width: 500 }}>ชื่อโครงการ</th>
          <th>วงเงิน</th>
          <th>วิธีจัดหา</th>
          <th>ประมาณการช่วงเวลา<br />
            การจัดซื้อจัดจ้าง
          </th>
        </tr>
      </thead>
      <tbody>
        {basicInfoListData.map((data, index) => (
          <tr className="text-center" key={index}>
            <td>
              <Button variant="link" onClick={() => goToPage(data.basicInformationId)}>
                {data.jorPor05Number}
              </Button>
            </td>
            <td>
              <Button variant="link" onClick={() => goToPage(data.basicInformationId)}>
                {data.basicDocumentNumber}
              </Button>
            </td>
            <td className='text-center'>
              {data.planNumber}
            </td>
            <td><Status type={StatusType.JOR_POR_04_05_APPROVER} value={data.status ?? ""} /></td>
            <td className='text-start'>{data.departmentName}</td>
            <td className='text-start text-wrap'>{data.planName}</td>
            <td className='text-end'>{THCurrency(data.financialAmount)}</td>
            <td>{data.supplyMethodName}</td>
            <td>{GetFormatDateBC(data.expectingProcurementAt)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}