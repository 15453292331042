import {
  useEffect,
  useState,
} from 'react';
import version from '_version'

function Footer() {
  const [sidebarIsCollapsed, setSidebarIsCollapsed] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('onSidebarIsCollapsed', (event) => {
      const customEvent = event as CustomEvent;

      setSidebarIsCollapsed(customEvent.detail);
    });
  }, []);

  return (
    <div className={`footer text-nowrap d-flex flex-column flex-lg-row justify-content-center justify-content-lg-center align-items-center ${sidebarIsCollapsed ? 'collapsed' : ''}`}>
      <p className="mb-0">Government Housing Bank © 2023. All Rights Reserved. {version}</p>
    </div>
  );
}

export default Footer;
