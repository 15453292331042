import { ItemModel } from 'models';
import { LoaderFunctionArgs } from 'react-router';
import { parameter, shareValue } from 'services';
import SectionApproverService from 'services/SU/SectionApproverService';
import SectionService from 'services/SU/SectionService';
import section from 'services/section';
import { SharedvalueGroup } from 'utils/constants/ContractEnum';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';

export const su13Loader = async () => {
  const sectionDDLList = await section.getDropdownSectionAsync();
  const processTypeList = await GetDDL(SharedvalueGroup.ProcessType);
  return {
    sectionDDL: sectionDDLList.data,
    processTypeDDL: processTypeList
  };
};

export const su13DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const sectionDDLList = await section.getDropdownSectionAsync();
  const buHeadDDLList = await SectionApproverService.getBuHeadDDLAsync();
  const buGroupDDLList = await SectionApproverService.getBuGroupDDLAsync();
  const buLineDDLList = await SectionApproverService.getBuLineDDLAsync();
  const buDepartmentDDLList = await SectionApproverService.getBuDepartmentDDLAsync();
  const buCenterDDLList = await SectionApproverService.getBuCenterDDLAsync();
  const buZoneDDLList = await SectionApproverService.getBuZoneDDLAsync();
  const buSegmentDDLList = await SectionApproverService.getBuSegmentDDLAsync();
  const buBranchDDLList = await SectionApproverService.getBuBranchDDLAsync();
  const buSubBranchDDLList = await SectionApproverService.getBuSubBranchDDLAsync();
  const processTypeList = await GetDDL(SharedvalueGroup.ProcessType);

  return {
    sectionDDL: sectionDDLList.data,
    buHeadDDL: buHeadDDLList.data,
    buGroupDDL: buGroupDDLList.data,
    buLineDDL: buLineDDLList.data,
    buDepartmentDDL: buDepartmentDDLList.data,
    buCenterDDL: buCenterDDLList.data,
    buZoneDDL: buZoneDDLList.data,
    buSegmentDDL: buSegmentDDLList.data,
    buBranchDDL: buBranchDDLList.data,
    buSubBranchDDL: buSubBranchDDLList.data,
    processTypeDDL: processTypeList
  };
};

const GetDDL = async (Type: string) => {
  const itemForMap: ItemModel[] = [];

  switch (Type) {
    case SharedvalueGroup.ProcessType: {
      const response = await await shareValue.getListSharedValueAsync(SharedvalueGroup.ProcessType);
      const items: ItemModel[] = response.data;
      items.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.code ?? ''
        })
      );
      break;
    }
  }

  return itemForMap;
};