import {
  DepartmentResponse,
  ItemModel,
} from 'models';
import { LoaderFunctionArgs } from 'react-router';
import {
  department,
  shareValue,
} from 'services';
import PP0201Services from 'services/PP/PP0201Services';
import { HttpStatusCode } from 'utils';

const PP0201Loader = async () => {
  // TODO: Do something on this function

};

const PP0201DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const departmentItems = await GetDDL('Department');
  const supplyMethodItems = await GetDDL('SupplyMethod');
  const supplyMethodTypeItems = await GetDDL('SupplyMethodType');
  // const planItems = await GetDDL('Plan');
  return {
    departmentDDL: departmentItems,
    supplyMethodDDL: supplyMethodItems,
    supplyMethodTypeDDL: supplyMethodTypeItems,
    // planDDL: planItems,
  };
};

const GetDDL = async (Type: string) => {
  const itemForMap: ItemModel[] = [];

  switch (Type) {
    case 'Department': {
      const response = await department.getDepartmentsAsync();
      const departmentData: DepartmentResponse[] = response.data;
      departmentData.forEach((data) => itemForMap.push({ label: data.name, value: data.id ?? '' }));
      break;
    }
    case 'SupplyMethod': {
      const response = await shareValue.getSupplyMethodListAsync();
      const supplyMethodData: ItemModel[] = response.data;
      supplyMethodData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case 'SupplyMethodType': {
      const response = await shareValue.getSupplyMethodTypeListAsync();
      const supplyMethodTypeData: ItemModel[] = response.data;
      supplyMethodTypeData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    // case 'Plan': {
    //   const response = await PP0201Services.GetPlanSelectionListAsync();

    //   if (response.status !== HttpStatusCode.OK) {
    //     return;
    //   }

    //   const planListData: ItemModel[] = response.data;
    //   planListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
    //   break;
    // }
  }

  return itemForMap;
};

export { PP0201Loader, PP0201DetailLoader };
