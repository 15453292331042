import {
  Modal,
  Status,
  StatusType,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { AppointHistoryListResponseModel } from 'models/PP/PreProcurementCreateDirectiveModels';
import {
  useEffect,
  useState,
} from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import PreProcurementTorServices from 'services/PP/PreProcurementTorServices';
import {
  fullDatetime,
  HttpStatusCode,
} from 'utils';

interface Props {
  show: boolean;
  onHide?: () => void;
  title?: string;
  id?: string;
  torNumber?: string;
}

export function HistoryModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [history, setHistory] = useState<AppointHistoryListResponseModel>({} as AppointHistoryListResponseModel);
  const [statusType, SetStatusType] = useState('');

  useEffect(() => {
    if (props.torNumber) {
      getTorHistoryAsynce(page, size);
    }
  }, [props.torNumber]);

  const getTorHistoryAsynce = async (page: number, size: number) => {
    setPage(page);
    setSize(size);
    const response = await PreProcurementTorServices.getListHistoryAsync(page, size, props.id, props.torNumber);

    if (response.status === HttpStatusCode.OK) {
      setHistory(response.data);
    }
  };

  const handleSetPagination = (page: number, size: number) => {
    getTorHistoryAsynce(page, size);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size="xl"
    >
      <Modal.Header>
        <Title
          text="ประวัติการใช้งานร่างขอบเขตของงาน (TOR)"
          className="fs-5 text-primary"
        />
      </Modal.Header>
      <ModalBT.Body className="p-0 ps-4">
        <Table
          className="my-3"
          total={history.totalCount}
          onChange={(size, page) => {
            handleSetPagination(page, size);
          }}
        >
          <thead>
            <tr>
              <th>วันที่/เวลา</th>
              <th>ผู้ดำเนินการ</th>
              <th>การดำเนินการ</th>
              <th>สถานะการดำเนินการ</th>
              <th>หมายเหตุ</th>

            </tr>
          </thead>
          <tbody>
            {
              (history && history.data) && (
                history.data.map((data, index) => (
                  <tr key={index}>
                    <td className="text-center">{fullDatetime(data.createdInfo_At)}</td>
                    <td>{data.createdInfo_ByUserFullName}</td>
                    <td>{data.description}</td>
                    <td className="text-start"><Status value={data.status} type={StatusType.TOR} /></td>
                    <td>{data.remark}</td>
                  </tr>
                ))
              )
            }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
