import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  ButtonCustom,
  Card,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Radio,
  Selector,
  SupplyMethodEnum,
  Table,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ContractAgreementModel, ContractAgreementWorkPhaseModel, DeliveryModel, EmploymentAgreementModel, FineModel, RetentionModel, UpdateWorkPhaseDetailModel } from '../../../../../../models/CM/CM02Models';
import { LocationDataHandler } from './LocationDataHandler';
import { InputNumber } from 'components/Controls/InputNumber';
import { ItemModel } from 'models';
import { ValidatePattern, fullDate } from 'utils';
import { EditDeliveryModal } from 'pages/CM/CM02/Modal/EditDeliveryModal';
import { ContractStartDatePeriodConditionIdEnum, FineTypeOtherIdEnum } from 'utils/constants/ShareValueEnum';
import { defaultFineRateValues } from 'pages/PreProcurement/TORDraft/data';


const fields = [
  {
    code: 'CF01',
    description: 'ผู้รับจ้างตกลงรับจ้างทำงาน',
    place: 'สถานที่รับจ้างทำงาน',
    province: 'จังหวัด',
    quantity: 'จำนวน'
  },
  {
    code: 'CF02',
    description: 'รายการสินค้าพัสดุที่ตกลงซื้อขาย',
    quantity: 'จำนวน',
    //processTime อยู่ใน CMS008
  },
  {
    code: 'CF03',
    description: 'ราคาสินค้าพัสดุที่ตกลงจะซื้อจะขาย',
    quantity: 'จำนวน',
    ProcessingTime: 'กำหนดเวลาจะซื้อจะขาย',
    processingTimePerYear: 'กำหนดเวลาจะซื้อจะขาย',
    processingTimePerMonth: 'เดือน',
    processingTimePerDay: 'วัน',
    startDate: 'นับตั้งแต่วันที่',
    dueDate: 'จนถึง',
  },
  {
    code: 'CF04',
    description: 'ผู้ขายตกลงขายและติดตั้งเครื่องคอมพิวเตอร์ฯ ซึ่งเป็นผลิตภัณฑ์ของ',
    quantity: 'จำนวน',
    //processTime อยู่ใน CMS008
  },
  {
    code: 'CF05',
    quantity: 'จำนวน',
    //processTime อยู่ใน CMS008
  },
  {
    code: 'CF06',
    description: 'ผู้ให้เช่าตกลงให้เช่าเครื่องคอมพิวเตอร์',
    //processTime อยู่ใน CMS008
  },
  {
    code: 'CF07',
    place: 'คอมพิวเตอร์ที่ตกลงจ้างบำรุงรักษา และซ่อมแซมแก้ไข ซึ่งติดตั้งอยู่ ณ',
    quantity: 'จำนวน',
    // processTime อยู่ใน CMS012
  },
  {
    code: 'CF08',
    description: 'รถยนต์ที่เช่า',
    brand: 'ยี่ห้อ',
    model: 'รุ่น',
    sizeOfEngine: 'ขนาดเครื่องยนต์ (ซีซี)',
    ProcessingTime: 'กำหนดระยะเวลาการเช่ารถยนต์',
    processingTimePerYear: 'กำหนดระยะเวลาการเช่ารถยนต์',
    startDate: 'นับตั้งแต่วันที่',
    dueDate: 'จนถึง',
    quantity: 'จำนวน',

    processingTimePerMonth: 'เดือน',
    processingTimePerDay: 'วัน',
  },
  {
    code: 'CF09',
    description: 'ผู้รับจ้างทำความสะอาดอาคาร',
    place: 'ตั้งอยู่ที่',
    province: 'จังหวัด',
    ProcessingTime: 'กำหนดเวลาจ้าง',
    processingTimePerMonth: 'เดือน',
    startDate: 'นับตั้งแต่วันที่',
    dueDate: 'จนถึง',
    contractStartDate: 'วันที่เริ่มทำงานตามสัญญา',
    contractDueDate: 'และสิ้นสุดในวันที่',
    quantity: 'จำนวน',

    processingTimePerYear: 'กำหนดเวลาจ้าง',
    processingTimePerDay: 'วัน',
  },
  {
    code: 'CF10',
    description: 'สถานที่รักษาความปลอดภัย',
    area: 'บริเวณ',
    place: 'ที่อยู่',
    province: 'จังหวัด',
    ProcessingTime: 'ระยะเวลาการจ้าง',
    processingTimePerYear: 'ระยะเวลาการจ้าง',
    processingTimePerMonth: 'เดือน',
    processingTimePerDay: 'วัน',
    startDate: 'นับตั้งแต่วันที่',
    dueDate: 'จนถึง',
    quantity: 'จำนวน',
    fine: 'ค่าปรับ',
    fineType: "ประเภทการปรับ",
    fineAmount: "จำนวนเงินค่าปรับ (บาท)",
    finePerHour: "คิดค่าปรับเมื่อปฏิบันงานไม่ครบ",
    finePercent: "ค่าปรับอัตราร้อยละ",
    finePercentUnit: "ต่อชั่วโมง",
  },
  {
    code: 'CF11',
    description: 'ผู้ให้เช่าตกลงให้เช่าเครื่องถ่ายเอกสาร',
    brand: 'ยี่ห้อ',
    model: 'รุ่น',
    serialNumber: 'หมายเลขเครื่อง',
    ProcessingTime: 'ระยะเวลาการเช่ามีกำหนด',
    processingTimePerYear: 'ระยะเวลาการเช่ามีกำหนด',
    processingTimePerMonth: 'เดือน',
    startDate: 'ตั้งแต่วันที่',
    dueDate: 'จนถึง',
    quantity: 'จำนวน',
    paymentTitle: 'งวดเงินและรายละเอียดการชำระเงิน',
    paymentType: 'ประเภทการจ่ายเงิน',

    processingTimePerDay: 'วัน',
  },
  {
    code: 'CF12',
    exchangeAgreement: 'โดยในการนี้ตกลงแลกเปลี่ยน',
    description: 'ให้แก่',
    quantity: 'จำนวน',
    // processTime อยู่ใน CMS012
  },
  {
    code: 'CF13',
    description: 'ผู้รับจ้างตกลงรับจ้างทำงาน',
    place: 'สถานที่รับจ้างทำงาน',
    province: 'จังหวัด',
    quantity: 'จำนวน',
    // processTime อยู่ใน CMS016
  },
  {
    code: 'CF14',
    description: 'ที่ปรีกษาตกลงรับจ้างออกแบบ',
    startDate2: 'วันที่เริ่มทำงาน',
    dueDate2: 'วันที่แล้วเสร็จ',
    quantity: 'จำนวน',
  },
  {
    code: 'CF15',
    description: 'ลักษณะงานที่จ้างที่ปรึกษา',
    quantity: 'จำนวน',
    startDate2: 'เริ่มลงมือทำงานภายในวันที่',
    dueDate2: 'ดำเนินการให้แล้วเสร็จภายในวันที่',
  },
];

const exchangeInfo = [
  {
    label: 'ผู้ให้แลกเปลี่ยน',
    value: 'true',
  },
  {
    label: 'ผู้รับแลกเปลี่ยน',
    value: 'false',
  },
];

interface IEmploymentAgreementProps {
  title?: string;
  contractTemplateCode?: string;
  contractAgreement: ContractAgreementModel;
  employmentAgreement: EmploymentAgreementModel;
  onEmploymentAgreementChange: (value: EmploymentAgreementModel) => void;
  onContractAgreementChange: (value: ContractAgreementModel) => void;
  onSubmitContractAgreementWorkPhase: (models: ContractAgreementWorkPhaseModel[]) => void; // TODO: Change this type
  fine: FineModel;
  onFineChange: (value: FineModel) => void;
  isDisabled?: boolean;
  isResponsible?: boolean;
  fineTypeDDL: ItemModel[];
  periodTypeDDL?: ItemModel[];
  vatTypeDDL?: ItemModel[];
  unitTypeDDL?: ItemModel[];
  paymentTypeDDL: ItemModel[];
  provincesDDL: ItemModel[];
  contractOfferTotalAmountVat: number;
  contractId: string;
  contractAgreementWorkPhaseData: ContractAgreementWorkPhaseModel[];
}

export function EmploymentAgreement(props: IEmploymentAgreementProps) {
  const [contractAgreement, setContractAgreement] = useState<ContractAgreementModel>(props.contractAgreement);
  const [employmentAgreement, setEmploymentAgreement] = useState<EmploymentAgreementModel>(props.employmentAgreement);
  const [fine, setFine] = useState<FineModel>(props.fine);
  const [exchange, setExchange] = useState<Boolean>(true);
  const [contractAgreementWorkPhases, setContractAgreementWorkPhases] = useState<ContractAgreementWorkPhaseModel[]>(props.contractAgreementWorkPhaseData);
  const [showEditDeliveryModal, setShowEditDeliveryModal] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (employmentAgreement) {
      props.onEmploymentAgreementChange(employmentAgreement);
    }
  }, [employmentAgreement]);

  useEffect(() => {
    if (contractAgreement) {
      props.onContractAgreementChange(contractAgreement);
    }
  }, [contractAgreement]);

  useEffect(() => {
    if (fine) {
      props.onFineChange(fine);
    }
  }, [fine]);

  useEffect(() => {
    if (firstLoad) {
      if (props.contractAgreement.supplyMethodId === SupplyMethodEnum.MethodId60
        && props.contractAgreement.offerTotalAmountVat > 100000
        && !props.fine) {
        getDefaultFineRates();
      }
      setFirstLoad(false);
    }
  }, [firstLoad]);

  const onSubmitDeliverWorkPhaseModal = async (models: ContractAgreementWorkPhaseModel[]) => {
    setContractAgreementWorkPhases(models);
    props.onSubmitContractAgreementWorkPhase(models);
    await onSaveWorkPhase(models);
  };

  const onSaveWorkPhase = async (models: ContractAgreementWorkPhaseModel[]) => {
    const updateDetails: UpdateWorkPhaseDetailModel[] = models.map((data) => ({
      id: data.id,
      sequence: data.sequence,
      paidPhase: data.paidPhase,
      workPhase: data.workPhase,
      periodDay: data.periodDay,
      amount: data.amount,
      percent: data.percent,
      advancePaymentAmount: data.advancePaymentAmount,
      retentionAmount: data.retentionAmount,
      detail: data.detail,
      deliveryDueDate: data.deliveryDueDate,
    } as UpdateWorkPhaseDetailModel));
  }

  const onTextChange = useCallback((key: string, value: string) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onChangeSubDistrict = useCallback((id: string, label: string) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      subDistrictCode: id,
      subDistrictName: label,
    }));
  }, []);

  const onChangeDistrict = useCallback((id: string, label: string) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      districtCode: id,
      districtName: label,
    }));
  }, []);

  const onChangeProvince = useCallback((id: string, label: string) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      provinceCode: id,
      provinceName: label,
    }));
  }, []);

  const onExchange = useCallback((value: string) => {
    console.log(value);
  }, []);

  const onChangeFinePercent = useCallback((val: number) => {
    setFine(prevState => ({
      ...prevState,
      finePercent: val,
      fineAmount: (props.contractAgreement.offerTotalAmountVat * (val / 100)),
    }));
  }, []);

  const onChangeFineAmount = useCallback((val: number) => {
    setFine(prevState => ({
      ...prevState,
      fineAmount: val,
      finePercent: ((val / props.contractAgreement.offerTotalAmountVat) * 100),
    }));
  }, []);

  const getDefaultFineRates = () => {
    defaultFineRateValues.filter(d => d.supplyMethodType === props.contractAgreement.supplyMethodTypeId).forEach((data) => {
      setFine(prevState => ({
        ...prevState,
        finePercent: data.fine,
        fineAmount: (props.contractAgreement.offerTotalAmountVat * (data.fine / 100)),
      }));
    });
  };

  const formatter = new Intl.NumberFormat('th-TH', {
    currency: 'THB',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <>
      <Card className='mt-3'>
        <Title text={`สัญญา${props.title}`}
          className='fs-5 text-primary' />
        <Row className='mt-3'>
          <>
            {
              fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
                <div>
                  {
                    data.exchangeAgreement &&
                    (
                      <Col sm={12}
                        md={6}>
                        <Radio
                          dflex
                          items={exchangeInfo}
                          value={exchange ? exchange?.toString() : 'false'}
                          rule={{ required: true }}
                          onChange={(val) => onExchange(val?.toString())}
                          name='exchange'
                          disabled={props.isDisabled} />
                      </Col>
                    )
                  }
                  {
                    data.description &&
                    (
                      <Row className='mt-3'>
                        <Col sm={10}>
                          <InputTextArea
                            label={data.description}
                            value={employmentAgreement?.description}
                            onChange={(value) => onTextChange('description', value)}
                            disabled={props.isDisabled} />
                        </Col>
                      </Row>
                    )
                  }
                  {
                    (data.brand || data.model) &&
                    (
                      <Row className='mt-3'>
                        {
                          data.brand &&
                          (
                            <Col sm={5}>
                              <Input
                                label={data.brand}
                                value={employmentAgreement?.brand}
                                onChange={(value) => onTextChange('brand', value)}
                                disabled={props.isDisabled}
                              />
                            </Col>
                          )
                        }
                        {
                          data.model &&
                          (
                            <Col sm={5}>
                              <Input
                                label={data.model}
                                value={employmentAgreement?.model}
                                onChange={(value) => onTextChange('model', value)}
                                disabled={props.isDisabled}
                              />
                            </Col>
                          )
                        }
                      </Row>
                    )
                  }
                  {
                    (data.serialNumber || data.sizeOfEngine) &&
                    (
                      <Row className='mt-3'>
                        {
                          data.serialNumber &&
                          (
                            <Col sm={5}>
                              <Input
                                label={data.serialNumber}
                                value={employmentAgreement?.serialNumber}
                                onChange={(value) => onTextChange('serialNumber', value)}
                                disabled={props.isDisabled}
                              />
                            </Col>
                          )
                        }
                        {
                          data.sizeOfEngine &&
                          (
                            <Col sm={5}>
                              <Input
                                label={data.sizeOfEngine}
                                value={employmentAgreement?.sizeOfEngine}
                                onChange={(value) => onTextChange('sizeOfEngine', value)}
                                disabled={props.isDisabled}
                              />
                            </Col>
                          )
                        }
                      </Row>
                    )
                  }
                  {
                    data.area &&
                    (
                      <Row className='mt-03'>
                        <Col sm={10}>
                          <InputTextArea
                            label={data.area}
                            value={employmentAgreement?.area}
                            onChange={(value) => onTextChange('area', value)}
                            disabled={props.isDisabled} />
                        </Col>
                      </Row>
                    )
                  }
                  {
                    data.place &&
                    (
                      <Row className='mt-03'>
                        <Col sm={10}>
                          <InputTextArea
                            label={data.place}
                            value={employmentAgreement?.place}
                            onChange={(value) => onTextChange('place', value)}
                            disabled={props.isDisabled} />
                        </Col>
                      </Row>
                    )
                  }
                  {
                    data.province &&
                    (
                      <Row>
                        <LocationDataHandler
                          provinceId={employmentAgreement?.provinceCode}
                          districtId={employmentAgreement?.districtCode}
                          subDistrictId={employmentAgreement?.subDistrictCode}
                          provincesDDL={props.provincesDDL}
                          onProvinceChange={(id, label) => onChangeProvince(id, label)}
                          onDistrictChange={(id, label) => onChangeDistrict(id, label)}
                          onSubDistrictChange={(id, label) => onChangeSubDistrict(id, label)}
                          isDisabled={props.isDisabled} />
                      </Row>
                    )
                  }
                  {
                    data.ProcessingTime &&
                    (
                      <Row>
                        {
                          data.processingTimePerYear &&
                          (

                            <Col sm={4} className='d-flex'>
                              <InputNumber
                                textCenter
                                label={data.processingTimePerYear}
                                value={employmentAgreement?.processingTimePerYear}
                                onChange={(val) => onNumberChange('processingTimePerYear', Number(val))}
                                disabled={props.isDisabled}
                              />
                              <label className='mt-5 ps-2'>ปี</label>
                            </Col>
                          )
                        }
                        {
                          data.processingTimePerMonth &&
                          (
                            <Col sm={4} className='d-flex'>
                              <InputNumber
                                textCenter
                                className='no-label'
                                value={employmentAgreement?.processingTimePerMonth}
                                onChange={(val) => onNumberChange('processingTimePerMonth', Number(val))}
                                disabled={props.isDisabled}
                              />
                              <label className='mt-5 ps-2'>เดือน</label>
                            </Col>
                          )
                        }
                        {
                          data.processingTimePerDay &&
                          (
                            <Col sm={4} className='d-flex'>
                              <InputNumber
                                textCenter
                                className='no-label'
                                value={employmentAgreement?.processingTimePerDay}
                                onChange={(val) => onNumberChange('processingTimePerDay', Number(val))}
                                disabled={props.isDisabled}
                              />
                              <label className='mt-5 ps-2'>วัน</label>
                            </Col>
                          )
                        }
                      </Row>
                    )
                  }
                  {
                    (data.startDate && data.dueDate) &&
                    (
                      <>
                        <Row className='mt-3'>
                          <Col sm={5}>
                            <DatePicker
                              label={data.startDate}
                              value={props.contractAgreement?.deliveryPeriod.startDate}
                              onChangeHasNullable={(value) => setEmploymentAgreement({ ...employmentAgreement, startDate: value })}
                              disabled={true}
                            />
                          </Col>
                          <Col sm={5}>
                            <DatePicker
                              label={data.dueDate}
                              value={props.contractAgreement?.deliveryPeriod.dueDate}
                              onChangeHasNullable={(value) => setEmploymentAgreement({ ...employmentAgreement, dueDate: value })}
                              disabled={true}
                            />
                          </Col>
                        </Row>
                        {
                          props.contractAgreement.deliveryPeriod.startDateCondition !== ContractStartDatePeriodConditionIdEnum.ตามระยะเวลาที่ระบุ &&
                          (
                            <Row>
                              <Col
                                sm={10}
                                style={{ fontSize: '13px' }}>
                                <div className='bg-light rounded bg-opacity-25 p-3 pb-1'>
                                  <p>*หมายเหตุ: {data.startDate} จะแสดงหลังจากยืนยันวันที่ลงนามแล้ว โดยระบบจะคำนวนจาก {data.ProcessingTime} เพื่อหาวันที่สิ้นสุดของสัญญา</p>
                                </div>
                              </Col>
                            </Row>
                          )
                        }
                      </>
                    )
                  }
                  {
                    (data.contractStartDate && data.contractDueDate) &&
                    (
                      <Row className='mt-3'>
                        <Col sm={5}>
                          <DatePicker
                            label={data.contractStartDate}
                            value={employmentAgreement?.contractStartDate}
                            onChangeHasNullable={(value) => setEmploymentAgreement({ ...employmentAgreement, contractStartDate: value })}
                            disabled={props.isDisabled}
                          />
                        </Col>
                        <Col sm={5}>
                          <DatePicker
                            label={data.contractDueDate}
                            value={employmentAgreement?.contractDueDate}
                            onChangeHasNullable={(value) => setEmploymentAgreement({ ...employmentAgreement, contractDueDate: value })}
                            disabled={props.isDisabled}
                          />
                        </Col>
                      </Row>
                    )
                  }
                  {
                    data.quantity &&
                    (
                      <Row className='mt-3'>
                        <Col sm={5}>
                          <InputNumber
                            label={data.quantity}
                            value={employmentAgreement?.quantity}
                            onChange={(val) => setEmploymentAgreement({ ...employmentAgreement, quantity: Number(val) })}
                            disabled={props.isDisabled}
                          />
                        </Col>
                        <Col sm={5}>
                          <Selector
                            className='no-label'
                            items={props.unitTypeDDL}
                            value={employmentAgreement?.quantityUnit}
                            onChange={(value) => onTextChange('quantityUnit', value)}
                            disabled={props.isDisabled} />
                        </Col>
                      </Row>
                    )
                  }
                  <Row>
                    <Col sm={10}>
                      <Selector
                        label='อัตราภาษีมูลค่าเพิ่ม'
                        items={props.vatTypeDDL}
                        onChange={(val) => setContractAgreement({ ...contractAgreement, offerVatType: val })}
                        value={contractAgreement?.offerVatType}
                        disabled={props.isDisabled} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={10}>
                      <Currency
                        label='ราคาที่ตกลง (บาท)'
                        onChange={(val) => setContractAgreement({ ...contractAgreement, offerTotalAmount: Number(val) })}
                        value={contractAgreement?.offerTotalAmount}
                        disabled={true} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={10}>
                      <Currency
                        label='ภาษีมูลค่าเพิ่ม (บาท)'
                        onChange={(val) => setContractAgreement({ ...contractAgreement, offerAmountVat: Number(val) })}
                        value={contractAgreement?.offerAmountVat}
                        disabled={true} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={10}>
                      <Currency
                        label='ราคารวมทั้งสิ้น (บาท)'
                        onChange={(val) => setContractAgreement({ ...contractAgreement, offerTotalAmountVat: Number(val) })}
                        value={contractAgreement?.offerTotalAmountVat}
                        disabled={true} />
                    </Col>
                  </Row>
                  {
                    (data.startDate2 && data.dueDate2) &&
                    (

                      <>
                        <Row className='mt-3'>
                          <Col sm={5}>
                            <DatePicker
                              label={data.startDate2}
                              value={props.contractAgreement?.deliveryPeriod.startDate}
                              onChangeHasNullable={(value) => setEmploymentAgreement({ ...employmentAgreement, startDate: value })}
                              disabled={true}
                            />
                          </Col>
                          <Col sm={5}>
                            <DatePicker
                              label={data.dueDate2}
                              value={props.contractAgreement?.deliveryPeriod.dueDate}
                              onChangeHasNullable={(value) => setEmploymentAgreement({ ...employmentAgreement, dueDate: value })}
                              disabled={true}
                            />
                          </Col>
                        </Row>
                        {
                          props.contractAgreement.deliveryPeriod.startDateCondition !== ContractStartDatePeriodConditionIdEnum.ตามระยะเวลาที่ระบุ &&
                          (
                            <Row>
                              <Col
                                sm={10}
                                style={{ fontSize: '13px' }}>
                                <div className='bg-light rounded bg-opacity-25 p-3 pb-1'>
                                  <p>*หมายเหตุ: {data.startDate} จะแสดงหลังจากยืนยันวันที่ลงนามแล้ว โดยระบบจะคำนวนจาก {data.ProcessingTime} เพื่อหาวันที่สิ้นสุดของสัญญา</p>
                                </div>
                              </Col>
                            </Row>
                          )
                        }
                      </>
                    )
                  }
                  {
                    data.fine &&
                    (
                      <>
                        {data.fine && (<h5 className='mt-3' >{data.fine}</h5>)}
                        <Row className='mt-3'>
                          <Col sm={8}
                            md={5}
                            className='d-flex'>
                            <div style={{ width: `${fine?.fineType !== FineTypeOtherIdEnum.อื่นๆระบุ ? '100%' : '30%'}` }}>
                              <Selector
                                label={data.fineType}
                                items={props.fineTypeDDL}
                                value={fine?.fineType}
                                onChange={(val) => setFine({ ...fine, fineType: val })}
                                disabled={props.isDisabled}
                              />
                            </div>
                            {
                              fine?.fineType === FineTypeOtherIdEnum.อื่นๆระบุ &&
                              (
                                <div style={{ width: '70%', marginTop: '1px' }}>
                                  <Input type='text'
                                    className='no-label ps-1'
                                    value={fine.fineTypeOther}
                                    onChange={(val) => setFine({ ...fine, fineTypeOther: val })}
                                    rule={{ required: true }}
                                    disabled={props.isDisabled} />
                                </div>
                              )
                            }
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col sm={8}
                            md={3}>
                            <Currency
                              label={data.finePercent}
                              value={fine?.finePercent}
                              rule={{ pattern: ValidatePattern.PERCENTAGE }}
                              onChange={(val) => onChangeFinePercent(Number(val))}
                              disabled={props.isDisabled} />
                          </Col>
                          <Col sm={8}
                            md={3}>
                            <Currency
                              label={data.fineAmount}
                              value={fine?.fineAmount}
                              onChange={(val) => onChangeFineAmount(Number(val))}
                              disabled={props.isDisabled} />
                          </Col>
                          <Col sm={8}
                            md={3}>
                            <Selector
                              label='ต่อ'
                              items={props.periodTypeDDL}
                              onChange={(val) => setFine({ ...fine, fineAmountUnit: (val) })}
                              disabled={props.isDisabled} />
                          </Col>
                        </Row>
                      </>
                    )
                  }
                  {
                    data.paymentType && (
                      <>
                        <Row className='d-flex justify-content-between'>
                          <Col sm={5}
                            md={5}>
                            <p>โดยมีรายละเอียดการส่งมอบ ดังนี้</p>
                          </Col>
                          <Col sm={5}
                            md={5}
                            className='text-end'>
                            {props.isResponsible && (<ButtonCustom
                              text='แก้ไขรายการ'
                              onClick={() => setShowEditDeliveryModal(true)}
                              disabled={props.isDisabled} />)}
                          </Col>
                        </Row>
                        <Row>
                          <Table hidePagination>
                            <thead>
                              <tr className='text-center'>
                                <th style={{ width: 20 }}>งวดเงิน</th>
                                <th style={{ width: 20 }}>งวดงาน</th>
                                <th style={{ width: 50 }}>ระยะเวลา (วัน)</th>
                                <th style={{ width: 70 }}>วันที่ต้องส่งมอบ</th>
                                <th style={{ width: 20 }}>ร้อยละ</th>
                                <th style={{ width: 70 }}>จำนวนเงิน</th>
                                <th style={{ width: 70 }}>หักเงินล่วงหน้า</th>
                                <th style={{ width: 70 }}>หักเงินประกันผลงาน</th>
                                <th style={{ width: '50%' }}>รายเอียด</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                contractAgreementWorkPhases?.map((data, index) => (
                                  <tr className='text-center' key={index}>
                                    <td>{data.paidPhase}</td>
                                    <td>{data.workPhase}</td>
                                    <td>{data.periodDay}</td>
                                    <td>{data.deliveryDueDate !== undefined ? fullDate(data.deliveryDueDate) : ''}</td>
                                    <td>{formatter.format(data.percent)}%</td>
                                    <td className='text-end'>{formatter.format(data.amount)}</td>
                                    <td className='text-end'>{formatter.format(data.advancePaymentAmount ?? 0)}</td>
                                    <td className='text-end'>{formatter.format(data.retentionAmount ?? 0)}</td>
                                    <td><InputTextArea value={data.detail}
                                      disabled />
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </Row>
                      </>
                    )
                  }
                </div>
              )}
          </>
        </Row>
      </Card>
      <EditDeliveryModal
        show={showEditDeliveryModal}
        contractAgreementId={props.contractId}
        onHide={() => setShowEditDeliveryModal(false)}
        contractAmountVat={props.contractOfferTotalAmountVat}
        contractAgreementWorkPhases={contractAgreementWorkPhases}
        onSubmit={onSubmitDeliverWorkPhaseModal}
        isDisabled={props.isDisabled}
      />
    </>
  );
}