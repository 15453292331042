import Cookie from 'cookie-universal';
import {
  AnnualPlanListResponseModel,
  AnnualPlanModel,
  CreatePL0201Model,
  SearchPL0201ModelForGet,
  SearchPL0201ModelForGetReport,
} from 'models/PL';
import http from 'utils/axios';

const qs = require('qs');

const cookies = Cookie();

const getFullName = cookies.get('fullName') as string;

const getPL0201sAsync = (page: number, size: number, searchData?: SearchPL0201ModelForGet) => {
  const params = {
    page,
    size,
    ...searchData,
  };
  return http.get<AnnualPlanListResponseModel>('/annualPlan', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getPL0201ByIdAsync = (planId: string) => http.get<AnnualPlanModel>(`/annualPlan/${planId}`);

const createMainDocumentAsync = (planId: string) => {
  const body = {
    annualPlanId: planId,
  };

  return http.post('annualplan/main-document', body);
};

const createPL0201Async = (body: CreatePL0201Model) => {
  const formData = new FormData();

  formData.append('departmentId', body.departmentId);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodName', body.supplyMethodName);
  formData.append('planName', body.planName);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString());
  formData.append('planAnnouncementDate', body.planAnnouncementDate ? new Date(body.planAnnouncementDate).toISOString() : body.planAnnouncementDate);
  formData.append('planEGPNumber', body.planEGPNumber);
  formData.append('createdByUserFullName', getFullName);
  formData.append('type', body.type);

  if (body.userIds !== undefined && body.userIds.length > 0) {
    body.userIds.forEach((userId) => {
      formData.append('userIds', userId);
    });
  }

  if (body.documents.length !== 0) {
    body.documents.forEach((file) => {
      formData.append('documents', file);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post('/annualPlan', formData, config);
};

const updateMultiplePl0201Async = (planIds: string[], userIds: string[]) => {
  const body = {
    planIds,
    userIds,
    updatedByUserFullName: cookies.get('fullName') || '',
  };

  return http.put('/annualPlan/update-list', body);
};

const updatePL0201Async = (id: string, body: CreatePL0201Model) => {
  const formData = new FormData();

  formData.append('departmentId', body.departmentId);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodName', body.supplyMethodName);
  formData.append('planName', body.planName);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString());
  formData.append('planAnnouncementDate', body.planAnnouncementDate ? new Date(body.planAnnouncementDate).toISOString() : body.planAnnouncementDate);
  formData.append('planEGPNumber', body.planEGPNumber);
  formData.append('updatedByUserFullName', cookies.get('fullName') || '');
  formData.append('type', body.type);

  if (body.userIds !== undefined && body.userIds.length !== 0) {
    body.userIds.forEach((userId) => {
      formData.append('userIds', userId);
    });
  }

  if (body.documents.length !== 0) {
    body.documents.forEach((file) => {
      formData.append('documents', file);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`/annualPlan/${id}`, formData, config);
};

const getAnnualPlanDocumentAsync = (planId: string, documentId: string) => http.get(`/annualPlan/${planId}/documents/${documentId}`, {
  responseType: 'blob',
});

const deleteAnnualPlanDocumentAsync = (planId: string, documentId: string) => http.delete(`/annualPlan/${planId}/documents/${documentId}`);

const deleteAnnualPlanDocumentListAsync = (planId: string, docNameList: string[]) => http.delete(`/annualPlan/${planId}/documents`, { data: { docNames: docNameList } });

const getReportExcelAsync = (searchData?: SearchPL0201ModelForGetReport) => {
  const params = { ...searchData };

  return http.get('/annualPlan/report', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
    responseType: 'blob',
  });
};

const getEgpReportExcelAsync = (searchData?: SearchPL0201ModelForGetReport) => {
  const params = { ...searchData };

  return http.get('/annualPlan/egpReport', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
    responseType: 'blob',
  });
};

const GetPlanDropDownListAsync = async () => await http.get('/annualplan/dropdown');

const deleteAnnualPlan = async (id: string) => await http.delete(`annualplan/${id}`);

const GetPlanSelectionListAsync = async () => await http.get('/annualplan/planselection');

export default {
  getPL0201sAsync,
  getPL0201ByIdAsync,
  getAnnualPlanDocumentAsync,
  getReportExcelAsync,
  getEgpReportExcelAsync,
  createPL0201Async,
  createMainDocumentAsync,
  updatePL0201Async,
  updateMultiplePl0201Async,
  deleteAnnualPlanDocumentAsync,
  GetPlanDropDownListAsync,
  deleteAnnualPlan,
  deleteAnnualPlanDocumentListAsync,
  GetPlanSelectionListAsync,
};
