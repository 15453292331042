import { Card as CardBT } from 'react-bootstrap';

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export function Card({ children, className }: Props) {
  return (
    <CardBT className={className || ''}>
      <CardBT.Body>
        {children}
      </CardBT.Body>
    </CardBT>
  );
}