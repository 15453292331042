import Cookie from 'cookie-universal';
import {
  AppointCommiteeListRequest,
  CreateAppointCommiteeModel,
  UpdateAppointCommiteeModel,
} from 'models/PR/PR0202Models';
import http from 'utils/axios';

const cookies = Cookie();

const qs = require('qs');

const getFullName = cookies.get('fullName') as string;
const getAppointmentProcurementListAsync = async (searchData: AppointCommiteeListRequest) => {
  const params = {
    orderNumber: searchData.orderNumber,
    orderName: searchData.orderName,
    projectName: searchData.projectName,
    departmentId: searchData.departmentId,
    procurementCommitee: searchData.procurementCommitee,
    purchaseInspector: searchData.purchaseInspector,
    responsiblePerson: searchData.responsiblePerson,
    page: searchData.page,
    size: searchData.size,
  };

  return http.get('/appointcommitteeprocurement/pagination', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getAppointmentProcurementByIdAsync = async (id: string) => await http.get(`/appointcommitteeprocurement/${id}`);

const getAppointmentProcurementDocumentAsync = (id: string, documentId: string) => http.get(`/appointcommitteeprocurement/${id}/documents/${documentId}`, {
  responseType: 'blob',
});

// const deleteAppointmentProcurementDocumentAsync = (id: string, docNameList: string[]) => {
//    return http.delete(`/appointcommitteeprocurement/${id}/`, { data: { docNames: docNameList } });
// }

const deleteAppointmentProcurementDocumentAsync = (id: string, docNameList: string[]) => http.delete(`/appointcommitteeprocurement/${id}/documents`, { data: { docNames: docNameList } });

const approverProcurementPendingAsync = async (id: string) => await http.put(`/appointcommitteeprocurement/pending/${id}`);

const createAppointCommiteeAsync = (body: CreateAppointCommiteeModel) => {
  const formData = new FormData();

  formData.append('planId', body.planId);
  formData.append('planName', body.planName);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
  formData.append('supplyMethodType', body.supplyMethodType);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString().split('T')[0]);
  formData.append('Subject', body.subject);
  formData.append('reason', body.reason);
  formData.append('templateId', '00000000-0000-0000-0000-000000000000');
  formData.append('descriptionDutyProcurement', body.descriptionDutyProcurement);
  formData.append('descriptionDutyCheck', body.descriptionDutyCheck);
  formData.append('descriptionDutyMaintenanceService', body.descriptionDutyMaintenanceService);
  formData.append('status', 'Draft');
  formData.append('createdByUserFullName', getFullName);

  if (body.files && body.files.length !== 0) {
    body.files.forEach((file) => {
      formData.append('files', file);
    });
  }

  if (body.committeeAppointProcurements && body.committeeAppointProcurements.length > 0) {
    body.committeeAppointProcurements.forEach((data, index) => {
      formData.append(`committeeAppointProcurements[${index}][userId]`, data.userId);
      formData.append(`committeeAppointProcurements[${index}][FullName]`, data.fullName);
      formData.append(`committeeAppointProcurements[${index}][position]`, data.position);
      formData.append(`committeeAppointProcurements[${index}][Department]`, data.department);
      formData.append(`committeeAppointProcurements[${index}][PositionOnBoardId]`, data.positionOnBoardId);
    });
  }

  if (body.committeeChecks && body.committeeChecks.length > 0) {
    body.committeeChecks.forEach((data, index) => {
      formData.append(`committeeChecks[${index}][userId]`, data.userId);
      formData.append(`committeeChecks[${index}][FullName]`, data.fullName);
      formData.append(`committeeChecks[${index}][position]`, data.position);
      formData.append(`committeeChecks[${index}][Department]`, data.department);
      formData.append(`committeeChecks[${index}][PositionOnBoardId]`, data.positionOnBoardId);
    });
  }

  if (body.committeeMaintenanceServices && body.committeeMaintenanceServices.length > 0) {
    body.committeeMaintenanceServices.forEach((data, index) => {
      formData.append(`committeeMaintenanceServices[${index}][userId]`, data.userId);
      formData.append(`committeeMaintenanceServices[${index}][FullName]`, data.fullName);
      formData.append(`committeeMaintenanceServices[${index}][position]`, data.position);
      formData.append(`committeeMaintenanceServices[${index}][Department]`, data.department);
      formData.append(`committeeMaintenanceServices[${index}][PositionOnBoardId]`, data.positionOnBoardId);
    });
  }

  if (body.appointProcurementApprovers && body.appointProcurementApprovers.length > 0) {
    body.appointProcurementApprovers.forEach((data, index) => {
      formData.append(`appointProcurementApprovers[${index}][approverUserId]`, data.approverUserId);
      formData.append(`appointProcurementApprovers[${index}][approverFullName]`, data.approverFullName);
      formData.append(`appointProcurementApprovers[${index}][position]`, data.position);
      formData.append(`appointProcurementApprovers[${index}][approvePositionOnBoardId]`, data.approvePositionOnBoardId);
      formData.append(`appointProcurementApprovers[${index}][remark]`, data.remark);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post('/appointcommitteeprocurement', formData, config);
};

const updateAppointCommiteeAsync = (body: UpdateAppointCommiteeModel) => {
  const formData = new FormData();
  formData.append('id', body.id);
  formData.append('planId', body.planId);
  formData.append('planName', body.planName);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
  formData.append('supplyMethodType', body.supplyMethodType);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString().split('T')[0]);
  formData.append('Subject', body.subject);
  formData.append('reason', body.reason);
  formData.append('templateId', '00000000-0000-0000-0000-000000000000');
  formData.append('descriptionDutyProcurement', body.descriptionDutyProcurement);
  formData.append('descriptionDutyCheck', body.descriptionDutyCheck);
  formData.append('descriptionDutyMaintenanceService', body.descriptionDutyMaintenanceService);
  formData.append('status', body.status);
  formData.append('updateByUserFullName', getFullName);

  if (body.files && body.files.length !== 0) {
    body.files.forEach((file) => {
      formData.append('files', file);
    });
  }

  if (body.committeeAppointProcurements && body.committeeAppointProcurements.length > 0) {
    body.committeeAppointProcurements.forEach((data, index) => {
      if (data.id) {
        formData.append(`committeeAppointProcurements[${index}][id]`, data.id);
      }
      formData.append(`committeeAppointProcurements[${index}][userId]`, data.userId);
      formData.append(`committeeAppointProcurements[${index}][FullName]`, data.fullName);
      formData.append(`committeeAppointProcurements[${index}][position]`, data.position);
      formData.append(`committeeAppointProcurements[${index}][Department]`, data.department);
      formData.append(`committeeAppointProcurements[${index}][PositionOnBoardId]`, data.positionOnBoardId);
    });
  }

  if (body.committeeChecks && body.committeeChecks.length > 0) {
    body.committeeChecks.forEach((data, index) => {
      if (data.id) {
        formData.append(`committeeChecks[${index}][id]`, data.id);
      }
      formData.append(`committeeChecks[${index}][userId]`, data.userId);
      formData.append(`committeeChecks[${index}][FullName]`, data.fullName);
      formData.append(`committeeChecks[${index}][position]`, data.position);
      formData.append(`committeeChecks[${index}][Department]`, data.department);
      formData.append(`committeeChecks[${index}][PositionOnBoardId]`, data.positionOnBoardId);
    });
  }

  if (body.committeeMaintenanceServices && body.committeeMaintenanceServices.length > 0) {
    body.committeeMaintenanceServices.forEach((data, index) => {
      if (data.id) {
        formData.append(`committeeMaintenanceServices[${index}][id]`, data.id);
      }
      formData.append(`committeeMaintenanceServices[${index}][userId]`, data.userId);
      formData.append(`committeeMaintenanceServices[${index}][FullName]`, data.fullName);
      formData.append(`committeeMaintenanceServices[${index}][position]`, data.position);
      formData.append(`committeeMaintenanceServices[${index}][Department]`, data.department);
      formData.append(`committeeMaintenanceServices[${index}][PositionOnBoardId]`, data.positionOnBoardId);
    });
  }

  if (body.appointProcurementApprovers && body.appointProcurementApprovers.length > 0) {
    body.appointProcurementApprovers.forEach((data, index) => {
      if (data.id) {
        formData.append(`appointProcurementApprovers[${index}][id]`, data.id);
      }
      formData.append(`appointProcurementApprovers[${index}][approverUserId]`, data.approverUserId);
      formData.append(`appointProcurementApprovers[${index}][approverFullName]`, data.approverFullName);
      formData.append(`appointProcurementApprovers[${index}][position]`, data.position);
      formData.append(`appointProcurementApprovers[${index}][approvePositionOnBoardId]`, data.approvePositionOnBoardId);
      formData.append(`appointProcurementApprovers[${index}][remark]`, data.remark);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put('/appointcommitteeprocurement', formData, config);
};

export default {
  getAppointmentProcurementListAsync,
  getAppointmentProcurementByIdAsync,
  getAppointmentProcurementDocumentAsync,
  deleteAppointmentProcurementDocumentAsync,
  createAppointCommiteeAsync,
  updateAppointCommiteeAsync,
  approverProcurementPendingAsync,
};
