import http from 'utils/axios';

const getPdfId = async (value: string) =>
  await http.get(`/DocumentTemplate/${value}/pdf`);

const getUserManualListAsync = async () => await http.get('/DocumentTemplate/usermanual');

export default {
  getPdfId,
  getUserManualListAsync,
};