import { Input, InputFile, Modal, Selector, Table, UploadFile } from 'components';
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaHistory, FaSave } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router';
import toast from 'utils/toast';
import { HttpStatusCode, fullDatetime, submitForm } from 'utils';
import { SU12Service } from 'services';
import { DocumentTemplate, DocumentTemplateHistory } from 'models';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';
import { GROUPS } from 'constant/TemplateGroups';

interface HistoryModalProps {
  show: boolean;
  data: DocumentTemplateHistory[];
  totalRecords: number;
  onChange: (size: number, page: number) => void;
  onHide: () => void;
}

export default function SU12Detail() {
  const [docTemp, setDocTemp] = useState<DocumentTemplate>({} as DocumentTemplate);
  const [docTempFile, setDocTempFile] = useState<File>();
  const [pdfFile, setPdfFile] = useState<File>();
  const collaboraRef = useRef<CollaboraRef>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [docTempHistoryList, setDocTempHistoryList] = useState<DocumentTemplateHistory[]>([]);
  const [docTempHistoryTotalRecords, setDocTempHistoryTotalRecords] = useState<number>(0);

  useEffect(() => {
    if (id) {
      getDataAsync();
    }
  }, [id]);

  const onSaveAsync = async () => {
    submitForm({
      programGroup: docTemp.programGroup,
      name: docTemp.name,
    });

    if (!docTemp.programGroup || !docTemp.name) {
      return;
    }

    if (id) {
      const res = await SU12Service.updateAsync(id!, docTemp.name, docTempFile, pdfFile);

      if (res.status === HttpStatusCode.OK) {
        toast.success('บันทึกข้อมูลสำเร็จ');

        window.location.reload();

        return;
      }
    }

    if (!docTempFile) {
      return;
    }

    const res = await SU12Service.saveAsync(
      docTemp.programGroup,
      docTemp.name,
      docTempFile,
      pdfFile);

    if (res.status === HttpStatusCode.OK) {
      toast.success('บันทึกข้อมูลสำเร็จ');

      navigate(`/su/su12/detail/${res.data}`);

      return;
    }
  };

  const getDataAsync = async () => {
    const res = await SU12Service.getDataAsync(id!);

    if (res.status === HttpStatusCode.OK) {
      setDocTemp(res.data);
    }

    if (res.status === HttpStatusCode.NOT_FOUND) {
      navigate('/su/su12/detail');
    }
  };

  const getHistoryAsync = async (size: number, page: number) => {
    const res = await SU12Service.getHistoryAsync(id!, size, page);

    if (res.status === HttpStatusCode.OK) {
      setDocTempHistoryList(res.data.data);
      setDocTempHistoryTotalRecords(res.data.totalRecords);

      return true;
    }

    return false;
  };

  const openHistoryModalAsync = async () => {
    await getHistoryAsync(10, 1);

    setShowHistoryModal(true);
  };

  const openPdf = async () => {
    const endpoint = process.env.REACT_APP_API_URL!;

    window.open(`${endpoint}/file/${docTemp.pdfFileId}/pdf`, '_blank');
  };

  return (
    <>
      <Row>
        <Col className='d-flex justify-content-end'>
          {/* <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={openHistoryModalAsync}>
            <FaHistory />ประวัติการแก้ไข
          </Button> */}
        </Col>
      </Row>
      <Row>
        <Col sm={6} xl={3}>
          <Selector
            label='กลุ่ม'
            value={docTemp.programGroup}
            onChange={programGroup => setDocTemp({ ...docTemp, programGroup })}
            items={GROUPS}
            rule={{ required: true }}
            disabled={!!id} />
        </Col>
        <Col sm={6} xl={3}>
          <Input
            label='รหัส'
            value={docTemp.code}
            disabled />
        </Col>
        <Col sm={6} xl={3}>
          <Input
            label='ชื่อรูปแบบเอกสาร'
            value={docTemp.name}
            onChange={name => setDocTemp({ ...docTemp, name })}
            rule={{ required: true }} />
        </Col>
        <Col sm={6} xl={3}>
          <InputFile
            label='ตัวอย่างเอกสาร'
            onChange={setPdfFile}
            fileTypes={['pdf']} />
          {docTemp.pdfFileId ? (
            <p
              className='text-decoration-underline cursor-pointer'
              onClick={openPdf}>
              {docTemp.pdfFileName}
            </p>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Document Template</p>
          <UploadFile
            limit={1}
            fileTypes={['docx', 'pdf']}
            onChange={setDocTempFile} />
        </Col>
      </Row>
      {docTemp.documentTemplateFileId ? (
        <Row>
          <Col>
            <Collabora
              docId={docTemp.documentTemplateFileId}
              docName='doc-01'
              ref={collaboraRef}
              readonly={false} />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col className='d-flex justify-content-between'>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={() => navigate('/su/su12')}>
            <MdArrowBack />ย้อนกลับ
          </Button>
          <Button
            variant='success'
            className='d-flex align-items-center gap-2'
            onClick={onSaveAsync}>
            บันทึก
          </Button>
        </Col>
      </Row>
      <HistoryModal
        show={showHistoryModal}
        data={docTempHistoryList}
        totalRecords={docTempHistoryTotalRecords}
        onChange={getHistoryAsync}
        onHide={() => setShowHistoryModal(false)} />
    </>
  );
}

function HistoryModal(props: HistoryModalProps) {
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const onChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);

    props.onChange(size, page);
  };

  return (
    <Modal show={props.show} size='lg'>
      <Row>
        <Col>
          <h3>ประวัติการแก้ไข</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            className='mt-4'
            total={props.totalRecords}
            onChange={onChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 75 }}>ลำดับ</th>
                <th style={{ minWidth: 150 }}>ผู้จัดทำล่าสุด</th>
                <th style={{ minWidth: 150 }}>วันที่จัดทำล่าสุด</th>
              </tr>
            </thead>
            <tbody>
              {props.data.map((data, i) => (
                <tr key={i}>
                  <td className='text-center'>{calculateRowNumber(i, page, size)}</td>
                  <td>{data.createdBy}</td>
                  <td className='text-center'>{fullDatetime(data.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col className='d-flex justify-content-end'>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={() => props.onHide()}>
            ยกเลิก
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}