import { TorStatusConstant } from 'constant/PreProcumentTorStatus';
import {
  useCallback,
  useMemo,
} from 'react';
import { Badge } from 'react-bootstrap';
import { FaCircle } from 'react-icons/fa';
import { useAppContext } from 'utils';

export enum StatusType {
  PROJECT,
  PROCESS,
  ANNOUNCEPLANPROCESS,
  APPOINTMENT,
  APPOINTMENTEDIT,
  APPOINTMENTCANCEL,
  PRE_PROCURMENT,
  JOR_POR_04_05_APPROVER,
  SUPPLYMETHOD,
  APPROVEDAPPOINTMENT,
  TOR,
  APPOINTMENTORDER,
  INVITATIONORDER,
  PURCHASEORDER,
  ASSIGN_CONTRACT,
  SupplyMethodNameEnum,
  PURCHASE_ORDER_APPROVAL,
  INVITEDCONTRACTSTATUS,
  DRAFT_CONTRACT,
  MEDIANPRICE,
  ContractCompleteReport,
  CM03Status,
  ContractStatus,
  INVITEDCONTRACT,
  ContractReturnCollateral,
  CollateralContractStatus,
  JorPorComment,
}

export const enum CollateralContractStatus {
  ContractWarranty = 'ContractWarranty',
  ContractComplete = 'ContractComplete',
}

export const enum CM03Status {
  ContractAgreement = 'ContractAgreement',
  ContractManagement = 'ContractManagement',
  ContractWarranty = 'ContractWarranty',
  ContractComplete = 'ContractComplete',
  ContractCancelled = 'ContractCancelled',
}

export const enum ContractAuditStatus {
  All = 'All',
  Draft = 'Draft',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Approve = 'Approve',
}

export const enum MedianPriceStatus {
  All = 'All',
  None = 'None',
  Draft = 'Draft',
  WaitingForCommitteeApprove = 'WaitingForCommitteeApprove',
  CommitteeRejected = 'CommitteeRejected',
  WaitingForApprove = 'WaitingForApprove',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  CommitteeApproved = 'CommitteeApproved',
  WaitingForJorPorDirector = 'WaitingForJorPorDirector',
  WaitingForAssignResponsible = 'WaitingForAssignResponsible',
  DraftSectionApprover = 'DraftSectionApprover',
}

export const enum ContractReceiveCollateral {
  Collateral = 'Collateral',
  Consider = 'Consider',
  Request = 'Request',
  Receive = 'Receive',
}

export const enum ContractReturnCollateralStatus {
  ALL = 'All',
  NONE = 'None',
  DRAFT = 'Draft',
  WAITINGFORCONSIDER = 'WaitingForConsider',
  WAITINGFORAPPROVE = 'WaitingForApprove',
  WAITINGFORRECEIVECOLLATERAL = 'WaitingForReceiveCollateral',
  COMPLETED = 'Completed',
}

export const enum ContractReturnCollateralConsiderStatus {
  NONE = 'None',
  DRAFT = 'Draft',
  WAITFORINSPECTORACCEPT = 'WaitForInspectorAccept',
  INSPECTORACCEPTED = 'InspectorAccepted',
  INSPECTORREJECTED = 'InspectorRejected',
  WAITFORJORPORASSIGN = 'WaitForJorPorAssign',
  JORPORASSIGNED = 'JorPorAssigned',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected'
}

export const enum ContractRequestCollateralStatus {
  DRAFT = 'Draft',
  WAITFORAPPROVE = 'WaitForApprove',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export const enum ContractReceiveCollateralStatus {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  DRAFT = 'Draft',
}

export const enum ReturnCollateralStatus {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  ALL = 'ALL',
  NONE = 'None',
  WAITINGFORAPPROVE = 'Waitingforapprove',
  WAITFORJORPORAPPROVE = 'WaitForJorPorApprove',
  JORPORAPPROVED = 'JorPorApproved',
  JORPORREJECTED = 'JorPorRejected',
}

export const enum ContractReceiveStatus {
  Draft = 'Draft',
  WaitForInspectorReceived = 'WaitForInspectorReceived',
  InspectorReceived = 'InspectorReceived',
  InspectorRejected = 'InspectorRejected',
  WaitForJorPorApprove = 'WaitForJorPorApprove',
  JorPorApproved = 'JorPorApproved',
  JorPorRejected = 'JorPorRejected',
  WaitForApprove = 'WaitForApprove',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancel = 'Cancel',
  Pending = 'Pending',
  Approve = 'Approve',
  Received = 'Received',
  Delivery = 'Delivery',
  PaidPhase = 'PaidPhase',
  Delivered = 'Delivered',
  Paid = 'Paid',
}

export const enum ContractStatus {
  ContractAgreement = 'ContractAgreement',
  ContractManagement = 'ContractManagement',
  ContractWarranty = 'ContractWarranty',
  ContractComplete = 'ContractComplete',
  ContractCancelled = 'ContractCancelled',
  None = 'None',
  Assigned = 'Assigned',

  Pending = 'Pending',
  Paid = 'Paid',
  Receive = 'Receive',
  Approve = 'Approve',
  Approved = 'Approved',
  Approved01 = 'Approved01',
  Invited = 'Invited',
  InspectorReceived = 'InspectorReceived',
  JorPorApproved = 'JorPorApproved',
  Reject = 'Reject',
  Rejected = 'Rejected',
  InspectorRejected = 'InspectorRejected',
  JorPorRejected = 'JorPorRejected',
  Cancel = 'Cancel',
  Warning = 'Warning',
  WaitForInspectorReceived = 'WaitForInspectorReceived',
  WaitForJorPorApprove = 'WaitForJorPorApprove',
  WaitForApprove = 'WaitForApprove',
  Delivered = 'Delivered',
  Draft = 'Draft',
  WaitingForApprove = 'WaitingForApprove',
}

export const enum InvitedContractStatus {
  DRAFT = 'Draft',
  WAITINGFORAPPROVE = 'WaitingForApprove',
  REJECTED = 'Rejected',
  NONE = 'None',
  INVITED = 'Invited',
  PENDING = 'Pending',
  ALL = 'All',
}

export enum PurchaseOrderApproval {
  WaitingForSign = 'WaitingForSign',
  Assigned = 'Assigned',
  Draft = 'Draft',
  Signed = 'Signed',
  Rejected = 'Rejected',
}

export enum ProjectName {
  ANNUAL_PLAN = 'AnnualPlan',
  IN_YEAR_PLAN = 'InYearPlan',
  CHANGE_PLAN = 'ChangePlan',
  CANCEL_PLAN = 'CancelPlan',
}

export const enum PlanType {
  AnnualPlan = 'AnnualPlan',
  InYearPlan = 'InYearPlan',
}

export const enum PurchaseOrderStatus {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export const enum AssignContractStatus {
  DRAFT = 'Draft',
  ASSIGNED = 'Assigned',
}

export enum ProcessStatus {
  DRAFT_PLAN = 'DraftPlan',
  WAITING_APPROVE_PLAN = 'WaitingApprovePlan',
  APPROVE_PLAN = 'ApprovePlan',
  REJECT_PLAN = 'RejectPlan',
  ASSIGNED = 'Assigned',
  DRAFT_RECORD_DOCUMENT = 'DraftRecordDocument',
  WAITING_ACCEPTOR = 'WaitingAcceptor',
  APPROVE_ACCEPTOR = 'ApproveAcceptor',
  REJECT_ACCEPTOR = 'RejectAcceptor',
  ANNOUNCEMENT = 'Announcement',
  REJECT_ANNOUNCEMENT = 'RejectAnnouncement',
  CANCEL_PLAN = 'CancelPlan',
  WaitingComitteeApprove = 'WaitingComitteeApprove',
  DraftAcceptor = 'DraftAcceptor',

  DRAFT = 'Draft',
  DRAFT_CHANGE_PLAN = 'DraftChangePlan',
  DRAFT_CANCEL_PLAN = 'DraftCancelPlan',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  WAITTIGAPPROVEPLAN = 'WaitingApprovePlan',
  APPROVEPLAN = 'ApprovePlan',
  REJECTPLAN = 'RejectPlan',

  ALL = 'All',
  ASSIGN_JORPOR = 'AssignJorPor',
  WAITING_ACCEPT = 'WaitingAccept',
  ACCEPTED = 'Accepted',
  REJECT = 'Reject',
}

export enum AnnouncementProcessStatus {
  All = 'All',
  DRAFT = 'Draft',
  ASSIGN_JORPOR = 'AssignJorPor',
  WAITING_ACCEPT = 'WaitingAccept',
  ACCEPTED = 'Accepted',
  REJECT = 'Reject',
}

export enum SupplyMethodNameEnum {
  Method60 = 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560',
  Method80 = 'ข้อบังคับธนาคาร 80',
}

export enum SupplyMethodEnum {
  All = 'All',
  MethodId60 = '342e4e0b-7682-48c0-b46a-97a066908c9a',
  MethodId80 = 'a21603e4-338d-486c-85ba-5f1cac8f6ace',
}

export enum AppointmentStatus {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
  APPROVE = 'Approve',
  REJECT = 'Reject',
}

export enum AppointmentOrderStatus {
  None = 'None',
  DRAFT = 'Draft',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
}

export enum InvitationOrderStatus {
  None = 'None',
  Draft = 'Draft',
  WaitingForApprove = 'WaitingForApprove',
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected'
}

export enum AppointTorStatus {
  Draft = 'Draft',
  DraftTor = 'DraftTor',
  WaitingForCommitteeApprove = 'WaitingForCommitteeApprove',
  DraftSectionApprover = 'DraftSectionApprover',
  WaitingForApprove = 'WaitingForApprove',
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
  APPROVE = 'Approve',
}

export enum RecordDocStatus {
  ASSIGNED = 'Assigned',
  SAVE = 'Save',
  WAITACCEPT = 'WaitAccept',
  ACCEPTED = 'Accepted',
}

export enum PreProcurmentStatus {
  DRAFT = 'Draft',
  SUBMIT = 'Submit',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  ANNOUNCEMENT = 'Announcement',
  REJECTED = 'Rejected'
}

export enum JorPor04Approve {
  ALL = 'All',
  DARFT = 'Draft',
  WAITING_ACCEPTOR = 'WaitingAcceptor',
  ACCEPTED = 'Accepted',
  REJECT = 'Reject',
  ASIGN_JORPOR = 'AssignJorPor',
  APPORVE = 'Approve',
  PENDING = 'Pending',
  WAITING_ACCEPT = 'WaitingAccept',
  NotYet = 'NotYet',
  NULL = '',
  None = 'None',
  WaitingComitteeApprove = 'WaitingComitteeApprove',
  DraftAcceptor = 'DraftAcceptor',
  WaitingForDirectorJorPorAssign = 'WaitingForDirectorJorPorAssign',
  Cancelled = 'Cancelled',
}

export enum PlanChangeOrAmendType {
  IsChanged = 'IsChanged',
  IsCancel = 'IsCancel',
}

export enum ContractAgreementStatus {
  Draft = 'Draft',
  WaitingForApprove = 'WaitingForApprove',
  WaitingForAttachSignedDocument = 'WaitingForAttachSignedDocument',
  Rejected = 'Rejected',
  WaitingForSign = 'WaitingForSign',
  Signed = 'Signed',
  RejectedSign = 'RejectedSign',
  WaitingForDirectorJorPorAssign = 'WaitingForDirectorJorPorAssign',
  WaitingForAssignResponsible = 'WaitingForAssignResponsible',
  DraftSectionApprover = 'DraftSectionApprover',
  Approved = 'Approved',
}

export enum ContractReturnRequestStatus {
  None = 'None',
  Draft = 'Draft',
  WaitForApprove = 'WaitForApprove',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export enum StatusEditType {
  IsChanged = 'IsChanged',
  IsCancel = 'IsCancel',
}

export enum ContractCompleteReportStatus {
  Draft = 'Draft',
  WaitingForApprove = 'WaitingForApprove',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

interface Props {
  value: ProjectName | ProcessStatus | AppointmentStatus | RecordDocStatus | AppointTorStatus | string;
  type: StatusType,
  badge?: boolean,
  couting?: number,
  planType?: string,
  showValue?: string;
}

export function Status({
  value, type, badge, couting, planType, showValue
}: Props) {
  const { positionCode } = useAppContext();

  const projectStatusCheck = (className: string, text: string) => {
    if (badge) {
      return <Badge className={`badge  mx-1 ${className}`}>{text}</Badge>;
    }

    return <span className={className}>{text}</span>;
  };

  const processStatusCheck = (className: string, text: string) => {
    if (badge) {
      if (couting !== undefined) {
        return <Badge className={`badge mx-1 ${className}`}>{couting}</Badge>;
      }

      return <Badge className={`badge ${className}`}>{text}</Badge>;
    }

    return (
      <span>
        <div>
          <FaCircle style={{ fontSize: 10 }} className={className} />
        </div>
        <p className='mb-0'>{text}</p>
      </span>
    );
  };

  const processSupplyMethodCheck = (className: string, text: string) =>
    <Badge className={`badge mx-1 ${className}`}>{couting}</Badge>;

  const appointmentStatusCheck = (className: string, text: string) => {
    if (badge) {
      return <Badge className={`badge ${className}`}>{text}</Badge>;
    }

    return (
      <span>
        <div><FaCircle style={{ fontSize: 10 }} className={className} />
        </div>
        <p className='mb-0'>{text}</p>
      </span>
    );
  };

  const preProcurementStatusCheck = (className: string, text: string) => {
    if (badge) {
      return <Badge className={`badge ${className}`}>{text}</Badge>;
    }

    return (
      <span>
        <div>
          <FaCircle style={{ fontSize: 10 }} className={className} />
        </div>
        <p className='mb-0'>{text}</p>
      </span>
    );
  };

  const jorPor04ApproveStatusCheck = (className: string, text: string) => {
    if (badge) {
      if (couting !== undefined) {
        return <Badge className={`badge mx-1 ${className}`}>{couting}</Badge>;
      }
      return <Badge className={`badge ${className}`}>{text}</Badge>;
    }

    return (
      <span>
        <div>
          <FaCircle style={{ fontSize: 10 }} className={className} />
        </div>
        <p className='mb-0'>{text}</p>
      </span>
    );
  };

  const appointTorStatusCheck = (className: string, text: string) => {
    if (badge) {
      return <Badge className={`badge ${className}`}>{text}</Badge>;
    }

    return (
      <span>
        <div>
          <FaCircle style={{ fontSize: 10 }} className={className} />
        </div>
        <p className='mb-0'>{text}</p>
      </span>
    );
  };

  const renderStatusContract = useCallback((statusValue: string) => {
    switch (statusValue) {
      case ContractStatus.Approve:
        return processStatusCheck('approve-plan', 'เห็นชอบ/อนุมัติ');
      case ContractStatus.Approved:
        return processStatusCheck('approve-plan', 'เห็นชอบ/อนุมัติ');
      case ContractStatus.Approved01:
        return processStatusCheck('approve-plan', 'เห็นชอบ/อนุมัติ');
      case ContractStatus.Cancel:
        return processStatusCheck('reject-plan', 'ยกเลิกสัญญา');
      case ContractStatus.ContractAgreement:
        return processStatusCheck('draft-plan', 'ร่างสัญญา');
      case ContractStatus.ContractCancelled:
        return processStatusCheck('reject-plan', 'ยกเลิกสัญญา');
      case ContractStatus.ContractComplete:
        return processStatusCheck('approve-plan', 'สิ้นสุดสัญญา');
      case ContractStatus.ContractManagement:
        return processStatusCheck('approve-plan', 'บริหารสัญญา');
      case ContractStatus.ContractWarranty:
        return processStatusCheck('approve-plan', 'รับประกันสัญญา');
      case ContractStatus.Delivered:
        return processStatusCheck('waiting-approve-plan', 'ส่งมอบแล้ว');
      case ContractStatus.Draft:
        return processStatusCheck('draft-plan', 'แบบร่าง');
      case ContractStatus.InspectorRejected:
        return processStatusCheck('reject-plan', 'ส่งกลับแก้ไข');
      case ContractStatus.Invited:
        return processStatusCheck('approve-plan', 'เห็นชอบ/อนุมัติ');
      case ContractStatus.JorPorApproved:
        return processStatusCheck('approve-plan', 'เจ้าหน้าที่พัสดุให้ความเห็น');
      case ContractStatus.JorPorRejected:
        return processStatusCheck('reject-plan', 'ส่งกลับแก้ไข');
      case ContractStatus.Paid:
        return processStatusCheck('approve-plan', 'เบิกจ่ายแล้ว');
      case ContractStatus.Pending:
        return processStatusCheck('waiting-approve-plan', 'รอเห็นชอบ/อนุมัติ');
      case ContractStatus.Receive:
        return processStatusCheck('waiting-approve-plan', 'รอเห็นชอบ');
      case ContractStatus.Reject:
        return processStatusCheck('reject-plan', 'ส่งกลับแก้ไข');
      case ContractStatus.Rejected:
        return processStatusCheck('reject-plan', 'ส่งกลับแก้ไข');
      case ContractStatus.WaitForApprove:
        return processStatusCheck('waiting-approve-plan', 'รอเห็นชอบ');
      case ContractStatus.WaitForInspectorReceived:
        return processStatusCheck('waiting-approve-plan', 'รอเห็นชอบ');
      case ContractStatus.WaitForJorPorApprove:
        return processStatusCheck('waiting-approve-plan', 'รอเจ้าหน้าที่พัสดุให้ความเห็น');
      case ContractStatus.WaitingForApprove:
        return processStatusCheck('waiting-approve-plan', 'รอเห็นชอบ');
      case ContractStatus.InspectorReceived:
        return processStatusCheck('waiting-approve-plan', 'รอเห็นชอบ');
      case ContractStatus.Warning:
        return processStatusCheck('waiting-approve-plan', 'ส่งมอบ');
      default:
        return processStatusCheck('draft-plan', 'รอดำเนินการ');
    }
  }, []);

  const renderStatusContractReturnCollateral = useCallback((statusValue: string) => {
    switch (statusValue) {
      case ContractReturnCollateralStatus.DRAFT:
        return processStatusCheck('draft-plan', 'แบบร่าง');
      case ContractReturnCollateralStatus.COMPLETED:
        return processStatusCheck('approve-plan', 'คืนหลักประกันสัญญา');
      case ContractReturnCollateralStatus.WAITINGFORCONSIDER:
        return processStatusCheck('waiting-approve-plan', 'อยู่ระหว่างพิจารณาคืนหลักประกันสัญญา');
      case ContractReturnCollateralStatus.WAITINGFORAPPROVE:
        return processStatusCheck('waiting-approve-plan', 'อยู่ระหว่างขออนุมัติคืนหลักประกันสัญญา');
      case ContractReturnCollateralStatus.WAITINGFORRECEIVECOLLATERAL:
        return processStatusCheck('waiting-approve-plan', 'อยู่ระหว่างการบันทึกรับหลักประกัน');
      default:
        return processStatusCheck('draft-plan', 'รอดำเนินการพิจารณาคืนหลักประกัน');
    }
  }, []);

  const renderStatusProject = useCallback((statusValue: string) => {
    switch (statusValue) {
      case ProjectName.ANNUAL_PLAN:
        return projectStatusCheck('announcement', 'แผนรวมปี');
      case ProjectName.IN_YEAR_PLAN:
        return projectStatusCheck('announcement', 'แผนระหว่างปี');
      case ProjectName.CHANGE_PLAN:
        return projectStatusCheck('change-plan', 'เปลี่ยนแปลงแผน');
      case ProjectName.CANCEL_PLAN:
        return projectStatusCheck('cancel-plan', 'ยกเลิกแผน');
      default:
        return projectStatusCheck('', 'ยังไม่ดำเนินการ');
    }
  }, []);

  const renderStatusProcess = useCallback((statusValue: string) => {
    switch (statusValue) {
      case ProcessStatus.DRAFT_PLAN:
        return processStatusCheck('draft-plan',
          planType === PlanChangeOrAmendType.IsChanged ? 'แบบร่างขอเปลี่ยนแปลง'
            : planType === PlanChangeOrAmendType.IsCancel ? 'แบบร่างขอยกเลิก' : 'แบบร่าง');
      case ProcessStatus.WAITING_APPROVE_PLAN:
        return processStatusCheck('waiting-approve-plan', 'ส่งผอ.ฝ่ายเห็นชอบ');
      case ProcessStatus.APPROVE_PLAN:
        return processStatusCheck('approve-plan', isMD ? 'เห็นชอบ/อนุมัติ' : 'เห็นชอบ');
      case ProcessStatus.REJECT_PLAN:
        return processStatusCheck('reject-plan', 'ส่งกลับแก้ไข');
      case ProcessStatus.ASSIGNED:
        return processStatusCheck('assigned', 'มอบหมายงาน');
      case ProcessStatus.DRAFT_RECORD_DOCUMENT:
        return processStatusCheck('draft-record-document', 'จัดทำเอกสารบันทึก');
      case ProcessStatus.WAITING_ACCEPTOR:
        return processStatusCheck('waiting-approve-plan', isMD ? 'รอเห็นชอบ' : 'รออนุมัติ');
      case ProcessStatus.APPROVE_ACCEPTOR:
        return processStatusCheck('approve-acceptor', 'เห็นชอบและอนุมัติสำเร็จ');
      case ProcessStatus.REJECT_ACCEPTOR:
        return processStatusCheck('reject-acceptor', 'ไม่เห็นชอบ');
      case ProcessStatus.ANNOUNCEMENT:
        return processStatusCheck('announcement', 'เผยแพร่ประกาศแผน');
      case ProcessStatus.REJECT_ANNOUNCEMENT:
        return processStatusCheck('reject-announcement', 'ส่งกลับแก้ไข-จากการเผยแพร่');
      case ProcessStatus.ALL:
        return processStatusCheck('all', 'ทั้งหมด');
      case ProcessStatus.ASSIGN_JORPOR:
        return processStatusCheck('assigned', 'ผอ.จพ. มอบหมาย');
      case ProcessStatus.DRAFT:
        return processStatusCheck('draft-plan', 'แบบร่าง');
      case ProcessStatus.WAITING_ACCEPT:
        return processStatusCheck('waiting-approve-plan', 'รอให้ความเห็นชอบ');
      case ProcessStatus.ACCEPTED:
        return processStatusCheck('approve-plan', 'เห็นชอบและอนุมัติสำเร็จ');
      case ProcessStatus.REJECT:
        return processStatusCheck('reject-plan', 'ไม่เห็นชอบ');
      case ProcessStatus.CANCEL_PLAN:
        return processStatusCheck('reject-plan', 'ยกเลิกรายการจัดซื้อจัดจ้าง');
      case ProcessStatus.DraftAcceptor:
        return processStatusCheck('waiting-approve-plan', 'อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ');
      case ProcessStatus.WaitingComitteeApprove:
        return processStatusCheck('waiting-approve-plan', 'อยู่ระหว่าง คกก. เห็นชอบ');
      default:
        return processStatusCheck('', 'ยังไม่ดำเนินการ');
    }
  }, []);

  const renderStatusAnnouncementProcess = useCallback((statusValue: string) => {
    switch (statusValue) {
      case AnnouncementProcessStatus.All:
        return processStatusCheck('all', 'ทั้งหมด');
      case AnnouncementProcessStatus.DRAFT:
        return processStatusCheck('draft-plan', 'แบบร่าง');
      case AnnouncementProcessStatus.ASSIGN_JORPOR:
        return processStatusCheck('assigned', 'ผอ.จพ. มอบหมาย');
      case AnnouncementProcessStatus.WAITING_ACCEPT:
        return processStatusCheck('waiting-approve-plan', 'รอให้ความเห็นชอบ');
      case AnnouncementProcessStatus.ACCEPTED:
        return processStatusCheck('approve-plan', 'เห็นชอบ');
      case AnnouncementProcessStatus.REJECT:
        return processStatusCheck('reject-plan', 'ส่งกลับแก้ไข');
      default:
        return processStatusCheck('', 'ยังไม่ดำเนินการ');
    }
  }, []);

  const renderStatusAppointment = useCallback((statusValue: string) => {
    switch (statusValue) {
      case AppointmentStatus.DRAFT:
        return appointmentStatusCheck('draft', 'แบบร่าง');
      case AppointmentStatus.PENDING:
        return appointmentStatusCheck('pending', 'รอเห็นชอบ/รออนุมัติ');
      case AppointmentStatus.APPROVED:
        return appointmentStatusCheck('approved', 'เห็นชอบ/อนุมัติ');
      case AppointmentStatus.REJECTED:
        return appointmentStatusCheck('reject', 'ส่งกลับแก้ไข');
      case AppointmentStatus.CANCELLED:
        return appointmentStatusCheck('cancel-pp', 'ยกเลิก');
      default:
        return appointmentStatusCheck('', 'ยังไม่ดำเนินการ');
    }
  }, []);

  const renderStatusAppointmentCancel = useCallback((statusValue: string) => {
    switch (statusValue) {
      case AppointmentStatus.DRAFT:
        return appointmentStatusCheck('draft', 'แบบร่างขอยกเลิก');
      case AppointmentStatus.PENDING:
        return appointmentStatusCheck('pending', 'รอเห็นชอบ/รออนุมัติขอยกเลิก');
      case AppointmentStatus.APPROVED:
        return appointmentStatusCheck('approved', 'เห็นชอบ/อนุมัติ');
      case AppointmentStatus.REJECTED:
        return appointmentStatusCheck('reject', 'ส่งกลับแก้ไข');
      case AppointmentStatus.CANCELLED:
        return appointmentStatusCheck('cancel-pp', 'ยกเลิก');
      default:
        return appointmentStatusCheck('', 'ยังไม่ดำเนินการ');
    }
  }, []);

  const renderStatusAppointmentEdit = useCallback((statusValue: string) => {
    switch (statusValue) {
      case AppointmentStatus.DRAFT:
        return appointmentStatusCheck('draft', 'แบบร่างขอแก้ไข');
      case AppointmentStatus.PENDING:
        return appointmentStatusCheck('pending', 'รอเห็นชอบ/รออนุมัติขอแก้ไข');
      case AppointmentStatus.APPROVED:
        return appointmentStatusCheck('approved', 'เห็นชอบ/อนุมัติ');
      case AppointmentStatus.REJECTED:
        return appointmentStatusCheck('reject', 'ส่งกลับแก้ไข');
      case AppointmentStatus.CANCELLED:
        return appointmentStatusCheck('cancel-pp', 'ยกเลิก');
      default:
        return appointmentStatusCheck('', 'ยังไม่ดำเนินการ');
    }
  }, []);

  const renderStatusPreProcurement = useCallback((statusValue: string) => {
    switch (statusValue) {
      case PreProcurmentStatus.DRAFT:
        return preProcurementStatusCheck('draft', 'ร่าง');
      case PreProcurmentStatus.PENDING:
        return preProcurementStatusCheck('pending', 'รออนุมัติ');
      case PreProcurmentStatus.APPROVED:
        return preProcurementStatusCheck('approved', 'อนุมัติ');
      case PreProcurmentStatus.ANNOUNCEMENT:
        return preProcurementStatusCheck('announcement', 'เผยแพร่');
      case PreProcurmentStatus.REJECTED:
        return preProcurementStatusCheck('reject', 'ส่งกลับแก้ไข');
      default:
        return preProcurementStatusCheck('', 'ยังไม่ดำเนินการ');
    }
  }, []);

  const renderJorPor0405Approve = useCallback((statusValue: string) => {
    switch (statusValue) {
      case JorPor04Approve.WAITING_ACCEPTOR:
        return jorPor04ApproveStatusCheck('waiting-approve-plan', 'รออนุมัติ');
      case JorPor04Approve.ACCEPTED:
        return jorPor04ApproveStatusCheck('approve-plan', 'เห็นชอบ');
      case JorPor04Approve.REJECT:
        return jorPor04ApproveStatusCheck('reject-plan', 'ส่งกลับแก้ไข');
      case JorPor04Approve.DARFT:
        return jorPor04ApproveStatusCheck('draft-plan', 'แบบร่าง');
      case JorPor04Approve.NotYet:
        return jorPor04ApproveStatusCheck('draft-plan', 'ยังไม่ดำเนินการ');
      case JorPor04Approve.NULL:
        return jorPor04ApproveStatusCheck('draft-plan', 'ยังไม่ดำเนินการ');
      case JorPor04Approve.ASIGN_JORPOR:
        return jorPor04ApproveStatusCheck('assigned', 'มอบหมายสำเร็จ');
      case JorPor04Approve.ALL:
        return jorPor04ApproveStatusCheck('draft-plan', 'ทั้งหมด');
      case JorPor04Approve.APPORVE:
        return jorPor04ApproveStatusCheck('approve-plan', 'เห็นชอบ/อนุมัติ');
      case JorPor04Approve.PENDING:
        return jorPor04ApproveStatusCheck('waiting-approve-plan', 'รออนุมัติ');
      case JorPor04Approve.WAITING_ACCEPT:
        return jorPor04ApproveStatusCheck('waiting-approve-plan', 'รอเห็นชอบ');
      case JorPor04Approve.WaitingComitteeApprove:
        return jorPor04ApproveStatusCheck('waiting-approve-plan', 'อยู่ระหว่าง คกก. เห็นชอบ');
      case JorPor04Approve.DraftAcceptor:
        return jorPor04ApproveStatusCheck('draft-plan', 'อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ');
      case JorPor04Approve.WaitingForDirectorJorPorAssign:
        return jorPor04ApproveStatusCheck('waiting-approve-plan', 'อยู่ระหว่างรอ ผอ.จพ. มอบหมาย');
      case JorPor04Approve.Cancelled:
        return jorPor04ApproveStatusCheck('reject-plan', 'ยกเลิก');
      case JorPor04Approve.None:
        return jorPor04ApproveStatusCheck('draft-plan', 'ยังไม่ดำเนินการ');
      default:
        return jorPor04ApproveStatusCheck('', 'ยังไม่ดำเนินการ');
    }
  }, []);

  const renderStatusSupplyMethod = useCallback((statusValue: string) => {
    switch (statusValue) {
      case SupplyMethodEnum.MethodId60:
        return processSupplyMethodCheck('announcement', '');
      case SupplyMethodEnum.MethodId80:
        return processSupplyMethodCheck('announcement', '');
      case SupplyMethodEnum.All:
        return processSupplyMethodCheck('all', '');
      default:
        return processSupplyMethodCheck('', 'ยังไม่ดำเนินการ');
    }
  }, []);

  const renderStatusApprovedAppointment = useCallback((statusValue: string) => {
    switch (statusValue) {
      case ProcessStatus.DRAFT:
        return appointmentStatusCheck('draft', 'ร่าง');
      case ProcessStatus.PENDING:
        return appointmentStatusCheck('pending', 'รออนุมัติ');
      case ProcessStatus.APPROVED:
        return appointmentStatusCheck('approved', 'เห็นชอบ/อนุมัติ');
      case ProcessStatus.REJECTED:
        return appointmentStatusCheck('rejected', 'ไม่เห็นชอบ/อนุมัติ');
      default:
        return appointmentStatusCheck('', 'ยังไม่ดำเนินการ');
    }
  }, []);

  const renderStatusTor = useCallback((statusValue: string) => {
    switch (statusValue) {
      case AppointTorStatus.Draft:
        return appointTorStatusCheck('draft', 'แบบร่าง');
      case AppointTorStatus.DraftTor:
        return appointTorStatusCheck('pending', 'อยู่ระหว่างร่าง TOR');
      case AppointTorStatus.WaitingForCommitteeApprove:
        return appointTorStatusCheck('pending', 'อยู่ระหว่าง คกก. เห็นชอบ');
      case TorStatusConstant.WaitingForDirectorJorPorAssign:
        return appointTorStatusCheck('pending', 'อยู่ระหว่างรอ ผอ.จพ. มอบหมาย');
      case TorStatusConstant.WaitingForAssignResponsible:
        return appointTorStatusCheck('pending', 'อยู่ระหว่างรอมอบหมายผู้รับผิดชอบ');
      case AppointTorStatus.DraftSectionApprover:
        return appointTorStatusCheck('pending', 'อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ');
      case AppointTorStatus.WaitingForApprove:
        return appointTorStatusCheck('pending', 'อยู่ระหว่างเห็นชอบ/อนุมัติ');
      case AppointTorStatus.Approved:
        return appointTorStatusCheck('approved', 'เห็นชอบอนุมัติ');
      case AppointTorStatus.Rejected:
        return appointTorStatusCheck('reject', 'ส่งกลับแก้ไข');
      case AppointTorStatus.Cancelled:
        return appointTorStatusCheck('cancel-pp', 'ยกเลิก');
      default:
        return appointTorStatusCheck('draft', 'ยังไม่ได้ดำเนินการ');
    }
  }, []);

  const renderStatusAppointmentOrder = useCallback((statusValue: string) => {
    switch (statusValue) {
      case AppointmentOrderStatus.DRAFT:
        return appointTorStatusCheck('draft', 'แบบร่าง');
      case AppointmentOrderStatus.PENDING:
        return appointTorStatusCheck('pending', 'รอเห็นชอบ/รออนุมัติ');
      case AppointmentOrderStatus.APPROVED:
        return appointTorStatusCheck('approved', 'เห็นชอบ/อนุมัติ');
      case AppointmentOrderStatus.REJECTED:
        return appointTorStatusCheck('reject', 'ส่งกลับแก้ไข');
      case AppointmentOrderStatus.CANCELLED:
        return appointTorStatusCheck('cancel-pp', 'ยกเลิก');
      default:
        return appointTorStatusCheck('draft', 'ยังไม่ได้ดำเนินการ');
    }
  }, []);

  const renderStatusInvitationOrder = useCallback((statusValue: string) => {
    switch (statusValue) {
      case InvitationOrderStatus.Draft:
        return appointTorStatusCheck('draft', 'แบบร่าง');
      case InvitationOrderStatus.WaitingForApprove:
        return appointTorStatusCheck('pending', 'อยู่ระหว่างเห็นชอบ/อนุมัติ');
      case InvitationOrderStatus.Approved:
        return appointTorStatusCheck('approved', 'เห็นชอบ/อนุมัติ');
      case InvitationOrderStatus.Rejected:
        return appointTorStatusCheck('reject', 'ส่งกลับแก้ไข');
      case InvitationOrderStatus.Cancelled:
        return appointTorStatusCheck('cancel-pp', 'ยกเลิก');
      default:
        return appointTorStatusCheck('draft', 'ยังไม่ได้ดำเนินการ');
    }
  }, []);

  const renderStatusPurchaseOrder = useCallback((statusValue: string) => {
    switch (statusValue) {
      case PurchaseOrderStatus.DRAFT:
        return processStatusCheck('draft', 'ระหว่างรอลงนาม');
      case PurchaseOrderStatus.PENDING:
        return processStatusCheck('waiting-approve-plan', 'ส่งลงนาม');
      case PurchaseOrderStatus.APPROVED:
        return processStatusCheck('approve-plan', 'ลงนามแล้ว');
      case PurchaseOrderStatus.REJECTED:
        return processStatusCheck('reject-plan', 'ส่งกลับแก้ไข');
      default:
        return processStatusCheck('', 'ระหว่างรอลงนาม');
    }
  }, []);

  const renderStatusAssignContract = useCallback((statusValue: string) => {
    switch (statusValue) {
      case AssignContractStatus.DRAFT:
        return processStatusCheck('draft', 'รอดำเนินการ');
      case AssignContractStatus.ASSIGNED:
        return processStatusCheck('approve-plan', 'มอบหมายแล้ว');
      default:
        return processStatusCheck('', 'รอดำเนินการ');
    }
  }, []);

  const renderStatusPurchaseOrderApproval = useCallback((statusValue: string) => {
    switch (statusValue) {
      case PurchaseOrderApproval.Assigned:
        return processStatusCheck('approved', 'มอบหมายงานแล้ว');
      case PurchaseOrderApproval.WaitingForSign:
        return processStatusCheck('pending', 'รอลงนาม');
      case PurchaseOrderApproval.Draft:
        return processStatusCheck('draft', 'ร่าง');
      case PurchaseOrderApproval.Signed:
        return processStatusCheck('approved', 'ลงนามแล้ว');
      case PurchaseOrderApproval.Rejected:
        return processStatusCheck('reject', 'ส่งกลับแก้ไข');
      default:
        return processStatusCheck('draft', 'รอดำเนินการ');
    }
  }, []);

  const renderStatusInvitedContract = useCallback((statusValue: string) => {
    switch (statusValue) {
      case InvitedContractStatus.INVITED:
        return processStatusCheck('approved', 'อนุมัติ');
      case InvitedContractStatus.WAITINGFORAPPROVE:
        return processStatusCheck('pending', 'รอเห็นชอบ/รออนุมัติ');
      case InvitedContractStatus.DRAFT:
        return processStatusCheck('draft', 'แบบร่าง');
      case InvitedContractStatus.REJECTED:
        return processStatusCheck('reject', 'ส่งกลับแก้ไข');
      default:
        return processStatusCheck('draft', 'รอดำเนินการ');
    }
  }, []);

  const renderInvitedContract = useCallback((statusValue: string, val?: string) => {
    switch (statusValue) {
      case InvitedContractStatus.INVITED:
        return processStatusCheck('approved', val!);
      default:
        return processStatusCheck('draft', val!);
    }
  }, []);

  const renderStatusCM03 = useCallback((statusValue: string) => {
    switch (statusValue) {
      case CM03Status.ContractAgreement:
        return processStatusCheck('draft', 'ร่างสัญญา');
      case CM03Status.ContractManagement:
        return processStatusCheck('pending', 'บริหารสัญญา');
      case CM03Status.ContractWarranty:
        return processStatusCheck('pending', 'รับประกันสัญญา');
      case CM03Status.ContractComplete:
        return processStatusCheck('approved', 'สิ้นสุดสัญญา');
      case CM03Status.ContractCancelled:
        return processStatusCheck('reject', 'ยกเลิกสัญญา');
      default:
        return processStatusCheck('draft', 'รอดำเนินการ');
    }
  }, []);

  const renderStatusDraftContract = useCallback((statusValue: string) => {
    switch (statusValue) {
      case ContractAgreementStatus.Draft:
        return processStatusCheck('draft', 'แบบร่าง');
      case ContractAgreementStatus.WaitingForApprove:
        return processStatusCheck('pending', 'รอตรวจสอบร่างสัญญา');
      case ContractAgreementStatus.Rejected:
        return processStatusCheck('reject', 'ส่งกลับแก้ไขร่างสัญญา');
      case ContractAgreementStatus.WaitingForAttachSignedDocument:
        return processStatusCheck('approved', 'ตรวจสอบร่างสัญญาแล้ว');
      case ContractAgreementStatus.Signed:
        return processStatusCheck('approved', 'ลงนามแล้ว');
      case ContractAgreementStatus.WaitingForDirectorJorPorAssign:
        return processStatusCheck('pending', 'รอ ผอ.จพ. มอบหมาย');
      case ContractAgreementStatus.WaitingForAssignResponsible:
        return processStatusCheck('pending', 'รอมอบหมายผู้รับผิดชอบ');
      case ContractAgreementStatus.DraftSectionApprover:
        return processStatusCheck('pending', ' อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ');
      case ContractAgreementStatus.WaitingForSign:
        return processStatusCheck('pending', 'รออนุมัติ/เห็นชอบ');
      case ContractAgreementStatus.Approved:
        return processStatusCheck('approved', 'เห็นชอบ/อนุมัติ');
      default:
        return processStatusCheck('draft', 'แบบร่าง');
    }
  }, []);

  const renderStatusContractCompleteReport = useCallback((statusValue: string) => {
    switch (statusValue) {
      case ContractCompleteReportStatus.Draft:
        return processStatusCheck('draft', 'แบบร่าง');
      case ContractCompleteReportStatus.WaitingForApprove:
        return processStatusCheck('pending', 'รอเห็นชอบ/อนุมัติ');
      case ContractCompleteReportStatus.Rejected:
        return processStatusCheck('reject', 'ส่งกลับแก้ไข');
      case ContractCompleteReportStatus.Approved:
        return processStatusCheck('approved', 'อนุมัติ');
      default:
        return processStatusCheck('draft', 'แบบร่าง');
    }
  }, []);

  const renderStatusMedianPrice = useCallback((statusValue: string) => {
    switch (statusValue) {
      case MedianPriceStatus.Draft:
        return processStatusCheck('draft', 'แบบร่าง');
      case MedianPriceStatus.WaitingForCommitteeApprove:
        return processStatusCheck('pending', 'อยู่ระหว่างคณะกรรมการราคากลางเห็นชอบ');
      case MedianPriceStatus.CommitteeApproved:
        return processStatusCheck('approved', 'คณะกรรมการราคากลางเห็นชอบ/อนุมัติ');
      case MedianPriceStatus.WaitingForApprove:
        return processStatusCheck('pending', 'อยู่ระหว่างเห็นชอบ/อนุมัติ');
      case MedianPriceStatus.Approved:
        return processStatusCheck('approved', 'เห็นชอบ/อนุมัติ');
      case MedianPriceStatus.Rejected:
        return processStatusCheck('reject', 'ส่งกลับแก้ไข');
      case MedianPriceStatus.Cancelled:
        return processStatusCheck('reject', 'ยกเลิก');
      case MedianPriceStatus.WaitingForJorPorDirector:
        return processStatusCheck('pending', 'อยู่ระหว่าง ผอ.จพ. มอบหมาย');
      case MedianPriceStatus.WaitingForAssignResponsible:
        return processStatusCheck('pending', 'อยู่ระหว่างมอบหมายผู้รับผิดชอบ');
      case MedianPriceStatus.DraftSectionApprover:
        return processStatusCheck('pending', 'อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ');
      default:
        return processStatusCheck('draft', 'รอดำเนินการ');
    }
  }, []);

  const renderContractStatus = useCallback((statusValue: string) => {
    switch (statusValue) {
      case CollateralContractStatus.ContractWarranty:
        return processStatusCheck('pending', 'อยู่ระหว่างรับประกันสัญญา');
      case CollateralContractStatus.ContractComplete:
        return processStatusCheck('approved', 'สิ้นสุดภาระผูกพัน');
      default:
        return processStatusCheck('draft', 'รอดำเนินการ');
    }
  }, []);

  const status = (statusValue: ProjectName | ProcessStatus | string, showValue?: string) => {
    switch (type) {
      case StatusType.PROJECT:
        return renderStatusProject(statusValue);
      case StatusType.PROCESS:
        return renderStatusProcess(statusValue);
      case StatusType.ANNOUNCEPLANPROCESS:
        return renderStatusAnnouncementProcess(statusValue);
      case StatusType.APPOINTMENT:
        return renderStatusAppointment(statusValue);
      case StatusType.APPOINTMENTCANCEL:
        return renderStatusAppointmentCancel(statusValue);
      case StatusType.APPOINTMENTEDIT:
        return renderStatusAppointmentEdit(statusValue);
      case StatusType.PRE_PROCURMENT:
        return renderStatusPreProcurement(statusValue);
      case StatusType.JOR_POR_04_05_APPROVER:
        return renderJorPor0405Approve(statusValue);
      case StatusType.SUPPLYMETHOD:
        return renderStatusSupplyMethod(statusValue);
      case StatusType.APPROVEDAPPOINTMENT:
        return renderStatusApprovedAppointment(statusValue);
      case StatusType.TOR:
        return renderStatusTor(statusValue);
      case StatusType.APPOINTMENTORDER:
        return renderStatusAppointmentOrder(statusValue);
      case StatusType.INVITATIONORDER:
        return renderStatusInvitationOrder(statusValue);
      case StatusType.PURCHASEORDER:
        return renderStatusPurchaseOrder(statusValue);
      case StatusType.ASSIGN_CONTRACT:
        return renderStatusAssignContract(statusValue);
      case StatusType.PURCHASE_ORDER_APPROVAL:
        return renderStatusPurchaseOrderApproval(statusValue);
      case StatusType.INVITEDCONTRACTSTATUS:
        return renderStatusInvitedContract(statusValue);
      case StatusType.INVITEDCONTRACT:
        return renderInvitedContract(statusValue, showValue);
      case StatusType.DRAFT_CONTRACT:
        return renderStatusDraftContract(statusValue);
      case StatusType.JorPorComment:
        return renderStatusJorProComment(statusValue);
      case StatusType.MEDIANPRICE:
        return renderStatusMedianPrice(statusValue);
      case StatusType.ContractCompleteReport:
        return renderStatusContractCompleteReport(statusValue);
      case StatusType.CM03Status:
        return renderStatusCM03(statusValue);
      case StatusType.ContractStatus:
        return renderStatusContract(statusValue);
      case StatusType.ContractReturnCollateral:
        return renderStatusContractReturnCollateral(statusValue);
      case StatusType.CollateralContractStatus:
        return renderContractStatus(statusValue);
      default:
        return projectStatusCheck('', 'ยังไม่ดำเนินการ');
    }
  };

  const renderStatusJorProComment = useCallback((statusValue: string) => {
    switch (statusValue) {
      case ContractAgreementStatus.Draft:
        return processStatusCheck('draft', 'แบบร่าง');
      case ContractAgreementStatus.WaitingForApprove:
        return processStatusCheck('pending', 'รออนุมัติ/เห็นชอบ');
      case ContractAgreementStatus.Rejected:
        return processStatusCheck('reject', 'ส่งกลับแก้ไข');
      case ContractAgreementStatus.WaitingForDirectorJorPorAssign:
        return processStatusCheck('pending', 'รอ ผอ.จพ. มอบหมาย');
      case ContractAgreementStatus.WaitingForAssignResponsible:
        return processStatusCheck('pending', 'รอมอบหมายผู้รับผิดชอบ');
      case ContractAgreementStatus.DraftSectionApprover:
        return processStatusCheck('pending', ' อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ');
      case ContractAgreementStatus.Approved:
        return processStatusCheck('approved', 'เห็นชอบ/อนุมัติ');
      default:
        return processStatusCheck('draft', 'แบบร่าง');
    }
  }, []);

  const isMD = useMemo(() => positionCode === 'MD', [positionCode]);

  return (
    <div className='status'>
      {status(value, showValue)}
    </div>
  );
}
