import { PL0207Criteria } from 'models/PL/PL0207Models';
import { LoaderFunctionArgs } from 'react-router';
import { shareValue } from 'services';
import { PL0207Service } from 'services/PL';

const PL0207Loader = async () => {
  const modelInit = {
    statusAssigned: true,
    statusDraftRecordDocument: true,
    statusWaitingAcceptor: true,
    statusApproveAcceptor: true,
    statusRejectAcceptor: true,
    statusAll: true,
    supplyMethodAll: true,
  } as PL0207Criteria;

  const [DepartmentItems, SupplyMethodTypeItems, { data, status }] = await Promise.all([
    shareValue.GetDDL('Department'),
    shareValue.GetDDL('SupplyMethodType'),
    PL0207Service.getListAsync(1, 10, modelInit),
  ]);

  return {
    departmentList: DepartmentItems,
    supplyMethodTypeList: SupplyMethodTypeItems,
    loaderDataTable: data,
    status,
  };
};

const PL0207DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const [DepartmentItems, SupplyMethodTypeItems, SupplyMethodTypeNameItems, positionItems, { data, status }] = await Promise.all([
    shareValue.GetDDL('Department'),
    shareValue.GetDDL('SupplyMethodType'),
    shareValue.GetDDL('SupplyMethodName'),
    shareValue.GetDDL('Position'),
    PL0207Service.getDetailAsync(id),
  ]);
  return {
    departmentList: DepartmentItems,
    supplyMethodTypeList: SupplyMethodTypeItems,
    SupplyMethodTypeNameList: SupplyMethodTypeNameItems,
    positionList: positionItems,
    data: data.data,
    status,
  };
};

export {
  PL0207Loader,
  PL0207DetailLoader,
};
