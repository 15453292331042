import {
  AppointmentStatus,
  DatePicker,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import { ItemModel } from 'models';
import {
  AppointmentApprovalResponseModel,
  GetAppointmentApprovalListRequest,
  GetAppointmentApprovalListResponse,
} from 'models/PP/PP0301Models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import PP0301Service from 'services/PP/PP0301Service';
import { formatMonthAndThaiYearWithSlash } from 'utils';
import { useForm } from '../../../utils/useForm';

type Loader = { departmentDDL: ItemModel[] };

export default function PP0301() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [formSearch, setFormSearch] = useState<GetAppointmentApprovalListRequest>({} as GetAppointmentApprovalListRequest);
  const [appointmentApprovalData, setAppointmentApprovalData] = useState<AppointmentApprovalResponseModel>({} as AppointmentApprovalResponseModel);
  const { departmentDDL } = useLoaderData() as Loader;
  const searchAppointmentApprovalList = useCallback(async (searchModel: GetAppointmentApprovalListRequest) => {
    if (!searchModel.page) {
      searchModel.page = page;
    }
    if (!searchModel.size) {
      searchModel.size = size;
    }
    const { data, status } = await PP0301Service.getAppointmentApprovalListAsync(searchModel);
    if (status !== 200) {
      return;
    }

    setAppointmentApprovalData(data);
  }, []);

  useEffect(() => {
    searchAppointmentApprovalList({ page, size });
  }, [page, searchAppointmentApprovalList, size]);

  useEffect(() => {
    searchAppointmentApprovalList(formSearch);
  }, [formSearch]);

  return (
    <div className="m01">
      <PP0301Header />
      <Criteria
        setForm={setFormSearch}
        departmentList={departmentDDL}
        page={page}
        size={size}
      />
      <DataTable
        appointmentApprovalData={appointmentApprovalData.data}
        total={appointmentApprovalData.totalCount}
        setPage={setPage}
        setSize={setSize}
      />
    </div>
  );
}

function PP0301Header() {
  return (
    <>
      <h4 className="text-primary m-0">อนุมัติแต่งตั้ง / เปลี่ยนแปลง / ยกเลิก</h4>
      <hr />
    </>
  );
}

interface FormInput {
  apointMemorandumDate: Date;
  departmentId: string;
  departmentName: string;
  projectName: string;
  subject: string;
  commiteeTorName: string;
  responsiblePerson: string;
  status: string;
}

interface CriteriaProps {
  setForm: (form: GetAppointmentApprovalListRequest) => void;
  departmentList: ItemModel[];
  page: number;
  size: number;
}

function Criteria(props: CriteriaProps) {
  const [appointApprovalMemorandumDate, setAppointApprovalMemorandumDate] = useState<Date>();
  const [status, setStatus] = useState('');
  const [departmentId, setDepartmentId] = useState('');

  function clearFormSearch() {
    handleClear();
    setDepartmentId('');
    setStatus('');
    setAppointApprovalMemorandumDate(undefined);
  }

  const { handleSubmit, handleChange, data: formData, handleClear } = useForm<FormInput>({
    initialValues: {
      apointMemorandumDate: undefined,
      departmentId: undefined,
      departmentName: '',
      projectName: undefined,
      subject: undefined,
      commiteeTorName: undefined,
      responsiblePerson: undefined,
      status: '',
    },
    validations: {},
    onSubmit: async () => {
      let apmDate: Date | undefined;
      if (appointApprovalMemorandumDate) {
        apmDate = new Date(appointApprovalMemorandumDate);
      }

      const searchModel: GetAppointmentApprovalListRequest = {
        page: props.page,
        size: props.size,
        apointMemorandumDate: apmDate,
        departmentId,
        projectName: formData.projectName,
        subject: formData.subject,
        commiteeTorName: formData.commiteeTorName,
        responsiblePerson: formData.responsiblePerson,
        status,
      };
      props.setForm(searchModel);
    },
  });

  const statusOptions: ItemModel[] = [
    { value: AppointmentStatus.PENDING, label: 'รออนุมัติ' },
    { value: AppointmentStatus.APPROVED, label: 'อนุมัติ' },
    { value: AppointmentStatus.REJECTED, label: 'ส่งกลับแก้ไข' },
  ];

  return (
    <Form onSubmit={handleSubmit} id="formPP0301">
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <DatePicker
              label="วันที่เอกสารบันทึกข้อความแต่งตั้ง"
              value={appointApprovalMemorandumDate}
              onChange={setAppointApprovalMemorandumDate}
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>ฝ่าย/สำนัก</Form.Label>
            <InputGroup hasValidation>
              <Selector
                items={props.departmentList}
                value={`${departmentId}`}
                onChange={(value) => (setDepartmentId(value.toString()))}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>ชื่อโครงการ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="ชื่อโครงการ"
                value={formData.projectName || ''}
                onChange={handleChange<string>('projectName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>เรื่อง</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="เรื่อง"
                value={formData.subject || ''}
                onChange={handleChange<string>('subject', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>คณะกรรมการจัดทำร่างขอบเขตของงาน</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="คณะกรรมการจัดทำร่างขอบเขตของงาน"
                value={formData.commiteeTorName || ''}
                onChange={handleChange<string>('commiteeTorName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>ผู้จัดทำ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="ผู้จัดทำ"
                value={formData.responsiblePerson || ''}
                onChange={handleChange<string>('responsiblePerson', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>สถานะ</Form.Label>
            <InputGroup hasValidation>
              <Selector
                items={statusOptions}
                value={`${status}`}
                onChange={(value) => (setStatus(value.toString()))}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <div className="d-flex gap-2">
          <Button form="formPP0301" type="submit" variant="primary" className="d-flex align-items-center gap-2">
            <FaSearch />ค้นหา
          </Button>
          <Button variant="outline-primary" onClick={() => clearFormSearch()} form="formPP0301" type="submit" className="d-flex align-items-center gap-2">
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
    </Form>
  );
}

interface DataTableProps {
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  total: number;
  appointmentApprovalData: GetAppointmentApprovalListResponse[];
}

function DataTable(props: DataTableProps) {
  useNavigate();

  return (
    <Table className="mt-4">
      <thead>
        <tr className="text-center">
          <th>ฝ่าย/สำนัก</th>
          <th>ชื่อแผน</th>
          <th>ชื่อเรื่อง</th>
          <th>วิธีจัดหา</th>
          <th>วันที่เอกสารบันทึกข้อความแต่งตั้ง</th>
          <th>ผู้จัดทำ</th>
          <th>สถานะ</th>
          <th>รายละเอียด</th>
        </tr>
      </thead>
      <tbody>
        {props.appointmentApprovalData?.map((data, index) => (
          <tr className="text-center" key={index}>
            <td>{data.department}</td>
            <td>{data.planName}</td>
            <td>{data.subject}</td>
            <td className="text-center"><p> {data.supplyMethod} </p></td>
            <td className="text-center">{formatMonthAndThaiYearWithSlash(data.memorandumDate)}</td>
            <td className="text-center">{data.createdInfo_ByUserFullName}</td>
            <td className="text-center">{Status({ value: data.status, type: StatusType.APPOINTMENT })}</td>
            <td className="text-center">
              <a className="btn-link" href={`pp0301/detail/${data.id}`}>รายละเอียด</a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
