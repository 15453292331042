import {
  AppointmentOrderStatus,
  AppointmentStatus,
  Card,
  Check,
  Input,
  InputTextArea,
  Modal,
  SearchBuNameModal,
  Table,
  ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import {
  ProcurementAppointmentOrderStatus,
  ProcurementAppointmentOrderStatusDetail,
  StatusDetailApproved,
} from 'components/ProcurementAppointmentOrderStatus';
import Cookie from 'cookie-universal';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { sectionApproverModel } from 'models/delegateModel';
import {
  approverSectionsModel,
  documentsModel,
  UpdateAppointCommiteeModel,
} from 'models/procurementAppointmentOrderModel';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { BsArrowDownCircle } from 'react-icons/bs';
import {
  FaHistory,
  FaPlus,
  FaRegCheckCircle,
  FaSave,
  FaTrashAlt,
  FaChevronCircleDown,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import { account, shareValue } from 'services';
import procurementAppointmentOrderServices from 'services/procurementAppointmentOrderServices';
import {
  HttpStatusCode,
  submitForm,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { HistoryModal } from '../HistoryModal';
import UploadFile from '../UploadFile';
import { UserApproveModal } from '../UserApproveModal';
import { Context } from './index';
import { JorPorCode } from 'constant/basicInformation';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

export const sectionsType = [
  {
    sectionSequence: 100,
    sectionName: 'หัวหน้าส่วน',
  },
  {
    sectionSequence: 200,
    sectionName: 'ผู่ช่วย ผอ จพ.',
  },
  {
    sectionSequence: 300,
    sectionName: 'ผอ จพ.',
  },
  {
    sectionSequence: 400,
    sectionName: 'ผู้ช่วยกรรมการ',
  },
  {
    sectionSequence: 500,
    sectionName: 'รองกรรมการผู้จัดการ',
  },
  {
    sectionSequence: 600,
    sectionName: 'กรรมการผู้จัดการ',
  },
];

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const { data, setData } = useContext(Context);
  const [, setShow] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [approverSections, setApproverSections] = useState<approverSectionsModel[]>([]);
  const { userId, departmentCode } = useAppContext();
  const [showModals, setShowModals] = useState<boolean[]>([]);
  const [isDisabled, setisDisabled] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const cookies = Cookie();
  const userfullName = cookies.get('fullName') as string;
  const [showHistory, setShowHistory] = useState(false);
  const [showPendingAppointmentOrder, setShowPendingAppointmentOrder] = useState(false);
  const [showRejectedAppointmentOrder, setShowRejectedgAppointmentOrder] = useState(false);
  const [showApprovedAppointmentOrder, setShowApprovedAppointmentOrder] = useState(false);
  const [remark, setRemark] = useState('');
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('procurement-appointment-order/detail');
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const navigate = useNavigate();
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = Math.max.apply(null,
      data.approverSections?.map((x) => x.sectionSequence));

    const valueSequence = data.approverSections?.filter((a) => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  useEffect(() => {
    const paramId = location.pathname.slice(location.pathname.lastIndexOf('/'), location.pathname.length);
    setCurrentPath(location.pathname.replace(paramId, ''));

    onCheckApproverStatus();

    if (data.approverSections.length === 0) {
      onGetDefaultApprover();
    }
  }, []);

  const onGetDefaultApprover = async () => {
    const response = await account
      .getDefaultApproverByTopLeve(PROCESSTYPE.ApproveProcurementCommitteeProcess, data.planBudget);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: approverSectionsModel[] = [];
      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
        sectionDataGroup.push({
          sectionName: item.sectionName,
          level: item.sectionLevel,
          sectionSequence: item.sectionSequence,
          inRefCode: item.inRefCode,
          sectionNameTh: item.sectionName,
          sectionId: item.sectionId,
        } as sectionGroup);

        approver.push({
          sectionSequence: item.sectionSequence,
          sectionName: item.sectionName,
          sequence: item.sequence,
          userId: item.userId,
          fullName: item.fullName,
          position: item.positionName,
          department: item.departmentName,
          inRefCode: item.inRefCode,
          processType: item.processType,
          seqKey: '',
          remark: '',
          isDisabled: false,
          approvedDateAt: undefined,
          status: '',
          sectionLevel: item.sectionLevel,
          delegateUserId: item.delegateUserId,
          delegateEmployeeCode: item.delegateEmployeeCode,
          delegateFullName: item.delegateFullName,
          delegateDepartmentName: item.delegateDepartmentName,
          delegatePositionName: item.delegatePositionName,
        } as approverSectionsModel);
      });

      const uniqueNames = sectionDataGroup
        .filter((data, index) => sectionDataGroup
          .findIndex((d) => d.sectionSequence === data.sectionSequence) === index);

      if (data.approverSections === undefined || data.approverSections === null || data.approverSections.length === 0) {
        let inx = 0;
        let sectionSeq = 0;

        approver?.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
          if (sectionSeq !== item.sectionSequence) {
            inx = 1;
            sectionSeq = item.sectionSequence;
          }

          approver[index].sequence = inx;

          inx++;
        });

        setApproverSections(approver);
        setSections(uniqueNames);
      }
    }
  };

  useEffect(() => {
    if (data) {
      if (data.status === AppointmentOrderStatus.None || data.status === AppointmentStatus.DRAFT || data.status === AppointmentStatus.REJECTED) {
        setisDisabled(false);
      } else {
        setisDisabled(true);
      }

      if (data.approverSections && data.approverSections.length > 0) {
        setApproverSections(data.approverSections);

        const sectionDataGroup: sectionGroup[] = [];
        data.approverSections.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            inRefCode: item.inRefCode,
            sectionNameTh: item.sectionName,
            sectionId: item.sectionId,
          } as sectionGroup);
        });

        const uniqueNames = sectionDataGroup
          .filter((data, index) => sectionDataGroup
            .findIndex((d) => d.sectionSequence === data.sectionSequence) === index);

        setSections(uniqueNames);
      }

      if (data.documents) {
        handleSetFile(data.documents);
      }
    }
  }, [data, userId]);

  const onSelectEmpApprover = (empId: string, name: string, position: string, department: string, index: number, sectionSequence?: number, sectionName?: string, inRefCode?: string) => {
    if (approverSections && approverSections.filter((x) => x.inRefCode === inRefCode && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const newData: approverSectionsModel = {
        id: undefined,
        sectionSequence: sectionSequence!,
        sectionName,
        userId: empId,
        sequence: approverSections.filter((x) => x.sectionSequence === sectionSequence).length + 1,
        fullName: name,
        position,
        approvedDateAt: undefined,
        isDisabled: false,
        remark: '',
        department,
        status: '',
        seqKey: `${sectionSequence?.toString()}${(approverSections.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        inRefCode: inRefCode!,
        processType: '',
        sectionId: '',
        sectionLevel: undefined,
      };
      setApproverSections((approverSections) => [...approverSections, newData]);
    }
  };

  const handlerOnChangeCheckbox = (value: boolean, seqKey: string) => {
    const data = [...approverSections];
    data.filter((x) => `${x.sectionSequence}${x.sequence}` === seqKey)[0].isDisabled = value;
    setApproverSections(data);
  };

  const handlerOnChangeRemark = (value: string, seqKey: string) => {
    const data = [...approverSections];
    data.filter((x) => `${x.sectionSequence}${x.sequence}` === seqKey)[0].remark = value;
    setApproverSections(data);
  };

  const removeApprover = (seqKey: string) => {
    const temp: approverSectionsModel[] = [...approverSections];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    setApproverSections(temp);
  };

  const onOpenModal = (index: number) => {
    setShowModals((showModals) => {
      const newModal = [...showModals];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideModal = (index: number) => {
    setShowModals((showModals) => {
      const newModal = [...showModals];
      newModal[index] = false;
      return newModal;
    });
  };

  const handleSetFile = (documents: documentsModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const getDataAppointmentOrderByIdAsync = async () => {
    const response = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(data.jorPor05Id, data.id);
    if (response.status === HttpStatusCode.OK) {
      if (data.files) {
        data.files = [];
      }
      setData(response.data);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await procurementAppointmentOrderServices.UploadAppointmentOrderAttachmentAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getDataAppointmentOrderByIdAsync();
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName) {
      setDelFiles((delFiles) => [...delFiles, docName]);
    }

    const delFile = [];
    delFile.push(docName);
    if (data && data.id) {
      const res = await procurementAppointmentOrderServices.deleteAppointmentOrderDocumentAsync(
        data.id,
        delFile,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('ลบไฟล์สำเร็จ');
        getDataAppointmentOrderByIdAsync();
      }
    }
  }, [files, data.id]);

  const onCheckApproverSectionsSubmitAsync = async () => {
    submitForm();

    let isHasError = false;
    sections.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((section) => {
      if (approverSections.filter((x) => x.sectionSequence === section.sectionSequence).length === 0) {
        toast.warn(`กรุณาระบุ${section.sectionName}`);
        isHasError = true;
        return;
      }
    });

    if (isHasError) {
      return;
    }

    if (approverSections.filter((x) => x.isDisabled === true && (x.remark === null || x.remark === '' || x.remark === undefined)).length > 0) {
      toast.warn('กรุณาระบุหมายเหตุที่ไม่สามารถปฏิบัติงานได้');
      return;
    }

    setShowPendingAppointmentOrder(true);
  };

  const onSubmitAsync = async (isSendApprove: boolean) => {
    submitForm();

    const docUpload: File[] = [];
    if (files) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });
      data.docNameforDelete = delFiles;
      data.files = [...docUpload];
    }

    let isHasError = false;
    sections.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((section) => {
      if (approverSections.filter((x) => x.sectionSequence === section.sectionSequence).length === 0) {
        toast.warn(`กรุณาระบุ${section.sectionName}`);
        isHasError = true;
        return;
      }
    });

    if (isHasError) {
      return;
    }

    if (approverSections.filter((x) => x.isDisabled === true && (x.remark === null || x.remark === '' || x.remark === undefined)).length > 0) {
      toast.warn('กรุณาระบุหมายเหตุที่ไม่สามารถปฏิบัติงานได้');
      return;
    }

    if (data && data.id) {
      const httpStatus = HttpStatusCode.OK;

      const updateDataModel: UpdateAppointCommiteeModel = {
        id: data.id,
        planId: data.planId,
        planNumber: data.planNumber,
        planName: data.planName,
        torId: data.torId,
        jorPor04Number: data.jorPor04Number,
        jorPor05Id: data.jorPor05Id,
        memorandumDate: data.memorandumDate,
        departmentId: data.departmentId,
        supplyMethodType: data.supplyMethodType,
        supplyMethod: data.supplyMethod,
        supplyMethodSpecialType: data.supplyMethodSpecialType,
        budgetYear: data.budgetYear,
        planBudget: data.planBudget,
        expectingProcurementAt: data.expectingProcurementAt,
        subject: data.subject,
        sourceAndReasons: data.sourceAndReasons,
        status: data.status,
        deliveryQuantity: data.deliveryQuantity,
        deliveryUnit: data.deliveryUnit,
        committeeProcurements: data.committeeProcurements,
        dutyProcurements: data.dutyProcurements,
        committeeInspectors: data.committeeInspectors,
        dutyInspectors: data.dutyInspectors,
        approverSections,
        files: docUpload,
        updatedByUserFullName: userfullName,
        docNameforDelete: data.docNameforDelete,
      };

      if (httpStatus === HttpStatusCode.OK) {
        const res = await procurementAppointmentOrderServices.updateAppointCommiteeAsync(updateDataModel);
        if (data.docNameforDelete === undefined) data.docNameforDelete = [];

        if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
          setShow(false);

          if (isSendApprove) {
            const response = await procurementAppointmentOrderServices.submitPendingAppointmentOrderAsync(data.id);
            setShowPendingAppointmentOrder(false);
            if (response.status === HttpStatusCode.OK) {
              toast.success('ส่งอนุมัติสำเร็จ');
              onClickNext();
            } else {
              toast.error('ส่งอนุมัติไม่สำเร็จ');
            }
          } else {
            toast.success('บันทึกข้อมูลสำเร็จ');
          }

          const res = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(data.jorPor05Id, data.id);
          if (res.status === HttpStatusCode.OK) {
            if (data.files) {
              data.files = [];
            }
            setData(res.data);
          }

          navigate(`${location.pathname}`);
        } else {
          toast.error(res.statusText);
        }
      }
    }
  };

  const onRejectedAppointmentOrderSubmit = async () => {
    if (remark === null || remark === '') {
      return toast.warn('กรุณากรอกเหตุผลส่งกลับแก้ไข');
    }

    const response = await procurementAppointmentOrderServices.submitRejectedAppointmentOrderAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK) {
      toast.success('ส่งกลับแก้ไขสำเร็จ');
      if (response.status === HttpStatusCode.OK) {
        const res = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(data.jorPor05Id, data.id);
        setData(res.data);
      }
      navigate(`${location.pathname}`);
    } else {
      toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
    }

    setShowRejectedgAppointmentOrder(false);
  };

  const onApprovedAppointmentOrderSubmit = async () => {
    const response = await procurementAppointmentOrderServices.submitApprovedAppointmentOrderAsync(data.id);

    if (response.status === HttpStatusCode.OK) {
      toast.success('อนุมัติสำเร็จ');
      if (response.status === HttpStatusCode.OK) {
        const res = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(data.jorPor05Id, data.id);
        setData(res.data);
      }
      navigate(`${location.pathname}`);
    }

    setShowApprovedAppointmentOrder(false);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <Title
              text='ผู้มีอำนาจเห็นชอบ'
              className='fs-5 text-primary'
            />
            {openCollapse ? (
              <ArrowCollapse onClick={() => setOpenCollapse(!openCollapse)} openCollapse={openCollapse} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse(true)} openCollapse={openCollapse} />)}
          </div>
          <div className='d-flex gap-2'>
            <ProcurementAppointmentOrderStatus
              value={data.status}
            />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>

        <Collapse in={openCollapse}>
          <div>
            <Row className='mt-3 align-items-center gap-5'>
              {
                sections.length > 0 ? (
                  sections
                    .sort((a, b) => a.sectionSequence - b.sectionSequence)
                    .map((section, index) => (
                      <Col sm={12} className='ps-5' key={index}>
                        <div className='d-flex justify-content-between mb-1'>
                          <p className='fs-5 text-primary'>{section.sectionName}</p>
                          <Button
                            variant='outline-primary'
                            disabled={isDisabled || !data.isResponsible}
                            onClick={() => onOpenModal(index)}
                          >
                            <FaPlus className='me-1' />
                            {' '}
                            เพิ่มรายชื่อ
                          </Button>
                        </div>
                        <Table total={1} hidePagination>
                          <thead>
                            <tr>
                              <th style={{ width: 50 }}>ลำดับ</th>
                              <th style={{ width: 400 }}>ชื่อ-นามสกุล</th>
                              <th style={{ width: 300 }}>ฝ่าย/สำนัก</th>
                              <th style={{ width: 300 }}>ตำแหน่ง</th>
                              <th style={{ width: 100 }}>สถานะ</th>
                              <th style={{ width: 150 }}>วันที่เห็นชอบ</th>
                              <th style={{ width: 100 }}>ไม่สามารถปฏิบัติงานได้</th>
                              <th style={{ width: 200 }}>หมายเหตุ</th>
                              <th style={{ width: 25 }} />
                            </tr>
                          </thead>
                          <tbody>
                            {approverSections
                              .filter((x) => x.sectionSequence === section.sectionSequence)
                              .sort((a, b) => a.sequence - b.sequence)
                              .map((data, i) => (
                                // <tr className='text-center' key={`${data.sectionSequence}${data.sequence}`}>
                                <tr className='text-center' key={i}>
                                  <td>{i + 1}</td>
                                  <td className='text-start'>{data.fullName}</td>
                                  <td className='text-start'>{data.department}</td>
                                  <td className='text-start'>{data.position}</td>
                                  <td className='text-center'>
                                    {isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ? <StatusDetailApproved value={data.status} /> : <ProcurementAppointmentOrderStatusDetail value={data.status} />}
                                  </td>
                                  <td className='text-center'>{data.approvedDateAt ? thaiFormatDateWithSlash(data.approvedDateAt) : ''}</td>
                                  <td>
                                    <Check
                                      className='d-inline-block'
                                      value={data.isDisabled}
                                      onChange={(val) => handlerOnChangeCheckbox(val, `${data.sectionSequence}${data.sequence}`)}
                                      disabled={isDisabled}
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      value={data.remark}
                                      onChange={(val) => handlerOnChangeRemark(val, `${data.sectionSequence}${data.sequence}`)}
                                      rule={{ required: data.isDisabled }}
                                      disabled={!data.isDisabled || isDisabled}
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      onClick={() => removeApprover(`${data.sectionSequence}${data.sequence}`)}
                                      variant='danger'
                                      disabled={isDisabled}
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                        {/* <UserApproveModal
                          show={showModals[index]}
                          onHide={() => onHideModal(index)}
                          onSelectItem={onSelectEmpApprover}
                          sectionSequence={section.sectionSequence}
                          sectionName={section.sectionName}
                          inRefCode={section.inRefCode}
                        /> */}
                        <SearchBuNameModal
                          total={userTotal}
                          show={showModals[index]}
                          onHide={() => onHideModal(index)}
                          onSelect={(id, name, department, position) => onSelectEmpApprover(id, name, position!, department!, index, section.sectionSequence, section.sectionName, section.inRefCode)}
                          data={users}
                          onSearch={onSearchUserAsync}
                          onPageChange={onPageChangeAsync}
                          departmentCode={departmentCode}
                          departmentDisabled={departmentCode !== JorPorCode.CODE}
                        />
                      </Col>
                    ))
                ) : (
                  <Col>
                    <span className='text-primary'>ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
                  </Col>
                )
              }
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12} lg={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              appointmentId={data.id}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <Button
          onClick={onClickBack}
          className='me-2 px-3'
          variant='outline-primary'
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          <div>

            {
              (((data.status === AppointmentOrderStatus.DRAFT && data.isResponsible) || (data.status === AppointmentOrderStatus.REJECTED && data.isResponsible))) && (
                <Button
                  onClick={() => onSubmitAsync(false)}
                  className='me-2 px-3'
                  variant='success'>
                  <FaSave className='me-2 pb-1 fs-5' />
                  บันทึก
                </Button>
              )
            }

            {
              ((data.status === AppointmentOrderStatus.DRAFT && data.isResponsible) || (data.status === AppointmentOrderStatus.REJECTED && data.isResponsible)) && (
                <Button
                  className='me-2 px-3'
                  onClick={onCheckApproverSectionsSubmitAsync}
                >
                  <FaRegCheckCircle className='me-2 pb-1 fs-5' />
                  ส่งอนุมัติเห็นชอบ
                </Button>
              )
            }
          </div>
        </div>
        {
          (data.status === AppointmentOrderStatus.PENDING || data.status === AppointmentOrderStatus.APPROVED)
            ? (
              <Button
                onClick={onClickNext}
                className='me-2 px-3'
              >
                ถัดไป
                <MdArrowForward className='ms-2 pb-1 fs-5' />
              </Button>
            )
            : <div style={{ width: '190px' }} />
        }
      </div>
      <HistoryModal
        title='ประวัติการใช้งานจัดทำคำสั่งแต่งตั้งคณะกรรมการจัดซื้อจัดจ้างและคณะกรรมการตรวจรับ'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={data.id}
      />
      <Modal
        show={showPendingAppointmentOrder}
        size='lg'
        onHide={() => setShowPendingAppointmentOrder(!showPendingAppointmentOrder)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งเห็นชอบ/อนุมัติ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary' onClick={() => setShowPendingAppointmentOrder(!showPendingAppointmentOrder)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApprovedAppointmentOrder}
        size='lg'
        onHide={() => setShowApprovedAppointmentOrder(!showApprovedAppointmentOrder)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการเห็นชอบ/อนุมัติ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary' onClick={() => setShowApprovedAppointmentOrder(!showApprovedAppointmentOrder)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onApprovedAppointmentOrderSubmit}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejectedAppointmentOrder}
        size='lg'
        onHide={() => setShowRejectedgAppointmentOrder(!showRejectedAppointmentOrder)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='เหตุผล'
                rule={{ required: true }}
                value={remark}
                onChange={(value) => setRemark(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='outline-primary'
                className='me-2 px-3'
                onClick={() => setShowRejectedgAppointmentOrder(!showRejectedAppointmentOrder)}
              >
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onRejectedAppointmentOrderSubmit}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step2;
