import {
  AppointmentStatus,
  Check,
  Currency,
  DatePicker,
  Modal,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  CriteriaSupplyMethod,
  CriteriaSupplyMethodId,
  PreProcumentFlag,
  PreProcumentStatus,
} from 'constant/PreProcumentFlag';
import {
  AppointmentListResponseModel,
  AppointmentModel,
  CountStatusModel,
  CountSupplyMethodModel,
  SearchPP0201Model,
} from 'models/PP/PreProcurementCreateDirectiveModels';
import moment from 'moment';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import PP0201Services from 'services/PP/PP0201Services';
import PreProcurementServices from 'services/PP/PreProcurementServices';
import {
  formatMonthAndThaiYearWithSlash,
  HttpStatusCode,
  THCurrency,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { useForm } from '../../../utils/useForm';

interface ItemModel {
  label: string;
  value: string;
}

type Loader = {
  departmentDDL: ItemModel[],
  supplyMethodDDL: ItemModel[],
};

interface StatusItemModel {
  label: string;
  value: boolean;
}

export default function PreProcurementEditDirectiveDetail() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [formSearch, setFormSearch] = useState<SearchPP0201Model>({} as SearchPP0201Model);
  const [appointmentData, setAppointmentData] = useState<AppointmentListResponseModel>({} as AppointmentListResponseModel);
  const { departmentDDL, supplyMethodDDL } = useLoaderData() as Loader;
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>([]);

  const searchAppointmentList = useCallback(async (searchModel: SearchPP0201Model) => {
    if (!searchModel.page) {
      searchModel.page = page;
    }

    if (!searchModel.size) {
      searchModel.size = size;
    }

    if (searchModel.isResponsible === undefined) {
      searchModel.isResponsible = true;
    }

    if (searchModel.status === undefined) {
      searchModel.status = [PreProcumentStatus.Draft, PreProcumentStatus.Pending, PreProcumentStatus.Rejected];
    }

    if (searchModel.supplyMethodIds === undefined) {
      searchModel.supplyMethodIds = [CriteriaSupplyMethodId.MethodId60, CriteriaSupplyMethodId.MethodId80];
    }

    const { data, status } = await PreProcurementServices.getListAppointmentAsync(searchModel, PreProcumentFlag.Editing);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    getCountStatus(searchModel);
    getCountSupplyMethod(searchModel);

    setAppointmentData(data);
  }, []);

  const getCountStatus = useCallback(async (searchModel: SearchPP0201Model) => {
    const countStatusRes = await PreProcurementServices.getCountStatusAsync(searchModel, PreProcumentFlag.Editing);

    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data);
    }
  }, []);

  const getCountSupplyMethod = useCallback(async (searchModel: SearchPP0201Model) => {
    const countMethodRes = await PreProcurementServices.getCountSupplyMethodAsync(searchModel, PreProcumentFlag.Editing);

    if (countMethodRes.status === HttpStatusCode.OK) {
      setCountSupplyMethod(countMethodRes.data);
    }
  }, []);

  const handleSetPagination = async (size: number, page: number) => {
    setPage(page);
    setSize(size);
    formSearch.page = page;
    formSearch.size = size;
    searchAppointmentList(formSearch);
  };

  useEffect(() => {
    searchAppointmentList(formSearch);
  }, [formSearch]);

  return (
    <div className='m01'>
      <PreProcurementEditDirectiveDetailHeader />
      <Criteria
        setForm={setFormSearch}
        departmentList={departmentDDL}
        supplyMethodList={supplyMethodDDL}
        countStatus={countStatus}
        countSupplyMethod={countSupplyMethod}
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
      />
      <DataTable
        appointmentData={appointmentData.data}
        total={appointmentData.totalCount}
        page={page}
        size={size}
        onChange={(size, page) => {
          handleSetPagination(size, page);
        }}
      />
    </div>
  );
}

function PreProcurementEditDirectiveDetailHeader() {
  useNavigate();
  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>ขอแก้ไขแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง</h4>
      </div>
      <hr />
    </>
  );
}

interface FormCriteriaInput {
  appointNumber: string;
  planNumber: string;
  departmentId: string;
  planName: string;
  expectingProcurementAt: string;
  planBudgetStart: string;
  planBudgetEnd: string;
  supplyMethodIds: string[];
  status: string[];
}

enum CriteriaStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

interface CriteriaProps {
  setForm: (form: SearchPP0201Model) => void;
  departmentList: ItemModel[];
  supplyMethodList: ItemModel[];
  countStatus: CountStatusModel[];
  countSupplyMethod: CountSupplyMethodModel[];
  page: number;
  size: number;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
}

function Criteria(props: CriteriaProps) {
  const [memorandumDate, setMemorandumDate] = useState<Date>();
  const [expectingProcurementDate, setExpectingProcurementDate] = useState<Date>();
  const [departmentId, setDepartmentId] = useState('');
  const [planBudgetStart, setPlanBudgetStart] = useState<number | undefined>(undefined);
  const [planBudgetEnd, setPlanBudgetEnd] = useState<number | undefined>(undefined);
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [statusDraft, setStatusDraft] = useState<boolean>(false);
  const [statusPending, setStatusPending] = useState<boolean>(false);
  const [, setStatusApproved] = useState<boolean>(false);
  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [methodAll, setMethodAll] = useState<boolean>(false);
  const [method60, setMethod60] = useState<boolean>(false);
  const [method80, setMethod80] = useState<boolean>(false);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);
  const [supplyMethods] = useState<ItemModel[]>(props.supplyMethodList);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>(props.countStatus);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>(props.countSupplyMethod);
  const [methodLists, setMethodLists] = useState<StatusItemModel[]>([
    { value: true, label: CriteriaSupplyMethodId.MethodId60 },
    { value: true, label: CriteriaSupplyMethodId.MethodId80 },
  ]);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    { value: true, label: PreProcumentStatus.Draft },
    {
      value: true, label: PreProcumentStatus.Pending,
    },
    {
      value: true, label: PreProcumentStatus.Rejected,
    },
  ]);

  useEffect(() => {
    setCountStatus(props.countStatus);
  }, [props.countStatus]);

  useEffect(() => {
    setCountSupplyMethod(props.countSupplyMethod);
  }, [props.countSupplyMethod]);

  useEffect(() => {
    setIsResponsible(true);
    onChangeStatusAll(true);
    onChangeMethodAll(true);
  }, []);

  const onChangeStatusAll = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setStatusDraft(true);
      setStatusPending(true);
      setStatusApproved(true);
      setStatusRejected(true);
    } else {
      setStatusAll(false);
      setStatusDraft(false);
      setStatusPending(false);
      setStatusApproved(false);
      setStatusRejected(false);
    }
  };

  const onChangeMethodAll = (result: boolean) => {
    if (result) {
      setMethodAll(true);
      setMethod60(true);
      setMethod80(true);
    } else {
      setMethodAll(false);
      setMethod60(false);
      setMethod80(false);
    }
  };

  const onClear = useCallback(async () => {
    setMemorandumDate(undefined);
    setExpectingProcurementDate(undefined);
    setDepartmentId('');
    setIsResponsible(true);
    setStatusAll(true);
    setStatusDraft(true);
    setStatusPending(true);
    setStatusApproved(true);
    setStatusRejected(true);
    setMethodAll(true);
    setMethod60(true);
    setMethod80(true);
    setPlanBudgetStart(0);
    setPlanBudgetEnd(0);
    handleClear();
    props.setPage(1);
    props.setSize(10);
  }, []);

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex(s => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter(s => s.value === false).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case PreProcumentStatus.Draft:
        return setStatusDraft(value);
      case PreProcumentStatus.Pending:
        return setStatusPending(value);
      case PreProcumentStatus.Rejected:
        return setStatusRejected(value);
    }
  };

  const onChangeCheckMethod = (methodId: string, value: boolean) => {
    const methodList = [...methodLists];
    const index = methodLists.findIndex(s => s.label === methodId);
    methodList[index].value = value;
    setMethodLists(methodList);

    if (!value) {
      setMethodAll(false);
    }

    if (methodList.filter(s => s.value === false).length === 0) {
      setMethodAll(true);
    }

    switch (methodId) {
      case CriteriaSupplyMethodId.MethodId60:
        return setMethod60(value);
      case CriteriaSupplyMethodId.MethodId80:
        return setMethod80(value);
    }
  };

  const { handleSubmit, handleChange, data: formData, handleClear } = useForm<FormCriteriaInput>({
    initialValues: {
      appointNumber: undefined,
      departmentId: undefined,
      planName: undefined,
      expectingProcurementAt: undefined,
      planBudgetStart: undefined,
      planBudgetEnd: undefined,
      supplyMethodIds: undefined,
      status: undefined,
      planNumber: undefined,
    },
    validations: {},
    onSubmit: async () => {
      let apmDate: Date | undefined;
      let expectingDate: string | undefined;

      const status: string[] = [];
      const method: string[] = [];

      if (statusDraft) {
        status.push(CriteriaStatus.Draft);
      }

      if (statusPending) {
        status.push(CriteriaStatus.Pending);
      }

      if (statusRejected) {
        status.push(CriteriaStatus.Rejected);
      }

      if (method60) {
        method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId60)[0].value);
      }

      if (method80) {
        method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId80)[0].value);
      }

      if (memorandumDate) {
        apmDate = new Date(memorandumDate);
      }

      if ((planBudgetStart && planBudgetEnd) && (planBudgetStart > planBudgetEnd)) {
        toast.error('ไม่สามารถระบุวงเงินเริ่มต้นมากกว่าวงเงินสิ้นสุดได้');
      }

      if (expectingProcurementDate) {
        expectingDate = moment(expectingProcurementDate)
          .format('YYYY-MM-DD');
      }

      const searchModel: SearchPP0201Model = {
        page: props.page,
        size: props.size,
        appointNumber: formData.appointNumber,
        planNumber: formData.planNumber,
        departmentId,
        planName: formData.planName,
        memorandumDate: apmDate,
        expectingProcurementAt: expectingDate,
        planBudgetStart,
        planBudgetEnd,
        isResponsible,
        supplyMethodIds: method,
        status: status,
      };

      props.setForm(searchModel);
    },
  });

  return (
    <Form onSubmit={handleSubmit}
      id='formPL01'>
      <Row className='criteria'>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่บันทึกแต่งตั้ง</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='เลขที่บันทึกแต่งตั้ง'
                value={formData.appointNumber || ''}
                onChange={handleChange<string>('appointNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่รายการจัดซื้อจัดจ้าง</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='เลขที่รายการจัดซื้อจัดจ้าง'
                value={formData.planNumber || ''}
                onChange={handleChange<string>('planNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Selector
              label='ฝ่าย/สำนัก'
              items={props.departmentList}
              value={`${departmentId}`}
              onChange={(value) => (setDepartmentId(value.toString()))}
            />
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>ชื่อโครงการ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='ชื่อโครงการ'
                value={formData.planName || ''}
                onChange={handleChange<string>('planName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <DatePicker
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              value={expectingProcurementDate}
              onChangeHasNullable={setExpectingProcurementDate}
              monthYearOnly
            />
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          xl={3}>
          <Currency
            label='วงเงินเริ่มต้น'
            placeholder='0.00'
            value={planBudgetStart}
            onChange={(value) => {
              setPlanBudgetStart(Number(value) ? Number(value) : undefined);
            }}
          />
        </Col>
        <Col sm={12}
          md={4}
          xl={3}>
          <Currency
            label='วงเงินสิ้นสุด'
            placeholder='0.00'
            value={planBudgetEnd}
            onChange={(value) => {
              setPlanBudgetEnd(Number(value) ? Number(value) : undefined);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className='mb-3'>
            <Form.Label>วิธีจัดหา</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={<div className='align-items-center d-flex'>ทั้งหมด<Badge className='h-50 mx-2'
                  bg='light'>{countSupplyMethod.length > 0 ? countSupplyMethod.map((x) => x.count)
                    .reduce((prev, curr) => prev + curr) : 0}</Badge></div>}
                value={methodAll}
                onChange={(val) => onChangeMethodAll(val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>{CriteriaSupplyMethod.Method80}
                  <Badge className='h-50 mx-2'
                    bg='primary'>{countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId80).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId80)[0].count : 0}</Badge>
                </div>}
                value={method80}
                onChange={(val) => onChangeCheckMethod(CriteriaSupplyMethodId.MethodId80, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>{CriteriaSupplyMethod.Method60}
                  <Badge className='h-50 mx-2'
                    bg='primary'>{countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId60).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId60)[0].count : 0}</Badge>
                </div>}
                value={method60}
                onChange={(val) => onChangeCheckMethod(CriteriaSupplyMethodId.MethodId60, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={<div className='align-items-center d-flex'>ทั้งหมด<Badge className='h-50 mx-2'
                  bg='light'>{countStatus.length > 0 ? countStatus.map((x) => x.count)
                    .reduce((prev, curr) => prev + curr) : 0}</Badge></div>}
                value={statusAll}
                onChange={(val) => onChangeStatusAll(val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>แบบร่างขอแก้ไข <Badge className='h-50 mx-2'
                  bg='secondary'>{countStatus.filter((x) => x.status === CriteriaStatus.Draft).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.Draft)[0].count : 0}</Badge>
                </div>}
                value={statusDraft}
                onChange={(val) => onChangeCheckStatus(PreProcumentStatus.Draft, val)}
              />
              <Check
                label={
                  <div className='align-items-center d-flex'>รอเห็นชอบ/รออนุมัติขอแก้ไข <Badge className='h-50 mx-2'
                    bg='warning'>{countStatus.filter((x) => x.status === CriteriaStatus.Pending).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.Pending)[0].count : 0}</Badge>
                  </div>}
                value={statusPending}
                onChange={(val) => onChangeCheckStatus(PreProcumentStatus.Pending, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>ส่งกลับแก้ไข<Badge className='h-50 mx-2'
                  bg='danger'>{countStatus.filter((x) => x.status === CriteriaStatus.Rejected).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.Rejected)[0].count : 0}</Badge>
                </div>}
                value={statusRejected}
                onChange={(val) => onChangeCheckStatus(PreProcumentStatus.Rejected, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12}
          md={4}
          xl={3}>
          <Check
            label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
            value={isResponsible}
            onChange={(val) => setIsResponsible(val)}
          />
        </Col>
      </Row>
      <div className='d-flex gap-2'>
        <Button form='formPL01'
          type='submit'
          variant='primary'
          className='me-2'>
          <FaSearch className='me-2' />ค้นหา
        </Button>
        <Button variant='outline-primary'
          onClick={onClear}
          form='formPL01'
          type='submit'>
          <FaEraser className='me-2' />ล้าง
        </Button>
      </div>
    </Form>
  );
}

interface DataTableProps {
  page: number;
  size: number;
  total: number;
  appointmentData: AppointmentModel[];
  onChange?: (size: number, page: number) => void;
}

function DataTable(props: DataTableProps) {
  const [appointment, setAppointment] = useState<AppointmentModel[]>([]);
  const [deleteId, setDeleteId] = useState<string>('');
  const [indexId, setIndexId] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const goToPage = (id: string) => {
    navigate(`/edit-preprocurement-directive/edit/${id}`);
  };
  const { departmentId } = useAppContext();
  const handleSetPagination = (page: number, size: number) => {
    if (props.onChange) {
      props.onChange(size, page);
    }
  };

  useEffect(() => {
    if (props && props.appointmentData) {
      setAppointment(props.appointmentData);
    }
  }, [props]);

  const openModalDelete = (index: number, id: string) => {
    setShowModal(true);
    setDeleteId(id);
    setIndexId(index);
  };

  const deleteAppointmentAsync = async (index: number, id: string) => {
    if (id) {
      const response = await PP0201Services.deleteAppointmentAsync(id);

      if (response.status === HttpStatusCode.OK) {
        toast.success('ลบรายการสำเร็จ');
        const temp: AppointmentModel[] = [...appointment];
        temp.splice(index, 1);
        setAppointment(temp);
        setShowModal(false);
        handleSetPagination(props.page, props.size);

      } else {
        toast.error('เกิดข้อผิดพลาด');
      }
    } else {
      toast.error('เกิดข้อผิดพลาด');
    }
    setShowModal(false);
  };

  return (
    <Table
      className='mt-4'
      total={props.total}
      onChange={(size, page) => {
        handleSetPagination(page, size);
      }}
    >
      <thead>
        <tr className='text-center'>
          <th>เลขที่บันทึกแต่งตั้ง</th>
          <th>เลขที่รายการ<br />จัดซื้อจัดจ้าง</th>
          <th>สถานะ</th>
          <th>ฝ่าย/สำนัก</th>
          <th>ชื่อโครงการ</th>
          <th>วงเงิน</th>
          <th>วิธีจัดหา</th>
          <th>ประมาณการช่วงเวลา<br />
            การจัดซื้อจัดจ้าง
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {
          appointment?.map((item, index) => (
            <tr className='text-center'
              key={item.id}>
              <td>
                <div className='d-flex justify-content-center'>
                  <Button
                    variant='link'
                    onClick={() => goToPage(item.id)}
                  >{item.appointNumber}
                  </Button>
                </div>
              </td>
              <td className='text-center'>{item.planNumber}</td>
              <td>
                <Status value={item.status}
                  type={StatusType.APPOINTMENTEDIT} />
              </td>
              <td className='text-start'>{`${item.departmentShortName} : ${item.departmentName}`}</td>
              <td className='text-start text-wrap'
                style={{ minWidth: 650, width: 650 }}>{item.planName}</td>
              <td className='text-end'>{THCurrency(item.planBudget)}</td>
              <td>{`${item.supplyMethodName} : ${item.supplyMethodSpecialTypeName}`}</td>
              <td>{formatMonthAndThaiYearWithSlash(item.expectingProcurementAt)}</td>
              <td>
                {
                  (item.status === AppointmentStatus.DRAFT || item.status === AppointmentStatus.REJECTED) && (
                    <div className='d-flex justify-content-center'>
                      <Button
                        disabled={departmentId !== item.departmentId}
                        variant='danger'
                        className='d-flex align-items-center gap-2'
                        onClick={() => {
                          openModalDelete(index, item.id);
                        }}
                      >
                        <FaTrashAlt />
                      </Button>
                    </div>
                  )
                }
              </td>
            </tr>
          ))
        }
      </tbody>
      <Modal
        show={showModal}
        size='lg'
        onHide={() => setShowModal(!showModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบรายการ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการลบรายการอนุมัติแต่งตั้งนี้หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-danger'
                onClick={() => setShowModal(!showModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  deleteAppointmentAsync(indexId, deleteId);
                }}
              >
                ยืนยันลบข้อมูล
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </Table>

  );
}
