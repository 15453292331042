import {
  Check,
  Currency,
  DatePicker,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  AcceptorParams,
  AcceptorResponse,
  ItemModel,
} from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { acceptor } from 'services';
import {
  GetMonthYearBC,
  HttpStatusCode,
  thaiFormatDateWithSlash,
  THCurrency,
} from 'utils';

type Loader = { acceptorData: AcceptorResponse[], acceptorTotalRecords: number, departmentItems: ItemModel[], supplymethodTypeList: ItemModel[] };

interface CriteriaProps {
  sendCriteriaData: (data: AcceptorParams) => void;
}

interface DataTableProps {
  criteriaData: AcceptorParams;
}

export default function PL0307() {
  const [criteria, setCriteria] = useState<AcceptorParams>({} as AcceptorParams);

  return (
    <div className='pl0302'>
      <PL0302Header />
      <Criteria sendCriteriaData={(data) => setCriteria(data)} />
      <DataTable criteriaData={criteria} />
    </div>
  );
}

function PL0302Header() {
  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>ให้ความเห็นชอบ</h4>
      </div>
      <hr />
    </>
  );
}

function Criteria(props: CriteriaProps) {
  const { departmentItems } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<AcceptorParams>({
    statusAll: true,
    statusWaitAccept: true,
    statusAccepted: true,
    statusRejectAcceptor: true,
    supplyMethodAll: true,
  } as AcceptorParams);

  useEffect(() => {
    if (criteria.statusWaitAccept && criteria.statusAccepted && criteria.statusRejectAcceptor) {
      setCriteria({ ...criteria, statusAll: true });

      return;
    }

    setCriteria({ ...criteria, statusAll: false });
  }, [criteria.statusWaitAccept, criteria.statusAccepted, criteria.statusRejectAcceptor]);

  const onSearch = () => {
    props.sendCriteriaData(criteria);
  };

  const onClear = () => {
    setCriteria({
      statusWaitAccept: true,
      statusAccepted: true,
      statusRejectAcceptor: true,
      supplyMethodAll: true,
      statusAll: true,
    } as AcceptorParams);

    props.sendCriteriaData({} as AcceptorParams);
  };

  const onCheckStatusAll = (value: boolean) => {
    setCriteria({ ...criteria, statusAll: value, statusWaitAccept: value, statusAccepted: value, statusRejectAcceptor: value });
  };

  return (
    <Form>
      <Row className='criteria'>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='เลขที่แผน'
            value={criteria.planNumber}
            onChange={(value) => setCriteria({ ...criteria, planNumber: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            items={departmentItems}
            label='ฝ่าย/สำนัก'
            value={criteria.departmentId}
            onChange={(value) => setCriteria({ ...criteria, departmentId: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='ชื่อแผน'
            value={criteria.planName}
            onChange={(value) => setCriteria({ ...criteria, planName: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Currency
            label='งบประมาณโครงการ'
            value={criteria.planBudget}
            onChange={(value) => setCriteria({ ...criteria, planBudget: value ?? 0 })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker
            label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
            monthYearOnly
            value={criteria.expectingProcurementAt}
            onChange={(value) => setCriteria({ ...criteria, expectingProcurementAt: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker
            label='วันที่ประกาศแผน'
            value={criteria.planAnnouncementDate}
            onChange={(value) => setCriteria({ ...criteria, planAnnouncementDate: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='ผู้จัดทำ'
            value={criteria.createBy}
            onChange={(value) => setCriteria({ ...criteria, createBy: value })}
          />
        </Col>
        <Row>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>สถานะ</Form.Label>
              <InputGroup>
                <Check
                  label={<p>ทั้งหมด</p>}
                  value={criteria.statusAll}
                  className='mx-2'
                  onChange={(value) => onCheckStatusAll(value)}
                />
                <Check
                  label={<p>รออนุมัติ</p>}
                  value={criteria.statusWaitAccept}
                  className='mx-2'
                  onChange={(value) => setCriteria({ ...criteria, statusWaitAccept: value })}
                />
                <Check
                  label={<p>อนุมัติ</p>}
                  value={criteria.statusAccepted}
                  className='mx-2'
                  onChange={(value) => setCriteria({ ...criteria, statusAccepted: value })}
                />
                <Check
                  label={<p>ส่งกลับแก้ไข</p>}
                  value={criteria.statusRejectAcceptor}
                  className='mx-2'
                  onChange={(value) => setCriteria({ ...criteria, statusRejectAcceptor: value })}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex gap-2'>
            <Button
              variant='primary'
              className='d-flex align-items-center gap-2'
              onClick={onSearch}
            >
              <FaSearch />ค้นหา
            </Button>
            <Button
              variant='outline-primary'
              className='d-flex align-items-center gap-2'
              onClick={onClear}
            >
              <FaEraser />ล้าง
            </Button>
          </div>
        </div>
      </Row>
    </Form>
  );
}

function DataTable(props: DataTableProps) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [acceptorList, setAcceptorList] = useState<AcceptorResponse[]>([]);
  const { acceptorData, acceptorTotalRecords } = useLoaderData() as Loader;
  const navigate = useNavigate();

  useEffect(() => {
    setAcceptorList(acceptorData);
    setTotalRecords(acceptorTotalRecords);
  }, []);

  const getAcceptorListAsync = useCallback(async () => {
    const response = await acceptor.GetListsAsync(page, size, props.criteriaData);

    if (response.status === HttpStatusCode.OK) {
      setAcceptorList(response.data.data);
      setTotalRecords(response.data.totalRecords);
    }
  }, [page, size, props.criteriaData]);

  useEffect(() => {
    getAcceptorListAsync();
  }, [getAcceptorListAsync]);

  const navigateToDetail = (id: string, type: string) => {
    navigate(`detail/${type}/${id}`);
  };

  return (
    <Table className='mt-4'
      onChange={(size, page) => (setPage(page), setSize(size))}
      total={totalRecords}>
      <thead>
        <tr>
          <th>เลขที่แผน</th>
          <th>ฝ่าย/สำนัก</th>
          <th style={{ minWidth: 300, width: 500 }}>ชื่อแผน</th>
          <th>งบประมาณ (บาท)</th>
          <th>วิธีจัดหา</th>
          <th>ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง</th>
          <th>วันที่ประกาศแผน</th>
          <th>สถานะโครงการ</th>
          <th>สถานะ</th>
        </tr>
      </thead>
      <tbody>
        {acceptorList.map((data, index) => (
          <tr key={data.id}>
            <td className='text-center'>
              <Button variant='link'
                onClick={() => navigateToDetail(data.id, data.planType)}>
                {data.planNumber}
              </Button>
            </td>
            <td className='text-center'>{data.department}</td>
            <td className='text-wrap'>{data.planName}</td>
            <td className='text-end'>
              {THCurrency(data.planBudget)}
            </td>
            <td className='text-center'>
              {data.supplyMethod}
            </td>
            <td className='text-center'>
              {GetMonthYearBC(data.expectingProcurementAt)}
            </td>
            <td className='text-center'>
              {thaiFormatDateWithSlash(data.planAnnouncementDate)}
            </td>
            <td className='text-center'>
              <Status type={StatusType.PROJECT}
                value={data.planType} />
            </td>
            <td className='text-center'>
              <Status type={StatusType.PROCESS}
                value={data.status} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
