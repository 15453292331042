import { Card } from 'components';
import { Input } from 'components/Controls';
import Title from 'components/Controls/Title';
import Cookie from 'cookie-universal';
import {
  FormEvent,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { account } from 'services';
import {
  HttpStatusCode,
  submitForm,
} from 'utils';
import version from '_version'
import toast from 'utils/toast';

type BrowserInfo = {
  name: string;
  version: number;
};

function Login() {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [disabled, setDisabled] = useState(false);
  const cookies = Cookie();
  const navigate = useNavigate();

  const handlerOnSubmitAsync = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault();
    submitForm();

    if (!username || !password) {
      return;
    }

    await signInAsync();
  };

  const signInAsync = async () => {
    setDisabled(true);

    const signInRes = await account.signInAsync(username.trim(), password.trim());

    switch (signInRes.status) {
      case HttpStatusCode.OK:
        const { data } = signInRes;

        cookies.set('access-token', data.access_token);
        cookies.set('refresh-token', data.refresh_token);

        toast.success('เข้าสู่ระบบสำเร็จ');
        navigate('/worklist');
        break;
      case HttpStatusCode.UNAUTHORIZED:
        toast.error('ไม่พบข้อมูลผู้ใช้งานในระบบ');
        break;
      default:
        toast.error('ไม่สามารถเข้าสู่ระบบได้');
        break;
    }

    setDisabled(false);
  };

  useEffect(() => {
    const supported = isSupportedBrowser();

    if (!supported) {
      navigate('/browser-not-support')
    }
  }, []);

  function getBrowserInfo(): BrowserInfo {
    const { userAgent } = navigator;
    let temp: RegExpMatchArray | null;
    const browserInfo: RegExpMatchArray | null = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i);

    // Check for Internet Explorer
    if (browserInfo && /trident/i.test(browserInfo[1])) {
      temp = userAgent.match(/\brv[ :]+(\d+)/g);

      return { name: 'IE', version: temp ? parseInt(temp[1], 10) : NaN };
    }

    // Check for Edge or Opera
    if (browserInfo && browserInfo[1] === 'Chrome') {
      temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (temp) {
        return { name: temp[1].replace('OPR', 'Opera'), version: parseInt(temp[2], 10) };
      }
    }

    // Get browser name and version
    if (browserInfo) {
      const [name, version] = browserInfo[2] ? [browserInfo[1], browserInfo[2]] : [navigator.appName, navigator.appVersion];
      temp = userAgent.match(/version\/(\d+)/i);

      return { name, version: temp ? parseInt(temp[1], 10) : parseInt(version, 10) };
    }

    return { name: navigator.appName, version: parseInt(navigator.appVersion, 10) };
  }

  function isSupportedBrowser(): boolean {
    const { name, version } = getBrowserInfo();

    const browserChecks: { [key: string]: (version: number) => boolean } = {
      Chrome: version => version >= 60,
      Firefox: version => version >= 60,
      'Firefox ESR': () => true, // Assuming always supported for ESR
      Safari: version => version >= 12,
      'Mobile Safari': version => version >= 12,
      Opera: version => version >= 47, // Chrome 60 is Opera 47
      Edge: version => version >= 16, // Chrome 60 is Edge 16
      IE: version => version > 11
    };

    return (name in browserChecks) && browserChecks[name](version);
  }

  return (
    <>
      <Container>
        <Row className="login">
          <Col lg={6} xl={7} className="d-none d-xl-block">
            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
              <Image src="/images/logo-207x108.png" width={150} />
              <p className="text-center fw-bold m-0">Digital Procurement (DP)</p>
              <Image src="/images/img-login.png" className="img-fluid" />
            </div>
          </Col>
          <Col md={9} lg={6} xl={5}>
            <Card className="p-5">
              <Title text="เข้าสู่ระบบ" className="text-dark fs-5" />
              <Form onSubmit={handlerOnSubmitAsync}>
                <Input type="text" label="ชื่อผู้ใช้งาน" onChange={setUsername} rule={{ required: true }} autoFocus />
                <Input type="password" label="รหัสผ่าน" onChange={setPassword} rule={{ required: true }} />
                <Button variant="primary" className="w-100 my-3" type="submit" disabled={disabled}>
                  เข้าสู่ระบบ
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <span className="version-label">
        {version}
      </span>
    </>
  );
}

export default Login;
