import {
  Check,
  Currency,
  DatePicker,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  AssignParams,
  AssignResponse,
  ItemModel,
} from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import assign from 'services/assign';
import {
  GetMonthYearBC,
  HttpStatusCode,
  thaiFormatDateWithSlash,
  THCurrency,
} from 'utils';
import { generateUniqueId } from '../../../utils/helper';

type Loader = { assignLists: AssignResponse[], assignTotalRecords: number, supplyMethodTypeList: ItemModel[], supplyMethodTypeNameList: ItemModel[] };

interface CriteriaProps {
  sendCriteriaData: (data: AssignParams) => void;
}

interface DataTableProps {
  criteriaData: AssignParams;
}

export default function PL0305() {
  const [criteriaData, setCriteria] = useState<AssignParams>({
    supplyMethodAll: true,
    statusAll: true,
    statusApproved: true,
    statusAssigned: true,
  } as AssignParams);

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>มอบหมายงาน</h4>
      </div>
      <hr />
      <Criteria sendCriteriaData={(data) => setCriteria(data)} />
      <Datatable criteriaData={criteriaData} />
    </>
  );
}

function Criteria(props: CriteriaProps) {
  const { supplyMethodTypeList } = useLoaderData() as Loader;
  const [criteriaData, setCriteriaData] = useState<AssignParams>({
    supplyMethodType: [...supplyMethodTypeList.map((i) => i.value)],
    supplyMethodAll: true,
    statusApproved: true,
    statusAssigned: true,

  } as AssignParams);
  const [typeAll, setTypeAll] = useState<boolean>(true);

  useEffect(() => {
    if (criteriaData.statusApproved && criteriaData.statusAssigned) {
      setCriteriaData({ ...criteriaData, statusAll: true });
    } else {
      setCriteriaData({ ...criteriaData, statusAll: false });
    }
  }, [criteriaData.statusApproved, criteriaData.statusAssigned]);

  const clearCriteria = () => {
    const clearData: AssignParams = {
      supplyMethodType: [...supplyMethodTypeList.map((i) => i.value)],
      supplyMethodAll: true,
      statusAll: true,
      statusApproved: true,
      statusAssigned: true,
    } as AssignParams;

    setTypeAll(true);
    setCriteriaData(clearData);
    props.sendCriteriaData(clearData);
  };

  const onClickAllStatus = (value: boolean) => {
    setCriteriaData({ ...criteriaData, statusAssigned: value, statusApproved: value, statusAll: value });
  };

  const supplyMethodOnChange = (value: string, result: boolean) => {
    if (result) {
      if (!criteriaData.supplyMethodType) {
        criteriaData.supplyMethodType = [];
      }

      criteriaData.supplyMethodType.push(value);

      setCriteriaData({ ...criteriaData });

      if (criteriaData.supplyMethodType.length === 2) {
        setTypeAll(true);
      }
    } else {
      const supplyMethod = criteriaData.supplyMethodType !== undefined ? criteriaData.supplyMethodType : [];
      const i = supplyMethod.findIndex((s) => s === value);

      supplyMethod.splice(i, 1);
      setCriteriaData({ ...criteriaData, supplyMethodType: [...supplyMethod] });
    }

    if (criteriaData.supplyMethodType !== undefined && criteriaData.supplyMethodType.length < 2) {
      setTypeAll(false);
    }
  };

  const supplyMethodAllOnChange = (result: boolean) => {
    if (result) {
      setTypeAll(true);
      setCriteriaData({ ...criteriaData, supplyMethodType: supplyMethodTypeList.map((i) => i.value) });
    } else {
      setTypeAll(false);
      setCriteriaData({ ...criteriaData, supplyMethodType: [] });
    }
  };

  return (
    <div className='criteria'>
      <Row>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='เลขที่แผน'
            value={criteriaData.planNumber}
            onChange={(value) => setCriteriaData({ ...criteriaData, planNumber: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='ฝ่าย/สำนัก'
            value={criteriaData.departmentId}
            onChange={(value) => setCriteriaData({ ...criteriaData, departmentId: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='ชื่อแผน'
            value={criteriaData.planName}
            onChange={(value) => setCriteriaData({ ...criteriaData, planName: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Currency
            label='งบประมาณโครงการ'
            value={criteriaData.planBudget}
            onChange={(value) => setCriteriaData({ ...criteriaData, planBudget: value ?? 0 })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker
            label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
            monthYearOnly
            value={criteriaData.expectingProcurementAt ? new Date(criteriaData.expectingProcurementAt) : undefined}
            onChange={(value) => setCriteriaData({ ...criteriaData, expectingProcurementAt: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker
            label='วันที่ประกาศแผน'
            value={criteriaData.planAnnouncementDate ? new Date(criteriaData.planAnnouncementDate) : undefined}
            onChange={(value) => setCriteriaData({ ...criteriaData, planAnnouncementDate: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='ผู้จัดทำ'
            value={criteriaData.organizer}
            onChange={(value) => setCriteriaData({ ...criteriaData, organizer: value })}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Form.Label>วิธีจัดหา</Form.Label>
          <div className='d-flex gap-2'>
            <Check
              value={typeAll}
              onChange={(val) => supplyMethodAllOnChange(val)}
              label={<>ทั้งหมด </>}
            />
            {supplyMethodTypeList.map((v, i) => (
              <Check
                key={generateUniqueId(i)}
                value={criteriaData.supplyMethodType?.some((s) => s === v.value)}
                onChange={(val) => supplyMethodOnChange(v.value, val)}
                label={(
                  <>{v.label}
                  </>
                )}
              />
            ))}
          </div>
        </Col>
        <Col>
          <Form.Label>สถานะ</Form.Label>
          <div className='d-flex gap-2 status'>
            <Check
              label='ทั้งหมด'
              value={criteriaData.statusAll}
              onChange={(value) => onClickAllStatus(value)}
            />
            <Check
              label={<>อนุมัติ</>}
              value={criteriaData.statusApproved}
              onChange={(value) => setCriteriaData({ ...criteriaData, statusApproved: value })}
            />
            <Check
              label={<>มอบหมายงาน</>}
              value={criteriaData.statusAssigned}
              onChange={(value) => setCriteriaData({ ...criteriaData, statusAssigned: value })}
            />
          </div>
        </Col>
      </Row>
      <div className='d-flex justify-content-between align-items-center mt-2'>
        <div className='d-flex gap-2'>
          <Button
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={() => props.sendCriteriaData(criteriaData)}
          >
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={clearCriteria}
          >
            <FaEraser />ล้าง
          </Button>
        </div>
      </div>
    </div>
  );
}

function Datatable(props: DataTableProps) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [assignListsData, setAssignListsData] = useState<AssignResponse[]>([]);
  const { assignLists, assignTotalRecords } = useLoaderData() as Loader;
  const navigate = useNavigate();

  useEffect(() => {
    setAssignListsData(assignLists);
    setTotalRecords(assignTotalRecords);
  }, []);

  const navigateToDetail = (planId: string) => {
    navigate(`detail/${planId}`);
  };

  const getAssignListAsync = useCallback(async () => {
    const response = await assign.getAssignListAsync(page, size, props.criteriaData);

    if (response.status === HttpStatusCode.OK) {
      setAssignListsData(response.data.data);
      setTotalRecords(response.data.totalRecords);
    }
  }, [page, size, props.criteriaData]);

  useEffect(() => {
    getAssignListAsync();
  }, [getAssignListAsync]);

  return (
    <Table
      className='mt-4'
      total={totalRecords}
      onChange={(size, page) => (setPage(page), setSize(size))}
    >
      <thead>
      <tr>
        <th style={{ minWidth: 100 }}>เลขที่แผน</th>
        <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
        <th style={{ minWidth: 300, width: 500 }}>ชื่อแผน</th>
        <th style={{ minWidth: 100 }}>งบประมาณ (บาท)</th>
        <th style={{ minWidth: 100 }}>วิธีจัดหา</th>
        <th style={{ minWidth: 100 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
        <th style={{ minWidth: 100 }}>วันที่ประกาศแผน</th>
        <th style={{ minWidth: 100 }}>ผู้จัดทำ</th>
        <th style={{ minWidth: 100 }}>วันที่จัดทำ</th>
        <th style={{ minWidth: 100 }}>สถานะโครงการ</th>
        <th style={{ minWidth: 100 }}>สถานะ</th>
      </tr>
      </thead>
      <tbody>
      {assignListsData.map((data, index) => (
        <tr key={generateUniqueId(data.id)}>
          <td className='text-center'>
            <Button variant='link'
              onClick={() => navigateToDetail(data.id)}>
              {data.planNumber}
            </Button>
          </td>
          <td className='text-center'>{data.department}</td>
          <td className='text-wrap'>{data.planName}</td>
          <td className='text-end'>{THCurrency(data.planBudget)}</td>
          <td className='text-center'>{data.supplyMethod}</td>
          <td className='text-center'>{GetMonthYearBC(data.expectingProcurementAt)}</td>
          <td className='text-center'>{thaiFormatDateWithSlash(data.planAnnouncementDate)}</td>
          <td>{data.createdFullName}</td>
          <td className='text-center'>{thaiFormatDateWithSlash(data.createdAt)}</td>
          <td className='text-center'><Status type={StatusType.PROJECT}
            value={data.planStatus} /></td>
          <td className='text-center'><Status type={StatusType.PROCESS}
            value={data.status} /></td>
        </tr>
      ))}
      </tbody>
    </Table>
  );
}
