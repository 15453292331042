import { PL0204Criteria } from 'models/PL/PL0204Models';
import { LoaderFunctionArgs } from 'react-router';
import { shareValue } from 'services';
import { PL0204Service } from 'services/PL';

const PL0204Loader = async () => {
  const [departmentItems, supplyMethodItems, supplyMethodNameItems, { data }] = await Promise.all([shareValue.GetDDL('Department'), shareValue.GetDDL('SupplyMethodType'), shareValue.GetDDL('SupplyMethodName'), PL0204Service.getCancelRequestPlanListAsync(1, 10, {} as PL0204Criteria)]);

  return {
    departmentDDL: departmentItems,
    supplyMethodTypeDDL: supplyMethodItems,
    supplyMethodTypeNameDDL: supplyMethodNameItems,
    dataTable: data,
  };
};

const PL0204DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  const [departmentItems, supplyMethodItems, supplyMethodNameItems, positionItems, { data }] = await Promise.all([shareValue.GetDDL('Department'), shareValue.GetDDL('SupplyMethodType'), shareValue.GetDDL('SupplyMethodName'), shareValue.GetDDL('Position'), PL0204Service.getCancelRequestPlanDetailAsync(id)]);

  return {
    departmentDDL: departmentItems,
    supplyMethodTypeDDL: supplyMethodItems,
    supplyMethodTypeNameDDL: supplyMethodNameItems,
    positionDDL: positionItems,
    detail: data.data,
  };
};

export {
  PL0204Loader,
  PL0204DetailLoader,
};
