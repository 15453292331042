import StepProgress from 'components/StepProgress';
import {
  useRef,
  useState,
  useEffect,
  createContext,
  Dispatch,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import Step2 from './step2';
import Step1 from './step1';
import { ContractCompleteReportModel } from 'models/CM/ContractCompleteReportModel';
import ContractCompleteReportService from 'services/CM/ContractCompleteReportService';
import { HttpStatusCode } from 'utils';
import { ContractCompleteReportStatus } from 'components';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type ContractContext = {
  dataContext: ContractCompleteReportModel
  setDataContext: Dispatch<React.SetStateAction<ContractCompleteReportModel>>,
};

export const Context = createContext({} as ContractContext);

export default function CreateContractMangementDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id } = useParams();
  const [defaultStep, setDefaultStep] = useState(1);
  const [dataContext, setDataContext] = useState<ContractCompleteReportModel>({} as ContractCompleteReportModel);

  useEffect(() => {
    if (id) {
      getContractCompleteReportByIdAsync(id);
    }
  }, [id]);

  const getContractCompleteReportByIdAsync = async (id: string) => {
    const {
      data,
      status,
    } = await ContractCompleteReportService.getContractCompleteReportByIdAsync(id);
    if (status === HttpStatusCode.OK) {
      setDataContext(data);
      setStepByStatus(data.status);
    }
  };

  const setStepByStatus = (status: string) => {
    switch (status) {
      case ContractCompleteReportStatus.Draft:
        setDefaultStep(1);
        break;
      case ContractCompleteReportStatus.WaitingForApprove:
        setDefaultStep(2);
        break;
      case ContractCompleteReportStatus.Approved:
        setDefaultStep(2);
        break;
      case ContractCompleteReportStatus.Rejected:
        setDefaultStep(1);
        break;
      default:
        break;
    }
  };

  const obj = [{
    title: <p>ข้อมูลรายงานสัญญาแล้วเสร็จตามไตรมาส</p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p> ผู้มีอำนาจเห็นชอบ/อนุมัติ </p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/cm/cm05');
  };

  return (
    <div className='document'>
      <Row>
        <Col xs={12}
          className='mb-2'>
          <Context.Provider value={{
            dataContext,
            setDataContext,
          }}>
            <StepProgress progressBarData={obj}
              ref={childRef}
              step={defaultStep} />
          </Context.Provider> <></>
        </Col>
      </Row>
    </div>
  );
}
