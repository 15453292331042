import {
  Card,
  DatePicker,
  HistoryModal,
  Input,
  InputTextArea,
  Radio,
  SearchNameModal,
  Selector,
  Table,
  UploadFile,
} from 'components';
import { ContractStatus } from 'components/ContractStatus';
import Title from 'components/Controls/Title';
import { StatusDeliver } from 'components/StatusDeliver';
import { Trader } from 'models';
import {
  InstallmentModel,
  ManageContractDetailModel,
  ParcelModel,
} from 'models/CM/ManageContractModels';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaHistory,
  FaSave,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdCircle,
} from 'react-icons/md';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { dataHistory } from './data';

const INFO = [
  { label: 'ละเว้น', value: '1' },
  { label: 'ไม่ละเว้น', value: '2' },
];

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

const supplyMethodDDL = [
  { label: 'ซื้อ', value: '1' },
  { label: 'จ้าง', value: '2' },
  { label: 'เช่า', value: '3' },
];

export const Context = createContext({} as ManageContractDetailContext);

type ManageContractDetailContext = {
  contractData: ManageContractDetailModel
  setContractData: Dispatch<React.SetStateAction<ManageContractDetailModel>>,
};

export default function ManageContractManagementDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const [showHistory, setShowHistory] = useState(false);
  const [showSearchNameModal, setModalSearchNameModal] = useState(false);
  const { id } = useParams();
  const [contractData, setContractData] = useState<ManageContractDetailModel>({} as ManageContractDetailModel);

  // const obj = [{
  //   title: <p>บริหารสัญญา</p>,
  //   displayIndex: '1',
  // },
  //   {
  //     title: <p>รับประกันสัญญา</p>,
  //     displayIndex: '2',
  //   },
  //   {
  //     title: <p>คืนหลักประกันสัญญา</p>,
  //     displayIndex: '3',
  //   },
  //   {
  //     title: <p>สิ้นสุดสัญญา</p>,
  //     displayIndex: '4',
  //   },
  // ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  useEffect(() => {
    getManageContractByIdAsynce();
  }, []);

  const getManageContractByIdAsynce = async () => {
    const parcels: ParcelModel[] = [
      {
        id: 'p00001',
        installment: 1,
        sequence: 1,
        description: 'แผนการดำเนินโครงการ (Project Planning)',
        quantity: 1,
        unit: 'ฉบับ',
        price: 150000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
      {
        id: 'p00002',
        installment: 1,
        sequence: 2,
        description: 'แผนการดำเนินโครงการ (Project Planning) ที่ 2',
        quantity: 1,
        unit: 'ฉบับ',
        price: 150000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
      {
        id: 'p00003',
        installment: 2,
        sequence: 3,
        description: 'รายงานรายละเอียดการวิเคราะห์และออกแบบระบบ (System Analysis and Design)',
        quantity: 1,
        unit: 'ฉบับ',
        price: 400000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
      {
        id: 'p00004',
        installment: 2,
        sequence: 4,
        description: 'รายงานสถาปัตยกรรมระบบของ โครงการ (System Architecture Design)',
        quantity: 1,
        unit: 'ฉบับ',
        price: 100000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
      {
        id: 'p00005',
        installment: 3,
        sequence: 5,
        description: 'รายงานการออกแบบ (Conceptual design)',
        quantity: 1,
        unit: 'ฉบับ',
        price: 500000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
      {
        id: 'p00006',
        installment: 3,
        sequence: 6,
        description: 'ระบบบันทึกเสียงออนไลน์',
        quantity: 1,
        unit: 'ฉบับ',
        price: 500000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
    ];

    const installmentData: InstallmentModel[] = [
      {
        id: 'ins001',
        installmentSequence: 1,
        paidPercent: 30,
        paidAmount: 250000,
        fineTotal: 0,
        totalBalance: 500000,
        status: 'process',
        deliverySetDate: new Date(),
        deliveryPeriod: 30,
        deliveryPeriodType: 'day',
        deliveryDate: new Date(),
        deliveryRemark: 'ส่งมอบครบถ้วน',
        parcels: parcels.filter(x => x.installment === 1),
        deliveryLists: [],
      },
      {
        id: 'ins002',
        installmentSequence: 2,
        paidPercent: 30,
        paidAmount: 500000,
        fineTotal: 0,
        totalBalance: 500000,
        status: 'process',
        deliverySetDate: new Date(),
        deliveryPeriod: 60,
        deliveryPeriodType: 'day',
        deliveryDate: new Date(),
        deliveryRemark: 'ส่งมอบครบถ้วน',
        parcels: parcels.filter(x => x.installment === 2),
        deliveryLists: [],
      },
      {
        id: 'ins003',
        installmentSequence: 3,
        paidPercent: 40,
        paidAmount: 1000000,
        fineTotal: 0,
        totalBalance: 500000,
        status: 'process',
        deliverySetDate: new Date(),
        deliveryPeriod: 150,
        deliveryPeriodType: 'day',
        deliveryDate: new Date(),
        deliveryRemark: 'ส่งมอบครบถ้วน',
        parcels: parcels.filter(x => x.installment === 3),
        deliveryLists: [],
      },
    ];

    // const deliveryItems: DeliveryItemModel[] = [
    //   {
    //     id: 'sdsdsdsd',
    //     sequence: 1,
    //     deliveryDate: new Date,
    //     deliveryParcels: deliveryParcelItemModel[],
    //     remark: '',
    //   },
    //   {
    //     id: 'sdsdsd',
    //     sequence: 2,
    //     description: 'แผนการดำเนินโครงการ (Project Planning) ที่ 2',
    //     quantity: 1,
    //     unit: 'ฉบับ',
    //     price: 150000,
    //     vatType: 'มี VAT',
    //     deliveryQuantityTotal: 1,
    //     deliveryQuantity: 1,
    //     remark: '',
    //     status: 'Pending',
    //   }
    // ]

    const data: ManageContractDetailModel = {
      id: 'ddddd',
      status: 'Draft',
      contractNumber: 'C00001',
      contractName: 'สัญญาจ้างบริการบำรุงรักษาและซ่อมแซมแก้ไขอุปกรณ์สื่อสารระบบ ITMX',
      planNumber: 'P00001',
      planName: 'สัญญาจ้างบริการบำรุงรักษาและซ่อมแซมแก้ไขอุปกรณ์สื่อสารระบบ ITMX',
      planId: 'p00001',
      contractSignDate: new Date(),
      contractPoNumber: '410007',
      contractJorPorNumber: '001/2566',
      contractLimitAmount: 1000000,
      deliveryPeriod: 200,
      deliveryPeriodType: 'day',
      startDeliveryPeriodType: 'นับถัดจากวันที่เซ็นสัญญา',
      deliverySetDate: new Date(),
      guaranteePeriod: 90,
      guaranteePeriodType: 'day',
      guaranteeExpireDate: new Date(),
      guaranteeConditionType: 'ถัดจากวันสิ้นสุด',
      installments: installmentData,
      hasGuarantee: false,
      guaranteeType: 'เงินโอน',
      guaranteeAmount: 100000,
      guaranteeRatio: 10,
      guaranteeNumber: 'G000001',
      guaranteeNumberDate: new Date(),
      contractDateType: 'ตามวันที่',
      contractStartDate: new Date(),
      contractEndDate: new Date(),
      guaranteeRemark: '',
      trader: {
        id: 'sdqdqdssas',
        nationality: 'ไทย',
        type: 'นิติบุคคล',
        taxpayerIdentificationNo: '1234567890123',
        entrepreneurType: 'นิติบุคคล',
        establishmentName: 'บริษัท โค้ดฮาร์ด จำกัด',
        placeName: '',
        roomNo: '',
        floorNo: '',
        villageName: '',
        address: '',
        group: '',
        alley: '',
        road: '',
        province: '',
        district: '',
        subDistrict: '',
        postalCode: '',
        tel: '0899999999',
        fax: '',
        email: 'welcome@codehard.co.th',
        documents: [],
      } as Trader,
      documents: [],
    };
    setContractData(data);
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/manage-contractMangement');
  };

  const goToPage = () => {
    navigate('/procurement-plan-summary');
  };

  return (
    <div className='document'>
      <h4 className='mt-2 mb-0 text-primary text-start'>
        <Button variant='link'>
          {/* <MdOutlineArrowBack className="fs-4 text-primary mb-1" /> */}
        </Button>บริหารสัญญา
      </h4>
      <Row>
        <Col xs={12}
          className='mb-2'>
          <Context.Provider value={{ contractData, setContractData }}>
            {/* <StepProgress progressBarData={obj} ref={childRef} /> */}
          </Context.Provider>
        </Col>
      </Row>
      <div className='document'>
        <Card className='mt-3'>
          <Title text='ข้อมูลโครงการ'
            className='fs-5 text-primary' />
          <div className='d-flex align-items-center'>
            <p className='mb-0 me-4'>เลขที่โครงการ </p>
            <Button onClick={goToPage}
              variant='link'>{contractData.planNumber}
            </Button>
          </div>
          <div className='d-flex align-items-center'>
            <p className='mb-0 me-4'>ชื่อโครงการ </p>
            <p className='mb-0 ms-4'>{contractData.planName}</p>
          </div>
        </Card>

        <Card className='mt-3'>
          <div className='d-flex mb-4 align-items-center justify-content-between'>
            <Title text='ข้อมูลใบสั่ง/สัญญา'
              className='fs-5 text-primary' />
            <div className='d-flex gap-2'>
              <ContractStatus
                value='Contract' />
              <Button
                onClick={() => setShowHistory(!showHistory)}
                variant='outline-primary'>
                <FaHistory className='me-1' />
                ประวัติการใช้งาน
              </Button>
            </div>
          </div>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              md={4}
              xl={3}>
              <DatePicker label='วันที่ลงนาม'
                rule={{ required: true }}
                value={contractData.contractSignDate}
                disabled />
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              xl={6}>
              <Input label='ชื่อสัญญา'
                value={contractData.contractName}
                disabled />
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input label='เลขที่สัญญา PO'
                textCenter
                rule={{ required: true }}
                value={contractData.contractNumber}
                disabled />
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input label='เลขที่สัญญา จพ.(สบส.)'
                textCenter
                rule={{ required: true }}
                value={contractData.contractJorPorNumber}
                disabled />
            </Col>
            <Col sm={12}
              md={4}
              xl={3}>
              <Selector
                label='ประเภทสัญญา'
                placeholder='กรุณาเลือก'
                items={supplyMethodDDL}
                value='1'
                rule={{ required: true }}
                disabled
              />
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input label='วงเงินตามสัญญา'
                textEnd
                rule={{ required: true }}
                value={contractData.contractLimitAmount}
                disabled />
            </Col>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input label='หมายเลขกล่องที่จัดเก็บ'
                placeholder='หมายเลขกล่องที่จัดเก็บ'
                disabled
                value='0001' />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              md={2}>
              <Input label='กำหนดส่งมอบภายใน'
                placeholder='กำหนดส่งมอบภายใน'
                value={contractData.deliveryPeriod}
                textCenter
                rule={{ required: true }}
                disabled />
            </Col>
            <Col sm={12}
              md={1}>
              <Selector className='no-label'
                placeholder='วัน'
                disabled
                value={contractData.deliveryPeriodType} />
            </Col>
            <Col sm={12}
              md={3}>
              <Selector className='no-label'
                placeholder='นับถัดจากวันที่ลงนามใมสัญญา'
                value={contractData.startDeliveryPeriodType}
                disabled />
            </Col>
            <Col sm={12}
              md={3}>
              <DatePicker label='ครบกำหนดส่งมอบวันที่'
                value={contractData.deliverySetDate}
                disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              md={2}>
              <Input label='ระยะเวลารับประกัน'
                placeholder='ระยะเวลารับประกัน'
                value={contractData.guaranteePeriod}
                textCenter
                rule={{ required: true }}
                disabled />
            </Col>
            <Col sm={12}
              md={1}>
              <Selector className='no-label'
                placeholder='วัน'
                value={contractData.guaranteePeriodType}
                disabled />
            </Col>
            <Col sm={12}
              md={3}>
              <DatePicker label='ครบกำหนดรับประกันวันที่'
                value={contractData.guaranteeExpireDate}
                disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              md={3}>
              <Selector label='เงื่อนไขการรับประกัน'
                placeholder='ถัดจากวันสิ้นสุด'
                rule={{ required: true }}
                value={contractData.guaranteeConditionType}
                disabled />
            </Col>
          </Row>
        </Card>

        <Card className='mt-3'>
          <Title text='ข้อมูลคู่ค้า'
            className='fs-5 text-primary' />
          {
            contractData.trader && (
              <>
                <Row className='mt-3'>
                  <Col sm={12}
                    xl={4}>
                    <p className='mb-0 me-4 text-start'>สัญชาติของผู้ประกอบการ
                      : <span className='ms-2'>{contractData.trader.nationality}</span>
                    </p>
                  </Col>
                  <Col sm={12}
                    xl={4}>
                    <p className='mb-0 me-4 text-start'>ประเภท : <span className='ms-2'>{contractData.trader.type}</span>
                    </p>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col sm={12}
                    xl={4}>
                    <p className='mb-0 me-4 text-start'>เลขประจำตัวผู้เสียภาษี
                      : <span className='ms-2'>{contractData.trader.taxpayerIdentificationNo}</span>
                    </p>
                  </Col>
                  <Col sm={12}
                    xl={4}>
                    <p className='mb-0 me-4 text-start'>ประเภทผู้ประกอบการ
                      : <span className='ms-2'>{contractData.trader.entrepreneurType}</span>
                    </p>
                  </Col>

                  <Col sm={12}
                    className='mt-2'>
                    <p className='mb-0 me-4 text-start'>ชื่อสถานประกอบการ
                      : <span className='ms-2'>{contractData.trader.establishmentName}</span>
                    </p>
                  </Col>
                  <Col sm={12}
                    xl={4}
                    className='mt-2'>
                    <p className='mb-0 me-4 text-start'>หมายเลขโทรศัพท์สำหรับติดต่อ
                      : <span className='ms-2'>{contractData.trader.tel}</span>
                    </p>
                  </Col>
                  <Col sm={12}
                    xl={4}>
                    <p className='mb-0 me-4 text-start'>อีเมล : <span className='ms-2'>{contractData.trader.email}</span>
                    </p>
                  </Col>
                </Row>
              </>
            )
          }
        </Card>

        <Card className='mt-3'>
          <Title text='งวด'
            className='fs-5 text-primary' />

          <Table total={1}
            hidePagination
            className='mt-3'>
            <thead>
              <tr className='text-center'>
                <th style={{ width: 10 }}>งวดที่</th>
                <th style={{ width: 70 }}>จำนวน (%)</th>
                <th style={{ width: 150 }}
                  className='text-end'>จำนวนเงิน
                </th>
                <th style={{ minWidth: 50 }}>จำนวนเงิน<br />
                  ค่าปรับ
                </th>
                <th style={{ minWidth: 50 }}>จำนวน<br />
                  ค่าปรับ
                </th>
                <th style={{ width: 250 }}>สถานะงวดเงิน</th>
                <th style={{ width: 250 }}>รายละเอียดส่งมอบ</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                contractData.installments && (
                  contractData.installments.map((data, index) => (
                    <tr className='text-center'
                      key={index}>
                      <td style={{ width: 10 }}>{data.installmentSequence}</td>
                      <td style={{ minWidth: 150 }}>{`${data.paidPercent}%`}</td>
                      <td className='text-end'>{data.paidAmount}</td>
                      <td />
                      <td />
                      <td><StatusDeliver Istatus='warning' /></td>
                      <td><MdCircle className='text-dark me-2'
                        style={{ fontSize: '8px' }} />{data.deliveryRemark}
                      </td>
                      <td>
                        <Button onClick={() => navigate(`inspect/${data.id}`)}>บันทึกส่งมอบ/ตรวจรับ</Button>
                      </td>
                    </tr>
                  ))
                )
              }
            </tbody>
          </Table>
        </Card>

        <Card className='mt-3'>
          <Title text='บันทึกรับหลักประกัน'
            className='fs-5 text-primary' />
          <Row>
            <Col sm={12}>
              <Radio
                className='mt-3'
                inline
                dflex
                items={INFO}
                value='1'
                label='จำนวนเงินหลักประกัน'
                rule={{ required: true }}
                name='name1'
                disabled
              />
            </Col>
            <Col sm={12}
              xl={3}>
              <Selector label='ประเภทหลักประกันสัญญา'
                placeholder='เงินโอน'
                value={contractData.guaranteeType}
                rule={{ required: true }}
                disabled />
            </Col>
            <Col sm={12}
              xl={3}>
              <Input label='จำนวนเงินหลักประกัน'
                placeholder='จำนวนเงินหลักประกัน'
                value={contractData.guaranteeAmount}
                rule={{ required: true }}
                disabled />
            </Col>
            <Col sm={12}
              xl={3}>
              <Input label='ร้อยละของราคาค่าจ้างตามสัญญา'
                value={contractData.guaranteeRatio}
                placeholder='ร้อยละของราคาค่าจ้างตามสัญญา'
                rule={{ required: true }}
                disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              xl={3}>
              <Input label='เลขที่หลักประกัน'
                value={contractData.guaranteeNumber}
                placeholder='เลขที่หลักประกัน'
                disabled />
            </Col>
            <Col sm={12}
              xl={3}>
              <DatePicker label='เลขที่หลักประกันลงวันที่'
                value={contractData.guaranteeNumberDate}
                disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              xl={3}>
              <Selector label='ประเภทวันทำสัญญา'
                placeholder='ตามวันที่'
                value={contractData.contractDateType}
                disabled />
            </Col>
            <Col sm={12}
              xl={3}>
              <DatePicker label='วันที่'
                rule={{ required: true }}
                value={contractData.contractStartDate}
                disabled />
            </Col>
            <Col sm={12}
              xl={3}>
              <DatePicker label='ถึงวันที่'
                value={contractData.contractEndDate}
                disabled />
            </Col>
            <Col sm={12}
              xl={9}>
              <InputTextArea label='หมายเหตุ'
                disabled />
            </Col>
          </Row>
        </Card>

        <Card className='mt-3'>
          <Title text='คืนหลักประกันสัญญา'
            className='fs-5 text-primary' />
          <Row>
            <Col sm={12}
              lg={6}>
              <InputTextArea label='เหตุผลการพิจารณาอนุมัติให้คืนหลักประกัน'
                disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              lg={3}>
              <Input label='บันทึกหักหลักประกัน'
                value='0'
                textEnd
                disabled />
            </Col>
            <Col sm={12}
              lg={3}>
              <Input label='จำนวนเงิน'
                disabled
                value='54,000.00'
                textEnd />
            </Col>
          </Row>
        </Card>

        <Card className='mt-3'>
          <Title text='เอกสารแนบ'
            className='fs-5 text-primary' />
          <Row className='justify-content-center'>
            <Col sm={12}
              lg={6}>
              <UploadFile />
            </Col>
          </Row>
        </Card>

        <div className='d-flex justify-content-between pt-3'>
          <Button
            className='me-2 px-3'
            variant='outline-primary'
            onClick={() => backToIndex()}
          >
            <MdArrowBack className='me-2 pb-1 fs-5' />
            กลับหน้าแรก
          </Button>
          <div>
            <Button
              variant='success'
              className='me-2 px-3'
            >
              <FaSave className='me-2 pb-1 fs-5' />
              บันทึก
            </Button>
          </div>
          <div>
            {/* <Button
              variant="outline-primary"
              className="me-2 px-3"
              onClick={() => clickNext()}
            >
              ถัดไป
              <MdArrowForward className="ms-2 pb-1 fs-5" />
            </Button> */}
          </div>
        </div>
        <HistoryModal
          title='ประวัติการใช้งานประกาศผู้ชนะการเสนอราคา'
          show={showHistory}
          onHide={() => setShowHistory(!showHistory)}
          data={dataHistory}
        />
        <SearchNameModal
          show={showSearchNameModal}
          onHide={() => setModalSearchNameModal(!showSearchNameModal)}
          data={[]}
        />
      </div>
    </div>
  );
}
