import {
  Breadcrumb,
  Card,
  Check,
  Input,
  Selector,
} from 'components';
import {
  BusinessUnitRequest,
  DepartmentRequest,
  ItemModel,
} from 'models';
import {
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import businessUnit from 'services/businessUnit';
import department from 'services/department';
import {
  HttpStatusCode,
  submitForm,
} from 'utils';
import toast from 'utils/toast';

const BREADCRUMB_INFO = [
  {
    routerName: '/su/su03', label: 'ฝ่าย/สำนัก',
  },
  {
    routerName: '', label: 'กำหนดฝ่าย/สำนัก',
  },
];

export default function SU03Detail() {
  useEffect(() => {
    id ? Promise.all([getBusinessUnitItemsAsync(), getDepartmentDetailById()]) : (async () => await getBusinessUnitItemsAsync())();
  }, []);

  const [departmentData, setDepartmentData] = useState<DepartmentRequest>({ active: true } as DepartmentRequest);
  const [businessUnitItems, setBusinessUnitItems] = useState<ItemModel[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const backToIndex = () => navigate('/su/su03');

  const getBusinessUnitItemsAsync = async () => {
    const response = await businessUnit.getBusinessUnitListByActiveStatus(true);
    const businessUnitData: BusinessUnitRequest[] = response.data;
    const items: ItemModel[] = [];
    businessUnitData.forEach((data) => items.push({ label: data.name, value: data.id ? data.id : '' }));

    setBusinessUnitItems(items);
  };

  const getDepartmentDetailById = useCallback(async () => {
    const response = await department.getDepartmentDetailAsync(id || '');

    if (response.status === HttpStatusCode.OK) {
      setDepartmentData(response.data);
    }
  }, [id]);

  const handleOnSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();
    submitForm();

    if (departmentData.shortCode
      && departmentData.name) {
      createOrUpdateSetGroupWorkAsync();
    }
  };

  const createOrUpdateSetGroupWorkAsync = async () => {
    const data = departmentData;

    if (!id) {
      const response = await department.createDepartmentAsync(data);

      if (response.status === HttpStatusCode.CREATED) {
        backToIndex();
        toast.success('บันทีกข้อมูลสำเร็จ');
      }
      return;
    }

    const response = await department.updateDepartmentAsync(id, data);

    if (response.status === HttpStatusCode.ACCEPTED) {
      backToIndex();
      toast.success('แก้ไขข้อมูลสำเร็จ');
    }
  };

  return (
    <div className="m03">
      <Form onSubmit={handleOnSubmit}>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mt-2 text-primary">
            <Button variant="link" onClick={backToIndex}>
              <MdOutlineArrowBack className="fs-4 text-primary mb-1" />
            </Button>
            ฝ่าย/สำนัก
          </h4>
          <Button type="submit" variant='success'>บันทึก</Button>
        </div>
        <Breadcrumb data={BREADCRUMB_INFO} />
        <div className="mt-4">
          <Card>
            <div className="criteria">
              <Row>
                <Col md={6} lg={4}>
                  <Selector
                    items={businessUnitItems}
                    placeholder="เลือกสายงาน"
                    label="สายงาน"
                    onChange={(value) => setDepartmentData({ ...departmentData, businessUnitId: value === '' ? undefined : value })}
                    value={departmentData.businessUnitId}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} lg={4}>
                  <Input
                    disabled={id !== undefined}
                    label="รหัส (SOL.ID)"
                    value={departmentData.code}
                    onChange={(value) => setDepartmentData({ ...departmentData, code: value })}
                  />
                </Col>
                {/* <Col md={6} lg={4}>
                  <Input
                    label="SOL.ID"
                    rule={{ required: true }}
                    value={departmentData.solId}
                    onChange={(value) => setDepartmentData({ ...departmentData, solId: value })} />
                </Col> */}
              </Row>
              <Row>
                <Col md={6} lg={4}>
                  <Input
                    label="ชื่อย่อฝ่าย"
                    rule={{ required: true }}
                    value={departmentData.shortCode}
                    onChange={(value) => setDepartmentData({ ...departmentData, shortCode: value })}
                  />
                </Col>
                <Col md={6} lg={4}>
                  <Input
                    label="ชื่อฝ่าย/สำนัก"
                    rule={{ required: true }}
                    value={departmentData.name}
                    onChange={(value) => setDepartmentData({ ...departmentData, name: value })}
                  />
                </Col>
                <Col lg={12} className="mt-2">
                  <Check label="ใช้งาน" value={departmentData.active} onChange={(value) => setDepartmentData({ ...departmentData, active: value })} />
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Form>
    </div>
  );
}
