import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Nav,
  ProgressBar,
  Tab,
} from 'react-bootstrap';

export interface Step {
  title: JSX.Element;
  displayIndex: string;
  child?: JSX.Element;
}

interface Props {
  progressBarData: Step[];
  step?: number;
}

export interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

const StepProgress = forwardRef<ProgressBarRefType, Props>((props, ref) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabCount = useMemo(() => props.progressBarData.length, [props.progressBarData]);
  const progressStep = useMemo(() => 100 / tabCount, [tabCount]);
  const [step, setStep] = useState(progressStep);
  const [preStep, setPreStep] = useState<number>(props.step || 0);

  useEffect(() => {
    if (props.step) {
      setStep((props.step === tabCount ? 100 : props.step) * progressStep);
      setPreStep(props.step);
      setSelectedTab(props.step - 1);
    } else {
      setStep(progressStep);
    }
  }, [progressStep, props.step]);

  useImperativeHandle(ref, () => ({
    clickNext() {
      handleNext();
    },
    clickBack() {
      handleBack();
    }
  }));

  const handleNext = useCallback(() => {
    if (selectedTab >= tabCount) {
      return;
    }

    setPreStep(selectedTab + 1);
    setStep((selectedTab + 2) * progressStep);
    setSelectedTab((pre) => pre + 1);
  }, [selectedTab, tabCount, progressStep]);

  const handleBack = useCallback(() => {
    if (selectedTab <= 0) {
      return;
    }

    setPreStep(selectedTab - 1);
    setStep((selectedTab) * progressStep);
    setSelectedTab((pre) => pre - 1);
  }, [selectedTab, progressStep]);

  return (
    <Tab.Container id=""
      activeKey={selectedTab}>
      <div className="steps-tabs">
        <ProgressBar now={step} />
        <Nav variant="pills"
          className="steps nav nav-tabs nav-justified">
          {
            props.progressBarData.map((p, index) => (
              <Nav.Item key={p.displayIndex}>
                <Nav.Link disabled
                  eventKey={index}
                  className={`${preStep < index + 1 ? '' : 'active'}`}>
                  <div className="btn-steps">{p.displayIndex}</div>
                  <div className="text">{p.title}</div>
                </Nav.Link>
              </Nav.Item>
            ))
          }
        </Nav>
      </div>
      <Tab.Content>
        {
          props.progressBarData.map((p, index) => (
            <Tab.Pane eventKey={index}
              key={p.displayIndex}
              mountOnEnter>
              {p.child}
            </Tab.Pane>
          ))
        }
      </Tab.Content>
    </Tab.Container>
  );
});

export default StepProgress;
