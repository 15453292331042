import {
  DatePicker,
  Input,
  Selector,
  Table,
} from 'components';
import { Modal, SearchNameModal } from 'components/Modal';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import {
  FaEdit,
  FaEraser,
  FaFileExcel,
  FaPlus,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';
import {
  DelegateResponseModel,
  SearchDelegateModel,
} from '../../models/delegateModel';
import { PositionListResponse } from '../../models/positionModel';
import {
  department,
  shareValue,
} from '../../services';
import delegateService from '../../services/delegateService';
import {
  HttpStatusCode,
  fullDatetime,
  thaiFormatDateWithSlash,
  useAppContext,
} from '../../utils';
import { calculateRowNumber } from '../../utils/constants/calculateRowNumber';
import toast from '../../utils/toast';
import { DepartmentListResponse } from 'models';
import { JorPorCode } from 'constant/basicInformation';

interface Options {
  value: string;
  label: string;
}

function Delegate() {
  const navigate = useNavigate();
  const { departmentId, departmentCode } = useAppContext();

  const [show, setShow] = useState(false);
  const [departmentName, setDepartmentName] = useState<string>('');
  const [listDelegate, setListDelegate] = useState<DelegateResponseModel[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [positions, setPositions] = useState<Options[]>([]);
  const [searchData, setSearchData] = useState<SearchDelegateModel>({} as SearchDelegateModel);
  const [departments, setDepartments] = useState<Options[]>([]);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string>('');

  useEffect(() => {
    getDepartmentData();
    getPositionAsync();
    if (departmentId !== undefined) {
      setSearchData((prevState) => ({ ...prevState, departmentId: departmentId }))
      onSearch(page, size, { departmentId: departmentId } as SearchDelegateModel);
    }
  }, [departmentId]);

  const getDepartmentData = async () => {
    const { data, status } = await department.getDepartmentsListAsync<DepartmentListResponse[]>();

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถดึงข้อมูล Department ได้');
      return;
    }

    const newData = data?.map((d) => ({ value: d.id, label: d.name }));

    setDepartments(newData);
  };

  const getPositionAsync = async () => {
    const { data, status } = await shareValue.getPositionListSelectionAsync<PositionListResponse[]>();

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถดึงข้อมูล Position ได้');
      return;
    }

    setPositions(data);
  };

  const getListDelegateAsync = async (
    page = 1,
    size = 10,
    departmentId?: string,
    ownerUserName?: string,
    ownerPositionId?: string,
    delegateUserName?: string,
    delegatePositionId?: string,
    start?: string,
    end?: string,
  ) => {
    const {
      data,
      status,
    } = await delegateService.getListDelegateAsync(page, size, departmentId, ownerUserName, ownerPositionId, delegateUserName, delegatePositionId, start, end);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    setListDelegate(data.data);
    setTotal(data.totalRecords);
  };

  const onDelete = (id: string) => {
    setDeleteId(id);
    setShowDelete(true);
  };

  const handleDelete = async (id: string) => {
    setShowDelete(false);
    setDeleteId('');
    const { status } = await delegateService.deleteDelegateAsync(id);

    if (status !== HttpStatusCode.OK) {
      if (status === HttpStatusCode.BAD_REQUEST) {
        toast.error('ข้อมูลนี้ไม่สามารถลบได้ เนื่องจากการรับมอบอำนาจมีผลแล้ว');
      } else {
        toast.error('ลบข้อมูลไม่สำเร็จ');
      }
      return;
    }

    toast.success('ลบข้อมูลสำเร็จ');
    getListDelegateAsync();
  };

  const onSearch = (page: number, size: number, searchData: SearchDelegateModel) => {
    const { startDate } = searchData;
    const { endDate } = searchData;

    // startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59, 999);

    getListDelegateAsync(page, size, searchData.departmentId, searchData.ownerUserName, searchData.ownerPosition, searchData.delegateUserName, searchData.delegatePosition, startDate?.toISOString(), endDate?.toISOString());
  };

  const onClear = () => {
    setSearchData({ page: 1, size: 10, departmentId: departmentId, startDate: undefined, endDate: undefined, delegatePosition: undefined, delegateUserName: undefined, ownerPosition: undefined, ownerUserName: undefined } as SearchDelegateModel);

    const searchCriteria = {
      departmentId: departmentId, startDate: undefined, endDate: undefined, delegatePosition: undefined, delegateUserName: undefined, ownerPosition: undefined, ownerUserName: undefined
    } as SearchDelegateModel;

    onSearch(1, 10, searchCriteria);
  };

  const onChangePageSize =
    useCallback((page: number, size: number, searchData: SearchDelegateModel) => {
      onSearch(page, size, searchData);
    }, []);

  const onExportReportAsync = async () => {
    const { startDate } = searchData;
    const { endDate } = searchData;

    // startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59, 999);

    const { data } = await delegateService.getDelegateReportAsync(searchData.departmentId, searchData.ownerUserName, searchData.ownerPosition, searchData.delegateUserName, searchData.delegatePosition, startDate?.toISOString(), endDate?.toISOString());

    const todayText = new Date().toISOString()
      .substring(0, 10)
      .replaceAll('-', '');

    downloadReport(data, `รายงานการมอบหมายให้ปฏิบัติหน้าที่แทน_${todayText}.xlsx`);
  };

  const downloadReport = (fileData: Blob, fileName: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(fileData);
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className='document'>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>การมอบหมายให้ปฏิบัติหน้าที่แทน</h4>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2 px-4'
          onClick={() => navigate('add')}
        >
          <FaPlus />เพิ่ม
        </Button>
      </div>
      <hr />
      <Row className='mt-3 align-items-center'>
        <Col sm={12}
          lg={3}>
          <Selector label="ฝ่าย/สำนัก"
            items={departments}
            value={searchData.departmentId}
            onChange={(value) => setSearchData((prevState) => ({ ...prevState, departmentId: value }))} />
        </Col>
      </Row>
      <Row className='mt-3 align-items-center'>
        <Col sm={12}
          lg={3}>
          <Input
            value={searchData.ownerUserName}
            label='ชื่อ - นามสกุล ผู้ปฏิบัติหน้าที่'
            placeholder='ชื่อ - นามสกุล ผู้ปฏิบัติหน้าที่'
            onChange={(value) => setSearchData((prevState) => ({ ...prevState, ownerUserName: value }))}
          />
        </Col>
        <Col sm={12}
          lg={3}>
          <Input
            value={searchData.ownerPosition}
            label='ตำแหน่งผู้ปฏิบัติหน้าที่'
            placeholder='ตำแหน่งผู้ปฏิบัติหน้าที่'
            onChange={(value) => setSearchData((prevState) => ({ ...prevState, ownerPosition: value }))}
          />
        </Col>
        <Col sm={12}
          lg={3}>
          <DatePicker
            value={searchData.startDate}
            label='มอบหมายให้ปฏิบัติหน้าที่แทนตั้งแต่วันที่'
            onChangeHasNullable={(value) => setSearchData((prevState) => ({
              ...prevState,
              startDate: value,
            }))}
          />
        </Col>
        <Col sm={12}
          lg={3}>
          <DatePicker
            value={searchData.endDate}
            label='มอบหมายให้ปฏิบัติหน้าที่แทนถึงวันที่'
            onChangeHasNullable={(value) => setSearchData((prevState) => ({
              ...prevState,
              endDate: value,
            }))}
          />
        </Col>
        <Col sm={12}
          lg={3}>
          <Input
            value={searchData.delegateUserName}
            label='ชื่อ - นามสกุล ผู้ปฏิบัติหน้าที่แทน'
            placeholder='ชื่อ - นามสกุล ผู้ปฏิบัติหน้าที่แทน'
            onChange={(value) => setSearchData((prevState) => ({ ...prevState, delegateUserName: value }))}
          />
        </Col>
        <Col sm={12}
          lg={3}>
          <Input
            value={searchData.delegatePosition}
            label='ตำแหน่งผู้ปฏิบัติหน้าที่แทน'
            placeholder='ตำแหน่งผู้ปฏิบัติหน้าที่แทน'
            onChange={(value) => setSearchData((prevState) => ({ ...prevState, delegatePosition: value }))}
          />
        </Col>
      </Row>

      <div className='d-flex gap-2'>
        <Button
          form='formPL01'
          type='submit'
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => onSearch(page, size, searchData)}
        >
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant='outline-primary'
          className='d-flex align-items-center gap-2'
          onClick={() => onClear()}
        >
          <FaEraser />ล้าง
        </Button>
      </div>
      <div className='d-flex gap-2 justify-content-end'>
        <Button
          variant='outline-primary'
          className='d-flex align-items-center gap-2'
          onClick={onExportReportAsync}
        >
          <FaFileExcel />
          พิมพ์รายงานการมอบหมายให้ปฏิบัติหน้าที่แทน
        </Button>
      </div>
      <Table
        total={total}
        className='mt-4'
        onChange={(size, page) => {
          onChangePageSize(page, size, searchData);
        }}
      >
        <thead>
          <tr className='text-center'>
            <th>ลำดับ</th>
            <th>ชื่อ - นามสกุล<br />
              ผู้ปฏิบัติหน้าที่
            </th>
            <th style={{ minWidth: 250 }}>ตำแหน่ง <br />
              ผู้ปฏิบัติหน้าที่
            </th>
            <th>ชื่อ - นามสกุล<br />
              ผู้ปฏิบัติหน้าที่แทน
            </th>
            <th style={{ minWidth: 250 }}>ตำแหน่ง<br />
              ผู้ปฏิบัติหน้าที่แทน
            </th>
            <th style={{ width: 250 }}>รับมอบอำนาจ<br />
              ตั้งแต่วันที่
            </th>
            <th style={{ width: 250 }}>รับมอบอำนาจ<br />
              ถึงวันที่
            </th>
            <th style={{ width: 200 }}>ผู้ปรับปรุง<br />
              ข้อมูล
            </th>
            <th style={{ width: 200 }}>วันที่ปรับปรุง<br />
              ข้อมูล
            </th>
            <th style={{ width: 150 }}>แก้ไข</th>
            <th style={{ width: 150 }} />
          </tr>
        </thead>
        <tbody>
          {listDelegate.map((d, index) => (
            <tr className='text-center'
              key={index}>
              <td>{calculateRowNumber(index, page, size)}</td>
              <td className='text-start'>{d.ownerDelegates.fullName}</td>
              <td className='text-start text-wrap'>{d.ownerDelegates.position}</td>
              <td className='text-start'>{d.delegateUserDelegates.fullName}</td>
              <td className='text-start text-wrap'>{d.delegateUserDelegates.position}</td>
              <td>{thaiFormatDateWithSlash(d.start)}</td>
              <td>{thaiFormatDateWithSlash(d.end)}</td>
              <td>{d.updatedByFullName}</td>
              <td>{fullDatetime(d.updatedAt)}</td>
              <td className='gap-2'>
                <Button variant="outline-primary" className="gap-2" onClick={() => navigate(`add/${d.ownerDelegates.id}/${d.start}/${d.end}`)}>
                  <FaEdit className="me-2 mb-1" />แก้ไข
                </Button>
              </td>
              <td className='gap-2'>
                <Button
                  onClick={() => onDelete(d.id)}
                  variant='danger'
                >
                  <FaTrashAlt />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <SearchNameModal
        show={show}
        onHide={() => setShow(!show)}
        data={[]}
      />

      <Modal
        show={showDelete}
        size='lg'
        onHide={() => setShowDelete(false)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบข้อมูล</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <p>ต้องการลบข้อมูลผู้ปฏิบัติหน้าที่แทนหรือไม่?</p>
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowDelete(false)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                variant='danger'
                onClick={() => handleDelete(deleteId)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div >
  );
}

export default Delegate;
