import {
  Card,
  Check,
  Currency,
  DatePicker,
  FileValue,
  Input,
  InputTextArea,
  Selector,
  Table,
  UploadFile,
  ArrowCollapse,
  Radio,
} from 'components';
import Title from 'components/Controls/Title';
import {
  HistoryModal,
  Modal,
  SearchBuNameModal,
  SearchNameModal,
} from 'components/Modal';
import {
  BuDepartmentListResponse,
  Committee,
  ItemModel,
  JorPor05Document,
  JorPor05GLAccountModel,
  JorPorO5,
  SolDataListResponse,
  UserListSelectionByCodeParams,
  UserListSelectionParams,
  UserListSelectionResponse,
  dutyModel,
} from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  InputGroup,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import {
  FaHistory,
  FaPlus,
  FaSave,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  account,
  department,
  jorPor05,
  shareValue,
} from 'services';
import {
  downloadFile,
  HttpStatusCode,
  PositionOnBoardIdEnum,
  submitForm,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { generateUniqueId } from '../../../utils/helper';
import {
  AcceptorStatus,
  CommitteeType,
  Context,
  CriteriaModal,
  DataModal,
  JorPor05Status,
  Pagination,
  Section,
  Status,
  UserResponse,
} from './Detail';
import { JorPorCode } from 'constant/basicInformation';
import { InputNumber } from 'components/Controls/InputNumber';

interface Options {
  value: string;
  label: string;
}

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type loader = {
  positionOnBoardProcurementDDL: ItemModel[],
  positionOnBoardInSpectionDDL: ItemModel[],
  positionOnBoardMADDL: ItemModel[],
  positionOnBoardSupervisorDDL: ItemModel[],
  medianPriceConsiderInformationDDL: ItemModel[],
  glAccountDDL: ItemModel[],
  periodTypeDDL: ItemModel[],
  biddingResultConsiderPeriodTypeDDL: ItemModel[],
};

export default function Step1({ onClickNext, onClickBack }: Props) {
  const { positionOnBoardProcurementDDL, positionOnBoardInSpectionDDL, positionOnBoardMADDL, positionOnBoardSupervisorDDL, medianPriceConsiderInformationDDL, glAccountDDL, periodTypeDDL, biddingResultConsiderPeriodTypeDDL } = useLoaderData() as loader;
  const [showSearchUserModal, setShowUserModal] = useState<{ show: boolean, type?: CommitteeType }>({ show: false });
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [criteriaModal, setCriteriaModal] = useState<CriteriaModal>({} as CriteriaModal);
  const [userDataModal, setUserDataModal] = useState<DataModal>({} as DataModal);
  const [paginationModal, setPaginationModal] = useState<Pagination>({ size: 10, page: 1 });
  const [showErrorConsideration, setShowErrorConsideration] = useState<boolean>(false);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [openCollapse3, setOpenCollapse3] = useState(true);
  const [openCollapse4, setOpenCollapse4] = useState(true);
  const [openCollapse5, setOpenCollapse5] = useState(true);
  const [openCollapse6, setOpenCollapse6] = useState(true);
  const [dutyProcurements, setDutyProcurements] = useState<dutyModel[]>([]);
  const [dutyInspectors, setDutyInspectors] = useState<dutyModel[]>([]);
  const [dutyMAs, setDutyMAs] = useState<dutyModel[]>([]);
  const [dutySupervisors, setDutySupervisors] = useState<dutyModel[]>([]);
  const context = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const { departmentCode } = useAppContext();
  const [considerData, setConsiderData] = useState('');
  const [glAccountData, setGLAccountData] = useState<JorPor05GLAccountModel[]>([]);
  const [solData, setSolData] = useState<Options[]>([]);
  const [amountGLTotal, setAmountGLTotal] = useState<number>(0);
  const [showReCall, setShowReCall] = useState(false);

  const onSetConsiderData = async (data: string) => {
    const checkListData = [...medianPriceConsiderInformationDDL];
    const splitData = data.split(',');

    splitData.forEach((s) => checkListData.forEach((c, i) => {
      if (s === c.label) {
        const data = { check: true, value: c.value, label: c.label };

        checkListData.splice(i, 1, data);
      }
    }));

    const consideration = splitData[0];
    const resultData = checkListData?.filter(x => x.label === consideration);

    if (resultData.length > 0) {
      const result = resultData[0].value;
      setConsiderData(result);
    }
  };

  useEffect(() => {
    if (context.data.considerationSelect && context.data.considerationSelect.length > 0) {
      onSetConsiderData(context.data.considerationSelect);

    }

    // setCheckList(checkData);
  }, [context.data.considerationSelect]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }

    getSolData();

  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  useEffect(() => {
    if (context.data.supplyMethod) {
      context.getSupplyMethodSpecialTypeAsync(context.data.supplyMethod);
    } else {
      context.setData({
        ...context.data,
        supplyMethodSpecialType: '',
      });
    }
  }, [context.data.supplyMethod]);

  useEffect(() => {
    if (context.data.glAccounts) {
      setGLAccountData(context.data.glAccounts);
      onSetGLAmountTotal(context.data.glAccounts);
    }
  }, [context.data.glAccounts]);

  const onSetGLAmountTotal = async (data: JorPor05GLAccountModel[]) => {
    const sumData = data.reduce((a, v) => a += v.amount!, 0);
    setAmountGLTotal(sumData);
  };

  useEffect(() => {
    if (context.data.dutyProcurements) {
      setDutyProcurements(context.data.dutyProcurements);
    }
  }, [context.data.dutyProcurements]);

  useEffect(() => {
    if (context.data.dutyInspectors) {
      setDutyInspectors(context.data.dutyInspectors);
    }
  }, [context.data.dutyInspectors]);

  useEffect(() => {
    if (context.data.dutyMAs) {
      setDutyMAs(context.data.dutyMAs);
    }
  }, [context.data.dutyMAs]);

  useEffect(() => {
    if (context.data.dutySupervisors) {
      setDutySupervisors(context.data.dutySupervisors);
    }
  }, [context.data.dutySupervisors]);

  useEffect(() => {
    if (context.data.dutyInspectors) {
      setDutyInspectors(context.data.dutyInspectors);
    }
  }, [context.data.dutyInspectors]);

  useEffect(() => {
    if (context.isAmountOver) {
      context.setData({ ...context.data, considerationDescription: '' });
    } else {
      context.setData({ ...context.data, considerationSelect: '' });
    }
  }, [context.isAmountOver]);

  const getUserAsync = async (
    size: number,
    page: number,
    name?: string,
    departmentId?: string,
    positionId?: string,
  ) => {
    const res = await account.getUsersOnModalAsync(
      size,
      page,
      name,
      departmentId,
      positionId,
    );

    if (res.status === HttpStatusCode.OK) {
      const data: UserListSelectionResponse[] =
        res.data.data.map((user: UserResponse) => ({
          id: user.id,
          fullName: user.name,
          departmentName: user.department,
          positionName: user.position,
        }));

      setUserDataModal({
        data: data,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onShowSearchUserModalAsync = async (type: CommitteeType) => {
    await getUserAsync(paginationModal.size, paginationModal.page);

    setShowUserModal({
      show: true,
      type,
    });
  };

  const onModalPageChange = async (size: number, page: number) => {
    setPaginationModal({ size, page });

    getUserAsync(
      size,
      page,
      criteriaModal.fullName,
      criteriaModal.departmentId,
      criteriaModal.positionId,
    );
  };

  const onModalSearch = (params: UserListSelectionParams) => {
    setCriteriaModal({
      fullName: params.fullName,
      departmentId: params.departmentId,
      positionId: params.positionId,
    });

    getUserAsync(
      paginationModal.size,
      paginationModal.page,
      params.fullName,
      params.departmentId,
      params.positionId,
    );
  };

  // const onModalSelect = (id: string, section?: string) => {
  //   if (context.data.committees?.some(r => r.userId === id)) {
  //     toast.warn('รายชื่อนี้ถูกเลือกแล้ว');

  //     return;
  //   }

  //   const user = userDataModal.data.find(u => u.id === id);

  //   if (user) {
  //     if (!context.data.committees) {
  //       context.data.committees = [];
  //     }

  //     const chairmanValue = context.positionOnBoardList[0].value;
  //     const alreadyData = context.data.committees.find(f => f.positionOnBoardId === chairmanValue && f.committeeType === section)
  //       ? context.positionOnBoardList[1].value : chairmanValue;
  //     const positionInit = context.positionOnBoardList.find(i => i.value === alreadyData)?.value;

  //     context.setData({
  //       ...context.data,
  //       committees: [
  //         ...context.data.committees,
  //         {
  //           userId: user.id,
  //           fullName: user.fullName,
  //           positionName: user.positionName,
  //           committeeType: showSearchUserModal.type,
  //           positionOnBoardId: positionInit!,
  //         } as Committee,
  //       ],
  //     });
  //   }
  // };

  const onModalSelect = (user: any) => {
    if (showSearchUserModal.type === CommitteeType.PROCUREMENT
      || showSearchUserModal.type === CommitteeType.INSPECT) {
      if (context.data.committees
        && context.data.committees.filter(x => x.userId === user.id && x.committeeType === CommitteeType.PROCUREMENT).length > 0) {

        const committeeProcurementData = context.data.committees.filter((x) => x.userId === user.id)[0];
        const noti = ' ถูกกำหนดอยู่ในผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้างแล้ว กรุณาตรวจสอบข้อมูล';
        toast.warn(`${committeeProcurementData.fullName + noti}`);
        return;
      }

      if (context.data.committees
        && context.data.committees.filter(x => x.userId === user.id && x.committeeType === CommitteeType.INSPECT).length > 0) {

        const committeeProcurementData = context.data.committees.filter((x) => x.userId === user.id)[0];
        const noti = ' ถูกกำหนดอยู่ในรายชื่อผู้ตรวจรับพัสดุ/คณะกรรมการตรวจรับแล้ว กรุณาตรวจสอบข้อมูล';
        toast.warn(`${committeeProcurementData.fullName + noti}`);
        return;
      }
    } else if (context.data.committees
      && context.data.committees.filter(x => x.userId === user.id && x.committeeType === showSearchUserModal.type).length > 0) {
      const committeeProcurementData = context.data.committees.filter((x) => x.userId === user.id)[0];
      const noti = ' ถูกกำหนดอยู่ในรายชื่อแล้ว กรุณาตรวจสอบข้อมูล';
      toast.warn(`${committeeProcurementData.fullName + noti}`);
      return;
    }

    if (user) {
      if (!context.data.committees) {
        context.data.committees = [];
      }

      context.setData({
        ...context.data,
        committees: [
          ...context.data.committees,
          {
            userId: user.id,
            fullName: user.fullName,
            positionName: user.positionName,
            committeeType: showSearchUserModal.type,
            positionOnBoardId: positionOnBoardProcurementDDL[0].value,
          } as Committee,
        ],
      });
    }
  };

  const removeUser = (userId: string, type: CommitteeType) => {
    const index = context.data.committees.findIndex(c => c.userId === userId && c.committeeType === type);

    if (index >= 0) {
      context.data.committees.splice(index, 1);

      context.setData({
        ...context.data,
        committees: [
          ...context.data.committees,
        ],
      });
    }
  };

  const onModalHide = () => {
    setShowUserModal({ show: false, type: undefined });
    setCriteriaModal({} as CriteriaModal);
    setPaginationModal({ size: 10, page: 1 });
  };

  const onSubmitAsync = async (status: Status, isRecall?: boolean) => {
    submitForm();
    const checkRequest =
      context.data.supplyMethod &&
      context.data.supplyMethodType &&
      context.data.supplyMethodSpecialType &&
      context.data.financialAmount &&
      context.data.prNumber &&
      // ((!context.isAmountOver && context.data.considerationDescription) ||
      //   (context.isAmountOver && considerData)) &&
      // ((!context.isAmountOver && context.data.considerationDescription) ||
      //   (context.isAmountOver && context.data.considerationSelect)) &&
      context.data.selectionCriteria;

    const isCommitteeError = context.data.committees?.some(t => !t.positionOnBoardId);

    const filterProcurement = context.data.committees?.filter(c => c.committeeType === CommitteeType.PROCUREMENT);
    const filterInspector = context.data.committees?.filter(c => c.committeeType === CommitteeType.INSPECT);

    if (filterProcurement.length === 0) {
      toast.warn('กรุณาเพิ่มรายชื่อผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้าง');

      return;
    }

    if (filterInspector.length === 0) {
      toast.warn('กรุณาเพิ่มรายชื่อผู้ตรวจรับพัสดุ/คณะกรรมการตรวจรับ');

      return;
    }

    const isCommitteeProMoreThen1Error = filterProcurement.length > 1 && filterProcurement.filter(c => c.positionOnBoardId === PositionOnBoardIdEnum.Chairman).length === 0;

    const isCommitteeInsMoreThen1Error = filterInspector.length > 1 && filterInspector.filter(c => c.positionOnBoardId === PositionOnBoardIdEnum.Chairman).length === 0;

    const isCommitteeProEqual1Error = filterProcurement.length === 1 && filterProcurement.filter(c => c.positionOnBoardId === PositionOnBoardIdEnum.Procurement).length === 0;

    const isCommitteeInsEqual1Error = filterInspector.length === 1 && filterInspector.filter(c => c.positionOnBoardId === PositionOnBoardIdEnum.Inspector).length === 0;

    if (context.isAmountOver && !considerData) {
      setShowErrorConsideration(true);
    }

    // if (context.isAmountOver && !context.data.considerationSelect) {
    //   setShowErrorConsideration(true);
    // }

    if (!checkRequest || isCommitteeError) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');

      return;
    }

    // if (isCommitteeProMoreThen1Error) {
    //   toast.warn('คณะกรรมการจัดซื้อจัดจ้าง ต้องมีประธานกรรมการอย่างน้อย 1 คน');

    //   return;
    // }

    // if (isCommitteeInsMoreThen1Error) {
    //   toast.warn('คณะกรรมการตรวจรับ ต้องมีประธานกรรมการอย่างน้อย 1 คน');

    //   return;
    // }

    // if (isCommitteeProEqual1Error) {
    //   toast.warn('รายชื่อผู้จัดซื้อจัดจ้าง 1 คน ต้องเลือกตำแหน่งเป็นผู้จัดซื้อจัดจ้าง');

    //   return;
    // }

    // if (isCommitteeInsEqual1Error) {
    //   toast.warn('รายชื่อผู้ตรวจรับ 1 คน ต้องเลือกตำแหน่งเป็นผู้ตรวจรับ');

    //   return;
    // }

    if (glAccountData && glAccountData.filter(x => x.departmentCode === null || x.departmentCode === undefined || x.departmentCode === '').length > 0) {
      toast.warn('กรุณาระบุ Sol ID');
      return;
    }

    if (glAccountData && glAccountData.filter(x => x.glAccountId === null || x.glAccountId === undefined || x.glAccountId === '').length > 0) {
      toast.warn('กรุณาระบุรหัสบัญชี');
      return;
    }

    if (glAccountData.reduce((a, v) => a += v.amount!, 0) !== amountGLTotal) {
      toast.warn('ต้องระบุจำนวนเงินของข้อมูลรหัสบัญชีและการใช้งบประมาณของฝ่ายเท่ากับวงเงินที่จะจัดจ้าง');
      return;
    }

    const saveDutyProcurements = dutyProcurements?.map((a, i) => ({ ...a, sequence: i + 1 }));
    const saveDutyInspectors = dutyInspectors?.map((a, i) => ({ ...a, sequence: i + 1 }));
    const saveDutyMAs = dutyMAs?.map((a, i) => ({ ...a, sequence: i + 1 }));
    const savedutySupervisors = dutySupervisors?.map((a, i) => ({ ...a, sequence: i + 1 }));

    let data = { ...context.data, status } as JorPorO5;

    if (context.isAmountOver) {
      data = { ...data, considerationDescription: '' };
    } else {
      data = { ...data, considerationSelect: '' };
    }

    if (considerData) {
      const checkListData = [...medianPriceConsiderInformationDDL];
      const resultConsider = checkListData.filter(x => x.value === considerData)[0].label

      data = { ...data, considerationSelect: resultConsider };
    }

    if (glAccountData) {
      data = { ...data, glAccounts: glAccountData };
    }

    if (saveDutyProcurements) {
      data = { ...data, dutyProcurements: saveDutyProcurements };
    }

    if (saveDutyInspectors) {
      data = { ...data, dutyInspectors: saveDutyInspectors };
    }

    if (saveDutyMAs) {
      data = { ...data, dutyMAs: saveDutyMAs };
    }

    if (savedutySupervisors) {
      data = { ...data, dutySupervisors: savedutySupervisors };
    }

    if (context.data.id) {
      const res = await jorPor05.updateAsync(data);

      if (res.status === HttpStatusCode.ACCEPTED) {
        if (isRecall) {
          toast.success('เรียกคืนแก้ไขสำเร็จ');
        } else {
          toast.success('บันทึกข้อมูลสำเร็จ');
        }
        setShowReCall(false);
        context.getDetailAsync(res.data.id);
      }
    } else {
      const res = await jorPor05.saveAsync(data);

      if (res.status === HttpStatusCode.CREATED) {
        toast.success('บันทึกข้อมูลสำเร็จ');

        context.getDetailAsync(res.data.id);
      }
    }
  };

  const onChange = (key: string, value: string | number | undefined) => {
    context.setData({
      ...context.data,
      [key]: value,
    });
  };

  const uploadFileOnChangeAsync = async (file: File) => {
    if (context.data.id) {
      const res = await jorPor05.uploadFileAsync(
        context.data.id,
        file,
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        context.setData({
          ...context.data,
          documents: [
            ...context.data.documents,
            {
              file,
            } as JorPor05Document,
          ],
        });

        toast.success('อัปโหลดไฟล์สำเร็จ');

        context.getDetailAsync(context.data.id);
      }
    } else {
      if (!context.data.documents) {
        context.data.documents = [];
      }

      context.setData({
        ...context.data,
        documents: [
          ...context.data.documents,
          {
            file,
          } as JorPor05Document,
        ],
      });
    }
  };

  const removeFileAsync = async (i: number, id: string | undefined) => {
    if (id) {
      const res = await jorPor05.removeFileAsync(context.data.id, id);

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
      }
    }

    context.data.documents.splice(i, 1);

    context.setData({
      ...context.data,
      documents: [...context.data.documents],
    });
  };

  const downloadFileAsync = async (index: number, documentId?: string) => {
    if (documentId) {
      const res = await jorPor05
        .downloadFileAsync(context.data.id, documentId);

      if (res.status === HttpStatusCode.OK) {
        const file = context.data.documents[index];

        downloadFile(res.data);
      }
    } else {
      const file = context.data.documents[index];

      if (file.file) {
        downloadFile(file.file);
      }
    }
  };

  const fileList = useMemo(() => {
    const files = context.data.documents?.filter((d) => d.id && d.fileName);

    return files?.map((f) => ({
      id: f.id,
      name: f.fileName,
    })) as FileValue[];
  }, [context.data.documents]);

  const onConsiderationChange = (value: string, result: boolean) => {
    if (result) {
      if (context.data.considerationSelect) {
        const items = [];

        if (context.data.considerationSelect.includes(',')) {
          items.push(...context.data.considerationSelect.split(','));
        } else {
          items.push(context.data.considerationSelect);
        }

        items.push(value);

        context.setData({
          ...context.data,
          considerationSelect: items.join(','),
        });

        setShowErrorConsideration(false);

        return;
      }

      context.setData({
        ...context.data,
        considerationSelect: value,
      });

      setShowErrorConsideration(false);

      return;
    }

    const items = context.data.considerationSelect.split(',');
    const index = items.findIndex(cs => cs === value);

    items.splice(index, 1);

    if (items.length) {
      context.setData({
        ...context.data,
        considerationSelect: items.join(','),
      });

      return;
    }

    context.setData({
      ...context.data,
      considerationSelect: '',
    });

    setShowErrorConsideration(true);
  };

  const onPositionOnBoardChange = (userId: string, value: string, comitteeType: CommitteeType) => {
    const index = context.data.committees.findIndex(c => c.userId === userId && c.committeeType === comitteeType);

    if (index >= 0) {
      context.data.committees[index].positionOnBoardId = value;

      context.setData(context.data);
    }
  };

  const procurementList = useMemo(() => {
    return context.data.committees?.filter(c => c.committeeType === CommitteeType.PROCUREMENT);
  }, [context.data.committees]);

  const inspectList = useMemo(() => {
    return context.data.committees?.filter(c => c.committeeType === CommitteeType.INSPECT);
  }, [context.data.committees]);

  const maintenanceList = useMemo(() => {
    return context.data.committees?.filter(c => c.committeeType === CommitteeType.MA);
  }, [context.data.committees]);

  const supervisorList = useMemo(() => {
    return context.data.committees?.filter(c => c.committeeType === CommitteeType.SUPERVISOR);
  }, [context.data.committees]);

  const isDisableForm = useMemo(() => {
    if (!context.data.isAssign) {
      return true;
    }

    if (context.data.status === Status.ACCEPTED) {
      return true;
    }

    return false;
  }, [context.data]);

  const isSaveShow = useMemo(() => {
    if (!context.data.isAssign) {
      return false;
    }

    if (context.data.status !== Status.DRAFT &&
      context.data.status !== Status.REJECT) {
      return false;
    }

    return true;
  }, [context.data]);

  const isRecallShow = useMemo(() => {
    if (!context.data.isAssign) {
      return false;
    }

    if (context.data.status !== Status.WAITING_ACCEPT) {
      return false;
    }

    if (context.data.acceptors?.some(a => a.status !== AcceptorStatus.PENDING)) {
      return false;
    }

    return true;
  }, [context.data]);

  const handlerAddDutyProcurements = (duty: string) => {
    const newData: dutyModel = {
      sequence: dutyProcurements.length + 1,
      duty,
    };

    setDutyProcurements((dutyProcurements) => [...dutyProcurements, newData]);
  };

  const handlerOnChangeDutyProcurements = (value: string, index: number) => {
    const data = [...dutyProcurements];
    data[index].duty = value;
    setDutyProcurements(data);
  };

  const handlerRemoveDutyProcurements = (i: number) => {
    const temp: dutyModel[] = [...dutyProcurements];
    temp.splice(i, 1);
    setDutyProcurements(temp);
  };

  const handlerAddDutyInspectors = (duty: string) => {
    const newData: dutyModel = {
      sequence: dutyInspectors.length + 1,
      duty,
    };

    setDutyInspectors((dutyInspectors) => [...dutyInspectors, newData]);
  };

  const handlerOnChangeDutyInspectors = (value: string, index: number) => {
    const data = [...dutyInspectors];
    data[index].duty = value;
    setDutyInspectors(data);
  };

  const handlerRemoveDutyInspectors = (i: number) => {
    const temp: dutyModel[] = [...dutyInspectors];
    temp.splice(i, 1);
    setDutyInspectors(temp);
  };

  const handlerAddDutys = (duty: string, type: CommitteeType) => {
    if (type === CommitteeType.MA) {
      const newData: dutyModel = {
        sequence: dutyMAs.length + 1,
        duty,
      };

      setDutyMAs((dutyMAs) => [...dutyMAs, newData]);
    } else if (type === CommitteeType.SUPERVISOR) {
      const newData: dutyModel = {
        sequence: dutySupervisors.length + 1,
        duty,
      };

      setDutySupervisors((dutySupervisors) => [...dutySupervisors, newData]);
    }
  };

  const handlerOnChangeDutys = (value: string, index: number, type: CommitteeType) => {
    if (type === CommitteeType.MA) {
      const data = [...dutyMAs];
      data[index].duty = value;
      setDutyMAs(data);
    } else if (type === CommitteeType.SUPERVISOR) {
      const data = [...dutySupervisors];
      data[index].duty = value;
      setDutySupervisors(data);
    }
  };

  const handlerRemoveDutys = (i: number, type: CommitteeType) => {
    if (type === CommitteeType.MA) {
      const temp: dutyModel[] = [...dutyMAs];
      temp.splice(i, 1);
      setDutyMAs(temp);
    } else if (type === CommitteeType.SUPERVISOR) {
      const temp: dutyModel[] = [...dutySupervisors];
      temp.splice(i, 1);
      setDutySupervisors(temp);
    }
  };

  const handlerOnChangeDepartmentCodes = (value: string, index: number) => {
    const data = [...glAccountData];
    data[index].departmentCode = value;
    setGLAccountData(data);
  };

  const handlerOnChangeGLAccountId = (value: string, index: number) => {
    const data = [...glAccountData];
    data[index].glAccountId = value;
    setGLAccountData(data);
  };

  const handlerOnChangeAmount = (value: number, index: number) => {
    const data = [...glAccountData];
    data[index].amount = value;
    setGLAccountData(data);
    // onSetGLAmountTotal(data);
  };

  const addGLAccount = useCallback(async (sequence: number) => {
    const newData: JorPor05GLAccountModel = {
      id: undefined,
      departmentCode: '',
      glAccountId: '',
      amount: 0,
      sequence: sequence
    };
    setGLAccountData((glAccountData) => [...glAccountData, newData]);
  }, [glAccountData]);

  const removeGLAccount = (i: number) => {
    const temp: JorPor05GLAccountModel[] = [...glAccountData];
    temp.splice(i, 1);
    setGLAccountData(temp);
  };

  const getSolData = async () => {
    const { data, status } = await department.getSolDatasListAsync<SolDataListResponse[]>();

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถดึงข้อมูล Sol Id ได้');
      return;
    }

    const newData = data?.map((d) => ({ value: d.buId, label: d.buName ?? "" }));
    setSolData(newData);
  };

  return (
    <>
      <Card>
        <Title
          text='จัดทำรายงานขอซื้อขอจ้าง (จพ.005)'
          className='fs-5 text-primary'
          extraElement={
            <div className='d-flex gap-2'>
              {context.data.id ?
                <JorPor05Status value={context.data.status} /> :
                <JorPor05Status value='None' />
              }
              <Button
                onClick={() => setShowHistoryModal(true)}
                variant='outline-primary'
                className='d-flex justify-content-center align-items-center gap-2'>
                <FaHistory />ประวัติการใช้งาน
              </Button>
            </div>} />
        <Row className='mt-3'>
          <Col lg={6}
            xxl={4}>
            <Input
              label='เลขที่รายงานขอซื้อขอจ้าง (จพ.005)'
              value={context.data.jorPor05Number}
              disabled />
          </Col>
        </Row>
        <Row>
          <Col lg={6}
            xxl={4}>
            <Input
              label='อ้างอิงแผนการจัดซื้อจัดจ้าง'
              value={context.data.termOfRefPlanNumber}
              disabled />
          </Col>
          <Col lg={6}
            xxl={4}>
            <Input
              label='เลขที่รายงานขอซื้อขอจ้าง (จพ.004)'
              value={context.data.basicInformationNumber}
              disabled />
          </Col>
        </Row>
        <Row>
          <Col lg={6}
            xxl={4}>
            <Selector
              items={context.departmentList}
              label='ฝ่าย/สำนัก'
              value={context.data.departmentId}
              disabled />
          </Col>
          <Col lg={6}
            xxl={4}>
            <Input
              label='ปีงบประมาณ'
              rule={{ required: true }}
              value={context.data.termOfRefBudgetYear}
              disabled />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Selector
              label='วิธีการจัดหา'
              rule={{ required: true }}
              value={context.data.supplyMethod}
              items={context.supplyMethodList}
              onChange={val => onChange('supplyMethod', val)}
              disabled />
          </Col>
          <Col lg={4}>
            <Selector
              className='no-label'
              rule={{ required: true }}
              value={context.data.supplyMethodType}
              items={context.supplyMethodTypeList}
              onChange={val => onChange('supplyMethodType', val)}
              disabled />
          </Col>
          <Col lg={4}>
            <Selector
              className='no-label'
              rule={{ required: true }}
              value={context.data.supplyMethodSpecialType}
              items={context.supplyMethodSpecialTypeList}
              onChange={val => onChange('supplyMethodSpecialType', val)}
              disabled />
          </Col>
        </Row>
        <Row>
          <Col lg={6}
            xxl={4}>
            <Currency
              label='วงเงิน (บาท)'
              rule={{ required: true }}
              value={context.data.financialAmount}
              onChange={val => onChange('financialAmount', val)}
              disabled={isDisableForm} />
          </Col>
          <Col lg={6}
            xxl={4}>
            <DatePicker
              label='ประมาณการช่วงเวลาการจัดซื้อขอจ้าง'
              value={context.data.expectingProcurementAt}
              rule={{ required: true }}
              disabled />
          </Col>
        </Row>
        <Row>
          <Col lg={6}
            xxl={4}>
            <DatePicker
              label='วันที่เอกสารบันทึกข้อความแต่งตั้ง'
              value={context.data.termOfRefMemorandumDate}
              disabled />
          </Col>
          <Col lg={6}
            xxl={4}>
            <Input
              label='เลขที่บันทึกแต่งตั้ง'
              value={context.data.termOfRefMemorandumNo}
              disabled />
          </Col>
        </Row>
        <Row>
          <Col xxl={8}>
            <InputTextArea
              label='ชื่อโครงการ (เรื่อง)'
              value={context.data.planName}
              disabled={isDisableForm}
              onChange={val => onChange('planName', val)} />
          </Col>
        </Row>
        <Row>
          <Col xxl={8}>
            <InputTextArea
              label='ที่มาและเหตุผล'
              disabled={isDisableForm}
              rows={5}
              value={context.data.sourceAndReasons} />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title
          text='จัดทำรายงานขอซื้อขอจ้าง (จพ.005)'
          className='fs-5 text-primary' />
        <Row className='mt-3'>
          <Col lg={6}
            xxl={4}>
            <Input
              label='เลขที่เอกสาร'
              value={context.data.documentNumber}
              onChange={val => onChange('documentNumber', val)}
              disabled={isDisableForm}
              textCenter />
          </Col>
          <Col lg={6}
            xxl={4}>
            <Input
              label='เลขที่เอกสาร PR'
              value={context.data.prNumber}
              onChange={val => onChange('prNumber', val)}
              rule={{ required: true }}
              disabled={isDisableForm}
              textCenter />
          </Col>
        </Row>
        <>
          {!context.isAmountOver ?
            <Row>
              <Col xxl={8}>
                <p className='mb-0'>ราคากลางของพัสดุที่จะซื้อหรือจ้างเช่า หรือ
                  ข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคา</p>
                <p>กรณีวงเงิน <u>ไม่เกิน 100,000 บาท</u> มีข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคา ดังนี้</p>
              </Col>
              <Col xxl={8}>
                <InputTextArea
                  value={context.data.considerationDescription}
                  onChange={val => onChange('considerationDescription', val)}
                  // rule={{ required: !context.isAmountOver }}
                  disabled={true}
                  className='mb-3' />
              </Col>
            </Row> :
            <Row className='mt-3'>
              <Col xxl={8}>
                <p>กรณีวงเงิน <u>เกิน 100,000 บาท</u> มีข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคา ดังนี้</p>
              </Col>
              <Col xs={12}>
                {/* {CONSIDERATION_LIST.map((data, i) =>
                  <Check
                    key={generateUniqueId(i)}
                    label={data.name}
                    value={context.data.considerationSelect?.includes(data.value)}
                    disabled={isDisableForm}
                    onChange={val => onConsiderationChange(data.value, val)} />)} */}
                <Radio
                  name='result'
                  disabled={true}
                  items={medianPriceConsiderInformationDDL}
                  value={considerData}
                  // rule={{ required: true }}
                  onChange={(val) => setConsiderData(val.toString())}
                />
                {showErrorConsideration ?
                  <div className='mb-3'>
                    <Form.Text className='text-danger'>
                      กรุณาเลือกข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคาอย่างน้อย 1 ข้อ
                    </Form.Text>
                  </div> : null}
              </Col>
            </Row>}
        </>
        <Row>
          <Col sm={12}
            lg={3}>
            <Selector
              label='หลักเกณฑ์การคัดเลือก'
              placeholder='หลักเกณฑ์การคัดเลือก'
              items={context.selectionCriteriaList}
              value={context.data.selectionCriteria}
              onChange={val => onChange('selectionCriteria', val)}
              rule={{ required: true }}
              disabled={isDisableForm} />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='ข้อมูลรหัสบัญชีและการใช้งบประมาณของฝ่าย'
            className='fs-5 text-primary' />
          {openCollapse6 ? (
            <ArrowCollapse onClick={() => setOpenCollapse6(!openCollapse6)} openCollapse={openCollapse6} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse6(true)} openCollapse={openCollapse6} />)}
        </div>
        <Collapse in={openCollapse6}>
          <div>
            <Row className='mt-2'>
              <Col sm={12}
                lg={3}>
                <Currency
                  disabled
                  label='รวมเป็นเงิน'
                  placeholder='รวมเป็นเงิน'
                  value={amountGLTotal} />
              </Col>
            </Row>
            <div className='d-flex justify-content-end my-3'>
              <Button
                variant='outline-primary'
                onClick={() => addGLAccount(glAccountData.length + 1)}
                disabled={isDisableForm}
              >
                <FaPlus className='me-2' />เพิ่มข้อมูล
              </Button>
            </div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 20 }}>ลำดับ</th>
                      <th style={{ width: 600 }}>Sol ID</th>
                      <th style={{ minWidth: 150 }}>รหัสบัญชี</th>
                      <th style={{ width: 250 }}>จำนวนเงิน</th>
                      <th style={{ width: 20 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {glAccountData?.sort((a, b) => a.sequence - b.sequence).map((data, index) => (
                      <tr key={index}>
                        <td className='text-center'>{index + 1}</td>
                        <td>
                          <Col sm={12}>
                            <Selector
                              items={solData}
                              value={data.departmentCode}
                              onChange={(val) => handlerOnChangeDepartmentCodes(val, index)}
                              disabled={isDisableForm} />
                          </Col>
                        </td>
                        <td>
                          <Col sm={12}>
                            <Selector
                              items={glAccountDDL}
                              value={data.glAccountId}
                              onChange={(val) => handlerOnChangeGLAccountId(val, index)}
                              disabled={isDisableForm} />
                          </Col>
                        </td>
                        <td><Currency disabled={isDisableForm}
                          value={data.amount}
                          onChange={(val) => handlerOnChangeAmount(Number(val), index)} />
                        </td>
                        <td className='text-end'>
                          <Button
                            onClick={() => removeGLAccount(index)}
                            variant='danger'
                            disabled={isDisableForm}
                          >
                            <FaTrashAlt />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='การกำหนดระยะเวลาในการพิจารณาผลการเสนอราคา' className='fs-5 text-primary' />
            {
              openCollapse1 ? (
                <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
              ) : (<ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />)}
          </div>
        </div>
        <Collapse in={openCollapse1}>
          <>
            <Row className='gap-5'>
              <Col sm={12}>
                <span>การกำหนดระยะเวลาในการพิจารณาผลการเสนอราคาให้แล้วเสร็จ</span>
              </Col>
            </Row>
            <Row className='gap-5'>
              <Col sm={12} lg={2}>
                <InputNumber label='ภายใน'
                  value={context.data.biddingResultConsiderPeriodDay}
                  onChange={(val) => onChange('biddingResultConsiderPeriodDay', val)}
                  disabled={isDisableForm} />
              </Col>
              <Col sm={12} lg={2}>
                <Form.Group className='mb-1'>
                  <InputGroup hasValidation>
                    <Selector
                      className='no-label'
                      items={periodTypeDDL}
                      value={context.data.biddingResultConsiderPeriodUnit}
                      onChange={(val) => onChange('biddingResultConsiderPeriodUnit', val)}
                      disabled={isDisableForm}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={12} lg={2}>
                <Form.Group className='mb-1'>
                  <InputGroup hasValidation>
                    <Selector
                      className='no-label'
                      items={biddingResultConsiderPeriodTypeDDL}
                      value={context.data.biddingResultConsiderPeriodType}
                      onChange={(val) => onChange('biddingResultConsiderPeriodType', val)}
                      disabled={isDisableForm}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <Title
          text='ผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้าง'
          className='fs-5 text-primary' />
        <>
          {!isDisableForm ?
            <div className='d-flex justify-content-end my-3'>
              <Button
                variant='outline-primary'
                onClick={() => onShowSearchUserModalAsync(CommitteeType.PROCUREMENT)}
                className='d-flex align-items-center gap-2'>
                <FaPlus />เพิ่มรายชื่อ
              </Button>
            </div> : null}
        </>
        <div style={{ paddingLeft: 40 }}>
          <Table hidePagination>
            <thead>
              <tr>
                <th style={{ width: '5%' }}>ลำดับ</th>
                <th style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
                <th style={{ width: '30%' }}>ตำแหน่ง</th>
                <th style={{ width: '30%' }}>ตำแหน่งในบุคคล/คณะกรรมการ</th>
                <th style={{ width: '5%' }} />
              </tr>
            </thead>
            <tbody>
              {procurementList?.map((data, i) => (
                <tr key={data.id}>
                  <td className='text-center'>{i + 1}</td>
                  <td className='text-start'>{data.fullName}</td>
                  <td className='text-start'>{data.positionName}</td>
                  <td>
                    <Selector
                      items={positionOnBoardProcurementDDL}
                      value={data.positionOnBoardId}
                      onChange={val => onPositionOnBoardChange(data.userId, val, CommitteeType.PROCUREMENT)}
                      rule={{ required: true }}
                      disabled={isDisableForm}
                    />
                  </td>
                  <td>
                    {!isDisableForm ?
                      <div className='d-flex justify-content-end'>
                        <Button
                          variant='danger'
                          className='d-flex justify-content-center align-items-center'
                          onClick={() => removeUser(data.userId, CommitteeType.PROCUREMENT)}
                          disabled={isDisableForm}>
                          <FaTrashAlt />
                        </Button>
                      </div> : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card>
      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='อำนาจหน้าที่คณะกรรมการจัดจ้าง' className='fs-5 text-primary' />
            {
              openCollapse2 ? (
                <ArrowCollapse onClick={() => setOpenCollapse2(!openCollapse2)} openCollapse={openCollapse2} />
              ) : (<ArrowCollapse onClick={() => setOpenCollapse2(true)} openCollapse={openCollapse2} />)}
          </div>
          <Button
            variant='outline-primary'
            onClick={() => handlerAddDutyProcurements('')}
            disabled={isDisableForm}>
            <FaPlus className='me-1' />
            เพิ่มข้อมูล
          </Button>
        </div>
        <Collapse in={openCollapse2}>
          <Row className='gap-5'>
            <Table hidePagination>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>ลำดับ</th>
                  <th style={{ width: '90%' }}>รายละเอียด</th>
                  <th style={{ width: '5%' }} />
                </tr>
              </thead>
              <tbody>
                {dutyProcurements.map((data, index) => (
                  <tr className='text-center' key={index}>
                    <td>{index + 1}</td>
                    <td className='text-start'>
                      <Form.Group className='mb-3'>
                        <InputGroup>
                          <Input
                            rule={{ required: true }}
                            type='text'
                            value={data.duty || ''}
                            disabled={isDisableForm}
                            onChange={(val) => handlerOnChangeDutyProcurements(val, index)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex justify-content-end'>
                        <Button
                          variant='danger'
                          className='d-flex align-items-center gap-2'
                          onClick={() => handlerRemoveDutyProcurements(index)}
                          disabled={isDisableForm}
                        >
                          <FaTrashAlt />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <Title
          text='รายชื่อผู้ตรวจรับพัสดุ/คณะกรรมการตรวจรับ'
          className='fs-5 text-primary' />
        <>
          {!isDisableForm ?
            <div className='d-flex justify-content-end my-3'>
              <Button
                variant='outline-primary'
                onClick={() => onShowSearchUserModalAsync(CommitteeType.INSPECT)}
                className='d-flex align-items-center gap-2'>
                <FaPlus />เพิ่มรายชื่อ
              </Button>
            </div> : null}
        </>
        <div style={{ paddingLeft: 40 }}>
          <Table hidePagination>
            <thead>
              <tr>
                <th style={{ width: '5%' }}>ลำดับ</th>
                <th style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
                <th style={{ width: '30%' }}>ตำแหน่ง</th>
                <th style={{ width: '30%' }}>ตำแหน่งในบุคคล/คณะกรรมการ</th>
                <th style={{ width: '5%' }} />
              </tr>
            </thead>
            <tbody>
              {inspectList?.map((data, i) => (
                <tr key={data.id}>
                  <td className='text-center'>{i + 1}</td>
                  <td className='text-start'>{data.fullName}</td>
                  <td className='text-start'>{data.positionName}</td>
                  <td>
                    <Selector
                      items={positionOnBoardInSpectionDDL}
                      value={data.positionOnBoardId}
                      onChange={val => onPositionOnBoardChange(data.userId, val, CommitteeType.INSPECT)}
                      rule={{ required: true }}
                      disabled={isDisableForm} />
                  </td>
                  <td>
                    {!isDisableForm ?
                      <div className='d-flex justify-content-end'>
                        <Button
                          variant='danger'
                          className='d-flex justify-content-center align-items-center'
                          onClick={() => removeUser(data.userId, CommitteeType.INSPECT)}
                          disabled={isDisableForm}>
                          <FaTrashAlt />
                        </Button>
                      </div> : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='อำนาจหน้าที่กรรมการตรวจรับพัสดุ' className='fs-5 text-primary' />
            {
              openCollapse3 ? (
                <ArrowCollapse onClick={() => setOpenCollapse3(!openCollapse3)} openCollapse={openCollapse3} />
              ) : (
                <ArrowCollapse onClick={() => setOpenCollapse3(true)} openCollapse={openCollapse3} />
              )}
          </div>
          <Button
            variant='outline-primary'
            onClick={() => handlerAddDutyInspectors('')}
            disabled={isDisableForm}>
            <FaPlus className='me-1' />
            เพิ่มข้อมูล
          </Button>
        </div>
        <Collapse in={openCollapse3}>
          <Row className='gap-5'>
            <Table hidePagination>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>ลำดับ</th>
                  <th style={{ width: '90%' }}>รายละเอียด</th>
                  <th style={{ width: '5%' }} />
                </tr>
              </thead>
              <tbody>
                {dutyInspectors.map((data, index) => (
                  <tr className='text-center' key={index}>
                    <td>{index + 1}</td>
                    <td className='text-start'>
                      <Form.Group className='mb-3'>
                        <InputGroup>
                          <Input
                            rule={{ required: true }}
                            type='text'
                            value={data.duty || ''}
                            disabled={isDisableForm}
                            onChange={(val) => handlerOnChangeDutyInspectors(val, index)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex justify-content-end'>
                        <Button
                          variant='danger'
                          className='d-flex align-items-center gap-2'
                          onClick={() => handlerRemoveDutyInspectors(index)}
                          disabled={isDisableForm}
                        >
                          <FaTrashAlt />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </Collapse >
      </Card >

      {(context.data.isMA) && (
        <>
          <Card className='mt-3'>
            <Title
              text='คณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)'
              className='fs-5 text-primary' />
            <>
              {!isDisableForm ?
                <div className='d-flex justify-content-end my-3'>
                  <Button
                    variant='outline-primary'
                    onClick={() => onShowSearchUserModalAsync(CommitteeType.MA)}
                    className='d-flex align-items-center gap-2'>
                    <FaPlus />เพิ่มรายชื่อ
                  </Button>
                </div> : null}
            </>
            <Table hidePagination>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>ลำดับ</th>
                  <th style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
                  <th style={{ width: '30%' }}>ตำแหน่ง</th>
                  <th style={{ width: '30%' }}>ตำแหน่งในบุคคล/คณะกรรมการ</th>
                  <th style={{ width: '5%' }} />
                </tr>
              </thead>
              <tbody>
                {maintenanceList?.map((data, i) => (
                  <tr key={data.id}>
                    <td className='text-center'>{i + 1}</td>
                    <td className='text-start'>{data.fullName}</td>
                    <td className='text-start'>{data.positionName}</td>
                    <td>
                      <Selector
                        items={positionOnBoardMADDL}
                        value={data.positionOnBoardId}
                        onChange={val => onPositionOnBoardChange(data.userId, val, CommitteeType.MA)}
                        rule={{ required: true }}
                        disabled={isDisableForm} />
                    </td>
                    <td>
                      {!isDisableForm ?
                        <div className='d-flex justify-content-center'>
                          <Button
                            variant='danger'
                            className='d-flex justify-content-center align-items-center'
                            onClick={() => removeUser(data.userId, CommitteeType.MA)}
                            disabled={isDisableForm}>
                            <FaTrashAlt />
                          </Button>
                        </div> : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>

          <Card className='mt-3'>
            <div className='d-flex mb-4 align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <Title text='อำนาจหน้ากรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)' className='fs-5 text-primary' />

                {
                  openCollapse4 ? (
                    <ArrowCollapse onClick={() => setOpenCollapse4(!openCollapse4)} openCollapse={openCollapse4} />
                  ) : (
                    <ArrowCollapse onClick={() => setOpenCollapse4(true)} openCollapse={openCollapse4} />
                  )}
              </div>
              <Button
                variant='outline-primary'
                onClick={() => handlerAddDutys('', CommitteeType.MA)}
                disabled={isDisableForm}
              >
                <FaPlus className='me-1' />
                เพิ่มข้อมูล
              </Button>
            </div>
            <Collapse in={openCollapse4}>
              <Row className='gap-5'>
                <Col sm={12} className='ps-5'>
                  <Table hidePagination>
                    <thead>
                      <tr>
                        <th style={{ width: 10 }}>ลำดับ</th>
                        <th style={{ minWidth: 50 }}>รายละเอียด</th>
                        <th style={{ width: 10 }} />
                      </tr>
                    </thead>
                    <tbody>
                      {dutyMAs.map((data, index) => (
                        <tr className='text-center' key={index}>
                          <td>{index + 1}</td>
                          <td className='text-start'>
                            <Form.Group className='mb-3'>
                              <InputGroup>
                                <Input
                                  rule={{ required: true }}
                                  type='text'
                                  value={data.duty || ''}
                                  onChange={(val) => handlerOnChangeDutys(val, index, CommitteeType.MA)}
                                  disabled={isDisableForm}
                                />
                              </InputGroup>
                            </Form.Group>
                          </td>
                          <td className='text-end'>
                            <div className='d-flex justify-content-end'>
                              <Button
                                variant='danger'
                                className='d-flex align-items-center gap-2'
                                onClick={() => handlerRemoveDutys(index, CommitteeType.MA)}
                                disabled={isDisableForm}
                              >
                                <FaTrashAlt />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Collapse>
          </Card>
        </>
      )
      }

      {
        (context.data.isSupervisor) && (
          <>
            <Card className='mt-3'>
              <Title
                text='ผู้ควบคุมงาน (เฉพาะงานก่อสร้าง)'
                className='fs-5 text-primary' />
              <>
                {!isDisableForm ?
                  <div className='d-flex justify-content-end my-3'>
                    <Button
                      variant='outline-primary'
                      onClick={() => onShowSearchUserModalAsync(CommitteeType.SUPERVISOR)}
                      className='d-flex align-items-center gap-2'>
                      <FaPlus />เพิ่มรายชื่อ
                    </Button>
                  </div> : null}
              </>
              <Table hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: '5%' }}>ลำดับ</th>
                    <th style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
                    <th style={{ width: '30%' }}>ตำแหน่ง</th>
                    <th style={{ width: '30%' }}>ตำแหน่งในบุคคล/คณะกรรมการ</th>
                    <th style={{ width: '5%' }} />
                  </tr>
                </thead>
                <tbody>
                  {supervisorList?.map((data, i) => (
                    <tr key={data.id}>
                      <td className='text-center'>{i + 1}</td>
                      <td className='text-start'>{data.fullName}</td>
                      <td className='text-start'>{data.positionName}</td>
                      <td>
                        <Selector
                          items={positionOnBoardSupervisorDDL}
                          value={data.positionOnBoardId}
                          onChange={val => onPositionOnBoardChange(data.userId, val, CommitteeType.SUPERVISOR)}
                          rule={{ required: true }}
                          disabled={isDisableForm} />
                      </td>
                      <td>
                        {!isDisableForm ?
                          <div className='d-flex justify-content-center'>
                            <Button
                              variant='danger'
                              className='d-flex justify-content-center align-items-center'
                              onClick={() => removeUser(data.userId, CommitteeType.SUPERVISOR)}
                              disabled={isDisableForm}>
                              <FaTrashAlt />
                            </Button>
                          </div> : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
            <Card className='mt-3'>
              <div className='d-flex mb-4 align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Title text='อำนาจหน้ากรรมการผู้ควบคุมงาน (เฉพาะงานก่อสร้าง)' className='fs-5 text-primary' />
                  {
                    openCollapse5 ? (
                      <ArrowCollapse onClick={() => setOpenCollapse5(!openCollapse5)} openCollapse={openCollapse5} />
                    ) : (
                      <ArrowCollapse onClick={() => setOpenCollapse5(true)} openCollapse={openCollapse5} />
                    )}
                </div>
                <Button
                  variant='outline-primary'
                  onClick={() => handlerAddDutys('', CommitteeType.SUPERVISOR)}
                  disabled={isDisableForm}
                >
                  <FaPlus className='me-1' />
                  เพิ่มข้อมูล
                </Button>
              </div>
              <Collapse in={openCollapse5}>
                <Row className='gap-5'>
                  <Col sm={12} className='ps-5'>
                    <Table hidePagination>
                      <thead>
                        <tr>
                          <th style={{ width: 10 }}>ลำดับ</th>
                          <th style={{ minWidth: 50 }}>รายละเอียด</th>
                          <th style={{ width: 10 }} />
                        </tr>
                      </thead>
                      <tbody>
                        {dutySupervisors.map((data, index) => (
                          <tr className='text-center' key={index}>
                            <td>{index + 1}</td>
                            <td className='text-start'>
                              <Form.Group className='mb-3'>
                                <InputGroup>
                                  <Input
                                    rule={{ required: true }}
                                    type='text'
                                    value={data.duty || ''}
                                    onChange={(val) => handlerOnChangeDutys(val, index, CommitteeType.SUPERVISOR)}
                                    disabled={isDisableForm}
                                  />
                                </InputGroup>
                              </Form.Group>
                            </td>
                            <td className='text-end'>
                              <div className='d-flex justify-content-end'>
                                <Button
                                  variant='danger'
                                  className='d-flex align-items-center gap-2'
                                  onClick={() => handlerRemoveDutys(index, CommitteeType.SUPERVISOR)}
                                  disabled={isDisableForm}
                                >
                                  <FaTrashAlt />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Collapse>
            </Card>
          </>
        )
      }

      {/* UploadFile */}
      <Card className='mt-3'>
        <Title
          text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              disabled={isDisableForm}
              onChange={uploadFileOnChangeAsync}
              value={fileList}
              onRemove={removeFileAsync}
              onDownload={downloadFileAsync} />
          </Col>
        </Row>
      </Card>

      {/* Button */}
      <div className='d-flex justify-content-between mt-3'>
        <Button
          className='d-flex justify-content-center align-items-center gap-2'
          onClick={onClickBack}
          variant='outline-primary'>
          <MdArrowBack />กลับหน้าแรก
        </Button>
        {isRecallShow ?
          <Button
            onClick={() => setShowReCall(true)}
            className='d-flex justify-content-center align-items-center gap-2'
            variant='danger'>
            <FaUndo />เรียกคืนแก้ไข
          </Button> : null}
        {isSaveShow ?
          <Button
            onClick={() => onSubmitAsync(Status.DRAFT)}
            className='d-flex justify-content-center align-items-center gap-2'
            variant='success'>
            <FaSave />บันทึก
          </Button> : null}
        {context.data.id ?
          <Button
            onClick={onClickNext}
            className='d-flex justify-content-center align-items-center gap-2'
            variant='outline-primary'>
            ถัดไป<MdArrowForward />
          </Button>
          : <div style={{ width: 100 }} />}
      </div>

      {/* Modal */}
      <HistoryModal
        title='ประวัติการใช้งานจัดทำรายงานขอซื้อขอจ้าง (จพ.005)'
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        data={context.data.activities} />
      {/* <SearchNameModal
        show={showSearchUserModal.show}
        section={showSearchUserModal.type}
        onHide={onModalHide}
        data={userDataModal.data || []}
        total={userDataModal.totalRecords || 0}
        onSearch={onModalSearch}
        onPageChange={onModalPageChange}
        onSelectItem={(id, name, type) => onModalSelect(id, type)} /> */}
      <SearchBuNameModal
        total={userTotal}
        show={showSearchUserModal.show}
        onHide={onModalHide}
        onSelectData={(data) => onModalSelect(data)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <Modal
        show={showReCall}
        size='lg'
        onHide={() => setShowReCall(!showReCall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowReCall(!showReCall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(Status.DRAFT, true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}
