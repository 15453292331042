import {
  AcceptorsRequestModel,
  CountStatusModel,
  CreateInvitedContractRequestModel,
  InvitedContractRequestListModel,
  UpdateContractAgreementContractNumberModel,
  UpdateContractAgreementPoModel,
} from 'models/CM/CM01Models';
import http from 'utils/axios';

const qs = require('qs');

const api = '/invitedcontract';

const getListInvitedContractAsync = (searchData: InvitedContractRequestListModel) => {
  const params = {
    page: searchData.page,
    pageSize: searchData.pageSize,
    contractNumber: searchData.contractNumber,
    poNumber: searchData.poNumber,
    planNumber: searchData.planNumber,
    planName: searchData.planName,
    departmentId: searchData.departmentName,
    traderName: searchData.traderName,
    detail: searchData.detail,
    statuses: searchData.statuses,
    isResponsible: searchData.isResponsible,
  };

  return http.get(`${api}`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountStatusAsync = (searchData: InvitedContractRequestListModel) => {
  const params = {
    page: searchData.page,
    pageSize: searchData.pageSize,
    contractNumber: searchData.contractNumber,
    planNumber: searchData.planNumber,
    planName: searchData.planName,
    departmentId: searchData.departmentName,
    traderName: searchData.traderName,
    detail: searchData.detail,
    statuses: searchData.statuses,
    isResponsible: searchData.isResponsible,
  };
  return http.get<CountStatusModel[]>(`${api}/status-count`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getInvitedContractByIdAsync = async (id: string, taxId?: string) => {
  return http.get(`${api}/${id}/${taxId}`);
};

// const createInvitedContractRequestAsync = (id: string, body: CreateInvitedContractRequestModel) => {
//   return id && body.contractAgreementId ? http.put(`${api}/${body.contractAgreementId}`, body) : http.post(`${api}`, body);
// };

const createInvitedContractRequestAsync = (body: CreateInvitedContractRequestModel) => {
  return http.post(`${api}`, body);
};

const updateInvitedContractRequestAsync = (body: CreateInvitedContractRequestModel) => {
  return http.put(`${api}/contractAgreement/${body.contractAgreementId}`, body);
};

// const updateInvitedContractRequestAsync = (body: CreateInvitedContractRequestModel) => {
//   return http.put(`ContractAgreement/${body.contractAgreementId}/someOne`, body);
// };

const createContractAgreementRequestAsync = (body: CreateInvitedContractRequestModel) => {
  return http.post(`${api}/contractAgreement`, body);
};

const updatePoNumberAsync = (id: string, body: UpdateContractAgreementPoModel) => {
  return http.put(`${api}/${id}/po-number`, body);
};

const getPoHistoryByIdAsync = async (id: string) => {
  return http.get(`${api}/${id}/po-number-history`);
};

const updateContractNumberAsync = (id: string, body: UpdateContractAgreementContractNumberModel) => {
  return http.put(`${api}/${id}/contract-number`, body);
};

const getContractHistoryByIdAsync = async (id: string) => {
  return http.get(`${api}/${id}/contract-number-history`);
};

const getAcceptorsByIdAsync = async (id: string) => {
  return http.get(`${api}/${id}/acceptors`);
};

const acceptorRequestAsync = (id: string, body: AcceptorsRequestModel) => {
  return http.post(`${api}/${id}/acceptors`, body);
};

const getListHistoryAsync = (page: number, size: number, id: string | undefined) => {
  return http.get(`${api}/${id}/history`);
};

const uploadAttachmentAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/${id}/upload-file`, formData, config);

};

const getAttachmentAsync = (id?: string, documentId?: string) => http.get(`${api}/${id}/download-file/${documentId}`, {
  responseType: 'blob',
});

const removeFileAsync = async (id: string, attachmentId: string) =>
  await http.delete(`${api}/${id}/delete-file/${attachmentId}`);

const waitingForApprovedAsync = (id: string) => http.put(`${api}/${id}/waiting-for-approved`);

const acceptorRejectAsync = (id: string, remark: string) => {
  const body = {
    remark,
  };

  return http.put(`${api}/${id}/rejected`, body);
};

const acceptorApprovedAsync = async (id: string, remark: string) => {

  const body = {
    remark,
  };

  return await http.put(`${api}/${id}/approved`, body);
};

const getDocumentByIdAsync = async (id: string, readonlyDocument: boolean) =>
  await http.get(`${api}/${id}/document/${readonlyDocument}`);

const updateDocumentAsync = async (id: string, documentId: string) =>
  await http.put(`${api}/${id}/document`, { documentId });

const uploadDocumentByDocumentIdAsync = async (id: string, documentId: string) =>
  await http.put(`${api}/${id}/convertToPdf/${documentId}/uploadfile`);

const sendEmailToEntrepreneurAsync = async (id: string, email: string) => {

  const body = {
    email,
  };

  return await http.put(`${api}/${id}/sendEmailToEntrepreneur`, body);
};

const reCallAsync = (id: string) => http.put(`${api}/${id}/recall`);

const CM01Service = {
  getContractHistoryByIdAsync,
  updateContractNumberAsync,
  getListInvitedContractAsync,
  getInvitedContractByIdAsync,
  getListHistoryAsync,
  createInvitedContractRequestAsync,
  uploadAttachmentAsync,
  removeFileAsync,
  acceptorApprovedAsync,
  acceptorRejectAsync,
  updatePoNumberAsync,
  acceptorRequestAsync,
  getPoHistoryByIdAsync,
  getAttachmentAsync,
  getAcceptorsByIdAsync,
  waitingForApprovedAsync,
  getCountStatusAsync,
  getDocumentByIdAsync,
  updateDocumentAsync,
  uploadDocumentByDocumentIdAsync,
  createContractAgreementRequestAsync,
  updateInvitedContractRequestAsync,
  sendEmailToEntrepreneurAsync,
  reCallAsync,
};

export default CM01Service;
