import {
  Breadcrumb,
  Card,
  InputTextArea,
  ProcessStatus,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import { AppointCmtPrApprovalDetailResponseModel } from 'models/PR/PR0302Models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { FaCheck, FaUndo } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import PR0302Services from 'services/PR/PR0302Services';
import toast from 'utils/toast';

const BREADCRUMB_INFO = [
  {
    routerName: '/pr/pr0302', label: 'อนุมัติจัดทำคำสั่งแต่งตั้งคณะกรรมาการจัดซื้อจัดจ้างและคณะตรวจรับพัสดุ',
  },
  {
    routerName: '/pr/pr0302/detail', label: 'รายละเอียดอนุมัติจัดทำคำสั่งแต่งตั้งคณะกรรมาการจัดซื้อจัดจ้างและคณะตรวจรับพัสดุ',
  },
];

function DetailPR0302() {
  const { id } = useParams();
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [appointmentCmtPrApproval, setAppointmentCmtPrApproval] = useState<AppointCmtPrApprovalDetailResponseModel>({} as AppointCmtPrApprovalDetailResponseModel);
  const [rejectReason, setRejectReason] = useState('');
  const [approveReason, setApproveReason] = useState('');

  useEffect(() => {
    if (id !== null && id !== undefined) {
      onGetAppointCmtPrApprovalById(id);
    }
  }, []);

  const onGetAppointCmtPrApprovalById = useCallback(async (id: string) => {
    const { data, status } = await PR0302Services.getAppointCmtProcurementApprovalByIdAsync(id);
    if (status !== 200) {
      toast.error('ไม่พบข้อมูล');
      return;
    }

    setAppointmentCmtPrApproval(data);
  }, []);

  const onShowReject = (() => {
    setRejectReason('');
    setShowRejectModal(true);
  });

  const onReject = (async () => {
    if (!rejectReason || rejectReason === '') {
      toast.error('กรุณากรอกหมายเหตุ');
      return;
    }

    const { status } = await PR0302Services.onRejectAppointCmtProcurementApprovalByIdAsync(appointmentCmtPrApproval.id, rejectReason);
    if (status !== 200) {
      toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
      return;
    }
    toast.success('ส่งกลับแก้ไขสำเร็จ');
    setShowRejectModal(false);
    onGetAppointCmtPrApprovalById(appointmentCmtPrApproval.id);
  });

  const onShowApprove = (() => {
    setApproveReason('');
    setShowApproveModal(true);
  });

  const onApprove = (async () => {
    if (!approveReason || approveReason === '') {
      toast.error('กรุณากรอกหมายเหตุ');
      return;
    }

    const { status } = await PR0302Services.onApproveAppointCmtProcurementApprovalByIdAsync(appointmentCmtPrApproval.id, approveReason);
    if (status !== 200) {
      toast.error('อนุมัติไม่สำเร็จ');
      return;
    }
    toast.success('อนุมัติสำเร็จ');
    setShowApproveModal(false);
    onGetAppointCmtPrApprovalById(appointmentCmtPrApproval.id);
  });

  return (
    <div className='document'>
      <h4 className='mt-2 mb-0 text-primary text-start'>อนุมัติจัดทำคำสั่งแต่งตั้งคณะกรรมาการจัดซื้อจัดจ้างและคณะตรวจรับพัสดุ</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Card className='mt-3'>
        <Title text='อนุมัติจัดทำคำสั่งแต่งตั้งคณะกรรมาการจัดซื้อจัดจ้างและคณะตรวจรับพัสดุ'
          className='fs-5 text-primary' />
        <Card className='mt-3'>
          <Title text='รายละเอียดร่างขอบเขตงาน (TOR)'
            className='fs-5 text-primary' />
          <p className='mt-2'><span className='fw-bold'>เลขที่</span> : -</p>
          <p><span className='fw-bold'>เรื่อง</span> : {appointmentCmtPrApproval.subject}</p>
        </Card>

        <Card className='mt-3'>
          <Title text='อำนาจอนุมัติ'
            className='fs-5 text-primary' />
          {
            appointmentCmtPrApproval.appointProcurementApprovers && (
              <>
                {
                  appointmentCmtPrApproval.appointProcurementApprovers.map((data, index) => (
                    <p className='mt-2'
                      key={index}>
                      {`${index + 1}. ${data.approverFullName}`}
                      <span className='fw-bold ms-2'>{data.position}</span>
                    </p>
                  ))
                }
              </>
            )
          }
        </Card>

        <Card className='mt-3'>
          <>รูปตัวอย่างเอกสาร</>
        </Card>

        <div className='d-flex justify-content-center gap-3 pt-3'>
          <ButtonCustom
            variant='danger'
            onClick={() => onShowReject()}
            disabled={appointmentCmtPrApproval.status !== ProcessStatus.PENDING}
            icon="undo"
            text="ส่งกลับแก้ไข"
          />
          <ButtonCustom
            onClick={() => onShowApprove()}
            disabled={appointmentCmtPrApproval.status !== ProcessStatus.PENDING}
            icon="checked"
            text="อนุมัติ"
          />
        </div>
      </Card>

      <Modal
        show={showApproveModal}
        size='lg'
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ให้ความเห็นชอบ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='หมายเหตุ'
                rule={{ required: true }}
                value={`${approveReason}`}
                onChange={(val) => setApproveReason(val)}
              />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-center'>
              <Button variant='outline-primary'
                onClick={() => setShowApproveModal(!showApproveModal)}>
                ยกเลิก
              </Button>
              <Button variant='primary'
                onClick={() => onApprove()}>
                <FaCheck className='me-2' /> ให้ความเห็น
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showRejectModal}
        size='lg'
        onHide={() => setShowRejectModal(!showRejectModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='หมายเหตุ'
                rule={{ required: true }}
                value={`${rejectReason}`}
                onChange={(val) => setRejectReason(val)}
              />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-center'>
              <Button
                variant='outline-primary'
                onClick={() => setShowRejectModal(!showRejectModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                onClick={() => onReject()}
                variant='danger'
                className='me-2 px-3'
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default DetailPR0302;
