import Cookie from 'cookie-universal';
import { SearchCreateContractMangementModel } from 'models/CM/CreateContracModels';
import http from 'utils/axios';

const qs = require('qs');

const cookies = Cookie();
const getFullName = cookies.get('fullName') as string;
const api = '/contractsigning';

const getListCreateContractMangementAsync = (searchData: SearchCreateContractMangementModel) => {
  const params = {
    page: searchData.page,
    size: searchData.size,
    contractNumber: searchData.contractNumber,
    planNumber: searchData.planNumber,
    planName: searchData.planName,
    departmentId: searchData.departmentId,
    traderName: searchData.traderName,
    contractStartDate: searchData.contractStartDate,
    cantractEndDate: searchData.contractEndDate,
    isResponsible: searchData.isResponsible,
    supplyMethodIds: searchData.supplyMethodIds,
    status: searchData.status,
  };
  return http.get(`${api}/pagination`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getContractByIdAsync = (id: string) => http.get(`${api}/${id}`);

const getDocumentAsync = (id: string) => {
  return http.get(`${api}/${id}/document`);
};

export default {
  getListCreateContractMangementAsync,
  getContractByIdAsync,
  getDocumentAsync,
};
