import {
  useCallback,
  useContext,
} from 'react';
import { useLoaderData } from 'react-router';
import PreProcurementTorServices from 'services/PP/PreProcurementTorServices';
import { TorDetailModel } from 'models/PP/TorModels';
import { TorTemplateCode } from 'utils/constants/torTemplateCodeEnum';
import { ItemModel } from 'models';
import { Context } from '../step';
import { HttpStatusCode } from 'utils';
import toast from 'utils/toast';
import { StepDefault } from './stepDefault';
import { StepTemplateComputer } from './stepTemplateComputer';

type Loader = {
  periodUnitDDL: ItemModel[],
  defectsWarrantyCountUnitDDL: ItemModel[],
  periodTimeUnitDDL: ItemModel[],
  pmFineTypeDDL: ItemModel[],
  cmFineTypeDDL: ItemModel[],
  trainingUnitTypeDDL: ItemModel[],
  dayOfWeekDDL: ItemModel[],
};

interface IProps {
  templateCode: string;
  onClickNext: () => void;
  onClickBack: () => void;
}

function MainStep2Tor(props: IProps) {
  const { periodUnitDDL, defectsWarrantyCountUnitDDL, periodTimeUnitDDL, pmFineTypeDDL, cmFineTypeDDL, trainingUnitTypeDDL, dayOfWeekDDL } = useLoaderData() as Loader;
  const { data, setData } = useContext(Context);

  const getTorByIdAsync = useCallback(async (id: string) => {
    const { status, data } = await PreProcurementTorServices.getTorByIdAsync(id);

    if (status === HttpStatusCode.OK) {
      setData(data);
    }
  }, [setData]);

  const onSubmit = useCallback(async (updateData: TorDetailModel) => {
    const { status } = await PreProcurementTorServices.updateTorStep2Async(updateData);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกร่างขอบเขตของงาน (TOR) ได้');

      return;
    }

    toast.success('บันทึกร่างขอบเขตของงาน (TOR) สำเร็จ');
    await getTorByIdAsync(updateData.id);
    props.onClickNext();
  }, [getTorByIdAsync]);

  const switchTemplate = (templateCode: string) => {
    switch (templateCode) {
      case TorTemplateCode.OV5STD: //Tor > 500k
        return <StepTemplateComputer
          contextTorData={data}
          onSubmit={onSubmit}
          periodUnitDDL={periodUnitDDL}
          periodTimeUnitDDL={periodTimeUnitDDL}
          defectsWarrantyCountUnitDDL={defectsWarrantyCountUnitDDL}
          pmFineTypeDDL={pmFineTypeDDL}
          cmFineTypeDDL={cmFineTypeDDL}
          trainingUnitTypeDDL={trainingUnitTypeDDL}
          dayOfWeekDDL={dayOfWeekDDL}
          onClickNext={() => props.onClickNext()}
          onClickBack={() => props.onClickBack()} />;
      default:
        return <StepDefault
          contextTorData={data}
          onSubmit={onSubmit}
          periodUnitDDL={periodUnitDDL}
          defectsWarrantyCountUnitDDL={defectsWarrantyCountUnitDDL}
          onClickNext={() => props.onClickNext()}
          onClickBack={() => props.onClickBack()} />; //Tor <= 500k
    }
  };

  return (
    <>
      {
        switchTemplate(props.templateCode)
      }
    </>
  );
}

export default MainStep2Tor;
