import {
  DepartmentResponse,
  ItemModel,
  UserListSelectionParams,
} from 'models';
import { AnnualPlanModel } from 'models/PL';
import { LoaderFunctionArgs } from 'react-router';
import {
  account,
  department,
  shareValue,
} from 'services';
import { PL0201Service } from 'services/PL';

const PL0201Loader = async () => {
  const [DepartmentItems, SupplyMethodTypeItems, SupplyMethodNameItems, { data }, userData, PositionList] = await Promise.all([
    GetDDL('Department'),
    GetDDL('SupplyMethodType'),
    GetDDL('SupplyMethodName'),
    PL0201Service.getPL0201sAsync(1, 10),
    shareValue.getUserListSelectionAsync(1, 10, {} as UserListSelectionParams),
    GetDDL('Position'),
  ]);

  return {
    departmentList: DepartmentItems,
    supplyMethodTypeList: SupplyMethodTypeItems,
    supplyMethodTypeNameList: SupplyMethodNameItems,
    dataTable: data,
    usersList: userData.data.data,
    positionList: PositionList,
  };
};

const PL0201DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (id !== undefined) {
    const [DepartmentItems, SupplyMethodTypeItems, SupplyMethodNameItems, PositionList, { data }, planData] = await Promise.all([
      GetDDL('Department'),
      GetDDL('SupplyMethodType'),
      GetDDL('SupplyMethodName'),
      GetDDL('Position'),
      account.getUsersAsync(10, 1),
      await PL0201Service.getPL0201ByIdAsync(id),
    ]);

    return {
      departmentList: DepartmentItems,
      supplyMethodTypeList: SupplyMethodTypeItems,
      supplyMethodTypeNameList: SupplyMethodNameItems,
      usersList: data.data,
      planData: planData.data,
      positionList: PositionList,
    };
  }

  const [DepartmentItems, SupplyMethodTypeItems, SupplyMethodNameItems, PositionList, { data }] = await Promise.all([
    GetDDL('Department'),
    GetDDL('SupplyMethodType'),
    GetDDL('SupplyMethodName'),
    GetDDL('Position'),
    account.getUsersAsync(10, 1),
  ]);

  return {
    departmentList: DepartmentItems,
    supplyMethodTypeList: SupplyMethodTypeItems,
    supplyMethodTypeNameList: SupplyMethodNameItems,
    usersList: data.data,
    positionList: PositionList,
    planData: {} as AnnualPlanModel,
  };
};

const GetDDL = async (Type: string) => {
  const itemForMap: ItemModel[] = [];
  if (Type === 'Department') {
    const departmentsRes = await department.getDepartmentsAsync();
    const departmentData: DepartmentResponse[] = departmentsRes.data;
    departmentData.forEach((data) => itemForMap.push({ label: data.name, value: data.id ? data.id : '' }));
  } else if (Type === 'SupplyMethodType') {
    const supplyMethodTypeListRes = await shareValue.getSupplyMethodTypeListAsync();
    const supplyMethodTypeData: ItemModel[] = supplyMethodTypeListRes.data;
    supplyMethodTypeData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
  } else if (Type === 'SupplyMethodName') {
    const supplyMethodListRes = await shareValue.getSupplyMethodListAsync();
    const supplyMethodListData: ItemModel[] = supplyMethodListRes.data;
    supplyMethodListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
  } else if (Type === 'Position') {
    const positionRes = await shareValue.getPostionListSelectionAsync();
    const positionList: ItemModel[] = positionRes.data;
    positionList.forEach((data) => itemForMap.push({ label: data.label, value: data.value }));
  }
  return itemForMap;
};

export {
  PL0201Loader,
  PL0201DetailLoader,
};
