import { LONG_MONTHS } from 'data';
import { Rule } from 'models';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap';
import { FaCalendarAlt } from 'react-icons/fa';
import { usePopperTooltip } from 'react-popper-tooltip';
import {
  GetFormatDateBC,
  GetMonthYearBC,
  GetYearBC,
  thaiFormatDate,
} from 'utils';
import { Selector } from './Selector';

interface Props {
  label?: string;
  placeholder?: string;
  rule?: Rule;
  value?: Date | undefined;
  onChange?: (value: Date) => void;
  onChangeHasNullable?: (value?: Date) => void;
  name?: string;
  className?: string;
  disabled?: boolean;
  monthYearOnly?: boolean;
  fullDate?: boolean;
  addDefaultYear?: number;
}

export function DatePicker(props: Props) {
  const [currentDate] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [year, setYear] = useState<number>(currentDate.getUTCFullYear());
  const [month, setMonth] = useState<number>(currentDate.getMonth());
  const [day, setDay] = useState<number>(currentDate.getDate());
  const [dateList, setDateList] = useState<(Date | null)[]>([]);
  const [dateShow, setDateShow] = useState<string>();
  const [value, setValue] = useState<Date | undefined>(props.value);
  const [visible, setVisible] = useState(false);

  document.addEventListener('onFormSubmit', (data) => {
    const event = data as CustomEvent;

    if (event.detail.hasNameOnly) {
      if (props.name) {
        validate(value);
      }
    } else {
      validate(value);
    }
  });

  document.addEventListener('onClearError', () => {
    setErrorMessage('');
  });

  useEffect(() => {
    setValue(props.value);

    if (!props.value) {
      setDateShow('');
      setValue(undefined);
    }
  }, [props.value]);

  const handlerOnVisibleChange = (visibleValue: boolean) => {
    setVisible(visibleValue);

    if (!visibleValue) {
      setLabelValue();
    }
  };

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    trigger: 'click',
    interactive: true,
    onVisibleChange: handlerOnVisibleChange,
    closeOnOutsideClick: true,
    visible,
  });

  const validate = useCallback((validateValue: Date | undefined) => {
    const checkRequired = (requiredValue: Date | undefined) => {
      if (props.rule?.required && !requiredValue) {
        setErrorMessage('กรุณากรอกข้อมูล');

        return false;
      }

      return true;
    };

    const checkMaxStartDate = (value: Date | undefined) => {
      if (props.rule?.maxStartDate &&
        value &&
        value > new Date(props.rule?.maxStartDate)) {
        setDateShow('');
        setValue(undefined);
        setErrorMessage('กรุณาระบุข้อมูลไม่เกินวันที่สิ้นสุด');

        return false;
      }

      return true;
    }

    const checkMinStartDate = (value: Date | undefined) => {
      if (props.rule?.minEndDate &&
        value &&
        value < new Date(props.rule?.minEndDate)) {
        setDateShow('');
        setValue(undefined);
        setErrorMessage('กรุณาระบุข้อมูลไม่ต่ำกว่าวันที่เริ่มต้น');

        return false;
      }

      return true;
    }

    if (!checkRequired(validateValue)) {
      return;
    }

    if (!checkMaxStartDate(validateValue)) {
      return;
    }

    if (!checkMinStartDate(validateValue)) {
      return;
    }

    setErrorMessage('');
  }, [props.rule]);

  useEffect(() => {
    if (year && month >= 0) {
      findDaysInMonth(year, month);
    }
  }, [year, month]);

  const setLabelValue = useCallback(() => {
    if (value) {
      const newDate = new Date(value);

      if (props.monthYearOnly) {
        setDay(1);
        setMonth(newDate.getMonth());
        setYear(newDate.getUTCFullYear());

        setDateShow(GetMonthYearBC(newDate));
      } else if (props.fullDate) {
        setDay(newDate.getDate());
        setMonth(newDate.getMonth());
        setYear(newDate.getUTCFullYear());

        setDateShow(thaiFormatDate(newDate));
      } else {
        setDay(newDate.getDate());
        setMonth(newDate.getMonth());
        setYear(newDate.getUTCFullYear());

        setDateShow(GetFormatDateBC(newDate));
      }
    } else {
      if (props.monthYearOnly) {
        setDay(1);
      } else {
        setDay(currentDate.getDate());
      }

      setMonth(currentDate.getMonth());
      setYear(props.addDefaultYear ? currentDate.getUTCFullYear() + props.addDefaultYear : currentDate.getUTCFullYear());
    }
  }, [currentDate, value, props.addDefaultYear]);

  useEffect(() => {
    setLabelValue();
  }, [value, setLabelValue]);

  const getRequired = () => {
    if (props.rule?.required) {
      return <span className='text-danger'>*</span>;
    }

    return null;
  };

  const getErrorMessage = useMemo(() => {
    if (errorMessage) {
      return <Form.Text className='text-danger'>{errorMessage}</Form.Text>;
    }

    return null;
  }, [errorMessage]);

  const findDaysInMonth = (yearValue: number, monthValue: number) => {
    const dateSelected = new Date(yearValue, monthValue, 1);
    const dayOfFirstDate = dateSelected.getDay();
    const newDateList: (Date | null)[] = [];

    Array.from(Array(dayOfFirstDate))
      .forEach(() => newDateList.push(null));

    while (dateSelected.getMonth() === monthValue) {
      const date = new Date(dateSelected);

      newDateList.push(date);

      dateSelected.setDate(dateSelected.getDate() + 1);
    }

    setDateList(newDateList);
  };

  const calendar = () => {
    const getYears = () => {
      let yearBack = currentDate.getUTCFullYear() - 5;

      return Array.from(Array(11))
        .map(() => {
          const item = {
            label: GetYearBC(yearBack.toString()),
            value: yearBack.toString(),
          };

          yearBack += 1;

          return item;
        });
    };

    const getMonths = () => LONG_MONTHS.map((m, i) => ({
      label: m.toString(),
      value: i.toString(),
    }));

    const createDate = (date: Date | null, i: number) => {
      if (!date) {
        return <p key={i} />;
      }

      const dayValue = date.getDate();
      const weekday = date.getDay();
      const isWeekend = weekday === 0 || weekday === 6;

      const onChange = () => {
        setDay(dayValue);
        onSubmit(year, month, dayValue);
      };

      const classNames = `date ${isWeekend ? 'weekend' : ''} ${dayValue === day ? 'active' : ''}`;

      return (
        <p
          key={i}
          className={classNames}
          onClick={onChange}>
          {dayValue}
        </p>
      );
    };

    const createMonth = (i: number) => {
      const monthData = getMonths()[i];

      const onChange = () => {
        setMonth(i);
        onSubmit(year, i, day);
      };

      return (
        <p
          key={i}
          className={`month ${month === i ? 'active' : ''}`}
          onClick={onChange}
        >
          {monthData.label}
        </p>
      );
    };

    const dates = () => (
      <>
        <div className='year-month-select'>
          <Selector
            value={year.toString()}
            items={getYears()}
            onChange={(val) => setYear(+val)}
            size='sm'
            isHideClearButton
          />
          <Selector
            value={month.toString()}
            items={getMonths()}
            onChange={(val) => setMonth(+val)}
            size='sm'
            isHideClearButton
          />
        </div>
        <div className='day-name'>
          <p>อา</p>
          <p>จ</p>
          <p>อ</p>
          <p>พ</p>
          <p>พฤ</p>
          <p>ศ</p>
          <p>ส</p>
        </div>
        <div className='date'>
          <div className='row-date'>
            {Array.from([0, 1, 2, 3, 4, 5, 6])
              .map((i) => createDate(dateList[i], i))}
          </div>
          <div className='row-date'>
            {Array.from([7, 8, 9, 10, 11, 12, 13])
              .map((i) => createDate(dateList[i], i))}
          </div>
          <div className='row-date'>
            {Array.from([14, 15, 16, 17, 18, 19, 20])
              .map((i) => createDate(dateList[i], i))}
          </div>
          <div className='row-date'>
            {Array.from([21, 22, 23, 24, 25, 26, 27])
              .map((i) => createDate(dateList[i], i))}
          </div>
          {dateList.length >= 28
            ? (
              <div className='row-date'>
                {Array.from([28, 29, 30, 31, 32, 33, 34])
                  .map((i) => createDate(dateList[i], i))}
              </div>
            ) : null}
          {dateList.length >= 35
            ? (
              <div className='row-date'>
                {Array.from([35, 36])
                  .map((i) => createDate(dateList[i], i))}
              </div>
            ) : null}
        </div>
      </>
    );

    const months = () => (
      <>
        <div className='year-select'>
          <Selector
            value={year.toString()}
            items={getYears()}
            onChange={(val) => setYear(+val)}
            size='sm'
            isHideClearButton
          />
        </div>
        <div className='month'>
          <div className='row-mount'>
            {Array.from([0, 1, 2])
              .map((i) => createMonth(i))}
          </div>
          <div className='row-mount'>
            {Array.from([3, 4, 5])
              .map((i) => createMonth(i))}
          </div>
          <div className='row-mount'>
            {Array.from([6, 7, 8])
              .map((i) => createMonth(i))}
          </div>
          <div className='row-mount'>
            {Array.from([9, 10, 11])
              .map((i) => createMonth(i))}
          </div>
        </div>
      </>
    );

    return (
      <div className='calendar'>
        {!props.monthYearOnly
          ? dates()
          : months()}
      </div>
    );
  };

  const onSubmit = (yearValue: number, monthValue: number, dayValue: number) => {
    const newDateValue = new Date(Date.UTC(yearValue, monthValue, dayValue));
    setValue(newDateValue);
    validate(newDateValue);
    setVisible(false);

    if (props.onChange) {
      props.onChange(newDateValue);
    }

    if (props.onChangeHasNullable) {
      props.onChangeHasNullable(newDateValue);
    }
  };

  const handleClear = () => {
    setDateShow('');
    setValue(undefined);
    validate(undefined);

    if (props.onChangeHasNullable) {
      props.onChangeHasNullable(undefined);
    }
  };

  return (
    <div className={`date-picker ${props.disabled ? 'disabled' : ''}`}>
      <Form.Group className={`w-100 ${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
        {props.label ? <Form.Label>{props.label} {getRequired()}</Form.Label> : null}
        <InputGroup style={{ zIndex: '0' }}>
          <Form.Control
            readOnly
            className={`${getErrorMessage ? 'is-invalid' : ''} border-end-0`}
            value={dateShow ?? ''}
            type='text'
            placeholder={props.placeholder}
            disabled={props.disabled}
            onClick={() => {
              setVisible(!visible);
            }}
            ref={setTriggerRef}
          />

          <Button variant='outline-light border-start-0 border-end-0 close'
            aria-label='Close'
            disabled={props.disabled}
            onClick={() => {
              handleClear();
            }}>
            <span aria-hidden='true'>&times;</span>
          </Button>
          <InputGroup.Text className={props.disabled ? '' : 'cursor-pointer'}
            ref={setTriggerRef}>
            <FaCalendarAlt />
          </InputGroup.Text>
        </InputGroup>
        <div className='display-calendar'>
          {(visible) && !props?.disabled ? (
            <div
              ref={setTooltipRef}
              {
              ...getTooltipProps({
                className: 'tooltip-container',
                style: visible
                  ? {
                    visibility: 'visible',
                    zIndex: '4',
                  }
                  : {
                    visibility: 'hidden',
                    pointerEvents: 'none',
                  },
              })
              }
            >
              {calendar()}
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            </div>
          ) : null}
        </div>
        {getErrorMessage}
      </Form.Group>
    </div>
  );
}
