import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Input,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import { TrainingConditionModel } from '../../../../../../models/CM/CM02Models';

interface TrainingManagerProps {
  trainingData: TrainingConditionModel;
  periodTypeDDL: ItemModel[];
  periodConditionDDL: ItemModel[];
  onTrainingManagerChange: (value: TrainingConditionModel) => void;
  isDisabled?: boolean;
}

export function TrainingManager(props: TrainingManagerProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [trainingData, setTrainingData] = useState<TrainingConditionModel>(props.trainingData);

  useEffect(() => {
    props.onTrainingManagerChange(trainingData);
  }, [trainingData]);

  useEffect(() => {
    if (props.trainingData && firstLoad) {
      setTrainingData(props.trainingData);
      setFirstLoad(false);
    }
  }, [props.trainingData, firstLoad]);

  const onTextChange = useCallback((key: string, value: string) => {
    setTrainingData((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  return (
    <Card className='mt-3'>
      <Title text='การอบรมการใช้งาน' className='fs-5 text-primary' />
      <Row className='mt-3'>
        <Col sm={8} md={3}>
          <Input
            label='คู่ค้าจะต้องดำเนินการอบรมใหเ้แล้วเสร็จภายใน'
            type='number'
            value={trainingData?.trainingInDay}
            onChange={(value) => onTextChange('trainingInDay', value)}
            textEnd
            disabled={props.isDisabled} />
        </Col>
        <Col sm={4} md={2}>
          <Selector
            className='no-label'
            items={props.periodTypeDDL}
            value={trainingData?.trainingInDayUnit}
            onChange={(value) => onTextChange('trainingInDayUnit', value)} />
        </Col>
        <Col sm={4} md={2}>
          <Selector
            className='no-label'
            items={props.periodConditionDDL}
            value={trainingData?.trainingInDayType}
            onChange={(value) => onTextChange('trainingInDayType', value)} />
        </Col>
      </Row>
    </Card>
  );
}
