import {
  Card,
  Input,
  Selector,
  Status as PlanStatus,
  StatusType,
  Table,
  ButtonCustom,
} from 'components';
import { Modal } from 'components/Modal';
import {
  ChangePlanRequestCreate,
  ChangePlanRequestDetailResponse,
  DepartmentResponse,
  ItemModel,
  UserListSelectionParams,
  UserListSelectionResponse,
} from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaEraser,
  FaPaperPlane,
  FaPlus,
  FaSave,
  FaSearch,
  FaTimes,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useNavigate,
  useParams,
} from 'react-router';
import {
  changePlanRequest,
  shareValue,
} from 'services';
import department from 'services/department';
import {
  fullDate,
  HttpStatusCode,
  isoDate,
  Status,
  StatusName,
} from 'utils';
import toast from 'utils/toast';
import { CreateDataContext } from './index';

interface Props {
  changeRequestDatail: ChangePlanRequestDetailResponse;
  onClickBack: () => void;
}

interface ModalUserProps {
  show: boolean;
  userDataListSelect: UserListSelectionResponse[];
  onHide: () => void;
  sendIdUser: (value: UserListSelectionResponse) => void;
}

export default function Approve(props: Props) {
  const [show, setShow] = useState(false);
  const [modalUserShow, setModalUserShow] = useState(false);
  const [createData, setCreateData] = useState<ChangePlanRequestCreate>({} as ChangePlanRequestCreate);
  const [userListData, setUserListData] = useState<UserListSelectionResponse[]>([]);
  const [showButton, setShowButton] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const contextCreateData = useContext(CreateDataContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (contextCreateData) {
      setContextToCreateData(contextCreateData);
    }
  }, [contextCreateData]);

  const setUserListDetail = useCallback((data: UserListSelectionResponse[]) => {
    setUserListData(data);
  }, []);

  useEffect(() => {
    const { changePlanRequest } = props.changeRequestDatail;
    if (changePlanRequest) {
      if (changePlanRequest.annualPlan.status === StatusName.APPROVED
        || changePlanRequest.annualPlan.status === StatusName.PENDING) {
        setShowButton(false);
      }

      if (changePlanRequest.approvers) {
        const userList: UserListSelectionResponse[] = changePlanRequest.approvers.map((data) => ({ id: data.id, fullName: data.name, departmentName: data.department, positionName: data.position, workGroupName: data.workGroupName ?? '', approveStatus: data.approveStatus, updatedAt: data.updatedAt }));
        setUserListDetail(userList);

        const userId = changePlanRequest.approvers.map((data) => data.id);

        setCreateData({ ...createData, userIds: userId });
      }
    }
  }, [props.changeRequestDatail]);

  const setContextToCreateData = (data: ChangePlanRequestCreate) => {
    setCreateData({ ...createData, planId: id ?? '', planName: data.planName, planBudget: data.planBudget, expectingProcurementAt: isoDate(data.expectingProcurementAt) });
  };

  const sendApprove = () => {
    setShow(true);
  };

  const createAndUpdateDraftAsync = async () => {
    if (!props.changeRequestDatail.changePlanRequest.id) {
      const body: ChangePlanRequestCreate = { ...createData };

      if (body.userIds?.length === 0) {
        delete body.userIds;
      }

      const response = await changePlanRequest.createChangePlanRequestAsync(body);

      if (response.status === HttpStatusCode.CREATED) {
        toast.success('บันทึกร่างสำเร็จ');

        navigate(-1);
      }

      return;
    }

    const body: ChangePlanRequestCreate = { ...createData };
    body.status = Status.Draft;

    if (body.userIds?.length === 0) {
      delete body.userIds;
    }

    const response = await changePlanRequest.updateChangePlanRequestAsync(props.changeRequestDatail.changePlanRequest.id, body);

    if (response.status === HttpStatusCode.NO_CONTENT) {
      toast.success('บันทึกร่างสำเร็จ');

      navigate(-1);
    }
  };

  const createAndUpdateSendApprovedAsync = async () => {
    if (!createData.userIds) {
      return toast.warn('ต้องมีผู้อนุมัติอย่างน้อย 1 คน');
    }

    const body: ChangePlanRequestCreate = { ...createData };
    body.status = Status.Pending;

    if (body.userIds?.length === 0) {
      delete body.userIds;
    }

    if (!props.changeRequestDatail.changePlanRequest.id) {
      const response = await changePlanRequest.createChangePlanRequestAsync(body);

      if (response.status === HttpStatusCode.CREATED) {
        toast.success('ส่งอนุมัติสำเร็จ');

        navigate(-1);
      }

      return;
    }

    const response = await changePlanRequest.updateChangePlanRequestAsync(props.changeRequestDatail.changePlanRequest.id, body);

    if (response.status === HttpStatusCode.NO_CONTENT) {
      toast.success('ส่งอนุมัติสำเร็จ');

      navigate(-1);
    }
  };

  const addUserDataList = (userData: UserListSelectionResponse) => {
    if (userData) {
      userListData.push(userData);

      setUserToCreateModel(userListData);
    }

    setTotalRecords(userListData.length);
  };

  const removeUserDataInList = (index: number) => {
    const rowArray: UserListSelectionResponse[] = [...userListData];
    rowArray.splice(index, 1);

    setUserListData(rowArray);
    setUserToCreateModel(rowArray);
  };

  const setUserToCreateModel = (userList: UserListSelectionResponse[]) => {
    if (userList.length > 0) {
      const userId = userList.map((data) => data.id);

      setCreateData({ ...createData, userIds: userId });
    } else {
      setCreateData({ ...createData, userIds: [] });
    }
  };

  return (
    <div className='approve'>
      <Card>
        <div className='d-flex align-items-center gap-3'>
          <div className='parallelogram' />
          <h4 className='text-primary m-0'>อำนาจอนุมัติ</h4>
        </div>
        <div>
          <Table total={totalRecords}
            onChange={(size, page) => (setSize(size), setPage(page))}
            className='mt-4'>
            <thead>
              <tr>
                <th style={{ minWidth: 100 }}>ลำดับ</th>
                <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
                <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
                {!showButton && <th style={{ minWidth: 100 }}>สถานะ</th>}
                {!showButton && <th style={{ minWidth: 100 }}>วันที่อนุมัติ</th>}
                {showButton && <th style={{ minWidth: 100 }} />}
              </tr>
            </thead>
            <tbody>
              {userListData.map((data, index) => (
                <tr key={data.id}>
                  <td className='text-center'>{index + 1}</td>
                  <td>{data.fullName}</td>
                  <td>{data.departmentName}</td>
                  <td className='text-center'>{data.positionName}</td>
                  {!showButton && (
                    <td className='text-center'>
                      <PlanStatus type={StatusType.PROCESS}
                        value={data.approveStatus ?? ''} />
                    </td>
                  )}
                  {!showButton && <td>{data.updatedAt ? fullDate(data.updatedAt) : ''}</td>}
                  {showButton && (
                    <td className='d-flex justify-content-center'>
                      <Button
                        className='d-flex align-items-center gap-2'
                        variant='danger'
                        onClick={() => removeUserDataInList(index)}
                      >
                        <FaTrashAlt />
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          <div className='d-flex justify-content-start mt-3'>
            {showButton && (
              <Button variant='outline-primary'
                onClick={() => setModalUserShow(true)}>
                <FaPlus className='me-3' />เพิ่มข้อมูล
              </Button>
            )}
          </div>
        </div>
        <div className='d-flex justify-content-center gap-3 mt-3'>
          <ButtonCustom
            onClick={props.onClickBack}
            text="ย้อนกลับ"
          />
          {showButton && (
            <>
              <ButtonCustom
                onClick={() => createAndUpdateDraftAsync()}
                text="บันทึก"
              />
              <ButtonCustom
                onClick={() => sendApprove()}
                icon="checked"
                text="ส่งอนุมัติ"
              />
            </>
          )}
        </div>
      </Card>
      <Modal
        size='lg'
        show={show}
        onHide={() => setShow(!show)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งอนุมัติเปลี่ยนแปลงแผนการจัดซื้อจัดจ้าง</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>คุณต้องการขอเปลี่ยนแปลงแผนการจัดซื้อจัดจ้างเพื่อทำการอนุมัติใช่หรือไม่
              ?</ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShow(!show)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={createAndUpdateSendApprovedAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <ModalUserApproved
        show={modalUserShow}
        userDataListSelect={userListData}
        onHide={() => setModalUserShow(false)}
        sendIdUser={(value) => addUserDataList(value)}
      />
    </div>
  );
}

function ModalUserApproved(props: ModalUserProps) {
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [departmentItems, setDepartmentItems] = useState<ItemModel[]>([]);
  const [postionItems, setPositionItems] = useState<ItemModel[]>([]);
  const [userList, setUserList] = useState<UserListSelectionResponse[]>([]);
  const [criteriaData, setCriteriaData] = useState<UserListSelectionParams>({} as UserListSelectionParams);

  const getDepartmentItemsAsync = async () => {
    const response = await department.getDepartmentsAsync();
    const departmentData: DepartmentResponse[] = response.data;
    const items: ItemModel[] = [];

    departmentData.forEach((data) => items.push({ label: data.name, value: data.id ? data.id : '' }));

    setDepartmentItems(items);
  };

  const getPositionItemsAsync = async () => {
    const response = await shareValue.getPostionListSelectionAsync();
    const positionData: ItemModel[] = response.data;
    const items: ItemModel[] = [];

    positionData.forEach((data) => items.push({ label: data.label, value: data.value }));

    setPositionItems(items);
  };

  const getUserListAsync = useCallback(async () => {
    const response = await shareValue.getUserListSelectionAsync(page, size, criteriaData);

    if (response.status === HttpStatusCode.OK) {
      if (props.userDataListSelect.length > 0) {
        const data = checkUserSelect(response.data.data);

        setTotalRecords(data.length);
        setUserList(data);
      } else {
        setUserList(response.data.data);
        setTotalRecords(response.data.totalRecords);
      }
    }
  }, [criteriaData, props.show]);

  useEffect(() => {
    if (props.show) {
      getDepartmentItemsAsync();
      getUserListAsync();
      getPositionItemsAsync();
    }
  }, [page, size, props.show]);

  const checkUserSelect = (userData: UserListSelectionResponse[]) => {
    const userListRowData: UserListSelectionResponse[] = [...userData];
    const userListSelectId = props.userDataListSelect.map((data) => data.id);

    userListSelectId.forEach((x) => {
      userListRowData.forEach((y, index) => {
        if (x === y.id) {
          userListRowData.splice(index, 1);
        }
      });
    });

    return userListRowData;
  };

  const chooseUser = (userData: UserListSelectionResponse) => {
    props.sendIdUser(userData);
    props.onHide();
  };

  const searchData = () => {
    getUserListAsync();
  };

  const clearData = useCallback(() => {
    setCriteriaData({} as UserListSelectionParams);

    getUserListAsync();
  }, [props.show]);

  return (
    <Modal show={props.show}
      size='xl'>
      <div className='d-flex justify-content-between'>
        <p className='fs-'>ค้นหารายชื่อ</p>
        <FaTimes className='cursor-pointer'
          onClick={props.onHide} />
      </div>
      <Card>
        <div className='mx-3 mb-3'>
          <p className='mt-3'>ค้นหา</p>
          <div>
            <div className='criteria'>
              <Row>
                <Col lg={4}>
                  <Input value={criteriaData.fullName}
                    label='ชื่อ-นามสกุล'
                    onChange={(value) => setCriteriaData({ ...criteriaData, fullName: value })} />
                </Col>
                <Col lg={4}>
                  <Selector
                    value={criteriaData.departmentId}
                    items={departmentItems}
                    label='ฝ่าย/สำนัก'
                    onChange={(value) => setCriteriaData({ ...criteriaData, departmentId: value === '' ? undefined : value })}
                  />
                </Col>
                <Col lg={4}>
                  <Selector
                    label='ตำแหน่ง'
                    value={criteriaData.positionId}
                    onChange={(value) => setCriteriaData({ ...criteriaData, positionId: value })}
                    items={postionItems}
                  />
                </Col>
              </Row>
              <div className='d-flex mb-3 gap-2'>
                <Button
                  variant='primary'
                  type='button'
                  className='d-flex align-items-center gap-2'
                  onClick={searchData}
                >
                  <FaSearch />ค้นหา
                </Button>
                <Button
                  variant='outline-primary'
                  className='d-flex align-items-center gap-2'
                  onClick={clearData}
                >
                  <FaEraser />ล้าง
                </Button>
              </div>
            </div>
            <Table total={totalRecords}
              onChange={(size, page) => (setSize(size), setPage(page))}>
              <thead>
                <tr>
                  <th style={{ minWidth: 75 }}>ลำดับ</th>
                  <th style={{ minWidth: 75 }}>ชื่อ-นามสกุล</th>
                  <th style={{ minWidth: 75 }}>ฝ่าย/สำนัก</th>
                  <th style={{ minWidth: 200 }}>ตำแหน่ง</th>
                  <th style={{ minWidth: 75 }} />
                </tr>
              </thead>
              <tbody>
                {userList.map((data, index) => (
                  <tr key={data.id}>
                    <td className='text-center'>{index + 1}</td>
                    <td>{data.fullName}</td>
                    <td>{data.departmentName}</td>
                    <td className='text-center'>{data.positionName}</td>
                    <td className='text-center'>
                      <Button
                        onClick={() => chooseUser(data)}
                      >
                        เลือก
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>
    </Modal>
  );
}
