import {
  Card,
  DirectiveStatus,
  Input,
  InputTextArea,
  PurchaseOrderStatus,
  Selector,
  Status,
  StatusType,
  Table,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import { HistoryModal } from 'components/Modal';
import { PlanModal } from 'components/Modal/PlanModal';
import { useState } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaHistory,
  FaSave,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { dataHistory } from '../data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function GetStatus(i: number) {
  switch (i) {
    case 1:
      return <Status type={StatusType.PURCHASEORDER}
        value={PurchaseOrderStatus.DRAFT} />;
    case 2:
      return <Status type={StatusType.PURCHASEORDER}
        value={PurchaseOrderStatus.PENDING} />;
    case 3:
      return <Status type={StatusType.PURCHASEORDER}
        value={PurchaseOrderStatus.APPROVED} />;
    default:
      return <Status type={StatusType.PURCHASEORDER}
        value={PurchaseOrderStatus.REJECTED} />;
  }
}

function Step1({ onClickNext, onClickBack }: Props) {
  const [showHistory, setShowHistory] = useState(false);
  const [showPlan, setShowPlan] = useState(false);

  const navigate = useNavigate();

  const toOligarch = () => {
    navigate('/pr/pr0208/oligarch');
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title
          text='ข้อมูลอ้างอิง'
          className='fs-5 text-primary'
          extraElement={
            <div className='d-flex gap-2'>
              <DirectiveStatus
                value='Draft'
              />
              <Button
                onClick={() => setShowHistory(!showHistory)}
                variant='outline-primary'>
                <FaHistory className='me-1' />
                ประวัติการ
              </Button>
            </div>
          }
        />
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={3}>
            <Input
              label='เลขที่อ้างอิง'
              placeholder='เลขที่อ้างอิง'
              value='DP66001'
              disabled
              textSearch
              variant='primary'
              onClick={() => setShowPlan(!showPlan)}
            />
          </Col>
          <Col sm={12}
            lg={4}>
            <p className='mb-0 mt-3'><span className='text-danger me-1'>*</span>เลขที่อ้างอิงเพื่อออก PO</p>
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={3}>
            <Selector label='ปีงบประมาณ'
              placeholder='กรุณาเลือก'
              rule={{ required: true }} />
          </Col>
          <Col sm={12}
            lg={3}>
            <Selector label='ฝ่าย/สำนัก'
              placeholder='กรุณาเลือก'
              rule={{ required: true }} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={8}>
            <Row>
              <Col sm={12}
                lg={4}>
                <Selector label='วิธีการจัดหา'
                  placeholder='วิธีการจัดหา' />
              </Col>
              <Col sm={12}
                lg={4}
                style={{ paddingTop: '38px' }}>
                <Selector rule={{ required: true }}
                  placeholder='เจาะจง' />
              </Col>
              <Col sm={12}
                lg={4}
                style={{ paddingTop: '38px' }}>
                <Selector />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={8}>
            <InputTextArea label='ชื่อโครงการ'
              rule={{ required: true }} />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title
          text='ใบสั่ง'
          className='fs-5 text-primary'
        />
        <Row className='mt-3 align-items-center'>
          <Col sm={12}>
            <Table total={1}
              hidePagination>
              <thead>
              <tr>
                <th style={{ minWidth: 20 }}>ลำดับ</th>
                <th style={{ minWidth: 150 }}>เลขที่ผู้เสียภาษี</th>
                <th style={{ minWidth: 150 }}>ชื่อ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>เลขที่ PO</th>
                <th style={{ minWidth: 150 }}>เลขที่ใบสั่ง/สัญญา</th>
                <th style={{ minWidth: 50 }}>สถานะ</th>
                <th />
              </tr>
              </thead>
              <tbody>
              {Array.from({ length: 2 })
                .map((_, index) => (
                  <tr key={index}>
                    <td className='text-center'>{index + 1}</td>
                    <td className='text-start'>0994000160461</td>
                    <td className='text-start'>บริษัท โค้ดฮาร์ด จำกัด</td>
                    <td className='text-end'>1,000,000</td>
                    <td className='text-center'><Input value={`40600${index + 1}`} /></td>
                    <td className='text-center'>จพ.(สบส) 00{index + 1}/66</td>
                    <td className='text-center'>{GetStatus(index + 1)}</td>
                    <td className='text-center'>
                      <div className='d-flex justify-content-end'>
                        <Button
                          className='d-flex align-items-center gap-2'
                          onClick={toOligarch}
                        >
                          ระหว่างรอลงนาม
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              <tr>
                <td className='text-center'>3</td>
                <td className='text-start'>0994000160461</td>
                <td className='text-start'>บริษัท โค้ดฮาร์ด จำกัด</td>
                <td className='text-end'>1,000,000</td>
                <td className='text-center'><Input value='406003' /></td>
                <td className='text-center'>จพ.(สบส) 003/66</td>
                <td className='text-center'>{GetStatus(3)}</td>
                <td className='text-center'>
                  <div className='d-flex justify-content-end'>
                    <Button
                      variant='success'
                      className='d-flex align-items-center gap-2'
                      onClick={toOligarch}
                    >
                      ลงนามแล้ว
                    </Button>
                  </div>
                </td>
              </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>
      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          onClick={onClickBack}
          variant='outline-primary'>
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          <Button
            className='me-2 px-3'
            onClick={onClickNext}
            variant='outline-primary'>
            <FaSave className='me-2 pb-1 fs-5' />
            บันทึก
          </Button>
        </div>
        <Button
          onClick={onClickNext}
          className='px-3'>
          ถัดไป
          <MdArrowForward className='ms-2 pb-1 fs-5' />
        </Button>
      </div>
      {/* <SearchNameModal
        show={show}
        onHide={() => setShow(!show)}
        data={dataSearchName}
      /> */}
      <HistoryModal
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        data={dataHistory}
      />
      <PlanModal
        show={showPlan}
        onHide={() => setShowPlan(!showPlan)}
      />
    </div>
  );
}

export default Step1;
