import { HttpStatusCode } from 'axios';
import {
  Check,
  Currency,
  DatePicker,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  ItemModel,
  Pagination,
} from 'models';
import {
  PL0207Criteria,
  PL0207Table,
} from 'models/PL/PL0207Models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { PL0207Service } from 'services/PL';
import {
  GetMonthYearBC,
  StatusRecordDocument,
  thaiFormatDateWithSlash,
  THCurrency,
} from 'utils';
import { PlanStatusName } from 'utils/constants/PlanEnum';
import { generateUniqueId } from '../../../utils/helper';

type Loader = { supplyMethodTypeList: ItemModel[], departmentList: ItemModel[], loaderDataTable: PL0207Table, status: number };

type Criteria = {
  planNumber: string,
  departmentId?: string,
  planName: string,
  planBudget?: number,
  expectingProcurementAt: Date,
  planAnnouncementDate: Date,
  createBy: string,
  supplyMethodType: string[],
  status: string[],
}

enum CriteriaStatus {
  Assigned = 'Assigned',
  DraftRecordDocument = 'DraftRecordDocument',
  WaitingAcceptor = 'WaitingAcceptor',
  ApproveAcceptor = 'ApproveAcceptor',
  RejectAcceptor = 'RejectAcceptor',
}

export default function PL0207() {
  const navigate = useNavigate();
  const { departmentList, supplyMethodTypeList, loaderDataTable, status } = useLoaderData() as Loader;
  const [tableData, setTableData] = useState<PL0207Table>({} as PL0207Table);
  const [criteria, setCriteria] = useState<Criteria>({ supplyMethodType: supplyMethodTypeList.map((i) => i.value), status: Object.values(CriteriaStatus) } as Criteria);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, size: 10 } as Pagination);
  const [statusAll, setStatusAll] = useState<boolean>(true);
  const [supplyMethodAll, setSupplyMethodAll] = useState<boolean>(true);
  const [isMount, setMount] = useState<boolean>(false);

  useEffect(() => {
    if (status === HttpStatusCode.Ok) {
      if (loaderDataTable) {
        setTableData(loaderDataTable);
        setPagination({ ...pagination, totalRecords: loaderDataTable.totalRecords });
        setMount(true);
      }
    } else {
      navigate(-1);
    }
  }, [loaderDataTable]);

  const getRecordDocListAsync = useCallback(async (criteriaData: Criteria, statusList: string[], allStatus: boolean, allSupplyMethod: boolean) => {
    const requestData = {
      planNumber: criteriaData.planNumber,
      departmentId: criteriaData.departmentId,
      planName: criteriaData.planName,
      planBudget: criteriaData.planBudget,
      organizer: criteriaData.createBy,
      expectingProcurementAt: criteriaData.expectingProcurementAt,
      planAnnouncementDate: criteriaData.planAnnouncementDate,
      statusAssigned: comparePlanStatus(CriteriaStatus.Assigned, statusList),
      statusDraftRecordDocument: comparePlanStatus(CriteriaStatus.DraftRecordDocument, statusList),
      statusWaitingAcceptor: comparePlanStatus(CriteriaStatus.WaitingAcceptor, statusList),
      statusApproveAcceptor: comparePlanStatus(CriteriaStatus.ApproveAcceptor, statusList),
      statusRejectAcceptor: comparePlanStatus(CriteriaStatus.RejectAcceptor, statusList),
      statusAll: allStatus,
      supplyMethodType: !allSupplyMethod ? criteriaData.supplyMethodType[0] : '',
      supplyMethodAll: allSupplyMethod,
    } as PL0207Criteria;
    (async () => {
      const { data, status } = await PL0207Service.getListAsync(pagination.page, pagination.size, requestData);
      if (status === HttpStatusCode.Ok) {
        setTableData(data);
        setPagination({ ...pagination, totalRecords: data.totalRecords });
      }
    })();
  }, [pagination.page, pagination.size]);

  useEffect(() => {
    getRecordDocListAsync(criteria, criteria.status, statusAll, supplyMethodAll);
  }, [getRecordDocListAsync]);

  const handlerOnSearchAsync = async (criteriaData: Criteria) => {
    getRecordDocListAsync(criteriaData, criteriaData.status, statusAll, supplyMethodAll);
  };

  const comparePlanStatus = (status: string, ListData: string[]): boolean => {
    let result = false;
    ListData.forEach((i) => {
      if (status === i) {
        result = true;
      }
    });

    return result;
  };

  const clearCriteria = () => {
    setCriteria({
      supplyMethodType: supplyMethodTypeList.map((i) => i.value), status: Object.values(CriteriaStatus),
    } as Criteria);
    setStatusAll(true);
    setSupplyMethodAll(true);
    getRecordDocListAsync({ supplyMethodType: supplyMethodTypeList.map((i) => i.value), status: Object.values(CriteriaStatus) } as Criteria, [], true, true);
  };

  const supplyMethodOnChange = (value: string, result: boolean) => {
    if (result) {
      if (!criteria.supplyMethodType) {
        criteria.supplyMethodType = [];
      }

      criteria.supplyMethodType.push(value);

      setCriteria({ ...criteria });

      if (criteria.supplyMethodType.length === 2) {
        setSupplyMethodAll(true);
      }
    } else {
      const supplyMethod = criteria.supplyMethodType;
      const i = supplyMethod.findIndex((s) => s === value);

      supplyMethod.splice(i, 1);
      setCriteria({ ...criteria, supplyMethodType: [...supplyMethod] });
    }

    if (criteria.supplyMethodType.length < 2) {
      setSupplyMethodAll(false);
    }
  };

  const supplyMethodAllOnChange = (result: boolean) => {
    if (result) {
      setSupplyMethodAll(true);
      setCriteria({ ...criteria, supplyMethodType: supplyMethodTypeList.map((i) => i.value) });
    } else {
      setSupplyMethodAll(false);
      setCriteria({ ...criteria, supplyMethodType: [] });
    }
  };

  const statusOnChange = (value: string, result: boolean) => {
    if (result) {
      if (!criteria.status) {
        criteria.status = [];
      }

      criteria.status.push(value);

      setCriteria({ ...criteria });

      if (criteria.status.length === Object.values(StatusRecordDocument).length) {
        setStatusAll(true);
      }
    } else {
      const { status } = criteria;
      const i = status.findIndex((s) => s === value);

      status.splice(i, 1);
      setCriteria({ ...criteria, status: [...status] });
    }

    if (criteria.status.length < Object.values(StatusRecordDocument).length) {
      setStatusAll(false);
    }
  };

  const statusAllOnChange = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setCriteria({ ...criteria, status: Object.values(StatusRecordDocument) });
    } else {
      setStatusAll(false);
      setCriteria({ ...criteria, status: [] });
    }
  };

  return (
    isMount
      ? (
        <div className='PL0207'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4 className='text-primary m-0'>จัดทำเอกสารบันทึก</h4>
          </div>
          <hr />
          <div className='criteria'>
            <Row>
              <Col sm={6}
                md={4}
                lg={4}
                xl={3}>
                <Input
                  value={criteria.planNumber}
                  onChange={(val) => setCriteria({ ...criteria, planNumber: val })}
                  label='เลขที่แผน'
                />
              </Col>
              <Col sm={6}
                md={4}
                lg={4}
                xl={3}>
                <Selector
                  label='ฝ่าย/สำนัก'
                  items={departmentList}
                  value={criteria.departmentId}
                  onChange={(val) => setCriteria({ ...criteria, departmentId: val })}
                />
              </Col>
              <Col sm={6}
                md={4}
                lg={4}
                xl={3}>
                <Input
                  label='ชื่อโครงการ'
                  value={criteria.planName}
                  onChange={(val) => setCriteria({ ...criteria, planName: val })}
                />
              </Col>
              <Col sm={6}
                md={4}
                lg={4}
                xl={3}>
                <Currency
                  label='งบประมาณโครงการ'
                  value={criteria.planBudget}
                  onChange={(val) => setCriteria({ ...criteria, planBudget: val })}
                />
              </Col>
              <Col sm={6}
                md={4}
                lg={4}
                xl={3}>
                <DatePicker
                  label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
                  value={criteria.expectingProcurementAt}
                  onChange={(val) => setCriteria({ ...criteria, expectingProcurementAt: val })}
                  monthYearOnly
                />
              </Col>
              <Col sm={6}
                md={4}
                lg={4}
                xl={3}>
                <DatePicker
                  label='วันที่ประกาศแผน'
                  value={criteria.planAnnouncementDate}
                  onChange={(val) => setCriteria({ ...criteria, planAnnouncementDate: val })}
                />
              </Col>
              <Col sm={6}
                md={4}
                lg={4}
                xl={3}>
                <Input
                  label='ผู้จัดทำ'
                  value={criteria.createBy}
                  onChange={(val) => setCriteria({ ...criteria, createBy: val })}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}
                md={4}
                lg={4}
                xl={3}>
                <Form.Label>วิธีจัดหา</Form.Label>
                <div className='d-flex gap-2'>
                  <Check
                    value={supplyMethodAll}
                    onChange={supplyMethodAllOnChange}
                    label='ทั้งหมด'
                  />
                  {
                    supplyMethodTypeList.map((data, index) => (
                      <Check
                        key={generateUniqueId(index)}
                        value={criteria.supplyMethodType?.some((s) => s === data.value)}
                        onChange={(val) => supplyMethodOnChange(data.value, val)}
                        label={<>{data.label} </>}
                      />
                    ))
                  }
                </div>
              </Col>
              <Col>
                <Form.Label>สถานะ</Form.Label>
                <div className='d-flex gap-2'>
                  <Check
                    value={statusAll}
                    onChange={statusAllOnChange}
                    label='ทั้งหมด'
                  />
                  <Check
                    value={criteria.status?.some((s) => s === CriteriaStatus.Assigned)}
                    onChange={(val) => statusOnChange(CriteriaStatus.Assigned, val)}
                    label={<>{PlanStatusName.Assigned} </>}
                  />
                  <Check
                    value={criteria.status?.some((s) => s === CriteriaStatus.DraftRecordDocument)}
                    onChange={(val) => statusOnChange(CriteriaStatus.DraftRecordDocument, val)}
                    label={<>{PlanStatusName.DraftRecordDocument}</>}
                  />
                  <Check
                    value={criteria.status?.some((s) => s === CriteriaStatus.WaitingAcceptor)}
                    onChange={(val) => statusOnChange(CriteriaStatus.WaitingAcceptor, val)}
                    label={<>{PlanStatusName.WaitingAcceptor} </>}
                  />
                  <Check
                    value={criteria.status?.some((s) => s === CriteriaStatus.ApproveAcceptor)}
                    onChange={(val) => statusOnChange(CriteriaStatus.ApproveAcceptor, val)}
                    label={<>{PlanStatusName.ApproveAcceptor}</>}
                  />
                  <Check
                    value={criteria.status?.some((s) => s === CriteriaStatus.RejectAcceptor)}
                    onChange={(val) => statusOnChange(CriteriaStatus.RejectAcceptor, val)}
                    label={<>{PlanStatusName.RejectAcceptor}</>}
                  />
                </div>
              </Col>
            </Row>
            <div className='d-flex gap-2 mt-3'>
              <Button
                variant='primary'
                className='d-flex align-items-center gap-2'
                onClick={() => handlerOnSearchAsync(criteria)}
              >
                <FaSearch />ค้นหา
              </Button>
              <Button
                variant='outline-primary'
                className='d-flex align-items-center gap-2'
                onClick={() => clearCriteria()}
              >
                <FaEraser />ล้าง
              </Button>
            </div>
          </div>
          <Table
            total={pagination.totalRecords}
            onChange={(size, page) => (setPagination({ ...pagination, size, page }))}
            className='mt-4'
          >
            <thead>
            <tr>
              <th style={{ minWidth: 135 }}>เลขที่แผน</th>
              <th style={{ minWidth: 135 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 300, width: 500 }}>ชื่อแผน</th>
              <th style={{ minWidth: 150 }}>งบประมาณ (บาท)</th>
              <th style={{ minWidth: 100 }}>วิธีจัดหา</th>
              <th style={{ minWidth: 50 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              <th style={{ minWidth: 120 }}>วันที่ประกาศแผน</th>
              <th style={{ minWidth: 120 }}>ผู้จัดทำ</th>
              <th style={{ minWidth: 120 }}>วันที่จัดทำ</th>
              <th style={{ minWidth: 150 }}>สถานะ</th>
            </tr>
            </thead>
            <tbody>
            {tableData.data?.map((value, index) => (
              <tr key={generateUniqueId(index)}>
                <td className='text-center'>
                  <Button
                    variant='link'
                    onClick={() => navigate(`detail/${value.id}`)}
                  >  {value.planNumber}
                  </Button>

                </td>
                <td className='text-center'>{value.department}</td>
                <td className='text-wrap'>{value.planName}</td>
                <td className='text-end'>{THCurrency(value.planBudget)}</td>
                <td className='text-center'>{value.supplyMethod}</td>
                <td className='text-center'>{GetMonthYearBC(value.expectingProcurementAt)}</td>
                <td className='text-center'>{thaiFormatDateWithSlash(value.planAnnouncementDate)}</td>
                <td>{value.createdFullName}</td>
                <td className='text-center'>{thaiFormatDateWithSlash(value.createdAt)}</td>
                <td className='text-center'><Status type={StatusType.PROCESS}
                  value={value.status} /></td>
              </tr>
            ))}
            </tbody>
          </Table>
        </div>
      ) : <></>
  );
}
