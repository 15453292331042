import {
  Check,
  DatePicker,
  Input,
  Selector,
  Status,
  StatusType,
  SupplyMethodEnum,
  Table,
} from 'components';
import { ItemModel } from 'models';
import {
  PlanProcurementAnnouncementCriteria,
  PlanProcurementAnnouncementResponse,
} from 'models/planAnnouncement';
import { PublicPlanProcurementStatusCount } from 'models/statusCount';
import {
  Dispatch,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import {
  planAnnouncement,
  publicPlanAnnouncement as publicService,
} from 'services';
import {
  GetFormatDateBC,
  HttpStatusCode,
  THCurrency,
} from 'utils';
import { generateUniqueId } from '../../../utils/helper';

type loader = {
  planAnnouncementListData: PlanProcurementAnnouncementResponse[],
  planAnnouncementListTotal: number,
  supplyMethodDDL: ItemModel[],
  badge: PublicPlanProcurementStatusCount,
}

interface CriteriaProps {
  sendCriteriaData: (data: PlanProcurementAnnouncementCriteria) => void;
  badgeList: PublicPlanProcurementStatusCount;
}

interface DataTableProps {
  criteriaData: PlanProcurementAnnouncementCriteria;
  setBadgeList: Dispatch<React.SetStateAction<PublicPlanProcurementStatusCount>>;
}

export default function Public() {
  const { supplyMethodDDL, badge } = useLoaderData() as loader;
  const PlanStatusNameArray = [
    { label: 'ทั้งหมด', value: 'All' },
    { label: 'แบบร่าง', value: 'Draft' },
    { label: 'มอบหมายงาน จพ.', value: 'AssignJorPor' },
    { label: 'รอให้ความเห็นชอบ', value: 'WaitingAccept' },
    { label: 'เห็นชอบ', value: 'Accepted' },
    { label: 'ไม่เห็นชอบ', value: 'Reject' },
  ];

  const [criteria, setCriteria] = useState<PlanProcurementAnnouncementCriteria>({
    supplyMethod: supplyMethodDDL.map((i) => i.value),
    status: PlanStatusNameArray.map((i) => i.value),
  } as PlanProcurementAnnouncementCriteria);
  const [badgeList, setBadgeList] = useState<PublicPlanProcurementStatusCount>(badge);
  return (
    <div className='m01'>
      <SummaryYearDetailHeader />
      <Criteria sendCriteriaData={(data) => setCriteria(data)}
        badgeList={badgeList} />
      <DataTable criteriaData={criteria}
        setBadgeList={setBadgeList} />
    </div>
  );
}

function SummaryYearDetailHeader() {
  const navigate = useNavigate();

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>ขออนุมัติเผยแพร่แผนจัดซื้อจัดจ้าง</h4>
        <Button onClick={() => navigate('detail')}>
          <MdAdd className='fs-5 me-2' />สร้างรายการขอเผยแพร่แผน
        </Button>
      </div>
      <hr />
    </>
  );
}

function Criteria(props: CriteriaProps) {
  const { supplyMethodDDL } = useLoaderData() as loader;

  const PlanStatusNameArray = [
    { label: 'ทั้งหมด', value: 'All', count: props.badgeList.countStatusAll },
    { label: 'แบบร่าง', value: 'Draft', count: props.badgeList.countStatusDraft },
    { label: 'ผอ.จพ. มอบหมาย', value: 'AssignJorPor', count: props.badgeList.countStatusAssignJorPor },
    { label: 'รอให้ความเห็นชอบ', value: 'WaitingAccept', count: props.badgeList.countStatusWaitingAccept },
    { label: 'เห็นชอบ', value: 'Accepted', count: props.badgeList.countStatusAccept },
    { label: 'ส่งกลับแก้ไข', value: 'Reject', count: props.badgeList.countStatusReject },
  ];

  const [criteria, setCriteria] = useState<PlanProcurementAnnouncementCriteria>({
    supplyMethod: supplyMethodDDL.map((i) => i.value),
    status: PlanStatusNameArray.map((i) => i.value),
  } as PlanProcurementAnnouncementCriteria);

  const items: ItemModel[] = [];
  const year = new Date();
  const thYear = (year.getFullYear() + 543) - 10;

  for (let i = 0; i <= 20; i++) {
    items.push({ label: `${thYear + i}`, value: `${thYear + i}` });
  }

  const supplyMethodOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const supplyMethod = [...supplyMethodDDL.map((s) => s.value)];
        setCriteria({ ...criteria, supplyMethod });
        return;
      }
      setCriteria({ ...criteria, supplyMethod: [] });
      return;
    }
    if (result) {
      if ((criteria.supplyMethod.length + 1) === (supplyMethodDDL.length - 1)) {
        setCriteria({
          ...criteria,
          supplyMethod: [
            ...criteria.supplyMethod,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        supplyMethod: [
          ...criteria.supplyMethod,
          value,
        ],
      });
    } else {
      const supplyMethod = [...criteria.supplyMethod];
      const index = supplyMethod.findIndex((ps) => ps === value);

      if (index >= 0) {
        supplyMethod.splice(index, 1);
        const allIndex = supplyMethod.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          supplyMethod.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, supplyMethod });
      }
    }
  };

  const statusOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const status = [...PlanStatusNameArray.map((s) => s.value)];
        setCriteria({ ...criteria, status });
        return;
      }
      setCriteria({ ...criteria, status: [] });
      return;
    }
    if (result) {
      if ((criteria.status.length + 1) === (PlanStatusNameArray.length - 1)) {
        setCriteria({
          ...criteria,
          status: [
            ...criteria.status,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        status: [
          ...criteria.status,
          value,
        ],
      });
    } else {
      const status = [...criteria.status];
      const index = status.findIndex((ps) => ps === value);

      if (index >= 0) {
        status.splice(index, 1);
        const allIndex = status.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          status.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, status });
      }
    }
  };

  const sendCriteriaSearch = () => {
    props.sendCriteriaData(criteria);
  };

  const clearCriteriaSearch = () => {
    const criteriaClear = {
      supplyMethod: supplyMethodDDL.map((i) => i.value),
      status: PlanStatusNameArray.map((i) => i.value),
    } as PlanProcurementAnnouncementCriteria;

    props.sendCriteriaData(criteriaClear);
    setCriteria(criteriaClear);
  };

  return (
    <Form>
      <Row className='criteria'>
        <Col sm={12}
          md={4}
          xl={3}>
          <Input label='เลขที่คำขอเผยแพร่แผนจัดซื้อจัดจ้าง'
            value={criteria.planNumber}
            placeholder='เลขที่คำขอเผยแพร่แผนจัดซื้อจัดจ้าง'
            onChange={(value) => setCriteria({ ...criteria, planNumber: value })} />
        </Col>
        <Col sm={12}
          md={4}
          xl={3}>
          <Selector label='ปีที่เริ่มต้น'
            items={items}
            value={criteria.startYear ? criteria.startYear.toString() : undefined}
            placeholder='ปีที่เริ่มต้น'
            onChange={(value) => setCriteria({ ...criteria, startYear: Number(value) })} />
        </Col>
        <Col sm={12}
          md={4}
          xl={3}>
          <Selector label='ปีที่สิ้นสุด'
            items={items}
            value={criteria.endYear ? criteria.endYear.toString() : undefined}
            placeholder='ปีที่สิ้นสุด'
            onChange={(value) => setCriteria({ ...criteria, endYear: Number(value) })} />
        </Col>
        <Col sm={12}
          md={4}
          xl={3}>
          <DatePicker label='วันที่เริ่มต้น'
            value={criteria.startDate}
            placeholder='วันที่เริ่มต้น'
            onChangeHasNullable={(value) => setCriteria({ ...criteria, startDate: value })} />
        </Col>
        <Col sm={12}
          md={4}
          xl={3}>
          <DatePicker label='วันที่สิ้นสุด'
            value={criteria.endDate}
            placeholder='วันที่สิ้นสุด'
            onChangeHasNullable={(value) => setCriteria({ ...criteria, endDate: value })} />
        </Col>
      </Row>
      <Row>
        <Col sm={6}
          className='Type'>
          วิธีจัดหา
          <div className='d-flex gap-2'>
            {supplyMethodDDL?.map((v, i) => (
              <Check
                key={generateUniqueId(i)}
                value={criteria.supplyMethod?.some((i) => i === v.value)}
                onChange={(val) => supplyMethodOnChange(v.value, val)}
                label={(
                  <>
                    {v.label}
                    <Status type={StatusType.SUPPLYMETHOD}
                      value={v.value}
                      couting={v.value === SupplyMethodEnum.All ? props.badgeList.countStatusAll
                        : v.value === SupplyMethodEnum.MethodId60 ? props.badgeList.countTypeSixty
                          : props.badgeList.countTypeEighty}
                      badge />
                  </>
                )}
              />
            ))}
          </div>
        </Col>
        <Col sm={6}
          className='Status'>
          สถานะ
          <div className='d-flex gap-2 flex-wrap'>
            {PlanStatusNameArray?.map((v, i) => (
              <Check
                key={generateUniqueId(i)}
                value={criteria.status?.some((i) => i === v.value)}
                onChange={(val) => statusOnChange(v.value, val)}
                label={<>{v.label}
                  <Status type={StatusType.ANNOUNCEPLANPROCESS}
                    value={v.value}
                    couting={v.count}
                    badge />
                </>}
              />
            ))}
          </div>
        </Col>
      </Row>
      <Button form='formPL01'
        type='submit'
        variant='primary'
        className='me-2'
        onClick={sendCriteriaSearch}>
        <FaSearch className='me-2' />ค้นหา
      </Button>
      <Button variant='outline-primary'
        onClick={clearCriteriaSearch}>
        <FaEraser className='me-2' />ล้าง
      </Button>
    </Form>
  );
}

function DataTable(props: DataTableProps) {
  const { planAnnouncementListData, planAnnouncementListTotal } = useLoaderData() as loader;
  const [listData, setListData] = useState<PlanProcurementAnnouncementResponse[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [onInit, setOnInit] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (planAnnouncementListData) {
      setListData(planAnnouncementListData);
      setTotalRecords(planAnnouncementListTotal);
      setOnInit(false);
    }
  }, []);

  const getPlanAnnoucementListAsync = useCallback(async () => {
    const [response, badgeResponse] = await Promise.all([
      planAnnouncement.getPlanProcurementAnnouncementListAsync(page, size, props.criteriaData),
      publicService.GetBadgePublicPlanAsync(page, size, props.criteriaData),
    ]);
    if (response.status === HttpStatusCode.OK) {
      setListData(response.data.data);
      setTotalRecords(response.data.totalRecords);
    }
    if (badgeResponse.status === HttpStatusCode.OK) {
      props.setBadgeList(badgeResponse.data);
    }
  }, [page, size, props.criteriaData]);

  useEffect(() => {
    if (!onInit) {
      getPlanAnnoucementListAsync();
    }
  }, [getPlanAnnoucementListAsync]);

  return (
    <>
      {!onInit
        && (
          <Table className='mt-4'
            total={totalRecords}
            onChange={(size, page) => (setSize(size), setPage(page))}>
            <thead>
              <tr className='text-center'>
                <th>เลขที่คำขอเผยแพร่แผนจัดซื้อจัดจ้าง</th>
                <th>สถานะ</th>
                <th>ปี</th>
                <th>จำนวนโครงการ</th>
                <th>งบประมาณรวม</th>
                <th>วิธีจัดหา</th>
                <th>วันที่เผยแพร่</th>
              </tr>
            </thead>
            <tbody>
              {listData.map((data, index) => (
                <tr className='text-center'
                  key={index}>
                  <td>
                    <Button variant='link'
                      onClick={() => navigate(`detail/${data.id}`)}>{data.planNumber}</Button>
                  </td>
                  <td><Status type={StatusType.ANNOUNCEPLANPROCESS}
                    value={data.status} /></td>
                  <td>{data.budgetYear}</td>
                  <td className='text-end'>{data.projectsCount}</td>
                  <td className='text-end'>{THCurrency(data.budget)}</td>
                  <td>{data.supplyMethod}</td>
                  <td>{GetFormatDateBC(data.announcementDate)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
    </>
  );
}
