import {
  Currency,
  DatePicker,
  Input,
  Table,
  UploadFile,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { MedianPriceSource } from 'models';
import {
  useCallback,
  useContext,
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row as RowBT,
} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { submitForm } from 'utils';
import toast from 'utils/toast';
import { useForm } from 'utils/useForm';
import { Context } from '.';
import { DateFormat } from '../../../../constant/dateFormat';

const formatDate = new DateFormat();

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

interface ProjectForm {
  refPriceDueDate: Date;
  refPrice: number;
}

interface PartnerProps {
  name: string;
}

export default function Step2({ onClickNext, onClickBack }: Props) {
  const formId = 'pp0204_ref_price_step_2';
  const context = useContext(Context);
  const { handleSubmit, handleChange, data: formData, errors, handleClear } = useForm<ProjectForm>({
    validations: {},
    onSubmit: async () => {

    },
  });

  const onChange = (val: Date | number | undefined, prop: string) => {
    context.setMedianPrice({ ...context.medianPrice, [prop]: val });
  };

  const add = useCallback(() => {
    let { sources } = context.medianPrice;

    if (!sources) {
      sources = [];
    }

    context.setMedianPrice((mp) => ({
      ...mp,
      sources: [...sources, { company: '' } as MedianPriceSource],
    }));
  }, [context.medianPrice]);

  const remove = useCallback((i: number) => {
    const { sources } = context.medianPrice;

    sources.splice(i, 1);
    context.setMedianPrice((mp) => ({ ...mp, sources }));
  }, [context.medianPrice]);

  const sourceOnChange = (val: string, i: number) => {
    const { sources } = context.medianPrice;

    sources[i].company = val;
    context.setMedianPrice((mp) => ({ ...mp, sources }));
  };

  const next = () => {
    const data = context.medianPrice;

    submitForm();

    if (!data.sources || !data.sources.length) {
      toast.warn('ต้องมีแหล่งที่มาของราคากลาง (ราคาอ้างอิง) อย่างน้อย 1 รายการ');

      return;
    }

    // if (data.settingDate && data.middlePrice && data.sources.every(s => s.company)) {
    //   onClickNext();
    // }

    onClickNext();
  };

  return (
    <>
      <Card className='mt-3'>
        <Card.Body>
          <Title text='ข้อมุลราคากลาง'
            className='fs-5 text-primary' />
          <Form onSubmit={handleSubmit}
            className='mt-3'
            id={formId}>
            <RowBT>
              <Col sm={6}
                md={3}
                lg={3}
                xl={3}>
                <DatePicker
                  label='วันที่กำหนดราคากลาง (ราคาอ้างอิง)'
                  onChange={(val) => onChange(val, 'settingDate')}
                  // rule={{ required: true }}
                  value={context.medianPrice?.settingDate}
                />
              </Col>
              <Col sm={6}
                md={3}
                lg={3}
                xl={3}>
                <Currency
                  label='เป็นจำนวนเงิน (บาท)'
                  // rule={{ required: true }}
                  onChange={(val) => onChange(val, 'middlePrice')}
                  value={context.medianPrice?.middlePrice}
                />
              </Col>
            </RowBT>
          </Form>
        </Card.Body>
      </Card>
      <Card className='mt-3'>
        <Card.Body>
          <Title text='แหล่งที่มาของราคากลาง (ราคาอ้างอิง)'
            className='fs-5 text-primary' />
          <Table className='mt-4'
            hidePagination>
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>บริษัท</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {context.medianPrice?.sources?.map((s, i) => (
                <tr key={s.id}>
                  <td className='text-center'>{i + 1}</td>
                  <td>
                    <Input
                      value={s.company}
                      // rule={{ required: true }}
                      onChange={(val) => sourceOnChange(val, i)}
                    />
                  </td>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='outline-primary'
                        className='d-flex align-items-center gap-2'
                        onClick={() => remove(i)}
                      >
                        <FaTrashAlt />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            onClick={add}
            variant='light'
            className='mt-3'
          >
            <BsPlus /> เพิ่มข้อมูล
          </Button>
        </Card.Body>
      </Card>
      <Card className='mt-3'>
        <Card.Body>
          <Title text='เอกสารแนบ'
            className='fs-5 text-primary' />
          <Card>
            <Card.Body>
              <RowBT className='justify-content-center'>
                <Col sm={12}
                  md={6}>
                  <UploadFile />
                </Col>
              </RowBT>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
      <div className='d-flex justify-content-between pt-5 gap-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <ButtonCustom
          onClick={next}
          text="ถัดไป"
        />
      </div>
    </>
  );
}
