import {
  Breadcrumb,
  FileValue,
} from 'components';
import StepProgress, { ProgressBarRefType } from 'components/StepProgress';
import { SectionJorPorType } from 'constant/SectionType';
import { ItemModel } from 'models';
import {
  CommitteeApproversModel,
  JorPor06Detail,
  Jorpor06User,
  UpdateJorPor06,
} from 'models/PR/JorPor06Models';
import {
  createContext,
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { jorPor06 } from 'services';
import {
  downloadFile,
  HttpStatusCode,
  showConfirmModalAsync,
  useAppContext,
} from 'utils';
import {
  AcceptorStatus,
  AnnouncementStatus,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import { TorBudgetConstant } from 'constant/PreProcumentTorStatus';
import { isNull } from 'utils/helper';

type Loader = {
  departmentDDL: ItemModel[];
  supplyMethodDDL: ItemModel[];
  jorPor06Detail: JorPor06Detail;
  isCreate: boolean;
  isAcceptor: boolean;
  isQueue: boolean;
}

interface activitiesModel {
  activityDate: Date;
  fullNameAction: string;
  activity: string;
  action: string;
  remark?: string;
}

const BREADCRUMB_INFO = [
  {
    routerName: '/procurement-create-006', label: 'จัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้า (จพ.006)',
  },
  {
    routerName: '', label: 'รายละเอียดรายจัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้า (จพ.006)',
  },
];

type JorPor06DetailContext = {
  currentPage: number;
  jorpor06Detail: JorPor06Detail;
  activityData: activitiesModel[];
  getJorPor06DetailAsync: Function;
  getNewJorPor06DetailAsync: Function;
  updateJorPor06Async: Function;
  disabledField: boolean;
  setJorpor06Detail: Dispatch<React.SetStateAction<JorPor06Detail>>;
  uploadFile: Function;
  downloadFileAsync: Function;
  removeFile: Function;
  recallAsync: Function;
  recallShow: boolean;
  isCreate: boolean;
  isAcceptor: boolean;
  isQueue: boolean;
  acceptorId?: string;
  readonly: boolean;
};

export const Context = createContext({} as JorPor06DetailContext);

export default function Jorpor06Detail() {
  const { userId } = useAppContext();
  const stepRef = useRef<ProgressBarRefType>({} as ProgressBarRefType);
  const [stepStatus, setStepStatus] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [jorpor06Detail, setJorpor06Detail] = useState<JorPor06Detail>({} as JorPor06Detail);
  const [activityData, setActivityData] = useState<activitiesModel[]>([]);
  const [disabledField, setDisabledField] = useState(false);
  const [recallShow, setRecallShow] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isAcceptor, setIsAcceptor] = useState(false);
  const [isQueue, setIsQueue] = useState(false);
  const [onInit, setOnInit] = useState(true);
  const [acceptorId, setAcceptorId] = useState<string>();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getJorPor06DetailAsync();
  }, [userId]);

  const backToIndex = () => navigate('/procurement-create-006');

  const setActivityLogData = (data: JorPor06Detail) => {
    const activity: activitiesModel[] = data.activities.map(d => ({
      activityDate: d.date,
      fullNameAction: d.fullName,
      activity: d.description,
      action: d.action,
      remark: d.remark,
    }));

    setActivityData(activity);
  };

  const getJorPor06DetailAsync = async () => {
    if (id) {
      const { data, status } = await jorPor06.getJorpor06DetailAsync(id);

      if (status === HttpStatusCode.OK) {
        setDisabled(data.data);
        setInitailValue(data.data);
        setActivityLogData(data.data);
      }
    }
  };

  const getNewJorPor06DetailAsync = async (id: string) => {
    if (id) {
      const { data, status } = await jorPor06.getJorpor06DetailAsync(id);

      if (status === HttpStatusCode.OK) {
        setDisabled(data.data);
        setInitailValue(data.data);
        setActivityLogData(data.data);
      }
    }
  };

  const setInitailValue = (data: JorPor06Detail) => {
    if (data.acceptors && data.acceptors.length > 0) {
      const supervisor = data.acceptors.filter(d => d.sectionCode === SectionJorPorType.JORPOR_SUPERVISOR_USER)
        .sort((a, b) => a.sequence! - b.sequence!);
      const assistantPriciple = data.acceptors.filter(d => d.sectionCode === SectionJorPorType.JORPOR_ASSISTANT_PRINCIPLE_USER)
        .sort((a, b) => a.sequence! - b.sequence!);
      const principle = data.acceptors.filter(d => d.sectionCode === SectionJorPorType.JORPOR_PRINCIPLE_USER)
        .sort((a, b) => a.sequence! - b.sequence!);
      const assistantMd = data.acceptors.filter(d => d.sectionCode === SectionJorPorType.ASSISTANT_MD)
        .sort((a, b) => a.sequence! - b.sequence!);
      const deputyMd = data.acceptors.filter(d => d.sectionCode === SectionJorPorType.DEPUTY_MD)
        .sort((a, b) => a.sequence! - b.sequence!);
      const md = data.acceptors.filter(d => d.sectionCode === SectionJorPorType.MD)
        .sort((a, b) => a.sequence! - b.sequence!);

      data.jorPorSupervisorUser = supervisor;
      data.jorPorAssistantPricipleUser = assistantPriciple;
      data.jorPorPrincipleUser = principle;
      data.assistantMdUser = assistantMd;
      data.deputyMdUser = deputyMd;
      data.mdUser = md;
    }

    if (data.documents && data.documents.length > 0) {
      const documentLists = data.documents.map(d => ({ id: d.id, name: d.fileName }));

      data.documentList = documentLists;
    }

    setJorpor06Detail(data);
    setOnInit(false);
  };

  const setDisabled = useCallback((data: JorPor06Detail) => {
    if (!data.isCreator || (data.status !== AnnouncementStatus.Draft && data.status !== AnnouncementStatus.Reject)) {
      setDisabledField(true);
    } else {
      setDisabledField(false);
    }

    if (data.isCreator && data.status === AnnouncementStatus.WaitingAccept && data.acceptors.every(s => s.status === AcceptorStatus.PENDING)) {
      setRecallShow(true);
    } else {
      setRecallShow(false);
    }

    if (data.status === AnnouncementStatus.WaitingAccept || data.status === AnnouncementStatus.Accepted || data.status === AnnouncementStatus.Reject) {
      setCurrentPage(2);
      setStepStatus(2);
    } else if (data.status === AnnouncementStatus.WaitingComitteeApprove || data.status === AnnouncementStatus.WaitingForDirectorJorPorAssign || data.status === AnnouncementStatus.DraftAcceptor) {
      setCurrentPage(2);
      setStepStatus(2);
    } else {
      setCurrentPage(1);
      setStepStatus(1);
    }

    if (data.status === AnnouncementStatus.Draft) {
      if (stepStatus && currentPage) {
        setCurrentPage(currentPage);
        setStepStatus(stepStatus);
      }
    }

    setIsCreate(data.isCreator ||
      ((data.status === AnnouncementStatus.DraftAcceptor ||
        data.status === AnnouncementStatus.WaitingAccept) &&
        data.responsibilities &&
        data.responsibilities.some(a => isNull(a.delegateUserId, a.userId) === userId)));
    setIsAcceptor(data.isAcceptor);
    setIsQueue(data.isQueue);
    setAcceptorId(data.acceptorId);
  }, [stepStatus, currentPage]);

  const updateJorPor06Async = async (
    status: AnnouncementStatus,
    isRecall?: boolean,
    isSave?: boolean,
    docJorPor006Id?: string,
    docWinnerId?: string,
    docCancelId?: string,
    isPending?: boolean,
    jorPorComment?: string,
    isJorPorAssig?: boolean) => {
    const acceptor: Jorpor06User[] = [];
    let runNumber = 1;

    if (jorpor06Detail.jorPorSupervisorUser && jorpor06Detail.jorPorSupervisorUser.length > 0) {
      jorpor06Detail.jorPorSupervisorUser.forEach(d => {
        const data = d;
        data.sequence = runNumber;
        acceptor.push(d);

        runNumber += 1;
      });
    }

    if (jorpor06Detail.jorPorAssistantPricipleUser && jorpor06Detail.jorPorAssistantPricipleUser.length > 0) {
      jorpor06Detail.jorPorAssistantPricipleUser.forEach(d => {
        const data = d;
        data.sequence = runNumber;
        acceptor.push(d);

        runNumber += 1;
      });
    }

    if (jorpor06Detail.jorPorPrincipleUser && jorpor06Detail.jorPorPrincipleUser.length > 0) {
      jorpor06Detail.jorPorPrincipleUser.forEach(d => {
        const data = d;
        data.sequence = runNumber;
        acceptor.push(d);

        runNumber += 1;
      });
    }

    if (jorpor06Detail.assistantMdUser && jorpor06Detail.assistantMdUser.length > 0) {
      jorpor06Detail.assistantMdUser.forEach(d => {
        const data = d;
        data.sequence = runNumber;
        acceptor.push(d);

        runNumber += 1;
      });
    }

    if (jorpor06Detail.deputyMdUser && jorpor06Detail.deputyMdUser.length > 0) {
      jorpor06Detail.deputyMdUser.forEach(d => {
        const data = d;
        data.sequence = runNumber;
        acceptor.push(d);

        runNumber += 1;
      });
    }

    if (jorpor06Detail.mdUser && jorpor06Detail.mdUser.length > 0) {
      jorpor06Detail.mdUser.forEach(d => {
        const data = d;
        data.sequence = runNumber;
        acceptor.push(d);

        runNumber += 1;
      });
    }

    const saveResponsibilities: Jorpor06User[] = [];
    jorpor06Detail.responsibilities.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        const newData: Jorpor06User = {
          sectionCode: data.sectionCode,
          userId: data.userId,
          fullName: data.fullName,
          departmentName: data.departmentName,
          positionName: data.positionName,
          sequence: index + 1,
          inRefCode: data.inRefCode,
          processType: data.processType,
          sectionLevel: data.sectionLevel,
          sectionName: data.sectionName,
          sectionSequence: data.sectionSequence,
          sectionId: data.sectionId,
          sectionType: data.sectionType,
          delegateDepartmentName: data.delegateDepartmentName,
          delegateUserId: data.delegateUserId,
          delegateFullName: data.delegateFullName,
          delegatePositionName: data.delegatePositionName,
        };

        saveResponsibilities.push(newData);
      });

    const saveAcceptors = [...jorpor06Detail.jorPorDirectors, ...saveResponsibilities, ...jorpor06Detail.acceptors];
    const body: UpdateJorPor06 = {
      status: status,
      titleDocument: jorpor06Detail.titleDocument,
      acceptors: saveAcceptors,
      documentId: docJorPor006Id,
      documentWinnerId: docWinnerId,
      documentCancelingId: docCancelId,
      Committees: jorpor06Detail.committees,
      jorPorComment: jorPorComment,
      isJorPorAssig: isJorPorAssig,
      isPending: isPending,
    };

    const response = await jorPor06.updateJorPor06Async(jorpor06Detail.id, body);

    if (response.status === HttpStatusCode.ACCEPTED) {

      if (status === AnnouncementStatus.WaitingComitteeApprove) {

        if (isPending) {
          toast.success('ส่งเห็นชอบสำเร็จ');
          getJorPor06DetailAsync();
          return;
        }

      }

      if (status === AnnouncementStatus.WaitingAccept) {
        if (isSave) {
          toast.success('บันทึกข้อมูลสำเร็จ');

          getJorPor06DetailAsync();
        } else {
          toast.success('ส่งอนุมัติเห็นชอบแล้ว');

          getJorPor06DetailAsync();
        }

        return;
      }

      if (isRecall) {
        toast.success('เรียกคืนค่าแก้ไขสำเร็จ');

        getJorPor06DetailAsync();

        return;
      }

      toast.success('บันทึกข้อมูลสำเร็จ');

      getJorPor06DetailAsync();
    }
  };

  const uploadFile = async (value: FileValue) => {
    if (id) {
      const response = await jorPor06.uploadJorPor06DocumentAsync(id, value);

      if (response.status === HttpStatusCode.ACCEPTED) {
        getJorPor06DetailAsync();

        toast.success('อัปโหลดเอกสารสำเร็จ');
      }
    }

    if (jorpor06Detail.documentList && jorpor06Detail.documentList.length > 0) {
      const file: FileValue[] = [...jorpor06Detail.documentList];

      file.push(value);

      setJorpor06Detail({ ...jorpor06Detail, documentList: file });

      return;
    }

    const file: FileValue[] = [];

    file.push(value);

    setJorpor06Detail({ ...jorpor06Detail, documentList: file });
  };

  const downloadFileAsync = async (index: number, documentId?: string) => {
    if (jorpor06Detail.documentList && jorpor06Detail.documentList.length > 0) {
      if (documentId && id) {
        const res = await jorPor06
          .downloadJorPor06FileAsync(id, documentId);

        if (res.status === HttpStatusCode.OK) {
          const file = jorpor06Detail.documentList[index];

          downloadFile(res.data);
        }
      } else {
        const file = jorpor06Detail.documentList[index];

        if (file.file) {
          downloadFile(file.file);
        }
      }
    }
  };

  const removeFile = async (i: number, docId: string | undefined) => {
    if (jorpor06Detail.documentList && jorpor06Detail.documentList.length > 0) {
      if (docId && id) {
        const res = await jorPor06.removeJorPor06FileAsync(id, docId);

        if (res.status === HttpStatusCode.NO_CONTENT) {
          toast.success('ลบไฟล์สำเร็จ');

          getJorPor06DetailAsync();
        }
      }

      jorpor06Detail.documentList.splice(i, 1);

      setJorpor06Detail({
        ...jorpor06Detail,
        documentList: [...jorpor06Detail.documentList],
      });
    }
  };

  const clickNextAndBack = (step: number) => {
    if (jorpor06Detail) {
      if (jorpor06Detail.status !== AnnouncementStatus.Draft
        && jorpor06Detail.status !== AnnouncementStatus.Reject) {
        setCurrentPage(step);

        return;
      }
    }

    setCurrentPage(step);
    setStepStatus(step);
  };

  const recallAsync = async () => {
    if (!await showConfirmModalAsync('คุณต้องการเรียกคืนแก้ไขหรือไม่ ')) {
      return;
    }

    if (jorpor06Detail.acceptors.some(a => a.status === AnnouncementStatus.Approve)) {
      toast.warn('ไม่สามารถเรียกคืนแก้ไขได้เนี่องจากมีผู้เห็นชอบ/อนุมัติแล้ว');

      return;
    }

    updateJorPor06Async(AnnouncementStatus.DraftAcceptor, true);
  };

  const steps = [{
    title: <p>จัดทำรายงานผลการพิจารณาขออนุมัติสั่งซื้อ/สั่งจ้าง ( จพ.006 )
    </p>,
    displayIndex: '1',
  },
  {
    title: <p>ผู้มีอำนาจเห็นชอบ/อนุมัติ</p>,
    displayIndex: '2',
  },
    // {
    //   title: <p>ตัวอย่างเอกสาร</p>,
    //   displayIndex: '3',
    // }
  ];

  const progressBar = useCallback(() => (
    <StepProgress
      progressBarData={steps}
      ref={stepRef}
      step={stepStatus} />
  ), [currentPage, stepStatus]);

  const readonly = useMemo(() =>
    jorpor06Detail.status === AnnouncementStatus.WaitingAccept
    || jorpor06Detail.status === AnnouncementStatus.WaitingAcceptor
    || jorpor06Detail.status === AnnouncementStatus.Accepted
    || jorpor06Detail.status === AnnouncementStatus.Approve
    || jorpor06Detail.status === AnnouncementStatus.WaitingComitteeApprove
    || jorpor06Detail.status === AnnouncementStatus.DraftAcceptor
    || jorpor06Detail.status === AnnouncementStatus.WaitingForDirectorJorPorAssign,
    [jorpor06Detail]);

  const contextValue = {
    currentPage,
    jorpor06Detail,
    activityData,
    updateJorPor06Async,
    getJorPor06DetailAsync,
    getNewJorPor06DetailAsync,
    disabledField,
    setJorpor06Detail,
    uploadFile,
    downloadFileAsync,
    removeFile,
    recallAsync,
    recallShow,
    isCreate,
    isAcceptor,
    isQueue,
    acceptorId,
    readonly,
  };

  return (
    !onInit ?
      <Context.Provider value={contextValue}>
        <h4 className='mt-2 text-primary'>
          <Button variant='link'
            onClick={backToIndex}>
            <MdOutlineArrowBack className='fs-4 text-primary mb-1' />
          </Button>
          จัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง ( จพ.006 )
        </h4>
        <Breadcrumb data={BREADCRUMB_INFO} />
        {progressBar()}
        {currentPage === 1
          ? (
            <Step1
              key={1}
              onClickBack={() => backToIndex()}
              onClickNext={() => clickNextAndBack(2)}
            />
          ) : null}
        {currentPage === 2
          ? (
            <Step2
              key={2}
              onClickBack={() => clickNextAndBack(1)}
              onClickNext={() => clickNextAndBack(3)}
            />
          ) : null}
        {currentPage === 3
          ? (
            <Step3
              key={3}
              onClickBack={() => clickNextAndBack(2)}
            />
          ) : null}
      </Context.Provider>
      : <></>
  );
}
