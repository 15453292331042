import {
  ButtonCustom,
  StatusCM,
  Table,
} from 'components';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';

interface Props {
  className?: string;
  INumber: number;
  IData: boolean;
  onClick?: () => void;
}

export function TableExpandable({
  INumber,
  IData,
  onClick,
}: Props) {
  function KeyboardArrow(showDetails: Boolean) {
    return (
      <>
        ดูรายเอียด
        {
          (showDetails)
            ? <MdKeyboardArrowUp />
            : <MdKeyboardArrowDown />
        }
      </>
    );
  }

  function TabsData(IdData: boolean, INumber: number) {
    return (
      <>
        <tr className='text-center'
          key={INumber}>
          <td>{INumber + 1}</td>
          <td>30</td>
          <td>11/06/2566</td>
          <td>2,000,000 (50%)</td>
          <td>
            <div className='d-flex'>
              {INumber === 1 ? <StatusCM systemStatue='Draft'
                  systemName='cm-03' /> :
                <StatusCM systemStatue='Warning'
                  systemName='cm-03' />}
            </div>
          </td>
          <td className='text-start'>
            <ul className='inside'>
              <li>ค่าใช้จ่ายในการฝึกอบรมและ 1 งาน ตามเอกสารแนบ</li>
            </ul>
          </td>
          <td className='text-end'>
            <ButtonCustom variant='outline-success'
              text='บันทึกส่งมอบ'
              icon='save-deliver'
              iconAlignRight />
          </td>
          <td>
            <ButtonCustom
              variant='light border-0'
              onClick={onClick}
              iconAlignRight
              text={KeyboardArrow(IdData)}
            />
          </td>
        </tr>
        {
          IdData && (
            <tr className='text-center data-info-full'>
              <td colSpan={8}>
                <Table hidePagination>
                  <thead>
                  <tr className='text-center'>
                    <th style={{ width: '10%' }}
                      className='text-muted'>งวดที่
                    </th>
                    <th style={{ width: '10%' }}
                      className='text-muted'>วันที่ส่งมอบ
                    </th>
                    <th style={{ width: '70%' }}
                      className='text-muted'>รายละเอียดส่งมอบ
                    </th>
                    <th style={{ width: 100 }}
                      className='text-muted'>สถานะ
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {Array.from({ length: 3 })
                    .map((_, index) => (
                      <tr key={index}>
                        <td>{index}</td>
                        <td>11/06/2566</td>
                        <td className='text-start'>
                          <ul className='inside'>
                            <li> ค่าใช้จ่ายในการฝึกอบรมและ 1 งาน ตามเอกสารแนบ</li>
                          </ul>
                        </td>
                        <td>
                          <div className='d-flex'>
                            {index === 1 ? <StatusCM systemStatue='Draft'
                                systemName='cm-03' /> :
                              <StatusCM systemStatue='Warning'
                                systemName='cm-03' />}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </td>
            </tr>
          )
        }
      </>
    );
  }

  return (
    <>
      {
        TabsData(IData, INumber)
      }
    </>
  );
}
