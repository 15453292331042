import { useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  FaPlus,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  Currency,
  Table,
} from '../../../components';
import { ISectionWithApproverModel } from '../../../models/SU/ISectionWithApproverModel';
import SectionService from '../../../services/SU/SectionService';
import { HttpStatusCode } from '../../../utils';
import toast from '../../../utils/toast';

interface IProps {
  sectionData: ISectionWithApproverModel;
  sendSectionCode: (sectionCode: string) => void;
  onFinancialAmountChange: (financial: number, sectionId: string) => void;
  onRemove?: () => void;
}

function TableSectionAndDefaultApprovers(props: IProps) {
  const [financialAmount, setFinancialAmount] = useState<number>(props.sectionData.financialAmount);
  const removeDefaultApproverAsync = async (sectionCodeId: string, defaultApproverId: string) => {
    const { status } = await SectionService.deleteSectionAsync(sectionCodeId, defaultApproverId);

    if (status !== HttpStatusCode.OK && status !== HttpStatusCode.NO_CONTENT) {
      toast.error('ไม่สามารถลบข้อมูลได้');

      return;
    }

    props.onRemove && props.onRemove();
  };

  const onFinancialAmountChange = (financial: number | undefined, sectionId: string) => {
    if (!financial || financial < 0) {
      toast.warn('กรุณากรอกจำนวนเงินให้ถูกต้อง');

      return;
    }

    setFinancialAmount(financial);
    props.onFinancialAmountChange(financial, sectionId);
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <span className="fs-5 text-primary">
          {props.sectionData.sectionNameTh}
          <Currency
            value={financialAmount}
            onChange={(value) => onFinancialAmountChange(value, props.sectionData.sectionId)}
          />
        </span>
        <Button
          variant="outline-primary"
          onClick={() => props.sendSectionCode(props.sectionData.sectionId)}
        >
          <FaPlus />เพิ่มข้อมูล
        </Button>
      </div>
      <Table total={1} hidePagination>
        <thead>
          <tr>
            <th style={{ minWidth: 20 }}>ลำดับ</th>
            <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
            <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
            <th style={{ width: 20 }} />
          </tr>
        </thead>
        <tbody>
          {props.sectionData.defaultApprovers.map((d, index) => (
            <tr key={d.id}>
              <td className="text-center">{index + 1}</td>
              <td className="text-start">{d.fullName}</td>
              <td className="text-start">{d.department}</td>
              <td className="text-start">{d.position}</td>
              <td>
                <Button
                  onClick={() => removeDefaultApproverAsync(props.sectionData.sectionId, d.id)}
                  variant="danger"
                >
                  <FaTrashAlt />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default TableSectionAndDefaultApprovers;
