import {
  ApproveTor,
  CreateTor,
  GetTorListsParams,
} from 'models';
import {
  isoDate,
  PREPROCUREMENT_STATUS_TEXT,
} from 'utils';
import http from 'utils/axios';

const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const getTorListAsync = async (page: number, size: number, data?: GetTorListsParams) => {
  const params = {
    page,
    size,
    ...data,
  };

  return await http.get('termofref', { params });
};

const createTorsAsync = async (data: CreateTor, status: PREPROCUREMENT_STATUS_TEXT) => {
  const formData = new FormData();

  formData.append('Title', data.title);
  formData.append('TemplateId', '6de6b11b-2848-4305-aa5e-1c0126f7592a');
  formData.append('Reason', data.reason);
  formData.append('Description', data.description);
  formData.append('FinancialAmount', data.financialAmount.toString());
  formData.append('WarantyPeriod', data.warantyPeriod.toString());
  formData.append('WarantyPeriodType', 'day');
  formData.append('Status', status);
  formData.append('DepartmentId', data.departmentId);
  formData.append('PlanName', data.planName);
  formData.append('SupplyMethodType', data.supplyMethodType);
  formData.append('SupplyMethod', data.supplyMethod);
  formData.append('PlanBudget', data.planBudget?.toString() ?? '');
  formData.append('ExpectingProcurementAt', isoDate(data.expectingProcurementAt));

  if (data.objectives.length > 0) {
    data.objectives.forEach((data, index) => formData.append(`Objectives[${index}].Description`, data.description));
  }

  if (data.qualifications.length > 0) {
    data.qualifications.forEach((data, index) => formData.append(`Qualifications[${index}].Description`, data.description));
  }

  if (data.deliveryPeriods.length > 0) {
    data.deliveryPeriods.forEach((data, index) => {
      formData.append(`DeliveryPeriods[${index}].Installment`, data.installment.toString());
      formData.append(`DeliveryPeriods[${index}].Period`, data.period.toString());
      formData.append(`DeliveryPeriods[${index}].PeriodType`, data.periodType.toString());
      formData.append(`DeliveryPeriods[${index}].Description`, data.description.toString());
    });
  }

  if (data.financials.length > 0) {
    data.financials.forEach((data, index) => {
      formData.append(`Financials[${index}].FinancialType`, data.financialType);
      formData.append(`Financials[${index}].Amount`, data.amount.toString());
      formData.append(`Financials[${index}].Description`, data.description);
    });
  }

  if (data.paymentTerms.length > 0) {
    data.paymentTerms.forEach((data, index) => {
      formData.append(`PaymentTerms[${index}].Installment`, data.installment.toString());
      formData.append(`PaymentTerms[${index}].Percent`, data.percent.toString());
      formData.append(`PaymentTerms[${index}].Description`, data.description);
    });
  }

  if (data.fineRates.length > 0) {
    data.fineRates.forEach((data, index) => {
      formData.append(`FineRates[${index}].FineRateType`, data.fineRateType);
      formData.append(`FineRates[${index}].FineRatePeriodType`, data.fineRatePeriodType);
      formData.append(`FineRates[${index}].Fine`, data.fine.toString());
      formData.append(`FineRates[${index}].Description`, data.description);
    });
  }

  if (data.parcels.length > 0) {
    data.parcels.forEach((data, index) => {
      formData.append(`Parcels[${index}].Description`, data.description);
      formData.append(`Parcels[${index}].Quantity`, data.quantity.toString());
      formData.append(`Parcels[${index}].Unit`, data.unit);
      formData.append(`Parcels[${index}].Price`, data.price.toString());
      formData.append(`Parcels[${index}].Total`, data.total.toString());
    });
  }

  return await http.post('/termofref', formData, config);
};

const getTorDetailAsync = async (id: string) => await http.get(`/termofref/${id}`);

const updateTorAsync = async (data: CreateTor, status: PREPROCUREMENT_STATUS_TEXT, id: string) => {
  const formData = new FormData();

  formData.append('Title', data.title);
  formData.append('TemplateId', '6de6b11b-2848-4305-aa5e-1c0126f7592a');
  formData.append('Reason', data.reason);
  formData.append('Description', data.description);
  formData.append('FinancialAmount', data.financialAmount.toString());
  formData.append('WarantyPeriod', data.warantyPeriod.toString());
  formData.append('WarantyPeriodType', 'day');
  formData.append('Status', status);
  formData.append('DepartmentId', data.departmentId);
  formData.append('PlanName', data.planName);
  formData.append('SupplyMethodType', data.supplyMethodType);
  formData.append('SupplyMethod', data.supplyMethod);
  formData.append('PlanBudget', data.planBudget?.toString() ?? '');
  formData.append('ExpectingProcurementAt', isoDate(data.expectingProcurementAt));

  if (data.objectives.length > 0) {
    data.objectives.forEach((data, index) => formData.append(`Objectives[${index}].Description`, data.description));
  }

  if (data.qualifications.length > 0) {
    data.qualifications.forEach((data, index) => formData.append(`Qualifications[${index}].Description`, data.description));
  }

  if (data.deliveryPeriods.length > 0) {
    data.deliveryPeriods.forEach((data, index) => {
      formData.append(`DeliveryPeriods[${index}].Installment`, data.installment.toString());
      formData.append(`DeliveryPeriods[${index}].Period`, data.period.toString());
      formData.append(`DeliveryPeriods[${index}].PeriodType`, data.periodType.toString());
      formData.append(`DeliveryPeriods[${index}].Description`, data.description.toString());
    });
  }

  if (data.financials.length > 0) {
    data.financials.forEach((data, index) => {
      formData.append(`Financials[${index}].FinancialType`, data.financialType);
      formData.append(`Financials[${index}].Amount`, data.amount.toString());
      formData.append(`Financials[${index}].Description`, data.description);
    });
  }

  if (data.paymentTerms.length > 0) {
    data.paymentTerms.forEach((data, index) => {
      formData.append(`PaymentTerms[${index}].Installment`, data.installment.toString());
      formData.append(`PaymentTerms[${index}].Percent`, data.percent.toString());
      formData.append(`PaymentTerms[${index}].Description`, data.description);
    });
  }

  if (data.fineRates.length > 0) {
    data.fineRates.forEach((data, index) => {
      formData.append(`FineRates[${index}].FineRateType`, data.fineRateType);
      formData.append(`FineRates[${index}].FineRatePeriodType`, data.fineRatePeriodType);
      formData.append(`FineRates[${index}].Fine`, data.fine.toString());
      formData.append(`FineRates[${index}].Description`, data.description);
    });
  }

  if (data.parcels.length > 0) {
    data.parcels.forEach((data, index) => {
      formData.append(`Parcels[${index}].Description`, data.description);
      formData.append(`Parcels[${index}].Quantity`, data.quantity.toString());
      formData.append(`Parcels[${index}].Unit`, data.unit);
      formData.append(`Parcels[${index}].Price`, data.price.toString());
      formData.append(`Parcels[${index}].Total`, data.total.toString());
    });
  }

  return await http.put(`/termofref/${id}`, formData, config);
};

const sendApproveAsync = async (torId: string, medianPrice: string, body: ApproveTor[]) => {
  const data = {
    approvers: body,
  };

  return await http.put(`/termofref/${torId}/medianprice/${medianPrice}`, data);
};

export default {
  createTorsAsync,
  getTorDetailAsync,
  updateTorAsync,
  sendApproveAsync,
  getTorListAsync,
};
