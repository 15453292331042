import { Modal as ModalBT } from 'react-bootstrap';

interface Props {
  show: boolean;
  children?: JSX.Element | JSX.Element[];
  onHide?: () => void;
  size?: 'sm' | 'lg' | 'xl';
  className?: string;
}

function Modal(props: Props) {
  document.addEventListener('onHideModal', () => onHide);

  const onHide = () => {
    if (props.onHide) {
      props.onHide();
    }
  };

  return (
    <ModalBT
      show={props.show}
      centered
      onHide={onHide}
      size={props.size || undefined}
      backdrop="static"
      className={props.className}
    >
      <ModalBT.Body>
        {props.children}
      </ModalBT.Body>
    </ModalBT>
  );
}

function Header(props: {
  children: JSX.Element | JSX.Element[] | string,
}) {
  const onHide = () => document.dispatchEvent(
    new CustomEvent('onHideModal'),
  );

  return (
    <ModalBT.Header
      onHide={onHide}
      closeButton
      className="pt-0"
    >
      {props.children}
    </ModalBT.Header>
  );
}

Modal.Header = Header;

export { Modal };