// import { Modal } from "./Modal";
import {
  DatePicker,
  Input,
  Modal,
  Selector,
  Table,
} from 'components';
import { ItemModel } from 'models';
import { deliveryParcelItemModel } from 'models/CM/ManageContractModels';
import { useState } from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaPlus,
  FaTrash,
} from 'react-icons/fa';

interface Props {
  show: boolean;
  onHide?: () => void;
  onSubmit: (deliveryDate: Date, items: deliveryParcelItemModel[]) => void;
}

const listParcels: ItemModel[] = [
  { value: 'แผนการดำเนินโครงการ (Project Planning)', label: 'แผนการดำเนินโครงการ (Project Planning)' },
  { value: 'แผนการดำเนินโครงการ (Project Planning) ที่ 2', label: 'แผนการดำเนินโครงการ (Project Planning) ที่ 2' },
  { value: 'รายงานรายละเอียดการวิเคราะห์และออกแบบระบบ (System Analysis and Design)', label: 'รายงานรายละเอียดการวิเคราะห์และออกแบบระบบ (System Analysis and Design)' },
  { value: 'รายงานสถาปัตยกรรมระบบของ โครงการ (System Architecture Design)', label: 'รายงานสถาปัตยกรรมระบบของ โครงการ (System Architecture Design)' },
  { value: 'รายงานการออกแบบ (Conceptual design)', label: 'รายงานการออกแบบ (Conceptual design)' },
  { value: 'ระบบบันทึกเสียงออนไลน์', label: 'ระบบบันทึกเสียงออนไลน์' },
];

export function DeliveryModal(props: Props) {
  const [deliveryParcelList, setDeliveryParcelList] = useState<deliveryParcelItemModel[]>([]);
  const [deliveryDate, setDeliveryDate] = useState<Date>();

  const onAddItem = () => {
    const newData: deliveryParcelItemModel = {
      id: '',
      sequence: deliveryParcelList.length + 1,
      description: 'แผนการดำเนินโครงการ (Project Planning)',
      quantity: 1,
      quantityBalance: 1,
      deliveryQty: 1,
      remark: '',
    }

    setDeliveryParcelList((deliveryParcelList) => [...deliveryParcelList, newData]);
  };

  const handlerOnChangeddl = (value: string, index: number) => {
    const data = [...deliveryParcelList];
    data[index].description = value;
    setDeliveryParcelList(data);
  };

  const handlerOnChangeQty = (value: number, index: number) => {
    const data = [...deliveryParcelList];
    data[index].deliveryQty = value;
    setDeliveryParcelList(data);
  };

  const handlerOnChangeRemark = (value: string, index: number) => {
    const data = [...deliveryParcelList];
    data[index].remark = value;
    setDeliveryParcelList(data);
  };

  const removeItem = (i: number) => {
    const temp: deliveryParcelItemModel[] = [...deliveryParcelList];
    temp.splice(i, 1);
    setDeliveryParcelList(temp);
  };

  const onSubmit = () => {
    props.onSubmit(deliveryDate!, deliveryParcelList);
    props.onHide!();
  };

  return (
    <Modal
      show={props.show}
      size="xl"
      onHide={() => (props.onHide ? props.onHide() : null)}
    >
      <ModalBT.Header closeButton>
        <ModalBT.Title className='text-primary'>รายการส่งมอบ</ModalBT.Title>
      </ModalBT.Header>
      <ModalBT.Body className="p-0 ps-4 pt-2 mb-4">
        <Row>
          <Col sm={12} md={4} xl={4}>
            <DatePicker label='วันที่ส่งมอบ' onChange={(val) => setDeliveryDate(val)} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex mb-4 align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span>รายการพัสดุที่จะส่งมอบ</span>
              </div>
              <Button
                variant="primary"
                onClick={onAddItem}
              >
                <FaPlus /> เพิ่มรายการพัสดุที่จะส่งมอบ
              </Button>
            </div>
            <Table total={1} hidePagination className='mt-3'>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>ลำดับที่</th>
                  <th style={{ width: 200 }}>รายละเอียด</th>
                  <th style={{ width: 100 }}>จำนวนสินค้า</th>
                  <th style={{ width: 100 }}>คงเหลือ</th>
                  <th style={{ width: 100 }}>จำนวนที่ส่งมอบ</th>
                  <th style={{ width: 200 }}>หมายเหตุ</th>
                  <th style={{ width: 50 }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  deliveryParcelList && (
                    deliveryParcelList.map((data, index) => (
                      <tr key={index}>
                        <td className='text-center'>{data.sequence}</td>
                        <td className='text-start'><Selector items={listParcels} value={data.description} onChange={(val) => handlerOnChangeddl(val, index)} /></td>
                        <td><Input value={data.quantity} disabled textEnd /></td>
                        <td><Input value={data.quantityBalance} disabled textEnd /></td>
                        <td><Input type='number' value={data.deliveryQty} textEnd onChange={(val) => handlerOnChangeQty(Number(val), index)} /></td>
                        <td><Input value={data.remark} onChange={(val) => handlerOnChangeRemark(val, index)} /></td>
                        <td className='text-center'>
                          <Button
                            variant="danger"
                            onClick={() => removeItem(index)}
                          >
                            <FaTrash />
                          </Button>
                        </td>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </ModalBT.Body>
      <ModalBT.Footer>
        <Button variant="outline-primary"
          onClick={props.onHide}>
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          onClick={onSubmit}
        >
          ยืนยัน
        </Button>
      </ModalBT.Footer>
    </Modal>
  );
}
