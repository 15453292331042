export enum PREPROCUREMENT_STATUS_TEXT {
  DRAFT = 'Draft',
  SUBMIT = 'Submit',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  ANNOUNCEMENT = 'Announcement',
  REJECTED = 'Rejected',
}

export enum PREPROCUREMENT_STATUS_TEXT_TH {
  DRAFT = 'ร่าง',
  SUBMIT = 'ส่ง',
  PENDING = 'รอดำเนินการ',
  APPROVED = 'อนุมัติ',
  ANNOUNCEMENT = 'เผยแพร่',
  REJECTED = 'แก้ไข',
}

export enum AcceptorStatusEnum {
  Pending = 'Pending',
  Approve = 'Approve',
  Reject = 'Reject',
}

export enum PREPROCUREMENT_STATUS {
  DRAFT,
  SUBMIT,
  PENDING,
  APPROVED,
  ANNOUNCEMENT,
  REJECTED,
}