import Cookie from 'cookie-universal';
import http from 'utils/axios';

const qs = require('qs');

const cookies = Cookie();
const getFullName = cookies.get('fullName') as string;

const api = '/collectAttachment';

const geAttachmenttByPlanIdAsynce = (planNumber: string) => http.get(`${api}/${planNumber}`);

// const geAttachmenttByPlanIdAsynce = (planId: string, torId?: string) => {
//     if (id) {
//         return http.get(`${api}/plan/${planId}/tor/${torId}`);
//     }

//     return http.get(`${api}/plan/${planId}/tor`);
// };

export default {
    geAttachmenttByPlanIdAsynce,
};