import {
  Check,
  Currency,
  DatePicker,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  ChangePlanRequestResponse,
  DepartmentResponse,
  GetChangeRequestParamsModel,
  Pl0203ChangePlanRequestCountStatus,
} from 'models';
import {
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { changePlanRequest } from 'services';
import department from 'services/department';
import {
  GetMonthYearBC,
  HttpStatusCode,
  isoDate,
  thaiFormatDateWithSlash,
  THCurrency,
} from 'utils';

interface CriteriaProps {
  sendData: (data: GetChangeRequestParamsModel) => void;
}

interface DataTableProps {
  criteriaData: GetChangeRequestParamsModel;
}

interface ItemModel {
  label: string;
  value: string;
}

export default function PL0203() {
  const [criteriaData, setCriteriaData] = useState<GetChangeRequestParamsModel>({
    allStatus: true,
    draftPlanStatus: true,
    waitingApprovePlanStatus: true,
    approvePlanStatus: true,
    rejectPlanStatus: true,
    assignedStatus: true,
    draftRecordDocumentStatus: true,
    waitingAcceptorStatus: true,
    approveAcceptorStatus: true,
    rejectAcceptorStatus: true,
    announcementStatus: true,
    rejectAnnouncementStatus: true,
  } as GetChangeRequestParamsModel);

  return (
    <div className='pl0203'>
      <div className='text-primary'>
        <h4>ขอเปลี่ยนแปลงแผนการจัดซื้อจัดจ้าง</h4>
        <hr />
      </div>
      <div className='mt-3'>
        <Criteria sendData={(data) => setCriteriaData(data)} />
      </div>
      <div>
        <DataTable criteriaData={criteriaData} />
      </div>
    </div>
  );
}

function Criteria(props: CriteriaProps) {
  const [criteria, setCriteria] = useState<GetChangeRequestParamsModel>({
    allStatus: true,
    draftPlanStatus: true,
    waitingApprovePlanStatus: true,
    approvePlanStatus: true,
    rejectPlanStatus: true,
    assignedStatus: true,
    draftRecordDocumentStatus: true,
    waitingAcceptorStatus: true,
    approveAcceptorStatus: true,
    rejectAcceptorStatus: true,
    announcementStatus: true,
    rejectAnnouncementStatus: true,
  } as GetChangeRequestParamsModel);
  const [departmentItems, setDepartmentItems] = useState<ItemModel[]>([]);
  const [allStatus, setAllStatus] = useState(false);
  const [changePlanRequestData, setChangePlanRequestData] = useState<Pl0203ChangePlanRequestCountStatus>({} as Pl0203ChangePlanRequestCountStatus);

  const setCheckAllByAnotherType = () => {
    const check = criteria.draftPlanStatus
      && criteria.waitingApprovePlanStatus
      && criteria.approvePlanStatus
      && criteria.rejectPlanStatus
      && criteria.assignedStatus
      && criteria.draftRecordDocumentStatus
      && criteria.waitingAcceptorStatus
      && criteria.approveAcceptorStatus
      && criteria.rejectAcceptorStatus
      && criteria.announcementStatus
      && criteria.rejectAnnouncementStatus;

    if (check) {
      setAllStatus(true);
      setCriteria({ ...criteria, allStatus: true });
    }

    if (!check) {
      setAllStatus(false);
      setCriteria({ ...criteria, allStatus: false });
    }
  };

  useEffect(() => {
    setCheckAllByAnotherType();
  }, [
    criteria.draftPlanStatus,
    criteria.waitingApprovePlanStatus,
    criteria.approvePlanStatus,
    criteria.rejectPlanStatus,
    criteria.assignedStatus,
    criteria.draftRecordDocumentStatus,
    criteria.waitingAcceptorStatus,
    criteria.approveAcceptorStatus,
    criteria.rejectAcceptorStatus,
    criteria.announcementStatus,
    criteria.rejectAnnouncementStatus,
  ]);

  useEffect(() => {
    setInitailCheck();
    getChangePlanRequestCountStatusAsync();
    getDepartmentItemsAsync();
  }, []);

  const setInitailCheck = useCallback(async () => {
    setCriteria({} as GetChangeRequestParamsModel);
    setCriteria({
      ...criteria,
      allStatus: true,
      draftPlanStatus: true,
      waitingApprovePlanStatus: true,
      approvePlanStatus: true,
      rejectPlanStatus: true,
      assignedStatus: true,
      draftRecordDocumentStatus: true,
      waitingAcceptorStatus: true,
      approveAcceptorStatus: true,
      rejectAcceptorStatus: true,
      announcementStatus: true,
      rejectAnnouncementStatus: true,
    });
  }, []);

  const getDepartmentItemsAsync = async () => {
    const response = await department.getDepartmentsAsync();
    const departmentData: DepartmentResponse[] = response.data;
    const items: ItemModel[] = [];

    departmentData.forEach((data) => items.push({ label: data.name, value: data.id ? data.id : '' }));

    setDepartmentItems(items);
  };

  const getChangePlanRequestCountStatusAsync = useCallback(async () => {
    const response = await changePlanRequest.getCountStatusAsync();

    if (response.status === HttpStatusCode.OK) {
      setChangePlanRequestData(response.data);
    }
  }, []);

  const onSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();

    sendCriteriaData();
  };

  const sendCriteriaData = () => {
    props.sendData(criteria);
  };

  const onCheckAllStatusCriteria = (value: boolean) => {
    if (value) {
      setAllStatus(value);
      setCriteria({
        ...criteria,
        draftPlanStatus: true,
        waitingApprovePlanStatus: true,
        approvePlanStatus: true,
        rejectPlanStatus: true,
        assignedStatus: true,
        draftRecordDocumentStatus: true,
        waitingAcceptorStatus: true,
        approveAcceptorStatus: true,
        rejectAcceptorStatus: true,
        announcementStatus: true,
        rejectAnnouncementStatus: true,
      });
    } else {
      setAllStatus(false);
      setCriteria({
        ...criteria,
        draftPlanStatus: false,
        waitingApprovePlanStatus: false,
        approvePlanStatus: false,
        rejectPlanStatus: false,
        assignedStatus: false,
        draftRecordDocumentStatus: false,
        waitingAcceptorStatus: false,
        approveAcceptorStatus: false,
        rejectAcceptorStatus: false,
        announcementStatus: false,
        rejectAnnouncementStatus: false,
      });
    }
  };

  const clearCriteria = () => {
    setInitailCheck();
    const data: GetChangeRequestParamsModel = {
      allStatus: true,
      draftPlanStatus: true,
      waitingApprovePlanStatus: true,
      approvePlanStatus: true,
      rejectPlanStatus: true,
      assignedStatus: true,
      draftRecordDocumentStatus: true,
      waitingAcceptorStatus: true,
      approveAcceptorStatus: true,
      rejectAcceptorStatus: true,
      announcementStatus: true,
      rejectAnnouncementStatus: true,
      expectingProcurementAt: undefined,
      planAnnouncementDate: undefined,
    } as GetChangeRequestParamsModel;
    props.sendData(data);
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className='criteria'>
        <Row>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='เลขที่แผน'
              value={criteria.planNumber}
              onChange={(value) => setCriteria({ ...criteria, planNumber: value })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Selector
              value={criteria.departmentId}
              items={departmentItems}
              label='ฝ่าย/สำนัก'
              onChange={(value) => setCriteria({ ...criteria, departmentId: value === '' ? undefined : value })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='ชื่อแผน'
              value={criteria.planName}
              onChange={(value) => setCriteria({ ...criteria, planName: value })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Currency
              label='งบประมาณโครงการ'
              value={criteria.planBudget}
              onChange={(value) => setCriteria({ ...criteria, planBudget: value || undefined })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <DatePicker
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              monthYearOnly
              value={criteria.expectingProcurementAt ? new Date(criteria.expectingProcurementAt) : undefined}
              onChange={(value) => setCriteria({ ...criteria, expectingProcurementAt: isoDate(value.toString()) })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <DatePicker
              label='วันที่ประกาศแผน'
              value={criteria.planAnnouncementDate ? new Date(criteria.planAnnouncementDate) : undefined}
              onChange={(value) => setCriteria({ ...criteria, planAnnouncementDate: isoDate(value.toString()) })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='ผู้จัดทำ'
              value={criteria.createdFullName}
              onChange={(value) => setCriteria({ ...criteria, createdFullName: value })}
            />
          </Col>
        </Row>
      </div>
      <div className='status'>
        <label>สถานะ</label>
        <div className='mt-3 d-flex flex-wrap gap-2'>
          <div className='d-flex'>
            <Check
              label='ทั้งหมด'
              value={allStatus}
              onChange={(val) => onCheckAllStatusCriteria(val)}
            />
            <Badge
              className='h-50 mx-2'
              bg='danger'
            >
              {changePlanRequestData.countAll}
            </Badge>
          </div>
          <div className='d-flex'>
            <Check
              label='จัดทำแผน'
              value={criteria.draftPlanStatus}
              onChange={(value) => {
                setCriteria({ ...criteria, draftPlanStatus: value });
              }}
            />
            <Badge
              className='h-50 mx-2'
              bg='secondary'
            >
              {changePlanRequestData.countDraftPlan}
            </Badge>
          </div>
          <div className='d-flex'>
            <Check
              label='รออนุมัติแผน'
              value={criteria.waitingApprovePlanStatus}
              onChange={(value) => {
                setCriteria({ ...criteria, waitingApprovePlanStatus: value });
              }}
            />
            <Badge
              className='h-50 mx-2'
              bg='primary'
            >
              {changePlanRequestData.countWaitingApprovePlan}
            </Badge>
          </div>
          <div className='d-flex'>
            <Check
              label='อนุมัติแผน'
              value={criteria.approvePlanStatus}
              onChange={(value) => {
                setCriteria({ ...criteria, approvePlanStatus: value });
              }}
            />
            <Badge
              className='h-50 mx-2'
              bg='success'
            >
              {changePlanRequestData.countApprovePlan}
            </Badge>
          </div>
          <div className='d-flex'>
            <Check
              label='ส่งกลับแก้ไข'
              value={criteria.rejectPlanStatus}
              onChange={(value) => {
                setCriteria({ ...criteria, rejectPlanStatus: value });
              }}
            />
            <Badge
              className='h-50 mx-2'
              bg='danger'
            >
              {changePlanRequestData.countRejectPlan}
            </Badge>
          </div>
          <div className='d-flex'>
            <Check
              label='มอบหมายงาน'
              value={criteria.assignedStatus}
              onChange={(value) => {
                setCriteria({ ...criteria, assignedStatus: value });
              }}
            />
            <Badge
              className='h-50 mx-2'
              bg='secondary'
            >
              {changePlanRequestData.countAssigned}
            </Badge>
          </div>
          <div className='d-flex'>
            <Check
              label='จัดทำเอกสารบันทึก'
              value={criteria.draftRecordDocumentStatus}
              onChange={(value) => {
                setCriteria({ ...criteria, draftRecordDocumentStatus: value });
              }}
            />
            <Badge
              className='h-50 mx-2'
              bg='primary'
            >
              {changePlanRequestData.countDraftRecordDocument}
            </Badge>
          </div>
          <div className='d-flex'>
            <Check
              label='รอให้ความเห็นชอบ'
              value={criteria.waitingAcceptorStatus}
              onChange={(value) => {
                setCriteria({ ...criteria, waitingAcceptorStatus: value });
              }}
            />
            <Badge
              className='h-50 mx-2'
              bg='success'
            >
              {changePlanRequestData.countWaitingAcceptor}
            </Badge>
          </div>
          <div className='d-flex'>
            <Check
              label='เห็นชอบและอนุมัติสำเร็จ'
              value={criteria.approveAcceptorStatus}
              onChange={(value) => {
                setCriteria({ ...criteria, approveAcceptorStatus: value });
              }}
            />
            <Badge
              className='h-50 mx-2'
              bg='danger'
            >
              {changePlanRequestData.countApproveAcceptor}
            </Badge>
          </div>
          <div className='d-flex'>
            <Check
              label='ไม่เห็นชอบ'
              value={criteria.rejectAcceptorStatus}
              onChange={(value) => {
                setCriteria({ ...criteria, rejectAcceptorStatus: value });
              }}
            />
            <Badge
              className='h-50 mx-2'
              bg='secondary'
            >
              {changePlanRequestData.countRejectAcceptor}
            </Badge>
          </div>
          <div className='d-flex'>
            <Check
              label='เผยแพร่ประกาศแผน'
              value={criteria.announcementStatus}
              onChange={(value) => {
                setCriteria({ ...criteria, announcementStatus: value });
              }}
            />
            <Badge
              className='h-50 mx-2'
              bg='primary'
            >
              {changePlanRequestData.countAnnouncement}
            </Badge>
          </div>
          <div className='d-flex'>
            <Check
              label='ส่งกลับแก้ไข-จากการเผยแพร่'
              value={criteria.rejectAnnouncementStatus}
              onChange={(value) => {
                setCriteria({ ...criteria, rejectAnnouncementStatus: value });
              }}
            />
            <Badge
              className='h-50 mx-2'
              bg='success'
            >
              {changePlanRequestData.countRejectAnnouncement}
            </Badge>
          </div>
        </div>
      </div>
      <div className='d-flex gap-2 button-inline mt-2'>
        <Button variant='primary'
          type='submit'
          className='d-flex align-items-center gap-2'>
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant='outline-primary'
          className='d-flex align-items-center gap-2'
          onClick={clearCriteria}
        >
          <FaEraser />ล้าง
        </Button>
      </div>
    </Form>
  );
}

function DataTable(props: DataTableProps) {
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [changePlanRequestData, setChangePlanRequestData] = useState<ChangePlanRequestResponse[]>([]);
  const [isMounted, setIsMounted] = useState(false);
  const navigate = useNavigate();

  const getChangePlanRequestListAsync = useCallback(async () => {
    const response = await changePlanRequest.getChangePlanRequestListPaginationAsync(page, size, props.criteriaData);

    if (response.status === HttpStatusCode.OK) {
      setTotalRecords(response.data.totalRecords);
      setChangePlanRequestData(response.data.data);
      setIsMounted(true);
    }
  }, [page, size, props.criteriaData]);

  useEffect(() => {
    getChangePlanRequestListAsync();
  }, [getChangePlanRequestListAsync]);

  return (
    isMounted ? (
      <Table className='mt-4'
        total={totalRecords}
        onChange={(size, page) => (setSize(size), setPage(page))}>
        <thead>
        <tr>
          <th style={{ minWidth: 100 }}>เลขที่แผน</th>
          <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
          <th style={{ minWidth: 300, width: 500 }}>ชื่อแผน</th>
          <th style={{ minWidth: 100 }}>งบประมาณ (บาท)</th>
          <th style={{ minWidth: 100 }}>วิธีจัดหา</th>
          <th style={{ minWidth: 100 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
          <th style={{ minWidth: 100 }}>วันที่ประกาศแผน</th>
          <th style={{ minWidth: 100 }}>ผู้จัดทำ</th>
          <th style={{ minWidth: 100 }}>วันที่จัดทำ</th>
          <th style={{ minWidth: 100 }}>สถานะ</th>
        </tr>
        </thead>
        <tbody>
        {changePlanRequestData.map((data, index) => (
          <tr key={data.id}>
            <td
              className='text-center'
              onClick={() => navigate(`detail/${data.id}`)}
            ><Button variant='link'>{data.planNumber}</Button>
            </td>
            <td>{data.department}</td>
            <td className='text-wrap'>{data.planName}</td>
            <td className='text-end'>{THCurrency(data.planBudget)}</td>
            <td className='text-center'>{data.supplyMethod}</td>
            <td className='text-center'>{GetMonthYearBC(data.expectingProcurementAt)}</td>
            <td className='text-center'>{thaiFormatDateWithSlash(data.planAnnouncementDate)}</td>
            <td className='text-start'>{data.createdFullName}</td>
            <td className='text-center'>{thaiFormatDateWithSlash(data.createdAt)}</td>
            <td className='text-center'><Status type={StatusType.PROCESS}
              value={data.status} /></td>
          </tr>
        ))}
        </tbody>
      </Table>
    ) : <></>
  );
}
