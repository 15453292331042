import DefaultApprover from '../../../services/SU/DefaultApprover';
import SectionService from '../../../services/SU/SectionService';
import { HttpStatusCode } from '../../../utils';
import toast from '../../../utils/toast';

const createDefaultApproverAsync = async (financialAmount: number, userId: string, sectionCodeId: string, onCreateSuccess: () => void) => {
  if (!sectionCodeId) {
    toast.error('กรุณาเพิ่มข้อมูล section');

    return;
  }

  const {
    data: approverId,
    status: createApproverStatus,
  } = await DefaultApprover.createDefaultApproverAsync(financialAmount, userId);

  if (createApproverStatus !== HttpStatusCode.OK && createApproverStatus !== HttpStatusCode.CREATED) {
    toast.error('ไม่สามารถเพิ่มข้อมูลได้');

    return;
  }

  const { status: AddApproverToSectionStatus } = await SectionService.createSectionAsync(sectionCodeId, approverId);

  if (AddApproverToSectionStatus !== HttpStatusCode.OK && AddApproverToSectionStatus !== HttpStatusCode.CREATED) {
    toast.error('ไม่สามารถเพิ่มข้อมูลได้');

    return;
  }

  onCreateSuccess();
  toast.success('เพิ่มข้อมูลสำเร็จ');
};

export {
  createDefaultApproverAsync,
};
