import Cookie from 'cookie-universal';
import { CriteriaPlanProcurementListModel } from 'models';
import {
  ContractRecordAssigneeRequestModel,
  ContractRecordCommitteeModel,
  ContractRecordEntrepreneursRequestModel,
  CountStatusModel,
  CountSupplyMethodModel,
  EntrepreneursPoRequestModel,
  FinancialModel,
  PurchaseOrderApprovalRequestModel,
  SearchPurchaseOrderApprovalModel,
  UpdateAcceptorsModel,
  UpdateFinancialModel,
} from 'models/PR/PurchaseOrderApprovalModels';
import department from 'services/department';
import http from 'utils/axios';

const cookies = Cookie();
const qs = require('qs');

const getFullName = cookies.get('fullName') as string;

const getPurchaseOrderApprovalListAsync = async (searchData: SearchPurchaseOrderApprovalModel) => {
  const params = {
    page: searchData.page,
    size: searchData.size,
    entrepreneursTaxId: searchData.entrepreneursTaxId,
    planNumber: searchData.planNumber,
    departmentId: searchData.departmentId,
    jorPor05Number: searchData.jorPor05Number,
    supplyMethod: searchData.supplyMethod,
    planName: searchData.planName,
    recordStartDate: searchData.recordStartDate,
    recordEndDate: searchData.recordEndDate,
    status: searchData.status,
    isAssigneeOnly: searchData.isAssigneeOnly,
    contractType: searchData.contractType,
  };

  return http.get('/contractRecord', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountStatusAsync = (searchData: SearchPurchaseOrderApprovalModel) => {
  const api = '/contractRecord';

  const params = {
    page: searchData.page,
    size: searchData.size,
    entrepreneursTaxId: searchData.entrepreneursTaxId,
    planNumber: searchData.planNumber,
    jorPor05Number: searchData.jorPor05Number,
    supplyMethod: searchData.supplyMethod,
    planName: searchData.planName,
    departmentId: searchData.departmentId,
    recordStartDate: searchData.recordStartDate,
    recordEndDate: searchData.recordEndDate,
    status: searchData.status,
    isAssigneeOnly: searchData.isAssigneeOnly,
    contractType: searchData.contractType,
  };
  return http.get<CountStatusModel[]>(`${api}/countStatus`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountSupplyMethodAsync = (searchData: SearchPurchaseOrderApprovalModel) => {
  const api = '/contractRecord';

  const params = {
    page: searchData.page,
    size: searchData.size,
    entrepreneursTaxId: searchData.entrepreneursTaxId,
    planNumber: searchData.planNumber,
    jorPor05Number: searchData.jorPor05Number,
    supplyMethod: searchData.supplyMethod,
    planName: searchData.planName,
    departmentId: searchData.departmentId,
    recordStartDate: searchData.recordStartDate,
    recordEndDate: searchData.recordEndDate,
    status: searchData.status,
    isAssigneeOnly: searchData.isAssigneeOnly,
    contractType: searchData.contractType,
  };
  return http.get<CountSupplyMethodModel[]>(`${api}/countSupplyMethod`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getPurchaseOrderApprovalByIdAsync = async (jorPor06Id?: string, basicInformationId?: string, id?: string) => {
  const params = {
    jorPor06Id,
    basicInformationId,
    id,
  };

  return http.get('/contractRecord/single', { params });
};

const getContractRecordByIdAsync = async (id?: string) => {
  return http.get(`/contractRecord/id/${id}`);
};

const getListHistoryAsync = (page: number, size: number, id: string | undefined) => {
  const params = {
    page,
    size,
    id,
  };

  return http.get('/contractRecord/history', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getContractRecordDocumentAsync = (id?: string, documentId?: string) => http.get(`/contractRecord/attachment/${documentId}`, {
  responseType: 'blob',
});

const uploadPurchaseOrderApprovalAttachmentAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('Files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`/contractRecord/attachment/${id}`, formData, config);
};

const createPurchaseOrderApprovalAsync = (body: PurchaseOrderApprovalRequestModel) => {
  return http.post('/contractRecord', body);
};
// const createPurchaseOrderApprovalAsync = (body: PurchaseOrderApprovalRequestModel) => {
//   const formData = new FormData();
//   formData.append('jorPor06Id', body.jorPor06Id ?? '');
//   formData.append('financialAmount', body.financialAmount.toString());
//   formData.append('departmentId', body.departmentId.toString());
//   formData.append('supplyMethod', body.supplyMethod);
//   formData.append('supplyMethodType', body.supplyMethodType);
//   formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
//   formData.append('planNumber', body.planNumber);
//   formData.append('planName', body.planName);
//   formData.append('budgetYear', body.budgetYear.toString());
//   formData.append('contractType', body.contractType.toString());
//   formData.append('createdByUserFullName', getFullName);
//   formData.append('planNumber', body.planNumber.toString());

//   if (body.files && body.files.length !== 0) {
//     body.files.forEach((file) => {
//       formData.append('AttachmentFiles', file);
//     });
//   }
//   if (body.financials && body.financials.length !== 0) {
//     body.financials?.forEach((financial, index) => {
//       const inx = index + 1;

//       formData.append(`Financials[${index}][Sequence]`, (inx).toString());
//       formData.append(`Financials[${index}][FinancialId]`, financial.financialId);
//       formData.append(`Financials[${index}][Detail]`, financial.detail);
//       formData.append(`Financials[${index}][Amount]`, financial.amount.toString());

//       body.financials[index].entrepreneursList?.forEach((entrepreneurs, i) => {
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].Sequence`, entrepreneurs.sequence.toString());
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].InviteEntrepreneursId`, entrepreneurs.inviteEntrepreneursId);
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].EntrepreneursTaxId`, entrepreneurs.entrepreneursTaxId ? entrepreneurs.entrepreneursTaxId : '');
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].EntrepreneursName`, entrepreneurs.entrepreneursName);
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].OfferTotalAmount`, entrepreneurs.offerTotalAmount ? entrepreneurs.offerTotalAmount.toString() : '');
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].PoNumber`, entrepreneurs.poNumber ? entrepreneurs.poNumber : '');
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].ReceiveCommitteeId`, entrepreneurs.receiveCommitteeId ? entrepreneurs.receiveCommitteeId : '');
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].IsMA`, entrepreneurs.isMA ? 'true' : 'false');
//       });
//     });
//   }

//   const config = {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   };

//   return http.post('/contractRecord', formData, config);
// };

const updatePurchaseOrderApprovalAsync = (body: PurchaseOrderApprovalRequestModel) => {
  return http.put(`/contractRecord/${body.id}`, body);
};

const updateAssigneeAsync = (id: string, body: ContractRecordAssigneeRequestModel) => {
  return http.put(`/contractRecord/${id}/assignee`, body);
};

// const updatePurchaseOrderApprovalAsync = (body: PurchaseOrderApprovalRequestModel) => {
//   const formData = new FormData();
//   formData.append('Id', body.id!);
//   formData.append('jorPor06Id', body.jorPor06Id);
//   formData.append('financialAmount', body.financialAmount.toString());
//   formData.append('departmentId', body.departmentId.toString());
//   formData.append('supplyMethod', body.supplyMethod);
//   formData.append('supplyMethodType', body.supplyMethodType);
//   formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
//   formData.append('planNumber', body.planNumber);
//   formData.append('planName', body.planName);
//   formData.append('budgetYear', body.budgetYear.toString());
//   formData.append('contractType', body.contractType.toString());

//   if (body.files && body.files.length !== 0) {
//     body.files.forEach((file) => {
//       formData.append('AttachmentFiles', file);
//     });
//   }

//   if (body.financials && body.financials.length !== 0) {
//     body.financials?.forEach((financial, index) => {
//       const inx = index + 1;

//       formData.append(`Financials[${index}][Id]`, financial.id!);
//       formData.append(`Financials[${index}][Sequence]`, (inx).toString());
//       formData.append(`Financials[${index}][FinancialId]`, financial.financialId);
//       formData.append(`Financials[${index}][Detail]`, financial.detail);
//       formData.append(`Financials[${index}][Amount]`, financial.amount.toString());

//       body.financials[index].entrepreneursList?.forEach((entrepreneurs, i) => {
//         if (entrepreneurs.id) {
//           formData.append(`Financials[${index}].EntrepreneursList[${i}].Id`, entrepreneurs.id!);
//         }

//         formData.append(`Financials[${index}].EntrepreneursList[${i}].Sequence`, entrepreneurs.sequence.toString());
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].ContractRecordFinancialId`, financial.id!);
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].InviteEntrepreneursId`, entrepreneurs.inviteEntrepreneursId);
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].EntrepreneursTaxId`, entrepreneurs.entrepreneursTaxId ? entrepreneurs.entrepreneursTaxId : '');
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].EntrepreneursName`, entrepreneurs.entrepreneursName);
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].OfferTotalAmount`, entrepreneurs.offerTotalAmount ? entrepreneurs.offerTotalAmount.toString() : '');
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].PoNumber`, entrepreneurs.poNumber ? entrepreneurs.poNumber : '');
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].ReceiveCommitteeId`, entrepreneurs.receiveCommitteeId ? entrepreneurs.receiveCommitteeId : '');
//         formData.append(`Financials[${index}].EntrepreneursList[${i}].IsMA`, entrepreneurs.isMA ? 'true' : 'false');
//       });
//     });
//   }

//   const config = {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   };

//   return http.put(`/contractRecord/${body.id}`, formData, config);
// };

const updateEntrepreneursAssigneesAsync = (body: ContractRecordEntrepreneursRequestModel) => {
  const formData = new FormData();
  formData.append('contractRecordFinancialId', body.contractRecordFinancialId);
  // formData.append('id', body.id);
  // formData.append('inviteEntrepreneursId', body.inviteEntrepreneursId);
  // formData.append('sequence', body.sequence.toString());
  // formData.append('entrepreneursTaxId', body.entrepreneursTaxId);
  // formData.append('entrepreneursName', body.entrepreneursName);
  // formData.append('offerTotalAmount', body.offerTotalAmount.toString());
  // formData.append('poNumber', body.poNumber);
  // formData.append('contractNumber',  body.contractNumber);
  formData.append('assignToAssignees', body.assignToAssignees ? 'true' : 'false');

  if (body.assignees && body.assignees.length !== 0) {
    body.assignees?.forEach((data, index) => {
      if (data.id) {
        formData.append(`Assignees[${index}][Id]`, data.id!);
      }
      formData.append(`Assignees[${index}][UserId]`, data.userId);
      formData.append(`Assignees[${index}][FullName]`, data.fullName);
      formData.append(`Assignees[${index}][DepartmentName]`, data.departmentName ? data.departmentName : '');
      formData.append(`Assignees[${index}][PositionName]`, data.positionName ? data.positionName : '');
      formData.append(`Assignees[${index}][Sequence]`, data.sequence.toString());
      formData.append(`Assignees[${index}][Level]`, data.level.toString());
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`/contractRecord/entrepreneurs/${body.id}/Assignee`, formData, config);
};

const updateEntrepreneursPoAsync = (body: EntrepreneursPoRequestModel) => {
  const formData = new FormData();
  formData.append('contractRecordFinancialId', body.contractRecordFinancialId);
  if (body.id) {
    formData.append('id', body.id ? body.id : '');
  }
  formData.append('sequence', body.sequence.toString());
  formData.append('inviteEntrepreneursId', body.inviteEntrepreneursId ?? '');
  formData.append('entrepreneursTaxId', body.entrepreneursTaxId);
  formData.append('entrepreneursName', body.entrepreneursName);
  formData.append('offerTotalAmount', body.offerTotalAmount.toString());
  formData.append('poNumber', body.poNumber);
  formData.append('contractNumber', body.contractNumber);
  formData.append('contractType', body.contractType);
  formData.append('ReceiveCommitteeId', body.receiveCommitteeId ?? '');
  formData.append(`IsMA`, body.isMA ? 'true' : 'false');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put('/contractRecord/entrepreneurs/poNumber', formData, config);
};

const getEntrepreneursByIdAsync = (id?: string, jorPor05Id?: string) => {
  const params = {
    jorPor05Id
  };

  return http.get(`/contractRecord/entrepreneurs/${id}`, {
    params,
  });
};

const updateAcceptorsAsync = (body: UpdateAcceptorsModel) => {
  const formData = new FormData();
  formData.append('contractRecordFinancialId', body.contractRecordFinancialId);
  formData.append('id', body.id ? body.id : '');
  formData.append('sequence', body.sequence.toString());
  formData.append('offerTotalAmount', body.offerTotalAmount.toString());
  formData.append('poNumber', body.poNumber);
  formData.append('sendToApprove', body.sendToApprove ? 'true' : 'false');

  if (body.acceptors && body.acceptors.length > 0) {
    let inx = 0;
    let sectionSeq = 0;

    body.acceptors.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((data, index) => {
      if (sectionSeq !== data.sectionSequence) {
        inx = 1;
        sectionSeq = data.sectionSequence;
      }

      if (data.id) {
        formData.append(`Acceptors[${index}][Id]`, data.id!);
      }
      formData.append(`Acceptors[${index}][SectionType]`, data.sectionType ? data.sectionType : '');
      formData.append(`Acceptors[${index}][SectionCode]`, data.sectionCode ? data.sectionCode : '');
      // formData.append(`Acceptors[${index}][SectionName]`, data.sectionName);
      formData.append(`Acceptors[${index}][SectionSequence]`, data.sectionSequence.toString());
      formData.append(`Acceptors[${index}][Sequence]`, (inx).toString());
      formData.append(`Acceptors[${index}][UserId]`, data.userId);
      formData.append(`Acceptors[${index}][PositionName]`, data.positionName ? data.positionName : '');
      // formData.append(`Acceptors[${index}][IsAvailable]`, data.isAvailable ? 'true' : 'false');
      // formData.append(`Acceptors[${index}][AvailableRemark]`, data.availableRemark ? data.availableRemark : '');

      inx++;
    });
  } const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`/contractRecord/entrepreneurs/${body.id}/Acceptor`, formData, config);
};

const removeFileAsync = async (contractRecordId: string, attachmentId: string) =>
  await http.delete(`/contractRecord/${contractRecordId}/attachment/${attachmentId}`);

const acceptorSignContractAsync = async (id?: string, remark?: string) => {
  const formData = new FormData();
  formData.append('remark', remark ?? '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return await http.put(`/ContractRecord/Sign/${id}`, formData, config);
};

const acceptorRejectSignContractAsync = async (id?: string, remark?: string) => {
  const formData = new FormData();
  formData.append('remark', remark ?? '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return await http.put(`/ContractRecord/RejectSign/${id}`, formData, config);
};


const assigneeRejectAsync = async (id?: string, remark?: string) => {
  const formData = new FormData();
  formData.append('remark', remark ?? '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return await http.put(`/ContractRecord/${id}/assignee-reject`, formData, config);
};

// const getReceivceCommitteeAsync = async (id: string) => {
//   const params = {
//     id: id,
//     group: 'supplymethod'
//   };

//   return await http.get(`/ContractRecord//receivceCommittee`, { params });
// };

const getReceivceCommitteeAsync = async (torId: string) => http.get(`/contractRecord/${torId}/receivceCommittee`);

const createFinancialAsync = (body: FinancialModel) => {
  return http.post(`/ContractRecord/create-financial`, body);
};

const updateFinancialAsync = (body: UpdateFinancialModel) => {
  return http.put(`/ContractRecord/update-financial/${body.financialId}`, body);
};

const deleteFinancialAsync = async (contractRecordId: string, financiaId: string) =>
  await http.delete(`/contractRecord/${contractRecordId}/financia/${financiaId}`);

const updateEntrepreneursCommitteeAsync = (body: ContractRecordCommitteeModel) => {
  return http.put(`/contractRecord/entrepreneurs/${body.id}/committee`, body);
};

const getPlanProcurementListAsync = async (page: number, pageSize: number, type?: string, departmentCode?: string, data?: CriteriaPlanProcurementListModel) => {
  const params = {
    page,
    pageSize,
    type,
    departmentCode,
    ...data
  };
  return await http.get('/contractRecord/planProcurement/pagination/', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const deleteContractRecordAsync = async (id: string) =>
  await http.delete(`/contractRecord/${id}`);

const onReCallAsync = (id: string) => http.put(`/contractRecord/${id}/recall`);

export default {
  getPurchaseOrderApprovalListAsync,
  getCountStatusAsync,
  getCountSupplyMethodAsync,
  getPurchaseOrderApprovalByIdAsync,
  getListHistoryAsync,
  uploadPurchaseOrderApprovalAttachmentAsync,
  getContractRecordDocumentAsync,
  createPurchaseOrderApprovalAsync,
  updatePurchaseOrderApprovalAsync,
  updateEntrepreneursAssigneesAsync,
  updateEntrepreneursPoAsync,
  getEntrepreneursByIdAsync,
  updateAcceptorsAsync,
  removeFileAsync,
  acceptorSignContractAsync,
  acceptorRejectSignContractAsync,
  getReceivceCommitteeAsync,
  createFinancialAsync,
  updateFinancialAsync,
  getContractRecordByIdAsync,
  deleteFinancialAsync,
  updateEntrepreneursCommitteeAsync,
  getPlanProcurementListAsync,
  deleteContractRecordAsync,
  updateAssigneeAsync,
  onReCallAsync,
  assigneeRejectAsync,
};
