import {
  Card,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import { HistoryModal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaHistory,
  FaSave,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { dataHistory } from '../data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex justify-content-between my-3'>
          <Title text='ตัวอย่างเอกสาร'
            className='fs-5 text-primary' />
          <Button
            className='px-3'
            onClick={() => setShow(true)}
            variant='outline-primary'
          >
            <FaHistory className='me-3' />ประวัติการแก้ไข
          </Button>
        </div>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between gap-3 pt-3'>
        <Button
          onClick={onClickBack}
          className='me-2 px-3'
          variant='outline-primary'
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          <Button
            variant='outline-primary'
            className='me-2 px-3'
          >
            <FaSave className='ms-2 pb-1 fs-5' />
            บันทึก
          </Button>
        </div>
        <div />
      </div>
      <HistoryModal
        show={show}
        onHide={() => setShow(!show)}
        data={dataHistory}
      />
    </div>
  );
}

export default Step2;
