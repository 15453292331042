import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Currency,
  Input,
  Selector,
  SupplyMethodEnum,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { FineModel } from '../../../../../../models/CM/CM02Models';
import { ItemModel } from 'models';
import { InputNumber } from 'components/Controls/InputNumber';
import { ValidatePattern } from 'utils';
import { FineTypeOtherIdEnum } from 'utils/constants/ShareValueEnum';
import { defaultFineRateValues } from 'pages/PreProcurement/TORDraft/data';

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

interface IProps {
  title?: string;
  fine: FineModel;
  offerTotalAmountVat: number;
  contractTemplateCode?: string;
  onFineChange: (value: FineModel) => void;
  fineTypeDDL: ItemModel[];
  periodTypeDDL: ItemModel[];
  isDisabled?: boolean;
  supplyMethodId: string;
  supplyMethodTypeId: string;
}

const fields = [
  {
    code: 'CF01',
    type: "ประเภทค่าปรับ",
    fineAmount: "จำนวนเงินค่าปรับ",

    finePercent: "ค่าปรับอัตราร้อยละ", // +
  },
  {
    code: 'CF02',
    type: "ประเภทค่าปรับ",
    finePercent: "ค่าปรับอัตราร้อยละ",

    fineAmount: "จำนวนเงินค่าปรับ", // +
  },
  {
    code: 'CF03',
    type: "ประเภทค่าปรับ",
    finePercent: "ค่าปรับอัตราร้อยละ",

    fineAmount: "จำนวนเงินค่าปรับ", // +
  },
  {
    code: 'CF04',
    type: "ประเภทค่าปรับ",
    finePercent: "ค่าปรับอัตราร้อยละ",

    fineAmount: "จำนวนเงินค่าปรับ", // +
  },
  {
    code: 'CF05',
    type: "ประเภทค่าปรับ",
    finePercent: "ค่าปรับอัตราร้อยละ",

    fineAmount: "จำนวนเงินค่าปรับ", // +
  },
  {
    code: 'CF06',
    type: "ประเภทค่าปรับ",
    fineAmount: "อัตราค่าปรับวันละ",
    perFineAmount: "ต่อเครื่อง",

    finePercent: "ค่าปรับอัตราร้อยละ", // +
  },
  {
    code: 'CF07',
    type: "ประเภทค่าปรับ",
    finePercent: "ผู้รับจ้างยินยอมให้ผู้ว่าจ้างปรับในอัตราร้อยละ",
    fineAmount: "จำนวนเงิน",
  },
  {
    code: 'CF08',
    type: "ประเภทค่าปรับ",
    fineAmount: "อัตราค่าปรับคันละ",

    finePercent: "ค่าปรับอัตราร้อยละ", // +
  },
  {
    code: 'CF09',
    type: "ประเภทค่าปรับ",
    fineAmount: "ผู้รับจ้างยอมให้ผู้ว่าจ้างปรับในอัตรา",

    finePercent: "ค่าปรับอัตราร้อยละ", // +
  },
  {
    code: 'CF10',
    // อยู่ในข้อตกลง
  },
  {
    code: 'CF11',
    type: "ประเภทค่าปรับ",
    fineAmount: "อัตราค่าปรับเครื่องละ",

    finePercent: "ค่าปรับอัตราร้อยละ", // +
  },
  {
    code: 'CF12',
    type: "ประเภทค่าปรับ",
    finePercent: "อัตราค่าปรับของราคาสิ่งของ และหรือเงินร้อยละ",

    fineAmount: "จำนวนเงินค่าปรับ", // +
  },
  {
    code: 'CF13',
    type: "ประเภทค่าปรับ",
    fineAmount: "จำนวนเงินค่าปรับ (บาท)",

    finePercent: "ค่าปรับอัตราร้อยละ", // +
  },
  {
    code: 'CF14',
    type: "ประเภทค่าปรับ",
    finePercent: "ค่าปรับอัตราร้อยละ",

    fineAmount: "จำนวนเงินค่าปรับ", // +
  },
  {
    code: 'CF15',
    type: "ประเภทค่าปรับ",
    finePercent: "ค่าปรับอัตราร้อยละ",

    fineAmount: "จำนวนเงินค่าปรับ", // +
  },
];

export function FineDetail(props: IProps) {
  const [fine, setFine] = useState<FineModel>(props.fine);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad) {

      if (props.supplyMethodId === SupplyMethodEnum.MethodId60
        && props.offerTotalAmountVat > 100000
        && !props.fine) {
        getDefaultFineRates();
      }
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    if (fine) {
      props.onFineChange(fine);
    }
  }, [fine]);

  const onChangeFinePercent = useCallback((val: number) => {
    setFine(prevState => ({
      ...prevState,
      finePercent: val,
      fineAmount: (props.offerTotalAmountVat * (val / 100)),
    }));
  }, []);

  const onChangeFineAmount = useCallback((val: number) => {
    setFine(prevState => ({
      ...prevState,
      fineAmount: val,
      finePercent: ((val / props.offerTotalAmountVat) * 100),
    }));
  }, []);

  const getDefaultFineRates = () => {
    defaultFineRateValues.filter(d => d.supplyMethodType === props.supplyMethodTypeId).forEach((data) => {
      setFine(prevState => ({
        ...prevState,
        finePercent: data.fine,
        fineAmount: (props.offerTotalAmountVat * (data.fine / 100)),
      }));
    });
  };

  return (
    <Card className='mt-3'>
      <Title text={`สัญญา${props.title ? props.title : 'ค่าปรับ'}`} className='fs-5 text-primary' />
      <>
        {
          fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
            <>
              {
                data.type &&
                (
                  <>
                    <Row className='mt-3'>
                      <Col sm={5}
                        md={5}
                        className='d-flex'>
                        <div style={{ width: `${fine?.fineType !== FineTypeOtherIdEnum.อื่นๆระบุ ? '100%' : '30%'}` }}>
                          <Selector
                            label={data.type}
                            items={props.fineTypeDDL}
                            value={fine?.fineType}
                            onChange={(val) => setFine({ ...fine, fineType: val })}
                            disabled={props.isDisabled}
                          />
                        </div>
                        {
                          fine?.fineType === FineTypeOtherIdEnum.อื่นๆระบุ &&
                          (
                            <div style={{ width: '70%', marginTop: '1px' }}>
                              <Input type='text'
                                className='no-label ps-1'
                                value={fine.fineTypeOther}
                                onChange={(val) => setFine({ ...fine, fineTypeOther: val })}
                                rule={{ required: true }}
                                disabled={props.isDisabled} />
                            </div>
                          )
                        }
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                data.finePercent &&
                (
                  <Row className='mt-3'>
                    <Col sm={3} md={3}>
                      <Currency
                        label={data.finePercent}
                        value={fine?.finePercent}
                        onChange={(val) => onChangeFinePercent(Number(val))}
                        rule={{ pattern: ValidatePattern.PERCENTAGE }}
                        disabled={props.isDisabled}
                      />
                    </Col>
                    <Col sm={3} md={3}>
                      <InputNumber
                        label={data.fineAmount}
                        value={fine?.fineAmount}
                        onChange={(val) => onChangeFineAmount(Number(val))}
                        disabled={props.isDisabled}
                      />
                    </Col>
                    <Col sm={3} md={3}>
                      <Selector
                        label='ต่อ'
                        items={props.periodTypeDDL}
                        value={fine?.fineAmountUnit}
                        onChange={(val) => setFine({ ...fine, fineAmountUnit: val })}
                        disabled={props.isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
              {/* {
                data.fineAmountIgnoreUnit &&
                (
                  <Row className='mt-3'>
                    <Col sm={5} md={5}>
                      <InputNumber
                        label={data.fineAmountIgnoreUnit}
                        value={fine?.fineAmount}
                        onChange={(val) => onChangeFineAmount(Number(val))}
                        disabled={props.isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                data.fineAmount &&
                (
                  <Row className='mt-3'>
                    <Col sm={5} md={5}>
                      <InputNumber
                        label={data.fineAmount}
                        value={fine?.fineAmount}
                        onChange={(val) => onChangeFineAmount(Number(val))}
                        disabled={props.isDisabled}
                      />
                    </Col>
                    <Col sm={4} md={4}>
                      <Selector
                        label='ต่อ'
                        items={props.periodTypeDDL}
                        value={fine?.fineAmountUnit}
                        onChange={(val) => setFine({ ...fine, fineAmountUnit: val })}
                        disabled={props.isDisabled}
                      />
                    </Col>
                  </Row>
                )
              } */}
            </>
          )
        }
      </>
    </Card>
  );
}
