import { ItemModel, ProvincesModel } from 'models';
import { LoaderFunctionArgs } from 'react-router-dom';
import {
  shareValue,
  trader,
} from 'services';
import provincesServices from 'services/provincesServices';

export const su04Loader = async () => {
  const [traderListRes, traderTypes, provinces] = await Promise.all([
    await trader.getTraderListAsync(10, 1),
    await shareValue.GetTraderTypeAsync(),
    await provincesServices.getProvincesListAsync(),
  ]);

  const itemForMapProvinces: ItemModel[] = [];
  const items: ProvincesModel[] = provinces.data;
  items.forEach((data) =>
    itemForMapProvinces.push({
      label: data.nameTh,
      value: data.code ?? ''
    })
  );

  return {
    traderList: traderListRes.data,
    traderTypes: traderTypes.data,
    provincesDDL: itemForMapProvinces,
  };
};

export const su04DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  const traderTypes = await shareValue.GetTraderTypeAsync();
  const provinces = await provincesServices.getProvincesListAsync();

  const itemProvinces: ItemModel[] = [];
  const items: ProvincesModel[] = provinces.data;
  items.forEach((data) =>
    itemProvinces.push({
      label: data.nameTh,
      value: data.code ?? ''
    })
  );


  if (id) {
    const traderRes = await trader.getTraderAsync(id);

    return {
      trader: traderRes.data,
      traderTypes: traderTypes.data,
      provincesDDL: itemProvinces,
    };
  }

  return {
    traderTypes: traderTypes.data,
    provincesDDL: itemProvinces,
  };
};
