import Title from 'components/Controls/Title';
import { HistoryModal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Card,
} from 'react-bootstrap';
import {
  FaHistory,
  FaRegCheckCircle,
  FaSave,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { dataHistory } from './data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <Card.Body>
          <div className="d-flex justify-content-between my-3">
            <Title text="ตัวอย่างเอกหนังสือเชิญชวน" className="fs-5 text-primary" />
            <Button
              className="px-3"
              onClick={() => setShow(true)}
              variant="outline-primary"
            >
              <FaHistory className="me-3" />ประวัติการแก้ไข
            </Button>
          </div>
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-between gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="outline-primary"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <div>
          <Button
            variant="outline-primary"
            className="me-2 px-3"
          >
            <FaSave className="ms-2 pb-1 fs-5" />
            บันทึก
          </Button>
          <Button
            className="me-2 px-3"
          >
            <FaRegCheckCircle className='me-2 pb-1 fs-5' />
            ส่งอนุมัติเห็นชอบ
          </Button>
        </div>
        <div />
      </div>
      <HistoryModal
        show={show}
        onHide={() => setShow(!show)}
        data={dataHistory}
      />
    </div>
  );
}

export default Step2;
