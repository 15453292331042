import { Rule } from 'models';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap';
import {
  FaRegEye,
  FaRegEyeSlash,
  FaSearch,
} from 'react-icons/fa';
import {
  MdBackspace,
  MdSearch,
} from 'react-icons/md';
import { ValidatePattern } from 'utils';
import regex from 'utils/regex';

interface Props {
  label?: string;
  remark?: string;
  type?: string;
  placeholder?: string;
  rule?: Rule;
  value?: string | number | undefined;
  onChange?: (value: string) => void;
  name?: string;
  className?: string;
  disabled?: boolean;
  textEnd?: boolean;
  textCenter?: boolean;
  textSearch?: boolean;
  textSearchStart?: boolean;
  clearSearchText?: boolean;
  autoFocus?: boolean;
  variant?: string;
  mount?: boolean;
  maxlength?: number;
  onClick?: () => void;
  postText?: string;
}

export function Input(props: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [value, setValue] = useState<string | number | undefined>(props.value);
  const [eyeIsOpened, setEyeIsOpened] = useState(false);
  const [mounted, setMounted] = useState(false);

  document.addEventListener('onFormSubmit', (data) => {
    const event = data as CustomEvent;

    if (event.detail.hasNameOnly) {
      if (props.name) {
        validate(value);
      }
    } else {
      validate(value);
    }
  });

  document.addEventListener('onClearError', () => {
    setErrorMessage('');
  });

  useEffect(() => {
    setValue(props.value);
    mounted ? validate(props.value) : setMounted(true);
  }, [props.value]);

  const getRequired = () => {
    if (props.rule?.required) {
      return <span className='text-danger'>*</span>;
    }

    return null;
  };

  const getRemark = () => {
    if (props.remark) {
      return <span className='bg-light rounded bg-opacity-25 pb-1' style={{ fontSize: '13px' }}>{props.remark}</span>;
    }

    return null;
  };

  const validate = (validateValue: string | number | undefined) => {
    if (!checkRequired(validateValue)) {
      return;
    }

    if (!checkMinLength(validateValue)) {
      return;
    }

    if (!checkMaxLength(validateValue)) {
      return;
    }

    if (!checkMinValue(validateValue)) {
      return;
    }

    if (!checkMaxValue(validateValue)) {
      return;
    }

    if (!checkPattern(validateValue)) {
      return;
    }

    setErrorMessage('');
  };

  const checkRequired = (requiredValue: string | number | undefined) => {
    if (props.rule?.required && requiredValue !== 0 && !requiredValue) {
      setErrorMessage('กรุณากรอกข้อมูล');

      return false;
    }

    return true;
  };

  const checkMinLength = (minValue: string | number | undefined) => {
    if (props.rule?.minLength && minValue && minValue.toString().length < props.rule.minLength) {
      setErrorMessage(`กรุณระบุอย่างน้อย ${props.rule.minLength} ตัวอักษร`);

      return false;
    }

    return true;
  };

  const checkMaxLength = (maxValue: string | number | undefined) => {
    if (props.rule?.maxLength && maxValue && maxValue.toString().length > props.rule.maxLength) {
      setErrorMessage(`กรุณระบุไม่เกิน ${props.rule.maxLength} ตัวอักษร`);

      return false;
    }

    return true;
  };

  const checkMinValue = (minValue: string | number | undefined) => {
    if (props.rule?.minValue && Number(minValue) < props.rule.minValue) {
      setErrorMessage(`กรุณระบุอย่างน้อย ${props.rule.minValue}`);

      return false;
    }

    return true;
  };

  const checkMaxValue = (maxValue: string | number | undefined) => {
    if (props.rule?.maxValue && Number(maxValue) > props.rule.maxValue) {
      setErrorMessage(`กรุณระบุไม่เกิน ${props.rule.maxValue}`);

      return false;
    }

    return true;
  };

  const checkPattern = (patternValue: string | number | undefined) => {
    if (props.rule?.pattern && patternValue) {
      if (props.rule.pattern === ValidatePattern.EMAIL
        && !regex.validateEmail(patternValue.toString())) {
        setErrorMessage('รูปแบบอีเมลไม่ถูกต้อง');
        return false;
      }
      if (props.rule.pattern === ValidatePattern.PHONENUMBER
        && !regex.validatePhone(patternValue.toString())) {
        setErrorMessage('รูปแบบหมายเลขโทรศัพท์สำหรับติดต่อไม่ถูกต้อง');
        return false;
      }
      if (props.rule.pattern === ValidatePattern.TAXNUMBER
        && !regex.validateTaxNumber(patternValue.toString())) {
        setErrorMessage('รูปแบบเลขประจำตัวผู้เสียภาษีไม่ถูกต้อง');
        return false;
      }
      if (props.rule.pattern === ValidatePattern.NUMBER
        && !regex.validateNumber(patternValue.toString())) {
        setErrorMessage('รูปแบบเลขไม่ถูกต้อง');
        return false;
      }
      if (props.rule.pattern === ValidatePattern.PERCENTAGE
        && !regex.validateNumberPercentage(patternValue.toString())) {
        setErrorMessage('รูปแบบเลขไม่ถูกต้อง');
        return false;
      }
    }

    return true;
  };

  const getErrorMessage = useMemo(() => {
    if (errorMessage) {
      return (
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      );
    }

    return null;
  }, [errorMessage]);

  const handlerOnChange = (event: HTMLInputElement) => {
    if (props.onChange) {
      props.onChange(event.value);
      validate(props.value);
    }

    setValue(event.value);

    validate(event.value);
  };

  const type = useMemo(() => {
    if (props.type === 'password') {
      if (eyeIsOpened) {
        return 'text';
      }

      return 'password';
    }

    return props.type;
  }, [props.type, eyeIsOpened]);

  const handlerOnClick = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [props.onClick]);

  useEffect(() => {
    const invalid: NodeListOf<HTMLElement> = document.querySelectorAll('input.is-invalid');

    if (invalid.length > 0) {
      invalid[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [getErrorMessage]);

  return (
    <Form.Group className={`w-100 ${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
      {props.label ? <Form.Label>{props.label} {getRequired()} {getRemark()}</Form.Label> : null}
      <InputGroup style={{ zIndex: '0' }}
        hasValidation
        className={`${props.textSearchStart && 'text-search'}`}>
        {props.textSearchStart
          && (
            <Button variant={`${props.variant ? props.variant : 'light'}`}
              onClick={() => handlerOnClick()}>
              <MdSearch className='fs-4' />
            </Button>
          )}
        <Form.Control
          autoFocus={props.autoFocus}
          className={`
          ${getErrorMessage ? 'is-invalid' : ''}
          ${props.textEnd ? 'text-end' : ''}
          ${props.textCenter ? 'text-center' : ''}`}
          value={value ?? ''}
          type={type}
          placeholder={props.placeholder}
          onChange={(event) => handlerOnChange(event.target as HTMLInputElement)}
          disabled={props.disabled}
          autoComplete='off'
          maxLength={props.maxlength}
        />
        {props.type === 'password' ? <EyeButton onChange={(changeValue) => setEyeIsOpened(changeValue)} /> : null}
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
        {props.clearSearchText
          && (
            <Button variant={`${props.variant ? props.variant : 'primary'}`}
              onClick={() => handlerOnClick()}>
              <MdBackspace />
            </Button>
          )}
        {props.textSearch
          && (
            <Button variant={`${props.variant ? props.variant : 'outline-primary'}`}
              onClick={() => handlerOnClick()}>
              <FaSearch />
            </Button>
          )}
        {
          props.postText && (
            <InputGroup.Text>{props.postText}</InputGroup.Text>
          )
        }
      </InputGroup>
    </Form.Group>
  );
}

function EyeButton(props: { onChange: (value: boolean) => void }) {
  const [eyeIsOpened, setEyeIsOpened] = useState(false);

  const icon = useMemo(() => (eyeIsOpened ? <FaRegEye /> : <FaRegEyeSlash />), [eyeIsOpened]);

  const handlerOnClick = useCallback(() => {
    setEyeIsOpened(!eyeIsOpened);

    props.onChange(!eyeIsOpened);
  }, [eyeIsOpened]);

  return (
    <InputGroup.Text onClick={handlerOnClick}
      className='cursor-pointer'>
      {icon}
    </InputGroup.Text>
  );
}
