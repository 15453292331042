import {
  Input,
  InputTextArea,
  Selector,
  Table,
  UploadFile,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  CreateTor,
  DeliveryPeriods,
  Financials,
  FineRates,
  Objectives,
  Parcels,
  PaymentTerms,
  Qualifications,
} from 'models';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row as RowBT,
} from 'react-bootstrap';
import {
  BsFillTrashFill,
  BsPlus,
} from 'react-icons/bs';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { generateUniqueId } from '../../../../utils/helper';
import { CreateTorContext } from './index';

interface Props {
  onClickNext: (data: CreateTor) => void;
  onClickBack: () => void;
}

export default function TORDetailStep2(props: Props) {
  const [createTorData, setCreateTorData] = useState<CreateTor>({} as CreateTor);
  const createTorFirstStepData = useContext(CreateTorContext);

  const dateTypeItems = [
    { label: 'วัน', value: 'day' },
    { label: 'เดือน', value: 'month' },
    { label: 'ปี', value: 'year' },
  ];

  useEffect(() => {
    if (createTorFirstStepData) {
      setCreateTorData(createTorFirstStepData);
    }
  }, [createTorFirstStepData]);

  const addObjectiveRow = () => {
    const row = [...createTorData.objectives];
    row.push({} as Objectives);

    setCreateTorData({ ...createTorData, objectives: row });
  };

  const removeObjectiveRow = (index: number) => {
    const row = [...createTorData.objectives];
    row.splice(index, 1);

    setCreateTorData({ ...createTorData, objectives: row });
  };

  const objectiveOnChange = (value: string | number, index: number, prop: string) => {
    createTorData.objectives[index] = {
      ...createTorData.objectives[index], [prop]: value,
    };
  };

  const addParcelsRow = () => {
    const row = [...createTorData.parcels];
    row.push({} as Parcels);

    setCreateTorData({ ...createTorData, parcels: row });
  };

  const removeParcelsRow = (index: number) => {
    const row = [...createTorData.parcels];
    row.splice(index, 1);

    setCreateTorData({ ...createTorData, parcels: row });
  };

  const parcelsOnChange = (value: string | number, index: number, prop: string) => {
    createTorData.parcels[index] = {
      ...createTorData.parcels[index], [prop]: value,
    };
  };

  const addQualificationsRow = () => {
    const row = [...createTorData.qualifications];
    row.push({} as Qualifications);

    setCreateTorData({ ...createTorData, qualifications: row });
  };

  const removeQualificationsRow = (index: number) => {
    const row = [...createTorData.qualifications];
    row.splice(index, 1);

    setCreateTorData({ ...createTorData, qualifications: row });
  };

  const qualificationsOnChange = (value: string | number, index: number, prop: string) => {
    createTorData.qualifications[index] = {
      ...createTorData.qualifications[index], [prop]: value,
    };
  };

  const addDeliveryPeriodsRow = () => {
    const row = [...createTorData.deliveryPeriods];
    row.push({} as DeliveryPeriods);

    setCreateTorData({ ...createTorData, deliveryPeriods: row });
  };

  const removeDeliveryPeriodsRow = (index: number) => {
    const row = [...createTorData.deliveryPeriods];
    row.splice(index, 1);

    setCreateTorData({ ...createTorData, deliveryPeriods: row });
  };

  const deliveryPeriodsOnChange = (value: string | number, index: number, prop: string) => {
    createTorData.deliveryPeriods[index] = {
      ...createTorData.deliveryPeriods[index], [prop]: value,
    };
  };

  const financialsAddRow = () => {
    const row = [...createTorData.financials];
    row.push({} as Financials);

    setCreateTorData({ ...createTorData, financials: row });
  };

  const financialsRemoveRow = (index: number) => {
    const row = [...createTorData.financials];
    row.splice(index, 1);

    setCreateTorData({ ...createTorData, financials: row });
  };

  const financialOnChange = (value: string | number, index: number, prop: string) => {
    createTorData.financials[index] = {
      ...createTorData.financials[index], [prop]: value,
    };
  };

  const addPaymentTermsRow = () => {
    const row = [...createTorData.paymentTerms];
    row.push({} as PaymentTerms);

    setCreateTorData({ ...createTorData, paymentTerms: row });
  };

  const removePaymentTermsRow = (index: number) => {
    const row = [...createTorData.paymentTerms];
    row.splice(index, 1);

    setCreateTorData({ ...createTorData, paymentTerms: row });
  };

  const paymentTermsOnChange = (value: string | number, index: number, prop: string) => {
    createTorData.paymentTerms[index] = {
      ...createTorData.paymentTerms[index], [prop]: value,
    };
  };

  const addFineRatesRow = () => {
    const row = [...createTorData.fineRates];
    row.push({} as FineRates);

    setCreateTorData({ ...createTorData, fineRates: row });
  };

  const removeFineRatesRow = (index: number) => {
    const row = [...createTorData.fineRates];
    row.splice(index, 1);

    setCreateTorData({ ...createTorData, fineRates: row });
  };

  const fineRatesOnChange = (value: string | number, index: number, prop: string) => {
    createTorData.fineRates[index] = {
      ...createTorData.fineRates[index], [prop]: value,
    };
  };

  return (
    <>
      {/* ข้อมูลร่างขอบเขตของงาน (TOR) */}
      <Card className='mt-3'>
        <Card.Body>
          <Title text='ข้อมูลร่างขอบเขตของงาน (TOR)'
            className='fs-5 text-primary' />
          <RowBT className='mt-4'>
            <Col sm={12}
              md={12}
              lg={12}
              xl={12}>
              <InputTextArea
                value={createTorData.reason}
                label='ความเป็นมา/เหตุผลและความจำเป็น'
                rule={{ required: true }}
                onChange={(value) => setCreateTorData({ ...createTorData, reason: value })}
              />
            </Col>
          </RowBT>
          <RowBT>
            <Col sm={12}
              md={12}
              lg={12}
              xl={12}>
              <Form.Label className='mt-4'>วัตถุประสงค์</Form.Label>
              <Table hidePagination>
                <thead>
                  <tr>
                    <th>ลำดับ</th>
                    <th>รายละเอียด</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {createTorData.objectives && createTorData.objectives.map((data, index) => (
                    <tr key={generateUniqueId(index)}>
                      <td className='text-center'>
                        {index + 1}
                      </td>
                      <td>
                        <Input
                          rule={{ required: true }}
                          value={data.description}
                          onChange={(value) => objectiveOnChange(value, index, 'description')}
                        />
                      </td>
                      <td className='text-center'>
                        {createTorData.objectives.length > 1 &&
                          <BsFillTrashFill onClick={() => removeObjectiveRow(index)} />}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button
                variant='light'
                className='mt-3'
                onClick={addObjectiveRow}
              >
                <BsPlus />เพิ่มวัตถุประสงค์
              </Button>
            </Col>
          </RowBT>
        </Card.Body>
      </Card>

      {/* รายการพัสดุ */}
      <Card className='mt-3'>
        <Card.Body>
          <Title text='รายการของพัสดุ'
            className='fs-5 text-primary' />
          <label className='mt-3'>รายการของพัสดุที่จะซื้อ</label>
          <Table hidePagination
            className='mt-3'>
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>รายละเอียด</th>
                <th>จำนวน</th>
                <th>หน่วย</th>
                <th>ราคา</th>
                <th>ราคารวมภาษี</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {createTorData.parcels && createTorData.parcels.map((data, index) => (
                <tr key={generateUniqueId(index)}>
                  <td className='text-center'>{index + 1}</td>
                  <td>
                    <Input value={data.description}
                      onChange={(value) => parcelsOnChange(value, index, 'description')}
                      rule={{ required: true }} />
                  </td>
                  <td>
                    <Input value={data.quantity}
                      onChange={(value) => parcelsOnChange(value, index, 'quantity')}
                      rule={{ required: true }} />
                  </td>
                  <td>
                    <Input value={data.unit}
                      onChange={(value) => parcelsOnChange(value, index, 'unit')}
                      rule={{ required: true }} />
                  </td>
                  <td>
                    <Input textEnd
                      type='number'
                      value={data.price}
                      onChange={(value) => parcelsOnChange(parseInt(value, 10), index, 'price')}
                      rule={{ required: true }} />
                  </td>
                  <td>
                    <Input textEnd
                      type='number'
                      value={data.total}
                      onChange={(value) => parcelsOnChange(parseInt(value, 10), index, 'total')}
                      rule={{ required: true }} />
                  </td>
                  <td className='text-center'>
                    {createTorData.parcels.length > 1 && <BsFillTrashFill onClick={() => removeParcelsRow(index)} />}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            variant='light'
            className='mt-3'
            onClick={addParcelsRow}
          >
            <BsPlus />เพิ่มรายการพัสดุ
          </Button>
          <div className='mt-3'>
            <label>เอกสารรายละเอียดลักษณะเฉพาะ</label>
            <UploadFile onChange={(data) => (console.log(data))}
              onRemove={(data) => console.log(data)} />
          </div>
        </Card.Body>
      </Card>

      {/* คุณสมบัติ */}
      <Card className='mt-3'>
        <Card.Body>
          <Title text='คุณสมบัติ'
            className='fs-5 text-primary' />
          <Table hidePagination
            className='mt-4'>
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>รายละเอียด</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {createTorData.qualifications && createTorData.qualifications.map((data, index) => (
                <tr key={generateUniqueId(data.description)}>
                  <td className='text-center'>{index + 1}</td>
                  <td>
                    <Input value={data.description}
                      onChange={(value) => qualificationsOnChange(value, index, 'description')}
                      rule={{ required: true }} />
                  </td>
                  <td className='text-center'>
                    {createTorData.qualifications.length > 1 &&
                      <BsFillTrashFill onClick={() => removeQualificationsRow(index)} />}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            variant='light'
            className='mt-3'
            onClick={addQualificationsRow}
          >
            <BsPlus />เพิ่มคุณสมบัติ
          </Button>
        </Card.Body>
      </Card>

      {/* รายละเอียด */}
      <Card className='mt-3'>
        <Card.Body>
          <Title text='รายละเอียดของงาน'
            className='fs-5 text-primary' />
          <RowBT className='mt-4'>
            <Col sm={12}
              md={12}
              lg={12}
              xl={12}>
              <InputTextArea
                value={createTorData.description}
                label='รายละเอียดของงาน'
                rule={{ required: true }}
                onChange={(value) => setCreateTorData({ ...createTorData, description: value })}
              />
            </Col>
          </RowBT>
        </Card.Body>
      </Card>

      {/* ระยะเวลาดำเนินการ/ส่งมอบงาน */}
      <Card className='mt-3'>
        <Card.Body>
          <Title text='ระยะเวลาดำเนินการ/ส่งมอบงาน'
            className='fs-5 text-primary' />
          <Table hidePagination
            className='mt-4'>
            <thead>
              <tr>
                <th style={{ minWidth: 10 }}>งวดที่</th>
                <th style={{ minWidth: 10 }}>จำนวน</th>
                <th style={{ minWidth: 500 }}>ประเภท</th>
                <th style={{ minWidth: 500 }}>รายละเอียด</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {createTorData.deliveryPeriods && createTorData.deliveryPeriods.map((data, index) => (
                <tr key={generateUniqueId(index)}>
                  <td>
                    <Input rule={{ required: true }}
                      type='number'
                      value={data.installment}
                      onChange={(value) => deliveryPeriodsOnChange(value ? parseInt(value, 10) : '', index, 'installment')} />
                  </td>
                  <td>
                    <Input rule={{ required: true }}
                      type='number'
                      value={data.period}
                      onChange={(value) => deliveryPeriodsOnChange(value ? parseInt(value, 10) : '', index, 'period')} />
                  </td>
                  <td>
                    <Input rule={{ required: true }}
                      value={data.periodType}
                      onChange={(value) => deliveryPeriodsOnChange(value, index, 'periodType')} />
                  </td>
                  <td>
                    <Input rule={{ required: true }}
                      value={data.description}
                      onChange={(value) => deliveryPeriodsOnChange(value, index, 'description')} />
                  </td>
                  <td>
                    {createTorData.deliveryPeriods.length > 1 &&
                      <BsFillTrashFill onClick={() => removeDeliveryPeriodsRow(index)} />}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            variant='light'
            className='mt-3'
            onClick={addDeliveryPeriodsRow}
          >
            <BsPlus /> เพิ่มงวด
          </Button>
        </Card.Body>
      </Card>

      {/* วงเงินทั้งสิ้น */}
      <Card className='mt-3'>
        <Card.Body>
          <Title text='วงเงินที่จะจัดจ้าง'
            className='fs-5 text-primary' />
          <div className='d-flex gap-3 align-items-center mb-3 mt-3'>
            <p className='text-primary fs-5 my-auto'>วงเงินทั้งสิ้น :</p>
            <div className='w-10'>
              <Input textEnd
                type='number'
                rule={{ required: true }}
                value={createTorData.financialAmount}
                onChange={(value) => setCreateTorData({ ...createTorData, financialAmount: parseInt(value, 10) })} />
            </div>
          </div>
          <label>รายละเอียดวงเงินที่จะจัดจ้าง</label>
          <Table hidePagination
            className='mt-3'>
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>ค่าพัฒนาระบบ</th>
                <th>ราคา</th>
                <th>รายละเอียด</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {createTorData.financials && createTorData.financials.map((data, index) => (
                <tr key={generateUniqueId(index)}>
                  <td className='text-center'>{index + 1}</td>
                  <td>
                    <Input rule={{ required: true }}
                      value={data.financialType}
                      onChange={(value) => financialOnChange(value, index, 'financialType')} />
                  </td>
                  <td>
                    <Input textEnd
                      type='number'
                      rule={{ required: true }}
                      value={data.amount}
                      onChange={(value) => financialOnChange(parseInt(value, 10), index, 'amount')} />
                  </td>
                  <td>
                    <Input rule={{ required: true }}
                      value={data.description}
                      onChange={(value) => financialOnChange(value, index, 'description')} />
                  </td>
                  <td className='text-center'>
                    {createTorData.financials.length > 1 &&
                      <BsFillTrashFill onClick={() => financialsRemoveRow(index)} />}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <RowBT>
            <Col>
              <Button
                variant='light'
                className='mt-3'
                onClick={financialsAddRow}
              >
                <BsPlus />เพิ่มวงเงินที่จะจัดจ้าง
              </Button>
            </Col>
          </RowBT>
        </Card.Body>
      </Card>

      {/* เงื่อนไขการชำระเงิน */}
      <Card className='mt-3'>
        <Card.Body>
          <Title text='เงื่อนไขการชำระเงิน'
            className='fs-5 text-primary' />
          <Table hidePagination
            className='mt-4'>
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>งวดที่</th>
                <th>จำนวน (%)</th>
                <th style={{ minWidth: 700 }}>รายละเอียด</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {createTorData.paymentTerms && createTorData.paymentTerms.map((data, index) => (
                <tr key={generateUniqueId(index)}>
                  <td className='text-center'>{index + 1}</td>
                  <td>
                    <Input rule={{ required: true }}
                      type='number'
                      value={data.installment}
                      onChange={(value) => paymentTermsOnChange(value ? parseInt(value, 10) : '', index, 'installment')} />
                  </td>
                  <td>
                    <Input rule={{ required: true }}
                      type='number'
                      value={data.percent}
                      onChange={(value) => paymentTermsOnChange(value ? parseInt(value, 10) : '', index, 'percent')} />
                  </td>
                  <td>
                    <Input rule={{ required: true }}
                      value={data.description}
                      onChange={(value) => paymentTermsOnChange(value, index, 'description')} />
                  </td>
                  <td>{createTorData.paymentTerms.length > 1 &&
                    <BsFillTrashFill onClick={() => removePaymentTermsRow(index)} />}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            variant='light'
            className='mt-3'
            onClick={addPaymentTermsRow}
          >
            <BsPlus /> เพิ่มเงื่อนไข
          </Button>
        </Card.Body>
      </Card>

      {/* สัญญาจ้างบริการบำรุงรักษาระบบ */}
      <Card className='mt-3'>
        <Card.Body>
          <Title text='สัญญาจ้างบริการบำรุงรักษาระบบ'
            className='fs-5 text-primary' />
          <div className='d-flex gap-3'>
            <Input rule={{ required: true }}
              type='number'
              label='ระยะเวลา'
              value={createTorData.warantyPeriod}
              onChange={(value) => setCreateTorData({ ...createTorData, warantyPeriod: parseInt(value, 10) })} />
            <Selector items={dateTypeItems}
              rule={{ required: true }}
              label='ประเภทการรับประกัน (วัน/เดือน/ปี)'
              value={createTorData.warantyPeriodType}
              onChange={(value) => setCreateTorData({ ...createTorData, warantyPeriodType: value })} />
          </div>
        </Card.Body>
      </Card>

      {/* อัตราค่าปรับ */}
      <Card className='mt-3'>
        <Card.Body>
          <Title text='อัตราค่าปรับ'
            className='fs-5 text-primary' />
          <Form.Label className='mt-4'>รายการของพัสดุที่จะซื้อ</Form.Label>
          <Table hidePagination>
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>ประเภทค่าปรับ</th>
                <th>ประเภทการปรับเป็นรายวัน / เดือน / ปี (%)</th>
                <th>ค่าปรับ</th>
                <th style={{ minWidth: 700 }}>รายละเอียด</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {createTorData.fineRates && createTorData.fineRates.map((data, index) => (
                <tr key={generateUniqueId(index)}>
                  <td className='text-center'>{index + 1}</td>
                  <td className='text-center'>
                    <Input rule={{ required: true }}
                      value={data.fineRateType}
                      onChange={(value) => fineRatesOnChange(value, index, 'fineRateType')} />
                  </td>
                  <td className='text-center'>
                    <Input rule={{ required: true }}
                      value={data.fineRatePeriodType}
                      onChange={(value) => fineRatesOnChange(value, index, 'fineRatePeriodType')} />
                  </td>
                  <td className='text-center'>
                    <Input textEnd
                      type='number'
                      rule={{ required: true }}
                      value={data.fine}
                      onChange={(value) => fineRatesOnChange(parseInt(value, 10), index, 'fine')} />
                  </td>
                  <td>
                    <Input rule={{ required: true }}
                      value={data.description}
                      onChange={(value) => fineRatesOnChange(value, index, 'description')} />
                  </td>
                  <td>{createTorData.fineRates.length > 1 &&
                    <BsFillTrashFill onClick={() => removeFineRatesRow(index)} />}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            variant='light'
            className='mt-3'
            onClick={addFineRatesRow}
          >
            <BsPlus /> เพิ่มเงื่อนไข
          </Button>
        </Card.Body>
      </Card>

      <div className='d-flex justify-content-between pt-5 gap-3'>
        <ButtonCustom
          onClick={props.onClickBack}
          text="ย้อนกลับ"
        />
        <ButtonCustom
          onClick={() => props.onClickNext(createTorData)}
          text="ถัดไป"
        />
      </div>
    </>
  );
}
