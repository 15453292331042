import {
  Card,
  ContractAgreementStatus,
  Modal,
  ButtonCustom,
  InputTextArea,
  SearchBuNameModal,
  TorStatus,
  Readonly,
} from 'components';
import Title from 'components/Controls/Title';
import {
  ContractAgreementAcceptorModel,
  JorPorCommentModel,
  UpdateJorPorCommentModel,
} from 'models/CM/CM02Models';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaUndo,
} from 'react-icons/fa';
import { shareValue } from 'services';
import CM02Service from 'services/CM/CM02Service';
import {
  HttpStatusCode,
  showConfirmModalAsync,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { BsCheckCircle } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router';
import { Acceptors } from 'components/Table/Acceptors';
import { JorPorCode } from 'constant/basicInformation';
import { AcceptorStatus, ProgramType } from 'utils/constants/PlanEnum';
import { UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { ApproverContractStatus, ContractAgreementTypeAcceptor } from 'constant/ContractStatus';
import { isNull } from 'utils/helper';
import JorPorComment from 'components/JorPorComment';

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default function JorPorCommentCM() {
  const [data, setData] = useState<JorPorCommentModel>({} as JorPorCommentModel);
  const { userId, departmentCode } = useAppContext();
  const [acceptorsApprove, setAcceptorsApprove] = useState<ContractAgreementAcceptorModel[]>([]);
  const [responsibles, setResponsibles] = useState<ContractAgreementAcceptorModel[]>([]);
  const [assignees, setAssignees] = useState<ContractAgreementAcceptorModel[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [commentDescription, setCommentDescription] = useState('');
  const [sectionType, setSectionType] = useState<string>();
  const [sectionSequence, setSectionSequence] = useState<number>();
  const [showModals, setShowModals] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [showConfirmAssign, setShowConfirmAssign] = useState(false);
  const [showConfirmJorPor, setShowConfirmJorPor] = useState(false);
  const [isJorPorDirector, setIsJorPorDirector] = useState<boolean>(false);
  const [isAssignee, setIsAssignee] = useState<boolean>(false);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);
  const [showWaitingForApprove, setShowWaitingForApprove] = useState(false);
  const [rejectRemark, setRejectRemark] = useState('');
  const [approveRemark, setApproveRemark] = useState('');
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showAcceptorApproveModal, setShowAcceptorApproveModal] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const navigate = useNavigate();
  const { contractId, id } = useParams();

  const goToPage = (contractId?: string) => {

    if (contractId) {
      navigate(`/contract-agreement/detail/${contractId}`);
    }
  };

  useEffect(() => {

    getContractJorPorCommentAsync(contractId, id);

  }, [contractId, id]);

  const getContractJorPorCommentAsync = async (contractId?: string, id?: string) => {
    const {
      data,
      status,
    } = await CM02Service.getContractJorPorCommentAsync(contractId, id);
    if (status === HttpStatusCode.OK) {
      setData(data);
    }
  };

  useEffect(() => {

    if (data) {
      if (data.commentDescription) {
        setCommentDescription(data.commentDescription);
      }

      if (data.acceptorsApprove) {
        setAcceptorsApprove(data.acceptorsApprove);
        setIsApprover(onCheckMessageApprover(data.acceptorsApprove));
      }

      if (data.jorPorDirectors) {
        setIsJorPorDirector(data.jorPorDirectors.filter(x => isNull(x.delegateUserId, x.userId) === userId).length > 0);
      }

      if (data.assignees) {
        setIsAssignee(data.assignees.filter(x => isNull(x.delegateUserId, x.userId) === userId).length > 0);
      }

      if (data.responsibles) {
        setIsResponsible(data.responsibles.filter(x => isNull(x.delegateUserId, x.userId) === userId).length > 0);
      }

      if (data.status === ContractAgreementStatus.Approved) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }

  }, [data, userId]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const onCheckMessageApprover = (data: ContractAgreementAcceptorModel[]) => {

    const approver = data?.filter(x => x.status === ApproverContractStatus.PENDING).length === 1;

    if (approver) {
      return true;
    }

    return false;
  };

  const isQueueApprover = useMemo(() => {
    if (data && data.acceptorsApprove && userId) {
      const minSection = Math.min(...data?.acceptorsApprove
        .filter(d => d.status === ApproverContractStatus.PENDING)
        .map(o => o.sectionSequence));

      const sectionData = data?.acceptorsApprove
        .filter(d => d.sectionSequence === minSection
          && d.status === ApproverContractStatus.PENDING)
        .sort((a, b) => a.sequence - b.sequence)[0];

      if (sectionData) {
        return isNull(sectionData.delegateUserId, sectionData.userId) === userId;
      }
    }

  }, [userId, data]);

  const onSelectEmpApprover = (empId: string, name: string, department: string, position: string, index: number, sectionSequence?: number, sectionName?: string, inRefCode?: string, sectionType?: string, delegateUserId?: string, delegateFullName?: string, delegateDepartmentName?: string, delegatePositionName?: string, delegateEmployeeCode?: string) => {

    if (acceptorsApprove && acceptorsApprove.filter((x) => x.inRefCode === inRefCode && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {

      const acceptorBySection = acceptorsApprove.filter((x) => x.sectionSequence === sectionSequence);

      const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
        acceptorBySection.map((x) => {
          return x.sequence;
        })) : 0;

      const newData: ContractAgreementAcceptorModel = {
        id: undefined,
        sectionSequence: sectionSequence!,
        sectionName: sectionName!,
        userId: empId,
        sequence: maxValueSequence + 1,
        fullName: name,
        isAvailable: false,
        availableRemark: '',
        departmentName: department,
        type: ContractAgreementTypeAcceptor.AcceptorApprover,
        status: AcceptorStatus.DRAFT,
        seqKey: `${sectionSequence?.toString()}${(acceptorsApprove.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        sectionInRefCode: inRefCode!,
        acceptDate: undefined,
        acceptRemark: '',
        positionName: position,
        rejectDate: undefined,
        rejectRemark: '',
        delegateUserId: delegateUserId,
        delegateFullName: delegateFullName,
        delegateDepartmentName: delegateDepartmentName,
        delegatePositionName: delegatePositionName,
        delegateEmployeeCode: delegateEmployeeCode,
        inRefCode: inRefCode,
        sectionType: sectionType,
      };
      setAcceptorsApprove((acceptorsData => [...acceptorsData, newData]))
    }
  };

  const onSave = async (isJorPor: boolean, isAssignee: boolean, isApprove: boolean) => {

    setShowConfirmJorPor(false);
    setShowWaitingForApprove(false);
    setShowConfirmAssign(false);

    if (data.status === ContractAgreementStatus.DraftSectionApprover
      && (!commentDescription)) {
      toast.warn('กรุณาระบุความเห็นเพิ่มเติม');

      return;
    }

    if ((!assignees || assignees.length === 0) && data.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign) {
      toast.warn('กรุณาเลือก ผอ.จพ. มอบหมาย');
      return;
    }

    if ((!responsibles || responsibles.length === 0) && data.status === ContractAgreementStatus.WaitingForAssignResponsible) {
      toast.warn('กรุณาเลือกผู้รับผิดชอบ');
      return;
    }

    if ((!acceptorsApprove || acceptorsApprove.length === 0) && data.status === ContractAgreementStatus.DraftSectionApprover) {
      toast.warn('กรุณาเลือกผู้มีอำนาจเห็นชอบอย่างน้อย 1 คน');
      return;
    }

    acceptorsApprove.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        data.sequence = index + 1;
        data.type = ContractAgreementTypeAcceptor.AcceptorApprover;
      });

    assignees.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        data.sequence = index + 1;
      });

    responsibles.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        data.sequence = index + 1;
      });

    const saveDatas = [...acceptorsApprove, ...assignees, ...responsibles, ...data.jorPorDirectors];

    const updateData: UpdateJorPorCommentModel = {
      acceptors: saveDatas,
      sendToApprove: isApprove,
      sendToAssignee: isAssignee,
      sendToJorPor: isJorPor,
      commentDescription: commentDescription,
      commentDate: data.commentDate,
      contractAgreementId: data.contractAgreementId,
    }

    if (data.id) {
      const res = await CM02Service.updateJorPorCommentAsync(data.id, updateData);
      if (res.status !== HttpStatusCode.OK) {
        toast.error('ไม่สามารถบันทึกข้อมูลได้');

        return;
      }

      getContractJorPorCommentAsync(data.contractAgreementId, data.id);

    } else {
      const res = await CM02Service.createJorPorCommentAsync(updateData);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('ไม่สามารถบันทึกข้อมูลได้');

        return;
      }

      const newId: string = res.data;
      navigate(`/contract-agreement/${contractId}/JorPorComment/${newId}`);
      getContractJorPorCommentAsync(data.contractAgreementId, newId);
    }

    toast.success('บันทึกข้อมูลสัญญาสำเร็จ');
  };

  const onReject = async () => {
    if (!rejectRemark) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    const res = await CM02Service.onRejectJorPorCommentAsync(data.id, rejectRemark);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
      return;
    }

    getContractJorPorCommentAsync(data.contractAgreementId, data.id);
    toast.success('ส่งกลับแก้ไขสำเร็จ');
    setShowRejectModal(false);
    setRejectRemark('');
  };

  const onApprove = async () => {
    const res = await CM02Service.onApproveJorPorCommentAsync(data.id, approveRemark);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('อนุมัติไม่สำเร็จ');
      return;
    }

    setApproveRemark('');
    getContractJorPorCommentAsync(data.contractAgreementId, data.id);
    toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
    setShowAcceptorApproveModal(false);
  };

  const recallAsync = async () => {
    const confirmRes = await showConfirmModalAsync('คุณต้องการเรียกคืนแก้ไขหรือไม่ ?');

    if (confirmRes) {
      const res = await CM02Service.onReCallAsync(data.id);

      if (res.status === HttpStatusCode.OK) {
        toast.success('เรียกคืนแก้ไขสำเร็จสำเร็จ');

        getContractJorPorCommentAsync(data.contractAgreementId, data.id);
      }
    }
  };

  const isRecall = useMemo(() => {
    return data.acceptorsApprove?.filter(a => a.status === AcceptorStatus.APPROVE).length === 0;
  }, [data, userId])

  return (
    <div className='document'>

      <Card className='mt-3'>
        <Title
          text='ข้อมูลโครงการ'
          className='fs-5 text-primary'
          extraElement={(
            <TorStatus
              value={data.status}
            />
          )}
        />
        <Row>
          <Col sm={12}
            md={3}
            xl={3}>
            <Readonly label='เลขที่รายการจัดซื้อจัดจ้าง'
              textReadonly={data.planNumber} />
          </Col>
          <Col sm={12}
            md={3}>
            <Readonly label='โครงการ'
              textReadonly={data.planName} />
          </Col>
          <Col sm={12}
            md={3}>
            <Readonly label='งบประมาณโครงการ (บาท)'
              textReadonly={formatter.format(data.financialAmount)} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={3}
            xl={3}>
            <Readonly label='วิธีจัดหา'
              textReadonly={data.supplyMethodName} />
          </Col>
          <Col sm={12}
            md={3}>
            <Readonly textReadonly={data.supplyMethodTypeName} />
          </Col>
          <Col sm={12}
            md={3}>
            <Readonly textReadonly={data.supplyMethodSpecialTypeName} />
          </Col>
        </Row>
      </Card>

      <JorPorComment
        data={data}
        isAssignee={isAssignee && data.status === ContractAgreementStatus.WaitingForAssignResponsible}
        isJorPorDirector={isJorPorDirector && data.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign}
        isResponsible={isResponsible}
        onAssigneeDataChange={(val) => setAssignees(val)}
        onResponsibleDataChange={(val) => setResponsibles(val)}
        onCommentDescriptionChange={(val) => setCommentDescription(val)}
      />

      {(data.supplyMethodId && (data.status !== ContractAgreementStatus.WaitingForDirectorJorPorAssign && data.status !== ContractAgreementStatus.WaitingForDirectorJorPorAssign && data.status !== ContractAgreementStatus.WaitingForAssignResponsible)) && (
        <Acceptors
          titleHistory='ประวัติบันทึกต่อท้ายสัญญา'
          data={acceptorsApprove.length > 0 ? acceptorsApprove : data.acceptorsApprove}
          visible={isResponsible && (data.status === ContractAgreementStatus.DraftSectionApprover || data.status === ContractAgreementStatus.Rejected)}
          isDepartmentDirector={false}
          processType={PROCESSTYPE.ApproveSignContractProcess}
          budgetAmount={data.sumOfferTotalAmountVat}
          supplyMethodId={data.supplyMethodId}
          program={ProgramType.ContractAgreement}
          onSetSectionType={(type) => setSectionType(type)}
          onAcceptorModalShow={() => setShowModals(!showModals)}
          onSetSectionSequence={(section) => setSectionSequence(section)}
          onAcceptorDataChange={(data) => setAcceptorsApprove(data)}
        />)}

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={() => goToPage(data.contractAgreementId)}
          text="ย้อนกลับ"
        />
        <div>
          {((isJorPorDirector && data.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign)
            || (isAssignee && data.status === ContractAgreementStatus.WaitingForAssignResponsible)
            || (isResponsible && (data.status === ContractAgreementStatus.DraftSectionApprover || data.status === ContractAgreementStatus.Rejected))) && (
              <ButtonCustom
                onClick={() => onSave(false, false, false)}
                text="บันทึก" />
            )}

          {(isJorPorDirector && data.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign) ?
            <ButtonCustom
              onClick={() => setShowConfirmJorPor(true)}
              text="มอบหมาย" /> : <></>
          }

          {(isAssignee && data.status === ContractAgreementStatus.WaitingForAssignResponsible) ?
            <ButtonCustom
              onClick={() => setShowConfirmAssign(true)}
              text="มอบหมายผู้รับผิดชอบ" /> : <></>
          }

          {(isResponsible && (data.status === ContractAgreementStatus.DraftSectionApprover || data.status === ContractAgreementStatus.Rejected)) ?
            <ButtonCustom
              onClick={() => setShowWaitingForApprove(true)}
              text='ส่งอนุมัติ/เห็นชอบ' /> : <></>
          }
          {
            (data.status === ContractAgreementStatus.WaitingForApprove && isRecall && isResponsible) && (
              <ButtonCustom
                text="เรียกคืนแก้ไข"
                icon='undo'
                variant='danger'
                onClick={recallAsync}
              />
            )
          }
          {(isQueueApprover && data.status === ContractAgreementStatus.WaitingForApprove) && (
            <>
              <Button
                className='me-2 px-3'
                onClick={() => setShowRejectModal(true)}
                variant='danger'
              >
                <FaUndo className='mx-2 pb-1 fs-5' />
                ส่งกลับแก้ไข
              </Button>
              <Button
                variant='success'
                className='me-2 px-3'
                onClick={() => setShowAcceptorApproveModal(true)}
              >
                <BsCheckCircle className='me-2 pb-1 fs-5' />
                {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </Button>
            </>
          )}

        </div>
        <div style={{ width: 120 }}></div>
      </div>
      <Modal
        show={showSaveConfirmModal}
        size='lg'
        onHide={() => setShowSaveConfirmModal(!showSaveConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการบันทึกข้อมูลหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowSaveConfirmModal(!showSaveConfirmModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(false, false, false)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showModals}
        onHide={() => setShowModals(!showModals)}
        onSelect={(id, name, position, departmentName, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName, delegateEmployeeCode) => onSelectEmpApprover(id, name, departmentName!, position!, acceptorsApprove.length + 1, sectionSequence, '', sectionType, sectionType, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName, delegateEmployeeCode)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <Modal
        show={showConfirmJorPor}
        size='lg'
        onHide={() => setShowConfirmJorPor(!showConfirmJorPor)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการมอบหมายงานหรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowConfirmJorPor(!showConfirmJorPor)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(true, false, false)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showConfirmAssign}
        size='lg'
        onHide={() => setShowConfirmAssign(!showConfirmAssign)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการมอบหมายผู้รับผิดชอบงานหรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowConfirmAssign(!showConfirmAssign)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(false, true, false)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showWaitingForApprove}
        size='lg'
        onHide={() => setShowWaitingForApprove(!showWaitingForApprove)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งอนุมัติ/เห็นชอบ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitingForApprove(!showWaitingForApprove)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(false, false, true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showAcceptorApproveModal}
        size='lg'
        onHide={() => setShowAcceptorApproveModal(!showAcceptorApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>{isApprover ? 'อนุมัติ' : 'เห็นชอบ'}</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                rule={{ required: false }}
                onChange={(val) => setApproveRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowAcceptorApproveModal(!showAcceptorApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onApprove}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejectModal}
        size='lg'
        onHide={() => setShowRejectModal(!showRejectModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRejectRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowRejectModal(!showRejectModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                variant='danger'
                onClick={onReject}
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}
