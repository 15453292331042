import {
  InputTextArea,
  ProcessStatus,
  Status,
  StatusType,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Card,
  Modal as ModalBT,
} from 'react-bootstrap';
import {
  BsArrowReturnLeft,
  BsChatRightDots,
} from 'react-icons/bs';
import {
  FaAlignLeft,
  FaCheck,
  FaHistory,
  FaUndo,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function GetStatus(i: number) {
  switch (i) {
    case 1:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.PENDING} />;
    case 2:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.APPROVED} />;
    case 3:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.REJECTED} />;
    default:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.DRAFT} />;
  }
}

function Step4({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalComment, setShowModalComment] = useState(false);
  return (
    <div className="document">
      <Card className="mt-3">
        <Card.Body>
          <Title text="สถานะการอนุมัติ" className="fs-5 text-primary" />
          <Table>
            <thead>
              <tr>
                <th style={{ minWidth: 10 }}>ลำดับ</th>
                <th style={{ minWidth: 50 }}>รหัสพนักงาน</th>
                <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
                <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
                <th style={{ minWidth: 35 }}>สถานะ</th>
                <th style={{ minWidth: 50 }}>วันที่อนุมัติ</th>
                <th style={{ minWidth: 50 }}>ความเห็น</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 5 }).map((_, index) => (
                <tr key={index}>
                  <td className="text-center">{index}</td>
                  <td className="text-center">5900010</td>
                  <td className="text-center">สมศรี แดนไทย</td>
                  <td className="text-center">รักษาการผู้อำนวยการสำนักงบประมาณ</td>
                  <td className="text-center">{GetStatus(index)} </td>
                  <td className="text-center">28-11-2566 16:24:22</td>
                  <td className="text-center"><BsChatRightDots className="text-primary" onClick={() => setShowModalComment(true)} /></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <div className="d-flex justify-content-between my-3">
            <Title text="ตัวอย่างเอกสารบันทึกข้อความแต่งตั้ง" className="fs-5 text-primary" />
            <Button
              className="px-3"
              onClick={() => setShow(true)}
              variant="light"
            >
              <FaHistory className="me-3" />ประวัติการแก้ไข
            </Button>
          </div>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-center gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-4" />
          ย้อนกลับ
        </Button>
        <Button
          variant="danger"
          onClick={() => setShow(true)}
          className="me-2 px-3"
        >
          <FaUndo className="mx-2 pb-1 fs-4" />
          ส่งกลับแก้ไข
        </Button>
        <Button
          onClick={() => setShowModal(true)}
          className="me-2 px-3"
        >
          <FaAlignLeft className="me-2 pb-1 fs-5" />
          ส่งอนุมัติ
        </Button>
      </div>
      <Modal
        show={showModal}
        size="lg"
        onHide={() => setShowModal(!showModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ให้ความเห็นชอบ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              <InputTextArea label="ความเห็น" />
            </ModalBT.Body>
            <ModalBT.Footer className="justify-content-center">
              <Button variant="light" onClick={() => setShowModal(!showModal)}>
                ยกเลิก
              </Button>
              <Button variant="success" className="text-white">
                <FaCheck className="me-2" />
                ให้ความเห็น
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(!show)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              <InputTextArea label="เหตุผล" rule={{ required: true }} />
            </ModalBT.Body>
            <ModalBT.Footer className="justify-content-center">
              <Button variant="light" onClick={() => setShow(!show)}>
                ยกเลิก
              </Button>
              <Button variant="danger">
                <FaUndo className="mx-2 pb-1 fs-4" />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showModalComment}
        size="lg"
        onHide={() => setShowModalComment(!showModalComment)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ความเห็น</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              <InputTextArea label="ความเห็น" />
            </ModalBT.Body>
            <ModalBT.Footer className="justify-content-center">
              <Button variant="light" className="px-5" onClick={() => setShowModalComment(!showModalComment)}>
                ปิด
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step4;
