import { ContractAgreementStatus } from 'components';
import StepProgress from 'components/StepProgress';
import { ContractAgreementModel } from 'models/CM/CM02Models';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import CM02Service from 'services/CM/CM02Service';
import { HttpStatusCode } from 'utils';
import Step1 from './step1';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import PreProcurementTorServices from 'services/PP/PreProcurementTorServices';
import { TorDetailModel } from 'models/PP/TorModels';
import MainStep2 from './Step2Component/MainStep2';
import { ItemModel } from 'models';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type ContractContext = {
  dataContext: ContractAgreementModel
  sectionContractContext: ItemModel[]
  setDataContext: Dispatch<React.SetStateAction<ContractAgreementModel>>,
  setSectionContractContext: Dispatch<React.SetStateAction<ItemModel[]>>,
};

export const Context = createContext({} as ContractContext);

function CreateContractMangementDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id } = useParams();
  const [dataContext, setDataContext] = useState<ContractAgreementModel>({} as ContractAgreementModel);
  const [sectionContractContext, setSectionContractContext] = useState<ItemModel[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [defaultStep, setDefaultStep] = useState(1);
  const [templateCode, setTemplateCode] = useState<string>('');
  const [templateId, setTemplateId] = useState<string>('');

  useEffect(() => {
    if (id) {
      getContractByIdAsync(id);
    }
  }, [id]);

  const setContractTemplate = (value: string, id: string) => {
    setTemplateCode(value);
    setTemplateId(id);
  };

  const getContractByIdAsync = async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getContractByIdAsync(id);
    if (status === HttpStatusCode.OK) {
      if (!data.isDefaultDataFromTor) {
        setDefaultDataFromTor(data);
      } else {
        setDataContext(data);
        setStepByStatus(data.status);
      }
    }
  };

  const setDefaultDataFromTor = async (contractData: ContractAgreementModel) => {
    setDataContext(contractData);
    setStepByStatus(contractData.status);
  }

  const setStepByStatus = (status: string) => {
    switch (status) {
      case ContractAgreementStatus.Draft:
        setDefaultStep(1);
        break;
      case ContractAgreementStatus.WaitingForApprove:
        setDefaultStep(3);
        break;
      case ContractAgreementStatus.WaitingForAttachSignedDocument:
        setDefaultStep(4);
        break;
      case ContractAgreementStatus.WaitingForSign:
        setDefaultStep(5);
        break;
      case ContractAgreementStatus.Signed:
        setDefaultStep(5);
        break;
      case ContractAgreementStatus.RejectedSign:
        setDefaultStep(5);
        break;
      case ContractAgreementStatus.Rejected:
        setDefaultStep(3);
        break;
      case ContractAgreementStatus.WaitingForDirectorJorPorAssign:
        setDefaultStep(5);
        break;
      case ContractAgreementStatus.WaitingForAssignResponsible:
        setDefaultStep(5);
        break;
      case ContractAgreementStatus.DraftSectionApprover:
        setDefaultStep(5);
        break;
      default:
        break;
    }
  };

  const obj = [
    {
      title: <p>ข้อมูลสัญญา</p>,
      displayIndex: '1',
      child: <Step1 onClickNext={() => clickNext()}
        onClickBack={() => backToIndex()}
        onTemplateChange={setContractTemplate} />,
    },
    {
      title: <p> รายละเอียดร่างสัญญา </p>,
      displayIndex: '2',
      child: <MainStep2
        templateId={templateId}
        templateCode={templateCode}
        onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />, //TODO: Change to Step2 when implement is done
    },
    {
      title: <p>ตรวจสอบร่างสัญญา</p>,
      displayIndex: '3',
      child: <Step3 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()}
        onTemplateChange={setContractTemplate} />,
    },
    {
      title: <p>เอกสารลงนามสัญญา</p>,
      displayIndex: '4',
      child: <Step4 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />,
    },
    {
      title: <p>ยืนยันวันที่ลงนาม</p>,
      displayIndex: '5',
      child: <Step5 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />,
    },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
    if (dataContext.isChange) {
      navigate(`/edit-contract-agreement/detail/${id}`);
    } else {
      navigate(`/contract-agreement/detail/${id}`);
    }
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    if (dataContext.isChange) {
      navigate('/edit-contract-agreement');
    } else {
      navigate('/contract-agreement');
    }
  };

  return (
    <div className='document'>
      <h4 className='mt-2 mb-0 text-primary text-start'>
        <Button variant='link'>
          {/* <MdOutlineArrowBack className="fs-4 text-primary mb-1" /> */}
        </Button> {dataContext.isChange ? 'บันทึกต่อท้ายสัญญา' : 'รายละเอียดร่างข้อมูลสัญญา'}</h4>
      {/* <Breadcrumb data={BREADCRUMB_INFO} /> */}
      <Row>
        <Col xs={12}
          className='mb-2'>
          <Context.Provider value={{
            dataContext,
            sectionContractContext,
            setDataContext,
            setSectionContractContext
          }}>
            <StepProgress progressBarData={obj}
              ref={childRef}
              step={defaultStep} /> {/*FIXME: Change step to defaultStep when implement is done*/}
          </Context.Provider> <></>
        </Col>
      </Row>
    </div>
  );
}

export default CreateContractMangementDetail;
