import {
  Card,
  DatePicker,
  Input,
  Radio,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { User } from 'models';
import {
  useCallback,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaPlus,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { generateUniqueId } from '../../../../utils/helper';

const NATIONALITY = [
  { label: 'กรณีซื้อ อัตราร้อนละ 0.2 ของราคาสิ่งของที่ยังไม่ได้รับมอบ', value: '1' },
  { label: 'กรณีซื้อ อัตราร้อนละ 0.1 ของราคาสิ่งของที่ยังไม่ได้รับมอบ', value: '2' },
];

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [user, setUser] = useState<User>({} as User);
  const [roles, setRoles] = useState<{ label: string, value: string }[]>([]);

  const addRole = useCallback(() => {
    if (!user.roles) {
      setUser((user) => ({ ...user, roles: [] }));
    }

    setUser((user) => ({ ...user, roles: [...user.roles, ''] }));
  }, [user]);

  const removeRole = useCallback((i: number) => {
    const { roles } = user;

    roles.splice(i, 1);
    setUser((user) => ({ ...user, roles }));
  }, [user]);

  return (
    <div className="document">
      <Card className="mt-3">
        <div className="d-flex justify-content-between my-3">
          <Title text="รายการสินค้า" className="fs-5 text-primary" />
          <Button
            variant="outline-primary"
            onClick={addRole}
          >
            <FaPlus />เพิ่มข้อมูล
          </Button>
        </div>
        <Table total={1}>
          <thead>
            <tr>
              <th>ลำดับ</th>
              <th>รายละเอียด</th>
              <th>จำนวน</th>
              <th>หน่วยนับ</th>
              <th>ราคา/หน่วย(รวม VAT)<br />(บาท)</th>
              <th>จำนวนเงิน(รวม VAT)<br />(บาท)</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {user.roles?.map((r, i) => (
              <tr key={generateUniqueId(r)}>
                <td className="text-center">{i + 1}</td>
                <td className="text-center">
                  <Input />
                </td>
                <td className="text-center">
                  <Input textEnd />
                </td>
                <td className="text-center">
                  <Input textCenter />
                </td>
                <td className="text-center">
                  <Input textEnd />
                </td>
                <td className="text-center">
                  <Input textEnd />
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="outline-primary"
                      className="d-flex align-items-center gap-2"
                      onClick={() => removeRole(i)}
                    >
                      <FaTrashAlt />ลบ
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row className="align-items-center mt-3">
          <Col sm={12} md={10}>
            <p className="text-end">รวมเป็นเงิน <span className="ms-2">620,000.00</span></p>
            <p className="text-end">ภาษีมูลค่าเพิ่ม <span className="ms-2">43400.00</span></p>
            <p className="text-end">รวมเป็นเงินทั้งสิ้น <span className="ms-2">663400.00</span></p>
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="การจัดซื้อจัด/สั่งจ้างอยู่ภายใต้เงื่อนไขดังนี้" className="fs-5 text-primary" />
        <Row>
          <Col sm={12} lg={6}>
            <Row className="mt-3">
              <Col sm={4}>
                <p className="mb-0">1. กำหนดส่งมอบภายใน </p>
              </Col>
              <Col sm={4}>
                <Input value="45" />
              </Col>
              <Col sm={4}>
                <p className="mb-0">นับถัดจากวันที่ลงนามในสัญญา</p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={4}>
                <p className="mb-0">2. ครบกำหนดส่งมอบวันที่ </p>
              </Col>
              <Col sm={8}>
                <DatePicker />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={4}>
                <p className="mb-0">3. สถานที่ส่งมอบ </p>
              </Col>
              <Col sm={8}>
                <Input value="ธนาคารอาคารสงเคราะห์" />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={4}>
                <p className="mb-0">4. ระยะเวลารับประกัน </p>
              </Col>
              <Col sm={4}>
                <Input value="45" />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12}>
                <p className="mb-0">5. สงวนสิทธิ์ค่าปรับกรณีส่งมอบเกินกำหนดโดยคิดค่าปรับเป็นรายวัน </p>
              </Col>
              <Col sm={12}>
                <Radio
                  className="d-flex flex-column"
                  inline
                  items={NATIONALITY}
                  name="nationality"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default Step2;
