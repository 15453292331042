import {
  Card,
  FileValue,
  Input,
  InputTextArea,
  Modal,
  SearchBuNameModal,
  Selector,
  Table,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  CreatorLevel,
  JorPorCode,
} from 'constant/basicInformation';
import { JorporDirectorAssign, sharedValueResponse, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {
  FaAngleLeft,
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaEraser,
  FaPlus,
  FaRegCheckCircle,
  FaSave,
  FaSearch,
  FaTimesCircle,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { account, planProcurement as planProcurementService, shareValue } from 'services';
import {
  downloadFile,
  fullDatetime,
  HttpStatusCode,
  ProcurementSection,
  submitForm,
  useAppContext,
} from 'utils';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';
import {
  AcceptorStatus,
  AssignLevel,
  PlanStatus as PlanStatusCons,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { Context } from '.';
import { UploadFile } from './UploadFile';
import { AccountModel } from 'models/accountModel';
import { isNull } from 'utils/helper';

interface Props {
  onClickBack?: () => void;
}

type Loader = {
  departments: { value: string, label: string }[],
  positions: { value: string, label: string }[],
  assignDepartments: { value: string, label: string }[],
  jorPorDirectorPosition: sharedValueResponse,
}

type UserCriteria = {
  name: string;
  departmentId: string;
  positionId: string;
}

type UserResponse = {
  id: string;
  no: number;
  name: string;
  department: string;
  position: string;
  status: string;
  UserDate: Date;
  isAvailable: boolean;
  level: number;
}

export default function Step3(props: Props) {
  const loader = useLoaderData() as Loader;
  const { currentStep, overLimitPrice, planProcurement, setPlanProcurement, reGetDetailAsync, setCurrentStep, readonly } = useContext(Context);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [collapsed2, setCollapsed2] = useState<boolean>(true);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [showUserModal2, setShowUserModal2] = useState<boolean>(false);
  const [showChangeModal, setShowChangeModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const { userId, departmentId, departmentCode, inRefLevel, positionCode } = useAppContext();
  const [showPendingModal, setShowPendingModal] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const getUserProfileByIdAsync = async (userId: string) => {
    const { data, status } = await account.getUserByIdAsync(userId);

    if (status === HttpStatusCode.OK) {
      return data as AccountModel;
    }
  };

  const getUserDataLevelAsync = async (userId: string) => {

    const userOwnerData = await getUserProfileByIdAsync(userId);
    if (!userOwnerData) {
      return;
    }

    const empty = ' ';

    if (!planProcurement.jorporDirectorAssign) {
      planProcurement.jorporDirectorAssign = [];
    }
    setPlanProcurement({
      ...planProcurement,
      jorporDirectorAssign: [
        ...planProcurement.jorporDirectorAssign,
        {
          userId: userOwnerData.id,
          fullName: userOwnerData.firstName + empty + userOwnerData.lastName,
          departmentName: userOwnerData.departmentName,
          positionName: userOwnerData.positionName,
          sectionCode: ProcurementSection.JORPOR_DIRECTOR_ASSIGN,
          sequence: planProcurement.jorporDirectorAssign.length + 1,
          status: AcceptorStatus.PENDING,
          level: AssignLevel.Level0,
        } as JorporDirectorAssign,
      ],
    });
  };

  const getUserDataLevel1Async = async (userId: string) => {

    const userOwnerData = await getUserProfileByIdAsync(userId);
    if (!userOwnerData) {
      return;
    }

    const empty = ' ';

    if (!planProcurement.jorporDirectorAssign) {
      planProcurement.jorporDirectorAssign = [];
    }
    setPlanProcurement({
      ...planProcurement,
      jorporDirectorAssign: [
        ...planProcurement.jorporDirectorAssign,
        {
          userId: userOwnerData.id,
          fullName: userOwnerData.firstName + empty + userOwnerData.lastName,
          departmentName: userOwnerData.departmentName,
          positionName: userOwnerData.positionName,
          sectionCode: ProcurementSection.JORPOR_DIRECTOR_ASSIGN,
          sequence: planProcurement.jorporDirectorAssign.length + 1,
          status: AcceptorStatus.PENDING,
          level: AssignLevel.Level1,
        } as JorporDirectorAssign,
      ],
    });
  };


  const canRework = useMemo(() => {
    if (!planProcurement || currentStep !== 3 || !userId) {
      return false;
    }

    if (planProcurement.isRefChange || planProcurement.isRefCancel) {
      return false;
    }

    if (planProcurement.isCanceled && planProcurement.isActive) {
      return false;
    }

    if ((planProcurement.jorporDirectorBeInformed?.filter((x) => isNull(x.delegateUserId, x.userId) === userId).length > 0)
      || isJorporDirectorAssignLevel0()) {
      return true;
    }

    if (planProcurement.status === PlanStatusCons.DraftRecordDocument
      && isJorporDirectorAssignLevel1()) {
      return true;
    }

    return false;
  }, [planProcurement, currentStep, userId]);

  const canCheckJorPorAssign = useMemo(() => {

    if (planProcurement.jorporDirectorAssign?.filter((x) => x.level === AssignLevel.Level0 && isNull(x.delegateUserId, x.userId) === userId).length > 0 || positionCode === loader.jorPorDirectorPosition.sharedValue.value) {
      return false;
    }


    if (planProcurement.jorporDirectorBeInformed?.filter((x) => isNull(x.delegateUserId, x.userId) === userId).length > 0 || positionCode === loader.jorPorDirectorPosition.sharedValue.value) {
      return false;
    }

    return true;
  }, [planProcurement, userId, currentStep]);

  const canRemoveFile = useMemo(() => {
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  useEffect(() => {
    if (currentStep === 3 || currentStep === 4) {
      setCollapsed(false);
      setCollapsed2(false);
    }
  }, [currentStep, planProcurement]);

  const userOnSelect = (
    userId: string,
    fullName: string,
    departmentName: string,
    positionName: string,
    level: number,
  ) => {
    if (!planProcurement.jorporDirectorAssign) {
      planProcurement.jorporDirectorAssign = [];
    }
    setPlanProcurement({
      ...planProcurement,
      jorporDirectorAssign: [
        ...planProcurement.jorporDirectorAssign,
        {
          userId,
          fullName,
          departmentName,
          positionName,
          sectionCode: ProcurementSection.JORPOR_DIRECTOR_ASSIGN,
          sequence: planProcurement.jorporDirectorAssign.length + 1,
          status: AcceptorStatus.PENDING,
          level: level,
        } as JorporDirectorAssign,
      ],
    });
  };

  const handlerOnChange = (key: string, value: string) => {
    setPlanProcurement({
      ...planProcurement,
      [key]: value,
    });
  };

  const handlerOnSubmitAsync = async (status: string, resetStatus: boolean) => {
    submitForm({}, true);

    if (status === PlanStatusCons.DraftRecordDocument) {
      if (!planProcurement.jorporDirectorAssign
        || planProcurement.jorporDirectorAssign.length === 0) {
        toast.warn('ต้องมี ผอ.จพ. รับทราบอย่างน้อย 1 คน');

        return;
      }

      if (!planProcurement.jorporDirectorAssign.some(j => j.level === AssignLevel.Level1)) {
        toast.warn('ต้องมีผู้รับผิดชอบอย่างน้อย 1 คน');
        return;
      }
    }

    if (!planProcurement.assignDepartment) {
      return;
    }

    planProcurement.status = status;
    const res = await planProcurementService
      .updateAsync(planProcurement, resetStatus);

    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
      reGetDetailAsync(planProcurement.id);
      if (status === PlanStatusCons.DraftRecordDocument) {
        toast.success('ส่งมอบหมายงานสำเร็จ');
        setShowPendingModal(false);

        if (planProcurement.jorporDirectorAssign.some(a => a.userId === userId)) {
          reGetDetailAsync(planProcurement.id);
          setCurrentStep(4);
          window.location.reload();
        }
      } else {
        toast.success('บันทึกสำเร็จ');
      }
    }
  };

  const canEdit = useMemo(() => {
    if (!Object.keys(planProcurement).length
      || (currentStep !== 3 && currentStep !== 4)
      || !userId) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.ApprovePlan
      && planProcurement.status !== PlanStatusCons.Assigned) {
      return false;
    }

    // jorporDirectorBeInformed
    const foundInJorporDirectorBeInformed = planProcurement
      .jorporDirectorBeInformed?.some((s) => isNull(s.delegateUserId, s.userId) === userId && !s.isAvailable);

    // jorporDirectorAssign
    const foundInJorporDirectorAssign = planProcurement
      .jorporDirectorAssign?.some((s) => isNull(s.delegateUserId, s.userId) === userId && !s.isAvailable);

    if (foundInJorporDirectorBeInformed || foundInJorporDirectorAssign) {
      return true;
    }

    return false;
  }, [userId, planProcurement, currentStep]);

  const uploadFileOnChangeAsync = async (file: File) => {
    if (planProcurement.id) {
      const res = await planProcurementService.uploadFileAsync(
        planProcurement.id,
        file,
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        setPlanProcurement({
          ...planProcurement,
          documents: [
            ...planProcurement.documents,
            {
              file,
            },
          ],
        });

        toast.success('อัปโหลดไฟล์สำเร็จ');

        reGetDetailAsync(planProcurement.id);
      }
    } else {
      if (!planProcurement.documents) {
        planProcurement.documents = [];
      }

      setPlanProcurement({
        ...planProcurement,
        documents: [
          ...planProcurement.documents,
          {
            file,
          },
        ],
      });
    }
  };

  const files = useCallback(() => {
    const files = planProcurement.documents?.filter((d) => d.id && d.name);

    return files?.map((f) => ({
      id: f.id,
      name: f.name,
      attachmentBy: f.byUserId,
    })) as FileValue[];
  }, [planProcurement.documents]);

  const removeFileAsync = async (i: number, id: string | undefined) => {
    if (id) {
      const res = await planProcurementService.removeFileAsync(planProcurement.id, id);

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
      }
    }

    planProcurement.documents.splice(i, 1);

    setPlanProcurement({
      ...planProcurement,
      documents: [...planProcurement.documents],
    });
  };

  const removeJorporDirectorAssign = (userId: string, level: number) => {
    const index = planProcurement.jorporDirectorAssign
      .findIndex((d) => d.userId === userId && d.level === level);

    planProcurement.jorporDirectorAssign.splice(index, 1);

    setPlanProcurement({
      ...planProcurement,
      jorporDirectorAssign: [...planProcurement.jorporDirectorAssign],
    });
  };

  const downloadFileAsync = async (index: number, documentId?: string) => {
    if (documentId) {
      const res = await planProcurementService
        .downloadFileAsync(planProcurement.id, documentId);

      if (res.status === HttpStatusCode.OK) {
        const file = planProcurement.documents[index];

        downloadFile(res.data);
      }
    } else {
      const file = planProcurement.documents[index];

      if (file.file) {
        downloadFile(file.file);
      }
    }
  };

  const canAddJorPorAssign = useMemo(() => {
    if (!planProcurement || (currentStep !== 3 && currentStep !== 4) || !userId) {
      return false;
    }

    if (!overLimitPrice) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.Assigned
      && planProcurement.status !== PlanStatusCons.DraftRecordDocument) {
      return false;
    }

    // if (!planProcurement.jorporDirectorBeInformed?.some(j => j.userId === userId) &&
    //   !planProcurement.jorporDirectorAssign?.some(j => j.userId === userId)) {
    //   console.log('5')
    //   return false;
    // }

    if (!planProcurement.jorporDirectorBeInformed?.some((s) => isNull(s.delegateUserId, s.userId) === userId && !s.isAvailable) &&
      !planProcurement.jorporDirectorAssign?.some((s) => isNull(s.delegateUserId, s.userId) === userId && !s.isAvailable)) {
      return false;
    }

    return true;
  }, [planProcurement, userId, currentStep]);

  function isNull(oldValue?: string, newValue?: string) {
    if (oldValue) {
      return oldValue;
    }

    return newValue;
  }

  const canRemoveJorPorAssign = useMemo(() => {
    if (!planProcurement || (currentStep !== 3 && currentStep !== 4) || !userId) {
      return false;
    }

    if (!overLimitPrice) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.Assigned
      && planProcurement.status !== PlanStatusCons.DraftRecordDocument) {
      return false;
    }

    if (!planProcurement.jorporDirectorBeInformed?.some(j => isNull(j.delegateUserId, j.userId) === userId) &&
      !planProcurement.jorporDirectorAssign?.some(j => isNull(j.delegateUserId, j.userId) === userId)) {
      return false;
    }

    return true;
  }, [planProcurement, userId, currentStep]);

  const canChangeAndCancel = useMemo(() => {
    if (!Object.keys(planProcurement).length || !userId || currentStep !== 3) {
      return false;
    }

    if ((planProcurement.status !== PlanStatusCons.Announcement && planProcurement.budget > 500000) || planProcurement.status === PlanStatusCons.Assigned) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (planProcurement.isCanceled) {
      return false;
    }

    if (planProcurement.isRefChange || planProcurement.isRefCancel) {
      return false;
    }

    return true;
  }, [planProcurement, userId, currentStep]);

  const canSave = useMemo(() => {
    if (!overLimitPrice) {
      return false;
    }

    if ((planProcurement.jorporDirectorBeInformed?.filter((x) => isNull(x.delegateUserId, x.userId) === userId).length > 0)
      || isJorporDirectorAssignLevel0() || isJorporDirectorAssignLevel1()) {
      return true;
    }

    if (planProcurement.status === PlanStatusCons.DraftRecordDocument
      && isJorporDirectorAssignLevel1()) {
      return true;
    }

    return false;
  }, [planProcurement, currentStep, userId, overLimitPrice]);

  const canAssign = useMemo(() => {
    if (!planProcurement || currentStep !== 3 || !userId) {
      return false;
    }

    if (!overLimitPrice) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.Assigned) {
      return false;
    }

    if (!isJorporDirectorAssignLevel0()) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId, overLimitPrice]);

  function isJorporDirectorAssignLevel0() {
    const assignLevel0 = planProcurement?.jorporDirectorAssign?.filter((d) => d.level === AssignLevel.Level0);

    if (assignLevel0
      && assignLevel0.length > 0) {
      return !!assignLevel0.some((j) => isNull(j.delegateUserId, j.userId) === userId);
    }
    return false;
  }

  function isJorporDirectorAssignLevel1() {
    const assignLevel1 = planProcurement?.jorporDirectorAssign?.filter((d) => d.level === AssignLevel.Level1);

    if (assignLevel1
      && assignLevel1.length > 0) {
      return !!assignLevel1.some((j) => isNull(j.delegateUserId, j.userId) === userId);
    }
    return false;
  }

  return (
    <>
      {overLimitPrice ?
        <>
          <Card className='mt-3'>
            {/* Title */}
            <div className='d-flex align-items-center gap-3'>
              <Title
                text='ผอ.จพ. มอบหมาย'
                className='fs-5 text-primary'
              />
              {collapsed
                ? (
                  <FaChevronCircleDown
                    className='text-primary fs-5 cursor-pointer'
                    onClick={() => setCollapsed(false)}
                  />
                ) : (
                  <FaChevronCircleUp
                    className='text-primary fs-5 cursor-pointer'
                    onClick={() => setCollapsed(true)}
                  />
                )}
            </div>

            <Collapse in={!collapsed}
              className='mt-3'>
              <div>
                {overLimitPrice ? (
                  <div>
                    <Row>
                      <Col sm={12}
                        lg={4}>
                        <Selector
                          label='มอบหมายส่วน'
                          disabled={!canEdit}
                          rule={{ required: true }}
                          items={loader.assignDepartments}
                          onChange={(val) => handlerOnChange('assignDepartment', val)}
                          value={planProcurement.assignDepartment}
                          name='assignDepartment'
                        />
                      </Col>
                      <Col sm={12}
                        lg={4}>
                        <Input
                          label='เลขกลุ่ม e-GP'
                          textCenter
                          onChange={(val) => handlerOnChange('groupEgpNumber', val)}
                          value={planProcurement.groupEgpNumber}
                          name='groupEgpNumber'
                          placeholder='M๖๖๑๑๐๐๐๑๑๑๑'
                        />
                      </Col>
                      <Col sm={12}
                        lg={4}>
                        <Input
                          label='เลขที่ e-GP'
                          textCenter
                          onChange={(val) => handlerOnChange('egpNumber', val)}
                          value={planProcurement.egpNumber}
                          name='egpNumber'
                          placeholder='P๖๖๑๑๐๐๐๘๘๘๘'
                        />
                      </Col>
                    </Row>
                    {canAddJorPorAssign ?
                      <div className='d-flex justify-content-end'>
                        <Button
                          className='d-flex align-items-center gap-2'
                          variant='outline-primary'
                          onClick={() => setShowUserModal(true)}
                          disabled={canCheckJorPorAssign}
                        >
                          <FaPlus />เพิ่มรายชื่อ
                        </Button>
                      </div> : null}
                    <Table
                      hidePagination
                      className='mt-3 step-bar'
                      notResponse
                    >
                      <thead>
                        <tr>
                          <th style={{ width: '5%' }}>ลำดับ</th>
                          <th className='text-start'
                            style={{ width: '30%' }}>ชื่อ-นามสกุล
                          </th>
                          <th className='text-start'
                            style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                          </th>
                          <th style={{ minHeight: 100, width: '25%' }} />
                        </tr>
                      </thead>
                      <tbody>
                        {planProcurement.jorporDirectorAssign?.filter((x) => x.level === AssignLevel.Level0)
                          .map((data, i) => (
                            <>
                              <tr key={data.id}>
                                <td className='text-center'>{i + 1}</td>
                                <td>
                                  {data.fullName}
                                  <p className='m-0 department'>
                                    {data.positionName}
                                    {/* {data.departmentName &&
                                <>
                                  <span className='mx-1'>|</span> {data.departmentName}
                                </>
                              } */}
                                  </p>
                                </td>
                                <td className='text-left'>
                                  {data.delegateFullName &&
                                    <>
                                      {data.delegateFullName}
                                      <p className='m-0 department'>
                                        {data.delegatePositionName}
                                      </p>
                                    </>
                                  }
                                </td>
                                <td className='text-end'>
                                  {canRemoveJorPorAssign ?
                                    <Button
                                      variant='danger'
                                      onClick={() => removeJorporDirectorAssign(data.userId, data.level)}
                                      disabled={canCheckJorPorAssign || data.userId === userId}>
                                      <FaTrashAlt />
                                    </Button>
                                    : null}
                                </td>
                              </tr>
                              {data.isAvailableRemark || data.acceptRemark || data.rejectRemark ?
                                <tr className='border-bottom bg-opacity-10 bg-light'>
                                  <td />
                                  <td colSpan={5}
                                    className='p-0'>
                                    <ul className='step-bar-list'>
                                      {data.isAvailableRemark &&
                                        <li>
                                          <div className='description'>
                                            <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                            <p className='mb-0'
                                              style={{ color: 'gray' }}>
                                              {data.isAvailableRemark}
                                            </p>
                                          </div>
                                        </li>
                                      }
                                      {data.acceptRemark &&
                                        <>
                                          <li>
                                            <div className='description'>
                                              <p className='mb-0 text-primary'>หมายเหตุเห็นชอบ</p>
                                              <p className='mb-0'
                                                style={{ color: 'gray' }}>
                                                <span className='text-muted me-2'>[{fullDatetime(data.acceptDate)} ]</span>
                                                {data.acceptRemark}
                                              </p>
                                            </div>
                                          </li>
                                        </>
                                      }
                                      {data.rejectRemark &&
                                        <li>
                                          <div className='description'>
                                            <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                            <p className='mb-0'
                                              style={{ color: 'gray' }}>
                                              <span className='text-muted me-2'>[{fullDatetime(data.rejectDate)} ]</span>
                                              {data.rejectRemark}
                                            </p>
                                          </div>
                                        </li>
                                      }
                                    </ul>
                                  </td>
                                </tr>
                                : <tr className='border-bottom' />}
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            </Collapse>
          </Card>
        </> : null
      }

      {overLimitPrice ?
        <>
          <Card className='mt-3'>
            {/* Title */}
            <div className='d-flex align-items-center gap-3'>
              <Title
                text='ผู้รับผิดชอบ'
                className='fs-5 text-primary'
              />
              {collapsed2
                ? (
                  <FaChevronCircleDown
                    className='text-primary fs-5 cursor-pointer'
                    onClick={() => setCollapsed2(false)}
                  />
                ) : (
                  <FaChevronCircleUp
                    className='text-primary fs-5 cursor-pointer'
                    onClick={() => setCollapsed2(true)}
                  />
                )}
            </div>

            <Collapse in={!collapsed2}
              className='mt-3'>
              <div>
                {overLimitPrice ? (
                  <div>
                    {canAddJorPorAssign ?
                      <div className='d-flex justify-content-end'>
                        <Button
                          className='d-flex align-items-center gap-2'
                          variant='outline-primary'
                          onClick={() => setShowUserModal2(true)}
                          disabled={!canSave}
                        >
                          <FaPlus />เพิ่มรายชื่อ
                        </Button>
                      </div> : null}
                    <Table
                      hidePagination
                      className='mt-3 step-bar'
                      notResponse
                    >
                      <thead>
                        <tr>
                          <th style={{ width: '5%' }}>ลำดับ</th>
                          <th className='text-start'
                            style={{ width: '30%' }}>ชื่อ-นามสกุล
                          </th>
                          <th className='text-start'
                            style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                          </th>
                          <th style={{ minHeight: 100, width: '25%' }} />
                        </tr>
                      </thead>
                      <tbody>
                        {planProcurement.jorporDirectorAssign?.filter((x) => x.level === AssignLevel.Level1)
                          .map((data, i) => (
                            <>
                              <tr key={data.id}>
                                <td className='text-center'>{i + 1}</td>
                                <td>
                                  {data.fullName}
                                  <p className='m-0 department'>
                                    {data.positionName}
                                  </p>
                                </td>
                                <td className='text-left'>
                                  {data.delegateFullName &&
                                    <>
                                      {data.delegateFullName}
                                      <p className='m-0 department'>
                                        {data.delegatePositionName}
                                      </p>
                                    </>
                                  }
                                </td>
                                <td className='text-end'>
                                  {canRemoveJorPorAssign ?
                                    <Button
                                      variant='danger'
                                      disabled={!canSave}
                                      onClick={() => removeJorporDirectorAssign(data.userId, data.level)}>
                                      <FaTrashAlt />
                                    </Button>
                                    : null}
                                </td>
                              </tr>
                              {data.isAvailableRemark || data.acceptRemark || data.rejectRemark ?
                                <tr className='border-bottom bg-opacity-10 bg-light'>
                                  <td />
                                  <td colSpan={5}
                                    className='p-0'>
                                    <ul className='step-bar-list'>
                                      {data.isAvailableRemark &&
                                        <li>
                                          <div className='description'>
                                            <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                            <p className='mb-0'
                                              style={{ color: 'gray' }}>
                                              {data.isAvailableRemark}
                                            </p>
                                          </div>
                                        </li>
                                      }
                                      {data.acceptRemark &&
                                        <>
                                          <li>
                                            <div className='description'>
                                              <p className='mb-0 text-primary'>หมายเหตุเห็นชอบ</p>
                                              <p className='mb-0'
                                                style={{ color: 'gray' }}>
                                                <span className='text-muted me-2'>[{fullDatetime(data.acceptDate)} ]</span>
                                                {data.acceptRemark}
                                              </p>
                                            </div>
                                          </li>
                                        </>
                                      }
                                      {data.rejectRemark &&
                                        <li>
                                          <div className='description'>
                                            <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                            <p className='mb-0'
                                              style={{ color: 'gray' }}>
                                              <span className='text-muted me-2'>[{fullDatetime(data.rejectDate)} ]</span>
                                              {data.rejectRemark}
                                            </p>
                                          </div>
                                        </li>
                                      }
                                    </ul>
                                  </td>
                                </tr>
                                : <tr className='border-bottom' />}
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            </Collapse>
          </Card>
        </> : null
      }

      {/* Upload File */}
      {
        currentStep === 3
          ? (
            <Card className='mt-3'>
              <Title text='เอกสารแนบ'
                className='fs-5 text-primary' />
              <Row className='justify-content-center'>
                <Col sm={12}
                  lg={6}>
                  <UploadFile
                    onChange={uploadFileOnChangeAsync}
                    value={files()}
                    onRemove={removeFileAsync}
                    onDownload={downloadFileAsync}
                    canRemoveFile={canRemoveFile}
                    disabled={readonly}
                  />
                </Col>
              </Row>
            </Card>
          ) : null
      }

      {/* Button */}
      {
        currentStep === 3
          ? (
            <div className='d-flex justify-content-between mt-3'>
              <ButtonCustom
                onClick={props.onClickBack}
                text="กลับหน้าแรก"
              />
              <div className='d-flex gap-2'>
                {canRework ? (
                  <ButtonCustom
                    text="ส่งกลับแก้ไข"
                    icon='undo'
                    variant='danger'
                    onClick={() => setShowRejectModal(true)}
                  />
                )
                  : null}
                {canSave ? (
                  <ButtonCustom
                    text="บันทึก"
                    onClick={() => handlerOnSubmitAsync(PlanStatusCons.Assigned, false)}
                  />
                ) : null}
                {canAssign
                  ? (
                    <ButtonCustom
                      text="ส่งมอบหมายงาน"
                      icon='checked'
                      onClick={() => setShowPendingModal(true)}
                    />
                  ) : null}
                {canChangeAndCancel
                  ? (
                    <div className='d-flex gap-2'>
                      <ButtonCustom
                        text="ขอเปลี่ยนแปลง"
                        icon='undo'
                        onClick={() => setShowChangeModal(true)}
                      />
                      <ButtonCustom
                        variant='danger'
                        text="ขอยกเลิก"
                        icon='cancel'
                        onClick={() => setShowCancelModal(true)}
                      />
                    </div>
                  ) : null}
              </div>
              <div style={{ width: 100 }} />
            </div>
          ) : null
      }

      {/* Modal */}
      <RejectModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(false)}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showUserModal}
        onHide={() => setShowUserModal(!showUserModal)}
        onSelect={(id, name, department, position) => userOnSelect(id, name, department!, position!, AssignLevel.Level0)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showUserModal2}
        onHide={() => setShowUserModal2(!showUserModal2)}
        onSelect={(id, name, department, position) => userOnSelect(id, name, department!, position!, AssignLevel.Level1)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <ChangeModal
        show={showChangeModal}
        onHide={() => setShowChangeModal(false)}
      />
      <CancelModal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
      />
      <Modal
        show={showPendingModal}
        size='lg'
        onHide={() => setShowPendingModal(!showPendingModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งมอบหมาย</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งมอบหมายงาน ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingModal(!showPendingModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerOnSubmitAsync(PlanStatusCons.DraftRecordDocument, true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

function SearchUserModal(props: {
  show: boolean;
  level?: number;
  onHide: () => void;
  onSelect: (id: string, fullName: string, departmentName: string, positionName: string, level: number) => void;
}) {
  const { planProcurement } = useContext(Context);
  const loader = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<UserCriteria>({} as UserCriteria);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (props.show) {
      setSize(10);
      setPage(1);
      clearCriteria();
      getUserAsync(10, 1);
    }
  }, [props.show]);

  const handlerOnChange = (key: string, value: string) => {
    setCriteria({
      ...criteria,
      [key]: value,
    });
  };

  const onSelect = (id: string) => {
    if (planProcurement.jorporDirectorAssign?.some((d) => d.userId === id && d.level === props.level)) {
      toast.warn('ข้อมูลซ้ำ');

      return;
    }

    const user = users.find((a) => a.id === id);

    if (user) {
      props.onSelect(
        user.id,
        user.name,
        user.department,
        user.position,
        props.level ? props.level : 0,
      );
      props.onHide();
    }
  };

  const getUserAsync = async (size: number, page: number) => {
    const res = await planProcurementService
      .getUsersAsync(size, page, criteria.name, criteria.departmentId, criteria.positionId);

    if (res.status === HttpStatusCode.OK) {
      setUsers(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }
  };

  const clearCriteria = () => {
    setCriteria({} as UserCriteria);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='xl'
    >
      <Modal.Header>
        <Title
          text='รายชื่อผู้รับมอบหมายงาน'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col sm={6}
          lg={4}>
          <Input
            label='ชื่อ-นามสกุล'
            value={criteria.name}
            onChange={(val) => handlerOnChange('name', val)}
          />
        </Col>
        <Col sm={6}
          lg={4}>
          <Selector
            label='ฝ่าย/สำนัก'
            value={criteria.departmentId}
            onChange={(val) => handlerOnChange('departmentId', val)}
            items={loader.departments}
          />
        </Col>
        <Col sm={6}
          lg={4}>
          <Selector
            label='ตำแหน่ง'
            value={criteria.positionId}
            onChange={(val) => handlerOnChange('positionId', val)}
            items={loader.positions}
          />
        </Col>
      </Row>
      <div className='d-flex gap-2'>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => getUserAsync(size, page)}
        >
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant='outline-primary'
          className='d-flex align-items-center gap-2'
          onClick={clearCriteria}
        >
          <FaEraser />ล้าง
        </Button>
      </div>
      <Table
        className='mt-3'
        total={totalRecords}
        onChange={(size, page) => {
          setSize(size);
          setPage(page);
          getUserAsync(size, page);
        }}
      >
        <thead>
          <tr>
            <th style={{ minWidth: 20 }}>ลำดับ</th>
            <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
            <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
            <th style={{ minWidth: 25 }} />
          </tr>
        </thead>
        <tbody>
          {
            users.map((data, i) => (
              <tr key={data.id}>
                <td className='text-center'>{calculateRowNumber(i, page, size)}</td>
                <td>{data.name}</td>
                <td>{data.department}</td>
                <td>{data.position}</td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Button variant='primary'
                      onClick={() => onSelect(data.id)}>
                      เลือก
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Modal>
  );
}

function ChangeModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement } = useContext(Context);
  const [remark, setRemark] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (!remark) {
      return;
    }

    const res = await planProcurementService.changeAsync(
      planProcurement,
      remark,
    );

    if (res.status === HttpStatusCode.CREATED) {
      toast.success('เปลี่ยนแปลงสำเร็จ');
      navigate('/procurement-plan-adjust');

      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
    >
      <Modal.Header>
        <Title
          text='ขอเปลี่ยนแปลง'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label='เหตุผล'
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name='remark'
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-end gap-2'>
        <Button
          variant='outline-primary'
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant='primary'
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function CancelModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement } = useContext(Context);
  const [remark, setRemark] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (!remark) {
      return;
    }

    const res = await planProcurementService.cancelAsync(
      planProcurement,
      remark,
    );

    if (res.status === HttpStatusCode.CREATED) {
      toast.success('ยกเลิกสำเร็จ');
      navigate('/procurement-plan-cancel');

      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
    >
      <Modal.Header>
        <Title
          text='ขอยกเลิก'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label='เหตุผล'
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name='remark'
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-end gap-2'>
        <Button
          variant='outline-primary'
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant='primary'
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function RejectModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement, reGetDetailAsync, setCurrentStep } = useContext(Context);
  const [remark, setRemark] = useState<string>();
  const { userId, departmentId, departmentCode } = useAppContext();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);
    if (!remark) {
      return;
    }

    const approverDirectorBeInformed = planProcurement.jorporDirectorBeInformed?.find((d) => isNull(d.delegateUserId, d.userId) === userId);
    const approverDirectorAssign = planProcurement.jorporDirectorAssign?.find((d) => isNull(d.delegateUserId, d.userId) === userId);
    let approverId = '';

    if (approverDirectorBeInformed) {
      approverId = approverDirectorBeInformed.id
    } else if (approverDirectorAssign) {
      approverId = approverDirectorAssign.id
    }

    if (approverId !== '') {
      const res = await planProcurementService
        .rejectAsync(
          planProcurement.id,
          approverId,
          PlanStatusCons.RejectPlan,
          remark,
        );

      if (res.status === HttpStatusCode.ACCEPTED) {
        reGetDetailAsync(planProcurement.id);
        toast.success('ส่งกลับแก้ไขสำเร็จ');

        props.onHide();
        if (planProcurement.departmentId === departmentId) {
          setCurrentStep(1);
        }
      }
    }

  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
    >
      <Modal.Header>
        <Title
          text='ส่งกลับแก้ไข'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label='เหตุผล'
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name='remark'
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-end gap-2'>
        <Button
          variant='outline-primary'
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant='primary'
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}
