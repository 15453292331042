import { DatePicker } from 'components';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import {
  CreatePlanAnnouncement,
  PlanAnnouncementListResponseModel,
  RejectPlanAnnouncement,
} from 'models/planAnnouncement';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaAlignLeft, FaUndo } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router';
import planAnnouncement from 'services/planAnnouncement';
import { StatusName } from 'utils/constants/planStatus';
import toast from 'utils/toast';
import Collabora, { CollaboraRef } from '../../../../components/Document/Collabora';
import { IReplaceText } from '../../../../models/CollaboraModel';
import { HttpStatusCode } from '../../../../utils';
import { useForm } from '../../../../utils/useForm';

interface Props {
  onClickBack: () => void;
  planIds: string[];
  data: PlanAnnouncementListResponseModel;
}

interface FormInput {
  publishTitle: string;
}

function PL0302Document({ onClickBack, planIds, data }: Props) {
  const navigate = useNavigate();
  const formId = 'pl0302_announcement';
  const [publishDate, setPublishDate] = useState<Date>(data.plans[0].publishDate!);
  const [showEditReqeustModal, setShowEditReqeustModal] = useState(false);
  const [showConfirmAnnouncement, setShowConfirmAnnouncement] = useState(false);
  const [docId, setDocId] = useState<string>('');
  const collaboraRef = useRef<CollaboraRef>(null);

  const { handleSubmit, handleChange, data: formData, errors } = useForm<FormInput>({
    initialValues: {
      publishTitle: data.plans[0].publishTitle,
    },
    validations: {},
    onSubmit: async () => {
      if (publishDate) {
        setShowConfirmAnnouncement(true);
      }
    },
  });

  const announcementAsync = async () => {
    collaboraRef.current?.clickSave();

    const createAnnouncementDto: CreatePlanAnnouncement = {
      planIds,
      publishDate: publishDate!.toISOString()
        .split('T')[0],
      publishTitle: formData.publishTitle,
    };

    const { status } = await planAnnouncement.announcementAsync(createAnnouncementDto);

    setShowConfirmAnnouncement(false);

    if (status !== 204) {
      toast.error('เกิดข้อผิดพลาด');
      return;
    }

    toast.success('ประกาศผลแผนสำเร็จ');
    navigate('/pl/pl0302');
  };

  useEffect(() => {
    createMainDocument();
  }, []);

  const createMainDocument = useCallback(async () => {
    // TODO: Check the template name and list to replace
    const templateName = 'template_plan_announcement.docx';
    const replaceList: IReplaceText = { publishTitle: 'แผนจัดซื้อจัดจ้าง', publishDate: 'แผนจัดซื้อจัดจ้าง' };
    const {
      data: docId,
      status,
    } = await planAnnouncement.createMainDocumentAsync(planIds, templateName, replaceList);

    if (status === HttpStatusCode.OK) {
      setDocId(docId);
    }
  }, []);

  return (
    <div>
      <Card className='mt-3'>
        <Card.Body>
          <Title text='ประกาศเผยแพร่ประกาศแผนจัดซื้อจัดจ้าง'
            className='fs-5 text-primary' />
          <Form id={formId}
            onSubmit={handleSubmit}>
            <Row className='criteria mb-3 mt-3'>
              <Col sm={12}
                md={8}
                lg={9}
                xl={9}>
                <Form.Group>
                  <Form.Label>ชื่อประกาศ</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type='text'
                      maxLength={100}
                      value={formData.publishTitle || ''}
                      required
                      readOnly={data.plans[0].status! === StatusName.ANNOUNCEMENT}
                      onChange={handleChange<string>('publishTitle', (value) => value)}
                    />
                  </InputGroup>
                </Form.Group>
                {errors.publishTitle && <p className='error fs-7'>{errors.publishTitle}</p>}
              </Col>
              <Col sm={12}
                md={4}
                lg={3}
                xl={3}>
                <DatePicker
                  value={publishDate}
                  label='วันที่เผยแพร่'
                  disabled={data.plans[0].status! === StatusName.ANNOUNCEMENT}
                  onChange={setPublishDate}
                  rule={{ required: true }}
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col>
              <Card style={{ height: 500 }}>
                <Collabora
                  docId={docId}
                  docName='doc-01'
                  readonly={false} />
              </Card>
            </Col>
          </Row>
          <div className='d-flex justify-content-center pt-5 gap-3'>
            <Button
              onClick={onClickBack}
              variant='light'
            >
              <MdArrowBack />ย้อนกลับ
            </Button>
            <Button
              onClick={() => {
                setShowEditReqeustModal(true);
                collaboraRef.current?.clickSave();
              }}
              variant='danger'
            >
              <FaUndo />ส่งกลับแก้ไข
            </Button>
            <Button
              onClick={() => setShowConfirmAnnouncement(true)}
              variant='primary'
              type='submit'
              disabled={data.plans[0].status! === StatusName.ANNOUNCEMENT}
              form={formId}
            >
              <FaAlignLeft />เผยแพร่
            </Button>
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={showConfirmAnnouncement}
        onHide={() => setShowConfirmAnnouncement(false)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ประกาศผลแผน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>คุณต้องการประกาศผลแผนใช่หรือไม่ ?</ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='light'
                onClick={() => setShowConfirmAnnouncement(false)}>
                ยกเลิก
              </Button>
              <Button variant='primary'
                type='button'
                onClick={announcementAsync}>
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <EditRequestModal
        planIds={planIds}
        show={showEditReqeustModal}
        status={data.plans[0].status}
        setShow={setShowEditReqeustModal}
      />
    </div>
  );
}

interface ModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  planIds: string[];
  status: string;
}

interface FormReject {
  rejectReason: string;
}

function EditRequestModal({ show, setShow, planIds, status }: ModalProps) {
  const navigate = useNavigate();
  const formId = 'pl0302_announcement_reject';

  const { handleSubmit, handleChange, data: formData } = useForm<FormReject>({
    validations: {},
    onSubmit: async () => {
      rejectAsync();
    },
  });

  const rejectAsync = async () => {
    const rejectAnnouncementDto: RejectPlanAnnouncement = {
      planIds,
      rejectReason: formData.rejectReason,
    };

    const { status } = await planAnnouncement.rejectAsync(rejectAnnouncementDto);

    if (status !== 204) {
      toast.error('เกิดข้อผิดพลาด');
      return;
    }

    toast.success('ส่งกลับแก้ไขสำเร็จ');
    navigate('/pl/pl0302');
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(!show)}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4'>
            <Form onSubmit={handleSubmit}
              id={formId}>
              <Form.Group>
                <Form.Label>เหตุผล</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as='textarea'
                    required
                    readOnly={status! === StatusName.REJECTED}
                    rows={5}
                    value={formData.rejectReason || ''}
                    onChange={handleChange<string>('rejectReason', (value) => value)}
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </ModalBT.Body>
          <ModalBT.Footer>
            <Button
              variant='light'
              onClick={() => setShow(!show)}
            >
              ยกเลิก
            </Button>
            <Button
              variant='primary'
              type='submit'
              form={formId}
              disabled={status! === StatusName.REJECTED}
            >
              ยืนยัน
            </Button>
          </ModalBT.Footer>
        </>
      )}
    />
  );
}

export default PL0302Document;
