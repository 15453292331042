import {
  Input,
  Selector,
  Table,
} from 'components';
import { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { useForm } from '../../../utils/useForm';

export default function PL01() {
  return (
    <div className="m01">
      <P0201Header />
      <Criteria />
      <DataTable />
    </div>
  );
}

function P0201Header() {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">รายงานขอซื้อขอจ้าง ( จพ.005 )</h4>
      </div>
      <hr />
    </>
  );
}

interface FormInput {
  code: string;
  department: string;
  name: string;
  budget: string;
  createBy: string;
  typeAll: string;
  type80: string;
  type60: string;
  statusAll: string;
  statusDraft: string;
  statusPending: string;
  statusApprove: string;
  statusReject: string;
}

function Criteria() {
  const [program, setProgram] = useState('');
  const { handleSubmit, data: formData, errors, handleClear } = useForm<FormInput>({
    validations: {

    },
    onSubmit: async () => { console.log(formData); },
  });

  return (
    <Form onSubmit={handleSubmit} id="formPL01">
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="โครงการ" placeholder="โครงการ" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="เรื่อง" placeholder="เรื่อง" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector label="ฝ่าย/สำนัก" value={program} onChange={(value) => setProgram(value.toString())} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="ผู้จัดทำ" placeholder="ผู้จัดทำ" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector label="สถานะ" value={program} onChange={(value) => setProgram(value.toString())} />
        </Col>

        <div className="d-flex gap-2">
          <Button form="formPL01" type="submit" variant="primary" className="d-flex align-items-center gap-2">
            <FaSearch />ค้นหา
          </Button>
          <Button variant="outline-primary" onClick={handleClear} className="d-flex align-items-center gap-2">
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
    </Form>
  );
}

function DataTable() {
  return (
    <Table className="mt-4">
      <thead>
        <tr className="text-center">
          <th>ลำดับ</th>
          <th>ชื่อโครงการ</th>
          <th>เรื่อง</th>
          <th>ฝ่าย/สำนัก</th>
          <th>ผู้จัดทำ</th>
          <th>วันที่จัดทำ</th>
          <th>สถานะ</th>
        </tr>
      </thead>
      <tbody>
        <tr className="text-center">
          <td>1</td>
          <td><a href="/p/p0201/detail" className="btn-link">1100010495</a> : โครงการ Digital Procurement</td>
          <td>รายงานขอเช่าสัญญาณสมาชิก True Vision</td>
          <td> บช. : ฝ่ายการบัญชี</td>
          <td>Pratya</td>
          <td>03/2566</td>
          <td>ส่งอนุมัติ</td>
        </tr>
      </tbody>
    </Table>
  );
}
