const nationalityLists = [
  { nationality: 'th', value: 'ไทย' },
  { nationality: 'en', value: 'อังกฤษ' },
  { nationality: 'jp', value: 'ญี่ปุ่น' },
  { nationality: 'foreign', value: 'ต่างชาติ' },
];

export const convertNationalToThWord = (nationality: string) => {
  if (nationality) {
    const getNationality = nationalityLists.find((n) => n.nationality === nationality);

    return getNationality?.value ?? 'ไม่พบสัญชาติ';
  }

  return '';
};
