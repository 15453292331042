import { Modal } from 'components';
import { IFile } from 'models';
import {
  useCallback,
  useState,
} from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import CM06Service from 'services/CM/CM06Service';
import { HttpStatusCode } from 'utils';
import toast from 'utils/toast';
import SingleUploadFile from '../SingleUploadFile';

interface Props {
  show: boolean;
  onHide: () => void;
  contractReceiveCollateralId: string;
  channelId: string;
  // onUploaded: (file: File[], index: number) => void;
  onUploaded: () => void;
  index: number;
}

export function UploadDocumentModal({ show, onHide, contractReceiveCollateralId, channelId, onUploaded, index }: Props) {
  const [files, setFiles] = useState<IFile[]>([]);

  const handleFileChange = async (file: File) => {
    const saveFiles: File[] = [];
    const newFile: IFile = {
      id: '',
      name: file.name,
      file,
    };

    saveFiles.push(file);

    if (contractReceiveCollateralId !== undefined && saveFiles.length > 0) {
      const res = await CM06Service.uploadReceiveChannelAttachmentDocumentAsync(
        contractReceiveCollateralId,
        channelId,
        saveFiles,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        onUploaded();
      } else {
        onHide();
      }

    }
  };

  // const handleFileChange = async (file: File) => {

  //   let saveFiles: File[] = [];
  //   const newFile: IFile = {
  //     id: '',
  //     name: file.name,
  //     file,
  //   };

  //   saveFiles.push(file);
  //   onUploaded(saveFiles, index)

  // };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {

    // if (contractData && contractData.id) {
    //   const res = await CM02Service.removeFileAsync(
    //     contractData.id,
    //     docId,
    //   );

    //   if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
    //     toast.success('ลบไฟล์สำเร็จ');
    //     getContractByIdAsync(contractData.id);
    //   }
    // }

  }, [files, contractReceiveCollateralId, channelId]);

  return (
    <Modal
      show={show}
      size='lg'
      onHide={() => onHide()}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>เพิ่มไฟล์</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='pt-4'>
            <SingleUploadFile
              disabled={false}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={contractReceiveCollateralId}
              channelId={channelId}
            />
          </ModalBT.Body>
        </>
      )}
    />
  );
}
