import {
  useEffect,
  useState,
} from 'react';
import Badge from 'react-bootstrap/Badge';

interface Props {
  value: string;
}

const STATUS = [
  { value: 'None', color: '#B7B7B7', label: 'ยังไม่ได้ดำเนินการ' },
  { value: 'Draft', color: '#B7B7B7', label: 'แบบร่าง' },
  { value: 'Pending', color: '#FBBD01', label: 'รอเห็นชอบ/รออนุมัติ' },
  { value: 'Approved', color: '#3CB371', label: 'เห็นชอบ/อนุมัติ' },
  { value: 'Rejected', color: '#E94F45', label: 'ส่งกลับแก้ไข' },
  { value: 'Cancelled', color: '#E94F45', label: 'ยกเลิก' },
  { value: 'Contract', color: '#FBBD01', label: 'บริหารสัญญา' },
  { value: 'WaitingForDelivery', color: '#B7B7B7', label: 'รอส่งมอบ' },
  { value: 'Delivered', color: '#FBBD01', label: 'รอตรวจรับ' },
  { value: 'Checked', color: '#5946ff', label: 'รอผู้มีอำนาจเห็นชอบ' },
  { value: 'Acknowledged', color: '#5946ff', label: 'รอตั้งหนี้ตั้งเบิก' },
  { value: 'Paid', color: '#3CB371', label: 'ตั้งหนี้ตั้งเบิก' },
];

const STATUS_DETAIL = [
  { value: 'None', color: '#secondary', label: 'ยังไม่ได้ดำเนินการ' },
  { value: 'Draft', color: 'secondary', label: 'ร่างเห็นชอบ/อนุมัติ' },
  { value: 'Pending', color: 'warning', label: 'รอเห็นชอบ/อนุมัติ' },
  { value: 'Approved', color: 'success', label: 'เห็นชอบ/อนุมัติ' },
  { value: 'Rejected', color: 'danger', label: 'ส่งกลับแก้ไข' },
];

const invitedContractStatus = [
  { value: 'None', color: '#B7B7B7', label: 'ยังไม่ได้ดำเนินการ' },
  { value: 'Draft', color: '#B7B7B7', label: 'แบบร่าง' },
  { value: 'WaitingForApprove', color: '#FBBD01', label: 'รอเห็นชอบ/รออนุมัติ' },
  { value: 'Invited', color: '#3CB371', label: 'เห็นชอบ/อนุมัติ' },
  { value: 'Rejected', color: '#E94F45', label: 'ส่งกลับแก้ไข' },
];

function ContractStatus(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);
  return (
    <>
      {props.value
        ? (
          <div className="directive-status" style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

function ContractStatusDetail(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS_DETAIL.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color} className="text-white">
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}

function InvitedContractStatusDetail(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = invitedContractStatus.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);
  return (
    <>
      {props.value
        ? (
          <div className="directive-status" style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

export { ContractStatusDetail, ContractStatus, InvitedContractStatusDetail };
