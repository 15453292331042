import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  ButtonCustom,
  Check,
  ContractCompleteReportStatus,
  Input,
  Selector,
  Status,
  StatusCM,
  StatusType,
  Table,
} from '../../../components';
import Title from '../../../components/Controls/Title';
import { ItemModel } from '../../../models';
import { useLoaderData, useNavigate } from 'react-router';
import { CountStatusModel, SearchContractListModel, SearchCriteriaModel } from 'models/CM/ContractCompleteReportModel';
import { ContractListResponseModel } from 'models/CM/CM02Models';
import ContractCompleteReportService from 'services/CM/ContractCompleteReportService';
import { HttpStatusCode, THCurrency, fullDate, thaiFormatDate } from 'utils';
import { StatusApprover } from 'components/StatusCMReturnCollateral';
import { FaEraser, FaSearch } from 'react-icons/fa';

type Loader = {
  quarterDDL: ItemModel[],
  budgetYearDDL: ItemModel[],
};

interface StatusItemModel {
  label: string;
  value: boolean;
}

const defaultStatus = [ContractCompleteReportStatus.Draft, ContractCompleteReportStatus.WaitingForApprove, ContractCompleteReportStatus.Rejected, ContractCompleteReportStatus.Approved];

export default function CM05() {
  const {
    quarterDDL,
    budgetYearDDL,
  } = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState<SearchCriteriaModel>({} as SearchCriteriaModel);
  const [dataList, setDataList] = useState<SearchContractListModel[]>([]);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [statusDraft, setStatusDraft] = useState<boolean>(false);
  const [statusWaitingForApprove, setStatusWaitingForApprove] = useState<boolean>(false);
  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [statusApproved, setStatusApproved] = useState<boolean>(false);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    {
      value: false,
      label: ContractCompleteReportStatus.Draft,
    },
    {
      value: false,
      label: ContractCompleteReportStatus.WaitingForApprove,
    },
    {
      value: false,
      label: ContractCompleteReportStatus.Rejected,
    },
    {
      value: false,
      label: ContractCompleteReportStatus.Approved,
    },
  ]);

  useEffect(() => {
    onChangeStatusAll(true);
    setIsResponsible(true);
    getListData(searchData, page, size);
  }, []);

  const getListData = async (searchFrom: SearchCriteriaModel, page: number, size: number) => {

    if (searchFrom.isResponsible === undefined) {
      searchFrom.isResponsible = true;
    }

    if (searchFrom.contractCompleteReportStatusList === undefined || searchFrom.contractCompleteReportStatusList.length === 0) {
      searchFrom.contractCompleteReportStatusList = defaultStatus;
    }

    const { data, status } = await ContractCompleteReportService.getContractCompleteReportListAsync(searchFrom, page, size);
    if (status === HttpStatusCode.OK) {
      setDataList(data.data);
      setTotal(data.totalRecords);

      await getCountStatus(searchFrom);
    }
  }


  const onSearch = (searchFrom: SearchCriteriaModel, page: number, size: number) => {
    const contractCompleteReportStatusList: string[] = [];

    if (statusDraft) {
      contractCompleteReportStatusList.push(ContractCompleteReportStatus.Draft);
    }

    if (statusWaitingForApprove) {
      contractCompleteReportStatusList.push(ContractCompleteReportStatus.WaitingForApprove);
    }

    if (statusRejected) {
      contractCompleteReportStatusList.push(ContractCompleteReportStatus.Rejected);
    }

    if (statusApproved) {
      contractCompleteReportStatusList.push(ContractCompleteReportStatus.Approved);
    }

    const searchCriteria = {
      contractCompleteReportNumber: searchFrom.contractCompleteReportNumber,
      budgetYear: searchFrom.budgetYear,
      quarterId: searchFrom.quarterId,
      contractCompleteReportStatusList,
      isResponsible: isResponsible,
    } as SearchCriteriaModel;

    getListData(searchCriteria, page, size);
  };


  const getCountStatus = useCallback(async (searchModel: SearchCriteriaModel) => {
    const countStatusRes = await ContractCompleteReportService.getCountStatusAsync(searchModel);

    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data);
    }
  }, []);

  const handleSetPagination = (page: number, size: number) => {
    setPage(page);
    setSize(size);
    onSearch(searchData, page, size);
  };

  const onClear = async () => {
    setSearchData({ ...searchData, contractCompleteReportNumber: undefined, budgetYear: undefined, quarterId: undefined });
    const contractCompleteReportStatusList: string[] = [];
    onChangeStatusAll(true);
    setStatusDraft(true);
    setStatusWaitingForApprove(true);
    setStatusRejected(true);
    setStatusApproved(true);
    setIsResponsible(true);
    const searchCriteria = {
      contractCompleteReportNumber: undefined,
      budgetYear: undefined,
      quarterId: undefined,
      contractCompleteReportStatusList,
      isResponsible: true,
    } as SearchCriteriaModel;

    getListData(searchCriteria, 1, 10)
  }

  const goToEditPage = () => {
    navigate('/cm/cm05/detail');
  }

  const goToPage = (id: string) => {
    navigate(`/cm/cm05/detail/${id}`);
  };

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex((s) => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter((s) => !s.value).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case ContractCompleteReportStatus.Draft:
        return setStatusDraft(value);
      case ContractCompleteReportStatus.WaitingForApprove:
        return setStatusWaitingForApprove(value);
      case ContractCompleteReportStatus.Rejected:
        return setStatusRejected(value);
      case ContractCompleteReportStatus.Approved:
        return setStatusApproved(value);
      default:
        break;
    }
  };

  const onChangeStatusAll = (result: boolean) => {
    setStatusAll(result);
    setStatusDraft(result);
    setStatusWaitingForApprove(result);
    setStatusRejected(result);
    setStatusApproved(result);
  };

  function GetStatus(status: string) {
    return <Status type={StatusType.ContractCompleteReport}
      value={status} />;
  }

  return (
    <div className='m01'>
      <Title text='รายงานสัญญาแล้วเสร็จตามไตรมาส' />

      <Row className='criteria my-4'>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่เอกสาร'
            value={searchData.contractCompleteReportNumber}
            onChange={(val) => setSearchData({ ...searchData, contractCompleteReportNumber: val })} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Selector label='ปี'
            value={searchData.budgetYear}
            items={budgetYearDDL}
            onChange={(val) => setSearchData({ ...searchData, budgetYear: val })} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Selector label='ไตรมาส'
            value={searchData.quarterId}
            items={quarterDDL}
            onChange={(val) => setSearchData({ ...searchData, quarterId: val })} />
        </Col>
        <Col sm={12}>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={(
                  <div className='align-items-center d-flex'>
                    ทั้งหมด
                    <Badge
                      className='h-50 mx-2 text-dark'
                      bg='light'
                    >
                      {countStatus.length > 0 ? countStatus.map((x) => x.count)
                        .reduce((prev, curr) => prev + curr) : 0}
                    </Badge>
                  </div>
                )}
                value={statusAll}
                onChange={(val) => onChangeStatusAll(val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>
                    แบบร่าง
                    <Badge
                      className='h-50 mx-2'
                      bg='secondary'
                    >
                      {
                        countStatus
                          .filter((x) => x.status === ContractCompleteReportStatus.Draft).length > 0
                          ? countStatus
                            .filter((x) => x.status === ContractCompleteReportStatus.Draft)[0].count
                          : 0
                      }
                    </Badge>
                  </div>
                )}
                value={statusDraft}
                onChange={(val) => onChangeCheckStatus(ContractCompleteReportStatus.Draft, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>
                    รอเห็นชอบ/อนุมัติ
                    <Badge
                      className='h-50 mx-2'
                      bg='warning'
                    >
                      {
                        countStatus
                          .filter((x) => x.status === ContractCompleteReportStatus.WaitingForApprove).length > 0
                          ? countStatus
                            .filter((x) => x.status === ContractCompleteReportStatus.WaitingForApprove)[0].count
                          : 0
                      }
                    </Badge>
                  </div>
                )}
                value={statusWaitingForApprove}
                onChange={(val) => onChangeCheckStatus(ContractCompleteReportStatus.WaitingForApprove, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>
                    อนุมัติ
                    <Badge
                      className='h-50 mx-2'
                      bg='success'
                    >
                      {
                        countStatus
                          .filter((x) => x.status === ContractCompleteReportStatus.Approved).length > 0
                          ? countStatus.filter((x) => x.status === ContractCompleteReportStatus.Approved)[0].count
                          : 0
                      }
                    </Badge>
                  </div>
                )}
                value={statusApproved}
                onChange={(val) => onChangeCheckStatus(ContractCompleteReportStatus.Approved, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>
                    ส่งกลับแก้ไข
                    <Badge
                      className='h-50 mx-2'
                      bg='danger'
                    >
                      {
                        countStatus
                          .filter((x) => x.status === ContractCompleteReportStatus.Rejected).length > 0
                          ? countStatus
                            .filter((x) => x.status === ContractCompleteReportStatus.Rejected)[0].count
                          : 0
                      }
                    </Badge>
                  </div>
                )}
                value={statusRejected}
                onChange={(val) => onChangeCheckStatus(ContractCompleteReportStatus.Rejected, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Row>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Check
              label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
              value={isResponsible}
              onChange={(val) => setIsResponsible(val)}
            />
          </Col>
        </Row>
        <div className='d-flex gap-2 justify-content-between'>
          <div className='d-flex'>
            <Button
              type='submit'
              variant='primary'
              className='me-2'
              onClick={() => onSearch(searchData, page, size)}>
              <FaSearch className='me-2' />
              ค้นหา
            </Button>
            <Button
              onClick={onClear}
              variant='outline-primary'
              type='submit'
              form='formCM02'
              className='d-flex align-items-center gap-2 ms-2'
            >
              <FaEraser />
              ล้าง
            </Button>
          </div>
          <ButtonCustom
            text='เพิ่มรายการ'
            onClick={() => goToEditPage()}
          />
        </div>
      </Row>

      <Row>
        <Col sm={12}>
          <Table
            total={total}
            onChange={(size, page) => {
              handleSetPagination(page, size);
            }}>
            <thead>
              <tr className='text-center'>
                <th style={{ width: '10%' }}>เลขที่เอกสาร</th>
                <th style={{ width: '10%' }}>สถานะ</th>
                <th style={{ width: '10%' }}>ปีงบประมาณ</th>
                <th style={{ width: '20%' }}>รอบไตรมาส</th>
                <th style={{ width: '20%' }}>ข้อมูลตั้งแต่วันที่</th>
                <th style={{ width: '10%' }}>จำนวนสัญญา (ฉบับ)</th>
                <th style={{ width: '20%' }}>มูลค่ารวม (บาท)
                </th>
              </tr>
            </thead>
            <tbody>
              {
                dataList?.map((data, index) => (
                  <tr key={index}>
                    <td className='text-center'>
                      <Button variant='link'
                        onClick={() => goToPage(data.id)}>{data.contractCompleteReportNumber}
                      </Button>
                    </td>
                    <td className='text-center'>{GetStatus(data.status)}</td>
                    <td className='text-center'>{data.budgetYear}</td>
                    <td className='text-center'>{data.quarter}</td>
                    <td className='text-center'>{`${thaiFormatDate(data.startSignDate)} - ${thaiFormatDate(data.endSignDate)}`}</td>
                    <td style={{ textAlign: 'right' }}>{data.countItems}</td>
                    <td style={{ textAlign: 'right' }}>{THCurrency(data.offerTotalAmountVat)}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
