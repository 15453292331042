import { Breadcrumb } from 'components';
import StepProgress from 'components/StepProgress';
import { useRef } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Step1 from './step1';
import Step2 from './step2';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

const BREADCRUMB_INFO = [
  {
    routerName: '/cm/cm0201', label: 'ร่างข้อมูลใบสั่ง',
  },
  {
    routerName: '', label: 'รายละเอียดร่างข้อมูลใบสั่ง',
  },
];

function AnnouncingWinnersDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const obj = [{
    title: <p>ข้อมูลใบสั่ง/สัญญา</p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p>ตัวอย่างเอกสาร</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/cm/cm0201');
  };

  return (
    <div className="document">
      <h4 className="mt-2 mb-0 text-primary text-start">
        <Button variant="link" onClick={backToIndex}>
          <MdOutlineArrowBack className="fs-4 text-primary mb-1" />
        </Button>ร่างข้อมูลใบสั่ง/สัญญา</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12} className="mb-2">
          <StepProgress progressBarData={obj} ref={childRef} />
        </Col>
      </Row>
    </div>
  );
}

export default AnnouncingWinnersDetail;
