import {
  Card,
  FileValue,
  InputTextArea,
  Modal,
  ModalReply,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  CreatorLevel,
  JorPorCode,
} from 'constant/basicInformation';
import { PlanProcurement } from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaCheckCircle,
  FaSave,
  FaUndo,
} from 'react-icons/fa';
import { planProcurement as planProcurementService } from 'services';
import {
  downloadFile,
  HttpStatusCode,
  showConfirmModalAsync,
  submitForm,
  useAppContext,
} from 'utils';
import {
  AcceptorStatus,
  PlanStatus as PlanStatusCons,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { UploadFile } from '../Procurement/UploadFile';
import { Context } from './Detail';
import { isNull } from 'utils/helper';

interface Props {
  onClickNext?: () => void;
  onClickBack?: () => void;
}

export default function Step2(props: Props) {
  const { currentStep, planProcurement, reGetDetail, setPlanProcurement, readonly } = useContext(Context);
  const { userId, positionCode, departmentId, departmentCode, inRefLevel } = useAppContext();
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
  const [remarkApprove, setRemarkApprove] = useState('');

  function isNull(oldValue?: string, newValue?: string) {
    if (oldValue) {
      return oldValue;
    }

    return newValue;
  }

  const canRemoveFile = useMemo(() => {
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel).filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const isMD = useMemo(() => {
    return positionCode === 'MD';
  }, [positionCode]);

  const approveAsync = async () => {
    if (!planProcurement.jorporDirectorBeInformed || !planProcurement.jorporDirectorBeInformed.length) {
      toast.warn('ต้องมีผอ.จพ.รับทราบอย่างน้อย 1 คน');

      return;
    }

    if (planProcurement.jorporDirectorBeInformed?.some(d => !d.id)) {
      toast.warn('กรุณากดบันทึกก่อนกดเห็นชอบ');

      return;
    }

    const approver = planProcurement.departmentDirectorAgree
      .find((d) => isNull(d.delegateUserId, d.userId) === userId);

    if (approver) {
      const res = await planProcurementService
        .approveAsync(
          planProcurement.id,
          approver.id,
          remarkApprove,
        );

      if (res.status === HttpStatusCode.ACCEPTED) {
        reGetDetail(planProcurement.id);
        toast.success('บันทึกเห็นชอบสำเร็จ');
        setShowApproveModal(false);
      }
    }
  };

  const uploadFileOnChangeAsync = async (file: File) => {
    if (planProcurement.id) {
      const res = await planProcurementService.uploadFileAsync(
        planProcurement.id,
        file,
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        setPlanProcurement({
          ...planProcurement,
          documents: [
            ...planProcurement.documents,
            {
              file,
            },
          ],
        });

        toast.success('อัปโหลดไฟล์สำเร็จ');

        reGetDetail(planProcurement.id);
      }
    } else {
      if (!planProcurement.documents) {
        planProcurement.documents = [];
      }

      setPlanProcurement({
        ...planProcurement,
        documents: [
          ...planProcurement.documents,
          {
            file,
          },
        ],
      });
    }
  };

  const files = useCallback(() => {
    const files = planProcurement.documents?.filter((d) => d.id && d.name);

    return files?.map((f) => ({
      id: f.id,
      name: f.name,
      attachmentBy: f.byUserId,
    })) as FileValue[];
  }, [planProcurement.documents]);

  const removeFileAsync = async (i: number, id: string | undefined) => {
    if (id) {
      const res = await planProcurementService.removeFileAsync(planProcurement.id, id);

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
      }
    }

    planProcurement.documents.splice(i, 1);

    setPlanProcurement({
      ...planProcurement,
      documents: [...planProcurement.documents],
    });
  };

  const downloadFileAsync = async (index: number, documentId?: string) => {
    if (documentId) {
      const res = await planProcurementService
        .downloadFileAsync(planProcurement.id, documentId);

      if (res.status === HttpStatusCode.OK) {
        const file = planProcurement.documents[index];

        downloadFile(res.data);
      }
    } else {
      const file = planProcurement.documents[index];

      if (file.file) {
        downloadFile(file.file);
      }
    }
  };

  const handlerOnSubmitAsync = async () => {
    if (planProcurement.status === PlanStatusCons.WaitingApprovePlan) {
      if (!planProcurement.jorporDirectorBeInformed ||
        planProcurement.jorporDirectorBeInformed?.length === 0) {
        toast.warn('ต้องมีผอ.จพ.รับทราบอย่างน้อย 1 คน');

        return;
      }

      if (!planProcurement.departmentDirectorAgree.some(d =>
        !d.isAvailable && d.status === AcceptorStatus.PENDING)) {
        toast.warn('ต้องมีฝ่ายเห็นชอบที่สามารถปฏิบัติงานได้อย่างน้อย 1 คน');

        return;
      }
    }

    const res = await planProcurementService
      .updateAsync(planProcurement);

    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
      toast.success('บันทึกสำเร็จ');

      reGetDetail(planProcurement.id);
    }
  };

  const recallAsync = async () => {
    const confirmRes = await showConfirmModalAsync('คุณต้องการเรียกคืนแก้ไขหรือไม่ ?');

    if (confirmRes) {
      const res = await planProcurementService.updateStatusAsync(
        planProcurement.id,
        PlanStatusCons.DraftPlan,
        'เรียกคืนแก้ไข',
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        reGetDetail(planProcurement.id);
        toast.success('เรียกคืนแก้ไขสำเร็จ');
      }
    }
  };

  const checkQueue = (data: PlanProcurement, userId: string) => {
    const sectionData = data.departmentDirectorAgree
      .filter(d => d.status === AcceptorStatus.PENDING
        && !d.isAvailable)
      .sort((a, b) => a.sequence - b.sequence)[0];

    if (sectionData) {
      return isNull(sectionData.delegateUserId, sectionData.userId) === userId;
    }

    return false;
  };

  const canRecall = useMemo(() => {
    if (!planProcurement || !userId || currentStep !== 2) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.WaitingApprovePlan) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel).filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    if (planProcurement.departmentDirectorAgree?.some(d =>
      d.status !== AcceptorStatus.PENDING)) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canRework = useMemo(() => {
    if (!planProcurement || currentStep !== 2 || !userId) {
      return false;
    }

    if (planProcurement.isRefChange || planProcurement.isRefCancel) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.WaitingApprovePlan) {
      return false;
    }

    if (planProcurement.isActive === false) {
      return false;
    }

    if (planProcurement.isCanceled) {
      return false;
    }

    if (checkQueue(planProcurement, userId)) {
      return true;
    }

    return false;
  }, [planProcurement, currentStep, userId]);

  const canSave = useMemo(() => {
    if (!planProcurement || currentStep !== 2 || !userId) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.WaitingApprovePlan) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel).filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canApprove = useMemo(() => {
    if (!planProcurement || currentStep !== 2 || !userId) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.WaitingApprovePlan) {
      return false;
    }

    if (checkQueue(planProcurement, userId)) {
      return true;
    }

    return false;
  }, [planProcurement, currentStep, userId]);

  return (
    <>
      {/* Upload File */}
      {currentStep === 2
        ? (
          <Card className="mt-3">
            <Title text="เอกสารแนบ" className="fs-5 text-primary" />
            <Row className="justify-content-center">
              <Col sm={12} lg={6}>
                <UploadFile
                  onChange={uploadFileOnChangeAsync}
                  value={files()}
                  onRemove={removeFileAsync}
                  onDownload={downloadFileAsync}
                  canRemoveFile={canRemoveFile}
                  disabled={readonly}
                />
              </Col>
            </Row>
          </Card>
        ) : null}

      {/* Button */}
      {currentStep === 2
        ? (
          <div className="d-flex justify-content-between mt-3">
            <ButtonCustom
              onClick={props.onClickBack}
              text="กลับหน้าแรก"
            />
            <div className='d-flex gap-2'>
              {canRecall && (
                <ButtonCustom
                  icon="undo"
                  variant="danger"
                  onClick={recallAsync}
                  text="เรียกคืนแก้ไข" />
              )}
              {canRework && (
                <ButtonCustom
                  icon="undo"
                  variant="danger"
                  onClick={() => setShowRejectModal(true)}
                  text="ส่งกลับแก้ไข" />
              )}
              {canSave && (
                <ButtonCustom
                  variant="success"
                  onClick={() => handlerOnSubmitAsync()}
                  text="บันทึก"
                />
              )}
              {canApprove && (
                <ButtonCustom
                  icon='checked'
                  variant={isMD ? 'success' : 'primary'}
                  onClick={() => setShowApproveModal(true)}
                  text={isMD ? 'อนุมัติ' : 'เห็นชอบ'}
                />
              )}
            </div>
            <div style={{ width: 100 }} />
          </div>
        ) : null}

      {/* Modal */}
      <RejectModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(false)}
      />
      <Modal
        show={showApproveModal}
        size="lg"
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>เห็นชอบ/อนุมัติ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              <InputTextArea
                label='ความเห็นเพิ่มเติม (ถ้ามี)'
                value={remarkApprove}
                onChange={(value) => setRemarkApprove(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant="outline-primary"
                onClick={() => setShowApproveModal(!showApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant="primary"
                onClick={approveAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

function RejectModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement, reGetDetail } = useContext(Context);
  const [remark, setRemark] = useState<string>();
  const { userId } = useAppContext();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (remark) {
      const approver = planProcurement.departmentDirectorAgree
        .find((d) => isNull(d.delegateUserId, d.userId) === userId);

      if (approver) {
        const res = await planProcurementService
          .rejectAsync(
            planProcurement.id,
            approver.id,
            PlanStatusCons.RejectPlan,
            remark,
          );

        if (res.status === HttpStatusCode.ACCEPTED) {
          reGetDetail(planProcurement.id);
          toast.success('ส่งกลับแก้ไขสำเร็จ');

          props.onHide();
        }
      }
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
    >
      <Modal.Header>
        <Title
          text='ส่งกลับแก้ไข'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label='เหตุผล'
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name='remark'
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-end gap-2'>
        <Button
          variant='outline-primary'
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant='primary'
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );

  // return (
  //   <ModalReply
  //     show={props.show}
  //     onHide={props.onHide}
  //     title="ส่งกลับแก้ไข"
  //     status='comment'
  //     remark={remark}
  //     setRemark={() => setRemark}
  //     textInBtn='ส่งกลับแก้ไข'
  //     onClick={onSubmitAsync}
  //     required
  //   />
  // );
}
