export const dataSearchName = [
  {
    no: '1',
    id: '5600025',
    name: 'สมหญิง รักษ์เมืองไทย',
    class: '',
    position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร',
  },
  {
    no: '2',
    id: '5600025',
    name: 'สมหญิง รักษ์เมืองไทย',
    class: '',
    position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร',
  },
];

export const dataHistory = [
  {
    no: '1',
    versionNo: 'V1.1.2.005',
    nameEdit: 'สมหญิง รักษ์เมืองไทย',
    dayEdit: '31/12/2021 12:22',
  },
  {
    no: '2',
    versionNo: 'V1.1.2.005',
    nameEdit: 'สมหญิง รักษ์เมืองไทย',
    dayEdit: '31/12/2021 12:22',
  },
];
