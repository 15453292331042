import {
  Check,
  DatePicker,
  Input,
  Table,
} from 'components';
import { RecordContract } from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaCircle,
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { recordContract } from 'services';
import {
  fullDate,
  HttpStatusCode,
  THCurrency,
} from 'utils';

interface Criteria {
  traderTaxId?: string;
  traderName?: string;
  torNumber?: string;
  torName?: string;
  startDate?: Date;
  endDate?: Date;
  isPermission: boolean;
}

export default function PR0206() {
  const navigate = useNavigate();
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [dataList, setDataList] = useState<RecordContract[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [criteria, setCriteria] = useState<Criteria>({ isPermission: true } as Criteria);

  useEffect(() => {
    getListAsync(size, page, criteria);
  }, []);

  const getListAsync = async (size: number, page: number, criteria: Criteria) => {
    const res = await recordContract.getListAsync(
      size,
      page,
      criteria.isPermission,
      criteria.traderTaxId,
      criteria.traderName,
      criteria.torNumber,
      criteria.torName,
      criteria.startDate,
      criteria.endDate);

    if (res.status === HttpStatusCode.OK) {
      setDataList(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);

    getListAsync(size, page, criteria);
  };

  const onSearch = () => {
    getListAsync(size, page, criteria);
  };

  const clear = (size: number, page: number) => {
    setCriteria({ isPermission: true } as Criteria);
    const criteria = {
      isPermission: true,
    } as Criteria;
    getListAsync(size, page, criteria);
  }

  const status = useCallback((status: string) => {
    if (status === 'Draft') {
      return (
        <div className='status'>
          <span>
            <FaCircle className='draft' />แบบร่าง
          </span>
        </div>
      );
    }

    if (status === 'Confirm') {
      return (
        <div className='status'>
          <span>
            <FaCircle className='warning' />ยืนยันข้อมูล
          </span>
        </div>
      );
    }

    if (status === 'Assign') {
      return (
        <div className='status'>
          <span>
            <FaCircle className='assign' />มอบหมายงาน
          </span>
        </div>
      );
    }

    return null;
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">ลงนามใบสั่ง</h4>
      </div>
      <hr />
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="เลขที่โครงการ"
            onChange={torNumber => setCriteria({ ...criteria, torNumber })}
            value={criteria.torNumber} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="ชื่อโครงการ"
            onChange={torName => setCriteria({ ...criteria, torName })}
            value={criteria.torName} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <DatePicker
            label="วันที่บันทึกเริ่มต้น"
            onChange={startDate => setCriteria({ ...criteria, startDate })}
            value={criteria.startDate} />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <DatePicker
            label="วันที่บันทึกสิ้นสุด"
            onChange={endDate => setCriteria({ ...criteria, endDate })}
            value={criteria.endDate} />
        </Col>
        <Col sm={12} md={4} xl={3} className="pt-5">
          <Check label='แสดงเฉพาะรายการที่ได้รับมอบหมาย' value={criteria.isPermission} onChange={(value) => setCriteria({ ...criteria, isPermission: value })} />
        </Col>
        <div className="d-flex gap-2">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2"
            onClick={onSearch}>
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant="outline-primary"
            className="d-flex align-items-center gap-2"
            onClick={() => clear(size, page)}>
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
      <Table
        className="mt-3"
        onChange={onPaginationChange}
        total={totalRecords}>
        <thead>
          <tr className="text-center">
            <th>เลขที่อ้างอิง</th>
            <th>ชื่อโครงการ</th>
            <th>สถานะ</th>
            <th>ฝ่าย/สำนัก</th>
            <th>งบประมาณ (บาท)</th>
            <th>วิธีจัดหา</th>
            <th>วันทีบันทึก</th>
          </tr>
        </thead>
        <tbody>
          {dataList.map((d, i) => (
            <tr key={d.id}>
              <td className="text-center">
                <Button variant="link" onClick={() => navigate(`detail`)}>
                  DP66001
                </Button>
              </td>
              <td>
                {d.planName}
              </td>
              <td>
                {status(d.status)}
              </td>
              <td>
                จพ : จัดหาและการพัสดุ
              </td>
              <td className="text-center">
                {THCurrency(d.financialAmount)}
              </td>
              <td>
                พ.ร.บ.จัดซื้อจัดจ้างฯ 2560 : วิธีคัดเลือก
              </td>
              <td className="text-center">
                {fullDate(d.recordContractDate)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
