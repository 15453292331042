import {
  Card,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Modal,
  Selector,
  Table,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { InvitationStatus } from 'components/InvitationStatus';
import {
  InvitationStatusConstant,
  TraderModalTypeConstant,
} from 'constant/ProcumentInvitationStatus';
import {
  IFile,
  ItemModel,
} from 'models';
import {
  DocumentModel,
  InvitationDetailModel,
  InviteEntrepreneurModel,
} from 'models/PR/InvitationModels';
import moment from 'moment';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaHistory,
  FaPlus,
  FaRegCheckCircle,
  FaSave,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { shareValue } from 'services';
import InvitationServices from 'services/PR/InvitationServices';
import {
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { CoiModal } from '../Modal/CoiModal';
import { HistoryModal } from '../Modal/HistoryModal';
import { TraderModal } from '../Modal/TraderModal';
import { Context } from './index';
import UploadFile from './UploadFile';
import { CommitteeType } from 'pages/PR/PR0101/Detail';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type Loader = {
  departmentDDL: ItemModel[],
  supplyMethodDDL: ItemModel[],
  supplyMethodTypeDDL: ItemModel[],
  entrepreneurTypeDDL: ItemModel[],
};

const ENTREPRENEURTYPE = [
  { label: 'นิติบุคคล', value: 'JuristicPerson' },
  { label: 'บุคคลธรรมดา', value: 'Individual' },
];

function Step1({ onClickNext, onClickBack }: Props) {
  const { departmentDDL, supplyMethodDDL, supplyMethodTypeDDL, entrepreneurTypeDDL } = useLoaderData() as Loader;
  const { data, setData } = useContext(Context);
  const [inviteData, setInviteData] = useState<InvitationDetailModel>({} as InvitationDetailModel);
  const [supplyMethod, setSupplyMethod] = useState('');
  const [supplyMethodType, setSupplyMethodType] = useState('');
  const [supplyMethodSpecialType, setSupplyMethodSpecialType] = useState('');
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const [showHistory, setShowHistory] = useState(false);
  const [inviteEntrepreneurs, setInviteEntrepreneurs] = useState<InviteEntrepreneurModel[]>([]);
  const [showTraderModal, setShowTraderModal] = useState(false);
  const [submitProposalsStartTime, setSubmitProposalsStartTime] = useState('');
  const [submitProposalsEndTime, setSubmitProposalsEndTime] = useState('');
  const [showCoiModal, setShowCoiModal] = useState(false);
  const [selectTraderModel, setSelectTraderModel] = useState<InviteEntrepreneurModel>({} as InviteEntrepreneurModel);
  const [modalTitle, setModalTitle] = useState('COI');
  const [modalType, setModalType] = useState<TraderModalTypeConstant>(TraderModalTypeConstant.COI);
  const [modalResult, setModalResult] = useState<boolean | undefined>(undefined);
  const [modalRemark, setModalRemark] = useState<string | undefined>(undefined);
  const [files, setFiles] = useState<IFile[]>([]);
  const navigate = useNavigate();
  const { userId } = useAppContext();
  const [showRecall, setShowRecall] = useState(false);
  const [isCanRecall, setIsCanRecall] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showSentEmail, setShowSentEmail] = useState(false);
  const [sentEmailTraderModal, setSentEmailTraderModal] = useState<InviteEntrepreneurModel>({} as InviteEntrepreneurModel);

  useEffect(() => {
    if (data) {
      setInviteData(data);
      if (data.documents) {
        handleSetFile(data.documents);
      }

      if (data.supplyMethodId) {
        setSupplyMethod(data.supplyMethodId);
        getSupplyMethodSpecialType(data.supplyMethodId);
      }

      if (data.supplyMethodTypeId) {
        setSupplyMethodType(data.supplyMethodTypeId);
      }

      if (data.supplyMethodSpecialTypeId && !supplyMethodSpecialType) {
        setSupplyMethodSpecialType(data.supplyMethodSpecialTypeId);
      }

      if (data.submitProposalsStartDate) {
        setSubmitProposalsStartTime(moment(data.submitProposalsStartDate)
          .format('HH:mm'));
      }

      if (data.submitProposalsEndDate) {
        setSubmitProposalsEndTime(moment(data.submitProposalsEndDate)
          .format('HH:mm'));
      }

      if (data.inviteEntrepreneurs) {
        setInviteEntrepreneurs(data.inviteEntrepreneurs);
      }

      if (data.status) {
        setDisableForm();
      }

      if (data.inviteAcceptors) {
        if (userId) {
          setIsCanRecall(!(data.inviteAcceptors.filter(c => c.status === InvitationStatusConstant.Approved || c.status === InvitationStatusConstant.Rejected).length > 0) && data.isResponsible);
        }
      }

      if (data.inviteCommittees) {
        setIsCanRecall(!(data.inviteCommittees.filter(c => c.approverStatus === InvitationStatusConstant.Approved || c.approverStatus === InvitationStatusConstant.Rejected).length > 0) && data.isResponsible);
      }
    }
  }, [data, userId]);

  const getSupplyMethodSpecialType = useCallback(async (supplyMethodId: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(supplyMethodId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setSupplyMethodSpecialDDL(data);
  }, [supplyMethod]);

  const setDisableForm = () => {
    const isDisable = data.status !== InvitationStatusConstant.None
      && data.status !== InvitationStatusConstant.Draft
      && data.status !== InvitationStatusConstant.Rejected;

    setIsDisabled(isDisable);

    return isDisable;
  }

  const onSelectTrader = (id: string, entrepreneurType: string, type: string, taxpayerIdentificationNo: string, establishmentName: string, email: string) => {
    if (inviteEntrepreneurs && inviteEntrepreneurs.filter((x) => x.traderId === id).length > 0) {
      toast.warn('ไม่สามารถเพิ่มคู่ค้าซ้ำได้');
    } else {
      const newData: InviteEntrepreneurModel = {
        id: undefined,
        sequence: inviteEntrepreneurs.length + 1,
        inviteId: inviteData.id,
        traderId: id,
        traderTaxId: taxpayerIdentificationNo,
        traderType: type,
        establishmentName: establishmentName,
        email: email,
        coiResult: undefined,
        coiRemark: '',
        watchListResult: undefined,
        watchListRemark: '',
        egpResult: undefined,
        egpRemark: '',
      };
      setInviteEntrepreneurs((inviteEntrepreneurs) => [...inviteEntrepreneurs, newData]);
    }
  };

  const handlerOnChangeEmail = (value: string, index: number) => {
    const data = [...inviteEntrepreneurs];
    data[index].email = value;
    setInviteEntrepreneurs(data);
  };

  const removeTrader = (i: number) => {
    const temp: InviteEntrepreneurModel[] = [...inviteEntrepreneurs];
    temp.splice(i, 1);
    setInviteEntrepreneurs(temp);
  };

  const onOpenCoiModal = (traderModel: InviteEntrepreneurModel, modalType: string, result?: boolean, remark?: string) => {
    setSelectTraderModel(traderModel);
    setModalResult(result);
    setModalRemark(remark);
    if (modalType === TraderModalTypeConstant.COI) {
      setModalTitle('COI');
      setModalType(TraderModalTypeConstant.COI);
    } else if (modalType === TraderModalTypeConstant.WATCHLIST) {
      setModalTitle('Watchlist');
      setModalType(TraderModalTypeConstant.WATCHLIST);
    } else {
      setModalTitle('e-GP');
      setModalType(TraderModalTypeConstant.EGP);
    }
    setShowCoiModal(true);
  };

  const onSubmitTraderResultModal = (traderId: string, modalType: string, remark: string, isAccept?: boolean, files?: IFile[]) => {
    const data = [...inviteEntrepreneurs];
    const index = data.findIndex(x => x.traderId === traderId);
    const docUpload: File[] = [];
    if (files && !data[index].id) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });
    }

    switch (modalType) {
      case TraderModalTypeConstant.COI:
        data[index].coiResult = isAccept;
        data[index].coiRemark = remark;
        data[index].filesCoi = docUpload;
        setInviteEntrepreneurs(data);
        onSave();
        return;
      case TraderModalTypeConstant.WATCHLIST:
        data[index].watchListResult = isAccept;
        data[index].watchListRemark = remark;
        data[index].filesWatchList = docUpload;
        setInviteEntrepreneurs(data);
        onSave();
        return;
      case TraderModalTypeConstant.EGP:
        data[index].egpResult = isAccept;
        data[index].egpRemark = remark;
        data[index].filesEgp = docUpload;
        setInviteEntrepreneurs(data);
        onSave();
        return;
    }
  };

  const onSave = async () => {
    submitForm();

    if (!inviteData.planName) {
      toast.warn('กรุณาระบุชื่อโครงการ');
      return;
    }

    if (!inviteData.sourceAndReasons) {
      toast.warn('กรุณาระบุที่มาและเหตุผล');
      return;
    }

    if (!inviteData.submitProposalsStartDate) {
      toast.warn('กรุณาระบุวันที่เริ่มต้นเสนอราคา');
      return;
    }

    if (!submitProposalsStartTime || submitProposalsStartTime.split(':').length < 2) {
      toast.warn('กรุณาระบุเวลาเริ่มต้นเสนอราคา');
      return;
    }

    if (!inviteData.submitProposalsEndDate) {
      toast.warn('กรุณาระบุวันที่สิ้นสุดเสนอราคา');
      return;
    }

    if (!submitProposalsEndTime || submitProposalsEndTime.split(':').length < 2) {
      toast.warn('กรุณาระบุเวลาสิ้นสุดเสนอราคา');
      return;
    }

    // if (!inviteData.needToKnowWithinDate) {
    //   toast.warn('กรุณาระบุวันที่ผู้สนใจต้องการทราบรายละเอียดเพิ่มเติม');
    //   return;
    // }

    // if (!inviteData.clarifyDetailViaDate) {
    //   toast.warn('กรุณาระบุวันที่ชี้แจงรายละเอียดผ่านเว็บไซต์');
    //   return;
    // }

    const StartTimes = submitProposalsStartTime.split(':');
    const EndTimes = submitProposalsEndTime.split(':');

    const saveStartDate = moment({
      year: new Date(inviteData.submitProposalsStartDate).getFullYear(),
      month: new Date(inviteData.submitProposalsStartDate).getMonth(),
      day: new Date(inviteData.submitProposalsStartDate).getDate(),
      hour: Number(StartTimes[0]),
      minute: Number(StartTimes[1]),
    })
      .format('YYYY-MM-DD H:mm:ss');
    const saveEndDate = moment({
      year: new Date(inviteData.submitProposalsEndDate).getFullYear(),
      month: new Date(inviteData.submitProposalsEndDate).getMonth(),
      day: new Date(inviteData.submitProposalsEndDate).getDate(),
      hour: Number(EndTimes[0]),
      minute: Number(EndTimes[1]),
    })
      .format('YYYY-MM-DD H:mm:ss');

    if (new Date(saveStartDate) > new Date(saveEndDate)) {
      toast.warn('ไม่สามารถระบุวันเวลาเริ่มต้นมากกว่าสิ้นสุดได้');
      return;
    }

    const docUpload: File[] = [];
    if (files && !inviteData.id) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });
    }

    const saveInviteEntrepreneurs: InviteEntrepreneurModel[] = [];

    inviteEntrepreneurs.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        const newData: InviteEntrepreneurModel = {
          id: data.id,
          sequence: index + 1,
          inviteId: data.inviteId,
          traderId: data.traderId,
          traderTaxId: data.traderTaxId,
          traderType: data.traderType,
          establishmentName: data.establishmentName,
          email: data.email,
          coiResult: data.coiResult,
          coiRemark: data.coiRemark,
          watchListResult: data.watchListResult,
          watchListRemark: data.watchListRemark,
          egpResult: data.egpResult,
          egpRemark: data.egpRemark,
          filesCoi: data.filesCoi,
          filesWatchList: data.filesWatchList,
          filesEgp: data.filesEgp,
        };

        saveInviteEntrepreneurs.push(newData);
      });

    const updateData: InvitationDetailModel = {
      id: inviteData.id,
      termOfRefId: inviteData.termOfRefId,
      jorPor05Id: inviteData.jorPor05Id,
      jorPor05Number: inviteData.jorPor05Number,
      inviteNumber: inviteData.inviteNumber,
      torNumber: inviteData.torNumber,
      planNumber: inviteData.planNumber,
      planName: inviteData.planName,
      financialAmount: inviteData.financialAmount,
      departmentId: inviteData.departmentId,
      departmentName: inviteData.departmentName,
      budgetYear: inviteData.budgetYear,
      memorandumNo: inviteData.memorandumNo,
      memorandumDate: inviteData.memorandumDate,
      expectingProcurementAt: inviteData.expectingProcurementAt,
      supplyMethodId: supplyMethod,
      supplyMethodTypeId: supplyMethodType,
      supplyMethodSpecialTypeId: supplyMethodSpecialType,
      supplyMethodName: inviteData.supplyMethodName,
      supplyMethodTypeName: inviteData.supplyMethodTypeName,
      supplyMethodSpecialTypeName: inviteData.supplyMethodSpecialTypeName,
      sourceAndReasons: inviteData.sourceAndReasons,
      submitProposalsStartDate: new Date(saveStartDate),
      submitProposalsEndDate: new Date(saveEndDate),
      needToKnowWithinDate: inviteData.needToKnowWithinDate,
      clarifyDetailViaDate: inviteData.clarifyDetailViaDate,
      inviteEntrepreneurs: saveInviteEntrepreneurs,
      inviteAcceptors: inviteData.inviteAcceptors,
      inviteActivities: inviteData.inviteActivities,
      status: inviteData.status,
      remark: inviteData.remark,
      isChange: inviteData.isChange,
      isCancel: inviteData.isCancel,
      isActive: inviteData.isActive,
      refId: inviteData.refId,
      documentId: inviteData.documentId,
      documents: inviteData.documents,
      createdByUserId: inviteData.createdByUserId,
      isResponsible: inviteData.isResponsible,
      inviteCommittees: data.inviteCommittees.filter(x => x.committeeType === CommitteeType.PROCUREMENT),
    };

    if (!inviteData.id) {
      const res = await InvitationServices.createInvitationAsync(updateData, docUpload);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('บันทึกข้อมูลไม่สำเร็จ');
        return;
      }

      toast.success('บันทึกข้อมูลสำเร็จ');
      if (res.data.id) {
        getInvitationByIdAsynce(inviteData.jorPor05Id, res.data.id);
      }
    } else {
      const res = await InvitationServices.updateInvitationAsync(updateData, docUpload);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('บันทึกข้อมูลไม่สำเร็จ');
        return;
      }

      toast.success('บันทึกข้อมูลสำเร็จ');
      if (res.data.id) {
        getInvitationByIdAsynce(inviteData.jorPor05Id, inviteData.id);
      }
    }
  };

  const getInvitationByIdAsynce = async (jorPor05Id: string, id?: string) => {
    const response = await InvitationServices.getInvitationByIdAsync(jorPor05Id, id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data.dto);
      navigate(`/procurement-invitation-order/detail/${jorPor05Id}/id/${id}`);
    }
  };

  function updateData(): InvitationDetailModel {
    let saveStartDate: string = '';
    let saveEndDate: string = '';

    if (submitProposalsStartTime && submitProposalsEndTime && inviteData.submitProposalsStartDate && inviteData.submitProposalsEndDate) {
      const StartTimes = submitProposalsStartTime.split(':');
      const EndTimes = submitProposalsEndTime.split(':');

      saveStartDate = moment({
        year: new Date(inviteData.submitProposalsStartDate).getFullYear(),
        month: new Date(inviteData.submitProposalsStartDate).getMonth(),
        day: new Date(inviteData.submitProposalsStartDate).getDate(),
        hour: Number(StartTimes[0]),
        minute: Number(StartTimes[1]),
      })
        .format('YYYY-MM-DD hh:mm:ss');
      saveEndDate = moment({
        year: new Date(inviteData.submitProposalsEndDate).getFullYear(),
        month: new Date(inviteData.submitProposalsEndDate).getMonth(),
        day: new Date(inviteData.submitProposalsEndDate).getDate(),
        hour: Number(EndTimes[0]),
        minute: Number(EndTimes[1]),
      })
        .format('YYYY-MM-DD hh:mm:ss');
    }

    const saveInviteEntrepreneurs: InviteEntrepreneurModel[] = [];

    inviteEntrepreneurs.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        const newData: InviteEntrepreneurModel = {
          id: data.id,
          sequence: index + 1,
          inviteId: data.inviteId,
          traderId: data.traderId,
          traderTaxId: data.traderTaxId,
          traderType: data.traderType,
          establishmentName: data.establishmentName,
          email: data.email,
          coiResult: data.coiResult,
          coiRemark: data.coiRemark,
          watchListResult: data.watchListResult,
          watchListRemark: data.watchListRemark,
          egpResult: data.egpResult,
          egpRemark: data.egpRemark,
          filesCoi: data.filesCoi,
          filesWatchList: data.filesWatchList,
          filesEgp: data.filesEgp,
        };

        saveInviteEntrepreneurs.push(newData);
      });

    const updateData: InvitationDetailModel = {
      id: inviteData.id,
      termOfRefId: inviteData.termOfRefId,
      jorPor05Id: inviteData.jorPor05Id,
      jorPor05Number: inviteData.jorPor05Number,
      inviteNumber: inviteData.inviteNumber,
      torNumber: inviteData.torNumber,
      planNumber: inviteData.planNumber,
      planName: inviteData.planName,
      financialAmount: inviteData.financialAmount,
      departmentId: inviteData.departmentId,
      departmentName: inviteData.departmentName,
      budgetYear: inviteData.budgetYear,
      memorandumNo: inviteData.memorandumNo,
      memorandumDate: inviteData.memorandumDate,
      expectingProcurementAt: inviteData.expectingProcurementAt,
      supplyMethodId: supplyMethod,
      supplyMethodTypeId: supplyMethodType,
      supplyMethodSpecialTypeId: supplyMethodSpecialType,
      supplyMethodName: inviteData.supplyMethodName,
      supplyMethodTypeName: inviteData.supplyMethodTypeName,
      supplyMethodSpecialTypeName: inviteData.supplyMethodSpecialTypeName,
      sourceAndReasons: inviteData.sourceAndReasons,
      submitProposalsStartDate: saveStartDate ? new Date(saveStartDate) : undefined,
      submitProposalsEndDate: saveEndDate ? new Date(saveEndDate) : undefined,
      needToKnowWithinDate: inviteData.needToKnowWithinDate,
      clarifyDetailViaDate: inviteData.clarifyDetailViaDate,
      inviteEntrepreneurs: saveInviteEntrepreneurs,
      inviteAcceptors: inviteData.inviteAcceptors,
      inviteActivities: inviteData.inviteActivities,
      status: inviteData.status,
      remark: inviteData.remark,
      isChange: inviteData.isChange,
      isCancel: inviteData.isCancel,
      isActive: inviteData.isActive,
      refId: inviteData.refId,
      documentId: inviteData.documentId,
      documents: inviteData.documents,
      createdByUserId: inviteData.createdByUserId,
      isResponsible: inviteData.isResponsible,
      inviteCommittees: data.inviteCommittees.filter(x => x.committeeType === CommitteeType.PROCUREMENT),
    };

    return updateData;
  }

  const onNext = async () => {
    const UpdData = updateData();
    setData(UpdData);
    onClickNext();
  };

  const handleSetFile = (documents: DocumentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    const newUpdFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };
      setFiles((pre) => [...pre, newFile]);
      newUpdFiles.push(file);
    }

    if (newUpdFiles && newUpdFiles.length > 0 && inviteData.id) {
      const res = await InvitationServices.onAttachmentInvitationDocumentByIdAsync(inviteData.id, newUpdFiles);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('อัปโหลดเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('อัปโหลดเอกสารแนบสำเร็จ');
      await getInvitationDocumentByIdAsynce(inviteData.jorPor05Id, inviteData.id);
    }
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    const delId = newArray.filter((i) => i.name === docName)[0].id;
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName && delId) {
      const res = await InvitationServices.deleteInvitationDocumentByIdAsync(data.id, delId);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('ลบเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('ลบเอกสารแนบสำเร็จ');
      await getInvitationDocumentByIdAsynce(inviteData.jorPor05Id, inviteData.id);
    }
  }, [files, data.id]);

  const getInvitationDocumentByIdAsynce = async (jorPor05Id: string, id?: string) => {
    const response = await InvitationServices.getInvitationByIdAsync(jorPor05Id, id);

    if (response.status === HttpStatusCode.OK) {
      if (response.data.dto.documents) {
        setInviteData({ ...data, documents: response.data.dto.documents });
      }
    }
  };

  const checkBooleanNullOrUndefined = (val?: boolean) => {
    return (val === null || val === undefined);
  };

  const onRecall = async () => {
    const res = await InvitationServices.onRecallAsync(inviteData.id);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('เรียกคืนแก้ไขไม่สำเร็จ');
      return;
    }

    await getInvitationByIdAsynce(inviteData.jorPor05Id, inviteData.id);
    toast.success('เรียกคืนแก้ไขสำเร็จ');
    setShowRecall(false);
  };

  const onPending = async () => {
    submitForm();

    if (!inviteData.inviteAcceptors || inviteData.inviteAcceptors.length === 0) {
      toast.warn('กรุณาระบุผู้มีอำนาจเห็นชอบ');
      return;
    }

    if (!inviteData.inviteEntrepreneurs || inviteData.inviteEntrepreneurs.length === 0) {
      toast.warn('กรุณาระบุผู้ประกอบการที่ต้องการเชิญชวน');
      return;
    }

    const UpdData = updateData();

    const res = await InvitationServices.updateInvitationAsync(UpdData, []);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('ส่งเห็นชอบ/อนุมัติไม่สำเร็จ');
      return;
    }

    const resPending = await InvitationServices.onPendingAsync(inviteData.id);

    if (resPending.status !== HttpStatusCode.OK) {
      toast.error('ส่งเห็นชอบ/อนุมัติไม่สำเร็จ');
      return;
    }

    toast.success('ส่งเห็นชอบ/อนุมัติสำเร็จ');
    setShowPendingModal(false);
    getInvitationByIdAsynce(inviteData.jorPor05Id, inviteData.id);
  };

  const onOpenEmailModal = (traderModel: InviteEntrepreneurModel, email: string) => {
    setSentEmailTraderModal(traderModel);
    setShowSentEmail(true);
  };

  const onSentEmail = async () => {
    submitForm();

    if (!sentEmailTraderModal) {
      toast.warn('กรุณาระบุผู้ประกอบการที่ต้องการจะส่งอีเมล');
      return;
    }

    if (sentEmailTraderModal) {
      const UpdData = updateData();

      const res = await InvitationServices.updateInvitationAsync(UpdData, []);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('ส่งอีเมลให้ผู้ประกอบการไม่สำเร็จ');
        return;
      }

      const resPending = await InvitationServices.onSentEmailAsync(inviteData.id, sentEmailTraderModal.traderId);

      if (resPending.status !== HttpStatusCode.OK) {
        toast.error('ส่งอีเมลให้ผู้ประกอบการไม่สำเร็จ');
        return;
      }

      toast.success('ส่งอีเมลให้ผู้ประกอบการสำเร็จ');
      setShowSentEmail(false);
      getInvitationByIdAsynce(inviteData.jorPor05Id, inviteData.id);
    }
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <Title text='ข้อมูลโครงการ'
            className='fs-5 text-primary' />
          <div className='d-flex gap-2'>
            <InvitationStatus
              value={inviteData.status}
            />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='เลขที่ทำหนังสือเชิญชวนผู้ประกอบการ'
              value={inviteData.inviteNumber}
              disabled
            />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='เลขที่รายงานขอซื้อขอจ้าง (จพ.005)'
              value={inviteData.jorPor05Number}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input
              label='อ้างอิงแผนการจัดซื้อจัดจ้าง'
              placeholder='อ้างอิงแผนการจัดซื้อจัดจ้าง'
              value={inviteData.planNumber}
              disabled
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={9}
            lg={4}>
            <div className='d-flex align-items-center'>
              <Selector label='ฝ่าย/สำนัก'
                placeholder='กรุณาเลือก'
                className='primary'
                items={departmentDDL}
                value={inviteData.departmentId}
                disabled />
            </div>
          </Col>
          <Col sm={3}
            lg={4}>
            <Input
              label='ปีงบประมาณ'
              placeholder='ปีงบประมาณ'
              value={inviteData.budgetYear}
              rule={{ required: true }}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={6}
            xl={3}>
            <Selector
              label='วิธีการจัดหา'
              placeholder='กรุณาเลือก'
              items={supplyMethodDDL}
              rule={{ required: true }}
              value={supplyMethod}
              disabled
            />
          </Col>
          <Col sm={12}
            md={6}
            xl={3}>
            <Selector
              className='no-label'
              placeholder='กรุณาเลือก'
              items={supplyMethodTypeDDL}
              rule={{ required: true }}
              value={supplyMethodType}
              disabled
            />
          </Col>
          <Col sm={12}
            md={6}
            xl={3}>
            <Selector
              className='no-label'
              placeholder='กรุณาเลือก'
              items={supplyMethodSpecialDDL}
              rule={{ required: true }}
              value={supplyMethodSpecialType}
              disabled
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Currency
              label='วงเงิน (บาท)'
              rule={{ required: true }}
              placeholder='วงเงิน (บาท)'
              value={inviteData.financialAmount}
              disabled
            />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <DatePicker
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              rule={{ required: true }}
              value={inviteData.expectingProcurementAt}
              monthYearOnly
              disabled
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={4}>
            <DatePicker
              label='วันที่เอกสารบันทึกร่างขอบเขตของงาน'
              value={inviteData.memorandumDate}
              fullDate
              disabled
            />
          </Col>
          <Col sm={12}
            lg={4}>
            <Input
              label='เลขที่เอกสารร่างขอบเขตของงาน'
              placeholder='เลขที่เอกสารร่างขอบเขตของงาน'
              value={inviteData.memorandumNo}
              disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            xl={9}>
            <InputTextArea label='ชื่อโครงการ (เรื่อง)'
              value={inviteData.planName}
              onChange={val => setInviteData({ ...inviteData, planName: val })}
              rule={{ required: true }}
              disabled={isDisabled} />
          </Col>
          <Col sm={12}
            xl={9}>
            <InputTextArea label='ที่มาและเหตุผล'
              value={inviteData.sourceAndReasons}
              onChange={val => setInviteData({ ...inviteData, sourceAndReasons: val })}
              rule={{ required: true }}
              disabled={isDisabled} />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='รายละเอียดสำหรับผู้ยืนข้อเสนอ'
          className='fs-5 text-primary' />
        <div className='ps-5'>
          <p className='mb-0 text-muted'>ผู้ยื่นข้อเสนอต้องยื่นข้อเสนอและเสนอราคา</p>
          <Row className='mb-2'>
            <Col sm={12}
              md={4}
              xl={3}>
              <DatePicker
                label='ตั้งแต่วันที่'
                value={inviteData.submitProposalsStartDate}
                onChange={val => setInviteData({ ...inviteData, submitProposalsStartDate: val })}
                rule={{ required: true }}
                disabled={isDisabled}
              />
            </Col>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input
                label='เวลา'
                type='time'
                value={submitProposalsStartTime}
                onChange={val => setSubmitProposalsStartTime(val)}
                rule={{ required: true }}
                disabled={isDisabled} />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col sm={12}
              md={4}
              xl={3}>
              <DatePicker
                label='ถึงวันที่'
                value={inviteData.submitProposalsEndDate}
                onChange={val => setInviteData({ ...inviteData, submitProposalsEndDate: val })}
                rule={{ required: true }}
                disabled={isDisabled}
              />
            </Col>
            <Col sm={12}
              md={4}
              xl={3}>
              <Input
                label='เวลา'
                type='time'
                value={submitProposalsEndTime}
                onChange={val => setSubmitProposalsEndTime(val)}
                rule={{ required: true }}
                disabled={isDisabled} />
            </Col>
          </Row>
          <p className='mb-0 text-muted'>ผู้สนใจต้องการทราบรายละเอียดเพิ่มเติมเกี่ยวกับรายละเอียดของขอบเขตงานทั้งโครงการภายในวันที่</p>
          <Row>
            <Col sm={12}
              md={4}
              xl={3}>
              <DatePicker
                label='วันที่'
                value={inviteData.needToKnowWithinDate}
                onChange={val => setInviteData({ ...inviteData, needToKnowWithinDate: val })}
                rule={{ required: false }}
                disabled={isDisabled}
              />
            </Col>
          </Row>
          <p className='mb-0 text-muted'>โดยธนาคารจะชี้แจงรายละเอียดดังกล่าว</p>
          <Row>
            <Col sm={12}
              md={4}
              xl={3}>
              <DatePicker
                label='วันที่'
                value={inviteData.clarifyDetailViaDate}
                onChange={val => setInviteData({ ...inviteData, clarifyDetailViaDate: val })}
                rule={{ required: false }}
                disabled={isDisabled}
              />
            </Col>
          </Row>
        </div>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='ผู้ประกอบการที่ต้องการเชิญชวน'
              className='fs-5 text-primary' />
          </div>
          <Button
            variant='outline-primary'
            onClick={() => setShowTraderModal(true)}
            disabled={(inviteData.id !== null && inviteData.id !== undefined) && !inviteData.isResponsible}
          >
            <FaPlus className='me-1' /> เพิ่มคู่ค้า
          </Button>
        </div>
        <Row className='gap-5'>
          <Col sm={12}>
            <Table hidePagination>
              <thead>
                <tr>
                  <th style={{ width: 100 }}>ลำดับ</th>
                  <th style={{ width: 300 }}>เลขประจำตัวผู้เสียภาษี</th>
                  <th style={{ width: 300 }}>ประเภทผู้ประกอบการ</th>
                  <th style={{ width: 300 }}>ชื่อสถานประกอบการ</th>
                  <th style={{ width: 100 }}>COI</th>
                  <th style={{ width: 100 }}>Watchlist</th>
                  <th style={{ width: 100 }}>e-GP</th>
                  <th style={{ width: 300 }}>อีเมล</th>
                  <th style={{ width: 100 }}>สถานะอีเมล</th>
                  <th style={{ width: 50 }} />
                </tr>
              </thead>
              <tbody>
                {inviteEntrepreneurs.sort((a, b) => a.sequence - b.sequence)
                  .map((data, index) => (
                    <tr className='text-center'
                      key={index}>
                      <td>{index + 1}</td>
                      <td>{data.traderTaxId}</td>
                      <td>{ENTREPRENEURTYPE.filter(x => x.value === data.traderType).length > 0 ? ENTREPRENEURTYPE.filter(x => x.value === data.traderType)[0].label : ''}</td>
                      <td>{data.establishmentName}</td>
                      <td>
                        <Button
                          variant={checkBooleanNullOrUndefined(data.coiResult) ? 'primary' : data.coiResult ? 'success' : 'secondary'}
                          onClick={() => onOpenCoiModal(data, TraderModalTypeConstant.COI, data.coiResult, data.coiRemark)}
                        >
                          {checkBooleanNullOrUndefined(data.coiResult) ? 'บันทึกผล' : 'บันทึกแล้ว'}
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant={checkBooleanNullOrUndefined(data.watchListResult) ? 'primary' : data.watchListResult ? 'success' : 'secondary'}
                          onClick={() => onOpenCoiModal(data, TraderModalTypeConstant.WATCHLIST, data.watchListResult, data.watchListRemark)}
                        >
                          {checkBooleanNullOrUndefined(data.watchListResult) ? 'บันทึกผล' : 'บันทึกแล้ว'}
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant={checkBooleanNullOrUndefined(data.egpResult) ? 'primary' : data.egpResult ? 'success' : 'secondary'}
                          onClick={() => onOpenCoiModal(data, TraderModalTypeConstant.EGP, data.egpResult, data.egpRemark)}
                        >
                          {checkBooleanNullOrUndefined(data.egpResult) ? 'บันทึกผล' : 'บันทึกแล้ว'}
                        </Button>
                      </td>
                      <td className='text-start'>
                        <div className='d-flex align-items-center gap-3'>
                          <Input
                            value={data.email}
                            onChange={val => handlerOnChangeEmail(val, index)}
                            rule={{ required: true }} />
                          <Button
                            onClick={() => onOpenEmailModal(data, '')}
                            disabled={inviteData.status !== InvitationStatusConstant.Approved}
                          >
                            ส่งอีเมล
                          </Button>
                        </div>
                      </td>
                      <td className='text-center'>
                        {
                          data.emailSent ? (
                            <Badge bg='success'
                              className='text-white'>ส่งแล้ว</Badge>
                          ) : (
                            <Badge bg='warning'
                              className='text-white'>ยังไม่ส่ง</Badge>
                          )
                        }
                      </td>
                      <td className='text-end'>
                        <Button
                          variant='danger'
                          disabled={(
                            inviteData.status !== InvitationStatusConstant.Draft
                            && inviteData.status !== InvitationStatusConstant.Rejected
                            && data.id !== undefined
                          )}
                          onClick={() => removeTrader(index)}
                        >
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              inviteId={inviteData.id}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="กลับหน้าแรก"
        />
        <div>
          {
            (inviteData.isResponsible && (data.status === InvitationStatusConstant.Draft || data.status === InvitationStatusConstant.Rejected)) && (
              <ButtonCustom
                onClick={onSave}
                text="บันทึก"
              />
            )
          }
          {
            (data.status === InvitationStatusConstant.WaitingForApprove
              && isCanRecall) && (
              <ButtonCustom
                onClick={() => setShowRecall(true)}
                icon='undo'
                variant='danger'
                text="เรียกคืนแก้ไข"
              />
            )
          }
          {/* {
            ((inviteData.status === InvitationStatusConstant.Draft || inviteData.status === InvitationStatusConstant.Rejected) && inviteData.isResponsible && (inviteData.inviteAcceptors && inviteData.inviteAcceptors.length > 0)) && (
              <Button
                variant='primary'
                className='me-2 px-3'
                onClick={() => setShowPendingModal(true)}
              >
                <FaRegCheckCircle className='me-2 pb-1 fs-5' />
                ส่งอนุมัติ/เห็นชอบ
              </Button>
            )
          } */}
        </div>
        {
          (inviteData.id && (data.inviteEntrepreneurs != null && data.inviteEntrepreneurs.length > 0)) ? (
            <ButtonCustom
              onClick={onNext}
              text="ถัดไป"
            />
          ) : (<div style={{ width: 150 }}></div>)
        }
      </div>
      <HistoryModal
        title='ประวัติการใช้งานจัดทำคำสั่งแต่งตั้งคณะกรรมาการจัดซื้อจัดจ้างและคณะกรรมการตรวจรับ'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={data.id}
      />
      <TraderModal
        show={showTraderModal}
        onHide={() => setShowTraderModal(!showTraderModal)}
        onSelectItem={onSelectTrader}
      />
      <CoiModal
        title={modalTitle}
        show={showCoiModal}
        modalType={modalType}
        onHide={() => setShowCoiModal(!showCoiModal)}
        traderModal={selectTraderModel}
        entrepreneurTypeDDL={entrepreneurTypeDDL}
        onSubmit={onSubmitTraderResultModal}
      />
      <Modal
        show={showRecall}
        size='lg'
        onHide={() => setShowRecall(!showRecall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowRecall(!showRecall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onRecall}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showPendingModal}
        size='lg'
        onHide={() => setShowPendingModal(!showPendingModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งอนุมัติ/เห็นชอบหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingModal(!showPendingModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onPending}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showSentEmail}
        size='lg'
        onHide={() => setShowSentEmail(!showSentEmail)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งอีเมลหนังสือประกาศเชิญชวนให้ผู้ประกอบการ {sentEmailTraderModal.establishmentName} หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowSentEmail(!showSentEmail)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onSentEmail}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step1;
