import {
  Selector,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { SearchNameModal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Card,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaPlus,
  FaSave,
  FaTrashAlt,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <Card.Body>
          <Title text="คณะกรรมการ" className="fs-5 text-primary" />
          <div className="d-flex justify-content-between my-3">
            <p>ข้อมูล</p>
            <Button
              variant="outline-primary"
              onClick={() => { setShow(true); }}
            >
              <FaPlus />เพิ่มข้อมูล
            </Button>
          </div>
          <Table total={1}>
            <thead>
              <tr className="text-center">
                <th>ลำดับ</th>
                <th>รหัสพนักงาน</th>
                <th className="text-start">ชื่อ-นามสกุล</th>
                <th className="text-start">ตำแหน่ง</th>
                <th>ตำแหน่งในคณะกรรมการ </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td>1</td>
                <td>5900010</td>
                <td className="text-start">สมศรี แดนไทย</td>
                <td className="text-start">รักษาการผู้อำนวยการสำนักงบประมาณ</td>
                <td>
                  <Selector />
                </td>
                <td>
                  <Button
                    variant="outline-primary"
                  >
                    <FaTrashAlt className="me-2" />ลบ
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-center gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          variant="light"
          className="me-2 px-3"
        >
          <FaSave className="ms-2 pb-1 fs-5" />
          บันทึก
        </Button>
        <Button
          className="me-2 px-3"
        >
          <FaAlignLeft className="me-2 pb-1 fs-5" />
          ส่งอนุมัติ
        </Button>
      </div>
      <SearchNameModal
        show={show}
        onHide={() => setShow(!show)}
        data={[]}
      />
    </div>
  );
}

export default Step2;
