import {
  ButtonCustom,
  ContractReceiveStatus,
  Currency,
  DatePicker,
  InputTextArea,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { StatusCMPaidPhase } from 'components/StatusCMPaidPhase';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  Col,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { IFile } from '../../../../models';
import { IPaidPhase } from '../../../../models/CM/CM03Models';
import CM03Service from '../../../../services/CM/CM03Service';
import { HttpStatusCode } from '../../../../utils';
import toast from '../../../../utils/toast';
import UploadFile from '../../Component/UploadFile';
import { HistoryModal } from '../Modal/HistoryReceiveModal';
import { TableExpandable3 } from './table';

export default function InfoSettlement() {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [form, setForm] = useState<IPaidPhase>({
    accountingPaidDate: undefined,
    activities: [],
    advanceAmount: 0,
    amount: 0,
    attachments: [],
    contractAgreementId: '',
    contractManagementReceives: [],
    fineAmount: 0,
    id: '',
    paidDate: undefined,
    receivedNumber: '',
    remainAmount: 0,
    remark: '',
    retentionAmount: 0,
    sequence: 0,
    status: '',
  });
  const [files, setFiles] = useState<IFile[]>([]);

  const navigate = useNavigate();

  const { contractId, paidId } = useParams();

  useEffect(() => {
    if (contractId && paidId) {
      getPaidPhaseAsync(contractId, paidId);
    }
  }, [contractId, paidId]);

  const getPaidPhaseAsync = async (contractId?: string, paidId?: string) => {
    if (!contractId || !paidId) {
      toast.error('ไม่พบรหัสสัญญาหรือรหัสการตั้งหนี้ตั้งเบิก');

      return;
    }

    const { data, status } = await CM03Service.getPaidPhaseAsync(contractId, paidId);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบรหัสสัญญาหรือรหัสการตั้งหนี้ตั้งเบิก');

      return;
    }

    const files: IFile[] = [];
    data.attachments?.forEach((item) => {
      files.push({
        id: item.id,
        name: item.fileName,
        file: null,
      });
    });

    setFiles(files);
    setForm(data);
  };

  const goToBack = () => {
    navigate(`/contract-management/detail/${contractId}`);
  };

  const onSave = useCallback(async (isConfirm: boolean) => {
    if (!paidId) {
      toast.warn('ไม่พบรหัสการเบิกจ่าย');

      return;
    }

    if (!form.paidDate) {
      toast.warn('กรุณาระบุวันที่เบิกจ่าย');

      return;
    }

    if (!form.accountingPaidDate) {
      toast.warn('กรุณาระบุวันที่เบิกจ่าย (สำหรับ บัญชี)');

      return;
    }

    if (!form.amount || form.amount <= 0) {
      toast.warn('กรุณาระบุจำนวนเงิน');

      return;
    }

    // if (!form.retentionAmount || form.retentionAmount <= 0) {
    //   toast.error('กรุณากรอกข้อมูลหักประกันผลงาน');

    //   return;
    // }

    // if (!form.advanceAmount || form.advanceAmount <= 0) {
    //   toast.error('กรุณากรอกข้อมูลเงินล่วงหน้า');

    //   return;
    // }

    // if (!form.fineAmount || form.fineAmount <= 0) {
    //   toast.error('กรุณากรอกข้อมูลค่าปรับ');

    //   return;
    // }

    // if (!form.remainAmount || form.remainAmount < 0) {
    //   toast.warn('กรุณาระบุคงเหลือที่ต้องจ่าย');

    //   return;
    // }

    const { status } = await CM03Service.updatePaidPhaseAsync(paidId, form);
    if (status === HttpStatusCode.CREATED || status === HttpStatusCode.OK) {
      if (isConfirm) {

        if (!form.attachments || form.attachments.length === 0) {
          toast.warn('กรุณาอัปโหลดเอกสารแนบ');

          return;
        }

        const { status } = await CM03Service.confirmPaidAsync(paidId);

        toast.success(status === HttpStatusCode.CREATED || status === HttpStatusCode.OK ? 'ยืนยันเบิกจ่ายสำเร็จ' : '');
      } else {
        toast.success('บันทึกข้อมูลสำเร็จ');
      }
    } else {
      toast.error('บันทึกข้อมูลไม่สำเร็จ');

      return;
    }

    await getPaidPhaseAsync(contractId, paidId);

    // goToBack();
  }, [paidId, form]);

  // TODO:This Function upload document for example
  const handleFileChange = async (files: File[]) => {

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (paidId) {
      const res = await CM03Service.uploadPaidPhaseAttachmentAsync(
        paidId,
        files,
      );

      if (res.status !== HttpStatusCode.OK) {
        toast.error('อัพโหลดไฟล์ไม่สำเร็จ');

        return;
      }

      toast.success('อัปโหลดไฟล์สำเร็จ');

      await getAttachmentByIdAsync(contractId, paidId);
    }
  };

  const getAttachmentByIdAsync = useCallback(async (contractId?: string, paidId?: string) => {

    if (contractId && paidId) {

      const { data, status } = await CM03Service.getPaidPhaseAsync(contractId, paidId);

      if (status !== HttpStatusCode.OK) {
        toast.error('ไม่สามารถโหลดข้อมูลได้');

        return;
      }

      const files: IFile[] = [];
      data.attachments?.forEach((item) => {
        files.push({
          id: item.id,
          name: item.fileName,
          file: null,
        });
      });

      setFiles(files);
    }

  }, []);

  const removeItemAsync = useCallback(async (index: number, docId: string) => {
    if (paidId) {
      const res = await CM03Service.removePaidPhaseFileAsync(
        paidId,
        docId,
      );

      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('ลบไฟล์ไม่สำเร็จ');

        return;
      }

      toast.success('ลบไฟล์สำเร็จ');

      await getAttachmentByIdAsync(contractId, paidId);
    }
  }, [paidId]);

  const downloadFile = useCallback(async (file: IFile) => {
    if (!file.file) {
      const { data, status } = await CM03Service.downloadDeliveryAttachmentAsync(paidId ?? '', file.id);

      if (status !== HttpStatusCode.OK) {
        return;
      }

      const fileUrl = URL.createObjectURL(data);
      window.open(fileUrl, '_blank');

      return;
    }

    const fileUrl = URL.createObjectURL(file.file);
    window.open(fileUrl, '_blank');
  }, [paidId]);
  // TODO: This Function upload document for example

  const onChangeAmount = (amount: number, retentionAmount: number, advanceAmount: number, fineAmount: number) => {
    const remainAmount = (amount || 0) - ((retentionAmount || 0) + (advanceAmount || 0) + (fineAmount || 0));

    setForm({
      ...form, amount, retentionAmount, advanceAmount, fineAmount, remainAmount,
    });
  };

  return (
    <>
      <h5 className='fw-bold mb-3'>ข้อมูลการตั้งหนี้ตั้งเบิก</h5>
      <Title
        text='ตั้งหนี้ตั้งเบิก'
        extraElement={(
          <div className='d-flex gap-3'>
            <StatusCMPaidPhase systemStatue={form.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => setShowHistoryModal(true)}
              text='ประวัติการใช้งาน'
              icon='history' />
          </div>
        )} />

      <Row className='mt-3 align-items-center'>
        <h5 className='fw-bold'>บันทึกเบิกจ่าย</h5>
        <Col sm={12} md={6} lg={4} className='mt-3'>
          <DatePicker rule={{ required: true }} label='วันที่เบิกจ่าย' value={form.paidDate} onChange={(value) => setForm((pre) => ({ ...pre, paidDate: value }))} />
        </Col>
        <Col sm={12} />
        <Col sm={12} md={6} lg={6}>
          <InputTextArea label='หมายเหตุเบิกจ่าย' value={form.remark} onChange={(value) => setForm((pre) => ({ ...pre, remark: value }))} />
        </Col>
      </Row>

      <Row className='mt-3 align-items-center'>
        <Col sm={12} md={6} lg={4}>
          <DatePicker rule={{ required: true }} label='วันที่เบิกจ่าย (สำหรับ บัญชี)' value={form.accountingPaidDate} onChange={(value) => setForm((pre) => ({ ...pre, accountingPaidDate: value }))} />
        </Col>
        <Col sm={12} />
        <Col sm={12} md={6} lg={4}>
          <Currency label='หักประกันผลงาน'
            value={form.retentionAmount}
            disabled
            onChange={(val) => onChangeAmount(form.amount, Number(val), form.advanceAmount, form.fineAmount)}
          />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Currency label='เงินล่วงหน้า'
            value={form.advanceAmount}
            disabled
            onChange={(val) => onChangeAmount(form.amount, form.retentionAmount, Number(val), form.fineAmount)}
          />
        </Col>
        <Col sm={12} />
        <Col sm={12} md={6} lg={4}>
          <Currency label='ค่าปรับ'
            value={form.fineAmount}
            disabled
            onChange={(val) => onChangeAmount(form.amount, form.retentionAmount, form.advanceAmount, Number(val))}
          />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Currency label='ค่าใช้จ่ายอื่นๆ' value={form.amountPaidFine} disabled />
        </Col>
        <Col sm={12} />
        <Col sm={12} md={6} lg={4}>
          <Currency label='จำนวนเงิน'
            value={form.amount}
            rule={{ required: true }}
            onChange={(val) => onChangeAmount(Number(val), form.retentionAmount, form.advanceAmount, form.fineAmount)}
            disabled
          />
        </Col>
      </Row>
      {/*  <div>
        <div className='d-flex justify-content-between my-3'>
          <p>รายการค่าปรับอื่นๆ</p>
        </div>
        <Row className='mt-1 align-items-center'>
          <Col sm={12}>
            <Table total={1}
              hidePagination>
              <thead>
                <tr>
                  <th style={{ width: 20 }}>ลำดับ</th>
                  <th style={{ width: '80%' }}>รายละเอียด</th>
                  <th style={{ width: 400 }}>จำนวนเงิน</th>
                </tr>
              </thead>
              <tbody>
                {form.paidFineAmount?.sort((a, b) => a.sequence - b.sequence)
                  .map((data, index) => (
                    <tr>
                      <td className='text-center'>{index + 1} </td>
                      <td className='text-start'>
                        <Input
                          rule={{ required: true }}
                          type='text'
                          value={data.detail || ''}
                          disabled
                        />
                      </td>
                      <td className='text-end'>
                        <Currency
                          rule={{ required: true }}
                          value={data.amount || 0}
                          disabled
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div> */}
      <Row className='mt-3'>
        <Col sm={12} md={6} lg={4}>
          <Currency label='คงเหลือที่ต้องจ่าย' value={form.remainAmount} onChange={(value) => setForm((pre) => ({ ...pre, remainAmount: value ?? 0 }))} disabled />
        </Col>
      </Row>
      <Row className='mt-3'>
        <h5 className='fw-bold mb-3'>รายการตรวจรับ</h5>
        <Col sm={12}>
          <Table hidePagination>
            <thead>
              <tr className='text-center'>
                <th style={{ width: '10%' }}>ลำดับ</th>
                <th style={{ width: '10%' }}>วันที่ประชุมตรวจรับ</th>
                <th style={{ width: '20%' }}>เลขที่ตรวจรับ</th>
                <th style={{ width: '20%' }}>รายละเอียด</th>
                <th style={{ width: 100 }}>การตรวจรับ</th>
                <th style={{ width: 100 }} />
                <th style={{ width: 100 }} />
              </tr>
            </thead>
            <tbody>
              {
                form.contractManagementReceives?.sort((a, b) => a.sequence - b.sequence).map((data, index) => (
                  <TableExpandable3 key={data.id} data={data} Index={index} />
                ))
              }
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Card className='mt-3 py-3'>
          <Title text='เอกสารแนบ' className='fs-5' />
          <Row className='justify-content-center'>
            <Col sm={12} lg={6}>
              <UploadFile
                files={files}
                handleFileChange={handleFileChange}
                removeItem={removeItemAsync}
                downLoadFile={downloadFile}
                contractRecordId={paidId!} />
            </Col>
          </Row>
        </Card>
      </Row>
      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <ButtonCustom
            text='ย้อนกลับ'
            onClick={() => goToBack()}
            icon='arrowback'
            variant='outline-primary' />
        </div>
        {
          (form.status !== ContractReceiveStatus.Paid) && (
            <div className='d-flex gap-3 align-items-center'>
              <ButtonCustom
                text='บันทึก'
                variant='outline-primary'
                onClick={() => onSave(false)} />
              <ButtonCustom
                text='ยืนยันเบิกจ่าย'
                onClick={() => onSave(true)} />
            </div>
          )
        }
        <div />
      </div>

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        activities={form.activities}
        title='การตั้งหนี้ตั้งเบิก'
        status={ContractReceiveStatus.PaidPhase}
      />

    </>
  );
}
