import {
  Breadcrumb,
  Card,
  Input,
  Radio,
  Selector,
  UploadFile,
} from 'components';
import {
  district,
  provinces,
  subdistrict,
} from 'data';
import { ItemModel, ProvincesModel, Trader } from 'models';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { trader as traderService } from 'services';
import provincesServices from 'services/provincesServices';
import {
  HttpStatusCode,
  submitForm,
  useAppContext,
  ValidatePattern,
} from 'utils';
import regex from 'utils/regex';
import toast from 'utils/toast';

const NATIONALITY = [
  { label: 'ไทย', value: 'th' },
  { label: 'ต่างชาติ', value: 'foreign' },
];

const TYPE = [
  { label: 'นิติบุคคล', value: 'JuristicPerson' },
  { label: 'บุคคลธรรมดา', value: 'Individual' },
];

const BREADCRUMB_INFO = [
  {
    routerName: '/su/su04', label: 'ข้อมูลคู่ค้า',
  },
  {
    routerName: '', label: 'รายละเอียดข้อมูลคู่ค้า',
  },
];

type Loader = {
  trader: Trader,
  traderTypes: { label: string, value: string }[],
  provincesDDL: ItemModel[],
};

export default function SU04Detail() {
  const loader = useLoaderData() as Loader;
  const [trader, setTrader] = useState<Trader>({} as Trader);
  const [districtList, setDistrictList] = useState<{ label: string, value: string }[]>([]);
  const [subdistrictList, setSubdistrictList] = useState<{ label: string, value: string }[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const navigate = useNavigate();
  const { userId } = useAppContext();

  const backToIndex = () => navigate('/su/su04/');

  useEffect(() => {
    if (loader?.trader) {
      setTrader(loader.trader);

      if (loader.trader.province) {
        getDistrictData(loader.trader.province);
      }

      if (loader.trader.district) {
        getSubdistrictData(loader.trader.district);
      }
    }
  }, []);

  const getDistrictData = async (provinceId: string) => {
    const itemForMap: ItemModel[] = [];
    const response = await provincesServices.getDistrictListAsync(provinceId);
    const items: ProvincesModel[] = response.data;

    items.sort((a, b) => {
      if (a.nameTh < b.nameTh) { return -1; }
      if (a.nameTh > b.nameTh) { return 1; }
      return 0;
    })
      .forEach((data) =>
        itemForMap.push({
          label: data.nameTh,
          value: data.code ?? '',
        })
      );

    setDistrictList(itemForMap);
  };

  const getSubdistrictData = async (districtId: string) => {
    const itemForMap: ItemModel[] = [];
    const response = await provincesServices.getSubDistrictListAsync(districtId);
    const items: ProvincesModel[] = response.data;

    items.sort((a, b) => {
      if (a.nameTh < b.nameTh) { return -1; }
      if (a.nameTh > b.nameTh) { return 1; }
      return 0;
    })
      .forEach((data) =>
        itemForMap.push({
          label: data.nameTh,
          value: data.code ?? '',
        })
      );

    setSubdistrictList(itemForMap);
  };

  const handlerOnSaveAsync = async () => {
    submitForm(trader, true);
    const checkRequired = trader.nationality && trader.type
      && trader.taxpayerIdentificationNo && trader.entrepreneurType
      && trader.establishmentName && trader.address
      && trader.province && trader.district
      && trader.subDistrict && trader.postalCode
      && trader.tel && trader.email && regex.validateEmail(trader.email.toString());

    if (checkRequired) {
      if (trader.id) {
        const res = await traderService
          .updateTraderAsync(trader.id, trader, files);

        if (res.status === HttpStatusCode.ACCEPTED) {
          backToIndex();
          toast.success('แก้ไขคู่ค้าสำเร็จ');
          navigate(-1);
          return;
        }
        toast.error(res.statusText);
      } else {
        const res = await traderService
          .saveTraderAsync(trader, files);

        if (res.status === HttpStatusCode.CREATED) {
          backToIndex();
          toast.success('สร้างคู่ค้าสำเร็จ');
          const { id } = res.data;
          setTrader({ ...trader, id });
          navigate(-1);
          return;
        }
        toast.error(res.statusText);
      }
    } else {
      toast.warn('กรุณาระบุข้อมูลให้ถูกต้องและครบถ้วน');
    }
  };

  const provinceOnChange = (val: string) => {
    if (val) {
      setTrader({
        ...trader,
        province: val,
      });

      getDistrictData(val);
    } else {
      setDistrictList([]);
      setTrader({
        ...trader,
        district: '',
        subDistrict: '',
        postalCode: '',
      });
    }
  };

  const districtOnChange = (val: string) => {
    if (val) {
      setTrader({
        ...trader,
        district: val,
      });

      getSubdistrictData(val);
    } else {
      setSubdistrictList([]);
      setTrader({
        ...trader,
        subDistrict: '',
        postalCode: '',
      });
    }
  };

  const subdistrictOnChange = (val: string) => {
    if (val) {
      const subdistrictSelected = subdistrict
        .find((s) => s.id.toString() === val);

      if (subdistrictSelected) {
        setTrader({
          ...trader,
          subDistrict: val,
          postalCode: subdistrictSelected.zip_code.toString(),
        });
      }
    } else {
      setTrader({
        ...trader,
        postalCode: '',
      });
    }
  };

  return (
    <div className="m04">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mt-2 text-primary">
          <Button variant="link" onClick={backToIndex}>
            <MdOutlineArrowBack className="fs-4 text-primary mb-1" />
          </Button>
          ข้อมูลคู่ค้า
        </h4>
      </div>

      <Breadcrumb data={BREADCRUMB_INFO} />
      <Card>
        <p>ข้อมูลผู้ประกอบการ</p>
        <Row>
          <Col xs={12}>
            <Radio
              inline
              items={NATIONALITY}
              name="nationality"
              label="สัญชาติของผู้ประกอบการ"
              rule={{ required: true }}
              value={trader.nationality}
              onChange={(val) => setTrader({ ...trader, nationality: val.toString() })}
            />
          </Col>
          <Col xs={12}>
            <Radio
              inline
              items={TYPE}
              name="type"
              label="ประเภท"
              rule={{ required: true }}
              value={trader.type}
              onChange={(val) => setTrader({ ...trader, type: val.toString() })}
            />
          </Col>
          <Col sm={6}>
            <Input
              name="taxpayerIdentificationNo"
              label="เลขประจำตัวผู้เสียภาษี"
              rule={{ required: true, pattern: ValidatePattern.TAXNUMBER }}
              maxlength={13}
              value={trader.taxpayerIdentificationNo}
              onChange={(val) => setTrader({ ...trader, taxpayerIdentificationNo: val })}
            />
          </Col>
          <Col sm={6}>
            <Selector
              name="entrepreneurType"
              label="ประเภทผู้ประกอบการ"
              rule={{ required: true }}
              items={loader.traderTypes}
              value={trader.entrepreneurType}
              onChange={(val) => setTrader({ ...trader, entrepreneurType: val.toString() })}
            />
          </Col>
          <Col sm={6}>
            <Input
              name="establishmentName"
              label="ชื่อสถานประกอบการ"
              rule={{ required: true }}
              value={trader.establishmentName}
              onChange={(val) => setTrader({ ...trader, establishmentName: val })}
            />
          </Col>
          <Col sm={6}>
            <Input
              name="placeName"
              label="ชื่ออาคาร/ชื่อสถานที่"
              value={trader.placeName}
              onChange={(val) => setTrader({ ...trader, placeName: val })}
            />
          </Col>
          <Col sm={6}>
            <Input
              label="ห้องเลขที่"
              value={trader.roomNo}
              onChange={(val) => setTrader({ ...trader, roomNo: val })}
            />
          </Col>
          <Col sm={6}>
            <Input
              label="ชั้นที่"
              value={trader.floorNo}
              onChange={(val) => setTrader({ ...trader, floorNo: val })}
            />
          </Col>
          <Col sm={6}>
            <Input
              label="หมู่บ้าน"
              value={trader.villageName}
              onChange={(val) => setTrader({ ...trader, villageName: val })}
            />
          </Col>
          <Col sm={6}>
            <Input
              label="เลขที่บ้าน"
              value={trader.address}
              rule={{ required: true }}
              onChange={(val) => setTrader({ ...trader, address: val })}
            />
          </Col>
          <Col sm={6}>
            <Input
              label="หมู่ที่"
              value={trader.group}
              onChange={(val) => setTrader({ ...trader, group: val })}
            />
          </Col>
          <Col sm={6}>
            <Input
              label="ซอย"
              value={trader.alley}
              onChange={(val) => setTrader({ ...trader, alley: val })}
            />
          </Col>
          <Col sm={6}>
            <Input
              label="ถนน"
              value={trader.road}
              onChange={(val) => setTrader({ ...trader, road: val })}
            />
          </Col>
          <Col sm={6}>
            <Selector
              name="province"
              label="จังหวัด"
              rule={{ required: true }}
              items={loader.provincesDDL}
              value={trader.province}
              onChange={(val) => provinceOnChange(val)}
            />
          </Col>
          <Col sm={6}>
            <Selector
              name="district"
              label="อำเภอ/เขต"
              rule={{ required: true }}
              items={districtList}
              value={trader.district}
              onChange={(val) => districtOnChange(val)}
            />
          </Col>
          <Col sm={6}>
            <Selector
              name="subDistrict"
              label="ตำบล/แขวง"
              rule={{ required: true }}
              items={subdistrictList}
              value={trader.subDistrict}
              onChange={(val) => subdistrictOnChange(val)}
            />
          </Col>
          <Col sm={6}>
            <Input
              name="postalCode"
              label="รหัสไปรษณีย์"
              rule={{ required: true }}
              value={trader.postalCode}
              onChange={(val) => setTrader({ ...trader, postalCode: val })}
            />
          </Col>
          <Col sm={6}>
            <Input
              name="tel"
              label="หมายเลขโทรศัพท์สำหรับติดต่อ"
              rule={{ required: true }}
              value={trader.tel}
              onChange={(val) => setTrader({ ...trader, tel: val })}
            />
          </Col>
          <Col sm={6}>
            <Input
              label="หมายเลขโทรสาร"
              value={trader.fax}
              onChange={(val) => setTrader({ ...trader, fax: val })}
            />
          </Col>
          <Col sm={6}>
            <Input
              name="email"
              label="อีเมล"
              rule={{ required: true, pattern: ValidatePattern.EMAIL }}
              value={trader.email}
              onChange={(val) => setTrader({ ...trader, email: val })}
            />
          </Col>
        </Row>
      </Card>
      <Card className="mt-3">
        <p>อัปโหลดเอกสาร</p>
        <UploadFile
          onChange={(val) => setFiles([...files, val])}
          // onRemove={fileOnRemove}
          value={trader.documents}
          userId={userId}
        />
      </Card>
      <div className='d-flex justify-content-center pt-3'>
        <Button onClick={handlerOnSaveAsync} className="d-flex align-items-center gap-2" variant='success'>บันทึก</Button>
      </div>
    </div>
  );
}
