import StepProgress, { ProgressBarRefType } from 'components/StepProgress';
import {
  InstallmentModel,
  ParcelModel,
} from 'models/CM/ManageContractModels';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

export const Context = createContext({} as InstallmentContext);

type InstallmentContext = {
  data: InstallmentModel
  setData: Dispatch<React.SetStateAction<InstallmentModel>>,
};

export default function InspectDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const navigate = useNavigate();
  const { id, inspectId } = useParams();
  const [data, setData] = useState<InstallmentModel>({} as InstallmentModel);

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  useEffect(() => {
    const parcels: ParcelModel[] = [
      {
        id: 'p00001',
        installment: 1,
        sequence: 1,
        description: 'แผนการดำเนินโครงการ (Project Planning)',
        quantity: 1,
        unit: 'ฉบับ',
        price: 150000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
      {
        id: 'p00002',
        installment: 1,
        sequence: 2,
        description: 'แผนการดำเนินโครงการ (Project Planning) ที่ 2',
        quantity: 1,
        unit: 'ฉบับ',
        price: 150000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
      {
        id: 'p00003',
        installment: 2,
        sequence: 3,
        description: 'รายงานรายละเอียดการวิเคราะห์และออกแบบระบบ (System Analysis and Design)',
        quantity: 1,
        unit: 'ฉบับ',
        price: 400000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
      {
        id: 'p00004',
        installment: 2,
        sequence: 4,
        description: 'รายงานสถาปัตยกรรมระบบของ โครงการ (System Architecture Design)',
        quantity: 1,
        unit: 'ฉบับ',
        price: 100000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
      {
        id: 'p00005',
        installment: 3,
        sequence: 5,
        description: 'รายงานการออกแบบ (Conceptual design)',
        quantity: 1,
        unit: 'ฉบับ',
        price: 500000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
      {
        id: 'p00006',
        installment: 3,
        sequence: 6,
        description: 'ระบบบันทึกเสียงออนไลน์',
        quantity: 1,
        unit: 'ฉบับ',
        price: 500000,
        vatType: 'มี VAT',
        deliveryQuantityTotal: 1,
        deliveryQuantity: 0,
        remark: '',
        status: 'Pending',
      },
    ];

    const installmentData: InstallmentModel[] = [
      {
        id: 'ins001',
        installmentSequence: 1,
        paidPercent: 30,
        paidAmount: 250000,
        fineTotal: 0,
        totalBalance: 500000,
        status: 'process',
        deliverySetDate: new Date(),
        deliveryPeriod: 30,
        deliveryPeriodType: 'day',
        deliveryDate: new Date(),
        deliveryRemark: 'ส่งมอบครบถ้วน',
        parcels: parcels.filter(x => x.installment === 1),
        deliveryLists: [],
      },
      {
        id: 'ins002',
        installmentSequence: 2,
        paidPercent: 30,
        paidAmount: 500000,
        fineTotal: 0,
        totalBalance: 500000,
        status: 'process',
        deliverySetDate: new Date(),
        deliveryPeriod: 60,
        deliveryPeriodType: 'day',
        deliveryDate: new Date(),
        deliveryRemark: 'ส่งมอบครบถ้วน',
        parcels: parcels.filter(x => x.installment === 2),
        deliveryLists: [],
      },
      {
        id: 'ins003',
        installmentSequence: 3,
        paidPercent: 40,
        paidAmount: 1000000,
        fineTotal: 0,
        totalBalance: 500000,
        status: 'process',
        deliverySetDate: new Date(),
        deliveryPeriod: 150,
        deliveryPeriodType: 'day',
        deliveryDate: new Date(),
        deliveryRemark: 'ส่งมอบครบถ้วน',
        parcels: parcels.filter(x => x.installment === 3),
        deliveryLists: [],
      },
    ];

    if (inspectId) {
      setData(installmentData.filter(x => x.id === inspectId)[0]);
    }

  }, [id, inspectId]);

  const backToIndex = () => {
    navigate(`/manage-contractMangement/detail/${id}`);
  };

  const obj = [
    {
      title: <p>บันทึกส่งมอบ</p>,
      displayIndex: '1',
      child: <Step1 onClickNext={() => clickNext()}
        onClickBack={() => backToIndex()} />,
    },
    {
      title: <p>บันทึกส่งตรวจรับ</p>,
      displayIndex: '2',
      child: <Step2 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />,
    },
    {
      title: <p>แจ้งรับทราบ</p>,
      displayIndex: '3',
      child: <Step3 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />,
    },
    {
      title: <p>บันทึกเบิกจ่าย</p>,
      displayIndex: '4',
      child: <Step4 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />,
    },
  ];

  return (
    <div>
      <Row>
        <Col xs={12}
          className='mb-2'>
          <h4 className='mt-2 mb-0 text-primary text-start'>
            <Button variant='link'
              onClick={backToIndex}>
              <MdOutlineArrowBack className='fs-4 text-primary mb-1' />
            </Button>บริหารสัญญา
          </h4>
          <Context.Provider value={{ data, setData }}>
            <StepProgress progressBarData={obj}
              ref={childRef} />
          </Context.Provider>
        </Col>
      </Row>
    </div>
  );
}
