import { PreProcumentFlag } from 'constant/PreProcumentFlag';
import Cookie from 'cookie-universal';
import { CriteriaPlanProcurementListModel } from 'models';
import {
  CountStatusModel,
  CountSupplyMethodModel,
  CreateAppointmenteditingModel,
  CreatePP0201Model,
  SearchPP0201Model,
  UpdateAppointmentDetailModel,
} from 'models/PP/PreProcurementCreateDirectiveModels';
import http from 'utils/axios';

const qs = require('qs');

const cookies = Cookie();
const getFullName = cookies.get('fullName') as string;

const getListAppointmentAsync = (searchData: SearchPP0201Model, flag?: PreProcumentFlag) => {
  let api = '/appointment';

  if (flag === PreProcumentFlag.Editing) {
    api = '/appointmentEditing';
  }

  if (flag === PreProcumentFlag.Cancel) {
    api = '/appointmentCancel';
  }

  const params = {
    page: searchData.page,
    size: searchData.size,
    appointNumber: searchData.appointNumber,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    memorandumDate: searchData.memorandumDate,
    planBudgetStart: searchData.planBudgetStart,
    planBudgetEnd: searchData.planBudgetEnd,
    isResponsible: searchData.isResponsible,
    supplyMethodIds: searchData.supplyMethodIds,
    status: searchData.status,
    expectingProcurementAt: searchData.expectingProcurementAt,
    planNumber: searchData.planNumber,
  };
  return http.get(`${api}/pagination`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountStatusAsync = (searchData: SearchPP0201Model, flag?: PreProcumentFlag) => {
  let api = '/appointment';

  if (flag === PreProcumentFlag.Editing) {
    api = '/appointmentEditing';
  }

  if (flag === PreProcumentFlag.Cancel) {
    api = '/appointmentCancel';
  }

  const params = {
    page: searchData.page,
    size: searchData.size,
    appointNumber: searchData.appointNumber,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    memorandumDate: searchData.memorandumDate,
    planBudgetStart: searchData.planBudgetStart,
    planBudgetEnd: searchData.planBudgetEnd,
    isResponsible: searchData.isResponsible,
    supplyMethodIds: searchData.supplyMethodIds,
    status: searchData.status,
    expectingProcurementAt: searchData.expectingProcurementAt,
    planNumber: searchData.planNumber,
  };
  return http.get<CountStatusModel[]>(`${api}/countStatus`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountSupplyMethodAsync = (searchData: SearchPP0201Model, flag?: PreProcumentFlag) => {
  let api = '/appointment';

  if (flag === PreProcumentFlag.Editing) {
    api = '/appointmentEditing';
  }

  if (flag === PreProcumentFlag.Cancel) {
    api = '/appointmentCancel';
  }

  const params = {
    page: searchData.page,
    size: searchData.size,
    appointNumber: searchData.appointNumber,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    memorandumDate: searchData.memorandumDate,
    planBudgetStart: searchData.planBudgetStart,
    planBudgetEnd: searchData.planBudgetEnd,
    isResponsible: searchData.isResponsible,
    supplyMethodIds: searchData.supplyMethodIds,
    status: searchData.status,
    expectingProcurementAt: searchData.expectingProcurementAt,
    planNumber: searchData.planNumber,
  };
  return http.get<CountSupplyMethodModel[]>(`${api}/countSupplyMethod`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getListHistoryAsync = (page: number, size: number, appointNumber: string | undefined) => {
  const params = {
    page,
    size,
    appointNumber,
  };

  return http.get('/appointment/history', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getAppointmentByIdAsync = (id: string) => http.get(`/appointment/${id}`);

const createAppointmentAsync = (body: CreatePP0201Model) => {
  const formData = new FormData();
  formData.append('planId', body.planId !== undefined ? body.planId : '');
  formData.append('departmentId', body.departmentId);
  formData.append('budgetYear', body.budgetYear.toString());
  formData.append('planName', body.planName);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodType', body.supplyMethodType);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString()
    .split('T')[0]);
  formData.append('memorandumDate', new Date(body.memorandumDate).toISOString());
  formData.append('createdByUserFullName', getFullName);
  formData.append('memorandumNo', body.memorandumNo ? body.memorandumNo : '');
  formData.append('sourceAndReasons', body.sourceAndReasons);
  formData.append('isConsumableMaterials', body.isConsumableMaterials ? 'true' : 'false');

  if (body.files && body.files.length !== 0) {
    body.files.forEach((file) => {
      formData.append('files', file);
    });
  }

  if (body.createCommitteeTors && body.createCommitteeTors.length !== 0) {
    body.createCommitteeTors.forEach((data, index) => {
      formData.append(`createCommitteeTors[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`createCommitteeTors[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`createCommitteeTors[${index}][department]`, data.department);
      formData.append(`createCommitteeTors[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`createCommitteeTors[${index}][positionOnBoardName]`, data.positionOnBoardName);
      formData.append(`createCommitteeTors[${index}][position]`, data.position);
      formData.append(`createCommitteeTors[${index}][sequence]`, (index + 1).toString());

    });
  }

  if (body.createDutyCommitteeTors && body.createDutyCommitteeTors.length !== 0) {
    body.createDutyCommitteeTors.forEach((data, index) => {
      formData.append(`createDutyCommitteeTors[${index}][dutyDescription]`, data.duty ? data.duty : '');
      formData.append(`createDutyCommitteeTors[${index}][sequence]`, (index + 1).toString());
    });
  }

  if (body.createCommitteeMiddlePrices && body.createCommitteeMiddlePrices.length !== 0) {
    body.createCommitteeMiddlePrices.forEach((data, index) => {
      formData.append(`createCommitteeMiddlePrices[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`createCommitteeMiddlePrices[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`createCommitteeMiddlePrices[${index}][department]`, data.department);
      formData.append(`createCommitteeMiddlePrices[${index}][position]`, data.position);
      formData.append(`createCommitteeMiddlePrices[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`createCommitteeMiddlePrices[${index}][positionOffBoardName]`, data.positionOnBoardName);
      formData.append(`createCommitteeMiddlePrices[${index}][sequence]`, (index + 1).toString());
    });
  }

  if (body.createDutyCommitteeMiddlePrices && body.createDutyCommitteeMiddlePrices.length !== 0) {
    body.createDutyCommitteeMiddlePrices.forEach((data, index) => {
      formData.append(`createDutyCommitteeMiddlePrices[${index}][dutyDescription]`, data.duty ? data.duty : '');
      formData.append(`createDutyCommitteeMiddlePrices[${index}][sequence]`, (index + 1).toString());
    });
  }

  if (body.createApproverAppointPreProcurements && body.createApproverAppointPreProcurements.length !== 0) {
    let inx = 0;
    let sectionSeq = 0;
    body.createApproverAppointPreProcurements.forEach((data, index) => {

      if (sectionSeq !== data.sectionSequence) {
        inx = 1;
        sectionSeq = data.sectionSequence;
      }

      formData.append(`createApproverAppointPreProcurements[${index}][approverUserId]`, data.approverUserId);
      formData.append(`createApproverAppointPreProcurements[${index}][approverUserName]`, data.approveFullName);
      formData.append(`createApproverAppointPreProcurements[${index}][position]`, data.position);
      formData.append(`createApproverAppointPreProcurements[${index}][remark]`, (data.remark ? data.remark : ''));
      formData.append(`createApproverAppointPreProcurements[${index}][isDisabled]`, data.isDisabled ? 'true' : 'false');
      formData.append(`createApproverAppointPreProcurements[${index}][departmentName]`, data.departmentName);
      formData.append(`createApproverAppointPreProcurements[${index}][sequence]`, (inx).toString());
      formData.append(`createApproverAppointPreProcurements[${index}][inRefCode]`, data.inRefCode);
      formData.append(`createApproverAppointPreProcurements[${index}][sectionSequence]`, data.sectionSequence.toString());

      inx++;
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post('/appointment', formData, config);
};

const updateAppointmentAsync = (body: UpdateAppointmentDetailModel) => {
  const formData = new FormData();

  formData.append('id', body.id);
  if (body.planId && body.planId.length > 0) {
    formData.append('planId', body.planId);
  }
  formData.append('departmentId', body.departmentId);
  if (body.documentId) {
    formData.append('documentId', body.documentId!);
  }
  formData.append('budgetYear', body.budgetYear.toString());
  formData.append('planName', body.planName);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodType', body.supplyMethodType);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString()
    .split('T')[0]);
  formData.append('status', body.status);
  formData.append('updateByUserFullName', getFullName);
  formData.append('memorandumNo', body.memorandumNo ? body.memorandumNo : '');
  formData.append('sourceAndReasons', body.sourceAndReasons);
  formData.append('remark', body.remark === null ? '' : body.remark);
  formData.append('isConsumableMaterials', body.isConsumableMaterials ? 'true' : 'false');

  if (body.files && body.files.length !== 0) {
    body.files.forEach((file) => {
      formData.append('files', file);
    });
  }

  if (body.updateCommitteeTors && body.updateCommitteeTors.length > 0) {
    body.updateCommitteeTors.forEach((data, index) => {
      if (data.id) {
        formData.append(`updateCommitteeTors[${index}][id]`, data.id);
      }
      formData.append(`updateCommitteeTors[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`updateCommitteeTors[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`updateCommitteeTors[${index}][department]`, data.department);
      formData.append(`updateCommitteeTors[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`updateCommitteeTors[${index}][positionOnBoardName]`, data.positionOnBoardName);
      formData.append(`updateCommitteeTors[${index}][position]`, data.position);
      formData.append(`updateCommitteeTors[${index}][sequence]`, (index + 1).toString());

    });
  }

  if (body.updateDutyCommitteeTors && body.updateDutyCommitteeTors.length !== 0) {
    body.updateDutyCommitteeTors.forEach((data, index) => {
      if (data.id) {
        formData.append(`updateDutyCommitteeTors[${index}][id]`, data.id);
      }
      formData.append(`updateDutyCommitteeTors[${index}][dutyDescription]`, data.duty ? data.duty : '');
      formData.append(`updateDutyCommitteeTors[${index}][sequence]`, (index + 1).toString());
    });
  }

  if (body.updateCommitteeMiddlePrices && body.updateCommitteeMiddlePrices.length !== 0) {
    body.updateCommitteeMiddlePrices.forEach((data, index) => {
      if (data.id) {
        formData.append(`updateCommitteeMiddlePrices[${index}][id]`, data.id);
      }
      formData.append(`updateCommitteeMiddlePrices[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`updateCommitteeMiddlePrices[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`updateCommitteeMiddlePrices[${index}][department]`, data.department);
      formData.append(`updateCommitteeMiddlePrices[${index}][position]`, data.position);
      formData.append(`updateCommitteeMiddlePrices[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`updateCommitteeMiddlePrices[${index}][positionOffBoardName]`, data.positionOnBoardName);
      formData.append(`updateCommitteeMiddlePrices[${index}][sequence]`, (index + 1).toString());
    });
  }

  if (body.updateDutyCommitteeMiddlePrices && body.updateDutyCommitteeMiddlePrices.length !== 0) {
    body.updateDutyCommitteeMiddlePrices.forEach((data, index) => {
      if (data.id) {
        formData.append(`updateDutyCommitteeMiddlePrices[${index}][id]`, data.id);
      }
      formData.append(`updateDutyCommitteeMiddlePrices[${index}][dutyDescription]`, data.duty ? data.duty : '');
      formData.append(`updateDutyCommitteeMiddlePrices[${index}][sequence]`, (index + 1).toString());
    });
  }

  if (body.updateApproverAppointPreProcurements && body.updateApproverAppointPreProcurements.length !== 0) {
    // let inx = 0;
    // let sectionSeq = 0;

    body.updateApproverAppointPreProcurements.forEach((data, index) => {
      // if (sectionSeq !== data.sectionSequence) {
      //   inx = 1;
      //   sectionSeq = data.sectionSequence;
      // }

      if (data.id) {
        formData.append(`updateApproverAppointPreProcurements[${index}][id]`, data.id);
      }

      formData.append(`updateApproverAppointPreProcurements[${index}][approverUserId]`, data.approverUserId);
      formData.append(`updateApproverAppointPreProcurements[${index}][approverUserName]`, data.approveFullName);
      formData.append(`updateApproverAppointPreProcurements[${index}][position]`, data.position);
      formData.append(`updateApproverAppointPreProcurements[${index}][remark]`, (data.remark ? data.remark : ''));
      formData.append(`updateApproverAppointPreProcurements[${index}][isDisabled]`, data.isDisabled ? 'true' : 'false');
      formData.append(`updateApproverAppointPreProcurements[${index}][departmentName]`, data.departmentName);
      formData.append(`updateApproverAppointPreProcurements[${index}][status]`, data.status ? data.status : '');
      // formData.append(`updateApproverAppointPreProcurements[${index}][sequence]`, (inx).toString());
      formData.append(`updateApproverAppointPreProcurements[${index}][sequence]`, data.sequence.toString());
      formData.append(`updateApproverAppointPreProcurements[${index}][inRefCode]`, data.inRefCode);
      formData.append(`updateApproverAppointPreProcurements[${index}][sectionSequence]`, data.sectionSequence.toString());
      if (data.approvedDateAt) {
        formData.append(`updateApproverAppointPreProcurements[${index}][approvedDateAt]`, new Date(data.approvedDateAt!).toISOString());
      }
      formData.append(`updateApproverAppointPreProcurements[${index}][approvedUserBy]`, data.approvedUserBy ?? '');
      formData.append(`updateApproverAppointPreProcurements[${index}][approvedRemark]`, data.approvedRemark ?? '');
      formData.append(`updateApproverAppointPreProcurements[${index}][rejectedUserBy]`, data.rejectedUserBy ?? '');
      if (data.rejectedDate) {
        formData.append(`updateApproverAppointPreProcurements[${index}][rejectedDateAt]`, new Date(data.rejectedDate!).toISOString());
      }
      formData.append(`updateApproverAppointPreProcurements[${index}][rejectedRemark]`, data.rejectedRemark ?? '');
      // inx++;

    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put('/appointment', formData, config);
};

const submitPendingAppointmentAsync = (id: string, documentId: string) =>
  http.put(`/appointment/pending/${id}`, { documentId });

const getAppointmentDocumentAsync = (id: string, documentId: string) => http.get(`/appointment/${id}/documents/${documentId}`, {
  responseType: 'blob',
});

const deleteAppointmentAsync = (id: string) => http.delete(`/appointment/${id}`);

const approvedAppointmentApprovalAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };
  return await http.put(`/appointmentapproval/approved/${id}`, body);
};

const rejectedAppointmentApprovalAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };
  return await http.put(`/appointmentapproval/rejected/${id}`, body);
};

const createAppointmentEditingAsync = (body: CreateAppointmenteditingModel, flag: PreProcumentFlag) => {
  let api = '/appointmentEditing';

  if (flag === PreProcumentFlag.Cancel) {
    api = '/appointmentCancel';
  }

  const formData = new FormData();
  formData.append('RefId', body.RefId);
  formData.append('planId', body.planId !== undefined ? body.planId : '');
  formData.append('departmentId', body.departmentId);
  formData.append('budgetYear', body.budgetYear.toString());
  formData.append('planName', body.planName);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodType', body.supplyMethodType);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString()
    .split('T')[0]);
  formData.append('memorandumDate', new Date(body.memorandumDate).toISOString());
  formData.append('createdByUserFullName', getFullName);
  formData.append('memorandumNo', body.memorandumNo ? body.memorandumNo : '');
  formData.append('sourceAndReasons', body.sourceAndReasons);
  formData.append('remark', body.remark === null ? '' : body.remark);
  formData.append('isConsumableMaterials', body.isConsumableMaterials ? 'true' : 'false');

  if (body.files && body.files.length !== 0) {
    body.files.forEach((file) => {
      formData.append('files', file);
    });
  }

  if (body.createCommitteeTors && body.createCommitteeTors.length !== 0) {
    body.createCommitteeTors.forEach((data, index) => {
      formData.append(`createCommitteeTors[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`createCommitteeTors[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`createCommitteeTors[${index}][department]`, data.department);
      formData.append(`createCommitteeTors[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`createCommitteeTors[${index}][positionOnBoardName]`, data.positionOnBoardName);
      formData.append(`createCommitteeTors[${index}][position]`, data.position);
      formData.append(`createCommitteeTors[${index}][sequence]`, (index + 1).toString());

    });
  }

  if (body.createDutyCommitteeTors && body.createDutyCommitteeTors.length !== 0) {
    body.createDutyCommitteeTors.forEach((data, index) => {
      formData.append(`createDutyCommitteeTors[${index}][dutyDescription]`, data.duty ? data.duty : '');
      formData.append(`createDutyCommitteeTors[${index}][sequence]`, (index + 1).toString());
    });
  }

  if (body.createCommitteeMiddlePrices && body.createCommitteeMiddlePrices.length !== 0) {
    body.createCommitteeMiddlePrices.forEach((data, index) => {
      formData.append(`createCommitteeMiddlePrices[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`createCommitteeMiddlePrices[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`createCommitteeMiddlePrices[${index}][department]`, data.department);
      formData.append(`createCommitteeMiddlePrices[${index}][position]`, data.position);
      formData.append(`createCommitteeMiddlePrices[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`createCommitteeMiddlePrices[${index}][positionOffBoardName]`, data.positionOnBoardName);
      formData.append(`createCommitteeMiddlePrices[${index}][sequence]`, (index + 1).toString());
    });
  }

  if (body.createDutyCommitteeMiddlePrices && body.createDutyCommitteeMiddlePrices.length !== 0) {
    body.createDutyCommitteeMiddlePrices.forEach((data, index) => {
      formData.append(`createDutyCommitteeMiddlePrices[${index}][dutyDescription]`, data.duty ? data.duty : '');
      formData.append(`createDutyCommitteeMiddlePrices[${index}][sequence]`, (index + 1).toString());
    });
  }

  // if (body.createApproverAppointPreProcurements && body.createApproverAppointPreProcurements.length !== 0) {
  //   body.createApproverAppointPreProcurements.forEach((data, index) => {
  //     formData.append(`createApproverAppointPreProcurements[${index}][approverUserId]`, data.approverUserId);
  //     formData.append(`createApproverAppointPreProcurements[${index}][approverUserName]`, data.approveFullName);
  //     formData.append(`createApproverAppointPreProcurements[${index}][position]`, data.position);
  //     formData.append(`createApproverAppointPreProcurements[${index}][remark]`, (data.remark === null ? '' : data.remark));
  //     formData.append(`createApproverAppointPreProcurements[${index}][isDisabled]`, data.isDisabled ? 'true' : 'false');
  //     formData.append(`createApproverAppointPreProcurements[${index}][departmentName]`, data.departmentName);
  //     formData.append(`createApproverAppointPreProcurements[${index}][sequence]`, (index + 1).toString());
  //     formData.append(`createApproverAppointPreProcurements[${index}][inRefCode]`, data.inRefCode);
  //     formData.append(`createApproverAppointPreProcurements[${index}][sectionSequence]`, data.sectionSequence.toString());
  //   });
  // }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(api, formData, config);
};

const approvedEditingAppointmentApprovalAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };
  return await http.put(`/appointmentediting/approved/${id}`, body);
};

const createAppointmentCancelingAsync = (body: CreateAppointmenteditingModel) => {
  const formData = new FormData();
  formData.append('RefId', body.RefId);
  formData.append('planId', body.planId !== undefined ? body.planId : '');
  formData.append('departmentId', body.departmentId);
  formData.append('budgetYear', body.budgetYear.toString());
  formData.append('planName', body.planName);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodType', body.supplyMethodType);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString()
    .split('T')[0]);
  formData.append('memorandumDate', new Date(body.memorandumDate).toISOString());
  formData.append('createdByUserFullName', getFullName);
  formData.append('memorandumNo', body.memorandumNo ? body.memorandumNo : '');
  formData.append('sourceAndReasons', body.sourceAndReasons);
  formData.append('isConsumableMaterials', body.isConsumableMaterials ? 'true' : 'false');

  if (body.files && body.files.length !== 0) {
    body.files.forEach((file) => {
      formData.append('files', file);
    });
  }

  if (body.createCommitteeTors && body.createCommitteeTors.length !== 0) {
    body.createCommitteeTors.forEach((data, index) => {
      formData.append(`createCommitteeTors[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`createCommitteeTors[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`createCommitteeTors[${index}][department]`, data.department);
      formData.append(`createCommitteeTors[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`createCommitteeTors[${index}][positionOnBoardName]`, data.positionOnBoardName);
      formData.append(`createCommitteeTors[${index}][position]`, data.position);
      formData.append(`createCommitteeTors[${index}][sequence]`, index.toString());
    });
  }

  if (body.createDutyCommitteeTors && body.createDutyCommitteeTors.length !== 0) {
    body.createDutyCommitteeTors.forEach((data, index) => {
      formData.append(`createDutyCommitteeTors[${index}][dutyDescription]`, data.duty ? data.duty : '');
      formData.append(`createDutyCommitteeTors[${index}][sequence]`, index.toString());
    });
  }

  if (body.createCommitteeMiddlePrices && body.createCommitteeMiddlePrices.length !== 0) {
    body.createCommitteeMiddlePrices.forEach((data, index) => {
      formData.append(`createCommitteeMiddlePrices[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`createCommitteeMiddlePrices[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`createCommitteeMiddlePrices[${index}][department]`, data.department);
      formData.append(`createCommitteeMiddlePrices[${index}][position]`, data.position);
      formData.append(`createCommitteeMiddlePrices[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`createCommitteeMiddlePrices[${index}][positionOffBoardName]`, data.positionOnBoardName);
      formData.append(`createCommitteeMiddlePrices[${index}][sequence]`, index.toString());
    });
  }

  if (body.createDutyCommitteeMiddlePrices && body.createDutyCommitteeMiddlePrices.length !== 0) {
    body.createDutyCommitteeMiddlePrices.forEach((data, index) => {
      formData.append(`createDutyCommitteeMiddlePrices[${index}][dutyDescription]`, data.duty ? data.duty : '');
      formData.append(`createDutyCommitteeMiddlePrices[${index}][sequence]`, index.toString());
    });
  }

  if (body.createApproverAppointPreProcurements && body.createApproverAppointPreProcurements.length !== 0) {
    body.createApproverAppointPreProcurements.forEach((data, index) => {
      formData.append(`createApproverAppointPreProcurements[${index}][approverUserId]`, data.approverUserId);
      formData.append(`createApproverAppointPreProcurements[${index}][approverUserName]`, data.approveFullName);
      formData.append(`createApproverAppointPreProcurements[${index}][position]`, data.position);
      formData.append(`createApproverAppointPreProcurements[${index}][remark]`, (data.remark ? data.remark : ''));
      formData.append(`createApproverAppointPreProcurements[${index}][isDisabled]`, data.isDisabled ? 'true' : 'false');
      formData.append(`createApproverAppointPreProcurements[${index}][departmentName]`, data.departmentName);
      formData.append(`createApproverAppointPreProcurements[${index}][sequence]`, index.toString());
      formData.append(`createApproverAppointPreProcurements[${index}][inRefCode]`, data.inRefCode);
      formData.append(`createApproverAppointPreProcurements[${index}][sectionSequence]`, data.sectionSequence.toString());
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post('/appointmentcancel', formData, config);
};

const approvedCancelingppointmentApprovalAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };
  return await http.put(`/appointmentcancel/approved/${id}`, body);
};

const createAppointmentOfCommiteeDocumentAsync = async (id: string, docReadonly: boolean) => await http.post(`/appointment/${id}/document/${docReadonly}`);

const UploadAppointmentAttachmentAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('Files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`/appointment/attachment/${id}`, formData, config);

};

const deleteAppointmentDocumentAsync = (id: string, docNames: string[]) => http.delete(`/appointment/${id}/documents`, { data: { docNames } });

const reCallAppointmentAsync = (id: string) => {

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`/appointment/recall/${id}`, config);
};

const getPlanProcurementListAsync = async (page: number, pageSize: number, type?: string, departmentCode?: string, data?: CriteriaPlanProcurementListModel) => {
  const params = {
    page,
    pageSize,
    type,
    departmentCode,
    ...data
  };
  return await http.get('/appointment/planProcurement/pagination/', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

export default {
  getListAppointmentAsync,
  getAppointmentByIdAsync,
  createAppointmentAsync,
  updateAppointmentAsync,
  submitPendingAppointmentAsync,
  getAppointmentDocumentAsync,
  deleteAppointmentDocumentAsync,
  deleteAppointmentAsync,
  getCountStatusAsync,
  getCountSupplyMethodAsync,
  approvedAppointmentApprovalAsync,
  rejectedAppointmentApprovalAsync,
  createAppointmentEditingAsync,
  approvedEditingAppointmentApprovalAsync,
  createAppointmentCancelingAsync,
  approvedCancelingppointmentApprovalAsync,
  getListHistoryAsync,
  createAppointmentOfCommiteeDocumentAsync,
  UploadAppointmentAttachmentAsync,
  reCallAppointmentAsync,
  getPlanProcurementListAsync,
};
