import { Modal } from "components";
import { useEffect, useState } from "react";
import { ProgramType } from "utils/constants/PlanEnum";
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { useNavigate } from "react-router";

type program = 'plan' | 'procurementDraft' | 'procurement' | 'manage';
type status = 'success' | 'fail' | 'warning';

export function StatusDashBoard(props: {
  program: ProgramType;
  status: string;
  id?: string;
  refId?: string;
  type?: string;
}) {
  const [statusStyle, setStatusStyle] = useState('');
  const [statusText, setStatusText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [programName, setProgramName] = useState('');
  const [pageName, setPageName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    initStatus(props.status);
  }, [props.status]);

  const goToPage = () => {
    // navigate(pageName);
    window.open(pageName, '_blank');

    setShowModal(false);
  };

  const initStatus = (status: string) => {
    if (props.program === ProgramType.Plan) {

      setProgramName('รายการจัดซื้อจัดจ้าง');

      if (props.type === 'AnnualPlan') {
        setPageName(`/web/procurement-plan-summary/detail/${props.id}`);
      } else {
        setPageName(`/web/procurement-plan-inyear/detail/${props.id}`);
      }

      switch (status) {
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'DraftPlan':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'ApprovePlan':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'WaitingApprovePlan':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'RejectPlan':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Assigned':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'DraftRecordDocument':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingAcceptor':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'ApproveAcceptor':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Announcement':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Draft':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'AssignJorPor':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingAccept':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Accepted':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Reject':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'CancelPlan':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
        case 'success':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'fail':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
        case 'warning':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
    if (props.program === ProgramType.Appoint) {

      setProgramName('ขอแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง');
      setPageName(`/web/preprocurement-directive/detail/${props.id}`);

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'Draft':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Pending':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Approved':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Rejected':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Cancelled':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
        case 'Success':
          setStatusStyle('success-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
    if (props.program === ProgramType.Tor) {

      setProgramName('จัดทำร่างขอบเขตของงาน (TOR)');
      setPageName(`/web/preprocurement-tor/detail/${props.id}`);

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'Draft':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'DraftTor':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForCommitteeApprove':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForDirectorJorPorAssign':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForAssignResponsible':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'DraftSectionApprover':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForApprove':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Approved':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Cancelled':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
        case 'Rejected':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
    if (props.program === ProgramType.MedianPrice) {

      setProgramName('กำหนดราคากลาง (ราคาอ้างอิง)');
      setPageName(`/web/preprocurement-median-price/detail/${props.id}`);

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'Draft':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForCommitteeApprove':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'CommitteeRejected':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'CommitteeApproved':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ/อนุมัติ');
          break;
        case 'WaitingForApprove':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Approved':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Rejected':
          setStatusStyle('fail-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Cancelled':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
        case 'WaitingForAssignResponsible':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'DraftSectionApprover':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForJorPorDirector':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
    if (props.program === ProgramType.JorPor04) {

      setProgramName('การแจ้งข้อมูลเบื้องต้น (จพ.004)');

      if (props.refId && props.id) {
        setPageName(`/web/pp/pp0205/detail/${props.refId}/${props.id}`);
      } else {
        setPageName(`/web/pp/pp0205/detail/${props.id}`);
      }

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'Draft':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingAcceptor':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Accepted':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Reject':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'AssignJorPor':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
    if (props.program === ProgramType.JorPor05) {

      setProgramName('จัดทำรายงานขอซื้อขอจ้าง (จพ.005)');
      setPageName(`/web/pr/pr0101/detail/${props.id}`);

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'Draft':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingAccept':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Accepted':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Reject':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
      }
    }
    if (props.program === ProgramType.Invite) {

      setProgramName('จัดทำหนังสือเชิญชวนผู้ประกอบการ');
      setPageName(`/web/procurement-invitation-order/detail/${props.refId}/id/${props.id}`);

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'Draft':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForApprove':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Approved':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Cancelled':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
        case 'Rejected':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
    if (props.program === ProgramType.JorPor06) {

      setProgramName('จัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง (จพ.006)');
      setPageName(`/web/procurement-create-006/detail/${props.refId}/${props.id}`);

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'Draft':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingComitteeApprove':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForDirectorJorPorAssign':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'DraftAcceptor':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingAccept':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Accepted':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Reject':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
    if (props.program === ProgramType.ContractRecord) {

      setProgramName('อนุมัติใบสั่งซื้อ/จ้าง/เช่า และแจ้งทำสัญญา');

      if (props.refId && props.id) {
        setPageName(`/web/pr/pr0206/detail/${props.refId}/id/${props.id}`);
      } else {
        setPageName(`/web/pr/pr0206/detail/${props.id}`);
      }

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'Draft':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForAssign':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Approve':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Rejected':
          setStatusStyle('fail-status');
          setStatusText('ส่งกลับแก้ไข');
          break;
        case 'WaitingForSign':
          setStatusStyle('warning-status');
          setStatusText('รอลงนาม');
          break;
        case 'Signed':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Pending':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Assigned':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Reject':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
    if (props.program === ProgramType.ContractAgreementInvitation) {

      setProgramName('หนังสือเชิญชวนทำสัญญา');
      setPageName(`/web/contract-invitation-sign/detail/${props.id}/${props.refId}`);

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'Draft':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForApprove':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Rejected':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Invited':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
    if (props.program === ProgramType.ContractAgreement) {

      setProgramName('ร่างสัญญาและสัญญา');
      setPageName(`/web/contract-agreement/detail/${props.id}`);

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'Draft':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForApprove':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForAttachSignedDocument':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Rejected':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Signed':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
    if (props.program === ProgramType.ContractManagement) {

      setProgramName('บันทึกส่งมอบและตรวจรับ');
      setPageName(`/web/contract-management/detail/${props.id}`);

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'ContractAgreement':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'ContractManagement':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'ContractWarranty':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'ContractComplete':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'ContractCancelled':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
    if (props.program === ProgramType.ContractReturnCollateral) {

      setProgramName('คืนหลักประกันสัญญา');
      setPageName(`/web/cm/cm06/detail/${props.id}`);

      switch (status) {
        case 'NotHave':
          setStatusStyle('draft-status');
          setStatusText('ไม่มี');
          break;
        case 'None':
          setStatusStyle('draft-status');
          setStatusText('รอดำเนินการ');
          break;
        case 'WaitingForConsider':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForApprove':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'WaitingForReceiveCollateral':
          setStatusStyle('warning-status');
          setStatusText('อยู่ระหว่างดำเนินการ');
          break;
        case 'Completed':
          setStatusStyle('success-status');
          setStatusText('เสร็จสิ้น');
          break;
        case 'Deleted':
          setStatusStyle('fail-status');
          setStatusText('ยกเลิก');
          break;
      }
    }
  };

  return (
    <>
      {/* <p
        className={`text-center text-white d-flex justify-content-center align-items-center m-0 ${statusStyle}`}
        style={{ height: '35px' }}>
        {statusText}
      </p> */}

      <Button
        onClick={() => setShowModal(props.id !== undefined && props.id !== '' && props.id !== null)}
        variant={statusStyle === 'success-status' ? 'success' : statusStyle === 'warning-status' ? 'warning' : statusStyle === 'fail-status' ? 'danger' : 'secondary'}
        className={`text-white ${statusStyle}`}
        style={{ height: '40px', width: '200px', borderRadius: '0px' }}
      >
        {statusText}
      </Button >

      <Modal
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        className="fix-width-1000"
        children={(
          <>
            ไปยัง{programName}
            <div className="d-flex flex-row gap-2 justify-content-end mt-2">
              <Button variant='outline-primary'
                onClick={() => setShowModal(!showModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={goToPage}
              >
                ยืนยัน
              </Button>
            </div>
          </>
        )}
      />
    </>
  );
}