import {
  Card,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  Table,
  UploadFile,
} from 'components';
import { ContractStatus } from 'components/ContractStatus';
import Title from 'components/Controls/Title';
import { HistoryModal } from 'components/Modal';
import {
  DeliveryItemModel,
  deliveryParcelItemModel,
  InstallmentModel,
} from 'models/CM/ManageContractModels';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaHistory,
  FaSave,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { Context } from '.';
import { dataHistory } from '../data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step4({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const { data, setData } = useContext(Context);
  const [installmentData, setInstallmentData] = useState<InstallmentModel>({} as InstallmentModel);
  const [deliveryList, setDeliveryList] = useState<DeliveryItemModel[]>([]);
  const [showSearchNameModal, setModalSearchNameModal] = useState(false);

  useEffect(() => {
    if (data) {
      setInstallmentData(data);
      if (data.deliveryLists && data.deliveryLists.length > 0) {
        setDeliveryList(data.deliveryLists);
      }
    }
  }, [data]);

  const onSubmitDeliveryModal = (deliveryDate: Date, items: deliveryParcelItemModel[]) => {
    const newData: DeliveryItemModel = {
      id: '',
      sequence: deliveryList.length + 1,
      deliveryDate: deliveryDate,
      deliveryParcels: items,
      remark: '',
    };

    const installData = installmentData;
    const parcelDatas = data.parcels;
    items.forEach(item => {
      const index = parcelDatas.findIndex(x => x.description === item.description);
      parcelDatas[index].deliveryQuantity = item.deliveryQty;
    });

    installData.parcels = parcelDatas;
    setInstallmentData(installData);
    setDeliveryList((deliveryList) => [...deliveryList, newData]);
  };

  const removeItem = (i: number) => {
    const temp: DeliveryItemModel[] = [...deliveryList];
    temp.splice(i, 1);
    setDeliveryList(temp);
  };

  const onNext = () => {
    setData(installmentData);
    onClickNext();
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex justify-content-between mb-3'>
          <Title text='บันทึกเบิกจ่าย'
            className='fs-5 text-primary' />
          <div className='d-flex gap-2'>
            <ContractStatus
              value='Acknowledged' />
            <Button
              className='px-3'
              onClick={() => setShow(true)}
              variant='outline-primary'
            >
              <FaHistory className='me-3' />ประวัติการแก้ไข
            </Button>
          </div>
        </div>
        <Row>
          <Col sm={12}
            xl={3}>
            <p>กำหนดวันส่งมอบงาน : <span className='ms-3'>19-06-2566</span></p>
          </Col>
          <Col sm={12}
            xl={3}>
            <p>กำหนดวันส่งมอบงาน : <span className='ms-3'>165 วัน</span></p>
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            xl={3}>
            <DatePicker label='วันที่ตั้งหนี้ตั้งเบิก'
              value={new Date()} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            xl={6}>
            <InputTextArea label='หมายเหตุตั้งหนี้ตั้งเบิก' />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col sm={12}
            xl={3}>
            <DatePicker label='วันที่เบิกจ่าย (สำหรับ บัญชี)' />
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col sm={12}>
            <div className='d-flex justify-content-between mb-3'>
              <Title text='รายการของพัสดุ'
                className='fs-5 text-primary' />
            </div>
            <div>รายการของพัสดุที่จะซื้อ</div>
            <Table total={1}
              hidePagination
              className='mt-3'>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>ลำดับที่</th>
                  <th style={{ width: 300 }}>รายละเอียด</th>
                  <th style={{ width: 100 }}>จำนวน</th>
                  <th style={{ width: 100 }}>หน่วย</th>
                  <th style={{ width: 100 }}>ประเภท VAT</th>
                  <th style={{ width: 100 }}>ราคาที่ตกลง/<br />ต่อหน่วย</th>
                  <th style={{ width: 100 }}>ราคาที่ตกลง/<br />รวม</th>
                  <th style={{ width: 100 }}>จำนวนที่ต้องส่ง</th>
                  <th style={{ width: 100 }}>จำนวนที่ส่งมอบ</th>
                  <th style={{ width: 150 }}>รายละเอียด</th>
                  <th style={{ width: 100 }}>สถานะ</th>
                </tr>
              </thead>
              <tbody>
                {
                  installmentData.parcels && (
                    installmentData.parcels.map((data, index) => (
                      <tr key={index}>
                        <td className='text-center'>{index + 1}</td>
                        <td><Input placeholder={data.description}
                          value={data.description}
                          disabled /></td>
                        <td><Input value={data.quantity}
                          disabled
                          textEnd /></td>
                        <td><Selector placeholder={data.unit}
                          value={data.unit}
                          disabled /></td>
                        <td><Selector placeholder={data.vatType}
                          value={data.vatType}
                          disabled /></td>
                        <td><Currency value={data.price}
                          disabled /></td>
                        <td><Currency value={data.price * data.quantity}
                          disabled /></td>
                        <td><Input value={data.deliveryQuantityTotal}
                          disabled
                          textEnd /></td>
                        <td><Input value={data.deliveryQuantityTotal}
                          disabled
                          textEnd /></td>
                        <td className='text-start'></td>
                        <td className='text-center'>
                          <Badge bg='success'>ส่งมอบครบถ้วน</Badge>
                        </td>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between gap-3 pt-3'>
        <Button
          onClick={onClickBack}
          className='me-2 px-3'
          variant='outline-primary'
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          <Button
            variant='success'
            className='me-2 px-3'
          >
            <FaSave className='me-2 pb-1 fs-5' />
            บันทึก
          </Button>
          <Button
            className='me-2 px-3'
          >
            บันทึกเบิกจ่าย
          </Button>
        </div>
        <div />
      </div>
      <HistoryModal
        show={show}
        onHide={() => setShow(!show)}
        data={dataHistory}
      />
    </div>
  );
}

export default Step4;
