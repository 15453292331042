import {
  ButtonCustom,
  ContractReceiveStatus,
  DatePicker,
  Input,
  Selector,
  Table,
} from 'components';
import { InputTextArea, Readonly } from 'components/Controls';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import { StatusCMDelivery } from 'components/StatusCMDelivery';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { MdOutlineDateRange } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { IFile } from '../../../../models';
import {
  DeliveryModel,
  IDeliveryItemCreate,
  IDeliveryItemCreateModel,
  IDeliveryParcel,
} from '../../../../models/CM/CM03Models';
import CM03Service from '../../../../services/CM/CM03Service';
import {
  HttpStatusCode,
  submitForm,
  thaiFormatDateWithDash,
  thaiFormatDateWithSlash,
  THCurrency,
} from '../../../../utils';
import toast from '../../../../utils/toast';
import UploadFile from '../../Component/UploadFile';
import { HistoryModal } from '../Modal/HistoryReceiveModal';
import { InputNumber } from 'components/Controls/InputNumber';
import { ContractAppendixOtherIdEnum } from 'utils/constants/ShareValueEnum';
import { FaTrashAlt } from 'react-icons/fa';

export default function Transfer() {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showModelCheck, setShowModelCheck] = useState(false);
  const [delivery, setDelivery] = useState<DeliveryModel>({} as DeliveryModel);
  const [files, setFiles] = useState<IFile[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { contractId, deliveryId } = useParams();
  const [showModelEdit, setShowModelEdit] = useState<boolean[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string>('');

  const onOpenEditModal = (index: number) => {
    setShowModelEdit((showModelEdit) => {
      const newModal = [...showModelEdit];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideEditModal = (index: number) => {
    setShowModelEdit((showModelEdit) => {
      const newModal = [...showModelEdit];
      newModal[index] = false;
      return newModal;
    });
  };

  const openModalDelete = (deliveryItemId: string) => {
    setShowDeleteModal(true);
    setDeleteId(deliveryItemId);
  };

  const deleteAsync = async (deliveryItemId: string) => {
    if (deliveryItemId) {
      const response = await await CM03Service.deleteDeliveryItemAsync(delivery.id, deliveryItemId);
      if (response.status === HttpStatusCode.OK) {
        toast.success('ลบรายการสำเร็จ');
        await getContractManagementDeliveryByIdAsync(contractId, deliveryId);
      } else {
        toast.error('เกิดข้อผิดพลาด');
      }
    } else {
      toast.error('เกิดข้อผิดพลาด');
    }

    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (deliveryId && contractId) {
      getContractManagementDeliveryByIdAsync(contractId, deliveryId);
    }
  }, [deliveryId, contractId]);

  const getContractManagementDeliveryByIdAsync = useCallback(async (contractId?: string, deliveryId?: string) => {
    if (!contractId || !deliveryId) {
      toast.error('ไม่พบรหัสสัญญา');

      return;
    }

    const { data, status } = await CM03Service.getContractManagementDeliveryByIdAsync(contractId, deliveryId);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถโหลดข้อมูลได้');

      return;
    }

    navigate(`/contract-management/detail/${contractId}/delivery/${deliveryId}`);
    const files: IFile[] = [];
    data.attachments?.forEach((item) => {
      files.push({
        id: item.id,
        name: item.fileName,
        file: null,
      });
    });

    setFiles(files);
    setDelivery(data);
  }, []);

  const navigate = useNavigate();

  const goToBack = () => {
    navigate(`/contract-management/detail/${contractId}`);
  };

  const onSaveAsync = async (deliveryDate: Date, remark: string, isUpdateStatus: boolean) => {
    if (!deliveryId) {
      toast.warn('ไม่พบรหัสการส่งมอบ');

      return;
    }

    // if (!deliveryDate) {
    //   toast.warn('กรุณาเลือกวันที่ส่งมอบ');

    //   return;
    // }

    if (delivery.contractManagementDeliveryItems.length === 0) {
      toast.warn('กรุณาระบุรายการส่งมอบ');
      return;
    }

    if (!remark) {
      toast.warn('กรุณากรอกหมายเหตุการส่งมอบ');

      return;
    }

    if (isUpdateStatus && (!files || files.length === 0)) {
      toast.warn('กรุณาแนบเอกสาร');
      return;
    }

    const deliveryMaxLength = delivery.contractManagementDeliveryItems.length > 0 ? (delivery.contractManagementDeliveryItems.length - 1) : undefined;

    const deliveryMaxDate = deliveryMaxLength ? delivery.contractManagementDeliveryItems.sort((a, b) => {
      if (a.deliveryItemDate < b.deliveryItemDate) { return -1; }
      if (a.deliveryItemDate > b.deliveryItemDate) { return 1; }
      return 0;
    })[deliveryMaxLength].deliveryItemDate : undefined;

    const { status } = await CM03Service.onSaveDeliveryAsync(deliveryId, deliveryMaxDate, remark);

    if (status === HttpStatusCode.OK || status === HttpStatusCode.CREATED) {
      if (isUpdateStatus) {
        await onSaveAndUpdateStatusAsync(deliveryId);
      } else {
        toast.success('บันทึกข้อมูลสำเร็จ');
        await getContractManagementDeliveryByIdAsync(contractId, deliveryId);
      }
    } else {
      toast.warn('ไม่สามารถบันทึกข้อมูลได้');
    }
  };

  const onSaveAndUpdateStatusAsync = async (deliveryId?: string) => {
    if (!deliveryId) {
      toast.warn('ไม่พบรหัสการส่งมอบ');

      return;
    }

    const { data, status } = await CM03Service.onSaveDeliveryStatusAsync(deliveryId);

    if (status === HttpStatusCode.OK || status === HttpStatusCode.CREATED) {
      toast.success('ส่งมอบสำเร็จ');

      await getContractManagementDeliveryByIdAsync(contractId, deliveryId);
    }

    setShowConfirmModal(false);
  };

  // TODO:This Function upload document for example
  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (deliveryId) {
      const res = await CM03Service.uploadDeliveryAttachmentAsync(
        deliveryId,
        files,
      );

      if (res.status !== HttpStatusCode.OK) {
        toast.error('อัพโหลดไฟล์ไม่สำเร็จ');

        return;
      }

      toast.success('อัปโหลดไฟล์สำเร็จ');

      await getAttachmentByIdAsync(contractId, deliveryId);
    }
  };

  const getAttachmentByIdAsync = useCallback(async (contractId?: string, deliveryId?: string) => {

    if (contractId && deliveryId) {

      const { data, status } = await CM03Service.getContractManagementDeliveryByIdAsync(contractId, deliveryId);

      if (status !== HttpStatusCode.OK) {
        toast.error('ไม่สามารถโหลดข้อมูลได้');

        return;
      }

      const files: IFile[] = [];
      data.attachments?.forEach((item) => {
        files.push({
          id: item.id,
          name: item.fileName,
          file: null,
        });
      });

      setFiles(files);
    }

  }, []);

  const removeItemAsync = useCallback(async (index: number, docId: string) => {
    if (deliveryId) {
      const res = await CM03Service.removeDeliveryFileAsync(
        deliveryId,
        docId,
      );

      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('ลบไฟล์ไม่สำเร็จ');

        return;
      }

      toast.success('ลบไฟล์สำเร็จ');

      await getAttachmentByIdAsync(contractId, deliveryId);
    }
  }, [deliveryId]);

  const downloadFile = useCallback(async (file: IFile) => {
    if (!file.file) {
      const { data, status } = await CM03Service.downloadDeliveryAttachmentAsync(deliveryId ?? '', file.id);

      if (status !== HttpStatusCode.OK) {
        return;
      }

      const fileUrl = URL.createObjectURL(data);
      window.open(fileUrl, '_blank');

      return;
    }

    const fileUrl = URL.createObjectURL(file.file);
    window.open(fileUrl, '_blank');
  }, [deliveryId]);
  // TODO: This Function upload document for example

  const removeDeliveryItemsAsync = (i: number) => {
    const temp = delivery.contractManagementDeliveryItems;
    temp.splice(i, 1);
    setDelivery(pre => ({ ...pre, contractManagementDeliveryItems: temp }))
  };

  return (
    <>
      <Title text={`รายละเอียดการส่งมอบงาน งวดที่ ${delivery.sequence}`}
        extraElement={(
          <>
            <StatusCMDelivery systemStatue={delivery.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </>
        )} />
      {!delivery.isMA && (
        <Row className='my-3 align-items-center'>
          <h5 className='fw-bold mb-4'>รายละเอียดการส่งมอบพัสดุ</h5>
          <Col sm={12} md={6} lg={4}>
            <div className='text-primary bg-primary px-3 py-2 mb-0 bg-opacity-10 d-flex align-items-center'>
              <MdOutlineDateRange className='me-2 fs-5' /><span> {`ครบกำหนดส่งมอบงาน ${thaiFormatDateWithDash(delivery.deliveryDueDate)}`}</span>
            </div>
          </Col>
          <Col sm={12} md={6} lg={2}>
            {/* <p className='mb-0 text-muted'>กำหนดวันส่งมอบงาน : <span className='text-primary'>19-06-2566</span></p> */}
          </Col>
          <Col sm={12} md={6} lg={6}>
            <p className='mb-0 text-muted'>กำหนดวันส่งมอบงาน
              : <span className='text-primary'>{delivery.periodDay} วัน</span>
            </p>
          </Col>
        </Row>

      )}

      {delivery.isMA && (
        <Row className='my-3 align-items-center'>
          <h5 className='fw-bold mb-4'>บันทึกส่งมอบ</h5>
          <Col sm={12} md={6} lg={4}>
            <div className='text-primary bg-primary px-3 py-2 mb-0 bg-opacity-10 d-flex align-items-center'>
              <MdOutlineDateRange className='me-2 fs-5' />
              <div> {`กำหนดส่งมอบงานทุกๆ ${delivery.maintenanceInformation?.quantity} ${delivery.maintenanceInformation?.periodTypeLable} รวม ${delivery.maintenanceInformation?.totalQuantity} ${delivery.maintenanceInformation?.totalPeriodTypeLable}`}</div>
            </div>
            <div className='text-primary bg-primary px-3 py-2 mb-0 bg-opacity-10 d-flex align-items-center'>
              <div>{`${delivery.maintenanceInformation?.maintenanceDescription}`}</div>
            </div>
          </Col>
        </Row>
      )}
      <div className='mt-4'>
        <Row>
          <Col sm={12}
            md={6}
            lg={6}>
            <Readonly label='รายละเอียดการส่งมอบ'
              textReadonly={delivery.detail} />
          </Col>
          <Col sm={12}
            md={6}
            lg={3}>
            <Readonly label='มูลค่า (%)'
              textReadonly={THCurrency(delivery.percent)} />
          </Col>
          <Col sm={12}
            md={6}
            lg={3}>
            <Readonly label='จำนวนเงิน'
              textReadonly={THCurrency(delivery.amount)} />
          </Col>
        </Row>
      </div>
      {!delivery.isMA && (
        <>
          {/* <Row className='mt-3'>
            <h5 className='fw-bold'>งวดเงิน</h5>
            <Col sm={12}>
              <Table total={2} hidePagination>
                <thead>
                  <tr>
                    <th className='text-start ps-3' style={{ width: '80%' }}>งวด</th>
                    <th className='text-center' style={{ width: 100 }}>มูลค่า (%)</th>
                    <th style={{ width: '20%' }}>จำนวนเงิน</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-start'>
                    <td className='ps-3'>{delivery.phaseNumber}</td>
                    <td>{delivery.percent}</td>
                    <td className='text-end'>{THCurrency(delivery.amount)}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row> */}
          <Row className='mt-4'>
            {/* <h5 className='fw-bold mb-3'>รายการของพัสดุ</h5>
            <InputTextArea
              label='รายการของพัสดุที่จะซื้อ'
              value={delivery.detail} /> */}
            <Col sm={12} className='mt-4'>
              <div className='d-flex justify-content-between align-items-center'>
                <h5 className='fw-bold mb-0'>งานที่ส่งมอบ</h5>
                {delivery.status !== ContractReceiveStatus.Delivered && (
                  <ButtonCustom icon='add' text='เพิ่มรายการส่งมอบ' onClick={() => setShowModelCheck(!showModelCheck)} />
                )}
              </div>
            </Col>
            <Col sm={12}>
              <Table total={2} hidePagination>
                <thead>
                  <tr>
                    <th className='text-center ps-3' style={{ width: 50 }}>ลำดับ</th>
                    <th className='text-start' style={{ width: 100 }}>วันที่ส่งมอบ</th>
                    <th className='text-start' style={{ width: '20%' }}>รายการพัสดุ</th>
                    <th className='text-center' style={{ width: '15%' }}>จำนวนส่งมอบ</th>
                    <th className='text-center' style={{ width: '20%' }}>หมายเหตุ</th>
                    <th style={{ width: 10 }} />
                  </tr>
                </thead>
                <tbody>
                  {delivery.contractManagementDeliveryItems?.sort((a, b) => {
                    if (a.deliveryItemDate < b.deliveryItemDate) { return -1; }
                    if (a.deliveryItemDate > b.deliveryItemDate) { return 1; }
                    return 0;
                  }).map((data, index) => (
                    <>
                      <tr className='text-start' key={index}>
                        <td className='text-center'>{index + 1}</td>
                        <td className='text-start'>{thaiFormatDateWithSlash(data.deliveryItemDate)}</td>
                        <td className='text-start'>
                          {
                            data.otherDiscretionParcelItem ? (
                              data.otherDiscretionParcelItem
                            ) :
                              (
                                data.additionalParcelItem
                              )
                          }
                        </td>
                        <td className='text-center'>{data.deliveryQuantity === undefined ? '' : data.deliveryQuantity}</td>
                        <td className='text-center'>{data.remark}</td>
                        <td className='text-end'>
                          {
                            delivery.status !== ContractReceiveStatus.Delivered && (
                              <>
                                <ButtonCustom
                                  text='แก้ไข'
                                  icon='edit'
                                  iconAlignRight
                                  variant='outline-primary'
                                  onClick={() => onOpenEditModal(index)}
                                />
                                <Button
                                  variant='danger'
                                  onClick={() => openModalDelete(data.id)}
                                >
                                  <FaTrashAlt />
                                </Button>
                              </>
                            )
                          }
                        </td>
                      </tr>
                      <ModalDelivery
                        isUpdate={true}
                        contractId={contractId}
                        deliveryId={deliveryId}
                        deliveryParcel={delivery.contractManagementDeliveryItems.filter(x => x.id === data.id)}
                        onCreateDelivery={() => getContractManagementDeliveryByIdAsync(contractId, deliveryId)}
                        show={showModelEdit[index]}
                        onHide={() => onHideEditModal(index)} />
                    </>
                  ))}
                </tbody>
              </Table>

            </Col>
          </Row>
          <Row className='mt-3'>
            {/* <Col sm={12} md={6} lg={4}>
            <DatePicker label='วันที่ส่งมอบงาน'
            onChange={(deliveryDate) => setDelivery((pre) => ({ ...delivery, deliveryDate }))}
            value={delivery.deliveryDate}
            rule={{ required: true }}
            />
            </Col> */}
            <Col sm={12}>
              <InputTextArea
                label='หมายเหตุการส่งมอบ'
                placeholder='หมายเหตุการส่งมอบ'
                disabled={delivery.status === ContractReceiveStatus.Delivered}
                value={delivery.remark}
                rule={{ required: true }}
                onChange={(value) => setDelivery((pre) => ({ ...pre, remark: value }))} />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Card className='mt-3 py-3'>
          <Title text='เอกสารแนบ' className='fs-5' />
          <Row className='justify-content-center'>
            <Col sm={12} lg={6}>
              <UploadFile
                files={files}
                handleFileChange={handleFileChange}
                removeItem={removeItemAsync}
                downLoadFile={downloadFile}
                contractRecordId={deliveryId!} />
            </Col>
            <p className='text-danger'> ***หมายเหตุ กรุณาแนบเอกสารดังนี้ </p>
            <p>- ใบส่งมอบงาน</p>
            <p>- เอกสารส่งมอบงาน</p>
            <p>- รายงานการประชุมคณะกรรมการตรวจรับ </p>
            <p>- ใบวางบิล</p>
          </Row>
        </Card>
      </Row>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <ButtonCustom text='ย้อนกลับ' onClick={() => goToBack()} icon='arrowback' variant='outline-primary' />
        </div>
        {
          (delivery.status !== ContractReceiveStatus.Delivered) && (
            <div className='d-flex gap-3 align-items-center'>
              <ButtonCustom
                text='บันทึก'
                variant='outline-primary'
                onClick={() => onSaveAsync(delivery.deliveryDate, delivery.remark, false)} />
              <ButtonCustom
                text='ยืนยันส่งมอบ'
                onClick={() => setShowConfirmModal(true)} />
            </div>
          )
        }
        <div />
      </div>
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        activities={delivery.activities}
        title='การส่งมอบ'
        status={ContractReceiveStatus.Delivery}
      />
      <ModalDelivery
        isUpdate={false}
        contractId={contractId}
        deliveryId={deliveryId}
        onCreateDelivery={() => getContractManagementDeliveryByIdAsync(contractId, deliveryId)}
        show={showModelCheck}
        onHide={() => setShowModelCheck(!showModelCheck)} />
      <Modal
        show={showConfirmModal}
        size='lg'
        onHide={() => setShowConfirmModal(!showConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการยืนยันส่งมอบหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowConfirmModal(!showConfirmModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSaveAsync(delivery.deliveryDate, delivery.remark, true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showDeleteModal}
        size='lg'
        onHide={() => setShowDeleteModal(!showDeleteModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบรายการ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการลบงานที่ส่งมอบหรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-danger'
                onClick={() => setShowDeleteModal(!showDeleteModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  deleteAsync(deleteId);
                }}
              >
                ยืนยันลบข้อมูล
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

interface IModalProps {
  show: boolean;
  onHide: () => void;
  contractId?: string;
  deliveryId?: string;
  onCreateDelivery: () => void;
  deliveryParcel?: any[];
  isUpdate: boolean;
}

function ModalDelivery(props: IModalProps) {
  const [deliveryParcelList, setDeliveryParcelList] = useState<IDeliveryParcel[]>([]);
  const [deliveryParcelTable, setDeliveryParcelTable] = useState<IDeliveryItemCreate[]>([]);
  const [deliveryItemDate, setDeliveryItemDate] = useState<Date>();
  const mapOption = useCallback((data: IDeliveryParcel[]) => data.map((item) => ({ label: item.description, value: item.jorPor06ParcelId })), []);
  const navigate = useNavigate();
  const [showOtherDetail, setShowOtherDetail] = useState(false);

  useEffect(() => {
    if (props.deliveryParcel) {
      setDeliveryParcelTable(props.deliveryParcel);
      setDeliveryItemDate(props.deliveryParcel[0].deliveryItemDate);
    }

    if (!props.isUpdate) {
      addDeliveryParcelTableAsync(1);
    }

  }, [props.deliveryParcel]);

  useEffect(() => {
    if (props.contractId) {
      getDeliveryParcelListAsync(props.contractId);
    }

  }, [props.contractId]);

  const getDeliveryParcelListAsync = useCallback(async (contractId?: string) => {
    if (!contractId) {
      toast.error('ไม่พบรหัสสัญญา');

      return;
    }

    const { data, status } = await CM03Service.getDeliveryParcelListAsync(contractId);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถโหลดข้อมูลได้');

      return;
    }

    if (data.length > 0) {

      setDeliveryParcelList(data);
    } else {

      const newData: IDeliveryParcel[] = [];

      newData.push({
        jorPor06ParcelId: ContractAppendixOtherIdEnum.อื่นๆระบุ,
        deliveryParcel: 'อื่นๆระบุ',
        description: 'อื่นๆระบุ',
        quantity: 0,
        remainQuantity: 0,
        deliveryQuantity: 0,
        sequence: newData.length + 1,
      } as IDeliveryParcel);

      setDeliveryParcelList(newData);
    }

  }, []);

  const onChangeDeliveryParcelList = (value: string, index: number) => {
    const item = deliveryParcelList.find((i) => i.jorPor06ParcelId === value);
    const data = [...deliveryParcelTable];

    if (value !== ContractAppendixOtherIdEnum.อื่นๆระบุ && deliveryParcelTable.filter(x => x.jorPor06ParcelId === value).length > 0) {

      toast.warn('ไม่สามารถระบุรายการพัสดุซ้ำได้');

    } else {

      data[index].contractManagementDeliveryId = props.deliveryId ? props.deliveryId : '';
      data[index].jorPor06ParcelId = item?.jorPor06ParcelId ? item.jorPor06ParcelId : '';
      data[index].additionalParcelItem = item?.description || '';
      data[index].quantity = item?.quantity!;
      data[index].remainQuantity = item?.remainQuantity!;
      data[index].amount = item?.quantity!;
      data[index].goodsName = item?.description!;
    }

    if (value === ContractAppendixOtherIdEnum.อื่นๆระบุ) {
      setShowOtherDetail(true);
    } else {
      setShowOtherDetail(false);
    }

    setDeliveryParcelTable(data);
  };

  const onChangeDeliveryQuantity = (value: number, index: number, remainQuantity: number, jorPor06ParcelId: string) => {
    const deliveryParce = deliveryParcelList.find((i) => i.jorPor06ParcelId === jorPor06ParcelId);

    const data = [...deliveryParcelTable];
    data[index].deliveryQuantity = !value ? 0 : value;

    if (jorPor06ParcelId !== ContractAppendixOtherIdEnum.อื่นๆระบุ) {

      if (!props.isUpdate && (data[index].deliveryQuantity && data[index].deliveryQuantity > 0)
        && (deliveryParce && deliveryParce!.remainQuantity && deliveryParce!.remainQuantity > 0)) {

        data[index].remainQuantity = deliveryParce!.remainQuantity - value;

      } else if (props.isUpdate && (data[index].deliveryQuantity && data[index].deliveryQuantity > 0)
        && (deliveryParce && deliveryParce!.quantity && deliveryParce!.quantity > 0)) {

        data[index].remainQuantity = deliveryParce!.quantity - value;

      } else {

        data[index].remainQuantity = deliveryParce!.quantity;
      }

    }

    if (jorPor06ParcelId === ContractAppendixOtherIdEnum.อื่นๆระบุ) {

      if ((value && value > 0) && (data[index].quantity && data[index].quantity > 0)) {

        data[index].remainQuantity = data[index].quantity - value;

      } else {
        data[index].remainQuantity = data[index].quantity;

      }

    }
    setDeliveryParcelTable(data);
  };

  const onChangeRemark = (value: string, index: number) => {
    const data = [...deliveryParcelTable];
    data[index].remark = value;
    setDeliveryParcelTable(data);
  };

  const onChangeOtherDetail = (value: string, index: number) => {
    const data = [...deliveryParcelTable];
    data[index].otherDiscretionParcelItem = value;
    setDeliveryParcelTable(data);
  };

  const onChangeQuantity = (value: number, index: number) => {
    const data = [...deliveryParcelTable];
    data[index].quantity = value;

    if (data[index].deliveryQuantity && data[index].deliveryQuantity > 0 && value > 0) {
      data[index].remainQuantity = value - data[index].deliveryQuantity;
    } else {
      data[index].remainQuantity = value;
    }

    setDeliveryParcelTable(data);
  };

  const onSaveAsync = useCallback(async (contractId?: string, deliveryId?: string) => {
    submitForm();

    if (!contractId || !deliveryId) {
      toast.warn('ไม่พบรหัสสัญญา');
      return;
    }

    if (!deliveryItemDate) {
      toast.warn('กรุณาเลือกวันที่ส่งมอบ');
      return;
    }

    if (deliveryParcelTable.filter((x) => (x.otherDiscretionParcelItem === '' || x.otherDiscretionParcelItem === null || x.otherDiscretionParcelItem === undefined) && x.jorPor06ParcelId === ContractAppendixOtherIdEnum.อื่นๆระบุ).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดอื่นๆ');
      return;
    }

    if (deliveryParcelTable.filter((x) => (x.quantity === null || x.quantity === undefined || x.quantity <= 0) && x.jorPor06ParcelId === ContractAppendixOtherIdEnum.อื่นๆระบุ).length > 0) {
      toast.warn('กรุณาระบุจำนวนสินค้า');
      return;
    }

    if (deliveryParcelTable.filter((x) => x.additionalParcelItem === null || x.additionalParcelItem === undefined || x.additionalParcelItem === '').length > 0) {
      toast.warn('กรุณาระบุรายละเอียด');
      return;
    }

    if (deliveryParcelTable.filter((x) => x.deliveryQuantity === null || x.deliveryQuantity === undefined || x.deliveryQuantity <= 0).length > 0) {
      toast.warn('กรุณาระบุจำนวนที่ส่งมอบ');
      return;
    }

    if (deliveryParcelTable && deliveryParcelTable.length > 0) {
      if (props.isUpdate) {
        const upddateObj: IDeliveryItemCreate = {
          id: deliveryParcelTable[0].id,
          contractManagementDeliveryId: deliveryParcelTable[0].contractManagementDeliveryId,
          jorPor06ParcelId: deliveryParcelTable[0].jorPor06ParcelId,
          goodsName: deliveryParcelTable[0].goodsName,
          sequence: deliveryParcelTable[0].sequence,
          additionalParcelItem: deliveryParcelTable[0].additionalParcelItem,
          quantity: deliveryParcelTable[0].quantity,
          amount: deliveryParcelTable[0].amount,
          remainQuantity: deliveryParcelTable[0].remainQuantity,
          deliveryQuantity: deliveryParcelTable[0].deliveryQuantity,
          remark: deliveryParcelTable[0].remark,
          deliveryItemDate: deliveryItemDate,
          otherDiscretionParcelItem: deliveryParcelTable[0].otherDiscretionParcelItem,
        };

        const { data, status } = await CM03Service.updateDeliveryItemsAsync(deliveryId, deliveryItemDate, upddateObj);

        if (status === HttpStatusCode.OK || status === HttpStatusCode.CREATED) {
          toast.success('บันทึกข้อมูลสำเร็จ');
          window.location.reload();
          props.onHide();
          props.onCreateDelivery();
        } else {
          toast.error('ไม่สามารถบันทึกข้อมูลได้');
        }

      } else {

        const saveObj: IDeliveryItemCreateModel = {
          deliveryItems: deliveryParcelTable,
        };

        const { data, status } = await CM03Service.addDeliveryItemsAsync(deliveryId, deliveryItemDate, saveObj);

        if (status === HttpStatusCode.OK || status === HttpStatusCode.CREATED) {
          toast.success('บันทึกข้อมูลสำเร็จ');
        } else {
          toast.error('ไม่สามารถบันทึกข้อมูลได้');
        }
      }
    }

    navigate(`/contract-management/detail/${contractId}/delivery/${deliveryId}`);
    props.onHide();
    props.onCreateDelivery();
  }, [deliveryItemDate]);

  const addDeliveryParcelTableAsync = useCallback(async (sequence: number) => {
    const newData: IDeliveryItemCreate = {
      id: undefined,
      contractManagementDeliveryId: '',
      jorPor06ParcelId: '',
      goodsName: '',
      sequence: 1,
      additionalParcelItem: '',
      quantity: 0,
      amount: 0,
      remainQuantity: 0,
      deliveryQuantity: 0,
      remark: '',
    };

    setDeliveryParcelTable((deliveryParcelTable) => [...deliveryParcelTable, newData]);
  }, [deliveryParcelTable]);

  const handlerRemoveDeliveryParcelTableAsync = (i: number) => {
    const temp: IDeliveryItemCreate[] = [...deliveryParcelTable];
    temp.splice(i, 1);
    setDeliveryParcelTable(temp);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      size='xl'
      className="fix-width-1800"
    >
      <Modal.Header>
        <h5 className='fs-5 fw-bold'>รายการส่งมอบ</h5>
      </Modal.Header>
      <Row className='mt-3'>
        <Col sm={12} lg={4}>
          <DatePicker label='วันที่ส่งมอบ'
            onChange={setDeliveryItemDate}
            value={deliveryItemDate} />
        </Col>
      </Row>
      <div className='d-flex justify-content-between align-items-center'>
        <h5 className='fw-bold mb-0'>รายการของพัสดุที่จะซื้อ</h5>
        {!props.isUpdate && <ButtonCustom text='เพิ่มรายการ'
          icon='add'
          onClick={() => addDeliveryParcelTableAsync(deliveryParcelTable.length + 1)}
        />}
      </div>
      <Table total={2} hidePagination>
        <thead>
          <tr>
            <th className='text-center ps-3' style={{ width: 50 }}>ลำดับ</th>
            <th className='text-center' style={{ width: '30%' }}>รายละเอียด</th>
            <th className='text-center' style={{ width: 100 }}>จำนวนสินค้า</th>
            <th className='text-center' style={{ width: 50 }}>คงเหลือ</th>
            <th className='text-center' style={{ width: 50 }}>จำนวนที่ส่งมอบ</th>
            <th className='text-center' style={{ width: '20%' }}>หมายเหตุ</th>
            <th style={{ width: 50 }} />
          </tr>
        </thead>
        <tbody>
          {deliveryParcelTable?.sort((a, b) => a.sequence - b.sequence).map((data, index) => (
            <tr key={index} className='text-start'>
              <td className='text-center'>{index + 1}</td>
              <td className='text-start d-flex'>
                <div style={{ width: `${data.jorPor06ParcelId !== ContractAppendixOtherIdEnum.อื่นๆระบุ ? '100%' : '30%'}` }}>
                  <Selector items={mapOption(deliveryParcelList)}
                    value={data.jorPor06ParcelId}
                    rule={{ required: true }}
                    onChange={(val) => onChangeDeliveryParcelList(val, index)}

                  />
                </div>
                {data.jorPor06ParcelId === ContractAppendixOtherIdEnum.อื่นๆระบุ && (
                  <div style={{ width: '70%', marginTop: '1px' }}>
                    <Input
                      rule={{ required: true }}
                      onChange={(val) => onChangeOtherDetail(val, index)}
                      value={data.otherDiscretionParcelItem} />
                  </div>
                )}
              </td>
              <td className='text-start'>
                <InputNumber
                  disabled={!showOtherDetail}
                  value={data.quantity}
                  rule={{ required: true }}
                  onChange={(val) => onChangeQuantity(Number(val), index)} />
              </td>
              <td className='text-center'>
                <InputNumber disabled value={data.remainQuantity} />
              </td>
              <td className='text-center'>
                <InputNumber
                  rule={{ required: true }}
                  onChange={(val) => onChangeDeliveryQuantity(Number(val), index, data.remainQuantity, data.jorPor06ParcelId)}
                  maxValue={data.quantity}
                  disabled={!data.jorPor06ParcelId}
                  value={data.deliveryQuantity} />
              </td>
              <td className='text-center'>
                <InputTextArea
                  onChange={(val) => onChangeRemark(val, index)}
                  value={data.remark} />
              </td>
              <td className='text-center'>
                {deliveryParcelTable.length > 1 && (
                  <Button
                    variant='danger'
                    onClick={() => handlerRemoveDeliveryParcelTableAsync(index)}
                  >
                    <FaTrashAlt />
                  </Button>
                )}
              </td>
            </tr>
          ))}

        </tbody>
      </Table>
      <div className='d-flex justify-content-center gap-1'>
        <ButtonCustom variant='outline-primary'
          onClick={() => props.onHide()}
          text='ยกเลิก' />
        <ButtonCustom
          icon='save'
          onClick={() => onSaveAsync(props.contractId, props.deliveryId)}
          text='บันทึกรายการส่งมอบ' />
      </div>
    </Modal>
  );
}
