import { Modal } from 'components';
import { IFile } from 'models/P/FileUploadModel';
import { useState } from 'react';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import {
  FaCloudUploadAlt,
  FaFileAlt,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaTrashAlt,
} from 'react-icons/fa';
import CM02Service from 'services/CM/CM02Service';
import { HttpStatusCode } from 'utils';

interface Props {
  contractRecordId: string;
  files: IFile[];
  handleFileChange: (files: File[]) => void;
  removeItem: (index: number, docId: string) => void;
  disabled?: boolean;
}

const fileTypes = ['PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PNG', 'JPG', 'JPEG', 'mp3'];

function UploadFile({ files, handleFileChange, removeItem, contractRecordId, disabled = false }: Props) {
  const [dragEnter, setDragEnter] = useState<boolean>(false);

  return (
    <div className='upload-file'
      draggable>
      <FileUploader
        className='browse'
        disabled={false}
        multiple={true}
        handleChange={handleFileChange}
        name='file'
        types={fileTypes}
        onDraggingStateChange={setDragEnter}
        hoverTitle='-'>
        <div
          className={`browse ${dragEnter ? 'drag-enter' : ''}`}
          style={{ paddingLeft: 6, paddingRight: 6, textAlign: 'center' }}>
          <FaCloudUploadAlt />
          <span>Drag and drop or <span className='text-primary'>browse</span>.</span>
          <span>รองรับไฟล์ที่มีนามสกุล .doc, .docx, .xls, .xlsx, .csv, .pdf, .png, .jpg, .jpeg และมีขนาดไฟล์ไม่เกิน 10 MB</span>
        </div>
      </FileUploader>

      <div className='file-list'>
        {
          Array.from(files)
            ?.map((f, index) => (
              <RenderFile key={index}
                contractRecordId={contractRecordId}
                file={f}
                removeItem={() => removeItem(index, f.id)}
                disabled={false} />))
        }
      </div>
    </div>
  );
}

function RenderFile(props: { contractRecordId: string, file: IFile, removeItem: () => void, disabled: boolean }) {
  const [showDelete, setShowDelete] = useState(false);
  const handleOpenFileAsync = async (contractRecordId: string, file: IFile) => {
    if (!file.file) {
      const { data, status } = await CM02Service.getAttachmentAsync(contractRecordId, file.id);
      if (status !== HttpStatusCode.OK) {
        return;
      }

      const fileUrl = URL.createObjectURL(data);
      window.open(fileUrl, '_blank');

      return;
    }

    const fileUrl = URL.createObjectURL(file.file);
    window.open(fileUrl, '_blank');
  };

  const fileIcon = (name: string) => {
    switch (true) {
      case name.includes('.pdf'):
        return (<FaFilePdf />);
      case name.includes('.doc') || name.includes('.docx'):
        return (<FaFileWord />);
      case name.includes('.xls') || name.includes('.xlsx'):
        return (<FaFileExcel />);
      case name.includes('.csv'):
        return (<FaFileCsv />);
      case name.includes('.png') || name.includes('jpg') || name.includes('jpeg'):
        return (<FaFileImage />);
      default:
        return (<FaFileAlt />);
    }
  };

  const removeFile = async () => {
    props.removeItem();
    setShowDelete(false);
  };

  return (
    <div className='file'>
      <div className='file-icon'>
        {fileIcon(props.file.name)}
      </div>
      <div className='detail'>
        <a
          onClick={() => handleOpenFileAsync(props.contractRecordId, props.file)}
          className='filename'
        >
          {props.file.name}
        </a>
      </div>
      <div className='trash'>
        <Button
          variant='danger'
          className='d-flex align-items-center gap-2'
          onClick={() => setShowDelete(true)}
        >
          <FaTrashAlt />
        </Button>
      </div>

      <Modal
        show={showDelete}
        size='lg'
        onHide={() => setShowDelete(!showDelete)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการยืนยันลบไฟล์หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDelete(!showDelete)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => removeFile()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default UploadFile;
