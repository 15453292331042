import { AxiosResponse } from 'axios';
import { CriteriaPlanProcurementList } from 'models';
import { LoaderFunctionArgs } from 'react-router-dom';
import {
  planProcurement,
  shareValue,
} from 'services';
import { SharedvalueId } from 'utils/constants/ContractEnum';
import { PlanTypeText } from 'utils/constants/PlanEnum';

export const planProcurementLoader = async () => {
  const [department, SupplyMethod, tableData, statusCount, jorPorDirectorPosition] = await Promise.all([
    shareValue.GetDDL('Department'),
    shareValue.GetDDL('SupplyMethod'),
    planProcurement.getPlanProcurementListAsync(1, 10, PlanTypeText.AnnualPlan, { isAssignToMeOnly: true } as CriteriaPlanProcurementList),
    planProcurement.getPlanStatusCount(1, 10, PlanTypeText.AnnualPlan, { isAssignToMeOnly: true } as CriteriaPlanProcurementList),
    shareValue.getSingleSharedValueAsync(SharedvalueId.jorPorDirectorPositionId),
  ]);

  const SupplyMethodAll = [{ label: 'ทั้งหมด', value: 'All' }, ...SupplyMethod];

  return {
    departmentDDL: department,
    supplyMethodDDL: SupplyMethodAll,
    tableData: tableData.data,
    statusCount: statusCount.data,
    jorPorDirectorPosition: jorPorDirectorPosition.data,
  };
};

export const planProcurementDetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  let data: AxiosResponse = {} as AxiosResponse;

  if (id) {
    data = await planProcurement.getDetailAsync(id);
  }

  const [
    departments,
    positions,
    supplyMethods,
    supplyMethodTypes,
    assignDepartment,
    jorPorDirectorPosition,
  ] = await Promise.all([
    await planProcurement.getDepartmentsAsync(),
    await planProcurement.getPositionsAsync(),
    await shareValue.getSupplyMethodListAsync(),
    await shareValue.getSupplyMethodTypeListAsync(),
    await shareValue.getAssignDepartmentListAsync(),
    await shareValue.getSingleSharedValueAsync(SharedvalueId.jorPorDirectorPositionId),
  ]);

  return {
    planProcurement: data.data?.planProcurement,
    departments: departments.data
      ? departments.data.map((d: { id: string, name: string }) => ({ value: d.id, label: d.name }))
      : null,
    positions: positions.data,
    supplyMethods: supplyMethods.data,
    supplyMethodTypes: supplyMethodTypes.data,
    assignDepartments: assignDepartment.data,
    jorPorDirectorPosition: jorPorDirectorPosition.data,
  };
};
