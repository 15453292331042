import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { EmploymentAgreementModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';

interface IDefectWarrantyProps {
  title: string;
  contractTemplateCode: string;
  employmentAgreement: EmploymentAgreementModel;
  onEmploymentAgreementChange: (value: EmploymentAgreementModel) => void;
  isDisabled?: boolean;
}

const fields = [
  {
    code: 'CF01',
  },
  {
    code: 'CF02',
  },
  {
    code: 'CF03',
  },
  {
    code: 'CF04',
  },
  {
    code: 'CF05',
  },
  {
    code: 'CF06',
    ProcessingTime: 'ระยะเวลาการคำนวนค่าเช่าคอมพิวเตอร์',
    processingTimePerYear: 'ระยะเวลาการคำนวนค่าเช่าคอมพิวเตอร์',
    processingTimePerMonth: 'เดือน',
    processingTimePerDay: 'วัน',
    processingTime_label: 'นับถัดจากวันที่ผู้เช่ารับมอบคอมพิวเตอร์ถูกต้องครบถ้วนแล้ว',
  },
  {
    code: 'CF07',
  },
  {
    code: 'CF08',
  },
  {
    code: 'CF09',
  },
  {
    code: 'CF10',
  },
  {
    code: 'CF11',
  },
  {
    code: 'CF12',
  },
  {
    code: 'CF13',
  },
  {
    code: 'CF14',
  },
  {
    code: 'CF15',
  },
];

export function RentalPeriodDetail(props: IDefectWarrantyProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [employmentAgreement, setEmploymentAgreement] = useState<EmploymentAgreementModel>(props.employmentAgreement);

  useEffect(() => {
    if (props.employmentAgreement && firstLoad) {
      setEmploymentAgreement(props.employmentAgreement);
      setFirstLoad(false);
    }
  }, [props.employmentAgreement, firstLoad]);

  useEffect(() => {
    if (employmentAgreement) {
      setEmploymentAgreement(employmentAgreement);
    }
  }, [employmentAgreement]);

  const onTextChange = useCallback((key: string, value: string) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <Card className='mt-3'>
      <Title text={`สัญญา${props.title ? props.title : 'ระยะเวลาการเช่า'}`} className='fs-5 text-primary' />
      <>
        {
          fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
            <>
              {
                data.ProcessingTime &&
                (
                  <Row>
                    {
                      data.processingTimePerYear &&
                      (
                        <Col sm={4} className='d-flex'>
                          <InputNumber
                            textCenter
                            label={data.processingTimePerYear}
                            value={employmentAgreement?.processingTimePerYear}
                            onChange={(val) => onNumberChange('processingTimePerYear', Number(val))}
                            disabled={props.isDisabled}
                          />
                          <label className='mt-5 ps-2'>ปี</label>
                        </Col>
                      )
                    }
                    {
                      data.processingTimePerMonth &&
                      (
                        <Col sm={4} className='d-flex'>
                          <InputNumber
                            textCenter
                            className='no-label'
                            value={employmentAgreement?.processingTimePerMonth}
                            onChange={(val) => onNumberChange('processingTimePerMonth', Number(val))}
                            disabled={props.isDisabled}
                          />
                          <label className='mt-5 ps-2'>เดือน</label>
                        </Col>
                      )
                    }
                    {
                      data.processingTimePerDay &&
                      (
                        <Col sm={4} className='d-flex'>
                          <InputNumber
                            textCenter
                            className='no-label'
                            value={employmentAgreement?.processingTimePerDay}
                            onChange={(val) => onNumberChange('processingTimePerDay', Number(val))}
                            disabled={props.isDisabled}
                          />
                          <label className='mt-5 ps-2'>วัน</label>
                        </Col>
                      )
                    }
                  </Row>
                )
              }
            </>
          )
        }
      </>
    </Card>
  );
}
