import {
  DatePicker,
  Selector,
  Table,
} from 'components';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { useLoaderData } from 'react-router';
import { auditLog } from 'services';
import {
  fullDatetime,
  HttpStatusCode,
  setTimeEndDate,
  setTimeStartDate,
} from 'utils';
import { generateUniqueId } from '../../../utils/helper';

type DataTable = {
  message: string;
  user: string;
  programName: string;
  ipAddress: string;
  statusCode: string;
  createdAt: Date;
};

type Loader = {
  data: DataTable[],
  totalCount: number,
  programList: { label: string, value: string }[],
  userList: { label: string, value: string }[],
};

export default function SU05() {
  const loader = useLoaderData() as Loader;
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [username, setUsername] = useState<string>();
  const [program, setProgram] = useState<string>();
  const [data, setDate] = useState<DataTable[]>([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setDate(loader.data);
    setTotalRecords(loader.totalCount);
  }, []);

  const clearDataCriteria = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setUsername('');
    setProgram('');
  };

  const searchActivityLogAsync = async (size: number, page: number) => {
    const programName = loader.programList.find((p) => p.value === program);

    const newStart = startDate ? setTimeStartDate(startDate) : startDate;
    const newEnd = endDate ? setTimeEndDate(endDate) : endDate;

    const res = await auditLog.getAuditLogListAsync(
      size,
      page,
      newStart,
      newEnd,
      username,
      programName?.label || '',
    );

    if (res.status === HttpStatusCode.OK) {
      setDate(res.data.data);
      setTotalRecords(res.data.totalCount);
    }
  };

  const tableOnChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);

    searchActivityLogAsync(size, page);
  };

  return (
    <div className="m05">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">ประวัติการใช้งาน</h4>
      </div>
      <hr />
      <div className="criteria">
        <Row>
          <Col sm={6} md={6} lg={3}>
            <DatePicker
              label="วันที่เริ่มต้น"
              onChange={setStartDate}
              value={startDate}
            />
          </Col>
          <Col sm={6} md={6} lg={3}>
            <DatePicker
              label="วันที่สิ้นสุด"
              onChange={setEndDate}
              value={endDate}
            />
          </Col>
          <Col sm={6} md={6} lg={3}>
            <Selector
              label="ชื่อผู้ใช้"
              value={username}
              onChange={setUsername}
              items={loader.userList}
            />
          </Col>
          <Col sm={6} md={6} lg={3}>
            <Selector
              label="โปรแกรม"
              value={program}
              onChange={setProgram}
              items={loader.programList}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex gap-2">
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                onClick={() => searchActivityLogAsync(size, page)}
              >
                <FaSearch />ค้นหา
              </Button>
              <Button
                variant="outline-primary"
                className="d-flex align-items-center gap-2"
                onClick={clearDataCriteria}
              >
                <FaEraser />ล้าง
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="data-table">
        <Table
          className="mt-4"
          total={totalRecords}
          onChange={(size, page) => tableOnChange(size, page)}
        >
          <thead>
            <tr>
              <th style={{ minWidth: 75 }}>วันที่</th>
              <th style={{ minWidth: 200 }}>IP</th>
              <th style={{ minWidth: 200 }}>ชื่อผู้ใช้</th>
              <th style={{ minWidth: 200 }}>โปรแกรม</th>
              <th style={{ minWidth: 300 }}>ข้อความ</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((d, i) => (
              <tr key={generateUniqueId(i)}>
                <td className="text-center">{fullDatetime(d.createdAt)}</td>
                <td className="text-center">{d.ipAddress}</td>
                <td className="text-center">{d.user}</td>
                <td>{d.programName}</td>
                <td>{d.message}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
