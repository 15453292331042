import Title from 'components/Controls/Title';
import { SearchNameModal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Card,
} from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step4({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <Card.Body>
          <Title text="ผู้ควบคุมงาน(เฉพาะงานก่อสร้าง)" className="fs-5 text-primary" />
          <div className="d-flex justify-content-between my-3">
            <p>ข้อมูล</p>
            <Button
              variant="outline-primary"
              onClick={() => setShow(true)}
            >
              <FaPlus />เพิ่มข้อมูล
            </Button>
          </div>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-between pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
      <SearchNameModal
        show={show}
        onHide={() => setShow(!show)}
        data={[]}
      />
    </div>
  );
}

export default Step4;
