import { ButtonCustom } from 'components';
import { StatusCMDelivery } from 'components/StatusCMDelivery';
import { StatusCMReceived } from 'components/StatusCMReceived';
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { useNavigate } from 'react-router';
import { ContractManagementReceiveModel } from '../../../../../models/CM/CM03Models';
import { thaiFormatDateWithSlash } from '../../../../../utils';

interface Props {
  Index: number;
  data: ContractManagementReceiveModel;
}

export function TableExpandable3(props: Props) {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  function KeyboardArrow(showDetails: Boolean) {
    return (
      <>
        ดูรายเอียด{
          (showDetails) ?
            <MdKeyboardArrowUp /> :
            <MdKeyboardArrowDown />
        }
      </>
    );
  }

  return (
    <>
      <tr className='text-center'
        key={props.Index}>
        <td>{props.data.sequence}</td>
        <td>{thaiFormatDateWithSlash(props.data.receivedDate) ?? '--/--/----'}</td>
        <td>{props.data.receivedNumber}</td>
        <td />
        <td>
          <div className='d-flex'>
            <StatusCMReceived systemStatus={props.data.status}
              systemName='cm-03' />
          </div>
        </td>
        <td />
        <td>
          <ButtonCustom
            variant='light border-0'
            onClick={() => setExpanded(!expanded)}
            iconAlignRight
            text={KeyboardArrow(expanded)}
          />
        </td>
      </tr>
      {
        expanded && (
          <tr className='text-center data-info-full'>
            <td colSpan={8}>
              <Table>
                <thead>
                  <tr className='text-center'>
                    <th style={{ width: '10%' }}
                      className='text-muted'>งวดที่
                    </th>
                    <th style={{ width: '10%' }}
                      className='text-muted'>วันที่ส่งมอบ
                    </th>
                    <th style={{ width: '70%' }}
                      className='text-muted'>รายละเอียดส่งมอบ
                    </th>
                    <th style={{ width: 100 }}
                      className='text-muted'>สถานะ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.data?.contractManagementDeliverys?.sort((a, b) => {
                    return a.sequence - b.sequence;
                  })
                    .map((delivery, index) => (
                      <tr key={index}>
                        <td>{delivery.sequence}</td>
                        <td>{thaiFormatDateWithSlash(delivery.deliveryDate) ?? '--/--/----'}</td>
                        <td className='text-start'>
                          <ul className='inside'>
                            <li>{delivery.remark}</li>
                          </ul>
                        </td>
                        <td>
                          <div className='d-flex'>
                            <StatusCMDelivery systemStatue={delivery.status}
                              systemName='cm-03' />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </td>
          </tr>
        )
      }
    </>
  );
}
