import { DepartmentResponse } from 'models';
import { ItemModel, ProvincesModel } from 'models/systemUtility';
import { department, shareValue, unitOfMeasure } from 'services';
import provincesServices from 'services/provincesServices';
import { ProvincesEnum, SharedvalueGroup, SubGroup } from 'utils/constants/ContractEnum';

const createContract = async () => {
  const departmentItems = await GetDDL('Department');
  const supplyMethodItems = await GetDDL('SupplyMethod');
  const supplyMethodTypeItems = await GetDDL('SupplyMethodType');
  const contractType = await GetDDL(SharedvalueGroup.ContractType);
  const periodCondition = await GetDDL(SharedvalueGroup.PeriodCondition);
  const warrantyCondition = await GetDDL(SharedvalueGroup.WarrantyCondition);
  const performanceBondType = await GetDDL(SharedvalueGroup.PerformanceBondType);
  const performanceBondReturn = await GetDDL(SharedvalueGroup.PerformanceBondReturn);
  const periodType = await GetDDL(SharedvalueGroup.PeriodType);
  const returnCollateralConsiderResultType = await GetDDL(SharedvalueGroup.ReturnCollateralConsiderResultType);
  const bankType = await GetDDL(SharedvalueGroup.Bank);
  const unitOfMeasureItems = await GetDDL('UnitOfMeasure');
  const periodTimeType = await GetDDL(SharedvalueGroup.PeriodTimeType);
  const quarter = await GetDDL(SharedvalueGroup.Quarter);
  const budgetYear = await GetDDL('BudgetYear');
  const maintenancePeriodType = await GetDDL(SharedvalueGroup.MaintenancePeriodType);
  const entrepreneurTypeItems = await GetDDL('EntrepreneurType');
  const paymentType = await GetDDL(SharedvalueGroup.PaymentType);
  const advanceDeductionType = await GetDDL(SharedvalueGroup.AdvanceDeductionType);
  const fineType = await GetDDL(SharedvalueGroup.FineType);
  const vatType = await GetDDL(SharedvalueGroup.VatType);
  const contractStartDatePeriodCondition = await GetDDL(SharedvalueGroup.ContractStartDatePeriodCondition);
  const repairPeriodType = await GetDDL(SharedvalueGroup.RepairPeriodType);
  const contractManagementType = await GetDDL(SharedvalueGroup.ContractManagementType);
  const provinces = await GetDDL(ProvincesEnum.provinces);

  return {
    provincesDDL: provinces,
    departmentDDL: departmentItems,
    supplyMethodDDL: supplyMethodItems,
    supplyMethodTypeDDL: supplyMethodTypeItems,
    contractTypeDDL: contractType,
    periodConditionDDL: periodCondition,
    warrantyConditionDDL: warrantyCondition,
    performanceBondTypeDDL: performanceBondType,
    performanceBondReturnDDL: performanceBondReturn,
    periodTypeDDL: periodType,
    entrepreneurTypeDDL: entrepreneurTypeItems,
    returnCollateralConsiderResultType: returnCollateralConsiderResultType,
    bankDDL: bankType,
    unitOfMeasureDDL: unitOfMeasureItems,
    periodTimeTypeDDL: periodTimeType,
    quarterDDL: quarter,
    budgetYearDDL: budgetYear,
    maintenancePeriodTypeDDL: maintenancePeriodType,
    paymentTypeDDL: paymentType,
    advanceDeductionTypeDDL: advanceDeductionType,
    fineTypeDDL: fineType,
    vatTypeDDL: vatType,
    contractStartDatePeriodConditionDDL: contractStartDatePeriodCondition,
    repairPeriodTypeDDL: repairPeriodType,
    contractManagementTypeDDL: contractManagementType,
  };
};

const GetDDL = async (Type: string) => {
  const itemForMap: ItemModel[] = [];

  switch (Type) {
    case 'Department': {
      const response = await department.getDepartmentsAsync();
      const departmentData: DepartmentResponse[] = response.data;
      departmentData.forEach((data) =>
        itemForMap.push({
          label: data.name,
          value: data.id ?? ''
        })
      );
      break;
    }
    case 'SupplyMethod': {
      const response = await shareValue.getSupplyMethodListAsync();
      const supplyMethodData: ItemModel[] = response.data;
      supplyMethodData.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case 'SupplyMethodType': {
      const response = await shareValue.getSupplyMethodTypeListAsync();
      const supplyMethodTypeData: ItemModel[] = response.data;
      supplyMethodTypeData.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.ContractType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractType);
      const contractTypeData: ItemModel[] = response.data;
      contractTypeData.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.PeriodCondition: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodCondition);
      const periodCondition: ItemModel[] = response.data;
      periodCondition.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.WarrantyCondition: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.WarrantyCondition);
      const warrantyCondition: ItemModel[] = response.data;
      warrantyCondition.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.PerformanceBondType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PerformanceBondType);
      const performanceBondType: ItemModel[] = response.data;
      performanceBondType.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.PerformanceBondReturn: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PerformanceBondReturn);
      const performanceBondReturn: ItemModel[] = response.data;
      performanceBondReturn.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.PeriodType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodType);
      const performanceBondReturn: ItemModel[] = response.data;
      performanceBondReturn.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case 'EntrepreneurType': {
      const response = await shareValue.GetTraderTypeAsync();
      const entrepreneurTypeData: ItemModel[] = response.data;
      entrepreneurTypeData.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.ReturnCollateralConsiderResultType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.ReturnCollateralConsiderResultType);
      const returnCollateralConsiderResultType: ItemModel[] = response.data;
      returnCollateralConsiderResultType.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.Bank: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.Bank);
      const bankType: ItemModel[] = response.data;
      bankType.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case 'UnitOfMeasure': {
      const response = await unitOfMeasure.GetUnitOfMeasureAsync();
      const unitOfMeasureData: ItemModel[] = response.data;
      unitOfMeasureData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case SharedvalueGroup.PeriodTimeType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodTimeType);
      const items: ItemModel[] = response.data;
      items.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.Quarter: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.Quarter);
      const items: ItemModel[] = response.data;
      items.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case 'BudgetYear': {
      const date = new Date();
      let year = date.getUTCFullYear() + 543 - 10;

      const items = Array.from({ length: 20 }).map((i) => {
        year += 1;

        return {
          label: year.toString(),
          value: year.toString()
        };
      });

      items.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );

      break;
    }
    case SharedvalueGroup.MaintenancePeriodType: {
      const response = await await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodType, '', SubGroup.MaintenancePeriodType);
      const items: ItemModel[] = response.data;
      items.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.ContractManagementType: {
      const response = await shareValue.getSharedvalueBySubGroupAsync(SharedvalueGroup.ContractManagementType, SubGroup.SubContractManagementType);
      const items: ItemModel[] = response.data;
      items.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case ProvincesEnum.provinces: {
      const response = await provincesServices.getProvincesListAsync();
      const items: ProvincesModel[] = response.data;
      items.forEach((data) =>
        itemForMap.push({
          label: data.nameTh,
          value: data.code ?? ''
        })
      );
      break;
    }
    default: {
      const response = await shareValue.getListSharedValueAsync(Type);
      const returnCollateralConsiderResultType: ItemModel[] = response.data;
      returnCollateralConsiderResultType.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
  }

  return itemForMap;
};

export { createContract };
