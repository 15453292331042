import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Input,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import { CancellationModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';

interface ICancellationDetailProps {
  cancellation: CancellationModel;
  onAgreementCancellationChange: (value: CancellationModel) => void;
  periodTypeDDL: ItemModel[];
  periodConditionDDL: ItemModel[],
  isDisabled?: boolean;
}

export function CancellationDetail(props: ICancellationDetailProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [cancellation, setCancellation] = useState<CancellationModel>(props.cancellation);

  useEffect(() => {
    if (props.cancellation && firstLoad) {
      setCancellation(props.cancellation);
      setFirstLoad(false);
    }
  }, [props.cancellation, firstLoad]);

  useEffect(() => {
    props.onAgreementCancellationChange(cancellation);
  }, [cancellation]);

  const onTextChange = useCallback((key: string, value: string) => {
    setCancellation(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setCancellation(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <Card className='mt-3'>
      <Title text='การบอกเลิกสัญญา'
        className='fs-5 text-primary' />
      <Row className='mt-3'>
        <Col sm={3}
          md={3}>
          <InputNumber
            label='ผู้ซื้อใช้สิทธิบอกเลิกสัญญา ภายใน'
            value={cancellation?.periodCount}
            onChange={(value) => onNumberChange('periodCount', Number(value))}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={2}
          md={2}>
          <Selector
            className='no-label'
            items={props.periodTypeDDL}
            value={cancellation?.periodType}
            onChange={(value) => onTextChange('periodType', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row className='mt-3'>
        <label className='mb-3'>กรณีที่มีการติดตั้ง คู่ค้าต้องทำสถานที่ให้มีสภาพดังที่มีอยู่เดิม ภายใน</label>
        <Col sm={1}
          md={1}>
          <InputNumber
            value={cancellation?.restorationInDay}
            onChange={(value) => onNumberChange('restorationInDay', Number(value))}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={2}
          md={2}>
          <Selector
            items={props.periodTypeDDL}
            value={cancellation?.restorationInDayUnit}
            onChange={(value) => onTextChange('restorationInDayUnit', value)}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={2}
          md={2}>
          <Selector
            items={props.periodConditionDDL}
            value={cancellation?.restorationInDayType}
            onChange={(value) => onTextChange('restorationInDayType', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
    </Card>
  );
}
