import {
  Check,
  Currency,
  DatePicker,
  Input,
  Modal,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  AnnualPlanListResponseModel,
  SearchPL0201Model,
  SearchPL0201ModelForGet,
  SearchPL0201ModelForGetReport,
} from 'models/PL';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaEraser,
  FaFileExcel,
  FaPlus,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { PL0201Service } from 'services/PL';
import {
  PlanStatusName,
  PlanStatusText,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import {
  ItemModel,
  Pagination,
  UserListSelectionResponse,
} from '../../../models';
import {
  GetMonthYearBC,
  HttpStatusCode,
  isoDate,
  thaiFormatDateWithSlash,
  THCurrency,
} from '../../../utils';
import { generateUniqueId } from '../../../utils/helper';
import ModalLicensor from './ModalLicensor';

type Loader = { supplyMethodTypeList: ItemModel[], supplyMethodTypeNameList: ItemModel[], departmentList: ItemModel[], dataTable: AnnualPlanListResponseModel };

enum CriteriaStatus {
  DraftPlan = 'DraftPlan',
  WaitingApprovePlan = 'WaitingApprovePlan',
  ApprovePlan = 'ApprovePlan',
  RejectPlan = 'RejectPlan',
  Announcement = 'Announcement',
}

export default function PL0201() {
  const navigate = useNavigate();
  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>จัดทำแผน</h4>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => navigate('detail')}
        >
          <FaPlus />สร้างแผน
        </Button>
      </div>
      <hr />
      <PL0201DataTable />
    </>
  );
}

function PL0201DataTable() {
  const navigate = useNavigate();
  const { departmentList, dataTable, supplyMethodTypeNameList } = useLoaderData() as Loader;
  const [pagination, setPagination] = useState<Pagination>({ page: 1, size: 10 } as Pagination);
  const [planData, setPlanData] = useState<AnnualPlanListResponseModel>({} as AnnualPlanListResponseModel);
  const [planIds, setPlanIds] = useState<string[]>([]);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [typeAll, setTypeAll] = useState<boolean>(true);
  const [statusAll, setStatusAll] = useState<boolean>(true);
  const [deleteId, setDeleteId] = useState<string>('');
  const [showModalLicensor, setShowModalLicensor] = useState(false);
  const [licensorData, setLicensorData] = useState<UserListSelectionResponse[]>([]);
  const [criteria, setCriteria] = useState<SearchPL0201Model>({
    supplyMethodType: [...supplyMethodTypeNameList.map((i) => i.value)],
    status: Object.values(CriteriaStatus),
  } as SearchPL0201Model);

  useEffect(() => {
    setPlanData(dataTable);
    setPagination({ ...pagination, totalRecords: dataTable.totalCount });
  }, []);

  const handleSelectPlan = (planId: string) => {
    const index = planIds.findIndex((id) => id === planId);

    if (index > -1) {
      setPlanIds(planIds.filter((id) => id !== planId));
    } else {
      setPlanIds([...planIds, planId]);
    }
  };

  const openModalDelete = (id: string) => {
    setDeleteId(id);
    setShowDelete(true);
  };

  const onShowModalSelectedLicensor = () => {
    if (planIds.length <= 0) {
      toast.warn('กรุณาเลือกแผนอย่างน้อย 1 แผน');
      return;
    }
    setShowModalLicensor(true);
  };

  const handleShowModalLicensor = (data: UserListSelectionResponse[]) => {
    if (data.length <= 0) {
      toast.warn('กรุณาเลือกผู้อนุมัติอย่างน้อย 1 คน');
      return;
    }
    setShow(true);
    setShowModalLicensor(!showModalLicensor);
    setLicensorData(data);
  };

  const onDeletePlan = async (id: string) => {
    if (id) {
      const { data, status } = await PL0201Service.deleteAnnualPlan(id);
      if (status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบข้อมูลสำเร็จ');
      } else {
        toast.error('เกิดข้อผิดพลาด');
      }
    } else {
      toast.warn('ไม่พบแผน, กรุณาลองใหม่.');
    }
    onClearSearch();
    setShowDelete(false);
  };

  const supplyMethodOnChange = (value: string, result: boolean) => {
    if (result) {
      if (!criteria.supplyMethodType) {
        criteria.supplyMethodType = [];
      }

      criteria.supplyMethodType.push(value);

      setCriteria({ ...criteria });

      if (criteria.supplyMethodType.length === 2) {
        setTypeAll(true);
      }
    } else {
      const supplyMethod = criteria.supplyMethodType !== undefined ? criteria.supplyMethodType : [];
      const i = supplyMethod.findIndex((s) => s === value);

      supplyMethod.splice(i, 1);
      setCriteria({ ...criteria, supplyMethodType: [...supplyMethod] });
    }

    if (criteria.supplyMethodType !== undefined && criteria.supplyMethodType.length < 2) {
      setTypeAll(false);
    }
  };

  const supplyMethodAllOnChange = (result: boolean) => {
    if (result) {
      setTypeAll(true);
      setCriteria({ ...criteria, supplyMethodType: supplyMethodTypeNameList.map((i) => i.value) });
    } else {
      setTypeAll(false);
      setCriteria({ ...criteria, supplyMethodType: [] });
    }
  };

  const statusOnChange = (value: string, result: boolean) => {
    if (result) {
      if (!criteria.status) {
        criteria.status = [];
      }

      criteria.status.push(value);

      setCriteria({ ...criteria });

      if (criteria.status.length === Object.values(CriteriaStatus).length) {
        setStatusAll(true);
      }
    } else {
      const { status } = criteria;
      const i = status.findIndex((s) => s === value);

      status.splice(i, 1);
      setCriteria({ ...criteria, status: [...status] });
    }

    if (criteria.status.length < Object.values(CriteriaStatus).length) {
      setStatusAll(false);
    }
  };

  const statusAllOnChange = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setCriteria({ ...criteria, status: Object.values(CriteriaStatus) });
    } else {
      setStatusAll(false);
      setCriteria({ ...criteria, status: [] });
    }
  };

  const onSearchDataTable = async () => {
    const searchModel: SearchPL0201ModelForGet = {
      ...criteria,
      expectingProcurementAt: criteria.expectingProcurementAt !== undefined ? isoDate(criteria.expectingProcurementAt) : undefined,
      planAnnouncementDate: criteria.planAnnouncementDate !== undefined ? new Date(criteria.planAnnouncementDate).toISOString() : undefined,
      supplyMethodType: typeAll === true ? [] : criteria.supplyMethodType,
      status: statusAll === true ? [] : criteria.status,
    };

    await searchAnnualPlan(searchModel);
  };

  const onClearSearch = async () => {
    setCriteria({
      supplyMethodType: [...supplyMethodTypeNameList.map((i) => i.value)],
      status: Object.values(CriteriaStatus),
    } as SearchPL0201Model);
    setTypeAll(true);
    setStatusAll(true);

    const searchModel: SearchPL0201ModelForGet = {
      supplyMethodType: [],
      status: [],
    };

    await searchAnnualPlan(searchModel);
  };

  const searchAnnualPlan = useCallback(async (searchModel: SearchPL0201ModelForGet) => {
    const { data, status } = await PL0201Service.getPL0201sAsync(pagination.page, pagination.size, searchModel);
    if (status !== 200) {
      toast.error('เกิดข้อผิดพลาด');
      return;
    }

    setPlanData(data);
    setPagination({ ...pagination, totalRecords: data.totalCount });
  }, [pagination.page, pagination.size]);

  useEffect(() => {
    const searchModel: SearchPL0201ModelForGet = {
      ...criteria,
      expectingProcurementAt: criteria.expectingProcurementAt !== undefined ? isoDate(criteria.expectingProcurementAt) : undefined,
      planAnnouncementDate: criteria.planAnnouncementDate !== undefined ? new Date(criteria.planAnnouncementDate).toISOString() : undefined,
      supplyMethodType: typeAll === true ? [] : criteria.supplyMethodType,
      status: statusAll === true ? [] : criteria.status,
    };

    searchAnnualPlan(searchModel);
  }, [searchAnnualPlan]);

  const getReportSearchCriteria = () => {
    const searchModel: SearchPL0201ModelForGetReport = {
      ...criteria,
      expectingProcurementAt: criteria.expectingProcurementAt !== undefined ? isoDate(criteria.expectingProcurementAt) : undefined,
      planAnnouncementDate: criteria.planAnnouncementDate !== undefined ? new Date(criteria.planAnnouncementDate).toISOString() : undefined,
      supplyMethodType: criteria.supplyMethodType,
      allStatus: statusAll,
      draftStatus: criteria.status.some((s) => s === PlanStatusText.DraftPlan),
      pendingStatus: criteria.status.some((s) => s === PlanStatusText.WaitingApprovePlan),
      approvedStatus: criteria.status.some((s) => s === PlanStatusText.ApprovePlan),
      rejectedStatus: criteria.status.some((s) => s === PlanStatusText.RejectPlan),
      announcementStatus: criteria.status.some((s) => s === PlanStatusText.Announcement),
    };

    return searchModel;
  };

  const exportReportAsync = async () => {
    const { data } = await PL0201Service.getReportExcelAsync(getReportSearchCriteria());

    downloadReport(data);
  };

  const exportEGPReportAsync = async () => {
    const { data } = await PL0201Service.getEgpReportExcelAsync(getReportSearchCriteria());

    downloadReport(data);
  };

  const downloadReport = (fileData: Blob) => {
    const todayText = new Date().toISOString()
      .substring(0, 10)
      .replaceAll('-', '');
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(fileData);
    downloadLink.download = `${todayText}.xlsx`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const submitApproveAsync = (() => {
    const licensorIds = licensorData.map((licensor) => licensor.id);
    (async () => {
      const { status } = await PL0201Service.updateMultiplePl0201Async(planIds, licensorIds);

      if (status === HttpStatusCode.OK) {
        toast.success('ส่งอนุมัติสำเร็จ');
        setShow(!show);
        setPlanData({} as AnnualPlanListResponseModel);
        onClearSearch();

        return;
      }
      toast.error('ส่งอนุมัติไม่สำเร็จ');
    })();
  });

  return (
    <>
      <div className='criteria'>
        <Row>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='เลขที่แผน'
              value={criteria.planNumber}
              onChange={(val) => setCriteria({ ...criteria, planNumber: val })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Selector
              label='ฝ่าย/สำนัก'
              items={departmentList}
              value={criteria.departmentId}
              onChange={(val) => setCriteria({ ...criteria, departmentId: val })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='ชื่อโครงการ'
              value={criteria.planName}
              onChange={(val) => setCriteria({ ...criteria, planName: val })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Currency
              label='งบประมาณโครงการ'
              value={criteria.planBudget}
              onChange={(val) => setCriteria({ ...criteria, planBudget: val })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <DatePicker
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              value={criteria.expectingProcurementAt}
              onChange={(val) => setCriteria({ ...criteria, expectingProcurementAt: val })}
              monthYearOnly
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <DatePicker
              label='วันที่ประกาศแผน'
              value={criteria.planAnnouncementDate}
              onChange={(val) => setCriteria({ ...criteria, planAnnouncementDate: val })}
            />
          </Col>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='ผู้จัดทำ'
              value={criteria.createBy}
              onChange={(val) => setCriteria({ ...criteria, createBy: val })}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}
            md={4}
            lg={4}
            xl={3}>
            <Form.Label>วิธีจัดหา</Form.Label>
            <div className='d-flex gap-2'>
              <Check
                value={typeAll}
                onChange={(val) => supplyMethodAllOnChange(val)}
                label={<>ทั้งหมด </>}
              />
              {supplyMethodTypeNameList.map((v, i) => (
                <Check
                  key={generateUniqueId(i)}
                  value={criteria.supplyMethodType?.some((s) => s === v.value)}
                  onChange={(val) => supplyMethodOnChange(v.value, val)}
                  label={<>{v.label}</>}
                />
              ))}
            </div>
          </Col>
          <Col>
            <Form.Label>สถานะ</Form.Label>
            <div className='d-flex gap-2'>
              <Check
                value={statusAll}
                onChange={statusAllOnChange}
                label='ทั้งหมด'
              />
              <Check
                value={criteria.status?.some((s) => s === CriteriaStatus.DraftPlan)}
                onChange={(val) => statusOnChange(CriteriaStatus.DraftPlan, val)}
                label={
                  <>{PlanStatusName.DraftPlan} </>
                }
              />
              <Check
                value={criteria.status?.some((s) => s === CriteriaStatus.WaitingApprovePlan)}
                onChange={(val) => statusOnChange(CriteriaStatus.WaitingApprovePlan, val)}
                label={<>{PlanStatusName.WaitingApprovePlan}</>}
              />
              <Check
                value={criteria.status?.some((s) => s === CriteriaStatus.ApprovePlan)}
                onChange={(val) => statusOnChange(CriteriaStatus.ApprovePlan, val)}
                label={<>{PlanStatusName.ApprovePlan}</>}
              />
              <Check
                value={criteria.status?.some((s) => s === CriteriaStatus.RejectPlan)}
                onChange={(val) => statusOnChange(CriteriaStatus.RejectPlan, val)}
                label={<>{PlanStatusName.RejectPlan}</>}
              />
              <Check
                value={criteria.status?.some((s) => s === CriteriaStatus.Announcement)}
                onChange={(val) => statusOnChange(CriteriaStatus.Announcement, val)}
                label={<>{PlanStatusName.Announcement}</>}
              />
            </div>
          </Col>
        </Row>
        <div className='d-flex justify-content-between align-items-center mt-2'>
          <div className='d-flex gap-2'>
            <Button
              variant='primary'
              className='d-flex align-items-center gap-2'
              onClick={onSearchDataTable}
            >
              <FaSearch />ค้นหา
            </Button>
            <Button
              variant='outline-primary'
              className='d-flex align-items-center gap-2'
              onClick={onClearSearch}
            >
              <FaEraser />ล้าง
            </Button>
          </div>

          <div className='d-flex gap-2'>
            <Button
              variant='outline-primary'
              className='d-flex align-items-center gap-2'
              onClick={exportEGPReportAsync}
            >
              <FaFileExcel />พิมพ์รายงานสำหรับ e-GP
            </Button>
            <Button
              variant='outline-primary'
              className='d-flex align-items-center gap-2'
              onClick={exportReportAsync}
            >
              <FaFileExcel />พิมพ์รายงานแผน
            </Button>
            <Button
              variant='primary'
              className='d-flex align-items-center gap-2'
              onClick={onShowModalSelectedLicensor}
            >
              <FaAlignLeft />ส่งอนุมัติ
            </Button>
          </div>
        </div>
      </div>
      <Table
        className='mt-4'
        total={pagination.totalRecords}
        onChange={(size, page) => {
          setPagination({ ...pagination, page, size });
        }}
      >
        <thead>
          <tr>
            <th style={{ minWidth: 50 }} />
            <th style={{ minWidth: 100 }}>เลขที่แผน</th>
            <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 300, width: 500 }}>ชื่อแผน</th>
            <th style={{ minWidth: 100 }}>งบประมาณ (บาท)</th>
            <th style={{ minWidth: 100 }}>วิธีจัดหา</th>
            <th style={{ minWidth: 100 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
            <th style={{ minWidth: 100 }}>วันที่ประกาศแผน</th>
            <th style={{ minWidth: 100 }}>ผู้จัดทำ</th>
            <th style={{ minWidth: 100 }}>วันที่จัดทำ</th>
            <th style={{ minWidth: 100 }}>สถานะโครงการ</th>
            <th style={{ minWidth: 100 }}>สถานะ</th>
            <th style={{ minWidth: 50 }} />
          </tr>
        </thead>
        <tbody>
          {
            planData.plans?.map((item, index) => (
              <tr key={item.id}>
                <td className='text-center'>
                  <Form.Check
                    onClick={() => handleSelectPlan(item.id)}
                    type='checkbox'
                    disabled={item.status !== PlanStatusText.DraftPlan
                      && item.status !== PlanStatusText.RejectPlan
                      && item.status !== PlanStatusText.RejectAcceptor
                      && item.status !== PlanStatusText.RejectAnnouncement}
                  />
                </td>
                <td className='text-center'>
                  <Button
                    variant='link'
                    onClick={() => navigate(`detail/${item.id}`)}
                  >
                    {item.planNumber}
                  </Button>
                </td>
                <td className='text-center'>{item.departmentName}</td>
                <td className='text-wrap'>{item.planName}</td>
                <td className='text-end'>{THCurrency(item.planBudget)}</td>
                <td className='text-center'>{item.supplyMethodValue} : {item.supplyMethodNameValue}</td>
                <td className='text-center'>{GetMonthYearBC(item.expectingProcurementAt)}</td>
                <td className='text-center'>{thaiFormatDateWithSlash(item.planAnnouncementDate)}</td>
                <td>{item.createdByUserFullName}</td>
                <td className='text-center'>{thaiFormatDateWithSlash(item.createdInfoAt.toString())}</td>
                <td className='text-center'><Status type={StatusType.PROJECT}
                  value={item.planStatus} /></td>
                <td className='text-center'><Status type={StatusType.PROCESS}
                  value={item.status} /></td>
                <td className='d-flex text-center gap-2'>
                  <Button
                    className='d-flex align-items-center gap-2'
                    variant='danger'
                    onClick={() => {
                      openModalDelete(item.id);
                    }}
                    disabled={item.status !== PlanStatusText.DraftPlan}
                  ><FaTrashAlt />
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
      <ModalLicensor
        show={showModalLicensor}
        setShow={setShowModalLicensor}
        onClickConfirm={(data) => handleShowModalLicensor(data)}
      />
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งอนุมัติ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>คุณต้องการส่งแผนเพื่อทำการอนุมัติใช่หรือไม่ ?</ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='light'
                onClick={() => {
                  setShow(!show);
                  setShowModalLicensor(!showModalLicensor);
                }}>
                ยกเลิก
              </Button>
              <Button variant='primary'
                onClick={() => {
                  submitApproveAsync();
                  setShow(!show);
                }}>
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showDelete}
        onHide={() => setShowDelete(!showDelete)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบข้อมูล</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>คุณต้องการลบข้อมูลหรือไม่ ?</ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='light'
                onClick={() => setShowDelete(!showDelete)}>
                ยกเลิก
              </Button>
              <Button variant='primary'
                onClick={() => {
                  onDeletePlan(deleteId);
                }}>
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}
