import http from 'utils/axios';

const getProvincesListAsync = async (nameTh?: string, nameEn?: string) => {
    const params = {
        nameTh,
        nameEn,
    };

    return await http.get('/provinces', { params });
};

const getDistrictListAsync = async (provinceCode?: string) => {
    const params = {
        provinceCode,
    };

    return await http.get('/district', { params });
};

const getSubDistrictListAsync = async (districtCode?: string) => {
    const params = {
        districtCode,
    };

    return await http.get('/subDistrict', { params });
};

export default {
    getProvincesListAsync,
    getDistrictListAsync,
    getSubDistrictListAsync,
};