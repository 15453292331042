import {
  Breadcrumb,
  Card,
  Check,
  Input,
  Modal,
  Table,
} from 'components';
import {
  Program,
  Role,
} from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaPlus,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import {
  program,
  role as roleService,
} from 'services';
import {
  HttpStatusCode,
  submitForm,
} from 'utils';
import toast from 'utils/toast';
import { generateUniqueId } from '../../../utils/helper';

const BREADCRUMB_INFO = [
  {
    routerName: '/su/su06', label: 'กำหนดสิทธิ์',
  },
  {
    routerName: '', label: 'กำหนดสิทธิ์ (เพิ่ม / แก้ไข)',
  },
];

type Loader = { role: Role, programs: Program[] };

export default function SU06Detail() {
  const loader = useLoaderData() as Loader;
  const [role, setRole] = useState<Role>({} as Role);
  const [showModal, setShowModal] = useState(false);
  const [programs, setPrograms] = useState<Program[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setRole(loader.role || {} as Role);
    setPrograms(loader.programs);
  }, []);

  const backToIndex = () => navigate('/su/su06');

  const handlerOnSaveAsync = async () => {
    submitForm();

    if (!role.permissionProgramIds || role.permissionProgramIds?.length === 0) {
      toast.warn('ต้องมีอย่างน้อย 1 โปรแกรม');
    }

    if (role.code && role.name && role.permissionProgramIds && role.permissionProgramIds.length > 0) {
      await saveAsync();
    }
  };

  const saveAsync = async () => {
    if (role.id) {
      const res = await roleService.updateRoleAsync(role);

      if (res.status === HttpStatusCode.ACCEPTED) {
        toast.success('บันทึกข้อมูลสำเร็จ');
        backToIndex();
      }
    } else {
      const res = await roleService.saveRoleAsync(role);

      if (res.status === HttpStatusCode.OK) {
        setRole({ ...role, id: res.data });
        toast.success('บันทึกข้อมูลสำเร็จ');
        backToIndex();
      }
    }
  };

  const handlerOnModalSubmit = (programIds: string[]) => setRole({ ...role, permissionProgramIds: programIds });

  const removeProgram = (id: string) => {
    const items = role.permissionProgramIds.filter((p) => p !== id);

    setRole({ ...role, permissionProgramIds: items });
  };

  const createElementProgramSelected = (id: string) => {
    const program = programs.find((p) => p.id === id);

    if (program) {
      return (
        <>
          <td>{program.moduleLabel}</td>
          <td>{program.label}</td>
        </>
      );
    }

    return null;
  };

  return (
    <div className='m01'>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='mt-2 text-primary'>
          <Button variant='link'
            onClick={backToIndex}>
            <MdOutlineArrowBack className='fs-4 text-primary mb-1' />
          </Button>
          กำหนดสิทธิ์
        </h4>
        <Button onClick={handlerOnSaveAsync}
          className='d-flex align-items-center gap-2'
          variant='success'>บันทึก</Button>
      </div>

      <Breadcrumb data={BREADCRUMB_INFO} />
      <hr />
      <Card>
        <p>ข้อมูลสิทธิ์</p>
        <Row>
          <Col md={6}
            lg={4}
            xl={3}>
            <Input
              label='รหัสสิทธิ์'
              rule={{ required: true }}
              onChange={(val) => setRole({ ...role, code: val })}
              value={role.code}
              disabled={!!role.id}
            />
          </Col>
          <Col md={6}
            lg={4}
            xl={3}>
            <Input
              label='ชื่อสิทธิ์'
              rule={{ required: true }}
              onChange={(val) => setRole({ ...role, name: val })}
              value={role.name}
            />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <div className='d-flex justify-content-between mb-3'>
          <p className='m-0'>โปรแกรม</p>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={() => setShowModal(true)}
          >
            <FaPlus />เพิ่มโปรแกรม
          </Button>
        </div>
        <Table className='mt-4'
          hidePagination>
          <thead>
          <tr>
            <th style={{ minWidth: 75 }}>ลำดับ</th>
            <th style={{ minWidth: 200 }}>ชื่อโมดูล</th>
            <th style={{ minWidth: 300 }}>ชื่อโปรแกรม</th>
            <th style={{ minWidth: 50 }} />
          </tr>
          </thead>
          <tbody>
          {role.permissionProgramIds?.map((p, i) => (
            <tr key={generateUniqueId(i)}>
              <td className='text-center'>{i + 1}</td>
              {createElementProgramSelected(p)}
              <td className='d-flex justify-content-center'>
                <Button
                  variant='outline-primary'
                  className='d-flex align-items-center gap-2'
                  onClick={() => removeProgram(p)}
                >
                  <FaTrashAlt />ลบ
                </Button>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Card>
      <ProgramModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onSubmit={handlerOnModalSubmit}
        programsSelected={role.permissionProgramIds}
      />
    </div>
  );
}

function ProgramModal(props: {
  show: boolean,
  onHide: () => void,
  onSubmit: (value: string[]) => void,
  programsSelected: string[],
}) {
  const [show, setShow] = useState<boolean>(false);
  const [programsSelected, setProgramsSelected] = useState<string[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [code, setCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [totalRecords, setTotalRecords] = useState<number>(0);

  useEffect(() => {
    const getDataAsync = async () => {
      if (props.show) {
        if (props.programsSelected?.length > 0) {
          setProgramsSelected(props.programsSelected);
        }

        setPrograms([]);
        setTotalRecords(0);

        await getProgramsAsync();

        setShow(true);
      }
    };

    getDataAsync();
  }, [props.show, props.programsSelected]);

  useEffect(() => {
    getProgramsAsync();
  }, [size, page]);

  const getProgramsAsync = async () => {
    const res = await program.getProgramsAsync(size, page, code, name);

    if (res.status === HttpStatusCode.OK) {
      setPrograms(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }
  };

  const handlerOnSubmit = () => {
    setCode('');
    setName('');
    setSize(10);
    setPage(1);

    setShow(false);

    props.onSubmit(programsSelected);
    props.onHide();

    setProgramsSelected([]);
  };

  const handleOnCheckChange = (id: string, value: boolean) => {
    if (value) {
      setProgramsSelected((val) => [...val, id]);
    } else {
      const i = programsSelected.findIndex((p) => p === id);

      programsSelected.splice(i, 1);
    }
  };

  const checkValue = useCallback((id: string) => programsSelected.some((c) => c === id), [programsSelected]);

  const clear = () => {
    setCode('');
    setName('');
  };

  const onHide = () => {
    setCode('');
    setName('');
    setSize(10);
    setPage(1);

    setShow(false);

    props.onHide();

    setProgramsSelected([]);
  };

  const onSearchAsync = async () => await getProgramsAsync();

  return (
    <Modal show={show}
      size='xl'>
      <h5 className='mb-3'>เพิ่มโปรแกรม</h5>
      <Card className='mb-3'>
        <p>ค้นหา</p>
        <Row>
          <Col lg={6}>
            <Input label='รหัสโปรแกรม'
              onChange={setCode}
              value={code} />
          </Col>
          <Col lg={6}>
            <Input label='ชื่อโปรแกรม'
              onChange={setName}
              value={name} />
          </Col>
        </Row>
        <div className='d-flex gap-2'>
          <Button
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={onSearchAsync}
          >
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={clear}
          >
            <FaEraser />ล้าง
          </Button>
        </div>
      </Card>
      <Table total={totalRecords}
        onChange={(size, page) => (setSize(size), setPage(page))}>
        <thead>
        <tr>
          <th style={{ minWidth: 200 }}>โมดูล</th>
          <th style={{ minWidth: 150 }}>รหัสโปรแกรม</th>
          <th style={{ minWidth: 300 }}>ชื่อโปรแกรม</th>
          <th style={{ minWidth: 125 }}>กำหนดสิทธิ์</th>
        </tr>
        </thead>
        <tbody>
        {programs.map((p) => (
          <tr key={p.id}>
            <td>{p.moduleLabel}</td>
            <td className='text-center'>{p.code}</td>
            <td>{p.label}</td>
            <td className='d-flex justify-content-center'>
              <Check onChange={(value) => handleOnCheckChange(p.id, value)}
                value={checkValue(p.id)} />
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
      <div className='d-flex justify-content-end gap-2 mt-4'>
        <Button variant='outline-primary'
          onClick={onHide}>
          ยกเลิก
        </Button>
        <Button variant='primary'
          onClick={handlerOnSubmit}>
          ตกลง
        </Button>
      </div>
    </Modal>
  );
}
