import {
  Card,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  Status,
  StatusType,
  UploadFile,
} from 'components';
import {
  DocumentModel,
  IFile,
  ItemModel,
} from 'models';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaAngleRight,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { fullDatetimeTH } from 'utils';
import { PlanStatusText } from 'utils/constants/PlanEnum';
import { Context } from './Detail';

interface Props {
  back: () => void;
  next: () => void;
}

type Loader = { departmentList: ItemModel[], supplyMethodTypeList: ItemModel[], SupplyMethodTypeNameList: ItemModel[] }

export default function Step1(props: Props) {

  const { setData, docData, setFiles } = useContext(Context);
  const { departmentList, supplyMethodTypeList, SupplyMethodTypeNameList } = useLoaderData() as Loader;
  const [onDisable, setOnDisable] = useState<boolean>(true);
  const navigate = useNavigate();

  const next = () => {
    props.next();
  };

  useEffect(() => {
    if (docData.planStatus) {
      if (docData.status === PlanStatusText.Assigned || docData.status === PlanStatusText.DraftRecordDocument) {
        setOnDisable(false);
      } else {
        setOnDisable(true);
      }
    }
    if (docData.documentInit.length) {
      handleSetFile(docData.documentInit);
    }
  }, []);

  const handleSetFile = (documents: DocumentModel[]) => {
    documents.forEach((value, index) => {
      const document = documents[index];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    });
  };

  const handleFileChange = (files: File) => {
    const newFile: IFile = {
      id: '',
      name: files.name,
      file: files,
    };

    setFiles((pre) => [...pre, newFile]);
  };

  return (
    <Card>
      <div className="d-flex gap-2">
        <p>ข้อมูลแผน</p>
        <h5><Status badge type={StatusType.PROJECT} value={docData.planStatus} /></h5>
        <h5> <Status badge type={StatusType.PROCESS} value={docData.status} /></h5>
      </div>
      <Row>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Input
            label="เลขที่แผน"
            value={docData.planNumber}
            disabled
          />
        </Col>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Selector
            label="ฝ่าย/สำนัก"
            items={departmentList}
            value={docData.departmentId}
            rule={{ required: true }}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Selector
            label="วิธีการจัดหา"
            items={supplyMethodTypeList}
            value={docData.supplyMethod}
            rule={{ required: true }}
            disabled
          />
        </Col>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Selector
            className="no-label"
            items={SupplyMethodTypeNameList}
            value={docData.supplyMethodName}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8} xl={6}>
          <InputTextArea
            label="ชื่อโครงการ"
            rule={{ required: true }}
            value={docData.planName}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Input
            label="งบประมาณโครงการ (บาท)"
            value={docData.planBudget}
            rule={{ required: true }}
            type="number"
            disabled
          />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <DatePicker
            label="ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง"
            value={docData.expectingProcurementAt}
            monthYearOnly
            rule={{ required: true }}
            disabled
          />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <DatePicker
            label="วันที่ประกาศแผน"
            value={docData.expectingProcurementAt}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Input
            label="เลขที่แผน e-GP"
            value={docData.planEgpNumber?.toString()}
            onChange={(val) => setData({ ...docData, planEgpNumber: val })}
          />
        </Col>
      </Row>

      <p className="mb-0">เอกสารแนบ</p>
      <Card>
        <div className="d-flex justify-content-center">
          <Row>
            <Col sm={12}>
              <UploadFile
                  // value={files}
                onChange={handleFileChange}
                  // onRemove={removeItemAsync}
                disabled
              />
            </Col>
          </Row>
        </div>
      </Card>

      {
          onDisable
            ? (
              <>
                <Row className="criteria">
                  <Col sm={12} xl={3}>
                    <Input
                      label="สร้างโดย"
                      value={docData.createdByFullName}
                      disabled
                    />
                  </Col>
                  <Col sm={12} xl={3}>
                    <Input
                      label="วันที่สร้าง"
                      value={fullDatetimeTH(docData.createdDate)}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="criteria">
                  <Col sm={12} xl={3}>
                    <Input
                      label="ผู้ส่งอนุมัติ"
                      value={typeof docData.updatedByUserFullName === 'string' ? docData.updatedByUserFullName.toString() : ''}
                      disabled
                    />
                  </Col>
                  <Col sm={12} xl={3}>
                    <Input
                      label="วันที่ส่งอนุมัติ"
                      value={fullDatetimeTH(docData.createdDate)}
                      disabled
                    />
                  </Col>
                </Row>
              </>
            )
            : (
              <>
              </>
            )
        }
      <div className="d-flex justify-content-between mt-4">
        <Button
          variant="outline-primary"
          className="d-flex align-items-center gap-2"
          onClick={() => navigate('/pl/pl0207')}
        >
          <FaAngleLeft />ย้อนกลับ
        </Button>
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={next}
        >
          ถัดไป<FaAngleRight />
        </Button>
      </div>
    </Card>
  );
}
