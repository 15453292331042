import { ContractReturnRequestStatus } from 'components';
import StepProgress from 'components/StepProgress';
import { ContractReturnCollateralRequestModel } from 'models/CM/CM06Model';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import CM06Service from 'services/CM/CM06Service';
import { HttpStatusCode } from 'utils';
import Step1 from './step1';
import Step2 from './step2';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type ContractContext = {
  dataContext: ContractReturnCollateralRequestModel
  setDataContext: Dispatch<React.SetStateAction<ContractReturnCollateralRequestModel>>,

};

export const Context = createContext({} as ContractContext);

function CreateContractMangementDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id } = useParams();
  const [dataContext, setDataContext] = useState<ContractReturnCollateralRequestModel>({} as ContractReturnCollateralRequestModel);
  const [defaultStep, setDefaultStep] = useState(1);


  useEffect(() => {
    if (id) {
      getContractReturnCollateralById(id);
    }
  }, [id]);

  const getContractReturnCollateralById = async (id: string) => {

    const { data, status } = await CM06Service.getContractReturnCollateralRequestApprovalByIdAsync(id);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setDataContext(data);
    setStepByStatus(data.status);
  };

  const setStepByStatus = (status: string) => {
    switch (status) {
      case ContractReturnRequestStatus.None:
        setDefaultStep(1);
        break;
      case ContractReturnRequestStatus.Draft:
        setDefaultStep(2);
        break;
      case ContractReturnRequestStatus.WaitForApprove:
        setDefaultStep(2);
        break;
      case ContractReturnRequestStatus.Approved:
        setDefaultStep(2);
        break;
      default:
        break;
    }
  };

  const obj = [{
    title: <p>บันทึกผลการพิจารณาคืนหลักประกันสัญญา</p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToDetail()} collateralId={id!} />,
  },
  {
    title: <p> ผู้มีอำนาจเห็นชอบ/อนุมัติ </p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} collateralId={id!} />,
  }
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/CM06');
  };

  const backToDetail = () => {
    navigate(`/cm/cm06/detail/${id}`);
  };

  return (
    <div className="document">
      <Row>
        <Col xs={12} className="mb-2">
          <Context.Provider value={{ dataContext, setDataContext }}>
            <StepProgress progressBarData={obj} ref={childRef} step={defaultStep} />
          </Context.Provider> <></>
        </Col>
      </Row>
    </div>
  );
}

export default CreateContractMangementDetail;
