import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card } from 'react-bootstrap';

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [{
        name: 'งบประมาณจัดซื้อจัดจ้าง',
        data: [10, 41, 35, 51, 49, 62, 60],
      },
      {
        name: 'มูลค่าโครงการรวม',
        data: [10, 52, 49, 62, 65, 52, 60],
      },
      {
        name: 'ราคากลาง',
        data: [10, 35, 65, 61, 62, 49, 60],
      },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          fontFamily: 'Kanit, sans-serif',
        },
        colors: ['#FF8585', '#FFB090', '#80CBC7'],
        strokeWidth: 1,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        markers: {
          size: [7, 7, 7],
          colors: ['#FF8585', '#FFB090', '#80CBC7'],
        },
        xaxis: {
          categories: ['ต.ค.64', 'พ.ย.64', 'ธ.ค.64', 'ม.ค.65', 'ก.พ.65', 'มี.ค.65', 'เม.ย.65'],
        },
      },
    };
  }

  render() {
    return (
      <div id="chart">
        <Card>
          <Card.Body>
            <p>จำนวนการจัดซื้อจัดจ้าง (รายเดือน)</p>
            <p className="mb-0">จำนวนโครงการ</p>
            <p className="text-muted"><small>(หน่วย:โครงการ)</small></p>
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="line"
              width="100%"
              height={350}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default LineChart;