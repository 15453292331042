import Demo from 'Demo';
import {
  announcingWinnerDetailLoader,
  announcingWinnerLoader,
  cancelPlanProcurementLoader,
  changePlanProcurementLoader,
  inYearPlanProcurementLoader,
  pl0301DetailLoader,
  pl0301Loader,
  PP0105Loader,
  PP0105LoaderDetail,
  pp0204Loader,
  pp0204TorDetailLoader,
  PR0101Loader,
  su04DetailLoader,
  su04Loader,
  su05Loader,
  su06DetailLoader,
  su06Loader,
  su07DetailLoader,
  su07Loader,
  su09DetailLoader,
  su09Loader,
} from 'loaders';
import { createContract } from 'loaders/createContract';
import {
  PL0201DetailLoader,
  PL0201Loader,
  PL0202DetailLoader,
  PL0202Loader,
  PL0204DetailLoader,
  PL0207DetailLoader,
  PL0207Loader,
  PL0305Loader,
  PL0306DetailLoader,
  PL0307Loader,
} from 'loaders/PL';
import { PL0204Loader } from 'loaders/PL/PL0204Loader';
import { PL0305DetailLoader } from 'loaders/PL/PL0305Loader';
import {
  planProcurementDetailLoader,
  planProcurementLoader,
} from 'loaders/planProcurement';
import {
  PL0205DetailLoader,
  PL0205Loader,
  PL0303DetailLoader,
  PL0303Loader,
  PP0201DetailLoader,
  PP0301Loader,
} from 'loaders/PP';
import { TorLoader } from 'loaders/PP/TorLoader';
import {
  JorPor06DetailLoader,
  JorPor06Loader,
  PR0202Loader,
} from 'loaders/PR';
import {
  InviteDetailLoader,
  InviteLoader,
} from 'loaders/PR/InviteLoader';
import { PR0302Loader } from 'loaders/PR/PR0302Loader';
import { PurchaseOrderApprovalLoder } from 'loaders/PR/PurchaseOrderApprovalLoder';
import { procurementAppointmentOrderLoader } from 'loaders/procurementAppointmentOrderLoader';
import {
  publicPlanProcurementDetailLoader,
  publicPlanProcurementLoader,
} from 'loaders/publicPlanProcurement';
import {
  CM01,
  CM01Detail,
  CM02,
  CM0201,
  CM0201Detail,
  CM0202,
  CM0202Detail,
  CM0203,
  CM0203Detail,
  CM0204,
  CM0204Detail,
  CM0205,
  CM0205Detail,
  CM0206,
  CM0206Detail,
  CM0207,
  CM0207Detail,
  CM0208,
  CM0208Detail,
  CM0210,
  CM0210Detail,
  CM02Detail,
  CM03,
  CM0302,
  CM0302Detail,
  CM0303,
  CM0303Detail,
  CM03Detail,
  CM03DetailCreate,
  CM03DetailTransfer,
  CM03InfoSettlement,
  CM04,
  CM04Detail,
  CM05,
  CM05Detail,
  CM06,
  CM0601,
  CM0602,
  CM0603,
  CM06Detail,
  CM07,
  ContractAgreementEditDetail,
  ContractAgreementEditing,
} from 'pages/CM';
import CM0212 from 'pages/CM/CM0212';
import CM0212Detail from 'pages/CM/CM0212/Detail';
import {
  InspectDetail,
  ManageContractManagement,
  ManageContractManagementDetail,
  ParcelsList,
} from 'pages/ContractManagement';
import {
  Dashboard,
  DashboardDetail,
  DashboardSupplyMethodDetail,
} from 'pages/Dashboard';
import Worklist from 'pages/Dashboard/Worklist';
import {
  AddDelegate,
  Delegate,
} from 'pages/Delegate';
import {
  BrowserNotSupport,
  Error,
  NotFound,
} from 'pages/Other';
import FaqPage from 'pages/Other/FAQ';
import {
  P0201,
  P0201Detail,
  PL0203,
  PL0203Detail,
  PL0301,
  PL0301Detail,
  PL0302,
  PL0302Detail,
  PP0204,
  PP0204TORDetail,
  PP0208,
  PP0208Detail,
} from 'pages/P';
import {
  PL0201,
  PL0201Detail,
  PL0202,
  PL0202Detail,
  PL0204,
  PL0204Detail,
  PL0207,
  PL0305,
  PL0305Detail,
  PL0307,
  PL0307Detail,
} from 'pages/PL';
import PL0207Detail from 'pages/PL/PL0207/Detail/Detail';
import {
  PP0105,
  PP0105Detail,
  PP0205,
  PP0205Detail,
  PP0206,
  PP0206Detail,
  PP0303,
  PP0303Detail,
  PP0304,
  PP0304Detail,
} from 'pages/PP';
import {
  PP0201,
  PP0201Detail,
  PP0202,
  PP0202Detail,
  PP0203,
  PP0203Detail,
} from 'pages/PP02';
import {
  PP0301,
  PP0301Detail,
  PP0302,
  PP0302Detail,
} from 'pages/PP03';
import {
  PR0101,
  PR0101Detail,
  PR0203,
  PR0203Detail,
  PR0204,
  PR0204Detail,
  PR0205,
  PR0205Detail,
  PR0302,
  PR0302Detail,
  PR0303,
  PR0303Detail,
  PR0304,
  PR0304Detail,
  PR0305,
  PR0305Detail,
  PR0306,
  PR0306Detail,
} from 'pages/PR';
import {
  Acceptors,
  Assignee,
  PR0201,
  PR0201Detail,
  PR0202,
  PR0202Detail,
  PR0206,
  PR0206Detail,
  PR0207,
  PR0207Detail,
  PR0208,
  PR0208Detail,
  PR0208Oligarch,
} from 'pages/PR02';
import {
  PR0301,
  PR0301Detail,
} from 'pages/PR03';
import {
  CancelDirective,
  CancelDirectiveDetail,
  CreateDirective,
  CreateDirectiveDetail,
  EditDirective,
  EditDirectiveDetail,
  MedianPrice,
  MedianPriceDetail,
  TORCancel,
  TORCancelDetail,
  TORDraft,
  TORDraftDetail,
  TOREdit,
  TOREditDetail,
} from 'pages/PreProcurement';
import {
  AnnouncingWinners,
  AnnouncingWinnersDetail,
  Annual,
  AppointmentOrder,
  AppointmentOrderDetail,
  Cancel,
  Change,
  CreateJOR006,
  CreateJOR006Detail,
  InvitationOrder,
  InvitationOrderDetail,
  Procurement,
  Public,
  PublicDetail,
} from 'pages/Procurement';
import AnnualDetail from 'pages/Procurement/Annual/Detail';
import { InYear } from 'pages/Procurement/InYear';
import {
  SU01,
  SU01Detail,
  SU02,
  SU02Detail,
  SU03,
  SU03Detail,
  SU04,
  SU04Detail,
  SU05,
  SU06,
  SU06Detail,
  SU07,
  SU07Detail,
  SU09,
  SU09Detail,
  SU10,
  SU11,
  SU12,
  SU12Detail,
} from 'pages/SU';
import { UserManual } from 'pages/Manual';
import { createBrowserRouter } from 'react-router-dom';
import LayoutTemplate from './components/Layout/LayoutTemplate';
import { suLoader } from './loaders/SU/suLoader';
import Login from './pages/Other/Login';
import { loadersDatas } from 'loaders/LoadersDatas';
import SU13 from 'pages/SU/SU13';
import SU13Detail from 'pages/SU/SU13/Detail';
import { su13DetailLoader, su13Loader } from 'loaders/su13';
import Detail from 'pages/CollectAttachments/Detail';
import ProjectList from 'pages/Dashboard/ProjectList';
import DocumentTemplate from 'pages/CM/CM01/Detail/documentTemplate';
import ContractRecordCommittee from 'pages/PR02/PR0206/ContractRecordCommittee';
import JorPorCommentCM from 'pages/CM/CM02/jorPorCommentCM';

const cm = [
  {
    path: '/cm/cm01',
    element: <CM01 />,
    loader: createContract,
  },
  {
    path: '/cm/cm01/detail',
    element: <CM01Detail />,
    loader: createContract,
  },
  {
    path: '/cm/cm02',
    element: <CM02 />,
    loader: createContract,
  },
  {
    path: '/cm/cm02/detail/:id',
    element: <CM02Detail />,
    loader: createContract,
  },
  {
    path: '/contract-management',
    element: <CM03 />,
    loader: createContract,
  },
  {
    path: '/contract-management/detail/:id',
    element: <CM03Detail />,
    loader: createContract,

  },
  {
    path: '/contract-management/detail/contractRecord/:contractRecordId/financial/:financialId/entrepreneurs/:entrepreneursId',
    element: <CM03Detail />,
    loader: createContract,

  },
  {
    path: '/contract-management/detail/:contractId/delivery/:deliveryId',
    element: <CM03DetailTransfer />,
    loader: createContract,
  },
  {
    path: '/contract-management/detail/:contractId/inspection/:receiveId',
    element: <CM03DetailCreate />,
    loader: createContract,
  },
  {
    path: '/contract-management/detail/:contractId/payment/:paidId',
    element: <CM03InfoSettlement />,
    loader: createContract,
  },
  {
    path: '/cm/cm04',
    element: <CM04 />,
  },
  {
    path: '/cm/cm04/detail',
    element: <CM04Detail />,
  },
  {
    path: '/cm/cm04/detail/:id',
    element: <CM04Detail />,
  },
  {
    path: '/cm/cm05',
    element: <CM05 />,
    loader: createContract,
  },
  {
    path: '/cm/cm05/detail',
    element: <CM05Detail />,
    loader: createContract,
  },
  {
    path: '/cm/cm05/detail/:id',
    element: <CM05Detail />,
    loader: createContract,
  },
  {
    path: '/cm/cm06',
    element: <CM06 />,
    loader: createContract,
  },
  {
    path: '/cm/cm06/detail/:id',
    element: <CM06Detail />,
    loader: createContract,
  },
  {
    path: '/cm/cm06/detail/considers/:id',
    element: <CM0601 />,
    loader: createContract,
  },
  {
    path: '/cm/cm06/detail/requests/:id',
    element: <CM0602 />,
    loader: createContract,
  },
  {
    path: '/cm/cm06/detail/receives/:id',
    element: <CM0603 />,
    loader: createContract,
  },
  {
    path: '/cm/cm07',
    element: <CM07 />,
    loader: createContract,
  },
  {
    path: '/cm/cm0201',
    element: <CM0201 />,
  },
  {
    path: '/cm/cm0201/detail',
    element: <CM0201Detail />,
  },
  {
    path: '/cm/cm0202',
    element: <CM0202 />,
  },
  {
    path: '/cm/cm0202/Detail',
    element: <CM0202Detail />,
  },
  {
    path: '/cm/cm0203',
    element: <CM0203 />,
  },
  {
    path: '/cm/cm0203/Detail',
    element: <CM0203Detail />,
  },
  {
    path: '/cm/cm0204',
    element: <CM0204 />,
  },
  {
    path: '/cm/cm0204/Detail',
    element: <CM0204Detail />,
  },
  {
    path: '/cm/cm0205',
    element: <CM0205 />,
  },
  {
    path: '/cm/cm0205/Detail',
    element: <CM0205Detail />,
  },
  {
    path: '/cm/cm0206',
    element: <CM0206 />,
  },
  {
    path: '/cm/cm0206/Detail',
    element: <CM0206Detail />,
  },
  {
    path: '/cm/cm0207',
    element: <CM0207 />,
  },
  {
    path: '/cm/cm0207/Detail',
    element: <CM0207Detail />,
  },
  {
    path: '/cm/CM0208',
    element: <CM0208 />,
  },
  {
    path: '/cm/CM0208/Detail',
    element: <CM0208Detail />,
  },
  {
    path: '/manage-contractMangement',
    element: <ManageContractManagement />,
  },
  {
    path: '/manage-contractMangement/Detail/:id',
    element: <ManageContractManagementDetail />,
  },
  {
    path: '/manage-contractMangement/Detail/:id/inspect/:inspectId',
    element: <InspectDetail />,
  },
  {
    path: '/cm/CM0210',
    element: <CM0210 />,
  },
  {
    path: '/cm/CM0210/Detail',
    element: <CM0210Detail />,
  },
  {
    path: '/cm/cm0302',
    element: <CM0302 />,
  },
  {
    path: '/cm/cm0302/Detail',
    element: <CM0302Detail />,
  },
  {
    path: '/cm/cm0303',
    element: <CM0303 />,
  },
  {
    path: '/cm/cm0303/Detail',
    element: <CM0303Detail />,
  },
  {
    path: '/cm/cm0212',
    element: <CM0212 />,
  },
  {
    path: '/cm/cm0212/detail',
    element: <CM0212Detail />,
  }
];

const su = [
  {
    path: '/su/su01',
    element: <SU01 />,
  },
  {
    path: '/su/su01/Detail',
    element: <SU01Detail />,
  },
  {
    path: '/su/su01/Detail/:id',
    element: <SU01Detail />,
  },
  {
    path: '/su/su02',
    element: <SU02 />,
  },
  {
    path: '/su/su02/Detail',
    element: <SU02Detail />,
  },
  {
    path: '/su/su02/Detail/:id',
    element: <SU02Detail />,
  },
  {
    path: '/su/su03',
    element: <SU03 />,
  },
  {
    path: '/su/su03/Detail',
    element: <SU03Detail />,
  },
  {
    path: '/su/su03/Detail/:id',
    element: <SU03Detail />,
  },
  {
    path: '/su/su04',
    element: <SU04 />,
    loader: su04Loader,
  },
  {
    path: '/su/su04/detail/:id',
    element: <SU04Detail />,
    loader: su04DetailLoader,
  },
  {
    path: '/su/su04/detail',
    element: <SU04Detail />,
    loader: su04DetailLoader,
  },
  {
    path: '/su/su05',
    element: <SU05 />,
    loader: su05Loader,
  },
  {
    path: '/su/su06',
    element: <SU06 />,
    loader: su06Loader,
  },
  {
    path: '/su/su06/detail',
    element: <SU06Detail />,
    loader: su06DetailLoader,
  },
  {
    path: '/su/su06/detail/:id',
    element: <SU06Detail />,
    loader: su06DetailLoader,
  },
  {
    path: '/su/su07',
    element: <SU07 />,
    loader: su07Loader,
  },
  {
    path: '/su/su07/Detail',
    element: <SU07Detail />,
    loader: su07DetailLoader,
  },
  {
    path: '/su/su07/Detail/:id',
    element: <SU07Detail />,
    loader: su07DetailLoader,
  },
  {
    path: '/su/su09',
    element: <SU09 />,
    loader: su09Loader,
  },
  {
    path: '/su/su09/detail',
    element: <SU09Detail />,
    loader: su09DetailLoader,
  },
  {
    path: '/su/su09/detail/:id',
    element: <SU09Detail />,
    loader: su09DetailLoader,
  },
  {
    path: '/su/su10',
    element: <SU10 />,
    loader: suLoader,
  },
  {
    path: '/su/su11',
    element: <SU11 />,
    loader: suLoader,
  },
  {
    path: '/su/su12',
    element: <SU12 />,
  },
  {
    path: '/su/su12/detail',
    element: <SU12Detail />,
  },
  {
    path: '/su/su12/detail/:id',
    element: <SU12Detail />,
  },
  {
    path: '/su/su13',
    element: <SU13 />,
    loader: su13Loader,
  },
  {
    path: '/su/su13/detail',
    element: <SU13Detail />,
    loader: su13DetailLoader,
  },
  {
    path: '/su/su13/detail/:id',
    element: <SU13Detail />,
    loader: su13DetailLoader,
  },
  {
    path: '/delegate',
    element: <Delegate />,
  },
  {
    path: '/delegate/add',
    element: <AddDelegate />,
    loader: su13DetailLoader,
  },
  {
    path: '/delegate/add/:id/:startDate/:endDate',
    element: <AddDelegate />,
    loader: su13DetailLoader,
  }
];

const p = [
  {
    path: '/p/p0201',
    element: <P0201 />,
  },
  {
    path: '/p/p0201/detail',
    element: <P0201Detail />,
  }
];

const pp = [
  {
    path: '/pp/pp0201',
    element: <PP0201 />,
    loader: PP0201DetailLoader,
  },
  {
    path: '/pp/pp0201/detail',
    element: <PP0201Detail />,
    loader: PP0201DetailLoader,
  },
  {
    path: '/pp/pp0201/detail/:id',
    element: <PP0201Detail />,
    loader: PP0201DetailLoader,
  },
  {
    path: '/pp/pp0202',
    element: <PP0202 />,
  },
  {
    path: '/pp/pp0202/detail',
    element: <PP0202Detail />,
  },
  {
    path: '/pp/pp0203',
    element: <PP0203 />,
  },
  {
    path: '/pp/pp0203/detail',
    element: <PP0203Detail />,
  },
  {
    path: '/pp/pp0301',
    element: <PP0301 />,
    loader: PP0301Loader,
  },
  {
    path: '/pp/pp0301/detail/:id',
    element: <PP0301Detail />,
    loader: PP0301Loader,
  },
  {
    path: '/pp/pp0303',
    element: <PP0303 />,
    loader: PL0303Loader,
  },
  {
    path: '/pp/pp0303/detail/:id',
    element: <PP0303Detail />,
    loader: PL0303DetailLoader,
  },
  {
    path: '/pp/pp0304',
    element: <PP0304 />,
  },
  {
    path: '/pp/pp0304/detail',
    element: <PP0304Detail />,
  },
  {
    path: '/pp/pp0302',
    element: <PP0302 />,
  },
  {
    path: '/pp/pp0302/detail',
    element: <PP0302Detail />,
  },
  {
    path: '/pp/pp0105',
    element: <PP0105 />,
    loader: PP0105Loader,
  },
  {
    path: '/pp/pp0105/detail',
    element: <PP0105Detail />,
    loader: PP0105LoaderDetail,
  },
  {
    path: '/pp/pp0105/detail/:id',
    element: <PP0105Detail />,
    loader: PP0105LoaderDetail,
  },
  {
    path: '/pp/pp0205',
    element: <PP0205 />,
    loader: PL0205Loader,
  },
  {
    path: '/pp/pp0205/detail',
    element: <PP0205Detail />,
    loader: PL0205DetailLoader,
  },
  {
    path: '/pp/pp0205/detail/basicInfo/:basicInfoId',
    element: <PP0205Detail />,
    loader: PL0205DetailLoader,
  },
  {
    path: '/pp/pp0205/detail/:id',
    element: <PP0205Detail />,
    loader: PL0205DetailLoader,
  },
  {
    path: '/pp/pp0205/detail/:id/:basicInfoId',
    element: <PP0205Detail />,
    loader: PL0205DetailLoader,
  },
  {
    path: '/pp/pp0206',
    element: <PP0206 />,
  },
  {
    path: '/pp/pp0206/detail',
    element: <PP0206Detail />,
  }
];

const dashboard = [
  {
    path: '/da/da01',
    element: <Dashboard />,
  },
  {
    path: '/da/da01/detail',
    element: <DashboardDetail />,
  },
  {
    path: '/da/da01/detail/supply-method',
    element: <DashboardSupplyMethodDetail />,
  },
  {
    path: '/project-list',
    element: <ProjectList />,
    loader: loadersDatas,
  }
];

const pr = [
  {
    path: '/pr/pr0201',
    element: <PR0201 />,
  },
  {
    path: '/pr/pr0201/detail',
    element: <PR0201Detail />,
  },
  {
    path: '/pr/pr0202',
    element: <PR0202 />,
    loader: PR0202Loader,
  },
  {
    path: '/pr/pr0202/detail',
    element: <PR0202Detail />,
    loader: PR0202Loader,
  },
  {
    path: '/pr/pr0202/detail/:id',
    element: <PR0202Detail />,
    loader: PR0202Loader,
  },
  {
    path: '/pr/pr0101',
    element: <PR0101 />,
    loader: PR0101Loader,
  },
  {
    path: '/pr/pr0101/detail/:basicInformationId',
    element: <PR0101Detail />,
    loader: PR0101Loader,
  },
  {
    path: '/pr/pr0203',
    element: <PR0203 />,
  },
  {
    path: '/pr/pr0203/detail',
    element: <PR0203Detail />,
  },
  {
    path: '/pr/pr0204',
    element: <PR0204 />,
  },
  {
    path: '/pr/pr0204/detail',
    element: <PR0204Detail />,
  },
  {
    path: '/pr/pr0205',
    element: <PR0205 />,
  },
  {
    path: '/pr/pr0205/detail',
    element: <PR0205Detail />,
  },
  {
    path: '/pr/pr0206',
    element: <PR0206 />,
    loader: PurchaseOrderApprovalLoder,
  },
  {
    path: '/pr/pr0206/detail/:jorPor06Id',
    element: <PR0206Detail />,
    loader: PurchaseOrderApprovalLoder,
  },
  {
    path: '/pr/pr0206/detail/:jorPor06Id/id/:id',
    element: <PR0206Detail />,
    loader: PurchaseOrderApprovalLoder,
  },
  {
    path: '/pr/pr0206/detail/basicInformation/:basicInformationId',
    element: <PR0206Detail />,
    loader: PurchaseOrderApprovalLoder,
  },
  {
    path: '/pr/pr0206/detail/basicInformation/:basicInformationId/id/:id',
    element: <PR0206Detail />,
    loader: PurchaseOrderApprovalLoder,
  },
  {
    path: '/pr/pr0206/detail',
    element: <PR0206Detail />,
    loader: PurchaseOrderApprovalLoder,
  },
  {
    path: '/pr/pr0206/detail/id/:id',
    element: <PR0206Detail />,
    loader: PurchaseOrderApprovalLoder,
  },
  {
    path: '/pr/pr0206/purchase-order-approval-committee/:id',
    element: <ContractRecordCommittee />,
    loader: PurchaseOrderApprovalLoder,
  },
  {
    path: '/pr/pr0206/purchase-order-approval-assignee/:id',
    element: <Assignee />,
    loader: PurchaseOrderApprovalLoder,
  },
  {
    path: '/pr/pr0206/acceptors-approval/:id',
    element: <Acceptors />,
    loader: PurchaseOrderApprovalLoder,
  },
  {
    path: '/pr/pr0207',
    element: <PR0207 />,
  },
  {
    path: '/pr/pr0207/detail',
    element: <PR0207Detail />,
  },
  {
    path: '/pr/pr0208',
    element: <PR0208 />,
  },
  {
    path: '/pr/pr0208/detail',
    element: <PR0208Detail />,
  },
  {
    path: '/pr/pr0208/oligarch',
    element: <PR0208Oligarch />,
  },
  {
    path: '/pr/pr0301',
    element: <PR0301 />,
  },
  {
    path: '/pr/pr0301/detail',
    element: <PR0301Detail />,
  },
  {
    path: '/pr/pr0302',
    element: <PR0302 />,
    loader: PR0302Loader,
  },
  {
    path: '/pr/pr0302/detail/:id',
    element: <PR0302Detail />,
  },
  {
    path: '/pr/pr0303',
    element: <PR0303 />,
  },
  {
    path: '/pr/pr0303/detail',
    element: <PR0303Detail />,
  },
  {
    path: '/pr/pr0304',
    element: <PR0304 />,
  },
  {
    path: '/pr/pr0304/detail',
    element: <PR0304Detail />,
  },
  {
    path: '/pr/pr0305',
    element: <PR0305 />,
  },
  {
    path: '/pr/pr0305/detail',
    element: <PR0305Detail />,
  },
  {
    path: '/pr/pr0306',
    element: <PR0306 />,
  },
  {
    path: '/pr/pr0306/detail',
    element: <PR0306Detail />,
  },
  {
    path: '/pp/pp0204',
    element: <PP0204 />,
    loader: pp0204Loader,
  },
  {
    path: '/pp/pp0204/detail',
    element: <PP0204TORDetail />,
    loader: pp0204TorDetailLoader,
  },
  {
    path: '/pp/pp0204/detail/:id',
    element: <PP0204TORDetail />,
    loader: pp0204TorDetailLoader,
  },
  {
    path: '/pp/pp0208',
    element: <PP0208 />,
    loader: pp0204Loader,
  },
  {
    path: '/pp/pp0208/detail',
    element: <PP0208Detail />,
    loader: pp0204TorDetailLoader,
  },
  {
    path: '/pp/pp0208/detail/:planId/:id',
    element: <PP0208Detail />,
    loader: pp0204TorDetailLoader,
  }
];

const pl = [
  {
    path: '/pl/pl0201',
    element: <PL0201 />,
    loader: PL0201Loader,
  },
  {
    path: '/pl/pl0201/detail',
    element: <PL0201Detail />,
    loader: PL0201DetailLoader,
  },
  {
    path: '/pl/pl0201/detail/:id',
    element: <PL0201Detail />,
    loader: PL0201DetailLoader,
  },
  {
    path: '/pl/pl0202',
    element: <PL0202 />,
    loader: PL0202Loader,
  },
  {
    path: '/pl/pl0202/detail',
    element: <PL0202Detail />,
    loader: PL0202DetailLoader,
  },
  {
    path: '/pl/pl0202/detail/:id',
    element: <PL0202Detail />,
    loader: PL0202DetailLoader,
  },
  {
    path: '/pl/pl0203',
    element: <PL0203 />,
  },
  {
    path: '/pl/pl0203/detail/:id',
    element: <PL0203Detail />,
  },
  {
    path: '/pl/pl0301',
    element: <PL0301 />,
    loader: pl0301Loader,
  },
  {
    path: '/pl/pl0301/Detail/:id/:planStatus',
    element: <PL0301Detail />,
    loader: pl0301DetailLoader,
  },
  {
    path: '/pl/pl0302',
    element: <PL0302 />,
  },
  {
    path: '/pl/pl0302/detail',
    element: <PL0302Detail />,
  },
  {
    path: '/pl/pl0204',
    element: <PL0204 />,
    loader: PL0204Loader,
  },
  {
    path: '/pl/pl0204/detail/:id',
    element: <PL0204Detail />,
    loader: PL0204DetailLoader,
  },
  {
    path: '/pl/pl0207',
    element: <PL0207 />,
    loader: PL0207Loader,
  },
  {
    path: '/pl/pl0207/detail/:id',
    element: <PL0207Detail />,
    loader: PL0207DetailLoader,
  },
  {
    path: '/pl/pl0305',
    element: <PL0305 />,
    loader: PL0305Loader,
  },
  {
    path: 'pl/pl0305/detail/:id',
    element: <PL0305Detail />,
    loader: PL0305DetailLoader,
  },
  {
    path: 'pl/pl0307',
    element: <PL0307 />,
    loader: PL0307Loader,
  },
  {
    path: 'pl/pl0307/detail/:type/:id',
    element: <PL0307Detail />,
    loader: PL0306DetailLoader,
  }
];

const other = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/demo',
    element: <Demo />,
  },
  {
    path: '/faq',
    element: <FaqPage />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '/browser-not-support',
    element: <BrowserNotSupport />,
  },
];

const plan = [
  {
    path: '/contract-invitation-sign',
    element: <CM01 />,
    loader: createContract,
  },
  {
    path: '/contract-invitation-sign/detail/:id/:taxId',
    element: <CM01Detail />,
    loader: createContract,
  },
  {
    path: '/contract-invitation-sign/documentTemplate/:id/:taxId/:contractAgreementsId',
    element: <DocumentTemplate />,
    loader: createContract,
  },
  {
    path: '/contract-agreement/:contractId/JorPorComment',
    element: <JorPorCommentCM />,
    loader: createContract,
  },
  {
    path: '/contract-agreement/:contractId/JorPorComment/:id',
    element: <JorPorCommentCM />,
    loader: createContract,
  },
  {
    path: '/contract-agreement/',
    element: <CM02 />,
    loader: createContract,
  },
  {
    path: '/contract-agreement/detail/:id',
    element: <CM02Detail />,
    loader: createContract,
  },
  {
    path: '/edit-contract-agreement/',
    element: <ContractAgreementEditing />,
    loader: createContract,
  },
  {
    path: '/edit-contract-agreement/detail/:id',
    element: <ContractAgreementEditDetail />,
    loader: createContract,
  },
  {
    path: '/create-parcels-contractMangement',
    element: <ParcelsList />,
    loader: createContract,
  },
  {
    path: '/procurement-plan-public',
    element: <Public />,
    loader: publicPlanProcurementLoader,
  },
  {
    path: '/procurement-plan-public/detail',
    element: <PublicDetail />,
    loader: publicPlanProcurementDetailLoader,
  },
  {
    path: '/procurement-plan-public/detail/:id',
    element: <PublicDetail />,
    loader: publicPlanProcurementDetailLoader,
  },
  {
    path: '/procurement-plan-adjust',
    element: <Change />,
    loader: changePlanProcurementLoader,
  },
  {
    path: '/procurement-plan-adjust/annual/detail/:id',
    element: <Procurement />,
    loader: planProcurementDetailLoader,
  },
  {
    path: '/procurement-plan-adjust/inyear/detail/:id',
    element: <Procurement />,
    loader: planProcurementDetailLoader,
  },
  {
    path: '/procurement-plan-cancel',
    element: <Cancel />,
    loader: cancelPlanProcurementLoader,
  },
  {
    path: '/procurement-plan-cancel/annual/detail/:id',
    element: <Procurement />,
    loader: planProcurementDetailLoader,
  },
  {
    path: '/procurement-plan-cancel/inyear/detail/:id',
    element: <Procurement />,
    loader: planProcurementDetailLoader,
  },
  {
    path: '/cancel-preprocurement-directive',
    element: <CancelDirective />,
    loader: PP0201DetailLoader,
  },
  {
    path: '/cancel-preprocurement-directive/cancel/:id',
    element: <CancelDirectiveDetail />,
    loader: PP0201DetailLoader,
  },
  {
    path: '/preprocurement-directive',
    element: <CreateDirective />,
    loader: PP0201DetailLoader,
  },
  {
    path: '/preprocurement-directive/detail',
    element: <CreateDirectiveDetail />,
    loader: PP0201DetailLoader,
  },
  {
    path: '/preprocurement-directive/detail/:id',
    element: <CreateDirectiveDetail />,
    loader: PP0201DetailLoader,
  },
  {
    path: '/edit-preprocurement-directive',
    element: <EditDirective />,
    loader: PP0201DetailLoader,
  },
  {
    path: '/edit-preprocurement-directive/edit/:id',
    element: <EditDirectiveDetail />,
    loader: PP0201DetailLoader,
  },
  {
    path: '/preprocurement-tor',
    element: <TORDraft />,
    loader: TorLoader,
  },
  {
    path: '/preprocurement-tor/detail/:id',
    element: <TORDraftDetail />,
    loader: TorLoader,
  },
  {
    path: '/edit-preprocurement-tor',
    element: <TOREdit />,
    loader: TorLoader,
  },
  {
    path: '/edit-preprocurement-tor/detail/:id',
    element: <TOREditDetail />,
    loader: TorLoader,
  },
  {
    path: '/cancel-preprocurement-tor',
    element: <TORCancel />,
    loader: TorLoader,
  },
  {
    path: '/cancel-preprocurement-tor/detail/:id',
    element: <TORCancelDetail />,
    loader: TorLoader,
  },
  {
    path: '/preprocurement-median-price',
    element: <MedianPrice />,
    loader: loadersDatas,
  },
  {
    path: '/preprocurement-median-price/detail/:id',
    element: <MedianPriceDetail />,
    loader: loadersDatas,
  },
  {
    path: '/procurement-plan-summary',
    element: <Annual />,
    loader: planProcurementLoader,
  },
  {
    path: '/procurement-plan-summary/detail',
    element: <AnnualDetail />,
    loader: planProcurementDetailLoader,
  },
  {
    path: '/procurement-plan-summary/detail/:id',
    element: <AnnualDetail />,
    loader: planProcurementDetailLoader,
  },
  {
    path: '/procurement-plan-inyear',
    element: <InYear />,
    loader: inYearPlanProcurementLoader,
  },
  {
    path: '/procurement-plan-inyear/detail',
    element: <Procurement />,
    loader: planProcurementDetailLoader,
  },
  {
    path: '/procurement-plan-inyear/detail/:id',
    element: <Procurement />,
    loader: planProcurementDetailLoader,
  },
  {
    path: '/procurement-cancel-plan',
    element: <Cancel />,
    loader: cancelPlanProcurementLoader,
  },
  {
    path: '/procurement-change-plan/detail/:id',
    element: <Procurement />,
    loader: changePlanProcurementLoader,
  },
  {
    path: '/procurement-appointment-order',
    element: <AppointmentOrder />,
    loader: procurementAppointmentOrderLoader,
  },
  {
    path: '/procurement-appointment-order/detail/:jorPor05Id',
    element: <AppointmentOrderDetail />,
    loader: procurementAppointmentOrderLoader,
  },
  {
    path: '/procurement-appointment-order/detail/:jorPor05Id/id/:id',
    element: <AppointmentOrderDetail />,
    loader: procurementAppointmentOrderLoader,
  },
  {
    path: '/procurement-invitation-order',
    element: <InvitationOrder />,
    loader: InviteLoader,
  },
  {
    path: '/procurement-invitation-order/detail/:jorPor05Id',
    element: <InvitationOrderDetail />,
    loader: InviteDetailLoader,
  },
  {
    path: '/procurement-invitation-order/detail/:jorPor05Id/id/:id',
    element: <InvitationOrderDetail />,
    loader: InviteDetailLoader,
  },
  {
    path: '/procurement-create-006',
    element: <CreateJOR006 />,
    loader: JorPor06Loader,

  },
  {
    path: '/procurement-create-006/detail/:inviteId',
    element: <CreateJOR006Detail />,
    loader: JorPor06DetailLoader,
  },
  {
    path: '/procurement-create-006/detail/:inviteId/:id',
    element: <CreateJOR006Detail />,
    loader: JorPor06DetailLoader,
  },
  {
    path: '/procurement-announcing-winners',
    element: <AnnouncingWinners />,
    loader: announcingWinnerLoader,
  },
  {
    path: '/procurement-announcing-winners/detail/:id',
    element: <AnnouncingWinnersDetail />,
    loader: announcingWinnerDetailLoader,
  },
  {
    path: '/worklist',
    element: <Worklist />,
  },
  {
    path: '/collect-attachment/:id',
    element: <Detail />,
  }
];

const manual = [
  {
    path: '/manual',
    element: <UserManual />,
  },
];

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutTemplate />,
    children: [
      ...cm,
      ...su,
      ...p,
      ...pp,
      ...dashboard,
      ...pr,
      ...pl,
      ...plan,
      ...manual,
    ],
    errorElement: <Error />,
  },
  ...other,
], { basename: '/web' });
