import {
  Card,
  Check,
  Currency,
  DatePicker,
  FileValue,
  Input,
  InputTextArea,
  Radio,
  SearchBuNameModal,
  Selector,
  Table,
  TorStatus,
  UploadFile,
  ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import {
  BuDepartmentListResponse,
  ItemModel,
  SolDataListResponse,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
} from 'models';
import { BasicInfoCreate, BasicInformationGLAccountModel, CommitteeUser, Financial, Objec, Parcel, WorkPhase } from 'models/PP';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {
  FaHistory,
  FaPlus,
  FaSave,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router';
import {
  PP0205Service,
  department,
  planProcurement,
  shareValue,
} from 'services';
import {
  AcceptorStatusEnum,
  HttpStatusCode,
  PositionOnBoardIdEnum,
  ValidatePattern,
  submitForm,
  useAppContext,
} from 'utils';
import { AnnouncementStatus, ProgramType } from 'utils/constants/PlanEnum';
import { scrollToTop } from 'utils/scroll';
import toast from 'utils/toast';
import { generateUniqueId } from '../../../../utils/helper';
import { HistoryModal } from './HistoryModal';
import { ModalSelectUser } from './ModalSelectUser';
import { Context } from './step';
import { JorPorCode } from 'constant/basicInformation';
import { InputNumber } from 'components/Controls/InputNumber';
import { Jorpor04Status } from 'components/JorPor04Status';
import { PlanProcurementModal } from 'components/Modal/PlanProcurementModal';
import { parcels } from 'models/PR/JorPor06Models';

type loader = {
  supplyMethodItems: ItemModel[],
  supplyMethodTypeItems: ItemModel[],
  positionOnBoardItems: positionOnBoardModel[],
  postionItems: ItemModel[],
  departmentItems: ItemModel[],
  criteriaConsiderations: ItemModel[],
  periodUnitDDL: ItemModel[],
  unitOfMeasureDDL: ItemModel[],
  glAccountDDL: ItemModel[],
  positionOnBoardProcurementDDL: ItemModel[],
  positionOnBoardInSpectionDDL: ItemModel[],
  positionOnBoardMADDL: ItemModel[],
  positionOnBoardSupervisorDDL: ItemModel[],
  deliveryCountUnitDDL: ItemModel[];
  MedianPriceConsiderInformationDDL: ItemModel[];
  maintenancePeriodTypeDDL: ItemModel[];
};

interface positionOnBoardModel extends ItemModel {
  sequence: number;
}

const dayItems: ItemModel[] = [
  { label: 'วัน', value: 'day' },
  { label: 'เดือน', value: 'month' },
  { label: 'ปี', value: 'year' },
];

const PeriodType = [
  { label: 'รายวัน', value: 'day' },
  { label: 'รายเดือน', value: 'month' },
  { label: 'รายปี', value: 'year' },
];

const HasWarranty = [
  {
    label: 'มีการรับประกัน',
    value: 'true',
  },
  {
    label: 'ไม่มีการรับประกัน',
    value: 'false',
  },
];

const HasCommitteeMAAndSupervisor = [
  {
    label: 'มี',
    value: 'true',
  },
  {
    label: 'ไม่มี',
    value: 'false',
  },
];

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

enum ModalType {
  PROCUREMENT = 'Procurement',
  INSPECT = 'Inspect',
  MA = 'MA',
  SUPERVISOR = 'Supervisor',
}

interface CheckModel {
  check: boolean;
  value: number;
  label: string;
}

// enum positionOnBoard {
//   president = '2c61837a-f048-460f-8747-efb6d62823d1',
//   committee = '75ae2194-cc83-4e7d-b4a5-ca3c8c34938f',
// }

interface Options {
  value: string;
  label: string;
}

const checkData = [
  {
    check: false, value: '6ff58413-ea2e-4763-bac9-05328d9443aa', label: 'ราคาที่ได้มาจากการคำนวณตามหลักเกณฑ์ที่คณะกรรมการราคากลางกำหนด'
  },
  { check: false, value: '035f21dd-6011-43e9-a1da-a09c96c63783', label: 'ราคาที่ได้มาจากฐานข้อมูลราคาอ้างอิงของพัสดุที่กรมบัญชีกลางจัดทำ' },
  { check: false, value: '4bee8dd9-f01d-4e9f-9fc6-5edfff990b6e', label: 'ราคามาตรฐานที่สำนักงบประมาณหรือหน่วยงานกลางอื่นกำหนด เช่น กระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม กระทรวงสาธารณสุข เป็นต้น' },
  { check: false, value: 'aac2da4b-0049-463b-afbb-1dba16ff5773', label: 'ราคาที่ได้มาจากการสืบราคาจากท้องตลาด' },
  { check: false, value: '65e99258-d3f8-4435-8a30-7173ab0f6813', label: 'ราคาที่เคยซื้อหรือจ้างหรือเช่าครั้งสุดท้ายภายในระยะเวลาสองปีงบประมาณ' },
  { check: false, value: '8999a989-e63b-4443-ac91-39181cfb4749', label: 'ราคาอื่นใดตามหลักเกณฑ์ วิธีการ หรือแนวทางปฏิบัติของหน่วยงานของรัฐนั้น ๆ' },
  // { check: false, value: 7, name: 'ได้แนบตารางแสดงวงเงินงบประมาณที่ได้รับตามสำนักงานคณะกรรมการป้องกันและปราบปรามการทุจริตแห่งชาติ รายละเอียดปรากฎตามเอกสารแนบท้าย' },
];

export default function Step1({ onClickNext, onClickBack }: Props) {
  const { supplyMethodItems, supplyMethodTypeItems, positionOnBoardItems, departmentItems, criteriaConsiderations, periodUnitDDL, unitOfMeasureDDL, glAccountDDL, positionOnBoardProcurementDDL, positionOnBoardInSpectionDDL, positionOnBoardMADDL, positionOnBoardSupervisorDDL, deliveryCountUnitDDL, MedianPriceConsiderInformationDDL, maintenancePeriodTypeDDL } = useLoaderData() as loader;
  const { id, basicInfoId } = useParams();
  const {
    basicInfoCreate,
    activitiesData,
    disable,
    getBasicInfoDetailAsync,
    downloadFileAsync,
    removeFile,
    setBasicInfoCreate,
    handleOnRecallBasicInfo,
    onRecall,
    isUploadFile,
    isAcceptor,
    isCreator,
    isQueue,
    acceptorId,
    medianPriceConsiderationSelect,
  } = useContext(Context);
  const [modalType, setModalType] = useState<ModalType>();
  const [supplyMethodParentItems, setSupplyMethodParentItems] = useState<ItemModel[]>([]);
  const [checkList, setCheckList] = useState<CheckModel[]>([]);
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [openCollapse3, setOpenCollapse3] = useState(true);
  const [openCollapse4, setOpenCollapse4] = useState(true);
  const [openCollapse5, setOpenCollapse5] = useState(true);
  const [openCollapse6, setOpenCollapse6] = useState(true);
  const [openCollapse7, setOpenCollapse7] = useState(true);
  const [openCollapse8, setOpenCollapse8] = useState(true);
  const [openCollapse9, setOpenCollapse9] = useState(true);
  const [openCollapse10, setOpenCollapse10] = useState(true);
  const [openCollapse11, setOpenCollapse11] = useState(true);
  const [openCollapse12, setOpenCollapse12] = useState(true);
  const [openCollapse13, setOpenCollapse13] = useState(true);
  const [openCollapse14, setOpenCollapse14] = useState(true);
  const [openCollapse15, setOpenCollapse15] = useState(true);
  const [openCollapse16, setOpenCollapse16] = useState(true);
  const [openCollapse17, setOpenCollapse17] = useState(true);
  const navigate = useNavigate();
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const { departmentCode, userId } = useAppContext();
  const [departments, setDepartments] = useState<Options[]>([]);
  const [solData, setSolData] = useState<Options[]>([]);
  const [glAccountData, setGLAccountData] = useState<BasicInformationGLAccountModel[]>([]);
  const [isMA, setIsMA] = useState(true);
  const [isSupervisor, setIsSupervisor] = useState(true);
  const [considerData, setConsiderData] = useState('');
  const [defaultMedianPriceConsider, setDefaultMedianPriceConsider] = useState(true);
  const [showPlan, setShowPlan] = useState(false);
  const [objectives, setObjectives] = useState<Objec[]>([]);
  const [parcels, setParcels] = useState<Parcel[]>([]);
  const [financials, setFinancials] = useState<Financial[]>([]);
  const [workPhases, setWorkPhases] = useState<WorkPhase[]>([]);
  const [deliveryPeriodSum, setDeliveryPeriodSum] = useState<number>(0);
  const [appointNumber, setAppointNumber] = useState('');
  const [documentDate, setDocumentDate] = useState<Date>();

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }

    //getDepartmentData();
    getSolData();
  }, [departmentCode]);

  useEffect(() => {

    if (basicInfoCreate) {
      if (basicInfoCreate.glAccounts) {
        setGLAccountData(basicInfoCreate.glAccounts)
      }

      if (basicInfoCreate.isSupervisor) {
        setIsSupervisor(true)
      } else {
        setIsSupervisor(false)
      }

      if (basicInfoCreate.isMA) {
        setIsMA(true)
      } else {
        setIsMA(false)
      }

      if (basicInfoCreate.objectives) {
        setObjectives(basicInfoCreate.objectives);
      }

      if (basicInfoCreate.parcels) {
        setParcels(basicInfoCreate.parcels);
      }

      if (basicInfoCreate.financials) {
        setFinancials(basicInfoCreate.financials);
      }

      if (basicInfoCreate.workPhases) {
        setWorkPhases(basicInfoCreate.workPhases);
      }

      if (basicInfoCreate.deliveryPeriodSum) {
        setDeliveryPeriodSum(basicInfoCreate.deliveryPeriodSum);
      }

      if (basicInfoCreate.appointNumber) {
        setAppointNumber(basicInfoCreate.appointNumber);
      }

      if (basicInfoCreate.documentDate) {
        setDocumentDate(basicInfoCreate.documentDate);
      }
    }
  }, [basicInfoCreate]);

  const getDepartmentData = async () => {
    const { data, status } = await department.getBuDepartmentsListAsync<BuDepartmentListResponse[]>();

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถดึงข้อมูล Department ได้');
      return;
    }

    const newData = data?.map((d) => ({ value: d.code, label: d.name }));
    setDepartments(newData);
  };

  const getSolData = async () => {
    const { data, status } = await department.getSolDatasListAsync<SolDataListResponse[]>();

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถดึงข้อมูล Sol Id ได้');
      return;
    }

    const newData = data?.map((d) => ({ value: d.buId, label: d.buName ?? "" }));
    setSolData(newData);
  };

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  useEffect(() => {
    if (basicInfoCreate.considerationSelect && basicInfoCreate.considerationSelect.length > 0) {
      onSetConsiderData(basicInfoCreate.considerationSelect);

    } else if (defaultMedianPriceConsider && medianPriceConsiderationSelect && medianPriceConsiderationSelect.length > 0) {
      onSetConsiderData(medianPriceConsiderationSelect);
      setDefaultMedianPriceConsider(false);
    }

    // setCheckList(checkData);
  }, [basicInfoCreate.considerationSelect, medianPriceConsiderationSelect]);

  const onSetConsiderData = async (data: string) => {
    const checkListData = [...MedianPriceConsiderInformationDDL];
    const splitData = data.split(',');

    splitData.forEach((s) => checkListData.forEach((c, i) => {
      if (s === c.label) {
        const data = { check: true, value: c.value, label: c.label };

        checkListData.splice(i, 1, data);
      }
    }));

    const consideration = splitData[0];
    const resultData = checkListData?.filter(x => x.label === consideration);

    if (resultData.length > 0) {
      const result = resultData[0].value;
      setConsiderData(result);
    }
  };

  useEffect(() => {
    if (basicInfoCreate.supplyMethod) {
      getSupplyMethodSpecialAsync(basicInfoCreate.supplyMethod);
    }
  }, [basicInfoCreate.supplyMethod]);

  const setCheckListData = (data: string) => {
    if (data) {
      const checkListData = [...checkData];
      const splitData = data.split(',');

      splitData.forEach((s) => checkListData.forEach((c, i) => {
        if (s === c.label) {
          const data = { check: true, value: c.value, name: c.label };

          // checkListData.splice(i, 1, data);
        }
      }));

      // setCheckList(checkListData);
    }
  };

  const onChangeSupplyMethod = async (id: string) => {
    setBasicInfoCreate({ ...basicInfoCreate, supplyMethod: id });

    getSupplyMethodSpecialAsync(id);
  };

  const getSupplyMethodSpecialAsync = async (id: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(id);

    if (status === HttpStatusCode.OK) {
      setSupplyMethodParentItems(data);

      return;
    }

    setSupplyMethodParentItems([]);
  };

  const onShowModalUser = (type: ModalType) => {
    setShow(true);
    setModalType(type);
  };

  const onSelectUser = (user: UserListSelectionResponse) => {
    if (modalType === ModalType.PROCUREMENT
      || modalType === ModalType.INSPECT) {
      if (basicInfoCreate.committeeInspectsUser && basicInfoCreate.committeeInspectsUser.filter(x => x.userId === user.id).length > 0) {
        const committeeInspectsUserData = basicInfoCreate.committeeInspectsUser.filter((x) => x.userId === user.id)[0];

        const noti = ' ถูกกำหนดอยู่ในรายชื่อณะกรรมการตรวจรับ/ผู้ตรวจรับพัสดุแล้ว กรุณาตรวจสอบข้อมูล';
        toast.warn(`${committeeInspectsUserData.fullName + noti}`);
        return;
      }

      if (basicInfoCreate.committeeProcurementUser && basicInfoCreate.committeeProcurementUser.filter(x => x.userId === user.id).length > 0) {
        const committeeProcurementData = basicInfoCreate.committeeProcurementUser.filter((x) => x.userId === user.id)[0];

        const noti = ' ถูกกำหนดอยู่ในผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้างแล้ว กรุณาตรวจสอบข้อมูล';
        toast.warn(`${committeeProcurementData.fullName + noti}`);
        return;
      }
    }

    if (basicInfoCreate.committeeMAUser && basicInfoCreate.committeeMAUser.filter(x => x.userId === user.id && x.committeeType === modalType).length > 0) {
      const committeeMAData = basicInfoCreate.committeeMAUser.filter((x) => x.userId === user.id)[0];

      const noti = ' ถูกกำหนดอยู่ในคณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา) แล้ว กรุณาตรวจสอบข้อมูล';
      toast.warn(`${committeeMAData.fullName + noti}`);
      return;
    }

    if (basicInfoCreate.committeeSupervisorUser && basicInfoCreate.committeeSupervisorUser.filter(x => x.userId === user.id && x.committeeType === modalType).length > 0) {
      const committeeSupervisorData = basicInfoCreate.committeeSupervisorUser.filter((x) => x.userId === user.id)[0];

      const noti = ' ถูกกำหนดอยู่ในผู้ควบคุมงาน (เฉพาะงานก่อสร้าง) แล้ว กรุณาตรวจสอบข้อมูล';
      toast.warn(`${committeeSupervisorData.fullName + noti}`);
      return;
    }

    const initValue = positionOnBoardItems.find(f => f.sequence === 1)?.value;

    const committeeInspectsUser: CommitteeUser[] = basicInfoCreate.committeeInspectsUser ? [...basicInfoCreate.committeeInspectsUser] : [];

    const committeeProcurementUser: CommitteeUser[] = basicInfoCreate.committeeProcurementUser ? [...basicInfoCreate.committeeProcurementUser] : [];

    const committeeMAUser: CommitteeUser[] = basicInfoCreate.committeeMAUser ? [...basicInfoCreate.committeeMAUser] : [];

    const committeeSupervisorUser: CommitteeUser[] = basicInfoCreate.committeeSupervisorUser ? [...basicInfoCreate.committeeSupervisorUser] : [];

    const isPresidentProcurementUserSelected = committeeProcurementUser.filter(comm => comm.positionOnBoardId === PositionOnBoardIdEnum.Chairman && comm.committeeType === modalType).length > 0;

    const isPresidentInspectsUserSelected = committeeInspectsUser.filter(comm => comm.positionOnBoardId === PositionOnBoardIdEnum.Chairman && comm.committeeType === modalType).length > 0;

    const isPresidentMAUserSelected = committeeMAUser.filter(comm => comm.positionOnBoardId === PositionOnBoardIdEnum.Chairman && comm.committeeType === modalType).length > 0;

    const isPresidentSupervisorUserSelected = committeeSupervisorUser.filter(comm => comm.positionOnBoardId === PositionOnBoardIdEnum.Chairman && comm.committeeType === modalType).length > 0;

    const userFromModal: CommitteeUser = {
      userId: user.id,
      name: user.fullName,
      fullName: user.fullName,
      positionName: user.positionName,
      position: user.positionName,
      committeeType: modalType,
      positionOnBoardId: isPresidentProcurementUserSelected || isPresidentInspectsUserSelected || isPresidentMAUserSelected || isPresidentSupervisorUserSelected ? PositionOnBoardIdEnum.Director : initValue,
    };

    if (modalType === ModalType.PROCUREMENT) {
      committeeProcurementUser.push(userFromModal);
      setBasicInfoCreate({ ...basicInfoCreate, committeeProcurementUser: committeeProcurementUser });

      return;
    }

    if (modalType === ModalType.MA) {
      committeeMAUser.push(userFromModal);
      setBasicInfoCreate({ ...basicInfoCreate, committeeMAUser: committeeMAUser });

      return;
    }

    if (modalType === ModalType.SUPERVISOR) {
      committeeSupervisorUser.push(userFromModal);
      setBasicInfoCreate({ ...basicInfoCreate, committeeSupervisorUser: committeeSupervisorUser });

      return;
    }

    committeeInspectsUser.push(userFromModal);
    setBasicInfoCreate({ ...basicInfoCreate, committeeInspectsUser: committeeInspectsUser });
  };

  const removeUserInList = (type: string, index: number) => {
    if (type === 'Precurement' && basicInfoCreate.committeeProcurementUser) {
      const userPrecurementList = [...basicInfoCreate.committeeProcurementUser];
      userPrecurementList.splice(index, 1);
      setBasicInfoCreate({ ...basicInfoCreate, committeeProcurementUser: userPrecurementList });

      return;
    }

    if (type === 'MA' && basicInfoCreate.committeeMAUser) {
      const userPrecurementList = [...basicInfoCreate.committeeMAUser];
      userPrecurementList.splice(index, 1);
      setBasicInfoCreate({ ...basicInfoCreate, committeeMAUser: userPrecurementList });

      return;
    }

    if (type === 'Supervisor' && basicInfoCreate.committeeSupervisorUser) {
      const userPrecurementList = [...basicInfoCreate.committeeSupervisorUser];
      userPrecurementList.splice(index, 1);
      setBasicInfoCreate({ ...basicInfoCreate, committeeSupervisorUser: userPrecurementList });

      return;
    }

    if (basicInfoCreate.committeeInspectsUser) {
      const userInspectsList = [...basicInfoCreate.committeeInspectsUser];
      userInspectsList.splice(index, 1);
      setBasicInfoCreate({ ...basicInfoCreate, committeeInspectsUser: userInspectsList });
    }
  };

  const onChangePositionOnBoard = (event: HTMLSelectElement, index: number, type: string) => {
    if (type === ModalType.PROCUREMENT && basicInfoCreate.committeeProcurementUser) {
      const committeeProcurementUser = [...basicInfoCreate.committeeProcurementUser];
      committeeProcurementUser[index].positionOnBoardId = event.value;

      setBasicInfoCreate({ ...basicInfoCreate, committeeProcurementUser: [...committeeProcurementUser] });

      return;
    }

    if (type === ModalType.MA && basicInfoCreate.committeeMAUser) {
      const committeeMAUser = [...basicInfoCreate.committeeMAUser];

      committeeMAUser[index].positionOnBoardId = event.value;

      setBasicInfoCreate({ ...basicInfoCreate, committeeMAUser: [...committeeMAUser] });

      return;
    }

    if (type === ModalType.SUPERVISOR && basicInfoCreate.committeeSupervisorUser) {
      const committeeSupervisorUser = [...basicInfoCreate.committeeSupervisorUser];

      committeeSupervisorUser[index].positionOnBoardId = event.value;

      setBasicInfoCreate({ ...basicInfoCreate, committeeSupervisorUser: [...committeeSupervisorUser] });

      return;
    }

    if (basicInfoCreate.committeeInspectsUser) {
      const committerInspects = [...basicInfoCreate.committeeInspectsUser];

      committerInspects[index].positionOnBoardId = event.value;
      setBasicInfoCreate({ ...basicInfoCreate, committeeInspectsUser: [...committerInspects] });
    }
  };

  const onCheckCondition = (data: CheckModel, value: boolean) => {
    const checklist = [...checkList];

    checklist.forEach((d, i) => {
      if (d.value === data.value) {
        d.check = value;

        checkList.splice(i, 1, d);
      }
    });
    setCheckList(checklist);
  };

  const formatCheckList = () => {
    const filterCheck = checkList.filter(d => d.check);
    const checkNameList = filterCheck.map(c => c.label);
    const checkData = checkNameList.join(',');
    return checkData;
  };

  const onSaveDraftAsync = async () => {
    const checkData = formatCheckList();

    basicInfoCreate.termOfRefId = id;
    basicInfoCreate.considerationSelect = checkData;
    basicInfoCreate.glAccounts = glAccountData;
    basicInfoCreate.workPhases = workPhases;
    basicInfoCreate.objectives = objectives;
    basicInfoCreate.parcels = parcels;
    basicInfoCreate.financials = financials;
    basicInfoCreate.deliveryPeriodSum = deliveryPeriodSum;
    basicInfoCreate.appointNumber = appointNumber
    basicInfoCreate.documentDate = documentDate

    if (!id && !financials || financials.length === 0) {
      toast.warn('กรุณาระบุวงเงินที่จะจัดจ้าง');
      return;
    }

    const financialSum = financials.reduce((p, c) => p += c.amount, 0);

    if (!id && parseFloat(financialSum?.toFixed(2)) > basicInfoCreate.financialAmount) {
      toast.warn('วงเงินที่จะจัดซื้อจัดจ้างจะต้องเท่ากับจำนวนเงินของ TOR');
      return;
    }

    if (!id && financials.filter((x) => x.description === '' || x.amount <= 0).length > 0) {
      toast.warn('กรุณาระบุรายละเอียด หรือราคา วงเงินที่จะจัดจ้าง');
      return;
    }

    if (!id && parseFloat(financials.reduce((a, v) => a += v.amount, 0).toFixed(2)) > basicInfoCreate.financialAmount) {
      toast.warn('ไม่สามารถระบุวงเงินที่จะจัดจ้างได้มากกว่าวงเงินงบประมาณ');
      return;
    }

    if (!glAccountData || glAccountData.length === 0) {
      toast.warn('ต้องมีข้อมูลรหัสบัญชีและการใช้งบประมาณของฝ่ายอย่างน้อย 1 รายการ');
      return;
    }

    if (glAccountData.filter(x => x.departmentCode === undefined || x.departmentCode === '' || x.departmentCode === null).length > 0) {
      toast.warn('กรุณาระบุ Sol ID ของข้อมูลรหัสบัญชีและการใช้งบประมาณของฝ่าย');
      return;
    }

    if (glAccountData.filter(x => x.departmentCode === undefined || x.departmentCode === '' || x.departmentCode === null).length > 0) {
      toast.warn('กรุณาระบุรหัสบัญชี ของข้อมูลรหัสบัญชีและการใช้งบประมาณของฝ่าย');
      return;
    }

    if (glAccountData.filter(x => x.amount === 0 || x.amount === undefined || x.amount === null).length > 0) {
      toast.warn('กรุณาระบุจำนวนเงิน ของข้อมูลรหัสบัญชีและการใช้งบประมาณของฝ่าย');
      return;
    }

    if (parseFloat(glAccountData.reduce((a, v) => a += v.amount!, 0).toFixed(2)) > basicInfoCreate.financialAmount) {
      toast.warn('ไม่สามารถระบุจำนวนเงินของข้อมูลรหัสบัญชีและการใช้งบประมาณของฝ่ายได้มากกว่าวงเงินที่จะจัดจ้าง');
      return;
    }

    if (!id && parcels.length === 0 || !parcels) {
      toast.warn('กรุณาระบุขอบเขตของงาน');
      return;
    }

    if (!id && parcels.filter((x) => x.description === '' || x.quantity <= 0 || x.unit === '').length > 0) {
      return;
    }

    if (!id && objectives.length === 0 || !objectives) {
      toast.warn('กรุณาระบุวัตถุประสงค์');
      return;
    }

    if (!id && objectives.filter((x) => x.description === '').length > 0) {
      return;
    }

    // if (!id && (!workPhases || workPhases.length === 0)) {
    //   toast.warn('กรุณาระบุระยะเวลาดำเนินการ/ส่งมอบงาน');
    //   return;
    // }

    // if (!id && (workPhases.filter((x) => x.detail === '' || x.periodDay <= 0).length > 0)) {
    //   toast.warn('กรุณาระบุรายละเอียดหรือจำนวนวันมากกว่า 0 ');
    //   return;
    // }

    // if (!id && (workPhases.reduce((a, v) => a += v.percent!, 0) < 100 || workPhases.reduce((a, v) => a += v.percent!, 0) > 100)) {
    //   toast.warn('กรุณาระบุจำนวนเงิน (%) รวมเท่ากับ 100%');
    //   return;
    // }

    let resultConsider = '';
    if (considerData) {
      const checkListData = [...MedianPriceConsiderInformationDDL];
      resultConsider = checkListData.filter(x => x.value === considerData)[0].label
    }

    basicInfoCreate.considerationSelect = resultConsider;

    if (!basicInfoId) {
      const { data, status } = await PP0205Service.createBasicInfoAsync(basicInfoCreate);

      if (status === HttpStatusCode.CREATED) {
        toast.success('บันทึกข้อมูลสำเร็จ');

        if (id) {
          navigate(`/pp/pp0205/detail/${id}/${data.id}`);
        } else {
          navigate(`/pp/pp0205/detail/basicInfo/${data.id}`);
        }

        getBasicInfoDetailAsync(id, data.id);
      }

      return;
    }

    basicInfoCreate.id = basicInfoId;

    const response = await PP0205Service.updateBasicInfoAsync(basicInfoCreate, basicInfoId, AnnouncementStatus.Draft);

    if (response.status === HttpStatusCode.ACCEPTED || response.status === HttpStatusCode.OK) {
      toast.success('บันทึกข้อมูลสำเร็จ');

      getBasicInfoDetailAsync(id, basicInfoId);
    }
  };

  const handleOnClickNext = () => {
    formatCheckList();
    scrollToTop();
    onClickNext();
  };

  const onCheckSubmitForm = (fn: Function) => {
    submitForm({
      supplyMethod: basicInfoCreate.supplyMethod,
      supplyMethodType: basicInfoCreate.supplyMethodType,
      financialAmount: basicInfoCreate.financialAmount,
      planName: basicInfoCreate.planName,
      sourceAndReasons: basicInfoCreate.sourceAndReasons,
      // documentNumber: basicInfoCreate.documentNumber,
      prNumber: basicInfoCreate.prNumber,
      selectionCriteria: basicInfoCreate.selectionCriteria,
    });

    const procurementList = basicInfoCreate.committeeProcurementUser?.filter(c => c.positionOnBoardId === positionOnBoardItems[0].value);
    const inspectList = basicInfoCreate.committeeInspectsUser?.filter(c => c.positionOnBoardId === positionOnBoardItems[0].value);

    if (procurementList && procurementList?.length > 1) {
      toast.warn('ผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้าง มีประธานมากกว่า 1 คน');

      return;
    }

    if (inspectList && inspectList?.length > 1) {
      toast.warn('รายชื่อผู้ตรวจรับ/คณะกรรมการตรวจรับ มีประธานมากกว่า 1 คน');

      return;
    }

    const procurementValid = basicInfoCreate.committeeProcurementUser?.length === 0;
    const inspectValid = basicInfoCreate.committeeInspectsUser?.length === 0;

    if (procurementValid) {
      toast.warn('ต้องมีผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้างอย่างน้อย 1 คน');
      return;
    }
    if (inspectValid) {
      toast.warn('ต้องมีรายชื่อผู้ตรวจรับ/คณะกรรมการตรวจรับอย่างน้อย 1 คน');
      return;
    }

    // const considerationValid = basicInfoCreate.financialAmount <= 100000 ?
    //   basicInfoCreate.considerationDescription !== undefined :
    //   checkList.filter(f => f.check).length > 0;

    const considerationValid = basicInfoCreate.financialAmount <= 100000 ?
      basicInfoCreate.considerationDescription !== undefined :
      considerData;

    if (!considerationValid) {
      toast.warn('กรุณาระบุข้อมูลการแจ้งข้อมูลเบื้องต้น (จพ.004');
      return;
    }

    if (basicInfoCreate.supplyMethod
      && basicInfoCreate.supplyMethodType
      && basicInfoCreate.financialAmount
      && basicInfoCreate.planName
      && basicInfoCreate.sourceAndReasons
      // && basicInfoCreate.documentNumber
      && basicInfoCreate.prNumber
      && basicInfoCreate.selectionCriteria) {
      fn();

      return;
    }

    toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
  };

  const onSubmitSave = () => {
    onCheckSubmitForm(onSaveDraftAsync);
  };

  const onNext = () => {
    onCheckSubmitForm(handleOnClickNext);
  };

  const uploadFile = async (value: FileValue) => {
    if (basicInfoId) {
      const response = await PP0205Service.uploadBasicInformationDocument(basicInfoId, value);

      if (response.status === HttpStatusCode.ACCEPTED) {
        getBasicInfoDetailAsync(id, basicInfoId);

        toast.success('อัปโหลดเอกสารสำเร็จ');
      }
    }

    if (basicInfoCreate.documentList && basicInfoCreate.documentList.length > 0) {
      const file: FileValue[] = [...basicInfoCreate.documentList];

      file.push(value);

      setBasicInfoCreate({ ...basicInfoCreate, documentList: file });

      return;
    }

    const file: FileValue[] = [];

    file.push(value);

    setBasicInfoCreate({ ...basicInfoCreate, documentList: file });
  };

  const onBack = () => {
    onClickBack();
  };

  const userSelectList = () => {
    const userSelect: CommitteeUser[] = [];

    if (basicInfoCreate.committeeProcurementUser && basicInfoCreate.committeeProcurementUser.length > 0) {
      basicInfoCreate.committeeProcurementUser.forEach(c => {
        userSelect.push(c);
      });
    }

    if (basicInfoCreate.committeeInspectsUser && basicInfoCreate.committeeInspectsUser.length > 0) {
      basicInfoCreate.committeeInspectsUser.forEach(c => {
        userSelect.push(c);
      });
    }

    return userSelect;
  };

  const handlerOnChangeDepartmentCodes = (value: string, index: number) => {
    const data = [...glAccountData];
    data[index].departmentCode = value;
    setGLAccountData(data);
  };

  const handlerOnChangeGLAccountId = (value: string, index: number) => {
    const data = [...glAccountData];
    data[index].glAccountId = value;
    setGLAccountData(data);
  };

  const handlerOnChangeAmount = (value: number, index: number) => {
    const data = [...glAccountData];
    data[index].amount = value ?? 0;
    setGLAccountData(data);
  };

  const addGLAccount = useCallback(async (sequence: number) => {
    const newData: BasicInformationGLAccountModel = {
      id: undefined,
      departmentCode: '',
      glAccountId: '',
      amount: 0,
      sequence: sequence
    };
    setGLAccountData((glAccountData) => [...glAccountData, newData]);
  }, [glAccountData]);

  const removeGLAccount = (i: number) => {
    const temp: BasicInformationGLAccountModel[] = [...glAccountData];
    temp.splice(i, 1);
    setGLAccountData(temp);
  };

  const onChangeCheckConsiderResult = (considerInformationId: string) => {
    // const medianPriceConsiderInformationResult = [...medianPriceConsiderInformationData];

    // medianPriceConsiderInformationResult.sort((a, b) => a.sequence - b.sequence)
    //   .forEach((data, index) => {
    //     data.result = false;
    //   });

    setConsiderData(considerInformationId);
  };

  const onSelectPlan = async (planId: string, balanceBudget: number) => {

    if (planId) {

      const response = await planProcurement.getDetailAsync(planId);

      if (response.status === HttpStatusCode.OK) {
        setBasicInfoCreate({
          ...basicInfoCreate,
          refPlanNumber: response.data.planProcurement.planNumber,
          supplyMethod: response.data.planProcurement.supplyMethod,
          supplyMethodType: response.data.planProcurement.supplyMethodType,
          supplyMethodSpecialType: response.data.planProcurement.supplyMethodSpecialType,
          departmentId: response.data.planProcurement.departmentId,
          financialAmount: response.data.planProcurement.budget,
          planName: response.data.planProcurement.name,
          budgetYear: response.data.planProcurement.budgetYear,
          expectingProcurementAt: response.data.planProcurement.expectingProcurementAt,
        });
      }
    }

    setShowPlan(false);
  };

  const removeObjective = (i: number) => {
    const temp: Objec[] = [...objectives];
    temp.splice(i, 1);
    setObjectives(temp);
  };

  const handlerOnChangeObjective = (value: string, index: number) => {
    const data = [...objectives];
    data[index].description = value;
    setObjectives(data);
  };

  const addObjective = (objectives: Objec[]) => {

    const maxValueSequence = objectives.length > 0 ? Math.max.apply(null,
      objectives.map((x) => {
        return x.sequence;
      })) : 0;

    const newData: Objec = {
      id: undefined,
      description: '',
      sequence: maxValueSequence + 1,
      basicInfoId: basicInfoCreate.id,
    };

    setObjectives((objectives) => [...objectives, newData]);
  };

  const handlerOnChangeParcelUnit = (value: string, index: number) => {
    const data = [...parcels];
    data[index].unit = value;
    setParcels(data);
  };

  const removeParcel = (i: number) => {
    const temp: Parcel[] = [...parcels];
    temp.splice(i, 1);
    setParcels(temp);
  };

  const addPacel = useCallback(async (parcels: Parcel[]) => {

    const maxValueSequence = parcels.length > 0 ? Math.max.apply(null,
      parcels.map((x) => {
        return x.sequence;
      })) : 0;

    const newData: Parcel = {
      id: undefined,
      sequence: maxValueSequence + 1,
      description: '',
      quantity: 0,
      unit: '',
      price: 0,
      total: undefined,
    };

    setParcels((parcels) => [...parcels, newData]);
  }, []);

  const handlerOnChangeParcelDesc = (value: string, index: number) => {
    const data = [...parcels];
    data[index].description = value;
    setParcels(data);
  };

  const handlerOnChangeParcelQuantity = (value: number, index: number) => {
    const data = [...parcels];
    data[index].quantity = value;
    setParcels(data);
  };

  const addFinancial = useCallback(async (financials: Financial[], financialAmount: number) => {
    const maxValueSequence = financials.length > 0 ? Math.max.apply(null,
      financials.map((x) => {
        return x.sequence;
      })) : 0;

    const newData: Financial = {
      id: undefined,
      financialType: 'default',
      description: '',
      amount: financials.length === 0 ? financialAmount : 0,
      sequence: maxValueSequence + 1,
    };

    setFinancials((financials) => [...financials, newData]);
  }, []);

  const handlerOnChangeFinancialDescription = (value: string, index: number) => {
    const data = [...financials];
    data[index].description = value;
    setFinancials(data);
  };

  const handlerOnChangeFinancialAmount = (value: number, index: number) => {
    const data = [...financials];
    data[index].amount = value;
    setFinancials(data);
  };

  const removeFinancial = (i: number) => {
    const temp: Financial[] = [...financials];
    temp.splice(i, 1);
    setFinancials(temp);
  };

  const addWorkPhase = useCallback(async (workPhases: WorkPhase[]) => {

    const maxValueSequence = workPhases.length > 0 ? Math.max.apply(null,
      workPhases.map((x) => {
        return x.sequence;
      })) : 0;

    const newData: WorkPhase = {
      id: undefined,
      sequence: maxValueSequence + 1,
      phaseNumber: workPhases.length + 1,
      periodDay: 0,
      amount: undefined,
      percent: undefined,
      detail: '',
      deliveryDueDate: undefined
    };

    setWorkPhases((workPhases) => [...workPhases, newData]);
  }, []);

  const handlerOnChangeWorkPhasePhaseNumber = (value: number, index: number) => {
    const data = [...workPhases];
    data[index].phaseNumber = value;
    setWorkPhases(data);
  };

  const handlerOnChangeWorkPhasePeriodDay = (value: number, index: number) => {
    const data = [...workPhases];
    const periodDay = Math.max.apply(null,
      data?.map((x) => {
        return x.periodDay;
      }));

    data[index].periodDay = value;
    setWorkPhases(data);

    if (value > periodDay || data.length === 1) {
      setDeliveryPeriodSum(value)
    }

  };

  const handlerOnChangeWorkPhasePct = (value: number, index: number) => {
    const data = [...workPhases];
    data[index].percent = value;
    setWorkPhases(data);
  };

  const handlerOnChangeWorkPhaseDetail = (value: string, index: number) => {
    const data = [...workPhases];
    data[index].detail = value;
    setWorkPhases(data);
  };

  const removeWorkPhase = (i: number) => {
    const temp: WorkPhase[] = [...workPhases];
    temp.splice(i, 1);
    setWorkPhases(temp);

    if (temp) {
      if (temp.length > 0) {
        const periodDay = Math.max.apply(null,
          temp?.map((x) => {
            return x.periodDay;
          }));
        setDeliveryPeriodSum(periodDay)
      } else {
        setDeliveryPeriodSum(0)
      }
    }
  };

  const onChangeIsMA = (val: string) => {
    if (val === 'true') {
      setIsMA(true);
      setBasicInfoCreate({ ...basicInfoCreate, isMA: true })
    } else {
      setIsMA(false);
      setBasicInfoCreate({ ...basicInfoCreate, isMA: false })

    }
  };

  const onChangeisSupervisor = (val: string) => {
    if (val === 'true') {
      setIsSupervisor(true);
      setBasicInfoCreate({ ...basicInfoCreate, isSupervisor: true })
    } else {
      setIsSupervisor(false);
      setBasicInfoCreate({ ...basicInfoCreate, isSupervisor: false })

    }
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title
          text='ข้อมูลร่างขอบเขตของงาน (TOR)'
          className='fs-5 text-primary'
          extraElement={
            <div className='d-flex gap-2'>
              <Jorpor04Status
                value={basicInfoCreate.status} />
              <Button
                onClick={() => setShowHistory(!showHistory)}
                variant='outline-primary'>
                <FaHistory className='me-1' />
                ประวัติการใช้งาน
              </Button>
            </div>
          } />
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={4}>
            <Input
              label='เลขที่รายงานขอซื้อขอจ้าง (จพ.004)'
              placeholder='เลขที่รายงานขอซื้อขอจ้าง (จพ.004)'
              variant='primary'
              value={basicInfoCreate.basicInformationNumber}
              disabled
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={4}>
            <Input
              label='อ้างอิงแผนการจัดซื้อจัดจ้าง'
              placeholder='อ้างอิงแผนการจัดซื้อจัดจ้าง'
              variant='primary'
              value={basicInfoCreate.refPlanNumber}
              // textSearch={!basicInfoCreate.torNumber && (!basicInfoCreate?.status || basicInfoCreate?.status === AnnouncementStatus.Draft
              //   || basicInfoCreate?.status === AnnouncementStatus.Reject)}
              disabled
              onClick={() => setShowPlan(!showPlan)}
            />
          </Col>
        </Row>
        <Row className='ps-40'>
          <Col sm={9}
            lg={4}>
            <div className='d-flex align-items-center'>
              <Selector label='ฝ่าย/สำนัก'
                placeholder='กรุณาเลือก'
                className='primary'
                disabled
                items={departmentItems}
                value={basicInfoCreate.departmentId} />
            </div>
          </Col>
          <Col sm={3}
            lg={4}>
            <Input label='ปีงบประมาณ'
              placeholder='ปีงบประมาณ'
              disabled
              value={basicInfoCreate.budgetYear} />
          </Col>
        </Row>

        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={8}>
            <Row>
              <Col sm={12}
                lg={4}>
                <Selector
                  name='supplyMethod'
                  value={basicInfoCreate.supplyMethod}
                  items={supplyMethodItems}
                  label='วิธีการจัดหา'
                  placeholder='วิธีการจัดหา'
                  rule={{ required: true }}
                  onChange={(value) => onChangeSupplyMethod(value)}
                  disabled />
              </Col>
              <Col sm={12}
                lg={4}
                style={{ paddingTop: '38px' }}>
                <Selector
                  name='supplyMethodType'
                  value={basicInfoCreate.supplyMethodType}
                  items={supplyMethodTypeItems}
                  rule={{ required: true }}
                  onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, supplyMethodType: value })}
                  disabled />
              </Col>
              <Col sm={12}
                lg={4}
                style={{ paddingTop: '38px' }}>
                <Selector
                  value={basicInfoCreate.supplyMethodSpecialType}
                  items={supplyMethodParentItems}
                  placeholder='เจาะจง'
                  onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, supplyMethodSpecialType: value })}
                  disabled />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={4}>
            <Currency
              disabled={disable}
              name='financialAmount'
              label='วงเงิน (บาท)'
              placeholder='วงเงิน (บาท)'
              rule={{ required: true }}
              value={basicInfoCreate.financialAmount}
              onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, financialAmount: value ?? 0 })} />
          </Col>
          <Col sm={12}
            lg={4}>
            <DatePicker monthYearOnly
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              disabled
              value={basicInfoCreate.expectingProcurementAt as Date} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={4}>
            <DatePicker label='วันที่เอกสารบันทึกข้อความแต่งตั้ง'
              disabled={basicInfoCreate.torNumber !== undefined || disable}
              value={documentDate}
              onChange={(value) => setDocumentDate(value)} />
          </Col>
          <Col sm={12}
            lg={4}>
            <Input label='เลขที่บันทึกแต่งตั้ง'
              placeholder='เลขที่บันทึกแต่งตั้ง'
              disabled={basicInfoCreate.torNumber !== undefined || disable}
              value={appointNumber}
              onChange={(value) => setAppointNumber(value)} />
          </Col>
        </Row>
        <Row className='mt-3 ps-40'>
          <Col sm={12}
            lg={8}>
            <InputTextArea
              name='planName'
              disabled={disable}
              rule={{ required: true }}
              label='ชื่อโครงการ (เรื่อง)'
              value={basicInfoCreate.planName}
              onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, planName: value })} />
          </Col>
          {/* <Col sm={12}
            lg={8}>
            <InputTextArea
              disabled={disable}
              name='sourceAndReasons'
              rule={{ required: true }}
              label='ที่มาและเหตุผล'
              value={basicInfoCreate.sourceAndReasons}
              onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, sourceAndReasons: value })} />
          </Col> */}
        </Row>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='ข้อมูลร่างขอบเขตของงาน (TOR)'
            className='fs-5 text-primary' />
          {openCollapse3 ? (
            <ArrowCollapse onClick={() => setOpenCollapse3(!openCollapse3)} openCollapse={openCollapse3} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse3(true)} openCollapse={openCollapse3} />)}
        </div>
        <Collapse in={openCollapse3}>
          <div>
            <Row className='mt-3 align-items-center ps-40'>
              <Col sm={12}>
                <div className='d-flex justify-content-between my-3'>
                  <p>วัตถุประสงค์</p>
                  {(basicInfoCreate.torNumber === undefined && !disable) && (
                    <Button
                      variant='outline-primary'
                      onClick={() => addObjective(objectives)}
                    >
                      <FaPlus className='me-1' />เพิ่มวัตถุประสงค์
                    </Button>
                  )}
                </div>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 10 }}>ลำดับ</th>
                      <th style={{ minWidth: 250 }}>รายละเอียด</th>
                      {basicInfoCreate.torNumber === undefined && (<th style={{ width: 20 }} />)}
                    </tr>
                  </thead>
                  <tbody>
                    {objectives?.map((data, index) => (
                      <tr>
                        <td className='text-center'>{index + 1}</td>
                        <td>
                          <Input
                            onChange={(val) => handlerOnChangeObjective(val, index)}
                            disabled={basicInfoCreate.torNumber !== undefined || disable}
                            value={data.description}
                            rule={{ required: true }} />
                        </td>
                        {(basicInfoCreate.torNumber === undefined && !disable) && (
                          <td>
                            <Button
                              onClick={() => removeObjective(index)}
                              variant='danger'
                            >
                              <FaTrashAlt />
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='ที่มาและเหตุผล'
            className='fs-5 text-primary' />
          {openCollapse10 ? (
            <ArrowCollapse onClick={() => setOpenCollapse10(!openCollapse10)} openCollapse={openCollapse10} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse10(true)} openCollapse={openCollapse10} />)}
        </div>
        <Collapse in={openCollapse10}>
          <div>
            <Row className='mt-3 align-items-center ps-40'>
              <Col sm={12}
                lg={12}>
                <InputTextArea
                  disabled={disable}
                  name='sourceAndReasons'
                  rule={{ required: true }}
                  // label='ที่มาและเหตุผล'
                  value={basicInfoCreate.sourceAndReasons}
                  onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, sourceAndReasons: value })} />
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='ขอบเขตของงาน'
            className='fs-5 text-primary' />
          {openCollapse11 ? (
            <ArrowCollapse onClick={() => setOpenCollapse11(!openCollapse11)} openCollapse={openCollapse11} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse11(true)} openCollapse={openCollapse11} />)}
        </div>
        <Collapse in={openCollapse11}>
          <div>
            <Row className='mt-3 align-items-center ps-40'>
              <div className='d-flex justify-content-between my-3'>
                <p>ขอบเขตของงาน</p>
                {(basicInfoCreate.torNumber === undefined && !disable) && (
                  <Button
                    variant='outline-primary'
                    onClick={() => addPacel(parcels)}
                  >
                    <FaPlus className='me-1' />เพิ่มรายการ
                  </Button>
                )}
              </div>
              <Col sm={12}>
                {/* <div className='d-flex justify-content-between my-3 '>
                  <p>คุณลักษณะเฉพาะของพัสดุที่ซื้อขาย</p>
                </div> */}
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>ลำดับ</th>
                      <th style={{ minWidth: 250 }}>รายละเอียด</th>
                      <th style={{ width: 250 }}>จำนวน</th>
                      <th style={{ width: 250 }}>หน่วย</th>
                      {(basicInfoCreate.torNumber === undefined && !disable) && (
                        <th style={{ width: 20 }} />
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {parcels?.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr>
                          <td className='text-center'>{index + 1}</td>
                          <td>
                            <Input
                              rule={{ required: true }}
                              value={data.description}
                              onChange={(val) => handlerOnChangeParcelDesc(val, index)}
                              disabled={basicInfoCreate.torNumber !== undefined || disable}
                            />
                          </td>
                          <td>
                            <InputNumber
                              rule={{ required: true }}
                              value={data.quantity}
                              onChange={(val) => handlerOnChangeParcelQuantity(Number(val), index)}
                              disabled={basicInfoCreate.torNumber !== undefined || disable}
                            />
                          </td>
                          <td>
                            <Selector
                              rule={{ required: true }}
                              className='primary'
                              items={unitOfMeasureDDL}
                              onChange={(val) => handlerOnChangeParcelUnit(val, index)}
                              value={data.unit}
                              disabled={basicInfoCreate.torNumber !== undefined || disable} />
                          </td>
                          {(basicInfoCreate.torNumber === undefined && !disable) && (
                            <td>
                              <Button
                                onClick={() => removeParcel(index)}
                                variant='danger'
                              >
                                <FaTrashAlt />
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title text='ข้อมูลการแจ้งข้อมูลเบื้องต้น (จพ.004)'
            className='fs-5 text-primary'
          />
          {openCollapse13 ? (
            <ArrowCollapse onClick={() => setOpenCollapse13(!openCollapse13)} openCollapse={openCollapse13} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse13(true)} openCollapse={openCollapse13} />)}
        </div>
        <Collapse in={openCollapse13}>
          <div>
            <Row className='ps-40'>
              <Col sm={12}
                lg={4}>
                <Input
                  name='documentNumber'
                  // rule={{ required: true }}
                  label='เลขที่สารบรรณ'
                  disabled={disable}
                  placeholder='เลขที่สารบรรณ'
                  value={basicInfoCreate.documentNumber}
                  onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, documentNumber: value })} />
              </Col>
              <Col sm={12}
                lg={4}>
                <Input
                  name='prNumber'
                  disabled={disable}
                  rule={{ required: true }}
                  label='เลขที่เอกสาร PR'
                  placeholder='เลขที่เอกสาร PR'
                  value={basicInfoCreate.prNumber}
                  onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, prNumber: value })} />
              </Col>
            </Row>
            {(basicInfoCreate.financialAmount <= 100000) ? <Row className='ps-40'>
              <Col sm={12}>
                <p className='mb-0'>ราคากลางของพัสดุที่จะซื้อหรือจ้างเช่า หรือ
                  ข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคา</p>
                <p>กรณีวงเงิน <u>ไม่เกิน 100,000 บาท</u> มีข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคา ดังนี้ <span className='text-danger'>*</span></p>
              </Col>
              <Col sm={12}
                lg={8}>
                <InputTextArea
                  name='condiderationDescription'
                  rule={{ required: true }}
                  disabled={disable}
                  value={basicInfoCreate.considerationDescription}
                  onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, considerationDescription: value })} />
              </Col>
            </Row> : <></>}
            {(basicInfoCreate.financialAmount > 100000) ? <Row className='g-3 align-items-center ps-40'>
              <Col sm={12}
                className='mb-1'>
                <p> ราคากลางของพัสดุที่จะซื้อหรือจ้างหรือเช่า หรือข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคา</p>
                <p>กรณีวงเงิน <u>เกิน 100,000 บาท</u> มีข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคา ดังนี้</p>
              </Col>
              {/* {checkList.length > 0 && checkList.map((data, index) => ( */}
              <Col sm={12}
                className='mt-0'
              // key={index}
              >
                {/* <Check
                    disabled={disable}
                    value={data.check}
                    label={data.label}
                    onChange={(value) => onCheckCondition(data, value)} /> */}
                <Radio
                  name='result'
                  disabled={disable}
                  items={MedianPriceConsiderInformationDDL}
                  value={considerData}
                  rule={{ required: true }}
                  onChange={(val) => setConsiderData(val.toString())}
                />
              </Col>
              {/* ))} */}
            </Row> : <></>}
            {/* <Row className='mt-1'>
          <Col sm={12}
            lg={3}>
            <Selector
              disabled={disable}
              items={criteriaConsiderations}
              name='selectionCriteria'
              rule={{ required: true }}
              label='หลักเกณฑ์การคัดเลือก'
              value={basicInfoCreate.selectionCriteria}
              placeholder='หลักเกณฑ์การคัดเลือก'
              onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, selectionCriteria: value })} />
          </Col>
        </Row> */}
            {/* <Row className='mt-1'>
          <Col sm={12} lg={3}>
            <Input
              disabled={disable}
              label="รหัสงบประมาณ"
              placeholder='รหัสงบประมาณ'
              name='budgetCode'
              value={basicInfoCreate.budgetCode}
              onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, budgetCode: value })} />
          </Col>
          <Col sm={12} lg={3}>
            <Selector
              disabled={disable}
              items={departmentItems}
              label="ใช้งบประมาณของฝ่าย"
              placeholder='เลือกฝ่าย'
              name='departmentsBudget'
              value={basicInfoCreate.departmentsBudget}
              onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, departmentsBudget: value })} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={3}>
            <Input
              disabled={disable}
              label="รหัสบัญชี"
              placeholder='รหัสบัญชี'
              name='accountNumber'
              value={basicInfoCreate.accountNumber}
              onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, accountNumber: value })} />
          </Col>
        </Row> */}
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='วงเงินที่จะจัดจ้าง'
            className='fs-5 text-primary' />
          {openCollapse4 ? (
            <ArrowCollapse onClick={() => setOpenCollapse4(!openCollapse4)} openCollapse={openCollapse4} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse4(true)} openCollapse={openCollapse4} />)}
        </div>
        <Collapse in={openCollapse4}>
          <div>
            <Row className='g-3 align-items-center ps-40'>
              <Col className='col-auto'>
                <label className='col-form-label fs-5 text-primary'>วงเงินทั้งสิ้น :</label>
              </Col>
              <Col className='col-auto'>
                <Currency value={financials.reduce((a, v) => a += v.amount, 0)}
                  disabled />
              </Col>
            </Row>
            <div className='d-flex justify-content-between align-items-center'>
              <p className='mb-0'>รายละเอียดวงเงินที่จัดซื้อจัดจ้าง</p>
              {(basicInfoCreate.torNumber === undefined && !disable) && (
                <Button
                  variant='outline-primary'
                  onClick={() => addFinancial(financials, basicInfoCreate.financialAmount)}
                >
                  <FaPlus className='me-1' />เพิ่มรายการ
                </Button>
              )}
            </div>
            <Row className='mt-3 align-items-center ps-40'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 20 }}>ลำดับ</th>
                      <th style={{ minWidth: 150 }}>รายละเอียด</th>
                      <th style={{ width: 150 }}>ราคา</th>
                      {(basicInfoCreate.torNumber === undefined && !disable) && (
                        <th style={{ width: 20 }} />
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {financials?.map((data, index) => (
                      <tr>
                        <td className='text-center'>{index + 1}</td>
                        <td><Input
                          rule={{ required: true }}
                          disabled={basicInfoCreate.torNumber !== undefined || disable}
                          onChange={(val) => handlerOnChangeFinancialDescription(val, index)}
                          value={data.description} />
                        </td>
                        <td><Currency
                          rule={{ required: true }}
                          disabled={basicInfoCreate.torNumber !== undefined || disable}
                          onChange={(val) => handlerOnChangeFinancialAmount(Number(val ?? 0), index)}
                          value={data.amount} />
                        </td>
                        {(basicInfoCreate.torNumber === undefined && !disable) && (
                          <td>
                            <Button
                              onClick={() => removeFinancial(index)}
                              variant='danger'
                            >
                              <FaTrashAlt />
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <div className='d-flex justify-content-between align-items-center mt-4'>
              <p className='mb-0'>ข้อมูลรหัสบัญชีและการใช้งบประมาณของฝ่าย<b className='text-danger'> *</b></p>
              <Button
                variant='outline-primary'
                onClick={() => addGLAccount(glAccountData.length + 1)}
                disabled={disable}
              >
                <FaPlus className='me-2' />เพิ่มข้อมูล
              </Button>
            </div>
            <Row className='mt-3 align-items-center ps-40'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 20 }}>ลำดับ</th>
                      <th style={{ width: 600 }}>Sol ID</th>
                      <th style={{ minWidth: 150 }}>รหัสบัญชี</th>
                      <th style={{ width: 150 }}>จำนวนเงิน</th>
                      <th style={{ width: 20 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {glAccountData?.sort((a, b) => a.sequence - b.sequence).map((data, index) => (
                      <tr>
                        <td className='text-center'>{index + 1}</td>
                        <td>
                          <Col sm={12}>
                            <Selector
                              items={solData}
                              value={data.departmentCode}
                              rule={{ required: true }}
                              onChange={(val) => handlerOnChangeDepartmentCodes(val, index)}
                              disabled={disable} />
                          </Col>
                        </td>
                        <td>
                          <Col sm={12}>
                            <Selector
                              items={glAccountDDL}
                              value={data.glAccountId}
                              rule={{ required: true }}
                              onChange={(val) => handlerOnChangeGLAccountId(val, index)}
                              disabled={disable} />
                          </Col>
                        </td>
                        <td>
                          <Currency disabled={disable}
                            value={data.amount}
                            rule={{ required: true }}
                            onChange={(val) => handlerOnChangeAmount(Number(val ?? 0), index)} />
                        </td>
                        <td className='text-end'>
                          <Button
                            onClick={() => removeGLAccount(index)}
                            variant='danger'
                            disabled={disable}
                          >
                            <FaTrashAlt />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='การส่งมอบ และเงื่อนไขการชำระเงิน'
            className='fs-5 text-primary' />
          {openCollapse5 ? (
            <ArrowCollapse onClick={() => setOpenCollapse5(!openCollapse5)} openCollapse={openCollapse5} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse5(true)} openCollapse={openCollapse5} />)}
        </div>
        <Collapse in={openCollapse5}>
          <div>
            <div className='d-flex align-items-center justify-content-between'>
              <p className='mb-0'>การส่งมอบ และเงื่อนไขการชำระเงิน</p>
            </div>
            <Row className='mt-5 ps-40'>
              <Col sm={12}
                md={5}>
                <InputNumber
                  label='กำหนดเวลาที่ต้องการใช้พัสดุนั้น หรือ ให้งานนั้นแล้วเสร็จ'
                  value={deliveryPeriodSum}
                  onChange={(val) => setBasicInfoCreate({ ...basicInfoCreate, deliveryPeriodSum: Number(val) })}
                  rule={{ pattern: ValidatePattern.NUMBER }}
                  disabled={basicInfoCreate.torNumber !== undefined || disable}
                />
              </Col>
              <Col sm={12}
                md={2}>
                <Selector
                  className='primary no-label'
                  items={periodUnitDDL}
                  value={basicInfoCreate.deliveryUnit}
                  onChange={(val) => setBasicInfoCreate({ ...basicInfoCreate, deliveryUnit: val })}
                  disabled={basicInfoCreate.torNumber !== undefined || disable} />
              </Col>
              <Col sm={12}
                md={3}>
                <Selector
                  className='primary no-label'
                  items={deliveryCountUnitDDL}
                  value={basicInfoCreate.deliveryCountUnit}
                  onChange={(val) => setBasicInfoCreate({ ...basicInfoCreate, deliveryCountUnit: val })}
                  disabled={basicInfoCreate.torNumber !== undefined || disable} />
              </Col>
            </Row>
            {/* {(basicInfoCreate.torNumber === undefined) ? (
              <>
                <div className='d-flex align-items-center justify-content-between'>
                  <p className='mb-0'>เงื่อนไขการชำระเงิน (งานซื้อและงานจ้าง)</p>
                  {!disable && (
                    <Button
                      variant='outline-primary'
                      onClick={() => addWorkPhase(workPhases)}
                    >
                      <FaPlus className='me-1' />เพิ่มเงื่อนไขการชำระเงิน
                    </Button>
                  )}
                </div>
                <Row className='mt-3 align-items-center'>
                  <Col sm={12}>
                    <Table total={1}
                      hidePagination>
                      <thead>
                        <tr>
                          <th style={{ width: 100 }}>ลำดับ</th>
                          <th style={{ width: 100 }}>งวดที่</th>
                          <th style={{ width: 100 }}>จำนวนวัน</th>
                          <th style={{ width: 100 }}>จำนวนเงิน (%)</th>
                          <th>รายละเอียด</th>
                          <th style={{ width: 20 }} />
                        </tr>
                      </thead>
                      <tbody>
                        {workPhases?.map((data, index) => (
                          <tr>
                            <td className='text-center'>{index + 1}</td>
                            <td>
                              <InputNumber
                                rule={{ required: true }}
                                onChange={(val) => handlerOnChangeWorkPhasePhaseNumber(Number(val), index)}
                                disabled={disable}
                                value={data.phaseNumber} />
                            </td>
                            <td>
                              <InputNumber
                                onChange={(val) => handlerOnChangeWorkPhasePeriodDay(Number(val), index)}
                                rule={{ required: true }}
                                disabled={disable}
                                value={data.periodDay} />
                            </td>
                            <td>
                              <Currency
                                onChange={(val) => handlerOnChangeWorkPhasePct(Number(val), index)}
                                rule={{ required: true, pattern: ValidatePattern.PERCENTAGE }}
                                maxValue={100}
                                disabled={disable}
                                value={data.percent} />
                            </td>
                            <td>
                              <InputTextArea
                                onChange={(val) => handlerOnChangeWorkPhaseDetail(val, index)}
                                rule={{ required: true }}
                                disabled={disable}
                                value={data.detail} />
                            </td>
                            <td className='text-end'>
                              {!disable && (
                                <Button
                                  onClick={() => removeWorkPhase(index)}
                                  variant='danger'
                                >
                                  <FaTrashAlt />
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </>

            ) : (<></>)} */}

            {/* <Row className='mt-5 align-items-center'>
              <Col sm={12}>
                <Radio
                  dflex
                  label='คณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)'
                  items={HasCommitteeMAAndSupervisor}
                  value={isMA.toString()}
                  disabled
                  name='name1'
                />
              </Col>
            </Row>
            {(basicInfoCreate.isMA) && (
              <div className='text-end'>
                <Row className='align-items-center'>
                  <Col sm={12}>
                    <Table total={1}
                      hidePagination>
                      <thead>
                        <tr>
                          <th style={{ width: 100 }}>ลำดับ</th>
                          <th>รายละเอียด</th>
                          <th style={{ width: 250 }}>จำนวน</th>
                          <th style={{ width: 250 }}>ระยะเวลา</th>
                          <th style={{ width: 250 }}>จำนวนรวม</th>
                          <th style={{ width: 250 }}>ระยะเวลารวม</th>
                        </tr>
                      </thead>
                      <tbody>
                        {basicInfoCreate.paymentTermPeriods?.sort((a, b) => a.sequence - b.sequence)
                          .map((data, index) => (
                            <tr key={index}>
                              <td className='text-center'>{index + 1}</td>
                              <td>
                                <InputTextArea
                                  value={data.description}
                                  disabled
                                  rule={{ required: true }}
                                />
                              </td>
                              <td>
                                <InputNumber
                                  className='text-center'
                                  value={data.quantity}
                                  disabled
                                  rule={{ required: true }}
                                />
                              </td>
                              <td>
                                <Selector
                                  className='text-start'
                                  items={maintenancePeriodTypeDDL}
                                  value={data.periodType}
                                  disabled
                                  rule={{ required: true }}
                                />
                              </td>
                              <td>
                                <InputNumber
                                  className='text-center'
                                  value={data.totalQuantity}
                                  disabled
                                  rule={{ required: true }}
                                />
                              </td>
                              <td>
                                <Selector
                                  className='text-start'
                                  items={maintenancePeriodTypeDDL}
                                  value={data.totalPeriodType}
                                  disabled
                                  rule={{ required: true }}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            )}
            <Row className='mt-5 align-items-center'>
              <Col sm={12}>
                <Radio
                  label='ผู้ควบคุมงาน (เฉพาะงานก่อสร้าง)'
                  dflex
                  items={HasCommitteeMAAndSupervisor}
                  value={isSupervisor.toString()}
                  name='name2'
                  disabled
                />
              </Col>
            </Row> */}
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='หลักเกณฑ์การคัดเลือก'
            className='fs-5 text-primary' />
          {openCollapse12 ? (
            <ArrowCollapse onClick={() => setOpenCollapse12(!openCollapse12)} openCollapse={openCollapse12} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse12(true)} openCollapse={openCollapse12} />)}
        </div>
        <Collapse in={openCollapse12}>
          <div>
            <Row className='mt-1 ps-40'>
              <Col sm={12}
                lg={6}>
                <Selector
                  disabled={disable}
                  items={criteriaConsiderations}
                  name='selectionCriteria'
                  rule={{ required: true }}
                  label='หลักเกณฑ์การคัดเลือก'
                  value={basicInfoCreate.selectionCriteria}
                  placeholder='หลักเกณฑ์การคัดเลือก'
                  onChange={(value) => setBasicInfoCreate({ ...basicInfoCreate, selectionCriteria: value })} />
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='ผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้าง'
            className='fs-5 text-primary' />
          {openCollapse14 ? (
            <ArrowCollapse onClick={() => setOpenCollapse14(!openCollapse14)} openCollapse={openCollapse14} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse14(true)} openCollapse={openCollapse14} />)}
        </div>
        <Collapse in={openCollapse14}>
          <div>
            <Row className='mt-3 align-items-center ps-40'>
              <Col sm={12}>
                <div className='d-flex justify-content-between my-3'>
                  <p className='mb-0'>ผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้าง</p>
                  {!disable && <Button
                    variant='outline-primary'
                    onClick={() => onShowModalUser(ModalType.PROCUREMENT)}>
                    <FaPlus className='me-1' />เพิ่มรายชื่อ
                  </Button>}
                </div>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ minWidth: 20 }}>ลำดับ</th>
                      <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
                      <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
                      <th style={{ width: 150 }}>ตำแหน่งในบุคคล/คณะกรรมการ</th>
                      {!disable && <th style={{ minWidth: 20 }} />}
                    </tr>
                  </thead>
                  <tbody>
                    {basicInfoCreate.committeeProcurementUser && basicInfoCreate.committeeProcurementUser.map((data, index) => (
                      <tr>
                        <td className='text-center'>{index + 1}</td>
                        <td className='text-start'>{data.fullName ? data.fullName : data.name}</td>
                        <td className='text-start'>{data.positionName ? data.positionName : data.position}</td>
                        <td>
                          <Form.Group className='mb-3'>
                            <InputGroup hasValidation>
                              <Form.Select
                                className='dark mt-1'
                                value={data.positionOnBoardId}
                                disabled={disable}
                                onChange={(event) => onChangePositionOnBoard(event.target as HTMLSelectElement, index, ModalType.PROCUREMENT)}
                              >
                                {
                                  positionOnBoardProcurementDDL.map((item) => (
                                    <option key={item.value}
                                      disabled={basicInfoCreate.committeeProcurementUser ? basicInfoCreate.committeeProcurementUser.filter(c => c.positionOnBoardId === PositionOnBoardIdEnum.Chairman).length > 0 && item.value === PositionOnBoardIdEnum.Chairman : false}
                                      value={item.value}>{item.label}</option>
                                  ))
                                }
                              </Form.Select>
                            </InputGroup>
                          </Form.Group>
                        </td>
                        {!disable && <td className='text-end'>
                          <Button
                            variant='danger'
                            onClick={() => removeUserInList('Precurement', index)}>
                            <FaTrashAlt />
                          </Button>
                        </td>}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card >

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='คณะกรรมการตรวจรับพัสดุ'
            className='fs-5 text-primary' />
          {openCollapse15 ? (
            <ArrowCollapse onClick={() => setOpenCollapse15(!openCollapse15)} openCollapse={openCollapse15} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse15(true)} openCollapse={openCollapse15} />)}
        </div>
        <Collapse in={openCollapse15}>
          <div>
            <Row className='mt-3 align-items-center ps-40'>
              <Col sm={12}>
                <div className='d-flex justify-content-between my-3'>
                  <p className='mb-0'>รายชื่อผู้ตรวจรับ/คณะกรรมการตรวจรับ</p>
                  {!disable && <Button
                    variant='outline-primary'
                    onClick={() => onShowModalUser(ModalType.INSPECT)}>
                    <FaPlus className='me-1' />เพิ่มรายชื่อ
                  </Button>}
                </div>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ minWidth: 20 }}>ลำดับ</th>
                      <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
                      <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
                      <th style={{ width: 150 }}>ตำแหน่งในบุคคล/คณะกรรมการ</th>
                      {!disable && <th style={{ minWidth: 20 }} />}
                    </tr>
                  </thead>
                  <tbody>
                    {basicInfoCreate.committeeInspectsUser && basicInfoCreate.committeeInspectsUser.map((data, index) => (
                      <tr>
                        <td className='text-center'>{index + 1}</td>
                        <td className='text-start'>{data.fullName ? data.fullName : data.name}</td>
                        <td className='text-start'>{data.positionName ? data.positionName : data.position}</td>
                        <td>
                          <Form.Group className='mb-3'>
                            <InputGroup hasValidation>
                              <Form.Select
                                className='dark mt-1'
                                value={data.positionOnBoardId}
                                disabled={disable}
                                onChange={(event) => onChangePositionOnBoard(event.target as HTMLSelectElement, index, ModalType.INSPECT)}
                              >
                                {
                                  positionOnBoardInSpectionDDL.map((item) => (
                                    <option key={item.value}
                                      disabled={basicInfoCreate.committeeInspectsUser ? basicInfoCreate.committeeInspectsUser.filter(c => c.positionOnBoardId === PositionOnBoardIdEnum.Chairman).length > 0 && item.value === PositionOnBoardIdEnum.Chairman : false}
                                      value={item.value}>{item.label}</option>
                                  ))
                                }
                              </Form.Select>
                            </InputGroup>
                          </Form.Group>
                        </td>
                        {!disable && <td className='text-end'>
                          <Button
                            variant='danger'
                            onClick={() => removeUserInList('Inspects', index)}>
                            <FaTrashAlt />
                          </Button>
                        </td>}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='คณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)'
            className='fs-5 text-primary' />
          {openCollapse16 ? (
            <ArrowCollapse onClick={() => setOpenCollapse16(!openCollapse16)} openCollapse={openCollapse16} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse16(true)} openCollapse={openCollapse16} />)}
        </div>
        <Collapse in={openCollapse16}>
          <div>
            <Row className='mt-3 align-items-center ps-40'>
              <Col sm={12}>
                <div className='d-flex justify-content-between my-3'>
                  <p className='mb-0'>คณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)</p>
                  {(!disable && isMA) && <Button
                    variant='outline-primary'
                    onClick={() => onShowModalUser(ModalType.MA)}
                    disabled={!basicInfoCreate.isMA}>
                    <FaPlus className='me-1' />เพิ่มรายชื่อ
                  </Button>}
                </div>
                <Col sm={12}>
                  <Radio
                    dflex
                    // label='คณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)'
                    items={HasCommitteeMAAndSupervisor}
                    value={isMA.toString()}
                    onChange={(val) => (onChangeIsMA(val.toString()))}
                    disabled={basicInfoCreate.torNumber !== undefined || disable}
                    name='name1'
                  />
                </Col>
                {(isMA) && (
                  <Table total={1}
                    hidePagination>
                    <thead>
                      <tr>
                        <th style={{ minWidth: 20 }}>ลำดับ</th>
                        <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
                        <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
                        <th style={{ width: 150 }}>ตำแหน่งในบุคคล/คณะกรรมการ</th>
                        {!disable && <th style={{ minWidth: 20 }} />}
                      </tr>
                    </thead>
                    <tbody>
                      {basicInfoCreate.committeeMAUser && basicInfoCreate.committeeMAUser.map((data, index) => (
                        <tr>
                          <td className='text-center'>{index + 1}</td>
                          <td className='text-start'>{data.fullName ? data.fullName : data.name}</td>
                          <td className='text-start'>{data.positionName ? data.positionName : data.position}</td>
                          <td>
                            <Form.Group className='mb-3'>
                              <InputGroup hasValidation>
                                <Form.Select
                                  className='dark mt-1'
                                  value={data.positionOnBoardId}
                                  disabled={disable}
                                  onChange={(event) => onChangePositionOnBoard(event.target as HTMLSelectElement, index, ModalType.MA)}
                                >
                                  {
                                    positionOnBoardMADDL.map((item) => (
                                      <option key={item.value}
                                        disabled={basicInfoCreate.committeeMAUser ? basicInfoCreate.committeeMAUser.filter(c => c.positionOnBoardId === PositionOnBoardIdEnum.Chairman).length > 0 && item.value === PositionOnBoardIdEnum.Chairman : false}
                                        value={item.value}>{item.label}</option>
                                    ))
                                  }
                                </Form.Select>
                              </InputGroup>
                            </Form.Group>
                          </td>
                          {!disable && <td className='text-end'>
                            <Button
                              variant='danger'
                              onClick={() => removeUserInList('MA', index)}>
                              <FaTrashAlt />
                            </Button>
                          </td>}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='ผู้ควบคุมงาน (เฉพาะงานก่อสร้าง)'
            className='fs-5 text-primary' />
          {openCollapse17 ? (
            <ArrowCollapse onClick={() => setOpenCollapse17(!openCollapse17)} openCollapse={openCollapse17} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse17(true)} openCollapse={openCollapse17} />)}
        </div>
        <Collapse in={openCollapse17}>
          <div>
            <Row className='mt-3 align-items-center ps-40'>
              <Col sm={12}>
                <div className='d-flex justify-content-between my-3'>
                  <p className='mb-0'>ผู้ควบคุมงาน (เฉพาะงานก่อสร้าง)</p>
                  {(!disable && isSupervisor) && <Button
                    variant='outline-primary'
                    onClick={() => onShowModalUser(ModalType.SUPERVISOR)}
                    disabled={!basicInfoCreate.isSupervisor}>
                    <FaPlus className='me-1' />เพิ่มรายชื่อ
                  </Button>}
                </div>
                <Col sm={12}>
                  <Radio
                    // label='ผู้ควบคุมงาน (เฉพาะงานก่อสร้าง)'
                    dflex
                    items={HasCommitteeMAAndSupervisor}
                    value={isSupervisor.toString()}
                    onChange={(val) => (onChangeisSupervisor(val.toString()))}
                    name='name2'
                    disabled={basicInfoCreate.torNumber !== undefined || disable}
                  />
                </Col>
                {(isSupervisor) && (
                  <Table total={1}
                    hidePagination>
                    <thead>
                      <tr>
                        <th style={{ minWidth: 20 }}>ลำดับ</th>
                        <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
                        <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
                        <th style={{ width: 150 }}>ตำแหน่งในบุคคล/คณะกรรมการ</th>
                        {!disable && <th style={{ minWidth: 20 }} />}
                      </tr>
                    </thead>
                    <tbody>
                      {basicInfoCreate.committeeSupervisorUser && basicInfoCreate.committeeSupervisorUser.map((data, index) => (
                        <tr>
                          <td className='text-center'>{index + 1}</td>
                          <td className='text-start'>{data.fullName ? data.fullName : data.name}</td>
                          <td className='text-start'>{data.positionName ? data.positionName : data.position}</td>
                          <td>
                            <Form.Group className='mb-3'>
                              <InputGroup hasValidation>
                                <Form.Select
                                  className='dark mt-1'
                                  value={data.positionOnBoardId}
                                  disabled={disable}
                                  onChange={(event) => onChangePositionOnBoard(event.target as HTMLSelectElement, index, ModalType.SUPERVISOR)}
                                >
                                  {
                                    positionOnBoardSupervisorDDL.map((item) => (
                                      <option key={item.value}
                                        disabled={basicInfoCreate.committeeSupervisorUser ? basicInfoCreate.committeeSupervisorUser.filter(c => c.positionOnBoardId === PositionOnBoardIdEnum.Chairman).length > 0 && item.value === PositionOnBoardIdEnum.Chairman : false}
                                        value={item.value}>{item.label}</option>
                                    ))
                                  }
                                </Form.Select>
                              </InputGroup>
                            </Form.Group>
                          </td>
                          {!disable && <td className='text-end'>
                            <Button
                              variant='danger'
                              onClick={() => removeUserInList('Supervisor', index)}>
                              <FaTrashAlt />
                            </Button>
                          </td>}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      {/* <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='รายการของพัสดุ'
            className='fs-5 text-primary' />
          {openCollapse7 ? (
            <ArrowCollapse onClick={() => setOpenCollapse7(!openCollapse7)} openCollapse={openCollapse7} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse7(true)} openCollapse={openCollapse7} />)}
        </div>
        <Collapse in={openCollapse7}>
          <div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <div className='d-flex justify-content-between my-3'>
                  <p>รายการของพัสดุที่จะซื้อ</p>
                </div>

                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 10 }}>ลำดับ</th>
                      <th style={{ minWidth: 400, width: 400 }}>รายละเอียด</th>
                      <th style={{ minWidth: 250, width: 250 }}>จำนวน</th>
                      <th style={{ minWidth: 250, width: 250 }}>หน่วย</th>
                      <th style={{ minWidth: 250, width: 250 }}>ราคา</th>
                    </tr>
                  </thead>
                  <tbody>
                    {basicInfoCreate.parcels?.map((data, index) => (
                      <tr key={generateUniqueId(index)}>
                        <td className='text-center'>{index + 1}</td>
                        <td>
                          <Input disabled
                            value={data.description} />
                        </td>
                        <td>
                          <Input textEnd
                            disabled
                            value={data.quantity} />
                        </td>
                        <td>
                          <Input textCenter
                            disabled
                            value={data.unit} />
                          <Selector
                            items={unitOfMeasureDDL}
                            value={data.unit}
                            disabled />
                        </td>
                        <td>
                          <Currency disabled
                            value={data.price} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card> */}

      {/* <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='คุณสมบัติ'
            className='fs-5 text-primary' />
          <FaChevronCircleDown
            onClick={() => setOpenCollapse2(!openCollapse2)}
            aria-controls='example-collapse-text'
            aria-expanded={openCollapse2}
            className='ms-2 text-primary' />
        </div>
        <Collapse in={openCollapse2}>
          <div>
            <div className='d-flex justify-content-between align-items-center'>
              <p className='mb-0'>คุณสมบัติ</p>
            </div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 20 }}>ลำดับ</th>
                      <th style={{ minWidth: 150 }}>รายละเอียด</th>
                    </tr>
                  </thead>
                  <tbody>
                    {basicInfoCreate.qualifications?.map((data, index) => (
                      <tr key={index}>
                        <td className='text-center'>{index + 1}</td>
                        <td>
                          <Input disabled
                            value={data.description} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card> */}

      {/* <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='การรับประกันความชำรุดบกพร่อง และการบำรุงรักษา'
            className='fs-5 text-primary' />
          <FaChevronCircleDown
            onClick={() => setOpenCollapse6(!openCollapse6)}
            aria-controls='example-collapse-text'
            aria-expanded={openCollapse6}
            className='ms-2 text-primary' />
        </div>
        <Collapse in={openCollapse6}>
          <div>
            <div className='d-flex align-items-center justify-content-between'>
              <p className='mb-0'>ผู้ได้รับการคัดเลือก(ผู้ขาย/ผู้รับจ้าง/ผู้ให้เช่า) ต้องยอมรับประกันความชำรุดบกพร่อง หรือขัดข้องของพัสดุ/อุปกรณ์ที่(ซื้อขาย/จ้าง/เช่า) ที่ติดตั้งให้กับธนาคาร</p>
            </div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Radio
                  dflex
                  items={HasWarranty}
                  value={basicInfoCreate.defectsWarranty ? basicInfoCreate.defectsWarranty.hasWarranty.toString() : 'false'}
                  disabled />
              </Col>
            </Row>
            <Row className='mt-3 align-items-center'>
              <Col sm={12} lg={3}>
                <InputNumber
                  label='เป็นระยะเวลา'
                  // type='number'
                  // textEnd
                  value={basicInfoCreate.defectsWarranty ? basicInfoCreate.defectsWarranty.count : undefined}
                  disabled />
              </Col>
              <Col sm={12} lg={3} className='no-label'>
                <Selector
                  value={basicInfoCreate.defectsWarranty ? basicInfoCreate.defectsWarranty.unit : undefined}
                  disabled
                  items={periodUnitDDL}
                />
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card> */}

      {/* <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='อัตราค่าปรับ'
            className='fs-5 text-primary' />
          <FaChevronCircleDown
            onClick={() => setOpenCollapse9(!openCollapse9)}
            aria-controls='example-collapse-text'
            aria-expanded={openCollapse9}
            className='ms-2 text-primary' />
        </div>
        <Collapse in={openCollapse9}>
          <div>
            <div className='d-flex align-items-center justify-content-between'>
              <p className='mb-0'>รายการของพัสดุที่จะซื้อ</p>
            </div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 10 }}>ลำดับ</th>
                      <th>รายละเอียด</th>
                      <th style={{ width: 20 }}>อัตราร้อยละ</th>
                      <th style={{ width: 190 }}>คิดเป็น</th>
                    </tr>
                  </thead>
                  <tbody>
                    {basicInfoCreate.fineRates?.map((data, index) => (
                      <tr key={index}>
                        <td className='text-center'>{index + 1}</td>
                        <td><Input disabled
                          value={data.description} /></td>
                        <td><Input textCenter
                          disabled
                          value={data.fine} /></td>
                        <td><Selector className='text-center'
                          items={PeriodType}
                          disabled
                          value={data.fineRatePeriodType} /></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card> */}

      {/* {
        basicInfoCreate.financialAmount > 100000 && <Card className='mt-3'>
          <div className='d-flex align-items-center'>
            <Title
              text='ตารางแสดงวงเงินงบประมาณที่ได้รับจัดสรรและรายละเอียดค่าใช้จ่าย'
              className='fs-5 text-primary' />
            <FaChevronCircleDown
              onClick={() => setOpenCollapse8(!openCollapse8)}
              aria-controls='example-collapse-text'
              aria-expanded={openCollapse8}
              className='ms-2 text-primary' />
          </div>
          <Collapse in={openCollapse8}>
            <div>
              <Row className='mt-3 align-items-center'>
                <Col sm={12}
                  lg={4}>
                  <DatePicker
                    monthYearOnly
                    disabled
                    label='วันที่กำหนดราคากลาง (ราคาอ้างอิง)'
                    value={basicInfoCreate.middlePriceSetDate}
                  />
                </Col>
                <Col sm={12}
                  lg={4}>
                  <Currency
                    disabled
                    label='เป็นจำนวนเงิน'
                    className='primary'
                    value={basicInfoCreate.middlePrice}
                  />
                </Col>
                <Col sm={12}>
                  <Table total={1}
                    hidePagination>
                    <thead>
                      <tr>
                        <th style={{ width: 10 }}>ลำดับ</th>
                        <th>รายละเอียด</th>
                      </tr>
                    </thead>
                    <tbody>
                      {basicInfoCreate.middlePriceSources?.map((data, index) => (
                        <tr key={index}>
                          <td className='text-center'>{index + 1}</td>
                          <td><Input disabled
                            value={data.description} /></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </Collapse>
        </Card>
      } */}



      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              disabled={false}
              value={basicInfoCreate.documentList}
              onChange={(value) => uploadFile(value)}
              onRemove={(index, id) => removeFile(index, id)}
              onDownload={(index, id) => downloadFileAsync(index, id)}
              disabledRemove={!isCreator}
              userId={userId} />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          onClick={onBack}
          variant='outline-primary'>
          <MdArrowBack className='me-2 pb-1 fs-5' />
          กลับหน้าแรก
        </Button>
        {(basicInfoCreate.status === AnnouncementStatus.WaitingAcceptor &&
          isCreator &&
          basicInfoCreate.acceptors?.every(f => f.status === AcceptorStatusEnum.Pending)) && <Button
            className='me-2 px-3'
            variant='danger'
            onClick={() => onRecall()}>
            <FaUndo className='mx-2 pb-1 fs-4' />
            เรียกคืนแก้ไข
          </Button>
        }
        {(isCreator && !disable) && <Button
          onClick={onSubmitSave}
          className='me-2 px-3'
          variant='success'>
          <FaSave className='me-2 pb-1 fs-5' />
          บันทึก
        </Button>}
        {basicInfoId ?
          <Button
            onClick={onNext}
            className='me-2 px-3'
            variant='outline-primary'>
            ถัดไป
            <MdArrowForward className='mห-2 pb-1 fs-5' />
          </Button>
          : <div />}
      </div>
      <HistoryModal
        title='ประวัติการแจ้งข้อมูลเบื้องต้น (จพ.004)'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        basicInformationId={basicInfoCreate.id}
      />

      <SearchBuNameModal
        total={userTotal}
        show={show}
        onHide={() => setShow(false)}
        onSelectData={(data) => onSelectUser(data)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
      // departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <PlanProcurementModal
        show={showPlan}
        onHide={() => setShowPlan(!showPlan)}
        onSelectItem={onSelectPlan}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
        programType={ProgramType.JorPor04}
      />
    </div >
  );
}
