import { AxiosResponse } from 'axios';
import {
  Breadcrumb,
  Card,
  Check,
  Input,
  Modal,
  Selector,
  Table,
  UploadImage,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Role,
  User,
} from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaPlus,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router';
import {
  account,
  ldap,
} from 'services';
import {
  HttpStatusCode,
  submitForm,
} from 'utils';
import toast from 'utils/toast';
import { generateUniqueId } from '../../../utils/helper';

type Loader = { user: User, roles: Role[] };

const BREADCRUMB_INFO = [
  {
    routerName: '/su/su07', label: 'จัดการผู้ใช้งาน',
  },
  {
    routerName: '', label: 'รายละเอียดผู้ใช้งาน',
  },
];

export default function SU07Detail() {
  const loader = useLoaderData() as Loader;
  const [user, setUser] = useState<User>({} as User);
  const [roles, setRoles] = useState<{ label: string, value: string }[]>([]);
  const [searchUser, setSearchUser] = useState<string>('');
  const [hideFormSearch, setFormSearch] = useState<boolean>(true);
  const [file, setFile] = useState<File>();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showUserModal, setShowUserModal] = useState<boolean>(false);

  const backToIndex = () => navigate('/su/su07');

  useEffect(() => {
    if (loader.user) {
      setUser(loader.user);
    } else {
      setUser({ ...user, isActive: true });
      setFormSearch(false);
    }

    setRoles(loader.roles.map((r) => ({ label: `${r.code} : ${r.name}`, value: r.id })));
  }, []);

  const handlerOnSave = () => {
    submitForm();

    if (!user.username && !id) {
      toast.warn('ต้องทำการค้นหาผู้ใช้ก่อน');

      return;
    }

    if (!user.roles || user.roles?.length === 0) {
      toast.warn('ต้องมีกลุ่มสิทธิ์อย่างน้อย 1 กลุ่ม');

      return;
    }

    if (user.roles.some((r) => !r)) {
      return;
    }

    saveUserAsync();
  };

  const saveUserAsync = async () => {
    let res: AxiosResponse = {} as AxiosResponse;

    if (user.id) {
      res = await account.updateUserAsync(user, file);
    } else {
      res = await account.saveUserAsync(user);
    }

    if (res.status === HttpStatusCode.ACCEPTED || res.status === HttpStatusCode.CREATED) {
      toast.success('บันทึกข้อมูลสำเร็จ');
      backToIndex();
    }
  };

  const searchUserAsync = async () => {
    submitForm();

    if (searchUser) {
      setShowUserModal(true);
    }
  };

  const addRole = useCallback(() => {
    if (!user.roles) {
      setUser((user) => ({ ...user, roles: [] }));
    }

    setUser((user) => ({ ...user, roles: [...user.roles, ''] }));
  }, [user]);

  const removeRole = useCallback((i: number) => {
    const { roles } = user;

    roles.splice(i, 1);
    setUser((user) => ({ ...user, roles }));
  }, [user]);

  const handlerOnChange = useCallback((i: number, value: string) => {
    const newUser = { ...user };
    newUser.roles[i] = value;

    setUser(newUser);
  }, [user]);

  const onSelect = (data: User) => {
    setUser({ ...user, ...data });
  };

  return (
    <>
      <div className='m01'>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='mt-2 text-primary'>
            <Button variant='link'
              onClick={backToIndex}>
              <MdOutlineArrowBack className='fs-4 text-primary mb-1' />
            </Button>
            จัดการผู้ใช้งาน
          </h4>
          <Button onClick={handlerOnSave}
            className='d-flex align-items-center gap-2'
            variant='success'>บันทึก</Button>
        </div>

        <Breadcrumb data={BREADCRUMB_INFO} />
        <hr />
        {!hideFormSearch
          ? (
            <Card>
              <p>ค้นหาผู้ใช้งาน</p>
              <Row className='criteria'>
                <Col lg={8}
                  xl={6}
                  className='d-flex gap-2'>
                  <Input
                    label='ชื่อ / อีเมล'
                    rule={{ required: true }}
                    onChange={setSearchUser}
                  />
                  <div className='d-flex gap-2 button-inline'>
                    <Button
                      variant='primary'
                      className='d-flex align-items-center gap-2'
                      onClick={searchUserAsync}
                    >
                      <FaSearch />ค้นหา
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>
          ) : null}
        <Card className='mt-3'>
          <p>ข้อมูลผู้ใช้</p>
          <Row>
            <Col sm={6}
              md={4}
              lg={4}
              xl={3}>
              <p>ชื่อ : {user?.firstName}</p>
            </Col>
            <Col sm={6}
              md={4}
              lg={4}
              xl={3}>
              <p>นามสกุล : {user?.lastName}</p>
            </Col>
            <Col sm={6}
              md={4}
              lg={4}
              xl={3}>
              <p>อีเมล : {user?.email}</p>
            </Col>
            <Col sm={6}
              md={4}
              lg={4}
              xl={3}>
              <p>เบอร์โทรศัพท์ : {user?.tel}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={24}
              md={16}
              lg={16}
              xl={12}>
              <p>ตำแหน่ง : {user?.buPositionName}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Check
                label='ใช้งาน'
                value={user?.isActive}
                onChange={(val) => setUser({ ...user, isActive: val })}
              />
            </Col>
          </Row>
          {hideFormSearch ? (
            <Row>
              <Col xl={12}
                className='mt-3'>
                <p>ลายเซ็น</p>
                <UploadImage
                  value={user.signatureImageId}
                  onChange={(val) => setFile(val)}
                />
              </Col>
            </Row>
          ) : <></>}
        </Card>
        <Card className='mt-3'>
          <div className='d-flex justify-content-between mb-3'>
            <p>สิทธิ์การเข้าใช้งาน</p>
            <Button
              variant='outline-primary'
              className='d-flex align-items-center gap-2'
              onClick={addRole}
            >
              <FaPlus />เพิ่มกลุ่มสิทธิ์
            </Button>
          </div>
          <Table total={1}>
            <thead>
              <tr>
                <th style={{ minWidth: 100 }}>ลำดับ</th>
                <th style={{ minWidth: 300 }}>กลุ่มสิทธิ์</th>
                <th style={{ minWidth: 125 }} />
              </tr>
            </thead>
            <tbody>
              {user.roles?.map((r, i) => (
                <tr key={generateUniqueId(i)}>
                  <td className='text-center'>{i + 1}</td>
                  <td>
                    <Selector
                      items={roles}
                      value={r}
                      onChange={(value) => handlerOnChange(i, value.toString())}
                      rule={{ required: true }}
                    />
                  </td>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='outline-primary'
                        className='d-flex align-items-center gap-2'
                        onClick={() => removeRole(i)}
                      >
                        <FaTrashAlt />ลบ
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </div>
      <SearchUserModal
        show={showUserModal}
        onHide={() => setShowUserModal(false)}
        onSelect={onSelect}
        search={searchUser}
      />
    </>
  );
}

function SearchUserModal(props: {
  show: boolean;
  onHide: () => void;
  onSelect: (user: User) => void;
  search: string;
}) {
  const [search, setSearch] = useState<string>('');
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (props.show) {
      setSearch(props.search);
      searchUserAsync(props.search);
    }
  }, [props.show]);

  const searchUserAsync = async (search: string) => {
    submitForm();

    if (!search) {
      return;
    }

    const res = await ldap.getUserListAsync(search);

    switch (res.status) {
      case HttpStatusCode.OK:
        setUsers(res.data);
        break;
      case HttpStatusCode.NOT_FOUND:
        toast.warn('ไม่พบผู้ใช้');
        break;
    }
  };

  const onSelect = (index: number) => {
    props.onSelect(users[index]);

    props.onHide();
  };

  const clearCriteria = () => {
    setSearch('');
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='xl'
    >
      <Modal.Header>
        <Title
          text='ค้นหาผู้ใช้งาน'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row className='mt-3'>
        <Col sm={6}
          lg={6}>
          <Input
            label='ชื่อ / อีเมล'
            value={search}
            onChange={(val) => setSearch(val)}
            rule={{ required: true }} />
        </Col>
        <Col className='d-flex gap-2 criteria'>
          <Button
            variant='primary'
            className='d-flex align-items-center gap-2 button-inline'
            onClick={() => searchUserAsync(search)}>
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2 button-inline'
            onClick={clearCriteria}
          >
            <FaEraser />ล้าง
          </Button>
        </Col>
      </Row>
      <Table
        className='mt-3'
        hidePagination
      >
        <thead>
          <tr>
            <th style={{ minWidth: 20 }}>ลำดับ</th>
            <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
            <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
            <th style={{ minWidth: 25 }} />
          </tr>
        </thead>
        <tbody>
          {
            users.map((data, i) => (
              <tr key={data.id}>
                <td className='text-center'>{i + 1}</td>
                <td>{data.firstName} {data.lastName}</td>
                <td className='text-center'>{data.departmentName}</td>
                <td className='text-center'>{data.positionName}</td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Button variant='primary'
                      onClick={() => onSelect(i)}>
                      เลือก
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Modal>
  );
}
