import { Approver, ApproverStatus, HistoryModal, Table } from 'components';
import Title from 'components/Controls/Title';
import { StatusDetail, TypeStatus } from 'components/StatusDetail';
import { CriteriaSupplyMethodId } from 'constant/PreProcumentFlag';
import { AcceptorModel } from 'models/PP';
import { sectionApproverModel } from 'models/delegateModel';
import {
  useEffect,
  useState,
} from 'react';
import { Button, Card, Col, Collapse } from 'react-bootstrap';
import { FaChevronCircleDown, FaChevronCircleUp, FaHistory, FaPlus, FaRedo, FaTrashAlt } from 'react-icons/fa';
import { account } from 'services';
import { HttpStatusCode, ProcurementSection, fullDatetime } from 'utils';
import { AcceptorStatus, ProgramType } from 'utils/constants/PlanEnum';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';

type sectionGroup = {
  name: string;
  sequence: number;
  level: number;
  inRefCode: string;
}

interface Props {
  data: any[];
  activity?: any[];
  status?: string;
  typeStatus?: string;
  visible: boolean;
  processType: PROCESSTYPE;
  budgetAmount: number;
  isDepartmentDirector: boolean;
  supplyMethodId?: string;
  program?: ProgramType;
  titleHistory?: string;
  ignorePurchaseOnTable159?: boolean;
  departmentCode?: string;
  acceptorByUserId?: string;
  onAcceptorModalShow?: () => void;
  onSetSectionType: (sectionType: string) => void;
  onSetSectionSequence?: (sectionSequence: number) => void;
  onAcceptorDataChange: (data: any[]) => void;
}

export function Acceptors(props: Props) {
  const [approverCollapsed, setApproverCollapsed] = useState<boolean>(true);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [approverData, setApproverData] = useState<AcceptorModel[]>(props.data);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [showActivity, setShowActivity] = useState(false);

  useEffect(() => {
    props.onAcceptorDataChange(approverData);
  }, [approverData]);

  useEffect(() => {
    setApproverCollapsed(false);
    if (props.data
      && props.data.length > 0) {
      onCheckApproverStatus();
      setAcceptorData();
    }

  }, [props.data]);

  useEffect(() => {
    if (!approverData || approverData.length === 0) {
      if (props.isDepartmentDirector) {
        onDefaultDepartmentDirector();
      } else {
        onDefaultAcceptor();
      }
    }
  }, []);

  const setAcceptorData = () => {
    setApproverData(props.data);

    if (!sections || sections.length === 0) {
      const sectionDatas: sectionGroup[] = [];

      props.data.sort((a, b) => a.sectionSequence! - b.sectionSequence!)
        .forEach((item) => {
          sectionDatas.push({
            name: item.sectionName,
            sequence: item.sectionSequence,
            inRefCode: item.sectionType,
          } as sectionGroup);
        });

      const uniqueNames = sectionDatas.filter((data, index) => {
        return sectionDatas.findIndex(d => d.inRefCode === data.inRefCode) === index;
      });

      setSections(uniqueNames);
    }
  };

  const onDefaultAcceptor = async () => {

    if (props.supplyMethodId) {
      let refBankOrder = 159;

      if ((props.program === ProgramType.JorPor06) && props.supplyMethodId === CriteriaSupplyMethodId.MethodId60) {
        refBankOrder = 216;
      } else if (props.program === ProgramType.ContractAgreement && props.supplyMethodId === CriteriaSupplyMethodId.MethodId60) {
        refBankOrder = 528;
      } else if (props.program === ProgramType.ContractReturnCollateral) {
        refBankOrder = 285;
      }

      const res = await account
        .getDefaultApproverBySupplyMethod(props.processType, props.budgetAmount, refBankOrder, props.ignorePurchaseOnTable159, props.departmentCode, props.acceptorByUserId);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
        mapData(res.data);
      }
    } else {
      const res = await account
        .getDefaultApproverByTopLeve(props.processType, props.budgetAmount, undefined, props.ignorePurchaseOnTable159, props.departmentCode, props.acceptorByUserId);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
        mapData(res.data);
      }
    }
  };

  const onDefaultDepartmentDirector = async () => {
    const res = await account
      .getDefaultDepartmentDirectorAgree(ProcurementSection.DEPARTMENT_DIRECTOR_AGREE);

    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
      mapData(res.data);
    }
  };

  const mapData = (data: sectionApproverModel[]) => {
    const newDatas = data;
    const sectionDatas: sectionGroup[] = [];
    const approver: AcceptorModel[] = [];

    newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
      .forEach((item, index) => {
        sectionDatas.push({
          name: item.sectionName,
          level: item.sectionLevel,
          sequence: item.sectionSequence,
          inRefCode: item.inRefCode,
        } as sectionGroup);

        approver.push({
          id: undefined,
          sectionCode: ProcurementSection.APPROVER,
          userId: item.userId,
          fullName: item.fullName,
          departmentName: item.departmentName,
          positionName: item.positionName,
          status: AcceptorStatus.DRAFT,
          at: undefined,
          isAvailable: false,
          isAvailableRemark: item.delegateAnnotation,
          acceptDate: undefined,
          acceptRemark: '',
          rejectDate: undefined,
          rejectRemark: '',
          sequence: index + 1,
          sectionType: item.sectionType,
          sectionName: item.sectionName,
          sectionSequence: item.sectionSequence,
          delegateUserId: item.delegateUserId,
          delegateEmployeeCode: item.delegateEmployeeCode,
          delegateFullName: item.delegateFullName,
          delegateDepartmentName: item.delegateDepartmentName,
          delegatePositionName: item.delegatePositionName,
          delegateRangeDate: item.delegateDateRange,
          inRefCode: item.inRefCode,
          sectionInRefCode: item.inRefCode,
        } as AcceptorModel);
      });

    const uniqueNames = sectionDatas.filter((data, index) => {
      return sectionDatas.findIndex(d => d.inRefCode === data.inRefCode) === index;
    });

    setSections(uniqueNames);
    setApproverData(approver);
  }

  const onRemoveUser = (userId: string, inRefCode: string) => {
    const index = approverData.findIndex((p) => p.userId === userId && p.sectionType === inRefCode);
    approverData.splice(index, 1);
    setApproverData([...approverData]);
  };

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = props.data ? Math.max.apply(null,
      props.data.map((x) => {
        return x.sectionSequence!;
      })) : 0;

    const valueSequence = props.data?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence!);
    setIsSectionSequence(maxValueSectionSequence);
  };

  return (
    <>
      {/* Approver */}
      <Card className='mt-3'>
        {/* Title */}
        <div className='d-flex justify-content-between gap-3 mt-3'>
          <div className='d-flex gap-3 mt-3 pb-3'>
            <Title
              text='ผู้มีอำนาจเห็นชอบ/อนุมัติ'
              className='fs-5 text-primary'
            />
            {approverCollapsed
              ? (
                <FaChevronCircleDown
                  className='text-primary fs-5 cursor-pointer'
                  onClick={() => setApproverCollapsed(false)}
                />
              ) : (
                <FaChevronCircleUp
                  className='text-primary fs-5 cursor-pointer'
                  onClick={() => setApproverCollapsed(true)}
                />
              )}
          </div>
          <div className='d-flex gap-3 align-items-end gap-3 mt-3 px-4'>
            {(props.status && props.typeStatus) && (
              <StatusDetail value={props.status} type={props.typeStatus} />
            )}
            {props.activity && (
              <Button
                className='px-3'
                onClick={() => setShowActivity(true)}
                variant='outline-primary'
              >
                <FaHistory className='me-3' />ประวัติการใช้งาน
              </Button>
            )}
          </div>
        </div>
        <Collapse in={!approverCollapsed}>
          <div className='px-4'>
            <div className='mt-3'>
              {props.visible
                ? (
                  <Button
                    className='d-flex align-items-center gap-2'
                    variant='outline-primary'
                    onClick={() => {
                      props.isDepartmentDirector
                        ? onDefaultDepartmentDirector()
                        : onDefaultAcceptor()
                    }}
                  >
                    <FaRedo />กำหนดผู้มีอำนาจเห็นชอบเริ่มต้น
                  </Button>
                ) : null}
            </div>
            {
              (sections && sections.length > 0) ? (
                sections
                  .sort((a, b) => a.sequence - b.sequence)
                  .map((section, index) => (
                    <Col sm={12} key={index}>
                      {props.visible
                        ? (
                          <div className='d-flex justify-content-between align-items-center mt-5'>
                            <p className='fs-5 text-primary m-0'>
                              {/* {section.name} */}
                            </p>
                            <Button
                              className='d-flex align-items-center gap-2'
                              variant='outline-primary'
                              onClick={() => {
                                (props.onAcceptorModalShow ? props.onAcceptorModalShow() : null);
                                props.onSetSectionType(section.inRefCode);
                                (props.onSetSectionSequence ? props.onSetSectionSequence(section.sequence) : undefined);
                              }}
                            >
                              <FaPlus />เพิ่มรายชื่อ
                            </Button>
                          </div>
                        ) : null}
                      <Table
                        hidePagination
                        className='mt-3 step-bar'
                        notResponse
                      >
                        <thead>
                          <tr>
                            <th style={{ width: '5%' }}>ลำดับ</th>
                            <th className='text-start'
                              style={{ width: '30%' }}>ชื่อ-นามสกุล
                            </th>
                            <th className='text-start'
                              style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                            </th>
                            <th style={{ width: '10%' }}>สถานะ</th>
                            <th style={{ width: '10%' }}>วันที่เห็นชอบ</th>
                            <th style={{ minHeight: 100, width: '5%' }} />
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (approverData && approverData.length > 0) && (
                              approverData
                                .filter(a => a.sectionType === section.inRefCode)
                                .sort((a, b) => a.sequence - b.sequence)
                                .map((data, i) => (
                                  <>
                                    <tr key={i}>
                                      <td className='text-center'>{i + 1}</td>
                                      <td className='text-left'>
                                        {data.fullName}
                                        <p className='m-0 department text-wrap'>
                                          {data.positionName}
                                        </p>
                                      </td>
                                      <td className='text-left'>
                                        {data.delegateFullName &&
                                          <>
                                            {data.delegateFullName}
                                            <p className='m-0 department text-wrap'>
                                              {data.delegatePositionName}
                                            </p>
                                          </>
                                        }
                                      </td>
                                      <td className='text-center'>
                                        {isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 && (props.typeStatus === undefined || props.typeStatus !== TypeStatus.JorPor04)
                                          ? <Approver value={data.status} outline />
                                          : <ApproverStatus value={data.status} outline />
                                        }
                                      </td>
                                      <td className='text-center'>
                                        {data.status === AcceptorStatus.APPROVE && data.acceptDate ? fullDatetime(data.acceptDate) : ''}
                                      </td>
                                      <td className='text-end'>
                                        {props.visible && approverData.length > 1 &&
                                          <Button
                                            variant='danger'
                                            onClick={() => onRemoveUser(data.userId, section.inRefCode)}
                                          >
                                            <FaTrashAlt />
                                          </Button>}
                                      </td>
                                    </tr>
                                    {data.delegateRangeDate || data.acceptRemark || data.rejectRemark ?
                                      <tr className='border-bottom bg-opacity-10 bg-light'>
                                        <td />
                                        <td colSpan={5}
                                          className='p-0'>
                                          <ul className='step-bar-list'>
                                            {data.delegateRangeDate &&
                                              <li>
                                                <div className='description'>
                                                  <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                                  <p className='mb-0'
                                                    style={{ color: 'gray' }}>
                                                    {data.delegateRangeDate}
                                                  </p>
                                                </div>
                                              </li>
                                            }
                                            {data.acceptRemark &&
                                              <li>
                                                <div className='description'>
                                                  <p className='mb-0 text-primary'>หมายเหตุ{isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ? 'อนุมัติ' : 'เห็นชอบ'}
                                                  </p>
                                                  <p className='mb-0 text-wrap'
                                                    style={{ color: 'gray' }}>
                                                    <span className='text-muted me-2'>[{data.acceptDate ? fullDatetime(data.acceptDate) : ''}]</span>
                                                    {data.acceptRemark}
                                                  </p>
                                                </div>
                                              </li>
                                            }
                                            {data.rejectRemark &&
                                              <li>
                                                <div className='description'>
                                                  <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                                  <p className='mb-0 text-wrap'
                                                    style={{ color: 'gray' }}>
                                                    <span className='text-muted me-2'>[{data.rejectDate ? fullDatetime(data.rejectDate) : ''}]</span>
                                                    {data.rejectRemark}
                                                  </p>
                                                </div>
                                              </li>
                                            }
                                          </ul>
                                        </td>
                                      </tr>
                                      : <tr className='border-bottom' />}
                                  </>
                                ))
                            )
                          }
                        </tbody>
                      </Table >
                    </Col>
                  ))
              ) : (
                <div className='d-flex flex-column gap-3 mt-3'>
                  <Col>
                    <span className='text-primary'>ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
                  </Col>
                </div>
              )
            }
          </div>
        </Collapse>
      </Card >

      <HistoryModal
        title={props.titleHistory ? props.titleHistory : 'ประวัติ'}
        show={showActivity}
        onHide={() => setShowActivity(!showActivity)}
        data={props.activity!}
      />
    </>
  );
}