import { AppointmentOrderStatus } from 'components';
import Cookie from 'cookie-universal';
import {
  CountStatusModel,
  CountSupplyMethodModel,
  CreateAppointCommiteeModel,
  SearchProcurementAppointment,
  UpdateAppointCommiteeModel,
} from 'models/procurementAppointmentOrderModel';
import http from 'utils/axios';

const cookies = Cookie();

const qs = require('qs');

const getFullName = cookies.get('fullName') as string;
const getAppointmentorderListAsync = async (searchData: SearchProcurementAppointment) => {
  const params = {
    jorPor05Number: searchData.jorPor05Number,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    prNumber: searchData.prNumber,
    responsiblePerson: searchData.responsiblePerson,
    supplyMethodIds: searchData.supplyMethodIds,
    status: searchData.status,
    page: searchData.page,
    size: searchData.size,
    isResponsible: searchData.isResponsible,
  };

  return http.get('/appointmentorder/pagination', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountStatusAsync = (searchData: SearchProcurementAppointment) => {
  const api = '/appointmentorder';

  const params = {
    page: searchData.page,
    size: searchData.size,
    jorPor05Number: searchData.jorPor05Number,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    prNumber: searchData.prNumber,
    responsiblePerson: searchData.responsiblePerson,
    supplyMethodIds: searchData.supplyMethodIds,
    status: searchData.status,
    isResponsible: searchData.isResponsible,
  };
  return http.get<CountStatusModel[]>(`${api}/countStatus`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountSupplyMethodAsync = (searchData: SearchProcurementAppointment) => {
  const api = '/appointmentorder';

  const params = {
    page: searchData.page,
    size: searchData.size,
    jorPor05Number: searchData.jorPor05Number,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    prNumber: searchData.prNumber,
    responsiblePerson: searchData.responsiblePerson,
    status: searchData.status,
    supplyMethodIds: searchData.supplyMethodIds,
    isResponsible: searchData.isResponsible,

  };
  return http.get<CountSupplyMethodModel[]>(`${api}/countSupplyMethod`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getAppointmentOrderByIdAsync = async (jorPor05Id?: string, id?: string) => {
  const paramId = id || '';
  return http.get(`/appointmentOrder/jorPor05/${jorPor05Id}/id/${paramId}`);
};

const deleteAppointmentProcurementDocumentAsync = (id: string, docNameList: string[]) => http.delete(`/appointcommitteeprocurement/${id}/documents`, { data: { docNames: docNameList } });

const approverProcurementPendingAsync = async (id: string) => http.put(`/appointcommitteeprocurement/pending/${id}`);

const createAppointCommiteeAsync = (body: CreateAppointCommiteeModel) => {
  const formData = new FormData();
  // formData.append('planId', body.planId ? body.planId : '');
  formData.append('planNumber', body.planNumber);
  formData.append('planName', body.planName);
  formData.append('torId', body.torId);
  formData.append('jorPor04Number', body.jorPor04Number);
  formData.append('jorPor05Id', body.jorPor05Id);
  formData.append('departmentId', body.departmentId);
  formData.append('supplyMethodType', body.supplyMethodType);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
  formData.append('budgetYear', body.budgetYear.toString());
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString().split('T')[0]);
  formData.append('Subject', body.subject);
  formData.append('sourceAndReasons', body.sourceAndReasons);
  formData.append('createdByUserFullName', getFullName);

  if (body.files && body.files.length !== 0) {
    body.files.forEach((file) => {
      formData.append('files', file);
    });
  }

  if (body.dutyProcurements && body.dutyProcurements.length !== 0) {
    body.dutyProcurements.forEach((data, index) => {
      formData.append(`dutyProcurements[${index}][sequence]`, index.toString());
      formData.append(`dutyProcurements[${index}][duty]`, data.duty ? data.duty : '');
    });
  }

  if (body.dutyInspectors && body.dutyInspectors.length !== 0) {
    body.dutyInspectors.forEach((data, index) => {
      formData.append(`dutyInspectors[${index}][sequence]`, index.toString());
      formData.append(`dutyInspectors[${index}][duty]`, data.duty ? data.duty : '');
    });
  }

  if (body.approverSections && body.approverSections.length > 0) {
    let inx = 0;
    let sectionSeq = 0;

    body.approverSections.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((data, index) => {
      if (sectionSeq !== data.sectionSequence) {
        inx = 1;
        sectionSeq = data.sectionSequence;
      }

      formData.append(`ApproverSections[${index}][sectionSequence]`, data.sectionSequence.toString());
      formData.append(`ApproverSections[${index}][sectionName]`, data.sectionName ? data.sectionName : '');
      formData.append(`ApproverSections[${index}][sequence]`, (inx).toString());
      formData.append(`ApproverSections[${index}][userId]`, data.userId);
      formData.append(`ApproverSections[${index}][fullName]`, data.fullName);
      formData.append(`ApproverSections[${index}][position]`, data.position);
      formData.append(`ApproverSections[${index}][department]`, data.department);
      formData.append(`ApproverSections[${index}][isDisabled]`, data.isDisabled ? 'true' : 'false');
      formData.append(`ApproverSections[${index}][remark]`, data.remark || '');

      inx++;
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return http.post('/appointmentOrder', formData, config);
};

const updateAppointCommiteeAsync = (body: UpdateAppointCommiteeModel) => {
  const formData = new FormData();

  formData.append('planId', body.planId ? body.planId : '');
  formData.append('planNumber', body.planNumber);
  formData.append('planName', body.planName);
  formData.append('torId', body.torId);
  formData.append('jorPor04Number', body.jorPor04Number);
  formData.append('jorPor05Id', body.jorPor05Id);
  formData.append('departmentId', body.departmentId);
  formData.append('supplyMethodType', body.supplyMethodType);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
  formData.append('budgetYear', body.budgetYear.toString());
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString().split('T')[0]);
  formData.append('Subject', body.subject);
  formData.append('sourceAndReasons', body.sourceAndReasons);
  formData.append('updateByUserFullName', getFullName);

  if (body.files && body.files.length !== 0) {
    body.files.forEach((file) => {
      formData.append('files', file);
    });
  }

  if (body.dutyProcurements && body.dutyProcurements.length !== 0) {
    body.dutyProcurements.forEach((data, index) => {
      formData.append(`dutyProcurements[${index}][sequence]`, (index + 1).toString());
      formData.append(`dutyProcurements[${index}][duty]`, data.duty ? data.duty : '');
    });
  }

  if (body.dutyInspectors && body.dutyInspectors.length !== 0) {
    body.dutyInspectors.forEach((data, index) => {
      formData.append(`dutyInspectors[${index}][sequence]`, (index + 1).toString());
      formData.append(`dutyInspectors[${index}][duty]`, data.duty ? data.duty : '');
    });
  }

  if (body.approverSections && body.approverSections.length > 0) {
    let inx = 0;
    let sectionSeq = 0;

    body.approverSections.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((data, index) => {
      if (sectionSeq !== data.sectionSequence) {
        inx = 1;
        sectionSeq = data.sectionSequence;
      }

      formData.append(`ApproverSections[${index}][sectionSequence]`, data.sectionSequence.toString());
      formData.append(`ApproverSections[${index}][sectionName]`, data.sectionName ? data.sectionName : '');
      formData.append(`ApproverSections[${index}][sequence]`, (inx).toString());
      formData.append(`ApproverSections[${index}][userId]`, data.userId);
      formData.append(`ApproverSections[${index}][fullName]`, data.fullName);
      formData.append(`ApproverSections[${index}][position]`, data.position);
      formData.append(`ApproverSections[${index}][department]`, data.department ? data.department : '');
      formData.append(`ApproverSections[${index}][isDisabled]`, data.isDisabled ? 'true' : 'false');
      formData.append(`ApproverSections[${index}][remark]`, data.remark || '');
      formData.append(`ApproverSections[${index}][status]`, data.status ? data.status : AppointmentOrderStatus.DRAFT);
      formData.append(`ApproverSections[${index}][inRefCode]`, data.inRefCode);
      inx++;
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`/appointmentOrder/${body.id}`, formData, config);
};

const submitPendingAppointmentOrderAsync = (id: string) => http.put(`/appointmentOrder/pending/${id}`);

const submitApprovedAppointmentOrderAsync = (id: string) => http.put(`/appointmentOrder/approved/${id}`);

const submitRejectedAppointmentOrderAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };
  return http.put(`/appointmentOrder/rejected/${id}`, body);
};

const getListHistoryAsync = (page: number, size: number, id: string | undefined) => {
  const params = {
    page,
    size,
    id,
  };
  return http.get('/appointmentOrder/history', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getDocumentAsync = (id: string) => http.get(`/appointmentOrder/${id}/document`);

const reCallAppointmentOrderAsync = (id: string) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`/appointmentOrder/recall/${id}`, config);
};

const UploadAppointmentOrderAttachmentAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('Files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`/appointmentOrder/attachment/${id}`, formData, config);
};

const deleteAppointmentOrderDocumentAsync = (id: string, docNames: string[]) => http.delete(`/appointmentOrder/${id}/documents`, { data: { docNames } });

// const deleteAppointmentOrderDocumentAsync = (id: string, docNameList: string[]) => http.delete(`/appointmentOrder/${id}/documents`, { data: { docNames: docNameList } });

export default {
  getAppointmentorderListAsync,
  createAppointCommiteeAsync,
  updateAppointCommiteeAsync,
  approverProcurementPendingAsync,
  getCountStatusAsync,
  getCountSupplyMethodAsync,
  getAppointmentOrderByIdAsync,
  submitPendingAppointmentOrderAsync,
  submitApprovedAppointmentOrderAsync,
  submitRejectedAppointmentOrderAsync,
  deleteAppointmentOrderDocumentAsync,
  getListHistoryAsync,
  getDocumentAsync,
  deleteAppointmentProcurementDocumentAsync,
  reCallAppointmentOrderAsync,
  UploadAppointmentOrderAttachmentAsync,
};
