import { ContractReceiveCollateral } from 'components';
import {
  AcceptorModel,
  ContractReceiveCollateralModel,
  ContractReturnCollateralConsiderModel,
  ContractReturnCollateralRequestModel,
  ContractReturnCollateralResultModel,
  CountStatusModel,
  CreateReturnCollateralModel,
  SearchCollateralListModel,
  updateAssignsModel,
  UpdateReceiveCollateralModel,
  UpdateReturnCollateralModel,
} from 'models/CM/CM06Model';
import http from 'utils/axios';

const qs = require('qs');

const api = '/contractReturnCollateral';
const apiReject = '';
const apiApprove = '';

const getListAsync = (searchData: SearchCollateralListModel) => {
  const params = {
    page: searchData.page,
    pageSize: searchData.size,
    contractNumber: searchData.contractNumber,
    planNumber: searchData.planNumber,
    planName: searchData.planName,
    departmentId: searchData.departmentId,
    entrepreneursName: searchData.entrepreneursName,
    status: searchData.status,
    contractStartDate: searchData.contractStartDate,
    contractEndDates: searchData.contractEndDate,
    isResponsible: searchData.isResponsible,
  };

  return http.get(`${api}/pagination`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountStatusAsync = (searchData: SearchCollateralListModel) => {
  const params = {
    page: searchData.page,
    pageSize: searchData.size,
    contractNumber: searchData.contractNumber,
    planNumber: searchData.planNumber,
    planName: searchData.planName,
    departmentId: searchData.departmentId,
    entrepreneursName: searchData.entrepreneursName,
    status: searchData.status,
    contractStartDate: searchData.contractStartDate,
    contractEndDates: searchData.contractEndDate,
    isResponsible: searchData.isResponsible,
  };
  return http.get<CountStatusModel[]>(`${api}/countStatus`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCollateralByIdAsync = (id: string) => {
  return http.get<ContractReturnCollateralResultModel>(`${api}/${id}`);
};

const getContractReturnCollateralConsiderByIdAsync = (id: string) => {
  return http.get<ContractReturnCollateralConsiderModel>(`${api}/consider/${id}`);
};

const getContractReturnCollateralRequestByIdAsync = (id: string) => {
  return http.get<ContractReturnCollateralRequestModel>(`${api}/${id}`);
};

const geContractReceiveCollateralByIdAsync = (id: string) => {
  return http.get<ContractReceiveCollateralModel>(`${api}/receive-collateral/${id}`);
};

// const geContractConsiderDefaultAssigneeAsync = () => {
//   return http.get<AcceptorModel>(`${api}/default-assignee`);
// };

const geContractConsiderDefaultAssigneeAsync = async (id?: string) => {
  return http.get(`${api}/consider/default-assignee`);
};

const uploadAttachmentReceiveAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/receive/${id}/upload-file`, formData, config);
};

const removeAttachmentReturnCollateralAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/receive-collateral/${id}/attachment/${attachmentId}`);

const removeAttachmentPersonalReturnCollateralAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/receive-collateral/${id}/attachment/${attachmentId}`);

const createReturnCollateralModelAsync = (body: CreateReturnCollateralModel) => {
  return http.post(`${api}`, body);
};

const updateReturnCollateralModelAsync = (id: string, body: UpdateReturnCollateralModel) => {
  return http.put(`${api}/consider/${id}`, body);
};

const returnCollateralWaitingForApprovedAsync = (id: string) => http.put(`${api}/${id}/waiting-for-approved`);

const returnCollateralCommitteesApprovedAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };

  return await http.put(`${api}/Consider/${id}/acceptor-approve`, body);
};

const returnCollateralCommitteesRejectAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };

  return http.put(`${api}/Consider/${id}/acceptor-reject`, body);
};

const returnCollateralJorPorApprovedAsync = async (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('remark', remark || '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${id}/jorPorApprove`, formData, config);
};

const returnCollateralJorPorRejectAsync = async (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('remark', remark || '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${apiReject}/${id}/jorPorRejected`, formData, config);
};

const updateReturnCollateralAssignsAsync = (id: string, body: updateAssignsModel) => {
  return http.put(`${api}/consider/${id}/Assignee`, body);
};

const waitForJorPorApproveReturnCollateralAsync = async (id: string) => await http.put(`${api}/${id}/waitForJorPorApprove`);

const updateSaveReturnCollateralModelAsync = (body: UpdateReturnCollateralModel) => {
  const formData = new FormData();

  formData.append('receivedDate', new Date('').toISOString());
  formData.append('remark', '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${body.id}`, formData, config);
};

const saveReturnCollateralJorPorApprovedAsync = async (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('remark', remark || '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${id}/jorPorApprove`, formData, config);
};

const saveReturnCollateralJorPorRejectAsync = async (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('remark', remark || '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${id}/jorPorApprove`, formData, config);
};

const getAttachmentDocumentAsync = (id?: string, documentId?: string) =>
  http.get(`${api}/document/${id}/download-file/${documentId}`, {
    responseType: 'blob',
  });

const getAttachmentReceiveAsync = (id?: string, documentId?: string) =>
  http.get(`${api}/receive-collateral/${id}/download-file/${documentId}`, {
    responseType: 'blob',
  });

const getContractReturnCollateralRequestApprovalByIdAsync = (id: string) => {
  return http.get(`${api}/request-approval/${id}`);
};

// const createContractReturnCollateralRequestApprovalAsync = (body: ContractReturnCollateralRequestModel) => {
//   const formData = new FormData();
//   formData.append(`ContractReturnCollateralId`, body.contractReturnCollateralId !== null && body.contractReturnCollateralId !== undefined ? body.contractReturnCollateralId : '');
//   formData.append(`ContractReturnCollateralConsiderId`, body.contractReturnCollateralConsiderId);
//   formData.append(`IsFine`, body.isFine ? 'true' : 'false');
//   formData.append(`FineAmount`, body.fineAmount.toString());

//   if (body.collateralChannes && body.collateralChannes.length !== 0) {
//     body.collateralChannes.forEach((data, index) => {
//       formData.append(`CollateralChannel[${index}][Id]`, data.id !== null && data.id !== undefined ? data.id : '');
//       formData.append(`CollateralChannel[${index}][ChannelType]`, data.channelType);
//       formData.append(
//         `CollateralChannel[${index}][ContractAgreementCollateralId]`,
//         data.contractAgreementCollateralId !== null && data.contractAgreementCollateralId !== undefined ? data.contractAgreementCollateralId : '',
//       );
//       formData.append(`CollateralChannel[${index}][Sequence]`, data.sequence.toString());
//       formData.append(`CollateralChannel[${index}][Bank]`, data.bank !== null && data.bank !== undefined ? data.bank : '');
//       formData.append(`CollateralChannel[${index}][BankBranch]`, data.bankBranch !== null && data.bankBranch !== undefined ? data.bankBranch : '');
//       formData.append(`CollateralChannel[${index}][BankAccountNumber]`, data.bankAccountNumber !== null && data.bankAccountNumber !== undefined ? data.bankAccountNumber : '');
//       formData.append(`CollateralChannel[${index}][Amount]`, data.amount != null ? data.amount.toString() : '');
//       formData.append(`CollateralChannel[${index}][RecordDate]`, data.recordDate !== null && data.recordDate !== undefined ? new Date(data.recordDate).toISOString() : '');
//       formData.append(`CollateralChannel[${index}][Active]`, data.active ? 'true' : 'false');
//       formData.append(`CollateralChannel[${index}][Type]`, data.type != null ? data.type : '');
//       formData.append(`CollateralChannel[${index}][Percent]`, data.percent != null ? data.percent.toString() : '');
//       formData.append(`CollateralChannel[${index}][BankAccountName]`, data.bankAccountName != null ? data.bankAccountName : '');
//       formData.append(`CollateralChannel[${index}][BankCollateralAmount]`, data.bankCollateralAmount != null ? data.bankCollateralAmount.toString() : '');
//       formData.append(`CollateralChannel[${index}][BankCollateralStartDate]`, data.bankCollateralStartDate !== null && data.bankCollateralStartDate !== undefined ? new Date(data.bankCollateralStartDate).toISOString() : '');
//       formData.append(`CollateralChannel[${index}][BankCollateralEndDate]`, data.bankCollateralEndDate !== null && data.bankCollateralEndDate !== undefined ? new Date(data.bankCollateralEndDate).toISOString() : '');
//       formData.append(`CollateralChannel[${index}][IsSubmitCollateral]`, data.isSubmitCollateral ? 'true' : 'false');
//       formData.append(`CollateralChannel[${index}][ReferenceNumber]`, data.referenceNumber != null ? data.referenceNumber : '');
//     });
//   }

//   if (body.transferDocumentTypes && body.transferDocumentTypes.length !== 0) {
//     body.transferDocumentTypes.forEach((data, index) => {
//       formData.append(`TransferDocumentTypes[${index}][Id]`, data.id !== null && data.id !== undefined ? data.id : '');
//       formData.append(`TransferDocumentTypes[${index}][Sequence]`, data.sequence.toString());
//       formData.append(`TransferDocumentTypes[${index}][TransferDocumentTypeId]`, data.transferDocumentTypeId);
//       formData.append(`TransferDocumentTypes[${index}][Result]`, data.result.toString());
//     });
//   }

//   const config = {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   };


//   return http.post(`${api}/request-approval/create`, formData, config);
// };

// const getAttachmentReceiveAsync = (id?: string, documentId?: string) =>
//   http.get(`${api}/receive/${id}/download-file/${documentId}`, {
//     responseType: 'blob',
//   });

const createContractReturnCollateralRequestApprovalAsync = (data: ContractReturnCollateralRequestModel) => {

  const body = {
    ContractReturnCollateralId: data.contractReturnCollateralId,
    ContractReturnCollateralConsiderId: data.contractReturnCollateralConsiderId,
    IsFine: data.isFine,
    FineAmount: data.fineAmount,
    CollateralChannes: data.collateralChannes,
    TransferDocumentTypes: data.transferDocumentTypes,
  };

  return http.post(`${api}/request-approval/create`, body);
};

const updateContractReturnCollateralRequestApprovalAsync = (id: string, data: ContractReturnCollateralRequestModel) => {

  const body = {
    ContractReturnCollateralId: data.contractReturnCollateralId,
    ContractReturnCollateralConsiderId: data.contractReturnCollateralConsiderId,
    IsFine: data.isFine,
    FineAmount: data.fineAmount,
    CollateralChannes: data.collateralChannes,
    TransferDocumentTypes: data.transferDocumentTypes,
  };

  return http.put(`${api}/request-approval/${id}/update`, body);
};

// const updateContractReturnCollateralRequestApprovalAsync = (id: string, body: ContractReturnCollateralRequestModel) => {
//   const formData = new FormData();
//   formData.append(`ContractReturnCollateralId`, body.contractReturnCollateralId !== null && body.contractReturnCollateralId !== undefined ? body.contractReturnCollateralId : '');
//   formData.append(`ContractReturnCollateralConsiderId`, body.contractReturnCollateralConsiderId);
//   formData.append(`IsFine`, body.isFine ? 'true' : 'false');
//   formData.append(`FineAmount`, body.fineAmount.toString());

//   if (body.collateralChannes && body.collateralChannes.length !== 0) {
//     body.collateralChannes.forEach((data, index) => {
//       formData.append(`CollateralChannel[${index}][Id]`, data.id !== null && data.id !== undefined ? data.id : '');
//       formData.append(`CollateralChannel[${index}][ChannelType]`, data.channelType);
//       formData.append(
//         `CollateralChannel[${index}][ContractAgreementCollateralId]`,
//         data.contractAgreementCollateralId !== null && data.contractAgreementCollateralId !== undefined ? data.contractAgreementCollateralId : '',
//       );
//       formData.append(`CollateralChannel[${index}][Sequence]`, data.sequence.toString());
//       formData.append(`CollateralChannel[${index}][Bank]`, data.bank !== null && data.bank !== undefined ? data.bank : '');
//       formData.append(`CollateralChannel[${index}][BankBranch]`, data.bankBranch !== null && data.bankBranch !== undefined ? data.bankBranch : '');
//       formData.append(`CollateralChannel[${index}][BankAccountNumber]`, data.bankAccountNumber !== null && data.bankAccountNumber !== undefined ? data.bankAccountNumber : '');
//       formData.append(`CollateralChannel[${index}][Amount]`, data.amount != null ? data.amount.toString() : '');
//       formData.append(`CollateralChannel[${index}][RecordDate]`, data.recordDate !== null && data.recordDate !== undefined ? new Date(data.recordDate).toISOString() : '');
//       formData.append(`CollateralChannel[${index}][Active]`, data.active ? 'true' : 'false');
//       formData.append(`CollateralChannel[${index}][Type]`, data.type != null ? data.type : '');
//       formData.append(`CollateralChannel[${index}][Percent]`, data.percent != null ? data.percent.toString() : '');
//       formData.append(`CollateralChannel[${index}][BankAccountName]`, data.bankAccountName != null ? data.bankAccountName : '');
//       formData.append(`CollateralChannel[${index}][BankCollateralAmount]`, data.bankCollateralAmount != null ? data.bankCollateralAmount.toString() : '');
//       formData.append(`CollateralChannel[${index}][BankCollateralStartDate]`, data.bankCollateralStartDate !== null && data.bankCollateralStartDate !== undefined ? new Date(data.bankCollateralStartDate).toISOString() : '');
//       formData.append(`CollateralChannel[${index}][BankCollateralEndDate]`, data.bankCollateralEndDate !== null && data.bankCollateralEndDate !== undefined ? new Date(data.bankCollateralEndDate).toISOString() : '');
//       formData.append(`CollateralChannel[${index}][IsSubmitCollateral]`, data.isSubmitCollateral ? 'true' : 'false');
//       formData.append(`CollateralChannel[${index}][ReferenceNumber]`, data.referenceNumber != null ? data.referenceNumber : '');
//     });
//   }

//   if (body.transferDocumentTypes && body.transferDocumentTypes.length !== 0) {
//     body.transferDocumentTypes.forEach((data, index) => {
//       formData.append(`TransferDocumentTypes[${index}][Id]`, data.id !== null && data.id !== undefined ? data.id : '');
//       formData.append(`TransferDocumentTypes[${index}][Sequence]`, data.sequence.toString());
//       formData.append(`TransferDocumentTypes[${index}][TransferDocumentTypeId]`, data.transferDocumentTypeId);
//       formData.append(`TransferDocumentTypes[${index}][Result]`, data.result.toString());
//     });
//   }

//   const config = {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   };

//   return http.put(`${api}/request-approval/${id}/update`, formData, config);
// };

const updateContractReturnCollateralRequestAcceptorAsync = (id: string, acceptors: AcceptorModel[], docId?: string) => {
  const formData = new FormData();
  if (docId !== null && docId !== undefined) {
    formData.append('DocumentId', docId);
  }

  if (acceptors && acceptors.length !== 0) {
    acceptors.forEach((data, index) => {
      if (data.id !== null && data.id !== undefined) {
        formData.append(`acceptor[${index}][Id]`, data.id);
      }
      formData.append(`acceptor[${index}][SectionInRefCode]`, data.sectionInRefCode);
      formData.append(`acceptor[${index}][SectionSequence]`, data.sectionSequence !== null && data.sectionSequence !== undefined ? data.sectionSequence.toString() : '');
      formData.append(`acceptor[${index}][UserId]`, data.userId);
      formData.append(`acceptor[${index}][FullName]`, data.fullName);
      formData.append(`acceptor[${index}][DepartmentName]`, data.departmentName !== null && data.departmentName !== undefined ? data.departmentName : '');
      formData.append(`acceptor[${index}][PositionName]`, data.positionName);
      formData.append(`acceptor[${index}][IsAvailable]`, data.isAvailable.toString());
      formData.append(`acceptor[${index}][AvailableRemark]`, data.availableRemark !== null && data.availableRemark !== undefined ? data.availableRemark : '');
      formData.append(`acceptor[${index}][AcceptDate]`, data.acceptDate !== null && data.acceptDate !== undefined ? new Date(data.acceptDate).toISOString() : '');
      formData.append(`acceptor[${index}][AcceptRemark]`, data.acceptRemark !== null && data.acceptRemark !== undefined ? data.acceptRemark : '');
      formData.append(`acceptor[${index}][RejectDate]`, data.rejectDate !== null && data.rejectDate !== undefined ? new Date(data.rejectDate).toISOString() : '');
      formData.append(`acceptor[${index}][RejectRemark]`, data.rejectRemark !== null && data.rejectRemark !== undefined ? data.rejectRemark : '');
      formData.append(`acceptor[${index}][Status]`, data.status);
      formData.append(`acceptor[${index}][Sequence]`, data.sequence.toString());
      formData.append(`acceptor[${index}][Level]`, '1');
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/request-approval/${id}/create-acceptor`, formData, config);
};

const pendingContractReturnCollateralRequestAcceptorAsync = (id: string, docId?: string) => {

  const body = {
    documentId: docId,
  };

  return http.put(`${api}/request-approval/${id}/waitForApprove`, body);
};

const approveContractReturnCollateralRequestAcceptorAsync = (id: string, remark: string) => {
  const formData = new FormData();
  formData.append(`Remark`, remark);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/request-approval/${id}/acceptor-approved`, formData, config);
};

const rejectContractReturnCollateralRequestAcceptorAsync = (id: string, remark: string) => {
  const formData = new FormData();
  formData.append(`Remark`, remark);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/request-approval/${id}/acceptor-rejected`, formData, config);
};

const getDocumentResultAsync = (id?: string) => http.get(`${api}/${id}/document-result`);

const updateDocumentResultAsync = (id: string, documentId: string) => http.put(`${api}/${id}/document-result`, { documentId });

const getDocumentApprovalAsync = (id?: string) => http.get(`${api}/${id}/document-approval`);

const updateDocumentApprovalAsync = (id: string, documentId: string) => http.put(`${api}/${id}/document-approval`, { documentId });

const uploadContractReturnCollateralRequestAttachmentAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/request-approval/${id}/attachment`, formData, config);
};

const uploadContractReturnCollateralRequestAttachmentPersonalAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/request-approval/${id}/attachment-personal`, formData, config);
};

const getContractReturnCollateralRequestAttachmentAsync = (id?: string, documentId?: string) => http.get(`${api}/request-approval/${id}/attachment`);

const getContractReturnCollateralRequestAttachmentPersonalAsync = (id?: string, documentId?: string) => http.get(`${api}/request-approval/${id}/attachment-personal`);

const downloadContractReturnCollateralRequestAttachmentAsync = (id?: string, documentId?: string) =>
  http.get(`${api}/request-approval/${id}/download-file/${documentId}`, {
    responseType: 'blob',
  });

const getAttachmentReceiveCollateralAsync = (id?: string, documentId?: string) =>
  http.get(`${api}/receive-collateral/${id}/download-file/${documentId}`, {
    responseType: 'blob',
  });

const removeAttachmentReceiveAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/receive-collateral/${id}/attachment/${attachmentId}`);

const uploadAttachmentDocumentReceiveAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/receive-collateral/${id}/attachment`, formData, config);
};

const uploadAttachmentDocumentPersonalReceiveAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/receive-collateral/${id}/attachment-personal`, formData, config);
};

const createReceiveCollateralAsync = (body: UpdateReceiveCollateralModel) => {
  return body.id ? http.put(`${api}/receive-collateral/${body.id}/update`, body) : http.post(`${api}/receive-collateral/create`, body);
};

// const createReceiveCollateralAsync = (body: UpdateReceiveCollateralModel) => {
//   const formData = new FormData();

//   formData.append('id', body.id);
//   formData.append('contractReturnCollateralId', body.contractReturnCollateralId);
//   formData.append('contractReturnCollateralConsiderId', body.contractReturnCollateralConsiderId);
//   formData.append('remark', body.remark);

//   if (body.Channels && body.Channels.length > 0) {
//     body.Channels.forEach((data, index) => {
//       formData.append(`channels[${index}][id]`, data.id ? data.id : '');
//       formData.append(`channels[${index}][contractReturnCollateralChannelId]`, '');
//       formData.append(`channels[${index}][receiveCollateralChannelType]`, data.receiveCollateralChannelType);
//       formData.append(`channels[${index}][sequence]`, '1');
//       formData.append(`channels[${index}][bank]`, data.bank ? data.bank : '');
//       formData.append(`channels[${index}][bankBranch]`, data.bankBranch ? data.bankBranch : '');
//       formData.append(`channels[${index}][bankAccountNumber]`, data.bankAccountNumber ? data.bankAccountNumber.toString() : '');
//       formData.append(`channels[${index}][bankAccountName]`, data.bankAccountName);
//       formData.append(`channels[${index}][recordDate]`, new Date(data.recordDate).toISOString());
//       formData.append(`channels[${index}][receiveDate]`, new Date(data.receiveDate).toISOString());

//       if (data.files && data.files.length !== 0) {
//         data.files.forEach((file) => {
//           formData.append(`channels[${index}][attachmentFiles]`, file);
//         });
//       }
//     });
//   }
//   const config = {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   };

//   return body.id ? http.put(`${api}/receive-collateral/${body.id}/update`, formData, config) : http.post(`${api}/receive-collateral/create`, formData, config);
// };

const updateReceiveCollateralComplatAsync = (id: string) => {
  return http.put(`${api}/receive-collateral/${id}/complate`);
};

const getAttachmentPersonalAsync = async (id?: string) => {
  return http.get(`${api}/receive-collateral/${id}/attachment-personal`);
};

const getAttachmentAsync = async (id?: string) => {
  return http.get(`${api}/receive-collateral/${id}/attachment`);
};

const getAttachmentConsiderAsync = async (id?: string) => {
  return http.get(`${api}/consider/${id}/attachment`);
};

const uploadAttachmentConsiderAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/consider/${id}/attachment`, formData, config);
};

const getDownloadAttachmentConsiderAsync = (id?: string, documentId?: string) =>
  http.get(`${api}/consider/${id}/download-file/${documentId}`, {
    responseType: 'blob',
  });

const removeAttachmentconsiderAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/consider/${id}/attachment/${attachmentId}`);

const getAttachmentReturnCollateralAsync = async (id?: string) => {
  return http.get(`${api}/${id}/attachment`);
};

const uploadAttachmentReturnCollateralAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/${id}/attachment`, formData, config);
};

const removeAttachmentFileReturnCollateralAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/${id}/attachment/${attachmentId}`);

const getAttachmentFileReturnCollateralAsync = (id?: string, documentId?: string) =>
  http.get(`${api}/${id}/download-file/${documentId}`, {
    responseType: 'blob',
  });

const removeFileContractReturnCollateralRequestAttachmentAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/request-approval/${id}/attachment/${attachmentId}`);

const uploadReceiveChannelAttachmentDocumentAsync = (id: string, ChannelId: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/receive-collateral/${id}/attachment-channel/${ChannelId}`, formData, config);
};

const getReceiveChannelAttachmentDocumentAsync = (id?: string, documentId?: string) =>
  http.get(`${api}/receive-collateral/${id}/download-channel-file/${documentId}`, {
    responseType: 'blob',
  });

const removeFileReceiveChannelAttachmentAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/receive-collateral/${id}/delete-channel-file/${attachmentId}`);

const getListHistory = async (id?: string, page?: number, pageSize?: number, status?: string) => {
  let apiHistory = 'history';

  if (status === ContractReceiveCollateral.Consider) {
    apiHistory = 'consider/history';
  } else if (status === ContractReceiveCollateral.Request) {

    apiHistory = 'request-approval/history';
  } else if (status === ContractReceiveCollateral.Receive) {

    apiHistory = 'receive-collateral/history';
  }

  const params = {
    id: id ?? null,
    page: page ?? null,
    pageSize: pageSize ?? null,
  };

  return await http.get(`${api}/${apiHistory}`, { params });
};

const sendEmailToEntrepreneurAsync = async (id: string, email: string) => {

  const body = {
    email,
  };
  return await http.put(`${api}/request-approval/${id}/sendEmailToEntrepreneur`, body);
};

const onReCallRequestApprovalAsync = (id: string) => http.put(`${api}/request-approval/${id}/recall`);

const CM06Service = {
  getListAsync,
  getCountStatusAsync,
  getContractReturnCollateralConsiderByIdAsync,
  uploadAttachmentReturnCollateralAsync,
  removeAttachmentReturnCollateralAsync,
  createReturnCollateralModelAsync,
  returnCollateralWaitingForApprovedAsync,
  returnCollateralCommitteesApprovedAsync,
  returnCollateralCommitteesRejectAsync,
  returnCollateralJorPorApprovedAsync,
  returnCollateralJorPorRejectAsync,
  updateReturnCollateralAssignsAsync,
  geContractReceiveCollateralByIdAsync,
  waitForJorPorApproveReturnCollateralAsync,
  updateSaveReturnCollateralModelAsync,
  saveReturnCollateralJorPorApprovedAsync,
  saveReturnCollateralJorPorRejectAsync,
  getCollateralByIdAsync,
  getAttachmentDocumentAsync,
  getAttachmentReceiveAsync,
  removeAttachmentReceiveAsync,
  getContractReturnCollateralRequestByIdAsync,
  updateReturnCollateralModelAsync,
  geContractConsiderDefaultAssigneeAsync,
  getDocumentResultAsync,
  updateDocumentResultAsync,
  getDocumentApprovalAsync,
  updateDocumentApprovalAsync,
  getContractReturnCollateralRequestApprovalByIdAsync,
  createContractReturnCollateralRequestApprovalAsync,
  updateContractReturnCollateralRequestApprovalAsync,
  updateContractReturnCollateralRequestAcceptorAsync,
  pendingContractReturnCollateralRequestAcceptorAsync,
  approveContractReturnCollateralRequestAcceptorAsync,
  rejectContractReturnCollateralRequestAcceptorAsync,
  uploadContractReturnCollateralRequestAttachmentAsync,
  uploadContractReturnCollateralRequestAttachmentPersonalAsync,
  getContractReturnCollateralRequestAttachmentAsync,
  getContractReturnCollateralRequestAttachmentPersonalAsync,
  downloadContractReturnCollateralRequestAttachmentAsync,
  removeFileContractReturnCollateralRequestAttachmentAsync,
  uploadAttachmentDocumentReceiveAsync,
  createReceiveCollateralAsync,
  updateReceiveCollateralComplatAsync,
  uploadAttachmentDocumentPersonalReceiveAsync,
  removeAttachmentPersonalReturnCollateralAsync,
  getAttachmentPersonalAsync,
  getAttachmentAsync,
  getAttachmentConsiderAsync,
  uploadAttachmentConsiderAsync,
  getDownloadAttachmentConsiderAsync,
  removeAttachmentconsiderAsync,
  getAttachmentReturnCollateralAsync,
  removeAttachmentFileReturnCollateralAsync,
  getAttachmentFileReturnCollateralAsync,
  uploadAttachmentReceiveAsync,
  getAttachmentReceiveCollateralAsync,
  uploadReceiveChannelAttachmentDocumentAsync,
  getReceiveChannelAttachmentDocumentAsync,
  removeFileReceiveChannelAttachmentAsync,
  getListHistory,
  sendEmailToEntrepreneurAsync,
  onReCallRequestApprovalAsync,
};

export default CM06Service;
