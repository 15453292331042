import { IFile } from 'models/P/FileUploadModel';
import {
  FaFileAlt,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
} from 'react-icons/fa';
import PP0201Services from 'services/PP/PP0201Services';
import { HttpStatusCode } from 'utils';

interface Props {
    appointmentId: string;
    files: IFile[];
}

function ListFile({ files, appointmentId }: Props) {
  return (
    <div className="upload-file">
      <div className="file-list">
        {
                        Array.from(files).map((f, index) => (
                          <RenderFile key={index} appointmentId={appointmentId} file={f} />))
                    }
      </div>
    </div>
  );
}

function RenderFile(props: { appointmentId: string, file: IFile }) {
  const handleOpenFileAsync = async (appointmentId: string, file: IFile) => {
    if (!file.file) {
      const { data, status } = await PP0201Services.getAppointmentDocumentAsync(appointmentId, file.id);

      if (status !== HttpStatusCode.OK) {
        return;
      }

      const fileUrl = URL.createObjectURL(data);
      window.open(fileUrl, '_blank');

      return;
    }

    const fileUrl = URL.createObjectURL(file.file);
    window.open(fileUrl, '_blank');
  };

  const fileIcon = (name: string) => {
    switch (true) {
      case name.includes('.pdf'):
        return (<FaFilePdf />);
      case name.includes('.doc') || name.includes('.docx'):
        return (<FaFileWord />);
      case name.includes('.xls') || name.includes('.xlsx'):
        return (<FaFileExcel />);
      case name.includes('.csv'):
        return (<FaFileCsv />);
      case name.includes('.png') || name.includes('jpg') || name.includes('jpeg'):
        return (<FaFileImage />);
      default:
        return (<FaFileAlt />);
    }
  };

  return (
    <div className="file">
      <div className="file-icon">
        {fileIcon(props.file.name)}
      </div>
      <div className="detail">
        <a
          onClick={() => handleOpenFileAsync(props.appointmentId, props.file)}
          className="filename"
        >
          {props.file.name}
        </a>
      </div>
    </div>
  );
}

export default ListFile;
