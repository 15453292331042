import {
  AppointmentStatus,
  Input,
  ProcessStatus,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import { ItemModel } from 'models';
import {
  AppointCmtPrApprovalListRequest,
  AppointCmtPrApprovalListResponse,
} from 'models/PR/PR0302Models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import PR0302Services from 'services/PR/PR0302Services';
import {
  GetMonthYearBC,
  THCurrency,
} from 'utils';
import toast from 'utils/toast';

function GetStatus(status: string) {
  switch (status) {
    case ProcessStatus.PENDING:
      return <Status type={StatusType.APPOINTMENT} value={ProcessStatus.PENDING} />;
    case ProcessStatus.APPROVED:
      return <Status type={StatusType.APPOINTMENT} value={ProcessStatus.APPROVED} />;
    case ProcessStatus.REJECTED:
      return <Status type={StatusType.APPOINTMENT} value={ProcessStatus.REJECTED} />;
    default:
      return <Status type={StatusType.APPOINTMENT} value={ProcessStatus.DRAFT} />;
  }
}

const statusOptions: ItemModel[] = [
  { value: AppointmentStatus.DRAFT, label: 'ร่าง' },
  { value: AppointmentStatus.PENDING, label: 'รออนุมัติ' },
  { value: AppointmentStatus.APPROVED, label: 'อนุมัติ' },
  { value: AppointmentStatus.REJECTED, label: 'ส่งกลับแก้ไข' },
];

type Loader = { departmentDDL: ItemModel[] };

export default function PR03() {
  const { departmentDDL } = useLoaderData() as Loader;
  const [program, setProgram] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [criteria, setCriteria] = useState<AppointCmtPrApprovalListRequest>({} as AppointCmtPrApprovalListRequest);
  const [dataTables, setDataTables] = useState<AppointCmtPrApprovalListResponse[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    searchAppointCmtPrApproval();
  }, [page, size]);

  const searchAppointCmtPrApproval = useCallback(async () => {
    const request: AppointCmtPrApprovalListRequest = {
      orderNumber: criteria.orderNumber,
      orderName: criteria.orderName,
      projectName: criteria.projectName,
      departmentId: criteria.departmentId,
      procurementCommitee: criteria.procurementCommitee,
      purchaseInspector: criteria.purchaseInspector,
      responsiblePerson: criteria.responsiblePerson,
      status: criteria.status,
      page,
      size,
    };
    const { data, status } = await PR0302Services.getAppointCmtProcurementApprovalListAsync(request);
    if (status !== 200) {
      toast.error('ไม่พบข้อมูล');
      return;
    }

    setTotalRecords(data.totalCount);
    setDataTables(data.data);
  }, [criteria]);

  const onSearch = async () => {
    searchAppointCmtPrApproval();
  };

  const onClearSearch = async () => {
    setCriteria({} as AppointCmtPrApprovalListRequest);
    searchAppointCmtPrApproval();
  };

  const goToPage = (id: string) => {
    navigate(`/pr/pr0302/detail/${id}`);
  };

  return (
    <div className="m01">
      <h4 className="text-primary m-0">อนุมัติจัดทำคำสั่งแต่งตั้งคณะกรรมาการจัดซื้อจัดจ้างและคณะตรวจรับพัสดุ</h4>
      <hr />
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="เลขที่คำสั่ง"
            placeholder="เลขที่คำสั่ง"
            value={criteria.orderNumber}
            onChange={(val) => setCriteria({ ...criteria, orderNumber: val })}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="ชื่อคำสั่ง"
            placeholder="ชื่อคำสั่ง"
            value={criteria.orderName}
            onChange={(val) => setCriteria({ ...criteria, orderName: val })}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="โครงการ"
            placeholder="โครงการ"
            value={criteria.projectName}
            onChange={(val) => setCriteria({ ...criteria, projectName: val })}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector
            label="ฝ่าย/สำนัก"
            value={criteria.departmentId}
            items={departmentDDL}
            onChange={(val) => setCriteria({ ...criteria, departmentId: val })}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="ชื่อ-นามสกุลคณะกรรมการจัดจ้าง"
            placeholder="ชื่อ-นามสกุลคณะกรรมการจัดจ้าง"
            value={criteria.orderNumber}
            onChange={(val) => setCriteria({ ...criteria, procurementCommitee: val })}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="ชื่อ-นามสกุลคณะกรรมาการตรวจรับพัสดุ"
            placeholder="ชื่อ-นามสกุลคณะกรรมาการตรวจรับพัสดุ"
            value={criteria.orderNumber}
            onChange={(val) => setCriteria({ ...criteria, purchaseInspector: val })}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="ผู้จัดทำ"
            placeholder="ผู้จัดทำ"
            value={criteria.orderNumber}
            onChange={(val) => setCriteria({ ...criteria, responsiblePerson: val })}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector
            label="สถานะ"
            value={criteria.status}
            items={statusOptions}
            onChange={(val) => setCriteria({ ...criteria, status: val })}
          />
        </Col>

        <div className="d-flex gap-2">
          <Button form="formPL01" onClick={onSearch} variant="primary" className="d-flex align-items-center gap-2">
            <FaSearch />ค้นหา
          </Button>
          <Button variant="outline-primary" className="d-flex align-items-center gap-2" onClick={onClearSearch}>
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
      <Table className="mt-4">
        <thead>
          <tr className="text-center">
            <th>เลขที่แผน</th>
            <th>เลขที่โครงการ</th>
            <th>ฝ่าย/สำนัก</th>
            <th>ชื่อแผน</th>
            <th>งบประมาณ (บาท)</th>
            <th>วิธีจัดหา</th>
            <th>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
            <th>วันที่<br />ประกาศแผน</th>
            <th>สถานะโครงการ</th>
            <th>สถานะ</th>
            <th>ดูรายละเอียด</th>
          </tr>
        </thead>
        <tbody>
          {dataTables.map((data, index) => (
            <tr key={index}>
              <td className="text-center">{data.planNumber}</td>
              <td className="text-center">
                <Button variant="link" onClick={() => goToPage(data.id)}>{data.projectNumber}</Button>
              </td>
              <td className="text-center">{`${data.departmentShort} : ${data.department}`}</td>
              <td>{data.planName}</td>
              <td style={{ textAlign: 'right' }}>{THCurrency(data.planBudget)}</td>
              <td className="text-center">{data.supplyMethodTypeValue} : {data.supplyMethodValue}</td>
              <td className="text-center">{GetMonthYearBC(data.expectingProcurementAt)}</td>
              <td className="text-center">{GetMonthYearBC(data.planAnnouncementDate)}</td>
              <td className="text-center">{data.projectStatus}</td>
              <td className="text-center">{GetStatus(data.status)}</td>
              <td className="text-center">
                <Button variant="primary" onClick={() => goToPage(data.id)}>ดูรายละเอียด</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
