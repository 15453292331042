import { Modal } from 'components';
import { InputTextArea } from 'components/Controls';
import Title from 'components/Controls/Title';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { FaUndo } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';
import { AcceptorStatus } from 'utils/constants/PlanEnum';

export function PublicProcurementRejectModal(props: {
  show: boolean,
  onHide?: () => void;
  onReject: (status: string) => Promise<void>;
  remark: string | undefined;
  setRejectRemark: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  return (
    <Modal show={props.show} size="xl">
      <ModalBT.Header onHide={props.onHide} closeButton>
        <Title
          text="ส่งกลับแก้ไข"
          className="fs-5 text-primary"
        />
      </ModalBT.Header>
      <ModalBT.Body className="p-0 ps-4">
        <InputTextArea
          label="หมายเหตุ"
          rule={{ required: true }}
          value={props.remark}
          onChange={(val) => props.setRejectRemark(val)}
        />
      </ModalBT.Body>
      <ModalBT.Footer className="justify-content-center">
        <Button
          variant="outline-primary"
          onClick={props.onHide}
          className="me-2 px-3"
        >
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          className="me-2 px-3"
          onClick={() => props.onReject(AcceptorStatus.REJECT)}
        >
          <FaUndo className="mx-2 pb-1 fs-4" />
          ส่งกลับแก้ไข
        </Button>
      </ModalBT.Footer>
    </Modal>
  );
}
