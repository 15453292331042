import {
  Input,
  Modal,
  Selector,
  Table,
} from 'components';
import {
  ItemModel,
  UserListSelectionParams,
  UserListSelectionResponse,
} from 'models';
import { CommitteeUser } from 'models/PP';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { shareValue } from 'services';
import { HttpStatusCode } from 'utils';
import toast from 'utils/toast';

interface ModalUserProps {
  show: boolean;
  onHide: () => void;
  userSelected?: CommitteeUser[];
  onSelect: (data: UserListSelectionResponse) => void;
}

export function ModalSelectUserWinner(props: ModalUserProps) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [criteria, setCriteria] = useState<UserListSelectionParams>({} as UserListSelectionParams);
  const [userList, setUserList] = useState<UserListSelectionResponse[]>([]);
  const [positionItems, setPositionItems] = useState<ItemModel[]>([]);
  const [departmentItems, setDepartmentItems] = useState<ItemModel[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const [positionList, departmentList] = await Promise.all([
      shareValue.GetDDL('Position'),
      shareValue.GetDDL('Department'),
    ]);

    setPositionItems(positionList);
    setDepartmentItems(departmentList);
  };

  useEffect(() => {
    if (props.show) {
      getUserListsAsync();
    }
  }, [props.show, page, size]);

  const getUserListsAsync = async (criteriaData?: UserListSelectionParams) => {
    const { data, status } = await shareValue.getUserListSelectionAsync(page, size, criteriaData || criteria);

    if (status === HttpStatusCode.OK) {
      const usersData: UserListSelectionResponse[] = data.data;

      setUserList(usersData);
      setTotalRecords(data.totalRecords);
    }
  };

  const onSelect = (user: UserListSelectionResponse) => {
    let filterUser: CommitteeUser[] = [];

    if (props.userSelected) {
      filterUser = props.userSelected.filter(data => data.userId === user.id);
    }

    if (filterUser.length > 0) {
      return toast.warn('ข้อมูลซ้ำ');
    }

    props.onSelect(user);
    onHide();
  };

  const onHide = () => {
    props.onHide();
  };

  const onChageCriteria = (value: string | number, prop: string) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onSearchUser = () => {
    getUserListsAsync();
  };

  const onClearCriteria = () => {
    setCriteria({} as UserListSelectionParams);

    getUserListsAsync({} as UserListSelectionParams);
  };

  return (
    <Modal
      show={props.show}
      size='xl'
      onHide={onHide}>
      <ModalBT.Header closeButton>
        <ModalBT.Title>รายชื่อ</ModalBT.Title>
      </ModalBT.Header>
      <ModalBT.Body className='p-0 ps-4'>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='ชื่อ-นามสกุล'
              value={criteria.fullName}
              onChange={(value) => onChageCriteria(value, 'fullName')} />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <Selector items={departmentItems}
              label='ฝ่าย/สำนัก'
              value={criteria.departmentId}
              onChange={(value) => onChageCriteria(value, 'departmentId')} />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <Selector items={positionItems}
              label='ตำแหน่ง'
              value={criteria.positionId}
              onChange={(value) => onChageCriteria(value, 'positionId')} />
          </Col>
        </Row>
        <div className='d-flex gap-2'>
          <Button variant='primary'
            className='px-3'
            onClick={onSearchUser}>
            <FaSearch className='me-2' />ค้นหา
          </Button>
          <Button
            className='px-4'
            variant='light'
            onClick={onClearCriteria}>
            ล้าง
          </Button>
        </div>
        <Table className='my-3'
          total={totalRecords}
          onChange={(size, page) => (setPage(page), setSize(size))}>
          <thead>
          <tr>
            <th style={{ minWidth: 20 }}>ลำดับ</th>
            <th style={{ minWidth: 50 }}>ชื่อ-นามสกุล</th>
            <th style={{ minWidth: 50 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
            <th style={{ minWidth: 25 }} />
          </tr>
          </thead>
          <tbody>
          {userList.map((data, index) => (
            <tr key={index}>
              <td className='text-center'>{index + 1}</td>
              <td>{data.fullName}</td>
              <td>{data.departmentName}</td>
              <td>{data.positionName} </td>
              <td>
                <Button variant='primary'
                  className='px-3'
                  onClick={() => onSelect(data)}>
                  เลือก
                </Button>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
