import {
  Currency,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row as RowBT,
} from 'react-bootstrap';
import {
  FaEraser,
  FaPlus,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { plan } from 'services';
import {
  HttpStatusCode,
  monthYear,
  thaiFormatDateWithSlash,
} from 'utils';

interface CriteriaForm {
  departmentId: string;
  planName: string;
  planBudget: number | undefined;
  supplyMethod: string;
  supplyMethodName: string;
  organizer: string;
  status: string;
}

type TableData = {
  torId: string,
  planId: string,
  medianPriceId: string,
  torNumber: string,
  planNumber: string,
  department: string,
  planName: string,
  planBudget: number,
  supplyMethod: string,
  expectingProcurementAt: Date,
  planAnnouncementDate: Date,
  status: string,
  planStatus: string,
};

type Loader = {
  plans: {
    data: TableData[],
    totalRecords: number,
  },
  departments: { label: string, value: string }[],
  supplyMethods: { label: string, value: string }[],
  supplyMethodTypes: { label: string, value: string }[],
};

export default function PP0208() {
  const loader = useLoaderData() as Loader;
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<TableData[]>([]);
  const [criteria, setCriteria] = useState<CriteriaForm>({} as CriteriaForm);
  const [activePlanId, setActivePlanId] = useState<string>();
  const [approveShow, setApproveShow] = useState(false);

  useEffect(() => {
    setData(loader.plans.data);
    setTotal(loader.plans.totalRecords);
  }, [loader]);

  const searchAsync = async (criteria: CriteriaForm) => await getPlanListAsync(criteria);

  const getPlanListAsync = async (criteria: CriteriaForm) => {
    const res = await plan.getPlanListAsync(
      size,
      page,
      criteria.departmentId,
      criteria.planName,
      criteria.planBudget,
      criteria.supplyMethod,
      criteria.supplyMethodName,
      criteria.organizer,
      criteria.status,
    );

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setTotal(res.data.totalRecords);
    }
  };

  return (
    <div className="PP0204">
      <PP0204Header />
      <Criteria
        onSubmitSearch={searchAsync}
        setCriteria={setCriteria}
        criteria={criteria}
      />
      <DataTable
        setPage={setPage}
        setSize={setSize}
        total={total}
        data={data}
      />
    </div>
  );
}

function PP0204Header() {
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">ราคากลาง (ราคาอ้างอิง)</h4>
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={() => navigate('detail')}
        >
          <FaPlus />ราคากลาง
        </Button>
      </div>
      <hr />
    </>
  );
}

function Criteria({ onSubmitSearch, setCriteria, criteria }: {
  onSubmitSearch: (criteria: CriteriaForm) => void;
  setCriteria: Dispatch<SetStateAction<CriteriaForm>>;
  criteria: CriteriaForm;
}) {
  const loader = useLoaderData() as Loader;

  const clear = () => setCriteria({} as CriteriaForm);

  return (
    <RowBT className="criteria">
      <Col sm={6} md={4} lg={4} xl={3}>
        <Selector
          label="ฝ่าย/สำนัก"
          items={loader.departments}
          onChange={(val) => setCriteria({ ...criteria, departmentId: val })}
          value={criteria.departmentId}
          placeholder="ฝ่าย/สำนัก"
        />
      </Col>
      <Col sm={6} md={4} lg={4} xl={3}>
        <Input
          label="ชื่อโครงการ"
          onChange={(val) => setCriteria({ ...criteria, planName: val })}
          value={criteria.planName}
          placeholder="ชื่อโครงการ"
        />
      </Col>
      <Col sm={6} md={4} lg={4} xl={3}>
        <Currency
          label="งบประมาณโครงการ"
          onChange={(val) => setCriteria({ ...criteria, planBudget: val })}
          value={criteria.planBudget}
          placeholder="งบประมาณโครงการ"
        />
      </Col>
      <Col sm={6} md={4} lg={4} xl={3}>
        <Selector
          label="วิธีจัดหา"
          items={loader.supplyMethodTypes}
          onChange={(val) => setCriteria({ ...criteria, supplyMethod: val })}
          value={criteria.supplyMethod}
          placeholder="วิธีจัดหา"
        />
      </Col>
      <Col sm={6} md={4} lg={4} xl={3}>
        <Selector
          className="no-label"
          items={loader.supplyMethods}
          onChange={(val) => setCriteria({ ...criteria, supplyMethodName: val })}
          value={criteria.supplyMethodName}
        />
      </Col>
      <Col sm={6} md={4} lg={4} xl={3}>
        <Input
          label="ผู้จัดทำ"
          onChange={(val) => setCriteria({ ...criteria, organizer: val })}
          value={criteria.organizer}
          placeholder="ผู้จัดทำ"
        />
      </Col>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-2">
          <Button
            type="submit"
            variant="primary"
            className="d-flex align-items-center gap-2"
            onClick={() => onSubmitSearch(criteria)}
          >
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant="outline-primary"
            className="d-flex align-items-center gap-2"
            onClick={clear}
          >
            <FaEraser />ล้าง
          </Button>
        </div>
      </div>
    </RowBT>
  );
}

function DataTable({ setPage, setSize, total, data }: {
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  total: number;
  data: TableData[];
}) {
  const navigate = useNavigate();

  return (
    <Table
      className="mt-4"
      total={total}
      onChange={(size, page) => (setSize(size), setPage(page))}
    >
      <thead>
        <tr>
          <th>เลขที่แผน</th>
          <th>ฝ่าย/สำนัก</th>
          <th>ชื่อแผน</th>
          <th>งบประมาณ (บาท)</th>
          <th>วิธีจัดหา</th>
          <th>ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง</th>
          <th>วันที่ประกาศแผน</th>
          <th>สถานะโครงการ</th>
          <th>สถานะ</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((d) => (
          <tr key={d.planId}>
            <td className="text-center">
              <Button
                variant="link"
                onClick={() => navigate(`detail/${d.planId}/${d.torId}`)}
                className="d-flex align-items-center gap-2"
              >
                {d.planNumber}
              </Button>
            </td>
            <td>{d.department}</td>
            <td>{d.planName}</td>
            <td className="text-end">{d.planBudget}</td>
            <td className="text-center">{d.supplyMethod}</td>
            <td className="text-center">
              {monthYear(d.expectingProcurementAt)}
            </td>
            <td className="text-center">
              {thaiFormatDateWithSlash(d.planAnnouncementDate)}
            </td>
            <td>
              <Status type={StatusType.PROJECT} value={d.planStatus} />
            </td>
            <td>
              <Status type={StatusType.PROCESS} value={d.status} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
