import { Currency, Input, Table } from 'components';
import { MedianPriceSourceModel } from 'models/PP/MedianPriceModel';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

interface IMedianPriceSourcesProps {
  medianPriceId: string;
  medianPriceSources: MedianPriceSourceModel[];
  onMedianPriceSources: (value: MedianPriceSourceModel[]) => void;
  isDisabled?: boolean;
}

export function MedianPriceSources(props: IMedianPriceSourcesProps) {
  const [medianPriceSources, setMedianPriceSources] = useState(props.medianPriceSources);

  useEffect(() => {
    props.onMedianPriceSources(medianPriceSources);
  }, [medianPriceSources]);

  useEffect(() => {
    if (props.medianPriceSources) {
      setMedianPriceSources(props.medianPriceSources);
    }
  }, [props.medianPriceSources]);

  const addMedianPriceSource = useCallback(async () => {
    const newData: MedianPriceSourceModel = {
      id: undefined,
      medianPriceId: props.medianPriceId,
      description: '',
      sequence: medianPriceSources.length + 1,
      amount: 0,
    };

    setMedianPriceSources((medianPriceSources) => [...medianPriceSources, newData]);
  }, [medianPriceSources]);

  const handlerOnChangeDesc = (value: string, index: number) => {
    const data = [...medianPriceSources];
    data[index].description = value;
    setMedianPriceSources(data);
  };

  const handlerOnChangeAmount = (value: number, index: number) => {
    const data = [...medianPriceSources];
    data[index].amount = value;
    setMedianPriceSources(data);
  };

  const removeMedianPriceSource = (i: number) => {
    const temp: MedianPriceSourceModel[] = [...medianPriceSources];
    temp.splice(i, 1);
    setMedianPriceSources(temp);
  };

  return (
    <div>
      <div className='d-flex justify-content-between my-3'>
        <p>แหล่งที่มาราคากลาง</p>
        <Button
          variant='outline-primary'
          onClick={() => addMedianPriceSource()}
          disabled={props.isDisabled}
        >
          <FaPlus className='me-2' />เพิ่มข้อมูล
        </Button>
      </div>
      <Row className='align-items-center'>
        <Col sm={12}>
          <Table total={1}
            hidePagination>
            <thead>
              <tr>
                <th style={{ width: 10 }}>ลำดับ</th>
                <th style={{ minWidth: 250 }}>รายละเอียด</th>
                <th style={{ width: 300 }}>ราคาอ้างอิง</th>
                <th style={{ width: 20 }} />
              </tr>
            </thead>
            <tbody>
              {medianPriceSources?.sort((a, b) => a.sequence - b.sequence)?.map((data, index) => (
                <tr key={index}>
                  <td className='text-center'>{index + 1}</td>
                  <td className='text-start'>
                    <Form.Group className='mb-3'>
                      <InputGroup>
                        <Input
                          rule={{ required: true }}
                          type='text'
                          value={data.description}
                          disabled={props.isDisabled}
                          onChange={(val) => handlerOnChangeDesc(val, index)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </td>
                  <td>
                    <Currency
                      value={data.amount}
                      onChange={(val) => handlerOnChangeAmount(Number(val), index)}
                      disabled={props.isDisabled}
                    />
                  </td>
                  <td>
                    {
                      !props.isDisabled && (
                        <div className='d-flex justify-content-end'>
                          <Button
                            variant='danger'
                            className='d-flex align-items-center gap-2'
                            onClick={() => removeMedianPriceSource(index)}
                            disabled={props.isDisabled}
                          >
                            <FaTrashAlt />
                          </Button>
                        </div>
                      )
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
