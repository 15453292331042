import {
  AppointmentOrderStatus,
  AppointmentStatus,
  Card,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Modal,
  Selector,
  Table,
  ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import { ProcurementAppointmentOrderStatus } from 'components/ProcurementAppointmentOrderStatus';
import Cookie from 'cookie-universal';
import {
  IFile,
  ItemModel,
} from 'models';
import {
  CreateAppointCommiteeModel,
  documentsModel,
  dutyTermsModel,
  UpdateAppointCommiteeModel,
} from 'models/procurementAppointmentOrderModel';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  InputGroup,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { BsArrowDownCircle } from 'react-icons/bs';
import {
  FaHistory,
  FaPlus,
  FaSave,
  FaTrashAlt,
  FaUndo,
  FaChevronCircleDown,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router';
import { shareValue } from 'services';
import procurementAppointmentOrderServices from 'services/procurementAppointmentOrderServices';
import {
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { HistoryModal } from '../HistoryModal';
import UploadFile from '../UploadFile';
import { Context } from './index';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const PeriodType = [
  { label: 'วัน', value: 'วัน' },
  { label: 'เดือน', value: 'เดือน' },
  { label: 'ปี', value: 'ปี' },
];

type Loader = {
  supplyMethodDDL: ItemModel[],
  supplyMethodTypeDDL: ItemModel[],
  departmentDDL: ItemModel[],
};

function Step1({ onClickNext, onClickBack }: Props) {
  const { data, setData } = useContext(Context);
  const { supplyMethodDDL, supplyMethodTypeDDL, departmentDDL } = useLoaderData() as Loader;
  const [, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [openCollapse3, setOpenCollapse3] = useState(true);
  const [openCollapse4, setOpenCollapse4] = useState(true);
  const [openCollapse5, setOpenCollapse5] = useState(true);
  const [dutyProcurements, setDutyProcurements] = useState<dutyTermsModel[]>([]);
  const [dutyInspectors, setDutyInspectors] = useState<dutyTermsModel[]>([]);
  const navigate = useNavigate();
  const { userId } = useAppContext();
  const [isDisabled, setisDisabled] = useState(false);
  const [, setBudgetYearItems] = useState<{ value: string; label: string }[]>([]);
  const [planBudget, setPlanBudget] = useState(0);
  const [deliveryQuantity] = useState(0);
  const [deliveryUnit, setDeliveryUnit] = useState('');
  const [subject, setSubject] = useState('');
  const [sourceAndReasons, setSourceAndReasons] = useState('');
  const [supplyMethod, setSupplyMethod] = useState('');
  const [supplyMethodType, setSupplyMethodType] = useState('');
  const [supplyMethodSpecialType, setSupplyMethodSpecialType] = useState('');
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const cookies = Cookie();
  const userfullName = cookies.get('fullName') as string;
  const [planName, setPlanName] = useState('');
  const [showReCall, setShowReCall] = useState(false);
  const [checkReCall, setCheckReCall] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (data) {
      if (data.status === AppointmentOrderStatus.None || data.status === AppointmentStatus.DRAFT || data.status === AppointmentStatus.REJECTED) {
        setisDisabled(false);
      } else {
        setisDisabled(true);
      }

      if (data.dutyProcurements) {
        setDutyProcurements(data.dutyProcurements);
      }

      if (data.dutyInspectors) {
        setDutyInspectors(data.dutyInspectors);
      }

      if (data.planBudget) {
        setPlanBudget(data.planBudget);
      }

      if (data.supplyMethod) {
        setSupplyMethod(data.supplyMethod);
      }

      if (data.supplyMethodType) {
        setSupplyMethodType(data.supplyMethodType);
      }

      if (data.supplyMethodSpecialType) {
        setSupplyMethodSpecialType(data.supplyMethodSpecialType);
      }

      if (data.subject) {
        setSubject(data.subject);
      }

      if (data.planName) {
        setPlanName(data.planName);
      }

      if (data.sourceAndReasons) {
        setSourceAndReasons(data.sourceAndReasons);
      }

      if (data.documents) {
        handleSetFile(data.documents);
      }

      if (data.approverSections) {
        const queueReCall = data.approverSections.some((a) => a.status === AppointmentOrderStatus.APPROVED);
        if (!queueReCall) {
          setCheckReCall(true);
        } else {
          setCheckReCall(false);
        }
      }
    }
  }, [data, userId]);

  useEffect(() => {
    getSupplyMethodSpecialType(supplyMethod);
  }, [supplyMethod]);

  useEffect(() => {
    const date = new Date();
    let year = (date.getUTCFullYear() + 543) - 10;
    const items = Array.from({ length: 20 }).map(() => {
      year += 1;

      return {
        label: year.toString(),
        value: year.toString(),
      };
    });
    setBudgetYearItems(items);

    // if (data.status === AppointmentOrderStatus.None) {
    //   for (let i = 0; i < 3; i++) {
    //     if (i === 0) {
    //       handlerAddDutyProcurements(DefaultDuty.dutyProcurements1);
    //       handlerAddDutyInspectors(DefaultDuty.dutyInspectors1)
    //     } else if (i === 1) {
    //       handlerAddDutyProcurements(DefaultDuty.dutyProcurements2);
    //       handlerAddDutyInspectors(DefaultDuty.dutyInspectors2)
    //     } else if (i === 2) {
    //       handlerAddDutyProcurements(DefaultDuty.dutyProcurements3);
    //     }
    //   }

    // }
  }, [data.status]);

  const handlerAddDutyProcurements = useCallback(async (duty: string) => {
    const newData: dutyTermsModel = {
      id: undefined,
      duty,
    };

    setDutyProcurements((dutyProcurements) => [...dutyProcurements, newData]);
  }, [dutyProcurements]);

  const handlerOnChangeDutyProcurements = (value: string, index: number) => {
    const data = [...dutyProcurements];
    data[index].duty = value;
    setDutyProcurements(data);
  };

  const handlerRemoveDutyProcurements = (i: number) => {
    const temp: dutyTermsModel[] = [...dutyProcurements];
    temp.splice(i, 1);
    setDutyProcurements(temp);
  };

  const handlerAddDutyInspectors = useCallback(async (duty: string) => {
    const newData: dutyTermsModel = {
      id: undefined,
      duty,
    };

    setDutyInspectors((dutyInspectors) => [...dutyInspectors, newData]);
  }, [dutyInspectors]);

  const handlerOnChangeDutyInspectors = (value: string, index: number) => {
    const data = [...dutyInspectors];
    data[index].duty = value;
    setDutyInspectors(data);
  };

  const handlerRemoveDutyInspectors = (i: number) => {
    const temp: dutyTermsModel[] = [...dutyInspectors];
    temp.splice(i, 1);
    setDutyInspectors(temp);
  };

  const getSupplyMethodSpecialType = useCallback(async (supplyMethodId: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(supplyMethodId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setSupplyMethodSpecialDDL(data);
  }, [supplyMethod]);

  const onChangeSupplyMethod = (value: string) => {
    getSupplyMethodSpecialType(value);
    setSupplyMethod(value.toString());
  };

  const onNext = () => {
    submitForm();

    if ((!planBudget || planBudget === 0)
      || (!supplyMethod || supplyMethod === '')
      || (!supplyMethodType || supplyMethodType === '')
      || (!supplyMethodSpecialType || supplyMethodSpecialType === '')
      || !sourceAndReasons
      || !planName) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
      return;
    }

    if (!dutyProcurements || dutyProcurements.length === 0) {
      toast.warn('กรุณาระบุอำนาจหน้าที่คณะกรรมการจัดจ้าง');
      return;
    }

    if (dutyProcurements.filter((x) => x.duty === null || x.duty === '' || x.duty === undefined).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดอำนาจหน้าที่คณะกรรมการจัดจ้าง');
      return;
    }

    if (!dutyInspectors || dutyInspectors.length === 0) {
      toast.warn('กรุณาระบุอำนาจหน้าที่กรรมการตรวจรับพัสดุ');
      return;
    }

    if (dutyInspectors.filter((x) => x.duty === null || x.duty === '' || x.duty === undefined).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดอำนาจหน้าที่กรรมการตรวจรับพัสดุ');
      return;
    }

    data.planName = planName;
    data.supplyMethodType = supplyMethodType;
    data.supplyMethod = supplyMethod;
    data.supplyMethodSpecialType = supplyMethodSpecialType;
    data.planBudget = planBudget;
    data.subject = subject;
    data.sourceAndReasons = sourceAndReasons;
    data.deliveryQuantity = deliveryQuantity;
    data.deliveryUnit = deliveryUnit;
    data.dutyProcurements = dutyProcurements;
    data.dutyInspectors = dutyInspectors;

    onClickNext();
  };

  const onSubmitAsync = async () => {
    submitForm();
    if ((!planBudget || planBudget === 0)
      || (!supplyMethod || supplyMethod === '')
      || (!supplyMethodType || supplyMethodType === '')
      || (!supplyMethodSpecialType || supplyMethodSpecialType === '')
      || !sourceAndReasons
      || !planName) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
      return;
    }

    const docUpload: File[] = [];
    if (files) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });
      data.docNameforDelete = delFiles;
      data.files = [...docUpload];
    }

    if (!dutyProcurements || dutyProcurements.length === 0) {
      toast.warn('กรุณาระบุอำนาจหน้าที่คณะกรรมการจัดจ้าง');
      return;
    }

    if (dutyProcurements.filter((x) => x.duty === null || x.duty === '' || x.duty === undefined).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดอำนาจหน้าที่คณะกรรมการจัดจ้าง');
      return;
    }

    if (!dutyInspectors || dutyInspectors.length === 0) {
      toast.warn('กรุณาระบุอำนาจหน้าที่กรรมการตรวจรับพัสดุ');
      return;
    }

    if (dutyInspectors.filter((x) => x.duty === null || x.duty === '' || x.duty === undefined).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดอำนาจหน้าที่กรรมการตรวจรับพัสดุ');
      return;
    }

    if (data && data.id) {
      const httpStatus = HttpStatusCode.OK;
      const updateDataModel: UpdateAppointCommiteeModel = {
        id: data.id,
        planId: data.planId,
        planNumber: data.planNumber,
        planName,
        torId: data.torId,
        jorPor04Number: data.jorPor04Number,
        jorPor05Id: data.jorPor05Id,
        memorandumDate: data.memorandumDate,
        departmentId: data.departmentId,
        supplyMethodType,
        supplyMethod,
        supplyMethodSpecialType,
        budgetYear: data.budgetYear,
        planBudget,
        expectingProcurementAt: data.expectingProcurementAt,
        subject,
        sourceAndReasons,
        status: data.status,
        deliveryQuantity,
        deliveryUnit,
        committeeProcurements: data.committeeProcurements,
        dutyProcurements,
        committeeInspectors: data.committeeInspectors,
        dutyInspectors,
        approverSections: data.approverSections,
        files: docUpload,
        updatedByUserFullName: userfullName,
        docNameforDelete: data.docNameforDelete,
      };

      if (httpStatus === HttpStatusCode.OK) {
        const res = await procurementAppointmentOrderServices.updateAppointCommiteeAsync(updateDataModel);
        if (data.docNameforDelete === undefined) data.docNameforDelete = [];

        if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
          setShow(false);
          toast.success('แก้ไขข้อมูลสำเร็จ');
          navigate(`/procurement-appointment-order/detail/${data.jorPor05Id}/id/${data.id}`);
        } else {
          toast.error(res.statusText);
        }
      }
    } else {
      const createDataModel: CreateAppointCommiteeModel = {
        planId: data.planId,
        planNumber: data.planNumber,
        planName,
        torId: data.torId,
        jorPor04Number: data.jorPor04Number,
        jorPor05Id: data.jorPor05Id,
        memorandumDate: data.memorandumDate,
        departmentId: data.departmentId,
        supplyMethodType,
        supplyMethod,
        supplyMethodSpecialType,
        budgetYear: data.budgetYear,
        planBudget,
        expectingProcurementAt: data.expectingProcurementAt,
        subject,
        sourceAndReasons,
        status: data.status,
        deliveryQuantity,
        deliveryUnit,
        createdByUserFullName: userfullName,
        committeeProcurements: data.committeeProcurements,
        dutyProcurements,
        committeeInspectors: data.committeeInspectors,
        dutyInspectors,
        approverSections: data.approverSections,
        files: docUpload,
      };

      const res = await procurementAppointmentOrderServices.createAppointCommiteeAsync(createDataModel);
      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('บันทึกข้อมูลสำเร็จ');
        const newId: string = res.data.id;
        const response = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(data.jorPor05Id, newId);
        if (response.status === HttpStatusCode.OK) {
          if (data.files) {
            data.files = [];
          }
          setData(response.data);
        }
        navigate(`/procurement-appointment-order/detail/${data.jorPor05Id}/id/${newId}`);
        onClickNext();
      } else {
        toast.error(res.statusText);
      }
    }
  };

  const handlerOnChangeFineRatePeriodType = (event: HTMLSelectElement) => {
    data.deliveryUnit = event.value;
    setDeliveryUnit(data.deliveryUnit);
  };

  const handleSetFile = (documents: documentsModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await procurementAppointmentOrderServices.UploadAppointmentOrderAttachmentAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getDataAppointmentOrderByIdAsync();
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName) {
      setDelFiles((delFiles) => [...delFiles, docName]);
    }

    const delFile = [];
    delFile.push(docName);
    if (data && data.id) {
      const res = await procurementAppointmentOrderServices.deleteAppointmentOrderDocumentAsync(
        data.id,
        delFile,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('ลบไฟล์สำเร็จ');
        getDataAppointmentOrderByIdAsync();
      }
    }
  }, [files, data.id]);

  const getDataAppointmentOrderByIdAsync = async () => {
    const response = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(data.jorPor05Id, data.id);
    if (response.status === HttpStatusCode.OK) {
      if (data.files) {
        data.files = [];
      }
      setData(response.data);
    }
  };

  const reCallAppointmentAsync = async () => {
    if (data && data.id) {
      const response = await procurementAppointmentOrderServices.reCallAppointmentOrderAsync(data.id);

      if (response.status === HttpStatusCode.OK) {
        toast.success('เรียกคืนแก้ไขสำเร็จ');
        getDataAppointmentOrderByIdAsync();
        navigate(`${location.pathname}`);
      }
    }

    setShowReCall(false);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <Title text='ข้อมูลโครงการ' className='fs-5 text-primary' />
          <div className='d-flex gap-2'>
            <ProcurementAppointmentOrderStatus
              value={data.status}
            />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <Input label='เลขที่เอกสารรายงานขอซื้อขอจ้่าง (จพ.005)'
              value={data.jorPor05Number}
              disabled />
          </Col>
          <Col sm={12} md={4} xl={3}>
            <Input label='เลขที่เอกสารการแจ้งข้อมูลเบื้องต้น (จพ.004)' value={data.jorPor04Number} disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4} xl={3}>
            <Input label='เลขที่เอกสารร่างขอบเขตงของงาน(TOR)' value={data.torNumber} disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <Input label='อ้างอิงแผนการจัดซื้อจัดจ้าง' value={data.planNumber} disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <Selector
              label='ฝ่าย/สำนัก'
              items={departmentDDL}
              value={data.departmentId}
              placeholder='กรุณาเลือก'
              disabled
            />
          </Col>
          <Col sm={12} md={4}>
            <Input
              label='ปีงบประมาณ'
              placeholder='ปีงบประมาณ'
              value={data.budgetYear}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} xl={3}>
            <Selector
              label='วิธีการจัดหา'
              placeholder='กรุณาเลือก'
              items={supplyMethodDDL}
              rule={{ required: true }}
              value={supplyMethod}
              onChange={(val) => onChangeSupplyMethod(val)}
              disabled={isDisabled}
            />
          </Col>
          <Col sm={12} md={6} xl={3}>
            <Selector
              className='no-label'
              placeholder='กรุณาเลือก'
              items={supplyMethodTypeDDL}
              rule={{ required: true }}
              value={supplyMethodType}
              onChange={(val) => setSupplyMethodType(val)}
              disabled={isDisabled}
            />
          </Col>
          <Col sm={12} md={6} xl={3}>
            <Selector
              className='no-label'
              placeholder='กรุณาเลือก'
              items={supplyMethodSpecialDDL}
              rule={{ required: true }}
              value={supplyMethodSpecialType}
              onChange={(val) => setSupplyMethodSpecialType(val)}
              disabled={isDisabled}
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <Currency
              label='วงเงิน (บาท)'
              rule={{ required: true }}
              placeholder='วงเงิน (บาท)'
              value={planBudget}
              onChange={(val) => setPlanBudget(Number(val))}
              disabled={isDisabled}
            />
          </Col>
          <Col sm={12} md={4} xl={3}>
            <DatePicker
              disabled
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              value={data.expectingProcurementAt}
              monthYearOnly
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <DatePicker
              disabled
              label='วันที่เอกสารบันทึกร่างขอบเขตของงาน'
              value={data.torMemorandumDate}
              fullDate
            />
          </Col>
          <Col sm={12} md={4} xl={3}>
            <Input label='เลขที่เอกสารร่างขอบเขตของงาน' value={data.torMemorandumNo} disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} xl={9}>
            <InputTextArea
              label='ชื่อโครงการ (เรื่อง)'
              value={planName}
              onChange={(val) => setPlanName(val)}
              rule={{ required: true }}
              disabled={isDisabled}
              placeholder='ชื่อโครงการ (เรื่อง)'
            />
          </Col>
          <Col sm={12} xl={9}>
            <InputTextArea
              label='ที่มาและเหตุผล'
              value={sourceAndReasons}
              onChange={(val) => setSourceAndReasons(val)}
              rule={{ required: true }}
              disabled={isDisabled}
              placeholder='ที่มาและเหตุผล'
            />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='คณะกรรมการจัดจ้าง' className='fs-5 text-primary' />
            {openCollapse ? (
              <ArrowCollapse onClick={() => setOpenCollapse(!openCollapse)} openCollapse={openCollapse} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse(true)} openCollapse={openCollapse} />)}
          </div>
        </div>
        <Collapse in={openCollapse}>
          <Row className='gap-5'>
            <Col sm={12} className='ps-5'>
              <Table hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>ลำดับ</th>
                    <th style={{ minWidth: 50 }}>ชื่อ-นามสกุล</th>
                    <th style={{ minWidth: 50 }}>ตำแหน่ง</th>
                    <th style={{ minWidth: 50 }}>ฝ่าย/สำนัก</th>
                    <th style={{ minWidth: 50 }}>ตำแหน่งในคณะกรรมการ</th>
                  </tr>
                </thead>
                <tbody>
                  {data.committeeProcurements?.map((data, index) => (
                    <tr className='text-center' key={index}>
                      <td>{index + 1}</td>
                      <td className='text-start'>{data.fullName}</td>
                      <td className='text-start'>{data.position}</td>
                      <td className='text-start'>{data.department}</td>
                      <td>{data.positionOnBoardName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='อำนาจหน้าที่คณะกรรมการจัดจ้าง' className='fs-5 text-primary' />
            {openCollapse2 ? (
              <ArrowCollapse onClick={() => setOpenCollapse2(!openCollapse2)} openCollapse={openCollapse2} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse2(true)} openCollapse={openCollapse2} />)}
          </div>
          <Button
            variant='outline-primary'
            onClick={() => handlerAddDutyProcurements('')}
            disabled={isDisabled || !data.isResponsible}
          >
            <FaPlus className='me-1' />
            เพิ่มข้อมูล
          </Button>
        </div>
        <Collapse in={openCollapse2}>
          <Row className='gap-5'>
            <Col sm={12} className='ps-5'>
              <Table hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>ลำดับ</th>
                    <th style={{ minWidth: 50 }}>รายละเอียด</th>
                    <th style={{ minWidth: 50 }} />
                  </tr>
                </thead>
                <tbody>
                  {dutyProcurements.map((data, index) => (
                    <tr className='text-center' key={index}>
                      <td>{index + 1}</td>
                      <td className='text-start'>
                        <Form.Group className='mb-3'>
                          <InputGroup>
                            <Input
                              rule={{ required: true }}
                              type='text'
                              value={data.duty || ''}
                              disabled={isDisabled}
                              onChange={(val) => handlerOnChangeDutyProcurements(val, index)}
                            />
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex justify-content-end'>
                          <Button
                            variant='danger'
                            className='d-flex align-items-center gap-2'
                            onClick={() => handlerRemoveDutyProcurements(index)}
                            disabled={isDisabled}
                          >
                            <FaTrashAlt />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='การกำหนดระยะเวลาในการพิจารณาผลการเสนอราคา' className='fs-5 text-primary' />
            {openCollapse3 ? (
              <ArrowCollapse onClick={() => setOpenCollapse3(!openCollapse3)} openCollapse={openCollapse3} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse3(true)} openCollapse={openCollapse3} />)}
          </div>
        </div>
        <Collapse in={openCollapse3}>
          <Row className='gap-5'>
            <Col sm={12} lg={4} className='ps-5'>
              <Input label='จำนวน'
                value={data.deliveryQuantity}
                disabled
                type='number' />
            </Col>
            <Col sm={12} lg={2}>
              <Form.Group className='mb-1'>
                <InputGroup hasValidation>
                  <Form.Select
                    className='no-label'
                    value={data.deliveryUnit}
                    onChange={(event) => handlerOnChangeFineRatePeriodType(event.target as HTMLSelectElement)}
                    disabled
                  >
                    {
                      PeriodType.map((item) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                      ))
                    }
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='คณะกรรมการตรวจรับพัสดุ' className='fs-5 text-primary' />
            {openCollapse3 ? (
              <ArrowCollapse onClick={() => setOpenCollapse4(!openCollapse4)} openCollapse={openCollapse4} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse4(true)} openCollapse={openCollapse4} />)}
          </div>
        </div>
        <Collapse in={openCollapse4}>
          <Row className='gap-5'>
            <Col sm={12} className='ps-5'>
              <Table hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>ลำดับ</th>
                    <th style={{ minWidth: 50 }}>ชื่อ-นามสกุล</th>
                    <th style={{ minWidth: 50 }}>ตำแหน่ง</th>
                    <th style={{ minWidth: 50 }}>ฝ่าย/สำนัก</th>
                    <th style={{ minWidth: 50 }}>ตำแหน่งในคณะกรรมการ</th>
                  </tr>
                </thead>
                <tbody>
                  {data.committeeInspectors?.map((data, index) => (
                    <tr className='text-center' key={index}>
                      <td>{index + 1}</td>
                      <td className='text-start'>{data.fullName}</td>
                      <td className='text-start'>{data.position}</td>
                      <td className='text-start'>{data.department}</td>
                      <td>{data.positionOnBoardName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='อำนาจหน้าที่กรรมการตรวจรับพัสดุ' className='fs-5 text-primary' />
            {openCollapse5 ? (
              <ArrowCollapse onClick={() => setOpenCollapse5(!openCollapse5)} openCollapse={openCollapse5} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse5(true)} openCollapse={openCollapse5} />)}
          </div>
          <Button
            variant='outline-primary'
            onClick={() => handlerAddDutyInspectors('')}
            disabled={isDisabled || !data.isResponsible}
          >
            <FaPlus className='me-1' />
            เพิ่มข้อมูล
          </Button>
        </div>
        <Collapse in={openCollapse5}>
          <Row className='gap-5'>
            <Col sm={12} className='ps-5'>
              <Table hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>ลำดับ</th>
                    <th style={{ minWidth: 50 }}>รายละเอียด</th>
                    <th style={{ width: 10 }} />
                  </tr>
                </thead>
                <tbody>
                  {dutyInspectors.map((data, index) => (
                    <tr className='text-center' key={index}>
                      <td>{index + 1}</td>
                      <td className='text-start'>
                        <Form.Group className='mb-3'>
                          <InputGroup>
                            <Input
                              rule={{ required: true }}
                              type='text'
                              value={data.duty || ''}
                              disabled={isDisabled}
                              onChange={(val) => handlerOnChangeDutyInspectors(val, index)}
                            />
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex justify-content-end'>
                          <Button
                            variant='danger'
                            className='d-flex align-items-center gap-2'
                            onClick={() => handlerRemoveDutyInspectors(index)}
                            disabled={isDisabled}
                          >
                            <FaTrashAlt />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12} lg={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              appointmentId={data.id}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          variant='outline-primary'
          onClick={onClickBack}
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          กลับหน้าแรก
        </Button>

        {(data.status === AppointmentStatus.PENDING && (data.isResponsible && checkReCall)) && (
          <Button
            className='me-2 px-3'
            onClick={() => setShowReCall(true)}
            variant='danger'
          >
            <FaUndo className='mx-2 pb-1 fs-5' />
            เรียกคืนแก้ไข
          </Button>
        )}

        {
          ((data.status === AppointmentOrderStatus.None && data.isResponsible) || (data.status === AppointmentOrderStatus.DRAFT && data.isResponsible) || (data.status === AppointmentOrderStatus.REJECTED && data.isResponsible)) && (
            <Button
              onClick={onSubmitAsync}
              className='me-2 px-3'
              variant='success'>
              <FaSave className='me-2 pb-1 fs-5' />
              บันทึก
            </Button>
          )
        }

        {
          (data.status === AppointmentOrderStatus.DRAFT || data.status === AppointmentOrderStatus.APPROVED || data.status === AppointmentOrderStatus.CANCELLED || data.status === AppointmentOrderStatus.PENDING || data.status === AppointmentOrderStatus.REJECTED)
            ? (
              <Button
                onClick={onNext}
                className='me-2 px-3'
                variant='outline-primary'
              >
                ถัดไป
                <MdArrowForward className='ms-2 pb-1 fs-5' />
              </Button>
            )
            : <div style={{ width: '190px' }} />
        }

      </div>
      <HistoryModal
        title='ประวัติการใช้งานจัดทำคำสั่งแต่งตั้งคณะกรรมการจัดซื้อจัดจ้างและคณะกรรมการตรวจรับ'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={data.id}
      />
      <Modal
        show={showReCall}
        size='lg'
        onHide={() => setShowReCall(!showReCall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary' onClick={() => setShowReCall(!showReCall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={reCallAppointmentAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step1;
