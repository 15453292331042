import {
  parameterCreate,
  parameterListResponse,
  parameterUpdate,
} from 'models';
import http from 'utils/axios';

const getParameterListAsync = async (page: number, size: number, criteriaData?: parameterListResponse) => {
  const params = {
    page,
    size,
    ...criteriaData,
  };

  return await http.get('/parameter', { params });
};

const createParameterAsync = async (body: parameterCreate) => await http.post('parameter', body);

const updateParameterAsync = async (body: parameterUpdate, id: string) => await http.put(`parameter/${id}`, body);

const getParameterDetailAsync = async (id: string) => await http.get(`parameter/${id}`);

const getDropdownParameterAsync = async (group?: string) => {
  if (group) {
    const params = {
      group,
    };

    return await http.get('parameter/paramlist', { params });
  }

  return await http.get('parameter/paramlist');
};

const getParameterGroupDropdownParameterAsync = async () => await http.get('parameter/getparamgroup');

export default {
  getParameterListAsync,
  createParameterAsync,
  updateParameterAsync,
  getParameterDetailAsync,
  getDropdownParameterAsync,
  getParameterGroupDropdownParameterAsync,
};
