import {
  Breadcrumb,
  Card,
  DatePicker,
  Input,
  InputTextArea,
  Modal,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  AcceptorApprovers,
  AcceptorPlanDetailResponse,
  IFile,
  ItemModel,
} from 'models';
import UploadFile from 'pages/PL/PL0201/UploadFile';
import {
  FormEvent,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaAngleLeft,
  FaUndo,
} from 'react-icons/fa';
import { FiCornerDownLeft } from 'react-icons/fi';
import {
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router';
import { acceptor } from 'services';
import {
  HttpStatusCode,
  submitForm,
  thaiFormatDateWithSlash,
} from 'utils';
import {
  PlanStatusText,
  PlanTypeText,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';

type Loader = { departmentItems: ItemModel[], supplymethodTypeList: ItemModel[], supplymethodNameList: ItemModel[], planDetailData: AcceptorPlanDetailResponse };

enum TYPE {
  REJECT = 'Reject',
  APPROVED = 'Approve',
}

interface ModalProps {
  show: boolean;
  type: TYPE;
  onHide: () => void;
}

export default function PL0307Detail() {
  const { type } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<TYPE>(TYPE.APPROVED);
  const [hasStatus, setHasStatus] = useState(false);
  const { planDetailData } = useLoaderData() as Loader;

  useEffect(() => {
    if (planDetailData.plan.status !== PlanStatusText.ApproveAcceptor && planDetailData.plan.status !== PlanStatusText.RejectAcceptor) {
      setHasStatus(true);
    }
  }, []);

  const BREADCRUMB_INFO = [
    {
      routerName: '/pl/pl0307', label: 'ให้ความเห็นชอบ',
    },
    {
      routerName: '', label: 'รายละเอียด',
    },
  ];

  const handleOnShowModal = (type: TYPE) => {
    if (type === TYPE.REJECT) {
      setModalType(TYPE.REJECT);
    } else {
      setModalType(TYPE.APPROVED);
    }

    setShowModal(true);
  };

  return (
    <>
      <h4 className='mt-2 mb-0 text-primary text-start'>รายละเอียด</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <div className='data-table mt-3'>
        <Card>
          <div className='d-flex align-items-center gap-3 mb-3'>
            <div className='parallelogram' />
            <h4 className='text-primary'>มอบหมายงาน</h4>
          </div>
          <DataTable />
        </Card>
      </div>
      {type === PlanTypeText.ChangePlan
        && (
          <div className='change-plan mt-3'>
            <Card>
              <ChangePlanDetail />
            </Card>
          </div>
        )}
      <div className='plan mt-3'>
        <Card>
          <PlanDetail />
        </Card>
      </div>
      <div className='d-flex justify-content-center gap-3 mt-3'>
        <Button
          variant='outline-primary'
          className='d-flex align-items-center gap-2'
          onClick={() => navigate(-1)}
        >
          <FaAngleLeft />ย้อนกลับ
        </Button>
        {hasStatus
          && (
            <>
              <Button
                variant='danger'
                className='d-flex align-items-center gap-2'
                onClick={() => handleOnShowModal(TYPE.REJECT)}
              >
                <FaUndo />ส่งกลับแก้ไข
              </Button><Button
                variant='primary'
                className='d-flex align-items-center gap-2'
                onClick={() => handleOnShowModal(TYPE.APPROVED)}
              >
                <FaAlignLeft />อนุมัติ
              </Button>
            </>
          )}
      </div>
      <AcceptModal show={showModal}
        onHide={() => setShowModal(false)}
        type={modalType} />
    </>
  );
}

function DataTable() {
  const [approverList, setApproverList] = useState<AcceptorApprovers[]>([]);
  const { planDetailData } = useLoaderData() as Loader;

  useEffect(() => {
    setApproverList(planDetailData.plan.approvers);
  }, []);

  return (
    <Table hidePagination>
      <thead>
        <tr>
          <th style={{ minWidth: 50 }}>ลำดับ</th>
          <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
          <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
          <th style={{ minWidth: 100 }}>สถานะ</th>
          <th style={{ minWidth: 100 }}>วันที่อนุมัติ</th>
          <th style={{ minWidth: 50 }}>ความเห็น</th>
        </tr>
      </thead>
      <tbody>
        {approverList.map((data, index) => (
          <tr key={data.id}>
            <td className='text-center'>{index + 1}</td>
            <td>{data.name}</td>
            <td>{data.department}</td>
            <td className='text-center'><Status type={StatusType.PROCESS}
              value={data.approveStatus} /></td>
            <td className='text-center'>{thaiFormatDateWithSlash(data.updatedAt)}</td>
            <td>{data.remark}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function ChangePlanDetail() {
  return (
    <>
      <div className='d-flex align-items-center gap-3 mb-3'>
        <div className='parallelogram' />
        <h4 className='text-primary'>แผนการจัดซื้อจัดจ้างใหม่</h4>
      </div>
      <Row className='mt-3'>
        <Col lg={6}>
          <InputTextArea
            disabled
            rule={{ required: true }}
            label='ชื่อโครงการ'
          />
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Input
            disabled
            label='งบประมาณโครงการ (บาท)'
            rule={{ required: true }}
          />
        </Col>
        <Col lg={3}>
          <DatePicker
            disabled
            monthYearOnly
            label='ประมาณการช่วงเวลาการจัดซิ้อจัดจ้าง'
          />
        </Col>
      </Row>
    </>
  );
}

function PlanDetail() {
  const [files, setFiles] = useState<IFile[]>([]);
  const { departmentItems, supplymethodTypeList, supplymethodNameList, planDetailData } = useLoaderData() as Loader;

  return (
    <>
      <div className='d-flex align-items-center gap-3 mb-3'>
        <div className='parallelogram' />
        <h4 className='text-primary'>ข้อมูลแผน</h4>
        <Status type={StatusType.PROJECT}
          value='AnnualPlan'
          badge />
        <Status type={StatusType.PROCESS}
          value='Approved'
          badge />
      </div>
      <Row>
        <Col md={6}
          lg={3}>
          <Input label='เลขที่แนบ'
            disabled
            value={planDetailData.plan.planNumber} />
        </Col>
        <Col md={6}
          lg={3}>
          <Selector label='ฝ่าย/สำนัก'
            disabled
            items={departmentItems}
            value={planDetailData.plan.departmentId} />
        </Col>
      </Row>
      <Row>
        <Col md={6}
          lg={3}>
          <Selector label='วิธีการจัดหา'
            disabled
            items={supplymethodTypeList}
            value={planDetailData.plan.supplyMethod} />
        </Col>
        <Col md={6}
          lg={3}>
          <Selector disabled
            label='&nbsp;'
            items={supplymethodNameList}
            value={planDetailData.plan.supplyMethodName} />
        </Col>
      </Row>
      <Row>
        <Col md={6}
          lg={6}>
          <InputTextArea label='ชื่อโครงการ'
            disabled
            value={planDetailData.plan.planName} />
        </Col>
      </Row>
      <Row>
        <Col md={6}
          lg={3}>
          <Input label='งบประมาณโครงการ (บาท)'
            disabled
            value={planDetailData.plan.planBudget} />
        </Col>
        <Col md={6}
          lg={3}>
          <DatePicker label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
            disabled
            value={planDetailData.plan.expectingProcurementAt} />
        </Col>
      </Row>
      <Row>
        <Col md={6}
          lg={3}>
          <Input label='เลขที่แผน e-GP'
            disabled
            value={planDetailData.plan.planEgpNumber} />
        </Col>
      </Row>
      <label>เอกสารแนบ</label>
      <Card>
        <div className='d-flex justify-content-center'>
          <Row>
            <Col lg={12}>
              <UploadFile
                files={files}
                planId='test'
                handleFileChange={() => {
                }}
                removeItem={() => {
                }}
                disabled
              />
            </Col>
          </Row>
        </div>
      </Card>
      <Row className='mt-3'>
        <Col md={6}
          lg={3}>
          <Input label='สร้างโดย'
            disabled
            value={planDetailData.plan.createdByUserFullName} />
        </Col>
        <Col md={6}
          lg={3}>
          <DatePicker label='วันที่สร้าง'
            disabled
            value={planDetailData.plan.createdInfoAt} />
        </Col>
      </Row>
    </>
  );
}

function AcceptModal(props: ModalProps) {
  const [remark, setRemark] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  const onSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();
    submitForm();

    if (remark && id) {
      approveAndRejectAsync(id);
    }
  };

  const approveAndRejectAsync = async (id: string) => {
    if (props.type === TYPE.REJECT) {
      const response = await acceptor.rejectAcceptorAsync(id, remark);

      if (response.status === HttpStatusCode.ACCEPTED) {
        toast.success('ส่งกลับแก้ไขสำเร็จ');

        navigate('/pl/pl0307');
      }

      return;
    }

    const response = await acceptor.approveAcceptorAsync(id, remark);

    if (response.status === HttpStatusCode.ACCEPTED) {
      toast.success('อนุมัติสำเร็จ');

      navigate('/pl/pl0307');
    }
  };

  return (
    <Modal show={props.show}
      size='lg'
      onHide={props.onHide}>
      <div className='d-flex align-items-center gap-3'>
        <div className='parallelogram' />
        <h5 className='mt-2'>{props.type === TYPE.REJECT ? 'ส่งกลับแก้ไข' : 'ส่งอนุมัติ'}</h5>
      </div>
      <div>
        <Form onSubmit={onSubmit}>
          <InputTextArea
            label='เหตุผล'
            value={remark}
            onChange={(value) => setRemark(value)}
            rule={{ required: true }}
          />
          <div className='d-flex justify-content-end gap-3'>
            <Button
              variant='outline-secondary'
              onClick={props.onHide}
            >
              ยกเลิก
            </Button>
            <Button type='submit'>ยืนยัน</Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
