import { DepartmentResponse } from 'models';
import { ItemModel } from 'models/systemUtility';
import {
  department,
  shareValue,
} from 'services';
import PL01Services from 'services/PL/PL0201Service';

const PR0202Loader = async () => {
  const departmentItems: ItemModel[] = [];
  await GetDepartmentList(departmentItems);

  const planItems: ItemModel[] = [];
  await GetPlantList(planItems);

  const supplyMethodItems: ItemModel[] = [];
  await GetSupplyMethodList(supplyMethodItems);

  const supplyMethodTypeItems: ItemModel[] = [];
  await GetSupplyMethodTypeList(supplyMethodTypeItems);

  return {
    departmentDDL: departmentItems,
    planDDL: planItems,
    supplyMethodDDL: supplyMethodItems,
    supplyMethodNameDDL: supplyMethodTypeItems,
  };
};

const GetDepartmentList = async (itemForMap: ItemModel[]) => {
  const departmentsRes = await department.getDepartmentsAsync();
  const departmentData: DepartmentResponse[] = departmentsRes.data;
  departmentData.forEach((data) => itemForMap.push({ label: data.name, value: data.id ? data.id : '' }));
};

const GetPlantList = async (itemForMap: ItemModel[]) => {
  const response = await PL01Services.GetPlanSelectionListAsync();
  const planListData: ItemModel[] = response.data;
  planListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
};

const GetSupplyMethodList = async (itemForMap: ItemModel[]) => {
  const response = await shareValue.getSupplyMethodListAsync();
  const supplyMethodTypeData: ItemModel[] = response.data;
  supplyMethodTypeData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
};

const GetSupplyMethodTypeList = async (itemForMap: ItemModel[]) => {
  const response = await shareValue.getSupplyMethodTypeListAsync();
  const supplyMethodListData: ItemModel[] = response.data;
  supplyMethodListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
};
export {
  PR0202Loader,
};
