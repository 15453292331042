import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Input,
  Radio,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import { DefectsWarrantyModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';
import { PeriodTypeIdEnum, WarrantyConditionIdEnum } from 'utils/constants/ShareValueEnum';

interface IDefectWarrantyProps {
  title?: string;
  defectsWarranty: DefectsWarrantyModel;
  contractTemplateCode: string;
  periodTypeDDL: ItemModel[];
  repairPeriodTypeDDL: ItemModel[];
  periodConditionDDL: ItemModel[];
  warrantyConditionDDL: ItemModel[];
  periodConditionOtherDDL: ItemModel[];
  onDefectWarrantyChange: (value: DefectsWarrantyModel) => void;
  isDisabled?: boolean;
}

const fields = [
  {
    code: 'CF01',
    isDefectsWarranty: "มีการรับประกันความชำรุดบกพร่องหรือไม่",
    periodYear: "ปี",
    periodMonth: "เดือน",
    periodConditionTypeDefault: WarrantyConditionIdEnum.นับถัดจากวันที่ได้รับมอบ, // ddl นับถัดจากวันที่ได้รับมอบ,
    period_label: "ระยะเวลาการรับประกันความชำรุดบกพร่องหรือขัดข้อง",
    repairPeriodDay: "ระยะเวลาให้แก้ไข ภายในกำหนด", // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodType: "นับถัดจากวันที่ได้รับแจ้งเป็นหนังสือ",
  },
  {
    code: 'CF02',
    isDefectsWarranty: "มีการรับประกันความชำรุดบกพร่องหรือไม่", //checkbox มี, ไม่
    period_label: "ระยะเวลาการรับประกันความชำรุดบกพร่องหรือขัดข้อง",
    periodYear: "ปี",
    periodMonth: "เดือน",
    periodDay: "วัน",
    periodConditionTypeDefault: WarrantyConditionIdEnum.นับถัดจากวันที่ได้รับมอบ, // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodDay: "ระยะเวลาให้แก้ไข ซ่อมแซมให้ดีดังเดิมภายใน", // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodDayDefault: PeriodTypeIdEnum.วัน,
    repairPeriodType: "นับถัดจากวันที่ได้รับแจ้งเป็นหนังสือจากผู้รับจ้าง",
  },
  {
    code: 'CF03',
    isDefectsWarranty: "มีการรับประกันความชำรุดบกพร่องหรือไม่", //checkbox มี, ไม่
    period_label: "ระยะเวลาการรับประกันความชำรุดบกพร่องหรือขัดข้อง",
    periodYear: "ปี",
    periodMonth: "เดือน",
    periodDay: "วัน",
    periodConditionTypeDefault: WarrantyConditionIdEnum.นับถัดจากวันที่ได้รับมอบ, // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodDay: "ระยะเวลาให้แก้ไข ซ่อมแซมให้ดีดังเดิมภายใน", // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodDayDefault: PeriodTypeIdEnum.วัน,
    repairPeriodType: "นับถัดจากวันที่ได้รับแจ้ง",
  },
  {
    code: 'CF04',
    isDefectsWarranty: "มีการรับประกันความชำรุดบกพร่องหรือไม่", //checkbox มี, ไม่
    period_label: "ระยะเวลาการรับประกันความชำรุดบกพร่องหรือขัดข้อง",
    periodYear: "ปี",
    periodMonth: "เดือน",
    periodDay: "วัน",
    periodConditionTypeDefault: WarrantyConditionIdEnum.นับถัดจากวันที่ได้รับมอบ, // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodDay: "ระยะเวลาให้แก้ไข ซ่อมแซมให้ดีดังเดิมภายใน", // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodDayDefault: PeriodTypeIdEnum.วัน,
    repairPeriodType: "นับถัดจากวันที่ได้รับแจ้ง",
  },
  {
    code: 'CF05',
    isDefectsWarranty: "มีการรับประกันความชำรุดบกพร่องหรือไม่", //checkbox มี, ไม่
    period_label: "ผู้ขายยินยอมจะรับผิดชอบในความชำรุดบกพร่องของโปรแกรมคอมพิวเตอร์ เป็นเวลา",
    periodYear: "ปี",
    periodMonth: "เดือน",
    periodConditionTypeDefault: WarrantyConditionIdEnum.นับถัดจากวันที่ได้รับมอบ, // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodDay: "ผู้ขายจะต้องซ่อมแซมแก้ไข โปรแกรมคอมพิวเตอร์ให้ทำงานได้ภายใน", // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodType: "นับถัดจากวันที่ได้รับแจ้งคอมพิวเตอร์ใหม่ ภายในกำหนด",
  },
  {
    code: 'CF06',
  },
  {
    code: 'CF07',
  },
  {
    code: 'CF08',
  },
  {
    code: 'CF09',

  },
  {
    code: 'CF10',
  },
  {
    code: 'CF11',
  },
  {
    code: 'CF12',
    isDefectsWarranty: "มีการรับประกันความชำรุดบกพร่องหรือไม่", //checkbox มี, ไม่
    period_label: "ระยะลเวลาการรับประกันความชำรุดบกพร่องหรือขัดข้อง",
    periodYear: "ปี",
    periodMonth: "เดือน",
    periodConditionTypeDefault: WarrantyConditionIdEnum.นับถัดจากวันที่ได้รับมอบ, // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodDay: "ระยะเวลาให้แก้ไข ซ่อมแซมให้ดีดังเดิมภายใน", // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodDayDefault: PeriodTypeIdEnum.วัน,
    repairPeriodType: "นับตั้งแต่วันที่ได้รับแจ้งจากผู้ให้แลกเปลี่ยน",
  },
  {
    code: 'CF13',
    isDefectsWarranty: "มีการรับประกันความชำรุดบกพร่องหรือไม่", //checkbox มี, ไม่
    period_label: "ระยะเวลาการรับประกันความชำรุดบกพร่องหรือขัดข้อง",
    periodYear: "ปี",
    periodMonth: "เดือน",
    periodConditionTypeDefault: WarrantyConditionIdEnum.นับถัดจากวันที่ได้รับมอบ, // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodDay: "ระยะเวลาให้แก้ไข ภายในกำหนด", // ddl นับถัดจากวันที่ได้รับมอบ,
    repairPeriodType: "นับถัดจากวันที่ได้รับแจ้งเป็นหนังสือ",
  },
  {
    code: 'CF14',
  },
  {
    code: 'CF15',
  },
];

const WarrantyInfo = [
  {
    label: 'มี',
    value: 'true',
  },
  {
    label: 'ไม่มี',
    value: 'false',
  },
];

export function DefectWarranty(props: IDefectWarrantyProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [defectsWarranty, setDefectsWarranty] = useState<DefectsWarrantyModel>(props.defectsWarranty);
  const [isDisabledWarranty, setIsDisabledWarranty] = useState<boolean>(!props.defectsWarranty?.isDefectsWarranty!);

  useEffect(() => {
    if (defectsWarranty) {
      props.onDefectWarrantyChange(defectsWarranty);
    }
  }, [defectsWarranty]);

  useEffect(() => {
    if (firstLoad) {
      defaultDDL();
      setFirstLoad(false);
    }
  }, [firstLoad]);

  const defaultDDL = useCallback(() => {
    fields.filter(f => f.code === props.contractTemplateCode).forEach((data) => {
      setDefectsWarranty({
        ...defectsWarranty,
        periodConditionType: (!defectsWarranty?.periodConditionType && data.periodConditionTypeDefault) ? data.periodConditionTypeDefault : defectsWarranty?.periodConditionType
      });
    });
  }, []);

  const onTextChange = useCallback((key: string, value: string) => {
    setDefectsWarranty(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setDefectsWarranty(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onChangesetWarrantyActive = useCallback((val: string) => {
    setDefectsWarranty({ ...defectsWarranty, isDefectsWarranty: val === 'true' });

    if (val === 'false') {
      setIsDisabledWarranty(true);
    } else {
      setIsDisabledWarranty(false);
    }
  }, []);

  return (
    <Card className='mt-3'>
      <Title text={`สัญญา${props.title ? props.title : 'การรับประกันความชำรุดบกพร่อง'}`} className='fs-5 text-primary' />
      <Row className='mt-3'>
        <>
          {
            fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
              <>
                {
                  data.isDefectsWarranty &&
                  (
                    <Col sm={12}
                      md={6}>
                      <Radio
                        label={data.isDefectsWarranty}
                        dflex
                        items={WarrantyInfo}
                        value={defectsWarranty && defectsWarranty.isDefectsWarranty ? 'true' : 'false'}
                        name='isDefectsWarrantyInfo'
                        onChange={(val) => onChangesetWarrantyActive(val?.toString())}
                        rule={{ required: true }}
                        disabled={props.isDisabled} />
                    </Col>
                  )
                }
                {
                  data.period_label &&
                  (
                    <>
                      <Row>
                        <Col sm={3}
                          md={3}
                          className='d-flex'>
                          <InputNumber
                            textCenter
                            label={data.period_label}
                            value={defectsWarranty?.periodYear}
                            onChange={(value) => onNumberChange('periodYear', Number(value))}
                            disabled={props.isDisabled || isDisabledWarranty} />
                          <label className='mt-5 ps-2'>ปี</label>
                        </Col>
                        <Col sm={3}
                          md={3}
                          className='d-flex'>
                          <InputNumber
                            textCenter
                            className='no-label'
                            value={defectsWarranty?.periodMonth}
                            onChange={(value) => onNumberChange('periodMonth', Number(value))}
                            disabled={props.isDisabled || isDisabledWarranty} />
                          <label className='mt-5 ps-2'>เดือน</label>
                        </Col>
                        <Col sm={3}
                          md={3}
                          className='d-flex'>
                          <InputNumber
                            textCenter
                            className='no-label'
                            value={defectsWarranty?.periodDay}
                            onChange={(value) => onNumberChange('periodDay', Number(value))}
                            disabled={props.isDisabled || isDisabledWarranty} />
                          <label className='mt-5 ps-2'>วัน</label>
                        </Col>
                        <Col sm={3}
                          md={3}>
                          <Selector
                            className='no-label'
                            items={props.warrantyConditionDDL}
                            value={defectsWarranty?.periodConditionType}
                            onChange={(value) => onTextChange('periodConditionType', value)}
                            disabled={props.isDisabled || isDisabledWarranty} />
                        </Col>
                      </Row>
                    </>
                  )
                }
                {
                  data.repairPeriodDay &&
                  (
                    <Row className='mt-3'>
                      <Col sm={3}
                        md={3}
                        className='d-flex'>
                        <InputNumber
                          textCenter
                          label={data.repairPeriodDay}
                          value={defectsWarranty?.repairPeriodDay}
                          onChange={(value) => onNumberChange('repairPeriodDay', Number(value))}
                          disabled={props.isDisabled || isDisabledWarranty} />
                      </Col>
                      <Col sm={3}
                        md={3}>
                        <Selector
                          className='no-label'
                          items={props.repairPeriodTypeDDL}
                          value={defectsWarranty?.repairPeriodType}
                          onChange={(value) => onTextChange('repairPeriodType', value)}
                          disabled={props.isDisabled || isDisabledWarranty} />
                      </Col>
                      <Col sm={3}
                        md={3}>
                        <Selector
                          className='no-label'
                          items={props.periodConditionOtherDDL}
                          value={defectsWarranty?.repairPeriodConditionType}
                          onChange={(value) => onTextChange('repairPeriodConditionType', value)}
                          disabled={props.isDisabled || isDisabledWarranty} />
                      </Col>
                    </Row>
                  )
                }
              </>
            )
          }
        </>
      </Row>
    </Card >
  );
}
