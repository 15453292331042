import { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { BiMailSend } from 'react-icons/bi';
import { BsDownload } from 'react-icons/bs';
import {
  FaHistory,
  FaSearch,
  FaTrash,
  FaCheckCircle,
  FaUndo,
} from 'react-icons/fa';
import {
  MdAdd,
  MdArrowBack,
  MdArrowForward,
  MdFactCheck,
  MdMode,
  MdNoteAdd,
  MdPrint,
  MdSave,
  MdTextSnippet,
  MdCancel,
  MdSegment
} from 'react-icons/md';
import { TfiExport } from 'react-icons/tfi';

interface Props {
  text?: JSX.Element | string;
  icon?: string;
  className?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: () => void;
  variant?: string;
  iconAlignRight?: boolean;
  form?: string;
  disabled?: boolean;
}

function generateIcon(icon: string) {
  switch (icon) {
    case 'download':
      return <BsDownload className="me-1" />;
    case 'export':
      return <TfiExport className="me-1" />;
    case 'add':
      return <MdAdd className="me-1 fs-5" />;
    case 'search':
      return <FaSearch className="me-1" />;
    case 'edit':
      return <MdMode className="mx-1 fs-5" />;
    case 'arrowBack':
      return <MdArrowBack className="fs-5 me-1" />;
    case 'save':
      return <MdSave className="fs-4 me-1" />;
    case 'checked':
      return <FaCheckCircle className="fs-6 me-1" />;
    case 'print':
      return <MdPrint className="me-1 fs-5" />;
    case 'arrowForward':
      return <MdArrowForward className="fs-5 ms-1" />;
    case 'delete':
      return <FaTrash className="me-1 fs-5" />;
    case 'history':
      return <FaHistory className="me-1 fs-5" />;
    case 'save-deliver':
      return <MdFactCheck className="me-1 fs-5" />;
    case 'add-note':
      return <MdNoteAdd className="me-1 fs-5" />;
    case 'add-report':
      return <MdTextSnippet className="me-1 fs-5" />;
    case 'cancel':
      return <MdCancel className="fs-5 me-1" />;
    case 'undo':
      return <FaUndo className="fs-6 me-1" />;
    case 'segment':
      return <MdSegment className='fs-5 me-1' />;
    case 'send-mail':
      return <BiMailSend className='fs-5 me-1' />;
    default:
    // code block
  }
}

const classNameForward = (text?: JSX.Element | string, variant?: string) => {
  if (text === "ย้อนกลับ" || text === "ถัดไป" || text === "กลับหน้าแรก") {
    return 'outline-primary';
  }
  if (text === "บันทึก") {
    return 'success';
  }
  return variant;
}

export function ButtonCustom({ text, icon, className, onClick, variant, iconAlignRight, disabled = false, form = '' }: Props) {
  const CLICK_INTERVAL = 500 // double click interval in ms
  let lastClick = (new Date()).getTime()

  const handlerOnClick = useCallback(() => {
    if (onClick) {
      const msNow = (new Date()).getTime()
      if ((msNow - lastClick) < CLICK_INTERVAL) {
        lastClick = msNow;
        return;
      }
      lastClick = msNow;
      onClick();
    }
  }, [onClick]);

  return (
    <Button form={form}
      className={`${className || ''} mx-1`}
      variant={classNameForward(text, variant)}
      onClick={() => handlerOnClick()}
      disabled={disabled}>
      <span className='me-1'>{iconAlignRight ? '' : icon && generateIcon(icon)}</span>
      {text === "ย้อนกลับ" && <MdArrowBack className="fs-5 me-1" />}
      {text === "กลับหน้าแรก" && <MdArrowBack className="fs-5 me-1" />}
      {text === "บันทึก" && <MdSave className="fs-4 me-1" />}
      {text}
      {text === "ถัดไป" && <MdArrowForward className="fs-5 ms-1" />}
      <span className='ml-1'>{iconAlignRight ? icon && generateIcon(icon) : ''}</span>
    </Button>
  );
}