import { DepartmentResponse } from 'models';
import { ItemModel } from 'models/systemUtility';
import {
  department,
  shareValue,
} from 'services';
import PP0201Services from 'services/PP/PP0201Services';

const PP0301Loader = async () => {
  const departmentItems: ItemModel[] = [];
  await GetDepartmentList(departmentItems);
  const planSelection: ItemModel[] = [];
  await GetPlanSelectionList(planSelection);
  const supplyMethodSelection: ItemModel[] = [];
  await GetSupplyMethodList(supplyMethodSelection);
  const supplyMethodTypeSelection: ItemModel[] = [];
  await GetSupplyMethodTypeList(supplyMethodTypeSelection);
  //   const supplyMethodSpecialTypeSelection: ItemModel[] = [];
  // await GetSupplyMethodSpecialTypeList(supplyMethodSpecialTypeSelection);

  return {
    departmentDDL: departmentItems,
    planDDL: planSelection,
    supplyMethodDDL: supplyMethodSelection,
    supplyMethodTypeDDL: supplyMethodTypeSelection,
    // supplyMethodSpecialTypeDDL: supplyMethodSpecialTypeSelection
  };
};

const GetDepartmentList = async (itemForMap: ItemModel[]) => {
  const departmentsRes = await department.getDepartmentsAsync();
  const departmentData: DepartmentResponse[] = departmentsRes.data;
  departmentData.forEach((data) => itemForMap.push({ label: data.name, value: data.id ? data.id : '' }));
};

const GetPlanSelectionList = async (itemForMap: ItemModel[]) => {
  const response = await PP0201Services.GetPlanSelectionListAsync();
  const planListData: ItemModel[] = response.data;
  planListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
};

const GetSupplyMethodList = async (itemForMap: ItemModel[]) => {
  const response = await shareValue.getSupplyMethodListAsync();
  const supplyMethodTypeData: ItemModel[] = response.data;
  supplyMethodTypeData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
};

const GetSupplyMethodTypeList = async (itemForMap: ItemModel[]) => {
  const response = await shareValue.getSupplyMethodTypeListAsync();
  const supplyMethodListData: ItemModel[] = response.data;
  supplyMethodListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
};

// const GetSupplyMethodSpecialTypeList = async (itemForMap: ItemModel[]) => {
//      const response = await shareValue.getSupplyMethodListAsync();
//       const supplyMethodListData: ItemModel[] = response.data;
//       supplyMethodListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? "" }));
// };

export {
  PP0301Loader,
};
