import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  DatePicker,
  Readonly,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ServiceDetailModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';
import moment from 'moment';
import { ItemModel } from 'models';

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

interface IProps {
  serviceDetail: ServiceDetailModel;
  onServiceDetailChange: (value: ServiceDetailModel) => void;
  periodTypeDDL: ItemModel[];
  isDisabled?: boolean;
}

export function ServiceDetail(props: IProps) {
  const [serviceDetail, setServiceDetail] = useState<ServiceDetailModel>({} as ServiceDetailModel);

  useEffect(() => {
    if (props.serviceDetail) {
      setServiceDetail(props.serviceDetail);
    }
  }, [props.serviceDetail]);

  useEffect(() => {
    props.onServiceDetailChange(serviceDetail);
  }, [serviceDetail]);

  const onChangeServiceStartDate = (start?: Date) => {
    if (start && serviceDetail.endServiceDate) {
      const startDate = moment(start);
      const timeEnd = moment(serviceDetail.endServiceDate);
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);

      setServiceDetail({ ...serviceDetail, startServiceDate: start, serviceYearTotal: diffDuration.years(), serviceMonthTotal: diffDuration.months(), serviceDayTotal: diffDuration.days() });
    } else {
      setServiceDetail({ ...serviceDetail, startServiceDate: start, serviceYearTotal: 0, serviceMonthTotal: 0, serviceDayTotal: 0 });
    }
  }

  const onChangeServiceEndDate = (end?: Date) => {
    if (serviceDetail.startServiceDate && end) {
      const startDate = moment(serviceDetail.startServiceDate);
      const timeEnd = moment(end);
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);

      setServiceDetail({ ...serviceDetail, endServiceDate: end, serviceYearTotal: diffDuration.years(), serviceMonthTotal: diffDuration.months(), serviceDayTotal: diffDuration.days() });
    } else {
      setServiceDetail({ ...serviceDetail, endServiceDate: end, serviceYearTotal: 0, serviceMonthTotal: 0, serviceDayTotal: 0 });
    }
  }

  return (
    <Card className='mt-3'>
      <Title text='การให้บริการ' className='fs-5 text-primary' />
      <Row className='mt-3 align-items-center'>
        <Col sm={6} md={3}>
          <DatePicker label='วันที่ตกลงให้บริการ ตั้งแต่'
            value={serviceDetail?.startServiceDate}
            onChangeHasNullable={(val) => onChangeServiceStartDate(val)}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={6} md={3}>
          <DatePicker label='ถึงวันที่'
            value={serviceDetail?.endServiceDate}
            onChangeHasNullable={(val) => onChangeServiceEndDate(val)}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={6} md={3} style={{ paddingTop: 20 }}>
          <span>รวมเป็นเวลาทั้งสิ้น</span>
          {
            (serviceDetail?.serviceYearTotal && serviceDetail.serviceYearTotal > 0) ? (
              <span>{` ${serviceDetail.serviceYearTotal.toString()} ปี`}</span>
            ) : (<></>)
          }
          {
            (serviceDetail?.serviceMonthTotal && serviceDetail.serviceMonthTotal > 0) ? (
              <span>{` ${serviceDetail.serviceMonthTotal.toString()} เดือน`}</span>
            ) : (<></>)
          }
          {
            (serviceDetail?.serviceDayTotal && serviceDetail.serviceDayTotal > 0) ? (
              <span>{` ${serviceDetail.serviceDayTotal.toString()} วัน`}</span>
            ) : (<></>)
          }
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col sm={4} md={2}>
          <InputNumber
            label='ต้องตรวจสอบบำรุงรักษา อย่างน้อยเดือนละ (ครั้ง)'
            value={serviceDetail?.maintenanceCountPerMonth}
            onChange={(val) => setServiceDetail({ ...serviceDetail, maintenanceCountPerMonth: Number(val) })}
            disabled={props.isDisabled}
          />
        </Col>
        <Col sm={4} md={2}>
          <InputNumber
            label='เริ่มจัดการซ่อมแซมภายใน'
            value={serviceDetail?.startMaintenanceCountIn}
            onChange={(val) => setServiceDetail({ ...serviceDetail, startMaintenanceCountIn: Number(val) })}
            disabled={props.isDisabled}
          />
        </Col>
        <Col sm={4} md={2}>
          <Selector
            items={props.periodTypeDDL}
            className='no-label'
            value={serviceDetail?.startMaintenanceCountInUnit}
            onChange={(val) => setServiceDetail({ ...serviceDetail, startMaintenanceCountInUnit: val })}
            disabled={props.isDisabled}
          />
        </Col>
        <Col sm={4} md={2}>
          <InputNumber
            label='ให้แล้วเสร็จภายใน'
            value={serviceDetail?.completeMaintenanceCountIn}
            onChange={(val) => setServiceDetail({ ...serviceDetail, completeMaintenanceCountIn: Number(val) })}
            disabled={props.isDisabled}
          />
        </Col>
        <Col sm={4} md={2}>
          <Selector
            items={props.periodTypeDDL}
            className='no-label'
            value={serviceDetail?.completeMaintenanceCountInUnit}
            onChange={(val) => setServiceDetail({ ...serviceDetail, completeMaintenanceCountInUnit: val })}
            disabled={props.isDisabled}
          />
        </Col>
      </Row>
    </Card>
  );
}
