import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  DatePicker,
  Input,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ClientDetailModel } from '../../../../../../models/CM/CM02Models';
import { LocationDataHandler } from './LocationDataHandler';
import { ItemModel } from 'models';

interface IClientDetailProps {
  data: ClientDetailModel;
  onTenantBuyerChange: (value: ClientDetailModel) => void;
  isDisabled?: boolean;
  provincesDDL: ItemModel[];
}

export function ClientDetail(props: IClientDetailProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [tenantBuyerData, setTenantBuyerData] = useState<ClientDetailModel>(props.data);

  useEffect(() => {
    if (props.data && firstLoad) {
      setTenantBuyerData(props.data);
      setFirstLoad(false);
    }
  }, [props.data, firstLoad]);

  useEffect(() => {
    props.onTenantBuyerChange(tenantBuyerData);
  }, [tenantBuyerData]);

  const onTextChange = useCallback((key: string, value: string) => {
    setTenantBuyerData(prevState => ({ ...prevState, [key]: value }));
  }, []);

  const onDateChange = useCallback((key: string, value: Date) => {
    setTenantBuyerData(prevState => ({ ...prevState, [key]: value }));
  }, []);

  const onChangeSubDistrict = useCallback((id: string, label: string) => {
    setTenantBuyerData(prevState => ({
      ...prevState,
      subDistrictCode: id,
      subDistrictName: label,
    }));
  }, []);

  const onChangeDistrict = useCallback((id: string, label: string) => {
    setTenantBuyerData(prevState => ({
      ...prevState,
      districtCode: id,
      districtName: label,
    }));
  }, []);

  const onChangeProvince = useCallback((id: string, label: string) => {
    setTenantBuyerData(prevState => ({
      ...prevState,
      provinceCode: id,
      provinceName: label,
    }));
  }, []);

  return (
    <Card className='mt-3'>
      <Title text='ข้อมูลผู้ซื้อ/ผู้จ้าง/ผู้เช่า'
        className='fs-5 text-primary' />
      <Row className='mt-3 align-items-center'>
        <Col sm={6}
          md={6}>
          <Input
            label='สัญญานี้จัดทำขึ้น ณ'
            value={tenantBuyerData?.place}
            onChange={(value) => onTextChange('place', value)}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={3}>
          <DatePicker
            label='วันที่จัดทำสัญญา'
            value={tenantBuyerData?.contractDraftDate}
            onChange={(value) => onTextChange('contractDraftDate', value.toISOString())}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row>
        <LocationDataHandler
          provinceId={tenantBuyerData?.provinceCode}
          districtId={tenantBuyerData?.districtCode}
          subDistrictId={tenantBuyerData?.subDistrictCode}
          provincesDDL={props.provincesDDL}
          onProvinceChange={(id, label) => onChangeProvince(id, label)}
          onDistrictChange={(id, label) => onChangeDistrict(id, label)}
          onSubDistrictChange={(id, label) => onChangeSubDistrict(id, label)}
          isDisabled={props.isDisabled} />
      </Row>
      <Row className='mt-3 align-items-center'>
        <Col sm={12}
          md={6}>
          <Input
            label='ระหว่าง'
            value={tenantBuyerData?.clientBetween}
            onChange={(value) => onTextChange('clientBetween', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row className='mt-3 align-items-center'>
        <Col sm={12}
          md={6}>
          <Input
            label='โดย'
            value={tenantBuyerData?.clientBy}
            onChange={(value) => onTextChange('clientBy', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
    </Card>
  );
}
