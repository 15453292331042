import { Breadcrumb } from 'components';
import StepProgress from 'components/StepProgress';
import { useRef } from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

const BREADCRUMB_INFO = [
  {
    routerName: '/pp/pp0202', label: 'บันทึกอนุมัติยกเลิกแต่งตั้ง',
  },
  { routerName: '/pp/pp0202/detail', label: 'รายละเอียดบันทึกอนุมัติยกเลิกแต่งตั้ง' },
];

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

function AddPP0201() {
  const childRef = useRef<ProgressBarRefType>(null);
  const obj = [{
    title: <p>ข้อมูลบันทึกอนุมัติ
      ยกเลิกแต่งตั้ง
    </p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p>คณะกรรมการจัดทำร่างขอบเขตของงาน</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>อำนาจอนุมัติ</p>,
    displayIndex: '3',
    child: <Step3 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>ตัวอย่าง
      เอกสารบันทึกอนุมัติยกเลิกแต่งตั้ง
    </p>,
    displayIndex: '4',
    child: <Step4 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();
  const backToIndex = () => {
    navigate('/pp/pp0202');
  };

  return (
    <div className="document">
      <p className="text-start fs-4 mb-0 text-primary">บันทึกอนุมัติยกเลิกแต่งตั้ง</p>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12} className="mb-2">
          <StepProgress progressBarData={obj} ref={childRef} />
        </Col>
      </Row>
    </div>
  );
}

export default AddPP0201;
