import { SectionApproverModel } from 'models/sectionApproverModel';
import http from 'utils/axios';

const getDataAsync = async (id: string) =>
  await http.get(`/sectionApprover/${id}`);

const getPaginatedAsync = async (size: number, page: number, sectionId?: string, processType?: string, refBankOrder?: string, budget?: number) =>
  await http.get('/sectionApprover', { params: { size, page, sectionId, processType, refBankOrder, budget } });

const createSectionApproverAsync = async (data: SectionApproverModel) => {
  const formData = new FormData();
  formData.append('SectionId', data.sectionId);
  formData.append('ProcessType', data.processType);
  formData.append('RefBankOrder', data.refBankOrder);
  formData.append('Remark', data.remark || '');
  formData.append('Budget', data.budget.toString());
  formData.append('BuHeadId', data.buHeadId);
  formData.append('BuGroupId', data.buGroupId || '');
  formData.append('BuLineId', data.buLineId || '');
  formData.append('BuDepartmentId', data.buDepartmentId || '');
  formData.append('BuCenterId', data.buCenterId || '');
  formData.append('BuZoneId', data.buZoneId || '');
  formData.append('BuSegmentId', data.buSegmentId || '');
  formData.append('BuBranchId', data.buBranchId || '');
  formData.append('BuSubBranchId', data.buSubBranchId || '');

  return await http.post('/sectionApprover', formData);
};

const updateSectionApproverAsync = async (data: SectionApproverModel) => {
  const params = {
    sectionId: data.sectionId,
    processType: data.processType,
    refBankOrder: data.refBankOrder,
    remark: data.remark,
    budget: data.budget,
    buHeadId: data.buHeadId,
    buGroupId: data.buGroupId,
    buLineId: data.buLineId,
    buDepartmentId: data.buDepartmentId,
    buCenterId: data.buCenterId,
    buZoneId: data.buZoneId,
    buSegmentId: data.buSegmentId,
    buBranchId: data.buBranchId,
    buSubBranchId: data.buSubBranchId,
  }

  return await http.put(`/sectionApprover/${data.id}`, params);
};

const deleteSectionApproverAsync = async (id: string) => {
  return await http.delete(`/sectionApprover/${id}`);
};

const getBuHeadDDLAsync = async () => {
  return await http.get('/buSelection/buHead');
};

const getBuGroupDDLAsync = async () => {
  return await http.get('/buSelection/buGroup');
};

const getBuLineDDLAsync = async () => {
  return await http.get('/buSelection/buLine');
};

const getBuDepartmentDDLAsync = async () => {
  return await http.get('/buSelection/buDepartment');
};

const getBuCenterDDLAsync = async () => {
  return await http.get('/buSelection/buCenter');
};

const getBuZoneDDLAsync = async () => {
  return await http.get('/buSelection/buZone');
};

const getBuSegmentDDLAsync = async () => {
  return await http.get('/buSelection/buSegment');
};

const getBuBranchDDLAsync = async () => {
  return await http.get('/buSelection/buBranch');
};

const getBuSubBranchDDLAsync = async () => {
  return await http.get('/buSelection/buSubBranch');
};

export default {
  getDataAsync,
  getPaginatedAsync,
  createSectionApproverAsync,
  updateSectionApproverAsync,
  deleteSectionApproverAsync,
  getBuHeadDDLAsync,
  getBuGroupDDLAsync,
  getBuLineDDLAsync,
  getBuDepartmentDDLAsync,
  getBuCenterDDLAsync,
  getBuZoneDDLAsync,
  getBuSegmentDDLAsync,
  getBuBranchDDLAsync,
  getBuSubBranchDDLAsync,
};