import Pagination, { PaginationRefType } from 'components/Pagination';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Table as TableBT } from 'react-bootstrap';
import { MdOutlineSearchOff } from 'react-icons/md';

interface Props {
  children?: JSX.Element | JSX.Element[];
  total?: number;
  onChange?: (size: number, page: number) => void;
  className?: string;
  hidePagination?: boolean;
  notResponse?: boolean;
  textWrap?: boolean;
}

const Table = forwardRef<PaginationRefType, Props>((props, ref) => {
  const [noData, setNodata] = useState<boolean>(false);
  const childRef = useRef<PaginationRefType>(null);

  useImperativeHandle(ref, () => ({
    PaginationClear() {
      childRef.current?.PaginationClear();
    },

    CountRowClear() {
      childRef.current?.CountRowClear();
    }
  }));

  const handlerPaginationOnChange = (size: number, page: number) => {
    if (props.onChange) {
      props.onChange(size, page);
    }
  };

  const checkNodata = (value: JSX.Element[] | undefined) => {
    if (value === undefined) {
      return true;
    }
    if (value.length === 0) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (Array.isArray(props.children)) {
      const tbodyChildren = props.children.find((i) => i.type === 'tbody');

      (checkNodata(tbodyChildren?.props.children)) ? setNodata(true) : setNodata(false);
    }
  }, [props.children]);

  return (
    <>
      <div className={`${props.notResponse ? '' : 'w-100 overflow-x-auto table-responsive'} ${props.className}`}>
        <TableBT borderless
          striped
          className={`${props.textWrap ? 'text-wrap' : 'text-nowrap'}`}>
          {props.children}
        </TableBT>
        {noData
          ? <h5 className='text-center py-5'><MdOutlineSearchOff className='me-2 fs-4' />" ไม่พบข้อมูล "</h5>
          : <></>}
      </div>
      {!props.hidePagination
        ? <Pagination total={props.total || 0}
          onChange={handlerPaginationOnChange}
          ref={childRef} />
        : <></>}
    </>
  );
});

export { Table };