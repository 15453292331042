import React, {
  useEffect,
  useState,
} from 'react';
import { Col } from 'react-bootstrap';
import { Selector } from '../../../../../../components';
import {
  district,
  provinces,
  subdistrict,
} from '../../../../../../data';
import { ItemModel, ProvincesModel } from 'models';
import provincesServices from 'services/provincesServices';

interface ILocationProps {
  provinceId?: string;
  districtId?: string;
  subDistrictId?: string;
  onProvinceChange?: (value: string, label: string) => void;
  onDistrictChange?: (value: string, label: string) => void;
  onSubDistrictChange: (value: string, label: string) => void;
  isDisabled?: boolean;
  provincesDDL?: ItemModel[];
}

export function LocationDataHandler(props: ILocationProps) {
  const [districtList, setDistrictList] = useState<{ label: string, value: string }[]>([]);
  const [subDistrictList, setSubDistrictList] = useState<{ label: string, value: string }[]>([]);
  const [districts, setDistrict] = useState<ProvincesModel[]>([]);
  const [subDistrics, setSubDistrics] = useState<ProvincesModel[]>([]);

  useEffect(() => {
    if (props.provinceId) {
      getDistrictData(props.provinceId);
    }
  }, [props.provinceId]);

  useEffect(() => {
    if (props.districtId) {
      getSubDistrictData(props.districtId);
    }
  }, [props.districtId]);

  // const getProvinceData = () => setProvinceList([
  //   ...provinces.sort((a, b) => {
  //     if (a.name_th < b.name_th) { return -1; }
  //     if (a.name_th > b.name_th) { return 1; }
  //     return 0;
  //   }).map((p) => ({
  //     label: p.name_th,
  //     value: p.id.toString(),
  //   }))]);

  const onChangeProvince = (value: string) => {
    if (props.onProvinceChange) {
      const provinceName = props.provincesDDL?.filter(p => p.value.toString() === value)[0].label;
      if (provinceName) {
        props.onProvinceChange(value, provinceName);
      }
    }

    getDistrictData(value);
  };

  const getDistrictData = async (provinceId: string) => {

    const itemForMap: ItemModel[] = [];
    const response = await provincesServices.getDistrictListAsync(provinceId);
    const items: ProvincesModel[] = response.data;

    setDistrict(items);

    items.sort((a, b) => {
      if (a.nameTh < b.nameTh) { return -1; }
      if (a.nameTh > b.nameTh) { return 1; }
      return 0;
    })
      .forEach((data) =>
        itemForMap.push({
          label: data.nameTh,
          value: data.code ?? '',
        })
      );

    setDistrictList(itemForMap);
  };

  const getSubDistrictData = async (districtId: string) => {

    const itemForMap: ItemModel[] = [];
    const response = await provincesServices.getSubDistrictListAsync(districtId);
    const items: ProvincesModel[] = response.data;

    setSubDistrics(items);

    items.sort((a, b) => {
      if (a.nameTh < b.nameTh) { return -1; }
      if (a.nameTh > b.nameTh) { return 1; }
      return 0;
    })
      .forEach((data) =>
        itemForMap.push({
          label: data.nameTh,
          value: data.code ?? '',
        })
      );

    setSubDistrictList(itemForMap);

    if (props.onDistrictChange) {
      const districtName = districts.filter(p => p.code.toString() === districtId)[0].nameTh;
      props.onDistrictChange(districtId, districtName);
    }
  };

  const onChangeSubDistrict = async (val: string) => {

    if (props.onSubDistrictChange) {
      const districtName = subDistrics.filter(p => p.code.toString() === val)[0].nameTh;
      props.onSubDistrictChange(val, districtName);
    }
  };

  return (
    <>
      <Col sm={4}>
        <Selector
          value={props.provinceId}
          label='จังหวัด'
          items={props.provincesDDL}
          onChange={onChangeProvince}
          placeholder='กรุณาเลือก'
          disabled={props.isDisabled} />
      </Col>
      <Col sm={4}>
        <Selector value={props.districtId}
          label='อำเภอ/เขต'
          placeholder='กรุณาเลือก'
          items={districtList}
          onChange={getSubDistrictData}
          disabled={props.isDisabled} />
      </Col>
      <Col sm={4}>
        <Selector value={props.subDistrictId}
          label='ตำบล/แขวง'
          placeholder='กรุณาเลือก'
          items={subDistrictList}
          onChange={onChangeSubDistrict}
          disabled={props.isDisabled} />
      </Col>
    </>
  );
}
