import {
  Modal,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { ContractCompleteReportSummaryModel } from 'models/CM/ContractCompleteReportModel';
import {
  useEffect,
  useState
} from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import ContractCompleteReportService from 'services/CM/ContractCompleteReportService';
import { HttpStatusCode, THCurrency } from 'utils';

interface Props {
  show: boolean;
  year?: number;
  quarter: number;
  contractCompleteReportId: string;
  onHide?: () => void;
}

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});


enum sumData {
  sum100 = 100,
}

export function SummaryModal(props: Props) {
  const [summarys, setSummarys] = useState<ContractCompleteReportSummaryModel[]>([]);
  useEffect(() => {
    if (props.year && props.show && props.quarter) {
      onGetContractCompleteReportSummaryAsync(props.year, props.quarter);
    }
  }, [props.year, props.show, props.quarter]);

  const onGetContractCompleteReportSummaryAsync = async (year: number, quarter: number) => {
    const { data, status } = await ContractCompleteReportService.getContractCompleteReportSummaryAsync(year, quarter);
    if (status === HttpStatusCode.OK) {
      setSummarys(data);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size="xl"
    >
      <Modal.Header>
        <Title
          text="ตารางสรุปการทำสัญญาแล้วเสร็จไตรมาส"
          className="fs-5 text-primary"
        />
      </Modal.Header>
      <ModalBT.Body className="p-0 ps-4">
        <Table
          className="my-3"
          hidePagination
        >
          <thead>
            <tr>
              <th style={{ borderBottom: 'none' }}></th>
              {props.quarter! >= 1 &&
                <th colSpan={2} style={{ borderBottom: 'none' }}>
                  สัญญาแล้วเสร็จ<br />ไตรมาส 1/{props.year?.toString()}
                </th>
              }
              {props.quarter! >= 2 &&
                <th colSpan={2} style={{ borderBottom: 'none' }}>
                  สัญญาแล้วเสร็จ<br />ไตรมาส 2/{props.year?.toString()}
                </th>
              }
              {props.quarter! >= 3 &&
                <th colSpan={2} style={{ borderBottom: 'none' }}>
                  สัญญาแล้วเสร็จ<br />ไตรมาส 3/{props.year?.toString()}
                </th>
              }
              {props.quarter! >= 4 &&
                <th colSpan={2} style={{ borderBottom: 'none' }}>
                  สัญญาแล้วเสร็จ<br />ไตรมาส 4/{props.year?.toString()}
                </th>
              }
              <th colSpan={2} style={{ borderBottom: 'none' }}>
                สัญญาแล้วเสร็จทั้งหมด<br />{props.year?.toString()}
              </th>
            </tr>
            <tr>
              <th>รายการสัญญาเรียงลำดับ<br />ตามประเภทสัญญา</th>
              {props.quarter! >= 1 &&
                <>
                  <th style={{ color: '#333' }}>จำนวน(ฉบับ)</th>
                  <th style={{ color: '#333' }}>เปอร์เซ็นต์</th>
                </>
              }
              {props.quarter! >= 2 &&
                <>
                  <th style={{ color: '#333' }}>จำนวน(ฉบับ)</th>
                  <th style={{ color: '#333' }}>เปอร์เซ็นต์</th>
                </>
              }
              {props.quarter! >= 3 &&
                <>
                  <th style={{ color: '#333' }}>จำนวน(ฉบับ)</th>
                  <th style={{ color: '#333' }}>เปอร์เซ็นต์</th>
                </>
              }
              {props.quarter! >= 4 &&
                <>
                  <th style={{ color: '#333' }}>จำนวน(ฉบับ)</th>
                  <th style={{ color: '#333' }}>เปอร์เซ็นต์</th>
                </>
              }
              <th style={{ color: '#333' }}>จำนวน(ฉบับ)</th>
              <th style={{ color: '#333' }}>เปอร์เซ็นต์</th>
            </tr>
          </thead>
          <tbody>
            <>
            </>
            {
              summarys?.sort((a, b) => a.Sequence - b.Sequence).map((data, index) => (
                <tr key={index}>
                  <td className='text-center'>{data.contractTypeName}</td>
                  {props.quarter! >= 1 &&
                    <>
                      <td className='text-center'>{data.countQuarter1}</td>
                      <td className='text-end'>{data.countQuarter1 > 0 ? formatter.format(((data.countQuarter1 * sumData.sum100) / summarys?.reduce((a, v) => a += v.countQuarter1, 0))) : 0}%</td>
                    </>
                  }
                  {props.quarter! >= 2 &&
                    <>
                      <td className='text-center'>{data.countQuarter2}</td>
                      <td className='text-end'>{data.countQuarter2 > 0 ? formatter.format(((data.countQuarter2 * sumData.sum100) / summarys?.reduce((a, v) => a += v.countQuarter2, 0))) : 0}%</td>
                    </>
                  }
                  {props.quarter! >= 3 &&
                    <>
                      <td className='text-center'>{data.countQuarter3}</td>
                      <td className='text-end'>{data.countQuarter3 > 0 ? formatter.format(((data.countQuarter3 * sumData.sum100) / summarys?.reduce((a, v) => a += v.countQuarter3, 0))) : 0}%</td>
                    </>
                  }
                  {props.quarter! >= 4 &&
                    <>
                      <td className='text-center'>{data.countQuarter4}</td>
                      <td className='text-end'>{data.countQuarter4 > 0 ? formatter.format(((data.countQuarter4 * sumData.sum100) / summarys?.reduce((a, v) => a += v.countQuarter4, 0))) : 0} %</td>
                    </>
                  }
                  <td className='text-center'>{data.sumQuarter}</td>
                  <td className='text-end'>{data.sumQuarter > 0 ? formatter.format(((data.sumQuarter * sumData.sum100) / summarys?.reduce((a, v) => a += v.sumQuarter, 0))) : 0}%</td>
                </tr>
              ))
            }
          </tbody>
          <tfoot>
            <tr style={{ borderTop: '2px solid #FF850D', backgroundColor: '#F3F3F3' }}>
              <td className='text-center' style={{ color: '#FF850D' }}>รวมสัญญาทั้งสิ้น</td>
              {props.quarter! >= 1 &&
                <>
                  <td className='text-center'>
                    {summarys?.reduce((a, v) => a += v.countQuarter1, 0)}
                  </td>
                  <td className='text-end'>
                    {formatter.format(summarys?.reduce((a, v) => a += v.countQuarter1, 0) > 0 ? summarys?.reduce((a, v) => a += (v.countQuarter1 * sumData.sum100) / summarys?.reduce((a, v) => a += v.countQuarter1, 0), 0) : 0)}%
                  </td>
                </>
              }
              {props.quarter! >= 2 &&
                <>
                  <td className='text-center'>
                    {summarys?.reduce((a, v) => a += v.countQuarter2, 0)}
                  </td>
                  <td className='text-end'>
                    {formatter.format(summarys?.reduce((a, v) => a += v.countQuarter2, 0) > 0 ? summarys?.reduce((a, v) => a += (v.countQuarter2 * sumData.sum100) / summarys?.reduce((a, v) => a += v.countQuarter2, 0), 0) : 0)}%
                  </td>
                </>
              }
              {props.quarter! >= 3 &&
                <>
                  <td className='text-center'>
                    {summarys?.reduce((a, v) => a += v.countQuarter3, 0)}
                  </td>
                  <td className='text-end'>
                    {formatter.format(summarys?.reduce((a, v) => a += v.countQuarter3, 0) > 0 ? summarys?.reduce((a, v) => a += (v.countQuarter3 * sumData.sum100) / summarys?.reduce((a, v) => a += v.countQuarter3, 0), 0) : 0)}%
                  </td>
                </>
              }
              {props.quarter! >= 4 &&
                <>
                  <td className='text-center'>
                    {summarys?.reduce((a, v) => a += v.countQuarter4, 0)}
                  </td>
                  <td className='text-end'>
                    {formatter.format(summarys?.reduce((a, v) => a += v.countQuarter4, 0) > 0 ? summarys?.reduce((a, v) => a += (v.countQuarter4 * sumData.sum100) / summarys?.reduce((a, v) => a += v.countQuarter4, 0), 0) : 0)}%
                  </td>
                </>
              }
              <td className='text-center'>
                {summarys?.reduce((a, v) => a += v.sumQuarter, 0)}
              </td>
              <td className='text-end'>
                {formatter.format(summarys?.reduce((a, v) => a += v.sumQuarter, 0) > 0 ? summarys?.reduce((a, v) => a += (v.sumQuarter * sumData.sum100) / summarys?.reduce((a, v) => a += v.sumQuarter, 0), 0) : 0)}%
              </td>
            </tr>
          </tfoot>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}