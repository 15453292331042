import {
  Breadcrumb,
  ProgressBarRefType,
} from 'components';
import StepProgress from 'components/StepProgress';
import { PublicPlanProcurement } from 'models';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { publicPlanAnnouncement as publicService } from 'services';
import {
  HttpStatusCode,
  useAppContext,
} from 'utils';
import { AnnouncementStatus as AnnouncementCons } from 'utils/constants/PlanEnum';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

const BREADCRUMB_INFO = [
  {
    routerName: '/procurement-plan-public', label: 'ขออนุมัติเผยแพร่แผนจัดซื้อจัดจ้าง',
  },
  {
    routerName: '', label: 'รายละเอียดการขออนุมัติเผยแพร่แผนจัดซื้อจัดจ้าง',
  },
];

type PublicPlanProcurementDetailContext = {
  currentStep: number;
  publicPlanProcurement: PublicPlanProcurement;
  setPublicPlanProcurement: Dispatch<SetStateAction<PublicPlanProcurement>>;
  reGetDetail: (id: string) => void;
  readonly: boolean;
};

export const Context = createContext({} as PublicPlanProcurementDetailContext);

type Loader = {
  publicPlanProcurement: PublicPlanProcurement;
}

export default function PublicDetail() {
  const { userId } = useAppContext();
  const loader = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [stepStatus, setStepStatus] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const stepRef = useRef<ProgressBarRefType>({} as ProgressBarRefType);
  const [publicPlanProcurement, setPublicPlanProcurement] = useState<PublicPlanProcurement>({} as PublicPlanProcurement);
  const [mounted, setMounted] = useState<boolean>(false);

  // find step
  useEffect(() => {
    if (!mounted && userId && Object.keys(publicPlanProcurement).length) {
      findStepFromStatus(publicPlanProcurement.status);
      if (publicPlanProcurement.jorporDirectorAssign?.some((d) => d.userId === userId)) {
        if (publicPlanProcurement.status === AnnouncementCons.Draft) {
          setCurrentStep(1);
          setMounted(true);
          return;
        }
      }

      if (publicPlanProcurement.sectionHeadJorpor?.some((j) => j.userId === userId)
        || publicPlanProcurement.assistantDirectorJorpor?.some((j) => j.userId === userId)
        || publicPlanProcurement.directorJorpor?.some((j) => j.userId === userId)
        || publicPlanProcurement.assistantMd?.some((j) => j.userId === userId)
        || publicPlanProcurement.deputyMd?.some((j) => j.userId === userId)
        || publicPlanProcurement.md?.some((j) => j.userId === userId)) {
        setCurrentStep(3);
        setMounted(true);
        return;
      }

      switch (publicPlanProcurement.status) {
        case AnnouncementCons.Draft: setCurrentStep(1); setMounted(true); return;
        case AnnouncementCons.AssignJorPor: setCurrentStep(1); setMounted(true); return;
        case AnnouncementCons.WaitingAccept: setCurrentStep(3); setMounted(true); return;
        case AnnouncementCons.WaitingAcceptor: setCurrentStep(3); setMounted(true); return;
        case AnnouncementCons.Accepted: setCurrentStep(3); setMounted(true); return;
        case AnnouncementCons.Reject: setCurrentStep(1); setMounted(true); return;
        case AnnouncementCons.Approve: setCurrentStep(3); setMounted(true); return;
      }
    }
  }, [userId, publicPlanProcurement, mounted]);

  useEffect(() => {
    if (loader.publicPlanProcurement) {
      setPublicPlanProcurement(loader.publicPlanProcurement);
    } else {
      setPublicPlanProcurement({
        ...publicPlanProcurement,
        status: AnnouncementCons.Draft,
      });
    }
  }, [loader]);

  const steps = [
    {
      title: <p>มอบหมายงาน</p>,
      displayIndex: '1',
    },
    {
      title: <p>ลำดับการเห็นชอบ</p>,
      displayIndex: '2',
    },
    {
      title: <p>ส่งผอ.จพ.</p>,
      displayIndex: '3',
    },
  ];

  const findStepFromStatus = (status: string) => {
    switch (status) {
      case AnnouncementCons.Draft: setStepStatus(1); break;
      case AnnouncementCons.AssignJorPor: setStepStatus(2); break;
      case AnnouncementCons.WaitingAccept: setStepStatus(3); break;
      case AnnouncementCons.Reject: setStepStatus(3); break;
      case AnnouncementCons.Accepted: setStepStatus(3); break;
    }
  };

  const backToIndex = () => navigate('/procurement-plan-public');

  const progressBar = useCallback(() => (
    <StepProgress
      progressBarData={steps}
      ref={stepRef}
      step={stepStatus}
    />
  ), [currentStep, stepStatus]);

  const reGetDetail = async (id: string) => {
    const res = await publicService
      .getDetailDataAsync(id);

    if (res.status === HttpStatusCode.OK) {
      setPublicPlanProcurement(res.data.planProcurementAnnouncement);
    }
  };

  const readonly = useMemo(() =>
    publicPlanProcurement.status === AnnouncementCons.WaitingAccept
    || publicPlanProcurement.status === AnnouncementCons.Accepted,
    [publicPlanProcurement]);

  const contextValue = {
    currentStep,
    publicPlanProcurement,
    setPublicPlanProcurement,
    reGetDetail,
    readonly,
  };

  return (
    <Context.Provider value={contextValue}>
      <h4 className="text-primary">ขออนุมัติเผยแพร่แผนจัดซื้อจัดจ้าง</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      {progressBar()}
      {currentStep === 1
        ? (
          <Step1
            key={1}
            onClickBack={() => backToIndex()}
            onClickNext={() => setCurrentStep(2)}
          />
        ) : null}
      {currentStep === 2
        ? (
          <Step2
            key={2}
            onClickBack={() => setCurrentStep(1)}
            onClickNext={() => setCurrentStep(3)}
          />
        ) : null}
      {currentStep === 3
        ? (
          <Step3
            key={3}
            onClickBack={() => setCurrentStep(2)}
          />
        ) : null}
    </Context.Provider>
  );
}
