import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Currency,
  Radio,
  Readonly,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ConfessionModel, RetentionModel, ServiceDetailModel } from '../../../../../../models/CM/CM02Models';
import { ItemModel } from 'models';
import { InputNumber } from 'components/Controls/InputNumber';
import { ValidatePattern } from 'utils';

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

interface IProps {
  title?: string;
  contractTemplateCode: string;
  retention: RetentionModel;
  onRetentionChange: (value: RetentionModel) => void;
  isDisabled?: boolean;
  advanceDeductionTypeDDL: ItemModel[];
}

const RetentionINFO = [
  {
    label: 'ต้องการ',
    value: 'true',
  },
  {
    label: 'ไม่ต้องการ',
    value: 'false',
  },
];

const fields = [
  {
    code: 'CF01',
    hasRetention: 'ต้องการหักเงินประกันผลงานหรือไม่',
    percent: 'จำนวนร้อยละ ของเงินที่ต้องจ่ายในงวดนั้น',
    amount: 'จำนวนเงิน (บาท)'
  },
  {
    code: 'CF02',
  },
  {
    code: 'CF03',
  },
  {
    code: 'CF04',
  },
  {
    code: 'CF05',
  },
  {
    code: 'CF06',
  },
  {
    code: 'CF07',
  },
  {
    code: 'CF08',
  },
  {
    code: 'CF09',
  },
  {
    code: 'CF10',
  },
  {
    code: 'CF11',
  },
  {
    code: 'CF12',
  },
  {
    code: 'CF13',
  },
  {
    code: 'CF14',
  },
  {
    code: 'CF15',
    hasRetention: 'ต้องการหักเงินประกันผลงานหรือไม่',
    percent: 'จำนวนร้อยละ ของเงินที่ต้องจ่ายในงวดนั้น',
    amount: 'จำนวนเงิน ของเงินที่ต้องจ่ายในงวดนั้น'
  },
];

export function RetentionDetail(props: IProps) {
  const [retention, setRetention] = useState<RetentionModel>(props.retention);
  const [isDisabledRetention, setIsDisabledRetention] = useState<boolean>(true);

  useEffect(() => {
    if (retention) {
      setRetention(retention);
    }
  }, [retention]);

  useEffect(() => {
    if (props.retention) {
      setIsDisabledRetention(!props.retention.hasRetention);
      setRetention(props.retention);
    } else {
      setIsDisabledRetention(true);
    }
  }, [props.retention]);

  useEffect(() => {
    if (retention) {
      props.onRetentionChange(retention);
    }
  }, [retention]);

  const onTextChange = useCallback((key: string, value: string) => {
    setRetention(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setRetention(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const hasRetentionChange = useCallback((value: string | number) => {
    setRetention(prevState => ({
      ...prevState,
      hasRetention: value === 'true',
    }));

    setIsDisabledRetention(value !== 'true');
  }, []);

  return (
    <Card className='mt-3'>
      <Title text={`สัญญา${props.title ? props.title : 'การหักเงินประกันผลงาน'}`} className='fs-5 text-primary' />
      <>
        {
          fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
            <>
              {
                data.hasRetention && (
                  <Row>
                    <Col sm={12}
                      md={12}>
                      <Radio
                        dflex
                        label={data.hasRetention}
                        items={RetentionINFO}
                        value={retention ? retention.hasRetention?.toString() : 'false'}
                        rule={{ required: true }}
                        onChange={(value) => hasRetentionChange(value)}
                        disabled={props.isDisabled}
                        name='hasRetention' />
                    </Col>
                    <Col sm={8}
                      md={4}>
                      <Currency
                        label={data.percent}
                        value={retention?.retentionPercent}
                        onChange={(value) => onNumberChange('retentionPercent', Number(value))}
                        rule={{ pattern: ValidatePattern.PERCENTAGE }}
                        disabled={props.isDisabled || isDisabledRetention} />
                    </Col>
                    <Col sm={8}
                      md={4}>
                      <Currency
                        // className='no-label'
                        label={data.amount}
                        value={retention?.retentionAmount}
                        onChange={(value) => onNumberChange('retentionAmount', Number(value))}
                        disabled={props.isDisabled || isDisabledRetention} />
                    </Col>
                    {/* <Col sm={8}
                      md={6}>
                      <Selector
                        label='การหักเงินประกันผลงาน'
                        items={props.advanceDeductionTypeDDL}
                        value={retention?.retentionDeductionType}
                        onChange={(value) => onTextChange('retentionDeductionType', value)}
                        disabled={props.isDisabled || isDisabledRetention} />
                    </Col> */}
                  </Row>
                )
              }
            </>
          )
        }
      </>
    </Card>
  );
}
