import { Table } from 'components';
import { Input } from 'components/Controls';
import { BadgeStatus } from 'components/Controls/BadgeisActive';
import { DepartmentResponse } from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEdit,
  FaEraser,
  FaPlus,
  FaSearch,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';
import department from 'services/department';

export default function SU03() {
  const [keyword, setKeyword] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [currentSize, setCurrentSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [departmentList, setDepartmentList] = useState<DepartmentResponse[]>([]);
  const navigate = useNavigate();

  const getDepartmentListAsync = useCallback(() => {
    (async () => {
      const response = await department.getDepartmentListAsync(page, size, keyword);

      setDepartmentList(response.data.data);
      setTotalRecords(response.data.totalRecords);
      setCurrentSize(size);
    })();
  }, [page, size, keyword]);

  useEffect(() => {
    getDepartmentListAsync();
  }, [page, size]);

  const clearData = () => {
    setKeyword('');
    (async () => {
      const response = await department.getDepartmentListAsync(page, size, '');

      setDepartmentList(response.data.data);
      setTotalRecords(response.data.totalRecords);
    })();
  };

  return (
    <div className="m03">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">ฝ่าย/สำนัก</h4>
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={() => navigate('detail')}
        >
          <FaPlus />เพิ่มฝ่าย/สำนัก
        </Button>
      </div>
      <hr />
      <Row className="criteria">
        <Col lg={8} xl={6} className="d-flex gap-2">
          <Input label="ค้นหา" value={keyword} onChange={setKeyword} placeholder="ชื่อย่อฝ่าย/ชื่อฝ่าย/สำนัก" />
          <div className="d-flex gap-2 button-inline">
            <Button variant="primary" className="d-flex align-items-center gap-2" onClick={getDepartmentListAsync}>
              <FaSearch />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              className="d-flex align-items-center gap-2"
              onClick={clearData}
            >
              <FaEraser />ล้าง
            </Button>
          </div>
        </Col>
      </Row>
      <Table
        className="mt-4"
        total={totalRecords}
        onChange={(size, page) => (
          setSize(size),
          setPage(size === currentSize ? page : 1)
        )}
      >
        <thead>
          <tr>
            <th style={{ minWidth: 75 }}>ลำดับ</th>
            <th style={{ minWidth: 100 }}>กลุ่มงาน</th>
            <th style={{ minWidth: 100 }}>สายงาน</th>
            <th style={{ minWidth: 100 }}>ชื่อย่อฝ่าย</th>
            <th style={{ minWidth: 350 }}>ชื่อฝ่าย/สำนัก</th>
            <th style={{ minWidth: 200 }}>สถานะ</th>
            <th style={{ minWidth: 50 }} />
          </tr>
        </thead>
        <tbody>
          {departmentList.map((data, index) => (
            <tr key={index}>
              <td className="text-center">{((page - 1) * size) + index + 1}</td>
              <td className="text-center">{data.workGroupName ? data.workGroupName : '-'}</td>
              <td className="text-center">{data.businessUnitName ? data.businessUnitName : '-'}</td>
              <td className="text-center">{data.shortCode}</td>
              <td className="text-wrap">{data.name}</td>
              <td className="text-center"><BadgeStatus status={data.active} /></td>
              <td className="text-end">
                <Button
                  variant="outline-primary"
                  className="gap-2"
                  onClick={() => navigate(`detail/${data.id}`)}
                >
                  <FaEdit className="me-2 mb-1" />แก้ไข
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
