import {
  Check,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  ItemModelBadge,
  Pagination,
} from 'models';
import {
  createContext,
  Dispatch,
  useContext,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { generateUniqueId } from '../../../utils/helper';

type CM0212ListContext = {
  statusNameArray: ItemModelBadge[];
  pagination: Pagination
  setStatusNameArray: Dispatch<React.SetStateAction<ItemModelBadge[]>>;
  setPagination: Dispatch<React.SetStateAction<Pagination>>;
};

const Context = createContext({} as CM0212ListContext);

export default function CM0212() {
  const [statusNameArray, setStatusNameArray] = useState<ItemModelBadge[]>([
    { label: 'ทั้งหมด', value: 'All', count: 10 },
    { label: 'ประกาศเผยแพร่', value: 'Announcement', count: 5 },
    { label: 'เห็นชอบ/อนุมัติ', value: 'ApprovePlan', count: 5 },
  ]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    size: 10,
    totalRecords: 5,
  });

  const conTextValue = {
    statusNameArray,
    pagination,
    setStatusNameArray,
    setPagination,
  };

  return (
    <Context.Provider value={conTextValue}>
      <CM0212Header />
      <CM0212Criteria />
      <CM0212Datable />
    </Context.Provider>
  );
}

function CM0212Header() {
  const navigate = useNavigate();

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>รายงานสำนักงานตรวจเงินแผ่นดินและหนังสือถึงสรรพากร</h4>
        <Button onClick={() => navigate('detail')}>
          <MdAdd className='fs-5 me-2' />สร้างรายงานสำนักงานตรวจเงินแผ่นดินและหนังสือสรรพากร
        </Button>
      </div>
      <hr />
    </>
  );
}

function CM0212Criteria() {
  const { statusNameArray, setStatusNameArray } = useContext(Context);

  return (
    <>
      <Row className='criteria'>
        <Col sm={12} md={4} xl={3}>
          <Input
            label='เลขที่คำขอเผยแพร่แผนจัดซื้อจัดจ้าง'
            placeholder='เลขที่คำขอเผยแพร่แผนจัดซื้อจัดจ้าง' />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Selector label='ปีที่เริ่มต้น' placeholder='ปีที่เริ่มต้น' />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Selector label='ปีที่สิ้นสุด' placeholder='ปีที่สิ้นสุด' />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Selector label='เดือน' placeholder='ม.ค. - ธ.ค.' />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col sm={12}>
          สถานะ
          <div className='d-flex gap-2'>
            {statusNameArray?.map((v, i) => (
              <Check
                key={generateUniqueId(i)}
                label={(
                  <>
                    {v.label}
                    <Status type={StatusType.PROCESS} value={v.value} couting={v.count} badge />
                  </>
                )}
              />
            ))}
          </div>
        </Col>
      </Row>
      <div className='d-flex gap-2'>
        <Button
          type='submit'
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => { }}>
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant='outline-primary'
          className='d-flex align-items-center gap-2'
          onClick={() => { }}>
          <FaEraser />ล้าง
        </Button>
      </div>
    </>
  );
}

function CM0212Datable() {
  const { pagination, setPagination } = useContext(Context);
  const navigate = useNavigate();
  const goToPage = () => navigate('detail');

  return (
    <Table total={pagination.totalRecords}
      className='mt-4'
      onChange={(size, page) => (setPagination({ ...pagination, page, size }))}>
      <thead>
        <tr className='text-center'>
          <th>เลขที่เอกสาร</th>
          <th>สถานะ</th>
          <th>ปี</th>
          <th>เดือน</th>
          <th>จำนวนโครงการ</th>
          <th>งบประมาณรวม</th>
          <th>วิธีจัดหา</th>
          <th>วันที่เผยแพร่</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 5 }).map((_, index) => (
          <tr className='text-center' key={generateUniqueId(index)}>
            <td>
              <Button variant='link' onClick={goToPage}>OAG66000{index + 1}</Button>
            </td>
            <td>
              <Status type={StatusType.PROCESS}
                value={index < 3 ? 'Announcement' : 'ApprovePlan'} />
            </td>
            <td>
              2566
            </td>
            <td>
              ม.ค. - มี.ค.
            </td>
            <td>
              {index < 2 ? 5 : index < 4 ? 6 : 10}
            </td>
            <td>
              {index < 2 ? '5,000,000' : index < 4 ? '10,000,000' : '20,000,000'}
            </td>
            <td>
              พ.ร.บ.จัดซื้อจัดจ้างฯ 2560
            </td>
            <td>
              {index < 2 ? '15/02/2566' : index < 4 ? '20/04/2566' : '18/06/2566'}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
