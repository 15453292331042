import { Card } from 'components';
import Title from 'components/Controls/Title';
import { HistoryModal } from 'components/Modal';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  FaAlignLeft,
  FaHistory,
  FaSave,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { dataHistory } from '../data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step3({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);

  return (
    <div className="document">

      <Card className="mt-3">
        <div className="d-flex justify-content-between my-3">
          <Title text="เอกสารหนังสือสั่งซื้อสั่งจ้างสั่งเช่า" className="fs-5 text-primary" />
          <Button
            className="px-3"
            onClick={() => setShow(true)}
            variant="light"
          >
            <FaHistory className="me-3" />ประวัติการแก้ไข
          </Button>
        </div>
      </Card>
      <div className="d-flex justify-content-center gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          variant="light"
          className="me-2 px-3"
        >
          <FaSave className="ms-2 pb-1 fs-5" />
          บันทึก
        </Button>
        <Button
          className="me-2 px-3"
        >
          <FaAlignLeft className="me-2 pb-1 fs-5" />
          ส่งอนุมัติ
        </Button>
      </div>
      <HistoryModal
        show={show}
        onHide={() => setShow(!show)}
        data={dataHistory}
      />
    </div>
  );
}

export default Step3;
