import { LoaderFunctionArgs } from 'react-router-dom';
import {
  department,
  medianPrice,
  plan,
  shareValue,
  termOfRef,
} from 'services';
import PL01Services from 'services/PL/PL0201Service';
import { HttpStatusCode } from 'utils';

export const pp0204Loader = async () => {
  const [planRes, departmentRes, supplyMethodRes, supplyMethodTypeRes, torLists] = await Promise.all([
    await plan.getPlanListAsync(10, 1),
    await department.getDepartmentsAsync(),
    await shareValue.getSupplyMethodListAsync(),
    await shareValue.getSupplyMethodTypeListAsync(),
    await termOfRef.getTorListAsync(1, 10),
  ]);

  const plans = {
    data: planRes.data.data,
    totalRecords: planRes.data.totalRecords,
  };

  const departments = departmentRes.data?.map((d: { id: string, name: string }) => ({
    label: d.name,
    value: d.id,
  }));

  return {
    plans,
    departments,
    supplyMethods: supplyMethodRes.data,
    supplyMethodTypes: supplyMethodTypeRes.data,
    torData: torLists.data,
  };
};

// export const pp0204MedianPriceDetail = async ({ params }: LoaderFunctionArgs) => {
//   const { planId } = params;
//   const [departmentRes, supplyMethodRes, supplyMethodTypeRes, planDropDownRes] = await Promise.all([
//     await department.getDepartmentsAsync(),
//     await shareValue.getSupplyMethodListAsync(),
//     await shareValue.getSupplyMethodTypeListAsync(),
//     await PL01Services.GetPlanDropDownListAsync(),
//   ]);

//   const departments = departmentRes.data?.map((d: { id: string, name: string }) => ({
//     label: d.name,
//     value: d.id,
//   }));

//   if (planId) {
//     const [planRes, medianPriceRes] = await Promise.all([
//       await medianPrice.getPlanSingleAsync(planId),
//       await medianPrice.getMedianPriceSingleAsync(planId),
//     ]);

//     return {
//       planData: planRes.data,
//       medianPriceData: medianPriceRes.status === HttpStatusCode.OK ? medianPriceRes.data : null,
//       departments,
//       supplyMethods: supplyMethodRes.data,
//       supplyMethodTypes: supplyMethodTypeRes.data,
//       planDropDownList: planDropDownRes.data,
//     };
//   }
//   return {
//     departments,
//     supplyMethods: supplyMethodRes.data,
//     supplyMethodTypes: supplyMethodTypeRes.data,
//     planDropDownList: planDropDownRes.data,
//   };
// };

export const pp0204TorDetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const [departmentRes, supplyMethodRes, supplyMethodTypeRes, planDropDownRes] = await Promise.all([
    await department.getDepartmentsAsync(),
    await shareValue.getSupplyMethodListAsync(),
    await shareValue.getSupplyMethodTypeListAsync(),
    await PL01Services.GetPlanDropDownListAsync(),
  ]);

  const departments = departmentRes.data?.map((d: { id: string, name: string }) => ({
    label: d.name,
    value: d.id,
  }));

  if (id) {
    const response = await termOfRef.getTorDetailAsync(id);

    if (response.status === HttpStatusCode.OK) {
      return {
        torDetail: response.data,
        departments,
        supplyMethods: supplyMethodRes.data,
        supplyMethodTypes: supplyMethodTypeRes.data,
        planDropDownList: planDropDownRes.data,
      };
    }

    return {
      departments,
      supplyMethods: supplyMethodRes.data,
      supplyMethodTypes: supplyMethodTypeRes.data,
      planDropDownList: planDropDownRes.data,
    };
  }

  return {
    departments,
    supplyMethods: supplyMethodRes.data,
    supplyMethodTypes: supplyMethodTypeRes.data,
    planDropDownList: planDropDownRes.data,
  };
};
