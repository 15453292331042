import Cookie from 'cookie-universal';
import {
  PL0207Criteria,
  PL0207SaveModel,
} from 'models/PL/PL0207Models';
import http from 'utils/axios';

const cookies = Cookie();

const getFullName = cookies.get('fullName') as string;

const getListAsync = async (
  page: number,
  size: number,
  criteria?: PL0207Criteria,
) => {
  const params = {
    page,
    size,
    ...criteria,
  };
  return await http.get('/recorddocument', { params });
};

const getDetailAsync = async (id: string | undefined) => await http.get(`/recorddocument/${id}`);

const updateRecDocumentAsync = async (id: string | undefined, data: PL0207SaveModel) => await http.put(`/recorddocument/${id}`, data);

export default {
  getListAsync,
  getDetailAsync,
  updateRecDocumentAsync,
};
