import {
  Card,
  ContractAgreementStatus,
  DatePicker,
  Input,
  Table,
  Modal,
  ButtonCustom,
  InputTextArea,
  SearchBuNameModal,
  ArrowCollapse,
  Status,
  StatusType,
} from 'components';
import Title from 'components/Controls/Title';
import {
  ContractAgreementAcceptorModel,
  ContractAgreementDocumentArchivedModel,
  ContractAgreementModel,
  CreateEditingContractAgreementModel,
  UpdateAcceptorStep4Model,
  UpdateContractDateStep5Model,
  UpdateDocBoxNumberStep4Model,
} from 'models/CM/CM02Models';
import { sectionApproverModel } from 'models/delegateModel';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaPlus,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdHistory,
  MdSave,
  MdSegment,
} from 'react-icons/md';
import { account, shareValue } from 'services';
import CM02Service from 'services/CM/CM02Service';
import {
  fullDatetimeTH,
  HttpStatusCode,
  ProcurementSection,
  submitForm,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { Context } from '.';
import Collabora, { CollaboraRef } from '../../../../components/Document/Collabora';
import { HistoryModal } from '../Modal/HistoryModal';
import { UploadModal } from '../Modal/UploadModal';
import { BsCheckCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { Acceptors } from 'components/Table/Acceptors';
import { JorPorCode } from 'constant/basicInformation';
import { AcceptorStatus } from 'utils/constants/PlanEnum';
import { ItemModel, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { ApproverContractStatus, ContractAgreementTypeAcceptor } from 'constant/ContractStatus';
import { isNull } from 'utils/helper';
import { SharedvalueGroup } from 'utils/constants/ContractEnum';
import { ContractStartDatePeriodConditionValue2Enum } from 'utils/constants/ShareValueEnum';
import { JorPorCommentModal } from 'components/Modal/JorPorCommentModal';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

export default function Step3({
  onClickNext,
  onClickBack,
}: Props) {
  const {
    dataContext,
    setDataContext,
  } = useContext(Context);
  const [contractData, setContractData] = useState<ContractAgreementModel>({} as ContractAgreementModel);
  const { userId, departmentCode } = useAppContext();
  const [acceptorsApprove, setAcceptorsApprove] = useState<ContractAgreementAcceptorModel[]>([]);
  const [responsibles, setResponsibles] = useState<ContractAgreementAcceptorModel[]>([]);
  const [assignees, setAssignees] = useState<ContractAgreementAcceptorModel[]>([]);
  const [docSignId, setDocSignId] = useState('');
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [remarkEditing, setRemarkEditing] = useState('');
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const collaboraRef = useRef<CollaboraRef>(null);
  const [documentArchives, setDocumentArchives] = useState<ContractAgreementDocumentArchivedModel[]>([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [docId, setDocId] = useState('');
  const [docChangeContractId, setDocChangeContractId] = useState('');
  const [docPDPAId, setDocPDPAId] = useState('');
  const collaboraRefChange = useRef<CollaboraRef>(null);
  const collaboraRefContractChange = useRef<CollaboraRef>(null);
  const collaboraPDPARefChange = useRef<CollaboraRef>(null);
  const [sectionType, setSectionType] = useState<string>();
  const [sectionSequence, setSectionSequence] = useState<number>();
  const [showModals, setShowModals] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [showConfirmAssign, setShowConfirmAssign] = useState(false);
  const [showConfirmJorPor, setShowConfirmJorPor] = useState(false);
  const [isJorPorDirector, setIsJorPorDirector] = useState<boolean>(false);
  const [isAssignee, setIsAssignee] = useState<boolean>(false);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);
  const [showAssigneeModal, setShowAssigneeModal] = useState<boolean>(false);
  const [showResponsibleModal, setShowResponsibleModal] = useState<boolean>(false);
  const [openCollapse, setOpenCollapse] = useState(!dataContext.isChange);
  const [openCollapse2, setOpenCollapse2] = useState(!dataContext.isChange);
  const [openCollapse3, setOpenCollapse3] = useState(!dataContext.isChange);
  const [showWaitingForApprove, setShowWaitingForApprove] = useState(false);
  const [rejectRemark, setRejectRemark] = useState('');
  const [approveRemark, setApproveRemark] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showAcceptorApproveModal, setShowAcceptorApproveModal] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isPDPADocuemnt, setIsPDPADocuemnt] = useState(false);
  const navigate = useNavigate();
  const [showAddJorPorComment, setShowAddJorPorComment] = useState(false);
  const [jorPorCommentId, setJorPorCommentId] = useState<string>('');

  const goToPage = (contractId: string, id: string) => {
    navigate(`/contract-agreement/${contractId}/JorPorComment/${id}`);
  };

  const getAppendix = async () => {
    const contractTemplate = dataContext.subContractTemplate ? dataContext.subContractTemplate : dataContext.contractTemplate;

    const resData = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractAppendix, contractTemplate);
    const datas: ItemModel[] = resData.data;

    const isPDPA = dataContext.documents.filter((d) => {
      return datas.some((s) => {
        return s.value === d.type && (s.value2 === ContractStartDatePeriodConditionValue2Enum.สัญญาการรักษาข้อมูลที่เป็นความลับ || s.value2 === ContractStartDatePeriodConditionValue2Enum.สัญญาการประมวลผลข้อมูลส่วนบุคคล);
      });
    });

    setIsPDPADocuemnt(isPDPA.length > 0);
  };

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  useEffect(() => {

    if (dataContext) {

      if (dataContext.documents) {
        getAppendix();
      }

      if (dataContext.isChange) {
        if (dataContext.acceptorsApprove) {
          setAcceptorsApprove(dataContext.acceptorsApprove);
          setIsApprover(onCheckMessageApprover(dataContext.acceptorsApprove));
        }

        if (dataContext.jorPorDirectors) {
          if (dataContext.jorPorDirectors.filter(x => x.delegateUserId === userId).length > 0) {
            setIsJorPorDirector(true);
          } else {
            setIsJorPorDirector(dataContext.jorPorDirectors.filter(x => x.userId === userId).length > 0);
          }
        }

        if (dataContext.assignees) {
          setAssignees(dataContext.assignees);
          if (dataContext.assignees.filter(x => x.delegateUserId === userId).length > 0) {
            setIsAssignee(true);
          } else {
            setIsAssignee(dataContext.assignees.filter(x => x.userId === userId).length > 0);
          }
        }

        if (dataContext.responsibles) {
          setResponsibles(dataContext.responsibles);
          if (dataContext.responsibles.filter(x => x.delegateUserId === userId).length > 0) {
            setIsResponsible(true);
          } else {
            const isResp = dataContext.responsibles.filter(x => x.userId === userId).length > 0;
            setIsResponsible(isResp);
          }
        }
      }

      if (dataContext.contractAgreementDocumentArchives) {
        setDocumentArchives(dataContext.contractAgreementDocumentArchives);
      }

      if (dataContext.status === ContractAgreementStatus.WaitingForAttachSignedDocument
        || (dataContext.isChange && (dataContext.status === ContractAgreementStatus.Draft
          || dataContext.status === ContractAgreementStatus.Rejected
          || dataContext.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign
          || dataContext.status === ContractAgreementStatus.WaitingForAssignResponsible
          || dataContext.status === ContractAgreementStatus.DraftSectionApprover))) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
    setContractData(dataContext);
  }, [dataContext]);

  const onCheckMessageApprover = (data: ContractAgreementAcceptorModel[]) => {

    const approver = data?.filter(x => x.status === ApproverContractStatus.PENDING).length === 1;

    if (approver) {
      return true;
    }

    return false;
  };

  const getDocumentSign = useCallback(async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getContractSignDocumentAsync(id);
    if (status === HttpStatusCode.OK) {
      setDocSignId(data);
    }
  }, [setDocSignId]);

  const getDocumentPDPA = useCallback(async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getDocumentPDPAAsync(id);

    if (status === HttpStatusCode.OK) {
      setDocPDPAId(data);
    }
  }, [setDocPDPAId]);

  const getDocument = useCallback(async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getDocumentAsync(id);
    if (status === HttpStatusCode.OK) {
      setDocId(data);
    }
  }, [setDocId]);

  const getDocumentChangeContractAsync = useCallback(async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getDocumentChangeContractAsync(id);
    if (status === HttpStatusCode.OK) {
      setDocChangeContractId(data);
    }
  }, [setDocChangeContractId]);

  useEffect(() => {
    if (dataContext.id) {
      getDocumentSign(dataContext.id);
      getDocument(dataContext.id);
      getDocumentPDPA(dataContext.id);

      if (dataContext.isChange) {
        getDocumentChangeContractAsync(dataContext.id);
      }
    }
  }, [dataContext.id,
    getDocumentSign,
    getDocument,
    getDocumentPDPA,
    getDocumentChangeContractAsync,
  ]);

  const isQueueApprover = useMemo(() => {
    if (dataContext && dataContext.acceptorsApprove && userId) {
      const minSection = Math.min(...dataContext?.acceptorsApprove
        .filter(d => d.status === ApproverContractStatus.PENDING)
        .map(o => o.sectionSequence));

      const sectionData = dataContext?.acceptorsApprove
        .filter(d => d.sectionSequence === minSection
          && d.status === ApproverContractStatus.PENDING)
        .sort((a, b) => a.sequence - b.sequence)[0];

      if (sectionData) {
        return isNull(sectionData.delegateUserId, sectionData.userId) === userId;
      }
    }

  }, [userId, dataContext]);

  const onSelectEmpApprover = (empId: string, name: string, department: string, position: string, index: number, sectionSequence?: number, sectionName?: string, inRefCode?: string, sectionType?: string, delegateUserId?: string, delegateFullName?: string, delegateDepartmentName?: string, delegatePositionName?: string, delegateEmployeeCode?: string) => {
    if (acceptorsApprove && acceptorsApprove.filter((x) => x.inRefCode === inRefCode && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const newData: ContractAgreementAcceptorModel = {
        id: undefined,
        sectionSequence: sectionSequence!,
        sectionName: sectionName!,
        userId: empId,
        sequence: acceptorsApprove.filter((x) => x.sectionSequence === sectionSequence).length + 1,
        fullName: name,
        isAvailable: false,
        availableRemark: '',
        departmentName: department,
        type: ContractAgreementTypeAcceptor.AcceptorApprover,
        status: AcceptorStatus.DRAFT,
        seqKey: `${sectionSequence?.toString()}${(acceptorsApprove.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        sectionInRefCode: inRefCode!,
        acceptDate: undefined,
        acceptRemark: '',
        positionName: position,
        rejectDate: undefined,
        rejectRemark: '',
        delegateUserId: delegateUserId,
        delegateFullName: delegateFullName,
        delegateDepartmentName: delegateDepartmentName,
        delegatePositionName: delegatePositionName,
        delegateEmployeeCode: delegateEmployeeCode,
        inRefCode: inRefCode,
        sectionType: sectionType,
      };

      setAcceptorsApprove((acceptorsApprove) => [...acceptorsApprove, newData]);
    }
  };


  const onSaveEditing = async (isChang: boolean, isCancel: boolean) => {
    submitForm();

    if (remarkEditing === undefined || remarkEditing === '' || remarkEditing === null) {
      return;
    }

    if (contractData.id) {
      const createEditingData: CreateEditingContractAgreementModel = {
        remark: remarkEditing,
        isCancel: isCancel,
        IsChange: isChang,
      };

      const response = await CM02Service.createEditingContractAgreementAsync(contractData.id, createEditingData);
      if (response.status !== HttpStatusCode.CREATED) {
        toast.error('ไม่สามารถบันทึกแก้ไขสัญญาได้');
        setShowEditConfirmModal(false);
        return;
      }

      const newId: string = response.data.id;
      toast.success('บันทึกแก้ไขสัญญาสำเร็จ');
      navigate(`/edit-contract-agreement/detail/${newId}`);
      getContractByIdAsync(newId);
      setShowEditConfirmModal(false);
    }
  };

  const onSave = async (isSignContract: boolean, isJorPor: boolean, isAssignee: boolean, isApprove: boolean) => {

    if ((contractData.contractDate === undefined
      || contractData.contractDate === null) && isSignContract) {
      toast.warn('กรุณากรอกวันที่ลงนาม');

      return;
    }

    setShowSaveConfirmModal(false);
    setShowApproveModal(false);
    setShowConfirmAssign(false);
    setShowConfirmJorPor(false);
    setShowWaitingForApprove(false);

    // if (contractData.isChange
    //   && (contractData.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign
    //     || contractData.status === ContractAgreementStatus.WaitingForAssignResponsible
    //     || contractData.status === ContractAgreementStatus.DraftSectionApprover
    //   )) {

    //   if (contractData.status === ContractAgreementStatus.DraftSectionApprover
    //     && (dataContext.commentDescription === undefined
    //       || dataContext.commentDescription === null)) {
    //     toast.warn('กรุณาระบุความเห็นเพิ่มเติม');

    //     return;
    //   }

    //   if ((!assignees || assignees.length === 0) && contractData.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign) {
    //     toast.warn('กรุณาเลือก ผอ.จพ. มอบหมาย');
    //     return;
    //   }

    //   if ((!responsibles || responsibles.length === 0) && contractData.status === ContractAgreementStatus.WaitingForAssignResponsible) {
    //     toast.warn('กรุณาเลือกผู้รับผิดชอบ');
    //     return;
    //   }

    //   if ((!acceptorsApprove || acceptorsApprove.length === 0) && contractData.status === ContractAgreementStatus.DraftSectionApprover) {
    //     toast.warn('กรุณาเลือกผู้มีอำนาจเห็นชอบอย่างน้อย 1 คน');
    //     return;
    //   }

    //   acceptorsApprove.sort((a, b) => a.sequence - b.sequence)
    //     .forEach((data, index) => {
    //       data.sequence = index + 1;
    //       data.type = ContractAgreementTypeAcceptor.AcceptorApprover;
    //     });

    //   assignees.sort((a, b) => a.sequence - b.sequence)
    //     .forEach((data, index) => {
    //       data.sequence = index + 1;
    //     });

    //   responsibles.sort((a, b) => a.sequence - b.sequence)
    //     .forEach((data, index) => {
    //       data.sequence = index + 1;
    //     });

    //   const saveDatas = [...dataContext.contractAgreementAcceptors, ...acceptorsApprove, ...assignees, ...responsibles, ...dataContext.jorPorDirectors];
    //   const updateAcceptor: UpdateAcceptorStep4Model = {
    //     acceptors: saveDatas,
    //     sendToApprove: isApprove,
    //     sendToAssignee: isAssignee,
    //     sendToJorPor: isJorPor,
    //     commentDescription: dataContext.commentDescription,
    //   }

    //   const res = await CM02Service.updateAcceptorStep4Async(contractData.id, updateAcceptor);
    //   if (res.status !== HttpStatusCode.OK) {
    //     toast.error('ไม่สามารถบันทึกข้อมูลได้');

    //     return;
    //   }
    // }

    if (contractData.documentBoxNumber) {
      const updateBoxData: UpdateDocBoxNumberStep4Model = {
        documentBoxNumber: contractData.documentBoxNumber,
      };

      const response = await CM02Service.updateDocumentBoxNumberStep4Async(contractData.id, updateBoxData);
      if (response.status !== HttpStatusCode.OK) {
        toast.error('ไม่สามารถบันทึกข้อมูลเลขที่กล่องเอกสารได้');

        return;
      }
    }

    if (contractData.contractDate && (contractData.status === ContractAgreementStatus.WaitingForAttachSignedDocument || (contractData.isChange && contractData.status === ContractAgreementStatus.Draft || contractData.status === ContractAgreementStatus.Rejected))) {
      const updateData: UpdateContractDateStep5Model = {
        contractDate: contractData.contractDate,
        confirmContractSigning: isSignContract,
      };

      if (contractData.status === ContractAgreementStatus.WaitingForAttachSignedDocument || (contractData.isChange && contractData.status === ContractAgreementStatus.Draft || contractData.status === ContractAgreementStatus.Rejected)) {
        const {
          data,
          status,
        } = await CM02Service.updateContractDateStep5Async(contractData.id, updateData);
        if (status !== HttpStatusCode.OK) {
          toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

          return;
        }
      }
    }

    if (dataContext.isChange) {
      setTimeout(async () => {
        collaboraRefContractChange.current?.clickSave();

        await CM02Service.updateContractDocumentChangeByIdAsync(contractData.id, docChangeContractId);
        getDocumentChangeContractAsync(dataContext.id);
      }, 500);

    } else {
      collaboraRef.current?.clickSave();
      setTimeout(async () => {
        await CM02Service.updateContractSignDocumentByIdAsync(contractData.id, docSignId);
        getDocumentSign(dataContext.id)
      }, 500);
    }

    toast.success('บันทึกข้อมูลสัญญาสำเร็จ');
    getContractByIdAsync(contractData.id);
  };

  const getContractByIdAsync = async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getContractByIdAsync(id);
    if (status === HttpStatusCode.OK) {
      setDataContext(data);
    }
  };

  const removeDocument = async (id: string, index: number) => {
    if (contractData && contractData.id) {
      const res = await CM02Service.removeFileAsync(
        contractData.id,
        id,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        const temp: ContractAgreementDocumentArchivedModel[] = [...documentArchives];
        temp.splice(index, 1);
        setDocumentArchives(temp);

        toast.success('ลบไฟล์สำเร็จ');
        getContractDocumentArchiveByIdAsync(contractData.id);
      }
    }

  };

  const onCloseUploadModal = (docs: ContractAgreementDocumentArchivedModel[]) => {
    setShowUploadModal(!showUploadModal);
    setDocumentArchives(docs);
  };

  const getContractDocumentArchiveByIdAsync = async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getDocumentByTypeAsync(id, 'Archive');
    if (status === HttpStatusCode.OK) {
      const attachments = data.attachments as ContractAgreementDocumentArchivedModel[];
      setDocumentArchives(attachments);
      setDataContext({
        ...dataContext,
        contractAgreementDocumentArchives: attachments,
      });
    }
  };

  const handlerRemoveAssignee = (index: number) => {
    const temp: ContractAgreementAcceptorModel[] = [...assignees];
    temp.splice(index, 1);
    setAssignees(temp);
  };

  const handlerRemoveResponsible = (index: number) => {
    const temp: ContractAgreementAcceptorModel[] = [...responsibles];
    temp.splice(index, 1);
    setResponsibles(temp);
  };

  const onSelectAssignee = (empId: string, name: string, position: string, department: string, sectionSequence: number, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
    delegatePositionName?: string, delegateAnnotation?: string, delegateDateRange?: string) => {
    if (assignees && assignees.filter(x => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผอ.จพ. มอบหมายซ้ำ');
    } else {

      const newData: ContractAgreementAcceptorModel = {
        id: undefined,
        sectionSequence: sectionSequence!,
        sectionName: '',
        userId: empId,
        sequence: assignees.filter((x) => x.sectionSequence === sectionSequence).length + 1,
        fullName: name,
        isAvailable: false,
        availableRemark: '',
        departmentName: department,
        type: ContractAgreementTypeAcceptor.Assignee,
        status: AcceptorStatus.DRAFT,
        seqKey: `${sectionSequence?.toString()}${(assignees.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        sectionInRefCode: '',
        acceptDate: undefined,
        acceptRemark: '',
        positionName: position,
        rejectDate: undefined,
        rejectRemark: '',
        delegateUserId: delegateUserId,
        delegateFullName: delegateFullName,
        delegateDepartmentName: delegateDepartmentName,
        delegatePositionName: delegatePositionName,
        delegateEmployeeCode: delegateEmployeeCode,
      };

      setAssignees((assignees) => [...assignees, newData]);
    }
  };


  const onSelectResponsible = (empId: string, name: string, position: string, department: string, sectionSequence: number, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
    delegatePositionName?: string, delegateAnnotation?: string, delegateDateRange?: string) => {

    if (responsibles && responsibles.filter(x => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้รับผิดชอบซ้ำ');
    } else {

      const newData: ContractAgreementAcceptorModel = {
        id: undefined,
        sectionSequence: sectionSequence!,
        sectionName: '',
        userId: empId,
        sequence: responsibles.filter((x) => x.sectionSequence === sectionSequence).length + 1,
        fullName: name,
        isAvailable: false,
        availableRemark: '',
        departmentName: department,
        type: ContractAgreementTypeAcceptor.Responsible,
        status: AcceptorStatus.DRAFT,
        seqKey: `${sectionSequence?.toString()}${(responsibles.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        sectionInRefCode: '',
        acceptDate: undefined,
        acceptRemark: '',
        positionName: position,
        rejectDate: undefined,
        rejectRemark: '',
        delegateUserId: delegateUserId,
        delegateFullName: delegateFullName,
        delegateDepartmentName: delegateDepartmentName,
        delegatePositionName: delegatePositionName,
        delegateEmployeeCode: delegateEmployeeCode,
      };

      setResponsibles((responsibles) => [...responsibles, newData]);
    }
  };

  const onReject = async () => {
    if (!rejectRemark) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    const res = await CM02Service.onRejectAsync(dataContext.id, rejectRemark);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
      return;
    }

    getContractByIdAsync(contractData.id);
    toast.success('ส่งกลับแก้ไขสำเร็จ');
    setShowRejectModal(false);
    setRejectRemark('');
  };

  const onApprove = async () => {
    const res = await CM02Service.approveStep4Async(dataContext.id, approveRemark);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('อนุมัติไม่สำเร็จ');
      return;
    }

    setApproveRemark('');
    getContractByIdAsync(contractData.id);
    toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
    setShowAcceptorApproveModal(false);
  };

  const openModalDelete = (id: string) => {
    setShowDeleteModal(true);
    setJorPorCommentId(id);
  };

  const deleteJorPorCommentAsync = async (id: string) => {

    if (id) {
      const response = await await CM02Service.deleteContractAgreementJorPorCommantAsync(id);
      if (response.status === HttpStatusCode.OK) {

        toast.success('ลบรายการสำเร็จ');

      } else {
        toast.error('เกิดข้อผิดพลาด');
      }

    }

    getContractByIdAsync(contractData.id);
    setShowDeleteModal(false);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title
          text='วันที่ลงนามในสัญญา'
          className='fs-5 text-primary'
          extraElement={(
            <Button variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}>
              <MdHistory /> ประวัติการแก้ไข
            </Button>
          )}
        />
        <Row className='mt-3'>
          <Col sm={12}
            md={3}>
            <DatePicker label='วันที่ลงนามในสัญญา'
              value={contractData.contractDate}
              rule={{ required: true }}
              onChangeHasNullable={(val) => setContractData({
                ...contractData,
                contractDate: val,
              })}
              disabled={isDisabled} />
          </Col>
        </Row>
      </Card>
      {/* {
        (dataContext.isChange && (dataContext.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign
          || dataContext.status === ContractAgreementStatus.WaitingForAssignResponsible
          || dataContext.status === ContractAgreementStatus.DraftSectionApprover
          || dataContext.status === ContractAgreementStatus.WaitingForSign
          || dataContext.status === ContractAgreementStatus.Signed
        )) && (
          <>
            <Card className='mt-3'>
              <div className='d-flex mb-4 align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Title text='ผอ.จพ.'
                    className='fs-5 text-primary' />
                </div>
              </div>
              <div>
                <Table
                  hidePagination
                  className='mt-3 step-bar'
                  notResponse
                >
                  <thead>
                    <tr>
                      <th style={{ width: '5%' }}>ลำดับ</th>
                      <th className='text-start'
                        style={{ width: '30%' }}>ชื่อ-นามสกุล
                      </th>
                      <th className='text-start'
                        style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                      </th>
                      <th style={{ minHeight: 100, width: '25%' }} />
                    </tr>
                  </thead>
                  <tbody>
                    {dataContext.jorPorDirectors?.sort((a, b) => a.sequence - b.sequence)
                      .map((data, i) => (
                        <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                          key={data.id}>
                          <td className='text-center'>{i + 1}</td>
                          <td>
                            {data.fullName}
                            <p className='m-0 department'>
                              {data.positionName}
                            </p>
                          </td>
                          <td className='text-left'>
                            {data.delegateFullName &&
                              <>
                                {data.delegateFullName}
                                <p className='m-0 department'>
                                  {data.delegatePositionName}
                                </p>
                              </>
                            }
                          </td>
                          <td />
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Card>
            <Card className='mt-3'>
              <div className='d-flex mb-4 align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Title text='ผอ.จพ. มอบหมาย'
                    className='fs-5 text-primary' />
                </div>
              </div>
              <div>
                {
                  (isJorPorDirector && dataContext.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign) && (
                    <div className='d-flex justify-content-end'>
                      <Button
                        className='d-flex align-items-center gap-2'
                        variant='outline-primary'
                        onClick={() => setShowAssigneeModal(true)}
                      >
                        <FaPlus />เพิ่มรายชื่อ
                      </Button>
                    </div>
                  )
                }
                <Table
                  hidePagination
                  className='mt-3 step-bar'
                  notResponse
                >
                  <thead>
                    <tr>
                      <th style={{ width: '5%' }}>ลำดับ</th>
                      <th className='text-start'
                        style={{ width: '30%' }}>ชื่อ-นามสกุล
                      </th>
                      <th className='text-start'
                        style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                      </th>
                      <th style={{ minHeight: 100, width: '25%' }} />
                    </tr>
                  </thead>
                  <tbody>
                    {assignees?.sort((a, b) => a.sequence - b.sequence)
                      .map((data, i) => (
                        <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                          key={data.id}>
                          <td className='text-center'>{i + 1}</td>
                          <td>
                            {data.fullName}
                            <p className='m-0 department'>
                              {data.positionName}
                            </p>
                          </td>
                          <td className='text-left'>
                            {data.delegateFullName &&
                              <>
                                {data.delegateFullName}
                                <p className='m-0 department'>
                                  {data.delegatePositionName}
                                </p>
                              </>
                            }
                          </td>
                          <td className='text-end'>
                            {((dataContext.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign) && isJorPorDirector) &&
                              <Button
                                variant='danger'
                                onClick={() => handlerRemoveAssignee(i)}
                              >
                                <FaTrashAlt />
                              </Button>}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Card>
            {(dataContext.status === ContractAgreementStatus.WaitingForAssignResponsible
              || dataContext.status === ContractAgreementStatus.DraftSectionApprover
              || dataContext.status === ContractAgreementStatus.WaitingForSign
              || dataContext.status === ContractAgreementStatus.Signed) &&
              (
                <>
                  <Card className='mt-3'>
                    <>
                      <div className='d-flex mb-4 align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                          <Title text='ผู้รับผิดชอบ'
                            className='fs-5 text-primary' />
                        </div>
                      </div>
                      <div>
                        <div className='d-flex justify-content-end'>
                          {
                            (isAssignee && dataContext.status === ContractAgreementStatus.WaitingForAssignResponsible) && (
                              <Button
                                className='d-flex align-items-center gap-2'
                                variant='outline-primary'
                                onClick={() => setShowResponsibleModal(true)}
                              >
                                <FaPlus />เพิ่มรายชื่อ
                              </Button>
                            )
                          }
                        </div>
                        <Table
                          hidePagination
                          className='mt-3 step-bar'
                          notResponse
                        >
                          <thead>
                            <tr>
                              <th style={{ width: '5%' }}>ลำดับ</th>
                              <th className='text-start'
                                style={{ width: '30%' }}>ชื่อ-นามสกุล
                              </th>
                              <th className='text-start'
                                style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                              </th>
                              <th style={{ minHeight: 100, width: '25%' }} />
                            </tr>
                          </thead>
                          <tbody>
                            {responsibles?.sort((a, b) => a.sequence - b.sequence)
                              .map((data, i) => (
                                <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                                  key={data.id}>
                                  <td className='text-center'>{i + 1}</td>
                                  <td>
                                    {data.fullName}
                                    <p className='m-0 department'>
                                      {data.positionName}
                                    </p>
                                  </td>
                                  <td className='text-left'>
                                    {data.delegateFullName &&
                                      <>
                                        {data.delegateFullName}
                                        <p className='m-0 department'>
                                          {data.delegatePositionName}
                                        </p>
                                      </>
                                    }
                                  </td>
                                  <td className='text-end'>
                                    {((dataContext.status === ContractAgreementStatus.WaitingForAssignResponsible) && isAssignee) &&
                                      <Button
                                        variant='danger'
                                        onClick={() => handlerRemoveResponsible(i)}
                                      >
                                        <FaTrashAlt />
                                      </Button>}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  </Card>
                </>
              )}

            {(dataContext.status === ContractAgreementStatus.DraftSectionApprover
              || dataContext.status === ContractAgreementStatus.WaitingForSign
              || dataContext.status === ContractAgreementStatus.Signed) && (
                <>
                  <Card className='mt-3'>
                    <div className='d-flex mb-4 align-items-center justify-content-between'>
                      <div className='d-flex align-items-center'>
                        <Title text='ความเห็น จพ.'
                          className='fs-5 text-primary' />
                      </div>
                    </div>
                    <InputTextArea
                      label='ความเห็นเพิ่มเติม'
                      value={dataContext.commentDescription}
                      onChange={val => setDataContext({ ...dataContext, commentDescription: val })}
                      rule={{ required: true }}
                      disabled={isDisabled || dataContext.status !== ContractAgreementStatus.DraftSectionApprover || !isResponsible} />
                  </Card>
                  <Card className='mt-3'>
                    <Acceptors
                      data={acceptorsApprove.length > 0 ? acceptorsApprove : dataContext.acceptorsApprove}
                      visible={!isDisabled || dataContext.status === ContractAgreementStatus.DraftSectionApprover || !isResponsible}
                      isDepartmentDirector={false}
                      processType={PROCESSTYPE.ApproveSignContractProcess}
                      budgetAmount={dataContext.sumOfferTotalAmountVat}
                      supplyMethodId={dataContext.supplyMethodId}
                      program={ProgramType.ContractAgreement}
                      onSetSectionType={(type) => setSectionType(type)}
                      onAcceptorModalShow={() => setShowModals(!showModals)}
                      onSetSectionSequence={(section) => setSectionSequence(section)}
                      onAcceptorDataChange={(data) => setAcceptorsApprove(data)}
                    />
                  </Card>
                </>
              )}
          </>
        )
      } */}

      {dataContext.isChange && (
        <>
          <Card className='mt-3'>
            <Title
              text='เอกสารบันทึกต่อท้าย'
              className='fs-5 text-primary'
            />
            <div className='mt-3'>
              <Collabora
                docId={docChangeContractId}
                docName='doc-change-03'
                ref={collaboraRefContractChange}
                readonly={false} />
            </div>
          </Card>
          <Card className='mt-3'>
            <div className='d-flex mb-4 align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <Title text='เอกสารร่างสัญญาหรือข้อตกลง'
                  className='fs-5 text-primary' />
                {openCollapse2 ? (
                  <ArrowCollapse onClick={() => setOpenCollapse2(!openCollapse2)} openCollapse={openCollapse2} />
                ) : (
                  <ArrowCollapse onClick={() => setOpenCollapse2(true)} openCollapse={openCollapse2} />)}
              </div>
            </div>
            <Collapse in={openCollapse2}
              className='mt-3 px-2'>
              <div className='mt-3'>
                <Collabora
                  docId={docId}
                  docName='doc-change-01'
                  ref={collaboraRefChange}
                  readonly={contractData.status === ContractAgreementStatus.Signed} />
              </div>
            </Collapse>
          </Card>
          {isPDPADocuemnt && (
            <Card className='mt-3'>
              <div className='d-flex mb-4 align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Title text='เอกสารรักษาความลับ, สัญญา PDPA'
                    className='fs-5 text-primary' />
                  {openCollapse3 ? (
                    <ArrowCollapse onClick={() => setOpenCollapse3(!openCollapse3)} openCollapse={openCollapse3} />
                  ) : (
                    <ArrowCollapse onClick={() => setOpenCollapse3(true)} openCollapse={openCollapse3} />)}
                </div>
              </div>
              <Collapse in={openCollapse3}
                className='mt-3 px-2'>
                <div className='mt-3'>
                  <Collabora
                    docId={docPDPAId}
                    docName='doc-change-02'
                    ref={collaboraPDPARefChange}
                    readonly={false || dataContext.isChange} />
                </div>
              </Collapse>
            </Card>
          )}
        </>
      )}

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='เอกสารร่างสัญญาหรือข้อตกลง'
              className='fs-5 text-primary' />
            {openCollapse ? (
              <ArrowCollapse onClick={() => setOpenCollapse(!openCollapse)} openCollapse={openCollapse} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse(true)} openCollapse={openCollapse} />)}
          </div>
        </div>
        <Collapse in={openCollapse}
          className='mt-3 px-2'>
          <div className='mt-3'>
            <Collabora
              docId={docSignId}
              docName='doc-04'
              ref={collaboraRef}
              readonly={contractData.status === ContractAgreementStatus.Signed} />
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <Title
          text='แนบเอกสารสัญญาและหมายเลขกล่อง'
          className='fs-5 text-primary'
        />
        <Row className='mt-3'>
          <Col sm={12}
            md={6}>
            <Input label='เลขที่กล่องเอกสาร'
              placeholder='เลขที่กล่องเอกสาร'
              value={contractData.documentBoxNumber}
              onChange={(val) => setContractData({
                ...contractData,
                documentBoxNumber: val,
              })} />
          </Col>
          <Col sm={12}
            className='d-flex justify-content-end'>
            <Button
              variant='primary'
              onClick={() => setShowUploadModal(true)}
            >
              <FaPlus className='me-1' /> เพิ่มไฟล์
            </Button>
          </Col>
          <Col sm={12}
            className='mt-3'>
            <Table hidePagination>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>ลำดับ</th>
                  <th className='text-start'
                    style={{ width: '50%' }}>ชื่อไฟล์
                  </th>
                  <th style={{ width: '40%' }}>วันที่แนบ</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  documentArchives.map((data, index) => (
                    <tr key={data.id}>
                      <td className='text-center'>{index + 1}</td>
                      <td>{data.fileName}</td>
                      <td className='text-center'>{fullDatetimeTH(data.createdDate)}</td>
                      <td className='text-center'>
                        <Button
                          variant='danger'
                          onClick={() => removeDocument(data.id, index)}
                        >
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title
          text='ความเห็น จพ.'
          className='fs-5 text-primary'
        />
        <Row className='mt-3'>
          <Col sm={12}
            className='d-flex justify-content-end'>
            <Button
              variant='primary'
              onClick={() => setShowAddJorPorComment(true)}
            >
              <FaPlus className='me-1' /> เพิ่มความเห็น
            </Button>
          </Col>
          <Col sm={12}
            className='mt-3'>
            <Table hidePagination>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>ลำดับ</th>
                  <th style={{ width: '50%' }}>ความเห็น จพ.</th>
                  <th style={{ width: '20%' }}>วันที่</th>
                  <th style={{ width: '20%' }}>สถานะ</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  dataContext.contractAgreementJorPorComments.map((item, index) => (
                    <tr key={item.id}>
                      <td className='text-center'>{index + 1}</td>
                      <td className='text-wrap'>{item.commentDescription}</td>
                      <td className='text-center'>{item.createdDate != null ? fullDatetimeTH(item.createdDate) : ''}</td>
                      <td className='text-center'>
                        <Status type={StatusType.JorPorComment}
                          value={item.status} />
                      </td>
                      <td className='text-center'>
                        <Button
                          variant='success'
                          onClick={() => goToPage(item.contractAgreementId, item.id)}
                        >
                          ไปยังความเห็น จพ.
                        </Button>
                      </td>
                      <td className='text-center'>
                        <Button
                          variant='danger'
                          onClick={() => openModalDelete(item.id)}
                        >
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>

                  ))
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <div>
          {(contractData.isResponsible &&
            (contractData.status === ContractAgreementStatus.WaitingForAttachSignedDocument
              || contractData.status === ContractAgreementStatus.Signed
              || contractData.status === ContractAgreementStatus.WaitingForSign
              || contractData.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign
              || contractData.status === ContractAgreementStatus.DraftSectionApprover)) ||
            (contractData.isChange &&
              ((contractData.status === ContractAgreementStatus.Draft || contractData.status === ContractAgreementStatus.Rejected)
                || (isJorPorDirector && contractData.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign)
                || ((isJorPorDirector || isAssignee) && contractData.status === ContractAgreementStatus.WaitingForAssignResponsible)
                || (isResponsible && contractData.status === ContractAgreementStatus.DraftSectionApprover)))
            ?
            <ButtonCustom
              onClick={() => setShowSaveConfirmModal(true)}
              text="บันทึกเลขที่กล่องเอกสาร" /> : <></>
          }

          {contractData.isResponsible &&
            (contractData.status === ContractAgreementStatus.WaitingForAttachSignedDocument || (contractData.isChange && contractData.status === ContractAgreementStatus.Draft || contractData.status === ContractAgreementStatus.Rejected)) ?
            <ButtonCustom
              onClick={() => setShowApproveModal(true)}
              icon="segment"
              text="ยืนยันวันที่ลงนาม" /> : <></>
          }
          {contractData.isResponsible && contractData.status === ContractAgreementStatus.Signed && !contractData.isInEditing ?
            <ButtonCustom
              onClick={() => setShowEditConfirmModal(true)}
              icon="undo"
              disabled={contractData.isInEditing}
              text="ขอบันทึกต่อท้ายสัญญา" /> : <></>
          }

          {contractData.isChange
            && (isJorPorDirector && contractData.status === ContractAgreementStatus.WaitingForDirectorJorPorAssign) ?
            <ButtonCustom
              onClick={() => setShowConfirmJorPor(true)}
              text="มอบหมาย" /> : <></>
          }

          {contractData.isChange
            && (isAssignee && contractData.status === ContractAgreementStatus.WaitingForAssignResponsible) ?
            <ButtonCustom
              onClick={() => setShowConfirmAssign(true)}
              text="มอบหมายผู้รับผิดชอบ" /> : <></>
          }

          {contractData.isChange
            && (isResponsible && contractData.status === ContractAgreementStatus.DraftSectionApprover) ?
            <ButtonCustom
              onClick={() => setShowWaitingForApprove(true)}
              text='ส่งอนุมัติ/เห็นชอบ' /> : <></>
          }

          {(isQueueApprover && contractData.status === ContractAgreementStatus.WaitingForSign) && (
            <>
              <Button
                className='me-2 px-3'
                onClick={() => setShowRejectModal(true)}
                variant='danger'
              >
                <FaUndo className='mx-2 pb-1 fs-5' />
                ส่งกลับแก้ไข
              </Button>
              <Button
                variant='success'
                className='me-2 px-3'
                onClick={() => setShowAcceptorApproveModal(true)}
              >
                <BsCheckCircle className='me-2 pb-1 fs-5' />
                {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </Button>
            </>
          )}

        </div>
        <div style={{ width: 120 }}></div>
      </div>
      <HistoryModal
        title='ประวัติการใช้งานร่างข้อมูลสัญญา'
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={contractData.id}
      />
      <JorPorCommentModal
        show={showAddJorPorComment}
        onHide={() => setShowAddJorPorComment(!showAddJorPorComment)}
        contractAgreementId={dataContext.id}
        onGet={() => getContractByIdAsync(dataContext.id)}
      />
      <Modal
        show={showSaveConfirmModal}
        size='lg'
        onHide={() => setShowSaveConfirmModal(!showSaveConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการบันทึกข้อมูลหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowSaveConfirmModal(!showSaveConfirmModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(false, false, false, false)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApproveModal}
        size='lg'
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการยืนยันวันที่ลงนามหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApproveModal(!showApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(true, false, false, false)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showEditConfirmModal}
        size='lg'
        onHide={() => setShowEditConfirmModal(!showEditConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title> คุณต้องการขอดำเนินการบันทึกต่อท้ายสัญญาหรือไม่ ?</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRemarkEditing(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowEditConfirmModal(!showEditConfirmModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                onClick={() => onSaveEditing(true, false)}
              >
                <BsCheckCircle className='mx-2 pb-1 fs-4' />
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <UploadModal
        show={showUploadModal}
        onHide={() => setShowUploadModal(!showUploadModal)}
        contractId={contractData.id}
        onUploaded={onCloseUploadModal}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showModals}
        onHide={() => setShowModals(!showModals)}
        onSelect={(id, name, departmentName, position, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName, delegateEmployeeCode) => onSelectEmpApprover(id, name, departmentName!, position!, acceptorsApprove.length + 1, sectionSequence, '', sectionType, sectionType, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName, delegateEmployeeCode)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showAssigneeModal}
        onHide={() => setShowAssigneeModal(!showAssigneeModal)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange) => onSelectAssignee(id, name, position!, department!, assignees.length + 1, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showResponsibleModal}
        onHide={() => setShowResponsibleModal(!showResponsibleModal)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange) => onSelectResponsible(id, name, position!, department!, responsibles.length + 1, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <Modal
        show={showConfirmJorPor}
        size='lg'
        onHide={() => setShowConfirmJorPor(!showConfirmJorPor)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการมอบหมายงานหรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowConfirmJorPor(!showConfirmJorPor)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(true, true, false, false)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showConfirmAssign}
        size='lg'
        onHide={() => setShowConfirmAssign(!showConfirmAssign)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการมอบหมายผู้รับผิดชอบงานหรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowConfirmAssign(!showConfirmAssign)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(true, false, true, false)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showWaitingForApprove}
        size='lg'
        onHide={() => setShowWaitingForApprove(!showWaitingForApprove)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งอนุมัติ/เห็นชอบ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitingForApprove(!showWaitingForApprove)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(false, false, false, true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showAcceptorApproveModal}
        size='lg'
        onHide={() => setShowAcceptorApproveModal(!showAcceptorApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>{isApprover ? 'อนุมัติ' : 'เห็นชอบ'}</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                rule={{ required: false }}
                onChange={(val) => setApproveRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowAcceptorApproveModal(!showAcceptorApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onApprove}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejectModal}
        size='lg'
        onHide={() => setShowRejectModal(!showRejectModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRejectRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowRejectModal(!showRejectModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                variant='danger'
                onClick={onReject}
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showDeleteModal}
        size='lg'
        onHide={() => setShowDeleteModal(!showDeleteModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบรายการ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการลบรายการความเห็น จพ. หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-danger'
                onClick={() => setShowDeleteModal(!showDeleteModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  deleteJorPorCommentAsync(jorPorCommentId);
                }}
              >
                ยืนยันลบข้อมูล
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}
