import { ContractReceiveStatus } from 'components';
import StepProgress from 'components/StepProgress';
import { ContractManagementReceiveModel } from 'models/CM/CM03Models';
import {
  createContext,
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import CM03Service from 'services/CM/CM03Service';
import { HttpStatusCode } from 'utils';
import toast from 'utils/toast';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type ContractContext = {
  data: ContractManagementReceiveModel
  setData: Dispatch<React.SetStateAction<ContractManagementReceiveModel>>,
};

export const Context = createContext({} as ContractContext);

function CreateContractMangementDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const [data, setData] = useState<ContractManagementReceiveModel>({} as ContractManagementReceiveModel);
  const { contractId, receiveId } = useParams();
  const [defaultStep, setDefaultStep] = useState(1);

  useEffect(() => {
    if (contractId && receiveId) {
      getContractManagementAsync(contractId, receiveId);
    }
  }, [receiveId, contractId]);

  const getContractManagementAsync = useCallback(async (contractId: string, receiveId: string) => {
    const { data, status } = await CM03Service.getContractManagementReceiveByIdAsync(contractId, receiveId);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    setData(data);
    setStepByStatus(data.status);
  }, []);

  const setStepByStatus = (status: string) => {
    switch (status) {
      case ContractReceiveStatus.InspectorRejected:
        setDefaultStep(2);
        break;
      case ContractReceiveStatus.WaitForInspectorReceived:
        setDefaultStep(2);
        break;
      case ContractReceiveStatus.JorPorRejected:
        setDefaultStep(3);
        break;
      case ContractReceiveStatus.WaitForJorPorApprove:
        setDefaultStep(3);
        break;
      case ContractReceiveStatus.InspectorReceived:
        setDefaultStep(3);
        break;
      case ContractReceiveStatus.JorPorApproved:
        setDefaultStep(4);
        break;
      case ContractReceiveStatus.Rejected:
        setDefaultStep(4);
        break;
      case ContractReceiveStatus.WaitForApprove:
        setDefaultStep(4);
        break;
      case ContractReceiveStatus.Approved:
        setDefaultStep(4);
        break;
      default:
        break;
    }
  };

  const obj = [{
    title: <p>บันทึกตรวจรับ</p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToDetail()} contractId={contractId!} />,
  },
  {
    title: <p> คณะกรรมการตรวจรับรับทราบ </p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} contractId={contractId!} />,
  },
  {
    title: <p>เจ้าหน้าที่พัสดุให้ความเห็น</p>,
    displayIndex: '3',
    child: <Step3 onClickNext={() => clickNext()} onClickBack={() => clickBack()} contractId={contractId!} />,
  },
  {
    title: <p>ผู้มีอำนาจเห็นชอบ/อนุมัติ</p>,
    displayIndex: '4',
    child: <Step4 onClickNext={() => clickNext()} onClickBack={() => clickBack()} contractId={contractId!} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/contract-management');
  };

  const backToDetail = () => {
    navigate(`/contract-management/detail/${contractId}`);
  };

  return (
    <div className="document">
      <Row>
        <Col xs={12} className="mb-2">
          <Context.Provider value={{ data, setData }}>
            <StepProgress progressBarData={obj} ref={childRef} step={defaultStep} />
          </Context.Provider> <></>
        </Col>
      </Row>
    </div>
  );
}

export default CreateContractMangementDetail;
