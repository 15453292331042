import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { EmploymentAgreementModel } from '../../../../../../models/CM/CM02Models';
import { LocationDataHandler } from './LocationDataHandler';
import { InputNumber } from 'components/Controls/InputNumber';
import moment from 'moment';
import { ItemModel } from 'models';

interface IEmploymentAgreementProps {
  employmentAgreement: EmploymentAgreementModel;
  onEmploymentAgreementChange: (value: EmploymentAgreementModel) => void;
  isDisabled?: boolean;
  provincesDDL: ItemModel[];
  districtDDL: ItemModel[];
  subDistrictDDL: ItemModel[];
}

export function EmploymentAgreementWorkShift(props: IEmploymentAgreementProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [employmentAgreement, setEmploymentAgreement] = useState<EmploymentAgreementModel>({} as EmploymentAgreementModel);

  useEffect(() => {
    props.onEmploymentAgreementChange(employmentAgreement);
  }, [employmentAgreement]);

  useEffect(() => {
    if (props.employmentAgreement && firstLoad) {
      setEmploymentAgreement(props.employmentAgreement);
      setFirstLoad(false);
    }
  }, [props.employmentAgreement, firstLoad]);

  const onTextChange = useCallback((key: string, value: string) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onChangeSubDistrict = useCallback((id: string, label: string) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      subDistrictCode: id,
      subDistrictName: label,
    }));
  }, []);

  const onChangeDistrict = useCallback((id: string, label: string) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      districtCode: id,
      districtName: label,
    }));
  }, []);

  const onChangeProvince = useCallback((id: string, label: string) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      provinceCode: id,
      provinceName: label,
    }));
  }, []);

  const onChangeStartDate = (start?: Date) => {
    if (start && employmentAgreement.endHireDate) {
      const startDate = moment(start);
      const timeEnd = moment(employmentAgreement.endHireDate);
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);

      setEmploymentAgreement({ ...employmentAgreement, startHireDate: start, hireYearTotal: diffDuration.years(), hireMonthTotal: diffDuration.months(), hireDayTotal: diffDuration.days() });
    } else {
      setEmploymentAgreement({ ...employmentAgreement, startHireDate: start, hireYearTotal: 0, hireMonthTotal: 0, hireDayTotal: 0 });
    }
  }

  const onChangeEndDate = (end?: Date) => {
    if (employmentAgreement.startHireDate && end) {
      const startDate = moment(employmentAgreement.startHireDate);
      const timeEnd = moment(end);
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);

      setEmploymentAgreement({ ...employmentAgreement, endHireDate: end, hireYearTotal: diffDuration.years(), hireMonthTotal: diffDuration.months(), hireDayTotal: diffDuration.days() });
    } else {
      setEmploymentAgreement({ ...employmentAgreement, endHireDate: end, hireYearTotal: 0, hireMonthTotal: 0, hireDayTotal: 0 });
    }
  }

  return (
    <Card className='mt-3'>
      <Title text='ข้อตกลงว่าจ้าง'
        className='fs-5 text-primary' />
      <Row className='mt-03'>
        <Col sm={9}>
          <InputTextArea
            label='ผู้รับจ้างตกลงรับจ้างทำงาน'
            value={employmentAgreement?.description}
            onChange={(value) => onTextChange('description', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row className='mt-03'>
        <Col sm={9}>
          <InputTextArea
            label='สถานที่รับจ้างทำงาน'
            value={employmentAgreement?.description}
            onChange={(value) => onTextChange('description', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row>
        <Col sm={9}>
          <Input
            label='บริเวณ'
            value={employmentAgreement?.place}
            onChange={(value) => onTextChange('place', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <Input
            label='เลขที่'
            value={employmentAgreement?.addressNo}
            onChange={(value) => onTextChange('addressNo', value)}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={3}>
          <Input
            label='หมู่ที่'
            value={employmentAgreement?.moo}
            onChange={(value) => onTextChange('moo', value)}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={3}>
          <Input
            label='ถนน'
            value={employmentAgreement?.street}
            onChange={(value) => onTextChange('street', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row>
        <LocationDataHandler
          provinceId={employmentAgreement?.provinceCode}
          districtId={employmentAgreement?.districtCode}
          subDistrictId={employmentAgreement?.subDistrictCode}
          provincesDDL={props.provincesDDL}
          onProvinceChange={(id, label) => onChangeProvince(id, label)}
          onDistrictChange={(id, label) => onChangeDistrict(id, label)}
          onSubDistrictChange={(id, label) => onChangeSubDistrict(id, label)}
          isDisabled={props.isDisabled} />
      </Row>
      <Row>
        <Col sm={3}>
          <DatePicker
            label='ระยะเวลาจ้างงานตั้งแต่'
            value={employmentAgreement?.startHireDate}
            onChange={(value) => onChangeStartDate(value)}
            rule={{ required: false }}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={3}>
          <DatePicker
            label='ถึงวันที่'
            value={employmentAgreement?.endHireDate}
            onChange={(value) => onChangeEndDate(value)}
            rule={{ required: false }}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={6} md={3} style={{ paddingTop: 50 }}>
          <span>รวมเป็นเวลาทั้งสิ้น</span>
          {
            (employmentAgreement?.hireYearTotal && employmentAgreement.hireYearTotal > 0) ? (
              <span>{` ${employmentAgreement.hireYearTotal.toString()} ปี`}</span>
            ) : (<></>)
          }
          {
            (employmentAgreement?.hireMonthTotal && employmentAgreement.hireMonthTotal > 0) ? (
              <span>{` ${employmentAgreement.hireMonthTotal.toString()} เดือน`}</span>
            ) : (<></>)
          }
          {
            (employmentAgreement?.hireDayTotal && employmentAgreement.hireDayTotal > 0) ? (
              <span>{` ${employmentAgreement.hireDayTotal.toString()} วัน`}</span>
            ) : (<></>)
          }
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <InputNumber
            label='ปฏิบัติหน้าที่วันละ (ผลัด)'
            value={employmentAgreement?.workShiftPerDay}
            onChange={(value) => setEmploymentAgreement({ ...employmentAgreement, workShiftPerDay: Number(value) })}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div>กรณีไม่มาปฏิบัติงาน หรือมาปฏิบัติงานไม่ครบจำนวนตามสัญญา</div>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <Currency
            label='ค่าปรับ (บาท ต่อผลัด)'
            value={employmentAgreement?.fineAmountPerWorkShift}
            onChange={(value) => setEmploymentAgreement({ ...employmentAgreement, fineAmountPerWorkShift: Number(value) })}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div>กรณีมาปฏิบัติงานครบตามสัญญา แต่ปฏิบัตงานไม่ครบ ตามจำนวนชั่วโมง</div>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <InputNumber
            label='จำนวน (ชั่วโมง)'
            value={employmentAgreement?.workShiftHourCount}
            onChange={(value) => setEmploymentAgreement({ ...employmentAgreement, workShiftHourCount: Number(value) })}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={2}>
          <Currency
            label='ค่าปรับ (บาท ต่อชั่วโมง)'
            value={employmentAgreement?.fineAmountPerHour}
            onChange={(value) => setEmploymentAgreement({ ...employmentAgreement, fineAmountPerHour: Number(value) })}
            disabled={props.isDisabled} />
        </Col>
      </Row>
    </Card>
  );
}
