import { DepartmentResponse } from 'models';
import { ItemModel } from 'models/systemUtility';
import { LoaderFunctionArgs } from 'react-router';
import { department } from 'services';
import PP0201Services from 'services/PP/PP0201Services';
import PP0303Service from 'services/PP/PP0303Service';

const PL0303Loader = async () => {
  const departmentItems: ItemModel[] = [];
  await GetDepartmentList(departmentItems);
  const ApproveJorPor04List = await PP0303Service.GetListAsync(1, 10);

  const planSelection: ItemModel[] = [];
  await GetPlanSelectionList(planSelection);

  return {
    departmentDDL: departmentItems,
    list: ApproveJorPor04List.data,
    planDDL: planSelection,
  };
};

const PL0303DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (id) {
    const { data } = await PP0303Service.GetDetailAsync(id);

    return {
      detailData: data.result,
    };
  }
};

const GetDepartmentList = async (itemForMap: ItemModel[]) => {
  const departmentsRes = await department.getDepartmentsAsync();
  const departmentData: DepartmentResponse[] = departmentsRes.data;
  departmentData.forEach((data) => itemForMap.push({ label: data.name, value: data.id ? data.id : '' }));
};

const GetPlanSelectionList = async (itemForMap: ItemModel[]) => {
  const response = await PP0201Services.GetPlanSelectionListAsync();
  const planListData: ItemModel[] = response.data;
  planListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
};

export {
  PL0303Loader,
  PL0303DetailLoader,
};