import { useEffect, useMemo, useState } from 'react';
import { MdCircle } from 'react-icons/md';

interface Props {
  systemStatue: string;
  outline?: boolean;
  text?: string;
  systemName: string;
}

const STATUS = [
  {
    systemStatue: 'None',
    color: '#BEBEBE',
    label: 'รอดำเนินการพิจารณาคืนหลักประกัน',
  },
  {
    systemStatue: 'Completed',
    color: '#1BA345',
    label: 'คืนหลักประกันเสร็จสิ้น',
  },
  {
    systemStatue: 'Draft',
    color: '#B7B7B7',
    label: 'แบบร่าง',
  },
  {
    systemStatue: 'WaitingForConsider',
    color: '#FBBD01',
    label: 'อยู่ระหว่างพิจารณาคืนหลักประกันสัญญา',
  },
  {
    systemStatue: 'WaitingForApprove',
    color: '#FBBD01',
    label: 'อยู่ระหว่างขออนุมัติคืนหลักประกันสัญญา',
  }, {
    systemStatue: 'WaitingForReceiveCollateral',
    color: '#FBBD01',
    label: 'อยู่ระหว่างการบันทึกรับหลักประกัน',
  },
];

export function ReturnCollateralStatus(props: Props) {
  const [status, setStatus] = useState<{ systemStatue: string, color: string, label: string }>();

  useEffect(() => {
    if (props.systemStatue) {
      const newStatus = STATUS.find((s) => s.systemStatue === props.systemStatue);

      if (newStatus) {
        setStatus(newStatus);
      }
    }
  }, [props.systemStatue]);
  return (
    <>
      {props.systemStatue
        ? (
          <div className='directive-status'
            style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

function Gettext(system: string, systemStatue: string) {
  switch (system) {
    case 'cm-03':
      if (systemStatue === 'None') {
        return 'รอดำเนินการ'
      }
      if (systemStatue === 'WaitingForConsider') {
        return 'อยู่ระหว่างพิจารณาคืนหลักประกันสัญญา'
      }
      if (systemStatue === 'WaitingForApprove') {
        return 'อยู่ระหว่างขออนุมัติคืนหลักประกันสัญญา'
      }
      if (systemStatue === 'WaitingForReceiveCollateral') {
        return 'อยู่ระหว่างการบันทึกรับหลักประกัน'
      }
      if (systemStatue === 'Completed') {
        return 'คืนหลักประกันเสร็จสิ้น'
      }
      if (systemStatue === '' || systemStatue === null || systemStatue === undefined) {
        return 'ร่าง'
      }
      if (systemStatue === 'SendEmail') {
        return 'ส่งอีเมลสำเร็จ'
      }
      break;
    case 'cm-04':
      break;
    default:
      return 'อยู่ระหว่างดำเนินการ'
  }
}


export function StatusCMReturnCollateral({ systemStatue, outline, text, systemName }: Props) {
  const status = useMemo(() => {

    if (systemStatue === 'WaitingForConsider') {
      return {
        color: 'bg-warning bg-opacity-10 text-warning rounded'
      }
    }
    if (systemStatue === 'Completed' || systemStatue === 'SendEmail') {
      return {
        color: 'bg-success text-success '
      };
    }
    // if (systemStatue === 'Rejected') {
    //   return {
    //     color: 'bg-danger text-danger'
    //   };
    // }
    if (systemStatue === 'WaitingForApprove'
      || systemStatue === 'WaitingForReceiveCollateral') {
      return {
        color: 'bg-yellow text-yellow '
      };
    }
    if (systemStatue === 'None') {
      return {
        color: 'bg-dark text-muted'
      };
    }
    return { color: 'bg-dark text-muted' };
  }, [systemStatue]);

  return (
    <>
      {status ?
        <div className={`d-flex align-items-center px-3 py-1 bg-opacity-10 rounded-pill ${status.color}`}> <MdCircle className="me-2 circle" /> {systemName ? Gettext(systemName, systemStatue) : 'text noting'}</div> : null}
    </>
  );
}

function GettextCMConsider(system: string, systemStatue: string) {
  switch (system) {
    case 'cm-03':
      if (systemStatue === 'None') {
        return 'รอดำเนินการ'
      }
      if (systemStatue === 'Draft') {
        return 'ร่าง'
      }
      if (systemStatue === 'WaitForInspectorAccept') {
        return 'รอคณะกรรมการตรวจรับเห็นชอบ'
      }
      if (systemStatue === 'InspectorRejected') {
        return 'คณะกรรมการตรวจรับส่งกลับแก้ไข'
      }
      if (systemStatue === 'WaitForJorPorAssign') {
        return 'รอ ผอ. จพ. มอบหมายงาน'
      }
      if (systemStatue === 'JorPorAssigned') {
        return 'จพ. มอบหมายงาน'
      }
      if (systemStatue === '' || systemStatue === null || systemStatue === undefined) {
        return 'ร่าง'
      }
      break;
    case 'cm-04':
      break;
    default:
      return 'อยู่ระหว่างดำเนินการ'
  }
}

export function StatusCMConsider({ systemStatue, outline, text, systemName }: Props) {
  const status = useMemo(() => {

    if (systemStatue === 'WaitForInspectorAccept') {
      return {
        color: 'bg-warning bg-opacity-10 text-warning rounded'
      }
    }
    if (systemStatue === 'JorPorAssigned') {
      return {
        color: 'bg-success text-success '
      };
    }
    if (systemStatue === 'InspectorRejected') {
      return {
        color: 'bg-danger text-danger'
      };
    }
    if (systemStatue === 'WaitForJorPorAssign') {
      return {
        color: 'bg-yellow text-yellow '
      };
    }
    if (systemStatue === 'Draft' || systemStatue === 'None') {
      return {
        color: 'bg-dark text-muted'
      };
    }
    return { color: 'bg-dark text-muted' };
  }, [systemStatue]);

  return (
    <>
      {status ?
        <div className={`d-flex align-items-center px-3 py-1 bg-opacity-10 rounded-pill ${status.color}`}> <MdCircle className="me-2 circle" /> {systemName ? GettextCMConsider(systemName, systemStatue) : 'text noting'}</div> : null}
    </>
  );
}

function GettextCMRequestApproval(system: string, systemStatue: string) {
  switch (system) {
    case 'cm-03':
      if (systemStatue === 'None') {
        return 'รอดำเนินการ'
      }
      if (systemStatue === 'Draft') {
        return 'ร่าง'
      }
      if (systemStatue === 'WaitForApprove') {
        return 'รอผู้มีอำนาจเห็นชอบ/อนุมัติ'
      }
      if (systemStatue === 'Approved') {
        return 'อนุมัติ'
      }
      if (systemStatue === 'Rejected') {
        return 'ส่งกลับแก้ไข'
      }
      if (systemStatue === '' || systemStatue === null || systemStatue === undefined) {
        return 'ร่าง'
      }
      break;
    case 'cm-04':
      break;
    default:
      return 'อยู่ระหว่างดำเนินการ'
  }
}


export function StatusCMRequestApproval({ systemStatue, outline, text, systemName }: Props) {
  const status = useMemo(() => {

    if (systemStatue === 'Approved') {
      return {
        color: 'bg-success text-success '
      };
    }
    if (systemStatue === 'Rejected') {
      return {
        color: 'bg-danger text-danger'
      };
    }
    if (systemStatue === 'WaitForApprove') {
      return {
        color: 'bg-yellow text-yellow'
      };
    }
    if (systemStatue === 'Draft' || systemStatue === 'None') {
      return {
        color: 'bg-dark text-muted'
      };
    }
    return { color: 'bg-dark text-muted' };
  }, [systemStatue]);

  return (
    <>
      {status ?
        <div className={`d-flex align-items-center px-3 py-1 bg-opacity-10 rounded-pill ${status.color}`}> <MdCircle className="me-2 circle" /> {systemName ? GettextCMRequestApproval(systemName, systemStatue) : 'text noting'}</div> : null}
    </>
  );
}

function GettextCMReceiveCollateral(system: string, systemStatue: string) {
  switch (system) {
    case 'cm-03':
      if (systemStatue === 'None') {
        return 'รอดำเนินการ'
      }
      if (systemStatue === 'Pending') {
        return 'รอดำเนินการ'
      }
      if (systemStatue === 'Draft') {
        return 'ร่าง'
      }
      if (systemStatue === 'Completed') {
        return 'คืนหลักประกันเสร็จสิ้น'
      }
      if (systemStatue === '' || systemStatue === null || systemStatue === undefined) {
        return 'ร่าง'
      }
      break;
    case 'cm-04':
      break;
    default:
      return 'อยู่ระหว่างดำเนินการ'
  }
}

export function StatusCMReceiveCollateral({ systemStatue, outline, text, systemName }: Props) {
  const status = useMemo(() => {

    if (systemStatue === 'WaitForInspectorAccept') {
      return {
        color: 'bg-warning bg-opacity-10 text-warning rounded'
      }
    }
    if (systemStatue === 'JorPorAssigned') {
      return {
        color: 'bg-success text-success'
      };
    }
    if (systemStatue === 'InspectorRejected') {
      return {
        color: 'bg-danger text-danger'
      };
    }
    if (systemStatue === 'WaitForJorPorAssign') {
      return {
        color: 'bg-yellow text-yellow'
      };
    }
    if (systemStatue === 'Draft' || systemStatue === 'None') {
      return {
        color: 'bg-dark text-muted'
      };
    }
    if (systemStatue === 'Pending') {
      return {
        color: 'bg-yellow text-yellow'
      };
    }
    if (systemStatue === 'Completed') {
      return {
        color: 'bg-success text-success'
      };
    }
    return { color: 'bg-dark text-muted' };
  }, [systemStatue]);

  return (
    <>
      {status ?
        <div className={`d-flex align-items-center px-3 py-1 bg-opacity-10 rounded-pill ${status.color}`}> <MdCircle className="me-2 circle" /> {systemName ? GettextCMReceiveCollateral(systemName, systemStatue) : 'text noting'}</div> : null}
    </>
  );
}


function GettextApprover(system: string, systemStatue: string) {
  switch (system) {
    case 'cm-03':
      if (systemStatue === 'Pending') {
        return 'รออนุมัติ'
      }
      if (systemStatue === 'Approve') {
        return 'อนุมัติ'
      }
      if (systemStatue === 'Reject') {
        return 'ส่งกลับแก้ไข'
      }
      if (systemStatue === 'Draft') {
        return 'ร่าง'
      }
      if (systemStatue === 'None') {
        return 'รอดำเนินการ'
      }
      if (systemStatue === '' || systemStatue === null || systemStatue === undefined) {
        return 'รอดำเนินการ'
      }
      break;
    case 'cm-04':
      break;
    default:
      return 'รอดำเนินการ'
  }
}


export function StatusApprover({ systemStatue, outline, text, systemName }: Props) {
  const status = useMemo(() => {

    if (systemStatue === 'Pending') {
      return {
        color: 'bg-warning bg-opacity-10 text-warning rounded'
      }
    }
    if (systemStatue === 'Approved'
      || systemStatue === 'Approve') {
      return {
        color: 'bg-success text-success '
      };
    }
    if (systemStatue === 'Cancel'
      || systemStatue === 'Reject') {
      return {
        color: 'bg-danger text-danger'
      };
    }
    if (systemStatue === 'WaitForApprove') {
      return {
        color: 'bg-yellow text-yellow '
      };
    }
    if (systemStatue === 'Draft'
      || systemStatue === 'ContractAgreement'
      || systemStatue === 'None') {
      return {
        color: 'bg-dark text-muted'
      };
    }
    return { color: 'bg-dark text-muted' };
  }, [systemStatue]);

  return (
    <>
      {status ?
        <div className={`d-flex align-items-center px-3 py-1 bg-opacity-10 rounded-pill ${status.color}`}> <MdCircle className="me-2 circle" /> {systemName ? GettextApprover(systemName, systemStatue) : 'text noting'}</div> : null}
    </>
  );
}
