import {
  Card,
  DatePicker,
  Input,
  Radio,
  Selector,
  Table,
  UploadFile,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const INFO = [
  { label: 'ละเว้น', value: '1' },
  { label: 'ไม่ละเว้น', value: '2' },
];

function Step1({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลโครงการ" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={6}>
            <Input label="เลขที่เอกสาร PR" placeholder="เลขที่เอกสาร PR" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Input label="โครงการ" placeholder="โครงการ" disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="งบประมาณโครงการ (บาท)" placeholder="1,000,000.00" disabled textEnd />
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="วิธีจัดหา" value="80" disabled />
          </Col>
          <Col sm={12} md={4} className="pt-4">
            <Input value="เจาะจง" disabled />
          </Col>
          <Col sm={12} md={4}>
            <Input label="เลขที่ PR" value="1,000,000.00" disabled textCenter />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="จัดทำสัญญาหรือข้อตกลง" className="fs-5 text-primary" />
        <Row className="mt-3">
          <Col sm={12} md={6}>
            <Input label="คู่ค้า" placeholder="คู่ค้า" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            <Input label="เลขที่ PR" placeholder="เลขที่ PR" disabled />
          </Col>
          <Col sm={12}>
            <Radio
              className="mt-3"
              inline
              items={INFO}
              label="จำนวนเงินหลักประกัน"
              rule={{ required: true }}
              name='name1'
            />
          </Col>
          <Col sm={12} md={3}>
            <Selector label="ประเภทหลักประกันสัญญา" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="จำนวนเงินหลักประกัน" placeholder="จำนวนเงินหลักประกัน" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="ร้อยละของราคาค่าจ้างตามสัญญา" placeholder="ร้อยละของราคาค่าจ้างตามสัญญา" rule={{ required: true }} disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            <Selector label="ประเภทวันทำสัญญา" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <DatePicker label="วันที่" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <DatePicker label="ถึงวันที่" rule={{ required: true }} disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            <Selector label="ค่าปรับราย" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="ค่าปรับ (%)" placeholder="0.00" rule={{ required: true }} textCenter disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            <Selector label="รูปแบบสัญญา" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={3}>
            <Selector label="เงื่อนไขการรับประกัน" rule={{ required: true }} disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="การจ่ายเงิน" className="fs-5 text-primary" />
        <Row>
          <Col sm={12}>
            <Table>
              <thead>
                <tr className="text-center">
                  <th style={{ minWidth: 20 }}>งวดที่</th>
                  <th style={{ minWidth: 150 }}>จำนวน ( % )</th>
                  <th style={{ minWidth: 150 }}>รายละเอียด</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>1</td>
                  <td><Input textCenter disabled value="10" /></td>
                  <td>
                    <Input disabled value="ส่งแผนการดำเนินโครงการและส่งมอบ Software Requirements Specification : SRS ภายใน 60 วัน นับถัดจากวันลงนามในสัญญาจ้าง" />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <ButtonCustom
          onClick={onClickNext}
          text="ถัดไป"
        />
      </div>
    </div>
  );
}

export default Step1;
