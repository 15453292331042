import { AxiosResponse } from 'axios';
import { LoaderFunctionArgs } from 'react-router';
import {
  planAnnouncement as planService,
  publicPlanAnnouncement as publicService,
  shareValue,
} from 'services';

const publicPlanProcurementLoader = async () => {
  const [planAnnoucementList, SupplyMethod, GetBadge] = await Promise.all([
    planService.getPlanProcurementAnnouncementListAsync(1, 10),
    shareValue.GetDDL('SupplyMethod'),
    publicService.GetBadgePublicPlanAsync(1, 10)]);
  const SupplyMethodAll = [{ label: 'ทั้งหมด', value: 'All' }, ...SupplyMethod];
  return {
    planAnnouncementListData: planAnnoucementList.data.data,
    planAnnouncementListTotal: planAnnoucementList.data.totalRecords,
    supplyMethodDDL: SupplyMethodAll,
    badge: GetBadge.data,
  };
};

const publicPlanProcurementDetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  let data: AxiosResponse = {} as AxiosResponse;
  if (id) {
    data = await publicService.getDetailDataAsync(id);
  }

  const [departments, positions, supplyMethods] = await Promise.all([
    shareValue.GetDDL('Department'),
    shareValue.GetDDL('Position'),
    shareValue.GetDDL('SupplyMethod'),
  ]);
  return {
    publicPlanProcurement: data.data?.planProcurementAnnouncement,
    departments,
    positions,
    supplyMethods,
  };
};

export {
  publicPlanProcurementLoader,
  publicPlanProcurementDetailLoader,
};
