import { SearchContractReportModel } from 'models/CM/CM07Model';
import http from 'utils/axios';

const qs = require('qs');

const api = '/contractReport';

const getListAsync = (searchData: SearchContractReportModel) => {
  const params = {
    page: searchData.page,
    size: searchData.size,
    poNumber: searchData.poNumber,
    planNumber: searchData.planNumber,
    contractNumber: searchData.contractNumber,
    contractName: searchData.contractName,
    entrepreneurName: searchData.entrepreneurName,
    departmentId: searchData.departmentId,
    supplyMethodIds: searchData.supplyMethodIds,
    isResponsible: searchData.isResponsible,
  };

  return http.get(`${api}`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountSupplyMethodAsync = (searchData: SearchContractReportModel) => {
  const params = {
    poNumber: searchData.poNumber,
    planNumber: searchData.planNumber,
    contractNumber: searchData.contractNumber,
    contractName: searchData.contractName,
    entrepreneurName: searchData.entrepreneurName,
    departmentId: searchData.departmentId,
    supplyMethodIds: searchData.supplyMethodIds,
    isResponsible: searchData.isResponsible,
  };

  return http.get(`${api}/supply-method-count`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getReportAsync = async (searchData: SearchContractReportModel) => {
  const params = {
    poNumber: searchData.poNumber,
    planNumber: searchData.planNumber,
    contractNumber: searchData.contractNumber,
    contractName: searchData.contractName,
    entrepreneurName: searchData.entrepreneurName,
    departmentId: searchData.departmentId,
    supplyMethodIds: searchData.supplyMethodIds,
  };

  return await http.get(`${api}/report`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
    responseType: 'blob'
  });
};

const CM07Service = {
  getListAsync,
  getCountSupplyMethodAsync,
  getReportAsync,
};

export default CM07Service;
