import {
  Card,
  ColumnChart as ColumnChartCom,
  DatePicker,
  PieChart as PieChartCom,
  Selector,
  Status,
  StatusType,
  Table,
  StatusDashBoard,
  Input,
} from 'components';
import { CardSummary } from 'components/Card/CardSummary';
import { SHORT_MONTHS } from 'data';
import {
  AcceptorCriteria,
  AcceptorList,
  Chart,
  ItemModel,
  Project,
  Summary,
} from 'models';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaFileExcel,
  FaSearch,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  dashboard,
  shareValue,
} from 'services';
import {
  GetMonthYearBC,
  HttpStatusCode,
  thaiFormatDate,
  THCurrency,
} from 'utils';
import { PlanTypeText, ProgramType } from 'utils/constants/PlanEnum';
import {
  differenceInMonths,
  generateUniqueId,
} from 'utils/helper';
import toast from 'utils/toast';

type DashboardContext = {
  criteria: Criteria;
  setCriteria: Dispatch<SetStateAction<Criteria>>;
  onSearch: (criteria: Criteria) => void;
  summary: Summary;
  chart: Chart;
  setSearchFn: Dispatch<SetStateAction<Function>>;
  departmentList: ItemModel[];
  supplyMethodList: ItemModel[];
  projectList: Project[];
  page: number;
  size: number;
  total: number;
  setPage: Dispatch<SetStateAction<Number>>;
  setSize: Dispatch<SetStateAction<Number>>;
}

type Criteria = {
  startDate?: Date;
  endDate?: Date;
  departmentId: string;
  supplyMethodId: string;
}

const Context = createContext({} as DashboardContext);

export default function Dashboard() {
  const [criteria, setCriteria] = useState<Criteria>({} as Criteria);
  const [summary, setSummary] = useState<Summary>({} as Summary);
  const [chart, setChart] = useState<Chart>({} as Chart);
  const [departmentList, setDepartmentList] = useState<ItemModel[]>([]);
  const [supplyMethodList, setSupplyMethodList] = useState<ItemModel[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [projectList, setProjectList] = useState<Project[]>([]);

  useEffect(() => {
    getMasterAsync();
    onSearch(criteria);
  }, []);


  const onSearch = (criteria?: Criteria) => {
    if (criteria?.startDate && criteria?.endDate) {
      if (criteria.startDate.toISOString() > criteria.endDate.toISOString()) {
        toast.warn('วันที่เริ่มต้นต้องน้อยกว่าหรือเท่ากับวันที่สิ้นสุด');

        return;
      }

      if (differenceInMonths(criteria.endDate, criteria.startDate) >= 12) {
        toast.warn('วันที่สิ้นสุดต้องไม่เกินจากวันที่เริ่มต้น 12 เดือน');

        return;
      }
    }

    getSummaryAsync(criteria);
    getChartAsync(criteria);
  };

  const getMasterAsync = async () => {
    const [department, supplyMethod] = await Promise.all([
      shareValue.GetDDL('Department'),
      shareValue.GetDDL('SupplyMethod'),
    ]);

    setDepartmentList(department);
    setSupplyMethodList(supplyMethod);
  };

  const getSummaryAsync = async (criteria?: Criteria) => {
    const res = await dashboard.getSummaryAsync(
      criteria?.startDate,
      criteria?.endDate,
      criteria?.departmentId,
      criteria?.supplyMethodId,
    );

    if (res.status === HttpStatusCode.OK) {
      setSummary(res.data);
    }
  };

  const getChartAsync = async (criteria?: Criteria) => {
    const res = await dashboard.getChartAsync(
      criteria?.startDate,
      criteria?.endDate,
      criteria?.departmentId,
      criteria?.supplyMethodId,
    );

    if (res.status === HttpStatusCode.OK) {
      setChart(res.data);
    }
  };

  const valueContext = {
    criteria,
    setCriteria,
    onSearch,
    summary,
    chart,
    departmentList,
    supplyMethodList,
    page,
    size,
    total,
    projectList,
    setPage,
    setSize,
  } as DashboardContext;

  return (
    <Context.Provider
      value={valueContext}>
      <Header />
      <SearchCriteria />
      <Summary />
      <Row>
        <Col xl={12}
          xxl={8}
          className='mt-3'>
          <ColumnChart />
        </Col>
        <Col xl={12}
          xxl={4}
          className='mt-3'>
          <PieChart />
        </Col>
      </Row>
      {/* <ExportButton /> */}
      {/* <AcceptorDataTable /> */}
    </Context.Provider>
  );
}

function Header() {
  const dateNow = new Date();

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>ภาพรวม</h4>
        <p className='m-0'>ข้อมูล ณ วันที่ {thaiFormatDate(dateNow)}</p>
      </div>
      <hr />
    </>
  );
}

function SearchCriteria() {
  const { criteria, setCriteria, onSearch, departmentList, supplyMethodList } = useContext(Context);
  const onChange = (key: string, value: string | Date) => {
    setCriteria({
      ...criteria,
      [key]: value,
    });
  };

  const onClear = () => {
    // setCriteria({} as Criteria);
    const clearCriteria = {
      startDate: undefined,
      endDate: undefined,
      departmentId: '',
      supplyMethodId: '',
    } as Criteria;

    setCriteria(clearCriteria);

    onSearch(clearCriteria);
  };

  return (
    <Card>
      <div className='ps-1'>
        <Row>
          <Col sm={12}
            md={6}
            lg={3}>
            <DatePicker
              label='วันที่เริ่มต้น'
              value={criteria.startDate}
              onChange={startDate =>
                onChange('startDate', startDate)}
              monthYearOnly />
          </Col>
          <Col sm={12}
            md={6}
            lg={3}>
            <DatePicker label='วันที่สิ้นสุด'
              value={criteria.endDate}
              onChange={endDate =>
                onChange('endDate', endDate)}
              monthYearOnly />
          </Col>
          <Col sm={12}
            md={6}
            lg={3}>
            <Selector label='ฝ่าย/สำนัก'
              value={criteria.departmentId}
              onChange={departmentId =>
                onChange('departmentId', departmentId)}
              items={departmentList} />
          </Col>
          <Col sm={12}
            md={6}
            lg={3}>
            <Selector label='วิธีจัดหา'
              value={criteria.supplyMethodId}
              onChange={supplyMethodId =>
                onChange('supplyMethodId', supplyMethodId)}
              items={supplyMethodList} />
          </Col>
        </Row>
        <Row>
          <Col className='d-flex gap-2'>
            <Button
              variant='primary'
              className='d-flex align-items-center gap-2'
              onClick={() => onSearch(criteria)}>
              <FaSearch />ค้นหา
            </Button>
            <Button
              variant='outline-primary'
              className='d-flex align-items-center gap-2'
              onClick={onClear}>
              <FaEraser />ล้าง
            </Button>
          </Col>
        </Row>
      </div>
    </Card>
  );
}

function Summary() {
  const navigate = useNavigate();
  const { summary } = useContext(Context);

  return (
    <Row>
      <Col md={6}
        lg={4}
        xl={3}
        className='mt-3'>
        <CardSummary
          title='งานที่รับผิดชอบ'
          total={summary?.responsible || 0}
          onClick={() => navigate('/da/da01/detail')} />
      </Col>
      <Col md={6}
        lg={4}
        xl={3}
        className='mt-3'>
        <CardSummary
          title='จำนวนโครงการทั้งหมด'
          total={summary?.project || 0}
          onClick={() => navigate('/preprocurement-tor')} />
      </Col>
      <Col md={6}
        lg={4}
        xl={3}
        className='mt-3'>
        <CardSummary
          title='พ.ร.บ.จัดซื้อจัดจ้างฯ 2560'
          total={summary?.supplyMethod60 || 0}
          onClick={() => navigate('/preprocurement-tor?supplymethod60=true')} />
      </Col>
      <Col md={6}
        lg={4}
        xl={3}
        className='mt-3'>
        <CardSummary
          title='ข้อบังคับธนาคาร 80'
          total={summary?.supplyMethod80 || 0}
          onClick={() => navigate('/preprocurement-tor?supplymethod80=true')} />
      </Col>
      <Col md={6}
        lg={4}
        xl={3}
        className='mt-3'>
        <CardSummary
          title='จัดทำแผน/ร่างจัดซื้อจัดจ้าง'
          total={summary?.plan || 0}
          onClick={() => navigate('/procurement-plan-summary')} />
      </Col>
      <Col md={6}
        lg={4}
        xl={3}
        className='mt-3'>
        <CardSummary
          title='จัดซื้อจัดจ้าง'
          total={summary?.procurement || 0}
          onClick={() => navigate('/pr/pr0101')} />
      </Col>
      <Col md={6}
        lg={4}
        xl={3}
        className='mt-3'>
        <CardSummary
          title='บริหารสัญญา'
          total={summary?.contract || 0}
          onClick={() => navigate('/da/da01')} />
      </Col>
      <Col md={6}
        lg={4}
        xl={3}
        className='mt-3'>
        <CardSummary
          title='โครงการแล้วเสร็จ'
          total={summary?.projectIsDone || 0}
          onClick={() => navigate('/da/da01')} />
      </Col>
    </Row>
  );
}

function ColumnChart() {
  const { chart, criteria } = useContext(Context);

  const getMonths = useMemo(() => {
    const months: ItemModel[] = [];
    const date = new Date();

    let diffMonths = 12;
    let monthIndex = date.getMonth();

    if (criteria.startDate) {
      monthIndex = criteria.startDate.getMonth();
    }

    if (criteria.startDate && criteria.endDate) {
      diffMonths = differenceInMonths(criteria.endDate, criteria.startDate) + 1;
    }

    Array.from({ length: diffMonths })
      .forEach(() => {
        if (monthIndex === 12) {
          monthIndex = 0;
          date.setUTCFullYear(date.getUTCFullYear() + 1);
        }

        months.push({
          label: `${SHORT_MONTHS[monthIndex]} ${date.getUTCFullYear() + 543}`,
          value: monthIndex.toString(),
        });

        monthIndex += 1;
      });

    return months;
  }, [chart]);

  const getValue = useCallback((status?: string) => {
    if (getMonths?.length && chart?.columnCharts?.length) {
      return getMonths.map(m => {
        let filter = [];

        filter = chart.columnCharts?.filter(c => new Date(c.expectingProcurementAt).getMonth() === +m.value);

        return filter.length;
      });
    }

    return [];
  }, [chart, getMonths]);

  const getValueComplete = useCallback((status?: string) => {
    if (getMonths?.length && chart?.columnCharts?.length) {
      return getMonths.map(m => {
        let filter = [];

        filter = chart.columnCharts?.filter(c => c.status === 'Approved' && new Date(c.expectingProcurementAt).getMonth() === +m.value);

        return filter.length;
      });
    }

    return [];
  }, [chart, getMonths]);

  const getValueInProcess = useCallback((status?: string) => {
    if (getMonths?.length && chart?.columnCharts?.length) {
      return getMonths.map(m => {
        let filter = [];

        filter = chart.columnCharts?.filter(c => c.status !== 'Approved' && new Date(c.expectingProcurementAt).getMonth() === +m.value);

        return filter.length;
      });
    }

    return [];
  }, [chart, getMonths]);

  const getColumnChartData = useMemo(() => {
    return [
      { label: 'โครงการทั้งหมด', color: '#de8345', value: getValue() },
      { label: 'โครงการแล้วเสร็จ', color: '#5f813f', value: getValueComplete() },
      { label: 'อยู่ระหว่างดำเนินการ', color: '#a5a5a5', value: getValueInProcess() },
    ];
  }, [getValue]);

  return (
    <Card>
      <h5 className='text-primary mb-5'>
        ข้อมูลจำนวนโครงการจัดซื้อจัดจ้าง
      </h5>
      <ColumnChartCom
        categories={getMonths.map(m => m.label)}
        data={getColumnChartData} />
    </Card>
  );
}

function PieChart() {
  const navigate = useNavigate();
  const { chart } = useContext(Context);

  const pieChartData = useMemo(() => {
    return [
      { label: 'ซื้อ', color: '#FF8585', value: chart?.pieChart?.countBuy },
      { label: 'จ้าง', color: '#80CBC7', value: chart?.pieChart?.countHire },
      { label: 'เช่า', color: '#ECC6D1', value: chart?.pieChart?.countRent },
    ];
  }, [chart.pieChart]);

  return (
    <Card>
      <h5 className='text-primary mb-5'>
        ข้อมูลโครงการแยกตามวิธีกการจัดซื้อจัดจ้าง
      </h5>
      <div
        onClick={() => navigate('detail/supply-method')}>
        <PieChartCom
          data={pieChartData} />
      </div>
    </Card>
  );
}

// function ExportButton() {
//   return (
//     <div className='d-flex justify-content-end gap-2 mt-3'>
//       <Button
//         variant='outline-primary'
//         className='d-flex align-items-center gap-2'>
//         <FaFileExcel />
//         Export PDF
//       </Button>
//       <Button
//         variant='outline-primary'
//         className='d-flex align-items-center gap-2'>
//         <FaFileExcel />
//         Export Excel
//       </Button>
//     </div>
//   );
// }


function AcceptorDataTable() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [criteria, setCriteria] = useState<AcceptorCriteria>({} as AcceptorCriteria);
  const [acceptorList, setAcceptorList] = useState<AcceptorList[]>([]);
  const { departmentList } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    getWorkerListAsync();
  }, [page, size]);

  const getWorkerListAsync = async () => {
    const { data, status } = await dashboard.getWorkerListAsync(page, size, criteria);

    if (status === HttpStatusCode.OK) {
      setAcceptorList(data.data);
      setTotalRecords(data.totalRecords);
    }
  };

  const onChangeCriteria = (value: string, type: keyof (AcceptorCriteria)) => {
    setCriteria({ ...criteria, [type]: value });
  };

  const planOnClick = (data: AcceptorList) => {
    if (data.isCancelled) {
      return navigate(`/procurement-plan-cancel/inyear/detail/${data.planId}`);
    }

    if (data.isChanged) {
      return navigate(`/procurement-plan-adjust/inyear/detail/${data.planId}`);
    }

    if (data.type === PlanTypeText.AnnualPlan) {
      return navigate(`/procurement-plan-summary/detail/${data.planId}`);
    }

    if (data.type === PlanTypeText.InYearPlan) {
      return navigate(`/procurement-plan-inyear/detail/${data.planId}`);
    }
  };

  const torOnClick = (torId: string) => {
    return navigate(`/preprocurement-tor/detail/${torId}`);
  };

  return (
    <Card className='mt-3'>
      <h5 className='text-primary'>
        รายชื่อผู้ปฏิบัติงาน
      </h5>
      <Row>
        <Col xs="2">
          <Selector
            label='ฝ่าย/สำนัก'
            items={departmentList}
            value={criteria.departmentId}
            onChange={(value) => onChangeCriteria(value, 'departmentId')} />
        </Col>
        <Col xs="2">
          <Input
            label='รายชื่อ'
            value={criteria.acceptorName}
            onChange={(value) => onChangeCriteria(value, 'acceptorName')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={getWorkerListAsync}>
            <FaSearch />ค้นหา
          </Button>
        </Col>
      </Row>
      <Table total={totalRecords} onChange={(size, page) => { setPage(page), setSize(size) }}>
        <thead>
          <tr>
            <th style={{ minWidth: 100 }}>รายชื่อ</th>
            <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 200 }}>ประเภท</th>
            <th style={{ minWidth: 300 }}>เลขที่แผน</th>
            <th style={{ minWidth: 300 }}>เลขที่โครงการ</th>
            <th style={{ minWidth: 300 }}>ชื่อโครงการ</th>
            <th style={{ minWidth: 100 }}>ประมาณการช่วงเวลาการ<br />จัดซื้อจัดจ้าง</th>
            <th style={{ minWidth: 150 }}>งบประมาณ (บาท)</th>
            <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
            <th style={{ minWidth: 150 }}>สถานะ</th>
          </tr>
        </thead>
        <tbody>
          {acceptorList?.map(data => (
            <tr key={generateUniqueId(data.planId)}>
              <td className='text-center'>
                {data.acceptorName}
              </td>
              <td>
                {data.department}
              </td>
              <td className='text-center'>
                <Status type={StatusType.PROJECT}
                  value={data.type} />
              </td>
              <td className='text-center'>
                <Button
                  variant='link'
                  onClick={() => planOnClick(data)}>
                  {data.planNumber}
                </Button>
              </td>
              <td className='text-center'>
                <Button
                  variant='link'
                  onClick={() => torOnClick(data.torId)}>
                  {data.torNumber}
                </Button>
              </td>
              <td>
                {data.planName}
              </td>
              <td className='text-center'>{GetMonthYearBC(data.expectingProcurementAt)}</td>
              <td className='text-end'>{THCurrency(data.budget)}</td>
              <td className='text-center'>{data.supplyMethod}</td>
              <td className='text-center'>
                <Status
                  type={StatusType.PROCESS}
                  value={data.status} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}