import {
  InputTextArea,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import { committeeModel } from 'models/PR/PR0202Models';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaPlus,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { shareValue } from 'services';
import {
  HttpStatusCode,
  StatusName,
} from 'utils';
import toast from 'utils/toast';
import { Context } from './index';
import { UserAccountModal } from './UserAccountModal';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  onDisable?: boolean;
}

function Step2({ onClickNext, onClickBack, onDisable = false }: Props) {
  const [show, setShow] = useState(false);
  const [posotionOnBoardItems, setPosotionOnBoardItems] = useState<ItemModel[]>([]);
  const [committees, setCommittees] = useState<committeeModel[]>([]);
  const { data, setData } = useContext(Context);

  useEffect(() => {
    getPositionOnBoardAsync();
    if (data && data.committeeAppointProcurements) {
      setCommittees(data.committeeAppointProcurements);
    }
  }, [data]);

  const onChangePosition = (event: HTMLSelectElement, index: number) => {
    const { value } = event;
    const data = [...committees];
    data[index].positionOnBoardId = value;
    data[index].positionOnBoardName = posotionOnBoardItems.filter((x) => x.value === value)[0].label;
    setCommittees(data);
  };

  const onSelectEmp = (empId: string, name: string, position: string, department: string) => {
    if (committees && committees.filter((x) => x.userId === empId).length > 0) {
      toast.error('ไม่สามารถเลือกคณะกรรมการซ้ำ');
    } else {
      const newData: committeeModel = {
        id: '',
        userId: empId,
        fullName: name,
        position,
        department,
        positionOnBoardId: posotionOnBoardItems[0].value,
        positionOnBoardName: posotionOnBoardItems[0].label,
      };
      setCommittees((committees) => [...committees, newData]);
      setData({ ...data, committeeAppointProcurements: [...committees, newData] });
    }
  };

  const getPositionOnBoardAsync = async () => {
    const { data, status } = await shareValue.getPositionOnBoardListAsync();

    if (status === HttpStatusCode.OK) {
      const positionOnBoards: ItemModel[] = data;
      setPosotionOnBoardItems(positionOnBoards);
    }
  };

  const removeCommittees = (index: number) => {
    const temp: committeeModel[] = [...committees];
    temp.splice(index, 1);
    setCommittees(temp);
  };

  const onNext = () => {
    if (committees.length <= 0) {
      toast.warn('กรุณาเลือกคณะกรรมการจัดจ้าง');
    } else {
      onClickNext();
    }
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Card.Body>
          <Title text='คณะกรรมการจัดจ้าง'
            className='fs-5 text-primary' />
          <div className='d-flex justify-content-between my-3'>
            <p>คณะกรรมการจัดจ้าง</p>
            {
              (data && (data.status === undefined || data.status === StatusName.DRAFT || data.status === StatusName.REJECTED)) && (
                <Button
                  variant='outline-primary'
                  onClick={() => setShow(true)}
                >
                  <FaPlus />เพิ่มข้อมูล
                </Button>
              )
            }
          </div>
          <Table>
            <thead>
            <tr>
              <th style={{ minWidth: 100 }}>ลำดับ</th>
              <th style={{ minWidth: 250 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 250 }}>ตำแหน่ง</th>
              <th style={{ minWidth: 250 }}>ฝ่าย</th>
              <th style={{ minWidth: 250 }}>ตำแหน่งในคณะกรรมการ</th>
              <th style={{ minWidth: 125 }} />
            </tr>
            </thead>
            <tbody>
            {committees?.map((data, index) => (
              <tr className='text-center'
                key={index}>
                <td className='text-center'>{index + 1}</td>
                <td className='text-center'>{data.fullName}</td>
                <td className='text-center'>{data.position}</td>
                <td className='text-center'>{data.department}</td>
                <td>
                  <Form.Group className='mb-3'>
                    <InputGroup hasValidation>
                      <Form.Select
                        className='dark mt-1'
                        value={data.positionOnBoardId || ''}
                        disabled={onDisable}
                        onChange={(event) => onChangePosition(event.target as HTMLSelectElement, index)}
                      >
                        {
                          posotionOnBoardItems.map((item) => (
                            <option key={item.value}
                              value={item.value}>{item.label}</option>
                          ))
                        }
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='danger'
                      className='d-flex align-items-center gap-2'
                      onClick={() => removeCommittees(index)}
                      disabled={onDisable}
                    >
                      <FaTrashAlt />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Card className='mt-3'>
        <Card.Body>
          <Title text='อำนาจและหน้าที่'
            className='fs-5 text-primary' />
          <div className='d-flex justify-content-between my-3'>
            <p>อำนาจและหน้าที่</p>
          </div>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              md={9}>
              <InputTextArea
                value={data.descriptionDutyProcurement}
                onChange={(val) => setData({ ...data, descriptionDutyProcurement: val })}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className='d-flex justify-content-between pt-3'>
        <Button
          onClick={onClickBack}
          className='me-2 px-3'
          variant='outline-primary'
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onNext}
          className='me-2 px-3'
          variant='primary'
        >
          ถัดไป
          <MdArrowForward className='ms-2 pb-1 fs-5' />
        </Button>
      </div>
      <UserAccountModal
        show={show}
        onHide={() => setShow(!show)}
        onSelectItem={onSelectEmp}
      />
    </div>
  );
}

export default Step2;
