import {
  Breadcrumb,
  ProgressBarRefType,
} from 'components';
import StepProgress from 'components/StepProgress';
import {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { useAppContext } from 'utils';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

const BREADCRUMB_INFO = [
  {
    routerName: '/cm/cm0212',
    label: 'รายงานสำนักงานตรวจเงินแผ่นดินและหนังสือถึงสรรพากร',
  },
  {
    routerName: '',
    label: 'รายละเอียดรายงานสำนักงานตรวจเงินแผ่นดินและหนังสือถึงสรรพากร',
  },
];

type CM0212DetailContext = {
  currentPage: number;
};

export const Context = createContext({} as CM0212DetailContext);

function CM0212Detail() {
  const { userId } = useAppContext();
  const navigate = useNavigate();
  const [stepStatus, setStepStatus] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const stepRef = useRef<ProgressBarRefType>({} as ProgressBarRefType);

  useEffect(() => {
    setCurrentPage(1);
    // findStepFromStatus(JP06Status.Draft);
  }, [userId]);

  const backToIndex = () => navigate('/cm/cm0212');

  const steps = [
    {
      title: <p>มอบหมายงาน</p>,
      displayIndex: '1',
    },
    {
      title: <p>ลำดับการเห็นชอบ</p>,
      displayIndex: '2',
    },
    {
      title: <p>ส่งผอ.จพ.</p>,
      displayIndex: '3',
    },
  ];

  const progressBar = useCallback(() => (
    <StepProgress
      progressBarData={steps}
      ref={stepRef}
      // step={stepStatus}
      step={currentPage}
    />
  ), [currentPage, stepStatus]);

  const contextValue = {
    currentPage,
  };

  return (
    <Context.Provider value={contextValue}>
      <h4 className='mt-2 text-primary'>
        <Button variant='link'
          onClick={backToIndex}>
          <MdOutlineArrowBack className='fs-4 text-primary mb-1' />
        </Button>
        รายละเอียดรายงานสำนักงานตรวจเงินแผ่นดินและหนังสือถึงสรรพากร
      </h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      {progressBar()}
      {
        currentPage === 1
        && (<Step1 onClickBack={() => backToIndex()}
              onClickNext={() => setCurrentPage(2)} />)
      }
      {currentPage === 2
        ? (
          <Step2
            key={2}
            onClickBack={() => setCurrentPage(1)}
            onClickNext={() => setCurrentPage(3)}
          />
        ) : null}
      {currentPage === 3
        ? (
          <Step3
            key={3}
            onClickBack={() => setCurrentPage(2)}
          />
        ) : null}
    </Context.Provider>
  );
}

export default CM0212Detail;
