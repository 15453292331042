import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  ButtonCustom,
  Card,
  Currency,
  Input,
  InputTextArea,
  Radio,
  Selector,
  Table,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import {
  MaintenanceInformationModel,
  ContractAgreementModel,
  ContractAgreementWorkPhaseModel,
  UpdateWorkPhaseDetailModel,
  UpdateWorkPhaseStep2Model,
  WagePaymentModel,
  PaymentModel,
  RetentionModel,
} from '../../../../../../models/CM/CM02Models';
import CM02Service from '../../../../../../services/CM/CM02Service';
import {
  fullDate,
  HttpStatusCode,
} from '../../../../../../utils';
import toast from '../../../../../../utils/toast';
import { EditDeliveryModal } from '../../../Modal/EditDeliveryModal';
import { ItemModel } from 'models';
import { EditMAModal } from './Modal/EditMAModal';
import { InputNumber } from 'components/Controls/InputNumber';
import { WorkPhasesModel } from 'pages/CM/CM02/Modal/WorkPhasesModel';
import { ContractFormatCode } from 'utils/constants/ContractEnum';
import { PaymentTypeIdEnum } from 'utils/constants/ShareValueEnum';

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const PayType = [
  {
    label: 'ชำระค่าจ้างเป็นงวด',
    value: 'true',
  },
  {
    label: 'ชำระค่าจ้างครั้งเดียว',
    value: 'false',
  },
];


const fields = [
  {
    code: 'CF01',
    description: 'ที่ปรึกษาตกลงรับจ้างออกแบบ',
    place: 'ที่ปรึกษาตกลงรับจ้างออกแบบ',
    quantity: 'จำนวน',
    paymentType: 'ประเภทการจ่ายเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
  },
  {
    code: 'CF02',
    // use CMS009
  },
  {
    code: 'CF03',
    // use CMS009
  },
  {
    code: 'CF04',
    // use CMS009
  },
  {
    code: 'CF05',
    // use CMS009
  },
  {
    code: 'CF06',
  },
  {
    code: 'CF07',
    paymentType: 'ประเภทการจ่ายเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
  },
  {
    code: 'CF08',
    place_and_delivery_label: 'สถานที่และวันที่ส่งมอบ',
    deliveryPlace: 'สถานที่ส่งมอบรถยนต์ ณ',
    deliveryDate: 'ผู้ให้เช่าต้องส่งมอบรถยนต์ภายในวันที่',
    paymentType: 'ประเภทการจ่ายเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
  },
  {
    code: 'CF09',
    paymentType: 'ประเภทการจ่ายเงิน',
    paymentTitle: 'งวดเงินและรายละเอียดการชำระเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
  },
  {
    code: 'CF10',
    amount: 'อัตราค่าจ้างเดือนละ',
    amountTax: 'ภาษีมูลค่าเพิ่ม',
    paymentType: 'ประเภทการจ่ายเงิน',
    paymentTitle: 'งวดเงินและรายละเอียดการชำระเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
  },
  {
    code: 'CF11',
    paymentType: 'ประเภทการจ่ายเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
  },
  {
    code: 'CF12',
    paymentType: 'ประเภทการจ่ายเงิน',
    place_and_delivery_label: 'สถานที่และวันที่ส่งมอบ',
    deliveryPlace: 'สถานที่ส่งมอบรถยนต์ ณ',
    deliveryDate: 'ผู้รับแลกเปลี่ยนจะส่งมอบของภายในวันที่',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
  },
  {
    code: 'CF13',
    // hasAdvance: 'การชำระเงินให้แก่ผู้ซื้อมีการจ่ายเงินล่วงหน้าหรือไม่',
    // advanceAmount: 'จ่ายเงินล่วงค่าจ้างล่วงหน้า (บาท)',
    // advancePercent: 'อัตราร้อยละ (ของราคาค่าจ้าง)',
    // advanceDeductionPercent: 'ผู้ว่าจ้างจะหักเงินค่าจ้างในแต่ละเดือนเพื่อชดใช้คืนเงินค่าจ้างล่วงหน้าไว้จำนวนร้อยละ (ของจำนวนเงินค่าจ้างในแต่ละเดือน)',
    paymentType: 'ประเภทการจ่ายเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
  },
  {
    code: 'CF14',
    amount: 'จำนวนเงินค่าจ้างออกแบบ (บาท)',
    paymentType: 'ประเภทการจ่ายเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
  },
  {
    code: 'CF15',
    paymentType: 'ประเภทการจ่ายเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
  },
];

const AdvancePaymentInfo = [
  {
    label: 'จ่ายเงินล่วงหน้า',
    value: 'true',
  },
  {
    label: 'ไม่จ่ายเงินล่วงหน้า',
    value: 'false',
  },
];

interface IProps {
  title?: string;
  contractId: string;
  contractOfferTotalAmountVat: number;
  context: ContractAgreementModel;
  data: WagePaymentModel;
  onSubmitContractAgreementWorkPhase: (models: ContractAgreementWorkPhaseModel[]) => void; // TODO: Change this type
  onSubmitMaintenanceInformations: (models: MaintenanceInformationModel[]) => void;
  onEarningsAndPaymentsChange: (value: WagePaymentModel) => void;
  onPaymentChange: (value: PaymentModel) => void;
  isDisabled?: boolean;
  periodTypeDDL: ItemModel[];
  paymentTypeDDL: ItemModel[];
  advanceDeductionTypeDDL: ItemModel[];
  contractTemplateCode: string;
  advancePayment: PaymentModel;
  isResponsible?: boolean;
  contractAgreementWorkPhaseData: ContractAgreementWorkPhaseModel[];
}

export function WagePaymentDetail(props: IProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [disableForm, setDisableForm] = useState(false);
  const [contractAgreementWorkPhases, setContractAgreementWorkPhases] = useState<ContractAgreementWorkPhaseModel[]>(props.contractAgreementWorkPhaseData);
  const [maintenanceInformation, setMaintenanceInformation] = useState<MaintenanceInformationModel[]>([]);
  const [showEditDeliveryModal, setShowEditDeliveryModal] = useState(false);
  const [showEditMaModal, setShowEditMaModal] = useState(false);
  const [advancePayment, setAdvancePayment] = useState<PaymentModel>(props.advancePayment);
  const [isDisabledPayment, setIsDisabledPayment] = useState<boolean>(true);
  const [showWorkPhasesModal, setShowWorkPhasesModal] = useState(false);
  const [earningsAndPayments, setEarningsAndPayments] = useState<WagePaymentModel>(props.data);

  useEffect(() => {
    if (earningsAndPayments) {
      props.onEarningsAndPaymentsChange(earningsAndPayments);
    }
  }, [earningsAndPayments]);

  useEffect(() => {
    if (advancePayment) {
      props.onPaymentChange(advancePayment);
    }
  }, [advancePayment]);

  useEffect(() => {
    if (firstLoad) {
      defaultDDL();
      setFirstLoad(false);
    }
  }, [firstLoad]);

  const defaultDDL = useCallback(() => {
    if (!advancePayment?.type) {
      fields.filter(f => f.code === props.contractTemplateCode).forEach((data) => {
        if (data.defaultPaymentType) {
          setAdvancePayment({ ...advancePayment, type: data.defaultPaymentType });
        }
      });
    }
  }, []);

  const onTextChange = useCallback((key: string, value: string) => {
    setEarningsAndPayments(prevState => ({
      ...prevState,
      [key]: value,
    }));

    setAdvancePayment(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setEarningsAndPayments(prevState => ({
      ...prevState,
      [key]: value,
    }));

    setAdvancePayment(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    if (props.context.contractAgreementWorkPhases) {
      setContractAgreementWorkPhases(props.context.contractAgreementWorkPhases);
    }

    if (props.context.maintenanceInformation) {
      setMaintenanceInformation(props.context.maintenanceInformation);
    }
  }, [props.context]);

  const onSubmitDeliverWorkPhaseModal = async (models: ContractAgreementWorkPhaseModel[]) => {
    setContractAgreementWorkPhases(models);
    props.onSubmitContractAgreementWorkPhase(models);
    await onSaveWorkPhase(models);
  };

  // TODO: Change this save function after API is ready
  const onSaveWorkPhase = async (models: ContractAgreementWorkPhaseModel[]) => {
    const updateDetails: UpdateWorkPhaseDetailModel[] = models.map((data) => ({
      id: data.id,
      sequence: data.sequence,
      periodDay: data.periodDay,
      paidPhase: data.paidPhase,
      workPhase: data.workPhase,
      amount: data.amount,
      percent: data.percent,
      advancePaymentAmount: data.advancePaymentAmount,
      retentionAmount: data.retentionAmount,
      detail: data.detail,
      deliveryDueDate: data.deliveryDueDate,
    } as UpdateWorkPhaseDetailModel));

    const updateData: UpdateWorkPhaseStep2Model = {
      WorkPhases: updateDetails,
    };

    const { status } = await CM02Service.updateContractWorkPhaseStep2Async(props.contractId, updateData);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

      return;
    }

    toast.success('บันทึกข้อมูลการส่งมอบสำเร็จ');
  };

  const onChangePhasePayment = useCallback((val: string) => {
    setEarningsAndPayments(prevState => ({
      ...prevState,
      isPhasePayment: Boolean(val),
    }));

    if (val === 'false') {
      setDisableForm(true);
      const wage: WagePaymentModel = {
        isPhasePayment: false,
        bankName: undefined,
        bankBranch: undefined,
        bankAccountName: undefined,
        bankAccountNumber: undefined,
      };

      setEarningsAndPayments(prevState => ({
        ...prevState,
        wage,
      }));
    } else {
      setDisableForm(false);
    }
  }, []);

  const onSubmitMaInfoModal = async (models: MaintenanceInformationModel[]) => {
    setMaintenanceInformation(models);
    props.onSubmitMaintenanceInformations(models);
    await onSaveMaInfo(models);
  };

  const onChangePaymentActive = useCallback((val: string) => {
    setAdvancePayment(prevState => ({
      ...prevState,
      hasAdvance: val === 'true',
    }));

    if (val === 'false') {
      setIsDisabledPayment(true);
      const payment = {
        hasAdvance: false,
        advanceAmount: undefined,
        advanceInDay: undefined,
        collateralType: undefined,
        remainAmount: undefined,
        bankName: undefined,
        bankBranch: undefined,
        bankAccountName: undefined,
        bankAccountNumber: undefined,
      } as PaymentModel;

      setAdvancePayment(prevState => ({
        ...prevState,
        payment,
      }));
    } else {
      setIsDisabledPayment(false);
    }
  }, []);

  const onSaveMaInfo = async (models: MaintenanceInformationModel[]) => {
    const updateDetails: MaintenanceInformationModel[] = models.map((data) => ({
      id: data.id,
      contractAgreementId: data.contractAgreementId,
      termOfRefId: data.termOfRefId,
      sequence: data.sequence,
      description: data.description,
      quantity: data.quantity,
      periodType: data.periodType,
      totalQuantity: data.totalQuantity,
      totalPeriodType: data.totalPeriodType,
      endDate: data.endDate,
    } as MaintenanceInformationModel));

    const { status } = await CM02Service.updateContractMAInfoStep2Async(props.contractId, updateDetails);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

      return;
    }

    toast.success('บันทึกข้อมูลการส่งมอบสำเร็จ');
  };

  const onSubmitWorkPhaseModal = async (models: ContractAgreementWorkPhaseModel[]) => {
    setContractAgreementWorkPhases(models);
  };

  return (
    <>
      <Card className='mt-3'>
        <Title text={`สัญญา${props.title ? props.title : 'ค่าจ้างและการจ่ายเงิน'}`}
          className='fs-5 text-primary' />
        <>
          {
            fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
              <>
                {
                  data.amount && (
                    <Row className='mt-3'>
                      <Col sm={8}
                        md={5}>
                        <Currency
                          label={data.amount}
                          value={earningsAndPayments?.amount}
                          onChange={(value) => onNumberChange('amount', Number(value))}
                          disabled={props.isDisabled} />
                      </Col>
                    </Row>
                  )
                }
                {
                  data.amountTax && (
                    <Row className='mt-3'>
                      <Col sm={8}
                        md={5}>
                        <Currency
                          label={data.amountTax}
                          value={earningsAndPayments?.amountTax}
                          onChange={(value) => onNumberChange('amountTax', Number(value))}
                          disabled={props.isDisabled} />
                      </Col>
                    </Row>
                  )
                }
                {
                  data.paymentType &&
                  (
                    <>
                      {data.paymentTitle && (
                        <h5 className='mt-3'>{data.paymentTitle}</h5>
                      )}
                      <Row className='mt-3'>
                        <Col sm={5}
                          md={5}>
                          <Selector
                            label={data.paymentType}
                            items={props.paymentTypeDDL}
                            value={advancePayment?.type}
                            onChange={(value) => onTextChange('type', value)}
                            disabled={true} />
                        </Col>
                      </Row>
                    </>
                  )
                }
                {/* {
                  data.hasAdvance &&
                  (
                    <Row className='mt-03'>
                      <Col sm={8}
                        md={4}>
                        <Radio
                          dflex
                          label={data.hasAdvance}
                          items={AdvancePaymentInfo}
                          value={advancePayment ? advancePayment.hasAdvance?.toString() : 'false'}
                          rule={{ required: true }}
                          onChange={(val) => onChangePaymentActive(val?.toString())}
                          disabled={props.isDisabled}
                          name='hasAdvance' />
                      </Col>
                    </Row>
                  )
                }
                {
                  data.advanceAmount && (
                    <Row className='mt-3'>
                      <Col sm={8}
                        md={3}>
                        <Currency
                          label={data.advanceAmount}
                          value={advancePayment?.advanceAmount}
                          onChange={(value) => onNumberChange('advanceAmount', Number(value))}
                          disabled={props.isDisabled || isDisabledPayment} />
                      </Col>
                      <Col sm={8}
                        md={3}>
                        <InputNumber
                          label={data.advancePercent}
                          value={advancePayment?.advancePercent}
                          onChange={(value) => onNumberChange('advancePercent', Number(value))}
                          disabled={props.isDisabled || isDisabledPayment} />
                      </Col>
                      <Col sm={8}
                        md={6}>
                        <Selector
                          label='การหักเงินล่วงหน้า'
                          items={props.deductionPhaseDDL}
                          value={advancePayment?.advanceDedutionPhaseType}
                          onChange={(value) => onTextChange('advanceDedutionPhaseType', value)}
                          disabled={props.isDisabled || isDisabledPayment} />
                      </Col>
                      {
                        data.advanceDeductionPercent && (
                          <Col sm={8}
                            md={6}>
                            <InputNumber
                              label={data.advanceDeductionPercent}
                              value={advancePayment?.advancePercent}
                              onChange={(value) => onNumberChange('advanceDeductionPercent', Number(value))}
                              disabled={props.isDisabled || isDisabledPayment} />
                          </Col>
                        )
                      }
                    </Row>
                  )
                } */}
              </>
            )
          }
        </>
        <>
          <Row className='mt-5 d-flex justify-content-between'>
            <Col sm={5}
              md={5}>
              <p>โดยมีรายละเอียดการส่งมอบ ดังนี้</p>
            </Col>
            <Col sm={2}
              md={2}
              className='text-end'>
              {props.isResponsible && (<ButtonCustom
                text='แก้ไขรายการ'
                onClick={() => setShowEditDeliveryModal(true)}
                disabled={disableForm || props.isDisabled} />)}
            </Col>
          </Row>
          <Row>
            <Table hidePagination>
              <thead>
                <tr className='text-center'>
                  {/* <th style={{ width: 20 }}>งวดเงิน</th> */}
                  <th style={{ width: 20 }}>งวดที่</th>
                  <th style={{ width: 50 }}>ระยะเวลา (วัน)</th>
                  <th style={{ width: 70 }}>วันที่ต้องส่งมอบ</th>
                  <th style={{ width: 20 }}>ร้อยละ</th>
                  <th style={{ width: 70 }}>จำนวนเงิน</th>
                  <th style={{ width: 70 }}>หักเงินล่วงหน้า</th>
                  <th style={{ width: 70 }}>หักเงินประกันผลงาน</th>
                  <th style={{ width: '50%' }}>รายเอียด</th>
                </tr>
              </thead>
              <tbody>
                {
                  contractAgreementWorkPhases?.map((data, index) => (
                    <tr className='text-center' key={index}>
                      {/* <td>{data.paidPhase}</td> */}
                      <td>{data.workPhase}</td>
                      <td>{data.periodDay}</td>
                      <td>{data.deliveryDueDate !== undefined ? fullDate(data.deliveryDueDate) : ''}</td>
                      <td>{formatter.format(data.percent)}%</td>
                      <td className='text-end'>{formatter.format(data.amount)}</td>
                      <td className='text-end'>{formatter.format(data.advancePaymentAmount ?? 0)}</td>
                      <td className='text-end'>{formatter.format(data.retentionAmount ?? 0)}</td>
                      <td><InputTextArea value={data.detail}
                        disabled />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Row>
        </>
      </Card>

      <EditDeliveryModal
        show={showEditDeliveryModal}
        contractAgreementId={props.contractId}
        onHide={() => setShowEditDeliveryModal(false)}
        contractAmountVat={props.contractOfferTotalAmountVat}
        contractAgreementWorkPhases={contractAgreementWorkPhases}
        onSubmit={onSubmitDeliverWorkPhaseModal}
        isDisabled={props.isDisabled}
      />
      <EditMAModal
        show={showEditMaModal}
        contractAgreementId={props.contractId}
        onHide={() => setShowEditMaModal(false)}
        maintenanceInformation={maintenanceInformation}
        periodTypeDDL={props.periodTypeDDL}
        onSubmit={onSubmitMaInfoModal}
        isDisabled={props.isDisabled}
      />
      <WorkPhasesModel
        show={showWorkPhasesModal}
        contractAgreementId={props.contractId}
        onHide={() => setShowWorkPhasesModal(false)}
        contractAgreementWorkPhases={contractAgreementWorkPhases}
        onSubmit={onSubmitWorkPhaseModal}
        isDisabled={props.isDisabled}
      />
    </>
  );
}
