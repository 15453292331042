import {
  Card,
  InputTextArea,
  TorStatus,
  UploadFile,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  HistoryModal,
  Modal,
} from 'components/Modal';
import { BasicInformationAction } from 'models/PP';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { BsArrowReturnLeft } from 'react-icons/bs';
import {
  FaCheckCircle,
  FaHistory,
  FaRegCheckCircle,
  FaSave,
  FaUndo,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { useParams } from 'react-router';
import { jorPor06 } from 'services';
import {
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import { AcceptorStatus, AnnouncementStatus } from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { Context } from '.';
import Collabora from '../../../../components/Document/Collabora';
import { isNull } from 'utils/helper';
import { JorPor06Detail } from 'models/PR/JorPor06Models';
import { TorBudgetConstant } from 'constant/PreProcumentTorStatus';
import { CollaboraRef } from 'components/Document/Collabora';

interface Props {
  onClickBack: () => void;
}

enum ModalType {
  APPROVE = 'Approve',
  REJECT = 'Reject',
}

export default function Step3({ onClickBack }: Props) {
  const {
    updateJorPor06Async,
    disabledField,
    jorpor06Detail,
    removeFile,
    uploadFile,
    downloadFileAsync,
    activityData,
    recallAsync,
    recallShow,
    isAcceptor,
    isQueue,
    acceptorId,
    readonly,
    isCreate,
  } = useContext(Context);
  const [showEdit, setShowEdit] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.APPROVE);
  const [showHistory, setShowHistory] = useState(false);
  const [docJorPor006Id, setDocJorPor006Id] = useState('');
  const [docWinnerId, setDocWinnerId] = useState('');
  const [isApprover, setIsApprover] = useState(false);
  const { userId } = useAppContext();
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [showWaitingForApprove, setShowWaitingForApprove] = useState(false);
  const collaboraRef = useRef<CollaboraRef>(null);
  const collaboraWinnerRef = useRef<CollaboraRef>(null);

  useEffect(() => {

    if (jorpor06Detail.acceptors) {
      const isApprover = onCheckQueueApprover(jorpor06Detail);
      setIsApprover(isApprover);

      const queueApprover = jorpor06Detail.acceptors?.filter((a) => a.status !== AcceptorStatus.APPROVE && !a.isAvailable)
        .sort((a, b) => a.sequence - b.sequence)[0];

      if (queueApprover) {
        setIsQueueApprover(isNull(queueApprover.delegateUserId, queueApprover.userId) === userId);
      }

    }

  }, [jorpor06Detail]);

  const onCheckQueueApprover = (data: JorPor06Detail) => {

    const maxValueSectionSequence = Math.max.apply(null,
      data.acceptors?.map((x) => {
        return x.sectionSequence;
      }));

    const maxValueSequence = Math.max.apply(null,
      data.acceptors?.filter(a => a.sectionSequence === maxValueSectionSequence)?.map((x) => {
        return x.sequence;
      }));

    const dataMaxValueSectionSequence = data.acceptors?.filter(a => a.sectionSequence === maxValueSectionSequence && a.sequence === maxValueSequence);

    const approverIndex = dataMaxValueSectionSequence?.findIndex(x => x.userId === userId || x.delegateUserId === userId) + 1;

    if (approverIndex) {
      return true;
    }

    return false;
  };

  // const onCheckApprover = () => {
  //   const maxValueSectionSequence = Math.max.apply(null,
  //     jorpor06Detail.acceptors?.map((x) => x.sectionSequence));

  //   const dataMaxValueSectionSequence = jorpor06Detail.acceptors.filter((a) => a.sectionSequence === maxValueSectionSequence);

  //   const approverIndex = dataMaxValueSectionSequence?.findIndex((x) => x.userId === userId) + 1;

  //   const maxValue = Math.max.apply(null,
  //     dataMaxValueSectionSequence?.map((x) => x.sequence));

  //   if (approverIndex === maxValue) {
  //     return true;
  //   }
  //   return false;
  // };

  // useEffect(() => {
  //   const isApprover = onCheckApprover();
  //   setIsApprover(isApprover);
  // }, []);

  const getJorPorDocument = useCallback(
    async (id: string) => {
      const { data: docId, status } = await jorPor06.getJorPor006DocumentAsync(id, isReadonlyDoc);

      if (status === HttpStatusCode.OK) {
        setDocJorPor006Id(docId);
      }
    }, []);

  const getWinnerDocument = useCallback(
    async (id: string) => {
      const { data: docId, status } = await jorPor06.getWinnerDocumentAsync(id, isReadonlyDoc);

      if (status === HttpStatusCode.OK) {
        setDocWinnerId(docId);
      }
    }, []);

  useEffect(() => {
    if (jorpor06Detail) {
      getJorPorDocument(jorpor06Detail.id);
      getWinnerDocument(jorpor06Detail.id);
    }
  }, [jorpor06Detail]);

  const sendApprove = async () => {
    if (jorpor06Detail.acceptors.filter((a) => a.isAvailable === false).length <= 0) {
      toast.warn('ต้องมีผู้มีอำนาจเห็นชอบที่สามารถปฏิบัติงานอย่างน้อย 1 คน');
      return;
    }

    if (jorpor06Detail.confirmPriceSum > TorBudgetConstant.TwoMillion &&
      (jorpor06Detail.jorPorComment === undefined || jorpor06Detail.jorPorComment === null)) {
      toast.warn('กรุณาระบุความเห็น จพ.');

      return;
    }

    if (!jorpor06Detail.acceptors || jorpor06Detail.acceptors.length === 0) {
      toast.warn('กรุณาเลือกผู้มีอำนาจเห็นชอบ');

      return;
    }

    if (jorpor06Detail.acceptors) {
      updateJorPor06Async(AnnouncementStatus.WaitingAccept, false, false, docJorPor006Id, docWinnerId, undefined, jorpor06Detail.jorPorComment);
      setShowWaitingForApprove(false);
      return;
    }
  };

  const onSave = () => {
    collaboraRef.current?.clickSave();
    collaboraWinnerRef.current?.clickSave();
    setTimeout(async () => {
      updateJorPor06Async(jorpor06Detail.status, false, true, docJorPor006Id, docWinnerId, undefined, jorpor06Detail.jorPorComment);
      return;
    }, 500);
  };

  // const sendApprove = async () => {
  //   if (!jorpor06Detail.jorPorSupervisorUser || jorpor06Detail.jorPorSupervisorUser.length === 0) {
  //     toast.warn('กรุณาเลือกข้อมูลหัวหน้าส่วน');

  //     return;
  //   }

  //   if (!jorpor06Detail.jorPorAssistantPricipleUser || jorpor06Detail.jorPorAssistantPricipleUser.length === 0) {
  //     toast.warn('กรุณาเลือกข้อมูลผู้ช่วยผู้อำนวยการจัดหาและพัสดุ');

  //     return;
  //   }

  //   if (!jorpor06Detail.jorPorPrincipleUser || jorpor06Detail.jorPorPrincipleUser.length === 0) {
  //     toast.warn('กรุณาเลือกข้อมูลผู้อำนวยการจัดหาและพัสดุ');

  //     return;
  //   }

  //   if (!jorpor06Detail.entrepreneurs.some(d => d.consideration.isWinner)) {
  //     toast.warn('ต้องมีผู้ชนะอย่างน้อย 1 คน');

  //     return;
  //   }

  //   const jorPorSupervisorUserLength = jorpor06Detail.jorPorSupervisorUser.filter(j => j.isAvailable).length;
  //   const jorPorAssistantPricipleUserLength = jorpor06Detail.jorPorAssistantPricipleUser.filter(j => j.isAvailable).length;
  //   const jorPorPrincipleUserLength = jorpor06Detail.jorPorPrincipleUser.filter(j => j.isAvailable).length;

  //   if (jorPorSupervisorUserLength === jorpor06Detail.jorPorSupervisorUser.length) {
  //     toast.warn('ต้องมีหัวหน้าส่วนที่ปฏิบัติงานได้อย่างน้อย 1 คน');

  //     return;
  //   }

  //   if (jorPorAssistantPricipleUserLength === jorpor06Detail.jorPorAssistantPricipleUser.length) {
  //     toast.warn('ต้องมีผู้ช่วยผู้อำนวยการจัดหาและพัสดุที่ปฏิบัติงานได้อย่างน้อย 1 คน');

  //     return;
  //   }

  //   if (jorPorPrincipleUserLength === jorpor06Detail.jorPorPrincipleUser.length) {
  //     toast.warn('ต้องมีผู้อำนวยการจัดหาและพัสดุที่ปฏิบัติงานได้อย่างน้อย 1 คน');

  //     return;
  //   }

  //   if (!await showConfirmModalAsync('ต้องการส่งอนุมัติเห็นชอบ ?')) {
  //     return;
  //   }

  //   if ((jorPorSupervisorUserLength !== jorpor06Detail.jorPorSupervisorUser.length)
  //     && (jorPorAssistantPricipleUserLength !== jorpor06Detail.jorPorAssistantPricipleUser.length)
  //     && (jorPorPrincipleUserLength !== jorpor06Detail.jorPorPrincipleUser.length)) {
  //     updateJorPor06Async(AnnouncementStatus.WaitingAccept);

  //     return;
  //   }
  // };

  const onShowModal = (modalType: ModalType) => {
    setShowEdit(true);
    setModalType(modalType);
  };

  const isReadonlyDoc = useMemo(() => {
    return !(jorpor06Detail.status !== AnnouncementStatus.WaitingAccept && jorpor06Detail.status !== AnnouncementStatus.Accepted);
  }, [jorpor06Detail]);

  return (
    <>
      <div className='document'>
        <Card className='mt-3'>
          <div className='d-flex justify-content-between align-items-center gap-3'>
            <div className='d-flex justify-content-between align-items-center gap-3'>
              <Title text='ตัวอย่างเอกสารจัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง (จพ.006)'
                className='fs-5 text-primary' />
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='d-flex gap-2'>
                <TorStatus value={jorpor06Detail.status} />
                <Button
                  onClick={() => setShowHistory(!showHistory)}
                  variant='outline-primary'>
                  <FaHistory className='me-1' />
                  ประวัติการใช้งาน
                </Button>
              </div>
            </div>

          </div>
          <div className='mt-3'>
            <Collabora
              docId={docJorPor006Id}
              docName='doc-jorpor006'
              ref={collaboraRef}
              readonly={isReadonlyDoc}
              showRemark={true} />
          </div>

        </Card>
      </div>
      <div className='document'>
        <Card className='mt-3'>
          <div className='mt-2'>
            <Title text='ตัวอย่างเอกสารประกาศผู้ชนะ'
              className='fs-5 text-primary' />
          </div>
          <Collabora
            docId={docWinnerId}
            docName='doc-winner'
            ref={collaboraWinnerRef}
            readonly={isReadonlyDoc} />
        </Card>
      </div>
      <Card className='mt-3'>
        <Row>
          <Col sm={12}>
            <Title text='เอกสารแนบ'
              className='fs-5 text-primary' />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile disabled={false}
              value={jorpor06Detail.documentList}
              onChange={(value) => uploadFile(value)}
              onRemove={(index, id) => removeFile(index, id)}
              onDownload={(index, id) => downloadFileAsync(index, id)}
              userId={userId} />
          </Col>
        </Row>
      </Card>
      <div className='d-flex justify-content-between gap-3 pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <div>
          {isCreate && jorpor06Detail.status === AnnouncementStatus.DraftAcceptor && (
            <>
              <ButtonCustom
                onClick={() => onSave()}
                text="บันทึก"
              />
              <ButtonCustom
                icon="checked"
                onClick={() => setShowWaitingForApprove(true)}
                text="ส่งอนุมัติ/เห็นชอบ"
              />
            </>
          )}
          {recallShow && (
            <ButtonCustom
              icon="undo"
              variant='danger'
              onClick={() => recallAsync()}
              text="เรียกคืนแก้ไข"
            />
          )}
          {(jorpor06Detail.status === AnnouncementStatus.WaitingAccept && isQueueApprover) && (
            <>
              <ButtonCustom
                icon="undo"
                variant='danger'
                onClick={() => onShowModal(ModalType.REJECT)}
                text="ส่งกลับแก้ไข"
              />
              <ButtonCustom
                variant='success'
                onClick={() => onShowModal(ModalType.APPROVE)}
                icon="checked"
                text={isApprover ? 'อนุมัติ' : 'เห็นชอบ'} />
            </>
          )}
        </div>
        <div style={{ width: 100 }} />
      </div>

      <HistoryModal
        title='ประวัติการใช้งานจัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้า (จพ.006)'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        data={activityData} />

      <ApproveAndRejectModal modalType={modalType}
        show={showEdit}
        onHide={() => setShowEdit(false)}
        acceptorId={acceptorId}
        isApprover={isApprover} />

      <Modal
        show={showWaitingForApprove}
        size='lg'
        onHide={() => setShowWaitingForApprove(!showWaitingForApprove)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งอนุมัติ/เห็นชอบ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitingForApprove(!showWaitingForApprove)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => sendApprove()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

function ApproveAndRejectModal(props: {
  modalType: ModalType,
  show: boolean,
  onHide: () => void,
  acceptorId?: string
  isApprover: boolean,
}) {
  const { jorpor06Detail, getJorPor06DetailAsync } = useContext(Context);
  const { userId } = useAppContext();
  const { id } = useParams();
  const [remark, setRemark] = useState<string>();

  const onSubmit = () => {
    submitForm();

    if (props.modalType === ModalType.REJECT && !remark) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    onSubmitApproveOrRejectAsync();
  };

  const onSubmitApproveOrRejectAsync = async () => {
    if (jorpor06Detail.acceptors && jorpor06Detail.acceptors.length > 0 && id) {

      const acceptor = jorpor06Detail.acceptors.find((b) => isNull(b.delegateUserId, b.userId) === userId);

      if (acceptor) {
        const body: BasicInformationAction = {
          sectionCode: acceptor.sectionCode,
          act: props.modalType,
          remark: remark!,
          acceptorId: acceptor.id ? acceptor.id : '',
        };

        const response = await jorPor06.sendApproveAndRejectAsync(id, body);

        if (props.modalType === ModalType.APPROVE) {
          if (response.status === HttpStatusCode.ACCEPTED) {
            toast.success(`${props.isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
            onHideModal();
            getJorPor06DetailAsync();
          }

          return;
        }

        if (response.status === HttpStatusCode.ACCEPTED) {
          toast.success('ส่งกลับแก้ไขสำเร็จ');

          onHideModal();
          getJorPor06DetailAsync();

          return;
        }
      }

    }
  };

  const onHideModal = () => {
    setRemark('');
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      size='lg'
      onHide={onHideModal}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>
              {
                (props.modalType === ModalType.APPROVE && props.isApprover) ? (
                  'อนุมัติ'
                ) : ('เห็นชอบ')
              }
              {
                (props.modalType === ModalType.REJECT) && (
                  'ส่งกลับแก้ไข'
                )
              }
            </ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4'>
            {
              (props.modalType === ModalType.APPROVE) && (
                <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                  value={remark}
                  onChange={(value) => setRemark(value)} />
              )
            }
            {
              (props.modalType === ModalType.REJECT) && (
                <InputTextArea label='หมายเหตุ'
                  rule={{ required: true }}
                  value={remark}
                  onChange={(value) => setRemark(value)} />
              )
            }
          </ModalBT.Body>
          <ModalBT.Footer className='justify-content-end'>
            <Button
              variant='light'
              onClick={onHideModal}
              className='me-2 px-3'>
              ยกเลิก
            </Button>
            {props.modalType === ModalType.APPROVE ? (
              <Button
                onClick={onSubmit}
                variant='success'
                className='me-2 px-3'>
                <FaCheckCircle className='mx-2 pb-1 fs-4' />
                {props.isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </Button>
            ) : (
              <Button
                onClick={onSubmit}
                variant='danger'
                className='me-2 px-3'>
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            )}
          </ModalBT.Footer>
        </>
      )}
    />
  );
}
