export enum PlanType {
  AnnualPlan,
  InYearPlan,
  CancelPlan,
  ChangePlan,
}

export enum PlanTypeName {
  AnnualPlan = 'จัดทำแผนรวม',
  InYearPlan = 'จัดทำแผนระหว่างปี',
  CancelPlan = 'เปลี่ยนแปลงแผน',
  ChangePlan = 'ยกเลิกแผน',
}

export const enum PlanTypeText {
  AnnualPlan = 'AnnualPlan',
  InYearPlan = 'InYearPlan',
  ChangePlan = 'ChangePlan',
  CancelPlan = 'CancelPlan',
}

export const budgetDDL = [
  { label: 'น้อยกว่าเท่ากับ 500,000 บาท', value: '500000' },
  { label: 'มากกว่า 500,000 บาท', value: '500001' },
];

export enum PlanStatus {
  DraftPlan = 'DraftPlan',
  WaitingApprovePlan = 'WaitingApprovePlan',
  ApprovePlan = 'ApprovePlan',
  RejectPlan = 'RejectPlan',
  Assigned = 'Assigned',
  DraftRecordDocument = 'DraftRecordDocument',
  WaitingAcceptor = 'WaitingAcceptor',
  ApproveAcceptor = 'ApproveAcceptor',
  RejectAcceptor = 'RejectAcceptor',
  Announcement = 'Announcement',
  RejectAnnouncement = 'RejectAnnouncement',
  CancelPlan = 'CancelPlan',
}

export enum AnnouncementStatus {
  Draft = 'Draft',
  AssignJorPor = 'AssignJorPor',
  WaitingAccept = 'WaitingAccept',
  WaitingAcceptor = 'WaitingAcceptor',
  Accepted = 'Accepted',
  Reject = 'Reject',
  Approve = 'Approve',
  WaitingComitteeApprove = 'WaitingComitteeApprove',
  DraftAcceptor = 'DraftAcceptor',
  WaitingForDirectorJorPorAssign = 'WaitingForDirectorJorPorAssign',
  Cancelled = 'Cancelled',
  Recall = 'Recall',
}

export enum PlanStatusName {
  DraftPlan = 'จัดทำแผน',
  WaitingApprovePlan = 'รออนุมัติแผน',
  ApprovePlan = 'อนุมัติแผน',
  RejectPlan = 'ส่งกลับแก้ไข',
  Assigned = 'มอบหมายงาน',
  DraftRecordDocument = 'จัดทำเอกสารบันทึก',
  WaitingAcceptor = 'รอให้ความเห็นชอบ',
  ApproveAcceptor = 'เห็นชอบและอนุมัติสำเร็จ',
  RejectAcceptor = 'ไม่เห็นชอบ',
  Announcement = 'เผยแพร่ประกาศแผน',
  RejectAnnouncement = 'ส่งกลับแก้ไข-จากการเผยแพร่',
}

export enum PlanStatusText {
  DraftPlan = 'DraftPlan',
  WaitingApprovePlan = 'WaitingApprovePlan',
  ApprovePlan = 'ApprovePlan',
  RejectPlan = 'RejectPlan',
  Assigned = 'Assigned',
  DraftRecordDocument = 'DraftRecordDocument',
  WaitingAcceptor = 'WaitingAcceptor',
  ApproveAcceptor = 'ApproveAcceptor',
  RejectAcceptor = 'RejectAcceptor',
  Announcement = 'Announcement',
  RejectAnnouncement = 'RejectAnnouncement',
}

export enum AcceptorStatus {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  APPROVE = 'Approve',
  REJECT = 'Reject',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum AssignLevel {
  Level0 = 0,
  Level1 = 1,
}

export enum ProgramType {
  Plan = 'Plan',
  Appoint = 'Appoint',
  Tor = 'Tor',
  MedianPrice = 'MedianPrice',
  JorPor04 = 'JorPor04',
  JorPor05 = 'JorPor05',
  Invite = 'Invite',
  JorPor06 = 'JorPor06',
  ContractRecord = 'ContractRecord',
  ContractAgreementInvitation = 'ContractAgreementInvitation',
  ContractAgreement = 'ContractAgreement',
  PersonalAgreement = 'PersonalAgreement',
  ContractManagement = 'ContractManagement',
  ContractReturnCollateral = 'ContractReturnCollateral',
  PersonalContractReturnCollateral = 'PersonalContractReturnCollateral'
}