import http from 'utils/axios';

const saveAsync = async (programGroup: string, name: string, exampleFile: File, pdfFile?: File) => {
  const formData = new FormData();
  formData.append('ProgramGroup', programGroup);
  formData.append('Name', name);
  formData.append('File', exampleFile);

  if (pdfFile) {
    formData.append('PdfFile', pdfFile);
  }

  return await http.post('/documenttemplate', formData);
};

const updateAsync = async (id: string, name: string, exampleFile?: File, pdfFile?: File) => {
  const formData = new FormData();
  formData.append('Name', name);

  if (exampleFile) {
    formData.append('File', exampleFile);
  }

  if (pdfFile) {
    formData.append('PdfFile', pdfFile);
  }

  return await http.put(`/documenttemplate/${id}`, formData);
}

const getDataAsync = async (id: string) =>
  await http.get(`/documenttemplate/${id}`);

const getPaginatedAsync = async (pageSize: number, page: number, programGroup: string, name: string) =>
  await http.get('/documenttemplate', { params: { pageSize, page, programGroup, name } });

const deleteAsync = async (id: string) =>
  await http.delete(`/documenttemplate/${id}`);

const getHistoryAsync = async (id: string, pageSize: number, page: number) =>
  await http.get(`/documenttemplate/${id}/history`, { params: { pageSize, page } });

const getPdfFileAsync = async (id: string) =>
  await http.get(`/file/${id}/pdf`);

export default {
  saveAsync,
  updateAsync,
  getDataAsync,
  getPaginatedAsync,
  deleteAsync,
  getHistoryAsync,
  getPdfFileAsync,
};