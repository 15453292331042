import { Input, Table } from 'components';
import { MedianPriceConsultantTypeQualifyModel } from 'models/PP/MedianPriceModel';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

interface IMedianPriceConsultantTypeProps {
  medianPriceId: string;
  medianPriceConsultantTypes: MedianPriceConsultantTypeQualifyModel[];
  onMedianPriceConsultantTypes: (value: MedianPriceConsultantTypeQualifyModel[]) => void;
  isDisabled?: boolean;
}

export function MedianPriceConsultantType(props: IMedianPriceConsultantTypeProps) {
  const [medianPriceConsultantTypes, setMedianPriceConsultantTypes] = useState(props.medianPriceConsultantTypes);

  useEffect(() => {
    props.onMedianPriceConsultantTypes(medianPriceConsultantTypes);
  }, [medianPriceConsultantTypes]);

  useEffect(() => {
    if (props.medianPriceConsultantTypes) {
      setMedianPriceConsultantTypes(props.medianPriceConsultantTypes);
    }
  }, [props.medianPriceConsultantTypes]);

  const addConsultant = useCallback(async () => {
    const newData: MedianPriceConsultantTypeQualifyModel = {
      id: undefined,
      medianPriceId: props.medianPriceId,
      description: '',
      sequence: medianPriceConsultantTypes.length + 1,
    };

    setMedianPriceConsultantTypes((medianPriceConsultantTypes) => [...medianPriceConsultantTypes, newData]);
  }, [medianPriceConsultantTypes]);

  const handlerOnChangeDesc = (value: string, index: number) => {
    const data = [...medianPriceConsultantTypes];
    data[index].description = value;
    setMedianPriceConsultantTypes(data);
  };

  const removeConsultant = (i: number) => {
    const temp: MedianPriceConsultantTypeQualifyModel[] = [...medianPriceConsultantTypes];
    temp.splice(i, 1);
    setMedianPriceConsultantTypes(temp);
  };

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between'>
        <p className='mb-0'>รายการประเภทที่ปรึกษา</p>
        <Button
          variant='outline-primary'
          onClick={addConsultant}
          disabled={props.isDisabled}
        >
          <FaPlus className='me-1' />เพิ่มรายการ
        </Button>
      </div>
      <Row className='align-items-center'>
        <Col sm={12}>
          <Table total={1}
            hidePagination>
            <thead>
              <tr>
                <th style={{ width: 20 }}>ลำดับ</th>
                <th>รายการ</th>
                <th style={{ width: 20 }} />
              </tr>
            </thead>
            <tbody>
              {medianPriceConsultantTypes?.sort((a, b) => a.sequence - b.sequence)
                .map((data, index) => (
                  <tr key={index}>
                    <td className='text-center'>{index + 1}</td>
                    <td>
                      <Input
                        value={data.description}
                        onChange={(val) => handlerOnChangeDesc(val, index)}
                        rule={{ required: true }}
                        disabled={props.isDisabled}
                      />
                    </td>
                    <td className='text-end'>
                      <Button
                        onClick={() => removeConsultant(index)}
                        variant='danger'
                        disabled={props.isDisabled}
                      >
                        <FaTrashAlt />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
