import {
  Card,
  ContractAgreementStatus,
  InputTextArea,
  SearchBuNameModal,
  StatusCM,
  Table,
  Modal,
  ButtonCustom,
  ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import {
  ContractAgreementModel,
  InspectorModel,
  UpdateInspectorStep3Model,
} from 'models/CM/CM02Models';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaPlus,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
  MdHistory,
  MdSave,
  MdSegment,
} from 'react-icons/md';
import CM02Service from 'services/CM/CM02Service';
import {
  fullDatetimeTH,
  HttpStatusCode,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { Context } from '.';
import Collabora, { CollaboraRef } from '../../../../components/Document/Collabora';
import { HistoryModal } from '../Modal/HistoryModal';
import { UserApproveModal } from '../Modal/UserApproveModal';
import { ItemModel, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { JorPorCode } from 'constant/basicInformation';
import { shareValue } from 'services';
import { SharedvalueGroup, SubGroup } from 'utils/constants/ContractEnum';
import { ContractStartDatePeriodConditionValue2Enum } from 'utils/constants/ShareValueEnum';
import context from 'react-bootstrap/esm/AccordionContext';
import { isNull } from 'utils/helper';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  onTemplateChange: (value: string, id: string) => void;
}

export default function Step3({ onClickNext, onClickBack, onTemplateChange }: Props) {
  const {
    dataContext,
    setDataContext,
  } = useContext(Context);
  const [contractData, setContractData] = useState<ContractAgreementModel>({} as ContractAgreementModel);
  const { userId, departmentCode } = useAppContext();
  const [inspectors, setInspectors] = useState<InspectorModel[]>([]);
  const [approveReason, setApproveReason] = useState<string>('');
  const [rejectReason, setRejectReason] = useState<string>('');
  const [docId, setDocId] = useState('');
  const [docSignId, setDocSignId] = useState('');
  const [docPDPAId, setDocPDPAId] = useState('');
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [isCanApprove, setIsCanApprove] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isPDPADocuemnt, setIsPDPADocuemnt] = useState(false);
  const collaboraRef = useRef<CollaboraRef>(null);
  const collaboraPDPARef = useRef<CollaboraRef>(null);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [contractTemplateCode, setContractTemplateCode] = useState('');
  const [contractTemplateId, setContractTemplateId] = useState('');
  const [docChangeContractId, setDocChangeContractId] = useState('');
  const collaboraRefContractChange = useRef<CollaboraRef>(null);
  const [openCollapse2, setOpenCollapse2] = useState(!dataContext.isChange);
  const [openCollapse3, setOpenCollapse3] = useState(!dataContext.isChange);

  const onClickBackStep = () => {
    onTemplateChange(contractTemplateCode, contractTemplateId);
    onClickBack();
  };

  const getContractTemplate = async (contractFormatId: string) => {
    const { data, status } = await shareValue.getSingleSharedValueAsync(contractFormatId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setContractTemplateCode(data.sharedValue.code);
    setContractTemplateId(data.sharedValue.id);
  };


  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  useEffect(() => {
    if (dataContext) {
      if (dataContext.status === ContractAgreementStatus.Draft
        || dataContext.status === ContractAgreementStatus.Rejected) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }

      if (dataContext.contractAgreementInspectors && dataContext.contractAgreementInspectors.length > 0) {
        setInspectors(dataContext.contractAgreementInspectors);
        const isCanApprove = onCheckCanApprove(dataContext.contractAgreementInspectors);
        setIsCanApprove(isCanApprove);
      } else {
        const inspector: InspectorModel[] = [];
        setInspectors(inspector);
        getDefaultInspectors();
      }

      if (dataContext.documents) {
        getAppendix();
      }

      setContractData(dataContext);

      if (dataContext.contractTemplate) {
        getContractTemplate(dataContext.contractTemplate);
      }
    }
  }, [dataContext]);

  const getAppendix = async () => {
    const contractTemplate = dataContext.subContractTemplate ? dataContext.subContractTemplate : dataContext.contractTemplate;

    const resData = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractAppendix, contractTemplate);
    const datas: ItemModel[] = resData.data;

    const isPDPA = dataContext.documents.filter((d) => {
      return datas.some((s) => {
        return s.value === d.type && (s.value2 === ContractStartDatePeriodConditionValue2Enum.สัญญาการรักษาข้อมูลที่เป็นความลับ || s.value2 === ContractStartDatePeriodConditionValue2Enum.สัญญาการประมวลผลข้อมูลส่วนบุคคล);
      });
    });

    setIsPDPADocuemnt(isPDPA.length > 0);
  };

  const getDefaultInspectors = async () => {
    const {
      data,
      status,
    } = await CM02Service.getDefaultInspectorsAsync();
    if (status === HttpStatusCode.OK) {
      setInspectors((pre) => [...pre, data as InspectorModel]);
    }
  };

  const getDocument = useCallback(async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getDocumentAsync(id);
    if (status === HttpStatusCode.OK) {
      setDocId(data);
    }
  }, [setDocId]);

  const getDocumentSign = useCallback(async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getContractSignDocumentAsync(id);
    if (status === HttpStatusCode.OK) {
      setDocSignId(data);
    }
  }, [setDocSignId]);

  const getDocumentPDPA = useCallback(async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getDocumentPDPAAsync(id);

    if (status === HttpStatusCode.OK) {
      setDocPDPAId(data);
    }
  }, [setDocPDPAId]);

  const getDocumentChangeContractAsync = useCallback(async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getDocumentChangeContractAsync(id);
    if (status === HttpStatusCode.OK) {
      setDocChangeContractId(data);
    }
  }, [setDocChangeContractId]);

  useEffect(() => {
    if (dataContext.id) {
      getDocument(dataContext.id);
      getDocumentSign(dataContext.id);
      getDocumentPDPA(dataContext.id);

      if (dataContext.isChange) {
        getDocumentChangeContractAsync(dataContext.id);
      }
    }
  }, [dataContext.id,
    getDocument,
    getDocumentSign,
    getDocumentPDPA,
    getDocumentChangeContractAsync,
  ]);

  const onSelectEmpApprover = (empId: string, name: string, position: string, department: string, index: number) => {
    if (inspectors && inspectors.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const newData: InspectorModel = {
        id: undefined,
        userId: empId,
        fullName: name,
        position: position,
        department: department,
        sequence: inspectors.length + 1,
        acceptDate: undefined,
        acceptRemark: undefined,
        rejectDate: undefined,
        rejectRemark: undefined,
        status: 'Draft',
      };
      setInspectors((inspectors) => [...inspectors, newData]);
    }
  };

  const onCheckCanApprove = (datas: InspectorModel[]) => {

    const indexApprover = datas.findIndex((x) => isNull(x.delegateUserId, x.userId) === userId && x.status === 'Pending');

    if (indexApprover < 0) {
      return false;
    }

    if (indexApprover === 0) {
      return true;
    }

    return (datas[indexApprover - 1].status === 'Approve');
  };

  const onSave = async (isSendToApprove: boolean) => {
    const saveInspectors: InspectorModel[] = [];

    inspectors.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        const newData: InspectorModel = {
          id: data.id,
          userId: data.userId,
          fullName: data.fullName,
          position: data.position,
          department: data.department,
          sequence: index + 1,
          acceptDate: data.acceptDate,
          acceptRemark: data.acceptRemark,
          rejectDate: data.rejectDate,
          rejectRemark: data.rejectRemark,
          status: data.status,
        };

        saveInspectors.push(newData);
      });

    const updateData: UpdateInspectorStep3Model = {
      inspectors: saveInspectors,
      sendToApprove: isSendToApprove,
    };

    setShowSaveConfirmModal(false);

    const {
      data,
      status,
    } = await CM02Service.updateInspectorStep3Async(contractData.id, updateData);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

      return;
    }

    if (!contractData.isChange) {
      collaboraRef.current?.clickSave();
      setTimeout(async () => {
        await CM02Service.updateDocumentByIdAsync(contractData.id, docId);
        getDocument(dataContext.id);
      }, 500);
    }

    if (!contractData.isChange && isPDPADocuemnt) {
      collaboraRef.current?.clickSave();
      setTimeout(async () => {
        await CM02Service.updateDocumentPDPAByIdAsync(contractData.id, docPDPAId);
        getDocumentPDPA(dataContext.id);
      }, 500);
    }

    await updateDocumentAsync(isSendToApprove);

    toast.success('บันทึกข้อมูลสัญญาสำเร็จ');

    setContractData({
      ...contractData,
      contractAgreementInspectors: updateData.inspectors,
    });

    if (isSendToApprove) {
      setShowPendingModal(false);
      getContractByIdAsync(contractData.id);
    }
  };

  const updateDocumentAsync = async (isSendToApprove: boolean) => {
    if (dataContext.isChange) {
      collaboraRefContractChange.current?.clickSave();
      setTimeout(async () => {
        await CM02Service.updateContractDocumentChangeByIdAsync(contractData.id, docChangeContractId);
        getDocumentChangeContractAsync(dataContext.id);
      }, 500);

    } else if (isSendToApprove) {
      collaboraRef.current?.clickSave();
      collaboraPDPARef.current?.clickSave();
      setTimeout(async () => {
        await CM02Service.updateContractSignDocumentByIdAsync(contractData.id, docSignId, isSendToApprove);
        getDocumentSign(dataContext.id);

        // await CM02Service.updateDocumentPDPAByIdAsync(contractData.id, docPDPAId);

        // getDocument(dataContext.id);
        // getDocumentPDPA(dataContext.id);
      }, 500);
    }
  };

  const getContractByIdAsync = async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getContractByIdAsync(id);
    if (status === HttpStatusCode.OK) {
      setDataContext(data);
    }
  };

  const removeInspector = (i: number) => {
    const newData = [...inspectors];

    newData.splice(i, 1);
    setInspectors(newData);
  };

  const onApprove = async () => {
    setShowApproveModal(false);
    const {
      data,
      status,
    } = await CM02Service.approveStep3Async(contractData.id, approveReason);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถเห็นชอบ/อนุมัติได้');

      return;
    }

    toast.success('เห็นชอบ/อนุมัติสำเร็จ');
    getContractByIdAsync(contractData.id);
  };

  const onReject = async () => {

    if (!rejectReason) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    await updateDocumentAsync(false);

    setShowRejectModal(false);
    const {
      data,
      status,
    } = await CM02Service.rejectStep3Async(contractData.id, rejectReason);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถส่งกลับแก้ไขได้');

      return;
    }

    toast.success('ส่งกลับแก้ไขสำเร็จ');
    getContractByIdAsync(contractData.id);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Row>
          <Title text='ตรวจสอบสัญญา'
            className='fs-5 text-primary'
            extraElement={
              <Button
                variant='primary'
                onClick={() => setShowUserModal(!showUserModal)}
                disabled={isDisabled}
              >
                <FaPlus /> เพิ่มรายชื่อ
              </Button>
            } />
          <Col sm={12}
            className='mt-3'>
            <Table total={2}
              hidePagination
              className='step-bar'>
              <thead>
                <tr>
                  <th style={{ width: 50 }}>ลำดับ</th>
                  <th className='text-start'
                    style={{ width: '50%' }}>ชื่อ-นามสกุล
                  </th>
                  <th className='text-start'
                    style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                  </th>
                  <th style={{ width: 150 }}>สถานะ</th>
                  <th style={{ width: 150 }}>วันที่อนุมัติ</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {inspectors.map((data, index) => (
                  <tr key={data.id}>
                    <td className='text-center'>{index + 1}</td>
                    <td className='text-left'>
                      {data.fullName}
                      <p className='m-0 department'>
                        {data.position}
                      </p>
                    </td>
                    <td className='text-left'>
                      {data.delegateFullName &&
                        <>
                          {data.delegateFullName}
                          <p className='m-0 department'>
                            {data.delegatePositionName}
                          </p>
                        </>
                      }
                    </td>
                    <td className='text-center'>
                      {
                        data.status !== undefined && data.status !== null ?
                          <StatusCM systemStatue={data.status}
                            systemName='cm-03' />
                          : <StatusCM systemStatue='Draft'
                            systemName='cm-03' />
                      }
                    </td>
                    <td className='text-center'>{data.acceptDate !== undefined && data.acceptDate !== null ? fullDatetimeTH(data.acceptDate) : undefined}</td>
                    <td>
                      {contractData.isResponsible && (
                        contractData.status === ContractAgreementStatus.Draft
                        || contractData.status === ContractAgreementStatus.Rejected) ?
                        <div className='d-flex justify-content-end'>
                          <Button
                            variant='danger'
                            className='d-flex align-items-center gap-2'
                            onClick={() => removeInspector(index)}
                          >
                            <FaTrashAlt />
                          </Button>
                        </div> : <></>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      {dataContext.isChange && (
        <>
          <Card className='mt-3'>
            <Title
              text='เอกสารบันทึกต่อท้าย'
              className='fs-5 text-primary'
            />
            <div className='mt-3'>
              <Collabora
                docId={docChangeContractId}
                docName='doc-change-03'
                ref={collaboraRefContractChange}
                readonly={false} />
            </div>
          </Card>
        </>
      )
      }

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='เอกสารร่างสัญญาหรือข้อตกลง'
              className='fs-5 text-primary' />
            {openCollapse2 ? (
              <ArrowCollapse onClick={() => setOpenCollapse2(!openCollapse2)} openCollapse={openCollapse2} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse2(true)} openCollapse={openCollapse2} />)}
          </div>
        </div>
        <Collapse in={openCollapse2}
          className='mt-3 px-2'>
          <div className='mt-3'>
            <Collabora
              docId={docId}
              docName='doc-01'
              ref={collaboraRef}
              readonly={false || dataContext.isChange} />
          </div>
        </Collapse >
      </Card >
      {
        isPDPADocuemnt && (
          <Card className='mt-3'>
            <div className='d-flex mb-4 align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <Title text='เอกสารรักษาความลับ, สัญญา PDPA'
                  className='fs-5 text-primary' />
                {openCollapse3 ? (
                  <ArrowCollapse onClick={() => setOpenCollapse3(!openCollapse3)} openCollapse={openCollapse3} />
                ) : (
                  <ArrowCollapse onClick={() => setOpenCollapse3(true)} openCollapse={openCollapse3} />)}
              </div>
            </div>
            <Collapse in={openCollapse3}
              className='mt-3 px-2'>
              <div className='mt-3'>
                <Collabora
                  docId={docPDPAId}
                  docName='doc-03'
                  ref={collaboraPDPARef}
                  readonly={false || dataContext.isChange} />
              </div>
            </Collapse>
          </Card>
        )
      }
      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBackStep}
          text="ย้อนกลับ"
        />

        {contractData.isResponsible && (
          contractData.status === ContractAgreementStatus.Draft
          || contractData.status === ContractAgreementStatus.Rejected) ?
          <div>
            <Button
              onClick={() => setShowSaveConfirmModal(true)}
              variant='success'
            >
              <MdSave />บันทึก
            </Button>
            {
              (contractData.isResponsible && contractData.contractInvited) && (
                <Button
                  onClick={() => setShowPendingModal(true)}
                  className="me-2 ms-2"
                >
                  <MdSegment className='me-2 fs-5' />
                  ส่งตรวจสอบ
                </Button>
              )
            }
          </div>
          : <></>
        }
        {
          contractData.status === ContractAgreementStatus.WaitingForApprove && isCanApprove ?
            <div>
              <ButtonCustom
                onClick={() => setShowRejectModal(true)}
                variant='danger'
                text="ส่งกลับแก้ไข"
                icon="undo"
              />
              <ButtonCustom
                onClick={() => setShowApproveModal(true)}
                icon="checked"
                variant='success'
                text="เห็นชอบ"
              />
            </div> : <></>
        }
        {
          (contractData.status !== ContractAgreementStatus.Draft
            && contractData.status !== ContractAgreementStatus.Rejected
            && contractData.status !== ContractAgreementStatus.WaitingForApprove) || contractData.isChange ?
            <ButtonCustom
              onClick={onClickNext}
              text="ถัดไป" /> : <div style={{ width: 145 }} />
        }
      </div>
      <HistoryModal
        title='ประวัติการใช้งานร่างข้อมูลสัญญา'
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={contractData.id}
      />
      {/* <UserApproveModal
        show={showUserModal}
        onHide={() => setShowUserModal(!showUserModal)}
        onSelectItem={onSelectEmpApprover}
      /> */}
      <SearchBuNameModal
        total={userTotal}
        show={showUserModal}
        onHide={() => setShowUserModal(!showUserModal)}
        onSelect={(id, name, department, position) => onSelectEmpApprover(id, name, position!, department!, inspectors.length + 1)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <Modal
        show={showSaveConfirmModal}
        size='lg'
        onHide={() => setShowSaveConfirmModal(!showSaveConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการบันทึกข้อมูลหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowSaveConfirmModal(!showSaveConfirmModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(false)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showPendingModal}
        size='lg'
        onHide={() => setShowPendingModal(!showPendingModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งตรวจสอบ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งตรวจสอบหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingModal(!showPendingModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApproveModal}
        size='lg'
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ความเห็นชอบพิจารณา</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body>
              <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                onChange={(val) => setApproveReason(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApproveModal(!showApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='success'
                onClick={onApprove}
              >
                <MdSegment className='me-2 pb-1 fs-5' />
                อนุมัติ
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejectModal}
        size='lg'
        onHide={() => setShowRejectModal(!showRejectModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body>
              <InputTextArea label='หมายเหตุ'
                onChange={(val) => setRejectReason(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowRejectModal(!showRejectModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={onReject}
              >
                <FaUndo className='me-2 pb-1 fs-5' />ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div >
  );
}
