import {
  Input,
  PaginationRefType,
  Selector,
  Table,
} from 'components';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import {
  BuDepartmentListResponse,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
} from '../../models';
import { PositionListResponse } from '../../models/positionModel';
import {
  department,
  shareValue,
} from '../../services';
import { HttpStatusCode } from '../../utils';
import { calculateRowNumber } from '../../utils/constants/calculateRowNumber';
import toast from '../../utils/toast';
import { Modal } from './Modal';

interface Props {
  show: boolean;
  onHide?: () => void;
  data: UserListSelectionResponse[];
  total?: number;
  onSelectItem?: (id: string, name: string, section?: string) => void;
  onSelect?: (
    userId: string,
    fullName: string,
    departmentName?: string,
    positionName?: string,
    delegateUserId?: string,
    delegateFullName?: string,
    delegateEmployeeCode?: string,
    delegateDepartmentName?: string,
    delegatePositionName?: string,
    delegateAnnotation?: string,
    delegateDateRange?: string,
  ) => void;
  onPageChange?: (size: number, page: number, params?: UserListSelectionByCodeParams) => void;
  onSearch?: (params: UserListSelectionByCodeParams) => void;
  onSelectData?: (data: any) => void;
  title?: string;
  section?: string;
  departmentCode?: string;
  departmentDisabled?: boolean;
}

interface Options {
  value: string;
  label: string;
}

export function SearchBuNameModal(props: Props) {
  const [departments, setDepartments] = useState<Options[]>([]);
  const [departmentCode, setDepartmentCode] = useState<string>('');
  const [positionId, setPositionId] = useState<string>('');
  const [positionName, setPositionName] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const childRef = useRef<PaginationRefType>(null);

  useEffect(() => {
    getDepartmentData();
  }, []);

  useEffect(() => {
    if (props.show) {
      if (props.departmentCode !== undefined) {
        setSize(10);
        setPage(1);
        setDepartmentCode(props.departmentCode);
        onSearch(props.departmentCode, '', '', '');
      } else {
        setDepartmentCode('');
        onSearch('', '', '', '');
      }
      setFullName('');
      setPositionId('');
      setPositionName('');
    }
  }, [props.show, props.departmentCode]);

  const getDepartmentData = async () => {
    const { data, status } = await department.getBuDepartmentsListAsync<BuDepartmentListResponse[]>();

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถดึงข้อมูล Department ได้');
      return;
    }

    const newData = data?.map((d) => ({ value: d.code, label: d.name }));

    setDepartments(newData);
  };

  const onSelect = (selectedItem: UserListSelectionResponse) => {
    if (props.onSelectItem) {
      props.onSelectItem(
        selectedItem.id,
        selectedItem.fullName,
        props.section);
    }

    if (props.onSelect) {
      props.onSelect(
        selectedItem.id,
        selectedItem.fullName,
        selectedItem.departmentName,
        selectedItem.positionName,
        selectedItem.delegateUserId,
        selectedItem.delegateFullName,
        selectedItem.delegateEmployeeCode,
        selectedItem.delegateDepartmentName,
        selectedItem.delegatePositionName,
        selectedItem.delegateAnnotation,
        selectedItem.delegateDateRange,);
    }

    if (props.onSelectData) {
      const data: any = {
        id: selectedItem.id,
        fullName: selectedItem.fullName,
        departmentName: selectedItem.departmentName,
        positionName: selectedItem.positionName,

      };

      props.onSelectData(data);
    }

    if (props.onHide) {
      props.onHide();
    }
  };

  const onSearch = useCallback((departmentCode: string, positionId: string, positionName: string, fullName: string) => {
    childRef.current?.PaginationClear();
    childRef.current?.CountRowClear();

    if (props.onSearch) {
      setDepartmentCode(departmentCode);
      setPositionId(positionId);
      setPositionName(positionName);
      setFullName(fullName);

      props.onSearch({
        departmentCode,
        positionId,
        positionName,
        fullName,
      });
    }
  }, [props]);

  const onPageSizeChange = useCallback((size: number, page: number) => {
    setSize(size);
    setPage(page);

    if (props.onPageChange) {
      props.onPageChange(size, page, {
        departmentCode,
        positionId,
        positionName,
        fullName,
      });
    }
  }, [departmentCode, fullName, positionId, positionName, props]);

  function clearInputSearch() {
    if (!props.departmentDisabled) {
      setDepartmentCode(preState => preState = departmentCode);
    }
    setPositionId('');
    setPositionName('');
    setFullName('');
    setPage(1);
    setSize(10);
    onSearch('', '', '', '');

    childRef.current?.PaginationClear();
    childRef.current?.CountRowClear();
  }

  return (
    <Modal
      show={props.show}
      size="xl"
      onHide={() => (props.onHide ? props.onHide() : null)}
    >
      <ModalBT.Header closeButton>
        <ModalBT.Title>{props.title ? props.title : "รายชื่อ"}</ModalBT.Title>
      </ModalBT.Header>
      <ModalBT.Body className="p-0 ps-4">
        <Row className="mt-3 align-items-center">
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label="ชื่อ-นามสกุล"
              value={fullName}
              onChange={setFullName}
              placeholder='ชื่อ-นามสกุล' />
          </Col>
          {
            (!props.departmentDisabled) && (
              <Col sm={12}
                md={4}
                xl={3}>
                <Selector label="ฝ่าย/สำนัก"
                  items={departments}
                  value={departmentCode}
                  onChange={setDepartmentCode}
                  disabled={props.departmentDisabled !== undefined ? props.departmentDisabled : false} />
              </Col>
            )
          }
          <Col sm={12}
            md={4}
            xl={3}>
            <Input
              value={positionName}
              label='ตำแหน่ง'
              placeholder='ตำแหน่ง'
              onChange={setPositionName}
            />
          </Col>
        </Row>
        <div className="d-flex gap-2">
          <Button
            variant="primary"
            className="px-3"
            onClick={() => {
              onSearch(departmentCode, positionId, positionName, fullName);
            }}
          >
            <FaSearch className="me-2" />ค้นหา
          </Button>
          <Button
            className="px-4"
            variant="light"
            onClick={() => { clearInputSearch(); }}
          >
            ล้าง
          </Button>
        </div>
        <Table className="my-3"
          onChange={onPageSizeChange}
          total={props.total}
          ref={childRef}>
          <thead>
            <tr>
              <th style={{ minWidth: 20 }}>ลำดับ</th>
              <th style={{ minWidth: 50 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 50 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
              <th style={{ minWidth: 25 }} />
            </tr>
          </thead>
          <tbody>
            {
              props.data.map((data, index) => (
                <tr key={index}>
                  <td className="text-center">{calculateRowNumber(index, page, size)}</td>
                  <td>{data.fullName}</td>
                  <td>{data.departmentName}</td>
                  <td>{data.positionName} </td>
                  <td>
                    <Button variant="primary"
                      className="px-3"
                      onClick={() => onSelect(data)}>
                      เลือก
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}