import StepProgress from 'components/StepProgress';
import {
  ApproverMedianPrice,
  MedianPrice,
  MedianPriceDocument,
  MedianPriceSource,
} from 'models';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useLoaderData,
  useParams,
} from 'react-router-dom';
import RefPriceStep1 from './step1';
import RefPriceStep2 from './step2';
import RefPriceStep3 from './step3';
import RefPriceStep4 from './step4';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

interface Stepper {
  title: JSX.Element;
  displayIndex: string;
  child: JSX.Element;
}

type Loader = { medianPriceData: MedianPrice };

type MedianPriceContext = {
  medianPrice: MedianPrice,
  setMedianPrice: Dispatch<SetStateAction<MedianPrice>>,
};

export const Context = createContext({} as MedianPriceContext);

export default function PP0208Detail() {
  const loader = useLoaderData() as Loader;
  const [stepper, setStepper] = useState<Stepper[]>([]);
  const childRef = useRef<ProgressBarRefType>(null);
  const [medianPrice, setMedianPrice] = useState<MedianPrice>({
    sources: [] as MedianPriceSource[],
    approvers: [] as ApproverMedianPrice[],
    documents: [] as MedianPriceDocument[],
  } as MedianPrice);
  const { planId } = useParams();

  useEffect(() => {
    getDetail();
    if (loader.medianPriceData) {
      setMedianPrice(loader?.medianPriceData);
    } else if (planId) {
      setMedianPrice({
        planId,
      } as MedianPrice);
    }
  }, []);

  const getDetail = () => {
    setStepper([
      {
        title: <p>ข้อมูลราคากลาง (ราคาอ้างอิง)</p>,
        displayIndex: '1',
        child: <RefPriceStep1 onClickNext={() => clickNext()} />,
      },
      {
        title: <p>แหล่งที่มาของราคากลาง (ราคากลาง)</p>,
        displayIndex: '2',
        child: <RefPriceStep2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
      },
      {
        title: <p>คณะกรรมการกำหนดราคากลาง</p>,
        displayIndex: '3',
        child: <RefPriceStep3 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
      },
      {
        title: <p>ตัวอย่างเอกสาร ตารางแสดงวงเงินงบประมาณที่ได้รับจัดสรรและราคากลาง (ราคาอ้างอิง)</p>,
        displayIndex: '4',
        child: <RefPriceStep4 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
      },
    ]);
  };

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  return (
    <Context.Provider value={{ medianPrice, setMedianPrice }}>
      <div className="document">
        <p className="text-center fs-4">
          วงเงินงบประมาณที่ได้รับจัดสรรคและราคากลาง (ราคาอ้างอิง)
        </p>
        <Row>
          <Col xs={12} className="mb-2">
            <StepProgress progressBarData={stepper} ref={childRef} />
          </Col>
        </Row>
      </div>
    </Context.Provider>
  );
}
