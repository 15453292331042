import {
  Input,
  Modal,
  Readonly,
} from 'components';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  MdSave,
} from 'react-icons/md';
import { useNavigate } from 'react-router';

interface Props {
  show: boolean;
  onHide: () => void;
  entrepreneurName?: string;
  entrepreneurEmail: string;
  onData?: (email: string) => void;
  title: string;
}

export function SendMailToEntrepreneurAsyncModal(props: Props) {
  const [showComment, setShowComment] = useState(false);
  const navigate = useNavigate();
  const [showRemark, setShowRemark] = useState<boolean[]>([]);
  const [entrepreneurEmail, setentrepreneurEmail] = useState('');

  useEffect(() => {
    if (props.entrepreneurEmail) {
      setentrepreneurEmail(props.entrepreneurEmail);
    }
  }, [props.entrepreneurEmail]);

  const onSubmitAsync = async (email: string) => {
    props.onData!(email);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      size='lg'
      onHide={() => props.onHide()}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>{props.title}</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body>
            <form>
              <Row>
                <Col sm={12}
                  md={12}>
                  <Readonly
                    label='คู่ค้า'
                    textReadonly={props.entrepreneurName ?? ''} />
                </Col>
                <Col sm={12}
                  md={12}>
                  <Input label='E-mail'
                    placeholder='E-mail'
                    rule={{ required: true }}
                    value={entrepreneurEmail}
                    onChange={val => setentrepreneurEmail(val)}
                  />
                </Col>
                <Col sm={12}
                  className='d-flex justify-content-center gap-3'>
                  <Button
                    variant='success'
                    className='me-2 px-3'
                    onClick={() => onSubmitAsync(entrepreneurEmail)}
                  >
                    <MdSave className='mx-2 pb-1 fs-4' />
                    บันทึก
                  </Button>
                </Col>
              </Row>
            </form>
          </ModalBT.Body>
        </>
      )}
    />
  );
}
