import {
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  CreateTor,
  ItemModel,
} from 'models';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row as RowBT,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { shareValue } from 'services';
import {
  HttpStatusCode,
  submitForm,
} from 'utils';
import { CreateTorContext } from './index';

type Loader = { departments: ItemModel[], planDropDownList: ItemModel[], supplyMethods: ItemModel[], supplyMethodTypes: ItemModel[] };

interface Props {
  onClickNext: (value: CreateTor) => void;
}

function TORDetailStep1(props: Props) {
  const contextData = useContext(CreateTorContext);
  const { departments, planDropDownList, supplyMethods, supplyMethodTypes } = useLoaderData() as Loader;
  const [createTorData, setCreateTorData] = useState<CreateTor>({} as CreateTor);
  const [supplyMethodParentLists, setSupplyMethodParentLists] = useState<ItemModel[]>([]);
  const navigate = useNavigate();

  // FIXME: Delete HardCode
  const fileDataList = [
    { label: 'รูปแบบเอกสาร 1', value: 'ebd9ea04-f872-11ed-b67e-0242ac120002' },
  ];

  useEffect(() => {
    if (contextData) {
      setCreateTorData(contextData);
    }
  }, [contextData]);

  const backToIndex = () => {
    navigate('/pp/pp0204');
  };

  const nextStep = () => {
    submitForm({
      departmentId: createTorData.departmentId,
      planName: createTorData.planName,
      supplyMethod: createTorData.supplyMethod,
      supplyMethodType: createTorData.supplyMethodType,
      planBudget: createTorData.planBudget,
      expectingProcurementAt: createTorData.expectingProcurementAt,
      title: createTorData.title,
    });

    // if (createTorData.departmentId
    //   && createTorData.planName
    //   && createTorData.supplyMethod
    //   && createTorData.planBudget
    //   && createTorData.expectingProcurementAt
    //   && createTorData.title
    //   && createTorData.torDocuments) {
    //   props.onClickNext(createTorData);
    // }

    props.onClickNext(createTorData);
  };

  const onChangeSupplyMethod = (value: string) => {
    setCreateTorData({ ...createTorData, supplyMethod: value });
    getParentSupplymethodAsync(value);
  };

  const getParentSupplymethodAsync = async (id: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(id);

    if (status === HttpStatusCode.OK) {
      setSupplyMethodParentLists(data);
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Title text='ข้อมูลโครงการ'
            className='fs-5 text-primary' />
          <RowBT className='mt-2'>
            <Col lg={3}>
              <Selector
                name='departmentId'
                items={departments}
                label='ฝ่าย/สำนัก'
                rule={{ required: true }}
                value={createTorData.departmentId}
                onChange={(value) => setCreateTorData({ ...createTorData, departmentId: value })}
              />
            </Col>
            <Col lg={6}>
              <Selector
                items={planDropDownList}
                label='โครงการ'
                value={createTorData.planId}
                onChange={(value) => setCreateTorData({ ...createTorData, planId: value })}
              />
            </Col>
          </RowBT>
          <RowBT>
            <Col lg={9}>
              <InputTextArea
                name='planName'
                label='ชื่อโครงการ'
                rule={{ required: true }}
                value={createTorData.planName}
                onChange={(value) => setCreateTorData({ ...createTorData, planName: value })}
              />
            </Col>
          </RowBT>
          <RowBT>
            <Col lg={3}>
              <Selector
                name='supplyMethod'
                items={supplyMethods}
                label='วิธีการจัดหา'
                rule={{ required: true }}
                value={createTorData.supplyMethod}
                onChange={(value) => onChangeSupplyMethod(value)}
              />
            </Col>
            <Col lg={3}>
              <Selector
                disabledStar
                name='supplyMethodType'
                items={supplyMethodTypes}
                rule={{ required: true }}
                label='&nbsp;'
                value={createTorData.supplyMethodType}
                onChange={(value) => setCreateTorData({ ...createTorData, supplyMethodType: value })}
              />
            </Col>
            <Col lg={3}>
              <Selector
                items={supplyMethodParentLists}
                label='&nbsp;'
                value={createTorData.supplyMethodType}
                onChange={(value) => setCreateTorData({ ...createTorData, supplyMethodTypeParent: value })}
              />
            </Col>
          </RowBT>
          <RowBT>
            <Col lg={3}>
              <Input
                textEnd
                name='planBudget'
                type='number'
                label='งบประมาณโครงการ (บาท) '
                rule={{ required: true }}
                value={createTorData.planBudget}
                onChange={(value) => setCreateTorData({ ...createTorData, planBudget: parseInt(value, 10) })}
              />
            </Col>
            <Col lg={3}>
              <DatePicker
                name='expectingProcurementAt'
                label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
                rule={{ required: true }}
                value={createTorData.expectingProcurementAt}
                onChange={(value) => setCreateTorData({ ...createTorData, expectingProcurementAt: value })}
              />
            </Col>
          </RowBT>
        </Card.Body>
      </Card>
      <Card className='mt-3'>
        <Card.Body>
          <Title text='ข้อมูลโครงการ'
            className='fs-5 text-primary' />
          <Form>
            <RowBT>
              <Col sm={5}
                md={3}
                lg={3}
                xl={3}>
                <DatePicker label='วันที่เอกสารบันทึกข้อความแต่งตั้ง'
                  disabled />
              </Col>
            </RowBT>
            <RowBT>
              <Col sm={12}>
                <Input
                  name='title'
                  label='เรื่อง'
                  rule={{ required: true }}
                  value={createTorData.title}
                  onChange={(value) => setCreateTorData({ ...createTorData, title: value })}
                />
              </Col>
              <Col sm={12}>
                <Selector
                  name='torDocument'
                  label='รูปแบบเอกสาร'
                  items={fileDataList}
                  rule={{ required: true }}
                  value={createTorData.templateId}
                  onChange={(value) => setCreateTorData({ ...createTorData, templateId: value })}
                />
              </Col>
            </RowBT>
          </Form>
          <div className='d-flex justify-content-between pt-5 gap-3'>
            <ButtonCustom
              onClick={backToIndex}
              text="ย้อนกลับ"
            />
            <ButtonCustom
              onClick={nextStep}
              text="ถัดไป"
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default TORDetailStep1;
