import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  ButtonCustom,
  Card,
  Currency,
  Input,
  Selector,
  Table,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ContractAgreementParcelModel } from '../../../../../../models/CM/CM02Models';
import { THCurrency } from '../../../../../../utils';
import { ItemModel } from 'models';

interface IParcelDetailProps {
  data: ContractAgreementParcelModel[];
  unitOfMeasureDDL: ItemModel[];
  isDisabled?: boolean;
}

export function ParcelDetail(props: IParcelDetailProps) {
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const summaryTotalPrice = useCallback(() => {
    let total = 0;

    props?.data?.forEach((data) => {
      const totalPrice = data?.totalPrice;
      const installationAmount = data?.installationAmount ?? 0;
      const summary = totalPrice + installationAmount;
      total += summary;
    });

    setTotalPrice(total);
  }, [props.data]);

  useEffect(() => {
    summaryTotalPrice();
  }, [props.data]);
  return (
    <Card className='mt-3'>
      <Title text='ข้อตกลงการซื้อขาย'
        className='fs-5 text-primary' />
      <p className='ms-5'>รายการของพัสดุที่ตกลงซื้อขาย</p>
      <Row className='mt-3 align-items-center'>
        <Table hidePagination>
          <thead>
            <tr className='text-center'>
              <th>ลำดับ</th>
              <th>รายละเอียด</th>
              <th>ยี่ห้อ</th>
              <th>จำนวน</th>
              <th>หน่วย</th>
              <th>ประเภท VAT</th>
              <th>ราคา/หน่วย</th>
              <th>ราคารวมทั้งสิ้น</th>
              <th>มูลค่าภาษี</th>
              <th>ค่าติดตั้ง</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              props?.data?.map((data) => (
                <tr className='text-center'
                  key={data?.sequence}>
                  <td style={{ width: '1%' }}>{data?.sequence}</td>
                  <td style={{ width: '5%' }}><Input type='text'
                    value={data?.detail}
                    disabled={props.isDisabled} /></td>
                  <td style={{ width: '5%' }}><Input type='text'
                    value={data?.brand}
                    disabled={props.isDisabled} /></td>
                  <td style={{ width: '2%' }}><Input type='text'
                    value={data?.quantity}
                    disabled={props.isDisabled} /></td>
                  <td style={{ width: '2%' }}>
                    <Selector
                      value={data.unit}
                      items={props.unitOfMeasureDDL}
                      disabled={props.isDisabled} />
                  </td>
                  <td style={{ width: '2%' }}><Input type='text'
                    value={data?.vatType}
                    disabled={props.isDisabled} /></td>
                  <td style={{ width: '2%' }}><Currency value={data?.pricePerUnit}
                    disabled={props.isDisabled} /></td>
                  <td style={{ width: '2%' }}><Currency value={data?.totalPrice}
                    disabled={props.isDisabled} /></td>
                  <td style={{ width: '2%' }}><Currency value={data?.vatAmount}
                    disabled={props.isDisabled} /></td>
                  <td style={{ width: '2%' }}><Currency value={data?.installationAmount}
                    disabled={props.isDisabled} /></td>
                  <td style={{ width: '1%' }}><ButtonCustom icon='delete'
                    disabled={props.isDisabled} /></td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        <Col sm={12}>
          <div className='bg-soft-primary text-end'>
            <p className='text-primary inline-p'>รวมค่าสินค้าและค่าติดตั้งทั้งสิ้น</p>
            <p className='inline-p'>{THCurrency(totalPrice)}</p>
            <p className='text-primary inline-p'>บาท</p>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
