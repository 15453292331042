import { IReplaceText } from '../../models/CollaboraModel';
import http from '../../utils/axios';

const api = process.env.REACT_APP_API_URL;

// TODO: Change the body to match the API
const createMainDocumentAsync = (planId: string[], templateName: string, replaceList: IReplaceText) => {
  const body = {
    AnnualPlanIds: planId,
    templateName,
    replaceList,
  };

  return http.post(`${api}/PlanAnnouncement/main-document`, body);
};

const createInYearDocumentAsync = (planId: string) => {
  const body = {
    PlanId: planId,
  };

  return http.post(`${api}/PlanProcurement/document/in-year`, body);
};

const getDocumentPlanAnnouncementAsync = (PlanProcurementAnnouncementId: string) => {
  const body = {
    PlanProcurementAnnouncementId,
  };

  return http.post(`${api}/PlanProcurementAnnouncement/document`, body);
};

// const createDocumentApprovePrincipleAsync = (planId: string) => {
//   const body = {
//     PlanId: planId,
//   };

//   return http.get(`${api}/PlanProcurement/document-approve-principle`, body);
// };

const createDocumentApprovePrincipleAsync = (id: string) => http.get(`${api}/PlanProcurement/${id}/document-approve-principle`);

export default {
  createMainDocumentAsync,
  createInYearDocumentAsync,
  getDocumentPlanAnnouncementAsync,
  createDocumentApprovePrincipleAsync,
};