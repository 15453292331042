import {
  ButtonCustom,
  Check,
  DatePicker,
  Input,
  InputTextArea,
  Readonly,
  Selector,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import { StatusCMDelivery } from 'components/StatusCMDelivery';
import { StatusCMPaidPhase } from 'components/StatusCMPaidPhase';
import { StatusCMReceived } from 'components/StatusCMReceived';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Accordion,
  Col,
  Row,
  Tab,
  Tabs,
  Modal as ModalBT,
  Button,
} from 'react-bootstrap';
import { useLoaderData, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  ContractManagementDeliveryModel,
  ContractManagementPaidModel,
  ContractManagementReceiveModel,
  ContractManagementResultModel,
  CreateContractManagementDeliveryModel,
  CreateContractModel,
  IDelivery,
  IReceive,
  MaintenanceInfomationDeliveryModel,
  UpdateContractModel,
} from '../../../../models/CM/CM03Models';
import CM03Service from '../../../../services/CM/CM03Service';
import {
  HttpStatusCode,
  submitForm,
  thaiFormatDateWithSlash,
  THCurrency,
} from '../../../../utils';
import { generateUniqueId } from '../../../../utils/helper';
import toast from '../../../../utils/toast';
import { HistoryModal } from '../Modal/HistoryReceiveModal';
import {
  TableExpandable,
  TableExpandable2,
} from './table';
import { ItemModel } from 'models';
import { InputNumber } from 'components/Controls/InputNumber';
import { ContractType } from 'constant/PurchaseOrderApprovalConstant';
import { FaTrashAlt } from 'react-icons/fa';

type Loader = {
  maintenancePeriodTypeDDL: ItemModel[],
  periodConditionDDL: ItemModel[];
  periodTypeDDL: ItemModel[],
};

export default function CM03Detail() {
  const [contractManagement, setContractManagement] = useState<ContractManagementResultModel>({} as ContractManagementResultModel);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [contractId, setContractId] = useState<string>('');
  const { maintenancePeriodTypeDDL, periodTypeDDL, periodConditionDDL } = useLoaderData() as Loader;
  const { id, contractRecordId, financialId, entrepreneursId } = useParams();

  const getContractManagementAsync = useCallback(async (id?: string, contractRecordId?: string, financialId?: string, entrepreneursId?: string) => {

    const { data, status } = await CM03Service.getContractManagementByIdAsync(id, contractRecordId, financialId, entrepreneursId);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    setContractManagement(data);
  }, []);

  const navigate = useNavigate();

  const goToBack = () => {
    navigate('/contract-management');
  };

  useEffect(() => {

    if (id) {
      getContractManagementAsync(id, undefined);
      setContractId(id);
    } else if (contractRecordId && financialId && entrepreneursId) {
      getContractManagementAsync(undefined, contractRecordId, financialId, entrepreneursId);
    }

  }, [getContractManagementAsync, id, contractRecordId, financialId, entrepreneursId]);

  const onSave = async () => {
    submitForm();

    if (contractManagement.contractManagementDeliverys && contractManagement.contractManagementDeliverys.length > 0 && contractManagement.contractManagementDeliverys.filter((x) => (x.phaseNumber <= 0 || x.phaseNumber === undefined) || (x.periodDay === undefined || x.periodDay <= 0) || x.deliveryDueDate === undefined).length > 0) {
      return;
    }

    if (contractManagement.contractManagementDeliverys && contractManagement.contractManagementDeliverys && contractManagement.contractManagementDeliverys.filter((x) => x.amount <= 0 || x.amount === undefined).length > 0) {
      toast.warn('กรุณาระบุมูลค่ามากกว่า 0');
      return;
    }

    if (contractManagement.contractManagementDeliverys && contractManagement.contractManagementDeliverys && contractManagement.contractManagementDeliverys.filter((x) => x.percent <= 0 || x.percent === undefined).length > 0) {
      toast.warn('กรุณาระบุเปอร์เซ็น (%) มากกว่า 0');
      return;
    }

    if (contractManagement.contractManagementDeliverys && contractManagement.contractManagementDeliverys && contractManagement.contractManagementDeliverys.reduce((a, v) => a += v.percent!, 0) > 100) {
      toast.warn('กรุณาระบุจำนวนเงิน (%) รวมร้อยกว่าหรือเท่ากับ 100%');
      return;
    }

    if (id) {

      const newData: UpdateContractModel = {
        warrantyPeriod: contractManagement.warranty,
        deliveryPeriod: contractManagement.deliveryPeriod,
        contractManagementDelivery: contractManagement.contractManagementDeliverys
      };

      const { data, status } = await CM03Service.updateContractRequestAsync(id, newData);

      if (status !== HttpStatusCode.CREATED) {
        toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');
        return;
      }

    } else {

      const saveDeliverys: CreateContractManagementDeliveryModel[] = [];

      if (contractManagement.contractManagementDeliverys) {
        contractManagement.contractManagementDeliverys
          .forEach((data, index) => {
            const newData: CreateContractManagementDeliveryModel = {
              amount: data.amount,
              detail: data.detail,
              percent: data.percent,
              phaseNumber: data.phaseNumber,
              sequence: data.sequence,
              deliveryDueDate: data.deliveryDueDate,
              periodDay: data.periodDay,
            };

            saveDeliverys.push(newData);
          });
      }

      const newData: CreateContractModel = {
        contractRecordId: contractRecordId,
        entrepreneursId: entrepreneursId,
        financialId: financialId,
        warrantyPeriod: contractManagement.warranty,
        deliveryPeriod: contractManagement.deliveryPeriod,
        contractManagementDelivery: saveDeliverys
      };

      const { data, status } = await CM03Service.createContractRequestAsync(newData);

      if (status !== HttpStatusCode.CREATED) {
        toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');
        return;
      }

      toast.success('บันทึกข้อมูลสัญญาสำเร็จ');
      navigate(`/contract-management/detail/${data}`);
      getContractManagementAsync(data);
      return;
    }

    toast.success('บันทึกข้อมูลสัญญาสำเร็จ');
    getContractManagementAsync(id);
  };

  function TabsComponent() {
    return (
      <>
        <Tabs
          defaultActiveKey='1'
          id='justify-tab-example'
          className='mb-2'
          justify
        >
          <Tab eventKey='1'
            title={<p>การส่งมอบ <span className='badge'>{contractManagement.contractManagementDeliverys?.length}</span>
            </p>}>
            <ContractManagementDeliverys contractId={contractId}
              offerTotalAmount={contractManagement.offerTotalAmountVat}
              listData={contractManagement.contractManagementDeliverys}
              contractRecordType={contractManagement.contractRecordType}
              contractManagement={contractManagement}
              maintenancePeriodTypeDDL={maintenancePeriodTypeDDL}
              onSelect={(deliveryList) => setContractManagement({
                ...contractManagement, contractManagementDeliverys: deliveryList
              })}
              onSuccessAddDelivery={() => getContractManagementAsync(contractId)} />
          </Tab>
          <Tab eventKey='2'
            title={<p>การตรวจรับ <span className='badge'>{contractManagement.contractManagementReceives?.length}</span>
            </p>}>
            <ContractManagementReceives contractId={contractId}
              listData={contractManagement.contractManagementReceives}
              onSuccessAddReceive={() => getContractManagementAsync(contractId)} />
          </Tab>
          <Tab eventKey='3'
            title={
              <p>การเบิกจ่าย <span className='badge'>{contractManagement.contractManagementPaids?.length}</span>
              </p>}>
            <ContractManagementPaids
              contractId={contractId}
              listData={contractManagement.contractManagementPaids}
              onSuccessAddPaid={() => getContractManagementAsync(contractId)} />
          </Tab>
        </Tabs>
      </>
    );
  }

  return (
    <>
      <Title text='ส่งมอบและตรวจรับงาน' />
      <Row className='my-3 '>
        <Col xs={12}>
          <Accordion defaultActiveKey='0'
            className='info'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <div className='header'>
                  <p>ข้อมูลโครงการ</p>
                </div>
                <div>
                  <Row>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='เลขที่รายการจัดซื้อจัดจ้าง'
                        textReadonly={contractManagement.planNumber} />
                    </Col>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='โครงการ'
                        textReadonly={contractManagement.planName} />
                    </Col>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='คู่ค้า'
                        textReadonly={contractManagement.entrepreneur?.name ?? '-'} />
                    </Col>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='เลขที่สัญญา PO *'
                        textReadonly={contractManagement.poNumber}
                        className='show-header-info' />
                      <Readonly label='วิธีจัดหา'
                        textReadonly={contractManagement.supplyMethodName}
                        className='show-hide-info' />
                    </Col>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='เลขที่สัญญา จพ.(สบส.) *'
                        textReadonly={contractManagement.contractNumber ?? '-'}
                        className='show-header-info' />
                      <Readonly label='วิธี'
                        textReadonly={contractManagement.supplyMethodTypeName}
                        className='show-hide-info' />
                    </Col>
                    <Col sm={12}
                      md={6}
                      lg={4}>
                      <Readonly label='ลักษะณะการจ้าง'
                        textReadonly={contractManagement.supplyMethodSpecialTypeName}
                        className='show-hide-info' />
                    </Col>
                  </Row>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className='fs-5 fw-bold border-top pt-2'>ข้อมูลสัญญา</p>
                <Row>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='คู่ค้า'
                      textReadonly={contractManagement.entrepreneur?.name ?? '-'} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='Email (คู่ค้า) *'
                      textReadonly={contractManagement.entrepreneur?.email ?? '-'} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='เลขที่สัญญา PO *'
                      textReadonly={contractManagement.poNumber} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='เลขที่สัญญา จพ.(สบส.) *'
                      textReadonly={contractManagement.contractNumber ?? '-'} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='ชื่อสัญญา'
                      textReadonly={contractManagement.contractName} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='วงเงินตามสัญญา *'
                      textReadonly={THCurrency(contractManagement.offerTotalAmountVat)} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='วันที่ลงนามในสัญญา'
                      textReadonly={thaiFormatDateWithSlash(contractManagement.contractDate) ?? '-'} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='ประเภทสัญญา *'
                      textReadonly={contractManagement.contractType ?? '-'} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={3}>
                    <Readonly label='รูปแบบสัญญา *'
                      textReadonly={contractManagement.contractTemplate ?? '-'} />
                  </Col>
                </Row>

                {contractManagement.contractRecordType === ContractType.NonContract
                  ? (<>
                    <Row>
                      <Col sm={2} className='d-flex'>
                        <InputNumber
                          textCenter
                          label='กำหนดส่งมอบภายใน'
                          value={contractManagement?.deliveryPeriod?.periodYear}
                          onChange={(val) =>
                            setContractManagement({
                              ...contractManagement,
                              deliveryPeriod: {
                                ...contractManagement.deliveryPeriod,
                                periodYear: val ? Number(val) : 0,
                              }
                            })}
                        />
                        <label className='mt-5 ps-2'>ปี</label>
                      </Col>
                      <Col sm={2} className='d-flex'>
                        <InputNumber
                          className='no-label'
                          textCenter
                          value={contractManagement?.deliveryPeriod?.periodMonth}
                          onChange={(val) =>
                            setContractManagement({
                              ...contractManagement,
                              deliveryPeriod: {
                                ...contractManagement.deliveryPeriod,
                                periodMonth: val ? Number(val) : 0,
                              }
                            })}
                        />
                        <label className='mt-5 ps-2'>เดือน</label>
                      </Col>
                      <Col sm={2} className='d-flex'>
                        <InputNumber
                          textCenter
                          className='no-label'
                          value={contractManagement?.deliveryPeriod?.periodDay}
                          onChange={(val) =>
                            setContractManagement({
                              ...contractManagement,
                              deliveryPeriod: {
                                ...contractManagement.deliveryPeriod,
                                periodDay: val ? Number(val) : 0,
                              }
                            })}
                        />
                        <label className='mt-5 ps-2'>วัน</label>
                      </Col>
                      {/* <Col sm={6}
                        md={3}
                        lg={3}>
                        <Selector className='no-label'
                          placeholder='นับถัดจากวันที่ลงนามใมสัญญา'
                          items={periodConditionDDL}
                          value={contractManagement.deliveryPeriod?.startDateCondition}
                          onChange={(val) =>
                            setContractManagement({
                              ...contractManagement,
                              deliveryPeriod: {
                                ...contractManagement.deliveryPeriod,
                                startDateCondition: val,
                              }
                            })}
                          rule={{ required: true }} />
                      </Col> */}
                      <Col sm={6}
                        md={3}
                        lg={3}>
                        <DatePicker label='ครบกำหนดส่งมอบวันที่'
                          value={contractManagement?.deliveryPeriod?.dueDate}
                          onChange={(val) =>
                            setContractManagement({
                              ...contractManagement,
                              deliveryPeriod: {
                                ...contractManagement.deliveryPeriod,
                                dueDate: val,
                              }
                            })} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2} className='d-flex'>
                        <InputNumber
                          textCenter
                          label='ระยะเวลารับประกัน'
                          value={contractManagement?.warranty?.periodYear}
                          onChange={(val) =>
                            setContractManagement({
                              ...contractManagement,
                              warranty: {
                                ...contractManagement.warranty,
                                periodYear: val ? Number(val) : 0,
                              }
                            })}
                        />
                        <label className='mt-5 ps-2'>ปี</label>
                      </Col>
                      <Col sm={2} className='d-flex'>
                        <InputNumber
                          className='no-label'
                          textCenter
                          value={contractManagement?.warranty?.periodMonth}
                          onChange={(val) =>
                            setContractManagement({
                              ...contractManagement,
                              warranty: {
                                ...contractManagement.warranty,
                                periodMonth: val ? Number(val) : 0,
                              }
                            })}
                        />
                        <label className='mt-5 ps-2'>เดือน</label>
                      </Col>
                      <Col sm={2} className='d-flex'>
                        <InputNumber
                          textCenter
                          className='no-label'
                          value={contractManagement?.warranty?.periodDay}
                          onChange={(val) =>
                            setContractManagement({
                              ...contractManagement,
                              warranty: {
                                ...contractManagement.warranty,
                                periodDay: val ? Number(val) : 0,
                              }
                            })}
                        />
                        <label className='mt-5 ps-2'>วัน</label>
                      </Col>
                      {/* <Col sm={12}
                        md={3}
                        lg={3}>
                        <Selector className='no-label'
                          placeholder='นับถัดจากวันที่ลงนามใมสัญญา'
                          items={periodConditionDDL}
                          value={contractManagement.warranty?.startDateCondition}
                          onChange={(val) =>
                            setContractManagement({
                              ...contractManagement,
                              warranty: {
                                ...contractManagement.warranty,
                                startDateCondition: val,
                              }
                            })}
                          rule={{ required: true }} />
                      </Col> */}
                      <Col sm={12}
                        md={3}
                        lg={3}>
                        <DatePicker label='ครบกำหนดรับประกันวันที่'
                          value={contractManagement.warranty?.dueDate}
                          disabled />
                      </Col>
                    </Row>
                  </>)
                  : (<>
                    <Row>
                      <Col sm={12}
                        md={6}
                        lg={3}>
                        <Readonly label='กำหนดส่งมอบภายใน *'
                          textReadonly={(
                            <>
                              {/* {contractManagement.deliveryPeriod?.periodYear}
                              <span className='mx-2'>
                                {contractManagement.deliveryPeriod?.unit}
                              </span>
                              {contractManagement.deliveryPeriod?.type} */}
                              {
                                (contractManagement.deliveryPeriod?.periodYear && contractManagement.deliveryPeriod?.periodYear > 0) ? (
                                  <span>{` ${contractManagement.deliveryPeriod?.periodYear.toString()} ปี`}</span>
                                ) : (<></>)
                              }
                              {
                                (contractManagement.deliveryPeriod?.periodMonth && contractManagement.deliveryPeriod?.periodMonth > 0) ? (
                                  <span>{` ${contractManagement.deliveryPeriod?.periodMonth.toString()} เดือน`}</span>
                                ) : (<></>)
                              }
                              {
                                (contractManagement.deliveryPeriod?.periodDay && contractManagement.deliveryPeriod?.periodDay > 0) ? (
                                  <span>{` ${contractManagement.deliveryPeriod?.periodDay.toString()} วัน`}</span>
                                ) : (<></>)
                              }
                              {` ${contractManagement.deliveryPeriod?.startDateCondition}`}
                            </>
                          )} />
                      </Col>
                      <Col sm={12}
                        md={6}
                        lg={3}>
                        <Readonly label='ครบกำหนดส่งมอบวันที่'
                          textReadonly={thaiFormatDateWithSlash(contractManagement.deliveryPeriod?.dueDate) ?? '-'} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}
                        md={6}
                        lg={3}>
                        <Readonly label='ระยะเวลารับประกัน *'
                          textReadonly={(
                            <>
                              {/* {contractManagement.warranty?.count}
                              <span className='mx-2'>
                                {contractManagement.warranty?.unit}
                              </span>
                              {contractManagement.warranty?.type} */}
                              {
                                (contractManagement.warranty?.periodYear && contractManagement.warranty?.periodYear > 0) ? (
                                  <span>{` ${contractManagement.warranty?.periodYear.toString()} ปี`}</span>
                                ) : (<></>)
                              }
                              {
                                (contractManagement.warranty?.periodMonth && contractManagement.warranty?.periodMonth > 0) ? (
                                  <span>{` ${contractManagement.warranty?.periodMonth.toString()} เดือน`}</span>
                                ) : (<></>)
                              }
                              {
                                (contractManagement.warranty?.periodDay && contractManagement.warranty?.periodDay > 0) ? (
                                  <span>{` ${contractManagement.warranty?.periodDay.toString()} วัน`}</span>
                                ) : (<></>)
                              }
                              {` ${contractManagement.warranty?.startDateCondition}`}
                            </>
                          )} />
                      </Col>
                      <Col sm={12}
                        md={6}
                        lg={3}>
                        <Readonly label='ครบกำหนดรับประกันวันที่'
                          textReadonly={thaiFormatDateWithSlash(contractManagement.warranty?.dueDate) ?? '-'} />
                      </Col>
                    </Row>
                  </>)}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <Row>
        <Col className='tab-border-active'>
          {TabsComponent()}
        </Col>
      </Row>
      <Row>
      </Row>
      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom text='กลับหน้าแรก'
          onClick={() => goToBack()}
          icon='arrowback'
          variant='outline-primary' />
        {
          (contractManagement.contractRecordType === ContractType.NonContract) && (
            <div>
              <ButtonCustom variant='success'
                text='บันทึก'
                onClick={onSave}
                iconAlignRight />
            </div>
          )
        }
        <div style={{ width: 145 }} />
      </div>
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        activities={contractManagement.activities}
        title='ส่งมอบและตรวจรับงาน'
      />
    </>
  );
}

interface ContractManagementDeliverysProps {
  contractId: string;
  offerTotalAmount: number;
  listData: ContractManagementDeliveryModel[];
  contractManagement: ContractManagementResultModel;
  maintenancePeriodTypeDDL: ItemModel[];
  contractRecordType: string;
  onSuccessAddDelivery: () => void;
  onSelect?: (deliveryList: ContractManagementDeliveryModel[]) => void;
}

function ContractManagementDeliverys(props: ContractManagementDeliverysProps) {
  const [showModel, setShowModel] = useState(false);
  const [maintenance, setManagement] = useState<MaintenanceInfomationDeliveryModel>({} as MaintenanceInfomationDeliveryModel);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deliveryList, setDeliveryList] = useState<ContractManagementDeliveryModel[]>([]);

  const onCloseModal = () => {
    setShowModel(false);
  };

  useEffect(() => {
    if (props.listData) {
      setDeliveryList(props.listData)
    }
  }, [props.listData]);

  const onShowModal = () => {
    maintenance.phaseNumber = undefined;
    maintenance.deliveryDate = undefined;
    maintenance.remark = undefined;
    setShowModel(true);
  };

  const onSaveAsync = async (isUpdateStatus: boolean) => {
    submitForm();

    if (!maintenance.deliveryDate) {
      toast.warn('กรุณาระบุวันที่ส่งมอบงาน');
      return;

    }
    if (!maintenance.phaseNumber) {
      toast.warn('กรุณาระบุงวดที่');
      return;

    }

    if (!maintenance.remark) {
      toast.warn('กรุณาระบุหมายเหตุการส่งมอบ');
      return;

    }
    const createDataModel: MaintenanceInfomationDeliveryModel = {
      id: props.contractManagement.id,
      deliveryDate: maintenance.deliveryDate,
      phaseNumber: maintenance.phaseNumber,
      remark: maintenance.remark,
      maintenanceId: props.contractManagement.maintenanceInfomationId
    };

    const res = await CM03Service.createMaintenanceInfomationAsync(createDataModel);

    if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.CREATED) {
      toast.warn('ไม่สามารถบันทึกรายการตรวจรับได้');
      return;
    }

    const newId: string = res.data.id;

    if (isUpdateStatus) {
      const { data, status } = await CM03Service.onSaveDeliveryStatusAsync(newId);

      if (status !== HttpStatusCode.OK && res.status !== HttpStatusCode.CREATED) {
        toast.error('ส่งมอบไม่สำเร็จ');
      }

      toast.success('ส่งมอบสำเร็จ');
      props.onSuccessAddDelivery();
      setShowConfirmModal(false);
      setShowModel(false);
      return;
    }
    props.onSuccessAddDelivery();
    toast.success('บันทึกรายการส่งมอบสำเร็จ');
    setShowModel(false);
  };

  const addDeliveryItem = useCallback(async () => {

    const maxValueSequence = deliveryList.length > 0 ? Math.max.apply(null,
      deliveryList.map((x) => {
        return x.sequence;
      })) : 0;

    const newData: ContractManagementDeliveryModel = {
      id: '',
      amount: 0,
      contractManagementReceives: undefined,
      deliveryDate: undefined,
      deliveryDueDate: undefined,
      detail: '',
      percent: 0,
      periodDay: undefined,
      phaseNumber: maxValueSequence + 1,
      remark: undefined,
      sequence: maxValueSequence + 1,
      status: '',
    };

    const data = [...deliveryList];

    setDeliveryList((delivery) => [...delivery, newData]);

    data.push({
      id: newData.id,
      amount: newData.amount,
      contractManagementReceives: newData.contractManagementReceives,
      deliveryDate: newData.deliveryDate,
      deliveryDueDate: newData.deliveryDueDate,
      detail: newData.detail,
      percent: newData.percent,
      periodDay: newData.periodDay,
      phaseNumber: newData.phaseNumber,
      remark: newData.remark,
      sequence: newData.sequence,
      status: newData.status,
    })

    if (props.onSelect && props.contractId) {
      props.onSelect(data)
    }

  }, [deliveryList]);

  const handlerRemoveItem = (index: number) => {
    const temp: ContractManagementDeliveryModel[] = [...deliveryList];
    temp.splice(index, 1);
    setDeliveryList(temp);

    if (props.onSelect) {
      props.onSelect(temp)
    }
  };

  const OnChangeStringData = (index: number, key: string, value: string) => {
    const data = [...deliveryList];

    if (key === 'detail') {

      data[index].detail = value;
    }

    setDeliveryList(data);

    if (props.onSelect) {
      props.onSelect(data)
    }
  };

  const onChangeNumberData = (index: number, key: string, value: number) => {
    const data = [...deliveryList];

    if (key === 'percent') {

      data[index].percent = value;
      data[index].amount = (value / 100) * props.offerTotalAmount;
    } else if (key === 'phaseNumber') {
      data[index].phaseNumber = value;

    } else if (key === 'amount') {

      data[index].amount = value;
      data[index].percent = (value / props.offerTotalAmount) * 100;
    } else if (key === 'periodDay') {

      data[index].periodDay = value;
    }

    setDeliveryList(data);

    if (props.onSelect) {
      props.onSelect(data)
    }
  };

  const onChangeDate = (index: number, key: string, value: Date) => {
    const data = [...deliveryList];

    if (key === 'deliveryDueDate') {

      data[index].deliveryDueDate = value;
    }

    setDeliveryList(data);

    if (props.onSelect) {
      props.onSelect(data)
    }
  };

  return (
    <>
      <Row className='mt-3'>
        <h5 className='fw-bold'>การส่งมอบ</h5>
        {props.contractManagement.isMA && (
          <>
            <Row>
              <Col sm={12}
                lg={3}>
                <Input
                  label='ส่งมอบทุกๆ'
                  placeholder='ส่งมอบทุกๆ'
                  disabled
                  value={props.contractManagement.quantity}
                />
              </Col>
              <Col sm={12}
                lg={3}>
                <Selector className='no-label'
                  placeholder='กรุณาเลือก'
                  items={props.maintenancePeriodTypeDDL}
                  rule={{ required: true }}
                  value={props.contractManagement.periodType}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}
                lg={3}>
                <Input
                  label='รวม'
                  placeholder='รวม'
                  disabled
                  value={props.contractManagement.totalQuantity}
                />
              </Col>
              <Col sm={12}
                lg={3}>
                <Selector className='no-label'
                  placeholder='กรุณาเลือก'
                  items={props.maintenancePeriodTypeDDL}
                  rule={{ required: true }}
                  value={props.contractManagement.totalPeriodType}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}
                lg={6}>
                <InputTextArea
                  label='รายละเอียด'
                  placeholder='รายละเอียด'
                  disabled
                  value={props.contractManagement.maintenanceDescription}
                />
              </Col>
            </Row>
            <div className='d-flex justify-content-end'>
              <ButtonCustom text='เพิ่มรายการส่งมอบ'
                onClick={() => onShowModal()}
                icon="add"
              />
            </div>
          </>
        )}
        <Col sm={12}>
          {(props.contractRecordType === ContractType.NonContract) && (
            <div className='d-flex justify-content-end'>
              <ButtonCustom text='เพิ่มรายการส่งมอบ'
                onClick={() => addDeliveryItem()}
                icon="add"
              />
            </div>
          )}
          <Table hidePagination
            className='mt-3 step-bar'
            notResponse>
            <thead>
              <tr className='text-center'>
                <th style={{ width: 10 }}>งวด <span className="text-danger">*</span></th>
                <th style={{ width: '5%' }}>ระยะเวลา (วัน) <span className="text-danger">*</span></th>
                <th style={{ width: '15%' }}>ครบกำหนดส่งมอบงานในวันที่ <span className="text-danger">*</span></th>
                {props.contractRecordType === ContractType.NonContract
                  ? (
                    <>
                      <th style={{ width: 200 }}>มูลค่า <span className="text-danger">*</span></th>
                      <th style={{ width: 100 }}>เปอร์เซ็น (%) <span className="text-danger">*</span></th>
                    </>)
                  : (<th style={{ width: 100 }}>มูลค่า (%)</th>)
                }
                <th style={{ width: '20%' }}>รายละเอียดส่งมอบ</th>
                <th style={{ width: '10%' }}>สถานะ</th>
                <th style={{ width: 100 }} />
                <th style={{ width: 100 }} />
                <th style={{ width: 100 }} />
              </tr>
            </thead>
            <tbody>
              {
                deliveryList?.sort((a, b) => a.sequence - b.sequence)
                  .map((data, i) => (
                    <>
                      <TableExpandable key={i}
                        contractRecordType={props.contractRecordType}
                        index={i}
                        data={data}
                        isMa={props.contractManagement.isMA}
                        contractId={props.contractId}
                        onSuccessAddDelivery={() => props.onSuccessAddDelivery()}
                        onRemove={(index) => handlerRemoveItem(index)}
                        onSelectString={(index, key, value) => OnChangeStringData(index, key, value)}
                        onSelectNumber={(index, key, value) => onChangeNumberData(index, key, value)}
                        onSelectDate={(index, key, value) => onChangeDate(index, key, value)} />
                    </>
                  ))
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal
        show={showModel}
        onHide={() => onCloseModal()}
        size='xl'
      >
        <Modal.Header>
          <h5 className='fs-5 fw-bold'>เพิ่มรายการส่งมอบ</h5>
        </Modal.Header>
        <Row>
          <Col sm={12}
            md={6}
            lg={3}>
            <Readonly label='ส่งมอบ'
              textReadonly={`${props.contractManagement.quantity} ${props.contractManagement.periodTypeLable} ${props.contractManagement.totalQuantity} ${props.contractManagement.totalPeriodTypeLable}`}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={6}
            lg={3}>
            <Readonly label='รายละเอียด'
              textReadonly={props.contractManagement.maintenanceDescription}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={6}
            lg={2}>
            <InputNumber
              className='text-start'
              label='งวดที่'
              rule={{ required: true }}
              onChange={(val) => setManagement({
                ...maintenance,
                phaseNumber: Number(val)
              })}
              value={maintenance.phaseNumber}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={4}>
            <DatePicker label='วันที่ส่งมอบงาน'
              onChange={(val) => setManagement({
                ...maintenance,
                deliveryDate: val,
              })}
              value={maintenance.deliveryDate}
              rule={{ required: true }}
              placeholder='วันที่ส่งมอบงาน'
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={12}>
            <InputTextArea
              label='หมายเหตุการส่งมอบ'
              placeholder='หมายเหตุการส่งมอบ'
              rule={{ required: true }}
              onChange={(val) => setManagement({
                ...maintenance,
                remark: val
              })}
              value={maintenance.remark}
            />
          </Col>
        </Row>
        <div className='d-flex justify-content-end'>
          <ButtonCustom variant='outline-primary'
            onClick={() => onCloseModal()}
            text='ยกเลิก' />
          <ButtonCustom
            onClick={() => onSaveAsync(false)}
            text='บันทึกรายการส่งมอบ' />
          <ButtonCustom
            onClick={() => setShowConfirmModal(true)}
            variant='success'
            text='ยืนยันส่งมอบ' />
        </div>
      </Modal>
      <Modal
        show={showConfirmModal}
        size='lg'
        onHide={() => setShowConfirmModal(!showConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการยืนยันส่งมอบหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowConfirmModal(!showConfirmModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSaveAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

interface ContractManagementReceivesProps {
  contractId: string;
  listData: ContractManagementReceiveModel[];
  onSuccessAddReceive: () => void;
}

function ContractManagementReceives(props: ContractManagementReceivesProps) {
  const [showModelCheck, setShowModelCheck] = useState(false);
  const [deliveryList, setDeliveryList] = useState<IDelivery[]>([]);
  const [selectedDelivery, setSelectedDelivery] = useState<string[]>([]);

  useEffect(() => {
    if (props.contractId) {
      getDeliveryListAsync(props.contractId);
    }
  }, [props.contractId]);

  const getDeliveryListAsync = useCallback(async (contractId: string) => {
    const { data, status } = await CM03Service.getDeliveryListAsync(contractId);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล การตรวจรับ');

      return;
    }

    setDeliveryList(data);
  }, []);

  // TODO: Modal function
  const onCheck = (checked: boolean, id: string) => {
    if (checked) {
      setSelectedDelivery([...selectedDelivery, id]);

      return;
    }

    setSelectedDelivery(selectedDelivery.filter((item) => item !== id));
  };

  const onCloseModal = () => {
    setShowModelCheck(!showModelCheck);
    setSelectedDelivery([]);
  };

  const onSave = useCallback(async () => {
    const { status } = await CM03Service.createReceiveAsync(props.contractId, selectedDelivery);

    if (status !== HttpStatusCode.OK && status !== HttpStatusCode.CREATED) {
      toast.warn('ไม่สามารถบันทึกรายการตรวจรับได้');

      return;
    }

    toast.success('บันทึกรายการตรวจรับสำเร็จ');
    props.onSuccessAddReceive();
    setShowModelCheck(!showModelCheck);
    setSelectedDelivery([]);
  }, [props.contractId, selectedDelivery, showModelCheck]);
  // TODO: End Modal function

  return (
    <>
      <Row className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <h5 className='fw-bold mb-0'>การตรวจรับ</h5>
          <ButtonCustom text='เพิ่มรายการตรวจรับ'
            onClick={() => setShowModelCheck(!showModelCheck)}
            icon="add" />
        </div>
        <Col sm={12}>
          <Table hidePagination
            notResponse>
            <thead>
              <tr className='text-center'>
                <th style={{ width: '10%' }}>ลำดับ</th>
                <th style={{ width: '10%' }}>วันที่ประชุมตรวจรับ</th>
                <th style={{ width: '20%' }}>เลขที่ตรวจรับ</th>
                <th style={{ width: '40%' }}>รายละเอียด</th>
                <th style={{ width: 100 }}>รอการตรวจรับ</th>
                <th style={{ width: 100 }} />
                <th style={{ width: 100 }} />
              </tr>
            </thead>
            <tbody>
              {
                props.listData?.sort((a, b) => a.sequence - b.sequence)
                  .map((data, index) => (
                    <TableExpandable2 key={data.id}
                      index={index}
                      data={data}
                      contractId={props.contractId} />
                  ))
              }
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal
        show={showModelCheck}
        onHide={() => onCloseModal()}
        size='xl'
      >
        <Modal.Header>
          <h5 className='fs-5 fw-bold'>รายการตรวจรับ</h5>
        </Modal.Header>
        <Table hidePagination
          notResponse>
          <thead>
            <tr className='text-center'>
              <th style={{ width: '10%' }}>งวด</th>
              <th style={{ width: '10%' }}>วันที่ส่งมอบ</th>
              <th style={{ width: '10%' }}>มูลค่า (%)</th>
              <th style={{ width: '40%' }}>รายละเอียดส่งมอบ</th>
              <th style={{ width: 100 }}>สถานะ</th>
              <th style={{ width: 50 }}>รายการตรวจรับ</th>
            </tr>
          </thead>
          <tbody>
            {deliveryList?.map((data, index) => (
              <tr className='text-center'
                key={data.id}>
                <td>{data.sequence}</td>
                <td>{thaiFormatDateWithSlash(data.deliveryDate) ?? '-'}</td>
                <td>{THCurrency(data.amount)} ({data.percent}%)</td>
                <td className='text-start'>
                  <ul className='inside'>
                    <li>{data.remark}</li>
                  </ul>
                </td>
                <td>
                  <div className='d-flex'>
                    <StatusCMDelivery systemStatue={data.status}
                      systemName='cm-03' />
                  </div>
                </td>
                <td>
                  <Check onChange={(value) => onCheck(value, data.id)}
                    className='d-inline-block' />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className='d-flex justify-content-end'>
          <ButtonCustom variant='outline-primary'
            onClick={() => onCloseModal()}
            text='ยกเลิก' />
          <ButtonCustom
            icon='save'
            onClick={() => onSave()}
            text='บันทึกรายการตรวจรับ' />
        </div>
      </Modal>
    </>
  );
}

interface ContractManagementPaidsProps {
  contractId: string;
  listData: ContractManagementPaidModel[];
  onSuccessAddPaid: () => void;
}

function ContractManagementPaids(props: ContractManagementPaidsProps) {
  const [showModelReceiveCheck, setShowModelReceiveCheck] = useState(false);
  const [receiveList, setReceiveList] = useState<IReceive[]>([]);
  const [selectedReceives, setSelectedReceives] = useState<string[]>([]);
  const [isCheckBox, setIsCheckBox] = useState<boolean[]>([]);

  const navigate = useNavigate();

  const goToPage = (id: string) => {
    navigate(`/contract-management/detail/${props.contractId}/payment/${id}`);
  };

  useEffect(() => {
    if (props.contractId) {
      getReceiveListAsync(props.contractId);
    }
  }, [props.contractId]);

  const getReceiveListAsync = useCallback(async (contractId: string) => {
    const { data, status } = await CM03Service.getReceiveListAsync(contractId);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล การตรวจรับ');

      return;
    }

    setReceiveList(data);
  }, []);

  //TODO: Modal function
  const onChange = (checked: boolean, id: string, index: number) => {
    if (checked) {
      setSelectedReceives([...selectedReceives, id]);
      onCheckBox(index, id, selectedReceives);
      return;
    }

    setSelectedReceives(selectedReceives.filter((item) => item !== id));
    onCheckBox(index, id, selectedReceives);
  };

  const onCheckBox = (index: number, id: string, receives: string[]) => {
    setIsCheckBox((isCheckBox) => {
      const newModal = [...isCheckBox];
      if (receives.filter((item) => item === id).length > 0) {
        newModal[index] = false;
      } else {
        newModal[index] = true;
      }
      return newModal;
    });
  };

  const onCloseReceiveModal = () => {
    setShowModelReceiveCheck(!showModelReceiveCheck);
    setSelectedReceives([]);
  };

  const onSave = useCallback(async () => {
    const { status } = await CM03Service.createPaidPhaseAsync(props.contractId, selectedReceives);

    if (status !== HttpStatusCode.OK && status !== HttpStatusCode.CREATED) {
      toast.warn('ไม่สามารถบันทึกรายการตรวจรับได้');

      return;
    }

    toast.success('บันทึกรายการตรวจรับสำเร็จ');
    props.onSuccessAddPaid();
    setShowModelReceiveCheck(!showModelReceiveCheck);
    setSelectedReceives([]);
  }, [props.contractId, selectedReceives, showModelReceiveCheck]);
  // TODO: End Modal function

  return (
    <>
      <Row className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <h5 className='fw-bold mb-0'>การเบิกจ่าย</h5>
          <ButtonCustom text='เพิ่มรายการเบิกจ่าย'
            onClick={() => setShowModelReceiveCheck(!showModelReceiveCheck)}
            icon="add" />
        </div>
        <Col sm={12}>
          <Table hidePagination>
            <thead>
              <tr className='text-center'>
                <th style={{ width: '10%' }}>ลำดับ</th>
                <th style={{ width: '10%' }}>วันที่ตั้ง หนี้/ตั้งเบิก</th>
                <th style={{ width: '20%' }}>วันที่เบิกจ่าย</th>
                <th style={{ width: '40%' }}>จำนวนเงินคงเหลือ</th>
                <th style={{ width: 100 }}>สถานะตั้งหนี้ตั้งเบิก</th>
                <th>หมายเหตุ</th>
                <th style={{ width: 100 }} />
              </tr>
            </thead>
            <tbody>
              {props.listData?.sort((a, b) => a.sequence - b.sequence)
                .map((data, index) => (
                  <tr className='text-center'
                    key={generateUniqueId(index)}>
                    <td>{data.sequence}</td>
                    <td>{thaiFormatDateWithSlash(data.paidDate) ?? '--/--/----'}</td>
                    <td>{thaiFormatDateWithSlash(data.accountingPaidDate) ?? '--/--/----'}</td>
                    <td>{THCurrency(data.amount ?? 0)}</td>
                    <td>
                      <div className='d-flex'>
                        <StatusCMPaidPhase systemStatue={data.status}
                          systemName='cm-03' />
                      </div>
                    </td>
                    <td>
                      <ul className='inside'>
                        <li> {data.remark}</li>
                      </ul>
                    </td>
                    <td className='text-end'>
                      <ButtonCustom variant={data.status === 'Paid' ? 'outline-success' : 'outline-primary'}
                        text='ไปยังบันทึกรายการเบิกจ่าย'
                        icon='note-add'
                        iconAlignRight
                        onClick={() => goToPage(data.id)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal
        show={showModelReceiveCheck}
        onHide={() => onCloseReceiveModal()}
        size='xl'
      >
        <Modal.Header>
          <h5 className='fs-5 fw-bold'>รายการตั้งหนี้ตั้งเบิก</h5>
        </Modal.Header>
        <Table hidePagination>
          <thead>
            <tr className='text-center'>
              <th style={{ width: '10%' }}>ลำดับ</th>
              <th style={{ width: '20%' }}>วันที่ตรวจรับ</th>
              <th style={{ width: '20%' }}>เลขที่ตรวจรับ</th>
              <th style={{ width: '10%' }}>สถานะตรวจรับ</th>
              <th style={{ width: '40%' }}>หมายเหตุการตรวจรับ</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {receiveList?.sort((a, b) => a.sequence - b.sequence)
              .map((data, index) => (
                <tr className='text-center'
                  key={generateUniqueId(index)}>
                  <td>{data.sequence}</td>
                  <td>{thaiFormatDateWithSlash(data.receivedDate) ?? '--/--/----'}</td>
                  <td>{data.receivedNumber}</td>
                  <td>
                    <div className='d-flex'>
                      <StatusCMReceived systemStatus={data.status}
                        systemName='cm-03' />
                    </div>
                  </td>
                  <td className='text-start'>{data.remark}</td>
                  <td>
                    <Check onChange={(value) => onChange(value, data.id, index)}
                      className='d-inline-block'
                      value={isCheckBox[index]} />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className='d-flex justify-content-end'>
          <ButtonCustom variant='outline-primary'
            onClick={() => onCloseReceiveModal()}
            text='ยกเลิก' />
          <ButtonCustom
            icon='save'
            onClick={() => onSave()}
            text='บันทึกรายการตรวจรับ' />
        </div>
      </Modal>
    </>
  );
}
