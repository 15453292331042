import {
  Card,
  DatePicker,
  Input,
  Selector,
} from 'components';
import { InputTextArea } from 'components/Controls/InputTextArea';
import Title from 'components/Controls/Title';
import { documentsModel } from 'models/PR/PR0202Models';
import {
  IFile,
  ItemModel,
} from 'models/systemUtility';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { MdArrowForward } from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';
import { useLoaderData } from 'react-router';
import { shareValue } from 'services';
import { PL0201Service } from 'services/PL';
import {
  HttpStatusCode,
  submitForm,
} from 'utils';
import { Context } from './index';
import UploadFile from './UploadFile';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  onDisable?: boolean;
}

type Loader = {
  supplyMethodDDL: ItemModel[],
  supplyMethodNameDDL: ItemModel[],
  planDDL: ItemModel[]
};

function Step1({ onClickNext, onClickBack, onDisable = false }: Props) {
  const { data, setData } = useContext(Context);
  const [files, setFiles] = useState<IFile[]>([]);
  const [reason, setReason] = useState('');
  const { planDDL, supplyMethodDDL, supplyMethodNameDDL } = useLoaderData() as Loader;
  const [planId, setPlanId] = useState('');
  const [planName, setPlanName] = useState('');
  const [supplyMethod, setSupplyMethod] = useState('');
  const [supplyMethodType, setSupplyMethodType] = useState('');
  const [supplyMethodSpecialType, setSupplyMethodSpecialType] = useState('');
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);
  const [onDocName, setdocName] = useState<string[]>([]);

  useEffect(() => {
    if (data && data.docNameforDelete) {
      setdocName(data.docNameforDelete);
    }

    if (data.planId) {
      setPlanId(data.planId);
    }

    if (data.reason) {
      setReason(data.reason);
    }

    if (data.subject) {
      setReason(data.subject);
    }

    if (data && data.documents) {
      handleSetFile(data.documents);
    }

    if (data && data.planId) {
      setPlanId(data.planId);
    }

    if (data && data.planName) {
      setPlanName(data.planName);
    }

    if (data && data.supplyMethod) {
      setSupplyMethod(data.supplyMethod);
      getSupplyMethodSpecialType(data.supplyMethod);
    }

    if (data && data.supplyMethodType) {
      setSupplyMethodType(data.supplyMethodType);
    }

    if (data && data.supplyMethodSpecialType) {
      setSupplyMethodSpecialType(data.supplyMethodSpecialType);
    }
  }, []);

  const getSupplyMethodSpecialType = useCallback(async (supplyMethodId: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(supplyMethodId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setSupplyMethodSpecialDDL(data);
  }, [supplyMethod]);

  const onChangeSupplyMethod = (value: string) => {
    getSupplyMethodSpecialType(value);
    setSupplyMethod(value.toString());
  };

  const onNext = () => {
    submitForm();
    data.planId = planId;

    if (planName && supplyMethod && supplyMethodType && supplyMethodSpecialType) {
      const docUpload: File[] = [];
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });

      data.planName = planName;
      data.supplyMethod = supplyMethod;
      data.supplyMethodType = supplyMethodType;
      data.supplyMethodSpecialType = supplyMethodSpecialType;
      data.docNameforDelete = onDocName;
      setData({ ...data, files: [...docUpload] });
      onClickNext();
    }
  };

  const onGetAnnualPlanAsync = useCallback(async (planId: string) => {
    setPlanId(planId);
    const response = await PL0201Service.getPL0201ByIdAsync(planId);

    if (response.status === HttpStatusCode.OK) {
      setPlanName(response.data.planName);
      setSupplyMethod(response.data.supplyMethod);
      setSupplyMethodType(response.data.supplyMethodName);
      // setSupplyMethodSpecialTypeType(response.data.supplyMethodSpecialType);
      getSupplyMethodSpecialType(response.data.supplyMethod);
    }
  }, [data.planId]);

  const handleSetFile = (documents: documentsModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };
      setFiles((pre) => [...pre, newFile]);
    }
  };

  const removeItemAsync = useCallback(async (docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName) {
      setdocName((de) => [...onDocName, docName]);
    }
    data.docNameforDelete = onDocName;
  }, [files, data.id]);

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title text='ข้อมูลรายงานขอซื้อขอจ้าง (จพ.005)'
          className='fs-5 text-primary' />
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <DatePicker
              label='วันที่เอกสารบันทึกข้อความแต่งตั้ง'
              rule={{ required: true }}
              value={data.expectingProcurementAt}
              onChange={(val) => setData({ ...data, expectingProcurementAt: val })}
              disabled={onDisable}
            />
          </Col>
          <Col sm={12}
            md={4}>
            <Selector
              label='โครงการ'
              items={planDDL}
              value={planId}
              onChange={(val) => onGetAnnualPlanAsync(val)}
              disabled={onDisable}
            />
          </Col>
          <Col sm={12}
            md={3}>
            <Button disabled={!data.planId}
              variant='link'
              href={`/pl/pl01/detail-pl01/${data.planId}`}><TbListSearch
                className='fs-4'
              /> ไปยังโครงการ
            </Button>
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={9}>
            <InputTextArea
              label='ชื่อโครงการ'
              value={planName}
              onChange={(val) => setPlanName(val)}
              rule={{ required: true }}
              disabled={onDisable}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={6}
            xl={3}>
            <Form.Group className='mb-3'>
              <Selector
                label='วิธีการจัดหา'
                items={supplyMethodDDL}
                rule={{ required: true }}
                value={supplyMethod}
                onChange={(val) => onChangeSupplyMethod(val)}
                disabled={onDisable}
              />
            </Form.Group>
          </Col>
          <Col sm={12}
            md={6}
            xl={3}>
            <Form.Group className='mb-3'>
              <Selector
                className='no-label'
                items={supplyMethodNameDDL}
                rule={{ required: true }}
                value={supplyMethodType}
                onChange={(val) => setSupplyMethodType(val)}
                disabled={onDisable}
              />
            </Form.Group>
          </Col>
          <Col sm={12}
            md={6}
            xl={3}>
            <Form.Group className='mb-3'>
              <Selector
                className='no-label'
                items={supplyMethodSpecialDDL}
                rule={{ required: true }}
                value={supplyMethodSpecialType}
                onChange={(val) => setSupplyMethodSpecialType(val)}
                disabled={onDisable}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input
              label='วงเงินโครงการ'
              value={data.planBudget}
              onChange={(val) => setData({ ...data, planBudget: val })}
              type='number'
              rule={{ required: true }}
              disabled={onDisable}
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={9}>
            <InputTextArea
              label='เรื่อง'
              value={data.subject}
              onChange={(val) => setData({ ...data, subject: val })}
            />
          </Col>
          <Col sm={12}
            md={9}>
            <InputTextArea
              label='หลักการ/เหตุผล'
              value={data.reason}
              onChange={(val) => setData({ ...data, reason: val })}
            />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={8}>
            <UploadFile
              disabled={onDisable}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              appointProcuremntId={data.id}
            />
          </Col>
        </Row>
      </Card>
      <div className='d-flex justify-content-end pt-3'>
        <Button
          type='submit'
          className='me-2 px-3'
          onClick={onNext}
        >
          ถัดไป
          <MdArrowForward className='ms-2 pb-1 fs-5' />
        </Button>
      </div>
    </div>
  );
}

export default Step1;
