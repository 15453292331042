export const dataSearchName = [
  {
    no: '1',
    id: '5600025',
    name: 'สมหญิง รักษ์เมืองไทย',
    class: '',
    position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร',
  },
  {
    no: '2',
    id: '5600025',
    name: 'สมหญิง รักษ์เมืองไทย',
    class: '',
    position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร ',
  },
];

export const dataHistory = [
  {
    activityDate: '2023-05-30T13:16:21.766924+00:00',
    fullNameAction: 'สมหญิง รักษ์เมืองไทย',
    activity: 'พนักงานสัญญาจ้าง (ปฏิบัติงานการเงิน)เห็นชอบ/อนุมัติ',
    action: 'เห็นชอบ/อนุมัติ',
    remark: '',
  },
  {
    activityDate: '2023-05-31T09:16:21.766924+00:00',
    fullNameAction: 'สมชาย รักษ์เมืองไทย',
    activity: 'ผู้ช่วยผู้จัดการ DEC อัพเดทข้อมูล',
    action: 'อัพเดทข้อมูล',
    remark: '',
  },
];
