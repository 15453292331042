import {
  Check,
  DatePicker,
  Input,
  Modal,
  PlanChangeOrAmendType,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  CriteriaPlanProcurementList,
  ItemModel,
  PlanProcurementList,
} from 'models';
import { planStatusCount } from 'models/statusCount';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { planProcurement } from 'services';
import {
  GetMonthYearBC,
  HttpStatusCode,
  showRemoveModalAsync,
  THCurrency,
  useAppContext,
} from 'utils';
import {
  budgetDDL,
  PlanStatusText,
  PlanTypeText,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { generateUniqueId } from '../../../utils/helper';

type loader = {
  departmentDDL: ItemModel[],
  supplyMethodDDL: ItemModel[],
  changePlanData: PlanProcurementList[],
  totalRecords: number,
  statusCount: planStatusCount,
};

interface ApproveProps {
  show: boolean;
  onHide: () => void;
}

interface ListData {
  data: PlanProcurementList[];
  totalRecords: number;
}

interface Pagination {
  size: number;
  page: number;
}

interface ChangeListContext {
  criteria: CriteriaPlanProcurementList;
  setCriteria: Dispatch<SetStateAction<CriteriaPlanProcurementList>>;
  listDataTable: ListData;
  onSearch: (size: number, page: number) => void;
  pagination: Pagination;
  onShowApproveModal: () => void,
  onClear: () => void;
  checkboxSupplyMethodList: ListItem[];
  checkboxStatusList: ListItem[];
  budgetYearItems: ListItem[];
}

interface ListItem {
  label: string;
  value: string;
  count?: number;
}

const Context = createContext({} as ChangeListContext);

export default function Change() {
  const { statusCount } = useLoaderData() as loader;
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 });
  const [listDataTable, setListDataTable] = useState<ListData>({} as ListData);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
  const [checkboxSupplyMethodList, setCheckboxSupplyMethodList] = useState<ListItem[]>([]);
  const [checkboxStatusList, setCheckboxStatusList] = useState<ListItem[]>([]);
  const [budgetYearItems, setBudgetYearItems] = useState<ListItem[]>([]);
  const [criteria, setCriteria] = useState<CriteriaPlanProcurementList>({
    isAssignToMeOnly: true,
    isChanged: true,
  } as CriteriaPlanProcurementList);

  useEffect(() => {
    const supplyMethods = [
      { label: 'ทั้งหมด', value: 'All', count: statusCount.allSupplyMethod },
      { label: 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560', value: '342e4e0b-7682-48c0-b46a-97a066908c9a', count: statusCount.supplyMethod60 },
      { label: 'ข้อบังคับธนาคาร 80', value: 'a21603e4-338d-486c-85ba-5f1cac8f6ace', count: statusCount.supplyMethod80 },
    ];

    const status = [
      { label: 'ทั้งหมด', value: 'All', count: statusCount.all || 0 },
      { label: 'แบบร่างขอเปลี่ยงแปลง', value: 'DraftPlan', count: statusCount.draftPlan || 0 },
      { label: 'ส่งผอ.ฝ่ายเห็นชอบ', value: 'WaitingApprovePlan', count: statusCount.waitingApprovePlan || 0 },
      { label: 'มอบหมายงาน', value: 'Assigned', count: statusCount.assigned || 0 },
      { label: 'จัดทำเอกสารบันทึก', value: 'DraftRecordDocument', count: statusCount.draftRecordDocument || 0 },
      { label: 'รอให้ความเห็นชอบ', value: 'WaitingAcceptor', count: statusCount.waitingAcceptor || 0 },
      { label: 'เผยแพร่ประกาศแผน', value: 'Announcement', count: statusCount.announcement || 0 },
      { label: 'ส่งกลับแก้ไข', value: 'RejectPlan', count: statusCount.rejectPlan || 0 },
    ];
    setCheckboxSupplyMethodList(supplyMethods);
    setCheckboxStatusList(status);
    setCriteria({
      ...criteria,
      status: status.map(s => s.value),
      supplyMethod: supplyMethods.map(s => s.value),
    });
  }, [statusCount]);

  useEffect(() => {
    const date = new Date();
    let year = (date.getUTCFullYear() + 543) - 10;

    const items = Array.from({ length: 20 })
      .map((i) => {
        year += 1;

        return {
          label: year.toString(),
          value: year.toString(),
        };
      });

    setBudgetYearItems(items);
  }, []);

  useEffect(() => {
    searchAsync(10, 1, criteria);
  }, []);

  const searchAsync = async (size: number, page: number, criteria: CriteriaPlanProcurementList) => {
    const [changedPlan, badge] = await Promise.all([
      planProcurement.getChangePlanAsync(page, size, criteria),
      planProcurement.getPlanChangeStatusCount(page, size, criteria),
    ]);

    if (changedPlan.status === HttpStatusCode.OK) {
      setListDataTable(changedPlan.data);
    }
    if (badge.status === HttpStatusCode.OK) {
      const supplyMethods = [
        { label: 'ทั้งหมด', value: 'All', count: badge.data.allSupplyMethod },
        { label: 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560', value: '342e4e0b-7682-48c0-b46a-97a066908c9a', count: badge.data.supplyMethod60 },
        { label: 'ข้อบังคับธนาคาร 80', value: 'a21603e4-338d-486c-85ba-5f1cac8f6ace', count: badge.data.supplyMethod80 },
      ];

      const status = [
        { label: 'ทั้งหมด', value: 'All', count: badge.data.all || 0 },
        { label: 'แบบร่างขอเปลี่ยนแปลง', value: 'DraftPlan', count: badge.data.draftPlan || 0 },
        { label: 'ส่งผอ.ฝ่ายเห็นชอบ', value: 'WaitingApprovePlan', count: badge.data.waitingApprovePlan || 0 },
        { label: 'มอบหมายงาน', value: 'Assigned', count: badge.data.assigned || 0 },
        { label: 'จัดทำเอกสารบันทึก', value: 'DraftRecordDocument', count: badge.data.draftRecordDocument || 0 },
        { label: 'รอให้ความเห็นชอบ', value: 'WaitingAcceptor', count: badge.data.waitingAcceptor || 0 },
        { label: 'เผยแพร่ประกาศแผน', value: 'Announcement', count: badge.data.announcement || 0 },
        { label: 'ส่งกลับแก้ไข', value: 'RejectPlan', count: badge.data.rejectPlan || 0 },
      ];
      setCheckboxSupplyMethodList(supplyMethods);
      setCheckboxStatusList(status);
    }
  };

  const onSearch = (size: number, page: number) => {
    setPagination({ size, page });

    searchAsync(size, page, criteria);
  };

  const onClear = () => {
    const criteria = {
      supplyMethod: checkboxSupplyMethodList.map(s => s.value),
      status: checkboxStatusList.map(s => s.value),
      isAssignToMeOnly: true,
      isChanged: true,
    } as CriteriaPlanProcurementList;

    setCriteria(criteria);

    searchAsync(pagination.size, pagination.page, criteria);
  };

  const onShowApproveModal = () => {
    setShowApproveModal(true);
  };

  const valueContext = {
    criteria,
    setCriteria,
    listDataTable,
    onSearch,
    pagination,
    onShowApproveModal,
    onClear,
    checkboxStatusList,
    budgetYearItems,
    checkboxSupplyMethodList,
  };

  return (
    <Context.Provider value={valueContext}>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>ขอเปลี่ยนแปลงรายการจัดซื้อจัดจ้าง</h4>
      </div>
      <hr />
      <Criteria />
      <DataTable />
      <ModalApprove show={showApproveModal}
        onHide={() => {
          onSearch(pagination.size, pagination.page);
          setShowApproveModal(false);
        }} />
    </Context.Provider>
  );
}

function Criteria() {
  const { departmentDDL } = useLoaderData() as loader;
  const { criteria, setCriteria, onShowApproveModal, onSearch, pagination, onClear, checkboxStatusList, budgetYearItems, checkboxSupplyMethodList } = useContext(Context);

  const statusOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const status = [...checkboxStatusList.map((s) => s.value)];
        setCriteria({ ...criteria, status });

        return;
      }

      setCriteria({ ...criteria, status: [] });

      return;
    }
    if (result) {
      if ((criteria.status.length + 1) === (checkboxStatusList.length - 1)) {
        setCriteria({
          ...criteria,
          status: [
            ...criteria.status,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        status: [
          ...criteria.status,
          value,
        ],
      });
    } else {
      const status = [...criteria.status];
      const index = status.findIndex((ps) => ps === value);

      if (index >= 0) {
        status.splice(index, 1);
        const allIndex = status.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          status.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, status });
      }
    }
  };

  const supplyMethodOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const supplyMethod = [...checkboxSupplyMethodList.map((s) => s.value)];
        setCriteria({ ...criteria, supplyMethod });
        return;
      }
      setCriteria({ ...criteria, supplyMethod: [] });
      return;
    }
    if (result) {
      if ((criteria.supplyMethod.length + 1) === (checkboxSupplyMethodList.length - 1)) {
        setCriteria({
          ...criteria,
          supplyMethod: [
            ...criteria.supplyMethod,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        supplyMethod: [
          ...criteria.supplyMethod,
          value,
        ],
      });
    } else {
      const supplyMethod = [...criteria.supplyMethod];
      const index = supplyMethod.findIndex((ps) => ps === value);
      if (index >= 0) {
        supplyMethod.splice(index, 1);
        const allIndex = supplyMethod.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          supplyMethod.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, supplyMethod });
      }
    }
  };

  const search = () => {
    onSearch(pagination.size, pagination.page);
  };

  return (
    <div className='criteria'>
      <Row>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='เลขที่รายการจัดซื้อจัดจ้าง'
            value={criteria.planNumber}
            onChange={(val) => setCriteria({ ...criteria, planNumber: val })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='ฝ่าย/สำนัก'
            items={departmentDDL}
            value={criteria.departmentId}
            onChange={(val) => setCriteria({ ...criteria, departmentId: val })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='ชื่อโครงการ'
            value={criteria.name}
            onChange={(val) => setCriteria({ ...criteria, name: val })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='วงเงิน'
            items={budgetDDL}
            value={criteria.budget?.toString()}
            onChange={(val) => setCriteria({ ...criteria, budget: Number(val) })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker
            label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
            value={criteria.expectingProcurementAt}
            onChangeHasNullable={(val) => setCriteria({ ...criteria, expectingProcurementAt: val })}
            monthYearOnly
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='ปีงบประมาณ'
            items={budgetYearItems}
            value={criteria.budgetYear?.toString()}
            onChange={(val) => {
              setCriteria({ ...criteria, budgetYear: val ? parseInt(val, 10) : undefined });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}
          className='Type'>
          วิธีจัดหา
          <div className='d-flex gap-2'>
            {checkboxSupplyMethodList?.map((v, i) => (
              <Check
                key={generateUniqueId(i)}
                onChange={(val) => supplyMethodOnChange(v.value, val)}
                value={criteria.supplyMethod?.some((i) => i === v.value)}
                label={(
                  <>
                    {v.label} <Status type={StatusType.SUPPLYMETHOD}
                      value={v.value}
                      couting={v.count}
                      badge />
                  </>
                )}
              />
            ))}
          </div>
        </Col>
        <Col sm={6}
          className='Status'>
          สถานะ
          <div className='d-flex gap-2 flex-wrap'>
            {checkboxStatusList?.map((v, i) => (
              <Check
                className='w-fix-context'
                key={generateUniqueId(i)}
                onChange={(val) => statusOnChange(v.value, val)}
                value={criteria.status?.some((i) => i === v.value)}
                label={(
                  <>
                    {v.label}
                    <Status
                      type={StatusType.PROCESS}
                      value={v.value}
                      couting={v.count}
                      badge
                    />
                  </>
                )}
              />
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Check
            label='แสดงเฉพาะรายการที่ได้รับมอบหมาย'
            value={criteria.isAssignToMeOnly}
            onChange={(val) => setCriteria({ ...criteria, isAssignToMeOnly: val })}
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-between align-items-center mt-2'>
        <div className='d-flex gap-2'>
          <Button
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={search}>
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={onClear}>
            <FaEraser />ล้าง
          </Button>
        </div>
      </div>
    </div>
  );
}

function DataTable() {
  const { onSearch, pagination, listDataTable } = useContext(Context);
  const navigate = useNavigate();
  const { departmentId } = useAppContext();

  const handleRemove = async (id: string) => {
    const res = await showRemoveModalAsync();

    if (res) {
      await removePlanAsync(id);
      onSearch(pagination.size, pagination.page);
    }
  };

  const removePlanAsync = async (id: string) => {
    const response = await planProcurement.removePlanAsync(id);

    if (response.status === HttpStatusCode.NO_CONTENT) {
      toast.success('ลบรายการจัดซื้อจัดจ้างสำเร็จ');

      onSearch(pagination.size, pagination.page);
    }
  };

  const go = (data: PlanProcurementList) => {
    if (data.type === PlanTypeText.AnnualPlan) {
      return navigate(`annual/detail/${data.id}`);
    }

    if (data.type === PlanTypeText.InYearPlan) {
      return navigate(`inyear/detail/${data.id}`);
    }
  };

  return (
    <>
      <Table
        className='mt-4'
        onChange={(size, page) => onSearch(size, page)}
        total={listDataTable.totalRecords}>
        <thead>
          <tr>
            <th style={{ minWidth: 150 }}>ประเภทแผน</th>
            <th style={{ minWidth: 100 }}>เลขที่รายการจัดซื้อจัดจ้าง</th>
            <th style={{ minWidth: 80 }}>สถานะ</th>
            <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 100 }}>ชื่อโครงการ</th>
            <th style={{ minWidth: 100 }}>วงเงิน</th>
            <th style={{ minWidth: 80 }}>วิธีจัดหา</th>
            <th style={{ minWidth: 80 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {listDataTable.data?.map((data, index) => (
            <tr key={index}>
              <td>
                <Status type={StatusType.PROJECT}
                  value={data.type} />
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Button
                    variant='link'
                    onClick={() => go(data)}
                  >
                    {data.planNumber}
                  </Button>
                </div>
              </td>
              <td className='text-center'>
                <Status type={StatusType.PROCESS}
                  value={data.status}
                  planType={PlanChangeOrAmendType.IsChanged} />
              </td>
              <td className='text-start'>
                {data.department}
              </td>
              <td className='text-start text-wrap'
                style={{ minWidth: 650, width: 650 }}>
                {data.name}
              </td>
              <td className='text-end'>
                {THCurrency(data.budget)}
              </td>
              <td className='text-center'>
                {data.supplyMethod}
              </td>
              <td className='text-center'>
                {GetMonthYearBC(data.expectingProcurementAt)}
              </td>
              <td>
                <Button variant='danger'
                  onClick={() => handleRemove(data.id)}
                  disabled={data.status !== PlanStatusText.DraftPlan && data.status !== PlanStatusText.RejectPlan || departmentId !== data.departmentId}>
                  <FaTrashAlt />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

function ModalApprove(props: ApproveProps) {
  const { departmentDDL } = useLoaderData() as loader;
  const [criteria, setCriteria] = useState<CriteriaPlanProcurementList>({ isAssignToMeOnly: true, isChanged: true } as CriteriaPlanProcurementList);
  const [data, setData] = useState<PlanProcurementList[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 });
  const [total, setTotal] = useState(0);
  const [disConfirm, setDisConfirm] = useState(false);
  const [tempId, setTempId] = useState<string[]>([]);
  const { budgetYearItems } = useContext(Context);

  const getModalDataList = async (size: number, page: number, criteria: CriteriaPlanProcurementList) => {
    setCheckAll(false);
    setTempId([]);

    const response = await planProcurement.getPlanProcurementListAsync(
      page,
      size,
      PlanTypeText.ChangePlan,
      {
        ...criteria,
        isAssignToMeOnly: true,
        isChanged: true,
      });

    if (response.status === HttpStatusCode.OK) {
      setData(response.data.data);
      setTotal(response.data.totalRecords);
    }
  };

  useEffect(() => {
    if (props.show) {
      getModalDataList(pagination.size, pagination.page, {} as CriteriaPlanProcurementList);
    }
  }, [props.show]);

  const onSearchData = (size: number, page: number) => {
    setPagination({ size, page });
    getModalDataList(size, page, criteria);
  };

  const onClearCriteria = () => {
    setCriteria({} as CriteriaPlanProcurementList);
    getModalDataList(pagination.size, pagination.page, {} as CriteriaPlanProcurementList);
  };

  const sendApproveAsync = async () => {
    setDisConfirm(true);

    if (tempId.length === 0) {
      setDisConfirm(false);

      return toast.warn('ไม่มีการเลือกรายการที่จะส่งอนุมัติ');
    }

    const response = await planProcurement.approvePlanListAsync(tempId);

    if (response.status === HttpStatusCode.ACCEPTED) {
      toast.success('ส่งอนุมัติสำเร็จ');

      setDisConfirm(false);
      return props.onHide();
    }
  };

  const statusAllOnChange = (result: boolean) => {
    if (result) {
      setCheckAll(true);
      setTempId(data.map((i) => i.id));
    } else {
      setCheckAll(false);
      setTempId([]);
    }
  };

  const statusOnChange = useCallback((value: string, result: boolean) => {
    if (result) {
      const newTempId = [...tempId];

      if (!newTempId) {
        setTempId([]);
      }

      newTempId.push(value);
      setTempId(newTempId);

      if (newTempId.length === data.length) {
        setCheckAll(true);
      }

      if (newTempId.length < data.length) {
        setCheckAll(false);
      }

      return;
    }

    const tempIds = [...tempId];
    const i = tempIds.findIndex((s) => s === value);

    tempIds.splice(i, 1);
    setTempId([...tempIds]);

    if (tempIds.length < data.length) {
      setCheckAll(false);
    }
  }, [tempId]);

  return (
    <Modal show={props.show}
      size='xl'>
      <div>
        <h4>ส่งอนุมัติ</h4>
        <Row>
          <Col lg={6}>
            <Selector
              value={criteria.departmentId}
              items={departmentDDL}
              label='ฝ่าย/สำนัก'
              onChange={(value) => setCriteria({ ...criteria, departmentId: value })}
            />
          </Col>
          <Col lg={6}>
            <Selector
              value={criteria.budgetYear?.toString()}
              items={budgetYearItems}
              label='ปีงบประมาณ'
              onChange={(value) => setCriteria({ ...criteria, budgetYear: parseInt(value, 10) })}
            />
          </Col>
        </Row>
        <div className='d-flex gap-2'>
          <Button
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={() => onSearchData(pagination.size, pagination.page)}
          >
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={onClearCriteria}
          >
            <FaEraser />ล้าง
          </Button>
        </div>
        <div className='mt-3'>
          <Table total={total}
            onChange={(size, page) => onSearchData(size, page)}>
            <thead>
              <tr>
                <th>
                  <div className='d-flex justify-content-end'>
                    <Check value={checkAll}
                      onChange={(val) => statusAllOnChange(val)} />
                  </div>
                </th>
                <th style={{ minWidth: 100 }}>เลขที่รายการ</th>
                <th style={{ minWidth: 80 }}>สถานะ</th>
                <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 100 }}>วงเงิน</th>
                <th style={{ minWidth: 80 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 80 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              </tr>
            </thead>
            <tbody>
              {data.map((val, i) => (
                <tr key={val.id}>
                  <td>
                    <div className='d-flex justify-content-end'>
                      <Check
                        value={tempId?.some((s) => s === val.id)}
                        onChange={(toggle) => statusOnChange(val.id, toggle)} />
                    </div>
                  </td>
                  <td className='text-center'>{val.planNumber}</td>
                  <td className='text-center'><Status type={StatusType.PROCESS}
                    value={val.status} /></td>
                  <td className='text-start'>{val.department}</td>
                  <td className='text-end'>{THCurrency(val.budget)}</td>
                  <td className='text-center'>{val.supplyMethod}</td>
                  <td className='text-center'>{GetMonthYearBC(val.expectingProcurementAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className='d-flex justify-content-end gap-2 mt-3'>
          <Button variant='outline-primary'
            onClick={props.onHide}
            disabled={disConfirm}>
            ยกเลิก
          </Button>
          <Button onClick={sendApproveAsync}
            disabled={disConfirm}>
            ส่งอนุมัติ
          </Button>
        </div>
      </div>
    </Modal>
  );
}
