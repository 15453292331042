export const dataSearchName = [
  {
    no: '1',
    id: '5600025',
    class: 'ฝ่ายจัดหาและการพัสดุ',
    position: 'จ้างพัฒนาระบบ S1',
    provide: '80 : พิเศษ',
    limit: '1,000,000'
  },
  {
    no: '2',
    id: '5600025',
    class: 'ฝ่ายจัดหาและการพัสดุ',
    position: 'จ้างพัฒนาระบบ S1',
    provide: '80 : พิเศษ',
    limit: '1,000,000'
  }
];

export const dataHistory = [
  {
    no: '1',
    versionNo: 'V1.1.2.005',
    nameEdit: 'สมหญิง รักษ์เมืองไทย',
    dayEdit: '31/12/2021 12:22'
  },
  {
    no: '2',
    versionNo: 'V1.1.2.005',
    nameEdit: 'สมหญิง รักษ์เมืองไทย',
    dayEdit: '31/12/2021 12:22'
  }
];

export const defaultQualificationValues = [
  'มีความสามารถตามกฎหมาย',
  'ไม่เป็นบุคคลล้มละลาย',
  'ไม่อยู่ระหว่างเลิกกิจการ',
  'ไม่เป็นบุคคลซึ่งอยู่ระหว่างถูกระงับการยื่นข้อเสนอหรือทำสัญญากับหน่วยงานของรัฐไว้ชั่วคราวเนื่องจากเป็นผู้ไม่ผ่านเกณฑ์การประเมินผลการปฏิบัติงานของผู้ประกอบการตามระเบียบที่รัฐมนตรีว่าการกระทรวงการคลังกำหนดตามที่ประกาศเผยแพร่ในระบบเครือข่ายสารสนเทศของกรมบัญชีกลาง',
  'ไม่เป็นบุคคลซึ่งถูกระบุชื่อไว้ในบัญชีรายชื่อผู้ทิ้งงานและได้แจ้งเวียนชื่อให้เป็นผู้ทิ้งงาน ของหน่วยงานของรัฐในระบบเครือข่ายสารสนเทศของกรมบัญชีกลาง ซึ่งรวมถึงนิติบุคคลที่ผู้ทิ้งงานเป็นหุ้นส่วน ผู้จัดการ กรรมการผู้จัดการ ผู้บริหาร ผู้มีอำนาจในการดำเนินงานในกิจการของนิติบุคคลนั้นด้วย',
  'มีคุณสมบัติและไม่มีลักษณะต้องห้ามตามที่คณะกรรมการนโยบายการจัดซื้อจัดจ้าง และการบริหารพัสดุภาครัฐกำหนดในราชกิจจานุเบกษา',
  'เป็นบุคคลธรรมดาหรือนิติบุคคลผู้มีอาชีพตามที่จัดซื้อดังกล่าว',
  'ไม่เป็นผู้มีผลประโยชน์ร่วมกันกับผู้ยื่นข้อเสนอรายอื่นที่เข้ายื่นข้อเสนอให้แก่ธนาคาร ณ วันประกาศประกวดราคา หรือไม่เป็นผู้กระทำการอันเป็นการขัดขวางการแข่งขันอย่างเป็นธรรมในการประกวดราคาครั้งนี้',
  'ไม่เป็นผู้ได้รับเอกสิทธิ์หรือความคุ้มกัน ซึ่งอาจปฏิเสธไม่ยอมขึ้นศาลไทย เว้นแต่รัฐบาลของผู้ยื่นข้อเสนอได้มีคำสั่งให้สละเอกสิทธิ์และความคุ้มกันเช่นว่านั้น',
  'ผู้ยื่นข้อเสนอต้องลงทะเบียนในระบบจัดซื้อจัดจ้างภาครัฐด้วยอิเล็กทรอนิกส์ (Electronic Government Procurement : e – GP) ของกรมบัญชีกลาง'
];

export const defaultFineRateValues = [
  {
    supplyMethodType: '37381528-e37c-46d0-b55c-8762c1ce0141', //ซื้อ
    description: 'ราคาพัสดุที่ยังไม่ได้รับมอบ',
    fine: 0.2
  },
  {
    supplyMethodType: 'f8ef8b87-25a4-43c1-9212-7fe68f4ffbc1', //เช่า
    description: 'ราคาพัสดุที่ยังไม่ได้รับมอบ',
    fine: 0.2
  },
  {
    supplyMethodType: 'b266cf22-8cc2-40e1-a7bf-c70579160f7d', //จ้าง
    description: 'ค่าจ้างตามสัญญา แต่จะต้องไม่ต่ำกว่า 100 บาท',
    fine: 0.1
  }
];
