import { Breadcrumb } from 'components';
import StepProgress from 'components/StepProgress';
import { useRef } from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

const BREADCRUMB_INFO = [
  {
    routerName: '/pr/pr0203', label: 'จัดทำหนังสือเชิญชวนผู้ประกอบการ',
  },
  {
    routerName: '/pr/pr0203/detail', label: 'หนังสือเชิญชวนผู้ประกอบการ',
  },
];

function AddPR0203() {
  const childRef = useRef<ProgressBarRefType>(null);
  const obj = [{
    title: <p>กำหนดรูปแบบเอกสาร
      หนังสือเชิญชวนผู้ประกอบการ
    </p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p>ผู้ประกอบการที่ต้องการเชิญชวน</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>อำนาจอนุมัติ</p>,
    displayIndex: '3',
    child: <Step3 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>ตัวอย่าง
      เอกสารหนังสือเชิญชวนผู้ประกอบการ
    </p>,
    displayIndex: '4',
    child: <Step4 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();
  const backToIndex = () => {
    navigate('/pr/pr0203');
  };

  return (
    <div className="document">
      <h4 className="mt-2 mb-0 text-primary text-start">หนังสือเชิญชวนผู้ประกอบการ</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12} className="mb-2">
          <StepProgress progressBarData={obj} ref={childRef} />
        </Col>
      </Row>
    </div>
  );
}

export default AddPR0203;
