import { Badge } from 'react-bootstrap';

interface Props {
  Istatus: 'warning' | 'done';
}

function generateStatusDeliver(Istatus: string): JSX.Element | undefined {
  switch (Istatus) {
    case 'warning':
      return <Badge bg="warning" className="p-2">ระหว่างดำเนินงาน</Badge>;
    case 'done':
      return <Badge bg="success" className="p-2"> ตรวจรับแล้ว</Badge>;
    default:
    // code block
  }
}

export function StatusDeliver({ Istatus }: Props) {
  return (
    <>
      {generateStatusDeliver(Istatus)}
    </>
  );
}


function generateStatusCM(Istatus: string): JSX.Element | undefined {
  switch (Istatus) {
    case 'warning':
      return <Badge bg="warning" className="p-2">ระหว่างดำเนินงาน</Badge>;
    case 'done':
      return <Badge bg="success" className="p-2"> บันทึกผลแล้ว</Badge>;
    default:
    // code block
  }
}

export function StatusCM({ Istatus }: Props) {
  return (
    <>
      {generateStatusCM(Istatus)}
    </>
  );
}