export const showLoading = () => {
  document.dispatchEvent(new CustomEvent('onShowLoading'));
};

export const hideLoading = () => {
  document.dispatchEvent(new CustomEvent('onHideLoading'));
};

export default {
  showLoading,
  hideLoading,
};