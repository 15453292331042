import {
  Card,
  Image,
} from 'react-bootstrap';

interface Props {
  InameFile: string;
  IsizeFile: string;
}

export function ListFile({ InameFile, IsizeFile }: Props) {
  return (
    <Card className="p-2 pointer">
      <div className="d-flex justify-content-staet align-items-center">
        <Image src="/icons/icon-word.svg" alt="" width={50} />
        <div className="ps-3">
          <p className="mb-0">{InameFile}</p>
          <p className="mb-0 text-muted"><small>{IsizeFile}</small></p>
        </div>
      </div>
    </Card>
  );
}
