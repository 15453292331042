import {
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import {
  useContext,
  useEffect,
} from 'react';
import {
  Button,
  Card,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import {
  submitForm,
  thaiFormatDate,
} from 'utils';
import { Context } from '.';

const TEMPLATES = [
  { label: 'รูปแบบเอกสาร 1', value: '7690cf8e-daf8-4d19-9c51-6739c66347bb' },
];

interface Props {
  onClickNext: () => void;
}

type Loader = {
  planData: {
    planBudget: number,
    approvers: {
      id: string,
      name: string,
      position: string,
    },
  },
  departments: ItemModel[],
  supplyMethodTypes: ItemModel[],
  supplyMethods: ItemModel[],
  planDropDownList: ItemModel[],
};

export default function Step1({ onClickNext }: Props) {
  const loader = useLoaderData() as Loader;
  const context = useContext(Context);
  const navigate = useNavigate();
  const dateNow = new Date();

  useEffect(() => {
    context.setMedianPrice({ ...context.medianPrice, createdAt: dateNow });
  }, []);

  const backToIndex = () => {
    navigate('/pp/pp0208');
  };

  const onChange = (val: string, prop: string) => {
    context.setMedianPrice({ ...context.medianPrice, [prop]: val });
  };

  const next = () => {
    const data = context.medianPrice;

    submitForm({
      title: data.title,
      templateId: data.templateId,
    });

    // if (data.title && data.templateId) {
    //   onClickNext();
    // }

    onClickNext();
  };

  return (
    <>
      <Card className="mt-3">
        <Card.Body>
          <Title text="ข้อมูลแผน" className="fs-5 text-primary" />
          <Row className="mt-3 align-items-center">
            <Col sm={12} md={6} xl={3}>
              <Selector
                label="ฝ่าย/สำนัก"
                items={loader.departments}
              // rule={{ required: true }}
              />
            </Col>
            <Col sm={12} xl={6}>
              <Selector
                label="แผน"
                items={loader.planDropDownList}
              />
            </Col>
            <Col sm={12} xl={9}>
              <InputTextArea
                label="ชื่อแผน"
              // rule={{ required: true }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} xl={3}>
              <Selector
                label="วิธีการจัดหา"
                items={loader.supplyMethodTypes}
              // rule={{ required: true }}
              />
            </Col>
            <Col sm={12} md={6} xl={3}>
              <Selector
                className="no-label"
                items={loader.supplyMethods}
              // rule={{ required: true }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} xl={3}>
              <Input
                label="งบประมาณโครงการ (บาท)"
                // rule={{ required: true }}
                type="number"
              />
            </Col>
            <Col sm={12} md={6} xl={3}>
              <DatePicker
                label="ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง"
                // rule={{ required: true }}
                monthYearOnly
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body>
          <Title text="ข้อมุลโครงการ" className="fs-5 text-primary" />
          <Row>
            <Col sm={5} md={3} lg={3} xl={3}>
              <Input
                label="วันที่เอกสารบันทึกข้อความแต่งตั้ง"
                value={thaiFormatDate(dateNow)}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Input
                label="เรื่อง"
                // rule={{ required: true }}
                onChange={(val) => onChange(val, 'title')}
                value={context.medianPrice?.title}
                name="title"
              />
            </Col>
            <Col sm={12}>
              <Selector
                items={TEMPLATES}
                label="รูปแบบเอกสาร"
                // rule={{ required: true }}
                onChange={(val) => onChange(val, 'templateId')}
                value={context.medianPrice?.templateId}
                name="templateId"
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-between pt-5 gap-3">
            <ButtonCustom
              onClick={backToIndex}
              text="ย้อนกลับ"
            />
            <ButtonCustom
              onClick={next}
              text="ถัดไป"
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
