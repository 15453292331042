import {
  Check,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import { CriteriaSupplyMethodId } from 'constant/PreProcumentFlag';
import { InvitationStatusConstant } from 'constant/ProcumentInvitationStatus';
import {
  CountStatusModel,
  CountSupplyMethodModel,
  InvitationListResponseModel,
  InvitationModel,
  SearchCriteriaModel,
} from 'models/PR/InvitationModels';
import moment from 'moment';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import InvitationServices from 'services/PR/InvitationServices';
import {
  HttpStatusCode,
  THCurrency,
} from 'utils';
import toast from 'utils/toast';
import { useForm } from '../../../utils/useForm';

interface ItemModel {
  label: string;
  value: string;
}

type Loader = {
  departmentDDL: ItemModel[],
  supplyMethodDDL: ItemModel[],
};

export default function InvitationOder() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { departmentDDL, supplyMethodDDL } = useLoaderData() as Loader;
  const [formSearch, setFormSearch] = useState<SearchCriteriaModel>({} as SearchCriteriaModel);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>([]);
  const [invitationData, setInvitationData] = useState<InvitationListResponseModel>({} as InvitationListResponseModel);

  useEffect(() => {
    searchInvitationList(formSearch);
  }, [formSearch]);

  const searchInvitationList = useCallback(async (searchModel: SearchCriteriaModel) => {
    if (!searchModel.page) {
      searchModel.page = page;
    }

    if (!searchModel.size) {
      searchModel.size = size;
    }

    if (searchModel.isResponsible === undefined) {
      searchModel.isResponsible = true;
    }

    if (searchModel.inviteStatus === undefined) {
      searchModel.inviteStatus = [InvitationStatusConstant.None, InvitationStatusConstant.Draft, InvitationStatusConstant.WaitingForApprove, InvitationStatusConstant.Approved, InvitationStatusConstant.Rejected];
    }

    if (searchModel.supplyMethodType === undefined) {
      searchModel.supplyMethodType = [CriteriaSupplyMethodId.MethodId60, CriteriaSupplyMethodId.MethodId80];
    }

    const { data, status } = await InvitationServices.getListInvitationAsync(searchModel);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    getCountStatus(searchModel);
    getCountSupplyMethod(searchModel);
    setInvitationData(data);
  }, []);

  const getCountStatus = useCallback(async (searchModel: SearchCriteriaModel) => {
    const countStatusRes = await InvitationServices.getCountStatusAsync(searchModel);

    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data);
    }
  }, []);

  const getCountSupplyMethod = useCallback(async (searchModel: SearchCriteriaModel) => {
    const countMethodRes = await InvitationServices.getCountSupplyMethodAsync(searchModel);

    if (countMethodRes.status === HttpStatusCode.OK) {
      setCountSupplyMethod(countMethodRes.data);
    }
  }, []);

  const handleSetPagination = async (size: number, page: number) => {
    setPage(page);
    setSize(size);
    formSearch.page = page;
    formSearch.size = size;
    searchInvitationList(formSearch);
  };

  return (
    <div className='m01'>
      <TORDraftHeader />
      <Criteria
        setForm={setFormSearch}
        departmentList={departmentDDL}
        supplyMethodList={supplyMethodDDL}
        countStatus={countStatus}
        countSupplyMethod={countSupplyMethod}
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
      />
      <DataTable
        invitationData={invitationData.data}
        total={invitationData.totalCount}
        page={page}
        size={size}
        onChange={(size, page) => {
          handleSetPagination(size, page);
        }}
      />
    </div>
  );
}

function TORDraftHeader() {
  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>จัดทำหนังสือเชิญชวนผู้ประกอบการ</h4>
      </div>
      <hr />
    </>
  );
}

interface FormCriteriaInput {
  torNumber: string;
  jorPor05Number: string;
  departmentId: string;
  planName: string;
  planNumber: string;
  traderName: string;
  supplyMethodType: string[];
  inviteStatus: string[];
  inviteNumber: string;
  inviteFlag: string[];
}

interface CriteriaProps {
  setForm: (form: SearchCriteriaModel) => void;
  departmentList: ItemModel[];
  supplyMethodList: ItemModel[];
  countStatus: CountStatusModel[];
  countSupplyMethod: CountSupplyMethodModel[];
  page: number;
  size: number;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
}

interface StatusItemModel {
  label: string;
  value: boolean;
}

enum CriteriaSupplyMethod {
  Method60 = 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560',
  Method80 = 'ข้อบังคับธนาคาร 80',
}

function Criteria(props: CriteriaProps) {
  const [memorandumDate, setMemorandumDate] = useState<Date>();
  const [expectingProcurementDate, setExpectingProcurementDate] = useState<Date>();
  const [departmentId, setDepartmentId] = useState('');
  const [planBudgetStart] = useState<number | undefined>(undefined);
  const [planBudgetEnd] = useState<number | undefined>(undefined);
  const [statusNone, setStatusNone] = useState<boolean>(false);
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [statusDraft, setStatusDraft] = useState<boolean>(false);
  const [statusApproved, setStatusApproved] = useState<boolean>(false);

  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [statusWaitingForApprove, setStatusWaitingForApprove] = useState<boolean>(false);
  const [methodAll, setMethodAll] = useState<boolean>(false);
  const [method60, setMethod60] = useState<boolean>(false);
  const [method80, setMethod80] = useState<boolean>(false);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);
  const [supplyMethods] = useState<ItemModel[]>(props.supplyMethodList);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>(props.countStatus);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>(props.countSupplyMethod);
  const [methodLists, setMethodLists] = useState<StatusItemModel[]>([
    { value: false, label: CriteriaSupplyMethodId.MethodId60 },
    { value: false, label: CriteriaSupplyMethodId.MethodId80 },
  ]);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    { value: false, label: InvitationStatusConstant.None },
    { value: false, label: InvitationStatusConstant.Draft },
    { value: false, label: InvitationStatusConstant.WaitingForApprove },
    { value: false, label: InvitationStatusConstant.Approved },
    { value: false, label: InvitationStatusConstant.Rejected },
  ]);

  useEffect(() => {
    if (props.countStatus) {
      setCountStatus(props.countStatus);
    }
  }, [props.countStatus]);

  useEffect(() => {
    if (props.countSupplyMethod) {
      setCountSupplyMethod(props.countSupplyMethod);
    }
  }, [props.countSupplyMethod]);

  useEffect(() => {
    setIsResponsible(true);
    onChangeMethodAll(true);
    onChangeStatusAll(true);
  }, []);

  const onChangeStatusAll = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setStatusNone(true);
      setStatusDraft(true);
      setStatusWaitingForApprove(true);
      setStatusApproved(true);
      setStatusRejected(true);
    } else {
      setStatusAll(false);
      setStatusNone(false);
      setStatusDraft(false);
      setStatusWaitingForApprove(false);
      setStatusApproved(false);
      setStatusRejected(false);
    }
  };

  const onChangeMethodAll = (result: boolean) => {
    if (result) {
      setMethodAll(true);
      setMethod60(true);
      setMethod80(true);
    } else {
      setMethodAll(false);
      setMethod60(false);
      setMethod80(false);
    }
  };

  const onClear = useCallback(async () => {
    setMemorandumDate(undefined);
    setDepartmentId('');
    setExpectingProcurementDate(undefined);
    setIsResponsible(true);

    onChangeMethodAll(true);
    onChangeStatusAll(true);
    props.setPage(1);
    props.setSize(10);
    handleClear();
  }, []);

  const { handleSubmit, handleChange, data: formData, handleClear } = useForm<FormCriteriaInput>({
    initialValues: {
      torNumber: undefined,
      jorPor05Number: undefined,
      departmentId: undefined,
      planName: undefined,
      planNumber: undefined,
      traderName: undefined,
      supplyMethodType: undefined,
      inviteStatus: undefined,
      inviteFlag: undefined,
      inviteNumber: undefined,
    },
    validations: {},
    onSubmit: async () => {
      let apmDate: Date | undefined;
      let expectingDate: string | undefined;

      const status: string[] = [];
      const method: string[] = [];

      if (statusNone) {
        status.push(InvitationStatusConstant.None);
      }

      if (statusDraft) {
        status.push(InvitationStatusConstant.Draft);
      }

      if (statusWaitingForApprove) {
        status.push(InvitationStatusConstant.WaitingForApprove);
      }

      if (statusApproved) {
        status.push(InvitationStatusConstant.Approved);
      }

      if (statusRejected) {
        status.push(InvitationStatusConstant.Rejected);
      }

      if (method60) {
        method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId60)[0].value);
      }

      if (method80) {
        method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId80)[0].value);
      }

      if (memorandumDate) {
        apmDate = new Date(memorandumDate);
      }

      if ((planBudgetStart && planBudgetEnd) && (planBudgetStart > planBudgetEnd)) {
        toast.error('ไม่สามารถระบุวงเงินเริ่มต้นมากกว่าวงเงินสิ้นสุดได้');
      }

      if (expectingProcurementDate) {
        expectingDate = moment(expectingProcurementDate)
          .format('YYYY-MM-DD');
      }

      const searchModel: SearchCriteriaModel = {
        page: props.page,
        size: props.size,
        jorPor05Number: formData.jorPor05Number,
        torNumber: formData.torNumber,
        planName: formData.planName,
        planNumber: formData.planNumber,
        departmentId,
        traderName: formData.traderName,
        supplyMethodType: method,
        inviteStatus: status,
        inviteFlag: '',
        isResponsible,
        inviteNumber: formData.inviteNumber,
      };

      props.setForm(searchModel);
    },
  });

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex(s => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter(s => s.value === false).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case InvitationStatusConstant.None:
        return setStatusNone(value);
      case InvitationStatusConstant.Draft:
        return setStatusDraft(value);
      case InvitationStatusConstant.WaitingForApprove:
        return setStatusWaitingForApprove(value);
      case InvitationStatusConstant.Approved:
        return setStatusApproved(value);
      case InvitationStatusConstant.Rejected:
        return setStatusRejected(value);
    }
  };

  const onChangeCheckMethod = (methodId: string, value: boolean) => {
    const methodList = [...methodLists];
    const index = methodLists.findIndex(s => s.label === methodId);
    methodList[index].value = value;
    setMethodLists(methodList);

    if (!value) {
      setMethodAll(false);
    }

    if (methodList.filter(s => s.value === false).length === 0) {
      setMethodAll(true);
    }

    switch (methodId) {
      case CriteriaSupplyMethodId.MethodId60:
        return setMethod60(value);
      case CriteriaSupplyMethodId.MethodId80:
        return setMethod80(value);
    }
  };

  return (
    <Form onSubmit={handleSubmit}
      id='formPL01'>
      <Row className='criteria'>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่รายงานขอซื้อขอจ้าง (จพ.005)</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='เลขที่รายงานขอซื้อขอจ้าง (จพ.005)'
                value={formData.jorPor05Number || ''}
                onChange={handleChange<string>('jorPor05Number', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่รายการจัดซื้อจัดจ้าง</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='เลขที่รายการจัดซื้อจัดจ้าง'
                value={formData.planNumber || ''}
                onChange={handleChange<string>('planNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่ทำหนังสือเชิญชวนผู้ประกอบการ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={9}
                placeholder='เลขที่ทำหนังสือเชิญชวนผู้ประกอบการ'
                value={formData.inviteNumber || ''}
                onChange={handleChange<string>('inviteNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Selector
              label='ฝ่าย/สำนัก'
              items={props.departmentList}
              value={`${departmentId}`}
              onChange={(value) => (setDepartmentId(value.toString()))}
            />
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>ชื่อโครงการ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='ชื่อโครงการ'
                value={formData.planName || ''}
                onChange={handleChange<string>('planName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>ชื่อบริษัท</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='ชื่อบริษัท'
                value={formData.traderName || ''}
                onChange={handleChange<string>('traderName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12}
          md={6}
          xl={6}>
          <Form.Group className='mb-3'>
            <Form.Label>วิธีจัดหา</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={<div className='align-items-center d-flex'>ทั้งหมด <Badge className='h-50 mx-2'
                  bg='light'>{countSupplyMethod ? countSupplyMethod.reduce((a, v) => a += v.count, 0) : '0'}</Badge>
                </div>}
                value={methodAll}
                onChange={(val) => onChangeMethodAll(val)}
              />
              <Check
                label={
                  <div className='align-items-center d-flex'>{CriteriaSupplyMethod.Method80}<Badge className='h-50 mx-2'
                    bg='primary'>{countSupplyMethod && countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId80).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId80)[0].count : 0}</Badge>
                  </div>}
                value={method80}
                onChange={(val) => onChangeCheckMethod(CriteriaSupplyMethodId.MethodId80, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>{CriteriaSupplyMethod.Method60}
                  <Badge className='h-50 mx-2'
                    bg='primary'>{countSupplyMethod && countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId60).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId60)[0].count : 0}</Badge>
                </div>}
                value={method60}
                onChange={(val) => onChangeCheckMethod(CriteriaSupplyMethodId.MethodId60, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={6}
          xl={6}>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ </Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={<div className='align-items-center d-flex'>ทั้งหมด <Badge className='h-50 mx-2'
                  bg='light'>{countStatus ? countStatus.reduce((a, v) => a += v.count, 0) : '0'}</Badge></div>}
                value={statusAll}
                onChange={(val) => onChangeStatusAll(val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>ยังไม่ได้ดำเนินการ <Badge className='h-50 mx-2'
                  bg='secondary'>{countStatus.filter((x) => x.status === InvitationStatusConstant.None).length > 0 ? countStatus.filter((x) => x.status === InvitationStatusConstant.None)[0].count : 0}</Badge>
                </div>}
                value={statusNone}
                onChange={(val) => onChangeCheckStatus(InvitationStatusConstant.None, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>แบบร่าง <Badge className='h-50 mx-2'
                    bg='secondary'>
                    {countStatus && countStatus.filter((x) => x.status === InvitationStatusConstant.Draft || x.status === null).length > 0 ? countStatus.filter((x) => x.status === InvitationStatusConstant.Draft || x.status === null)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusDraft}
                onChange={(val) => onChangeCheckStatus(InvitationStatusConstant.Draft, val)}
              />
              <Check
                label={
                  <div className='align-items-center d-flex'>อยู่ระหว่างเห็นชอบ/อนุมัติ <Badge className='h-50 mx-2'
                    bg='warning'>{countStatus && countStatus.filter((x) => x.status === InvitationStatusConstant.WaitingForApprove).length > 0 ? countStatus.filter((x) => x.status === InvitationStatusConstant.WaitingForApprove)[0].count : 0}</Badge>
                  </div>}
                value={statusWaitingForApprove}
                onChange={(val) => onChangeCheckStatus(InvitationStatusConstant.WaitingForApprove, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>เห็นชอบ/อนุมัติ <Badge className='h-50 mx-2'
                  bg='success'>{countStatus && countStatus.filter((x) => x.status === InvitationStatusConstant.Approved).length > 0 ? countStatus.filter((x) => x.status === InvitationStatusConstant.Approved)[0].count : 0}</Badge>
                </div>}
                value={statusApproved}
                onChange={(val) => onChangeCheckStatus(InvitationStatusConstant.Approved, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>ส่งกลับแก้ไข<Badge className='h-50 mx-2'
                  bg='danger'>{countStatus && countStatus.filter((x) => x.status === InvitationStatusConstant.Rejected).length > 0 ? countStatus.filter((x) => x.status === InvitationStatusConstant.Rejected)[0].count : 0}</Badge>
                </div>}
                value={statusRejected}
                onChange={(val) => onChangeCheckStatus(InvitationStatusConstant.Rejected, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Check
            label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
            value={isResponsible}
            onChange={(val) => setIsResponsible(val)}
          />
        </Col>
      </Row>
      <div className='d-flex gap-2'>
        <Button form='formPL01'
          type='submit'
          variant='primary'
          className='me-2'>
          <FaSearch className='me-2' />ค้นหา
        </Button>
        <Button variant='outline-primary'
          onClick={onClear}
          form='formPL01'
          type='submit'>
          <FaEraser className='me-2' />ล้าง
        </Button>
      </div>
    </Form>
  );
}

interface DataTableProps {
  page: number;
  size: number;
  total: number;
  invitationData: InvitationModel[];
  onChange?: (size: number, page: number) => void;
}

function DataTable(props: DataTableProps) {
  const [datas, setDatas] = useState<InvitationModel[]>([]);

  const navigate = useNavigate();

  const handleSetPagination = (page: number, size: number) => {
    if (props.onChange) {
      props.onChange(size, page);
    }
  };

  useEffect(() => {
    if (props && props.invitationData) {
      setDatas(props.invitationData);
    }
  }, [props]);

  const goToPage = (jorpor05Id: string, id: string) => {
    if (id) {
      navigate(`/procurement-invitation-order/detail/${jorpor05Id}/id/${id}`);
    } else {
      navigate(`/procurement-invitation-order/detail/${jorpor05Id}`);
    }
  };

  return (
    <Table
      className='mt-4'
      total={props.total}
      onChange={(size, page) => {
        handleSetPagination(page, size);
      }}
    >
      <thead>
        <tr className='text-center'>
          <th>เลขที่รายงานขอซื้อขอจ้าง<br />(จพ.005)</th>
          <th>เลขที่รายการ<br />จัดซื้อจัดจ้าง</th>
          <th>เลขที่ทำหนังสือ<br />เชิญชวนผู้ประกอบการ</th>
          <th>สถานะ</th>
          <th>ฝ่าย/สำนัก</th>
          <th>ชื่อโครงการ</th>
          <th>วงเงิน</th>
          <th>วิธีจัดหา</th>
        </tr>
      </thead>
      <tbody>
        {
          datas?.map((item, index) => (
            <tr className='text-center'
              key={index}>
              <td>
                <div className='d-flex justify-content-center'>
                  <Button
                    variant='link'
                    onClick={() => goToPage(item.jorPor05Id, item.id)}
                  >{item.jorPor05Number}
                  </Button>
                </div>
              </td>
              <td className='text-center'>
                {item.planNumber}
              </td>
              <td className='text-center'>
                {item.inviteNumber}
              </td>
              <td>
                <Status
                  value={item.status}
                  type={StatusType.INVITATIONORDER} />
              </td>
              <td className='text-start'>
                {`${item.departmentShort} : ${item.department}`}
              </td>
              <td className='text-start text-wrap'
                style={{ minWidth: 650, width: 650 }}>
                {item.planName}
              </td>
              <td className='text-end'>
                {THCurrency(item.planBudget)}
              </td>
              <td>
                {`${item.supplyMethodValue}`}
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
}
