import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

const thDay = ['จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์', 'อาทิตย์'];
const thMonth = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];

export const fullDatetime = (value: string | Date) => {
  if (!value) {
    return '';
  }

  const dateValue = new Date(value);
  const date = (`0${dateValue.getDate()}`).slice(-2);
  const month = (`0${dateValue.getMonth() + 1}`).slice(-2);
  const year = (dateValue.getFullYear() + 543).toString();
  const hour = (`0${dateValue.getHours()}`).slice(-2);
  const minute = (`0${dateValue.getMinutes()}`).slice(-2);
  const seconds = (`0${dateValue.getSeconds()}`).slice(-2);

  return `${date}/${month}/${year} ${hour}:${minute}:${seconds}`;
};

export const fullDatetimeTH = (value: string | Date) => {
  if (!value) {
    return '';
  }
  const date = new Date(value);

  const month = (date.getMonth() + 1).toString()
    .padStart(2, '0');
  const year = date.getFullYear() < 2500 ? date.getFullYear() + 543 : date.getFullYear();

  return `${date.getDate()}/${month}/${year} ${date.getHours()}:${date.getMinutes()
    .toString()
    .padStart(2, '0')} น.`;
};

export const fullDate = (value: string | Date) => {
  if (!value) {
    return;
  }
  const date = new Date(value);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const monthYear = (value: string | Date) => {
  const date = new Date(value);
  return `${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const isoDate = (value: string | Date) => {
  const date = new Date(value);

  const year = date.getUTCFullYear();
  const month = `0${+date.getMonth() + 1}`.slice(-2);
  const day = `0${+date.getDate()}`.slice(-2);

  return `${year}-${month}-${day}`;
};

export const thaiFormatDate = (value: string | Date) => {
  if (!value) {
    return;
  }

  const date = new Date(value);

  const day = date.getDate();
  const month = thMonth.find((_, index) => index === date.getMonth());
  const year = date.getFullYear() + 543;

  return `${day} ${month} ${year}`;
};

export const expectingProcurementThaiFormant = (value: string | Date) => {
  if (!value) {
    return;
  }

  const date = new Date(value);

  const month = thMonth.find((_, index) => index === date.getMonth());
  const year = date.getFullYear() + 543;

  return `${month} ${year}`;
};

export const formatMonthAndThaiYearWithSlash = (value: string | Date) => {
  const date = new Date(value);

  const month = (date.getMonth() + 1).toString()
    .padStart(2, '0');
  const year = date.getFullYear() < 2500 ? date.getFullYear() + 543 : date.getFullYear();

  return `${month}/${year}`;
};

export const thaiFormatDateWithSlash = (value: string | Date | undefined) => {
  if (!value) {
    return;
  }

  const date = new Date(value);

  const day = date.getDate()
    .toString()
    .padStart(2, '0');
  const month = (date.getMonth() + 1).toString()
    .padStart(2, '0');
  const year = date.getFullYear() < 2500 ? date.getFullYear() + 543 : date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const thaiFormatDateWithDash = (value: string | Date | undefined) => {
  if (!value) {
    return;
  }

  const date = new Date(value);

  const day = date.getDate()
    .toString()
    .padStart(2, '0');
  const month = (date.getMonth() + 1).toString()
    .padStart(2, '0');
  const year = date.getFullYear() < 2500 ? date.getFullYear() + 543 : date.getFullYear();

  return `${day}-${month}-${year}`;
};

export const thaiFormatDateTimeWithSlash = (value: string | Date | undefined) => {
  if (!value) {
    return;
  }

  const date = new Date(value);

  const day = date.getDate()
    .toString()
    .padStart(2, '0');
  const month = (date.getMonth() + 1).toString()
    .padStart(2, '0');
  const year = date.getFullYear() < 2500 ? date.getFullYear() + 543 : date.getFullYear();

  const hour = date.getHours()
    .toString()
    .padStart(2, '0');
  const min = date.getMinutes()
    .toString()
    .padStart(2, '0');

  return `${day}/${month}/${year} ${hour}:${min}`;
};

export const GetMonthYearBC = (value: string | Date) => {
  const date = new Date(value);

  const month = (date.getMonth() + 1).toString()
    .padStart(2, '0');
  const year = date.getFullYear() < 2500 ? date.getFullYear() + 543 : date.getFullYear();

  return `${month}/${year} `;
};

export const GetFormatDateBC = (value: string | Date) => {
  if (!value) {
    return;
  }

  const date = new Date(value);

  const day = date.getDate()
    .toString()
    .padStart(2, '0');
  const month = (date.getMonth() + 1).toString()
    .padStart(2, '0');
  const year = date.getFullYear() < 2500 ? date.getFullYear() + 543 : date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const GetYearBC = (value: string | Date) => {
  const date = new Date(value);
  const year = date.getFullYear() < 2500 ? date.getFullYear() + 543 : date.getFullYear();

  return `${year} `;
};

export const GetFormatTimeBC = (value: string | Date | undefined) => {
  if (!value) {
    return;
  }

  const date = new Date(value);

  const hour = date.getHours()
    .toString()
    .padStart(2, '0');
  const min = date.getMinutes()
    .toString()
    .padStart(2, '0');

  return `${hour}:${min}`;
};

export const timeFromNow = (value: Date) => {
  dayjs.extend(updateLocale);
  dayjs.extend(relativeTime);

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'ใน %s',
      past: 'เมื่อ %s ที่แล้ว',
      s: '1 วินาที',
      m: '1 นาที',
      mm: '%d นาที',
      h: '1 ชั่วโมง',
      hh: '%d ชั่วโมง',
      d: '1 วัน',
      dd: '%d วัน',
      M: '1 เดือน',
      MM: '%d เดือน',
      y: '1 ปี',
      yy: '%d ปี',
    },
  });

  return dayjs(value)
    .fromNow();
};

export function setTimeStartDate(startDate: Date) {
  startDate.setUTCHours(0, 0, 0, 0);

  return startDate;
}

export function setTimeEndDate(endDate: Date) {
  endDate.setUTCHours(23, 59, 59, 999);

  return endDate;
}
