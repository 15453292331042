import {
  useEffect,
  useState,
} from 'react';
import { Badge } from 'react-bootstrap';

interface Props {
  value: string;
}

const STATUS = [
  {
    value: 'Draft',
    color: '#BEBEBE',
    label: 'แบบร่าง',
  },
  {
    value: 'DraftTor',
    color: '#FBBD01',
    label: 'อยู่ระหว่างร่าง TOR',
  },
  {
    value: 'WaitingForCommitteeApprove',
    color: '#FBBD01',
    label: 'อยู่ระหว่าง คกก. เห็นชอบ',
  },
  {
    value: 'WaitingForDirectorJorPorAssign',
    color: '#FBBD01',
    label: 'อยู่ระหว่างรอ ผอ.จพ. มอบหมาย',
  },
  {
    value: 'WaitingForAssignResponsible',
    color: '#FBBD01',
    label: 'อยู่ระหว่างรอมอบหมายผู้รับผิดชอบ',
  },
  {
    value: 'DraftSectionApprover',
    color: '#FBBD01',
    label: 'อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ',
  },
  {
    value: 'WaitingForApprove',
    color: '#FBBD01',
    label: 'อยู่ระหว่างเห็นชอบ/อนุมัติ',
  },
  {
    value: 'Approved',
    color: '#1BA345',
    label: 'เห็นชอบ',
  },
  {
    value: 'Cancelled',
    color: '#DE3E44',
    label: 'ยกเลิก',
  },
  {
    value: 'Rejected',
    color: '#DE3E44',
    label: 'ส่งกลับแก้ไข',
  },
  {
    value: 'Accepted',
    color: '#1BA345',
    label: 'เห็นชอบ',
  },
  {
    value: 'noImplement',
    color: '#BEBEBE',
    label: 'ยังไม่ดำเนินการ',
  },
  {
    value: 'WaitingAcceptor',
    color: '#FBBD01',
    label: 'รอเห็นชอบ',
  },
  {
    value: 'AssignJorPor',
    color: '#5946ff',
    label: 'มอบหมายสำเร็จ',
  },
  {
    value: 'Pending',
    color: '#FBBD01',
    label: 'รอเห็นชอบ',
  },
  {
    value: 'WaitingAccept',
    color: '#FBBD01',
    label: 'รอเห็นชอบ',
  },
  {
    value: 'Reject',
    color: '#DE3E44',
    label: 'ส่งกลับแก้ไข',
  },
  {
    value: 'Announcement',
    color: '#FF850D',
    label: 'ประกาศเผยแพร่',
  },
  {
    value: 'WaitingComitteeApprove',
    color: '#FBBD01',
    label: 'อยู่ระหว่าง คกก. เห็นชอบ',
  },
  {
    value: 'DraftAcceptor',
    color: '#FBBD01',
    label: 'อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ',
  },
];

const STATUS_DETAIL = [
  {
    value: 'Pending',
    color: 'warning',
    label: 'รออนุมัติ',
  },
  {
    value: 'Approved',
    color: 'success',
    label: 'เห็นชอบ/อนุมัติ',
  },
  {
    value: 'Rejected',
    color: 'danger',
    label: 'ไม่อนุมัติ',
  },
];

const STATUS_DETAIL_APPROVED = [
  {
    value: 'Pending',
    color: 'warning',
    label: 'รออนุมัติ',
  },
  {
    value: 'Approved',
    color: 'success',
    label: 'อนุมัติ',
  },
  {
    value: 'Rejected',
    color: 'danger',
    label: 'ไม่อนุมัติ',
  },
];

function Jorpor04Status(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);
  return (
    <>
      {props.value
        ? (
          <div className='tor-status'
            style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

function Jorpor04StatusDetail(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS_DETAIL.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color}
            className='text-white'>
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}

function Jorpor04StatusDetailApproved(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS_DETAIL_APPROVED.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color}
            className='text-white'>
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}

function Jorpor04StatusCommitteeApprovers(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS_DETAIL_COMITTEE.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color}
            className='text-white'>
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}

const STATUS_DETAIL_COMITTEE = [
  {
    value: 'Draft',
    color: 'secondary',
    label: 'แบบร่าง',
  },
  {
    value: 'Pending',
    color: 'warning',
    label: 'รอเห็นชอบ',
  },
  {
    value: 'Approved',
    color: 'success',
    label: 'เห็นชอบ',
  },
  {
    value: 'Rejected',
    color: 'danger',
    label: 'ไม่เห็นชอบ',
  },
];

export {
  Jorpor04StatusDetail,
  Jorpor04Status,
  Jorpor04StatusDetailApproved,
  Jorpor04StatusCommitteeApprovers,
};
