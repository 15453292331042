import {
  Card,
  Check,
  DatePicker,
  Input,
  InputTextArea,
  Radio,
  Selector,
  Table,
  TorStatus,
  TorStatusDetail,
  UploadFile,
  ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import {
  HistoryModal,
  SearchNameModal,
} from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Row,
} from 'react-bootstrap';
import {
  FaHistory,
  FaPlus,
  FaRegCheckCircle,
  FaSave,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { generateUniqueId } from '../../../../utils/helper';
import { dataHistory } from './data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const INFO = [
  {
    label: 'ละเว้น',
    value: '1',
  },
  {
    label: 'ไม่ละเว้น',
    value: '2',
  },
];

function Step1({
  onClickNext,
  onClickBack,
}: Props) {
  const [showHistory, setShowHistory] = useState(false);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [showSearchNameModal, setModalSearchNameModal] = useState(false);

  const navigate = useNavigate();
  const goToPage = () => {
    navigate('/cm/cm0201/Detail');
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title text='ข้อมูลโครงการ'
          className='fs-5 text-primary' />
        <div className='d-flex align-items-center'>
          <p className='mb-0 me-4'>เลขที่โครงการ </p>
          <Button onClick={goToPage}
            variant='link'>T0001</Button>
        </div>
        <div className='d-flex align-items-center'>
          <p className='mb-0 me-4'>ชื่อโครงการ </p>
          <p className='mb-0 ms-4'>จ้างที่ปรึกษา </p>
        </div>
      </Card>
      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <Title text='ข้อมูลใบสั่ง/สัญญา'
            className='fs-5 text-primary' />
          <div className='d-flex gap-2'>
            <TorStatus
              value='Draft' />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'>
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            xl={6}>
            <Input label='ชื่อสัญญา'
              value='สัญญาจ้างบริการบำรุงรักษาและซ่อมแซมแก้ไขอุปกรณ์สื่อสารระบบ ITMX'
              disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='เลขที่สัญญา PO'
              textCenter
              rule={{ required: true }}
              value='410007'
              disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='เลขที่สัญญา จพ.(สบส.)'
              textCenter
              rule={{ required: true }}
              value='001/2566'
              disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='วงเงินตามสัญญา'
              textEnd
              rule={{ required: true }}
              value='1,000,000.00'
              disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={2}>
            <Input label='กำหนดส่งมอบภายใน'
              placeholder='กำหนดส่งมอบภายใน'
              textCenter
              rule={{ required: true }}
              disabled />
          </Col>
          <Col sm={12}
            md={1}>
            <Selector className='no-label'
              placeholder='วัน'
              disabled />
          </Col>
          <Col sm={12}
            md={3}>
            <Selector className='no-label'
              placeholder='นับถัดจากวันที่ลงนามใมสัญญา'
              disabled />
          </Col>
          <Col sm={12}
            md={3}>
            <DatePicker label='ครบกำหนดส่งมอบวันที่'
              disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={2}>
            <Input label='ระยะเวลารับประกัน'
              placeholder='ระยะเวลารับประกัน '
              textCenter
              rule={{ required: true }}
              disabled />
          </Col>
          <Col sm={12}
            md={1}>
            <Selector className='no-label'
              placeholder='วัน'
              disabled />
          </Col>
          <Col sm={12}
            md={3}>
            <DatePicker label='ครบกำหนดส่งมอบวันที่'
              disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={3}>
            <Selector label='เงื่อนไขการรับประกัน'
              placeholder='ถัดจากวันสิ้นสุด'
              rule={{ required: true }}
              disabled />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='ข้อมูลคู่ค้า'
          className='fs-5 text-primary' />
        <Row className='mt-3'>
          <Col sm={12}
            xl={4}>
            <p className='mb-0 me-4 text-start'>สัญชาติของผู้ประกอบการ : <span className='ms-2'>ไทย</span></p>
          </Col>
          <Col sm={12}
            xl={4}>
            <p className='mb-0 me-4 text-start'>ประเภท : <span className='ms-2'>นิติบุคคล</span></p>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col sm={12}
            xl={4}>
            <p className='mb-0 me-4 text-start'>เลขประจำตัวผู้เสียภาษี : <span className='ms-2'>1234567890123</span></p>
          </Col>
          <Col sm={12}
            xl={4}>
            <p className='mb-0 me-4 text-start'>ประเภทผู้ประกอบการ : <span className='ms-2'>นิติบุคคล</span></p>
          </Col>

          <Col sm={12}
            className='mt-2'>
            <p className='mb-0 me-4 text-start'>ชื่อสถานประกอบการ
              : <span className='ms-2'>บริษัท โค้ดฮาร์ด ดอท คอม</span></p>
          </Col>
          <Col sm={12}
            xl={4}
            className='mt-2'>
            <p className='mb-0 me-4 text-start'>หมายเลขโทรศัพท์สำหรับติดต่อ : <span className='ms-2'>08731333333</span>
            </p>
          </Col>
          <Col sm={12}
            xl={4}>
            <p className='mb-0 me-4 text-start'>อีเมล : <span className='ms-2'>xxx@xxx.xxx</span></p>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <Title text='ข้อมูลใบสั่ง/สัญญา'
            className='fs-5 text-primary' />
        </div>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='เลขที่สัญญา PO'
              textCenter
              rule={{ required: true }}
              value='410007'
              disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='เลขที่สัญญา จพ.(สบส.)'
              textCenter
              rule={{ required: true }}
              value='001/2566'
              disabled />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <DatePicker label='วันที่ลงนามในสัญญา'
              disabled
              value={new Date()} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='วงเงินตามสัญญา'
              textEnd
              rule={{ required: true }}
              value='1,000,000.00'
              disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={2}>
            <Input label='กำหนดส่งมอบภายใน'
              placeholder='กำหนดส่งมอบภายใน'
              textCenter
              rule={{ required: true }}
              disabled
              value={45} />
          </Col>
          <Col sm={12}
            md={1}>
            <Selector className='no-label'
              placeholder='วัน'
              disabled />
          </Col>
          <Col sm={12}
            md={3}>
            <Selector className='no-label'
              placeholder='นับถัดจากวันที่ลงนามใมสัญญา'
              disabled />
          </Col>
          <Col sm={12}
            md={3}>
            <DatePicker label='ครบกำหนดส่งมอบวันที่'
              disabled
              value={new Date()} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={2}>
            <Input label='ระยะเวลารับประกัน'
              placeholder='ระยะเวลารับประกัน '
              textCenter
              rule={{ required: true }}
              disabled
              value={45} />
          </Col>
          <Col sm={12}
            md={1}>
            <Selector className='no-label'
              placeholder='วัน'
              disabled />
          </Col>
          <Col sm={12}
            md={3}>
            <DatePicker label='ครบกำหนดส่งมอบวันที่'
              disabled
              value={new Date()} />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='บันทึกรับหลักประกัน'
          className='fs-5 text-primary' />
        <Row>
          <Col sm={12}>
            <Radio
              className='mt-3'
              inline
              dflex
              items={INFO}
              label='จำนวนเงินหลักประกัน'
              name='name1'
              rule={{ required: true }}
            />
          </Col>
          <Col sm={12}
            xl={3}>
            <Selector label='ประเภทหลักประกันสัญญา'
              placeholder='เงินโอน'
              rule={{ required: true }}
              disabled />
          </Col>
          <Col sm={12}
            xl={3}>
            <Input label='จำนวนเงินหลักประกัน'
              placeholder='จำนวนเงินหลักประกัน'
              rule={{ required: true }}
              disabled />
          </Col>
          <Col sm={12}
            xl={3}>
            <Input label='ร้อยละของราคาค่าจ้างตามสัญญา'
              placeholder='ร้อยละของราคาค่าจ้างตามสัญญา'
              rule={{ required: true }}
              disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            xl={3}>
            <Input label='เลขที่หลักประกัน'
              placeholder='เลขที่หลักประกัน'
              disabled />
          </Col>
          <Col sm={12}
            xl={3}>
            <DatePicker label='เลขที่หลักประกันลงวันที่'
              disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            xl={3}>
            <Selector label='ประเภทวันทำสัญญา'
              placeholder='ตามวันที่'
              disabled />
          </Col>
          <Col sm={12}
            xl={3}>
            <DatePicker label='วันที่'
              rule={{ required: true }}
              disabled />
          </Col>
          <Col sm={12}
            xl={3}>
            <DatePicker label='ถึงวันที่'
              disabled />
          </Col>
          <Col sm={12}
            xl={8}>
            <InputTextArea label='หมายเหตุ'
              disabled />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='คืนหลักประกันสัญญา'
          className='fs-5 text-primary' />
        <Row>
          <Col sm={12}
            lg={6}>
            <InputTextArea label='เหตุผลการพิจารณาอนุมัติให้คืนหลักประกัน' />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={3}>
            <Input label='บันทึกหักหลักประกัน'
              value='0'
              textEnd />
          </Col>
          <Col sm={12}
            lg={3}>
            <Input label='จำนวนเงิน'
              disabled
              value='54,000.00'
              textEnd />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='ส่งเห็นชอบ/อนุมัติ'
              className='fs-5 text-primary' />
            {openCollapse1 ? (
              <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />
            )}
          </div>
          <Button
            variant='outline-primary'
            onClick={() => setModalSearchNameModal(!showSearchNameModal)}>
            <FaPlus className='me-1' />เพิ่มรายชื่อ
          </Button>
        </div>
        <Collapse in={openCollapse1}>
          <div>
            <Table total={1}
              hidePagination>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>ลำดับ</th>
                  <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
                  <th style={{ width: 250 }}>ฝ่าย/สำนัก</th>
                  <th style={{ width: 250 }}>ตำแหน่ง</th>
                  <th style={{ width: 250 }}>วันที่เห็นชอบ</th>
                  <th style={{ width: 250 }}>สถานะ</th>
                  <th style={{ width: 50 }}>ไม่สามารถปฏิบัติงานได้</th>
                  <th style={{ width: 250 }}>หมายเหตุ</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 3 })
                  .map((_, index) => (
                    <tr className='text-center'
                      key={generateUniqueId(index)}>
                      <td>{index + 1}</td>
                      <td>สมศรี แดนไทย</td>
                      <td>สมศรี แดนไทย</td>
                      <td>ผู้ช่วยกรรมการ</td>
                      <td>17/05/2566</td>
                      <td><TorStatusDetail value='Approved' /></td>
                      <td>
                        <div className='d-flex justify-content-center'><Check /></div>
                      </td>
                      <td><Input /></td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          variant='outline-primary'
          onClick={onClickBack}
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          กลับหน้าแรก
        </Button>
        <div>
          <Button
            variant='success'
            className='me-2 px-3'
          >
            <FaSave className='me-2 pb-1 fs-5' />
            บันทึก
          </Button>
          <Button
            className='me-2 px-3'
          >
            <FaRegCheckCircle className='me-2 pb-1 fs-5' />
            ส่งอนุมัติ
          </Button>
        </div>
        <Button
          variant='outline-primary'
          onClick={onClickNext}
          className='me-2 px-3'
        >
          ถัดไป
          <MdArrowForward className='ms-2 pb-1 fs-5' />
        </Button>
      </div>
      <HistoryModal
        title='ประวัติการใช้งานประกาศผู้ชนะการเสนอราคา'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        data={dataHistory}
      />
      <SearchNameModal
        show={showSearchNameModal}
        onHide={() => setModalSearchNameModal(!showSearchNameModal)}
        data={[]}
      />
    </div>
  );
}

export default Step1;
