import {
  Card,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { User } from 'models';
import {
  useCallback,
  useState,
} from 'react';
import { Button } from 'react-bootstrap';
import {
  FaPlus,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { generateUniqueId } from '../../../../utils/helper';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [user, setUser] = useState<User>({} as User);
  const [roles, setRoles] = useState<{ label: string, value: string }[]>([]);

  const addRole = useCallback(() => {
    if (!user.roles) {
      setUser((user) => ({ ...user, roles: [] }));
    }

    setUser((user) => ({ ...user, roles: [...user.roles, ''] }));
  }, [user]);

  const removeRole = useCallback((i: number) => {
    const { roles } = user;

    roles.splice(i, 1);
    setUser((user) => ({ ...user, roles }));
  }, [user]);

  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="อำนาจอนุมัติ" className="fs-5 text-primary" />
        <div className="d-flex justify-content-between my-3">
          <p>ข้อมูล</p>
          <Button
            variant="outline-primary"
            onClick={addRole}
          >
            <FaPlus />เพิ่มข้อมูล
          </Button>
        </div>
        <Table>
          <thead>
            <tr>
              <th style={{ minWidth: 100 }}>ลำดับ</th>
              <th style={{ minWidth: 150 }}>รหัสพนักงาน</th>
              <th style={{ minWidth: 250 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 250 }}>ตำแหน่งในคณะกรรมการ</th>
              <th style={{ minWidth: 125 }} />
            </tr>
          </thead>
          <tbody>
            {user.roles?.map((r, i) => (
              <tr key={generateUniqueId(r)}>
                <td className="text-center">1</td>
                <td className="text-center">5900010</td>
                <td className="text-center">สมศรี แดนไทย</td>
                <td className="text-center">รักษาการผู้อำนวยการสำนักงบประมาณ</td>
                <td>
                  <Button
                    onClick={() => removeRole(i)}
                    variant="outline-primary"
                    className="d-flex align-items-center gap-2"
                  >
                    <FaTrashAlt />ลบ
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default Step2;
