import {
  Card,
  DatePicker,
  Input,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function step1({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลโครงการ" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="เลขที่เอกสาร" value="เลขที่เอกสาร" disabled />
          </Col>
          <Col sm={12} md={4}>
            <Input label="โครงการ" value="059/2565 : โครงการ Digital Procurement " disabled />
          </Col>
          <Col sm={12} md={3}>
            <Button variant="link"><TbListSearch className="fs-4" /> ไปยังแผน</Button>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} md={4}>
            <Input label="งบประมาณโครงการ(บาท)" value="1,000,000.00" disabled textEnd />
          </Col>
          <Col sm={12} md={4}>
            <Input label="วิธีจัดหา" value="80" disabled />
          </Col>
          <Col sm={12} md={4} className="pt-4">
            <Input value="เจาะจง" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="ข้อมูลใบสั่งซื้อ/จ้าง/เช่า" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={6}>
            <Input label="เลขที่เอกสาร" value="เลขที่เอกสาร" rule={{ required: true }} />
          </Col>
          <Col sm={12} md={6}>
            <DatePicker label="วันที่สิ้นสุด" rule={{ required: true }} />
          </Col>
          <Col sm={12} md={6}>
            <Input label="อ้างอิงใบเสนอราคา" value="QU2301-0021" rule={{ required: true }} />
          </Col>
          <Col sm={12} md={6}>
            <Input label="เลขที่สัญญา" value="จพ(สบส)032/2566 " rule={{ required: true }} />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="ข้อมูลผู้ขาย/รับจ้าง" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={6}>
            <Input label="ผู้ขาย/ผู้รับจ้าง" value="บริษัท แอลเอ็มเอ็นกรุ๊ป จำกัด" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Input label="ที่อยู่" value="936 ชั้น 3 ถ.ศรีนครินทร์ แขวงสวนหลวง กรุงเทพมหานคร 10250" disabled />
          </Col>
          <Col sm={12} md={6}>
            <Input label="โทรศัพท์" value="02-222-3325" disabled />
          </Col>
          <Col sm={12} md={6}>
            <Input label="โทรสาร" value="" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Input label="เลขที่ประจำตัวผู้เสียภาษี" value="" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Input label="ชื่อบัญชี" value="" disabled />
          </Col>
          <Col sm={12} md={6}>
            <Input label="ธนาคาร" value="" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default step1;
