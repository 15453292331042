import {
  Card,
  DatePicker,
  Input,
  InputTextArea,
  ProcessStatus,
  Radio,
  Status,
  StatusType,
  Table,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaSave,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { generateUniqueId } from '../../../../utils/helper';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const INFO = [
  { label: 'ผ่าน', value: '1' },
  { label: 'ไม่ผ่าน', value: '2' },
];

function GetStatus(i: number) {
  switch (i) {
    case 1:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.PENDING} />;
    case 2:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.APPROVED} />;
    case 3:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.REJECTED} />;
    default:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.DRAFT} />;
  }
}

function Step2({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="สถานะการอนุมัติ" className="fs-5 text-primary" />
        <Table className="mt-3">
          <thead>
            <tr className="text-center">
              <th>ลำดับ</th>
              <th>รหัสพนักงาน</th>
              <th>ชื่อ-นามสกุล</th>
              <th>ตำแหน่ง</th>
              <th>สถานะ</th>
              <th>วันที่อนุมัติ</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 5 }).map((_, index) => (
              <tr className="text-center" key={generateUniqueId(index)}>
                <td>1</td>
                <td>5900010</td>
                <td>สมศรี แดนไทย</td>
                <td>รักษาการผู้อำนวยการสำนักงบประมาณ</td>
                <td>{GetStatus(index)}</td>
                <td>28-11-2566 16:24:22</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className="mt-3">
        <Title text="รายละเอียดงวดงาน" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} lg={2}>
            <Input label="งวดงานที่" placeholder="งวดงานที่" disabled textCenter />
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col sm={12} lg={2}>
            <Input label="ระยะเวลา(วัน)" placeholder="ระยะเวลา(วัน)" disabled textCenter />
          </Col>
          <Col sm={12} lg={2}>
            <DatePicker label="งวดงานที่" disabled />
          </Col>
          <Col sm={12}>
            <Radio
              className="mt-3"
              inline
              items={INFO}
              label="สถานะตรวจรับ"
              rule={{ required: true }}
              name='name1'
            />
          </Col>
          <Col sm={12}>
            <InputTextArea label="บันทึกความเห็น" />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-center gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          variant="light"
          className="me-2 px-3"
        >
          <FaSave className="me-2 pb-1 fs-5" />บันทึก
        </Button>
        <Button
          className="me-2 px-3"
        >
          <FaAlignLeft className="me-2 pb-1 fs-5" />
          อนุมัติความเห็น
        </Button>
      </div>
    </div>
  );
}

export default Step2;
