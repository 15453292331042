import {
  ButtonCustom,
  Card,
  Check,
  ContractReceiveCollateral,
  ContractReceiveCollateralStatus,
  Currency,
  DatePicker,
  Input,
  Modal,
  ReturnCollateralStatus,
  Selector,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { StatusCMReceiveCollateral } from 'components/StatusCMReturnCollateral';
import {
  IFile,
  ItemModel,
} from 'models';
import {
  AttachmentFileModel,
  ContractReceiveCollateralModel,
  ContractReturnCollateralChannelModel,
  ReceiveCollateralChannelsModel,
  UpdateReceiveCollateralModel,
} from 'models/CM/CM06Model';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaFileAlt,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaPaperclip,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router';
import CM06Service from 'services/CM/CM06Service';
import {
  HttpStatusCode,
  submitForm,
  thaiFormatDate,
  thaiFormatDateWithSlash,
  THCurrency,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { Context } from '.';
import { generateUniqueId } from '../../../../../utils/helper';
import { HistoryModal } from '../../Modal/HistoryModal';
import { UploadDocumentModal } from './Modal/UploadDocumentModal';
import UploadFile from './UploadFile';
import UploadFilePersonal from './UploadFilePersonal';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  contractReturnCollateralId: string;
}

type Loader = {
  bankDDL: ItemModel[],
  performanceBondTypeDDL: ItemModel[],
};

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function Step1({ onClickNext, onClickBack, contractReturnCollateralId }: Props) {
  const { bankDDL, performanceBondTypeDDL } = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [filesPersonal, setFilesPersonal] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const { userId } = useAppContext();
  const location = useLocation();
  const [showWaitForInspectorReceivedModal, setShowWaitForInspectorReceivedModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { data, setData } = useContext(Context);
  const [contractReceiveCollateralId, setContractReceiveCollateralId] = useState<string>('');
  const [channelId, setChannelId] = useState<string>('');
  const [deleteFileId, setDeleteFileId] = useState<string>('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const [channelsData, setChannelsData] = useState<ReceiveCollateralChannelsModel[]>([]);
  const [amount, setAmount] = useState<number>(0);
  const [showComplateModal, setShowComplateModal] = useState(false);
  const [receiveChannelAttachmentDocument, setReceiveChannelAttachmentDocument] = useState<ContractReceiveCollateralModel[]>([]);
  const [collateralChannelData, setCollateralChannelData] = useState<ContractReturnCollateralChannelModel[]>([]);
  const [isFine, setIsFine] = useState(false);
  const [fineAmount, setFineAmount] = useState<number>(0);
  const [collateralAmount, setCollateralAmount] = useState<number>(0);

  const goToBack = () => {
    navigate(`/cm/cm06/detail/${data.contractReturnCollateralId}`);
  };

  const getReturnCollateralAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.geContractReceiveCollateralByIdAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    setData(data);
  }, []);

  const getFilesPersonalAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.getAttachmentPersonalAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    handleSetFilePersonal(data.attachments);

  }, []);

  const getFilesAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.getAttachmentAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    handleSetFile(data.attachments);

  }, []);

  useEffect(() => {

    if (data) {

      if (data.id) {
        getFilesPersonalAsync(data.id);
        getFilesAsync(data.id);
      }

      if (!data.status || data.status === ReturnCollateralStatus.NONE || data.status === ReturnCollateralStatus.PENDING || data.status === ReturnCollateralStatus.DRAFT) {
        setIsDisabled(false);
      } else {

        setIsDisabled(true);
      }

      // if (data.attachmentFiles) {
      //   handleSetFile(data.attachmentFiles);
      // }

      if (data.channels) {
        setChannelsData(data.channels);
      }

      if (data.collateralChannes) {
        setCollateralChannelData(data.collateralChannes)
      }

      if (data.isFine) {
        setIsFine(data.isFine);
      }

      if (data.collateralChannes) {
        setCollateralAmount(data.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0));
      }

      if (data.fineAmount) {
        setFineAmount(data.fineAmount);
        handleSumCollateralAmount(data.fineAmount, data.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0));
      }

      if (data.collateralItem && data.collateralItem.length > 0) {
        const newAmount = data.collateralItem?.map((x) => x.amount)
          .reduce((prev, curr) => prev + curr);
        setAmount(newAmount);
      }
    }

  }, [data, userId]);

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleSetFilePersonal = (documents: AttachmentFileModel[]) => {
    setFilesPersonal([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFilesPersonal((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await CM06Service.uploadAttachmentDocumentReceiveAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getFilesAsync(data.id);
      }
    }
  };

  const handleFilePersonalChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFilesPersonal((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await CM06Service.uploadAttachmentDocumentPersonalReceiveAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getFilesPersonalAsync(data.id);

      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {

    if (data && data.id) {
      const res = await CM06Service.removeAttachmentReceiveAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getFilesAsync(data.id);
        getFilesPersonalAsync(data.id);
      }
    }

  }, [files, data.id]);

  const removeItemPersonalAsync = useCallback(async (index: number, docId: string) => {

    if (data && data.id) {
      const res = await CM06Service.removeAttachmentPersonalReturnCollateralAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getFilesPersonalAsync(data.id);
      }
    }

  }, [files, data.id]);

  const onSubmitAsync = async (isComplate: boolean) => {

    submitForm();

    if (channelsData.filter((x) => x.receiveDate === undefined || x.receiveDate === null).length > 0) {
      toast.warn('กรุณาระบุวันที่คืนหลักประกัน');
      return;
    }

    if (data) {

      const createDataModel: UpdateReceiveCollateralModel = {
        id: data.id ? data.id : '',
        contractReturnCollateralConsiderId: data.contractReturnCollateralConsiderId,
        channels: channelsData,
        remark: data.remark,
        contractReturnCollateralId: data.contractReturnCollateralConsiderId,
        isFine: isFine,
        fineAmount: fineAmount,
      };

      const res = await CM06Service.createReceiveCollateralAsync(createDataModel);
      // if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {

      if (isComplate) {
        const res = await CM06Service.updateReceiveCollateralComplatAsync(data.id);
        toast.success('ยืนยันคืนหลกประกันสำเร็จสำเร็จ');
        setShowComplateModal(false);

      } else {
        toast.success('บันทึกข้อมูลสำเร็จ');
      }

      getReturnCollateralAsync(data.contractReturnCollateralConsiderId);
      navigate(`${location.pathname}`);

      // } else {
      //   toast.error(res.statusText);
      // }

    }
  };

  const onOpenUploadDoumentModal = (contractReceiveCollateralId: string, channelId: string, index: number) => {
    setContractReceiveCollateralId(contractReceiveCollateralId);
    setChannelId(channelId);
    setShowUploadModal(true);
  };

  const onCloseUploadModal = () => {
    setShowUploadModal(!showUploadModal);
    getSaveReturnCollateralAsync(data.contractReturnCollateralConsiderId);
  };

  const getSaveReturnCollateralAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.geContractReceiveCollateralByIdAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    setData(data);
    // setStepByStatus(data.status);
  }, []);

  // const getDocumentContractAttachmentByIdAsync = async (id: string, docId: string) => {
  //   const { data, status } = await CM06Service.getReceiveChannelAttachmentDocumentAsync(id, docId);
  //   if (status === HttpStatusCode.OK) {
  //     setReceiveChannelAttachmentDocument([]);
  //     const attachments = data.attachments as AttachmentFileModel[];
  //     let newData = [...receiveChannelAttachmentDocument];
  //     const index = receiveChannelAttachmentDocument.findIndex(x => x.id === docId);
  //     newData[index].attachmentFiles = attachments;

  //     setReceiveChannelAttachmentDocument(newData);
  //   }
  // };

  // const onCloseUploadModal = (files: File[], index: number) => {
  //   setShowUploadModal(!showUploadModal);
  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];
  //     const newFile: IFile = {
  //       id: '',
  //       name: file.name,
  //       file,
  //     };

  //     const newFileData = [...channelsData[index].contractReceiveCollateralChannelDocumentAttachments];

  //     newFileData.push({
  //       fileName: newFile.name,
  //       id: newFile.id,
  //     } as ContractReceiveCollateralChannelDocumentAttachments);

  //     const data = [...channelsData];
  //     data[index].contractReceiveCollateralChannelDocumentAttachments = newFileData;

  //     data[index].files.push(file);
  //     setChannelsData(data);

  //   }

  // }

  // const onDeleteFile = (parentIndex: number, fileIndex: number) => {

  //   let tempAttachment = [...channelsData[parentIndex].contractReceiveCollateralChannelDocumentAttachments];
  //   tempAttachment.splice(fileIndex, 1);

  //   const data = [...channelsData];
  //   data[parentIndex].contractReceiveCollateralChannelDocumentAttachments = tempAttachment;

  //   setChannelsData(data);
  // };

  const onDownloadFileDocument = async (contractDocId: string, fileId: string) => {
    if (contractDocId && fileId) {
      const res = await CM06Service.getReceiveChannelAttachmentDocumentAsync(
        contractDocId,
        fileId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank');
      }
    }
  };

  const fileIcon = (name: string) => {
    switch (true) {
      case name.includes('.pdf'):
        return (<FaFilePdf />);
      case name.includes('.doc') || name.includes('.docx'):
        return (<FaFileWord />);
      case name.includes('.xls') || name.includes('.xlsx'):
        return (<FaFileExcel />);
      case name.includes('.csv'):
        return (<FaFileCsv />);
      case name.includes('.png') || name.includes('jpg') || name.includes('jpeg'):
        return (<FaFileImage />);
      default:
        return (<FaFileAlt />);
    }
  };

  const onDeleteFile = (id: string, channelId: string, attachmentId: string) => {
    setChannelId(channelId);
    setDeleteFileId(attachmentId);
    setShowDeleteFileModal(true);
  };

  const onDeleteFileAsync = async () => {
    setShowDeleteFileModal(false);
    if (channelId && deleteFileId) {
      const res = await CM06Service.removeFileReceiveChannelAttachmentAsync(
        channelId,
        deleteFileId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getReturnCollateralAsync(data.contractReturnCollateralConsiderId);
      }
    }
  };

  const onChangeReceiveDateResult = (val: Date, index: number) => {
    const data = [...channelsData];
    data[index].receiveDate = val;
    setChannelsData(data);
  };

  const handleSumCollateralAmount = (fineAmount: number, totalAmount: number) => {


    if ((fineAmount !== undefined || fineAmount !== null || fineAmount > 0) && (totalAmount !== undefined || totalAmount !== null || totalAmount > 0)) {

      const val = (totalAmount - fineAmount);
      setCollateralAmount(val);

    }
    setFineAmount(fineAmount);
  };

  const handleCollateralIsFine = (val: boolean) => {
    setIsFine(val);
    if (!val) {
      setFineAmount(0);
      handleSumCollateralAmount(0, collateralChannelData?.reduce((a, v) => a += v.amount ?? 0, 0))
    }
  };

  return (

    <div className='document'>
      <h5 className='fw-bold mb-3'>ขออนุมัติคืนหลักประกันสัญญา</h5>
      {/*
      <Title text='รายละเอียดหลักประกันสัญญา'
        extraElement={
          <>
            <StatusCM systemStatue='Warning'
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text="ประวัติการใช้งาน"
              icon='history' />
          </>
        } /> */}

      <Row className='mt-3'>
        {/* <Title text='รายละเอียดหลักประกันสัญญา' lineBorder /> */}

        <Title text='รายละเอียดหลักประกันสัญญา'
          extraElement={
            <>
              <StatusCMReceiveCollateral systemStatue={data.status}
                systemName='cm-03' />
              <ButtonCustom variant='outline-primary'
                onClick={() => {
                  setShowHistoryModal(true);
                }}
                text='ประวัติการใช้งาน'
                icon='history' />
            </>
          } />

        <Row className='mt-3 align-items-center'>
          <h5 className='fw-bold'>รายละเอียดหลักประกันสัญญา</h5>
          <Col sm={12}>
            <Table total={2}
              hidePagination>
              <thead>
                <tr>
                  <th className='text-center ps-3'
                    style={{ width: '20%' }}>รายละเอียดหลักประกัน
                  </th>
                  <th className='text-center'
                    style={{ width: '15%' }}>ร้อยละ <br />(ของราคาทั้งหมดตามสัญญา)
                  </th>
                  <th className='text-center'
                    style={{ width: '15%' }}>จำนวนเงิน
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.collateralChannes?.map((item, index) => (
                  <tr>
                    <td className='ps-3 text-start text-wrap'>
                      <div>
                        {item.typeName}
                      </div>
                      {(item.bankName) && (
                        <div>
                          {item.bankName} สาขา {item.bankBranch}
                        </div>
                      )}
                      {(item.bankAccountName) && (
                        <div>
                          ชื่อบัญชี {item.bankAccountName}
                        </div>
                      )}
                      {(item.bankAccountNumber) && (
                        <div>
                          เลขที่ {item.bankAccountNumber}
                        </div>
                      )}
                      {
                        (item.bankCollateralStartDate && item.bankCollateralEndDate)
                        && (
                          <div>
                            วันที่หนังสือค้ำประกัน {thaiFormatDate(item.bankCollateralStartDate)} -  {thaiFormatDate(item.bankCollateralEndDate)}
                          </div>
                        )
                      }
                    </td>
                    <td className='text-end'>{formatter.format(item.percent ?? 0)}</td>
                    <td className='text-end'>{formatter.format(item.amount ?? 0)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className='bg-opacity-10 bg-primary p-4 rounded'
                  style={{ height: 70 }}>
                  <td colSpan={2}>
                    <p className='mb-0 text-primary text-end'>คืนหลักประกันสัญญาคงเหลือ :</p>
                  </td>
                  <td className='text-end'>
                    <span className='ms-3 text-dark'>
                      {
                        formatter.format(collateralAmount)
                      }
                    </span>
                  </td>
                </tr>
              </tfoot>
              <tfoot>
                <tr className='bg-opacity-10 p-4 rounded'
                  style={{ height: 70 }}>
                  <td colSpan={2}>
                    <Check
                      label='หักค่าปรับจากเงินหลักประกันสัญญา'
                      onChange={(val) => handleCollateralIsFine(val)}
                      value={isFine}
                      disabled={isDisabled}
                    />
                  </td>
                  <td className='text-end'>
                    <Currency
                      value={fineAmount}
                      onChange={(val) => handleSumCollateralAmount(Number(val), data.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0))}
                      maxValue={data.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0)}
                      disabled={!isFine || isDisabled} />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
        <Col sm={12}
          className='mt-3'>
          <Title text='รายละเอียดหลักประกันที่คืนให้คู่ค้า'
            lineBorder />
          <Table total={2}
            hidePagination
            notResponse
            className='overflow-x-scroll'>
            <thead>
              <tr>
                {/* <th className='text-center ps-3'>ลำดับที่</th>
                <th className='text-center'>ประเภทหลักประกัน <br />สัญญา</th>
                <th className='text-center'>ธนาคาร</th>
                <th className='text-end'>สาขาธนาคาร/
                  <br />ชื่อบัญชี
                </th>
                <th className='text-center'>เลขที่</th> */}
                <th className='text-center'>ลงวันที่คืนหลักประกัน</th>
                {/* <th className='text-center'> วันที่ <br />คืนหลักประกัน</th> */}
                <th />
              </tr>
            </thead>
            <tbody>
              {channelsData?.map((item, parentIndex) => (
                <>
                  <tr className='text-start'>
                    {/* <td>{parentIndex + 1}</td>
                    <td>{item.receiveCollateralChannelTypeValue}</td>
                    <td className='ps-3 text-center'>
                      <Selector
                        value={item.bank}
                        disabled={isDisabled}
                        items={bankDDL}
                        onChange={(val) => onChangeBankResult(val, parentIndex)}
                      />
                    </td>
                    <td>
                      <Input value={item.bankBranch}
                        disabled={isDisabled}
                        onChange={(val) => onChangeBankBranchResult(val, parentIndex)} />
                    </td>
                    <td className='ps-3 text-center'>
                      <Input value={item.bankAccountNumber}
                        type='number'
                        disabled={isDisabled}
                        textCenter
                        onChange={(val) => onChangeBankAccountNumberResult(Number(val), parentIndex)} />
                    </td> */}
                    {/* <td className='text-end'>
                      <DatePicker
                        disabled={isDisabled}
                        value={item.recordDate}
                        onChange={(val) => onChangeRecordDateResult(val, parentIndex)}
                      /></td> */}
                    <td className='text-end'>
                      <DatePicker
                        disabled={isDisabled}
                        value={item.receiveDate}
                        onChange={(val) => onChangeReceiveDateResult(val, parentIndex)}
                        rule={{ required: true }}
                      /></td>
                    <td className='text-end'>
                      <Button
                        variant='outline-primary'
                        className='d-flex align-items-center gap-2'
                        onClick={() => onOpenUploadDoumentModal(data.id, item.id!, parentIndex)}
                        disabled={!data.id}
                      >
                        แนบเอกสาร <FaPaperclip />
                      </Button></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {
                        item.contractReceiveCollateralChannelDocumentAttachments?.map((att, index) => (
                          <div className='upload-file'
                            key={generateUniqueId(att.id)}>
                            <div className='file-list'>
                              <div className='file'>
                                <div className='file-icon'>
                                  {fileIcon(att.fileName)}
                                </div>
                                <div className='detail'>
                                  <a
                                    onClick={() => onDownloadFileDocument(item.id!, att.id)}
                                    className='filename'
                                  >
                                    {att.fileName}
                                  </a>
                                </div>
                                <div className='trash'>
                                  <Button
                                    variant='danger'
                                    className='d-flex align-items-center gap-2'
                                    onClick={() => onDeleteFile(data.id, item.id!, att.id)}
                                  // disabled={isDisabled}
                                  >
                                    <FaTrashAlt />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </td>
                  </tr>
                  <UploadDocumentModal
                    show={showUploadModal}
                    onHide={() => setShowUploadModal(!showUploadModal)}
                    contractReceiveCollateralId={data.id}
                    onUploaded={onCloseUploadModal}
                    index={parentIndex}
                    channelId={item.id}
                  />
                </>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5'
          confidentiality />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFilePersonal
              disabled={!data.id}
              files={filesPersonal}
              handleFileChange={handleFilePersonalChange}
              removeItem={removeItemAsync}
              id={data.id!}
            />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5' />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFile
              disabled={!data.id}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractReceiveId={data.id!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <ButtonCustom
            text='ย้อนกลับ'
            onClick={() => goToBack()}
            icon='arrowback'
            variant='outline-primary' />
        </div>
        <div className='d-flex gap-3 align-items-center'>
          {
            (data.status !== ContractReceiveCollateralStatus.COMPLETED) && (
              <>
                <ButtonCustom text='บันทึก'
                  variant='outline-primary'
                  onClick={() => onSubmitAsync(false)} />
              </>
            )
          }

          {
            (data.status !== ContractReceiveCollateralStatus.COMPLETED && data.status === ContractReceiveCollateralStatus.DRAFT) && (
              <>
                <ButtonCustom text='ยืนยันคืนหลักประกัน'
                  variant='primary'
                  onClick={() => setShowComplateModal(true)} />
              </>
            )
          }
        </div>
        <div />
      </div>

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={data.id}
        title='บันทึกคืนหลักประกันสัญญา'
        status={ContractReceiveCollateral.Receive}
      />

      <Modal
        show={showComplateModal}
        size='lg'
        onHide={() => setShowComplateModal(!showComplateModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการยืนยันคืนหลักประกัน หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowComplateModal(!showComplateModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showDeleteFileModal}
        size='lg'
        onHide={() => setShowDeleteFileModal(!showDeleteFileModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการยืนยันลบไฟล์หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDeleteFileModal(!showDeleteFileModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onDeleteFileAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step1;
