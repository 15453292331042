import {
  Check,
  Currency,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  GetTorListResponse,
  GetTorListsParams,
} from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row as RowBT,
} from 'react-bootstrap';
import {
  FaEraser,
  FaPlus,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { termOfRef } from 'services';
import {
  HttpStatusCode,
  monthYear,
  thaiFormatDateWithSlash,
  THCurrency,
} from 'utils';

type Loader = {
  torData: {
    data: GetTorListResponse[],
    totalRecords: number,
  },
  departments: { label: string, value: string }[],
  supplyMethods: { label: string, value: string }[],
  supplyMethodTypes: { label: string, value: string }[],
};

export default function PP0204() {
  const [criteria, setCriteria] = useState<GetTorListsParams>({} as GetTorListsParams);

  return (
    <div className='PP0204'>
      <PP0204Header />
      <Criteria
        onSubmitSearch={(data) => setCriteria(data)}
        onClear={() => setCriteria({} as GetTorListsParams)}
      />
      <DataTable
        criteria={criteria}
      />
    </div>
  );
}

function PP0204Header() {
  const navigate = useNavigate();

  const onClickTorDetail = () => {
    navigate('detail');
  };

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>ร่างขอบเขตของงาน (TOR)</h4>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={onClickTorDetail}
        >
          <FaPlus />ร่างขอบเขต
        </Button>
      </div>
      <hr />
    </>
  );
}

function Criteria({ onSubmitSearch, onClear }: {
  onSubmitSearch: (criteria: GetTorListsParams) => void;
  onClear: () => void;
}) {
  const loader = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<GetTorListsParams>({} as GetTorListsParams);

  useEffect(() => {
    if (criteria.draftStatus && criteria.submitStatus) {
      setCriteria({ ...criteria, allStatus: true });

      return;
    }

    setCriteria({ ...criteria, allStatus: false });
  }, [criteria.draftStatus, criteria.submitStatus]);

  const onClearCriteria = () => {
    setCriteria({ allStatus: true, draftStatus: true, submitStatus: true } as GetTorListsParams);
    onClear();
  };

  const handleClickAll = (value: boolean) => {
    setCriteria({ ...criteria, allStatus: value, draftStatus: value, submitStatus: value });
  };

  return (
    <>
      <RowBT className='criteria'>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='ฝ่าย/สำนัก'
            items={loader.departments}
            onChange={(val) => setCriteria({ ...criteria, departmentId: val })}
            value={criteria.departmentId}
            placeholder='ฝ่าย/สำนัก'
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='ชื่อโครงการ'
            onChange={(val) => setCriteria({ ...criteria, planName: val })}
            value={criteria.planName}
            placeholder='ชื่อโครงการ'
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Currency
            label='งบประมาณโครงการ'
            onChange={(val) => setCriteria({ ...criteria, planBudget: val })}
            value={criteria.planBudget}
            placeholder='งบประมาณโครงการ'
          />
        </Col>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex gap-2'>
            <Button
              type='submit'
              variant='primary'
              className='d-flex align-items-center gap-2'
              onClick={() => onSubmitSearch(criteria)}
            >
              <FaSearch />ค้นหา
            </Button>
            <Button
              variant='outline-primary'
              className='d-flex align-items-center gap-2'
              onClick={onClearCriteria}
            >
              <FaEraser />ล้าง
            </Button>
          </div>
        </div>
      </RowBT>
      <RowBT className='mt-3'>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <div className='mb-2'>
            <label>สถานะ</label>
          </div>
          <div className='d-flex gap-3'>
            <Check label='ทั้งหมด'
              value={criteria.allStatus}
              onChange={(value) => handleClickAll(value)} />
            <Check label='ร่าง'
              value={criteria.draftStatus}
              onChange={(value) => setCriteria({ ...criteria, draftStatus: value })} />
            <Check label='สำเร็จ'
              value={criteria.submitStatus}
              onChange={(value) => setCriteria({ ...criteria, submitStatus: value })} />
          </div>
        </Col>
      </RowBT>
    </>
  );
}

function DataTable({ criteria }: {
  criteria: GetTorListsParams;
}) {
  const navigate = useNavigate();
  const loader = useLoaderData() as Loader;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<GetTorListResponse[]>([]);

  useEffect(() => {
    setData(loader.torData.data);
    setTotal(loader.torData.totalRecords);
  }, [loader]);

  const getTorListsAsync = useCallback(async () => {
    const { data, status } = await termOfRef.getTorListAsync(page, size, criteria);

    if (status === HttpStatusCode.OK) {
      setData(data.data);
      setTotal(data.totalRecords);
    }
  }, [page, size, criteria]);

  useEffect(() => {
    getTorListsAsync();
  }, [getTorListsAsync]);

  return (
    <Table
      className='mt-4'
      total={total}
      onChange={(size, page) => (setSize(size), setPage(page))}
    >
      <thead>
      <tr>
        <th>ชื่อแผน</th>
        <th>ฝ่าย/สำนัก</th>
        <th>งบประมาณ (บาท)</th>
        <th>วิธีจัดหา</th>
        <th>ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง</th>
        <th>วันที่ประกาศแผน</th>
        <th>สถานะ</th>
      </tr>
      </thead>
      <tbody>
      {data.map((d) => (
        <tr key={d.id}>
          <td className='text-center'>
            <Button
              variant='link'
              onClick={() => navigate(`detail/${d.id}`)}
              className='d-flex align-items-center gap-2'
            >
              {d.planName}
            </Button>
          </td>
          <td>{d.department}</td>
          <td className='text-end'>{THCurrency(d.planBudget)}</td>
          <td className='text-center'>{d.supplyMethod}</td>
          <td className='text-center'>{monthYear(d.expectingProcurementAt)}</td>
          <td className='text-center'>
            {thaiFormatDateWithSlash(d.planAnnouncementDate)}
          </td>
          <td>
            <Status type={StatusType.PROCESS}
              value={d.status} />
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  );
}
