import {
  ArrowCollapse,
  Card,
  Check,
  FileValue,
  Input,
  InputTextArea,
  ProcessStatus,
  Status,
  StatusType,
  Table,
  TorStatus,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal, SearchBuNameModal } from 'components/Modal';
import { JorporDirectorBeInformed, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { CommitteeUser, AcceptorModel, BasicInformationAction } from 'models/PP';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {
  BsArrowReturnLeft,
} from 'react-icons/bs';
import {
  FaAlignLeft,
  FaCheckCircle,
  FaHistory,
  FaPlus,
  FaRegCheckCircle,
  FaSave,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { PP0205Service, account, shareValue } from 'services';
import {
  AcceptorStatusEnum,
  fullDatetime,
  HttpStatusCode,
  ProcurementSection,
  showConfirmModalAsync,
  submitForm,
  useAppContext,
} from 'utils';
import { AcceptorStatus, AnnouncementStatus, AssignLevel } from 'utils/constants/PlanEnum';
import { scrollToTop } from 'utils/scroll';
import toast from 'utils/toast';
import { HistoryModal } from './HistoryModal';
import { Context } from './step';
import { JorPorCode } from 'constant/basicInformation';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import { Acceptors } from 'components/Table/Acceptors';
import { Assignee } from 'components/Table/Assignee';
import { AssigneesLevel } from 'constant/PurchaseOrderApprovalConstant';
import { TypeStatus } from 'components/StatusDetail';
import Collabora, { CollaboraRef } from 'components/Document/Collabora';

type loader = {
  isCreator: boolean,
};

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type sectionGroup = {
  name: string;
  sequence: number;
  level: number;
  inRefCode: string;
}

enum ModalType {
  APPROVE = 'Approve',
  REJECT = 'Reject',
  JORPORREJECT = 'JorPorReject',
}

export default function Step2({ onClickNext, onClickBack }: Props) {
  const {
    basicInfoCreate,
    activitiesData,
    disable,
    isJorPorHeader,
    getBasicInfoDetailAsync,
    downloadFileAsync,
    removeFile,
    setBasicInfoCreate,
    handleOnRecallBasicInfo,
    onRecall,
    isUploadFile,
    isAcceptor,
    isCreator,
    isQueue,
    acceptorId,
  } = useContext(Context);
  const { id, basicInfoId } = useParams();
  const [show, setShow] = useState(false);
  const [acceptorModalShow, setAcceptorModalShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [section, setSection] = useState<ProcurementSection>();
  const navigate = useNavigate();
  const { userId, departmentCode } = useAppContext();
  const location = useLocation();
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [sectionSequences, setSectionSequences] = useState<sectionGroup[]>([]);
  const [sectionType, setSectionType] = useState<string>();
  const [showUserAssigns, setShowUserAssigns] = useState(false);
  const [showUserJorporDirectorBeInformed, setShowJorporDirectorBeInformed] = useState(false);
  const [isDisabledJorporDirectorBeInformed, setIsDisabledJorporDirectorBeInformed] = useState(false);
  const [isDisabledLevel1, setIsDisabledLevel1] = useState(false);
  const [isDisabledLevel0, setIsDisabledLevel0,] = useState(false);
  const [sectionSequence, setSectionSequence] = useState<number>();
  const [openCollapse, setOpenCollapse] = useState(true);
  const [docId, setDocId] = useState('');
  const [showModalPending, setShowModalPending] = useState(false);
  const collaboraRef = useRef<CollaboraRef>(null);
  const [isLastApprover, setIsLastApprover] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.APPROVE);

  const onShowModal = (modalType: ModalType) => {
    collaboraRef.current?.clickSave();
    setShowEdit(true);
    setModalType(modalType);
  };

  useEffect(() => {
    if (basicInfoId === undefined) {
      return;
    }

    if (docId) {
      return;
    }

    getDocument(basicInfoId);
  }, [basicInfoId]);

  const getDocument = useCallback(

    async (id: string) => {
      const { data: docId, status } =
        await PP0205Service.getDocumentAsync(id);
      if (status === HttpStatusCode.OK) {
        setDocId(docId);
      }
    }, []);

  const onSendApprove = async () => {
    if (basicInfoId) {
      collaboraRef.current?.clickSave();
      setTimeout(async () => {
        basicInfoCreate.id = basicInfoId;
        basicInfoCreate.documentId = docId;

        const response = await PP0205Service.updateBasicInfoAsync(basicInfoCreate, basicInfoId, AnnouncementStatus.WaitingAcceptor);

        if (response.status === HttpStatusCode.ACCEPTED) {
          getBasicInfoDetailAsync(basicInfoId);
          getDocument(basicInfoId);
          window.location.reload();
          toast.success(`ส่งเห็นชอบ/อนุมัติสำเร็จ`);
          setShowModalPending(false);
          return;
        }
      }, 500);
    }
  };

  const sendApprove = () => {
    if (!basicInfoCreate.committeeProcurementUser || basicInfoCreate.committeeProcurementUser.length === 0) {
      return toast.warn('กรุณาเลือกข้อมูลรายชื่อผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้าง');
    }

    if (!basicInfoCreate.committeeInspectsUser || basicInfoCreate.committeeInspectsUser.length === 0) {
      return toast.warn('กรุณาเลือกรายชื่อคณะกรรมาการตรวจรับ/ผู้ตรวจรับพัสดุ');
    }

    if (!basicInfoCreate.acceptors || basicInfoCreate.acceptors.length === 0) {
      return toast.warn('กรุณาเลือกผู้มีอำนาจเห็นชอบ');
    }

    setShowModalPending(true);
    // onSendApprove();
  };

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  useEffect(() => {

    if (!basicInfoCreate.assignsLists) {
      getDefaultUserAsync();
    }

    if (basicInfoCreate.assignsLists) {

      if (basicInfoCreate.assignsLists && basicInfoCreate.assignsLists.filter((a) => a.sectionCode === ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED && isNull(a.delegateUserId, a.userId) === userId).length > 0) {
        setIsDisabledJorporDirectorBeInformed(true)
      }

      if (basicInfoCreate.assignsLists && basicInfoCreate.assignsLists.filter(a => a.level === AssignLevel.Level0 && isNull(a.delegateUserId, a.userId) === userId).length > 0) {
        setIsDisabledLevel0(true)
      }

      if (basicInfoCreate.assignsLists && basicInfoCreate.assignsLists.filter((a) => a.level === AssignLevel.Level1 && isNull(a.delegateUserId, a.userId) === userId).length > 0) {
        setIsDisabledLevel1(true)
      }

    }

  }, [basicInfoCreate.assignsLists, userId]);

  function isNull(oldValue?: string, newValue?: string) {
    if (oldValue) {
      return oldValue;
    }

    return newValue;
  }

  useEffect(() => {
    if (basicInfoCreate.acceptors) {
      const sectionDatas: sectionGroup[] = [];

      basicInfoCreate.acceptors.sort((a, b) => a.sectionSequence! - b.sectionSequence!)
        .forEach((item, index) => {
          sectionDatas.push({
            name: item.sectionName,
            level: 0,
            sequence: item.sectionSequence,
            inRefCode: item.sectionType,
          } as sectionGroup);
        });

      const uniqueNames = sectionDatas.filter((data, index) => {
        return sectionDatas.findIndex(d => d.inRefCode === data.inRefCode) === index;
      });

      setSectionSequences(uniqueNames);

      const queueApprover = basicInfoCreate.acceptors.filter(a => a.status === AcceptorStatus.PENDING && !a.isAvailable)
      setIsLastApprover(queueApprover.length === 1);
    }
  }, [basicInfoCreate.acceptors]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const showModalUser = (section?: ProcurementSection) => {
    if (section) {
      setShow(true);
      setSection(section);

      return;
    }

    setShow(true);
    setSection(undefined);
  };

  const acceptorOnSelect = (
    userId: string,
    fullName: string,
    departmentName: string,
    positionName: string) => {

    const acceptorBySection = basicInfoCreate.acceptors?.filter((x) => x.sectionSequence === sectionSequence);

    const maxValueSequence = acceptorBySection!.length > 0 ? Math.max.apply(null,
      acceptorBySection!.map((x) => {
        return x.sequence;
      })) : 0;

    const userLists: AcceptorModel[] = basicInfoCreate.acceptors ? [
      ...basicInfoCreate.acceptors,
      {
        userId,
        fullName,
        departmentName,
        positionName,
        sectionCode: ProcurementSection.APPROVER,
        sequence: maxValueSequence + 1,
        status: AcceptorStatus.DRAFT,
        sectionType: sectionType,
        sectionSequence: sectionSequence,
      } as AcceptorModel,
    ] : [];

    setBasicInfoCreate({ ...basicInfoCreate, acceptors: userLists.sort((a, b) => a.sequence - b.sequence) });
    return;
  };

  const assignsOnSelect = (
    userId: string,
    fullName: string,
    departmentName: string,
    positionName: string,
    sectionCode?: string,
    level?: number,
  ) => {

    const userFromModal: CommitteeUser = {
      userId: userId,
      name: fullName,
      fullName: fullName,
      departmentName: departmentName,
      position: positionName,
      positionName: positionName,
      sectionCode: sectionCode,
      level: level,
    };

    if (basicInfoCreate.assignsLists && basicInfoCreate.assignsLists.filter(x => x.sectionCode === ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED && x.userId === userId).length > 0) {
      toast.warn('ไม่สามารถเลือก ผอ.จพ.รับทราบ');
      return;
    }

    const userLists: CommitteeUser[] = basicInfoCreate.assignsLists ? [...basicInfoCreate.assignsLists] : [];
    userLists.push(userFromModal);
    setBasicInfoCreate({ ...basicInfoCreate, assignsLists: userLists });
    return;
  };

  const assignsOnSelectLevel0 = (
    userId: string,
    fullName: string,
    departmentName: string,
    positionName: string,
    sectionCode?: string,
    level?: number,
  ) => {

    const userFromModal: CommitteeUser = {
      userId: userId,
      name: fullName,
      fullName: fullName,
      departmentName: departmentName,
      position: positionName,
      positionName: positionName,
      sectionCode: sectionCode,
      level: level,
    };

    if (basicInfoCreate.assignsLists && basicInfoCreate.assignsLists.filter(x => x.level === AssignLevel.Level0 && x.userId === userId).length > 0) {
      toast.warn('ไม่สามารถเลือก ผอ.จพ. มอบหมายงานไปยังผู้รับผิดชอบ ซ้ำ');
      return;
    }

    const userLists: CommitteeUser[] = basicInfoCreate.assignsLists ? [...basicInfoCreate.assignsLists] : [];
    userLists.push(userFromModal);
    setBasicInfoCreate({ ...basicInfoCreate, assignsLists: userLists });
    return;
  };

  const assignsOnSelectLevel1 = (
    userId: string,
    fullName: string,
    departmentName: string,
    positionName: string,
    sectionCode?: string,
    level?: number,
  ) => {

    const userFromModal: CommitteeUser = {
      userId: userId,
      name: fullName,
      fullName: fullName,
      departmentName: departmentName,
      position: positionName,
      positionName: positionName,
      sectionCode: sectionCode,
      level: level,
    };

    if (basicInfoCreate.assignsLists && basicInfoCreate.assignsLists.filter(x => x.level === AssignLevel.Level1 && x.userId === userId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้รับผิดชอบ');
      return;
    }

    const userLists: CommitteeUser[] = basicInfoCreate.assignsLists ? [...basicInfoCreate.assignsLists] : [];
    userLists.push(userFromModal);
    setBasicInfoCreate({ ...basicInfoCreate, assignsLists: userLists });
    return;
  };

  const getDefaultUserAsync = async () => {
    const [
      jorporDirectorBeInformedDefault,
    ] = await Promise.all([
      account.getDefaultDepartmentDirectorAgree(ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED),
    ]);

    const jorporDirectorBeInformed = jorporDirectorBeInformedDefault.data as JorporDirectorBeInformed[];

    if (basicInfoCreate.assignsLists?.filter(x => x.sectionCode === ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED).length === 0 || !basicInfoCreate.assignsLists) {
      setBasicInfoCreate({ ...basicInfoCreate, assignsLists: jorporDirectorBeInformed });
    }

    return;
  };

  const removeUserJorporDirectorBeInformed = (index: number, section?: ProcurementSection) => {

    if (basicInfoCreate.assignsLists && basicInfoCreate.assignsLists.length > 0) {
      const userListData = [...basicInfoCreate.assignsLists.filter((x) => x.sectionCode !== section)];
      const userData = [...basicInfoCreate.assignsLists.filter((x) => x.sectionCode === section)];

      userData.splice(index, 1);

      userData.forEach((item, i) => {
        userListData.push({
          userId: item.userId,
          name: item.fullName,
          fullName: item.fullName,
          departmentName: item.departmentName,
          position: item.positionName,
          positionName: item.positionName,
          sectionCode: item.sectionCode,
          level: item.level,
        } as CommitteeUser);
      });

      setBasicInfoCreate({ ...basicInfoCreate, assignsLists: userListData });

      return;
    }
  };

  const removeUserLevel = (index: number, level: number) => {
    if (basicInfoCreate.assignsLists && basicInfoCreate.assignsLists.length > 0) {

      const userListData = [...basicInfoCreate.assignsLists.filter((x) => x.level !== level)];
      const userData = [...basicInfoCreate.assignsLists.filter((x) => x.level === level)];
      userData.splice(index, 1);

      userData.forEach((item, i) => {
        userListData.push({
          userId: item.userId,
          name: item.fullName,
          fullName: item.fullName,
          departmentName: item.departmentName,
          position: item.positionName,
          positionName: item.positionName,
          sectionCode: item.sectionCode,
          level: item.level,
        } as CommitteeUser);
      });

      setBasicInfoCreate({ ...basicInfoCreate, assignsLists: userListData });

      return;
    }
  };

  const onSaveDraftAsync = async (status: AnnouncementStatus | string) => {
    basicInfoCreate.termOfRefId = id ?? '';

    if (status === AnnouncementStatus.Accepted && (!basicInfoCreate.assignsLists || basicInfoCreate.assignsLists.length === 0)) {
      toast.warn('กรุณาเลือก ผอ.จพ. รับทราบอย่างน้อย 1 คน');

      return;
    }

    collaboraRef.current?.clickSave();
    setTimeout(async () => {
      if (!basicInfoId) {
        const { data, status } = await PP0205Service.createBasicInfoAsync(basicInfoCreate);

        if (status === HttpStatusCode.CREATED) {
          toast.success('บันทึกข้อมูลสำเร็จ');

          if (id) {
            navigate(`/pp/pp0205/detail/${id}/${data.id}`);
          } else {
            navigate(`/pp/pp0205/detail/basicInfo/${data.id}`);
          }
        }

        getBasicInfoDetailAsync(data.id);

        return;
      }

      basicInfoCreate.id = basicInfoId;
      basicInfoCreate.documentId = docId;

      const response = await PP0205Service.updateBasicInfoAsync(basicInfoCreate, basicInfoId, status);

      if (response.status === HttpStatusCode.ACCEPTED || response.status === HttpStatusCode.OK) {
        if (status === AnnouncementStatus.AssignJorPor) {
          toast.success('มอบหมายสำเร็จ');
          window.location.reload();
        } else {
          toast.success('บันทึกข้อมูลสำเร็จ');
        }

        getBasicInfoDetailAsync(basicInfoId);
      }
    }, 500);
  };

  const onSaveAssignAsync = async () => {

    if (!basicInfoCreate.assignsLists || basicInfoCreate.assignsLists?.filter(x => x.level === 0).length === 0) {
      toast.warn('กรุณาเลือก ผอ.จพ. มอบหมายงานไปยังผู้รับผิดชอบบอย่างน้อย 1 คน');

      return;
    }

    if (basicInfoId) {
      const { data, status } = await PP0205Service.updateAssignsAsync(basicInfoCreate, basicInfoId);

      if (status === HttpStatusCode.ACCEPTED) {
        toast.success('บันทึกมอบหมายงานสำเร็จ');
        navigate(`${location.pathname}`);
      }

      getBasicInfoDetailAsync(data.id);

      return;
    }
  };

  const checkNullDataTable = (fn: Function) => {
    if (!basicInfoCreate.acceptors || basicInfoCreate.acceptors.length === 0) {
      return toast.warn('กรุณาเลือกผู้มีอำนาจเห็นชอบ');
    }

    fn();
  };

  const onNext = () => {
    checkNullDataTable(onClickNext);
    scrollToTop();
  };

  const onBack = () => {
    onClickBack();
    scrollToTop();
  };

  const uploadFile = async (value: FileValue) => {
    if (basicInfoId) {
      const response = await PP0205Service.uploadBasicInformationDocument(basicInfoId, value);

      if (response.status === HttpStatusCode.ACCEPTED) {
        getBasicInfoDetailAsync(basicInfoId);

        toast.success('อัปโหลดเอกสารสำเร็จ');
      }
    }

    if (basicInfoCreate.documentList && basicInfoCreate.documentList.length > 0) {
      const file: FileValue[] = [...basicInfoCreate.documentList];

      file.push(value);

      setBasicInfoCreate({ ...basicInfoCreate, documentList: file });

      return;
    }

    const file: FileValue[] = [];

    file.push(value);

    setBasicInfoCreate({ ...basicInfoCreate, documentList: file });
  };

  const assignByJorPorHeader = async () => {
    if (!await showConfirmModalAsync('ต้องการมอบหมาย ?')) {
      return;
    }

    if (!basicInfoCreate.assignsLists || basicInfoCreate.assignsLists?.filter(x => x.level === 0).length === 0) {
      toast.warn('กรุณาเลือก ผอ.จพ. มอบหมายงานไปยังผู้รับผิดชอบบอย่างน้อย 1 คน');

      return;
    }

    onSaveDraftAsync(AnnouncementStatus.AssignJorPor);
  };

  return (
    <div className='document'>

      <Acceptors
        data={basicInfoCreate.acceptors!}
        visible={(basicInfoCreate.status === AnnouncementStatus.Draft || basicInfoCreate.status === AnnouncementStatus.Reject)}
        titleHistory='ประวัติการแจ้งข้อมูลเบื้องต้น (จพ.004)'
        isDepartmentDirector={true}
        processType={PROCESSTYPE.ApproveBasicInformation}
        budgetAmount={basicInfoCreate.financialAmount}
        onSetSectionType={(type) => setSectionType(type)}
        status={basicInfoCreate.status}
        typeStatus={TypeStatus.JorPor04}
        onSetSectionSequence={(section) => setSectionSequence(section)}
        activity={activitiesData}
        onAcceptorModalShow={() => setAcceptorModalShow(!acceptorModalShow)}
        onAcceptorDataChange={(data) => setBasicInfoCreate(pre => ({
          ...pre,
          acceptors: data,
        }))}
      />

      <Card className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <Title
              text='หนังสือขออนุมัติ'
              className='fs-5 text-primary' />
            {openCollapse ? (
              <ArrowCollapse onClick={() => setOpenCollapse(!openCollapse)} openCollapse={openCollapse} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse(true)} openCollapse={openCollapse} />
            )}
          </div>
        </div>
        <Collapse in={openCollapse}>
          <div className='mt-3'>
            <Collabora
              docId={docId}
              docName='doc-01'
              ref={collaboraRef}
              readonly={basicInfoCreate.status !== AnnouncementStatus.Draft
                && basicInfoCreate.status !== AnnouncementStatus.Reject
                && basicInfoCreate.status !== AnnouncementStatus.DraftAcceptor} />
          </div>
        </Collapse>
      </Card>

      {(basicInfoId && (basicInfoCreate.status === AnnouncementStatus.Accepted || basicInfoCreate.status === AnnouncementStatus.AssignJorPor)) &&
        <>
          <Assignee
            data={basicInfoCreate.assignsLists?.filter(x => x.sectionCode === ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED)!}
            visible={isDisabledJorporDirectorBeInformed && basicInfoCreate.status === AnnouncementStatus.Accepted}
            onAssigneeModalShow={() => setShowJorporDirectorBeInformed(true)}
            onRemoveAssigneeChange={(index) => removeUserJorporDirectorBeInformed(index, ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED)}
            textTitle='ส่งผอ.จพ.รับทราบ'
          />

          <Assignee
            data={basicInfoCreate.assignsLists?.filter((x) => x.level === AssignLevel.Level0)!}
            visible={isDisabledLevel0 || (isDisabledJorporDirectorBeInformed && basicInfoCreate.status === AnnouncementStatus.Accepted)}
            onAssigneeModalShow={() => showModalUser()}
            onRemoveAssigneeChange={(index) => removeUserLevel(index, AssigneesLevel.Level0)}
            textTitle='ผอ.จพ. มอบหมายงานไปยังผู้รับผิดชอบ'
          />
        </>
      }

      {(basicInfoCreate.status === AnnouncementStatus.AssignJorPor) &&
        <Assignee
          data={basicInfoCreate.assignsLists!.filter((x) => x.level === AssignLevel.Level1)!}
          visible={isDisabledLevel1 || isDisabledLevel0}
          onAssigneeModalShow={() => setShowUserAssigns(true)}
          onRemoveAssigneeChange={(index) => removeUserLevel(index, AssignLevel.Level1)}
          textTitle='ผู้รับผิดชอบ'
        />
      }

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              disabled={false}
              value={basicInfoCreate.documentList}
              onChange={(value) => uploadFile(value)}
              onRemove={(index, id) => removeFile(index, id)}
              onDownload={(index, id) => downloadFileAsync(index, id)}
              disabledRemove={!isCreator} />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          onClick={onBack}
          variant='outline-primary'>
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          {(basicInfoCreate.status === AnnouncementStatus.Accepted && (isDisabledJorporDirectorBeInformed || isDisabledLevel0 || isDisabledLevel1)) &&
            <>
              <Button
                variant='danger'
                onClick={() => onShowModal(ModalType.JORPORREJECT)}
                className='me-2 px-3'>
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
              <Button
                variant='success'
                className='mx-3'
                onClick={() => onSaveDraftAsync(basicInfoCreate.status)}>
                <FaSave className='me-2 pb-1 fs-5' />
                บันทึก
              </Button>
              <Button onClick={assignByJorPorHeader}>
                <FaAlignLeft className='me-2 pb-1 fs-5' />
                ยืนยันมอบหมาย
              </Button>
            </>
          }
          {
            (basicInfoCreate.status === AnnouncementStatus.AssignJorPor && (isDisabledLevel0 || isDisabledLevel1)) && (
              <Button
                onClick={onSaveAssignAsync}
                className='me-2 px-3'
                variant='success'>
                <FaSave className='me-2 pb-1 fs-5' />
                บันทึกมอบหมายงาน
              </Button>
            )
          }
          {
            isCreator && (basicInfoCreate.status === AnnouncementStatus.Draft || basicInfoCreate.status === AnnouncementStatus.Reject) && (
              <Button
                onClick={() => onSaveDraftAsync((basicInfoCreate.status))}
                className='me-2 px-3'
                variant='success'>
                <FaSave className='me-2 pb-1 fs-5' />
                บันทึก
              </Button>
            )
          }
          {isCreator &&
            (basicInfoCreate.status !== ProcessStatus.WAITING_ACCEPTOR && basicInfoCreate.status !== ProcessStatus.ACCEPTED && basicInfoCreate.status !== ProcessStatus.ASSIGN_JORPOR) && (
              <Button
                variant='primary'
                onClick={sendApprove}
                className='me-2 px-3'>
                <FaRegCheckCircle className='mx-2 pb-1 fs-4' />
                ส่งเห็นชอบ/อนุมัติ
              </Button>
            )}
          {(
            basicInfoCreate.status === AnnouncementStatus.WaitingAcceptor &&
            isCreator &&
            basicInfoCreate.acceptors?.every(f => f.status === AcceptorStatusEnum.Pending)) && <Button
              className='me-2 px-3'
              variant='danger'
              onClick={() => onRecall()}>
              <FaUndo className='mx-2 pb-1 fs-4' />
              เรียกคืนแก้ไข
            </Button>}
          {(basicInfoCreate.status === ProcessStatus.WAITING_ACCEPTOR && isQueue && isAcceptor) && <>
            <Button
              variant='danger'
              onClick={() => onShowModal(ModalType.REJECT)}
              className='me-2 px-3'>
              <FaUndo className='mx-2 pb-1 fs-4' />
              ส่งกลับแก้ไข
            </Button>
            <Button
              variant='success'
              onClick={() => onShowModal(ModalType.APPROVE)}
              className='me-2 px-3'>
              <FaCheckCircle className='mx-2 pb-1 fs-4' />
              {/* {isLastApprover ? 'อนุมัติ' : 'เห็นชอบ'} */}
              เห็นชอบ
            </Button>
          </>}
        </div>
        {/* {basicInfoId ? <Button
          onClick={onNext}
          className='me-2 px-3'
          variant='outline-primary'>
          ถัดไป
          <MdArrowForward className='pb-1 fs-5' />
        </Button> : <div />} */}
        <div style={{ width: '190px' }} />
      </div>

      <SearchBuNameModal
        total={userTotal}
        show={acceptorModalShow}
        onHide={() => setAcceptorModalShow(!acceptorModalShow)}
        onSelect={(id, name, department, position) => acceptorOnSelect(id, name, department!, position!)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <SearchBuNameModal
        total={userTotal}
        show={showUserJorporDirectorBeInformed}
        onHide={() => setShowJorporDirectorBeInformed(!showUserJorporDirectorBeInformed)}
        onSelect={(id, name, department, position) => assignsOnSelect(id, name, department!, position!, ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <SearchBuNameModal
        total={userTotal}
        show={show}
        onHide={() => setShow(!show)}
        onSelect={(id, name, department, position) => assignsOnSelectLevel0(id, name, department!, position!, ProcurementSection.JORPOR_DIRECTOR_ASSIGN, AssignLevel.Level0)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <SearchBuNameModal
        total={userTotal}
        show={showUserAssigns}
        onHide={() => setShowUserAssigns(!showUserAssigns)}
        onSelect={(id, name, department, position) => assignsOnSelectLevel1(id, name, department!, position!, '', AssignLevel.Level1)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <HistoryModal
        title='ประวัติการแจ้งข้อมูลเบื้องต้น (จพ.004)'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        basicInformationId={basicInfoCreate.id}
      />
      <ApproveAndRejectModal modalType={modalType}
        show={showEdit}
        onHide={() => setShowEdit(false)}
        onApproved={() => getDocument(basicInfoId ?? '')}
        isLastApprover={isLastApprover}
        onClickBack={onClickBack} />

      <Modal
        show={showModalPending}
        size='lg'
        onHide={() => setShowModalPending(!showModalPending)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งเห็นชอบ/อนุมัติการแจ้งข้อมูลเบื้องต้น (จพ.004) หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowModalPending(!showModalPending)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSendApprove()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div >
  );
}


function ApproveAndRejectModal(props: { modalType: ModalType, show: boolean, onHide: () => void, isLastApprover: boolean, onApproved: () => void, onClickBack: () => void }) {
  const { basicInfoCreate, isAcceptor, isCreator, isQueue, acceptorId, getBasicInfoDetailAsync } = useContext(Context);
  const { userId } = useAppContext();
  const { basicInfoId } = useParams();
  const [remark, setRemark] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    props.show && setRemark('');
  }, [props.show]);

  const onSubmit = () => {
    submitForm();

    if ((props.modalType === ModalType.REJECT || props.modalType === ModalType.JORPORREJECT) && !remark) {
      return;
    }

    if (props.modalType === ModalType.JORPORREJECT) {

      onSubmitJorPorRejectAsync();
    } else {

      onSubmitApproveOrRejectAsync();
    }
  };

  function isNull(oldValue?: string, newValue?: string) {
    if (oldValue) {
      return oldValue;
    }

    return newValue;
  }

  const onSubmitJorPorRejectAsync = async () => {
    if (basicInfoId) {
      const response = await PP0205Service.sendJorPorRejectAsync(basicInfoId, remark);

      if (response.status === HttpStatusCode.ACCEPTED) {
        getBasicInfoDetailAsync(basicInfoId);
        toast.success('ส่งกลับแก้ไขสำเร็จ');
        window.location.reload();
        props.onHide();
        return;
      }
    }
  };

  const onSubmitApproveOrRejectAsync = async () => {
    if (basicInfoCreate.acceptors && basicInfoCreate.acceptors.length > 0 && basicInfoId) {
      const section = basicInfoCreate.acceptors.filter(b => isNull(b.delegateUserId, b.userId) === userId);
      const body: BasicInformationAction = {
        sectionCode: section[0].sectionCode!,
        act: props.modalType,
        remark: remark!,
        acceptorId: acceptorId
      };

      const response = await PP0205Service.sendApproveAndRejectAsync(basicInfoId, body);

      if (props.modalType === ModalType.APPROVE) {
        if (response.status === HttpStatusCode.ACCEPTED) {
          // toast.success(`${props.isLastApprover ? 'อนุมัติ' : 'เห็นชอบ'}สำเร็จ`);
          toast.success('เห็นชอบสำเร็จ');
          props.onApproved();
          props.onHide();
          window.location.reload();
          getBasicInfoDetailAsync(basicInfoId);
          if (props.isLastApprover) {
            props.onClickBack();
          }
        }

        return;
      }

      if (response.status === HttpStatusCode.ACCEPTED) {
        getBasicInfoDetailAsync(basicInfoId);
        toast.success('ส่งกลับแก้ไขสำเร็จ');
        window.location.reload();
        props.onHide();
        return;
      }
    }
  };

  return (
    <Modal
      show={props.show}
      size='lg'
      onHide={props.onHide}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>{props.modalType === ModalType.APPROVE ? 'เห็นชอบ'
              : 'ส่งกลับแก้ไข'}</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4 mt-2'>
            {
              (props.modalType === ModalType.APPROVE) && (
                <InputTextArea label='หมายเหตุ'
                  value={remark}
                  onChange={(value) => setRemark(value)} />
              )
            }
            {
              (props.modalType === ModalType.REJECT || props.modalType === ModalType.JORPORREJECT) && (
                <InputTextArea label='หมายเหตุ'
                  rule={{ required: true }}
                  value={remark}
                  onChange={(value) => setRemark(value)} />
              )
            }
          </ModalBT.Body>
          <ModalBT.Footer className='justify-content-center'>
            <Button
              variant='light'
              onClick={props.onHide}
              className='me-2 px-3'>
              ยกเลิก
            </Button>
            {props.modalType === ModalType.APPROVE ? <Button
              onClick={onSubmit}
              variant='success'
              className='me-2 px-3'>
              <FaCheckCircle className='mx-2 pb-1 fs-4' />
              {/* {props.isLastApprover ? 'อนุมัติ' : 'เห็นชอบ'} */}
              เห็นชอบ
            </Button> : <Button
              onClick={onSubmit}
              variant='danger'
              className='me-2 px-3'>
              <FaUndo className='mx-2 pb-1 fs-4' />
              ส่งกลับแก้ไข
            </Button>}
          </ModalBT.Footer>
        </>
      )}
    />
  );
}