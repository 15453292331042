import { HttpStatusCode } from 'axios';
import {
  ItemModel,
  Pagination,
  UserListSelectionParams,
  UserListSelectionResponse,
} from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { useLoaderData } from 'react-router';
import { shareValue } from 'services';
import {
  Card,
  Input,
  Modal,
  Selector,
  Table,
} from '../../../components';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  onClickAdd: (data: UserListSelectionResponse) => void;
  approverSelected: UserListSelectionResponse[];
  toggleListModal: boolean;
  setShowListModal: (value: boolean) => void;
}

interface FormInput {
  id: string;
  name: string;
  department: string;
  position: string;
}

type Loader = { departmentList: ItemModel[], usersList: UserListSelectionResponse[], positionList: ItemModel[] };

export default function ModalListLicensor({ toggleListModal, setShowListModal, show, setShow, onClickAdd, approverSelected }: Props) {
  const { departmentList, usersList, positionList } = useLoaderData() as Loader;
  const [pagination, setPagination] = useState<Pagination>({ page: 1, size: 10, totalRecords: 0 });
  const [licensorData, setLicensorData] = useState<UserListSelectionResponse[]>([]);
  const [criteriaModal, setCriteriaModal] = useState<UserListSelectionParams>({} as UserListSelectionParams);

  useEffect(() => {
    const temp: UserListSelectionResponse[] = [...usersList];
    if (approverSelected.length > 0) {
      approverSelected.forEach((value) => {
        temp.forEach((v, i) => {
          if (v.id === value.id) {
            temp.splice(i, 1);
          }
        });
      });
    }
    setLicensorData(temp);
    setPagination({ ...pagination, totalRecords: temp.length });
  }, [show]);

  const onSelectedApprover = (data: UserListSelectionResponse) => {
    onClickAdd(data);
    setShow(!show);
    setLicensorData([]);
    setShowListModal(!toggleListModal);
  };

  const getUsersAsync = async () => {
    const res = await shareValue.getUserListSelectionAsync(
      pagination.page,
      pagination.size,
      criteriaModal,
    );
    if (res.status === HttpStatusCode.Ok) {
      const usersData: UserListSelectionResponse[] = res.data.data;
      if (approverSelected.length > 0) {
        approverSelected.forEach((value) => {
          usersData.forEach((v, i) => {
            if (v.id === value.id) {
              usersData.splice(i, 1);
            }
          });
        });
      }
      setLicensorData(usersData);
      setPagination({ ...pagination, totalRecords: usersData.length });
    }
  };

  const SearchData = () => {
    getUsersAsync();
  };

  const onClear = useCallback(async () => {
    setCriteriaModal({} as UserListSelectionParams);
    await getUsersAsync();
  }, [show]);

  return (
    <Modal
      show={show}
      size='xl'
      onHide={() => {
        setShow(!show);
        setShowListModal(!toggleListModal);
      }}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>ค้นหารายชื่อ</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4'>
            <Card className='mb-3'>
              <p>ค้นหา</p>
              <Row>
                <Col md={12}
                  lg={4}>
                  <Input
                    label='ชื่อ-นามสกุล'
                    value={criteriaModal.fullName}
                    onChange={(val) => setCriteriaModal({ ...criteriaModal, fullName: val })}
                  />
                </Col>
                <Col md={12}
                  lg={4}>
                  <Selector
                    label='ฝ่าย/สำนัก'
                    items={departmentList}
                    value={criteriaModal.departmentId}
                    onChange={(val) => setCriteriaModal({ ...criteriaModal, departmentId: val })}
                  />
                </Col>
                <Col md={12}
                  lg={4}>
                  <Selector
                    label='ตำแหน่ง'
                    items={positionList}
                    value={criteriaModal.positionId}
                    onChange={(val) => setCriteriaModal({ ...criteriaModal, positionId: val })}
                  />
                </Col>
              </Row>
              <div className='d-flex gap-2'>
                <Button
                  variant='primary'
                  className='d-flex align-items-center gap-2'
                  onClick={SearchData}
                >
                  <FaSearch />ค้นหา
                </Button>
                <Button
                  variant='outline-primary'
                  className='d-flex align-items-center gap-2'
                  onClick={onClear}
                >
                  <FaEraser />ล้าง
                </Button>
              </div>
            </Card>
            <Table total={pagination.totalRecords}
              onChange={(size, page) => (setPagination({ ...pagination, size }), setPagination({ ...pagination, page }))}>
              <thead>
              <tr>
                <th>ลำดับ</th>
                <th>ชื่อ-นามสกุล</th>
                <th>ฝ่าย/สำนัก</th>
                <th>ตำแหน่ง</th>
                <th />
              </tr>
              </thead>
              <tbody>
              {
                licensorData.map((item, index) => (
                  <tr key={item.id}>
                    <td className='text-center'>{index + 1}</td>
                    <td>{item.fullName}</td>
                    <td className='text-center'>{item.departmentName}</td>
                    <td className='text-center'>{item.positionName}</td>
                    <td className='text-center'>
                      <Button variant='primary'
                        onClick={() => onSelectedApprover(item)}> เลือก </Button>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </Table>
          </ModalBT.Body>
        </>
      )}
    />
  );
}
