export enum PROCESSTYPE {
  ApprovePlanProcess = 'ApprovePlanProcess', // อนุมัติเผยแพร่แผนจัดซื้อจัดจ้าง
  ApproveTorCommitteeAnnouncementProcessStock = 'ApproveTorCommitteeAnnouncementProcessStock', // อนุมัติแต่ตั้งคณะกรรมการ TOR ราคากลาง (Stock)
  ApproveTorCommitteeAnnouncementProcessNonStock = 'ApproveTorCommitteeAnnouncementProcessNonStock', // อนุมัติแต่ตั้งคณะกรรมการ TOR ราคากลาง (NonStock)
  ApproveTorProcessStock = 'ApproveTorProcessStock', //  อนุมัติ TOR (Stock)
  ApproveTorProcessNonStock = 'ApproveTorProcessNonStock', //  อนุมัติ TOR (NonStock)
  ApproveMedianProcessStock = 'ApproveMedianProcessStock', // อนุมัติราคากลาง (Stock)
  ApproveMedianProcessNonStock = 'ApproveMedianProcessNonStock', // อนุมัติราคากลาง (NonStock)
  ApprovePrerequisiteProcess = 'ApprovePrerequisiteProcess', // อนุมัติ จพ.005
  ApproveProcurementCommitteeProcess = 'ApproveProcurementCommitteeProcess', // อนุมัติแต่ตั้งคณะกรรมการจัดซื้อจัดจ้าง
  ApproveWinnerAnnouncementProcess = 'ApproveWinnerAnnouncementProcess', // อนุมัติประกาศผู้ชนะการเสนอราคา
  ApprovePurchaseOrderProcess = 'ApprovePurchaseOrderProcess', // อนุมัติใบสั่งซื้อ ใบสั่งจ้าง
  ApproveInvitationSignContractProcess = 'ApproveInvitationSignContractProcess', // อนุมัติเชิญลงนามสัญญา
  ApproveSignContractProcess = 'ApproveSignContractProcess', // อนุมัติลงนามสัญญา
  ApproveReceiveProcess = 'ApproveReceiveProcess', // อนุมัติตรวจรับพัสดุ
  ApproveContractAuditAndRevenueReport = 'ApproveContractAuditAndRevenueReport', // อนุมัติรายงานสำนักงานตรวจเงินแผ่นดินและหนังสือถึงสรรพากร
  ApproveContractQuaterReport = 'ApproveContractQuaterReport', // อนุมัติรายงานสัญญาแล้วเสร็จ ตามไตรมาส
  ApproveContractReturnCollateral = 'ApproveContractReturnCollateral', // อนุมัติคืนหลักประกันสัญญา
  ApproveProcurementInviteProcess = 'ApproveProcurementInviteProcess', // จัดทำหนังสือเชิญชวนผู้ประกอบการ
  ApproveContractCompleteReport = 'ApproveContractCompleteReport', //รายงานสัญญาแล้วเสร็จตามไตรมาส
  ApproveBasicInformation = 'ApproveBasicInformation', // จพ.004
}

const SectionTypeData = [
  { name: 'ผู้ช่วยหัวหน้าส่วน', sequence: 1, level: 10, inRefCode: 'BP040' },
  { name: 'หัวหน้าส่วน', sequence: 2, level: 11, inRefCode: 'BP035' },
  { name: 'ผู้จัดการเขต', sequence: 3, level: 12, inRefCode: 'BP022' },
  { name: 'ผู้ช่วยผู้อำนวยการฝ่าย', sequence: 4, level: 13, inRefCode: 'BP015' },
  { name: 'ผู้ช่วยผู้อำนวยการภาค', sequence: 5, level: 13, inRefCode: 'BP012' },
  { name: 'ผู้ช่วยผู้อำนวยการ', sequence: 6, level: 14, inRefCode: 'BP010' },
  { name: 'ผู้อำนวยการศูนย์วิเคราะห์สินเชื่อ', sequence: 13, level: 10, inRefCode: 'BP011' },
  { name: 'ผู้อำนวยการสำนัก', sequence: 8, level: 14, inRefCode: 'BP009' },
  { name: 'ผู้อำนวยการฝ่าย', sequence: 9, level: 14, inRefCode: 'BP008' },
  { name: 'ผู้อำนวยการภาค', sequence: 10, level: 14, inRefCode: 'BP007' },
  { name: 'ผู้ช่วยกรรมการผู้จัดการ', sequence: 11, level: 15, inRefCode: 'BP006' },
  { name: 'ผู้อำนวยการศูนย์ข้อมูลอสังหาริมทรัพย์', sequence: 16, level: 10, inRefCode: 'BP005' },
  { name: 'รองกรรมการผู้จัดการ', sequence: 13, level: 16, inRefCode: 'BP003' },
  { name: 'กรรมการผู้จัดการ', sequence: 14, level: 99, inRefCode: 'BP002' }
];

export enum SectionType {
  BP001 = 'BP001', //กรรมการธนาคาร
  BP002 = 'BP002', //กรรมการผู้จัดการ
}