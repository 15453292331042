import {
  Card,
  Check,
  Input,
  InputTextArea,
  Status,
  StatusType,
  Table,
  UploadFile,
  ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import {
  HistoryModal,
  Modal,
} from 'components/Modal';
import { UserListSelectionResponse } from 'models';
import { CommitteeUser } from 'models/PP';
import { useState } from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {
  BsArrowDownCircle,
  BsArrowReturnLeft,
} from 'react-icons/bs';
import {
  FaPlus,
  FaSave,
  FaTrashAlt,
  FaChevronCircleDown,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { dataHistory } from '../data';
import { ModalSelectUserWinner } from './ModalSelectUser';
import { useAppContext } from 'utils';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

enum ModalType {
  SECTION_HEAD = 'SectionHead',
  ASSISTANT = 'Assistant',
  DEPARTMENT = 'Department',
  ASSIS = 'Assis',
  DEPUTY = 'Deputy',
  MANAGE = 'Manage',
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [sectionHeadLists, setSectionLists] = useState<CommitteeUser[]>([]);
  const [assistantDirectorLists, setAssistantDirectorLists] = useState<CommitteeUser[]>([]);
  const [departmentDirectorLists, setDepartmentDirectorLists] = useState<CommitteeUser[]>([]);
  const [assisDirectorLists, setAssisDirectorLists] = useState<CommitteeUser[]>([]);
  const [deputymanageDirectorLists, setDeputyManageDirectorLists] = useState<CommitteeUser[]>([]);
  const [manageDirectorLists, setManageDirectorLists] = useState<CommitteeUser[]>([]);
  const [userListsSelect, setUserListSelect] = useState<CommitteeUser[]>([]);
  const [modalSelectUserShow, setModalSelectUserShow] = useState(false);
  const [modalType, setModalType] = useState<ModalType>();
  const [onSave, setOnSave] = useState(false);
  const { userId } = useAppContext();

  const showModalSelect = (type: ModalType) => {
    switch (type) {
      case ModalType.SECTION_HEAD:
        setUserListSelect(sectionHeadLists);
        break;
      case ModalType.ASSISTANT:
        setUserListSelect(assistantDirectorLists);
        break;
      case ModalType.DEPARTMENT:
        setUserListSelect(departmentDirectorLists);
        break;
      case ModalType.ASSIS:
        setUserListSelect(assisDirectorLists);
        break;
      case ModalType.DEPUTY:
        setUserListSelect(deputymanageDirectorLists);
        break;
      default:
        setUserListSelect(manageDirectorLists);
    }

    setModalType(type);
    setModalSelectUserShow(true);
  };

  const onSelect = (userData: UserListSelectionResponse) => {
    const user: CommitteeUser = ({
      userId: userData.id,
      fullName: userData.fullName,
      departmentName: userData.departmentName,
      positionName: userData.positionName,
    });

    switch (modalType) {
      case ModalType.SECTION_HEAD:
        sectionHeadLists.push(user);
        break;
      case ModalType.ASSISTANT:
        assistantDirectorLists.push(user);
        break;
      case ModalType.DEPARTMENT:
        departmentDirectorLists.push(user);
        break;
      case ModalType.ASSIS:
        assisDirectorLists.push(user);
        break;
      case ModalType.DEPUTY:
        deputymanageDirectorLists.push(user);
        break;
      default:
        manageDirectorLists.push(user);
    }
  };

  const removeUserSelect = (type: ModalType, index: number) => {
    if (type === ModalType.SECTION_HEAD) {
      const userSelect = [...sectionHeadLists];
      userSelect.splice(index, 1);
      setSectionLists(userSelect);

      return;
    }

    if (type === ModalType.ASSISTANT) {
      const userSelect = [...assistantDirectorLists];
      userSelect.splice(index, 1);
      setAssistantDirectorLists(userSelect);

      return;
    }

    if (type === ModalType.ASSIS) {
      const userSelect = [...assisDirectorLists];
      userSelect.splice(index, 1);
      setAssisDirectorLists(userSelect);

      return;
    }

    if (type === ModalType.DEPARTMENT) {
      const userSelect = [...departmentDirectorLists];
      userSelect.splice(index, 1);
      setDepartmentDirectorLists(userSelect);

      return;
    }

    if (type === ModalType.DEPUTY) {
      const userSelect = [...deputymanageDirectorLists];
      userSelect.splice(index, 1);
      setDeputyManageDirectorLists(userSelect);

      return;
    }

    if (type === ModalType.MANAGE) {
      const userSelect = [...manageDirectorLists];
      userSelect.splice(index, 1);
      setManageDirectorLists(userSelect);

      return;
    }
  };

  const onSaveMockUp = () => {
    setOnSave(true);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='ผู้มีอำนาจเห็นชอบ'
              className='fs-5 text-primary' />
            {openCollapse1 ? (
              <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />)}
          </div>
        </div>
        <Collapse in={openCollapse1}>
          <Row className='gap-5'>
            <Col sm={12}
              className='ps-5'>
              <div className='d-flex justify-content-between mb-1'>
                <p className='fs-5 text-primary'>หัวหน้าส่วน</p>
                <Button
                  variant='outline-primary'
                  onClick={() => showModalSelect(ModalType.SECTION_HEAD)}>
                  <FaPlus />เพิ่มรายชื่อ
                </Button>
              </div>

              <Table total={1}
                hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>ลำดับ</th>
                    <th style={{ minWidth: 200, width: 270 }}>ชื่อ-นามสกุล</th>
                    <th style={!onSave ? { minWidth: 500, width: 180 } : { minWidth: 50, width: 180 }}>ฝ่าย/สำนัก</th>
                    <th style={!onSave ? { minWidth: 500, width: 300 } : { minWidth: 50, width: 300 }}>ตำแหน่ง</th>
                    {onSave && <>
                      <th style={{ minWidth: 150, width: 180 }}>สถานะ</th>
                      <th style={{ minWidth: 50, width: 300 }}>วันที่อนุมัติเห็นชอบ/<br />ส่งกลับแก้ไข</th>
                      <th style={{ minWidth: 50, width: 300 }}>ไม่สามารถปฏิบัติงานได้</th>
                      <th style={{ minWidth: 200 }}>หมายเหตุ</th>
                    </>}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {sectionHeadLists.map((data, index) => (
                    <tr className='text-center'
                      key={index}>
                      <td>{index + 1}</td>
                      <td className='text-start'>{data.fullName}</td>
                      <td className='text-start'>{data.departmentName}</td>
                      <td className='text-start'>{data.positionName}</td>
                      {onSave && <>
                        <td><Status type={StatusType.JOR_POR_04_05_APPROVER}
                          value='Pending'
                          badge /></td>
                        <td></td>
                        <td><Check className='d-inline-block' /></td>
                        <td><Input /></td>
                      </>}
                      <td>
                        <Button
                          variant='danger'
                          onClick={() => removeUserSelect(ModalType.SECTION_HEAD, index)}>
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col sm={12}
              className='ps-5'>
              <div className='d-flex justify-content-between mb-1'>
                <p className='fs-5 text-primary'>ผู้ช่วยผู้อำนวยการฝ่าย</p>
                <Button
                  variant='outline-primary'
                  onClick={() => showModalSelect(ModalType.ASSISTANT)}
                >
                  <FaPlus />เพิ่มรายชื่อ
                </Button>
              </div>
              <Table total={1}
                hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>ลำดับ</th>
                    <th style={{ minWidth: 200, width: 270 }}>ชื่อ-นามสกุล</th>
                    <th style={!onSave ? { minWidth: 500, width: 180 } : { minWidth: 50, width: 180 }}>ฝ่าย/สำนัก</th>
                    <th style={!onSave ? { minWidth: 500, width: 300 } : { minWidth: 50, width: 300 }}>ตำแหน่ง</th>
                    {onSave && <>
                      <th style={{ minWidth: 150, width: 180 }}>สถานะ</th>
                      <th style={{ minWidth: 50, width: 300 }}>วันที่อนุมัติเห็นชอบ/<br />ส่งกลับแก้ไข</th>
                      <th style={{ minWidth: 50, width: 300 }}>ไม่สามารถปฏิบัติงานได้</th>
                      <th style={{ minWidth: 200 }}>หมายเหตุ</th>
                    </>}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {assistantDirectorLists.map((data, index) => (
                    <tr className='text-center'
                      key={index}>
                      <td>{index + 1}</td>
                      <td className='text-start'>{data.fullName}</td>
                      <td className='text-start'>{data.departmentName}</td>
                      <td className='text-start'>{data.positionName}</td>
                      {onSave && <>
                        <td><Status type={StatusType.JOR_POR_04_05_APPROVER}
                          value='Pending'
                          badge /></td>
                        <td></td>
                        <td><Check className='d-inline-block' /></td>
                        <td><Input /></td>
                      </>}
                      <td>
                        <Button
                          variant='danger'
                          onClick={() => removeUserSelect(ModalType.ASSISTANT, index)}>
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>

            <Col sm={12}
              className='ps-5'>
              <div className='d-flex justify-content-between mb-1'>
                <p className='fs-5 text-primary'>ผู้อำนวยการฝ่าย</p>
                <Button
                  variant='outline-primary'
                  onClick={() => showModalSelect(ModalType.DEPARTMENT)}
                >
                  <FaPlus />เพิ่มรายชื่อ
                </Button>
              </div>
              <Table total={1}
                hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>ลำดับ</th>
                    <th style={{ minWidth: 200, width: 270 }}>ชื่อ-นามสกุล</th>
                    <th style={!onSave ? { minWidth: 500, width: 180 } : { minWidth: 50, width: 180 }}>ฝ่าย/สำนัก</th>
                    <th style={!onSave ? { minWidth: 500, width: 300 } : { minWidth: 50, width: 300 }}>ตำแหน่ง</th>
                    {onSave && <>
                      <th style={{ minWidth: 150, width: 180 }}>สถานะ</th>
                      <th style={{ minWidth: 50, width: 300 }}>วันที่อนุมัติเห็นชอบ/<br />ส่งกลับแก้ไข</th>
                      <th style={{ minWidth: 50, width: 300 }}>ไม่สามารถปฏิบัติงานได้</th>
                      <th style={{ minWidth: 200 }}>หมายเหตุ</th>
                    </>}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {departmentDirectorLists.map((data, index) => (
                    <tr className='text-center'
                      key={index}>
                      <td>{index + 1}</td>
                      <td className='text-start'>{data.fullName}</td>
                      <td className='text-start'>{data.departmentName}</td>
                      <td className='text-start'>{data.positionName}</td>
                      {onSave && <>
                        <td><Status type={StatusType.JOR_POR_04_05_APPROVER}
                          value='Pending'
                          badge /></td>
                        <td></td>
                        <td><Check className='d-inline-block' /></td>
                        <td><Input /></td>
                      </>}
                      <td>
                        <Button
                          variant='danger'
                          onClick={() => removeUserSelect(ModalType.DEPARTMENT, index)}>
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col sm={12}
              className='ps-5'>
              <div className='d-flex justify-content-between mb-1'>
                <p className='fs-5 text-primary'>ผู้ช่วยกรรมการ</p>
                <Button
                  variant='outline-primary'
                  onClick={() => showModalSelect(ModalType.ASSIS)}
                >
                  <FaPlus />เพิ่มรายชื่อ
                </Button>
              </div>
              <Table total={1}
                hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>ลำดับ</th>
                    <th style={{ minWidth: 200, width: 270 }}>ชื่อ-นามสกุล</th>
                    <th style={!onSave ? { minWidth: 500, width: 180 } : { minWidth: 50, width: 180 }}>ฝ่าย/สำนัก</th>
                    <th style={!onSave ? { minWidth: 500, width: 300 } : { minWidth: 50, width: 300 }}>ตำแหน่ง</th>
                    {onSave && <>
                      <th style={{ minWidth: 150, width: 180 }}>สถานะ</th>
                      <th style={{ minWidth: 50, width: 300 }}>วันที่อนุมัติเห็นชอบ/<br />ส่งกลับแก้ไข</th>
                      <th style={{ minWidth: 50, width: 300 }}>ไม่สามารถปฏิบัติงานได้</th>
                      <th style={{ minWidth: 200 }}>หมายเหตุ</th>
                    </>}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {assisDirectorLists.map((data, index) => (
                    <tr className='text-center'
                      key={index}>
                      <td>{index + 1}</td>
                      <td className='text-start'>{data.fullName}</td>
                      <td className='text-start'>{data.departmentName}</td>
                      <td className='text-start'>{data.positionName}</td>
                      {onSave && <>
                        <td><Status type={StatusType.JOR_POR_04_05_APPROVER}
                          value='Pending'
                          badge /></td>
                        <td></td>
                        <td><Check className='d-inline-block' /></td>
                        <td><Input /></td>
                      </>}
                      <td>
                        <Button
                          variant='danger'
                          onClick={() => removeUserSelect(ModalType.ASSIS, index)}>
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col sm={12}
              className='ps-5'>
              <div className='d-flex justify-content-between mb-1'>
                <p className='fs-5 text-primary'>รองกรรมการผู้จัดการ</p>
                <Button
                  variant='outline-primary'
                  onClick={() => showModalSelect(ModalType.DEPUTY)}
                >
                  <FaPlus />เพิ่มรายชื่อ
                </Button>
              </div>
              <Table total={1}
                hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>ลำดับ</th>
                    <th style={{ minWidth: 200, width: 270 }}>ชื่อ-นามสกุล</th>
                    <th style={!onSave ? { minWidth: 500, width: 180 } : { minWidth: 50, width: 180 }}>ฝ่าย/สำนัก</th>
                    <th style={!onSave ? { minWidth: 500, width: 300 } : { minWidth: 50, width: 300 }}>ตำแหน่ง</th>
                    {onSave && <>
                      <th style={{ minWidth: 150, width: 180 }}>สถานะ</th>
                      <th style={{ minWidth: 50, width: 300 }}>วันที่อนุมัติเห็นชอบ/<br />ส่งกลับแก้ไข</th>
                      <th style={{ minWidth: 50, width: 300 }}>ไม่สามารถปฏิบัติงานได้</th>
                      <th style={{ minWidth: 200 }}>หมายเหตุ</th>
                    </>}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {deputymanageDirectorLists.map((data, index) => (
                    <tr className='text-center'
                      key={index}>
                      <td>{index + 1}</td>
                      <td className='text-start'>{data.fullName}</td>
                      <td className='text-start'>{data.departmentName}</td>
                      <td className='text-start'>{data.positionName}</td>
                      {onSave && <>
                        <td><Status type={StatusType.JOR_POR_04_05_APPROVER}
                          value='Pending'
                          badge /></td>
                        <td></td>
                        <td><Check className='d-inline-block' /></td>
                        <td><Input /></td>
                      </>}
                      <td>
                        <Button
                          variant='danger'
                          onClick={() => removeUserSelect(ModalType.DEPUTY, index)}>
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col sm={12}
              className='ps-5'>
              <div className='d-flex justify-content-between mb-1'>
                <p className='fs-5 text-primary'>กรรมการผู้จัดการ</p>
                <Button
                  variant='outline-primary'
                  onClick={() => showModalSelect(ModalType.MANAGE)}
                >
                  <FaPlus />เพิ่มรายชื่อ
                </Button>
              </div>
              <Table total={1}
                hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>ลำดับ</th>
                    <th style={{ minWidth: 200, width: 270 }}>ชื่อ-นามสกุล</th>
                    <th style={!onSave ? { minWidth: 500, width: 180 } : { minWidth: 50, width: 180 }}>ฝ่าย/สำนัก</th>
                    <th style={!onSave ? { minWidth: 500, width: 300 } : { minWidth: 50, width: 300 }}>ตำแหน่ง</th>
                    {onSave && <>
                      <th style={{ minWidth: 150, width: 180 }}>สถานะ</th>
                      <th style={{ minWidth: 50, width: 300 }}>วันที่อนุมัติเห็นชอบ/<br />ส่งกลับแก้ไข</th>
                      <th style={{ minWidth: 50, width: 300 }}>ไม่สามารถปฏิบัติงานได้</th>
                      <th style={{ minWidth: 200 }}>หมายเหตุ</th>
                    </>}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {manageDirectorLists.map((data, index) => (
                    <tr className='text-center'
                      key={index}>
                      <td>{index + 1}</td>
                      <td className='text-start'>{data.fullName}</td>
                      <td className='text-start'>{data.departmentName}</td>
                      <td className='text-start'>{data.positionName}</td>
                      {onSave && <>
                        <td><Status type={StatusType.JOR_POR_04_05_APPROVER}
                          value='Pending'
                          badge /></td>
                        <td></td>
                        <td><Check className='d-inline-block' /></td>
                        <td><Input /></td>
                      </>}
                      <td>
                        <Button
                          variant='danger'
                          onClick={() => removeUserSelect(ModalType.MANAGE, index)}>
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile userId={userId} />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          variant='outline-primary'
          onClick={onClickBack}
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          {/* <Button
            variant="danger"
            onClick={() => setShow(show)}
            className="me-2 px-3"
          >
            <BsArrowReturnLeft className="mx-2 pb-1 fs-4" />
            ส่งกลับแก้ไข
          </Button> */}
          <Button
            variant='success'
            className='me-2 px-3'
            onClick={onSaveMockUp}>
            <FaSave className='ms-2 pb-1 fs-5' />
            บันทึก
          </Button>
          {/* <Button
            className="me-2 px-3"
          >
            <FaRegCheckCircle className="me-2 pb-1 fs-5" />
            เห็นชอบ
          </Button> */}
        </div>
        <Button
          variant='outline-primary'
          onClick={onClickNext}
          className='me-2 px-3'
        >
          ถัดไป
          <MdArrowForward className='ms-2 pb-1 fs-5' />
        </Button>
      </div>
      <HistoryModal
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        data={dataHistory}
      />

      <Modal
        show={show}
        size='lg'
        onHide={() => setShow(!show)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-center'>
              <Button
                variant='light'
                onClick={() => setShow(!show)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                className='me-2 px-3'
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <ModalSelectUserWinner show={modalSelectUserShow}
        onHide={() => setModalSelectUserShow(false)}
        userSelected={userListsSelect}
        onSelect={onSelect} />
    </div>
  );
}

export default Step2;
