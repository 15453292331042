import { DepartmentResponse } from 'models';
import { ItemModel } from 'models/systemUtility';
import { department } from 'services';

const PR0302Loader = async () => {
  const departmentItems: ItemModel[] = [];
  await GetDepartmentList(departmentItems);

  return {
    departmentDDL: departmentItems,
  };
};

const GetDepartmentList = async (itemForMap: ItemModel[]) => {
  const departmentsRes = await department.getDepartmentsAsync();
  const departmentData: DepartmentResponse[] = departmentsRes.data;
  departmentData.forEach((data) => itemForMap.push({ label: data.name, value: data.id ? data.id : '' }));
};

export { PR0302Loader };
