import {
  Card,
  DatePicker,
  Input,
  Selector,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step1({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลโครงการ" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="เลขที่เอกสาร PR" value="เลขที่เอกสาร PR" disabled />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} md={4}>
            <Selector label="โครงการ" disabled />
          </Col>
          <Col sm={12} md={3}>
            <Button variant="link"><TbListSearch className="fs-4" /> ไปยังแผน</Button>
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="งบประมาณโครงการ (บาท)" value="1,000,000.00" disabled textEnd rule={{ required: true }} />
          </Col>
          <Col sm={12} md={4}>
            <Input label="วิธีจัดหา" value="80" disabled rule={{ required: true }} />
          </Col>
          <Col sm={12} md={4} className="pt-4">
            <Input value="เจาะจง" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="ข้อมูลใบสั่งซื้อ/จ้าง/เช่า" className="fs-5 text-primary" />
        <Row className="mt-3">
          <Col sm={12} md={6}>
            <Input label="เลขที่เอกสาร" placeholder="เลขที่เอกสาร PR" rule={{ required: true }} />
          </Col>
          <Col sm={12} md={6}>
            <DatePicker label="วันที่เอกสาร" rule={{ required: true }} />
          </Col>
          <Col sm={12} md={6}>
            <Input label="อ้างอิงใบเสนอราคา" placeholder="เลขที่เอกสาร PR" rule={{ required: true }} />
          </Col>
          <Col sm={12} md={6}>
            <Input label="เลขที่สัญญา" placeholder="เลขที่สัญญา" rule={{ required: true }} />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="ข้อมูลผู้ขาย/รับจ้าง" className="fs-5 text-primary" />
        <Row className="mt-3">
          <Col sm={12} md={6}>
            <Input label="ผู้ขาย/ผู้รับจ้าง" placeholder="ผู้ขาย/ผู้รับจ้าง" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Input label="ที่อยู่" placeholder="ที่อยู่" disabled />
          </Col>
          <Col sm={12} md={6}>
            <Input label="โทรศัพท์" placeholder="โทรศัพท์" disabled />
          </Col>
          <Col sm={12} md={6}>
            <Input label="โทรสาร" placeholder="โทรสาร" disabled />
          </Col>
          <Col sm={12} md={6}>
            <Input label="เลขที่ประจำตัวผู้เสียภาษี" placeholder="เลขที่ประจำตัวผู้เสียภาษี" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Input label="ชื่อบัญชี" placeholder="ชื่อบัญชี" disabled />
          </Col>
          <Col sm={12} md={6}>
            <Input label="ธนาคาร" placeholder="ธนาคาร" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          className="me-2 px-3"
          onClick={onClickBack}
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default Step1;
