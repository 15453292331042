import StepProgress from 'components/StepProgress';
import {
  createContext,
  Dispatch,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { HttpStatusCode } from 'utils';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import { MedianPriceResultModel } from 'models/PP/MedianPriceModel';
import MedianPriceService from 'services/PP/MedianPriceService';
import { MedianPriceStatus } from 'components';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type MedianPriceContext = {
  data: MedianPriceResultModel
  setData: Dispatch<React.SetStateAction<MedianPriceResultModel>>,
  readonly: boolean;
};

export const Context = createContext({} as MedianPriceContext);

export default function MedianPriceDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id } = useParams();
  const [data, setData] = useState<MedianPriceResultModel>({} as MedianPriceResultModel);
  const [defaultStep, setDefaultStep] = useState(1);

  useEffect(() => {
    if (id) {
      getMedianPriceByIdAsynce(id);
    }
  }, [id]);

  const getMedianPriceByIdAsynce = async (id: string) => {
    const response = await MedianPriceService.getMedianPriceByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
      setStepByStatus(response.data.status);
    }
  };

  const setStepByStatus = (status: string) => {
    switch (status) {
      case MedianPriceStatus.Draft:
        setDefaultStep(1);
        break;
      case MedianPriceStatus.WaitingForCommitteeApprove:
        setDefaultStep(2);
        break;
      case MedianPriceStatus.CommitteeRejected:
        setDefaultStep(1);
        break;
      case MedianPriceStatus.CommitteeApproved:
        setDefaultStep(3);
        break;
      case MedianPriceStatus.WaitingForApprove:
        setDefaultStep(3);
        break;
      case MedianPriceStatus.Approved:
        setDefaultStep(3);
        break;
      case MedianPriceStatus.WaitingForAssignResponsible:
        setDefaultStep(3);
        break;
      case MedianPriceStatus.DraftSectionApprover:
        setDefaultStep(3);
        break;
      case MedianPriceStatus.Rejected:
        setDefaultStep(1);
        break;
      case MedianPriceStatus.WaitingForJorPorDirector:
        setDefaultStep(3);
        break;
      default:
        break;
    }
  };

  const obj = [
    {
      title: <p>ข้อมูลกำหนดราคากลาง
      </p>,
      displayIndex: '1',
      child: <Step1 onClickNext={() => clickNext()}
        onClickBack={() => backToIndex()} />
    },
    {
      title: <p>บุคคล/คณะกรรมการเห็นชอบ</p>,
      displayIndex: '2',
      child: <Step2 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />
    },
    {
      title: <p>ลำดับเห็นชอบ/อนุมัติ</p>,
      displayIndex: '3',
      child: <Step3 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />
    },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/preprocurement-median-price');
  };

  const readonly = useMemo(() =>
    data.status === 'WaitingForCommitteeApprove'
    || data.status === 'CommitteeApproved'
    || data.status === 'WaitingForApprove'
    || data.status === 'Approved'
    || data.status === 'Approved',
    [data]);

  return (
    <div className="document">
      {/* {
        (data && data.isActive && data.isCancel) && (

          <h4 className="mt-2 mb-0 text-primary text-start">ขอยกเลิกกำหนดราคากลาง</h4>
        )
      }
      {
        (data && data.isActive && data.isChange) && (
          <h4 className="mt-2 mb-0 text-primary text-start">ขอแก้ไขกำหนดราคากลาง</h4>
        )
      }
      {
        (data && data.isActive && !data.isChange && !data.isCancel) && (

          <h4 className="mt-2 mb-0 text-primary text-start">กำหนดราคากลาง</h4>
        )
      } */}

      <h4 className="mt-2 mb-0 text-primary text-start">กำหนดราคากลาง (ราคาอ้างอิง)</h4>

      <Row>
        <Col xs={12}
          className="mb-2 mt-3">
          <Context.Provider value={{ data, setData, readonly }}>
            <StepProgress progressBarData={obj}
              ref={childRef}
              step={defaultStep} />
          </Context.Provider>
        </Col>
      </Row>
    </div>
  );
}
