import Title from 'components/Controls/Title';
import { useEffect, useState } from 'react';
import { HttpStatusCode } from 'utils';
import { documentTemplate, SU12Service } from 'services';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { DocumentTemplate } from 'models';
import Collabora from 'components/Document/Collabora';
import { FaFilePdf } from 'react-icons/fa';

export default function SU04Detail() {
  const [docTemps, setDocTemps] = useState<DocumentTemplate[]>([]);
  const [tabActive, setTabActive] = useState<number>(1);
  const [docTemp, setDocTemp] = useState<DocumentTemplate>({} as DocumentTemplate);

  useEffect(() => {
    getDataAsync();
  }, []);

  const openPdfAsync = async (documentTemplateCode: string) => {
    const res = await documentTemplate.getPdfId(documentTemplateCode);

    if (res.status === HttpStatusCode.OK) {
      const endpoint = process.env.REACT_APP_API_URL!;

      window.open(`${endpoint}/file/${res.data}/pdf`, '_blank');
    }
  };

  const getDataAsync = async () => {
    const res = await documentTemplate.getUserManualListAsync();

    if (res.status === HttpStatusCode.OK) {
      setDocTemps(res.data.results);

      if (!docTemp.id
        && res.data.results?.length > 0) {
        getDataTemplateAsync(res.data.results[0].id, 1);
      }
    }
  };

  const getDataTemplateAsync = async (id: string, tabActive: number) => {
    setTabActive(tabActive);
    const res = await SU12Service.getDataAsync(id!);

    if (res.status === HttpStatusCode.OK) {
      setDocTemp(res.data);
    }
  };

  return (
    <>
      <Title text='คู่มือการใช้งานระบบ' />
      <Row>
        <Col md={2} className='tab-border-active' style={{ paddingLeft: 50 }}>
          {
            docTemps?.map((item, index) => (
              <Tabs
                defaultActiveKey={tabActive}
                id="tab-01"
                className='mb-2'
                activeKey={tabActive}
                onClick={() => getDataTemplateAsync(item.id, index + 1)}
              >
                <Tab eventKey={index + 1}
                  title={<p className='text-start'>
                    <b>{item.name}</b></p>}>
                </Tab>
              </Tabs>
            ))
          }
        </Col>
        <Col className='text-end' md={10}>
          {docTemp.documentTemplateFileId ? (
            <>
              <Button
                onClick={() => openPdfAsync(docTemp.code)}>
                <FaFilePdf />
              </Button>
              <Row>
                <Col>
                  <Collabora
                    docId={docTemp.documentTemplateFileId}
                    docName='doc-01'
                    height='800'
                    showRemark={false}
                    readonly={true} />
                </Col>
              </Row>
            </>
          ) : null}
        </Col>
      </Row>
    </>
  );
}