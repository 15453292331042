import { HttpStatusCode } from 'axios';
import {
  Check,
  Currency,
  DatePicker,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  ItemModel,
  Pagination,
} from 'models';
import {
  PL0204Criteria,
  PL0204TableList,
} from 'models/PL';
import {
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { PL0204Service } from 'services/PL';
import {
  GetMonthYearBC,
  thaiFormatDateWithSlash,
} from 'utils';
import { THCurrency } from 'utils/convertors/currency';
import toast from 'utils/toast';
import { generateUniqueId } from '../../../utils/helper';

type Loader = { departmentDDL: ItemModel[], dataTable: any };

const PlanStatusNameArray = [
  { label: 'ทั้งหมด', value: 'All' },
  { label: 'จัดทำแผน', value: 'DraftPlan' },
  { label: 'รออนุมัติแผน', value: 'WaitingApprovePlan' },
  { label: 'อนุมัติแผน', value: 'ApprovePlan' },
  { label: 'ส่งกลับแก้ไข', value: 'RejectPlan' },
  { label: 'มอบหมายงาน', value: 'Assigned' },
  { label: 'จัดทำเอกสารบันทึก', value: 'DraftRecordDocument' },
  { label: 'รอให้ความเห็นชอบ', value: 'WaitingAcceptor' },
  { label: 'เห็นชอบและอนุมัติสำเร็จ', value: 'ApproveAcceptor' },
  { label: 'ไม่เห็นชอบ', value: 'RejectAcceptor' },
  { label: 'เผยแพร่ประกาศแผน', value: 'Announcement' },
  { label: 'ส่งกลับแก้ไข-จากการเผยแพร่', value: 'RejectAnnouncement' },
];

function PL0204Header() {
  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>ขออนุมัติยกเลิกแผนการจัดซื้อจัดจ้าง</h4>
      </div>
      <hr />
    </>
  );
}

export default function PL0204() {
  const navigate = useNavigate();
  const { departmentDDL, dataTable } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<PL0204Criteria>({
    planStatus: [...PlanStatusNameArray.map((s) => s.value)],
  } as PL0204Criteria);
  const [data, setData] = useState<PL0204TableList[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, size: 10 } as Pagination);

  useEffect(() => {
    setData(dataTable.data);
    setPagination({ ...pagination, totalRecords: data.length });
  }, []);

  const statusOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const planStatus = [...PlanStatusNameArray.map((s) => s.value)];
        setCriteria({ ...criteria, planStatus });
        return;
      }
      setCriteria({ ...criteria, planStatus: [] });
      return;
    }
    if (result) {
      if ((criteria.planStatus.length + 1) === (PlanStatusNameArray.length - 1)) {
        setCriteria({
          ...criteria,
          planStatus: [
            ...criteria.planStatus,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        planStatus: [
          ...criteria.planStatus,
          value,
        ],
      });
    } else {
      const planStatus = [...criteria.planStatus];
      const index = planStatus.findIndex((ps) => ps === value);

      if (index >= 0) {
        planStatus.splice(index, 1);
        const allIndex = planStatus.findIndex((ps) => ps === 'ALL');

        if (allIndex >= 0) {
          planStatus.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, planStatus });
      }
    }
  };

  const onSearchAsync = () => {
    (async () => {
      const { data, status } = await PL0204Service.getCancelRequestPlanListAsync(pagination.page, pagination.size, criteria);
      if (status !== HttpStatusCode.Ok) {
        toast.error('เกิดข้อผิดพลาด');
        return;
      }
      setData(data.data);
      setPagination({ ...pagination, totalRecords: data.length });
    })();
  };

  return (
    <>
      <PL0204Header />
      <Row className='criteria'>
        <Col sm={12}
          md={6}
          lg={4}
          xl={3}>
          <Input
            value={criteria.planNumber}
            onChange={(val) => setCriteria({ ...criteria, planNumber: val })}
            label='เลขที่แผน'
          />
        </Col>
        <Col sm={12}
          md={6}
          lg={4}
          xl={3}>
          <Selector
            label='ฝ่าย/สำนัก'
            items={departmentDDL}
            value={criteria.departmentId}
            onChange={(val) => setCriteria({ ...criteria, departmentId: val })}
          />
        </Col>
        <Col sm={12}
          md={6}
          lg={4}
          xl={3}>
          <Input
            label='ชื่อแผน'
            value={criteria.planName}
            onChange={(val) => setCriteria({ ...criteria, planName: val })}
          />
        </Col>
        <Col sm={12}
          md={6}
          lg={4}
          xl={3}>
          <Currency
            label='งบประมาณโครงการ'
            value={criteria.planBudget}
            onChange={(val) => setCriteria({ ...criteria, planBudget: Number(val) })}
          />
        </Col>
        <Col sm={12}
          md={6}
          lg={4}
          xl={3}>
          <DatePicker
            label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
            value={criteria.expectingProcurementAt}
            onChange={(val) => setCriteria({ ...criteria, expectingProcurementAt: val })}
            monthYearOnly
          />
        </Col>
        <Col sm={12}
          md={6}
          lg={4}
          xl={3}>
          <DatePicker
            label='วันที่ประกาศแผน'
            value={criteria.planAnnouncementDate}
            onChange={(val) => setCriteria({ ...criteria, planAnnouncementDate: val })}
          />
        </Col>
        <Col sm={12}
          md={6}
          lg={4}
          xl={3}>
          <Input
            label='ผู้จัดทำ'
            value={criteria.CreateBy}
            onChange={(val) => setCriteria({ ...criteria, CreateBy: val })}
          />
        </Col>
      </Row>
      <Row className='status'>
        <Col sm={12}>
          <p>สถานะ</p>
        </Col>
        {PlanStatusNameArray.map((data, index) => (
          <Col sm={12}
            md={6}
            lg={3}
            xl={2}
            key={generateUniqueId(index)}>
            <Check
              value={criteria.planStatus.some((s) => s === data.value)}
              onChange={(val) => statusOnChange(data.value, val)}
              label={<>{data.label} <Badge bg='secondary'
                className='mx-1'> 10 </Badge></>}
            />
          </Col>
        ))}
      </Row>
      <div className='d-flex gap-2 mt-3'>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={onSearchAsync}
        >
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant='outline-primary'
          className='d-flex align-items-center gap-2'
          onClick={() => {
          }}
        >
          <FaEraser />ล้าง
        </Button>
      </div>
      <Table
        className='mt-4'
        total={pagination.totalRecords}
        onChange={(size, page) => (setPagination({ ...pagination, size, page }))}
      >
        <thead>
        <tr>
          <th style={{ minWidth: 100 }}>เลขที่แผน</th>
          <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
          <th style={{ minWidth: 100 }}>ชื่อแผน</th>
          <th style={{ minWidth: 100 }}>งบประมาณ (บาท)</th>
          <th style={{ minWidth: 100 }}>วิธีจัดหา</th>
          <th style={{ minWidth: 100 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
          <th style={{ minWidth: 100 }}>วันที่ประกาศแผน</th>
          <th style={{ minWidth: 100 }}>ผู้จัดทำ</th>
          <th style={{ minWidth: 100 }}>วันที่จัดทำ</th>
          <th style={{ minWidth: 100 }}>สถานะโครงการ</th>
          <th style={{ minWidth: 100 }}>สถานะ</th>
        </tr>
        </thead>
        <tbody>
        {data.map((value, index) => (
          <tr key={generateUniqueId(value.id)}>
            <td className='text-center'>
              <Button
                variant='link'
                onClick={() => navigate(`detail/${value.id}`)}
              >{value.planNumber}
              </Button>
            </td>
            <td className='text-start'>
              {value.department}
            </td>
            <td className='text-start'>
              {value.planName}
            </td>
            <td className='text-end'>
              {THCurrency(value.planBudget)}
            </td>
            <td className='text-center'>
              {value.supplyMethod}
            </td>
            <td className='text-center'>
              {GetMonthYearBC(value.expectingProcurementAt)}
            </td>
            <td className='text-center'>
              {thaiFormatDateWithSlash(value.planAnnouncementDate)}
            </td>
            <td>
              {value.createBy}
            </td>
            <td className='text-center'>
              {thaiFormatDateWithSlash(value.createDate)}
            </td>
            <td className='text-center'>
              <Status type={StatusType.PROJECT}
                value={value.planStatus} />
            </td>
            <td className='text-center'>
              <Status type={StatusType.PROCESS}
                value={value.status} />
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </>
  );
}
