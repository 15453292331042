import {
  PieChart as PieChartCom,
  Status,
  StatusType,
  Table,
} from 'components';
import { CardSummary } from 'components/Card/CardSummary';
import {
  Chart,
  Pagination,
  SupplyMethodType,
} from 'models';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { dashboard } from 'services';
import {
  GetMonthYearBC,
  HttpStatusCode,
  thaiFormatDate,
} from 'utils';

type DashboardSupplyMethodDetailContext = {
  chart: Chart;
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  supplyMethodTypeList: SupplyMethodType[],
}

const Context = createContext({} as DashboardSupplyMethodDetailContext);

export default function DashboardSupplyMethodDetail() {
  const [chart, setChart] = useState<Chart>({} as Chart);
  const [supplyMethodTypeList, setSupplyMethodTypeList] = useState<SupplyMethodType[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getChartAsync();
    getSupplyMethodTypeListAsync();
  }, []);

  const getChartAsync = async () => {
    const res = await dashboard.getChartAsync();

    if (res.status === HttpStatusCode.OK) {
      setChart(res.data);
    }
  };

  const getSupplyMethodTypeListAsync = async () => {
    const res = await dashboard.getSupplyMethodTypeListAsync(
      pagination.page,
      pagination.size,
    );

    if (res.status === HttpStatusCode.OK) {
      setSupplyMethodTypeList(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const valueContext = {
    chart,
    pagination,
    setPagination,
    supplyMethodTypeList,
  };

  return (
    <Context.Provider value={valueContext}>
      <Header />
      <Summary />
      <PieChart />
      <DataTable />
    </Context.Provider>
  );
}

function Header() {
  const dateNow = new Date();

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>ข้อมูลโครงการแยกตามวิธี</h4>
        <p className='m-0'>ข้อมูล ณ วันที่ {thaiFormatDate(dateNow)}</p>
      </div>
      <hr />
    </>
  );
}

function Summary() {
  const { chart } = useContext(Context);

  return (
    <Row>
      <Col sm={12}
        md={6}
        lg={4}
        className='mb-3'>
        <CardSummary title='ซื้อ'
          total={chart.pieChart?.countBuy} />
      </Col>
      <Col sm={12}
        md={6}
        lg={4}
        className='mb-3'>
        <CardSummary title='จ้าง'
          total={chart.pieChart?.countHire} />
      </Col>
      <Col sm={12}
        md={6}
        lg={4}
        className='mb-3'>
        <CardSummary title='เช่า'
          total={chart.pieChart?.countRent} />
      </Col>
    </Row>
  );
}

function PieChart() {
  const { chart } = useContext(Context);

  const pieChartData = useMemo(() => {
    return [
      { label: 'ซื้อ', color: '#FF8585', value: chart?.pieChart?.countBuy },
      { label: 'จ้าง', color: '#80CBC7', value: chart?.pieChart?.countHire },
      { label: 'เช่า', color: '#ECC6D1', value: chart?.pieChart?.countRent },
    ];
  }, [chart.pieChart]);

  return (
    <Row className='justify-content-center'>
      <Col md={6}
        lg={5}
        xl={4}
        xxl={3}>
        <PieChartCom
          data={pieChartData} />
      </Col>
    </Row>
  );
}

function DataTable() {
  const navigate = useNavigate();
  const { supplyMethodTypeList, pagination } = useContext(Context);

  return (
    <Table
      total={pagination.totalRecords}
      className='mt-3'>
      <thead>
      <tr className='text-center'>
        <th style={{ minWidth: 10 }}>เลขที่รายการ</th>
        <th style={{ width: 250 }}>สถานะ</th>
        <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
        <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
        <th style={{ minWidth: 150 }}>ปีงบประมาณ</th>
        <th style={{ minWidth: 150 }}>วงเงิน</th>
        <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
        <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />
          การจัดซื้อจัดจ้าง
        </th>
      </tr>
      </thead>
      <tbody>
      {supplyMethodTypeList?.map((s, i) => (
        <tr key={s.id}>
          <td>
            <Button
              variant='link'
              onClick={() => navigate(`/preprocurement-tor/detail/${s.id}`)}>
              {s.torNumber}
            </Button>
          </td>
          <td>
            <Status value={s.status}
              type={StatusType.TOR} />
          </td>
          <td>
            {s.departmentName}
          </td>
          <td>
            {s.planName}
          </td>
          <td className='text-center'>
            {s.budgetYear}
          </td>
          <td className='text-end'>
            {s.planBudget}
          </td>
          <td className='text-center'>
            {s.supplyMethod}
          </td>
          <td className='text-center'>
            {GetMonthYearBC(s.expectingProcurementAt)}
          </td>
        </tr>
      ))
      }
      </tbody>
    </Table>
  );
}
