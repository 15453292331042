import StepProgress from 'components/StepProgress';
import { useRef } from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

function AddP0201() {
  const childRef = useRef<ProgressBarRefType>(null);
  const obj = [{
    title: <p>ข้อมูลการแจ้งข้อมูล
      เบื้องต้น ( จพ.004  )
    </p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} />,
  },
  {
    title: <p>คณะกรรมการ</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>คณะกรรมการตรวจรับพัสดุ</p>,
    displayIndex: '3',
    child: <Step3 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>ผู้ควบคุมงาน
      (เฉพาะงานก่อสร้าง)
    </p>,
    displayIndex: '4',
    child: <Step4 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>อำนาจอนุมัตและหน้าที่</p>,
    displayIndex: '5',
    child: <Step5 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>ตัวอย่างเอกสาร จพ.005</p>,
    displayIndex: '6',
    child: <Step6 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  return (
    <div className="document">
      <p className="text-center fs-4">รายงานขอซื้อขอจ้าง (จพ.005)</p>
      <Row>
        <Col xs={12} className="mb-2">
          <StepProgress progressBarData={obj} ref={childRef} />
        </Col>
      </Row>
    </div>
  );
}

export default AddP0201;
