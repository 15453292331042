import {
  ButtonCustom,
  CMDetailStatus,
  Card,
  InvitedContractStatus,
  Modal,
  SearchBuNameModal,
  Status,
  StatusCM,
  StatusType,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { UserApproveModal } from 'components/Modal/UserApproveModal';
import { StatusAcceptor } from 'components/StatusAcceptors';
import { StatusInvitation } from 'components/StatusCMinvitation';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import {
  AcceptorsRequestModel,
  AttachmentsModel,
  ContractAgreementInvitationAcceptorListModel,
  ContractAgreementInvitationAcceptorModel,
  ContractAgreementInvitationAcceptorSignedListModel,
  InvitedContractResponseModel,
  contractAgreementModel,
} from 'models/CM/CM01Models';
import { sectionApproverModel } from 'models/delegateModel';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  FaPlus,
  FaRedo,
  FaRegCheckCircle,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdSave,
} from 'react-icons/md';
import { useNavigate, useParams } from 'react-router';
import { account, documentTemplate, shareValue } from 'services';
import CM01Service from 'services/CM/CM01Service';
import {
  fullDatetime,
  fullDatetimeTH,
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { Context } from '.';
import Collabora, { CollaboraRef } from '../../../../components/Document/Collabora';
import { ApproveModal } from '../Modal/ApproveModal';
import { HistoryModal } from '../Modal/HistoryModal';
import { RejectModal } from '../Modal/RejectModal';
import UploadFile from '../UploadFile';
import { JorPorCode } from 'constant/basicInformation';
import { onCheckQueueSectionApprover } from 'utils/checkQueueSectionApprover';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

function DocumentTemplate() {
  const [docId, setDocId] = useState('');
  const [data, setData] = useState<InvitedContractResponseModel>({} as InvitedContractResponseModel);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const collaboraRef = useRef<CollaboraRef>(null);
  const { id, taxId, contractAgreementsId } = useParams();

  const getDocument = useCallback(async (contractAgreement: string) => {
    const res = await CM01Service.getDocumentByIdAsync(contractAgreement, false);
    if (res.status === HttpStatusCode.OK) {
      setDocId(res.data);
    }
  }, [setDocId]);

  useEffect(() => {
    if (contractAgreementsId) {
      getDocument(contractAgreementsId);
    }

    if (id && taxId) {
      getInvitedContractAsync(id, taxId);
    }

  }, [id, taxId, contractAgreementsId]);

  useEffect(() => {
    if (data) {
      if (data.status === InvitedContractStatus.DRAFT || data.status === InvitedContractStatus.REJECTED) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [data]);


  const getInvitedContractAsync = async (id: string, taxId: string) => {
    const response = await CM01Service.getInvitedContractByIdAsync(id, taxId);
    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
    }
  };

  const onSubmitAsync = async () => {
    submitForm();
    collaboraRef.current?.clickSave();

    if (contractAgreementsId && docId) {
      const res = await CM01Service.updateDocumentAsync(contractAgreementsId, docId);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {

        toast.success('บันทึกข้อมูลสำเร็จ');
        await getInvitedContractAsync(data.id, data.taxId);

      } else {
        toast.error(res.statusText);
      }
    }
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title
          text='เอกสารเชิญชวนทำสัญญา'
          className='fs-5'
        />
        <Row className='justify-content-center mt-3'>
          <div className='mt-3'>
            <Col className='tab-border-active'>
              <Collabora
                docId={docId}
                docName='doc-01'
                ref={collaboraRef}
                readonly={false} />
            </Col>
          </div>
        </Row>
      </Card>
      <div className='d-flex justify-content-center pt-3'>
        {
          ((data.status === InvitedContractStatus.DRAFT || data.status === InvitedContractStatus.REJECTED) && contractAgreementsId) && (
            <ButtonCustom
              onClick={onSubmitAsync}
              text="บันทึก"
            />
          )
        }
      </div>
    </div >
  );
}

export default DocumentTemplate
