import { Table } from 'components';
import Title from 'components/Controls/Title';
import dayjs from 'dayjs';
import { AnnualPlanApproverModel } from 'models/PL';
import { PlanAnnouncementListResponseModel } from 'models/planAnnouncement';
import {
  Badge,
  Button,
  Card,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  PlanStatusText,
  StatusName,
} from 'utils/constants/planStatus';
import { DateFormat } from '../../../../constant/dateFormat';

interface Props {
  onClickNext: () => void;
  data: PlanAnnouncementListResponseModel;
}

const formatDate = new DateFormat();

function PL0302Data({ onClickNext, data }: Props) {
  const navigate = useNavigate();
  const planStatusType = data.plans[0].planStatus;
  const { plans } = data;
  const { totalCount } = data;
  const approvers = planStatusType === PlanStatusText.ChangePlan
    ? data.plans[0].changePlanDto?.approvers
    : data.plans[0].approvers;

  return (
    <>
      <Card className='mt-3'>
        <Card.Body>
          <Title text='สถานะการอนุมัติ'
            className='fs-5 text-primary' />
          <ApproveStateDatatable approvers={approvers} />
        </Card.Body>
      </Card>
      <Card className='mt-3'>
        <Card.Body>
          {
            (planStatusType === PlanStatusText.AnnualPlan || planStatusType === PlanStatusText.InYearPlan)
            && <PlanDatatable plans={plans}
              totalCount={totalCount} />
          }
          {
            planStatusType === PlanStatusText.CancelPlan
            && <PlanCancelDatatable plans={plans}
              totalCount={totalCount} />
          }
          {
            planStatusType === PlanStatusText.ChangePlan
            && <PlanChangeDatatable plans={plans}
              totalCount={totalCount} />
          }

          <div className='d-flex justify-content-between pt-5 gap-3'>
            <Button onClick={() => navigate('/pl/pl0302')}
              variant='light'><MdArrowBack />ย้อนกลับ</Button>
            <Button onClick={onClickNext}
              variant='primary'>ถัดไป <MdArrowForward /></Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

interface ApproverProps {
  approvers?: AnnualPlanApproverModel[];
}

function ApproveStateDatatable({ approvers }: ApproverProps) {
  const mapStatus = (status: string) => {
    switch (status) {
      case StatusName.DRAFT:
        return <Badge bg='dark'
          text='white'>ร่างแผน</Badge>;
      case StatusName.PENDING:
        return <Badge text='white'>รอนุมัติ</Badge>;
      case StatusName.APPROVED:
        return <Badge bg='success'
          text='white'>อนุมัติแล้ว</Badge>;
      case StatusName.REJECTED:
        return <Badge bg='danger'
          text='white'>แก้ไข</Badge>;
    }
  };

  return (
    <Table className='mt-4'
      hidePagination>
      <thead>
      <tr>
        <th style={{ minWidth: 50 }}>ลำดับ</th>
        <th style={{ minWidth: 100 }}>รหัสพนักงาน</th>
        <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
        <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
        <th style={{ minWidth: 100 }}>สถานะ</th>
      </tr>
      </thead>
      <tbody>
      {
        approvers
        && approvers.map((rowData, index) => (
          <tr key={rowData.id}>
            <td className='text-center'>
              {index + 1}
            </td>
            <td className='text-center'>
              {rowData.code}
            </td>
            <td>
              {rowData.name}
            </td>
            <td className='text-center'>
              {rowData.position}
            </td>
            <td className='text-center'>
              {mapStatus(rowData.approveStatus)}
            </td>
          </tr>
        ))
      }
      </tbody>
    </Table>
  );
}

function PlanDatatable({ plans, totalCount }: PlanAnnouncementListResponseModel) {
  return (
    <>
      <Title text='รายละเอียดแผนการจัดซื้อจัดจ้าง'
        className='fs-5 text-primary' />
      <Table className='mt-4'
        hidePagination
        total={totalCount}>
        <thead>
        <tr>
          <th>ลำดับ</th>
          <th>เลขที่แผน</th>
          <th>ฝ่าย/สำนัก</th>
          <th>ชื่อโครงการ</th>
          <th>งบประมาณโครงการ</th>
          <th>วิธีจัดหา</th>
          <th>ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง</th>
        </tr>
        </thead>
        <tbody>
        {
          plans.map((rowData, index) => (
            <tr key={rowData.id}>
              <td>
                {index + 1}
              </td>
              <td className='text-center'>
                {rowData.planNumber}
              </td>
              <td>
                {rowData.departmentName}
              </td>
              <td>
                {rowData.planName}
              </td>
              <td className='text-end'>
                {rowData.planBudget.toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </td>
              <td className='text-center'>
                {rowData.supplyMethodValue} : {rowData.supplyMethodNameValue}
              </td>
              <td className='text-center'>
                {dayjs(rowData.expectingProcurementAt)
                  .format(formatDate.monthYear)}
              </td>
            </tr>
          ))
        }
        </tbody>
      </Table>
    </>
  );
}

function PlanCancelDatatable({ plans, totalCount }: PlanAnnouncementListResponseModel) {
  return (
    <>
      <Title text='รายละเอียดขอยกเลิกแผนการจัดซื้อจัดจ้าง'
        className='fs-5 text-primary' />
      <Table className='mt-4'
        hidePagination
        total={totalCount}>
        <thead>
        <tr>
          <th>ลำดับ</th>
          <th>ชื่อโครงการ</th>
          <th>งบประมาณโครงการ (บาท)</th>
          <th>สาเหตุการขอยกเลิก</th>
        </tr>
        </thead>
        <tbody>
        {
          plans.map((rowData, index) => (
            <tr key={rowData.id}>
              <td>
                {index + 1}
              </td>
              <td>
                {rowData.planName}
              </td>
              <td className='text-end'>
                {rowData.planBudget.toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </td>
              <td className='text-center'>
                {rowData.cancelDescription}
              </td>
            </tr>
          ))
        }
        </tbody>
      </Table>
    </>
  );
}

function PlanChangeDatatable({ plans, totalCount }: PlanAnnouncementListResponseModel) {
  const styleHeaderLine = '0.5px solid gray';
  const styleTrOld = {
    borderLeft: styleHeaderLine,
    borderBottom: styleHeaderLine,
    borderRight: styleHeaderLine,
  };
  const styleTrNew = {
    borderLeft: styleHeaderLine,
    borderBottom: styleHeaderLine,
  };
  const styleTrProjectName = { borderLeft: styleHeaderLine };

  return (
    <>
      <Title text='รายละเอียดขอเปลี่ยนแปลงแผนการจัดซื้อจัดจ้าง'
        className='fs-5 text-primary' />
      <Table className='mt-4'
        hidePagination
        total={totalCount}>
        <thead>
        <tr>
          <th rowSpan={2}>ลำดับ</th>
          <th style={styleTrOld}
            colSpan={3}>เดิม
          </th>
          <th style={styleTrNew}
            colSpan={3}>ใหม่
          </th>
        </tr>
        <tr>
          <th style={styleTrProjectName}>ชื่อโครงการ</th>
          <th>งบประมาณโครงการ (บาท)</th>
          <th>คาดว่าจะประกาศจัดซื้อจัดจ้าง</th>
          <th style={styleTrProjectName}>ชื่อโครงการ</th>
          <th>งบประมาณโครงการ (บาท)</th>
          <th>คาดว่าจะประกาศจัดซื้อจัดจ้าง</th>
        </tr>
        </thead>
        <tbody>
        {
          plans.map((rowData, index) => (
            <tr key={rowData.id}>
              <td className='text-center'>
                {index + 1}
              </td>
              <td>
                <Button
                  variant='link'
                >
                  {rowData.planName}
                </Button>
              </td>
              <td className='text-end'>
                {rowData.planBudget.toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </td>
              <td className='text-center'>
                {dayjs(rowData.expectingProcurementAt)
                  .format(formatDate.monthYear)}
              </td>
              <td>
                <Button
                  variant='link'
                >
                  {rowData.changePlanDto?.planName}
                </Button>
              </td>
              <td className='text-end'>
                {rowData.changePlanDto?.planBudget.toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </td>
              <td className='text-center'>
                {dayjs(rowData.changePlanDto?.expectingProcurementAt)
                  .format(formatDate.monthYear)}
              </td>
            </tr>
          ))
        }

        </tbody>
      </Table>
    </>
  );
}

export default PL0302Data;
