import { FileValue } from 'components';
import { BasicInformationAction } from 'models/PP';
import {
  CoiAndWatchListAndEgp,
  ConsiderationUpdate,
  JorPor06Count,
  JorPor06Criteria,
  ParcelsUpdate,
  UpdateJorPor06,
} from 'models/PR/JorPor06Models';
import qs from 'qs';
import http from 'utils/axios';

const getJorpor06ListsAsync = async (page: number, size: number, criteriaData?: JorPor06Criteria) => {
  const params = {
    page,
    size,
    ...criteriaData,
  };

  return await http.get('/jorpor06', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getJorpor06DetailAsync = async (id: string) => {
  return await http.get(`/jorpor06/${id}`);
};

const createJorpor06Async = async (inviteId: string) => {
  return await http.post(`/jorpor06/${inviteId}`);
};

const updateCOIAndWatchAndEgpAsync = async (inviteEntrepreneursId: string, data: CoiAndWatchListAndEgp) => {
  return await http.put(`jorpor06/coiwatchlistegp/${inviteEntrepreneursId}`, data);
};

const updateParcelsAsync = async (inviteEntrepreneursId: string, data: ParcelsUpdate) => {
  return await http.put(`jorpor06/parcels/${inviteEntrepreneursId}`, data);
};

const updateConsiderationAsync = async (inviteEntrepreneursId: string, data: ConsiderationUpdate) => {
  return await http.put(`jorpor06/considerations/${inviteEntrepreneursId}`, data);
};

const updateJorPor06Async = async (jorpor06Id: string, data: UpdateJorPor06) => {
  return await http.put(`jorpor06/${jorpor06Id}`, data);
};

const uploadJorPor06DocumentAsync = async (id: string, data: FileValue) => {
  const body = {
    file: data,
    fileName: data.name,
  };

  return await http.put(`/jorpor06/${id}/uploadfile`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
};

const downloadJorPor06FileAsync = async (id: string, documentId: string) => await http.get(`/jorpor06/${id}/documents/${documentId}`, { responseType: 'blob' });

const removeJorPor06FileAsync = async (id: string, documentId: string) => await http.delete(`/jorpor06/${id}/deletefile/${documentId}`);

const sendApproveAndRejectAsync = async (id: string, data: BasicInformationAction) => {
  const body = {
    ...data,
  };

  return await http.put(`/jorpor06/actions/${id}`, body);
};

const getCountJorPor06ListAsync = async (data: JorPor06Count) => {
  const params = {
    ...data
  };

  return await http.get('/jorpor06/countbadgejorpor06', { params });
};

const getJorPor006DocumentAsync = (id: string, docReadonly: boolean) => {
  return http.get(`/jorpor06/${id}/document-jorpor06/${docReadonly}`);
};

const getWinnerDocumentAsync = (id: string, docReadonly: boolean) => {
  return http.get(`/jorpor06/${id}/document-winner/${docReadonly}`);
};

const getCancelJorPor006DocumentAsync = (id: string) => {
  return http.get(`/jorpor06/${id}/document-cancel-jorpor06`);
};

const getEntrepreneursWinnerAsync = (jorpor06id: string) => {
  return http.get(`/jorpor06/${jorpor06id}/entrepreneurs-winner`);
};

const sendApproveCommitteeAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };

  return await http.put(`/jorpor06/${id}/committee/approve`, body);
};

const sendRejectCommitteeAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };

  return await http.put(`/jorpor06/${id}/committee/reject`, body);
};

const jorporDirectorRejectAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };

  return await http.put(`/jorpor06/${id}/jorpor-director/reject`, body);
};

const cancelJorPor06Async = (jorpor06RefId: string, remark: string) => {
  const body = {
    remark,
  };
  return http.post(`/jorpor06/${jorpor06RefId}/cancel`, body);
};

const deleteJorPor06Async = (id: string) => http.delete(`/jorpor06/${id}`);

export default {
  deleteJorPor06Async,
  cancelJorPor06Async,
  getJorpor06ListsAsync,
  getJorpor06DetailAsync,
  createJorpor06Async,
  updateCOIAndWatchAndEgpAsync,
  updateParcelsAsync,
  updateConsiderationAsync,
  updateJorPor06Async,
  uploadJorPor06DocumentAsync,
  downloadJorPor06FileAsync,
  removeJorPor06FileAsync,
  sendApproveAndRejectAsync,
  getCountJorPor06ListAsync,
  getJorPor006DocumentAsync,
  getWinnerDocumentAsync,
  getEntrepreneursWinnerAsync,
  sendApproveCommitteeAsync,
  sendRejectCommitteeAsync,
  getCancelJorPor006DocumentAsync,
  jorporDirectorRejectAsync,
};
