import { StatusModel } from "pages/PP/PP0205";
import { useEffect, useState } from "react";
import { Check } from "./Controls";
import { Form } from "react-bootstrap";
import { generateUniqueId } from "utils/helper";

interface FormInput {
  onChange: (value: string[]) => void;
  supplyMethod: string[];
}

function CheckBoxSupplyMethod(props: FormInput) {
  const [supplyMethodArray, setSupplyMethodArray] = useState<string[]>(props.supplyMethod);

  const [supplyMethodValue] = useState<StatusModel[]>([
    { label: 'ทั้งหมด', value: 'All', count: 0 },
    { label: 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560', value: '342e4e0b-7682-48c0-b46a-97a066908c9a', count: 0 },
    { label: 'ข้อบังคับธนาคาร 80', value: 'a21603e4-338d-486c-85ba-5f1cac8f6ace', count: 0 },
  ]);

  useEffect(() => {
    if (supplyMethodArray) {
      props.onChange(supplyMethodArray);
    }
  }, [supplyMethodArray]);

  useEffect(() => {
    if (props.supplyMethod) {
      setSupplyMethodArray(props.supplyMethod);
    }
  }, [props.supplyMethod]);

  const supplyMethodOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const supplyMethod = [...supplyMethodValue.map((s) => s.value)];
        setSupplyMethodArray(supplyMethod);
        return;
      }
      setSupplyMethodArray([]);
      return;
    }
    if (result) {
      if ((supplyMethodArray.length + 1) === (supplyMethodValue.length - 1)) {
        setSupplyMethodArray([
          ...supplyMethodArray,
          value,
          'All',
        ]);
        return;
      }
      setSupplyMethodArray([value]);
    } else {
      const supplyMethod = [...supplyMethodArray];
      const index = supplyMethod.findIndex((ps) => ps === value);

      if (index >= 0) {
        supplyMethod.splice(index, 1);
        const allIndex = supplyMethod.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          supplyMethod.splice(allIndex, 1);
        }
        setSupplyMethodArray(supplyMethod);
      }
    }
  };

  return (
    <>
      <Form.Label>วิธีจัดหา</Form.Label>
      <div className="d-flex gap-2">
        {supplyMethodValue?.map((v, i) => (
          <div key={generateUniqueId(i)}>
            <Check
              value={supplyMethodArray?.some((i) => i === v.value)}
              onChange={(val) => supplyMethodOnChange(v.value, val)}
              label={(
                <>
                  {v.label}
                </>
              )}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default CheckBoxSupplyMethod;