import {
  Check,
  ContractStatus,
  Status,
  StatusType,
  SupplyMethodEnum,
  Table,
} from 'components';
import {
  DatePicker,
  Input,
  Selector,
} from 'components/Controls';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import {
  ContractManagementListModel,
  CountStatusModel,
  CountSupplyMethodsModel,
  SearchContractManagementListModel,
} from 'models/CM/CM03Models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { FaEraser, FaSearch } from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import CM03Service from 'services/CM/CM03Service';
import {
  fullDate,
  HttpStatusCode,
  THCurrency,
} from 'utils';

type Loader = { departmentDDL: ItemModel[], contractTypeDDL: ItemModel[] };

interface StatusItemModel {
  label: string;
  value: boolean;
}

const defaultStatus = [ContractStatus.ContractAgreement, ContractStatus.ContractCancelled, ContractStatus.ContractComplete, ContractStatus.ContractManagement, ContractStatus.ContractWarranty, ContractStatus.Assigned, ContractStatus.None];

const defaultMethod = [SupplyMethodEnum.MethodId60, SupplyMethodEnum.MethodId80];

export default function CM03() {
  const { departmentDDL, contractTypeDDL } = useLoaderData() as Loader;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const [criteria, setCriteria] = useState<SearchContractManagementListModel>({
    poNumber: '',
    contractNumber: '',
    planNumber: '',
    planName: '',
    departmentId: '',
    entrepreneursName: '',
    parcelDetail: '',
    supplyMethodIds: [],
    contractStatus: [],
    page: 1,
    pageSize: 10,
    contractName: '',
    isResponsible: true,
    contractRecordType: '',
  });
  const [dataList, setDataList] = useState<ContractManagementListModel[]>([]);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    {
      value: false,
      label: ContractStatus.ContractAgreement,
    },
    {
      value: false,
      label: ContractStatus.ContractCancelled,
    },
    {
      value: false,
      label: ContractStatus.ContractComplete,
    },
    {
      value: false,
      label: ContractStatus.ContractManagement,
    },
    {
      value: false,
      label: ContractStatus.ContractWarranty,
    },
    {
      value: false,
      label: ContractStatus.Assigned,
    },
    {
      value: false,
      label: ContractStatus.None,
    },
  ]);
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [statusContractAgreement, setStatusContractAgreement] = useState<boolean>(false);
  const [statusContractCancelled, setStatusContractCancelled] = useState<boolean>(false);
  const [statusContractComplete, setStatusContractComplete] = useState<boolean>(false);
  const [statusContractManagement, setStatusContractManagement] = useState<boolean>(false);
  const [statusContractWarranty, setStatusContractWarranty] = useState<boolean>(false);
  const [statusNone, setStatusNone] = useState<boolean>(false);
  const [departmentId, setDepartmentId] = useState('');
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodsModel[]>([]);
  const [methodAll, setMethodAll] = useState<boolean>(false);
  const [method60, setMethod60] = useState<boolean>(false);
  const [method80, setMethod80] = useState<boolean>(false);
  const [methodLists, setMethodLists] = useState<StatusItemModel[]>([
    {
      value: false,
      label: SupplyMethodEnum.MethodId60,
    },
    {
      value: false,
      label: SupplyMethodEnum.MethodId80,
    },
  ]);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);

  const goToPage = (id?: string, contractRecordId?: string, financialId?: string, entrepreneursId?: string) => {

    if (id) {

      navigate(`/contract-management/detail/${id}`);
    } else if (contractRecordId && financialId && entrepreneursId) {

      navigate(`/contract-management/detail/contractRecord/${contractRecordId}/financial/${financialId}/entrepreneurs/${entrepreneursId}`);
    }
  };

  useEffect(() => {
    setStatusContractAgreement(true);
    setStatusContractCancelled(true);
    setStatusContractComplete(true);
    setStatusContractManagement(true);
    setStatusContractWarranty(true);
    setStatusAll(true);
    setStatusNone(true);
    setMethodAll(true);
    setMethod60(true);
    setMethod80(true);
    getListDataAsync(criteria);
    setIsResponsible(true);
  }, []);

  const getListDataAsync = async (searchModel: SearchContractManagementListModel) => {
    const search = { ...searchModel };

    if (!searchModel.page) {
      search.page = page;
    }

    if (!searchModel.pageSize) {
      search.pageSize = size;
    }

    if (searchModel.contractStatus === undefined || searchModel.contractStatus.length === 0) {
      search.contractStatus = defaultStatus;
    }

    if (searchModel.supplyMethodIds === undefined || searchModel.supplyMethodIds.length === 0) {
      search.supplyMethodIds = defaultMethod;
    }

    const {
      data: response,
      status,
    } =
      await CM03Service.getListContractManagementAsync(search);

    if (status === HttpStatusCode.OK) {
      setDataList(response.data);
      setTotal(response.totalRecords);
    }

    getCountStatus(search);
    getCountSupplyMethodAsync(search);
  };

  const getCountStatus = useCallback(async (searchModel: SearchContractManagementListModel) => {

    const countStatusRes = await CM03Service.getCountStatusAsync(
      searchModel,
    );

    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data);
    }

  }, []);

  const getCountSupplyMethodAsync = useCallback(async (searchModel: SearchContractManagementListModel) => {

    const countRes = await CM03Service.getCountSupplyMethodAsync(
      searchModel,
    );

    if (countRes.status === HttpStatusCode.OK) {
      setCountSupplyMethod(countRes.data);
    }

  }, []);

  const onChangeStatusAll = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setStatusContractAgreement(true);
      setStatusContractCancelled(true);
      setStatusContractComplete(true);
      setStatusContractManagement(true);
      setStatusContractWarranty(true);
      setStatusNone(true);
    } else {
      setStatusAll(false);
      setStatusContractAgreement(false);
      setStatusContractCancelled(false);
      setStatusContractComplete(false);
      setStatusContractManagement(false);
      setStatusContractWarranty(false);
      setStatusNone(false);
    }
  };

  const onChangeMethodAll = (result: boolean) => {
    setMethodAll(result);
    setMethod60(result);
    setMethod80(result);
  };

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex(s => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter(s => s.value === false).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case ContractStatus.ContractAgreement:
        return setStatusContractAgreement(value);
      case ContractStatus.ContractCancelled:
        return setStatusContractCancelled(value);
      case ContractStatus.ContractComplete:
        return setStatusContractComplete(value);
      case ContractStatus.ContractManagement:
        return setStatusContractManagement(value);
      case ContractStatus.ContractWarranty:
        return setStatusContractWarranty(value);
      case ContractStatus.None:
        return setStatusNone(value);
    }
  };

  const onChangeCheckMethod = (methodId: string, value: boolean) => {
    const methodList = [...methodLists];
    const index = methodLists.findIndex((s) => s.label === methodId);
    methodList[index].value = value;
    setMethodLists(methodList);

    if (!value) {
      setMethodAll(false);
    }

    if (methodList.filter(s => s.value === false).length === 0) {
      setMethodAll(true);
    }

    switch (methodId) {
      case SupplyMethodEnum.MethodId60:
        return setMethod60(value);
      case SupplyMethodEnum.MethodId80:
        return setMethod80(value);
      default:
        break;
    }
  };

  const onSearch = (size: number, page: number) => {
    const status: string[] = [];
    const method: string[] = [];

    if (statusContractAgreement) {
      status.push(ContractStatus.ContractAgreement);
    }

    if (statusContractCancelled) {
      status.push(ContractStatus.ContractCancelled);
    }

    if (statusContractComplete) {
      status.push(ContractStatus.ContractComplete);
    }

    if (statusContractManagement) {
      status.push(ContractStatus.ContractManagement);
    }

    if (statusContractWarranty) {
      status.push(ContractStatus.ContractWarranty);
    }

    if (statusNone) {
      status.push(ContractStatus.Assigned);
    }

    if (method60) {
      method.push(SupplyMethodEnum.MethodId60);
    }

    if (method80) {
      method.push(SupplyMethodEnum.MethodId80);
    }

    const search = {
      contractNumber: criteria.contractNumber,
      departmentId: departmentId,
      planName: criteria.planName,
      planNumber: criteria.planNumber,
      contractName: criteria.contractName,
      page: page,
      pageSize: size,
      contractEndDate: criteria.contractEndDate,
      contractStartDate: criteria.contractStartDate,
      contractStatus: status,
      parcelDetail: criteria.parcelDetail,
      entrepreneursName: criteria.entrepreneursName,
      poNumber: criteria.poNumber,
      isResponsible,
      contractRecordType: criteria.contractRecordType,
      supplyMethodIds: method,
    } as SearchContractManagementListModel;

    getListDataAsync(search);
  };

  const onClearSearch = () => {
    const status: string[] = [];
    const method: string[] = [];
    const isSize = 10;
    const isPage = 1;
    setPage(isPage);
    setSize(isSize);
    onPaginationChange(isSize, isPage);
    setDepartmentId('');
    setStatusAll(true);
    onChangeMethodAll(true);
    setMethod60(true);
    setMethod80(true);
    setStatusContractAgreement(true);
    setStatusContractCancelled(true);
    setStatusContractComplete(true);
    setStatusContractManagement(true);
    setStatusContractWarranty(true);
    setIsResponsible(true);
    setStatusNone(true);
    setCriteria({
      page: isPage,
      pageSize: isSize,
    } as SearchContractManagementListModel);

    const search = {
      page: isPage,
      pageSize: isSize,
      contractStatus: status,
      isResponsible: true,
      supplyMethodIds: method,
    } as SearchContractManagementListModel;

    getListDataAsync(search);
  };

  const onPaginationChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);
    criteria.page = page;
    criteria.pageSize = size;
    onSearch(size, page);
  };

  return (
    <div className='m01'>
      <Title text='ส่งมอบและตรวจรับงาน' />
      <Row className='criteria'>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่รายการจัดซื้อจัดจ้าง'
            placeholder='เลขที่รายการจัดซื้อจัดจ้าง'
            onChange={planNumber => setCriteria({
              ...criteria,
              planNumber,
            })}
            value={criteria.planNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่สัญญา'
            placeholder='เลขที่สัญญา'
            onChange={contractNumber => setCriteria({
              ...criteria,
              contractNumber,
            })}
            value={criteria.contractNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่ PO'
            placeholder='เลขที่ PO'
            onChange={poNumber => setCriteria({
              ...criteria,
              poNumber,
            })}
            value={criteria.poNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='ชื่อสัญญา'
            placeholder='ชื่อสัญญา'
            onChange={contractName => setCriteria({
              ...criteria,
              contractName,
            })}
            value={criteria.contractName} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='ประเภทอนุมัติใบสั่งซื้อ/จ้าง/เช่า'
            items={contractTypeDDL}
            value={criteria.contractRecordType}
            onChange={contractRecordType => setCriteria({ ...criteria, contractRecordType })}
          />
        </Col>
        {/* <Col sm={12} md={4} lg={4} xl={3}>
          <Selector
            label="ฝ่าย/สำนัก"
            placeholder="ฝ่าย/สำนัก"
            items={departmentDDL}
            value={criteria.departmentId}
            onChange={departmentId => setCriteria({ ...criteria, departmentId })}
          />
        </Col> */}
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='ผู้ประกอบการ/คู่ค้า'
            placeholder='ผู้ประกอบการ/คู่ค้า'
            onChange={entrepreneursName => setCriteria({
              ...criteria,
              entrepreneursName,
            })}
            value={criteria.entrepreneursName} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker label='วันที่เริ่มสัญญา'
            onChange={contractStartDate => setCriteria({
              ...criteria,
              contractStartDate,
            })}
            value={criteria.contractStartDate} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker label='วันที่สิ้นสุด'
            onChange={contractEndDate => setCriteria({
              ...criteria,
              contractEndDate,
            })}
            value={criteria.contractEndDate} />
        </Col>
        <Col md={6}>
          <Form.Group className='mb-3'>
            <Form.Label>วิธีจัดหา</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={(
                  <div className='align-items-center d-flex'>
                    ทั้งหมด
                    <Badge
                      className='h-50 mx-2'
                      bg='light'
                    >
                      {
                        countSupplyMethod.length > 0
                          ? countSupplyMethod
                            .map((x) => x.count)
                            .reduce((prev, curr) => prev + curr)
                          : 0
                      }
                    </Badge>
                  </div>
                )}
                value={methodAll}
                onChange={(val) => onChangeMethodAll(val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>
                    พ.ร.บ.จัดซื้อจัดจ้างฯ
                    2560
                    <Badge
                      className='h-50 mx-2'
                      bg='primary'
                    >
                      {
                        countSupplyMethod
                          .filter((x) => x.supplyMethod === SupplyMethodEnum.MethodId60).length > 0
                          ? countSupplyMethod
                            .filter((x) => x.supplyMethod === SupplyMethodEnum.MethodId60)[0].count
                          : 0
                      }
                    </Badge>
                  </div>
                )}
                value={method60}
                onChange={(val) => onChangeCheckMethod(SupplyMethodEnum.MethodId60, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>
                    ข้อบังคับธนาคาร 80
                    <Badge
                      className='h-50 mx-2'
                      bg='primary'
                    >
                      {
                        countSupplyMethod
                          .filter((x) => x.supplyMethod === SupplyMethodEnum.MethodId80).length > 0
                          ? countSupplyMethod
                            .filter((x) => x.supplyMethod === SupplyMethodEnum.MethodId80)[0].count
                          : 0
                      }
                    </Badge>
                  </div>
                )}
                value={method80}
                onChange={(val) => onChangeCheckMethod(SupplyMethodEnum.MethodId80, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={<div className='align-items-center d-flex'>ทั้งหมด <Badge className='h-50 mx-2'
                  bg='light'>{countStatus.reduce((count, v) => count + v.count, 0)}</Badge>
                </div>}
                value={statusAll}
                onChange={(val) => onChangeStatusAll(val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>รอดำเนินการ <Badge className='h-50 mx-2'
                    bg='secondary'>
                    {
                      countStatus
                        .filter((x) => x.status === ContractStatus.None).length > 0
                        ? countStatus
                          .filter((x) => x.status === ContractStatus.None)[0].count
                        : 0
                    }
                  </Badge>
                  </div>
                )}
                value={statusNone}
                onChange={(val) => onChangeCheckStatus(ContractStatus.None, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>ร่างสัญญา <Badge className='h-50 mx-2'
                    bg='secondary'>
                    {
                      countStatus
                        .filter((x) => x.status === ContractStatus.ContractAgreement).length > 0
                        ? countStatus
                          .filter((x) => x.status === ContractStatus.ContractAgreement)[0].count
                        : 0
                    }
                  </Badge>
                  </div>
                )}
                value={statusContractAgreement}
                onChange={(val) => onChangeCheckStatus(ContractStatus.ContractAgreement, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>บริหารสัญญา <Badge className='h-50 mx-2'
                    bg='warning'>
                    {countStatus.filter((x) => x.status === ContractStatus.ContractManagement).length > 0 ? countStatus.filter((x) => x.status === ContractStatus.ContractManagement)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusContractManagement}
                onChange={(val) => onChangeCheckStatus(ContractStatus.ContractManagement, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>รับประกันสัญญา <Badge className='h-50 mx-2'
                    bg='warning'>
                    {countStatus.filter((x) => x.status === ContractStatus.ContractWarranty).length > 0 ? countStatus.filter((x) => x.status === ContractStatus.ContractWarranty)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusContractWarranty}
                onChange={(val) => onChangeCheckStatus(ContractStatus.ContractWarranty, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>สิ้นสุดสัญญา <Badge className='h-50 mx-2'
                    bg='success'>
                    {countStatus.filter((x) => x.status === ContractStatus.ContractComplete).length > 0 ? countStatus.filter((x) => x.status === ContractStatus.ContractComplete)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusContractComplete}
                onChange={(val) => onChangeCheckStatus(ContractStatus.ContractComplete, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>ยกเลิกสัญญา <Badge className='h-50 mx-2'
                    bg='danger'>
                    {countStatus.filter((x) => x.status === ContractStatus.ContractCancelled).length > 0 ? countStatus.filter((x) => x.status === ContractStatus.ContractCancelled)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusContractCancelled}
                onChange={(val) => onChangeCheckStatus(ContractStatus.ContractCancelled, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Row>
          <Col sm={6}>
            <Check
              label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
              value={isResponsible}
              onChange={(val) => setIsResponsible(val)}
            />
          </Col>
        </Row>
        <div className='d-flex gap-2'>
          <Button
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={() => onSearch(size, page)}>
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={() => onClearSearch()}
          >
            <FaEraser /> ล้าง
          </Button>
        </div>
      </Row>
      <Row className='mt-5'>
        <Col sm={12}>
          <Table total={total}
            onChange={(size, page) => { onPaginationChange(size, page) }}>
            <thead>
              <tr className='text-center'>
                <th style={{ width: '10%' }}>เลขที่รายการจัดซื้อจัดจ้าง</th>
                <th style={{ width: '10%' }}>เลขที่สัญญา</th>
                <th style={{ width: '10%' }}>เลขที่ PO</th>
                <th style={{ width: '20%' }}>ชื่อสัญญา</th>
                <th>วิธีจัดหา</th>
                <th style={{ width: '20%' }}>ประเภทอนุมัติใบสั่งซื้อ/จ้าง/เช่า</th>
                <th style={{ width: 100 }}>สถานะ</th>
                <th style={{ width: '20%' }}>ผู้ประกอบการ/คู่ค้า</th>
                <th style={{ width: '10%' }}>วงเงินตามสัญญา</th>
                <th style={{ width: '10%' }}>วันที่ทำเริ่มสัญญา</th>
                <th style={{ width: '10%' }}>วันที่สิ้นสุดสัญญา</th>
              </tr>
            </thead>
            <tbody>
              {dataList?.map((data, index) => (
                <tr className='text-center'
                  key={index}>
                  <td><Button variant='link'
                    onClick={() => goToPage(data.id, data.contractRecordId, data.financialId, data.entrepreneursId)}>{data.planNumber}</Button></td>
                  <td>{data.contractNumber}</td>
                  <td>{data.poNumber}</td>
                  <td className='text-start'>{data.planName}</td>
                  <td className='text-start'>{data.supplyMethodName} : {data.supplyMethodSpecialTypeName}</td>
                  <td>{data.contractRecordType}</td>
                  <td>
                    {/* <StatusIndexCM systemStatue={data.status}
                      systemName='cm-03' /> */}
                    <Status value={data.status}
                      type={StatusType.CM03Status} />
                  </td>
                  <td className='text-start'>{data.entrepreneurName}</td>
                  <td className='text-end'>{THCurrency(data.offerTotalAmountVat)}</td>
                  <td>{fullDate(data.contractStartDate)}</td>
                  <td>{fullDate(data.contractEndDate)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
