import {
  Card,
  Selector,
} from 'components';
import Title from 'components/Controls/Title';
import { SearchNameModal } from 'components/Modal';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { useLoaderData } from 'react-router';
import { SectionType } from '../../../constant/SectionType';
import {
  ItemModel,
  UserListSelectionParams,
  UserListSelectionResponse,
} from '../../../models';
import {
  ISectionModel,
  ISectionUpdateModel,
} from '../../../models/SU/ISectionWithApproverModel';
import { shareValue } from '../../../services';
import SectionService from '../../../services/SU/SectionService';
import {
  HttpStatusCode,
  useAppContext,
} from '../../../utils';
import toast from '../../../utils/toast';
import TableSectionAndDefaultApprovers from '../Component/TableSectionAndDefaultApprovers';
import { createDefaultApproverAsync } from '../Functions/section';

type Loader = { departmentWithOutJorPorDropDownList: ItemModel[] };

function SU010() {
  const { departmentWithOutJorPorDropDownList } = useLoaderData() as Loader;
  const { departmentId: userDepartmentId } = useAppContext();

  const [show, setShow] = useState(false);
  const [sectionData, setSectionData] = useState<ISectionModel>({} as ISectionModel);
  const [sectionCodeId, setSectionCodeId] = useState<string>('');
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [departments, setDepartments] = useState<ItemModel[]>([]);
  const [departmentId, setDepartmentId] = useState<string>('');
  const [sectionsUpdate, setSectionsUpdate] = useState<ISectionUpdateModel[]>([]);

  const getSectionAndDefaultApproversAsync = async (departmentId: string) => {
    const {
      data,
      status,
    } = await SectionService.getListSectionWithDefaultApproverAsync(SectionType.NoneJorPor, departmentId);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถดึงข้อมูลได้');

      return;
    }

    if (data.sections.length === 0) {
      const { status: defaultStatus } = await SectionService.createListSectionAsync(departmentId, SectionType.NoneJorPor);

      if (defaultStatus !== HttpStatusCode.OK && defaultStatus !== HttpStatusCode.CREATED) {
        toast.error('ไม่สามารถเพิ่มข้อมูลของ Section ได้ในขณะนี้');

        return;
      }

      await getSectionAndDefaultApproversAsync(departmentId);

      return;
    }

    setSectionData(data);
  };

  useEffect(() => {
    if (userDepartmentId) {
      setDepartmentId(userDepartmentId);
      getSectionAndDefaultApproversAsync(userDepartmentId);
    }

    getUserData();
    setDepartments(departmentWithOutJorPorDropDownList);
  }, [departmentWithOutJorPorDropDownList, userDepartmentId]);

  const getUserData = async (fullName?: string, departmentId?: string, positionId?: string, page = 1, size = 10) => {
    const search: UserListSelectionParams = { fullName, departmentId, positionId } as UserListSelectionParams;
    const { data, status } = await shareValue.getUserListSelectionAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onDepartmentChangeAsync = async (departmentId: string) => {
    await getSectionAndDefaultApproversAsync(departmentId);
    setDepartmentId(departmentId);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionParams, page: number = 1, size: number = 10) => {
    await getUserData(searchData.fullName, searchData.departmentId, searchData.positionId, page, size);
  };

  const onFinancialAmountChange = (financial: number, sectionId: string) => {
    setSectionsUpdate((pre) => {
      const index = pre.findIndex((d) => d.sectionCodeId === sectionId);

      if (index !== -1) {
        pre[index].financialAmount = financial;
      } else {
        pre.push({ sectionCodeId: sectionId, financialAmount: financial });
      }

      return [...pre];
    });
  };

  const updateMultipleSectionFinancialAmountAsync = async (sectionsUpdate: ISectionUpdateModel[], departmentId: string) => {
    const { status } = await SectionService.updateMultipleSectionFinancialAmountAsync(sectionsUpdate);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถแก้ไขข้อมูลได้');

      return;
    }

    await getSectionAndDefaultApproversAsync(departmentId);
    toast.success('แก้ไขข้อมูลสำเร็จ');
  };

  return (
    <div className="document">
      <Title text="กำหนดข้อมูลผู้เห็นชอบ/อนุมัติ"
        className="fs-5 text-primary" />
      <Row className="gap-5 mb-4">
        <Col sm={12}
          md={3}>
          <Selector label="ฝ่าย/สำนัก"
            items={departments}
            value={departmentId}
            onChange={onDepartmentChangeAsync} />
        </Col>
      </Row>
      <Card className="mt-3">
        <Title text="ผู้มีอำนาจเห็นชอบ"
          className="fs-5 text-primary" />
        <Row className="gap-5 mt-3">
          <RenderTable
            onRemove={() => getSectionAndDefaultApproversAsync(departmentId)}
            setShow={() => setShow(!show)}
            sectionData={sectionData}
            setSectionCodeId={setSectionCodeId}
            onFinancialAmountChange={onFinancialAmountChange}
          />
        </Row>
        <div className="mt-3 center">
          <Button
            onClick={() => updateMultipleSectionFinancialAmountAsync(sectionsUpdate, departmentId)}
            variant="success"
          >
            บันทึกข้อมูล
          </Button>
        </div>
      </Card>
      <SearchNameModal
        total={userTotal}
        show={show}
        onHide={() => setShow(!show)}
        onSelectItem={(id) => {
          createDefaultApproverAsync(0, id, sectionCodeId, async () => await getSectionAndDefaultApproversAsync(departmentId));
        }}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={(size, page, data) => onSearchUserAsync(data ?? {
          departmentId: '',
          fullName: '',
          positionId: '',
          workGroupId: '',
        }, page, size)}
      />
    </div>
  );
}

interface IPropsRenderTable {
  sectionData: ISectionModel;
  setSectionCodeId: (value: string) => void;
  setShow: () => void;
  onFinancialAmountChange: (financial: number, sectionId: string) => void;
  onRemove: () => void;
}

function RenderTable(props: IPropsRenderTable) {
  return (
    <>
      {
        props.sectionData?.sections?.map((d, index) => (
          <Col sm={12}
            className="ps-5"
            key={`${d.sectionId}-${index}`}>
            <TableSectionAndDefaultApprovers
              onRemove={() => props.onRemove()}
              sectionData={d}
              sendSectionCode={(sectionCode) => {
                props.setSectionCodeId(sectionCode);
                props.setShow();
              }}
              onFinancialAmountChange={props.onFinancialAmountChange}
            />
          </Col>
        ))
      }
    </>
  );
}

export default SU010;
