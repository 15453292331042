import { Currency, Input, Selector, Table } from 'components';
import { RefBankOrder } from 'constant/PreProcumentFlag';
import { ItemModel } from 'models';
import { SectionApproverModel, SectionApproverSearchModel } from 'models/sectionApproverModel';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaEdit, FaEraser, FaPlus, FaSearch, FaTrashAlt } from 'react-icons/fa';
import { useLoaderData, useNavigate } from 'react-router';
import { SU12Service } from 'services';
import SectionApproverService from 'services/SU/SectionApproverService';
import { HttpStatusCode, THCurrency, fullDatetime, showRemoveModalAsync } from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';
import toast from 'utils/toast';

const RefBankOrderDDL = [
  { label: RefBankOrder.RefBankOrder72, value: RefBankOrder.RefBankOrder72 },
  { label: RefBankOrder.RefBankOrder159, value: RefBankOrder.RefBankOrder159 },
  { label: RefBankOrder.RefBankOrder216, value: RefBankOrder.RefBankOrder216 },
  { label: RefBankOrder.RefBankOrder285, value: RefBankOrder.RefBankOrder285 },
  { label: RefBankOrder.RefBankOrder528, value: RefBankOrder.RefBankOrder528 },
];

interface CriteriaProps {
  sectionDDL: ItemModel[];
  processTypeDDL: ItemModel[];
  onSearch: (sectionId?: string, processType?: string, refBankOrder?: string, budget?: number) => void;
}

interface DataTableProps {
  totalRecords: number;
  processTypeDDL: ItemModel[];
  onChange: (size: number, page: number) => void;
  data: SectionApproverModel[];
  onRemove: (id: string) => void;
}

type Loader = {
  sectionDDL: ItemModel[],
  processTypeDDL: ItemModel[],
};

export default function SU13() {
  const { sectionDDL, processTypeDDL } = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [data, setData] = useState<SectionApproverModel[]>([]);
  const [searchModel, setSearchModel] = useState<SectionApproverSearchModel>({} as SectionApproverSearchModel);

  const getDataAsync = async (
    size: number,
    page: number,
    sectionId?: string,
    processType?: string,
    refBankOrder?: string,
    budget?: number) => {
    const res = await SectionApproverService.getPaginatedAsync(size, page, sectionId, processType, refBankOrder, budget);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }
  };

  const onCriteria = async (sectionId?: string, processType?: string, refBankOrder?: string, budget?: number) => {
    setSearchModel({
      sectionId: sectionId,
      processType: processType,
      refBankOrder: refBankOrder,
      budget: budget,
    });

    getDataAsync(size, page, sectionId, processType, refBankOrder, budget);
  };

  const onDataTableChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);

    getDataAsync(size, page, searchModel.sectionId, searchModel.processType, searchModel.refBankOrder, searchModel.budget);
  };

  const onRemoveAsync = async (id: string) => {
    const res = await SU12Service.deleteAsync(id);

    if (res.status === HttpStatusCode.NO_CONTENT) {
      toast.success('ลบข้อมูลสำเร็จ');

      await getDataAsync(size, page, searchModel.sectionId, searchModel.processType, searchModel.refBankOrder, searchModel.budget);
    }
  };

  useEffect(() => {
    getDataAsync(size, page, searchModel.sectionId, searchModel.processType, searchModel.refBankOrder, searchModel.budget);
  }, []);

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>ลำดับการอนุมัติ</h4>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => navigate('detail')}>
          <FaPlus />เพิ่มลำดับการอนุมัติ
        </Button>
      </div>
      <hr />
      <Criteria onSearch={onCriteria} sectionDDL={sectionDDL} processTypeDDL={processTypeDDL} />
      <DataTable
        totalRecords={totalRecords}
        processTypeDDL={processTypeDDL}
        onChange={onDataTableChange}
        data={data}
        onRemove={onRemoveAsync} />
    </>
  );
}

function Criteria(props: CriteriaProps) {
  const [searchModel, setSearchModel] = useState<SectionApproverSearchModel>({} as SectionApproverSearchModel);

  const clear = () => {
    setSearchModel({} as SectionApproverSearchModel);

    props.onSearch('', '');
  };

  return (
    <div className='criteria'>
      <Row>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label='การอนุมัติ'
            value={searchModel.processType}
            onChange={(val) => setSearchModel({ ...searchModel, processType: val })}
            items={props.processTypeDDL}
          />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label='ลำดับอนุมัติ'
            value={searchModel.sectionId}
            onChange={(val) => setSearchModel({ ...searchModel, sectionId: val })}
            items={props.sectionDDL}
          />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label='คำสั่งธนาคาร'
            value={searchModel.refBankOrder}
            onChange={(val) => setSearchModel({ ...searchModel, refBankOrder: val })}
            items={RefBankOrderDDL}
          />
        </Col>
        <div className='d-flex gap-2'>
          <Button
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={() => props.onSearch(searchModel.sectionId, searchModel.processType, searchModel.refBankOrder, searchModel.budget)}>
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={clear}>
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
    </div>
  );
}

function DataTable(props: DataTableProps) {
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();

  const onChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);

    props.onChange(size, page);
  };

  const onRenderBuOrganization = (data: SectionApproverModel) => {
    return [data.buHeadName, data.buGroupName, data.buLineName, data.buDepartmentName, data.buCenterName, data.buZoneName, data.buSegmentName, data.buBranchName, data.buSubBranchName].filter(Boolean).join(", ");
  };

  return (
    <>
      <div className="table-relative-fix">
        <div className="table-scroll">
          <Table
            className='mt-4'
            total={props.totalRecords}
            onChange={onChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 75 }}>ลำดับ</th>
                <th style={{ minWidth: 100 }}>การอนุมัติ</th>
                <th style={{ minWidth: 100 }}>คำสั่งธนาคาร</th>
                <th style={{ minWidth: 100 }}>ชื่อลำดับอนุมัติ</th>
                <th style={{ minWidth: 100 }}>งบประมาณสูงสุดที่อนุมัติ</th>
                <th style={{ minWidth: 150 }}>โครงสร้างองค์กร</th>
                <th style={{ width: 30 }} />
              </tr>
            </thead>
            <tbody>
              {props.data.map((data, i) => (
                <tr key={data.id}>
                  <td className='text-center'>{calculateRowNumber(i, page, size)}</td>
                  <td className='text-center'>{
                    props.processTypeDDL.some(p => p.value === data.processType) ?
                      props.processTypeDDL.filter(p => p.value === data.processType)[0].label : ''}</td>
                  <td className='text-center'>{data.refBankOrder}</td>
                  <td className='text-center'>{data.sectionName}</td>
                  <td className='text-end'>{data.budget > 0 ? THCurrency(data.budget) : data.budget}</td>
                  <td style={{ whiteSpace: 'break-spaces' }}>
                    {onRenderBuOrganization(data)}
                  </td>
                  <td>
                    <Button
                      variant='outline-primary'
                      className='d-flex align-items-center gap-2'
                      onClick={() => navigate(`detail/${data.id}`)}>
                      <FaEdit />แก้ไข
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}