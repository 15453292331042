import { Card, InputTextArea } from 'components';
import Title from 'components/Controls/Title';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Collapse,
  Row,
} from 'react-bootstrap';
import { Context } from '../../step';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';

interface IDocumentDescriptionProps {
  documentDescription: string;
  onDocumentDescriptionChange: (value: string) => void;
  isDisabled?: boolean;
}

export function DocumentDescription(props: IDocumentDescriptionProps) {
  const { readonly } = useContext(Context);
  const [firstLoad, setFirstLoad] = useState(true);
  const [documentDescription, setDocumentDescription] = useState(props.documentDescription);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    props.onDocumentDescriptionChange(documentDescription);
  }, [documentDescription]);

  useEffect(() => {
    if (props.documentDescription && firstLoad) {
      setDocumentDescription(props.documentDescription);
      setFirstLoad(false);
    }
  }, [props.documentDescription, firstLoad]);

  return (
    <Card className='mt-3'>
      <div className='d-flex align-items-center'>
        <Title
          text='เอกสารประกอบการเสนอราคา(ถ้ามี)'
          className='fs-5 text-primary'
        />
        {
          collapsed
            ? (
              <FaChevronCircleDown
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(false)}
              />
            ) : (
              <FaChevronCircleUp
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(true)}
              />
            )
        }
      </div>
      <Collapse in={!collapsed} className='ps-40'>
        <div>
          <Row className='mt-2'>
            <Col sm={12} md={12}>
              <InputTextArea
                value={documentDescription}
                onChange={(val) => setDocumentDescription(val)}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly}
                placeholder='.......(กรุณาระบุในเอกสาร)......'
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card>
  );
}
