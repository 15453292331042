import {
  AcceptorsAgreeStatus,
  AcceptorsApprovalStatus,
  Card,
  Check,
  Input,
  InputTextArea,
  Modal,
  PurchaseOrderApprovalStatus,
  SearchBuNameModal,
  ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import { UserApproveModal } from 'components/Modal/UserApproveModal';
import { PurchaseOrderApprovalStatusConstant } from 'constant/PurchaseOrderApprovalConstant';
import Cookie from 'cookie-universal';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { sectionApproverModel } from 'models/delegateModel';
import {
  AcceptorsModel,
  ContractRecordEntrepreneursModel,
  ContractRecordEntrepreneursRequestModel,
  UpdateAcceptorsModel,
} from 'models/PR/PurchaseOrderApprovalModels';
import { documentsModel } from 'models/procurementAppointmentOrderModel';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaCheckCircle,
  FaHistory,
  FaPlus,
  FaRegCheckCircle,
  FaSave,
  FaChevronCircleDown,
  FaUndo,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { account, shareValue } from 'services';
import PurchaseOrderApprovalServices from 'services/PR/PurchaseOrderApprovalServices';
import {
  HttpStatusCode,
  ProcurementSection,
  submitForm,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { HistoryModal } from './HistoryModal';
import UploadFile from './UploadFile';
import { JorPorCode } from 'constant/basicInformation';
import { Acceptors } from 'components/Table/Acceptors';
import { AcceptorStatus } from 'utils/constants/PlanEnum';
import { isNull } from 'utils/helper';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

function AcceptorApproval() {
  const [openCollapse, setOpenCollapse] = useState(true);
  const { userId, departmentCode } = useAppContext();
  const [showModals, setShowModals] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const cookies = Cookie();
  const userfullName = cookies.get('fullName') as string;
  const [showHistory, setShowHistory] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const [showRejected, setShowRejectedg] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const [remark, setRemark] = useState('');
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('procurement-appointment-order/detail');
  // const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const navigate = useNavigate();
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [data, setData] = useState<ContractRecordEntrepreneursModel>({} as ContractRecordEntrepreneursModel);
  const { id } = useParams();
  const [acceptorsData, setAcceptorsData] = useState<AcceptorsModel[]>([]);
  const [isApprover, setIsApprover] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [sectionType, setSectionType] = useState<string>();
  const [sectionSequence, setSectionSequence] = useState<number>();

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const goToPage = (jorPor06Id?: string, id?: string) => {
    if (jorPor06Id) {
      navigate(`/pr/pr0206/detail/${jorPor06Id}/id/${id}`);
    } else {
      navigate(`/pr/pr0206/detail/id/${id}`);
    }
  };

  useEffect(() => {
    if (id) {

      getDataAsync(id);

      if (data.contractRecordEntrepreneurs?.acceptors) {
        setAcceptorsData(data.contractRecordEntrepreneurs?.acceptors);
      }

    }
  }, [id]);

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = Math.max.apply(null,
      data.contractRecordEntrepreneurs?.acceptors?.map((x) => x.sectionSequence));

    const valueSequence = data.contractRecordEntrepreneurs.acceptors?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  useEffect(() => {
    const paramId = location.pathname.slice(location.pathname.lastIndexOf('/'), location.pathname.length);
    setCurrentPath(location.pathname.replace(paramId, ''));

  }, []);

  const getDataAsync = async (id?: string) => {

    const res = await PurchaseOrderApprovalServices.getEntrepreneursByIdAsync(id);
    if (res.status === HttpStatusCode.OK) {
      setData(res.data);
    }

  };

  const onGetDefaultApprover = async () => {

    const response = await account
      .getDefaultApproverByTopLeve(PROCESSTYPE.ApproveSignContractProcess, data.contractRecordEntrepreneurs.offerTotalAmount);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: AcceptorsModel[] = [];
      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            inRefCode: item.inRefCode,
            sectionNameTh: item.sectionName,
            sectionId: item.sectionId,
          } as sectionGroup);

          approver.push({
            sectionSequence: item.sectionSequence,
            sectionName: item.sectionName,
            sequence: item.sequence,
            userId: item.userId,
            fullName: item.fullName,
            positionName: item.positionName,
            departmentName: item.departmentName,
            inRefCode: item.inRefCode,
            processType: item.processType,
            seqKey: '',
            availableRemark: '',
            isAvailable: false,
            approvedDateAt: undefined,
            status: '',
            sectionLevel: item.sectionLevel,
            delegateUserId: item.delegateUserId,
            delegateEmployeeCode: item.delegateEmployeeCode,
            delegateFullName: item.delegateFullName,
            delegateDepartmentName: item.delegateDepartmentName,
            delegatePositionName: item.delegatePositionName,
          } as AcceptorsModel);
        });

      const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);

      if (data.contractRecordEntrepreneurs?.acceptors === undefined || data.contractRecordEntrepreneurs?.acceptors === null || data.contractRecordEntrepreneurs?.acceptors.length === 0) {
        let inx = 0;
        let sectionSeq = 0;

        approver?.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {

            if (sectionSeq !== item.sectionSequence) {
              inx = 1;
              sectionSeq = item.sectionSequence;
            }

            approver[index].sequence = inx;

            inx++;
          });

        setAcceptorsData(approver);
        setSections(uniqueNames);
      }
    }
  };

  useEffect(() => {

    if (data) {

      if (data.contractRecordEntrepreneurs?.attachmentFiles) {
        handleSetFile(data.contractRecordEntrepreneurs.attachmentFiles);
      }

      if (data.contractRecordEntrepreneurs?.acceptors) {
        onCheckApproverStatus();
      }

      // if (data.contractRecordEntrepreneurs?.acceptors.length === 0) {
      //   onGetDefaultApprover();
      // }

      if (data.contractRecordEntrepreneurs?.status === PurchaseOrderApprovalStatusConstant.Draft || data.contractRecordEntrepreneurs?.status === PurchaseOrderApprovalStatusConstant.Rejected || data.contractRecordEntrepreneurs?.status === PurchaseOrderApprovalStatusConstant.WaitingForAssign) {
        setisDisabled(false);
      } else {
        setisDisabled(true);
      }

      if (data.contractRecordEntrepreneurs?.acceptors && data.contractRecordEntrepreneurs?.acceptors.length > 0) {
        setAcceptorsData(data.contractRecordEntrepreneurs.acceptors);

        const sectionDataGroup: sectionGroup[] = [];
        data.contractRecordEntrepreneurs.acceptors.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {
            sectionDataGroup.push({
              sectionName: item.sectionName,
              level: item.sectionLevel,
              sectionSequence: item.sectionSequence,
              inRefCode: item.inRefCode,
              sectionNameTh: item.sectionName,
              sectionId: item.sectionId,
            } as sectionGroup);
          });

        const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);

        setSections(uniqueNames);
      }

      if (data.contractRecordEntrepreneurs?.acceptors) {
        // const queueApprover = onCheckQueueApprover(data.contractRecordEntrepreneurs.acceptors);
        // setIsQueueApprover(queueApprover);
        const isApprover = onCheckApprover(data.contractRecordEntrepreneurs.acceptors);
        setIsApprover(isApprover);
      }

    }
  }, [data, userId]);

  const isQueueApprover = useMemo(() => {
    if (data.contractRecordEntrepreneurs) {
      const minSection = Math.min(...data.contractRecordEntrepreneurs.acceptors
        .filter(d => d.status === AcceptorStatus.PENDING)
        .map(o => o.sectionSequence));

      const sectionData = data.contractRecordEntrepreneurs?.acceptors
        .filter(d => d.sectionSequence === minSection
          && d.status === AcceptorStatus.PENDING)
        .sort((a, b) => a.sequence - b.sequence)[0];

      if (sectionData) {
        return isNull(sectionData.delegateUserId, sectionData.userId) === userId;
      }
    }
  }, [userId, data]);

  const onCheckApprover = (datas: AcceptorsModel[]) => {

    const maxValueSectionSequence = Math.max.apply(null,
      datas?.map((x) => {
        return x.sectionSequence;
      }));

    const maxValueSequence = Math.max.apply(null,
      datas?.filter(a => a.sectionSequence === maxValueSectionSequence)?.map((x) => {
        return x.sequence;
      }));

    const dataMaxValueSectionSequence = datas?.filter(a => a.sectionSequence === maxValueSectionSequence && a.sequence === maxValueSequence);
    const approverIndex = dataMaxValueSectionSequence?.findIndex(x => x.userId === userId || x.delegateUserId === userId) + 1;

    if (approverIndex) {
      return true;
    }

    return false;
  };

  const onCheckQueueApprover = (datas: AcceptorsModel[]) => {

    if (data.contractRecordEntrepreneurs.status === PurchaseOrderApprovalStatusConstant.WaitingForSign) {

      const minSection = Math.min(...datas.filter(d => d.status === PurchaseOrderApprovalStatusConstant.Pending && !d.isAvailable)
        .map(o => o.sectionSequence));

      const sectionDatas = datas.filter(a => a.status === PurchaseOrderApprovalStatusConstant.Pending && a.sectionSequence === minSection && !a.isAvailable)
        .sort((a, b) => a.sequence - b.sequence);

      if (sectionDatas && sectionDatas.length > 0) {
        return sectionDatas[0].userId === userId;
      }
      return false;
    }
    return false;

  };

  const onSelectEmpApprover = (empId: string, name: string, department: string, position: string, index: number, sectionSequence?: number, sectionName?: string, inRefCode?: string, sectionType?: string, delegateUserId?: string, delegateFullName?: string, delegateDepartmentName?: string, delegatePositionName?: string) => {
    if (acceptorsData && acceptorsData.filter((x) => x.inRefCode === inRefCode && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const acceptorBySection = acceptorsData.filter((x) => x.sectionSequence === sectionSequence);

      const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
        acceptorBySection.map((x) => {
          return x.sequence;
        })) : 0;

      const newData: AcceptorsModel = {
        id: undefined,
        sectionSequence: sectionSequence!,
        sectionName: sectionName!,
        userId: empId,
        sequence: maxValueSequence + 1,
        fullName: name,
        approvedDateAt: undefined,
        isAvailable: false,
        availableRemark: '',
        departmentName: department,
        status: AcceptorStatus.DRAFT,
        seqKey: `${sectionSequence?.toString()}${(acceptorsData.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        inRefCode: inRefCode!,
        processType: '',
        sectionId: '',
        sectionLevel: undefined,
        acceptDate: undefined,
        acceptRemark: '',
        positionName: position,
        rejectDate: undefined,
        rejectRemark: '',
        sectionCode: ProcurementSection.APPROVER,
        sectionType: sectionType,
        delegateUserId: delegateUserId,
        delegateFullName: delegateFullName,
        delegateDepartmentName: delegateDepartmentName,
        delegatePositionName: delegatePositionName,
        delegateEmployeeCode: '',
        delegateRangeDate: '',
      };
      setAcceptorsData((acceptorsData) => [...acceptorsData, newData]);
    }
  };

  const handlerOnChangeCheckbox = (value: boolean, seqKey: string) => {
    const data = [...acceptorsData];
    data.filter((x) => `${x.sectionSequence}${x.sequence}` === seqKey)[0].isAvailable = value;
    setAcceptorsData(data);
  };

  const handlerOnChangeRemark = (value: string, seqKey: string) => {
    const data = [...acceptorsData];
    data.filter((x) => `${x.sectionSequence}${x.sequence}` === seqKey)[0].availableRemark = value;
    setAcceptorsData(data);
  };

  const removeApprover = (seqKey: string) => {
    const temp: AcceptorsModel[] = [...acceptorsData];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    setAcceptorsData(temp);
  };

  // const onOpenModal = (index: number) => {
  //   setShowModals((showModals) => {
  //     const newModal = [...showModals];
  //     newModal[index] = true;
  //     return newModal;
  //   });
  // };

  // const onHideModal = (index: number) => {
  //   setShowModals((showModals) => {
  //     const newModal = [...showModals];
  //     newModal[index] = false;
  //     return newModal;
  //   });
  // };

  const handleSetFile = (documents: documentsModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const onSubmitAsync = async (sendToApprove: boolean) => {
    submitForm();

    // let isHasError = false;
    // sections.sort((a, b) => a.sectionSequence - b.sectionSequence)
    //   .forEach((section) => {
    //     if (acceptorsData.filter((x) => x.sectionSequence === section.sectionSequence).length === 0) {
    //       toast.warn(`กรุณาระบุ${section.sectionName}`);
    //       isHasError = true;
    //       return;
    //     }
    //   });

    // if (isHasError) {
    //   return;
    // }

    // if (acceptorsData.filter((x) => x.isAvailable === true && (x.availableRemark === null || x.availableRemark === '' || x.availableRemark === undefined)).length > 0) {
    //   toast.warn('กรุณาระบุหมายเหตุที่ไม่สามารถปฏิบัติงานได้');
    //   return;
    // }

    if (data && data.contractRecordEntrepreneurs.id) {
      const httpStatus = HttpStatusCode.OK;

      const updateDataModel: UpdateAcceptorsModel = {
        id: data.contractRecordEntrepreneurs.id,
        contractRecordFinancialId: data.contractRecordEntrepreneurs.contractRecordFinancialId,
        offerTotalAmount: data.contractRecordEntrepreneurs.offerTotalAmount,
        poNumber: data.contractRecordEntrepreneurs.poNumber,
        sendToApprove,
        sequence: data.contractRecordEntrepreneurs.sequence,
        acceptors: acceptorsData,
      };

      if (httpStatus === HttpStatusCode.OK) {
        const res = await PurchaseOrderApprovalServices.updateAcceptorsAsync(updateDataModel);

        if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
          getDataAsync(id);
          setShowPending(false);
          toast.success('บันทึกข้อมูลสำเร็จ');
          navigate(`${location.pathname}`);
        } else {
          toast.error(res.statusText);
        }

        if (data.contractRecordEntrepreneurs.status === PurchaseOrderApprovalStatusConstant.Draft
          && data.contractRecordEntrepreneurs?.assignees
          && data.contractRecordEntrepreneurs?.assignees.length > 0) {

          const updateDataModel: ContractRecordEntrepreneursRequestModel = {
            contractRecordFinancialId: data.contractRecordEntrepreneurs.contractRecordFinancialId,
            id: data.contractRecordEntrepreneurs.id,
            inviteEntrepreneursId: data.contractRecordEntrepreneurs.inviteEntrepreneursId,
            sequence: data.contractRecordEntrepreneurs.sequence,
            entrepreneursTaxId: data.contractRecordEntrepreneurs.entrepreneursTaxId,
            entrepreneursName: data.contractRecordEntrepreneurs.entrepreneursName,
            offerTotalAmount: data.contractRecordEntrepreneurs.offerTotalAmount,
            poNumber: data.contractRecordEntrepreneurs.poNumber,
            contractNumber: data.contractRecordEntrepreneurs.contractNumber,
            assignToAssignees: false,
            jorPor06Id: data.contractRecordEntrepreneurs.jorPor06Id,
            contractRecordId: data.contractRecordEntrepreneurs.contractRecordId,
            status: data.contractRecordEntrepreneurs.status,
            assignees: data.contractRecordEntrepreneurs?.assignees,
            acceptors: acceptorsData,
            acceptorStatus: data.contractRecordEntrepreneurs.acceptorStatus,
            assigneeStatus: data.contractRecordEntrepreneurs.assigneeStatus,
            attachmentFiles: data.contractRecordEntrepreneurs.attachmentFiles,
            files: data.contractRecordEntrepreneurs.files,
          };

          const res = await PurchaseOrderApprovalServices.updateEntrepreneursAssigneesAsync(updateDataModel);
        }
      }
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.contractRecordEntrepreneurs.contractRecordId) {
      const res = await PurchaseOrderApprovalServices.uploadPurchaseOrderApprovalAttachmentAsync(
        data.contractRecordEntrepreneurs.contractRecordId,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getDataAsync(data.contractRecordEntrepreneurs?.id);

      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {

    if (data && data.contractRecordEntrepreneurs.contractRecordId) {
      const res = await PurchaseOrderApprovalServices.removeFileAsync(
        data.contractRecordEntrepreneurs.contractRecordId,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getDataAsync(data.contractRecordEntrepreneurs?.id);

      }
    }

  }, [files, data.contractRecordEntrepreneurs?.id]);

  const onApproved = async () => {
    submitForm();

    const response = await PurchaseOrderApprovalServices.acceptorSignContractAsync(data.contractRecordEntrepreneurs?.id, remark);

    if (response.status === HttpStatusCode.OK) {
      toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);

      const res = await PurchaseOrderApprovalServices.getEntrepreneursByIdAsync(id);
      if (res.status === HttpStatusCode.OK) {
        setData(res.data);
      }

      navigate(`${location.pathname}`);

    }

    setShowApproved(false);
  };

  const onReject = async () => {
    submitForm();

    if (remark === null || remark === '') {
      return toast.error('กรุณากรอกเหตุผลส่งกลับแก้ไข');
    }

    const response = await PurchaseOrderApprovalServices.acceptorRejectSignContractAsync(data.contractRecordEntrepreneurs?.id, remark);

    if (response.status === HttpStatusCode.OK) {
      toast.success('ส่งกลับแก้ไขสำเร็จ');

      const res = await PurchaseOrderApprovalServices.getEntrepreneursByIdAsync(id);
      if (res.status === HttpStatusCode.OK) {
        setData(res.data);
      }

      navigate(`${location.pathname}`);

    }

    setShowRejectedg(false);
  };

  return (
    <div className='document'>
      {
        data.contractRecordEntrepreneurs && (
          <Acceptors
            titleHistory='ประวัติอนุมัติใบสั่งซื้อ/จ้าง/เช่า และแจ้งทำสัญญา'
            data={acceptorsData.length > 0 ? acceptorsData : data.contractRecordEntrepreneurs.acceptors!}
            visible={!isDisabled}
            isDepartmentDirector={false}
            processType={PROCESSTYPE.ApprovePurchaseOrderProcess}
            budgetAmount={data.contractRecordEntrepreneurs?.offerTotalAmount}
            onSetSectionType={(type) => setSectionType(type)}
            onAcceptorModalShow={() => setShowModals(!showModals)}
            onSetSectionSequence={(section) => setSectionSequence(section)}
            onAcceptorDataChange={(data) => setAcceptorsData(data)}
          />
        )
      }
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.contractRecordEntrepreneurs?.contractRecordId!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          onClick={() => goToPage(data.contractRecordEntrepreneurs.jorPor06Id, data.contractRecordEntrepreneurs.contractRecordId)}
          variant='outline-primary'>
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          {
            (data.contractRecordEntrepreneurs?.status === PurchaseOrderApprovalStatusConstant.Draft || data.contractRecordEntrepreneurs?.status === PurchaseOrderApprovalStatusConstant.Rejected) && (
              <>
                <Button
                  className='me-2 px-3'
                  variant='success'
                  onClick={() => onSubmitAsync(false)}
                >
                  <FaSave className='me-2 pb-1 fs-5' />
                  บันทึก
                </Button>
                <Button
                  className='me-2 px-3'
                  onClick={() => setShowPending(true)}
                >
                  <FaRegCheckCircle className='me-2 pb-1 fs-5' />
                  ส่งเห็นชอบ/อนุมัติ
                </Button>
              </>
            )
          }
          {(isQueueApprover && (data.contractRecordEntrepreneurs?.status === PurchaseOrderApprovalStatusConstant.WaitingForSign)) && (
            <>
              <Button
                className='me-2 px-3'
                onClick={() => setShowRejectedg(true)}
                variant='danger'>
                <FaUndo className='mx-2 pb-1 fs-5' />
                ส่งกลับแก้ไข
              </Button>

              <Button
                onClick={() => setShowApproved(true)}
                className='me-2 px-3'
                variant='success'
              >
                <FaCheckCircle className='me-2 pb-1 fs-5' />
                {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </Button>
            </>

          )}
        </div>
        <div style={{ width: 138 }} />
      </div>

      <HistoryModal
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={data.contractRecordEntrepreneurs?.contractRecordId}
      />
      <Modal
        show={showPending}
        size='lg'
        onHide={() => setShowPending(!showPending)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งเห็นชอบ/อนุมัติ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPending(!showPending)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showApproved}
        size='lg'
        onHide={() => setShowApproved(!showApproved)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>
                {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='ความเห็นเพิ่มเติม (ถ้ามี)'
                value={remark}
                onChange={(value) => setRemark(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApproved(!showApproved)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onApproved}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejected}
        size='lg'
        onHide={() => setShowRejectedg(!showRejected)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='เหตุผล'
                rule={{ required: true }}
                value={remark}
                onChange={(value) => setRemark(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='outline-primary'
                className='me-2 px-3'
                onClick={() => setShowRejectedg(!showRejected)}
              >
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onReject}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showModals}
        onHide={() => setShowModals(!showModals)}
        onSelect={(id, name, departmentName, position, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName) => onSelectEmpApprover(id, name, departmentName!, position!, acceptorsData.length + 1, sectionSequence, '', sectionType, sectionType, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
    </div>
  );
}

export default AcceptorApproval;
