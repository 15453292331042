import {
  Approver,
  ApproverStatus,
  Card,
  InputTextArea,
  MedianPriceStatus,
  Table,
  ArrowCollapse,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Modal,
  SearchBuNameModal,
  SearchNameModal,
} from 'components/Modal';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { sectionApproverModel } from 'models/delegateModel';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { BsCheckCircle } from 'react-icons/bs';
import {
  FaCheckCircle,
  FaCircle,
  FaHistory,
  FaPlus,
  FaRedo,
  FaRegCheckCircle,
  FaSave,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
} from 'react-icons/md';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import { account, shareValue } from 'services';
import {
  HttpStatusCode,
  fullDatetime,
  submitForm,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { HistoryModal } from './Modal/HistoryModal';
import { Context } from './step';
import UploadFile from './UploadFile';
import { AcceptorModel, AttachmentFileModel, MedianPriceAcceptorModel, UpdateIsAvailableCommitteeMedianPriceModel, UpdateIsAvailableCommitteeMedianPriceModels, UpdateMedianPriceAcceptorModel, UpdateMedianPriceJorPorAcceptorsModel } from 'models/PP/MedianPriceModel';
import { StatusMedianPrice } from 'components/StatusMedianPrice';
import MedianPriceService from 'services/PP/MedianPriceService';
import { PreProcumentFlag } from 'constant/PreProcumentFlag';
import { TorBudgetConstant, TorEditCancelStatusConstant } from 'constant/PreProcumentTorStatus';
import { JorPorCode } from 'constant/basicInformation';
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import { onCheckQueueSectionApprover } from 'utils/checkQueueSectionApprover';
import { AcceptorStatus } from 'utils/constants/PlanEnum';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level?: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

export enum sectionInRefCode {
  JorPorDirector = 'JorPorDirector',
  Assignee = 'Assignee',
  Responsible = 'Responsible',
}

function Step3({ onClickNext, onClickBack }: Props) {
  const { data, setData, readonly } = useContext(Context);
  const [show, setShow] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [openCollapse3, setOpenCollapse3] = useState(true);
  const [openCollapse4, setOpenCollapse4] = useState(true);
  const [files, setFiles] = useState<IFile[]>([]);
  const [currentPath, setCurrentPath] = useState('preprocurement-tor/detail');
  const [approverUsers, setApproverUsers] = useState<AcceptorModel[]>([]);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [showModals, setShowModals] = useState<boolean[]>([]);
  const [isApprover, setIsApprover] = useState(false);
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const { userId, departmentCode } = useAppContext();
  const [rejectRemark, setRejectRemark] = useState('');
  const [jorPorComment, setJorPorComment] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [showWaitingForApprove, setShowWaitingForApprove] = useState(false);
  const [showWaitingForAssign, setShowWaitingForAssign] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [approveRemark, setApproveRemark] = useState('');
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [remark, setRemark] = useState('');
  const [showDefaultApproverModal, setShowDefaultApproverModal] = useState<boolean>(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [showAssigneeModal, setShowAssigneeModal] = useState<boolean>(false);
  const [assignees, setAssignees] = useState<MedianPriceAcceptorModel[]>([]);
  const [showResponsibleModal, setShowResponsibleModal] = useState<boolean>(false);
  const [responsibles, setResponsibles] = useState<MedianPriceAcceptorModel[]>([]);
  const [isJorPorDirector, setIsJorPorDirector] = useState<boolean>(false);
  const [isAssignee, setIsAssignee] = useState<boolean>(false);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);
  const [docId, setDocId] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);
  const [isCommittee, setIsCommittee] = useState(false);
  const [showRecall, setShowRecall] = useState(false);
  const [isRecall, setIsRecall] = useState(false);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  useCallback(async (
    medianPriceId: string,
  ) => {
    const { data: docId, status } = await MedianPriceService.createDocumentAsync(medianPriceId, isDisabledProcess);

    if (status === HttpStatusCode.OK) {
      setData({ ...data, docId: docId });
    }
  }, []);

  const getDocument = useCallback(async (
    medianPriceId: string,
  ) => {
    const {
      data: docId,
      status,
    } = await MedianPriceService.createDocumentAsync(medianPriceId, isDisabledProcess);

    if (status === HttpStatusCode.OK) {
      setDocId(docId);
    }
  }, []);

  const setIsDisabledProcess = () => {
    setIsDisabled(isDisabledProcess);
  };

  const isDisabledProcess = useMemo(() => {
    return (data.status === MedianPriceStatus.Approved || data.status === MedianPriceStatus.WaitingForApprove);
  }, [data.status]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const onGetDefaultApprover = async () => {
    const processType = data.isConsumableMaterials ? PROCESSTYPE.ApproveMedianProcessStock : PROCESSTYPE.ApproveMedianProcessNonStock;

    const response = await account
      .getDefaultApproverByTopLeve(processType, data.isJorPorComment ? TorBudgetConstant.OneHundredMillion : data.financialAmount, data.appointPreProcurement.supplyMethod, data.appointPreProcurement.ignorePurchaseOnTable159, departmentCode);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: MedianPriceAcceptorModel[] = [];
      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
        sectionDataGroup.push({
          sectionName: item.sectionName,
          level: item.sectionLevel,
          sectionSequence: item.sectionSequence,
          inRefCode: item.inRefCode,
          sectionNameTh: item.sectionName,
          sectionId: item.sectionId,
        } as sectionGroup);

        approver.push({
          sectionSequence: item.sectionSequence,
          sectionName: item.sectionName,
          sequence: item.sequence,
          userId: item.userId,
          fullName: item.fullName,
          status: AcceptorStatus.DRAFT,
          positionName: item.positionName,
          departmentName: item.departmentName,
          sectionInRefCode: item.inRefCode,
          delegateFullName: item.delegateFullName,
          delegateDepartmentName: item.delegateDepartmentName,
          delegateEmployeeCode: item.delegateEmployeeCode,
          delegatePositionName: item.delegatePositionName,
          delegateUserId: item.delegateUserId,
          delegateDateRange: item.delegateDateRange,
        } as MedianPriceAcceptorModel);
      });

      const uniqueNames = sectionDataGroup
        .filter((data, index) => sectionDataGroup
          .findIndex((d) => d.inRefCode === data.inRefCode) === index);

      if (data.approvers === undefined || data.approvers === null || data.approvers.length === 0) {
        let inx = 0;
        let sectionSeq = 0;

        approver?.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
          if (sectionSeq !== item.sectionSequence) {
            inx = 1;
            sectionSeq = item.sectionSequence;
          }

          approver[index].sequence = inx;

          inx++;
        });

        setApproverUsers(approver);
        setSections(uniqueNames);
      }
    }
  };

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = data.approvers ? Math.max.apply(null,
      data.approvers?.map((x) => {
        return x.sectionSequence;
      })) : 0;

    const valueSequence = data.approvers?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  useEffect(() => {
    const paramId = location.pathname.slice(location.pathname.lastIndexOf('/'), location.pathname.length);

    setCurrentPath(location.pathname.replace(paramId, ''));
    // onCheckApproverStatus();

  }, []);

  useEffect(() => {
    if (data) {

      onCheckApproverStatus();

      if (data.id) {
        getFilesAsync(data.id);
        getDocument(data.id);
      }

      if (data.jorPorComment) {
        setJorPorComment(data.jorPorComment);
      }

      if (data.committees && data.committees.filter(x => x.userId === userId || x.delegateUserId === userId).length > 0) {
        setIsCommittee(true)
      } else {
        setIsCommittee(false)
      }

      setIsDisabledProcess();

      if (data.approvers) {

        setApproverUsers(data.approvers);
        onSetSections(data.approvers);

        // const queueApprover = data.approvers?.filter((a) => a.status === MedianPriceStatus.Pending)
        //   .sort((a, b) => a.sequence - b.sequence)[0];

        // if (queueApprover) {
        //   setIsQueueApprover(isNull(queueApprover.delegateUserId, queueApprover.userId) === userId);
        // }

        setIsQueueApprover(onCheckQueueSectionApprover(data.approvers, userId))

        const isApprover = onCheckApprover(data.approvers);
        setIsApprover(isApprover);

        const queueReCall = data.approvers?.filter(a => a.status === AcceptorStatus.APPROVE).length === 0;

        if (queueReCall) {
          setIsRecall(true);
        } else {
          setIsRecall(false);
        }

      }

      if (data.approvers.length === 0) {
        onGetDefaultApprover();
      }

      if (data.jorPorAcceptors) {

        setAssignees(data.jorPorAcceptors.filter(x => x.sectionInRefCode === sectionInRefCode.Assignee));
        setResponsibles(data.jorPorAcceptors.filter(x => x.sectionInRefCode === sectionInRefCode.Responsible));

        if (data.jorPorAcceptors?.filter(x => x.sectionInRefCode === sectionInRefCode.JorPorDirector && isNull(x.delegateUserId, x.userId) === userId).length > 0) {
          setIsJorPorDirector(true);
        }

        if (data.jorPorAcceptors?.filter(x => x.sectionInRefCode === sectionInRefCode.Assignee && isNull(x.delegateUserId, x.userId) === userId).length > 0) {
          setIsAssignee(true);
        }

        if (data.jorPorAcceptors?.filter(x => x.sectionInRefCode === sectionInRefCode.Responsible && isNull(x.delegateUserId, x.userId) === userId).length > 0) {
          setIsResponsible(true);
        }

      }
    }

  }, [data, userId]);

  function isNull(oldValue?: string, newValue?: string) {
    if (oldValue) {
      return oldValue;
    }

    return newValue;
  }

  const getFilesAsync = useCallback(async (id: string) => {
    const { data, status } = await MedianPriceService.getAttachmentAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    handleSetFile(data.attachments);

  }, []);

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await MedianPriceService.uploadAttachmentAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        handleSetFile(res.data);
        toast.success('อัปโหลดไฟล์สำเร็จ');
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {
    if (data && data.id) {
      const res = await MedianPriceService.removeAttachmentAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        const newArray = [...files];
        const i = newArray.findIndex((i) => i.id === docId);
        newArray.splice(i, 1);
        setFiles(newArray);

        toast.success('ลบไฟล์สำเร็จ');
      }
    }

  }, [files, data.id]);

  const onCheckApprover = (datas: AcceptorModel[]) => {
    const unApproved = datas?.filter((d) => d.status === MedianPriceStatus.Pending);

    if (unApproved?.length === 1) {
      return true;
    }

    return false;
  };

  const onCheckQueueApprover = (datas: MedianPriceAcceptorModel[]) => {

    if (data.status === MedianPriceStatus.WaitingForApprove) {

      const minSection = Math.min(...datas.filter(d => d.status === MedianPriceStatus.Pending)
        .map(o => o.sectionSequence));

      const sectionDatas = datas.filter(a => a.status === MedianPriceStatus.Pending && a.sectionSequence === minSection)
        .sort((a, b) => a.sequence - b.sequence);

      if (sectionDatas && sectionDatas.length > 0) {
        return sectionDatas[0].userId === userId;
      }
      return false;
    }
    return false;

  };

  const onSetSections = (datas: MedianPriceAcceptorModel[]) => {
    const sectionDataGroup: sectionGroup[] = [];

    if (datas) {
      datas.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {

          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: undefined,
            sectionSequence: item.sectionSequence,
            inRefCode: item.sectionInRefCode,
            sectionNameTh: item.sectionName,
            sectionId: '',
          } as sectionGroup);

        });

      const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);

      setSections(uniqueNames);
    }
  };

  const onOpenModal = (index: number) => {
    setShowModals((showModals) => {
      const newModal = [...showModals];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideModal = (index: number) => {
    setShowModals((showModals) => {
      const newModal = [...showModals];
      newModal[index] = false;
      return newModal;
    });
  };

  const onSelectEmpApprover = (empId: string, name: string, position: string, department: string, index: number, sectionSequence?: number, sectionName?: string, inRefCode?: string) => {
    if (approverUsers && approverUsers.filter((x) => x.sectionInRefCode === inRefCode && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {

      const acceptorBySection = approverUsers.filter((x) => x.sectionSequence === sectionSequence);

      const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
        acceptorBySection.map((x) => {
          return x.sequence;
        })) : 0;

      const newData: AcceptorModel = {
        id: undefined,
        sectionSequence: sectionSequence!,
        sectionName: sectionName!,
        userId: empId,
        sequence: maxValueSequence + 1,
        fullName: name,
        isAvailable: false,
        isAvailableRemark: '',
        departmentName: department,
        status: AcceptorStatus.DRAFT,
        seqKey: `${sectionSequence?.toString()}${(approverUsers.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        sectionInRefCode: inRefCode!,
        acceptDate: undefined,
        acceptRemark: '',
        positionName: position,
        rejectDate: undefined,
        rejectRemark: '',
        acceptUserId: empId,
        medianPriceId: '',
        rejectUserId: '',
        userApprovedBy: '',
        userRejectedBy: '',
        delegateDepartmentName: undefined,
        delegateEmployeeCode: undefined,
        delegateFullName: undefined,
        delegatePositionName: undefined,
        delegateUserId: undefined,
      };
      setApproverUsers((acceptorsData) => [...acceptorsData, newData]);
    }
  };

  const removeApprover = (seqKey: string) => {
    const temp: AcceptorModel[] = [...approverUsers];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    setApproverUsers(temp);
  };

  const onSubmitAsync = async (sendToApprove: boolean, assign: boolean) => {

    // submitForm();

    // let isHasError = false;
    // sections.sort((a, b) => a.sectionSequence - b.sectionSequence)
    //   .forEach((section) => {
    //     if (approverUsers.filter((x) => x.sectionSequence === section.sectionSequence).length === 0) {
    //       toast.warn(`กรุณาระบุ${section.sectionName}`);
    //       isHasError = true;
    //       return;
    //     }
    //   });

    // if (isHasError) {
    //   return;
    // }

    // if ((!approverUsers || approverUsers.length === 0) && (data.appointPreProcurement?.planBudget > TorBudgetConstant.TwoMillion && (data.status === MedianPriceStatus.WaitingForAssignResponsible || data.status === MedianPriceStatus.DraftSectionApprover))) {
    //   toast.warn('กรุณาเลือกผู้มีอำนาจเห็นชอบ/อนุมัติ');
    //   return;
    // }
    if (data.id) {

      collaboraRef.current?.clickSave();

      setTimeout(async () => {

        const saveApprovers: AcceptorModel[] = [];
        const jorPorAcceptors: MedianPriceAcceptorModel[] = [];
        const saveJorPorAcceptors: MedianPriceAcceptorModel[] = [];

        if (data.isJorPorComment && (data.status === MedianPriceStatus.Draft || data.status === MedianPriceStatus.Rejected || data.status === MedianPriceStatus.WaitingForJorPorDirector || data.status === MedianPriceStatus.WaitingForAssignResponsible)) {

          if ((!assignees || assignees.length === 0) && (data.isJorPorComment && data.status === MedianPriceStatus.WaitingForJorPorDirector)) {
            toast.warn('กรุณาเลือก ผอ.จพ. มอบหมาย');
            return;
          }

          if ((!responsibles || responsibles.length === 0) && (data.isJorPorComment && data.status === MedianPriceStatus.WaitingForAssignResponsible)) {
            toast.warn('กรุณาเลือกผู้รับผิดชอบ');
            return;
          }

          data.jorPorAcceptors?.filter(x => x.sectionInRefCode === sectionInRefCode.JorPorDirector).forEach((data, index) => {
            jorPorAcceptors.push({
              id: data.id,
              userId: data.userId,
              departmentName: data.departmentName,
              positionName: data.positionName,
              sectionSequence: data.sectionSequence,
              sequence: index + 1,
              status: data.status,
              sectionName: data.sectionName,
              seqKey: data.seqKey,
              delegateFullName: data.delegateFullName,
              delegateUserId: data.delegateUserId,
              delegatePositionName: data.delegatePositionName,
              delegateAnnotation: data.delegateAnnotation,
              delegateDateRange: data.delegateDateRange,
              delegateDepartmentName: data.delegateDepartmentName,
              delegateEmployeeCode: data.delegateEmployeeCode,
              acceptRemark: data.acceptRemark,
              acceptUserId: data.acceptUserId,
              isAvailable: data.isAvailable,
              isAvailableRemark: data.isAvailableRemark,
              medianPriceId: data.medianPriceId,
              rejectRemark: data.rejectRemark,
              rejectUserId: data.rejectUserId,
              sectionInRefCode: data.sectionInRefCode,
              userApprovedBy: data.userApprovedBy,
              userRejectedBy: data.userRejectedBy,
              acceptDate: data.acceptDate,
              rejectDate: data.rejectDate,
              fullName: data.fullName,
            } as MedianPriceAcceptorModel)
          });

          assignees?.forEach((data, index) => {
            jorPorAcceptors.push({
              id: data.id,
              userId: data.userId,
              departmentName: data.departmentName,
              positionName: data.positionName,
              sectionSequence: data.sectionSequence,
              sequence: index + 1,
              status: data.status,
              sectionName: data.sectionName,
              seqKey: data.seqKey,
              delegateFullName: data.delegateFullName,
              delegateUserId: data.delegateUserId,
              delegatePositionName: data.delegatePositionName,
              delegateAnnotation: data.delegateAnnotation,
              delegateDateRange: data.delegateDateRange,
              delegateDepartmentName: data.delegateDepartmentName,
              delegateEmployeeCode: data.delegateEmployeeCode,
              acceptRemark: data.acceptRemark,
              acceptUserId: data.acceptUserId,
              isAvailable: data.isAvailable,
              isAvailableRemark: data.isAvailableRemark,
              medianPriceId: data.medianPriceId,
              rejectRemark: data.rejectRemark,
              rejectUserId: data.rejectUserId,
              sectionInRefCode: data.sectionInRefCode,
              userApprovedBy: data.userApprovedBy,
              userRejectedBy: data.userRejectedBy,
              acceptDate: data.acceptDate,
              rejectDate: data.rejectDate,
              fullName: data.fullName,
            } as MedianPriceAcceptorModel)
          });

          responsibles?.forEach((data, index) => {
            jorPorAcceptors.push({
              id: data.id,
              userId: data.userId,
              departmentName: data.departmentName,
              positionName: data.positionName,
              sectionSequence: data.sectionSequence,
              sequence: index + 1,
              status: data.status,
              sectionName: data.sectionName,
              seqKey: data.seqKey,
              delegateFullName: data.delegateFullName,
              delegateUserId: data.delegateUserId,
              delegatePositionName: data.delegatePositionName,
              delegateAnnotation: data.delegateAnnotation,
              delegateDateRange: data.delegateDateRange,
              delegateDepartmentName: data.delegateDepartmentName,
              delegateEmployeeCode: data.delegateEmployeeCode,
              acceptRemark: data.acceptRemark,
              acceptUserId: data.acceptUserId,
              isAvailable: data.isAvailable,
              isAvailableRemark: data.isAvailableRemark,
              medianPriceId: data.medianPriceId,
              rejectRemark: data.rejectRemark,
              rejectUserId: data.rejectUserId,
              sectionInRefCode: data.sectionInRefCode,
              userApprovedBy: data.userApprovedBy,
              userRejectedBy: data.userRejectedBy,
              acceptDate: data.acceptDate,
              rejectDate: data.rejectDate,
              fullName: data.fullName,
            } as MedianPriceAcceptorModel)
          });

          jorPorAcceptors?.forEach((data, index) => {
            const newData: MedianPriceAcceptorModel = {
              id: data.id,
              userId: data.userId,
              departmentName: data.departmentName,
              positionName: data.positionName,
              sectionSequence: data.sectionSequence,
              sequence: index + 1,
              status: data.status,
              sectionName: data.sectionName,
              seqKey: data.seqKey,
              delegateFullName: data.delegateFullName,
              delegateUserId: data.delegateUserId,
              delegatePositionName: data.delegatePositionName,
              delegateAnnotation: data.delegateAnnotation,
              delegateDateRange: data.delegateDateRange,
              delegateDepartmentName: data.delegateDepartmentName,
              delegateEmployeeCode: data.delegateEmployeeCode,
              acceptRemark: data.acceptRemark,
              acceptUserId: data.acceptUserId,
              isAvailable: data.isAvailable,
              isAvailableRemark: data.isAvailableRemark,
              medianPriceId: data.medianPriceId,
              rejectRemark: data.rejectRemark,
              rejectUserId: data.rejectUserId,
              sectionInRefCode: data.sectionInRefCode,
              userApprovedBy: data.userApprovedBy,
              userRejectedBy: data.userRejectedBy,
              acceptDate: data.acceptDate,
              rejectDate: data.rejectDate,
              fullName: data.fullName,
              isChairman: undefined,
            };

            saveJorPorAcceptors.push(newData);
          });

          const updateDataJorPorAcceptorsModel: UpdateMedianPriceJorPorAcceptorsModel = {
            assign: assign,
            medianPriceJorPorAcceptors: saveJorPorAcceptors,
            documentId: docId,
          };

          const response = await MedianPriceService.updateJorPorAcceptorAsync(data.id, updateDataJorPorAcceptorsModel);
          if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.CREATED) {
            if (assign) {
              toast.success('มอบหมายสำเร็จ');
            } else {
              toast.success('บันทึกข้อมูลสำเร็จ');
            }
          }
          getMedianPriceByIdAsynce(data.id);
          onCheckApproverStatus();
          setShowWaitingForAssign(false)
          return;
        }

        if (data.status === MedianPriceStatus.DraftSectionApprover && (data.isJorPorComment && !jorPorComment)) {
          toast.warn('กรุณาระบุความเห็น จพ.');
          return;
        }

        if ((data.isJorPorComment && (!assignees || assignees.length === 0 || !responsibles || responsibles.length === 0)) || (!approverUsers || approverUsers.length === 0)) {
          toast.warn('กรุณาเลือกผู้มีอำนาจเห็นชอบ/อนุมัติ');
          return;
        }

        sections?.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((section) => {

            approverUsers?.filter((x) => x.sectionInRefCode === section.inRefCode)
              .forEach((data, index) => {
                const newData: AcceptorModel = {
                  id: data.id,
                  sectionSequence: section.sectionSequence,
                  userId: data.userId,
                  sequence: index + 1,
                  fullName: data.fullName,
                  positionName: data.positionName,
                  acceptDate: data.acceptDate,
                  isAvailable: data.isAvailable,
                  acceptRemark: data.acceptRemark,
                  departmentName: data.departmentName,
                  status: data.status,
                  sectionInRefCode: data.sectionInRefCode,
                  sectionName: data.sectionName,
                  rejectDate: data.rejectDate,
                  delegateAnnotation: data.delegateAnnotation,
                  delegateDepartmentName: data.delegateDepartmentName,
                  delegateEmployeeCode: data.delegateEmployeeCode,
                  delegateFullName: data.delegateFullName,
                  delegatePositionName: data.delegatePositionName,
                  delegateUserId: data.delegateUserId,
                  acceptUserId: data.acceptUserId,
                  isAvailableRemark: data.isAvailableRemark,
                  medianPriceId: data.medianPriceId,
                  rejectRemark: data.rejectRemark,
                  rejectUserId: data.rejectUserId,
                  seqKey: data.seqKey,
                  userApprovedBy: data.userApprovedBy,
                  userRejectedBy: data.userRejectedBy,
                };

                saveApprovers.push(newData);
              });
          });

        const updateDataModel: UpdateMedianPriceAcceptorModel = {
          medianPriceAcceptors: saveApprovers,
          jorPorComment: jorPorComment,
          documentId: docId,
        };

        const res = await MedianPriceService.updateAcceptorModelAsync(data.id, updateDataModel);

        if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
          if (sendToApprove) {

            const res = await MedianPriceService.sendAcceptorToApproveModelAsync(data.id, docId);
            if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
              toast.success('ส่งอนุมัติสำเร็จ');
            } else {
              toast.error('ส่งอนุมัติไม่สำเร็จ');
            }

          } else {
            toast.success('บันทึกข้อมูลสำเร็จ');
          }

          getMedianPriceByIdAsynce(data.id);
          onCheckApproverStatus();

        } else {
          toast.error(res.statusText);
        }

        setShowWaitingForApprove(false)
      }, 500);
    }
  };

  const onSubmitCommitteeAsync = async () => {
    submitForm();

    if (data.id) {
      const updateCommitteeModels: UpdateIsAvailableCommitteeMedianPriceModel[] = [];
      const saveApprovers: AcceptorModel[] = [];

      data.committees.forEach((data) => {
        if (data.id) {
          const newData: UpdateIsAvailableCommitteeMedianPriceModel = {
            id: data.id,
            isAvailable: data.isAvailable,
            isAvailableRemark: data.isAvailableRemark,
          };

          updateCommitteeModels.push(newData);
        }
      });

      sections?.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((section) => {

          approverUsers?.filter((x) => x.sectionSequence === section.sectionSequence)
            .forEach((data, index) => {
              const newData: AcceptorModel = {
                id: data.id,
                sectionSequence: section.sectionSequence,
                userId: data.userId,
                sequence: index + 1,
                fullName: data.fullName,
                positionName: data.positionName,
                acceptDate: data.acceptDate,
                isAvailable: data.isAvailable,
                acceptRemark: data.acceptRemark,
                departmentName: data.departmentName,
                status: data.status,
                sectionInRefCode: data.sectionInRefCode,
                sectionName: data.sectionName,
                rejectDate: data.rejectDate,
                delegateAnnotation: data.delegateAnnotation,
                delegateDepartmentName: data.delegateDepartmentName,
                delegateEmployeeCode: data.delegateEmployeeCode,
                delegateFullName: data.delegateFullName,
                delegatePositionName: data.delegatePositionName,
                delegateUserId: data.delegateUserId,
                acceptUserId: data.acceptUserId,
                isAvailableRemark: data.isAvailableRemark,
                medianPriceId: data.medianPriceId,
                rejectRemark: data.rejectRemark,
                rejectUserId: data.rejectUserId,
                seqKey: data.seqKey,
                userApprovedBy: data.userApprovedBy,
                userRejectedBy: data.userRejectedBy,
              };

              saveApprovers.push(newData);
            });
        });

      const updateDataModel: UpdateIsAvailableCommitteeMedianPriceModels = {
        medianPriceCommittees: updateCommitteeModels,
        medianPriceAcceptors: saveApprovers,
        documentId: docId
      };

      await MedianPriceService.updateIsAvailableCommitteeModelAsync(data.id, updateDataModel);
    }
  };

  const getMedianPriceByIdAsynce = async (id: string) => {
    const response = await MedianPriceService.getMedianPriceByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
    }
  };

  const onApproved = async () => {
    submitForm();

    if (data.id) {
      const response = await MedianPriceService.acceptorApproveModelAsync(data.id, approveRemark);

      if (response.status === HttpStatusCode.OK) {
        toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);

        getMedianPriceByIdAsynce(data.id);

      }
    }

    setShowApprove(false);
  };

  const onReject = async () => {
    submitForm();

    if (rejectRemark === null || rejectRemark === '') {
      return toast.error('กรุณากรอกเหตุผลส่งกลับแก้ไข');
    }

    if (data.status === MedianPriceStatus.WaitingForJorPorDirector) {
      const response = await MedianPriceService.jorPorDirectorRejectedAsync(data.id, rejectRemark);

      if (response.status !== HttpStatusCode.OK) {
        toast.warn('ส่งกลับแก้ไขไม่สำเร็จ');
        return;
      }
    } else {
      const response = await MedianPriceService.acceptorRejectModelAsync(data.id, rejectRemark);

      if (response.status !== HttpStatusCode.OK) {
        toast.warn('ส่งกลับแก้ไขไม่สำเร็จ');
        return;
      }
    }

    await getMedianPriceByIdAsynce(data.id);
    toast.success('ส่งกลับแก้ไขสำเร็จ');
    setShowReject(false);
    window.location.reload();
  };

  const onCancelingOrEditing = async (type: PreProcumentFlag) => {
    submitForm();
    if (!remark || remark.length === 0) {
      return;
    }

    if (type === PreProcumentFlag.Cancel) {
      const res = await MedianPriceService.cancelMedianPriceModelAsync(data.id, remark);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        const newId: string = res.data;
        await getMedianPriceByIdAsynce(newId);
        toast.success('บันทึกขอยกเลิกสำเร็จ');
        navigate(`/preprocurement-median-price/detail/${newId}`);
      } else {
        toast.warn('บันทึกขอยกเลิกไม่สำเร็จ');
      }
    } else if (type === PreProcumentFlag.Editing) {
      const res = await MedianPriceService.changeMedianPriceModelAsync(data.id, remark);
      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        const newId: string = res.data;
        await getMedianPriceByIdAsynce(newId);
        toast.success('บันทึกขอแก้ไขสำเร็จ');
        navigate(`/preprocurement-median-price/detail/${newId}`);
      } else {
        toast.warn('บันทึกขอแก้ไขไม่สำเร็จ');
      }
    }

    setShowEditModal(false);
    setShowCancelModal(false);
  };

  const handlerSetDefaultApproverAsync = async () => {
    const approver: MedianPriceAcceptorModel[] = [];
    const sectionDataGroup: sectionGroup[] = [];

    setApproverUsers(approver);
    setSections(sectionDataGroup);
    setDefaultApproverAsync();
  }

  const setDefaultApproverAsync = async () => {
    const processType = data.isConsumableMaterials ? PROCESSTYPE.ApproveMedianProcessStock : PROCESSTYPE.ApproveMedianProcessNonStock;

    const response = await account
      .getDefaultApproverByTopLeve(processType, data.isJorPorComment ? TorBudgetConstant.OneHundredMillion : data.financialAmount, data.appointPreProcurement.supplyMethod, data.appointPreProcurement.ignorePurchaseOnTable159, departmentCode);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: MedianPriceAcceptorModel[] = [];
      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
        sectionDataGroup.push({
          sectionName: item.sectionName,
          level: item.sectionLevel,
          sectionSequence: item.sectionSequence,
          inRefCode: item.inRefCode,
          sectionNameTh: item.sectionName,
          sectionId: item.sectionId,
        } as sectionGroup);

        approver.push({
          sectionSequence: item.sectionSequence,
          sectionName: item.sectionName,
          sequence: item.sequence,
          userId: item.userId,
          fullName: item.fullName,
          status: AcceptorStatus.DRAFT,
          positionName: item.positionName,
          departmentName: item.departmentName,
          sectionInRefCode: item.inRefCode,
          delegateFullName: item.delegateFullName,
          delegateDepartmentName: item.delegateDepartmentName,
          delegateEmployeeCode: item.delegateEmployeeCode,
          delegatePositionName: item.delegatePositionName,
          delegateUserId: item.delegateUserId,
          delegateDateRange: item.delegateDateRange,
        } as MedianPriceAcceptorModel);
      });

      const uniqueNames = sectionDataGroup
        .filter((data, index) => sectionDataGroup
          .findIndex((d) => d.inRefCode === data.inRefCode) === index);

      let inx = 0;
      let sectionSeq = 0;

      approver?.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
        if (sectionSeq !== item.sectionSequence) {
          inx = 1;
          sectionSeq = item.sectionSequence;
        }

        approver[index].sequence = inx;

        inx++;
      });

      setApproverUsers(approver);
      setSections(uniqueNames);
      setShowDefaultApproverModal(false);
      toast.success('กำหนดผู้มีอำนาจเห็นชอบตั้งต้น สำเร็จ');
    } else {
      toast.warn('กำหนดผู้มีอำนาจเห็นชอบตั้งต้น ไม่สำเร็จ');
    }
  };

  const handlerRemoveAssignee = (index: number) => {
    const temp: MedianPriceAcceptorModel[] = [...assignees];
    temp.splice(index, 1);
    setAssignees(temp);
  };

  const handlerRemoveResponsible = (index: number) => {
    const temp: MedianPriceAcceptorModel[] = [...responsibles];
    temp.splice(index, 1);
    setResponsibles(temp);
  };

  const onSelectAssignee = (empId: string, name: string, position: string, department: string, sectionSequence: number, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
    delegatePositionName?: string, delegateAnnotation?: string, delegateDateRange?: string) => {
    if (assignees && assignees.filter(x => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผอ.จพ. มอบหมายซ้ำ');
    } else {

      const newData: MedianPriceAcceptorModel = {
        id: undefined,
        fullName: name,
        userId: empId,
        departmentName: department,
        positionName: position,
        sectionSequence: sectionSequence,
        sequence: assignees.length + 1,
        status: MedianPriceStatus.Pending,
        sectionName: '',
        seqKey: `${sectionSequence?.toString()}${(assignees.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        delegateFullName: delegateFullName,
        delegateUserId: delegateUserId,
        delegatePositionName: delegatePositionName,
        delegateAnnotation: delegateAnnotation,
        delegateDateRange: delegateDateRange,
        delegateDepartmentName: delegateDepartmentName,
        delegateEmployeeCode: delegateEmployeeCode,
        acceptRemark: '',
        acceptUserId: '',
        isAvailable: false,
        isAvailableRemark: '',
        medianPriceId: '',
        positionOnBoardId: '',
        positionOnBoardName: '',
        rejectRemark: '',
        rejectUserId: '',
        sectionInRefCode: sectionInRefCode.Assignee,
        userApprovedBy: '',
        userRejectedBy: '',
        acceptDate: undefined,
        rejectDate: undefined,
        isChairman: undefined,
      };

      setAssignees((assignees) => [...assignees, newData]);
    }
  };

  const onSelectResponsible = (empId: string, name: string, position: string, department: string, sectionSequence: number, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
    delegatePositionName?: string, delegateAnnotation?: string, delegateDateRange?: string) => {
    if (responsibles && responsibles.filter(x => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้รับผิดชอบซ้ำ');
    } else {

      const newData: MedianPriceAcceptorModel = {
        id: undefined,
        fullName: name,
        userId: empId,
        departmentName: department,
        positionName: position,
        sectionSequence: sectionSequence,
        sequence: data.jorPorAcceptors.length + 1,
        status: MedianPriceStatus.Pending,
        sectionName: '',
        seqKey: `${sectionSequence?.toString()}${(responsibles.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        delegateFullName: delegateFullName,
        delegateUserId: delegateUserId,
        delegatePositionName: delegatePositionName,
        delegateAnnotation: delegateAnnotation,
        delegateDateRange: delegateDateRange,
        delegateDepartmentName: delegateDepartmentName,
        delegateEmployeeCode: delegateEmployeeCode,
        acceptRemark: '',
        acceptUserId: '',
        isAvailable: false,
        isAvailableRemark: '',
        medianPriceId: '',
        rejectRemark: '',
        rejectUserId: '',
        sectionInRefCode: sectionInRefCode.Responsible,
        userApprovedBy: '',
        userRejectedBy: '',
        acceptDate: undefined,
        rejectDate: undefined,
        isChairman: undefined,
      };

      setResponsibles((responsibles) => [...responsibles, newData]);
    }
  };

  const onRecall = async () => {
    const res = await MedianPriceService.onRecallAsync(data.id);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('เรียกคืนแก้ไขไม่สำเร็จ');
      return;
    }

    await getMedianPriceByIdAsynce(data.id);
    toast.success('เรียกคืนแก้ไขสำเร็จ');
    navigate(`/preprocurement-median-price/detail/${data.id}`);
    setShowRecall(false);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        {/* Title */}
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='กำหนดราคากลาง'
              className='fs-5 text-primary' />
          </div>
          <div className='d-flex align-items-center'>
            {
              data.isChanging && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-warning' /></div>
                    {TorEditCancelStatusConstant.Editing}</span>
                </div>
              )
            }
            {
              data.isCanceling && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-danger' /></div>
                    {TorEditCancelStatusConstant.Canceling}</span>
                </div>
              )
            }
            <StatusMedianPrice
              value={data.status}
            />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              className='ms-2'
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
      </Card>

      {
        ((data.status === MedianPriceStatus.CommitteeApproved || data.status === MedianPriceStatus.WaitingForAssignResponsible || data.status === MedianPriceStatus.DraftSectionApprover || data.status === MedianPriceStatus.WaitingForApprove || data.status === MedianPriceStatus.Approved || data.status === MedianPriceStatus.Rejected || data.status === MedianPriceStatus.Cancelled || data.status === MedianPriceStatus.WaitingForJorPorDirector) && data.isJorPorComment) && (
          <>
            <Card className='mt-3'>
              <div className='d-flex mb-4 align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Title text='ผอ.จพ.'
                    className='fs-5 text-primary' />
                  {openCollapse1 ? (
                    <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
                  ) : (
                    <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />)}
                </div>
              </div>
              <Collapse in={openCollapse1}
                className='mt-3'>
                <div>
                  <div>
                    <Table
                      hidePagination
                      className='mt-3 step-bar'
                      notResponse
                    >
                      <thead>
                        <tr>
                          <th style={{ width: '5%' }}>ลำดับ</th>
                          <th className='text-start'
                            style={{ width: '30%' }}>ชื่อ-นามสกุล
                          </th>
                          <th className='text-start'
                            style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                          </th>
                          <th style={{ minHeight: 100, width: '25%' }} />
                        </tr>
                      </thead>
                      <tbody>
                        {data.jorPorAcceptors?.filter(x => x.sectionInRefCode === sectionInRefCode.JorPorDirector).sort((a, b) => a.sequence - b.sequence)
                          .map((data, i) => (
                            <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                              key={data.id}>
                              <td className='text-center'>{i + 1}</td>
                              <td>
                                {data.fullName}
                                <p className='m-0 department'>
                                  {data.positionName}
                                </p>
                              </td>
                              <td className='text-left'>
                                {data.delegateFullName &&
                                  <>
                                    {data.delegateFullName}
                                    <p className='m-0 department'>
                                      {data.delegatePositionName}
                                    </p>
                                  </>
                                }
                              </td>
                              <td />
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Collapse>
            </Card>
            <Card className='mt-3'>
              <div className='d-flex mb-4 align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Title text='ผอ.จพ. มอบหมาย'
                    className='fs-5 text-primary' />
                  {openCollapse2 ? (
                    <ArrowCollapse onClick={() => setOpenCollapse2(!openCollapse2)} openCollapse={openCollapse2} />
                  ) : (
                    <ArrowCollapse onClick={() => setOpenCollapse2(true)} openCollapse={openCollapse2} />)}
                </div>
              </div>
              <Collapse in={openCollapse2}
                className='mt-3'>
                <div>
                  <div>
                    <div className='d-flex justify-content-end'>
                      {
                        (data.status === MedianPriceStatus.WaitingForJorPorDirector && isJorPorDirector) && (
                          <Button
                            className='d-flex align-items-center gap-2'
                            variant='outline-primary'
                            onClick={() => setShowAssigneeModal(true)}
                            disabled={isDisabled}
                          >
                            <FaPlus />เพิ่มรายชื่อ
                          </Button>
                        )
                      }
                    </div>
                    <Table
                      hidePagination
                      className='mt-3 step-bar'
                      notResponse
                    >
                      <thead>
                        <tr>
                          <th style={{ width: '5%' }}>ลำดับ</th>
                          <th className='text-start'
                            style={{ width: '30%' }}>ชื่อ-นามสกุล
                          </th>
                          <th className='text-start'
                            style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                          </th>
                          <th style={{ minHeight: 100, width: '25%' }} />
                        </tr>
                      </thead>
                      <tbody>
                        {assignees?.sort((a, b) => a.sequence - b.sequence)
                          .map((item, i) => (
                            <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                              key={item.id}>
                              <td className='text-center'>{i + 1}</td>
                              <td>
                                {item.fullName}
                                <p className='m-0 department text-wrap'>
                                  {item.positionName}
                                </p>
                              </td>
                              <td className='text-left'>
                                {item.delegateFullName &&
                                  <>
                                    {item.delegateFullName}
                                    <p className='m-0 department text-wrap'>
                                      {item.delegatePositionName}
                                    </p>
                                  </>
                                }
                              </td>
                              <td className='text-end'>
                                {
                                  (data.status === MedianPriceStatus.WaitingForJorPorDirector && isJorPorDirector) && (
                                    <Button
                                      variant='danger'
                                      onClick={() => handlerRemoveAssignee(i)}
                                      disabled={isDisabled}
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  )
                                }
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Collapse>
            </Card>
            {
              (data.status === MedianPriceStatus.WaitingForAssignResponsible || data.status === MedianPriceStatus.DraftSectionApprover || data.status === MedianPriceStatus.WaitingForApprove || data.status === MedianPriceStatus.Approved || data.status === MedianPriceStatus.Rejected || data.status === MedianPriceStatus.Cancelled) && (
                <>
                  <Card className='mt-3'>
                    <>
                      <div className='d-flex mb-4 align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                          <Title text='ผู้รับผิดชอบ'
                            className='fs-5 text-primary' />
                          {openCollapse3 ? (
                            <ArrowCollapse onClick={() => setOpenCollapse3(!openCollapse3)} openCollapse={openCollapse3} />
                          ) : (
                            <ArrowCollapse onClick={() => setOpenCollapse3(true)} openCollapse={openCollapse3} />)}
                        </div>
                      </div>
                      <Collapse in={openCollapse3}
                        className='mt-3'>
                        <div>
                          <div>
                            <div className='d-flex justify-content-end'>
                              {((data.status === MedianPriceStatus.WaitingForAssignResponsible && isAssignee)) && (
                                <Button
                                  className='d-flex align-items-center gap-2'
                                  variant='outline-primary'
                                  onClick={() => setShowResponsibleModal(true)}
                                >
                                  <FaPlus />เพิ่มรายชื่อ
                                </Button>
                              )}
                            </div>
                            <Table
                              hidePagination
                              className='mt-3 step-bar'
                              notResponse
                            >
                              <thead>
                                <tr>
                                  <th style={{ width: '5%' }}>ลำดับ</th>
                                  <th className='text-start'
                                    style={{ width: '30%' }}>ชื่อ-นามสกุล
                                  </th>
                                  <th className='text-start'
                                    style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                                  </th>
                                  <th style={{ minHeight: 100, width: '25%' }} />
                                </tr>
                              </thead>
                              <tbody>
                                {responsibles?.sort((a, b) => a.sequence - b.sequence)
                                  .map((item, i) => (
                                    <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                                      key={item.id}>
                                      <td className='text-center'>{i + 1}</td>
                                      <td>
                                        {item.fullName}
                                        <p className='m-0 department'>
                                          {item.positionName}
                                        </p>
                                      </td>
                                      <td className='text-left'>
                                        {item.delegateFullName &&
                                          <>
                                            {item.delegateFullName}
                                            <p className='m-0 department'>
                                              {item.delegatePositionName}
                                            </p>
                                          </>
                                        }
                                      </td>
                                      <td className='text-end'>
                                        {(isAssignee && data.status === MedianPriceStatus.WaitingForAssignResponsible) && (
                                          <Button
                                            variant='danger'
                                            onClick={() => handlerRemoveResponsible(i)}
                                          >
                                            <FaTrashAlt />
                                          </Button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Collapse>
                    </>
                  </Card>
                  <Card className='mt-3'>
                    <div className='d-flex mb-4 align-items-center justify-content-between'>
                      <div className='d-flex align-items-center'>
                        <Title text='ความเห็น จพ.'
                          className='fs-5 text-primary' />
                        {openCollapse4 ? (
                          <ArrowCollapse onClick={() => setOpenCollapse4(!openCollapse4)} openCollapse={openCollapse4} />
                        ) : (
                          <ArrowCollapse onClick={() => setOpenCollapse4(true)} openCollapse={openCollapse4} />)}
                      </div>
                    </div>
                    <Collapse in={openCollapse4}
                      className='mt-3'>
                      <div>
                        <Row className='justify-content-center'>
                          <Col sm={12}
                            lg={12}>
                            <InputTextArea
                              label='ความเห็นเพิ่มเติม'
                              value={jorPorComment}
                              onChange={val => setJorPorComment(val)}
                              rule={{ required: true }}
                              disabled={isDisabled || data.status !== MedianPriceStatus.DraftSectionApprover || !isResponsible} />
                          </Col>
                        </Row>
                      </div>
                    </Collapse>
                  </Card>
                </>
              )
            }
          </>
        )
      }
      {
        ((data.status === MedianPriceStatus.CommitteeApproved) || (data.status === MedianPriceStatus.DraftSectionApprover || data.status === MedianPriceStatus.WaitingForApprove || data.status === MedianPriceStatus.Approved || data.status === MedianPriceStatus.Rejected || data.status === MedianPriceStatus.Cancelled || data.status === MedianPriceStatus.CommitteeRejected || data.status === MedianPriceStatus.WaitingForCommitteeApprove)) && (
          <Card className='mt-3'>
            <div className='d-flex mb-4 align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <Title text='ผู้มีอำนาจเห็นชอบ/อนุมัติ'
                  className='fs-5 text-primary' />
                {openCollapse1 ? (
                  <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
                ) : (
                  <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />)}
              </div>
              {/* <div className='d-flex align-items-center'>
            {
              data.isChanging && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-warning' /></div>
                    {TorEditCancelStatusConstant.Editing}</span>
                </div>
              )
            }
            {
              data.isCanceling && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-danger' /></div>
                    {TorEditCancelStatusConstant.Canceling}</span>
                </div>
              )
            }
            <StatusMedianPrice systemStatue={data.status}
              systemName='cm-03' />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              className='ms-2'
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div> */}
            </div>
            <Collapse in={openCollapse1}>
              <Row className='gap-5'>
                <div>
                  {data.status !== MedianPriceStatus.WaitingForApprove
                    && data.status !== MedianPriceStatus.Approved
                    ? (
                      <Button
                        className='d-flex align-items-center gap-2'
                        variant='outline-primary'
                        onClick={() => {
                          setShowDefaultApproverModal(true);
                        }}
                      >
                        <FaRedo />กำหนดผู้มีอำนาจเห็นชอบเริ่มต้น
                      </Button>
                    ) : null}
                </div>
                {
                  sections.length > 0 ? (
                    sections
                      .sort((a, b) => a.sectionSequence - b.sectionSequence)
                      .map((section, index) => (
                        <Col sm={12}
                          key={index}>
                          {!isDisabled && (
                            <div className='d-flex justify-content-between mb-1'>
                              <p className='fs-5 text-primary'>
                                {/* {section.sectionName} */}
                              </p>
                              <Button
                                variant='outline-primary'
                                onClick={() => onOpenModal(index)}
                              >
                                <FaPlus className='me-1' /> เพิ่มรายชื่อ
                              </Button>
                            </div>
                          )}
                          <Table total={1}
                            className="mt-3 step-bar"
                            hidePagination>
                            <thead>
                              <tr>
                                <th style={{ width: '5%' }}>ลำดับ</th>
                                <th className='text-start'
                                  style={{ width: '30%' }}>ชื่อ-นามสกุล
                                </th>
                                <th className='text-start'
                                  style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                                </th>
                                <th style={{ width: '10%' }}>สถานะ</th>
                                <th style={{ width: '10%' }}>วันที่เห็นชอบ</th>
                                <th style={{ minHeight: 100, width: '5%' }} />
                              </tr>
                            </thead>
                            <tbody>
                              {approverUsers.filter(a => a.sectionInRefCode === section.inRefCode)
                                .sort((a, b) => a.sequence - b.sequence)
                                .map((data, index) => (
                                  <>
                                    <tr key={data.sequence}>
                                      <td className='text-center'>{index + 1}</td>
                                      <td>
                                        {data.fullName}
                                        <p className='m-0 department'>
                                          {data.positionName}
                                        </p>
                                      </td>
                                      <td className='text-left'>
                                        {data.delegateFullName &&
                                          <>
                                            {data.delegateFullName}
                                            <p className='m-0 department'>
                                              {data.delegatePositionName}
                                            </p>
                                          </>
                                        }
                                      </td>
                                      <td className='text-center'>
                                        {isSectionSequence === data.sectionSequence && isApproverStatus === index + 1
                                          ? <Approver value={data.status}
                                            outline />
                                          : <ApproverStatus value={data.status}
                                            outline />}
                                      </td>
                                      <td className='text-center'>
                                        {fullDatetime(data.acceptDate!)}
                                      </td>
                                      <td className='text-end'>
                                        <Button
                                          variant='danger'
                                          disabled={isDisabled}
                                          onClick={() => removeApprover(`${data.sectionSequence}${data.sequence}`)}

                                        >
                                          <FaTrashAlt />
                                        </Button>
                                      </td>
                                    </tr>
                                    {
                                      data.delegateDateRange || data.acceptRemark || data.rejectRemark ?
                                        <tr className='border-bottom bg-opacity-10 bg-light'>
                                          <td />
                                          <td colSpan={5}
                                            className='p-0'>
                                            <ul className='step-bar-list'>
                                              {data.delegateDateRange &&
                                                <li>
                                                  <div className='description'>
                                                    <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                                    <p className='mb-0'
                                                      style={{ color: 'gray' }}>
                                                      {data.delegateDateRange}
                                                    </p>
                                                  </div>
                                                </li>
                                              }
                                              {data.acceptRemark &&
                                                <>
                                                  <li>
                                                    <div className='description'>
                                                      <p className='mb-0 text-primary'>หมายเหตุเห็นชอบ</p>
                                                      <p className='mb-0 text-wrap'
                                                        style={{ color: 'gray' }}>
                                                        <span className='text-muted me-2'>[{fullDatetime(data.acceptDate ?? '')} ]</span>
                                                        {data.acceptRemark}
                                                      </p>
                                                    </div>
                                                  </li>
                                                </>
                                              }
                                              {data.rejectRemark &&
                                                <li>
                                                  <div className='description'>
                                                    <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                                    <p className='mb-0 text-wrap'
                                                      style={{ color: 'gray' }}>
                                                      <span className='text-muted me-2'>[{fullDatetime(data.rejectDate ?? '')} ]</span>
                                                      {data.rejectRemark}
                                                    </p>
                                                  </div>
                                                </li>
                                              }
                                            </ul>
                                          </td>
                                        </tr>
                                        : <tr className='border-bottom' />
                                    }
                                  </>
                                ))}
                            </tbody>
                          </Table>
                          {/* <UserApproveModal
                        show={showModals[index]}
                        onHide={() => onHideModal(index)}
                        onSelectItem={onSelectEmpApprover}
                        sectionSequence={section.sectionSequence}
                        sectionName={section.sectionName}
                        inRefCode={section.inRefCode}
                      /> */}
                          <SearchBuNameModal
                            total={userTotal}
                            show={showModals[index]}
                            onHide={() => onHideModal(index)}
                            onSelect={(id, name, department, position) => onSelectEmpApprover(id, name, position!, department!, index, section.sectionSequence, section.sectionName, section.inRefCode)}
                            data={users}
                            onSearch={onSearchUserAsync}
                            onPageChange={onPageChangeAsync}
                            departmentCode={departmentCode}
                            departmentDisabled={departmentCode !== JorPorCode.CODE}
                          />
                        </Col>
                      ))
                  ) : (
                    <Col>
                      <span className='text-primary'>ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
                    </Col>
                  )
                }
              </Row>
            </Collapse>
          </Card>
        )
      }
      <Card className='mt-3'>
        <Title text='ตัวอย่างเอกสาร'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}>
            <div className='mt-3'>
              <Collabora
                docId={docId}
                docName='doc-step-3'
                ref={collaboraRef}
                readonly={isDisabledProcess}
                showRemark={true} />
            </div>
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              id={data.id}
              disabled={!data.id}
            />
          </Col>
        </Row>
      </Card>
      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          onClick={onClickBack}
          variant='outline-primary'
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          {/* {
            (isApprover && data.status === TorStatusConstant.WaitingForApprove) && (
              <Button
                className='me-2 px-3'
                onClick={() => setShowReject(true)}
                variant='danger'
              >
                <FaUndo className='mx-2 pb-1 fs-5' />
                ส่งกลับแก้ไข
              </Button>
            )
          } */}
          {
            ((isCommittee && (data.status === MedianPriceStatus.Draft || data.status === MedianPriceStatus.Rejected || data.status === MedianPriceStatus.CommitteeApproved) && !data.isJorPorComment) || (data.status === MedianPriceStatus.WaitingForJorPorDirector && isJorPorDirector)
              || (data.status === MedianPriceStatus.WaitingForAssignResponsible && isAssignee)
              || (data.status === MedianPriceStatus.DraftSectionApprover && isResponsible)) && (
              <>
                <Button
                  onClick={() => onSubmitAsync(false, false)}
                  className='me-2 px-3'
                  variant='success'
                >
                  <FaSave className='me-2 pb-1 fs-5' />
                  บันทึก
                </Button>
              </>
            )
          }
          {
            ((data.status === MedianPriceStatus.WaitingForJorPorDirector && isJorPorDirector)) && (
              <ButtonCustom
                text="ส่งกลับแก้ไข"
                icon='undo'
                className='me-2'
                variant='danger'
                onClick={() => setShowReject(true)}
              />
            )
          }
          {
            ((data.status === MedianPriceStatus.WaitingForJorPorDirector && isJorPorDirector)
              || (data.status === MedianPriceStatus.WaitingForAssignResponsible && isAssignee)) && (
              <Button
                className='me-2 px-3'
                onClick={() => setShowWaitingForAssign(true)}
                variant='primary'
              >
                <FaRegCheckCircle className='me-2 pb-1 fs-5' />
                มอบหมาย
              </Button>
            )
          }
          {
            ((isCommittee && (data.status === MedianPriceStatus.CommitteeApproved && !data.isJorPorComment))
              || ((data.status === MedianPriceStatus.DraftSectionApprover && isResponsible) && data.isJorPorComment)) && (
              <>
                <Button
                  className='me-2 px-3'
                  onClick={() => setShowWaitingForApprove(true)}
                  variant='primary'
                >
                  <FaCheckCircle className='me-2 pb-1 fs-5' />
                  ส่งอนุมัติ/เห็นชอบ
                </Button>
              </>
            )
          }
          {(data.status === MedianPriceStatus.WaitingForApprove && isRecall && isCommittee) && (
            <ButtonCustom
              onClick={() => setShowRecall(true)}
              variant='danger'
              icon="undo"
              text="เรียกคืนแก้ไข"
            />
          )}
          {
            (isQueueApprover && data.status === MedianPriceStatus.WaitingForApprove) && (
              <>
                <ButtonCustom
                  text="ส่งกลับแก้ไข"
                  icon='undo'
                  className='me-2'
                  variant='danger'
                  onClick={() => setShowReject(true)}
                />
                <ButtonCustom
                  variant='success'
                  onClick={() => setShowApprove(true)}
                  icon='checked'
                  text={isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
                />
              </>
            )
          }
          {
            (data.status === MedianPriceStatus.Approved && !data.isCanceling && !data.isChanging) && (
              <>
                <ButtonCustom
                  text="บันทึกขอแก้ไขกำหนดราคากลาง"
                  icon='save'
                  className='me-2'
                  variant='success'
                  onClick={() => setShowEditModal(true)}
                />
                <ButtonCustom
                  variant='danger'
                  onClick={() => setShowCancelModal(true)}
                  icon='cancel'
                  text="บันทึกขอยกเลิกกำหนดราคากลาง"
                />
              </>
            )
          }
        </div>
        <div style={{ width: '190px' }} />
      </div>
      <SearchNameModal
        show={show}
        onHide={() => setShow(!show)}
        data={[]}
      />
      <HistoryModal
        title='ประวัติการใช้งานแบบร่างขอบเขตของงาน (TOR)'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={data.id}
      />
      <Modal
        show={showReject}
        size='lg'
        onHide={() => setShowReject(!showReject)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRejectRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowReject(!showReject)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                variant='danger'
                onClick={onReject}
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showWaitingForApprove}
        size='lg'
        onHide={() => setShowWaitingForApprove(!showWaitingForApprove)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งอนุมัติ/เห็นชอบ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitingForApprove(!showWaitingForApprove)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true, false)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRecall}
        size='lg'
        onHide={() => setShowRecall(!showRecall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowRecall(!showRecall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onRecall}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showWaitingForAssign}
        size='lg'
        onHide={() => setShowWaitingForAssign(!showWaitingForAssign)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการมอบหมายงาน หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitingForAssign(!showWaitingForAssign)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(false, true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showApprove}
        size='lg'
        onHide={() => setShowApprove(!showApprove)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>
                {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                rule={{ required: false }}
                onChange={(val) => setApproveRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApprove(!showApprove)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onApproved}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showEditModal}
        size='lg'
        onHide={() => setShowEditModal(!showEditModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ขอแก้ไขกำหนดราคากลาง</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowEditModal(!showEditModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                onClick={() => onCancelingOrEditing(PreProcumentFlag.Editing)}
              >
                <BsCheckCircle className='mx-2 pb-1 fs-4' />
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showCancelModal}
        size='lg'
        onHide={() => setShowCancelModal(!showCancelModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ขอยกเลิกกำหนดราคากลาง</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowCancelModal(!showCancelModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                onClick={() => onCancelingOrEditing(PreProcumentFlag.Cancel)}
              >
                <BsCheckCircle className='mx-2 pb-1 fs-4' />
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showDefaultApproverModal}
        size='lg'
        onHide={() => setShowDefaultApproverModal(!showDefaultApproverModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการกำหนดผู้มีอำนาจเห็นชอบเริ่มต้นใหม่หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDefaultApproverModal(!showDefaultApproverModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerSetDefaultApproverAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showAssigneeModal}
        onHide={() => setShowAssigneeModal(!showAssigneeModal)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange) => onSelectAssignee(id, name, position!, department!, assignees.length + 1, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <SearchBuNameModal
        total={userTotal}
        show={showResponsibleModal}
        onHide={() => setShowResponsibleModal(!showResponsibleModal)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange) => onSelectResponsible(id, name, position!, department!, responsibles.length + 1, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />


    </div>
  );
}

export default Step3;
