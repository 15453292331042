import { AcceptorParams } from 'models/acceptor';
import http from 'utils/axios';

const GetListsAsync = async (page: number, size: number, paramsData?: AcceptorParams) => {
  const params = {
    page,
    size,
    ...paramsData,
  };
  return await http.get('acceptPlan', { params });
};

const getPlanDetailAsync = async (id: string) => await http.get(`acceptplan/plan/${id}`);

const getChangePlanDetailAsync = async (id: string) => await http.get(`acceptplan/changeplan/${id}`);

const approveAcceptorAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };

  return await http.put(`acceptplan/${id}`, body);
};

const rejectAcceptorAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };

  return await http.put(`acceptplan/reject/${id}`, body);
};

export default {
  GetListsAsync,
  getPlanDetailAsync,
  getChangePlanDetailAsync,
  approveAcceptorAsync,
  rejectAcceptorAsync,
};