import {
  AppointmentStatus,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import { ItemModel } from 'models';
import {
  AppointCommiteeListRequest,
  AppointCommiteeListResponse,
  AppointCommiteeListResponseModel,
} from 'models/PR/PR0202Models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaPlus,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import PR0202Services from 'services/PR/PR0202Services';
import {
  formatMonthAndThaiYearWithSlash,
  THCurrency,
} from 'utils';
import { useForm } from '../../../utils/useForm';

type Loader = { departmentDDL: ItemModel[] };

export default function PR0202() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [formSearch, setFormSearch] = useState<AppointCommiteeListRequest>({} as AppointCommiteeListRequest);
  const [appointCommiteeListData, setAppointCommiteeListData] = useState<AppointCommiteeListResponseModel>({} as AppointCommiteeListResponseModel);
  const { departmentDDL } = useLoaderData() as Loader;

  const searchAppointCommiteeListList = useCallback(async (searchModel: AppointCommiteeListRequest) => {
    if (!searchModel.page) {
      searchModel.page = page;
    }
    if (!searchModel.size) {
      searchModel.size = size;
    }
    const { data, status } = await PR0202Services.getAppointmentProcurementListAsync(searchModel);
    if (status !== 200) {
      return;
    }

    setAppointCommiteeListData(data);
  }, []);

  useEffect(() => {
    searchAppointCommiteeListList({ page, size });
  }, [page, searchAppointCommiteeListList, size]);

  useEffect(() => {
    searchAppointCommiteeListList(formSearch);
  }, [formSearch]);

  return (
    <div className="m01">
      <PR0202Header />
      <Criteria
        setForm={setFormSearch}
        departmentList={departmentDDL}
        page={page}
        size={size}
      />
      <DataTable
        appointCommiteeListData={appointCommiteeListData.data}
        total={appointCommiteeListData.totalCount}
        setPage={setPage}
        setSize={setSize}
      />
    </div>
  );
}

function PR0202Header() {
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">จัดทำคำสั่งแต่งตั้งคณะกรรมาการจัดซื้อจัดจ้างและคณะตรวจรับพัสดุ</h4>
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={() => navigate('detail')}
        >
          <FaPlus />จัดทำคำสั่ง
        </Button>
      </div>
      <hr />
    </>
  );
}

interface FormInput {
  orderNumber: string;
  orderName: string;
  projectName: string;
  departmentId: string;
  procurementCommitee: string;
  purchaseInspector: string;
  responsiblePerson: string;
  status: string;
}

interface CriteriaProps {
  setForm: (form: AppointCommiteeListRequest) => void;
  departmentList: ItemModel[];
  page: number;
  size: number;
}

function Criteria(props: CriteriaProps) {
  const [departmentId, setDepartmentId] = useState('');
  const [status, setStatus] = useState('');
  function clearFormSearch() {
    handleClear();
    setDepartmentId('');
    setStatus('');
  }

  const { handleSubmit, handleChange, data: formData, handleClear } = useForm<FormInput>({
    initialValues: {
      orderNumber: undefined,
      orderName: undefined,
      projectName: undefined,
      departmentId: undefined,
      procurementCommitee: undefined,
      purchaseInspector: undefined,
      responsiblePerson: undefined,
      status: undefined,
    },
    validations: {},
    onSubmit: async () => {

      const searchModel: AppointCommiteeListRequest = {
        orderNumber: formData.orderNumber,
        orderName: formData.orderName,
        projectName: formData.projectName,
        departmentId,
        procurementCommitee: formData.procurementCommitee,
        purchaseInspector: formData.purchaseInspector,
        responsiblePerson: formData.responsiblePerson,
        status,
        page: props.page,
        size: props.size,
      };
      props.setForm(searchModel);
    },
  });

  const statusOptions: ItemModel[] = [
    { value: AppointmentStatus.DRAFT, label: 'ร่าง' },
    { value: AppointmentStatus.PENDING, label: 'รออนุมัติ' },
    { value: AppointmentStatus.APPROVED, label: 'อนุมัติ' },
    { value: AppointmentStatus.REJECTED, label: 'ส่งกลับแก้ไข' },
  ];

  return (
    <Form onSubmit={handleSubmit} id="formPR0202">
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>เลขที่คำสั่ง</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="เลขที่คำสั่ง"
                value={formData.orderNumber}
                onChange={handleChange<string>('orderNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>ชื่อคำสั่ง</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="ชื่อคำสั่ง"
                value={formData.orderName}
                onChange={handleChange<string>('orderName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>โครงการ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="โครงการ"
                value={formData.projectName}
                onChange={handleChange<string>('projectName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>ฝ่าย/สำนัก</Form.Label>
            <InputGroup hasValidation>
              <Selector
                items={props.departmentList}
                value={`${departmentId}`}
                onChange={(value) => (setDepartmentId(value.toString()))}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>ชื่อ-นามสกุลคณะกรรมการจัดจ้าง</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="ชื่อ-นามสกุลคณะกรรมการจัดจ้าง"
                value={formData.procurementCommitee || ''}
                onChange={handleChange<string>('procurementCommitee', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>ชื่อ-นามสกุลคณะกรรมาการตรวจรับพัสดุ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="ชื่อ-นามสกุลคณะกรรมาการตรวจรับพัสดุ"
                value={formData.purchaseInspector}
                onChange={handleChange<string>('purchaseInspector', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>ผู้จัดทำ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="ผู้จัดทำ"
                value={formData.responsiblePerson}
                onChange={handleChange<string>('responsiblePerson', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>สถานะ</Form.Label>
            <InputGroup hasValidation>
              <Selector
                items={statusOptions}
                value={`${status}`}
                onChange={(value) => (setStatus(value.toString()))}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <div className="d-flex gap-2">
          <Button form="formPR0202" type="submit" variant="primary" className="d-flex align-items-center gap-2">
            <FaSearch />ค้นหา
          </Button>
          <Button variant="outline-primary" onClick={() => clearFormSearch()} form="formPR0202" type="submit" className="d-flex align-items-center gap-2">
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
    </Form>
  );
}

interface DataTableProps {
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  total: number;
  appointCommiteeListData: AppointCommiteeListResponse[];
}

function DataTable(props: DataTableProps) {
  return (
    <Table className="mt-4">
      <thead>
        <tr className="text-center">
          <th>เลขที่แผน</th>
          <th>เลขที่โครงการ</th>
          <th>ชื่อแผน</th>
          <th>งบประมาณ (บาท)</th>
          <th>วิธีจัดหา</th>
          <th><p>ประมาณการช่วงเวลา<br />
            การจัดซื้อจัดจ้าง
          </p>
          </th>
          <th><p>วันที่<br />
            ประกาศแผน
          </p>
          </th>
          <th>สถานะ</th>
          <th>รายละเอียด</th>
        </tr>
      </thead>
      <tbody>
        {props.appointCommiteeListData?.map((data, index) => (
          <tr className="text-center" key={index}>
            <td className="text-center">{data.planNumber}</td>
            <td>{data.projectNumber}</td>
            <td>{data.planName}</td>
            <td className="text-end">{THCurrency(data.planBudget)}</td>
            <td>{data.supplyMethodTypeValue} : {data.supplyMethodValue}</td>
            <td className="text-center">{formatMonthAndThaiYearWithSlash(data.expectingProcurementAt)}</td>
            <td className="text-center">{formatMonthAndThaiYearWithSlash(data.planAnnouncementDate)}</td>
            <td className="text-center">{Status({ value: data.status, type: StatusType.APPOINTMENT })}</td>
            <td className="text-center">
              <a className="btn-link" href={`pr0202/detail/${data.id}`}>รายละเอียด</a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
