import { Card, Currency, Input, Selector, Table } from 'components';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import { FineRate, TorDetailModel } from 'models/PP/TorModels';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Row,
} from 'react-bootstrap';
import { FaChevronCircleDown, FaChevronCircleUp, FaPlus, FaTrashAlt } from 'react-icons/fa';
import { ValidatePattern } from 'utils';
import { Context } from '../../step';

interface IFineRateComponentProps {
  torData: TorDetailModel;
  onFineRatesChange: (value: FineRate[]) => void;
  periodUnitDDL: ItemModel[];
  isDisabled?: boolean;
}

export function FineRateComponent(props: IFineRateComponentProps) {
  const { readonly } = useContext(Context);
  const [fineRates, setFineRates] = useState<FineRate[]>(props.torData.fineRates);
  const [torData, setTorData] = useState<TorDetailModel>(props.torData);
  const [firstLoad, setFirstLoad] = useState(true);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    props.onFineRatesChange(fineRates);
  }, [fineRates]);

  useEffect(() => {
    if (props.torData.fineRates && firstLoad) {
      setFineRates(props.torData.fineRates);
      setFirstLoad(false);
    }
  }, [props.torData, firstLoad]);

  const handlerOnChangeDescription = (value: string, index: number) => {
    const data = [...fineRates];
    data[index].description = value;
    setFineRates(data);
  };

  const handlerOnChangeFineRatePeriodType = (value: string, index: number) => {
    const data = [...fineRates];
    data[index].fineRatePeriodType = value;
    setFineRates(data);
  };

  const handlerOnChangeFine = (value: number, index: number) => {
    const data = [...fineRates];
    data[index].fine = value;
    setFineRates(data);
  };

  const addFineRate = useCallback(async () => {
    const newData: FineRate = {
      id: undefined,
      torId: torData.id,
      description: '',
      fineRatePeriodType: props.periodUnitDDL[0].value,
      fineRateType: 'percent',
      fine: 0,
      sequence: fineRates.length + 1,
    };

    setFineRates((fineRates) => [...fineRates, newData]);
  }, [fineRates]);

  const removeFineRate = (i: number) => {
    const temp: FineRate[] = [...fineRates];
    temp.splice(i, 1);
    setFineRates(temp);
  };

  return (
    <Card className='mt-3'>
      <div className='d-flex align-items-center'>
        <Title
          text='อัตราค่าปรับ'
          className='fs-5 text-primary'
        />
        {
          collapsed
            ? (
              <FaChevronCircleDown
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(false)}
              />
            ) : (
              <FaChevronCircleUp
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(true)}
              />
            )
        }
      </div>
      <Collapse in={!collapsed} className='ps-40'>
        <div>
          <div className='d-flex align-items-center justify-content-between'>
            <p className='mb-0'>อัตราค่าปรับของรายการพัสดุที่ยังไม่ได้รับมอบ</p>
            <Button
              variant='outline-primary'
              onClick={addFineRate}
              disabled={props.isDisabled || readonly}
            >
              <FaPlus className='me-1' />เพิ่มอัตราค่าปรับ
            </Button>
          </div>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}>
              <Table total={1}
                hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 100 }}>ลำดับ</th>
                    <th>รายละเอียด</th>
                    <th style={{ width: 100 }}>อัตราร้อยละ</th>
                    <th style={{ width: 200 }}>คิดเป็น</th>
                    <th style={{ width: 20 }} />
                  </tr>
                </thead>
                <tbody>
                  {fineRates?.sort((a, b) => a.sequence - b.sequence)
                    .map((data, index) => (
                      <tr key={index}>
                        <td className='text-center' style={{ verticalAlign: 'middle' }}>{index + 1}</td>
                        <td>
                          {
                            props.periodUnitDDL.filter(d => d.value === data.fineRatePeriodType).length > 0 ? (
                              <div className='mb-2'>
                                {`ตัวอย่าง: กำหนดค่าปรับเป็นราย${props.periodUnitDDL.filter(d => d.value === data.fineRatePeriodType)[0].label}ในอัตราร้อยละ ${data.fine} ของ${data.description}`}
                              </div>
                            ) : (
                              <div className='mb-2'>
                                {`ตัวอย่าง: กำหนดค่าปรับเป็นราย..ในอัตราร้อยละ ${data.fine} ของ${data.description}`}
                              </div>
                            )
                          }
                          <div>
                            <Input
                              value={data.description}
                              onChange={(val) => handlerOnChangeDescription(val, index)}
                              rule={{ required: true }}
                              disabled={props.isDisabled || readonly}
                            />
                          </div>
                        </td>
                        <td style={{ verticalAlign: 'bottom' }}>
                          <Currency
                            value={data.fine}
                            onChange={(val) => handlerOnChangeFine(Number(val), index)}
                            rule={{ required: true, pattern: ValidatePattern.PERCENTAGE }}
                            maxValue={100}
                            disabled={props.isDisabled || readonly}
                          />
                        </td>
                        <td style={{ verticalAlign: 'bottom' }}>
                          <Selector
                            items={props.periodUnitDDL}
                            value={data.fineRatePeriodType || ''}
                            onChange={(value) => handlerOnChangeFineRatePeriodType(value, index)}
                            rule={{ required: true }}
                            disabled={props.isDisabled || readonly}
                          />
                        </td>
                        <td className='text-end' style={{ verticalAlign: 'bottom' }}>
                          <Button
                            onClick={() => removeFineRate(index)}
                            variant='danger'
                            disabled={props.isDisabled || readonly}
                          >
                            <FaTrashAlt />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card>
  );
}
