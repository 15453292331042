import {
  Currency,
  Input,
  InputTextArea,
  Modal,
  Table,
} from 'components';
import {
  ContractAgreementPoResponseModel,
  PoHistoryListResponseModel,
  UpdateContractAgreementPoModel,
} from 'models/CM/CM01Models';
import { FinancialModel, UpdateFinancialModel } from 'models/PR/PurchaseOrderApprovalModels';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaRegEye } from 'react-icons/fa';
import {
  MdArrowBack,
  MdSave,
} from 'react-icons/md';
import { useNavigate } from 'react-router';
import CM01Service from 'services/CM/CM01Service';
import PurchaseOrderApprovalServices from 'services/PR/PurchaseOrderApprovalServices';
import {
  GetFormatDateBC,
  GetFormatTimeBC,
  HttpStatusCode,
} from 'utils';
import toast from 'utils/toast';

interface Props {
  show: boolean;
  onHide: () => void;
  contractRecordId?: string;
  onGet: () => void;
  sequence: number;
  data?: any;
  financialId?: string;
  planBudget: number;
  sumFinancialBudget: number;
}

export function FinancialModal(props: Props) {
  const [showComment, setShowComment] = useState(false);
  const [data, setData] = useState<FinancialModel>({} as FinancialModel);
  const navigate = useNavigate();
  const [showRemark, setShowRemark] = useState<boolean[]>([]);

  useEffect(() => {
    if (props.data) {
      if (props.data.detail && props.data.amount) {
        setData({
          ...data,
          detail: props.data.detail,
          amount: props.data.amount,
        })
      }

    }
  }, [props.data]);

  const onSubmitAsync = async () => {

    if ((!props.contractRecordId)) {
      toast.warn('ไม่พบข้อมูล');
      return;
    }

    if ((!data.amount || !data.detail)) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
      return;
    }

    if (!props.financialId) {

      const financial: FinancialModel = {
        contractRecordId: props.contractRecordId!,
        amount: data.amount,
        detail: data.detail,
        sequence: props.sequence + 1,
      };

      const res = await PurchaseOrderApprovalServices.createFinancialAsync(financial);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {

        toast.success('บันทึกข้อมูลสำเร็จ');
      } else {
        toast.error(res.statusText);
      }
    } else {

      const updateFinancial: UpdateFinancialModel = {
        contractRecordId: props.contractRecordId!,
        amount: data.amount,
        detail: data.detail,
        financialId: props.financialId,
      };

      const res = await PurchaseOrderApprovalServices.updateFinancialAsync(updateFinancial);

      if (res.status === HttpStatusCode.OK) {

        toast.success('บันทึกข้อมูลสำเร็จ');
      } else {
        toast.error(res.statusText);
      }
    }

    props.onHide();
    props.onGet();
  };

  const onSetAmount = (val: number) => {

    const budget = props.planBudget - props.sumFinancialBudget;

    if (val > budget) {
      toast.warn('วงเงินต้องไม่เกินวงเงินรวมที่จัดซื้อจัดจ้าง');
      return;
    }

    setData({
      ...data,
      amount: val,
    })
  };

  return (
    <Modal
      show={props.show}
      size='lg'
      onHide={() => props.onHide()}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>วงเงินที่จัดซื้อจัดจ้าง </ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body>
            <form>
              <Row>
                <Col sm={12}
                  md={12}>
                  <InputTextArea label='รายการจัดซื้อจัดจ้าง'
                    placeholder='รายการจัดซื้อจัดจ้าง'
                    value={data.detail}
                    rule={{ required: true }}
                    onChange={val => setData({
                      ...data,
                      detail: val,
                    })} />
                </Col>
                <Col sm={12}
                  md={12}>
                  <Currency label='วงเงิน (บาท)'
                    placeholder='วงเงิน (บาท)'
                    rule={{ required: true }}
                    value={data.amount}
                    maxValue={props.planBudget}
                    onChange={val => onSetAmount(Number(val))}
                  />
                </Col>
                <Col sm={12}
                  className='d-flex justify-content-center gap-3'>
                  <Button
                    variant='success'
                    className='me-2 px-3'
                    onClick={onSubmitAsync}
                  >
                    <MdSave className='mx-2 pb-1 fs-4' />
                    บันทึก
                  </Button>
                </Col>
              </Row>
            </form>
          </ModalBT.Body>
        </>
      )}
    />
  );
}
