import { useState } from 'react';
import {
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap';
import {
  AiFillEye,
  AiFillEyeInvisible,
} from 'react-icons/ai';

interface Props {
  placeholder?: string;
  label?: string;
  type?: string;
  formCustom?: string;
}

function InputPassword({ label, placeholder, type, formCustom }: Props) {
  const [show, setShow] = useState(true);

  return (
    <>
      <Form.Label>{label}</Form.Label>
      <InputGroup className="mb-3 input-password">
        <Form.Control
          type={show ? type : 'text'}
          placeholder={placeholder}
          className={`form--${formCustom}`}
        />
        <Button variant="outline-secondary" onClick={() => setShow(!show)}>
          {show ? <AiFillEyeInvisible className="fs-4" /> : <AiFillEye className="fs-4" />}
        </Button>
      </InputGroup>
    </>
  );
}

export default InputPassword;
