import { MatrixTorPp } from 'models/PP';
import http from 'utils/axios';

const CreateMatrixTorPpAsync = async (body: MatrixTorPp) => await http.post('matrixtorpp', body);

const GetMatrixListAsync = async (
  page: number,
  size: number,
  keyword?: string,
) => {
  const params = {
    page,
    size,
    keyword,
  };
  return await http.get('matrixtorpp', { params });
};

const DeleteMatrixAsync = async (id: string) => await http.delete(`matrixtorpp/${id}`);

const getMatrixDetail = async (id: string | undefined) => await http.get(`matrixtorpp/${id}`);

const updateMatrixAsync = async (body: MatrixTorPp) => await http.put(`/matrixtorpp/${body.id}`, body);

export default {
  CreateMatrixTorPpAsync,
  GetMatrixListAsync,
  DeleteMatrixAsync,
  getMatrixDetail,
  updateMatrixAsync,
};