import { Table } from 'components';
import Title from 'components/Controls/Title';
import { UserAccountModal } from 'components/Modal/UserAccountModal';
import {
  AppointmentDetailModel,
  approverAppointModel,
} from 'models/P/PP0201Model';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Card,
  Form,
} from 'react-bootstrap';
import {
  FaPlus,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { shareValue } from 'services';
import { HttpStatusCode } from 'utils';
import toast from 'utils/toast';
import { useForm } from 'utils/useForm';

interface Props {
  setForm: (form: AppointmentDetailModel) => void;
  appointmentData: AppointmentDetailModel;
  onClickNext: () => void;
  onClickBack: () => void;
  isDisabled?: boolean;
}

interface FormInput {
  committeeTermsOfReferences: [];
}

interface ItemModel {
  label: string;
  value: string;
}

function Step4({ onClickNext, onClickBack, appointmentData, setForm, isDisabled = false }: Props) {
  const [show, setShow] = useState(false);
  const [approvers, setApprovers] = useState<approverAppointModel[]>([]);
  const [posotionOnBoardItems, setPosotionOnBoardItems] = useState<ItemModel[]>([]);

  useEffect(() => {
    getPositionOnBoardAsync();
  }, []);

  useEffect(() => {
    if (appointmentData && appointmentData.approverAppointPreProcurements) {
      setApprovers(appointmentData.approverAppointPreProcurements);
    }
  }, [appointmentData]);

  const { handleSubmit, handleChange, data: formData, errors, initFormData } = useForm<FormInput>({
    initialValues: {
      committeeTermsOfReferences: [],
    },
    validations: {},
    onSubmit: async () => {
      if (!approvers || approvers.length === 0) {
        toast.error('กรุณาเลือกอำนาจอนุมัติ (ผู้ลงนาม)');
        return;
      }

      const appData: AppointmentDetailModel = {
        id: appointmentData.id,
        planId: appointmentData.planId,
        departmentId: appointmentData.departmentId,
        budgetYear: appointmentData.budgetYear,
        planName: appointmentData.planName,
        supplyMethod: appointmentData.supplyMethod,
        supplyMethodType: appointmentData.supplyMethodType,
        supplyMethodSpecialType: appointmentData.supplyMethodSpecialType,
        expectingProcurementAt: appointmentData.expectingProcurementAt!,
        memorandumDate: appointmentData.memorandumDate,
        planBudget: appointmentData.planBudget,
        financialAmount: appointmentData.financialAmount,
        byOrderFrom: appointmentData.byOrderFrom,
        subject: appointmentData.subject,
        status: appointmentData.status,
        createdByUserFullName: '',
        createdInfoAt: new Date(),
        updateByUserFullName: '',
        updatedInfoAt: new Date(),
        appointApprover: appointmentData.appointApprover,
        appointPreProcurementDocuments: appointmentData.appointPreProcurementDocuments,
        committeeTors: appointmentData.committeeTors,
        dutyCommitteeTors: appointmentData.dutyCommitteeTors,
        committeeMiddlePrices: appointmentData.committeeMiddlePrices,
        dutyCommitteeMiddlePrices: appointmentData.dutyCommitteeMiddlePrices,
        approverAppointPreProcurements: approvers,
        documents: appointmentData.documents,
        files: appointmentData.files,
        documentDeletes: appointmentData.documentDeletes,
      };
      setForm(appData);
      if (approvers.length > 0) {
        onClickNext();
      } else {
        alert('กรุณาเลือกคณะกรรมการ และอำนาจเจ้าหน้าที่');
      }
    },
  });

  const onSelectEmp = (empId: string, name: string, position: string) => {
    if (approvers && approvers.filter((x) => x.approverUserId === empId).length > 0) {
      alert('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const newData: approverAppointModel = {
        id: undefined,
        appointPreProcurementId: appointmentData.id,
        approverUserId: empId,
        approveFullName: name,
        position: posotionOnBoardItems[0].label,
        status: '',
        approvedDateAt: new Date(),
      };

      setApprovers((approvers) => [...approvers, newData]);
    }
  };

  const removeCommittees = (i: number) => {
    const temp: approverAppointModel[] = [...approvers];
    temp.splice(i, 1);
    setApprovers(temp);
  };

  const getPositionOnBoardAsync = async () => {
    const { data, status } = await shareValue.getPositionOnBoardListAsync();

    if (status === HttpStatusCode.OK) {
      const positionOnBoards: ItemModel[] = data;
      setPosotionOnBoardItems(positionOnBoards);
    }
  };

  return (
    <div className='document'>
      <Form onSubmit={handleSubmit}
        id='updateForm4'>
        <Card className='mt-3'>
          <Card.Body>
            <Title text='อำนาจอนุมัติ (ผู้ลงนาม)'
              className='fs-5 text-primary' />
            <div className='d-flex justify-content-between my-3'>
              <p>ข้อมูล</p>
              <Button
                variant='outline-primary'
                onClick={() => setShow(true)}
                disabled={isDisabled}
              >
                <FaPlus />เพิ่มข้อมูล
              </Button>
            </div>
            <Table>
              <thead>
              <tr>
                <th style={{ minWidth: 100 }}>ลำดับ</th>
                <th style={{ minWidth: 250 }}>ชื่อ-นามสกุล</th>
                <th style={{ minWidth: 300 }}>ตำแหน่ง</th>
                <th style={{ minWidth: 125 }} />
              </tr>
              </thead>
              <tbody>
              {
                approvers.map((data, index) => (
                  <tr key={index}>
                    <td className='text-center'>{index + 1}</td>
                    <td className='text-center'>{data.approveFullName}</td>
                    <td className='text-center'>{data.position}</td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <Button
                          variant='danger'
                          className='d-flex align-items-center gap-2'
                          onClick={() => removeCommittees(index)}
                          disabled={isDisabled}
                        >
                          <FaTrashAlt />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <div className='d-flex justify-content-between pt-3'>
          <Button
            onClick={onClickBack}
            className='me-2 px-3'
            variant='outline-primary'
          >
            <MdArrowBack className='me-2 pb-1 fs-5' />
            ย้อนกลับ
          </Button>
          <Button
            type='submit'
            form='updateForm4'
            className='me-2 px-3'
          >
            ถัดไป
            <MdArrowForward className='ms-2 pb-1 fs-5' />
          </Button>
        </div>
      </Form>
      <UserAccountModal
        show={show}
        onHide={() => setShow(!show)}
        onSelectItem={onSelectEmp}
      />
    </div>
  );
}

export default Step4;
