import {
  AppointmentStatus,
  Card,
  Check,
  Currency,
  DatePicker,
  DirectiveStatus,
  Input,
  InputTextArea,
  Selector,
  StatusEditType,
  StatusType,
  Table,
  ArrowCollapse,
  ButtonCustom,
  SupplyMethodEnum,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal, SearchBuNameModal } from 'components/Modal';
import {
  ApproverAppointmentStatus,
  PreProcumentEditCancelStatusConstant,
  PreProcumentFlag,
} from 'constant/PreProcumentFlag';
import Cookie from 'cookie-universal';
import {
  IFile,
  ItemModel,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
} from 'models';
import {
  AppointmentDocumentModel,
  approverAppointModel,
  committeeModel,
  CreateAppointmenteditingModel,
  CreatePP0201Model,
  dutyTermsModel,
  UpdateAppointmentDetailModel,
} from 'models/PP/PreProcurementCreateDirectiveModels';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {
  BsCheckCircle,
} from 'react-icons/bs';
import {
  FaCircle,
  FaHistory,
  FaPlus,
  FaSave,
  FaTrashAlt,
  FaUndo,
  FaChevronCircleDown
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router';
import {
  account,
  planProcurement,
  shareValue,
} from 'services';
import PreProcurementServices from 'services/PP/PreProcurementServices';
import {
  HttpStatusCode,
  PositionOnBoardIdEnum,
  submitForm,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { HistoryModal } from './Modal/HistoryModal';
import { Context } from './step';
import UploadFile from './UploadFile';
import { DepartmentId, JorPorCode, ProcurementCommerceId } from 'constant/basicInformation';
import { PlanProcurementModal } from 'components/Modal/PlanProcurementModal';
import { sectionApproverModel } from 'models/delegateModel';
import { SharedvalueGroup, SubGroup } from 'utils/constants/ContractEnum';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  onDisabled?: boolean;
  onHasMiddlePrice?: boolean;
  isVisable?: boolean;
}

type Loader = {
  supplyMethodDDL: ItemModel[],
  supplyMethodTypeDDL: ItemModel[],
  departmentDDL: ItemModel[],
};

// enum positionOnBoard {
//   president = '2c61837a-f048-460f-8747-efb6d62823d1',
// }

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

function Step1({
  onClickNext, onClickBack, onDisabled = false, onHasMiddlePrice = false, isVisable = false,
}: Props) {
  const [showPlan, setShowPlan] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [openCollapse3, setOpenCollapse3] = useState(true);
  const [openCollapse4, setOpenCollapse4] = useState(true);
  const [showCommitteesTors, setShowCommitteesTors] = useState(false);
  const [dutyTermsTors, setDutyTermsTors] = useState<dutyTermsModel[]>([]);
  const [showCommitteesMiddlePrices, setShowCommitteesMiddlePrices] = useState(false);
  const [dutyTermsMiddlePrices, setDutyTermsMiddlePrices] = useState<dutyTermsModel[]>([]);
  const { data, setData, readonly, isCancel } = useContext(Context);
  const [posotionOnBoardItems, setPosotionOnBoardItems] = useState<ItemModel[]>([]);
  const [committeesTors, setCommitteesTors] = useState<committeeModel[]>([]);
  const [committeesMiddlePrices, setCommitteesMiddlePrices] = useState<committeeModel[]>([]);
  const [committeesApprovers, setCommitteesApprovers] = useState<approverAppointModel[]>([]);
  const { supplyMethodDDL, supplyMethodTypeDDL, departmentDDL } = useLoaderData() as Loader;
  const [supplyMethod, setSupplyMethod] = useState('');
  const [supplyMethodType, setSupplyMethodType] = useState('');
  const [supplyMethodSpecialType, setSupplyMethodSpecialType] = useState('');
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);
  const [deptId, setDepartmentId] = useState('');
  const [budgetYear, setBudgetYear] = useState(0);
  const [planName, setPlanName] = useState('');
  const [planId, setPlanId] = useState('');
  const [planNumber, setPlanNumber] = useState('');
  const [planBudget, setplanBudget] = useState(0);
  const [expectingProcurementAt, setExpectingProcurementAt] = useState<Date>();
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const cookies = Cookie();
  const userfullName = cookies.get('fullName') as string;
  const [showRequestEdit, setShowRequestEdit] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [currentPath, setCurrentPath] = useState('preprocurement-directive/detail');
  const [isDisabled, setisDisabled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, departmentCode } = useAppContext();
  const [checkUserId, setCheckUserId] = useState(false);
  const [sourceAndReasons, setSourceAndReasons] = useState('');
  const [memorandumNo, setMemorandumNo] = useState<string>();
  const [memorandumDate, setMemorandumDate] = useState<Date>();
  const [budgetYearItems, setBudgetYearItems] = useState<{ value: string; label: string }[]>([]);
  const [remarkCancelOrChange, setRemarkCancelOrChange] = useState('');
  const [isConsumableMaterials, setIsConsumableMaterials] = useState(Boolean);
  const [checkReCall, setCheckReCall] = useState(false);
  const [showReCall, setShowReCall] = useState(false);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [isDisabledPlan, setisDisabledPlan] = useState(false);
  const [ignorePurchaseOnTable159, setIgnorePurchaseOnTable159] = useState(false);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const handlerRemoveCommitteesTors = (index: number) => {
    const temp: committeeModel[] = [...committeesTors];
    temp.splice(index, 1);
    setCommitteesTors(temp);
  };

  const handlerRemoveCommitteesMiddlePrices = (index: number) => {
    const temp: committeeModel[] = [...committeesMiddlePrices];
    temp.splice(index, 1);
    setCommitteesMiddlePrices(temp);
  };

  enum DefaultName {
    subjectNameTor = 'ขออนุมัติแต่งตั้งคณะกรรมการ/ผู้จัดทำร่างขอบเขตของงาน/คณะกรรมการ/ผู้จัดทำรายละเอียดคุณลักษณะเฉพาะของพัสดุ และกำหนดลักษณะเฉพาะของพัสดุ และกำหนดหลักเกณฑ์การพิจารณาคัดเลือกข้อเสนอ โครงการ',
    subjectNameMiddlePrices = 'ขออนุมัติแต่งตั้งคณะกรรมการ/ผู้จัดทำร่างขอบเขตของงาน/คณะกรรมการ/ผู้จัดทำรายละเอียดคุณลักษณะเฉพาะของพัสดุ และกำหนดลักษณะเฉพาะของพัสดุ และกำหนดหลักเกณฑ์การพิจารณาคัดเลือกข้อเสนอ และผู้กำหนดราคากลาง/คณะกรรมการกำหนดราคากลาง โครงการ',
    sourceAndReasons = 'ตามแผนยุทธศาสตร์ธนาคารปี 2564 - 2568 วัตถุประสงค์เชิงยุทธศาสตร์ (Stratecic Objectives) ยุทธศาสตร์ (Strategic)  กลยุทธ์ (Tactics) ตัวชี้วัด (KPIs) และเป้าหมาย (Targets) SO4: ยกระดับองค์กรให้เติบโตอย่างยั่งยืน ยุทธศาสตร์การยกระดับความรับผิดชอบต่อสังคมและสิ่งแวดล้อมและภาพลักษณ์ องค์กร กลยุทธ์ 4.3 ยกระดับ CSR และภาพลักษณ์องค์กร พร้อมทั้งสนับสนุนโครงทารสำคัญตามแผนยุทธศาสตร์ปี 2565 (Strategic Project) SP 57 : โครงการยกระตับภาพลักษณ์องค์กรสู SUPER BRAND นั้น ฝ่ายสื่อสารองค์กร ซึ่งเป็นผู้ดูแลและวางเผนสื่อโมษณาประชาสัมพันธ์ของธนาคาร จึงมีความประสงค์ ที่จะว่าจ้างบริษัทฯ ดำเนินการผลิตและโฆษณาประชาสัมพันธ์ธนาคาร ผ่านสื่อโทรทัศน์ รายการ 5 เช้าเล่าเรื่อง และรายการ ZAAB TODAY (แซ่บทูเตย์) ซึ่งเป็นรายการวาไรตี้ข่าว สัมภาษณ์บุคคล ตีแผ่ข้อเท็จจริงกันแบบสดๆ',
    DutyTerms1 = 'จัดทำร่างขอบเขตงาน/ร่างรายละเอียดคุณลักษณะเฉพาะสำหรับการจัดซื้อจัดจ้างข้างต้น',
    DutyTerms2 = 'กำหนดหลักเกณฑ์การพิจารณาคัดเลือกข้อเสนอสำหรับการจัดซื้อจัดจ้างข้างต้น',
    DutyTerms3 = 'ให้บุคคล/คณะกรรมการข้างต้นถือปฏิบัติตามกฎหมายว่าด้วยการจัดซื้อจัดจ้างและการบริหารพัสดุภาครัฐ กฎกระทรวง ข้อบังคับ ระเบียบ และประกาศที่เกี่ยวข้องกับการจัดซื้อจัดจ้างและการบริหารพัสดุภาครัฐ',
    DutyTermsMiddlePrices1 = 'กำหนดราคากลางราคา',
    DutyTermsMiddlePrices2 = 'ให้ผู้กำหนดราคากลาง/คณะกรรมการถือปฏิบัติตามกฎหมายว่าด้วยการจัดซื้อจัดจ้างและการบริหารพัสดุภาครัฐ กฎกระทรวง ข้อบังคับ ระเบียบ และประกาศที่เกี่ยวข้องกับการจัดซื้อจัดจ้างและการบริหารพัสดุภาครัฐโดยเคร่งครัด',
  }

  const getDataAppointmentByIdAsync = async (id: string) => {

    const response = await PreProcurementServices.getAppointmentByIdAsync(id);
    if (response.status === HttpStatusCode.OK) {
      if (data.files) {
        data.files = [];
      }
      setData(response.data);
    }
  };

  useEffect(() => {
    getPositionOnBoardAsync();
    const paramId = location.pathname.slice(location.pathname.lastIndexOf('/'), location.pathname.length);
    setCurrentPath(location.pathname.replace(paramId, ''));

    const date = new Date();
    let year = (date.getUTCFullYear() + 543) - 10;

    const items = Array.from({ length: 20 })
      .map((i) => {
        year += 1;

        return {
          label: year.toString(),
          value: year.toString(),
        };
      });

    setBudgetYearItems(items);
    const defaultYear = (date.getUTCFullYear() + 543);
    setBudgetYear(defaultYear);

    if (data.status === undefined || data.status === '' || data.status === null) {
      for (let i = 0; i < 3; i++) {
        if (i === 0) {
          addDutyTermsTors(DefaultName.DutyTerms1, dutyTermsTors.length + 1);
        } else if (i === 1) {
          addDutyTermsTors(DefaultName.DutyTerms2, dutyTermsTors.length + 1);
        } else if (i === 2) {
          addDutyTermsTors(DefaultName.DutyTerms3, dutyTermsTors.length + 1);
        }
      }
    }

  }, []);

  useEffect(() => {
    if (planBudget > 100000) {
      if (dutyTermsMiddlePrices.length === 0) {
        for (let i = 0; i < 2; i++) {
          if (i === 0) {
            addDutyTermsMiddlePrices(DefaultName.DutyTermsMiddlePrices1, dutyTermsMiddlePrices.length + 1);
          } else if (i === 1) {
            addDutyTermsMiddlePrices(DefaultName.DutyTermsMiddlePrices2, dutyTermsMiddlePrices.length + 1);
          }
        }
      }
    }
  }, [planBudget]);

  useEffect(() => {
    getSupplyMethodSpecialType(supplyMethod);
  }, [supplyMethod]);

  useEffect(() => {
    if (!data.approverAppointPreProcurements || data.approverAppointPreProcurements.length === 0) {
      onGetDefaultApprover(isConsumableMaterials, planBudget, supplyMethod, ignorePurchaseOnTable159);
    }
  }, [isConsumableMaterials, planBudget, supplyMethod, ignorePurchaseOnTable159]);

  const onGetDefaultApprover = async (isConsumableMaterials: boolean, planBudget: number, supplyMethod: string, ignorePurchaseOnTable159: boolean) => {
    const processType = isConsumableMaterials ? PROCESSTYPE.ApproveTorCommitteeAnnouncementProcessStock : PROCESSTYPE.ApproveTorCommitteeAnnouncementProcessNonStock;

    const response = await account
      .getDefaultApproverByTopLeve(processType, planBudget, supplyMethod, ignorePurchaseOnTable159, departmentCode);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: approverAppointModel[] = [];
      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            inRefCode: item.inRefCode,
            sectionNameTh: item.sectionName,
            sectionId: item.sectionId,
          } as sectionGroup);

          approver.push({
            id: undefined,
            appointPreProcurementId: '',
            approverUserId: item.userId,
            approveFullName: item.fullName,
            status: '',
            approvedDateAt: undefined,
            departmentName: item.departmentName,
            departmentId: '',
            departmetShortName: '',
            remark: '',
            isDisabled: false,
            sequence: item.sequence,
            inRefCode: item.inRefCode,
            position: item.positionName,
            processType: item.processType,
            sectionLevel: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            delegateUserId: item.delegateUserId,
            delegateEmployeeCode: item.delegateEmployeeCode,
            delegateFullName: item.delegateFullName,
            delegateDepartmentName: item.delegateDepartmentName,
            delegatePositionName: item.delegatePositionName,
          } as approverAppointModel);

        });

      const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);

      if (data.approverAppointPreProcurements === undefined || data.approverAppointPreProcurements === null || data.approverAppointPreProcurements.length === 0) {

        let inx = 0;
        let sectionSeq = 0;

        approver?.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {

            if (sectionSeq !== item.sectionSequence) {
              inx = 1;
              sectionSeq = item.sectionSequence;
            }

            approver[index].sequence = inx;

            inx++;
          });

        setCommitteesApprovers(approver);
        setSections(uniqueNames);
      }
    }
  };

  useEffect(() => {
    if (data) {

      if (data.status === null || data.status === undefined || data.status === '' || data.status === AppointmentStatus.DRAFT || data.status === AppointmentStatus.REJECTED) {
        setisDisabled(false);
      } else {
        setisDisabled(true);
      }

      if (((data.status === null || data.status === undefined || data.status === '' || data.status === AppointmentStatus.DRAFT || data.status === AppointmentStatus.REJECTED) && (data.isChange || data.isCancel)) || (data.status === AppointmentStatus.APPROVED || data.status === AppointmentStatus.PENDING || data.status === AppointmentStatus.CANCELLED)) {
        setisDisabledPlan(false);
      } else {
        setisDisabledPlan(true);
      }

      if (data.documentDeletes) {
        setDelFiles(data.documentDeletes);
      }

      if (data.committeeTors) {
        setCommitteesTors(data.committeeTors);
      }

      if (data.dutyCommitteeTors) {
        setDutyTermsTors(data.dutyCommitteeTors);
      }

      if (data.committeeMiddlePrices) {
        setCommitteesMiddlePrices(data.committeeMiddlePrices);
      }

      if (data.dutyCommitteeMiddlePrices) {
        setDutyTermsMiddlePrices(data.dutyCommitteeMiddlePrices);
      }

      if (data.isConsumableMaterials) {
        setIsConsumableMaterials(data.isConsumableMaterials);
      }

      if (data.approverAppointPreProcurements) {
        const sectionDataGroup: sectionGroup[] = [];
        data.approverAppointPreProcurements.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {
            sectionDataGroup.push({
              sectionName: item.sectionName,
              level: item.sectionLevel,
              sectionSequence: item.sectionSequence,
              inRefCode: item.inRefCode,
              sectionNameTh: item.sectionName,
              sectionId: item.sectionId,
            } as sectionGroup);
          });

        const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);
        setSections(uniqueNames);
        setCommitteesApprovers(data.approverAppointPreProcurements);

        const queueReCall = data.approverAppointPreProcurements?.filter(a => a.status === ApproverAppointmentStatus.APPROVED).length === 0;

        if (queueReCall) {
          setCheckReCall(true);
        } else {
          setCheckReCall(false);
        }
      }

      if (data.departmentId) {
        setDepartmentId(data.departmentId);
      }

      if (data.supplyMethod) {
        setSupplyMethod(data.supplyMethod);
      }

      if (data.supplyMethodType) {
        setSupplyMethodType(data.supplyMethodType);
      }

      if (data.supplyMethodSpecialType) {
        setSupplyMethodSpecialType(data.supplyMethodSpecialType);
      }

      if (data.expectingProcurementAt) {
        setExpectingProcurementAt(data.expectingProcurementAt);
      }

      if (data.planName) {
        setPlanName(data.planName);
      }

      if (data.planNumber) {
        setPlanNumber(data.planNumber);
      }

      if (data.planBudget) {
        setplanBudget(data.planBudget);
      }

      if (data.budgetYear) {
        setBudgetYear(data.budgetYear);
      }

      if (data.planId) {
        setPlanId(data.planId);
      }

      if (data.sourceAndReasons) {
        setSourceAndReasons(data.sourceAndReasons);
      }

      if (data.memorandumDate) {
        setMemorandumDate(data.memorandumDate);
      } else {
        setMemorandumDate(new Date());
      }

      if (data.memorandumNo) {
        setMemorandumNo(data.memorandumNo);
      }

      if (data.documents) {
        handleSetFile(data.documents);
      }

      if (data.remark) {
        setRemarkCancelOrChange(data.remark);
      }
    }
  }, [data, userId, checkUserId]);

  const onSelectPlan = async (planId: string, balanceBudget: number) => {
    setPlanId(planId);
    const response = await planProcurement.getDetailAsync(planId);
    if (response.status === HttpStatusCode.OK) {
      setPlanNumber(response.data.planProcurement.planNumber);
      setSupplyMethod(response.data.planProcurement.supplyMethod);
      setSupplyMethodType(response.data.planProcurement.supplyMethodType);
      setSupplyMethodSpecialType(response.data.planProcurement.supplyMethodSpecialType);
      setplanBudget(balanceBudget);
      setDepartmentId(response.data.planProcurement.departmentId);
      setBudgetYear(response.data.planProcurement.budgetYear);
      setExpectingProcurementAt(response.data.planProcurement.expectingProcurementAt);
      getSupplyMethodSpecialType(response.data.planProcurement.supplyMethod);
      setPlanName(response.data.planProcurement.name);
      setIgnorePurchaseOnTable159(response.data.planProcurement.ignorePurchaseOnTable159)

      if (!data.approverAppointPreProcurements || data.approverAppointPreProcurements.length === 0) {

        onGetDefaultApprover(isConsumableMaterials, response.data.planProcurement.budget, response.data.planProcurement.supplyMethod, response.data.planProcurement.ignorePurchaseOnTable159);

      }

    }
    setShowPlan(false);
  };

  const getSupplyMethodSpecialType = useCallback(async (supplyMethodId: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(supplyMethodId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setSupplyMethodSpecialDDL(data);
  }, [supplyMethod]);

  const onChangeSupplyMethod = (value: string) => {
    getSupplyMethodSpecialType(value);
    setSupplyMethod(value.toString());
  };

  const getPositionOnBoardAsync = async () => {
    const { data, status } = await shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardCreator);

    if (status === HttpStatusCode.OK) {
      const positionOnBoards: ItemModel[] = data;
      setPosotionOnBoardItems(positionOnBoards);
    }
  };

  const onChangePositionTor = (event: HTMLSelectElement, index: number) => {
    const { value } = event;
    const data = [...committeesTors];
    data[index].positionOnBoardId = value;
    data[index].positionOnBoardName = posotionOnBoardItems.filter((x) => x.value === value)[0].label;
    setCommitteesTors(data);
  };

  const onChangePositionMiddlePrices = (event: HTMLSelectElement, index: number) => {
    const { value } = event;
    const data = [...committeesMiddlePrices];
    data[index].positionOnBoardId = value;
    data[index].positionOnBoardName = posotionOnBoardItems.filter((x) => x.value === value)[0].label;
    setCommitteesMiddlePrices(data);
  };

  // const handlerOnChangeDisabledApproversCommittees = (isDisabled: boolean, index: number) => {
  //   const data = [...committeesApprovers];
  //   data[index].isDisabled = isDisabled;
  //   setCommitteesApprovers(data);
  // };

  // const handlerOnChangeRemarkApproversCommittees = (remark: string, index: number) => {
  //   const data = [...committeesApprovers];
  //   data[index].remark = remark;
  //   setCommitteesApprovers(data);
  // };

  const onSelectEmpTors = (empId: string, name: string, position: string, department: string, sectionSequence: number) => {

    if (committeesTors && committeesTors.filter(x => x.committeeUserId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกคณะกรรมการซ้ำ');
    } else {
      const isPresidentSelected = committeesTors.filter(comm => comm.positionOnBoardId === PositionOnBoardIdEnum.Chairman).length > 0;

      const newData: committeeModel = {
        id: undefined,
        appointPreProcurementId: data.id,
        committeeUserId: empId,
        committeeUserName: name,
        department,
        positionOnBoardId: isPresidentSelected ? posotionOnBoardItems[1].value : posotionOnBoardItems[0].value,
        positionOnBoardName: isPresidentSelected ? posotionOnBoardItems[1].label : posotionOnBoardItems[0].label,
        position,
        sequence: sectionSequence,
      };

      setCommitteesTors((committeesTors) => [...committeesTors, newData]);
      data.planBudget = planBudget;
      // setData({ ...data, committeeTors: [...committeesTors, newData] });
    }
  };

  const onSelectEmpMiddlePrices = (empId: string, name: string, position: string, department: string, sectionSequence: number) => {
    if (committeesMiddlePrices && committeesMiddlePrices.filter(x => x.committeeUserId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกคณะกรรมการซ้ำ');
    } else {
      const isPresidentSelected = committeesMiddlePrices.filter(comm => comm.positionOnBoardId === PositionOnBoardIdEnum.Chairman).length > 0;

      const newData: committeeModel = {
        id: undefined,
        appointPreProcurementId: data.id,
        committeeUserId: empId,
        committeeUserName: name,
        department,
        positionOnBoardId: isPresidentSelected ? posotionOnBoardItems[1].value : posotionOnBoardItems[0].value,
        positionOnBoardName: isPresidentSelected ? posotionOnBoardItems[1].label : posotionOnBoardItems[0].label,
        position,
        sequence: sectionSequence,
      };

      setCommitteesMiddlePrices((committeesMiddlePrices) => [...committeesMiddlePrices, newData]);
      data.planBudget = planBudget;
      // setData({ ...data, committeeMiddlePrices: [...committeesMiddlePrices, newData] });
    }
  };

  // const onSelectEmpApprover = (empId: string, name: string, position: string, department: string, index: number, sectionSequence?: number, inRefCode?: string) => {
  //   if (committeesApprovers && committeesApprovers.filter((x) => x.sectionSequence === sectionSequence && x.approverUserId === empId).length > 0) {
  //     toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
  //   } else {
  //     const newData: approverAppointModel = {
  //       id: undefined,
  //       remark,
  //       appointPreProcurementId: data.id,
  //       approverUserId: empId,
  //       approveFullName: name,
  //       position,
  //       status: '',
  //       approvedDateAt: undefined,
  //       approvedUserBy: undefined,
  //       approvedRemark: undefined,
  //       rejectedUserBy: undefined,
  //       rejectedDate: undefined,
  //       rejectedRemark: undefined,
  //       departmentName: department,
  //       departmentId: '',
  //       departmetShortName: '',
  //       isDisabled: false,
  //       sequence: committeesApprovers.filter((x) => x.sectionSequence === sectionSequence).length + 1,
  //       sectionSequence: sectionSequence!,
  //       inRefCode: inRefCode!,
  //       processType: '',
  //       sectionLevel: undefined,
  //       sectionName: '',
  //       sectionId: '',
  //     };
  //     setCommitteesApprovers(approvers => [...approvers, newData]);
  //   }
  // };

  const addDutyTermsTors = useCallback(async (duty: string, sectionSequence: number) => {
    const newData: dutyTermsModel = {
      id: undefined,
      duty,
      sequence: sectionSequence,
    };

    setDutyTermsTors((dutyTermsTors) => [...dutyTermsTors, newData]);
  }, [dutyTermsTors]);

  const handlerOnChangeDutyTors = (value: string, index: number) => {
    const data = [...dutyTermsTors];
    data[index].duty = value;
    setDutyTermsTors(data);
  };

  const handlerRemoveDutyTermsTors = (i: number) => {
    const temp: dutyTermsModel[] = [...dutyTermsTors];
    temp.splice(i, 1);
    setDutyTermsTors(temp);
  };

  const addDutyTermsMiddlePrices = useCallback(async (duty: string, sectionSequence: number) => {
    const newData: dutyTermsModel = {
      id: undefined,
      duty,
      sequence: sectionSequence,
    };

    setDutyTermsMiddlePrices((dutyTermsMiddlePrices) => [...dutyTermsMiddlePrices, newData]);
  }, [dutyTermsMiddlePrices]);

  const handlerOnChangeDutyMiddlePrices = (value: string, index: number) => {
    const data = [...dutyTermsMiddlePrices];
    data[index].duty = value;
    setDutyTermsMiddlePrices(data);
  };

  const handlerRemoveDutyTermsMiddlePrices = (i: number) => {
    const temp: dutyTermsModel[] = [...dutyTermsMiddlePrices];
    temp.splice(i, 1);
    setDutyTermsMiddlePrices(temp);
  };

  const onSubmitAsync = async () => {
    submitForm();

    if (!planNumber || planNumber === '') {
      toast.warn('กรุณาเลือกข้อมูลอ้างอิงแผนการจัดซื้อจัดจ้าง');
      return;
    }

    if (!memorandumDate
      //|| !expectingProcurementAt
      //|| (!deptId || deptId === '')
      // || (!budgetYear || budgetYear <= 0)
      // || (!supplyMethod || supplyMethod === '')
      // || (!supplyMethodType || supplyMethodType === '')
      // || (!supplyMethodSpecialType || supplyMethodSpecialType === '')
      || !sourceAndReasons
      || (!planName || planName === '')) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
      return;
    }

    if (!planBudget || planBudget <= 0) {
      toast.warn('กรุณากรอกวงเงินมากกว่า 0 บาท');
      return;
    }

    if (!committeesTors || committeesTors.length === 0) {
      toast.warn('กรุณาเลือกบุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน');
      return;
    }

    if (!dutyTermsTors || dutyTermsTors.length === 0) {
      toast.warn('กรุณาเลือกอำนาจหน้าที่บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน');
      return;
    }
    if (dutyTermsTors.filter((x) => x.duty === null || x.duty === '' || x.duty === undefined).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดอำนาจหน้าที่บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน');
      return;
    }

    if ((!committeesMiddlePrices || committeesMiddlePrices.length === 0)
      && planBudget > 100000) {
      toast.warn('กรุณาเลือกบุคคล/คณะกรรมการกำหนดราคากลาง');
      return;
    }

    if ((!dutyTermsMiddlePrices || dutyTermsMiddlePrices.length === 0)
      && planBudget > 100000) {
      toast.warn('กรุณาเลือกอำนาจหน้าที่บุคคล/คณะกรรมการกำหนดราคากลาง');
      return;
    }
    if (dutyTermsMiddlePrices.filter((x) => x.duty === null || x.duty === '' || x.duty === undefined).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดอำนาจหน้าที่บุคคล/คณะกรรมการกำหนดราคากลาง');
      return;
    }

    // if (!committeesApprovers || committeesApprovers.length === 0) {
    //   toast.warn('กรุณาเลือกผู้เห็นชอบ/อนุมัติ');
    //   return;
    // }

    // if (committeesApprovers.filter((x) => x.isDisabled === true && (x.remark === null || x.remark === '' || x.remark === undefined)).length > 0) {
    //   toast.warn('กรุณาระบุหมายเหตุที่ไม่สามารถปฏิบัติงานได้');
    //   return;
    // }

    if (data.isChange || data.isCancel) {
      if (remarkCancelOrChange === null || remarkCancelOrChange === '') {
        return toast.warn('กรุณากรอกหมายเหตุ');
      }
    }

    const docUpload: File[] = [];
    if (files) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });
      data.documentDeletes = delFiles;
      data.files = [...docUpload];

      // setData({ ...data, files: [...docUpload] });
    }

    const committeeMiddles: committeeModel[] = [];
    const dutyMiddles: dutyTermsModel[] = [];

    if (planBudget > 100000) {
      if (committeesMiddlePrices) {
        committeesMiddlePrices.forEach((data) => committeeMiddles.push({
          id: data.id, appointPreProcurementId: data.appointPreProcurementId, committeeUserId: data.committeeUserId, committeeUserName: data.committeeUserName, positionOnBoardId: data.positionOnBoardId, positionOnBoardName: data.positionOnBoardName, position: data.position, department: data.department, sequence: data.sequence,
        }));
      }

      if (dutyTermsMiddlePrices) {
        dutyTermsMiddlePrices.forEach((data) => dutyMiddles.push({ id: data.id, duty: data.duty, sequence: data.sequence }));
      }
    }

    if (data && data.id) {
      const httpStatus = HttpStatusCode.OK;
      const updateDataModel: UpdateAppointmentDetailModel = {
        id: data.id,
        planId: planId !== null ? planId : undefined,
        departmentId: deptId,
        budgetYear,
        planName,
        supplyMethod,
        supplyMethodType,
        supplyMethodSpecialType,
        expectingProcurementAt: expectingProcurementAt || data.expectingProcurementAt,
        planBudget,
        updateByUserFullName: userfullName,
        updateCommitteeTors: committeesTors,
        updateDutyCommitteeTors: dutyTermsTors,
        updateCommitteeMiddlePrices: committeeMiddles,
        updateDutyCommitteeMiddlePrices: dutyMiddles,
        updateApproverAppointPreProcurements: committeesApprovers,
        files: docUpload,
        status: data.status,
        documentId: null,
        documents: data.documents,
        memorandumNo: memorandumNo !== null ? memorandumNo : '',
        memorandumDate: memorandumDate || data.memorandumDate,
        sourceAndReasons,
        remark: remarkCancelOrChange,
        isConsumableMaterials,
      };

      if (httpStatus === HttpStatusCode.OK) {
        const res = await PreProcurementServices.updateAppointmentAsync(updateDataModel);

        if (res.status === HttpStatusCode.OK) {

          if (data.documentDeletes && data.documentDeletes.length > 0) {
            const { status } = await PreProcurementServices.deleteAppointmentDocumentAsync(data.id, data.documentDeletes);

            if (status !== HttpStatusCode.NO_CONTENT && status !== HttpStatusCode.OK) {
              toast.error('ลบไฟล์ไม่สำเร็จ');

              return;
            }
          }

          toast.success('บันทึกข้อมูลสำเร็จ');

          getDataAppointmentByIdAsync(data.id);
          onClickNext();

        } else if (res.status === HttpStatusCode.CONFLICT) {
          toast.error('วงเงินเกินงบประมาณ');
        } else {
          toast.error('บันทึกข้อมูลไม่สำเร็จ');
        }
      }
    } else {
      const createDataModel: CreatePP0201Model = {
        planId: planId !== null ? planId : undefined,
        departmentId: deptId,
        budgetYear,
        planName,
        supplyMethod,
        supplyMethodType,
        supplyMethodSpecialType,
        planBudget,
        expectingProcurementAt: expectingProcurementAt || data.expectingProcurementAt,
        createCommitteeTors: committeesTors,
        createDutyCommitteeTors: dutyTermsTors,
        createCommitteeMiddlePrices: committeeMiddles,
        createDutyCommitteeMiddlePrices: dutyMiddles,
        createApproverAppointPreProcurements: committeesApprovers,
        files: docUpload,
        createdByUserFullName: userfullName,
        memorandumNo: memorandumNo !== null ? memorandumNo : '',
        memorandumDate: memorandumDate || data.memorandumDate,
        sourceAndReasons,
        isConsumableMaterials,
      };

      const res = await PreProcurementServices.createAppointmentAsync(createDataModel);
      if (res.status === HttpStatusCode.CREATED) {
        toast.success('บันทึกข้อมูลสำเร็จ');
        const newId: string = res.data.id;
        const response = await PreProcurementServices.getAppointmentByIdAsync(newId);
        if (response.status === HttpStatusCode.OK) {
          if (data.files) {
            data.files = [];
          }
          setData(response.data);
        }
        navigate(`${location.pathname}/${response.data.id}`);
        onClickNext();
      } else if (res.status === HttpStatusCode.CONFLICT) {
        toast.error('วงเงินเกินงบประมาณ');
      } else {
        toast.error('บันทึกข้อมูลไม่สำเร็จ');
      }
    }
  };

  const reCallAppointmentAsync = async () => {

    if (data && data.id) {
      const response = await PreProcurementServices.reCallAppointmentAsync(data.id);

      if (response.status === HttpStatusCode.OK) {
        toast.success('เรียกคืนแก้ไขสำเร็จ');
        getDataAppointmentByIdAsync(data.id);
        navigate(`${location.pathname}`);
      }
    }

    setShowReCall(false);
  };

  const handleSetFile = (documents: AppointmentDocumentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
        createdByUserId: document.createdByUserId,
        createdByUserFullName: document.createdByUserFullName,
        createdAt: document.createdAt,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await PreProcurementServices.UploadAppointmentAttachmentAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getAppointmentById(data.id);
      }
    }

  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName) {
      setDelFiles((delFiles) => [...delFiles, docName]);
    }

    const delFile = [];
    delFile.push(docName);
    if (data && data.id) {
      const res = await PreProcurementServices.deleteAppointmentDocumentAsync(
        data.id,
        delFile,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('ลบไฟล์สำเร็จ');
        getAppointmentById(data.id);
      }
    }

  }, [files, data.id]);

  const getAppointmentById = async (id: string) => {
    const response = await PreProcurementServices.getAppointmentByIdAsync(id);
    if (response.status === HttpStatusCode.OK) {
      setData({ ...data, documents: response.data.documents });
    }
  };

  const onSubmitAppointmentEditingAsync = async (type: PreProcumentFlag) => {
    submitForm();
    if (!remarkCancelOrChange) {
      toast.warn('กรุณากรอกหมายเหตุ');
      return;
    }

    if (data && data.id) {
      const createDataModel: CreateAppointmenteditingModel = {
        RefId: data.id,
        planId: data.planId !== null ? data.planId : undefined,
        departmentId: data.departmentId,
        budgetYear: data.budgetYear,
        planName: data.planName,
        supplyMethod: data.supplyMethod,
        supplyMethodType: data.supplyMethodType,
        supplyMethodSpecialType: data.supplyMethodSpecialType,
        planBudget: data.planBudget,
        expectingProcurementAt: data.expectingProcurementAt,
        memorandumDate: data.memorandumDate,
        remark: remarkCancelOrChange,
        createCommitteeTors: data.committeeTors,
        createDutyCommitteeTors: data.dutyCommitteeTors,
        createCommitteeMiddlePrices: data.committeeMiddlePrices,
        createDutyCommitteeMiddlePrices: data.dutyCommitteeMiddlePrices,
        createApproverAppointPreProcurements: data.approverAppointPreProcurements,
        files: data.files,
        createdByUserFullName: userfullName,
        memorandumNo: data.memorandumNo !== null ? data.memorandumNo : '',
        sourceAndReasons: data.sourceAndReasons,
        isConsumableMaterials,
      };

      const res = await PreProcurementServices.createAppointmentEditingAsync(createDataModel, type);

      if (res.status === HttpStatusCode.CREATED) {
        const newId: string = res.data.id;

        getDataAppointmentByIdAsync(data.id);

        if (type === PreProcumentFlag.Cancel) {
          toast.success('บันทึกขอยกเลิกแบบขอแต่งตั้งบุคคล/คกก.ฯสำเร็จ');
          navigate(`/cancel-preprocurement-directive/cancel/${newId}`);
        } else {
          toast.success('บันทึกขอแก้ไขแบบขอแต่งตั้งบุคคล/คกก.ฯสำเร็จ');
          navigate(`/edit-preprocurement-directive/edit/${newId}`);
        }

      } else {
        toast.error('บันทึกข้อมูลไม่สำเร็จ');
      }
    }
    setShowRequestEdit(false);
    setShowCancel(false);
  };

  const onNext = () => {
    submitForm();

    if (!expectingProcurementAt

      || !memorandumDate
      || (!deptId || deptId === '')
      || (!budgetYear || budgetYear <= 0)
      || (!supplyMethod || supplyMethod === '')
      || (!supplyMethodType || supplyMethodType === '')
      || (!supplyMethodSpecialType || supplyMethodSpecialType === '')
      || !sourceAndReasons
      || (!planName || planName === '')) {
      return;
    }

    if (!planBudget || planBudget <= 0) {
      toast.warn('กรุณากรอกวงเงินมากกว่า 0 บาท');
      return;
    }

    if (!committeesTors || committeesTors.length === 0) {
      toast.warn('กรุณาเลือกบุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน');
      return;
    }

    if (!dutyTermsTors || dutyTermsTors.length === 0) {
      toast.warn('กรุณาเลือกอำนาจหน้าที่บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน');
      return;
    }
    if (dutyTermsTors.filter((x) => x.duty === null || x.duty === '' || x.duty === undefined).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดอำนาจหน้าที่บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน');
      return;
    }

    if ((!committeesMiddlePrices || committeesMiddlePrices.length === 0)
      && planBudget > 100000) {
      toast.warn('กรุณาเลือกบุคคล/คณะกรรมการกำหนดราคากลาง');
      return;
    }

    if ((!dutyTermsMiddlePrices || dutyTermsMiddlePrices.length === 0)
      && planBudget > 100000) {
      toast.warn('กรุณาเลือกอำนาจหน้าที่บุคคล/คณะกรรมการกำหนดราคากลาง');
      return;
    }

    if (dutyTermsMiddlePrices.filter((x) => x.duty === null || x.duty === '' || x.duty === undefined).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดอำนาจหน้าที่บุคคล/คณะกรรมการกำหนดราคากลาง');
      return;
    }

    // if (!committeesApprovers || committeesApprovers.length === 0) {
    //   toast.warn('กรุณาเลือกผู้เห็นชอบ/อนุมัติ');
    //   return;
    // }

    // if (committeesApprovers.filter((x) => x.isDisabled === true && (x.remark === null || x.remark === '' || x.remark === undefined)).length > 0) {
    //   toast.warn('กรุณาระบุหมายเหตุที่ไม่สามารถปฏิบัติงานได้');
    //   return;
    // }

    if (data.isChange || data.isCancel) {
      if (remarkCancelOrChange === null || remarkCancelOrChange === '') {
        return toast.warn('กรุณากรอกหมายเหตุ');
      }
    }

    const docUpload: File[] = [];
    files.forEach((i) => {
      if (i.file !== null) {
        docUpload.push(i.file);
      }
    });

    data.planId = planId;
    data.planName = planName;
    data.planBudget = planBudget;
    data.departmentId = deptId;
    data.budgetYear = budgetYear;
    data.supplyMethod = supplyMethod;
    data.supplyMethodType = supplyMethodType;
    data.supplyMethodSpecialType = supplyMethodSpecialType;
    data.planBudget = planBudget;
    data.expectingProcurementAt = expectingProcurementAt || data.expectingProcurementAt;
    data.memorandumDate = memorandumDate || data.memorandumDate;
    data.memorandumNo = memorandumNo || data.memorandumNo;
    data.sourceAndReasons = sourceAndReasons;
    data.committeeTors = committeesTors;
    data.committeeMiddlePrices = committeesMiddlePrices;
    data.dutyCommitteeTors = dutyTermsTors;
    data.dutyCommitteeMiddlePrices = dutyTermsMiddlePrices;
    data.approverAppointPreProcurements = committeesApprovers;
    data.documentDeletes = delFiles;
    data.isConsumableMaterials = isConsumableMaterials;
    data.remark = remarkCancelOrChange;
    setData({ ...data, files: [...docUpload] });
    onClickNext();
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title
          text='ข้อมูลเอกสารบันทึกข้อความแต่งตั้ง'
          className='fs-5 text-primary'
          extraElement={(
            <div className='d-flex gap-2'>
              {
                data.isEditing && (
                  <div className='status'
                    style={{ marginRight: 13 }}>
                    <span><FaCircle style={{ fontSize: 13 }}
                      className='text-warning' />{PreProcumentEditCancelStatusConstant.Editing}
                    </span>
                  </div>
                )
              }
              {
                data.isInCanceling && (
                  <div className='status'
                    style={{ marginRight: 13 }}>
                    <span><FaCircle style={{ fontSize: 13 }}
                      className='text-danger' />{PreProcumentEditCancelStatusConstant.Canceling}
                    </span>
                  </div>
                )
              }
              <DirectiveStatus
                value={data.status}
                editType={
                  data.isChange ? StatusEditType.IsChanged
                    : data.isCancel ? StatusEditType.IsCancel
                      : undefined}
              />
              <Button
                onClick={() => setShowHistory(!showHistory)}
                variant='outline-primary'
              >
                <FaHistory className='me-1' />
                ประวัติการใช้งาน
              </Button>
            </div>
          )}
        />

        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={4}>
            <Input
              label='เลขที่อ้างอิงในระบบ'
              value={data.appointNumber}
              onChange={(val) => setData({ ...data, appointNumber: val })}
              disabled
              placeholder='เลขที่อ้างอิงในระบบ'
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={4}>
            <DatePicker
              label='วันที่เอกสารบันทึกข้อความแต่งตั้ง'
              rule={{ required: true }}
              value={memorandumDate}
              onChange={(val) => setMemorandumDate(val)}
              disabled={isDisabled || isCancel}
              placeholder='วันที่เอกสารบันทึกข้อความแต่งตั้ง'
              fullDate
            />
          </Col>
          <Col sm={12}
            lg={4}>
            <Input
              label='เลขที่ระบบสารบรรณ (ถ้ามี)'
              value={memorandumNo}
              onChange={(val) => setMemorandumNo(val)}
              disabled={isDisabled || isCancel}
              placeholder='เลขที่ระบบสารบรรณ (ถ้ามี)'
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={4}>
            <Input
              label='อ้างอิงแผนการจัดซื้อจัดจ้าง'
              placeholder='อ้างอิงแผนการจัดซื้อจัดจ้าง'
              value={planNumber}
              disabled
              textSearch={isDisabledPlan}
              variant='primary'
              onClick={() => setShowPlan(!showPlan)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={9}
            lg={4}>
            <Form.Group className='mb-3'>
              <Selector
                label='ฝ่าย/สำนัก'
                items={departmentDDL}
                value={deptId}
                placeholder='กรุณาเลือก'
                // rule={{ required: true }}
                onChange={(val) => setDepartmentId(val)}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={3}
            lg={4}>
            <Selector
              label='ปีงบประมาณ'
              placeholder='กรุณาเลือก'
              items={budgetYearItems}
              disabled
              value={budgetYear.toString()}
              // rule={{ required: true }}
              onChange={(val) => setBudgetYear(Number(val))}
              name='budgetYear'
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={8}>
            <Row>
              <Col sm={12}
                lg={4}>
                <Selector
                  label='วิธีการจัดหา'
                  placeholder='กรุณาเลือก'
                  items={supplyMethodDDL}
                  // rule={{ required: true }}
                  value={supplyMethod}
                  onChange={(val) => onChangeSupplyMethod(val)}
                  disabled
                />
              </Col>
              <Col sm={12}
                lg={4}>
                <Selector
                  className='no-label'
                  placeholder='กรุณาเลือก'
                  items={supplyMethodTypeDDL}
                  // rule={{ required: true }}
                  value={supplyMethodType}
                  onChange={(val) => setSupplyMethodType(val)}
                  disabled
                />
              </Col>
              <Col sm={12}
                lg={4}>
                <Selector
                  className='no-label'
                  placeholder='กรุณาเลือก'
                  items={supplyMethodSpecialDDL}
                  // rule={{ required: true }}
                  value={supplyMethodSpecialType}
                  onChange={(val) => setSupplyMethodSpecialType(val)}
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={8}>
            <Row>
              <Col sm={12}
                lg={4}>
                <DatePicker
                  label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
                  // rule={{ required: true }}
                  value={expectingProcurementAt}
                  onChange={(val) => setExpectingProcurementAt(val)}
                  disabled={isDisabled || isCancel}
                  placeholder='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
                  monthYearOnly
                />
              </Col>
              <Col sm={12}
                lg={4}>
                <Currency
                  label='วงเงิน (บาท)'
                  rule={{ required: true }}
                  placeholder='วงเงิน (บาท)'
                  value={planBudget}
                  onChange={(val) => setplanBudget(Number(val))}
                  // onChange={(val) => onChangePlanBudget(Number(val))}
                  disabled={isDisabled || isCancel}
                />
              </Col>
              <Col sm={12}
                lg={4}>
                <Form.Label>ประเภทพัสดุ</Form.Label>
                <Check
                  label={<>(Stock) เป็นรายการวัสดุเครื่องเขียนแบบพิมพ์<br />และวัสดุของใช้สิ้นเปลืองคลังพัสดุ</>}
                  value={isConsumableMaterials}
                  // onChange={(val) => onChangeConsumableMaterials(val)}
                  onChange={(val) => setIsConsumableMaterials(val)}
                  disabled={isDisabled || isCancel}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col sm={12}
            lg={8}>
            <InputTextArea
              label='ชื่อโครงการ (เรื่อง)'
              value={planName}
              onChange={(val) => setPlanName(val)}
              rule={{ required: true }}
              disabled={isDisabled || isCancel}
              placeholder='ชื่อโครงการ (เรื่อง)'
            />
          </Col>
          <Col sm={12}
            lg={8}>
            <InputTextArea
              label='เหตุผลและความจำเป็นที่จะซื้อหรือจ้างหรือเช่า'
              value={sourceAndReasons}
              onChange={(val) => setSourceAndReasons(val)}
              rule={{ required: true }}
              disabled={isDisabled || isCancel}
              placeholder='เหตุผลและความจำเป็นที่จะซื้อหรือจ้างหรือเช่า'
            />
          </Col>
          {
            (data.isChange || data.isCancel)
            && (
              <Col sm={12}
                lg={8}>
                <InputTextArea label={data.isChange ? 'เหตุผลที่ขอเปลี่ยนแปลง' : 'เหตุผลที่ขอยกเลิก'}
                  value={remarkCancelOrChange}
                  onChange={(value) => setRemarkCancelOrChange(value)}
                  rule={{ required: true }}
                  disabled={isDisabled}
                  placeholder='หมายเหตุ'
                />
              </Col>
            )
          }
        </Row>
      </Card>
      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน'
            className='fs-5 text-primary'
          />
          {openCollapse1 ? (
            <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />)}
        </div>
        <Collapse in={openCollapse1}>
          <div>
            <div className='d-flex justify-content-between my-3'>
              <p>บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน</p>
              <Button
                variant='outline-primary'
                onClick={() => setShowCommitteesTors(true)}
                disabled={isDisabled || isCancel}
                hidden={!isVisable}
              >
                <FaPlus className='me-1' />เพิ่มรายชื่อ
              </Button>
            </div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ minWidth: 20 }}>ลำดับ</th>
                      <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
                      <th>ฝ่าย/สำนัก</th>
                      <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
                      <th style={{ width: 150 }}>ตำแหน่งในคณะกรรมการ</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {committeesTors?.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr className='text-center'
                          key={index}>
                          <td className='text-center'>{index + 1}</td>
                          <td className='text-start'>{data.committeeUserName}</td>
                          <td className='text-start'>{data.department}</td>
                          <td className='text-start text-wrap'>{data.position}</td>
                          <td style={{ width: 350 }}>
                            <Form.Group className='mb-3'>
                              <InputGroup hasValidation>
                                <Form.Select
                                  className='dark mt-1'
                                  value={data.positionOnBoardId || ''}
                                  disabled={isDisabled || isCancel}
                                  onChange={(event) => onChangePositionTor(event.target as HTMLSelectElement, index)}
                                >
                                  {
                                    posotionOnBoardItems.map((item) => (
                                      <option
                                        key={item.value}
                                        disabled={committeesTors.filter(c => c.positionOnBoardId === PositionOnBoardIdEnum.Chairman).length > 0 && item.value === PositionOnBoardIdEnum.Chairman}
                                        value={item.value}>{item.label}
                                      </option>
                                    ))
                                  }
                                </Form.Select>
                              </InputGroup>
                            </Form.Group>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end'>
                              {isVisable ?
                                <Button
                                  variant='danger'
                                  className='d-flex align-items-center gap-2'
                                  onClick={() => handlerRemoveCommitteesTors(index)}
                                  disabled={isDisabled || isCancel}
                                >
                                  <FaTrashAlt />
                                </Button> : null
                              }
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='อำนาจหน้าที่บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน'
            className='fs-5 text-primary'
          />

          {openCollapse2 ? (
            <ArrowCollapse onClick={() => setOpenCollapse2(!openCollapse2)} openCollapse={openCollapse2} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse2(true)} openCollapse={openCollapse2} />)}
        </div>
        <Collapse in={openCollapse2}>
          <div>
            <div className='d-flex justify-content-between my-3'>
              <p>อำนาจหน้าที่บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน</p>
              <Button
                variant='outline-primary'
                onClick={() => addDutyTermsTors('', dutyTermsTors.length + 1)}
                disabled={isDisabled || isCancel}
                hidden={!isVisable}
              >
                <FaPlus className='me-2' />เพิ่มข้อมูล
              </Button>
            </div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 10 }}>ลำดับ</th>
                      <th style={{ minWidth: 250 }}>รายละเอียด</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {dutyTermsTors.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr key={index}>
                          <td className='text-center'>{index + 1}</td>
                          <td className='text-start'>
                            <Form.Group className='mb-3'>
                              <InputGroup>
                                <Input
                                  rule={{ required: true }}
                                  type='text'
                                  value={data.duty || ''}
                                  disabled={isDisabled || isCancel}
                                  onChange={(val) => handlerOnChangeDutyTors(val, index)}
                                />
                              </InputGroup>
                            </Form.Group>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end'>
                              {isVisable ?
                                <Button
                                  variant='danger'
                                  className='d-flex align-items-center gap-2'
                                  onClick={() => handlerRemoveDutyTermsTors(index)}
                                  disabled={isDisabled || isCancel}
                                  hidden={!isVisable}
                                >
                                  <FaTrashAlt />
                                </Button> : null
                              }
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>
      {(planBudget > 100000) && (
        <>
          <Card className='mt-3'>
            <div className='d-flex align-items-center'>
              <Title
                text='บุคคล/คณะกรรมการกำหนดราคากลาง'
                className='fs-5 text-primary'
              />
              {openCollapse3 ? (
                <ArrowCollapse onClick={() => setOpenCollapse3(!openCollapse3)} openCollapse={openCollapse3} />
              ) : (
                <ArrowCollapse onClick={() => setOpenCollapse3(true)} openCollapse={openCollapse3} />)}
            </div>
            <Collapse in={openCollapse3}>
              <div>
                <div className='d-flex justify-content-between my-3'>
                  <p>บุคคล/คณะกรรมการกำหนดราคากลาง</p>
                  <Button
                    variant='outline-primary'
                    onClick={() => setShowCommitteesMiddlePrices(true)}
                    disabled={isDisabled || isCancel}
                    hidden={!isVisable}
                  >
                    <FaPlus className='me-1' />เพิ่มรายชื่อ
                  </Button>
                </div>
                <Row className='mt-3 align-items-center'>
                  <Col sm={12}>
                    <Table total={1}
                      hidePagination>
                      <thead>
                        <tr>
                          <th style={{ minWidth: 20 }}>ลำดับ</th>
                          <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
                          <th>ฝ่าย/สำนัก</th>
                          <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
                          <th style={{ width: 150 }}>ตำแหน่งในคณะกรรมการ</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {committeesMiddlePrices?.sort((a, b) => a.sequence - b.sequence)
                          .map((data, index) => (
                            <tr key={index}>
                              <td className='text-center'>{index + 1}</td>
                              <td className='text-start'>{data.committeeUserName}</td>
                              <td>{data.department}</td>
                              <td className='text-start text-wrap'>{data.position}</td>
                              <td style={{ width: 350 }}>
                                <Form.Group className='mb-3'>
                                  <InputGroup hasValidation>
                                    <Form.Select
                                      className='dark mt-1'
                                      value={data.positionOnBoardId || ''}
                                      disabled={isDisabled || isCancel}
                                      onChange={(event) => onChangePositionMiddlePrices(event.target as HTMLSelectElement, index)}
                                    >
                                      {
                                        posotionOnBoardItems.map((item) => (
                                          <option key={item.value}
                                            disabled={committeesMiddlePrices.filter(c => c.positionOnBoardId === PositionOnBoardIdEnum.Chairman).length > 0 && item.value === PositionOnBoardIdEnum.Chairman}
                                            value={item.value}>{item.label}
                                          </option>
                                        ))
                                      }
                                    </Form.Select>
                                  </InputGroup>
                                </Form.Group>
                              </td>
                              <td className='text-end'>
                                <div className='d-flex justify-content-end'>
                                  {isVisable ?
                                    <Button
                                      variant='danger'
                                      className='d-flex align-items-center gap-2'
                                      onClick={() => handlerRemoveCommitteesMiddlePrices(index)}
                                      disabled={isDisabled || isCancel}
                                    >
                                      <FaTrashAlt />
                                    </Button> : null
                                  }
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Card>

          <Card className='mt-3'>
            <div className='d-flex align-items-center'>
              <Title
                text='อำนาจหน้าที่บุคคล/คณะกรรมการกำหนดราคากลาง'
                className='fs-5 text-primary'
              />
              {openCollapse4 ? (
                <ArrowCollapse onClick={() => setOpenCollapse4(!openCollapse4)} openCollapse={openCollapse4} />
              ) : (
                <ArrowCollapse onClick={() => setOpenCollapse4(true)} openCollapse={openCollapse4} />)}
            </div>
            <Collapse in={openCollapse4}>
              <div>
                <div className='d-flex justify-content-between my-3'>
                  <p>อำนาจหน้าที่บุคคล/คณะกรรมการกำหนดราคากลาง</p>
                  <Button
                    variant='outline-primary'
                    onClick={() => addDutyTermsMiddlePrices('', dutyTermsMiddlePrices.length + 1)}
                    disabled={isDisabled || isCancel}
                    hidden={!isVisable}
                  >
                    <FaPlus className='me-2' />เพิ่มข้อมูล
                  </Button>
                </div>
                <Row className='mt-3 align-items-center'>
                  <Col sm={12}>
                    <Table total={1}
                      hidePagination>
                      <thead>
                        <tr>
                          <th style={{ width: 10 }}>ลำดับ</th>
                          <th style={{ minWidth: 250 }}>รายละเอียด</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {dutyTermsMiddlePrices?.sort((a, b) => a.sequence - b.sequence)
                          .map((data, index) => (
                            <tr key={index}>
                              <td className='text-center'>{index + 1}</td>
                              <td className='text-start'>
                                <Form.Group className='mb-3'>
                                  <InputGroup>
                                    <Input
                                      rule={{ required: true }}
                                      type='text'
                                      value={data.duty || ''}
                                      disabled={isDisabled || isCancel}
                                      onChange={(val) => handlerOnChangeDutyMiddlePrices(val, index)}
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </td>
                              <td>
                                <div className='d-flex justify-content-end'>
                                  {isVisable ?
                                    <Button
                                      variant='danger'
                                      className='d-flex align-items-center gap-2'
                                      onClick={() => handlerRemoveDutyTermsMiddlePrices(index)}
                                      disabled={isDisabled || isCancel}
                                    >
                                      <FaTrashAlt />
                                    </Button> : null
                                  }
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Card>
        </>
      )}

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={8}>
            <UploadFile
              disabled={isDisabled || readonly}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              appointmentId={data.id}
              canDelete={isVisable}
              userId={userId}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="กลับหน้าแรก"
        />
        <div>
          <div>
            {isVisable ?
              (data.status === undefined || data.status === null || data.status === '' || data.status === AppointmentStatus.DRAFT || data.status === AppointmentStatus.REJECTED) && (
                <ButtonCustom
                  onClick={onSubmitAsync}
                  variant='success'
                  icon="save"
                  text="บันทึกข้อมูลเอกสารบันทึกข้อความแต่งตั้ง"
                />
              ) : null
            }

            {
              (data.status === AppointmentStatus.APPROVED && !data.isEditing && !data.isInCanceling) && (
                <>
                  <ButtonCustom
                    onClick={() => setShowRequestEdit(true)}
                    icon="undo"
                    text="ขอแก้ไขแบบขอแต่งตั้งบุคคล/คกก.ฯ"
                  />
                  <ButtonCustom
                    onClick={() => setShowCancel(true)}
                    icon="cancel"
                    variant='danger'
                    text="ขอยกเลิกแบบขอแต่งตั้งบุคคล/คกก.ฯ"
                  />
                </>
              )
            }

            {(data.status === AppointmentStatus.PENDING && checkReCall) && (
              <ButtonCustom
                onClick={() => setShowReCall(true)}
                variant='danger'
                icon="undo"
                text="เรียกคืนแก้ไข"
              />
            )}
          </div>
        </div>
        {
          (data.status !== undefined && data.status !== '' && data.status !== null)
            ? (
              <ButtonCustom
                onClick={onNext}
                text="ถัดไป"
              />
            )
            : <div style={{ width: '190px' }} />
        }
      </div>

      <HistoryModal
        title='ประวัติการใช้งานแบบขอแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        appointNumber={data.appointNumber}
        statusType={
          data.isChange ? StatusType.APPOINTMENTEDIT
            : data.isCancel ? StatusType.APPOINTMENTCANCEL
              : StatusType.APPOINTMENT}
      />
      {/* <UserApproveModal
        show={showCommitteesTors}
        onHide={() => setShowCommitteesTors(!showCommitteesTors)}
        onSelectItem={onSelectEmpTors}
      /> */}
      {/* <UserApproveModal
        show={showCommitteesMiddlePrices}
        onHide={() => setShowCommitteesMiddlePrices(!showCommitteesMiddlePrices)}
        onSelectItem={onSelectEmpMiddlePrices}
      /> */}
      <SearchBuNameModal
        total={userTotal}
        show={showCommitteesTors}
        onHide={() => setShowCommitteesTors(!showCommitteesTors)}
        onSelect={(id, name, department, position) => onSelectEmpTors(id, name, position!, department!, committeesTors.length + 1)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
      // departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showCommitteesMiddlePrices}
        onHide={() => setShowCommitteesMiddlePrices(!showCommitteesMiddlePrices)}
        onSelect={(id, name, department, position) => onSelectEmpMiddlePrices(id, name, position!, department!, committeesMiddlePrices.length + 1)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
      // departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      {/* <UserApproveModal
        show={showCommitteesApprovers}
        onHide={() => setShowCommitteesApprovers(!showCommitteesApprovers)}
        onSelectItem={onSelectEmpApprover}
      /> */}
      <PlanProcurementModal
        show={showPlan}
        onHide={() => setShowPlan(!showPlan)}
        onSelectItem={onSelectPlan}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <Modal
        show={showRequestEdit}
        size='lg'
        onHide={() => setShowRequestEdit(!showRequestEdit)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title> คุณต้องการบันทึกขอแก้ไขแบบขอแต่งตั้งบุคคล/คกก.ฯ ?</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRemarkCancelOrChange(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowRequestEdit(!showRequestEdit)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                onClick={() => onSubmitAppointmentEditingAsync(PreProcumentFlag.Editing)}
              >
                <BsCheckCircle className='mx-2 pb-1 fs-4' />
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showCancel}
        size='lg'
        onHide={() => setShowCancel(!showCancel)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title> คุณต้องการบันทึกขอยกเลิกแบบขอแต่งตั้งบุคคล/คกก.ฯ ?</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRemarkCancelOrChange(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowCancel(!showCancel)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                onClick={() => onSubmitAppointmentEditingAsync(PreProcumentFlag.Cancel)}
              >
                <BsCheckCircle className='mx-2 pb-1 fs-4' />
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showReCall}
        size='lg'
        onHide={() => setShowReCall(!showReCall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowReCall(!showReCall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={reCallAppointmentAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step1;
