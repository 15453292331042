import {
  Card,
  Check,
  Input,
  InputTextArea,
  Selector,
  ButtonCustom,
  ArrowCollapse,
  Table,
  TorStatusCommitteeApprovers,
} from 'components';
import Title from 'components/Controls/Title';
import {
  InvitationStatus,
} from 'components/InvitationStatus';
import { Modal, SearchBuNameModal } from 'components/Modal';
import { InvitationStatusConstant } from 'constant/ProcumentInvitationStatus';
import { IFile, ItemModel, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import {
  CommitteeApproverModel,
  DocumentModel,
  InvitationDetailModel,
  InviteAcceptorModel,
} from 'models/PR/InvitationModels';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaHistory,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowForward,
} from 'react-icons/md';
import { useNavigate } from 'react-router';
import { shareValue } from 'services';
import InvitationServices from 'services/PR/InvitationServices';
import {
  HttpStatusCode,
  PositionOnBoardIdEnum,
  ProcurementSection,
  submitForm,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { Context } from '.';
import { HistoryModal } from '../Modal/HistoryModal';
import UploadFile from './UploadFile';
import { JorPorCode } from 'constant/basicInformation';
import { AcceptorStatus } from 'utils/constants/PlanEnum';
import { isNull } from 'utils/helper';
import { SubGroup } from 'utils/constants/ContractEnum';
import { TorStatusConstant } from 'constant/PreProcumentTorStatus';
import Collabora, { CollaboraRef } from 'components/Document/Collabora';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

export default function Step2({ onClickNext, onClickBack }: Props) {
  const { userId, departmentCode } = useAppContext();
  const { data, setData } = useContext(Context);
  const [inviteData, setInviteData] = useState<InvitationDetailModel>({} as InvitationDetailModel);
  const [inviteAcceptors, setInviteAcceptors] = useState<InviteAcceptorModel[]>([]);
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showInvitationApprovers, setShowInvitationApprovers] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [rejectRemark, setRejectRemark] = useState('');
  const [files, setFiles] = useState<IFile[]>([]);
  const navigate = useNavigate();
  const [showRecall, setShowRecall] = useState(false);
  const [isCanRecall, setIsCanRecall] = useState(true);
  const [positionOnBoardList, setPositionOnBoardList] = useState<{ label: string, value: string }[]>([]);
  const [approveRemark, setApproveRemark] = useState('');
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [sectionType, setSectionType] = useState<string>();
  const [sectionSequence, setSectionSequence] = useState<number>();
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [committeeApprovers, setCommitteeApprovers] = useState<CommitteeApproverModel[]>([]);
  const [positionOnBoardItems, setPositionOnBoardItems] = useState<ItemModel[]>([]);
  const [readonly, setReadonly] = useState<boolean>(false);
  const [docId, setDocId] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);

  const getPositionOnBoardItemAsync = async () => {
    const { data, status } = await shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardProcurement);

    if (status === HttpStatusCode.OK) {
      const positionOnBoards: ItemModel[] = data;
      setPositionOnBoardItems(positionOnBoards);
    }
  };

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  useEffect(() => {
    if (data) {
      setInviteData(data);
      if (data.inviteAcceptors) {
        setInviteAcceptors(data.inviteAcceptors);
        if (userId) {
          setIsApprover(onCheckMessageApprover(data.inviteAcceptors));
          onCheckCanApprove(data.inviteAcceptors);
          setIsCanRecall(!(data.inviteAcceptors.filter(c => c.status === InvitationStatusConstant.Approved || c.status === InvitationStatusConstant.Rejected).length > 0) && data.isResponsible);
        }
      }

      if (data.status) {
        setIsDisabled(setDisableForm());
      }

      if (data.documents) {
        handleSetFile(data.documents);
      }

      if (data.inviteCommittees) {
        setCommitteeApprovers(data.inviteCommittees);
        const queueApprover = onCheckQueueApprover(data.inviteCommittees);
        setIsQueueApprover(queueApprover);
        setIsCanRecall(!(data.inviteCommittees.filter(c => c.approverStatus === InvitationStatusConstant.Approved || c.approverStatus === InvitationStatusConstant.Rejected).length > 0) && data.isResponsible);
      }

      if (docId) {
        return;
      }

      getDocument(data.id);
    }

    getPositionOnBoardAsync();
    getPositionOnBoardItemAsync();
    setReadonly(!(
      data.status === TorStatusConstant.Draft ||
      data.status === TorStatusConstant.DraftTor
    ));
  }, [data, userId]);

  const setDisableForm = () => {
    const isDisable = data.status !== InvitationStatusConstant.None
      && data.status !== InvitationStatusConstant.Draft
      && data.status !== InvitationStatusConstant.Rejected;

    setIsDisabled(isDisable || !data.isResponsible);

    return isDisable;
  }

  const isDisableForm = useMemo(() => {
    return setDisableForm();
  }, [data]);

  const onCheckQueueApprover = (datas: CommitteeApproverModel[]) => {
    if (datas && data.status === InvitationStatusConstant.WaitingForApprove) {
      const pendings = datas?.filter((a) => a.isDisabled === false && a.approverStatus === InvitationStatusConstant.Pending);
      const president = pendings.filter((a) => a.positionOnBoardId !== PositionOnBoardIdEnum.Chairman);
      if (president.length > 0) {
        return president.filter((a) => a.userId === userId).length > 0;
      }

      return (pendings.filter((a) => a.positionOnBoardId === PositionOnBoardIdEnum.Chairman && a.userId === userId).length > 0);
    }
    return false;
  };

  const getDocument = useCallback(
    async (id: string) => {
      const { data: docId, status } =
        await InvitationServices.getDocumentAsync(id, isDisableForm);

      if (status === HttpStatusCode.OK) {
        setDocId(docId);
      }
    }, []);

  const onCheckCanApprove = (datas: InviteAcceptorModel[]) => {
    if (datas) {

      const queueApprover = datas?.filter((a) => a.status === AcceptorStatus.PENDING)
        .sort((a, b) => a.sequence - b.sequence)[0];

      if (queueApprover) {
        setIsQueueApprover(isNull(queueApprover.delegateUserId, queueApprover.userId) === userId);
      }

    }
  };

  const onCheckMessageApprover = (data: InviteAcceptorModel[]) => {

    const maxValueSectionSequence = Math.max.apply(null,
      data?.map((x) => {
        return x.sectionSequence;
      }));

    const maxValueSequence = Math.max.apply(null,
      data?.filter(a => a.sectionSequence === maxValueSectionSequence)?.map((x) => {
        return x.sequence;
      }));

    const dataMaxValueSectionSequence = data?.filter(a => a.sectionSequence === maxValueSectionSequence && a.sequence === maxValueSequence);

    const approverIndex = dataMaxValueSectionSequence?.findIndex(x => x.userId === userId || x.delegateUserId === userId) + 1;

    if (approverIndex) {
      return true;
    }

    return false;
  };

  // const onCheckCanApprove = (datas: InviteAcceptorModel[]) => {
  //   const indexApprover = datas.sort((a, b) => a.sequence - b.sequence)
  //     .findIndex((a) => a.userId === userId && a.status === InvitationStatusConstant.WaitingForApprove && !a.isDisabled);
  //   if (indexApprover < 0) {
  //     return false;
  //   }

  //   if (indexApprover === 0) {
  //     return true;
  //   }

  //   if (datas[indexApprover - 1].isDisabled) {
  //     return true;
  //   }

  //   return (datas[indexApprover - 1].status === InvitationStatusConstant.Approved);
  // };

  const onSelectEmpApprover = (empId: string, name: string, department: string, position: string, index: number, sectionSequence?: number, sectionType?: string, delegateUserId?: string, delegateFullName?: string, delegateDepartmentName?: string, delegatePositionName?: string) => {

    if (inviteAcceptors && inviteAcceptors.filter((x) => x.sectionType === sectionType && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const newData: InviteAcceptorModel = {
        id: undefined,
        inviteId: data.id,
        sequence: inviteAcceptors.length + 1,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        isDisabled: false,
        status: 'Draft',
        approvedDateAt: undefined,
        remark: '',
        createdAt: new Date(),
        updatedAt: undefined,
        sectionCode: ProcurementSection.APPROVER,
        sectionSequence: sectionSequence!,
        sectionType: sectionType,
        delegateUserId: delegateUserId,
        delegateFullName: delegateFullName,
        delegateDepartmentName: delegateDepartmentName,
        delegatePositionName: delegatePositionName,
        sectionId: '',
        sectionName: '',
        delegateEmployeeCode: '',
        delegateRangeDate: '',
      };
      setInviteAcceptors((inviteAcceptors) => [...inviteAcceptors, newData]);
    }
  };

  const handlerOnChangeCheckbox = (value: boolean, index: number) => {
    const data = [...inviteAcceptors];
    data[index].isDisabled = value;
    setInviteAcceptors(data);
  };

  const handlerOnChangeRemark = (value: string, index: number) => {
    const data = [...inviteAcceptors];
    data[index].remark = value;
    setInviteAcceptors(data);
  };

  const handlerOnChangeCommitteeCheckbox = (value: boolean, index: number) => {
    const data = [...committeeApprovers];
    data[index].isDisabled = value;

    if (!value) {
      data[index].remark = '';
    }

    setCommitteeApprovers(data);
  };

  const handlerOnChangeCommitteeRemark = (value: string, index: number) => {
    const data = [...committeeApprovers];
    data[index].remark = value;
    setCommitteeApprovers(data);
  };

  const removeApprover = (index: number) => {
    const temp: InviteAcceptorModel[] = [...inviteAcceptors];
    temp.splice(index, 1);
    setInviteAcceptors(temp);
  };

  function updaData(): InvitationDetailModel {
    const saveInviteAcceptors: InviteAcceptorModel[] = [];
    const newData = inviteData;

    // inviteAcceptors.sort((a, b) => a.sequence - b.sequence)
    //   .forEach((data, index) => {
    //     const newData: InviteAcceptorModel = {
    //       id: data.id,
    //       inviteId: data.inviteId,
    //       sequence: index + 1,
    //       userId: data.userId,
    //       fullName: data.fullName,
    //       departmentName: data.departmentName,
    //       positionName: data.positionName,
    //       isDisabled: data.isDisabled,
    //       status: data.status,
    //       approvedDateAt: data.approvedDateAt,
    //       remark: data.remark,
    //       createdAt: data.createdAt,
    //       updatedAt: data.updatedAt,
    //       sectionCode: data.sectionCode,
    //       sectionSequence: data.sectionSequence!,
    //       sectionType: data.sectionType,
    //       delegateUserId: data.delegateUserId,
    //       delegateFullName: data.delegateFullName,
    //       delegateDepartmentName: data.delegateDepartmentName,
    //       delegatePositionName: data.delegatePositionName,
    //       sectionId: data.sectionId,
    //       sectionName: data.sectionName,
    //       delegateEmployeeCode: data.delegateEmployeeCode,
    //       delegateRangeDate: data.delegateRangeDate,
    //       acceptDate: data.acceptDate,
    //       acceptRemark: data.acceptRemark,
    //       rejectDate: data.rejectDate,
    //       rejectRemark: data.rejectRemark,
    //     };

    //     saveInviteAcceptors.push(newData);
    //   });

    // newData.inviteAcceptors = saveInviteAcceptors;
    newData.inviteCommittees = committeeApprovers;
    newData.documentId = docId;
    return newData;
  }

  const handleSetFile = (documents: DocumentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    const newUpdFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };
      setFiles((pre) => [...pre, newFile]);
      newUpdFiles.push(file);
    }

    if (newUpdFiles && newUpdFiles.length > 0 && inviteData.id) {
      const res = await InvitationServices.onAttachmentInvitationDocumentByIdAsync(inviteData.id, newUpdFiles);
      handleSetFile(res.data);

      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('อัปโหลดเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('อัปโหลดเอกสารแนบสำเร็จ');
      await getInvitationDocumentByIdAsynce(inviteData.jorPor05Id, inviteData.id);
    }
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    const delId = newArray.filter((i) => i.name === docName)[0].id;
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName && delId) {
      const res = await InvitationServices.deleteInvitationDocumentByIdAsync(data.id, delId);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('ลบเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('ลบเอกสารแนบสำเร็จ');
      await getInvitationDocumentByIdAsynce(inviteData.jorPor05Id, inviteData.id);
    }
  }, [files, data.id]);

  const onSave = async () => {
    collaboraRef.current?.clickSave();
    submitForm();

    // if (!inviteAcceptors || inviteAcceptors.length === 0) {
    //   toast.warn('กรุณาระบุผู้มีอำนาจเห็นชอบ');
    //   return;
    // }

    // if (inviteAcceptors.some(i => !i.positionOnBoardId)) {
    //   toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');

    //   return;
    // }

    if (committeeApprovers.filter(x => x.isDisabled && (x.remark === '' || x.remark === null || x.remark === undefined)).length > 0) {
      toast.warn('กรุณากรอกหมายเหตุ');

      return;
    }

    const UpdData = updaData();

    collaboraRef.current?.clickSave();

    setTimeout(async () => {

      const res = await InvitationServices.updateInvitationAsync(UpdData, []);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('บันทึกข้อมูลไม่สำเร็จ');
        return;
      }

      toast.success('บันทึกข้อมูลสำเร็จ');

      if (res.data.id) {
        getInvitationByIdAsynce(inviteData.jorPor05Id, inviteData.id);
      }

    }, 500);
  };

  const getInvitationByIdAsynce = async (jorPor05Id: string, id?: string) => {
    const response = await InvitationServices.getInvitationByIdAsync(jorPor05Id, id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data.dto);
      setInviteData(response.data.dto);
      navigate(`/procurement-invitation-order/detail/${jorPor05Id}/id/${id}`);
    }
  };

  const onPending = async () => {
    collaboraRef.current?.clickSave();
    submitForm();
    // if (!inviteAcceptors || inviteAcceptors.length === 0) {
    //   toast.warn('กรุณาระบุผู้มีอำนาจเห็นชอบ');
    //   return;
    // }

    // if (!inviteData.inviteEntrepreneurs || inviteData.inviteEntrepreneurs.length === 0) {
    //   toast.warn('กรุณาระบุผู้ประกอบการที่ต้องการเชิญชวน');
    //   return;
    // }

    if (committeeApprovers.filter(x => x.isDisabled && (x.remark === '' || x.remark === null || x.remark === undefined)).length > 0) {
      toast.warn('กรุณากรอกหมายเหตุ');

      return;
    }

    const UpdData = updaData();

    collaboraRef.current?.clickSave();

    setTimeout(async () => {

      const res = await InvitationServices.updateInvitationAsync(UpdData, []);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('ส่งเห็นชอบ/อนุมัติไม่สำเร็จ');
        return;
      }

      const resPending = await InvitationServices.onPendingAsync(inviteData.id);

      if (resPending.status !== HttpStatusCode.OK) {
        toast.error('ส่งเห็นชอบ/อนุมัติไม่สำเร็จ');
        return;
      }

      toast.success('ส่งเห็นชอบ/อนุมัติสำเร็จ');
      setShowPendingModal(false);
      getInvitationByIdAsynce(inviteData.jorPor05Id, inviteData.id);
      window.location.reload();
    }, 500);
  };

  const onReject = async () => {
    if (!rejectRemark) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    const res = await InvitationServices.onRejectAsync(inviteData.id, rejectRemark);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
      return;
    }

    toast.success('ส่งกลับแก้ไขสำเร็จ');
    setShowRejectModal(false);
    setRejectRemark('');
    getInvitationByIdAsynce(inviteData.jorPor05Id, inviteData.id);
  };

  const onApprove = async () => {
    const res = await InvitationServices.onApproveAsync(inviteData.id, approveRemark);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('อนุมัติไม่สำเร็จ');
      return;
    }

    setApproveRemark('');
    toast.success('อนุมัติสำเร็จ');
    setShowApproveModal(false);
    getInvitationByIdAsynce(inviteData.jorPor05Id, inviteData.id);
  };

  const onBack = async () => {
    const UpdData = updaData();
    setData(UpdData);
    onClickBack();
  };

  const onNext = async () => {
    const UpdData = updaData();
    setData(UpdData);
    onClickNext();
  };

  const getInvitationDocumentByIdAsynce = async (jorPor05Id: string, id?: string) => {
    const response = await InvitationServices.getInvitationByIdAsync(jorPor05Id, id);

    if (response.status === HttpStatusCode.OK) {
      if (response.data.dto.documents) {
        setInviteData({ ...data, documents: response.data.dto.documents });
      }
    }
  };

  const onRecall = async () => {
    const res = await InvitationServices.onRecallAsync(inviteData.id);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('เรียกคืนแก้ไขไม่สำเร็จ');
      return;
    }

    await getInvitationByIdAsynce(inviteData.jorPor05Id, inviteData.id);
    toast.success('เรียกคืนแก้ไขสำเร็จ');
    setShowRecall(false);
  };

  const getPositionOnBoardAsync = async () => {
    const res = await shareValue.getPositionOnBoardListAsync();

    setPositionOnBoardList(res.data);
  };

  // const onPositionOnBoardChange = (userId: string, value: string) => {
  //   const newInviteAcceptors = [...inviteAcceptors];
  //   const index = newInviteAcceptors.findIndex(c => c.userId === userId);

  //   if (index >= 0) {
  //     newInviteAcceptors[index].positionOnBoardId = value;

  //     setInviteAcceptors(newInviteAcceptors);
  //   }
  // };

  return (
    <div className='document'>
      {/* <Acceptors
        data={inviteAcceptors.length > 0 ? inviteAcceptors : data.inviteAcceptors}
        visible={!isDisabled}
        isDepartmentDirector={false}
        processType={PROCESSTYPE.ApproveProcurementInviteProcess}
        budgetAmount={data.financialAmount}
        onSetSectionType={(type) => setSectionType(type)}
        onAcceptorModalShow={() => setShowInvitationApprovers(!showInvitationApprovers)}
        onSetSectionSequence={(section) => setSectionSequence(section)}
        onAcceptorDataChange={(data) => setInviteAcceptors(data)}
      /> */}
      <Card className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <Title
              text='ผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้าง'
              className='fs-5 text-primary'
            />
            {openCollapse2 ? (
              <ArrowCollapse onClick={() => setOpenCollapse2(!openCollapse2)} openCollapse={openCollapse2} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse2(true)} openCollapse={openCollapse2} />)}
          </div>
          <div className='d-flex gap-2'>
            <InvitationStatus
              value={inviteData.status}
            />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>

        <Collapse in={openCollapse2}>
          <div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>ลำดับ</th>
                      <th style={{ width: 200 }}>ชื่อ-นามสกุล</th>
                      <th style={{ width: 200 }}>ฝ่าย/สำนัก</th>
                      <th style={{ width: 200 }}>ตำแหน่ง</th>
                      <th style={{ width: 200 }}>ตำแหน่งในคณะกรรมการ</th>
                      <th style={{ width: 200 }}>สถานะ</th>
                      <th style={{ width: 200 }}>วันที่เห็นชอบ</th>
                      <th style={{ width: 200 }}>ไม่สามารถปฏิบัติงานได้</th>
                      <th style={{ width: 200 }}>หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {committeeApprovers.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr className='text-center'
                          key={index}>
                          <td>{index + 1}</td>
                          <td className='text-start'>{data.fullName}</td>
                          <td className='text-start'>{data.departmentName}</td>
                          <td className='text-start'>{data.positionName}</td>
                          <td className='text-center'>
                            <Selector
                              className='primary'
                              items={positionOnBoardItems}
                              value={data.positionOnBoardId}
                              disabled
                            />
                          </td>
                          <td>
                            <TorStatusCommitteeApprovers value={data.approverStatus} />
                          </td>
                          <td>{data.approvedDateAt ? thaiFormatDateWithSlash(data.approvedDateAt) : ''}</td>
                          <td>
                            {(data.positionOnBoardId !== PositionOnBoardIdEnum.Chairman) && (
                              <Check
                                className='d-inline-block'
                                value={data.isDisabled}
                                onChange={(val) => handlerOnChangeCommitteeCheckbox(val, index)}
                                disabled={data.userId === userId
                                  || (data.approverStatus === InvitationStatusConstant.Approved
                                    || (readonly && inviteData.status !== InvitationStatusConstant.WaitingForApprove))}
                              />
                            )}
                          </td>
                          <td>
                            {(data.positionOnBoardId !== PositionOnBoardIdEnum.Chairman) && (
                              <Input
                                value={data.remark}
                                onChange={(val) => handlerOnChangeCommitteeRemark(val, index)}
                                rule={{ required: data.isDisabled }}
                                disabled={!data.isDisabled
                                  || data.approverStatus === InvitationStatusConstant.Approved
                                  || (readonly && inviteData.status !== InvitationStatusConstant.WaitingForApprove)}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>
      <Card className='mt-3'>
        <div className='d-flex justify-content-between my-3'>
          <Title text='เอกสารรายงานหนังสือเชิญชวนผู้ประกอบการ'
            className='fs-5 text-primary' />
        </div>
        <Collabora
          docId={docId}
          docName='doc-01'
          ref={collaboraRef}
          readonly={isDisableForm} />
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              inviteId={inviteData.id}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onBack}
          text="ย้อนกลับ"
        />
        <div>
          {
            (inviteData.status === InvitationStatusConstant.WaitingForApprove
              && isCanRecall) && (
              <ButtonCustom
                onClick={() => setShowRecall(true)}
                icon='undo'
                variant='danger'
                text="เรียกคืนแก้ไข"
              />
            )
          }
          {
            ((inviteData.status === InvitationStatusConstant.Draft
              || inviteData.status === InvitationStatusConstant.Rejected
              || inviteData.status === InvitationStatusConstant.WaitingForApprove)
              && inviteData.isResponsible) && (
              <ButtonCustom
                onClick={onSave}
                text="บันทึก"
              />
            )
          }
          {
            ((inviteData.status === InvitationStatusConstant.Draft ||
              inviteData.status === InvitationStatusConstant.Rejected) &&
              inviteData.isResponsible) && (
              <ButtonCustom
                onClick={() => setShowPendingModal(true)}
                icon="checked"
                text="ส่งอนุมัติ/เห็นชอบ"
              />
            )
          }
          {
            (isQueueApprover && inviteData.status === InvitationStatusConstant.WaitingForApprove) && (
              <>
                <ButtonCustom
                  onClick={() => setShowRejectModal(true)}
                  variant='danger'
                  icon="undo"
                  text="ส่งกลับแก้ไข"
                />
                <ButtonCustom
                  icon="checked"
                  variant='success'
                  onClick={() => setShowApproveModal(true)}
                  text='เห็นชอบ'
                />
              </>
            )
          }
        </div>
        <div style={{ width: 120 }}></div>
        {/* <Button
          variant='outline-primary'
          onClick={onNext}
          className='me-2 px-3'
        >
          ถัดไป
          <MdArrowForward className='ms-2 pb-1 fs-5' />
        </Button> */}
      </div>
      <HistoryModal
        title='ประวัติการใช้งานจัดทำคำสั่งแต่งตั้งคณะกรรมาการจัดซื้อจัดจ้างและคณะกรรมการตรวจรับ'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={data.id}
      />
      {/* <UserApproveModal
        show={showInvitationApprovers}
        onHide={() => setShowInvitationApprovers(!showInvitationApprovers)}
        onSelectItem={onSelectEmpApprover}
      /> */}
      <SearchBuNameModal
        total={userTotal}
        show={showInvitationApprovers}
        onHide={() => setShowInvitationApprovers(!showInvitationApprovers)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName) => onSelectEmpApprover(id, name, department!, position!, inviteAcceptors.length + 1, sectionSequence, sectionType, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <Modal
        show={showPendingModal}
        size='lg'
        onHide={() => setShowPendingModal(!showPendingModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งอนุมัติ/เห็นชอบหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingModal(!showPendingModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onPending}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejectModal}
        size='lg'
        onHide={() => setShowRejectModal(!showRejectModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRejectRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowRejectModal(!showRejectModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                variant='danger'
                onClick={onReject}
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApproveModal}
        size='lg'
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ให้ความเห็นชอบ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                rule={{ required: false }}
                onChange={(val) => setApproveRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApproveModal(!showApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onApprove}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRecall}
        size='lg'
        onHide={() => setShowRecall(!showRecall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowRecall(!showRecall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onRecall}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}
