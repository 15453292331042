import {
  account,
  auditLog,
  program,
} from 'services';

export const su05Loader = async () => {
  const [list, programList, userList] = await Promise.all([
    auditLog.getAuditLogListAsync(10, 1),
    program.getProgramsAllAsync(),
    account.getUserDdlAsync(),
  ]);

  return {
    data: list.data.data,
    totalCount: list.data.totalCount,
    programList: programList.data,
    userList: userList.data,
  };
};
