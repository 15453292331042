export const enum SectionType {
  JorPor = 'JorPor',
  NoneJorPor = 'NoneJorPor',
}

export enum SectionJorPorType {
  JORPOR_SUPERVISOR_USER = 'JorPorSupervisorUser', // หัวหน้าส่วน
  JORPOR_ASSISTANT_PRINCIPLE_USER = 'JorPorAssistantPrincipleUser', // ผู้ช่วยผู้อำนวยการจัดหาและพัสดุ
  JORPOR_PRINCIPLE_USER = 'JorPorPrincipleUser', // ผู้อำนวยการจัดหาและพัสดุ
  ASSISTANT_MD = 'AssistantMd', // ผู้ช่วยกรรมการ
  DEPUTY_MD = 'DeputyMd', // รองกรรมการผู้จัดการ
  MD = 'Md', // กรรมการผู้จัดการ
}

export const enum Level {
  Segment = 'Segment',
  Department = 'Department',
  Line = 'Line',
  Group = 'Group',
  Head = 'Head',
}