import { ButtonCustom, ContractReceiveStatus, Currency, DatePicker, Input, InputTextArea, Modal } from 'components';
import { StatusCMDelivery } from 'components/StatusCMDelivery';
import { StatusCMReceived } from 'components/StatusCMReceived';
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { useNavigate } from 'react-router';
import { ContractManagementDeliveryModel } from '../../../../../models/CM/CM03Models';
import {
  HttpStatusCode,
  thaiFormatDateWithSlash,
  THCurrency,
} from '../../../../../utils';
import { generateUniqueId } from '../../../../../utils/helper';
import { Button, Modal as ModalBT, } from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import CM03Service from 'services/CM/CM03Service';
import toast from 'utils/toast';
import { ContractType } from 'constant/PurchaseOrderApprovalConstant';

interface Props {
  index: number;
  contractId: string;
  data: ContractManagementDeliveryModel;
  isMa: boolean;
  contractRecordType: string;
  onSuccessAddDelivery: () => void;
  onSelectString?: (
    index: number,
    key: string,
    value: string,
  ) => void;
  onSelectNumber?: (
    index: number,
    key: string,
    value: number,
  ) => void;
  onSelectDate?: (
    index: number,
    key: string,
    value: Date,
  ) => void;
  onRemove?: (index: number) => void;
}

export function TableExpandable(props: Props) {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string>('');

  function KeyboardArrow(showDetails: Boolean) {
    return (
      <>
        ดูรายเอียด{
          (showDetails) ?
            <MdKeyboardArrowUp /> :
            <MdKeyboardArrowDown />
        }
      </>
    );
  }

  const onRemoveAsync = async (deliveryId: string) => {
    const res = await CM03Service.deleteDeliveryAsync(props.contractId, deliveryId);

    if (res.status === HttpStatusCode.CREATED) {
      toast.success('ลบข้อมูลสำเร็จ');
      props.onSuccessAddDelivery();
      setShowModal(false);
    }
  };

  const openModalDelete = (id: string) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const goToPage = (deliveryId: string) => {
    navigate(`/contract-management/detail/${props.contractId}/delivery/${deliveryId}`);
  };

  const onRemoveItem = (index: number) => {
    if (props.onRemove) {
      props.onRemove(index)
    }
  };

  const OnChangeStringData = (index: number, key: string, value: string) => {
    if (props.onSelectString) {
      props.onSelectString(index, key, value)
    }
  };

  const OnChangeNumberData = (index: number, key: string, value: number) => {
    if (props.onSelectNumber) {
      props.onSelectNumber(index, key, value)
    }
  };

  const OnChangeDateData = (index: number, key: string, value: Date) => {
    if (props.onSelectDate) {
      props.onSelectDate(index, key, value)
    }
  };

  return (
    <>
      <tr className='text-center'
        key={props.index}>
        {props.contractRecordType === ContractType.NonContract
          ? (
            <>
              <td>
                <div>
                  <Input
                    textCenter
                    value={props.data.phaseNumber}
                    disabled={props.data.status === ContractReceiveStatus.Delivered}
                    rule={{ required: true }}
                    onChange={(val) => OnChangeNumberData(props.index, 'phaseNumber', Number(val))} />
                </div>
              </td>
              <td>
                <Input
                  textCenter
                  value={props.data.periodDay}
                  disabled={props.data.status === ContractReceiveStatus.Delivered}
                  rule={{ required: true }}
                  onChange={(val) => OnChangeNumberData(props.index, 'periodDay', Number(val))} />
              </td>
              <td>
                <DatePicker
                  value={props.data.deliveryDueDate}
                  disabled={props.data.status === ContractReceiveStatus.Delivered}
                  rule={{ required: true }}
                  onChange={(val) => OnChangeDateData(props.index, 'deliveryDueDate', val)} />
              </td>
              <td>
                <div >
                  <Currency
                    value={props.data.amount}
                    disabled={props.data.status === ContractReceiveStatus.Delivered}
                    rule={{ required: true }}
                    onChange={(val) => OnChangeNumberData(props.index, 'amount', Number(val))} />
                </div>
              </td>
              <td >
                <div>
                  <Currency
                    value={props.data.percent}
                    maxValue={100}
                    disabled={props.data.status === ContractReceiveStatus.Delivered}
                    rule={{ required: true }}
                    onChange={(val) => OnChangeNumberData(props.index, 'percent', Number(val))} />
                </div>
              </td>
              <td className='text-start'>
                <InputTextArea
                  value={props.data.detail}
                  disabled={props.data.status === ContractReceiveStatus.Delivered}
                  onChange={(val) => OnChangeStringData(props.index, 'detail', val)} />
              </td>
              <td>
                <div className='d-flex'>
                  <StatusCMDelivery systemStatue={props.data.status}
                    systemName='cm-03' />
                </div>
              </td>
            </>
          )
          : (<>
            <td>{props.data.phaseNumber}</td>
            <td>{props.data.periodDay}</td>
            <td>{thaiFormatDateWithSlash(props.data.deliveryDueDate) ?? '--/--/----'}</td>
            <td>{`${THCurrency(props.data.amount)} (${props.data.percent}%)`}</td>
            <td className='text-start'>
              {props.isMa ? (
                <ul className='inside'>
                  <li>{props.data.remark}</li>
                </ul>
              ) : <ul className='inside'>
                <li>{props.data.detail}</li>
              </ul>}
            </td>
            <td>
              <div className='d-flex'>
                <StatusCMDelivery systemStatue={props.data.status}
                  systemName='cm-03' />
              </div>
            </td>
          </>)}
        <td className='text-end'>
          <ButtonCustom variant={props.data.status === 'Delivered' ? 'outline-success' : 'outline-primary'}
            text='ไปยังรายการบันทึกส่งมอบ'
            icon='save-deliver'
            iconAlignRight
            disabled={!props.data.id}
            onClick={() => goToPage(props.data.id ?? '')} />
        </td>
        <td>
          <ButtonCustom
            variant='light border-0'
            onClick={() => setExpanded(!expanded)}
            iconAlignRight
            text={KeyboardArrow(expanded)}
          />
        </td>
        <td>
          {((props.isMa || props.contractRecordType === ContractType.NonContract) && (props.data.id && props.data.status !== ContractReceiveStatus.Delivered)) && (
            <div className='d-flex justify-content-end'>
              <Button
                variant='danger'
                className='d-flex align-items-center gap-2'
                onClick={() => openModalDelete(props.data.id ?? '')}
              >
                <FaTrashAlt />
              </Button>
            </div>
          )}
          {(!props.data.id) && (
            <div className='d-flex justify-content-end'>
              <Button
                variant='danger'
                className='d-flex align-items-center gap-2'
                onClick={() => onRemoveItem(props.index)}
              >
                <FaTrashAlt />
              </Button>
            </div>
          )}
        </td>
      </tr >
      {
        expanded && (
          <tr className='text-center data-info-full'>
            <td colSpan={10}>
              <Table>
                <thead>
                  <tr className='text-center'>
                    <th style={{ width: '10%' }}
                      className='text-muted'>ลำดับ
                    </th>
                    <th style={{ width: '10%' }}
                      className='text-muted'>วันที่ตรวจรับ
                    </th>
                    <th style={{ width: '70%' }}
                      className='text-muted'>หมายเหตุการตรวจรับ
                    </th>
                    <th style={{ width: 100 }}
                      className='text-muted'>สถานะการตรวจรับ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.data.contractManagementReceives?.sort(cmr => cmr.sequence)
                    .map((cmr, index) => (
                      <tr key={generateUniqueId(cmr.id)}>
                        <td>{index + 1}</td>
                        <td>{thaiFormatDateWithSlash(cmr.receivedDate) ?? '-'}</td>
                        <td className='text-start'>
                          <ul className='inside'>
                            <li>{cmr.remark}</li>
                          </ul>
                        </td>
                        <td>
                          <div className='d-flex'>
                            <StatusCMReceived systemStatus={cmr.status}
                              systemName='cm-03' />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </td>
          </tr>
        )
      }
      <Modal
        show={showModal}
        size='lg'
        onHide={() => setShowModal(!showModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบรายการ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการลบรายการส่งมอบ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-danger'
                onClick={() => setShowModal(!showModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  onRemoveAsync(deleteId);
                }}
              >
                ยืนยันลบข้อมูล
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}
