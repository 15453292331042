import {
  ButtonCustom,
  Card,
  ContractAuditStatus,
  DatePicker,
  HistoryModal,
  Input,
  ModalReply,
  Selector,
  Table,
  TabsFilter,
  Modal,
  InputTextArea,
} from 'components';
import Title from 'components/Controls/Title';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import { FaHistory, FaRedo, FaSave, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { MdCircle } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { Context } from '.';
import { HttpStatusCode, THCurrency, submitForm, thaiFormatDateWithSlash, useAppContext } from 'utils';
import { ContractAuditAttachment, ContractAuditListSelectedModel, ContractAuditModel } from 'models/CM/CM04Models';
import { StatusDetail, TypeStatus } from 'components/StatusDetail';
import UploadFile from '../UploadFile';
import { IFile } from 'models';
import toast from 'utils/toast';
import CM04Service from 'services/CM/CM04Service';
import { BsCheckCircle } from 'react-icons/bs';
import { UploadFileModal } from '../Modal/UploadFileModal';
import UploadFileAuditListSelected from '../Modal/UploadFileAuditListSelected';
import { TabsFilterCM04 } from '../TabsFilterCM04';
import { JorPorCode } from 'constant/basicInformation';

interface IExampleData {
  date: string;
  no: string;
  name: string;
  partner: string;
  price: number;
  note: string;
  attachment: attachment[];
}

interface attachment {
  id?: string;
  fileName: string;
  createdBy?: string;
  createdDate?: string;
  documentType?: string;
}

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

export default function Step1({
  onClickNext,
  onClickBack,
}: Props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [listSelect, setListSelect] = useState<IExampleData[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const { data, setData } = useContext(Context);
  const [showUploadfile, setShowUploadfile] = useState(false);
  const [contractAuditAttachmentData, setContractAuditAttachmentData] = useState<ContractAuditAttachment[]>([]);
  const [auditSelectedAttachmentData, setAuditSelectedAttachmentData] = useState<ContractAuditAttachment[]>([]);
  const [contractAuditListSelectedData, setContractAuditListSelectedData] = useState<ContractAuditListSelectedModel[]>([]);
  const [contractAuditData, setContractAuditData] = useState<ContractAuditModel[]>([]);
  const [showActivity, setShowActivity] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [filesAuditListSelected, setFilesAuditListSelected] = useState<IFile[]>([]);
  const [documentDate, setDocumentDate] = useState<Date>();
  const [contractStartDate, setContractStartDate] = useState<Date>();
  const [contractEndDate, setContractEndDate] = useState<Date>();
  const [showRemark, setShowRemark] = useState(false);
  const [remarkSelected, setRemarkSelected] = useState('');
  const [auditListSelectedId, setAuditListSelectedId] = useState('');
  const [showSelectedModal, setShowSelectedModal] = useState<boolean>(false);
  const { departmentCode } = useAppContext();

  useEffect(() => {
    if (data) {
      if (data.contractAuditListSelecteds) {
        setContractAuditListSelectedData(data.contractAuditListSelecteds)
      }

      if (data.attachments) {
        handleSetFile(data.attachments);
      }

      if (data.documentDate) {
        setDocumentDate(data.documentDate)
      }

      if (data.contractStartDate) {
        setContractStartDate(data.contractStartDate)
      }

      if (data.contractEndDate) {
        setContractEndDate(data.contractEndDate)
      }

      if (data.status === ContractAuditStatus.Approved || data.status === ContractAuditStatus.Pending) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }

    }
  }, [data]);

  const removeContractAuditList = (i: number) => {

    const temp: ContractAuditListSelectedModel[] = [...contractAuditListSelectedData];
    temp.splice(i, 1);
    setContractAuditListSelectedData(temp);

  };

  const getContractAuditListSelectedAsync = useCallback(async (documentDate: Date, contractStartDate: Date, contractEndDate: Date, id?: string) => {

    const res = await CM04Service.getContractAuditListSelectedAsync(documentDate, contractStartDate, contractEndDate, id);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    setContractAuditListSelectedData(res.data);
    toast.success('ดึงรายการสัญญาเพิ่มเติมสำเร็จ');

  }, []);

  const onSelectedContractAuditList = (value: Date, props: string) => {

    setData({
      ...data,
      [props]: value,
    });

    if (props === 'documentDate') {
      setDocumentDate(value)
      if (value && contractStartDate && contractEndDate) {
        getContractAuditListSelectedAsync(value, contractStartDate, contractEndDate, data.id);
      }
    }

    if (props === 'contractStartDate') {
      setContractStartDate(value)
      if (documentDate && value && contractEndDate) {
        getContractAuditListSelectedAsync(documentDate, value, contractEndDate, data.id);
      }
    }

    if (props === 'contractEndDate') {
      setContractEndDate(value)
      if (documentDate && contractStartDate && value) {
        getContractAuditListSelectedAsync(documentDate, contractStartDate, value, data.id);
      }
    }
  };

  const onSubmitAsync = async () => {
    submitForm();

    if (!data.documentDate
      || !data.contractStartDate
      || !data.contractEndDate) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
      return;
    }

    const docUpload: File[] = [];
    if (files) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });

      data.files = [...docUpload];
    }

    if (data.id) {
      const httpStatus = HttpStatusCode.OK;
      const updateDataModel: ContractAuditModel = {
        id: data.id,
        documentDate: data.documentDate,
        contractStartDate: data.contractStartDate,
        contractEndDate: data.contractEndDate,
        acceptors: data.acceptors,
        contractAuditListSelecteds: contractAuditListSelectedData,
        files: docUpload,
        activities: data.activities,
        attachments: data.attachments,
        remark: data.remark,
        status: data.status,
        contractAuditNumber: data.contractAuditNumber,
        documentId: undefined,
        documentAuditorId: undefined,
        documentDirectorId: undefined,
        totalAmount: data.totalAmount,
      };

      if (httpStatus === HttpStatusCode.OK) {
        const res = await CM04Service.updateContractAuditAsync(updateDataModel);

        if (res.status === HttpStatusCode.OK) {

          toast.success('บันทึกข้อมูลสำเร็จ');
          getContractAuditByIdAsynce(data.id);

        } else {
          toast.error('บันทึกข้อมูลไม่สำเร็จ');
        }
      }
    } else {
      const createDataModel: ContractAuditModel = {
        id: data.id,
        documentDate: data.documentDate,
        contractStartDate: data.contractStartDate,
        contractEndDate: data.contractEndDate,
        acceptors: data.acceptors,
        contractAuditListSelecteds: contractAuditListSelectedData,
        files: docUpload,
        activities: data.activities,
        attachments: data.attachments,
        remark: data.remark,
        status: data.status,
        contractAuditNumber: data.contractAuditNumber,
        documentId: undefined,
        documentAuditorId: undefined,
        documentDirectorId: undefined,
        totalAmount: data.totalAmount,
      };

      const res = await CM04Service.createContractAuditAsync(createDataModel);
      if (res.status === HttpStatusCode.OK) {
        toast.success('บันทึกข้อมูลสำเร็จ');
        const newId: string = res.data;
        getContractAuditByIdAsynce(newId);
        navigate(`/cm/cm04/detail/${newId}`);

      } else {
        toast.error('บันทึกข้อมูลไม่สำเร็จ');
      }
    }
  };

  const onOpenModalRemark = async (auditListSelectedId: string) => {
    setShowRemark(true);
    setAuditListSelectedId(auditListSelectedId);
  };

  const onSubmitRemarkSelectedAsynce = async (auditListSelectedId: string) => {
    submitForm();
    if (!remarkSelected) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    if (data.id) {
      const response = await CM04Service.saveRemarkSelectedAsync(data.id, auditListSelectedId, remarkSelected);

      if (response.status === HttpStatusCode.OK) {
        setData(response.data);
        toast.success('บันทึกข้อมูลสำเร็จ');
        getContractAuditByIdAsynce(data.id);
      }
    } else {
      toast.error('บันทึกข้อมูลไม่สำเร็จ');
    }

    setShowRemark(false);
  };

  const getContractAuditByIdAsynce = async (id: string) => {
    const response = await CM04Service.getContractAuditByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
    }
  };

  const reGetContractAuditListSelectedList = async (documentDate: Date, contractStartDate: Date, contractEndDate: Date) => {
    getContractAuditListSelectedAsync(documentDate, contractStartDate, contractEndDate);
  };

  const getAttachmentAuditByIdAsync = async (id: string) => {
    const response = await CM04Service.getAttachmentAuditByIdAsync(id);
    if (response.status === HttpStatusCode.OK) {
      handleSetFile(response.data.attachments);
    }
  };

  const handleSetFile = (documents: ContractAuditAttachment[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.name,
        file: null,
        createdByUserId: document.createdByUserId,
        createdByUserFullName: document.createdByUserFullName,
        createdAt: document.createdAt,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleAuditFileChange = async (files: File[]) => {
    const newUpdFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };
      setFiles((pre) => [...pre, newFile]);
      newUpdFiles.push(file);
    }

    if (data.id) {
      const res = await CM04Service.uploadAttachmentAuditAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getAttachmentAuditByIdAsync(data.id);
      } else {
        toast.error('อัปโหลดไฟล์ไม่สำเร็จ');
      }
    }
    return;
  };

  const removeAuditItemAsync = useCallback(async (index: number, docId: string) => {

    if (data.id && docId) {
      const res = await CM04Service.removeAttachmentAuditAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getAttachmentAuditByIdAsync(data.id);
        return;
      }
    }

    toast.error('ลบไฟล์ไม่สำเร็จ');

  }, [files, data,]);

  const getAttachmentAuditListSelectedByIdAsync = async (id: string) => {
    const response = await CM04Service.getAttachmentAuditListSelectedByIdAsync(id);
    if (response.status === HttpStatusCode.OK) {
      setFilesAuditListSelected(response.data.attachments)
    }
  };

  const onHideModal = () => {
    setShowUploadfile(false);
  };

  const onOpenModalUploadFile = async (auditListSelectedId: string, file: ContractAuditAttachment[]) => {
    setShowUploadfile(true);

    setAuditListSelectedId(auditListSelectedId);
    handleAuditListSelectedSetFile(file);
  };

  const handleAuditListSelectedSetFile = (documents: ContractAuditAttachment[]) => {
    setFilesAuditListSelected([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.name,
        file: null,
        createdByUserId: document.createdByUserId,
        createdByUserFullName: document.createdByUserFullName,
        createdAt: document.createdAt,
      };

      setFilesAuditListSelected((pre) => [...pre, newFile]);
    }
  };

  const handleAuditListSelectedFileChange = async (files: File[]) => {
    const newUpdFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };
      setFilesAuditListSelected((pre) => [...pre, newFile]);
      newUpdFiles.push(file);
    }

    if (data.id && auditListSelectedId) {
      const res = await CM04Service.uploadAttachmentAuditListSelectedAsync(
        auditListSelectedId,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getAttachmentAuditListSelectedByIdAsync(auditListSelectedId);
        getContractAuditByIdAsynce(data.id);
      } else {
        toast.error('อัปโหลดไฟล์ไม่สำเร็จ');
      }
    }
    return;
  };

  const removeAuditListSelectedItemAsync = useCallback(async (index: number, docId: string, indexId: string) => {

    if (data.id && indexId) {
      const res = await CM04Service.removeAttachmentAuditListSelectedAsync(
        indexId,
        docId,
      );

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getAttachmentAuditListSelectedByIdAsync(indexId!);
        getContractAuditByIdAsynce(data.id);
        return;
      }
    }

    toast.error('ลบไฟล์ไม่สำเร็จ');

  }, [files, data]);

  return (
    <div className='m01'>
      <Title
        text='รายงานสำนักงานการตรวจเงินแผ่นดินและกรมสรรพากร'
        className='fs-5 text-primary'
        extraElement={
          <div className='d-flex gap-2'>
            <StatusDetail value={data?.status} type={TypeStatus.ContractAudit} />
            <Button
              onClick={() => setShowActivity(!showActivity)}
              variant='outline-primary'>
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        }
      />
      <Row className='mt-4'>
        <Col sm={12}
          md={3}
          lg={3}
          xl={3}>
          <Input label='เลขที่เอกสาร'
            disabled
            value={data?.contractAuditNumber} />
        </Col>
        <Col sm={12}
          md={3}
          lg={3}
          xl={3}>
          <DatePicker label='วันที่ทำเอกสาร'
            rule={{ required: true }}
            placeholder='วันที่ทำเอกสาร'
            value={data.documentDate}
            onChange={(val) => onSelectedContractAuditList(val, 'documentDate')}
            disabled={isDisabled} />
        </Col>
        <Col sm={12}
          md={3}
          lg={3}
          xl={3}>
          <DatePicker label='วันที่ลงนามในสัญญาตั้งแต่'
            rule={{ required: true }}
            placeholder='วันที่ทำเอกสาร'
            value={data.contractStartDate}
            onChange={(val) => onSelectedContractAuditList(val, 'contractStartDate')}
            disabled={isDisabled} />
        </Col>
        <Col sm={12}
          md={3}
          lg={3}
          xl={3}>
          <DatePicker label='ถึงวันที่'
            rule={{ required: true }}
            placeholder='วันที่ทำเอกสาร'
            value={data.contractEndDate}
            onChange={(val) => onSelectedContractAuditList(val, 'contractEndDate')}
            disabled={isDisabled} />
        </Col>
        <div className='d-flex gap-2 mt-3'>
          <Button
            className='d-flex align-items-center gap-2'
            variant='outline-primary'
            disabled={isDisabled || (departmentCode !== JorPorCode.CODE)}
            onClick={() => {
              getContractAuditListSelectedAsync(data?.documentDate, data?.contractStartDate, data?.contractEndDate, data?.id);
            }}
          >
            <FaRedo />ดึงรายการสัญญาเพิ่มเติม
          </Button>
        </div>
      </Row>
      <TabsFilterCM04
        onClick={() => setShow(!show)}
        textNote1='สัญญาปกติ'
        textNote2='สัญญาเกิน 30 วัน'
        isDisabled={false}
        data={data.countContractTypes}
      />
      <Row>
        <Col sm={12}>
          <Table total={2}
            hidePagination>
            <thead>
              <tr className='text-center'>
                <th style={{ width: '3%' }}>ลำดับ</th>
                <th style={{ width: '3%' }}></th>
                <th style={{ width: '10%' }}>เลขที่สัญญา</th>
                <th style={{ width: '10%' }}>วันที่ลงนามในสัญญา</th>
                <th style={{ width: '26%' }}>ชื่อสัญญา</th>
                <th style={{ width: '10%' }}>คู่ค้า</th>
                <th style={{ width: '10%' }}>จำนวนเงิน (บาท)</th>
                <th style={{ width: '10%' }}>หมายเหตุ</th>
                <th style={{ width: 100 }}></th>
                <th style={{ width: 100 }}></th>
                <th style={{ width: 100 }}></th>
              </tr>
            </thead>
            <tbody>
              {
                contractAuditListSelectedData.map((item, index) => (
                  <>
                    <tr className='text-center'
                      key={index}>
                      <td className='text-center'>{index + 1}</td>
                      <td className='text-center'>
                        {
                          (item.isOverContractDate)
                            ? <MdCircle className='text-danger' />
                            : <MdCircle className='text-success' />
                        }
                      </td>
                      <td className='text-center'>{item.contractNumber}</td>
                      <td className='text-center'>{thaiFormatDateWithSlash(item.contractDate)}</td>
                      <td className='text-start'>{item.planName}</td>
                      <td className='text-start'>{item.entrepreneur_Name}</td>
                      <td className='text-end'>{THCurrency(item.offerTotalAmountVat)}</td>
                      <td className='text-start'> {item.remark}</td>
                      <td>
                        <ButtonCustom
                          iconAlignRight
                          text='ระบุหมายเหตุ'
                          variant='outline-primary'
                          icon='add-note'
                          onClick={() => onOpenModalRemark(item.id)}
                          disabled={(!data.id || isDisabled) || (departmentCode !== JorPorCode.CODE)} />
                      </td>
                      <td>
                        <Button
                          onClick={() => onOpenModalUploadFile(item.id, item.attachments)}
                          disabled={!data.id}>
                          อัปโหลดเอกสาร
                        </Button>
                      </td>
                      <td>
                        <div className='d-flex justify-content-center'>
                          <Button
                            onClick={() => removeContractAuditList(index)}
                            variant='danger'
                            disabled={isDisabled || (departmentCode !== JorPorCode.CODE)}
                          >
                            <FaTrashAlt />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </>
                ))
              }
            </tbody>
          </Table>
        </Col>
      </Row>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              files={files}
              handleFileChange={handleAuditFileChange}
              removeItem={removeAuditItemAsync}
              id={data.id!}
              disabled={!data.id}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <ButtonCustom text='กลับหน้าแรก'
          onClick={onClickBack}
          icon='arrowback'
          variant='outline-primary' />
        <div className='d-flex gap-3 align-items-center'>
          {(departmentCode === JorPorCode.CODE) && (data?.status === ContractAuditStatus.Draft
            || data?.status === ContractAuditStatus.Rejected || data?.status === undefined || data?.status === '')
            ? (
              <Button
                onClick={() => onSubmitAsync()}
                variant='success'
              >
                <FaSave className='me-1' />
                บันทึก
              </Button>
            ) : null}
        </div>

        <div style={{ width: 150 }} className='d-flex justify-content-end align-items-end'>
          <ButtonCustom
            variant='outline-primary'
            icon='arrowforward'
            iconAlignRight
            onClick={onClickNext}
            text='ถัดไป'
          />
        </div>
      </div>

      {/* <ModalReply
        show={show}
        onHide={() => setShow(!show)}
        title='หมายเหตุ'
        status='general'
        textInBtn='บันทึก'
      /> */}

      <HistoryModal
        title='ประวัติรายงานสำนักงานตรวจเงินแผ่นดินและหนังสือถึงสรรพากร'
        show={showActivity}
        onHide={() => setShowActivity(!showActivity)}
        data={data?.activities}
      />

      <Modal
        show={showRemark}
        size='lg'
        onHide={() => setShowRemark(!showRemark)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>หมายเหตุ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRemarkSelected(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowRemark(!showRemark)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                onClick={() => onSubmitRemarkSelectedAsynce(auditListSelectedId)}
              >
                <FaSave className='me-2 pb-1 fs-5' />
                บันทึก
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showSelectedModal}
        size='lg'
        onHide={() => setShowSelectedModal(!showSelectedModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการดึงรายการสัญญาเพิ่มเติมหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowSelectedModal(!showSelectedModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => reGetContractAuditListSelectedList(documentDate!, contractStartDate!, contractEndDate!)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      {/* <UploadFileModal
        data={auditSelectedAttachmentData}
        show={showUploadfile}
        onHide={() => setShowUploadfile(!showUploadfile)}
        id={auditListSelectedId}
        auditListSelectedId={data?.id}
      /> */}

      <Modal show={showUploadfile}
        size='xl'>
        <>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <h5 className='mb-1'>อัปโหลดเอกสารแนบ</h5>
            <FaTimes onClick={onHideModal} />
          </div>
          <Card className='mt-3'>
            <Title text='เอกสารแนบ'
              className='fs-5 text-primary' />
            <Row className='justify-content-center'>
              <Col sm={12}
                lg={6}>
                <UploadFileAuditListSelected
                  files={filesAuditListSelected}
                  handleFileChange={handleAuditListSelectedFileChange}
                  removeItem={removeAuditListSelectedItemAsync}
                  id={auditListSelectedId!}
                />
              </Col>
            </Row>
          </Card>
        </>
      </Modal>
    </div>
  );
}
