import {
  useEffect,
  useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import {
  FaCloudUploadAlt,
  FaFileAlt,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaTrashAlt,
} from 'react-icons/fa';
import { showConfirmModalAsync } from 'utils';
import toast from 'utils/toast';

export interface FileValue {
  id?: string;
  name: string;
  file?: File;
  fileName?: string;
  createdByUserId?: string;
  createdByUserFullName?: string;
  createdAt?: Date
}

interface Props {
  value?: FileValue[],
  onChange?: (files: File) => void;
  onRemove?: (index: number, id: string | undefined) => void;
  onDownload?: (index: number, id: string | undefined) => void;
  disabled?: boolean;
  disabledRemove?: boolean;
  userId?: string;
  limit?: number;
  fileTypes?: string[];
}

const fileTypes = ['doc', 'docx', 'xls', 'xlsx', 'csv', 'pdf', 'png', 'jpg', 'jpeg'];

export function UploadFile(props: Props) {
  const [files, setFiles] = useState<FileValue[]>([]);
  const [dragEnter, setDragEnter] = useState<boolean>(false);

  useEffect(() => {
    if (props.value?.length) {
      setFiles([...props.value]);
    }
  }, [props.value]);

  const handlerOnChange = (file: File) => {
    if (props.disabled) {
      return;
    }

    const uploadFileElements = document.getElementsByName('upload-file');

    if (uploadFileElements.length) {
      const input = uploadFileElements[0] as HTMLInputElement;

      input.value = '';
    }

    if (props.limit && files.length >= props.limit) {
      toast.warn(`จำกัดสูงสุด ${props.limit} ไฟล์`);

      return;
    }

    if (files.some((f) => f.name === file.name)) {
      toast.warn('ชื่อไฟล์ซ้ำ');

      return;
    }

    setFiles([
      ...files,
      {
        file,
        name: file.name,
      },
    ]);

    if (props.onChange) {
      props.onChange(file);
    }
  };

  const fileIcon = (name: string) => {
    switch (true) {
      case name.includes('.pdf'):
        return (<FaFilePdf />);
      case name.includes('.doc') || name.includes('.docx'):
        return (<FaFileWord />);
      case name.includes('.xls') || name.includes('.xlsx'):
        return (<FaFileExcel />);
      case name.includes('.csv'):
        return (<FaFileCsv />);
      case name.includes('.png') || name.includes('jpg') || name.includes('jpeg'):
        return (<FaFileImage />);
      default:
        return (<FaFileAlt />);
    }
  };

  const removeAsync = async (i: number, id: string | undefined) => {
    if (!await showConfirmModalAsync('คุณต้องการยืนยันลบไฟล์หรือไม่ ?')) {
      return;
    }

    files.splice(i, 1);
    setFiles([...files]);

    if (props.onRemove) {
      props.onRemove(i, id);
    }
  };

  const download = (index: number, file: FileValue) => {
    if (props.onDownload) {
      props.onDownload(index, file.id);
    }
  };

  const getFileTypes = () => {
    const types = (props.fileTypes ?? fileTypes).map(t => `.${t}`);

    return types.join(', ');
  };

  return (
    <div className='upload-file' draggable>
      {!props.disabled ? (
        <FileUploader
          handleChange={handlerOnChange}
          name='upload-file'
          types={props.fileTypes ?? fileTypes}
          onDraggingStateChange={setDragEnter}
          hoverTitle='&nbsp;'
          disabled={props.disabled}>
          <div className={`browse ${props.disabled ? 'disabled' : ''} ${dragEnter ? 'drag-enter' : ''}`}>
            <div className='icon'>
              <FaCloudUploadAlt className='text-primary' />
            </div>
            <span>Drag and drop or <span className='text-primary'>browse</span>.</span>
            <span className='fs-8'>
              รองรับไฟล์ที่มีนามสกุล {getFileTypes()} และมีขนาดไฟล์ไม่เกิน 10 MB
            </span>
          </div>
        </FileUploader>
      ) : null}
      <div className='file-list'>
        {files.map((f, i) => (
          <div key={f.name}
            className='file'>
            <div className='file-icon'>
              {fileIcon(f.name)}
            </div>
            <div className='detail'>
              <a
                onClick={() => download(i, f)}
                className='filename'
              >
                {f.name}
              </a>
            </div>
            {(!props.disabled && !props.disabledRemove)
              ? (
                <div className='trash'>
                  <Button
                    variant='danger'
                    className='d-flex align-items-center gap-2'
                    onClick={() => removeAsync(i, f.id)}
                  >
                    <FaTrashAlt />
                  </Button>
                </div>
              ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}
