import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Input,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { UserManualModel } from '../../../../../../models/CM/CM02Models';

const InstructionManualUnit = [
  { label: 'ชุด', value: '1' },
  { label: 'ชิ้น', value: '2' },
  { label: 'ฉบับ', value: '3' },
];

interface InstructionManualProps {
  instructionManualData: UserManualModel;
  onInstructionManualChange: (value: UserManualModel) => void;
  isDisabled?: boolean;
}

export function UserManual(props: InstructionManualProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [instructionManualData, setInstructionManualData] = useState<UserManualModel>(props.instructionManualData);

  useEffect(() => {
    props.onInstructionManualChange(instructionManualData);
  }, [instructionManualData]);

  useEffect(() => {
    if (props.instructionManualData && firstLoad) {
      setInstructionManualData(props.instructionManualData);
      setFirstLoad(false);
    }
  }, [props.instructionManualData, firstLoad]);

  const onTextChange = useCallback((key: string, value: string) => {
    setInstructionManualData((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  return (
    <Card className='mt-3'>
      <Title text='คู่มือการใช้งาน' className='fs-5 text-primary' />
      <Row className='mt-3'>
        <label className='mb-3'>คู่ค้าจะต้องส่งมอบคู่มือการใช้งาน ในวันส่งมอบสิ่งของตามสัญญา จำนวน</label>
        <Col sm={8} md={3}>
          <Input
            type='number'
            value={instructionManualData?.sendUserManualInDay}
            onChange={(value) => onTextChange('sendUserManualInDay', value)}
            textEnd
            disabled={props.isDisabled} />
        </Col>
        <Col sm={4} md={2}>
          <Selector
            items={InstructionManualUnit}
            value={instructionManualData?.sendUserManualInDayUnit}
            onChange={(value) => onTextChange('sendUserManualInDayUnit', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
    </Card>
  );
}
