import { LoaderFunctionArgs } from 'react-router-dom';
import {
  account,
  role,
  shareValue,
} from 'services';

export const su07Loader = async () => {
  const [usersRes, departments, positions, roleList] = await Promise.all([
    account.getUsersAsync(10, 1),
    shareValue.GetDDL('Department'),
    shareValue.GetDDL('Position'),
    role.getRoleDdlAsync(),
  ]);

  return {
    users: usersRes.data,
    departmentDDL: departments,
    positionDDL: positions,
    roleList: roleList.data,
  };
};

export const su07DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  const rolesRes = await role.getRolesAsync(100, 1);

  if (id) {
    const userRes = await account.getUserByIdAsync(id);

    return {
      user: userRes.data,
      roles: rolesRes.data.data,
    };
  }

  return {
    roles: rolesRes.data.data,
  };
};
