import { Rule } from 'models';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Form,
  InputGroup,
} from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';

interface Props {
  label?: string;
  placeholder?: string;
  rule?: Rule;
  onChange?: (value: File | undefined) => void;
  name?: string;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  fileTypes?: string[];
  clearable?: boolean;
}

const fileTypes = ['doc', 'docx', 'xls', 'xlsx', 'csv', 'pdf', 'png', 'jpg', 'jpeg'];

export function InputFile(props: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [value, setValue] = useState<File | undefined>();
  const inputRef = useRef({} as HTMLInputElement);

  document.addEventListener('onFormSubmit', (data) => {
    const event = data as CustomEvent;

    if (event.detail.hasNameOnly) {
      if (props.name) {
        validate(value);
      }
    } else {
      validate(value);
    }
  });

  document.addEventListener('onClearError', () => {
    setErrorMessage('');
  });

  const getRequired = () => {
    if (props.rule?.required) {
      return <span className='text-danger'>*</span>;
    }

    return null;
  };

  const validate = (validateValue: File | undefined) => {
    if (!checkRequired(validateValue)) {
      return;
    }

    setErrorMessage('');
  };

  const checkRequired = (requiredValue: File | undefined) => {
    if (props.rule?.required && !requiredValue) {
      setErrorMessage('กรุณาเลือกไฟล์');

      return false;
    }

    return true;
  };

  const getErrorMessage = useMemo(() => {
    if (errorMessage) {
      return (
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      );
    }

    return null;
  }, [errorMessage]);

  const handlerOnChange = (event: HTMLInputElement) => {
    if (props.onChange) {
      props.onChange(event.files![0]);
      validate(event.files![0]);
    }

    setValue(event.files![0]);

    validate(event.files![0]);
  };

  const getFileTypes = () => {
    const types = (props.fileTypes ?? fileTypes).map(t => `.${t}`);

    return types.join(', ');
  };

  const onClear = () => {
    setValue(undefined);

    inputRef.current.value = '';

    if (props.onChange) {
      props.onChange(undefined);
    }
  };

  useEffect(() => {
    const invalid: NodeListOf<HTMLElement> = document.querySelectorAll('input.is-invalid');

    if (invalid.length > 0) {
      invalid[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [getErrorMessage]);

  return (
    <Form.Group className={`w-100 ${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
      {props.label ? <Form.Label>{props.label} {getRequired()}</Form.Label> : null}
      <InputGroup style={{ zIndex: '0' }}
        hasValidation>
        <Form.Control
          ref={inputRef}
          autoFocus={props.autoFocus}
          className={`${getErrorMessage ? 'is-invalid' : ''}`}
          accept={getFileTypes()}
          type='file'
          placeholder={props.placeholder}
          onChange={(event) => handlerOnChange(event.target as HTMLInputElement)}
          disabled={props.disabled}
          autoComplete='off' />
        {props.clearable ? (
          <InputGroup.Text
            onClick={onClear}
            className='cursor-pointer'>
            <FaTrashAlt />
          </InputGroup.Text>
        ) : null}
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}