import {
  Card,
  Input,
  ProcessStatus,
  Selector,
  Status,
  StatusType,
  Table,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';
import { generateUniqueId } from '../../../../utils/helper';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function GetStatus(i: number) {
  switch (i) {
    case 1:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.PENDING} />;
    case 2:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.APPROVED} />;
    case 3:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.REJECTED} />;
    default:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.DRAFT} />;
  }
}

function step1({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลการแจ้งข้อมูลเบื้องต้น (จพ.004)" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={6}>
            <Input label="วันที่เอกสารบันทึกข้อความแต่งตั้ง" value="วันที่เอกสารบันทึกข้อความแต่งตั้ง" disabled />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} md={6}>
            <Selector label="โครงการ" disabled />
          </Col>
          <Col sm={12} md={6}>
            <Button variant="link"><TbListSearch className="fs-4" /> ไปยังแผน</Button>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="งบประมาณโครงการ (บาท)" value="1,000,000.00" disabled textEnd />
          </Col>
          <Col sm={12} md={4} xl={3}>
            <Input label="วิธีจัดหา" value="80" disabled />
          </Col>
          <Col sm={12} md={4} xl={3} className="pt-3">
            <Input label="" value="เจาะจง" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="สถานะการอนุมัติ" className="fs-5 text-primary" />
        <Table className="mt-3">
          <thead>
            <tr className="text-center">
              <th>ลำดับ</th>
              <th>รหัสพนักงาน</th>
              <th>ชื่อ-นามสกุล</th>
              <th>ตำแหน่ง</th>
              <th>สถานะ</th>
              <th>วันที่อนุมัติ</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 5 }).map((_, index) => (
              <tr className="text-center" key={generateUniqueId(index)}>
                <td>1</td>
                <td>5900010</td>
                <td>สมศรี แดนไทย</td>
                <td>รักษาการผู้อำนวยการสำนักงบประมาณ</td>
                <td>{GetStatus(index)}</td>
                <td>28-11-2566 16:24:22</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          className="me-2 px-3"
          onClick={onClickBack}
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default step1;
