import {
  Card,
  Check,
  DatePicker,
  Input,
  InputTextArea,
  Radio,
  Selector,
  Table,
  TorStatus,
  TorStatusDetail,
  UploadFile,
  ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import {
  HistoryModal,
  SearchNameModal,
} from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Row,
} from 'react-bootstrap';
import { BsArrowDownCircle } from 'react-icons/bs';
import {
  FaHistory,
  FaPlus,
  FaSave,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { generateUniqueId } from '../../../../utils/helper';
import { dataHistory } from './data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const INFO = [
  { label: 'ละเว้น', value: '1' },
  { label: 'ไม่ละเว้น', value: '2' },
];

function Step1({ onClickNext, onClickBack }: Props) {
  const [showHistory, setShowHistory] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [showSearchNameModal, setModalSearchNameModal] = useState(false);

  const navigate = useNavigate();
  const goToPage = () => {
    navigate('/cm/cm0201/Detail');
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title text='ข้อมูลโครงการ' className='fs-5 text-primary' />
        <div className='d-flex align-items-center'>
          <p className='mb-0 me-4'>เลขที่โครงการ </p>
          <Button onClick={goToPage} variant='link'>T0001</Button>
        </div>
        <div className='d-flex align-items-center'>
          <p className='mb-0 me-4'>ชื่อโครงการ </p>
          <p className='mb-0 ms-4'>จ้างที่ปรึกษา </p>
        </div>
      </Card>
      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <Title text='ข้อมูลใบสั่ง/สัญญา' className='fs-5 text-primary' />
          <div className='d-flex gap-2'>
            <TorStatus
              value='Draft' />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'>
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} xl={6}>
            <Input label='ชื่อสัญญา' value='สัญญาจ้างบริการบำรุงรักษาและซ่อมแซมแก้ไขอุปกรณ์สื่อสารระบบ ITMX' />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <Input label='เลขที่สัญญา PO' textCenter rule={{ required: true }} value='410007' />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <Input label='เลขที่สัญญา จพ.(สบส.)' textCenter rule={{ required: true }} value='001/2566' />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <Input label='วงเงินตามสัญญา' textEnd rule={{ required: true }} value='1,000,000.00' />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={2}>
            <Input label='กำหนดส่งมอบภายใน' placeholder='กำหนดส่งมอบภายใน' textCenter rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} md={1}>
            <Selector className='no-label' placeholder='วัน' disabled />
          </Col>
          <Col sm={12} md={3}>
            <Selector className='no-label' placeholder='นับถัดจากวันที่ลงนามใมสัญญา' />
          </Col>
          <Col sm={12} md={3}>
            <DatePicker label='ครบกำหนดส่งมอบวันที่' disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={2}>
            <Input label='ระยะเวลารับประกัน' placeholder='ระยะเวลารับประกัน ' textCenter rule={{ required: true }} />
          </Col>
          <Col sm={12} md={1}>
            <Selector className='no-label' placeholder='วัน' />
          </Col>
          <Col sm={12} md={3}>
            <DatePicker label='ครบกำหนดส่งมอบวันที่' disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            <Selector label='เงื่อนไขการรับประกัน' placeholder='ถัดจากวันสิ้นสุด' rule={{ required: true }} />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center mb-3'>
          <Title text='รายการของพัสดุ' className='fs-5 text-primary' />
          {
            openCollapse ? (
              <ArrowCollapse onClick={() => setOpenCollapse(!openCollapse)} openCollapse={openCollapse} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse(true)} openCollapse={openCollapse} />
            )}
        </div>
        <Collapse in={openCollapse}>
          <div>
            <Table total={1} hidePagination>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>ลำดับ</th>
                  <th style={{ minWidth: 150 }}>รายละเอียด</th>
                  <th style={{ minWidth: 50 }}>จำนวน</th>
                  <th style={{ minWidth: 50 }}>หน่วย</th>
                  <th style={{ minWidth: 50 }}>ประเภท VAT</th>
                  <th style={{ minWidth: 50 }}>ราคาที่เสนอ/ <br />ต่อหน่วย</th>
                  <th style={{ minWidth: 50 }}>ราคาที่เสนอ<br />
                    รวม
                  </th>
                  <th style={{ minWidth: 50 }}>ราคาทีตกลง/<br />ต่อหน่วย</th>
                  <th style={{ minWidth: 50 }}>ราคาที่ตกลง<br />
                    รวม
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr className='text-center'>
                  <td style={{ width: 10 }}>1</td>
                  <td style={{ minWidth: 150 }}><Input value='เครื่องปริ้น Canon G5070' disabled /></td>
                  <td style={{ minWidth: 50 }}><Input value='1' disabled textCenter /></td>
                  <td style={{ minWidth: 50 }}><Input value='เครื่อง' disabled /></td>
                  <td style={{ minWidth: 50 }}><Input value='ไม่มี VAT' disabled /></td>
                  <td style={{ minWidth: 50 }}><Input value='1,000.00' disabled textEnd /></td>
                  <td style={{ minWidth: 50 }}><Input value='1,000.00' disabled textEnd /></td>
                  <td style={{ minWidth: 50 }}><Input value='1,000.00' disabled textEnd /></td>
                  <td style={{ minWidth: 50 }}><Input value='1,000.00' disabled textEnd /></td>
                  <td className='text-end'>
                    <Button
                      variant='danger'
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                </tr>
                <tr className='text-center'>
                  <td style={{ width: 10 }}>2</td>
                  <td style={{ minWidth: 150 }}><Input value='เครื่องแบบ' disabled /></td>
                  <td style={{ minWidth: 50 }}><Input value='1' disabled textCenter /></td>
                  <td style={{ minWidth: 50 }}><Input value='ชุด' disabled /></td>
                  <td style={{ minWidth: 50 }}><Input value='รวม VAT' disabled /></td>
                  <td style={{ minWidth: 50 }}><Input value='800.00' disabled textEnd /></td>
                  <td style={{ minWidth: 50 }}><Input value='800.00' disabled textEnd /></td>
                  <td style={{ minWidth: 50 }}><Input value='800.00' disabled textEnd /></td>
                  <td style={{ minWidth: 50 }}><Input value='800.00' disabled textEnd /></td>
                  <td className='text-end'>
                    <Button
                      variant='danger'
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className='d-flex flex-lg-row flex-column justify-content-end gap-3 mt-3'>
              <div className='d-flex align-items-center'>
                <p className='mb-0 text-nowrap me-2'>ราคารวมที่เสนอ</p>
                <Input value='1,000.00' disabled textEnd className='d-inline-block' />
              </div>
              <div className='d-flex align-items-center'>
                <p className='mb-0 text-nowrap me-2'>ราคารวมที่เสนอ</p>
                <Input value='1,000.00' disabled textEnd className='d-inline-block' />
              </div>
            </div>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center mb-3'>
          <Title text='ระยะเวลาดำเนินการ/ส่งมอบงาน' className='fs-5 text-primary' />
          {openCollapse1 ? (
            <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />
          )}
        </div>
        <Collapse in={openCollapse1}>
          <div>
            <Table total={1} hidePagination>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>งวดที่</th>
                  <th style={{ minWidth: 150 }}>รายละเอียด</th>
                  <th style={{ width: 250 }}>จำนวนวัน</th>
                  <th style={{ width: 250 }}>วันที่ต้อง<br />
                    ส่งมอบ
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 3 }).map((_, index) => (
                  <tr className='text-center' key={generateUniqueId(index)}>
                    <td style={{ width: 10 }}>{index + 1}</td>
                    <td style={{ minWidth: 150 }}><Input disabled value={`ส่งมอบงาน ${index + 1}`} /></td>
                    <td><Input disabled value={30} textCenter /></td>
                    <td><DatePicker disabled value={new Date()} /></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center mb-3'>
          <Title text='เงื่อนไขการชำระเงิน' className='fs-5 text-primary' />
          {openCollapse1 ? (
            <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />
          )}
        </div>
        <Collapse in={openCollapse1}>
          <div>
            <Table total={1} hidePagination>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>ลำดับที่</th>
                  <th style={{ width: 100 }}>งวดที่</th>
                  <th style={{ width: 50 }}>จำนวน (%)</th>
                  <th style={{ minWidth: 150 }}>รายละเอียด</th>
                  <th style={{ width: 250 }}>จำนวนเงิน</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 3 }).map((_, index) => (
                  <tr className='text-center' key={generateUniqueId(index)}>
                    <td style={{ width: 10 }}>{index + 1}</td>
                    <td><Input disabled value={1} textCenter /></td>
                    <td><Input disabled value={10} textCenter /></td>
                    <td style={{ minWidth: 150 }}>
                      <Input disabled value={`ส่งมอบแผนดำเนินการโครงการทั้งหมด ${index + 1}`} />
                    </td>
                    <td><Input disabled value='1, 000.00' textEnd /></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center mb-3'>
          <Title text='อัตราค่าปรับ' className='fs-5 text-primary' />
          {openCollapse1 ? (
            <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />
          )}
        </div>
        <Collapse in={openCollapse1}>
          <div>
            <Table total={1} hidePagination>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>ลำดับ</th>
                  <th style={{ minWidth: 150 }}>รายละเอียด</th>
                  <th style={{ width: 250 }}>อัตราร้อยละ</th>
                  <th style={{ width: 250 }}>คิดเป็น</th>
                  <th style={{ width: 250 }}>จำนวนเงิน</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 3 }).map((_, index) => (
                  <tr className='text-center' key={generateUniqueId(index)}>
                    <td style={{ width: 10 }}>{index + 1}</td>
                    <td style={{ minWidth: 150 }}><Input disabled value={`กรณีจ้างบำรุงรักษา ${index + 1}`} /></td>
                    <td><Selector disabled placeholder='รายวัน' /></td>
                    <td><Input disabled value='0.10' textCenter /></td>
                    <td><Input disabled value='100.00' textEnd /></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='ส่งอนุมัติหนังสือเชิญชวนให้มาทำสัญญา' className='fs-5 text-primary' />
            {openCollapse1 ? (
              <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />
            )}
          </div>
          <Button
            variant='outline-primary'
            onClick={() => setModalSearchNameModal(!showSearchNameModal)}>
            <FaPlus />เพิ่มรายชื่อ
          </Button>
        </div>
        <Collapse in={openCollapse1}>
          <div>
            <Table total={1} hidePagination>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>ลำดับ</th>
                  <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
                  <th style={{ width: 250 }}>ฝ่าย/สำนัก</th>
                  <th style={{ width: 250 }}>ตำแหน่ง</th>
                  <th style={{ width: 250 }}>วันที่เห็นชอบ</th>
                  <th style={{ width: 250 }}>สถานะ</th>
                  <th style={{ width: 50 }}>ไม่สามารถปฏิบัติงานได้</th>
                  <th style={{ width: 250 }}>หมายเหตุ</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 3 }).map((_, index) => (
                  <tr className='text-center' key={generateUniqueId(index)}>
                    <td>{index + 1}</td>
                    <td>สมศรี แดนไทย</td>
                    <td>สมศรี แดนไทย</td>
                    <td>ผู้ช่วยกรรมการ</td>
                    <td>17/05/2566</td>
                    <td> <TorStatusDetail value='Approved' /></td>
                    <td><div className='d-flex justify-content-center'><Check /></div></td>
                    <td><Input /></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <Title text='บันทึกรับหลักประกัน' className='fs-5 text-primary' />
        <Row>
          <Col sm={12}>
            <Radio
              className='mt-3'
              inline
              dflex
              items={INFO}
              label='จำนวนเงินหลักประกัน'
              rule={{ required: true }}
              name='name1'
            />
          </Col>
          <Col sm={12} xl={3}>
            <Selector label='ประเภทหลักประกันสัญญา' placeholder='เงินโอน' rule={{ required: true }} />
          </Col>
          <Col sm={12} xl={3}>
            <Input label='จำนวนเงินหลักประกัน' placeholder='จำนวนเงินหลักประกัน' rule={{ required: true }} />
          </Col>
          <Col sm={12} xl={3}>
            <Input label='ร้อยละของราคาค่าจ้างตามสัญญา' placeholder='ร้อยละของราคาค่าจ้างตามสัญญา' rule={{ required: true }} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xl={3}>
            <Input label='เลขที่หลักประกัน' placeholder='เลขที่หลักประกัน' />
          </Col>
          <Col sm={12} xl={3}>
            <DatePicker label='เลขที่หลักประกันลงวันที่' />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xl={3}>
            <Selector label='ประเภทวันทำสัญญา' placeholder='ตามวันที่' />
          </Col>
          <Col sm={12} xl={3}>
            <DatePicker label='วันที่' rule={{ required: true }} />
          </Col>
          <Col sm={12} xl={3}>
            <DatePicker label='ถึงวันที่' />
          </Col>
          <Col sm={12} xl={8}>
            <InputTextArea label='หมายเหตุ' />
          </Col>
        </Row>

        <Table total={1} hidePagination>
          <thead>
            <tr className='text-center'>
              <th style={{ width: 10 }}>ลำดับ</th>
              <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
              <th style={{ width: 250 }}>ฝ่าย/สำนัก</th>
              <th style={{ width: 250 }}>สถานะ</th>
              <th style={{ width: 250 }}>วันที่เห็นชอบ</th>
              <th style={{ width: 50 }}>ไม่สามารถปฏิบัติงานได้</th>
              <th style={{ width: 250 }}>หมายเหตุ</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 3 }).map((_, index) => (
              <tr className='text-center' key={generateUniqueId(index)}>
                <td>{index + 1}</td>
                <td>สมศรี แดนไทย</td>
                <td>สมศรี แดนไทย</td>
                <td>17/05/2566</td>
                <td> <TorStatusDetail value='Approved' /></td>
                <td><div className='d-flex justify-content-center'><Check /></div></td>
                <td><Input /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12} lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          variant='outline-primary'
          onClick={onClickBack}
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          <Button
            variant='outline-primary'
            className='me-2 px-3'
          >
            <FaSave className='ms-2 pb-1 fs-5' />
            บันทึก
          </Button>
          <Button
            className='me-2 px-3'
          >
            ส่งเห็นชอบ/อนุมัติ
          </Button>
        </div>
        <Button
          variant='outline-primary'
          onClick={onClickNext}
          className='me-2 px-3'
        >
          ถัดไป
          <MdArrowForward className='ms-2 pb-1 fs-5' />
        </Button>
      </div>
      <HistoryModal
        title='ประวัติการใช้งานประกาศผู้ชนะการเสนอราคา'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        data={dataHistory}
      />
      <SearchNameModal
        show={showSearchNameModal}
        onHide={() => setModalSearchNameModal(!showSearchNameModal)}
        data={[]}
      />
    </div>
  );
}

export default Step1;
