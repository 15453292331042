import { Input, Selector, Table } from 'components';
import { GROUPS } from 'constant/TemplateGroups';
import { DocumentTemplate } from 'models';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaEdit, FaEraser, FaPlus, FaSearch, FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { SU12Service } from 'services';
import { HttpStatusCode, fullDatetime, showRemoveModalAsync } from 'utils';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';
import toast from 'utils/toast';

interface CriteriaProps {
  onSearch: (programGroup: string, name: string) => void;
}

interface DataTableProps {
  totalRecords: number;
  onChange: (size: number, page: number) => void;
  data: DocumentTemplate[];
  onRemove: (id: string) => void;
}

export default function SU12() {
  const navigate = useNavigate();
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [data, setData] = useState<DocumentTemplate[]>([]);
  const [programGroup, setProgramGroup] = useState<string>('');
  const [name, setName] = useState<string>('');

  const getDataAsync = async (size: number, page: number, programGroup: string, name: string) => {
    const res = await SU12Service.getPaginatedAsync(size, page, programGroup, name);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }
  };

  const onCriteria = async (programGroup: string, name: string) => {
    setProgramGroup(programGroup);
    setName(name);

    getDataAsync(size, page, programGroup, name);
  };

  const onDataTableChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);

    getDataAsync(size, page, programGroup, name);
  };

  const onRemoveAsync = async (id: string) => {
    const res = await SU12Service.deleteAsync(id);

    if (res.status === HttpStatusCode.NO_CONTENT) {
      toast.success('ลบข้อมูลสำเร็จ');

      await getDataAsync(size, page, programGroup, name);
    }
  };

  useEffect(() => {
    getDataAsync(size, page, programGroup, name);
  }, []);

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>จัดการรูปแบบเอกสาร</h4>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => navigate('detail')}>
          <FaPlus />เพิ่มรูปแบบเอกสาร
        </Button>
      </div>
      <hr />
      <Criteria onSearch={onCriteria} />
      <DataTable
        totalRecords={totalRecords}
        onChange={onDataTableChange}
        data={data}
        onRemove={onRemoveAsync} />
    </>
  );
}

function Criteria(props: CriteriaProps) {
  const [programGroup, setProgramGroup] = useState<string>('');
  const [name, setName] = useState<string>('');

  const clear = () => {
    setProgramGroup('');
    setName('');

    props.onSearch('', '');
  };

  return (
    <div className='criteria'>
      <Row>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Selector
            label='กลุ่ม'
            value={programGroup}
            onChange={setProgramGroup}
            items={GROUPS}
          />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <Input
            label='รูปแบบเอกสาร'
            value={name}
            onChange={setName}
          />
        </Col>
        <div className='d-flex gap-2'>
          <Button
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={() => props.onSearch(programGroup, name)}>
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={clear}>
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
    </div>
  );
}

function DataTable(props: DataTableProps) {
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();

  const onChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);

    props.onChange(size, page);
  };

  const onRemoveAsync = async (id: string) => {
    const confirmResult = await showRemoveModalAsync();

    if (confirmResult) {
      props.onRemove(id);
    }
  };

  return (
    <>
      <Table
        className='mt-4'
        total={props.totalRecords}
        onChange={onChange}>
        <thead>
          <tr>
            <th style={{ minWidth: 75 }}>ลำดับ</th>
            <th style={{ minWidth: 150 }}>กลุ่ม</th>
            <th style={{ minWidth: 150 }}>รหัส</th>
            <th style={{ minWidth: 150 }}>รูปแบบเอกสาร</th>
            {/* <th style={{ minWidth: 150 }}>ผู้แก้ไขล่าสุด</th>
            <th style={{ minWidth: 150 }}>วันที่แก้ไขล่าสุด</th> */}
            <th style={{ minWidth: 60 }} />
            <th style={{ minWidth: 30 }} />
          </tr>
        </thead>
        <tbody>
          {props.data.map((data, i) => (
            <tr key={data.id}>
              <td className='text-center'>{calculateRowNumber(i, page, size)}</td>
              <td className='text-center'>{data.programGroup}</td>
              <td className='text-center'>{data.code}</td>
              <td>{data.name}</td>
              {/* <td>{data.updatedBy ?? data.createdBy}</td>
              <td className='text-center'>
                {fullDatetime(data.updatedAt ?? data.createdAt)}
              </td> */}
              <td>
                <Button
                  variant='outline-primary'
                  className='d-flex align-items-center gap-2'
                  onClick={() => navigate(`detail/${data.id}`)}>
                  <FaEdit />แก้ไข
                </Button>
              </td>
              <td>
                {!data.isSystem ? (
                  <Button
                    variant='outline-primary'
                    className='d-flex align-items-center gap-2'
                    onClick={() => onRemoveAsync(data.id)}>
                    <FaTrashAlt />
                  </Button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}