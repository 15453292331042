import {
  ButtonCustom,
  Card,
  Check,
  ContractReceiveCollateral,
  ContractReturnRequestStatus,
  Currency,
  DatePicker,
  Input,
  Selector,
  StatusCM,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import {
  IFile,
  ItemModel,
} from 'models';
import {
  AttachmentFileModel,
  ContractReturnCollateralChannelModel,
  ContractReturnCollateralRequestModel,
  contractReturnCollateralTransferDocumentTypesModel,
} from 'models/CM/CM06Model';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { useLoaderData, useNavigate } from 'react-router';
import { shareValue } from 'services';
import CM06Service from 'services/CM/CM06Service';
import { HttpStatusCode, THCurrency, thaiFormatDate, thaiFormatDateWithSlash, useAppContext } from 'utils';
import { PerformanceBondType, SharedvalueGroup } from 'utils/constants/ContractEnum';
import toast from 'utils/toast';
import { Context } from '.';
import { generateUniqueId, isNull } from '../../../../../utils/helper';
import { HistoryModal } from '../../Modal/HistoryModal';
import UploadFile from './UploadFile';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  collateralId: string;
}

const INFO = [
  { label: 'มีหลักประกัน', value: '1' },
  { label: 'ไม่มีหลักประกัน', value: '2' },
];

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

type Loader = {
  performanceBondTypeDDL: ItemModel[],
  bankDDL: ItemModel[],
};

function Step1({ onClickNext, onClickBack, collateralId }: Props) {
  const { dataContext, setDataContext } = useContext(Context);
  const [contractReturnRequestData, setContractReturnRequestData] = useState<ContractReturnCollateralRequestModel>({} as ContractReturnCollateralRequestModel);
  const [transferDocumentTypeDDL, setTransferDocumentTypeDDL] = useState<ItemModel[]>([]);
  const [transferDocumentTypes, setTransferDocumentTypes] = useState<contractReturnCollateralTransferDocumentTypesModel[]>([]);
  // const [bankDDL, setBankDDL] = useState<ItemModel[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [personalfiles, setPersonalFiles] = useState<IFile[]>([]);
  const navigate = useNavigate();
  const { performanceBondTypeDDL, bankDDL } = useLoaderData() as Loader;
  const [collateralChannelData, setCollateralChannelData] = useState<ContractReturnCollateralChannelModel[]>([]);
  const [isFine, setIsFine] = useState(false);
  const [fineAmount, setFineAmount] = useState<number>(0);
  const [collateralAmount, setCollateralAmount] = useState<number>(0);
  const [isNextStep, setIsNextStep] = useState(false);
  const [isAssignee, setIsAssignee] = useState(false);
  const { userId } = useAppContext();

  const nextStep = () => {
    onClickNext();
  };

  useEffect(() => {
    if (contractReturnRequestData) {
      if (contractReturnRequestData.isFine) {
        setIsFine(isFine);
      }

      if (contractReturnRequestData.collateralChannes) {
        setCollateralAmount(contractReturnRequestData.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0));
      }

      if (contractReturnRequestData.fineAmount) {
        setFineAmount(contractReturnRequestData.fineAmount);
        handleSumCollateralAmount(contractReturnRequestData.fineAmount, contractReturnRequestData.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0));
      }
    }
  }, [contractReturnRequestData]);

  useEffect(() => {
    // getBankDDL();

    if (dataContext) {
      if (dataContext.transferDocumentTypes !== null && dataContext.transferDocumentTypes !== undefined && dataContext.transferDocumentTypes.length > 0) {
        getTransferDocumentTypes(false);
        setTransferDocumentTypes(dataContext.transferDocumentTypes);
      } else {
        getTransferDocumentTypes(true);
      }

      if (dataContext.status === ContractReturnRequestStatus.WaitForApprove
        || dataContext.status === ContractReturnRequestStatus.Approved) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }

      if (dataContext.isFine) {
        setIsFine(dataContext.isFine);
      }

      if (dataContext.id !== null && dataContext.id !== undefined) {
        getContractReturnCollateralRequestAttachmentByIdAsync(dataContext.id!);
        getContractReturnCollateralRequestAttachmentPersonalByIdAsync(dataContext.id!);
      }

      if (dataContext.collateralChannes) {
        setCollateralChannelData(dataContext.collateralChannes)
      }

      if (dataContext.transferDocumentTypes) {
        const countResult = dataContext.transferDocumentTypes.length;
        const result = dataContext.transferDocumentTypes.filter(x => x.result).length;

        if ((dataContext.collateralChannes.filter(x => x.type === PerformanceBondType.cash).length > 0 && countResult === result) || dataContext.collateralChannes.filter(x => x.type !== PerformanceBondType.cash).length > 0) {
          setIsNextStep(true)
        } else {
          setIsNextStep(false);
        }

      }

      if (dataContext.contractReturnCollateralConsiderAssignee) {
        if (dataContext.contractReturnCollateralConsiderAssignee.filter(x => isNull(x.delegateUserId, x.userId) === userId).length > 0) {
          setIsAssignee(true);
        }
      }

      setContractReturnRequestData(dataContext);

    }
  }, [dataContext, userId]);

  const getTransferDocumentTypes = async (isNewData: boolean) => {
    const { data, status } = await shareValue.getListSharedValueAsync(SharedvalueGroup.ReturnCollateralTransferDocumentType);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    const ddl = data as ItemModel[];
    if ((dataContext.transferDocumentTypes === undefined
      || dataContext.transferDocumentTypes === null
      || dataContext.transferDocumentTypes.length === 0) && isNewData) {
      const newDatas: contractReturnCollateralTransferDocumentTypesModel[] = [];

      ddl.forEach((data, index) => {
        newDatas.push({
          id: "",
          contractReturnCollateralRequestApprovalId: "",
          sequence: index + 1,
          transferDocumentTypeId: data.value,
          result: false,
        });
      });

      setTransferDocumentTypes(newDatas);
    }

    setTransferDocumentTypeDDL(data);
  };

  const goToBack = () => {
    navigate(`/cm/cm06/detail/${contractReturnRequestData.contractReturnCollateralId}`);
  };

  const handleFileChange = async (files: File[]) => {
    if (contractReturnRequestData && contractReturnRequestData.id) {
      const res = await CM06Service.uploadContractReturnCollateralRequestAttachmentAsync(
        contractReturnRequestData.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');

        const dataFile: AttachmentFileModel[] = res.data as AttachmentFileModel[];
        if (dataFile && dataFile.length > 0) {
          const newFiles: IFile[] = dataFile.map((data, index) => {
            return {
              id: data.id!,
              name: data.fileName,
              file: null,
            } as IFile;
          });

          setFiles(newFiles);
        }
      }
    }
  };

  const removeItemAsync = async (index: number, docId: string) => {

    if (contractReturnRequestData && contractReturnRequestData.id) {
      const res = await CM06Service.removeFileContractReturnCollateralRequestAttachmentAsync(
        contractReturnRequestData.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');

        getContractReturnCollateralRequestAttachmentByIdAsync(contractReturnRequestData.id);
      }
    }

  };

  const handlePersonalFileChange = async (files: File[]) => {
    if (contractReturnRequestData && contractReturnRequestData.id) {
      const res = await CM06Service.uploadContractReturnCollateralRequestAttachmentPersonalAsync(
        contractReturnRequestData.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');

        const dataFile: AttachmentFileModel[] = res.data as AttachmentFileModel[];
        if (dataFile && dataFile.length > 0) {
          const newFiles: IFile[] = dataFile.map((data, index) => {
            return {
              id: data.id!,
              name: data.fileName,
              file: null,
            } as IFile;
          });

          setPersonalFiles(newFiles);
        }
      }
    }
  };

  const removeItemPersonalAsync = async (index: number, docId: string) => {

    if (contractReturnRequestData && contractReturnRequestData.id) {
      const res = await CM06Service.removeFileContractReturnCollateralRequestAttachmentAsync(
        contractReturnRequestData.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');

        getContractReturnCollateralRequestAttachmentPersonalByIdAsync(contractReturnRequestData.id);
      }
    }

  };

  const getContractReturnCollateralRequestAttachmentByIdAsync = async (id: string) => {
    const { data, status } = await CM06Service.getContractReturnCollateralRequestAttachmentAsync(id);
    if (status === HttpStatusCode.OK) {
      const attachments = data.attachments as AttachmentFileModel[];
      if (attachments && attachments.length > 0) {
        const newFiles: IFile[] = attachments.map((data, index) => {
          return {
            id: data.id!,
            name: data.fileName,
            file: null,
          } as IFile;
        });

        setFiles(newFiles);
      } else {
        setFiles([]);
      }
    }
  };

  const getContractReturnCollateralRequestAttachmentPersonalByIdAsync = async (id: string) => {
    const { data, status } = await CM06Service.getContractReturnCollateralRequestAttachmentPersonalAsync(id);
    if (status === HttpStatusCode.OK) {
      const attachments = data.attachments as AttachmentFileModel[];
      if (attachments && attachments.length > 0) {
        const newFiles: IFile[] = attachments.map((data, index) => {
          return {
            id: data.id!,
            name: data.fileName,
            file: null,
          } as IFile;
        });

        setPersonalFiles(newFiles);
      } else {
        setPersonalFiles([]);
      }
    }
  };

  const onChangeTransferDocumentType = (id: string, val: boolean) => {
    const indexData = transferDocumentTypes.findIndex(x => x.transferDocumentTypeId === id);
    const newData = [...transferDocumentTypes];
    newData[indexData].result = val;

    setTransferDocumentTypes(newData);
  };

  const onSave = async () => {
    setShowSaveConfirmModal(false);
    const saveData = contractReturnRequestData;

    saveData.transferDocumentTypes = transferDocumentTypes;

    if (contractReturnRequestData.id !== undefined &&
      contractReturnRequestData.id !== null) {
      const res = await CM06Service.updateContractReturnCollateralRequestApprovalAsync(contractReturnRequestData.id, saveData);
      // if (res.status !== HttpStatusCode.OK) {
      //   toast.error('บันทึกข้อมูลขออนุมัติคืนหลักประกันสัญญาไม่สำเร็จ');
      //   return;
      // }
      toast.success('บันทึกข้อมูลขออนุมัติคืนหลักประกันสัญญาสำเร็จ');
      getContractReturnCollateralById(contractReturnRequestData.contractReturnCollateralConsiderId);
    } else {
      const res = await CM06Service.createContractReturnCollateralRequestApprovalAsync(saveData);
      // if (res.status !== HttpStatusCode.OK) {
      //   toast.error('บันทึกข้อมูลขออนุมัติคืนหลักประกันสัญญาไม่สำเร็จ');
      //   return;
      // }
      toast.success('บันทึกข้อมูลขออนุมัติคืนหลักประกันสัญญาสำเร็จ');
      getContractReturnCollateralById(contractReturnRequestData.contractReturnCollateralConsiderId);
    }
  };

  const getContractReturnCollateralById = async (id: string) => {
    const { data, status } = await CM06Service.getContractReturnCollateralRequestApprovalByIdAsync(id);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setDataContext(data);
  };

  const handleSumCollateralAmount = (fineAmount: number, totalAmount: number) => {
    if ((fineAmount !== undefined || fineAmount !== null || fineAmount > 0) && (totalAmount !== undefined || totalAmount !== null || totalAmount > 0)) {
      const val = (totalAmount - fineAmount);
      setCollateralAmount(val);
    }

    setFineAmount(fineAmount);

    const datas = contractReturnRequestData;
    datas.fineAmount = fineAmount;
    setContractReturnRequestData(datas);
  };

  const handleCollateralIsFine = (val: boolean) => {
    setIsFine(val);
    if (!val) {
      setFineAmount(0);
      handleSumCollateralAmount(0, contractReturnRequestData.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0))
    }
    const datas = contractReturnRequestData;
    datas.isFine = val;
    setContractReturnRequestData(datas);
  };

  const [showHistoryModal, setShowHistoryModal] = useState(false);
  return (
    <div className='document'>
      <Title text='บันทึกขออนุมัติคืนหลักประกันสัญญา'
        extraElement={(
          <>
            <StatusCM systemStatue={contractReturnRequestData.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </>
        )} />

      <Row className='mt-3 align-items-center'>
        <h5 className='fw-bold'>รายละเอียดหลักประกันสัญญา</h5>
        <Col sm={12}>
          <Table total={2}
            hidePagination>
            <thead>
              <tr>
                <th className='text-center ps-3'
                  style={{ width: '20%' }}>รายละเอียดหลักประกัน
                </th>
                <th className='text-center'
                  style={{ width: '15%' }}>ร้อยละ <br />(ของราคาทั้งหมดตามสัญญา)
                </th>
                <th className='text-center'
                  style={{ width: '15%' }}>จำนวนเงิน
                </th>
              </tr>
            </thead>
            <tbody>
              {contractReturnRequestData.collateralChannes?.map((item, index) => (
                <tr>
                  <td className='ps-3 text-start text-wrap'>
                    <div>
                      {item.typeName}
                    </div>
                    {(item.bankName) && (
                      <div>
                        {item.bankName} สาขา {item.bankBranch}
                      </div>
                    )}
                    {(item.bankAccountName) && (
                      <div>
                        ชื่อบัญชี {item.bankAccountName}
                      </div>
                    )}
                    {(item.bankAccountNumber) && (
                      <div>
                        เลขที่ {item.bankAccountNumber}
                      </div>
                    )}
                    {
                      (item.bankCollateralStartDate && item.bankCollateralEndDate)
                      && (
                        <div>
                          วันที่หนังสือค้ำประกัน {thaiFormatDate(item.bankCollateralStartDate)} -  {thaiFormatDate(item.bankCollateralEndDate)}
                        </div>
                      )
                    }
                  </td>
                  <td className='text-end'>{formatter.format(item.percent ?? 0)}</td>
                  <td className='text-end'>{formatter.format(item.amount ?? 0)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className='bg-opacity-10 bg-primary p-4 rounded'
                style={{ height: 70 }}>
                <td colSpan={2}>
                  <p className='mb-0 text-primary text-end'>คืนหลักประกันสัญญาคงเหลือ :</p>
                </td>
                <td className='text-end'>
                  <span className='ms-3 text-dark'>
                    {
                      formatter.format(collateralAmount)
                    }
                  </span>
                </td>
              </tr>
            </tfoot>
            <tfoot>
              <tr className='bg-opacity-10 p-4 rounded'
                style={{ height: 70 }}>
                <td colSpan={2}>
                  <Check
                    label='หักค่าปรับจากเงินหลักประกันสัญญา'
                    onChange={(val) => handleCollateralIsFine(val)}
                    value={isFine}
                    disabled={isDisabled}
                  />
                </td>
                <td className='text-end'>
                  <Currency
                    value={fineAmount}
                    onChange={(val) => handleSumCollateralAmount(Number(val), contractReturnRequestData.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0))}
                    maxValue={contractReturnRequestData.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0)}
                    disabled={!isFine || isDisabled} />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
        {/* <Col sm={12}
          className='mt-3'>
          <Title text='รายละเอียดหลักประกันที่คืนให้คู่ค้า'
            lineBorder />
          <Table total={2}
            hidePagination
            notResponse
            className='overflow-x-scroll'>
            <thead>
              <tr>
                <th className='text-center ps-3'
                  style={{ width: '20%' }}>ประเภทหลักประกัน
                </th>
                <th className='text-center'
                  style={{ width: '15%' }}>ธนาคาร
                </th>
                <th className='text-center'
                  style={{ width: '15%' }}>สาขาธนาคาร
                </th>
                <th className='text-center'
                  style={{ width: '10%' }}>เลขที่
                </th>
                <th className='text-center'
                  style={{ width: '10%' }}>เลขที่หลักประกันลงวันที่เริ่มต้น
                </th>
                <th className='text-end'
                  style={{ width: '10%' }}>เลขที่หลักประกันลงวันที่สิ้นสุด
                </th>
                <th className='text-center'
                  style={{ width: '10%' }}>ร้อยละ <br />(ของราคาทั้งหมดตามสัญญา)
                </th>
                <th className='text-center'
                  style={{ width: '10%' }}>จำนวนเงิน
                </th>
              </tr>
            </thead>
            <tbody>
              {
                contractReturnRequestData?.collateralChannes?.map((item, index) => (
                  <tr key={generateUniqueId(item.sequence)}>
                    <td className='ps-3 text-start'>
                      <Selector
                        items={performanceBondTypeDDL}
                        value={item.type}
                        onChange={(val) => handlerOnChangeType(val, index)}
                        disabled={isDisabled} />
                    </td>
                    <td className='ps-3 text-start'>
                      <Selector items={bankDDL}
                        value={item.bank || ''}
                        onChange={(val) => handlerOnChangeBank(val, index)}
                        disabled={isDisabled} />
                    </td>
                    <td className='ps-3 text-start'>
                      <Input value={item.bankBranch}
                        onChange={(val) => handlerOnChangeBankBranch(val, index)}
                        disabled={isDisabled} />
                    </td>
                    <td className='ps-3 text-start'>
                      <Input
                        value={item.bankAccountNumber}
                        onChange={(val) => handlerOnChangeAccountNumber(val, index)}
                        disabled={isDisabled} />
                    </td>
                    <td className='text-center'>
                      <DatePicker value={item.bankCollateralStartDate}
                        onChangeHasNullable={(val) => handlerOnChangeCollateralStartDate(val!, index)}
                        disabled={isDisabled} /></td>
                    <td className='text-center'>
                      <DatePicker value={item.bankCollateralEndDate}
                        onChangeHasNullable={(val) => handlerOnChangeCollateralEndDate(val!, index)}
                        disabled={isDisabled} /></td>
                    <td className='ps-3 text-'>
                      <Currency
                        value={item.percent}
                        onChange={(val) => handlerOnChangePercent(Number(val!), index)}
                        disabled={isDisabled} />
                    </td>
                    <td className='ps-3 text-end'>
                      <Currency
                        value={item.amount}
                        onChange={(val) => handlerOnChangeAmount(Number(val!), index)}
                        disabled={isDisabled} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
            <tfoot>
              <tr className='bg-opacity-10 bg-primary p-4 rounded'
                style={{ height: 70 }}>
                <td colSpan={7}>
                  <p className='mb-0 text-primary'>รวมมูลค่าหลักประกันสัญญา :</p>
                </td>
                <td className='text-end'>
                  <span className='ms-3 text-dark'>
                    {
                      THCurrency(collateralAmount)
                    }
                  </span>
                </td>
              </tr>
            </tfoot>
            <tfoot>
              <tr className='bg-opacity-10 p-4 rounded'
                style={{ height: 70 }}>
                <td colSpan={7}>
                  <Check
                    label='หักค่าปรับจากเงินหลักประกันสัญญา'
                    onChange={(val) => handleCollateralIsFine(val)}
                    value={isFine}
                    disabled={isDisabled}
                  />
                </td>
                <td className='text-end'>
                  <Currency
                    value={fineAmount}
                    onChange={(val) => handleSumCollateralAmount(Number(val), contractReturnRequestData?.collateralChannes?.reduce((a, v) => a += v.amount, 0))}
                    disabled={!isFine || isDisabled} />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col> */}
        {
          (contractReturnRequestData?.collateralChannes?.filter(x => x.type === PerformanceBondType.cash).length > 0) && (
            <Col sm={12}
              className='mt-1'>
              <Title text='บันทึกผลการพิจารณาคืนหลักประกันสัญญา'
                lineBorder />
              <p className='p-2'>กรณีวางหลักประกันเป็นเงินสด (เงินโอน) <span className='text-danger'> *</span></p>
              <ul>
                {
                  (transferDocumentTypeDDL.length > 0) ?
                    transferDocumentTypeDDL.map((data, index) => (
                      <li key={generateUniqueId(index)}>
                        <Check label={data.label}
                          value={(transferDocumentTypes !== undefined && transferDocumentTypes.length > 0) ? transferDocumentTypes?.filter(x => x.transferDocumentTypeId === data.value)[0].result
                            : false}
                          onChange={(val) => onChangeTransferDocumentType(data.value, val)}
                          disabled={isDisabled} />
                      </li>
                    )) : <></>
                }
              </ul>
            </Col>
          )
        }
      </Row>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5'
          confidentiality />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFile
              disabled={contractReturnRequestData.id === null || contractReturnRequestData.id === undefined}
              files={personalfiles}
              handleFileChange={handlePersonalFileChange}
              removeItem={removeItemPersonalAsync}
              contractApprovalId={contractReturnRequestData.id!}
            />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5' />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFile
              disabled={contractReturnRequestData.id === null || contractReturnRequestData.id === undefined}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractApprovalId={contractReturnRequestData.id!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>

        <ButtonCustom text='ย้อนกลับ'
          onClick={goToBack}
          icon='arrowback'
          variant='outline-primary' />

        {isAssignee && (
          contractReturnRequestData.status === ContractReturnRequestStatus.None
          || contractReturnRequestData.status === ContractReturnRequestStatus.Draft
          || contractReturnRequestData.status === ContractReturnRequestStatus.Rejected) ?
          <ButtonCustom text='บันทึก'
            variant='outline-primary'
            onClick={() => setShowSaveConfirmModal(true)} />
          : <></>
        }

        {
          isNextStep ?
            <ButtonCustom text='ถัดไป'
              onClick={nextStep}
              icon='arrowforward'
              variant='outline-primary'
              iconAlignRight /> : <div style={{ width: 140 }} />
        }
      </div>

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={contractReturnRequestData.id}
        title='ขออนุมัติคืนหลักประกันสัญญา'
        status={ContractReceiveCollateral.Request}
      />

      <Modal
        show={showSaveConfirmModal}
        size='lg'
        onHide={() => setShowSaveConfirmModal(!showSaveConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการบันทึกข้อมูลหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowSaveConfirmModal(!showSaveConfirmModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onSave}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step1;
