interface Props {
  text: string;
  className?: string;
}

function Heading({ text, className = 'text-primary' }: Props) {
  return (
    <p className={`ms-3 fs-4 mb-0 ${className}`}>{text}</p>
  );
}

export default Heading;