import {
  ContractManagementModel,
  ContractManagementReceiveModel,
  CountStatusModel,
  CountSupplyMethodsModel,
  CreateContractModel,
  DeliveryModel,
  IDelivery,
  IDeliveryItemCreate,
  IDeliveryItemCreateModel,
  IDeliveryParcel,
  IPaidPhase,
  IReceive,
  MaintenanceInfomationDeliveryModel,
  SearchContractManagementListModel,
  updateAcceptorModel,
  updateAssignsModel,
  UpdateContractModel,
  UpdateReceiveRequestModel,
} from 'models/CM/CM03Models';
import http from 'utils/axios';

const qs = require('qs');

const api = '/contractManagement';
const apiUpdateStatus = 'update-receive-status';
const apiApprove = 'approve-receive';
const apiReject = 'reject-receive';
const apiUpdateReceive = 'update-receive';

const getListContractManagementAsync = (searchData: SearchContractManagementListModel) => {
  const params = {
    ...searchData,
    page: searchData.page,
    pageSize: searchData.pageSize,
    contractNumber: searchData.contractNumber,
    planNumber: searchData.planNumber,
    contractName: searchData.contractName,
    poNumber: searchData.poNumber,
    planName: searchData.planName,
    departmentId: searchData.departmentId,
    countStatus: searchData.contractStatus,
    isResponsible: searchData.isResponsible,
    supplyMethodIds: searchData.supplyMethodIds, // FIXME: Change to real data
  };

  return http.get<ContractManagementModel>(`${api}/pagination`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountStatusAsync = (searchData: SearchContractManagementListModel) => {
  const params = {
    ...searchData,
    page: searchData.page,
    pageSize: searchData.pageSize,
    contractNumber: searchData.contractNumber,
    poNumber: searchData.poNumber,
    planNumber: searchData.planNumber,
    contractName: searchData.contractName,
    planName: searchData.planName,
    departmentId: searchData.departmentId,
    countStatus: searchData.contractStatus,
    isResponsible: searchData.isResponsible,
    supplyMethodIds: searchData.supplyMethodIds, // FIXME: Change to real data
  };
  return http.get<CountStatusModel[]>(`${api}/countStatus`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};


const getCountSupplyMethodAsync = (searchData: SearchContractManagementListModel) => {
  const params = {
    ...searchData,
    page: searchData.page,
    pageSize: searchData.pageSize,
    contractNumber: searchData.contractNumber,
    poNumber: searchData.poNumber,
    planNumber: searchData.planNumber,
    contractName: searchData.contractName,
    planName: searchData.planName,
    departmentId: searchData.departmentId,
    countStatus: searchData.contractStatus,
    isResponsible: searchData.isResponsible,
    supplyMethodIds: searchData.supplyMethodIds, // FIXME: Change to real data
  };
  return http.get<CountSupplyMethodsModel[]>(`${api}/countSupplyMethod`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getContractManagementByIdAsync = async (contractAgreementId?: string, contractRecordId?: string, financialId?: string, entrepreneursId?: string) => {

  const params = {
    contractAgreementId,
    contractRecordId,
    financialId,
    entrepreneursId,
  };

  return http.get(`${api}/single`, { params });
};

const getContractManagementDeliveryByIdAsync = (contractId: string, deliveryId: string) => http.get<DeliveryModel>(`${api}/${contractId}/delivery/${deliveryId}`);

const onSaveDeliveryAsync = (deliveryId: string, deliveryDate?: Date, remark?: string) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put<DeliveryModel>(`${api}/update-delivery/${deliveryId}`, { deliveryDate, remark }, config);
};

const onSaveDeliveryStatusAsync = (deliveryId: string) => http.put<DeliveryModel>(`${api}/update-delivery-status/${deliveryId}/delivered`);

const getDeliveryListAsync = (contractId: string) => http.get<IDelivery[]>(`${api}/${contractId}/delivery-list`);

const getReceiveListAsync = (contractId: string) => http.get<IReceive[]>(`${api}/${contractId}/receive-list`);

const createReceiveAsync = async (contractId: string, deliveries: string[]) => {
  const formData = new FormData();
  formData.append('contractId', contractId);

  deliveries.forEach((delivery, index) => {
    formData.append(`deliverys[${index}][id]`, delivery);
  });

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/create-receive`, formData, config);
};

const createPaidPhaseAsync = async (contractId: string, receives: string[]) => {
  const formData = new FormData();
  formData.append('contractId', contractId);

  receives.forEach((delivery, index) => {
    formData.append(`receives[${index}][id]`, delivery);
  });

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/create-paid-phase`, formData, config);
};

const getDeliveryParcelListAsync = async (contractId: string) => http.get<IDeliveryParcel[]>(`${api}/delivery-parcel-list/${contractId}`);

// const addDeliveryItemsAsync = (deliveryId: string, deliveryItemDate?: Date, dataList?: IDeliveryItemCreateModel) => {

//   const body = {
//     DeliveryItemDate: deliveryItemDate,
//     DeliveryItems: dataList?.deliveryItems,
//   };

//   return http.put(`${api}/add-delivery-items/${deliveryId}`, body);
// };

const deleteDeliveryItemAsync = async (id: string, deliveryItemId: string) =>
  await http.delete(`${api}/${id}/deliveryItem/${deliveryItemId}`);

const updateDeliveryItemsAsync = (deliveryId: string, deliveryItemDate: Date, dataList?: IDeliveryItemCreate) => {

  const body = {
    DeliveryItems: dataList,
    DeliveryItemDate: deliveryItemDate,
  };

  return http.put(`${api}/update-delivery-items/${deliveryId}`, body);
};

const addDeliveryItemsAsync = (deliveryId: string, deliveryItemDate: Date, body: IDeliveryItemCreateModel) => {

  const formData = new FormData();

  formData.append('DeliveryItemDate', new Date(deliveryItemDate!).toISOString());

  if (body.deliveryItems && body.deliveryItems.length !== 0) {

    body.deliveryItems?.forEach((data, index) => {
      formData.append(`DeliveryItems[${index}][contractManagementDeliveryId]`, data.contractManagementDeliveryId);
      formData.append(`DeliveryItems[${index}][jorPor06ParcelId]`, data.jorPor06ParcelId);
      formData.append(`DeliveryItems[${index}][goodsName]`, data.goodsName ? data.goodsName : '');
      formData.append(`DeliveryItems[${index}][sequence]`, data.sequence ? data.sequence.toString() : '');
      formData.append(`DeliveryItems[${index}][additionalParcelItem]`, data.goodsName ? data.goodsName : '');
      formData.append(`DeliveryItems[${index}][quantity]`, data.quantity.toString());
      formData.append(`DeliveryItems[${index}][amount]`, data.amount.toString());
      formData.append(`DeliveryItems[${index}][remainQuantity]`, data.remainQuantity ? data.remainQuantity.toString() : '');
      formData.append(`DeliveryItems[${index}][deliveryQuantity]`, data.deliveryQuantity ? data.deliveryQuantity.toString() : '');
      formData.append(`DeliveryItems[${index}][remark]`, data.remark ? data.remark.toString() : '');
      formData.append(`DeliveryItems[${index}][OtherDiscretionParcelItem]`, data.otherDiscretionParcelItem ? data.otherDiscretionParcelItem.toString() : '');
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/add-delivery-items/${deliveryId}`, formData, config);
};

const getPaidPhaseAsync = (contractId: string, paidId: string) => http.get<IPaidPhase>(`${api}/${contractId}/paidphase/${paidId}`);

const updatePaidPhaseAsync = (paidId: string, updateData: IPaidPhase) => {
  const formData = {
    paidDate: updateData.paidDate,
    receivedNumber: updateData.receivedNumber,
    accountingPaidDate: updateData.accountingPaidDate,
    amount: updateData.amount,
    retentionAmount: updateData.retentionAmount,
    advanceAmount: updateData.advanceAmount,
    fineAmount: updateData.fineAmount,
    remainAmount: updateData.remainAmount,
    remark: updateData.remark,
  };

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/update-paid-phase/${paidId}`, { ...formData }, config);
};

const getContractManagementReceiveByIdAsync = (contractId: string, receiveId: string) => http.get<ContractManagementReceiveModel>(`${api}/${contractId}/receive/${receiveId}`);

const updateReceiveRequestAsync = (body: UpdateReceiveRequestModel) => {

  const formData = new FormData();

  formData.append('receivedDate', new Date(body.receivedDate).toISOString());
  formData.append('receivedNumber', body.receivedNumber ? body.receivedNumber : '');
  formData.append('remark', body.remark ? body.remark : '');
  formData.append('amount', body.amount ? body.amount.toString() : '0');
  formData.append('retentionAmount', body.retentionAmount ? body.retentionAmount.toString() : '0');
  formData.append('advanceAmount', body.advanceAmount ? body.advanceAmount.toString() : '0');
  formData.append('fineAmount', body.fineAmount ? body.fineAmount.toString() : '0');
  formData.append('remainAmount', body.remainAmount ? body.remainAmount.toString() : '0');

  body.receiveFineAmounts?.forEach((data, index) => {
    formData.append(`receiveFineAmounts[${index}][contractManagementReceiveId]`, body.id);
    formData.append(`receiveFineAmounts[${index}][detail]`, data.detail ? data.detail.toString() : '');
    formData.append(`receiveFineAmounts[${index}][amount]`, data.amount ? data.amount.toString() : '0');
    formData.append(`receiveFineAmounts[${index}][sequence]`, (index + 1).toString());
  });

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/update-receive/${body.id}`, formData, config);

};

const confirmPaidAsync = async (id: string) => await http.put(`${api}/update-paid-status/${id}/paid`);

const waitForInspectorReceivedAsync = async (id: string) => await http.put(`${api}/${apiUpdateStatus}/${id}/waitForInspectorReceived`);

const waitForJorPorApproveReceivedAsync = async (id: string) => await http.put(`${api}/${apiUpdateStatus}/${id}/waitForJorPorApprove`);

const waitForApproveReceivedAsync = async (id: string) => await http.put(`${api}/${apiUpdateStatus}/${id}/waitForApprove`);

const committeesRejectAsync = (id: string, remark: string) => {

  const formData = new FormData();

  formData.append('remark', remark || '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${apiReject}/${id}/inspectorRejected`, formData, config);

};

const committeesApprovedAsync = async (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('remark', remark || '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${apiApprove}/${id}/received`, formData, config);

};

// const updateAssignsAsync =  (id: string, body: updateAssignsModel) => {
//   return http.post(`${api}/${id}/acceptors`, body);
// };

const updateAssignsAsync = (id: string, body: updateAssignsModel) => {

  const formData = new FormData();

  if (body.contractManagementReceiveAssigns && body.contractManagementReceiveAssigns.length !== 0) {

    body.contractManagementReceiveAssigns?.forEach((data, index) => {

      if (data.id) {
        formData.append(`receiveAssign[${index}][Id]`, data.id!);
      }
      formData.append(`receiveAssign[${index}][UserId]`, data.userId);
      formData.append(`receiveAssign[${index}][Fullname]`, data.fullName);
      formData.append(`receiveAssign[${index}][DepartmentName]`, data.departmentName ? data.departmentName : '');
      formData.append(`receiveAssign[${index}][PositionName]`, data.positionName ? data.positionName : '');
      formData.append(`receiveAssign[${index}][IsAvailable]`, data.isAvailable ? 'true' : 'false');
      formData.append(`receiveAssign[${index}][AvailableRemark]`, data.availableRemark ? data.availableRemark : '');
      if (data.acceptDate) {
        formData.append(`receiveAssign[${index}][AcceptDate]`, new Date(data.acceptDate!).toISOString());
      }
      formData.append(`receiveAssign[${index}][AcceptRemark]`, data.acceptRemark ? data.acceptRemark : '');
      if (data.rejectDate) {
        formData.append(`receiveAssign[${index}][RejectDate]`, new Date(data.rejectDate!).toISOString());
      }
      formData.append(`receiveAssign[${index}][RejectRemark]`, data.rejectRemark ? data.rejectRemark : '');
      formData.append(`receiveAssign[${index}][Sequence]`, data.sequence.toString());
      formData.append(`receiveAssign[${index}][Level]`, data.level.toString());

    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${apiUpdateReceive}/${id}/addReceiveAssign`, formData, config);

};

const updateAcceptorAsync = (id: string, body: updateAcceptorModel, documentId?: string) => {

  const formData = new FormData();

  if (documentId) {
    formData.append(`documentId`, documentId);
  }

  if (body.contractManagementReceiveAcceptors && body.contractManagementReceiveAcceptors.length !== 0) {
    let inx = 0;
    let sectionSeq = 0;
    body.contractManagementReceiveAcceptors?.forEach((data, index) => {

      if (data.sectionSequence && sectionSeq !== data.sectionSequence) {
        inx = 1;
        sectionSeq = data.sectionSequence!;
      }

      if (data.id) {
        formData.append(`receiveAcceptor[${index}][Id]`, data.id ? data.id : '');
      }
      formData.append(`receiveAcceptor[${index}][UserId]`, data.userId);
      formData.append(`receiveAcceptor[${index}][Fullname]`, data.fullName);
      formData.append(`receiveAcceptor[${index}][DepartmentName]`, data.departmentName ? data.departmentName : '');
      formData.append(`receiveAcceptor[${index}][PositionName]`, data.positionName ? data.positionName : '');
      formData.append(`receiveAcceptor[${index}][IsAvailable]`, data.isAvailable ? 'true' : 'false');
      formData.append(`receiveAcceptor[${index}][AvailableRemark]`, data.availableRemark ? data.availableRemark : '');
      if (data.acceptDate) {
        formData.append(`receiveAcceptor[${index}][AcceptDate]`, new Date(data.acceptDate!).toISOString());
      }
      formData.append(`receiveAcceptor[${index}][AcceptRemark]`, data.acceptRemark ? data.acceptRemark : '');
      if (data.rejectDate) {
        formData.append(`receiveAcceptor[${index}][RejectDate]`, new Date(data.rejectDate!).toISOString());
      }
      formData.append(`receiveAcceptor[${index}][RejectRemark]`, data.rejectRemark ? data.rejectRemark : '');
      formData.append(`receiveAcceptor[${index}][Sequence]`, data.sequence ? data.sequence.toString() : '');
      formData.append(`receiveAcceptor[${index}][SectionInRefCode]`, data.sectionInRefCode);
      formData.append(`receiveAcceptor[${index}][SectionName]`, data.sectionName);
      formData.append(`receiveAcceptor[${index}][SectionSequence]`, data.sectionSequence ? data.sectionSequence.toString() : '');
      formData.append(`Acceptors[${index}][Sequence]`, (inx).toString());

      inx++;

    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${apiUpdateReceive}/${id}/addReceiveAcceptor`, formData, config);

};

const jorPorRejectAsync = async (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('remark', remark || '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${apiReject}/${id}/jorPorRejected`, formData, config);

};

const jorPorApprovedAsync = async (id: string, remark: string, jorPorComment?: string) => {
  const formData = new FormData();

  formData.append('remark', remark ?? '');
  formData.append('jorPorComment', jorPorComment ?? '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${apiApprove}/${id}/jorPorApprove`, formData, config);

};

const acceptorsRejectAsync = async (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('remark', remark || '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${apiReject}/${id}/rejected`, formData, config);

};

const acceptorsApprovedAsync = async (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('remark', remark || '');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${apiApprove}/${id}/approved`, formData, config);

};

const uploadAttachmentReceiveAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/receive/${id}/upload-file`, formData, config);

};

const getAttachmentReceiveAsync = (id?: string, documentId?: string) => http.get(`${api}/receive/${id}/download-file/${documentId}`, {
  responseType: 'blob',
});

const removeAttachmentReceiveAsync = async (id: string, attachmentId: string) =>
  await http.delete(`${api}/receive/${id}/delete-file/${attachmentId}`);

const getListReceiveHistoryAsync = (page: number, size: number, id: string | undefined) => {
  const params = {
    page,
    size,
    id,
  };

  return http.get(`${api}/receive/history`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const uploadDeliveryAttachmentAsync = (deliveryId: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/delivery/${deliveryId}/upload-file`, formData, config);
};

const removeDeliveryFileAsync = (deliveryId: string, fileId: string) => http.delete(`${api}/delivery/${deliveryId}/delete-file/${fileId}`);

const downloadPaidPhaseAttachmentAsync = (paidId: string, fileId: string) => http.get(`${api}/paid-phase/${paidId}/download-file/${fileId}`, {
  responseType: 'blob',
});

const uploadPaidPhaseAttachmentAsync = (paidId: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/paid-phase/${paidId}/upload-file`, formData, config);
};

const removePaidPhaseFileAsync = (paidId: string, fileId: string) => http.delete(`${api}/paid-phase/${paidId}/delete-file/${fileId}`);

const downloadDeliveryAttachmentAsync = (deliveryId: string, fileId: string) => http.get(`${api}/delivery/${deliveryId}/download-file/${fileId}`, {
  responseType: 'blob',
});

// const getDocumentAsync = (id: string) => http.get(`${api}/${id}/document`);

const getDocumentInspectionAsync = (id: string) => http.get(`${api}/${id}/document-inspection`);

const updateDocumentAsync = (id: string, documentId: string) =>
  http.put(`${api}/${id}/document`, { documentId });

const createMaintenanceInfomationAsync = (body: MaintenanceInfomationDeliveryModel) => {
  return http.post(`${api}/${body.id}/create-delivery-naintenance/${body.maintenanceId}`, body);
};

const onReCallAsync = (id: string) => http.put(`${api}/${id}/recall`);

const deleteDeliveryAsync = async (contractId: string, deliveryId: string) =>
  await http.delete(`${api}/${contractId}/delivery/${deliveryId}`);

const getRequestApprovalFineDocumentAsync = (id: string) => {
  return http.get(`${api}/${id}/document-request-approval-fine`);
};

const createContractRequestAsync = (body: CreateContractModel) => {
  return http.post(`${api}/create-contract`, body);
};

// const updateContractRequestAsync = (id: string, body: UpdateContractModel) => {
//   return http.post(`${api}/update-contract/${id}`, body);
// };

const updateContractRequestAsync = (id: string, body: UpdateContractModel) => {
  const formData = new FormData();

  if (body.deliveryPeriod) {
    formData.append('deliveryPeriod.periodDay', body.deliveryPeriod.periodDay != null ? body.deliveryPeriod.periodDay.toString() : '');
    formData.append('deliveryPeriod.periodMonth', body.deliveryPeriod.periodMonth != null ? body.deliveryPeriod.periodMonth.toString() : '');
    formData.append('deliveryPeriod.periodYear', body.deliveryPeriod.periodYear != null ? body.deliveryPeriod.periodYear.toString() : '');
    formData.append('deliveryPeriod.dueDate', body.deliveryPeriod.dueDate != null ? new Date(body.deliveryPeriod.dueDate).toISOString() : '');
  }

  if (body.warrantyPeriod) {
    formData.append('warrantyPeriod.periodDay', body.warrantyPeriod.periodDay != null ? body.warrantyPeriod.periodDay.toString() : '');
    formData.append('warrantyPeriod.periodMonth', body.warrantyPeriod.periodMonth != null ? body.warrantyPeriod.periodMonth.toString() : '');
    formData.append('warrantyPeriod.periodYear', body.warrantyPeriod.periodYear != null ? body.warrantyPeriod.periodYear.toString() : '');
    formData.append('warrantyPeriod.dueDate', body.warrantyPeriod.dueDate != null ? new Date(body.warrantyPeriod.dueDate).toISOString() : '');
  }

  if (body.contractManagementDelivery && body.contractManagementDelivery.length > 0) {

    body.contractManagementDelivery.forEach((data, index) => {
      if (data.id) {
        formData.append(`contractManagementDelivery[${index}][id]`, data.id);
      }
      formData.append(`contractManagementDelivery[${index}][sequence]`, data.sequence.toString());
      formData.append(`contractManagementDelivery[${index}][phaseNumber]`, data.phaseNumber.toString());
      formData.append(`contractManagementDelivery[${index}][periodDay]`, data.periodDay != null ? data.periodDay.toString() : '');
      formData.append(`contractManagementDelivery[${index}][amount]`, data.amount.toString());
      formData.append(`contractManagementDelivery[${index}][percent]`, data.percent.toString());
      formData.append(`contractManagementDelivery[${index}][detail]`, data.detail.toString() ?? '');

      if (data.status) {
        formData.append(`contractManagementDelivery[${index}][status]`, data.status.toString() ?? '');
      }

      formData.append(`contractManagementDelivery[${index}][deliveryDueDate]`, data.deliveryDueDate != null ? new Date(data.deliveryDueDate).toISOString() : '');
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/update-contract/${id}`, formData, config);
};

const CM03Service = {
  getRequestApprovalFineDocumentAsync,
  getListContractManagementAsync,
  getContractManagementByIdAsync,
  getContractManagementDeliveryByIdAsync,
  getContractManagementReceiveByIdAsync,
  updateReceiveRequestAsync,
  waitForInspectorReceivedAsync,
  waitForJorPorApproveReceivedAsync,
  waitForApproveReceivedAsync,
  committeesRejectAsync,
  committeesApprovedAsync,
  updateAssignsAsync,
  jorPorRejectAsync,
  jorPorApprovedAsync,
  uploadAttachmentReceiveAsync,
  getAttachmentReceiveAsync,
  removeAttachmentReceiveAsync,
  updateAcceptorAsync,
  acceptorsRejectAsync,
  acceptorsApprovedAsync,
  getListReceiveHistoryAsync,
  onSaveDeliveryAsync,
  onSaveDeliveryStatusAsync,
  getDeliveryListAsync,
  getReceiveListAsync,
  getDeliveryParcelListAsync,
  addDeliveryItemsAsync,
  createReceiveAsync,
  createPaidPhaseAsync,
  getPaidPhaseAsync,
  updatePaidPhaseAsync,
  uploadDeliveryAttachmentAsync,
  removeDeliveryFileAsync,
  downloadDeliveryAttachmentAsync,
  uploadPaidPhaseAttachmentAsync,
  removePaidPhaseFileAsync,
  downloadPaidPhaseAttachmentAsync,
  confirmPaidAsync,
  getCountStatusAsync,
  deleteDeliveryItemAsync,
  onReCallAsync,
  getCountSupplyMethodAsync,
  createContractRequestAsync,
  updateContractRequestAsync,
  getDocumentInspectionAsync,
  updateDocumentAsync,
  createMaintenanceInfomationAsync,
  deleteDeliveryAsync,
  updateDeliveryItemsAsync,
};

export default CM03Service;
