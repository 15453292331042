import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  DatePicker,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ContractAgreementModel, EmploymentAgreementModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';

interface IProps {
  title?: string;
  contractTemplateCode?: string;
  contractData: ContractAgreementModel;
  employmentAgreement: EmploymentAgreementModel;
  onEmploymentAgreementChange: (value: EmploymentAgreementModel) => void;
  isDisabled?: boolean;
}

const fields = [
  {
    code: 'CF01',
    processingTime: 'ผู้รับจ้างจะต้องทำงานให้แล้วเสร็จ ภายใน',
    processingTimePerYear: 'ผู้รับจ้างจะต้องทำงานให้แล้วเสร็จ ภายใน',
    processingTimePerMonth: 'เดือน',
    processingTimePerDay: 'วัน',
  },
  {
    code: 'CF02',
  },
  {
    code: 'CF03',
  },
  {
    code: 'CF04',
  },
  {
    code: 'CF05',
    processingTimeTitle: '3.3 กำหนดระยะเวลาอนุญาตให้ใช้สิทธิในโปรแกรมคอมพิวเตอร์เริ่มต้นตั้งแต่วันที่ได้รับมอบโปรแกรมคอมพิวเตอร์',
    dueDate: 'วันที่สิ้นสุดระยะเวลาอนุญาตให้ใช้สิทธิในโปรแกรมคอมฯ',
    processingTime: 'กำหนดระยะเวลา',
    processingTimePerYear: 'กำหนดระยะเวลา',
    processingTimePerMonth: 'เดือน',
    processingTimePerDay: 'วัน',
  },
  {
    code: 'CF06',
  },
  {
    code: 'CF07',
  },
  {
    code: 'CF08',
  },
  {
    code: 'CF09',
  },
  {
    code: 'CF10',
  },
  {
    code: 'CF11',
  },
  {
    code: 'CF12',
  },
  {
    code: 'CF13',
    startDate: 'ผู้รับจ้างต้องเริ่มทำงาน ภายในวันที่',
    dueDate: 'ผู้รับจ้างต้องทำงานให้เสร็จบริบูรณ์ ภายในวันที่',
  },
  {
    code: 'CF14',
  },
  {
    code: 'CF15',
  },
];

export function ProcessingTime(props: IProps) {
  const [employmentAgreement, setEmploymentAgreement] = useState<EmploymentAgreementModel>(props.employmentAgreement);

  useEffect(() => {
    if (props.employmentAgreement) {
      setEmploymentAgreement(props.employmentAgreement);
    }
  }, [props.employmentAgreement]);

  useEffect(() => {
    if (employmentAgreement) {
      props.onEmploymentAgreementChange(employmentAgreement);
    }
  }, [employmentAgreement]);

  const onNumberChange = useCallback((key: string, value: number) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <>
      <Card className='mt-3'>
        <Title text={`สัญญา${props.title}`}
          className='fs-5 text-primary' />
        <Row className='mt-3'>
          <>
            {
              fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
                <div>
                  {
                    data.processingTimeTitle &&
                    (
                      <Col sm={12}
                        md={12}>
                        <label className='mt-5'>{data.processingTimeTitle}</label>
                      </Col>
                    )
                  }
                  {
                    (data.startDate || data.dueDate) && (
                      <Row className='mt-3'>
                        {
                          (data.startDate) &&
                          (
                            <Col sm={4}>
                              <DatePicker
                                label={data.startDate}
                                value={props.contractData.deliveryPeriod.startDate}
                                onChangeHasNullable={(value) => setEmploymentAgreement({ ...employmentAgreement, startDate: value })}
                                disabled={true}
                              />
                            </Col>
                          )
                        }
                        {
                          (data.dueDate) &&
                          (
                            <Col sm={4}>
                              <DatePicker
                                label={data.dueDate}
                                value={props.contractData.deliveryPeriod.dueDate}
                                onChangeHasNullable={(value) => setEmploymentAgreement({ ...employmentAgreement, dueDate: value })}
                                disabled={true}
                              />
                            </Col>
                          )
                        }
                      </Row>
                    )
                  }
                  {
                    data.processingTime &&
                    (
                      <Row>
                        {
                          data.processingTimePerYear &&
                          (
                            <Col sm={4} className='d-flex'>
                              <InputNumber
                                textCenter
                                label={data.processingTimePerYear}
                                value={employmentAgreement?.processingTimePerYear}
                                onChange={(val) => onNumberChange('processingTimePerYear', Number(val))}
                                disabled={props.isDisabled}
                              />
                              <label className='mt-5 ps-2'>ปี</label>
                            </Col>
                          )
                        }
                        {
                          data.processingTimePerMonth &&
                          (
                            <Col sm={4} className='d-flex'>
                              <InputNumber
                                textCenter
                                className='no-label'
                                value={employmentAgreement?.processingTimePerMonth}
                                onChange={(val) => onNumberChange('processingTimePerMonth', Number(val))}
                                disabled={props.isDisabled}
                              />
                              <label className='mt-5 ps-2'>เดือน</label>
                            </Col>
                          )
                        }
                        {
                          data.processingTimePerDay &&
                          (
                            <Col sm={4} className='d-flex'>
                              <InputNumber
                                textCenter
                                className='no-label'
                                value={employmentAgreement?.processingTimePerDay}
                                onChange={(val) => onNumberChange('processingTimePerDay', Number(val))}
                                disabled={props.isDisabled}
                              />
                              <label className='mt-5 ps-2'>วัน</label>
                            </Col>
                          )
                        }
                      </Row>
                    )
                  }
                </div>
              )}
          </>
        </Row>
      </Card>
    </>
  );
}