import { CriteriaPlanProcurementList } from 'models';
import {
  planProcurement,
  shareValue,
} from 'services';

export const changePlanProcurementLoader = async () => {
  const [department, SupplyMethod, changePlanData, count] = await Promise.all([
    shareValue.GetDDL('Department'),
    shareValue.GetDDL('SupplyMethod'),
    planProcurement.getChangePlanAsync(1, 10, { isAssignToMeOnly: true, isChanged: true } as CriteriaPlanProcurementList),
    planProcurement.getPlanChangeStatusCount(1, 10, { isAssignToMeOnly: true, isChanged: true } as CriteriaPlanProcurementList),
  ]);
  const SupplyMethodAll = [{ label: 'ทั้งหมด', value: 'All' }, ...SupplyMethod];

  return {
    departmentDDL: department,
    supplyMethodDDL: SupplyMethodAll,
    changePlanData: changePlanData.data.data,
    changePlanTotal: changePlanData.data.totalRecords,
    statusCount: count.data,
  };
};
