import {
  Card,
  InputTextArea,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { BsArrowReturnLeft } from 'react-icons/bs';
import {
  FaAlignLeft,
  FaCheck,
  FaHistory,
  FaUndo,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="document">

      <Card className="mt-3">
        <div className="d-flex justify-content-between my-3">
          <Title text="เอกสารรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง (จพ.006)" className="fs-5 text-primary" />
          <Button
            className="px-3"
            onClick={() => setShow(true)}
            variant="light"
          >
            <FaHistory className="me-3" />ประวัติการแก้ไข
          </Button>
        </div>
      </Card>
      <div className="d-flex justify-content-center gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-4" />
          ย้อนกลับ
        </Button>
        <Button
          variant="danger"
          onClick={() => setShow(true)}
          className="me-2 px-3"
        >
          <FaUndo className="mx-2 pb-1 fs-4" />
          ส่งกลับแก้ไข
        </Button>
        <Button
          onClick={() => setShowModal(true)}
          className="me-2 px-3"
        >
          <FaAlignLeft className="me-2 pb-1 fs-5" />
          ส่งอนุมัติ
        </Button>
      </div>
      <Modal
        show={showModal}
        size="lg"
        onHide={() => setShowModal(!showModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>อนุมัติ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              <InputTextArea label="อนุมัติ" rule={{ required: true }} />
            </ModalBT.Body>
            <ModalBT.Footer className="justify-content-center">
              <Button variant="light" onClick={() => setShow(!show)}>
                ยกเลิก
              </Button>
              <Button variant="success text-white">
                <FaCheck className="me-2" /> ให้ความเห็น
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(!show)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              <InputTextArea label="เหตุผล" rule={{ required: true }} />
            </ModalBT.Body>
            <ModalBT.Footer className="justify-content-center">
              <Button variant="light" onClick={() => setShow(!show)}>
                ยกเลิก
              </Button>
              <Button variant="danger">
                <FaUndo className="mx-2 pb-1 fs-4" />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step2;
