import { ApexOptions } from 'apexcharts';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

interface Data {
  label: string;
  color: string;
  value: number;
}

interface Props {
  data: Data[];
  label?: string;
}

export function PieChart(props: Props) {
  const options = useMemo(() => {
    return {
      labels: props.data.map(d => d.label),
      colors: props.data.map(d => d.color),
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: false,
              },
              total: {
                show: true,
                showAlways: true,
                label: props.label,
                fontSize: '1.5rem',
                fontFamily: 'Kanit, sans-serif',
              },
            },
          },
        },
      },
    } as ApexOptions;
  }, [props]);

  const series = useMemo(() => {
    return props.data.map(d => d.value || 0);
  }, [props]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut" />
  );
}
