import {
  useEffect,
  useState,
} from 'react';
import Badge from 'react-bootstrap/Badge';

interface Props {
  value: string;
}

const STATUS = [
  { value: 'None', color: '#B7B7B7', label: 'ยังไม่ได้ดำเนินการ' },
  { value: 'Draft', color: '#B7B7B7', label: 'แบบร่าง' },
  { value: 'Pending', color: '#FBBD01', label: 'รอเห็นชอบ/รออนุมัติ' },
  { value: 'Approved', color: '#3CB371', label: 'เห็นชอบ/อนุมัติ' },
  { value: 'Rejected', color: '#E94F45', label: 'ส่งกลับแก้ไข' },
  { value: 'Cancelled', color: '#E94F45', label: 'ยกเลิก' },
];

const STATUS_DETAIL = [
  { value: 'None', color: '#secondary', label: 'ยังไม่ได้ดำเนินการ' },
  { value: 'Draft', color: 'secondary', label: 'ร่างเห็นชอบ' },
  { value: 'Pending', color: 'warning', label: 'รอเห็นชอบ' },
  { value: 'Approved', color: 'success', label: 'เห็นชอบ' },
  { value: 'Rejected', color: 'danger', label: 'ส่งกลับแก้ไข' },
];

const STATUS_APPROVED = [
  { value: 'None', color: '#secondary', label: 'ยังไม่ได้ดำเนินการ' },
  { value: 'Draft', color: 'secondary', label: 'ร่างอนุมัติ' },
  { value: 'Pending', color: 'warning', label: 'รออนุมัติ' },
  { value: 'Approved', color: 'success', label: 'อนุมัติ' },
  { value: 'Rejected', color: 'danger', label: 'ส่งกลับแก้ไข' },
];

function ProcurementAppointmentOrderStatus(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);
  return (
    <>
      {props.value
        ? (
          <div className="directive-status" style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

function ProcurementAppointmentOrderStatusDetail(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS_DETAIL.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color} className="text-white">
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}

function StatusDetailApproved(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS_APPROVED.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color} className="text-white">
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}


export { ProcurementAppointmentOrderStatusDetail, ProcurementAppointmentOrderStatus, StatusDetailApproved };
