import { AcceptorCriteria } from 'models';
import { setTimeEndDate, setTimeStartDate } from '../utils';
import http from '../utils/axios';
import { ProjectCriteria } from 'models/projectListModels';

const getSummaryAsync = async (startDate?: Date, endDate?: Date, departmentId?: string, supplyMethodId?: string) => {
  const start = startDate ? setTimeStartDate(startDate) : startDate;
  const end = endDate ? setTimeEndDate(endDate) : endDate;

  return await http.get('dashboard/summary', {
    params: {
      startDate: start,
      endDate: end,
      departmentId,
      supplyMethodId
    }
  });
};

const getChartAsync = async (startDate?: Date, endDate?: Date, departmentId?: string, supplyMethodId?: string) => {
  const start = startDate ? setTimeStartDate(startDate) : startDate;
  const end = endDate ? setTimeEndDate(endDate) : endDate;

  return await http.get('dashboard/chart', {
    params: {
      startDate: start,
      endDate: end,
      departmentId,
      supplyMethodId
    }
  });
};

const getProjectListAsync = async (searchModel: ProjectCriteria) => {
  const start = searchModel.startDate ? setTimeStartDate(searchModel.startDate) : searchModel.startDate;
  const end = searchModel.endDate ? setTimeEndDate(searchModel.endDate) : searchModel.endDate;

  return await http.get('dashboard/project-list', {
    params: {
      page: searchModel.page,
      size: searchModel.pageSize,
      startDate: start,
      endDate: end,
      departmentId: searchModel.departmentId,
      supplyMethodId: searchModel.supplyMethodId,
      planNumber: searchModel.planNumber,
      planName: searchModel.planName,
      traderName: searchModel.traderName,
      contractNumber: searchModel.contractNumber,
      poNumber: searchModel.poNumber,
    }
  });
};

const getSupplyMethodTypeListAsync = async (page: number, size: number) => {
  return await http.get('dashboard/supply-method-type-list', {
    params: {
      page,
      size
    }
  });
};

const getWorklistPlanAsync = async (planNo: string, planName: string, departmentId: string, supplyMethodId: string, isResponsible: boolean, isJorPor: boolean, page: number, size: number) => {
  const params = {
    planNo: planNo,
    planName: planName,
    departmentId: departmentId !== 'All' ? departmentId : undefined,
    supplyMethodId: supplyMethodId !== 'All' ? supplyMethodId : undefined,
    isResponsible: isResponsible === undefined ? true : isResponsible,
    isJorPor: isJorPor === undefined ? false : isJorPor,
    page: page,
    size: size
  };
  return http.get('dashboard/worklist-plan', {
    params
  });
};

const getWorklistPreProAsync = async (planNo: string, planName: string, departmentId: string, supplyMethodId: string, isResponsible: boolean, isJorPor: boolean, page: number, size: number) => {
  const params = {
    planNo: planNo,
    planName: planName,
    departmentId: departmentId !== 'All' ? departmentId : undefined,
    supplyMethodId: supplyMethodId !== 'All' ? supplyMethodId : undefined,
    isResponsible: isResponsible === undefined ? true : isResponsible,
    isJorPor: isJorPor === undefined ? false : isJorPor,
    page: page,
    size: size
  };
  return http.get('dashboard/worklist-prepro', {
    params
  });
};

const getWorklistProAsync = async (planNo: string, planName: string, departmentId: string, supplyMethodId: string, isResponsible: boolean, isJorPor: boolean, page: number, size: number) => {
  const params = {
    planNo: planNo,
    planName: planName,
    departmentId: departmentId !== 'All' ? departmentId : undefined,
    supplyMethodId: supplyMethodId !== 'All' ? supplyMethodId : undefined,
    isResponsible: isResponsible === undefined ? true : isResponsible,
    isJorPor: isJorPor === undefined ? false : isJorPor,
    page: page,
    size: size
  };
  return http.get('dashboard/worklist-pro', {
    params
  });
};

const getWorklistContractAsync = async (planNo: string, planName: string, departmentId: string, supplyMethodId: string, isResponsible: boolean, isJorPor: boolean, page: number, size: number) => {
  const params = {
    planNo: planNo,
    planName: planName,
    departmentId: departmentId !== 'All' ? departmentId : undefined,
    supplyMethodId: supplyMethodId !== 'All' ? supplyMethodId : undefined,
    isResponsible: isResponsible === undefined ? true : isResponsible,
    isJorPor: isJorPor === undefined ? false : isJorPor,
    page: page,
    size: size
  };
  return http.get('dashboard/worklist-contract', {
    params
  });
};


const getWorkerListAsync = async (page: number, size: number, criteria: AcceptorCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('dashboard/worker-list', { params });
};

export default {
  getSummaryAsync,
  getChartAsync,
  getProjectListAsync,
  getSupplyMethodTypeListAsync,
  getWorklistPlanAsync,
  getWorklistPreProAsync,
  getWorklistProAsync,
  getWorklistContractAsync,
  getWorkerListAsync,
};
