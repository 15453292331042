import {
  InputTextArea,
  Modal,
} from 'components';
import { useState } from 'react';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

interface Props {
  show: boolean;
  onRemark?: (remark: string) => void;
  onHide: () => void;
  isApprover?: boolean;
}

export function ApproveModal(props: Props) {
  const [remark, setRemark] = useState('');

  const onSubmit = (remark: string) => {
    props.onRemark!(remark);
    props.onHide!();
    setRemark('');
  };

  return (
    <Modal
      show={props.show}
      size="lg"
      onHide={() => props.onHide()}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>{props.isApprover ? 'อนุมัติ' : 'เห็นชอบ'}</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className="p-0 ps-4">
            <InputTextArea
              label='ความเห็นเพิ่มเติม (ถ้ามี)'
              value={remark}
              onChange={(value) => setRemark(value)}
            />
          </ModalBT.Body>
          <ModalBT.Footer className="justify-content-center">
            <Button
              variant="light"
              onClick={() => props.onHide()}
            >
              ยกเลิก
            </Button>
            <Button
              variant="success text-white"
              onClick={() => onSubmit(remark)}
            >
              <FaCheck className="me-2" /> ยืนยัน
            </Button>
          </ModalBT.Footer>
        </>
      )}
    />
  );
}
