import {
  Breadcrumb,
  Card,
  Input,
  InputTextArea,
  ProcessStatus,
  Status,
  StatusType,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  BsArrowReturnLeft,
  BsChatLeftDots,
} from 'react-icons/bs';
import {
  FaAlignLeft,
  FaCheck,
  FaUndo,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { generateUniqueId } from '../../../../utils/helper';

function GetStatus(i: number) {
  switch (i) {
    case 1:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.PENDING} />;
    case 2:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.APPROVED} />;
    case 3:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.REJECTED} />;
    default:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.DRAFT} />;
  }
}

const BREADCRUMB_INFO = [
  {
    routerName: '/pp/pp0304', label: 'อนุมัติ จพ.004 ตามงานที่มอบหมาย',
  },
  {
    routerName: '/pp/pp0304/detail', label: 'รายละเอียดการแจ้งข้อมูลเบื้องต้น (จพ.004) - มอบหมายงาน',
  },
];

function DetailPP0303() {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalComment, setShowModalComment] = useState(false);
  const navigate = useNavigate();

  function backPage() {
    navigate('/pp/pp0304');
  }

  return (
    <div className="document">
      <h4 className="mt-2 mb-0 text-primary text-start">รายละเอียดการแจ้งข้อมูลเบื้องต้น (จพ.004) - มอบหมายงาน</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Card className="mt-3">
        <Title text="ข้อมูลการแจ้งข้อมูลเบื้องต้น (จพ.004)" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="เลขที่เอกสาร PR" value="เลขที่เอกสาร PR" disabled />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} md={4}>
            <Input label="โครงการ" value="059 / 2565 : โครงการ Digital Procurement ฝ่ายจัดหาและการพัสดุ" disabled />
          </Col>
          <Col sm={12} md={3}>
            <Button variant="link"><TbListSearch className="fs-4" /> ไปยังแผน</Button>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="งบประมาณโครงการ (บาท)" value="1,000,000.00" disabled textEnd />
          </Col>
          <Col sm={12} md={4} xl={3}>
            <Input label="วิธีจัดหา" value="80" disabled />
          </Col>
          <Col sm={12} md={4} xl={3} className="pt-3">
            <Input label="" value="เจาะจง" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="สถานะการอนุมัติตามการมอบหมาย" className="fs-5 text-primary" />
        <Table className="mt-3">
          <thead>
            <tr>
              <th style={{ minWidth: 10 }}>ลำดับ</th>
              <th style={{ minWidth: 50 }}>รหัสพนักงาน</th>
              <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
              <th style={{ minWidth: 35 }}>สถานะ</th>
              <th style={{ minWidth: 50 }}>วันที่อนุมัติ</th>
              <th style={{ minWidth: 50 }}>ความเห็น</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 5 }).map((_, index) => (
              <tr key={generateUniqueId(index)}>
                <td className="text-center">{index}</td>
                <td className="text-center">5900010</td>
                <td className="text-center">สมศรี แดนไทย</td>
                <td className="text-center">รักษาการผู้อำนวยการสำนักงบประมาณ</td>
                <td className="text-center">{GetStatus(index)} </td>
                <td className="text-center">28-11-2566 16:24:22</td>
                <td className="text-center text-primary"><BsChatLeftDots onClick={() => setShowModalComment(!showModalComment)} /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารการแจ้งข้อมูลเบื้องต้น (จพ.004)" className="fs-5 text-primary" />
      </Card>

      <div className="d-flex justify-content-center gap-3 pt-3">
        <Button
          className="me-2 px-3"
          onClick={() => backPage()}
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-4" />
          ย้อนกลับ
        </Button>
        <Button
          variant="danger"
          onClick={() => setShow(true)}
          className="me-2 px-3"
        >
          <FaUndo className="mx-2 pb-1 fs-4" />
          ส่งกลับแก้ไข
        </Button>
        <Button
          onClick={() => setShowModal(true)}
          className="me-2 px-3"
        >
          <FaAlignLeft className="me-2 pb-1 fs-5" />
          เห็นชอบ
        </Button>
      </div>

      <Modal
        show={showModal}
        size="lg"
        onHide={() => setShowModal(!showModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ให้ความเห็นชอบ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              <InputTextArea label="ความเห็น" rule={{ required: true }} />
            </ModalBT.Body>
            <ModalBT.Footer className="justify-content-center">
              <Button variant="light" onClick={() => setShowModal(!showModal)}>
                ยกเลิก
              </Button>
              <Button variant="success text-white">
                <FaCheck className="me-2" /> ให้ความเห็น
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(!show)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              <InputTextArea label="หมายเหตุ" rule={{ required: true }} />
            </ModalBT.Body>
            <ModalBT.Footer className="justify-content-center">
              <Button
                variant="light"
                onClick={() => setShow(!show)}
                className="me-2 px-3"
              >
                ยกเลิก
              </Button>
              <Button
                variant="danger"
                className="me-2 px-3"
              >
                <FaUndo className="mx-2 pb-1 fs-4" />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showModalComment}
        size="lg"
        onHide={() => setShowModalComment(!showModalComment)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ความคิดเห็น</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              <InputTextArea label="ความคิดเห็น" />
            </ModalBT.Body>
            <ModalBT.Footer className="justify-content-center">
              <Button variant="light" onClick={() => setShowModalComment(!showModalComment)}>
                ยกเลิก
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default DetailPP0303;
