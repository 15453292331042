import { PreProcumentFlag } from 'constant/PreProcumentFlag';
import Cookie from 'cookie-universal';
import { CountStatusModel, CountSupplyMethodModel, CreateMedianPriceModel, SearchCriteriaModel, UpdateIsAvailableCommitteeMedianPriceModels, UpdateMedianPriceAcceptorModel, UpdateMedianPriceJorPorAcceptorsModel, UpdateMedianPriceModel } from 'models/PP/MedianPriceModel';
import http from 'utils/axios';

const qs = require('qs');

const api = '/medianPrice';
const cookies = Cookie();
const getFullName = cookies.get('fullName') as string;

const getListMedianPriceBAsync = (searchData: SearchCriteriaModel) => {
  const params = {
    page: searchData.page,
    pageSize: searchData.pageSize,
    planNumber: searchData.planNumber,
    appointNumber: searchData.appointNumber,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    expectingProcurementAt: searchData.expectingProcurementAt,
    financialAmountMinimum: searchData.financialAmountMinimum,
    financialAmountMaximum: searchData.financialAmountMaximum,
    supplyMethods: searchData.supplyMethods,
    medianPriceStatuses: searchData.medianPriceStatuses,
    isAssigneeOnly: searchData.isAssigneeOnly,
    medianPriceNumber: searchData.medianPriceNumber,
  };

  return http.get(`${api}`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountStatusAsync = (searchData: SearchCriteriaModel) => {
  const params = {
    page: searchData.page,
    pageSize: searchData.pageSize,
    planNumber: searchData.planNumber,
    appointNumber: searchData.appointNumber,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    expectingProcurementAt: searchData.expectingProcurementAt,
    financialAmountMinimum: searchData.financialAmountMinimum,
    financialAmountMaximum: searchData.financialAmountMaximum,
    supplyMethods: searchData.supplyMethods,
    medianPriceStatuses: searchData.medianPriceStatuses,
    isAssigneeOnly: searchData.isAssigneeOnly,
    medianPriceNumber: searchData.medianPriceNumber,
  };
  return http.get<CountStatusModel[]>(`${api}/status-count`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountSupplyMethodAsync = (searchData: SearchCriteriaModel) => {
  const params = {
    page: searchData.page,
    pageSize: searchData.pageSize,
    planNumber: searchData.planNumber,
    appointNumber: searchData.appointNumber,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    expectingProcurementAt: searchData.expectingProcurementAt,
    financialAmountMinimum: searchData.financialAmountMinimum,
    financialAmountMaximum: searchData.financialAmountMaximum,
    supplyMethods: searchData.supplyMethods,
    medianPriceStatuses: searchData.medianPriceStatuses,
    isAssigneeOnly: searchData.isAssigneeOnly,
    medianPriceNumber: searchData.medianPriceNumber,
  };
  return http.get<CountSupplyMethodModel[]>(`${api}/supply-method-count`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getMedianPriceByIdAsync = (id: string) => http.get(`${api}/${id}`);

const createMedianPriceModelAsync = (body: CreateMedianPriceModel) => {
  return http.post(`${api}`, body);
};

const updateMedianPriceModelAsync = (id: string, body: UpdateMedianPriceModel) => {
  return http.put(`${api}/${id}`, body);
};

const sendCommitteeMedianPriceModelAsync = (id: string) => {
  return http.put(`${api}/${id}/sendToCommittee`);
};

const committeeApproveMedianPriceModelAsync = (id: string, remark: string) => {
  const body = {
    remark,
  };
  return http.put(`${api}/${id}/committeeApprove`, body);
};

const committeeRejectMedianPriceModelAsync = (id: string, remark: string) => {
  const body = {
    remark,
  };
  return http.put(`${api}/${id}/committeeReject`, body);
};

const updateAcceptorModelAsync = (id: string, body: UpdateMedianPriceAcceptorModel) => {
  return http.put(`${api}/${id}/acceptor`, body);
};

const updateJorPorAcceptorAsync = (id: string, body: UpdateMedianPriceJorPorAcceptorsModel) => {
  return http.put(`${api}/${id}/jorPorAcceptor`, body);
};

const updateIsAvailableCommitteeModelAsync = (id: string, body: UpdateIsAvailableCommitteeMedianPriceModels) => {
  return http.put(`${api}/${id}/updateIsAvailableCommittee`, body);
};

const sendAcceptorToApproveModelAsync = (id: string, documentId: string) => {
  const formData = new FormData();
  formData.append('DocumentId', documentId);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.put(`${api}/${id}/sendToAcceptor`, formData, config);
};

const acceptorApproveModelAsync = (id: string, remark: string) => {
  const body = {
    remark,
  };
  return http.put(`${api}/${id}/acceptorApprove`, body);
};

const acceptorRejectModelAsync = (id: string, remark: string) => {
  const body = {
    remark,
  };
  return http.put(`${api}/${id}/acceptorReject`, body);
};

const jorPorDirectorRejectedAsync = (id: string, remark: string) => {
  const body = {
    remark,
  };
  return http.put(`${api}/${id}/jorpor-director-rejected`, body);
};

const getListHistoryAsync = (page: number, pageSize: number, id: string | undefined) => {
  const params = {
    page,
    pageSize,
    id,
  };

  return http.get(`${api}/history`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const uploadAttachmentAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/${id}/attachment`, formData, config);
};

const getAttachmentAsync = (id?: string, documentId?: string) => http.get(`${api}/${id}/attachment`);

const getDownloadAttachmentAsync = (id?: string, documentId?: string) =>
  http.get(`${api}/${id}/download-file/${documentId}`, {
    responseType: 'blob',
  });

const removeAttachmentAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/${id}/attachment/${attachmentId}`);

const changeMedianPriceModelAsync = (medianPriceRefId: string, remark: string) => {
  const body = {
    remark,
  };
  return http.post(`${api}/${medianPriceRefId}/change`, body);
};

const cancelMedianPriceModelAsync = (medianPriceRefId: string, remark: string) => {
  const body = {
    remark,
  };
  return http.post(`${api}/${medianPriceRefId}/cancel`, body);
};

const deleteMedianPriceAsync = (id: string) => http.delete(`${api}/${id}`);

const createDocumentAsync = (medianPriceId: string, docReadonly: boolean) => {
  const api = process.env.REACT_APP_API_URL;

  return http.post(`${api}/medianPrice/${medianPriceId}/document/${docReadonly}`);
};

const onRecallAsync = (id: string) => http.put(`${api}/${id}/Recall`);

export default {
  getListMedianPriceBAsync,
  getMedianPriceByIdAsync,
  getCountStatusAsync,
  createMedianPriceModelAsync,
  updateMedianPriceModelAsync,
  sendCommitteeMedianPriceModelAsync,
  committeeApproveMedianPriceModelAsync,
  committeeRejectMedianPriceModelAsync,
  updateAcceptorModelAsync,
  sendAcceptorToApproveModelAsync,
  acceptorApproveModelAsync,
  acceptorRejectModelAsync,
  getListHistoryAsync,
  uploadAttachmentAsync,
  getAttachmentAsync,
  getDownloadAttachmentAsync,
  removeAttachmentAsync,
  changeMedianPriceModelAsync,
  cancelMedianPriceModelAsync,
  getCountSupplyMethodAsync,
  deleteMedianPriceAsync,
  updateIsAvailableCommitteeModelAsync,
  updateJorPorAcceptorAsync,
  createDocumentAsync,
  jorPorDirectorRejectedAsync,
  onRecallAsync,
}