import {
  Check,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  AnnouncingWinnerListResponse,
  AnnouncingWinnerParamsSearch,
  ItemModel,
} from 'models';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { announcingWinner } from 'services';
import {
  GetMonthYearBC,
  HttpStatusCode,
  THCurrency,
} from 'utils';
import { generateUniqueId } from '../../../utils/helper';

type Loader = {
  departmentDDL: ItemModel[];
  announcingWinnerLists: AnnouncingWinnerListResponse[];
  announcingWinnerListsTotal: number;
  supplyMethodDDL: ItemModel[];
}

interface StatusModels {
  label: string;
  value: string;
  count: number;
}

interface Context {
  page: number;
  size: number;
  totalRecords: number;
  criteria: AnnouncingWinnerParamsSearch;
  announcinWinnerLists: AnnouncingWinnerListResponse[];
  onSearch: Function;
  onClearCriteria: Function;
  setPage: Dispatch<SetStateAction<number>>;
  setSize: Dispatch<SetStateAction<number>>;
  setTotalRecords: Dispatch<SetStateAction<number>>;
  setCriteria: Dispatch<SetStateAction<AnnouncingWinnerParamsSearch>>;
}

const Context = createContext({} as Context);

export default function AnnouncingWinner() {
  const { announcingWinnerLists, announcingWinnerListsTotal } = useLoaderData() as Loader;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [criteria, setCriteria] = useState<AnnouncingWinnerParamsSearch>({} as AnnouncingWinnerParamsSearch);
  const [announcinWinnerLists, setAnnouncingWinnerLists] = useState<AnnouncingWinnerListResponse[]>([]);
  const [onInit, setOnInit] = useState(true);

  useEffect(() => {
    if (announcingWinnerLists && announcingWinnerListsTotal) {
      setAnnouncingWinnerLists(announcingWinnerLists);
      setTotalRecords(announcingWinnerListsTotal);
      setOnInit(false);
    }
  }, [announcingWinnerLists, announcingWinnerListsTotal]);

  useEffect(() => {
    if (!onInit) {
      getAnnoucingWinnerAsync(criteria);
    }
  }, [page, size]);

  const onSearch = () => {
    getAnnoucingWinnerAsync(criteria);
  };

  const onClearCriteria = (data: AnnouncingWinnerParamsSearch) => {
    setCriteria(data);
    getAnnoucingWinnerAsync(data);
  };

  const contextValue: Context = {
    page,
    size,
    totalRecords,
    criteria,
    announcinWinnerLists,
    onSearch,
    onClearCriteria,
    setPage,
    setSize,
    setTotalRecords,
    setCriteria,
  };

  const getAnnoucingWinnerAsync = async (criteriaSearch: AnnouncingWinnerParamsSearch) => {
    const { data, status } = await announcingWinner.getAnnoucingWinnerListAsync(page, size, criteriaSearch);

    if (status === HttpStatusCode.OK) {
      setAnnouncingWinnerLists(data.data);
    }
  };

  return (
    <Context.Provider value={contextValue}>
      <AnnouncingWinnerHeader />
      <Criteria />
      <DataTable />
    </Context.Provider>
  );
}

function AnnouncingWinnerHeader() {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">ประกาศผู้ชนะเสนอราคา</h4>
      </div>
      <hr />
    </>
  );
}

function Criteria() {
  const { departmentDDL, supplyMethodDDL } = useLoaderData() as Loader;
  const { criteria, setCriteria, onSearch, onClearCriteria } = useContext(Context);
  const [supplyMethod, setSupplyMethod] = useState<StatusModels[]>([
    { label: 'ทั้งหมด', value: 'All', count: 0 },
    { label: 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560', value: '342e4e0b-7682-48c0-b46a-97a066908c9a', count: 0 },
    { label: 'ข้อบังคับธนาคาร 80', value: 'a21603e4-338d-486c-85ba-5f1cac8f6ace', count: 0 },
  ]);
  const [statusNameArray, setStatusNameArray] = useState<StatusModels[]>(
    [
      { label: 'ทั้งหมด', value: 'All', count: 0 },
      { label: 'ยังไม่ได้ดำเนินการ', value: '', count: 0 },
      { label: 'แบบร่าง', value: 'Draft', count: 0 },
      { label: 'ส่งผอ.ฝ่ายเห็นชอบ', value: 'WaitingAccept', count: 0 },
      { label: 'เห็นชอบ/อนุมัติ', value: 'Accepted', count: 0 },
      { label: 'ส่งกลับแก้ไข', value: 'Reject', count: 0 },
    ]
  );

  useEffect(() => {
    setCriteria({
      supplyMethod: supplyMethod.map(i => i.value),
      status: statusNameArray.map(i => i.value)
    });
  }, []);

  const handleOnChangeCriteria = (value: string | number | boolean, prop: string) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const supplyMethodOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const supplyMethod = [...supplyMethodDDL.map((s) => s.value)];
        setCriteria({ ...criteria, supplyMethod });
        return;
      }
      setCriteria({ ...criteria, supplyMethod: [] });
      return;
    }
    if (result) {
      if ((criteria.supplyMethod.length + 1) === (supplyMethodDDL.length - 1)) {
        setCriteria({
          ...criteria,
          supplyMethod: [
            ...criteria.supplyMethod,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        supplyMethod: [
          ...criteria.supplyMethod,
          value,
        ],
      });
    } else {
      const supplyMethod = [...criteria.supplyMethod];
      const index = supplyMethod.findIndex((ps) => ps === value);

      if (index >= 0) {
        supplyMethod.splice(index, 1);
        const allIndex = supplyMethod.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          supplyMethod.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, supplyMethod });
      }
    }
  };

  const statusOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const status = [...statusNameArray.map((s) => s.value)];
        setCriteria({ ...criteria, status });
        return;
      }
      setCriteria({ ...criteria, status: [] });
      return;
    }
    if (result) {
      if ((criteria.status.length + 1) === (statusNameArray.length - 1)) {
        setCriteria({
          ...criteria,
          status: [
            ...criteria.status,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        status: [
          ...criteria.status,
          value,
        ],
      });
    } else {
      const status = [...criteria.status];
      const index = status.findIndex((ps) => ps === value);

      if (index >= 0) {
        status.splice(index, 1);
        const allIndex = status.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          status.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, status });
      }
    }
  };

  return (
    <>
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          {/* <Input
            label="เลขที่เอกสารจัดทำหนังสือเชิญชวนผู้ประกอบการ"
            placeholder="เลขที่เอกสารจัดทำหนังสือเชิญชวนผู้ประกอบการ" /> */}
          <Input
            label="เลขที่เอกสาร( จพ.006 )"
            placeholder="เลขที่เอกสาร( จพ.006 )"
            value={criteria.jorPor06Number}
            onChange={(value) => handleOnChangeCriteria(value, 'jorPor06Number')} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          {/* <Input
            label="เลขที่เอกสารร่างขอบเขตงของงาน(TOR)"
            placeholder="เลขที่เอกสารร่างขอบเขตงของงาน(TOR)" /> */}
          <Input
            label="เลขที่เอกสารจัดทำหนังสือเชิญชวนผู้ประกอบการ"
            placeholder="เลขที่เอกสารจัดทำหนังสือเชิญชวนผู้ประกอบการ"
            value={criteria.titleDocument}
            onChange={(value) => handleOnChangeCriteria(value, 'titleDocument')} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector label="ฝ่าย/สำนัก"
            placeholder="ฝ่าย/สำนัก"
            items={departmentDDL}
            value={criteria.departmentId}
            onChange={(value) => handleOnChangeCriteria(value, 'departmentId')} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="ชื่อโครงการ"
            placeholder="ชื่อโครงการ"
            value={criteria.planName}
            onChange={(value) => handleOnChangeCriteria(value, 'planName')} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="ชื่อบริษัท"
            placeholder="ชื่อบริษัท" />
        </Col>
      </Row>
      <Row>
        <Col className="Type">
          วิธีจัดหา
          <div className="d-flex gap-2">
            {supplyMethod?.map((v, i) => (
              <Check
                key={generateUniqueId(i)}
                value={criteria.supplyMethod?.some((i) => i === v.value)}
                onChange={(val) => supplyMethodOnChange(v.value, val)}
                label={<>{v.label} <Status type={StatusType.PROCESS} value={v.label} couting={v.count} badge /></>}
              />
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="Status">
          สถานะ
          <div className="d-flex gap-2">
            {statusNameArray?.map((v, i) => (
              <div key={generateUniqueId(i)}>
                <Check
                  value={criteria.status?.some(i => i === v.value)}
                  onChange={val => statusOnChange(v.value, val)}
                  label={<>{v.label} <Status type={StatusType.PROCESS} value={v.value} couting={v.count} badge /></>} />
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <div className="d-flex gap-2">
        <Button
          type="submit"
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={() => onSearch()}>
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant="outline-primary"
          className="d-flex align-items-center gap-2"
          onClick={() => onClearCriteria({
            supplyMethod: supplyMethod.map(i => i.value),
            status: statusNameArray.map(i => i.value)
          })}>
          <FaEraser />ล้าง
        </Button>
      </div>
    </>
  );
}

function DataTable() {
  const { announcinWinnerLists, totalRecords, setPage, setSize } = useContext(Context);

  const navigate = useNavigate();

  const goToPage = (id: string) => {
    navigate(`/procurement-announcing-winners/detail/${id}`);
  };

  return (
    <Table total={totalRecords} className="mt-4" onChange={(size, page) => (setPage(page), setSize(size))}>
      <thead>
        <tr className="text-center">
          <th>เลขที่เอกสารรายงานผลการพิจารณาและ<br />
            ขออนุมัติสั่งซื้อ/สั่งจ้าง ( จพ.006 )</th>
          <th>เลขที่เอกสารจัดทำ<br />
            หนังสือเชิญชวนผู้ประกอบการ</th>
          <th>สถานะ</th>
          <th className='text-start'>ฝ่าย/สำนัก</th>
          <th className='text-start'>ชื่อโครงการ</th>
          <th className='text-end'>งบประมาณ (บาท)</th>
          <th>วิธีจัดหา</th>
          <th>ประมาณการช่วงเวลา<br />
            การจัดซื้อจัดจ้าง</th>
        </tr>
      </thead>
      <tbody>
        {announcinWinnerLists.map((data, index) => (
          <tr className="text-center" key={index}>
            <td>
              <Button variant="link" onClick={() => goToPage(data.id)}>
                {data.jorPor06Number}
              </Button>
            </td>
            <td>{data.titleDocument}</td>
            <td><Status type={StatusType.JOR_POR_04_05_APPROVER} value={data.status} /></td>
            <td className='text-start'>{data.departmentName}</td>
            <td className='text-start'>{data.planName}</td>
            <td className='text-end'>{THCurrency(data.planBudget)}</td>
            <td>{data.supplyMethod}</td>
            <td>{GetMonthYearBC(data.expectingProcurementAt)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
