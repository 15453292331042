interface Props {
  IstatusProject: 'inprogress-plan' | 'change' | 'cancel-plan' | 'inprogress' | 'cancel';
}

function generateStatusProject(IstatusProject: string): JSX.Element | undefined {
  switch (IstatusProject) {
    case 'inprogress-plan':
      return <p className="mb-0 text-secondary">จัดทำแผน</p>;
    case 'change':
      return <p className="mb-0 text-warning">เปลี่ยนแปลงแผน</p>;
    case 'cancel-plan':
      return <p className="mb-0 text-danger"> ยกเลิกแผน</p>;
    case 'inprogress':
      return <p className="mb-0 text-secondary">จัดทำคำสั่งแต่งตั้ง</p>;
    case 'cancel':
      return <p className="mb-0 text-danger"> ยกเลิกคำสั่งแต่งตั้ง</p>;
    default:
    // code block
  }
}

export function StatusProject({ IstatusProject }: Props) {
  return (
    <>
      {generateStatusProject(IstatusProject)}
    </>
  );
}