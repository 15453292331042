import {
  Breadcrumb,
  Card,
  DatePicker,
  Input,
  InputTextArea,
  Modal,
  Selector,
  Status,
  StatusType,
  Table,
  UploadFile,
} from 'components';
import {
  ChangePlanRequestDetailResponse,
  DepartmentListResponse,
  GetPlanResponse,
  UserResponse,
} from 'models';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaCommentAlt,
  FaLongArrowAltLeft,
  FaUndo,
} from 'react-icons/fa';
import { FiCornerDownLeft } from 'react-icons/fi';
import {
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { approvePlan } from 'services';
import {
  HttpStatusCode,
  PlanStatusText,
  thaiFormatDate,
} from 'utils';
import { PlanStatusText as PlanStatusEnum } from 'utils/constants/PlanEnum';
import toast from 'utils/toast';

const BREADCRUMB_INFO = [
  { routerName: '/pl/pl0301', label: 'อนุมัติแผน (ผอ.ฝ่าย)' },
  { routerName: '', label: 'รายละเอียดอนุมัติแผน (ผอ.ฝ่าย)' },
];

type Loader = {
  changePlanData: ChangePlanRequestDetailResponse,
  planData: GetPlanResponse,
  department: DepartmentListResponse[],
  supplyMethod: { label: string, value: string }[],
  supplyMethodType: { label: string, value: string }[],
};

interface RejectedModalProps {
  changePlanId?: string;
  show: boolean;
  onHide: () => void;
  disabled?: boolean;
  remark?: string;
}

interface Detail {
  id: string;
  changePlanId: string;
  approver: UserResponse[];
  planNumber: string;
  departmentId?: string;
  planName: string;
  planBudget: number;
  changePlanName?: string;
  changePlanBudget?: string;
  changePlanExpectingProcurement?: string;
  supplyMethod: string;
  supplyMethodName?: string;
  expectingProcurementAt: string;
  planEgpNumber?: string;
  status: string;
  createdByUserFullName: string;
  createdInfoAt: Date;
}

interface ApproverProps {
  approver: UserResponse[];
}

interface PlanProps {
  changePlanId?: string;
  data: Detail;
}

interface ApproveModal {
  show: boolean;
  onHide: () => void;
  changePlanId?: string;
}

export default function PL0301Detail() {
  const { planStatus } = useParams();
  const loader = useLoaderData() as Loader;
  const [detail, setDetail] = useState<Detail>({} as Detail);

  useEffect(() => {
    if (planStatus === PlanStatusText.ChangePlan) {
      return setChangePlanDataToInitailData();
    }

    return setPlanDataToInitailData();
  }, []);

  const setChangePlanDataToInitailData = () => {
    const changePlanData = loader.changePlanData.changePlanRequest;

    setDetail({
      ...detail,
      id: changePlanData.annualPlan.id,
      changePlanId: changePlanData.id,
      approver: changePlanData.approvers,
      planNumber: changePlanData.annualPlan.planNumber,
      departmentId: changePlanData.annualPlan.departmentId,
      planName: changePlanData.annualPlan.planName,
      planBudget: changePlanData.annualPlan.planBudget,
      changePlanName: changePlanData.planName,
      changePlanBudget: changePlanData.planBudget,
      changePlanExpectingProcurement: changePlanData.expectingProcurementAt,
      expectingProcurementAt: changePlanData.annualPlan.expectingProcurementAt,
      status: changePlanData.annualPlan.status,
      supplyMethod: changePlanData.annualPlan.supplyMethod,
      supplyMethodName: changePlanData.annualPlan.supplyMethodName,
      planEgpNumber: changePlanData.annualPlan.planEgpNumber,
      createdByUserFullName: changePlanData.createUserFullName,
      createdInfoAt: new Date(changePlanData.createAt),
    });
  };

  const setPlanDataToInitailData = () => {
    const planData = loader.planData.plan;

    setDetail({
      ...detail,
      id: planData.id,
      approver: planData.approvers,
      planNumber: planData.planNumber,
      departmentId: planData.departmentId,
      planName: planData.planName,
      planBudget: planData.planBudget,
      expectingProcurementAt: planData.expectingProcurementAt,
      status: planData.status,
      supplyMethod: planData.supplyMethod,
      supplyMethodName: planData.supplyMethodName,
      planEgpNumber: planData.planEgpNumber,
      createdByUserFullName: planData.createdByUserFullName,
      createdInfoAt: new Date(planData.createdInfoAt),
    });
  };

  return (
    <div className='pl01Detail'>
      <h4 className='mt-2 mb-0 text-primary text-start'>รายละเอียดอนุมัติแผน (ผอ.ฝ่าย)</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <div className='data-table mt-3'>
        <Card>
          <div className='d-flex align-items-center gap-3 mb-3'>
            <div className='parallelogram' />
            <h4 className='text-primary'>สถานะการอนุมัติ</h4>
          </div>
          <Approver approver={detail.approver} />
        </Card>
      </div>
      <div className='plan-detail mt-4'>
        <PlanDetail data={detail}
          changePlanId={detail.changePlanId ?? ''} />
      </div>
    </div>
  );
}

function Approver(props: ApproverProps) {
  const [showModal, setShowModal] = useState(false);
  const [remark, setRemark] = useState<string>();

  const showModalOnClick = (remark: string) => {
    setRemark(remark);
    setShowModal(true);
  };

  return (
    <>
      <Table hidePagination>
        <thead>
          <tr>
            <th style={{ minWidth: 50 }}>ลำดับ</th>
            <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
            <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
            <th style={{ minWidth: 100 }}>สถานะ</th>
            <th style={{ minWidth: 100 }}>วันที่อนุมัติ</th>
            <th style={{ minWidth: 50 }}>ความเห็น</th>
          </tr>
        </thead>
        <tbody>
          {props.approver && props.approver.map((data, index) => (
            <tr key={data.id}>
              <td className='text-center'>{index + 1}</td>
              <td className='text-center'>{data.name}</td>
              <td className='text-center'>{data.position}</td>
              <td className='text-center'><Status type={StatusType.PROCESS}
                value={data.approveStatus!} /></td>
              <td className='text-center'>{thaiFormatDate(data.updatedAt)}</td>
              <td>
                {data.approveStatus === PlanStatusEnum.RejectPlan
                  ? (
                    <div
                      className='d-flex justify-content-center'
                      onClick={() => showModalOnClick(data.remark)}
                    >
                      <FaCommentAlt className='text-primary cursor-pointer' />
                    </div>
                  ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <RejectedModal
        disabled
        show={showModal}
        onHide={() => setShowModal(false)}
        remark={remark}
      />
    </>
  );
}

interface ItemsModel {
  label: string;
  value: string;
}

function PlanDetail(props: PlanProps) {
  const [showModal, setShowModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [items, setItems] = useState<ItemsModel[]>([]);
  const loader = useLoaderData() as Loader;
  const { planStatus } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setItemsList();
  }, []);

  const setItemsList = () => {
    const items = loader.department.map((data) => ({ label: data.name, value: data.id }));
    setItems(items);
  };

  return (
    <Form>
      {planStatus && planStatus === 'ChangePlan' ? (
        <Card className='mb-4'>
          <div className='new-plan mt-3'>
            <div className='d-flex align-items-center gap-3 mb-3'>
              <div className='parallelogram' />
              <h4 className='text-primary'>แผนการจัดซื้อจัดจ้างใหม่</h4>
            </div>
            <Row className='mt-3'>
              <Col lg={6}>
                <InputTextArea
                  disabled
                  rule={{ required: true }}
                  label='ชื่อโครงการ'
                  value={props.data.changePlanName}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <Input
                  disabled
                  label='งบประมาณโครงการ (บาท)'
                  rule={{ required: true }}
                  value={props.data.changePlanBudget}
                />
              </Col>
              <Col lg={3}>
                <DatePicker
                  disabled
                  monthYearOnly
                  label='ประมาณการช่วงเวลาการจัดซิ้อจัดจ้าง'
                  value={new Date(props.data.changePlanExpectingProcurement!)}
                />
              </Col>
            </Row>
          </div>
        </Card>
      )
        : null}
      <Card>
        <div className='d-flex align-items-center gap-3 mb-3'>
          <div className='parallelogram' />
          <h4 className='text-primary'>ข้อมูลแผน</h4>
          <Status type={StatusType.PROJECT}
            value={planStatus!}
            badge />
          <Status type={StatusType.PROCESS}
            value={props.data.status}
            badge />
        </div>
        <Row>
          <Col md={4}
            lg={3}>
            <Input
              disabled
              label='เลขที่แนบ'
              value={props.data.planNumber}
            />
          </Col>
          <Col md={4}
            lg={3}>
            <Selector
              disabled
              label='ฝ่าย/สำนัก'
              items={items}
              value={props.data.departmentId}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}
            lg={3}>
            <Selector
              label='วิธีการจัดหา'
              items={loader.supplyMethodType}
              value={props.data.supplyMethod}
              disabled
            />
          </Col>
          <Col md={4}
            lg={3}>
            <Selector
              label='&nbsp;'
              items={loader.supplyMethod}
              value={props.data.supplyMethodName}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}
            lg={6}>
            <InputTextArea
              disabled
              label='ชื่อโครงการ'
              value={props.data.planName}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}
            lg={3}>
            <Input
              disabled
              label='งบประมาณโครงการ (บาท)'
              value={props.data.planBudget}
            />
          </Col>
          <Col md={4}
            lg={3}>
            <DatePicker
              monthYearOnly
              disabled
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              value={new Date(props.data.expectingProcurementAt)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}
            lg={3}>
            <Input
              disabled
              label='เลขที่แผน e-GP'
              value={props.data.planEgpNumber}
            />
          </Col>
        </Row>
        <Row>
          <label>เอกสารแนบ</label>
          <UploadFile disabled />
        </Row>
        <Row>
          <Col md={4}
            lg={3}>
            <Input
              disabled
              label='สร้างโดย'
              value={props.data.createdByUserFullName}
            />
          </Col>
          <Col md={4}
            lg={3}>
            <DatePicker
              disabled
              label='วันที่สร้าง'
              value={props.data.createdInfoAt}
            />
          </Col>
        </Row>
      </Card>
      <div className='d-flex gap-3 justify-content-center mt-3'>
        <Button variant='outline-secondary'
          onClick={() => navigate(-1)}
          className='d-flex align-items-center'>
          <FaLongArrowAltLeft className='me-2' />ย้อนกลับ
        </Button>
        {props.data.status !== PlanStatusEnum.ApprovePlan && props.data.status !== PlanStatusEnum.RejectPlan
          ? (
            <>
              <Button variant='danger'
                onClick={() => setShowModal(true)}
                className='d-flex align-items-center'>
                <FaUndo className='me-2' />ส่งกลับแก้ไข
              </Button>
              <Button variant='primary'
                onClick={() => setApproveModal(true)}
                className='d-flex align-items-center'>
                <FaAlignLeft className='me-2' />อนุมัติ
              </Button>
            </>
          )
          : <></>}
      </div>
      <RejectedModal show={showModal}
        onHide={() => setShowModal(false)}
        changePlanId={props.changePlanId} />
      <ApproveModal show={approveModal}
        onHide={() => setApproveModal(false)}
        changePlanId={props.changePlanId} />
    </Form>
  );
}

function RejectedModal(props: RejectedModalProps) {
  const { id, planStatus } = useParams();
  const [remark, setRemark] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (props.remark) {
      setRemark(props.remark);
    }
  }, [props.remark]);

  const updateStatusToRejectedAsync = async () => {
    const response = await approvePlan.updateApproveRejectStatus(id!, planStatus!, remark, props.changePlanId);

    if (response.data === 'Approver Not Found') {
      return toast.error('ไม่สามารถส่งแก่ไขได้เนื่องจากไม่มีสิทธิ์');
    }

    if (response.status === HttpStatusCode.NO_CONTENT) {
      toast.success('ส่งกลับแก้ไขสำเร็จ');

      props.onHide();

      navigate(-1);
    }
  };

  return (
    <Modal show={props.show}
      size='lg'
      onHide={props.onHide}>
      <Modal.Header>
        <div className='d-flex align-items-center gap-3'>
          <div className='parallelogram' />
          <h5 className='mt-2'>ส่งกลับแก้ไข</h5>
        </div>
      </Modal.Header>
      <div>
        <Form>
          <InputTextArea
            label='สาเหตุ'
            value={remark}
            onChange={(value) => setRemark(value)}
            disabled={props.disabled}
          />
          {!props.disabled
            ? (
              <div className='d-flex justify-content-end gap-3'>
                <Button
                  variant='outline-secondary'
                  onClick={() => props.onHide()}
                >
                  ยกเลิก
                </Button>
                <Button onClick={updateStatusToRejectedAsync}>ยืนยัน</Button>
              </div>
            ) : null}
        </Form>
      </div>
    </Modal>
  );
}

function ApproveModal(props: ApproveModal) {
  const { id, planStatus } = useParams();
  const navigate = useNavigate();

  const updateStatusToRejectedAsync = async () => {
    const response = await approvePlan.updateApproveStatus(id!, planStatus!, props.changePlanId);

    if (response.data === 'Approver Not Found') {
      return toast.error('ไม่สามารถอนุมัติได้เนื่องจากไม่มีสิทธิ์');
    }

    if (response.status === HttpStatusCode.NO_CONTENT) {
      toast.success('อนุมัติสำเร็จ');

      props.onHide();

      navigate(-1);
    }
  };

  return (
    <Modal
      size='lg'
      show={props.show}
      onHide={props.onHide}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>อนุมัติแผนการจัดซื้อจัดจ้าง</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4'>คุณต้องการอนุมัติแผนการจัดซื้อจัดจ้างใช่หรือไม่ ?</ModalBT.Body>
          <ModalBT.Footer>
            <Button variant='light'
              onClick={props.onHide}>
              ยกเลิก
            </Button>
            <Button
              variant='primary'
              onClick={updateStatusToRejectedAsync}
            >
              ยืนยัน
            </Button>
          </ModalBT.Footer>
        </>
      )}
    />
  );
}
