import {
  ContractReceiveStatus,
  Modal,
  StatusCM,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { StatusCMDelivery } from 'components/StatusCMDelivery';
import { StatusCMPaidPhase } from 'components/StatusCMPaidPhase';
import { StatusCMReceived } from 'components/StatusCMReceived';
import { useState } from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import { fullDatetime } from 'utils';
import { generateUniqueId } from '../../../../utils/helper';

interface Props {
  show: boolean;
  onHide?: () => void;
  title?: string;
  id?: string;
  activities?: any[];
  status?: string;
}

export function HistoryModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size='xl'
    >
      <Modal.Header>
        <Title
          text={props.title ? `ประวัติ ${props.title}` : 'ประวัติการดำเนินการ'}
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <ModalBT.Body className='p-0 ps-4'>
        <Table className='my-3'
          hidePagination
        >
          <thead>
            <tr>
              <th>วันที่/เวลา</th>
              <th>ผู้ดำเนินการ</th>
              <th>การดำเนินการ</th>
              <th>สถานะการดำเนินการ</th>
              <th>หมายเหตุ</th>

            </tr>
          </thead>
          <tbody>
            {
              (props && props.activities) && (
                props.activities?.map((data, index) => (
                  <tr key={generateUniqueId(index)}>
                    <td className='text-center'>{fullDatetime(data.createdDate)}</td>
                    <td>{data.createdBy}</td>
                    <td>{data.description}</td>
                    <td className='text-start'>
                      {
                        (props.status === ContractReceiveStatus.Received) && (
                          <>
                            <StatusCMReceived systemStatus={data.action}
                              systemName='cm-03' />
                          </>
                        )
                      }
                      {
                        (props.status === ContractReceiveStatus.Delivery) && (
                          <>
                            <StatusCMDelivery systemStatue={data.action}
                              systemName='cm-03' />
                          </>
                        )
                      }
                      {
                        (props.status === ContractReceiveStatus.PaidPhase) && (
                          <>
                            <StatusCMPaidPhase systemStatue={data.action}
                              systemName='cm-03' />
                          </>
                        )
                      }
                      {
                        (!props.status) && (
                          <>
                            <StatusCM systemStatue={data.action}
                              systemName='cm-03' />
                          </>
                        )
                      }
                    </td>
                    <td className='text-start text-wrap'
                      style={{
                        minWidth: 400,
                        width: 400,
                      }}>{data.remark}</td>
                  </tr>
                ))
              )
            }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
