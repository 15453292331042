import {
  DepartmentResponse,
  UserResponse,
} from 'models';
import { ListModel } from 'models/PP';
import { LoaderFunctionArgs } from 'react-router-dom';
import {
  account,
  department,
  shareValue,
} from 'services';
import PP0105Service from 'services/PP/PP0105Service';

interface ItemModel {
  label: string;
  value: string;
}

const PP0105Loader = async () => {
  const res = await PP0105Service.GetMatrixListAsync(1, 10);
  const tableData: ListModel = res.data;
  return {
    tableData,
  };
};

const PP0105LoaderDetail = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const departmentItems: ItemModel[] = [];
  const supplyMethodTypeList: ItemModel[] = [];
  const supplyMethodTypeNameList: ItemModel[] = [];
  const positionOnBoardList: ItemModel[] = [];
  const employeeList: UserResponse[] = [];
  await getDepartmentList(departmentItems);
  await getSupplyMethodType(supplyMethodTypeList);
  await getSupplyMethodName(supplyMethodTypeNameList);
  await getEmployeeAsync(employeeList);
  await getPositionOnBoardAsync(positionOnBoardList);

  if (id !== undefined) {
    const res = await PP0105Service.getMatrixDetail(id);
    const { data } = res;

    return {
      departmentList: departmentItems,
      supplyMethodTypeList,
      supplyMethodTypeNameList,
      employeeList,
      positionOnBoardList,
      matrixDetail: data.matrixDetail,
    };
  }

  return {
    departmentList: departmentItems,
    supplyMethodTypeList,
    supplyMethodTypeNameList,
    employeeList,
    positionOnBoardList,
  };
};

const getDepartmentList = async (itemForMap: ItemModel[]) => {
  const departmentsRes = await department.getDepartmentsAsync();
  const departmentData: DepartmentResponse[] = departmentsRes.data;
  departmentData.forEach((data) => itemForMap.push({ label: data.name, value: data.id ? data.id : '' }));
};

const getSupplyMethodType = async (itemForMap: ItemModel[]) => {
  const supplyMethodTypeListRes = await shareValue.getSupplyMethodTypeListAsync();
  const supplyMethodTypeData: ItemModel[] = supplyMethodTypeListRes.data;
  supplyMethodTypeData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
};

const getSupplyMethodName = async (itemForMap: ItemModel[]) => {
  const supplyMethodListRes = await shareValue.getSupplyMethodListAsync();
  const supplyMethodListData: ItemModel[] = supplyMethodListRes.data;
  supplyMethodListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
};

const getEmployeeAsync = async (resp: UserResponse[]) => {
  const { data } = await account.getUsersAsync(10, 1);
  const responseData: UserResponse[] = data.data;
  responseData.forEach((data) => resp.push(data));
};

const getPositionOnBoardAsync = async (itemForMap: ItemModel[]) => {
  const response = await shareValue.getPositionOnBoardListAsync();
  const itemData: ItemModel[] = response.data;
  itemData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
};

export {
  PP0105Loader,
  PP0105LoaderDetail,
};
