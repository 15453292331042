import {
  Check,
  Input,
  Modal,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  ItemModel,
  ItemModelBadge,
} from 'models';
import {
  JorPor06Count,
  JorPor06Criteria,
  JorPor06ListsResponse,
} from 'models/PR/JorPor06Models';
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import {
  FaCheck,
  FaEraser,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { jorPor06 } from 'services';
import {
  GetMonthYearBC,
  HttpStatusCode,
  THCurrency,
} from 'utils';
import { generateUniqueId } from '../../../utils/helper';
import { TorEditCancelStatusConstant } from 'constant/PreProcumentTorStatus';
import { AnnouncementStatus } from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { idText } from 'typescript';

interface CountStatus {
  allSupplyMethod: number,
  supplyMethod60: number,
  supplyMethod80: number,
  all: number,
  notYet: number,
  draft: number,
  waitingAcceptor: number,
  accepted: number,
  reject: number,
  waitingComitteeApprove: number,
  waitingForDirectorJorPorAssign: number,
  draftAcceptor: number,
  cancelled: number,
}

type Loader = {
  departmentDDL: ItemModel[];
  supplyMethodDDL: ItemModel[];
  countStatusData: CountStatus;
}

type JorPor06ListContext = {
  criteria: JorPor06Criteria;
  jorpor06Lists: JorPor06ListsResponse[];
  page: number;
  size: number;
  totalRecords: number;
  onSearchCriteria: Function;
  onClearCriteria: Function;
  setCriteria: Dispatch<React.SetStateAction<JorPor06Criteria>>;
  setJorpor06Lists: Dispatch<React.SetStateAction<JorPor06ListsResponse[]>>;
  setPage: Dispatch<React.SetStateAction<number>>;
  setSize: Dispatch<React.SetStateAction<number>>;
  setTotalRecords: Dispatch<React.SetStateAction<number>>;
}

const Context = createContext({} as JorPor06ListContext);

export default function Jorpor006() {
  const { supplyMethodDDL, countStatusData } = useLoaderData() as Loader;
  const [supplyMethodArray, setSupplyMethodArray] = useState<ItemModelBadge[]>([
    { label: 'ทั้งหมด', value: 'All', count: countStatusData.allSupplyMethod },
    { label: 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560', value: '342e4e0b-7682-48c0-b46a-97a066908c9a', count: countStatusData.supplyMethod60 },
    { label: 'ข้อบังคับธนาคาร 80', value: 'a21603e4-338d-486c-85ba-5f1cac8f6ace', count: countStatusData.supplyMethod80 },
  ]);
  const [statusNameArray, setStatusNameArray] = useState<ItemModelBadge[]>([
    { label: 'ทั้งหมด', value: 'All', count: countStatusData.all },
    { label: 'ยังไม่ดำเนินการ', value: 'NotYet', count: countStatusData.notYet },
    { label: 'แบบร่าง', value: 'Draft', count: countStatusData.draft },
    { label: 'อยู่ระหว่าง คกก. เห็นชอบ', value: 'WaitingComitteeApprove', count: countStatusData.waitingComitteeApprove },
    { label: 'อยู่ระหว่างรอ ผอ.จพ. มอบหมาย', value: 'WaitingForDirectorJorPorAssign', count: countStatusData.waitingForDirectorJorPorAssign },
    { label: 'อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ', value: 'DraftAcceptor', count: countStatusData.draftAcceptor },
    { label: 'รออนุมัติ', value: 'WaitingAccept', count: countStatusData.waitingAcceptor },
    { label: 'เห็นชอบ/อนุมัติ', value: 'Accepted', count: countStatusData.accepted },
    { label: 'ส่งกลับแก้ไข', value: 'Reject', count: countStatusData.reject },
    { label: 'ยกเลิก', value: 'Cancelled', count: countStatusData.cancelled },
  ]);
  const [criteria, setCriteria] = useState<JorPor06Criteria>({
    isPermission: true,
    supplyMethod: supplyMethodDDL.map(v => v.value),
    status: statusNameArray.map(v => v.value)
  } as JorPor06Criteria);
  const [jorpor06Lists, setJorpor06Lists] = useState<JorPor06ListsResponse[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0)
  const [onInit, setOnInit] = useState(true);

  useEffect(() => {
    getJorpor06ListsAsync(criteria);
  }, [page, size]);

  const onSearchCriteria = () => {
    getJorpor06ListsAsync(criteria);
    getConstStatusAsync(criteria);
  };

  const onClearCriteria = () => {
    const criteriaData = {
      isPermission: true,
      supplyMethod: supplyMethodDDL.map(v => v.value),
      status: statusNameArray.map(v => v.value),
    } as JorPor06Criteria;

    setCriteria(criteriaData);
    getJorpor06ListsAsync(criteriaData);
    getConstStatusAsync(criteriaData);
  };

  const conTextValue = {
    criteria,
    jorpor06Lists,
    page,
    size,
    totalRecords,
    onSearchCriteria,
    onClearCriteria,
    setCriteria,
    setJorpor06Lists,
    setPage,
    setSize,
    setTotalRecords,
  };

  const getJorpor06ListsAsync = async (criteriaSearch: JorPor06Criteria) => {
    const { data, status } = await jorPor06.getJorpor06ListsAsync(page, size, criteriaSearch);

    if (status === HttpStatusCode.OK) {
      setJorpor06Lists(data.data);
      setTotalRecords(data.totalRecords);
      setOnInit(false);
    }
  };

  const getConstStatusAsync = async (criteriaSearch: JorPor06Criteria) => {
    const criteriaData: JorPor06Count = {
      jorPor05Number: criteriaSearch.jorPor05Number,
      jorPor06Number: criteriaSearch.jorPor06Number,
      torNumber: criteriaSearch.torNumber,
      departmentId: criteriaSearch.departmentId,
      planName: criteriaSearch.planName,
      planNumber: criteriaSearch.planNumber,
      companyName: criteriaSearch.companyName,
      isPermission: criteriaSearch.isPermission,
    };

    const { data, status } = await jorPor06.getCountJorPor06ListAsync(criteriaData);

    if (status === HttpStatusCode.OK) {
      const count = data.data;

      const supplyMethod = [
        { label: 'ทั้งหมด', value: 'All', count: count.allSupplyMethod },
        { label: 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560', value: '342e4e0b-7682-48c0-b46a-97a066908c9a', count: count.supplyMethod60 },
        { label: 'ข้อบังคับธนาคาร 80', value: 'a21603e4-338d-486c-85ba-5f1cac8f6ace', count: count.supplyMethod80 },
      ];

      const status = [
        { label: 'ทั้งหมด', value: 'All', count: count.all },
        { label: 'ยังไม่ดำเนินการ', value: 'NotYet', count: count.notYet },
        { label: 'แบบร่าง', value: 'Draft', count: count.draft },
        { label: 'อยู่ระหว่าง คกก. เห็นชอบ', value: 'WaitingComitteeApprove', count: count.waitingComitteeApprove },
        { label: 'อยู่ระหว่างรอ ผอ.จพ. มอบหมาย', value: 'WaitingForDirectorJorPorAssign', count: count.waitingForDirectorJorPorAssign },
        { label: 'อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ', value: 'DraftAcceptor', count: count.draftAcceptor },
        { label: 'รออนุมัติ', value: 'WaitingAccept', count: count.waitingAcceptor },
        { label: 'เห็นชอบ/อนุมัติ', value: 'Accepted', count: count.accepted },
        { label: 'ส่งกลับแก้ไข', value: 'Reject', count: count.reject },
        { label: 'ยกเลิก', value: 'Cancelled', count: count.cancelled },
      ]

      setSupplyMethodArray(supplyMethod);
      setStatusNameArray(status);
    }
  };

  return (
    <Context.Provider value={conTextValue}>
      {!onInit && <>
        <Jorpor006header />
        <Criteria planStatusNameArray={statusNameArray} supplyMethodArray={supplyMethodArray} />
        <DataTable />
      </>}
    </Context.Provider>
  );
}

function Jorpor006header() {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">จัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง ( จพ.006 )</h4>
      </div>
      <hr />
    </>
  );
}

function Criteria(props: { planStatusNameArray: ItemModelBadge[], supplyMethodArray: ItemModelBadge[] }) {
  const { departmentDDL, supplyMethodDDL } = useLoaderData() as Loader;
  const { criteria, setCriteria, onSearchCriteria, onClearCriteria } = useContext(Context);

  const supplyMethodOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const supplyMethod = [...supplyMethodDDL.map((s) => s.value)];
        setCriteria({ ...criteria, supplyMethod });
        return;
      }
      setCriteria({ ...criteria, supplyMethod: [] });
      return;
    }
    if (result) {
      if ((criteria.supplyMethod.length + 1) === (supplyMethodDDL.length - 1)) {
        setCriteria({
          ...criteria,
          supplyMethod: [
            ...criteria.supplyMethod,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        supplyMethod: [
          ...criteria.supplyMethod,
          value,
        ],
      });
    } else {
      const supplyMethod = [...criteria.supplyMethod];
      const index = supplyMethod.findIndex((ps) => ps === value);

      if (index >= 0) {
        supplyMethod.splice(index, 1);
        const allIndex = supplyMethod.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          supplyMethod.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, supplyMethod });
      }
    }
  };

  const statusOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const status = [...props.planStatusNameArray.map((s) => s.value)];
        setCriteria({ ...criteria, status });
        return;
      }
      setCriteria({ ...criteria, status: [] });
      return;
    }
    if (result) {
      if ((criteria.status.length + 1) === (props.planStatusNameArray.length - 1)) {
        setCriteria({
          ...criteria,
          status: [
            ...criteria.status,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        status: [
          ...criteria.status,
          value,
        ],
      });
    } else {
      const status = [...criteria.status];
      const index = status.findIndex((ps) => ps === value);

      if (index >= 0) {
        status.splice(index, 1);
        const allIndex = status.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          status.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, status });
      }
    }
  };

  return (
    <>
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="เลขที่รายงานขอซื้อขอจ้าง (จพ.005)"
            placeholder="เลขที่รายงานขอซื้อขอจ้าง (จพ.005)"
            value={criteria.jorPor05Number}
            onChange={value => setCriteria({ ...criteria, jorPor05Number: value })} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="เลขที่รายการจัดซื้อจัดจ้าง"
            placeholder="เลขที่รายการจัดซื้อจัดจ้าง"
            value={criteria.planNumber}
            onChange={value => setCriteria({ ...criteria, planNumber: value })} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="เลขที่ จพ.006"
            placeholder="เลขที่ จพ.006"
            value={criteria.jorPor06Number}
            onChange={value => setCriteria({ ...criteria, jorPor06Number: value })} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector label="ฝ่าย/สำนัก"
            placeholder="ฝ่าย/สำนัก"
            items={departmentDDL}
            value={criteria.departmentId}
            onChange={value => setCriteria({ ...criteria, departmentId: value })} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="ชื่อโครงการ"
            placeholder="ชื่อโครงการ"
            value={criteria.planName}
            onChange={value => setCriteria({ ...criteria, planName: value })} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input
            label="ชื่อบริษัท"
            placeholder="ชื่อบริษัท"
            value={criteria.companyName}
            onChange={value => setCriteria({ ...criteria, companyName: value })} />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col sm={6}
          className='Type'>
          วิธีจัดหา
          <div className='d-flex gap-2'>
            {props.supplyMethodArray?.map((v, i) => (
              <Check
                key={generateUniqueId(i)}
                onChange={(val) => supplyMethodOnChange(v.value, val)}
                value={criteria.supplyMethod?.some((i) => i === v.value)}
                label={(
                  <>
                    {v.label} <Status type={StatusType.SUPPLYMETHOD}
                      value={v.value}
                      couting={v.count}
                      badge />
                  </>
                )}
              />
            ))}
          </div>
        </Col>
        <Col md={6} className="Status">
          สถานะ
          <div className="d-flex gap-2 flex-wrap">
            {props.planStatusNameArray?.map((v, i) => (
              <Check
                key={generateUniqueId(i)}
                value={criteria.status?.some((i) => i === v.value)}
                onChange={(val) => statusOnChange(v.value, val)}
                label={
                  <>
                    {v.label}
                    <Status type={StatusType.PROCESS} value={v.value} couting={v.count} badge />
                  </>
                }
              />
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4} xl={3}>
          <Check label='แสดงเฉพาะรายการที่ได้รับมอบหมาย' value={criteria.isPermission} onChange={(value) => setCriteria({ ...criteria, isPermission: value })} />
        </Col>
      </Row>
      <div className="d-flex gap-2">
        <Button
          type="submit"
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={() => onSearchCriteria()}>
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant="outline-primary"
          className="d-flex align-items-center gap-2"
          onClick={() => onClearCriteria()}>
          <FaEraser />ล้าง
        </Button>
      </div>
    </>
  );
}

function DataTable() {
  const { jorpor06Lists, onSearchCriteria, setPage, setSize, totalRecords } = useContext(Context);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const [indexId, setIndexId] = useState<number>(0);

  const goToPage = async (inviteId: string, id?: string) => {
    if (id) {
      navigate(`/procurement-create-006/detail/${inviteId}/${id}`);

      return;
    }

    const { data, status } = await jorPor06.createJorpor06Async(inviteId);

    if (status === HttpStatusCode.CREATED) {
      navigate(`/procurement-create-006/detail/${inviteId}/${data.id}`);
    }
  };

  const openModalDelete = (index: number, id: string) => {
    setShowDeleteModal(true);
    setDeleteId(id);
    setIndexId(index);
  };

  const deleteAsync = async (index: number, id: string) => {

    if (id) {
      const response = await jorPor06.deleteJorPor06Async(id);
      if (response.status === HttpStatusCode.OK) {
        toast.success('ลบรายการสำเร็จ');
        const temp: JorPor06ListsResponse[] = [...jorpor06Lists];
        temp.splice(index, 1);
        onSearchCriteria();
        setPage;
        setSize;
        setShowDeleteModal(false);
      } else {
        toast.error('เกิดข้อผิดพลาด');
      }
    } else {
      toast.error('เกิดข้อผิดพลาด');
    }

    setShowDeleteModal(false);
  };

  return (
    <Table total={totalRecords} className="mt-4" onChange={(size, page) => (setPage(page), setSize(size))}>
      <thead>
        <tr className="text-center">
          <th>
            เลขที่รายการขอซื้อขอจ้าง<br />(จพ.005)
          </th>
          <th style={{ minWidth: 50 }}>
            เลขที่รายการ<br />จัดซื้อจัดจ้าง
          </th>
          <th>เลขที่ จพ.006</th>
          <th>สถานะ</th>
          <th className='text-start'>ฝ่าย/สำนัก</th>
          <th style={{ minWidth: 150, width: 400 }}>ชื่อโครงการ</th>
          <th className='text-end'>งบประมาณ (บาท)</th>
          <th>วิธีจัดหา</th>
          <th>ประมาณการช่วงเวลา<br />
            การจัดซื้อจัดจ้าง</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {jorpor06Lists.map((data, index) => (
          <tr className="text-center" key={index}>
            <td>
              <Button
                variant="link"
                onClick={() => goToPage(data.inviteId, data.id)}>
                {data.jorPor05Number}
              </Button>
            </td>
            <td>{data.planNumber}</td>
            <td>{data.jorPor06Number}</td>
            <td>
              <Status type={StatusType.JOR_POR_04_05_APPROVER} value={data.status ?? ""} />
              {
                data.isInCanceling ? (
                  <div className='status'
                    style={{ marginRight: 10 }}>
                    <span className='text-danger'>({TorEditCancelStatusConstant.Canceling})</span>
                  </div>
                ) : data.isInChanging ? (
                  <div className='status'
                    style={{ marginRight: 10 }}>
                    <span className='text-warning'>({TorEditCancelStatusConstant.Editing})</span>
                  </div>
                ) : (<></>)
              }</td>
            <td className='text-start'>{data.departmentName}</td>
            <td className='text-start text-wrap'>{data.planName}</td>
            <td className='text-end'>{THCurrency(data.financialAmount)}</td>
            <td>{data.supplyMethodName}</td>
            <td>{GetMonthYearBC(data.expectingProcurementAt)}</td>
            <td>
              {
                (data.isInCanceling && (data.status === AnnouncementStatus.Draft || data.status === AnnouncementStatus.Reject)) && (
                  <div className='d-flex justify-content-center'>
                    <Button
                      disabled={!data.isAssign && !data.isCommitteeProcurement}
                      variant='danger'
                      className='d-flex align-items-center gap-2'
                      onClick={() => {
                        openModalDelete(index, data.id!);
                      }}
                    >
                      <FaTrashAlt />
                    </Button>
                  </div>
                )
              }
            </td>
          </tr>
        ))}
      </tbody>
      <Modal
        show={showDeleteModal}
        size='lg'
        onHide={() => setShowDeleteModal(!showDeleteModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบรายการ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการลบรายการขอยกเลิกสั่งซื้อ/สั่งจ้าง (จพ.006) หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-danger'
                onClick={() => setShowDeleteModal(!showDeleteModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  deleteAsync(indexId, deleteId);
                }}
              >
                ยืนยันลบข้อมูล
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </Table>
  );
}
