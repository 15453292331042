import { HttpStatusCode } from 'axios';
import {
  Breadcrumb,
  Card,
  Check,
  Currency,
  Input,
  Modal,
  Selector,
  Table,
} from 'components';
import {
  matrixPagination,
  MatrixTorPp,
  MatrixTorPpApprover,
  searchMatrixApprover,
} from 'models/PP';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaPlus,
  FaSave,
  FaSearch,
  FaTimes,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { account } from 'services';
import PP0105Service from 'services/PP/PP0105Service';
import { submitForm } from 'utils';
import toast from 'utils/toast';
import { generateUniqueId } from '../../../utils/helper';

const BREADCRUMB_INFO = [
  {
    routerName: '/pp/pp0105', label: 'Matrix การอนุมัติร่างขอบเขตและราคากลาง',
  },
  {
    routerName: '/pp/pp0105/detail', label: 'รายละเอียด Matrix การอนุมัติร่างขอบเขตและราคากลาง',
  },
];

interface ItemModel {
  label: string;
  value: string;
}

type Loader = {
  departmentList: ItemModel[],
  supplyMethodTypeList: ItemModel[],
  supplyMethodTypeNameList: ItemModel[],
  employeeList: MatrixTorPpApprover[],
  positionOnBoardList: ItemModel[],
  matrixDetail: MatrixTorPp,
};

export default function PP0105Detail() {
  const navigate = useNavigate();
  const [data, setData] = useState<MatrixTorPp>({
    matrixApprovers: [] as MatrixTorPpApprover[],
  } as MatrixTorPp);
  const [toggleModal, setToggleModal] = useState(false);
  const [pagination, setPagination] = useState<matrixPagination>({
    page: 1,
    size: 10,
    totalRecords: 0,
  } as matrixPagination);
  const {
    departmentList,
    supplyMethodTypeList,
    supplyMethodTypeNameList,
    employeeList,
    positionOnBoardList,
    matrixDetail,
  } = useLoaderData() as Loader;

  useEffect(() => {
    if (matrixDetail !== undefined) {
      setData(matrixDetail);
    }
  }, []);

  const onSelect = (user: MatrixTorPpApprover) => {
    data.matrixApprovers.push(user);
    setPagination({ ...pagination, totalRecords: data.matrixApprovers.length });
  };

  const remove = (index: number) => {
    const temp: MatrixTorPpApprover[] = [...data.matrixApprovers];
    temp.splice(index, 1);
    setData({ ...data, matrixApprovers: [...temp] });
    setPagination({ ...pagination, totalRecords: temp.length });
  };

  const handlerOnChange = useCallback((i: number, value: string) => {
    data.matrixApprovers[i].positionOnBoardId = value;

    setData({ ...data });
  }, [data]);

  const onSubmit = async (value: MatrixTorPp) => {
    submitForm();
    if (
      value.name === null
      || value.limitFrom === null
      || value.supplyMethodTypeId === null
      || value.supplyMethodId === null) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
      return;
    }

    if (value.matrixApprovers.length <= 0) {
      toast.warn('ต้องมีผู้อนุมัติอย่างน้อย 1 คน');
      return;
    }
    if (value.matrixApprovers.some((i) => i.positionOnBoardId === null || i.positionOnBoardId === undefined)) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
      return;
    }

    const modelForSave: MatrixTorPp = value;
    const temp: MatrixTorPpApprover[] = modelForSave.matrixApprovers.map((i) => ({
      id: i.id === undefined ? i.approverUserId : i.id,
      positionOnBoardId: i.positionOnBoardId,
      approverUserId: i.approverUserId === undefined ? i.id : i.approverUserId,
      matrixTorPpId: i.matrixTorPpId === undefined ? null : i.matrixTorPpId,
      matrixApproverId: i.matrixApproverId === undefined ? null : i.matrixApproverId,
    } as MatrixTorPpApprover));
    modelForSave.matrixApprovers = temp;

    if (value.id) {
      const { status } = await PP0105Service.updateMatrixAsync(modelForSave);
      if (status === HttpStatusCode.NoContent) {
        toast.success('บันทึกข้อมูลสำเร็จ');
        navigate('/pp/pp0105/');
      }
      return;
    }

    const { data, status, statusText } = await PP0105Service.CreateMatrixTorPpAsync(modelForSave);
    if (status === HttpStatusCode.Created) {
      toast.success('บันทึกข้อมูลสำเร็จ');
      navigate('/pp/pp0105/');
    } else {
      toast.warn(statusText);
    }
  };

  return (
    <div className='PP0105Detail'>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <h4 className='mt-2 mb-0 text-primary text-start'>Matrix การอนุมัติร่างขอบเขตและราคากลาง</h4>
          <Breadcrumb data={BREADCRUMB_INFO} />
        </div>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => {
            onSubmit(data);
          }}
        >
          <FaSave />บันทึก
        </Button>
      </div>
      <hr />
      <div className='mt-4'>
        <Card>
          <div className='d-flex align-items-center gap-3'>
            <div className='parallelogram' />
            <h4 className='mt-2 text-primary'>ข้อมูล Matrix</h4>
          </div>
          <div className='criteria'>
            <Row>
              <Col md={6}
                lg={4}>
                <Input
                  label='ชื่อ Matrix'
                  value={data.name}
                  onChange={(value) => setData({ ...data, name: value })}
                  rule={{ required: true }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}
                lg={3}>
                <Currency
                  label='วงเงินตั้งแต่'
                  value={data.limitFrom}
                  onChange={(value) => setData({ ...data, limitFrom: Number(value) })}
                  rule={{ required: true }}
                />
              </Col>
              <Col md={6}
                lg={3}>
                <Currency
                  label='วงเงินถึง'
                  value={data.limitTo}
                  onChange={(value) => setData({ ...data, limitTo: Number(value) })}
                />
              </Col>
              <Col md={6}
                lg={3}>
                <Selector
                  label='วิธีการจัดหา'
                  items={supplyMethodTypeList}
                  value={data.supplyMethodTypeId}
                  rule={{ required: true }}
                  onChange={(val) => setData({ ...data, supplyMethodTypeId: val })}
                />
              </Col>
              <Col className='d-flex align-items-center mt-3'>
                <Selector
                  items={supplyMethodTypeNameList}
                  value={data.supplyMethodId}
                  onChange={(val) => setData({ ...data, supplyMethodId: val })}
                  rule={{ required: true }}
                />
              </Col>
              <Col lg={12}
                className='mt-2'>
                <Check label='ใช้งาน'
                  value={data.active}
                  onChange={(value) => setData({ ...data, active: value })} />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
      <div className='mt-3'>
        <Card>
          <div className='d-flex align-items-center gap-3'>
            <div className='parallelogram' />
            <h4 className='mt-2 text-primary'>อำนาจอนุมัติ</h4>
          </div>
          <Table
            className='table mt-4'
            total={pagination.totalRecords}
            onChange={(size, page) => (setPagination({ ...pagination, size }), setPagination({ ...pagination, page }))}
          >
            <thead>
            <tr>
              <th style={{ minWidth: 75 }}>ลำดับ</th>
              <th style={{ minWidth: 250 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 200 }}>ตำแหน่ง</th>
              <th style={{ minWidth: 200 }}>ตำแหน่งในคณะกรรมการ</th>
              <th style={{ minWidth: 75 }} />
            </tr>
            </thead>
            <tbody>
            {data.matrixApprovers?.map((val, index) => (
              <tr key={val.id}>
                <td className='text-center'>
                  {index + 1}
                </td>
                <td className='text-center'>
                  {val.name}
                </td>
                <td className='text-center'>
                  {val.position}
                </td>
                <td className='text-center'>
                  <Selector
                    items={positionOnBoardList}
                    value={val.positionOnBoardId}
                    onChange={(value) => handlerOnChange(index, value)}
                    rule={{ required: true }}
                  />
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='outline-primary'
                      className='d-flex align-items-center gap-2'
                      onClick={() => remove(index)}
                    >
                      <FaTrashAlt />ลบ
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
          <div className='mt-3'>
            <Button
              variant='light'
              className='d-flex align-items-center gap-2'
              onClick={() => setToggleModal(true)}
            >
              <FaPlus />เพิ่มข้อมูล
            </Button>
          </div>
        </Card>
      </div>
      <SearchNameModal
        show={toggleModal}
        onHide={() => setToggleModal(false)}
        onSubmit={(val) => onSelect(val)}
        approverSelected={data.matrixApprovers}
        departmentDDL={departmentList}
        employeeData={employeeList}
      />
    </div>
  );
}

interface ModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (value: MatrixTorPpApprover) => void;
  approverSelected: MatrixTorPpApprover[];
  departmentDDL: ItemModel[];
  employeeData: MatrixTorPpApprover[];
}

function SearchNameModal(props: ModalProps) {
  const [show, setShow] = useState<boolean>(false);
  const [pagination, setPagination] = useState<matrixPagination>({
    page: 1,
    size: 10,
    totalRecords: 0,
  });
  const [criteria, setCriteria] = useState<searchMatrixApprover>({} as searchMatrixApprover);
  const [users, setUsers] = useState<MatrixTorPpApprover[]>();
  const [approverSelected, setApproverSelected] = useState<MatrixTorPpApprover[]>([]);

  useEffect(() => {
    if (props.show) {
      const temp: MatrixTorPpApprover[] = [...props.employeeData];
      if (props.approverSelected.length > 0) {
        props.approverSelected.forEach((value) => {
          temp.forEach((v, i) => {
            if (v.id === value.id || v.id === value.approverUserId) {
              temp.splice(i, 1);
            }
          });
        });
      }
      setInitailValue(temp);
      setShow(true);
    }
  }, [props.show]);

  const setInitailValue = useCallback((data: MatrixTorPpApprover[]) => {
    setUsers(data);
    setPagination({ ...pagination, totalRecords: data.length });
  }, []);

  const clearCriteria = () => {
    setCriteria({} as searchMatrixApprover);
    const temp: MatrixTorPpApprover[] = [...props.employeeData];
    if (props.approverSelected.length > 0) {
      props.approverSelected.forEach((value) => {
        temp.forEach((v, i) => {
          if (v.id === value.id || v.id === value.approverUserId) {
            temp.splice(i, 1);
          }
        });
      });
    }
    setInitailValue(temp);
  };

  const onHide = () => {
    setUsers([]);
    setShow(false);
    props.onHide();
  };

  const onSearch = (value: searchMatrixApprover, pagiante: matrixPagination) => {
    getUsersAsync(value, pagiante);
  };

  const getUsersAsync = async (value: searchMatrixApprover, paged: matrixPagination) => {
    const { data, status } = await account.getUsersAsync(
      paged.size,
      paged.page,
      value.name,
      '',
      '',
      value.department,
      value.position,
    );
    if (status === HttpStatusCode.Ok) {
      const temp: MatrixTorPpApprover[] = [...data.data];
      if (props.approverSelected.length > 0) {
        props.approverSelected.forEach((value) => {
          temp.forEach((v, i) => {
            if (v.id === value.id || v.id === value.approverUserId) {
              temp.splice(i, 1);
            }
          });
        });
      }
      setInitailValue(temp);
    }
  };

  const handlerOnSubmit = (data: MatrixTorPpApprover) => {
    setApproverSelected([...approverSelected, data]);
    props.onSubmit(data);
    onHide();
  };

  return (
    <Modal show={show}
      size='xl'>
      <div className='d-flex justify-content-between align-items-center'>
        <h5 className='mb-1'>ค้นหารายชื่อ</h5>
        <FaTimes onClick={() => onHide()} />
      </div>
      <Card className='mb-3'>
        <p>ค้นหา</p>
        <div className='criteria'>
          <Row>
            <Col md={12}
              lg={4}>
              <Input
                label='ชื่อ-นามสกุล'
                value={criteria.name}
                onChange={(val) => setCriteria({ ...criteria, name: val })}
              />
            </Col>
            <Col md={12}
              lg={4}>
              <Selector
                label='ฝ่าย/สำนัก'
                items={props.departmentDDL}
                value={criteria.department}
                onChange={(val) => setCriteria({ ...criteria, department: val })}
              />
            </Col>
            <Col md={12}
              lg={4}>
              <Selector
                label='ตำแหน่ง'
                value={criteria.position}
                onChange={(val) => setCriteria({ ...criteria, position: val })}
              />
            </Col>
            <div className='d-flex gap-2'>
              <Button
                variant='primary'
                className='d-flex align-items-center gap-2'
                onClick={() => onSearch(criteria, pagination)}
              >
                <FaSearch />ค้นหา
              </Button>
              <Button
                variant='outline-primary'
                className='d-flex align-items-center gap-2'
                onClick={clearCriteria}
              >
                <FaEraser />ล้าง
              </Button>
            </div>
          </Row>
          <Table
            className='mt-4'
            total={pagination.totalRecords}
            onChange={(size, page) => (setPagination({ ...pagination, size }), setPagination({ ...pagination, page }))}
          >
            <thead>
            <tr>
              <th style={{ minWidth: 75 }}>ลำดับ</th>
              <th style={{ minWidth: 200 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 200 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 350 }}>ตำแหน่ง</th>
              <th style={{ minWidth: 50 }} />
            </tr>
            </thead>
            <tbody>
            {users?.map((value, index) => (
              <tr key={generateUniqueId(value.id)}>
                <td className='text-center'>{index + 1}</td>
                <td className='text-center'>{value.name}</td>
                <td className='text-center'>{value.department}</td>
                <td className='text-center'>{value.position}</td>
                <td className='text-center'>
                  <Button
                    variant='primary'
                    className='d-flex align-items-center gap-2'
                    onClick={() => handlerOnSubmit(value)}
                  >
                    เลือก
                  </Button>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </div>
      </Card>
    </Modal>
  );
}
