import { AppointCmtPrApprovalListRequest } from 'models/PR/PR0302Models';
import http from 'utils/axios';

const qs = require('qs');

const getAppointCmtProcurementApprovalListAsync = async (searchData: AppointCmtPrApprovalListRequest) => {
  const params = {
    orderNumber: searchData.orderNumber,
    orderName: searchData.orderName,
    projectName: searchData.projectName,
    departmentId: searchData.departmentId,
    procurementCommitee: searchData.procurementCommitee,
    purchaseInspector: searchData.purchaseInspector,
    responsiblePerson: searchData.responsiblePerson,
    page: searchData.page,
    size: searchData.size,
  };

  return http.get('/appointCmtProcurementApproval/pagination', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getAppointCmtProcurementApprovalByIdAsync = async (id: string) => await http.get(`/appointCmtProcurementApproval/${id}`);

const onRejectAppointCmtProcurementApprovalByIdAsync = async (id: string, remark: string) => {
  const params = {
    remark,
  };
  return await http.put(`/appointCmtProcurementApproval/rejected/${id}`, params);
};

const onApproveAppointCmtProcurementApprovalByIdAsync = async (id: string, remark: string) => {
  const params = {
    remark,
  };
  return await http.put(`/appointCmtProcurementApproval/approved/${id}`, params);
};

export default {
  getAppointCmtProcurementApprovalListAsync,
  getAppointCmtProcurementApprovalByIdAsync,
  onRejectAppointCmtProcurementApprovalByIdAsync,
  onApproveAppointCmtProcurementApprovalByIdAsync,
};
