import {
  Input,
  Selector,
  Table,
} from 'components';
import { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { generateUniqueId } from '../../../utils/helper';
import { useForm } from '../../../utils/useForm';

export default function PP0206() {
  return (
    <>
      <PP0206Header />
      <Criteria />
      <DataTable />
    </>
  );
}

function PP0206Header() {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">การแจ้งข้อมูลเบื้องต้น (จพ.004) - มอบหมายงาน</h4>
        <Button><MdAdd className="me-2 fs-5" />มอบหมายงาน</Button>
      </div>
      <hr />
    </>
  );
}

interface FormInput {
  code: string;
  department: string;
  name: string;
  budget: string;
  createBy: string;
  typeAll: string;
  type80: string;
  type60: string;
  statusAll: string;
  statusDraft: string;
  statusPending: string;
  statusApprove: string;
  statusReject: string;
}

function Criteria() {
  const [program, setProgram] = useState('');
  const { handleSubmit, data: formData, errors, handleClear } = useForm<FormInput>({
    validations: {

    },
    onSubmit: async () => { console.log(formData); },
  });

  return (
    <Form onSubmit={handleSubmit} id="formPL01">
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="เลขที่เอกสาร PR" placeholder="เลขที่เอกสาร PR" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="ร่างขอบเขตของงาน (TOR)" placeholder="ร่างขอบเขตของงาน (TOR)" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="ชื่อโครงการ" placeholder="ชื่อโครงการ" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector label="ฝ่าย/สำนัก" value={program} onChange={(value) => setProgram(value.toString())} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="ผู้จัดทำ" placeholder="ผู้จัดทำ" />
        </Col>
        <div className="d-flex gap-2">
          <Button form="formPL01" type="submit" variant="primary" className="d-flex align-items-center gap-2">
            <FaSearch />ค้นหา
          </Button>
          <Button variant="outline-primary" onClick={handleClear} className="d-flex align-items-center gap-2">
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
    </Form>
  );
}

function DataTable() {
  const navigate = useNavigate();
  const goToPage = () => {
    navigate('/pp/pp0206/detail');
  };
  return (
    <Table className="mt-4">
      <thead>
        <tr className="text-center">
          <th>ลำดับ</th>
          <th>เลขที่เอกสาร PR</th>
          <th>ร่างขอบเขตของงาน (TOR)</th>
          <th>ชื่อโครงการ</th>
          <th>ฝ่าย/สำนัก</th>
          <th>ผู้จัดทำ</th>
          <th>สถานะ</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 5 }).map((_, index) => (
          <tr className="text-center" key={generateUniqueId(index)}>
            <td>{index}</td>
            <td>PJ660001</td>
            <td>059 / 2565 : โครงการ Digital Procurement ฝ่ายจัดหาและการพัสดุ</td>
            <td><Button variant="link" onClick={goToPage}>P660001</Button> : จ้างพัฒนาระบบ SAP</td>
            <td>บช. : ฝ่ายการบัญชี</td>
            <td>Pratya</td>
            <td>03/2566</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
