import {
  Selector,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import {
  BuDepartmentListResponse,
  CriteriaPlanProcurementListModel,
  ItemModel,
  PlanProcurementListModel,
} from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { department, PP0205Service } from 'services';
import {
  HttpStatusCode,
  THCurrency,
} from 'utils';
import { useForm } from 'utils/useForm';
import { Modal } from './Modal';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';
import toast from 'utils/toast';
import PreProcurementServices from 'services/PP/PreProcurementServices';
import { ProgramType } from 'utils/constants/PlanEnum';
import PurchaseOrderApprovalServices from 'services/PR/PurchaseOrderApprovalServices';
import CheckBoxSupplyMethod from 'components/SupplyMethodCheck';

interface Props {
  show: boolean;
  onHide?: () => void;
  onSelectItem?: (planId: string, balanceBudget: number) => void;
  budgetAmount?: number;
  departmentCode?: string;
  departmentDisabled?: boolean;
  programType?: ProgramType;
}

interface FormInput {
  planNumber: string;
  planName: string;
  departmentId: string;
  supplyMethod: string[];
}

const enum PlanType {
  All = '',
  AnnualPlan = 'AnnualPlan',
  InYearPlan = 'InYearPlan',
}

const enum PlanStatus {
  Announcement = 'Announcement',
  ApprovePlan = 'ApprovePlan',
  ApproveAcceptor = 'ApproveAcceptor',
}

const supplyMethodValue = ['All', '342e4e0b-7682-48c0-b46a-97a066908c9a', 'a21603e4-338d-486c-85ba-5f1cac8f6ace'];

interface Options {
  value: string;
  label: string;
}

export function PlanProcurementModal(props: Props) {
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [planData, setplanData] = useState<PlanProcurementListModel[]>([]);
  const [planType, setPlanType] = useState('');
  const [totalRecord, setTotalRecord] = useState(0);
  const [departments, setDepartments] = useState<Options[]>([]);
  const [departmentCode, setDepartmentCode] = useState<string>('');
  const [supplyMethod, setSupplyMethod] = useState<string[]>(supplyMethodValue);

  useEffect(() => {
    if (props.show) {
      getDepartmentData();
      if (props.departmentCode !== undefined) {
        setDepartmentCode(props.departmentCode);
      } else {
        setDepartmentCode('');
      }
      setPlanType(PlanType.All);
      onGetPlanLiatAsync({ supplyMethod: supplyMethodValue } as CriteriaPlanProcurementListModel, '', props.departmentCode);
    }
  }, [props.show, props.departmentCode]);

  useEffect(() => {
    if (supplyMethod) {
      setSupplyMethod(supplyMethod);
      initFormData({
        ...formData,
        supplyMethod: supplyMethod,
      })
    }
  }, [supplyMethod]);

  const getDepartmentData = async () => {
    const { data, status } = await department.getBuDepartmentsListAsync<BuDepartmentListResponse[]>();

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถดึงข้อมูล Department ได้');
      return;
    }

    const newData = data?.map((d) => ({ value: d.code, label: d.name }));

    setDepartments(newData);
  };

  const onSelect = (planId: string, balanceBudget: number) => {
    props.onSelectItem!(planId, balanceBudget);
    props.onHide!();
  };

  const onGetPlanLiatAsync = async (criteria: CriteriaPlanProcurementListModel, planType?: string,
    departmentCode?: string, pageParam?: number, sizeParam?: number) => {
    const sizeValue = sizeParam || size;
    const pageValue = pageParam || page;

    if (props.programType === ProgramType.ContractRecord) {

      const res = await PurchaseOrderApprovalServices.getPlanProcurementListAsync(pageValue, sizeValue, planType, departmentCode, criteria);
      if (res.status === HttpStatusCode.OK) {
        const { data } = res.data;
        setplanData(data);
        setTotalRecord(res.data.totalRecords);
      }

    } else if (props.programType === ProgramType.JorPor04) {
      const res = await PP0205Service.getPlanProcurementListAsync(pageValue, sizeValue, planType, departmentCode, criteria);
      if (res.status === HttpStatusCode.OK) {
        const { data } = res.data;
        setplanData(data);
        setTotalRecord(res.data.totalRecords);
      }
    } else {
      const res = await PreProcurementServices.getPlanProcurementListAsync(pageValue, sizeValue, planType, departmentCode, criteria);
      if (res.status === HttpStatusCode.OK) {
        const { data } = res.data;
        setplanData(data);
        setTotalRecord(res.data.totalRecords);
      }
    }
  };

  const onClear = useCallback(async () => {
    setPlanType('');
    setDepartmentCode('');
    handleClear();
    setSupplyMethod(supplyMethodValue);
  }, []);

  const { handleSubmit, handleChange, data: formData, errors, initFormData, handleClear } = useForm<FormInput>({
    initialValues: {
      planName: '',
      planNumber: '',
      departmentId: '',
      supplyMethod: supplyMethod,
    },
    validations: {},
    onSubmit: async () => {
      const status: string[] = [];
      const searchModel: CriteriaPlanProcurementListModel = {
        planNumber: formData.planNumber,
        planName: formData.planName,
        budget: undefined,
        budgetYear: undefined,
        expectingProcurementAt: undefined,
        supplyMethod: supplyMethod ?? [],
        status,
      };

      onGetPlanLiatAsync(searchModel, planType, departmentCode);
    },
  });

  const handleSetPagination = (page: number, size: number) => {
    setSize(size);
    setPage(page);
    const status: string[] = [];

    const searchModel: CriteriaPlanProcurementListModel = {
      planNumber: formData.planNumber,
      planName: formData.planName,
      budget: undefined,
      budgetYear: undefined,
      expectingProcurementAt: undefined,
      supplyMethod: formData.supplyMethod ?? [],
      status,
    };
    onGetPlanLiatAsync(searchModel, planType, departmentCode, page, size);
  };

  const typePlanOptions: ItemModel[] = [
    { value: '', label: 'ทั้งหมด' },
    { value: PlanType.AnnualPlan, label: 'รายการจัดซื้อจัดจ้าง (รวมปี)' },
    { value: PlanType.InYearPlan, label: 'รายการจัดซื้อจัดจ้าง (ระหว่างปี)' },
  ];

  return (

    <Modal
      show={props.show}
      size="xl"
      className="fix-width-1800"
      onHide={() => (props.onHide ? props.onHide() : null)}
    >
      <ModalBT.Header closeButton>
        <ModalBT.Title>
          <Title
            text="ค้นหารายการจัดซื้อจัดจ้าง"
            className="fs-5 text-primary"
          />
        </ModalBT.Title>
      </ModalBT.Header>
      <ModalBT.Body className="p-0 ps-4">
        <Form onSubmit={handleSubmit} id="userModalForm">
          <Row className="align-items-center">
            <Col sm={12} md={6}>
              <Form.Label>เลขที่รายการจัดซื้อจัดจ้าง</Form.Label>
              <Form.Group className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={formData.planNumber || ''}
                    onChange={handleChange<string>('planNumber', (value) => value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Label>ชื่อโครงการ</Form.Label>
              <Form.Group className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={formData.planName}
                    onChange={handleChange<string>('planName', (value) => value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Selector
                label="รายการจัดซื้อจัดจ้าง"
                items={typePlanOptions}
                onChange={(val) => setPlanType(val)}
                value={planType}
              />
            </Col>
            {
              (!props.departmentDisabled) && (
                <Col sm={12} md={6}>
                  <Selector label="ฝ่าย/สำนัก"
                    items={departments}
                    value={departmentCode}
                    onChange={(val) => setDepartmentCode(val)}
                    disabled={props.departmentDisabled !== undefined ? props.departmentDisabled : false}
                  />
                </Col>
              )
            }
            <Col sm={12} md={6}>
              <CheckBoxSupplyMethod
                onChange={(value) => setSupplyMethod(value)}
                supplyMethod={supplyMethod}
              />
            </Col>
          </Row>
          <div className="d-flex gap-2">
            <Button variant="primary" className="px-3" type="submit" form="userModalForm">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              className="px-4"
              variant="outline-primary"
              onClick={onClear}
              type="submit"
              form="userModalForm"
            >
              ล้าง
            </Button>
          </div>
        </Form>
        <Table
          className="my-3"
          total={totalRecord}
          onChange={(size, page) => {
            handleSetPagination(page, size);
          }}
        >
          <thead>
            <tr>
              <th style={{ minWidth: 20 }}>ลำดับ</th>
              <th style={{ minWidth: 50 }}>เลขที่รายการจัดซื้อจัดจ้าง</th>
              <th style={{ minWidth: 50 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 100 }}>ชื่อโครงการ</th>
              <th style={{ minWidth: 50 }}>วิธีจัดหา</th>
              <th style={{ minWidth: 50 }}>วงเงินงบประมาณ</th>
              {props.programType !== ProgramType.ContractRecord && (
                <th style={{ minWidth: 50 }}>วงเงินคงเหลือ</th>
              )}
              <th style={{ minWidth: 25 }} />
            </tr>
          </thead>
          <tbody>
            {planData?.map((data, index) => (
              <tr key={index}>
                <td className="text-center">{calculateRowNumber(index, page, size)}</td>
                <td className="text-center">{data.planNumber}</td>
                <td className="text-start">{data.department}</td>
                <td className="text-start  text-wrap"
                  style={{
                    width: 200,
                    minWidth: 200,
                  }}>{data.name}</td>
                <td className="text-start">{data.supplyMethod} </td>
                <td className="text-end">{THCurrency(data.budget)}</td>
                {props.programType !== ProgramType.ContractRecord && (
                  <td className="text-end">{THCurrency(data.balanceBudget)}</td>
                )}
                <td>
                  <Button variant="primary" className="px-3" onClick={() => onSelect(data.id, data.balanceBudget)}>
                    เลือก
                  </Button>
                </td>
              </tr>
            ))}

          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}