import { Card } from 'components';

interface Props {
  title: string;
  total: number;
  onClick?: () => void;
}

export function CardSummary({ total, title, onClick }: Props) {
  return (
    <div onClick={onClick} className='w-100 cursor-pointer'>
      <Card className='d-flex flex-column'>
        <p className="m-0">{title}</p>
        <p className="m-0 text-end fs-3 lh-1 text-primary">{total}</p>
        <p className="m-0 text-end">โครงการ</p>
      </Card>
    </div>
  );
}
