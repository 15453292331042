import {
  Card,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  Table,
  TorStatusDetail,
  UploadFile,
} from 'components';
import { ContractStatus } from 'components/ContractStatus';
import Title from 'components/Controls/Title';
import {
  HistoryModal,
  SearchNameModal,
} from 'components/Modal';
import {
  DeliveryItemModel,
  deliveryParcelItemModel,
  InstallmentModel,
} from 'models/CM/ManageContractModels';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaHistory,
  FaPlus,
  FaSave,
  FaTrash,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  thaiFormatDateWithSlash,
  THCurrency,
} from 'utils';
import { Context } from '.';
import {
  approveContract,
  dataHistory,
} from '../data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const { data, setData } = useContext(Context);
  const [installmentData, setInstallmentData] = useState<InstallmentModel>({} as InstallmentModel);
  const [deliveryList, setDeliveryList] = useState<DeliveryItemModel[]>([]);
  const [showSearchNameModal, setModalSearchNameModal] = useState(false);

  useEffect(() => {
    if (data) {
      setInstallmentData(data);
      if (data.deliveryLists && data.deliveryLists.length > 0) {
        setDeliveryList(data.deliveryLists);
      }
    }
  }, [data]);

  const onSubmitDeliveryModal = (deliveryDate: Date, items: deliveryParcelItemModel[]) => {
    const newData: DeliveryItemModel = {
      id: '',
      sequence: deliveryList.length + 1,
      deliveryDate,
      deliveryParcels: items,
      remark: '',
    };

    const installData = installmentData;
    const parcelDatas = data.parcels;
    items.forEach(item => {
      const index = parcelDatas.findIndex(x => x.description === item.description);
      parcelDatas[index].deliveryQuantity = item.deliveryQty;
    });

    installData.parcels = parcelDatas;
    setInstallmentData(installData);
    setDeliveryList((deliveryList) => [...deliveryList, newData]);
  };

  const removeItem = (i: number) => {
    const temp: DeliveryItemModel[] = [...deliveryList];
    temp.splice(i, 1);
    setDeliveryList(temp);
  };

  const onNext = () => {
    setData(installmentData);
    onClickNext();
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex justify-content-between mb-3'>
          <Title text='บันทึกตรวจรับ'
            className='fs-5 text-primary' />
          <div className='d-flex gap-2'>
            <ContractStatus
              value='Delivered' />
            <Button
              className='px-3'
              onClick={() => setShow(true)}
              variant='outline-primary'
            >
              <FaHistory className='me-3' />ประวัติการแก้ไข
            </Button>
          </div>
        </div>
        <Row>
          <Col sm={12}
            xl={3}>
            <p>กำหนดวันส่งมอบงาน
              : <span className='ms-3'>{thaiFormatDateWithSlash(installmentData.deliverySetDate)}</span>
            </p>
          </Col>
          <Col sm={12}
            xl={3}>
            <p>กำหนดวันส่งมอบงาน
              : <span className='ms-3'>{installmentData.deliveryPeriod} {installmentData.deliveryPeriodType}</span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            xl={3}>
            <DatePicker label='วันที่ประชุมตรวจรับ'
              value={installmentData.deliveryDate} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            xl={6}>
            <InputTextArea label='หมายเหตุการตรวจรับ'
              value={installmentData.deliveryRemark} />
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col sm={12}>
            <div className='d-flex justify-content-between mb-3'>
              <Title text='งวดเงิน'
                className='fs-5 text-primary' />
            </div>
            <Table total={1}
              hidePagination
              className='mt-3'>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 100 }}>งวดที่</th>
                  <th style={{ width: 300 }}>จำนวน (%)</th>
                  <th style={{ width: 300 }}>จำนวนเงิน</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-center'>
                  <td>1</td>
                  <td className='text-center'>{`${installmentData.paidPercent}%`}</td>
                  <td className='text-center'>{THCurrency(installmentData.paidAmount)}</td>
                </tr>
              </tbody>
            </Table>
            <p className='mt-2'>จำนวนเงินค่าปรับ : <span className='ms-3'>{installmentData.fineTotal}</span>
              <span className='ms-3'>จำนวนเงินที่จ่ายคงเหลือ : </span><span className='ms-3'>{THCurrency(installmentData.paidAmount - installmentData.fineTotal)}</span>
            </p>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col sm={12}>
            <div className='d-flex justify-content-between mb-3'>
              <Title text='รายการของพัสดุ'
                className='fs-5 text-primary' />
            </div>
            <div>รายการของพัสดุที่จะซื้อ</div>
            <Table total={1}
              hidePagination
              className='mt-3'>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>ลำดับที่</th>
                  <th style={{ width: 300 }}>รายละเอียด</th>
                  <th style={{ width: 100 }}>จำนวน</th>
                  <th style={{ width: 100 }}>หน่วย</th>
                  <th style={{ width: 100 }}>ประเภท VAT</th>
                  <th style={{ width: 100 }}>ราคาที่ตกลง/<br />ต่อหน่วย</th>
                  <th style={{ width: 100 }}>ราคาที่ตกลง/<br />รวม</th>
                  <th style={{ width: 100 }}>จำนวนที่ต้องส่ง</th>
                  <th style={{ width: 100 }}>จำนวนที่ส่งมอบ</th>
                  <th style={{ width: 150 }}>รายละเอียด</th>
                  <th style={{ width: 100 }}>สถานะ</th>
                </tr>
              </thead>
              <tbody>
                {
                  installmentData.parcels && (
                    installmentData.parcels.map((data, index) => (
                      <tr key={index}>
                        <td className='text-center'>{data.sequence}</td>
                        <td><Input placeholder={data.description}
                          value={data.description}
                          disabled />
                        </td>
                        <td><Input value={data.quantity}
                          disabled
                          textEnd />
                        </td>
                        <td><Selector placeholder={data.unit}
                          value={data.unit}
                          disabled />
                        </td>
                        <td><Selector placeholder={data.vatType}
                          value={data.vatType}
                          disabled />
                        </td>
                        <td><Currency value={data.price}
                          disabled />
                        </td>
                        <td><Currency value={data.price * data.quantity}
                          disabled />
                        </td>
                        <td><Input value={data.deliveryQuantityTotal}
                          disabled
                          textEnd />
                        </td>
                        <td><Input value={data.deliveryQuantityTotal}
                          disabled
                          textEnd />
                        </td>
                        <td className='text-start' />
                        <td className='text-center'>
                          <Badge bg='success'>ส่งมอบครบถ้วน</Badge>
                        </td>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='งานที่ส่งมอบ'
              className='fs-5 text-primary' />
          </div>
          {/* <Button
            variant="primary"
            disabled
          >
            <FaPlus /> เพิ่มรายการส่งมอบ
          </Button> */}
        </div>
        <Table total={1}
          hidePagination
          className='mt-3'>
          <thead>
            <tr className='text-center'>
              <th style={{ width: 10 }}>ลำดับที่</th>
              <th style={{ width: 50 }}>วันที่ส่งมอบ</th>
              <th style={{ width: 200 }}>รายการพัสดุ</th>
              <th style={{ width: 50 }}>จำนวนที่ส่งมอบ</th>
              <th style={{ width: 200 }}>หมายเหตุ</th>
              <th style={{ width: 50 }} />
              <th style={{ width: 50 }} />
            </tr>
          </thead>
          <tbody>
            {
              deliveryList && (
                deliveryList.map((data, index) => (
                  <tr key={index}>
                    <td className='text-center'>{data.sequence}</td>
                    <td className='text-center'>{thaiFormatDateWithSlash(data.deliveryDate)}</td>
                    <td colSpan={3}>
                      {
                        data.deliveryParcels && (
                          data.deliveryParcels.map((item, i) => (
                            <Row className='justify-content-end'
                              key={index}>
                              <Col xs={4}>{i + 1}. {item.description}</Col>
                              <Col className='text-end'
                                xs={4}>{item.quantity}
                              </Col>
                              <Col xs={4}>{item.remark}</Col>
                            </Row>
                          ))
                        )
                      }
                    </td>
                    <td className='text-center'>
                      <Button
                        variant='primary'
                        disabled
                      >
                        แก้ไข
                      </Button>
                    </td>
                    <td className='text-center'>
                      <Button
                        variant='danger'
                        disabled
                      >
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))
              )
            }
          </tbody>
        </Table>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <Title text='แจ้งรับทราบ'
            className='fs-5 text-primary' />
          <Button
            variant='outline-primary'
            onClick={() => setModalSearchNameModal(!showSearchNameModal)}>
            <FaPlus className='me-1' />เพิ่มรายชื่อ
          </Button>
        </div>
        <Table total={1}
          hidePagination>
          <thead>
            <tr className='text-center'>
              <th style={{ width: 10 }}>ลำดับ</th>
              <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
              <th style={{ width: 250 }}>ฝ่าย/สำนัก</th>
              <th style={{ width: 250 }}>ตำแหน่ง</th>
              <th style={{ width: 250 }}>สถานะ</th>
              <th style={{ width: 250 }}>วันทีรับทราบ</th>
            </tr>
          </thead>
          <tbody>
            {approveContract.map((data, index) => (
              <tr className='text-center'
                key={index}>
                <td>{index + 1}</td>
                <td>{data.userName}</td>
                <td>{data.department}</td>
                <td>{data.positionOnBoardName}</td>
                <td><TorStatusDetail value='Approved' /></td>
                <td>{thaiFormatDateWithSlash(new Date())}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between gap-3 pt-3'>
        <Button
          onClick={onClickBack}
          className='me-2 px-3'
          variant='outline-primary'
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          <Button
            variant='success'
            className='me-2 px-3'
          >
            <FaSave className='me-2 pb-1 fs-5' />
            บันทึก
          </Button>
          <Button
            className='me-2 px-3'
          >
            บันทึกตรวจรับ
          </Button>
        </div>
        <Button
          variant='outline-primary'
          onClick={onClickNext}
          className='me-2 px-3'
        >
          ถัดไป
          <MdArrowForward className='ms-2 pb-1 fs-5' />
        </Button>
      </div>
      <HistoryModal
        show={show}
        onHide={() => setShow(!show)}
        data={dataHistory}
      />
      <SearchNameModal
        show={showSearchNameModal}
        onHide={() => setModalSearchNameModal(!showSearchNameModal)}
        data={[]}
      />
    </div>
  );
}

export default Step2;
