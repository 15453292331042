import {
  Input,
  Selector,
  Table,
} from 'components';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEdit,
  FaEraser,
  FaPlus,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { trader } from 'services';
import {
  HttpStatusCode,
  showRemoveModalAsync,
} from 'utils';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';
import toast from 'utils/toast';

type Criteria = {
  type: string,
  entrepreneurType: string,
  taxpayerIdentificationNo: string,
  establishmentName: string,
};

type TraderTable = {
  id: string,
  entrepreneurType: string,
  type: string,
  taxpayerIdentificationNo: string,
  establishmentName: string,
};

type Loader = {
  traderList: {
    data: TraderTable[],
    totalRecords: number,
  },
  traderTypes: { label: string, value: string }[],
};

const TYPE = [
  { label: 'นิติบุคคล', value: 'JuristicPerson' },
  { label: 'บุคคลธรรมดา', value: 'Individual' },
];

export default function SU04() {
  const loader = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [criteria, setCriteria] = useState<Criteria>({} as Criteria);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [traders, setTraders] = useState<TraderTable[]>([]);

  useEffect(() => {
    setTraders(loader?.traderList.data);
    setTotalRecords(loader?.traderList.totalRecords);
  }, []);

  const handlerOnSearchAsync = async (size: number, page: number) => {
    await getTraderListAsync(size, page, criteria);
  };

  const clearCriteria = async (size: number, page: number) => {
    setCriteria({} as Criteria);
    const clearCriteria = {} as Criteria;
    await getTraderListAsync(size, page, clearCriteria);
  };

  const removeAsync = async (id: string) => {
    const confirmResult = await showRemoveModalAsync();

    if (confirmResult) {
      const res = await trader.deleteTraderAsync(id);

      if (res.status === HttpStatusCode.OK) {
        toast.success('ลบคู่ค้าสำเร็จ');
        await getTraderListAsync(size, page, criteria);
        return;
      }
      toast.error(res.statusText);
    }
  };

  const getTraderListAsync = async (size: number, page: number, criteria: Criteria) => {
    const res = await trader.getTraderListAsync(
      size,
      page,
      criteria.type,
      criteria.entrepreneurType,
      criteria.taxpayerIdentificationNo,
      criteria.establishmentName,
    );

    setTraders(res.data.data);
    setTotalRecords(res.data.totalRecords);
  };

  const getType = (value: string) => {
    const type = TYPE.find((t) => t.value === value);

    if (type) {
      return type.label;
    }

    return null;
  };

  return (
    <div className="m04">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">ข้อมูลคู่ค้า</h4>
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={() => navigate('detail')}
        >
          <FaPlus />เพิ่มข้อมูลคู่ค้า
        </Button>
      </div>
      <hr />
      <div className="criteria">
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="ประเภทผู้ประกอบการ"
              value={criteria.type}
              onChange={(val) => setCriteria({ ...criteria, type: val.toString() })}
              items={TYPE}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="ประเภท"
              value={criteria.entrepreneurType}
              onChange={(val) => setCriteria({ ...criteria, entrepreneurType: val.toString() })}
              items={loader.traderTypes}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="เลขประจำตัวผู้เสียภาษีอากร/เลขประจำตัวประชาชน"
              value={criteria.taxpayerIdentificationNo}
              onChange={(val) => setCriteria({ ...criteria, taxpayerIdentificationNo: val })}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="ชื่อบริษัท/ชื่อ - นามสกุล"
              value={criteria.establishmentName}
              onChange={(val) => setCriteria({ ...criteria, establishmentName: val })}
            />
          </Col>
          <div className="d-flex gap-2">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={() => handlerOnSearchAsync(size, page)}
            >
              <FaSearch />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              className="d-flex align-items-center gap-2"
              onClick={() => clearCriteria(size, page)}
            >
              <FaEraser />ล้าง
            </Button>
          </div>
        </Row>
      </div>
      <Table
        className="mt-4"
        total={totalRecords}
        onChange={(size, page) => (setSize(size), setPage(page), getTraderListAsync(size, page, criteria))}
      >
        <thead>
          <tr>
            <th style={{ minWidth: 75 }}>ลำดับ</th>
            <th style={{ minWidth: 200 }}>ประเภท</th>
            <th style={{ minWidth: 200 }}>ประเภทผู้ประกอบการ</th>
            <th style={{ minWidth: 100 }}>เลขประจำตัวผู้เสียภาษีอากร/<br />เลขประจำตัวประชาชน</th>
            <th style={{ minWidth: 300 }}>ชื่อบริษัท/ชื่อ - นามสกุล</th>
            <th style={{ minWidth: 60 }} />
          </tr>
        </thead>
        <tbody>
          {traders?.map((m, i) => (
            <tr key={m.id}>
              <td className="text-center">{calculateRowNumber(i, page, size)}</td>
              <td>{getType(m.type)}</td>
              <td>{m.entrepreneurType}</td>
              <td className="text-center">{m.taxpayerIdentificationNo}</td>
              <td className="text-wrap">{m.establishmentName}</td>
              <td className="text-end">
                <Button
                  className="mx-2"
                  variant="outline-primary"
                  onClick={() => navigate(`detail/${m.id}`)}
                >
                  <FaEdit className="me-2 mb-1" />แก้ไข
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => removeAsync(m.id)}
                >
                  <FaTrashAlt className="me-2 mb-1" />ลบ
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
