import { Breadcrumb } from 'components';
import StepProgress from 'components/StepProgress';
import { useRef } from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

const BREADCRUMB_INFO = [
  {
    routerName: '/pr/pr0204', label: 'จัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้า (จพ.006)',
  },
  {
    routerName: '/pr/pr0204/detail', label: 'รายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง',
  },
];

function AddPR0204() {
  const childRef = useRef<ProgressBarRefType>(null);
  const obj = [{
    title: <p>กำหนดรูปแบบเอกสาร
      รายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง
    </p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p>อำนาจอนุมัติ</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>ตัวอย่าง
      เอกสารรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง
    </p>,
    displayIndex: '3',
    child: <Step3 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/pr/pr0204');
  };

  return (
    <div className="document">
      <h4 className="mt-2 mb-0 text-primary text-start">รายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12} className="mb-2">
          <StepProgress progressBarData={obj} ref={childRef} />
        </Col>
      </Row>
    </div>
  );
}

export default AddPR0204;
