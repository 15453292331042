import {
  Breadcrumb,
  Card,
  Check,
  Input,
} from 'components';
import { WorkGroupRequest } from 'models';
import {
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import workGroup from 'services/workGroup';
import {
  HttpStatusCode,
  submitForm,
} from 'utils';
import toast from 'utils/toast';

const BREADCRUMB_INFO = [
  {
    routerName: '/su/su01', label: 'กลุ่มงาน',
  },
  {
    routerName: '', label: 'กำหนดกลุ่มงาน',
  },
];

export default function SU01Detail() {
  const [workGroupData, setWorkGroupData] = useState<WorkGroupRequest>({ active: true } as WorkGroupRequest);
  const { id } = useParams();
  const navigate = useNavigate();

  const backToIndex = () => navigate('/su/su01');

  const getDataByIdAsync = useCallback(async (id: string) => {
    const response = await workGroup.getWorkGroupDetailAsync(id);

    setWorkGroupData(response.data);
  }, []);

  useEffect(() => {
    if (id) {
      getDataByIdAsync(id);
    }
  }, [id]);

  const handleOnSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();
    submitForm();
    if (workGroupData.code && workGroupData.name) {
      createOrUpdateSetGroupWorkAsync();
    }
  };

  const createOrUpdateSetGroupWorkAsync = async () => {
    const data: WorkGroupRequest = workGroupData;

    if (!id) {
      const response = await workGroup.createWorkGroupAsync(data);

      if (response.status === HttpStatusCode.CREATED) {
        backToIndex();
        toast.success('บันทีกข้อมูลสำเร็จ');
        return;
      }

      toast.error('รหัสกลุ่มงานซ้ำ');
      return;
    }

    const response = await workGroup.updateWorkGroupAsync(id, data);

    if (response.status === HttpStatusCode.ACCEPTED) {
      backToIndex();
      toast.success('แก้ไขข้อมูลสำเร็จ');
    }
  };

  return (
    <div className="m01">
      <Form onSubmit={handleOnSubmit}>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mt-2 text-primary">
            <Button variant="link" onClick={backToIndex}>
              <MdOutlineArrowBack className="fs-4 text-primary mb-1" />
            </Button>
            กลุ่มงาน
          </h4>
          <Button type="submit" variant='success'>บันทึก</Button>
        </div>
        <Breadcrumb data={BREADCRUMB_INFO} />
        <div className="mt-4">
          <Card>
            <div className="criteria">
              <Row>
                <Col sm={12} md={6} lg={3}>
                  <Input
                    disabled={id !== undefined}
                    label="รหัสกลุ่มงาน"
                    placeholder="รหัสกลุ่มงาน"
                    rule={{ required: true }}
                    value={workGroupData.code}
                    onChange={(value) => setWorkGroupData({ ...workGroupData, code: value })}
                  />
                </Col>
                <Col sm={12} md={6} lg={3}>
                  <Input
                    label="ชื่อกลุ่มงาน"
                    placeholder="ชื่อกลุ่มงาน"
                    rule={{ required: true }}
                    value={workGroupData.name}
                    onChange={(value) => setWorkGroupData({ ...workGroupData, name: value })}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mt-2">
                  <Check label="ใช้งาน" value={workGroupData.active} onChange={(value) => setWorkGroupData({ ...workGroupData, active: value })} />
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Form>
    </div>
  );
}
