import Title from 'components/Controls/Title';
import {
  useContext,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Row,
} from 'react-bootstrap';
import { FaAlignLeft } from 'react-icons/fa';
import {
  MdArrowBack,
  MdHistory,
  MdSave,
} from 'react-icons/md';
import {
  useNavigate,
  useParams,
} from 'react-router';
import { termOfRef } from 'services';
import {
  HttpStatusCode,
  PREPROCUREMENT_STATUS_TEXT,
} from 'utils';
import toast from 'utils/toast';
import ModalTORHistory from '../ModalTORHistory';
import { CreateTorContext } from './index';
import { ButtonCustom } from 'components';

interface Props {
  onClickBack: () => void;
}

function TORDetailStep4(props: Props) {
  const [showTORHistoryModal, setShowTORHistoryModal] = useState(false);
  const navigate = useNavigate();
  const createTorData = useContext(CreateTorContext);
  const { id } = useParams();

  const onSavaDraft = () => {
    if (id) {
      updateTorAsync(PREPROCUREMENT_STATUS_TEXT.DRAFT, id);

      return;
    }

    createTorAsync(PREPROCUREMENT_STATUS_TEXT.DRAFT);
  };

  const sendApprove = () => {
    if (id) {
      updateTorAsync(PREPROCUREMENT_STATUS_TEXT.SUBMIT, id);

      return;
    }

    createTorAsync(PREPROCUREMENT_STATUS_TEXT.SUBMIT);
  };

  const createTorAsync = async (status: PREPROCUREMENT_STATUS_TEXT) => {
    const response = await termOfRef.createTorsAsync(createTorData, status);

    if (response.status === HttpStatusCode.CREATED) {
      toast.success('บันทึกข้อมูลสำเร็จ');

      navigate(`/pp/pp0204/detail/${response.data.id}`);

      if (status !== PREPROCUREMENT_STATUS_TEXT.DRAFT) {
        toast.success('ส่งอนุมัติสำเร็จ');

        navigate('/pp/pp0204');
      }
    }
  };

  const updateTorAsync = async (status: PREPROCUREMENT_STATUS_TEXT, id: string) => {
    const response = await termOfRef.updateTorAsync(createTorData, status, id);

    if (response.status === HttpStatusCode.NO_CONTENT) {
      if (status !== PREPROCUREMENT_STATUS_TEXT.DRAFT) {
        toast.success('แก้ไขข้อมูลสำเร็จ');

        navigate('/pp/pp0204');

        return;
      }

      toast.success('แก้ไขข้อมูลสำเร็จ');
    }
  };

  return (
    <>
      <Card className="mt-3">
        <Card.Body>
          <Title
            text="ตัวอย่างเอกสารร่างขอบเขตของงาน (TOR)"
            extraElement={(
              <Button variant="light" onClick={() => { setShowTORHistoryModal(true); }}>
                <MdHistory /> ประวัติการแก้ไข
              </Button>
            )}
            className="fs-5 text-primary"
          />
          <Row className="mt-3">
            <Col>
              <Card style={{ height: 500 }} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-center pt-5 gap-3">
        <Button onClick={props.onClickBack} variant="light"><MdArrowBack /> ย้อนกลับ</Button>
        <Button variant="light" onClick={onSavaDraft}><MdSave /> บันทึก</Button>
        <Button variant="primary" type="submit" onClick={sendApprove}><FaAlignLeft /> เสร็จสิ้น</Button>

        <ButtonCustom
          onClick={props.onClickBack}
          text="ย้อนกลับ"
        />
        <ButtonCustom
          onClick={onSavaDraft}
          text="บันทึก"
        />
        <ButtonCustom
          onClick={sendApprove}
          icon='checked'
          text="เสร็จสิ้น"
        />
      </div>
      <ModalTORHistory show={showTORHistoryModal} setShow={setShowTORHistoryModal} />
    </>
  );
}

export default TORDetailStep4;
