import {
  ButtonCustom,
  Card,
  ContractReceiveStatus,
  InputTextArea,
  Modal,
  SearchBuNameModal,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { StatusCMReceived } from 'components/StatusCMReceived';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import {
  AcceptorModel,
  AttachmentFileModel,
  updateAssignsModel,
} from 'models/CM/CM03Models';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import CM03Service from 'services/CM/CM03Service';
import {
  fullDatetime,
  fullDatetimeTH,
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { generateUniqueId, isNull } from '../../../../utils/helper';
import { ApproveModal } from '../Modal/ApproveModal';
import { EditModal } from '../Modal/EditModal';
import { HistoryModal } from '../Modal/HistoryReceiveModal';
import UploadFile from '../UploadFileReceive';
import { Context } from './step';
import { shareValue } from 'services';
import { JorPorCode } from 'constant/basicInformation';
import { CollaboraRef } from 'components/Document/Collabora';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  contractId: string;
}

enum level {
  level_0 = 0,
  level_1 = 1,
}

function Step3({ onClickNext, onClickBack, contractId }: Props) {
  const navigate = useNavigate();
  const { data, setData } = useContext(Context);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showReceiveAssignsLeve0, setShowReceiveAssignsLeve0] = useState(false);
  const [showReceiveAssignsLeve1, setShowReceiveAssignsLeve1] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const { userId, departmentCode } = useAppContext();
  const [assignsDataLevel0, setAssignsDataLevel0] = useState<AcceptorModel[]>([]);
  const [assignsDataLevel1, setAssignsDataLevel1] = useState<AcceptorModel[]>([]);
  const location = useLocation();
  const [showWaitForJorPorApproveModal, setShowWaitForJorPorApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [isQueueAssignLevel0, setIsQueueAssignLevel0] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [docId, setDocId] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);

  const getRequestApprovalFineDocument = useCallback(async (planId: string) => {

    const { data: docId, status, } = await CM03Service.getRequestApprovalFineDocumentAsync(planId);

    if (status === HttpStatusCode.OK) {
      setDocId(docId);
    }

  }, []);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  useEffect(() => {
    if (data) {

      //getRequestApprovalFineDocument(data.id);
      if (data.status === ContractReceiveStatus.InspectorReceived) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }

      if (data.contractManagementReceiveAssigns) {
        setAssignsDataLevel0(data.contractManagementReceiveAssigns.filter((x) => x.level === level.level_0));
        setAssignsDataLevel1(data.contractManagementReceiveAssigns.filter((x) => x.level === level.level_1));

        const queueApprover = onCheckQueueApprover(data.contractManagementReceiveAssigns.filter((x) => x.level === level.level_1));
        setIsQueueApprover(queueApprover);

        const queueAssign = onCheckQueueApprover(data.contractManagementReceiveAssigns.filter((x) => x.level === level.level_0));
        setIsQueueAssignLevel0(queueAssign);
      }

      if (data.attachments) {
        handleSetFile(data.attachments);
      }
    }
  }, [data, userId]);

  const onCheckQueueApprover = (datas: AcceptorModel[]) => {
    if (datas && data.status === ContractReceiveStatus.WaitForJorPorApprove || data.status === ContractReceiveStatus.InspectorReceived) {
      const sectionDatas = datas?.filter((a) => (a.status === ContractReceiveStatus.Pending || a.status === ContractReceiveStatus.Draft) && !a.isAvailable).sort((a, b) => a.sequence - b.sequence);
      if (sectionDatas && sectionDatas.length > 0) {
        return isNull(sectionDatas[0].delegateUserId, sectionDatas[0].userId) === userId;
      }
      return false;
    }
    return false;
  };

  const onSelectEmpLevel0 = (empId: string, name: string, position: string, department: string, index: number, level: number) => {
    if (assignsDataLevel0 && assignsDataLevel0.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกเจ้าหน้าที่ซ้ำ');
    } else {
      const newData: AcceptorModel = {
        id: undefined,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        status: '',
        sequence: assignsDataLevel0.length + 1,
        level,
        employeeCode: '',
        isAvailable: false,
        sectionInRefCode: '',
        sectionName: '',
        sectionSequence: undefined,
        delegateDepartmentName: '',
        delegateEmployeeCode: '',
        delegateFullName: '',
        delegatePositionName: '',
        delegateUserId: '',
        seqKey: undefined,
        acceptDate: undefined,
        acceptRemark: '',
        availableRemark: '',
        rejectDate: undefined,
        rejectRemark: '',
      };

      setAssignsDataLevel0((assignsDataLevel0) => [...assignsDataLevel0, newData]);
    }
  };

  const onSelectEmpLevel1 = (empId: string, name: string, position: string, department: string, index: number, level: number, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string, delegatePositionName?: string) => {
    if (assignsDataLevel1 && assignsDataLevel1.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกเจ้าหน้าที่ซ้ำ');
    } else {
      const newData: AcceptorModel = {
        id: undefined,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        status: '',
        sequence: assignsDataLevel1.length + 1,
        level,
        employeeCode: '',
        isAvailable: false,
        sectionInRefCode: '',
        sectionName: '',
        sectionSequence: undefined,
        delegateDepartmentName: delegateDepartmentName,
        delegateEmployeeCode: delegateEmployeeCode,
        delegateFullName: delegateFullName,
        delegatePositionName: delegatePositionName,
        delegateUserId: delegateUserId,
        seqKey: undefined,
        acceptDate: undefined,
        acceptRemark: '',
        availableRemark: '',
        rejectDate: undefined,
        rejectRemark: '',
      };

      setAssignsDataLevel1((assignsDataLevel1) => [...assignsDataLevel1, newData]);
    }
  };

  const handlerRemoveAssignsDataLevel0 = (index: number) => {
    const temp: AcceptorModel[] = [...assignsDataLevel0];
    temp.splice(index, 1);
    setAssignsDataLevel0(temp);
  };

  const handlerRemoveAssignsDataLevel1 = (index: number) => {
    const temp: AcceptorModel[] = [...assignsDataLevel1];
    temp.splice(index, 1);
    setAssignsDataLevel1(temp);
  };

  const onSubmitAsync = async (isAssignJorPor: boolean) => {
    submitForm();

    if ((!assignsDataLevel0 || assignsDataLevel0.length === 0)) {
      toast.warn('กรุณากรอกข้อมูล ผอ.จพ. มอบหมาย');
      return;
    }

    if ((!assignsDataLevel1 || assignsDataLevel1.length === 0)) {
      toast.warn('กรุณากรอกข้อมูลเจ้าหน้าที่พัสดุให้ความเห็น');
      return;
    }

    const assignsDataList: AcceptorModel[] = [];

    assignsDataLevel0?.forEach((item, index) => {
      assignsDataList.push({
        id: item.id,
        departmentName: item.departmentName,
        fullName: item.fullName,
        level: item.level,
        positionName: item.positionName,
        sequence: item.sequence,
        userId: item.userId,
        employeeCode: item.employeeCode,
        isAvailable: item.isAvailable,
        sectionInRefCode: item.sectionInRefCode,
        sectionName: item.sectionName,
        sectionSequence: item.sectionSequence,
        status: item.status,
        delegateDepartmentName: item.delegateDepartmentName,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegatePositionName: item.delegatePositionName,
        delegateUserId: item.delegateUserId,
      } as AcceptorModel);
    });

    assignsDataLevel1?.forEach((item, index) => {
      assignsDataList.push({
        id: item.id,
        departmentName: item.departmentName,
        fullName: item.fullName,
        level: item.level,
        positionName: item.positionName,
        sequence: item.sequence,
        userId: item.userId,
        employeeCode: item.employeeCode,
        isAvailable: item.isAvailable,
        sectionInRefCode: item.sectionInRefCode,
        sectionName: item.sectionName,
        sectionSequence: item.sectionSequence,
        status: item.status,
        delegateDepartmentName: item.delegateDepartmentName,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegatePositionName: item.delegatePositionName,
        delegateUserId: item.delegateUserId,
      } as AcceptorModel);
    });

    if (data && data.id && assignsDataList.length > 0) {
      const updateDataModel: updateAssignsModel = {
        contractManagementReceiveAssigns: assignsDataList,
      };

      const res = await CM03Service.updateAssignsAsync(data.id, updateDataModel);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        if (isAssignJorPor) {
          const res = await CM03Service.waitForJorPorApproveReceivedAsync(data.id);
          toast.success('ส่งเจ้าหน้าที่พัสดุให้ความเห็นสำเร็จ');
          setShowWaitForJorPorApproveModal(false);
        } else {
          toast.success('บันทึกข้อมูลสำเร็จ');
        }

        getContractManagementAsync(contractId, data.id);
      } else {
        toast.error(res.statusText);
      }
    }
  };

  const getContractManagementAsync = useCallback(async (contractId: string, receiveId: string) => {
    const { data, status } = await CM03Service.getContractManagementReceiveByIdAsync(contractId, receiveId);

    if (status === HttpStatusCode.OK) {
      setData(data);
      return;
    }
  }, []);

  const onApproved = async (remark: string) => {

    submitForm();

    if (!data.jorPorComment) {
      return;
    }

    const response = await CM03Service.jorPorApprovedAsync(data.id, remark, data.jorPorComment);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.CREATED) {
      toast.success('ให้ความเห็นสำเร็จ');
      getContractManagementAsync(contractId, data.id);
    }

    setShowApproveModal(false);
  };

  const onReject = async (remark: string) => {
    submitForm();

    if (!remark) {
      toast.warn('กรุณากรอกหมายเหตุ');
      return;
    }

    const response = await CM03Service.jorPorRejectAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.CREATED) {
      toast.success('ส่งกลับแก้ไขสำเร็จ');
      getContractManagementAsync(contractId, data.id);
      setShowRejectModal(false);
    }
  };

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await CM03Service.uploadAttachmentReceiveAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getAttachmentAsync(contractId, data.id);
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {
    if (data && data.id) {
      const res = await CM03Service.removeAttachmentReceiveAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getAttachmentAsync(contractId, data.id);
      }
    }
  }, [files, data.id]);

  const getAttachmentAsync = useCallback(async (contractId: string, receiveId: string) => {
    const { data, status } = await CM03Service.getContractManagementReceiveByIdAsync(contractId, receiveId);

    if (status === HttpStatusCode.OK) {

      const files: IFile[] = [];
      data.attachments?.forEach((item) => {
        files.push({
          id: item.id,
          name: item.fileName,
          file: null,
        });
      });

      setFiles(files);

      return;
    }
  }, []);

  return (
    <div className='document'>
      <Title text='เจ้าหน้าที่พัสดุให้ความเห็น'
        extraElement={(
          <>
            <StatusCMReceived systemStatus={data.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </>
        )} />
      <Card className='mt-3'>
        <Row className='align-items-center'>
          <div className='d-flex justify-content-between'>
            <Title text='ผอ.จพ. มอบหมาย'
              className='fs-5 text-primary' />
            {
              (!isDisabled && isQueueAssignLevel0) && (
                <ButtonCustom
                  text='เพิ่มรายชื่อ'
                  icon='add'
                  onClick={() => setShowReceiveAssignsLeve0(true)}
                />
              )
            }
          </div>
          <Col sm={12}>
            <Table
              hidePagination
              className='mt-1 step-bar'
              notResponse
            >
              <thead>
                <tr>
                  <th className='text-center' style={{ width: 100 }}>ลำดับ</th>
                  <th className='text-start' style={{ width: '60%' }}>ชื่อ-นามสกุล</th>
                  <th className='text-start' style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน</th>
                  <th style={{ width: 100 }} />
                </tr>
              </thead>
              <tbody>
                {assignsDataLevel0?.sort((a, b) => a.sequence - b.sequence).map((data, index) => (
                  <tr className='text-start' key={generateUniqueId(data.id ?? index)}>
                    <td className='text-center'>{index + 1}</td>
                    <td className='text-left'>
                      {data.fullName}
                      <p className='m-0 department'>
                        {data.positionName}
                        {/* {data.departmentName &&
                        <>
                          <span className='mx-1'>|</span> {data.departmentName}
                        </>
                      } */}
                      </p>
                    </td>
                    <td className='text-left'>
                      {data.delegateFullName &&
                        <>
                          {data.delegateFullName}
                          <p className='m-0 department'>
                            {data.delegatePositionName}
                          </p>
                        </>
                      }
                    </td>
                    <td className='text-end'>
                      {(isQueueAssignLevel0) && (
                        <Button variant='danger'
                          onClick={() => handlerRemoveAssignsDataLevel0(index)}
                          disabled={isDisabled}
                        ><FaTrash />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className='mt-4 align-items-center'>
          <div className='d-flex justify-content-between'>
            <Title text='เจ้าหน้าที่พัสดุให้ความเห็น'
              className='fs-5 text-primary' />
            {(!isDisabled && (isQueueAssignLevel0 || (isQueueApprover && data.status === ContractReceiveStatus.WaitForJorPorApprove))) && (
              <ButtonCustom
                text='เพิ่มรายชื่อ'
                icon='add'
                onClick={() => setShowReceiveAssignsLeve1(true)} />
            )}
          </div>
          <Col sm={12}>
            <Table
              hidePagination
              className='mt-3 step-bar'
              notResponse
            >
              <thead>
                <tr>
                  <th style={{ width: 20 }}>ลำดับ</th>
                  <th className='text-start' style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
                  <th className='text-start' style={{ width: '30%' }}>ผู้ปฏิบัติงานแทน</th>
                  <th style={{ width: 100 }}>สถานะ</th>
                  <th style={{ width: 100 }}>วันที่เห็นชอบ</th>
                  <th style={{ width: 100 }} />
                </tr>
              </thead>
              <tbody>
                {assignsDataLevel1?.sort((a, b) => a.sequence - b.sequence).map((data, index) => (
                  <>
                    <tr>
                      <td className='text-center'>{index + 1}</td>
                      <td className='text-left'>
                        {data.fullName}
                        <p className='m-0 department'>
                          {data.positionName}
                          {/* {data.departmentName &&
                          <>
                            <span className='mx-1'>|</span> {data.departmentName}
                          </>
                        } */}
                        </p>
                      </td>
                      <td className='text-left'>
                        {data.delegateFullName
                          && (
                            <>
                              {data.delegateFullName}
                              <p className='m-0 department'>
                                {data.delegatePositionName}
                                {/* {data.delegateDepartmentName &&
                              <>
                                <span className='mx-1'>|</span> {data.delegateDepartmentName}
                              </>
                            } */}
                              </p>
                            </>
                          )}
                      </td>
                      <td className='text-center'>
                        {data.status && <StatusCMReceived systemStatus={data.status} systemName='cm-03' />}
                      </td>
                      <td className='text-center'>
                        {fullDatetimeTH(data.acceptDate!)}
                      </td>
                      <td className='text-end'>
                        {(isQueueAssignLevel0 || (isQueueApprover && data.status === ContractReceiveStatus.WaitForJorPorApprove)) && (
                          <Button
                            onClick={() => handlerRemoveAssignsDataLevel1(index)}
                            variant='danger'
                            disabled={isDisabled}
                          >
                            <FaTrash />
                          </Button>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td colSpan={6} className='p-0'>
                        <ul className='step-bar-list'>
                          {data.isAvailable
                            && (
                              <li>
                                <div className='description'>
                                  <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                  <p className='mb-0' style={{ color: 'gray' }}>
                                    {data.availableRemark}
                                  </p>
                                </div>
                              </li>
                            )}
                          {data.acceptRemark
                            && (
                              <li>
                                <div className='description'>
                                  <p className='mb-0 text-primary'>หมายเหตุความเห็น
                                  </p>
                                  <p className='mb-0' style={{ color: 'gray' }}>
                                    <span className='text-muted me-2'>[{data.acceptDate ? fullDatetime(data.acceptDate) : ''}]</span>
                                    {data.acceptRemark}
                                  </p>
                                </div>
                              </li>
                            )}
                          {data.rejectRemark
                            && (
                              <li>
                                <div className='description'>
                                  <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                  <p className='mb-0' style={{ color: 'gray' }}>
                                    <span className='text-muted me-2'>[{data.rejectDate ? fullDatetime(data.rejectDate) : ''}]</span>
                                    {data.rejectRemark}
                                  </p>
                                </div>
                              </li>
                            )}
                        </ul>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        {
          data.status !== ContractReceiveStatus.InspectorReceived
            ? <Row>
              <div className='d-flex mb-4 align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Title text='ความเห็น จพ.'
                    className='fs-5 text-primary' />
                </div>
              </div>
              <div>
                <InputTextArea
                  label='ความเห็นเพิ่มเติม'
                  rule={{ required: true }}
                  value={data.jorPorComment}
                  disabled={data.status !== ContractReceiveStatus.WaitForJorPorApprove}
                  onChange={(val) => setData({ ...data, jorPorComment: val })} />
              </div>
              {/* <div className='d-flex justify-content-between align-items-center gap-3 mt-3'>
            <div className='d-flex justify-content-between align-items-center gap-3'>
              <Title text='ตัวอย่างเอกสารขออนุมัติค่าปรับ'
                className='fs-5 text-primary' />
            </div>
          </div>
          <div className='mt-3'>
            <Collabora
              docId={docId}
              docName='doc-approval-fine'
              ref={collaboraRef}
              readonly={false} />
          </div> */}
            </Row>
            : <div />
        }

      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5' />
        <Row className='justify-content-center'>
          <Col sm={12} md={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.id!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <ButtonCustom text='ย้อนกลับ' onClick={onClickBack} icon='arrowback' variant='outline-primary' />
        </div>
        <div className='d-flex gap-3 align-items-center'>

          {
            (isQueueAssignLevel0 && data.status === ContractReceiveStatus.InspectorReceived) && (
              <>
                <ButtonCustom text='บันทึก'
                  variant='outline-primary'
                  onClick={() => onSubmitAsync(false)}
                />
                <ButtonCustom text='ส่งเจ้าหน้าที่พัสดุให้ความเห็น' onClick={() => setShowWaitForJorPorApproveModal(true)} variant='outline-primary' />
              </>
            )
          }

          {
            (isQueueApprover && data.status === ContractReceiveStatus.WaitForJorPorApprove) && (
              <>
                <ButtonCustom text='ส่งกลับแก้ไข' icon="undo" variant='danger' onClick={() => setShowRejectModal(true)} />
                <ButtonCustom text='ยืนยันความเห็น' onClick={() => setShowApproveModal(true)} />
              </>
            )
          }

        </div>

        {
          (data.status && data.status !== ContractReceiveStatus.InspectorReceived) ? (
            <div>
              <ButtonCustom text='ถัดไป' onClick={onClickNext} icon='arrowforward' variant='outline-primary' iconAlignRight />
            </div>
          ) : <div style={{ width: 145 }} />
        }

      </div>

      <SearchBuNameModal
        total={userTotal}
        show={showReceiveAssignsLeve0}
        onHide={() => setShowReceiveAssignsLeve0(!showReceiveAssignsLeve0)}
        onSelect={(id, name, department, position) => onSelectEmpLevel0(id, name, position!, department!, assignsDataLevel0.length + 1, level.level_0)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <SearchBuNameModal
        total={userTotal}
        show={showReceiveAssignsLeve1}
        onHide={() => setShowReceiveAssignsLeve1(!showReceiveAssignsLeve1)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode,
          delegateDepartmentName, delegatePositionName) => onSelectEmpLevel1(id, name, position!, department!, assignsDataLevel0.length + 1, level.level_1, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      {/* <UserAssigneesModal
        show={showReceiveAssignsLeve0}
        onHide={() => setShowReceiveAssignsLeve0(!showReceiveAssignsLeve0)}
        onSelectItem={onSelectEmpLevel0}
        level={level.level_0}
      />

      <UserAssigneesModal
        show={showReceiveAssignsLeve1}
        onHide={() => setShowReceiveAssignsLeve1(!showReceiveAssignsLeve1)}
        onSelectItem={onSelectEmpLevel1}
        level={level.level_1}
      /> */}

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={data.id}
        activities={data.activities}
        title='การตรวจรับ'
        status={ContractReceiveStatus.Received}
      />

      <EditModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(!showRejectModal)}
        onRemark={onReject}
      />

      <ApproveModal
        show={showApproveModal}
        onRemark={onApproved}
        click={() => setShowApproveModal(!showApproveModal)}
      />

      <Modal
        show={showWaitForJorPorApproveModal}
        size='lg'
        onHide={() => setShowWaitForJorPorApproveModal(!showWaitForJorPorApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งเจ้าหน้าที่พัสดุให้ความเห็น หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitForJorPorApproveModal(!showWaitForJorPorApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step3;
