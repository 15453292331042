import {
  Card,
  Input,
  InputTextArea,
  ProcessStatus,
  Selector,
  Status,
  StatusType,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
  Table,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function GetStatus(i: number) {
  switch (i) {
    case 1:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.PENDING} />;
    case 2:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.APPROVED} />;
    case 3:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.REJECTED} />;
    default:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.DRAFT} />;
  }
}

function Step1({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลโครงการ" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="เลขที่เอกสาร PR" value="เลขที่เอกสาร PR" disabled />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} md={4}>
            <Selector label="โครงการ" disabled />
          </Col>
          <Col sm={12} md={3}>
            <Button variant="link"><TbListSearch className="fs-4" /> ไปยังแผน</Button>
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="งบประมาณโครงการ (บาท)" value="1,000,000.00" disabled textEnd rule={{ required: true }} />
          </Col>
          <Col sm={12} md={4}>
            <Input label="วงเงิน" value="1,000,000.00" disabled textEnd rule={{ required: true }} />
          </Col>
          <Col sm={12} md={4} className="pt-4">
            <Input value="เจาะจง" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="ข้อมูลรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง (จพ.006)" className="fs-5 text-primary" />
        <Row className="mt-3">
          <Col sm={12}>
            <Input label="เรื่อง" placeholder="เรื่อง" rule={{ required: true }} disabled />
          </Col>
          <Col sm={12} className="mt-3">
            <p>ผู้ได้รับการคัดเลือก</p>
            <Table>
              <thead>
                <tr>
                  <th style={{ minWidth: 20 }}>ลำดับ</th>
                  <th style={{ minWidth: 150 }}>เลขประจำตัวผู้เสียภาษี</th>
                  <th style={{ minWidth: 150 }}>ประเภทผู้ประกอบการ</th>
                  <th style={{ minWidth: 150 }}>ชื่อสถานประกอบการ</th>
                  <th style={{ minWidth: 150 }}>อีเมล</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">1</td>
                  <td className="text-center">8765432119861</td>
                  <td className="text-center">บริษัทจำกัด</td>
                  <td className="text-center">เครื่องไฟฟ้าไทย</td>
                  <td className="text-center">egpmail@gmail.com</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col sm={12} className="mt-3">
            <Input label="เสนอราคาเป็นเงิน" placeholder="1,974,599.97" textEnd disabled />
          </Col>
          <Col sm={12}>
            <InputTextArea label="การพิจารณา" disabled />
          </Col>
          <Col sm={12}>
            <InputTextArea label="ขออนุมัติ" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="สถานะการอนุมัติ" className="fs-5 text-primary" />
        <Row className="mt-3">
          <Col sm={12} className="mt-3">
            <Table>
              <thead>
                <tr className="text-center">
                  <th style={{ minWidth: 20 }}>ลำดับ</th>
                  <th style={{ minWidth: 150 }}>รหัสพนักงาน</th>
                  <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
                  <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
                  <th style={{ minWidth: 50 }}>สถานะ</th>
                  <th style={{ minWidth: 50 }}>วันที่อนุมัติ</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 4 }).map((_, index) => (
                  <tr className="text-center" key={index}>
                    <td>{index + 1}</td>
                    <td>5900010</td>
                    <td>สมศรี แดนไทย</td>
                    <td>รักษาการผู้อำนวยการสำนักงบประมาณ</td>
                    <td>{GetStatus(index)}</td>
                    <td>28-11-2566 16:24:22</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          className="me-2 px-3"
          onClick={onClickBack}
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default Step1;
