import {
  Card,
  Currency,
  Input,
  Modal,
  SearchBuNameModal,
} from 'components';
import Title from 'components/Controls/Title';
import { PurchaseOrderApprovalStatus } from 'components/PurchaseOrderApprovalStatus';
import { JorPorCode } from 'constant/basicInformation';
import {
  AssigneesLevel,
  PurchaseOrderApprovalStatusConstant,
} from 'constant/PurchaseOrderApprovalConstant';
import {
  IFile,
  ItemModel,
  sharedValueResponse,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
} from 'models';
import {
  AcceptorsModel,
  AssigneesModel,
  ContractRecordEntrepreneursModel,
  ContractRecordEntrepreneursRequestModel,
  documentsModel,
} from 'models/PR/PurchaseOrderApprovalModels';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaHistory,
  FaSave,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router';
import { shareValue } from 'services';
import PurchaseOrderApprovalServices from 'services/PR/PurchaseOrderApprovalServices';
import {
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { HistoryModal } from './HistoryModal';
import UploadFile from './UploadFile';
import { Assignee } from 'components/Table/Assignee';
import { AssignLevel } from 'utils/constants/PlanEnum';

type Loader = {
  jorPorDirectorPosition: sharedValueResponse,
};

function PurchaseOrderApprovalAssignee() {
  const { jorPorDirectorPosition } = useLoaderData() as Loader;
  const [showSaveAssignees, setShowSaveAssignees] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showUserAssigneeLevel0, setShowUserAssigneeLevel0] = useState(false);
  const [showUserAssigneeLevel1, setShowUserAssigneeLevel1] = useState(false);
  const { userId, positionCode, departmentCode } = useAppContext();
  const [supplyMethod, setSupplyMethod] = useState('');
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);
  const [isDisabled, setisDisabled] = useState(false);
  const [assigneesDataLevel0, setAssigneesDataLevel0] = useState<AssigneesModel[]>([]);
  const [assigneesDataLevel1, setAssigneesDataLevel1] = useState<AssigneesModel[]>([]);
  const [acceptorsData, setAcceptorsData] = useState<AcceptorsModel[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<ContractRecordEntrepreneursModel>({} as ContractRecordEntrepreneursModel);
  const [poNumberData, setPoNumberData] = useState('');
  const [offerTotalAmountData, setofferTotalAmountData] = useState(0);
  const [files, setFiles] = useState<IFile[]>([]);
  const [isDisabledAssigneesLevel1, setIsDisabledAssigneesLevel1] = useState(false);
  const [isDisabledAssigneesLevel0, setIsDisabledAssigneesLevel0] = useState(false);
  const [isDisabledRemove, setIsDisabledRemove] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const goToPage = (jorPor06Id?: string, id?: string) => {
    if (jorPor06Id) {
      navigate(`/pr/pr0206/detail/${jorPor06Id}/id/${id}`);
    } else {
      navigate(`/pr/pr0206/detail/id/${id}`);
    }
  };

  useEffect(() => {
    if (id) {

      getDataAsync(id);

    }
  }, [id]);

  useEffect(() => {
    if (data) {

      if (data.contractRecordEntrepreneurs?.assignees) {

        if (data.contractRecordEntrepreneurs?.assignees.filter((x) => x.level === AssigneesLevel.Level1 && x.userId === userId || x.delegateUserId === userId).length > 0) {
          setIsDisabledAssigneesLevel0(false);
        } else {
          (
            setIsDisabledAssigneesLevel0(true)
          );
        }

        if (data.contractRecordEntrepreneurs?.assignees.filter((x) => x.level === AssigneesLevel.Level0 && x.userId === userId || x.delegateUserId === userId).length > 0) {
          setIsDisabledRemove(true);
        } else {
          (
            setIsDisabledRemove(false)
          );
        }

        if (data.contractRecordEntrepreneurs?.assignees.filter((x) => x.userId === userId || x.delegateUserId === userId).length > 0 || positionCode === jorPorDirectorPosition.sharedValue.value) {
          setIsDisabledAssigneesLevel1(true);
        } else {
          (
            setIsDisabledAssigneesLevel1(false)
          );
        }

      }

      if (data.contractRecordEntrepreneurs?.status !== PurchaseOrderApprovalStatusConstant.Assigned) {
        setisDisabled(false);
      } else {
        setisDisabled(true);
      }

      if (data.contractRecordEntrepreneurs?.assignees.filter((x) => x.level === AssigneesLevel.Level0)) {
        setAssigneesDataLevel0(data.contractRecordEntrepreneurs?.assignees.filter((x) => x.level === AssigneesLevel.Level0));
      }

      if (data.contractRecordEntrepreneurs?.assignees.filter((x) => x.level === AssigneesLevel.Level1)) {
        setAssigneesDataLevel1(data.contractRecordEntrepreneurs?.assignees.filter((x) => x.level === AssigneesLevel.Level1));
      }

      if (data.contractRecordEntrepreneurs?.poNumber) {
        setPoNumberData(data.contractRecordEntrepreneurs.poNumber);
      }

      if (data.contractRecordEntrepreneurs?.offerTotalAmount) {
        setofferTotalAmountData(data.contractRecordEntrepreneurs.offerTotalAmount);
      }

      if (data.contractRecordEntrepreneurs?.attachmentFiles) {
        handleSetFile(data.contractRecordEntrepreneurs.attachmentFiles);
      }

    }
  }, [data, userId]);

  const isJorporDirectorAssignLv0 = useMemo(() => {

    if (data.contractRecordEntrepreneurs && data.contractRecordEntrepreneurs.assignees.filter((d) => d.level === AssignLevel.Level0 && (d.userId === userId || d.delegateUserId === userId)).length > 0) {

      return true;

    }

    return false;

  }, [data, userId]);


  const isJorporDirectorAssignLv1 = useMemo(() => {

    if (data.contractRecordEntrepreneurs && data.contractRecordEntrepreneurs.assignees.filter((d) => d.level === AssignLevel.Level0 && (d.userId === userId || d.delegateUserId === userId)).length > 0 ||
      data.contractRecordEntrepreneurs && data.contractRecordEntrepreneurs.assignees.filter((d) => d.level === AssignLevel.Level1 && (d.userId === userId || d.delegateUserId === userId)).length > 0) {

      return true;

    }

    return false;

  }, [data, userId]);

  const getDataAsync = async (id?: string) => {

    const res = await PurchaseOrderApprovalServices.getEntrepreneursByIdAsync(id);
    if (res.status === HttpStatusCode.OK) {
      setData(res.data);
    }

  };

  const getSupplyMethodSpecialType = useCallback(async (supplyMethodId: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(supplyMethodId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setSupplyMethodSpecialDDL(data);
  }, [supplyMethod]);

  const handlerRemoveAssigneesDataLevel0 = (index: number) => {
    const temp: AssigneesModel[] = [...assigneesDataLevel0];
    temp.splice(index, 1);
    setAssigneesDataLevel0(temp);
  };

  const handlerRemoveAssigneesDataLevel1 = (index: number) => {
    const temp: AssigneesModel[] = [...assigneesDataLevel1];
    temp.splice(index, 1);
    setAssigneesDataLevel1(temp);
  };

  const onSelectEmpApproverAssigneesDataLevel0 = (empId: string, name: string, position: string, department: string, index: number, level: number) => {
    if (assigneesDataLevel0 && assigneesDataLevel0.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้รับมอบหมายงานซ้ำได้');
    } else if (assigneesDataLevel1 && assigneesDataLevel1.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้รับมอบหมายงานซ้ำกับผู้รับผิดชอบได้');
    } else {
      const newData: AssigneesModel = {
        id: undefined,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        sequence: assigneesDataLevel0.length + 1,
        level: level,
        assignByUserId: '',
      };
      setAssigneesDataLevel0((assigneesDataLevel0) => [...assigneesDataLevel0, newData]);
    }
  };

  const onSelectEmpApproverAssigneesDataLevel1 = (empId: string, name: string, position: string, department: string, index: number, level: number) => {
    if (assigneesDataLevel1 && assigneesDataLevel1.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้รับผิดชอบซ้ำได้');
    } else if (assigneesDataLevel0 && assigneesDataLevel0.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำกับข้อมูล ผอ.จพ. มอบหมายงานไปยังผู้รับผิดชอบได้');
    } else {
      const newData: AssigneesModel = {
        id: undefined,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        sequence: assigneesDataLevel1.length + 1,
        level: level,
        assignByUserId: '',
      };
      setAssigneesDataLevel1((assigneesDataLevel1) => [...assigneesDataLevel1, newData]);
    }
  };

  const onSubmitAsync = async (isAssignToAssignees: boolean) => {
    submitForm();

    const assigneesDataList: AssigneesModel[] = [];

    assigneesDataLevel0?.forEach((item, index) => {
      assigneesDataList.push({
        id: item.id,
        departmentName: item.departmentName,
        fullName: item.fullName,
        level: item.level,
        positionName: item.positionName,
        sequence: item.sequence,
        userId: item.userId,
        assignByUserId: item.assignByUserId,
      } as AssigneesModel);

    });

    assigneesDataLevel1?.forEach((item, index) => {
      assigneesDataList.push({
        id: item.id,
        departmentName: item.departmentName,
        fullName: item.fullName,
        level: item.level,
        positionName: item.positionName,
        sequence: item.sequence,
        userId: item.userId,
        assignByUserId: item.assignByUserId,
      } as AssigneesModel);
    });

    if (data && data.contractRecordEntrepreneurs?.id && assigneesDataList.length > 0) {
      const updateDataModel: ContractRecordEntrepreneursRequestModel = {
        contractRecordFinancialId: data.contractRecordEntrepreneurs.contractRecordFinancialId,
        id: data.contractRecordEntrepreneurs.id,
        inviteEntrepreneursId: data.contractRecordEntrepreneurs.inviteEntrepreneursId,
        sequence: data.contractRecordEntrepreneurs.sequence,
        entrepreneursTaxId: data.contractRecordEntrepreneurs.entrepreneursTaxId,
        entrepreneursName: data.contractRecordEntrepreneurs.entrepreneursName,
        offerTotalAmount: offerTotalAmountData,
        poNumber: poNumberData,
        contractNumber: data.contractRecordEntrepreneurs.contractNumber,
        assignToAssignees: isAssignToAssignees,
        jorPor06Id: data.contractRecordEntrepreneurs.jorPor06Id,
        contractRecordId: data.contractRecordEntrepreneurs.contractRecordId,
        status: data.contractRecordEntrepreneurs.status,
        assignees: assigneesDataList,
        acceptors: acceptorsData,
        acceptorStatus: data.contractRecordEntrepreneurs.acceptorStatus,
        assigneeStatus: data.contractRecordEntrepreneurs.assigneeStatus,
        attachmentFiles: data.contractRecordEntrepreneurs.attachmentFiles,
        files: data.contractRecordEntrepreneurs.files,
      };

      const res = await PurchaseOrderApprovalServices.updateEntrepreneursAssigneesAsync(updateDataModel);

      if (res.status === HttpStatusCode.OK) {
        if (isAssignToAssignees) {
          toast.success('ส่งมอบหมายสำเร็จ');

        } else {
          toast.success('บันทึกข้อมูลสำเร็จ');
        }

        getDataAsync(id);
        // navigate(`/pr/pr0206/purchase-order-approval-assignee/${id}`);
        goToPage(data.contractRecordEntrepreneurs.jorPor06Id, data.contractRecordEntrepreneurs.contractRecordId)
        setShowSaveAssignees(false);
      } else {
        toast.error(res.statusText);
      }
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.contractRecordEntrepreneurs.contractRecordId) {
      const res = await PurchaseOrderApprovalServices.uploadPurchaseOrderApprovalAttachmentAsync(
        data.contractRecordEntrepreneurs.contractRecordId,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getDataAsync(data.contractRecordEntrepreneurs?.id);
      }
    }
  };

  const handleSetFile = (documents: documentsModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {

    if (data && data.contractRecordEntrepreneurs.contractRecordId) {
      const res = await PurchaseOrderApprovalServices.removeFileAsync(
        data.contractRecordEntrepreneurs.contractRecordId,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getDataAsync(data.contractRecordEntrepreneurs?.id);
      }
    }

  }, [files, data.contractRecordEntrepreneurs?.id]);

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <Title
              text='มอบหมายผู้รับผิดชอบสัญญา'
              className='fs-5 text-primary'
            />
          </div>
          <div className='d-flex gap-2'>
            <PurchaseOrderApprovalStatus value={data.contractRecordEntrepreneurs?.status} />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'>
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={3}>
            <Input
              label='เลขที่ใบสั่ง/สัญญา'
              placeholder='เลขที่ใบสั่ง/สัญญา'
              value={data.contractRecordEntrepreneurs?.contractNumber}
              disabled
              variant='primary'
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={3}>
            <Input
              label='เลขที่ผู้เสียภาษี'
              placeholder='เลขที่ผู้เสียภาษี'
              value={data.contractRecordEntrepreneurs?.entrepreneursTaxId}
              disabled
              variant='primary'
            />
          </Col>
          <Col sm={12}
            lg={3}>
            <Input
              label='ชื่อสถานประกอบการ'
              placeholder='ชื่อสถานประกอบการ'
              value={data.contractRecordEntrepreneurs?.entrepreneursName}
              disabled
              variant='primary'
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={3}>
            <Currency
              label='วงเงิน'
              placeholder='วงเงิน'
              value={offerTotalAmountData}
              onChange={(val) => setofferTotalAmountData(Number(val))}
              disabled
            />
          </Col>
          <Col sm={12}
            lg={3}>
            <Input
              label='เลขที่ PO'
              placeholder='เลขที่ PO'
              value={poNumberData}
              variant='primary'
              onChange={(val) => setPoNumberData(val)}
              disabled
            />
          </Col>
        </Row>
      </Card>

      <Assignee
        data={assigneesDataLevel0?.sort((a, b) => a.sequence - b.sequence)!}
        visible={isJorporDirectorAssignLv0}
        onAssigneeModalShow={() => setShowUserAssigneeLevel0(true)}
        onRemoveAssigneeChange={(index) => handlerRemoveAssigneesDataLevel0(index)}
        textTitle='ผอ.จพ. มอบหมายงานไปยังผู้รับผิดชอบ'
      />

      <Assignee
        data={assigneesDataLevel1?.sort((a, b) => a.sequence - b.sequence)!}
        visible={isJorporDirectorAssignLv1}
        onAssigneeModalShow={() => setShowUserAssigneeLevel1(true)}
        onRemoveAssigneeChange={(index) => handlerRemoveAssigneesDataLevel1(index)}
        textTitle='ผู้รับผิดชอบ'
      />

      {/* <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='ผอ.จพ. มอบหมายงานไปยังผู้รับผิดชอบ'
            className='fs-5 text-primary'
          />
          <FaChevronCircleDown
            onClick={() => setOpenCollapse1(!openCollapse1)}
            aria-controls='example-collapse-text'
            aria-expanded={openCollapse1}
            className='ms-2 text-primary'
          />
        </div>
        <Collapse in={openCollapse1}>
          <div>
            <div className='d-flex justify-content-between my-3'>
              <p>ผอ.จพ. มอบหมายงานไปยังผู้รับผิดชอบ</p>
              {
                (isDisabledAssigneesLevel0) && (
                  <Button
                    variant='outline-primary'
                    disabled={!isDisabledAssigneesLevel1}
                    onClick={() => setShowUserAssigneeLevel0(true)}>
                    <FaPlus className='me-1' />เพิ่มรายชื่อ
                  </Button>
                )
              }
            </div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr className='text-center'>
                      <th style={{ width: 20 }}>ลำดับ</th>
                      <th style={{ width: 120 }}>ชื่อ-นามสกุล</th>
                      <th style={{ width: 120 }}>ฝ่าย/สำนัก</th>
                      <th style={{ width: 120 }}>ตำแหน่ง</th>
                      <th style={{ width: 50 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {assigneesDataLevel0?.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr className='text-center'
                          key={index}>
                          <td>{index + 1}</td>
                          <td className='text-start'>{data.fullname}</td>
                          <td className='text-start'>{data.departmentName}</td>
                          <td className='text-start'>{data.positionName}</td>
                          <td>
                            <div className='d-flex justify-content-end'>
                              <Button
                                variant='danger'
                                className='d-flex align-items-center gap-2'
                                onClick={() => handlerRemoveAssigneesDataLevel0(index)}
                                disabled={!isDisabledAssigneesLevel0 ? !isDisabledAssigneesLevel0 : data.assignByUserId === data.userId}
                              >
                                <FaTrashAlt />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='ผู้รับผิดชอบ'
            className='fs-5 text-primary'
          />
          <FaChevronCircleDown
            onClick={() => setOpenCollapse2(!openCollapse2)}
            aria-controls='example-collapse-text'
            aria-expanded={openCollapse2}
            className='ms-2 text-primary'
          />
        </div>
        <Collapse in={openCollapse2}>
          <div>
            <div className='d-flex justify-content-between my-3'>
              <p>ผู้รับผิดชอบ</p>
              <Button
                variant='outline-primary'
                disabled={!isDisabledAssigneesLevel1}
                onClick={() => setShowUserAssigneeLevel1(true)}>
                <FaPlus className='me-1' />เพิ่มรายชื่อ
              </Button>
            </div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr className='text-center'>
                      <th style={{ width: 20 }}>ลำดับ</th>
                      <th style={{ width: 120 }}>ชื่อ-นามสกุล</th>
                      <th style={{ width: 120 }}>ฝ่าย/สำนัก</th>
                      <th style={{ width: 120 }}>ตำแหน่ง</th>
                      <th style={{ width: 50 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {assigneesDataLevel1?.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr className='text-center'
                          key={index}>
                          <td>{index + 1}</td>
                          <td className='text-start'>{data.fullname}</td>
                          <td className='text-start'>{data.departmentName}</td>
                          <td className='text-start'>{data.positionName}</td>
                          <td>
                            <div className='d-flex justify-content-end'>
                              <Button
                                variant='danger'
                                className='d-flex align-items-center gap-2'
                                onClick={() => handlerRemoveAssigneesDataLevel1(index)}
                              // disabled={isDisabled}
                              >
                                <FaTrashAlt />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card> */}

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.contractRecordEntrepreneurs?.contractRecordId!}
            />
          </Col>
        </Row>
      </Card>
      <HistoryModal
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={data.contractRecordEntrepreneurs?.contractRecordId}
      />
      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          onClick={() => goToPage(data.contractRecordEntrepreneurs.jorPor06Id, data.contractRecordEntrepreneurs.contractRecordId)}
          variant='outline-primary'>
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          {(isDisabledAssigneesLevel1) && (
            <>
              <Button
                className='me-2 px-3'
                variant='success'
                onClick={() => onSubmitAsync(false)}
              >
                <FaSave className='me-2 pb-1 fs-5' />
                บันทึก
              </Button>
            </>
          )}
          {(isDisabledAssigneesLevel1 && data.contractRecordEntrepreneurs?.status !== PurchaseOrderApprovalStatusConstant.Assigned) && (
            <Button
              className='me-2 px-3'
              variant='success'
              onClick={() => setShowSaveAssignees(true)}
            >
              <FaAlignLeft className='me-2 pb-1 fs-5' />
              ส่งมอบหมาย
            </Button>
          )}
        </div>
        <div style={{ width: 145 }} />
      </div>

      <Modal
        show={showSaveAssignees}
        size='lg'
        onHide={() => setShowSaveAssignees(!showSaveAssignees)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการบันทึกมอบหมายหรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowSaveAssignees(!showSaveAssignees)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      {/* <UserAssigneesModal
        show={showUserAssigneeLevel0}
        onHide={() => setShowUserAssigneeLevel0(!showUserAssigneeLevel0)}
        onSelectItem={onSelectEmpApproverAssigneesDataLevel0}
        level={AssigneesLevel.Level0}
      />

      <UserAssigneesModal
        show={showUserAssigneeLevel1}
        onHide={() => setShowUserAssigneeLevel1(!showUserAssigneeLevel1)}
        onSelectItem={onSelectEmpApproverAssigneesDataLevel1}
        level={AssigneesLevel.Level1}
      /> */}

      <SearchBuNameModal
        total={userTotal}
        show={showUserAssigneeLevel0}
        onHide={() => setShowUserAssigneeLevel0(!showUserAssigneeLevel0)}
        onSelect={(id, name, department, position) => onSelectEmpApproverAssigneesDataLevel0(id, name, position!, department!, assigneesDataLevel0.length + 1, AssigneesLevel.Level0)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <SearchBuNameModal
        total={userTotal}
        show={showUserAssigneeLevel1}
        onHide={() => setShowUserAssigneeLevel1(!showUserAssigneeLevel1)}
        onSelect={(id, name, department, position) => onSelectEmpApproverAssigneesDataLevel1(id, name, position!, department!, assigneesDataLevel1.length + 1, AssigneesLevel.Level1)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
    </div>
  );
}

export default PurchaseOrderApprovalAssignee;
