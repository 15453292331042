import {
  Breadcrumb,
  Card,
  Check,
  Input,
  Selector,
} from 'components';
import {
  ItemModel,
  parameterCreate,
  parameterListResponse,
  parameterUpdate,
} from 'models';
import {
  FormEvent,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router';
import { parameter } from 'services';
import {
  HttpStatusCode,
  submitForm,
} from 'utils';
import toast from 'utils/toast';

const BREADCRUMB_INFO = [
  {
    routerName: '/su/su09', label: 'พารามิเตอร์',
  },
  {
    routerName: '', label: 'รายละเอียดพารามิเตอร์',
  },
];

type Loader = { dropDownLists: ItemModel[], parameterDetail: parameterListResponse };

export default function SU09Detail() {
  const { dropDownLists, parameterDetail } = useLoaderData() as Loader;
  const { id } = useParams();
  const [parameterData, setParameterData] = useState<parameterCreate>({ isActive: true } as parameterCreate);
  const [groupDropDownItems, setGroupDropDownItems] = useState<ItemModel[]>([]);
  const [parameterGroupDropDownItems, setparamerterGroupDropDownItems] = useState<ItemModel[]>([]);
  const [onInit, setOnInit] = useState(true);
  const [disabledGroup, setDisabledGroup] = useState(false);
  const [disabledParameterGroup, setDisabledParameterGroup] = useState(false);
  const navigate = useNavigate();

  const backToIndex = () => navigate('/su/su09');

  useEffect(() => {
    setGroupDropDownItems(dropDownLists);
  }, [dropDownLists]);

  useEffect(() => {
    if (id) {
      setDefaultDataForUpdate(parameterDetail);
      setDisabledGroup(true);
      setDisabledParameterGroup(true);

      return;
    }

    setOnInit(false);
  }, [parameterDetail]);

  const handleOnChangeGroup = (value: string) => {
    if (value) {
      setParameterData({ ...parameterData, group: value });
      getParameterGroupDropDownItemsAsync(value);

      return;
    }

    setParameterData({ ...parameterData, id: undefined });
  };

  const getParameterGroupDropDownItemsAsync = async (group: string) => {
    const response = await parameter.getDropdownParameterAsync(group);

    if (response.status === HttpStatusCode.OK) {
      setparamerterGroupDropDownItems(response.data);
    }
  };

  const setDefaultDataForUpdate = (data: parameterListResponse) => {
    setParameterData({ ...parameterData, group: data.group, id: data.parameterGroup, isActive: data.isActive, value: data.value, sequence: data.sequence, code: data.code, label: data.label, subGroup: data.subGroup });

    if (data.id) {
      getParameterGroupDropDownItemsAsync(data.group);
    }

    setOnInit(false);
  };

  const onSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();

    submitForm();

    if (parameterData.group && parameterData.value && parameterData.sequence) {
      if (id) {
        updateParameterAsync(id, parameterData.id);

        return;
      }

      createParameterAsync();
    }
  };

  const createParameterAsync = async () => {

    const { data, status } = await parameter.createParameterAsync(parameterData);

    if (data === 'Sequence Parameter Duplicate.') {
      return toast.warn('ลำดับมีการใช้งานแล้ว');
    }

    if (status === HttpStatusCode.CREATED) {
      backToIndex();
      toast.success('สร้างพารามิเตอร์สำเร็จ');
    }

    if (status === HttpStatusCode.BAD_REQUEST
      && data.toString()
        .includes('Duplicate')) {
      toast.error('ค่าพารามิเตอร์ซ้ำภายใต้กลุ่มเดียวกัน');
    }
  };

  const updateParameterAsync = async (id: string, parentId?: string) => {
    const body: parameterUpdate = {
      group: parameterData.group,
      id,
      parameterGroup: parameterData.group,
      parentId,
      sequence: parameterData.sequence,
      value: parameterData.value,
      isActive: parameterData.isActive,
      code: parameterData.code,
      label: parameterData.label,
      subGroup: parameterData.subGroup,
    };

    const { data, status } = await parameter.updateParameterAsync(body, id);

    if (data === 'Sequence is Duplicate.') {
      return toast.warn('ลำดับมีการใช้งานแล้ว');
    }

    if (status === HttpStatusCode.ACCEPTED) {
      backToIndex();
      toast.success('แก้ไขพารามิเตอร์สำเร็จ');
    }
  };

  return (
    <>
      {!onInit && (
        <Form onSubmit={onSubmit}>
          <div className='d-flex justify-content-between align-items-center'>
            <h4 className='mt-2 text-primary'>
              <Button variant='link'
                onClick={backToIndex}>
                <MdOutlineArrowBack className='fs-4 text-primary mb-1' />
              </Button>
              รายละเอียดพารามิเตอร์ในระบบ
            </h4>
            <Button type='submit'
              className='d-flex align-items-center gap-2'
              variant='success'>บันทึก</Button>
          </div>

          <Breadcrumb data={BREADCRUMB_INFO} />
          <hr />
          <Card>
            <Row>
              <Col xs={12}
                sm={6}
                md={6}
                lg={3}>
                <Selector
                  disabled={disabledGroup}
                  rule={{ required: true }}
                  value={parameterData.group}
                  items={groupDropDownItems}
                  label='กลุ่ม'
                  onChange={(value) => handleOnChangeGroup(value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}
                sm={6}
                md={6}
                lg={3}>
                <Selector
                  disabled={disabledParameterGroup}
                  value={parameterData.id}
                  items={parameterGroupDropDownItems}
                  label='กลุ่มย่อย'
                  onChange={(value) => setParameterData({ ...parameterData, id: value })}
                />
              </Col>
              <Col xs={12}
                sm={6}
                md={6}
                lg={3}>
                <Input
                  value={parameterData.subGroup}
                  label='SupGroup'
                  onChange={(value) => setParameterData({ ...parameterData, subGroup: value })}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}
                sm={6}
                md={6}
                lg={3}>
                <Input
                  value={parameterData.code}
                  label='Code'
                  onChange={(value) => setParameterData({ ...parameterData, code: value })}
                />
              </Col>
              <Col xs={12}
                sm={6}
                md={6}
                lg={3}>
                <Input
                  value={parameterData.label}
                  label='Name'
                  onChange={(value) => setParameterData({ ...parameterData, label: value })}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}
                sm={6}
                md={6}
                lg={3}>
                <Input
                  rule={{ required: true }}
                  value={parameterData.value}
                  label='ค่าพารามิเตอร์'
                  onChange={(value) => setParameterData({ ...parameterData, value })}
                />
              </Col>
              <Col xs={12}
                sm={6}
                md={6}
                lg={3}>
                <Input
                  rule={{ required: true }}
                  value={parameterData.sequence}
                  type='number'
                  label='ลำดับ'
                  onChange={(value) => setParameterData({ ...parameterData, sequence: parseInt(value, 10) })}
                />
              </Col>
            </Row>
            <Row>
              <Check label='ใช้งาน'
                value={parameterData.isActive}
                onChange={(value) => setParameterData({ ...parameterData, isActive: value })} />
            </Row>
          </Card>
        </Form>
      )}
    </>
  );
}
