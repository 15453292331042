import {
    InputTextArea,
    Modal,
} from 'components';
import { ContractAgreementJorPorCommentsResultModel, UpdateJorPorCommentModel } from 'models/CM/CM02Models';
import {
    useEffect,
    useState,
} from 'react';
import {
    Button,
    Col,
    Modal as ModalBT,
    Row,
} from 'react-bootstrap';
import { FaRegEye } from 'react-icons/fa';
import {
    MdArrowBack,
    MdSave,
} from 'react-icons/md';
import { useNavigate } from 'react-router';
import CM02Service from 'services/CM/CM02Service';
import {
    HttpStatusCode,
} from 'utils';
import toast from 'utils/toast';

interface Props {
    show: boolean;
    onHide: () => void;
    contractAgreementId: string;
    onGet: () => void;
    data?: any;
}

export function JorPorCommentModal(props: Props) {
    const [data, setData] = useState<ContractAgreementJorPorCommentsResultModel>({} as ContractAgreementJorPorCommentsResultModel);

    const onSubmitAsync = async () => {

        if ((data.commentDescription === undefined
            || data.commentDescription === null)) {
            toast.warn('กรุณาระบุความเห็น');

            return;
        }

        const updateData: UpdateJorPorCommentModel = {
            sendToApprove: false,
            sendToAssignee: false,
            sendToJorPor: false,
            commentDescription: data.commentDescription,
            commentDate: data.commentDate,
            contractAgreementId: props.contractAgreementId,
        }

        const res = await CM02Service.createJorPorCommentAsync(updateData);

        if (res.status !== HttpStatusCode.OK) {
            toast.error('ไม่สามารถบันทึกข้อมูลได้');

            return;
        }

        toast.success('บันทึกข้อมูลสัญญาสำเร็จ');
        props.onHide();
        props.onGet();
    };

    return (
        <Modal
            show={props.show}
            size='lg'
            onHide={() => props.onHide()}
            children={(
                <>
                    <ModalBT.Header closeButton>
                        <ModalBT.Title>ความเห็น จพ.</ModalBT.Title>
                    </ModalBT.Header>
                    <ModalBT.Body>
                        <form>
                            <Row>
                                <Col sm={12}
                                    md={12}>
                                    <InputTextArea label='ความเห็น จพ.'
                                        placeholder='ความเห็น จพ.'
                                        value={data.commentDescription}
                                        rule={{ required: true }}
                                        onChange={val => setData({
                                            ...data,
                                            commentDescription: val,
                                        })} />
                                </Col>
                                <Col sm={12}
                                    className='d-flex justify-content-center gap-3'>
                                    <Button
                                        variant='success'
                                        className='me-2 px-3'
                                        onClick={onSubmitAsync}
                                    >
                                        <MdSave className='mx-2 pb-1 fs-4' />
                                        บันทึก
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </ModalBT.Body>
                </>
            )}
        />
    );
}
