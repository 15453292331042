import {
  Check,
  Currency,
  DatePicker,
  Input,
  Modal,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import { DepartmentListResponse } from 'models';
import {
  PlanAnnouncementDetailModel,
  PlanAnnouncementListResponseModel,
  planAnnouncementPlanTypeCount,
  planAnnouncementStatusCount,
  PlanRowSelected,
  SearchPL0302Model,
} from 'models/planAnnouncement';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaPlus,
  FaSearch,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { planAnnouncement } from 'services';
import department from 'services/department';
import {
  GetMonthYearBC,
  thaiFormatDateWithSlash,
} from 'utils';
import { HttpStatusCode } from 'utils/constants/httpStatusCode';
import {
  PlanStatusText,
  StatusName,
} from 'utils/constants/planStatus';
import toast from 'utils/toast';

interface CriteriaForm {
  planNumber: string;
  departmentId: string;
  planName: string;
  planBudget?: number;
  expectingProcurementAt: Date;
  planAnnouncementDate: Date;
  status: string[];
  planStatus: string[];
  createBy: string;

  typeAll: boolean;
  typeAnnualPlan: boolean;
  typeInYearPlan: boolean;

  statusAll: boolean;
  statusApproveAcceptor: boolean;
  statusAnnouncement: boolean;
  statusRejectAnnouncement: boolean;
}

export default function PL0302() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [departments, setDepartments] = useState<DepartmentListResponse[]>([]);
  const [statusCount, setStatusCount] = useState<planAnnouncementStatusCount>({} as planAnnouncementStatusCount);
  const [planTypeCount, setPlanTypeCount] = useState<planAnnouncementPlanTypeCount>({} as planAnnouncementPlanTypeCount);
  const [planData, setPlanData] = useState<PlanAnnouncementListResponseModel>({} as PlanAnnouncementListResponseModel);
  const [rowsSelected, setRowsSelected] = useState<PlanRowSelected[]>([]);

  const onRowsSelectedHandle = (data: PlanRowSelected[]) => {
    setRowsSelected(data);
  };

  const searchAnnualPlan = useCallback(async (page: number, size: number, criteriaForm?: CriteriaForm) => {
    const searchDto: SearchPL0302Model = {
      page,
      size,
      planNumber: criteriaForm?.planNumber,
      departmentId: criteriaForm?.departmentId,
      planName: criteriaForm?.planName,
      planBudget: criteriaForm?.planBudget?.toString(),
      expectingProcurementAt: criteriaForm?.expectingProcurementAt?.toISOString(),
      planAnnouncementDate: criteriaForm?.planAnnouncementDate?.toISOString(),
      createBy: criteriaForm?.createBy,
    };

    if (criteriaForm?.typeAnnualPlan) {
      searchDto.annualPlan = true;
    }

    if (criteriaForm?.typeInYearPlan) {
      searchDto.inYearPlan = true;
    }

    if (criteriaForm?.statusApproveAcceptor) {
      searchDto.approveAcceptor = true;
    }

    if (criteriaForm?.statusAnnouncement) {
      searchDto.announcement = true;
    }

    if (criteriaForm?.statusRejectAnnouncement) {
      searchDto.rejectAnnouncement = true;
    }

    const { data, status } = await planAnnouncement.getPlansAsync(searchDto);

    if (status !== 200) {
      toast.error('เกิดข้อผิดพลาด');
      return;
    }

    setPlanData({
      plans: data.data,
      totalCount: data.totalRecords,
    });
  }, []);

  useEffect(() => {
    searchAnnualPlan(page, size);
  }, [page, searchAnnualPlan, size]);

  const search = (criteriaForm: CriteriaForm) => {
    getPlanAnnouncementStatusCountAsync();
    getPlanAnnouncementPlanTypeCountAsync();
    searchAnnualPlan(page, size, criteriaForm);
  };

  useEffect(() => {
    getDepartmentsAsync();
    getPlanAnnouncementStatusCountAsync();
    getPlanAnnouncementPlanTypeCountAsync();
  }, []);

  const getDepartmentsAsync = async () => {
    const { data, status } = await department.getDepartmentsAsync();

    if (status !== HttpStatusCode.OK) {
      toast.error('เกิดข้อผิดพลาด');
      return;
    }

    setDepartments(data);
  };

  const getPlanAnnouncementStatusCountAsync = useCallback(async () => {
    const response = await planAnnouncement.getCountStatusAsync();

    if (response.status !== HttpStatusCode.OK) {
      toast.error('เกิดข้อผิดพลาด');
      return;
    }

    setStatusCount(response.data);
  }, []);

  const getPlanAnnouncementPlanTypeCountAsync = useCallback(async () => {
    const response = await planAnnouncement.getCountPlanTypeAsync();

    if (response.status !== HttpStatusCode.OK) {
      toast.error('เกิดข้อผิดพลาด');
      return;
    }

    setPlanTypeCount(response.data);
  }, []);

  return (
    <div className='pl0302'>
      <PL0302Header rowSelected={rowsSelected} />
      <Criteria
        onSubmitSearch={search}
        departmentList={departments}
        statusCount={statusCount}
        planTypeCount={planTypeCount}
      />
      <DataTable
        rowsData={planData.plans}
        setPage={setPage}
        setSize={setSize}
        currentSize={size}
        total={planData.totalCount}
        onRowsSelected={onRowsSelectedHandle}
      />
    </div>
  );
}

interface HeaderProps {
  rowSelected: PlanRowSelected[];
}

function PL0302Header({ rowSelected }: HeaderProps) {
  const navigate = useNavigate();
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const createAnnouncementHandle = () => {
    if (rowSelected.length === 0) {
      setShowWarningModal(true);

      return;
    }

    const planIds = rowSelected.map((p) => p.ids);

    navigate(`detail?ids=${planIds}`);
  };

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>ประกาศเผยแพร่ประกาศแผนจัดซื้อจัดจ้าง</h4>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={createAnnouncementHandle}
        >
          <FaPlus />สร้างประกาศ
        </Button>
      </div>
      <hr />
      <Modal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>
                กรุณาเลือกรายการ!
              </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Footer>
              <Button
                variant='primary'
                onClick={() => setShowWarningModal(false)}
              >
                ลองอีกครั้ง
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

interface SearchCriteriaProps {
  onSubmitSearch: (formValue: CriteriaForm) => void;
  departmentList: DepartmentListResponse[];
  statusCount: planAnnouncementStatusCount;
  planTypeCount: planAnnouncementPlanTypeCount;
}

function Criteria({ onSubmitSearch, departmentList, statusCount, planTypeCount }: SearchCriteriaProps) {
  const [criteria, setCriteria] = useState<CriteriaForm>({
    typeAll: true,
    typeAnnualPlan: true,
    typeInYearPlan: true,
    statusAll: true,
    statusApproveAcceptor: true,
    statusAnnouncement: true,
    statusRejectAnnouncement: true,
  } as CriteriaForm);

  const clearCriteria = () => setCriteria({
    typeAll: true,
    typeAnnualPlan: true,
    typeInYearPlan: true,
    statusAll: true,
    statusApproveAcceptor: true,
    statusAnnouncement: true,
    statusRejectAnnouncement: true,
  } as CriteriaForm);

  const handleCheckboxPlanTypeChange = (name: string, value: boolean) => {
    if (name === 'typeAll') {
      setCriteria({
        ...criteria,
        typeAll: value,
        typeAnnualPlan: value,
        typeInYearPlan: value,
      });

      return;
    }

    if (name === 'typeAnnualPlan') {
      setCriteria({
        ...criteria,
        typeAnnualPlan: value,
        typeAll: value
          && criteria.typeInYearPlan,
      });

      return;
    }

    if (name === 'typeInYearPlan') {
      setCriteria({
        ...criteria,
        typeInYearPlan: value,
        typeAll: value
          && criteria.typeAnnualPlan,
      });
    }
  };

  const handleCheckboxStatusChange = (name: string, value: boolean) => {
    if (name === 'statusAll') {
      setCriteria({
        ...criteria,
        statusAll: value,
        statusApproveAcceptor: value,
        statusAnnouncement: value,
        statusRejectAnnouncement: value,
      });

      return;
    }

    if (name === 'statusApproveAcceptor') {
      setCriteria({
        ...criteria,
        statusApproveAcceptor: value,
        statusAll: !!(value
          && criteria.statusAnnouncement
          && criteria.statusRejectAnnouncement),
      });

      return;
    }

    if (name === 'statusAnnouncement') {
      setCriteria({
        ...criteria,
        statusAnnouncement: value,
        statusAll: !!(value
          && criteria.statusApproveAcceptor
          && criteria.statusRejectAnnouncement),
      });

      return;
    }

    if (name === 'statusRejectAnnouncement') {
      setCriteria({
        ...criteria,
        statusRejectAnnouncement: value,
        statusAll: !!(value
          && criteria.statusApproveAcceptor
          && criteria.statusAnnouncement),
      });
    }
  };

  return (
    <Form>
      <Row className='criteria'>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='เลขที่แผน'
            onChange={(val) => setCriteria({ ...criteria, planNumber: val })}
            value={criteria.planNumber}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='ฝ่าย/สำนัก'
            items={departmentList.map((d) => ({ label: d.name, value: d.id }))}
            onChange={(val) => setCriteria({ ...criteria, departmentId: val })}
            value={criteria.departmentId}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='ชื่อแผน'
            onChange={(val) => setCriteria({ ...criteria, planName: val })}
            value={criteria.planName}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Currency
            label='งบประมาณโครงการ'
            onChange={(val) => setCriteria({ ...criteria, planBudget: val })}
            value={criteria.planBudget}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker
            label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
            onChange={(val) => setCriteria({ ...criteria, expectingProcurementAt: val })}
            value={criteria.expectingProcurementAt}
            monthYearOnly
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker
            label='วันที่ประกาศแผน'
            onChange={(val) => setCriteria({ ...criteria, planAnnouncementDate: val })}
            value={criteria.planAnnouncementDate}
          />
        </Col>
        <Col sm={12}
          lg={6}
          xl={3}>
          <Input
            label='ผู้จัดทำ'
            onChange={(val) => setCriteria({ ...criteria, createBy: val })}
            value={criteria.createBy}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}
          lg={6}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>ประเภท</Form.Label>
            <InputGroup>
              <Check
                label={<p>ทั้งหมด <Badge bg='secondary'>{planTypeCount.countAll}</Badge></p>}
                className='me-2'
                value={criteria.typeAll}
                onChange={(val) => handleCheckboxPlanTypeChange('typeAll', val)}
              />
              <Check
                label={<p>จัดทำแผนรวมปี <Badge bg='warning'>{planTypeCount.countAnnualPlan}</Badge></p>}
                className='me-2'
                value={criteria.typeAnnualPlan}
                onChange={(val) => handleCheckboxPlanTypeChange('typeAnnualPlan', val)}
              />
              <Check
                label={<p>จัดทำแผนระหว่างปี <Badge bg='warning'>{planTypeCount.countInYearPlan}</Badge></p>}
                className='me-2'
                value={criteria.typeInYearPlan}
                onChange={(val) => handleCheckboxPlanTypeChange('typeInYearPlan', val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={6}
          md={6}
          lg={6}
          xl={6}>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ</Form.Label>
            <InputGroup>
              <Check
                label={<p>ทั้งหมด <Badge bg='secondary'>{statusCount.countAll}</Badge></p>}
                className='me-2'
                value={criteria.statusAll}
                onChange={(val) => handleCheckboxStatusChange('statusAll', val)}
              />
              <Check
                label={<p>รอเผยแพร่ <Badge bg='warning'>{statusCount.countApproveAcceptor}</Badge></p>}
                className='me-2'
                value={criteria.statusApproveAcceptor}
                onChange={(val) => handleCheckboxStatusChange('statusApproveAcceptor', val)}
              />
              <Check
                label={<p>เผยแพร่แล้ว <Badge bg='success'>{statusCount.countAnnouncement}</Badge></p>}
                className='me-2'
                value={criteria.statusAnnouncement}
                onChange={(val) => handleCheckboxStatusChange('statusAnnouncement', val)}
              />
              <Check
                label={<p>แก้ไข <Badge bg='danger'>{statusCount.countRejectAnnouncement}</Badge></p>}
                className='me-2'
                value={criteria.statusRejectAnnouncement}
                onChange={(val) => handleCheckboxStatusChange('statusRejectAnnouncement', val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex gap-2'>
            <Button
              variant='primary'
              className='d-flex align-items-center gap-2'
              onClick={() => onSubmitSearch(criteria)}
            >
              <FaSearch />ค้นหา
            </Button>
            <Button
              variant='outline-primary'
              onClick={clearCriteria}
              className='d-flex align-items-center gap-2'
            >
              <FaEraser />ล้าง
            </Button>
          </div>
        </div>
      </Row>
    </Form>
  );
}

interface DataTableProps {
  rowsData: PlanAnnouncementDetailModel[];
  total: number;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  onRowsSelected: (rowsSelected: PlanRowSelected[]) => void;
  currentSize: number;
}

function DataTable({ rowsData, total, setPage, setSize, onRowsSelected, currentSize }: DataTableProps) {
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [warningModalMessage, setWarningModalMessage] = useState<string>('');
  const [rowsSelected, setRowsSelected] = useState<PlanRowSelected[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    onRowsSelected(rowsSelected);
  }, [rowsSelected]);

  const handleCheckedPlan = (index: number) => {
    const targetIndex = rowsSelected.findIndex((item) => item.ids === rowsData[index].id);

    if (targetIndex >= 0) {
      rowsData[index].isSelected = 'false';

      const newValue = [...rowsSelected];
      newValue.splice(targetIndex, 1);
      setRowsSelected(newValue);

      return;
    }

    if (rowsSelected.length > 0 && rowsSelected[0].planStatus !== rowsData[index].planStatus) {
      setWarningModalMessage('ไม่สามารถเลือก เนื่องจากประเภทโครงการแตกต่างกัน');
      setShowWarningModal(true);

      return;
    }

    if (rowsSelected.length > 0 && rowsSelected[0].planStatus === PlanStatusText.ChangePlan) {
      setWarningModalMessage('ไม่สามารถเลือกประเภทเปลี่ยนแปลงแผน มากกว่า 1 รายการ');
      setShowWarningModal(true);

      return;
    }

    if (rowsSelected.length > 0 && rowsSelected[0].planStatus === PlanStatusText.InYearPlan) {
      setWarningModalMessage('ไม่สามารถเลือก แผนระหว่างปี มากกว่า 1 รายการ');
      setShowWarningModal(true);

      return;
    }

    rowsData[index].isSelected = 'true';
    setRowsSelected([
      ...rowsSelected,
      {
        ids: rowsData[index].id,
        planStatus: rowsData[index].planStatus,
      },
    ]);
  };

  return (
    <>
      <Table
        className='mt-4'
        total={total}
        onChange={(size, page) => (
          setSize(size),
            setPage(size === currentSize ? page : 1),
            onRowsSelected([])
        )}
      >
        <thead>
        <tr>
          <th />
          <th>เลขที่แผน</th>
          <th>ฝ่าย/สำนัก</th>
          <th style={{ minWidth: 300, width: 500 }}>ชื่อแผน</th>
          <th>งบประมาณ (บาท)</th>
          <th>วิธีจัดหา</th>
          <th>ประมาณการช่วง<br />เวลาการจัดซื้อจัดจ้าง</th>
          <th>วันที่ประกาศแผน</th>
          <th>สถานะโครงการ</th>
          <th>สถานะ</th>
        </tr>
        </thead>
        <tbody>
        {
          rowsData
          && rowsData.map((rowData, index) => (
            <tr key={rowData.id}>
              <td className='text-center'>
                <Form.Check
                  type='checkbox'
                  className='me-2'
                  checked={rowData.isSelected === 'true'}
                  value={rowData.isSelected}
                  disabled={rowData.status === StatusName.ANNOUNCEMENT}
                  onChange={() => handleCheckedPlan(index)}
                />
              </td>
              <td className='text-center'>
                <Button
                  onClick={() => navigate(`/pl/pl0302/detail?ids=${rowData.id}`)}
                  variant='link'
                >
                  {rowData.planNumber}
                </Button>
              </td>
              <td className='text-center'>{rowData.departmentName}</td>
              <td className='text-wrap'>{rowData.planName}</td>
              <td className='text-end'>
                {rowData.planBudget.toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </td>
              <td className='text-center'>{rowData.supplyMethod}</td>
              <td className='text-center'>
                {GetMonthYearBC(rowData.expectingProcurementAt)}
              </td>
              <td className='text-center'>
                {rowData.planAnnouncementDate ? thaiFormatDateWithSlash(rowData.planAnnouncementDate) : null}
              </td>
              <td className='text-center'>
                <Status type={StatusType.PROJECT}
                  value={rowData.planStatus} />
              </td>
              <td className='text-center'>
                <Status type={StatusType.PROCESS}
                  value={rowData.status} />
              </td>
            </tr>
          ))
        }
        </tbody>
      </Table>
      <Modal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>
                ไม่สามารถเลือกได้!
              </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              {warningModalMessage}
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='primary'
                onClick={() => setShowWarningModal(false)}>
                ลองอีกครั้ง
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}
