import { DepartmentResponse, ItemModel } from 'models';
import { LoaderFunctionArgs } from 'react-router';
import { department, shareValue, unitOfMeasure } from 'services';
import { SharedvalueGroup } from 'utils/constants/ContractEnum';

const TorLoader = async () => {
  const departmentItems = await GetDDL('Department');
  const supplyMethodItems = await GetDDL('SupplyMethod');
  const supplyMethodTypeItems = await GetDDL('SupplyMethodType');
  const planItems = await GetDDL('Plan');
  const periodUnitItems = await GetDDL(SharedvalueGroup.PeriodType);
  const unitOfMeasureItems = await GetDDL('UnitOfMeasure');
  const deliveryCountUnitItems = await GetDDL('DeliveryCountUnit');
  const defectsWarrantyCountUnitItems = await GetDDL('DefectsWarrantyCountUnit');
  const periodTimeTypeItems = await GetDDL(SharedvalueGroup.PeriodTimeType);
  const pmFineTypeItems = await GetDDL(SharedvalueGroup.PmFineType);
  const cmFineTypeItems = await GetDDL(SharedvalueGroup.CmFineType);
  const trainingUnitTypeItems = await GetDDL(SharedvalueGroup.TrainingUnitType);
  const dayOfWeekItems = await GetDDL(SharedvalueGroup.DayOfWeek);
  const documentTemplates = await GetDDL(SharedvalueGroup.DocumentTemplate);
  const maintenancePeriodType = await GetDDL(SharedvalueGroup.MaintenancePeriodType);

  return {
    departmentDDL: departmentItems,
    supplyMethodDDL: supplyMethodItems,
    supplyMethodTypeDDL: supplyMethodTypeItems,
    planDDL: planItems,
    periodUnitDDL: periodUnitItems,
    unitOfMeasureDDL: unitOfMeasureItems,
    deliveryCountUnitDDL: deliveryCountUnitItems,
    defectsWarrantyCountUnitDDL: defectsWarrantyCountUnitItems,
    periodTimeUnitDDL: periodTimeTypeItems,
    pmFineTypeDDL: pmFineTypeItems,
    cmFineTypeDDL: cmFineTypeItems,
    trainingUnitTypeDDL: trainingUnitTypeItems,
    dayOfWeekDDL: dayOfWeekItems,
    documentTemplateDDL: documentTemplates,
    maintenancePeriodTypeDDL: maintenancePeriodType,
  };
};

const TorDetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const departmentItems = await GetDDL('Department');
  const supplyMethodItems = await GetDDL('SupplyMethod');
  const supplyMethodTypeItems = await GetDDL('SupplyMethodType');
  const planItems = await GetDDL('Plan');
  return {
    departmentDDL: departmentItems,
    supplyMethodDDL: supplyMethodItems,
    supplyMethodTypeDDL: supplyMethodTypeItems,
    planDDL: planItems
  };
};

const GetDDL = async (Type: string) => {
  const itemForMap: ItemModel[] = [];

  switch (Type) {
    case 'Department': {
      const response = await department.getDepartmentsAsync();
      const departmentData: DepartmentResponse[] = response.data;
      departmentData.forEach((data) => itemForMap.push({ label: data.name, value: data.id ?? '' }));
      break;
    }
    case 'SupplyMethod': {
      const response = await shareValue.getSupplyMethodListAsync();
      const supplyMethodData: ItemModel[] = response.data;
      supplyMethodData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case 'SupplyMethodType': {
      const response = await shareValue.getSupplyMethodTypeListAsync();
      const supplyMethodTypeData: ItemModel[] = response.data;
      supplyMethodTypeData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case SharedvalueGroup.PeriodType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodType);
      const periodUnitData: ItemModel[] = response.data;
      periodUnitData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case 'UnitOfMeasure': {
      const response = await unitOfMeasure.GetUnitOfMeasureAsync();
      const unitOfMeasureData: ItemModel[] = response.data;
      unitOfMeasureData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case 'DeliveryCountUnit': {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.DeliveryCountUnit);
      const datas: ItemModel[] = response.data;
      datas.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case 'DefectsWarrantyCountUnit': {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.DefectsWarrantyCountUnit);
      const datas: ItemModel[] = response.data;
      datas.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case SharedvalueGroup.PeriodTimeType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodTimeType);
      const periodUnitData: ItemModel[] = response.data;
      periodUnitData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case SharedvalueGroup.PmFineType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PmFineType);
      const datas: ItemModel[] = response.data;
      datas.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case SharedvalueGroup.CmFineType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.CmFineType);
      const datas: ItemModel[] = response.data;
      datas.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case SharedvalueGroup.TrainingUnitType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.TrainingUnitType);
      const datas: ItemModel[] = response.data;
      datas.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case SharedvalueGroup.DayOfWeek: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.DayOfWeek);
      const datas: ItemModel[] = response.data;
      datas.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case SharedvalueGroup.MaintenancePeriodType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodType, '', SharedvalueGroup.MaintenancePeriodType);
      const datas: ItemModel[] = response.data;
      datas.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case SharedvalueGroup.DocumentTemplate: {
      const response = await shareValue.getDocumentTemplateAsync(SharedvalueGroup.DocumentTemplateTor);
      const datas: ItemModel[] = response.data;

      datas.forEach((data) =>
        itemForMap.push(
          {
            label: data.label,
            value: data.code ?? '',
            value3: data.value3 ?? ''
          }));
      break;
    }
  }

  return itemForMap;
};

export { TorLoader, TorDetailLoader };
