import {
  useEffect,
  useState,
} from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { FaImage } from 'react-icons/fa';
import { HttpStatusCode } from 'utils';
import { account } from '../../services';

interface Props {
  value?: string;
  onChange?: (file: File) => void;
  disabled?: boolean;
}

const fileTypes = ['png', 'jpg', 'jpeg'];

export function UploadImage(props: Props) {
  const [dragEnter, setDragEnter] = useState<boolean>(false);
  const [img, setImg] = useState<string>();

  useEffect(() => {
    if (props.value) {
      getImageAsync(props.value);
    }
  }, [props.value]);

  const getImageAsync = async (value: string) => {
    //TODO: If need to make it to global function change a service function
    const {
      status,
      data,
    } = await account.getProfileImageAsync(value);

    if (status === HttpStatusCode.OK) {
      setImg(`data:${data.mimeType};base64,${data.contents}`);
    }
  };

  const handlerOnChangeAsync = async (file: File) => {
    if (!props.disabled) {
      const base64 = await toBase64(file);

      setImg(`${base64}`);

      if (props.onChange) {
        props.onChange(file);
      }
    }
  };

  const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  return (
    <div className='upload-image'>
      <FileUploader
        hoverTitle='&nbsp;'
        types={fileTypes}
        onDraggingStateChange={setDragEnter}
        handleChange={handlerOnChangeAsync}
        disabled={props.disabled}
      >
        <div
          className={`upload-image-container ${props.disabled ? 'disabled' : dragEnter ? 'drag-enter' : ''}`}
        >
          {img
            ? (
              <div className='has-image'>
                <img src={img}
                  alt='uploadImage' />
              </div>
            )
            : (
              <div className='no-image'>
                <FaImage />
              </div>
            )}
        </div>
      </FileUploader>
    </div>
  );
}
