import {
  Modal,
  Status,
  StatusType,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { InvitedContractHistoryListResponseModel } from 'models/CM/CM01Models';
import {
  useEffect,
  useState,
} from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import CM01Service from 'services/CM/CM01Service';
import {
  fullDatetime,
  HttpStatusCode,
} from 'utils';

interface Props {
  show: boolean;
  onHide?: () => void;
  title?: string;
  id?: string;
  activities?: InvitedContractHistoryListResponseModel[];
}

export function HistoryModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [history, setHistory] = useState<InvitedContractHistoryListResponseModel>({} as InvitedContractHistoryListResponseModel);

  useEffect(() => {
    if (props.id && props.show) {
      getPurchaseOrderApprovalHistoryAsynce(page, size);
    }
  }, [props.id, props.show]);

  const getPurchaseOrderApprovalHistoryAsynce = async (page: number, size: number) => {
    const response = await CM01Service.getListHistoryAsync(page, size, props.id);
    if (response.status === HttpStatusCode.OK) {
      setHistory(response);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size='xl'
    >
      <Modal.Header>
        <Title
          text='ประวัติเชิญชวนทำสัญญา'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <ModalBT.Body className='p-0 ps-4'>
        <Table className='my-3'
          hidePagination
          onChange={(size, page) => {
            getPurchaseOrderApprovalHistoryAsynce(page, size);
          }}>
          <thead>
            <tr>
              <th>วันที่/เวลา</th>
              <th>ผู้ดำเนินการ</th>
              <th>การดำเนินการ</th>
              <th>สถานะการดำเนินการ</th>
              <th>หมายเหตุ</th>

            </tr>
          </thead>
          <tbody>
            {
              (history && history.data) && (
                history.data.map((data, index) => (
                  <tr
                  // key={`${data.createdAt.toISOString()}-${data.description}-${data.remark}-${data.action}`}
                  >
                    <td className='text-center'>{fullDatetime(data.createdAt)}</td>
                    <td>{data.createdBy}
                    </td>
                    <td>{data.description}</td>
                    <td className='text-start'><Status value={data.status}
                      type={StatusType.PURCHASE_ORDER_APPROVAL} />
                    </td>
                    <td className='text-start text-wrap'
                      style={{
                        minWidth: 400,
                        width: 400,
                      }}>{data.remark}
                    </td>
                  </tr>
                ))
              )
            }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
