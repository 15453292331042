import {
  ButtonCustom,
  Card,
  ContractReceiveStatus,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { StatusCMReceived } from 'components/StatusCMReceived';
import {
  IFile,
  ItemModel,
} from 'models';
import {
  AttachmentFileModel,
  contractManagementReceiveCommitteesModel,
} from 'models/CM/CM03Models';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { shareValue } from 'services';
import CM03Service from 'services/CM/CM03Service';
import {
  fullDatetime,
  HttpStatusCode,
  PositionOnBoardIdEnum,
  submitForm,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import Collabora, { CollaboraRef } from '../../../../components/Document/Collabora';
import { generateUniqueId } from '../../../../utils/helper';
import { ApproveModal } from '../Modal/ApproveModal';
import { EditModal } from '../Modal/EditModal';
import { HistoryModal } from '../Modal/HistoryReceiveModal';
import UploadFile from '../UploadFileReceive';
import { Context } from './step';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  contractId: string;
}
enum positionOnBoard {
  positionOnBoardMA = 'positionOnBoardMA',
  positionOnBoardInSpection = 'positionOnBoardInSpection',
}

const INFO = [
  { label: 'มีหลักประกัน', value: '1' },
  { label: 'ไม่มีหลักประกัน', value: '2' },
];

function Step2({ onClickNext, onClickBack, contractId }: Props) {
  const navigate = useNavigate();
  const { data, setData } = useContext(Context);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [posotionOnBoardItems, setPosotionOnBoardItems] = useState<ItemModel[]>([]);
  const [committees, setCommittees] = useState<contractManagementReceiveCommitteesModel[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const { userId } = useAppContext();
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const collaboraRef = useRef<CollaboraRef>(null);
  const [docId, setDocId] = useState('');

  useEffect(() => {
    if (data) {
      getPositionOnBoardAsync(data.isMA);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (data.contractManagementReceiveCommittees && data.contractManagementReceiveCommittees.length > 0) {
        setCommittees(data.contractManagementReceiveCommittees);
        const queueApprover = onCheckQueueApprover(data.contractManagementReceiveCommittees);
        setIsQueueApprover(queueApprover);
      }

      if (data.attachments) {
        handleSetFile(data.attachments);
      }
    }
  }, [data, userId]);

  const getDocument = useCallback(async (id: string) => {
    const { data, status } = await CM03Service.getDocumentInspectionAsync(id);
    if (status === HttpStatusCode.OK) {
      setDocId(data);
    }
  }, [setDocId]);

  useEffect(() => {
    if (data.id) {
      getDocument(data.id);
    }
  }, [data.id, getDocument]);

  const onCheckQueueApprover = (datas: contractManagementReceiveCommitteesModel[]) => {
    if (datas && data.status === ContractReceiveStatus.WaitForInspectorReceived) {
      const pendings = datas?.filter((a) => a.status === ContractReceiveStatus.Pending);
      const president = pendings.filter((a) => a.positionOnBoardId !== PositionOnBoardIdEnum.Chairman);

      if (president.length > 0) {
        return president.filter((a) => a.userId === userId).length > 0;
      }

      return (pendings.filter((a) => a.positionOnBoardId === PositionOnBoardIdEnum.Chairman && a.userId === userId).length > 0);
    }
    return false;
  };

  const getPositionOnBoardAsync = async (isMA: boolean) => {
    if (isMA) {
      const { data, status } = await shareValue.getPositionOnBoardListAsync('', positionOnBoard.positionOnBoardMA);
      if (status === HttpStatusCode.OK) {
        const positionOnBoards: ItemModel[] = data;
        setPosotionOnBoardItems(positionOnBoards);
      }
    } else {
      const { data, status } = await shareValue.getPositionOnBoardListAsync('', positionOnBoard.positionOnBoardInSpection);
      if (status === HttpStatusCode.OK) {
        const positionOnBoards: ItemModel[] = data;
        setPosotionOnBoardItems(positionOnBoards);
      }
    }

  };

  const onApproved = async (remark: string) => {
    const response = await CM03Service.committeesApprovedAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.CREATED) {
      toast.success('รับทราบสำเร็จ');
      getContractManagementAsync(contractId, data.id);

      collaboraRef.current?.clickSave();

      setTimeout(async () => {
        await CM03Service.updateDocumentAsync(data.id, docId);
      }, 500);
    }

    setShowApproveModal(false);
  };

  const onReject = async (remark: string) => {
    submitForm();

    if (!remark) {
      toast.warn('กรุณากรอกหมายเหตุ');
      return;
    }

    const response = await CM03Service.committeesRejectAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.CREATED) {
      toast.success('ส่งกลับแก้ไขสำเร็จ');
      getContractManagementAsync(contractId, data.id);
      setShowRejectModal(false);
    }
  };

  const getContractManagementAsync = useCallback(async (contractId: string, receiveId: string) => {
    const { data, status } = await CM03Service.getContractManagementReceiveByIdAsync(contractId, receiveId);

    if (status === HttpStatusCode.OK) {
      setData(data);
      return;
    }
  }, []);

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await CM03Service.uploadAttachmentReceiveAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getAttachmentAsync(contractId, data.id);
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {
    if (data && data.id) {
      const res = await CM03Service.removeAttachmentReceiveAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getAttachmentAsync(contractId, data.id);
      }
    }
  }, [files, data.id]);

  const getAttachmentAsync = useCallback(async (contractId: string, receiveId: string) => {
    const { data, status } = await CM03Service.getContractManagementReceiveByIdAsync(contractId, receiveId);

    if (status === HttpStatusCode.OK) {

      const files: IFile[] = [];
      data.attachments?.forEach((item) => {
        files.push({
          id: item.id,
          name: item.fileName,
          file: null,
        });
      });

      setFiles(files);

      return;
    }
  }, []);

  return (
    <div className='document'>
      <Title text='ส่งมอบและตรวจรับงาน'
        extraElement={(
          <>
            <StatusCMReceived systemStatus={data.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </>
        )} />

      <Row className='mt-4 align-items-center'>
        <div className='d-flex justify-content-between'>
          <h5 className='fw-bold'>คณะกรรมการตรวจรับรับทราบ</h5>
          {/* <ButtonCustom
            text="เพิ่มรายชื่อ"
            icon='add'
            onClick={() => setShowCommittees(true)} /> */}
        </div>
        <Col sm={12}>
          <Table
            hidePagination
            className='mt-3 step-bar'
            notResponse
          >
            <thead>
              <tr>
                <th className='text-start ps-3' style={{ width: 100 }}>ลำดับ</th>
                <th className='text-start' style={{ width: '60%' }}>ชื่อ-นามสกุล</th>
                <th style={{ width: 200 }}>ตำแหน่ง</th>
                <th style={{ width: 100 }}>สถานะ</th>
                <th className='text-center' style={{ width: 120 }}>วันทีรับทราบ</th>
              </tr>
            </thead>
            <tbody>
              {committees?.sort((a, b) => a.sequence - b.sequence).map((item, index) => (
                <>
                  <tr className='text-start' key={generateUniqueId(item.userId)}>
                    <td className='text-center'>{index + 1}</td>
                    <td className='text-left'>
                      {item.fullName}
                      <p className='m-0 department'>
                        {item.positionName}
                        {/* {item.departmentName &&
                          <>
                            <span className='mx-1'>|</span> {item.departmentName}
                          </>
                        } */}
                      </p>
                    </td>
                    <td style={{ width: 350 }}>
                      {item.positionOnBoardLable}
                      {/* <Form.Group className='mb-3'>
                        <InputGroup hasValidation>
                          <Form.Select
                            className='dark mt-1'
                            value={item.positionOnBoardId || ''}
                            disabled
                          >
                            {
                              posotionOnBoardItems.map((item) => (
                                <option
                                  key={item.value}
                                  disabled
                                  value={item.value}>{item.label}
                                </option>
                              ))
                            }
                          </Form.Select>
                        </InputGroup>
                      </Form.Group> */}
                    </td>
                    <td><StatusCMReceived systemStatus={item.status}
                      systemName='cm-03' />
                    </td>
                    <td className='text-center'>{thaiFormatDateWithSlash(item.acceptDate)}</td>
                  </tr>

                  <tr>
                    <td />
                    <td colSpan={6} className='p-0'>
                      <ul className='step-bar-list'>

                        {item.acceptRemark
                          && (
                            <li>
                              <div className='description'>
                                <p className='mb-0 text-primary'>หมายเหตุรับทราบ
                                </p>
                                <p className='mb-0' style={{ color: 'gray' }}>
                                  <span className='text-muted me-2'>[{item.acceptDate ? fullDatetime(item.acceptDate) : ''}]</span>
                                  {item.acceptRemark}
                                </p>
                              </div>
                            </li>
                          )}
                        {item.rejectRemark
                          && (
                            <li>
                              <div className='description'>
                                <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                <p className='mb-0' style={{ color: 'gray' }}>
                                  <span className='text-muted me-2'>[{item.rejectDate ? fullDatetime(item.rejectDate) : ''}]</span>
                                  {item.rejectRemark}
                                </p>
                              </div>
                            </li>
                          )}
                      </ul>
                    </td>
                  </tr>
                </>

              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Card className='mt-3'>
        <Title
          text='เอกสารผลการตรวจรับ'
          className='fs-5 text-primary'
        />
        <div className='mt-3'>
          <Collabora
            docId={docId}
            docName='doc-01'
            ref={collaboraRef}
            readonly={false} />
        </div>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5' />
        <Row className='justify-content-center'>
          <Col sm={12} md={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.id!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <ButtonCustom text='ย้อนกลับ' onClick={onClickBack} icon='arrowback' variant='outline-primary' />
        </div>
        <div className='d-flex gap-3 align-items-center'>
          {
            (isQueueApprover && data.status === ContractReceiveStatus.WaitForInspectorReceived) && (
              <>
                <ButtonCustom variant='danger'
                  text='ส่งกลับแก้ไข'
                  icon="undo"
                  onClick={() => setShowRejectModal(true)}
                />
                <ButtonCustom text='รับทราบ'
                  variant='success'
                  onClick={() => setShowApproveModal(true)}
                />
              </>
            )
          }
        </div>

        {
          (data.status && data.status !== ContractReceiveStatus.WaitForInspectorReceived) ? (
            <div>
              <ButtonCustom text='ถัดไป' onClick={onClickNext} icon='arrowforward' variant='outline-primary' iconAlignRight />
            </div>
          ) : <div style={{ width: 145 }} />
        }

      </div>
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={data.id}
        activities={data.activities}
        title='การตรวจรับ'
        status={ContractReceiveStatus.Received}
      />
      <EditModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(!showRejectModal)}
        onRemark={onReject}
      />
      <ApproveModal
        show={showApproveModal}
        onRemark={onApproved}
        click={() => setShowApproveModal(!showApproveModal)}
      />
    </div>
  );
}

export default Step2;
