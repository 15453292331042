import { AxiosResponse } from 'axios';
import { LoaderFunctionArgs } from 'react-router';
import { recordContract } from 'services';

const PR0206LoaderDetail = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  let res: AxiosResponse<any, any>;
  if (id) {
    res = await recordContract.getDetailAsync(id);

    return {
      recordContractLoader: res.data.data,
    }

  }


}

export {
  PR0206LoaderDetail,
}
