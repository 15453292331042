import {
  Card,
  InputTextArea,
  ButtonCustom,
  Table,
  Input,
  Modal,
  ContractAgreementStatus,
} from 'components';
import Title from 'components/Controls/Title';
import {
  AttachmentModel,
  ContractAgreementAcceptorModel,
  ContractAgreementModel,
  EntrepreneurModel,
  UpdateDocBoxNumberStep4Model,
  UpdateEntrepreneurConsiderationModel,
} from 'models/CM/CM02Models';
import { sectionApproverModel } from 'models/delegateModel';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
  MdHistory,
  MdSave,
  MdSegment,
} from 'react-icons/md';
import { account, shareValue } from 'services';
import CM02Service from 'services/CM/CM02Service';
import {
  HttpStatusCode,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { Context } from '.';
import Collabora, { CollaboraRef } from '../../../../components/Document/Collabora';
import { HistoryModal } from '../Modal/HistoryModal';
import { FaUndo } from 'react-icons/fa';
import { IFile, ItemModel } from 'models';
import { SharedvalueGroup } from 'utils/constants/ContractEnum';
import { TraderModalTypeConstant } from 'constant/ProcumentInvitationStatus';
import { CoiModalStep4 } from '../Modal/CoiModalStep4';
import { useLoaderData } from 'react-router';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

type Loader = {
  entrepreneurTypeDDL: ItemModel[],
};

export default function Step3({
  onClickNext,
  onClickBack,
}: Props) {
  const {
    dataContext,
    setDataContext,
  } = useContext(Context);
  const { entrepreneurTypeDDL } = useLoaderData() as Loader;
  const [contractData, setContractData] = useState<ContractAgreementModel>({} as ContractAgreementModel);
  const { userId } = useAppContext();
  const [acceptors, setAcceptors] = useState<ContractAgreementAcceptorModel[]>([]);
  const [docId, setDocId] = useState('');
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [approveReason, setApproveReason] = useState<string>('');
  const [rejectReason, setRejectReason] = useState<string>('');
  const [showUserModals, setShowUserModals] = useState<boolean[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [isCanApprove, setIsCanApprove] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const collaboraRef = useRef<CollaboraRef>(null);
  const [contractFormatDDL, setContractFormatDDL] = useState<ItemModel[]>([]);
  const [entrepreneur, setEntrepreneur] = useState<EntrepreneurModel>({} as EntrepreneurModel);
  const [modalTitle, setModalTitle] = useState('COI');
  const [modalType, setModalType] = useState<TraderModalTypeConstant>(TraderModalTypeConstant.COI);
  const [modalResult, setModalResult] = useState<boolean | undefined>(undefined);
  const [modalRemark, setModalRemark] = useState<string | undefined>(undefined);
  const [showCoiModal, setShowCoiModal] = useState(false);

  useEffect(() => {
    if (dataContext) {
      // onGetDefaultApprover();
      // onCheckApproverStatus();

      // if (dataContext.contractAgreementAcceptors) {
      //   setAcceptors(dataContext.contractAgreementAcceptors);
      //   const isCanApprove = onCheckCanApprove(dataContext.contractAgreementAcceptors);
      //   setIsCanApprove(isCanApprove);
      // }

      // if (dataContext.status === ContractAgreementStatus.WaitingForAttachSignedDocument
      //   || dataContext.status === ContractAgreementStatus.RejectedSign) {
      //   setIsDisabled(false);
      // }
      // else {
      //   setIsDisabled(true);
      // }
      setContractData(dataContext);
    }
  }, [dataContext]);

  const getDocument = useCallback(async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getContractSignDocumentAsync(id);
    if (status === HttpStatusCode.OK) {
      setDocId(data);
    }
  }, [setDocId]);

  useEffect(() => {
    if (dataContext.id) {
      getDocument(dataContext.id);
    }
  }, [dataContext.id, getDocument]);

  const onCheckCanApprove = (datas: ContractAgreementAcceptorModel[]) => {
    const indexApprover = datas.findIndex((x) => x.userId === userId && x.status === 'Pending');

    if (indexApprover < 0) {
      return false;
    }

    if (indexApprover === 0) {
      return true;
    }

    return (datas[indexApprover - 1].status === 'Approve');
  };

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = Math.max.apply(null,
      acceptors?.map((x) => {
        return x.sectionSequence;
      }));

    const valueSequence = acceptors?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  const onSelectEmpApprover = (empId: string, name: string, position: string, department: string, index: number, sectionSequence?: number, sectionName?: string, inRefCode?: string) => {
    if (acceptors && acceptors.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const newData: ContractAgreementAcceptorModel = {
        id: undefined,
        sectionInRefCode: inRefCode!,
        sectionSequence: sectionSequence!,
        sectionName: sectionName!,
        userId: empId,
        fullName: name,
        positionName: position,
        departmentName: department,
        isAvailable: false,
        availableRemark: undefined,
        acceptDate: undefined,
        acceptRemark: undefined,
        rejectDate: undefined,
        rejectRemark: undefined,
        delegateUserId: undefined,
        delegateEmployeeCode: undefined,
        delegateFullName: undefined,
        delegateDepartmentName: undefined,
        delegatePositionName: undefined,
        sequence: acceptors.filter((x) => x.sectionSequence === sectionSequence).length + 1,
        status: 'Draft',
        seqKey: `${sectionSequence?.toString()}${(acceptors.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
      };
      setAcceptors((acceptors) => [...acceptors, newData]);
    }
  };

  const removeAccepter = (seqKey: string) => {
    const temp: ContractAgreementAcceptorModel[] = [...acceptors];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    setAcceptors(temp);
  };

  const onSave = async (isSendToApprove: boolean) => {
    // if (!isSendToApprove) {
    //   const updateBoxData: UpdateDocBoxNumberStep4Model = {
    //     documentBoxNumber: contractData.documentBoxNumber,
    //   };

    //   setShowSaveConfirmModal(false);
    //   const response = await CM02Service.updateDocumentBoxNumberStep4Async(contractData.id, updateBoxData);
    //   if (response.status !== HttpStatusCode.OK) {
    //     toast.error('ไม่สามารถบันทึกข้อมูลเลขที่กล่องเอกสารได้');

    //     return;
    //   }
    // }

    // const updateData: UpdateAcceptorStep4Model = {
    //   acceptors: acceptors,
    //   sendToApprove: isSendToApprove,
    // }

    // const { data, status } = await CM02Service.updateAcceptorStep4Async(contractData.id, updateData);
    // if (status !== HttpStatusCode.OK) {
    //   toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

    //   return;
    // }

    // setContractData({ ...contractData, documentBoxNumber: updateBoxData });

    // if (isSendToApprove) {
    //   setShowPendingModal(false);
    // }

    if (!contractData.isChange) {
      collaboraRef.current?.clickSave();
      setTimeout(async () => {
        await CM02Service.updateContractSignDocumentByIdAsync(contractData.id, docId);
        getDocument(dataContext.id)
      }, 500);
    }

    toast.success('บันทึกข้อมูลสัญญาสำเร็จ');
    getContractByIdAsync(contractData.id);
    setShowSaveConfirmModal(false);
  };

  const getContractByIdAsync = async (id: string) => {
    const {
      data,
      status,
    } = await CM02Service.getContractByIdAsync(id);
    if (status === HttpStatusCode.OK) {
      setDataContext(data);
    }
  };

  const onGetDefaultApprover = async () => {
    const response = await account
      .getDefaultApproverByTopLeve(PROCESSTYPE.ApproveSignContractProcess, contractData.offerTotalAmount);
    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: ContractAgreementAcceptorModel[] = [];

      if (dataContext.contractAgreementAcceptors === undefined ||
        dataContext.contractAgreementAcceptors === null ||
        dataContext.contractAgreementAcceptors.length === 0) {
        newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {

            sectionDataGroup.push({
              sectionName: item.sectionName,
              level: item.sectionLevel,
              sectionSequence: item.sectionSequence,
              inRefCode: item.inRefCode,
              sectionNameTh: item.sectionName,
              sectionId: item.sectionId,
            } as sectionGroup);

            approver.push({
              id: undefined,
              sectionInRefCode: item.inRefCode!,
              sectionSequence: item.sectionSequence!,
              sectionName: item.sectionName!,
              userId: item.userId,
              fullName: item.fullName,
              positionName: item.positionName,
              departmentName: item.departmentName,
              isAvailable: false,
              availableRemark: undefined,
              acceptDate: undefined,
              acceptRemark: undefined,
              rejectDate: undefined,
              rejectRemark: undefined,
              delegateUserId: item.delegateUserId,
              delegateEmployeeCode: item.delegateEmployeeCode,
              delegateFullName: item.delegateFullName,
              delegateDepartmentName: item.delegateDepartmentName,
              delegatePositionName: item.delegatePositionName,
              sequence: acceptors.filter((x) => x.sectionSequence === item.sectionSequence).length + 1,
              status: 'Draft',
              seqKey: `${item.sectionSequence?.toString()}${(acceptors.filter((x) => x.sectionSequence === item.sectionSequence).length + 1).toString()}`,
            } as ContractAgreementAcceptorModel);
          });

        const uniqueNames = sectionDataGroup.filter((data, index) => {
          return sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index;
        });

        if (acceptors === undefined || acceptors === null || acceptors.length === 0) {
          let inx = 0;
          let sectionSeq = 0;

          approver?.sort((a, b) => a.sectionSequence - b.sectionSequence)
            .forEach((item, index) => {

              if (sectionSeq !== item.sectionSequence) {
                inx = 1;
                sectionSeq = item.sectionSequence;
              }

              approver[index].sequence = inx;

              inx++;
            });

          setAcceptors(approver);
          setSections(uniqueNames);
        }
      } else {
        dataContext.contractAgreementAcceptors.forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: index,
            sectionSequence: item.sectionSequence,
            inRefCode: item.sectionInRefCode,
            sectionNameTh: item.sectionName,
            sectionId: '',
          } as sectionGroup);
        });

        const uniqueNames = sectionDataGroup.filter((data, index) => {
          return sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index;
        });
        setSections(uniqueNames);
      }
    }
  };

  const onOpenModal = (index: number) => {
    setShowUserModals((showUserModals) => {
      const newModal = [...showUserModals];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideModal = (index: number) => {
    setShowUserModals((showUserModals) => {
      const newModal = [...showUserModals];
      newModal[index] = false;
      return newModal;
    });
  };

  const onApprove = async () => {
    setShowApproveModal(false);
    const {
      data,
      status,
    } = await CM02Service.approveStep4Async(contractData.id, approveReason);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถเห็นชอบ/อนุมัติได้');

      return;
    }

    toast.success('เห็นชอบ/อนุมัติสำเร็จ');
    getContractByIdAsync(contractData.id);
  };

  const onReject = async () => {
    setShowRejectModal(false);
    const {
      data,
      status,
    } = await CM02Service.rejectStep4Async(contractData.id, rejectReason);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถส่งกลับแก้ไขได้');

      return;
    }

    toast.success('ส่งกลับแก้ไขสำเร็จ');
    getContractByIdAsync(contractData.id);
  };

  const checkBooleanNullOrUndefined = (val?: boolean) => (val === null || val === undefined);

  const onChangeContractType = (contractType: string) => {
    setContractData({ ...contractData, contractType });
    getContractFormat(contractType);
  };

  const getContractFormat = async (contractType: string) => {
    const { data, status } = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractFormat, contractType);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setContractFormatDDL(data);
  };

  const onOpenCoiModal = (traderModel: EntrepreneurModel, modalType: string, result?: boolean, remark?: string) => {
    setEntrepreneur(traderModel);
    setModalResult(result);
    setModalRemark(remark);
    if (modalType === TraderModalTypeConstant.COI) {
      setModalTitle('COI');
      setModalType(TraderModalTypeConstant.COI);
    } else if (modalType === TraderModalTypeConstant.WATCHLIST) {
      setModalTitle('Watchlist');
      setModalType(TraderModalTypeConstant.WATCHLIST);
    } else {
      setModalTitle('ตรวจสอบผู้ทิ้งงาน ครั้งที่ 2');
      setModalType(TraderModalTypeConstant.EGP);
    }
    setShowCoiModal(true);
  };

  const onSubmitTraderResultModal = async (traderId: string, modalType: string, remark: string, isAccept?: boolean, files?: IFile[]) => {
    const entrepreneurData = contractData.entrepreneur;
    const docUpload: AttachmentModel[] = [];
    if (files && !entrepreneurData.id) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push({ id: i.id, fileName: i.name, createdDate: new Date() } as AttachmentModel);
        }
      });
    }
    switch (modalType) {
      case TraderModalTypeConstant.COI:
        entrepreneurData.coiResult = isAccept;
        entrepreneurData.coiRemark = remark;
        entrepreneurData.coiAttachments = docUpload;
        setContractData({ ...contractData, entrepreneur: entrepreneurData });
        onSaveEnterprenuar(modalType, remark, isAccept);
        return;
      case TraderModalTypeConstant.WATCHLIST:
        entrepreneurData.watchListResult = isAccept;
        entrepreneurData.watchListRemark = remark;
        entrepreneurData.watchListAttachments = docUpload;
        setContractData({ ...contractData, entrepreneur: entrepreneurData });
        onSaveEnterprenuar(modalType, remark, isAccept);
        return;
      case TraderModalTypeConstant.EGP:
        entrepreneurData.egpResult = isAccept;
        entrepreneurData.egpRemark = remark;
        entrepreneurData.egpAttachments = docUpload;
        setContractData({ ...contractData, entrepreneur: entrepreneurData });
        onSaveEnterprenuar(modalType, remark, isAccept);
        return;
    }
  };

  const onSaveEnterprenuar = async (modalType: string, remark: string, isAccept?: boolean) => {
    const updateData: UpdateEntrepreneurConsiderationModel = {
      result: isAccept!,
      remark,
      type: modalType,
    };
    const { data, status } = await CM02Service.UpdateContractCoiWatchListEgpStep4Async(contractData.id, updateData);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

      return;
    }
    getContractByIdAsync(contractData.id);
    toast.success('บันทึกข้อมูลคู่ค้าสำเร็จ');
  };

  return (
    <div className='document'>

      {/* <Card className="mt-3">
        <Title
          text='ผู้มีอำนาจลงนาม'
          className='fs-5 text-primary'
        />
        <Row className="mt-4 align-items-center">
          {
            sections.length > 0 ? (
              sections
                .sort((a, b) => a.sectionSequence - b.sectionSequence)
                .map((section, i) => (
                  <Col sm={12} className="ps-5" key={section.sectionName}>
                    <div className="d-flex justify-content-between">
                      <h5 className='fw-bold'>{section.sectionName}</h5>
                      <Button
                        variant="primary"
                        disabled={isDisabled}
                        onClick={() => onOpenModal(i)}
                      >
                        <FaPlus className='me-1' /> เพิ่มรายชื่อ
                      </Button>
                      <UserSectionApproveModal
                        show={showUserModals[i]}
                        onHide={() => onHideModal(i)}
                        onSelectItem={onSelectEmpApprover}
                        sectionSequence={section.sectionSequence!}
                        sectionName={section.sectionName!}
                        inRefCode={section.inRefCode!}
                      />
                    </div>
                    <Table notResponse hidePagination className="mt-3 step-bar">
                      <thead>
                        <tr>
                          <th style={{ width: 20 }}>ลำดับ</th>
                          <th className="text-start" style={{ width: '50%' }}>ชื่อ-นามสกุล</th>
                          <th style={{ width: 100 }}>สถานะ</th>
                          <th style={{ width: 100 }}>วันที่เห็นชอบ</th>
                          <th style={{ width: 100 }} />
                        </tr>
                      </thead>
                      <tbody>
                        {acceptors?.filter((x) => x.sectionSequence === section.sectionSequence)
                          .sort((a, b) => a.sequence - b.sequence)
                          .map((data, index) => (
                            <>
                              <tr>
                                <td className='text-center'>{index + 1}</td>
                                <td className='text-left'>
                                  {data.fullName}
                                  <p className="m-0 department">
                                    {data.positionName}
                                    // {data.departmentName &&
                                    //   <>
                                    //     <span className='mx-1'>|</span> {data.departmentName}
                                    //   </>
                                    // }
                                  </p>
                                </td>
                                <td className='text-center'>
                                  <StatusCM systemStatue={data.status} systemName='cm-03' />
                                </td>
                                <td className='text-center'>
                                  {fullDatetimeTH(data.acceptDate!)}
                                </td>
                                <td className='text-end'>
                                  <Button
                                    onClick={() => removeAccepter(`${data.sectionSequence}${data.sequence}`)}
                                    variant="danger"
                                    disabled={isDisabled}
                                  >
                                    <FaTrashAlt />
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                ))
            ) : (
              <Col>
                <span className="text-primary">ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
              </Col>
            )
          }
        </Row>
      </Card> */}
      <Card className='mt-3'>
        <Title text='ตรวจสอบคู่ค้า'
          className='fs-5 text-primary' />
        <Row className='mt-3'>
          <Col sm={12}>
            <Table hidePagination>
              <thead>
                <tr className='text-center'>
                  <th>เลขประจำตัวผู้เสียภาษี</th>
                  <th>ประเภทผู้ประกอบการ</th>
                  <th>ชื่อสถานประกอบการ</th>
                  <th>อีเมล</th>
                  <th>COI</th>
                  <th>Watchlist</th>
                  <th>ตรวจสอบผู้ทิ้งงาน ครั้งที่ 2</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-center'>
                  <td>{contractData.contractCoiWatchListEgp?.taxId}</td>
                  <td className='text-center'>
                    {contractData.contractCoiWatchListEgp?.traderType === 'JuristicPerson' ? 'นิติบุคคล' : contractData.contractCoiWatchListEgp?.traderType === 'Individual' ? 'บุคคลธรรมดา' : ''}
                  </td>
                  <td>{contractData.contractCoiWatchListEgp?.name}</td>
                  <td>
                    <Input type='text'
                      value={contractData.contractCoiWatchListEgp?.email}
                      disabled={isDisabled} />
                  </td>
                  <td>
                    <ButtonCustom
                      variant={checkBooleanNullOrUndefined(contractData.contractCoiWatchListEgp?.coiResult) ? 'primary' : contractData.contractCoiWatchListEgp?.coiResult ? 'success' : 'danger'}
                      onClick={() => onOpenCoiModal(contractData.contractCoiWatchListEgp, TraderModalTypeConstant.COI, contractData.contractCoiWatchListEgp?.coiResult, contractData.contractCoiWatchListEgp?.coiRemark)}
                      text={checkBooleanNullOrUndefined(contractData.contractCoiWatchListEgp?.coiResult) ? 'บันทึกผล' : 'บันทึกแล้ว'} />
                  </td>
                  <td>
                    <ButtonCustom
                      variant={checkBooleanNullOrUndefined(contractData.contractCoiWatchListEgp?.watchListResult) ? 'primary' : contractData.contractCoiWatchListEgp?.watchListResult ? 'success' : 'danger'}
                      onClick={() => onOpenCoiModal(contractData.contractCoiWatchListEgp, TraderModalTypeConstant.WATCHLIST, contractData.contractCoiWatchListEgp?.watchListResult, contractData.contractCoiWatchListEgp?.watchListRemark)}
                      text={checkBooleanNullOrUndefined(contractData.contractCoiWatchListEgp?.watchListResult) ? 'บันทึกผล' : 'บันทึกแล้ว'} />
                  </td>
                  <td>
                    <ButtonCustom
                      variant={checkBooleanNullOrUndefined(contractData.contractCoiWatchListEgp?.egpResult) ? 'primary' : contractData.contractCoiWatchListEgp?.egpResult ? 'success' : 'danger'}
                      onClick={() => onOpenCoiModal(contractData.contractCoiWatchListEgp, TraderModalTypeConstant.EGP, contractData.contractCoiWatchListEgp?.egpResult, contractData.contractCoiWatchListEgp?.egpRemark)}
                      text={checkBooleanNullOrUndefined(contractData.contractCoiWatchListEgp?.egpResult) ? 'บันทึกผล' : 'บันทึกแล้ว'} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title
          text='เอกสารร่างสัญญาหรือข้อตกลง'
          className='fs-5 text-primary'
          extraElement={(
            <Button variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}>
              <MdHistory /> ประวัติการแก้ไข
            </Button>
          )}
        />
        <div className='mt-3'>
          <Collabora
            docId={docId}
            docName='doc-02'
            ref={collaboraRef}
            readonly={false} />
        </div>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <div>
          {
            (contractData.isResponsible && (contractData.status === ContractAgreementStatus.WaitingForAttachSignedDocument
              || contractData.status === ContractAgreementStatus.RejectedSign)) ?
              <div>
                <Button
                  onClick={() => setShowSaveConfirmModal(true)}
                  variant="success"
                >
                  <MdSave />บันทึก
                </Button>
                {/* <Button
                  onClick={() => setShowPendingModal(true)}
                  className="me-2 px-3 ms-2"
                >
                  <MdSegment className='me-2 pb-1 fs-5' />
                  ส่งตรวจสอบ
                </Button> */}
              </div>
              : <></>
          }
          {/* {
            contractData.status === ContractAgreementStatus.WaitingForSign && isCanApprove ?
              <div>
                <Button
                  onClick={() => setShowRejectModal(true)}
                  variant="danger"
                >
                  <MdArrowBack className='me-2 pb-1 fs-5' />ส่งกลับแก้ไข
                </Button>
                <Button
                  onClick={() => setShowApproveModal(true)}
                  className="me-2 px-3 ms-2"
                >
                  <MdSegment className='me-2 pb-1 fs-5' />
                  เห็นชอบ
                </Button>
              </div> : <></>
          } */}
        </div>
        <ButtonCustom
          onClick={onClickNext}
          text="ถัดไป"
        />
      </div>
      <CoiModalStep4
        isResponsible={contractData.isResponsible}
        title={modalTitle}
        show={showCoiModal}
        modalType={modalType}
        onHide={() => setShowCoiModal(!showCoiModal)}
        traderModal={entrepreneur}
        entrepreneurTypeDDL={entrepreneurTypeDDL}
        onSubmit={onSubmitTraderResultModal}
        contractId={contractData.id}
      />
      <HistoryModal
        title='ประวัติการใช้งานร่างข้อมูลสัญญา'
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={contractData.id}
      />
      <Modal
        show={showSaveConfirmModal}
        size='lg'
        onHide={() => setShowSaveConfirmModal(!showSaveConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการบันทึกข้อมูลหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowSaveConfirmModal(!showSaveConfirmModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(false)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showPendingModal}
        size='lg'
        onHide={() => setShowPendingModal(!showPendingModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งลงนามสัญญา</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งลงนามสัญญาหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingModal(!showPendingModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApproveModal}
        size='lg'
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ความเห็นชอบพิจารณา</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body>
              <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                onChange={(val) => setApproveReason(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApproveModal(!showApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='success'
                onClick={onApprove}
              >
                <MdSegment className='me-2 pb-1 fs-5' />
                ลงนามสัญญา
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejectModal}
        size='lg'
        onHide={() => setShowRejectModal(!showRejectModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body>
              <InputTextArea label='หมายเหตุ'
                onChange={(val) => setRejectReason(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowRejectModal(!showRejectModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={onReject}
              >
                <FaUndo className='me-2 pb-1 fs-5' />ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}
