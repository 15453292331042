import {
  Card,
  InputTextArea,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaSave,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">

      <Card className="mt-3">
        <Title text="บันทึกความเห็น" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12}>
            <InputTextArea label="ความเห็น" />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-center gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          variant="light"
          className="me-2 px-3"
        >
          <FaSave className="me-2 pb-1 fs-5" />บันทึก
        </Button>
        <Button
          className="me-2 px-3"
        >
          <FaAlignLeft className="me-2 pb-1 fs-5" />
          ยืนยัน
        </Button>
      </div>
    </div>
  );
}

export default Step2;
