import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Cookie from 'cookie-universal';
import { account } from 'services';
import { HttpStatusCode } from 'utils';
import toast from './toast';
import loading from './loading';

const cookies = Cookie();

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

Axios.interceptors.request.use(
  async (request) => {
    const accessToken = cookies.get('access-token');
    const ipAddress = cookies.get('ipAddress');

    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (ipAddress) {
      request.headers['X-Forwarded-For'] = ipAddress;
    }

    showLoading(request);

    return request;
  }, (error) => {
    hideLoading(error);

    return error.response;
  },
);

Axios.interceptors.response.use(
  (response) => {
    hideLoading(response);

    return response;
  }, async (error) => {
    hideLoading(error);

    if (error.response?.status === HttpStatusCode.UNAUTHORIZED && !error.config._retry) {
      error.config._retry = true;

      const refreshTokenRes = await account.refreshTokenAsync();

      if (refreshTokenRes.status !== HttpStatusCode.OK) {
        cookies.removeAll();

        const path = window.location.pathname;

        if (path !== '/web/login') {
          toast.error('ไม่สามารถเข้าสู่ระบบได้');

          window.location.href = '/web/login';
        }
      }

      return Axios(error.config);
    }

    handlerErrorMessage(error);

    return error.response;
  },
);

const handlerErrorMessage = (error: AxiosError) => {
  switch (error.response?.status) {
    case HttpStatusCode.FORBIDDEN:
      toast.error('หวงห้าม');
      break;
    case HttpStatusCode.METHOD_NOT_ALLOWED:
      toast.error('วิธีที่ใช้ไม่ได้รับการอนุญาต');
      break;
    case HttpStatusCode.CONFLICT:
      toast.error('ข้อมูลซ้ำ');
      break;
    case HttpStatusCode.INTERNAL_SERVER_ERROR:
      toast.error('เกิดความผิดพลาดที่เซิฟเวอร์');
      break;
    default:
  }
};

const showLoading = (request: InternalAxiosRequestConfig) => {
  if (request.headers?.disabledLoading) {
    return;
  }

  loading.showLoading();
};

const hideLoading = (response: AxiosResponse) => {
  if (response.config.headers?.disabledLoading) {
    return;
  }

  loading.hideLoading();
};

export default Axios;