import React from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Readonly,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ContractAgreementModel } from '../../../../../../models/CM/CM02Models';

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

interface IProps {
  data: ContractAgreementModel;
}

export function ProjectDetail(props: IProps) {
  return (
    <Card className='mt-3'>
      <Title text='ข้อมูลโครงการ' className='fs-5 text-primary' />
      <Row className='mt-3 align-items-center'>
        <Col sm={12} md={6}>
          <Readonly label='เลขที่รายการจัดซื้อจัดจ้าง' textReadonly={props.data.planName} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Readonly label='โครงการ' textReadonly={props.data.planName} />
        </Col>
        <Col sm={12} md={3}>
          <Readonly label='งบประมาณโครงการ (บาท)' textReadonly={formatter.format(props.data.financialAmount)} />
        </Col>
      </Row>
      <Row className='mt-3 align-items-center'>
        <Col sm={12} md={3} xl={3}>
          <Readonly label='วิธีจัดหา' textReadonly={props.data.supplyMethodName} />
        </Col>
        <Col sm={12} md={3}>
          <Readonly textReadonly={props.data.supplyMethodTypeName} />
        </Col>
        <Col sm={12} md={3}>
          <Readonly textReadonly={props.data.supplyMethodSpecialTypeName} />
        </Col>
      </Row>
    </Card>
  );
}
