import { Rule } from 'models';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import Select, {
  SingleValue,
  StylesConfig,
} from 'react-select';

interface Options {
  value: string;
  label: string;
}

interface Props {
  label?: string;
  placeholder?: string;
  rule?: Rule;
  value?: string | undefined;
  defaultValue?: string | undefined;
  name?: string;
  onChange?: (value: string) => void;
  items?: Options[];
  className?: string;
  disabled?: boolean;
  size?: 'sm';
  isHideClearButton?: boolean;
  disabledStar?: boolean;
}

export function Selector(props: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [value, setValue] = useState<SingleValue<Options> | null>();

  document.addEventListener('onFormSubmit', (data) => {
    const event = data as CustomEvent;

    if (event.detail.hasNameOnly) {
      if (props.name) {
        validate(value);
      }
    } else {
      validate(value);
    }
  });

  document.addEventListener('onClearError', () => {
    setErrorMessage('');
  });

  useEffect(() => {
    if (props.value) {
      const item = props.items?.find((i) => i.value === props.value);

      if (item) {
        setValue({ ...item });
      }
    } else if (props.defaultValue) {
      const item = props.items?.find((i) => i.value === props.defaultValue);

      if (item) {
        setValue({ ...item });
      }

      if (props.onChange) {
        props.onChange(item?.value ?? '');
      }

    } else {
      setValue(null);
    }
  }, [props.value, props.items]);

  const validate = useCallback((value: SingleValue<Options> | undefined) => {
    const checkRequired = (value: SingleValue<Options> | undefined) => {
      if (props.rule?.required && !value?.value) {
        setErrorMessage('กรุณาเลือกข้อมูล');

        return false;
      }

      return true;
    };

    if (!checkRequired(value)) {
      return;
    }

    setErrorMessage('');
  }, [props.rule?.required]);

  const getRequired = () => {
    if (props.rule?.required && props.disabledStar) {
      return null;
    }

    if (props.rule?.required) {
      return <span className='text-danger'>*</span>;
    }

    return null;
  };

  const getErrorMessage = useMemo(() => {
    if (errorMessage) {
      return <Form.Text className='text-danger'>{errorMessage}</Form.Text>;
    }

    return null;
  }, [errorMessage]);

  const handlerOnChange = (value: SingleValue<Options>) => {
    if (props.onChange) {
      if (value) {
        props.onChange(value.value);

        setValue(value);
      } else {
        props.onChange('');

        setValue(null);
      }
    }

    validate(value);
  };

  const styles: StylesConfig = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor:
        state.isSelected ? '#FF850D'
          : state.isFocused ? '#ffe6cd'
            : '',
      zIndex: '4',
      ':active': {
        ...styles[':active'],
        backgroundColor: '#ffe6cd',
        color: 'black',
      },
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#ffe6cd',
        color: 'black',
      },
    }),
  };

  useEffect(() => {
    const invalid = document.getElementsByClassName("is-invalid")[0];

    if (invalid) {
      invalid.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

  }, [getErrorMessage]);

  return (
    <Form.Group className={`select w-100 ${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
      {props.label ? <Form.Label>{props.label} {getRequired()}</Form.Label> : null}
      <Select
        className={`${getErrorMessage ? 'is-invalid' : ''} ${props.disabled ? 'disabled' : ''}`}
        value={value}
        onChange={(event) => handlerOnChange(event as SingleValue<Options>)}
        options={props.items}
        placeholder={props.placeholder || ''}
        isDisabled={props.disabled}
        styles={styles}
        menuShouldBlockScroll
        isClearable={!props.isHideClearButton}
        noOptionsMessage={() => (<p className='m-0'>ไม่มีข้อมูล</p>)}
        menuPosition='fixed'
      />
      {getErrorMessage}
    </Form.Group>
  );
}
