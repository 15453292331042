import {
  Card,
  HistoryModal,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
  TorStatus,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaAngleLeft,
  FaHistory,
  FaPlus,
  FaSave,
} from 'react-icons/fa';
import { MdArrowForward } from 'react-icons/md';
import { generateUniqueId } from '../../../../utils/helper';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}
const monthNames = ['ม.ค. - มี.ค.', 'เม.ย. - มิ.ย', 'ก.ค. - ก.ย.', 'ต.ค. - ธ.ค.'];

function Step1({ onClickNext, onClickBack }: Props) {
  const [showHistory, setShowHistory] = useState(false);
  const [budgetYearItems, setBudgetYearItems] = useState<ItemModel[]>([]);
  const [monthItems, setMonthItems] = useState<ItemModel[]>([]);
  useEffect(() => {
    const date = new Date('01/01/2023');
    let month = (date.getMonth() - 1);

    const items = Array.from({ length: 4 }).map((i) => {
      month += 1;

      return {
        label: monthNames[month],
        value: month.toString(),
      };
    });
    let year = (date.getUTCFullYear() + 543) - 10;

    const yItems = Array.from({ length: 20 }).map((i) => {
      year += 1;

      return {
        label: year.toString(),
        value: year.toString(),
      };
    });

    setBudgetYearItems(yItems);
    setMonthItems(items);
  }, []);

  return (
    <div className='step1'>
      <Card>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <Title text='ข้อมูลรายงานสำนักงานตรวจเงินแผ่นดินและหนังสือถึงสรรพากร' className='fs-5 text-primary' />
          <div className='d-flex gap-2'>
            <TorStatus
              value='Annoucement'
            />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'>
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Row>
          <Col sm={4}>
            <Input
              label='เลขที่เอกสารรายงานสำนักงานตรวจเงินแผนดินและหนังสือถึงสรรพากร'
              value='OAG660001'
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Selector
              label='ปี'
              items={budgetYearItems}
              value='2566'
            />
          </Col>
          <Col sm={3}>
            <Selector
              label='เดือน'
              items={monthItems}
              value='0'
            />
          </Col>
        </Row>
        <Table hidePagination>
          <thead>
            <tr>
              <th style={{ minWidth: 80 }}>เลขที่สัญญา</th>
              <th style={{ minWidth: 150 }}>สถานะ</th>
              <th style={{ minWidth: 100 }}>เลขที่โครงการ</th>
              <th style={{ minWidth: 250 }}>ชื่อโครงการ</th>
              <th style={{ minWidth: 80 }}>ผู้ประกอบการ/<br />คู่ค้า</th>
              <th style={{ minWidth: 150 }}>จำนวนเงิน</th>
              <th style={{ minWidth: 100 }}>วันที่ทำเริ่ม<br />สัญญา</th>
              <th style={{ minWidth: 100 }}>วันที่สิ้นสุด<br />สัญญา</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 2 }).map((_, index) => (
              <tr className='text-center' key={generateUniqueId(index)}>
                <td>
                  76600{index + 1}
                </td>
                <td>
                  <Status type={StatusType.PROCESS}
                    value={index < 3 ? 'DraftPlan' : 'ApprovePlan'} />
                </td>
                <td>
                  {index === 0 ? 'PL0001' : 'PL0002'}
                </td>
                <td className='text-start'>
                  จ้างที่ปรึกษา
                </td>
                <td>
                  {index === 0 ? 'บริษัท ออนไลน์ เน็ตเวิร์ค' : 'บริษัท ออฟไฟล์ เน็ตเวิร์ค'}
                </td>
                <td>
                  {index === 0 ? '300,000' : '600,000'}
                </td>
                <td>
                  {index === 0 ? '05/01/2566' : '10/01/2566'}
                </td>
                <td>
                  {index === 0 ? '10/01/2566' : '20/01/2566'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
      <Card className='mt-4'>
        <div className='d-flex mb-4 align-items-center justify-content-start'>
          <Title text='มอบหมายงาน จพ.' className='fs-5 text-primary' />
        </div>
        <div className='d-flex justify-content-end mb-2'>
          <Button
            variant='outline-primary'>
            <FaPlus className='fs-5 me-2' />เพิ่มรายชื่อ
          </Button>
        </div>
        <Table total={5} hidePagination>
          <thead>
            <tr>
              <th style={{ minWidth: 20 }}>ลำดับ</th>
              <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 200 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 200 }}>ตำแหน่ง</th>
              <th style={{ minWidth: 120 }}>สถานะ</th>
              <th style={{ minHeight: 100 }}>วันที่เห็นชอบ</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 2 }).map((_, index) => (
              <tr className='text-center' key={generateUniqueId(index)}>
                <td>
                  {index + 1}
                </td>
                <td>
                  สมศรี แดนไทย
                </td>
                <td>
                  {index === 0 ? 'จัดซื้อจัดจ้างพัสดุ' : 'เทคโนโลยี'}
                </td>
                <td className='text-start'>
                  รักษาการผู้อำนวยการสำนักงบประมาณ
                </td>
                <td>
                  <div className='bg-success text-white rounded'>
                    เห็นชอบ/อนุมัติ
                  </div>
                </td>
                <td>
                  {index === 0 ? '10/01/2566' : '11/01/2566'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12} lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <Button
          variant='outline-primary'
          onClick={onClickBack}>
          <FaAngleLeft className='me-2 pb-1 fs-5' />
          กลับหน้าแรก
        </Button>
        <div>
          <Button
            variant='success'>
            <FaSave className='me-2 pb-1 fs-5' />
            บันทึก
          </Button>
          <Button
            className='mx-2'
            variant='primary'>
            <FaAlignLeft className='me-2 pb-1 fs-5' />
            มอบหมาย
          </Button>
        </div>
        <Button
          className='me-2 px-3'
          onClick={onClickNext}
          variant='outline-primary'>
          ถัดไป
          <MdArrowForward className='ms-2 pb-1 fs-5' />
        </Button>
      </div>
      <HistoryModal
        title='ประวัติการใช้งาน'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        data={dataHistory}
      />
    </div>
  );
}

const dataHistory = [
  {
    remark: '',
    action: 'สร้างข้อมูล',
    activity: 'สร้างข้อมูล รายงาน',
    fullNameAction: 'สมหญิง รักษ์เมืองไทย',
    activityDate: new Date(),
  },
  {
    remark: '',
    action: 'อัพเดทข้อมูล',
    activity: 'อัพเดทข้อมูล รายงาน',
    fullNameAction: 'สมหญิง รักษ์เมืองไทย',
    activityDate: new Date(),
  },
];

export default Step1;
