import {
  Selector,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import {
  CriteriaPlanProcurementList,
  ItemModel,
  PlanProcurementList,
} from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { planProcurement } from 'services';
import {
  HttpStatusCode,
  THCurrency,
} from 'utils';
import { useForm } from 'utils/useForm';
import { Modal } from './Modal';

interface Props {
    show: boolean;
    onHide?: () => void;
    onSelectItem?: (planId: string) => void;
    budgetAmount?: number;
}

interface FormInput {
    planNumber: string;
    planName: string;

}

const enum PlanType {
    AnnualPlan = 'AnnualPlan',
    InYearPlan = 'InYearPlan',
}

const enum PlanStatus {
    Announcement = 'Announcement',
    ApprovePlan = 'ApprovePlan',
    ApproveAcceptor = 'ApproveAcceptor',
}

export function PlanModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [planData, setplanData] = useState<PlanProcurementList[]>([]);
  const [criteriaData, setcriteriaData] = useState<CriteriaPlanProcurementList>({} as CriteriaPlanProcurementList);
  const [planType, setPlanType] = useState('');
  const [totalRecord, setTotalRecord] = useState(0);

  useEffect(() => {
    onGetPlanLiatAsync({} as CriteriaPlanProcurementList);
    setPlanType(PlanType.AnnualPlan);
  }, []);

  const onSelect = (planId: string) => {
        props.onSelectItem!(planId);
        props.onHide!();
  };

  const onGetPlanLiatAsync = async (criteria: CriteriaPlanProcurementList, planType?: string, pageParam?: number, sizeParam?: number) => {
    const sizeValue = sizeParam || size;
    const pageValue = pageParam || page;

    if (planType === '' || planType === undefined) {
      planType = PlanType.AnnualPlan;
    }

    if (props.budgetAmount) {
      if (props.budgetAmount > 500000) {
        criteria.status = [PlanStatus.Announcement];
      } else {
        criteria.status = [PlanStatus.Announcement, PlanStatus.ApprovePlan];
      }
    } else {
      criteria.status = [PlanStatus.Announcement, PlanStatus.ApprovePlan];
    }

    const res = await planProcurement.getPlanProcurementListAsync(pageValue, sizeValue, planType, criteria);
    if (res.status === HttpStatusCode.OK) {
      const { data } = res.data;
      setplanData(data);
      setTotalRecord(res.data.totalRecords);
    }
  };

  const onClear = useCallback(async () => {
    setPlanType('');
    handleClear();
  }, []);

  const { handleSubmit, handleChange, data: formData, errors, initFormData, handleClear } = useForm<FormInput>({
    initialValues: {
      planName: '',
      planNumber: '',
    },
    validations: {},
    onSubmit: async () => {
      const status: string[] = [];
      const method: string[] = [];

      const searchModel: CriteriaPlanProcurementList = {
        planNumber: formData.planNumber,
        departmentId: undefined,
        name: formData.planName,
        budget: undefined,
        budgetYear: undefined,
        expectingProcurementAt: undefined,
        supplyMethod: method,
        status,
      };
      onGetPlanLiatAsync(searchModel, planType);
    },
  });

  const handleSetPagination = (page: number, size: number) => {
    const status: string[] = [];
    const method: string[] = [];

    const searchModel: CriteriaPlanProcurementList = {
      planNumber: formData.planNumber,
      departmentId: undefined,
      name: formData.planName,
      budget: undefined,
      budgetYear: undefined,
      expectingProcurementAt: undefined,
      supplyMethod: method,
      status,
    };
    onGetPlanLiatAsync(searchModel, planType, page, size);
  };

  const typePlanOptions: ItemModel[] = [
    { value: PlanType.AnnualPlan, label: 'รายการจัดซื้อจัดจ้าง' },
    { value: PlanType.InYearPlan, label: 'รายการจัดซื้อจัดจ้าง (ระหว่างปี)' },
  ];

  return (

    <Modal
      show={props.show}
      size="xl"
      onHide={() => (props.onHide ? props.onHide() : null)}
    >
      <ModalBT.Header closeButton>
        <ModalBT.Title>
          <Title
            text="ค้นหารายการจัดซื้อจัดจ้าง"
            className="fs-5 text-primary"
          />
        </ModalBT.Title>
      </ModalBT.Header>
      <ModalBT.Body className="p-0 ps-4">
        <Form onSubmit={handleSubmit} id="userModalForm">
          <Row className="align-items-center">
            <Col sm={12} md={4} xl={4}>
              <Form.Label>เลขที่รายการจัดซื้อจัดจ้าง</Form.Label>
              <Form.Group className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={formData.planNumber || ''}
                    onChange={handleChange<string>('planNumber', (value) => value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={12} md={4} xl={4}>
              <Form.Label>ชื่อโครงการ</Form.Label>
              <Form.Group className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={formData.planName}
                    onChange={handleChange<string>('planName', (value) => value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={12} md={4} xl={4}>
              <Selector
                label="รายการจัดซื้อจัดจ้าง"
                items={typePlanOptions}
                onChange={(val) => setPlanType(val)}
                value={planType}
              />
            </Col>
          </Row>
          <div className="d-flex gap-2">
            <Button variant="primary" className="px-3" type="submit" form="userModalForm">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              className="px-4"
              variant="outline-primary"
              onClick={onClear}
              type="submit"
              form="userModalForm"
            >
              ล้าง
            </Button>
          </div>
        </Form>
        <Table
          className="my-3"
          total={totalRecord}
          onChange={(size, page) => {
            handleSetPagination(page, size);
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 20 }}>ลำดับ</th>
              <th style={{ minWidth: 50 }}>เลขที่รายการจัดซื้อจัดจ้าง</th>
              <th style={{ minWidth: 50 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 50 }}>ชื่อโครงการ</th>
              <th style={{ minWidth: 50 }}>วิธีจัดหา</th>
              <th style={{ minWidth: 50 }}>วงเงิน</th>
              <th style={{ minWidth: 25 }} />
            </tr>
          </thead>
          <tbody>
            {planData?.map((data, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{data.planNumber}</td>
                <td className="text-start">{data.department}</td>
                <td className="text-start">{data.name}</td>
                <td className="text-center">{data.supplyMethod} </td>
                <td className="text-end">{THCurrency(data.budget)}</td>
                <td>
                  <Button variant="primary" className="px-3" onClick={() => onSelect(data.id)}>
                    เลือก
                                    </Button>
                </td>
              </tr>
            ))}

          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
