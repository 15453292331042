import { Card, SearchBuNameModal, Table, ContractCompleteReportStatus, Modal, Approver, ApproverStatus, TorStatus, InputTextArea, ButtonCustom } from 'components';
import Title from 'components/Controls/Title';
import { ContractCompleteReportAcceptorModel, ContractCompleteReportModel } from 'models/CM/ContractCompleteReportModel';
import { useCallback, useState, useContext, useEffect, useRef, useMemo } from 'react';
import { Button, Col, Row, Modal as ModalBT } from 'react-bootstrap';
import { FaHistory, FaPlus, FaRedo, FaRegCheckCircle, FaTrashAlt, FaUndo } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router';
import { HttpStatusCode, fullDatetime, fullDatetimeTH, useAppContext } from 'utils';
import { Context } from '.';
import toast from 'utils/toast';
import { UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { account, shareValue } from 'services';
import { JorPorCode } from 'constant/basicInformation';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import { sectionApproverModel } from 'models/delegateModel';
import ContractCompleteReportService from 'services/CM/ContractCompleteReportService';
import { TorStatusConstant } from 'constant/PreProcumentTorStatus';
import { isNull } from 'utils/helper';
import { HistoryModal } from '../Modal/HistoryModal';
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import { Quarter } from 'constant/ContractStatus';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

export default function Step2({ onClickNext, onClickBack }: Props) {
  const { dataContext, setDataContext } = useContext(Context);
  const [contractData, setContractData] = useState<ContractCompleteReportModel>({} as ContractCompleteReportModel);
  const navigate = useNavigate();
  const location = useLocation();
  const [showUserModal, setShowUserModal] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [acceptors, setAcceptors] = useState<ContractCompleteReportAcceptorModel[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [showDefaultApproverModal, setShowDefaultApproverModal] = useState<boolean>(false);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [showModals, setShowModals] = useState<boolean[]>([]);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [showHistory, setShowHistory] = useState(false);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const { userId, departmentCode } = useAppContext();
  const [currentPath, setCurrentPath] = useState('cm/cm05/detail');
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [isCanRecall, setIsCanRecall] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showRecallModal, setShowRecallModal] = useState(false);
  const [approveReason, setApproveReason] = useState<string>('');
  const [rejectReason, setRejectReason] = useState<string>('');
  const [recallReason, setRecallReason] = useState<string>('');
  const [docId, setDocId] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);
  const [isApprover, setIsApprover] = useState(false);
  const [quarter, setQuarter] = useState(0);

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = dataContext.contractCompleteReportAcceptors ? Math.max.apply(null,
      dataContext.contractCompleteReportAcceptors.map((x) => {
        return x.sectionSequence!;
      })) : 0;

    const valueSequence = dataContext.contractCompleteReportAcceptors?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence!);
    setIsSectionSequence(maxValueSectionSequence);
  };

  const getDocument = useCallback(

    async (id: string) => {

      let documentTemplate = 'CMCOMPLETE001';

      if (dataContext.quarterId === Quarter.Q2) {
        documentTemplate = 'CMCOMPLETE002';
      }
      if (dataContext.quarterId === Quarter.Q3) {
        documentTemplate = 'CMCOMPLETE003';
      }
      if (dataContext.quarterId === Quarter.Q4) {
        documentTemplate = 'CMCOMPLETE004';
      }

      const { data: docId, status } =
        await ContractCompleteReportService.getDocumentAsync(id, documentTemplate!);

      if (status === HttpStatusCode.OK) {
        setDocId(docId);
      }
    }, [dataContext]);

  useEffect(() => {
    if (dataContext) {
      setContractData(dataContext);

      getDocument(dataContext.id ?? '');

      if (dataContext.contractCompleteReportAcceptors) {
        setAcceptors(dataContext.contractCompleteReportAcceptors);
        setIsApprover(onCheckMessageApprover(dataContext.contractCompleteReportAcceptors));
        const sectionDataGroup: sectionGroup[] = [];
        dataContext.contractCompleteReportAcceptors.sort((a, b) => a.sectionSequence! - b.sectionSequence!)
          .forEach((item, index) => {
            sectionDataGroup.push({
              sectionName: item.sectionName,
              level: item.sectionLevel,
              sectionSequence: item.sectionSequence,
              inRefCode: item.inRefCode,
              sectionNameTh: item.sectionName,
              sectionId: item.sectionId,
            } as sectionGroup);
          });

        const uniqueNames = sectionDataGroup.filter((data, index) => {
          return sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index;
        });

        setSections(uniqueNames);
        onCheckQueueApprover(dataContext.contractCompleteReportAcceptors);
        onCheckApproverStatus();
        setIsCanRecall(!(dataContext.contractCompleteReportAcceptors.filter(a => a.status !== 'Pending').length > 0));
      }

      if (dataContext.contractCompleteReportAcceptors.length === 0) {
        onGetDefaultApprover();
      }
    }
  }, [dataContext]);

  const onCheckMessageApprover = (data: ContractCompleteReportAcceptorModel[]) => {

    const approver = data?.filter(x => x.status === 'Pending').length === 1;

    if (approver) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const paramId = location.pathname.slice(location.pathname.lastIndexOf('/'), location.pathname.length);

    setCurrentPath(location.pathname.replace(paramId, ''));
  }, []);

  const onCheckQueueApprover = (datas: ContractCompleteReportAcceptorModel[]) => {
    const queueApprover = datas?.filter((a) => a.status !== TorStatusConstant.Approved && !a.isDisabled)
      .sort((a, b) => Number(`${a.sectionSequence}${a.sequence}`) - Number(`${b.sectionSequence}${b.sequence}`))[0];

    if (queueApprover) {
      setIsQueueApprover(isNull(queueApprover.delegateUserId, queueApprover.approverUserId) === userId);
    }
  };

  const onOpenModal = (index: number) => {
    setShowModals((showModals) => {
      const newModal = [...showModals];
      newModal[index] = true;
      return newModal;
    });
  };

  const handlerSetDefaultApproverAsync = async () => {
    const approver: ContractCompleteReportAcceptorModel[] = [];
    const sectionDataGroup: sectionGroup[] = [];

    setShowDefaultApproverModal(false);
    onGetDefaultApprover();
  }

  const onHideModal = (index: number) => {
    setShowModals((showModals) => {
      const newModal = [...showModals];
      newModal[index] = false;
      return newModal;
    });
  };

  const handlerRemoveApproverUser = (seqKey: string) => {
    const temp: ContractCompleteReportAcceptorModel[] = [...acceptors];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    setAcceptors(temp);
  };

  const onGetDefaultApprover = async () => {

    const processType = PROCESSTYPE.ApproveContractCompleteReport;

    const response = await account.getDefaultApproverByTopLeve(processType, 0);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: ContractCompleteReportAcceptorModel[] = [];
      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            inRefCode: item.inRefCode,
            sectionNameTh: item.sectionName,
            sectionId: item.sectionId,
          } as sectionGroup);

          approver.push({
            contractCompleteReportId: contractData.id!,
            sectionId: item.sectionId,
            inRefCode: item.inRefCode,
            sectionSequence: item.sectionSequence,
            sectionLevel: item.sectionLevel,
            sectionName: item.sectionName,
            sequence: item.sequence,
            approverUserId: item.userId,
            approverFullName: item.fullName,
            position: item.positionName,
            positionOnBoardId: undefined,
            positionOnBoardName: undefined,
            isDisabled: false,
            remark: undefined,
            departmentName: item.departmentName ? item.departmentName : '',
            status: 'Draft',
            approvedUserBy: undefined,
            approvedDateAt: undefined,
            approvedRemark: undefined,
            rejectedUserBy: undefined,
            rejectedDateAt: undefined,
            rejectedRemark: undefined,
            seqKey: '',
            delegateUserId: item.delegateUserId,
            delegateEmployeeCode: item.delegateEmployeeCode,
            delegateFullName: item.delegateFullName,
            delegateDepartmentName: item.delegateDepartmentName,
            delegatePositionName: item.delegatePositionName,
          } as ContractCompleteReportAcceptorModel);
        });

      const uniqueNames = sectionDataGroup.filter((data, index) => {
        return sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index;
      });

      if (contractData.contractCompleteReportAcceptors === undefined || contractData.contractCompleteReportAcceptors === null || contractData.contractCompleteReportAcceptors.length === 0) {

        let inx = 0;
        let sectionSeq = 0;

        approver?.sort((a, b) => a.sectionSequence! - b.sectionSequence!)
          .forEach((item, index) => {

            if (sectionSeq !== item.sectionSequence) {
              inx = 1;
              sectionSeq = item.sectionSequence!;
            }

            approver[index].sequence = inx;

            inx++;
          });
      }

      setAcceptors(approver);
      setSections(uniqueNames);
    }
  };

  const onSelectEmpApprover = (empId: string, name: string, position: string, department: string, index: number, sectionSequence?: number, inRefCode?: string, sectionName?: string, sectionLevel?: number) => {
    if (acceptors && acceptors.filter((x) => x.inRefCode === inRefCode && x.approverUserId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {

      const acceptorBySection = acceptors.filter((x) => x.sectionSequence === sectionSequence);

      const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
        acceptorBySection.map((x) => {
          return x.sequence;
        })) : 0;

      const newData: ContractCompleteReportAcceptorModel = {
        id: undefined,
        contractCompleteReportId: contractData.id!,
        sectionId: '',
        inRefCode: inRefCode!,
        sectionName: sectionName,
        sectionLevel: sectionLevel,
        sectionSequence: sectionSequence,
        sequence: maxValueSequence + 1,
        approverUserId: empId,
        approverFullName: name,
        position: position,
        positionOnBoardId: undefined,
        positionOnBoardName: undefined,
        isDisabled: false,
        remark: undefined,
        departmentName: department,
        status: 'Draft',
        approvedUserBy: undefined,
        approvedDateAt: undefined,
        approvedRemark: undefined,
        rejectedUserBy: undefined,
        rejectedDateAt: undefined,
        rejectedRemark: undefined,
        seqKey: `${sectionSequence?.toString()}${(acceptors.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
      };
      setAcceptors((acceptors) => [...acceptors, newData]);
    }
  };

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const onSave = async (isSendPending: boolean) => {

    if (acceptors === undefined || acceptors === null || acceptors.length === 0) {
      toast.warn('กรุณาระบุผู้มีอำนาจเห็นชอบ');
      return;
    }

    const saveApprovers: ContractCompleteReportAcceptorModel[] = [];
    sections.sort((a, b) => a.sectionSequence - b.sectionSequence)
      .forEach((section) => {
        acceptors.filter((x) => x.sectionSequence === section.sectionSequence)
          .forEach((data, index) => {
            const newData: ContractCompleteReportAcceptorModel = {
              id: data.id,
              contractCompleteReportId: data.contractCompleteReportId,
              sectionId: section.sectionId,
              inRefCode: data.inRefCode,
              sectionSequence: section.sectionSequence,
              sectionName: data.sectionName,
              sequence: index + 1,
              approverUserId: data.approverUserId,
              approverFullName: data.approverFullName,
              position: data.position,
              positionOnBoardId: data.positionOnBoardId,
              positionOnBoardName: data.positionOnBoardName,
              isDisabled: data.isDisabled,
              remark: data.remark,
              departmentName: data.departmentName,
              status: data.status,
              approvedUserBy: data.approvedUserBy,
              approvedDateAt: data.approvedDateAt,
              approvedRemark: data.approvedRemark,
              rejectedUserBy: data.rejectedUserBy,
              rejectedDateAt: data.rejectedDateAt,
              rejectedRemark: data.rejectedRemark,
              seqKey: data.seqKey,
              delegateUserId: data.delegateUserId,
              delegateEmployeeCode: data.delegateEmployeeCode,
              delegateFullName: data.delegateFullName,
              delegatePositionName: data.delegatePositionName,
              delegateDepartmentName: data.delegateDepartmentName,
            };

            saveApprovers.push(newData);
          });
      });

    collaboraRef.current?.clickSave();

    setTimeout(async () => {

      const { data, status } = await ContractCompleteReportService.UpdateContractCompleteReportAcceptor(contractData.id!, saveApprovers, isSendPending, docId);
      if (status === HttpStatusCode.OK) {
        if (!isSendPending) {
          toast.success('บันทึกข้อมูลสำเร็จ');
        } else {
          toast.success('ส่งเห็นชอบ/อนุมัติสำเร็จ');
        }
        onGetContractCompleteReportByIdAsync(contractData.id!);
        navigate(`${currentPath}/${contractData.id}`);
      } else {
        toast.error('บันทึกข้อมูลไม่สำเร็จ');
      }

      setShowPendingModal(false);

    }, 500);
  }

  const onGetContractCompleteReportByIdAsync = async (id: string) => {
    const { data, status } = await ContractCompleteReportService.getContractCompleteReportByIdAsync(id);
    if (status === HttpStatusCode.OK) {
      setDataContext(data);
      setContractData(data);
    }
  }

  const onRecall = async () => {

    const { data, status } = await ContractCompleteReportService.OnRecallAsync(contractData.id!);
    if (status === HttpStatusCode.OK) {
      toast.success('เรียกกลับแก้ไขสำเร็จ');
      onGetContractCompleteReportByIdAsync(contractData.id!);
      navigate(`${currentPath}/${contractData.id}`);
    } else {
      toast.error('เรียกกลับแก้ไขไม่สำเร็จ');
    }

    setShowRecallModal(false);
  }

  const onReject = async () => {
    if (rejectReason === '' || rejectReason === undefined || rejectReason === null) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    const { data, status } = await ContractCompleteReportService.AcceptorRejectAsync(contractData.id!, rejectReason);
    if (status === HttpStatusCode.OK) {
      toast.success('ส่งกลับแก้ไขสำเร็จ');
      onGetContractCompleteReportByIdAsync(contractData.id!);
      navigate(`${currentPath}/${contractData.id}`);
    } else {
      toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
    }

    setShowRejectModal(false);
  }

  const isQueueAcceptor = useMemo(() => {
    if (contractData && contractData.contractCompleteReportAcceptors && userId) {
      const lastSection = contractData?.contractCompleteReportAcceptors
        .filter(d => d.status === 'Pending')
        .map(o => o.sectionSequence)[0];

      if (lastSection) {
        const minSection = Math.min(lastSection);

        const sectionData = contractData?.contractCompleteReportAcceptors
          .filter(d => d.sectionSequence === minSection
            && d.status === 'Pending')
          .sort((a, b) => a.sequence - b.sequence)[0];

        if (sectionData) {
          return isNull(sectionData.delegateUserId, sectionData.approverUserId) === userId;
        }
      }
    }
  }, [userId, contractData]);

  const onApprove = async () => {
    // if (approveReason === '' || approveReason === undefined || approveReason === null) {
    //   toast.warn('กรุณาระบุหมายเหตุ');
    //   return;
    // }

    const { data, status } = await ContractCompleteReportService.AcceptorApproveAsync(contractData.id!, approveReason);
    if (status === HttpStatusCode.OK) {
      toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
      onGetContractCompleteReportByIdAsync(contractData.id!);
      navigate(`${currentPath}/${contractData.id}`);
    } else {
      toast.warn(`${isApprover ? 'อนุมัติไม่สำเร็จ' : 'เห็นชอบไม่สำเร็จ'}`);
    }

    setShowApproveModal(false);
  }

  return (
    <>
      <Card className='mt-3'>
        <Row>
          <Title text='ผู้มีอำนาจเห็นชอบ/อนุมัติ'
            className='fs-5 text-primary'
            extraElement={
              <>
                <TorStatus
                  value={contractData.status}
                />
                <Button
                  onClick={() => setShowHistory(!showHistory)}
                  className='ms-2'
                  variant='outline-primary'
                >
                  <FaHistory className='me-1' />
                  ประวัติการใช้งาน
                </Button>
              </>
            } />
          <Col sm={12}
            className='mt-3'>
            <Row className='gap-5'>
              <div>
                {(contractData.status === ContractCompleteReportStatus.Draft || contractData.status === ContractCompleteReportStatus.Rejected)
                  ? (
                    <Button
                      className='d-flex align-items-center gap-2'
                      variant='outline-primary'
                      onClick={() => {
                        setShowDefaultApproverModal(true);
                      }}
                    >
                      <FaRedo />กำหนดผู้มีอำนาจเห็นชอบเริ่มต้น
                    </Button>
                  ) : null}
              </div>
              {
                sections.length > 0 ? (
                  sections
                    .sort((a, b) => a.sectionSequence - b.sectionSequence)
                    .map((section, index) => (
                      <Col sm={12}
                        key={index}>
                        {
                          (contractData.status === ContractCompleteReportStatus.Draft || contractData.status === ContractCompleteReportStatus.Rejected) && (
                            <div className='d-flex justify-content-between mb-1'>
                              <p className='fs-5 text-primary'>
                                {/* {section.sectionName} */}
                              </p>
                              <Button
                                variant='outline-primary'
                                disabled={isDisabled}
                                onClick={() => onOpenModal(index)}
                              >
                                <FaPlus className='me-1' /> เพิ่มรายชื่อ
                              </Button>
                            </div>
                          )
                        }
                        <Table
                          hidePagination
                          className='mt-3 step-bar'
                          notResponse
                        >
                          <thead>
                            <tr>
                              <th style={{ width: '5%' }}>ลำดับ</th>
                              <th className='text-start'
                                style={{ width: '30%' }}>ชื่อ-นามสกุล
                              </th>
                              <th className='text-start'
                                style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                              </th>
                              <th style={{ width: '10%' }}>สถานะ</th>
                              <th style={{ width: '10%' }}>วันที่เห็นชอบ</th>
                              <th style={{ minHeight: 100, width: '5%' }} />
                            </tr>
                          </thead>
                          <tbody>
                            {
                              (acceptors && acceptors.length > 0) && (
                                acceptors
                                  .filter((x) => x.inRefCode === section.inRefCode)
                                  .sort((a, b) => a.sequence - b.sequence)
                                  .map((data, i) => (
                                    <>
                                      <tr key={i}>
                                        <td className='text-center'>{i + 1}</td>
                                        <td className='text-left'>
                                          {data.approverFullName}
                                          <p className='m-0 department'>
                                            {data.position}
                                          </p>
                                        </td>
                                        <td className='text-left'>
                                          {data.delegateFullName &&
                                            <>
                                              {data.delegateFullName}
                                              <p className='m-0 department'>
                                                {data.delegatePositionName}
                                              </p>
                                            </>
                                          }
                                        </td>
                                        <td className='text-center'>
                                          {isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ?
                                            <Approver value={data.status}
                                              outline /> : <ApproverStatus value={data.status}
                                                outline />}
                                        </td>
                                        <td className='text-center'>
                                          {
                                            (data.status === ContractCompleteReportStatus.Approved) && (
                                              <>
                                                {data.approvedDateAt ? fullDatetime(data.approvedDateAt) : ''}
                                              </>
                                            )
                                          }
                                        </td>
                                        <td className='text-end'>
                                          {(contractData.status === ContractCompleteReportStatus.Draft || contractData.status === ContractCompleteReportStatus.Rejected) &&
                                            <Button
                                              variant='danger'
                                              onClick={() => handlerRemoveApproverUser(`${data.sectionSequence}${data.sequence}`)}
                                            >
                                              <FaTrashAlt />
                                            </Button>}
                                        </td>
                                      </tr>
                                      {data.delegateDateRange || data.approvedRemark || data.rejectedRemark ?
                                        <tr className='border-bottom bg-opacity-10 bg-light'>
                                          <td />
                                          <td colSpan={5}
                                            className='p-0'>
                                            <ul className='step-bar-list'>
                                              {data.delegateDateRange &&
                                                <li>
                                                  <div className='description'>
                                                    <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                                    <p className='mb-0'
                                                      style={{ color: 'gray' }}>
                                                      {data.delegateDateRange}
                                                    </p>
                                                  </div>
                                                </li>
                                              }
                                              {data.approvedRemark &&
                                                <li>
                                                  <div className='description'>
                                                    <p className='mb-0 text-primary'>หมายเหตุ{isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ? 'อนุมัติ' : 'เห็นชอบ'}
                                                    </p>
                                                    <p className='mb-0 text-wrap'
                                                      style={{ color: 'gray' }}>
                                                      <span className='text-muted me-2'>[{data.approvedDateAt ? fullDatetime(data.approvedDateAt) : ''}]</span>
                                                      {data.approvedUserByName} {data.approvedRemark}
                                                    </p>
                                                  </div>
                                                </li>
                                              }
                                              {data.rejectedRemark &&
                                                <li>
                                                  <div className='description'>
                                                    <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                                    <p className='mb-0 text-wrap'
                                                      style={{ color: 'gray' }}>
                                                      <span className='text-muted me-2'>[{data.rejectedDateAt ? fullDatetime(data.rejectedDateAt) : ''}]</span>
                                                      {data.rejectedUserByName} {data.rejectedRemark}
                                                    </p>
                                                  </div>
                                                </li>
                                              }
                                            </ul>
                                          </td>
                                        </tr>
                                        : <tr className='border-bottom' />}
                                    </>
                                  ))
                              )
                            }
                          </tbody>
                        </Table>
                        <SearchBuNameModal
                          total={userTotal}
                          show={showModals[index]}
                          onHide={() => onHideModal(index)}
                          onSelect={(id, name, department, position) => onSelectEmpApprover(id, name, position!, department!, index, section.sectionSequence, section.inRefCode, section.sectionName, section.level)}
                          data={users}
                          onSearch={onSearchUserAsync}
                          onPageChange={onPageChangeAsync}
                          departmentCode={departmentCode}
                          departmentDisabled={departmentCode !== JorPorCode.CODE}
                        />
                      </Col>
                    ))
                ) : (
                  <Col>
                    <span className='text-primary'>ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
                  </Col>
                )
              }
            </Row>
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title
          text='เอกสารร่างรายงานสัญญาแล้วเสร็จตามไตรมาส'
          className='fs-5 text-primary'
        />
        <div className='mt-3'>
          <Collabora
            docId={docId}
            docName='doc-03'
            ref={collaboraRef}
            readonly={false} />
        </div>
      </Card>
      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <ButtonCustom
            text="ย้อนกลับ"
            onClick={onClickBack}
          />
        </div>
        <div className='d-flex gap-3 align-items-center'>
          {
            (departmentCode === JorPorCode.CODE) &&
              contractData.status === ContractCompleteReportStatus.Draft
              || contractData.status === ContractCompleteReportStatus.Rejected
              ? (
                <div>
                  <ButtonCustom
                    onClick={() => onSave(false)}
                    text="บันทึก" />
                  <ButtonCustom
                    className='mx-2'
                    onClick={() => setShowPendingModal(true)}
                    icon="checked"
                    text="ส่งเห็นชอบ/อนุมัติ" />
                </div>
              )
              : <></>
          }
          {
            ((departmentCode === JorPorCode.CODE) && contractData.status === ContractCompleteReportStatus.WaitingForApprove
              && isCanRecall) && (
              <ButtonCustom
                variant='danger'
                onClick={() => setShowRecallModal(true)}
                text="เรียกคืนแก้ไข"
                icon="undo"
              />
            )
          }
          {
            contractData.status === ContractCompleteReportStatus.WaitingForApprove
              && isQueueAcceptor
              ? (
                <div>
                  <ButtonCustom
                    className='mx-2'
                    variant='danger'
                    onClick={() => setShowRejectModal(true)}
                    icon="undo"
                    text="ส่งกลับแก้ไข"
                  />
                  <ButtonCustom
                    className='mx-2'
                    variant='success'
                    onClick={() => setShowApproveModal(true)}
                    icon="checked"
                    text={isApprover ? 'อนุมัติ' : 'เห็นชอบ'} />
                </div>
              )
              : <></>
          }
        </div>
        <div style={{ width: 150 }}></div>
      </div>
      <Modal
        show={showDefaultApproverModal}
        size='lg'
        onHide={() => setShowDefaultApproverModal(!showDefaultApproverModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการกำหนดผู้มีอำนาจเห็นชอบเริ่มต้นใหม่หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDefaultApproverModal(!showDefaultApproverModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerSetDefaultApproverAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showPendingModal}
        size='lg'
        onHide={() => setShowPendingModal(!showPendingModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งเห็นชอบ/อนุมัติ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-4 ps-4'>
              คุณต้องการกำหนดส่งเห็นชอบ/อนุมัติหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingModal(!showPendingModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(true)}
              >
                <FaRegCheckCircle className='me-2 pb-1 fs-5' />ส่งเห็นชอบ/อนุมัติ
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRecallModal}
        size='lg'
        onHide={() => setShowRecallModal(!showRecallModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowRecallModal(!showRecallModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onRecall}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejectModal}
        size='lg'
        onHide={() => setShowRejectModal(!showRejectModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body>
              <InputTextArea label='หมายเหตุ'
                onChange={(val) => setRejectReason(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowRejectModal(!showRejectModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={onReject}
              >
                <FaUndo className='me-2 pb-1 fs-5' />ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApproveModal}
        size='lg'
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>{isApprover ? 'อนุมัติ' : 'เห็นชอบ'}</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body>
              <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                onChange={(val) => setApproveReason(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApproveModal(!showApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='success'
                onClick={onApprove}
              >
                <FaRegCheckCircle className='me-2 pb-1 fs-5' />
                เห็นชอบ/อนุมัติ
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <HistoryModal
        title='ประวัติการใช้งานรายงานสัญญาแล้วเสร็จ'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={contractData.id}
      />
    </>
  );
}
