import {
  Card,
  DatePicker,
  Input,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaRegCheckCircle,
  FaSave,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import CreateContractService from 'services/CM/CreateContractService';
import { HttpStatusCode } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import Collabora from '../../../../components/Document/Collabora';

interface Props {
  onClickBack: () => void;
}

function Step3({ onClickBack }: Props) {
  const [docId, setDocId] = useState('');

  useEffect(() => {
    const uuid = uuidv4();

    getDocumentTor(uuid);
  }, []);

  const getDocumentTor = useCallback(async (
    contractId: string,
  ) => {

    const {
      data: docId,
      status,
    } =
      await CreateContractService.getDocumentAsync(
        contractId);

    if (status === HttpStatusCode.OK) {
      setDocId(docId);
    }
  }, []);

  return (
    <>
      <Card className='mt-4'>
        <Title
          text='เผยแพร่'
          className='fs-5 text-primary'
        />
        <Row className='mt-3'>
          <Col sm={12}
            lg={6}>
            <Input
              label='ชื่อประกาศ'
              rule={{ required: true }}

            />
          </Col>
          <Col sm={12}
            lg={3}>
            <DatePicker
              label='วันที่เผยแพร่'
              disabled
            />
          </Col>
        </Row>
      </Card>
      <div className='document'>
        <Card className='mt-3'>
          <Title
            text='ตัวอย่างเอกสาร'
            className='fs-5 text-primary'
          />
          <div className='mt-3'>
            <Collabora
              docId={docId}
              docName='doc-01'
              readonly={false} />
          </div>
        </Card>
      </div>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          onClick={onClickBack}
          variant='outline-primary'>
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          <Button
            className='me-2 px-3'
            variant='success'>
            <FaSave className='me-2 pb-1 fs-5' />
            บันทึก
          </Button>
          <Button
            className='me-2 px-3'>
            <FaRegCheckCircle className='me-2 pb-1 fs-5' />
            ส่งอนุมัติเห็นชอบ
          </Button>
        </div>
        <div />
      </div>
    </>
  );
}

export default Step3;
