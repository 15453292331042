export const approveContract = [
  {
    id: '1',
    approveId: '5600025',
    userName: 'สมหญิง รักษ์เมืองไทย',
    department: 'จัดหาและการพัสดุ',
    positionOnBoardId: '',
    positionOnBoardName: '',
    position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร',
    sequence: '1'
  },
  {
    id: '2',
    approveId: '5600026',
    userName: 'กชกร ธนารักษ์',
    department: 'จัดหาและการพัสดุ',
    positionOnBoardId: '',
    positionOnBoardName: '',
    position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร',
    sequence: '1'
  },
  {
    id: '3',
    approveId: '5600027',
    userName: 'กชกร ธนารักษ์',
    department: 'จัดหาและการพัสดุ',
    positionOnBoardId: '',
    positionOnBoardName: '',
    position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร',
    sequence: '1'
  }
];

export const dataHistory = [
  {
    no: '1',
    versionNo: 'V1.1.2.005',
    nameEdit: 'สมหญิง รักษ์เมืองไทย',
    dayEdit: '31/12/2021 12:22'
  },
  {
    no: '2',
    versionNo: 'V1.1.2.005',
    nameEdit: 'สมหญิง รักษ์เมืองไทย',
    dayEdit: '31/12/2021 12:22'
  }
];

export const installment = [
  {
    id: '1',
    Installment: '1',
    percent: '30%',
    amount: '250,000.00',
    day: '30',
    deliveryDate: '31/06/2023',
    percentDelivery: '30%',
    amountDelivery: '250,000.00',
    details: '',
    details1: '1) แผนการดำเนินโครงการ (Project Planning) จำนวน 1 ฉบับ ส่งมอบภายใน 30 วัน',
    details2: '2) แผนการดำเนินโครงการที่ 2 (Project Planning) จำนวน 1 ฉบับ ส่งมอบภายใน 30 วัน'
  },
  {
    id: '2',
    Installment: '1',
    percent: '30%',
    amount: '500,000.00',
    day: '60',
    deliveryDate: '31/06/2023',
    percentDelivery: '30%',
    amountDelivery: '500,000.00',
    details: '',
    details1: '1) รายงานรายละเอียดการวิเคราะห์และออกแบบระบบ (System Analysis and Design) จำนวน 1 ฉบับ ส่งมอบภายใน 60 วัน',
    details2: '2) รายงานะสถาปัตยกรรมระบบของ โครงการ (System Architecture Design) จำนวน 1 ฉบับ ส่งมอบภายใน 60 วัน'
  },

  {
    id: '3',
    Installment: '2',
    percent: '40%',
    amount: '1,000,000.00',
    day: '150',
    deliveryDate: '31/06/2023',
    percentDelivery: '40%',
    amountDelivery: '1,000,000.00',
    details: '',
    details1: '1) รายงานการออกแบบ (Conceptual design) ส่งมอบภายใน 150 วัน',
    details2: '2) ระบบบันทึกเสียงออนไลน์ จำนวน 1 License ส่งมอบภายใน 150 วัน'
  }
];

export const installmentData1 = {
  id: '1',
  Installment: '1',
  percent: '30%',
  amount: '250,000.00',
  day: '30',
  deliveryDate: '31/06/2023',
  percentDelivery: '30%',
  amountDelivery: '250,000.00',
  details: '',
  details1: 'แผนการดำเนินโครงการ (Project Planning) จำนวน 1 ฉบับ รวมเป็นเงิน 250,000.00 บาท ส่งมอบภายใน 30 วัน',
  details2: ''
};

export const installmentData2 = {
  id: '2',
  Installment: '1',
  percent: '30%',
  amount: '500,000.00',
  day: '60',
  deliveryDate: '31/06/2023',
  percentDelivery: '30%',
  amountDelivery: '500,000.00',
  details: '',
  details1: 'รายงานรายละเอียดการวิเคราะห์และออกแบบระบบ (System Analysis and Design) จำนวน 1 ฉบับ รวมเป็นเงิน 500,000.00 บาท ส่งมอบภายใน 60 วัน',
  details2: ''
};

export const installmentData3 = {
  id: '3',
  Installment: '2',
  percent: '40%',
  amount: '1,000,000.00',
  day: '150',
  deliveryDate: '31/06/2023',
  percentDelivery: '40%',
  amountDelivery: '1,000,000.00',
  details: '',
  details1: 'รายงานการออกแบบ (Conceptual design)  รวมเป็นเงิน 1,000,000.00 บาท ส่งมอบภายใน 150 วัน',
  details2: ''
};

export const parcels = [
  {
    id: '1',
    details: 'แผนการดำเนินโครงการ (Project Planning)',
    quantity: '1',
    unit: 'ฉบับ',
    typeVat: 'มี VAT',
    offerPrice: '150,000.00',
    quotedPriceTogether: '150,000.00',
    agreedPrice: '150,000.00',
    agreedPriceTogether: '150,000.00',
    amountDelivery: '1',
    unitDelivery: '30',
    deliveryDays: 'ฉบับ',
    deliveryDate: '31/06/2023'
  },
  {
    id: '2',
    details: 'แผนการดำเนินโครงการ (Project Planning) ที่ 2',
    quantity: '1',
    unit: 'ฉบับ',
    typeVat: 'มี VAT',
    offerPrice: '100,000.00',
    quotedPriceTogether: '100,000.00',
    agreedPrice: '100,000.00',
    agreedPriceTogether: '100,000.00',
    amountDelivery: '1',
    unitDelivery: '30',
    deliveryDays: 'ฉบับ',
    deliveryDate: '31/06/2023'
  }
];
