import { Badge } from 'react-bootstrap';

interface Props {
  Istatus: 'warning' | 'done';
}

function generateStatusContract(Istatus: string): JSX.Element | undefined {
  switch (Istatus) {
    case 'warning':
      return <Badge bg="warning" className="p-2">ร่างสัญญแล้ว</Badge>;
    case 'done':
      return <Badge bg="success" className="p-2">ร่างสัญญา</Badge>;
    default:
    // code block
  }
}

export function StatusContract({ Istatus }: Props) {
  return (
    <>
      {generateStatusContract(Istatus)}
    </>
  );
}