import { Card } from 'react-bootstrap';
import { THCurrency } from '../utils';

interface Props {
  total: number;
  label: string;
  unit: string;
}

function CardTotalProjects({ label, total, unit }: Props) {
  return (
    <Card>
      <Card.Body>
        <p className="mb-0">{label}</p>
        <p className="text-primary fs-1 text-end mb-0">{THCurrency(total)}</p>
        <p className="text-end mb-0">{unit}</p>
      </Card.Body>
    </Card>
  );
}

export default CardTotalProjects;
