import { PreProcumentFlag } from 'constant/PreProcumentFlag';
import Cookie from 'cookie-universal';
import { CommitteeApproverTorModel, SearchCriteriaModel, SequenceApproverTorModel, TorDetailModel } from 'models/PP/TorModels';
import http from 'utils/axios';
import { TorTemplateCode } from 'utils/constants/torTemplateCodeEnum';

const qs = require('qs');

const cookies = Cookie();
const getFullName = cookies.get('fullName') as string;

const getListTorAsync = (searchModel: SearchCriteriaModel, flag?: PreProcumentFlag) => {
  let api = '/termOfRef';

  if (flag === PreProcumentFlag.IsEditing) {
    api = '/termOfRefediting';
  }

  if (flag === PreProcumentFlag.IsCanceling) {
    api = '/termOfRefcanceling';
  }

  const params = {
    page: searchModel.page,
    size: searchModel.size,
    planNumber: searchModel.planNumber,
    planName: searchModel.planName,
    departmentId: searchModel.departmentId,
    planBudgetMin: searchModel.planBudgetMin,
    planBudgetMax: searchModel.planBudgetMax,
    memorandumDate: searchModel.memorandumDate,
    expectingProcurementAt: searchModel.expectingProcurementAt,
    isResponsible: searchModel.isResponsible,
    supplyMethodType: searchModel.supplyMethodType,
    termOfRefStatus: searchModel.termOfRefStatus,
    appointNumber: searchModel.appointNumber,
    torNumber: searchModel.torNumber,
  };

  return http.get(`${api}`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getCountStatusAsync = (searchModel: SearchCriteriaModel, responsibleBy: string, flag?: PreProcumentFlag) => {
  let api = '/termOfRef';

  if (flag === PreProcumentFlag.IsEditing) {
    api = '/termOfRefEditing';
  }

  if (flag === PreProcumentFlag.IsCanceling) {
    api = '/termOfRefCanceling';
  }

  const params = {
    planNumber: searchModel.planNumber,
    planName: searchModel.planName,
    departmentId: searchModel.departmentId,
    planBudgetMin: searchModel.planBudgetMin,
    planBudgetMax: searchModel.planBudgetMax,
    memorandumDate: searchModel.memorandumDate,
    expectingProcurementAt: searchModel.expectingProcurementAt,
    IsResponsible: searchModel.isResponsible,
    supplyMethodType: searchModel.supplyMethodType,
    termOfRefStatus: searchModel.termOfRefStatus,
    responsibleBy: responsibleBy,
    appointNumber: searchModel.appointNumber,
    torFlag: flag || undefined,
    torNumber: searchModel.torNumber,
  };
  return http.get(`${api}/countStatus`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getCountSupplyMethodAsync = (searchModel: SearchCriteriaModel, responsibleBy: string, flag?: PreProcumentFlag) => {
  let api = '/termOfRef';

  if (flag === PreProcumentFlag.IsEditing) {
    api = '/termOfRefEditing';
  }

  if (flag === PreProcumentFlag.IsCanceling) {
    api = '/termOfRefCanceling';
  }

  const params = {
    planNumber: searchModel.planNumber,
    planName: searchModel.planName,
    departmentId: searchModel.departmentId,
    planBudgetMin: searchModel.planBudgetMin,
    planBudgetMax: searchModel.planBudgetMax,
    memorandumDate: searchModel.memorandumDate,
    expectingProcurementAt: searchModel.expectingProcurementAt,
    isResponsible: searchModel.isResponsible,
    termOfRefStatus: searchModel.termOfRefStatus,
    supplyMethodType: searchModel.supplyMethodType,
    responsibleBy: responsibleBy,
    appointNumber: searchModel.appointNumber,
    torFlag: flag || undefined,
    torNumber: searchModel.torNumber,
  };
  return http.get(`${api}/countSupplyMethod`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getListHistoryAsync = (page: number, size: number, id: string | undefined, torNumber: string | undefined) => {
  const params = {
    page,
    size,
    termOfRefId: id,
    torNumber
  };
  return http.get('/termOfRef/history', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getTorByIdAsync = (id: string) => http.get(`/termOfRef/${id}`);

const updateTorStep1Async = (body: TorDetailModel) => {
  const formData = new FormData();
  formData.append('id', body.id);
  formData.append('Step', body.step.toString());
  formData.append('FinancialAmount', body.financialAmount.toString());
  formData.append('planName', body.planName);
  formData.append('sourceAndReasons', body.sourceAndReasons);
  formData.append('torTemplateCode', body.torTemplateCode);
  formData.append('departmentId', body.departmentId);
  formData.append('budgetYear', body.budgetYear.toString());
  formData.append('memorandumNo', body.memorandumNo);
  formData.append('memorandumDate', new Date(body.memorandumDate!).toISOString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt!).toISOString());
  formData.append('planBudget', body.planBudget.toString());
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodType', body.supplyMethodType);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
  formData.append('deliveryPeriodSum', body.deliveryPeriodSum ? body.deliveryPeriodSum.toString() : '');
  formData.append('deliveryUnit', body.deliveryUnit ? body.deliveryUnit : '');
  formData.append('deliveryCountUnit', body.deliveryCountUnit ? body.deliveryCountUnit : '');
  formData.append('isConsumableMaterials', body.isConsumableMaterials ? 'true' : 'false');
  formData.append('documentTemplateCode', body.documentTemplateCode);
  formData.append('isMA', body.isMA ? 'true' : 'false');
  formData.append('hasPerformanceBond', body.hasPerformanceBond ? 'true' : 'false');
  formData.append('hasBidBond', body.hasBidBond ? 'true' : 'false');

  if (body.middlePrice) {
    formData.append('MiddlePrice', body.middlePrice.toString());
  }

  if (body.middlePriceSetDate) {
    formData.append('MiddlePriceSetDate', new Date(body.middlePriceSetDate!).toISOString());
  }

  if (body.objectives && body.objectives.length > 0) {
    body.objectives.forEach((data, index) => {
      if (data.id) {
        formData.append(`Objectives[${index}][id]`, data.id);
      }
      formData.append(`Objectives[${index}][Description]`, data.description);
      formData.append(`Objectives[${index}][Sequence]`, data.sequence.toString());
    });
  }

  if (body.parcels && body.parcels.length > 0) {
    body.parcels.forEach((data, index) => {
      if (data.id) {
        formData.append(`Parcels[${index}][id]`, data.id);
      }
      formData.append(`Parcels[${index}][TorId]`, data.torId);
      formData.append(`Parcels[${index}][Sequence]`, data.sequence.toString());
      formData.append(`Parcels[${index}][Description]`, data.description);
      formData.append(`Parcels[${index}][Quantity]`, data.quantity.toString());
      formData.append(`Parcels[${index}][Unit]`, data.unit);
      formData.append(`Parcels[${index}][Price]`, data.price.toString());
      formData.append(`Parcels[${index}][Total]`, data.total ? data.total.toString() : '');
    });
  }

  if (body.qualifications && body.qualifications.length > 0) {
    body.qualifications.forEach((data, index) => {
      if (data.id) {
        formData.append(`Qualifications[${index}][id]`, data.id);
      }
      formData.append(`Qualifications[${index}][Description]`, data.description);
      formData.append(`Qualifications[${index}][Sequence]`, data.sequence.toString());
      formData.append(`Qualifications[${index}][IsDefault]`, data.isDefault.toString());
    });
  }

  if (body.financials && body.financials.length > 0) {
    body.financials.forEach((data, index) => {
      if (data.id) {
        formData.append(`Financials[${index}][id]`, data.id);
      }
      formData.append(`Financials[${index}][FinancialType]`, data.financialType);
      formData.append(`Financials[${index}][Amount]`, data.amount.toString());
      formData.append(`Financials[${index}][Description]`, data.description);
      formData.append(`Financials[${index}][Sequence]`, data.sequence.toString());
    });
  }

  if (body.workPhases && body.workPhases.length > 0) {
    body.workPhases.forEach((data, index) => {
      if (data.id) {
        formData.append(`WorkPhases[${index}][id]`, data.id);
      }
      formData.append(`WorkPhases[${index}][Sequence]`, data.sequence.toString());
      formData.append(`WorkPhases[${index}][TermOfRefId]`, data.termOfRefId);
      formData.append(`WorkPhases[${index}][PhaseNumber]`, data.phaseNumber.toString());
      formData.append(`WorkPhases[${index}][PeriodDay]`, data.periodDay.toString());
      formData.append(`WorkPhases[${index}][Amount]`, data.amount ? data.amount.toString() : '');
      formData.append(`WorkPhases[${index}][Percent]`, data.percent ? data.percent.toString() : '');
      formData.append(`WorkPhases[${index}][Detail]`, data.detail);
      formData.append(`WorkPhases[${index}][DeliveryDueDate]`, data.deliveryDueDate ? new Date(data.deliveryDueDate).toISOString() : '');
    });
  }

  if (body.defectsWarranty && body.defectsWarranty.hasWarranty) {
    formData.append(`DefectsWarranty[HasWarranty]`, 'true');
    formData.append(`DefectsWarranty[Count]`, body.defectsWarranty.count!.toString());
    formData.append(`DefectsWarranty[Unit]`, body.defectsWarranty.unit!);
    formData.append(`DefectsWarranty[CountUnit]`, body.defectsWarranty.countUnit!);
  } else {
    formData.append(`DefectsWarranty[HasWarranty]`, 'false');
    formData.append(`DefectsWarranty[Count]`, '');
    formData.append(`DefectsWarranty[Unit]`, '');
    formData.append(`DefectsWarranty[CountUnit]`, '');
  }

  if (body.middlePriceSources && body.middlePriceSources.length > 0) {
    body.middlePriceSources.forEach((data, index) => {
      if (data.id) {
        formData.append(`MiddlePriceSources[${index}][id]`, data.id);
      }
      formData.append(`MiddlePriceSources[${index}][sequence]`, data.sequence.toString());
      formData.append(`MiddlePriceSources[${index}][description]`, data.description);
    });
  }

  if (body.paymentTermPeriods && body.paymentTermPeriods.length > 0) {
    body.paymentTermPeriods.forEach((data, index) => {
      if (data.id) {
        formData.append(`PaymentTermPeriods[${index}][id]`, data.id);
      }
      formData.append(`PaymentTermPeriods[${index}][termOfRefId]`, data.termOfRefId);
      formData.append(`PaymentTermPeriods[${index}][Sequence]`, data.sequence.toString());
      formData.append(`PaymentTermPeriods[${index}][Description]`, data.description ? data.description.toString() : '');
      formData.append(`PaymentTermPeriods[${index}][Quantity]`, data.quantity ? data.quantity.toString() : '');
      formData.append(`PaymentTermPeriods[${index}][PeriodType]`, data.periodType ? data.periodType.toString() : '');
      formData.append(`PaymentTermPeriods[${index}][TotalQuantity]`, data.totalQuantity ? data.totalQuantity.toString() : '');
      formData.append(`PaymentTermPeriods[${index}][TotalPeriodType]`, data.totalPeriodType ? data.totalPeriodType.toString() : '');
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.put(`/termOfRef/draftTermOfRef/${body.id}`, formData, config);
};

const updateTorStep2Async = (body: TorDetailModel) => {
  const formData = new FormData();

  formData.append('Step', body.step.toString());

  if (body.defectsWarranty && body.defectsWarranty.hasWarranty) {
    formData.append(`DefectsWarranty[HasWarranty]`, 'true');
    formData.append(`DefectsWarranty[Count]`, body.defectsWarranty.count!.toString());
    formData.append(`DefectsWarranty[Unit]`, body.defectsWarranty.unit!);
    formData.append(`DefectsWarranty[countUnit]`, body.defectsWarranty.countUnit!);
  } else {
    formData.append(`DefectsWarranty[HasWarranty]`, 'false');
    formData.append(`DefectsWarranty[Count]`, '');
    formData.append(`DefectsWarranty[Unit]`, '');
    formData.append(`DefectsWarranty[countUnit]`, '');
  }

  if (body.fineRates && body.fineRates.length > 0) {
    body.fineRates.forEach((data, index) => {
      if (data.id) {
        formData.append(`FineRates[${index}][id]`, data.id);
      }
      formData.append(`FineRates[${index}][FineRateType]`, data.fineRateType);
      formData.append(`FineRates[${index}][FineRatePeriodType]`, data.fineRatePeriodType);
      formData.append(`FineRates[${index}][Fine]`, data.fine.toString());
      formData.append(`FineRates[${index}][Description]`, data.description);
      formData.append(`FineRates[${index}][Sequence]`, data.sequence.toString());
    });
  }

  if (body.torTemplateCode === TorTemplateCode.OV5STD) {
    formData.append('EvidenceDescription', body.evidenceDescription ? body.evidenceDescription : '');
    formData.append('EvidenceNumber', body.evidenceNumber ? body.evidenceNumber : '');
    formData.append('DocumentDescription', body.documentDescription ? body.documentDescription : '');
    formData.append('CriteriaConsiderDescription', body.criteriaConsiderDescription ? body.criteriaConsiderDescription : '');
    formData.append('ManuelDescription', body.manuelDescription ? body.manuelDescription : '');

    if (body.evidence) {
      formData.append(`Evidence[PresentTotal]`, body.evidence.presentTotal ? body.evidence.presentTotal.toString() : '');
      formData.append(`Evidence[PresentCount]`, body.evidence.presentCount ? body.evidence.presentCount.toString() : '');
      formData.append(`Evidence[PresentCountUnit]`, body.evidence.presentCountUnit ? body.evidence.presentCountUnit : '');
      formData.append(`Evidence[PresentCountType]`, body.evidence.presentCountType ? body.evidence.presentCountType : '');
    }

    if (body.evidenceCompareItems && body.evidenceCompareItems.length > 0) {
      body.evidenceCompareItems.forEach((data, index) => {
        if (data.id) {
          formData.append(`EvidenceCompareItems[${index}][Id]`, data.id);
        }
        formData.append(`EvidenceCompareItems[${index}][TermOfRefId]`, data.termOfRefId ? data.termOfRefId : '');
        formData.append(`EvidenceCompareItems[${index}][Sequence]`, data.sequence.toString());
        formData.append(`EvidenceCompareItems[${index}][Condition]`, data.condition ? data.condition : '');
        formData.append(`EvidenceCompareItems[${index}][Description]`, data.description ? data.description : '');
        formData.append(`EvidenceCompareItems[${index}][RefDocument]`, data.refDocument ? data.refDocument : '');
        formData.append(`EvidenceCompareItems[${index}][InspectionDescription]`, data.inspectionDescription ? data.inspectionDescription : '');
      });
    }

    if (body.collateral) {
      formData.append(`Collateral[Amount]`, body.collateral.amount.toString());
      formData.append(`Collateral[StartDueDate]`, new Date(body.collateral.startDueDate).toISOString());
      formData.append(`Collateral[EndDueDate]`, new Date(body.collateral.endDueDate).toISOString());
    }

    if (body.preventiveMaintenance) {
      formData.append(`PreventiveMaintenance[PmProductName]`, body.preventiveMaintenance.pmProductName ? body.preventiveMaintenance.pmProductName.toString() : '');
      formData.append(`PreventiveMaintenance[PmCount]`, body.preventiveMaintenance.pmCount ? body.preventiveMaintenance.pmCount.toString() : '');
      formData.append(`PreventiveMaintenance[PmUnit]`, body.preventiveMaintenance.pmUnit ? body.preventiveMaintenance.pmUnit.toString() : '');
      formData.append(`PreventiveMaintenance[PmFinePct]`, body.preventiveMaintenance.pmFinePct ? body.preventiveMaintenance.pmFinePct.toString() : '');
      formData.append(`PreventiveMaintenance[PmFineAmount]`, body.preventiveMaintenance.pmFineAmount ? body.preventiveMaintenance.pmFineAmount.toString() : '');
      formData.append(`PreventiveMaintenance[Condition]`, body.preventiveMaintenance.condition ? body.preventiveMaintenance.condition.toString() : '');
      formData.append(`PreventiveMaintenance[DisruptedCount]`, body.preventiveMaintenance.disruptedCount ? body.preventiveMaintenance.disruptedCount.toString() : '');
      formData.append(`PreventiveMaintenance[DisruptedCountUnit]`, body.preventiveMaintenance.disruptedCountUnit ? body.preventiveMaintenance.disruptedCountUnit.toString() : '');
      formData.append(`PreventiveMaintenance[DisruptedPercent]`, body.preventiveMaintenance.disruptedPercent ? body.preventiveMaintenance.disruptedPercent.toString() : '');
      formData.append(`PreventiveMaintenance[DisruptedFinePercent]`, body.preventiveMaintenance.disruptedFinePercent ? body.preventiveMaintenance.disruptedFinePercent.toString() : '');
      formData.append(`PreventiveMaintenance[DisruptedFineAmount]`, body.preventiveMaintenance.disruptedFineAmount ? body.preventiveMaintenance.disruptedFineAmount.toString() : '');
      formData.append(`PreventiveMaintenance[PmFinePctUnit]`, body.preventiveMaintenance.pmFinePctUnit ? body.preventiveMaintenance.pmFinePctUnit.toString() : '');
    }

    if (body.correctiveMaintenance) {
      formData.append(`CorrectiveMaintenance[CmProductName]`, body.correctiveMaintenance.cmProductName ? body.correctiveMaintenance.cmProductName.toString() : '');
      formData.append(`CorrectiveMaintenance[StartDate]`, body.correctiveMaintenance.startDate ? new Date(body.correctiveMaintenance.startDate).toISOString() : '');
      formData.append(`CorrectiveMaintenance[EndDate]`, body.correctiveMaintenance.endDate ? new Date(body.correctiveMaintenance.endDate).toISOString() : '');
      formData.append(`CorrectiveMaintenance[CmCount]`, body.correctiveMaintenance.cmCount ? body.correctiveMaintenance.cmCount.toString() : '');
      formData.append(`CorrectiveMaintenance[CmUnit]`, body.correctiveMaintenance.cmUnit ? body.correctiveMaintenance.cmUnit.toString() : '');
      formData.append(`CorrectiveMaintenance[CmCompleteCount]`, body.correctiveMaintenance.cmCompleteCount ? body.correctiveMaintenance.cmCompleteCount.toString() : '');
      formData.append(`CorrectiveMaintenance[CmCompleteUnit]`, body.correctiveMaintenance.cmCompleteUnit ? body.correctiveMaintenance.cmCompleteUnit.toString() : '');
      formData.append(`CorrectiveMaintenance[CmFinePercent]`, body.correctiveMaintenance.cmFinePercent ? body.correctiveMaintenance.cmFinePercent.toString() : '');
      formData.append(`CorrectiveMaintenance[CmDisruptedFinePercent]`, body.correctiveMaintenance.cmDisruptedFinePercent ? body.correctiveMaintenance.cmDisruptedFinePercent.toString() : '');
      formData.append(`CorrectiveMaintenance[DayStart]`, body.correctiveMaintenance.dayStart ? body.correctiveMaintenance.dayStart.toString() : '');
      formData.append(`CorrectiveMaintenance[DayEnd]`, body.correctiveMaintenance.dayEnd ? body.correctiveMaintenance.dayEnd.toString() : '');
      formData.append(`CorrectiveMaintenance[StartTime]`, body.correctiveMaintenance.startTime ? body.correctiveMaintenance.startTime.toString() : '');
      formData.append(`CorrectiveMaintenance[EndTime]`, body.correctiveMaintenance.endTime ? body.correctiveMaintenance.endTime.toString() : '');
      formData.append(`CorrectiveMaintenance[CmFinePercentUnit]`, body.correctiveMaintenance.cmFinePercentUnit ? body.correctiveMaintenance.cmFinePercentUnit.toString() : '');
    }

    if (body.training) {
      formData.append(`Training[TrainingCount]`, body.training.trainingCount ? body.training.trainingCount.toString() : '');
      formData.append(`Training[TrainingCountUnit]`, body.training.trainingCountUnit ? body.training.trainingCountUnit.toString() : '');
      formData.append(`Training[TrainingUnitId]`, body.training.trainingUnitId ? body.training.trainingUnitId.toString() : '');
    }

    if (body.trainingItems && body.trainingItems.length > 0) {
      body.trainingItems.forEach((data, index) => {
        if (data.id) {
          formData.append(`TrainingItems[${index}][Id]`, data.id);
        }
        formData.append(`TrainingItems[${index}][TermOfRefId]`, data.termOfRefId ? data.termOfRefId : '');
        formData.append(`TrainingItems[${index}][Sequence]`, data.sequence.toString());
        formData.append(`TrainingItems[${index}][CourseName]`, data.courseName ? data.courseName : '');
        formData.append(`TrainingItems[${index}][PeriodDay]`, data.periodDay ? data.periodDay.toString() : '');
        formData.append(`TrainingItems[${index}][Place]`, data.place ? data.place : '');
        formData.append(`TrainingItems[${index}][TrainingCount]`, data.trainingCount ? data.trainingCount.toString() : '');
        formData.append(`TrainingItems[${index}][TotalPersonPerTime]`, data.totalPersonPerTime ? data.totalPersonPerTime.toString() : '');
      });
    }

    if (body.impediments && body.impediments.length > 0) {
      body.impediments.forEach((data, index) => {
        if (data.id) {
          formData.append(`Impediments[${index}][Id]`, data.id);
        }
        formData.append(`Impediments[${index}][TermOfRefId]`, data.termOfRefId ? data.termOfRefId : '');
        formData.append(`Impediments[${index}][Sequence]`, data.sequence.toString());
        formData.append(`Impediments[${index}][Description]`, data.description ? data.description.toString() : '');
        formData.append(`Impediments[${index}][ImpedimentValue]`, data.impedimentValue ? data.impedimentValue.toString() : '');
      });
    }
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.put(`/termOfRef/saveDraftTorStep2/${body.id}`, formData, config);
};

const updateTorStep3Async = (id: string) => {
  const formData = new FormData();

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.put(`/termOfRef/saveDraftTorDocument/${id}`, formData, config);
};

const updateTorStep4Async = (id: string, documentId: string, datas: CommitteeApproverTorModel[], middleDatas: CommitteeApproverTorModel[], sectionApprovers: SequenceApproverTorModel[], sendToCommitteeApproval: boolean, step: number, isJorPorComment: boolean) => {
  const formData = new FormData();

  formData.append('SendToCommitteeApproval', sendToCommitteeApproval ? 'true' : 'false');
  formData.append('DocumentId', documentId);
  formData.append('Step', step.toString());
  formData.append('IsJorPorComment', isJorPorComment === true ? 'true' : 'false');

  if (datas && datas.length > 0) {
    datas.forEach((data, index) => {
      if (data.id) {
        formData.append(`CommitteeApproverTors[${index}][id]`, data.id);
      }
      formData.append(`CommitteeApproverTors[${index}][approverUserId]`, data.approverUserId);
      formData.append(`CommitteeApproverTors[${index}][approverFullName]`, data.approverFullName);
      formData.append(`CommitteeApproverTors[${index}][DepartmentName]`, data.departmentName);
      formData.append(`CommitteeApproverTors[${index}][position]`, data.position);
      formData.append(`CommitteeApproverTors[${index}][approverPositionOnBoardId]`, data.approverPositionOnBoardId);
      formData.append(`CommitteeApproverTors[${index}][approverPositionOnBoardName]`, data.approverPositionOnBoardName);
      formData.append(`CommitteeApproverTors[${index}][isDisabled]`, data.isDisabled ? 'true' : 'false');
      formData.append(`CommitteeApproverTors[${index}][sequence]`, data.sequence.toString());
      formData.append(`CommitteeApproverTors[${index}][remark]`, data.remark === null ? '' : data.remark);
      if (data.approvedDateAt) {
        formData.append(`CommitteeApproverTors[${index}][approvedDateAt]`, new Date(data.approvedDateAt!).toISOString());
      }
    });
  }

  if (middleDatas && middleDatas.length > 0) {
    middleDatas.forEach((data, index) => {
      if (data.id) {
        formData.append(`CommitteeApproverMiddlePrices[${index}][id]`, data.id);
      }
      formData.append(`CommitteeApproverMiddlePrices[${index}][approverUserId]`, data.approverUserId);
      formData.append(`CommitteeApproverMiddlePrices[${index}][approverFullName]`, data.approverFullName);
      formData.append(`CommitteeApproverMiddlePrices[${index}][DepartmentName]`, data.departmentName);
      formData.append(`CommitteeApproverMiddlePrices[${index}][position]`, data.position);
      formData.append(`CommitteeApproverMiddlePrices[${index}][approverPositionOnBoardId]`, data.approverPositionOnBoardId);
      formData.append(`CommitteeApproverMiddlePrices[${index}][approverPositionOnBoardName]`, data.approverPositionOnBoardName);
      formData.append(`CommitteeApproverMiddlePrices[${index}][isDisabled]`, data.isDisabled ? 'true' : 'false');
      formData.append(`CommitteeApproverMiddlePrices[${index}][sequence]`, data.sequence.toString());
      formData.append(`CommitteeApproverMiddlePrices[${index}][remark]`, data.remark === null ? '' : data.remark);
    });
  }

  if (sectionApprovers && sectionApprovers.length > 0) {
    sectionApprovers.forEach((data, index) => {
      if (data.id) {
        formData.append(`CommitteeApproverTorSections[${index}][id]`, data.id);
      }
      formData.append(`CommitteeApproverTorSections[${index}][sectionSequence]`, data.sectionSequence.toString());
      formData.append(`CommitteeApproverTorSections[${index}][sequence]`, data.sequence.toString());
      formData.append(`CommitteeApproverTorSections[${index}][approverUserId]`, data.approverUserId);
      formData.append(`CommitteeApproverTorSections[${index}][approverFullName]`, data.approverFullName);
      formData.append(`CommitteeApproverTorSections[${index}][departmentName]`, data.departmentName);
      formData.append(`CommitteeApproverTorSections[${index}][position]`, data.position);
      formData.append(`CommitteeApproverTorSections[${index}][status]`, data.status);
      formData.append(`CommitteeApproverTorSections[${index}][inRefCode]`, data.inRefCode);
      if (data.approvedDateAt) {
        formData.append(`CommitteeApproverTorSections[${index}][approvedDateAt]`, new Date(data.approvedDateAt!).toISOString());
      }
      formData.append(`CommitteeApproverTorSections[${index}][remark]`, data.remark === null ? '' : data.remark);
      formData.append(`CommitteeApproverTorSections[${index}][isDisabled]`, data.isDisabled ? 'true' : 'false');
      formData.append(`CommitteeApproverTorSections[${index}][approvedUserBy]`, data.approvedUserBy ?? '');
      formData.append(`CommitteeApproverTorSections[${index}][approvedRemark]`, data.approvedRemark ?? '');
      formData.append(`CommitteeApproverTorSections[${index}][rejectedUserBy]`, data.rejectedUserBy ?? '');
      if (data.rejectedDateAt) {
        formData.append(`CommitteeApproverTorSections[${index}][rejectedDateAt]`, new Date(data.rejectedDateAt!).toISOString());
      }
      formData.append(`CommitteeApproverTorSections[${index}][rejectedRemark]`, data.rejectedRemark ?? '');
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.put(`/termOfRef/saveCommitteeApproval/${id}`, formData, config);
};

const updateTorStep5Async = (id: string, documentId: string, datas: SequenceApproverTorModel[], sentToSectionApprover: boolean, commentDescription?: string, step?: number) => {
  const formData = new FormData();

  formData.append('SentToSectionApprover', sentToSectionApprover ? 'true' : 'false');
  formData.append('CommentDescription', commentDescription ?? '');
  formData.append('DocumentId', documentId);
  formData.append('Step', step != null ? step.toString() : '3');

  if (datas && datas.length > 0) {
    datas.forEach((data, index) => {
      if (data.id) {
        formData.append(`CommitteeApproverTorSections[${index}][id]`, data.id);
      }
      formData.append(`CommitteeApproverTorSections[${index}][sectionSequence]`, data.sectionSequence.toString());
      formData.append(`CommitteeApproverTorSections[${index}][sequence]`, data.sequence.toString());
      formData.append(`CommitteeApproverTorSections[${index}][approverUserId]`, data.approverUserId);
      formData.append(`CommitteeApproverTorSections[${index}][approverFullName]`, data.approverFullName);
      formData.append(`CommitteeApproverTorSections[${index}][departmentName]`, data.departmentName);
      formData.append(`CommitteeApproverTorSections[${index}][position]`, data.position);
      formData.append(`CommitteeApproverTorSections[${index}][status]`, data.status);
      formData.append(`CommitteeApproverTorSections[${index}][inRefCode]`, data.inRefCode);
      if (data.approvedDateAt) {
        formData.append(`CommitteeApproverTorSections[${index}][approvedDateAt]`, new Date(data.approvedDateAt!).toISOString());
      }
      formData.append(`CommitteeApproverTorSections[${index}][remark]`, data.remark === null ? '' : data.remark);
      formData.append(`CommitteeApproverTorSections[${index}][isDisabled]`, data.isDisabled ? 'true' : 'false');
      formData.append(`CommitteeApproverTorSections[${index}][approvedUserBy]`, data.approvedUserBy ?? '');
      formData.append(`CommitteeApproverTorSections[${index}][approvedRemark]`, data.approvedRemark ?? '');
      formData.append(`CommitteeApproverTorSections[${index}][rejectedUserBy]`, data.rejectedUserBy ?? '');
      if (data.rejectedDateAt) {
        formData.append(`CommitteeApproverTorSections[${index}][rejectedDateAt]`, new Date(data.rejectedDateAt!).toISOString());
      }
      formData.append(`CommitteeApproverTorSections[${index}][rejectedRemark]`, data.rejectedRemark ?? '');
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.put(`/termOfRef/${id}/committeeApproverTorSection`, formData, config);
};

const deleteTorByIdAsync = (id: string) => http.delete(`/termOfRef/${id}`);

const deleteTorDocumentByIdAsync = (id: string, docId: string) => http.delete(`/termOfRef/${id}/documents/${docId}`);

const onCommiteeApproveAsync = (id: string, isMiddle: boolean, remark: string) => {
  let api = '/TermOfRefCommitteeApproval';

  if (isMiddle) {
    api = '/termofrefmiddlepriceapproval';
  }
  const formData = new FormData();
  formData.append('Remark', remark);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return http.put(`${api}/approve/${id}`, formData, config);
};

const onCommiteeRejectAsync = (id: string, remark: string, isMiddle: boolean) => {
  let api = '/TermOfRefCommitteeApproval';

  if (isMiddle) {
    api = '/termofrefmiddlepriceapproval';
  }

  const formData = new FormData();
  formData.append('Remark', remark);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return http.put(`${api}/reject/${id}`, formData, config);
};

const onRecallAsync = (id: string) => http.put(`/termOfRef/Recall/${id}`);

const onSectionApproveAsync = (id: string, remark: string) => {
  const formData = new FormData();
  formData.append('Remark', remark);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return http.put(`/termOfRef/approved/${id}`, formData, config);
};

const onSectionApproveEditingAsync = (id: string, remark: string) => {
  const formData = new FormData();
  formData.append('Remark', remark);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return http.put(`/termOfRefediting/approved/${id}`, formData, config);
};

const onSectionApproveCancelingAsync = (id: string, remark: string) => {
  const formData = new FormData();
  formData.append('Remark', remark);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return http.put(`/termOfRefcanceling/approved/${id}`, formData, config);
};

const onSectionRejectAsync = (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('Remark', remark);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.put(`/termOfRef/rejected/${id}`, formData, config);
};

const onJorPorDirectorRejectedAsync = (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('Remark', remark);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.put(`/termOfRef/jorpor-director-rejected/${id}`, formData, config);
};

const onSectiTermofrefCancelingAsync = (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('remark', remark);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.post(`/termofrefcanceling/${id}`, formData, config);
};

const onSectiTermofrefEditingAsync = (id: string, remark: string) => {
  const formData = new FormData();

  formData.append('remark', remark);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.post(`/termofrefediting/${id}`, formData, config);
};

const createDocumentTorAsync = (termOfRefId: string, isReadonly?: boolean) => {
  const body = {
    termOfRefId,
    isReadonly
  };

  const api = process.env.REACT_APP_API_URL;

  return http.post(`${api}/termofref/document/tor`, body);
};

const createMiddlePriceDocumentAsync = (termOfRefId: string) => {
  const body = {
    termOfRefId
  };

  const api = process.env.REACT_APP_API_URL;

  return http.post(`${api}/termofref/${termOfRefId}/document/middle-price`, body);
};

const onAttachmentTorDocumentByIdAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  if (files && files.length !== 0) {
    files.forEach((file) => {
      formData.append('Files', file);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.put(`/termOfRef/attachment/${id}`, formData, config);
};

export default {
  getListTorAsync,
  getTorByIdAsync,
  updateTorStep1Async,
  updateTorStep2Async,
  updateTorStep3Async,
  updateTorStep4Async,
  updateTorStep5Async,
  getCountStatusAsync,
  getCountSupplyMethodAsync,
  getListHistoryAsync,
  deleteTorByIdAsync,
  deleteTorDocumentByIdAsync,
  onCommiteeApproveAsync,
  onCommiteeRejectAsync,
  onSectionApproveAsync,
  onSectionRejectAsync,
  onSectiTermofrefCancelingAsync,
  onSectiTermofrefEditingAsync,
  onSectionApproveEditingAsync,
  onSectionApproveCancelingAsync,
  createDocumentTorAsync,
  createMiddlePriceDocumentAsync,
  onAttachmentTorDocumentByIdAsync,
  onRecallAsync,
  onJorPorDirectorRejectedAsync,
};
