import { DepartmentRequest } from 'models';
import http from 'utils/axios';

const createDepartmentAsync = async (body: DepartmentRequest) => await http.post('/department', body);

const getDepartmentListAsync = async (page: number, size: number, keyword?: string) => {
  const params = {
    page,
    size,
    keyword,
  };

  return await http.get('/department', { params });
};

const getDepartmentDetailAsync = async (id: string) => await http.get(`/department/${id}`);

const updateDepartmentAsync = async (id: string, body: DepartmentRequest) => await http.put(`/department/${id}`, body);

const deleteDepartmentAsync = async (id: string) => await http.delete(`/department/${id}`);

const getDepartmentsAsync = async (departmentCode?: string) => {
  const params = {
    departmentCode,
  };

  return await http.get('/department/list', { params });
};

const getDepartmentsListAsync = async <T>(departmentCode?: string, exceptJorPorCode?: string) => {
  const params = {
    departmentCode,
    exceptJorPorCode,
  };

  return await http.get<T>('/department/list', { params });
};

const getBuDepartmentsListAsync = async <T>(departmentCode?: string, exceptJorPorCode?: string) => {
  const params = {
    departmentCode,
    exceptJorPorCode,
  };

  return await http.get<T>('/department/list-selection', { params });
};

const getSolDatasListAsync = async <T>(keyword?: string) => {
  const params = {
    keyword,
  };

  return await http.get<T>('/department/list-sol-selection', { params });
};

export default {
  createDepartmentAsync,
  getDepartmentListAsync,
  getDepartmentDetailAsync,
  updateDepartmentAsync,
  deleteDepartmentAsync,
  getDepartmentsAsync,
  getDepartmentsListAsync,
  getBuDepartmentsListAsync,
  getSolDatasListAsync,
};
