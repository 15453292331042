import {
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  label?: string | JSX.Element;
  value?: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
  labelNotbtn?: string | JSX.Element;
}

export function Check(props: Props) {
  const uuid = uuidv4();
  const [value, setValue] = useState<boolean | undefined>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handlerOnChange = (event: HTMLInputElement) => {
    setValue(event.checked);

    if (props.onChange) {
      props.onChange(event.checked);
    }
  };

  return (
    <Form.Group className={`${props.label ? 'mb-3' : ''} ${props.className ?? ''}`}>
      <Form.Check
        type='checkbox'
        id={`checkbox-${uuid}`}
        className='d-flex gap-2'
      >
        <Form.Check.Input
          type='checkbox'
          className='cursor-pointer'
          onChange={(event) => handlerOnChange(event.target as HTMLInputElement)}
          checked={value || false}
          disabled={props.disabled}
        />
        <Form.Check.Label
          className='cursor-pointer d-flex align-items-center'
        >
          {props.label}
        </Form.Check.Label>
        {
          props.labelNotbtn
          && (
            <Form.Check.Label
              className='cursor-pointer d-flex align-items-center'>
              {props.labelNotbtn}
            </Form.Check.Label>
          )
        }
      </Form.Check>
    </Form.Group>
  );
}
