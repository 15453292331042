import {
  Card,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import { CardSummary } from 'components/Card/CardSummary';
import { JorPorCode } from 'constant/basicInformation';
import ScrollIntoView from "react-scroll-into-view";
import {
  ItemModel,
  Pagination,
  WorklistDataModel,
} from 'models';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';
import {
  dashboard,
  shareValue,
} from 'services';
import {
  HttpStatusCode,
  thaiFormatDate,
  THCurrency,
  useAppContext,
} from 'utils';
// import { setFixShowLoadingFlag } from 'utils/axios';

type DashboardContext = {
  criteria: Criteria;
  setCriteria: Dispatch<SetStateAction<Criteria>>;
  onSearch: (method: WorklistMethod, criteria: Criteria, page: number, size: number) => void;
  setSearchFn: Dispatch<SetStateAction<Function>>;
  departmentList: ItemModel[];
  supplyMethodList: ItemModel[];
  planList: WorklistDataModel[];
  planPagination: Pagination;
  setPlanPagination: Dispatch<SetStateAction<Pagination>>;
  preProcurementList: WorklistDataModel[];
  preProPagination: Pagination;
  setPreProPagination: Dispatch<SetStateAction<Pagination>>;
  procurementList: WorklistDataModel[];
  proPagination: Pagination;
  setProPagination: Dispatch<SetStateAction<Pagination>>;
  contractList: WorklistDataModel[];
  contractPagination: Pagination;
  setContractPagination: Dispatch<SetStateAction<Pagination>>;
}

type Criteria = {
  planNumber: string;
  planName: string;
  departmentId: string;
  supplyMethodId: string;
  isResponsible: boolean;
  isJorPor: boolean;
}

export enum WorklistMethod {
  Plan = 'Plan',
  PreProcurement = 'PreProcurement',
  Procurement = 'Procurement',
  Contract = 'Contract',
  All = 'All'
}

const Context = createContext({} as DashboardContext);

export default function Worklist() {
  const [criteria, setCriteria] = useState<Criteria>({} as Criteria);
  const [planList, setPlanList] = useState<WorklistDataModel[]>([]);
  const [planPagination, setPlanPagination] = useState<Pagination>({
    size: 10,
    page: 1,
  } as Pagination);
  const [preProcurementList, setPreProcurementList] = useState<WorklistDataModel[]>([]);
  const [preProPagination, setPreProPagination] = useState<Pagination>({
    size: 10,
    page: 1,
  } as Pagination);
  const [procurementList, setProcurementList] = useState<WorklistDataModel[]>([]);
  const [proPagination, setProPagination] = useState<Pagination>({
    size: 10,
    page: 1,
  } as Pagination);
  const [contractList, setContractList] = useState<WorklistDataModel[]>([]);
  const [contractPagination, setContractPagination] = useState<Pagination>({
    size: 10,
    page: 1,
  } as Pagination);
  const [departmentList, setDepartmentList] = useState<ItemModel[]>([]);
  const [supplyMethodList, setSupplyMethodList] = useState<ItemModel[]>([]);
  const {
    userId,
    departmentCode,
    departmentId,
  } = useAppContext();

  useEffect(() => {
    if (departmentCode) {
      setCriteria({
        ...criteria,
        isJorPor: departmentCode === JorPorCode.CODE,
        departmentId: departmentCode !== JorPorCode.CODE ? departmentId : 'All',
        isResponsible: departmentCode !== JorPorCode.CODE,
      });
    }
    getMasterAsync();
    onSearch(WorklistMethod.All, criteria, 1, 10, false);
  }, [departmentCode]);

  const getMasterAsync = async () => {
    const [department, supplyMethod] = await Promise.all([
      shareValue.GetDDL('Department'),
      shareValue.GetDDL('SupplyMethod'),
    ]);

    const departmentAll = [{
      label: 'ทั้งหมด',
      value: 'All',
    }, ...department];
    const SupplyMethodAll = [{
      label: 'ทั้งหมด',
      value: 'All',
    }, ...supplyMethod];

    setDepartmentList(departmentAll);
    setSupplyMethodList(SupplyMethodAll);
  };

  const onSearch = (method: WorklistMethod, parmCriteria: Criteria, page: number, size: number, isHideLoading: boolean) => {
    if (method === WorklistMethod.Plan || method === WorklistMethod.All) {
      getWorklistPlanAsync(page, parmCriteria, size, isHideLoading);
    }

    if (method === WorklistMethod.PreProcurement || method === WorklistMethod.All) {
      getWorklistPreProAsync(page, parmCriteria, size, isHideLoading);
    }

    if (method === WorklistMethod.Procurement || method === WorklistMethod.All) {
      getWorklistProAsync(page, parmCriteria, size, isHideLoading);
    }

    if (method === WorklistMethod.Contract || method === WorklistMethod.All) {
      getWorklistContractAsync(page, parmCriteria, size, isHideLoading);
    }
  };

  const getWorklistPlanAsync = async (page: number, parmCriteria: Criteria, size: number, isHideLoading: boolean) => {
    if (isHideLoading) {
      // setFixShowLoadingFlag(false);
    }
    const res = await dashboard.getWorklistPlanAsync(
      parmCriteria.planNumber,
      parmCriteria.planName,
      parmCriteria.departmentId,
      parmCriteria.supplyMethodId,
      true,
      parmCriteria.isJorPor,
      page,
      size,
    );

    if (res.status === HttpStatusCode.OK) {
      setPlanList(res.data.data);
      setPlanPagination({
        ...planPagination,
        page,
        size,
        totalRecords: res.data.totalCount,
      });
    }
    // setFixShowLoadingFlag(true);
  };

  const getWorklistPreProAsync = async (page: number, parmCriteria: Criteria, size: number, isHideLoading: boolean) => {
    if (isHideLoading) {
      // setFixShowLoadingFlag(false);
    }
    const res = await dashboard.getWorklistPreProAsync(
      parmCriteria.planNumber,
      parmCriteria.planName,
      parmCriteria.departmentId,
      parmCriteria.supplyMethodId,
      true,
      parmCriteria.isJorPor,
      page,
      size,
    );

    if (res.status === HttpStatusCode.OK) {
      setPreProcurementList(res.data.data);
      setPreProPagination({
        ...preProPagination,
        page,
        size,
        totalRecords: res.data.totalCount,
      });
    }
    // setFixShowLoadingFlag(true);
  };

  const getWorklistProAsync = async (page: number, parmCriteria: Criteria, size: number, isHideLoading: boolean) => {
    if (isHideLoading) {
      // setFixShowLoadingFlag(false);
    }
    const res = await dashboard.getWorklistProAsync(
      parmCriteria.planNumber,
      parmCriteria.planName,
      parmCriteria.departmentId,
      parmCriteria.supplyMethodId,
      true,
      parmCriteria.isJorPor,
      page,
      size,
    );

    if (res.status === HttpStatusCode.OK) {
      setProcurementList(res.data.data);
      setProPagination({
        ...proPagination,
        page,
        size,
        totalRecords: res.data.totalCount,
      });
    }
    // setFixShowLoadingFlag(true);
  };

  const getWorklistContractAsync = async (page: number, parmCriteria: Criteria, size: number, isHideLoading: boolean) => {
    if (isHideLoading) {
      // setFixShowLoadingFlag(false);
    }
    const res = await dashboard.getWorklistContractAsync(
      parmCriteria.planNumber,
      parmCriteria.planName,
      parmCriteria.departmentId,
      parmCriteria.supplyMethodId,
      true,
      parmCriteria.isJorPor,
      page,
      size,
    );

    if (res.status === HttpStatusCode.OK) {
      setContractList(res.data.data);
      setContractPagination({
        ...contractPagination,
        page,
        size,
        totalRecords: res.data.totalCount,
      });
    }
    // setFixShowLoadingFlag(true);
  };

  const valueContext = {
    criteria,
    setCriteria,
    onSearch,
    departmentList,
    supplyMethodList,
    planList,
    planPagination,
    setPlanPagination,
    preProcurementList,
    preProPagination,
    setPreProPagination,
    procurementList,
    proPagination,
    setProPagination,
    contractList,
    contractPagination,
    setContractPagination,
  } as DashboardContext;

  return (
    <Context.Provider
      value={valueContext}>
      <Header />
      <Criteria />
      <Summary />
      <PlanDataTable />
      <PreProurementDataTable />
      <ProurementDataTable />
      <ContractDataTable />
    </Context.Provider>
  );
}

function Header() {
  const dateNow = new Date();

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>Worklist</h4>
        <p className='m-0'>ข้อมูล ณ วันที่ {thaiFormatDate(dateNow)}</p>
      </div>
      <hr />
    </>
  );
}

function Criteria() {
  const {
    criteria,
    setCriteria,
    onSearch,
    departmentList,
    supplyMethodList,
    planPagination,
  } = useContext(Context);

  const onChange = (key: string, value: string | Date) => {
    setCriteria({
      ...criteria,
      [key]: value,
    });
  };

  const onClear = () => {
    setCriteria({} as Criteria);
    onSearch(WorklistMethod.All, {} as Criteria, 1, 10);
  };

  return (
    <Card>
      <div className='ps-1'>
        <Row>
          <Col sm={12}
            md={6}
            lg={3}>
            <Input type='text'
              label='เลขที่รายการจัดซื้อจัดจ้าง'
              value={criteria.planNumber}
              onChange={(val) => setCriteria({
                ...criteria,
                planNumber: val,
              })} />
          </Col>
          <Col sm={12}
            md={6}
            lg={3}>
            <Input type='text'
              label='ชื่อโครงการจัดซื้อจัดจ้าง'
              value={criteria.planName}
              onChange={(val) => setCriteria({
                ...criteria,
                planName: val,
              })} />
          </Col>
          <Col sm={12}
            md={6}
            lg={3}>
            <Selector label='ฝ่าย/สำนัก'
              value={criteria.departmentId || 'All'}
              onChange={departmentId =>
                onChange('departmentId', departmentId)}
              items={departmentList}
              disabled={!criteria.isJorPor} />
          </Col>
          <Col sm={12}
            md={6}
            lg={3}>
            <Selector label='วิธีจัดหา'
              value={criteria.supplyMethodId || 'All'}
              onChange={supplyMethodId =>
                onChange('supplyMethodId', supplyMethodId)}
              items={supplyMethodList} />
          </Col>
        </Row>
        <Row>
          <Col className='d-flex gap-2'>
            <Button
              variant='primary'
              className='d-flex align-items-center gap-2'
              onClick={() => onSearch(WorklistMethod.All, criteria, 1, 10)}>
              <FaSearch />ค้นหา
            </Button>
            <Button
              variant='outline-primary'
              className='d-flex align-items-center gap-2'
              onClick={() => onClear()}>
              <FaEraser />ล้าง
            </Button>
          </Col>
        </Row>
      </div>
    </Card>
  );
}

function Summary() {
  const {
    planPagination,
    preProPagination,
    proPagination,
    contractPagination,
  } = useContext(Context);

  return (
    <Row>
      <Col className='mt-3'>
        <CardSummary
          title='ทั้งหมด'
          total={(planPagination.totalRecords + preProPagination.totalRecords + proPagination.totalRecords + contractPagination.totalRecords) || 0} />
      </Col>
      <Col className='mt-3'>
        <ScrollIntoView selector=".card-plan">
          <CardSummary
            title='Plan'
            total={planPagination?.totalRecords || 0} />
        </ScrollIntoView>
      </Col>
      <Col className='mt-3'>
        <ScrollIntoView selector=".card-pre-procurement">
          <CardSummary
            title='Pre-Procurement'
            total={preProPagination?.totalRecords || 0} />
        </ScrollIntoView>
      </Col>
      <Col className='mt-3'>
        <ScrollIntoView selector=".card-procurement">
          <CardSummary
            title='Procurement'
            total={proPagination?.totalRecords || 0} />
        </ScrollIntoView>
      </Col>
      <Col className='mt-3'>
        <ScrollIntoView selector=".card-contract">
          <CardSummary
            title='Contract Management'
            total={contractPagination?.totalRecords || 0} />
        </ScrollIntoView>
      </Col>
    </Row>
  );
}

function PlanDataTable() {
  const {
    planList,
    planPagination,
    setPlanPagination,
    onSearch,
  } = useContext(Context);
  const navigate = useNavigate();

  const onClickLink = (id: string, type: string) => {
    switch (type) {
      case 'InYearPlan':
        return navigate(`/procurement-plan-inyear/detail/${id}`);
      case 'AnnualPlan':
        return navigate(`/procurement-plan-summary/detail/${id}`);
      case 'Announcement':
        return navigate(`/procurement-plan-public/detail/${id}`);
      case 'AnnualPlanChange':
        return navigate(`/procurement-plan-adjust/annual/detail/${id}`);
      case 'InYearPlanChange':
        return navigate(`/procurement-plan-adjust/inyear/detail/${id}`);
      case 'AnnualPlanCancel':
        return navigate(`/procurement-plan-cancel/annual/detail/${id}`);
      case 'InYearPlanCancel':
        return navigate(`/procurement-plan-cancel/inyear/detail/${id}`);
    }
  };

  const renderStatus = (type: string, status: string) => {
    switch (type) {
      case 'Announcement':
        return (<Status
          type={StatusType.ANNOUNCEPLANPROCESS}
          value={status} />);
      default:
        return (<Status
          type={StatusType.PROCESS}
          value={status} />);
    }
  };

  return (
    <Card className='mt-3'>
      <h5 className='text-primary card-plan'>
        Plan
      </h5>
      <Table
        total={planPagination.totalRecords}
        onChange={(size, page) => {
          setPlanPagination({
            ...planPagination,
            size,
            page,
          });
          onSearch(WorklistMethod.Plan, {} as Criteria, page, size);
        }}>
        <thead>
          <tr>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>เลขที่รายการจัดซื้อจัดจ้าง
            </th>
            <th style={{
              width: 150,
              minWidth: 150,
              maxWidth: 150,
            }}>สถานะ
            </th>
            <th style={{
              width: 400,
              minWidth: 400,
              maxWidth: 400,
            }}>ชื่อโครงการ/ชื่อประกาศ
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>งบประมาณ (บาท)
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>วิธีจัดหา
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>ฝ่าย/สำนัก
            </th>
          </tr>
        </thead>
        <tbody>
          {planList?.map((data, i) => (
            <tr key={data.id}>
              <td>
                <div className='d-flex justify-content-center'>
                  <Button
                    variant='link'
                    onClick={() => onClickLink(data.id, data.type)}>
                    {data.planNumber !== null ? data.planNumber : data.refNo}
                  </Button>
                </div>
              </td>
              <td className='text-wrap text-center'>
                {
                  renderStatus(data.type, data.status)
                }
              </td>
              <td className='text-wrap'
                style={{ width: 400 }}>{data.planName}
              </td>
              <td className='text-end'>{THCurrency(data.budget)}</td>
              <td className='text-wrap text-center'>{data.supplyMethodName}</td>
              <td className='text-wrap text-center'>{data.departmentName}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}

function PreProurementDataTable() {
  const {
    preProcurementList,
    preProPagination,
    setPreProPagination,
    onSearch,
  } = useContext(Context);
  const navigate = useNavigate();

  const onClickLink = (id: string, type: string, refId: string) => {
    switch (type) {
      case 'Appoint':
        return navigate(`/preprocurement-directive/detail/${id}`);
      case 'AppointChange':
        return navigate(`/edit-preprocurement-directive/edit/${id}`);
      case 'AppointCancel':
        return navigate(`/cancel-preprocurement-directive/cancel/${id}`);
      case 'Tor':
        return navigate(`/preprocurement-tor/detail/${id}`);
      case 'TorChange':
        return navigate(`/edit-preprocurement-tor/detail/${id}`);
      case 'TorCancel':
        return navigate(`/cancel-preprocurement-tor/detail/${id}`);
      case 'MedianPrice':
        return navigate(`/preprocurement-median-price/detail/${id}`);
      case 'JorPor04IgnoreTor':
        return navigate(`/pp/pp0205/detail/basicInfo/${id}`);
      default:
        if (id === '00000000-0000-0000-0000-000000000000') {
          return navigate(`/pp/pp0205/detail/${refId}`);
        }

        return navigate(`/pp/pp0205/detail/${refId}/${id}`);
    }
  };

  const renderStatus = (type: string, status: string) => {
    switch (type) {
      case 'Appoint':
        return (<Status
          type={StatusType.APPOINTMENT}
          value={status} />);
      case 'AppointChange':
        return (<Status
          type={StatusType.APPOINTMENT}
          value={status} />);
      case 'AppointCancel':
        return (<Status
          type={StatusType.APPOINTMENT}
          value={status} />);
      case 'Tor':
        return (<Status
          type={StatusType.TOR}
          value={status} />);
      case 'TorChange':
        return (<Status
          type={StatusType.TOR}
          value={status} />);
      case 'TorCancel':
        return (<Status
          type={StatusType.TOR}
          value={status} />);
      case 'MedianPrice':
        return (<Status
          type={StatusType.MEDIANPRICE}
          value={status} />);
      default:
        return (<Status type={StatusType.JOR_POR_04_05_APPROVER}
          value={status ?? ''} />);
    }
  }

  return (
    <Card className='mt-3 card-pre-procurement'>
      <h5 className='text-primary'>
        Pre-Procurement
      </h5>
      <Table
        total={preProPagination.totalRecords}
        onChange={(size, page) => {
          setPreProPagination({
            ...preProPagination,
            size,
            page,
          });
          onSearch(WorklistMethod.PreProcurement, {} as Criteria, page, size);
        }}>
        <thead>
          <tr>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>เลขที่รายการจัดซื้อจัดจ้าง
            </th>
            <th style={{
              width: 150,
              minWidth: 150,
              maxWidth: 150,
            }}>สถานะ
            </th>
            <th style={{
              width: 400,
              minWidth: 400,
              maxWidth: 400,
            }}>ชื่อโครงการ
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>งบประมาณ (บาท)
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>วิธีจัดหา
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>ฝ่าย/สำนัก
            </th>
          </tr>
        </thead>
        <tbody>
          {preProcurementList?.map((data, i) => (
            <tr key={data.id}>
              <td>
                <div className='d-flex justify-content-center'>
                  <Button
                    variant='link'
                    onClick={() => onClickLink(data.id, data.type, data.refId)}>
                    {data.planNumber !== null ? data.planNumber : data.refNo}
                  </Button>
                </div>
              </td>
              <td className='text-center text-wrap'>
                {
                  renderStatus(data.type, data.status)
                }
              </td>
              <td className='text-wrap'
                style={{ width: 400 }}>{data.planName}
              </td>
              <td className='text-end'>{THCurrency(data.budget)}</td>
              <td className='text-center text-wrap'>{data.supplyMethodName}</td>
              <td className='text-center text-wrap'>{data.departmentName}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}

function ProurementDataTable() {
  const {
    procurementList,
    proPagination,
    setProPagination,
    onSearch,
  } = useContext(Context);
  const navigate = useNavigate();

  const onClickLink = (id: string, type: string, refId: string) => {
    switch (type) {
      case 'JorPor05':
        return navigate(`/pr/pr0101/detail/${id}`);
      case 'AppointOrder':
        if (id === '00000000-0000-0000-0000-000000000000') {
          return navigate(`/procurement-appointment-order/detail/${refId}`);
        }

        return navigate(`/procurement-appointment-order/detail/${refId}/id/${id}`);
      case 'Invite':
        if (id === '00000000-0000-0000-0000-000000000000') {
          return navigate(`/procurement-invitation-order/detail/${refId}`);
        }
        return navigate(`/procurement-invitation-order/detail/${refId}/id/${id}`);

      case 'JorPor06':
        if (id === '00000000-0000-0000-0000-000000000000') {
          return navigate(`/procurement-create-006/detail/${refId}`);
        }
        return navigate(`/procurement-create-006/detail/${refId}/${id}`);

      case 'ContractRecord':
        if (id === '00000000-0000-0000-0000-000000000000') {
          return navigate(`/pr/pr0206/detail/${refId}`);
        }

        if (refId === '00000000-0000-0000-0000-000000000000' || refId === '' || refId === null) {
          return navigate(`/pr/pr0206/detail/id/${id}`);
        }

        return navigate(`/pr/pr0206/detail/${refId}/id/${id}`);
    }
  };

  const renderStatus = (type: string, status: string) => {
    switch (type) {
      case 'JorPor05':
        return (<Status
          type={StatusType.JOR_POR_04_05_APPROVER}
          value={status} />);
      case 'AppointOrder':
        return (<Status
          type={StatusType.APPOINTMENTORDER}
          value={status} />);
      case 'Invite':
        return (<Status
          type={StatusType.INVITATIONORDER}
          value={status} />);
      case 'JorPor06':
        return (<Status
          type={StatusType.JOR_POR_04_05_APPROVER}
          value={status} />);
      default:
        return (<Status
          type={StatusType.PURCHASE_ORDER_APPROVAL}
          value={status ?? ''} />);
    }
  };

  return (
    <Card className='mt-3 card-procurement'>
      <h5 className='text-primary'>
        Procurement
      </h5>
      <Table
        total={proPagination.totalRecords}
        onChange={(size, page) => {
          setProPagination({
            ...proPagination,
            size,
            page,
          });
          onSearch(WorklistMethod.Procurement, {} as Criteria, page, size);
        }}>
        <thead>
          <tr>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>เลขที่รายการจัดซื้อจัดจ้าง
            </th>
            <th style={{
              width: 150,
              minWidth: 150,
              maxWidth: 150,
            }}>สถานะ
            </th>
            <th style={{
              width: 400,
              minWidth: 400,
              maxWidth: 400,
            }}>ชื่อโครงการ
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>งบประมาณ (บาท)
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>วิธีจัดหา
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>ฝ่าย/สำนัก
            </th>
          </tr>
        </thead>
        <tbody>
          {procurementList?.map((data, i) => (
            <tr key={data.id}>
              <td>
                <div className='d-flex justify-content-center'>
                  <Button
                    variant='link'
                    onClick={() => onClickLink(data.id, data.type, data.refId)}>
                    {data.planNumber !== null ? data.planNumber : data.refNo}
                  </Button>
                </div>
              </td>
              <td className='text-center text-wrap'>
                {
                  renderStatus(data.type, data.status)
                }
              </td>
              <td className='text-wrap'
                style={{ width: 400 }}>{data.planName}
              </td>
              <td className='text-end'>{THCurrency(data.budget)}</td>
              <td className='text-center text-wrap'>{data.supplyMethodName}</td>
              <td className='text-center text-wrap'>{data.departmentName}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}

function ContractDataTable() {
  const {
    contractList,
    contractPagination,
    setContractPagination,
    onSearch,
  } = useContext(Context);
  const navigate = useNavigate();

  const onClickLink = (id: string, type: string, refNo?: string, refId?: string, refId2?: string) => {
    switch (type) {
      case 'InviteContract':
        return navigate(`/contract-invitation-sign/detail/${id}/${refNo}`);
      case 'ContractAgreement':
        return navigate(`/contract-agreement/detail/${id}`);
      case 'ContractManagement':
        return navigate(`/contract-management/detail/${id}`);
      case 'ContractManagementNotContract':
        return navigate(`/contract-management/detail/contractRecord/${id}/financial/${refId}/entrepreneurs/${refId2}`);
      case 'ContractComplete':
        return navigate(`/cm/cm06/detail/${id}`);
      case 'ContractWarranty':
        return navigate(`/cm/cm06/detail/${id}`);
      case 'ContractCompleteReport':
        return navigate(`/cm/cm05/detail/${id}`);
      case 'ContractAudit':
        return navigate(`/cm/cm04/detail/${id}`);
    }
  };

  const renderStatus = (type: string, status: string) => {
    switch (type) {
      case 'InviteContract':
        return (
          <Status
            type={StatusType.INVITEDCONTRACTSTATUS}
            value={status} />
        );
      case 'ContractAgreement':
        return (
          <Status
            type={StatusType.DRAFT_CONTRACT}
            value={status} />
        );
      case 'ContractCompleteReport':
        return (
          <Status
            type={StatusType.ContractCompleteReport}
            value={status} />
        );
      case 'ContractComplete':
        return (
          <Status
            type={StatusType.ContractReturnCollateral}
            value={status} />
        );
      case 'ContractAudit':
        return (
          <Status
            type={StatusType.ContractStatus}
            value={status} />
        );
      default:
        return (
          <Status
            type={StatusType.CM03Status}
            value={type} />
        );
    }
  };

  return (
    <Card className='mt-3 card-contract'>
      <h5 className='text-primary'>
        Contract Management
      </h5>
      <Table
        total={contractPagination.totalRecords}
        onChange={(size, page) => {
          setContractPagination({
            ...contractPagination,
            size,
            page,
          });
          onSearch(WorklistMethod.Contract, {} as Criteria, page, size);
        }}>
        <thead>
          <tr>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>เลขที่รายการจัดซื้อจัดจ้าง
            </th>
            <th style={{
              width: 150,
              minWidth: 150,
              maxWidth: 150,
            }}>สถานะ
            </th>
            <th style={{
              width: 400,
              minWidth: 400,
              maxWidth: 400,
            }}>ชื่อโครงการ
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>งบประมาณ (บาท)
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>วิธีจัดหา
            </th>
            <th className='text-wrap'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}>ฝ่าย/สำนัก
            </th>
          </tr>
        </thead>
        <tbody>
          {contractList?.map((data, i) => (
            <tr key={data.id}>
              <td>
                <div className='d-flex justify-content-center'>
                  <Button
                    variant='link'
                    onClick={() => onClickLink(data.id, data.type, data.refNo, data.refId, data.refId2)}>
                    {data.planNumber !== null ? data.planNumber : data.refNo}
                  </Button>
                </div>
              </td>
              <td className='text-center text-wrap'>
                {
                  renderStatus(data.type, data.status)
                }
              </td>
              <td className='text-wrap'
                style={{ width: 400 }}>{data.planName}
              </td>
              <td className='text-end'>{THCurrency(data.budget)}</td>
              <td className='text-center text-wrap'>{data.supplyMethodName}</td>
              <td className='text-center text-wrap'>{data.departmentName}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}
