import { Breadcrumb } from 'components';
import StepProgress from 'components/StepProgress';
import { AppointmentApprovalResponseStep1 } from 'models/PP/PP0301Models';
import {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import PP0301Service from 'services/PP/PP0301Service';
import { HttpStatusCode } from 'utils';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type PP0301Context = {
  appointmentApprovalData: AppointmentApprovalResponseStep1
};

export const Context = createContext({} as PP0301Context);

const BREADCRUMB_INFO = [
  {
    routerName: '/pp/pp0301', label: 'อนุมัติแต่งตั้ง / เปลี่ยนแปลง / ยกเลิก',
  },
  {
    routerName: '/pp/pp0301/detail', label: 'รายละเอียดบันทึกแต่งตั้ง',
  },
];

interface stepData {
  title: JSX.Element;
  displayIndex: string;
  child: JSX.Element;
}

export default function AddPP0301() {
  const { id } = useParams();
  const [appointmentApprovalData, setAppointmentApprovalResponseStep1Data] = useState<AppointmentApprovalResponseStep1>({} as AppointmentApprovalResponseStep1);
  const [steps, setSteps] = useState<stepData[]>([]);

  const getAppointmentApprovalDetailById = useCallback(async () => {
    const response = await PP0301Service.getAppointmentApprovalDetailAsync(id || '');

    if (response.status === HttpStatusCode.OK) {
      setAppointmentApprovalResponseStep1Data(response.data);

      if (response.data.planBudget < 100000) {
        setSteps(objLow);
      } else {
        setSteps(obj);
      }
    }
  }, [id]);

  useEffect(() => {
    getAppointmentApprovalDetailById();
  }, []);

  const childRef = useRef<ProgressBarRefType>(null);
  const obj = [{
    title: <p>ข้อมูลเอกสาร
      บันทึกข้อความแต่งตั้ง
    </p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p>คณะกรรมการจัดทำร่างขอบเขตของงาน</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>คณะกรรมการกำหนดราคากลาง</p>,
    displayIndex: '3',
    child: <Step3 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>อำนาจอนุมัติ</p>,
    displayIndex: '4',
    child: <Step4 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const objLow = [{
    title: <p>ข้อมูลเอกสาร
      บันทึกข้อความแต่งตั้ง
    </p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p>คณะกรรมการจัดทำร่างขอบเขตของงาน</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>อำนาจอนุมัติ</p>,
    displayIndex: '4',
    child: <Step4 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();
  const backToIndex = () => {
    navigate('/pp/pp0301');
  };

  return (
    <div className="document">
      <h4 className="mt-2 mb-0 text-primary text-start">รายละเอียดบันทึกแต่งตั้ง</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12} className="mb-2">
          <Context.Provider value={{ appointmentApprovalData }}>
            <StepProgress progressBarData={steps} ref={childRef} />
          </Context.Provider> : <></>
        </Col>
      </Row>
    </div>
  );
}
