import {
    Check,
    MedianPriceStatus,
    Status,
    StatusType,
    Table,
    Modal,
    StatusDashBoard,
    ButtonCustom,
    SupplyMethodEnum,
} from 'components';
import {
    DatePicker,
    Input,
    Selector,
} from 'components/Controls';
import { TorEditCancelStatusConstant } from 'constant/PreProcumentTorStatus';
import { ItemModel } from 'models';
import {
    useEffect,
    useState,
} from 'react';
import {
    Badge,
    Button,
    Col,
    Form,
    InputGroup,
    Row,
    Modal as ModalBT,
} from 'react-bootstrap';
import { FaCheck, FaEraser, FaSearch, FaTrashAlt } from 'react-icons/fa';
import {
    useLoaderData,
    useNavigate,
} from 'react-router';
import MedianPriceService from 'services/PP/MedianPriceService';
import {
    GetMonthYearBC,
    HttpStatusCode,
    THCurrency,
    thaiFormatDate,
    useAppContext,
} from 'utils';
import { Project, ProjectCriteria } from 'models/projectListModels';
import { dashboard } from 'services';
import { PlanTypeText, ProgramType } from 'utils/constants/PlanEnum';
import { TbListSearch } from 'react-icons/tb';
import { JorPorCode } from 'constant/basicInformation';

interface StatusItemModel {
    label: string;
    value: boolean;
}

type Loader = { departmentDDL: ItemModel[], supplyMethodDDL: ItemModel[], };

export default function ProjectList() {
    const { departmentDDL, supplyMethodDDL } = useLoaderData() as Loader;
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [dataList, setDataList] = useState<Project[]>([]);
    const [criteria, setCriteria] = useState<ProjectCriteria>({} as ProjectCriteria);
    const dateNow = new Date();
    const { userId, departmentCode, departmentId } = useAppContext();

    useEffect(() => {
        if (departmentCode) {
            setCriteria({
                ...criteria,
                departmentId: departmentCode !== JorPorCode.CODE ? departmentId : '',
            });
        }

        getListDataAsync(criteria, departmentCode);

    }, [departmentCode]);

    const goToPageCollectAttachment = (planNumber: string) => {
        window.open(`/web/collect-attachment/${planNumber}`, '_blank');
    };

    const planOnClick = (data: Project) => {
        if (data.planIsCanceled) {
            return navigate(`/procurement-plan-cancel/inyear/detail/${data.planId}`);
        }

        if (data.planIsChanged) {
            return navigate(`/procurement-plan-adjust/inyear/detail/${data.planId}`);
        }

        if (data.planType === PlanTypeText.AnnualPlan) {
            return navigate(`/procurement-plan-summary/detail/${data.planId}`);
        }

        if (data.planType === PlanTypeText.InYearPlan) {
            return navigate(`/procurement-plan-inyear/detail/${data.planId}`);
        }
    };


    const torOnClick = (torId: string) => {
        return navigate(`/preprocurement-tor/detail/${torId}`);
    };

    const getListDataAsync = async (searchModel: ProjectCriteria, departmentCode?: string) => {

        if (!searchModel.page) {
            searchModel.page = page;
        }

        if (!searchModel.pageSize) {
            searchModel.pageSize = 10;
        }

        if (searchModel.departmentId === undefined && departmentCode !== JorPorCode.CODE) {
            searchModel.departmentId = departmentId;
        }

        const res = await dashboard.getProjectListAsync(
            searchModel,
        );

        if (res.status === HttpStatusCode.OK) {
            setDataList(res.data.data);
            setTotalRecords(res.data.totalRecords);
        }

    };

    const onPaginationChange = (size: number, page: number) => {
        setPageSize(size);
        setPage(page);
        criteria.page = page;
        criteria.pageSize = size;
        onSearch();
    };

    const onSearch = () => {
        const searchCriteria = {
            departmentId: criteria.departmentId,
            planName: criteria.planName,
            supplyMethodId: criteria.supplyMethodId,
            page: criteria.page,
            pageSize: criteria.pageSize,
            contractNumber: criteria.contractNumber,
            planNumber: criteria.planNumber,
            traderName: criteria.traderName,
            endDate: criteria.endDate,
            startDate: criteria.startDate,
        } as ProjectCriteria;

        getListDataAsync(searchCriteria);
    };

    const onClearSearch = () => {
        const isSize = 10;
        const isPage = 1;
        setPage(isPage);
        setPageSize(isSize);

        onPaginationChange(isSize, isPage);
        setCriteria({
            page: isPage,
            pageSize: isSize
        } as ProjectCriteria);

        const searchCriteria = {
            page: isPage,
            pageSize: isSize,
        } as ProjectCriteria;
        getListDataAsync(searchCriteria);
    };

    return (
        <div className='m01'>
            <>
                <div className='d-flex justify-content-between align-items-center'>
                    <h4 className='text-primary m-0'>ติดตามรายการจัดซื้อจัดจ้าง</h4>
                    <p className='m-0'>ข้อมูล ณ วันที่ {thaiFormatDate(dateNow)}</p>
                </div>
                <hr />
            </>
            {/* <hr /> */}
            <Row className='criteria'>
                <Row className='criteria'>
                    <Col sm={12}
                        md={6}
                        lg={3}>
                        <Input
                            label='เลขที่รายการจัดซื้อจัดจ้าง'
                            value={criteria.planNumber}
                            onChange={planNumber => setCriteria({ ...criteria, planNumber })}
                        />
                    </Col>
                    <Col sm={12}
                        md={6}
                        lg={3}>
                        <Input
                            label='ชื่อโครงการ'
                            value={criteria.planName}
                            onChange={planName => setCriteria({ ...criteria, planName })}
                        />
                    </Col>
                    <Col sm={12}
                        md={6}
                        lg={3}>
                        <Selector label='ฝ่าย/สำนัก'
                            value={criteria.departmentId}
                            onChange={departmentId => setCriteria({ ...criteria, departmentId })}
                            items={departmentDDL}
                            disabled={departmentCode !== JorPorCode.CODE} />
                    </Col>
                    <Col sm={12}
                        md={6}
                        lg={3}>
                        <Selector label='วิธีจัดหา'
                            value={criteria.supplyMethodId}
                            onChange={supplyMethodId => setCriteria({ ...criteria, supplyMethodId })}
                            items={supplyMethodDDL} />
                    </Col>
                    <Col sm={12}
                        md={6}
                        lg={3}>
                        <DatePicker
                            label='ช่วงเวลาการจัดซื้อจัดจ้างเริ่มต้น'
                            value={criteria.startDate}
                            onChange={startDate => setCriteria({ ...criteria, startDate })}
                            monthYearOnly />
                    </Col>
                    <Col sm={12}
                        md={6}
                        lg={3}>
                        <DatePicker label='ช่วงเวลาการจัดซื้อจัดจ้างสิ้นสุด'
                            value={criteria.endDate}
                            onChange={endDate => setCriteria({ ...criteria, endDate })}
                            monthYearOnly />
                    </Col>
                    <Col sm={12}
                        md={6}
                        lg={3}>
                        <Input
                            label='เลขที่สัญญา'
                            value={criteria.contractNumber}
                            onChange={contractNumber => setCriteria({ ...criteria, contractNumber })}
                        />
                    </Col>
                    {/* <Col sm={12}
                        md={6}
                        lg={3}>
                        <Input
                            label='เลขที่ PO'
                            value={criteria.poNumber}
                            onChange={poNumber => setCriteria({ ...criteria, poNumber })}
                        />
                    </Col> */}
                    <Col sm={12}
                        md={6}
                        lg={3}>
                        <Input
                            label='ชื่อบริษัท'
                            value={criteria.traderName}
                            onChange={traderName => setCriteria({ ...criteria, traderName })}
                        />
                    </Col>
                </Row>
                <div className='d-flex gap-2'>
                    <Button
                        variant="primary"
                        className="px-3"
                        type="submit"
                        form="userModalForm"
                        onClick={onSearch}>
                        <FaSearch className="me-2" />ค้นหา
                    </Button>
                    <Button
                        className="px-4"
                        variant="outline-primary"
                        type="submit"
                        form="userModalForm"
                        onClick={onClearSearch}>
                        <FaEraser className="me-2" />ล้าง
                    </Button>
                </div>
            </Row>
            <Row>
                <Col sm={12}>
                    <Table total={totalRecords}
                        className='table-relative-fix-start-and-head'
                        onChange={(size, page) => {
                            onPaginationChange(size, page);
                        }}>
                        <thead>
                            <tr className='text-center'>
                                <th style={{ minWidth: 100 }}>เลขที่รายการ<br />จัดซื้อจัดจ้าง</th>
                                <th style={{ minWidth: 300 }}>ชื่อโครงการ</th>
                                <th style={{ minWidth: 200 }}>ฝ่าย/สำนัก</th>
                                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                                <th style={{ minWidth: 100 }}>ช่วงเวลาการ<br />จัดซื้อจัดจ้าง</th>
                                <th style={{ minWidth: 150 }}>เลขที่สัญญา</th>
                                <th style={{ minWidth: 150 }}>ชื่อบริษัท</th>
                                <th style={{ minWidth: 150 }}>งบประมาณ (บาท)</th>
                                <th style={{ minWidth: 200 }}>รายการจัดซื้อจัดจ้าง</th>
                                <th style={{ minWidth: 200 }}>ขอแต่งตั้งบุคคล/คกก. <br />จัดทำขอบเขตของงาน/ราคากลาง</th>
                                <th style={{ minWidth: 200 }}>จัดทำร่างขอบเขตของงาน <br />(TOR)</th>
                                <th style={{ minWidth: 200 }}>กำหนดราคากลาง<br /> (ราคาอ้างอิง)</th>
                                <th style={{ minWidth: 200 }}>การแจ้งข้อมูลเบื้องต้น <br />(จพ.004)</th>
                                <th style={{ minWidth: 200 }}>จัดทำรายงานขอซื้อขอจ้าง <br />(จพ.005)</th>
                                <th style={{ minWidth: 200 }}>จัดทำหนังสือเชิญชวน <br />ผู้ประกอบการ</th>
                                <th style={{ minWidth: 200 }}>จัดทำรายงานผลการพิจารณา<br />และขออนุมัติสั่งซื้อ/สั่งจ้าง <br />(จพ.006)</th>
                                <th style={{ minWidth: 200 }}>อนุมัติใบสั่งซื้อ/จ้าง/เช่า <br />และแจ้งทำสัญญา</th>
                                <th style={{ minWidth: 200 }}>หนังสือเชิญชวนทำสัญญา</th>
                                <th style={{ minWidth: 200 }}>ร่างสัญญาและสัญญา</th>
                                <th style={{ minWidth: 200 }}>บันทึกส่งมอบและตรวจรับ</th>
                                <th style={{ minWidth: 200 }}>คืนหลักประกันสัญญา</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataList?.map((data, index) => (
                                    <tr>
                                        <td>
                                            <div className='d-flex justify-content-center'>
                                                <Button
                                                    variant='link'
                                                    onClick={() => planOnClick(data)}>
                                                    {data.planNumber}
                                                </Button>
                                            </div>
                                        </td>
                                        {/* <td>
                                            <div className='d-flex justify-content-center p-2'>
                                                <Button
                                                    variant='link'
                                                    onClick={() => torOnClick(data.torId)}>
                                                    {data.torNumber}
                                                </Button>
                                            </div>
                                        </td> */}
                                        <td className='p-2 text-wrap'>{data.name}</td>
                                        <td className='p-2'>{data.departmentName}</td>
                                        <td className='text-center p-2'>{data.supplyMethod}</td>
                                        <td className='text-center p-2'>{GetMonthYearBC(data.expectingProcurementAt)}</td>
                                        <td className='text-center p-2'>{data.contractNumber}</td>
                                        <td className='text-start p-2'>{data.entrepreneurName}</td>
                                        <td className='text-end p-2'>{THCurrency(data.budget)}</td>
                                        <td>
                                            {(data.planIsCanceled)
                                                ? <StatusDashBoard id={data.planId} type={data.planType} program={ProgramType.Plan} status="Deleted" />
                                                : <StatusDashBoard id={data.planId} type={data.planType} program={ProgramType.Plan} status={data.planStatus ? data.planStatus : 'None'} />
                                            }
                                        </td>
                                        <td>
                                            {(data.supplyMethodId === SupplyMethodEnum.MethodId60 && data.budget > 100000)
                                                ? <StatusDashBoard program={ProgramType.Appoint} status='NotHave' />
                                                : (data.appointIsDeleted || data.appointIsCancel)
                                                    ? <StatusDashBoard program={ProgramType.Appoint} status={data.appointIsDeleted ? "None" : "Deleted"} />
                                                    : <StatusDashBoard id={data.appointId} program={ProgramType.Appoint} status={data.appointStatus ? data.appointStatus : 'None'} />
                                            }
                                        </td>
                                        <td>
                                            {(data.supplyMethodId === SupplyMethodEnum.MethodId60 && data.budget > 100000)
                                                ? <StatusDashBoard program={ProgramType.Tor} status='NotHave' />
                                                : (data.torIsDeleted || data.torIsCancel)
                                                    ? <StatusDashBoard program={ProgramType.Tor} status={data.torIsDeleted ? "None" : "Deleted"} />
                                                    : <StatusDashBoard id={data.torId} program={ProgramType.Tor} status={data.torStatus ? data.torStatus : 'None'} />
                                            }
                                        </td>
                                        <td>
                                            {(data.supplyMethodId === SupplyMethodEnum.MethodId60 && data.budget > 100000)
                                                ? <StatusDashBoard program={ProgramType.MedianPrice} status='NotHave' />
                                                : (data.appointPlanBudget !== null && data.appointPlanBudget < 100000)
                                                    ? <StatusDashBoard program={ProgramType.MedianPrice} status='NotHave' />
                                                    : <StatusDashBoard id={data.medianPriceId} program={ProgramType.MedianPrice} status={data.medianPriceStatus ? data.medianPriceStatus : 'None'} />
                                            }
                                        </td>
                                        <td>
                                            {(data.supplyMethodId === SupplyMethodEnum.MethodId60 && data.budget > 100000
                                                ? <StatusDashBoard program={ProgramType.JorPor04} status='NotHave' />
                                                : <StatusDashBoard id={data.jp04Id} refId={data.torId} program={ProgramType.JorPor04} status={data.jp04Status ? data.jp04Status : 'None'} />
                                            )}
                                        </td>
                                        <td>
                                            {(data.supplyMethodId === SupplyMethodEnum.MethodId60 && data.budget > 100000
                                                ? <StatusDashBoard program={ProgramType.JorPor05} status='NotHave' />
                                                : <StatusDashBoard id={data.jp04Id} program={ProgramType.JorPor05} status={data.jp05Status ? data.jp05Status : 'None'} />
                                            )}
                                        </td>
                                        <td>
                                            {(data.supplyMethodId === SupplyMethodEnum.MethodId60 && data.budget > 100000
                                                ? <StatusDashBoard program={ProgramType.Invite} status='NotHave' />
                                                : <StatusDashBoard id={data.inviteId} refId={data.jp05Id} program={ProgramType.Invite} status={data.inviteStatus ? data.inviteStatus : 'None'} />
                                            )}
                                        </td>
                                        <td>
                                            {(data.supplyMethodId === SupplyMethodEnum.MethodId60 && data.budget > 100000
                                                ? <StatusDashBoard program={ProgramType.JorPor06} status='NotHave' />
                                                : <StatusDashBoard id={data.jp06Id} refId={data.inviteId} program={ProgramType.JorPor06} status={data.jp06Status ? data.jp06Status : 'None'} />
                                            )}
                                        </td>
                                        <td>
                                            <StatusDashBoard id={data.contractRecordId} refId={data.jp06Id !== undefined ? data.jp06Id : data.jp04Id} program={ProgramType.ContractRecord} status={data.contractRecordStatus ? data.contractRecordStatus : 'None'} />
                                        </td>
                                        <td>
                                            <StatusDashBoard id={data.contractInvitationId} refId={data.entrepreneursTaxId} program={ProgramType.ContractAgreementInvitation} status={data.contractInvitationStatus ? data.contractInvitationStatus : 'None'} />
                                        </td>
                                        <td>
                                            <StatusDashBoard id={data.contractAgreementId} program={ProgramType.ContractAgreement} status={data.contractAgreementStatus ? data.contractAgreementStatus : 'None'} />
                                        </td>
                                        <td>
                                            <StatusDashBoard id={data.contractAgreementId} program={ProgramType.ContractManagement} status={data.contractManagementStatus ? data.contractManagementStatus : 'None'} />
                                        </td>
                                        <td>
                                            <StatusDashBoard id={data.contractReturnId} program={ProgramType.ContractReturnCollateral} status={data.contractReturnStatus ? data.contractReturnStatus : 'None'} />
                                        </td>
                                        <td className='text-center'>
                                            <Button variant="link" onClick={() => goToPageCollectAttachment(data.planNumber)}><TbListSearch className="fs-4" /> ไปยังเอกสารแนบทั้งหมด</Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    );
}
