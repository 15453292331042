import { Table } from 'components';
import { Input } from 'components/Controls';
import { Role } from 'models';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEdit,
  FaEraser,
  FaPlus,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { role } from 'services';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';

type Loader = { roles: { data: Role[], totalRecords: number } };

export default function SU06() {
  const loader = useLoaderData() as Loader;
  const [keyword, setKeyword] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [roles, setRoles] = useState<Role[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setRoles(loader.roles.data);
    setTotalRecords(loader.roles.totalRecords);
  }, []);

  useEffect(() => {
    getRolesAsync();
  }, [size, page]);

  const getRolesAsync = async () => {
    const res = await role.getRolesAsync(size, page, keyword);

    setRoles(res.data.data);
    setTotalRecords(res.data.totalRecords);
  };

  const handlerOnSearchAsync = async () => {
    await getRolesAsync();
  };

  const clearCriteria = () => {
    setKeyword('');
    (async () => {
      const res = await role.getRolesAsync(size, page);

      setRoles(res.data.data);
      setTotalRecords(res.data.totalRecords);
    })();
  };

  return (
    <div className="m01">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">กำหนดสิทธิ์</h4>
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={() => navigate('detail')}
        >
          <FaPlus />เพิ่มสิทธิ์
        </Button>
      </div>
      <hr />
      <Row className="criteria">
        <Col lg={8} xl={6} className="d-flex gap-2">
          <Input label="ค้นหา" value={keyword} onChange={setKeyword} placeholder="รหัส/ชื่อ สิทธิ์" />
          <div className="d-flex gap-2 button-inline">
            <Button variant="primary" className="d-flex align-items-center gap-2" onClick={handlerOnSearchAsync}>
              <FaSearch />ค้นหา
            </Button>
            <Button variant="outline-primary" className="d-flex align-items-center gap-2" onClick={clearCriteria}>
              <FaEraser />ล้าง
            </Button>
          </div>
        </Col>
      </Row>
      <Table className="mt-4" total={totalRecords} onChange={(size, page) => (setSize(size), setPage(page))}>
        <thead>
          <tr>
            <th style={{ minWidth: 80 }}>ลำดับ</th>
            <th style={{ minWidth: 200 }}>รหัสสิทธิ์</th>
            <th style={{ minWidth: 150 }}>ชื่อสิทธิ์</th>
            <th style={{ minWidth: 50 }} />
          </tr>
        </thead>
        <tbody>
          {roles.map((r, i) => (
            <tr key={r.id}>
              <td className="text-center">{calculateRowNumber(i, page, size)}</td>
              <td className="text-center">{r.code}</td>
              <td className="text-wrap">{r.name}</td>
              <td className="text-end">
                <Button variant="outline-primary" className="gap-2" onClick={() => navigate(`detail/${r.id}`)}>
                  <FaEdit className="me-2 mb-1" />แก้ไข
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
