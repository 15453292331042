import {
  Breadcrumb as BreadcrumbBT,
  Button,
} from 'react-bootstrap';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

interface Data {
  routerName: string;
  label: string;
}

interface Props {
  data: Data[]
}

export function Breadcrumb(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const linkTo = (linkName: string) => {
    linkName && navigate(linkName);
  };

  return (
    <BreadcrumbBT>
      {props.data.map((data, index) => (
        <Button
          onClick={() => linkTo(data.routerName)}
          className={`breadcrumb-item btn-link text-muted px-1 ${data.routerName === location.pathname ? '' : 'text-decoration-none'}`}
          key={index}
        >
          {data.label}
        </Button>
      ))}
    </BreadcrumbBT>
  );
}
