import { ProcessStatus } from 'components';
import Title from 'components/Controls/Title';
import { HistoryModal } from 'components/Modal';
import Cookie from 'cookie-universal';
import {
  AppointmentDetailModel,
  CreateApproverAppointPreProcurements,
  CreateCommitteeMiddlePrices,
  CreateCommitteeTors,
  createDutyCommitteeMiddlePrices,
  CreateDutyCommitteeTors,
  CreatePP0201Model,
  UpdateAppointmentDetailModel,
} from 'models/P/PP0201Model';
import { useState } from 'react';
import {
  Button,
  Card,
  Form,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaHistory,
  FaSave,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router';
import PP0201Services from 'services/PP/PP0201Services';
import { HttpStatusCode } from 'utils';
import toast from 'utils/toast';
import { useForm } from 'utils/useForm';
import { dataHistory } from '../data';

interface Props {
  setForm: (form: AppointmentDetailModel) => void;
  appointmentData: AppointmentDetailModel;
  onClickNext: () => void;
  onClickBack: () => void;
  isDisabled?: boolean;
}

interface FormInput {
  committeeTermsOfReferences: [];
}

function Step5({ onClickNext, onClickBack, appointmentData, setForm, isDisabled = false }: Props) {
  const [show, setShow] = useState(false);
  const cookies = Cookie();
  const userfullName = cookies.get('fullName') as string;
  const navigate = useNavigate();

  const { handleSubmit, handleChange, data: formData, errors, initFormData } = useForm<FormInput>({
    initialValues: {
      committeeTermsOfReferences: [],
    },
    validations: {},
    onSubmit: async () => {
      if (!appointmentData.departmentId || appointmentData.departmentId === '') {
        toast.error('กรุณากรอกข้อมูลฝ่าย/สำนัก');
        return;
      }

      if (!appointmentData.planName || appointmentData.planName === '') {
        toast.error('กรุณากรอกข้อมูลชื่อแผน');
        return;
      }

      if (!appointmentData.supplyMethodType || appointmentData.supplyMethodType === '') {
        toast.error('กรุณากรอกข้อมูลวิธีจัดหา');
        return;
      }

      if (!appointmentData.supplyMethod || appointmentData.supplyMethod === '') {
        toast.error('กรุณากรอกข้อมูลวิธีจัดหา');
        return;
      }

      if (!appointmentData.memorandumDate) {
        toast.error('กรุณากรอกข้อมูลวันที่เอกสารบันทึกข้อความแต่งตั้ง');
        return;
      }

      if (!appointmentData.byOrderFrom || appointmentData.byOrderFrom === '') {
        toast.error('กรุณากรอกข้อมูลตามคำสั่ง');
        return;
      }

      if (!appointmentData.subject || appointmentData.subject === '') {
        toast.error('กรุณากรอกข้อมูลเรื่อง');
        return;
      }

      if (!appointmentData.planBudget || appointmentData.planBudget <= 0) {
        toast.error('กรุณากรอกข้อมูลงบประมาณให้ครบถ้วน');
        return;
      }

      if ((!appointmentData.documents || appointmentData.documents.length === 0)
        && (!appointmentData.files || appointmentData.files.length === 0)) {
        toast.error('กรุณาแนบเอกสาร');
        return;
      }

      if (!appointmentData.committeeTors || appointmentData.committeeTors.length === 0) {
        toast.error('กรุณาเลือกคณะกรรมการ');
        return;
      }

      if (!appointmentData.dutyCommitteeTors || appointmentData.dutyCommitteeTors.length === 0) {
        toast.error('กรุณาเลือกอำนาจเจ้าหน้าที่');
        return;
      }

      if ((!appointmentData.committeeMiddlePrices || appointmentData.committeeMiddlePrices.length === 0)
        && appointmentData.planBudget >= 100000) {
        toast.error('กรุณาเลือกคณะกรรมการกำหนดราคากลาง');
        return;
      }

      if ((!appointmentData.dutyCommitteeMiddlePrices || appointmentData.dutyCommitteeMiddlePrices.length === 0)
        && appointmentData.planBudget >= 100000) {
        toast.error('กรุณาเลือกอำนาจเจ้าหน้าที่ราคากลาง');
        return;
      }

      if (!appointmentData.approverAppointPreProcurements || appointmentData.approverAppointPreProcurements.length === 0) {
        toast.error('กรุณาเลือกอำนาจอนุมัติ (ผู้ลงนาม)');
        return;
      }

      if (appointmentData && appointmentData.id) {
        const committeeTors: CreateCommitteeTors[] = [];
        const dutyTors: CreateDutyCommitteeTors[] = [];
        const committeeMiddles: CreateCommitteeMiddlePrices[] = [];
        const dutyMiddles: createDutyCommitteeMiddlePrices[] = [];
        const approvers: CreateApproverAppointPreProcurements[] = [];

        appointmentData.committeeTors.forEach((data) => committeeTors.push({ id: data.id, committeeUserId: data.committeeUserId, committeeUserName: data.committeeUserName, positionOnBoardId: data.positionOnBoardId, positionOnBoardName: data.positionOnBoardName, position: data.position }));

        appointmentData.dutyCommitteeTors.forEach((data) => dutyTors.push({ id: data.id, dutyDescription: data.duty }));

        if (Number(appointmentData.planBudget) >= 100000) {
          appointmentData.committeeMiddlePrices.forEach((data) => committeeMiddles.push({ id: data.id, committeeUserId: data.committeeUserId, committeeUserName: data.committeeUserName, positionOnBoardId: data.positionOnBoardId, positionOffBoardName: data.positionOnBoardName }));

          appointmentData.dutyCommitteeMiddlePrices.forEach((data) => dutyMiddles.push({ id: data.id, dutyDescription: data.duty }));
        }

        appointmentData.approverAppointPreProcurements.forEach((data) => approvers.push({ id: data.id, approverUserId: data.approverUserId, approverUserName: data.approveFullName, position: data.position }));

        const updateData: UpdateAppointmentDetailModel = {
          id: appointmentData.id,
          planId: appointmentData.planId,
          departmentId: appointmentData.departmentId,
          budgetYear: appointmentData.budgetYear,
          planName: appointmentData.planName,
          supplyMethod: appointmentData.supplyMethod,
          supplyMethodType: appointmentData.supplyMethodType,
          supplyMethodSpecialType: appointmentData.supplyMethodSpecialType,
          expectingProcurementAt: appointmentData.expectingProcurementAt,
          planBudget: appointmentData.planBudget,
          financialAmount: appointmentData.planBudget,
          byOrderFrom: appointmentData.byOrderFrom,
          subject: appointmentData.subject,
          updateByUserFullName: userfullName,
          updateCommitteeTors: committeeTors,
          updateDutyCommitteeTors: dutyTors,
          updateCommitteeMiddlePrices: committeeMiddles,
          updateDutyCommitteeMiddlePrices: dutyMiddles,
          updateApproverAppointPreProcurements: approvers,
          files: appointmentData.files,
          status: appointmentData.status,
        };

        const updResponse = await PP0201Services.updateAppointmentAsync(updateData);

        if (updResponse.status === HttpStatusCode.OK) {
          if (appointmentData.documentDeletes && appointmentData.documentDeletes.length > 0) {
            const { status } = await PP0201Services.deleteAppointmentDocumentAsync(appointmentData.id, appointmentData.documentDeletes);

            if (status !== HttpStatusCode.NO_CONTENT && status !== HttpStatusCode.OK) {
              toast.error('ลบไฟล์ไม่สำเร็จ');

              return;
            }
          }

          toast.success('บันทึกข้อมูลสำเร็จ');
          const response = await PP0201Services.getPP0201ByIdAsync(appointmentData.id);
          if (response.status === HttpStatusCode.OK) {
            if (appointmentData.files) {
              appointmentData.files = [];
            }
            setForm(response.data);
          }
          navigate(`/pp/pp0201/detail/${appointmentData.id}`);
        } else {
          toast.error('บันทึกข้อมูลไม่สำเร็จ');
        }
      } else {
        const committeeTors: CreateCommitteeTors[] = [];
        const dutyTors: CreateDutyCommitteeTors[] = [];
        const committeeMiddles: CreateCommitteeMiddlePrices[] = [];
        const dutyMiddles: createDutyCommitteeMiddlePrices[] = [];
        const approvers: CreateApproverAppointPreProcurements[] = [];

        appointmentData.committeeTors.forEach((data) => committeeTors.push({ id: undefined, committeeUserId: data.committeeUserId, committeeUserName: data.committeeUserName, positionOnBoardId: data.positionOnBoardId, positionOnBoardName: data.positionOnBoardName, position: data.position }));

        appointmentData.dutyCommitteeTors.forEach((data) => dutyTors.push({ id: undefined, dutyDescription: data.duty }));

        if (Number(appointmentData.planBudget) >= 100000) {
          appointmentData.committeeMiddlePrices.forEach((data) => committeeMiddles.push({ id: undefined, committeeUserId: data.committeeUserId, committeeUserName: data.committeeUserName, positionOnBoardId: data.positionOnBoardId, positionOffBoardName: data.positionOnBoardName }));

          appointmentData.dutyCommitteeMiddlePrices.forEach((data) => dutyMiddles.push({ id: undefined, dutyDescription: data.duty }));
        }

        appointmentData.approverAppointPreProcurements.forEach((data) => approvers.push({ id: undefined, approverUserId: data.approverUserId, approverUserName: data.approveFullName, position: data.position }));

        const createData: CreatePP0201Model = {
          planId: appointmentData.planId,
          departmentId: appointmentData.departmentId,
          budgetYear: appointmentData.budgetYear,
          planName: appointmentData.planName,
          supplyMethod: appointmentData.supplyMethod,
          supplyMethodType: appointmentData.supplyMethodType,
          supplyMethodSpecialType: appointmentData.supplyMethodSpecialType,
          planBudget: appointmentData.planBudget,
          expectingProcurementAt: appointmentData.expectingProcurementAt,
          memorandumDate: appointmentData.memorandumDate,
          financialAmount: appointmentData.planBudget,
          byOrderFrom: appointmentData.byOrderFrom,
          subject: appointmentData.subject,
          createCommitteeTors: committeeTors,
          createDutyCommitteeTors: dutyTors,
          createCommitteeMiddlePrices: committeeMiddles,
          createDutyCommitteeMiddlePrices: dutyMiddles,
          createApproverAppointPreProcurements: approvers,
          files: appointmentData.files,
          createdByUserFullName: userfullName,
        };

        const createResponse = await PP0201Services.createAppointmentAsync(createData);

        if (createResponse.status === HttpStatusCode.CREATED) {
          toast.success('บันทึกข้อมูลสำเร็จ');
          const newId: string = createResponse.data.id;
          const response = await PP0201Services.getPP0201ByIdAsync(newId);
          if (response.status === HttpStatusCode.OK) {
            if (appointmentData.files) {
              appointmentData.files = [];
            }
            setForm(response.data);
          }
          navigate(`/pp/pp0201/detail/${response.data.id}`);
        } else {
          toast.error('บันทึกข้อมูลไม่สำเร็จ');
        }
      }
    },
  });

  const onClickSubmit = async () => {
    const response = await PP0201Services.submitPendingAppointmentAsync(appointmentData.id);

    if (response.status === HttpStatusCode.OK) {
      toast.success('ส่งอนุมัติสำเร็จ');
      const response = await PP0201Services.getPP0201ByIdAsync(appointmentData.id);
      if (response.status === HttpStatusCode.OK) {
        setForm(response.data);
      }
      navigate(`/pp/pp0201/detail/${appointmentData.id}`);
    } else {
      toast.error('ส่งอนุมัติไม่สำเร็จ');
    }
  };

  return (
    <div className='document'>
      <Form onSubmit={handleSubmit}
        id='updateForm5'>
        <Card className='mt-3'>
          <Card.Body>
            <div className='d-flex justify-content-between my-3'>
              <Title text='ตัวอย่างเอกสารบันทึกข้อความแต่งตั้ง'
                className='fs-5 text-primary' />
              <Button
                className='px-3'
                onClick={() => setShow(true)}
                variant='outline-primary'
              >
                <FaHistory className='me-3' />ประวัติการแก้ไข
              </Button>
            </div>
          </Card.Body>
        </Card>
        <div className='d-flex justify-content-center gap-3 pt-3'>
          <Button
            onClick={onClickBack}
            className='me-2 px-3'
            variant='outline-primary'
          >
            <MdArrowBack className='me-2 pb-1 fs-5' />
            ย้อนกลับ
          </Button>
          {
            (appointmentData && (appointmentData.status === undefined || appointmentData.status === ProcessStatus.DRAFT || appointmentData.status === ProcessStatus.REJECTED)) && (
              <Button
                type='submit'
                form='updateForm5'
                variant={appointmentData.status === undefined ? 'primary' : 'outline-primary'}
                className='me-2 px-3'
              >
                <FaSave className='ms-2 pb-1 fs-5' />
                บันทึก
              </Button>
            )
          }
          {
            (appointmentData && appointmentData.id && (appointmentData.status === ProcessStatus.DRAFT || appointmentData.status === ProcessStatus.REJECTED)) && (
              <Button
                onClick={onClickSubmit}
                className='me-2 px-3'
              >
                <FaAlignLeft className='me-2 pb-1 fs-5' />
                ส่งอนุมัติ
              </Button>
            )
          }
        </div>
      </Form>
      <HistoryModal
        show={show}
        onHide={() => setShow(!show)}
        data={dataHistory}
      />
    </div>
  );
}

export default Step5;
