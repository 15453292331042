import {
  CriteriaPlanProcurementList,
  PlanApproveList,
  PlanProcurement,
} from 'models';
import http from 'utils/axios';
import { PlanStatus as PlanStatusCons } from 'utils/constants/PlanEnum';

const qs = require('qs');

const createAsync = async (data: PlanProcurement) => {
  const formData = new FormData();
  formData.append('type', data.type);
  formData.append('departmentId', data.departmentId);
  formData.append('supplyMethod', data.supplyMethod);
  formData.append('supplyMethodType', data.supplyMethodType);
  formData.append('supplyMethodSpecialType', data.supplyMethodSpecialType);
  formData.append('budgetYear', data.budgetYear.toString());
  formData.append('name', data.name);
  formData.append('budget', data.budget.toString());
  formData.append('expectingProcurementAt', data.expectingProcurementAt.toISOString());
  formData.append('remark', data.remark || '');
  formData.append('tel', data.tel || '');
  formData.append('status', data.status);

  Array.from([
    ...(data.departmentDirectorAgree || []),
    ...(data.jorporDirectorBeInformed || []),
    ...(data.jorporDirectorAssign || []),
    ...(data.sectionHeadJorpor || []),
    ...(data.assistantDirectorJorpor || []),
    ...(data.directorJorpor || []),
    ...(data.assistantMd || []),
    ...(data.deputyMd || []),
    ...(data.md || [])
  ]).forEach((d, i) => {
    formData.append(`authority[${i}][sectionCode]`, d.sectionCode);
    formData.append(`authority[${i}][userId]`, d.userId);
    formData.append(`authority[${i}][fullName]`, d.fullName);
    formData.append(`authority[${i}][departmentName]`, d.departmentName);
    formData.append(`authority[${i}][positionName]`, d.positionName);
    formData.append(`authority[${i}][status]`, d.status);
    formData.append(`authority[${i}][isAvailable]`, d.isAvailable !== undefined ? d.isAvailable.toString() : '');
    formData.append(`authority[${i}][availableRemark]`, d.isAvailableRemark || '');
    formData.append(`authority[${i}][acceptDate]`, d.acceptDate ? d.acceptDate.toISOString() : '');
    formData.append(`authority[${i}][acceptRemark]`, d.acceptRemark || '');
    formData.append(`authority[${i}][rejectDate]`, d.rejectDate ? d.rejectDate.toISOString() : '');
    formData.append(`authority[${i}][rejectRemark]`, d.rejectRemark || '');
    formData.append(`authority[${i}][sequence]`, d.sequence.toString());
    formData.append(`authority[${i}][delegateUserId]`, d.delegateUserId || '');
    formData.append(`authority[${i}][delegateEmployeeCode]`, d.delegateEmployeeCode || '');
    formData.append(`authority[${i}][delegateFullName]`, d.delegateFullName || '');
    formData.append(`authority[${i}][delegateDepartmentName]`, d.delegateDepartmentName || '');
    formData.append(`authority[${i}][delegatePositionName]`, d.delegatePositionName || '');
  });

  data.planProcurementCommerceInformation?.forEach((d, i) => {
    if (d.id) {
      formData.append(`PlanProcurementCommerceInformation[${i}][Id]`, d.id);
    }

    formData.append(`PlanProcurementCommerceInformation[${i}][Sequence]`, d.sequence.toString());
    formData.append(`PlanProcurementCommerceInformation[${i}][PlanProcurementCommerceInformationId]`, d.planProcurementCommerceInformationId ?? '');
    formData.append(`PlanProcurementCommerceInformation[${i}][Result]`, d.result === true ? 'true' : 'false');
  });

  data.documents?.forEach((d) => {
    if (d.file) {
      formData.append('documents', d.file);
    }
  });

  return http.post('/planprocurement', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const updateAsync = async (data: PlanProcurement, resetStatus?: boolean) => {
  data.authority = [];
  if (data.departmentDirectorAgree && data.departmentDirectorAgree.length) {
    data.departmentDirectorAgree.forEach((item) =>
      data.authority.push({
        id: item.id || null,
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        delegateUserId: item.delegateUserId,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegateDepartmentName: item.delegateDepartmentName,
        delegatePositionName: item.delegatePositionName,
      })
    );
  }

  if (data.jorporDirectorBeInformed && data.jorporDirectorBeInformed.length) {
    data.jorporDirectorBeInformed.forEach((item) =>
      data.authority.push({
        id: item.id || null,
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        delegateUserId: item.delegateUserId,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegateDepartmentName: item.delegateDepartmentName,
        delegatePositionName: item.delegatePositionName,
      })
    );
  }

  if (data.jorporDirectorAssign && data.jorporDirectorAssign.length) {
    data.jorporDirectorAssign.forEach((item) =>
      data.authority.push({
        id: item.id || null,
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        delegateUserId: item.delegateUserId,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegateDepartmentName: item.delegateDepartmentName,
        delegatePositionName: item.delegatePositionName,
        level: item.level,
      })
    );
  }

  if (data.sectionHeadJorpor && data.sectionHeadJorpor.length) {
    data.sectionHeadJorpor.forEach((item) =>
      data.authority.push({
        id: item.id || null,
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        delegateUserId: item.delegateUserId,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegateDepartmentName: item.delegateDepartmentName,
        delegatePositionName: item.delegatePositionName,
      })
    );
  }

  if (data.assistantDirectorJorpor && data.assistantDirectorJorpor.length) {
    data.assistantDirectorJorpor.forEach((item) =>
      data.authority.push({
        id: item.id || null,
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        delegateUserId: item.delegateUserId,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegateDepartmentName: item.delegateDepartmentName,
        delegatePositionName: item.delegatePositionName,
      })
    );
  }

  if (data.directorJorpor && data.directorJorpor.length) {
    data.directorJorpor.forEach((item) =>
      data.authority.push({
        id: item.id || null,
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        delegateUserId: item.delegateUserId,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegateDepartmentName: item.delegateDepartmentName,
        delegatePositionName: item.delegatePositionName,
      })
    );
  }

  if (data.assistantMd && data.assistantMd.length) {
    data.assistantMd.forEach((item) =>
      data.authority.push({
        id: item.id || null,
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        delegateUserId: item.delegateUserId,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegateDepartmentName: item.delegateDepartmentName,
        delegatePositionName: item.delegatePositionName,
      })
    );
  }

  if (data.deputyMd && data.deputyMd.length) {
    data.deputyMd.forEach((item) =>
      data.authority.push({
        id: item.id || null,
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        delegateUserId: item.delegateUserId,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegateDepartmentName: item.delegateDepartmentName,
        delegatePositionName: item.delegatePositionName,
      })
    );
  }

  if (data.md && data.md.length) {
    data.md.forEach((item) =>
      data.authority.push({
        id: item.id || null,
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        delegateUserId: item.delegateUserId,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegateDepartmentName: item.delegateDepartmentName,
        delegatePositionName: item.delegatePositionName,
      })
    );
  }

  if (data.approvers && data.approvers.length) {
    data.approvers.forEach((item) =>
      data.authority.push({
        id: item.id || null,
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate!,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate!,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        sectionType: item.sectionType,
        sectionSequence: item.sectionSequence,
        delegateUserId: item.delegateUserId,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegateFullName: item.delegateFullName,
        delegateDepartmentName: item.delegateDepartmentName,
        delegatePositionName: item.delegatePositionName,
      })
    );
  }

  return await http.put(
    `/planprocurement/${data.id}`,
    {
      ...data,
      resetStatus
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

const getDetailAsync = async (id: string) => await http.get(`/planprocurement/${id}`);

const getUsersAsync = async (size: number, page: number, name?: string, departmentId?: string, positionId?: string) =>
  await http.get('/account', {
    params: {
      page,
      size,
      name,
      departmentId,
      positionId,
      isActive: true
    }
  });

const getPositionsAsync = async () => await http.get('position/listselection');

const getDepartmentsAsync = async () => await http.get('/department/list');

const getPlanProcurementListAsync = async (page: number, size: number, type?: string, data?: CriteriaPlanProcurementList) => {
  const params = {
    page,
    size,
    type,
    ...data
  };
  return await http.get('/planprocurement/', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getReportExcelAsync = async (type: string, criteria: CriteriaPlanProcurementList) => {
  const params = {
    type,
    ...criteria
  };

  return await http.get('/planprocurement/report', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
    responseType: 'blob'
  });
};

const getEgpReportExcelAsync = async (type: string, criteria: CriteriaPlanProcurementList) => {
  const params = {
    type,
    ...criteria
  };

  return await http.get('/planprocurement/egpReport', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
    responseType: 'blob'
  });
};

const approvePlanListAsync = async (planLists: string[]) => {
  const body: PlanApproveList = {
    planProcurementIds: planLists
  };

  return await http.post('/planprocurement/sendapprove', body);
};

const removePlanAsync = async (id: string) => await http.delete(`/planprocurement/${id}`);

const getModalListAsync = async (type: string, page: number, size: number, criteria?: CriteriaPlanProcurementList) => {
  const params = {
    type,
    page,
    size,
    ...criteria
  };

  return await http.get('/planprocurement/getplanapprove', { params });
};

const rejectAsync = async (planId: string, id: string, planStatus: string, remark?: string) =>
  await http.put(`/planprocurement/reject/${planId}`, {
    id,
    remark: remark || '',
    planStatus
  });

const approveAsync = async (planId: string, id: string, remark?: string) =>
  await http.put(`/planprocurement/approve/${planId}`, {
    id,
    remark: remark || ''
  });

const updateStatusAsync = async (id: string, status: string, activityDescription: string) =>
  await http.put(`planprocurement/updatestatus/${id}`, {
    status,
    activityDescription
  });

const uploadFileAsync = async (id: string, file: File) =>
  await http.put(
    `/planprocurement/${id}/uploadfile`,
    {
      file,
      fileName: file.name
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

const removeFileAsync = async (planId: string, id: string) => await http.delete(`/planprocurement/${planId}/deletefile/${id}`);

const getCancelPlanAsync = async (page: number, size: number, criteria?: CriteriaPlanProcurementList) => {
  const params = {
    page,
    size,
    ...criteria
  };

  return await http.get('/cancelplanprocurement', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getChangePlanAsync = async (page: number, size: number, criteria?: CriteriaPlanProcurementList) => {
  const params = {
    page,
    size,
    ...criteria
  };

  return await http.get('/amendplanprocurement', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getPlanChangeStatusCount = async (page: number, size: number, data?: CriteriaPlanProcurementList) => {
  const params = {
    page,
    size,
    ...data
  };

  return await http.get('/amendplanprocurement/count-status', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getPlanCancelStatusCount = async (page: number, size: number, data?: CriteriaPlanProcurementList) => {
  const params = {
    page,
    size,
    ...data
  };

  return await http.get('/cancelplanprocurement/count-status', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getPlanStatusCount = async (page: number, size: number, type?: string, data?: CriteriaPlanProcurementList) => {
  const params = {
    page,
    size,
    type,
    ...data
  };

  return await http.get('/planprocurement/count-status', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const changeAsync = async (data: PlanProcurement, changeRemark: string) => {
  data.authority = [];

  // if (data.departmentDirectorAgree && data.departmentDirectorAgree.length) {
  //   data.departmentDirectorAgree.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.jorporDirectorBeInformed && data.jorporDirectorBeInformed.length) {
  //   data.jorporDirectorBeInformed.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }
  // if (data.jorporDirectorAssign && data.jorporDirectorAssign.length) {
  //   data.jorporDirectorAssign.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //       level: item.level,
  //     })
  //   );
  // }

  // if (data.sectionHeadJorpor && data.sectionHeadJorpor.length) {
  //   data.sectionHeadJorpor.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.assistantDirectorJorpor && data.assistantDirectorJorpor.length) {
  //   data.assistantDirectorJorpor.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.directorJorpor && data.directorJorpor.length) {
  //   data.directorJorpor.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.assistantMd && data.assistantMd.length) {
  //   data.assistantMd.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.deputyMd && data.deputyMd.length) {
  //   data.deputyMd.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.md && data.md.length) {
  //   data.md.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  data.status = PlanStatusCons.DraftPlan;

  return await http.post(
    '/amendplanprocurement',
    {
      ...data,
      changeRemark
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

const cancelAsync = async (data: PlanProcurement, cancelRemark: string) => {
  data.authority = [];

  // if (data.departmentDirectorAgree && data.departmentDirectorAgree.length) {
  //   data.departmentDirectorAgree.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.jorporDirectorBeInformed && data.jorporDirectorBeInformed.length) {
  //   data.jorporDirectorBeInformed.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.jorporDirectorAssign && data.jorporDirectorAssign.length) {
  //   data.jorporDirectorAssign.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //       level: item.level,
  //     })
  //   );
  // }

  // if (data.sectionHeadJorpor && data.sectionHeadJorpor.length) {
  //   data.sectionHeadJorpor.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.assistantDirectorJorpor && data.assistantDirectorJorpor.length) {
  //   data.assistantDirectorJorpor.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.directorJorpor && data.directorJorpor.length) {
  //   data.directorJorpor.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.assistantMd && data.assistantMd.length) {
  //   data.assistantMd.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.deputyMd && data.deputyMd.length) {
  //   data.deputyMd.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  // if (data.md && data.md.length) {
  //   data.md.forEach((item) =>
  //     data.authority.push({
  //       id: item.id || null,
  //       sectionCode: item.sectionCode,
  //       userId: item.userId,
  //       fullName: item.fullName,
  //       departmentName: item.departmentName,
  //       positionName: item.positionName,
  //       status: item.status,
  //       isAvailable: item.isAvailable,
  //       availableRemark: item.isAvailableRemark,
  //       acceptDate: item.acceptDate,
  //       acceptRemark: item.acceptRemark,
  //       rejectDate: item.rejectDate,
  //       rejectRemark: item.rejectRemark,
  //       sequence: item.sequence,
  //       delegateUserId: item.delegateUserId,
  //       delegateEmployeeCode: item.delegateEmployeeCode,
  //       delegateFullName: item.delegateFullName,
  //       delegateDepartmentName: item.delegateDepartmentName,
  //       delegatePositionName: item.delegatePositionName,
  //     })
  //   );
  // }

  data.status = PlanStatusCons.DraftPlan;

  return await http.post(`/cancelplanprocurement/${data.id}`, {
    ...data,
    cancelRemark
  });
};

const downloadFileAsync = async (planId: string, documentId: string) =>
  await http.get(`/planprocurement/${planId}/documents/${documentId}`, {
    responseType: 'blob'
  });

export default {
  createAsync,
  updateAsync,
  getUsersAsync,
  getPositionsAsync,
  getDepartmentsAsync,
  getDetailAsync,
  getPlanProcurementListAsync,
  getReportExcelAsync,
  getEgpReportExcelAsync,
  approvePlanListAsync,
  removePlanAsync,
  getModalListAsync,
  rejectAsync,
  approveAsync,
  getCancelPlanAsync,
  getChangePlanAsync,
  updateStatusAsync,
  uploadFileAsync,
  removeFileAsync,
  getPlanStatusCount,
  changeAsync,
  cancelAsync,
  downloadFileAsync,
  getPlanChangeStatusCount,
  getPlanCancelStatusCount
};
