import Cookie from 'cookie-universal';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import CHEditorRemark from './CHEditorRemark';

const cookies = Cookie();
const apiUrl = process.env.REACT_APP_API_URL;
const docsService = process.env.REACT_APP_DOCS_URL;

interface IProps {
  docId: string;
  docName: string;
  readonly: boolean;
  width?: string;
  height?: string;
  showRemark?: boolean;
}

export interface CollaboraRef {
  clickSave: () => void;
}

const hostName = window.location.origin;

const Collabora = forwardRef<CollaboraRef, IProps>((props, ref) => {
  const {
    width = '100%',
    height = '600',
  } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [eventSource, setEventSource] = useState<MessageEventSource | null>(null);

  const onSetDocumentTor = useCallback((docId: string) => {
    if (formRef.current) {
      formRef.current.action = `${docsService}/cool.html?WOPISrc=${apiUrl}/wopi/files/${docId}?readOnly=${props.readonly}`;
      formRef.current.submit();
    }
  }, [formRef, props.readonly]);

  const post = useCallback((msg: any) => {
    const json = JSON.stringify(msg);

    eventSource?.postMessage(json, {
      targetOrigin: hostName,
    });
  }, [eventSource]);

  const save = useCallback(() => {
    post({
      MessageId: 'Action_Save',
      Values: { Notify: true },
    });
  }, [post]);

  function receiveMessage(event: MessageEvent<any>) {
    setEventSource(event.source);

    if (event.origin !== hostName) {
      return;
    }

    if (typeof event.data !== 'string') {
      return;
    }

    const msg = JSON.parse(event.data);

    if (!msg) {
      return;
    }

    switch (msg.MessageId) {
      case 'App_LoadingStatus':
        if (msg.Values && msg.Values.Status === 'Document_Loaded') {
          post({ MessageId: 'Host_PostmessageReady' });
        }

        break;
      case 'Doc_ModifiedStatus':
        if (msg.Values && msg.Values.Modified === true) {
          save();
        }

        break;
      default:
        break;
    }
  }

  window.addEventListener('message', receiveMessage, false);

  useImperativeHandle(ref, () => ({
    clickSave() {
      save();
    },
  }));

  useEffect(() => {
    if (props.docId) {
      onSetDocumentTor(props.docId);
    }
  }, [onSetDocumentTor, props.docId]);

  return (
    <>
      <form
        ref={formRef}
        action=''
        target={props.docName}
        encType='multipart/form-data'
        method='post'
        name='collaboraForm'
      >
        <input name='access_token'
          value={cookies.get('access-token')}
          type='hidden' />
        <input name='ui_defaults'
          value='UIMode=compact;TextSidebar=true'
          type='hidden' />
      </form>
      {
        (props.showRemark || !props.readonly) &&
        <CHEditorRemark></CHEditorRemark >
      }
      <iframe
        title={props.docName}
        ref={iframeRef}
        id={props.docId}
        name={props.docName}
        height={height}
        width={width}
      />
    </>
  );
});

export default Collabora;
