import {
  Card,
  Input,
  InputTextArea,
  ListFile,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step3({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลร่างขอบเขตของงาน (TOR)" className="fs-5 text-primary" />
        <Row className="mt-3">
          <Col sm={12}>
            <Input label="เรื่อง" disabled />
          </Col>
          <Col sm={12}>
            <InputTextArea label="ความเป็นมา/เหตุผลและความจำเป็น" disabled />
          </Col>
        </Row>
        <p>วัตถุประสงค์</p>
        <Table>
          <thead>
            <tr>
              <th style={{ minWidth: 10 }}>ลำดับ</th>
              <th style={{ minWidth: 350 }}>รายละเอียด</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 2 }).map((_, index) => (
              <tr key={index}>
                <td className="text-center">{index}</td>
                <td className="text-start"><Input disabled value="ยกระดับองค์กรให้เติบโตอย่างยั่งยืน ยุทธศาสตร์การยกระดับความรับผิดชอบต่อสังคมและสิ่งแวดล้อมและภาพลักษณ์ องค์กร" /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className="mt-3">
        <Title text="รายการของพัสดุ" className="fs-5 text-primary" />
        <p className="mt-3">รายการของพัสดุที่จะซื้อ</p>
        <Table>
          <thead>
            <tr>
              <th style={{ minWidth: 10 }}>ลำดับ</th>
              <th style={{ minWidth: 350 }}>รายละเอียด</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 2 }).map((_, index) => (
              <tr key={index}>
                <td className="text-center">{index}</td>
                <td className="text-start"><Input disabled value="ยกระดับองค์กรให้เติบโตอย่างยั่งยืน ยุทธศาสตร์การยกระดับความรับผิดชอบต่อสังคมและสิ่งแวดล้อมและภาพลักษณ์ องค์กร" /></td>
              </tr>
            ))}
          </tbody>
        </Table>
        <p className="mt-3">เอกสารรายละเอียดลักษณะเฉพาะ</p>
        <Row className="justify-content-center">
          <Col sm={12} lg={6}>
            <ListFile InameFile="อัตราค่าใช้จ่ายอื่นๆ ปี 65/2.doc" IsizeFile="123KB" />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="รายละเอียดของงาน" className="fs-5 text-primary" />
        <Row className="mt-3">
          <Col sm={12}>
            <InputTextArea label="เรื่อง" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="ระยะเวลาดำเนิการ/ส่งมอบงาน" className="fs-5 text-primary" />
        <Table className="mt-3">
          <thead>
            <tr>
              <th style={{ minWidth: 10 }}>งวดที่</th>
              <th style={{ minWidth: 350 }}>รายละเอียด</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 2 }).map((_, index) => (
              <tr key={index}>
                <td className="text-center">{index}</td>
                <td className="text-start"><Input disabled value="ยกระดับองค์กรให้เติบโตอย่างยั่งยืน ยุทธศาสตร์การยกระดับความรับผิดชอบต่อสังคมและสิ่งแวดล้อมและภาพลักษณ์ องค์กร" /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className="mt-3">
        <Title text="เงื่อนไขการชำระเงิน" className="fs-5 text-primary" />
        <Table className="mt-3">
          <thead>
            <tr>
              <th style={{ width: 80 }}>งวดที่</th>
              <th style={{ width: 180 }}>จำนวน ( % )</th>
              <th style={{ minWidth: 350 }}>รายละเอียด</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 2 }).map((_, index) => (
              <tr key={index}>
                <td className="text-center">{index}</td>
                <td className="text-center"><Input disabled value="10" textCenter /></td>
                <td className="text-start"><Input disabled value="ยกระดับองค์กรให้เติบโตอย่างยั่งยืน ยุทธศาสตร์การยกระดับความรับผิดชอบต่อสังคมและสิ่งแวดล้อมและภาพลักษณ์ องค์กร" /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className="mt-3">
        <Title text="เงื่อนไขการชำระเงิน" className="fs-5 text-primary" />
        <Row className="justify-content-center mt-3">
          <Col sm={12} lg={6}>
            <ListFile InameFile="อัตราค่าใช้จ่ายอื่นๆ ปี 65/2.doc" IsizeFile="123KB" />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default Step3;
