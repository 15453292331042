import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  ButtonCustom,
  Card,
  Input,
  InputTextArea,
  Selector,
  Table,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import {
  MaintenanceInformationModel,
  ContractAgreementModel,
  ContractAgreementWorkPhaseModel,
  DeliveryModel,
  UpdateWorkPhaseDetailModel,
  UpdateWorkPhaseStep2Model,
} from '../../../../../../models/CM/CM02Models';
import CM02Service from '../../../../../../services/CM/CM02Service';
import {
  fullDate,
  HttpStatusCode,
} from '../../../../../../utils';
import toast from '../../../../../../utils/toast';
import { EditDeliveryModal } from '../../../Modal/EditDeliveryModal';
import { InputNumber } from 'components/Controls/InputNumber';
import { EditMAModal } from './Modal/EditMAModal';

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

interface IDeliverySetupProps {
  contractId: string;
  contractOfferTotalAmountVat: number;
  periodTypeDDL: ItemModel[];
  periodConditionDDL: ItemModel[];
  context: ContractAgreementModel;
  data: DeliveryModel;
  contractAgreementWorkPhaseData: ContractAgreementWorkPhaseModel[];
  onSubmitDeliverWorkPhaseModal: (models: ContractAgreementWorkPhaseModel[]) => void;
  onDeliveryChange: (value: DeliveryModel) => void;
  isDisabled?: boolean;
  onSubmitMaintenanceInformations: (models: MaintenanceInformationModel[]) => void;
}

export function DeliverySetupManager(props: IDeliverySetupProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [contractAgreementWorkPhases, setContractAgreementWorkPhases] = useState<ContractAgreementWorkPhaseModel[]>(props.contractAgreementWorkPhaseData);
  const [maintenanceInformation, setMaintenanceInformation] = useState<MaintenanceInformationModel[]>([]);
  const [showEditDeliveryModal, setShowEditDeliveryModal] = useState(false);
  const [showEditMaModal, setShowEditMaModal] = useState(false);

  const [delivery, setDelivery] = useState<DeliveryModel>(props.data);

  useEffect(() => {
    if (props.data && firstLoad) {
      setDelivery(props.data);
      setFirstLoad(false);
    }
  }, [props.data, firstLoad]);

  useEffect(() => {
    props.onDeliveryChange(delivery);
  }, [delivery]);

  const onTextChange = useCallback((key: string, value: string) => {
    setDelivery(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setDelivery(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    if (props.context.contractAgreementWorkPhases) {
      setContractAgreementWorkPhases(props.context.contractAgreementWorkPhases);
    }

    if (props.context.maintenanceInformation) {
      setMaintenanceInformation(props.context.maintenanceInformation);
    }
  }, [props.context]);

  const onSubmitDeliverWorkPhaseModal = async (models: ContractAgreementWorkPhaseModel[]) => {
    setContractAgreementWorkPhases(models);
    props.onSubmitDeliverWorkPhaseModal(models);
    onSaveWorkPhase(models);
  };

  const onSubmitMaInfoModal = async (models: MaintenanceInformationModel[]) => {
    setMaintenanceInformation(models);
    props.onSubmitMaintenanceInformations(models);
    await onSaveMaInfo(models);
  };

  const onSaveWorkPhase = async (models: ContractAgreementWorkPhaseModel[]) => {
    const updateDetails: UpdateWorkPhaseDetailModel[] = models.map((data) => ({
      id: data.id,
      sequence: data.sequence,
      periodDay: data.periodDay,
      paidPhase: data.paidPhase,
      workPhase: data.workPhase,
      amount: data.amount,
      percent: data.percent,
      detail: data.detail,
      advancePaymentAmount: data.advancePaymentAmount,
      retentionAmount: data.retentionAmount,
      deliveryDueDate: data.deliveryDueDate,
    } as UpdateWorkPhaseDetailModel));

    const updateData: UpdateWorkPhaseStep2Model = {
      WorkPhases: updateDetails,
    };

    const { status } = await CM02Service.updateContractWorkPhaseStep2Async(props.contractId, updateData);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

      return;
    }

    toast.success('บันทึกข้อมูลการส่งมอบสำเร็จ');
  };

  const onSaveMaInfo = async (models: MaintenanceInformationModel[]) => {
    const updateDetails: MaintenanceInformationModel[] = models.map((data) => ({
      id: data.id,
      contractAgreementId: data.contractAgreementId,
      termOfRefId: data.termOfRefId,
      sequence: data.sequence,
      description: data.description,
      quantity: data.quantity,
      periodType: data.periodType,
      totalQuantity: data.totalQuantity,
      totalPeriodType: data.totalPeriodType,
      endDate: data.endDate,
    } as MaintenanceInformationModel));

    const { status } = await CM02Service.updateContractMAInfoStep2Async(props.contractId, updateDetails);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

      return;
    }

    toast.success('บันทึกข้อมูลการส่งมอบสำเร็จ');
  };

  return (
    <>
      <Card className='mt-3'>
        <Title text='การส่งมอบและติดตั้ง'
          className='fs-5 text-primary' />
        {/* <Row className='mt-3'>
          <Col sm={6}
            md={6}>
            <Input
              label='ชื่อคู่ค้า'
              value={delivery?.place}
              onChange={(value) => onTextChange('place', value)}
              disabled={props.isDisabled}
            />
          </Col>
          <Col sm={2}
            md={2}>
            <InputNumber
              label='ระบุภายใน'
              value={delivery?.dueDay}
              onChange={(value) => onNumberChange('dueDay', Number(value))}
              disabled={props.isDisabled}
            />
          </Col>
          <Col sm={2}
            md={2}>
            <Selector
              items={props.periodTypeDDL}
              className='no-label'
              value={delivery?.dueUnit}
              onChange={(value) => onTextChange('dueUnit', value)}
              disabled={props.isDisabled}
            />
          </Col>
        </Row> */}
        <Row>
          <Col sm={6}
            md={6}>
            <Input
              label='โดยผู้ขายจะต้องแจ้งเป็นหนังสือ ยื่นต่อผู้ซื้อ ณ'
              value={delivery?.submissionPlace}
              onChange={(value) => onTextChange('submissionPlace', value)}
              disabled={props.isDisabled} />
          </Col>
          <Col sm={2}
            md={2}>
            <InputNumber
              label='ก่อนส่งมอบไม่น้อยกว่า'
              value={delivery?.submissionBeforeDay}
              onChange={(value) => onNumberChange('submissionBeforeDay', Number(value))}
              disabled={props.isDisabled} />
          </Col>
          <Col sm={2}
            md={2}>
            <Selector
              items={props.periodTypeDDL}
              className='no-label'
              value={delivery?.submissionBeforeUnit}
              onChange={(value) => onTextChange('submissionBeforeUnit', value)}
              disabled={props.isDisabled} />
          </Col>
        </Row>
        <Row>
          <label className='mb-3'>กรณีที่มีการติดตั้ง ผู้ขายต้องจัดเตรียมสถานที่ติดตั้งให้แล้วเสร็จภายใน</label>
          <Col sm={2}
            md={2}>
            <InputNumber
              value={delivery?.installationDay}
              onChange={(value) => onNumberChange('installationDay', Number(value))}
              disabled={props.isDisabled} />
          </Col>
          <Col sm={2}
            md={2}>
            <Selector
              items={props.periodTypeDDL}
              value={delivery?.installationUnit}
              onChange={(value) => onTextChange('installationUnit', value)}
              disabled={props.isDisabled} />
          </Col>
          <Col sm={3}
            md={3}>
            <Selector
              items={props.periodConditionDDL}
              value={delivery?.installationType}
              onChange={(value) => onTextChange('installationType', value)}
              disabled={props.isDisabled} />
          </Col>
        </Row>
        {
          props.context.isMA ? (
            <>
              <Row className='mt-5 d-flex justify-content-between'>
                <Col sm={4}
                  md={4}>
                  <p>งานจ้างบริการบำรุงรักษา</p>
                </Col>
                <Col sm={8}
                  md={8}
                  className='text-end'>
                  <ButtonCustom
                    text='แก้ไขรายการ'
                    onClick={() => setShowEditMaModal(true)}
                    disabled={props.isDisabled} />
                </Col>
              </Row>
              <Row>
                <Table hidePagination>
                  <thead>
                    <tr className='text-center'>
                      <th style={{ width: 20 }}>ลำดับ</th>
                      <th style={{ width: '50%' }}>รายละเอียด</th>
                      <th style={{ width: 200 }}>จำนวน</th>
                      <th style={{ width: 200 }}>ระยะเวลา</th>
                      <th style={{ width: 200 }}>รวมทั้งหมด</th>
                      <th style={{ width: 200 }}>ระยะเวลา</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      maintenanceInformation?.map((data, index) => (
                        <tr
                          key={index}>
                          <td className='text-center'>{data.sequence}</td>
                          <td>{data.description}</td>
                          <td className='text-end'>{data.quantity}</td>
                          <td className='text-center'>
                            {
                              (data.periodType !== undefined && props.periodTypeDDL.filter(p => p.value === data.periodType).length > 0) ? props.periodTypeDDL.filter(p => p.value === data.periodType)[0].label : ''
                            }
                          </td>
                          <td className='text-end'>{data.totalQuantity}</td>
                          <td className='text-center'>
                            {
                              (data.totalPeriodType !== undefined && props.periodTypeDDL.filter(p => p.value === data.totalPeriodType).length > 0) ? props.periodTypeDDL.filter(p => p.value === data.totalPeriodType)[0].label : ''
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Row>
            </>
          ) : (
            <>
              <Row className='mt-5 d-flex justify-content-between'>
                <Col sm={2}
                  md={2}>
                  <p>โดยมีรายละเอียดการส่งมอบ ดังนี้</p>
                </Col>
                <Col sm={2}
                  md={2}
                  className='text-end'>
                  <ButtonCustom
                    text='แก้ไขรายการ'
                    onClick={() => setShowEditDeliveryModal(true)}
                    disabled={props.isDisabled} />
                </Col>
              </Row>
              <Row>
                <Table hidePagination>
                  <thead>
                    <tr className='text-center'>
                      <th style={{ width: 20 }}>ลำดับ</th>
                      <th style={{ width: 20 }}>งวดที่</th>
                      <th style={{ width: 50 }}>ระยะเวลา (วัน)</th>
                      <th style={{ width: 80 }}>วันที่ต้องส่งมอบ</th>
                      <th style={{ width: 80 }}>มูลค่า (%)</th>
                      <th style={{ width: '50%' }}>รายเอียด</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      contractAgreementWorkPhases?.map((data, index) => (
                        <tr className='text-center'
                          key={index}>
                          <td>{index + 1}</td>
                          <td>{data.sequence}</td>
                          <td>{data.periodDay}</td>
                          <td>{data.deliveryDueDate !== undefined ? fullDate(data.deliveryDueDate) : ''}</td>
                          <td>{formatter.format(data.amount)} ({data.percent}%)</td>
                          <td><InputTextArea value={data.detail}
                            disabled />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Row>
            </>
          )
        }
      </Card>

      <EditDeliveryModal
        show={showEditDeliveryModal}
        contractAgreementId={props.contractId}
        onHide={() => setShowEditDeliveryModal(false)}
        contractAmountVat={props.contractOfferTotalAmountVat}
        contractAgreementWorkPhases={contractAgreementWorkPhases}
        onSubmit={onSubmitDeliverWorkPhaseModal}
        isDisabled={props.isDisabled}
      />
      <EditMAModal
        show={showEditMaModal}
        contractAgreementId={props.contractId}
        onHide={() => setShowEditMaModal(false)}
        maintenanceInformation={maintenanceInformation}
        periodTypeDDL={props.periodTypeDDL}
        onSubmit={onSubmitMaInfoModal}
        isDisabled={props.isDisabled}
      />
    </>
  );
}
