const GROUPS = [
    { label: 'TOR', value: 'Tor' },
    { label: 'CM', value: 'Cm' },
    { label: 'CM80', value: 'Cm80' },
    { label: 'CMCHANGE60', value: 'CMChange60' },
    { label: 'CMCHANGE80', value: 'CmChange80' },
    { label: 'CMR', value: 'Cmr' },
    { label: 'PL', value: 'Pl' },
    { label: 'PLEDIT', value: 'Pledit' },
    { label: 'PLCANCEL', value: 'Plcancel' },
    { label: 'AP', value: 'Ap' },
    { label: 'APEDIT', value: 'Apedit' },
    { label: 'APCANCEL', value: 'Apcancel' },
    { label: 'TOREDIT', value: 'Toredit' },
    { label: 'TORCANCEL', value: 'Torcancel' },
    { label: 'MDP', value: 'Mdp' },
    { label: 'JP04', value: 'Jp04' },
    { label: 'JP05', value: 'Jp05' },
    { label: 'JP05Ord', value: 'JP05Ord' },
    { label: 'INV', value: 'Inv' },
    { label: 'JP06', value: 'Jp06' },
    { label: 'WA', value: 'Wa' },
    { label: 'CMINV', value: 'CmInv' },
    { label: 'CMAD', value: 'CmAd' },
    { label: 'CMCOMPLETE', value: 'CmComplete' },
    { label: 'CMSIGN', value: 'CmSign' },
    { label: 'CMCLTR', value: 'CmCltr' },
    { label: 'CMPDPA', value: 'CmPdpa' },
    { label: 'CMPDPA80', value: 'CmPdpa80' },
    { label: 'Element', value: 'Element' },
    { label: 'USERMANUAL', value: 'UserManual' },
];

const GroupReports = [
    { label: 'TOR', value: 'AllBudgetYear' },
    { label: 'CM', value: 'AllQuarter' },
    { label: 'CM80', value: 'All' },
];

export { GROUPS };
