import { Trader } from 'models';
import http from 'utils/axios';

const saveTraderAsync = async (trader: Trader, files?: File[]) => {
  const formData = new FormData();
  formData.append('nationality', trader.nationality);
  formData.append('type', trader.type);
  formData.append('taxpayerIdentificationNo', trader.taxpayerIdentificationNo);
  formData.append('entrepreneurType', trader.entrepreneurType);
  formData.append('establishmentName', trader.establishmentName);
  formData.append('placeName', trader.placeName || '');
  formData.append('roomNo', trader.roomNo || '');
  formData.append('floorNo', trader.floorNo || '');
  formData.append('villageName', trader.villageName || '');
  formData.append('address', trader.address || '');
  formData.append('group', trader.group || '');
  formData.append('alley', trader.alley || '');
  formData.append('road', trader.road || '');
  formData.append('province', trader.province);
  formData.append('district', trader.district);
  formData.append('subDistrict', trader.subDistrict);
  formData.append('postalCode', trader.postalCode);
  formData.append('tel', trader.tel);
  formData.append('fax', trader.fax || '');
  formData.append('email', trader.email);

  if (files && files.length) {
    files.forEach((f) => formData.append('documents', f));
  }

  return await http.post('/trader', formData);
};

const getTraderListAsync = async (
  size: number,
  page: number,
  type?: string,
  entrepreneurType?: string,
  taxpayerIdentificationNo?: string,
  establishmentName?: string,
) => await http.get('/trader', {
  params: {
    type,
    entrepreneurType,
    taxpayerIdentificationNo,
    establishmentName,
    page,
    size,
  },
});

const getTraderAsync = async (id: string) => await http.get(`/trader/${id}`);

const deleteTraderAsync = async (id: string) => await http.delete(`/trader/${id}`);

const updateTraderAsync = async (id: string, trader: Trader, files?: File[]) => {
  const formData = new FormData();
  formData.append('id', trader.id);
  formData.append('nationality', trader.nationality);
  formData.append('type', trader.type);
  formData.append('taxpayerIdentificationNo', trader.taxpayerIdentificationNo);
  formData.append('entrepreneurType', trader.entrepreneurType);
  formData.append('establishmentName', trader.establishmentName);
  formData.append('placeName', trader.placeName || '');
  formData.append('roomNo', trader.roomNo || '');
  formData.append('floorNo', trader.floorNo || '');
  formData.append('villageName', trader.villageName || '');
  formData.append('address', trader.address || '');
  formData.append('group', trader.group || '');
  formData.append('alley', trader.alley || '');
  formData.append('road', trader.road || '');
  formData.append('province', trader.province);
  formData.append('district', trader.district);
  formData.append('subDistrict', trader.subDistrict);
  formData.append('postalCode', trader.postalCode);
  formData.append('tel', trader.tel);
  formData.append('fax', trader.fax || '');
  formData.append('email', trader.email);

  if (files && files.length) {
    files.forEach((f) => formData.append('documents', f));
  }

  return await http.put(`/trader/${id}`, formData);
};

export default {
  saveTraderAsync,
  getTraderListAsync,
  getTraderAsync,
  deleteTraderAsync,
  updateTraderAsync,
};