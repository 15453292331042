import { Table } from 'components';
import Title from 'components/Controls/Title';
import { CommitteeUser } from 'models/PP';
import {
  useEffect,
  useState,
} from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { BsArrowDownCircle } from 'react-icons/bs';
import { FaChevronCircleDown, FaChevronCircleUp, FaPlus, FaTrashAlt } from 'react-icons/fa';

interface Props {
  data: CommitteeUser[];
  visible?: boolean;
  onAssigneeModalShow?: () => void;
  onAssigneeDataChange?: (data: CommitteeUser[]) => void;
  textTitle?: string;
  sectionCode?: string;
  level?: number;
  onRemoveAssigneeDataChange?: (data: any) => void;
  onRemoveAssigneeChange?: (index: number) => void;
}

export function Assignee(props: Props) {
  const [approverCollapsed, setApproverCollapsed] = useState<boolean>(true);
  const [assigneeData, setAssigneeData] = useState<CommitteeUser[]>(props.data);

  useEffect(() => {
    if (props.onAssigneeDataChange) {
      props.onAssigneeDataChange(assigneeData);
    }

  }, [assigneeData]);

  useEffect(() => {
    setApproverCollapsed(false);

    if (props.data
      && props.data.length > 0) {

      setAssigneeData(props.data);
    }

  }, [props.data]);

  const onRemoveUser = (userId: string, level?: number) => {
    const index = assigneeData.findIndex((p) => p.userId === userId);
    assigneeData.splice(index, 1);

    if (props.onRemoveAssigneeDataChange) {
      props.onRemoveAssigneeDataChange(assigneeData)
    }

    if (props.onRemoveAssigneeChange) {
      props.onRemoveAssigneeChange(index)
    }
  };

  return (
    <>
      <Card className='mt-3'>
        {/* Title */}
        <div
          className='d-flex align-items-center gap-3'
        >
          <Title
            text={props.textTitle}
            className='fs-5 text-primary'
          />
          {approverCollapsed
            ? (
              <FaChevronCircleDown
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setApproverCollapsed(false)}
              />
            ) : (
              <FaChevronCircleUp
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setApproverCollapsed(true)}
              />
            )}
        </div>
        <Collapse in={!approverCollapsed}
          className='mt-3'>
          <Container fluid>
            <>
              <div className='d-flex align-items-center justify-content-end'>
                {
                  (props.visible) && <Button
                    className='mb-2'
                    variant='outline-primary'
                    onClick={() => {
                      (props.onAssigneeModalShow ? props.onAssigneeModalShow() : null);
                    }}
                  >
                    <FaPlus className='me-1' />เพิ่มรายชื่อ
                  </Button>
                }

              </div>
              <Table
                hidePagination
                className='mt-3 step-bar'
                notResponse>
                <thead>
                  <tr>
                    <th style={{ width: '5%' }}>ลำดับ</th>
                    <th className='text-start'
                      style={{ width: '30%' }}>ชื่อ-นามสกุล
                    </th>
                    <th className='text-start'
                      style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                    </th>
                    <th className='text-end' style={{ minHeight: 100, width: '25%' }} />
                  </tr>
                </thead>
                <tbody>
                  {assigneeData?.map((data, i) => (
                    <>
                      <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                        key={data.id}>
                        <td className='text-center'>{i + 1}</td>
                        <td>
                          {data.fullName}
                          <p className='m-0 department text-wrap'>
                            {data.positionName}
                          </p>
                        </td>
                        <td className='text-left'>
                          {data.delegateFullName &&
                            <>
                              {data.delegateFullName}
                              <p className='m-0 department text-wrap'>
                                {data.delegatePositionName}
                              </p>
                            </>
                          }
                        </td>
                        <td className='text-end'>
                          {
                            (props.visible) && <Button
                              variant='danger'
                              onClick={() => onRemoveUser(data.userId, data.level)}>
                              <FaTrashAlt />
                            </Button>
                          }
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </>
          </Container>
        </Collapse>
      </Card >
    </>
  );
}