import StepProgress from 'components/StepProgress';
import { useRef } from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Step1 from './step1';
import Step2 from './step2';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

function AddPR0204() {
  const childRef = useRef<ProgressBarRefType>(null);
  const obj = [{
    title: <p>ข้อมูลลงนามใบสั่ง</p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p>ตัวอย่างเอกสาร</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  }

  const clickBack = () => {
    childRef.current?.clickBack();
  }

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/pr/pr0208')
  }

  return (
    <div className='document'>
      <h4 className="mt-2 mb-0 text-primary text-start">ลงนามใบสั่ง</h4>
      <Row>
        <Col xs={12} className='mb-2 mt-3'>
          <StepProgress progressBarData={obj} ref={childRef} />
        </Col>
      </Row>
    </div >
  );
}

export default AddPR0204;
