import { Modal } from 'components';
import { useState } from 'react';
import {
  Button,
  Col,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';


export function ConfirmModal() {
  const [show, setShow] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();

  document.addEventListener('onShowConfirmModal', (data) => {
    const event = data as CustomEvent;

    setTitle(event.detail.title);
    setShow(true);
  });

  const action = (confirm: boolean) => {
    setShow(false);

    document.dispatchEvent(new CustomEvent('onShowConfirmModalClosed', { detail: { result: confirm } }));
  };

  return (
    // <Modal
    //   show={show}
    // >
    //   <Row>
    //     <Col>
    //       <p>{title}</p>
    //     </Col>
    //   </Row>
    //   <Row>
    //     <Col>
    //       <div className="d-flex flex-row gap-2 justify-content-end">
    //         <Button variant="light" type="button" onClick={() => action(false)}>ยกเลิก</Button>
    //         <Button variant="primary" type="button" onClick={() => action(true)}>ตกลง</Button>
    //       </div>
    //     </Col>
    //   </Row>
    // </Modal>
    <Modal
      show={show}
      size='lg'
      onHide={() => action(false)}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>ยืนยัน</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4'>
            {title}
          </ModalBT.Body>
          <ModalBT.Footer>
            <Button variant="light" type="button" onClick={() => action(false)}>ยกเลิก</Button>
            <Button variant="primary" type="button" onClick={() => action(true)}>ตกลง</Button>
          </ModalBT.Footer>
        </>
      )}
    />
  );
}
