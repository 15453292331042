export function showRemoveModalAsync() {
  return new Promise((resolve) => {
    document.dispatchEvent(new CustomEvent('onShowRemoveModal'));

    document.addEventListener('onShowRemoveModalClosed', (event) => {
      document.removeEventListener('onShowRemoveModalClosed', () => { });

      const customEvent = event as CustomEvent;

      resolve(customEvent.detail.result);
    });
  });
}

export function showConfirmModalAsync(title: string) {
  return new Promise((resolve) => {
    document.dispatchEvent(new CustomEvent('onShowConfirmModal', { detail: { title } }));

    document.addEventListener('onShowConfirmModalClosed', (event) => {
      document.removeEventListener('onShowConfirmModalClosed', () => { });

      const customEvent = event as CustomEvent;

      resolve(customEvent.detail.result);
    });
  });
}