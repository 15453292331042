import {
  Check,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import { ItemModel } from 'models';
import {
  AppointCommiteeListResponse,
  AppointCommiteeListResponseModel,
  CountStatusModel,
  CountSupplyMethodModel,
  SearchProcurementAppointment,
} from 'models/procurementAppointmentOrderModel';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import procurementAppointmentOrderServices from 'services/procurementAppointmentOrderServices';
import {
  formatMonthAndThaiYearWithSlash,
  HttpStatusCode,
  THCurrency,
} from 'utils';
import { useForm } from '../../../utils/useForm';

type Loader = { departmentDDL: ItemModel[], supplyMethodDDL: ItemModel[] };

export default function AppointmentOrder() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [formSearch, setFormSearch] = useState<SearchProcurementAppointment>({} as SearchProcurementAppointment);
  const [appointCommiteeListData, setAppointCommiteeListData] = useState<AppointCommiteeListResponseModel>({} as AppointCommiteeListResponseModel);
  const { departmentDDL, supplyMethodDDL } = useLoaderData() as Loader;
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);

  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>([]);
  const searchProcurementAppointmentList = useCallback(async (searchModel: SearchProcurementAppointment) => {
    if (!searchModel.page) {
      searchModel.page = page;
    }

    if (!searchModel.size) {
      searchModel.size = size;
    }

    if (searchModel.isResponsible === undefined) {
      searchModel.isResponsible = true;
    }

    if (searchModel.status === undefined) {
      searchModel.status = [CriteriaStatus.None, CriteriaStatus.Draft, CriteriaStatus.Pending, CriteriaStatus.Approved, CriteriaStatus.Rejected, CriteriaStatus.Cancelled];
    }

    if (searchModel.supplyMethodIds === undefined) {
      searchModel.supplyMethodIds = [CriteriaSupplyMethodId.MethodId60, CriteriaSupplyMethodId.MethodId80];
    }

    const { data, status } = await procurementAppointmentOrderServices.getAppointmentorderListAsync(searchModel);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    getCountStatus(searchModel);
    getCountSupplyMethod(searchModel);
    setAppointCommiteeListData(data);
  }, []);

  const getCountStatus = useCallback(async (searchModel: SearchProcurementAppointment) => {
    const countStatusRes = await procurementAppointmentOrderServices.getCountStatusAsync(searchModel);

    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data);
    }
  }, []);

  const getCountSupplyMethod = useCallback(async (searchModel: SearchProcurementAppointment) => {
    const countMethodRes = await procurementAppointmentOrderServices.getCountSupplyMethodAsync(searchModel);

    if (countMethodRes.status === HttpStatusCode.OK) {
      setCountSupplyMethod(countMethodRes.data);
    }
  }, []);

  const handleSetPagination = async (size: number, page: number) => {
    setPage(page);
    setSize(size);
    formSearch.page = page;
    formSearch.size = size;

    await searchProcurementAppointmentList(formSearch);
  };

  useEffect(() => {
    searchProcurementAppointmentList(formSearch);
    setPage(formSearch.page);
    setSize(formSearch.size);
  }, [formSearch]);

  return (
    <>
      <AppointmentOrderHeader />
      <Criteria
        setForm={setFormSearch}
        departmentList={departmentDDL}
        supplyMethodList={supplyMethodDDL}
        countStatus={countStatus}
        countSupplyMethod={countSupplyMethod}
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize} />
      <DataTable
        appointCommiteeListData={appointCommiteeListData.data}
        total={appointCommiteeListData.totalCount}
        page={page}
        size={size}
        onChange={(size, page) => {
          handleSetPagination(size, page);
        }} />
    </>
  );
}

function AppointmentOrderHeader() {
  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>จัดทำคำสั่งแต่งตั้งคณะกรรมการจัดซื้อจัดจ้างและคณะกรรมการตรวจรับ</h4>
      </div>
      <hr />
    </>
  );
}

interface FormInput {
  jorPor05Number: string;
  departmentId: string;
  planName: string;
  prNumber: string;
  responsiblePerson: string;
  supplyMethodIds: string[];
  status: string[];
}

enum CriteriaStatus {
  None = 'None',
  Draft = 'Draft',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
}

enum CriteriaSupplyMethod {
  Method60 = 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560',
  Method80 = 'ข้อบังคับธนาคาร 80',
}

export const enum CriteriaSupplyMethodId {
  MethodId60 = '342e4e0b-7682-48c0-b46a-97a066908c9a',
  MethodId80 = 'a21603e4-338d-486c-85ba-5f1cac8f6ace',
}

interface StatusItemModel {
  label: string;
  value: boolean;
}

interface CriteriaProps {
  setForm: (form: SearchProcurementAppointment) => void;
  departmentList: ItemModel[];
  supplyMethodList: ItemModel[];
  countStatus: CountStatusModel[];
  countSupplyMethod: CountSupplyMethodModel[];
  page: number;
  size: number;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
}

function Criteria(props: CriteriaProps) {
  const [program, setProgram] = useState('');
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [statusNone, setStatusNone] = useState<boolean>(false);
  const [statusDraft, setStatusDraft] = useState<boolean>(false);
  const [statusPending, setStatusPending] = useState<boolean>(false);
  const [statusApproved, setStatusApproved] = useState<boolean>(false);
  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [statusCancelled, setStatusCancelled] = useState<boolean>(false);
  const [methodAll, setMethodAll] = useState<boolean>(false);
  const [method60, setMethod60] = useState<boolean>(false);
  const [method80, setMethod80] = useState<boolean>(false);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);
  const [supplyMethods, setSupplyMethods] = useState<ItemModel[]>(props.supplyMethodList);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>(props.countStatus);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>(props.countSupplyMethod);
  const [departmentId, setDepartmentId] = useState('');
  const [methodLists, setMethodLists] = useState<StatusItemModel[]>([
    { value: false, label: CriteriaSupplyMethodId.MethodId60 },
    { value: false, label: CriteriaSupplyMethodId.MethodId80 },
  ]);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    { value: false, label: CriteriaStatus.None },
    { value: false, label: CriteriaStatus.Draft },
    {
      value: false, label: CriteriaStatus.Pending,
    },
    {
      value: false, label: CriteriaStatus.Approved,
    },
    {
      value: false, label: CriteriaStatus.Rejected,
    },
    { value: false, label: CriteriaStatus.Cancelled },
  ]);

  useEffect(() => {
    setCountStatus(props.countStatus);
  }, [props.countStatus]);

  useEffect(() => {
    setCountSupplyMethod(props.countSupplyMethod);
  }, [props.countSupplyMethod]);

  useEffect(() => {
    setIsResponsible(true);
    onChangeStatusAll(true);
    onChangeMethodAll(true);
  }, []);

  const onChangeStatusAll = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setStatusNone(true);
      setStatusDraft(true);
      setStatusPending(true);
      setStatusApproved(true);
      setStatusRejected(true);
      setStatusCancelled(true);
    } else {
      setStatusAll(false);
      setStatusNone(false);
      setStatusDraft(false);
      setStatusPending(false);
      setStatusApproved(false);
      setStatusRejected(false);
      setStatusCancelled(false);
    }
  };

  const onChangeMethodAll = (result: boolean) => {
    if (result) {
      setMethodAll(true);
      setMethod60(true);
      setMethod80(true);
    } else {
      setMethodAll(false);
      setMethod60(false);
      setMethod80(false);
    }
  };

  const onClear = useCallback(async () => {
    setDepartmentId('');
    setStatusAll(true);
    setStatusNone(true);
    setStatusDraft(true);
    setStatusPending(true);
    setStatusApproved(true);
    setStatusRejected(true);
    setStatusCancelled(true);
    setMethodAll(true);
    setMethod60(true);
    setMethod80(true);
    handleClear();
    setIsResponsible(true);
    props.setPage(1);
    props.setSize(10);
  }, []);

  const onChangeCheckMethod = (methodId: string, value: boolean) => {
    const methodList = [...methodLists];
    const index = methodLists.findIndex(s => s.label === methodId);
    methodList[index].value = value;
    setMethodLists(methodList);

    if (!value) {
      setMethodAll(false);
    }

    if (methodList.filter(s => s.value === false).length === 0) {
      setMethodAll(true);
    }

    switch (methodId) {
      case CriteriaSupplyMethodId.MethodId60:
        return setMethod60(value);
      case CriteriaSupplyMethodId.MethodId80:
        return setMethod80(value);
    }
  };

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex(s => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter(s => s.value === false).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case CriteriaStatus.None:
        return setStatusNone(value);
      case CriteriaStatus.Draft:
        return setStatusDraft(value);
      case CriteriaStatus.Pending:
        return setStatusPending(value);
      case CriteriaStatus.Approved:
        return setStatusApproved(value);
      case CriteriaStatus.Rejected:
        return setStatusRejected(value);
      case CriteriaStatus.Cancelled:
        return setStatusCancelled(value);
    }
  };

  const { handleSubmit, handleChange, data: formData, errors, handleClear } = useForm<FormInput>({
    initialValues: {
      jorPor05Number: '',
      departmentId: undefined,
      planName: '',
      prNumber: '',
      responsiblePerson: undefined,
      supplyMethodIds: undefined,
      status: undefined,
    },
    validations: {},
    onSubmit: async () => {
      const status: string[] = [];
      const method: string[] = [];

      if (statusNone) {
        status.push(CriteriaStatus.None);
      }

      if (statusDraft) {
        status.push(CriteriaStatus.Draft);
      }

      if (statusPending) {
        status.push(CriteriaStatus.Pending);
      }

      if (statusApproved) {
        status.push(CriteriaStatus.Approved);
      }

      if (statusRejected) {
        status.push(CriteriaStatus.Rejected);
      }

      if (statusCancelled) {
        status.push(CriteriaStatus.Cancelled);
      }

      if (method60) {
        method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId60)[0].value);
      }

      if (method80) {
        method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId80)[0].value);
      }

      const searchModel: SearchProcurementAppointment = {
        jorPor05Number: formData.jorPor05Number,
        departmentId,
        planName: formData.planName,
        prNumber: formData.prNumber,
        isResponsible,
        responsiblePerson: formData.responsiblePerson,
        supplyMethodIds: methodAll ? undefined : method,
        status: statusAll ? undefined : status,
        page: props.page,
        size: props.size,
      };
      props.setForm(searchModel);
    },
  });
  return (
    <Form onSubmit={handleSubmit}
      id='formSubmitPR'>
      <Row className='criteria'>
        <Col sm={12}
          md={3}
          lg={4}
          xl={4}>
          <Form.Group className='mb-3'>
            <Form.Label>เเลขที่เอกสารรายงานขอซื้อขอจ้าง (จพ.005)</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='เลขที่เอกสารรายงานขอซื้อขอจ้าง (จพ.005)'
                value={formData.jorPor05Number}
                onChange={handleChange<string>('jorPor05Number', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={4}>
          <Form.Group className='mb-3'>
            <Selector
              label='ฝ่าย/สำนัก'
              items={props.departmentList}
              value={`${departmentId}`}
              onChange={(value) => (setDepartmentId(value.toString()))}
            />
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={4}>
          <Form.Group className='mb-3'>
            <Form.Label>ชื่อโครงการ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='ชื่อโครงการ'
                value={formData.planName}
                onChange={handleChange<string>('planName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={4}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่เอกสาร PR</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='เลขที่เอกสาร PR'
                value={formData.prNumber}
                onChange={handleChange<string>('prNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={4}>
          <Check
            className='no-label'
            label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
            value={isResponsible}
            onChange={(val) => setIsResponsible(val)}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}
          md={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>วิธีจัดหา</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={<div className='align-items-center d-flex'>ทั้งหมด<Badge className='h-50 mx-2'
                  bg='light'>{countSupplyMethod.length > 0 ? countSupplyMethod.map((x) => x.count)
                    .reduce((prev, curr) => prev + curr) : 0}</Badge></div>}
                value={methodAll}
                onChange={(val) => onChangeMethodAll(val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>{CriteriaSupplyMethod.Method80}
                  <Badge className='h-50 mx-2'
                    bg='warning'>{countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId80).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId80)[0].count : 0}</Badge>
                </div>}
                value={method80}
                onChange={(val) => onChangeCheckMethod(CriteriaSupplyMethodId.MethodId80, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>{CriteriaSupplyMethod.Method60}
                  <Badge className='h-50 mx-2'
                    bg='secondary'>{countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId60).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId60)[0].count : 0}</Badge>
                </div>}
                value={method60}
                onChange={(val) => onChangeCheckMethod(CriteriaSupplyMethodId.MethodId60, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={8}
          xl={9}>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={<div className='align-items-center d-flex'>ทั้งหมด <Badge className='h-50 mx-2'
                  bg='light'>{countStatus.length > 0 ? countStatus.map((x) => x.count)
                    .reduce((prev, curr) => prev + curr) : 0}</Badge></div>}
                value={statusAll}
                onChange={(val) => onChangeStatusAll(val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>ยังไม่ได้ดำเนินการ <Badge className='h-50 mx-2'
                  bg='secondary'>{countStatus.filter((x) => x.status === CriteriaStatus.None).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.None)[0].count : 0}</Badge>
                </div>}
                value={statusNone}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.None, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>แบบร่าง <Badge className='h-50 mx-2'
                  bg='secondary'>{countStatus.filter((x) => x.status === CriteriaStatus.Draft).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.Draft)[0].count : 0}</Badge>
                </div>}
                value={statusDraft}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.Draft, val)}
              />
              <Check
                label={
                  <div className='align-items-center d-flex'>รอเห็นชอบ/รออนุมัติขอแก้ไข <Badge className='h-50 mx-2'
                    bg='warning'>{countStatus.filter((x) => x.status === CriteriaStatus.Pending).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.Pending)[0].count : 0}</Badge>
                  </div>}
                value={statusPending}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.Pending, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>เห็นชอบ/อนุมัติ <Badge className='h-50 mx-2'
                  bg='success'>{countStatus.filter((x) => x.status === CriteriaStatus.Approved).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.Approved)[0].count : 0}</Badge>
                </div>}
                value={statusApproved}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.Approved, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>ส่งกลับแก้ไข<Badge className='h-50 mx-2'
                  bg='danger'>{countStatus.filter((x) => x.status === CriteriaStatus.Rejected).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.Rejected)[0].count : 0}</Badge>
                </div>}
                value={statusRejected}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.Rejected, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>ยกเลิก<Badge className='h-50 mx-2'
                  bg='danger'>{countStatus.filter((x) => x.status === CriteriaStatus.Cancelled).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.Cancelled)[0].count : 0}</Badge>
                </div>}
                value={statusCancelled}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.Cancelled, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      <div className='d-flex gap-2'>
        <Button form='formSubmitPR'
          type='submit'
          variant='primary'
          className='d-flex align-items-center gap-2'>
          <FaSearch />ค้นหา
        </Button>
        <Button variant='outline-primary'
          onClick={onClear}
          form='formSubmitPR'
          type='submit'
          className='d-flex align-items-center gap-2'>
          <FaEraser />ล้าง
        </Button>
      </div>
    </Form>
  );
}

interface DataTableProps {
  page: number;
  size: number;
  total: number;
  appointCommiteeListData: AppointCommiteeListResponse[];
  onChange?: (size: number, page: number) => void;
}

function DataTable(props: DataTableProps) {
  const [appointCommiteeListData, setAppointCommiteeListData] = useState<AppointCommiteeListResponse[]>([]);
  const [indexId, setIndexId] = useState<number>(0);
  const navigate = useNavigate();

  const goToPage = (jorPor05Id: string, id: string) => {
    if (id && jorPor05Id) {
      navigate(`/procurement-appointment-order/detail/${jorPor05Id}/id/${id}`);
    } else {
      navigate(`/procurement-appointment-order/detail/${jorPor05Id}`);
    }
  };

  const handleSetPagination = (page: number, size: number) => {
    if (props.onChange) {
      props.onChange(size, page);
    }
  };

  useEffect(() => {
    if (props && props.appointCommiteeListData) {
      setAppointCommiteeListData(props.appointCommiteeListData);
    }
  }, [props]);

  return (
    <Table className='mt-4 default-page'
      total={props.total}
      onChange={(size, page) => {
        handleSetPagination(page, size);
      }}>
      <thead>
        <tr className='text-center'>
          <th className='text-center'>เลขที่เอกสารรายงาน<br />
            ขอซื้อขอจ้าง (จพ.005)
          </th>
          <th className='text-center'>เลขที่เอกสาร PR</th>
          <th className='text-center'>สถานะ</th>
          <th className='text-center'>ฝ่าย/สำนัก</th>
          <th className='text-center'>ชื่อโครงการ</th>
          <th className='text-center'>วงเงิน</th>
          <th className='text-center'>วิธีจัดหา</th>
          <th className='text-center'>ประมาณการช่วงเวลา<br />
            การจัดซื้อจัดจ้าง
          </th>
        </tr>
      </thead>
      <tbody>
        {appointCommiteeListData.map((data, index) => (
          <tr className='text-center'
            key={index}>
            <td className='text-center'>
              <Button variant='link'
                onClick={() => goToPage(data.jorPor05Id, data.id)}>{data.jorPor05Number}
              </Button>
            </td>
            <td className='text-center'>{data.prNumber}</td>
            <td><Status value={data.status}
              type={StatusType.APPOINTMENTORDER} /></td>
            <td className='text-start'>{data.departmentShort} : {data.department}</td>
            <td className='text-start text-wrap'
              style={{ minWidth: 550, width: 550 }}>{data.planName}</td>
            <td className='text-end'>{THCurrency(data.planBudget)}</td>
            <td>{data.supplyMethodValue}</td>
            <td>{formatMonthAndThaiYearWithSlash(data.expectingProcurementAt)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
