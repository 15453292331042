import {
  Check,
  ProcessStatus,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  Currency,
  DatePicker,
  Input,
  Selector,
} from 'components/Controls';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';

const supplyMethodDDL = [
  { label: 'ซื้อ', value: '1' },
  { label: 'จ้าง', value: '2' },
  { label: 'เช่า', value: '3' },
];

export default function ManageContractManagement() {

  function GetStatus(i: number) {
    switch (i) {
      case 1:
        return <Status type={StatusType.PROCESS} value={ProcessStatus.PENDING} />;
      case 2:
        return <Status type={StatusType.PROCESS} value={ProcessStatus.APPROVED} />;
      case 3:
        return <Status type={StatusType.PROCESS} value={ProcessStatus.REJECTED} />;
      default:
        return <Status type={StatusType.PROCESS} value={ProcessStatus.DRAFT} />;
    }
  }

  const navigate = useNavigate();
  const goToPage = () => {
    navigate('/manage-contractMangement/Detail/C00001');
  };

  return (
    <div className="m01">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">บริหารสัญญา</h4>
      </div>
      <hr />
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="เลขที่สัญญา" placeholder="เลขที่สัญญา" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="เลขที่โครงการ" placeholder="เลขที่โครงการ" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="ฝ่าย/สำนัก" placeholder="ฝ่าย/สำนัก" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="ชื่อโครงการ" placeholder="ชื่อโครงการ" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="ชื่อบริษัท" placeholder="ชื่อบริษัท" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <DatePicker label="วันที่เริ่มสัญญา" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <DatePicker label="วันที่สิ้นสุด" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="หมายเลขกล่องที่จัดเก็บ" placeholder="หมายเลขกล่องที่จัดเก็บ" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector
            label="ประเภทสัญญา"
            placeholder="กรุณาเลือก"
            items={supplyMethodDDL}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Currency
            label="วงเงินอนุมัติเริ่มต้น"
            placeholder="วงเงินอนุมัติเริ่มต้น"
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Currency
            label="วงเงินอนุมัติสิ้นสุด"
            placeholder="วงเงินอนุมัติสิ้นสุด"
          />
        </Col>
        <Col sm={12} >
          <Form.Group className="mb-3">
            <Form.Label>สถานะ</Form.Label>
            <InputGroup className="align-items-center gap-3">
              <Check
                label={<div className="align-items-center d-flex">ทั้งหมด <Badge className="h-50 mx-2 text-dark" bg="light">40</Badge></div>}
              />
              <Check
                label={<div className="align-items-center d-flex">แบบร่าง <Badge className="h-50 mx-2" bg="light">10</Badge></div>}
              />
              <Check
                label={<div className="align-items-center d-flex">รอเห็นชอบ/รออนุมัติขอแก้ไข <Badge className="h-50 mx-2" bg="warning">10</Badge></div>}
              />
              <Check
                label={<div className="align-items-center d-flex">เห็นชอบ/อนุมัติ <Badge className="h-50 mx-2" bg="success">10</Badge></div>}
              />
              <Check
                label={<div className="align-items-center d-flex">ยกเลิก <Badge className="h-50 mx-2" bg="danger">10</Badge></div>}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <div className="d-flex gap-2">
          <Button form="formPL01" type="submit" variant="primary" className="d-flex align-items-center gap-2">
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant="outline-primary"
            className="d-flex align-items-center gap-2"
          >
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
      <Row className="mt-5">
        <Col sm={12}>
          <Table hidePagination>
            <thead>
              <tr>
                <th style={{ width: 50 }}>เลขที่
                  สัญญา</th>
                <th style={{ minWidth: 50 }}>สถานะ</th>
                <th style={{ minWidth: 50 }}>เลขที่โครงการ</th>
                <th style={{ minWidth: 50 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 50 }}>ประเภทสัญญา</th>
                <th style={{ minWidth: 50 }}>ผู้ประกอบการ/<br />
                  คู่ค้า</th>
                <th style={{ minWidth: 50 }}>จำนวนเงิน</th>
                <th style={{ minWidth: 50 }}>วันที่ทำเริ่ม<br />
                  สัญญา</th>
                <th style={{ minWidth: 50 }}>วันที่สิ้นสุด<br />
                  สัญญา</th>
                <th style={{ minWidth: 50 }}>หมายเลขกล่องที่จัดเก็บ</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 3 }).map((_, index) => (
                <tr className="text-center" key={index}>
                  <td><Button variant="link" onClick={goToPage}>1100010495</Button></td>
                  <td>{GetStatus(index)}</td>
                  <td>T0001</td>
                  <td>จ้างที่ปรึกษา</td>
                  <td className="text-start">จ้าง</td>
                  <td>บริษัท ออนไลน์ เน็ตเวิร์ค</td>
                  <td>300,000.00</td>
                  <td>05/01/2566</td>
                  <td>12/12/2566</td>
                  <td>0002</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
