import {
  ChangePlanRequestCreate,
  GetChangeRequestParamsModel,
} from 'models';
import http from 'utils/axios';

const getChangePlanRequestListPaginationAsync = async (page: number, size: number, paramsModel: GetChangeRequestParamsModel) => {
  const params = {
    page,
    size,
    ...paramsModel,
  };

  return await http.get('changeplanrequest', { params });
};

const getCountStatusAsync = async () => await http.get('changeplanrequest/count-status');

const getChangePlanRequestDetailAsync = async (id: string) => await http.get(`changeplanrequest/${id}`);

const createChangePlanRequestAsync = async (body: ChangePlanRequestCreate) => await http.post('changeplanrequest', body);

const updateChangePlanRequestAsync = async (id: string, body: ChangePlanRequestCreate) => await http.put(`changeplanrequest/${id}`, body);

export default {
  getChangePlanRequestListPaginationAsync,
  createChangePlanRequestAsync,
  getCountStatusAsync,
  getChangePlanRequestDetailAsync,
  updateChangePlanRequestAsync,
};
