import { useMemo } from 'react';

interface Props {
  value?: string;
  outline?: boolean;
}

export function ApproverStatus(props: Props) {
  const status = useMemo(() => {
    if (props.value === 'Draft') {
      return {
        text: 'ร่าง',
        color: `${!props.outline
          ? 'bg-muted text-white rounded'
          : 'bg-muted-outline text-muted border border-muted rounded'}`,
      };
    }

    if (props.value === 'Pending') {
      return {
        text: 'รอเห็นชอบ',
        color: `${!props.outline
          ? 'bg-warning text-white rounded'
          : 'bg-warning-outline text-warning border border-warning rounded'}`,
      };
    }

    if (props.value === 'Approve' || props.value === 'Approved') {
      return {
        text: 'เห็นชอบ',
        color: `${!props.outline
          ? 'bg-success text-white rounded'
          : 'bg-success-outline text-success border border-success rounded'}`,
      };
    }

    if (props.value === 'Reject' || props.value === 'Rejected') {
      return {
        text: 'ส่งกลับแก้ไข',
        color: `${!props.outline
          ? 'bg-danger text-white rounded'
          : 'bg-danger-outline text-danger border border-danger rounded'}`,
      };
    }

    return null;
  }, [props.value, props.outline]);

  return (
    <>
      {
        status
          ? <div className={status.color}>{status.text}</div>
          : null
      }
    </>
  );
}

export function Approver(props: Props) {
  const status = useMemo(() => {
    if (props.value === 'Draft') {
      return {
        text: 'ร่าง',
        color: `${!props.outline
          ? 'bg-muted text-white rounded'
          : 'bg-muted-outline text-muted border border-muted rounded'}`,
      };
    }

    if (props.value === 'Pending') {
      return {
        text: 'รออนุมัติ',
        color: `${!props.outline
          ? 'bg-warning text-white rounded'
          : 'bg-warning-outline text-warning border border-warning rounded'}`,
      };
    }

    if (props.value === 'Approve' || props.value === 'Approved') {
      return {
        text: 'อนุมัติ',
        color: `${!props.outline
          ? 'bg-success text-white rounded'
          : 'bg-success-outline text-success border border-success rounded'}`,
      };
    }

    if (props.value === 'Reject' || props.value === 'Rejected') {
      return {
        text: 'ส่งกลับแก้ไข',
        color: `${!props.outline
          ? 'bg-danger text-white rounded'
          : 'bg-danger-outline text-danger border border-danger rounded'}`,
      };
    }

    return null;
  }, [props.value]);

  return (
    <>
      {
        status
          ? <div className={status.color}>{status.text}</div>
          : null
      }
    </>
  );
}
