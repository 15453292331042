import {
  Input,
  Selector,
  Table,
} from 'components';
import { BadgeStatus } from 'components/Controls/BadgeisActive';
import {
  ItemModel,
  UserResponse,
} from 'models';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEdit,
  FaEraser,
  FaPlus,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { account } from 'services';
import {
  fullDatetime,
  HttpStatusCode,
} from 'utils';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';

const STATUS = [
  { label: 'ใช้งาน', value: 'true' },
  { label: 'ระงับการใช้งาน', value: 'false' },
];

type Loader = {
  users: { data: UserResponse[], totalRecords: number },
  departmentDDL: ItemModel[],
  positionDDL: ItemModel[],
  roleList: { label: string, value: string }[],
};

export default function SU07() {
  const loader = useLoaderData() as Loader;
  const [name, setName] = useState<string>('');
  const [tel, setTel] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [department, setDepartment] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [roleId, setRoleId] = useState<string>('');
  const [updatedBy, setUpdatedBy] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    setUsers(loader.users.data);
    setTotalRecords(loader.users.totalRecords);
  }, []);

  useEffect(() => {
    getUsersAsync();
  }, [size, page]);

  const getUsersAsync = async () => {
    const res = await account.getUsersAsync(size, page, name, email, tel, department, position, roleId, updatedBy, isActive);

    if (res.status === HttpStatusCode.OK) {
      setUsers(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }
  };

  const handlerOnSearchAsync = async () => {
    await getUsersAsync();
  };

  const clearCriteria = () => {
    setName('');
    setTel('');
    setEmail('');
    setDepartment('');
    setPosition('');
    setRoleId('');
    setUpdatedBy('');
    setIsActive(true);
    (async () => {
      const res = await account.getUsersAsync(10, 1);

      if (res.status === HttpStatusCode.OK) {
        setUsers(res.data.data);
        setTotalRecords(res.data.totalRecords);
      }
    })();
  };



  return (
    <div className="m02">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">จัดการผู้ใช้งาน</h4>
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={() => navigate('detail')}
        >
          <FaPlus />เพิ่มผู้ใช้งาน
        </Button>
      </div>
      <hr />
      <div className="criteria">
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="ชื่อ-นามสกุล"
              value={name}
              onChange={setName}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="เบอร์โทรศัพท์"
              value={tel}
              onChange={setTel}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="อีเมล"
              value={email}
              onChange={setEmail}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="ฝ่าย"
              value={department}
              items={loader.departmentDDL}
              onChange={setDepartment}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="ตำแหน่ง"
              value={position}
              onChange={setPosition}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="สิทธิ์การใช้งาน"
              value={roleId}
              onChange={setRoleId}
              items={loader.roleList}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="ชื่อผู้แก้ไข"
              value={updatedBy}
              onChange={setUpdatedBy}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label="สถานะ"
              items={STATUS}
              value={`${isActive}`}
              onChange={(val) => setIsActive(val === 'true')}
            />
          </Col>
          <div className="d-flex gap-2">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={handlerOnSearchAsync}
            >
              <FaSearch />ค้นหา
            </Button>
            <Button
              variant="outline-primary"
              className="d-flex align-items-center gap-2"
              onClick={clearCriteria}
            >
              <FaEraser />ล้าง
            </Button>
          </div>
        </Row>
      </div>
      <div className="table-relative-fix">
        <div className="table-scroll">
          <Table
            className="mt-4"
            total={totalRecords}
            onChange={(size, page) => (setSize(size), setPage(page))}
          >
            <thead>
              <tr>
                <th style={{ minWidth: 75 }}>ลำดับ</th>
                <th style={{ minWidth: 250 }}>ชื่อ - นามสกุล</th>
                <th style={{ minWidth: 150 }}>เบอร์โทรศัพท์</th>
                <th style={{ minWidth: 200 }}>อีเมล</th>
                <th style={{ minWidth: 250 }}>ตำแหน่ง</th>
                <th style={{ minWidth: 150 }}>ฝ่าย</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ชื่อผู้แก้ไข</th>
                <th style={{ minWidth: 150 }}>วันที่แก้ไข</th>
                <th className="fix-width" />
                <th className="fix-col" />
              </tr>
            </thead>
            <tbody>
              {users.map((u, i) => (
                <tr key={u.id}>
                  <td className="text-center">{calculateRowNumber(i, page, size)}</td>
                  <td>{u.name}</td>
                  <td className="text-center">{u.tel}</td>
                  <td>{u.email}</td>
                  <td>{u.position}</td>
                  <td>{u.department}</td>
                  <td className="text-center"><BadgeStatus status={u.isActive} /></td>
                  <td>{u.updatedBy}</td>
                  <td className="text-center">{fullDatetime(u.updatedAt)}</td>
                  <td className="fix-width" />
                  <td className="text-end fix-col">
                    <Button
                      variant="outline-primary"
                      className="gap-2"
                      onClick={() => navigate(`detail/${u.id}`)}
                    >
                      <FaEdit className="me-2 mb-1" />แก้ไข
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
