import {
  Check,
  ContractAuditStatus,
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { CriteriaSupplyMethodId } from 'constant/ContractStatus';
import { ItemModel } from 'models';
import { ContractReportListModel, CountSupplyMethodModel, SearchContractReportModel } from 'models/CM/CM07Model';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { FaEraser, FaFileExcel, FaSearch } from 'react-icons/fa';
import { useLoaderData, useNavigate } from 'react-router';
import CM07Service from 'services/CM/CM07Service';
import { HttpStatusCode, THCurrency, thaiFormatDateWithSlash } from 'utils';

interface StatusItemModel {
  label: string;
  value: boolean;
}

type Loader = { departmentDDL: ItemModel[], supplyMethodDDL: ItemModel[] };

export default function CM07() {
  const { departmentDDL, supplyMethodDDL } = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [supplyMethods] = useState<ItemModel[]>(supplyMethodDDL);
  const [page, setPage] = useState(1);
  const [criteria, setCriteria] = useState<SearchContractReportModel>({} as SearchContractReportModel);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [dataList, setDataList] = useState<ContractReportListModel[]>([]);
  const [departmentId, setDepartmentId] = useState('');
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>([]);
  const [methodAll, setMethodAll] = useState<boolean>(true);
  const [method60, setMethod60] = useState<boolean>(false);
  const [method80, setMethod80] = useState<boolean>(false);
  const [isResponsible, setIsResponsible] = useState<boolean>(true);
  const [methodLists, setMethodLists] = useState<StatusItemModel[]>([
    {
      value: false,
      label: CriteriaSupplyMethodId.MethodId60,
    },
    {
      value: false,
      label: CriteriaSupplyMethodId.MethodId80,
    },
  ]);

  useEffect(() => {
    onChangeMethodAll(true);
    setIsResponsible(true);
    onSearch();
  }, []);

  const getListDataAsync = async (searchModel: SearchContractReportModel) => {
    if (!searchModel.page) {
      searchModel.page = page;
    }

    if (!searchModel.size) {
      searchModel.size = 10;
    }

    const res = await CM07Service.getListAsync(searchModel);

    if (res.status === HttpStatusCode.OK) {
      setDataList(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }

    await getCountSupplyMethod(searchModel);
  };

  const getCountSupplyMethod = useCallback(async (searchModel: SearchContractReportModel) => {
    const countMethodRes = await CM07Service.getCountSupplyMethodAsync(searchModel);

    if (countMethodRes.status === HttpStatusCode.OK) {
      setCountSupplyMethod(countMethodRes.data);
    }
  }, []);

  const onPaginationChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);
    criteria.page = page;
    criteria.size = size;
    onSearch();
  };

  const onSearch = () => {
    const method: string[] = [];

    if (method60) {
      method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId60)[0].value);
    }

    if (method80) {
      method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId80)[0].value);
    }

    const searchCriteria = {
      poNumber: criteria.poNumber,
      planNumber: criteria.planNumber,
      contractNumber: criteria.contractNumber,
      contractName: criteria.contractName,
      entrepreneurName: criteria.entrepreneurName,
      departmentId: criteria.departmentId,
      supplyMethodIds: methodAll ? [CriteriaSupplyMethodId.MethodId60, CriteriaSupplyMethodId.MethodId80] : method,
      page,
      size,
      isResponsible,
    } as SearchContractReportModel;
    getListDataAsync(searchCriteria);
  };

  const onClearSearch = () => {
    const status: string[] = [];
    const sizeData = 10;
    const pageData = 1;
    setSize(sizeData);
    setPage(pageData);
    setIsResponsible(true);
    onPaginationChange(sizeData, pageData);
    setCriteria({
      size: sizeData,
      page: pageData,
      supplyMethodIds: [CriteriaSupplyMethodId.MethodId60, CriteriaSupplyMethodId.MethodId80]
    } as SearchContractReportModel);

    const searchCriteria = {
      size: sizeData,
      page: pageData,
      isResponsible: true,
      supplyMethodIds: [CriteriaSupplyMethodId.MethodId60, CriteriaSupplyMethodId.MethodId80]
    } as SearchContractReportModel;
    getListDataAsync(searchCriteria);
  };

  const onChangeMethodAll = (result: boolean) => {
    setMethodAll(result);
    setMethod60(result);
    setMethod80(result);
  };

  const onChangeCheckMethod = (methodId: string, value: boolean) => {
    const methodList = [...methodLists];
    const index = methodLists.findIndex((s) => s.label === methodId);
    methodList[index].value = value;
    setMethodLists(methodList);

    if (!value) {
      setMethodAll(false);
    }

    if (methodList.filter(s => s.value === false).length === 0) {
      setMethodAll(true);
    }

    switch (methodId) {
      case CriteriaSupplyMethodId.MethodId60:
        return setMethod60(value);
      case CriteriaSupplyMethodId.MethodId80:
        return setMethod80(value);
      default:
        break;
    }
  };

  const onExportReportAsync = async () => {
    const method: string[] = [];

    if (method60) {
      method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId60)[0].value);
    }

    if (method80) {
      method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId80)[0].value);
    }

    const searchCriteria = {
      poNumber: criteria.poNumber,
      planNumber: criteria.planNumber,
      contractNumber: criteria.contractNumber,
      contractName: criteria.contractName,
      entrepreneurName: criteria.entrepreneurName,
      departmentId: criteria.departmentId,
      supplyMethodIds: methodAll ? [CriteriaSupplyMethodId.MethodId60, CriteriaSupplyMethodId.MethodId80] : method,
      page,
      size,
      isResponsible,
    } as SearchContractReportModel;
    const { data } = await CM07Service.getReportAsync(searchCriteria);

    const todayText = new Date().toISOString()
      .substring(0, 10)
      .replaceAll('-', '');

    downloadReport(data, `รายงานบริหารสัญญา_${todayText}.xlsx`);
  };

  const downloadReport = (fileData: Blob, fileName: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(fileData);
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className='m01'>
      <Title text='รายงานบริหารสัญญา' />

      <Row className='criteria my-4'>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่สัญญา'
            placeholder='เลขที่สัญญา'
            onChange={contractNumber => setCriteria({
              ...criteria,
              contractNumber,
            })}
            value={criteria.contractNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่ PO'
            placeholder='เลขที่ PO'
            onChange={poNumber => setCriteria({
              ...criteria,
              poNumber,
            })}
            value={criteria.poNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่โครงการ'
            placeholder='เลขที่โครงการ'
            onChange={planNumber => setCriteria({
              ...criteria,
              planNumber,
            })}
            value={criteria.planNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='ฝ่าย/สำนัก'
            placeholder='ฝ่าย/สำนัก'
            items={departmentDDL}
            value={`${criteria.departmentId}`}
            onChange={(val) => setCriteria({
              ...criteria,
              departmentId: val,
            })}
          />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='ชื่อโครงการ'
            placeholder='ชื่อโครงการ'
            onChange={(val) => setCriteria({
              ...criteria,
              contractName: val,
            })}
            value={criteria.contractName} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='ชื่อบริษัท'
            placeholder='ชื่อบริษัท'
            onChange={(val) => setCriteria({
              ...criteria,
              entrepreneurName: val,
            })}
            value={criteria.entrepreneurName} />
        </Col>
        <Col sm={12}>
          <Form.Group className='mb-3'>
            <Form.Label>วิธีจัดหา</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={(
                  <div className='align-items-center d-flex'>
                    ทั้งหมด
                    <Badge
                      className='h-50 mx-2'
                      bg='light'
                    >
                      {
                        countSupplyMethod.length > 0
                          ? countSupplyMethod
                            .map((x) => x.count)
                            .reduce((prev, curr) => prev + curr)
                          : 0
                      }
                    </Badge>
                  </div>
                )}
                value={methodAll}
                onChange={(val) => onChangeMethodAll(val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>
                    พ.ร.บ.จัดซื้อจัดจ้างฯ
                    2560
                    <Badge
                      className='h-50 mx-2'
                      bg='warning'
                    >
                      {
                        countSupplyMethod
                          .filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId60).length > 0
                          ? countSupplyMethod
                            .filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId60)[0].count
                          : 0
                      }
                    </Badge>
                  </div>
                )}
                value={method60}
                onChange={(val) => onChangeCheckMethod(CriteriaSupplyMethodId.MethodId60, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>
                    ข้อบังคับธนาคาร 80
                    <Badge
                      className='h-50 mx-2'
                      bg='secondary'
                    >
                      {
                        countSupplyMethod
                          .filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId80).length > 0
                          ? countSupplyMethod
                            .filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId80)[0].count
                          : 0
                      }
                    </Badge>
                  </div>
                )}
                value={method80}
                onChange={(val) => onChangeCheckMethod(CriteriaSupplyMethodId.MethodId80, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Row>
          <Col sm={6}>
            <Check
              label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
              value={isResponsible}
              onChange={(val) => setIsResponsible(val)}
            />
          </Col>
        </Row>
        <div className='d-flex gap-2 justify-content-between'>
          <div className='d-flex'>
            <Button
              type='submit'
              variant='primary'
              className='me-2'
              onClick={onSearch}>
              <FaSearch className='me-2' />
              ค้นหา
            </Button>
            <Button
              onClick={onClearSearch}
              variant='outline-primary'
              type='submit'
              form='formCM02'
              className='d-flex align-items-center gap-2 ms-2'
            >
              <FaEraser />
              ล้าง
            </Button>
          </div>
          <div className='d-flex'>
            <Button
              variant='outline-primary'
              className='d-flex align-items-center gap-2'
              onClick={onExportReportAsync}
            >
              <FaFileExcel />
              พิมพ์รายงานบริหารสัญญา
            </Button>
          </div>
        </div>
      </Row>

      <Row>
        <Col sm={12}>
          <Table total={totalRecords}
            onChange={(size, page) => {
              onPaginationChange(size, page);
            }}>
            <thead>
              <tr className='text-center'>
                <th style={{ width: '10%' }}>เลขที่สัญญา</th>
                <th style={{ width: '10%' }}>เลขที่ PO</th>
                <th style={{ width: 100 }}>วิธีจัดหา</th>
                <th style={{ width: '10%' }}>ฝ่าย/สำนัก</th>
                <th style={{ width: 100 }}>วันที่ทำสัญญา</th>
                <th style={{ width: 100 }}>ประเภทสัญญา</th>
                <th style={{ width: '20%' }}>ชื่อโครงการ (สัญญา)</th>
                <th style={{ width: 100 }}>บริษัทคู่ค้า/ผู้รับจ้าง</th>
                <th style={{ width: 100 }}>ค่าจ้าง (รวมภาษี)</th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((data, index) => (
                <tr className='text-center'>
                  <td>
                    {data.contractNumber}
                  </td>
                  <td className='text-center'>
                    {data.poNumber}
                  </td>
                  <td className='text-start'>
                    {data.supplyMethodName}
                  </td>
                  <td className='text-start'>
                    {data.departmentName}
                  </td>
                  <td>
                    {thaiFormatDateWithSlash(data.contractCreateDate)}
                  </td>
                  <td>
                    {data.contractTypeName}
                  </td>
                  <td className='text-start'>{data.contractName}</td>
                  <td className='text-start'>{data.entrepreneurName}</td>
                  <td className='text-end'>{THCurrency(data.offerTotalAmount)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
