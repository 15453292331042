import {
  ApproverStatus,
  Card,
  Check,
  Input,
  InputTextArea,
  MedianPriceStatus,
  Modal,
  Table,
  ArrowCollapse,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  IFile,
  ItemModel,
  JorporDirectorBeInformed,
} from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {
  FaCircle,
  FaHistory,
  FaUndo,
} from 'react-icons/fa';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import { account, shareValue } from 'services';
import PreProcurementTorServices from 'services/PP/PreProcurementTorServices';
import {
  HttpStatusCode,
  PositionOnBoardIdEnum,
  ProcurementSection,
  submitForm,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { HistoryModal } from './Modal/HistoryModal';
import { Context } from './step';
import UploadFile from './UploadFile';
import { AcceptorModel, AttachmentFileModel, MedianPriceAcceptorModel, UpdateIsAvailableCommitteeMedianPriceModel, UpdateIsAvailableCommitteeMedianPriceModels } from 'models/PP/MedianPriceModel';
import { StatusMedianPrice } from 'components/StatusMedianPrice';
import MedianPriceService from 'services/PP/MedianPriceService';
import { TorBudgetConstant, TorEditCancelStatusConstant, TorInRefCodeConstant } from 'constant/PreProcumentTorStatus';
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import { PROCESSTYPE, SectionType } from 'utils/constants/ProcessTypeEnum';
import { sectionApproverModel } from 'models/delegateModel';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type Loader = {
  positionOnBoardDDL: ItemModel[];
};

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level?: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const { data, setData, readonly } = useContext(Context);
  const { userId, departmentCode } = useAppContext();
  const [openCollapse, setOpenCollapse] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showRecall, setShowRecall] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [currentPath, setCurrentPath] = useState('preprocurement-tor/detail');
  const [committeeApprovers, setCommitteeApprovers] = useState<MedianPriceAcceptorModel[]>([]);
  const [rejectRemark, setRejectRemark] = useState('');
  const [showWaitingForApprove, setShowWaitingForApprove] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [approveRemark, setApproveRemark] = useState('');
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [positionOnBoardItems, setPositionOnBoardItems] = useState<ItemModel[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isAvailableAll, setIsAvailableAll] = useState(data.committees?.filter((a) => a.isAvailable === false).length > 0);
  const [docId, setDocId] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);
  const [approverUsers, setApproverUsers] = useState<AcceptorModel[]>([]);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [isCommittee, setIsCommittee] = useState(false);
  const [jorPorDirectors, setJorPorDirectors] = useState<MedianPriceAcceptorModel[]>([]);
  const [isJorPorComment, setIsJorPorComment] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const getDefaultUserAsync = async () => {
    const [
      jorporDirectorBeInformedDefault,
    ] = await Promise.all([
      account.getDefaultDepartmentDirectorAgree(ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED),
    ]);

    const jorporDirectorBeInformed = jorporDirectorBeInformedDefault.data as JorporDirectorBeInformed[];

    const newJorPorDirectors: MedianPriceAcceptorModel[] = [];

    jorporDirectorBeInformed.forEach((item, index) => {
      newJorPorDirectors.push({
        sequence: item.sequence,
        sectionInRefCode: TorInRefCodeConstant.JorPorDirector,
        fullName: item.fullName,
        userId: item.userId,
        medianPriceId: data.id,
        departmentName: item.departmentName,
        positionName: item.positionName,
        sectionSequence: 0,
        status: MedianPriceStatus.Pending,
      } as MedianPriceAcceptorModel);
    });

    setJorPorDirectors(newJorPorDirectors);

    if (data.isJorPorComment && newJorPorDirectors.length > 0) {
      onGetDefaultApprover(TorBudgetConstant.OneHundredMillion, newJorPorDirectors[0].userId);
    }

    return;
  };

  useEffect(() => {
    const paramId = location.pathname.slice(location.pathname.lastIndexOf('/'), location.pathname.length);

    setCurrentPath(location.pathname.replace(paramId, ''));
    getPositionOnBoardAsync();
    setIsDisabledProcess();
  }, []);

  const setIsDisabledProcess = () => {
    setIsDisabled(isDisabledProcess);
  };

  const isDisabledProcess = useMemo(() => {
    return !(data.status === MedianPriceStatus.None
      || data.status === MedianPriceStatus.Draft
      || data.status === MedianPriceStatus.Rejected);
  }, [data.status]);

  const onGetDefaultJorPor = async () => {

    const processType = data.isConsumableMaterials ? PROCESSTYPE.ApproveMedianProcessStock : PROCESSTYPE.ApproveMedianProcessNonStock;

    const response = await account
      .getDefaultApproverByTopLeve(processType, data.financialAmount, data.appointPreProcurement.supplyMethod, data.appointPreProcurement.ignorePurchaseOnTable159, departmentCode);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      // const isMD = newDatas.filter(x => x.inRefCode === SectionType.BP001 || x.inRefCode === SectionType.BP002).length > 0;
      // setIsJorPorComment(isMD);

      // if (isMD) {
      //   getDefaultUserAsync();
      // }
    }
  };

  useEffect(() => {
    if (data) {
      if (data.id) {
        getFilesAsync(data.id);
        getDocument(data.id);
      }

      if (data.isJorPorComment) {
        setIsJorPorComment(data.isJorPorComment);
      }

      if (data.jorPorAcceptors.length === 0) {
        getDefaultUserAsync();
        // onGetDefaultJorPor();
      }

      if (data.jorPorAcceptors) {
        setJorPorDirectors(data.jorPorAcceptors);
      }

      if (data.committees) {
        setCommitteeApprovers(data.committees);
        const queueApprover = onCheckQueueApprover(data.committees);
        setIsQueueApprover(queueApprover);

        if (data.committees.filter(x => x.userId === userId || x.delegateUserId === userId).length > 0) {
          setIsCommittee(true)
        } else {
          setIsCommittee(false)
        }
      }

      if (data.approvers) {
        setApproverUsers(data.approvers);
        onSetSections(data.approvers);
      }

      if (data.approvers.length === 0) {
        onGetDefaultApprover(data.financialAmount);
      }
    }
  }, [data, userId]);

  const getPositionOnBoardAsync = async () => {
    const getPositionOnBoardList = await shareValue.getPositionOnBoardListAsync();

    if (getPositionOnBoardList.status === HttpStatusCode.OK) {
      const positionOnBoards: ItemModel[] = getPositionOnBoardList.data;
      setPositionOnBoardItems(positionOnBoards);
    }
  };

  const onSubmitAsync = async (sendToApprove: boolean) => {
    submitForm();

    if (data.id) {

      const updateCommitteeModels: UpdateIsAvailableCommitteeMedianPriceModel[] = [];
      const saveApprovers: AcceptorModel[] = [];

      data.committees.forEach((data) => {
        if (data.id) {
          const newData: UpdateIsAvailableCommitteeMedianPriceModel = {
            id: data.id,
            isAvailable: data.isAvailable,
            availableRemark: data.availableRemark,
          };

          updateCommitteeModels.push(newData);
        }
      });

      sections?.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((section) => {

          approverUsers?.filter((x) => x.sectionSequence === section.sectionSequence)
            .forEach((data, index) => {
              const newData: AcceptorModel = {
                id: data.id,
                sectionSequence: section.sectionSequence,
                userId: data.userId,
                sequence: index + 1,
                fullName: data.fullName,
                positionName: data.positionName,
                acceptDate: data.acceptDate,
                isAvailable: data.isAvailable,
                acceptRemark: data.acceptRemark,
                departmentName: data.departmentName,
                status: data.status,
                sectionInRefCode: data.sectionInRefCode,
                sectionName: data.sectionName,
                rejectDate: data.rejectDate,
                delegateAnnotation: data.delegateAnnotation,
                delegateDepartmentName: data.delegateDepartmentName,
                delegateEmployeeCode: data.delegateEmployeeCode,
                delegateFullName: data.delegateFullName,
                delegatePositionName: data.delegatePositionName,
                delegateUserId: data.delegateUserId,
                acceptUserId: data.acceptUserId,
                isAvailableRemark: data.isAvailableRemark,
                medianPriceId: data.medianPriceId,
                rejectRemark: data.rejectRemark,
                rejectUserId: data.rejectUserId,
                seqKey: data.seqKey,
                userApprovedBy: data.userApprovedBy,
                userRejectedBy: data.userRejectedBy,
              };

              saveApprovers.push(newData);
            });
        });

      const updateDataModel: UpdateIsAvailableCommitteeMedianPriceModels = {
        medianPriceCommittees: updateCommitteeModels,
        medianPriceAcceptors: saveApprovers,
        medianPriceJorPorAcceptors: jorPorDirectors,
        documentId: docId,
        isJorPorComment: isJorPorComment,
      };

      collaboraRef.current?.clickSave();

      setTimeout(async () => {
        const res = await MedianPriceService.updateIsAvailableCommitteeModelAsync(data.id, updateDataModel);

        if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {

          if (sendToApprove) {
            onSubmitToApproveAsync();
          } else {
            toast.success('บันทึกข้อมูลสำเร็จ');
          }

          getMedianPriceByIdAsynce(data.id);
        } else {
          toast.error(res.statusText);
        }

        setShowWaitingForApprove(false)
      }, 500);
    }
  };

  const onCheckQueueApprover = (datas: MedianPriceAcceptorModel[]) => {
    if (datas && data.status === MedianPriceStatus.WaitingForCommitteeApprove) {
      const pendings = datas?.filter((a) => a.isAvailable === false && a.status === MedianPriceStatus.Pending);
      const president = pendings.filter((a) => a.positionOnBoardId !== PositionOnBoardIdEnum.Chairman);

      if (president.length > 0) {
        return president.filter((a) => a.userId === userId).length > 0;
      }

      return (pendings.filter((a) => a.positionOnBoardId === PositionOnBoardIdEnum.Chairman && a.userId === userId).length > 0);
    }
    return false;
  };

  const getFilesAsync = useCallback(async (id: string) => {
    const { data, status } = await MedianPriceService.getAttachmentAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    handleSetFile(data.attachments);

  }, []);

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await MedianPriceService.uploadAttachmentAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        handleSetFile(res.data);

        toast.success('อัปโหลดไฟล์สำเร็จ');
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {
    if (data && data.id) {
      const res = await MedianPriceService.removeAttachmentAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');

        const newArray = [...files];
        const i = newArray.findIndex((i) => i.id === docId);
        newArray.splice(i, 1);
        setFiles(newArray);
      }
    }

  }, [files, data.id]);

  const getTorByIdAsync = async (id: string) => {
    const response = await PreProcurementTorServices.getTorByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
    }
  };

  const getMedianPriceByIdAsynce = async (id: string) => {
    const response = await MedianPriceService.getMedianPriceByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);

      if (
        response.data.status === MedianPriceStatus.None
        || response.data.status === MedianPriceStatus.Draft
        || response.data.status === MedianPriceStatus.Rejected) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  };

  useCallback(async (
    medianPriceId: string,
  ) => {
    const { data: docId, status } = await MedianPriceService.createDocumentAsync(medianPriceId, isDisabledProcess);

    if (status === HttpStatusCode.OK) {
      setData({ ...data, docId: docId });
    }
  }, []);

  const getDocument = useCallback(async (
    medianPriceId: string,
  ) => {
    const {
      data: docId,
      status,
    } = await MedianPriceService.createDocumentAsync(medianPriceId, isDisabledProcess);

    if (status === HttpStatusCode.OK) {
      setDocId(docId);
    }
  }, []);

  const onSubmitToApproveAsync = async () => {
    collaboraRef.current?.clickSave();
    setTimeout(async () => {
      const res = await MedianPriceService.sendCommitteeMedianPriceModelAsync(data.id);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('ส่งอนุมัติ/เห็นชอบไม่สำเร็จ');
        return;
      }

      getMedianPriceByIdAsynce(data.id);
      toast.success('ส่งอนุมัติ/เห็นชอบสำเร็จ');
      setShowWaitingForApprove(false);
    }, 500);
  };

  const onApprove = async () => {
    if (data.id) {
      const res = await MedianPriceService.committeeApproveMedianPriceModelAsync(data.id, approveRemark);
      if (res.status === HttpStatusCode.OK) {
        setApproveRemark('');
        getMedianPriceByIdAsynce(data.id);
        toast.success('เห็นชอบสำเร็จ');
      }
    } else {
      toast.error('เห็นชอบไม่สำเร็จ');
      return;
    }
    setShowApprove(false);
  };

  const onReject = async () => {
    submitForm();
    if (!rejectRemark || rejectRemark.length === 0) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    if (data.id) {
      const res = await MedianPriceService.committeeRejectMedianPriceModelAsync(data.id, rejectRemark);
      if (res.status === HttpStatusCode.OK) {
        setRejectRemark('');
        getMedianPriceByIdAsynce(data.id);
        toast.success('ส่งกลับแก้ไขสำเร็จ');
      }
    } else {
      toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
      return;
    }
    setShowReject(false);
  };

  const handlerOnChangeCheckbox = (value: boolean, index: number) => {
    const data = [...committeeApprovers];
    data[index].isAvailable = value;

    if (!value) {
      data[index].availableRemark = '';
    }

    setCommitteeApprovers(data);
    setIsAvailableAll(data?.filter((a) => a.isAvailable === false).length > 0);
  };

  const handlerOnChangeRemark = (value: string, index: number) => {
    const data = [...committeeApprovers];
    data[index].availableRemark = value;
    setCommitteeApprovers(data);
  };

  const onGetDefaultApprover = async (amount: number, userId?: string) => {
    const processType = data.isConsumableMaterials ? PROCESSTYPE.ApproveMedianProcessStock : PROCESSTYPE.ApproveMedianProcessNonStock;

    const response = await account
      .getDefaultApproverByTopLeve(processType, amount, data.appointPreProcurement.supplyMethod, data.appointPreProcurement.ignorePurchaseOnTable159, departmentCode, userId);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: MedianPriceAcceptorModel[] = [];
      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
        sectionDataGroup.push({
          sectionName: item.sectionName,
          level: item.sectionLevel,
          sectionSequence: item.sectionSequence,
          inRefCode: item.inRefCode,
          sectionNameTh: item.sectionName,
          sectionId: item.sectionId,
        } as sectionGroup);

        approver.push({
          sectionSequence: item.sectionSequence,
          sectionName: item.sectionName,
          sequence: item.sequence,
          userId: item.userId,
          fullName: item.fullName,
          positionName: item.positionName,
          departmentName: item.departmentName,
          sectionInRefCode: item.inRefCode,
          delegateFullName: item.delegateFullName,
          delegateDepartmentName: item.delegateDepartmentName,
          delegateEmployeeCode: item.delegateEmployeeCode,
          delegatePositionName: item.delegatePositionName,
          delegateUserId: item.delegateUserId,
          delegateDateRange: item.delegateDateRange,
        } as MedianPriceAcceptorModel);
      });

      const uniqueNames = sectionDataGroup
        .filter((data, index) => sectionDataGroup
          .findIndex((d) => d.sectionSequence === data.sectionSequence) === index);

      if (data.approvers === undefined || data.approvers === null || data.approvers.length === 0) {
        let inx = 0;
        let sectionSeq = 0;

        approver?.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
          if (sectionSeq !== item.sectionSequence) {
            inx = 1;
            sectionSeq = item.sectionSequence;
          }

          approver[index].sequence = inx;

          inx++;
        });

        setApproverUsers(approver);
        setSections(uniqueNames);
      }
    }
  };

  const onSetSections = (datas: MedianPriceAcceptorModel[]) => {
    const sectionDataGroup: sectionGroup[] = [];

    if (datas) {
      datas.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {

          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: undefined,
            sectionSequence: item.sectionSequence,
            inRefCode: item.sectionInRefCode,
            sectionNameTh: item.sectionName,
            sectionId: '',
          } as sectionGroup);

        });

      const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.sectionSequence === data.sectionSequence) === index);

      setSections(uniqueNames);
    }
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <Title
              text='บุคคล/คณะกรรมการเห็นชอบ'
              className='fs-5 text-primary'
            />
            {openCollapse ? (
              <ArrowCollapse onClick={() => setOpenCollapse(!openCollapse)} openCollapse={openCollapse} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse(true)} openCollapse={openCollapse} />)}
          </div>
          <div className='d-flex gap-2'>
            {
              data.isChanging && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-warning' /></div>
                    {TorEditCancelStatusConstant.Editing}</span>
                </div>
              )
            }
            {
              data.isCanceling && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-danger' /></div>
                    {TorEditCancelStatusConstant.Canceling}</span>
                </div>
              )
            }
            <StatusMedianPrice
              value={data.status}
            />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>

        <Collapse in={openCollapse}>
          <div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>ลำดับ</th>
                      <th style={{ width: 200 }}>ชื่อ-นามสกุล</th>
                      <th style={{ width: 200 }}>ฝ่าย/สำนัก</th>
                      <th style={{ width: 200 }}>ตำแหน่ง</th>
                      <th style={{ width: 200 }}>ตำแหน่งในคณะกรรมการ</th>
                      <th style={{ width: 200 }}>สถานะ</th>
                      <th style={{ width: 200 }}>วันที่เห็นชอบ</th>
                      <th style={{ width: 200 }}>ไม่สามารถปฏิบัติงานได้</th>
                      <th style={{ width: 200 }}>หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {committeeApprovers.sort((a, b) => a.sequence - b.sequence)
                      .map((dataCommittee, index) => (
                        <tr className='text-center'
                          key={index}>
                          <td>{index + 1}</td>
                          <td className='text-start'>{dataCommittee.fullName}</td>
                          <td className='text-start text-wrap'>{dataCommittee.departmentName}</td>
                          <td className='text-start text-wrap'>{dataCommittee.positionName}</td>
                          <td className='text-center'>{dataCommittee.positionOnBoardName}</td>
                          <td><ApproverStatus value={dataCommittee.status} outline /></td>
                          <td>{dataCommittee.acceptDate ? thaiFormatDateWithSlash(dataCommittee.acceptDate) : ''}</td>
                          <td>{
                            (dataCommittee.isChairman) && (
                              <Check
                                className='d-inline-block'
                                value={dataCommittee.isAvailable}
                                onChange={(val) => handlerOnChangeCheckbox(val, index)}
                                disabled={(dataCommittee.userId === userId
                                  || (isDisabled && data.status !== MedianPriceStatus.WaitingForCommitteeApprove)
                                  || dataCommittee.status === 'Approve')}
                              />
                            )
                          }
                          </td>
                          <td>{
                            (dataCommittee.isChairman) && (
                              <Input
                                value={dataCommittee.availableRemark}
                                onChange={(val) => handlerOnChangeRemark(val, index)}
                                rule={{ required: false }}
                                disabled={!dataCommittee.isAvailable || (isDisabled && data.status !== MedianPriceStatus.WaitingForCommitteeApprove)}
                              />
                            )
                          }
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>
      <Card className='mt-3'>
        <Title text='ตัวอย่างเอกสาร'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}>
            <div className='mt-3'>
              <Collabora
                docId={docId}
                docName='doc-step-2'
                ref={collaboraRef}
                readonly={isDisabledProcess} />
            </div>
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              id={data.id}
              disabled={!data.id}
            />
          </Col>
        </Row>
      </Card>
      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <div>
          {/* {
            (data.status === MedianPriceStatus.WaitingForCommitteeApprove) && (
              <>
                <Button
                  className='me-2 px-3'
                  onClick={() => setShowRecall(true)}
                  variant='danger'
                >
                  <FaUndo className='mx-2 pb-1 fs-5' />
                  เรียกคืนแก้ไข
                </Button>
              </>

            )
          } */}
          {
            (isCommittee && (data.status === MedianPriceStatus.Draft
              || data.status === MedianPriceStatus.Rejected
              || data.status === MedianPriceStatus.CommitteeRejected
              || data.status === MedianPriceStatus.WaitingForCommitteeApprove)
            ) && (
              <>
                <ButtonCustom
                  onClick={() => onSubmitAsync(false)}
                  text="บันทึก"
                />
              </>
            )
          }
          {
            (isCommittee && isAvailableAll && ((data.status === MedianPriceStatus.Draft || data.status === MedianPriceStatus.Rejected || data.status === MedianPriceStatus.CommitteeRejected))
            ) && (
              <>
                <ButtonCustom
                  onClick={() => setShowWaitingForApprove(true)}
                  icon="checked"
                  text="ส่งอนุมัติ/เห็นชอบ"
                />
              </>
            )
          }
          {
            (isQueueApprover && data.status === MedianPriceStatus.WaitingForCommitteeApprove) && (
              <>
                <ButtonCustom
                  onClick={() => setShowReject(true)}
                  variant='danger'
                  icon="undo"
                  text="ส่งกลับแก้ไข"
                />

                <ButtonCustom
                  onClick={() => setShowApprove(true)}
                  variant='success'
                  icon="checked"
                  text="เห็นชอบ"
                />
              </>

            )
          }
        </div>
        {
          ((!isAvailableAll && (data.status === MedianPriceStatus.Draft || data.status === MedianPriceStatus.Rejected))
            || (data.status !== MedianPriceStatus.WaitingForCommitteeApprove
              && data.status !== MedianPriceStatus.Rejected
              && data.status !== MedianPriceStatus.Draft)) ? (
            <ButtonCustom
              onClick={onClickNext}
              text="ถัดไป"
            />
          ) : (<div style={{ width: 120 }} />)
        }

      </div>
      <HistoryModal
        title='ประวัติการใช้งานแบบร่างขอบเขตของงาน (TOR)'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={data.id}
      />
      <Modal
        show={showReject}
        size='lg'
        onHide={() => setShowReject(!showReject)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRejectRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowReject(!showReject)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                variant='danger'
                onClick={() => onReject()}
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      {/* <UserApproveModal
        show={showCommitteesApprovers}
        onHide={() => setShowCommitteesApprovers(!showCommitteesApprovers)}
        onSelectItem={onSelectEmpApprover}
      /> */}
      {/* <UserApproveModal
        show={showCommitteesMiddlePriceApprovers}
        onHide={() => setShowCommitteesMiddlePriceApprovers(!showCommitteesMiddlePriceApprovers)}
        onSelectItem={onSelectEmpMiddlePriceApprover}
      /> */}
      <Modal
        show={showWaitingForApprove}
        size='lg'
        onHide={() => setShowWaitingForApprove(!showWaitingForApprove)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งอนุมัติ/เห็นชอบ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitingForApprove(!showWaitingForApprove)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApprove}
        size='lg'
        onHide={() => setShowApprove(!showApprove)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ให้ความเห็นชอบ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                rule={{ required: false }}
                onChange={(val) => setApproveRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApprove(!showApprove)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onApprove()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      {/* <Modal
        show={showApproveMiddle}
        size="lg"
        onHide={() => setShowApproveMiddle(!showApproveMiddle)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              คุณต้องการอนุมัติหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant="outline-primary"
                onClick={() => setShowApproveMiddle(!showApproveMiddle)}>
                ยกเลิก
              </Button>
              <Button
                variant="primary"
                onClick={() => onApprove(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      /> */}
      <Modal
        show={showRecall}
        size='lg'
        onHide={() => setShowRecall(!showRecall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowRecall(!showRecall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
              // onClick={onRecall}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step2;
