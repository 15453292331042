import {
    useEffect,
    useState,
} from 'react';
import { Badge } from 'react-bootstrap';

interface Props {
    value: string;
    type?: string;
}

const CONTRACT_AUDIT = [
    {
        value: 'Draft',
        color: '#BEBEBE',
        label: 'แบบร่าง',
    },
    {
        value: 'Approved',
        color: '#1BA345',
        label: 'เห็นชอบ/อนุมัติ',
    },
    {
        value: 'Rejected',
        color: '#DE3E44',
        label: 'ส่งกลับแก้ไข',
    },
    {
        value: 'Pending',
        color: '#FBBD01',
        label: 'รอเห็นชอบ/อนุมัติ',
    },
];

const JORPOR04 = [
    {
        value: 'Draft',
        color: '#BEBEBE',
        label: 'แบบร่าง',
    },
    {
        value: 'DraftTor',
        color: '#FFC895',
        label: 'อยู่ระหว่างร่าง TOR',
    },
    {
        value: 'WaitingForCommitteeApprove',
        color: '#FBBD01',
        label: 'อยู่ระหว่าง คกก. เห็นชอบ',
    },
    {
        value: 'WaitingForDirectorJorPorAssign',
        color: '#FBBD01',
        label: 'อยู่ระหว่างรอ ผอ.จพ. มอบหมาย',
    },
    {
        value: 'WaitingForAssignResponsible',
        color: '#FBBD01',
        label: 'อยู่ระหว่างรอมอบหมายผู้รับผิดชอบ',
    },
    {
        value: 'DraftSectionApprover',
        color: '#FBBD01',
        label: 'อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ',
    },
    {
        value: 'WaitingForApprove',
        color: '#FF850D',
        label: 'อยู่ระหว่างเห็นชอบ/อนุมัติ',
    },
    {
        value: 'Approved',
        color: '#1BA345',
        label: 'เห็นชอบ/อนุมัติ',
    },
    {
        value: 'Cancelled',
        color: '#DE3E44',
        label: 'ยกเลิก',
    },
    {
        value: 'Rejected',
        color: '#DE3E44',
        label: 'ส่งกลับแก้ไข',
    },
    {
        value: 'Accepted',
        color: '#1BA345',
        label: 'เห็นชอบ/อนุมัติ',
    },
    {
        value: 'noImplement',
        color: '#BEBEBE',
        label: 'ยังไม่ดำเนินการ',
    },
    {
        value: 'WaitingAcceptor',
        color: '#FBBD01',
        label: 'รอเห็นชอบ',
    },
    {
        value: 'AssignJorPor',
        color: '#5946ff',
        label: 'มอบหมายสำเร็จ',
    },
    {
        value: 'Pending',
        color: '#FBBD01',
        label: 'รออนุมัติ',
    },
    {
        value: 'WaitingAccept',
        color: '#FBBD01',
        label: 'รออนุมัติ',
    },
    {
        value: 'Reject',
        color: '#DE3E44',
        label: 'ส่งกลับแก้ไข',
    },
    {
        value: 'Announcement',
        color: '#FF850D',
        label: 'ประกาศเผยแพร่',
    },
];

const JORPOR05 = [
    {
        value: 'Draft',
        color: '#BEBEBE',
        label: 'แบบร่าง',
    },
    {
        value: 'WaitingAccept',
        color: '#FBBD01',
        label: 'อยู่ระหว่างเห็นชอบ/อนุมัติ',
    },
    {
        value: 'Accepted',
        color: '#1BA345',
        label: 'เห็นชอบ/อนุมัติ',
    },
    {
        value: 'Reject',
        color: '#DE3E44',
        label: 'ส่งกลับแก้ไข',
    },
    {
        value: 'None',
        color: '#BEBEBE',
        label: 'ยังไม่ดำเนินการ',
    },
];

function StatusDetail(props: Props,) {
    const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

    useEffect(() => {
        if (props.value && props.type === TypeStatus.ContractAudit) {
            const status = CONTRACT_AUDIT.find((s) => s.value === props.value);

            if (status) {
                setStatus(status);
            }
        }
        if (props.value && props.type === TypeStatus.JorPor04) {
            const status = JORPOR04.find((s) => s.value === props.value);

            if (status) {
                setStatus(status);
            }
        }
        if (props.value && props.type === TypeStatus.JorPor05) {
            const status = JORPOR05.find((s) => s.value === props.value);

            if (status) {
                setStatus(status);
            }
        }
    }, [props.value]);
    return (
        <>
            {props.value
                ? (
                    <div className='tor-status'
                        style={{ backgroundColor: status?.color }}>
                        {status?.label}
                    </div>
                ) : null}
        </>
    );
}

enum TypeStatus {
    ContractAudit = 'ContractAudit',
    JorPor04 = 'JorPor04',
    JorPor05 = 'JorPor05'
}

export {
    StatusDetail,
    TypeStatus,
};
