import {
  Card,
  DatePicker,
  Input,
  InputTextArea,
  Table,
  TorStatus,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import { HistoryModal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaHistory,
  FaSave,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { dataHistory } from '../data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step1({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <div className="d-flex justify-content-between mb-3">
          <Title text="บันทึกส่งมอบ" className="fs-5 text-primary" /><div className="d-flex gap-2">
            <TorStatus
              value="Draft" />
            <Button
              className="px-3"
              onClick={() => setShow(true)}
              variant="outline-primary"
            >
              <FaHistory className="me-3" />ประวัติการแก้ไข
            </Button>
          </div>
        </div>
        <Row>
          <Col sm={12} xl={3}>
            <p>กำหนดวันส่งมอบงาน : <span className='ms-3'>19-06-2566</span></p>
          </Col>
          <Col sm={12} xl={3}>
            <p>กำหนดวันส่งมอบงาน  : <span className='ms-3'>165 วัน</span></p>
          </Col>
        </Row>
        <Row>
          <Col sm={12} xl={3}>
            <DatePicker label='วันที่ส่งมอบงาน' value={new Date()} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xl={6}>
            <InputTextArea label='หมายเหตุการส่งมอบ' />
          </Col>
        </Row>
        <p>สิ่งที่ต้องส่งมอบ</p>
        <Table total={1} hidePagination className='mt-3'>
          <thead>
            <tr className='text-center'>
              <th style={{ width: 10 }}>ลำดับที่</th>
              <th style={{ minWidth: 50 }}>รายละเอียดบ</th>
              <th style={{ width: 50 }}>จำนวน</th>
            </tr>
          </thead>
          <tbody>
            <tr className='text-center'>
              <td>1</td>
              <td><Input value='ส่งมอบแผนดำเนินการโครงการทั้งหมด' disabled /></td>
              <td ><Input value='1' disabled textEnd /></td>
            </tr>
          </tbody>
        </Table>
      </Card>

      <Card className="mt-3">
        <div className="d-flex justify-content-between mb-3">
          <Title text="งวดเงิน" className="fs-5 text-primary" />
        </div>
        <Table total={1} hidePagination className='mt-3'>
          <thead>
            <tr className='text-center'>
              <th style={{ width: 10 }}>งวดที่</th>
              <th style={{ minWidth: 50 }}>จำนวน (%)</th>
              <th style={{ width: 50 }}>จำนวนเงิน</th>
            </tr>
          </thead>
          <tbody>
            <tr className='text-center'>
              <td>1</td>
              <td className='text-center'>50%</td>
              <td className='text-end'>500,000.00</td>
            </tr>
          </tbody>
        </Table>
        <p className='mt-2'>จำนวนเงินค่าปรับ : <span className='ms-3'>0</span> <span className='ms-3'>จำนวนเงินที่จ่ายคงเหลือ : </span><span className='ms-3'>500,000.00</span></p>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="outline-primary"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <div>
          <Button
            variant="outline-primary"
            className="me-2 px-3"
          >
            <FaSave className="ms-2 pb-1 fs-5" />
            บันทึก
          </Button>
          <Button
            className="me-2 px-3"
          >
            บันทึกส่งมอบ
          </Button>
        </div>
        <Button
          variant="outline-primary"
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
      <HistoryModal
        show={show}
        onHide={() => setShow(!show)}
        data={dataHistory}
      />
    </div>
  );
}

export default Step1;
