import {
  Card,
  Table,
  TorStatus,
  TorStatusDetail,
  UploadFile,
  ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import {
  HistoryModal,
  SearchNameModal,
} from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Row,
} from 'react-bootstrap';
import { BsArrowDownCircle } from 'react-icons/bs';
import {
  FaHistory,
  FaPlus,
  FaSave,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { dataHistory } from '../data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step3({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [showSearchNameModal, setModalSearchNameModal] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  return (
    <div className="document">

      <Card className="mt-3">
        <div className="d-flex mb-4 align-items-center justify-content-end">
          <TorStatus
            value="Draft" />
          <Button
            onClick={() => setShowHistory(!showHistory)}
            className='ms-2'
            variant="outline-primary">
            <FaHistory className="me-1" />
            ประวัติการใช้งาน
          </Button>
        </div>
        <div className="d-flex mb-4 align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <Title text="แจ้งรับทราบ" className="fs-5 text-primary" />
            {openCollapse1 ? (
              <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />
            )}
          </div>
          <Button
            variant="outline-primary"
            onClick={() => setModalSearchNameModal(!showSearchNameModal)}>
            <FaPlus />เพิ่มรายชื่อ
          </Button>
        </div>
        <Collapse in={openCollapse1}>
          <div>
            <Table total={1} hidePagination>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>ลำดับ</th>
                  <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
                  <th style={{ width: 250 }}>ฝ่าย/สำนัก</th>
                  <th style={{ width: 250 }}>ตำแหน่ง</th>
                  <th style={{ width: 250 }}>สถานะ</th>
                  <th style={{ width: 250 }}>วันทีรับทราบ</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 3 }).map((_, index) => (
                  <tr className='text-center'>
                    <td>{index + 1}</td>
                    <td>สมศรี แดนไทย</td>
                    <td>สมศรี แดนไทย</td>
                    <td>ผู้ช่วยกรรมการ</td>
                    <td><TorStatusDetail value='Approved' /></td>
                    <td>17/05/2566</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Collapse>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="outline-primary"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <div>
          <Button
            variant="outline-primary"
            className="me-2 px-3"
          >
            <FaSave className="ms-2 pb-1 fs-5" />
            บันทึก
          </Button>
          <Button
            className="me-2 px-3"
          >
            บันทึกแจ้งรับทราบ
          </Button>
        </div>
        <Button
          variant="outline-primary"
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
      <HistoryModal
        show={show}
        onHide={() => setShow(!show)}
        data={dataHistory}
      /> <SearchNameModal
        show={showSearchNameModal}
        onHide={() => setModalSearchNameModal(!showSearchNameModal)}
        data={[]}
      />

    </div>
  );
}

export default Step3;
