import CreateContractMangementDetail from '../CM02/Detail';

export default function ContractAgreementEditDetail() {

    return (
        <>
            <CreateContractMangementDetail />
        </>
    );
}
