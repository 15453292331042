import {
    Card,
    ContractAgreementStatus,
    DatePicker,
    Input,
    Table,
    Modal,
    ButtonCustom,
    InputTextArea,
    SearchBuNameModal,
    ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import { ContractAgreementTypeAcceptor } from 'constant/ContractStatus';
import { JorPorCode } from 'constant/basicInformation';
import { UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import {
    ContractAgreementAcceptorModel,
    ContractAgreementDocumentArchivedModel,
    ContractAgreementModel,
    CreateEditingContractAgreementModel,
    JorPorCommentModel,
    UpdateAcceptorStep4Model,
    UpdateContractDateStep5Model,
    UpdateDocBoxNumberStep4Model,
} from 'models/CM/CM02Models';
import { sectionApproverModel } from 'models/delegateModel';
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Button,
    Col,
    Collapse,
    Modal as ModalBT,
    Row,
} from 'react-bootstrap';
import {
    FaPlus,
    FaTrashAlt,
    FaUndo,
} from 'react-icons/fa';
import { shareValue } from 'services';
import {
    HttpStatusCode,
    useAppContext,
} from 'utils';
import { AcceptorStatus, ProgramType } from 'utils/constants/PlanEnum';
import toast from 'utils/toast';

interface Props {
    data: any;
    status?: string;
    isJorPorDirector: boolean;
    isAssignee: boolean;
    isResponsible: boolean;
    program?: ProgramType;
    onResponsibleDataChange: (data: any[]) => void;
    onAssigneeDataChange: (data: any[]) => void;
    onCommentDescriptionChange: (data: string) => void;
}

export default function JorPorComment(props: Props) {
    const [data, setData] = useState<JorPorCommentModel>({} as JorPorCommentModel);
    const [responsibles, setResponsibles] = useState<ContractAgreementAcceptorModel[]>(props.data.responsibles);
    const [assignees, setAssignees] = useState<ContractAgreementAcceptorModel[]>(props.data.assignees);
    const { userId, departmentCode } = useAppContext();
    const [isResponsible, setIsResponsible] = useState<boolean>(false);
    const [userTotal, setUserTotal] = useState<number>(0);
    const [showAssigneeModal, setShowAssigneeModal] = useState<boolean>(false);
    const [showResponsibleModal, setShowResponsibleModal] = useState<boolean>(false);
    const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
    const [commentDescription, setCommentDescription] = useState('');

    useEffect(() => {
        if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
            getUserData(undefined, departmentCode, undefined);
        } else {
            getUserData();
        }
    }, [departmentCode]);

    const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
        const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
        const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

        if (status === HttpStatusCode.OK) {
            setUsers(data.data);
            setUserTotal(data.totalRecords);
        }
    };

    const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
        await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
    };

    const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
        await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
    };

    useEffect(() => {
        setIsResponsible(props.isResponsible);
    }, [props.isResponsible]);

    useEffect(() => {
        if (props.data) {
            setData(props.data);
        }
    }, [props.data]);

    useEffect(() => {

        props.onResponsibleDataChange(responsibles);
    }, [responsibles]);

    useEffect(() => {

        props.onAssigneeDataChange(assignees);
    }, [assignees]);

    useEffect(() => {

        props.onCommentDescriptionChange(commentDescription);
    }, [commentDescription]);

    useEffect(() => {

        if (data) {

            if (data.commentDescription) {
                setCommentDescription(data.commentDescription);
            }

            if (data.assignees) {
                setAssignees(data.assignees);
            }

            if (data.responsibles) {
                setResponsibles(data.responsibles);
            }
        }

    }, [data, userId]);

    const handlerRemoveAssignee = (index: number) => {
        const temp: ContractAgreementAcceptorModel[] = [...assignees];
        temp.splice(index, 1);
        setAssignees(temp);
    };

    const handlerRemoveResponsible = (index: number) => {
        const temp: ContractAgreementAcceptorModel[] = [...responsibles];
        temp.splice(index, 1);
        setResponsibles(temp);
    };

    const onSelectAssignee = (empId: string, name: string, position: string, department: string, sectionSequence: number, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
        delegatePositionName?: string, delegateAnnotation?: string, delegateDateRange?: string) => {
        if (assignees && assignees.filter(x => x.userId === empId).length > 0) {
            toast.warn('ไม่สามารถเลือกผอ.จพ. มอบหมายซ้ำ');
        } else {

            const newData: ContractAgreementAcceptorModel = {
                id: undefined,
                sectionSequence: sectionSequence!,
                sectionName: '',
                userId: empId,
                sequence: assignees.filter((x) => x.sectionSequence === sectionSequence).length + 1,
                fullName: name,
                isAvailable: false,
                availableRemark: '',
                departmentName: department,
                type: ContractAgreementTypeAcceptor.Assignee,
                status: AcceptorStatus.DRAFT,
                seqKey: `${sectionSequence?.toString()}${(assignees.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
                sectionInRefCode: '',
                acceptDate: undefined,
                acceptRemark: '',
                positionName: position,
                rejectDate: undefined,
                rejectRemark: '',
                delegateUserId: delegateUserId,
                delegateFullName: delegateFullName,
                delegateDepartmentName: delegateDepartmentName,
                delegatePositionName: delegatePositionName,
                delegateEmployeeCode: delegateEmployeeCode,
            };

            setAssignees((assignees) => [...assignees, newData]);
        }
    };

    const onSelectResponsible = (empId: string, name: string, position: string, department: string, sectionSequence: number, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
        delegatePositionName?: string, delegateAnnotation?: string, delegateDateRange?: string) => {

        if (responsibles && responsibles.filter(x => x.userId === empId).length > 0) {
            toast.warn('ไม่สามารถเลือกผู้รับผิดชอบซ้ำ');
        } else {

            const newData: ContractAgreementAcceptorModel = {
                id: undefined,
                sectionSequence: sectionSequence!,
                sectionName: '',
                userId: empId,
                sequence: responsibles.filter((x) => x.sectionSequence === sectionSequence).length + 1,
                fullName: name,
                isAvailable: false,
                availableRemark: '',
                departmentName: department,
                type: ContractAgreementTypeAcceptor.Responsible,
                status: AcceptorStatus.DRAFT,
                seqKey: `${sectionSequence?.toString()}${(responsibles.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
                sectionInRefCode: '',
                acceptDate: undefined,
                acceptRemark: '',
                positionName: position,
                rejectDate: undefined,
                rejectRemark: '',
                delegateUserId: delegateUserId,
                delegateFullName: delegateFullName,
                delegateDepartmentName: delegateDepartmentName,
                delegatePositionName: delegatePositionName,
                delegateEmployeeCode: delegateEmployeeCode,
            };

            setResponsibles((responsibles) => [...responsibles, newData]);
        }
    };

    return (
        <div className='document'>
            <Card className='mt-3'>
                <div className='d-flex mb-4 align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <Title text='ผอ.จพ.'
                            className='fs-5 text-primary' />
                    </div>
                </div>
                <div>
                    <Table
                        hidePagination
                        className='mt-3 step-bar'
                        notResponse
                    >
                        <thead>
                            <tr>
                                <th style={{ width: '5%' }}>ลำดับ</th>
                                <th className='text-start'
                                    style={{ width: '30%' }}>ชื่อ-นามสกุล
                                </th>
                                <th className='text-start'
                                    style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                                </th>
                                <th style={{ minHeight: 100, width: '25%' }} />
                            </tr>
                        </thead>
                        <tbody>
                            {data.jorPorDirectors?.sort((a, b) => a.sequence - b.sequence)
                                .map((data, i) => (
                                    <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                                        key={data.id}>
                                        <td className='text-center'>{i + 1}</td>
                                        <td>
                                            {data.fullName}
                                            <p className='m-0 department'>
                                                {data.positionName}
                                            </p>
                                        </td>
                                        <td className='text-left'>
                                            {data.delegateFullName &&
                                                <>
                                                    {data.delegateFullName}
                                                    <p className='m-0 department'>
                                                        {data.delegatePositionName}
                                                    </p>
                                                </>
                                            }
                                        </td>
                                        <td />
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </Card>
            <Card className='mt-3'>
                <div className='d-flex mb-4 align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <Title text='ผอ.จพ. มอบหมาย'
                            className='fs-5 text-primary' />
                    </div>
                </div>
                <div>
                    {
                        (props.isJorPorDirector) && (
                            <div className='d-flex justify-content-end'>
                                <Button
                                    className='d-flex align-items-center gap-2'
                                    variant='outline-primary'
                                    onClick={() => setShowAssigneeModal(true)}
                                >
                                    <FaPlus />เพิ่มรายชื่อ
                                </Button>
                            </div>
                        )
                    }
                    <Table
                        hidePagination
                        className='mt-3 step-bar'
                        notResponse
                    >
                        <thead>
                            <tr>
                                <th style={{ width: '5%' }}>ลำดับ</th>
                                <th className='text-start'
                                    style={{ width: '30%' }}>ชื่อ-นามสกุล
                                </th>
                                <th className='text-start'
                                    style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                                </th>
                                <th style={{ minHeight: 100, width: '25%' }} />
                            </tr>
                        </thead>
                        <tbody>
                            {assignees?.sort((a, b) => a.sequence - b.sequence)
                                .map((item, i) => (
                                    <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                                        key={item.id}>
                                        <td className='text-center'>{i + 1}</td>
                                        <td>
                                            {item.fullName}
                                            <p className='m-0 department'>
                                                {item.positionName}
                                            </p>
                                        </td>
                                        <td className='text-left'>
                                            {item.delegateFullName &&
                                                <>
                                                    {item.delegateFullName}
                                                    <p className='m-0 department'>
                                                        {item.delegatePositionName}
                                                    </p>
                                                </>
                                            }
                                        </td>
                                        <td className='text-end'>
                                            {(props.isJorPorDirector) &&
                                                <Button
                                                    variant='danger'
                                                    onClick={() => handlerRemoveAssignee(i)}
                                                >
                                                    <FaTrashAlt />
                                                </Button>}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </Card>

            <Card className='mt-3'>
                <>
                    <div className='d-flex mb-4 align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <Title text='ผู้รับผิดชอบ'
                                className='fs-5 text-primary' />
                        </div>
                    </div>
                    <div>
                        <div className='d-flex justify-content-end'>
                            {
                                (props.isAssignee) && (
                                    <Button
                                        className='d-flex align-items-center gap-2'
                                        variant='outline-primary'
                                        onClick={() => setShowResponsibleModal(true)}
                                    >
                                        <FaPlus />เพิ่มรายชื่อ
                                    </Button>
                                )
                            }
                        </div>
                        <Table
                            hidePagination
                            className='mt-3 step-bar'
                            notResponse
                        >
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }}>ลำดับ</th>
                                    <th className='text-start'
                                        style={{ width: '30%' }}>ชื่อ-นามสกุล
                                    </th>
                                    <th className='text-start'
                                        style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                                    </th>
                                    <th style={{ minHeight: 100, width: '25%' }} />
                                </tr>
                            </thead>
                            <tbody>
                                {responsibles?.sort((a, b) => a.sequence - b.sequence)
                                    .map((item, i) => (
                                        <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                                            key={item.id}>
                                            <td className='text-center'>{i + 1}</td>
                                            <td>
                                                {item.fullName}
                                                <p className='m-0 department'>
                                                    {item.positionName}
                                                </p>
                                            </td>
                                            <td className='text-left'>
                                                {item.delegateFullName &&
                                                    <>
                                                        {item.delegateFullName}
                                                        <p className='m-0 department'>
                                                            {item.delegatePositionName}
                                                        </p>
                                                    </>
                                                }
                                            </td>
                                            <td className='text-end'>
                                                {(props.isAssignee) &&
                                                    <Button
                                                        variant='danger'
                                                        onClick={() => handlerRemoveResponsible(i)}
                                                    >
                                                        <FaTrashAlt />
                                                    </Button>}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </>
            </Card>
            <Card className='mt-3'>
                <div className='d-flex mb-4 align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <Title text='ความเห็น จพ.'
                            className='fs-5 text-primary' />
                    </div>
                </div>
                <InputTextArea
                    label='ความเห็นเพิ่มเติม'
                    value={commentDescription}
                    onChange={val => setCommentDescription(val)}
                    rule={{ required: true }}
                    disabled={(data.status !== ContractAgreementStatus.DraftSectionApprover
                        && data.status !== ContractAgreementStatus.Rejected) || !isResponsible} />
            </Card>

            <SearchBuNameModal
                total={userTotal}
                show={showAssigneeModal}
                onHide={() => setShowAssigneeModal(!showAssigneeModal)}
                onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange) => onSelectAssignee(id, name, position!, department!, assignees.length + 1, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange)}
                data={users}
                onSearch={onSearchUserAsync}
                onPageChange={onPageChangeAsync}
                departmentCode={departmentCode}
                departmentDisabled={departmentCode !== JorPorCode.CODE}
            />

            <SearchBuNameModal
                total={userTotal}
                show={showResponsibleModal}
                onHide={() => setShowResponsibleModal(!showResponsibleModal)}
                onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange) => onSelectResponsible(id, name, position!, department!, responsibles.length + 1, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange)}
                data={users}
                onSearch={onSearchUserAsync}
                onPageChange={onPageChangeAsync}
                departmentCode={departmentCode}
                departmentDisabled={departmentCode !== JorPorCode.CODE}
            />
        </div>
    );
}