import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Currency,
  DatePicker,
  Input,
  Radio,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import { CollateralModel, ContractAgreementModel, DefectsWarrantyModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';
import { el } from 'date-fns/locale';
import { ValidatePattern } from 'utils';

interface IDefectWarrantyProps {
  title: string;
  contractTemplateCode?: string;
  offerTotalAmountVat: number;
  collateral: CollateralModel;
  bankDDL: ItemModel[];
  periodTypeDDL: ItemModel[];
  periodConditionDDL: ItemModel[];
  performanceBondTypeDDL: ItemModel[];
  isDisabled?: boolean;
  onCollateralChange: (value: CollateralModel) => void;
}

const INFO = [
  { label: 'มีหลักประกัน', value: 'true' },
  { label: 'ไม่มีหลักประกัน', value: 'false' },
];

const PerformanceBondType = {
  cash: '0c1d6674-9282-8c27-7d04-4c22280b6baf',
  guaranteeElectronics: '474c0cbb-0496-6331-07c8-91f84a68e510',
  guaranteeBank: '02b37b8a-bbec-705f-8ec4-05074b3af398',
  guaranteeCompany: 'c55a61cf-e00d-ae94-930a-c3013fd73ca0',
  governmentBonds: '23b43be8-d1a4-a64f-fe2c-0025acda6ad9',
  cheque: '03101d8f-d472-d51c-0aed-b173c45073e6',
}

const fields = [
  {
    code: 'CF01',
    isSubmitCollateral: 'ผู้รับจ้างต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาจ้างตามสัญญา)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF02',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาทั้งหมดตามสัญญา)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF03',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF04',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาซื้อขายคอมพิวเตอร์)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF05',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาโปรแกรมคอมพิวเตอร์)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF06',
    isSubmitCollateral: 'ผู้เช่าต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของค่าเช่าคอมพิวเตอร์ทั้งหมดตามสัญญา)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF07',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาจ้างบำรุงรักษา)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF08',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาทั้งหมดตามสัญญา)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF09',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาค่าจ้าง)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF10',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาค่าจ้าง)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF11',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาทั้งหมดตามสัญญา)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF12',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาสิ่งของทั้งหมด)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF13',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF14',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาค่าจ้าง)',
    amount: 'จำนวนเงิน',
  },
  {
    code: 'CF15',
    isSubmitCollateral: 'ผู้ขายต้องยื่นหลักประกันสัญญาหรือไม่',
    type: 'ประเภทหลักประกัน',
    percent: 'ร้อยละ (ของราคาค่าจ้างบำรุงรักษา)',
    amount: 'จำนวนเงิน',
  },
];

const SubmitCollateralItems = [
  {
    label: 'ยื่น',
    value: 'true',
  },
  {
    label: 'ไม่ยื่น',
    value: 'false',
  },
];

export function CollateralDetail(props: IDefectWarrantyProps) {
  const [collateral, setCollateral] = useState<CollateralModel>(props.collateral);
  const [isReferenceNumber, setIsReferenceNumber] = useState(false);
  const [isBank, setIsBank] = useState(PerformanceBondType.guaranteeBank === collateral?.type || collateral?.type === PerformanceBondType.guaranteeCompany || collateral?.type === PerformanceBondType.guaranteeElectronics);
  const [isDisabledCollateral, setIsDisabledCollateral] = useState(!props.collateral?.active);

  useEffect(() => {
    if (collateral) {
      props.onCollateralChange(collateral);
    }
  }, [collateral]);

  useEffect(() => {
    if (props.collateral && props.collateral.active && props.collateral.isSubmitCollateral) {
      setCollateral(props.collateral)
    } else {
      const collateral = {
        isSubmitCollateral: false,
        active: false,
        type: undefined,
        amount: undefined,
        percent: undefined,
        fineType: undefined,
        finePercent: undefined,
        bankId: undefined,
        bankBranch: undefined,
        bankAccountNumber: undefined,
        bankCollateralEndDate: undefined,
        bankCollateralStartDate: undefined,
        referenceNumber: undefined,
        changeCollateralIn: undefined,
        changeCollateralInUnit: undefined,
      } as CollateralModel;
      setCollateral(contractData => ({
        ...contractData,
        collateral,
      }));
    }

  }, [props.collateral]);

  const onChangeCollateralActive = (val: string) => {
    setCollateral({ ...collateral, active: val === 'true', isSubmitCollateral: val === 'true' });
    if (val === 'false') {
      setIsDisabledCollateral(true);
      setIsBank(false);
    } else {
      setIsDisabledCollateral(false);
    }
  };

  const onChangeCollateralInfoType = (val: string) => {
    setIsReferenceNumber(false);
    setIsBank(false);

    if (val === PerformanceBondType.cash || val === PerformanceBondType.cheque || val === PerformanceBondType.governmentBonds) {
      setIsReferenceNumber(true);
      setCollateral({
        ...collateral,
        changeCollateralIn: undefined,
        bankId: undefined,
        bankBranch: undefined,
        bankAccountNumber: undefined,
        bankCollateralStartDate: undefined,
        bankCollateralEndDate: undefined,
        type: val
      });
      return;
    }

    if (val === PerformanceBondType.guaranteeBank || val === PerformanceBondType.guaranteeCompany || val === PerformanceBondType.guaranteeElectronics) {
      setIsBank(true);
      setCollateral({
        ...collateral,
        changeCollateralIn: 15,
        referenceNumber: undefined,
        type: val
      });

      return;
    }
  };

  const onChangePercent = useCallback((val: number) => {
    setCollateral(prevState => ({
      ...prevState,
      percent: val,
      amount: (props.offerTotalAmountVat * (val / 100)),
    }));
  }, []);

  const onChangeAmount = useCallback((val: number) => {
    setCollateral(prevState => ({
      ...prevState,
      amount: val,
      percent: ((val / props.offerTotalAmountVat) * 100),
    }));
  }, []);

  return (
    <Card className='mt-3'>
      <Title text={`สัญญา${props.title}`}
        className='fs-5 text-primary' />
      <>
        {
          fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
            <div style={{ paddingLeft: 40 }}>
              {
                data.isSubmitCollateral &&
                (
                  <Col sm={12}
                    md={6}>
                    <Radio
                      label={data.isSubmitCollateral}
                      dflex
                      items={SubmitCollateralItems}
                      value={collateral && collateral?.active ? 'true' : 'false'}
                      name='isSubmitCollateralInfo'
                      onChange={(val) => onChangeCollateralActive(val?.toString())}
                      rule={{ required: true }}
                      disabled={props.isDisabled} />
                  </Col>
                )
              }
              {
                data.type &&
                (
                  <Row>
                    <Col sm={12}
                      lg={5}>
                      <Selector label={data.type}
                        items={props.performanceBondTypeDDL}
                        value={collateral?.type}
                        rule={{ required: !isDisabledCollateral }}
                        onChange={(val) => onChangeCollateralInfoType(val)}
                        disabled={props.isDisabled || isDisabledCollateral} />
                    </Col>
                  </Row>
                )
              }
              <Row>
                {isReferenceNumber &&
                  <Col sm={12}
                    lg={3}>
                    <Input label='เลขที่อ้างอิง'
                      value={collateral?.referenceNumber}
                      rule={{ required: !isDisabledCollateral }}
                      onChange={(value) => setCollateral({ ...collateral, referenceNumber: value })}
                      disabled={props.isDisabled || isDisabledCollateral} />
                  </Col>}
                {isBank &&
                  <>
                    <Col sm={12}
                      lg={4}>
                      <Selector label='ธนาคาร'
                        items={props.bankDDL}
                        value={collateral?.bank}
                        rule={{ required: true }}
                        onChange={(value) => setCollateral({ ...collateral, bank: value })}
                        disabled={props.isDisabled || isDisabledCollateral} />
                    </Col>
                    <Col sm={12}
                      lg={4}>
                      <Input label='สาขาธนาคาร'
                        value={collateral?.bankBranch}
                        onChange={(value) => setCollateral({ ...collateral, bankBranch: value })}
                        disabled={props.isDisabled || isDisabledCollateral} />
                    </Col>
                    <Col sm={12}
                      lg={4}>
                      <Input label='เลขที่'
                        value={collateral?.bankAccountNumber}
                        rule={{ required: true }}
                        onChange={(value) => setCollateral({ ...collateral, bankAccountNumber: value })}
                        disabled={props.isDisabled || isDisabledCollateral} />
                    </Col>
                    <Col sm={12}
                      lg={4}>
                      <DatePicker label='วันเริ่มต้นของหนังสือค้ำประกัน'
                        value={collateral?.bankCollateralStartDate}
                        rule={{ required: true }}
                        onChange={(value) => setCollateral({ ...collateral, bankCollateralStartDate: value })}
                        disabled={props.isDisabled || isDisabledCollateral} />
                    </Col>
                    <Col sm={12}
                      lg={4}>
                      <DatePicker label='วันที่สิ้นสุดของหนังสือค้ำประกัน'
                        value={collateral?.bankCollateralEndDate}
                        rule={{ required: true }}
                        onChange={(value) => setCollateral({ ...collateral, bankCollateralEndDate: value })}
                        disabled={props.isDisabled || isDisabledCollateral} />
                    </Col>
                  </>
                }

                <>
                  {
                    fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
                      <div>
                        {
                          data.amount &&
                          (
                            <Col sm={12}
                              lg={3}>
                              <Currency label={data.amount}
                                value={collateral?.amount}
                                rule={{ required: !isDisabledCollateral! }}
                                onChange={(value) => onChangeAmount(Number(value))}
                                disabled={props.isDisabled || isDisabledCollateral} />
                            </Col>
                          )
                        }
                        {
                          data.percent && (
                            <Col sm={12}
                              lg={3}>
                              <Currency label={data.percent}
                                value={collateral?.percent}
                                rule={{ required: !isDisabledCollateral, pattern: ValidatePattern.PERCENTAGE }}
                                onChange={(value) => onChangePercent(Number(value))}
                                disabled={props.isDisabled || isDisabledCollateral} />
                            </Col>
                          )
                        }
                      </div>
                    )
                  }
                </>
              </Row>
            </div>
          )
        }
      </>
    </Card >
  );
}
