import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ReceiveModel, RentPaymentModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';
import { ItemModel } from 'models';

interface IProps {
  title?: string;
  contractTemplateCode: string;
  defectsReceive: ReceiveModel;
  onDefectReceiveChange: (value: ReceiveModel) => void;
  isDisabled?: boolean;
  periodTypeDDL: ItemModel[]
}

const fields = [
  {
    code: 'CF01',
  },
  {
    code: 'CF02',
  },
  {
    code: 'CF03',
  },
  {
    code: 'CF04',
  },
  {
    code: 'CF05',
  },
  {
    code: 'CF06',
  },
  {
    code: 'CF07',
  },
  {
    code: 'CF08',
    receiveDay: 'ผู้ให้เช่าส่งมอบไม่ถูกต้อง ต้องรีบนำรถยนต์นั้นกลับคืนไปทันที และต้องนำรถยนต์คันอื่นมาส่งมอบให้ใหม่ภายใน',
    newDeliveryDay: 'หรือต้องทำการแก้ไขให้ถูกต้องตามสัญญาด้วยค่าใช่จ่ายของผู้ให้เช่าเองภายใน'
  },
  {
    code: 'CF09',

  },
  {
    code: 'CF10',
  },
  {
    code: 'CF11',
  },
  {
    code: 'CF12',
    receiveDetail: 'ผู้ให้แลกเปลี่ยนได้รับตรวจรับฯ ผู้รับแลกเปลี่ยนจะออกหลีกฐานฯ เพื่อให้ผู้รับแลกเปลี่ยนมารับสิ่งของของผู้ให้แลกเปลี่ยนจาก',
    receiveDay: 'ผู้รับแลกเปลี่ยนต้องมารับมอบสิ่งของภายในกำหนด'
  },
  {
    code: 'CF13',
  },
  {
    code: 'CF14',
  },
  {
    code: 'CF15',
  },
];

export function ReceiveAgreement(props: IProps) {
  const [receive, setReceive] = useState<ReceiveModel>(props.defectsReceive);

  useEffect(() => {
    if (receive) {
      props.onDefectReceiveChange(receive);
    }
  }, [receive]);

  useEffect(() => {
    if (props.defectsReceive) {
      setReceive(props.defectsReceive);
    }
  }, [props.defectsReceive]);

  return (
    <Card className='mt-3'>
      <Title text={`สัญญา${props.title ? props.title : 'การตรวจรับ'}`} className='fs-5 text-primary' />
      <>
        {
          fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
            <>
              {data.receiveDetail && (
                <Row className='mt-3'>
                  <Col sm={10}>
                    <InputTextArea
                      label={data.receiveDetail}
                      value={receive?.receiveDetail}
                      onChange={(value) => setReceive({ ...receive, newDeliveryDay: Number(value) })}
                      disabled={props.isDisabled} />
                  </Col>
                </Row>
              )}

              {data.receiveDay && (
                <Row>
                  <Col sm={5}>
                    <InputNumber
                      textCenter
                      label={data.receiveDay}
                      value={receive?.receiveDay}
                      onChange={(value) => setReceive({ ...receive, receiveDay: Number(value) })}
                      disabled={props.isDisabled} />
                  </Col>
                  <Col sm={4}>
                    <Selector
                      className='no-label'
                      value={receive?.receiveDayUnit}
                      items={props.periodTypeDDL}
                      onChange={(value) => setReceive({ ...receive, receiveDayUnit: value })}
                      disabled={props.isDisabled} />
                  </Col>
                </Row>
              )}

              {data.newDeliveryDay && (
                <Row>
                  <Col sm={5}>
                    <InputNumber
                      textCenter
                      label={data.newDeliveryDay}
                      value={receive?.newDeliveryDay}
                      onChange={(value) => setReceive({ ...receive, newDeliveryDay: Number(value) })}
                      disabled={props.isDisabled} />
                  </Col>
                  <Col sm={4}>
                    <Selector
                      className='no-label'
                      value={receive?.newDeliveryDayUnit}
                      items={props.periodTypeDDL}
                      onChange={(value) => setReceive({ ...receive, newDeliveryDayUnit: value })}
                      disabled={props.isDisabled} />
                  </Col>
                </Row>
              )}
            </>
          )
        }
      </>
    </Card>
  );
}
