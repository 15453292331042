import {
  AppointmentStatus,
  Approver,
  ApproverStatus,
  Card,
  DirectiveStatus,
  InputTextArea,
  StatusEditType,
  StatusType,
  Table,
  ArrowCollapse,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal, SearchBuNameModal } from 'components/Modal';
import {
  ApproverAppointmentStatus,
  PreProcumentEditCancelStatusConstant,
  PreProcumentFlag,
} from 'constant/PreProcumentFlag';
import Cookie from 'cookie-universal';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import {
  AppointmentDetailModel,
  AppointmentDocumentModel,
  CreateAppointmenteditingModel,
  UpdateAppointmentDetailModel,
  approverAppointModel,
} from 'models/PP/PreProcurementCreateDirectiveModels';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {
  FaCheckCircle,
  FaCircle,
  FaHistory,
  FaPlus,
  FaRedo,
  FaRegCheckCircle,
  FaSave,
  FaTrashAlt,
  FaUndo,
  FaChevronCircleDown,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import PreProcurementServices from 'services/PP/PreProcurementServices';
import {
  HttpStatusCode,
  fullDatetime,
  submitForm,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import Collabora, { CollaboraRef } from '../../../components/Document/Collabora';
import { isNull, nullObject } from '../../../utils/helper';
import { HistoryModal } from './Modal/HistoryModal';
import { Context } from './step';
import UploadFile from './UploadFile';
import { UserApproveModal } from '../UserApproveModal';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import { account, shareValue } from 'services';
import { sectionApproverModel } from 'models/delegateModel';
import { JorPorCode } from 'constant/basicInformation';
import { AccountModel } from 'models/accountModel';
import { onCheckQueueSectionApprover } from 'utils/checkQueueSectionApprover';
import { AcceptorStatus } from 'utils/constants/PlanEnum';


interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  onDisabled?: boolean;
  isVisable?: boolean;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

function Step2({ onClickBack, onDisabled = false, isVisable = false }: Props) {
  const { data, setData, readonly } = useContext(Context);
  const { userId, departmentCode } = useAppContext();
  const [showHistory, setShowHistory] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const [showApprovers, setShowApprovers] = useState(false);
  const [showPendingAppointment, setShowPendingAppointment] = useState(false);
  const [showRejectAppointment, setShowRejectAppointment] = useState(false);
  const [showRequestEdit, setShowRequestEdit] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const cookies = Cookie();
  const userfullName = cookies.get('fullName') as string;
  const navigate = useNavigate();
  const location = useLocation();
  const [remark, setRemark] = useState('');
  const [, setCurrentPath] = useState('preprocurement-directive/detail');
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [docId, setDocId] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);
  const [isApprover, setIsApprover] = useState(false);
  const [showCommitteesApprovers, setShowCommitteesApprovers] = useState<boolean[]>([]);
  const [openCollapse5, setOpenCollapse5] = useState(true);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [isDisabled, setisDisabled] = useState(false);
  const [committeesApprovers, setCommitteesApprovers] = useState<approverAppointModel[]>([]);
  const [checkReCall, setCheckReCall] = useState(false);
  const [showDefaultApproverModal, setShowDefaultApproverModal] = useState<boolean>(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [showReCall, setShowReCall] = useState(false);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const getUserProfileByIdAsync = async (userId: string) => {
    const { data, status } = await account.getUserByIdAsync(userId);

    if (status === HttpStatusCode.OK) {
      return data as AccountModel;
    }
  };

  const onCheckQueueApprover = (data: AppointmentDetailModel) => {

    const indexUser = data.approverAppointPreProcurements?.findIndex(x => (x.approverUserId === userId || x.delegateUserId === userId) && x.status === 'Pending');

    const max = Math.max(data.approverAppointPreProcurements.length);
    if (indexUser === (max - 1)) {
      return true;
    }

    return false;
  };

  const getDataAppointmentByIdAsync = async (id: string) => {
    const response = await PreProcurementServices.getAppointmentByIdAsync(id);
    if (response.status === HttpStatusCode.OK) {
      if (data.files) {
        data.files = [];
      }
      setData(response.data);
      onCheckApproverStatus(response.data.approverAppointPreProcurements);
      if (response.data.status === AppointmentStatus.CANCELLED || response.data.status === AppointmentStatus.APPROVED) {
        navigate(`/preprocurement-directive/detail/${data.id}`);
      } else {
        navigate(`${location.pathname}`);
      }
    }
  };

  const createDocument = useCallback(async () => {
    const {
      data: docId,
      status,
    } = await PreProcurementServices.createAppointmentOfCommiteeDocumentAsync(data.id, readonly);

    if (status === HttpStatusCode.OK) {
      setDocId(docId);
    }
  }, [data]);

  useEffect(() => {

    if (!nullObject(data)) {

      if (data.status === null || data.status === undefined || data.status === '' || data.status === AppointmentStatus.DRAFT || data.status === AppointmentStatus.REJECTED) {
        setisDisabled(false);
      } else {
        setisDisabled(true);
      }

      if (data.approverAppointPreProcurements) {
        const sectionDataGroup: sectionGroup[] = [];
        data.approverAppointPreProcurements.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {
            sectionDataGroup.push({
              sectionName: item.sectionName,
              level: item.sectionLevel,
              sectionSequence: item.sectionSequence,
              inRefCode: item.inRefCode,
              sectionNameTh: item.sectionName,
              sectionId: item.sectionId,
            } as sectionGroup);
          });

        const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);
        setSections(uniqueNames);
        setCommitteesApprovers(data.approverAppointPreProcurements);

        const queueReCall = data.approverAppointPreProcurements?.filter(a => a.status === ApproverAppointmentStatus.APPROVED).length === 0;

        if (queueReCall) {
          setCheckReCall(true);
        } else {
          setCheckReCall(false);
        }
      }

      if (data.documents
        && files.length === 0) {
        handleSetFile(data.documents);
      }

      if (data.documentDeletes) {
        setDelFiles(data.documentDeletes);
      }

      if (data.approverAppointPreProcurements) {

        setIsQueueApprover(onCheckQueueSectionApprover(data.approverAppointPreProcurements, userId))

        const isApprover = onCheckQueueApprover(data);
        setIsApprover(isApprover);
      }

      createDocument();
    }
  }, [data, userId, createDocument]);

  useEffect(() => {
    const paramId = location.pathname.slice(location.pathname.lastIndexOf('/'), location.pathname.length);
    setCurrentPath(location.pathname.replace(paramId, ''));

    if (data.approverAppointPreProcurements) {
      onCheckApproverStatus(data.approverAppointPreProcurements);

    }

  }, []);

  const handleSetFile = (documents: AppointmentDocumentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];

      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
        createdByUserId: document.createdByUserId,
        createdByUserFullName: document.createdByUserFullName,
        createdAt: document.createdAt,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await PreProcurementServices.UploadAppointmentAttachmentAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        handleSetFile(res.data);
        toast.success('อัปโหลดไฟล์สำเร็จ');
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);

    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName) {
      setDelFiles((delFiles) => [...delFiles, docName]);
    }

    const delFile = [];

    delFile.push(docName);

    if (data && data.id) {
      const res = await PreProcurementServices.deleteAppointmentDocumentAsync(
        data.id,
        delFile,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('ลบไฟล์สำเร็จ');
      }
    }
  }, [files, data.id]);

  const getAppointmentById = async (id: string) => {
    const response = await PreProcurementServices.getAppointmentByIdAsync(id);
    if (response.status === HttpStatusCode.OK) {
      setData({ ...data, documents: response.data.documents });
    }
  };

  const onSubmitAsync = async (isSendApprove: boolean) => {
    submitForm();

    const docUpload: File[] = [];

    if (files) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });

      data.documentDeletes = delFiles;
      setData({ ...data, files: [...docUpload] });
    }

    if (!data.expectingProcurementAt
      || !data.memorandumDate
      || (!data.departmentId || data.departmentId === '')
      || (!data.budgetYear || data.budgetYear <= 0)
      || (!data.supplyMethod || data.supplyMethod === '')
      || (!data.supplyMethodType || data.supplyMethodType === '')
      || (!data.supplyMethodSpecialType || data.supplyMethodSpecialType === '')
      || !data.sourceAndReasons
      || (!data.planName || data.planName === '')) {
      return;
    }

    if (!data.planBudget || data.planBudget <= 0) {
      toast.warn('กรุณากรอกวงเงินมากกว่า 0 บาท');
      return;
    }

    if (!data.committeeTors || data.committeeTors.length === 0) {
      toast.warn('กรุณาเลือกบุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน');
      return;
    }

    if (!data.dutyCommitteeTors || data.dutyCommitteeTors.length === 0) {
      toast.warn('กรุณาเลือกอำนาจหน้าที่บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน');
      return;
    }
    if (data.dutyCommitteeTors.filter((x) => x.duty === null || x.duty === '').length > 0) {
      toast.warn('กรุณาระบุรายละเอียดอำนาจหน้าที่บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน');
      return;
    }

    if ((!data.committeeMiddlePrices || data.committeeMiddlePrices.length === 0)
      && data.planBudget > 100000) {
      toast.warn('กรุณาเลือกบุคคล/คณะกรรมการกำหนดราคากลาง');
      return;
    }

    if ((!data.dutyCommitteeMiddlePrices || data.dutyCommitteeMiddlePrices.length === 0)
      && data.planBudget > 100000) {
      toast.warn('กรุณาเลือกอำนาจหน้าที่บุคคล/คณะกรรมการกำหนดราคากลาง');
      return;
    }

    if (data.dutyCommitteeMiddlePrices.filter((x) => x.duty === null || x.duty === '').length > 0) {
      toast.warn('กรุณาระบุรายละเอียดอำนาจหน้าที่บุคคล/คณะกรรมการกำหนดราคากลาง');
      return;
    }

    if (!committeesApprovers || committeesApprovers.length === 0) {
      toast.warn('กรุณาเลือกผู้เห็นชอบ/อนุมัติ');
      return;
    }

    // if (committeesApprovers.filter((x) => x.isDisabled && x.remark === null).length > 0) {
    //   toast.warn('กรุณาระบุหมายเหตุที่ไม่สามารถปฏิบัติงานได้');
    //   return;
    // }

    if (data.isChange || data.isCancel) {
      if (data.remark === null || data.remark === '') {
        return toast.warn('กรุณากรอกหมายเหตุ');
      }
    }

    if (data && data.id) {

      const saveApprovers: approverAppointModel[] = [];

      sections.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((section) => {

          committeesApprovers.filter((x) => x.inRefCode === section.inRefCode)
            .forEach((data, index) => {
              const newData: approverAppointModel = {
                id: data.id,
                sectionSequence: section.sectionSequence,
                approverUserId: data.approverUserId,
                sequence: index + 1,
                approveFullName: data.approveFullName,
                position: data.position,
                approvedDateAt: data.approvedDateAt,
                isDisabled: data.isDisabled,
                remark: data.remark,
                departmentName: data.departmentName,
                status: data.status,
                processType: data.processType,
                inRefCode: section.inRefCode,
                sectionLevel: data.sectionLevel,
                sectionId: data.sectionId,
                sectionName: data.sectionName,
                rejectedDate: data.rejectedDate,
                approvedRemark: data.approvedRemark,
                approvedUserBy: data.approvedUserBy,
                delegateAnnotation: data.delegateAnnotation,
                delegateDateRange: data.delegateDateRange,
                delegateDepartmentName: data.delegateDepartmentName,
                delegateEmployeeCode: data.delegateEmployeeCode,
                delegateFullName: data.delegateFullName,
                delegatePositionName: data.delegatePositionName,
                delegateUserId: data.delegateUserId,
                rejectedRemark: data.rejectedRemark,
                appointPreProcurementId: data.appointPreProcurementId,
                departmentId: data.departmentId,
                departmetShortName: data.departmetShortName,
                rejectedUserBy: data.rejectedUserBy,
              };

              saveApprovers.push(newData);
            });
        });

      const updateDataModel: UpdateAppointmentDetailModel = {
        id: data.id,
        planId: data.planId !== null ? data.planId : undefined,
        departmentId: data.departmentId,
        budgetYear: data.budgetYear,
        planName: data.planName,
        supplyMethod: data.supplyMethod,
        supplyMethodType: data.supplyMethodType,
        supplyMethodSpecialType: data.supplyMethodSpecialType,
        expectingProcurementAt: data.expectingProcurementAt,
        planBudget: data.planBudget,
        updateByUserFullName: userfullName,
        updateCommitteeTors: data.committeeTors,
        updateDutyCommitteeTors: data.dutyCommitteeTors,
        updateCommitteeMiddlePrices: data.committeeMiddlePrices,
        updateDutyCommitteeMiddlePrices: data.dutyCommitteeMiddlePrices,
        updateApproverAppointPreProcurements: saveApprovers,
        files: docUpload,
        status: data.status,
        documentId: docId,
        documents: [],
        memorandumNo: data.memorandumNo !== null ? data.memorandumNo : '',
        memorandumDate: data.memorandumDate,
        sourceAndReasons: data.sourceAndReasons,
        remark: data.remark,
        isConsumableMaterials: data.isConsumableMaterials,
      };

      collaboraRef.current?.clickSave();

      setTimeout(async () => {
        const res = await PreProcurementServices.updateAppointmentAsync(updateDataModel);

        if (res.status === HttpStatusCode.OK) {

          if (data.documentDeletes && data.documentDeletes.length > 0) {
            const { status } = await PreProcurementServices.deleteAppointmentDocumentAsync(data.id, data.documentDeletes);
            if (status !== HttpStatusCode.NO_CONTENT && status !== HttpStatusCode.OK) {
              toast.error('ลบไฟล์ไม่สำเร็จ');
              return;
            }
          }

          if (isSendApprove) {
            const response = await PreProcurementServices.submitPendingAppointmentAsync(data.id, docId);
            if (response.status === HttpStatusCode.OK) {
              toast.success('ส่งอนุมัติสำเร็จ');
            }
            setShowPendingAppointment(false);
          } else {
            toast.success('บันทึกข้อมูลสำเร็จ');
          }

          getDataAppointmentByIdAsync(data.id);
        } else {
          toast.error('บันทึกข้อมูลไม่สำเร็จ');
        }
      }, 500);
    }
  };

  const approvedAppointmentAsync = async () => {
    collaboraRef.current?.clickSave();

    setTimeout(async () => {
      if (data.isChange) {
        const response = await PreProcurementServices.approvedEditingAppointmentApprovalAsync(data.id!, remark);

        if (response.status === 200) {
          toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);

          getDataAppointmentByIdAsync(data.id);
        } else {
          toast.error(`${isApprover ? 'อนุมัติไม่สำเร็จ' : 'เห็นชอบไม่สำเร็จ'}`);

        }
      } else if (data.isCancel) {
        const response = await PreProcurementServices.approvedCancelingppointmentApprovalAsync(data.id!, remark);

        if (response.status === 200) {
          toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);

          getDataAppointmentByIdAsync(data.id);
        } else {
          toast.error(`${isApprover ? 'อนุมัติไม่สำเร็จ' : 'เห็นชอบไม่สำเร็จ'}`);

        }
      } else {
        const response = await PreProcurementServices.approvedAppointmentApprovalAsync(data.id!, remark);

        if (response.status === 200) {
          toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);

          getDataAppointmentByIdAsync(data.id);
          navigate(`${location.pathname}`);
        } else {
          toast.error(`${isApprover ? 'อนุมัติไม่สำเร็จ' : 'เห็นชอบไม่สำเร็จ'}`);

        }
      }
      setShowApprovers(false);

    }, 500);
  };

  const rejectAppointmentAsync = async () => {

    submitForm();

    if (remark === null || remark === '') {
      return toast.error('กรุณากรอกเหตุผลส่งกลับแก้ไข');
    }

    collaboraRef.current?.clickSave();

    setTimeout(async () => {
      const response = await PreProcurementServices.rejectedAppointmentApprovalAsync(data.id!, remark);
      if (response.status === 200) {
        toast.success('ส่งกลับแก้ไขสำเร็จ');

        getDataAppointmentByIdAsync(data.id);

        navigate(`${location.pathname}`);
      } else {
        toast.warn('ส่งกลับแก้ไขไม่สำเร็จ');

      }
      setShowRejectAppointment(false);

    }, 500);
  };

  const onSubmitAppointmentEditingAsync = async (type: PreProcumentFlag) => {

    if (!data.remark) {
      toast.error('กรุณากรอกหมายเหตุ');
      setShowRequestEdit(false);
      setShowCancel(false);
      return;
    }

    if (data && data.id) {

      const createDataModel: CreateAppointmenteditingModel = {
        RefId: data.id,
        planId: data.planId !== null ? data.planId : undefined,
        departmentId: data.departmentId,
        budgetYear: data.budgetYear,
        planName: data.planName,
        supplyMethod: data.supplyMethod,
        supplyMethodType: data.supplyMethodType,
        supplyMethodSpecialType: data.supplyMethodSpecialType,
        planBudget: data.planBudget,
        expectingProcurementAt: data.expectingProcurementAt,
        memorandumDate: data.memorandumDate,
        remark: data.remark,
        createCommitteeTors: data.committeeTors,
        createDutyCommitteeTors: data.dutyCommitteeTors,
        createCommitteeMiddlePrices: data.committeeMiddlePrices,
        createDutyCommitteeMiddlePrices: data.dutyCommitteeMiddlePrices,
        createApproverAppointPreProcurements: data.approverAppointPreProcurements,
        files: data.files,
        createdByUserFullName: userfullName,
        memorandumNo: data.memorandumNo !== null ? data.memorandumNo : '',
        sourceAndReasons: data.sourceAndReasons,
        isConsumableMaterials: data.isConsumableMaterials,
      };

      collaboraRef.current?.clickSave();

      setTimeout(async () => {
        const res = await PreProcurementServices.createAppointmentEditingAsync(createDataModel, type);

        if (res.status === HttpStatusCode.CREATED) {
          toast.success('บันทึกข้อมูลสำเร็จ');
          const newId: string = res.data.id;

          getDataAppointmentByIdAsync(data.id);

          if (type === PreProcumentFlag.Cancel) {
            navigate(`/cancel-preprocurement-directive/cancel/${newId}`);
          } else {
            navigate(`/edit-preprocurement-directive/edit/${newId}`);
          }
        } else {
          toast.error('บันทึกข้อมูลไม่สำเร็จ');
        }

        setShowRequestEdit(false);
        setShowCancel(false);
      }, 500);
    }
  };

  const setDefaultApproverAsync = async () => {
    const processType = data.isConsumableMaterials ? PROCESSTYPE.ApproveTorCommitteeAnnouncementProcessStock : PROCESSTYPE.ApproveTorCommitteeAnnouncementProcessNonStock;
    const response = await account
      .getDefaultApproverByTopLeve(processType, data.planBudget, data.supplyMethod, data.ignorePurchaseOnTable159, departmentCode);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: approverAppointModel[] = [];
      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            inRefCode: item.inRefCode,
            sectionNameTh: item.sectionName,
            sectionId: item.sectionId,
          } as sectionGroup);

          approver.push({
            id: undefined,
            appointPreProcurementId: '',
            approverUserId: item.userId,
            approveFullName: item.fullName,
            status: AcceptorStatus.DRAFT,
            approvedDateAt: undefined,
            departmentName: item.departmentName,
            departmentId: '',
            departmetShortName: '',
            remark: '',
            isDisabled: false,
            sequence: item.sequence,
            inRefCode: item.inRefCode,
            position: item.positionName,
            processType: item.processType,
            sectionLevel: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            delegateFullName: item.delegateFullName,
            delegateDepartmentName: item.delegateDepartmentName,
            delegateEmployeeCode: item.delegateEmployeeCode,
            delegatePositionName: item.delegatePositionName,
            delegateUserId: item.delegateUserId,
          } as approverAppointModel);

        });

      const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);

      let inx = 0;
      let sectionSeq = 0;

      approver?.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {

          if (sectionSeq !== item.sectionSequence) {
            inx = 1;
            sectionSeq = item.sectionSequence;
          }

          approver[index].sequence = inx;

          inx++;
        });

      setCommitteesApprovers(approver);
      setSections(uniqueNames);
      setShowDefaultApproverModal(false);
      toast.success('กำหนดผู้มีอำนาจเห็นชอบตั้งต้น สำเร็จ');
    } else {
      toast.warn('กำหนดผู้มีอำนาจเห็นชอบตั้งต้น ไม่สำเร็จ');
    }
  };

  const onHideModal = (index: number) => {
    setShowCommitteesApprovers((showCommitteesApprovers) => {
      const newModal = [...showCommitteesApprovers];
      newModal[index] = false;
      return newModal;
    });
  };

  const onOpenModal = (index: number) => {
    setShowCommitteesApprovers((showCommitteesApprovers) => {
      const newModal = [...showCommitteesApprovers];
      newModal[index] = true;
      return newModal;
    });
  };

  const removeApprovers = (seqKey: string) => {
    const temp: approverAppointModel[] = [...committeesApprovers];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    setCommitteesApprovers(temp);
  };

  const onSelectEmpApprover = (
    empId: string,
    name: string,
    position: string,
    department: string,
    index: number,
    sectionSequence?: number,
    inRefCode?: string,
    delegateUserId?: string,
    delegateFullName?: string,
    delegateDepartmentName?: string,
    delegatePositionName?: string,
  ) => {
    if (committeesApprovers &&
      committeesApprovers.filter((x) => x.inRefCode === inRefCode && x.approverUserId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {

      const acceptorBySection = committeesApprovers.filter((x) => x.inRefCode === inRefCode);

      const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
        acceptorBySection.map((x) => {
          return x.sequence;
        })) : 0;

      const newData: approverAppointModel = {
        id: undefined,
        remark,
        appointPreProcurementId: data.id,
        approverUserId: empId,
        approveFullName: name,
        position,
        status: AcceptorStatus.DRAFT,
        approvedDateAt: undefined,
        approvedUserBy: undefined,
        approvedRemark: undefined,
        rejectedUserBy: undefined,
        rejectedDate: undefined,
        rejectedRemark: undefined,
        departmentName: department,
        departmentId: '',
        departmetShortName: '',
        isDisabled: false,
        sequence: maxValueSequence + 1,
        sectionSequence: sectionSequence!,
        inRefCode: inRefCode!,
        processType: '',
        sectionLevel: undefined,
        sectionName: '',
        sectionId: '',
        delegateUserId: delegateUserId,
        delegateFullName: delegateFullName,
        delegateDepartmentName: delegateDepartmentName,
        delegatePositionName: delegatePositionName,
      };

      setCommitteesApprovers(approvers => [...approvers, newData]);
    }
  };

  const handlerSetDefaultApproverAsync = async () => {
    const approver: approverAppointModel[] = [];
    const sectionDataGroup: sectionGroup[] = [];

    setCommitteesApprovers(approver);
    setSections(sectionDataGroup);
    setDefaultApproverAsync();
  }

  const onGetDefaultApprover = async () => {
    const processType = data.isConsumableMaterials ? PROCESSTYPE.ApproveTorCommitteeAnnouncementProcessStock : PROCESSTYPE.ApproveTorCommitteeAnnouncementProcessNonStock;
    const response = await account
      .getDefaultApproverByTopLeve(processType, data.planBudget, data.supplyMethod, data.ignorePurchaseOnTable159, departmentCode);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: approverAppointModel[] = [];
      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            inRefCode: item.inRefCode,
            sectionNameTh: item.sectionName,
            sectionId: item.sectionId,
          } as sectionGroup);

          approver.push({
            id: undefined,
            appointPreProcurementId: '',
            approverUserId: item.userId,
            approveFullName: item.fullName,
            status: AcceptorStatus.DRAFT,
            approvedDateAt: undefined,
            departmentName: item.departmentName,
            departmentId: '',
            departmetShortName: '',
            remark: '',
            isDisabled: false,
            sequence: item.sequence,
            inRefCode: item.inRefCode,
            position: item.positionName,
            processType: item.processType,
            sectionLevel: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            delegateFullName: item.delegateFullName,
            delegateDepartmentName: item.delegateDepartmentName,
            delegateEmployeeCode: item.delegateEmployeeCode,
            delegatePositionName: item.delegatePositionName,
            delegateUserId: item.delegateUserId,
          } as approverAppointModel);

        });

      const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);

      if (data.approverAppointPreProcurements === undefined || data.approverAppointPreProcurements === null || data.approverAppointPreProcurements.length === 0) {

        let inx = 0;
        let sectionSeq = 0;

        approver?.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {

            if (sectionSeq !== item.sectionSequence) {
              inx = 1;
              sectionSeq = item.sectionSequence;
            }

            approver[index].sequence = inx;

            inx++;
          });

        setCommitteesApprovers(approver);
        setSections(uniqueNames);
      }
    }
  };

  const onCheckApproverStatus = (data: approverAppointModel[]) => {
    const maxValueSectionSequence = Math.max.apply(null,
      data?.map((x) => x.sectionSequence));

    const valueSequence = data?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  useEffect(() => {
    if ((!data.approverAppointPreProcurements || data.approverAppointPreProcurements.length === 0) || data.planBudget || data.isConsumableMaterials) {
      onGetDefaultApprover();
    }
  }, [data.approverAppointPreProcurements, data.planBudget, data.isConsumableMaterials]);

  const reCallAppointmentAsync = async () => {

    if (data && data.id) {
      const response = await PreProcurementServices.reCallAppointmentAsync(data.id);

      if (response.status === HttpStatusCode.OK) {
        toast.success('เรียกคืนแก้ไขสำเร็จ');
        getDataAppointmentByIdAsync(data.id);
        navigate(`${location.pathname}`);
      }
    }

    setShowReCall(false);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <div className='d-flex align-items-center'>
            <Title
              text='ผู้มีอำนาจเห็นชอบ/อนุมัติ'
              className='fs-5 text-primary'
            />
            {openCollapse5 ? (
              <ArrowCollapse onClick={() => setOpenCollapse5(!openCollapse5)} openCollapse={openCollapse5} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse5(true)} openCollapse={openCollapse5} />)}
          </div>
          <div className='d-flex align-items-center gap-2'>
            {
              data.isEditing && (
                <div className='status'
                  style={{ marginRight: 13 }}>
                  <span><FaCircle style={{ fontSize: 13 }}
                    className='text-warning' />{PreProcumentEditCancelStatusConstant.Editing}</span>
                </div>
              )
            }
            {
              data.isInCanceling && (
                <div className='status'
                  style={{ marginRight: 13 }}>
                  <span><FaCircle style={{ fontSize: 13 }}
                    className='text-danger' />{PreProcumentEditCancelStatusConstant.Canceling}</span>
                </div>
              )
            }
            <DirectiveStatus
              value={data.status}
              editType={
                data.isChange ? StatusEditType.IsChanged
                  : data.isCancel ? StatusEditType.IsCancel
                    : undefined}
            />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Collapse in={openCollapse5}>
          <div>
            <div className='d-flex justify-content-between my-3'>
              <p>ผู้มีอำนาจเห็นชอบ/อนุมัติ</p>
            </div>
            <Row className='gap-2'>
              <div>
                {isVisable && data.status === AppointmentStatus.DRAFT || data.status === AppointmentStatus.REJECTED
                  ? (
                    <Button
                      className='d-flex align-items-center gap-2'
                      variant='outline-primary'
                      onClick={() => {
                        setShowDefaultApproverModal(true);
                      }}
                    >
                      <FaRedo />กำหนดผู้มีอำนาจเห็นชอบเริ่มต้น
                    </Button>
                  ) : null}
              </div>
              {
                sections.length > 0 ? (
                  sections
                    .sort((a, b) => a.sectionSequence - b.sectionSequence)
                    .map((section, index) => (
                      <Col sm={12}
                        key={index}>
                        {
                          (!isDisabled) && (
                            <div className='d-flex justify-content-between mb-1'>
                              <p className='fs-5 text-primary'>
                                {/* {section.sectionName} */}
                              </p>
                              <Button
                                variant='outline-primary'
                                onClick={() => onOpenModal(index)}
                                hidden={!isVisable}
                              >
                                <FaPlus className='me-1' /> เพิ่มรายชื่อ
                              </Button>
                            </div>

                          )
                        }
                        <Table
                          hidePagination
                          className='mt-3 step-bar'
                          notResponse
                        >
                          <thead>
                            <tr>
                              <th style={{ width: '5%' }}>ลำดับ</th>
                              <th className='text-start'
                                style={{ width: '30%' }}>ชื่อ-นามสกุล
                              </th>
                              <th className='text-start'
                                style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                              </th>
                              <th style={{ width: '10%' }}>สถานะ</th>
                              <th style={{ width: '10%' }}>วันที่เห็นชอบ</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {
                              committeesApprovers &&
                              committeesApprovers.length > 0 &&
                              (
                                committeesApprovers
                                  .filter((x) => x.inRefCode === section.inRefCode)
                                  .sort((a, b) => a.sequence - b.sequence)
                                  .map((data, i) => (
                                    <>
                                      <tr key={i}>
                                        <td className='text-center'>{i + 1}</td>
                                        <td className='text-left'>
                                          {data.approveFullName}
                                          <p className='m-0 department text-wrap'>
                                            {data.position}
                                          </p>
                                        </td>
                                        <td className='text-left'>
                                          {data.delegateFullName &&
                                            <>
                                              {data.delegateFullName}
                                              <p className='m-0 department text-wrap'>
                                                {data.delegatePositionName}
                                              </p>
                                            </>
                                          }
                                        </td>
                                        <td className='text-center'>
                                          {isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ?
                                            <Approver value={data.status}
                                              outline /> : <ApproverStatus value={data.status}
                                                outline />}
                                        </td>
                                        <td className='text-center'>
                                          {data.approvedDateAt ? fullDatetime(data.approvedDateAt) : ''}
                                        </td>
                                        <td className='text-end'>
                                          {isVisable &&
                                            <Button
                                              variant='danger'
                                              onClick={() => removeApprovers(`${data.sectionSequence}${data.sequence}`)}
                                              disabled={isDisabled}
                                            >
                                              <FaTrashAlt />
                                            </Button>}
                                        </td>
                                      </tr>
                                      {data.delegateDateRange || data.approvedRemark || data.rejectedRemark ?
                                        <tr className='border-bottom bg-opacity-10 bg-light'>
                                          <td />
                                          <td colSpan={5}
                                            className='p-0'>
                                            <ul className='step-bar-list'>
                                              {data.delegateDateRange &&
                                                <li>
                                                  <div className='description'>
                                                    <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                                    <p className='mb-0'
                                                      style={{ color: 'gray' }}>
                                                      {data.delegateDateRange}
                                                    </p>
                                                  </div>
                                                </li>
                                              }
                                              {data.approvedRemark &&
                                                <li className=''>
                                                  <div className='description'>
                                                    <p className='mb-0 text-primary'>หมายเหตุ{isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ? 'อนุมัติ' : 'เห็นชอบ'}
                                                    </p>
                                                    <p className='mb-0 text-wrap'
                                                      style={{ color: 'gray' }}>
                                                      <span className='text-muted me-2'>[{data.approvedDateAt ? fullDatetime(data.approvedDateAt) : ''}]</span>
                                                      {data.approvedUserBy} {data.approvedRemark}
                                                    </p>
                                                  </div>
                                                </li>
                                              }
                                              {data.rejectedRemark &&
                                                <li>
                                                  <div className='description'>
                                                    <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                                    <p className='mb-0 text-wrap'
                                                      style={{ color: 'gray' }}>
                                                      <span className='text-muted me-2'>[{data.rejectedDate ? fullDatetime(data.rejectedDate) : ''}]</span>
                                                      {data.rejectedUserBy} {data.rejectedRemark}
                                                    </p>
                                                  </div>
                                                </li>
                                              }
                                            </ul>
                                          </td>
                                        </tr>
                                        : <tr className='border-bottom' />}
                                    </>
                                  ))
                              )
                            }
                          </tbody>
                        </Table>
                        <SearchBuNameModal
                          total={userTotal}
                          show={showCommitteesApprovers[index]}
                          onHide={() => onHideModal(index)}
                          onSelect={
                            (
                              id,
                              name,
                              department,
                              position,
                              delegateUserId,
                              delegateFullName,
                              delegateDepartmentName,
                              delegatePositionName,
                            ) => onSelectEmpApprover(
                              id,
                              name,
                              position!,
                              department!,
                              index,
                              section.sectionSequence,
                              section.inRefCode,
                              delegateUserId,
                              delegateFullName,
                              delegateDepartmentName,
                              delegatePositionName,
                            )}
                          data={users}
                          onSearch={onSearchUserAsync}
                          onPageChange={onPageChangeAsync}
                          departmentCode={departmentCode}
                          departmentDisabled={departmentCode !== JorPorCode.CODE}
                        />
                      </Col>
                    ))
                ) : (
                  <Col>
                    <span className='text-primary'>ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
                  </Col>
                )
              }
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <div className='d-flex align-items-center'>
            <Title
              text='เอกสารขออนุมัติแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง'
              className='fs-5 text-primary'
            />
            <Row className='justify-content-center'>
              <Col sm={12}
                md={8}>
                {openCollapse ? (
                  <ArrowCollapse onClick={() => setOpenCollapse(!openCollapse)} openCollapse={openCollapse} />
                ) : (
                  <ArrowCollapse onClick={() => setOpenCollapse(true)} openCollapse={openCollapse} />)}
              </Col>
            </Row>
          </div>

          {/* <div className='d-flex align-items-center gap-2'>
            {
              data.isEditing && (
                <div className='status'
                  style={{ marginRight: 13 }}>
                  <span><FaCircle style={{ fontSize: 13 }}
                    className='text-warning' />{PreProcumentEditCancelStatusConstant.Editing}</span>
                </div>
              )
            }
            {
              data.isInCanceling && (
                <div className='status'
                  style={{ marginRight: 13 }}>
                  <span><FaCircle style={{ fontSize: 13 }}
                    className='text-danger' />{PreProcumentEditCancelStatusConstant.Canceling}</span>
                </div>
              )
            }
            <DirectiveStatus
              value={data.status}
            />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div> */}
        </div>
        <Collapse in={openCollapse}>
          <div>
            <Collabora
              docId={docId}
              docName='doc-01'
              ref={collaboraRef}
              readonly={readonly} />
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={8}>
            <UploadFile
              disabled={onDisabled || readonly}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              appointmentId={data.id}
              canDelete={isVisable}
              userId={userId}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <div>
          {(data.status === AppointmentStatus.PENDING && checkReCall) && (
            <ButtonCustom
              onClick={() => setShowReCall(true)}
              variant='danger'
              icon="undo"
              text="เรียกคืนแก้ไข"
            />
          )}
          {(data.status === AppointmentStatus.PENDING && isQueueApprover) && (
            <ButtonCustom
              onClick={() => setShowRejectAppointment(true)}
              variant='danger'
              icon="undo"
              text="ส่งกลับแก้ไข"
            />
          )}
          {isVisable ?
            (data.status === AppointmentStatus.DRAFT || data.status === AppointmentStatus.REJECTED) && (
              <ButtonCustom
                onClick={() => onSubmitAsync(false)}
                variant='success'
                icon="save"
                text="บันทึกข้อมูลเอกสารบันทึกข้อความแต่งตั้ง"
              />
            ) : null
          }
          {isVisable ?
            (isVisable && data.status === AppointmentStatus.DRAFT || data.status === AppointmentStatus.REJECTED) && (
              <ButtonCustom
                onClick={() => setShowPendingAppointment(true)}
                icon="checked"
                text="ส่งเห็นชอบ/อนุมัติ"
              />
            ) : null
          }
          {(data.status === AppointmentStatus.PENDING && isQueueApprover) && (

            <ButtonCustom
              onClick={() => setShowApprovers(true)}
              variant='success'
              icon="checked"
              text={isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
            />
          )}
        </div>
        <div style={{ width: '190px' }} />
      </div>

      <HistoryModal
        title='ประวัติการใช้งานแบบขอแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        appointNumber={data.appointNumber}
        statusType={
          data.isChange ? StatusType.APPOINTMENTEDIT
            : data.isCancel ? StatusType.APPOINTMENTCANCEL
              : StatusType.APPOINTMENT}
      />

      <Modal
        show={showReCall}
        size='lg'
        onHide={() => setShowReCall(!showReCall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowReCall(!showReCall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={reCallAppointmentAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApprovers}
        size='lg'
        onHide={() => setShowApprovers(!showApprovers)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>
                {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              {/* คุณต้องการอนุมัติบันทึกการแต่งตั้งหรือไม่ ? */}
              <InputTextArea
                label='ความเห็นเพิ่มเติม (ถ้ามี)'
                value={remark}
                onChange={(value) => setRemark(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApprovers(!showApprovers)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={approvedAppointmentAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showPendingAppointment}
        size='lg'
        onHide={() => setShowPendingAppointment(!showPendingAppointment)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งเห็นชอบ/อนุมัติ แต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingAppointment(!showPendingAppointment)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejectAppointment}
        size='lg'
        onHide={() => setShowRejectAppointment(!showRejectAppointment)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='เหตุผล'
                rule={{ required: true }}
                value={remark}
                onChange={(value) => setRemark(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='outline-primary'
                className='me-2 px-3'
                onClick={() => setShowRejectAppointment(!showRejectAppointment)}
              >
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={rejectAppointmentAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRequestEdit}
        size='lg'
        onHide={() => setShowRequestEdit(!showRequestEdit)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการบันทึกขอแก้ไขแต่งตั้งหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowRequestEdit(!showRequestEdit)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAppointmentEditingAsync(PreProcumentFlag.Editing)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showCancel}
        size='lg'
        onHide={() => setShowCancel(!showCancel)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการบันทึกยกเลิกแต่งตั้งหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowCancel(!showCancel)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAppointmentEditingAsync(PreProcumentFlag.Cancel)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showDefaultApproverModal}
        size='lg'
        onHide={() => setShowDefaultApproverModal(!showDefaultApproverModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการกำหนดผู้มีอำนาจเห็นชอบเริ่มต้นใหม่หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDefaultApproverModal(!showDefaultApproverModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerSetDefaultApproverAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step2;
