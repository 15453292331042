import { DepartmentResponse, ItemModel, UserListSelectionByCodeParams, UserListSelectionParams } from 'models';
import { shareValue, unitOfMeasure } from 'services';
import http from 'utils/axios';
import department from './department';
import { SharedvalueGroup } from 'utils/constants/ContractEnum';

const GetTraderTypeAsync = async (id?: string) => {
  const params = {
    parentId: id ?? null,
    group: 'tradertype'
  };

  return await http.get('/sharedvalue/tradertype', { params });
};

const getSupplyMethodListAsync = async (id?: string) => {
  const params = {
    parentId: id ?? null,
    group: 'supplymethod'
  };

  return await http.get('/sharedvalue/supplymethod', { params });
};

const getSupplyMethodTypeListAsync = async (id?: string) => {
  const params = {
    parentId: id ?? null,
    group: 'supplymethodtype'
  };

  return await http.get('/sharedvalue/supplymethodtype', { params });
};

const getAssignDepartmentListAsync = async () => {
  const params = {
    group: 'assigndepartment'
  };

  return await http.get('/sharedvalue/assigndepartment', { params });
};

const getPositionOnBoardListAsync = async (id?: string, subGroup?: string) => {
  const params = {
    parentId: id ?? null,
    group: 'positiononboard',
    subGroup: subGroup ?? null
  };

  return await http.get('/sharedvalue/positiononboard', { params });
};

const getUserListSelectionAsync = async (page: number, size: number, search: UserListSelectionParams) => {
  const params = {
    page,
    size,
    ...search
  };

  return await http.get('/Account/listselection', { params });
};

const getUserListSelectionForAcceptorAsync = async (page: number, size: number, search: UserListSelectionByCodeParams) => {
  const params = {
    page,
    size,
    ...search
  };

  return await http.get('/Account/list-selection-for-acceptor', { params });
};

const getWinnerAndReasonListAsync = async () => {
  const params = {
    Group: 'winnerReason'
  };

  return await http.get('sharedvalue/winnerReason', { params });
};

const getVattypeAsync = async () => {
  const params = {
    Group: 'vattype'
  };

  return await http.get('sharedvalue/vattype', { params });
};

const GetCriteriaConsiderationAsync = async () => {
  const params = {
    Group: 'CriteriaConsideration'
  };

  return await http.get('sharedvalue/criteriaconsideration', { params });
};

const getPostionListSelectionAsync = async () => http.get('Position/listselection');

const getPositionListSelectionAsync = async <T>() => http.get<T>('Position/listselection');

const GetDDL = async (Type: string) => {
  const itemForMap: ItemModel[] = [];
  if (Type === 'Department') {

    const departmentsRes = await department.getDepartmentsAsync();
    const departmentData: DepartmentResponse[] = departmentsRes.data;
    departmentData.forEach((data) => itemForMap.push({ label: data.name, value: data.id ? data.id : '' }));
  } else if (Type === 'SupplyMethodType') {

    const supplyMethodTypeListRes = await shareValue.getSupplyMethodTypeListAsync();
    const supplyMethodTypeData: ItemModel[] = supplyMethodTypeListRes.data;
    supplyMethodTypeData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
  } else if (Type === 'SupplyMethod') {

    const supplyMethodListRes = await shareValue.getSupplyMethodListAsync();
    const supplyMethodListData: ItemModel[] = supplyMethodListRes.data;
    supplyMethodListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
  } else if (Type === 'Position') {

    const positionRes = await shareValue.getPostionListSelectionAsync();
    const positionListData: ItemModel[] = positionRes.data;
    positionListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value }));
  } else if (Type === 'WinnerAndReason') {

    const winnerAndReason = await shareValue.getWinnerAndReasonListAsync();
    const winnerAndReasonData: ItemModel[] = winnerAndReason.data;
    winnerAndReasonData.forEach((data) => itemForMap.push({ label: data.label, value: data.value }));
  } else if (Type === 'VatType') {

    const vattype = await shareValue.getVattypeAsync();
    const vatTypeData: ItemModel[] = vattype.data;
    vatTypeData.forEach((data) => itemForMap.push({ label: data.label, value: data.value }));
  } else if (Type === 'CriteriaConsideration') {

    const { data } = await shareValue.GetCriteriaConsiderationAsync();
    const criteriaConsideration: ItemModel[] = data;
    criteriaConsideration.forEach((val) => itemForMap.push({ label: val.label, value: val.value }));
  } else if (Type === SharedvalueGroup.PeriodType) {

    const { data } = await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodType);
    const periodTypes: ItemModel[] = data;
    periodTypes.forEach((val) => itemForMap.push({ label: val.label, value: val.value }));
  } else if (Type === SharedvalueGroup.UnitOfMeasure) {

    const { data } = await unitOfMeasure.GetUnitOfMeasureAsync();
    const unitOfMeasureData: ItemModel[] = data;
    unitOfMeasureData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
  } else if (Type === SharedvalueGroup.MedianPriceConsiderInformation) {

    const { data } = await shareValue.getListSharedValueAsync(SharedvalueGroup.MedianPriceConsiderInformation);
    const MedianPriceConsiderInformationData: ItemModel[] = data;
    MedianPriceConsiderInformationData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
  } else if (Type === SharedvalueGroup.BiddingResultConsiderPeriodType) {

    const { data } = await shareValue.getListSharedValueAsync(SharedvalueGroup.BiddingResultConsiderPeriodType);
    const itemData: ItemModel[] = data;
    itemData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
  } else if (Type === SharedvalueGroup.MaintenancePeriodType) {

    const { data } = await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodType, '', SharedvalueGroup.MaintenancePeriodType);
    const itemData: ItemModel[] = data;
    itemData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
  }

  return itemForMap;
};

const getContractTypeListAsync = async (id?: string) => {
  const params = {
    parentId: id ?? null,
    group: 'contractType'
  };

  return await http.get('/sharedvalue/contractType', { params });
};

const getListSharedValueAsync = async (
  group?: string,
  parentId?: string,
  subGroup?: string) => {
  const params = {
    group: group ?? null,
    parentId: parentId ?? null,
    subGroup: subGroup ?? null,
  };

  return await http.get('/sharedvalue/byGroup', { params });
};

const getDocumentTemplateAsync = async (subGroup: string) => {
  const params = {
    subGroup: subGroup,
  };

  return await http.get('/sharedvalue/documentTemplate', { params });
};

const getSingleSharedValueAsync = async (id: string) => {
  return await http.get(`/sharedvalue/${id}`);
};

const getSharedvalueBySubGroupAsync = async (group: string, subGroup: string) => {
  const params = {
    group: group,
    subGroup: subGroup,
  };

  return await http.get('/sharedvalue/bySubGroup', { params });
};

const geGLAccountSelectionAsync = async () => http.get('glAccount');

export default {
  GetTraderTypeAsync,
  getSupplyMethodListAsync,
  getSupplyMethodTypeListAsync,
  getPositionOnBoardListAsync,
  getUserListSelectionAsync,
  getPostionListSelectionAsync,
  getPositionListSelectionAsync,
  GetDDL,
  getAssignDepartmentListAsync,
  getWinnerAndReasonListAsync,
  getVattypeAsync,
  GetCriteriaConsiderationAsync,
  getContractTypeListAsync,
  getListSharedValueAsync,
  getDocumentTemplateAsync,
  getSingleSharedValueAsync,
  getUserListSelectionForAcceptorAsync,
  geGLAccountSelectionAsync,
  getSharedvalueBySubGroupAsync,
};
