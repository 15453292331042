import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import {
  Card,
  Input,
  InputTextArea,
  SearchBuNameModal,
  Table,
  Modal,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ContractAgreementAcceptorModel, EmployerModel } from '../../../../../../models/CM/CM02Models';
import { LocationDataHandler } from './LocationDataHandler';
import { FaRedo, FaUndo } from 'react-icons/fa';
import { ItemModel, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { account, shareValue } from 'services';
import { HttpStatusCode, useAppContext } from 'utils';
import { JorPorCode } from 'constant/basicInformation';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import { sectionApproverModel } from 'models/delegateModel';
import { ContractAgreementTypeAcceptor } from 'constant/ContractStatus';

interface IEmployerDetailProps {
  contractTemplateCode?: string;
  employerData: EmployerModel;
  onChange: (value: EmployerModel) => void;
  isDisabled?: boolean;
  isResponsible?: boolean;
  acceptorSigned?: ContractAgreementAcceptorModel[];
  onChangeAcceptorSigned: (value: ContractAgreementAcceptorModel[]) => void;
  sumOfferTotalAmountVat?: number;
  supplyMethod?: string;
  provincesDDL: ItemModel[];
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

const fields = [
  {
    code: 'CF01',
    title: 'ข้อมูลผู้ว่าจ้าง',
  },
  {
    code: 'CF02',
    title: 'ข้อมูลผู้ซื้อ',
  },
  {
    code: 'CF03',
    title: 'ข้อมูลผู้ซื้อ',
  },
  {
    code: 'CF04',
    title: 'ข้อมูลผู้ซื้อ',
  },
  {
    code: 'CF05',
    title: 'ข้อมูลผู้ซื้อ',
  },
  {
    code: 'CF06',
    title: 'ข้อมูลผู้เช่า',
  },
  {
    code: 'CF07',
    title: 'ข้อมูลผู้ว่าจ้าง',
  },
  {
    code: 'CF08',
    title: 'ข้อมูลผู้เช่า',
  },
  {
    code: 'CF09',
    title: 'ข้อมูลผู้ว่าจ้าง',
  },
  {
    code: 'CF10',
    title: 'ข้อมูลผู้ว่าจ้าง',
  },
  {
    code: 'CF11',
    title: 'ข้อมูลผู้เช่า',
  },
  {
    code: 'CF12',
    title: 'ข้อมูลผู้ให้แลกเปลี่ยน',
  },
  {
    code: 'CF13',
    title: 'ข้อมูลผู้ว่าจ้าง',
  },
  {
    code: 'CF14',
    title: 'ข้อมูลผู้ว่าจ้าง',
  },
  {
    code: 'CF15',
    title: 'ข้อมูลผู้ว่าจ้าง',
  },
];

export function EmployerDetail(props: IEmployerDetailProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [partnerData, setPartnerData] = useState<EmployerModel>(props.employerData);
  const [acceptorSigned, setAcceptorSigned] = useState<ContractAgreementAcceptorModel[]>([]);
  const [showUserSignedModals, setShowUserSignedModals] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const { departmentCode } = useAppContext();
  const [showDefaultApproverModal, setShowDefaultApproverModal] = useState<boolean>(false);

  useEffect(() => {
    if (!props.employerData) {
      setPartnerData({} as EmployerModel);
    }

    if (partnerData && firstLoad) {
      setPartnerData(partnerData);
      setFirstLoad(false);
    }

    if (props.acceptorSigned) {
      setAcceptorSigned(props.acceptorSigned);
    }

    if (!partnerData) {
      defaultDDL();
    }

  }, [props.employerData, firstLoad]);

  useEffect(() => {
    if (partnerData) {
      props.onChange(partnerData);
    }
  }, [partnerData]);

  const onSelectEmpAcceptorSigned = (empId: string, name: string, position: string, department: string, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
    delegatePositionName?: string) => {

    const newData: ContractAgreementAcceptorModel[] = [];
    newData.push({
      id: acceptorSigned.length > 0 ? acceptorSigned[0].id : undefined,
      sequence: 1,
      userId: empId,
      fullName: name,
      positionName: position,
      departmentName: department,
      delegateFullName: delegateFullName,
      delegateDepartmentName: delegateDepartmentName,
      delegateEmployeeCode: delegateEmployeeCode,
      delegatePositionName: delegatePositionName,
      delegateUserId: delegateUserId,
      isAvailable: false,
      availableRemark: undefined,
      status: 'Draft',
      sectionInRefCode: acceptorSigned.length > 0 ? acceptorSigned[0].sectionInRefCode : '',
      sectionName: acceptorSigned.length > 0 ? acceptorSigned[0].sectionName : '',
      sectionSequence: acceptorSigned.length > 0 ? acceptorSigned[0].sectionSequence : 0,
    } as ContractAgreementAcceptorModel);

    setAcceptorSigned(newData);
    props.onChangeAcceptorSigned(newData);
  };

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  }

  const onTextChange = useCallback((key: string, value: string) => {
    setPartnerData(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onChangeSubDistrict = useCallback((id: string, label: string) => {
    setPartnerData(prevState => ({
      ...prevState,
      subDistrictCode: id,
      subDistrictName: label,
    }));
  }, []);

  const onChangeDistrict = useCallback((id: string, label: string) => {
    setPartnerData(prevState => ({
      ...prevState,
      districtCode: id,
      districtName: label,
    }));
  }, []);

  const onChangeProvince = useCallback((id: string, label: string) => {
    setPartnerData(prevState => ({
      ...prevState,
      provinceCode: id,
      provinceName: label,
    }));
  }, []);

  const defaultDDL = useCallback(() => {
    fields.filter(f => f.code === props.contractTemplateCode).forEach((data) => {
      setPartnerData({
        ...partnerData,
        name: 'ธนาคารอาคารสงเคราะห์',
        place: 'ธนาคารอาคารสงเคราะห์ สำนักงานใหญ่ ตั้งอยู่เลขที่ 63 ถนนพระราม 9',
        provinceCode: '1',
        provinceName: 'กรุงเทพมหานคร',
        districtCode: '1017',
        districtName: 'ห้วยขวาง',
        subDistrictCode: '101701',
        subDistrictName: 'ห้วยขวาง',
      });
    });
  }, []);

  const onGetDefaultApprover = async () => {
    const response = await account
      .getDefaultApproverContract(PROCESSTYPE.ApproveSignContractProcess, props.sumOfferTotalAmountVat!, props.supplyMethod);
    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: ContractAgreementAcceptorModel[] = [];
      const firstData = newDatas && newDatas.length > 0 ? [newDatas[newDatas.length - 1]] : newDatas;

      firstData.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {

          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            inRefCode: item.inRefCode,
            sectionNameTh: item.sectionName,
            sectionId: item.sectionId,
          } as sectionGroup);

          approver.push({
            id: acceptorSigned.length > 0 ? acceptorSigned[0].id : undefined,
            sectionInRefCode: item.inRefCode!,
            sectionSequence: item.sectionSequence!,
            sectionName: item.sectionName!,
            userId: item.userId,
            fullName: item.fullName,
            positionName: item.positionName,
            departmentName: item.departmentName,
            isAvailable: false,
            availableRemark: undefined,
            type: ContractAgreementTypeAcceptor.AcceptorSign,
            acceptDate: undefined,
            acceptRemark: undefined,
            rejectDate: undefined,
            rejectRemark: undefined,
            delegateUserId: item.delegateUserId,
            delegateEmployeeCode: item.delegateEmployeeCode,
            delegateFullName: item.delegateFullName,
            delegateDepartmentName: item.delegateDepartmentName,
            delegatePositionName: item.delegatePositionName,
            sequence: acceptorSigned.filter((x) => x.sectionSequence === item.sectionSequence).length + 1,
            status: 'Draft',
            seqKey: `${item.sectionSequence?.toString()}${(acceptorSigned.filter((x) => x.sectionSequence === item.sectionSequence).length + 1).toString()}`,
          } as ContractAgreementAcceptorModel);
        });

      let inx = 0;
      let sectionSeq = 0;

      approver?.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {

          if (sectionSeq !== item.sectionSequence) {
            inx = 1;
            sectionSeq = item.sectionSequence;
          }

          approver[index].sequence = inx;

          inx++;
        });

      setAcceptorSigned(approver);
      props.onChangeAcceptorSigned(approver);
      setShowDefaultApproverModal(false);
    }
  };

  return (
    <Card className='mt-3'>
      <>
        {
          fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
            <div>
              {
                data.title && (
                  <Title text={data.title}
                    className='fs-5 text-primary' />
                )
              }
            </div>
          )
        }
      </>
      <Row className='mt-3 align-items-center'>
        <Col sm={6}
          md={6}>
          <Input
            label='ชื่อ-ที่อยู่'
            value={partnerData?.name}
            onChange={(value) => onTextChange('name', value.toString())}
            disabled />
        </Col>
      </Row>
      <Row>
        <Col sm={6}
          md={6}>
          <InputTextArea
            label='สัญญาฉบับนี้ทำขึ้น ณ'
            value={partnerData?.place}
            onChange={(value) => onTextChange('place', value.toString())}
            disabled />
        </Col>
      </Row>
      <Row>
        <LocationDataHandler
          provinceId={partnerData?.provinceCode}
          districtId={partnerData?.districtCode}
          subDistrictId={partnerData?.subDistrictCode}
          provincesDDL={props.provincesDDL}
          onProvinceChange={(id, label) => onChangeProvince(id, label)}
          onDistrictChange={(id, label) => onChangeDistrict(id, label)}
          onSubDistrictChange={(id, label) => onChangeSubDistrict(id, label)}
          isDisabled />
      </Row>
      <>
        <Title text='ลงนามโดย' />
        <Row>
          <div className='mt-3'>
            {props.isResponsible && <Button
              className='d-flex align-items-center gap-2'
              variant='outline-primary'
              onClick={() => {
                setShowDefaultApproverModal(true);
              }}
              disabled={props.isDisabled}
            >
              <FaRedo />กำหนดผู้ลงนามเริ่มต้น
            </Button>}
          </div>
          <Col sm={12}>
            <Table notResponse hidePagination className='mt-3 step-bar'>
              <thead>
                <tr>
                  <th style={{ width: 20 }}>ลำดับ</th>
                  <th className='text-start' style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
                  <th className='text-start' style={{ width: '45%' }}>ผู้ปฏิบัติงานแทน</th>
                  <th style={{ width: 100 }} />
                </tr>
              </thead>
              <tbody>
                {acceptorSigned?.sort((a, b) => a.sequence - b.sequence)
                  .map((data, index) => (
                    <>
                      <tr>
                        <td className='text-center'>{index + 1}</td>
                        <td className='text-left'>
                          {data.fullName}
                          <p className='m-0 department'>
                            {data.positionName}
                          </p>
                        </td>
                        <td className='text-left'>
                          {data.delegateFullName
                            && (
                              <>
                                {data.delegateFullName}
                                <p className='m-0 department'>
                                  {data.delegatePositionName}
                                </p>
                              </>
                            )}
                        </td>
                        <td className='text-end'>
                          {props.isResponsible && (<Button
                            disabled={props.isDisabled}
                            variant='primary'
                            onClick={() => setShowUserSignedModals(true)}
                          >
                            <FaUndo className='me-1' /> เปลี่ยนผู้ลงนาม
                          </Button>)}
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
      <Modal
        show={showDefaultApproverModal}
        size='lg'
        onHide={() => setShowDefaultApproverModal(!showDefaultApproverModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการกำหนดผู้ลงนามเริ่มต้นใหม่หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDefaultApproverModal(!showDefaultApproverModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onGetDefaultApprover()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showUserSignedModals}
        onHide={() => setShowUserSignedModals(!showUserSignedModals)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode,
          delegateDepartmentName, delegatePositionName) => onSelectEmpAcceptorSigned(id, name, position!, department!, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
    </Card>
  );
}
