function nullObject(obj: object) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function differenceInMonths(date1: Date, date2: Date) {
  const monthDiff = date1.getMonth() - date2.getMonth();
  const yearDiff = date1.getUTCFullYear() - date2.getUTCFullYear();

  return monthDiff + yearDiff * 12;
}

function generateUniqueId(tailingValue: string | number) {
  const timestamp = new Date().getTime()
    .toString(36); // Convert timestamp to base36

  return `${timestamp}-${tailingValue}`;
}

function dataValidation<U, T>(validateData: U, data: T, fn: (value: T) => void) {
  if (!validateData) {
    return;
  }

  fn(data);
}

function isNull(oldValue?: string, newValue?: string) {
  if (oldValue) {
    return oldValue;
  }

  return newValue;
}

export {
  nullObject,
  differenceInMonths,
  generateUniqueId,
  dataValidation,
  isNull,
};
