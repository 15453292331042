import { Breadcrumb } from 'components';
import StepProgress from 'components/StepProgress';
import { AppointCommiteeDetailResponseModel } from 'models/PR/PR0202Models';
import {
  createContext,
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import PR0202Services from 'services/PR/PR0202Services';
import {
  HttpStatusCode,
  StatusName,
} from 'utils';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type PR0202Context = {
  data: AppointCommiteeDetailResponseModel
  setData: Dispatch<React.SetStateAction<AppointCommiteeDetailResponseModel>>,
  //   setFiles: Dispatch<React.SetStateAction<IFile[]>>,
  // files: IFile[],
};

export const Context = createContext({} as PR0202Context);

const BREADCRUMB_INFO = [
  {
    routerName: '/pr/pr0202', label: 'จัดทำคำสั่งแต่งตั้งคณะกรรมาการจัดซื้อจัดจ้างและคณะตรวจรับพัสดุ',
  },
  {
    routerName: '/pr/pr0202/detail', label: 'จัดทำคำสั่งแต่งตั้งคณะกรรมาการจัดซื้อจัดจ้างและคณะกรรมาการตรวจรับ',
  },
];

export default function AddPR0202() {
  const { id } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState<AppointCommiteeDetailResponseModel>({} as AppointCommiteeDetailResponseModel);


  const getAppointmentApprovalDetailById = useCallback(async () => {
    if (id !== null || id !== undefined) {
      const response = await PR0202Services.getAppointmentProcurementByIdAsync(id || '');

      if (response.status === HttpStatusCode.OK) {
        setData(response.data);

        if (response.data.status) {
          if (response.data.status === undefined || response.data.status === StatusName.DRAFT || response.data.status === StatusName.REJECTED) {
            setDisabled(false);
          } else {
            setDisabled(true);
          }
        }
      }
    }
  }, [id]);

  useEffect(() => {
    getAppointmentApprovalDetailById();
  }, []);

  const childRef = useRef<ProgressBarRefType>(null);
  const obj = [{
    title: <p>กำหนดรูปแบบเอกสาร
      รายงานขอซื้อขอจ้าง ( จพ.005 )
    </p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} onDisable={disabled} />,
  },
  {
    title: <p>คณะกรรมาการจัดจ้าง</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} onDisable={disabled} />,
  },
  {
    title: <p>คณะกรรมการตรวจรับพัสดุ</p>,
    displayIndex: '3',
    child: <Step3 onClickNext={() => clickNext()} onClickBack={() => clickBack()} onDisable={disabled} />,
  },
  {
    title: <p>คณะกรรมการตรวจรับพัสดุ
      (การจ้างบริการบำรุงรักษา )
    </p>,
    displayIndex: '4',
    child: <Step4 onClickNext={() => clickNext()} onClickBack={() => clickBack()} onDisable={disabled} />,
  },
  {
    title: <p>คณะกรรมาการตรวจรับพัสดุ</p>,
    displayIndex: '5',
    child: <Step5 onClickNext={() => clickNext()} onClickBack={() => clickBack()} onDisable={disabled} />,
  },
  {
    title: <p>ตัวอย่าง
      เอกสารรายงานขอชื้ขอจ้าง ( จพ.005 )
    </p>,
    displayIndex: '6',
    child: <Step6 onClickNext={() => clickNext()} onClickBack={() => clickBack()} onDisable={disabled} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();
  const backToIndex = () => {
    navigate('/pr/pr0202');
  };

  return (
    <div className="document">
      <h4 className="mt-2 mb-0 text-primary text-start">จัดทำคำสั่งแต่งตั้งคณะกรรมาการจัดซื้อจัดจ้างและคณะกรรมาการตรวจรับ</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12} className="mb-2">
          <Context.Provider value={{ data, setData }}>
            <StepProgress progressBarData={obj} ref={childRef} />
          </Context.Provider> <></>
        </Col>
      </Row>
    </div>
  );
}
