import {
  ButtonCustom,
  Currency,
  Input,
  InputTextArea,
  Modal,
  Selector,
  Table,
} from 'components';
import { InputNumber } from 'components/Controls/InputNumber';
import { ItemModel } from 'models';
import { MaintenanceInformationModel } from 'models/CM/CM02Models';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { MdSave } from 'react-icons/md';
import { fullDate } from 'utils';
import toast from 'utils/toast';

interface Props {
  show: boolean;
  onHide?: () => void;
  contractAgreementId: string;
  maintenanceInformation: MaintenanceInformationModel[];
  onSubmit: (saveDatas: MaintenanceInformationModel[]) => void;
  periodTypeDDL: ItemModel[];
  isDisabled?: boolean;
}

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function EditMAModal(props: Props) {
  const [maintenanceInformation, setMaintenanceInformation] = useState<MaintenanceInformationModel[]>([]);

  useEffect(() => {
    if (props.maintenanceInformation) {
      setMaintenanceInformation(props.maintenanceInformation);
    }
  }, [props.maintenanceInformation]);

  const onSave = () => {
    if (maintenanceInformation.filter(x => x.description === '' || x.description === undefined || x.description === null).length > 0) {
      toast.warn('กรุณากรอกข้อมูลรายเอียดให้ครบถ้วน');
      return;
    }
    props.onSubmit(maintenanceInformation);
    props.onHide!();
  };

  const handlerOnChangeDescription = (value: string, index: number) => {
    const data = [...maintenanceInformation];
    data[index].description = value;
    setMaintenanceInformation(data);
  };

  const handlerOnChangePeriodType = (value: string, index: number) => {
    const data = [...maintenanceInformation];
    data[index].periodType = value;
    setMaintenanceInformation(data);
  };

  const handlerOnChangeTotalPeriodType = (value: string, index: number) => {
    const data = [...maintenanceInformation];
    data[index].totalPeriodType = value;
    setMaintenanceInformation(data);
  };

  const handlerOnChangeQuantity = (value: number, index: number) => {
    const data = [...maintenanceInformation];
    data[index].quantity = value;
    setMaintenanceInformation(data);
  };

  const handlerOnChangeTotalQuantity = (value: number, index: number) => {
    const data = [...maintenanceInformation];
    data[index].totalQuantity = value;
    setMaintenanceInformation(data);
  };

  const addItem = () => {
    const newData: MaintenanceInformationModel = {
      id: undefined,
      contractAgreementId: props.contractAgreementId,
      termOfRefId: undefined,
      sequence: maintenanceInformation.length + 1,
      description: undefined,
      quantity: 0,
      periodType: '',
      totalQuantity: 0,
      totalPeriodType: '',
      endDate: undefined,
    };

    setMaintenanceInformation((maintenanceInformation) => [...maintenanceInformation, newData]);
  };

  const removeItem = (i: number) => {
    const temp: MaintenanceInformationModel[] = [...maintenanceInformation];
    temp.splice(i, 1);
    setMaintenanceInformation(temp);
  };

  return (
    <Modal
      show={props.show}
      size='xl'
      onHide={() => (props.onHide ? props.onHide() : null)}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title className='text-primary'>รายละเอียดการจ้างบำรุงรักษา</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4 pt-2 pb-2'>
            <Row className='mt-3'>
              <Col sm={12}
                md={12}>
                <div className='d-flex justify-content-end'>
                  <ButtonCustom
                    onClick={addItem}
                    disabled={props.isDisabled}
                    text='เพิ่มรายการ' />
                </div>
              </Col>
            </Row>
            <Table hidePagination>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 20 }}>ลำดับ</th>
                  <th style={{ width: 300 }}>รายละเอียด</th>
                  <th style={{ width: 100 }}>ชำระเงินทุกๆ</th>
                  <th style={{ width: 100 }}>ระยะเวลา</th>
                  <th style={{ width: 100 }}>รวม</th>
                  <th style={{ width: 100 }}>ระยะเวลา</th>
                  <th style={{ width: 20 }} />
                </tr>
              </thead>
              <tbody>
                {
                  maintenanceInformation?.map((data, index) => (
                    <tr className='text-center'
                      key={data.id}>
                      <td>{index + 1}</td>
                      <td>
                        <InputTextArea
                          value={data.description}
                          onChange={(val) => handlerOnChangeDescription(val, index)}
                          disabled={props.isDisabled} />
                      </td>
                      <td>
                        <InputNumber value={data.quantity}
                          onChange={(val) => handlerOnChangeQuantity(Number(val), index)}
                          disabled={props.isDisabled} />
                      </td>
                      <td>
                        <Selector
                          value={data.periodType}
                          items={props.periodTypeDDL}
                          onChange={(value) => handlerOnChangePeriodType(value, index)}
                          disabled={props.isDisabled} />
                      </td>
                      <td>
                        <InputNumber value={data.totalQuantity}
                          onChange={(val) => handlerOnChangeTotalQuantity(Number(val), index)}
                          disabled={props.isDisabled} />
                      </td>
                      <td>
                        <Selector
                          value={data.totalPeriodType}
                          items={props.periodTypeDDL}
                          onChange={(value) => handlerOnChangeTotalPeriodType(value, index)}
                          disabled={props.isDisabled} />
                      </td>
                      <td>
                        <Button
                          onClick={() => removeItem(index)}
                          variant='danger'
                          disabled={props.isDisabled}
                        >
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </ModalBT.Body>
          <ModalBT.Footer className='justify-content-center'>
            <Button
              variant='outline-primary'
              onClick={() => (props.onHide ? props.onHide() : null)}
              className='me-2 px-3'
            >
              ยกเลิก
            </Button>
            <Button
              variant='primary'
              className='me-2 px-3'
              onClick={onSave}
              disabled={props.isDisabled}
            >
              <MdSave className='mx-2 pb-1 fs-4' />
              บันทึก
            </Button>
          </ModalBT.Footer>
        </>
      )}
    />
  );
}
