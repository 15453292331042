import {
  ButtonCustom,
  Card,
  Check,
  ContractReceiveCollateral,
  ContractReturnCollateralConsiderStatus,
  Table,
} from 'components';
import { Currency, Input, InputTextArea } from 'components/Controls';
import Title from 'components/Controls/Title';
import { StatusCMConsider } from 'components/StatusCMReturnCollateral';
import {
  IFile,
  ItemModel,
} from 'models';
import {
  AcceptorModel,
  AttachmentFileModel,
  CollateralItemsModel,
  ConsidersModel,
  ContractReturnCollateralConsiderResultModel,
  ContractReturnCollateralResultModel,
  CreateReturnCollateralModel,
  UpdateReturnCollateralModel,
} from 'models/CM/CM06Model';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router';
import CM06Service from 'services/CM/CM06Service';
import {
  HttpStatusCode,
  submitForm,
  thaiFormatDate,
  thaiFormatDateWithSlash,
  THCurrency,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { Context } from '.';
import { generateUniqueId } from '../../../../../utils/helper';
import { HistoryModal } from '../../Modal/HistoryModal';
import UploadFile from '../../Modal/UploadFileReturnCollateral';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  dataCollateral: ContractReturnCollateralResultModel;
}

type Loader = {
  returnCollateralConsiderResultType: ItemModel[],
};

enum ConsiderResultType {
  considerResultTypeReceivedId = '12ab76d4-42be-4ee7-86e3-6183d162b1c1',
}

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function Step1({ onClickNext, onClickBack, dataCollateral }: Props) {
  const { returnCollateralConsiderResultType } = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const { userId } = useAppContext();
  const location = useLocation();
  const [showWaitForInspectorReceivedModal, setShowWaitForInspectorReceivedModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { data, setData } = useContext(Context);
  const [collateralItemsData, setCollateralItemsData] = useState<CollateralItemsModel[]>([]);
  const [contractReturnCollateralConsiderResultData, setContractReturnCollateralConsiderResultData] = useState<ContractReturnCollateralConsiderResultModel[]>([]);
  const [considersData, setConsidersData] = useState<ConsidersModel[]>([]);
  const [inspectorCommentData, setInspectorCommentData] = useState('');
  const [isFine, setIsFine] = useState(false);
  const [fineAmount, setFineAmount] = useState<number>(0);
  const [collateralAmount, setCollateralAmount] = useState<number>(0);
  const [isNextStep, setIsNextStep] = useState(false);
  const [isResponsible, setIsResponsible] = useState(false);

  const backToDetail = () => {
    navigate(`/cm/cm06/detail/${data.contractReturnCollateralId ? data.contractReturnCollateralId : data.contractAgreementId}`);
  };

  const getReturnCollateralAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.getContractReturnCollateralConsiderByIdAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');
      return;
    }

    navigate(`/cm/cm06/detail/considers/${id}`);

    setData(data);
  }, []);

  const getFilesAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.getAttachmentConsiderAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    handleSetFile(data.attachments);

  }, []);

  useEffect(() => {

    if (data) {

      if (data.acceptors && data.acceptors.filter(x => x.userId === userId).length > 0) {
        setIsResponsible(true);
      } else {
        setIsResponsible(false);
      }

      if (data.id) {
        getFilesAsync(data.id);
      }

      if (!data.status || data.status === ContractReturnCollateralConsiderStatus.NONE || data.status === ContractReturnCollateralConsiderStatus.DRAFT || data.status === ContractReturnCollateralConsiderStatus.INSPECTORREJECTED) {

        setIsDisabled(false);
      } else {

        setIsDisabled(true);
      }

      if (data.isFine) {
        setIsFine(data.isFine);
      }

      if (data.collateralChannes) {
        setCollateralAmount(data.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0));
      }

      if (data.fineAmount) {
        setFineAmount(data.fineAmount);
        handleSumCollateralAmount(data.fineAmount, data.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0));
      }

      const considerResultData: ContractReturnCollateralConsiderResultModel[] = [];
      if (data.considerResults) {
        data.considerResults?.sort((a, b) => a.sequence - b.sequence)
          .forEach((item, index) => {
            considerResultData.push({
              considerResultTypeId: item.considerResultTypeId,
              name: returnCollateralConsiderResultType
                .filter((x) => x.value === item.considerResultTypeId
                  && x.value === ConsiderResultType.considerResultTypeReceivedId)[0]
                ? returnCollateralConsiderResultType
                  .filter((x) => x.value === item.considerResultTypeId
                    && x.value === ConsiderResultType.considerResultTypeReceivedId)[0].label.toLocaleString()
                  .replace('[received_date]', `${thaiFormatDateWithSlash(data.receivedDate)}`)
                  .replace('[paid_date]', `${thaiFormatDateWithSlash(data.paidDate)}`)
                : returnCollateralConsiderResultType.filter((x) => x.value === item.considerResultTypeId)[0].label,
              result: item.result,
              sequence: item.sequence,
            } as ContractReturnCollateralConsiderResultModel);
            setContractReturnCollateralConsiderResultData(considerResultData);
          });
      } else {
        returnCollateralConsiderResultType?.forEach((item, index) => {
          considerResultData.push({
            considerResultTypeId: item.value,
            name: item.label,
            result: data.considerResults?.filter((x) => x.considerResultTypeId === item.value)[index].result,
            sequence: index + 1,
          } as ContractReturnCollateralConsiderResultModel);
          setContractReturnCollateralConsiderResultData(considerResultData);
        });
      }

      if (data.inspectorComment) {
        setInspectorCommentData(data.inspectorComment);
      }

      if (!data.id) {
        handleConsiders();
      }

      if (data.considerResults) {
        const countResult = data.considerResults.length;
        const result = data.considerResults.filter(x => x.result).length;

        if (countResult === result) {
          setIsNextStep(true);
        } else {
          setIsNextStep(false);
        }

      }

    }

  }, [data, userId]);

  const handleConsiders = () => {
    const consider: ConsidersModel[] = [];
    consider?.push({
      inspectorComment: inspectorCommentData,
      contractManagementFinancialAmountId: data.contractManagementFinancialAmountId ? data.contractManagementFinancialAmountId : '',
      considerResults: contractReturnCollateralConsiderResultData || [],
      sequence: dataCollateral?.considers?.filter((x) => x.contractManagementFinancialAmountId === data.contractManagementFinancialAmountId)[0].sequence,
    } as ConsidersModel);

    setConsidersData(consider);

  };

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await CM06Service.uploadAttachmentConsiderAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getFilesAsync(data.id);
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {

    if (data && data.id) {
      const res = await CM06Service.removeAttachmentconsiderAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getFilesAsync(data.id);

      }
    }

  }, [files, data.id]);

  const onSubmitAsync = async () => {

    submitForm();
    // const countResult = contractReturnCollateralConsiderResultData.length;
    // const result = contractReturnCollateralConsiderResultData.filter(x => x.result).length;

    // if (countResult !== result) {
    //   toast.warn('กรุณาระบุบันทึกผลการพิจารณาคืนหลักประกันสัญญาให้ครบทุกข้อ');
    //   return;
    // }

    const docUpload: File[] = [];
    if (files) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });
      data.docNameforDelete = delFiles;
      data.files = [...docUpload];
    }

    if (data && data.id) {
      const updateDataModel: UpdateReturnCollateralModel = {
        id: data.id,
        acceptors: data.acceptors,
        considerResults: contractReturnCollateralConsiderResultData,
        inspectorComment: inspectorCommentData,
        sendToAcceptorApprove: false,
        collateralChannel: data.collateralChannes,
        fineAmount: fineAmount,
        isFine: isFine,
      };

      const res = await CM06Service.updateReturnCollateralModelAsync(data.id, updateDataModel);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {

        toast.success('บันทึกข้อมูลสำเร็จ');

        getReturnCollateralAsync(data.id);
        navigate(`${location.pathname}`);

      } else {
        toast.error(res.statusText);
      }
    } else {
      const considersDataList: ConsidersModel[] = [];

      considersData?.forEach((item, index) => {
        considersDataList.push({
          inspectorComment: inspectorCommentData,
          contractManagementFinancialAmountId: item.contractManagementFinancialAmountId,
          considerResults: item.considerResults,
          sequence: item.sequence,
          acceptors: item.acceptors,
          isFine: item.isFine,
          fineAmount: item.fineAmount,
        } as ConsidersModel);
      });

      const createDataModel: CreateReturnCollateralModel = {
        contractAgreementId: data.contractAgreementId,
        considers: considersDataList,
        collateralChannel: data.collateralChannes,
        fineAmount: fineAmount,
        isFine: isFine,
      };

      const res = await CM06Service.createReturnCollateralModelAsync(createDataModel);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {

        toast.success('บันทึกข้อมูลสำเร็จ');
        // const newId: string = res.data.considerItems.filter((x) => x.sequence === data.collateralItems[0].sequence)[0].id;
        const newId: string = res.data.considerItems[0].id;
        getReturnCollateralAsync(newId);
        navigate(`${location.pathname}`);

      } else {
        toast.error(res.statusText);
      }
    }
  };

  const onNext = () => {
    submitForm();

    data.considerResults = contractReturnCollateralConsiderResultData;
    data.collateralItems = collateralItemsData;
    data.inspectorComment = inspectorCommentData;

    onClickNext();
  };

  const onChangeCheckConsiderResult = (considerResultTypeId: string, value: boolean) => {
    const contractReturnCollateralConsiderResult = [...contractReturnCollateralConsiderResultData];
    const index = contractReturnCollateralConsiderResultData?.findIndex(s => s.considerResultTypeId === considerResultTypeId);
    contractReturnCollateralConsiderResult[index].result = value;
    setContractReturnCollateralConsiderResultData(contractReturnCollateralConsiderResult);

    if (!data.id) {
      const consider: ConsidersModel[] = [];
      const acceptor: AcceptorModel[] = [];

      consider?.push({
        inspectorComment: inspectorCommentData,
        contractManagementFinancialAmountId: data.contractManagementFinancialAmountId ? data.contractManagementFinancialAmountId : '',
        considerResults: contractReturnCollateralConsiderResult || [],
        sequence: dataCollateral?.considers?.filter((x) => x.contractManagementFinancialAmountId === data.contractManagementFinancialAmountId)[0].sequence,
        acceptors: acceptor,
      } as ConsidersModel);
      setConsidersData(consider);
    }
  };

  const handleSumCollateralAmount = (fineAmount: number, totalAmount: number) => {
    if ((fineAmount !== undefined || fineAmount !== null || fineAmount > 0) && (totalAmount !== undefined || totalAmount !== null || totalAmount > 0)) {
      const val = (totalAmount - fineAmount);

      setCollateralAmount(val);
    }

    setFineAmount(fineAmount);

  };

  const handleCollateralIsFine = (val: boolean) => {
    setIsFine(val);
    if (!val) {
      setFineAmount(0);
      handleSumCollateralAmount(0, data.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0))
    }
  };

  return (
    <div className='document'>
      <Title text='ขออนุมัติคืนหลักประกันสัญญา'
        extraElement={
          <>
            <StatusCMConsider systemStatue={data.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </>
        } />

      <Row className='mt-3'>
        <Title text='รายละเอียดหลักประกันสัญญา'
          lineBorder />
        <Col sm={12}>
          <Table total={2}
            hidePagination>
            <thead>
              <tr>
                <th className='text-center ps-3'
                  style={{ width: '20%' }}>รายละเอียดหลักประกัน
                </th>
                <th className='text-center'
                  style={{ width: '15%' }}>ร้อยละ <br />(ของราคาทั้งหมดตามสัญญา)
                </th>
                <th className='text-center'
                  style={{ width: '15%' }}>จำนวนเงิน
                </th>
              </tr>
            </thead>
            <tbody>
              {data.collateralChannes != null && data.collateralChannes?.length > 0
                ? (data.collateralChannes?.map((item, index) => (
                  <tr>
                    <td className='ps-3 text-start text-wrap'>
                      <div>
                        {item.typeName}
                      </div>
                      {(item.bankName) && (
                        <div>
                          {item.bankName} สาขา {item.bankBranch}
                        </div>
                      )}
                      {(item.bankAccountName) && (
                        <div>
                          ชื่อบัญชี {item.bankAccountName}
                        </div>
                      )}
                      {(item.bankAccountNumber) && (
                        <div>
                          เลขที่ {item.bankAccountNumber}
                        </div>
                      )}
                      {/* {
                        (item.bankCollateralStartDate)
                        && (
                          <div>
                            วันที่หนังสือค้ำประกัน {thaiFormatDate(item.bankCollateralStartDate)} -  {thaiFormatDate(item.bankCollateralEndDate)}
                          </div>
                        )
                      } */}
                    </td>
                    <td className='text-end'>{formatter.format(item.percent ?? 0)}</td>
                    <td className='text-end'>{formatter.format(item.amount ?? 0)}</td>
                  </tr>
                )))
                : (
                  <Col>
                    <span className='text-primary'>ไม่พบรายละเอียดหลักประกันสัญญา</span>
                  </Col>
                )}
            </tbody>

            {
              data.collateralChannes != null && data.collateralChannes?.length > 0 ?
                (
                  <>
                    <tfoot>
                      <tr className='bg-opacity-10 bg-primary p-4 rounded'
                        style={{ height: 70 }}>
                        <td colSpan={2}>
                          <p className='mb-0 text-primary text-end'>คืนหลักประกันสัญญาคงเหลือ :</p>
                        </td>
                        <td className='text-end'>
                          <span className='ms-3 text-dark'>
                            {
                              formatter.format(collateralAmount)
                            }
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                    <tfoot>
                      <tr className='bg-opacity-10 p-4 rounded'
                        style={{ height: 70 }}>
                        <td colSpan={2}>
                          <Check
                            label='หักค่าปรับจากเงินหลักประกันสัญญา'
                            onChange={(val) => handleCollateralIsFine(val)}
                            value={isFine}
                            disabled={isDisabled}
                          />
                        </td>
                        <td className='text-end'>
                          <Currency
                            value={fineAmount}
                            onChange={(val) => handleSumCollateralAmount(Number(val), data.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0))}
                            maxValue={data.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0)}
                            disabled={!isFine || isDisabled} />
                        </td>
                      </tr>
                    </tfoot>
                  </>
                )
                : (<Col>
                  <span className='text-primary'></span>
                </Col>)}
          </Table>
        </Col>
        <Col sm={12}
          className='mt-5'>
          <Title text='บันทึกผลการพิจารณาคืนหลักประกันสัญญา'
            lineBorder />
          <ul className='mt-3'>
            {contractReturnCollateralConsiderResultData.sort((a, b) => a.sequence - b.sequence)
              .map((data, index) => (
                <Check label={data.name}
                  onChange={(val) => onChangeCheckConsiderResult(data.considerResultTypeId, val)}
                  value={data.result}
                  disabled={isDisabled}
                />
              ))}
          </ul>
        </Col>
        <Col sm={12}
          className='mt-3'>
          <Title text='ความเห็นเพิ่มเติม'
            lineBorder />
          <InputTextArea label='ความเห็นคืนหลักประกันสัญญาเพิ่มเติม'
            value={inspectorCommentData}
            onChange={(val) => setInspectorCommentData(val)}
            disabled={isDisabled}
          />
        </Col>
      </Row>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5' />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFile
              disabled={!data.id}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.id!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <ButtonCustom text='ย้อนกลับ'
          onClick={backToDetail}
          icon='arrowback'
          variant='outline-primary' />
        {
          ((data.status === ContractReturnCollateralConsiderStatus.NONE || data.status === ContractReturnCollateralConsiderStatus.DRAFT || data.status === ContractReturnCollateralConsiderStatus.INSPECTORREJECTED))
          && (
            <div className='d-flex gap-3 align-items-center'>
              < ButtonCustom text='บันทึก'
                onClick={onSubmitAsync}
                variant='outline-primary' />
            </div>
          )
        }
        {
          (data.status !== ContractReturnCollateralConsiderStatus.NONE && isNextStep) ? (
            <div>
              <ButtonCustom text='ถัดไป'
                onClick={onNext}
                icon='arrowforward'
                variant='outline-primary'
                iconAlignRight />
            </div>
          ) : <div style={{ width: 145 }} />
        }
      </div>

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={data.id}
        title='คืนหลักประกันสัญญา'
        status={ContractReceiveCollateral.Consider}
      />

    </div >
  );
}

export default Step1;
