import { Role } from 'models';
import http from 'utils/axios';

const getRolesAsync = async (size: number, page: number, keyword?: string) => http.get('/role', { params: { keyword, page, size } });

const saveRoleAsync = async (role: Role) => http.post('/role', { ...role });

const getRoleByIdAsync = async (id: string) => http.get(`/role/${id}`);

const updateRoleAsync = async (role: Role) => http.put(`/role/${role.id}`, { ...role });

const getRoleDdlAsync = async () => http.get('/role/ddl');

export default {
  getRolesAsync,
  saveRoleAsync,
  getRoleByIdAsync,
  updateRoleAsync,
  getRoleDdlAsync,
}
