import {
  Card,
  DatePicker,
  Input,
  Radio,
  Selector,
  UploadFile,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { useState } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
  MdSave,
} from 'react-icons/md';
import { ModalEditPO } from '../Modal/ModalEditPO';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const INFO = [
  { label: 'มีหลักประกัน', value: '1' },
  { label: 'ไม่มีหลักประกัน', value: '2' },
];

function Step1({ onClickNext, onClickBack }: Props) {
  const [editPO, setEditPO] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลโครงการ" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={6}>
            <Input label="เลขที่รายการจัดซื้อจัดจ้าง" placeholder="เลขที่รายการจัดซื้อจัดจ้าง" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Input label="โครงการ" placeholder="โครงการ" disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="งบประมาณโครงการ (บาท)" placeholder="1,000,000.00" disabled textEnd />
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={3}>
            <Input label="วิธีจัดหา" value="80" disabled />
          </Col>
          <Col sm={12} md={3} className="pt-4">
            <Input value="เจาะจง" disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="เลขที่ PR" value="1,000,000.00" disabled textCenter />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="ข้อมูลสัญญา" className="fs-5 text-primary" />
        <Row className="mt-3">
          <Col sm={12} md={6}>
            <Input label="คู่ค้า" placeholder="คู่ค้า" disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="Email (คู่ค้า)" placeholder="Email (คู่ค้า)" rule={{ required: true }} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Input label="ชื่อสัญญา" placeholder="ชื่อสัญญา" />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} lg={3}>
            <Input label="เลขที่สัญญา PO" placeholder="เลขที่สัญญา PO" disabled textCenter rule={{ required: true }} />
          </Col>
          <Col sm={12} lg={3}>
            <Input label="เลขที่สัญญา จพ.(สบส.)" placeholder="เลขที่สัญญา จพ.(สบส.)" disabled textCenter rule={{ required: true }} />
          </Col>
          <Col sm={2} className='pt-4'>
            <Button
              className="me-2 px-3"
              variant="outline-primary"
              onClick={() => setEditPO(!editPO)}
            >
              แก้ไข PO
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={3}>
            <Input label="วงเงินตามสัญญา" placeholder="วงเงินตามสัญญา" textEnd rule={{ required: true }} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={3} >
            <Selector label="ประเภทสัญญา" rule={{ required: true }} />
          </Col>
          <Col sm={12} lg={3} >
            <Selector label="รูปแบบสัญญา" rule={{ required: true }} />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} lg={2}>
            <Input label="กำหนดส่งมอบภายใน" placeholder="กำหนดส่งมอบภายใน" disabled textCenter rule={{ required: true }} />
          </Col>
          <Col sm={12} lg={1} className="pt-4">
            <Selector disabled />
          </Col>
          <Col sm={12} lg={3} className="pt-4">
            <Selector />
          </Col>
          <Col sm={12} lg={3}>
            <DatePicker label="ครบกำหนดส่งมอบวันที่" disabled />
          </Col>

        </Row>
        <Row className="align-items-center">
          <Col sm={12} lg={2}>
            <Input label="ระยะเวลารับประกัน" placeholder="ระยะเวลารับประกัน" disabled textCenter rule={{ required: true }} />
          </Col>
          <Col sm={12} lg={1} className="pt-4">
            <Selector disabled />
          </Col>
          <Col sm={12} lg={3} className="pt-4">
            <Selector />
          </Col>
          <Col sm={12} lg={3}>
            <DatePicker label="ครบกำหนดรับประกันวันที่" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={6} >
            <Input label="ค่าปรับกรณีส่งมอบเกินกำหนดโดยคิดค่าปรับเป็นรายวัน" value="อัตราร้อยละ 0.2 ของราคาสิ่งของที่ยังไม่ได้รับมอบ" />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="ข้อมูลหลักประกันสัญญา" className="fs-5 text-primary" />
        <Row>
          <Col sm={12}>
            <Radio
              className="my-3"
              dflex
              items={INFO}
              name='name1'
              rule={{ required: true }}
            />
          </Col>
          <Col sm={12} lg={3}>
            <Selector label="ร้อยละของราคาค่าจ้างตามสัญญา " />
          </Col>
          <Col sm={12} lg={3}>
            <Input label="จำนวนเงินหลักประกัน" placeholder="จำนวนเงินหลักประกัน" rule={{ required: true }} textEnd />
          </Col>
        </Row>
      </Card>
      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <ButtonCustom
          text="บันทึก"
        />
        <ButtonCustom
          onClick={onClickNext}
          text="ถัดไป"
        />
      </div>
      <ModalEditPO
        show={editPO}
        onHide={() => setEditPO(!editPO)}
      />
    </div>
  );
}

export default Step1;
