import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Form,
} from 'react-bootstrap';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from 'react-icons/fa';
import { generateUniqueId } from '../utils/helper';

interface Props {
  total: number;
  onChange?: (size: number, page: number) => void;
}

export interface PaginationRefType {
  PaginationClear: () => void;
  CountRowClear: () => void;
}

const MAX_ROW = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const Pagination = forwardRef<PaginationRefType, Props>((props, ref) => {
  const [pageActive, setPageActive] = useState(1);
  const [pageAll, setPageAll] = useState(0);
  const [maxRowPerPage, setMaxRowPerPage] = useState<number>(10);
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (mounted) {
      if (props.onChange) {
        props.onChange(maxRowPerPage, pageActive);
      }
    }

    return () => {
      setMounted(true);
    };
  }, [maxRowPerPage, pageActive, mounted]);

  useImperativeHandle(ref, () => ({
    PaginationClear() {
      setPageActive(1);
    },

    CountRowClear() {
      setMaxRowPerPage(10);
    }
  }));

  const first = useCallback(() => {
    if (pageActive > 1) {
      setPageActive(1);
    }
  }, [pageActive]);

  const prev = useCallback(() => {
    if (pageActive > 1) {
      const page = pageActive === 1 ? 1 : (pageActive - 1);

      setPageActive(page);
    }
  }, [pageActive]);

  const go = useCallback((page: number) => {
    if (page !== pageActive) {
      setPageActive(page);
    }
  }, [pageActive]);

  const next = useCallback(() => {
    if (pageActive < pageAll) {
      const page = pageActive === pageAll ? pageAll : (pageActive + 1);

      setPageActive(page);
    }
  }, [pageActive, pageAll]);

  const last = useCallback(() => {
    if (pageActive < pageAll) {
      setPageActive(pageAll);
    }
  }, [pageActive, pageAll]);

  const changeMaxRowPerPage = useCallback((currentMaxRow: number) => {
    setMaxRowPerPage(currentMaxRow);
    setPageActive(1);
  }, []);

  const pageButton = useCallback((start: number, end: number) => {
    const templates: JSX.Element[] = [];

    for (let i = start; i <= end; i++) {
      templates.push(
        <Button
          variant="outline-primary"
          key={generateUniqueId(i)}
          className={`${pageActive === i ? 'active px-2' : 'px-2'}`}
          onClick={() => go(i)}
        >
          {i}
        </Button>,
      );
    }

    return templates;
  }, [go, pageActive]);

  const pages = useMemo(() => {
    const sum = props.total / maxRowPerPage;
    const split = sum.toString().split('.');
    let pageAll = split.length === 1 ? Number(split[0]) : Number(split[0]) + 1;

    if (pageAll === 0) {
      pageAll = 1;
    }

    setPageAll(pageAll);

    if (pageAll === 1) {
      setPageActive(1);
    }

    if (pageActive <= 3) {
      const end = pageAll > 5 ? 5 : pageAll;

      return pageButton(1, end);
    }
    const end = pageAll;

    if (end - pageActive > 2) {
      return pageButton(pageActive - 2, pageActive + 2);
    }
    return pageButton(end - 3, end);
  }, [pageActive, maxRowPerPage, pageButton, props.total]);

  return (
    <div className="pagination d-flex flex-column flex-xl-row">
      <div className="max-row mt-3 w-100 d-flex flex-row flex-fill justify-content-center justify-content-xl-start align-items-center gap-2">
        <p className="mb-0">แสดง</p>
        <Form.Select
          value={maxRowPerPage}
          onChange={(value) => changeMaxRowPerPage(Number(value.target.value))}
        >
          {MAX_ROW.map((m) => <option key={m.label} value={m.value}>{m.label}</option>)}
        </Form.Select>
        <p className="mb-0">รายการ จากทั้งหมด {props.total} รายการ</p>
      </div>
      <div className="page mt-3 w-100 d-flex flex-row flex-fill justify-content-center justify-content-xl-end gap-2">
        <Button className="d-flex align-items-center gap-2" variant="outline-primary" onClick={first}>
          <FaAngleDoubleLeft />
        </Button>
        <Button className="d-flex align-items-center gap-2" variant="outline-primary" onClick={prev}>
          <FaAngleLeft />
        </Button>
        {pages}
        <Button className="d-flex align-items-center gap-2" variant="outline-primary" onClick={next}>
          <FaAngleRight />
        </Button>
        <Button className="d-flex align-items-center gap-2" variant="outline-primary" onClick={last}>
          <FaAngleDoubleRight />
        </Button>
      </div>
    </div>
  );
});

export default Pagination;