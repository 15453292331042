import { Col, Row } from 'react-bootstrap';
import toast from 'utils/toast';
import copy from 'copy-to-clipboard';

function CHEditorRemark() {
  const tags = [
    'table_acceptor',
    'table_committee',
    'table_acceptor_committee'
  ];

  const remarks = [
    'ข้อความดังกล่าวจะแสดงอยู่บนหัวตารางของเอกสารบางฉบับ',
    'เมื่อดำเนินการส่งเห็นชอบ/อนุมัติ หรือดำเนินการสำเร็จแล้ว ข้อความนี้จะหายไป',
    'หากข้อความถูกลบออก กรุณาคัดลอกข้อความดังกล่าว ไปวางที่ตำแหน่งเดิม'
  ];

  const copyText = (textSource: string) => {
    copy(textSource);
    toast.success(`คัดลอกข้อความ '${textSource}' สำเร็จ`);
  }

  return (
    <div className='bg-light rounded bg-opacity-25 mt-3 p-3'>
      <Row>
        <Col
          sm={12}
          md={6}
          lg={4}
          style={{ fontSize: '13px' }}>
          <p>*หมายเหตุ: ห้ามลบข้อความที่ปรากฏอยู่ในเอกสาร ดังนี้</p>
          <div className='d-flex justify-content-between'>
            <div>
              <ul style={{ listStyleType: 'none' }}>
                {
                  tags.map((tag) => (
                    <li>
                      {tag} (ถ้ามี)
                      <span
                        onClick={() => copyText(tag)}
                        className='copy-span'>
                        คัดลอก
                      </span>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </Col>
        <Col
          sm={12}
          md={6}
          lg={8}
          style={{ fontSize: '13px' }}
          className='split-box-line'>
          <div>
            <ul style={{ listStyleType: 'circle' }}>
              {
                remarks.map((remark) => (
                  <li>{remark}</li>
                ))
              }
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CHEditorRemark;