import {
  ButtonCustom,
  Card,
  ContractAuditStatus,
  InputTextArea,
  StatusCM,
  TableStepBar,
} from 'components';
import Title from 'components/Controls/Title';
import { HistoryModal, Modal, SearchBuNameModal } from 'components/Modal';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Col,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { dataHistory } from './data';
import { Context } from '.';
import { Acceptors } from 'components/Table/Acceptors';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import { AcceptorModel, ContractAuditAttachment, ContractAuditModel } from 'models/CM/CM04Models';
import toast from 'utils/toast';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { HttpStatusCode, submitForm, useAppContext } from 'utils';
import { JorPorCode } from 'constant/basicInformation';
import { shareValue } from 'services';
import { isNull } from 'utils/helper';
import { FaHistory, FaRegCheckCircle, FaSave, FaUndo } from 'react-icons/fa';
import { BsCheckCircle } from 'react-icons/bs';
import CM04Service from 'services/CM/CM04Service';
import { StatusDetail, TypeStatus } from 'components/StatusDetail';
import UploadFile from '../UploadFile';
import Collabora, { CollaboraRef } from 'components/Document/Collabora';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

export default function Step2({ onClickNext, onClickBack }: Props) {
  const navigate = useNavigate();
  const { data, setData } = useContext(Context);
  const [acceptorModalShow, setAcceptorModalShow] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const [sectionType, setSectionType] = useState<string>();
  const [sectionSequence, setSectionSequence] = useState<number>();
  const [acceptors, setAcceptors] = useState<AcceptorModel[]>([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const { userId, departmentCode } = useAppContext();
  const [isApprover, setIsApprover] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [rejectRemark, setRejectRemark] = useState('');
  const [approveRemark, setApproveRemark] = useState('');
  const [showActivity, setShowActivity] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [showReCall, setShowReCall] = useState(false);
  const [checkReCall, setCheckReCall] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [docId, setDocId] = useState('');
  const [docIdAuditor, setDocIdAuditor] = useState('');
  const [docIdDirector, setDocIdDirector] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);

  const getDocument = useCallback(
    async (id: string) => {
      const { data: docId, status } =
        await CM04Service.getDocumentAsync(id);

      if (status === HttpStatusCode.OK) {
        setDocId(docId);
      }
    }, []);

  const getDocumentAuditor = useCallback(
    async (id: string) => {
      const { data: docIdAuditor, status } =
        await CM04Service.getDocumentAuditorAsync(id);
      if (status === HttpStatusCode.OK) {
        setDocIdAuditor(docIdAuditor);
      }
    }, []);

  const getDocumentDirector = useCallback(
    async (id: string) => {
      const { data: docIdDirector, status } =
        await CM04Service.getDocumentDirectorAsync(id);

      if (status === HttpStatusCode.OK) {
        setDocIdDirector(docIdDirector);
      }
    }, []);
  useEffect(() => {
    if (data) {
      getDocument(data.id);
      getDocumentAuditor(data.id);
      getDocumentDirector(data.id);

      if (data.attachments) {
        handleSetFile(data.attachments);
      }

      if ((data.status === ContractAuditStatus.Approved || data.status === ContractAuditStatus.Pending) && (departmentCode === JorPorCode.CODE)) {
        setIsDisabled(true)
      }

      if (data.acceptors) {
        setAcceptors(data.acceptors)
        setIsApprover(onCheckMessageApprover(data.acceptors));
        const queueReCall = data.acceptors?.filter(a => a.status === ContractAuditStatus.Approve).length === 0;

        if (queueReCall) {
          setCheckReCall(true);
        } else {
          setCheckReCall(false);
        }
      }

    }
  }, [data]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const onSelectEmpApprover = (empId: string, name: string, department: string, position: string, index: number, sectionSequence?: number, sectionType?: string, delegateUserId?: string, delegateFullName?: string, delegateDepartmentName?: string, delegatePositionName?: string) => {

    if (acceptors && acceptors.filter((x) => x.sectionInRefCode === sectionType && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {

      const acceptorBySection = acceptors.filter((x) => x.sectionSequence === sectionSequence);

      const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
        acceptorBySection.map((x) => {
          return x.sequence;
        })) : 0;

      const newData: AcceptorModel = {
        id: undefined,
        sequence: maxValueSequence + 1,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        status: ContractAuditStatus.Draft,
        sectionSequence: sectionSequence!,
        delegateUserId: delegateUserId,
        delegateFullName: delegateFullName,
        delegateDepartmentName: delegateDepartmentName,
        delegatePositionName: delegatePositionName,
        sectionType: sectionType!,
        sectionName: '',
        delegateEmployeeCode: '',
        delegateRangeDate: '',
        acceptRemark: '',
        acceptUserId: '',
        isAvailable: false,
        isAvailableRemark: '',
        rejectRemark: '',
        rejectUserId: '',
        sectionInRefCode: '',
        userApprovedBy: '',
        userRejectedBy: '',
        acceptDate: undefined,
        delegateAnnotation: '',
        rejectDate: undefined,
      };

      setAcceptors((acceptors) => [...acceptors, newData]);
    }
  };

  const isQueueApprover = useMemo(() => {

    if (data && data.acceptors && userId) {
      const minSection = Math.min(...data?.acceptors
        .filter(d => d.status === ContractAuditStatus.Pending)
        .map(o => o.sectionSequence));

      const sectionData = data?.acceptors
        .filter(d => d.sectionSequence === minSection
          && d.status === ContractAuditStatus.Pending)
        .sort((a, b) => a.sequence - b.sequence)[0];

      if (sectionData) {
        return isNull(sectionData.delegateUserId, sectionData.userId) === userId;
      }
    }

  }, [userId, data]);

  const onCheckMessageApprover = (data: AcceptorModel[]) => {

    // const maxValueSectionSequence = Math.max.apply(null,
    //   data?.map((x) => {
    //     return x.sectionSequence;
    //   }));

    // const maxValueSequence = Math.max.apply(null,
    //   data?.filter(a => a.sectionSequence === maxValueSectionSequence)?.map((x) => {
    //     return x.sequence;
    //   }));

    // const dataMaxValueSectionSequence = data?.filter(a => a.sectionSequence === maxValueSectionSequence && a.sequence === maxValueSequence);

    // const approverIndex = dataMaxValueSectionSequence?.findIndex(x => x.userId === userId || x.delegateUserId === userId) + 1;

    // if (approverIndex) {
    //   return true;
    // }

    const approver = data?.filter(x => x.status === ContractAuditStatus.Pending).length === 1;

    if (approver) {
      return true;
    }

    return false;
  };

  const onReject = async () => {
    if (!rejectRemark) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    const res = await CM04Service.onRejectAsync(data.id, rejectRemark);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
      return;
    }

    getContractAuditByIdAsynce(data.id);
    toast.success('ส่งกลับแก้ไขสำเร็จ');
    setShowRejectModal(false);
    setRejectRemark('');
  };

  const onApprove = async () => {
    const res = await CM04Service.onApproveAsync(data.id, approveRemark);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('อนุมัติไม่สำเร็จ');
      return;
    }

    setApproveRemark('');
    getContractAuditByIdAsynce(data.id);
    toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
    navigate(`/cm/cm04/detail/${data.id}`);
    setShowApproveModal(false);
  };

  const onSubmitAsync = async (isPending: boolean) => {
    submitForm();
    collaboraRef.current?.clickSave();

    const docUpload: File[] = [];
    // if (files) {
    //   files.forEach((i) => {
    //     if (i.file !== null) {
    //       docUpload.push(i.file);
    //     }
    //   });

    //   data.files = [...docUpload];
    // }

    if (data.id) {
      const httpStatus = HttpStatusCode.OK;
      const updateDataModel: ContractAuditModel = {
        id: data.id,
        documentDate: data.documentDate,
        contractStartDate: data.contractStartDate,
        contractEndDate: data.contractEndDate,
        acceptors: acceptors,
        contractAuditListSelecteds: data.contractAuditListSelecteds,
        files: docUpload,
        activities: data.activities,
        attachments: data.attachments,
        remark: data.remark,
        status: data.status,
        contractAuditNumber: data.contractAuditNumber,
        documentId: docId,
        documentAuditorId: docIdAuditor,
        documentDirectorId: docIdDirector,
        totalAmount: data.totalAmount,
      };
      setTimeout(async () => {

        if (httpStatus === HttpStatusCode.OK) {
          const res = await CM04Service.updateContractAuditAsync(updateDataModel);

          if (res.status === HttpStatusCode.OK) {

            if (isPending) {
              const resPending = await CM04Service.onPendingAsync(data.id, docId, docIdAuditor, docIdDirector);

              if (resPending.status !== HttpStatusCode.OK) {
                toast.error('ส่งเห็นชอบ/อนุมัติไม่สำเร็จ');
                return;
              }

              toast.success('ส่งเห็นชอบ/อนุมัติสำเร็จ');
              setShowPendingModal(false);
              getContractAuditByIdAsynce(data.id);
              return;
            }

            toast.success('บันทึกข้อมูลสำเร็จ');
            getContractAuditByIdAsynce(data.id);

          } else {
            toast.error('บันทึกข้อมูลไม่สำเร็จ');
          }
        }
      }, 500);
    }
  };

  const getContractAuditByIdAsynce = async (id: string) => {
    const response = await CM04Service.getContractAuditByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
      navigate(`/cm/cm04/detail/${id}`);
    }
  };

  const reCallContractAuditAsync = async () => {

    if (data && data.id) {
      const response = await CM04Service.onReCallAsync(data.id);

      if (response.status === HttpStatusCode.OK) {
        toast.success('เรียกคืนแก้ไขสำเร็จ');
        getContractAuditByIdAsynce(data.id);
      }
    }

    setShowReCall(false);
  };

  const getAttachmentAuditByIdAsync = async (id: string) => {
    const response = await CM04Service.getAttachmentAuditByIdAsync(id);
    if (response.status === HttpStatusCode.OK) {
      handleSetFile(response.data.attachments);
    }
  };

  const handleSetFile = (documents: ContractAuditAttachment[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.name,
        file: null,
        createdByUserId: document.createdByUserId,
        createdByUserFullName: document.createdByUserFullName,
        createdAt: document.createdAt,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleAuditFileChange = async (files: File[]) => {
    const newUpdFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };
      setFiles((pre) => [...pre, newFile]);
      newUpdFiles.push(file);
    }

    if (data.id) {
      const res = await CM04Service.uploadAttachmentAuditAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getAttachmentAuditByIdAsync(data.id);
      } else {
        toast.error('อัปโหลดไฟล์ไม่สำเร็จ');
      }
    }
    return;
  };

  const removeAuditItemAsync = useCallback(async (index: number, docId: string) => {

    if (data.id && docId) {
      const res = await CM04Service.removeAttachmentAuditAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getAttachmentAuditByIdAsync(data.id);
        return;
      }
    }

    toast.error('ลบไฟล์ไม่สำเร็จ');

  }, [files, data,]);

  return (
    <div className="document">
      {
        data?.acceptors ? (
          <Acceptors
            titleHistory='ประวัติรายงานสำนักงานตรวจเงินแผ่นดินและหนังสือถึงสรรพากร'
            data={acceptors.length > 0 ? acceptors : data.acceptors!}
            visible={(data.status === ContractAuditStatus.Draft || data.status === ContractAuditStatus.Rejected)}
            isDepartmentDirector={false}
            processType={PROCESSTYPE.ApproveContractAuditAndRevenueReport}
            budgetAmount={data?.totalAmount}
            onSetSectionType={(type) => setSectionType(type)}
            onAcceptorModalShow={() => setShowModals(!showModals)}
            onSetSectionSequence={(section) => setSectionSequence(section)}
            onAcceptorDataChange={(data) => setAcceptors(data)}
            status={data?.status}
            typeStatus={TypeStatus.ContractAudit}
            activity={data?.activities}
          />
        ) :
          <Card className='mt-3'>
            <Title
              text='ผู้มีอำนาจเห็นชอบ/อนุมัติ'
              className='fs-5 text-primary'
              extraElement={
                <div className='d-flex gap-2'>
                  <StatusDetail value={data?.status} type={TypeStatus.ContractAudit} />
                  <Button
                    onClick={() => setShowActivity(!showActivity)}
                    variant='outline-primary'>
                    <FaHistory className='me-1' />
                    ประวัติการใช้งาน
                  </Button>
                </div>
              }
            />
            <div className='d-flex flex-column gap-3 mt-3'>
              <Col>
                <span className='text-primary'>ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
              </Col>
            </div>
          </Card >
      }

      <Card className='mt-3'>
        <h5 className='fw-bold mt-6'>รายงานสำนักงานตรวจเงินแผ่นดินและหนังสือถึงสรรพากร</h5>
        <Collabora
          docId={docId}
          docName='doc-01'
          ref={collaboraRef}
          readonly={false} />
      </Card >

      <Card className='mt-3'>
        <h5 className='fw-bold mt-6'>รายงานสำนักงานตรวจเงินแผ่นดินถึงผู้ว่าการตรวจเงินแผ่นดิน</h5>
        <Collabora
          docId={docIdAuditor}
          docName='doc-02'
          ref={collaboraRef}
          readonly={false} />
      </Card >

      <Card className='mt-3'>
        <h5 className='fw-bold mt-6'>รายงานหนังสือถึงสรรพากรถึงอธิบดีกรมสรรพากร</h5>
        <Collabora
          docId={docIdDirector}
          docName='doc-03'
          ref={collaboraRef}
          readonly={false} />
      </Card >

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              files={files}
              handleFileChange={handleAuditFileChange}
              removeItem={removeAuditItemAsync}
              id={data.id}
              disabled={!data.id}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <ButtonCustom text="ย้อนกลับ" onClick={onClickBack} icon='arrowback' variant='outline-primary' />
        </div>
        <div className='d-flex gap-3 align-items-center'>
          {(departmentCode === JorPorCode.CODE) && (data?.status === ContractAuditStatus.Draft
            || data?.status === ContractAuditStatus.Rejected)
            && (
              <>
                <div style={{ width: 300 }} />
                <Button
                  onClick={() => onSubmitAsync(false)}
                  variant='success'
                >
                  <FaSave className='me-1' />
                  บันทึก
                </Button>
                <Button
                  className='me-2 px-3'
                  onClick={() => setShowPendingModal(true)}
                  variant='primary'
                >
                  <FaRegCheckCircle className='me-2 pb-1 fs-5' />
                  ส่งเห็นชอบ/อนุมัติ
                </Button>
                <div style={{ width: 300 }} />
              </>
            )}
          {(data.status === ContractAuditStatus.Pending && checkReCall && (departmentCode === JorPorCode.CODE)) && (
            <Button
              className='me-2 px-3'
              onClick={() => setShowReCall(true)}
              variant='danger'
            >
              <FaUndo className='mx-2 pb-1 fs-5' />
              เรียกคืนแก้ไข
            </Button>
          )}
          {(isQueueApprover && data.status === ContractAuditStatus.Pending) && (
            <>
              <Button
                className='me-2 px-3'
                onClick={() => setShowRejectModal(true)}
                variant='danger'
              >
                <FaUndo className='mx-2 pb-1 fs-5' />
                ส่งกลับแก้ไข
              </Button>
              <Button
                variant='success'
                className='me-2 px-3'
                onClick={() => setShowApproveModal(true)}
              >
                <BsCheckCircle className='me-2 pb-1 fs-5' />
                {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </Button>
            </>
          )}
        </div>
        <div style={{ width: 100 }} />
      </div>

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        data={dataHistory}
        title='ประวัติ'
      />

      <SearchBuNameModal
        total={userTotal}
        show={showModals}
        onHide={() => setShowModals(!showModals)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName) => onSelectEmpApprover(id, name, department!, position!, acceptors.length + 1, sectionSequence, sectionType, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <Modal
        show={showPendingModal}
        size='lg'
        onHide={() => setShowPendingModal(!showPendingModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งอนุมัติ/เห็นชอบหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingModal(!showPendingModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showReCall}
        size='lg'
        onHide={() => setShowReCall(!showReCall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowReCall(!showReCall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={reCallContractAuditAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejectModal}
        size='lg'
        onHide={() => setShowRejectModal(!showRejectModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRejectRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowRejectModal(!showRejectModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                variant='danger'
                onClick={onReject}
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApproveModal}
        size='lg'
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>{isApprover ? 'อนุมัติ' : 'เห็นชอบ'}</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                rule={{ required: false }}
                onChange={(val) => setApproveRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApproveModal(!showApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onApprove}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <HistoryModal
        title='ประวัติรายงานสำนักงานตรวจเงินแผ่นดินและหนังสือถึงสรรพากร'
        show={showActivity}
        onHide={() => setShowActivity(!showActivity)}
        data={data?.acceptors}
      />

    </div>
  );
}
