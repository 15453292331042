import {
  AppointmentOrderStatus,
  AppointmentStatus,
  InputTextArea,
  Modal,
} from 'components';
import Title from 'components/Controls/Title';
import { ProcurementAppointmentOrderStatus } from 'components/ProcurementAppointmentOrderStatus';
import Cookie from 'cookie-universal';
import { IFile } from 'models';
import {
  approverSectionsModel,
  documentsModel,
  UpdateAppointCommiteeModel,
} from 'models/procurementAppointmentOrderModel';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaCheckCircle,
  FaHistory,
  FaRegCheckCircle,
  FaSave,
  FaUndo,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import procurementAppointmentOrderServices from 'services/procurementAppointmentOrderServices';
import {
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import Collabora from '../../../../components/Document/Collabora';
import { HistoryModal } from '../HistoryModal';
import UploadFile from '../UploadFile';
import { Context } from './index';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step3({ onClickNext, onClickBack }: Props) {
  const { data, setData } = useContext(Context);
  const [, setShow] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const [isDisabled, setisDisabled] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showPendingAppointmentOrder, setShowPendingAppointmentOrder] = useState(false);
  const [showRejectedAppointmentOrder, setShowRejectedgAppointmentOrder] = useState(false);
  const [showApprovedAppointmentOrder, setShowApprovedAppointmentOrder] = useState(false);
  const [remark, setRemark] = useState('');
  const location = useLocation();
  const [, setCurrentPath] = useState('procurement-appointment-order/detail');
  const { userId } = useAppContext();
  const navigate = useNavigate();
  const cookies = Cookie();
  const userfullName = cookies.get('fullName') as string;
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [docId, setDocId] = useState('');
  const [isApprover, setIsApprover] = useState(false);

  const onCheckApprover = (datas: approverSectionsModel[]) => {
    const maxValueSectionSequence = Math.max.apply(null,
      datas?.map((x) => x.sectionSequence));

    const dataMaxValueSectionSequence = datas.filter((a) => a.sectionSequence === maxValueSectionSequence);

    const approverIndex = dataMaxValueSectionSequence?.findIndex((x) => x.userId === userId) + 1;

    const maxValue = Math.max.apply(null,
      dataMaxValueSectionSequence?.map((x) => x.sequence));

    if (approverIndex === maxValue) {
      return true;
    }
    return false;
  };

  const getDocument = useCallback(
    async (id: string) => {
      const { data: docId, status } = await procurementAppointmentOrderServices.getDocumentAsync(id);

      if (status === HttpStatusCode.OK) {
        setDocId(docId);
      }
    }, []);

  useEffect(() => {
    const paramId = location.pathname.slice(location.pathname.lastIndexOf('/'), location.pathname.length);
    setCurrentPath(location.pathname.replace(paramId, ''));
  }, []);

  useEffect(() => {
    if (data) {
      if (data.documents) {
        handleSetFile(data.documents);
      }

      if (data.approverSections) {
        const queueApprover = onCheckQueueApprover(data.approverSections);
        setIsQueueApprover(queueApprover);

        const isApprover = onCheckApprover(data.approverSections);
        setIsApprover(isApprover);
      }

      if (data.status === AppointmentOrderStatus.None || data.status === AppointmentStatus.DRAFT || data.status === AppointmentStatus.REJECTED) {
        setisDisabled(false);
      } else {
        setisDisabled(true);
      }

      if (data.documents) {
        handleSetFile(data.documents);
      }

      if (docId) {
        return;
      }

      getDocument(data.id);
    }
  }, [data, userId]);

  const onCheckQueueApprover = (datas: approverSectionsModel[]) => {
    if (data.status === AppointmentOrderStatus.PENDING) {
      const minSection = Math.min(...datas.filter((d) => d.status === AppointmentOrderStatus.PENDING && !d.isDisabled).map((o) => o.sectionSequence));

      const sectionDatas = datas.filter((a) => a.status === AppointmentOrderStatus.PENDING && a.sectionSequence === minSection && !a.isDisabled).sort((a, b) => a.sequence - b.sequence);

      if (sectionDatas && sectionDatas.length > 0) {
        return sectionDatas[0].userId === userId;
      }
      return false;
    }
    return false;
  };

  const handleSetFile = (documents: documentsModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const getDataAppointmentOrderByIdAsync = async () => {
    const response = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(data.jorPor05Id, data.id);
    if (response.status === HttpStatusCode.OK) {
      if (data.files) {
        data.files = [];
      }
      setData(response.data);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await procurementAppointmentOrderServices.UploadAppointmentOrderAttachmentAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getDataAppointmentOrderByIdAsync();
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName) {
      setDelFiles((delFiles) => [...delFiles, docName]);
    }

    const delFile = [];
    delFile.push(docName);
    if (data && data.id) {
      const res = await procurementAppointmentOrderServices.deleteAppointmentOrderDocumentAsync(
        data.id,
        delFile,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('ลบไฟล์สำเร็จ');
        getDataAppointmentOrderByIdAsync();
      }
    }
  }, [files, data.id]);

  const onPendingAppointmentOrderSubmit = async () => {
    submitForm();
    if (data.approverSections) {
      if (data.approverSections.filter((x) => x.isDisabled === true && !x.remark).length > 0) {
        toast.warn('กรุณาระบุหมายเหตุที่ไม่สามารถปฏิบัติงานได้');
        return;
      }
    }
    const response = await procurementAppointmentOrderServices.submitPendingAppointmentOrderAsync(data.id);

    if (response.status === HttpStatusCode.OK) {
      toast.success('ส่งอนุมัติสำเร็จ');
      if (response.status === HttpStatusCode.OK) {
        const res = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(data.jorPor05Id, data.id);
        setData(res.data);
      }
      navigate(`${location.pathname}`);
    } else {
      toast.error('ส่งอนุมัติไม่สำเร็จ');
    }

    setShowPendingAppointmentOrder(false);
  };

  const onSubmitAsync = async (isSendApprove: boolean) => {
    submitForm();

    if (data.approverSections) {
      if (data.approverSections.filter((x) => x.isDisabled === true && !x.remark).length > 0) {
        toast.warn('กรุณาระบุหมายเหตุที่ไม่สามารถปฏิบัติงานได้');
        return;
      }
    }

    const docUpload: File[] = [];
    if (files) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });
      data.docNameforDelete = delFiles;
      data.files = [...docUpload];
    }

    if (data && data.id) {
      const httpStatus = HttpStatusCode.OK;

      const updateDataModel: UpdateAppointCommiteeModel = {
        id: data.id,
        planId: data.planId,
        planNumber: data.planNumber,
        planName: data.planName,
        torId: data.torId,
        jorPor04Number: data.jorPor04Number,
        jorPor05Id: data.jorPor05Id,
        memorandumDate: data.memorandumDate,
        departmentId: data.departmentId,
        supplyMethodType: data.supplyMethodType,
        supplyMethod: data.supplyMethod,
        supplyMethodSpecialType: data.supplyMethodSpecialType,
        budgetYear: data.budgetYear,
        planBudget: data.planBudget,
        expectingProcurementAt: data.expectingProcurementAt,
        subject: data.subject,
        sourceAndReasons: data.sourceAndReasons,
        status: data.status,
        deliveryQuantity: data.deliveryQuantity,
        deliveryUnit: data.deliveryUnit,
        committeeProcurements: data.committeeProcurements,
        dutyProcurements: data.dutyProcurements,
        committeeInspectors: data.committeeInspectors,
        dutyInspectors: data.dutyInspectors,
        approverSections: data.approverSections,
        files: docUpload,
        updatedByUserFullName: userfullName,
        docNameforDelete: data.docNameforDelete,
      };

      if (httpStatus === HttpStatusCode.OK) {
        const res = await procurementAppointmentOrderServices.updateAppointCommiteeAsync(updateDataModel);
        if (data.docNameforDelete === undefined) data.docNameforDelete = [];

        if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
          setShow(false);

          if (isSendApprove) {
            const response = await procurementAppointmentOrderServices.submitPendingAppointmentOrderAsync(data.id);
            setShowPendingAppointmentOrder(false);
            if (response.status === HttpStatusCode.OK) {
              toast.success('ส่งอนุมัติสำเร็จ');
            } else {
              toast.error('ส่งอนุมัติไม่สำเร็จ');
            }
          } else {
            toast.success('บันทึกข้อมูลสำเร็จ');
          }

          const res = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(data.jorPor05Id, data.id);
          if (res.status === HttpStatusCode.OK) {
            if (data.files) {
              data.files = [];
            }
            setData(res.data);
          }

          navigate(`${location.pathname}`);
          onClickNext();
        } else {
          toast.error(res.statusText);
        }
      }
    }
  };

  const onRejectedAppointmentOrderSubmit = async () => {
    submitForm();

    if (remark === null || remark === '') {
      return toast.error('กรุณากรอกเหตุผลส่งกลับแก้ไข');
    }

    const response = await procurementAppointmentOrderServices.submitRejectedAppointmentOrderAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK) {
      toast.success('ส่งกลับแก้ไขสำเร็จ');
      if (response.status === HttpStatusCode.OK) {
        const res = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(data.jorPor05Id, data.id);
        setData(res.data);
      }
      navigate(`${location.pathname}`);
    } else {
      toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
    }

    setShowRejectedgAppointmentOrder(false);
  };

  const onApprovedAppointmentOrderSubmit = async () => {
    const response = await procurementAppointmentOrderServices.submitApprovedAppointmentOrderAsync(data.id);

    if (response.status === HttpStatusCode.OK) {
      toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
      const res = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(data.jorPor05Id, data.id);
      setData(res.data);
      if (res.status === HttpStatusCode.OK) {
        setData(res.data);
      }

      navigate(`${location.pathname}`);
    }

    setShowApprovedAppointmentOrder(false);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Card.Body>
          <div className='d-flex justify-content-between my-3'>
            <Title
              text='เอกสารรายงานผลการจัดทำคำสั่งแต่งตั้งคณะกรรมการจัดซื้อจัดจ้างและคณะกรรมการตรวจรับ'
              className='fs-5 text-primary' />
            <div className='d-flex gap-2'>
              <ProcurementAppointmentOrderStatus
                value={data.status}
              />
              <Button
                className='px-3'
                onClick={() => setShowHistory(!showHistory)}
                variant='outline-primary'
              >
                <FaHistory className='me-3' />
                ประวัติการใช้งาน
              </Button>
            </div>
          </div>
          <Collabora
            docId={docId}
            docName='doc-01'
            readonly={false} />
        </Card.Body>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              appointmentId={data.id}
            />
          </Col>
        </Row>
      </Card>
      <div className='d-flex justify-content-between gap-3 pt-3'>
        <Button
          onClick={onClickBack}
          className='me-2 px-3'
          variant='outline-primary'
        >
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          {(data.status === AppointmentOrderStatus.PENDING && isQueueApprover) && (
            <Button
              className='me-2 px-3'
              onClick={() => setShowRejectedgAppointmentOrder(true)}
              variant='danger'>
              <FaUndo className='mx-2 pb-1 fs-5' />
              ส่งกลับแก้ไข
            </Button>
          )}
          {
            (((data.status === AppointmentOrderStatus.DRAFT && data.isResponsible) || (data.status === AppointmentOrderStatus.REJECTED && data.isResponsible))) && (
              <Button
                onClick={() => onSubmitAsync(false)}
                className='me-2 px-3'
                variant='outline-primary'>
                <FaSave className='me-2 pb-1 fs-5' />
                บันทึก
              </Button>
            )
          }
          {
            ((data.status === AppointmentOrderStatus.DRAFT && data.isResponsible) || (data.status === AppointmentOrderStatus.REJECTED && data.isResponsible)) && (
              <Button
                className='me-2 px-3'
                onClick={() => setShowPendingAppointmentOrder(true)}
              >
                <FaRegCheckCircle className='me-2 pb-1 fs-5' />
                ส่งอนุมัติเห็นชอบ
              </Button>
            )
          }

          {(data.status === AppointmentOrderStatus.PENDING && isQueueApprover) && (

            <Button
              onClick={() => setShowApprovedAppointmentOrder(true)}
              className='me-2 px-3'
              variant='success'
            >
              <FaCheckCircle className='me-2 pb-1 fs-5' />
              {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
            </Button>
          )}
        </div>
        <div />
      </div>
      <HistoryModal
        title='ประวัติการใช้งานจัดทำคำสั่งแต่งตั้งคณะกรรมการจัดซื้อจัดจ้างและคณะกรรมการตรวจรับ'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={data.id}
      />
      <Modal
        show={showPendingAppointmentOrder}
        size='lg'
        onHide={() => setShowPendingAppointmentOrder(!showPendingAppointmentOrder)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งเห็นชอบ/อนุมัติ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingAppointmentOrder(!showPendingAppointmentOrder)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onPendingAppointmentOrderSubmit}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApprovedAppointmentOrder}
        size='lg'
        onHide={() => setShowApprovedAppointmentOrder(!showApprovedAppointmentOrder)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>
                {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              {/* ต้องการเห็นชอบ/อนุมัติ หรือไม่? */}
              <InputTextArea
                label='ความเห็นเพิ่มเติม (ถ้ามี)'
                value={remark}
                onChange={(value) => setRemark(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApprovedAppointmentOrder(!showApprovedAppointmentOrder)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onApprovedAppointmentOrderSubmit}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejectedAppointmentOrder}
        size='lg'
        onHide={() => setShowRejectedgAppointmentOrder(!showRejectedAppointmentOrder)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='เหตุผล'
                rule={{ required: true }}
                value={remark}
                onChange={(value) => setRemark(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='outline-primary'
                className='me-2 px-3'
                onClick={() => setShowRejectedgAppointmentOrder(!showRejectedAppointmentOrder)}
              >
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onRejectedAppointmentOrderSubmit}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step3;
