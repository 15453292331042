import {
  Check,
  DatePicker,
  Input,
  Status,
  StatusType,
  SupplyMethodEnum,
  SupplyMethodNameEnum,
  Table,
  Modal,
  Selector,
} from 'components';
import { PurchaseOrderApprovalStatusConstant } from 'constant/PurchaseOrderApprovalConstant';
import { ItemModel } from 'models';
import {
  PurchaseOrderApprovalResponseListModel,
  SearchPurchaseOrderApprovalModel,
} from 'models/PR/PurchaseOrderApprovalModels';
import {
  CountStatusModel,
  CountSupplyMethodModel,
} from 'models/procurementAppointmentOrderModel';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import PurchaseOrderApprovalServices from 'services/PR/PurchaseOrderApprovalServices';
import {
  fullDate,
  HttpStatusCode,
  THCurrency,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';

interface Criteria {
  traderTaxId?: string;
  traderName?: string;
  torNumber?: string;
  torName?: string;
  startDate?: Date;
  endDate?: Date;
  isPermission: boolean;
}

type Loader = { departmentDDL: ItemModel[], supplyMethodDDL: ItemModel[], contractTypeDDL: ItemModel[] };

interface StatusItemModel {
  label: string;
  value: boolean;
}

const defaultStatus = [PurchaseOrderApprovalStatusConstant.Assigned, PurchaseOrderApprovalStatusConstant.Draft, PurchaseOrderApprovalStatusConstant.Signed, PurchaseOrderApprovalStatusConstant.Rejected, PurchaseOrderApprovalStatusConstant.None, PurchaseOrderApprovalStatusConstant.WaitingForSign];

const defaultMethod = [SupplyMethodEnum.MethodId60, SupplyMethodEnum.MethodId80];

export default function PR0206() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [dataList, setDataList] = useState<PurchaseOrderApprovalResponseListModel[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const { departmentDDL, supplyMethodDDL, contractTypeDDL } = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<SearchPurchaseOrderApprovalModel>({} as SearchPurchaseOrderApprovalModel);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>([]);
  const [methodAll, setMethodAll] = useState<boolean>(false);
  const [method60, setMethod60] = useState<boolean>(false);
  const [method80, setMethod80] = useState<boolean>(false);
  const [supplyMethods] = useState<ItemModel[]>(supplyMethodDDL);
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [statusDraft, setStatusDraft] = useState<boolean>(false);
  const [statusNone, setStatusNone] = useState<boolean>(false);
  const [statusWaitingForSign, setStatusWaitingForSign] = useState<boolean>(false);
  const [statusSigned, setStatusSigned] = useState<boolean>(false);
  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [statusAssigned, setStatusAssigned] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const [indexId, setIndexId] = useState<number>(0);
  const [methodLists, setMethodLists] = useState<StatusItemModel[]>([
    { value: false, label: SupplyMethodEnum.MethodId60 },
    { value: false, label: SupplyMethodEnum.MethodId80 },
  ]);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    { value: false, label: PurchaseOrderApprovalStatusConstant.Assigned },
    { value: false, label: PurchaseOrderApprovalStatusConstant.Draft },
    { value: false, label: PurchaseOrderApprovalStatusConstant.Signed },
    { value: false, label: PurchaseOrderApprovalStatusConstant.Rejected },
    { value: false, label: PurchaseOrderApprovalStatusConstant.WaitingForSign },
    { value: false, label: PurchaseOrderApprovalStatusConstant.None },
  ]);
  const { departmentId } = useAppContext();

  const goToPage = (jorPor06Id: string, basicInformationId: string, id: string) => {
    if (jorPor06Id) {

      navigate(`${id ? `/pr/pr0206/detail/${jorPor06Id}/id/${id}` : `/pr/pr0206/detail/${jorPor06Id}`} `);
    } else if (basicInformationId) {

      navigate(`${id ? `/pr/pr0206/detail/basicInformation/${basicInformationId}/id/${id}` : `/pr/pr0206/detail/basicInformation/${basicInformationId}`} `);
    } else {

      navigate(`${id ? `/pr/pr0206/detail/id/${id}` : `/pr/pr0206/detail/${jorPor06Id}`} `);
    }
  };

  useEffect(() => {
    setCriteria({ isAssigneeOnly: true } as SearchPurchaseOrderApprovalModel);
    setMethodAll(true);
    setMethod60(true);
    setMethod80(true);
    setStatusAll(true);
    setStatusAssigned(true);
    setStatusDraft(true);
    setStatusSigned(true);
    setStatusRejected(true);
    setStatusNone(true);
    setStatusWaitingForSign(true);
    getListDataAsync(criteria);
  }, []);

  const getListDataAsync = async (searchModel: SearchPurchaseOrderApprovalModel) => {

    if (!searchModel.page) {
      searchModel.page = page;
    }

    if (!searchModel.size) {
      searchModel.size = size;
    }

    if (searchModel.isAssigneeOnly === undefined) {
      searchModel.isAssigneeOnly = true;
    }

    if (searchModel.supplyMethod === undefined || searchModel.supplyMethod.length === 0) {
      searchModel.supplyMethod = defaultMethod;
    }

    if (searchModel.status === undefined || searchModel.status.length === 0) {
      searchModel.status = defaultStatus;
    }

    const res = await PurchaseOrderApprovalServices.getPurchaseOrderApprovalListAsync(
      searchModel,
    );

    if (res.status === HttpStatusCode.OK) {
      setDataList(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }

    getCountStatus(searchModel);
    getCountSupplyMethod(searchModel);
  };

  const getCountStatus = useCallback(async (searchModel: SearchPurchaseOrderApprovalModel) => {
    const countStatusRes = await PurchaseOrderApprovalServices.getCountStatusAsync(
      searchModel,
    );
    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data);
    }
  }, []);

  const getCountSupplyMethod = useCallback(async (searchModel: SearchPurchaseOrderApprovalModel) => {
    const countMethodRes = await PurchaseOrderApprovalServices.getCountSupplyMethodAsync(searchModel);

    if (countMethodRes.status === HttpStatusCode.OK) {
      setCountSupplyMethod(countMethodRes.data);
    }

  }, []);

  const onPaginationChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);
    onSearch(size, page);
  };

  const onSearch = (size: number, page: number) => {
    const method: string[] = [];
    const status: string[] = [];

    if (method60) {
      method.push(supplyMethods.filter((x) => x.value === SupplyMethodEnum.MethodId60)[0].value);
    }

    if (method80) {
      method.push(supplyMethods.filter((x) => x.value === SupplyMethodEnum.MethodId80)[0].value);
    }

    if (statusNone) {
      status.push(PurchaseOrderApprovalStatusConstant.None);
    }

    if (statusWaitingForSign) {
      status.push(PurchaseOrderApprovalStatusConstant.WaitingForSign);
    }

    if (statusAssigned) {
      status.push(PurchaseOrderApprovalStatusConstant.Assigned);
    }

    if (statusDraft) {
      status.push(PurchaseOrderApprovalStatusConstant.Draft);
    }

    if (statusRejected) {
      status.push(PurchaseOrderApprovalStatusConstant.Rejected);
    }

    if (statusSigned) {
      status.push(PurchaseOrderApprovalStatusConstant.Signed);
    }

    const searchPurchaseOrderApproval = {
      entrepreneursTaxId: criteria.entrepreneursTaxId,
      entrepreneursName: criteria.entrepreneursName,
      planName: criteria.planName,
      planNumber: criteria.planNumber,
      jorPor05Number: criteria.jorPor05Number,
      recordStartDate: criteria.recordStartDate,
      recordEndDate: criteria.recordEndDate,
      supplyMethod: method,
      departmentId: criteria.departmentId,
      status,
      isAssigneeOnly: criteria.isAssigneeOnly,
      contractType: criteria.contractType,
      page,
      size,
    } as SearchPurchaseOrderApprovalModel;
    getListDataAsync(searchPurchaseOrderApproval);
  };

  const onClearSearch = () => {
    const isSize = 10;
    const isPage = 1;
    setPage(isPage);
    setSize(isSize);
    setMethodAll(true);
    setMethod60(true);
    setMethod80(true);
    setStatusAll(true);
    setStatusAssigned(true);
    setStatusNone(true);
    setStatusWaitingForSign(true);
    setStatusDraft(true);
    setStatusSigned(true);
    setStatusRejected(true);
    onPaginationChange(isSize, isPage);
    setCriteria({ isAssigneeOnly: true, page: isPage, size: isSize } as SearchPurchaseOrderApprovalModel);
    const searchPurchaseOrderApproval = {
      page: isPage, size: isSize, isAssigneeOnly: true, supplyMethod: [], status: [],
    } as SearchPurchaseOrderApprovalModel;
    getListDataAsync(searchPurchaseOrderApproval);
  };

  const onChangeMethodAll = (result: boolean) => {
    if (result) {
      setMethodAll(true);
      setMethod60(true);
      setMethod80(true);
    } else {
      setMethodAll(false);
      setMethod60(false);
      setMethod80(false);
    }
  };

  const onChangeCheckMethod = (methodId: string, value: boolean) => {
    const methodList = [...methodLists];
    const index = methodLists.findIndex(s => s.label === methodId);
    methodList[index].value = value;
    setMethodLists(methodList);

    if (!value) {
      setMethodAll(false);
    }

    if (methodList.filter(s => s.value === false).length === 0) {
      setMethodAll(true);
    }

    switch (methodId) {
      case SupplyMethodEnum.MethodId60:
        return setMethod60(value);
      case SupplyMethodEnum.MethodId80:
        return setMethod80(value);
    }
  };

  const onChangeStatusAll = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setStatusNone(true);
      setStatusWaitingForSign(true);
      setStatusAssigned(true);
      setStatusDraft(true);
      setStatusSigned(true);
      setStatusRejected(true);
    } else {
      setStatusAll(false);
      setStatusNone(false);
      setStatusWaitingForSign(false);
      setStatusAssigned(false);
      setStatusDraft(false);
      setStatusSigned(false);
      setStatusRejected(false);
    }
  };

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex(s => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter(s => s.value === false).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case PurchaseOrderApprovalStatusConstant.Assigned:
        return setStatusAssigned(value);
      case PurchaseOrderApprovalStatusConstant.Signed:
        return setStatusSigned(value);
      case PurchaseOrderApprovalStatusConstant.Draft:
        return setStatusDraft(value);
      case PurchaseOrderApprovalStatusConstant.Rejected:
        return setStatusRejected(value);
      case PurchaseOrderApprovalStatusConstant.None:
        return setStatusNone(value);
      case PurchaseOrderApprovalStatusConstant.WaitingForSign:
        return setStatusWaitingForSign(value);
    }
  };

  const openModalDelete = (index: number, id: string) => {
    setShowModal(true);
    setDeleteId(id);
    setIndexId(index);
  };

  const deleteAsync = async (index: number, id: string) => {
    if (id) {
      const response = await await PurchaseOrderApprovalServices.deleteContractRecordAsync(id);
      if (response.status === HttpStatusCode.OK) {
        toast.success('ลบรายการสำเร็จ');
        const temp: PurchaseOrderApprovalResponseListModel[] = [...dataList];
        temp.splice(index, 1);
        setDataList(temp);
        setShowModal(false);
      } else {
        toast.error('เกิดข้อผิดพลาด');
      }
    } else {
      toast.error('เกิดข้อผิดพลาด');
    }

    setShowModal(false);
  };

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>อนุมัติใบสั่งซื้อ/จ้าง/เช่า และแจ้งทำสัญญา</h4>
        <Button onClick={() => navigate('detail')}><MdAdd className='me-2 fs-5' />สร้างใบสั่งซื้อ/จ้าง/เช่า และแจ้งทำสัญญา</Button>
      </div>
      <hr />
      <Row className='criteria'>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='เลขที่รายงานขอซื้อขอจ้าง (จพ.005)'
            onChange={jorPor05Number => setCriteria({ ...criteria, jorPor05Number })}
            value={criteria.jorPor05Number} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='เลขที่รายการจัดซื้อจัดจ้าง'
            onChange={planNumber => setCriteria({ ...criteria, planNumber })}
            value={criteria.planNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='ชื่อโครงการ'
            onChange={planName => setCriteria({ ...criteria, planName })}
            value={criteria.planName} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector label="ฝ่าย/สำนัก"
            placeholder="ฝ่าย/สำนัก"
            items={departmentDDL}
            value={criteria.departmentId}
            onChange={value => setCriteria({ ...criteria, departmentId: value })} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='เลขประจำตัวผู้เสียภาษี'
            onChange={val => setCriteria({ ...criteria, entrepreneursTaxId: Number(val) })}
            value={criteria.entrepreneursTaxId}
            type='number' />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='ชื่อบริษัท'
            onChange={entrepreneursName => setCriteria({ ...criteria, entrepreneursName })}
            value={criteria.entrepreneursName} />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='ประเภทอนุมัติใบสั่งซื้อ/จ้าง/เช่า'
            items={contractTypeDDL}
            value={criteria.contractType}
            onChange={contractType => setCriteria({ ...criteria, contractType })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker
            label='วันที่บันทึกเริ่มต้น'
            onChangeHasNullable={recordStartDate => setCriteria({ ...criteria, recordStartDate })}
            value={criteria.recordStartDate} />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker
            label='วันที่บันทึกสิ้นสุด'
            onChangeHasNullable={recordEndDate => setCriteria({ ...criteria, recordEndDate })}
            value={criteria.recordEndDate} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className='mb-3'>
            <Form.Label>วิธีจัดหา</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={(
                  <div className='align-items-center d-flex'>ทั้งหมด<Badge className='h-50 mx-2'
                    bg='light'>{countSupplyMethod.length > 0 ? countSupplyMethod.map((x) => x.count)
                      .reduce((prev, curr) => prev + curr) : 0}
                  </Badge>
                  </div>
                )}
                value={methodAll}
                onChange={(val) => onChangeMethodAll(val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>{SupplyMethodNameEnum.Method80}
                    <Badge className='h-50 mx-2'
                      bg='primary'>{countSupplyMethod.filter((x) => x.supplyMethod === SupplyMethodEnum.MethodId80).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethod === SupplyMethodEnum.MethodId80)[0].count : 0}
                    </Badge>
                  </div>
                )}
                value={method80}
                onChange={(val) => onChangeCheckMethod(SupplyMethodEnum.MethodId80, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>{SupplyMethodNameEnum.Method60}
                    <Badge className='h-50 mx-2'
                      bg='primary'>{countSupplyMethod.filter((x) => x.supplyMethod === SupplyMethodEnum.MethodId60).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethod === SupplyMethodEnum.MethodId60)[0].count : 0}
                    </Badge>
                  </div>
                )}
                value={method60}
                onChange={(val) => onChangeCheckMethod(SupplyMethodEnum.MethodId60, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ </Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={(
                  <div className='align-items-center d-flex'>ทั้งหมด <Badge className='h-50 mx-2'
                    bg='light'>{countStatus.reduce((a, v) => a += v.count, 0)}
                  </Badge>
                  </div>
                )}
                value={statusAll}
                onChange={(val) => onChangeStatusAll(val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>รอดำเนินการ <Badge className='h-50 mx-2'
                    bg='secondary'>
                    {countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.None).length > 0 ? countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.None)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusNone}
                onChange={(val) => onChangeCheckStatus(PurchaseOrderApprovalStatusConstant.None, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>ร่าง <Badge className='h-50 mx-2'
                    bg='secondary'>
                    {countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.Draft).length > 0 ? countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.Draft)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusDraft}
                onChange={(val) => onChangeCheckStatus(PurchaseOrderApprovalStatusConstant.Draft, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>รอลงนาม <Badge className='h-50 mx-2'
                    bg='warning'>
                    {countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.WaitingForSign).length > 0 ? countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.WaitingForSign)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusWaitingForSign}
                onChange={(val) => onChangeCheckStatus(PurchaseOrderApprovalStatusConstant.WaitingForSign, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>ลงนามแล้ว <Badge className='h-50 mx-2'
                    bg='success'>
                    {countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.Signed).length > 0 ? countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.Signed)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusSigned}
                onChange={(val) => onChangeCheckStatus(PurchaseOrderApprovalStatusConstant.Signed, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>ส่งกลับแก้ไข <Badge className='h-50 mx-2'
                    bg='danger'>
                    {countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.Rejected).length > 0 ? countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.Rejected)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusRejected}
                onChange={(val) => onChangeCheckStatus(PurchaseOrderApprovalStatusConstant.Rejected, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>มอบหมายงานแล้ว<Badge className='h-50 mx-2'
                    bg='success'>{countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.Assigned).length > 0 ? countStatus.filter((x) => x.status === PurchaseOrderApprovalStatusConstant.Assigned)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusAssigned}
                onChange={(val) => onChangeCheckStatus(PurchaseOrderApprovalStatusConstant.Assigned, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12}
          md={4}
          xl={3}>
          <Check label='แสดงเฉพาะรายการที่ได้รับมอบหมาย'
            value={criteria.isAssigneeOnly}
            onChange={(value) => setCriteria({ ...criteria, isAssigneeOnly: value })} />
        </Col>
      </Row>
      <div className='d-flex gap-2'>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => onSearch(size, page)}>
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant='outline-primary'
          className='d-flex align-items-center gap-2'
          onClick={onClearSearch}>
          <FaEraser />ล้าง
        </Button>
      </div>
      <Table
        className='mt-3'
        total={totalRecords}
        onChange={(size, page) => {
          onPaginationChange(size, page);
        }}>
        <thead>
          <tr className='text-center'>
            <th>เลขที่รายงานขอซื้อขอจ้าง<br />(จพ.005)</th>
            <th>เลขที่รายการ<br />จัดซื้อจัดจ้าง</th>
            <th>ชื่อโครงการ</th>
            <th>สถานะ</th>
            <th>ฝ่าย/สำนัก</th>
            <th>งบประมาณ (บาท)</th>
            <th>วิธีจัดหา</th>
            <th>ประเภทอนุมัติใบสั่งซื้อ/จ้าง/เช่า</th>
            <th>วันทีบันทึก</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {dataList?.map((d, i) => (
            <>
              <tr key={d.id}>
                <td className='text-center'>
                  <Button variant='link'
                    onClick={() => goToPage(d.jorPor06Id, d.basicInformationId, d.id)}>
                    {d.jorPor05Number}
                  </Button>
                </td>
                <td>
                  {/* <p className='mb-0 text-center'>{d.planNumber}</p> */}
                  <Button variant='link'
                    onClick={() => goToPage(d.jorPor06Id, d.basicInformationId, d.id)}>
                    {d.planNumber}
                  </Button></td>
                <td>{d.planName}</td>
                <td>
                  <Status value={d.status}
                    type={StatusType.PURCHASE_ORDER_APPROVAL} />
                </td>
                <td>{d.departmentShortCode} : {d.departmentName}</td>
                <td className='text-end'>{THCurrency(d.financialAmount)}</td>
                <td>{d.supplyMethodName} : {d.supplyMethodSpecialTypeName}</td>
                <td className='text-start'>{d.contractTypeName}</td>
                <td className='text-center'>{fullDate(d.recordDate)}</td>
                <td className='text-center'>
                  {(!d.jorPor06Id && !d.basicInformationId && (d.status === PurchaseOrderApprovalStatusConstant.Draft || d.status === PurchaseOrderApprovalStatusConstant.Rejected)) && (
                    <Button
                      disabled={departmentId !== d.departmentId}
                      variant='danger'
                      onClick={() => openModalDelete(i, d.id)}>
                      <FaTrashAlt />
                    </Button>
                  )
                  }
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
      <Modal
        show={showModal}
        size='lg'
        onHide={() => setShowModal(!showModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบรายการ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการลบรายการใบสั่งซื้อ/จ้าง/เช่า และแจ้งทำสัญญา หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-danger'
                onClick={() => setShowModal(!showModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  deleteAsync(indexId, deleteId);
                }}
              >
                ยืนยันลบข้อมูล
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}
