import Cookie from 'cookie-universal';
import { ContractCompleteReportAcceptorModel, ContractCompleteReportItemModel, ContractCompleteReportModel, SearchCriteriaModel } from 'models/CM/ContractCompleteReportModel';
import http from 'utils/axios';

const qs = require('qs');

const cookies = Cookie();
const getFullName = cookies.get('fullName') as string;
const api = '/ContractCompleteReport';

const getContractCompleteReportByIdAsync = (id: string, contractManagementType?: string) => {

  const params = {
    contractManagementType: contractManagementType
  };

  return http.get(`${api}/${id}`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getContractCompleteReportItemByCriteriaAsync = (data: ContractCompleteReportModel) => {
  const params = {
    contractCompleteReportId: data.id,
    budgetYear: data.budgetYear,
    startSignDate: data.startSignDate,
    endSignDate: data.endSignDate
  };

  return http.get(`${api}/contract-complete-item`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const CreateContractCompleteReport = (data: ContractCompleteReportModel, items: ContractCompleteReportItemModel[]) => {
  const params = {
    contractCompleteReportDate: data.contractCompleteReportDate,
    budgetYear: data.budgetYear,
    quarterId: data.quarterId,
    startSignDate: data.startSignDate,
    endSignDate: data.endSignDate,
    contractCompleteReportItem: items
  };

  return http.post(`${api}`, params);
};

const UpdateContractCompleteReport = (data: ContractCompleteReportModel, items: ContractCompleteReportItemModel[]) => {
  const params = {
    contractCompleteReportDate: data.contractCompleteReportDate,
    budgetYear: data.budgetYear,
    quarterId: data.quarterId,
    startSignDate: data.startSignDate,
    endSignDate: data.endSignDate,
    contractCompleteReportItem: items,
  };

  return http.put(`${api}/${data.id}`, params);
};

const getContractCompleteReportListAsync = (data: SearchCriteriaModel, page: number, size: number) => {
  const params = {
    contractCompleteReportNumber: data.contractCompleteReportNumber,
    budgetYear: data.budgetYear,
    quarterId: data.quarterId,
    contractCompleteReportStatusList: data.contractCompleteReportStatusList,
    page: page,
    pageSize: size,
    isResponsible: data.isResponsible,
  };

  return http.get(`${api}`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getCountStatusAsync = (data: SearchCriteriaModel) => {
  const params = {
    contractCompleteReportNumber: data.contractCompleteReportNumber,
    budgetYear: data.budgetYear,
    quarterId: data.quarterId,
    contractCompleteReportStatusList: data.contractCompleteReportStatusList,
    isResponsible: data.isResponsible,
  };
  return http.get(`${api}/status-count`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const UpdateContractCompleteReportAcceptor = (id: string, datas: ContractCompleteReportAcceptorModel[], isSendPending: boolean, docId: string) => {
  const params = {
    contractCompleteReportAcceptors: datas,
    isSendPending: isSendPending,
    documentId: docId,
  };

  return http.put(`${api}/${id}/acceptors`, params);
};

const AcceptorApproveAsync = (id: string, remark: string) => {
  const params = {
    Remark: remark
  };

  return http.put(`${api}/${id}/acceptorApprove`, params);
};

const AcceptorRejectAsync = (id: string, remark: string) => {
  const params = {
    Remark: remark
  };

  return http.put(`${api}/${id}/acceptorReject`, params);
};

const OnRecallAsync = (id: string, remark?: string) => {
  const params = {
    Remark: remark
  };

  return http.put(`${api}/${id}/recall`, params);
};

const getListHistoryAsync = (id: string, page: number, size: number) => {
  const params = {
    id: id,
    page: page,
    pageSize: size
  };

  return http.get(`${api}/activities`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getExcelReportAsync = (id: string, quarterId?: string, budgetYear?: number, contractManagementType?: string) => {

  const params = {
    contractManagementType: contractManagementType,
    quarterId: quarterId,
    budgetYear: budgetYear,
  };

  return http.get(`${api}/${id}/report`, {
    responseType: 'blob',
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getDocumentAsync = (id: string, documentTemplate: string) => {
  const params = {
    documentTemplate: documentTemplate,
  };

  return http.get(`${api}/${id}/document`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getContractCompleteReportSummaryAsync = (budgetYear: number, quarter: number) => {
  const params = {
    budgetYear: budgetYear,
    quarter: quarter,
  };

  return http.get(`${api}/contract-complete-summary`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const ContractCompleteReportService = {
  getContractCompleteReportByIdAsync,
  getContractCompleteReportItemByCriteriaAsync,
  CreateContractCompleteReport,
  UpdateContractCompleteReport,
  getContractCompleteReportListAsync,
  getCountStatusAsync,
  UpdateContractCompleteReportAcceptor,
  AcceptorApproveAsync,
  AcceptorRejectAsync,
  OnRecallAsync,
  getListHistoryAsync,
  getExcelReportAsync,
  getDocumentAsync,
  getContractCompleteReportSummaryAsync,
};

export default ContractCompleteReportService;
