import { DepartmentResponse } from 'models';
import { ItemModel } from 'models/systemUtility';
import {
  department,
  shareValue,
} from 'services';

const procurementAppointmentOrderLoader = async () => {
  const departmentItems = await GetDDL('Department');
  const supplyMethodItems = await GetDDL('SupplyMethod');
  const supplyMethodTypeItems = await GetDDL('SupplyMethodType');
  const planItems = await GetDDL('Plan');
  return {
    departmentDDL: departmentItems,
    supplyMethodDDL: supplyMethodItems,
    supplyMethodTypeDDL: supplyMethodTypeItems,
    planDDL: planItems,
  };
};

const GetDDL = async (Type: string) => {
  const itemForMap: ItemModel[] = [];

  switch (Type) {
    case 'Department': {
      const response = await department.getDepartmentsAsync();
      const departmentData: DepartmentResponse[] = response.data;
      departmentData.forEach((data) => itemForMap.push({ label: data.name, value: data.id ?? '' }));
      break;
    }
    case 'SupplyMethod': {
      const response = await shareValue.getSupplyMethodListAsync();
      const supplyMethodData: ItemModel[] = response.data;
      supplyMethodData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
    case 'SupplyMethodType': {
      const response = await shareValue.getSupplyMethodTypeListAsync();
      const supplyMethodTypeData: ItemModel[] = response.data;
      supplyMethodTypeData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
  }

  return itemForMap;
};

export {
  procurementAppointmentOrderLoader,
};
