import { ProcessStatus } from "components";

const textLastSectionApprover = (datas: any) => {
  const queueApprover = datas?.filter((a: { status: ProcessStatus; isDisabled: any; }) => a.status === ProcessStatus.PENDING && !a.isDisabled)

  if (queueApprover.length === 1) {
    return "อนุมัติ";
  }

  return "เห็นชอบ";
};

export {
  textLastSectionApprover,
};
