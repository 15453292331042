import {
  useRef,
  useState,
} from 'react';
import { MdOutlineAttachFile } from 'react-icons/md';

export function UploadButton() {
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };
  const handleDisplayFileDetails = () => {
    inputRef.current?.files &&
    setUploadedFileName(inputRef.current.files[0].name);
  };
  return (
    <>
      <input
        ref={inputRef}
        onChange={handleDisplayFileDetails}
        className='d-none'
        type='file'
      />
      <button
        type='button'
        onClick={handleUpload}
        className='btn btn-outline-primary text-truncate max-width'>
        {
          uploadedFileName || <>Upload <MdOutlineAttachFile /> </>
        }
      </button>
    </>
  );
}
