import Cookie from 'cookie-universal';
import { User } from 'models';
import { ProcurementSection } from 'utils';
import http from 'utils/axios';
import { ActingPositionModel, IAccountImageResponse } from '../models/accountModel';
import { CriteriaSupplyMethodId } from 'constant/PreProcumentFlag';

const cookies = Cookie();

const qs = require('qs');

const signInAsync = (username: string, password: string) => {
  const formData = new FormData();
  formData.set('Username', username);
  formData.set('Password', password);

  return http.post('/account/signin', formData);
};

const saveUserAsync = async (user: User) => await http.post('/account', user);

const getUserAsync = async () => await http.get('/account/profile');

const getUsersOnModalAsync = async (size: number, page: number, name?: string, department?: string, position?: string) => {
  return await http.get('/account', {
    params: {
      page,
      size,
      name,
      department,
      position,
      isActive: true
    }
  });
};

const getUserByIdAsync = async (id: string) => {
  return await http.get(`/account/${id}`);
};

const getActingPositionAsync = (employeeCode: string) => {
  const params = {
    employeeCode: employeeCode,
  };

  return http.get<ActingPositionModel[]>(`/account/acting-position`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getMenusAsync = async () => await http.get('/account/menu');

const updateUserAsync = async (user: User, file?: File) =>
  await http.put(
    `/account/${user.id}`,
    {
      isActive: user.isActive,
      roles: user.roles,
      file
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

const refreshTokenAsync = async () => {
  const refreshToken = cookies.get('refresh-token');

  return await http.post('/account/refresh-token', { refreshToken });
};

const getUserDdlAsync = async () => await http.get('/account/ddl');

const getUsersAsync = async (
  size: number,
  page: number,
  name?: string,
  email?: string,
  tel?: string,
  departmentId?: string,
  positionName?: string,
  roleId?: string,
  updatedBy?: string,
  isActive?: boolean
) => {
  return await http.get('/account', {
    params: {
      page,
      size,
      name,
      email,
      tel,
      positionName,
      departmentId,
      roleId,
      updatedBy,
      isActive
    }
  });
};

const getDefaultDepartmentDirectorAgree = async (sectionCode: ProcurementSection) => {
  return await http.get('/account/default-department-director-agree', { params: { sectionCode } });
};

const getDefaultApprover = async (processType: string, budget: number, supplyMethodId?: string, ignorePurchaseOnTable159?: boolean, departmentCode?: string, byUserId?: string) => {
  if (supplyMethodId
    && supplyMethodId === CriteriaSupplyMethodId.MethodId60) {
    return await http.get('/account/default-approver-72');
  }
  return await http.get('/account/default-approver-by-top-level', { params: { processType, budget, ignorePurchaseOnTable159, departmentCode, byUserId } });
};

const getDefaultApproverByTopLeve = async (processType: string, budget: number, supplyMethodId?: string, ignorePurchaseOnTable159?: boolean, departmentCode?: string, byUserId?: string) => {

  if (supplyMethodId
    && supplyMethodId === CriteriaSupplyMethodId.MethodId60) {
    return await http.get('/account/default-approver-72');
  }
  return await http.get('/account/default-approver-by-top-level', { params: { processType, budget, ignorePurchaseOnTable159, departmentCode, byUserId } });
};

const getDefaultApproverContract = async (processType: string, budget: number, supplyMethodId?: string) => {

  let refBankOrder = 159;

  if (supplyMethodId === CriteriaSupplyMethodId.MethodId60) {
    refBankOrder = 528;
  }

  return await http.get('/account/default-approver-by-top-level', { params: { processType, budget, refBankOrder } });
};

const getDefaultApproverBySupplyMethod = async (processType: string, budget: number, refBankOrder: number, ignorePurchaseOnTable159?: boolean, departmentCode?: string, byUserId?: string) => {
  return await http.get('/account/default-approver-by-top-level', { params: { processType, budget, refBankOrder, ignorePurchaseOnTable159, departmentCode, byUserId } });
};

const getDefaultApproverByTopLevel285 = async (processType: string, budget: number, supplyMethodId?: string, byUserId?: string) => {
  const refBankOrder = 285;

  return await http.get('/account/default-approver-by-top-level', { params: { processType, budget, refBankOrder, byUserId } });
};

const getProfileImageAsync = async (id: string) => await http.get<IAccountImageResponse>(`/account/${id}/signature-image`);

export default {
  signInAsync,
  saveUserAsync,
  getUserAsync,
  getUserByIdAsync,
  getMenusAsync,
  updateUserAsync,
  refreshTokenAsync,
  getUserDdlAsync,
  getUsersOnModalAsync,
  getUsersAsync,
  getDefaultDepartmentDirectorAgree,
  getDefaultApprover,
  getProfileImageAsync,
  getDefaultApproverByTopLeve,
  getDefaultApproverContract,
  getDefaultApproverByTopLevel285,
  getDefaultApproverBySupplyMethod,
  getActingPositionAsync,
};
