import {
  Breadcrumb,
  Card,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal, SearchBuNameModal } from 'components/Modal';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useLoaderData, useNavigate, useParams } from 'react-router';
import {
  ItemModel,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
} from '../../models';
import { AccountModel, ActingPositionModel, UserListModel } from '../../models/accountModel';
import { DelegateResponseModel } from '../../models/delegateModel';
import {
  account,
  shareValue,
} from '../../services';
import delegateService from '../../services/delegateService';
import { HttpStatusCode, submitForm, thaiFormatDateWithSlash, useAppContext } from '../../utils';
import toast from '../../utils/toast';
import { JorPorCode } from 'constant/basicInformation';
import { Level } from 'constant/SectionType';

const BREADCRUMB_INFO = [
  {
    routerName: '/delegate', label: 'การมอบหมายให้ปฏิบัติหน้าที่แทน',
  },
  {
    routerName: '', label: 'การมอบหมายให้ปฏิบัติหน้าที่แทน (เพิ่ม / แก้ไข)',
  },
];

const enum DELEGATE_TYPE {
  OWNER = 'owner',
  DELEGATE = 'delegate'
}

const enum ActingType {
  R = 'R',
  X = 'X',
  A = 'A'
}

type Loader = {
  buGroupDDL: ItemModel[],
  buLineDDL: ItemModel[],
  buDepartmentDDL: ItemModel[],
  buSegmentDDL: ItemModel[],
};

function AddDelegate() {
  const {
    buGroupDDL,
    buLineDDL,
    buDepartmentDDL,
    buSegmentDDL,
  } = useLoaderData() as Loader;
  const { id, startDate, endDate } = useParams();
  const [ownerUserShow, setOwnerUserShow] = useState(false);
  const [delegateUserShow, setDelegateUserShow] = useState<boolean[]>([]);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [ownerUser, setOwnerUser] = useState<AccountModel>();
  const [saveData, setSaveData] = useState<DelegateResponseModel>({} as DelegateResponseModel);
  const { departmentCode } = useAppContext();
  const [currentDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [isCanEditAll, setIsCanEditAll] = useState(true);
  const [isCanEditEndDate, setIsCanEditEndDate] = useState(true);
  const [oldEndDate, setOldEndDate] = useState(new Date());
  const [oldStartDate, setOldStartDate] = useState(new Date());
  const [delegateUserList, setDelegateUserList] = useState<UserListModel[]>([]);
  const [lineDDL, setLineDDL] = useState<ItemModel[]>([]);
  const [departmentDDL, setDepartmentDDL] = useState<ItemModel[]>([]);
  const [actingPosition, setActingPosition] = useState<ActingPositionModel[]>([]);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const [isSelectUser, setIsSelectUser] = useState<boolean[]>([]);
  const [groupLineDDL, setGroupLineDDL] = useState<ItemModel[]>([]);
  const [isChangeOwner, setIsChangeOwner] = useState(false);

  const onOpenModal = (index: number, acting: string) => {

    if (delegateUserList.filter(x => x.level === Level.Department && x.acting === acting && (x.buSegmentId === null || x.buSegmentId === undefined || x.buSegmentId === '')).length > 0) {
      submitForm();

      setIsSelectUser((isSelectUser) => {
        const newSelect = [...isSelectUser];
        newSelect[index] = true;
        return newSelect;
      });

      return toast.warn('กรุณาเลือกส่วนงาน');
    }

    if (delegateUserList.filter(x => x.level === Level.Line && x.acting === acting && (x.buDepartmentId === null || x.buDepartmentId === undefined || x.buDepartmentId === '')).length > 0) {
      submitForm();

      setIsSelectUser((isSelectUser) => {
        const newSelect = [...isSelectUser];
        newSelect[index] = true;
        return newSelect;
      });

      return toast.warn('กรุณาเลือกฝ่าย');
    }

    if (delegateUserList.filter(x => x.level === Level.Group && x.acting === acting && (x.buLineId === null || x.buLineId === undefined || x.buLineId === '')).length > 0) {
      submitForm();

      setIsSelectUser((isSelectUser) => {
        const newSelect = [...isSelectUser];
        newSelect[index] = true;
        return newSelect;
      });

      return toast.warn('กรุณาเลือกสายงาน');
    }

    if (delegateUserList.filter(x => x.level === Level.Head && x.acting === acting && (x.buGroupId === null || x.buGroupId === undefined || x.buGroupId === '')).length > 0) {
      submitForm();

      setIsSelectUser((isSelectUser) => {
        const newSelect = [...isSelectUser];
        newSelect[index] = true;
        return newSelect;
      });

      return toast.warn('กรุณาเลือกกลุ่มงาน');
    }

    setDelegateUserShow((delegateUserShow) => {
      const newModal = [...delegateUserShow];
      newModal[index] = true;
      return newModal;
    });

  };

  const onHideModal = (index: number) => {
    setDelegateUserShow((delegateUserShow) => {
      const newModal = [...delegateUserShow];
      newModal[index] = false;
      return newModal;
    });
  };

  const backToIndex = () => navigate('/delegate');
  const navigate = useNavigate();

  useEffect(() => {
    if (id !== undefined && startDate !== undefined && endDate !== undefined) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      getDelegateDataAsync(id, startDate, endDate);
    }
  }, [id]);

  useEffect(() => {
    if (saveData !== undefined && saveData.id !== undefined) {
      if (oldStartDate <= currentDate) {
        setIsCanEditAll(oldEndDate < currentDate);
      }
      setIsCanEditEndDate(oldStartDate <= currentDate || oldEndDate >= currentDate);

      if (oldEndDate < currentDate) {
        setIsCanEditAll(false);
        setIsCanEditEndDate(false);
      }
    }
  }, [saveData]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getDelegateByIdAsync = async (id: string, startDate: string, endDate: string) => {

    const { data, status } = await delegateService.getDelegateAsync(id, startDate, endDate);

    if (status === HttpStatusCode.OK) {
      return data as DelegateResponseModel;
    }
  };

  const getDelegateDataAsync = async (id: string, startDate: string, endDate: string) => {

    const delegateData = await getDelegateByIdAsync(id, startDate, endDate);

    if (!delegateData) {
      return;
    }

    if (delegateData.id) {
      setIsChangeOwner(true)
    }

    setSaveData(delegateData);
    setOldStartDate(new Date(new Date(delegateData.start).setHours(0, 0, 0, 0)));
    setOldEndDate(new Date(new Date(delegateData.end).setHours(0, 0, 0, 0)));
    getUserDataAsync(delegateData.ownerDelegates.id, DELEGATE_TYPE.OWNER, delegateData.delegateUsers);
    // getUserDataAsync(delegateData.delegateUserDelegates.id, DELEGATE_TYPE.DELEGATE);

    delegateData.delegateUsers.forEach((data, index) => {
      data.departmentDDL = buDepartmentDDL.filter(x => x.refCode === data.buLineCode);
      data.lineDDL = buLineDDL.filter(x => x.refCode === data.buGroupCode);
    });

    // setDelegateUserList(delegateData.delegateUsers);
    // handlerUserList(delegateData.ownerDelegates.id, delegateData.delegateUsers);
    return;
  };

  const getUserProfileByIdAsync = async (userId: string) => {
    const { data, status } = await account.getUserByIdAsync(userId);

    if (status === HttpStatusCode.OK) {
      return data as AccountModel;
    }
  };

  const getActingPositionAsync = async (userId: string) => {
    const { data, status } = await account.getActingPositionAsync(userId);

    if (status === HttpStatusCode.OK) {
      return data as ActingPositionModel[];
    }
  };

  const handlerActingPosition = async (employeeCode: string, delegateUsers?: UserListModel[]) => {

    const actingPosition = await getActingPositionAsync(employeeCode);

    if (actingPosition) {

      const ActingPositionDatas: ActingPositionModel[] = [];

      actingPosition.forEach((item, index) => {
        ActingPositionDatas.push({
          acting: item.acting,
          coalesceBu: item.coalesceBu,
          coalesceBuName: item.coalesceBuName,
          employeeCode: item.employeeCode,
          groupId: item.groupId,
          groupName: item.groupName,
          inRefCode: item.inRefCode,
          lineId: item.lineId,
          lineName: item.lineName,
          positionId: item.positionId,
          positionName: item.positionName,
          userId: item.userId,
          lineCode: item.lineCode,
          groupCode: item.groupCode,
          levelName: item.levelName,
          level: item.level,
          headId: item.headId,
          headCode: item.headCode,
          headName: item.headName,
          departmentId: item.departmentId,
          departmentCode: item.departmentCode,
          departmentName: item.departmentName,
          segmentId: item.segmentId,
          segmentCode: item.segmentCode,
          segmentName: item.segmentName,
        } as ActingPositionModel);
      });

      const uniqueNames = ActingPositionDatas.filter((data, index) => ActingPositionDatas.findIndex(d => d.acting === data.acting) === index);
      setActingPosition(uniqueNames);


      if (delegateUsers && actingPosition) {

        delegateUsers.forEach((item, index) => {
          item.level = actingPosition.filter(x => x.acting === item.acting)[0].level;
        });

        setDelegateUserList(delegateUsers);
      }
    }
  };

  const getUserDataAsync = async (
    userId: string,
    type: DELEGATE_TYPE,
    delegateUsers?: UserListModel[],
    acting?: string,
    isChangeOwner?: boolean,
    lineCode?: string,
    lineId?: string,
    lineName?: string,
    groupCode?: string,
    groupId?: string,
    groupName?: string,
    headId?: string,
    headCode?: string,
    headName?: string,
    departmentId?: string,
    departmentCode?: string,
    departmentName?: string,
    segmentId?: string,
    segmentName?: string,
    segmentCode?: string,
    level?: string) => {

    if (type === DELEGATE_TYPE.OWNER) {
      const userOwnerData = await getUserProfileByIdAsync(userId);

      if (!userOwnerData) {
        return;
      }

      handlerActingPosition(userOwnerData.employeeCode, delegateUsers);

      setSaveData((prevState) => ({ ...prevState, ownerDelegates: { ...prevState.ownerDelegates, id: userOwnerData.id, position: userOwnerData.buPositionName, department: userOwnerData.departmentName } }));
      setOwnerUser(userOwnerData);

      if (isChangeOwner) {
        const temp: UserListModel[] = [];
        setDelegateUserList(temp);
      }

      if (userOwnerData.lineCode) {
        const newBuDepartmentDDL = buDepartmentDDL.filter(x => x.refCode === userOwnerData.lineCode);

        setDepartmentDDL(newBuDepartmentDDL);
      } else {
        setDepartmentDDL(buDepartmentDDL);
      }

      if (userOwnerData.workGroupCode) {

        const newBuLineDDL = buLineDDL.filter(x => x.refCode === userOwnerData.workGroupCode);
        setLineDDL(newBuLineDDL);
      }

      return;
    }

    if (delegateUserList && delegateUserList.filter((x) => x.acting === acting && x.userId === userId).length > 0) {

      toast.warn('ไม่สามารถเลือกรายชื่อผู้ปฏิบัติหน้าที่แทนซ้ำได้');

    } else if (saveData.ownerDelegates && saveData.ownerDelegates.id === userId) {

      toast.warn('ไม่สามารถระบุผู้ปฏิบัติหน้าที่แทนซ้ำกับผู้ปฏิบัติหน้าที่ได้');

    } else {

      const userDelegateData = await getUserProfileByIdAsync(userId);

      if (!userDelegateData) {
        return;
      }

      const maxValueSequence = delegateUserList.length > 0 ? Math.max.apply(null,
        delegateUserList.map((x) => {
          return x.sequence;
        })) : 0;

      if (id) {
        delegateUserList.push({
          acting: acting,
          userId: userId,
          fullName: `${userDelegateData.title}${userDelegateData.firstName} ${userDelegateData.lastName}`,
          departmentName: userDelegateData.departmentName,
          positionName: userDelegateData.buPositionName,
          sequence: maxValueSequence + 1,
          departmentId: userDelegateData.departmentId,
          positionId: userDelegateData.buPositionId,
          tel: userDelegateData.tel,
          branchCode: userDelegateData.branchCode,
          branchId: userDelegateData.branchId,
          branchName: userDelegateData.branchName,
          citizenCardId: userDelegateData.citizenCardId,
          departmentCode: userDelegateData.departmentCode,
          email: userDelegateData.email,
          isActive: userDelegateData.isActive,
          positionCode: userDelegateData.positionCode,
          workGroupCode: userDelegateData.workGroupCode,
          workGroupName: userDelegateData.workGroupName,
          buHeadId: headId,
          buHeadCode: headCode,
          buHeadName: headName,
          buGroupId: groupId,
          buGroupCode: groupCode,
          buGroupName: groupName,
          buLineId: lineId,
          buLineCode: lineCode,
          buLineName: lineName,
          buDepartmentId: (level === Level.Department || level === Level.Segment) ? departmentId : null,
          buDepartmentName: (level === Level.Department || level === Level.Segment) ? departmentName : null,
          buDepartmentCode: (level === Level.Department || level === Level.Segment) ? departmentCode : null,
          buSegmentId: level === Level.Segment ? segmentId : null,
          buSegmentName: level === Level.Segment ? segmentName : null,
          buSegmentCode: level === Level.Segment ? segmentCode : null,
          level: level,
          departmentDDL: buDepartmentDDL.filter(x => x.refCode === lineCode),
          lineDDL: buLineDDL.filter(x => x.refCode === groupCode),
        } as UserListModel);

        setDelegateUserList(delegateUserList);
        onSave(delegateUserList, false);
      } else {
        const newData: UserListModel = {
          acting: acting,
          userId: userId,
          fullName: `${userDelegateData.title}${userDelegateData.firstName} ${userDelegateData.lastName}`,
          departmentName: userDelegateData.departmentName,
          positionName: userDelegateData.buPositionName,
          sequence: maxValueSequence + 1,
          departmentId: userDelegateData.departmentId,
          positionId: userDelegateData.buPositionId,
          tel: userDelegateData.tel,
          branchCode: userDelegateData.branchCode,
          branchId: userDelegateData.branchId,
          branchName: userDelegateData.branchName,
          citizenCardId: userDelegateData.citizenCardId,
          departmentCode: userDelegateData.departmentCode,
          email: userDelegateData.email,
          isActive: userDelegateData.isActive,
          positionCode: userDelegateData.positionCode,
          workGroupCode: userDelegateData.workGroupCode,
          workGroupName: userDelegateData.workGroupName,
          buHeadId: headId,
          buHeadCode: headCode,
          buHeadName: headName,
          buGroupId: groupId,
          buGroupCode: groupCode,
          buGroupName: groupName,
          buLineId: lineId,
          buLineCode: lineCode,
          buLineName: lineName,
          buDepartmentId: (level === Level.Department || level === Level.Segment) ? departmentId : null,
          buDepartmentName: (level === Level.Department || level === Level.Segment) ? departmentName : null,
          buDepartmentCode: (level === Level.Department || level === Level.Segment) ? departmentCode : null,
          buSegmentId: level === Level.Segment ? segmentId : null,
          buSegmentName: level === Level.Segment ? segmentName : null,
          buSegmentCode: level === Level.Segment ? segmentCode : null,
          level: level,
          departmentDDL: buDepartmentDDL.filter(x => x.refCode === lineCode),
          lineDDL: buLineDDL.filter(x => x.refCode === groupCode),
        };

        setDelegateUserList((userDelegate) => [...userDelegate, newData]);
      }
    }
  };

  const onChangeBuLine = (userId: string, value: string, acting?: string) => {
    const index = delegateUserList.findIndex(c => c.userId === userId && c.acting === acting);
    const buLine = buLineDDL.filter(x => x.value === value)[0];

    if (index >= 0 && buLine) {

      delegateUserList[index].buLineId = value ?? '';
      delegateUserList[index].buLineCode = buLine.code ?? '';
      delegateUserList[index].buLineName = buLine.label ?? '';
      delegateUserList[index].departmentDDL = buDepartmentDDL.filter(x => x.refCode === buLine.code);

      const newBuLineDDL = buDepartmentDDL.filter(x => x.refCode === buLine.code);
      setDepartmentDDL(newBuLineDDL);
    } else {
      delegateUserList[index].buLineId = undefined;
      delegateUserList[index].buLineCode = undefined;
      delegateUserList[index].buLineName = undefined;
      const item: ItemModel[] = [];
      setDepartmentDDL(item);
    }

    delegateUserList[index].buDepartmentId = undefined;
    delegateUserList[index].buDepartmentCode = undefined;
    delegateUserList[index].buDepartmentName = undefined;
    setDelegateUserList(delegateUserList);
  };

  const onChangeBuGruop = (userId: string, value: string, acting?: string) => {
    const index = delegateUserList.findIndex(c => c.userId === userId && c.acting === acting);
    const buGroup = buGroupDDL.filter(x => x.value === value)[0];

    if (index >= 0 && buGroup) {

      delegateUserList[index].buGroupId = value ?? '';
      delegateUserList[index].buGroupCode = buGroup.code ?? '';
      delegateUserList[index].buGroupName = buGroup.label ?? '';
      delegateUserList[index].lineDDL = buLineDDL.filter(x => x.refCode === buGroup.code);

      const newBuLineDDL = buLineDDL.filter(x => x.refCode === buGroup.code);
      setGroupLineDDL(newBuLineDDL);
    } else {
      delegateUserList[index].buGroupId = undefined;
      delegateUserList[index].buGroupCode = undefined;
      delegateUserList[index].buGroupName = undefined;
    }

    delegateUserList[index].buLineId = undefined;
    delegateUserList[index].buLineCode = undefined;
    delegateUserList[index].buLineName = undefined;

    setDelegateUserList(delegateUserList);
  };

  const onChangeBuDepartment = (userId: string, value: string, acting?: string) => {

    const index = delegateUserList.findIndex(c => c.userId === userId && c.acting === acting);
    const buDepartment = buDepartmentDDL.filter(x => x.value === value)[0];

    if (index >= 0 && buDepartment) {
      delegateUserList[index].buDepartmentId = value;
      delegateUserList[index].buDepartmentCode = buDepartment.code;
      delegateUserList[index].buDepartmentName = buDepartment.label;
    } else {
      delegateUserList[index].buDepartmentId = undefined;
      delegateUserList[index].buDepartmentCode = undefined;
      delegateUserList[index].buDepartmentName = undefined;
    }

    setDelegateUserList(delegateUserList);
  };

  const onChangeBuSegment = (userId: string, value: string, acting?: string) => {

    const index = delegateUserList.findIndex(c => c.userId === userId && c.acting === acting);
    const buSegment = buSegmentDDL.filter(x => x.value === value)[0];

    if (index >= 0 && buSegment) {
      delegateUserList[index].buSegmentId = value;
      delegateUserList[index].buSegmentCode = buSegment.code;
      delegateUserList[index].buSegmentName = buSegment.label;
    } else {
      delegateUserList[index].buSegmentId = undefined;
      delegateUserList[index].buSegmentCode = undefined;
      delegateUserList[index].buSegmentName = undefined;
    }

    setDelegateUserList(delegateUserList);
  };

  const removeUser = (userId: string, acting?: string) => {
    const temp: UserListModel[] = [...delegateUserList];
    const index = temp.findIndex(c => c.userId === userId && c.acting === acting);

    if (index >= 0) {
      temp.splice(index, 1);
      setDelegateUserList(temp);
    }
  };

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };


  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };


  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const getFullName = (firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) {
      return '';
    }

    return `${firstName} ${lastName}`;
  };

  const onSave = async (userDelegateList: UserListModel[], isSave: boolean) => {

    submitForm();

    const startDate = new Date(saveData.start);
    const endDate = new Date(saveData.end);
    if (!saveData.start || !saveData.end) {
      toast.warn('กรุณาระบุวันที่');

      return;
    }

    if (startDate > endDate) {
      toast.warn('ข้อมูลมอบหมายให้ปฏิบัติหน้าที่แทนตั้งแต่วันที่ ต้องน้อยกว่า มอบหมายให้ปฏิบัติหน้าที่แทนถึงวันที่');

      return;
    }

    if (endDate < currentDate) {
      toast.warn('ไม่สามารถระบุวันที่สิ้นสุดย้อนหลังได้');

      return;
    }

    if (!saveData.annotation) {
      toast.warn('กรุณาระบุเหตุผล');

      return;
    }

    if (userDelegateList.length === 0) {
      return toast.warn('กรุณาระบุผู้ปฏิบัติหน้าที่แทนอย่างน้อย 1 ท่าน');
    }

    if (userDelegateList && userDelegateList.filter(x => x.userId === saveData.ownerDelegates.id).length > 0) {
      return toast.warn('ไม่สามารถระบุผู้ปฏิบัติหน้าที่แทนซ้ำกับผู้ปฏิบัติหน้าที่ได้');
    }

    if (isSave) {

      const userLevelDepartmentR = delegateUserList.filter(x => x.level === Level.Department && x.acting === ActingType.R)
      if (userLevelDepartmentR.length > 1) {

        if (userLevelDepartmentR?.filter(x => x.buSegmentId === null || x.buSegmentId === undefined || x.buSegmentId === '').length > 0) {

          return toast.warn('กรุณาระบุส่วนงาน');
        }

        if (userLevelDepartmentR?.filter((data, index) => userLevelDepartmentR?.findIndex(d => d.buSegmentId === data.buSegmentId) !== index).length > 0) {

          return toast.warn('ไม่สามารถระบุส่วนงานซ้ำได้');
        }
      }

      const userLevelDepartmentX = delegateUserList.filter(x => x.level === Level.Department && x.acting === ActingType.X)
      if (userLevelDepartmentX.length > 1) {

        if (userLevelDepartmentX?.filter(x => x.buSegmentId === null || x.buSegmentId === undefined || x.buSegmentId === '').length > 0) {

          return toast.warn('กรุณาระบุส่วนงาน');
        }

        if (userLevelDepartmentX?.filter((data, index) => userLevelDepartmentX.findIndex(d => d.buSegmentId === data.buSegmentId) !== index).length > 0) {

          return toast.warn('ไม่สามารถระบุส่วนงานซ้ำได้');
        }
      }

      const userLevelLineR = delegateUserList.filter(x => x.level === Level.Line && x.acting === ActingType.R)
      if (userLevelLineR.length > 1) {

        if (userLevelLineR?.filter(x => x.buDepartmentId === null || x.buDepartmentId === undefined || x.buDepartmentId === '').length > 0) {

          return toast.warn('กรุณาระบุฝ่าย');
        }

        if (userLevelLineR?.filter((data, index) => userLevelLineR?.findIndex(d => d.buDepartmentId === data.buDepartmentId) !== index).length > 0) {

          return toast.warn('ไม่สามารถระบุฝ่ายซ้ำได้');
        }
      }

      const userLevelLineX = delegateUserList.filter(x => x.level === Level.Line && x.acting === ActingType.X)
      if (userLevelLineX.length > 1) {

        if (userLevelLineX?.filter(x => x.buDepartmentId === null || x.buDepartmentId === undefined || x.buDepartmentId === '').length > 0) {

          return toast.warn('กรุณาระบุฝ่าย');
        }

        if (userLevelLineX?.filter((data, index) => userLevelLineX.findIndex(d => d.buDepartmentId === data.buDepartmentId) !== index).length > 0) {

          return toast.warn('ไม่สามารถระบุฝ่ายซ้ำได้');
        }
      }

      const userLevelGroupR = delegateUserList.filter(x => x.level === Level.Group && x.acting === ActingType.R)
      if (userLevelGroupR.length > 1) {

        if (userLevelGroupR?.filter(x => x.buLineId === null || x.buLineId === undefined || x.buLineId === '').length > 0) {

          return toast.warn('กรุณาระบุสายงาน');
        }

        const groupR = userLevelGroupR?.filter((data, index) => userLevelGroupR?.findIndex(d => d.buLineId === data.buLineId) !== index);

        if (groupR.length > 0) {

          if (groupR?.filter(x => x.buDepartmentId === null || x.buDepartmentId === undefined || x.buDepartmentId === '').length > 0) {

            return toast.warn('กรุณาระบุฝ่ายเนื่องจากมีสายงานซ้ำ');
          }

          if (userLevelGroupR?.filter((data, index) => userLevelGroupR?.findIndex(d => d.buDepartmentId === data.buDepartmentId) !== index).length > 0) {

            return toast.warn('ไม่สามารถระบุฝ่ายซ้ำได้');
          }
        }
      }

      const userLevelGroupX = delegateUserList.filter(x => x.level === Level.Group && x.acting === ActingType.X)
      if (userLevelGroupX.length > 1) {

        if (userLevelGroupX?.filter(x => x.buLineId === null || x.buLineId === undefined || x.buLineId === '').length > 0) {

          return toast.warn('กรุณาระบุสายงาน');
        }

        const groupX = userLevelGroupX?.filter((data, index) => userLevelGroupX?.findIndex(d => d.buLineId === data.buLineId) !== index);

        if (groupX.length > 0) {

          if (groupX?.filter(x => x.buDepartmentId === null || x.buDepartmentId === undefined || x.buDepartmentId === '').length > 0) {

            return toast.warn('กรุณาระบุฝ่ายเนื่องจากมีสายงานซ้ำ');
          }

          if (userLevelGroupX?.filter((data, index) => userLevelGroupX?.findIndex(d => d.buDepartmentId === data.buDepartmentId) !== index).length > 0) {

            return toast.warn('ไม่สามารถระบุฝ่ายซ้ำได้');
          }
        }
      }

      const userLevelHeadR = delegateUserList.filter(x => x.level === Level.Head && x.acting === ActingType.R)
      if (userLevelHeadR.length > 1) {

        if (userLevelHeadR?.filter(x => x.buGroupId === null || x.buGroupId === undefined || x.buGroupId === '').length > 0) {

          return toast.warn('กรุณาระบุกลุ่มงาน');
        }

        const headR = userLevelHeadR?.filter((data, index) => userLevelHeadR?.findIndex(d => d.buGroupId === data.buGroupId) !== index);
        if (headR.length > 0) {

          if (headR?.filter(x => x.buLineId === null || x.buLineId === undefined || x.buLineId === '').length > 0) {

            return toast.warn('กรุณาระบุสายงานเนื่องจากมีกลุ่มงานซ้ำ');
          }

          if (userLevelHeadR?.filter((data, index) => userLevelHeadR?.findIndex(d => d.buLineId === data.buLineId) !== index).length > 0) {

            return toast.warn('ไม่สามารถระบุสายงานซ้ำได้');
          }
        }
      }

      const userLevelHeadX = delegateUserList.filter(x => x.level === Level.Head && x.acting === ActingType.X)
      if (userLevelHeadX.length > 1) {

        if (userLevelHeadX?.filter(x => x.buGroupId === null || x.buGroupId === undefined || x.buGroupId === '').length > 0) {

          return toast.warn('กรุณาระบุกลุ่มงาน');
        }

        const headX = userLevelHeadX?.filter((data, index) => userLevelHeadX?.findIndex(d => d.buGroupId === data.buGroupId) !== index);
        if (headX.length > 0) {

          if (headX?.filter(x => x.buLineId === null || x.buLineId === undefined || x.buLineId === '').length > 0) {

            return toast.warn('กรุณาระบุสายงานเนื่องจากมีกลุ่มงานซ้ำ');
          }

          if (userLevelHeadX?.filter((data, index) => userLevelHeadX.findIndex(d => d.buLineId === data.buLineId) !== index).length > 0) {

            return toast.warn('ไม่สามารถระบุสายงานซ้ำได้');
          }
        }
      }
    }

    if (saveData.id !== undefined) {
      const { status } = await delegateService.updateDelegateAsync(saveData.id, saveData.ownerDelegates.id, saveData.start, saveData.end, saveData.annotation, userDelegateList);

      if (status === HttpStatusCode.OK) {

        if (isSave) {
          toast.success('บันทึกข้อมูลสำเร็จ');
        } else {
          toast.success('บันทึกผู้ปฏิบัติหน้าที่แทนสำเร็จ');
        }

        const end = new Date(saveData.end).toISOString();
        const start = new Date(saveData.start).toISOString();
        navigate(`/delegate/add/${saveData.ownerDelegates.id}/${start}/${end}`);
        getDelegateDataAsync(saveData.ownerDelegates.id, start, end);
        return;
      }
      // if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
      //   toast.error(`ผู้ปฏิบัติหน้าที่ มีการบันทึกข้อมูลการมอบหมายให้ปฏิบัติหน้าที่แทนแล้ว ในช่วงวันที่ ${thaiFormatDateWithSlash(saveData.start)} - ${thaiFormatDateWithSlash(saveData.end)} กรุณาตรวจสอบ`);

      //   return;
      // }
    } else {

      const { status, data } = await delegateService.createDelegateAsync(saveData.ownerDelegates.id, saveData.start, saveData.end, saveData.annotation, delegateUserList);

      if (status === HttpStatusCode.OK) {
        toast.success('บันทึกข้อมูลสำเร็จ');
        const end = new Date(saveData.end).toISOString();
        const start = new Date(saveData.start).toISOString();
        navigate(`/delegate/add/${saveData.ownerDelegates.id}/${start}/${end}`);
        // backToIndex();
        return;
      }

      if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
        toast.error(`ผู้ปฏิบัติหน้าที่ มีการบันทึกข้อมูลการมอบหมายให้ปฏิบัติหน้าที่แทนแล้ว ในช่วงวันที่ ${thaiFormatDateWithSlash(saveData.start)} - ${thaiFormatDateWithSlash(saveData.end)} กรุณาตรวจสอบ`);

        return;
      }
    }

    toast.error('บันทึกข้อมูลไม่สำเร็จ');
  };

  // const onSaveUpdate = async (userDelegateList: UserListModel[]) => {
  //   const startDate = new Date(saveData.start);
  //   const endDate = new Date(saveData.end);
  //   if (!saveData.start || !saveData.end) {
  //     toast.warn('กรุณาระบุวันที่');

  //     return;
  //   }

  //   if (startDate > endDate) {
  //     toast.warn('ข้อมูลมอบหมายให้ปฏิบัติหน้าที่แทนตั้งแต่วันที่ ต้องน้อยกว่า มอบหมายให้ปฏิบัติหน้าที่แทนถึงวันที่');

  //     return;
  //   }

  //   if (endDate < currentDate) {
  //     toast.warn('ไม่สามารถระบุวันที่สิ้นสุดย้อนหลังได้');

  //     return;
  //   }

  //   if (!saveData.annotation) {
  //     toast.warn('กรุณาระบุเหตุผล');

  //     return;
  //   }

  //   if (userDelegateList && userDelegateList.filter(x => x.userId === saveData.ownerDelegates.id).length > 0) {
  //     return toast.warn('ไม่สามารถระบุผู้ปฏิบัติหน้าที่แทนซ้ำกับผู้ปฏิบัติหน้าที่ได้');
  //   }

  //   if (saveData.id !== undefined) {
  //     const { status } = await delegateService.updateDelegateAsync(saveData.id, saveData.ownerDelegates.id, saveData.start, saveData.end, saveData.annotation, userDelegateList);

  //     if (status === HttpStatusCode.OK) {
  //       toast.success('บันทึกข้อมูลสำเร็จ');
  //       const end = new Date(saveData.end).toISOString();
  //       const start = new Date(saveData.start).toISOString();
  //       navigate(`/delegate/add/${saveData.ownerDelegates.id}/${start}/${end}`);
  //       getDelegateDataAsync(saveData.ownerDelegates.id, start, end);
  //       return;
  //     }

  //     if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
  //       toast.error(`ผู้ปฏิบัติหน้าที่ มีการบันทึกข้อมูลการมอบหมายให้ปฏิบัติหน้าที่แทนแล้ว ในช่วงวันที่ ${thaiFormatDateWithSlash(saveData.start)} - ${thaiFormatDateWithSlash(saveData.end)} กรุณาตรวจสอบ`);

  //       return;
  //     }
  //   }

  //   toast.error('บันทึกข้อมูลไม่สำเร็จ');
  // };

  const onDelete = (id: string) => {
    setDeleteId(id);
    setShowDelete(true);
  };

  const handleDelete = async (id: string) => {
    setShowDelete(false);
    setDeleteId('');

    if (delegateUserList.length === 1) {
      toast.warn('ต้องมีผู้ปฏิบัติหน้าที่แทนอย่างน้อย 1 คน');
      return;
    }

    const { status } = await delegateService.deleteDelegateAsync(id);

    if (status !== HttpStatusCode.OK) {

      if (status === HttpStatusCode.BAD_REQUEST) {
        toast.error('ข้อมูลนี้ไม่สามารถลบได้ เนื่องจากการรับมอบอำนาจมีผลแล้ว');
      } else {
        toast.error('ลบข้อมูลไม่สำเร็จ');
      }

      return;
    }

    toast.success('ลบข้อมูลสำเร็จ');
    const end = new Date(saveData.end).toISOString();
    const start = new Date(saveData.start).toISOString();
    navigate(`/delegate/add/${saveData.ownerDelegates.id}/${start}/${end}`);
    getDelegateDataAsync(saveData.ownerDelegates.id, start, end);
  };

  return (
    <div className="document">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">
          <Button variant="link" onClick={backToIndex}>
            <MdOutlineArrowBack className="fs-4 text-primary mb-1" />
          </Button>
          การมอบหมายให้ปฏิบัติหน้าที่แทน
        </h4>
      </div>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Card>
        <Title text="ผู้ปฏิบัติหน้าที่" className="text-primary fs-5" />
        <Row style={{ paddingLeft: '4%' }}>
          <Col sm={12} lg={4} className="mt-3">
            {
              (!isChangeOwner) ?
                <Input
                  value={getFullName(ownerUser?.firstName, ownerUser?.lastName)}
                  disabled
                  textSearch
                  label="ชื่อ/อีเมล"
                  rule={{ required: true }}
                  variant="primary"
                  onClick={() => setOwnerUserShow(!ownerUserShow)}
                />
                :
                <Input
                  value={getFullName(ownerUser?.firstName, ownerUser?.lastName)}
                  disabled
                  label="ชื่อ/อีเมล"
                  rule={{ required: true }}
                  variant="primary"
                />
            }
          </Col>
        </Row>
        <Title text="ข้อมูล" className="text-primary fs-5" />
        <Row className="mt-3" style={{ paddingLeft: '4%' }} >
          <Col sm={12} lg={3}><p>ชื่อ : <span className="ms-2 me-3">{ownerUser?.firstName}</span></p></Col>
          <Col sm={12} lg={3}><p>นามสกุล : <span className="ms-2 me-3">{ownerUser?.lastName}</span></p></Col>
          <Col sm={12} lg={3}><p>อีเมล : <span className="ms-2 me-3">{ownerUser?.email}</span></p></Col>
          <Col sm={12} lg={3}><p>เบอร์โทรศัพท์ : <span className="ms-2 me-3">{ownerUser?.tel}</span></p></Col>
          <Col sm={12} lg={12}><p>ตำแหน่ง : <span className="ms-2 me-3">{ownerUser?.buPositionName}</span></p></Col>
        </Row>
      </Card>
      <Card className="mt-3">
        <div className='d-flex align-items-center'>
          <Title text="ผู้ปฏิบัติหน้าที่แทน" className="text-primary fs-5" />
        </div>
        <Row className='mt-3 align-items-center'>
          {actingPosition.length > 0
            ? (
              actingPosition.map((section, i) => (
                <Col sm={12} key={i} style={{ paddingLeft: '3%' }}>
                  <div className='d-flex justify-content-between my-3' style={{ alignItems: 'center' }}>
                    <b>ปฏิบัติหน้าที่แทนตำแหน่ง: {section.positionName} {section.levelName}</b>
                    <Button
                      className='d-flex align-items-center gap-2'
                      variant='outline-primary'
                      onClick={() => onOpenModal(i, section.acting)}
                      disabled={section.level === Level.Segment && delegateUserList.length > 0}
                    >
                      <FaPlus />เพิ่มรายชื่อ
                    </Button>
                  </div>
                  <div style={{ paddingLeft: '3%' }}>
                    <Table total={1}
                      className='mt-1 step-bar'
                      hidePagination>
                      <tr />
                      <thead />
                      <tbody>
                        {delegateUserList?.filter((x) => x.acting === section.acting)
                          .sort((a, b) => a.sequence - b.sequence)
                          .map((data, index) => (
                            <tr className='text-start'
                              key={index}>
                              <td style={{ width: 200 }}>
                                {data.fullName}
                                <p className='m-0 department text-wrap'>
                                  {data.positionName}
                                </p>
                                <p className='m-0 department' style={{ color: 'grey' }}>
                                  {data.email}
                                </p>
                              </td>
                              <td style={{ width: 200 }}>
                                {(section.level === Level.Department) && (
                                  <div>
                                    <Selector
                                      value={data.buSegmentId ?? ''}
                                      placeholder='ทุกส่วนงาน'
                                      onChange={(val) => onChangeBuSegment(data.userId, val, data.acting)}
                                      items={buSegmentDDL.filter((x) => x.refCode === section.departmentCode)}
                                      rule={{ required: delegateUserList.filter(x => x.acting === section.acting).length > 1 || isSelectUser[i] }}
                                    />
                                  </div>
                                )}

                                {(section.level === Level.Line) && (
                                  <div>
                                    <Selector
                                      value={data.buDepartmentId ?? ''}
                                      placeholder='ทุกฝ่าย'
                                      onChange={(val) => onChangeBuDepartment(data.userId, val, data.acting)}
                                      items={buDepartmentDDL.filter((x) => x.refCode === section.lineCode)}
                                      rule={{ required: delegateUserList.filter(x => x.acting === section.acting).length > 1 || isSelectUser[i] }}
                                    />
                                  </div>
                                )}

                                {(section.level === Level.Group) && (
                                  <>
                                    <Selector
                                      value={data.buLineId ?? ''}
                                      placeholder='ทุกสายงาน'
                                      onChange={(val) => onChangeBuLine(data.userId, val, data.acting)}
                                      items={lineDDL}
                                      rule={{ required: delegateUserList.filter(x => x.acting === section.acting).length > 1 || isSelectUser[i] }}
                                    />
                                    {(data.buLineId !== null && data.buLineId !== undefined && data.buLineId !== '') && (
                                      <div className='d-flex' style={{ paddingLeft: 50 }}>
                                        <hr className='mt-0' style={{ paddingLeft: 50, border: 5, borderLeft: '1.8px solid  #cdcdcf', borderBottom: '1.8px solid #cdcdcf' }} />
                                        <Selector
                                          className='m-0 mt-2'
                                          items={data.departmentDDL}
                                          value={data.buDepartmentId ?? ''}
                                          placeholder='ทุกฝ่าย'
                                          onChange={(val) => onChangeBuDepartment(data.userId, val, data.acting)}
                                          rule={{
                                            required: (delegateUserList.filter(x => x.acting === section.acting).length > 1
                                              && delegateUserList.filter((data, uIndex) => data.acting === section.acting && delegateUserList.findIndex(d => d.acting === section.acting && d.buLineId === data.buLineId) !== uIndex).length > 0 && (data.buDepartmentId === '' || data.buDepartmentId === undefined || data.buDepartmentId === null)) || isSelectUser[i]
                                          }}
                                        />
                                      </div>
                                    )}
                                  </>
                                )}

                                {(section.level === Level.Head) && (
                                  <>
                                    <Selector
                                      value={data.buGroupId ?? ''}
                                      placeholder='ทุกกลุ่มงาน'
                                      items={buGroupDDL}
                                      onChange={(val) => onChangeBuGruop(data.userId, val, data.acting)}
                                      rule={{ required: delegateUserList.filter(x => x.acting === section.acting).length > 1 || isSelectUser[i] }}
                                    />
                                    {(data.buGroupId !== null && data.buGroupId !== undefined && data.buGroupId !== '') && (
                                      <div className='d-flex' style={{ paddingLeft: 50 }}>
                                        <hr className='mt-0' style={{ paddingLeft: 50, border: 5, borderLeft: '1.8px solid  #cdcdcf', borderBottom: '1.8px solid #cdcdcf' }} />
                                        <Selector
                                          className='m-0 mt-2'
                                          items={data.lineDDL}
                                          value={data.buLineId ?? ''}
                                          placeholder='ทุกสายงาน'
                                          onChange={(val) => onChangeBuLine(data.userId, val, data.acting)}
                                          rule={{
                                            required: (delegateUserList.filter(x => x.acting === section.acting).length > 1
                                              && delegateUserList.filter((data, uIndex) => data.acting === section.acting && delegateUserList.findIndex(d => d.acting === section.acting && d.buGroupId === data.buGroupId) !== uIndex).length > 0
                                              && delegateUserList.filter(x => x.acting === section.acting && (x.buLineId === '' || x.buLineId === undefined || x.buLineId === null)).length > 0)
                                              || isSelectUser[i]
                                          }}
                                        />
                                      </div>
                                    )}
                                  </>
                                )}

                                {/* {(section.lineCode !== null && section.lineCode !== undefined && section.lineCode !== '')
                                ? (
                                  <div style={{ paddingLeft: 100 }}>
                                    <Selector
                                      value={data.buDepartmentId ?? ''}
                                      placeholder='ทุกฝ่าย'
                                      onChange={(val) => onChangeBuDepartment(data.userId, val, data.acting)}
                                      items={buDepartmentDDL.filter((x) => x.refCode === section.lineCode)}
                                      rule={{ required: delegateUserList.filter(x => x.acting === section.acting).length > 1 || isSelectUser[i] }}
                                    />
                                  </div>)
                                : (<>
                                  <Selector
                                    value={data.buLineId ?? ''}
                                    placeholder='ทุกสายงาน'
                                    onChange={(val) => onChangeBuLine(data.userId, val, data.acting)}
                                    items={lineDDL}
                                    rule={{ required: delegateUserList.filter(x => x.acting === section.acting).length > 1 || isSelectUser[i] }}
                                  />
                                  {(data.buLineId !== null && data.buLineId !== undefined && data.buLineId !== '') && (
                                    <div className='d-flex' style={{ paddingLeft: 50 }}>
                                      <hr className='mt-0' style={{ paddingLeft: 50, border: 5, borderLeft: '1.8px solid  #cdcdcf', borderBottom: '1.8px solid #cdcdcf' }} />
                                      <Selector
                                        className='m-0 mt-2'
                                        items={data.departmentDDL}
                                        value={data.buDepartmentId ?? ''}
                                        placeholder='ทุกฝ่าย'
                                        onChange={(val) => onChangeBuDepartment(data.userId, val, data.acting)}
                                        rule={{ required: (delegateUserList.filter(x => x.acting === section.acting).length > 1 && delegateUserList.filter((data, index) => data.acting === section.acting && delegateUserList.findIndex(d => d.acting === section.acting && d.buLineId === data.buLineId) !== index).length > 0 && delegateUserList && delegateUserList.filter(x => x.acting === section.acting && (x.buDepartmentId === '' || x.buDepartmentId === undefined || x.buDepartmentId === null)).length > 0) || isSelectUser[i] }}
                                      />
                                    </div>
                                  )}
                                </>)} */}
                              </td>
                              <td className='gap-2' style={{ width: '10%' }} >
                                <div className='d-flex justify-content-end'>
                                  <Button
                                    variant='danger'
                                    className='d-flex align-items-center gap-2'
                                    onClick={(data.id !== '' && data.id !== undefined && data.id !== null) ? () => onDelete(data.id!) : () => removeUser(data.userId, data.acting)}
                                  >
                                    <FaTrashAlt />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  <SearchBuNameModal
                    total={userTotal}
                    show={delegateUserShow[i]}
                    onHide={() => onHideModal(i)}
                    onSelectItem={(id) => getUserDataAsync(id,
                      DELEGATE_TYPE.DELEGATE,
                      delegateUserList,
                      section.acting,
                      false,
                      section.lineCode,
                      section.lineId,
                      section.lineName,
                      section.groupCode,
                      section.groupId,
                      section.groupName,
                      section.headId,
                      section.headCode,
                      section.headName,
                      section.departmentId,
                      section.departmentCode,
                      section.departmentName,
                      section.segmentId,
                      section.segmentName,
                      section.segmentCode,
                      section.level)}
                    data={users}
                    onSearch={onSearchUserAsync}
                    onPageChange={onPageChangeAsync}
                    departmentCode={departmentCode}
                    departmentDisabled={departmentCode !== JorPorCode.CODE}
                  />
                </Col>
              ))
            )
            : (
              <Col>
                <span className='text-primary'>ไม่พบข้อมูล</span>
              </Col>
            )}
        </Row>
        {/* <Row>
          <Col sm={12} lg={4} className="mt-3">
            {
              (isCanEditAll) ?
                <Input
                  value={getFullName(delegateUser?.firstName, delegateUser?.lastName)}
                  disabled
                  textSearch
                  label="ชื่อ/อีเมล"
                  rule={{ required: true }}
                  variant="primary"
                  onClick={() => setDelegateUserShow(!delegateUserShow)}
                />
                :
                <Input
                  value={getFullName(delegateUser?.firstName, delegateUser?.lastName)}
                  disabled
                  label="ชื่อ/อีเมล"
                  rule={{ required: true }}
                  variant="primary"
                />
            }
          </Col>
        </Row> */}
        {/* <Title text="ข้อมูล" className="text-primary fs-5" />
        <Row className="mt-3">
          <Col sm={12} lg={3}><p>ชื่อ : <span className="ms-2 me-3">{delegateUser?.firstName}</span></p></Col>
          <Col sm={12} lg={3}><p>นามสกุล : <span className="ms-2 me-3">{delegateUser?.lastName}</span></p></Col>
          <Col sm={12} lg={3}><p>อีเมล : <span className="ms-2 me-3">{delegateUser?.email}</span></p></Col>
          <Col sm={12} lg={3}><p>เบอร์โทรศัพท์ : <span className="ms-2 me-3">{delegateUser?.tel}</span></p></Col>
          <Col sm={12} lg={6}><p>ตำแหน่ง : <span className="ms-2 me-3">{delegateUser?.buPositionName}</span></p></Col>
        </Row> */}
      </Card >

      <Card className="mt-3">
        <Title text="ข้อมูลการมอบหมายให้ปฏิบัติหน้าที่แทน" className="text-primary fs-5" />
        <Row className="mt-3" style={{ paddingLeft: '4%' }}>
          <Col sm={12} lg={4}>
            <DatePicker
              label="มอบหมายให้ปฏิบัติหน้าที่แทนตั้งแต่วันที่"
              rule={{ required: true }}
              value={saveData.start}
              onChange={(value) => setSaveData((prevState) => ({ ...prevState, start: value }))}
              disabled={!isCanEditAll}
            />
          </Col>
          <Col sm={12} lg={4}>
            <DatePicker
              label="มอบหมายให้ปฏิบัติหน้าที่แทนถึงวันที่"
              rule={{ required: true }}
              value={saveData.end}
              onChange={(value) => setSaveData((prevState) => ({ ...prevState, end: value }))}
              disabled={!isCanEditEndDate}
            />
          </Col>
          <Col sm={12} lg={8}>
            <InputTextArea
              label="เหตุผล"
              rule={{ required: true }}
              value={saveData.annotation}
              onChange={(value) => setSaveData((prevState) => ({ ...prevState, annotation: value }))}
              disabled={!isCanEditEndDate}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-center pt-3'>
        <Button
          variant="success"
          className="d-flex align-items-center gap-2"
          onClick={() => onSave(delegateUserList, true)}
          disabled={(!isCanEditAll) && (!isCanEditEndDate)}
        >
          บันทึก
        </Button>
      </div>

      <SearchBuNameModal
        total={userTotal}
        show={ownerUserShow}
        onHide={() => setOwnerUserShow(!ownerUserShow)}
        onSelectItem={(id) => getUserDataAsync(id, DELEGATE_TYPE.OWNER, delegateUserList, '', true)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <Modal
        show={showDelete}
        size='lg'
        onHide={() => setShowDelete(false)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบข้อมูล</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <p>ต้องการลบรายชื่อผู้ปฏิบัติหน้าที่แทนหรือไม่?</p>
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowDelete(false)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                variant='danger'
                onClick={() => handleDelete(deleteId)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div >
  );
}

export default AddDelegate;
