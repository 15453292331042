import http from 'utils/axios';

const createDefaultApproverAsync = async (financialAmount: number, userId: string) => {
  const body = {
    financialAmount,
    userId,
  };

  return http.post('defaultapprover', body);
};

const updateDefaultApproverAsync = async (financialAmount: number, DefaultApproverId: string) => {
  const body = {
    financialAmount,
  };

  return http.post(`defaultapprover/${DefaultApproverId}`, body);
};

export default {
  createDefaultApproverAsync,
};