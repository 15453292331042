import { Breadcrumb } from 'components';
import StepProgress from 'components/StepProgress';
import { useRef } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

const BREADCRUMB_INFO = [
  {
    routerName: '/procurement-announcing-winners', label: 'ประกาศผู้ชนะเสนอราคา',
  },
  {
    routerName: '', label: 'รายละเอียดประกาศผู้ชนะเสนอราคา',
  },
];

function AnnouncingWinnersDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const obj = [{
    title: <p>ข้อมูลประกาศผู้ชนะเสนอราคา</p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p>ผู้มีอำนาจเห็นชอบ</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>ตัวอย่างเอกสารประกาศผู้ชนะเสนอราคา</p>,
    displayIndex: '3',
    child: <Step3 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/procurement-announcing-winners');
  };

  return (
    <div className="document">
      <h4 className="mt-2 mb-0 text-primary text-start">
        <Button variant="link" onClick={backToIndex}>
          <MdOutlineArrowBack className="fs-4 text-primary mb-1" />
        </Button>ประกาศผู้ชนะเสนอราคา</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12} className="mb-2">
          <StepProgress progressBarData={obj} ref={childRef} />
        </Col>
      </Row>
    </div>
  );
}

export default AnnouncingWinnersDetail;
