import {
  DatePicker,
  Input,
  Selector,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Card,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function step1({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Card.Body>
          <Title text="ข้อมูลโครงการ" className="fs-5 text-primary" />
          <Row className="mt-3 align-items-center">
            <Col sm={12} md={4} xl={3}>
              <Input label="วันที่เอกสารบันทึกข้อความแต่งตั้ง" value="วันที่เอกสารบันทึกข้อความแต่งตั้ง" disabled />
            </Col>
            <Col sm={12} md={4}>
              <Selector label="โครงการ" disabled rule={{ required: true }} />
            </Col>
            <Col sm={12} md={3}>
              <Button variant="link"><TbListSearch className="fs-4" /> ไปยังแผน</Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4} xl={3}>
              <Input label="งบประมาณโครงการ (บาท)" value="1,000,000.00" disabled textEnd rule={{ required: true }} />
            </Col>
            <Col sm={12} md={4}>
              <Input label="วงเงิน" value="1,000,000.00" disabled textEnd rule={{ required: true }} />
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={4} xl={3}>
              <Input label="สร้างโดย" value="แสงดี ศวีวรรณ" disabled />
            </Col>
            <Col sm={12} md={4} xl={3}>
              <DatePicker label="ค้นหาด้วยวันที่เริ่มต้น" disabled />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-between pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default step1;
