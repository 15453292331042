import Cookie from 'cookie-universal';
import {
  PL0204Criteria,
  PL0204ModelSave,
} from 'models/PL';
import http from 'utils/axios';

const qs = require('qs');

const cookies = Cookie();

const getFullName = cookies.get('fullName') as string;

const getCancelRequestPlanListAsync = async (
  page: number,
  size: number,
  data: PL0204Criteria,
) => {
  const params = {
    page,
    size,
    ...data,
  };
  return await http.get('/cancelplanrequest', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCancelStatusCountAsync = async () => await http.get('/cancelplanrequest/count-status');

const getCancelRequestPlanDetailAsync = async (planId: string | undefined) => await http.get(`/cancelplanrequest/${planId}`);

const createCancelPlanRequest = async (data: PL0204ModelSave) => {
  data.updateByFullname = getFullName;
  return await http.put(`/cancelplanrequest/${data.planId}`, data);
};

export default {
  getCancelRequestPlanListAsync,
  getCancelStatusCountAsync,
  getCancelRequestPlanDetailAsync,
  createCancelPlanRequest,
};
