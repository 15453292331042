import {
  useEffect,
  useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import {
  FaCloudUploadAlt,
  FaFileAlt,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  showConfirmModalAsync,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';

export interface FileValue {
  documentId?: string;
  name: string;
  file?: File;
  fileName?: string;
  createdByName?: string;
  createdById?: string;
  programId?: string;
  subProgram?: string;
}

interface Props {
  value?: FileValue[],
  onChange?: (files: File) => void;
  onRemove?: (index: number, id: string | undefined) => void;
  onDownload?: (index: number, documentId: string | undefined, type: string | undefined, id: string | undefined, subProgram: string | undefined, programId: string | undefined) => void;
  disabled?: boolean;
  disabledRemove?: boolean;
  canRemoveFile?: boolean;
  program?: string;
  id?: string;
}

const fileTypes = ['doc', 'docx', 'xls', 'xlsx', 'csv', 'pdf', 'png', 'jpg', 'jpeg'];

export function UploadFile(props: Props) {
  const [files, setFiles] = useState<FileValue[]>([]);
  const [dragEnter, setDragEnter] = useState<boolean>(false);
  const { userId } = useAppContext();

  useEffect(() => {
    if (props.value?.length) {
      setFiles([...props.value]);
    }
  }, [props.value]);

  const handlerOnChange = (file: File) => {
    if (props.disabled) {
      return;
    }

    const uploadFileElements = document.getElementsByName('upload-file');

    if (uploadFileElements.length) {
      const input = uploadFileElements[0] as HTMLInputElement;

      input.value = '';
    }

    if (files.some((f) => f.name === file.name)) {
      return toast.warn('ชื่อไฟล์ซ้ำ');
    }

    setFiles([
      ...files,
      {
        file,
        name: file.name,
      },
    ]);

    if (props.onChange) {
      props.onChange(file);
    }
  };

  const fileIcon = (name: string) => {
    switch (true) {
      case name.includes('.pdf'):
        return (<FaFilePdf />);
      case name.includes('.doc') || name.includes('.docx'):
        return (<FaFileWord />);
      case name.includes('.xls') || name.includes('.xlsx'):
        return (<FaFileExcel />);
      case name.includes('.csv'):
        return (<FaFileCsv />);
      case name.includes('.png') || name.includes('jpg') || name.includes('jpeg'):
        return (<FaFileImage />);
      default:
        return (<FaFileAlt />);
    }
  };

  const removeAsync = async (i: number, id: string | undefined) => {
    if (!await showConfirmModalAsync('คุณต้องการยืนยันลบไฟล์หรือไม่ ?')) {
      return;
    }

    files.splice(i, 1);
    setFiles([...files]);

    if (props.onRemove) {
      props.onRemove(i, id);
    }
  };

  const download = (index: number, file: FileValue) => {
    if (props.onDownload) {
      props.onDownload(index, file.documentId, props.program, props.id, file.subProgram, file.programId);
    }
  };

  return (
    <div className='upload-file'
      draggable>
      <div className='file-list'>
        {files.map((f, i) => (
          <div key={f.documentId}
            className='file'>
            <div className='file-icon'>
              {fileIcon(f.name)}
            </div>
            <div className='detail'>
              <a
                onClick={() => download(i, f)}
                className='filename'
              >
                {f.name}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
