import {
    ButtonCustom,
    ArrowCollapse,
} from 'components';
import {
    Readonly,
} from 'components/Controls';
import Title from 'components/Controls/Title';
import { CollectAttachmentsResponseModel, FileValue } from 'models/CollectAttachments/CollectAttachmentsModel';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    Col,
    Row,
    Modal as ModalBT,
    Card,
    Collapse,
} from 'react-bootstrap';
import {
    useNavigate,
    useParams,
} from 'react-router';
import {
    HttpStatusCode, downloadFile, useAppContext,
} from 'utils';
import CollectAttachmentServices from 'services/CollectAttachmentServices';
import { TabFiles } from 'components/TabFiles';
import { JorPorCode } from 'constant/basicInformation';

const formatter = new Intl.NumberFormat('th-TH', {
    currency: 'THB',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export default function Detail() {
    const navigate = useNavigate();
    const [data, setData] = useState<CollectAttachmentsResponseModel>({} as CollectAttachmentsResponseModel);
    const [openCollapse1, setOpenCollapse1] = useState(true);
    const [openCollapse2, setOpenCollapse2] = useState(true);
    const [openCollapse3, setOpenCollapse3] = useState(true);
    const [openCollapse4, setOpenCollapse4] = useState(true);
    const { id } = useParams();
    const { userId, departmentCode } = useAppContext();

    useEffect(() => {
        if (id) {
            geAttachmenttByPlanIdAsynce(id);
        }
    }, [id,]);

    const geAttachmenttByPlanIdAsynce = async (id: string) => {
        const response = await CollectAttachmentServices.geAttachmenttByPlanIdAsynce(id);

        if (response.status === HttpStatusCode.OK) {
            setData(response.data);
        }
    };

    return (
        <div className='document'>
            <div className='d-flex justify-content-between align-items-center'>
                <h4 className='text-primary m-0'>รวบรวมเอกสาร</h4>
            </div>
            <hr />
            <Card className='mt-3'>
                <Title text='ข้อมูลจัดซื้อจัดจ้าง'
                    className='fs-5' />
                <div style={{ marginLeft: 20 }}>
                    <Row className='mt-3 align-items-center'>
                        <Col sm={12}
                            md={6}>
                            <Readonly label='เลขที่รายการจัดซื้อจัดจ้าง'
                                textReadonly={data.planNumber} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}
                            md={6}>
                            <Readonly label='โครงการ'
                                textReadonly={data.planName} />
                        </Col>
                        <Col sm={12}
                            md={3}>
                            <Readonly label='งบประมาณโครงการ (บาท)'
                                textReadonly={formatter.format(data.financialAmount)} />
                        </Col>
                    </Row>
                    <Row className='mt-3 align-items-center'>
                        <Col sm={12}
                            md={3}
                            xl={3}>
                            <Readonly label='วิธีจัดหา'
                                textReadonly={data.supplyMethodName} />
                        </Col>
                        <Col sm={12}
                            md={3}>
                            <Readonly textReadonly={data.supplyMethodTypeName} />
                        </Col>
                        <Col sm={12}
                            md={3}>
                            <Readonly textReadonly={data.supplyMethodSpecialTypeName} />
                        </Col>
                    </Row>
                </div>
            </Card >
            <Card className='mt-3 shadow'>
                <div className='d-flex align-items-center'>
                    <Title text='Plan'
                        className='fs-4' />
                    {openCollapse1 ? (
                        <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
                    ) : (
                        <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />)}
                </div>
                <Collapse in={openCollapse1}>
                    <div style={{ paddingLeft: 40 }}>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>รายการจัดซื้อจัดจ้าง (รวมปี)</p>
                                    </div>
                                </div>
                                {(data?.planDocuments?.annual?.numbers.length > 0)
                                    ? (<TabFiles data={data?.planDocuments?.annual?.numbers} name2='เลขที่รายการจัดซื้อจัดจ้าง' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>รายการจัดซื้อจัดจ้าง (ระหว่างปี)</p>
                                    </div>
                                </div>
                                {(data?.planDocuments?.inYear?.numbers.length > 0)
                                    ? (<TabFiles data={data?.planDocuments?.inYear?.numbers} name2='เลขที่รายการจัดซื้อจัดจ้าง' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='ustify-content-center mt-3' />
                    </div>
                </Collapse>
            </Card>

            <Card className='mt-3 shadow'>
                <div className='d-flex align-items-center'>
                    <Title text='Pre-Procurement'
                        className='fs-4' />
                    {openCollapse2 ? (
                        <ArrowCollapse onClick={() => setOpenCollapse2(!openCollapse2)} openCollapse={openCollapse2} />
                    ) : (
                        <ArrowCollapse onClick={() => setOpenCollapse2(true)} openCollapse={openCollapse2} />)}
                </div>
                <Collapse in={openCollapse2}>
                    <div style={{ paddingLeft: 40 }}>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>ขอแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง</p>
                                    </div>
                                </div>
                                {(data?.preProcurementsDocument?.appoint?.numbers.length > 0)
                                    ? (<TabFiles data={data?.preProcurementsDocument?.appoint?.numbers}
                                        name2='เลขที่บันทึกแต่งตั้ง' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>จัดทำร่างขอบเขตของงาน (TOR)</p>
                                    </div>
                                </div>
                                {(data?.preProcurementsDocument?.tor?.numbers.length > 0)
                                    ? (<TabFiles data={data?.preProcurementsDocument?.tor?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่จัดทำร่างขอบเขตงาน' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>  กำหนดราคากลาง (ราคาอ้างอิง)</p>
                                    </div>
                                </div>
                                {(data?.preProcurementsDocument?.medianPrice?.numbers.length > 0)
                                    ? (<TabFiles data={data?.preProcurementsDocument?.medianPrice?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่กำหนดราคากลาง' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>การแจ้งข้อมูลเบื้องต้น (จพ.004)</p>
                                    </div>
                                </div>
                                {(data?.preProcurementsDocument?.jorpor04?.numbers.length > 0)
                                    ? (<TabFiles data={data?.preProcurementsDocument?.jorpor04?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่รายงานขอซื้อขอจ้าง (จพ.004)' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='ustify-content-center mt-3' />
                    </div>
                </Collapse>
            </Card>

            <Card className='mt-3 shadow'>
                <div className='d-flex align-items-center'>
                    <Title text='Procurement'
                        className='fs-4' />
                    {openCollapse3 ? (
                        <ArrowCollapse onClick={() => setOpenCollapse3(!openCollapse3)} openCollapse={openCollapse3} />
                    ) : (
                        <ArrowCollapse onClick={() => setOpenCollapse3(true)} openCollapse={openCollapse3} />)}
                </div>
                <Collapse in={openCollapse3}>
                    <div style={{ paddingLeft: 40 }}>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>จัดทำรายงานขอซื้อขอจ้าง (จพ.005)</p>
                                    </div>
                                </div>
                                {(data?.procurementsDocument?.jorpor05?.numbers.length > 0)
                                    ? (<TabFiles data={data?.procurementsDocument?.jorpor05?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่รายงานขอซื้อขอจ้าง (จพ.005)' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>จัดทำหนังสือเชิญชวนผู้ประกอบการ</p>
                                    </div>
                                </div>
                                {(data?.procurementsDocument?.invitationOrder?.numbers.length > 0)
                                    ? (<TabFiles data={data?.procurementsDocument?.invitationOrder?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่ทำหนังสือเชิญชวนผู้ประกอบการ' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>จัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง (จพ.006)</p>
                                    </div>
                                </div>
                                {(data?.procurementsDocument?.jorpor06?.numbers.length > 0)
                                    ? (<TabFiles data={data?.procurementsDocument?.jorpor06?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่รายงาน (จพ.006)' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>อนุมัติใบสั่งซื้อ/จ้าง/เช่า และแจ้งทำสัญญา</p>
                                    </div>
                                </div>
                                {(data?.procurementsDocument?.purchaseOrderApproval?.numbers.length > 0)
                                    ? (<TabFiles data={data?.procurementsDocument?.purchaseOrderApproval?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่รายการจัดซื้อจัดจ้าง' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='ustify-content-center mt-3' />
                    </div>
                </Collapse>
            </Card>

            <Card className='mt-3 shadow'>
                <div className='d-flex align-items-center'>
                    <Title text='Contract Management'
                        className='fs-4' />
                    {openCollapse4 ? (
                        <ArrowCollapse onClick={() => setOpenCollapse4(!openCollapse4)} openCollapse={openCollapse4} />
                    ) : (
                        <ArrowCollapse onClick={() => setOpenCollapse4(true)} openCollapse={openCollapse4} />)}
                </div>
                <Collapse in={openCollapse4}>
                    <div style={{ paddingLeft: 40 }}>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>หนังสือเชิญชวนทำสัญญา</p>
                                    </div>
                                </div>
                                {(data?.contractsDocument?.invitationSign?.numbers.length > 0)
                                    ? (<TabFiles data={data?.contractsDocument?.invitationSign?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่สัญญา' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>ร่างสัญญาและสัญญา (เอกสารส่วนบุคคล)</p>
                                    </div>
                                </div>
                                {(data?.contractsDocument?.personalAgreement?.numbers.length > 0 && departmentCode === JorPorCode.CODE)
                                    ?
                                    (<TabFiles data={data?.contractsDocument?.personalAgreement
                                        ?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่สัญญา' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>ร่างสัญญาและสัญญา</p>
                                    </div>
                                </div>
                                {(data?.contractsDocument?.agreement?.numbers.length > 0)
                                    ? (<TabFiles data={data?.contractsDocument?.agreement?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่สัญญา' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>บันทึกส่งมอบและตรวจรับ</p>
                                    </div>
                                </div>
                                {(data?.contractsDocument?.management?.numbers.length > 0)
                                    ? (<TabFiles data={data?.contractsDocument?.management?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่สัญญา' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>คืนหลักประกันสัญญา (เอกสารส่วนบุคคล)</p>
                                    </div>
                                </div>
                                {(data?.contractsDocument?.personalContractReturnCollateral?.numbers.length > 0 && departmentCode === JorPorCode.CODE)
                                    ? (<TabFiles data={data?.contractsDocument?.personalContractReturnCollateral?.numbers}
                                        // name1='เลขที่บันทึกแต่งตั้ง'
                                        name2='เลขที่สัญญา' />)
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='justify-content-center mt-3'>
                            <Col>
                                <div className='d-flex justify-content-between title-head'>
                                    <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
                                        <p className='fw-bold mb-0 fs-5'>คืนหลักประกันสัญญา</p>
                                    </div>
                                </div>

                                {(data?.contractsDocument?.returnCollateralConsider?.numbers.length > 0)
                                    ? (
                                        <TabFiles data={data?.contractsDocument?.returnCollateralConsider?.numbers}
                                            // name1='เลขที่บันทึกแต่งตั้ง'
                                            name2='เลขที่สัญญา' />
                                    )
                                    : (<div className='d-flex flex-column gap-3 mt-2 align-items-center justify-content-between'>
                                        <Col>
                                            <span>ไม่พบเอกสารแนบ</span>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Row>
                        <Row className='ustify-content-center mt-3' />
                    </div>
                </Collapse>
            </Card>
        </div >
    );
}
