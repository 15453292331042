import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Input,
  Radio,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import { DefectsWarrantyModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';

interface IDefectWarrantyProps {
  title?: string;
  defectsWarranty: DefectsWarrantyModel;
  contractTemplateCode: string;
  periodTypeDDL: ItemModel[];
  repairPeriodTypeDDL: ItemModel[];
  periodConditionDDL: ItemModel[];
  onDefectWarrantyChange: (value: DefectsWarrantyModel) => void;
  isDisabled?: boolean;
}

const fields = [
  {
    code: 'CF01',
  },
  {
    code: 'CF02',
  },
  {
    code: 'CF03',
  },
  {
    code: 'CF04',
  },
  {
    code: 'CF05',
    repairPeriodDay: 'หากโปรแกรมคอมพิวเตอร์เกิดการชำรุดบกพร่อง ผู้ขายจะต้องซ่อมแชมหรือติดตั้งโปรแกรมคอมพิวเตอร์ใหม่ ภายในกำหนด',
    repairPeriodDayCondition: 'นับถัดจากวันที่ได้รับแจ้ง',
  },
  {
    code: 'CF06',
  },
  {
    code: 'CF07',
  },
  {
    code: 'CF08',
  },
  {
    code: 'CF09',

  },
  {
    code: 'CF10',
  },
  {
    code: 'CF11',
  },
  {
    code: 'CF12',
  },
  {
    code: 'CF13',
  },
  {
    code: 'CF14',
  },
  {
    code: 'CF15',
  },
];

export function DefectWarrantyAgainst(props: IDefectWarrantyProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [defectsWarranty, setDefectsWarranty] = useState<DefectsWarrantyModel>(props.defectsWarranty);
  const [isDisabledWarranty, setIsDisabledWarranty] = useState<boolean>(true);

  useEffect(() => {
    if (defectsWarranty) {
      props.onDefectWarrantyChange(defectsWarranty);
    }
  }, [defectsWarranty]);

  useEffect(() => {
    if (props.defectsWarranty && firstLoad) {
      setDefectsWarranty(props.defectsWarranty);
      setFirstLoad(false);
    }
  }, [props.defectsWarranty, firstLoad]);

  const onTextChange = useCallback((key: string, value: string) => {
    setDefectsWarranty(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setDefectsWarranty(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onChangesetWarrantyActive = useCallback((val: string) => {
    setDefectsWarranty({ ...defectsWarranty, isDefectsWarranty: val === 'true' });

    if (val === 'false') {
      setIsDisabledWarranty(true);
      const warranty = {
        isDefectsWarranty: false,
        periodDay: undefined,
        periodMonth: undefined,
        periodType: undefined,
        periodYear: undefined,
        repairPeriodDay: undefined,
        repairPeriodType: undefined,
      } as DefectsWarrantyModel;

      setDefectsWarranty(prevState => ({
        ...prevState,
        warranty,
      }));

    } else {
      setIsDisabledWarranty(false);
    }
  }, []);

  return (
    <Card className='mt-3'>
      <Title text={`สัญญา${props.title ? props.title : 'การรับประกันความชำรุดบกพร่อง'}`} className='fs-5 text-primary' />
      <Row>
        <>
          {
            fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
              <>
                {
                  data.repairPeriodDay &&
                  (
                    <Row>
                      <Col sm={12}
                        md={12}>
                        <label className='mt-3'>{data.repairPeriodDay}</label>
                      </Col>
                      <Col sm={8}
                        md={3}
                        className='d-flex'>
                        <InputNumber
                          labelClassName='text-wrap'
                          className='no-label'
                          textCenter
                          value={defectsWarranty?.repairPeriodDay}
                          onChange={(value) => onNumberChange('repairPeriodDay', Number(value))}
                          disabled={props.isDisabled} />
                      </Col>
                      <Col sm={3}
                        md={3}>
                        <Selector
                          className='no-label'
                          items={props.repairPeriodTypeDDL}
                          value={defectsWarranty?.repairPeriodType}
                          onChange={(value) => onTextChange('repairPeriodType', value)}
                          disabled={props.isDisabled} />
                      </Col>
                    </Row>
                  )
                }
              </ >
            )
          }
        </>
      </Row>
    </Card>
  );
}
