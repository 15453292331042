import {
  Input,
  Selector,
  Table,
} from 'components';
import { BadgeStatus } from 'components/Controls/BadgeisActive';
import {
  ItemModel,
  parameterListResponse,
} from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEdit,
  FaEraser,
  FaPlus,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { parameter } from 'services';
import { HttpStatusCode } from 'utils';

type Loader = { parameterLists: parameterListResponse[], parameterTotalRecord: number, dropDownLists: ItemModel[], parameterGroupDropDown: ItemModel[] };

interface CriteriaProps {
  criteriaData: (data: parameterListResponse) => void;
}

export default function SU09() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [parameterListsData, setParemeterListsData] = useState<parameterListResponse[]>([]);
  const [criteria, setCriteria] = useState<parameterListResponse>({} as parameterListResponse);
  const [onInit, setOnInit] = useState(false);
  const { parameterLists, parameterTotalRecord } = useLoaderData() as Loader;
  const navigate = useNavigate();

  useEffect(() => {
    setOnInit(true);
    if (parameterLists && parameterTotalRecord) {
      setTotalRecords(parameterTotalRecord);
      setParemeterListsData(parameterLists);
      setOnInit(false);
    }
  }, [parameterLists]);

  const getParameterListAsync = useCallback(async () => {
    const { data, status } = await parameter.getParameterListAsync(page, size, criteria);

    if (status === HttpStatusCode.OK) {
      setTotalRecords(data.totalRecords);
      setParemeterListsData(data.data);
    }
  }, [page, size, criteria]);

  useEffect(() => {
    getParameterListAsync();
  }, [getParameterListAsync]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">พารามิเตอร์</h4>
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={() => navigate('detail')}
        >
          <FaPlus />เพิ่มพารามิเตอร์
        </Button>
      </div>
      <Criteria criteriaData={(data) => setCriteria(data)} />
      <hr />
      {!onInit && (
      <div className="DataTable">
        <Table total={totalRecords} onChange={(size, page) => (setPage(page), setSize(size))}>
          <thead>
            <tr>
              <th style={{ minWidth: 150 }}>กลุ่ม</th>
              <th style={{ minWidth: 150 }}>กลุ่มพารามิเตอร์</th>
              <th style={{ minWidth: 150 }}>ค่าพารามิเตอร์</th>
              <th style={{ minWidth: 50 }}>ลำดับ</th>
              <th style={{ minWidth: 100 }}>สถานะ</th>
              <th className="fix-width" />
            </tr>
          </thead>
          <tbody>
            {parameterListsData.map((data, index) => (
              <tr key={index}>
                <td className="text-center">{data.group}</td>
                <td className="text-center">{data.parameterGroup}</td>
                <td className="text-center">{data.value}</td>
                <td className="text-center">{data.sequence}</td>
                <td className="text-center">
                  <BadgeStatus status={data.isActive} />
                </td>
                <td className="d-flex justify-content-center gap-2">
                  <Button
                    variant="outline-primary"
                    className="d-flex align-items-center gap-2"
                    onClick={() => navigate(`detail/${data.id}`)}
                  >
                    <FaEdit />แก้ไข
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      )}
    </>
  );
}

function Criteria(props: CriteriaProps) {
  const [criteria, setCriteria] = useState<parameterListResponse>({} as parameterListResponse);
  const { dropDownLists, parameterGroupDropDown } = useLoaderData() as Loader;

  const onSearch = () => {
    props.criteriaData(criteria);
  };

  const onClearCriteria = () => {
    const criteriaData = {} as parameterListResponse;

    setCriteria(criteriaData);
    props.criteriaData(criteriaData);
  };

  return (
    <div className="criteria mt-3">
      <Row>
        <Col sm={12} md={6} lg={4} xl={3} className="d-flex gap-2">
          <Selector
            label="กลุ่ม"
            placeholder="กลุ่ม"
            value={criteria.group}
            items={dropDownLists}
            onChange={(value) => setCriteria({ ...criteria, group: value })}
          />
        </Col>
        <Col sm={12} md={6} lg={4} xl={3}>
          <Selector
            items={parameterGroupDropDown}
            label="กลุ่มย่อย"
            placeholder="กลุ่มย่อย"
            value={criteria.parameterGroup}
            onChange={(value) => setCriteria({ ...criteria, parameterGroup: value })}
          />
        </Col>
        <Col sm={12} md={6} lg={4} xl={3} className="d-flex gap-2">
          <Input
            label="ค่าพารามิเตอร์"
            placeholder="ค่าพารามิเตอร์"
            value={criteria.value}
            onChange={(value) => setCriteria({ ...criteria, value })}
          />
        </Col>
      </Row>
      <div className="d-flex gap-2">
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2 button-sm-inline"
          onClick={onSearch}
        >
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant="outline-primary"
          className="d-flex align-items-center gap-2 button-sm-inline"
          onClick={onClearCriteria}
        >
          <FaEraser />ล้าง
        </Button>
      </div>
    </div>
  );
}
