import {
  ButtonCustom,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Modal,
  Table,
} from 'components';
import { InputNumber } from 'components/Controls/InputNumber';
import { ContractAgreementWorkPhaseModel } from 'models/CM/CM02Models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { MdSave } from 'react-icons/md';
import { fullDate } from 'utils';
import toast from 'utils/toast';

interface Props {
  show: boolean;
  onHide?: () => void;
  contractAgreementId: string;
  contractAgreementWorkPhases: ContractAgreementWorkPhaseModel[];
  onSubmit: (saveDatas: ContractAgreementWorkPhaseModel[]) => void;
  isDisabled?: boolean;
}

export function WorkPhasesModel({ show, onHide, contractAgreementWorkPhases, onSubmit, contractAgreementId, isDisabled }: Props) {
  const [editContractAgreementWorkPhases, setEditContractAgreementWorkPhases] = useState<ContractAgreementWorkPhaseModel[]>([]);

  useEffect(() => {
    if (contractAgreementWorkPhases) {
      setEditContractAgreementWorkPhases(contractAgreementWorkPhases);
    }
  }, [contractAgreementWorkPhases]);

  const onSave = () => {

    onSubmit(editContractAgreementWorkPhases);
    onHide!();
  };

  const handlerOnChangePlace = (value: string, index: number) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].place = value;
    setEditContractAgreementWorkPhases(data);
  };

  const handlerOnChangeWorkPhasesDetail = (value: string, index: number) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].workPhasesDetail = value;
    setEditContractAgreementWorkPhases(data);
  };

  const handlerOnChangeWorkPhases = (value: number, index: number) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].workPhase = value;
    setEditContractAgreementWorkPhases(data);
  };

  const handlerOnChangePaidPhase = (value: number, index: number) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].paidPhase = value;
    setEditContractAgreementWorkPhases(data);
  };

  const addWorkphase = () => {
    const newData: ContractAgreementWorkPhaseModel = {
      id: undefined,
      contractAgreementId: contractAgreementId,
      sequence: editContractAgreementWorkPhases.length + 1,
      periodDay: 0,
      amount: 0,
      percent: 0,
      detail: '',
      deliveryDueDate: undefined,
    };

    setEditContractAgreementWorkPhases((editContractAgreementWorkPhases) => [...editContractAgreementWorkPhases, newData]);
  };

  const removeWorkphase = (i: number) => {
    const temp: ContractAgreementWorkPhaseModel[] = [...editContractAgreementWorkPhases];
    temp.splice(i, 1);
    setEditContractAgreementWorkPhases(temp);
  };

  return (
    <Modal
      show={show}
      size='xl'
      onHide={() => (onHide ? onHide() : null)}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title className='text-primary'>รายละเอียดงวดเงิน</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4 pt-2 pb-2' style={{ minHeight: 500 }}>
            <Row className='mt-3'>
              <Col sm={12}
                md={12}>
                <div className='d-flex justify-content-end'>
                  <ButtonCustom
                    className='no-label'
                    onClick={addWorkphase}
                    disabled={isDisabled}
                    text='เพิ่มรายการ' />
                </div>
              </Col>
            </Row>
            <Row className='pb-3 mb-2' style={{ color: '#FF850D', fontWeight: 400, borderBottom: '2px solid #FF850D' }}>
              <Col sm={1} className='text-center'>ลำดับ</Col>
              <Col sm={3} className='text-center'>รายการที่ส่งมอบ</Col>
              <Col sm={2} className='text-center'>จำนวนงวดเงิน</Col>
              <Col sm={2} className='text-center'>จำนวนงวดงาน</Col>
              <Col sm={3} className='text-center'>สถานที่ก่อสร้างหรือสถานที่ติดตั้ง <br />หรือสถานที่ปฎิบันติงาน</Col>
              <Col sm={1}></Col>
            </Row>
            {
              editContractAgreementWorkPhases?.map((data, index) => (
                <Row className='pb-2'>
                  <Col sm={1} className='text-center'>{index + 1}</Col>
                  <Col sm={3} className='text-center'>
                    <InputTextArea
                      value={data.workPhasesDetail}
                      onChange={(val) => handlerOnChangeWorkPhasesDetail(val, index)}
                      disabled={isDisabled} />
                  </Col>
                  <Col sm={2} className='text-center'>
                    <InputNumber
                      value={data.workPhase}
                      onChange={(val) => handlerOnChangeWorkPhases(Number(val), index)}
                      disabled={isDisabled} />
                  </Col>
                  <Col sm={2} className='text-center'>
                    <InputNumber
                      value={data.paidPhase}
                      onChange={(val) => handlerOnChangePaidPhase(Number(val), index)}
                      disabled={isDisabled} />
                  </Col>
                  <Col sm={3} className='text-center'>
                    <InputTextArea
                      value={data.place}
                      onChange={(val) => handlerOnChangePlace(val, index)}
                      disabled={isDisabled} />
                  </Col>
                  <Col sm={1}>
                    <Button
                      onClick={() => removeWorkphase(index)}
                      variant='danger'
                      disabled={isDisabled}
                    >
                      <FaTrashAlt />
                    </Button>
                  </Col>
                </Row>
              ))
            }
          </ModalBT.Body>
          <ModalBT.Footer className='justify-content-center'>
            <Button
              variant='outline-primary'
              onClick={() => (onHide ? onHide() : null)}
              className='me-2 px-3'
            >
              ยกเลิก
            </Button>
            <Button
              variant='primary'
              className='me-2 px-3'
              onClick={onSave}
              disabled={isDisabled}
            >
              <MdSave className='mx-2 pb-1 fs-4' />
              บันทึก
            </Button>
          </ModalBT.Footer>
        </>
      )}
    />
  );
}
