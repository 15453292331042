import StepProgress from 'components/StepProgress';
import { InvitedContractResponseModel } from 'models/CM/CM01Models';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import CM01Service from 'services/CM/CM01Service';
import { HttpStatusCode } from 'utils';
import Step1 from './step1';
import Step2 from './step2';
import { InvitedContractStatus } from 'components';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type ContractContext = {
  data: InvitedContractResponseModel
  setData: Dispatch<React.SetStateAction<InvitedContractResponseModel>>,

};

export const Context = createContext({} as ContractContext);

function InvitedContractDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id, taxId } = useParams();
  const [data, setData] = useState<InvitedContractResponseModel>({} as InvitedContractResponseModel);
  const [defaultStep, setDefaultStep] = useState(1);

  useEffect(() => {
    if (id && taxId) {
      getInvitedContractAsync(id, taxId);
    }
  }, [id, taxId]);

  const getInvitedContractAsync = async (id: string, taxId: string) => {
    const response = await CM01Service.getInvitedContractByIdAsync(id, taxId);
    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
      setStepByStatus(response.data.status);
    }
  };

  const setStepByStatus = (status: string) => {
    switch (status) {
      case InvitedContractStatus.WAITINGFORAPPROVE:
        setDefaultStep(2);
        break;
      case InvitedContractStatus.INVITED:
        setDefaultStep(2);
        break;
      case InvitedContractStatus.PENDING:
        setDefaultStep(2);
        break;
      default:
        break;
    }
  };

  const obj = [{
    title: <p>ข้อมูลใบสั่ง/สัญญา</p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p> เอกสารเชิญชวนทำสัญญา </p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/contract-invitation-sign');
  };

  return (
    <div className="document">
      <Row>
        <Col xs={12} className="mb-2">
          <Context.Provider value={{ data, setData }}>
            <StepProgress progressBarData={obj} ref={childRef} step={defaultStep} />
          </Context.Provider> <></>
        </Col>
      </Row>
    </div>
  );
}

export default InvitedContractDetail;
