import {
  Check,
  ContractAgreementStatus,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  DatePicker,
  Selector,
} from 'components/Controls';
import Title from 'components/Controls/Title';
import { CriteriaSupplyMethodId } from 'constant/ContractStatus';
import { ItemModel } from 'models';
import {
  ContractListResponseModel,
  CountStatusModel,
  CountSupplyMethodModel,
  SearchContractListModel,
  SearchCriteriaModel,
} from 'models/CM/CM02Models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import CM02Service from 'services/CM/CM02Service';
import {
  fullDate,
  HttpStatusCode,
} from 'utils';
import { useForm } from 'utils/useForm';

interface StatusItemModel {
  label: string;
  value: boolean;
}

type Loader = {
  departmentDDL: ItemModel[],
  supplyMethodDDL: ItemModel[],
};

export default function CreateContractManagement() {
  const {
    departmentDDL,
    supplyMethodDDL,
  } = useLoaderData() as Loader;
  const [formSearch, setFormSearch] = useState<SearchCriteriaModel>({} as SearchCriteriaModel);
  const [contractData, setContractData] = useState<ContractListResponseModel>({} as ContractListResponseModel);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    if (formSearch) {
      searchContractList(formSearch, page, size);
    }
  }, [formSearch]);

  const searchContractList = useCallback(async (formData: SearchCriteriaModel, page: number, size: number) => {
    const newFormData = { ...formData };

    newFormData.page = page;

    newFormData.size = size;

    if (newFormData.isResponsible === undefined) {
      newFormData.isResponsible = true;
    }

    if (newFormData.contractAgreementStatus === undefined) {
      newFormData.contractAgreementStatus = [ContractAgreementStatus.Draft,
      ContractAgreementStatus.WaitingForApprove,
      ContractAgreementStatus.WaitingForAttachSignedDocument,
      ContractAgreementStatus.Signed,
      ContractAgreementStatus.Rejected];
    }

    if (newFormData.supplyMethod === undefined) {
      newFormData.supplyMethod = [CriteriaSupplyMethodId.MethodId60, CriteriaSupplyMethodId.MethodId80];
    }

    const {
      data,
      status,
    } = await CM02Service.getListDraftContractMangementAsync(newFormData, page, size);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    await getCountStatus(newFormData);
    await getCountSupplyMethod(newFormData);
    setContractData(data);
  }, []);

  useNavigate();

  const handleSetPagination = (page: number, size: number) => {
    setPage(page);
    setSize(size);
    searchContractList(formSearch, page, size);
  };

  const getCountStatus = useCallback(async (searchModel: SearchCriteriaModel) => {
    const countStatusRes = await CM02Service.getCountStatusAsync(searchModel);

    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data);
    }
  }, []);

  const getCountSupplyMethod = useCallback(async (searchModel: SearchCriteriaModel) => {
    const countMethodRes = await CM02Service.getCountSupplyMethodAsync(searchModel);

    if (countMethodRes.status === HttpStatusCode.OK) {
      setCountSupplyMethod(countMethodRes.data);
    }
  }, []);

  return (
    <div className='m01'>
      <Title text='ร่างข้อมูลใบสั่ง/สัญญา' />
      <Criteria
        setForm={setFormSearch}
        departmentList={departmentDDL}
        supplyMethodList={supplyMethodDDL}
        countStatus={countStatus}
        countSupplyMethod={countSupplyMethod}
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
      />
      <DataTable
        contractDatas={contractData.data}
        total={contractData.totalRecords}
        page={page}
        size={size}
        onChange={(size, page) => {
          handleSetPagination(page, size);
        }}
      />
    </div>
  );
}

interface FormCriteriaInput {
  contractNumber: string;
  poNumber: string;
  planNumber: string;
  planName: string;
  departmentId: string;
  entrepreneurName: string;
  parcelDetail: string;
  contractStartDate: Date;
  contractEndDate: Date;
  supplyMethod: string[];
  contractAgreementStatus: string[];
}

interface CriteriaProps {
  setForm: (form: SearchCriteriaModel) => void;
  departmentList: ItemModel[];
  supplyMethodList: ItemModel[];
  countStatus: CountStatusModel[];
  countSupplyMethod: CountSupplyMethodModel[];
  page: number;
  size: number;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
}

function Criteria(props: CriteriaProps) {
  const [contractStartDate, setContractStartDate] = useState<Date>();
  const [contractEndDate, setContractEndDate] = useState<Date>();
  const [departmentId, setDepartmentId] = useState('');
  const [supplyMethods] = useState<ItemModel[]>(props.supplyMethodList);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>(props.countStatus);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>(props.countSupplyMethod);
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [statusDraft, setStatusDraft] = useState<boolean>(false);
  const [statusWaitingForApprove, setStatusWaitingForApprove] = useState<boolean>(false);
  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [statusWaitingForAttachSignedDocument, setStatusWaitingForAttachSignedDocument] = useState<boolean>(false);
  const [statusSigned, setStatusSigned] = useState<boolean>(false);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    {
      value: false,
      label: ContractAgreementStatus.Draft,
    },
    {
      value: false,
      label: ContractAgreementStatus.WaitingForApprove,
    },
    {
      value: false,
      label: ContractAgreementStatus.Rejected,
    },
    {
      value: false,
      label: ContractAgreementStatus.WaitingForAttachSignedDocument,
    },
    {
      value: false,
      label: ContractAgreementStatus.Signed,
    },
  ]);
  const [methodAll, setMethodAll] = useState<boolean>(false);
  const [method60, setMethod60] = useState<boolean>(false);
  const [method80, setMethod80] = useState<boolean>(false);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);
  const [methodLists, setMethodLists] = useState<StatusItemModel[]>([
    {
      value: false,
      label: CriteriaSupplyMethodId.MethodId60,
    },
    {
      value: false,
      label: CriteriaSupplyMethodId.MethodId80,
    },
  ]);

  useEffect(() => {
    if (props.countStatus) {
      setCountStatus(props.countStatus);
    }
  }, [props.countStatus]);

  useEffect(() => {
    if (props.countSupplyMethod) {
      setCountSupplyMethod(props.countSupplyMethod);
    }
  }, [props.countSupplyMethod]);

  useEffect(() => {
    setIsResponsible(true);
    onChangeMethodAll(true);
    onChangeStatusAll(true);
  }, []);

  const onChangeStatusAll = (result: boolean) => {
    setStatusAll(result);
    setStatusDraft(result);
    setStatusWaitingForApprove(result);
    setStatusRejected(result);
    setStatusWaitingForAttachSignedDocument(result);
    setStatusSigned(result);
  };

  const onChangeMethodAll = (result: boolean) => {
    setMethodAll(result);
    setMethod60(result);
    setMethod80(result);
  };

  const onClear = useCallback(async () => {
    setContractStartDate(undefined);
    setDepartmentId('');
    setContractEndDate(undefined);
    setIsResponsible(true);
    onChangeMethodAll(true);
    onChangeStatusAll(true);
    props.setPage(1);
    props.setSize(10);
    handleClear();
  }, []);

  const {
    handleSubmit,
    handleChange,
    data: formData,
    handleClear,
  } = useForm<FormCriteriaInput>({
    initialValues: {
      contractNumber: undefined,
      poNumber: undefined,
      planNumber: undefined,
      planName: undefined,
      departmentId: undefined,
      entrepreneurName: undefined,
      parcelDetail: undefined,
      contractStartDate: undefined,
      contractEndDate: undefined,
      supplyMethod: undefined,
      contractAgreementStatus: undefined,
    },
    validations: {},
    onSubmit: async () => {
      const contractStatus: string[] = [];
      const method: string[] = [];

      if (statusDraft) {
        contractStatus.push(ContractAgreementStatus.Draft);
      }

      if (statusWaitingForApprove) {
        contractStatus.push(ContractAgreementStatus.WaitingForApprove);
      }

      if (statusRejected) {
        contractStatus.push(ContractAgreementStatus.Rejected);
      }

      if (statusWaitingForAttachSignedDocument) {
        contractStatus.push(ContractAgreementStatus.WaitingForAttachSignedDocument);
      }

      if (statusSigned) {
        contractStatus.push(ContractAgreementStatus.Signed);
      }

      if (method60) {
        method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId60)[0].value);
      }

      if (method80) {
        method.push(supplyMethods.filter((x) => x.value === CriteriaSupplyMethodId.MethodId80)[0].value);
      }

      const searchModel: SearchCriteriaModel = {
        page: props.page,
        size: props.size,
        contractNumber: formData.contractNumber,
        poNumber: formData.poNumber,
        planNumber: formData.planNumber,
        planName: formData.planName,
        departmentId,
        entrepreneurName: formData.entrepreneurName,
        parcelDetail: formData.parcelDetail,
        contractStartDate: contractStartDate,
        contractEndDate: contractEndDate,
        supplyMethod: method,
        contractAgreementStatus: contractStatus,
        isResponsible,
      };

      props.setForm(searchModel);
    },
  });

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex((s) => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter((s) => !s.value).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case ContractAgreementStatus.Draft:
        return setStatusDraft(value);
      case ContractAgreementStatus.WaitingForApprove:
        return setStatusWaitingForApprove(value);
      case ContractAgreementStatus.Rejected:
        return setStatusRejected(value);
      case ContractAgreementStatus.WaitingForAttachSignedDocument:
        return setStatusWaitingForAttachSignedDocument(value);
      case ContractAgreementStatus.Signed:
        return setStatusSigned(value);
      default:
        break;
    }
  };

  const onChangeCheckMethod = (methodId: string, value: boolean) => {
    const methodList = [...methodLists];
    const index = methodLists.findIndex((s) => s.label === methodId);
    methodList[index].value = value;
    setMethodLists(methodList);

    if (!value) {
      setMethodAll(false);
    }

    if (methodList.filter(s => s.value === false).length === 0) {
      setMethodAll(true);
    }

    switch (methodId) {
      case CriteriaSupplyMethodId.MethodId60:
        return setMethod60(value);
      case CriteriaSupplyMethodId.MethodId80:
        return setMethod80(value);
      default:
        break;
    }
  };

  return (
    <Form onSubmit={handleSubmit}
      id='formCM02'>
      <Row className='criteria'>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่สัญญา</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                placeholder='เลขที่สัญญา'
                value={formData.contractNumber || ''}
                onChange={handleChange<string>('contractNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่ PO</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                placeholder='เลขที่ PO'
                value={formData.poNumber || ''}
                onChange={handleChange<string>('poNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่โครงการ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                placeholder='เลขที่โครงการ'
                value={formData.planNumber || ''}
                onChange={handleChange<string>('planNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Selector
              label='ฝ่าย/สำนัก'
              items={props.departmentList}
              value={`${departmentId}`}
              onChange={(value) => (setDepartmentId(value.toString()))}
            />
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>ชื่อโครงการ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                placeholder='ชื่อโครงการ'
                value={formData.planName || ''}
                onChange={handleChange<string>('planName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>ชื่อบริษัท</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                placeholder='ชื่อบริษัท'
                value={formData.entrepreneurName || ''}
                onChange={handleChange<string>('entrepreneurName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <DatePicker
              label='วันที่เริ่มสัญญา'
              value={contractStartDate}
              onChangeHasNullable={setContractStartDate}
            />
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <DatePicker
              label='วันที่สิ้นสุด'
              value={contractEndDate}
              onChangeHasNullable={setContractEndDate}
            />
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>รายละเอียดพัสดุ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                placeholder='รายละเอียดพัสดุ'
                value={formData.parcelDetail || ''}
                onChange={handleChange<string>('parcelDetail', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Row>
          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>วิธีจัดหา</Form.Label>
              <InputGroup className='align-items-center gap-3'>
                <Check
                  label={(
                    <div className='align-items-center d-flex'>
                      ทั้งหมด
                      <Badge
                        className='h-50 mx-2'
                        bg='light'
                      >
                        {
                          countSupplyMethod.length > 0
                            ? countSupplyMethod
                              .map((x) => x.count)
                              .reduce((prev, curr) => prev + curr)
                            : 0
                        }
                      </Badge>
                    </div>
                  )}
                  value={methodAll}
                  onChange={(val) => onChangeMethodAll(val)}
                />
                <Check
                  label={(
                    <div className='align-items-center d-flex'>
                      พ.ร.บ.จัดซื้อจัดจ้างฯ
                      2560
                      <Badge
                        className='h-50 mx-2'
                        bg='primary'
                      >
                        {
                          countSupplyMethod
                            .filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId60).length > 0
                            ? countSupplyMethod
                              .filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId60)[0].count
                            : 0
                        }
                      </Badge>
                    </div>
                  )}
                  value={method60}
                  onChange={(val) => onChangeCheckMethod(CriteriaSupplyMethodId.MethodId60, val)}
                />
                <Check
                  label={(
                    <div className='align-items-center d-flex'>
                      ข้อบังคับธนาคาร 80
                      <Badge
                        className='h-50 mx-2'
                        bg='primary'
                      >
                        {
                          countSupplyMethod
                            .filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId80).length > 0
                            ? countSupplyMethod
                              .filter((x) => x.supplyMethod === CriteriaSupplyMethodId.MethodId80)[0].count
                            : 0
                        }
                      </Badge>
                    </div>
                  )}
                  value={method80}
                  onChange={(val) => onChangeCheckMethod(CriteriaSupplyMethodId.MethodId80, val)}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>สถานะ</Form.Label>
              <InputGroup className='align-items-center gap-3'>
                <Check
                  label={(
                    <div className='align-items-center d-flex'>
                      ทั้งหมด
                      <Badge
                        className='h-50 mx-2 text-dark'
                        bg='light'
                      >
                        {countStatus.length > 0 ? countStatus.map((x) => x.count)
                          .reduce((prev, curr) => prev + curr) : 0}
                      </Badge>
                    </div>
                  )}
                  value={statusAll}
                  onChange={(val) => onChangeStatusAll(val)}
                />
                <Check
                  label={(
                    <div className='align-items-center d-flex'>
                      แบบร่าง
                      <Badge
                        className='h-50 mx-2'
                        bg='light'
                      >
                        {
                          countStatus
                            .filter((x) => x.status === ContractAgreementStatus.Draft).length > 0
                            ? countStatus
                              .filter((x) => x.status === ContractAgreementStatus.Draft)[0].count
                            : 0
                        }
                      </Badge>
                    </div>
                  )}
                  value={statusDraft}
                  onChange={(val) => onChangeCheckStatus(ContractAgreementStatus.Draft, val)}
                />
                <Check
                  label={(
                    <div className='align-items-center d-flex'>
                      รอตรวจสอบร่างสัญญา
                      <Badge
                        className='h-50 mx-2'
                        bg='warning'
                      >
                        {
                          countStatus
                            .filter((x) => x.status === ContractAgreementStatus.WaitingForApprove).length > 0
                            ? countStatus
                              .filter((x) => x.status === ContractAgreementStatus.WaitingForApprove)[0].count
                            : 0
                        }
                      </Badge>
                    </div>
                  )}
                  value={statusWaitingForApprove}
                  onChange={(val) => onChangeCheckStatus(ContractAgreementStatus.WaitingForApprove, val)}
                />
                <Check
                  label={(
                    <div className='align-items-center d-flex'>
                      ตรวจสอบร่างสัญญาแล้ว
                      <Badge
                        className='h-50 mx-2'
                        bg='success'
                      >
                        {
                          countStatus
                            .filter((x) => x.status === ContractAgreementStatus.WaitingForAttachSignedDocument).length > 0
                            ? countStatus
                              .filter((x) => x.status === ContractAgreementStatus.WaitingForAttachSignedDocument)[0].count
                            : 0
                        }
                      </Badge>
                    </div>
                  )}
                  value={statusWaitingForAttachSignedDocument}
                  onChange={(val) => onChangeCheckStatus(ContractAgreementStatus.WaitingForAttachSignedDocument, val)}
                />
                <Check
                  label={(
                    <div className='align-items-center d-flex'>
                      ลงนามแล้ว
                      <Badge
                        className='h-50 mx-2'
                        bg='success'
                      >
                        {
                          countStatus
                            .filter((x) => x.status === ContractAgreementStatus.Signed).length > 0
                            ? countStatus.filter((x) => x.status === ContractAgreementStatus.Signed)[0].count
                            : 0
                        }
                      </Badge>
                    </div>
                  )}
                  value={statusSigned}
                  onChange={(val) => onChangeCheckStatus(ContractAgreementStatus.Signed, val)}
                />
                <Check
                  label={(
                    <div className='align-items-center d-flex'>
                      ส่งกลับแก้ไขร่างสัญญา
                      <Badge
                        className='h-50 mx-2'
                        bg='danger'
                      >
                        {
                          countStatus
                            .filter((x) => x.status === ContractAgreementStatus.Rejected).length > 0
                            ? countStatus
                              .filter((x) => x.status === ContractAgreementStatus.Rejected)[0].count
                            : 0
                        }
                      </Badge>
                    </div>
                  )}
                  value={statusRejected}
                  onChange={(val) => onChangeCheckStatus(ContractAgreementStatus.Rejected, val)}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Check
              label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
              value={isResponsible}
              onChange={(val) => setIsResponsible(val)}
            />
          </Col>
        </Row>
        <div className='d-flex gap-2'>
          <Button form='formCM02'
            type='submit'
            variant='primary'
            className='me-2'>
            <FaSearch className='me-2' />
            ค้นหา
          </Button>
          <Button
            onClick={onClear}
            variant='outline-primary'
            type='submit'
            form='formCM02'
            className='d-flex align-items-center gap-2'
          >
            <FaEraser />
            ล้าง
          </Button>
        </div>
      </Row>
    </Form>
  );
}

interface DataTableProps {
  page: number;
  size: number;
  total: number;
  contractDatas: SearchContractListModel[];
  onChange?: (size: number, page: number) => void;
}

function DataTable(props: DataTableProps) {
  const [datas, setDatas] = useState<SearchContractListModel[]>([]);

  const navigate = useNavigate();

  const handleSetPagination = (page: number, size: number) => {
    if (props.onChange) {
      props.onChange(size, page);
    }
  };

  useEffect(() => {
    if (props && props.contractDatas) {
      setDatas(props.contractDatas);
    }
  }, [props]);

  function statusToNewLine(status: String) {
    return (
      <>
        {
          status.split('|').map((val, index) => (
            <div>
              <Status type={StatusType.DRAFT_CONTRACT}
                value={val} />
            </div>
          ))
        }
      </>
    )
  }

  function statusAndEditingToNewLine(status: String, editingValue: string) {
    return (
      <>
        {
          status.split('|').map((val, index) => (
            <div>
              <Status type={StatusType.DRAFT_CONTRACT}
                value={val} />
              <div className='status'
                style={{ marginRight: 10 }}>
                <span className='text-warning'>
                  {editingValue.split('|')[index] === 'true' ? '(อยู่ระหว่างบันทึกต่อท้ายสัญญา)' : ''}
                </span>
              </div>
            </div>
          ))
        }
      </>
    )
  }

  function toNewLine(value: string) {
    return (
      <>
        {
          value.split('|').map((val) => (
            { val }
          ))
        }
      </>
    )
  }

  function contracrDateToNewLine(value: string) {
    return (
      <>
        {
          value.split('|').map((val) => (
            <div>
              {contracrDate(val)}
            </div>
          ))
        }
      </>
    )
  }

  function contracrDate(value: string) {
    const newDate = new Date(value)
    const fullNewDate = fullDate(newDate)
    return fullNewDate;
  }

  const toLinkNewLine = (id: string, value: string) => {
    return (
      <>
        {
          id.split('|').map((id, index) => (
            <div>
              <Button variant='link'
                onClick={() => goToPage(id)}>
                {value.split('|')[index]}
              </Button>
            </div >
          ))
        }
      </>
    )
  }

  const goToPage = (id: string) => {
    navigate(`/contract-agreement/detail/${id}`);
  };

  return (
    <Table
      total={props.total}
      onChange={(size, page) => {
        handleSetPagination(page, size);
      }}
    >
      <thead>
        <tr>
          <th style={{ minWidth: 50 }}>
            เลขที่สัญญา
          </th>
          <th style={{ width: 50 }}>
            เลขที่ PO
          </th>
          <th style={{ minWidth: 50 }}>สถานะ</th>
          <th style={{ minWidth: 50 }}>เลขที่โครงการ</th>
          <th style={{ minWidth: 50 }}>ฝ่าย/สำนัก</th>
          <th style={{ minWidth: 50 }}>ชื่อโครงการ</th>
          <th>วิธีจัดหา</th>
          <th style={{ minWidth: 50 }}>
            ผู้ประกอบการ/
            <br />
            คู่ค้า
          </th>
          <th style={{ minWidth: 50 }}>
            วันที่ทำเริ่ม
            <br />
            สัญญา
          </th>
          <th style={{ minWidth: 50 }}>
            วันที่สิ้นสุด
            <br />
            สัญญา
          </th>
        </tr>
      </thead>
      <tbody>
        {
          datas?.map((data, index) => (
            <tr className='text-center'
              key={data.id}>
              <td>
                {toLinkNewLine(data.id, data.contractNumber)}
              </td>
              <td>
                {toLinkNewLine(data.id, data.poNumber)}
              </td>
              <td>{data.inEditing != null ? statusAndEditingToNewLine(data.status, data.inEditing) : statusToNewLine(data.status)}</td>
              <td>{data.planNumber}</td>
              <td>{data.departmentName}</td>
              <td className='text-start'>{data.planName}</td>
              <td className='text-start'>{data.supplyMethodName} : {data.supplyMethodSpecialTypeName}</td>
              <td className='text-start'>{data.entrepreneurName}</td>
              <td>{data.contractStartDate != null ? contracrDateToNewLine(data.contractStartDate) : ''}</td>
              <td>{data.contractEndDate != null ? contracrDateToNewLine(data.contractEndDate) : ''}</td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
}
