import http from 'utils/axios';

const getNotificationByUserIdAsync = async () =>
  http.get('/notification', { headers: { disabledLoading: true } });

const updateReadNotificationAsync = async (id: string) =>
  http.put(`/notification/${id}`, { headers: { disabledLoading: true } });

const readNotificationByUserAsync = async () =>
  http.put('notification/read-by-user', { headers: { disabledLoading: true } });

export default {
  getNotificationByUserIdAsync,
  updateReadNotificationAsync,
  readNotificationByUserAsync,
};
