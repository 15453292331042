import {
  Card,
  FileValue,
  Input,
  InputTextArea,
  Modal,
  Selector,
  Table,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  CreatorLevel,
  JorPorCode,
} from 'constant/basicInformation';
import {
  JorporDirectorBeInformed,
  PlanProcurement,
} from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaCheckCircle,
  FaEraser,
  FaSave,
  FaSearch,
  FaUndo,
} from 'react-icons/fa';
import { useLoaderData } from 'react-router-dom';
import { planProcurement as planProcurementService } from 'services';
import {
  downloadFile,
  HttpStatusCode,
  ProcurementSection,
  showConfirmModalAsync,
  submitForm,
  useAppContext,
} from 'utils';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';
import {
  AcceptorStatus,
  PlanStatus as PlanStatusCons,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { Context } from '.';
import { UploadFile } from './UploadFile';

interface Props {
  onClickNext?: () => void;
  onClickBack?: () => void;
}

type Loader = {
  departments: { value: string, label: string }[],
  positions: { value: string, label: string }[],
}

type UserCriteria = {
  name: string;
  departmentId: string;
  positionId: string;
}

type UserResponse = {
  id: string;
  no: number;
  name: string;
  department: string;
  position: string;
  status: string;
  UserDate: Date;
  isAvailable: boolean;
}

export default function Step2(props: Props) {
  const { currentStep, planProcurement, setPlanProcurement, reGetDetailAsync, readonly } = useContext(Context);
  const { userId, positionCode, departmentId, departmentCode, inRefLevel } = useAppContext();
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
  const [remarkApprove, setRemarkApprove] = useState('');

  const canRemoveFile = useMemo(() => {
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const isMD = useMemo(() => {
    return positionCode === 'MD';
  }, [positionCode]);

  const handlerOnSubmitAsync = async () => {
    if (planProcurement.status === PlanStatusCons.WaitingApprovePlan) {
      if (!planProcurement.jorporDirectorBeInformed?.length) {
        toast.warn('ต้องมีผอ.จพ.รับทราบอย่างน้อย 1 คน');

        return;
      }

      if (!planProcurement.departmentDirectorAgree.some(d =>
        !d.isAvailable && d.status === AcceptorStatus.PENDING)) {
        toast.warn('ต้องมีฝ่ายเห็นชอบที่สามารถปฏิบัติงานได้อย่างน้อย 1 คน');

        return;
      }
    }

    const res = await planProcurementService
      .updateAsync(planProcurement);

    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
      const newData = await reGetDetailAsync(planProcurement.id);

      toast.success('บันทึกสำเร็จ');

      return newData;
    }

    return null;
  };

  const userOnSelect = (
    userId: string,
    fullName: string,
    departmentName: string,
    positionName: string,
  ) => {
    if (!planProcurement.jorporDirectorBeInformed) {
      planProcurement.jorporDirectorBeInformed = [];
    }

    setPlanProcurement({
      ...planProcurement,
      jorporDirectorBeInformed: [
        ...planProcurement.jorporDirectorBeInformed,
        {
          userId,
          fullName,
          departmentName,
          positionName,
          sectionCode: ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED,
          sequence: planProcurement.jorporDirectorBeInformed.length + 1,
        } as JorporDirectorBeInformed,
      ],
    });
  };

  const approveAsync = async () => {
    if (!planProcurement.jorporDirectorBeInformed || !planProcurement.jorporDirectorBeInformed.length) {
      toast.warn('ต้องมีผอ.จพ.รับทราบอย่างน้อย 1 คน');

      return;
    }

    if (planProcurement.jorporDirectorBeInformed.some((jp) => !jp.id)) {
      toast.warn('กรุณาบันทึกก่อนเห็นชอบ');

      return;
    }

    const approver = planProcurement.departmentDirectorAgree
      .find((d) => (d.userId === userId && !d.delegateUserId) || (d.delegateUserId === userId));

    if (!approver) {
      return;
    }

    const res = await planProcurementService
      .approveAsync(
        planProcurement.id,
        approver.id,
        remarkApprove,
      );

    if (res.status === HttpStatusCode.ACCEPTED) {
      reGetDetailAsync(planProcurement.id);
      toast.success('อนุมัติเห็นชอบสำเร็จ');
      setShowApproveModal(false);
    }
  };

  const uploadFileOnChangeAsync = async (file: File) => {
    if (planProcurement.id) {
      const res = await planProcurementService.uploadFileAsync(
        planProcurement.id,
        file,
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        setPlanProcurement({
          ...planProcurement,
          documents: [
            ...planProcurement.documents,
            {
              file,
            },
          ],
        });

        toast.success('อัปโหลดไฟล์สำเร็จ');

        reGetDetailAsync(planProcurement.id);
      }
    } else {
      if (!planProcurement.documents) {
        planProcurement.documents = [];
      }

      setPlanProcurement({
        ...planProcurement,
        documents: [
          ...planProcurement.documents,
          {
            file,
          },
        ],
      });
    }
  };

  const files = useCallback(() => {
    const files = planProcurement.documents?.filter((d) => d.id && d.name);

    return files?.map((f) => ({
      id: f.id,
      name: f.name,
      attachmentBy: f.byUserId,
    })) as FileValue[];
  }, [planProcurement.documents]);

  const removeFileAsync = async (i: number, id: string | undefined) => {
    if (id) {
      const res = await planProcurementService.removeFileAsync(planProcurement.id, id);

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
      }
    }

    planProcurement.documents.splice(i, 1);

    setPlanProcurement({
      ...planProcurement,
      documents: [...planProcurement.documents],
    });
  };

  const downloadFileAsync = async (index: number, documentId?: string) => {
    if (documentId) {
      const res = await planProcurementService
        .downloadFileAsync(planProcurement.id, documentId);

      if (res.status === HttpStatusCode.OK) {
        const file = planProcurement.documents[index];

        downloadFile(res.data);
      }
    } else {
      const file = planProcurement.documents[index];

      if (file.file) {
        downloadFile(file.file);
      }
    }
  };

  const recallAsync = async () => {
    const confirmRes = await showConfirmModalAsync('คุณต้องการเรียกคืนแก้ไขหรือไม่ ?');

    if (confirmRes) {
      const res = await planProcurementService.updateStatusAsync(
        planProcurement.id,
        PlanStatusCons.DraftPlan,
        'เรียกคืนแก้ไข',
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        reGetDetailAsync(planProcurement.id);
        toast.success('เรียกคืนแก้ไขสำเร็จสำเร็จ');
      }
    }
  };

  const checkQueue = (data: PlanProcurement, userId: string) => {
    const sectionData = data.departmentDirectorAgree
      .filter(d => d.status === AcceptorStatus.PENDING
        && !d.isAvailable)
      .sort((a, b) => a.sequence - b.sequence)[0];

    if (sectionData) {
      return (!sectionData.delegateUserId &&
        sectionData.userId === userId) ||
        (sectionData.delegateUserId === userId);
    }

    return false;
  };

  const canRecall = useMemo(() => {
    if (!planProcurement || !userId || currentStep !== 2) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.WaitingApprovePlan) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    if (planProcurement.departmentDirectorAgree?.some(d =>
      d.status !== AcceptorStatus.PENDING)) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canRework = useMemo(() => {
    if (!planProcurement || currentStep !== 2 || !userId) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.WaitingApprovePlan) {
      return false;
    }

    if (planProcurement.isCanceled && planProcurement.isActive) {
      return false;
    }

    if (checkQueue(planProcurement, userId)) {
      return true;
    }

    return false;
  }, [planProcurement, currentStep, userId]);

  const canSave = useMemo(() => {
    if (!planProcurement || currentStep !== 2 || !userId) {
      return false;
    }

    if (planProcurement.status === PlanStatusCons.WaitingApprovePlan) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canApprove = useMemo(() => {
    if (!planProcurement || currentStep !== 2 || !userId) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.WaitingApprovePlan) {
      return false;
    }

    if (checkQueue(planProcurement, userId)) {
      return true;
    }

    return false;
  }, [planProcurement, currentStep, userId]);

  return (
    <>
      {/* Upload File */}
      {currentStep === 2
        ? (
          <Card className='mt-3'>
            <Title text='เอกสารแนบ'
              className='fs-5 text-primary' />
            <Row className='justify-content-center'>
              <Col sm={12}
                lg={6}>
                <UploadFile
                  onChange={uploadFileOnChangeAsync}
                  value={files()}
                  onRemove={removeFileAsync}
                  onDownload={downloadFileAsync}
                  canRemoveFile={canRemoveFile}
                  disabled={readonly}
                />
              </Col>
            </Row>
          </Card>
        ) : null}

      {/* Button */}
      {currentStep === 2
        ? (
          <div className='d-flex justify-content-between mt-3'>
            <ButtonCustom
              onClick={props.onClickBack}
              text="กลับหน้าแรก"
            />
            <div className='d-flex gap-2'>
              {canRecall
                ? (
                  <ButtonCustom
                    text="เรียกคืนแก้ไข"
                    icon='undo'
                    variant='danger'
                    onClick={recallAsync}
                  />
                ) : null}
              {canRework ? (
                <ButtonCustom
                  text="ส่งกลับแก้ไข"
                  icon='undo'
                  variant='danger'
                  onClick={() => setShowRejectModal(true)}
                />
              )
                : null}
              {canSave ? (
                <ButtonCustom
                  onClick={() => handlerOnSubmitAsync()}
                  text="บันทึก"
                />
              ) : null}
              {canApprove ? (
                <ButtonCustom
                  onClick={() => setShowApproveModal(true)}
                  variant='success'
                  icon='checked'
                  text="เห็นชอบ"
                />
              ) : null}
            </div>
            <div style={{ width: 100 }} />
          </div>
        ) : null}

      {/* Modal */}
      <SearchUserModal
        show={showUserModal}
        onHide={() => setShowUserModal(false)}
        onSelect={userOnSelect}
      />
      <RejectModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(false)}
      />
      <Modal
        show={showApproveModal}
        size='lg'
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>เห็นชอบ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              {/* คุณต้องการเห็นชอบ ? */}
              <InputTextArea
                label='ความเห็นเพิ่มเติม (ถ้ามี)'
                value={remarkApprove}
                onChange={(value) => setRemarkApprove(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApproveModal(!showApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => approveAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

function SearchUserModal(props: {
  show: boolean;
  onHide: () => void;
  onSelect: (id: string, fullName: string, departmentName: string, positionName: string) => void;
}) {
  const { planProcurement } = useContext(Context);
  const loader = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<UserCriteria>({} as UserCriteria);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (props.show) {
      setSize(10);
      setPage(1);
      clearCriteria();
      getUserAsync(10, 1);
    }
  }, [props.show]);

  const handlerOnChange = (key: string, value: string) => {
    setCriteria({
      ...criteria,
      [key]: value,
    });
  };

  const onSelect = (id: string) => {
    if (planProcurement.jorporDirectorBeInformed?.some((d) => d.userId === id)) {
      toast.warn('ข้อมูลซ้ำ');

      return;
    }

    const user = users.find((a) => a.id === id);

    if (user) {
      props.onSelect(
        user.id,
        user.name,
        user.department,
        user.position,
      );

      props.onHide();
    }
  };

  const getUserAsync = async (size: number, page: number) => {
    const res = await planProcurementService
      .getUsersAsync(size, page, criteria.name, criteria.departmentId, criteria.positionId);

    if (res.status === HttpStatusCode.OK) {
      setUsers(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }
  };

  const clearCriteria = () => {
    setCriteria({} as UserCriteria);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='xl'
    >
      <Modal.Header>
        <Title
          text='รายชื่อฝ่ายเห็นชอบ'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col sm={6}
          lg={4}>
          <Input
            label='ชื่อ-นามสกุล'
            value={criteria.name}
            onChange={(val) => handlerOnChange('name', val)}
          />
        </Col>
        <Col sm={6}
          lg={4}>
          <Selector
            label='ฝ่าย/สำนัก'
            value={criteria.departmentId}
            onChange={(val) => handlerOnChange('departmentId', val)}
            items={loader.departments}
          />
        </Col>
        <Col sm={6}
          lg={4}>
          <Selector
            label='ตำแหน่ง'
            value={criteria.positionId}
            onChange={(val) => handlerOnChange('positionId', val)}
            items={loader.positions}
          />
        </Col>
      </Row>
      <div className='d-flex gap-2'>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => getUserAsync(size, page)}
        >
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant='outline-primary'
          className='d-flex align-items-center gap-2'
          onClick={clearCriteria}
        >
          <FaEraser />ล้าง
        </Button>
      </div>
      <Table
        className='mt-3'
        total={totalRecords}
        onChange={(size, page) => {
          setSize(size);
          setPage(page);
          getUserAsync(size, page);
        }}
      >
        <thead>
          <tr>
            <th style={{ minWidth: 20 }}>ลำดับ</th>
            <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
            <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
            <th style={{ minWidth: 25 }} />
          </tr>
        </thead>
        <tbody>
          {
            users.map((data, i) => (
              <tr key={data.id}>
                <td className='text-center'>{calculateRowNumber(i, page, size)}</td>
                <td>{data.name}</td>
                <td>{data.department}</td>
                <td>{data.position}</td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Button variant='primary'
                      onClick={() => onSelect(data.id)}>
                      เลือก
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Modal>
  );
}

function RejectModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement, reGetDetailAsync, setCurrentStep } = useContext(Context);
  const [remark, setRemark] = useState<string>();
  const { userId, departmentId, departmentCode } = useAppContext();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (!remark) {
      return;
    }

    const approver = planProcurement.departmentDirectorAgree
      .find((d) => (d.userId === userId && !d.delegateUserId) || (d.delegateUserId === userId));

    if (approver) {
      const res = await planProcurementService
        .rejectAsync(
          planProcurement.id,
          approver.id,
          PlanStatusCons.RejectPlan,
          remark,
        );

      if (res.status === HttpStatusCode.ACCEPTED) {
        reGetDetailAsync(planProcurement.id);
        toast.success('ส่งกลับแก้ไขสำเร็จ');

        props.onHide();

        // if (planProcurement.departmentDirectorAgree?.some((d) => planProcurement.createdByUserId)) {
        //   setCurrentStep(1);
        // }
        if (planProcurement.departmentId === departmentId) {
          setCurrentStep(1);
        }
      }
    }

  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
    >
      <Modal.Header>
        <Title
          text='ส่งกลับแก้ไข'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label='เหตุผล'
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name='remark'
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-end gap-2'>
        <Button
          variant='outline-primary'
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant='primary'
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}
