import { MdCircle } from 'react-icons/md';

interface Props {
  text?: string;
  className?: string;
  status: 'reject' | 'general' | 'comment' | string;
}

function RejectModal(status: string) {
  if (status === 'reject') {
    return 'text-danger';
  }

  if (status === 'comment') {
    return 'text-success';
  }

  return 'text-muted';
}

export function TitleModal({
  text,
  className,
  status = 'comment',
}: Props) {
  return (
    <div className={className}>
      <div className={`d-flex align-items-center ${RejectModal(status)}`}>
        <MdCircle className='fs-6 me-2 ' />
        <span className='text-dark fs-5'>{text}</span>
      </div>
    </div>
  );
}
