import Title from 'components/Controls/Title';
import {
  HistoryModal,
  Modal,
} from 'components/Modal';
import {
  CreateAppointCommiteeModel,
  UpdateAppointCommiteeModel,
} from 'models/PR/PR0202Models';
import {
  useContext,
  useState,
} from 'react';
import {
  Button,
  Card,
  Modal as ModalBT,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaHistory,
  FaSave,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import {
  useNavigate,
  useParams,
} from 'react-router';
import PR0202Services from 'services/PR/PR0202Services';
import {
  HttpStatusCode,
  StatusName,
} from 'utils';
import toast from 'utils/toast';
import { dataHistory } from '../data';
import { Context } from './index';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  onDisable?: boolean;
}

function Step6({ onClickBack }: Props) {
  const [show, setShow] = useState(false);
  const { data, setData } = useContext(Context);
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  useNavigate();

  const onGetProcurementAsync = async (id: string) => {
    const response = await PR0202Services.getAppointmentProcurementByIdAsync(id);

    if (response.status !== HttpStatusCode.OK) {
      toast.error('Test');
      return;
    }

    setData(response.data);
  };

  const approverProcurementPendingAsync = async () => {
    const response = await PR0202Services.approverProcurementPendingAsync(data.id!);

    if (response.data === 'Approver Not Found') {
      return toast.error('ไม่สามารถอนุมัติได้เนื่องจากไม่มีสิทธิ์');
    }

    if (response.status === HttpStatusCode.OK) {
      toast.success('อนุมัติสำเร็จ');
      onGetProcurementAsync(data.id!);
    }

    setShowModal(false);
  };

  const onSubmitAsync = async (Type: string) => {
    if (Type === '') {
      toast.error('เกิดข้อผิดพลาด');
      return;
    }

    if (typeof id === 'string') {
      const httpStatus = HttpStatusCode.OK;

      const updateDataModel: UpdateAppointCommiteeModel = {
        id: data.id,
        planId: data.planId,
        planName: data.planName,
        supplyMethodType: data.supplyMethodType,
        supplyMethod: data.supplyMethod,
        supplyMethodSpecialType: data.supplyMethodSpecialType,
        planBudget: data.planBudget,
        expectingProcurementAt: data.expectingProcurementAt,
        subject: data.subject,
        reason: data.reason,
        templateId: data.templateId,
        descriptionDutyProcurement: data.descriptionDutyProcurement,
        descriptionDutyCheck: data.descriptionDutyCheck,
        descriptionDutyMaintenanceService: data.descriptionDutyMaintenanceService,
        status: data.status,
        committeeAppointProcurements: data.committeeAppointProcurements,
        committeeChecks: data.committeeChecks,
        committeeMaintenanceServices: data.committeeMaintenanceServices,
        appointProcurementApprovers: data.appointProcurementApprovers,
        files: data.files,
        updatedByUserFullName: '',
        docNameforDelete: data.docNameforDelete,
      };

      if (httpStatus === HttpStatusCode.OK) {
        const res = await PR0202Services.updateAppointCommiteeAsync(updateDataModel);
        if (data.docNameforDelete === undefined) data.docNameforDelete = [];


        if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
          setShow(false);
          toast.success('แก้ไขข้อมูลสำเร็จ');
          onGetProcurementAsync(data.id!);
        } else {
          toast.error(res.statusText);
        }
      }
    } else {
      const createDataModel: CreateAppointCommiteeModel = {
        planId: data.planId,
        planName: data.planName,
        supplyMethodType: data.supplyMethodType,
        supplyMethod: data.supplyMethod,
        supplyMethodSpecialType: data.supplyMethodSpecialType,
        planBudget: data.planBudget,
        expectingProcurementAt: data.expectingProcurementAt,
        subject: data.subject,
        reason: data.reason,
        templateId: data.templateId,
        descriptionDutyProcurement: data.descriptionDutyProcurement,
        descriptionDutyCheck: data.descriptionDutyCheck,
        descriptionDutyMaintenanceService: data.descriptionDutyMaintenanceService,
        status: data.status,
        committeeAppointProcurements: data.committeeAppointProcurements,
        committeeChecks: data.committeeChecks,
        committeeMaintenanceServices: data.committeeMaintenanceServices,
        appointProcurementApprovers: data.appointProcurementApprovers,
        files: data.files,
        createdByUserFullName: '',
      };

      const res = await PR0202Services.createAppointCommiteeAsync(createDataModel);
      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('บันทึกข้อมูลสำเร็จ');
        onGetProcurementAsync(res.data.id);
      } else {
        toast.error(res.statusText);
      }
    }
  };

  return (
    <div className="document">
      <Card className="mt-3">
        <Card.Body>
          <div className="d-flex justify-content-between my-3">
            <Title text="ตัวอย่างเอกสารรายงานขอชื้ขอจ้าง ( จพ.005 )" className="fs-5 text-primary" />
            <Button
              className="px-3"
              onClick={() => setShow(true)}
              variant="outline-primary"
            >
              <FaHistory className="me-3" />ประวัติการแก้ไข
            </Button>
          </div>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-center gap-3 pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="outline-primary"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        {
          (data && (data.status === undefined || data.status === StatusName.DRAFT || data.status === StatusName.REJECTED)) && (
            <Button
              variant="outline-primary"
              className="d-flex align-items-center gap-2"
              onClick={() => onSubmitAsync(StatusName.DRAFT)}
            >
              <FaSave className="ms-2 pb-1 fs-5" />บันทึก
            </Button>

          )
        }
        {
          (data && (data.status === StatusName.DRAFT || data.status === StatusName.REJECTED)) && (
            <Button
              className="me-2 px-3"
              variant="primary"
              disabled={data.status === undefined}
              onClick={() => setShowModal(true)}
            >
              <FaAlignLeft className="me-2 pb-1 fs-5" />
              ส่งอนุมัติ
            </Button>
          )
        }
      </div>
      <HistoryModal
        show={show}
        onHide={() => setShow(!show)}
        data={dataHistory}
      />
      <Modal
        show={showModal}
        size="lg"
        onHide={() => setShowModal(!showModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>อนมุัติ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              คุณต้องการอนุมัติบันทึกการแต่งตั้งหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant="outline-primary" onClick={() => setShowModal(!showModal)}>
                ยกเลิก
              </Button>
              <Button
                variant="primary"
                onClick={approverProcurementPendingAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step6;
