import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Currency,
  Input,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import { DefectsWarrantyMoreModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';

interface IProps {
  defectsWarrantyDetail: DefectsWarrantyMoreModel;
  periodTypeDDL: ItemModel[];
  periodConditionDDL: ItemModel[];
  onDefectWarrantyDetailChange: (value: DefectsWarrantyMoreModel) => void;
  isDisabled?: boolean;
}

const TimesDDL = [
  {
    label: 'ชั่วโมง',
    value: 'hour',
  },
  {
    label: 'นาที',
    value: 'minutes',
  },
];

export function DefectWarrantyMoreDetail(props: IProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [defectsWarrantyDetail, setDefectsWarrantyDetail] = useState<DefectsWarrantyMoreModel>(props.defectsWarrantyDetail);

  useEffect(() => {
    props.onDefectWarrantyDetailChange(defectsWarrantyDetail);
  }, [defectsWarrantyDetail]);

  useEffect(() => {
    if (props.defectsWarrantyDetail && firstLoad) {
      setDefectsWarrantyDetail(props.defectsWarrantyDetail);
      setFirstLoad(false);
    }
  }, [props.defectsWarrantyDetail, firstLoad]);

  const onTextChange = useCallback((key: string, value: string) => {
    setDefectsWarrantyDetail(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setDefectsWarrantyDetail(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <Card className='mt-3'>
      <Title text='รายละเอียดการรับประกันความชำรุดบกพร่องเพิ่มเติม'
        className='fs-5 text-primary' />
      <Row className='mt-3'>
        <Col sm={8}
          md={3}>
          <InputNumber
            label='เกณฑ์การขัดข้องของสิ่งของตามสัญญา ไม่เกินเดือนละ'
            value={defectsWarrantyDetail?.defectTotalPerMonth}
            onChange={(value) => onNumberChange('defectTotalPerMonth', Number(value))}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={4}
          md={2}>
          <Selector
            className='no-label'
            items={TimesDDL}
            value={defectsWarrantyDetail?.defectTotalPerMonthUnit}
            onChange={(value) => onTextChange('defectTotalPerMonthUnit', value)} />
        </Col>
        <Col sm={8}
          md={3}>
          <Currency
            label='หรือร้อยละ'
            value={defectsWarrantyDetail?.defectPercentPerMonth}
            onChange={(value) => onNumberChange('defectPercentPerMonth', Number(value))}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={4}
          md={2}>
          <Selector
            className='no-label'
            items={props.periodConditionDDL}
            value={defectsWarrantyDetail?.defectPercentPerType}
            onChange={(value) => onTextChange('defectPercentPerType', value)} />
        </Col>
      </Row>
      <Row>
        <Col sm={8}
          md={3}>
          <Currency
            label='กรณีสิ่งของตามสัญญา ขัดข้องเกินเกณฑ์ที่กำหนด จะมีค่าปรับร้อยละ'
            value={defectsWarrantyDetail?.defectFinePercent}
            onChange={(value) => onNumberChange('defectFinePercent', Number(value))}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={4}
          md={2}>
          <Selector
            className='no-label'
            items={props.periodConditionDDL}
            value={defectsWarrantyDetail?.defectFinePercentType}
            onChange={(value) => onTextChange('defectFinePercentType', value)} />
        </Col>
        <Col sm={8}
          md={3}>
          <Currency
            label='คิดเป็นค่าปรับจำนวน (บาท)'
            value={defectsWarrantyDetail?.defectFineTotal}
            onChange={(value) => onNumberChange('defectFineTotal', Number(value))}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={4}
          md={2}>
          <Selector
            label='ต่อ'
            items={TimesDDL}
            value={defectsWarrantyDetail?.defectFineTotalUnit}
            onChange={(value) => onTextChange('defectFineTotalUnit', value)} />
        </Col>
      </Row>
      <Row>
        <label className='mb-3'>คู่ค้าจะชำระค่าปรับภายใน</label>
        <Col sm={8}
          md={1}>
          <InputNumber
            value={defectsWarrantyDetail?.defectFineDay}
            onChange={(value) => onNumberChange('defectFineDay', Number(value))}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={4}
          md={2}>
          <Selector
            items={TimesDDL}
            value={defectsWarrantyDetail?.defectFineDayUnit}
            onChange={(value) => onTextChange('defectFineDayUnit', value)} />
        </Col>
        <Col sm={4}
          md={2}>
          <Selector
            items={props.periodConditionDDL}
            value={defectsWarrantyDetail?.defectFineDayType}
            onChange={(value) => onTextChange('defectFineDayType', value)} />
        </Col>
      </Row>
    </Card>
  );
}
