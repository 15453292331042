import { ApproverJorpor04 } from 'models/PP';
import http from 'utils/axios';

const api = 'approvejorpor04';

const GetListAsync = async (
  page: number,
  size: number,
  criteria?: ApproverJorpor04,
) => {
  const params = {
    page,
    size,
    ...criteria,
  };
  return await http.get(api, { params });
};

const GetDetailAsync = async (id: string) => await http.get(`${api}/${id}`);

const ApproveJorPor04Async = async (id: string, remark: string) => {
  const params = {
    remark,
  };

  return await http.put(`/${api}/approve/${id}`, params);
};

const RejectJorPor04Async = async (id: string, remark: string) => {
  const params = {
    remark,
  };

  return await http.put(`/${api}/reject/${id}`, params);
};

export default {
  GetListAsync,
  GetDetailAsync,
  ApproveJorPor04Async,
  RejectJorPor04Async,
};