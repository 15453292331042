import { Card, Currency, Input, InputTextArea, Selector } from 'components';
import { InputNumber } from 'components/Controls/InputNumber';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import { TorPreventiveMaintenanceModel } from 'models/PP/TorModels';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Collapse,
  Row,
} from 'react-bootstrap';
import { ValidatePattern } from 'utils';
import { Context } from '../../step';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';

interface IPreventiveMaintenanceProps {
  preventiveMaintenance: TorPreventiveMaintenanceModel;
  onPreventiveMaintenanceChange: (value: TorPreventiveMaintenanceModel) => void;
  periodUnitDDL: ItemModel[];
  periodTimeUnitDDL: ItemModel[];
  pmFineTypeDDL: ItemModel[];
  isDisabled?: boolean;
}

export function PreventiveMaintenance(props: IPreventiveMaintenanceProps) {
  const { readonly } = useContext(Context);
  const [preventiveMaintenance, setPreventiveMaintenance] = useState<TorPreventiveMaintenanceModel>({} as TorPreventiveMaintenanceModel);
  const [firstLoad, setFirstLoad] = useState(true);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    props.onPreventiveMaintenanceChange(preventiveMaintenance);
  }, [preventiveMaintenance]);

  useEffect(() => {
    if (props.preventiveMaintenance && firstLoad) {
      setPreventiveMaintenance(props.preventiveMaintenance);
      setFirstLoad(false);
    }
  }, [props.preventiveMaintenance, firstLoad]);

  const onTextChange = useCallback((key: string, value: string) => {
    setPreventiveMaintenance(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setPreventiveMaintenance(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <Card className='mt-3'>
      <div className='d-flex align-items-center'>
        <Title
          text='การบำรุงรักษา (Preventive Maintenance) (IT)'
          className='fs-5 text-primary'
        />
        {
          collapsed
            ? (
              <FaChevronCircleDown
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(false)}
              />
            ) : (
              <FaChevronCircleUp
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(true)}
              />
            )
        }
      </div>
      <Collapse in={!collapsed} className='ps-40'>
        <div>
          <Row className='mt-2'>
            <Col sm={6} md={5}>
              <Input
                label='ชื่อพัสดุ'
                value={preventiveMaintenance?.pmProductName}
                onChange={(value) => onTextChange('pmProductName', value)}
                disabled={props.isDisabled || readonly}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={3}>
              <Currency
                label='มิฉะนั้นจะยอมให้คิดค่าปรับในอัตราร้อยละ'
                value={preventiveMaintenance?.pmFinePct}
                onChange={(value) => onNumberChange('pmFinePct', Number(value))}
                disabled={props.isDisabled || readonly}
              />
            </Col>
            <Col sm={6} md={2}>
              <Selector
                className='primary no-label'
                items={props.pmFineTypeDDL}
                value={preventiveMaintenance?.pmFinePctUnit}
                onChange={(value) => onTextChange('pmFinePctUnit', value)}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly} />
            </Col>
            <Col sm={6} md={3}>
              <Currency
                label='มูลค่ารวมของการปรับแต่ละครั้งต่ำสุด'
                value={preventiveMaintenance?.pmFineAmount}
                onChange={(value) => onNumberChange('pmFineAmount', Number(value))}
                disabled={props.isDisabled || readonly}
              />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col sm={6} md={3}>
              <InputNumber
                label='ตรวจสอบบำรุงรักษาอย่างน้อยต่อครั้ง'
                value={preventiveMaintenance?.pmCount}
                onChange={(value) => onNumberChange('pmCount', Number(value))}
                disabled={props.isDisabled || readonly}
              />
            </Col>
            <Col sm={6} md={2}>
              <Selector
                className='primary no-label'
                items={props.periodUnitDDL}
                value={preventiveMaintenance?.pmUnit}
                onChange={(value) => onTextChange('pmUnit', value)}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly} />
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={3}>
              <InputNumber
                label='ขัดข้องรวมตามเกณฑ์ไม่เกินเดือนละ'
                value={preventiveMaintenance?.disruptedCount}
                onChange={(value) => onNumberChange('disruptedCount', Number(value))}
                disabled={props.isDisabled || readonly}
              />
            </Col>
            <Col sm={6} md={2}>
              <Selector
                className='primary no-label'
                items={props.periodTimeUnitDDL}
                value={preventiveMaintenance?.disruptedCountUnit}
                onChange={(value) => onTextChange('disruptedCountUnit', value)}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly} />
            </Col>
            <Col sm={12} md={3}>
              <Currency
                label='หรือไม่เกินร้อยละ (%) ของเวลาทั้งหมดของเดือนนั้น'
                value={preventiveMaintenance?.disruptedPercent}
                onChange={(value) => onNumberChange('disruptedPercent', Number(value))}
                rule={{ pattern: ValidatePattern.PERCENTAGE }}
                maxValue={100}
                disabled={props.isDisabled || readonly}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3}>
              <Currency
                label='มิฉะนั้นจะถูกปรับอัตราชั่วโมงละ (%)'
                value={preventiveMaintenance?.disruptedFinePercent}
                onChange={(value) => onNumberChange('disruptedFinePercent', Number(value))}
                maxValue={100}
                disabled={props.isDisabled || readonly}
              />
            </Col>
            <Col sm={12} md={3}>
              <Currency
                label='คิดเป็นเงิน (บาท)'
                value={preventiveMaintenance?.disruptedFineAmount}
                onChange={(value) => onNumberChange('disruptedFineAmount', Number(value))}
                disabled={props.isDisabled || readonly}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <InputTextArea
                label='เงื่อนไขการบำรุงรักษา'
                value={preventiveMaintenance?.condition}
                onChange={(value) => onTextChange('condition', value)}
                disabled={props.isDisabled || readonly}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card>
  );
}
