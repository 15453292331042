import {
  Card,
  Check,
  Input,
  Table,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaPlus,
  FaSave,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { generateUniqueId } from '../../../../utils/helper';

interface Props {
  onClickNext?: () => void;
  onClickBack?: () => void;
}

export default function Step2({ onClickBack, onClickNext }: Props) {
  return (
    <>
      <div className='document'>
        <Card className='mt-3'>
          <Title
            text='ผู้มีอำนาจเห็นชอบ'
            className='fs-5 text-primary'
          />
          <Row className='gap-5'>
            <Col sm={12} className='mt-4'>
              <div className='d-flex justify-content-between mb-1'>
                <p className='fs-5 text-primary'>ผู้ช่วยกรรมการ</p>
                <Button
                  variant='outline-primary'>
                  <FaPlus className='me-1' />เพิ่มรายชื่อ
                </Button>

              </div>
              <Table total={1} hidePagination>
                <thead>
                  <tr>
                    <th style={{ minWidth: 20 }}>ลำดับ</th>
                    <th style={{ minWidth: 150, width: 200 }}>ชื่อ-นามสกุล</th>
                    <th style={{ minWidth: 150, width: 200 }}>ฝ่าย/สำนัก</th>
                    <th style={{ minWidth: 150, width: 200 }}>ตำแหน่ง</th>
                    <th style={{ minWidth: 150, width: 180 }}>สถานะ</th>
                    <th style={{ minWidth: 150 }}>วันที่เห็นชอบ</th>
                    <th style={{ minWidth: 50, width: 50 }}>ไม่สามารถ<br />ปฏิบัติงานได้</th>
                    <th style={{ minWidth: 150 }}>หมายเหตุ</th>
                    <th style={{ minHeight: 100 }} />
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <tr className='text-center' key={generateUniqueId(index)}>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        สมศรี แดนไทย
                      </td>
                      <td>
                        {index === 0 ? 'จัดซื้อจัดจ้างพัสดุ' : 'เทคโนโลยี'}
                      </td>
                      <td className='text-start'>
                        รักษาการผู้อำนวยการสำนักงบประมาณ
                      </td>
                      <td>
                        <div className='bg-success text-white rounded'>
                          เห็นชอบ/อนุมัติ
                        </div>
                      </td>
                      <td>
                        {index === 0 ? '10/01/2566' : '11/01/2566'}
                      </td>
                      <td>
                        <Check />
                      </td>
                      <td>
                        <Input />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>

            <Col sm={12}>
              <div className='d-flex justify-content-between mb-1'>
                <p className='fs-5 text-primary'>รองกรรมการ</p>
                <Button
                  variant='outline-primary'>
                  <FaPlus className='me-1' />เพิ่มรายชื่อ
                </Button>

              </div>

              <Table total={1} hidePagination>
                <thead>
                  <tr>
                    <th style={{ minWidth: 20 }}>ลำดับ</th>
                    <th style={{ minWidth: 150, width: 200 }}>ชื่อ-นามสกุล</th>
                    <th style={{ minWidth: 150, width: 200 }}>ฝ่าย/สำนัก</th>
                    <th style={{ minWidth: 150, width: 200 }}>ตำแหน่ง</th>
                    <th style={{ minWidth: 150, width: 180 }}>สถานะ</th>
                    <th style={{ minWidth: 150 }}>วันที่เห็นชอบ</th>
                    <th style={{ minWidth: 50, width: 50 }}>ไม่สามารถ<br />ปฏิบัติงานได้</th>
                    <th style={{ minWidth: 150 }}>หมายเหตุ</th>
                    <th style={{ minHeight: 100 }} />
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <tr className='text-center' key={generateUniqueId(index)}>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        สมศรี แดนไทย
                      </td>
                      <td>
                        {index === 0 ? 'จัดซื้อจัดจ้างพัสดุ' : 'เทคโนโลยี'}
                      </td>
                      <td className='text-start'>
                        รักษาการผู้อำนวยการสำนักงบประมาณ
                      </td>
                      <td>
                        <div className='bg-success text-white rounded'>
                          เห็นชอบ/อนุมัติ
                        </div>
                      </td>
                      <td>
                        {index === 0 ? '10/01/2566' : '11/01/2566'}
                      </td>
                      <td>
                        <Check />
                      </td>
                      <td>
                        <Input />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>

            <Col sm={12}>
              <div className='d-flex justify-content-between mb-1'>
                <p className='fs-5 text-primary'>กรรมการผู้จัดการ</p>

                <Button
                  variant='outline-primary'>
                  <FaPlus className='me-1' />เพิ่มรายชื่อ
                </Button>

              </div>

              <Table total={1} hidePagination>
                <thead>
                  <tr>
                    <th style={{ minWidth: 20 }}>ลำดับ</th>
                    <th style={{ minWidth: 150, width: 200 }}>ชื่อ-นามสกุล</th>
                    <th style={{ minWidth: 150, width: 200 }}>ฝ่าย/สำนัก</th>
                    <th style={{ minWidth: 150, width: 200 }}>ตำแหน่ง</th>
                    <th style={{ minWidth: 150, width: 180 }}>สถานะ</th>
                    <th style={{ minWidth: 150 }}>วันที่เห็นชอบ</th>
                    <th style={{ minWidth: 50, width: 50 }}>ไม่สามารถ<br />ปฏิบัติงานได้</th>
                    <th style={{ minWidth: 150 }}>หมายเหตุ</th>
                    <th style={{ minHeight: 100 }} />
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <tr className='text-center' key={generateUniqueId(index)}>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        สมศรี แดนไทย
                      </td>
                      <td>
                        {index === 0 ? 'จัดซื้อจัดจ้างพัสดุ' : 'เทคโนโลยี'}
                      </td>
                      <td className='text-start'>
                        รักษาการผู้อำนวยการสำนักงบประมาณ
                      </td>
                      <td>
                        <div className='bg-success text-white rounded'>
                          เห็นชอบ/อนุมัติ
                        </div>
                      </td>
                      <td>
                        {index === 0 ? '10/01/2566' : '11/01/2566'}
                      </td>
                      <td>
                        <Check />
                      </td>
                      <td>
                        <Input />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>

        <Card className='mt-3'>
          <Title text='เอกสารแนบ' className='fs-5 text-primary' />
          <Row className='justify-content-center'>
            <Col sm={12} lg={6}>
              <UploadFile />
            </Col>
          </Row>
        </Card>

        <div className='d-flex justify-content-between pt-3'>
          <Button
            onClick={onClickBack}
            variant='outline-primary'
          >
            <MdArrowBack className='me-2 pb-1 fs-5' />
            ย้อนกลับ
          </Button>
          <div>
            <Button
              variant='success'>
              <FaSave className='me-2 pb-1 fs-5' />
              บันทึก
            </Button>
          </div>
          <Button
            onClick={onClickNext}
            variant='outline-primary'>
            ถัดไป
            <MdArrowForward className='ms-2 pb-1 fs-5' />
          </Button>
        </div>
      </div>
    </>
  );
}
