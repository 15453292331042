import {
  Card,
  Input,
  InputTextArea,
  Selector,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step1({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลโครงการ" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={6}>
            <Input label="เลขที่เอกสาร PR" placeholder="เลขที่เอกสาร PR" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Input label="โครงการ" placeholder="โครงการ" disabled />
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={3}>
            <Input label="งบประมาณโครงการ (บาท)" placeholder="1,000,000.00" disabled textEnd />
          </Col>
          <Col sm={12} md={4} xl={3}>
            <Input label="วิธีจัดหา" value="80" disabled />
          </Col>
          <Col sm={12} md={4} className="pt-4">
            <Input value="เจาะจง" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="แบบฟอร์มจัดส่งเอกสารบริหารสัญญา" className="fs-5 text-primary" />
        <Row>
          <Col sm={12} md={6}>
            <Selector label="เรื่อง" />
          </Col>
          <Col sm={12}>
            <Input label="เรียน" placeholder="เรียน" />
          </Col>
          <Col sm={12}>
            <InputTextArea label="เหตุผลและความสำคัญ" placeholder="เหตุผลและความสำคัญ" />
          </Col>
        </Row>
      </Card>
      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          className="me-2 px-3"
          variant="light"
          onClick={onClickBack}
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default Step1;
