import { UserListSelectionResponse } from 'models';
import { useState } from 'react';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import {
  Modal,
  Table,
} from '../../../components';
import ModalListLicensor from './ModalListLicensor';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  onClickConfirm: (data: UserListSelectionResponse[]) => void;
}

export default function ModalLicensor({ show, setShow, onClickConfirm }: Props) {
  const [showListLicensor, setShowListLicensor] = useState(false);
  // TODO: get licensor from api
  const [licensorData, setLicensorData] = useState<UserListSelectionResponse[]>([]);

  function addLicensor(data: UserListSelectionResponse) {
    setLicensorData((pre) => [...pre, data]);
  }

  function removeLicensor(id: string) {
    setLicensorData((pre) => pre.filter((licensor) => licensor.id !== id));
  }

  const onAbortSubmit = () => {
    setShow(!show);
    setLicensorData([]);
  };

  return (
    <>
      <Modal
        show={show}
        size='xl'
        onHide={() => setShow(!show)}
        children={(
          <>
            <ModalBT.Header closeButton
              className='p-0 ps-4'>
              <div className='d-flex align-items-center gap-2'>
                <div className='parallelogram' />
                <h4 className='text-primary m-0'>ส่งอนุมัติ</h4>
              </div>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4 mt-4'>
              <Table hidePagination>
                <thead>
                <tr>
                  <th style={{ minWidth: 100 }}>ลำดับ</th>
                  <th style={{ minWidth: 200 }}>ชื่อ-นามสกุล</th>
                  <th style={{ minWidth: 200 }}>ตำแหน่ง</th>
                  <th />
                </tr>
                </thead>
                <tbody>
                {
                  licensorData.map((item, index) => (
                    <tr key={item.id}>
                      <td className='text-center'>{index + 1}</td>
                      <td>{item.fullName}</td>
                      <td>{item.positionName}</td>
                      <td className='text-center'>
                        <Button
                          variant='outline-primary'
                          className='d-flex align-items-center gap-2'
                          onClick={() => removeLicensor(item.id)}
                        >
                          <FaTrashAlt />ลบ
                        </Button>
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
              <div className='mt-2'>
                <Button
                  variant='outline-primary'
                  onClick={() => {
                    setShowListLicensor(!showListLicensor);
                    setShow(false);
                  }}
                >
                  เพิ่มผู้อนุมัติ
                </Button>
              </div>
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={onAbortSubmit}>
                ยกเลิก
              </Button>
              <Button variant='primary'
                onClick={() => onClickConfirm(licensorData)}>
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <ModalListLicensor toggleListModal={show}
        setShowListModal={setShow}
        show={showListLicensor}
        setShow={setShowListLicensor}
        onClickAdd={addLicensor}
        approverSelected={licensorData} />
    </>
  );
}
