import Cookie from 'cookie-universal';
import {
  CreatePP0201Model,
  SearchPP0201Model,
  UpdateAppointmentDetailModel,
} from 'models/P/PP0201Model';
import http from 'utils/axios';

const qs = require('qs');

const cookies = Cookie();

const getFullName = cookies.get('fullName') as string;

const getListPP0201Async = (searchData: SearchPP0201Model) => {
  const params = {
    page: searchData.page,
    size: searchData.size,
    appointMemorandumDate: searchData.appointMemorandumDate,
    departmentId: searchData.departmentId,
    projectName: searchData.projectName,
    subject: searchData.subject,
    commiteeTorName: searchData.committeeTorName,
    responsiblePerson: searchData.responsiblePerson,
    status: ['Pending'],
  };
  return http.get('/appointment/pagination', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getPP0201ByIdAsync = (id: string) => http.get(`/appointment/${id}`);

const createAppointmentAsync = (body: CreatePP0201Model) => {
  const formData = new FormData();

  formData.append('planId', body.planId);
  formData.append('departmentId', body.departmentId);
  formData.append('budgetYear', body.budgetYear.toString());
  formData.append('planName', body.planName);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodType', body.supplyMethodType);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString().split('T')[0]);
  formData.append('memorandumDate', new Date(body.memorandumDate).toISOString());
  formData.append('financialAmount', body.financialAmount.toString());
  formData.append('byOrderFrom', body.byOrderFrom);
  formData.append('subject', body.subject);
  formData.append('createdByUserFullName', getFullName);

  if (body.files && body.files.length !== 0) {
    body.files.forEach((file) => {
      formData.append('files', file);
    });
  }

  if (body.createCommitteeTors && body.createCommitteeTors.length !== 0) {
    body.createCommitteeTors.forEach((data, index) => {
      formData.append(`createCommitteeTors[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`createCommitteeTors[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`createCommitteeTors[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`createCommitteeTors[${index}][positionOnBoardName]`, data.positionOnBoardName);
      formData.append(`createCommitteeTors[${index}][position]`, data.position);
    });
  }

  if (body.createDutyCommitteeTors && body.createDutyCommitteeTors.length !== 0) {
    body.createDutyCommitteeTors.forEach((data, index) => {
      formData.append(`createDutyCommitteeTors[${index}][dutyDescription]`, data.dutyDescription);
    });
  }

  if (body.createCommitteeMiddlePrices && body.createCommitteeMiddlePrices.length !== 0) {
    body.createCommitteeMiddlePrices.forEach((data, index) => {
      formData.append(`createCommitteeMiddlePrices[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`createCommitteeMiddlePrices[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`createCommitteeMiddlePrices[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`createCommitteeMiddlePrices[${index}][positionOffBoardName]`, data.positionOffBoardName);
    });
  }

  if (body.createDutyCommitteeMiddlePrices && body.createDutyCommitteeMiddlePrices.length !== 0) {
    body.createDutyCommitteeMiddlePrices.forEach((data, index) => {
      formData.append(`createDutyCommitteeMiddlePrices[${index}][dutyDescription]`, data.dutyDescription);
    });
  }

  if (body.createApproverAppointPreProcurements && body.createApproverAppointPreProcurements.length !== 0) {
    body.createApproverAppointPreProcurements.forEach((data, index) => {
      formData.append(`createApproverAppointPreProcurements[${index}][approverUserId]`, data.approverUserId);
      formData.append(`createApproverAppointPreProcurements[${index}][approverUserName]`, data.approverUserName);
      formData.append(`createApproverAppointPreProcurements[${index}][position]`, data.position);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post('/appointment', formData, config);
};

const updateAppointmentAsync = (body: UpdateAppointmentDetailModel) => {
  const formData = new FormData();

  formData.append('id', body.id);
  if (body.planId && body.planId.length > 0) {
    formData.append('planId', body.planId);
  }
  formData.append('departmentId', body.departmentId);
  formData.append('budgetYear', body.budgetYear.toString());
  formData.append('planName', body.planName);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodType', body.supplyMethodType);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialType);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString().split('T')[0]);
  formData.append('financialAmount', body.financialAmount.toString());
  formData.append('byOrderFrom', body.byOrderFrom);
  formData.append('subject', body.subject);
  formData.append('status', body.status);
  formData.append('updateByUserFullName', getFullName);

  if (body.files && body.files.length !== 0) {
    body.files.forEach((file) => {
      formData.append('files', file);
    });
  }

  if (body.updateCommitteeTors && body.updateCommitteeTors.length > 0) {
    body.updateCommitteeTors.forEach((data, index) => {
      if (data.id) {
        formData.append(`updateCommitteeTors[${index}][id]`, data.id);
      }
      formData.append(`updateCommitteeTors[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`updateCommitteeTors[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`updateCommitteeTors[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`updateCommitteeTors[${index}][positionOnBoardName]`, data.positionOnBoardName);
      formData.append(`updateCommitteeTors[${index}][position]`, data.position);
    });
  }

  if (body.updateDutyCommitteeTors && body.updateDutyCommitteeTors.length !== 0) {
    body.updateDutyCommitteeTors.forEach((data, index) => {
      if (data.id) {
        formData.append(`updateDutyCommitteeTors[${index}][id]`, data.id);
      }
      formData.append(`updateDutyCommitteeTors[${index}][dutyDescription]`, data.dutyDescription);
    });
  }

  if (body.updateCommitteeMiddlePrices && body.updateCommitteeMiddlePrices.length !== 0) {
    body.updateCommitteeMiddlePrices.forEach((data, index) => {
      if (data.id) {
        formData.append(`updateCommitteeMiddlePrices[${index}][id]`, data.id);
      }
      formData.append(`updateCommitteeMiddlePrices[${index}][committeeUserId]`, data.committeeUserId);
      formData.append(`updateCommitteeMiddlePrices[${index}][committeeUserName]`, data.committeeUserName);
      formData.append(`updateCommitteeMiddlePrices[${index}][positionOnBoardId]`, data.positionOnBoardId);
      formData.append(`updateCommitteeMiddlePrices[${index}][positionOffBoardName]`, data.positionOffBoardName);
    });
  }

  if (body.updateDutyCommitteeMiddlePrices && body.updateDutyCommitteeMiddlePrices.length !== 0) {
    body.updateDutyCommitteeMiddlePrices.forEach((data, index) => {
      if (data.id) {
        formData.append(`updateDutyCommitteeMiddlePrices[${index}][id]`, data.id);
      }
      formData.append(`updateDutyCommitteeMiddlePrices[${index}][dutyDescription]`, data.dutyDescription);
    });
  }

  if (body.updateApproverAppointPreProcurements && body.updateApproverAppointPreProcurements.length !== 0) {
    body.updateApproverAppointPreProcurements.forEach((data, index) => {
      if (data.id) {
        formData.append(`updateApproverAppointPreProcurements[${index}][id]`, data.id);
      }
      formData.append(`updateApproverAppointPreProcurements[${index}][approverUserId]`, data.approverUserId);
      formData.append(`updateApproverAppointPreProcurements[${index}][approverUserName]`, data.approverUserName);
      formData.append(`updateApproverAppointPreProcurements[${index}][position]`, data.position);
    });
  }
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put('/appointment', formData, config);
};

const submitPendingAppointmentAsync = (id: string) => http.put(`/appointment/pending/${id}`);

const getAppointmentDocumentAsync = (id: string, documentId: string) => http.get(`/appointment/${id}/documents/${documentId}`, {
  responseType: 'blob',
});

const deleteAppointmentDocumentAsync = (id: string, docNames: string[]) => http.delete(`/appointment/${id}/documents`, { data: { docNames } });

const GetPlanSelectionListAsync = async () => await http.get('/annualPlan/planselection');

const deleteAppointmentAsync = (id: string) => http.delete(`/appointment/${id}`);

export default {
  getListPP0201Async,
  getPP0201ByIdAsync,
  createAppointmentAsync,
  updateAppointmentAsync,
  submitPendingAppointmentAsync,
  getAppointmentDocumentAsync,
  deleteAppointmentDocumentAsync,
  GetPlanSelectionListAsync,
  deleteAppointmentAsync,
};
