import { HttpStatusCode } from 'axios';
import {
  Card,
  Input,
  Modal,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  UserListSelectionParams,
  UserListSelectionResponse,
  UserResponse,
} from 'models';
import {
  AnnualPlanDocumentModel,
  CreatePL0201Model,
  PL0201ResponseDraft,
} from 'models/PL';
import {
  IFile,
  ItemModel,
  Pagination,
} from 'models/systemUtility';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaEraser,
  FaPaperPlane,
  FaPlus,
  FaSave,
  FaSearch,
  FaTimes,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router';
import { shareValue } from 'services';
import { PL0201Service } from 'services/PL';
import { fullDate } from 'utils';
import {
  PlanStatusText,
  PlanTypeText,
} from 'utils/constants/PlanEnum';
import { toStringNull } from 'utils/convertors/checkStringNull';
import toast from 'utils/toast';
import { Context } from './Detail';

interface Props {
  back: () => void;
}

type Loader = {
  departmentList: ItemModel[],
  supplyMethodTypeList: ItemModel[],
  supplyMethodTypeNameList: ItemModel[],
  positionList: ItemModel[],
};

export default function Step2({ back }: Props) {
  const { id } = useParams();
  const { data, setData, files, setFiles } = useContext(Context);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const { departmentList, positionList } = useLoaderData() as Loader;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [onDisable, setOnDisable] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    size: 10,
    totalRecords: 0,
  } as Pagination);

  useEffect(() => {
    if (data.planStatus) {
      if (data.planStatus === PlanTypeText.AnnualPlan) {
        if (data.status !== PlanStatusText.DraftPlan) {
          setOnDisable(true);
        } else {
          setOnDisable(false);
        }
      } else {
        setOnDisable(false);
      }
    }
    if (data.approvers?.length > 0) {
      setUsers(data?.approvers);
      setPagination({ ...pagination, totalRecords: data.approvers.length });
    }
  }, []);

  const onSelect = (user: UserListSelectionResponse) => {
    const userFromModal: UserResponse = {
      id: user.id,
      name: user.fullName,
      tel: '',
      email: '',
      position: user.positionName,
      department: user.departmentName,
      role: '',
      isActive: false,
      updatedBy: '',
      updatedAt: '',
      approveStatus: '',
      remark: '',
      workGroupName: '',
    };

    users.push(userFromModal);
    setPagination({ ...pagination, totalRecords: users.length });
  };

  const remove = (index: number) => {
    const temp: UserResponse[] = [...users];
    temp.splice(index, 1);
    setUsers(temp);
    setPagination({ ...pagination, totalRecords: temp.length });
  };

  const handleSetFile = (documents: AnnualPlanDocumentModel[]) => {
    documents.forEach((document, index) => {
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    });
  };

  const onSubmitAsync = async (Type: PlanStatusText, nonRedirect = false) => {
    const docUpload: File[] = [];

    files.forEach((i) => {
      if (i.file !== null) {
        docUpload.push(i.file);
      }
    });

    const userIds = [...users.map((u) => u.id)];
    const saveDataModel: CreatePL0201Model = {
      departmentId: data.departmentId,
      supplyMethod: data.supplyMethod,
      supplyMethodName: data.supplyMethodName,
      planName: data.planName,
      planBudget: data.planBudget.toString(),
      expectingProcurementAt: data.expectingProcurementAt.toString(),
      planAnnouncementDate: toStringNull(data.planAnnouncementDate),
      planEGPNumber: toStringNull(data.planEgpNumber),
      userIds,
      documents: docUpload,
      type: Type,
    };

    if (typeof id === 'undefined') {
      const planRes = await PL0201Service.createPL0201Async(saveDataModel);

      if (planRes.status !== HttpStatusCode.Created) {
        toast.error('เกิดข้อผิดพลาด');
        return;
      }

      if (Type === PlanStatusText.DraftPlan) {
        setFiles([]);
        const resp: PL0201ResponseDraft = planRes.data;
        const { data, status } = await PL0201Service.getPL0201ByIdAsync(resp.id);
        setData(data);
        if (data.documentInit) { handleSetFile(data.documentInit); }
        navigate(`/pl/pl0201/detail/${data.id}`);
      } else {
        setShow(false);
        navigate('/pl/pl0201');
      }
      toast.success('บันทึกข้อมูลสำเร็จ');
    } else {
      if (data.docNameforDelete === undefined) data.docNameforDelete = [];
      if (data.docNameforDelete.length > 0) {
        const [docRes, planRes] = await Promise.all([PL0201Service.deleteAnnualPlanDocumentListAsync(id, data.docNameforDelete), PL0201Service.updatePL0201Async(id, saveDataModel)]);
        if (planRes.status !== HttpStatusCode.Created) {
          toast.error('เกิดข้อผิดพลาด');
          return;
        }
        if (nonRedirect) {
          setFiles([]);
          const resp: PL0201ResponseDraft = planRes.data;
          const { data, status } = await PL0201Service.getPL0201ByIdAsync(resp.id);
          setData(data);
          if (data.documentInit) { handleSetFile(data.documentInit); }
          navigate(`/pl/pl0201/detail/${data.id}`);
        } else if (Type === PlanStatusText.DraftPlan) {
          setFiles([]);
          const resp: PL0201ResponseDraft = planRes.data;
          const { data, status } = await PL0201Service.getPL0201ByIdAsync(resp.id);
          setData(data);
          if (data.documentInit) { handleSetFile(data.documentInit); }
          navigate(`/pl/pl0201/detail/${data.id}`);
        } else {
          setShow(false);
          navigate('/pl/pl0201');
        }
        toast.success('แก้ไขข้อมูลสำเร็จ');
      } else {
        const res = await PL0201Service.updatePL0201Async(id, saveDataModel);
        if (res.status !== HttpStatusCode.Created) {
          toast.error('เกิดข้อผิดพลาด');
          return;
        }

        if (nonRedirect) {
          setFiles([]);
          const resp: PL0201ResponseDraft = res.data;
          const { data, status } = await PL0201Service.getPL0201ByIdAsync(resp.id);
          setData(data);
          if (data.documentInit) { handleSetFile(data.documentInit); }
          navigate(`/pl/pl0201/detail/${data.id}`);
        } else if (Type === PlanStatusText.DraftPlan) {
          setFiles([]);
          const resp: PL0201ResponseDraft = res.data;
          const { data, status } = await PL0201Service.getPL0201ByIdAsync(resp.id);
          setData(data);
          if (data.documentInit) { handleSetFile(data.documentInit); }
          navigate(`/pl/pl0201/detail/${data.id}`);
        } else {
          setShow(false);
          navigate('/pl/pl0201');
        }
        toast.success('แก้ไขข้อมูลสำเร็จ');
      }
    }
  };

  const onValidateSendAprrove = () => {
    if (users.length <= 0) {
      toast.warn('ต้องมีผู้อนุมัติอย่างน้อย 1 คน');
    } else {
      setShow(true);
    }
  };

  return (
    <>
      <Card className="mt-3">
        <div className="d-flex align-items-center gap-2">
          <div className="parallelogram" />
          <h4 className="text-primary m-0">อำนาจอนุมัติ</h4>
        </div>
        <Table
          className="mt-3"
          total={pagination.totalRecords}
          onChange={(size, page) => (setPagination({ ...pagination, size }), setPagination({ ...pagination, page }))}
        >
          <thead>
            <tr>
              <th style={{ minWidth: 75 }}>ลำดับ</th>
              <th style={{ minWidth: 75 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 75 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 200 }}>ตำแหน่ง</th>
              {onDisable && <th style={{ minWidth: 75 }}>สถานะ</th>}
              {onDisable && <th style={{ minWidth: 75 }}>วันที่อนุมัติ</th>}
              {!onDisable ? <th /> : <></>}
            </tr>
          </thead>
          <tbody>
            {users.map((u, i) => (
              <tr key={u.id}>
                <td className="text-center">{i + 1}</td>
                <td className="text-start">{u.name}</td>
                <td className="text-start">{u.department}</td>
                <td className="text-center">{u.position}</td>
                {onDisable && <td className="text-center"><Status type={StatusType.PROCESS} value={u.approveStatus ?? ''} /></td>}
                {onDisable && <td className="text-center">{u.updatedAt ? fullDate(u.updatedAt) : ''}</td>}
                {!onDisable ? (
                  <td>
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="danger"
                        className="d-flex align-items-center gap-2"
                        onClick={() => remove(i)}
                      >
                        <FaTrashAlt />
                      </Button>
                    </div>
                  </td>
                ) : <></>}
              </tr>
            ))}
          </tbody>
        </Table>
        {!onDisable
          ? (
            <div className="mt-3">
              <Button
                variant="outline-primary"
                className="d-flex align-items-center gap-2"
                onClick={() => setShowModal(true)}
              >
                <FaPlus />เพิ่มข้อมูล
              </Button>
            </div>
          ) : <></>}
        {!onDisable
          ? (
            <div className="d-flex justify-content-center gap-3 mt-3">
              <Button
                variant="outline-primary"
                className="d-flex align-items-center gap-2"
                onClick={back}
              >
                <FaAngleLeft />ย้อนกลับ
              </Button>
              <Button
                variant="outline-primary"
                className="d-flex align-items-center gap-2"
                onClick={() => onSubmitAsync(PlanStatusText.DraftPlan)}
              >
                <FaSave />บันทึก
              </Button>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                onClick={onValidateSendAprrove}
              >
                <FaPaperPlane />ส่งอนุมัติ
              </Button>
            </div>
          )
          : (
            <div className="d-flex justify-content-center gap-3 mt-4">
              <Button
                variant="outline-primary"
                className="d-flex align-items-center gap-2"
                onClick={back}
              >
                <FaAngleLeft />ย้อนกลับ
              </Button>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                onClick={() => onSubmitAsync(data.status, true)}
              >
                <FaSave />บันทึก
              </Button>
            </div>
          )}
      </Card>
      <ApproverModal
        show={showModal}
        onSelect={onSelect}
        onHide={() => setShowModal(false)}
        userSelected={users}
        departmentDDL={departmentList}
        positionList={positionList}
      />
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งอนุมัติ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">คุณต้องการส่งแผนเพื่อทำการอนุมัติใช่หรือไม่ ?</ModalBT.Body>
            <ModalBT.Footer>
              <Button variant="outline-primary" onClick={() => setShow(!show)}>
                ยกเลิก
              </Button>
              <Button variant="primary" onClick={() => onSubmitAsync(PlanStatusText.WaitingApprovePlan)}>
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

function ApproverModal(props: {
  show: boolean,
  onHide: () => void,
  onSelect: (value: UserListSelectionResponse) => void,
  userSelected: UserResponse[],
  departmentDDL: ItemModel[],
  positionList: ItemModel[],
}) {
  const [show, setShow] = useState<boolean>(false);
  const [criteriaModal, setCriteriaModal] = useState<UserListSelectionParams>({} as UserListSelectionParams);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    size: 10,
    totalRecords: 0,
  } as Pagination);

  useEffect(() => {
    const getDataAsync = async () => {
      if (props.show) {
        await getUsersAsync();
        setShow(true);
      }
    };
    getDataAsync();
  }, [props.show]);

  useEffect(() => {
    getUsersAsync();
  }, [pagination.size, pagination.page]);

  const getUsersAsync = async () => {
    const res = await shareValue.getUserListSelectionAsync(
      pagination.page,
      pagination.size,
      criteriaModal,
    );
    if (res.status === HttpStatusCode.Ok) {
      const usersData: UserListSelectionResponse[] = res.data.data;
      if (props.userSelected.length > 0) {
        props.userSelected.forEach((value) => {
          usersData.forEach((v, i) => {
            if (v.id === value.id) {
              usersData.splice(i, 1);
            }
          });
        });
      }
      setUsers(usersData);
      setPagination({ ...pagination, totalRecords: usersData.length });
    }
  };

  const SearchData = () => {
    getUsersAsync();
  };

  const onSelect = (user: UserListSelectionResponse) => {
    props.onSelect(user);
    onHideModal();
  };

  const onHideModal = () => {
    setCriteriaModal({} as UserListSelectionParams);
    setUsers([]);
    setShow(false);
    props.onHide();
  };

  const onClear = useCallback(async () => {
    setCriteriaModal({} as UserListSelectionParams);
    await getUsersAsync();
  }, [props.show]);

  return (
    <Modal show={show} size="xl">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h5 className="mb-1">ค้นหารายชื่อ</h5>
        <FaTimes onClick={() => onHideModal()} />
      </div>
      <Card className="mb-3">
        <p>ค้นหา</p>
        <Row>
          <Col md={12} lg={4}>
            <Input
              label="ชื่อ-นามสกุล"
              value={criteriaModal.fullName}
              onChange={(val) => setCriteriaModal({ ...criteriaModal, fullName: val })}
            />
          </Col>
          <Col md={12} lg={4}>
            <Selector
              label="ฝ่าย/สำนัก"
              items={props.departmentDDL}
              value={criteriaModal.departmentId}
              onChange={(val) => setCriteriaModal({ ...criteriaModal, departmentId: val })}
            />
          </Col>
          <Col md={12} lg={4}>
            <Selector
              label="ตำแหน่ง"
              items={props.positionList}
              value={criteriaModal.positionId}
              onChange={(val) => setCriteriaModal({ ...criteriaModal, positionId: val })}
            />
          </Col>
        </Row>
        <div className="d-flex gap-2">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2"
            onClick={SearchData}
          >
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant="outline-primary"
            className="d-flex align-items-center gap-2"
            onClick={onClear}
          >
            <FaEraser />ล้าง
          </Button>
        </div>
      </Card>
      <Table total={pagination.totalRecords} onChange={(size, page) => (setPagination({ ...pagination, size }), setPagination({ ...pagination, page }))}>
        <thead>
          <tr>
            <th style={{ minWidth: 85 }}>ลำดับ</th>
            <th style={{ minWidth: 200 }}>ชื่อ-นามสกุล</th>
            <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
            <th style={{ minWidth: 80 }} />
          </tr>
        </thead>
        <tbody>
          {users.map((u, i) => (
            <tr key={u.id}>
              <td className="text-center">{i + 1}</td>
              <td>{u.fullName}</td>
              <td className="text-center">{u.departmentName}</td>
              <td className="text-center">{u.positionName}</td>
              <td className="d-flex justify-content-center">
                <Button
                  onClick={() => onSelect(u)}
                  variant="primary"
                  className="d-flex align-items-center gap-2"
                >
                  เลือก
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  );
}
