import { useCallback, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import loaderAnimation from '../data/loading.json';

enum LoadingState {
  FADING_IN,
  FADE_IN_FINISH,
  FADING_OUT,
  FADE_OUT_FINISH,
}

enum Style {
  HIDE = 'd-none',
  FADE_IN = 'fade-in',
  FADE_OUT = 'fade-out',
}

enum EventListener {
  SHOW_LOADING = 'onShowLoading',
  HIDE_LOADING = 'onHideLoading',
}

export function Loading() {
  const loadingStack = [] as number[];
  let loadingState: LoadingState | null = null;
  const [style, setStyle] = useState<Style>(Style.HIDE);
  const delay = 500;
  let interval: NodeJS.Timer | null = null;

  useEffect(() => {
    addEventListener();
  }, []);

  const clearLoading = useCallback(() => {
    if (interval) {
      return;
    }

    interval = setInterval(() => {
      if (loadingState === LoadingState.FADE_IN_FINISH && !loadingStack.length) {
        loadingState = LoadingState.FADING_OUT;

        setStyle(Style.FADE_OUT);

        const timeout = setTimeout(() => {
          loadingState = null;

          setStyle(Style.HIDE);

          clearTimeout(timeout);
        }, delay);

        clearInterval(interval!);

        interval = null;
      }
    }, delay);
  }, [loadingState]);

  const addEventListener = useCallback(() => {
    document.addEventListener(EventListener.SHOW_LOADING, () => {
      loadingStack.push(0);

      loadingState = LoadingState.FADING_IN;

      setStyle(Style.FADE_IN);

      const timeout = setTimeout(() => {
        loadingState = LoadingState.FADE_IN_FINISH;

        clearTimeout(timeout);
      }, delay);
    });

    document.addEventListener(EventListener.HIDE_LOADING, () => {
      loadingStack.pop();

      clearLoading();
    });
  }, []);

  return (
    <div className={`loading ${style}`}>
      <div className='spinner'>
        <Lottie className='animation'
          animationData={loaderAnimation}
          loop />
      </div>
      <div className='back-drop' />
    </div>
  );
}