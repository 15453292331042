import {
  Card,
  Input,
  Status,
  StatusType,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import {
  CriteriaSupplyMethodId,
  PreProcumentFlag,
  PreProcumentStatus,
} from 'constant/PreProcumentFlag';
import { TorStatusConstant } from 'constant/PreProcumentTorStatus';
import {
  CriteriaPlanProcurementList,
  JorPor05Criteria,
  JorPor05ListData,
  Pagination,
  PlanProcurementList,
  RecordContract,
} from 'models';
import { PlanProcurementAnnouncementResponse } from 'models/planAnnouncement';
import {
  BasicInfoResponse,
  BasicInfoSearchParams,
} from 'models/PP';
import {
  AppointmentModel,
  SearchPP0201Model,
} from 'models/PP/PreProcurementCreateDirectiveModels';
import { TorModel } from 'models/PP/TorModels';
import { InvitationModel } from 'models/PR/InvitationModels';
import {
  JorPor06Criteria,
  JorPor06ListsResponse,
} from 'models/PR/JorPor06Models';
import { AppointCommiteeListResponse } from 'models/procurementAppointmentOrderModel';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  jorPor05,
  jorPor06,
  planAnnouncement,
  planProcurement,
  PP0205Service,
  recordContract,
} from 'services';
import PreProcurementServices from 'services/PP/PreProcurementServices';
import PreProcurementTorServices from 'services/PP/PreProcurementTorServices';
import InvitationServices from 'services/PR/InvitationServices';
import procurementAppointmentOrderServices from 'services/procurementAppointmentOrderServices';
import {
  formatMonthAndThaiYearWithSlash,
  fullDate,
  GetFormatDateBC,
  GetMonthYearBC,
  HttpStatusCode,
  thaiFormatDate,
  THCurrency,
  useAppContext,
} from 'utils';
import { PlanTypeText } from 'utils/constants/PlanEnum';

export default function DashboardDetail() {
  const navigate = useNavigate();

  const goToPage = () => {
    navigate('/#');
  };

  return (
    <>
      <Header />
      <UserInfo />
      <Table1 />
      <Table2 />
      <Table3 />
      <Table4 />
      <Table5 />
      <Table6 />
      <Table7 />
      <Table8 />
      <Table9 />
      <Table10 />
      <Table11 />
      <Table12 />
      <Table13 />
      <Table14 />
      <Table15 />
      <Table16 />
      <Table17 />
    </>
  );
}

function Header() {
  const dateNow = new Date();

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>งานที่รับผิดชอบ</h4>
        <p className='m-0'>ข้อมูล ณ วันที่ {thaiFormatDate(dateNow)}</p>
      </div>
      <hr />
    </>
  );
}

function UserInfo() {
  const { email, firstName, lastName, departmentName, positionName } = useAppContext();

  return (
    <Card>
      <Title
        text='ข้อมูลผู้รับผิดชอบ'
        className='fs-5 text-primary' />
      <Row className='mt-3'>
        <Col sm={12}
          lg={6}
          xl={5}
          xxl={4}>
          <Input label='อีเมล'
            value={email}
            disabled />
        </Col>
        <Col sm={12}
          lg={6}
          xl={5}
          xxl={4}>
          <Input label='ชื่อ-นามกสุล'
            value={`${firstName} ${lastName}`}
            disabled />
        </Col>
      </Row>
      <Row>
        <Col sm={12}
          lg={6}
          xl={5}
          xxl={4}>
          <Input label='ฝ่าย/สำนัก'
            value={departmentName}
            disabled />
        </Col>
        <Col sm={12}
          lg={6}
          xl={5}
          xxl={4}>
          <Input label='ตำแหน่ง'
            value={positionName}
            disabled />
        </Col>
      </Row>
    </Card>
  );
}

// รายการจัดซื้อจัดจ้าง (รวมปี)
function Table1() {
  const navigate = useNavigate();
  const [data, setData] = useState<PlanProcurementList[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const res = await planProcurement.getPlanProcurementListAsync(
      page,
      size,
      PlanTypeText.AnnualPlan,
      { isAssignToMeOnly: true } as CriteriaPlanProcurementList);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='รายการจัดซื้อจัดจ้าง (รวมปี)'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>เลขที่รายการจัดซื้อจัดจ้าง</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/procurement-plan-summary/detail/${data.id}`)}>
                        {data.planNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.PROCESS}
                    value={data.status} /></td>
                  <td>{data.department}</td>
                  <td>{data.name}</td>
                  <td className='text-end'>{THCurrency(data.budget)}</td>
                  <td className='text-center'>{data.supplyMethod}</td>
                  <td className='text-center'>{GetMonthYearBC(data.expectingProcurementAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// รายการจัดซื้อจัดจ้าง (ระหว่างปี)
function Table2() {
  const navigate = useNavigate();
  const [data, setData] = useState<PlanProcurementList[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const res = await planProcurement.getPlanProcurementListAsync(
      page,
      size,
      PlanTypeText.InYearPlan,
      { isAssignToMeOnly: true } as CriteriaPlanProcurementList);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='รายการจัดซื้อจัดจ้าง (ระหว่างปี)'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>เลขที่รายการจัดซื้อจัดจ้าง</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/procurement-plan-inyear/detail/${data.id}`)}>
                        {data.planNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.PROCESS}
                    value={data.status} /></td>
                  <td>{data.department}</td>
                  <td>{data.name}</td>
                  <td className='text-end'>{THCurrency(data.budget)}</td>
                  <td className='text-center'>{data.supplyMethod}</td>
                  <td className='text-center'>{GetMonthYearBC(data.expectingProcurementAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// เปลี่ยนแปลงรายการจัดซื้อจัดจ้าง
function Table3() {
  const navigate = useNavigate();
  const [data, setData] = useState<PlanProcurementList[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const res = await planProcurement.getPlanProcurementListAsync(
      page,
      size,
      PlanTypeText.ChangePlan,
      { isAssignToMeOnly: true } as CriteriaPlanProcurementList);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='เปลี่ยนแปลงรายการจัดซื้อจัดจ้าง'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>เลขที่รายการ</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>ปีงบประมาณ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/procurement-plan-adjust/detail/${data.id}`)}>
                        {data.planNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.PROCESS}
                    value={data.status} /></td>
                  <td>{data.department}</td>
                  <td>{data.planNumber}</td>
                  <td className='text-center'>{data.budgetYear}</td>
                  <td className='text-end'>{THCurrency(data.budget)}</td>
                  <td className='text-center'>{data.supplyMethod}</td>
                  <td className='text-center'>{GetMonthYearBC(data.expectingProcurementAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// ยกเลิกรายการจัดซื้อจัดจ้าง
function Table4() {
  const navigate = useNavigate();
  const [data, setData] = useState<PlanProcurementList[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const res = await planProcurement.getPlanProcurementListAsync(
      page,
      size,
      PlanTypeText.CancelPlan,
      { isAssignToMeOnly: true } as CriteriaPlanProcurementList);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='ยกเลิกรายการจัดซื้อจัดจ้าง'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>เลขที่รายการ</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>ปีงบประมาณ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/procurement-plan-cancel/detail/${data.id}`)}>
                        {data.planNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.PROCESS}
                    value={data.status} /></td>
                  <td>{data.department}</td>
                  <td>{data.planNumber}</td>
                  <td className='text-center'>{data.budgetYear}</td>
                  <td className='text-end'>{THCurrency(data.budget)}</td>
                  <td className='text-center'>{data.supplyMethod}</td>
                  <td className='text-center'>{GetMonthYearBC(data.expectingProcurementAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// ขออนุมัติเผยแพร่แผนจัดซื้อจัดจ้าง
function Table5() {
  const navigate = useNavigate();
  const [data, setData] = useState<PlanProcurementAnnouncementResponse[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const res = await planAnnouncement.getPlanProcurementAnnouncementListAsync(
      page,
      size);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='ขออนุมัติเผยแพร่แผนจัดซื้อจัดจ้าง'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>เลขที่คำขอเผยแพร่<br />แผนจัดซื้อจัดจ้าง</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ปี</th>
                <th style={{ minWidth: 150 }}>จำนวนโครงการ</th>
                <th style={{ minWidth: 150 }}>งบประมาณรวม</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>วันที่เผยแพร่</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/procurement-plan-public/detail/${data.id}`)}>
                        {data.planNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.ANNOUNCEPLANPROCESS}
                    value={data.status} /></td>
                  <td className='text-center'>{data.budgetYear}</td>
                  <td className='text-end'>{data.projectsCount}</td>
                  <td className='text-end'>{THCurrency(data.budget)}</td>
                  <td className='text-center'>{data.supplyMethod}</td>
                  <td className='text-center'>{GetFormatDateBC(data.announcementDate)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// แบบขอแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง
function Table6() {
  const navigate = useNavigate();
  const [data, setData] = useState<AppointmentModel[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const supplyMethodIds = [
      CriteriaSupplyMethodId.MethodId60,
      CriteriaSupplyMethodId.MethodId80,
    ];

    const status = [
      PreProcumentStatus.Draft,
      PreProcumentStatus.Pending,
      PreProcumentStatus.Approved,
      PreProcumentStatus.Rejected,
      PreProcumentStatus.Cancelled,
    ];

    const res = await PreProcurementServices.getListAppointmentAsync(
      {
        size,
        page,
        supplyMethodIds,
        status,
        isResponsible: true,
      });

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalCount,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='แบบขอแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>เลขที่คำสั่งแต่งตั้ง</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
                <th style={{ minWidth: 150 }}>แก้ไข (แต่งตั้งฯ)</th>
                <th style={{ minWidth: 150 }}>ยกเลิก (แต่งตั้งฯ)</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/preprocurement-directive/detail/${data.id}`)}>
                        {data.appointNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.APPOINTMENTCANCEL}
                    value={data.status} /></td>
                  <td>{`${data.departmentShortName} : ${data.departmentName}`}</td>
                  <td>{data.planName}</td>
                  <td className='text-center'>{THCurrency(data.planBudget)}</td>
                  <td>{`${data.supplyMethodName} : ${data.supplyMethodSpecialTypeName}`}</td>
                  <td>{formatMonthAndThaiYearWithSlash(data.expectingProcurementAt)}</td>
                  <td>{data.isEdited && (<FaCheck className='text-warning' />)}</td>
                  <td>{data.isInCancelled && (<FaCheck className='text-warning' />)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// ขอแก้ไขแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง
function Table7() {
  const navigate = useNavigate();
  const [data, setData] = useState<AppointmentModel[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const supplyMethodIds = [
      CriteriaSupplyMethodId.MethodId60,
      CriteriaSupplyMethodId.MethodId80,
    ];

    const status = [
      PreProcumentStatus.Draft,
      PreProcumentStatus.Pending,
      PreProcumentStatus.Rejected,
    ];

    const res = await PreProcurementServices.getListAppointmentAsync(
      {
        size,
        page,
        supplyMethodIds,
        status,
      } as SearchPP0201Model,
      PreProcumentFlag.Editing);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='ขอแก้ไขแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>เลขที่คำสั่งแต่งตั้ง</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/edit-preprocurement-directive/edit/${data.id}`)}>
                        {data.appointNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.APPOINTMENTEDIT}
                    value={data.status} /></td>
                  <td>{`${data.departmentShortName} : ${data.departmentName}`}</td>
                  <td>{data.planName}</td>
                  <td className='text-end'>{THCurrency(data.planBudget)}</td>
                  <td>{`${data.supplyMethodName} : ${data.supplyMethodSpecialTypeName}`}</td>
                  <td>{formatMonthAndThaiYearWithSlash(data.expectingProcurementAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// ขอยกเลิกแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง
function Table8() {
  const navigate = useNavigate();
  const [data, setData] = useState<AppointmentModel[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const supplyMethodIds = [
      CriteriaSupplyMethodId.MethodId60,
      CriteriaSupplyMethodId.MethodId80,
    ];

    const status = [
      PreProcumentStatus.Draft,
      PreProcumentStatus.Pending,
      PreProcumentStatus.Rejected,
    ];

    const res = await PreProcurementServices.getListAppointmentAsync(
      {
        size,
        page,
        supplyMethodIds,
        status,
      } as SearchPP0201Model,
      PreProcumentFlag.Cancel);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='แบบขอยกเลิกแต่งตั้งบุคคล/คกก. จัดทำขอบเขตของงาน/ราคากลาง'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>เลขที่คำสั่งแต่งตั้ง</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/cancel-preprocurement-directive/cancel/${data.id}`)}>
                        {data.appointNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.APPOINTMENTCANCEL}
                    value={data.status} /></td>
                  <td className='text-start'>{`${data.departmentShortName} : ${data.departmentName}`}</td>
                  <td className='text-start text-wrap'
                    style={{ minWidth: 650, width: 650 }}>{data.planName}</td>
                  <td className='text-end'>{THCurrency(data.planBudget)}</td>
                  <td>{`${data.supplyMethodName} : ${data.supplyMethodSpecialTypeName}`}</td>
                  <td>{formatMonthAndThaiYearWithSlash(data.expectingProcurementAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// จัดทำร่างขอบเขตของงาน (TOR)
function Table9() {
  const navigate = useNavigate();
  const [data, setData] = useState<TorModel[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const supplyMethodType = [
      CriteriaSupplyMethodId.MethodId60,
      CriteriaSupplyMethodId.MethodId80,
    ];

    const termOfRefStatus = [
      TorStatusConstant.Draft,
      TorStatusConstant.DraftTor,
      TorStatusConstant.WaitingForCommitteeApprove,
      TorStatusConstant.DraftSectionApprover,
      TorStatusConstant.WaitingForApprove,
      TorStatusConstant.Approved,
      TorStatusConstant.Cancelled,
      TorStatusConstant.Rejected];

    const res = await PreProcurementTorServices.getListTorAsync({
      page,
      size,
      supplyMethodType,
      termOfRefStatus,
      isResponsible: true,
    });

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalCount,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='จัดทำร่างขอบเขตของงาน (TOR)'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 200 }}>เลขที่เอกสาร<br />
                  ร่างขอบเขตของงาน (TOR)
                </th>
                <th style={{ minWidth: 200 }}>เลขที่เอกสาร<br />
                  คำสั่งแต่งตั้ง
                </th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>แก้ไข (TOR)</th>
                <th style={{ minWidth: 150 }}>ยกเลิก (TOR)</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/preprocurement-tor/detail/${data.id}`)}>
                        {data.torNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.TOR}
                    value={data.status} /></td>
                  <td>{data.departmentName}</td>
                  <td>{data.planName}</td>
                  <td className='text-end'>{THCurrency(data.planBudget)}</td>
                  <td>{data.supplyMethodName}</td>
                  <td>{data.isEdited && (<FaCheck className='text-warning' />)}</td>
                  <td>{data.isCancelled && (<FaCheck className='text-warning' />)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// ขอแก้ไขร่างขอบเขตของงาน (TOR)
function Table10() {
  const navigate = useNavigate();
  const [data, setData] = useState<TorModel[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const supplyMethodType = [
      CriteriaSupplyMethodId.MethodId60,
      CriteriaSupplyMethodId.MethodId80,
    ];

    const termOfRefStatus = [
      TorStatusConstant.Draft,
      TorStatusConstant.DraftTor,
      TorStatusConstant.WaitingForCommitteeApprove,
      TorStatusConstant.DraftSectionApprover,
      TorStatusConstant.WaitingForApprove,
      TorStatusConstant.Approved,
      TorStatusConstant.Cancelled,
      TorStatusConstant.Rejected];

    const res = await PreProcurementTorServices.getListTorAsync(
      {
        page,
        size,
        supplyMethodType,
        termOfRefStatus,
        isResponsible: true,
      },
      PreProcumentFlag.IsEditing);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalCount,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='ขอแก้ไขร่างขอบเขตของงาน (TOR)'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 200 }}>เลขที่เอกสารร่างขอบ<br />
                  เขตงของงาน(TOR)
                </th>
                <th style={{ minWidth: 200 }}>เลขที่เอกสาร<br />
                  คำสั่งแต่งตั้ง
                </th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/edit-preprocurement-tor/detail/${data.id}`)}>
                        {data.torNumber}
                      </Button>
                    </div>
                  </td>
                  <td className='text-center'>{data.appointNumber}</td>
                  <td><Status type={StatusType.TOR}
                    value={data.status} /></td>
                  <td className='text-start'>{data.departmentName}</td>
                  <td className='text-start text-wrap'
                    style={{ minWidth: 650, width: 650 }}>{data.planName}</td>
                  <td className='text-end'>{THCurrency(data.planBudget)}</td>
                  <td>{data.supplyMethodName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// ขอยกเลิกร่างขอบเขตของงาน (TOR)
function Table11() {
  const navigate = useNavigate();
  const [data, setData] = useState<TorModel[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const supplyMethodType = [
      CriteriaSupplyMethodId.MethodId60,
      CriteriaSupplyMethodId.MethodId80,
    ];

    const termOfRefStatus = [
      TorStatusConstant.Draft,
      TorStatusConstant.DraftTor,
      TorStatusConstant.WaitingForCommitteeApprove,
      TorStatusConstant.DraftSectionApprover,
      TorStatusConstant.WaitingForApprove,
      TorStatusConstant.Approved,
      TorStatusConstant.Cancelled,
      TorStatusConstant.Rejected];

    const res = await PreProcurementTorServices.getListTorAsync(
      {
        page,
        size,
        supplyMethodType,
        termOfRefStatus,
        isResponsible: true,
      },
      PreProcumentFlag.IsCanceling);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalCount,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='ขอยกเลิกร่างขอบเขตของงาน (TOR)'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 200 }}>เลขที่เอกสารร่างขอบ<br />
                  เขตงของงาน(TOR)
                </th>
                <th style={{ minWidth: 200 }}>เลขที่เอกสาร<br />
                  คำสั่งแต่งตั้ง
                </th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/cancel-preprocurement-tor/detail/${data.id}`)}>
                        {data.torNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.TOR}
                    value={data.status} /></td>
                  <td>{data.departmentName}</td>
                  <td>{data.planName}</td>
                  <td className='text-end'>{THCurrency(data.planBudget)}</td>
                  <td>{data.supplyMethodName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// การแจ้งข้อมูลเบื้องต้น (จพ.004)
function Table12() {
  const { userId } = useAppContext();
  const navigate = useNavigate();
  const [data, setData] = useState<BasicInfoResponse[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const supplyMethod = [
      CriteriaSupplyMethodId.MethodId60,
      CriteriaSupplyMethodId.MethodId80,
    ];

    const status = [
      'All',
      'NotYet',
      'WaitingAccept',
      'Accepted',
      'Reject',
      'AssignJorPor',
    ];

    const res = await PP0205Service.getBasicInfoListAsync(
      page,
      size,
      {
        isPermission: true,
        supplyMethod,
        status,
      } as BasicInfoSearchParams,
      userId);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalCount,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='การแจ้งข้อมูลเบื้องต้น (จพ.004)'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 200 }}>เลขที่เอกสารร่างขอบ<br />
                  เขตงของงาน(TOR)
                </th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />
                  การจัดซื้อจัดจ้าง
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/pp/pp0205/detail/${data.id}/${data.basicInfoId}`)}>
                        {data.torNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.JOR_POR_04_05_APPROVER}
                    value={data.status} /></td>
                  <td>{data.departmentName}</td>
                  <td>{data.planName}</td>
                  <td className='text-end'>{THCurrency(data.planBudget)}</td>
                  <td>{data.supplyMethod}</td>
                  <td>{GetMonthYearBC(data.expectingProcurementAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// จัดทำรายงานขอซื้อขอจ้าง (จพ.005)
function Table13() {
  const navigate = useNavigate();
  const [data, setData] = useState<JorPor05ListData[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const res = await jorPor05.getListDataAsync(
      size,
      page,
      {
        isPermission: true,
      } as JorPor05Criteria);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='จัดทำรายงานขอซื้อขอจ้าง (จพ.005)'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>เลขที่เอกสาร (จพ.004)</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/pr/pr0101/detail/${data.basicInformationId}`)}>
                        {data.basicDocumentNumber}
                      </Button>
                    </div>
                  </td>
                  <td><Status type={StatusType.PROCESS}
                    value={data.status} /></td>
                  <td>{data.departmentName}</td>
                  <td>{data.planName}</td>
                  <td className='text-end'>{THCurrency(data.financialAmount)}</td>
                  <td className='text-center'>{data.supplyMethodName}</td>
                  <td className='text-center'>{GetMonthYearBC(data.expectingProcurementAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// จัดทำคำสั่งแต่งตั้งคณะกรรมการจัดซื้อจัดจ้างและคณะกรรมการตรวจรับ
function Table14() {
  const navigate = useNavigate();
  const [data, setData] = useState<AppointCommiteeListResponse[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const supplyMethodIds = [
      CriteriaSupplyMethodId.MethodId60,
      CriteriaSupplyMethodId.MethodId80,
    ];

    const status = [
      'None',
      'Draft',
      'Pending',
      'Approved',
      'Rejected',
      'Cancelled',
    ];

    const res = await procurementAppointmentOrderServices.getAppointmentorderListAsync({
      page,
      size,
      supplyMethodIds,
      status,
    });

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalCount,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='จัดทำคำสั่งแต่งตั้งคณะกรรมการจัดซื้อจัดจ้างและคณะกรรมการตรวจรับ'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 200 }}>เลขที่เอกสารรายงาน<br />
                  ขอซื้อขอจ้าง (จพ.005)
                </th>
                <th style={{ minWidth: 150 }}>เลขที่เอกสาร PR</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />
                  การจัดซื้อจัดจ้าง
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/procurement-appointment-order/detail/${data.jorPor05Id}/id/${data.id}`)}>
                        {data.jorPor05Number}
                      </Button>
                    </div>
                  </td>
                  <td className='text-center'>{data.prNumber}</td>
                  <td><Status type={StatusType.APPOINTMENTORDER}
                    value={data.status} /></td>
                  <td>{data.departmentShort} : {data.department}</td>
                  <td>{data.planName}</td>
                  <td className='text-end'>{THCurrency(data.planBudget)}</td>
                  <td>{data.supplyMethodValue}</td>
                  <td>{formatMonthAndThaiYearWithSlash(data.expectingProcurementAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// จัดทำหนังสือเชิญชวนผู้ประกอบการ
function Table15() {
  const navigate = useNavigate();
  const [data, setData] = useState<InvitationModel[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const supplyMethodType = [
      CriteriaSupplyMethodId.MethodId60,
      CriteriaSupplyMethodId.MethodId80,
    ];

    const inviteStatus = [
      'None',
      'Draft',
      'WaitingForApprove',
      'Approved',
      'Rejected',
    ];

    const res = await InvitationServices.getListInvitationAsync({
      size,
      page,
      supplyMethodType,
      inviteStatus,
    });

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalCount,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='จัดทำหนังสือเชิญชวนผู้ประกอบการ'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 200 }}>เลขที่โครงการ</th>
                <th style={{ minWidth: 250 }}>เลขที่เอกสารจัดทำ<br />หนังสือเชิญชวนผู้ประกอบการ</th>
                <th style={{ minWidth: 150 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/procurement-invitation-order/detail/${data.jorPor05Id}/id/${data.id}`)}>
                        {data.torNumber}
                      </Button>
                    </div>
                  </td>
                  <td className='text-center'>{data.inviteNumber}</td>
                  <td><Status type={StatusType.INVITATIONORDER}
                    value={data.status} /></td>
                  <td>{`${data.departmentShort} : ${data.department}`}</td>
                  <td>{data.planName}</td>
                  <td className='text-end'>{THCurrency(data.planBudget)}</td>
                  <td>{`${data.supplyMethodValue}`}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// รายงานผลการพิจารณาและอนุมัติสั่งซื้อ/สั้งจ้าง (จพ.006)
function Table16() {
  const navigate = useNavigate();
  const [data, setData] = useState<JorPor06ListsResponse[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const supplyMethod = [
      CriteriaSupplyMethodId.MethodId60,
      CriteriaSupplyMethodId.MethodId80,
    ];

    const status = [
      'All',
      'NotYet',
      'Draft',
      'WaitingAccept',
      'Accepted',
      'Reject',
    ];

    const res = await jorPor06.getJorpor06ListsAsync(
      page,
      size,
      {
        supplyMethod,
        status,
      } as JorPor06Criteria);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='รายงานผลการพิจารณาและอนุมัติสั่งซื้อ/สั้งจ้าง (จพ.006)'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>เลขที่เอกสารจัดทำ<br />
                  หนังสือเชิญชวนผู้ประกอบการ
                </th>
                <th style={{ minWidth: 150 }}>เลขที่เอกสารรายงานผลการพิจารณาและ<br />
                  ขออนุมัติสั่งซื้อ/สั่งจ้าง ( จพ.006 )
                </th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>เลขที่โครงการ</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>งบประมาณ (บาท)</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/procurement-create-006/detail/${data.inviteId}/${data.id}`)}>
                        {data.jorPor06Number}
                      </Button>
                    </div>
                  </td>
                  <td>{data.jorPor06Number}</td>
                  <td><Status type={StatusType.JOR_POR_04_05_APPROVER}
                    value={data.status} /></td>
                  <td>{data.departmentName}</td>
                  <td>{data.torNumber}</td>
                  <td>{data.planName}</td>
                  <td className='text-end'>{THCurrency(data.financialAmount)}</td>
                  <td>{data.supplyMethodName}</td>
                  <td>{GetMonthYearBC(data.expectingProcurementAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}

// บันทึกเลขที่ทะเบียนสัญญา
function Table17() {
  const navigate = useNavigate();
  const [data, setData] = useState<RecordContract[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ size: 10, page: 1 } as Pagination);

  useEffect(() => {
    getDataAsync(pagination.size, pagination.page);
  }, []);

  const getDataAsync = async (size: number, page: number) => {
    const res = await recordContract.getListAsync(
      size,
      page,
      true);

    if (res.status === HttpStatusCode.OK) {
      setData(res.data.data);
      setPagination({
        ...pagination,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPagination({
      ...pagination,
      size,
      page,
    });

    getDataAsync(size, page);
  };

  return (
    <>
      {pagination.totalRecords ?
        <Card className='my-3'>
          <Title
            text='บันทึกเลขที่ทะเบียนสัญญา'
            className='fs-5 text-primary' />
          <Table
            className='mt-3'
            total={pagination.totalRecords}
            onChange={onPaginationChange}>
            <thead>
              <tr>
                <th style={{ minWidth: 150 }}>เลขที่เอกสารรายงาน<br />ขอซื้อขอจ้าง (จพ.005)</th>
                <th style={{ minWidth: 250 }}>สถานะ</th>
                <th style={{ minWidth: 150 }}>ฝ่าย/สำนัก</th>
                <th style={{ minWidth: 150 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 150 }}>วงเงิน</th>
                <th style={{ minWidth: 150 }}>วิธีจัดหา</th>
                <th style={{ minWidth: 150 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data) => (
                <tr key={data.id}>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='link'
                        onClick={() => navigate(`/pr/pr0206/detail/${data.id}`)}>
                        {data.taxpayerIdentificationNo}
                      </Button>
                    </div>
                  </td>
                  <td>{data.establishmentName}</td>
                  <td><Status type={StatusType.PROCESS}
                    value={data.status} /></td>
                  <td className='text-center'>{data.torNumber}</td>
                  <td>{data.planName}</td>
                  <td className='text-end'>{THCurrency(data.financialAmount)}</td>
                  <td className='text-center'>{fullDate(data.recordContractDate)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> : null}
    </>
  );
}
