import { Breadcrumb } from 'components';
import StepProgress from 'components/StepProgress';
import { useRef } from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Step1 from './step1';
import Step2 from './step2';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

function AddCM0202() {
  const childRef = useRef<ProgressBarRefType>(null);
  const obj = [{
    title: <p>ข้อมูลแบบฟอร์มจัดส่งเอกสาร
      บริหารสัญญา
    </p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p>ตัวอย่าง
      แบบฟอร์มจัดส่งเอกสารบริหารสัญญา
    </p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();
  const backToIndex = () => {
    navigate('/cm/cm0202');
  };

  const BREADCRUMB_INFO = [
    {
      routerName: '/cm/cm0202', label: 'แบบฟอร์มจัดส่งเอกสารบริหารสัญญา',
    },
    {
      routerName: '/cm/cm0202/detail', label: 'รายละเอียดแบบฟอร์มจัดส่งเอกสารบริหารสัญญา',
    },
  ];

  return (
    <div className="document">
      <h4 className="mt-2 mb-0 text-primary text-start fs-4">แบบฟอร์มจัดส่งเอกสารบริหารสัญญา</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12} className="mb-2">
          <StepProgress progressBarData={obj} ref={childRef} />
        </Col>
      </Row>
    </div>
  );
}

export default AddCM0202;
