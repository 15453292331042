import {
  Input,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import { ItemModel } from 'models';
import {
  ApproverJorpor04,
  ApproverJorpor04List,
} from 'models/PP';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import PP0303Service from 'services/PP/PP0303Service';
import {
  formatMonthAndThaiYearWithSlash,
  HttpStatusCode,
} from 'utils';

export default function PP03() {
  return (
    <div className='m01'>
      <PP0303Header />
      <ApproveJorPor04List />
    </div>
  );
}

function PP0303Header() {
  return (
    <>
      <h4 className='text-primary m-0'>อนุมัติ จพ.004 ตามสายงาน</h4>
      <hr />
    </>
  );
}

type Loader = {
  departmentDDL: ItemModel[],
  list: ApproverJorpor04List
};

function ApproveJorPor04List() {
  const loaderData = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<ApproverJorpor04>({} as ApproverJorpor04);
  const [listData, setListData] = useState<ApproverJorpor04List>({} as ApproverJorpor04List);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [currentSize, setCurrentSize] = useState<number>(10);
  const navigate = useNavigate();

  const onSumbit = async (searchData: ApproverJorpor04) => {
    await getJorPor04List(searchData);
  };

  const onClear = async () => {
    setCriteria({} as ApproverJorpor04);
    await getJorPor04List();
  };

  useEffect(() => {
    getJorPor04List();
  }, [page, size]);

  const getJorPor04List = useCallback(async (searchData?: ApproverJorpor04) => {
    const { data, status } = await PP0303Service.GetListAsync(page, size, searchData);

    if (status === HttpStatusCode.OK) {
      setListData(data);
      setCurrentSize(size);
    }
  }, [page, size]);

  return (
    <>
      <div className='criteria'>
        <Row>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='เลขที่เอกสาร PR'
              placeholder='เลขที่เอกสาร PR'
              value={criteria.prNumber}
              onChange={(value) => setCriteria({ ...criteria, prNumber: value })}
            />
          </Col>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='ร่างขอบเขตของงาน (TOR)'
              placeholder='ร่างขอบเขตของงาน (TOR)'
              value={criteria.objective}
              onChange={(value) => setCriteria({ ...criteria, objective: value })}
            />
          </Col>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='ชื่อโครงการ'
              placeholder='ชื่อโครงการ'
              value={criteria.projectName}
              onChange={(value) => setCriteria({ ...criteria, projectName: value })}
            />
          </Col>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Selector
              label='ฝ่าย/สำนัก'
              items={loaderData.departmentDDL}
              value={criteria.departmentName}
              onChange={(value) => setCriteria({ ...criteria, departmentName: value })}
            />
          </Col>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Input
              label='ผู้จัดทำ'
              placeholder='ผู้จัดทำ'
              value={criteria.createdBy}
              onChange={(value) => setCriteria({ ...criteria, createdBy: value })}
            />
          </Col>
          <div className='d-flex gap-2 mt-2'>
            <Button
              variant='primary'
              className='d-flex align-items-center gap-2'
              onClick={() => onSumbit(criteria)}
            >
              <FaSearch />ค้นหา
            </Button>
            <Button
              variant='outline-primary'
              className='d-flex align-items-center gap-2'
              onClick={onClear}
            >
              <FaEraser />ล้าง
            </Button>
          </div>
        </Row>
      </div>
      <Table
        className='mt-4'
        total={listData.totalRecords}
        onChange={(size, page) => (
          setSize(size),
            setPage(size === currentSize ? page : 1)
        )}
      >
        <thead>
        <tr className='text-center'>
          <th>ลำดับ</th>
          <th>เลขที่เอกสาร PR</th>
          <th>ร่างขอบเขตของงาน (TOR)</th>
          <th>ชื่อโครงการ</th>
          <th>ฝ่าย/สำนัก</th>
          <th>ผู้จัดทำ</th>
          <th>วันที่จัดทำ</th>
          <th>สถานะ</th>
        </tr>
        </thead>
        <tbody>
        {listData.tableData?.map((val, index) => (
          <tr key={val.id}>
            <td className='text-center'>
              {((page - 1) * size) + index + 1}
            </td>
            <td className='text-center'>
              <Button variant='link'
                onClick={() => navigate(`detail/${val.id}`)}>
                {val.prNumber}
              </Button>
            </td>
            <td>{val.objective}</td>
            <td>{val.projectName}</td>
            <td>{val.departmentName}</td>
            <td className='text-center'>{val.createdBy}</td>
            <td className='text-center'>{formatMonthAndThaiYearWithSlash(val.createdDate)}</td>
            <td className='text-center'>
              <Status type={StatusType.PRE_PROCURMENT}
                value={val.status} />
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </>
  );
}
