import { Breadcrumb, ButtonCustom, Card, Currency, Input, InputFile, InputTextArea, Modal, Selector, Table, UploadFile } from 'components';
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Modal as ModalBT } from 'react-bootstrap';
import { FaHistory, FaSave, FaTrashAlt } from 'react-icons/fa';
import { MdArrowBack, MdOutlineArrowBack } from 'react-icons/md';
import { useLoaderData, useNavigate, useParams } from 'react-router';
import toast from 'utils/toast';
import { HttpStatusCode, fullDatetime, submitForm } from 'utils';
import { SU12Service } from 'services';
import { DocumentTemplate, DocumentTemplateHistory, ItemModel } from 'models';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';
import { SectionApproverModel } from 'models/sectionApproverModel';
import SectionApproverService from 'services/SU/SectionApproverService';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import { RefBankOrder } from 'constant/PreProcumentFlag';
import Title from 'components/Controls/Title';

const BREADCRUMB_INFO = [
  {
    routerName: '/su/su13', label: 'ลำดับการอนุมัติ',
  },
  {
    routerName: '', label: 'ข้อมูลรายละเอียดลำดับอนุมัติ',
  },
];

const RefBankOrderDDL = [
  { label: RefBankOrder.RefBankOrder72, value: RefBankOrder.RefBankOrder72 },
  { label: RefBankOrder.RefBankOrder159, value: RefBankOrder.RefBankOrder159 },
  { label: RefBankOrder.RefBankOrder216, value: RefBankOrder.RefBankOrder216 },
  { label: RefBankOrder.RefBankOrder285, value: RefBankOrder.RefBankOrder285 },
  { label: RefBankOrder.RefBankOrder528, value: RefBankOrder.RefBankOrder528 },
];

type Loader = {
  sectionDDL: ItemModel[],
  processTypeDDL: ItemModel[],
  buHeadDDL: ItemModel[],
  buGroupDDL: ItemModel[],
  buLineDDL: ItemModel[],
  buDepartmentDDL: ItemModel[],
  buCenterDDL: ItemModel[],
  buZoneDDL: ItemModel[],
  buSegmentDDL: ItemModel[],
  buBranchDDL: ItemModel[],
  buSubBranchDDL: ItemModel[],
};

export default function SU13Detail() {
  const {
    sectionDDL,
    processTypeDDL,
    buHeadDDL,
    buGroupDDL,
    buLineDDL,
    buDepartmentDDL,
    buCenterDDL,
    buZoneDDL,
    buSegmentDDL,
    buBranchDDL,
    buSubBranchDDL
  } = useLoaderData() as Loader;
  const [sectionApprover, setSectionApprover] = useState<SectionApproverModel>({} as SectionApproverModel);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getDataAsync();
    }
  }, [id]);

  const getDataAsync = async () => {
    const res = await SectionApproverService.getDataAsync(id!);

    if (res.status === HttpStatusCode.OK) {
      setSectionApprover(res.data);
    }

    if (res.status === HttpStatusCode.NOT_FOUND) {
      navigate('/su/su13/detail');
    }
  };

  const onSave = async () => {
    if (sectionApprover.processType === undefined || sectionApprover.processType === null) {
      toast.warn('กรุณาระบุการอนุมัติ');
      return;
    }

    if (sectionApprover.refBankOrder === undefined || sectionApprover.refBankOrder === null) {
      toast.warn('กรุณาระบุคำสั่งธนาคาร');
      return;
    }

    if (sectionApprover.sectionId === undefined || sectionApprover.sectionId === null) {
      toast.warn('กรุณาระบุลำดับอนุมัติ');
      return;
    }

    if (sectionApprover.budget === undefined || sectionApprover.budget === null) {
      toast.warn('กรุณาระบุงบประมาณสูงสุดที่อนุมัติได้');
      return;
    }

    if (sectionApprover.buHeadId === undefined || sectionApprover.buHeadId === null) {
      toast.warn('กรุณาระบุธนาคาร');
      return;
    }


    if (sectionApprover.id) {
      const res = await SectionApproverService.updateSectionApproverAsync(sectionApprover);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('บันทึกข้อมูลไม่สำเร็จ');
      }
      toast.success('บันทึกข้อมูลสำเร็จ');
      getDataAsync()
    } else {
      const res = await SectionApproverService.createSectionApproverAsync(sectionApprover);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('บันทึกข้อมูลไม่สำเร็จ');
      }
      setSectionApprover(res.data);
      toast.success('บันทึกข้อมูลสำเร็จ');
      navigate(`/su/su13/detail/${res.data}`);
    }
  }

  const onDelete = async () => {
    if (sectionApprover.id) {
      const res = await SectionApproverService.deleteSectionApproverAsync(sectionApprover.id);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('บันทึกข้อมูลไม่สำเร็จ');
      }
      toast.success('บันทึกข้อมูลสำเร็จ');
      navigate('/su/su13');
    } else {
      toast.warn('เกิดข้อผิดพลาด');
    }
  }

  return (
    <>
      <div className="d-flex justify-start align-items-center">
        <h4 className="text-primary m-0">
          <Button variant="link" onClick={() => navigate('/su/su13')}>
            <MdOutlineArrowBack className="fs-4 text-primary mb-1" />
          </Button>
          ข้อมูลรายละเอียดลำดับอนุมัติ
        </h4>
      </div>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Card>
        <Title text='ข้อมูลรายละเอียดลำดับอนุมัติ' className='fs-5 text-primary' />
        <Row className='mt-4'>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='การอนุมัติ'
              rule={{ required: true }}
              value={sectionApprover.processType}
              onChange={(val) => setSectionApprover({ ...sectionApprover, processType: val })}
              items={processTypeDDL}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='คำสั่งธนาคาร'
              rule={{ required: true }}
              value={sectionApprover.refBankOrder}
              onChange={(val) => setSectionApprover({ ...sectionApprover, refBankOrder: val })}
              items={RefBankOrderDDL}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='ลำดับอนุมัติ'
              rule={{ required: true }}
              value={sectionApprover.sectionId}
              onChange={(val) => setSectionApprover({ ...sectionApprover, sectionId: val })}
              items={sectionDDL}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Currency
              label='งบประมาณสูงสุดที่อนุมัติ'
              rule={{ required: true }}
              value={sectionApprover.budget}
              onChange={(val) => setSectionApprover({ ...sectionApprover, budget: val ? Number(val) : 0 })}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={8} xl={6}>
            <InputTextArea
              label='รายละเอียด'
              value={sectionApprover.remark}
              onChange={(val) => setSectionApprover({ ...sectionApprover, remark: val })}
            />
          </Col>
        </Row>
        <div>
          <h5 className='mt-2 text-primary'>
            โครงสร้างองค์กร
          </h5>
        </div>
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='ธนาคาร'
              rule={{ required: true }}
              value={sectionApprover.buHeadId}
              onChange={(val) => setSectionApprover({ ...sectionApprover, buHeadId: val })}
              items={buHeadDDL}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='กลุ่มงาน'
              value={sectionApprover.buGroupId}
              onChange={(val) => setSectionApprover({ ...sectionApprover, buGroupId: val })}
              items={buGroupDDL}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='สายงาน'
              value={sectionApprover.buLineId}
              onChange={(val) => setSectionApprover({ ...sectionApprover, buLineId: val })}
              items={buLineDDL}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='ฝ่าย'
              value={sectionApprover.buDepartmentId}
              onChange={(val) => setSectionApprover({ ...sectionApprover, buDepartmentId: val })}
              items={buDepartmentDDL}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='ศูนย์'
              value={sectionApprover.buCenterId}
              onChange={(val) => setSectionApprover({ ...sectionApprover, buCenterId: val })}
              items={buCenterDDL}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='เขต'
              value={sectionApprover.buZoneId}
              onChange={(val) => setSectionApprover({ ...sectionApprover, buZoneId: val })}
              items={buZoneDDL}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='ส่วน'
              value={sectionApprover.buSegmentId}
              onChange={(val) => setSectionApprover({ ...sectionApprover, buSegmentId: val })}
              items={buSegmentDDL}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='สาขา'
              value={sectionApprover.buBranchId}
              onChange={(val) => setSectionApprover({ ...sectionApprover, buBranchId: val })}
              items={buBranchDDL}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Selector
              label='สาขาย่อย'
              value={sectionApprover.buSubBranchId}
              onChange={(val) => setSectionApprover({ ...sectionApprover, buSubBranchId: val })}
              items={buSubBranchDDL}
            />
          </Col>
        </Row>
      </Card>
      <Row className='mt-4'>
        <Col className='d-flex justify-content-between'>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={() => navigate('/su/su13')}>
            <MdArrowBack />ย้อนกลับ
          </Button>
          <div>
            {
              sectionApprover.id && (
                <ButtonCustom variant='danger' onClick={() => setShowDeleteModal(true)} icon='delete' text="ลบข้อมูล" />
              )
            }
            <ButtonCustom onClick={onSave} text="บันทึก" />
          </div>
        </Col>
      </Row>
      <Modal
        show={showDeleteModal}
        size='lg'
        onHide={() => setShowDeleteModal(false)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบข้อมูล</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <span>ท่านต้องการลบข้อมูลลำดับอนุมัติหรือไม่?</span>
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <ButtonCustom variant='outline-primary' onClick={() => setShowDeleteModal(false)} text="ยกเลิก" />
              <ButtonCustom variant='primary' onClick={onDelete} text="ยืนยันลบข้อมูล" />
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}