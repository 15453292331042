export enum ProcurementSection {
  DEPARTMENT_DIRECTOR_AGREE = 'DepartmentDirectorAgree', // ผู้อำนวยการฝ่ายเห็นชอบ
  JORPOR_DIRECTOR_BE_INFORMED = 'JorporDirectorBeInformed', // ผอ.จพ.รับทราบ
  JORPOR_DIRECTOR_ASSIGN = 'JorporDirectorAssign', // ผอ.จพ. มอบหมาย
  SECTION_HEAD_JORPOR = 'SectionHeadJorpor', // หัวหน้าส่วน จพ.
  ASSISTANT_DIRECTOR_JORPOR = 'AssistantDirectorJorpor', // ผู้ช่วย ผอ. จพ.
  DIRECTOR_JORPOR = 'DirectorJorpor', // ผอ. จพ.
  ASSISTANT_MD = 'AssistantMd', // ผู้ช่วยกรรมการ
  DEPUTY_MD = 'DeputyMd', // รองกรรมการผู้จัดการ
  MD = 'Md', // กรรมการผู้จัดการ
  ASSISTANT_HEAD_OF_DEPARTMENT = 'AssistantHeadOfDepartment', //ผู้ช่วยหัวหน้าส่วน
  SECTION_HEAD = 'SectionHead', //หัวหน้าส่วน
  ASSISTANT_DEPARTMENT_DITRECTOR = 'AssistantDepartmentDirector', //ผู้ช่วย ผอ.ฝ่าย
  DEPARTMENT_DIRECTOR = 'DepartmentDirector', // ผู้อำนวยการฝ่าย
  APPROVER = 'Approver', // ผู้อำนาจเห็นชอบ
}