import { createContext, useContext } from 'react';

export type AppContextType = {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  positionId: string;
  positionCode: string;
  positionName: string;
  departmentId: string;
  departmentCode: string;
  departmentName: string;
  buDepartmentId: string;
  buDepartmentBuId: string;
  buDepartmentCode: string;
  buDepartmentName: string;
  tel: string;
  inRefCode?: string;
  inRefLevel?: string;
};

const AppContext = createContext({} as AppContextType);

export const useAppContext = () => useContext(AppContext);

export { AppContext };
