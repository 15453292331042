import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Currency,
  DatePicker,
  Input,
  Radio,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import { CollateralModel, ContractAgreementModel, DefectsWarrantyModel, EmploymentAgreementModel, ServiceDetailModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';
import moment from 'moment';

interface IDefectWarrantyProps {
  title: string;
  contractTemplateCode: string;
  contractData: ContractAgreementModel;
  employmentAgreement: EmploymentAgreementModel;
  onServiceDetailChange: (value: EmploymentAgreementModel) => void;
  isDisabled?: boolean;
}

const INFO = [
  { label: 'มีหลักประกัน', value: 'true' },
  { label: 'ไม่มีหลักประกัน', value: 'false' },
];

const PerformanceBondType = {
  cash: '0c1d6674-9282-8c27-7d04-4c22280b6baf',
  guaranteeElectronics: '474c0cbb-0496-6331-07c8-91f84a68e510',
  guaranteeBank: '02b37b8a-bbec-705f-8ec4-05074b3af398',
  guaranteeCompany: 'c55a61cf-e00d-ae94-930a-c3013fd73ca0',
  governmentBonds: '23b43be8-d1a4-a64f-fe2c-0025acda6ad9',
  cheque: '03101d8f-d472-d51c-0aed-b173c45073e6',
}

const fields = [
  {
    code: 'CF01',
  },
  {
    code: 'CF02',
  },
  {
    code: 'CF03',
  },
  {
    code: 'CF04',
  },
  {
    code: 'CF05',
  },
  {
    code: 'CF06',
  },
  {
    code: 'CF07',
    startServiceDate: 'ผู้รับจ้างตกลงให้บริการระยะเวลาตั้งแต่วันที่',
    endServiceDate: 'จนถึง',
    periodService: 'ระยะเวลาการให้บริการ',
  },
  {
    code: 'CF08',
  },
  {
    code: 'CF09',
  },
  {
    code: 'CF10',
  },
  {
    code: 'CF11',
  },
  {
    code: 'CF12',
  },
  {
    code: 'CF13',
  },
  {
    code: 'CF14',
  },
  {
    code: 'CF15',
  },
];

export function ServicePeriodDetail(props: IDefectWarrantyProps) {
  const [employmentAgreement, setEmploymentAgreement] = useState<EmploymentAgreementModel>(props.employmentAgreement);

  useEffect(() => {
    if (employmentAgreement) {
      props.onServiceDetailChange(employmentAgreement);
    }
  }, [employmentAgreement]);

  const onChangeStartDate = (start?: Date) => {
    if (start && employmentAgreement.dueDate) {
      const startDate = moment(start);
      const timeEnd = moment(employmentAgreement.dueDate);
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);

      setEmploymentAgreement({ ...employmentAgreement, startDate: start, processingTimePerYear: diffDuration.years(), processingTimePerMonth: diffDuration.months(), processingTimePerDay: diffDuration.days() });
    } else {
      setEmploymentAgreement({ ...employmentAgreement, startDate: start, processingTimePerYear: 0, processingTimePerMonth: 0, processingTimePerDay: 0 });
    }
  }

  const onChangeEndDate = (end?: Date) => {
    if (employmentAgreement.startDate && end) {
      const startDate = moment(employmentAgreement.startDate);
      const timeEnd = moment(end);
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);

      setEmploymentAgreement({ ...employmentAgreement, dueDate: end, processingTimePerYear: diffDuration.years(), processingTimePerMonth: diffDuration.months(), processingTimePerDay: diffDuration.days() });
    } else {
      setEmploymentAgreement({ ...employmentAgreement, dueDate: end, processingTimePerYear: 0, processingTimePerMonth: 0, processingTimePerDay: 0 });
    }
  }

  const onNumberChange = useCallback((key: string, value: number) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <Card className='mt-3'>
      <Title text={`สัญญา${props.title ? props.title : 'ระยะเวลาให้บริการ'}`} className='fs-5 text-primary' />
      <>
        {
          fields?.filter(f => f.code === props.contractTemplateCode).map((data) =>
            <>
              {
                data.startServiceDate &&
                (
                  <>
                    <Row>
                      <Col sm={4}>
                        <DatePicker
                          label={data.startServiceDate}
                          value={props.contractData?.deliveryPeriod.startDate}
                          onChange={(value) => onChangeStartDate(value)}
                          rule={{ required: false }}
                          disabled={true} />
                      </Col>
                      <Col sm={4}>
                        <DatePicker
                          label={data?.endServiceDate}
                          value={props.contractData?.deliveryPeriod.dueDate}
                          onChange={(value) => onChangeEndDate(value)}
                          rule={{ required: false }}
                          disabled={true} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4} className='d-flex'>
                        <InputNumber
                          textCenter
                          label={data.periodService}
                          value={employmentAgreement?.processingTimePerYear}
                          onChange={(val) => onNumberChange('processingTimePerYear', Number(val))}
                          disabled={props.isDisabled}
                        />
                        <label className='mt-5 ps-2'>ปี</label>
                      </Col>
                      <Col sm={4} className='d-flex'>
                        <InputNumber
                          className='no-label'
                          textCenter
                          value={employmentAgreement?.processingTimePerMonth}
                          onChange={(val) => onNumberChange('processingTimePerMonth', Number(val))}
                          disabled={props.isDisabled}
                        />
                        <label className='mt-5 ps-2'>เดือน</label>
                      </Col>
                      <Col sm={4} className='d-flex'>
                        <InputNumber
                          textCenter
                          className='no-label'
                          value={employmentAgreement?.processingTimePerDay}
                          onChange={(val) => onNumberChange('processingTimePerDay', Number(val))}
                          disabled={props.isDisabled}
                        />
                        <label className='mt-5 ps-2'>วัน</label>
                      </Col>
                    </Row>
                  </>
                )
              }
            </ >
          )
        }
      </>
    </Card>
  );
}
