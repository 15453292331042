export enum JorPorCode {
  ID = '8220fd29-7cb6-4e60-99ad-629e817ccb38',
  CODE = '50004689',
  POSITION_NAME = 'ฝ่ายจัดหาและการพัสดุ'
}

export enum DepartmentId {
  SorOr = '0008cb2b-99e7-40d6-b70a-72cb402d6b1e',
  BorSorSor = 'b3a75d4b-0307-4c57-add6-9a1e8e659c51',
  BorPor = '820a59a1-1cd5-4d31-b025-bf1fd08df4bd',
  NPA = '26103d09-28c3-403a-aeb6-39aaa18f1656',
}

export enum ProcurementCommerceId {
  NotProcurementCommerce = 'c54d0ec4-bfce-4788-978f-cf00e99e9b12',
}

export enum CreatorLevel {
  LV010 = '10',
  LV009 = '09',
  LV008 = '08',
  LV007 = '07',
  LV006 = '06',
  LV005 = '05',
  LV004 = '04',
  LV003 = '03',
  LV002 = '02',
  LV000 = '00',
}