import { CriteriaPlanProcurementList } from 'models';
import {
  planProcurement,
  shareValue,
} from 'services';
import { PlanTypeText } from 'utils/constants/PlanEnum';

export const inYearPlanProcurementLoader = async () => {
  const [department, SupplyMethod, inYearData, statusCount] = await Promise.all([
    shareValue.GetDDL('Department'),
    shareValue.GetDDL('SupplyMethod'),
    planProcurement.getPlanProcurementListAsync(1, 10, PlanTypeText.InYearPlan, { isAssignToMeOnly: true } as CriteriaPlanProcurementList),
    planProcurement.getPlanStatusCount(1, 10, PlanTypeText.InYearPlan, { isAssignToMeOnly: true } as CriteriaPlanProcurementList)]);
  const SupplyMethodAll = [{ label: 'ทั้งหมด', value: 'All' }, ...SupplyMethod];
  return {
    departmentDDL: department,
    supplyMethodDDL: SupplyMethodAll,
    inYearPlanData: inYearData.data.data,
    totalRecords: inYearData.data.totalRecords,
    statusCount: statusCount.data,
  };
};
