import { TraderModalTypeConstant } from 'constant/ProcumentInvitationStatus';
import Cookie from 'cookie-universal';
import {
  CreateEditingContractAgreementModel,
  MaintenanceInformationModel,
  SearchCriteriaModel,
  UpdateAcceptorStep4Model,
  UpdateContractAgreementDraftModel,
  UpdateContractAgreementStep2Model,
  UpdateContractDateStep5Model,
  UpdateDocBoxNumberStep4Model,
  UpdateEntrepreneurConsiderationModel,
  UpdateInspectorStep3Model,
  UpdateJorPorCommentModel,
  UpdateWorkPhaseStep2Model
} from 'models/CM/CM02Models';
import http from 'utils/axios';

const qs = require('qs');

const cookies = Cookie();
const getFullName = cookies.get('fullName') as string;
const api = '/ContractAgreement';

const getListDraftContractMangementAsync = (searchData: SearchCriteriaModel, page: number, size: number) => {
  const params = {
    page: page,
    pageSize: size,
    contractNumber: searchData.contractNumber,
    poNumber: searchData.poNumber,
    planNumber: searchData.planNumber,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    entrepreneurName: searchData.entrepreneurName,
    contractStartDate: searchData.contractStartDate,
    contractEndDate: searchData.contractEndDate,
    parcelDescription: searchData.parcelDetail,
    SupplyMethod: searchData.supplyMethod,
    ContractAgreementStatus: searchData.contractAgreementStatus,
    IsAssigneeOnly: searchData.isResponsible,
    ContractAgreementFlag: searchData.contractAgreementFlag
  };
  return http.get(`${api}`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getCountStatusAsync = (searchData: SearchCriteriaModel) => {
  const params = {
    contractNumber: searchData.contractNumber,
    poNumber: searchData.poNumber,
    planNumber: searchData.planNumber,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    entrepreneurName: searchData.entrepreneurName,
    contractStartDate: searchData.contractStartDate,
    contractEndDate: searchData.contractEndDate,
    parcelDescription: searchData.parcelDetail,
    SupplyMethod: searchData.supplyMethod,
    ContractAgreementStatus: searchData.contractAgreementStatus,
    IsAssigneeOnly: searchData.isResponsible,
    ContractAgreementFlag: searchData.contractAgreementFlag
  };
  return http.get(`${api}/status-count`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getCountSupplyMethodAsync = (searchData: SearchCriteriaModel) => {
  const params = {
    contractNumber: searchData.contractNumber,
    poNumber: searchData.poNumber,
    planNumber: searchData.planNumber,
    departmentId: searchData.departmentId,
    planName: searchData.planName,
    entrepreneurName: searchData.entrepreneurName,
    contractStartDate: searchData.contractStartDate,
    contractEndDate: searchData.contractEndDate,
    parcelDescription: searchData.parcelDetail,
    SupplyMethod: searchData.supplyMethod,
    ContractAgreementStatus: searchData.contractAgreementStatus,
    IsAssigneeOnly: searchData.isResponsible,
    ContractAgreementFlag: searchData.contractAgreementFlag
  };
  return http.get(`${api}/supply-method-count`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getContractByIdAsync = (id: string) => {
  return http.get(`${api}/${id}`);
};

const getContractJorPorCommentAsync = (contractId?: string, id?: string) => {
  const paramId = id || '';

  return http.get(`${api}/${contractId}/jorpor-comment/${paramId}`);
};

const createJorPorCommentAsync = (body: UpdateJorPorCommentModel) => {
  return http.post(`${api}/create-jorpor-comment`, body);
};

const updateContractStep1Async = (id: string, body: UpdateContractAgreementDraftModel) => {
  return http.put(`${api}/${id}/draft`, body);
};

const updateContractEntrepreneurStep1Async = (id: string, body: UpdateEntrepreneurConsiderationModel) => {
  return http.put(`${api}/${id}/entrepreneur-consideration`, body);
};

const UpdateContractCoiWatchListEgpStep4Async = (id: string, body: UpdateEntrepreneurConsiderationModel) => {
  return http.put(`${api}/${id}/contract-consideration`, body);
};

const updateContractStep2Async = (id: string, body: UpdateContractAgreementStep2Model) => {
  return http.put(`${api}/${id}/draft-detail`, body);
};

const updateDefaultUploadAppendixFileAsync = (id: string) => {
  return http.put(`${api}/${id}/default-appendix-file`);
};

const updateContractWorkPhaseStep2Async = (id: string, body: UpdateWorkPhaseStep2Model) => {
  return http.put(`${api}/${id}/work-phases`, body);
};

const updateContractMAInfoStep2Async = (id: string, data: MaintenanceInformationModel[]) => {
  const params = {
    MaintenanceInformation: data
  }
  return http.put(`${api}/${id}/maintenance-information`, params);
};

const updateInspectorStep3Async = (id: string, body: UpdateInspectorStep3Model) => {
  return http.put(`${api}/${id}/inspector`, body);
};

const approveStep3Async = (id: string, remark: string) => {
  const request = {
    remark: remark
  };
  return http.put(`${api}/${id}/inspector-approve`, request);
};

const rejectStep3Async = (id: string, remark: string) => {
  const request = {
    remark: remark
  };
  return http.put(`${api}/${id}/inspector-reject`, request);
};

const updateDocumentBoxNumberStep4Async = (id: string, body: UpdateDocBoxNumberStep4Model) => {
  return http.put(`${api}/${id}/document-box-number`, body);
};

const updateAcceptorStep4Async = (id: string, body: UpdateAcceptorStep4Model) => {
  return http.put(`${api}/${id}/acceptor`, body);
};

const rejectStep4Async = (id: string, remark: string) => {
  const request = {
    remark: remark
  };
  return http.put(`${api}/${id}/acceptor-reject`, request);
};

const updateContractDateStep5Async = (id: string, body: UpdateContractDateStep5Model) => {
  return http.put(`${api}/${id}/contract-date`, body);
};

const getDocumentAsync = (id: string) => http.get(`${api}/${id}/document`);

const getDocumentChangeContractAsync = (id: string) => http.get(`${api}/${id}/document-change-contract`);

const uploadAttachmentConfidentialAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.post(`${api}/${id}/attachment-confidential`, formData, config);
};

const uploadAttachmentAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.post(`${api}/${id}/attachment-normal`, formData, config);
};

const uploadAttachmentArchiveAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.post(`${api}/${id}/attachment-archive`, formData, config);
};

const getAttachmentAsync = (id?: string, documentId?: string) =>
  http.get(`${api}/${id}/download-file/${documentId}`, {
    responseType: 'blob'
  });

const removeFileAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/${id}/attachment/${attachmentId}`);

const updateDocumentByIdAsync = (id: string, documentId: string) => http.put(`${api}/${id}/document`, { documentId });

const getDefaultInspectorsAsync = () => http.get(`${api}/default-inspector`);

const getHistoryAsync = (id: string, Page: number, PageSize: number) => {
  const params = {
    id: id,
    Page: Page,
    PageSize: PageSize
  };
  return http.get(`${api}/history`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const uploadAttachmentDocumentAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.post(`${api}/document/${id}/attachment`, formData, config);
};

const getAttachmentDocumentAsync = (id?: string, documentId?: string) =>
  http.get(`${api}/document/${id}/download-file/${documentId}`, {
    responseType: 'blob'
  });

const removeFileDocumentAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/document/${id}/attachment/${attachmentId}`);

const getContractSignDocumentAsync = (id: string) => http.get(`${api}/${id}/document-sign`);

const getDocumentPDPAAsync = (id: string) => http.get(`${api}/${id}/document-pdpa`);

const updateContractSignDocumentByIdAsync =
  (
    id: string,
    documentId: string,
    replaceCreator: boolean = false
  ) => http.put(`${api}/${id}/document-sign`, { documentId, replaceCreator });

const updateContractDocumentChangeByIdAsync =
  (
    id: string,
    documentId: string,
  ) => http.put(`${api}/${id}/document-change`, { documentId });

const updateDocumentPDPAByIdAsync =
  (
    id: string,
    documentId: string
  ) => http.put(`${api}/${id}/document-pdpa`, { documentId });

const uploadAttachmentEntrepreneurTypeAsync = (id: string, type: TraderModalTypeConstant, files: File[]) => {
  let endpointUrl = '';
  if (type === TraderModalTypeConstant.COI) {
    endpointUrl = 'attachment-coi';
  } else if (type === TraderModalTypeConstant.WATCHLIST) {
    endpointUrl = 'attachment-watch-list';
  } else {
    endpointUrl = 'attachment-egp';
  }

  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return http.post(`${api}/${id}/${endpointUrl}`, formData, config);
};

const getDocumentByTypeAsync = (id: string, type: string) => {
  const params = {
    id: id,
    documentType: type
  };
  return http.get(`${api}/attachments`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getDocumentAttachmentByDocIdAsync = (id: string, docId: string) => {
  const params = {
    id: id,
    documentId: docId
  };
  return http.get(`${api}/document/attachments`, {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const createEditingContractAgreementAsync = (id: string, body: CreateEditingContractAgreementModel) => {
  return http.post(`${api}/${id}/editing`, body);
};

const onRejectAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };
  return await http.put(`${api}/${id}/acceptor-reject`, body);
};

const approveStep4Async = (id: string, remark: string) => {
  const request = {
    remark: remark
  };
  return http.put(`${api}/${id}/acceptor-approve`, request);
};

const deleteContractAgreementAsync = async (id: string) =>
  await http.delete(`${api}/${id}`);

const onRejectJorPorCommentAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };
  return await http.put(`${api}/${id}/jorpor-comment-acceptor-reject`, body);
};

const onApproveJorPorCommentAsync = (id: string, remark: string) => {
  const request = {
    remark: remark
  };
  return http.put(`${api}/${id}/jorpor-comment-acceptor-approve`, request);
};

const updateJorPorCommentAsync = (id: string, body: UpdateJorPorCommentModel) => {
  return http.put(`${api}/${id}/jorpor-comment`, body);
};

const deleteContractAgreementJorPorCommantAsync = async (id: string) =>
  await http.delete(`${api}/delete-jorpor-commant/${id}`);

const onReCallAsync = (id: string) => http.put(`${api}/${id}/recall-jor-por-comment`);

const CM02Service = {
  onRejectAsync,
  getListDraftContractMangementAsync,
  getContractByIdAsync,
  updateContractStep1Async,
  updateContractEntrepreneurStep1Async,
  UpdateContractCoiWatchListEgpStep4Async,
  updateContractStep2Async,
  updateContractWorkPhaseStep2Async,
  updateInspectorStep3Async,
  approveStep3Async,
  rejectStep3Async,
  updateAcceptorStep4Async,
  approveStep4Async,
  rejectStep4Async,
  updateDocumentBoxNumberStep4Async,
  updateContractDateStep5Async,
  getDocumentAsync,
  uploadAttachmentConfidentialAsync,
  uploadAttachmentAsync,
  getAttachmentAsync,
  removeFileAsync,
  updateDocumentByIdAsync,
  getCountStatusAsync,
  getCountSupplyMethodAsync,
  uploadAttachmentArchiveAsync,
  getDefaultInspectorsAsync,
  getHistoryAsync,
  uploadAttachmentDocumentAsync,
  getAttachmentDocumentAsync,
  removeFileDocumentAsync,
  getContractSignDocumentAsync,
  getDocumentPDPAAsync,
  updateContractSignDocumentByIdAsync,
  updateDocumentPDPAByIdAsync,
  uploadAttachmentEntrepreneurTypeAsync,
  getDocumentByTypeAsync,
  getDocumentAttachmentByDocIdAsync,
  updateContractMAInfoStep2Async,
  createEditingContractAgreementAsync,
  deleteContractAgreementAsync,
  getDocumentChangeContractAsync,
  updateContractDocumentChangeByIdAsync,
  getContractJorPorCommentAsync,
  createJorPorCommentAsync,
  updateJorPorCommentAsync,
  onRejectJorPorCommentAsync,
  onApproveJorPorCommentAsync,
  deleteContractAgreementJorPorCommantAsync,
  updateDefaultUploadAppendixFileAsync,
  onReCallAsync
};

export default CM02Service;
