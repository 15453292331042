import { Check } from 'components';
import { Button } from 'react-bootstrap';
import { MdCircle } from 'react-icons/md';

interface Props {
  onClick?: () => void;
  textNote1: string;
  textNote2: string;
}

export function TabsFilter(props: Props) {
  return (
    <div className='bg-light rounded  bg-opacity-25  tabs-filter'>
      <p className='ms-2 mb-0'> จำนวนสัญญา(ฉบับ):</p>
      {/* <div className='d-flex gap-2 align-items-center text-nowrap group-check'> */}
      <Check className='mb-0 check-item'
        labelNotbtn={<div className='align-items-center d-flex'>ทั้งหมด <span className='text-primary mx-2'>10</span>
        </div>}
      />
      <Check className='mb-0 check-item'
        labelNotbtn={<div className='align-items-center d-flex'>ซื้อขาย <span className='text-primary mx-2'>2</span>
        </div>}
      />
      <Check className='mb-0 check-item'
        labelNotbtn={<div className='align-items-center d-flex'>จ้าง<span className='text-primary mx-2'>0</span></div>}
      />
      <Check className='mb-0 check-item'
        labelNotbtn={<div className='align-items-center d-flex'>เช่า<span className='text-primary mx-2'>4</span></div>}
      />
      {/* </div>
      <div className='d-flex gap-2 align-items-center'> */}
      <p className='mb-0'>หมายเหตุ : <MdCircle className='mx-2 text-success' />{props.textNote1}
        <MdCircle className='mx-2 text-danger' />{props.textNote2}</p>
      <Button className='m-0 py-0'
        onClick={props.onClick}>ตารางสรุปการทำสัญญา</Button>
      {/* </div> */}
    </div>
  );
}
