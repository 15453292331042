import {
  Modal,
  Status,
  StatusType,
  Table,
} from 'components';

import Title from 'components/Controls/Title';
import { HistoryModel } from 'models/CM/CM02Models';
import {
  useEffect,
  useState,
} from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import CM02Service from 'services/CM/CM02Service';
import {
  fullDatetime,
  HttpStatusCode,
} from 'utils';

interface Props {
  show: boolean;
  onHide?: () => void;
  title?: string;
  id: string;
}

export function HistoryModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [history, setHistory] = useState<HistoryModel[]>([]);
  const [statusType, SetStatusType] = useState('');
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (props.id && props.show) {
      getHistoryAsync(page, size);
    }
  }, [props.id, props.show]);

  const getHistoryAsync = async (page: number, size: number) => {
    const response = await CM02Service.getHistoryAsync(props.id, page, size);

    if (response.status === HttpStatusCode.OK) {
      setHistory(response.data.data);
      setTotal(response.data.totalRecords);
    }
  };

  const handleSetPagination = (page: number, size: number) => {
    getHistoryAsync(page, size);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size='xl'
    >
      <Modal.Header>
        <Title
          text={props.title}
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <ModalBT.Body className='p-0 ps-4'>
        <Table className='my-3'
          total={total}
          onChange={(size, page) => {
            handleSetPagination(page, size);
          }}>
          <thead>
          <tr>
            <th>วันที่/เวลา</th>
            <th>ผู้ดำเนินการ</th>
            <th>การดำเนินการ</th>
            <th>สถานะการดำเนินการ</th>
            <th>หมายเหตุ</th>

          </tr>
          </thead>
          <tbody>
          {
            history.map((data, index) => (
              <tr key={data.id}>
                <td className='text-center'>{fullDatetime(data.date)}</td>
                <td>{data.fullName}</td>
                <td>{data.action}</td>
                <td className='text-center'>
                  <Status type={StatusType.DRAFT_CONTRACT}
                      value={data.status} />
                </td>
                <td>{data.remark}</td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
