import {
  StatusCM,
  Table,
} from 'components';
import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';

interface Data {
  id: number;
  fullName?: string;
  positionName?: string;
  departmentName?: string;
  delegateFullName?: string;
  delegateDepartmentName?: string;
  delegatePositionName?: string;
  status?: string;
  isAvailableRemark?: string;
  acceptRemark?: string;
  rejectRemark?: string;
  acceptDate?: string;
}

interface Props {
  data: Data[]
}

export function TableStepBar(props: Props) {
  return (
    <Table
      hidePagination
      className="mt-3 step-bar"
      notResponse
    >
      <thead>
        <tr>
          <th style={{ width: 20 }}>ลำดับ</th>
          <th className="text-start" style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
          <th className="text-start" style={{ width: '30%' }}>ผู้ปฏิบัติงานแทน</th>
          <th style={{ width: 100 }}>สถานะ</th>
          <th style={{ width: 100 }}>วันที่เห็นชอบ</th>
          <th style={{ width: 100 }} />
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <>
            <tr>
              <td className='text-center'>{index + 1}</td>
              <td className='text-left'>
                {data.fullName ? data.fullName : 'Nattaporn SiriTung'}
                <p className="m-0 department">
                  {data.positionName ? data.positionName : 'ผู้อำนวยการฝ่าย'}
                  {/* <span className='mx-1'>|</span>{data.departmentName ? data.departmentName : 'จัดหาและการพัสดุ'} */}
                </p>
              </td>
              <td className='text-left'>
                {data.delegateFullName ? data.delegateFullName : 'KAKAK DDAGAG'}
                <p className="m-0 department">
                  {data.delegateDepartmentName ? data.delegateDepartmentName : 'ผู้อำนวยการ'}
                  {/* <span className='mx-1'>|</span>{data.delegatePositionName ? data.delegatePositionName : 'จัดหาและการพัสดุ'} */}
                </p>
              </td>
              <td className='text-center'>
                {data.status && <StatusCM systemStatue='Approve' systemName='cm-03' />}
              </td>
              <td className='text-center'>
                {data.acceptDate ? data.acceptDate : '11/07/2566 18:09:32'}
              </td>
              <td className='text-end'>
                <Button variant='danger'><FaTrash /></Button>
              </td>
            </tr>
            <tr>
              <td />
              <td colSpan={6} className='p-0'>
                <ul className='step-bar-list'>
                  <li>
                    <div className="description">
                      <p className='mb-0 text-primary'>{data.isAvailableRemark ? data.isAvailableRemark : 'ส่งกลลับแก้ไข'}</p>
                      <p className='mb-0'>
                        <span className='text-muted me-2'>{data.acceptDate ? [data.acceptDate] : '[26/07/2566 18:09:32]'}</span>
                        ไม่เห็นด้วย
                      </p>
                    </div>
                  </li>

                  <li>
                    <div className="description">
                      <p className='mb-0 text-primary'>{data.acceptRemark ? data.acceptRemark : 'หมายเหตุไม่สามารถปฏิบัติงาน'}</p>
                      <p className='mb-0'>
                        <span className='text-muted me-2'>{data.acceptDate ? [data.acceptDate] : '[26/07/2566 18:09:32]'}</span>
                        เห็นด้วยครับ
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="description">
                      <p className='mb-0 text-primary'>{data.rejectRemark ? data.rejectRemark : 'หมายเหตุอนุมัติ'}</p>
                      <p className='mb-0'>
                        <span className='text-muted me-2'>{data.acceptDate ? [data.acceptDate] : '[26/07/2566 18:09:32]'}</span>
                        เห็นด้วยครับ
                      </p>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
          </>
        ))}
      </tbody>
    </Table>
  )
}
