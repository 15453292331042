import StepProgress from 'components/StepProgress';
import {
  CreateTor,
  DeliveryPeriods,
  Financials,
  FineRates,
  Objectives,
  Parcels,
  PaymentTerms,
  Qualifications,
} from 'models';
import {
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useLoaderData } from 'react-router-dom';
import TORDetailStep1 from './step1';
import TORDetailStep2 from './step2';
import TORDetailStep3 from './step3';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

interface Stepper {
  title: JSX.Element;
  displayIndex: string;
  child: JSX.Element;
}

type Loader = { torDetail: CreateTor };

export const CreateTorContext = createContext({} as CreateTor);

function PP0204TORDetail() {
  const { torDetail } = useLoaderData() as Loader;
  const childRef = useRef<ProgressBarRefType>(null);
  const [createTorData, setCreateTorData] = useState<CreateTor>({
    objectives: [{} as Objectives],
    parcels: [{} as Parcels],
    qualifications: [{} as Qualifications],
    deliveryPeriods: [{} as DeliveryPeriods],
    financials: [{} as Financials],
    paymentTerms: [{} as PaymentTerms],
    fineRates: [{} as FineRates],
  } as CreateTor);

  useEffect(() => {
    if (torDetail) {
      setCreateTorData(torDetail);
    }
  }, []);

  const setDataFromFisrtStep = (data: CreateTor) => {
    if (data) {
      if (torDetail) {
        setCreateTorData({ ...torDetail, title: data.title, templateId: data.templateId });
      } else {
        setCreateTorData({ ...data, title: data.title, templateId: data.templateId });
      }
    }

    clickNext();
  };

  const setDataFromSecondStep = (data: CreateTor) => {
    if (data) {
      setCreateTorData(data);
    }

    clickNext();
  };

  const [stepper] = useState<Stepper[]>([{
    title: <p>ข้อมูลร่างขอบเขตของงาน</p>,
    displayIndex: '1',
    child: <TORDetailStep1 onClickNext={(data) => setDataFromFisrtStep(data)} />,
  },
  {
    title: <p>ข้อมูลร่างขอบเขตของงาน</p>,
    displayIndex: '2',
    child: <TORDetailStep2 onClickNext={(data) => setDataFromSecondStep(data)} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>ตัวอย่างร่างขอบเขตงาน (TOR)</p>,
    displayIndex: '3',
    child: <TORDetailStep3 onClickBack={() => clickBack()} />,
  }]);

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  return (
    <CreateTorContext.Provider value={createTorData}>
      <div className="document">
        <p className="text-center fs-4">
          ร่างขอบเขตของงาน (TOR)
        </p>
        <Row>
          <Col xs={12} className="mb-2">
            <StepProgress progressBarData={stepper} ref={childRef} />
          </Col>
        </Row>
      </div>
    </CreateTorContext.Provider>
  );
}

export default PP0204TORDetail;
