import { LoaderFunctionArgs } from 'react-router-dom';
import {
  approvePlan,
  department,
  shareValue,
} from 'services';
import { PlanStatusText } from 'utils';

export const pl0301Loader = async () => {
  const response = await approvePlan.getApproveListAsync(1, 10);
  const countStatus = await approvePlan.getApprovePlanCountStatusAsync();

  return {
    approveList: response.data,
    countStatus: countStatus.data,
  };
};

export const pl0301DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const { planStatus } = params;

  const [departmentList, supplyMethodTypeList, supplyMethodList] = await Promise.all([
    await department.getDepartmentsAsync(),
    await shareValue.getSupplyMethodTypeListAsync(),
    await shareValue.getSupplyMethodListAsync(),
  ]);

  if (id) {
    if (planStatus === PlanStatusText.ChangePlan) {
      const changePlanResponse = await approvePlan.getApproveChangePlanRequestAsync(id);

      return {
        changePlanData: changePlanResponse.data,
        department: departmentList.data,
        supplyMethodType: supplyMethodTypeList.data,
        supplyMethod: supplyMethodList.data,
      };
    }
    const planResponse = await approvePlan.getApprovePlanAsync(id);

    return {
      planData: planResponse.data,
      department: departmentList.data,
      supplyMethodType: supplyMethodTypeList.data,
      supplyMethod: supplyMethodList.data,
    };
  }
};
