import { styled } from '@stitches/react';
import { Check } from 'components';
import { FileValue } from 'models/CollectAttachments/CollectAttachmentsModel';
import { UploadFile } from 'pages/CollectAttachments/UploadFile';
import { useCallback, useEffect } from 'react';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { MdCircle } from 'react-icons/md';
import { PP0205Service, jorPor05, jorPor06, planProcurement } from 'services';
import CM01Service from 'services/CM/CM01Service';
import CM02Service from 'services/CM/CM02Service';
import CM03Service from 'services/CM/CM03Service';
import CM06Service from 'services/CM/CM06Service';
import MedianPriceService from 'services/PP/MedianPriceService';
import PreProcurementServices from 'services/PP/PreProcurementServices';
import InvitationServices from 'services/PR/InvitationServices';
import PurchaseOrderApprovalServices from 'services/PR/PurchaseOrderApprovalServices';
import { HttpStatusCode, downloadFile } from 'utils';

interface Props {
    data: any[];
    name1?: string;
    name2?: string;
}

enum types {
    plan = 'Plan',
    appoint = 'Appoint',
    tor = 'Tor',
    medianPrice = 'MedianPrice',
    jorPor04 = 'JorPor04',

    jorPor05 = 'JorPor05',
    invite = 'Invite',
    jorPor06 = 'JorPor06',
    contractRecord = 'ContractRecord',

    contractAgreementInvitation = 'ContractAgreementInvitation',
    contractAgreement = 'ContractAgreement',

    contractManagement = 'ContractManagement',
    deliveryManagement = 'DeliveryManagement',
    paidPhaseManagement = 'PaidPhaseManagement',
    receiveManagement = 'ReceiveManagement',

    contractReturnCollateral = 'ContractReturnCollateral',
    returnCollateral = 'ReturnCollateral',
    returnCollateralConsider = 'ReturnCollateralConsider',
    requestApproval = 'RequestApproval',
    receiveCollateral = 'ReceiveCollateral',
    personalContractReturn = 'PersonalContractReturn',
    personalRequestApproval = 'PersonalRequestApproval',
    personalReceiveCollateral = 'PersonalReceiveCollateral',
}

export function TabFiles(props: Props) {

    useEffect(() => {
        console.log(props.data)
    }, [props.data]);

    const files = useCallback((fileData: any[]) => {
        const files = fileData?.filter((d: { fileId: any; fileName: any; }) => d.fileId && d.fileName);
        return files?.map((f: { fileId: any; fileName: any; createdById: any; createdByName: any; programId: any; subProgram: any; }) => ({
            documentId: f.fileId,
            name: f.fileName,
            createdByUserId: f.createdById,
            createdByUserFullName: f.createdByName,
            programId: f.programId,
            subProgram: f.subProgram
        })) as FileValue[];
    }, []);

    const downloadFileAsync = async (index: number, documentId?: string, program?: string, id?: string, subProgram?: string, programId?: string) => {
        if (documentId) {
            if (program === types.plan) {
                const res = await PreProcurementServices.getAppointmentDocumentAsync(programId!, documentId);
                if (res.status === HttpStatusCode.OK) {
                    downloadFile(res.data);
                }
            }
            if (program === types.appoint || program === types.tor) {
                const res = await PreProcurementServices.getAppointmentDocumentAsync(programId!, documentId);
                if (res.status === HttpStatusCode.OK) {
                    downloadFile(res.data);
                }
            }
            if (program === types.medianPrice) {
                const res = await MedianPriceService.getDownloadAttachmentAsync(programId!, documentId);
                if (res.status === HttpStatusCode.OK) {
                    downloadFile(res.data);
                }
            }
            if (program === types.jorPor04) {
                const res = await PP0205Service.downloadFileAsync(programId!, documentId);
                if (res.status === HttpStatusCode.OK) {
                    downloadFile(res.data);
                }
            }
            if (program === types.jorPor05) {
                const res = await jorPor05.downloadFileAsync(programId!, documentId);
                if (res.status === HttpStatusCode.OK) {
                    downloadFile(res.data);
                }
            }
            if (program === types.invite) {
                const res = await InvitationServices.getInviteDocumentAsync(documentId);
                if (res.status === HttpStatusCode.OK) {
                    downloadFile(res.data);
                }
            }
            if (program === types.jorPor06) {
                const res = await jorPor06.downloadJorPor06FileAsync(programId!, documentId);
                if (res.status === HttpStatusCode.OK) {
                    downloadFile(res.data);
                }
            }
            if (program === types.contractRecord) {
                const res = await PurchaseOrderApprovalServices.getContractRecordDocumentAsync(programId!, documentId);
                if (res.status === HttpStatusCode.OK) {
                    downloadFile(res.data);
                }
            }
            if (program === types.contractAgreementInvitation) {
                const res = await CM01Service.getAttachmentAsync(programId!, documentId);
                if (res.status === HttpStatusCode.OK) {
                    downloadFile(res.data);
                }
            }
            if (program === types.contractAgreement) {
                const res = await CM02Service.getAttachmentAsync(programId!, documentId);
                if (res.status === HttpStatusCode.OK) {
                    downloadFile(res.data);
                }
            }
            if (program === types.contractManagement) {
                if (subProgram === types.deliveryManagement) {
                    const res = await CM03Service.downloadDeliveryAttachmentAsync(programId!, documentId);
                    if (res.status === HttpStatusCode.OK) {
                        downloadFile(res.data);
                    }
                }
                if (subProgram === types.paidPhaseManagement) {
                    const res = await CM03Service.downloadPaidPhaseAttachmentAsync(programId!, documentId);
                    if (res.status === HttpStatusCode.OK) {
                        downloadFile(res.data);
                    }
                }
                if (subProgram === types.receiveManagement) {
                    const res = await CM03Service.getAttachmentReceiveAsync(programId!, documentId);
                    if (res.status === HttpStatusCode.OK) {
                        downloadFile(res.data);
                    }
                }
            }
            if (program === types.contractReturnCollateral || program === types.personalContractReturn) {
                if (subProgram === types.returnCollateral) {
                    const res = await CM06Service.getAttachmentFileReturnCollateralAsync(programId!, documentId);
                    if (res.status === HttpStatusCode.OK) {
                        downloadFile(res.data);
                    }
                }
                if (subProgram === types.returnCollateralConsider) {
                    const res = await CM06Service.getDownloadAttachmentConsiderAsync(programId!, documentId);
                    if (res.status === HttpStatusCode.OK) {
                        downloadFile(res.data);
                    }
                } if (subProgram === types.requestApproval || subProgram === types.personalRequestApproval) {
                    const res = await CM06Service.downloadContractReturnCollateralRequestAttachmentAsync(programId!, documentId);
                    if (res.status === HttpStatusCode.OK) {
                        downloadFile(res.data);
                    }
                } if (subProgram === types.receiveCollateral || subProgram === types.personalReceiveCollateral) {
                    const res = await CM06Service.getAttachmentReceiveAsync(programId!, documentId);
                    if (res.status === HttpStatusCode.OK) {
                        downloadFile(res.data);
                    }
                }
            }
        }
    };

    return (
        <>
            <Col className='tab-border-active' style={{ paddingLeft: 50 }}>
                <Tabs
                    defaultActiveKey='1'
                    id='justify-tab-example'
                    className='mb-2'
                >
                    {
                        props.data?.map((item, index) => (
                            <Tab eventKey={index + 1}
                                title={<p className='text-start'>
                                    {/* <b>{`${props.name1 && item.appointNumber ? `${props.name1} : ` : ''}`}</b>
                                    {`${item.appointNumber ? item.appointNumber : ''}`}
                                    <br /> */}
                                    <b>{`${props.name2 && item.number ? `${props.name2} : ` : ''}`}</b>
                                    {item.number ? item.number : ''}</p>}
                            >
                                <div className='mt-4 gap-3' style={{ marginLeft: 20, marginRight: 20 }}>
                                    <UploadFile
                                        value={files(item.files)}
                                        onDownload={downloadFileAsync}
                                        program={item.program}
                                    />
                                </div>
                            </Tab>
                        ))
                    }
                </Tabs>
            </Col >
        </>
    );
}