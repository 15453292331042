import { Breadcrumb } from 'components';
import StepProgress, { ProgressBarRefType } from 'components/StepProgress';
import {
  IFile,
  ItemModel,
} from 'models';
import { AnnualPlanModel } from 'models/PL';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLoaderData } from 'react-router';
import Step1 from './Step1';
import Step2 from './Step2';

const BREADCRUMB_INFO = [
  {
    routerName: '/pl/pl0201', label: 'จัดทำแผน',
  },
  {
    routerName: '', label: 'รายละเอียดแผน',
  },
];

type ContextType = {
  data: AnnualPlanModel,
  setData: Dispatch<React.SetStateAction<AnnualPlanModel>>,
  positionLists: ItemModel[],
  setFiles: Dispatch<React.SetStateAction<IFile[]>>,
  files: IFile[],
}

type Loader = {
  planData: AnnualPlanModel,
  positionList: ItemModel[],
};

export const Context = createContext({} as ContextType);

export default function PL0201Detail() {
  const progressBarRef = useRef({} as ProgressBarRefType);
  const { planData, positionList } = useLoaderData() as Loader;
  const [data, setData] = useState<AnnualPlanModel>({} as AnnualPlanModel);
  const [positionLists, setPositionLists] = useState<ItemModel[]>([]);
  const [isMount, setMount] = useState<boolean>(false);
  const [files, setFiles] = useState<IFile[]>([]);

  useEffect(() => {
    setData(planData);
    setMount(true);
    setPositionLists(positionList);
  }, []);

  const stepData = [
    {
      title: <p>ข้อมูลแผน</p>,
      displayIndex: '1',
      child: <Step1
        back={() => progressBarRef.current.clickBack()}
        next={() => { progressBarRef.current.clickNext(); }}
      />,
    },
    {
      title: <p>อำนาจอนุมัติ</p>,
      displayIndex: '2',
      child: <Step2
        back={() => progressBarRef.current.clickBack()}
      />,
    },
  ];

  return (
    isMount ? (
      <>
        <div>
          <h4 className="mt-2 mb-0 text-primary text-start">รายละเอียดแผน</h4>
          <Breadcrumb data={BREADCRUMB_INFO} />
        </div>
        <Context.Provider value={{ data, setData, positionLists, setFiles, files }}>
          <StepProgress
            progressBarData={stepData}
            ref={progressBarRef}
          />
        </Context.Provider>
      </>
    ) : <></>
  );
}
