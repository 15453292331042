interface Props {
  text?: string;
  className?: string;
  extraElement?: JSX.Element;
  confidentiality?: Boolean;
  hasPrefix?: Boolean;
  lineBorder?: Boolean;
}

function Title({
  text,
  className,
  extraElement,
  confidentiality,
  hasPrefix = true,
  lineBorder,
}: Props) {
  return (
    <div className={`d-flex justify-content-between title-head ${lineBorder && 'line-border'}`}>
      <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
        {
          hasPrefix && <div className='parallelogram' />
        }
        <p className='fw-bold mb-0 fs-5'>{text}</p>
        {
          confidentiality &&
          <p className='fw-bold mb-0 text-primary fs-5'>(เอกสารส่วนบุคคล)</p>
        }
      </div>
      {extraElement && <div className='d-flex align-items-center gap-3 bg-white'> {extraElement}</div>}
    </div>
  );
}

export default Title;
