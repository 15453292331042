import {
  Card,
  DatePicker,
  Input,
  ListFile,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลราคากลาง" className="fs-5 text-primary" />
        <Row className="mt-3">
          <Col sm={12} md={4} xl={3}>
            <DatePicker label="ค้นหาด้วยวันที่เริ่มต้น" disabled />
          </Col>
          <Col sm={12} md={4}>
            <Input label="เป็นจำนวนเงิน" value="1,000,000.00" disabled textEnd rule={{ required: true }} />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="แหล่งที่มาของราคากลาง (ราคาอ้างอิง)" className="fs-5 text-primary" />
        <Table className="mt-3">
          <thead>
            <tr>
              <th style={{ minWidth: 10 }}>ลำดับ</th>
              <th style={{ minWidth: 350 }}>รายละเอียด</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 5 }).map((_, index) => (
              <tr key={index}>
                <td className="text-center">1</td>
                <td className="text-start">ออกแบบและพัฒนาระบบงานตามสถาปัตยกรรมแบบ Micro Service ให้สามารถใช้งานร่วมกับระบบฐานข้อมูลคำนวณราคากลางงาน</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} lg={6}>
            <ListFile InameFile="อัตราค่าใช้จ่ายอื่นๆ ปี 65/2.doc" IsizeFile="123KB" />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default Step2;
