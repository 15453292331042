export const enum PreProcumentFlag {
  Main = 'Main',
  Editing = 'Editing',
  Cancel = 'Cancel',
  IsEditing = 'IsEditing',
  IsCanceling = 'IsCanceling'
}

export const enum PreProcumentEditCancelStatusConstant {
  Canceling = 'อยู่ระหว่างขอยกเลิก',
  Editing = 'อยู่ระหว่างขอแก้ไข'
}

export const enum PlanEditCancelStatusConstant {
  Canceling = 'อยู่ระหว่างขอยกเลิก',
  Editing = 'อยู่ระหว่างขอเปลี่ยนแปลง'
}

export const enum CriteriaSupplyMethodId {
  MethodId60 = '342e4e0b-7682-48c0-b46a-97a066908c9a',
  MethodId80 = 'a21603e4-338d-486c-85ba-5f1cac8f6ace'
}

export const enum CriteriaSupplyMethod {
  Method60 = 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560',
  Method80 = 'ข้อบังคับธนาคาร 80'
}

export const enum PreProcumentStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled'
}

export enum ApproverAppointmentStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected'
}

export const enum RefBankOrder {
  RefBankOrder72 = '72',
  RefBankOrder159 = '159',
  RefBankOrder216 = '216',
  RefBankOrder285 = '285',
  RefBankOrder528 = '528',
}
