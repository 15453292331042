// import { Modal } from "./Modal";
import {
  Modal,
  Selector,
  Table,
} from 'components';
import {
  DepartmentListResponse,
  ItemModel,
  Role,
  UserResponse,
} from 'models';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { useLoaderData } from 'react-router';
import {
  account,
  department,
  role,
} from 'services';
import { HttpStatusCode } from 'utils';
import { useForm } from 'utils/useForm';

interface Props {
  show: boolean;
  onHide?: () => void;
  onSelectItem?: (id: string, name: string, position: string, department: string, index: number, sectionSequence?: number, inRefCode?: string) => void;
  sectionSequence?: number;
  inRefCode?: string;
}

interface FormInput {
  name: string;
  departmentId: string;
}

type Loader = {
  departmentDDL: ItemModel[],
};

export function UserApproveModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [departments, setDepartments] = useState<DepartmentListResponse[]>([]);
  const [departmentId, setDepartmentId] = useState('');
  const [username, setUserName] = useState('');
  const { departmentDDL } = useLoaderData() as Loader;
  const [totalRecord, setTotalRecords] = useState(0);

  useEffect(() => {
    onGetAccountList('', '');
    onGetRoleList();
    getDepartmentsAsync();
  }, []);

  const onSelect = (id: string, name: string, position: string, department: string, index: number, sectionSequence?: number, inRefCode?: string) => {
    props.onSelectItem!(id, name, position, department, index, sectionSequence, inRefCode);
    props.onHide!();
  };

  const onGetRoleList = async () => {
    const res = await role.getRolesAsync(
      100,
      1,
      undefined,
    );
  };

  const getDepartmentsAsync = async () => {
    const { data, status } = await department.getDepartmentsAsync();

    if (status === HttpStatusCode.OK) {
      setDepartments(data);
    }
  };

  const onGetAccountList = async (name: string, depId: string, pageParam?: number, sizeParam?: number) => {
    const sizeValue = sizeParam || size;
    const pageValue = pageParam || page;

    const res = await account.getUsersAsync(
      sizeValue,
      pageValue,
      name !== '' ? name : undefined,
      undefined,
      undefined,
      depId !== '' ? depId : undefined,
    );
    if (res.status === HttpStatusCode.OK) {
      const usersData: UserResponse[] = res.data.data;
      setUsers(usersData);
      setTotalRecords(res.data.totalRecords);
    }
  };

  function clearFormSearch() {
    handleClear();
    setDepartmentId('');
  }

  const { handleSubmit, handleChange, data: formData, errors, initFormData, handleClear } = useForm<FormInput>({
    initialValues: {
      name: '',
      departmentId: '',
    },
    validations: {},
    onSubmit: async () => {
      onGetAccountList(formData.name, departmentId);
    },
  });

  const handleSetPagination = (page: number, size: number) => {
    onGetAccountList(formData.name, departmentId, page, size);
  };

  return (
    <Modal
      show={props.show}
      size="xl"
      onHide={() => (props.onHide ? props.onHide() : null)}
    >
      <ModalBT.Header closeButton>
        <ModalBT.Title>รายชื่อ</ModalBT.Title>
      </ModalBT.Header>
      <ModalBT.Body className="p-0 ps-4">
        <Form onSubmit={handleSubmit} id="userModalForm">
          <Row className="mt-3 align-items-center">
            <Col sm={12} md={4} xl={4}>
              <Form.Label>ชื่อ-นามสกุล</Form.Label>
              <Form.Group className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={formData.name || ''}
                    onChange={handleChange<string>('name', (value) => value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={12} md={4} xl={4}>
              <Selector
                label="ฝ่าย/สำนัก"
                items={departmentDDL}
                value={`${departmentId}`}
                onChange={(value) => (setDepartmentId(value.toString()))}
              />
            </Col>
          </Row>
          <div className="d-flex gap-2">
            <Button variant="primary" className="px-3" type="submit" form="userModalForm">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              className="px-4"
              variant="light"
              onClick={clearFormSearch}
              type="submit"
              form="userModalForm"
            >
              ล้าง
            </Button>
          </div>
        </Form>
        <Table
          className="my-3"
          total={totalRecord}
          onChange={(size, page) => {
            handleSetPagination(page, size);
          }}
        >
          <thead>
            <tr>
              <th style={{ minWidth: 20 }}>ลำดับ</th>
              <th style={{ minWidth: 50 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 50 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
              <th style={{ minWidth: 25 }} />
            </tr>
          </thead>
          <tbody>
            {
              users.map((data, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{data.name}</td>
                  <td className="text-center">{data.department}</td>
                  <td className="text-center">{data.position} </td>
                  <td>
                    <Button variant="primary" className="px-3" onClick={() => onSelect(data.id, data.name, data.position, data.department, index + 1, props.sectionSequence, props.inRefCode)}>
                      เลือก
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
