import { JorPorCode } from 'constant/basicInformation';
import {
  DepartmentListResponse,
  ItemModel,
} from '../../models';
import { department } from '../../services';
import { HttpStatusCode } from '../../utils';
import toast from '../../utils/toast';

export const suLoader = async () => {
  const departmentDropDownList = await getDepartmentData();
  const departmentWithOutJorPorDropDownList = await getDepartmentWithOutJorPorData();
  const departmentJorPorDropDownList = await getDepartmentJorPorData();

  return {
    departmentDropDownList,
    departmentWithOutJorPorDropDownList,
    departmentJorPorDropDownList
  };
};

const getDepartmentData = async () => {
  const { data, status } = await department.getDepartmentsListAsync<DepartmentListResponse[]>();

  if (status !== HttpStatusCode.OK) {
    toast.error('ไม่สามารถดึงข้อมูล Department ได้');

    return null;
  }

  const newData = data?.map((d) => ({ value: d.id, label: d.name }));

  return newData as ItemModel[];
};

const getDepartmentWithOutJorPorData = async () => {
  const departmentJorPorCode = JorPorCode.CODE; // FIXME: ต้องเปลี่ยนเป็น 50004689 ในฐานข้อมูลจริง

  const { data, status } = await department.getDepartmentsListAsync<DepartmentListResponse[]>('', departmentJorPorCode);

  if (status !== HttpStatusCode.OK) {
    toast.error('ไม่สามารถดึงข้อมูล Department JorPor ได้');

    return null;
  }

  const newData = data?.map((d) => ({ value: d.id, label: d.name }));

  return newData as ItemModel[];
};

const getDepartmentJorPorData = async () => {
  const departmentJorPorCode = JorPorCode.CODE; // FIXME: ต้องเปลี่ยนเป็น 50004689 ในฐานข้อมูลจริง

  const { data, status } = await department.getDepartmentsListAsync<DepartmentListResponse[]>(departmentJorPorCode);

  if (status !== HttpStatusCode.OK) {
    toast.error('ไม่สามารถดึงข้อมูล Department JorPor ได้');

    return null;
  }

  const newData = data?.map((d) => ({ value: d.id, label: d.name }));

  return newData as ItemModel[];
};
