import {
  Approver,
  ApproverStatus,
  Card,
  Input,
  Modal,
  SearchBuNameModal,
  Table,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { PublicPlanSelectUserModal } from 'components/Modal/PublicPlanProcurementUserModal';
import { PublicProcurementRejectModal } from 'components/Modal/RejectPublicPlanProcurementModal';
import {
  ApproverModel,
  ItemModel,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
  onActionAnnoucementModel,
} from 'models';
import { sectionApproverModel } from 'models/delegateModel';
import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaPlus,
  FaRedo,
  FaSave,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
  MdRefresh,
} from 'react-icons/md';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router';
import {
  account,
  publicPlanAnnouncement as publicService,
  shareValue,
} from 'services';
import {
  fullDatetime,
  HttpStatusCode,
  submitForm,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import {
  AcceptorStatus,
  AnnouncementStatus as AnnouncementCons,
  AssignLevel,
} from 'utils/constants/PlanEnum';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { Context } from './Detail';
import { JorPorCode } from 'constant/basicInformation';
import { AccountModel } from 'models/accountModel';

interface Props {
  onClickBack: () => void;
  onClickNext: () => void;
}

type Loader = {
  departments: ItemModel[],
  positions: ItemModel[],
}

type sectionGroup = {
  name: string;
  sequence: number;
  level: number;
  inRefCode: string;
}

function Step2({ onClickBack, onClickNext }: Props) {
  const { userId, departmentCode } = useAppContext();
  const [show, setShow] = useState(false);
  const loader = useLoaderData() as Loader;
  const [showReject, setOnShowReject] = useState(false);
  const { currentStep, publicPlanProcurement, setPublicPlanProcurement, reGetDetail } = useContext(Context);
  const [section, setSection] = useState<string>();
  const [rejectRemark, setRejectRemark] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [showDefaultApproverModal, setShowDefaultApproverModal] = useState<boolean>(false);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const getUserProfileByIdAsync = async (userId: string) => {
    const { data, status } = await account.getUserByIdAsync(userId);

    if (status === HttpStatusCode.OK) {
      return data as AccountModel;
    }
  };

  const getUserDataAsync = async (userId: string, fullName: string, departmentName: string, positionName: string) => {
    if (publicPlanProcurement.approvers.filter(a => a.userId === userId && a.sectionType === section).length > 0) {
      toast.warn('ไม่สามารถระบุผู้มีอำนาจซ้ำได้');
      return;
    }

    if (!publicPlanProcurement.approvers) {
      publicPlanProcurement.approvers = [];
    }

    const acceptorBySection = publicPlanProcurement.approvers.filter((x) => x.sectionType === section);

    const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
      acceptorBySection.map((x) => {
        return x.sequence;
      })) : 0;

    setPublicPlanProcurement({
      ...publicPlanProcurement,
      approvers: [
        ...publicPlanProcurement.approvers,
        {
          id: '',
          userId: userId,
          fullName: fullName,
          departmentName: departmentName,
          positionName: positionName,
          sectionCode: 'Approver',
          status: AcceptorStatus.DRAFT,
          sequence: maxValueSequence + 1,
          sectionType: section,
          sectionSequence: sections.filter(x => x.inRefCode === section)[0].sequence,
        } as ApproverModel,
      ],
    });
  };

  useEffect(() => {
    if (currentStep === 2 || currentStep === 3) {
      onGetDefaultApprover();
    }
  }, [currentStep]);

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = Math.max.apply(null,
      publicPlanProcurement.approvers?.map((x) => {
        return x.sectionSequence;
      }));

    const valueSequence = publicPlanProcurement.approvers?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  useEffect(() => {
    onCheckApproverStatus();
  }, []);

  const onGetDefaultApprover = async () => {
    const sumBudget = publicPlanProcurement.planProcurements.reduce((a, v) => a += v.budget, 0);
    const res = await account
      .getDefaultApprover(PROCESSTYPE.ApprovePlanProcess, sumBudget);
    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
      const newDatas = res.data as sectionApproverModel[];
      const sectionDatas: sectionGroup[] = [];
      const approver: ApproverModel[] = [];

      if (publicPlanProcurement.approvers === undefined ||
        publicPlanProcurement.approvers === null ||
        publicPlanProcurement.approvers.length === 0) {
        newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {
            sectionDatas.push({
              name: item.sectionName,
              level: item.sectionLevel,
              sequence: item.sectionSequence,
              inRefCode: item.inRefCode,
            } as sectionGroup);

            approver.push({
              id: undefined,
              sectionCode: 'Approver',
              userId: item.userId,
              fullName: item.fullName,
              departmentName: item.departmentName,
              positionName: item.positionName,
              status: AcceptorStatus.DRAFT,
              at: undefined,
              isAvailable: false,
              isAvailableRemark: '',
              acceptDate: undefined,
              acceptRemark: '',
              rejectDate: undefined,
              rejectRemark: '',
              sequence: index + 1,
              sectionType: item.inRefCode,
              sectionSequence: item.sectionSequence,
              delegateUserId: item.delegateUserId,
              delegateEmployeeCode: item.delegateEmployeeCode,
              delegateFullName: item.delegateFullName,
              delegateDepartmentName: item.delegateDepartmentName,
              delegatePositionName: item.delegatePositionName,
            } as ApproverModel);
          });

        const uniqueNames = sectionDatas.filter((data, index) => {
          return sectionDatas.findIndex(d => d.inRefCode === data.inRefCode) === index;
        });
        setSections(uniqueNames);
        setPublicPlanProcurement({
          ...publicPlanProcurement,
          approvers: approver,
        });
      } else {
        publicPlanProcurement.approvers.forEach((item, index) => {
          sectionDatas.push({
            name: item.sectionName,
            level: 0,
            sequence: item.sectionSequence,
            inRefCode: item.sectionType,
          } as sectionGroup);
        });

        const uniqueNames = sectionDatas.filter((data, index) => {
          return sectionDatas.findIndex(d => d.inRefCode === data.inRefCode) === index;
        });
        setSections(uniqueNames);
      }
    }
  };

  const formTableOnChange = (
    section: string,
    userId: string,
    key: string,
    value: string | boolean,
  ) => {
    const index = publicPlanProcurement.approvers.findIndex(a => a.sectionType === section && a.userId === userId);

    if (key === 'isAvailable') {
      publicPlanProcurement.approvers[index] = {
        ...publicPlanProcurement.approvers[index],
        isAvailable: Boolean(value),
        isAvailableRemark: '',
      };
    } else {
      publicPlanProcurement.approvers[index] = {
        ...publicPlanProcurement.approvers[index],
        [key]: value,
      };
    }

    setPublicPlanProcurement({
      ...publicPlanProcurement,
      approvers: publicPlanProcurement.approvers,
    });
  };

  const onSubmitAsync = async (AnnoucementStatus: string) => {
    if ((!publicPlanProcurement.approvers
      || publicPlanProcurement.approvers.length === 0)) {
      toast.warn('ต้องมีผู้มีอำนาจเห็นชอบ อย่างน้อย 1 คน');

      return;
    }

    const checkRequestField = publicPlanProcurement.year
      && publicPlanProcurement.supplyMethod
      && publicPlanProcurement.remark;

    if (!checkRequestField) {
      return;
    }
    publicPlanProcurement.status = AnnoucementStatus;

    const { data, status } = await publicService.updateAnnouncementAsync(publicPlanProcurement);
    if (status === HttpStatusCode.OK || status === HttpStatusCode.ACCEPTED) {
      publicPlanProcurement.id = data.id;
      reGetDetail(publicPlanProcurement.id);
      navigate(`${location.pathname}`);
      toast.success('บันทึกสำเร็จ');
    }
  };

  const onAssignApprover = () => {
    if (
      publicPlanProcurement.approvers.length
    ) {
      onClickNext();
    } else {
      toast.warn('ต้องมีผู้มีอำนาจเห็นชอบอย่างน้อย 1 คน');
    }
  };

  const removeUsers = (userId: string, inRefCode: string) => {
    const index = publicPlanProcurement.approvers.findIndex((p) => p.userId === userId && p.sectionType === inRefCode);

    publicPlanProcurement.approvers.splice(index, 1);

    setPublicPlanProcurement({
      ...publicPlanProcurement,
      approvers: [...publicPlanProcurement.approvers],
    });
  };

  const isJorporDirectorAssign = useMemo(() => {
    if (publicPlanProcurement?.jorporDirectorAssign?.filter((x) => x.level === AssignLevel.Level1).some((d) => isNull(d.delegateUserId, d.userId) === userId)) {
      return true;
    }

    return false;
  }, [publicPlanProcurement]);

  const isApprover = useMemo(() => {
    if (publicPlanProcurement.approvers?.some((s) => isNull(s.delegateUserId, s.userId) === userId)) {
      return true;
    }
    return false;
  }, [publicPlanProcurement]);

  const conditionCanAction = useMemo(() => {
    if (!Object.keys(publicPlanProcurement).length || currentStep !== 2 || !userId) {
      return false;
    }

    if (publicPlanProcurement.status !== AnnouncementCons.WaitingAccept) {
      return false;
    }

    const minSection = Math.min(...publicPlanProcurement.approvers
      .filter(d => d.status === AcceptorStatus.PENDING
        && !d.isAvailable)
      .map(o => o.sectionSequence));

    const sectionData = publicPlanProcurement.approvers
      .filter(d => d.sectionSequence === minSection
        && d.status === AcceptorStatus.PENDING
        && !d.isAvailable)
      .sort((a, b) => a.sequence - b.sequence)[0];

    if (sectionData) {
      return isNull(sectionData.delegateUserId, sectionData.userId) === userId;
    }

    return false;
  }, [userId, publicPlanProcurement]);


  function isNull(oldValue?: string, newValue?: string) {
    if (oldValue) {
      return oldValue;
    }

    return newValue;
  }

  const sectionCode = useMemo(() => {
    if (publicPlanProcurement.approvers?.some((s) => isNull(s.delegateUserId, s.userId) === userId)) {
      return publicPlanProcurement.approvers?.find((s) => isNull(s.delegateUserId, s.userId) === userId)?.sectionCode;
    }
  }, [publicPlanProcurement]);

  const onToggleModal = (state: boolean) => {
    if (!state) {
      setRejectRemark('');
    }
    setOnShowReject(state);
  };

  const onApproveorReject = async (action: string) => {
    if (action === AcceptorStatus.REJECT) {
      if (!rejectRemark) {
        submitForm();
        toast.warn('กรุณาระบุหมายเหตุการส่งกลับแก้ไข');
        return;
      }
    }
    if (!isApprover) {
      toast.warn('คุณไม่มีสิทธิ์ เห็นชอบ/อนุมัติ หรือ ส่งกลับแก้ไข');
      return;
    }
    const payload = {
      act: action,
      remark: rejectRemark,
      sectionCode,
    } as onActionAnnoucementModel;

    const { data, status } = await publicService.onActionAnnoucement(publicPlanProcurement.id, payload);
    if (status === HttpStatusCode.ACCEPTED) {
      reGetDetail(data.id);
      toast.success('บันทึกสำเร็จ');
      if (action === AcceptorStatus.REJECT) {
        setRejectRemark('');
        setOnShowReject(false);
      }
    }
  };

  const handlerSetDefaultApproverAsync = async () => {
    const sumBudget = publicPlanProcurement.planProcurements.reduce((a, v) => a += v.budget, 0);
    const res = await account
      .getDefaultApprover(PROCESSTYPE.ApprovePlanProcess, sumBudget);
    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
      const newDatas = res.data as sectionApproverModel[];
      const sectionDatas: sectionGroup[] = [];
      const approver: ApproverModel[] = [];

      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {
          sectionDatas.push({
            name: item.sectionName,
            level: item.sectionLevel,
            sequence: item.sectionSequence,
            inRefCode: item.inRefCode,
          } as sectionGroup);

          approver.push({
            id: undefined,
            sectionCode: 'Approver',
            userId: item.userId,
            fullName: item.fullName,
            departmentName: item.departmentName,
            positionName: item.positionName,
            status: AcceptorStatus.DRAFT,
            at: undefined,
            isAvailable: false,
            isAvailableRemark: '',
            acceptDate: undefined,
            acceptRemark: '',
            rejectDate: undefined,
            rejectRemark: '',
            sequence: index + 1,
            sectionType: item.inRefCode,
            sectionSequence: item.sectionSequence,
            delegateUserId: item.delegateUserId,
            delegateEmployeeCode: item.delegateEmployeeCode,
            delegateFullName: item.delegateFullName,
            delegateDepartmentName: item.delegateDepartmentName,
            delegatePositionName: item.delegatePositionName,
          } as ApproverModel);
        });

      const uniqueNames = sectionDatas.filter((data, index) => {
        return sectionDatas.findIndex(d => d.inRefCode === data.inRefCode) === index;
      });
      setSections(uniqueNames);

      setPublicPlanProcurement({
        ...publicPlanProcurement,
        approvers: approver,
      });

      setShowDefaultApproverModal(false);
      toast.success('กำหนดผู้มีอำนาจเห็นชอบตั้งต้น สำเร็จ');
    } else {
      toast.warn('กำหนดผู้มีอำนาจเห็นชอบตั้งต้น ไม่สำเร็จ');
    }
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title
          text='ผู้มีอำนาจเห็นชอบ/อนุมัติ'
          className='fs-5 text-primary'
        />
        <div className='mt-3'>
          {currentStep === 2 && (isJorporDirectorAssign
            && (publicPlanProcurement.status === AnnouncementCons.AssignJorPor
              || publicPlanProcurement.status === AnnouncementCons.Reject))
            ? (
              <Button
                className='d-flex align-items-center gap-2'
                variant='outline-primary'
                onClick={() => {
                  setShowDefaultApproverModal(true);
                }}
              >
                <FaRedo />กำหนดผู้มีอำนาจเห็นชอบเริ่มต้น
              </Button>
            )
            : null}
        </div>
        <Row className='gap-5'>
          {
            (sections && sections.length > 0) && (
              sections
                .sort((a, b) => a.sequence - b.sequence)
                .map((section, index) => (
                  <Col sm={12}
                    className='mt-4'
                    key={index}>
                    <div className='d-flex justify-content-between mb-1'>
                      <p className='fs-5 text-primary'>
                        {/* {section.sectionName} */}
                      </p>
                      {currentStep === 2 && (isJorporDirectorAssign && (publicPlanProcurement.status === AnnouncementCons.AssignJorPor || publicPlanProcurement.status === AnnouncementCons.Reject))
                        ? (
                          <Button
                            variant='outline-primary'
                            onClick={() => {
                              setSection(section.inRefCode);
                              setShow(true);
                            }}
                          >
                            <FaPlus />เพิ่มรายชื่อ
                          </Button>
                        )
                        : null}
                    </div>
                    <Table total={1} hidePagination className="mt-3 step-bar">
                      <thead>
                        <tr>
                          <th style={{ width: 20 }}>ลำดับ</th>
                          <th className="text-start">ชื่อ-นามสกุล</th>
                          <th className="text-start" style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน</th>
                          <th style={{ width: 100 }}>สถานะ</th>
                          <th style={{ width: 150 }}>วันที่เห็นชอบ</th>
                          <th style={{ width: 100 }} />
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (publicPlanProcurement && publicPlanProcurement.approvers && publicPlanProcurement.approvers.length > 0) && (
                            publicPlanProcurement?.approvers
                              .filter(a => a.sectionType === section.inRefCode)
                              .sort((a, b) => a.sequence - b.sequence)
                              .map((data, i) => (
                                <>
                                  <tr key={data.sequence}>
                                    <td className="text-center">{i + 1}</td>
                                    <td>
                                      {data.fullName}
                                      <p className="m-0 department">
                                        {data.positionName}
                                      </p>
                                    </td>
                                    <td className='text-left'>
                                      {data.delegateFullName &&
                                        <>
                                          {data.delegateFullName}
                                          <p className="m-0 department">
                                            {data.delegatePositionName}
                                          </p>
                                        </>
                                      }
                                    </td>
                                    <td className="text-center">
                                      {isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ?
                                        <Approver value={data.status}
                                          outline /> : <ApproverStatus value={data.status}
                                            outline />}
                                    </td>
                                    <td className="text-center">
                                      {data.status === AcceptorStatus.APPROVE &&
                                        <>{thaiFormatDateWithSlash(data.acceptDate)}</>
                                      }
                                    </td>
                                    <td>
                                      {publicPlanProcurement.status === AnnouncementCons.AssignJorPor
                                        || publicPlanProcurement.status === AnnouncementCons.Reject
                                        ? (
                                          <div className="d-flex justify-content-center align-items-center">
                                            <Button
                                              className="d-flex align-items-center gap-2"
                                              variant="danger"
                                              onClick={() => removeUsers(data.userId, section.inRefCode)}
                                              disabled={!isJorporDirectorAssign}
                                            >
                                              <FaTrashAlt />
                                            </Button>
                                          </div>
                                        ) : null}
                                    </td>
                                  </tr>
                                  {
                                    data.isAvailableRemark || data.acceptRemark || data.rejectRemark ?
                                      <tr className='border-bottom bg-opacity-10 bg-light'>
                                        <td />
                                        <td colSpan={5}
                                          className='p-0'>
                                          <ul className='step-bar-list'>
                                            {data.isAvailableRemark &&
                                              <li>
                                                <div className='description'>
                                                  <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                                  <p className='mb-0'
                                                    style={{ color: 'gray' }}>
                                                    {data.isAvailableRemark}
                                                  </p>
                                                </div>
                                              </li>
                                            }
                                            {data.acceptRemark &&
                                              <li>
                                                <div className='description'>
                                                  <p className='mb-0 text-primary'>หมายเหตุ{isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ? 'อนุมัติ' : 'เห็นชอบ'}
                                                  </p>
                                                  <p className='mb-0 text-wrap'
                                                    style={{ color: 'gray' }}>
                                                    <span className='text-muted me-2'>[{data.acceptDate ? fullDatetime(data.acceptDate) : ''}]</span>
                                                    {data.acceptRemark}
                                                  </p>
                                                </div>
                                              </li>
                                            }
                                            {data.rejectRemark &&
                                              <li>
                                                <div className='description'>
                                                  <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                                  <p className='mb-0 text-wrap'
                                                    style={{ color: 'gray' }}>
                                                    <span className='text-muted me-2'>[{data.rejectDate ? fullDatetime(data.rejectDate) : ''}]</span>
                                                    {data.rejectRemark}
                                                  </p>
                                                </div>
                                              </li>
                                            }
                                          </ul>
                                        </td>
                                      </tr>
                                      : <tr className='border-bottom' />
                                  }
                                </>
                              ))
                          )
                        }
                      </tbody>
                    </Table>
                  </Col>
                ))
            )
          }
        </Row>
      </Card>

      {currentStep === 2
        ? (
          <div className='d-flex justify-content-between pt-3'>
            <ButtonCustom
              onClick={onClickBack}
              text="ย้อนกลับ"
            />
            <div>
              {/* {publicPlanProcurement?.status === AnnouncementCons.WaitingAccept && conditionCanAction
                ? (
                  <ButtonCustom
                    text="ส่งกลับแก้ไข"
                    icon='undo'
                    className='me-2'
                    variant='danger'
                    onClick={() => onToggleModal(true)}
                  />
                )
                : <div />} */}
              {(publicPlanProcurement?.status === AnnouncementCons.AssignJorPor
                || publicPlanProcurement?.status === AnnouncementCons.Reject) && isJorporDirectorAssign
                ? (
                  <ButtonCustom
                    onClick={() => onSubmitAsync(publicPlanProcurement?.status)}
                    text="บันทึก"
                  />
                ) : null}
            </div>
            <ButtonCustom
              onClick={() => onAssignApprover()}
              text="ถัดไป"
            />
          </div>
        )
        : <></>}

      {/* <PublicPlanSelectUserModal
        show={show}
        onHide={() => setShow(!show)}
        onSelect={userOnSelect}
        section={section}
        departments={loader.departments}
        positions={loader.positions}
        data={publicPlanProcurement}
      /> */}

      <SearchBuNameModal
        total={userTotal}
        show={show}
        onHide={() => setShow(!show)}
        onSelect={(id, name, department, position) => getUserDataAsync(id, name, department!, position!)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <PublicProcurementRejectModal
        show={showReject}
        onReject={(val) => onApproveorReject(val)}
        remark={rejectRemark}
        setRejectRemark={setRejectRemark}
        onHide={() => onToggleModal(false)}
      />
      <Modal
        show={showDefaultApproverModal}
        size='lg'
        onHide={() => setShowDefaultApproverModal(!showDefaultApproverModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการกำหนดผู้มีอำนาจเห็นชอบเริ่มต้นใหม่หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDefaultApproverModal(!showDefaultApproverModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerSetDefaultApproverAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step2;
