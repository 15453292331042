import {
  Check,
  Input,
  InputTextArea,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Button,
  Card,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';

interface Props {
  onClickNext: () => void;
}

function step1({ onClickNext }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Card.Body>
          <Title text="ข้อมูลการแจ้งข้อมูลเบื้องต้น (จพ.004)" className="fs-5 text-primary" />
          <Row className="mt-3 align-items-center">
            <Col sm={12} md={6}>
              <Input label="เลขที่เอกสาร PR" value="เลขที่เอกสาร PR" />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col sm={12} md={6}>
              <Input label="โครงการ" value="059 / 2565 : โครงการ Digital Procurement ฝ่ายจัดหาและการพัสดุ" disabled />
            </Col>
            <Col sm={12} md={3}>
              <Button variant="link"><TbListSearch className="fs-4" /> ไปยังแผน</Button>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col sm={12} md={4} xl={3}>
              <Input label="งบประมาณโครงการ (บาท)" value="1,000,000.00" disabled textEnd />
            </Col>
            <Col sm={12} md={4} xl={3}>
              <Input label="วิธีจัดหา" value="80" disabled />
            </Col>
            <Col sm={12} md={4} xl={3} className="pt-3">
              <Input label="" value="เจาะจง" disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Input label="เรื่อง" rule={{ required: true }} />
            </Col>
            <Col sm={12}>
              <InputTextArea label="วัตถุประสงค์การขอซื้อ" rule={{ required: true }} />
            </Col>
            <Col sm={12}>
              <InputTextArea label="เหตุผลและความจำเป็นที่จะซื้อหรือจ้่างหรือเช่่า" rule={{ required: true }} />
            </Col>
            <Col sm={12}>
              <Input label="ราคากลาง" rule={{ required: true }} />
            </Col>
            <Col sm={12}>
              <Input label="วงเงินที่จะซื้อหรือจ้างหรือเช่า" rule={{ required: true }} />
            </Col>
            <Col sm={12}>
              <Input label="กำหนดระยะเวลาแล้วเสร็จของงาน" rule={{ required: true }} />
            </Col>
            <Col sm={12}>
              <div className="d-flex gap-2">หลักเกณฑ์การคัดเลือก<Check label="เกณฑ์ราคา" /></div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Title text="เอกสารแนบ" className="fs-5 text-primary" />
          <Row className="justify-content-center">
            <Col sm={12} md={6}>
              <UploadFile />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default step1;
