import {
  Card,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  Status,
  StatusType,
  UploadFile,
} from 'components';
import {
  IFile,
  ItemModel,
} from 'models/systemUtility';
import { useContext } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaAngleRight,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { submitForm } from 'utils';
import toast from 'utils/toast';
import { Context } from './Detail';

interface Props {
  onClickNext: () => void;
}

type Loader = {
  departmentDDL: ItemModel[],
  supplyMethodTypeDDL: ItemModel[],
  supplyMethodTypeNameDDL: ItemModel[],
}

export default function PL0204Data({ onClickNext }: Props) {
  const { data, setData, files, setFiles } = useContext(Context);
  const { departmentDDL, supplyMethodTypeDDL, supplyMethodTypeNameDDL } = useLoaderData() as Loader;
  const navigate = useNavigate();

  const handleFileChange = (files: File) => {
    const newFile: IFile = {
      id: '',
      name: files.name,
      file: files,
    };
    setFiles((pre) => [...pre, newFile]);
  };

  const onNext = () => {
    submitForm();
    if (data.departmentId && data.supplyMethod && data.supplyMethodName && data.planName && data.planBudget && data.expectingProcurementAt) {
      files.forEach((i) => {
        if (i.file !== null) {
          data.documents.push(i.file);
        }
      });
      onClickNext();
    } else {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
    }
  };

  return (
    <>
      {data.isCancel
        && (
        <Card>
          <div className="d-flex align-items-center gap-2">
            <div className="parallelogram" />
            <h4 className="text-primary m-0">ขอยกเลิกแผน</h4>
          </div>
          <div className="d-flex gap-2 mt-3">
            <InputTextArea
              label="สาเหตุที่ยกเลิก"
              rule={{ required: true }}
              value={data.cancelDescription}
              disabled
            />
          </div>
        </Card>
        )}
      <Card>
        <div className="d-flex gap-2 mt-4">
          <p>ข้อมูลแผน</p>
          <h5>
            <Status badge type={StatusType.PROJECT} value={data.planStatus} />
          </h5>
          <h5>
            <Status badge type={StatusType.PROCESS} value={data.status} />
          </h5>
        </div>
        <Row>
          <Col sm={6} md={6} lg={4} xl={3}>
            <Input
              label="เลขที่แผน"
              value={data.planNumber}
              disabled
            />
          </Col>
          <Col sm={6} md={6} lg={4} xl={3}>
            <Selector
              label="ฝ่าย/สำนัก"
              rule={{ required: true }}
              value={data.departmentId}
              onChange={(val) => setData({ ...data, departmentId: val.toString() })}
              items={departmentDDL}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={4} xl={3}>
            <Selector
              label="วิธีการจัดหา"
              rule={{ required: true }}
              value={data.supplyMethod}
              onChange={(val) => setData({ ...data, supplyMethod: val.toString() })}
              items={supplyMethodTypeDDL}
              disabled
            />
          </Col>
          <Col sm={6} md={6} lg={4} xl={3}>
            <Selector
              className="no-label"
              value={data.supplyMethodName}
              onChange={(val) => setData({ ...data, supplyMethodName: val.toString() })}
              items={supplyMethodTypeNameDDL}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col lg={8} xl={6}>
            <InputTextArea
              label="ชื่อโครงการ"
              rule={{ required: true }}
              value={data.planName}
              onChange={(val) => setData({ ...data, planName: val.toString() })}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={4} xl={3}>
            <Input
              label="งบประมาณโครงการ (บาท)"
              rule={{ required: true }}
              value={data.planBudget}
              onChange={(val) => setData({ ...data, planBudget: +val })}
              type="number"
              disabled
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <DatePicker
              label="ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง"
              value={data.expectingProcurementAt}
              onChange={(val) => setData({ ...data, expectingProcurementAt: val })}
              monthYearOnly
              rule={{ required: true }}
              disabled
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3}>
            <DatePicker
              label="วันที่ประกาศแผน"
              value={data.planAnnouncementDate}
              onChange={(val) => setData({ ...data, planAnnouncementDate: val })}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={4} xl={3}>
            <Input
              label="เลขที่แผน e-GP"
              value={data.planEGPNumber}
              onChange={(val) => setData({ ...data, planEGPNumber: val })}
              disabled
            />
          </Col>
        </Row>

        <p className="mb-0 mt-2">เอกสารแนบ</p>
        <div>
          <Row>
            <Col sm={12} className="mb-0 mt-4">
              <UploadFile
                // value={files}
                onChange={handleFileChange}
                // onRemove={removeItemAsync}
                disabled
              />
            </Col>
          </Row>
        </div>
      </Card>
      <div className="d-flex justify-content-between mt-4">
        <Button
          variant="outline-primary"
          className="d-flex align-items-center gap-2"
          onClick={() => navigate('/pl/pl0204')}
        >
          <FaAngleLeft />ย้อนกลับ
        </Button>
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={onNext}
        >
          ถัดไป<FaAngleRight />
        </Button>
      </div>
    </>
  );
}
