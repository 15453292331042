export const enum InvitationStatusConstant {
  None = 'None',
  Draft = 'Draft',
  WaitingForApprove = 'WaitingForApprove',
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
  Pending = 'Pending',
}

export const enum TraderModalTypeConstant {
  COI = 'Coi',
  WATCHLIST = 'WatchList',
  EGP = 'Egp'
}
