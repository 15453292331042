import {
  Card,
  Check,
  Input,
  InputTextArea,
  Status,
  StatusType,
  Table,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import { HistoryModal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaCheck,
  FaHistory,
  FaPlus,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { dataHistory } from '../data';

export default function Oligarch() {
  const [showHistory, setShowHistory] = useState(false);
  const [showPlan, setShowPlan] = useState(false);

  const navigate = useNavigate();

  const backPage = () => {
    navigate('/pr/pr0208/detail')
  }

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title
          text='ข้อมูลอ้างอิง'
          className='fs-5 text-primary'
          extraElement={
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant="outline-primary">
              <FaHistory className='me-1' />
              ประวัติการ
            </Button>
          }
        />
        <Row className='mt-3 align-items-center'>
          <Col sm={12} lg={4} >
            <Input
              label="เลขที่อ้างอิง"
              placeholder="เลขที่อ้างอิง"
              value="DP66001"
            />
          </Col>
          <Col sm={12} />
          <Col sm={12} lg={4} >
            <Input
              label="เลขที่ใบสั่ง"
              placeholder="เลขที่ใบสั่ง"
              value="66001"
            />
          </Col>
          <Col sm={12} />
          <Col sm={12} lg={4} >
            <Input
              label="เลขที่ผู้เสียภาษี"
              placeholder="เลขที่ผู้เสียภาษี"
              value="0994000160461"
            />
          </Col>
          <Col sm={12} />
          <Col sm={12} lg={4} >
            <Input
              label="ชื่อผู้ชนะ"
              placeholder="ชื่อผู้ชนะ"
              value="บริษัท โค้ดฮาร์ด จำกัด"
            />
          </Col>
          <Col sm={12} lg={4} >
            <Input
              label="Email"
              placeholder="Email"
              value="hello@codehard.co.th"
            />
          </Col>
          <Col sm={12} />
          <Col sm={12} lg={6}>
            <InputTextArea label='ชื่อโครงการ' rule={{ required: true }} />
          </Col>
          <Col sm={12} lg={6} />
          <Col sm={12} lg={3} >
            <Input
              label="วงเงิน"
              placeholder="วงเงิน"
              value="10,000,000"
              textEnd
            />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Row >
          <Col sm={12}>
            <div className="d-flex justify-content-between mb-1">
              <p className="fs-5 text-primary">ผู้มีอำนาจลงนาม</p>
              <Button
                variant="outline-primary">
                <FaPlus className='me-1' />เพิ่มรายชื่อ
              </Button>
            </div>
          </Col>
          <Col>
            <Table total={1} hidePagination>
              <thead>
                <tr>
                  <th style={{ width: 10 }}>ลำดับ</th>
                  <th style={{ width: 270 }}>ชื่อ-นามสกุล</th>
                  <th style={{ width: 180 }}>ฝ่าย/สำนัก</th>
                  <th style={{ width: 300 }}>ตำแหน่ง</th>
                  <th style={{ minWidth: 150, width: 180 }}>สถานะ</th>
                  <th style={{ minWidth: 50, width: 300 }}>วันที่อนุมัติเห็นชอบ/<br />ส่งกลับแก้ไข</th>
                  <th style={{ minWidth: 50, width: 300 }}>ไม่สามารถ<br /> ปฏิบัติงานได้</th>
                  <th style={{ minWidth: 200 }}>หมายเหตุ</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-center'>
                  <td>1</td>
                  <td className='text-start'>Kamonrat Pramongwattana</td>
                  <td className='text-start text-wrap'>จัดหาและการพัสดุ</td>
                  <td className='text-start'>ผู้อำนวยการ</td>
                  <td><Status type={StatusType.JOR_POR_04_05_APPROVER} value='Accepted' badge /></td>
                  <td>2/06/2566</td>
                  <td>
                    <div className='d-flex justify-content-center'>
                      <Check />
                    </div>
                  </td>
                  <td><Input /></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12} lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>
      <div className='d-flex justify-content-center pt-3'>

        <Button
          className='me-2 px-3'
          onClick={backPage}
          variant='outline-primary'>
          ย้อนกลับ
        </Button>
        <Button
          onClick={backPage}
          className='me-2 px-3'>
          <FaCheck className='me-2' />
          เห็นชอบ
        </Button>
        <HistoryModal
          show={showHistory}
          onHide={() => setShowHistory(!showHistory)}
          data={dataHistory}
        />
      </div>
    </div>
  );
}
