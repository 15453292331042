import {
  Input,
  ProcessStatus,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { generateUniqueId } from '../../../utils/helper';
import { useForm } from '../../../utils/useForm';

export default function PR0203() {
  return (
    <>
      <PR0203Header />
      <Criteria />
      <DataTable />
    </>
  );
}

function PR0203Header() {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">จัดทำสัญญาหรือข้อตกลง และบันทึต่อท้ายสัญญา</h4>
      </div>
      <hr />
    </>
  );
}

function GetStatus(i: number) {
  switch (i) {
    case 1:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.PENDING} />;
    case 2:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.APPROVED} />;
    case 3:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.REJECTED} />;
    default:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.DRAFT} />;
  }
}

interface FormInput {
  code: string;
  department: string;
  name: string;
  budget: string;
  createBy: string;
  typeAll: string;
  type80: string;
  type60: string;
  statusAll: string;
  statusDraft: string;
  statusPending: string;
  statusApprove: string;
  statusReject: string;
}

function Criteria() {
  const [program, setProgram] = useState('');
  const { handleSubmit, data: formData, errors, handleClear } = useForm<FormInput>({
    validations: {

    },
    onSubmit: async () => { console.log(formData); },
  });

  return (
    <Form onSubmit={handleSubmit} id="formPL01">
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="โครงการ" placeholder="โครงการ" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="เรื่อง" placeholder="เรื่อง" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="วิธีการจัดซื้อจัดจ้าง" placeholder="วิธีการจัดซื้อจัดจ้าง" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="วงเงินการจัดซื้อจัดจ้าง" placeholder="วงเงินการจัดซื้อจัดจ้าง" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="ชื่อผู้ประกอบการ" placeholder="ชื่อผู้ประกอบการ" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="ผู้จัดทำ" placeholder="ผู้จัดทำ" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector label="สถานะ" value={program} onChange={(value) => setProgram(value.toString())} />
        </Col>

        <div className="d-flex gap-2">
          <Button form="formPL01" type="submit" variant="primary" className="d-flex align-items-center gap-2">
            <FaSearch />ค้นหา
          </Button>
          <Button variant="outline-primary" onClick={handleClear} className="d-flex align-items-center gap-2">
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
    </Form>
  );
}

function DataTable() {
  const navigate = useNavigate();
  const goToPage = () => {
    navigate('/cm/cm0203/detail');
  };

  return (
    <Table className="mt-4">
      <thead>
        <tr className="text-center">
          <th>ลำดับ</th>
          <th>ชื่อโครงการ</th>
          <th>เรื่อง</th>
          <th>ผู้จัดทำ</th>
          <th>วันที่จัดทำ</th>
          <th>สถานะ</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 5 }).map((_, index) => (
          <tr className="text-center" key={generateUniqueId(index)}>
            <td>{index}</td>
            <td><Button variant="link" onClick={goToPage}>1100010495</Button> : โครงการ Digital Procurement</td>
            <td>รายงานขอเช่าสัญญาณสมาชิก True Vision</td>
            <td>Pratya</td>
            <td>03/2566</td>
            <td>{GetStatus(index)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
