import {
  AppointTorStatus,
  Check,
  Currency,
  DatePicker,
  Modal,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import { PreProcumentFlag } from 'constant/PreProcumentFlag';
import { TorStatusConstant } from 'constant/PreProcumentTorStatus';
import {
  CountStatusModel,
  CountSupplyMethodModel,
  SearchCriteriaModel,
  TorListResponseModel,
  TorModel,
} from 'models/PP/TorModels';
import moment from 'moment';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import PreProcurementTorServices from 'services/PP/PreProcurementTorServices';
import {
  HttpStatusCode,
  THCurrency,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { useForm } from '../../../utils/useForm';

interface ItemModel {
  label: string;
  value: string;
}

type Loader = {
  departmentDDL: ItemModel[],
  supplyMethodDDL: ItemModel[],
};

const defaultStatus = [TorStatusConstant.Draft, TorStatusConstant.DraftTor, TorStatusConstant.WaitingForCommitteeApprove, TorStatusConstant.WaitingForDirectorJorPorAssign, TorStatusConstant.WaitingForAssignResponsible, TorStatusConstant.DraftSectionApprover, TorStatusConstant.WaitingForApprove, TorStatusConstant.Approved, TorStatusConstant.Cancelled, TorStatusConstant.Rejected];

const defaultMethod = ['a21603e4-338d-486c-85ba-5f1cac8f6ace', '342e4e0b-7682-48c0-b46a-97a066908c9a'];

export default function TORCancel() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { departmentDDL, supplyMethodDDL } = useLoaderData() as Loader;
  const [formSearch, setFormSearch] = useState<SearchCriteriaModel>({} as SearchCriteriaModel);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>([]);
  const [torData, setTorData] = useState<TorListResponseModel>({} as TorListResponseModel);
  const { userId } = useAppContext();

  useEffect(() => {
    searchTorList(formSearch);
  }, [formSearch]);

  const searchTorList = useCallback(async (searchModel: SearchCriteriaModel) => {
    if (!searchModel.page) {
      searchModel.page = page;
    }

    if (!searchModel.size) {
      searchModel.size = size;
    }

    if (searchModel.isResponsible === undefined) {
      searchModel.isResponsible = true;
    }

    if (searchModel.isResponsible === undefined) {
      searchModel.isResponsible = true;
    }

    if (searchModel.termOfRefStatus === undefined) {
      searchModel.termOfRefStatus = defaultStatus;
    }

    if (searchModel.supplyMethodType === undefined) {
      searchModel.supplyMethodType = defaultMethod;
    }

    const { data, status } = await PreProcurementTorServices.getListTorAsync(searchModel, PreProcumentFlag.IsCanceling);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    getCountStatus(searchModel);
    getCountSupplyMethod(searchModel);
    setTorData(data);
  }, []);

  // useEffect(() => {
  //   searchTorList({ page, size });
  //   getCountStatus(formSearch);
  //   getCountSupplyMethod(formSearch);
  // }, [page, searchTorList, size]);

  const getCountStatus = useCallback(async (searchModel: SearchCriteriaModel) => {
    const countStatusRes = await PreProcurementTorServices.getCountStatusAsync(searchModel, userId, PreProcumentFlag.IsCanceling);

    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data.termOfRefStatus);
    }
  }, []);

  const getCountSupplyMethod = useCallback(async (searchModel: SearchCriteriaModel) => {
    const countMethodRes = await PreProcurementTorServices.getCountSupplyMethodAsync(searchModel, userId, PreProcumentFlag.IsCanceling);

    if (countMethodRes.status === HttpStatusCode.OK) {
      setCountSupplyMethod(countMethodRes.data.supplyMethod);
    }
  }, []);

  const handleSetPagination = async (size: number, page: number) => {
    setPage(page);
    setSize(size);
    formSearch.page = page;
    formSearch.size = size;
    searchTorList(formSearch);
  };

  return (
    <div className='m01'>
      <TORDraftHeader />
      <Criteria
        setForm={setFormSearch}
        departmentList={departmentDDL}
        supplyMethodList={supplyMethodDDL}
        countStatus={countStatus}
        countSupplyMethod={countSupplyMethod}
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
      />
      <DataTable
        torData={torData.data}
        total={torData.totalCount}
        page={page}
        size={size}
        onChange={(size, page) => {
          handleSetPagination(size, page);
        }}
      />
    </div>
  );
}

function TORDraftHeader() {
  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='text-primary m-0'>ขอยกเลิกร่างขอบเขตของงาน (TOR)</h4>
      </div>
      <hr />
    </>
  );
}

interface FormCriteriaInput {
  planNumber: string;
  torNumber: string;
  departmentId: string;
  planName: string;
  expectingProcurementAt: string;
  planBudgetStart: string;
  planBudgetEnd: string;
  supplyMethodIds: string[];
  status: string[];
  appointNumber: string;
}

interface CriteriaProps {
  setForm: (form: SearchCriteriaModel) => void;
  departmentList: ItemModel[];
  supplyMethodList: ItemModel[];
  countStatus: CountStatusModel[];
  countSupplyMethod: CountSupplyMethodModel[];
  page: number;
  size: number;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
}

enum CriteriaStatus {
  Draft = 'Draft',
  DraftTor = 'DraftTor',
  WaitingForCommitteeApprove = 'WaitingForCommitteeApprove',
  WaitingForDirectorJorPorAssign = 'WaitingForDirectorJorPorAssign',
  WaitingForAssignResponsible = 'WaitingForAssignResponsible',
  WaitingForApprove = 'WaitingForApprove',
  Approved = 'Approved',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
}

enum CriteriaSupplyMethod {
  Method60 = 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560',
  Method80 = 'ข้อบังคับธนาคาร 80',
}

enum CriteriaSupplyMethodCode {
  Method60 = '342e4e0b-7682-48c0-b46a-97a066908c9a',
  Method80 = 'a21603e4-338d-486c-85ba-5f1cac8f6ace',
}

interface StatusItemModel {
  label: string;
  value: boolean;
}

function Criteria(props: CriteriaProps) {
  const [memorandumDate, setMemorandumDate] = useState<Date>();
  const [expectingProcurementDate, setExpectingProcurementDate] = useState<Date>();
  const [departmentId, setDepartmentId] = useState('');
  const [planBudgetStart, setPlanBudgetStart] = useState<number | undefined>(undefined);
  const [planBudgetEnd, setPlanBudgetEnd] = useState<number | undefined>(undefined);
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [statusDraft, setStatusDraft] = useState<boolean>(false);
  const [statusDraftTor, setStatusDraftTor] = useState<boolean>(false);
  const [statusApproved, setStatusApproved] = useState<boolean>(false);
  const [statusWaitingForCommitteeApprove, setStatusWaitingForCommitteeApprove] = useState<boolean>(false);
  const [statusWaitingForDirectorJorPorAssign, setStatusWaitingForDirectorJorPorAssign] = useState<boolean>(false);
  const [statusWaitingForAssignResponsible, setStatusWaitingForAssignResponsible] = useState<boolean>(false);
  const [statusWaitingForApprove, setStatusWaitingForApprove] = useState<boolean>(false);
  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [methodAll, setMethodAll] = useState<boolean>(false);
  const [method60, setMethod60] = useState<boolean>(false);
  const [method80, setMethod80] = useState<boolean>(false);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);
  const [supplyMethods, setSupplyMethods] = useState<ItemModel[]>(props.supplyMethodList);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>(props.countStatus);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>(props.countSupplyMethod);
  const [methodLists, setMethodLists] = useState<StatusItemModel[]>([
    { value: false, label: '342e4e0b-7682-48c0-b46a-97a066908c9a' },
    { value: false, label: 'a21603e4-338d-486c-85ba-5f1cac8f6ace' },
  ]);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    { value: false, label: 'Draft' },
    { value: false, label: 'DraftTor' },
    { value: false, label: 'WaitingForCommitteeApprove' },
    { value: false, label: 'WaitingForDirectorJorPorAssign' },
    { value: false, label: 'WaitingForAssignResponsible' },
    { value: false, label: 'WaitingForApprove' },
    { value: false, label: 'Approved' },
    { value: false, label: 'Rejected' },
  ]);

  useEffect(() => {
    setCountStatus(props.countStatus);
  }, [props.countStatus]);

  useEffect(() => {
    setCountSupplyMethod(props.countSupplyMethod);
  }, [props.countSupplyMethod]);

  useEffect(() => {
    setIsResponsible(true);
    onChangeStatusAll(true);
    onChangeMethodAll(true);
  }, []);

  const onChangeStatusAll = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setStatusDraft(true);
      setStatusDraftTor(true);
      setStatusWaitingForCommitteeApprove(true);
      setStatusWaitingForDirectorJorPorAssign(true);
      setStatusWaitingForAssignResponsible(true);
      setStatusWaitingForApprove(true);
      setStatusApproved(true);
      setStatusRejected(true);
    } else {
      setStatusAll(false);
      setStatusDraft(false);
      setStatusDraftTor(false);
      setStatusWaitingForCommitteeApprove(false);
      setStatusWaitingForDirectorJorPorAssign(false);
      setStatusWaitingForAssignResponsible(false);
      setStatusWaitingForApprove(false);
      setStatusApproved(false);
      setStatusRejected(false);
    }
  };

  const onChangeMethodAll = (result: boolean) => {
    if (result) {
      setMethodAll(true);
      setMethod60(true);
      setMethod80(true);
    } else {
      setMethodAll(false);
      setMethod60(false);
      setMethod80(false);
    }
  };

  const onClear = useCallback(async () => {
    setMemorandumDate(undefined);
    setDepartmentId('');
    setExpectingProcurementDate(undefined);
    setIsResponsible(true);

    onChangeStatusAll(true);
    onChangeMethodAll(true);
    props.setPage(1);
    props.setSize(10);
    handleClear();
  }, []);

  const onChangeCheckMethod = (methodId: string, value: boolean) => {
    const methodList = [...methodLists];
    const index = methodLists.findIndex(s => s.label === methodId);
    methodList[index].value = value;
    setMethodLists(methodList);

    if (!value) {
      setMethodAll(false);
    }

    if (methodList.filter(s => s.value === false).length === 0) {
      setMethodAll(true);
    }

    switch (methodId) {
      case '342e4e0b-7682-48c0-b46a-97a066908c9a':
        return setMethod60(value);
      case 'a21603e4-338d-486c-85ba-5f1cac8f6ace':
        return setMethod80(value);
    }
  };

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex(s => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter(s => s.value === false).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case TorStatusConstant.Draft:
        return setStatusDraft(value);
      case TorStatusConstant.DraftTor:
        return setStatusDraftTor(value);
      case TorStatusConstant.WaitingForCommitteeApprove:
        return setStatusWaitingForCommitteeApprove(value);
      case TorStatusConstant.WaitingForDirectorJorPorAssign:
        return setStatusWaitingForDirectorJorPorAssign(value);
      case TorStatusConstant.WaitingForAssignResponsible:
        return setStatusWaitingForAssignResponsible(value);
      case TorStatusConstant.WaitingForApprove:
        return setStatusWaitingForApprove(value);
      case TorStatusConstant.Approved:
        return setStatusApproved(value);
      case TorStatusConstant.Rejected:
        return setStatusRejected(value);
    }
  };

  const { handleSubmit, handleChange, data: formData, handleClear } = useForm<FormCriteriaInput>({
    initialValues: {
      planNumber: undefined,
      departmentId: undefined,
      planName: undefined,
      expectingProcurementAt: undefined,
      planBudgetStart: undefined,
      planBudgetEnd: undefined,
      supplyMethodIds: undefined,
      status: undefined,
      appointNumber: undefined,
      torNumber: undefined,
    },
    validations: {},
    onSubmit: async () => {
      let apmDate: Date | undefined;
      let expectingDate: string | undefined;
      const status: string[] = [];
      const method: string[] = [];

      if (statusDraft) {
        status.push(CriteriaStatus.Draft);
      }

      if (statusDraftTor) {
        status.push(CriteriaStatus.DraftTor);
      }

      if (statusWaitingForCommitteeApprove) {
        status.push(CriteriaStatus.WaitingForCommitteeApprove);
      }

      if (statusWaitingForDirectorJorPorAssign) {
        status.push(CriteriaStatus.WaitingForDirectorJorPorAssign);
      }

      if (statusWaitingForAssignResponsible) {
        status.push(CriteriaStatus.WaitingForAssignResponsible);
      }

      if (statusWaitingForApprove) {
        status.push(CriteriaStatus.WaitingForApprove);
      }

      if (statusApproved) {
        status.push(CriteriaStatus.Approved);
      }

      if (statusRejected) {
        status.push(CriteriaStatus.Rejected);
      }

      if (method60) {
        method.push(supplyMethods.filter((x) => x.value === '342e4e0b-7682-48c0-b46a-97a066908c9a')[0].value);
      }

      if (method80) {
        method.push(supplyMethods.filter((x) => x.value === 'a21603e4-338d-486c-85ba-5f1cac8f6ace')[0].value);
      }

      if (memorandumDate) {
        apmDate = new Date(memorandumDate);
      }

      if ((planBudgetStart && planBudgetEnd) && (planBudgetStart > planBudgetEnd)) {
        toast.error('ไม่สามารถระบุวงเงินเริ่มต้นมากกว่าวงเงินสิ้นสุดได้');
      }

      if (expectingProcurementDate) {
        expectingDate = moment(expectingProcurementDate)
          .format('YYYY-MM-DD');
      }

      const searchModel: SearchCriteriaModel = {
        page: props.page,
        size: props.size,
        planNumber: formData.planNumber,
        planName: formData.planName,
        departmentId,
        planBudgetMin: planBudgetStart,
        planBudgetMax: planBudgetEnd,
        memorandumDate: apmDate,
        expectingProcurementAt: expectingDate,
        isResponsible,
        supplyMethodType: method,
        termOfRefStatus: status,
        appointNumber: formData.appointNumber,
        torNumber: formData.torNumber,
      };

      props.setForm(searchModel);
    },
  });

  return (
    <Form onSubmit={handleSubmit}
      id='formPL01'>
      <Row className='criteria'>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่บันทึกแต่งตั้ง</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='เลขที่บันทึกแต่งตั้ง'
                value={formData.appointNumber || ''}
                onChange={handleChange<string>('appointNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่รายการจัดซื้อจัดจ้าง</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='เลขที่รายการจัดซื้อจัดจ้าง'
                value={formData.planNumber || ''}
                onChange={handleChange<string>('planNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>เลขที่จัดทำร่างขอบเขตงาน</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='เลขที่จัดทำร่างขอบเขตงาน'
                value={formData.torNumber || ''}
                onChange={handleChange<string>('torNumber', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Selector
              label='ฝ่าย/สำนัก'
              items={props.departmentList}
              value={`${departmentId}`}
              onChange={(value) => (setDepartmentId(value.toString()))}
            />
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <Form.Label>ชื่อโครงการ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type='text'
                maxLength={100}
                placeholder='ชื่อโครงการ'
                value={formData.planName || ''}
                onChange={handleChange<string>('planName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Form.Group className='mb-3'>
            <DatePicker
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              value={expectingProcurementDate}
              onChangeHasNullable={setExpectingProcurementDate}
              monthYearOnly
            />
          </Form.Group>
        </Col>
        <Col sm={12}
          md={4}
          xl={3}>
          <Currency
            label='วงเงินเริ่มต้น'
            placeholder='0.00'
            value={planBudgetStart}
            onChange={(value) => {
              setPlanBudgetStart(Number(value) ? Number(value) : undefined);
            }}
          />
        </Col>
        <Col sm={12}
          md={4}
          xl={3}>
          <Currency
            label='วงเงินสิ้นสุด'
            placeholder='0.00'
            value={planBudgetEnd}
            onChange={(value) => {
              setPlanBudgetEnd(Number(value) ? Number(value) : undefined);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}
          md={6}
          xl={6}>
          <Form.Group className='mb-3'>
            <Form.Label>วิธีจัดหา</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={<div className='align-items-center d-flex'>ทั้งหมด <Badge className='h-50 mx-2'
                  bg='light'>{countSupplyMethod.reduce((a, v) => a += v.count, 0)}</Badge></div>}
                value={methodAll}
                onChange={(val) => onChangeMethodAll(val)}
              />
              <Check
                label={
                  <div className='align-items-center d-flex'>{CriteriaSupplyMethod.Method80}<Badge className='h-50 mx-2'
                    bg='primary'>{countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodCode.Method80).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodCode.Method80)[0].count : 0}</Badge>
                  </div>}
                value={method80}
                onChange={(val) => onChangeCheckMethod('342e4e0b-7682-48c0-b46a-97a066908c9a', val)}
              />
              <Check
                label={
                  <div className='align-items-center d-flex'>{CriteriaSupplyMethod.Method60}<Badge className='h-50 mx-2'
                    bg='primary'>{countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodCode.Method60).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethod === CriteriaSupplyMethodCode.Method60)[0].count : 0}</Badge>
                  </div>}
                value={method60}
                onChange={(val) => onChangeCheckMethod('342e4e0b-7682-48c0-b46a-97a066908c9a', val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12}
          md={6}
          xl={6}>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={<div className='align-items-center d-flex'>ทั้งหมด <Badge className='h-50 mx-2'
                  bg='light'>{countStatus.reduce((a, v) => a += v.count, 0)}</Badge></div>}
                value={statusAll}
                onChange={(val) => onChangeStatusAll(val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>แบบร่าง <Badge className='h-50 mx-2'
                  bg='secondary'>{countStatus.filter((x) => x.status === CriteriaStatus.Draft).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.Draft)[0].count : 0}</Badge>
                </div>}
                value={statusDraft}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.Draft, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>อยู่ระหว่างร่าง TOR <Badge className='h-50 mx-2'
                  bg='warning'>{countStatus.filter((x) => x.status === CriteriaStatus.DraftTor).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.DraftTor)[0].count : 0}</Badge>
                </div>}
                value={statusDraftTor}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.DraftTor, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>อยู่ระหว่าง คกก. เห็นชอบ <Badge className='h-50 mx-2'
                  bg='warning'>{countStatus.filter((x) => x.status === CriteriaStatus.WaitingForCommitteeApprove).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.WaitingForCommitteeApprove)[0].count : 0}</Badge>
                </div>}
                value={statusWaitingForCommitteeApprove}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.WaitingForCommitteeApprove, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>อยู่ระหว่างรอ ผอ.จพ.มอบหมาย <Badge className='h-50 mx-2'
                  bg='warning'>{countStatus.filter((x) => x.status === CriteriaStatus.WaitingForDirectorJorPorAssign).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.WaitingForDirectorJorPorAssign)[0].count : 0}</Badge>
                </div>}
                value={statusWaitingForDirectorJorPorAssign}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.WaitingForDirectorJorPorAssign, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>อยู่ระหว่างรอมอบหมายผู้รับผิดชอบ <Badge className='h-50 mx-2'
                  bg='warning'>{countStatus.filter((x) => x.status === CriteriaStatus.WaitingForAssignResponsible).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.WaitingForAssignResponsible)[0].count : 0}</Badge>
                </div>}
                value={statusWaitingForAssignResponsible}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.WaitingForAssignResponsible, val)}
              />
              <Check
                label={
                  <div className='align-items-center d-flex'>อยู่ระหว่างเห็นชอบ/อนุมัติ <Badge className='h-50 mx-2'
                    bg='warning'>{countStatus.filter((x) => x.status === CriteriaStatus.WaitingForApprove).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.WaitingForApprove)[0].count : 0}</Badge>
                  </div>}
                value={statusWaitingForApprove}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.WaitingForApprove, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>เห็นชอบ/อนุมัติ <Badge className='h-50 mx-2'
                  bg='success'>{countStatus.filter((x) => x.status === CriteriaStatus.Approved).length > 0 ? countStatus.filter((x) => x.status === CriteriaStatus.Approved)[0].count : 0}</Badge>
                </div>}
                value={statusApproved}
                onChange={(val) => onChangeCheckStatus(CriteriaStatus.Approved, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>ส่งกลับแก้ไข<Badge className='h-50 mx-2'
                  bg='danger'>{countStatus.filter((x) => x.status === TorStatusConstant.Rejected).length > 0 ? countStatus.filter((x) => x.status === TorStatusConstant.Rejected)[0].count : 0}</Badge>
                </div>}
                value={statusRejected}
                onChange={(val) => onChangeCheckStatus(TorStatusConstant.Rejected, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12}
          md={4}
          xl={3}>
          <Check
            label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
            value={isResponsible}
            onChange={(val) => setIsResponsible(val)}
          />
        </Col>
      </Row>
      <div className='d-flex gap-2'>
        <Button form='formPL01'
          type='submit'
          variant='primary'
          className='me-2'>
          <FaSearch className='me-2' />ค้นหา
        </Button>
        <Button variant='outline-primary'
          onClick={onClear}
          form='formPL01'
          type='submit'>
          <FaEraser className='me-2' />ล้าง
        </Button>
      </div>
    </Form>
  );
}

interface DataTableProps {
  page: number;
  size: number;
  total: number;
  torData: TorModel[];
  onChange?: (size: number, page: number) => void;
}

function DataTable(props: DataTableProps) {
  const [datas, setDatas] = useState<TorModel[]>([]);
  const [deleteId, setDeleteId] = useState<string>('');
  const [indexId, setIndexId] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSetPagination = (page: number, size: number) => {
    if (props.onChange) {
      props.onChange(size, page);
    }
  };

  useEffect(() => {
    if (props && props.torData) {
      setDatas(props.torData);
    }
  }, [props]);

  const openModalDelete = (index: number, id: string) => {
    setShowModal(true);
    setDeleteId(id);
    setIndexId(index);
  };

  const deleteTorAsync = async (index: number, id: string) => {
    if (id) {
      const response = await PreProcurementTorServices.deleteTorByIdAsync(id);

      if (response.status === HttpStatusCode.OK) {
        toast.success('ลบรายการสำเร็จ');
        const temp: TorModel[] = [...datas];
        temp.splice(index, 1);
        setDatas(temp);
      } else {
        toast.error('เกิดข้อผิดพลาด');
      }
    } else {
      toast.error('เกิดข้อผิดพลาด');
    }
    setShowModal(false);
  };

  const goToPage = (id: string) => {
    navigate(`/cancel-preprocurement-tor/detail/${id}`);
  };

  return (
    <Table
      className='mt-4'
      total={props.total}
      onChange={(size, page) => {
        handleSetPagination(page, size);
      }}
    >
      <thead>
        <tr className='text-center'>
          <th>เลขที่บันทึกแต่งตั้ง</th>
          <th>เลขที่รายการ<br />จัดซื้อจัดจ้าง</th>
          <th>เลขที่จัดทำ<br />ร่างขอบเขตงาน</th>
          <th>สถานะ</th>
          <th>ฝ่าย/สำนัก</th>
          <th>ชื่อโครงการ</th>
          <th>วงเงิน</th>
          <th>วิธีจัดหา</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {
          datas?.map((item, index) => (
            <tr className='text-center'
              key={index}>
              <td>
                <div className='d-flex justify-content-center'>
                  <Button
                    variant='link'
                    onClick={() => goToPage(item.id)}
                  >{item.appointNumber}
                  </Button>
                </div>
              </td>
              <td className='text-center'>{item.planNumber}</td>
              <td className='text-center'>{item.torNumber}</td>
              <td><Status value={item.status}
                type={StatusType.TOR} /></td>
              <td className='text-start'>{item.departmentName}</td>
              <td className='text-start text-wrap'
                style={{ minWidth: 650, width: 650 }}>{item.planName}</td>
              <td className='text-end'>{THCurrency(item.planBudget)}</td>
              <td>{item.supplyMethodName}</td>
              <td>
                {
                  (item.status === AppointTorStatus.Draft || item.status === AppointTorStatus.DraftTor) && (
                    <div className='d-flex justify-content-center'>
                      <Button
                        disabled={!item.isCommitteeTor}
                        variant='danger'
                        className='d-flex align-items-center gap-2'
                        onClick={() => {
                          openModalDelete(index, item.id);
                        }}
                      >
                        <FaTrashAlt />
                      </Button>
                    </div>
                  )
                }
              </td>
            </tr>
          ))
        }
      </tbody>
      <Modal
        show={showModal}
        size='lg'
        onHide={() => setShowModal(!showModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบรายการ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการลบรายการ TOR นี้หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-danger'
                onClick={() => setShowModal(!showModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  deleteTorAsync(indexId, deleteId);
                }}
              >
                ยืนยันลบข้อมูล
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </Table>
  );
}
