import {
  Check,
  MedianPriceStatus,
  Status,
  StatusType,
  SupplyMethodEnum,
  SupplyMethodNameEnum,
  Table,
  Modal,
} from 'components';
import {
  Currency,
  DatePicker,
  Input,
  Selector,
} from 'components/Controls';
import { TorEditCancelStatusConstant } from 'constant/PreProcumentTorStatus';
import { ItemModel } from 'models';
import { CountStatusModel, CountSupplyMethodModel, MedianPriceListModel, SearchCriteriaModel } from 'models/PP/MedianPriceModel';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import { FaCheck, FaEraser, FaSearch, FaTrashAlt } from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import MedianPriceService from 'services/PP/MedianPriceService';
import {
  HttpStatusCode,
  THCurrency,
} from 'utils';
import toast from 'utils/toast';

interface StatusItemModel {
  label: string;
  value: boolean;
}

type Loader = { departmentDDL: ItemModel[], supplyMethodDDL: ItemModel[], };

const defaultStatus = [MedianPriceStatus.None, MedianPriceStatus.Draft, MedianPriceStatus.WaitingForCommitteeApprove,
MedianPriceStatus.Cancelled, MedianPriceStatus.Rejected, MedianPriceStatus.Approved, MedianPriceStatus.WaitingForApprove, MedianPriceStatus.CommitteeApproved, MedianPriceStatus.WaitingForAssignResponsible, MedianPriceStatus.DraftSectionApprover, MedianPriceStatus.WaitingForJorPorDirector];

const defaultSupplyMethod = [SupplyMethodEnum.MethodId60, SupplyMethodEnum.MethodId80];

export default function CM06() {
  const goToPage = (id: string) => {
    navigate(`/preprocurement-median-price/detail/${id}`);
  };
  const { departmentDDL, supplyMethodDDL } = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [dataList, setDataList] = useState<MedianPriceListModel[]>([]);
  const [criteria, setCriteria] = useState<SearchCriteriaModel>({} as SearchCriteriaModel);
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [methodAll, setMethodAll] = useState<boolean>(false);
  const [statusNone, setStatusNone] = useState<boolean>(false);
  const [statuApproved, setStatusApproved] = useState<boolean>(false);
  const [statusCancelled, setStatusCancelled] = useState<boolean>(false);
  const [statusDraft, setStatusDraft] = useState<boolean>(false);
  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [statusWaitingForApprove, setStatusWaitingForApprove] = useState<boolean>(false);
  const [statusWaitingForCommitteeApprove, setStatusWaitingForCommitteeApprove] = useState<boolean>(false);
  const [statusCommitteeApproved, setStatusCommitteeApproved] = useState<boolean>(false);
  const [statusWaitingForAssignResponsible, setStatusWaitingForAssignResponsible] = useState<boolean>(false);
  const [statusDraftSectionApprover, setStatusDraftSectionApprover] = useState<boolean>(false);
  const [statusWaitingForJorPorDirector, setStatusWaitingForJorPorDirector] = useState<boolean>(false);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);
  const [method60, setMethod60] = useState<boolean>(false);
  const [method80, setMethod80] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [countSupplyMethod, setCountSupplyMethod] = useState<CountSupplyMethodModel[]>([]);
  const [deleteId, setDeleteId] = useState<string>('');
  const [indexId, setIndexId] = useState<number>(0);
  const [methodLists, setMethodLists] = useState<StatusItemModel[]>([
    { value: false, label: SupplyMethodEnum.MethodId60 },
    { value: false, label: SupplyMethodEnum.MethodId80 },
  ]);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    { value: false, label: MedianPriceStatus.All },
    { value: false, label: MedianPriceStatus.None },
    { value: false, label: MedianPriceStatus.Approved },
    { value: false, label: MedianPriceStatus.Cancelled },
    { value: false, label: MedianPriceStatus.CommitteeRejected },
    { value: false, label: MedianPriceStatus.Draft },
    { value: false, label: MedianPriceStatus.Rejected },
    { value: false, label: MedianPriceStatus.WaitingForApprove },
    { value: false, label: MedianPriceStatus.WaitingForCommitteeApprove },
    { value: false, label: MedianPriceStatus.CommitteeApproved },
    { value: false, label: MedianPriceStatus.WaitingForAssignResponsible },
    { value: false, label: MedianPriceStatus.DraftSectionApprover },
    { value: false, label: MedianPriceStatus.WaitingForJorPorDirector },
  ]);

  useEffect(() => {
    setMethodAll(true);
    setMethod60(true);
    setMethod80(true);
    setStatusAll(true);
    setStatusNone(true);
    setStatusApproved(true);
    setStatusCancelled(true);
    setStatusCancelled(true);
    setStatusDraft(true);
    setStatusRejected(true);
    setStatusWaitingForCommitteeApprove(true);
    // setStatusCommitteeRejected(true);
    setStatusCommitteeApproved(true);
    setStatusWaitingForApprove(true);
    setStatusWaitingForAssignResponsible(true);
    setStatusDraftSectionApprover(true);
    setStatusWaitingForJorPorDirector(true);
    criteria.isAssigneeOnly = true;
    getListDataAsync(criteria);
  }, []);

  const getListDataAsync = async (searchModel: SearchCriteriaModel) => {

    if (!searchModel.page) {
      searchModel.page = page;
    }

    if (!searchModel.pageSize) {
      searchModel.pageSize = 10;
    }

    if (searchModel.medianPriceStatuses === undefined || searchModel.medianPriceStatuses.length === 0) {
      searchModel.medianPriceStatuses = defaultStatus;
    }

    if (searchModel.supplyMethods === undefined || searchModel.supplyMethods.length === 0) {
      searchModel.supplyMethods = defaultSupplyMethod;
    }

    const res = await MedianPriceService.getListMedianPriceBAsync(
      searchModel,
    );

    if (res.status === HttpStatusCode.OK) {
      setDataList(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }

    await getCountStatus(searchModel);
    await getCountSupplyMethod(searchModel);
  };

  const getCountStatus = useCallback(async (searchModel: SearchCriteriaModel) => {
    const countStatusRes = await MedianPriceService.getCountStatusAsync(
      searchModel,
    );

    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data);
    }
  }, []);

  const getCountSupplyMethod = useCallback(async (searchModel: SearchCriteriaModel) => {
    const countMethodRes = await MedianPriceService.getCountSupplyMethodAsync(searchModel);

    if (countMethodRes.status === HttpStatusCode.OK) {
      setCountSupplyMethod(countMethodRes.data);
    }
  }, []);

  const onChangeStatusAll = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setStatusNone(true);
      setStatusApproved(true);
      setStatusCancelled(true);
      setStatusCancelled(true);
      setStatusDraft(true);
      setStatusRejected(true);
      setStatusWaitingForCommitteeApprove(true);
      setStatusWaitingForAssignResponsible(true);
      setStatusDraftSectionApprover(true);
      setStatusWaitingForJorPorDirector(true);
      // setStatusCommitteeRejected(true);
      setStatusWaitingForApprove(true);
      setStatusCommitteeApproved(true);
    } else {
      setStatusAll(false);
      setStatusNone(false);
      setStatusApproved(false);
      setStatusCancelled(false);
      setStatusCancelled(false);
      setStatusDraft(false);
      setStatusRejected(false);
      setStatusWaitingForCommitteeApprove(false);
      // setStatusCommitteeRejected(false);
      setStatusWaitingForApprove(false);
      setStatusCommitteeApproved(false);
      setStatusWaitingForAssignResponsible(false);
      setStatusDraftSectionApprover(false);
      setStatusWaitingForJorPorDirector(false);
    }
  };

  const onChangeMethodAll = (result: boolean) => {
    if (result) {
      setMethodAll(true);
      setMethod60(true);
      setMethod80(true);
    } else {
      setMethodAll(false);
      setMethod60(false);
      setMethod80(false);
    }
  };

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex(s => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter(s => s.value === false).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case MedianPriceStatus.None:
        return setStatusNone(value);
      case MedianPriceStatus.Approved:
        return setStatusApproved(value);
      case MedianPriceStatus.Cancelled:
        return setStatusCancelled(value);
      // case MedianPriceStatus.CommitteeRejected:
      //   return setStatusCommitteeRejected(value);
      case MedianPriceStatus.Draft:
        return setStatusDraft(value);
      case MedianPriceStatus.Rejected:
        return setStatusRejected(value);
      case MedianPriceStatus.WaitingForApprove:
        return setStatusWaitingForApprove(value);
      case MedianPriceStatus.WaitingForCommitteeApprove:
        return setStatusWaitingForCommitteeApprove(value);
      case MedianPriceStatus.CommitteeApproved:
        return setStatusCommitteeApproved(value);
      case MedianPriceStatus.WaitingForAssignResponsible:
        return setStatusWaitingForAssignResponsible(value);
      case MedianPriceStatus.DraftSectionApprover:
        return setStatusDraftSectionApprover(value);
      case MedianPriceStatus.WaitingForJorPorDirector:
        return setStatusWaitingForJorPorDirector(value);
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPageSize(size);
    setPage(page);
    criteria.page = page;
    criteria.pageSize = size;
    onSearch();
  };

  const onSearch = () => {
    const medianPriceStatuses: string[] = [];
    const supplyMethods: string[] = [];

    if (statusNone) {
      medianPriceStatuses.push(MedianPriceStatus.None);
    }

    if (statuApproved) {
      medianPriceStatuses.push(MedianPriceStatus.Approved);
    }

    if (statusCancelled) {
      medianPriceStatuses.push(MedianPriceStatus.Cancelled);
    }

    // if (statusCommitteeRejected) {
    //   medianPriceStatuses.push(MedianPriceStatus.CommitteeRejected);
    // }

    if (statusDraft) {
      medianPriceStatuses.push(MedianPriceStatus.Draft);
    }

    if (statusRejected) {
      medianPriceStatuses.push(MedianPriceStatus.Rejected);
    }

    if (statusWaitingForApprove) {
      medianPriceStatuses.push(MedianPriceStatus.WaitingForApprove);
    }

    if (statusWaitingForCommitteeApprove) {
      medianPriceStatuses.push(MedianPriceStatus.WaitingForCommitteeApprove);
    }

    if (statusCommitteeApproved) {
      medianPriceStatuses.push(MedianPriceStatus.CommitteeApproved);
    }

    if (statusWaitingForAssignResponsible) {
      medianPriceStatuses.push(MedianPriceStatus.WaitingForAssignResponsible);
    }

    if (statusDraftSectionApprover) {
      medianPriceStatuses.push(MedianPriceStatus.DraftSectionApprover);
    }

    if (statusWaitingForJorPorDirector) {
      medianPriceStatuses.push(MedianPriceStatus.WaitingForJorPorDirector);
    }

    if (method60) {
      supplyMethods.push(supplyMethodDDL.filter((x) => x.value === SupplyMethodEnum.MethodId60)[0].value);
    }

    if (method80) {
      supplyMethods.push(supplyMethodDDL.filter((x) => x.value === SupplyMethodEnum.MethodId80)[0].value);
    }

    const searchCriteria = {
      planNumber: criteria.planNumber,
      appointNumber: criteria.appointNumber,
      departmentId: criteria.departmentId,
      planName: criteria.planName,
      expectingProcurementAt: criteria.expectingProcurementAt,
      financialAmountMaximum: criteria.financialAmountMaximum,
      financialAmountMinimum: criteria.financialAmountMinimum,
      isAssigneeOnly: criteria.isAssigneeOnly,
      medianPriceStatuses,
      supplyMethods,
      page: criteria.page,
      pageSize: criteria.pageSize,
      medianPriceNumber: criteria.medianPriceNumber,
    } as SearchCriteriaModel;

    getListDataAsync(searchCriteria);
  };

  const onClearSearch = () => {
    const medianPriceStatuses: string[] = [];
    const supplyMethods: string[] = [];
    const isSize = 10;
    const isPage = 1;
    setPage(isPage);
    setPageSize(isSize);
    setStatusAll(true);
    setStatusNone(true);
    setStatusApproved(true);
    setStatusCancelled(true);
    setStatusCancelled(true);
    setStatusDraft(true);
    setStatusRejected(true);
    setStatusWaitingForCommitteeApprove(true);
    setStatusWaitingForAssignResponsible(true);
    setStatusDraftSectionApprover(true);
    setStatusWaitingForJorPorDirector(true);
    setStatusWaitingForApprove(true);
    setStatusCommitteeApproved(true);

    setMethodAll(true);
    setMethod60(true);
    setMethod80(true);

    onPaginationChange(isSize, isPage);
    setCriteria({
      isAssigneeOnly: true,
      page: isPage,
      pageSize: isSize
    } as SearchCriteriaModel);

    const searchCriteria = {
      page: isPage,
      pageSize: isSize,
      isAssigneeOnly: true,
      medianPriceStatuses,
      supplyMethods
    } as SearchCriteriaModel;
    getListDataAsync(searchCriteria);
  };

  const onChangeCheckMethod = (methodId: string, value: boolean) => {
    const methodList = [...methodLists];
    const index = methodLists.findIndex(s => s.label === methodId);
    methodList[index].value = value;
    setMethodLists(methodList);

    if (!value) {
      setMethodAll(false);
    }

    if (methodList.filter(s => s.value === false).length === 0) {
      setMethodAll(true);
    }

    switch (methodId) {
      case SupplyMethodEnum.MethodId60:
        return setMethod60(value);
      case SupplyMethodEnum.MethodId80:
        return setMethod80(value);
    }
  };

  const openModalDelete = (index: number, id: string) => {
    setShowDeleteModal(true);
    setDeleteId(id);
    setIndexId(index);
  };

  const deleteAppointmentAsync = async (index: number, id: string) => {

    if (id) {
      const response = await MedianPriceService.deleteMedianPriceAsync(id);
      if (response.status === HttpStatusCode.OK) {
        toast.success('ลบรายการสำเร็จ');
        const temp: MedianPriceListModel[] = [...dataList];
        temp.splice(index, 1);
        onSearch();
        onPaginationChange(pageSize, page);
        setShowDeleteModal(false);
      } else {
        toast.error('เกิดข้อผิดพลาด');
      }
    } else {
      toast.error('เกิดข้อผิดพลาด');
    }

    setShowDeleteModal(false);
  };

  return (
    <div className='m01'>
      <>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='text-primary m-0'>กำหนดราคากลาง (ราคาอ้างอิง)</h4>
        </div>
        <hr />
      </>
      {/* <hr /> */}
      <Row className='criteria'>
        <Row className='criteria'>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Input label='เลขที่บันทึกแต่งตั้ง'
              placeholder='เลขที่บันทึกแต่งตั้ง'
              onChange={appointNumber => setCriteria({ ...criteria, appointNumber })}
              value={criteria.appointNumber} />
          </Col>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Input label='เลขที่รายการจัดซื้อจัดจ้าง'
              placeholder='เลขที่รายการจัดซื้อจัดจ้าง'
              onChange={planNumber => setCriteria({ ...criteria, planNumber })}
              value={criteria.planNumber} />
          </Col>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Input label='เลขที่กำหนดราคากลาง'
              placeholder='เลขที่กำหนดราคากลาง'
              onChange={medianPriceNumber => setCriteria({ ...criteria, medianPriceNumber })}
              value={criteria.medianPriceNumber} />
          </Col>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Selector
              label='ฝ่าย/สำนัก'
              placeholder='ฝ่าย/สำนัก'
              items={departmentDDL}
              onChange={departmentId => setCriteria({ ...criteria, departmentId })}
              value={criteria.departmentId}
            />
          </Col>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Input label='ชื่อโครงการ'
              placeholder='ชื่อโครงการ'
              onChange={planName => setCriteria({ ...criteria, planName })}
              value={criteria.planName} />
          </Col>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <DatePicker
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              placeholder='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              onChangeHasNullable={expectingProcurementAt => setCriteria({ ...criteria, expectingProcurementAt })}
              value={criteria.expectingProcurementAt}
              monthYearOnly
            />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <Currency
              label='วงเงินเริ่มต้น'
              placeholder='0.00'
              onChange={financialAmountMinimum => setCriteria({ ...criteria, financialAmountMinimum })}
              value={criteria.financialAmountMinimum}
            />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <Currency
              label='วงเงินสิ้นสุด'
              placeholder='0.00'
              onChange={financialAmountMaximum => setCriteria({ ...criteria, financialAmountMaximum })}
              value={criteria.financialAmountMaximum}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={6}
            xl={6}>
            <Form.Group className='mb-3'>
              <Form.Label>วิธีจัดหา</Form.Label>
              <InputGroup className='align-items-center gap-3'>
                <Check
                  label={<div className='align-items-center d-flex'>ทั้งหมด <Badge className='h-50 mx-2'
                    bg='light'>{countSupplyMethod.reduce((a, v) => a += v.count, 0)}</Badge>
                  </div>}
                  value={methodAll}
                  onChange={(val) => onChangeMethodAll(val)}
                />
                <Check
                  label={
                    <div className='align-items-center d-flex'>{SupplyMethodNameEnum.Method80}<Badge className='h-50 mx-2'
                      bg='primary'>{countSupplyMethod.filter((x) => x.supplyMethodId === SupplyMethodEnum.MethodId80).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethodId === SupplyMethodEnum.MethodId80)[0].count : 0}</Badge>
                    </div>}
                  value={method80}
                  onChange={(val) => onChangeCheckMethod(SupplyMethodEnum.MethodId80, val)}
                />
                <Check
                  label={<div className='align-items-center d-flex'>{SupplyMethodNameEnum.Method60}
                    <Badge className='h-50 mx-2'
                      bg='primary'>{countSupplyMethod.filter((x) => x.supplyMethodId === SupplyMethodEnum.MethodId60).length > 0 ? countSupplyMethod.filter((x) => x.supplyMethodId === SupplyMethodEnum.MethodId60)[0].count : 0}</Badge>
                  </div>}
                  value={method60}
                  onChange={(val) => onChangeCheckMethod(SupplyMethodEnum.MethodId60, val)}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={12}
            md={6}
            xl={6}>
            <Form.Group className='mb-3'>
              <Form.Label>สถานะ </Form.Label>
              <InputGroup className='align-items-center gap-3'>
                <Check
                  label={<div className='align-items-center d-flex'>ทั้งหมด <Badge className='h-50 mx-2'
                    bg='light'>{countStatus.reduce((a, v) => a += v.count, 0)}</Badge>
                  </div>}
                  value={statusAll}
                  onChange={(val) => onChangeStatusAll(val)}
                />
                <Check
                  label={(
                    <div className='align-items-center d-flex'>รอดำเนินการ <Badge className='h-50 mx-2'
                      bg='secondary'>
                      {countStatus.filter((x) => x.status === MedianPriceStatus.None).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.None)[0].count : 0}
                    </Badge>
                    </div>
                  )}
                  value={statusNone}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.None, val)}
                />
                <Check
                  label={(
                    <div className='align-items-center d-flex'>แบบร่าง <Badge className='h-50 mx-2'
                      bg='secondary'>
                      {countStatus.filter((x) => x.status === MedianPriceStatus.Draft).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.Draft)[0].count : 0}
                    </Badge>
                    </div>
                  )}
                  value={statusDraft}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.Draft, val)}
                />
                <Check
                  label={<div className='align-items-center d-flex'>อยู่ระหว่างคณะกรรมการราคากลางเห็นชอบ <Badge className='h-50 mx-2'
                    bg='warning'>{countStatus.filter((x) => x.status === MedianPriceStatus.WaitingForCommitteeApprove).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.WaitingForCommitteeApprove)[0].count : 0}</Badge>
                  </div>}
                  value={statusWaitingForCommitteeApprove}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.WaitingForCommitteeApprove, val)}
                />
                <Check
                  label={<div className='align-items-center d-flex'>คณะกรรมการราคากลางเห็นชอบ/อนุมัติ <Badge className='h-50 mx-2'
                    bg='success'>{countStatus.filter((x) => x.status === MedianPriceStatus.CommitteeApproved).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.CommitteeApproved)[0].count : 0}</Badge>
                  </div>}
                  value={statusCommitteeApproved}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.CommitteeApproved, val)}
                />
                {/* <Check
                  label={<div className='align-items-center d-flex'>คณะกรรมการราคากลางส่งกลับแก้ไข <Badge className='h-50 mx-2'
                    bg='danger'>{countStatus.filter((x) => x.status === MedianPriceStatus.CommitteeRejected).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.CommitteeRejected)[0].count : 0}</Badge>
                  </div>}
                  value={statusCommitteeRejected}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.CommitteeRejected, val)}
                /> */}
                <Check
                  label={
                    <div className='align-items-center d-flex'>อยู่ระหว่าง ผอ.จพ. มอบหมาย <Badge className='h-50 mx-2'
                      bg='warning'>{countStatus.filter((x) => x.status === MedianPriceStatus.WaitingForJorPorDirector).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.WaitingForJorPorDirector)[0].count : 0}</Badge>
                    </div>}
                  value={statusWaitingForJorPorDirector}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.WaitingForJorPorDirector, val)}
                />
                <Check
                  label={
                    <div className='align-items-center d-flex'>อยู่ระหว่างมอบหมายผู้รับผิดชอบ <Badge className='h-50 mx-2'
                      bg='warning'>{countStatus.filter((x) => x.status === MedianPriceStatus.WaitingForAssignResponsible).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.WaitingForAssignResponsible)[0].count : 0}</Badge>
                    </div>}
                  value={statusWaitingForAssignResponsible}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.WaitingForAssignResponsible, val)}
                />
                <Check
                  label={
                    <div className='align-items-center d-flex'>อยู่ระหว่างจัดตั้งลำดับเห็นชอบ/อนุมัติ <Badge className='h-50 mx-2'
                      bg='warning'>{countStatus.filter((x) => x.status === MedianPriceStatus.DraftSectionApprover).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.DraftSectionApprover)[0].count : 0}</Badge>
                    </div>}
                  value={statusDraftSectionApprover}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.DraftSectionApprover, val)}
                />
                <Check
                  label={
                    <div className='align-items-center d-flex'>อยู่ระหว่างเห็นชอบ/อนุมัติ <Badge className='h-50 mx-2'
                      bg='warning'>{countStatus.filter((x) => x.status === MedianPriceStatus.WaitingForApprove).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.WaitingForApprove)[0].count : 0}</Badge>
                    </div>}
                  value={statusWaitingForApprove}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.WaitingForApprove, val)}
                />
                <Check
                  label={
                    <div className='align-items-center d-flex'>เห็นชอบ/อนุมัติ <Badge className='h-50 mx-2'
                      bg='success'>{countStatus.filter((x) => x.status === MedianPriceStatus.Approved).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.Approved)[0].count : 0}</Badge>
                    </div>}
                  value={statuApproved}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.Approved, val)}
                />
                <Check
                  label={<div className='align-items-center d-flex'>ส่งกลับแก้ไข<Badge className='h-50 mx-2'
                    bg='danger'>{countStatus.filter((x) => x.status === MedianPriceStatus.Rejected).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.Rejected)[0].count : 0}</Badge>
                  </div>}
                  value={statusRejected}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.Rejected, val)}
                />
                <Check
                  label={<div className='align-items-center d-flex'>ยกเลิก<Badge className='h-50 mx-2'
                    bg='danger'>{countStatus.filter((x) => x.status === MedianPriceStatus.Cancelled).length > 0 ? countStatus.filter((x) => x.status === MedianPriceStatus.Cancelled)[0].count : 0}</Badge>
                  </div>}
                  value={statusCancelled}
                  onChange={(val) => onChangeCheckStatus(MedianPriceStatus.Cancelled, val)}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={4}
            xl={3}>
            <Check
              label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
              onChange={isAssigneeOnly => setCriteria({ ...criteria, isAssigneeOnly })}
              value={criteria.isAssigneeOnly}
            />
          </Col>
        </Row>
        <div className='d-flex gap-2'>
          <Button form='formPL01'
            type='submit'
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={onSearch}>
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={onClearSearch}
          >
            <FaEraser className='me-2' /> ล้าง
          </Button>
        </div>
      </Row>
      <Row className='mt-5'>
        <Col sm={12}>
          <Table total={totalRecords}
            onChange={(size, page) => {
              onPaginationChange(size, page);
            }}>
            <thead>
              <tr className='text-center'>
                <th>เลขที่บันทึกแต่งตั้ง</th>
                <th>เลขที่รายการ<br />จัดซื้อจัดจ้าง</th>
                <th>เลขที่<br />กำหนดราคากลาง</th>
                <th style={{ width: 100 }}>สถานะ</th>
                <th>ฝ่าย/สำนัก</th>
                <th>ชื่อโครงการ</th>
                <th>วงเงิน</th>
                <th>วิธีจัดหา</th>
                <th>แก้ไข (ราคากลาง)</th>
                <th>ยกเลิก (ราคากลาง)</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                dataList?.map((item, index) => (
                  <tr className='text-center'
                    key={index}>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <Button
                          variant='link'
                          onClick={() => goToPage(item.id ?? item.termOfRefId)}
                        >{item.appointNumber}
                        </Button>
                      </div>
                    </td>
                    <td className='text-center'>{item.planNumber}</td>
                    <td className='text-center'>{item.medianPriceNumber}</td>
                    <td>
                      <Status value={item.status}
                        type={StatusType.MEDIANPRICE} />
                      {
                        item.isCanceling ? (
                          <div className='status'
                            style={{ marginRight: 10 }}>
                            <span className='text-danger'>({TorEditCancelStatusConstant.Canceling})</span>
                          </div>
                        ) : item.isChanging ? (
                          <div className='status'
                            style={{ marginRight: 10 }}>
                            <span className='text-warning'>({TorEditCancelStatusConstant.Editing})</span>
                          </div>
                        ) : (<></>)
                      }
                    </td>
                    <td className='text-start'>{item.departmentName}</td>
                    <td className='text-start text-wrap'
                      style={{ minWidth: 650, width: 650 }}>{item.planName}</td>
                    <td className='text-end'>{THCurrency(item.financialAmount)}</td>
                    <td className='text-start'>{item.supplyMethodName}</td>
                    <td>
                      {
                        item.isChanging && (
                          <FaCheck className='text-warning' />
                        )
                      }
                    </td>
                    <td>
                      {
                        item.isCanceling && (
                          <FaCheck className='text-warning' />
                        )
                      }
                    </td>
                    <td>
                      {
                        (item.status === MedianPriceStatus.Draft || item.status === MedianPriceStatus.Rejected) && (
                          <div className='d-flex justify-content-center'>
                            <Button
                              disabled={!item.isCommitteeMiddlePrice}
                              variant='danger'
                              className='d-flex align-items-center gap-2'
                              onClick={() => {
                                openModalDelete(index, item.id);
                              }}
                            >
                              <FaTrashAlt />
                            </Button>
                          </div>
                        )
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
            <Modal
              show={showDeleteModal}
              size='lg'
              onHide={() => setShowDeleteModal(!showDeleteModal)}
              children={(
                <>
                  <ModalBT.Header closeButton>
                    <ModalBT.Title>ลบรายการ</ModalBT.Title>
                  </ModalBT.Header>
                  <ModalBT.Body className='p-0 ps-4'>
                    ต้องการลบรายการราคากลาง หรือไม่?
                  </ModalBT.Body>
                  <ModalBT.Footer>
                    <Button variant='outline-danger'
                      onClick={() => setShowDeleteModal(!showDeleteModal)}>
                      ยกเลิก
                    </Button>
                    <Button
                      variant='danger'
                      onClick={() => {
                        deleteAppointmentAsync(indexId, deleteId);
                      }}
                    >
                      ยืนยันลบข้อมูล
                    </Button>
                  </ModalBT.Footer>
                </>
              )}
            />
          </Table>
        </Col>
      </Row>
    </div>
  );
}
