import {
  Modal,
  Table,
} from 'components';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { parcelsList } from '../Detail/data';

interface Props {
  show: boolean;
  onHide?: () => void;
  onSelectItem?: (id: string, details: string, quantity: string, unit: string, typeVat: string, offerPrice: string, quotedPriceTogether: string, agreedPrice: string, agreedPriceTogether: string) => void;
}

export function ParcelsModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecord, setTotalRecords] = useState(0);

  useEffect(() => {
    onGetList(page, size);
  }, []);

  const onSelect = (id: string, details: string, quantity: string, unit: string, typeVat: string, offerPrice: string, quotedPriceTogether: string, agreedPrice: string, agreedPriceTogether: string) => {
    props.onSelectItem!(id, details, quantity, unit, typeVat, offerPrice, quotedPriceTogether, agreedPrice, agreedPriceTogether);
    props.onHide!();
  };

  const onGetList = async (pageParam?: number, sizeParam?: number) => {
    const sizeValue = sizeParam || size;
    const pageValue = pageParam || page;

    // const res = await account.getUsersAsync(
    //   sizeValue,
    //   pageValue,
    // );
    // if (res.status === HttpStatusCode.OK) {
    //   const usersData: UserResponse[] = res.data.data;
    //   setUsers(usersData);
    //   setTotalRecords(res.data.totalRecords);
    // }
  };

  return (
    <Modal
      show={props.show}
      size="xl"
      onHide={() => (props.onHide ? props.onHide() : null)}
    >
      <ModalBT.Header closeButton>
        <ModalBT.Title>รายการพัสดุ</ModalBT.Title>
      </ModalBT.Header>
      <ModalBT.Body className="p-0 ps-4">
        <Table
          className="my-3"
          total={totalRecord}
          onChange={(size, page) => {
            onGetList(page, size);
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 10 }}>ลำดับ</th>
              <th style={{ minWidth: 150 }}>รายละเอียด</th>
              <th style={{ minWidth: 50 }}>จำนวน</th>
              <th style={{ minWidth: 50 }}>หน่วย</th>
              <th style={{ minWidth: 50 }}>ประเภท VAT</th>
              <th style={{ minWidth: 50 }}>ราคาที่ตกลง/<br />ต่อหน่วย</th>
              <th style={{ minWidth: 50 }}>ราคาที่ตกลง<br />รวม</th>
              <th style={{ minWidth: 25 }} />
            </tr>
          </thead>
          <tbody>
            {
              parcelsList.map((data, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td style={{ minWidth: 150 }}>{data.details}</td>
                  <td style={{ minWidth: 50 }}>{data.quantity}</td>
                  <td style={{ minWidth: 50 }}>{data.unit}</td>
                  <td style={{ minWidth: 50 }}>{data.typeVat}</td>
                  <td style={{ minWidth: 50 }}>{data.agreedPrice}</td>
                  <td style={{ minWidth: 50 }}>{data.agreedPriceTogether}</td>
                  <td>
                    <Button variant="primary" className="px-3" onClick={() => onSelect(data.id, data.details, data.quantity, data.unit, data.typeVat, data.offerPrice, data.quotedPriceTogether, data.agreedPrice, data.agreedPriceTogether)}>
                      เลือก
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
