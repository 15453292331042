import {
  DepartmentResponse,
  ItemModel,
} from 'models';
import {
  InYearPlanDetails,
  PL0202Criteria,
} from 'models/PL';
import { LoaderFunctionArgs } from 'react-router-dom';
import {
  account,
  department,
  shareValue,
} from 'services';
import PL0202Service from 'services/PL/PL0202Service';

const PL0202Loader = async () => {
  const requestData = {
    statusDraft: true,
    statusPending: true,
    statusApproved: true,
    statusRejected: true,
    statusAnnouncement: true,
    statusAll: true,
    supplyMethodType: '',
    supplyMethodAll: true,
  } as PL0202Criteria;

  const { data, status } = await PL0202Service.getInYearPlanListAsync(1, 10, requestData);
  const DepartmentItems = await GetDDL('Department');
  const SupplyMethodTypeItems = await GetDDL('SupplyMethodType');
  return {
    departmentList: DepartmentItems,
    supplyMethodTypeList: SupplyMethodTypeItems,
    loaderDataTable: data,
  };
};

const PL0202DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (id !== undefined) {
    const [DepartmentItems, SupplyMethodTypeItems, SupplyMethodNameItems, PositionList, { data }, planData] = await Promise.all([
      GetDDL('Department'),
      GetDDL('SupplyMethodType'),
      GetDDL('SupplyMethodName'),
      GetDDL('Position'),
      account.getUsersAsync(10, 1),
      await PL0202Service.getDetailInYearPlanAsync(id),
    ]);

    return {
      departmentList: DepartmentItems,
      supplyMethodTypeList: SupplyMethodTypeItems,
      supplyMethodTypeNameList: SupplyMethodNameItems,
      positionList: PositionList,
      usersList: data.data,
      planData: planData.data.data,
    };
  }

  const [DepartmentItems, SupplyMethodTypeItems, SupplyMethodNameItems, PositionList, { data }] = await Promise.all([
    GetDDL('Department'),
    GetDDL('SupplyMethodType'),
    GetDDL('SupplyMethodName'),
    GetDDL('Position'),
    account.getUsersAsync(10, 1),
  ]);

  return {
    departmentList: DepartmentItems,
    supplyMethodTypeList: SupplyMethodTypeItems,
    supplyMethodTypeNameList: SupplyMethodNameItems,
    usersList: data.data,
    positionList: PositionList,
    planData: {} as InYearPlanDetails,
  };
};

const GetDDL = async (Type: string) => {
  const itemForMap: ItemModel[] = [];
  if (Type === 'Department') {
    const departmentsRes = await department.getDepartmentsAsync();
    const departmentData: DepartmentResponse[] = departmentsRes.data;
    departmentData.forEach((data) => itemForMap.push({ label: data.name, value: data.id ? data.id : '' }));
  } else if (Type === 'SupplyMethodType') {
    const supplyMethodTypeListRes = await shareValue.getSupplyMethodTypeListAsync();
    const supplyMethodTypeData: ItemModel[] = supplyMethodTypeListRes.data;
    supplyMethodTypeData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
  } else if (Type === 'SupplyMethodName') {
    const supplyMethodListRes = await shareValue.getSupplyMethodListAsync();
    const supplyMethodListData: ItemModel[] = supplyMethodListRes.data;
    supplyMethodListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
  } else if (Type === 'Position') {
    const positionRes = await shareValue.getPostionListSelectionAsync();
    const positionListData: ItemModel[] = positionRes.data;
    positionListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value }));
  }
  return itemForMap;
};

export {
  PL0202Loader,
  PL0202DetailLoader,
};
