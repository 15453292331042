import {
  ButtonCustom,
  Card,
  ContractReceiveCollateral,
  ContractReturnCollateralConsiderStatus,
  Modal,
  SearchBuNameModal,
  StatusCM,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { UserApproveModal } from 'components/Modal/UserApproveModal';
import { StatusCMConsider } from 'components/StatusCMReturnCollateral';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import {
  AcceptorModel,
  AttachmentFileModel,
  ContractReturnCollateralResultModel,
  UpdateReturnCollateralModel,
} from 'models/CM/CM06Model';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaPlus,
  FaTrash,
  FaUndo,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import CM06Service from 'services/CM/CM06Service';
import {
  fullDatetimeTH,
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { Context } from '.';
import Collabora, { CollaboraRef } from '../../../../../components/Document/Collabora';
import { ApproveModal } from '../../Modal/ApproveModal';
import { EditModal } from '../../Modal/EditModal';
import { HistoryModal } from '../../Modal/HistoryModal';
import UploadFile from '../../Modal/UploadFileReturnCollateral';
import { shareValue } from 'services';
import { JorPorCode } from 'constant/basicInformation';
import { isNull } from 'utils/helper';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  dataCollateral: ContractReturnCollateralResultModel;
}

const INFO = [
  { label: 'มีหลักประกัน', value: '1' },
  { label: 'ไม่มีหลักประกัน', value: '2' },
];

function Step2({ onClickNext, onClickBack, dataCollateral }: Props) {
  const navigate = useNavigate();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const { userId, departmentCode } = useAppContext();
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(false);
  const { data, setData } = useContext(Context);
  const [showWaitForApproveModal, setShowWaitForApproveModal] = useState(false);
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [acceptorData, setAcceptorData] = useState<AcceptorModel[]>([]);
  const [docId, setDocId] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [isResponsible, setIsResponsible] = useState(false);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const getDocument = useCallback(async (contractReturnCollateralId: string) => {
    const res = await CM06Service.getDocumentResultAsync(contractReturnCollateralId);

    if (res.status === HttpStatusCode.OK) {
      setDocId(res.data);
    }
  }, [setDocId]);

  useEffect(() => {
    if (data.id) {
      getDocument(data.id);
    }
  }, [data.id, getDocument]);

  const getFilesAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.getAttachmentConsiderAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    handleSetFile(data.attachments);

  }, []);

  useEffect(() => {

    if (data) {

      if (data.id) {
        getFilesAsync(data.id);
      }

      if (!data.status || data.status === ContractReturnCollateralConsiderStatus.NONE || data.status === ContractReturnCollateralConsiderStatus.DRAFT || data.status === ContractReturnCollateralConsiderStatus.INSPECTORREJECTED) {

        setIsDisabled(false);
      } else {

        setIsDisabled(true);
      }

      if (data.acceptors) {
        setAcceptorData(data.acceptors);
        const queueApprover = onCheckQueueApprover(data.acceptors);
        setIsQueueApprover(queueApprover);
      }

      if (data.acceptors && data.acceptors.filter(x => x.userId === userId).length > 0) {
        setIsResponsible(true);
      } else {
        setIsResponsible(false);
      }
    }

  }, [data, userId]);

  const onCheckQueueApprover = (datas: AcceptorModel[]) => {

    if (datas && data.status === ContractReturnCollateralConsiderStatus.WAITFORINSPECTORACCEPT) {

      const committees = datas?.filter(a => a.status === ContractReturnCollateralConsiderStatus.PENDING)
        .sort((a, b) => a.sequence - b.sequence);

      const president = datas?.filter(a => a.status === ContractReturnCollateralConsiderStatus.PENDING);

      const presidentApprove = datas?.filter(a => a.status === ContractReturnCollateralConsiderStatus.PENDING).length === 1;

      if (committees && committees.length > 0) {

        return isNull(committees[0].delegateUserId, committees[0].userId) === userId;
      }

      if (presidentApprove && president && president.length === 1) {

        return isNull(president[0].delegateUserId, president[0].userId) === userId;

      }

      return false;
    }

    return false;
  };

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await CM06Service.uploadAttachmentConsiderAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getFilesAsync(data.id);
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {

    if (data && data.id) {
      const res = await CM06Service.removeAttachmentconsiderAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getFilesAsync(data.id);

      }
    }

  }, [files, data.id]);

  const getReturnCollateralAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.getContractReturnCollateralConsiderByIdAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    setData(data);
  }, []);

  const onSubmitAsync = async (waitForApprove: boolean) => {

    submitForm();

    if (acceptorData.length === 0) {
      toast.warn('กรุณาระบุคณะกรรมการตรวจรับเห็นชอบ');
      return;
    }

    if (data) {

      acceptorData?.forEach((item, i) => {
        item.sequence = i + 1
      });

      const updateDataModel: UpdateReturnCollateralModel = {
        id: data.id,
        acceptors: acceptorData,
        considerResults: data.considerResults,
        inspectorComment: data.inspectorComment,
        sendToAcceptorApprove: waitForApprove,
        collateralChannel: data.collateralChannes,
        fineAmount: data.fineAmount,
        isFine: data.isFine,
      };

      const res = await CM06Service.updateReturnCollateralModelAsync(data.id, updateDataModel);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {

        if (waitForApprove) {

          toast.success('ส่งอนุมัติสำเร็จ');
          setShowWaitForApproveModal(false);
        } else {
          toast.success('บันทึกข้อมูลสำเร็จ');
        }

        getReturnCollateralAsync(data.id);
        navigate(`${location.pathname}`);

      } else {
        toast.error(res.statusText);
      }

      collaboraRef.current?.clickSave();
      setTimeout(async () => {
        await CM06Service.updateDocumentResultAsync(data.id, docId);
      }, 500);
    }
  };

  const onApproved = async (remark: string) => {

    const response = await CM06Service.returnCollateralCommitteesApprovedAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK) {
      toast.success('เห็นชอบสำเร็จ');
      getReturnCollateralAsync(data.id);

    }

    setShowApproveModal(false);
  };

  const onReject = async (remark: string) => {

    submitForm();

    if (!remark) {
      toast.warn('กรุณากรอกหมายเหตุ');
      return;
    }

    const response = await CM06Service.returnCollateralCommitteesRejectAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.CREATED) {
      toast.success('ส่งกลับแก้ไขสำเร็จ');
      getReturnCollateralAsync(data.id);
      setShowRejectModal(false);
    }

  };

  const onSelectEmp = (empId: string, name: string, position: string, department: string, index: number, sectionSequence?: number, sectionName?: string, inRefCode?: string) => {

    if (acceptorData?.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
      return;
    }

    const newData: AcceptorModel[] = acceptorData || [];

    newData.push({
      // id: '',
      sequence: acceptorData.length + 1,
      userId: empId,
      fullName: name,
      positionName: position,
      departmentName: department,
      isAvailable: false,
      availableRemark: '',
    } as AcceptorModel);

    setAcceptorData(newData);
  };

  const handlerRemoveAcceptorData = (index: number) => {
    const temp: AcceptorModel[] = [...acceptorData];
    temp.splice(index, 1);
    setAcceptorData(temp);
  };

  return (
    <div className='document'>
      <Title text='พิจารณาคืนหลักประกันสัญญา'
        extraElement={
          <>
            <StatusCMConsider systemStatue={data.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </>
        } />

      <Row className='mt-4 align-items-center'>
        <div className='d-flex justify-content-between'>
          <h5 className='fw-bold'>คณะกรรมการตรวจรับเห็นชอบ</h5>
          <Button
            variant='primary'
            disabled={isDisabled}
            onClick={() => setShowUserModal(true)}
          >
            <FaPlus className='me-1' /> เพิ่มรายชื่อ
          </Button>

        </div>
        <Col sm={12}>
          <Table
            hidePagination
            className='mt-3 step-bar'
            notResponse
          >
            <thead>
              <tr>
                <th className='text-center ps-3'
                  style={{ width: 100 }}>ลำดับ
                </th>
                <th className='text-start'
                  style={{ width: '50%' }}>ชื่อ-นามสกุล
                </th>
                <th className='text-start' style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน</th>
                <th style={{ width: 100 }}>สถานะ</th>
                <th className='text-center'
                  style={{ width: 120 }}>วันทีรับทราบ
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {acceptorData?.map((data, index) => (
                <tr className='text-start'
                  key={data.id}>
                  <td className='text-center'>{index + 1}</td>
                  <td className='text-left'>
                    {data.fullName}
                    <p className='m-0 department'>
                      {data.positionName}
                    </p>
                  </td>
                  <td className='text-left'>
                    {data.delegateFullName &&
                      <>
                        {data.delegateFullName}
                        <p className='m-0 department'>
                          {data.delegatePositionName}
                        </p>
                      </>
                    }
                  </td>
                  <td><StatusCM systemStatue={data.status}
                    systemName='cm-03' />
                  </td>
                  <td className='text-center'>{fullDatetimeTH(data.acceptDate!)}</td>
                  <td className='text-end'>
                    <Button
                      onClick={() => handlerRemoveAcceptorData(index)}
                      variant='danger'
                      disabled={isDisabled}
                    >
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Card className='mt-3'>
        <Title
          text='เอกสารผลการพิจารณาคืนหลักประกันสัญญา'
          className='fs-5'
          lineBorder
        />
        <div className='mt-3'>
          <Collabora
            docId={docId}
            docName='doc-01'
            ref={collaboraRef}
            readonly={false} />
        </div>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5' />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFile
              disabled={!data.id}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.id!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <ButtonCustom text='ย้อนกลับ'
            onClick={onClickBack}
            icon='arrowback'
            variant='outline-primary' />
        </div>
        {
          ((data.status === ContractReturnCollateralConsiderStatus.DRAFT || data.status === ContractReturnCollateralConsiderStatus.INSPECTORREJECTED))
          && (
            <div className='d-flex gap-3 align-items-center'>
              <ButtonCustom text='บันทึก'
                variant='outline-primary'
                onClick={() => onSubmitAsync(false)} />
              <ButtonCustom
                icon="checked"
                text='ส่งเห็นชอบ'
                onClick={() => setShowWaitForApproveModal(true)} />
            </div>
          )
        }
        {
          (isQueueApprover && data.status === ContractReturnCollateralConsiderStatus.WAITFORINSPECTORACCEPT) && (
            <div className='d-flex gap-3 align-items-center'>
              <ButtonCustom
                onClick={() => setShowRejectModal(true)}
                icon="undo"
                text="ส่งกลับแก้ไข"
                variant='danger'
              />
              <ButtonCustom
                onClick={() => setShowApproveModal(true)}
                className='me-2 ms-2'
                variant='success'
                text='เห็นชอบ'
                icon="checked"
              />
            </div>
          )
        }
        {
          (
            (data.status && data.status === ContractReturnCollateralConsiderStatus.WAITFORJORPORASSIGN)
            || data.status === ContractReturnCollateralConsiderStatus.JORPORASSIGNED)
            ? (<div>
              <ButtonCustom text='ถัดไป'
                onClick={onClickNext}
                icon='arrowforward'
                variant='outline-primary'
                iconAlignRight />
            </div>)
            : <div style={{ width: 145 }} />
        }

      </div>

      <Modal
        show={showWaitForApproveModal}
        size='lg'
        onHide={() => setShowWaitForApproveModal(!showWaitForApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งผู้มีอำนาจเห็นชอบ/อนุมัติ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitForApproveModal(!showWaitForApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <EditModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(!showRejectModal)}
        onRemark={onReject}
      />

      <ApproveModal
        show={showApproveModal}
        onRemark={onApproved}
        click={() => setShowApproveModal(!showApproveModal)}
        isApprover={isApprover}
      />

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={data.id}
        title='คืนหลักประกันสัญญา'
        status={ContractReceiveCollateral.Consider}
      />

      {/* <UserApproveModal
        show={showUserModal}
        onHide={() => setShowUserModal(!showUserModal)}
        onSelectItem={onSelectEmp}
      /> */}

      <SearchBuNameModal
        total={userTotal}
        show={showUserModal}
        onHide={() => setShowUserModal(!showUserModal)}
        onSelect={(id, name, department, position) => onSelectEmp(id, name, position!, department!, acceptorData.length + 1)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

    </div>
  );
}

export default Step2;
