import { Modal } from 'components';
import { IFile } from 'models/P/FileUploadModel';
import { useState } from 'react';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import {
  FaCloudUploadAlt,
  FaFileAlt,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaTrashAlt,
} from 'react-icons/fa';
import InvitationServices from 'services/PR/InvitationServices';
import { HttpStatusCode } from 'utils';

interface Props {
  inviteEntreprenurId: string;
  files: IFile[];
  handleFileChange: (files: File[]) => void;
  removeItem: (index: number, docName: string) => void;
  disabled?: boolean;
}

const fileTypes = ['PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PNG', 'JPG', 'JPEG', 'mp3'];

export default function UploadFile({ files, handleFileChange, removeItem, inviteEntreprenurId, disabled = false }: Props) {
  const [dragEnter, setDragEnter] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const [deleteName, setDeleteName] = useState<string>('');

  const onOpenDeleteFile = (index: number, name: string) => {
    setDeleteIndex(index);
    setDeleteName(name);
    setShowDeleteConfirm(true);
  };

  const onDeleteFile = () => {
    removeItem(deleteIndex, deleteName);
    setShowDeleteConfirm(false);
  };

  return (
    <>
      <div className='upload-file'
        draggable>
        <FileUploader
          className='browse'
          disabled={disabled}
          multiple
          handleChange={handleFileChange}
          name='file'
          types={fileTypes}
          onDraggingStateChange={setDragEnter}
          hoverTitle='-'
        >
          <div
            className={`browse ${dragEnter ? 'drag-enter' : ''}`}
            style={{ paddingLeft: 6, paddingRight: 6, textAlign: 'center' }}
          >
            <FaCloudUploadAlt />
            <span>Drag and drop or <span className='text-primary'>browse</span>.</span>
            <span>รองรับไฟล์ที่มีนามสกุล .doc, .docx, .xls, .xlsx, .csv, .pdf, .png, .jpg, .jpeg และมีขนาดไฟล์ไม่เกิน 10 MB</span>
          </div>
        </FileUploader>
        <div className='file-list'>
          {
            Array.from(files)
              .map((f, index) => (
                <RenderFile key={f.id}
                  inviteEntreprenurId={inviteEntreprenurId}
                  file={f}
                  removeItem={() => onOpenDeleteFile(index, f.name)}
                  disabled={disabled} />))
          }
        </div>
      </div>
      <Modal
        show={showDeleteConfirm}
        size='lg'
        onHide={() => setShowDeleteConfirm(!showDeleteConfirm)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยันการลบเอกสารแนบ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการลบเอกสารแนบ หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDeleteConfirm(!showDeleteConfirm)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onDeleteFile}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

function RenderFile(props: { inviteEntreprenurId: string, file: IFile, removeItem: () => void, disabled: boolean }) {
  const handleOpenFileAsync = async (file: IFile) => {
    if (!file.file) {
      const { data, status } = await InvitationServices.getInviteDocumentAsync(file.id);

      if (status !== HttpStatusCode.OK) {
        return;
      }

      const fileUrl = URL.createObjectURL(data);
      window.open(fileUrl, '_blank');

      return;
    }

    const fileUrl = URL.createObjectURL(file.file);
    window.open(fileUrl, '_blank');
  };

  const fileIcon = (name: string) => {
    switch (true) {
      case name.includes('.pdf'):
        return (<FaFilePdf />);
      case name.includes('.doc') || name.includes('.docx'):
        return (<FaFileWord />);
      case name.includes('.xls') || name.includes('.xlsx'):
        return (<FaFileExcel />);
      case name.includes('.csv'):
        return (<FaFileCsv />);
      case name.includes('.png') || name.includes('jpg') || name.includes('jpeg'):
        return (<FaFileImage />);
      default:
        return (<FaFileAlt />);
    }
  };

  return (
    <div className='file'>
      <div className='file-icon'>
        {fileIcon(props.file.name)}
      </div>
      <div className='detail'>
        <a
          onClick={() => handleOpenFileAsync(props.file)}
          className='filename'
        >
          {props.file.name}
        </a>
      </div>
      {!props.disabled
        ? (
          <div className='trash'>
            <Button
              variant='danger'
              className='d-flex align-items-center gap-2'
              onClick={() => props.removeItem()}
            >
              <FaTrashAlt />
            </Button>
          </div>
        ) : null}
    </div>
  );
}
