import {
  CreatePlanAnnouncement,
  PlanAnnouncementListResponseModel,
  PlanProcurementAnnouncementCriteria,
  RejectPlanAnnouncement,
  SearchPL0302Model,
  SearchPlanDetailModel,
} from 'models/planAnnouncement';
import qs from 'qs';
import http from 'utils/axios';
import { IReplaceText } from '../models/CollaboraModel';

const api = 'planAnnouncement';

const getCountStatusAsync = async () => await http.get(`${api}/count-status`);

const getCountPlanTypeAsync = async () => await http.get(`${api}/count-plan-type`);

const createMainDocumentAsync = (planIds: string[], templateName: string, replaceList: IReplaceText) => {
  const body = {
    annualPlanIds: planIds,
    templateName,
    replaceList,
  };

  return http.post(`${api}/main-document`, body);
};

const getPlansAsync = (searchDto: SearchPL0302Model) => http.get(`/${api}`, {
  params: searchDto,
  paramsSerializer: (params) => qs.stringify(params),
});

const getPlansDetailAsync = (searchDto: SearchPlanDetailModel) => http.get<PlanAnnouncementListResponseModel>(`${api}/detail-list`, {
  params: searchDto,
  paramsSerializer: (params) => qs.stringify(params),
});

const announcementAsync = async (body: CreatePlanAnnouncement) => await http.put(`${api}/announcement-list`, body);

const rejectAsync = async (body: RejectPlanAnnouncement) => await http.put(`${api}/reject-list`, body);

const getPlanProcurementAnnouncementListAsync = async (page: number, size: number, criteria?: PlanProcurementAnnouncementCriteria) => {
  const params = {
    page,
    size,
    ...criteria,
  };

  return await http.get('/planprocurementannouncement', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

export default {
  getCountStatusAsync,
  getCountPlanTypeAsync,
  getPlansAsync,
  getPlansDetailAsync,
  announcementAsync,
  rejectAsync,
  createMainDocumentAsync,
  getPlanProcurementAnnouncementListAsync,
};
