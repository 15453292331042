import {
  Approver,
  ApproverStatus,
  Card,
  FileValue,
  Input,
  InputTextArea,
  Modal,
  SearchBuNameModal,
  Selector,
  Table,
  ButtonCustom,
  ArrowCollapse,
} from 'components';
import Title from 'components/Controls/Title';
import {
  CreatorLevel,
  JorPorCode,
} from 'constant/basicInformation';
import Cookie from 'cookie-universal';
import { ApproverModel, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { sectionApproverModel } from 'models/delegateModel';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {
  FaAngleLeft,
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaEraser,
  FaPlus,
  FaRedo,
  FaRegCheckCircle,
  FaSave,
  FaSearch,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import { useLoaderData } from 'react-router-dom';
import {
  account,
  planProcurement as planProcurementService,
  shareValue,
} from 'services';
import {
  downloadFile,
  fullDatetime,
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';
import {
  AcceptorStatus,
  AssignLevel,
  PlanStatus as PlanStatusCons,
} from 'utils/constants/PlanEnum';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { Context } from '.';
import Collabora, { CollaboraRef } from '../../../components/Document/Collabora';
import publicYearDetailService from '../../../services/PP/PublicYearDetailService';
import { isNull, nullObject } from '../../../utils/helper';
import { UploadFile } from './UploadFile';
import { AccountModel } from 'models/accountModel';

Cookie();

interface Props {
  onClickBack?: () => void;
}

type Loader = {
  departments: { value: string, label: string }[],
  positions: { value: string, label: string }[],
}

type UserCriteria = {
  name: string;
  departmentId: string;
  positionId: string;
}

type UserResponse = {
  id: string;
  no: number;
  name: string;
  department: string;
  position: string;
  status: string;
  UserDate: Date;
  isAvailable: boolean;
}

type sectionGroup = {
  name: string;
  sequence: number;
  level: number;
  inRefCode: string;
}

export default function Step4(props: Props) {
  const { currentStep, planProcurement, setPlanProcurement, reGetDetailAsync, readonly } = useContext(Context);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [section, setSection] = useState<string>();
  const [approverCollapsed, setApproverCollapsed] = useState<boolean>(true);
  const [jorporCommentCollapsed, setJorPorCommentCollapsed] = useState<boolean>(true);
  const [documentCollapsed, setDocumentCollapsed] = useState<boolean>(true);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [showPendingModal, setShowPendingModal] = useState<boolean>(false);
  const { userId, departmentId, departmentCode, inRefLevel } = useAppContext();
  const [docId, setDocId] = useState<string>('');
  const [docApprovePrincipleId, setDocApprovePrincipleId] = useState<string>('');
  const [planProcurementId, setPlanProcurementId] = useState<string>('');
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [showDefaultApproverModal, setShowDefaultApproverModal] = useState<boolean>(false);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const collaboraRef = useRef<CollaboraRef>(null);
  const collaboraApprovePrincipleRef = useRef<CollaboraRef>(null);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const getUserProfileByIdAsync = async (userId: string) => {
    const { data, status } = await account.getUserByIdAsync(userId);

    if (status === HttpStatusCode.OK) {
      return data as AccountModel;
    }
  };

  function isNull(oldValue?: string, newValue?: string) {
    if (oldValue) {
      return oldValue;
    }

    return newValue;
  }

  const canRework = useMemo(() => {
    if (!planProcurement || currentStep !== 4 || !userId) {
      return false;
    }

    if (planProcurement.isCanceled && planProcurement.isActive) {
      return false;
    }

    if (planProcurement.jorporDirectorBeInformed?.filter((x) => isNull(x.delegateUserId, x.userId) === userId).length > 0 || planProcurement.jorporDirectorAssign?.filter((x) => isNull(x.delegateUserId, x.userId) === userId).length > 0) {
      return true;
    }

    return false;
  }, [planProcurement, currentStep, userId]);

  const canRemoveFile = useMemo(() => {
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = Math.max.apply(null,
      planProcurement.approvers?.map((x) => {
        return x.sectionSequence;
      }));

    const valueSequence = planProcurement.approvers?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  useEffect(() => {
    onCheckApproverStatus();
  }, []);

  const getMainDocument = useCallback(async (planId: string) => {
    setPlanProcurementId(planId);
    const {
      data: docId,
      status,
    } = await publicYearDetailService.createInYearDocumentAsync(planId);

    if (status === HttpStatusCode.OK) {
      setPlanProcurement(pre => ({ ...pre, documentId: docId }));
      setDocId(docId);
    }
  }, [setPlanProcurement]);

  const getDocumentApprovePrinciple = useCallback(async (planId: string) => {
    const {
      data: docId,
      status,
    } = await publicYearDetailService.createDocumentApprovePrincipleAsync(planId);

    if (status === HttpStatusCode.OK) {
      setDocApprovePrincipleId(docId);
    }
  }, []);

  useEffect(() => {
    if (currentStep === 4 || currentStep === 5) {
      setApproverCollapsed(false);
      setDocumentCollapsed(false);
      onGetDefaultApprover();
    }
  }, [currentStep]);

  useEffect(() => {
    if (!nullObject(planProcurement) && planProcurementId !== planProcurement.id) {
      getMainDocument(planProcurement.id);
      getDocumentApprovePrinciple(planProcurement.id);
    }
  }, [planProcurement, planProcurementId, getMainDocument]);

  const userOnSelect = (
    userId: string,
    fullName: string,
    departmentName: string,
    positionName: string,
  ) => {
    if (planProcurement.approvers.filter(a => a.userId === userId && a.sectionType === section).length > 0) {
      toast.warn('ไม่สามารถระบุผู้มีอำนาจซ้ำได้');

      return;
    }

    const acceptorBySection = planProcurement.approvers.filter((x) => x.sectionType === section);

    const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
      acceptorBySection.map((x) => {
        return x.sequence;
      })) : 0;

    setPlanProcurement({
      ...planProcurement,
      approvers: [
        ...planProcurement.approvers,
        {
          userId,
          fullName,
          departmentName,
          positionName,
          sectionCode: 'Approver',
          sequence: maxValueSequence + 1,
          status: AcceptorStatus.DRAFT,
          sectionType: section,
          sectionSequence: sections.filter(x => x.inRefCode === section)[0].sequence,
        } as ApproverModel,
      ],
    });
  };

  const onGetDefaultApprover = async () => {
    const res = await account
      .getDefaultApprover(PROCESSTYPE.ApprovePlanProcess, planProcurement.budget);

    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
      const newDatas = res.data as sectionApproverModel[];
      const sectionDatas: sectionGroup[] = [];
      const approver: ApproverModel[] = [];

      if (planProcurement.approvers === undefined ||
        planProcurement.approvers === null ||
        planProcurement.approvers.length === 0) {
        newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {
            sectionDatas.push({
              name: item.sectionName,
              level: item.sectionLevel,
              sequence: item.sectionSequence,
              inRefCode: item.inRefCode,
            } as sectionGroup);

            approver.push({
              id: undefined,
              sectionCode: 'Approver',
              userId: item.userId,
              fullName: item.fullName,
              departmentName: item.departmentName,
              positionName: item.positionName,
              status: AcceptorStatus.DRAFT,
              at: undefined,
              isAvailable: false,
              isAvailableRemark: item.delegateAnnotation,
              acceptDate: undefined,
              acceptRemark: '',
              rejectDate: undefined,
              rejectRemark: '',
              sequence: index + 1,
              sectionType: item.inRefCode,
              sectionSequence: item.sectionSequence,
              delegateUserId: item.delegateUserId,
              delegateEmployeeCode: item.delegateEmployeeCode,
              delegateFullName: item.delegateFullName,
              delegateDepartmentName: item.delegateDepartmentName,
              delegatePositionName: item.delegatePositionName,
            } as ApproverModel);
          });

        const uniqueNames = sectionDatas.filter((data, index) => {
          return sectionDatas.findIndex(d => d.inRefCode === data.inRefCode) === index;
        });
        setSections(uniqueNames);

        setPlanProcurement({
          ...planProcurement,
          approvers: approver,
        });
      } else {
        planProcurement.approvers.forEach((item, index) => {
          sectionDatas.push({
            name: item.sectionName,
            level: 0,
            sequence: item.sectionSequence,
            inRefCode: item.sectionType,
          } as sectionGroup);
        });

        const uniqueNames = sectionDatas.filter((data, index) => {
          return sectionDatas.findIndex(d => d.inRefCode === data.inRefCode) === index;
        });
        setSections(uniqueNames);
      }
    }
  };

  const handlerOnSubmitAsync = async (status: string) => {
    submitForm();

    if (!planProcurement.approvers?.length) {
      toast.warn('ต้องมีผู้มีอำนาจเห็นชอบอย่างน้อย 1 คน');

      return;
    }

    if (status === PlanStatusCons.WaitingAcceptor) {
      if (planProcurement.approvers.every(s => s.isAvailable)) {
        toast.warn('ต้องผู้มีอำนาจเห็นชอบที่สามารถปฏิบัติงานได้อย่างน้อย 1 คน');

        return;
      }
    }

    collaboraRef.current?.clickSave();
    planProcurement.status = status;
    planProcurement.documentId = docId;

    if (planProcurement.approvers && planProcurement.approvers.length > 0) {
      let inx = 0;
      let sectionSeq = 0;

      planProcurement.approvers.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {
          if (sectionSeq !== item.sectionSequence) {
            inx = 1;
            sectionSeq = item.sectionSequence;
          }

          planProcurement.approvers[index].sequence = inx;

          inx++;
        });
    }

    setTimeout(async () => {
      const res = await planProcurementService
        .updateAsync(planProcurement, status === PlanStatusCons.WaitingAcceptor);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
        reGetDetailAsync(planProcurement.id);

        if (status === PlanStatusCons.WaitingAcceptor) {
          toast.success('ส่งเห็นชอบสำเร็จ');
          setShowPendingModal(false);
        } else {
          toast.success('บันทึกสำเร็จ');
        }

        window.location.reload();
      }
    }, 500);

  };

  const handlerSetDefaultApproverAsync = async () => {
    const res = await account
      .getDefaultApprover(PROCESSTYPE.ApprovePlanProcess, planProcurement.budget);

    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
      const newDatas = res.data as sectionApproverModel[];
      const sectionDatas: sectionGroup[] = [];
      const approver: ApproverModel[] = [];

      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {
          sectionDatas.push({
            name: item.sectionName,
            level: item.sectionLevel,
            sequence: item.sectionSequence,
            inRefCode: item.inRefCode,
          } as sectionGroup);

          approver.push({
            id: undefined,
            sectionCode: 'Approver',
            userId: item.userId,
            fullName: item.fullName,
            departmentName: item.departmentName,
            positionName: item.positionName,
            status: AcceptorStatus.DRAFT,
            at: undefined,
            isAvailable: false,
            isAvailableRemark: item.delegateAnnotation,
            acceptDate: undefined,
            acceptRemark: '',
            rejectDate: undefined,
            rejectRemark: '',
            sequence: index + 1,
            sectionType: item.inRefCode,
            sectionSequence: item.sectionSequence,
            delegateUserId: item.delegateUserId,
            delegateEmployeeCode: item.delegateEmployeeCode,
            delegateFullName: item.delegateFullName,
            delegateDepartmentName: item.delegateDepartmentName,
            delegatePositionName: item.delegatePositionName,
          } as ApproverModel);
        });

      const uniqueNames = sectionDatas.filter((data, index) => {
        return sectionDatas.findIndex(d => d.inRefCode === data.inRefCode) === index;
      });
      setSections(uniqueNames);

      setPlanProcurement({
        ...planProcurement,
        approvers: approver,
      });

      setShowDefaultApproverModal(false);
      toast.success('กำหนดผู้มีอำนาจเห็นชอบตั้งต้น สำเร็จ');
    } else {
      toast.warn('กำหนดผู้มีอำนาจเห็นชอบตั้งต้น ไม่สำเร็จ');
    }
  };

  const formTableOnChange = (
    section: string,
    userId: string,
    key: string,
    value: string | boolean,
  ) => {
    const index = planProcurement.approvers.findIndex(a => a.sectionType === section && a.userId === userId);

    if (key === 'isAvailable') {
      planProcurement.approvers[index] = {
        ...planProcurement.approvers[index],
        isAvailable: Boolean(value),
        isAvailableRemark: '',
      };
    } else {
      planProcurement.approvers[index] = {
        ...planProcurement.approvers[index],
        [key]: value,
      };
    }

    setPlanProcurement({
      ...planProcurement,
      approvers: planProcurement.approvers,
    });
  };

  const uploadFileOnChangeAsync = async (file: File) => {
    if (planProcurement.id) {
      const res = await planProcurementService.uploadFileAsync(
        planProcurement.id,
        file,
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        setPlanProcurement({
          ...planProcurement,
          documents: [
            ...planProcurement.documents,
            {
              file,
            },
          ],
        });

        toast.success('อัปโหลดไฟล์สำเร็จ');

        reGetDetailAsync(planProcurement.id);
      }
    } else {
      if (!planProcurement.documents) {
        planProcurement.documents = [];
      }

      setPlanProcurement({
        ...planProcurement,
        documents: [
          ...planProcurement.documents,
          {
            file,
          },
        ],
      });
    }
  };

  const files = useCallback(() => {
    const files = planProcurement.documents?.filter((d) => d.id && d.name);

    return files?.map((f) => ({
      id: f.id,
      name: f.name,
      attachmentBy: f.byUserId,
    })) as FileValue[];
  }, [planProcurement.documents]);

  const removeFileAsync = async (i: number, id: string | undefined) => {
    if (id) {
      const res = await planProcurementService.removeFileAsync(planProcurement.id, id);

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
      }
    }

    planProcurement.documents.splice(i, 1);

    setPlanProcurement({
      ...planProcurement,
      documents: [...planProcurement.documents],
    });
  };

  const removeUser = (userId: string, inRefCode: string) => {
    const index = planProcurement.approvers.findIndex((p) => p.userId === userId && p.sectionType === inRefCode);

    planProcurement.approvers.splice(index, 1);

    setPlanProcurement({
      ...planProcurement,
      approvers: [
        ...planProcurement.approvers,
      ],
    });
  };

  const downloadFileAsync = async (index: number, documentId?: string) => {
    if (documentId) {
      const res = await planProcurementService
        .downloadFileAsync(planProcurement.id, documentId);

      if (res.status === HttpStatusCode.OK) {
        const file = planProcurement.documents[index];

        downloadFile(res.data);
      }
    } else {
      const file = planProcurement.documents[index];

      if (file.file) {
        downloadFile(file.file);
      }
    }
  };

  const canAdd = useMemo(() => {
    if (!planProcurement || (currentStep !== 4 && currentStep !== 5) || !userId) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftRecordDocument &&
      planProcurement.status !== PlanStatusCons.WaitingAcceptor) {
      return false;
    }

    if (!planProcurement.jorporDirectorAssign?.some(j => isNull(j.delegateUserId, j.userId) === userId)) {
      return false;
    }

    if (planProcurement.status === PlanStatusCons.WaitingAcceptor) {
      if (planProcurement.approvers?.some(d => !d.isAvailable && d.status === AcceptorStatus.PENDING && d.id)) {
        return false;
      }
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canRemove = useCallback((approverId: string | undefined) => {
    if (!planProcurement || (currentStep !== 4 && currentStep !== 5) || !userId) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftRecordDocument &&
      planProcurement.status !== PlanStatusCons.WaitingAcceptor) {
      return false;
    }

    if (!planProcurement.jorporDirectorAssign?.some(j => isNull(j.delegateUserId, j.userId) === userId)) {
      return false;
    }

    return !(planProcurement.status === PlanStatusCons.WaitingAcceptor &&
      approverId);
  }, [planProcurement, currentStep, userId]);

  const canSave = useMemo(() => {
    if (!planProcurement || (currentStep !== 4 && currentStep !== 5) || !userId) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftRecordDocument) {
      return false;
    }

    return planProcurement.jorporDirectorAssign?.some(j => isNull(j.delegateUserId, j.userId) === userId);
  }, [planProcurement, currentStep, userId]);

  function isJorporDirectorAssignLevel1() {
    const assignLevel1 = planProcurement?.jorporDirectorAssign?.filter((d) => d.level === AssignLevel.Level1);

    if (assignLevel1
      && assignLevel1.length > 0) {
      return !!assignLevel1.some((j) => isNull(j.delegateUserId, j.userId) === userId);
    }
    return false;
  }

  const canSendApprove = useMemo(() => {
    if (!planProcurement || currentStep !== 4 || !userId) {
      return false;
    }

    if (!isJorporDirectorAssignLevel1()) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftRecordDocument) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  return (
    <>
      {/* <Card className='mt-3'>

      <div
        className='d-flex align-items-center gap-3'
      >
        <Title
          text='จัดทำความเห็นเจ้าหน้าที่พัสดุ (ขออนุมัติหลักการ)'
          className='fs-5 text-primary'
        />
        {jorporCommentCollapsed
          ? (
            <FaChevronCircleDown
              className='text-primary fs-5 cursor-pointer'
              onClick={() => setJorPorCommentCollapsed(false)}
            />
          ) : (
            <FaChevronCircleUp
              className='text-primary fs-5 cursor-pointer'
              onClick={() => setJorPorCommentCollapsed(true)}
            />
          )}
      </div>

      <Collapse in={!jorporCommentCollapsed}
        className='mt-3'>
        <div>
          <div className='d-flex justify-content-between title-head ps-40'>
            <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
              <p className='fw-bold mb-0 fs-5'>ผอ.จพ.</p>
            </div>
          </div>
          <div className='ps-40'>
            <Table
              hidePagination
              className='mt-3 step-bar'
              notResponse
            >
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>ลำดับ</th>
                  <th className='text-start'
                    style={{ width: '30%' }}>ชื่อ-นามสกุล
                  </th>
                  <th className='text-start'
                    style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                  </th>
                  <th style={{ minHeight: 100, width: '25%' }} />
                </tr>
              </thead>
              <tbody>
                {planProcurement.jorporDirectorAssign?.filter((x) => x.level === AssignLevel.Level0).sort((a, b) => a.sequence - b.sequence)
                  .map((data, i) => (
                    <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                      key={data.id}>
                      <td className='text-center'>{i + 1}</td>
                      <td>
                        {data.fullName}
                        <p className='m-0 department'>
                          {data.positionName}
                        </p>
                      </td>
                      <td className='text-left'>
                        {data.delegateFullName &&
                          <>
                            {data.delegateFullName}
                            <p className='m-0 department'>
                              {data.delegatePositionName}
                            </p>
                          </>
                        }
                      </td>
                      <td />
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div className='d-flex justify-content-between title-head ps-40'>
            <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
              <p className='fw-bold mb-0 fs-5'>ผอ.จพ. มอบหมาย</p>
            </div>
          </div>

          <div className='ps-40'>
            <div className='d-flex justify-content-end'>
              <Button
                className='d-flex align-items-center gap-2'
                variant='outline-primary'
                disabled={false}>
                <FaPlus />เพิ่มรายชื่อ
              </Button>
            </div>
            <Table
              hidePagination
              className='mt-3 step-bar'
              notResponse
            >
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>ลำดับ</th>
                  <th className='text-start'
                    style={{ width: '30%' }}>ชื่อ-นามสกุล
                  </th>
                  <th className='text-start'
                    style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                  </th>
                  <th style={{ minHeight: 100, width: '25%' }} />
                </tr>
              </thead>
              <tbody>
                {planProcurement.jorporDirectorAssign?.filter((x) => x.level === AssignLevel.Level1)?.sort((a, b) => a.sequence - b.sequence)
                  .map((data, i) => (
                    <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                      key={data.id}>
                      <td className='text-center'>{i + 1}</td>
                      <td>
                        {data.fullName}
                        <p className='m-0 department'>
                          {data.positionName}
                        </p>
                      </td>
                      <td className='text-left'>
                        {data.delegateFullName &&
                          <>
                            {data.delegateFullName}
                            <p className='m-0 department'>
                              {data.delegatePositionName}
                            </p>
                          </>
                        }
                      </td>
                      <td className='text-end'>
                        <Button
                          variant='danger'
                        >
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div className='d-flex justify-content-between title-head ps-40'>
            <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
              <p className='fw-bold mb-0 fs-5'>ผู้รับผิดชอบ</p>
            </div>
          </div>

          <div className='ps-40'>
            <div className='d-flex justify-content-end'>
              <Button
                className='d-flex align-items-center gap-2'
                variant='outline-primary'
              >
                <FaPlus />เพิ่มรายชื่อ
              </Button>
            </div>
            <Table
              hidePagination
              className='mt-3 step-bar'
              notResponse
            >
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>ลำดับ</th>
                  <th className='text-start'
                    style={{ width: '30%' }}>ชื่อ-นามสกุล
                  </th>
                  <th className='text-start'
                    style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                  </th>
                  <th style={{ minHeight: 100, width: '25%' }} />
                </tr>
              </thead>
              <tbody>
                {planProcurement.approvers?.sort((a, b) => a.sequence - b.sequence)
                  .map((data, i) => (
                    <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                      key={data.id}>
                      <td className='text-center'>{i + 1}</td>
                      <td>
                        {data.fullName}
                        <p className='m-0 department'>
                          {data.positionName}
                        </p>
                      </td>
                      <td className='text-left'>
                        {data.delegateFullName &&
                          <>
                            {data.delegateFullName}
                            <p className='m-0 department'>
                              {data.delegatePositionName}
                            </p>
                          </>
                        }
                      </td>
                      <td className='text-end'>
                        <Button
                          variant='danger'
                        >
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div className='d-flex justify-content-between title-head ps-40'>
            <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
              <p className='fw-bold mb-0 fs-5'>ความเห็น จพ.</p>
            </div>
          </div>
          <div className='ps-40'>
            <InputTextArea
              label='ความเห็นเพิ่มเติม'
              value={planProcurement.remark}
              rule={{ required: true }}
              disabled={false} />
          </div>

          <div className='d-flex justify-content-between title-head ps-40'>
            <div className='d-flex align-items-center justify-content-between gap-3 bg-white'>
              <p className='fw-bold mb-0 fs-5'>เอกสารขออนุมัติหลักการ</p>
            </div>
          </div>
          <div className='ps-40'>
            <Collabora
              docId={docApprovePrincipleId}
              docName='doc-02'
              ref={collaboraApprovePrincipleRef}
              readonly={false} />
          </div>
        </div>
      </Collapse>
    </Card > * /}

  {/* Approver */ }
      <Card className='mt-3'>

        {/* Title */}
        <div
          className='d-flex align-items-center gap-3'
        >
          <Title
            text='ผู้มีอำนาจเห็นชอบ/อนุมัติ'
            className='fs-5 text-primary'
          />
          {approverCollapsed
            ? (
              <FaChevronCircleDown
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setApproverCollapsed(false)}
              />
            ) : (
              <FaChevronCircleUp
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setApproverCollapsed(true)}
              />
            )}
        </div>
        <Collapse in={!approverCollapsed}
          className='mt-3'>
          <div>
            {canAdd
              ? (
                <Button
                  className='d-flex align-items-center gap-2'
                  variant='outline-primary'
                  onClick={() => {
                    setShowDefaultApproverModal(true);
                  }}
                >
                  <FaRedo />กำหนดผู้มีอำนาจเห็นชอบเริ่มต้น
                </Button>
              ) : null}
            {
              (sections && sections.length > 0) && (
                sections
                  .sort((a, b) => a.sequence - b.sequence)
                  .map((section, index) => (
                    <Col sm={12}
                      className='mt-4'
                      key={index}>
                      {canAdd
                        ? (
                          <div className='d-flex justify-content-between align-items-center mt-5'>
                            <p className='fs-5 text-primary'>
                              {/* {section.sectionName} */}
                            </p>
                            <Button
                              className='d-flex align-items-center gap-2'
                              variant='outline-primary'
                              onClick={() => {
                                setSection(section.inRefCode);
                                setShowUserModal(true);
                              }}
                            >
                              <FaPlus />เพิ่มรายชื่อ
                            </Button>
                          </div>
                        ) : null}
                      <Table
                        hidePagination
                        className='mt-3 step-bar'
                        notResponse
                      >
                        <thead>
                          <tr>
                            <th style={{ width: '5%' }}>ลำดับ</th>
                            <th className='text-start'
                              style={{ width: '30%' }}>ชื่อ-นามสกุล
                            </th>
                            <th className='text-start'
                              style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                            </th>
                            <th style={{ width: '10%' }}>สถานะ</th>
                            <th style={{ width: '10%' }}>วันที่เห็นชอบ</th>
                            <th style={{ minHeight: 100, width: '5%' }} />
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (planProcurement.approvers && planProcurement.approvers.length > 0) && (
                              planProcurement.approvers
                                .filter(a => a.sectionType === section.inRefCode)
                                .sort((a, b) => a.sequence - b.sequence)
                                .map((data, i) => (
                                  <>
                                    <tr key={i}>
                                      <td className='text-center'>{i + 1}</td>
                                      <td className='text-left'>
                                        {data.fullName}
                                        <p className='m-0 department text-wrap'>
                                          {data.positionName}
                                        </p>
                                      </td>
                                      <td className='text-left'>
                                        {data.delegateFullName &&
                                          <>
                                            {data.delegateFullName}
                                            <p className='m-0 department text-wrap'>
                                              {data.delegatePositionName}
                                            </p>
                                          </>
                                        }
                                      </td>
                                      <td className='text-center'>
                                        {isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ?
                                          <Approver value={data.status}
                                            outline /> : <ApproverStatus value={data.status}
                                              outline />}
                                      </td>
                                      <td className='text-center'>
                                        {data.acceptDate ? fullDatetime(data.acceptDate) : ''}
                                      </td>
                                      <td className='text-end'>
                                        {canRemove(data.id) &&
                                          <Button
                                            variant='danger'
                                            onClick={() => removeUser(data.userId, section.inRefCode)}
                                          >
                                            <FaTrashAlt />
                                          </Button>}
                                      </td>
                                    </tr>
                                    {data.isAvailableRemark || data.acceptRemark || data.rejectRemark ?
                                      <tr className='border-bottom bg-opacity-10 bg-light'>
                                        <td />
                                        <td colSpan={5}
                                          className='p-0'>
                                          <ul className='step-bar-list'>
                                            {data.isAvailableRemark &&
                                              <li>
                                                <div className='description'>
                                                  <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                                  <p className='mb-0'
                                                    style={{ color: 'gray' }}>
                                                    {data.isAvailableRemark}
                                                  </p>
                                                </div>
                                              </li>
                                            }
                                            {data.acceptRemark &&
                                              <li>
                                                <div className='description'>
                                                  <p className='mb-0 text-primary'>หมายเหตุ{isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ? 'อนุมัติ' : 'เห็นชอบ'}
                                                  </p>
                                                  <p className='mb-0 text-wrap'
                                                    style={{ color: 'gray' }}>
                                                    <span className='text-muted me-2'>[{data.acceptDate ? fullDatetime(data.acceptDate) : ''}]</span>
                                                    {data.acceptRemark}
                                                  </p>
                                                </div>
                                              </li>
                                            }
                                            {data.rejectRemark &&
                                              <li>
                                                <div className='description'>
                                                  <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                                  <p className='mb-0 text-wrap'
                                                    style={{ color: 'gray' }}>
                                                    <span className='text-muted me-2'>[{data.rejectDate ? fullDatetime(data.rejectDate) : ''}]</span>
                                                    {data.rejectRemark}
                                                  </p>
                                                </div>
                                              </li>
                                            }
                                          </ul>
                                        </td>
                                      </tr>
                                      : <tr className='border-bottom' />}
                                  </>
                                ))
                            )
                          }
                        </tbody>
                      </Table>
                    </Col>
                  ))
              )
            }
          </div>
        </Collapse>
      </Card>

      {/* Document */}
      < Card className='mt-3'>

        {/* Title */}
        < div
          className='d-flex align-items-center gap-3'
        >
          <Title
            text='หนังสือขออนุมัติ'
            className='fs-5 text-primary'
          />
          {
            documentCollapsed
              ? (
                <FaChevronCircleDown
                  className='text-primary fs-5 cursor-pointer'
                  onClick={() => setDocumentCollapsed(false)}
                />
              ) : (
                <FaChevronCircleUp
                  className='text-primary fs-5 cursor-pointer'
                  onClick={() => setDocumentCollapsed(true)}
                />
              )
          }
        </div>

        <Collapse in={!documentCollapsed}
          className='mt-3'>
          <div>
            <Collabora
              docId={docId}
              docName='doc-01'
              ref={collaboraRef}
              readonly={!canSave} />
          </div>
        </Collapse>
      </Card>

      {/* Upload File */}
      {
        currentStep === 4
          ? (
            <Card className='mt-3'>
              <Title text='เอกสารแนบ'
                className='fs-5 text-primary' />
              <Row className='justify-content-center'>
                <Col sm={12}
                  lg={6}>
                  <UploadFile
                    onChange={uploadFileOnChangeAsync}
                    value={files()}
                    onRemove={removeFileAsync}
                    onDownload={downloadFileAsync}
                    canRemoveFile={canRemoveFile}
                    disabled={readonly}
                  />
                </Col>
              </Row>
            </Card>
          ) : null
      }

      {/* Button */}
      {
        currentStep === 4
          ? (
            <div className='d-flex justify-content-between mt-3'>
              <ButtonCustom
                onClick={props.onClickBack}
                text="กลับหน้าแรก"
              />
              <div className='d-flex justify-content-between gap-2'>
                {canRework ? (
                  <ButtonCustom
                    text="ส่งกลับแก้ไข"
                    icon='undo'
                    variant='danger'
                    onClick={() => setShowRejectModal(true)}
                  />
                ) : null}
                {canSave ? (
                  <ButtonCustom
                    onClick={() => handlerOnSubmitAsync(PlanStatusCons.DraftRecordDocument)}
                    text="บันทึก"
                  />
                ) : null}
                {canSendApprove
                  ? (
                    <ButtonCustom
                      onClick={() => setShowPendingModal(true)}
                      icon='checked'
                      text="ส่งเห็นชอบ/อนุมัติ"
                    />
                  )
                  : null}
              </div>
              <div style={{ width: 100 }} />
            </div>
          ) : null
      }

      {/* Modal */}
      <SearchBuNameModal
        total={userTotal}
        show={showUserModal}
        onHide={() => setShowUserModal(!showUserModal)}
        onSelect={(id, name, department, position) => userOnSelect(id, name, department!, position!)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <RejectModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(false)}
      />
      <Modal
        show={showPendingModal}
        size='lg'
        onHide={() => setShowPendingModal(!showPendingModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งเห็นชอบ/อนุมัติ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งเห็นชอบ/อนุมัติ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingModal(!showPendingModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerOnSubmitAsync(PlanStatusCons.WaitingAcceptor)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showDefaultApproverModal}
        size='lg'
        onHide={() => setShowDefaultApproverModal(!showDefaultApproverModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการกำหนดผู้มีอำนาจเห็นชอบเริ่มต้นใหม่หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDefaultApproverModal(!showDefaultApproverModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerSetDefaultApproverAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

function SearchUserModal(props: {
  show: boolean;
  onHide: () => void;
  onSelect: (id: string, fullName: string, departmentName: string, positionName: string) => void;
}) {
  const loader = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<UserCriteria>({} as UserCriteria);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (props.show) {
      setSize(10);
      setPage(1);
      clearCriteria();
      getUserAsync(10, 1);
    }
  }, [props.show]);

  const handlerOnChange = (key: string, value: string) => {
    setCriteria({
      ...criteria,
      [key]: value,
    });
  };

  const onSelect = (id: string) => {
    const user = users.find((a) => a.id === id);

    if (user) {
      props.onSelect(
        user.id,
        user.name,
        user.department,
        user.position,
      );

      props.onHide();
    }
  };

  const getUserAsync = async (size: number, page: number) => {
    const res = await planProcurementService
      .getUsersAsync(size, page, criteria.name, criteria.departmentId, criteria.positionId);

    if (res.status === HttpStatusCode.OK) {
      setUsers(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }
  };

  const clearCriteria = () => {
    setCriteria({} as UserCriteria);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='xl'
    >
      <Modal.Header>
        <Title
          text='รายชื่อฝ่ายเห็นชอบ'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col sm={6}
          lg={4}>
          <Input
            label='ชื่อ-นามสกุล'
            value={criteria.name}
            onChange={(val) => handlerOnChange('name', val)}
          />
        </Col>
        <Col sm={6}
          lg={4}>
          <Selector
            label='ฝ่าย/สำนัก'
            value={criteria.departmentId}
            onChange={(val) => handlerOnChange('departmentId', val)}
            items={loader.departments}
          />
        </Col>
        <Col sm={6}
          lg={4}>
          <Selector
            label='ตำแหน่ง'
            value={criteria.positionId}
            onChange={(val) => handlerOnChange('positionId', val)}
            items={loader.positions}
          />
        </Col>
      </Row>
      <div className='d-flex gap-2'>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => getUserAsync(size, page)}
        >
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant='outline-primary'
          className='d-flex align-items-center gap-2'
          onClick={clearCriteria}
        >
          <FaEraser />ล้าง
        </Button>
      </div>
      <Table
        className='mt-3'
        total={totalRecords}
        onChange={(size, page) => {
          setSize(size);
          setPage(page);
          getUserAsync(size, page);
        }}
      >
        <thead>
          <tr>
            <th style={{ minWidth: 20 }}>ลำดับ</th>
            <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
            <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
            <th style={{ minWidth: 25 }} />
          </tr>
        </thead>
        <tbody>
          {
            users.map((data, i) => (
              <tr key={data.id}>
                <td className='text-center'>{calculateRowNumber(i, page, size)}</td>
                <td>{data.name}</td>
                <td>{data.department}</td>
                <td>{data.position}</td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Button variant='primary'
                      onClick={() => onSelect(data.id)}>
                      เลือก
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Modal>
  );
}

function RejectModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement, reGetDetailAsync } = useContext(Context);
  const [remark, setRemark] = useState<string>('');
  const { userId } = useAppContext();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (!remark) {
      return;
    }

    const approverDirectorBeInformed = planProcurement.jorporDirectorBeInformed?.find((d) => isNull(d.delegateUserId, d.userId) === userId);
    const approverDirectorAssign = planProcurement.jorporDirectorAssign?.find((d) => isNull(d.delegateUserId, d.userId) === userId);
    let approverId = '';

    if (approverDirectorBeInformed) {
      approverId = approverDirectorBeInformed.id
    } else if (approverDirectorAssign) {
      approverId = approverDirectorAssign.id
    }

    if (approverId !== '') {
      const res = await planProcurementService
        .rejectAsync(
          planProcurement.id,
          approverId,
          PlanStatusCons.RejectPlan,
          remark,
        );

      if (res.status === HttpStatusCode.ACCEPTED) {
        reGetDetailAsync(planProcurement.id);
        toast.success('ส่งกลับแก้ไขสำเร็จ');

        props.onHide();
      }
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
    >
      <Modal.Header>
        <Title
          text='ส่งกลับแก้ไข'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label='เหตุผล'
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name='remark'
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-end gap-2'>
        <Button
          variant='outline-primary'
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant='primary'
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}
