import { JorPor06Count } from 'models/PR/JorPor06Models';
import { LoaderFunctionArgs } from 'react-router';
import {
  jorPor06,
  shareValue as shareService,
  shareValue,
} from 'services';
import { SharedvalueGroup, SharedvalueId, SubGroup } from 'utils/constants/ContractEnum';

const JorPor06Loader = async () => {
  const criteriaData: JorPor06Count = {
    isPermission: true,
  };

  const [department, supplymethod, countStatusData, unitOfMeasure] = await Promise.all([
    shareService.GetDDL("Department"),
    shareService.GetDDL("SupplyMethod"),
    jorPor06.getCountJorPor06ListAsync(criteriaData),
    shareService.GetDDL('UnitOfMeasure'),
  ]);

  const supplyMethodAll = [{ label: 'ทั้งหมด', value: 'All' }, ...supplymethod];

  return {
    departmentDDL: department,
    supplyMethodDDL: supplyMethodAll,
    countStatusData: countStatusData.data.data,
    unitOfMeasureDDL: unitOfMeasure,
  }
}

const JorPor06DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (id) {
    const [department, supplymethod, supplymethodType, positionList, jorpor06Data, vatTypeList, unitOfMeasure, periodUnitList, positionOnBoardProcurementList, budgetConstantJorPro06] = await Promise.all([
      shareService.GetDDL("Department"),
      shareService.GetDDL("SupplyMethod"),
      shareService.GetDDL("SupplyMethodType"),
      shareService.GetDDL("Position"),
      jorPor06.getJorpor06DetailAsync(id),
      shareService.GetDDL("VatType"),
      shareService.GetDDL('UnitOfMeasure'),
      shareService.GetDDL(SharedvalueGroup.PeriodType),
      shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardProcurement),
      shareValue.getSingleSharedValueAsync(SharedvalueId.budgetConstantJorPro06),
    ]);
    return {
      departments: department,
      supplyMethods: supplymethod,
      supplyMethodTypes: supplymethodType,
      postionItems: positionList,
      jorPor06Detail: jorpor06Data.data.data,
      isCreate: jorpor06Data.data.data.isCreator,
      isAcceptor: jorpor06Data.data.data.isAcceptor,
      isQueue: jorpor06Data.data.data.isQueue,
      vatTypeItems: vatTypeList,
      acceptorId: jorpor06Data.data.data.acceptorId,
      unitOfMeasureDDL: unitOfMeasure,
      periodUnitDDL: periodUnitList,
      positionOnBoardProcurementDDL: positionOnBoardProcurementList.data,
      budgetConstantJorPro06: budgetConstantJorPro06.data,
    }
  }
}

export {
  JorPor06Loader,
  JorPor06DetailLoader
}
