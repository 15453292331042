import { Check } from 'components';
import { Button } from 'react-bootstrap';
import { MdCircle } from 'react-icons/md';

interface Props {
  onClick?: () => void;
  textNote1: string;
  textNote2: string;
  isDisabled?: boolean;
  data?: any[];
}

export function TabsFilterCM04(props: Props) {
  return (
    <div className='bg-light rounded  bg-opacity-25  tabs-filter'>
      <p className='ms-2 mb-0'> จำนวนสัญญา(ฉบับ):</p>

      {
        props.data?.map((data, i) =>
          <Check className='mb-0 check-item'
            labelNotbtn={<div className='align-items-center d-flex'> {data.value ? data.value : '-'}<span className='text-primary mx-2'>{data.count ? data.count : '0'}</span>
            </div>}
          />)
      }
      <p className='mb-0'>หมายเหตุ : <MdCircle className='mx-2 text-success' />{props.textNote1}
        <MdCircle className='mx-2 text-danger' />{props.textNote2}</p>
      {
        (props.isDisabled) && (
          <Button className='m-0 py-0'
            onClick={props.onClick}>ตารางสรุปการทำสัญญา</Button>
        )
      }
    </div>
  );
}
