import { DepartmentResponse } from 'models';
import { ItemModel } from 'models/systemUtility';
import { department, shareValue } from 'services';
import { SharedvalueGroup } from 'utils/constants/ContractEnum';

const loadersDatas = async () => {
  const departmentItems = await GetDDL('Department');
  const supplyMethodItems = await GetDDL('SupplyMethod');
  const supplyMethodTypeItems = await GetDDL('SupplyMethodType');
  const contractType = await GetDDL(SharedvalueGroup.ContractType);
  const periodCondition = await GetDDL(SharedvalueGroup.PeriodCondition);
  const warrantyCondition = await GetDDL(SharedvalueGroup.WarrantyCondition);
  const performanceBondType = await GetDDL(SharedvalueGroup.PerformanceBondType);
  const performanceBondReturn = await GetDDL(SharedvalueGroup.PerformanceBondReturn);
  const periodType = await GetDDL(SharedvalueGroup.PeriodType);
  const returnCollateralConsiderResultType = await GetDDL(SharedvalueGroup.ReturnCollateralConsiderResultType);
  const bankType = await GetDDL(SharedvalueGroup.Bank);
  const medianPriceConsiderInformation = await GetDDL(SharedvalueGroup.MedianPriceConsiderInformation);
  const criteriaConsideration = await GetDDL(SharedvalueGroup.CriteriaConsideration);
  const positionOnBoard = await GetDDL(SharedvalueGroup.PositionOnBoard);
  const medianPriceTemplate = await GetDDL(SharedvalueGroup.MedianPriceTemplate);

  const entrepreneurTypeItems = await GetDDL('EntrepreneurType');
  return {
    departmentDDL: departmentItems,
    supplyMethodDDL: supplyMethodItems,
    supplyMethodTypeDDL: supplyMethodTypeItems,
    contractTypeDDL: contractType,
    periodConditionDDL: periodCondition,
    warrantyConditionDDL: warrantyCondition,
    performanceBondTypeDDL: performanceBondType,
    performanceBondReturnDDL: performanceBondReturn,
    periodTypeDDL: periodType,
    entrepreneurTypeDDL: entrepreneurTypeItems,
    returnCollateralConsiderResultType: returnCollateralConsiderResultType,
    bankDDL: bankType,
    medianPriceConsiderInformationType: medianPriceConsiderInformation,
    criteriaConsiderationDDL: criteriaConsideration,
    positionOnBoardDDL: positionOnBoard,
    medianPriceTemplateDDL: medianPriceTemplate
  };
};

const GetDDL = async (Type: string) => {
  const itemForMap: ItemModel[] = [];

  switch (Type) {
    case 'Department': {
      const response = await department.getDepartmentsAsync();
      const departmentData: DepartmentResponse[] = response.data;
      departmentData.forEach((data) =>
        itemForMap.push({
          label: data.name,
          value: data.id ?? ''
        })
      );
      break;
    }
    case 'SupplyMethod': {
      const response = await shareValue.getSupplyMethodListAsync();
      const supplyMethodData: ItemModel[] = response.data;
      supplyMethodData.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case 'SupplyMethodType': {
      const response = await shareValue.getSupplyMethodTypeListAsync();
      const supplyMethodTypeData: ItemModel[] = response.data;
      supplyMethodTypeData.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.ContractType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractType);
      const contractTypeData: ItemModel[] = response.data;
      contractTypeData.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.PeriodCondition: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodCondition);
      const periodCondition: ItemModel[] = response.data;
      periodCondition.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.WarrantyCondition: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.WarrantyCondition);
      const warrantyCondition: ItemModel[] = response.data;
      warrantyCondition.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.PerformanceBondType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PerformanceBondType);
      const performanceBondType: ItemModel[] = response.data;
      performanceBondType.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.PerformanceBondReturn: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PerformanceBondReturn);
      const performanceBondReturn: ItemModel[] = response.data;
      performanceBondReturn.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.PeriodType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodType);
      const performanceBondReturn: ItemModel[] = response.data;
      performanceBondReturn.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case 'EntrepreneurType': {
      const response = await shareValue.GetTraderTypeAsync();
      const entrepreneurTypeData: ItemModel[] = response.data;
      entrepreneurTypeData.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.ReturnCollateralConsiderResultType: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.ReturnCollateralConsiderResultType);
      const returnCollateralConsiderResultType: ItemModel[] = response.data;
      returnCollateralConsiderResultType.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.Bank: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.Bank);
      const bankType: ItemModel[] = response.data;
      bankType.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.MedianPriceConsiderInformation: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.MedianPriceConsiderInformation);
      const MedianPriceConsiderInformation: ItemModel[] = response.data;
      MedianPriceConsiderInformation.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.CriteriaConsideration: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.CriteriaConsideration);
      const CriteriaConsideration: ItemModel[] = response.data;
      CriteriaConsideration.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.PositionOnBoard: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.PositionOnBoard);
      const PositionOnBoard: ItemModel[] = response.data;
      PositionOnBoard.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
    case SharedvalueGroup.MedianPriceTemplate: {
      const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.MedianPriceTemplate);
      const MedianPriceTemplate: ItemModel[] = response.data;
      MedianPriceTemplate.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
  }

  return itemForMap;
};

export { loadersDatas };
