import {
  Card,
  ContractAgreementStatus,
  Currency,
  DatePicker,
  Input,
  Modal,
  Readonly,
  Selector,
  Table,
  ButtonCustom,
  InputTextArea,
} from 'components';
import Title from 'components/Controls/Title';
import { TraderModalTypeConstant } from 'constant/ProcumentInvitationStatus';
import {
  IFile,
  ItemModel,
} from 'models';
import {
  AttachmentModel,
  CollateralModel,
  ContractAgreementAcceptorModel,
  ContractAgreementModel,
  CreateEditingContractAgreementModel,
  EntrepreneurModel,
  UpdateAcceptorStep4Model,
  UpdateContractAgreementDraftModel,
  UpdateEntrepreneurConsiderationModel,
} from 'models/CM/CM02Models';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { useLoaderData, useNavigate } from 'react-router';
import { account, shareValue } from 'services';
import CM02Service from 'services/CM/CM02Service';
import { HttpStatusCode, submitForm } from 'utils';
import { SharedvalueGroup } from 'utils/constants/ContractEnum';
import toast from 'utils/toast';
import { Context } from '.';
import { dataValidation } from '../../../../utils/helper';
import { CoiModal } from '../Modal/CoiModal';
import UploadFile from '../UploadFile';
import { ContractStartDatePeriodConditionIdEnum } from 'utils/constants/ShareValueEnum';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import { sectionApproverModel } from 'models/delegateModel';
import { BsCheckCircle } from 'react-icons/bs';
import { ContractAgreementTypeAcceptor } from 'constant/ContractStatus';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  onTemplateChange: (value: string, id: string) => void;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

const INFO = [
  { label: 'มีหลักประกัน', value: 'true' },
  { label: 'ไม่มีหลักประกัน', value: 'false' },
];

type Loader = {
  supplyMethodDDL: ItemModel[],
  supplyMethodTypeDDL: ItemModel[],
  entrepreneurTypeDDL: ItemModel[],
  contractTypeDDL: ItemModel[],
  periodConditionDDL: ItemModel[],
  warrantyConditionDDL: ItemModel[],
  performanceBondTypeDDL: ItemModel[],
  bankDDL: ItemModel[],
  contractStartDatePeriodConditionDDL: ItemModel[],
};

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const typeDeliveryDueDate = {
  AfterReceiveLetter: '4ba16353-bcb8-021b-993a-5da537bf1e39', // นับถัดจากวันที่ได้รับหนังสือแจ้งให้เริ่มงาน
  AccordingSpecifiedPeriod: 'bcd9fd07-8b4c-eea6-e417-af9d18254055'// ตามระยะเวลาที่ระบุ
};

const typeWarrantyDueDate = {
  AfterEndDate: 'b2e8be20-f667-74a6-aacd-5777b9124cec', // ถัดจากวันที่สิ้นสุด
  AccordingSpecifiedPeriod: '8f86e195-249c-4d3e-85bc-ba7b9e2353f1', // ตามระยะเวลาที่ระบุ
};

const PerformanceBondType = {
  cash: '0c1d6674-9282-8c27-7d04-4c22280b6baf',
  guaranteeElectronics: '474c0cbb-0496-6331-07c8-91f84a68e510',
  guaranteeBank: '02b37b8a-bbec-705f-8ec4-05074b3af398',
  guaranteeCompany: 'c55a61cf-e00d-ae94-930a-c3013fd73ca0',
  governmentBonds: '23b43be8-d1a4-a64f-fe2c-0025acda6ad9',
  cheque: '03101d8f-d472-d51c-0aed-b173c45073e6',
}

interface IValidator {
  fnContractData: (contractData: ContractAgreementModel) => boolean;
  message: string;
}

export default function Step1({ onClickNext, onClickBack, onTemplateChange }: Props) {
  const {
    warrantyConditionDDL, entrepreneurTypeDDL, contractStartDatePeriodConditionDDL
  } = useLoaderData() as Loader;
  const { dataContext, sectionContractContext, setDataContext, setSectionContractContext } = useContext(Context);
  const [contractData, setContractData] = useState<ContractAgreementModel>({} as ContractAgreementModel);
  const [entrepreneur, setEntrepreneur] = useState<EntrepreneurModel>({} as EntrepreneurModel);
  const [modalTitle, setModalTitle] = useState('COI');
  const [modalType, setModalType] = useState<TraderModalTypeConstant>(TraderModalTypeConstant.COI);
  const [modalResult, setModalResult] = useState<boolean | undefined>(undefined);
  const [modalRemark, setModalRemark] = useState<string | undefined>(undefined);
  const [showCoiModal, setShowCoiModal] = useState(false);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [contractManagementTypeDDL, setContractManagementTypeDDL] = useState<ItemModel[]>([]);
  const [contractFormatDDL, setContractFormatDDL] = useState<ItemModel[]>([]);
  const [contractFormatLevelDDL, setContractFormatLevel2DDL] = useState<ItemModel[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [confidentialFiles, setConfidentialFiles] = useState<IFile[]>([]);
  const [periodTypeDDL, setPeriodTypeDDL] = useState<ItemModel[]>([]);
  const [isDisabledCollateral, setIsDisabledCollateral] = useState(false);
  const [contractTemplateCode, setContractTemplateCode] = useState('');
  const [contractTemplateId, setContractTemplateId] = useState('');
  const [isReferenceNumber, setIsReferenceNumber] = useState(false);
  const [isBank, setIsBank] = useState(false);
  const [isDisabledWarrantyDueDate, setIsDisabledWarrantyDueDate] = useState(true);
  const [acceptors, setAcceptors] = useState<ContractAgreementAcceptorModel[]>([]);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [remarkEditing, setRemarkEditing] = useState('');
  const [contractTemplatenName, setcontractTemplatenName] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    getPeriodType();
    if (dataContext) {

      if (dataContext.contractAgreementAcceptors?.length === 0) {
        onGetDefaultApprover();
      }

      if (sectionContractContext.length === 0
        && dataContext.contractTemplate) {
        getSectionContractFormat(dataContext.contractTemplate);
      }

      dataValidation(dataContext.supplyMethodTypeId, dataContext.supplyMethodTypeId, getContractManagementType);

      setIsDisabled(!(dataContext.status === ContractAgreementStatus.Draft
        || dataContext.status === ContractAgreementStatus.Rejected));

      dataValidation((dataContext.secretAttachments && dataContext.secretAttachments.length > 0),
        dataContext.secretAttachments,
        (context) => {
          const newFiles: IFile[] = context?.map((data, index) => ({
            id: data.id!,
            name: data.fileName,
            file: null,
          } as IFile));

          setConfidentialFiles(newFiles);
        });

      dataValidation((dataContext.attachments && dataContext.attachments.length > 0),
        dataContext.attachments,
        (context) => {
          const newFiles: IFile[] = context?.map((data, index) => ({
            id: data.id!,
            name: data.fileName,
            file: null,
          } as IFile));

          setFiles(newFiles);
        });

      if (dataContext.collateral !== undefined && dataContext.collateral !== null) {
        setIsDisabledCollateral(!dataContext.collateral?.active);
      } else {
        setIsDisabledCollateral(true);
      }

      if (dataContext.contractTemplate) {
        getContractTemplate(dataContext.contractTemplate);

        getSubContractFormat(dataContext.contractTemplate);
      }

      if (dataContext.collateral) {
        setIsReferenceNumber(false);
        setIsBank(false);

        if (dataContext.collateral.type === PerformanceBondType.cash || dataContext.collateral.type === PerformanceBondType.cheque || dataContext.collateral.type === PerformanceBondType.governmentBonds) {
          setIsReferenceNumber(true);
        }

        if (dataContext.collateral.type === PerformanceBondType.guaranteeBank || dataContext.collateral.type === PerformanceBondType.guaranteeCompany || dataContext.collateral.type === PerformanceBondType.guaranteeElectronics) {
          setIsBank(true);
        }
      }

      setContractData(dataContext);
    }
  }, [dataContext]);

  const getSectionContractFormat = async (contractTemplate: string) => {
    const {
      data,
      status,
    } = await shareValue.getListSharedValueAsync(SharedvalueGroup.SectionContractFormat, contractTemplate);

    if (status === HttpStatusCode.OK) {
      const items: ItemModel[] = data;

      setSectionContractContext(items);
    }
  };

  const getContractManagementType = async () => {
    const { data, status } = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractManagementType);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setContractManagementTypeDDL(data);
    getContractFormat(dataContext.contractType);
  };

  const getContractTemplate = async (contractFormatId: string) => {
    const { data, status } = await shareValue.getSingleSharedValueAsync(contractFormatId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setContractTemplateCode(data.sharedValue.code);
    setContractTemplateId(data.sharedValue.id);
  };

  const getContractFormat = async (contractType: string) => {
    const { data, status } = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractFormat, contractType);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setContractFormatDDL(data);

    const contractFormat = data.filter((x: { value: string; }) => x.value === dataContext.contractTemplate)[0];

    if (contractFormat) {
      setcontractTemplatenName(contractFormat.value4 ?? '');
    } else {
      setcontractTemplatenName('');
    }

  };

  const getSubContractFormat = async (contractFormatId: string) => {
    const { data, status } = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractFormatLevel2, contractFormatId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setContractFormatLevel2DDL(data);
  };

  const getPeriodType = async () => {
    const { data, status } = await shareValue.getListSharedValueAsync(SharedvalueGroup.PeriodType);
    if (status !== HttpStatusCode.OK) {
      return;
    }
    setPeriodTypeDDL(data);
  };

  const getContractByIdAsync = async (id: string) => {
    const { data, status } = await CM02Service.getContractByIdAsync(id);
    if (status === HttpStatusCode.OK) {
      setDataContext(data);
      setContractData(data);
    }
  };

  const getDocumentContractByIdAsync = async (id: string) => {
    const { data, status } = await CM02Service.getContractByIdAsync(id);
    if (status === HttpStatusCode.OK) {
      const secretAttachments = data.secretAttachments as AttachmentModel[];
      const attachments = data.attachments as AttachmentModel[];

      setDataContext({ ...dataContext, secretAttachments, attachments });

      if (secretAttachments && secretAttachments.length > 0) {
        const newFiles: IFile[] = secretAttachments?.map((data, index) => ({
          id: data.id!,
          name: data.fileName,
          file: null,
        } as IFile));

        setConfidentialFiles(newFiles);
      } else {
        setConfidentialFiles([]);
      }

      if (attachments && attachments.length > 0) {
        const newFiles: IFile[] = attachments?.map((data, index) => ({
          id: data.id!,
          name: data.fileName,
          file: null,
        } as IFile));

        setFiles(newFiles);
      } else {
        setFiles([]);
      }
    }
  };

  const checkBooleanNullOrUndefined = (val?: boolean) => (val === null || val === undefined);

  const onChangeContractType = (contractType: string) => {
    setContractData({ ...contractData, contractType, contractTemplate: '', subContractTemplate: '', contractTemplateText: '' });

    setcontractTemplatenName('');
    getContractFormat(contractType);
  };

  const onChangeContractFormatType = (val: string) => {
    setContractData({ ...contractData, contractTemplate: val, subContractTemplate: '', contractTemplateText: '' });

    onSetContractTemplatenName(val);
    getSubContractFormat(val);
    getSectionContractFormat(val);
  };

  const onSetContractTemplatenName = async (val: string) => {

    const contractFormat = contractFormatDDL.filter((x: { value: string; }) => x.value === val)[0];

    if (contractFormat) {
      setcontractTemplatenName(contractFormat.value4 ?? '');
    }

  };

  const onOpenCoiModal = (traderModel: EntrepreneurModel, modalType: string, result?: boolean, remark?: string) => {
    setEntrepreneur(traderModel);
    setModalResult(result);
    setModalRemark(remark);
    if (modalType === TraderModalTypeConstant.COI) {
      setModalTitle('COI');
      setModalType(TraderModalTypeConstant.COI);
    } else if (modalType === TraderModalTypeConstant.WATCHLIST) {
      setModalTitle('Watchlist');
      setModalType(TraderModalTypeConstant.WATCHLIST);
    } else {
      setModalTitle('ตรวจสอบผู้ทิ้งงาน ครั้งที่ 1');
      setModalType(TraderModalTypeConstant.EGP);
    }
    setShowCoiModal(true);
  };

  const onSubmitTraderResultModal = async (traderId: string, modalType: string, remark: string, isAccept?: boolean, files?: IFile[]) => {
    const entrepreneurData = contractData.entrepreneur;
    const docUpload: AttachmentModel[] = [];
    if (files && !entrepreneurData.id) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push({ id: i.id, fileName: i.name, createdDate: new Date() } as AttachmentModel);
        }
      });
    }
    switch (modalType) {
      case TraderModalTypeConstant.COI:
        entrepreneurData.coiResult = isAccept;
        entrepreneurData.coiRemark = remark;
        entrepreneurData.coiAttachments = docUpload;
        setContractData({ ...contractData, entrepreneur: entrepreneurData });
        onSaveEnterprenuar(modalType, remark, isAccept);
        return;
      case TraderModalTypeConstant.WATCHLIST:
        entrepreneurData.watchListResult = isAccept;
        entrepreneurData.watchListRemark = remark;
        entrepreneurData.watchListAttachments = docUpload;
        setContractData({ ...contractData, entrepreneur: entrepreneurData });
        onSaveEnterprenuar(modalType, remark, isAccept);
        return;
      case TraderModalTypeConstant.EGP:
        entrepreneurData.egpResult = isAccept;
        entrepreneurData.egpRemark = remark;
        entrepreneurData.egpAttachments = docUpload;
        setContractData({ ...contractData, entrepreneur: entrepreneurData });
        onSaveEnterprenuar(modalType, remark, isAccept);
        return;
    }
  };

  const onSaveEnterprenuar = async (modalType: string, remark: string, isAccept?: boolean) => {
    const updateData: UpdateEntrepreneurConsiderationModel = {
      result: isAccept!,
      remark,
      type: modalType,
    };
    const { data, status } = await CM02Service.updateContractEntrepreneurStep1Async(contractData.id, updateData);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

      return;
    }

    toast.success('บันทึกข้อมูลคู่ค้าสำเร็จ');
  };

  const onSave = async () => {
    const validators: IValidator[] = [
      {
        fnContractData: (contractData: ContractAgreementModel) =>
          contractData.entrepreneur.email === undefined
          || contractData.entrepreneur.email === null
          || contractData.entrepreneur.email === '',
        message: 'กรุณากรอกข้อมูล Email คู่ค้า',
      },
      {
        fnContractData: (contractData: ContractAgreementModel) =>
          contractData.entrepreneur.email === undefined
          || contractData.entrepreneur.email === null
          || contractData.entrepreneur.email === '',
        message: 'กรุณากรอกข้อมูลวงเงินตามสัญญา',
      },
      {
        fnContractData: (contractData: ContractAgreementModel) =>
          contractData.contractType === undefined
          || contractData.contractType === null
          || contractData.contractType === '',
        message: 'กรุณากรอกข้อมูลประเภทสัญญา',
      },
      {
        fnContractData: (contractData: ContractAgreementModel) =>
          contractData.contractTemplate === undefined
          || contractData.contractTemplate === null
          || contractData.contractTemplate === '',
        message: 'กรุณากรอกข้อมูลรูปแบบสัญญา',
      },
      {
        fnContractData: (contractData: ContractAgreementModel) =>
          (contractTemplatenName !== ''
            && contractTemplatenName !== undefined
            && contractTemplatenName !== null)
          && (contractData.contractTemplateText === undefined
            || contractData.contractTemplateText === null
            || contractData.contractTemplateText === ''),
        message: `กรุณากรอกข้อมูลชื่องาน ${contractTemplatenName}`,
      },
      {
        fnContractData: (contractData: ContractAgreementModel) =>
          contractFormatLevelDDL.length > 0 && !contractData.subContractTemplate,
        message: 'กรุณาเลือกรูปแบบสัญญาให้ครบถ้วน',
      },
      {
        fnContractData: (contractData: ContractAgreementModel) =>
          !contractData.deliveryPeriod?.startDateCondition,
        message: 'กรุณาเลือกระยะเวลาเริ่มต้นสัญญา',
      },
      {
        fnContractData: (contractData: ContractAgreementModel) =>
          contractData.deliveryPeriod?.startDateCondition === ContractStartDatePeriodConditionIdEnum.ตามระยะเวลาที่ระบุ
          && (!contractData.deliveryPeriod?.startDate || !contractData.deliveryPeriod.dueDate),
        message: 'กรุณาระบุวันที่เริ่มปฏิบัติงาน และวันที่สิ้นสุดของสัญญา',
      },
      {
        fnContractData: (contractData: ContractAgreementModel) =>
          contractData.deliveryPeriod?.startDateCondition === ContractStartDatePeriodConditionIdEnum.ตามระยะเวลาที่ระบุ
          && (new Date(contractData.deliveryPeriod?.startDate!) > new Date(contractData.deliveryPeriod.dueDate!)),
        message: 'กรุณาระบุวันที่เริ่มปฏิบัติงาน น้อยกว่าวันที่สิ้นสุดของสัญญา',
      },
    ];

    for (const key in validators) {
      const validator = validators[key];

      if (validator.fnContractData(contractData)) {
        toast.warn(validator.message);

        return;
      }
    }

    const updateData: UpdateContractAgreementDraftModel = {
      entrepreneursEmail: contractData.entrepreneur.email,
      contractName: contractData.contractName,
      contractType: contractData.contractType,
      contractTemplate: contractData.contractTemplate,
      subContractTemplate: contractData.subContractTemplate,
      fineRemark: contractData.fineRemark,
      contractTemplateText: contractData.contractTemplateText,
      startDateCondition: contractData.deliveryPeriod?.startDateCondition,
      deliveryPeriodStartDate: contractData.deliveryPeriod?.startDate,
      deliveryPeriodDueDate: contractData.deliveryPeriod?.dueDate,
      workDayOnly: contractData.deliveryPeriod?.workDayOnly
    };

    setShowSaveConfirmModal(false);
    const { data, status } = await CM02Service.updateContractStep1Async(contractData.id, updateData);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');
      return;
    }

    if (dataContext.contractAgreementAcceptors?.length === 0 && acceptors) {
      const updateAcceptor: UpdateAcceptorStep4Model = {
        acceptors: acceptors && acceptors.length > 0 ? [acceptors[acceptors.length - 1]] : acceptors,
        sendToApprove: false,
      }

      const res = await CM02Service.updateAcceptorStep4Async(contractData.id, updateAcceptor);
    }

    toast.success('บันทึกข้อมูลสัญญาสำเร็จ');
    if (contractData.id) {
      getContractByIdAsync(contractData.id);
    }

    window.location.reload();
  };

  const handleFileConfidentialChange = async (files: File[]) => {
    if (contractData && contractData.id) {
      const res = await CM02Service.uploadAttachmentConfidentialAsync(
        contractData.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');

        const dataFile: AttachmentModel[] = res.data as AttachmentModel[];
        if (dataFile && dataFile.length > 0) {
          const newFiles: IFile[] = dataFile.map((data, index) => ({
            id: data.id!,
            name: data.fileName,
            file: null,
          } as IFile));

          setConfidentialFiles(newFiles);
        }
      }
    }
  };

  const handleFileChange = async (files: File[]) => {
    if (contractData && contractData.id) {
      const res = await CM02Service.uploadAttachmentAsync(
        contractData.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');

        const dataFile: AttachmentModel[] = res.data as AttachmentModel[];
        if (dataFile && dataFile.length > 0) {
          const newFiles: IFile[] = dataFile.map((data, index) => ({
            id: data.id!,
            name: data.fileName,
            file: null,
          } as IFile));

          setFiles(newFiles);
        }
      }
    }
  };

  const removeItemAsync = async (index: number, docId: string) => {
    if (contractData && contractData.id) {
      const res = await CM02Service.removeFileAsync(
        contractData.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');

        getDocumentContractByIdAsync(contractData.id);
      }
    }
  };

  const onChangeCollateralActive = (val: string) => {
    setContractData({ ...contractData, collateral: { ...contractData.collateral, active: val === 'true' } });

    if (val === 'false') {
      setIsDisabledCollateral(true);
      const collateral = {
        active: false,
        type: undefined,
        amount: undefined,
        percent: undefined,
        fineType: undefined,
        finePercent: undefined,
        bankId: undefined,
        bankBranch: undefined,
        bankAccountNumber: undefined,
        bankCollateralEndDate: undefined,
        bankCollateralStartDate: undefined,
        referenceNumber: undefined,
        changeCollateralIn: undefined,
        changeCollateralInUnit: undefined,
      } as CollateralModel;
      setIsBank(false);
      setIsReferenceNumber(false);
      setContractData({ ...contractData, collateral });
    } else {
      setIsDisabledCollateral(false);
    }
  };

  const onChangeCollateralType = (val: string) => {
    setIsReferenceNumber(false);
    setIsBank(false);

    if (val === PerformanceBondType.cash || val === PerformanceBondType.cheque || val === PerformanceBondType.governmentBonds) {
      setIsReferenceNumber(true);
      setContractData({ ...contractData, collateral: { ...contractData.collateral, changeCollateralIn: undefined, bankId: undefined, bankBranch: undefined, bankAccountNumber: undefined, bankCollateralStartDate: undefined, bankCollateralEndDate: undefined, type: val } })
      return;
    }

    if (val === PerformanceBondType.guaranteeBank || val === PerformanceBondType.guaranteeCompany || val === PerformanceBondType.guaranteeElectronics) {
      setIsBank(true);
      setContractData({ ...contractData, collateral: { ...contractData.collateral, changeCollateralIn: 15, referenceNumber: undefined, type: val } })
      return;
    }
  };

  const onClickNextStep = () => {
    onTemplateChange(contractTemplateCode, contractTemplateId);
    onClickNext();
  };

  const onChangeWarrantyType = (val: string) => {
    setContractData({ ...contractData, warranty: { ...contractData.warranty, startDateCondition: val } });
    if (val === typeWarrantyDueDate.AccordingSpecifiedPeriod) {
      setIsDisabledWarrantyDueDate(false);
    } else {
      setIsDisabledWarrantyDueDate(true);
    }
  };

  const goToTorPage = (torId?: string) => {
    if (torId) {
      window.open(`/web/preprocurement-tor/detail/${torId}`, '_blank');
    }
  };

  const onGetDefaultApprover = async () => {
    const response = await account
      .getDefaultApproverContract(PROCESSTYPE.ApproveSignContractProcess, dataContext.sumOfferTotalAmountVat, dataContext.supplyMethodId);
    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: ContractAgreementAcceptorModel[] = [];

      if (dataContext.contractAgreementAcceptors === undefined ||
        dataContext.contractAgreementAcceptors === null ||
        dataContext.contractAgreementAcceptors.length === 0) {
        newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {

            sectionDataGroup.push({
              sectionName: item.sectionName,
              level: item.sectionLevel,
              sectionSequence: item.sectionSequence,
              inRefCode: item.inRefCode,
              sectionNameTh: item.sectionName,
              sectionId: item.sectionId,
            } as sectionGroup);

            approver.push({
              id: undefined,
              sectionInRefCode: item.inRefCode!,
              sectionSequence: item.sectionSequence!,
              sectionName: item.sectionName!,
              userId: item.userId,
              fullName: item.fullName,
              positionName: item.positionName,
              departmentName: item.departmentName,
              isAvailable: false,
              availableRemark: undefined,
              acceptDate: undefined,
              acceptRemark: undefined,
              rejectDate: undefined,
              rejectRemark: undefined,
              delegateUserId: item.delegateUserId,
              delegateEmployeeCode: item.delegateEmployeeCode,
              delegateFullName: item.delegateFullName,
              delegateDepartmentName: item.delegateDepartmentName,
              delegatePositionName: item.delegatePositionName,
              type: ContractAgreementTypeAcceptor.AcceptorSign,
              sequence: acceptors.filter((x) => x.sectionSequence === item.sectionSequence).length + 1,
              status: 'Draft',
              seqKey: `${item.sectionSequence?.toString()}${(acceptors.filter((x) => x.sectionSequence === item.sectionSequence).length + 1).toString()}`,
            } as ContractAgreementAcceptorModel);
          });

        const uniqueNames = sectionDataGroup.filter((data, index) => {
          return sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index;
        });

        if (acceptors === undefined || acceptors === null || acceptors.length === 0) {
          let inx = 0;
          let sectionSeq = 0;

          approver?.sort((a, b) => a.sectionSequence - b.sectionSequence)
            .forEach((item, index) => {

              if (sectionSeq !== item.sectionSequence) {
                inx = 1;
                sectionSeq = item.sectionSequence;
              }

              approver[index].sequence = inx;

              inx++;
            });

          setAcceptors(approver);
          setSections(uniqueNames);
        }
      } else {
        dataContext.contractAgreementAcceptors.forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: index,
            sectionSequence: item.sectionSequence,
            inRefCode: item.sectionInRefCode,
            sectionNameTh: item.sectionName,
            sectionId: '',
          } as sectionGroup);
        });

        const uniqueNames = sectionDataGroup.filter((data, index) => {
          return sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index;
        });
        setSections(uniqueNames);
      }
    }
  };

  const onSaveEditing = async (isChang: boolean, isCancel: boolean) => {
    submitForm();

    if (remarkEditing === undefined || remarkEditing === '' || remarkEditing === null) {
      return;
    }

    if (contractData.id) {
      const createEditingData: CreateEditingContractAgreementModel = {
        remark: remarkEditing,
        isCancel: isCancel,
        IsChange: isChang,
      };

      const response = await CM02Service.createEditingContractAgreementAsync(contractData.id, createEditingData);
      if (response.status !== HttpStatusCode.CREATED) {
        toast.error('ไม่สามารถบันทึกแก้ไขสัญญาได้');
        setShowEditConfirmModal(false);
        return;
      }

      const newId: string = response.data.id;
      toast.success('บันทึกแก้ไขสัญญาสำเร็จ');
      navigate(`/edit-contract-agreement/detail/${newId}`);
      getContractByIdAsync(newId);
      setShowEditConfirmModal(false);
    }
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title text='ข้อมูลโครงการ'
          className='fs-5 text-primary' />
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={6}>
            <Readonly label='เลขที่รายการจัดซื้อจัดจ้าง'
              textReadonly={contractData.planNumber} />
          </Col>
          <Col sm={12}
            md={6}>
            <Readonly label='เลขที่จัดทำร่างขอบเขตงาน'
              textReadonly={
                <Button
                  variant="link"
                  onClick={() => goToTorPage(contractData.torId)}
                  className='p-0 m-0'>
                  {contractData.torNumber}
                </Button>
              } />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={6}>
            <Readonly label='โครงการ'
              textReadonly={contractData.planName} />
          </Col>
          <Col sm={12}
            md={3}>
            <Readonly label='งบประมาณโครงการ (บาท)'
              textReadonly={formatter.format(contractData.financialAmount)} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={3}
            xl={3}>
            <Readonly label='วิธีจัดหา'
              textReadonly={contractData.supplyMethodName} />
          </Col>
          <Col sm={12}
            md={3}>
            <Readonly textReadonly={contractData.supplyMethodTypeName} />
          </Col>
          <Col sm={12}
            md={3}>
            <Readonly textReadonly={contractData.supplyMethodSpecialTypeName} />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='ข้อมูลสัญญา'
          className='fs-5 text-primary' />
        <Row className='mt-3'>
          <Col sm={12}
            md={7}>
            <Input
              label='คู่ค้า'
              placeholder='คู่ค้า'
              value={contractData.entrepreneur?.name}
              disabled />
          </Col>
          <Col sm={12}
            md={4}>
            <Input
              label='Email (คู่ค้า)'
              placeholder='Email (คู่ค้า)'
              value={contractData.entrepreneur?.email}
              rule={{ required: true }}
              onChange={(val) => setContractData({ ...contractData, entrepreneur: { ...contractData.entrepreneur, email: val } })}
              disabled={isDisabled} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={7}>
            <Input
              label='ชื่อสัญญา'
              placeholder='ชื่อสัญญา'
              value={contractData.contractName}
              onChange={(val) => setContractData({ ...contractData, contractName: val })}
              disabled={isDisabled} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={4}>
            <Input label='เลขที่สัญญา PO'
              placeholder='เลขที่สัญญา PO'
              value={contractData.poNumber}
              disabled
              textCenter
              rule={{ required: true }} />
          </Col>
          <Col sm={12}
            lg={3}>
            <Input label='เลขที่สัญญา จพ.(สบส.)'
              placeholder='เลขที่สัญญา จพ.(สบส.)'
              value={contractData.contractNumber}
              disabled
              textCenter
              rule={{ required: true }} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={4}>
            <Currency label='วงเงินตามสัญญา'
              placeholder='วงเงินตามสัญญา'
              value={contractData.offerTotalAmountVat}
              rule={{ required: true }}
              onChange={(val) => setContractData({ ...contractData, offerTotalAmountVat: Number(val) })}
              disabled={true} />
          </Col>
          {/* <Col sm={12}
            lg={3}>
            <DatePicker label='วันที่ทำสัญญา'
              value={contractData.contractCreateDate}
              onChangeHasNullable={(val) => setContractData({ ...contractData, contractCreateDate: val })}
              rule={{ required: true }}
              disabled={isDisabled} />
          </Col> */}
          <Col sm={12}
            lg={3}>
            <DatePicker label='วันที่ลงนามในสัญญา'
              value={contractData.contractDate}
              disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={4}>
            <Selector label='ประเภทสัญญา'
              value={contractData.contractType}
              defaultValue={contractManagementTypeDDL?.find((i) => i.value2 === dataContext.supplyMethodTypeId)?.value}
              items={contractManagementTypeDDL}
              rule={{ required: true }}
              onChange={(val) => onChangeContractType(val)}
              disabled={isDisabled} />
          </Col>
          <Col sm={12}
            lg={3}>
            <Selector label='รูปแบบสัญญา'
              value={contractData.contractTemplate}
              items={contractFormatDDL}
              rule={{ required: true }}
              onChange={(val) => onChangeContractFormatType(val)}
              disabled={isDisabled} />
          </Col>
          {
            (contractFormatLevelDDL && contractFormatLevelDDL.length > 0) && (
              <Col sm={12}
                lg={3}>
                <Selector
                  className='no-label'
                  value={contractData.subContractTemplate}
                  items={contractFormatLevelDDL}
                  rule={{ required: contractFormatLevelDDL.length > 0 }}
                  onChange={(val) => setContractData({ ...contractData, subContractTemplate: val })}
                  disabled={isDisabled} />
              </Col>
            )
          }
          {(contractTemplatenName !== ''
            && contractTemplatenName !== undefined
            && contractTemplatenName !== null) && (
              <>
                <Col sm={12}
                  lg={4}>
                  <Input label={`ชื่องาาน ${contractTemplatenName}`}
                    remark='เช่น: คอมพิวเตอร์, Server อื่นๆ'
                    value={contractData.contractTemplateText}
                    onChange={(val) => setContractData({ ...contractData, contractTemplateText: val })}
                    rule={{ required: true }}
                    disabled={isDisabled} />
                </Col>
              </>
            )}
        </Row>
        <>
          {
            (contractData.deliveryPeriod?.periodYear ||
              contractData.deliveryPeriod?.periodMonth ||
              contractData.deliveryPeriod?.periodDay) &&
            (

              <Row className='align-items-center'>
                <Col sm={12}
                  lg={2}
                  className='d-flex'>
                  <Input label='ระยะเวลาปฏิบัติงาน'
                    value={contractData.deliveryPeriod?.periodYear}
                    disabled={true}
                    textCenter />
                  <label className='mt-5 ps-2'>ปี</label>
                </Col>

                <Col sm={12}
                  lg={2}
                  className='d-flex'>
                  <Input className='no-label'
                    value={contractData.deliveryPeriod?.periodMonth}
                    disabled={true}
                    textCenter />
                  <label className='mt-5 ps-2'>เดือน</label>
                </Col>

                <Col sm={12}
                  lg={2}
                  className='d-flex'>
                  <Input className='no-label'
                    value={contractData.deliveryPeriod?.periodDay}
                    disabled={true}
                    textCenter />
                  <label className='mt-5 ps-2'>วัน</label>
                </Col>
              </Row>
            )
          }
        </>
        <Row className='align-items-center'>
          <Col sm={12}
            lg={4}>
            <Selector
              label='ระยะเวลาเริ่มต้นสัญญา'
              items={contractStartDatePeriodConditionDDL}
              value={contractData.deliveryPeriod?.startDateCondition}
              onChange={(val) =>
                setContractData({
                  ...contractData,
                  deliveryPeriod: {
                    ...contractData.deliveryPeriod,
                    startDateCondition: val,
                    startDate: val === ContractStartDatePeriodConditionIdEnum.ตามระยะเวลาที่ระบุ ? contractData.deliveryPeriod.startDate : undefined,
                    dueDate: val === ContractStartDatePeriodConditionIdEnum.ตามระยะเวลาที่ระบุ ? contractData.deliveryPeriod.dueDate : undefined,
                  }
                })}
              rule={{ required: true }}
              disabled={isDisabled} />
          </Col>
          {/* <Col sm={12}
            lg={2}
            className='pt-4'>
            <Check
              value={contractData.deliveryPeriod?.workDayOnly}
              label={<div className='align-items-center d-flex'>นับเฉพาะวันทำการ </div>}
              onChange={(val) => setContractData({ ...contractData, deliveryPeriod: { ...contractData.deliveryPeriod, workDayOnly: val } })}
              disabled={isDisabled}
            />
          </Col> */}
          {
            (contractData.deliveryPeriod?.startDate
              || contractData.deliveryPeriod?.startDateCondition === ContractStartDatePeriodConditionIdEnum.ตามระยะเวลาที่ระบุ) &&
            (
              <>
                <Col sm={12}
                  lg={3}>
                  <DatePicker label='วันที่เริ่มปฏิบัติงาน'
                    value={contractData.deliveryPeriod?.startDate}
                    onChangeHasNullable={(val) =>
                      setContractData({
                        ...contractData,
                        deliveryPeriod: {
                          ...contractData.deliveryPeriod,
                          startDate: val
                        }
                      })}
                    rule={{ required: contractData.deliveryPeriod?.startDateCondition === ContractStartDatePeriodConditionIdEnum.ตามระยะเวลาที่ระบุ, maxStartDate: contractData.deliveryPeriod?.dueDate }}
                    disabled={isDisabled} />
                </Col>
                <Col sm={12}
                  lg={3}>
                  <DatePicker label='จนถึง'
                    value={contractData.deliveryPeriod?.dueDate}
                    onChangeHasNullable={(val) =>
                      setContractData({
                        ...contractData,
                        deliveryPeriod: {
                          ...contractData.deliveryPeriod,
                          dueDate: val
                        }
                      })}
                    rule={{ required: contractData.deliveryPeriod?.startDateCondition === ContractStartDatePeriodConditionIdEnum.ตามระยะเวลาที่ระบุ, minEndDate: contractData.deliveryPeriod?.startDate }}
                    disabled={isDisabled} />
                </Col>
              </>
            )
          }
        </Row>
        <Row>
          <Col
            sm={10}
            style={{ fontSize: '13px' }}>
            <div className='bg-light rounded bg-opacity-25 p-3 pb-1'>
              <p>*หมายเหตุ: ระยะเวลาเริ่มต้นสัญญา คือ เงื่อนไขสำหรับคำนวนวันที่สิ้นสุดของสัญญา โดยระบบจะคำนวนหลังจากยืนยันวันที่ลงนามแล้ว</p>
            </div>
          </Col>
        </Row>
        <>
          {
            (contractData.warranty?.periodYear
              || contractData.warranty?.periodMonth
              || contractData.warranty?.periodDay) &&
            (
              <>
                <Row className='align-items-center'>
                  <Col sm={12}
                    lg={2}
                    className='d-flex'>
                    <Input label='ระยะเวลารับประกัน'
                      value={contractData.warranty?.periodYear}
                      disabled={true}
                      textCenter
                      onChange={(val) => setContractData({ ...contractData, warranty: { ...contractData.warranty, periodYear: Number(val) } })}
                    />
                    <label className='mt-5 ps-2'>ปี</label>
                  </Col>
                  <Col sm={12}
                    lg={2}
                    className='d-flex'>
                    <Input className='no-label'
                      value={contractData.warranty?.periodMonth}
                      disabled={true}
                      textCenter
                      onChange={(val) => setContractData({ ...contractData, warranty: { ...contractData.warranty, periodMonth: Number(val) } })}
                    />
                    <label className='mt-5 ps-2'>เดือน</label>
                  </Col>
                  <Col sm={12}
                    lg={2}
                    className='d-flex'>
                    <Input className='no-label'
                      value={contractData.warranty?.periodDay}
                      disabled={true}
                      textCenter
                      onChange={(val) => setContractData({ ...contractData, warranty: { ...contractData.warranty, periodDay: Number(val) } })}
                    />
                    <label className='mt-5 ps-2'>วัน</label>
                  </Col>
                  <Col sm={12}
                    lg={3}
                    className='pt-4'>
                    <Selector items={warrantyConditionDDL}
                      value={contractData.warranty?.startDateCondition}
                      onChange={(val) => onChangeWarrantyType(val)}
                      disabled={true} />
                  </Col>
                  {/* <Col sm={12}
                      lg={3}
                      className='pt-4'>
                      <Check
                        value={contractData.warranty?.workDayOnly}
                        label={<div className='align-items-center d-flex'>นับเฉพาะวันทำการ </div>}
                        onChange={(val) => setContractData({ ...contractData, warranty: { ...contractData.warranty, workDayOnly: val } })}
                        disabled={isDisabled}
                      />
                    </Col> */}
                </Row>
              </>
            )
          }
        </>
      </Card>

      {/* <Card className='mt-3'>
        <Title text='ข้อมูลหลักประกันสัญญา'
          className='fs-5 text-primary' />
        <Row>
          <Col sm={12}>
            <Radio
              className='my-3'
              dflex
              items={INFO}
              value={contractData.collateral !== undefined && contractData.collateral !== null ? contractData.collateral?.active.toString() : 'false'}
              rule={{ required: true }}
              onChange={(val) => onChangeCollateralActive(val.toString())}
              disabled={isDisabled}
              name='name1'
            />
          </Col>
          <Col sm={12}
            lg={3}>
            <Selector label='ประเภทหลักประกันสัญญา'
              items={performanceBondTypeDDL}
              value={contractData.collateral?.type}
              rule={{ required: true }}
              onChange={(val) => onChangeCollateralType(val)}
              disabled={isDisabled || isDisabledCollateral} />
          </Col>
          {isReferenceNumber &&
            <Col sm={12}
              lg={3}>
              <Input label='เลขที่อ้างอิง'
                value={contractData.collateral?.referenceNumber}
                rule={{ required: true }}
                onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, referenceNumber: val } })}
                disabled={isDisabled || isDisabledCollateral} />
            </Col>}
          {isBank &&
            <>
              <Col sm={12}
                lg={3}>
                <Selector label='ธนาคาร'
                  items={bankDDL}
                  value={contractData.collateral?.bankId}
                  rule={{ required: true }}
                  onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, bankId: val } })}
                  disabled={isDisabled || isDisabledCollateral} />
              </Col>
              <Col sm={12}
                lg={3}>
                <Input label='สาขาธนาคาร'
                  value={contractData.collateral?.bankBranch}
                  onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, bankBranch: val } })}
                  disabled={isDisabled || isDisabledCollateral} />
              </Col>
              <Col sm={12}
                lg={3}>
                <Input label='เลขที่'
                  value={contractData.collateral?.bankAccountNumber}
                  rule={{ required: true }}
                  onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, bankAccountNumber: val } })}
                  disabled={isDisabled || isDisabledCollateral} />
              </Col>
              <Col sm={12}
                lg={3}>
                <DatePicker label='เลขที่หลักประกันลงวันที่เริ่มต้น'
                  value={contractData.collateral?.bankCollateralStartDate}
                  rule={{ required: true }}
                  onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, bankCollateralStartDate: val } })}
                  disabled={isDisabled || isDisabledCollateral} />
              </Col>
              <Col sm={12}
                lg={3}>
                <DatePicker label='เลขที่หลักประกันลงวันที่สิ้นสุด'
                  value={contractData.collateral?.bankCollateralEndDate}
                  rule={{ required: true }}
                  onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, bankCollateralEndDate: val } })}
                  disabled={isDisabled || isDisabledCollateral} />
              </Col>
            </>
          }
          <Col sm={12}
            lg={3}>
            <Currency label='จำนวนเงินหลักประกัน'
              value={contractData.collateral?.amount}
              rule={{ required: true }}
              onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, amount: val! } })}
              disabled={isDisabled || isDisabledCollateral} />
          </Col>
          <Col sm={12}
            lg={3}>
            <Currency label='ร้อยละของราคาค่าจ้างตามสัญญา'
              value={contractData.collateral?.percent}
              rule={{ required: true }}
              onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, percent: val! } })}
              disabled={isDisabled || isDisabledCollateral} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={3}>
            <Selector label='ค่าปรับราย'
              items={periodTypeDDL}
              value={contractData.collateral?.fineType}
              rule={{ required: true }}
              onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, fineType: val } })}
              disabled={isDisabled || isDisabledCollateral} />
          </Col>
          <Col sm={12}
            lg={3}>
            <Currency label='ค่าปรับ (%)'
              value={contractData.collateral?.finePercent}
              rule={{ required: true }}
              onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, finePercent: val! } })}
              disabled={isDisabled || isDisabledCollateral} />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col sm={12}>
            ถ้าหลักประกันเสื่อมลง หรือมีอายุไม่ครอบคลุม ผู้รับจ้างต้องหาหลักประกันมาเปลี่ยนใหม่
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col sm={12} md={2}>
            <InputNumber
              label='ภายใน'
              value={contractData.collateral?.changeCollateralIn}
              onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, changeCollateralIn: val! } })}
              disabled={isDisabled || isDisabledCollateral}
            />
          </Col>
          <Col sm={12} md={2}>
            <Selector
              items={periodTypeDDL}
              className='no-label'
              value={contractData.collateral?.changeCollateralInUnit}
              onChange={(val) => setContractData({ ...contractData, collateral: { ...contractData.collateral, changeCollateralInUnit: val! } })}
              disabled={isDisabled || isDisabledCollateral}
            />
          </Col>
        </Row>
      </Card> */}

      <Card className='mt-3'>
        <Title text='ตรวจสอบคู่ค้า'
          className='fs-5 text-primary' />
        <Row className='mt-3'>
          <Col sm={12}>
            <Table hidePagination>
              <thead>
                <tr className='text-center'>
                  <th>เลขประจำตัวผู้เสียภาษี</th>
                  <th>ประเภทผู้ประกอบการ</th>
                  <th>ชื่อสถานประกอบการ</th>
                  <th>อีเมล</th>
                  {/* <th>COI</th>
                  <th>Watchlist</th> */}
                  <th>ตรวจสอบผู้ทิ้งงาน ครั้งที่ 1</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-center'>
                  <td>{contractData.entrepreneur?.taxId}</td>
                  <td> {contractData.entrepreneur?.traderType === 'JuristicPerson' ? 'นิติบุคคล' : contractData.entrepreneur?.traderType === 'Individual' ? 'บุคคลธรรมดา' : ''}</td>
                  <td>{contractData.entrepreneur?.name}</td>
                  <td><Input type='text'
                    value={contractData.entrepreneur?.email}
                    disabled={isDisabled} /></td>
                  {/* <td>
                    <ButtonCustom
                      variant={checkBooleanNullOrUndefined(contractData.entrepreneur?.coiResult) ? 'primary' : contractData.entrepreneur?.coiResult ? 'success' : 'danger'}
                      onClick={() => onOpenCoiModal(contractData.entrepreneur, TraderModalTypeConstant.COI, contractData.entrepreneur?.coiResult, contractData.entrepreneur?.coiRemark)}
                      text={checkBooleanNullOrUndefined(contractData.entrepreneur?.coiResult) ? 'บันทึกผล' : 'บันทึกแล้ว'} />
                  </td>
                  <td>
                    <ButtonCustom
                      variant={checkBooleanNullOrUndefined(contractData.entrepreneur?.watchListResult) ? 'primary' : contractData.entrepreneur?.watchListResult ? 'success' : 'danger'}
                      onClick={() => onOpenCoiModal(contractData.entrepreneur, TraderModalTypeConstant.WATCHLIST, contractData.entrepreneur?.watchListResult, contractData.entrepreneur?.watchListRemark)}
                      text={checkBooleanNullOrUndefined(contractData.entrepreneur?.watchListResult) ? 'บันทึกผล' : 'บันทึกแล้ว'} />
                  </td> */}
                  <td>
                    <ButtonCustom
                      disabled={isDisabled}
                      variant={checkBooleanNullOrUndefined(contractData.entrepreneur?.egpResult) ? 'primary' : contractData.entrepreneur?.egpResult ? 'success' : 'danger'}
                      onClick={() => onOpenCoiModal(contractData.entrepreneur, TraderModalTypeConstant.EGP, contractData.entrepreneur?.egpResult, contractData.entrepreneur?.egpRemark)}
                      text={checkBooleanNullOrUndefined(contractData.entrepreneur?.egpResult) ? 'บันทึกผล' : 'บันทึกแล้ว'} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary'
          confidentiality
          lineBorder />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFile
              disabled={isDisabled}
              files={confidentialFiles}
              handleFileChange={handleFileConfidentialChange}
              removeItem={removeItemAsync}
              contractRecordId={contractData.id!}
            />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary'
          lineBorder />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={contractData.id!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text='กลับหน้าแรก'
        />
        {contractData.isResponsible && (
          contractData.status === ContractAgreementStatus.Draft
          || contractData.status === ContractAgreementStatus.Rejected)
          ? (
            <ButtonCustom
              className='me-2'
              onClick={() => setShowSaveConfirmModal(true)}
              text='บันทึก'
            />
          )
          : <></>
        }
        <ButtonCustom
          onClick={onClickNextStep}
          text="ถัดไป"
          disabled={contractData.contractTemplate === ''} />
      </div>
      <CoiModal
        title={modalTitle}
        show={showCoiModal}
        modalType={modalType}
        onHide={() => setShowCoiModal(!showCoiModal)}
        traderModal={entrepreneur}
        entrepreneurTypeDDL={entrepreneurTypeDDL}
        onSubmit={onSubmitTraderResultModal}
        contractId={contractData.id}
        disabled={isDisabled}
        isResponsible={contractData.isResponsible}
      />
      <Modal
        show={showEditConfirmModal}
        size='lg'
        onHide={() => setShowEditConfirmModal(!showEditConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title> คุณต้องการบันทึกแก้ไขสัญญาหรือไม่ ?</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRemarkEditing(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowEditConfirmModal(!showEditConfirmModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                onClick={() => onSaveEditing(true, false)}
              >
                <BsCheckCircle className='mx-2 pb-1 fs-4' />
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showSaveConfirmModal}
        size='lg'
        onHide={() => setShowSaveConfirmModal(!showSaveConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการบันทึกข้อมูลหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <ButtonCustom
                variant='danger'
                onClick={() => setShowSaveConfirmModal(!showSaveConfirmModal)}
                text="ยกเลิก"
                icon="cancel"
              />
              <ButtonCustom
                onClick={onSave}
                text="ยืนยัน"
                icon="save"
              />
            </ModalBT.Footer>
          </>
        )}
      />
    </div >
  );
}
