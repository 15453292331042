import {
  Card,
  FileValue,
  InputTextArea,
  Modal,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  CreatorLevel,
  JorPorCode,
} from 'constant/basicInformation';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaTimesCircle,
  FaUndo,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { planProcurement as planProcurementService } from 'services';
import {
  downloadFile,
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import { AcceptorStatus, PlanStatus as PlanStatusCons } from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { UploadFile } from '../Procurement/UploadFile';
import { Context } from './Detail';
import { isNull } from 'utils/helper';

interface Props {
  onClickBack?: () => void;
}

export default function Step3(props: Props) {
  const { currentStep, planProcurement, setPlanProcurement, reGetDetail, readonly } = useContext(Context);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const { userId, departmentId, departmentCode, inRefLevel } = useAppContext();
  const [showChangeModal, setShowChangeModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const canRemoveFile = useMemo(() => {
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel).filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const uploadFileOnChangeAsync = async (file: File) => {
    if (planProcurement.id) {
      const res = await planProcurementService.uploadFileAsync(
        planProcurement.id,
        file,
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        setPlanProcurement({
          ...planProcurement,
          documents: [
            ...planProcurement.documents,
            {
              file,
            },
          ],
        });

        toast.success('อัปโหลดไฟล์สำเร็จ');

        reGetDetail(planProcurement.id);
      }
    } else {
      if (!planProcurement.documents) {
        planProcurement.documents = [];
      }

      setPlanProcurement({
        ...planProcurement,
        documents: [
          ...planProcurement.documents,
          {
            file,
          },
        ],
      });
    }
  };

  const files = useCallback(() => {
    const files = planProcurement.documents?.filter((d) => d.id && d.name);

    return files?.map((f) => ({
      id: f.id,
      name: f.name,
      attachmentBy: f.byUserId,
    })) as FileValue[];
  }, [planProcurement.documents]);

  const removeFileAsync = async (i: number, id: string | undefined) => {
    if (id) {
      const res = await planProcurementService.removeFileAsync(planProcurement.id, id);

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
      }
    }

    planProcurement.documents.splice(i, 1);

    setPlanProcurement({
      ...planProcurement,
      documents: [...planProcurement.documents],
    });
  };

  const downloadFileAsync = async (index: number, documentId?: string) => {
    if (documentId) {
      const res = await planProcurementService
        .downloadFileAsync(planProcurement.id, documentId);

      if (res.status === HttpStatusCode.OK) {
        const file = planProcurement.documents[index];

        downloadFile(res.data);
      }
    } else {
      const file = planProcurement.documents[index];

      if (file.file) {
        downloadFile(file.file);
      }
    }
  };

  const conditionCanAction = useMemo(() => {
    if (!Object.keys(planProcurement).length || currentStep !== 3 || !userId) {
      return false;
    }

    if (planProcurement.isRefChange || planProcurement.isRefCancel) {
      return false;
    }

    if (planProcurement.isActive === false) {
      return false;
    }

    if (planProcurement.isCanceled) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.ApprovePlan) {
      return false;
    }

    // jorporDirectorBeInformed
    const foundInJorporDirectorBeInformed = planProcurement
      .jorporDirectorBeInformed?.some((s) => isNull(s.delegateUserId, s.userId) === userId && !s.isAvailable);

    if (foundInJorporDirectorBeInformed) {
      return true;
    }

    return false;
  }, [userId, planProcurement]);

  const canChangeAndCancel = useMemo(() => {
    if (!Object.keys(planProcurement).length || !userId) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.Announcement && planProcurement.budget > 500000) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (planProcurement.isCanceled) {
      return false;
    }

    if (planProcurement.isRefChange || planProcurement.isRefCancel) {
      return false;
    }

    return true;
  }, [planProcurement, userId]);

  return (
    <>
      {/* Upload File */}
      {currentStep === 3
        ? (
          <Card className="mt-3">
            <Title text="เอกสารแนบ" className="fs-5 text-primary" />
            <Row className="justify-content-center">
              <Col sm={12} lg={6}>
                <UploadFile
                  onChange={uploadFileOnChangeAsync}
                  value={files()}
                  onRemove={removeFileAsync}
                  onDownload={downloadFileAsync}
                  canRemoveFile={canRemoveFile}
                  disabled={readonly}
                />
              </Col>
            </Row>
          </Card>
        ) : null}

      {/* Button */}
      {currentStep === 3
        ? (
          <div className="d-flex justify-content-between mt-3">
            <ButtonCustom
              onClick={props.onClickBack}
              text="ย้อนกลับ"
            />
            <div className="d-flex gap-2">
              {conditionCanAction && (
                <ButtonCustom
                  icon="undo"
                  variant="danger"
                  onClick={() => setShowRejectModal(true)}
                  text="ส่งกลับแก้ไข" />
              )}
              {canChangeAndCancel
                && (
                  <>
                    <ButtonCustom
                      icon="undo"
                      onClick={() => setShowChangeModal(true)}
                      text="ขอเปลี่ยนแปลง" />
                    <ButtonCustom
                      icon="cancel"
                      variant="danger"
                      onClick={() => setShowCancelModal(true)}
                      text="ขอยกเลิก" />
                  </>
                )}
            </div>
            <div style={{ width: 100 }} />
          </div>
        ) : null}

      {/* Modal */}
      <RejectModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(false)}
      />
      <ChangeModal
        show={showChangeModal}
        onHide={() => setShowChangeModal(false)}
      />
      <CancelModal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
      />
    </>
  );
}

function RejectModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement, reGetDetail } = useContext(Context);
  const [remark, setRemark] = useState<string>();
  const { userId } = useAppContext();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  function isNull(oldValue?: string, newValue?: string) {
    if (oldValue) {
      return oldValue;
    }

    return newValue;
  }

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (remark) {
      const approver = planProcurement.jorporDirectorBeInformed
        .find((d) => isNull(d.delegateUserId, d.userId) === userId);

      if (approver) {
        const res = await planProcurementService
          .rejectAsync(
            planProcurement.id,
            approver.id,
            PlanStatusCons.RejectPlan,
            remark,
          );

        if (res.status === HttpStatusCode.ACCEPTED) {
          reGetDetail(planProcurement.id);
          toast.success('ส่งกลับแก้ไขสำเร็จ');

          props.onHide();
        }
      }
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
    >
      <Modal.Header>
        <Title
          text="ส่งกลับแก้ไข"
          className="fs-5 text-primary"
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label="เหตุผล"
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name="remark"
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-end gap-2">
        <Button
          variant="outline-primary"
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function ChangeModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement } = useContext(Context);
  const [remark, setRemark] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (!remark) {
      return;
    }

    const res = await planProcurementService.changeAsync(
      planProcurement,
      remark,
    );

    if (res.status === HttpStatusCode.CREATED) {
      toast.success('เปลี่ยนแปลงสำเร็จ');
      navigate('/procurement-plan-adjust');

      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
    >
      <Modal.Header>
        <Title
          text="ขอเปลี่ยนแปลง"
          className="fs-5 text-primary"
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label="เหตุผล"
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name="remark"
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-end gap-2">
        <Button
          variant="outline-primary"
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}

function CancelModal(props: {
  show: boolean;
  onHide: () => void;
}) {
  const { planProcurement } = useContext(Context);
  const [remark, setRemark] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (props.show) {
      setRemark('');
    }
  }, [props.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (!remark) {
      return;
    }

    const res = await planProcurementService.cancelAsync(
      planProcurement,
      remark,
    );

    if (res.status === HttpStatusCode.CREATED) {
      toast.success('ยกเลิกสำเร็จ');
      navigate('/procurement-plan-cancel');

      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
    >
      <Modal.Header>
        <Title
          text="ขอยกเลิก"
          className="fs-5 text-primary"
        />
      </Modal.Header>
      <Row>
        <Col>
          <InputTextArea
            label="เหตุผล"
            rule={{ required: true }}
            onChange={setRemark}
            value={remark}
            name="remark"
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-end gap-2">
        <Button
          variant="outline-primary"
          onClick={props.onHide}
        >
          ยกเลิก
        </Button>
        <Button
          variant="primary"
          onClick={onSubmitAsync}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}
