import { MedianPrice } from 'models';
import http from 'utils/axios';

// const saveAsync = async (medianPrice: MedianPrice) => {
//   const formData = new FormData();
//   formData.append('PlanId', medianPrice.planId);
//   formData.append('CreateAt', medianPrice.createdAt.toISOString());
//   formData.append('Title', medianPrice.title);
//   formData.append('TemplateId', medianPrice.templateId);
//   formData.append('SettingDate', medianPrice.settingDate.toISOString());
//   formData.append('MiddlePrice', medianPrice.middlePrice.toString());

//   if (medianPrice.sources && medianPrice.sources.length) {
//     medianPrice.sources.forEach((s) => formData.append('Sources', s.company));
//   }

//   return await http.post('medianprice', formData);
// };

// const updateAsync = async (medianPrice: MedianPrice) => {
//   const formData = new FormData();
//   formData.append('Id', medianPrice.id);
//   formData.append('Title', medianPrice.title);
//   formData.append('TemplateId', medianPrice.templateId);
//   formData.append('SettingDate', medianPrice.settingDate.toISOString());
//   formData.append('MiddlePrice', medianPrice.middlePrice.toString());

//   if (medianPrice.sources && medianPrice.sources.length) {
//     medianPrice.sources.forEach((s) => formData.append('Sources', s.company));
//   }

//   return await http.put('medianprice', formData);
// };

const getTorSingleAsync = async (torId: string) => await http.get(`/termofref/${torId}`);

const getPlanSingleAsync = async (planId: string) => await http.get(`annualplan/${planId}`);

// const getMedianPriceSingleAsync = async (planId: string) => await http.get(`/medianprice/${planId}`);

export default {
  // saveAsync,
  // updateAsync,
  getTorSingleAsync,
  getPlanSingleAsync,
  // getMedianPriceSingleAsync,
};