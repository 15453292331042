import {
  ButtonCustom,
  Card,
  Currency,
  Input,
  InputTextArea,
  Modal,
  PurchaseOrderApprovalStatus,
  SearchBuNameModal,
  Selector,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import {
  AssigneesLevel,
  ContractType,
  PurchaseOrderApprovalStatusConstant,
} from 'constant/PurchaseOrderApprovalConstant';
import {
  IFile,
  ItemModel,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
} from 'models';
import {
  AcceptorsModel,
  AssigneesModel,
  ContractRecordAssigneeRequestModel,
  documentsModel,
  entrepreneursListModel,
  EntrepreneursPoRequestModel,
  financialsModel,
  PurchaseOrderApprovalModel,
  PurchaseOrderApprovalRequestModel,
} from 'models/PR/PurchaseOrderApprovalModels';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaCheckCircle,
  FaHistory,
  FaPlus,
  FaRegCheckCircle,
  FaSave,
  FaTrash,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
} from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router';
import { planProcurement, shareValue } from 'services';
import PurchaseOrderApprovalServices from 'services/PR/PurchaseOrderApprovalServices';
import {
  HttpStatusCode,
  ProcurementSection,
  submitForm,
  THCurrency,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { Context } from '.';
import { EntrepreneursWinneModal } from '../EntrepreneursWinneModal';
import { HistoryModal } from '../HistoryModal';
import UploadFile from '../UploadFile';
import { FinancialModal } from './FinancialModal';
import { TraderModal } from 'pages/Procurement/InvitationOrder/Modal/TraderModal';
import { PlanProcurementModal } from 'components/Modal/PlanProcurementModal';
import { AcceptorStatus, ProgramType } from 'utils/constants/PlanEnum';
import { JorPorCode } from 'constant/basicInformation';
import { Acceptors } from 'components/Table/Acceptors';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import { isNull } from 'utils/helper';
import { Assignee } from 'components/Table/Assignee';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type Loader = {
  supplyMethodDDL: ItemModel[],
  supplyMethodTypeDDL: ItemModel[],
  departmentDDL: ItemModel[],
  budgetYearDDL: ItemModel[],
  contractTypeDDL: ItemModel[],
  receivceCommitteeTypeDDL: ItemModel[],
};

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

function Step1({ onClickNext, onClickBack }: Props) {
  const [showHistory, setShowHistory] = useState(false);
  const [showTrader, setShowTrader] = useState<boolean[]>([]);
  const { data, setData } = useContext(Context);
  const [purchaseOrderApproval, setPerchaseOrderApproval] = useState<PurchaseOrderApprovalModel>({} as PurchaseOrderApprovalModel);
  const { userId, departmentCode, positionCode } = useAppContext();
  const { supplyMethodDDL, supplyMethodTypeDDL, departmentDDL, budgetYearDDL, contractTypeDDL, receivceCommitteeTypeDDL } = useLoaderData() as Loader;
  const [supplyMethod, setSupplyMethod] = useState('');
  const [supplyMethodType, setSupplyMethodType] = useState('');
  const [supplyMethodSpecialType, setSupplyMethodSpecialType] = useState('');
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);
  const [isDisabled, setisDisabled] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const navigate = useNavigate();
  const [showAssignee, setShowAssignee] = useState(false);
  const [showCreatePO, setShowCreatePO] = useState(false);
  const [showUpdatePO, setShowUpdatePO] = useState(false);
  const [contractType, setContractType] = useState('');
  const [isDisabledContractType, setisDisabledContractType] = useState(false);
  const [financialIndex, setFinancialIndex] = useState<number>(0);
  const [entrepreneursIndex, setEntrepreneursIndex] = useState<number>(0);
  const [entrepreneursId, setEntrepreneursId] = useState<string>();
  const [receivceCommitteeDDL, setReceivceCommitteeDDL] = useState<ItemModel[]>([]);
  const [planName, setPlanName] = useState('');
  const [financialAmount, setFinancialAmount] = useState<number>(0);
  const [departmentId, setDepartmentId] = useState('');
  const [planNumber, setPlanNumber] = useState('');
  const [budgetYear, setBudgetYear] = useState<number>(0);
  const [showContractFinancial, setShowContractFinancial] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean[]>([]);
  const [showEntrepreneur, setShowEntrepreneur] = useState<boolean[]>([]);
  const [showEditContractFinancial, setEditShowContractFinancial] = useState<boolean[]>([]);
  const [isDisabledPlan, setisDisabledPlan] = useState(false);
  const [showPlan, setShowPlan] = useState(false);
  const [acceptorsData, setAcceptorsData] = useState<AcceptorsModel[]>([]);
  const [sectionType, setSectionType] = useState<string>();
  const [sectionSequence, setSectionSequence] = useState<number>();
  const [isApprover, setIsApprover] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const { id } = useParams();
  const [showPending, setShowPending] = useState(false);
  const [showReCall, setShowReCall] = useState(false);
  const [showRejected, setShowRejected] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const [remark, setRemark] = useState('');
  const [showModals, setShowModals] = useState(false);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [assigneesDataLevel0, setAssigneesDataLevel0] = useState<AssigneesModel[]>([]);
  const [assigneesDataLevel1, setAssigneesDataLevel1] = useState<AssigneesModel[]>([]);
  const [showUserAssigneeLevel0, setShowUserAssigneeLevel0] = useState(false);
  const [showUserAssigneeLevel1, setShowUserAssigneeLevel1] = useState(false);
  const [showSaveAssignees, setShowSaveAssignees] = useState(false);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = Math.max.apply(null,
      data.contractRecord?.acceptors?.map((x) => x.sectionSequence));

    const valueSequence = data.contractRecord.acceptors?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  const onCheckApprover = (datas: AcceptorsModel[]) => {

    const maxValueSectionSequence = Math.max.apply(null,
      datas?.map((x) => {
        return x.sectionSequence;
      }));

    const maxValueSequence = Math.max.apply(null,
      datas?.filter(a => a.sectionSequence === maxValueSectionSequence)?.map((x) => {
        return x.sequence;
      }));

    const dataMaxValueSectionSequence = datas?.filter(a => a.sectionSequence === maxValueSectionSequence && a.sequence === maxValueSequence);
    const approverIndex = dataMaxValueSectionSequence?.findIndex(x => x.userId === userId || x.delegateUserId === userId) + 1;

    if (approverIndex) {
      return true;
    }

    return false;
  };

  const isQueueApprover = useMemo(() => {
    if (data.contractRecord != null && data.contractRecord?.acceptors != null && data.contractRecord?.acceptors.length > 0) {
      const minSection = Math.min(...data.contractRecord?.acceptors
        .filter(d => d.status === AcceptorStatus.PENDING)
        .map(o => o.sectionSequence));

      const sectionData = data.contractRecord?.acceptors
        .filter(d => d.sectionSequence === minSection
          && d.status === AcceptorStatus.PENDING)
        .sort((a, b) => a.sequence - b.sequence)[0];

      if (sectionData) {
        return isNull(sectionData.delegateUserId, sectionData.userId) === userId;
      }
    }

  }, [userId, data]);

  const onSelectEmpApprover = (empId: string, name: string, department: string, position: string, index: number, sectionSequence?: number, sectionName?: string, inRefCode?: string, sectionType?: string, delegateUserId?: string, delegateFullName?: string, delegateDepartmentName?: string, delegatePositionName?: string) => {
    if (acceptorsData && acceptorsData.filter((x) => x.inRefCode === inRefCode && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const acceptorBySection = acceptorsData.filter((x) => x.sectionSequence === sectionSequence);

      const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
        acceptorBySection.map((x) => {
          return x.sequence;
        })) : 0;

      const newData: AcceptorsModel = {
        id: undefined,
        sectionSequence: sectionSequence!,
        sectionName: sectionName!,
        userId: empId,
        sequence: maxValueSequence + 1,
        fullName: name,
        approvedDateAt: undefined,
        isAvailable: false,
        availableRemark: '',
        departmentName: department,
        status: AcceptorStatus.DRAFT,
        seqKey: `${sectionSequence?.toString()}${(acceptorsData.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        inRefCode: inRefCode!,
        processType: '',
        sectionId: '',
        sectionLevel: undefined,
        acceptDate: undefined,
        acceptRemark: '',
        positionName: position,
        rejectDate: undefined,
        rejectRemark: '',
        sectionCode: ProcurementSection.APPROVER,
        sectionType: sectionType,
        delegateUserId: delegateUserId,
        delegateFullName: delegateFullName,
        delegateDepartmentName: delegateDepartmentName,
        delegatePositionName: delegatePositionName,
        delegateEmployeeCode: '',
        delegateRangeDate: '',
      };
      setAcceptorsData((acceptorsData) => [...acceptorsData, newData]);
    }
  };

  const onApproved = async () => {
    submitForm();

    const response = await PurchaseOrderApprovalServices.acceptorSignContractAsync(data.contractRecord?.id, remark);

    if (response.status === HttpStatusCode.OK) {
      toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);

      getPurchaseOrderApprovalByIdAsynce();

    }

    setShowApproved(false);
  };

  const isCreator = useMemo(() => {
    if (departmentCode === JorPorCode.CODE) {
      return true;
    }

  }, [data, userId]);

  const isJorporDirectorAssignLv0 = useMemo(() => {
    if (data.contractRecord && data.contractRecord.assignees
      && data.contractRecord.assignees.filter((d) => d.level === AssigneesLevel.Level0 && (d.userId === userId || d.delegateUserId === userId)).length > 0) {

      return true;
    }

    return false;
  }, [data, userId]);

  const isRecall = useMemo(() => {
    return purchaseOrderApproval.contractRecord?.acceptors?.filter(a => a.status === AcceptorStatus.APPROVED).length === 0;
  }, [purchaseOrderApproval, userId])

  const isJorporDirectorAssignLv1 = useMemo(() => {

    if (data.contractRecord && data.contractRecord.assignees && data.contractRecord.assignees.filter((d) => d.level === AssigneesLevel.Level0 && (d.userId === userId || d.delegateUserId === userId)).length > 0
      || data.contractRecord && data.contractRecord.assignees && data.contractRecord.assignees.filter((d) => d.level === AssigneesLevel.Level1 && (d.userId === userId || d.delegateUserId === userId)).length > 0) {

      return true;
    }

    return false;
  }, [data, userId]);

  const handlerRemoveAssigneesDataLevel0 = (index: number) => {
    const temp: AssigneesModel[] = [...assigneesDataLevel0];
    temp.splice(index, 1);
    setAssigneesDataLevel0(temp);
  };

  const handlerRemoveAssigneesDataLevel1 = (index: number) => {
    const temp: AssigneesModel[] = [...assigneesDataLevel1];
    temp.splice(index, 1);
    setAssigneesDataLevel1(temp);
  };

  const onSelectEmpApproverAssigneesDataLevel0 = (empId: string, name: string, position: string, department: string, index: number, level: number) => {
    if (assigneesDataLevel0 && assigneesDataLevel0.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้รับมอบหมายงานซ้ำได้');
    } else if (assigneesDataLevel1 && assigneesDataLevel1.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้รับมอบหมายงานซ้ำกับผู้รับผิดชอบได้');
    } else {
      const newData: AssigneesModel = {
        id: undefined,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        sequence: assigneesDataLevel0.length + 1,
        level: level,
        assignByUserId: '',
      };
      setAssigneesDataLevel0((assigneesDataLevel0) => [...assigneesDataLevel0, newData]);
    }
  };


  const onSelectEmpApproverAssigneesDataLevel1 = (empId: string, name: string, position: string, department: string, index: number, level: number) => {
    if (assigneesDataLevel1 && assigneesDataLevel1.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้รับผิดชอบซ้ำได้');
    } else if (assigneesDataLevel0 && assigneesDataLevel0.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำกับข้อมูล ผอ.จพ. มอบหมายงานไปยังผู้รับผิดชอบได้');
    } else {
      const newData: AssigneesModel = {
        id: undefined,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        sequence: assigneesDataLevel1.length + 1,
        level: level,
        assignByUserId: '',
      };
      setAssigneesDataLevel1((assigneesDataLevel1) => [...assigneesDataLevel1, newData]);
    }
  };

  const onReject = async () => {
    submitForm();

    if (remark === null || remark === '') {
      return toast.error('กรุณากรอกเหตุผลส่งกลับแก้ไข');
    }

    if (data.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Signed
      || data.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Assigned) {
      const response = await PurchaseOrderApprovalServices.assigneeRejectAsync(data.contractRecord?.id, remark);
      if (response.status === HttpStatusCode.OK) {
        toast.success('ส่งกลับแก้ไขสำเร็จ');
      }
    } else {
      const response = await PurchaseOrderApprovalServices.acceptorRejectSignContractAsync(data.contractRecord?.id, remark);
      if (response.status === HttpStatusCode.OK) {
        toast.success('ส่งกลับแก้ไขสำเร็จ');
      }
    }

    getPurchaseOrderApprovalByIdAsynce();
    setShowRejected(false);
  };

  const sumFinancialAmount = useMemo(() => {

    if (data.contractRecord?.financials) {
      let sumAmount = 0;

      data.contractRecord?.financials.forEach((d, i) => {

        const offerTotalAmount = d.entrepreneursList?.reduce((a, v) => a += v.offerTotalAmount ?? 0, 0)
        sumAmount = offerTotalAmount + sumAmount;
      });

      return sumAmount;
    }

    return 0;

  }, [data]);

  // const isSendToApprover = useMemo(() => {

  //   if (data.contractRecord?.financials) {
  //     if (data.contractRecord?.financials
  //       .filter((x) => x.entrepreneursList
  //         .filter((ent) => ent.committees?.length === 0)).length === 0) {

  //       return false;
  //     }

  //     return true
  //   }

  //   return false;

  // }, [data]);

  const getReceivceCommitteeType = useCallback(async (termOfRefId: string) => {
    const { data, status } = await PurchaseOrderApprovalServices.getReceivceCommitteeAsync(termOfRefId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setReceivceCommitteeDDL(data);
  }, []);

  const goToAssignee = (id: string) => {
    navigate(`/pr/pr0206/purchase-order-approval-assignee/${id}`);
  };

  const goToAcceptors = (id: string) => {
    navigate(`/pr/pr0206/acceptors-approval/${id}`);
  };

  const goToCommittee = (id: string) => {
    navigate(`/pr/pr0206/purchase-order-approval-committee/${id}`);
  };

  useEffect(() => {
    if (data) {

      setPerchaseOrderApproval(data);

      if (data.contractRecord?.acceptors && data.contractRecord?.acceptors.length > 0) {
        setAcceptorsData(data.contractRecord.acceptors);

        if (data.contractRecord?.assignees && data.contractRecord?.assignees.filter((x) => x.level === AssigneesLevel.Level0)) {
          setAssigneesDataLevel0(data.contractRecord?.assignees.filter((x) => x.level === AssigneesLevel.Level0));
        }

        if (data.contractRecord?.assignees && data.contractRecord?.assignees.filter((x) => x.level === AssigneesLevel.Level1)) {
          setAssigneesDataLevel1(data.contractRecord?.assignees.filter((x) => x.level === AssigneesLevel.Level1));
        }

        const sectionDataGroup: sectionGroup[] = [];
        data.contractRecord.acceptors.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {
            sectionDataGroup.push({
              sectionName: item.sectionName,
              level: item.sectionLevel,
              sectionSequence: item.sectionSequence,
              inRefCode: item.inRefCode,
              sectionNameTh: item.sectionName,
              sectionId: item.sectionId,
            } as sectionGroup);
          });

        const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);

        setSections(uniqueNames);
        const isApprover = onCheckApprover(data.contractRecord.acceptors);
        setIsApprover(isApprover);
      }

      if (data.contractRecord?.termOfRefId) {
        getReceivceCommitteeType(data.contractRecord.termOfRefId);
      }

      if ((!data.contractRecord?.status || (data.contractRecord?.status === PurchaseOrderApprovalStatusConstant.None || data.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Draft || data.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Rejected))) {
        setisDisabled(false);
      } else {
        setisDisabled(true);
      }

      if (!data.contractRecord?.jorPor06Id) {
        setisDisabledPlan(false);
        // setContractType(ContractType.Contract);
      } else {
        setisDisabledPlan(true);
      }

      if (data.contractRecord?.financials && data.contractRecord?.financials.filter((f) => f.entrepreneursList.filter((e) => e.status === PurchaseOrderApprovalStatusConstant.Signed || e.status === PurchaseOrderApprovalStatusConstant.WaitingForSign || e.status === PurchaseOrderApprovalStatusConstant.Assigned).length > 0).length > 0) {
        setisDisabledContractType(true);
      } else {
        setisDisabledContractType(false);
      }

      if (data.contractRecord?.supplyMethod) {
        setSupplyMethod(data.contractRecord.supplyMethod);
      }

      if (data.contractRecord?.supplyMethodType) {
        setSupplyMethodType(data.contractRecord.supplyMethodType);
      }

      if (data.contractRecord?.supplyMethodSpecialType) {
        setSupplyMethodSpecialType(data.contractRecord.supplyMethodSpecialType);
      }

      if (data.contractRecord?.contractType) {
        setContractType(data.contractRecord.contractType);
      }

      if (data.contractRecord?.planName) {
        setPlanName(data.contractRecord?.planName);
      }

      if (data.contractRecord?.planNumber) {
        setPlanNumber(data.contractRecord?.planNumber);
      }

      if (data.contractRecord?.budgetYear) {
        setBudgetYear(data.contractRecord?.budgetYear);
      }

      if (data.contractRecord?.financialAmount) {
        setFinancialAmount(data.contractRecord?.financialAmount);
      }

      if (data.contractRecord?.departmentId) {
        setDepartmentId(data.contractRecord?.departmentId);
      }

      if (data.contractRecord?.attachmentFiles) {
        handleSetFile(data.contractRecord.attachmentFiles);
      }
    }

  }, [data, userId]);

  const getSupplyMethodSpecialType = useCallback(async (supplyMethodId: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(supplyMethodId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setSupplyMethodSpecialDDL(data);
  }, [supplyMethod]);

  useEffect(() => {
    getSupplyMethodSpecialType(supplyMethod);
  }, [supplyMethod]);

  const handlerAssigneesLevel1 = () => {
    if ((data.contractRecord?.assignees
      && data.contractRecord?.assignees.length === 0) ||
      assigneesDataLevel1
      && assigneesDataLevel1.length === 0) {

      toast.warn('กรุณาเลือกผู้รับผิดชอบ');
      return;
    }

    setShowSaveAssignees(true)
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && purchaseOrderApproval.contractRecord.id) {
      const res = await PurchaseOrderApprovalServices.uploadPurchaseOrderApprovalAttachmentAsync(
        purchaseOrderApproval.contractRecord.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getPurchaseOrderApprovalByIdAsynce();

      }
    }
  };

  const handleSetFile = (documents: documentsModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const onSelectTrader = (inviteEntrepreneursId: string, establishmentName: string, taxpayerIdentificationNo: string, index: number, financialId: string, financialIndex: number, confirmPriceTotal: number) => {

    if (purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList && purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList?.filter((x) => x.entrepreneursTaxId === taxpayerIdentificationNo).length > 0) {
      toast.warn('ไม่สามารถเพิ่มคู่ค้าซ้ำได้');
    } else {

      const newData: entrepreneursListModel[] = purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList ? [...purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList] : [];

      newData.push({
        id: undefined,
        sequence: newData.length + 1,
        contractRecordFinancialId: '',
        inviteEntrepreneursId: inviteEntrepreneursId,
        entrepreneursTaxId: taxpayerIdentificationNo,
        entrepreneursName: establishmentName,
        offerTotalAmount: confirmPriceTotal,
        poNumber: undefined,
        contractNumber: '',
        status: undefined,
      } as entrepreneursListModel);

      const financialsData: financialsModel[] = purchaseOrderApproval.contractRecord?.financials ? [...purchaseOrderApproval.contractRecord?.financials] : [];

      financialsData.forEach((x) => {
        if (x.financialId === financialId) {
          x.entrepreneursList = newData;
        }
      });

      setPerchaseOrderApproval(pre => {

        const newObj = { ...pre.contractRecord, financials: financialsData };
        if (!newObj) {
          return pre;
        }

        const newObj2 = { ...pre, contractRecord: newObj } as PurchaseOrderApprovalModel;
        if (!newObj2) {
          return pre;
        }

        return newObj2;
      });

    }
  };

  const handlerRemoveTrader = (index: number, financialIndex: number, financialId: string, id: string) => {

    const temp: entrepreneursListModel[] = purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList ? [...purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList] : [];

    temp.splice(index, 1);

    const financialsData: financialsModel[] = purchaseOrderApproval.contractRecord?.financials ? [...purchaseOrderApproval.contractRecord?.financials] : [];

    if (data.contractRecord?.jorPor06Id) {
      financialsData.forEach((x) => {
        if (x.financialId === financialId) {
          x.entrepreneursList = temp;
        }
      });
    } else {
      financialsData.forEach((x) => {
        if (x.id === id) {
          x.entrepreneursList = temp;
        }
      });
    }

    setPerchaseOrderApproval(pre => {

      const newObj = { ...pre.contractRecord, financials: financialsData };
      if (!newObj) {
        return pre;
      }

      const newObj2 = { ...pre, contractRecord: newObj } as PurchaseOrderApprovalModel;
      if (!newObj2) {
        return pre;
      }

      return newObj2;
    });
  };


  const handlerRemoveEntrepreneur = (entrepreneursIndex: number, financialIndex: number, id: string) => {
    const temp: entrepreneursListModel[] = purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList ? [...purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList] : [];

    temp.splice(entrepreneursIndex, 1);

    const financialsData: financialsModel[] = purchaseOrderApproval.contractRecord?.financials ? [...purchaseOrderApproval.contractRecord?.financials] : [];

    financialsData.forEach((x) => {
      if (x.id === id) {
        x.entrepreneursList = temp;
      }
    });

    setPerchaseOrderApproval(pre => {

      const newObj = { ...pre.contractRecord, financials: financialsData };
      if (!newObj) {
        return pre;
      }

      const newObj2 = { ...pre, contractRecord: newObj } as PurchaseOrderApprovalModel;
      if (!newObj2) {
        return pre;
      }

      return newObj2;
    });
  };

  const handlerOnChangePoNumber = (value: string, seqKey: string, index: number, financialIndex: number, financialId: string, id: string) => {

    const newData: entrepreneursListModel[] = purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList ? [...purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList] : [];

    newData.filter((x) => `${x.sequence}` === seqKey)[0].poNumber = value;
    const financialsData: financialsModel[] = purchaseOrderApproval.contractRecord?.financials ? [...purchaseOrderApproval.contractRecord?.financials] : [];

    if (data.contractRecord?.jorPor06Id) {

      financialsData.forEach((x) => {
        if (x.financialId === financialId) {
          x.entrepreneursList = newData;
        }
      });
    } else {

      financialsData.forEach((x) => {
        if (x.id === id) {
          x.entrepreneursList = newData;
        }
      });
    }

    setPerchaseOrderApproval(pre => {

      const newObj = { ...pre.contractRecord, financials: financialsData };
      if (!newObj) {
        return pre;
      }

      const newObj2 = { ...pre, contractRecord: newObj } as PurchaseOrderApprovalModel;
      if (!newObj2) {
        return pre;
      }

      return newObj2;
    });

  };

  const handlerOnChangeContractNumber = (value: string, seqKey: string, index: number, financialIndex: number, financialId: string, id: string) => {

    const newData: entrepreneursListModel[] = purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList ? [...purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList] : [];

    newData.filter((x) => `${x.sequence}` === seqKey)[0].contractNumber = value;
    const financialsData: financialsModel[] = purchaseOrderApproval.contractRecord?.financials ? [...purchaseOrderApproval.contractRecord?.financials] : [];

    if (data.contractRecord?.jorPor06Id) {

      financialsData.forEach((x) => {
        if (x.financialId === financialId) {
          x.entrepreneursList = newData;
        }
      });
    } else {

      financialsData.forEach((x) => {
        if (x.id === id) {
          x.entrepreneursList = newData;
        }
      });
    }

    setPerchaseOrderApproval(pre => {

      const newObj = { ...pre.contractRecord, financials: financialsData };
      if (!newObj) {
        return pre;
      }

      const newObj2 = { ...pre, contractRecord: newObj } as PurchaseOrderApprovalModel;
      if (!newObj2) {
        return pre;
      }

      return newObj2;
    });

  };

  const handlerOnChangeReceivceCommittee = (value: string, seqKey: string, index: number, financialIndex: number, financialId: string, id: string) => {

    const newData: entrepreneursListModel[] = purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList ? [...purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList] : [];

    newData.filter((x) => `${x.sequence}` === seqKey)[0].receiveCommitteeId = value;
    const financialsData: financialsModel[] = purchaseOrderApproval.contractRecord?.financials ? [...purchaseOrderApproval.contractRecord?.financials] : [];

    if (data.contractRecord?.jorPor06Id) {

      financialsData.forEach((x) => {
        if (x.financialId === financialId) {
          x.entrepreneursList = newData;
        }
      });
    } else {

      financialsData.forEach((x) => {
        if (x.id === id) {
          x.entrepreneursList = newData;
        }
      });
    }

    setPerchaseOrderApproval(pre => {

      const newObj = { ...pre.contractRecord, financials: financialsData };
      if (!newObj) {
        return pre;
      }

      const newObj2 = { ...pre, contractRecord: newObj } as PurchaseOrderApprovalModel;
      if (!newObj2) {
        return pre;
      }

      return newObj2;
    });

  };

  const handlerOnChangeIsMA = (value: boolean, seqKey: string, index: number, financialIndex: number, financialId: string, id: string) => {

    const newData: entrepreneursListModel[] = purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList ? [...purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList] : [];

    newData.filter((x) => `${x.sequence}` === seqKey)[0].isMA = value;
    const financialsData: financialsModel[] = purchaseOrderApproval.contractRecord?.financials ? [...purchaseOrderApproval.contractRecord?.financials] : [];

    if (data.contractRecord?.jorPor06Id) {

      financialsData.forEach((x) => {
        if (x.financialId === financialId) {
          x.entrepreneursList = newData;
        }
      });
    } else {

      financialsData.forEach((x) => {
        if (x.id === id) {
          x.entrepreneursList = newData;
        }
      });
    }

    setPerchaseOrderApproval(pre => {

      const newObj = { ...pre.contractRecord, financials: financialsData };
      if (!newObj) {
        return pre;
      }

      const newObj2 = { ...pre, contractRecord: newObj } as PurchaseOrderApprovalModel;
      if (!newObj2) {
        return pre;
      }

      return newObj2;
    });

  };

  const handlerOnChangeOfferTotalAmount = (value: number, seqKey: string, index: number, financialIndex: number, financialId: string, id: string) => {

    const newData: entrepreneursListModel[] = purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList ? [...purchaseOrderApproval.contractRecord?.financials[financialIndex].entrepreneursList] : [];

    newData.filter((x) => `${x.sequence}` === seqKey)[0].offerTotalAmount = value;

    const financialsData: financialsModel[] = purchaseOrderApproval.contractRecord?.financials ? [...purchaseOrderApproval.contractRecord?.financials] : [];

    if (data.contractRecord?.jorPor06Id) {
      financialsData.forEach((x) => {
        if (x.financialId === financialId) {
          x.entrepreneursList = newData;
        }
      });
    } else {
      financialsData.forEach((x) => {
        if (x.id === id) {
          x.entrepreneursList = newData;
        }
      });
    }

    setPerchaseOrderApproval(pre => {
      const newObj = { ...pre.contractRecord, financials: financialsData };
      if (!newObj) {
        return pre;
      }

      const newObj2 = { ...pre, contractRecord: newObj } as PurchaseOrderApprovalModel;
      if (!newObj2) {
        return pre;
      }

      return newObj2;
    });

  };

  const onUpdatrPOAsync = async (financialIndex: number, entrepreneursIndex: number, id?: string) => {
    submitForm();

    if (!purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].offerTotalAmount) {
      toast.warn('กรุณาระบุราคาที่ตกลง');
      return;
    }

    if (!purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].poNumber) {
      toast.warn('กรุณาระบุข้อมูลเลขที่ PO');
      return;
    }

    if (contractType === ContractType.Contract && (!purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].contractNumber)) {
      toast.warn('กรุณาระบุเลขที่ใบสั่ง/สัญญา จพ.(สบส.)');
      return;
    }

    if (contractType === ContractType.Contract && !purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].receiveCommitteeId) {
      toast.warn('กรุณาระบุคณะกรรมการ');
      return;
    }

    if (data && purchaseOrderApproval.contractRecord?.id) {
      const updateDataModel: EntrepreneursPoRequestModel = {
        contractRecordFinancialId: purchaseOrderApproval.contractRecord.financials[financialIndex].id!,
        id: id,
        inviteEntrepreneursId: purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].inviteEntrepreneursId!,
        sequence: purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].sequence,
        entrepreneursTaxId: purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].entrepreneursTaxId!,
        entrepreneursName: purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].entrepreneursName,
        offerTotalAmount: purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].offerTotalAmount!,
        poNumber: purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].poNumber!,
        contractNumber: purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].contractNumber!,
        contractType: contractType,
        receiveCommitteeId: purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].receiveCommitteeId!,
        isMA: purchaseOrderApproval.contractRecord.financials[financialIndex].entrepreneursList[entrepreneursIndex].isMA,
      };

      const res = await PurchaseOrderApprovalServices.updateEntrepreneursPoAsync(updateDataModel);

      if (res.status === HttpStatusCode.OK) {
        toast.success('บันทึกมูลสำเร็จ');
        getPurchaseOrderApprovalByIdAsynce();

      } else {
        toast.error(res.statusText);
      }

    } else {
      onSubmitAsync(false);
    }

    setShowCreatePO(false);
    setShowUpdatePO(false);
  };

  const onDeleteFinancialAsync = async (financialId: string, index: number) => {
    if (data.contractRecord?.id, financialId) {

      const res = await PurchaseOrderApprovalServices.deleteFinancialAsync(data.contractRecord?.id!, financialId);

      if (res.status === HttpStatusCode.OK) {

        toast.success('ลบข้อมูลสำเร็จ');
        getPurchaseOrderApprovalByIdAsynce();
      } else {

        toast.error(res.statusText);
      }

      onHideConfirmDeleteModal(index);
    }
  };

  const checkPONumberDuplicate = async () => {
    const uniquePONumber = new Set(
      purchaseOrderApproval.contractRecord?.financials
        .flatMap(f =>
          f.entrepreneursList
            .filter(e => e.poNumber)
            .map(e => e.poNumber)));

    const allPONumber = purchaseOrderApproval.contractRecord?.financials
      .flatMap(f =>
        f.entrepreneursList
          .filter(e => e.poNumber)
          .map(e => e.poNumber));

    const isDuplicate = uniquePONumber.size !== allPONumber.length;

    return isDuplicate;
  }

  const onSubmitAsync = async (sendToApprove: boolean) => {
    submitForm();

    if (!planNumber) {
      toast.warn('กรุณาระบุแผนการจัดซื้อจัดจ้าง');
      return;
    }

    if (!contractType) {
      toast.warn('กรุณาระบุประเภทอนุมัติใบสั่งซื้อ/จ้าง/เช่า');
      return;
    }

    const docUpload: File[] = [];

    if (files && files.length > 0) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });
      purchaseOrderApproval.contractRecord.docNameforDelete = delFiles;
      purchaseOrderApproval.contractRecord.files = [...docUpload];
    }

    if (data && purchaseOrderApproval.contractRecord?.id) {

      // if (await checkPONumberDuplicate()) {
      //   toast.warn('เลข PO ซ้ำ');
      //   return;
      // }

      if (purchaseOrderApproval.contractRecord?.financials && purchaseOrderApproval.contractRecord?.financials.filter((x) => x.entrepreneursList.length === 0).length > 0) {
        toast.warn('กรุณาระบุผู้ชนะโครงการ');
        return;
      }

      if (purchaseOrderApproval.contractRecord?.financials && purchaseOrderApproval.contractRecord?.financials.filter((x) => x.entrepreneursList.filter((ent) => ent.offerTotalAmount === undefined || ent.offerTotalAmount === null).length > 0).length > 0) {
        toast.warn('กรุณาระบุราคาที่ตกลง');
        return;
      }

      if (purchaseOrderApproval.contractRecord?.financials && purchaseOrderApproval.contractRecord?.financials.filter((x) => x.entrepreneursList.filter((ent) => ent.poNumber === '' || ent.poNumber === undefined || ent.poNumber === null).length > 0).length > 0) {
        toast.warn('กรุณาระบุเลขที่ PO');
        return;
      }

      if (contractType === ContractType.Contract && purchaseOrderApproval.contractRecord?.financials && purchaseOrderApproval.contractRecord?.financials.filter((x) => x.entrepreneursList.filter((ent) => ent.contractNumber === '' || ent.contractNumber === undefined || ent.contractNumber === null).length > 0).length > 0) {
        toast.warn('กรุณาระบุเลขที่ใบสั่ง/สัญญา จพ.(สบส.)');
        return;
      }

      if (contractType === ContractType.Contract && purchaseOrderApproval.contractRecord?.financials && purchaseOrderApproval.contractRecord?.financials.filter((x) => x.entrepreneursList.filter((ent) => ent.receiveCommitteeId === '' || ent.receiveCommitteeId === undefined || ent.receiveCommitteeId === null).length > 0).length > 0) {
        toast.warn('กรุณาระบุประเภทคณะกรรมการ');
        return;
      }

      let isReturn = false;

      if (purchaseOrderApproval.contractRecord?.financials) {
        purchaseOrderApproval.contractRecord?.financials.forEach((financials, i) => {
          const offerTotalAmount = financials.entrepreneursList?.reduce((a, v) => a += v.offerTotalAmount ?? 0, 0)

          if (offerTotalAmount > financials.amount) {
            isReturn = true;
          }
        });

        if (isReturn) {
          toast.warn('ราคาที่ตกลงรวมกันต้องไม่เกินวงเงินที่จัดซื้อจัดจ้างของแต่ละวงเงิน');
          return;
        }
      }

      const assigneesDataList: AssigneesModel[] = [];

      assigneesDataLevel0?.forEach((item, index) => {
        assigneesDataList.push({
          id: item.id,
          departmentName: item.departmentName,
          fullName: item.fullName,
          level: item.level,
          positionName: item.positionName,
          sequence: item.sequence,
          userId: item.userId,
          assignByUserId: item.assignByUserId,
        } as AssigneesModel);
      });

      const updateDataModel: PurchaseOrderApprovalRequestModel = {
        id: purchaseOrderApproval.contractRecord.id,
        jorPor06Id: purchaseOrderApproval.contractRecord.jorPor06Id,
        financialAmount: financialAmount,
        departmentId: departmentId,
        supplyMethod: supplyMethod,
        supplyMethodType: supplyMethodType,
        supplyMethodSpecialType: supplyMethodSpecialType,
        planNumber: planNumber,
        planName: planName,
        financials: purchaseOrderApproval.contractRecord.financials!,
        files: docUpload,
        budgetYear: budgetYear,
        contractType: contractType,
        sendToApprove: sendToApprove,
        acceptors: acceptorsData,
        assignees: assigneesDataList,
        basicInformationId: purchaseOrderApproval.contractRecord?.basicInformationId
      };

      const res = await PurchaseOrderApprovalServices.updatePurchaseOrderApprovalAsync(updateDataModel);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {

        if (sendToApprove) {

          toast.success('ส่งเห็นชอบ/อนุมัติสำเร็จ');
        } else {

          toast.success('แก้ไขข้อมูลสำเร็จ');
        }

        getPurchaseOrderApprovalByIdAsynce();

      } else {
        toast.error(res.statusText);
      }
    } else {

      const createDataModel: PurchaseOrderApprovalRequestModel = {
        id: '',
        jorPor06Id: purchaseOrderApproval.contractRecord?.jorPor06Id!,
        financialAmount: financialAmount!,
        departmentId: departmentId!,
        supplyMethod: supplyMethod!,
        supplyMethodType: supplyMethodType!,
        supplyMethodSpecialType: supplyMethodSpecialType!,
        planNumber: planNumber!,
        planName: planName!,
        financials: purchaseOrderApproval.contractRecord?.financials,
        files: docUpload,
        budgetYear: budgetYear,
        contractType: contractType,
        basicInformationId: purchaseOrderApproval.contractRecord?.basicInformationId
      };

      const res = await PurchaseOrderApprovalServices.createPurchaseOrderApprovalAsync(createDataModel);
      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('บันทึกข้อมูลสำเร็จ');
        const newId: string = res.data.newContractRecordId;

        if (data.contractRecord?.jorPor06Id) {
          navigate(`/pr/pr0206/detail/${purchaseOrderApproval.contractRecord?.jorPor06Id}/id/${newId}`);
        } else if (data.contractRecord?.basicInformationId) {
          navigate(`/pr/pr0206/detail/basicInformation/${purchaseOrderApproval.contractRecord?.basicInformationId}/id/${newId}`);
        } else {
          navigate(`/pr/pr0206/detail/id/${newId}`);
        }
        getPurchaseOrderApprovalByIdAsynce();
        setShowCreatePO(false);
      } else {
        toast.error(res.statusText);
      }
    }

    setShowPending(false);
  };

  const reCallAsync = async () => {
    if (data && data.contractRecord.id) {
      const response = await PurchaseOrderApprovalServices.onReCallAsync(data.contractRecord.id);

      if (response.status === HttpStatusCode.OK) {
        toast.success('เรียกคืนแก้ไขสำเร็จ');
        getPurchaseOrderApprovalByIdAsynce();
      } else {
        toast.error("เรียกคืนแก้ไขไม่สำเร็จ");
      }
    }

    setShowReCall(false);
  };

  const onSubmitAssigneeAsync = async (isSendToAssignees: boolean) => {
    submitForm();

    const assigneesDataList: AssigneesModel[] = [];

    assigneesDataLevel0?.forEach((item, index) => {
      assigneesDataList.push({
        id: item.id,
        departmentName: item.departmentName,
        fullName: item.fullName,
        level: item.level,
        positionName: item.positionName,
        sequence: item.sequence,
        userId: item.userId,
        assignByUserId: item.assignByUserId,
      } as AssigneesModel);

    });

    assigneesDataLevel1?.forEach((item, index) => {
      assigneesDataList.push({
        id: item.id,
        departmentName: item.departmentName,
        fullName: item.fullName,
        level: item.level,
        positionName: item.positionName,
        sequence: item.sequence,
        userId: item.userId,
        assignByUserId: item.assignByUserId,
      } as AssigneesModel);
    });

    if (data && data.contractRecord?.id && assigneesDataList.length > 0) {
      const updateDataModel: ContractRecordAssigneeRequestModel = {
        isSendAssign: isSendToAssignees,
        assignees: assigneesDataList,
      };

      const res = await PurchaseOrderApprovalServices.updateAssigneeAsync(data.contractRecord?.id, updateDataModel);

      if (res.status === HttpStatusCode.OK) {
        if (isSendToAssignees) {
          toast.success('ส่งมอบหมายสำเร็จ');

        } else {
          toast.success('บันทึกข้อมูลสำเร็จ');
        }

        getPurchaseOrderApprovalByIdAsynce();
      } else {
        toast.error(res.statusText);
      }

      setShowSaveAssignees(false);
    }

    setShowSaveAssignees(false);
  };

  const getPurchaseOrderApprovalByIdAsynce = async () => {
    if (purchaseOrderApproval.contractRecord?.jorPor06Id) {

      const response = await PurchaseOrderApprovalServices.getPurchaseOrderApprovalByIdAsync(purchaseOrderApproval.contractRecord?.jorPor06Id, undefined, purchaseOrderApproval.contractRecord?.id);
      if (response.status === HttpStatusCode.OK) {
        setData(response.data);
        navigate(`/pr/pr0206/detail/${purchaseOrderApproval.contractRecord?.jorPor06Id}/id/${purchaseOrderApproval.contractRecord.id}`);
      }

    } else if (purchaseOrderApproval.contractRecord?.basicInformationId) {

      const response = await PurchaseOrderApprovalServices.getPurchaseOrderApprovalByIdAsync(undefined, purchaseOrderApproval.contractRecord?.basicInformationId, purchaseOrderApproval.contractRecord?.id);
      if (response.status === HttpStatusCode.OK) {
        setData(response.data);
        navigate(`/pr/pr0206/detail/basicInformation/${purchaseOrderApproval.contractRecord?.basicInformationId}/id/${purchaseOrderApproval.contractRecord.id}`);
      }

    } else {
      const response = await PurchaseOrderApprovalServices.getContractRecordByIdAsync(purchaseOrderApproval.contractRecord?.id);
      if (response.status === HttpStatusCode.OK) {
        setData(response.data);
        navigate(`/pr/pr0206/detail/id/${purchaseOrderApproval.contractRecord.id}`);
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {

    if (data && purchaseOrderApproval.contractRecord.id) {
      const res = await PurchaseOrderApprovalServices.removeFileAsync(
        purchaseOrderApproval.contractRecord.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getPurchaseOrderApprovalByIdAsynce();

      }
    }

  }, [files, purchaseOrderApproval.contractRecord?.id]);

  const getPurchaseOrderApprovalById = async (jorPor06Id: string, id: string) => {
    const response = await PurchaseOrderApprovalServices.getPurchaseOrderApprovalByIdAsync(jorPor06Id, id!);
    if (response.status === HttpStatusCode.OK) {
      setData({ ...data, contractRecord: response.data });
    }
  };

  const onOpenModal = (index: number) => {
    setShowTrader((showTrader) => {
      const newModal = [...showTrader];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideModal = (index: number) => {
    setShowTrader((showTrader) => {
      const newModal = [...showTrader];
      newModal[index] = false;
      return newModal;
    });
  };

  const onOpenEntrepreneurModal = (index: number) => {
    setShowEntrepreneur((showEntrepreneur) => {
      const newModal = [...showEntrepreneur];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideEntrepreneurModal = (index: number) => {
    setShowEntrepreneur((showEntrepreneur) => {
      const newModal = [...showEntrepreneur];
      newModal[index] = false;
      return newModal;
    });
  };

  const onOpenEditFinancialModal = (index: number) => {
    setEditShowContractFinancial((showEditContractFinancial) => {
      const newModal = [...showEditContractFinancial];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideEditFinancialModal = (index: number) => {
    setEditShowContractFinancial((showEditContractFinancial) => {
      const newModal = [...showEditContractFinancial];
      newModal[index] = false;
      return newModal;
    });
  };

  const onConfirmUpdatePO = async (financialIndex: number, index: number, entrepreneursId?: string) => {
    // if (await checkPONumberDuplicate()) {
    //   toast.warn('เลข PO ซ้ำ');
    //   return;
    // }
    setFinancialIndex(financialIndex);
    setEntrepreneursIndex(index);
    setEntrepreneursId(entrepreneursId);
    setShowUpdatePO(true)
  };

  const onHideConfirmDeleteModal = (index: number) => {
    setShowConfirmDelete((showConfirmDelete) => {
      const newModal = [...showConfirmDelete];
      newModal[index] = false;
      return newModal;
    });
  };

  const onOpenConfirmDeleteModal = (index: number) => {
    setShowConfirmDelete((showConfirmDelete) => {
      const newModal = [...showConfirmDelete];
      newModal[index] = true;
      return newModal;
    });
  };

  const onSelectEntrepreneur = (id: string, entrepreneurType: string, type: string, taxpayerIdentificationNo: string, establishmentName: string, email: string, financialId: string, index: number) => {

    if (purchaseOrderApproval.contractRecord?.financials[index].entrepreneursList && purchaseOrderApproval.contractRecord?.financials[index].entrepreneursList?.filter((x) => x.entrepreneursTaxId === taxpayerIdentificationNo).length > 0) {

      toast.warn('ไม่สามารถเพิ่มคู่ค้าซ้ำได้');
    } else {

      const newData: entrepreneursListModel[] = purchaseOrderApproval.contractRecord?.financials[index].entrepreneursList ? [...purchaseOrderApproval.contractRecord?.financials[index].entrepreneursList] : [];

      const maxValueSequence = newData.length > 0 ? Math.max.apply(null,
        newData.map((x) => {
          return x.sequence;
        })) : 0;

      newData.push({
        id: undefined,
        sequence: maxValueSequence + 1,
        contractRecordFinancialId: '',
        inviteEntrepreneursId: undefined,
        entrepreneursTaxId: taxpayerIdentificationNo,
        entrepreneursName: establishmentName,
        offerTotalAmount: undefined,
        poNumber: undefined,
        contractNumber: '',
        status: undefined,
      } as entrepreneursListModel);

      const financialsData: financialsModel[] = purchaseOrderApproval.contractRecord?.financials ? [...purchaseOrderApproval.contractRecord?.financials] : [];

      financialsData.forEach((x) => {
        if (x.id === financialId) {
          x.entrepreneursList = newData;
        }
      });

      setPerchaseOrderApproval(pre => {

        const newObj = { ...pre.contractRecord, financials: financialsData };
        if (!newObj) {
          return pre;
        }

        const newObj2 = { ...pre, contractRecord: newObj } as PurchaseOrderApprovalModel;
        if (!newObj2) {
          return pre;
        }

        return newObj2;
      });

    }
  };

  const onSelectPlan = async (planId: string, balanceBudget: number) => {

    if (planId) {

      const response = await planProcurement.getDetailAsync(planId);

      if (response.status === HttpStatusCode.OK) {
        setPlanNumber(response.data.planProcurement.planNumber);
        setSupplyMethod(response.data.planProcurement.supplyMethod);
        setSupplyMethodType(response.data.planProcurement.supplyMethodType);
        setSupplyMethodSpecialType(response.data.planProcurement.supplyMethodSpecialType);
        setFinancialAmount(response.data.planProcurement.budget);
        setDepartmentId(response.data.planProcurement.departmentId);
        setBudgetYear(response.data.planProcurement.budgetYear);
        getSupplyMethodSpecialType(response.data.planProcurement.supplyMethod);
        setPlanName(response.data.planProcurement.name);
      }
    }

    setShowPlan(false);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title
          text='ข้อมูลอ้างอิง'
          className='fs-5 text-primary'
          extraElement={
            <div className='d-flex gap-2'>
              <PurchaseOrderApprovalStatus value={purchaseOrderApproval.contractRecord?.status} />
              <Button
                onClick={() => setShowHistory(!showHistory)}
                variant='outline-primary'>
                <FaHistory className='me-1' />
                ประวัติการใช้งาน
              </Button>
            </div>
          }
        />
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={8}>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}
                lg={4}>
                <Input
                  label='เลขที่รายงานขอซื้อขอจ้าง (จพ.005)'
                  placeholder='เลขที่รายงานขอซื้อขอจ้าง (จพ.005)'
                  value={purchaseOrderApproval.contractRecord?.jorPor05Number}
                  disabled={true}
                  variant='primary'
                />
              </Col>
            </Row>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}
                lg={4}>
                <Input
                  label='อ้างอิงแผนการจัดซื้อจัดจ้าง'
                  placeholder='อ้างอิงแผนการจัดซื้อจัดจ้าง'
                  value={planNumber}
                  onClick={() => setShowPlan(!showPlan)}
                  disabled
                  textSearch={!data.contractRecord || (data.contractRecord?.status === PurchaseOrderApprovalStatusConstant.None
                    || data.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Draft
                    || data.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Rejected)}
                  variant='primary'
                />
              </Col>
              <Col sm={12}
                lg={4}>
                <p className='mb-0 mt-3'><span className='text-danger me-1'>*</span>เลขที่อ้างอิงเพื่อออก PO</p>
              </Col>
            </Row>
            <Row>
              <Col sm={12}
                lg={4}>
                <Selector
                  label='ปีงบประมาณ'
                  placeholder='กรุณาเลือก'
                  items={budgetYearDDL}
                  disabled
                  value={budgetYear?.toString()}
                  onChange={(val) => setBudgetYear(Number(val))}
                  name='budgetYear'
                />
              </Col>
              <Col sm={12}
                lg={4}>
                <Currency
                  label='วงเงิน (บาท)'
                  placeholder='วงเงิน (บาท)'
                  value={financialAmount}
                  onChange={(val) => setFinancialAmount(Number(val))}
                  disabled
                />
              </Col>
              <Col sm={12}
                lg={4}>
                <Selector
                  label='ฝ่าย/สำนัก'
                  items={departmentDDL}
                  value={departmentId}
                  onChange={(val) => setDepartmentId(val)}
                  disabled
                  placeholder='กรุณาเลือก'
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={8}>
            <Row>
              <Col sm={12}
                lg={4}>
                <Selector
                  label='วิธีการจัดหา'
                  placeholder='กรุณาเลือก'
                  items={supplyMethodDDL}
                  value={supplyMethod}
                  onChange={(val) => setSupplyMethod(val)}
                  disabled
                />
              </Col>
              <Col sm={12}
                lg={4}>
                <Selector
                  className='no-label'
                  placeholder='กรุณาเลือก'
                  items={supplyMethodTypeDDL}
                  value={supplyMethodType}
                  onChange={(val) => setSupplyMethodType(val)}
                  disabled
                />
              </Col>
              <Col sm={12}
                lg={4}>
                <Selector
                  className='no-label'
                  placeholder='กรุณาเลือก'
                  items={supplyMethodSpecialDDL}
                  value={supplyMethodSpecialType}
                  onChange={(val) => setSupplyMethodSpecialType(val)}
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={8}>
            <InputTextArea label='ชื่อโครงการ'
              value={planName}
              onChange={(val) => setPlanName(val)}
              placeholder='ชื่อโครงการ'
              disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={8}>
            <Row>
              <Col sm={12}
                lg={4}>
                <Selector
                  label='ประเภทอนุมัติใบสั่งซื้อ/จ้าง/เช่า'
                  placeholder='กรุณาเลือก'
                  items={contractTypeDDL}
                  rule={{ required: true }}
                  value={contractType}
                  onChange={(val) => setContractType(val)}
                  disabled={isDisabled}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      {(data.contractRecord?.jorPor06Id || data.contractRecord?.id) && (
        <>
          <Card className='mt-3'>
            <>
              <div className='d-flex align-items-center justify-content-between mt-1'>
                <Title text='วงเงินที่จัดซื้อจัดจ้าง'
                  className='fs-5 text-primary' />
                {(!data.contractRecord?.jorPor06Id && data.contractRecord?.id) && (
                  <Button
                    className='me-2 px-3'
                    variant='outline-primary'
                    onClick={() => setShowContractFinancial(true)}
                    disabled={isDisabled}
                  >
                    <FaPlus className='me-1' /> เพิ่มวงเงิน
                  </Button>
                )}
              </div>
              {purchaseOrderApproval.contractRecord?.financials?.map((financial, financialIndex) => (
                <Card className='mt-3 shadow' key={financialIndex}>
                  <div className='d-flex justify-content-between pt-3'>
                    <div>
                      วงเงินที่ {financialIndex + 1} : {THCurrency(financial.amount)} บาท {financial.detail}
                    </div>
                    {
                      (!data.contractRecord?.jorPor06Id && !isDisabled) && (
                        <>
                          <div style={{ width: 145 }} />
                          <div className='d-flex justify-content-between'>
                            <Button
                              variant=''
                              onClick={() => onOpenEditFinancialModal(financialIndex)}
                              disabled={isDisabled}>
                              <span style={{ color: '#FF850D' }}>แก้ไขรายการ</span>
                            </Button>
                            <Button
                              variant=''
                              onClick={() => onOpenConfirmDeleteModal(financialIndex)}
                              disabled={isDisabled}>
                              <FaTrash className="me-1 fs-5" color='#dc3545' />
                            </Button>
                          </div>
                        </>
                      )
                    }
                  </div>
                  <div className='d-flex align-items-center justify-content-between mt-3'>
                    <p className='mb-0'>ผู้ชนะโครงการ</p>
                    {(data.contractRecord?.jorPor06Id)
                      ? <Button
                        variant='outline-primary'
                        disabled={purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Assigned || isDisabled}
                        onClick={() => onOpenModal(financialIndex)}
                      >
                        <FaPlus className='me-1' />เพิ่มผู้ชนะโครงการ
                      </Button>
                      : <Button
                        variant='outline-primary'
                        disabled={purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Assigned || isDisabled}
                        onClick={() => onOpenEntrepreneurModal(financialIndex)}
                      >
                        <FaPlus className='me-1' />เพิ่มคู่ค้า
                      </Button>
                    }
                  </div>
                  <EntrepreneursWinneModal
                    show={showTrader[financialIndex]}
                    onHide={() => onHideModal(financialIndex)}
                    onSelectItem={onSelectTrader}
                    jorpor06id={purchaseOrderApproval.contractRecord?.jorPor06Id!}
                    financialId={financial.financialId}
                    financialIndex={financialIndex}
                  />
                  <Row className='mt-3 align-items-center'>
                    <Col sm={12}>
                      <Table total={1}
                        hidePagination>
                        <thead>
                          <tr className='text-center'>
                            <th style={{ minWidth: 20 }}>ลำดับ</th>
                            <th style={{ minWidth: 100 }}>เลขที่ผู้เสียภาษี</th>
                            <th style={{ minWidth: 200 }}>ชื่อ</th>
                            <th style={{ minWidth: 200 }}>ราคาที่ตกลง <span className="text-danger">*</span></th>
                            <th style={{ minWidth: 200 }}>เลขที่ PO <span className="text-danger">*</span></th>
                            {
                              (contractType === ContractType.Contract) && (
                                <>
                                  <th style={{ minWidth: 200 }}>เลขที่ใบสั่ง/สัญญา จพ.(สบส.)<span className="text-danger">*</span></th>
                                </>
                              )
                            }
                            <th style={{ minWidth: 200 }}>ประเภทคณะกรรมการ <span className="text-danger">*</span></th>
                            <th style={{ minWidth: 100 }}>คณะกรรมการ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {financial.entrepreneursList?.map((entrepreneurs, index) => (
                            <tr key={index}
                              className='text-center'>
                              <td className='text-center'>{index + 1}</td>
                              <td className='text-start'>{entrepreneurs.entrepreneursTaxId}</td>
                              <td className='text-start'>{entrepreneurs.entrepreneursName}</td>
                              <td className='text-end'>
                                <Currency
                                  value={entrepreneurs.offerTotalAmount}
                                  onChange={(val) => handlerOnChangeOfferTotalAmount(Number(val), `${entrepreneurs.sequence}`, index, financialIndex, financial.financialId, financial.id!)}
                                  disabled={isDisabled}
                                  rule={{ required: true }}
                                  maxValue={financial.amount}
                                />
                              </td>
                              <td className='text-center'>
                                <Input
                                  value={entrepreneurs.poNumber}
                                  onChange={(val) => handlerOnChangePoNumber(val, `${entrepreneurs.sequence}`, index, financialIndex, financial.financialId, financial.id!)}
                                  rule={{ required: true }}
                                  disabled={isDisabled}
                                />
                              </td>
                              {
                                (contractType === ContractType.Contract) && (
                                  <>
                                    <td className='text-center'>
                                      <Input
                                        textCenter
                                        onChange={(val) => handlerOnChangeContractNumber(val, `${entrepreneurs.sequence}`, index, financialIndex, financial.financialId, financial.id!)}
                                        rule={{ required: true }}
                                        value={entrepreneurs.contractNumber}
                                        type='text'
                                        disabled={isDisabled} />
                                    </td>
                                  </>
                                )
                              }
                              <td className='text-start'>
                                <Selector
                                  disabled={isDisabled}
                                  rule={{ required: true }}
                                  value={entrepreneurs.receiveCommitteeId}
                                  items={purchaseOrderApproval.contractRecord?.termOfRefId
                                    ? receivceCommitteeDDL
                                    : receivceCommitteeTypeDDL}
                                  onChange={(val) => handlerOnChangeReceivceCommittee(val, `${entrepreneurs.sequence}`, index, financialIndex, financial.financialId, financial.id!)}
                                />
                              </td>
                              {/* <td className='text-center align-items-center'>
                              <Check
                                value={entrepreneurs.isMA}
                                onChange={(val) => handlerOnChangeIsMA(val, `${entrepreneurs.sequence}`, index, financialIndex, financial.financialId, financial.id!)}
                                disabled={isDisabled}
                                className='d-inline-block mt-1'
                              />
                            </td> */}
                              {/* <td className='text-center'>
                              <div className='d-flex justify-content-center'>
                                <Button
                                  variant={entrepreneurs.poNumber && (entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Draft || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.WaitingForSign || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Rejected) ? 'success' : 'primary'}
                                  className='d-flex align-items-center gap-2'
                                  onClick={() => (purchaseOrderApproval.contractRecord?.id ? onConfirmUpdatePO(financialIndex, index, entrepreneurs.id) : setShowCreatePO(true))}
                                  disabled={entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned}
                                >
                                  <FaSave className='pb-1 fs-5' />
                                  {entrepreneurs.poNumber && (entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Draft || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.WaitingForSign || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Rejected) ? 'บันทึกเลขที่ PO แล้ว' : 'รอบันทึกเลขที่ PO'}
                                </Button>
                              </div>
                            </td> */}
                              <td className='text-center'>
                                <div className='d-flex justify-content-center'>
                                  {!(entrepreneurs.poNumber && (entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Draft || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.WaitingForSign || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Rejected))
                                    ? <Button
                                      variant='secondary'
                                      className='d-flex align-items-center gap-2'
                                      disabled={!(entrepreneurs.poNumber && (entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Draft || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.WaitingForSign || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Rejected))}
                                    >
                                      ไปยังบันทึกคณะกรรมการ
                                    </Button>
                                    : <Button
                                      variant={entrepreneurs.committees && entrepreneurs.committees!.length > 0 ? 'success' : 'primary'}
                                      className='d-flex align-items-center gap-2'
                                      onClick={() => goToCommittee(entrepreneurs.id!)}
                                      disabled={!(entrepreneurs.poNumber && (entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Draft || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.WaitingForSign || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Rejected))}
                                    >
                                      {entrepreneurs.committees && entrepreneurs.committees!.length > 0 ? 'บันทึกคณะกรรมการแล้ว' : 'ไปยังบันทึกคณะกรรมการ'}
                                    </Button>
                                  }
                                </div>
                              </td>
                              {/* <td className='text-center'>
                                <div className='d-flex justify-content-center'>
                                  {!(entrepreneurs.poNumber && (entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Draft || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.WaitingForSign || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Rejected) && (entrepreneurs.committees && entrepreneurs.committees!.length > 0))
                                    ? <Button
                                      variant='secondary'
                                      className='d-flex align-items-center gap-2'
                                      disabled={!(entrepreneurs.poNumber && (entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Draft || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.WaitingForSign || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Rejected) && (entrepreneurs.committees && entrepreneurs.committees!.length > 0))}
                                    >
                                      รอผู้มีอำนาจลงนาม
                                    </Button>
                                    : <Button
                                      variant={entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned ? 'success' : 'primary'}
                                      className='d-flex align-items-center gap-2'
                                      onClick={() => goToAcceptors(entrepreneurs.id!)}
                                      disabled={!(entrepreneurs.poNumber && (entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Draft || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.WaitingForSign || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Rejected) && (entrepreneurs.committees && entrepreneurs.committees!.length > 0))}
                                    >
                                      {entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned ? 'ลงนามแล้ว' : 'รอผู้มีอำนาจลงนาม'}
                                    </Button>}
                                </div>
                              </td> */}
                              <td className='text-center'>
                                {!isDisabled && (
                                  <div className='d-flex justify-content-end'>
                                    <Button
                                      variant='danger'
                                      className='d-flex align-items-center gap-2'
                                      onClick={() => handlerRemoveTrader(index, financialIndex, financial.financialId, financial.id!)}
                                      disabled={entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Signed || entrepreneurs.status === PurchaseOrderApprovalStatusConstant.Assigned}
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </div>
                                )}
                              </td>
                              <Modal
                                show={showAssignee}
                                size='lg'
                                onHide={() => setShowAssignee(!showAssignee)}
                                children={(
                                  <>
                                    <ModalBT.Header closeButton>
                                      <ModalBT.Title>มอบหมายงาน</ModalBT.Title>
                                    </ModalBT.Header>
                                    <ModalBT.Body className='p-0 ps-4'>
                                      คุณต้องการที่จะดำเนินการมอบหมายงานหรือไม่ ?
                                    </ModalBT.Body>
                                    <ModalBT.Footer>
                                      <Button variant='outline-primary'
                                        onClick={() => setShowAssignee(!showAssignee)}>
                                        ยกเลิก
                                      </Button>
                                      <Button
                                        variant='primary'
                                        onClick={() => goToAssignee(entrepreneurs.id!)}
                                      >
                                        มอบหมายงาน
                                      </Button>
                                    </ModalBT.Footer>
                                  </>
                                )}
                              />
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <FinancialModal
                    show={showEditContractFinancial[financialIndex]}
                    onHide={() => onHideEditFinancialModal(financialIndex)}
                    contractRecordId={data.contractRecord?.id}
                    financialId={financial.id}
                    data={financial}
                    onGet={() => getPurchaseOrderApprovalByIdAsynce()}
                    sequence={purchaseOrderApproval.contractRecord?.financials.length}
                    planBudget={financialAmount}
                    sumFinancialBudget={purchaseOrderApproval.contractRecord?.financials?.filter(x => x.id !== financial.id).reduce((a, v) => a += v.amount, 0)}
                  />
                  <TraderModal
                    show={showEntrepreneur[financialIndex]}
                    onHide={() => onHideEntrepreneurModal(financialIndex)}
                    onSelectItem={(id, entrepreneurType, type, taxpayerIdentificationNo, establishmentName, email) => onSelectEntrepreneur(id, entrepreneurType, type, taxpayerIdentificationNo, establishmentName, email, financial.id!, financialIndex)}
                  />
                  <Modal
                    show={showConfirmDelete[financialIndex]}
                    size='lg'
                    onHide={() => onHideConfirmDeleteModal(financialIndex)}
                    children={(
                      <>
                        <ModalBT.Header closeButton>
                          <ModalBT.Title>ลบวงเงินที่จัดซื้อจัดจ้าง </ModalBT.Title>
                        </ModalBT.Header>
                        <ModalBT.Body className='p-0 ps-4'>
                          คุณต้องการลบวงเงิน ใช่หรือไม่ ?
                        </ModalBT.Body>
                        <ModalBT.Footer>
                          <Button variant='outline-primary'
                            onClick={() => onHideConfirmDeleteModal(financialIndex)}>
                            ยกเลิก
                          </Button>
                          <Button
                            variant='primary'
                            onClick={(x) => onDeleteFinancialAsync(financial.id!, financialIndex)}
                          >
                            ยืนยัน
                          </Button>
                        </ModalBT.Footer>
                      </>
                    )}
                  />
                </Card>
              ))}
            </>
          </Card>
          {
            (sumFinancialAmount > 0 && data.contractRecord?.isNextAcceptors) && (
              <Acceptors
                titleHistory='ประวัติอนุมัติใบสั่งซื้อ/จ้าง/เช่า และแจ้งทำสัญญา'
                data={acceptorsData && acceptorsData.length > 0 ? acceptorsData : data.contractRecord.acceptors}
                visible={!isDisabled}
                isDepartmentDirector={false}
                processType={PROCESSTYPE.ApprovePurchaseOrderProcess}
                budgetAmount={sumFinancialAmount}
                onSetSectionType={(type) => setSectionType(type)}
                onAcceptorModalShow={() => setShowModals(!showModals)}
                onSetSectionSequence={(section) => setSectionSequence(section)}
                onAcceptorDataChange={(data) => setAcceptorsData(data)}
              />
            )
          }
          {
            (data.contractRecord.status
              && (data.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Signed
                || data.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Assigned
              )) && (
              <>
                <Assignee
                  data={assigneesDataLevel0?.sort((a, b) => a.sequence - b.sequence)!}
                  visible={isJorporDirectorAssignLv0}
                  onAssigneeModalShow={() => setShowUserAssigneeLevel0(true)}
                  onRemoveAssigneeChange={(index) => handlerRemoveAssigneesDataLevel0(index)}
                  textTitle='ผอ.จพ. มอบหมายงานไปยังผู้รับผิดชอบ'
                />
                <Assignee
                  data={assigneesDataLevel1?.sort((a, b) => a.sequence - b.sequence)!}
                  visible={isJorporDirectorAssignLv1}
                  onAssigneeModalShow={() => setShowUserAssigneeLevel1(true)}
                  onRemoveAssigneeChange={(index) => handlerRemoveAssigneesDataLevel1(index)}
                  textTitle='ผู้รับผิดชอบ'
                />
              </>
            )
          }
        </>
      )
      }
      {
        data.contractRecord?.id && (
          <Card className='mt-3'>
            <Title text='เอกสารแนบ'
              className='fs-5 text-primary' />
            <Row className='justify-content-center'>
              <Col sm={12}
                lg={6}>
                <UploadFile
                  disabled={isDisabled}
                  files={files}
                  handleFileChange={handleFileChange}
                  removeItem={removeItemAsync}
                  contractRecordId={purchaseOrderApproval.contractRecord?.id!}
                />
              </Col>
            </Row>
          </Card>
        )
      }

      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          onClick={onClickBack}
          variant='outline-primary'>
          <MdArrowBack className='me-2 pb-1 fs-5' />
          กลับหน้าแรก
        </Button>
        <div>
          {((!data.contractRecord || !purchaseOrderApproval.contractRecord?.status) ||
            (
              (purchaseOrderApproval.contractRecord?.status
                && purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Draft
                || purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.None
                || purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Rejected
              ) && isCreator
            )
          ) && (
              <>
                <Button
                  className='me-2 px-3'
                  onClick={() => onSubmitAsync(false)}
                  variant='success'>
                  <FaSave className='me-2 pb-1 fs-5' />
                  บันทึก
                </Button>
              </>
            )}
          {(purchaseOrderApproval.contractRecord?.status
            && ((purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Signed
              && (isJorporDirectorAssignLv0 || isJorporDirectorAssignLv1))
            )) && (
              <>
                <Button
                  className='me-2 px-3'
                  onClick={() => setShowRejected(true)}
                  variant='danger'>
                  <FaUndo className='mx-2 pb-1 fs-5' />
                  ส่งกลับแก้ไข
                </Button>
              </>
            )}
          {(purchaseOrderApproval.contractRecord?.status
            && ((purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Signed && isJorporDirectorAssignLv0) || (purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Assigned && (isJorporDirectorAssignLv0 || isJorporDirectorAssignLv1))
            )) && (
              <>
                <Button
                  className='me-2 px-3'
                  onClick={() => onSubmitAssigneeAsync(false)}
                  variant='success'>
                  <FaSave className='me-2 pb-1 fs-5' />
                  บันทึก
                </Button>
              </>
            )}
          {(isJorporDirectorAssignLv0
            && purchaseOrderApproval.contractRecord?.status
            && purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Signed) && (
              <>
                <ButtonCustom
                  text="มอบหมายงาน"
                  icon='checked'
                  onClick={() => handlerAssigneesLevel1()}
                />
              </>
            )}
          {(isCreator
            && (purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Draft
              || purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.Rejected)
            && sumFinancialAmount > 0 && data.contractRecord?.isNextAcceptors) && (
              <>
                <Button
                  className='me-2 px-3'
                  onClick={() => setShowPending(true)}
                >
                  <FaRegCheckCircle className='me-2 pb-1 fs-5' />
                  ส่งเห็นชอบ/อนุมัติ
                </Button>
              </>
            )}
          {(isCreator
            && purchaseOrderApproval.contractRecord?.status === PurchaseOrderApprovalStatusConstant.WaitingForSign
            && isRecall) && (
              <Button
                className='me-2 px-3'
                onClick={() => setShowReCall(true)}
                variant='danger'
              >
                <FaUndo className='mx-2 pb-1 fs-5' />
                เรียกคืนแก้ไข
              </Button>
            )}
          {(isQueueApprover && (data.contractRecord?.status === PurchaseOrderApprovalStatusConstant.WaitingForSign)) && (
            <>
              <Button
                className='me-2 px-3'
                onClick={() => setShowRejected(true)}
                variant='danger'>
                <FaUndo className='mx-2 pb-1 fs-5' />
                ส่งกลับแก้ไข
              </Button>

              <Button
                onClick={() => setShowApproved(true)}
                className='me-2 px-3'
                variant='success'
              >
                <FaCheckCircle className='me-2 pb-1 fs-5' />
                {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </Button>
            </>

          )}
        </div>
        {/* <Button
          onClick={onClickNext}
          className='me-2 px-3'
        >
          ถัดไป
          <MdArrowForward className='ms-2 pb-1 fs-5' />
        </Button> */}
        <div style={{ width: 145 }} />
      </div>
      <HistoryModal
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={purchaseOrderApproval.contractRecord?.id}
      />
      <Modal
        show={showCreatePO}
        size='lg'
        onHide={() => setShowCreatePO(!showCreatePO)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>บันทึกเลขที่ PO </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการดำเนินการบันทึกเลขที่ PO หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowCreatePO(!showCreatePO)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(false)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <FinancialModal
        show={showContractFinancial}
        onHide={() => setShowContractFinancial(!showContractFinancial)}
        contractRecordId={data.contractRecord?.id}
        onGet={() => getPurchaseOrderApprovalByIdAsynce()}
        sequence={purchaseOrderApproval.contractRecord?.financials != null ? purchaseOrderApproval.contractRecord?.financials.length : 0}
        planBudget={financialAmount}
        sumFinancialBudget={purchaseOrderApproval.contractRecord?.financials != null ? purchaseOrderApproval.contractRecord?.financials?.reduce((a, v) => a += v.amount, 0) : 0}
      />
      <Modal
        show={showUpdatePO}
        size='lg'
        onHide={() => setShowUpdatePO(!showUpdatePO)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>บันทึกเลขที่ PO </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการบันทึกเลขที่ PO ใช่หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowUpdatePO(!showUpdatePO)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={(x) => onUpdatrPOAsync(financialIndex, entrepreneursIndex, entrepreneursId)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <PlanProcurementModal
        show={showPlan}
        onHide={() => setShowPlan(!showPlan)}
        onSelectItem={onSelectPlan}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
        programType={ProgramType.ContractRecord}
      />
      <Modal
        show={showPending}
        size='lg'
        onHide={() => setShowPending(!showPending)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งเห็นชอบ/อนุมัติ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPending(!showPending)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showApproved}
        size='lg'
        onHide={() => setShowApproved(!showApproved)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>
                {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
              </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='ความเห็นเพิ่มเติม (ถ้ามี)'
                value={remark}
                onChange={(value) => setRemark(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApproved(!showApproved)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onApproved}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejected}
        size='lg'
        onHide={() => setShowRejected(!showRejected)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='เหตุผล'
                rule={{ required: true }}
                value={remark}
                onChange={(value) => setRemark(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='outline-primary'
                className='me-2 px-3'
                onClick={() => setShowRejected(!showRejected)}
              >
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onReject}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showModals}
        onHide={() => setShowModals(!showModals)}
        onSelect={(id, name, departmentName, position, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName) => onSelectEmpApprover(id, name, departmentName!, position!, acceptorsData.length + 1, sectionSequence, '', sectionType, sectionType, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />


      <SearchBuNameModal
        total={userTotal}
        show={showUserAssigneeLevel0}
        onHide={() => setShowUserAssigneeLevel0(!showUserAssigneeLevel0)}
        onSelect={(id, name, department, position) => onSelectEmpApproverAssigneesDataLevel0(id, name, position!, department!, assigneesDataLevel0.length + 1, AssigneesLevel.Level0)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <SearchBuNameModal
        total={userTotal}
        show={showUserAssigneeLevel1}
        onHide={() => setShowUserAssigneeLevel1(!showUserAssigneeLevel1)}
        onSelect={(id, name, department, position) => onSelectEmpApproverAssigneesDataLevel1(id, name, position!, department!, assigneesDataLevel1.length + 1, AssigneesLevel.Level1)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />


      <Modal
        show={showSaveAssignees}
        size='lg'
        onHide={() => setShowSaveAssignees(!showSaveAssignees)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการบันทึกมอบหมายหรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowSaveAssignees(!showSaveAssignees)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAssigneeAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showReCall}
        size='lg'
        onHide={() => setShowReCall(!showReCall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowReCall(!showReCall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={reCallAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div >
  );
}

export default Step1;
