import {
  AnnouncementPlanStatus,
  Card,
  Input,
  InputTextArea,
  Selector,
  Status,
  StatusType,
  Table,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  HistoryModal,
  Modal,
  SearchBuNameModal,
} from 'components/Modal';
import { JorPorCode } from 'constant/basicInformation';
import {
  ItemModel,
  JorporDirectorAssign,
  PlanList,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
} from 'models';
import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaAngleLeft,
  FaHistory,
  FaPlus,
  FaRedo,
  FaSave,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import { MdArrowForward } from 'react-icons/md';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router';
import { account, publicPlanAnnouncement as publicService, shareValue } from 'services';
import {
  GetMonthYearBC,
  HttpStatusCode,
  ProcurementSection,
  THCurrency,
  showRemoveModalAsync,
  useAppContext,
} from 'utils';
import {
  AnnouncementStatus as AnnouncementCons,
  AnnouncementStatus,
  AssignLevel,
  PlanTypeText,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { Context } from './Detail';
import { AccountModel } from 'models/accountModel';

const THBText = require('thai-baht-text');

interface Props {
  onClickBack: () => void;
  onClickNext: () => void;
}

type Loader = {
  departments: ItemModel[],
  positions: ItemModel[],
  supplyMethods: ItemModel[],
}

function Step1({ onClickBack, onClickNext }: Props) {
  const { userId, positionCode, departmentCode } = useAppContext();
  const loader = useLoaderData() as Loader;
  const [show, setShow] = useState(false);
  const [showLevel1, setShowLevel1] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [budgetYearItems, setBudgetYearItems] = useState<ItemModel[]>([]);
  const [section, setSection] = useState<string>();
  const { currentStep, publicPlanProcurement, setPublicPlanProcurement, reGetDetail, readonly } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPendingModal, setShowPendingModal] = useState<boolean>(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [level, setlevel] = useState<number>(0);
  const [showDefaultPlanModal, setShowDefaultPlanModal] = useState<boolean>(false);
  const [year, setYear] = useState<number>(0);
  const [supplyMethod, setSupplyMethod] = useState('');
  const [planRejectId, setPlanRejectId] = useState('');
  const [announcementDescription, setAnnouncementDescription] = useState('');
  const [showPlanRejectModal, setShowPlanRejectModal] = useState<boolean>(false);

  const canAdd = useMemo(() => {
    const isJorPorAssignLv1 = publicPlanProcurement?.jorporDirectorAssign?.some((d) => isNull(d.delegateUserId, d.userId) === userId && d.level === AssignLevel.Level1)

    if (publicPlanProcurement.id
      && ((publicPlanProcurement.status === AnnouncementCons.Draft || publicPlanProcurement.status === AnnouncementCons.Reject)
        || (publicPlanProcurement.status === AnnouncementCons.AssignJorPor && isJorPorAssignLv1))) {
      return true;
    }

    return false;
  }, [publicPlanProcurement]);

  const canNext = useMemo(() => {

    if (publicPlanProcurement.jorporDirectorAssign?.filter((x) => x.level === AssignLevel.Level1).length > 0) {
      return true;
    }

    return false;
  }, [publicPlanProcurement]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const getUserProfileByIdAsync = async (userId: string) => {
    const { data, status } = await account.getUserByIdAsync(userId);

    if (status === HttpStatusCode.OK) {
      return data as AccountModel;
    }
  };

  const getUserDataAsync = async (userId: string) => {

    const userOwnerData = await getUserProfileByIdAsync(userId);
    if (!userOwnerData) {
      return;
    }

    const empty = ' ';

    if (section === ProcurementSection.JORPOR_DIRECTOR_ASSIGN) {
      if (!publicPlanProcurement.jorporDirectorAssign) {
        publicPlanProcurement.jorporDirectorAssign = [];
      }

      setPublicPlanProcurement({
        ...publicPlanProcurement,
        jorporDirectorAssign: [
          ...publicPlanProcurement.jorporDirectorAssign,
          {
            userId: userOwnerData.id,
            fullName: userOwnerData.firstName + empty + userOwnerData.lastName,
            departmentName: userOwnerData.departmentName,
            positionName: userOwnerData.positionName,
            sectionCode: ProcurementSection.JORPOR_DIRECTOR_ASSIGN,
            sequence: publicPlanProcurement.jorporDirectorAssign.length + 1,
            level: level,
          } as JorporDirectorAssign,
        ],
      });
    }
  };

  useEffect(() => {
    const date = new Date();
    let year = (date.getUTCFullYear() + 543) - 10;

    const items = Array.from({ length: 20 })
      .map((i) => {
        year += 1;

        return {
          label: year.toString(),
          value: year.toString(),
        };
      });

    setBudgetYearItems(items);
  }, []);

  useEffect(() => {
    // if ((!publicPlanProcurement.id) && (publicPlanProcurement.year && publicPlanProcurement.supplyMethod)) {
    //   getPlanList(publicPlanProcurement.year, publicPlanProcurement.supplyMethod);
    // }
    if (publicPlanProcurement.year) {
      setYear(publicPlanProcurement.year)
    }

    if (publicPlanProcurement.supplyMethod) {
      setSupplyMethod(publicPlanProcurement.supplyMethod)
    }

  }, [publicPlanProcurement.year, publicPlanProcurement.supplyMethod]);

  const userOnSelect = (userId: string, fullName: string, departmentName: string, positionName: string, level: number) => {
    if (publicPlanProcurement.jorporDirectorAssign &&
      publicPlanProcurement.jorporDirectorAssign.find(j => j.userId === userId && j.level === level)) {
      toast.warn('รายชื่อซ้ำ');

      return;
    }

    if (section === ProcurementSection.JORPOR_DIRECTOR_ASSIGN) {
      if (!publicPlanProcurement.jorporDirectorAssign) {
        publicPlanProcurement.jorporDirectorAssign = [];
      }

      setPublicPlanProcurement({
        ...publicPlanProcurement,
        jorporDirectorAssign: [
          ...publicPlanProcurement.jorporDirectorAssign,
          {
            userId,
            fullName,
            departmentName,
            positionName,
            sectionCode: ProcurementSection.JORPOR_DIRECTOR_ASSIGN,
            sequence: publicPlanProcurement.jorporDirectorAssign.length + 1,
            level,
          } as JorporDirectorAssign,
        ],
      });
    }
  };

  const onSelectedPlan = (value: string | number, props: string) => {
    setPublicPlanProcurement({
      ...publicPlanProcurement,
      [props]: value,
    });

    if (props === 'year') {
      setYear(Number(value))
      if (value && supplyMethod) {
        getPlanList(Number(value), supplyMethod, false, '');
      }
    }

    if (props === 'supplyMethod') {
      setSupplyMethod(value.toString())
      if (value && year) {
        getPlanList(year, value.toString(), false, '');
      }
    }
  };

  const reGetPlanProcurementList = async (year: number, supplyMethod: string, isDefault?: boolean) => {
    getPlanList(year, supplyMethod, isDefault, 'ดึงรายการจัดซื้อจัดจ้างเพิ่มเติมสำเร็จ');
  };

  const getPlanList = (year: number, supplyMethod: string, isDefault?: boolean, messageSuccessDefault?: string) => {
    if (publicPlanProcurement.status !== AnnouncementCons.Accepted) {
      setPublicPlanProcurement({ ...publicPlanProcurement, planProcurements: [], remark: '' });
      (async () => {
        const { data, status } = await publicService.getPlanListAsync(year, supplyMethod, publicPlanProcurement.id ?? undefined);
        if (status === HttpStatusCode.OK) {
          const planData: PlanList[] = data;
          if (planData.length === 0) {
            toast.warn('ไม่พบแผนกรุณาลองค้นหาอีกครั้ง');
            setShowDefaultPlanModal(false)
            return;
          }
          const summary = planData.map((value) => value.budget)
            .reduce((sum, p) => sum + p);

          const description = `ธนาคารอาคารสงเคราะห์ได้จัดทำประกาศเผยแพร่แผนการจัดซื้อจัดจ้างประจำปีงบประมาณ ${year} รวม ${data.length} โครงการ รวมเป็นเงิน ${THCurrency(summary)} บาท (${THBText(summary)})`

          setPublicPlanProcurement({
            ...publicPlanProcurement,
            planProcurements: data,
            remark: description,
          });

          if (isDefault) {
            toast.success(messageSuccessDefault!);
            setShowDefaultPlanModal(false)

            if (publicPlanProcurement.id) {
              await publicService.updateAnnouncementAsync({ ...publicPlanProcurement, planProcurements: data, remark: description });
            }
          }
        }
      })();
    } else {
      const summary = publicPlanProcurement.planProcurements.map((value) => value.budget)
        .reduce((sum, p) => sum + p);
      setPublicPlanProcurement({
        ...publicPlanProcurement,
        remark:
          `ธนาคารอาคารสงเคราะห์ได้จัดทำประกาศเผยแพร่แผนการจัดซื้อจัดจ้างประจำปีงบประมาณ ${year} รวม ${publicPlanProcurement.planProcurements.length} โครงการ รวมเป็นเงิน ${THCurrency(summary)} บาท (${THBText(summary)})`,
      });
    }
  };

  const onSubmitAsync = async (status: string) => {
    if (!publicPlanProcurement.planProcurements ||
      publicPlanProcurement.planProcurements.length === 0) {
      toast.warn('ต้องมีรายการจัดซื้อจัดจ้างอย่างน้อย 1 รายการ');
      return;
    }
    if ((!publicPlanProcurement.jorporDirectorAssign
      || publicPlanProcurement.jorporDirectorAssign.filter((x) => x.level === AssignLevel.Level0).length === 0)) {
      toast.warn('ต้องมีผู้ถูกมอบหมายงานจาก จพ. อย่างน้อย 1 คน');
      return;
    }

    if (publicPlanProcurement.status === AnnouncementCons.AssignJorPor.toString()
      && (!publicPlanProcurement.jorporDirectorAssign
        || publicPlanProcurement.jorporDirectorAssign.filter((x) => x.level === AssignLevel.Level1).length === 0)) {
      toast.warn('ต้องมีผู้รับผิดชอบอย่างน้อย 1 คน');
      return;
    }

    // const checkRequestField = publicPlanProcurement.year
    //   && publicPlanProcurement.supplyMethod
    //   && publicPlanProcurement.remark;

    const checkRequestField = year
      && supplyMethod
      && publicPlanProcurement.remark;

    if (!checkRequestField) {
      return;
    }

    publicPlanProcurement.status = status;
    publicPlanProcurement.supplyMethod = supplyMethod;
    publicPlanProcurement.year = year;

    if (publicPlanProcurement.id) {
      const { data, status } = await publicService.updateAnnouncementAsync(publicPlanProcurement);
      if (status === HttpStatusCode.OK || status === HttpStatusCode.ACCEPTED) {
        publicPlanProcurement.id = data.id;
        reGetDetail(data.id);
        // getPlanList(publicPlanProcurement.year, publicPlanProcurement.supplyMethod);
        navigate(`${location.pathname}`, { replace: true });
        toast.success('บันทึกสำเร็จ');
        setShowPendingModal(false);
      }
    } else {
      const { data, status } = await publicService.createAnnouncementAsync(publicPlanProcurement);
      if (status === HttpStatusCode.CREATED) {
        publicPlanProcurement.id = data.id;
        reGetDetail(data.id);
        // getPlanList(publicPlanProcurement.year, publicPlanProcurement.supplyMethod);
        navigate(`${location.pathname}/${data.id}`, { replace: true });
        toast.success('บันทึกสำเร็จ');
        setShowPendingModal(false);
      }
    }
  };

  const isApprover = useMemo(() => {
    if (publicPlanProcurement.sectionHeadJorpor?.some((s) => isNull(s.delegateUserId, s.userId) === userId)
      || publicPlanProcurement.assistantDirectorJorpor?.some((s) => isNull(s.delegateUserId, s.userId) === userId)
      || publicPlanProcurement.directorJorpor?.some((s) => isNull(s.delegateUserId, s.userId) === userId)
      || publicPlanProcurement.assistantMd?.some((s) => isNull(s.delegateUserId, s.userId) === userId)
      || publicPlanProcurement.deputyMd?.some((s) => isNull(s.delegateUserId, s.userId) === userId)
      || publicPlanProcurement.md?.some((s) => isNull(s.delegateUserId, s.userId) === userId)) {
      return true;
    }
    return false;
  }, [publicPlanProcurement]);

  const isJorporDirectorAssign = useMemo(() => {
    return !!publicPlanProcurement?.jorporDirectorAssign?.some((d) => isNull(d.delegateUserId, d.userId) === userId);
  }, [publicPlanProcurement]);

  const isJorporAssign = useMemo(() => {
    return !!publicPlanProcurement?.jorporDirectorAssign?.filter((x) => x.level === AssignLevel.Level0).some((d) => isNull(d.delegateUserId, d.userId) === userId);
  }, [publicPlanProcurement]);

  const isJorporDirectorAssignLv0 = useMemo(() => {
    return !!publicPlanProcurement?.jorporDirectorAssign?.some((d) => isNull(d.delegateUserId, d.userId) === userId && d.level === AssignLevel.Level0);
  }, [publicPlanProcurement]);

  const isJorporDirectorAssignLv1 = useMemo(() => {
    return !!publicPlanProcurement?.jorporDirectorAssign?.some((d) => isNull(d.delegateUserId, d.userId) === userId && d.level === AssignLevel.Level1);
  }, [publicPlanProcurement]);

  const conditionCanAction = useMemo(() => {
    if (!publicPlanProcurement
      || (publicPlanProcurement.status !== AnnouncementCons.Draft && publicPlanProcurement.status !== AnnouncementCons.Reject)) {
      return false;
    }

    if (publicPlanProcurement.status === AnnouncementCons.Draft || publicPlanProcurement.status === AnnouncementCons.Reject && !isApprover) {
      return true;
    }

    return false;
  }, [publicPlanProcurement]);

  function isNull(oldValue?: string, newValue?: string) {
    if (oldValue) {
      return oldValue;
    }

    return newValue;
  }

  const removeUsers = (userId: string, section: ProcurementSection, level: number) => {
    if (section === ProcurementSection.JORPOR_DIRECTOR_ASSIGN) {
      const index = publicPlanProcurement.jorporDirectorAssign
        .findIndex((d) => d.userId === userId && d.level === level);
      publicPlanProcurement.jorporDirectorAssign.splice(index, 1);

      setPublicPlanProcurement({
        ...publicPlanProcurement,
        jorporDirectorAssign: [...publicPlanProcurement.jorporDirectorAssign],
      });
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const onChangeEGPNumber = (value: string, index: number) => {
    const data = [...publicPlanProcurement.planProcurements];
    data[index].egpNumber = value;
    setPublicPlanProcurement({
      ...publicPlanProcurement,
      planProcurements: [...data],
    });
  };

  const handlePlanReject = async (id: string) => {
    setPlanRejectId(id);
    setShowPlanRejectModal(true);
  };

  const handleRemoveListPlan = async (id: string) => {
    const res = await showRemoveModalAsync();

    if (res) {
      await removeSeletedPlanAsync(id);
    }
  };

  useEffect(() => {
    let summary = 0;
    let description = '';

    if (publicPlanProcurement.planProcurements
      && publicPlanProcurement.planProcurements.length > 0) {
      summary = publicPlanProcurement.planProcurements?.map((value) => value.budget)
        .reduce((sum, p) => sum + p);

      description = `ธนาคารอาคารสงเคราะห์ได้จัดทำประกาศเผยแพร่แผนการจัดซื้อจัดจ้างประจำปีงบประมาณ ${year} รวม ${publicPlanProcurement.planProcurements.length} โครงการ รวมเป็นเงิน ${THCurrency(summary)} บาท (${THBText(summary)})`
    }

    setPublicPlanProcurement({
      ...publicPlanProcurement,
      remark: description,
    });

    setAnnouncementDescription(description);
  }, [publicPlanProcurement.planProcurements, announcementDescription]);

  const removeSeletedPlanAsync = async (planId: string) => {
    if (publicPlanProcurement.id) {
      const response = await publicService.deletePlanProcurementSeletedAsync(publicPlanProcurement.id, planId);

      if (response.status === HttpStatusCode.OK) {
        toast.success('ลบรายการจัดซื้อจัดจ้างสำเร็จ');
      }
    }

    if (planId) {
      setPublicPlanProcurement({
        ...publicPlanProcurement,
        planProcurements: publicPlanProcurement.planProcurements.filter(p => p.id !== planId),
      });
    }
  };

  return (
    <>
      <Card>
        <Title
          text='ข้อมูลแผนรวมปี'
          className='fs-5 text-primary'
          extraElement={(
            <div className='d-flex gap-2'>
              <AnnouncementPlanStatus
                value={publicPlanProcurement?.status}
              />
              <Button
                className='d-flex align-items-center gap-2'
                onClick={() => setShowHistoryModal(!showHistoryModal)}
                variant='outline-primary'
              >
                <FaHistory className='me-1' />
                ประวัติการใช้งาน
              </Button>
            </div>
          )}
        />
        <Row className='mt-3'>
          <Col sm={12}
            lg={3}>
            <Input
              label='เลขที่คำขอเผยแพร่แผนจัดซื้อจัดจ้าง'
              value={publicPlanProcurement?.planAnnouncementNumber}
              disabled
            />
          </Col>
          <Col sm={12}
            lg={3}>
            <Input
              label='เลขกลุ่ม e-GP'
              textCenter
              onChange={(val) => setPublicPlanProcurement({
                ...publicPlanProcurement,
                groupEgpNumber: val,
              })}
              value={publicPlanProcurement.groupEgpNumber}
              name='groupEgpNumber'
              placeholder='M๖๖๑๑๐๐๐๑๑๑๑'
              disabled={readonly}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={3}>
            <Selector
              label='ปี'
              items={budgetYearItems}
              value={year?.toString()}
              placeholder='กรุณาเลือก'
              onChange={(val) => onSelectedPlan(val, 'year')}
              disabled={!conditionCanAction}
            />
          </Col>
          <Col sm={12}
            lg={3}>
            <Selector
              label='วิธีการจัดหา'
              items={loader.supplyMethods}
              value={supplyMethod}
              placeholder='กรุณาเลือก'
              onChange={(val) => onSelectedPlan(val, 'supplyMethod')}
              rule={{ required: true }}
              disabled={!conditionCanAction}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            lg={6}>
            <InputTextArea
              label='รายละเอียด'
              value={publicPlanProcurement?.remark}
              placeholder='ระบุรายละเอียด'
              onChange={(val) => setPublicPlanProcurement({
                ...publicPlanProcurement,
                remark: val,
              })}
              rows={5}
              disabled={!conditionCanAction}
            />
          </Col>
        </Row>
        <div className='mt-3'>
          {canAdd
            ? (
              <Button
                className='d-flex align-items-center gap-2'
                variant='outline-primary'
                onClick={() => {
                  setShowDefaultPlanModal(true);
                }}
                disabled={readonly}
              >
                <FaRedo />ดึงรายการจัดซื้อจัดจ้างเพิ่มเติม
              </Button>
            ) : null}
        </div>
        <Table hidePagination>
          <thead>
            <tr>
              <th style={{ minWidth: 80 }}>เลขที่รายการจัดซื้อจัดจ้าง</th>
              <th style={{ minWidth: 150 }}>สถานะ</th>
              <th style={{ minWidth: 250 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 250 }}>ชื่อโครงการ</th>
              <th style={{ minWidth: 80 }}>ปีงบประมาณ</th>
              <th style={{ minWidth: 150 }}>วงเงิน</th>
              <th style={{ minWidth: 200 }}>วิธีจัดหา</th>
              <th style={{ minWidth: 80 }}>ประมาณการช่วงเวลา<br />การจัดซื้อจัดจ้าง</th>
              <th style={{ minWidth: 150 }}>เลขที่ e-GP</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {publicPlanProcurement?.planProcurements?.map((val, index) => (
              <tr key={index}>
                <td className='text-center'>
                  <Button
                    variant='link'
                    onClick={() => {
                      val.type === PlanTypeText.AnnualPlan
                        ? openInNewTab(`/web/procurement-plan-summary/detail/${val.id}`)
                        : openInNewTab(`/web/procurement-plan-inyear/detail/${val.id}`);
                    }}
                  >
                    {val.planNumber}
                  </Button>
                </td>
                <td className='text-center'>
                  <Status type={StatusType.PROCESS}
                    value={val.status} />
                </td>
                <td className='text-center'>
                  {val.department}
                </td>
                <td className='text-start'>
                  {val.planName}
                </td>
                <td className='text-center'>
                  {val.budgetYear}
                </td>
                <td className='text-end'>
                  {THCurrency(val.budget)}
                </td>
                <td className='text-start'>
                  {val.supplyMethod}
                </td>
                <td className='text-center'>
                  {GetMonthYearBC(val.expectingProcurementAt)}
                </td>
                <td className='text-end'>
                  <Input
                    textCenter
                    onChange={(val) => onChangeEGPNumber(val, index)}
                    value={val.egpNumber}
                    name='egpNumber'
                    placeholder='P๖๖๑๑๐๐๐๑๑๑๑'
                  />
                </td>
                <td>
                  {((publicPlanProcurement.id)
                    && (publicPlanProcurement?.status === AnnouncementCons.AssignJorPor || publicPlanProcurement?.status === AnnouncementCons.Reject)
                    && isJorporDirectorAssignLv1) ?
                    <Button
                      className="d-flex align-items-center gap-2"
                      variant="danger"
                      onClick={() => handlePlanReject(val.id)}
                    >
                      <FaUndo />
                      ส่งกลับแก้ไข
                    </Button> : null}
                </td>
                <td>
                  {(publicPlanProcurement?.status === AnnouncementCons.Draft
                    || (publicPlanProcurement.id
                      && (publicPlanProcurement?.status === AnnouncementCons.AssignJorPor
                        || publicPlanProcurement?.status === AnnouncementCons.Reject)
                      && isJorporDirectorAssign)) ?
                    <Button variant='danger'
                      onClick={() => handleRemoveListPlan(val.id)}>
                      <FaTrashAlt />
                    </Button> : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className='mt-3'>
        <Title
          text='มอบหมายงาน จพ.'
          className='fs-5 text-primary'
        />

        {
          (publicPlanProcurement?.status !== AnnouncementCons.WaitingAccept
            && publicPlanProcurement?.status !== AnnouncementCons.Approve)
            ? (
              <div className='d-flex justify-content-end'>
                <Button
                  variant='outline-primary'
                  onClick={() => {
                    setSection(ProcurementSection.JORPOR_DIRECTOR_ASSIGN);
                    setShow(true);
                    setlevel(AssignLevel.Level0);
                  }}
                >
                  <FaPlus className='fs-5 me-2' />เพิ่มรายชื่อ
                </Button>
              </div>
            )
            : <></>
        }
        <Row className='mt-3 align-items-center'>
          <Col sm={12}>
            <Table total={5}
              className='mt-3 step-bar'
              hidePagination>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>ลำดับ</th>
                  <th className='text-start'
                    style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
                  <th className='text-start'
                    style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน</th>
                  <th style={{ minHeight: 100, width: '25%' }} />
                </tr>
              </thead>
              <tbody>
                {publicPlanProcurement?.jorporDirectorAssign?.filter((x) => x.level === AssignLevel.Level0).map((val, index) => (
                  <tr key={index}>
                    <td className='text-center'>{index + 1}</td>
                    <td>
                      {val.fullName}
                      <p className='m-0 department text-wrap'>
                        {val.positionName}
                      </p>
                    </td>
                    <td className='text-left'>
                      {val.delegateFullName &&
                        <>
                          {val.delegateFullName}
                          <p className='m-0 department'>
                            {val.delegatePositionName}
                          </p>
                        </>
                      }
                    </td>
                    {/* <td className='text-start'>{val.fullName}</td>
                    <td>{val.departmentName}</td>
                    <td className='text-start'>{val.positionName}</td> */}
                    <td>
                      {!publicPlanProcurement.id || publicPlanProcurement.createdByUserId === userId
                        || ((publicPlanProcurement?.status === AnnouncementCons.Draft ||
                          publicPlanProcurement?.status === AnnouncementCons.Reject || publicPlanProcurement?.status === AnnouncementCons.AssignJorPor) && isJorporDirectorAssignLv0) ? (
                        <div className='d-flex justify-content-end align-items-end'>
                          <Button
                            className='d-flex align-items-center gap-2'
                            variant='danger'
                            onClick={() => removeUsers(val.userId, ProcurementSection.JORPOR_DIRECTOR_ASSIGN, val.level)}
                          >
                            <FaTrashAlt />
                          </Button>
                        </div>
                      ) : <></>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
      {
        publicPlanProcurement?.id &&
        // publicPlanProcurement?.status !== AnnouncementStatus.Draft
        <Card className='mt-3'>
          <Title
            text='ผู้รับผิดชอบ'
            className='fs-5 text-primary' />

          {(publicPlanProcurement?.status === AnnouncementCons.Reject || publicPlanProcurement?.status === AnnouncementCons.Draft || publicPlanProcurement?.status === AnnouncementCons.AssignJorPor) && isJorporDirectorAssign ? (
            <div className='d-flex justify-content-end'>
              <Button
                variant='outline-primary'
                onClick={() => {
                  setSection(ProcurementSection.JORPOR_DIRECTOR_ASSIGN);
                  setShowLevel1(true);
                  setlevel(AssignLevel.Level1);
                }}
              >
                <FaPlus className='fs-5 me-2' />เพิ่มรายชื่อ
              </Button>
            </div>
          )
            : <></>
          }

          <Row className='mt-3 align-items-center'>
            <Col sm={12}>
              <Table total={5}
                className='mt-3 step-bar'
                hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: '5%' }}>ลำดับ</th>
                    <th className='text-start'
                      style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
                    <th className='text-start'
                      style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน</th>
                    <th style={{ minHeight: 100, width: '25%' }} />
                  </tr>
                </thead>
                <tbody>
                  {publicPlanProcurement?.jorporDirectorAssign?.filter((x) => x.level === AssignLevel.Level1).map((val, index) => (
                    <tr key={index}>
                      <td className='text-center'>{index + 1}</td>
                      <td>
                        {val.fullName}
                        <p className='m-0 department text-wrap'>
                          {val.positionName}
                        </p>
                      </td>
                      <td className='text-left'>
                        {val.delegateFullName &&
                          <>
                            {val.delegateFullName}
                            <p className='m-0 department'>
                              {val.delegatePositionName}
                            </p>
                          </>
                        }
                      </td>
                      {/* <td className='text-start'>{val.fullName}</td>
                      <td>{val.departmentName}</td>
                      <td className='text-center'>{val.positionName}</td> */}
                      <td>
                        {(publicPlanProcurement?.status === AnnouncementCons.Reject || publicPlanProcurement?.status === AnnouncementCons.AssignJorPor || publicPlanProcurement?.status === AnnouncementCons.Draft) && isJorporDirectorAssign ? (
                          <div className='d-flex justify-content-end align-items-end'>
                            <Button
                              className='d-flex align-items-center gap-2'
                              variant='danger'
                              onClick={() => removeUsers(val.userId, ProcurementSection.JORPOR_DIRECTOR_ASSIGN, val.level)}
                            >
                              <FaTrashAlt />
                            </Button>
                          </div>
                        ) : <></>
                        }

                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      }


      {currentStep === 1
        ? (
          <div className='d-flex justify-content-between pt-3'>
            <ButtonCustom
              onClick={onClickBack}
              text="กลับหน้าแรก"
            />
            <div>
              {(publicPlanProcurement?.status === AnnouncementCons.AssignJorPor
                && isJorporDirectorAssign)
                ? (
                  <ButtonCustom
                    onClick={() => onSubmitAsync(publicPlanProcurement?.status)}
                    text="บันทึก"
                  />
                ) : null}
              {(publicPlanProcurement?.status === AnnouncementCons.Draft
                || (publicPlanProcurement?.status === AnnouncementCons.Reject && isJorporDirectorAssign))
                ? (
                  <ButtonCustom
                    onClick={() => onSubmitAsync(publicPlanProcurement?.status)}
                    text="บันทึก"
                  />
                ) : null}
              {((publicPlanProcurement.id) && (publicPlanProcurement?.status === AnnouncementCons.Draft) && isJorporAssign)
                ? (
                  <ButtonCustom
                    onClick={() => setShowPendingModal(true)}
                    text="มอบหมาย"
                  />
                ) : null}
            </div>
            {publicPlanProcurement?.status !== AnnouncementCons.Draft && canNext
              ? (
                <div style={{ width: '129px' }}>
                  <ButtonCustom
                    onClick={onClickNext}
                    text="ถัดไป"
                  />
                </div>
              )
              : <div style={{ width: '129px' }} />}
          </div>
        ) : null}
      <SearchBuNameModal
        total={userTotal}
        show={show}
        onHide={() => setShow(false)}
        onSelect={(id, name, department, position) => userOnSelect(id, name, department!, position!, AssignLevel.Level0)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showLevel1}
        onHide={() => setShowLevel1(false)}
        onSelect={(id, name, department, position) => userOnSelect(id, name, department!, position!, AssignLevel.Level1)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      {/* <PublicPlanSelectUserModal
        show={show}
        onHide={() => setShow(false)}
        onSelect={userOnSelect}
        section={section}
        departments={loader.departments}
        positions={loader.positions}
        data={publicPlanProcurement}
        level={AssignLevel.Level0}
      /> */}
      {/* <PublicPlanSelectUserModal
        show={showLevel1}
        onHide={() => setShowLevel1(false)}
        onSelect={userOnSelect}
        section={section}
        departments={loader.departments}
        positions={loader.positions}
        data={publicPlanProcurement}
        level={AssignLevel.Level1}
      /> */}
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        data={publicPlanProcurement?.activities}
        title='ประวัติการใช้งานจัดซื้อจัดจ้าง'
      />
      <RejectModal
        show={showPlanRejectModal}
        onHide={() => setShowPlanRejectModal(false)}
      />
      <Modal
        show={showDefaultPlanModal}
        size='lg'
        onHide={() => setShowDefaultPlanModal(!showDefaultPlanModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการดึงรายการจัดซื้อจัดจ้างเพิ่มเติมหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDefaultPlanModal(!showDefaultPlanModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => reGetPlanProcurementList(year, supplyMethod, true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showPendingModal}
        size='lg'
        onHide={() => setShowPendingModal(!showPendingModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>มอบหมาย</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการยืนยันการมอบหมาย ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingModal(!showPendingModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(AnnouncementCons.AssignJorPor)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
  function RejectModal(props: {
    show: boolean;
    onHide: () => void;
  }) {
    const [remark, setRemark] = useState<string>();
    const { userId } = useAppContext();

    useEffect(() => {
      if (props.show) {
        setRemark('');
      }
    }, [props.show]);

    function isNull(oldValue?: string, newValue?: string) {
      if (oldValue) {
        return oldValue;
      }

      return newValue;
    }

    const onSubmitAsync = async () => {
      if (remark) {
        if (planRejectId) {
          const res = await publicService
            .rejectPlanProcurementAsync(
              publicPlanProcurement.id,
              planRejectId,
              remark,
            );

          if (res.status === HttpStatusCode.OK) {
            setPublicPlanProcurement({
              ...publicPlanProcurement,
              planProcurements: publicPlanProcurement.planProcurements.filter(p => p.id !== planRejectId),
            });
            toast.success('ส่งกลับแก้ไขสำเร็จ');
            props.onHide();
          } else {
            toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
          }
        }
      }
    };

    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        size='lg'
      >
        <Modal.Header>
          <Title
            text='ส่งกลับแก้ไข'
            className='fs-5 text-primary'
          />
        </Modal.Header>
        <Row>
          <Col>
            <InputTextArea
              label='เหตุผล'
              rule={{ required: true }}
              onChange={setRemark}
              value={remark}
              name='remark'
            />
          </Col>
        </Row>
        <div className='d-flex justify-content-end gap-2'>
          <Button
            variant='outline-primary'
            onClick={props.onHide}
          >
            ยกเลิก
          </Button>
          <Button
            variant='primary'
            onClick={onSubmitAsync}
          >
            ยืนยัน
          </Button>
        </div>
      </Modal>
    );
  }
}

export default Step1;
