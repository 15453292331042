import {
  Breadcrumb,
  Card,
  Currency,
  Input,
  InputTextArea,
  PreProcurmentStatus,
  Status,
  StatusType,
  Table,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import { Pagination } from 'models';
import { ApproveJorPor04Detail } from 'models/PP';
import { useState } from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { BsArrowReturnLeft } from 'react-icons/bs';
import {
  FaAlignLeft,
  FaCheck,
  FaUndo,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import PP0303Service from 'services/PP/PP0303Service';
import { thaiFormatDateTimeWithSlash } from 'utils';
import toast from 'utils/toast';

const BREADCRUMB_INFO = [
  {
    routerName: '/pp/pp0303', label: 'อนุมัติ จพ.004 ตามสายงาน',
  },
  {
    routerName: '', label: 'รายละเอียดการแจ้งข้อมูลเบื้องต้น (จพ.004)',
  },
];

type Loader = { detailData: ApproveJorPor04Detail };

function DetailPP0303() {
  const { detailData } = useLoaderData() as Loader;
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [approveReason, setApproveReason] = useState('');
  const [pagination, setPagination] = useState<Pagination>({ page: 1, size: 10 } as Pagination);
  const navigate = useNavigate();

  function backPage() {
    navigate('/pp/pp0303');
  }

  const reloadPage = () => {
    navigate(`/pp/pp0303/detail/${detailData.id}`);
  };

  const approveAsync = async () => {
    const { status } = await PP0303Service.ApproveJorPor04Async(detailData.id, approveReason.trim());

    if (status !== 200) {
      toast.error('อนุมัติไม่สำเร็จ');
      return;
    }

    toast.success('อนุมัติสำเร็จ');
    setShowApproveModal(false);
    reloadPage();
  };

  const rejectAsync = async () => {
    if (!rejectReason || rejectReason.trim() === '') {
      toast.error('กรุณากรอกหมายเหตุ');
      return;
    }

    const { status } = await PP0303Service.RejectJorPor04Async(detailData.id, rejectReason.trim());

    if (status !== 200) {
      toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
      return;
    }

    toast.success('ส่งกลับแก้ไขสำเร็จ');
    setShowRejectModal(false);
    reloadPage();
  };

  const onShowApproveModal = () => {
    setApproveReason('');
    setShowApproveModal(true);
  };

  const onShowRejectModal = () => {
    setRejectReason('');
    setShowRejectModal(true);
  };

  return (
    <div className='document'>
      <h4 className='mt-2 mb-0 text-primary text-start'>รายละเอียดการแจ้งข้อมูลเบื้องต้น (จพ.004)</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Card className='mt-3'>
        <Title text='ข้อมูลการแจ้งข้อมูลเบื้องต้น (จพ.004)'
          className='fs-5 text-primary' />
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='เลขที่เอกสาร PR'
              value={detailData.prNumber}
              disabled />
          </Col>
        </Row>
        <Row className='align-items-center'>
          <Col sm={12}
            md={4}>
            <Input label='โครงการ'
              value={detailData.projectName}
              disabled />
          </Col>
          <Col sm={12}
            md={3}>
            <Button
              disabled
              variant='link'
              onClick={() => navigate(`/pl/pl0202/detail/${detailData.planId}`, { relative: 'route' })}
            >
              <TbListSearch className='fs-4' /> ไปยังแผน
            </Button>
          </Col>
        </Row>
        <Row className='align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Currency label='งบประมาณโครงการ (บาท)'
              value={detailData.projectBudget}
              disabled />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='วิธีจัดหา'
              value={detailData.supplyMethodType}
              disabled />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}
            className='pt-3'>
            <Input label=''
              value={detailData.supplyMethodName}
              disabled />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='สถานะการอนุมัติตามสายงาน'
          className='fs-5 text-primary' />
        <Table
          className='mt-3'
          hidePagination
          total={pagination.totalRecords}
          onChange={(size, page) => (
            setPagination({ ...pagination, size }),
            setPagination({ ...pagination, page })
          )}
        >
          <thead>
            <tr>
              <th style={{ minWidth: 10 }}>ลำดับ</th>
              <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
              <th style={{ minWidth: 35 }}>สถานะ</th>
              <th style={{ minWidth: 50 }}>วันที่อนุมัติ</th>
            </tr>
          </thead>
          <tbody>
            {detailData.approvers?.map((val, index) => (
              <tr key={val.id}>
                <td className='text-center'>{index + 1}</td>
                <td>{val.fullName}</td>
                <td className='text-center'>{val.positionOnBoardName}</td>
                <td className='text-center'>
                  <Status type={StatusType.JOR_POR_04_05_APPROVER}
                    value={val.approveStatus} />
                </td>
                <td className='text-center'>
                  {val.updatedAt === null ? '' : thaiFormatDateTimeWithSlash(val.updatedAt)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารการแจ้งข้อมูลเบื้องต้น (จพ.004)'
          className='fs-5 text-primary' />
      </Card>

      <div className='d-flex justify-content-center gap-3 pt-3'>
        <ButtonCustom
          onClick={() => backPage()}
          text="ย้อนกลับ"
        />
        <ButtonCustom
          variant='danger'
          icon="undo"
          onClick={() => onShowRejectModal()}
          text="ส่งกลับแก้ไข"
        />
        <ButtonCustom
          disabled={detailData.status === PreProcurmentStatus.APPROVED}
          onClick={() => onShowApproveModal()}
          icon="checked"
          text="ส่งอนุมัติ"
        />
      </div>

      <Modal
        show={showApproveModal}
        size='lg'
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ให้ความเห็นชอบ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='ความเห็น'
                value={approveReason}
                onChange={(val) => setApproveReason(val)}
              />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-center'>
              <Button
                variant='light'
                onClick={() => setShowApproveModal(!showApproveModal)}
              >
                ยกเลิก
              </Button>
              <Button
                variant='success text-white'
                onClick={approveAsync}
              >
                <FaCheck className='me-2' /> ให้ความเห็น
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showRejectModal}
        size='lg'
        onHide={() => setShowRejectModal(!showRejectModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='หมายเหตุ'
                value={rejectReason}
                onChange={(val) => setRejectReason(val)}
                rule={{ required: true }}
              />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-center'>
              <Button
                variant='light'
                onClick={() => setShowRejectModal(!showRejectModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                className='me-2 px-3'
                onClick={rejectAsync}
              >
                <FaUndo className='mx-2 pb-1 fs-4' /> ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default DetailPP0303;
