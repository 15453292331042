import StepProgress from 'components/StepProgress';
import { Trader } from 'models';
import {
  ContractModel,
  GuaranteeModel,
} from 'models/CM/CreateContracModels';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

// const BREADCRUMB_INFO = [
//   {
//     routerName: '/cm/cm0201', label: 'ร่างข้อมูลใบสั่ง',
//   },
//   {
//     routerName: '', label: 'รายละเอียดร่างข้อมูลใบสั่ง',
//   },
// ];

type ContractContext = {
  dataContext: ContractModel
  setDataContext: Dispatch<React.SetStateAction<ContractModel>>,

};

export const Context = createContext({} as ContractContext);

function CreateContractManagementDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id } = useParams();
  const [dataContext, setDataContext] = useState<ContractModel>({} as ContractModel);
  const [disabled, setDisabled] = useState(false);
  // useEffect(() => {
  //   if (id) {
  //     getContractByIdAsynce(id);
  //   }
  // }, [id]);

  // const getContractByIdAsynce = async (id: string) => {
  //   const response = await CreateContractService.getContractByIdAsync(id);

  //   if (response.status === HttpStatusCode.OK) {
  //     setDataContext(response.data);
  //   }
  // };

  useEffect(() => {
    const contract: ContractModel = {
      id: '1',
      jorPor05Number: 'T0001',
      planName: 'จ้างบริการบำรุงรักษาและซ่อมแซมแก้ไขอุปกรณ์สื่อสารระบบ ITMX',
      contractStartDate: new Date(),
      recordContractId: '',
      jorPor05Id: '',
      traderId: '',
      establishmentName: 'บริษัท ออนไลน์ เน็ตเวิร์ค',
      poNumber: '410007',
      contractNo: '001/2566',
      contractName: 'สัญญาจ้างบริการบำรุงรักษาและซ่อมแซมแก้ไขอุปกรณ์สื่อสารระบบ ITMX',
      contractDate: new Date(),
      ContractStartDate: new Date(),
      contractBudget: 2750000,
      delivered: 150,
      deliveredPeriodType: '1',
      deliveredType: '1',
      startWorkingType: '1',
      deliveredDate: new Date(),
      warrantyPeriod: 30,
      warrantyPeriodType: '1',
      warrantyPeriodDate: new Date(),
      startGuaranteeType: '1',
      fineRateText: '',
      status: 'Draft',
      isActive: false,
      FineRateType: '',
      FineRatePerType: '',
      FinesRatePercent: 0,
      FinesRateAmount: 0,
      isResponsible: false,
      IsActive: false,
      trader: {
        id: 'sdqdqdssas',
        nationality: 'ไทย',
        type: 'นิติบุคคล',
        taxpayerIdentificationNo: '1234567890123',
        entrepreneurType: 'นิติบุคคล',
        establishmentName: 'บริษัท ออนไลน์ เน็ตเวิร์ค',
        placeName: '',
        roomNo: '',
        floorNo: '',
        villageName: '',
        address: '',
        group: '',
        alley: '',
        road: '',
        province: '',
        district: '',
        subDistrict: '',
        postalCode: '',
        tel: '0899999999',
        fax: '',
        email: 'welcome@codehard.co.th',
        documents: [],
      } as Trader,
      attachmentFiles: [],
      contractApprover: [
        {
          id: '1',
          contractId: '',
          approverUserId: '',
          approveFullName: 'กชกร สินธววิบูลย์',
          position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร',
          status: 'Approved',
          approvedDateAt: new Date(),
          departmentName: 'จัดหาและการพัสดุ',
          departmentId: '',
          departmetShortName: '',
          remark: '',
          isDisabled: false,
          sequence: 1,
        },
        {
          id: '2',
          contractId: '',
          approverUserId: '',
          approveFullName: 'กชกร ธนารักษ์',
          position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร',
          status: 'Approved',
          approvedDateAt: new Date(),
          departmentName: 'จัดหาและการพัสดุ',
          departmentId: '',
          departmetShortName: '',
          remark: '',
          isDisabled: false,
          sequence: 2,
        },
        {
          id: '3',
          contractId: '',
          approverUserId: '',
          approveFullName: 'กชกร ธนารักษ์',
          position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร',
          status: 'Approved',
          approvedDateAt: new Date(),
          departmentName: 'จัดหาและการพัสดุ',
          departmentId: '',
          departmetShortName: '',
          remark: '',
          isDisabled: false,
          sequence: 3,
        },
      ],
      contractPaidPhases: [],
      guarantee: {
        Guarantee_IsRefrain: false,
        Guarantee_ContractGuaranteeType: '',
        Guarantee_Bank: '',
        Guarantee_Branch: '',
        Guarantee_Amount: 0,
        Guarantee_Percent: 0,
        Guarantee_Number: '',
        Guarantee_Date: new Date(),
        Guarantee_ContractDateType: '',
        Guarantee_ContractStareDate: new Date(),
        Guarantee_ContractEndDate: new Date(),
        Guarantee_Remark: '',
      } as GuaranteeModel,
    };
    setDataContext(contract);
  }, []);

  const obj = [
    {
      title: <p>ข้อมูลใบสั่ง/สัญญา</p>,
      displayIndex: '1',
      child: <Step1 onClickNext={() => clickNext()}
        onClickBack={() => backToIndex()} />,
    },
    {
      title: <p> ตัวอย่างเอกสาร </p>,
      displayIndex: '2',
      child: <Step2 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />,
    },
    {
      title: <p>ข้อมูลลงนาม/สัญญา</p>,
      displayIndex: '3',
      child: <Step3 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />,
    },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/create-contractMangement');
  };

  return (
    <div className='document'>
      <h4 className='mt-2 mb-0 text-primary text-start'>
        <Button variant='link'>
          {/* <MdOutlineArrowBack className="fs-4 text-primary mb-1" /> */}
        </Button>ร่างข้อมูลใบสั่ง/สัญญา
      </h4>
      {/* <Breadcrumb data={BREADCRUMB_INFO} /> */}
      <Row>
        <Col xs={12}
          className='mb-2'>
          <Context.Provider value={{ dataContext, setDataContext }}>
            <StepProgress progressBarData={obj}
              ref={childRef} />
          </Context.Provider> <></>
        </Col>
      </Row>
    </div>
  );
}

export default CreateContractManagementDetail;
