import {
  useEffect,
  useState,
} from 'react';
import Badge from 'react-bootstrap/Badge';

interface Props {
  value: string;
}

const STATUS = [
  { value: 'Draft', color: '#BEBEBE', label: 'แบบร่าง' },
  { value: 'WaitingForApprove', color: '#FBBD01', label: 'อยู่ระหว่างเห็นชอบ/อนุมัติ' },
  { value: 'Approved', color: '#1BA345', label: 'เห็นชอบ/อนุมัติ' },
  { value: 'Cancelled', color: '#DE3E44', label: 'ยกเลิก' },
  { value: 'Rejected', color: '#DE3E44', label: 'ส่งกลับแก้ไข' },
];

const STATUS_DETAIL = [
  { value: 'Draft', color: '', label: '' },
  { value: 'WaitingForApprove', color: 'warning', label: 'รอเห็นชอบ/อนุมัติ' },
  { value: 'Approved', color: 'success', label: 'เห็นชอบ/อนุมัติ' },
  { value: 'Rejected', color: 'danger', label: 'ไม่อนุมัติ' },
];

function InvitationStatus(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();


  useEffect(() => {
    if (props.value) {
      const status = STATUS.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);
  return (
    <>
      {props.value
        ? (
          <div className="tor-status" style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

function InvitationStatusDetail(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS_DETAIL.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color} className="text-white">
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}

export { InvitationStatusDetail, InvitationStatus };
