import {
  Modal,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { BasicInfoHistoryListResponseModel } from 'models/PP';
import {
  useEffect,
  useState,
} from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import { PP0205Service } from 'services';
import {
  fullDatetime,
  HttpStatusCode,
} from 'utils';

interface Props {
  show: boolean;
  onHide?: () => void;
  title?: string;
  id?: string;
  basicInformationId?: string;
}

export function HistoryModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [history, setHistory] = useState<BasicInfoHistoryListResponseModel>({} as BasicInfoHistoryListResponseModel);

  useEffect(() => {
    if (props.basicInformationId && props.show) {
      getbasicInfoHistoryAsynce(page, size);
    }
  }, [props.basicInformationId, props.show]);

  const getbasicInfoHistoryAsynce = async (page: number, size: number) => {
    const response = await PP0205Service.getListHistoryAsync(page, size, props.basicInformationId);

    if (response.status === HttpStatusCode.OK) {
      setHistory(response.data);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size='xl'
    >
      <Modal.Header>
        <Title
          text='ประวัติการแจ้งข้อมูลเบื้องต้น (จพ.004)'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <ModalBT.Body className='p-0 ps-4'>
        <Table className='my-3'
          total={history.totalCount}
          onChange={(size, page) => {
            getbasicInfoHistoryAsynce(page, size);
          }}>
          <thead>
          <tr>
            <th>วันที่/เวลา</th>
            <th>ผู้ดำเนินการ</th>
            <th>การดำเนินการ</th>
            <th>สถานะการดำเนินการ</th>
            <th>หมายเหตุ</th>

          </tr>
          </thead>
          <tbody>
          {
            (history && history.data) && (
              history.data.map((data, index) => (
                <tr key={data.id}>
                  <td className='text-center'>{fullDatetime(data.createdInfo_At)}</td>
                  <td>{data.fullName}</td>
                  <td>{data.description}</td>
                  <td className='text-start'>{data.status}</td>
                  <td className='text-start text-wrap'
                    style={{ minWidth: 400, width: 400 }}>{data.remark}</td>
                </tr>
              ))
            )
          }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
