import { HttpStatusCode } from 'axios';
import {
  Input,
  Modal,
  Table,
} from 'components';
import {
  ListModel,
  matrixPagination,
} from 'models/PP';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaEdit,
  FaEraser,
  FaPlus,
  FaSearch,
  FaTrash,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import PP0105Service from 'services/PP/PP0105Service';
import { THCurrency } from 'utils';
import toast from 'utils/toast';

export default function PP0105() {
  return (
    <div className="PP0105">
      <PP0105Header />
      <PP0105Table />
    </div>
  );

  type Loader = {
    tableData: ListModel
  };

  function PP0105Header() {
    const navigate = useNavigate();

    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="text-primary m-0">Matrix การอนุมัติร่างขอบเขตและราคากลาง</h4>
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2"
            onClick={() => { navigate('detail'); }}
          >
            <FaPlus />เพิ่ม Matrix
          </Button>
        </div>
        <hr />
      </>
    );
  }

  function PP0105Table() {
    const navigate = useNavigate();
    const { tableData } = useLoaderData() as Loader;
    const [pagination, setPagination] = useState<matrixPagination>({
      page: 1,
      size: 10,
    } as matrixPagination);
    const [keyword, setKeyword] = useState<string>('');
    const [dataList, setData] = useState<ListModel>({} as ListModel);
    const [isMount, setIsMount] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [deleteId, setDelteId] = useState<string>('');

    useEffect(() => {
      if (tableData) {
        setData(tableData);
        setPagination({ ...pagination, totalRecords: tableData.totalRecords });
        setIsMount(true);
      }
    }, [tableData]);

    const searchData = useCallback(async (value: string) => {
      const { data, status } = await PP0105Service.GetMatrixListAsync(pagination.page, pagination.size, value);
      if (status === HttpStatusCode.Ok) {
        setData(data);
        setPagination({ ...pagination, totalRecords: data.totalRecords });
      }
    }, [pagination.page, pagination.size]);

    const clearData = useCallback(async () => {
      setKeyword('');
      const { data, status } = await PP0105Service.GetMatrixListAsync(pagination.page, pagination.size, '');
      if (status === HttpStatusCode.Ok) {
        setData(data);
        setPagination({ ...pagination, totalRecords: data.totalRecords });
      }
    }, []);

    const showModal = (id: string) => {
      setDelteId(id);
      setShow(true);
    };

    const onHideModal = async (staus: boolean) => {
      setShow(false);
      searchData('');
    };

    return (
      isMount
        ? (
          <>
            <Row className="criteria">
              <Col lg={8} xl={6} className="d-flex gap-2">
                <Input
                  label="ชื่อ Matrix"
                  placeholder="ชื่อ Matrix"
                  value={keyword}
                  onChange={setKeyword}
                />
                <div className="d-flex gap-2 button-inline">
                  <Button
                    variant="primary"
                    className="d-flex align-items-center gap-2"
                    onClick={() => searchData(keyword)}
                  >
                    <FaSearch />ค้นหา
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="d-flex align-items-center gap-2"
                    onClick={clearData}
                  >
                    <FaEraser />ล้าง
                  </Button>
                </div>
              </Col>
            </Row>
            <Table
              className="table mt-4"
              total={pagination.totalRecords}
              onChange={(size, page) => (
                setPagination({ ...pagination, size }),
                setPagination({ ...pagination, page }))}
            >
              <thead>
                <tr>
                  <th style={{ minWidth: 100 }}>ลำดับ</th>
                  <th style={{ minWidth: 300 }}>ชื่อ</th>
                  <th style={{ minWidth: 300 }}>วงเงิน</th>
                  <th style={{ minWidth: 200 }}>วิธีจัดหา</th>
                  <th style={{ minWidth: 50 }} />
                </tr>
              </thead>
              <tbody>
                {dataList.tableData.map((v, i) => (
                  <tr key={v.id}>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{v.name}</td>
                    <td className="text-center">{THCurrency(v.limit)}</td>
                    <td className="text-center">{v.supplyMethodName}</td>
                    <td className="d-flex justify-content-center gap-4">
                      <Button
                        variant="outline-primary"
                        className="d-flex align-items-center gap-2"
                        onClick={() => { navigate(`detail/${v.id}`); }}
                      >
                        <FaEdit />แก้ไข
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="d-flex align-items-center gap-2"
                        onClick={() => showModal(v.id)}
                      >
                        <FaTrash />ลบ
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <ConfirmModal
              show={show}
              id={deleteId}
              onHide={() => onHideModal(false)}
            />
          </>
        ) : <></>
    );
  }
}

function ConfirmModal(
  props: {
    show: boolean,
    id: string,
    onHide: () => void,
  },
) {
  const deleteMatrix = async (id: string) => {
    const { data, status } = await PP0105Service.DeleteMatrixAsync(id);
    if (status === HttpStatusCode.NoContent) {
      props.onHide();
      toast.success('ลบข้อมูลสำเร็จ');
    }
  };
  return (
    <Modal show={props.show}>
      <ModalBT.Header>
        <ModalBT.Title>ลบข้อมูล</ModalBT.Title>
      </ModalBT.Header>
      <ModalBT.Body className="p-0 ps-4">คุณต้องการที่จะลบข้อมูลใช่หรือไม่ ?</ModalBT.Body>
      <ModalBT.Footer>
        <Button variant="light" onClick={() => props.onHide()}>
          ยกเลิก
        </Button>
        <Button variant="primary" onClick={() => deleteMatrix(props.id)}>
          ยืนยัน
        </Button>
      </ModalBT.Footer>
    </Modal>
  );
}
