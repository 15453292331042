import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  ButtonCustom,
  Card,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  Table,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import {
  ContractAgreementModel,
  ContractAgreementWorkPhaseModel,
  DeliveryModel,
  EmploymentAgreementModel,
  MaintenanceInformationModel,
  UpdateWorkPhaseDetailModel,
  UpdateWorkPhaseStep2Model,
} from '../../../../../../models/CM/CM02Models';
import CM02Service from '../../../../../../services/CM/CM02Service';
import {
  fullDate,
  HttpStatusCode,
} from '../../../../../../utils';
import toast from '../../../../../../utils/toast';
import { EditDeliveryModal } from '../../../Modal/EditDeliveryModal';
import { InputNumber } from 'components/Controls/InputNumber';
import { EditMAModal } from './Modal/EditMAModal';
import { PeriodConditionIdEnum, PeriodTypeIdEnum } from 'utils/constants/ShareValueEnum';

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

interface IProps {
  title?: string;
  contractTemplateCode: string;
  contractId: string;
  contractOfferTotalAmountVat: number;
  periodTypeDDL: ItemModel[];
  periodConditionDDL: ItemModel[];
  context: ContractAgreementModel;
  deliveryData: DeliveryModel;
  employmentAgreement: EmploymentAgreementModel;
  contractAgreementWorkPhaseData: ContractAgreementWorkPhaseModel[];
  onSubmitDeliverWorkPhaseModal: (models: ContractAgreementWorkPhaseModel[]) => void;
  onEmploymentAgreementChange: (value: EmploymentAgreementModel) => void;
  onDeliveryChange: (value: DeliveryModel) => void;
  isDisabled?: boolean;
  isResponsible?: boolean;
  onSubmitMaintenanceInformations: (models: MaintenanceInformationModel[]) => void;
}

const fields = [
  {
    code: 'CF01',
  },
  {
    code: 'CF02',
    place: 'สถานที่ส่งมอบที่ปรากฎตามสัญญา',
    dueDate: 'ผู้ขายจะส่งมอบของภายในวันที่',
    dueDay: 'ผู้ขายจะส่งมอบของภายใน',
    dueDayPeriodDefault: PeriodTypeIdEnum.วัน,
    defaultPeriodConditionDDL: PeriodConditionIdEnum.นับถัดจากวันลงนามในสัญญา, //นับถัดวันที่ลงนามในสัญญา
  },
  {
    code: 'CF03',
  },
  {
    code: 'CF04',
    place: 'สถานที่ส่งมอบที่ปรากฎตามสัญญา',
    dueDay: 'ผู้ขายจะส่งมอบของภายใน ',
    dueDayPeriodDefault: PeriodTypeIdEnum.วัน,
    defaultPeriodConditionDDL: PeriodConditionIdEnum.นับตั้งแต่วันที่ลงนามในสัญญา, //นับแต่วันที่ลงนามในสัญญา

    designAndInstallationDay: 'ผู้ขายต้องออกแบบสถานะที่ ติดตั้งคอมพิวเตอร์ และระบบอื่นๆภายใน',
    designAndInstallationConditionDefault: PeriodConditionIdEnum.นับตั้งแต่วันที่ลงนามในสัญญา, //นับตั้งแต่วันที่ลงนามในสัญญา
  },
  {
    code: 'CF05',
    place: 'สถานที่ส่งมอบที่ปรากฎตามสัญญา',
    dueDay: 'ผู้ขายจะส่งมอบของภายใน',
    dueDayPeriodDefault: PeriodTypeIdEnum.วัน,
    defaultPeriodConditionDDL: PeriodConditionIdEnum.นับถัดจากวันลงนามในสัญญา, //นับถัดจากวันที่ลงนามในสัญญา
  },
  {
    code: 'CF06',
    place: 'สถานที่ส่งมอบคอมพิวเตอร์ ณ',
    dueDay: 'ผู้ให้เช่าจะส่งมอบของภายใน',
  },
  {
    code: 'CF07',
  },
  {
    code: 'CF08',
    place: 'สถานที่ส่งมอบรถยนต์ ณ',
    dueDate: 'ผู้ให้เช่าต้องส่งมอบรถยนต์ภายในวันที่',
    placeTitle: 'สถานที่และวันที่ส่งมอบ',
    paymentTitle: 'งวดเงินและรายละเอียดการชำระเงิน',
  },
  {
    code: 'CF09',
  },
  {
    code: 'CF10',
  },
  {
    code: 'CF11',
    place: 'สถานที่ส่งมอบที่ปรากฎตามสัญญา',
    dueDate: 'ผู้ให้เช่าต้องส่งมอบและติดตั้งภายในวันที่',
  },
  {
    code: 'CF12',
    place: 'สถานที่ส่งมอบที่ปรากฎตามสัญญา',
    dueDate: 'ผู้รับแลกเปลี่ยนจะส่งมอบของภายในวันที่',
    placeTitle: 'สถานที่และวันที่ส่งมอบ',
    paymentTitle: 'งวดเงินและรายละเอียดการชำระเงิน',
  },
  {
    code: 'CF13',
  },
  {
    code: 'CF14',
  },
  {
    code: 'CF15',
  },
];

export function DeliveryLeaseDetail(props: IProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [contractAgreementWorkPhases, setContractAgreementWorkPhases] = useState<ContractAgreementWorkPhaseModel[]>(props.contractAgreementWorkPhaseData);
  const [showEditDeliveryModal, setShowEditDeliveryModal] = useState(false);
  const [maintenanceInformation, setMaintenanceInformation] = useState<MaintenanceInformationModel[]>([]);
  const [showEditMaModal, setShowEditMaModal] = useState(false);
  const [delivery, setDelivery] = useState<DeliveryModel>(props.deliveryData);
  const [employmentAgreement, setEmploymentAgreement] = useState<EmploymentAgreementModel>(props.employmentAgreement);

  useEffect(() => {
    if (firstLoad) {
      defaultDDL();
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    if (employmentAgreement) {
      props.onEmploymentAgreementChange(employmentAgreement);
    }
  }, [employmentAgreement]);

  useEffect(() => {
    if (delivery) {
      props.onDeliveryChange(delivery);
    }
  }, [delivery]);

  const defaultDDL = useCallback(() => {
    fields.filter(f => f.code === props.contractTemplateCode).forEach((data) => {
      setDelivery({
        ...delivery,
        periodCondition: (!delivery?.periodCondition && data.defaultPeriodConditionDDL) ? data.defaultPeriodConditionDDL : delivery?.periodCondition,
        designAndInstallationCondition: (!delivery?.designAndInstallationCondition && data.designAndInstallationConditionDefault) ? data.designAndInstallationConditionDefault : delivery?.designAndInstallationCondition,
      })
    });
  }, []);

  useEffect(() => {
    if (props.context.contractAgreementWorkPhases) {
      setContractAgreementWorkPhases(props.context.contractAgreementWorkPhases);
    }
    if (props.context.maintenanceInformation) {
      setMaintenanceInformation(props.context.maintenanceInformation);
    }
  }, [props.context]);

  const onSubmitDeliverWorkPhaseModal = async (models: ContractAgreementWorkPhaseModel[]) => {
    setContractAgreementWorkPhases(models);
    props.onSubmitDeliverWorkPhaseModal(models);
    onSaveWorkPhase(models);
  };

  const onSubmitMaInfoModal = async (models: MaintenanceInformationModel[]) => {
    setMaintenanceInformation(models);
    props.onSubmitMaintenanceInformations(models);
    await onSaveMaInfo(models);
  };

  const onSaveWorkPhase = async (models: ContractAgreementWorkPhaseModel[]) => {
    const updateDetails: UpdateWorkPhaseDetailModel[] = models.map((data) => ({
      id: data.id,
      sequence: data.sequence,
      periodDay: data.periodDay,
      paidPhase: data.paidPhase,
      workPhase: data.workPhase,
      amount: data.amount,
      percent: data.percent,
      advancePaymentAmount: data.advancePaymentAmount,
      retentionAmount: data.retentionAmount,
      detail: data.detail,
      deliveryDueDate: data.deliveryDueDate,
    } as UpdateWorkPhaseDetailModel));

    const updateData: UpdateWorkPhaseStep2Model = {
      WorkPhases: updateDetails,
    };

    const { status } = await CM02Service.updateContractWorkPhaseStep2Async(props.contractId, updateData);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

      return;
    }

    toast.success('บันทึกข้อมูลการส่งมอบสำเร็จ');
  };

  const onSaveMaInfo = async (models: MaintenanceInformationModel[]) => {
    const updateDetails: MaintenanceInformationModel[] = models.map((data) => ({
      id: data.id,
      contractAgreementId: data.contractAgreementId,
      termOfRefId: data.termOfRefId,
      sequence: data.sequence,
      description: data.description,
      quantity: data.quantity,
      periodType: data.periodType,
      totalQuantity: data.totalQuantity,
      totalPeriodType: data.totalPeriodType,
      endDate: data.endDate,
    } as MaintenanceInformationModel));

    const { status } = await CM02Service.updateContractMAInfoStep2Async(props.contractId, updateDetails);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

      return;
    }

    toast.success('บันทึกข้อมูลการส่งมอบสำเร็จ');
  };

  return (
    <>
      <Card className='mt-3'>
        <Title text={`สัญญา${props.title ? props.title : 'การส่งมอบ'}`} className='fs-5 text-primary' />
        <>
          {
            fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
              <>
                {
                  data.place &&
                  (
                    <>
                      <h5 className='mt-3'>{data.placeTitle}</h5>
                      <Row className='mt-4'>
                        {
                          data.place &&
                          (
                            <Col sm={10}
                              md={10}>
                              <InputTextArea
                                label={data.place}
                                value={delivery?.place}
                                onChange={(value) => setDelivery({ ...delivery, place: value })}
                                disabled={props.isDisabled}
                              />
                            </Col>
                          )
                        }
                      </Row>
                      {
                        data.dueDate &&
                        (
                          <Row className='mt-4'>
                            <Col sm={5}
                              md={5}>
                              <DatePicker
                                label={data.dueDate}
                                value={employmentAgreement?.dueDate}
                                onChangeHasNullable={(value) => setEmploymentAgreement({ ...employmentAgreement, dueDate: value })}
                                disabled={props.isDisabled}
                              />
                            </Col>
                          </Row>
                        )
                      }
                      {
                        data.dueDay &&
                        (
                          <Row className='mt-4'>
                            <Col sm={5}
                              md={5}
                              className='d-flex'>
                              <InputNumber
                                textCenter
                                label={data.dueDay}
                                value={employmentAgreement?.processingTimePerDay}
                                onChange={(value) => setEmploymentAgreement({ ...employmentAgreement, processingTimePerDay: value })}
                                disabled={props.isDisabled}
                              />
                              <label className='mt-5 ps-2'>วัน</label>
                            </Col>
                            <Col sm={5}
                              md={5}>
                              <Selector
                                className='no-label'
                                items={props.periodConditionDDL}
                                value={delivery?.periodCondition}
                                onChange={(value) => setDelivery({ ...delivery, periodCondition: value })}
                                disabled={props.isDisabled} />
                            </Col>
                          </Row>
                        )
                      }
                    </>
                  )
                }
                {
                  data.designAndInstallationDay &&
                  (
                    <>
                      <h5 className='mt-3'>การแจ้งกำหนดเวลาการส่งมอบ</h5>
                      <Row className='mt-4'>
                        <Col sm={8}
                          md={5}
                          className='d-flex'>
                          <InputNumber
                            textCenter
                            label={data.designAndInstallationDay}
                            className='text-wrap'
                            value={delivery?.designAndInstallationDay}
                            onChange={(value) => setDelivery({ ...delivery, designAndInstallationDay: value })}
                            disabled={props.isDisabled}
                          />
                          <label className='mt-5 ps-2'>วัน</label>
                        </Col>
                        <Col sm={8}
                          md={5}>
                          <Selector
                            className='no-label'
                            items={props.periodConditionDDL}
                            value={delivery?.designAndInstallationCondition}
                            onChange={(value) => setDelivery({ ...delivery, designAndInstallationCondition: value })}
                            disabled={props.isDisabled} />
                        </Col>
                      </Row>
                    </>
                  )
                }
                {
                  data.paymentTitle && (
                    <>
                      <h5 className='mt-3'>{data.paymentTitle}</h5>
                      <Row className='d-flex justify-content-between'>
                        <Col sm={5}
                          md={5}>
                          <p>โดยมีรายละเอียดการส่งมอบ ดังนี้</p>
                        </Col>
                        <Col sm={5}
                          md={5}
                          className='text-end'>
                          {props.isResponsible && (<ButtonCustom
                            text='แก้ไขรายการ'
                            onClick={() => setShowEditDeliveryModal(true)}
                            disabled={props.isDisabled} />)}
                        </Col>
                      </Row>
                      <Row>
                        <Table hidePagination>
                          <thead>
                            <tr className='text-center'>
                              {/* <th style={{ width: 20 }}>งวดเงิน</th> */}
                              <th style={{ width: 20 }}>งวดที่</th>
                              <th style={{ width: 50 }}>ระยะเวลาส่งมอบ (วัน)</th>
                              <th style={{ width: 70 }}>วันที่ต้องส่งมอบ</th>
                              <th style={{ width: 20 }}>ร้อยละ</th>
                              <th style={{ width: 70 }}>จำนวนเงิน</th>
                              <th style={{ width: 70 }}>หักเงินล่วงหน้า</th>
                              <th style={{ width: 70 }}>หักเงินประกันผลงาน</th>
                              <th style={{ width: '50%' }}>รายเอียด</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              contractAgreementWorkPhases?.map((data, index) => (
                                <tr className='text-center' key={index}>
                                  {/* <td>{data.paidPhase}</td> */}
                                  <td>{data.workPhase}</td>
                                  <td>{data.periodDay}</td>
                                  <td>{data.deliveryDueDate !== undefined ? fullDate(data.deliveryDueDate) : ''}</td>
                                  <td>{formatter.format(data.percent)}%</td>
                                  <td className='text-end'>{formatter.format(data.amount)}</td>
                                  <td className='text-end'>{formatter.format(data.advancePaymentAmount ?? 0)}</td>
                                  <td className='text-end'>{formatter.format(data.retentionAmount ?? 0)}</td>
                                  <td><InputTextArea value={data.detail}
                                    disabled />
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </Row>
                    </>
                  )
                }
              </>
            )
          }
        </>
      </Card >

      <EditDeliveryModal
        show={showEditDeliveryModal}
        contractAgreementId={props.contractId}
        onHide={() => setShowEditDeliveryModal(false)}
        contractAmountVat={props.contractOfferTotalAmountVat}
        contractAgreementWorkPhases={contractAgreementWorkPhases}
        onSubmit={onSubmitDeliverWorkPhaseModal}
        isDisabled={props.isDisabled}
      />

      <EditMAModal
        show={showEditMaModal}
        contractAgreementId={props.contractId}
        onHide={() => setShowEditMaModal(false)}
        maintenanceInformation={maintenanceInformation}
        periodTypeDDL={props.periodTypeDDL}
        onSubmit={onSubmitMaInfoModal}
        isDisabled={props.isDisabled}
      />
    </>
  );
}
