import {
  Card,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  Status,
  StatusType,
  ButtonCustom,
} from 'components';
import {
  ChangePlanRequestCreate,
  ChangePlanRequestDetailResponse,
  DepartmentResponse,
  DocumentFile,
  ItemModel,
} from 'models';
import { IFile } from 'models/P/FileUploadModel';
import UploadFile from 'pages/PL/PL0201/UploadFile';
import {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaAngleRight,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';
import {
  department,
  shareValue,
} from 'services';
import {
  HttpStatusCode,
  StatusName,
  submitForm,
} from 'utils';
import { CreateDataContext } from './index';

interface Props {
  changeRequestDatail: ChangePlanRequestDetailResponse;
  onClickNext: () => void;
  sendCreateData: (value: ChangePlanRequestCreate) => void;
}

interface CriteriaProps {
  changeRequestDatail: ChangePlanRequestDetailResponse;
  onClickNext: () => void;
  sendData: (value: ChangePlanRequestCreate) => void;
}

export default function PlanInformation(props: Props) {
  const [changeRequestData, setChangeRequestData] = useState<ChangePlanRequestDetailResponse>({} as ChangePlanRequestDetailResponse);

  useEffect(() => {
    if (props.changeRequestDatail.changePlanRequest) {
      setChangeRequestDetailInit(props.changeRequestDatail);
    }
  }, [props.changeRequestDatail]);

  const setChangeRequestDetailInit = (data: ChangePlanRequestDetailResponse) => {
    setChangeRequestData(data);
  };

  return (
    <div className='plan-information'>
      <div>
        <Criteria onClickNext={props.onClickNext}
          sendData={(value) => props.sendCreateData(value)}
          changeRequestDatail={changeRequestData} />
      </div>
    </div>
  );
}

function Criteria(props: CriteriaProps) {
  const [planCreateData, setPlanCreateData] = useState<ChangePlanRequestCreate>({} as ChangePlanRequestCreate);
  const [changePlanRequestData, setChangePlanRequestData] = useState<ChangePlanRequestDetailResponse>({} as ChangePlanRequestDetailResponse);
  const [departmentItems, setDepartmentItems] = useState<ItemModel[]>([]);
  const [supplyMethodTypeItems, setSupplyMethodTypeItems] = useState<ItemModel[]>([]);
  const [supplyMethodNameItems, setSupplyMethodNameItems] = useState<ItemModel[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [onInit, setOnInit] = useState(true);
  const createData = useContext(CreateDataContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (createData) {
      checkContextHasData(createData);
    }
  }, [createData]);

  useEffect(() => {
    getDepartmentItemsAsync();
    getSupplyMethodTypeAsync();
    getSupplyMethodNameAsync();
  }, []);

  const getDepartmentItemsAsync = async () => {
    const response = await department.getDepartmentsAsync();

    if (response.status === HttpStatusCode.OK) {
      const departmentData: DepartmentResponse[] = response.data;
      const items: ItemModel[] = [];

      departmentData.forEach((data) => items.push({ label: data.name, value: data.id ? data.id : '' }));

      setDepartmentItems(items);
    }
  };

  const getSupplyMethodTypeAsync = async () => {
    const response = await shareValue.getSupplyMethodTypeListAsync();

    if (response.status === HttpStatusCode.OK) {
      const supplyMethodTypeItems: ItemModel[] = response.data;
      const items: ItemModel[] = [];

      supplyMethodTypeItems.forEach((data) => items.push({ label: data.label, value: data.value ? data.value : '' }));

      setSupplyMethodTypeItems(items);
    }
  };

  const getSupplyMethodNameAsync = async () => {
    const response = await shareValue.getSupplyMethodListAsync();

    if (response.status === HttpStatusCode.OK) {
      const supplyMethodItems: ItemModel[] = response.data;
      const items: ItemModel[] = [];

      supplyMethodItems.forEach((data) => items.push({ label: data.label, value: data.value ? data.value : '' }));

      setSupplyMethodNameItems(items);
    }
  };

  const setDetailChangePlanDetail = useCallback((data: ChangePlanRequestDetailResponse) => {
    setChangePlanRequestData(data);

    if (data.changePlanRequest.planName && data.changePlanRequest.planBudget && data.changePlanRequest.expectingProcurementAt) {
      setPlanCreateData({ ...planCreateData, planName: data.changePlanRequest.planName, planBudget: parseInt(data.changePlanRequest.planBudget, 10), expectingProcurementAt: new Date(data.changePlanRequest.expectingProcurementAt) });
    }

    if (data.changePlanRequest.annualPlan.documents && files.length === 0) {
      handleSetFile(data.changePlanRequest.annualPlan.documents);
    }
  }, []);

  useEffect(() => {
    const data = props.changeRequestDatail;
    if (data.changePlanRequest) {
      setOnInit(true);
      setDetailChangePlanDetail(data);
      setOnInit(false);

      if (data.changePlanRequest.annualPlan.status === StatusName.APPROVED
        || data.changePlanRequest.annualPlan.status === StatusName.PENDING) {
        setDisabled(true);
      }
    }
  }, [props.changeRequestDatail]);

  const setInitailData = useCallback(() => {
    if (!planCreateData.expectingProcurementAt) {
      setPlanCreateData({ ...planCreateData, expectingProcurementAt: new Date() });
    }
  }, []);

  useEffect(() => {
    setInitailData();
  }, [setInitailData]);

  const checkContextHasData = (data: ChangePlanRequestCreate) => {
    setPlanCreateData({ ...planCreateData, planName: data.planName, planBudget: data.planBudget, expectingProcurementAt: data.expectingProcurementAt });
  };

  const handleOnNext = () => {
    props.sendData(planCreateData);
    props.onClickNext();
  };

  const onSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();

    submitForm();

    if (planCreateData.planName && planCreateData.planBudget && planCreateData.expectingProcurementAt) {
      handleOnNext();
    }
  };

  const handleSetFile = (documents: DocumentFile[]) => {
    documents.forEach((document, index) => {
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    });
  };

  return (
    <div className='criteria mt-2'>
      {!onInit && (
        <Form onSubmit={onSubmit}>
          <Card>
            <div className='d-flex align-items-center gap-3'>
              <div className='parallelogram' />
              <h4 className='mt-2 text-primary'>ข้อมูลเปลี่ยนแปลงแผน</h4>
              <Status value={changePlanRequestData.changePlanRequest.annualPlan.status}
                type={StatusType.PROCESS}
                badge />
            </div>
            <div className='new-plan mt-3'>
              <h5 className='text-decoration-underline'>แผนการจัดซื้อจัดจ้างใหม่</h5>
              <Row className='mt-3'>
                <Col lg={6}>
                  <InputTextArea
                    disabled={disabled}
                    rule={{ required: true }}
                    label='ชื่อโครงการ'
                    value={planCreateData.planName}
                    onChange={(value) => setPlanCreateData({ ...planCreateData, planName: value })}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Currency
                    disabled={disabled}
                    label='งบประมาณโครงการ (บาท)'
                    rule={{ required: true }}
                    value={planCreateData.planBudget ? planCreateData.planBudget : undefined}
                    onChange={(value) => setPlanCreateData({ ...planCreateData, planBudget: value || 0 })}
                  />
                </Col>
                <Col lg={3}>
                  <DatePicker
                    disabled={disabled}
                    monthYearOnly
                    label='ประมาณการช่วงเวลาการจัดซิ้อจัดจ้าง'
                    value={planCreateData.expectingProcurementAt ? new Date(planCreateData.expectingProcurementAt) : undefined}
                    onChange={(value) => setPlanCreateData({ ...planCreateData, expectingProcurementAt: value })}
                  />
                </Col>
              </Row>
            </div>
          </Card>
          <Card className='mt-3'>
            <div className='d-flex align-items-center gap-3'>
              <div className='parallelogram' />
              <h4 className='mt-2 text-primary'>ข้อมูลแผน</h4>
            </div>
            <div className='old-plan'>
              <h5 className='text-decoration-underline'>แผนการจัดซื้อจัดจ้างเดิม</h5>
              <Row>
                <Col lg={3}>
                  <Input label='เลขที่แผน'
                    disabled
                    value={changePlanRequestData.changePlanRequest.annualPlan.planNumber} />
                </Col>
                <Col lg={3}>
                  <Selector label='ฝ่าย/สำนัก'
                    disabled
                    value={changePlanRequestData.changePlanRequest.annualPlan.departmentId}
                    items={departmentItems} />
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Selector label='วิธีการจัดหา'
                    disabled
                    value={changePlanRequestData.changePlanRequest.annualPlan.supplyMethod}
                    items={supplyMethodTypeItems} />
                </Col>
                <Col lg={3}>
                  <Selector label='&nbsp;'
                    disabled
                    value={changePlanRequestData.changePlanRequest.annualPlan.supplyMethodName}
                    items={supplyMethodNameItems} />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <InputTextArea label='ชื่อโครงการ'
                    disabled
                    value={changePlanRequestData.changePlanRequest.annualPlan.planName} />
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Currency label='งบประมาณโครงการ (บาท)'
                    disabled
                    value={changePlanRequestData.changePlanRequest.annualPlan.planBudget} />
                </Col>
                <Col lg={3}>
                  <DatePicker
                    label='ประมาณการช่วงเวลาการจัดซิ้อจัดจ้าง'
                    disabled
                    monthYearOnly
                    value={new Date(changePlanRequestData.changePlanRequest.annualPlan.expectingProcurementAt)}
                  />
                </Col>
                <Col lg={3}>
                  <DatePicker label='วันที่ประกาศแผน'
                    disabled
                    value={new Date(changePlanRequestData.changePlanRequest.annualPlan.planAnnouncementDate)} />
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Input label='เลขที่แนบ e-GP'
                    disabled
                    value={changePlanRequestData.changePlanRequest.annualPlan.planEgpNumber} />
                </Col>
              </Row>
              <p className='mb-0'>เอกสารแนบ</p>
              <Card>
                <div className='d-flex justify-content-center'>
                  <Row>
                    <Col sm={12}>
                      <UploadFile
                        files={files}
                        planId={changePlanRequestData.changePlanRequest.annualPlan.id}
                        handleFileChange={() => {
                        }}
                        removeItem={() => {
                        }}
                        disabled
                      />
                    </Col>
                  </Row>
                </div>
              </Card>
              <Row className='mt-3'>
                <Col lg={3}>
                  <Input label='สร้างโดย'
                    disabled
                    value={changePlanRequestData.changePlanRequest.createUserFullName} />
                </Col>
                <Col lg={3}>
                  <DatePicker label='วันที่สร้าง'
                    disabled
                    value={new Date(changePlanRequestData.changePlanRequest.createAt)} />
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Input label='ผู้ประกาศเผยแพร่'
                    disabled
                    value={changePlanRequestData.changePlanRequest.publishedFullName} />
                </Col>
                <Col lg={3}>
                  <DatePicker label='วันที่เผยแพร่'
                    disabled
                    value={new Date(changePlanRequestData.changePlanRequest.publishedAt)} />
                </Col>
              </Row>
            </div>
            <div className='d-flex justify-content-between mt-3'>
              <ButtonCustom
                onClick={() => navigate(-1)}
                text="ย้อนกลับ"
              />
              <ButtonCustom
                type='submit'
                text="ถัดไป"
              />
            </div>
          </Card>
        </Form>
      )}
    </div>
  );
}
