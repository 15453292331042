import Title from 'components/Controls/Title';
import {
  useContext,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Row,
} from 'react-bootstrap';
import { FaAlignLeft } from 'react-icons/fa';
import {
  MdArrowBack,
  MdHistory,
  MdSave,
} from 'react-icons/md';
import { medianPrice } from 'services';
import { HttpStatusCode } from 'utils';
import toast from 'utils/toast';
import { Context } from '.';
import ModalTORHistory from '../ModalTORHistory';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

export default function Step4({ onClickNext, onClickBack }: Props) {
  const formId = 'pp0204_ref_price_step_3';
  const [showTORHistoryModal, setShowTORHistoryModal] = useState(false);
  const context = useContext(Context);

  // const saveAsync = async () => {
  //   if (context.medianPrice.id) {
  //     const res = await medianPrice.updateAsync(context.medianPrice);

  //     if (res.status === HttpStatusCode.OK) {
  //       toast.success('บันทึกข้อมูลสำเร็จ');
  //     }
  //   } else {
  //     const res = await medianPrice.saveAsync(context.medianPrice);

  //     if (res.status === HttpStatusCode.CREATED) {
  //       toast.success('บันทึกข้อมูลสำเร็จ');
  //       context.setMedianPrice({ ...context.medianPrice, id: res.data.id });
  //     }
  //   }
  // };

  return (
    <>
      <Card className="mt-3">
        <Card.Body>
          <Title
            text="ตัวอย่างเอกสารตารางแสดงวงเงินงบประมาณที่ได้รับจัดสรรและราคากลาง (ราคาอ้างอิง)"
            extraElement={(
              <Button variant="light" onClick={() => { setShowTORHistoryModal(true); }}>
                <MdHistory /> ประวัติการแก้ไข
              </Button>
            )}
            className="fs-5 text-primary"
          />
          <Row className="mt-3">
            <Col>
              <Card style={{ height: 500 }} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-center pt-5 gap-3">
        <Button onClick={onClickBack} variant="light"><MdArrowBack /> ย้อนกลับ</Button>
        <Button
          variant="light"
        // onClick={saveAsync}
        >
          <MdSave /> บันทึก
        </Button>
        <Button variant="primary" type="submit" form={formId}><FaAlignLeft /> เสร็จสิ้น</Button>
      </div>
      <ModalTORHistory show={showTORHistoryModal} setShow={setShowTORHistoryModal} />
    </>
  );
}
