import {
  Check,
  InvitedContractStatus,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  Input,
  Selector,
} from 'components/Controls';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import {
  CountStatusModel,
  InvitedContractListModel,
  InvitedContractRequestListModel,
  contractNumberDataModel,
} from 'models/CM/CM01Models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import CM01Service from 'services/CM/CM01Service';
import { HttpStatusCode } from 'utils';

interface StatusItemModel {
  label: string;
  value: boolean;
}

type Loader = { departmentDDL: ItemModel[] };

const defaultStatus = [InvitedContractStatus.ALL];

export default function CreateContractManagement() {
  const { departmentDDL } = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [dataList, setDataList] = useState<InvitedContractListModel[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [criteria, setCriteria] = useState<InvitedContractRequestListModel>({ pageSize: 10, page: 1 } as InvitedContractRequestListModel);
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [statusDraft, setStatusDraft] = useState<boolean>(false);
  const [statusNone, setStatusNone] = useState<boolean>(false);
  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [statusInvited, setStatusInvited] = useState<boolean>(false);
  const [statusWaitingForApprove, setStatusWaitingForApprove] = useState<boolean>(false);
  const [departmentId, setDepartmentId] = useState('');
  const [isResponsible, setIsResponsible] = useState<boolean>(false);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    {
      value: false,
      label: InvitedContractStatus.WAITINGFORAPPROVE,
    },
    {
      value: false,
      label: InvitedContractStatus.DRAFT,
    },
    {
      value: false,
      label: InvitedContractStatus.NONE,
    },
    {
      value: false,
      label: InvitedContractStatus.INVITED,
    },
    {
      value: false,
      label: InvitedContractStatus.REJECTED,
    },
  ]);

  const goToPage = (id: string, taxId: string) => {
    navigate(`/contract-invitation-sign/detail/${id}/${taxId}`);
  };

  useEffect(() => {
    setStatusAll(true);
    setStatusNone(true);
    setStatusDraft(true);
    setStatusRejected(true);
    setStatusWaitingForApprove(true);
    setStatusInvited(true);
    setIsResponsible(true);
    getListDataAsync(criteria);
  }, []);

  const getListDataAsync = async (searchModel: InvitedContractRequestListModel) => {
    if (searchModel.statuses === undefined || searchModel.statuses.length === 0) {
      searchModel.statuses = defaultStatus;
    }

    if (searchModel.isResponsible === undefined) {
      searchModel.isResponsible = true;
    }

    const res = await CM01Service.getListInvitedContractAsync(
      searchModel,
    );

    if (res.status === HttpStatusCode.OK) {
      setDataList(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }
    getCountStatus(searchModel);
  };

  const getContractNumberLink = (val: string, id: string, taxId: string) => {
    const contractNumbwers = val.split('|');
    return (
      <>
        {
          contractNumbwers.map((val) => (
            // <div><a href=''>{val}</a></div>
            <div>
              <Button variant='link'
                onClick={() => goToPage(id, taxId)}>
                {val}
              </Button>
            </div >
          ))
        }
      </>
    )
  }

  const getCountStatus = useCallback(async (searchModel: InvitedContractRequestListModel) => {
    const countStatusRes = await CM01Service.getCountStatusAsync(
      searchModel,
    );
    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data);
    }
  }, []);

  const onPaginationChange = (size: number, page: number) => {
    setSize(size);
    setPage(page);
    onSearch(size, page);
  };

  const onSearch = (size: number, page: number) => {
    const status: string[] = [];

    if (statusNone) {
      status.push(InvitedContractStatus.NONE);
    }

    if (statusDraft) {
      status.push(InvitedContractStatus.DRAFT);
    }

    if (statusWaitingForApprove) {
      status.push(InvitedContractStatus.WAITINGFORAPPROVE);
    }

    if (statusInvited) {
      status.push(InvitedContractStatus.INVITED);
    }

    if (statusRejected) {
      status.push(InvitedContractStatus.REJECTED);
    }

    const searchInvitedContract = {
      contractNumber: criteria.contractNumber,
      poNumber: criteria.poNumber,
      departmentName: departmentId,
      detail: criteria.detail,
      planName: criteria.planName,
      planNumber: criteria.planNumber,
      traderName: criteria.traderName,
      isResponsible: isResponsible,
      statuses: status,
      page: page,
      pageSize: size,
    } as InvitedContractRequestListModel;
    getListDataAsync(searchInvitedContract);
  };

  const onClearSearch = () => {
    const status: string[] = [];
    const isSize = 10;
    const isPage = 1;
    setPage(isPage);
    setSize(isSize);
    onPaginationChange(isSize, isPage);
    setDepartmentId('');
    setStatusAll(true);
    setStatusDraft(true);
    setStatusInvited(true);
    setStatusNone(true);
    setStatusRejected(true);
    setStatusWaitingForApprove(true);
    setIsResponsible(true);
    setDataList([]);
    setCriteria({
      page: isPage,
      pageSize: isSize,
    } as InvitedContractRequestListModel);
    const searchInvitedContract = {
      page: isPage,
      pageSize: isSize,
      statuses: status,
      isResponsible: true,
    } as InvitedContractRequestListModel;
    getListDataAsync(searchInvitedContract);
  };

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex(s => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter(s => s.value === false).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case InvitedContractStatus.NONE:
        return setStatusNone(value);
      case InvitedContractStatus.DRAFT:
        return setStatusDraft(value);
      case InvitedContractStatus.WAITINGFORAPPROVE:
        return setStatusWaitingForApprove(value);
      case InvitedContractStatus.REJECTED:
        return setStatusRejected(value);
      case InvitedContractStatus.INVITED:
        return setStatusInvited(value);
    }
  };

  const onChangeStatusAll = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setStatusNone(true);
      setStatusDraft(true);
      setStatusRejected(true);
      setStatusWaitingForApprove(true);
      setStatusInvited(true);
    } else {
      setStatusAll(false);
      setStatusNone(false);
      setStatusDraft(false);
      setStatusRejected(false);
      setStatusWaitingForApprove(false);
      setStatusInvited(false);
    }
  };

  return (
    <div className='m01'>
      <Title text='เชิญชวนทำสัญญา' />
      <Row className='criteria mt-3'>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่สัญญา'
            placeholder='เลขที่สัญญา'
            onChange={contractNumber => setCriteria({
              ...criteria,
              contractNumber,
            })}
            value={criteria.contractNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่ PO'
            placeholder='เลขที่ PO'
            onChange={poNumber => setCriteria({
              ...criteria,
              poNumber,
            })}
            value={criteria.poNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่โครงการ'
            placeholder='เลขที่โครงการ'
            onChange={planNumber => setCriteria({
              ...criteria,
              planNumber,
            })}
            value={criteria.planNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='ฝ่าย/สำนัก'
            placeholder='ฝ่าย/สำนัก'
            items={departmentDDL}
            value={`${departmentId}`}
            onChange={(value) => (setDepartmentId(value.toString()))}
          />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='ชื่อโครงการ'
            placeholder='ชื่อโครงการ'
            onChange={planName => setCriteria({
              ...criteria,
              planName,
            })}
            value={criteria.planName} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='ชื่อบริษัท'
            placeholder='ชื่อบริษัท'
            onChange={traderName => setCriteria({
              ...criteria,
              traderName,
            })}
            value={criteria.traderName} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='รายละเอียดพัสดุ'
            placeholder='รายละเอียดพัสดุ'
            onChange={detail => setCriteria({
              ...criteria,
              detail,
            })}
            value={criteria.detail} />
        </Col>
        <Col md={12}>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={(
                  <div className='align-items-center d-flex'>ทั้งหมด <Badge className='h-50 mx-2'
                    bg='light'>
                    {countStatus.filter((x) => x.status === InvitedContractStatus.ALL).length > 0 ? countStatus.filter((x) => x.status === InvitedContractStatus.ALL)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusAll}
                onChange={(val) => onChangeStatusAll(val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>รอดำเนินการ <Badge className='h-50 mx-2'
                    bg='secondary'>
                    {countStatus.filter((x) => x.status === InvitedContractStatus.NONE).length > 0 ? countStatus.filter((x) => x.status === InvitedContractStatus.NONE)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusNone}
                onChange={(val) => onChangeCheckStatus(InvitedContractStatus.NONE, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>แบบร่าง <Badge className='h-50 mx-2'
                    bg='secondary'>
                    {countStatus.filter((x) => x.status === InvitedContractStatus.DRAFT).length > 0 ? countStatus.filter((x) => x.status === InvitedContractStatus.DRAFT)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusDraft}
                onChange={(val) => onChangeCheckStatus(InvitedContractStatus.DRAFT, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>รอเห็นชอบ/รออนุมัติ <Badge className='h-50 mx-2'
                    bg='warning'>
                    {countStatus.filter((x) => x.status === InvitedContractStatus.WAITINGFORAPPROVE).length > 0 ? countStatus.filter((x) => x.status === InvitedContractStatus.WAITINGFORAPPROVE)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusWaitingForApprove}
                onChange={(val) => onChangeCheckStatus(InvitedContractStatus.WAITINGFORAPPROVE, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>เห็นชอบ/อนุมัติ <Badge className='h-50 mx-2'
                    bg='success'>
                    {countStatus.filter((x) => x.status === InvitedContractStatus.INVITED).length > 0 ? countStatus.filter((x) => x.status === InvitedContractStatus.INVITED)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusInvited}
                onChange={(val) => onChangeCheckStatus(InvitedContractStatus.INVITED, val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>ส่งกลับแก้ไข <Badge className='h-50 mx-2'
                    bg='danger'>
                    {countStatus.filter((x) => x.status === InvitedContractStatus.REJECTED).length > 0 ? countStatus.filter((x) => x.status === InvitedContractStatus.REJECTED)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusRejected}
                onChange={(val) => onChangeCheckStatus(InvitedContractStatus.REJECTED, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Row>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Check
              label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
              value={isResponsible}
              onChange={(val) => setIsResponsible(val)}
            />
          </Col>
        </Row>
        <div className='d-flex gap-2'>
          <Button form='formPL01'
            type='submit'
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={() => onSearch(size, page)}>
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={onClearSearch}>
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
      <Row className='mt-5'>
        <Col sm={12}>
          <Table total={totalRecords}
            onChange={(size, page) => {
              onPaginationChange(size, page);
            }}>
            <thead>
              <tr>
                <th style={{ width: 50 }}>เลขที่สัญญา</th>
                <th style={{ width: 50 }}>เลขที่ PO</th>
                <th style={{ minWidth: 50 }}>สถานะ</th>
                <th style={{ minWidth: 50 }}>เลขที่โครงการ</th>
                <th style={{ minWidth: 50 }}>ชื่อโครงการ</th>
                <th style={{ minWidth: 50 }}>ผู้ประกอบการ/<br />
                  คู่ค้า
                </th>
              </tr>
            </thead>
            <tbody>
              {dataList?.map((data, index) => (
                <tr className='text-center'
                  key={`inv-${data.id}${data.entrepreneursTaxId}`}>
                  <td>
                    {getContractNumberLink(data.contractNumber, data.id, data.entrepreneursTaxId)}
                  </td>
                  <td>
                    {getContractNumberLink(data.poNumber, data.id, data.entrepreneursTaxId)}
                  </td>
                  <td>
                    <Status value={data.status}
                      type={StatusType.INVITEDCONTRACTSTATUS} />
                  </td>
                  <td >{data.planNumber}</td>
                  <td className='text-start text-wrap'>{data.planName}</td>
                  <td className='text-start'>{data.traderName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row >
    </div >
  );
}
