import {
  Check,
  DatePicker,
  Input,
  ProcessStatus,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  changePlanRequestCountStatus,
  DepartmentResponse,
  GetApproveListResponse,
  GetListParams,
} from 'models';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import {
  approvePlan,
  department,
} from 'services';
import {
  expectingProcurementThaiFormant,
  HttpStatusCode,
  isoDate,
  thaiFormatDate,
  THCurrency,
} from 'utils';
import toast from 'utils/toast';

type Loader = { approveList: { data: GetApproveListResponse[], totalRecords: number }, countStatus: changePlanRequestCountStatus };

interface ItemModel {
  label: string;
  value: string;
}

interface CriteriaProps {
  onClearData: (data: GetListParams) => void;
  searchOnClick: () => void;
  approveOnClick: () => void;
  checkedList: CheckedApprove[];
  setCheckedList: Dispatch<SetStateAction<CheckedApprove[]>>;
}

interface CheckedApprove {
  planId: string;
  planStatus: string;
}

interface PL0301Context {
  checkedList: CheckedApprove[];
  setCheckedList: Dispatch<SetStateAction<CheckedApprove[]>>;
  criteria: GetListParams;
  setCriteria: Dispatch<SetStateAction<GetListParams>>;
  approveList: GetApproveListResponse[];
  totalRecords: number;
  size: number;
  setSize: Dispatch<SetStateAction<number>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const Context = createContext({} as PL0301Context);

export default function PL0301() {
  const loader = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<GetListParams>({
    allPlanStatus: true,
    annualPlanStatus: true,
    inYearPlanStatus: true,
    changePlanStatus: true,
    cancelPlanStatus: true,
    allStatus: true,
    waitingApprovePlan: true,
    approvePlan: true,
    rejectPlan: true,
  } as GetListParams);
  const [checkedList, setCheckedList] = useState<CheckedApprove[]>([]);
  const [approveList, setApproveList] = useState<GetApproveListResponse[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const contextValue = {
    checkedList,
    setCheckedList,
    criteria,
    setCriteria,
    approveList,
    totalRecords,
    size,
    setSize,
    page,
    setPage,
  };

  useEffect(() => {
    if (loader.approveList.data.length > 0) {
      setApproveList(loader.approveList.data);
      setTotalRecords(loader.approveList.totalRecords);
    }
  }, []);

  const searchOnClick = () => {
    getApproveListAsync();
  };

  const getApproveListAsync = useCallback(async (criteriaData?: GetListParams) => {
    const response = await approvePlan.getApproveListAsync(page, size, criteriaData || criteria);

    if (response.status === HttpStatusCode.OK) {
      setApproveList(response.data.data);
      setTotalRecords(response.data.totalRecords);
    }
  }, [criteria]);

  const approveAsync = async () => {
    if (checkedList.length === 0) {
      toast.warn('ต้องเลือกอย่างน้อย 1 แผน');

      return;
    }

    const res = await approvePlan.updateApproveStatusListAsync(checkedList);

    if (res.status === HttpStatusCode.NO_CONTENT) {
      toast.success('อนุมัติสำเร็จ');

      setCheckedList([] as CheckedApprove[]);
      searchOnClick();
    } else {
      toast.success('อนุมัติไม่สำเร็จ');
    }
  };

  return (
    <Context.Provider value={contextValue}>
      <div className='pl0301'>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='text-primary m-0'>อนุมัติแผน (ผอ.ฝ่าย)</h4>
        </div>
        <Criteria
          searchOnClick={() => searchOnClick()}
          approveOnClick={() => approveAsync()}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          onClearData={(data) => getApproveListAsync(data)}
        />
        <DataTable />
      </div>
    </Context.Provider>
  );
}

function Criteria(props: CriteriaProps) {
  const [departmentList, setDepartmentList] = useState<ItemModel[]>([]);
  const loader = useLoaderData() as Loader;
  const [statusCount, setStatusCount] = useState<changePlanRequestCountStatus>({} as changePlanRequestCountStatus);
  const context = useContext(Context);

  useEffect(() => {
    getDepartmentItemsAsync();
    setStatusCount(loader.countStatus);
  }, []);

  useEffect(() => {
    checkAllPlanStatus();
  }, [
    context.criteria.annualPlanStatus,
    context.criteria.inYearPlanStatus,
    context.criteria.changePlanStatus,
    context.criteria.cancelPlanStatus,
  ]);

  useEffect(() => {
    checkAllStatus();
  }, [context.criteria.waitingApprovePlan, context.criteria.approvePlan, context.criteria.rejectPlan]);

  const getDepartmentItemsAsync = async () => {
    const response = await department.getDepartmentsAsync();

    if (response.status === HttpStatusCode.OK) {
      const departmentData: DepartmentResponse[] = response.data;
      const items: ItemModel[] = [];

      departmentData.forEach((data) => items.push({ label: data.name, value: data.id ? data.id : '' }));

      setDepartmentList(items);
    }
  };

  const clearCriteriaData = () => {
    const criteriaClearData = {
      allPlanStatus: true,
      annualPlanStatus: true,
      inYearPlanStatus: true,
      changePlanStatus: true,
      cancelPlanStatus: true,
      allStatus: true,
      waitingApprovePlan: true,
      approvePlan: true,
      rejectPlan: true,
    } as GetListParams;

    context.setCriteria(criteriaClearData);
    props.onClearData(criteriaClearData);
  };

  const onCheckAllPlanStatusCriteria = (value: boolean) => {
    if (value) {
      context.setCriteria({
        ...context.criteria,
        allPlanStatus: value,
        annualPlanStatus: value,
        inYearPlanStatus: value,
        changePlanStatus: value,
        cancelPlanStatus: value,
      });
    } else {
      context.setCriteria({
        ...context.criteria,
        allPlanStatus: false,
        annualPlanStatus: false,
        inYearPlanStatus: false,
        changePlanStatus: false,
        cancelPlanStatus: false,
      });
    }
  };

  const checkAllPlanStatus = () => {
    if (context.criteria.annualPlanStatus
      && context.criteria.inYearPlanStatus
      && context.criteria.changePlanStatus
      && context.criteria.cancelPlanStatus) {
      context.setCriteria({ ...context.criteria, allPlanStatus: true });
    }

    if (!context.criteria.annualPlanStatus
      || !context.criteria.inYearPlanStatus
      || !context.criteria.changePlanStatus
      || !context.criteria.cancelPlanStatus) {
      context.setCriteria({ ...context.criteria, allPlanStatus: false });
    }
  };

  const onCheckAllStatusCriteria = (value: boolean) => {
    if (value) {
      context.setCriteria({ ...context.criteria, allStatus: value, waitingApprovePlan: value, approvePlan: value, rejectPlan: value });
    } else {
      context.setCriteria({ ...context.criteria, allStatus: false, waitingApprovePlan: false, approvePlan: false, rejectPlan: false });
    }
  };

  const checkAllStatus = () => {
    if (context.criteria.waitingApprovePlan && context.criteria.approvePlan && context.criteria.rejectPlan) {
      context.setCriteria({ ...context.criteria, allStatus: true });
    }

    if (!context.criteria.waitingApprovePlan || !context.criteria.approvePlan || !context.criteria.rejectPlan) {
      context.setCriteria({ ...context.criteria, allStatus: false });
    }
  };

  return (
    <div className='criteria mt-4'>
      <Row>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='เลขแผนที่'
            value={context.criteria.planNumber}
            onChange={(value) => context.setCriteria({ ...context.criteria, planNumber: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Selector
            label='ฝ่าย/สำนัก'
            items={departmentList}
            value={context.criteria.departmentId}
            onChange={(value) => context.setCriteria({ ...context.criteria, departmentId: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='ชื่อแผน'
            value={context.criteria.planName}
            onChange={(value) => context.setCriteria({ ...context.criteria, planName: value })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='งบประมาณโครงการ'
            value={context.criteria.planBudget}
            onChange={(value) => context.setCriteria({ ...context.criteria, planBudget: value ? parseInt(value, 10) : undefined })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker
            monthYearOnly
            label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
            value={context.criteria.expectingProcurementAt ? new Date(context.criteria.expectingProcurementAt) : undefined}
            onChange={(value) => context.setCriteria({ ...context.criteria, expectingProcurementAt: isoDate(new Date(value)) })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker
            label='วันที่ประกาศแผน'
            value={context.criteria.planAnnouncementDate ? new Date(context.criteria.planAnnouncementDate) : undefined}
            onChange={(value) => context.setCriteria({ ...context.criteria, planAnnouncementDate: isoDate(new Date(value)) })}
          />
        </Col>
        <Col sm={6}
          md={4}
          lg={4}
          xl={3}>
          <Input
            label='ผู้จัดทำ'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>ประเภท</Form.Label>
            <InputGroup>
              <Check
                label={<p>ทั้งหมด<Badge className='h-50 mx-2'
                  bg='danger'>{statusCount.countPlanAll}</Badge></p>}
                value={context.criteria.allPlanStatus}
                onChange={(value) => onCheckAllPlanStatusCriteria(value)}
              />
              <Check
                label={<p>จัดทำแผนรวมปี<Badge className='h-50 mx-2'
                  bg='secondary'>{statusCount.countAnnualPlan}</Badge></p>}
                value={context.criteria.annualPlanStatus}
                onChange={(value) => context.setCriteria({ ...context.criteria, annualPlanStatus: value })}
              />
              <Check
                label={<p>จัดทำแผนระหว่างปี<Badge className='h-50 mx-2'
                  bg='secondary'>{statusCount.countInYearPlan}</Badge></p>}
                value={context.criteria.inYearPlanStatus}
                onChange={(value) => context.setCriteria({ ...context.criteria, inYearPlanStatus: value })}
              />
              <Check
                label={<p>เปลี่ยนแปลงแผน<Badge className='h-50 mx-2'
                  bg='primary'>{statusCount.countChangePlan}</Badge></p>}
                value={context.criteria.changePlanStatus}
                onChange={(value) => context.setCriteria({ ...context.criteria, changePlanStatus: value })}
              />
              <Check
                label={<p>ยกเลิกแผน<Badge className='h-50 mx-2'
                  bg='danger'>{statusCount.countCancelPlan}</Badge></p>}
                value={context.criteria.cancelPlanStatus}
                onChange={(value) => context.setCriteria({ ...context.criteria, cancelPlanStatus: value })}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ</Form.Label>
            <InputGroup>
              <Check
                label={<p>ทั้งหมด<Badge className='h-50 mx-2'
                  bg='danger'>{statusCount.countAll}</Badge></p>}
                value={context.criteria.allStatus}
                onChange={(value) => onCheckAllStatusCriteria(value)}
              />
              <Check
                label={<p>รออนุมัติจากหัวหน้าส่วน<Badge className='h-50 mx-2'
                  bg='primary'>{statusCount.countPending}</Badge></p>}
                value={context.criteria.waitingApprovePlan}
                onChange={(value) => context.setCriteria({ ...context.criteria, waitingApprovePlan: value })}
              />
              <Check
                label={<p>หัวหน้าส่วนอนุมัติ<Badge className='h-50 mx-2'
                  bg='success'>{statusCount.countApproved}</Badge></p>}
                value={context.criteria.approvePlan}
                onChange={(value) => context.setCriteria({ ...context.criteria, approvePlan: value })}
              />
              <Check
                label={<p>ส่งกลับแก้ไขโดยหัวหน้าส่วน<Badge className='h-50 mx-2'
                  bg='danger'>{statusCount.countReject}</Badge></p>}
                value={context.criteria.rejectPlan}
                onChange={(value) => context.setCriteria({ ...context.criteria, rejectPlan: value })}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <div className='d-flex gap-2 button-inline justify-content-between'>
        <div className='criteria-control d-flex gap-2'>
          <Button
            type='submit'
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={props.searchOnClick}
          >
            <FaSearch />ค้นหา
          </Button>
          <Button
            variant='outline-primary'
            className='d-flex align-items-center gap-2'
            onClick={clearCriteriaData}
          >
            <FaEraser />ล้าง
          </Button>
        </div>
        <div>
          <Button
            type='submit'
            variant='primary'
            className='d-flex align-items-center gap-2'
            onClick={props.approveOnClick}
          >
            <FaAlignLeft />อนุมัติ
          </Button>
        </div>
      </div>
    </div>
  );
}

function DataTable() {
  const navigate = useNavigate();
  const context = useContext(Context);

  const navigateToDetail = (id: string, planStatus: string) => {
    navigate(`detail/${id}/${planStatus}`);
  };

  const checkboxOnChange = (planId: string, planStatus: string, value: boolean) => {
    if (value) {
      context.setCheckedList([...context.checkedList, { planId, planStatus } as CheckedApprove]);
    } else {
      const list = [...context.checkedList];
      const index = list.findIndex((l) => l.planId === planId);

      if (index >= 0) {
        list.splice(index, 1);

        context.setCheckedList(list);
      }
    }
  };

  return (
    <div className='data-table mt-5'>
      <Table total={context.totalRecords}
        onChange={(size, page) => (context.setSize(size), context.setPage(page))}>
        <thead>
          <tr>
            <th style={{ minWidth: 50 }} />
            <th style={{ minWidth: 100 }}>เลขที่แผน</th>
            <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 300, width: 500 }}>ชื่อแผน</th>
            <th style={{ minWidth: 100 }}>งบประมาณ (บาท)</th>
            <th style={{ minWidth: 100 }}>วิธีจัดหา</th>
            <th style={{ minWidth: 100 }}>ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง</th>
            <th style={{ minWidth: 100 }}>วันที่ประกาศแผน</th>
            <th style={{ minWidth: 100 }}>สถานะโครงการ</th>
            <th style={{ minWidth: 100 }}>สถานะ</th>
          </tr>
        </thead>
        <tbody>
          {context.approveList.map((data, index) => (
            <tr key={data.id}>
              <td className='text-center'>
                <div className='mx-3'>
                  <Check
                    disabled={data.status === ProcessStatus.REJECTPLAN || data.status === ProcessStatus.APPROVEPLAN}
                    onChange={(val) => checkboxOnChange(data.id, data.planStatus, val)}
                    value={context.checkedList.some((c) => c.planId === data.id)}
                  />
                </div>
              </td>
              <td
                className='text-center'
                onClick={(() => navigateToDetail(data.id, data.planStatus))}
              >
                <Button variant='link'>{data.planNumber}</Button>
              </td>
              <td>{data.department}</td>
              <td className='text-wrap'>{data.planName}</td>
              <td className='text-end'>{THCurrency(data.planBudget)}</td>
              <td className='text-center'>{data.supplyMethod}</td>
              <td className='text-center'>{expectingProcurementThaiFormant(data.expectingProcurementAt)}</td>
              <td className='text-center'>{thaiFormatDate(data.planAnnouncementDate)}</td>
              <td className='text-center'><Status type={StatusType.PROJECT}
                value={data.planStatus} /></td>
              <td className='text-center'><Status type={StatusType.PROCESS}
                value={data.status} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
