export const dataSearchName = [
  {
    no: '1',
    id: '5600025',
    name: 'สมหญิง รักษ์เมืองไทย',
    class: '',
    position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร',
  },
  {
    no: '2',
    id: '5600025',
    name: 'สมหญิง รักษ์เมืองไทย',
    class: '',
    position: 'ผู้ช่วยผู้อำนวยการฝ่ายสื่อสารองค์กร',
  },
];

export const dataHistory = [
  {
    remark: '',
    action: 'สร้างข้อมูล',
    activity: 'สร้างข้อมูล จพ.006',
    fullNameAction: 'สมหญิง รักษ์เมืองไทย',
    activityDate: new Date(),
  },
  {
    remark: '',
    action: 'อัพเดทข้อมูล',
    activity: 'อัพเดทข้อมูล จพ.006',
    fullNameAction: 'สมหญิง รักษ์เมืองไทย',
    activityDate: new Date(),
  },
];
