import { CriteriaPlanProcurementList } from 'models';
import {
  planProcurement,
  shareValue,
} from 'services';

export const cancelPlanProcurementLoader = async () => {
  const [department, SupplyMethod, cancelPlanData, count] = await Promise.all([
    shareValue.GetDDL('Department'),
    shareValue.GetDDL('SupplyMethod'),
    planProcurement.getCancelPlanAsync(1, 10, { isAssignToMeOnly: true, isCanceled: true } as CriteriaPlanProcurementList),
    planProcurement.getPlanCancelStatusCount(1, 10, { isAssignToMeOnly: true, isCanceled: true } as CriteriaPlanProcurementList),
  ]);
  const SupplyMethodAll = [{ label: 'ทั้งหมด', value: 'All' }, ...SupplyMethod];
  return {
    departmentDDL: department,
    supplyMethodDDL: SupplyMethodAll,
    cancelPlanData: cancelPlanData.data.data,
    cancelPlanTotal: cancelPlanData.data.totalRecords,
    statusCount: count.data,
  };
};
