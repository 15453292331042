import { Breadcrumb } from 'components';
import StepProgress from 'components/StepProgress';
import { SearchPlanDetailModel } from 'models/planAnnouncement';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import planAnnouncement from 'services/planAnnouncement';
import toast from 'utils/toast';
import PL0302Data from './Data';
import PL0302Document from './Document';

const BREADCRUMB_INFO = [
  { routerName: '/pl/pl0302', label: 'เผยแพร่' },
  { routerName: '', label: 'รายละเอียดเผยแพร่' },
];

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

interface Stepper {
  title: JSX.Element;
  displayIndex: string;
  child: JSX.Element;
}

function PL0302Detail() {
  const [stepper, setStepper] = useState<Stepper[]>([]);
  const childRef = useRef<ProgressBarRefType>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const planIds = searchParams.get('ids')?.split(',');

    if (planIds) {
      getDetailAsync(planIds);
    }
  }, [searchParams]);

  const getDetailAsync = async (planIds: string[]) => {
    const searchDto: SearchPlanDetailModel = {
      page: 1,
      size: 100,
      planIds,
    };

    const { data, status } = await planAnnouncement.getPlansDetailAsync(searchDto);

    if (status !== 200) {
      toast.error('เกิดข้อผิดพลาด');
      return;
    }

    setStepper(
      [
        {
          title: <p>ข้อมูลแผนการจัดซื้อจัดจ้าง</p>,
          displayIndex: '1',
          child:
  <PL0302Data
    data={data}
    onClickNext={() => clickNext()}
  />,
        },
        {
          title: <p>ตัวอย่างเอกสาร</p>,
          displayIndex: '2',
          child:
  <PL0302Document
    data={data}
    planIds={planIds}
    onClickBack={() => clickBack()}
  />,
        },
      ],
    );
  };

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  return (
    <div className="document">
      <h4 className="mt-2 mb-0 text-primary text-start">รายละเอียดเผยแพร่</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12} className="mb-2">
          <StepProgress progressBarData={stepper} ref={childRef} />
        </Col>
      </Row>
    </div>
  );
}

export default PL0302Detail;
