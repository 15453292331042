import { JorPor05Criteria, JorPorO5 } from 'models';
import qs from 'qs';
import http from 'utils/axios';

const getListDataAsync = async (size: number, page: number, criteria?: JorPor05Criteria) => {
  const params = {
    size,
    page,
    ...criteria
  };

  return await http.get('jorpor05', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getDetailAsync = async (basicInformationId: string) => {
  return await http.get(`jorpor05/${basicInformationId}`);
};

const saveAsync = async (data: JorPorO5) => {
  let runNumber = 1;
  const formData = new FormData();
  formData.append('basicInformationId', data.basicInformationId || '');
  formData.append('planName', data.planName || '');
  formData.append('financialAmount', data.financialAmount ? data.financialAmount.toString() : '');
  formData.append('departmentId', data.departmentId || '');
  formData.append('supplyMethod', data.supplyMethod || '');
  formData.append('supplyMethodType', data.supplyMethodType || '');
  formData.append('supplyMethodSpecialType', data.supplyMethodSpecialType || '');
  formData.append('expectingProcurementAt', data.expectingProcurementAt ? new Date(data.expectingProcurementAt).toISOString() : '');
  formData.append('sourceAndReasons', data.sourceAndReasons || '');
  formData.append('documentNumber', data.documentNumber || '');
  formData.append('prNumber', data.prNumber || '');
  formData.append('considerationDescription', data.considerationDescription || '');
  formData.append('considerationSelect', data.considerationSelect || '');
  formData.append('selectionCriteria', data.selectionCriteria || '');
  formData.append('status', data.status);
  formData.append('budgetCode', data.budgetCode || '');
  formData.append('departmentsBudget', data.departmentsBudget || '');
  formData.append('accountNumber', data.accountNumber || '');
  formData.append('biddingResultConsiderPeriodDay', data.biddingResultConsiderPeriodDay ? data.biddingResultConsiderPeriodDay.toString() : '');
  formData.append('biddingResultConsiderPeriodUnit', data.biddingResultConsiderPeriodUnit || '');
  formData.append('biddingResultConsiderPeriodType', data.biddingResultConsiderPeriodType || '');

  data.committees?.forEach((c, i) => {
    formData.append(`committees[${i}][committeeType]`, c.committeeType || '');
    formData.append(`committees[${i}][userId]`, c.userId || '');
    formData.append(`committees[${i}][positionOnBoardId]`, c.positionOnBoardId || '');
    formData.append(`committees[${i}][positionName]`, c.positionName || '');
  });

  data.acceptors?.forEach((a, i) => {
    formData.append(`acceptors[${i}][sectionCode]`, a.sectionCode || '');
    formData.append(`acceptors[${i}][userId]`, a.userId || '');
    formData.append(`acceptors[${i}][sequence]`, a.sequence ? a.sequence.toString() : '');
    formData.append(`acceptors[${i}][positionName]`, a.positionName || '');
  });

  data.dutyProcurements?.forEach((a, i) => {
    formData.append(`dutyProcurements[${i}][sequence]`, a.sequence ? a.sequence.toString() : '');
    formData.append(`dutyProcurements[${i}][duty]`, a.duty || '');
  });

  data.dutyInspectors?.forEach((a, i) => {
    formData.append(`dutyInspectors[${i}][sequence]`, a.sequence ? a.sequence.toString() : '');
    formData.append(`dutyInspectors[${i}][duty]`, a.duty || '');
  });

  data.dutyMAs?.forEach((a, i) => {
    formData.append(`dutyMAs[${i}][sequence]`, a.sequence ? a.sequence.toString() : '');
    formData.append(`dutyMAs[${i}][duty]`, a.duty || '');
  });

  data.dutySupervisors?.forEach((a, i) => {
    formData.append(`dutySupervisors[${i}][sequence]`, a.sequence ? a.sequence.toString() : '');
    formData.append(`dutySupervisors[${i}][duty]`, a.duty || '');
  });

  data.documents?.forEach((d) => {
    formData.append('documents', d.file);
  });

  data.glAccounts?.forEach((c, i) => {
    formData.append(`glAccounts[${i}][amount]`, c.amount ? c.amount.toString() : '');
    formData.append(`glAccounts[${i}][departmentCode]`, c.departmentCode ? c.departmentCode.toString() : '');
    formData.append(`glAccounts[${i}][glAccountId]`, c.glAccountId ? c.glAccountId.toString() : '');
    formData.append(`glAccounts[${i}][gLAccountName]`, c.gLAccountName ? c.gLAccountName.toString() : '');
    formData.append(`glAccounts[${i}][jorPor05Id]`, c.jorpor05Id ? c.jorpor05Id.toString() : '');
    formData.append(`glAccounts[${i}][sequence]`, runNumber.toString()!);
    runNumber += 1;
  });

  return await http.post('jorpor05', formData);
};

const updateAsync = async (data: JorPorO5) => {
  return await http.put(`/jorpor05/${data.id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const actionAsync = async (id: string, sectionCode: string, act: string, acceptorId?: string, remark?: string) => {
  return await http.put(`jorpor05/action/${id}`, { sectionCode, act, remark, acceptorId });
};

const getCountStatusAsync = async (params?: JorPor05Criteria) => {
  return await http.get('jorpor05/countstatus', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getDocumentAsync = (id: string, isReadOnly: boolean) => {
  return http.get(`jorpor05/${id}/document/${isReadOnly}`);
};

const getDocumentOrderAsync = (id: string, isReadOnly: boolean) => {
  return http.get(`jorpor05/${id}/documentOrder/${isReadOnly}`);
};

const uploadFileAsync = async (id: string, file: File) =>
  await http.put(
    `/jorpor05/${id}/uploadfile`,
    {
      file,
      fileName: file.name
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

const removeFileAsync = async (jorpor05Id: string, id: string) => await http.delete(`/jorpor05/${jorpor05Id}/deletefile/${id}`);

const downloadFileAsync = async (jorpor05Id: string, documentId: string) =>
  await http.get(`/jorpor05/${jorpor05Id}/documents/${documentId}`, {
    responseType: 'blob'
  });

export default {
  getListDataAsync,
  getDetailAsync,
  saveAsync,
  updateAsync,
  actionAsync,
  getCountStatusAsync,
  getDocumentAsync,
  getDocumentOrderAsync,
  uploadFileAsync,
  removeFileAsync,
  downloadFileAsync
};
