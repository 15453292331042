import {
  AssignParams,
  ItemModel,
} from 'models';
import { LoaderFunctionArgs } from 'react-router';
import { shareValue } from 'services';
import assign from 'services/assign';

export const PL0305Loader = async () => {
  const criteriaData: AssignParams = { supplyMethodAll: true, statusAll: true } as AssignParams;
  const response = await assign.getAssignListAsync(1, 10, criteriaData);

  const [SupplyMethodTypeItems, SupplyMethodNameItems] = await Promise.all([
    GetDDL('SupplyMethodType'),
    GetDDL('SupplyMethodName'),
  ]);

  return {
    assignLists: response.data.data,
    assignTotalRecords: response.data.totalRecords,
    supplyMethodTypeList: SupplyMethodTypeItems,
    supplyMethodTypeNameList: SupplyMethodNameItems,
  };
};

const GetDDL = async (Type: string) => {
  const itemForMap: ItemModel[] = [];
  if (Type === 'SupplyMethodType') {
    const supplyMethodTypeListRes = await shareValue.getSupplyMethodTypeListAsync();
    const supplyMethodTypeData: ItemModel[] = supplyMethodTypeListRes.data;
    supplyMethodTypeData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
  } else if (Type === 'SupplyMethodName') {
    const supplyMethodListRes = await shareValue.getSupplyMethodListAsync();
    const supplyMethodListData: ItemModel[] = supplyMethodListRes.data;
    supplyMethodListData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ? data.value : '' }));
  }
  return itemForMap;
};

export const PL0305DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;

  if (id) {
    const detail = await assign.getAssignDetailAsync(id);

    return {
      assignDetail: detail.data,
    };
  }
};
