import {
} from 'models/CM/CM01Models';
import { ContractAuditModel, ContractAuditRequestListModel, CountStatusModel } from 'models/CM/CM04Models';
import http from 'utils/axios';

const qs = require('qs');

const api = '/contractAudit';

const getListContractAuditAsync = (searchData: ContractAuditRequestListModel) => {
  const params = {
    page: searchData.page,
    pageSize: searchData.pageSize,
    contractAuditNumber: searchData.contractAuditNumber,
    documentDate: searchData.documentDate,
    contractStartDate: searchData.contractStartDate,
    contractEndDate: searchData.contractEndDate,
    status: searchData.status,
    isAssignToMeOnly: searchData.isAssignToMeOnly,
  };

  return http.get(`${api}/pagination`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountStatusAsync = (searchData: ContractAuditRequestListModel) => {
  const params = {
    page: searchData.page,
    pageSize: searchData.pageSize,
    contractAuditNumber: searchData.contractAuditNumber,
    documentDate: searchData.documentDate,
    contractStartDate: searchData.contractStartDate,
    contractEndDate: searchData.contractEndDate,
    status: searchData.status,
    isAssignToMeOnly: searchData.isAssignToMeOnly,
  };
  return http.get<CountStatusModel[]>(`${api}/countStatus`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const createContractAuditAsync = (body: ContractAuditModel) => {
  const formData = new FormData();

  formData.append('documentDate', new Date(body.documentDate).toISOString());
  formData.append('contractStartDate', new Date(body.contractStartDate).toISOString());
  formData.append('contractEndDate', new Date(body.contractEndDate).toISOString());
  formData.append('remark', new Date(body.contractEndDate).toISOString());

  // if (body.files && body.files.length !== 0) {
  //   body.files.forEach((file) => {
  //     formData.append('files', file);
  //   });
  // }

  if (body.contractAuditListSelecteds && body.contractAuditListSelecteds.length !== 0) {
    body.contractAuditListSelecteds.forEach((data, index) => {
      formData.append(`contractAuditListSelecteds[${index}][contractAgreementId]`, data.contractAgreementId);
      formData.append(`contractAuditListSelecteds[${index}][remark]`, data.remark ? data.remark : '');
      formData.append(`contractAuditListSelecteds[${index}][isOverContractDate]`, data.isOverContractDate ? 'true' : 'false');
      formData.append(`contractAuditListSelecteds[${index}][sequence]`, (index + 1).toString());
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}`, formData, config);
};

const updateContractAuditAsync = (body: ContractAuditModel) => {
  const formData = new FormData();

  // formData.append('id', body.id);
  formData.append('documentDate', new Date(body.documentDate).toISOString());
  formData.append('contractStartDate', new Date(body.contractStartDate).toISOString());
  formData.append('contractEndDate', new Date(body.contractEndDate).toISOString());
  formData.append('remark', body.remark ? body.remark : '');
  formData.append('DocumentId', body.documentId ? body.documentId : '');
  formData.append('DocumentAuditorId', body.documentAuditorId ? body.documentAuditorId : '');
  formData.append('DocumentDirectorId', body.documentDirectorId ? body.documentDirectorId : '');

  // if (body.files && body.files.length !== 0) {
  //   body.files.forEach((file) => {
  //     formData.append('files', file);
  //   });
  // }

  if (body.contractAuditListSelecteds && body.contractAuditListSelecteds.length !== 0) {
    body.contractAuditListSelecteds.forEach((data, index) => {
      if (data.id) {
        formData.append(`contractAuditListSelecteds[${index}][id]`, data.id);
      }
      formData.append(`contractAuditListSelecteds[${index}][contractAgreementId]`, data.contractAgreementId);
      formData.append(`contractAuditListSelecteds[${index}][remark]`, data.remark ? data.remark : '');
      formData.append(`contractAuditListSelecteds[${index}][isOverContractDate]`, data.isOverContractDate ? 'true' : 'false');
      formData.append(`contractAuditListSelecteds[${index}][sequence]`, (index + 1).toString());
    });
  }

  if (body.acceptors && body.acceptors.length !== 0) {
    body.acceptors.forEach((data, index) => {
      if (data.id) {
        formData.append(`acceptors[${index}][id]`, data.id);
      }
      formData.append(`acceptors[${index}][userId]`, data.userId);
      formData.append(`acceptors[${index}][positionName]`, data.positionName ? data.positionName : '');
      formData.append(`acceptors[${index}][sectionType]`, data.sectionType ? data.sectionType : '');
      formData.append(`acceptors[${index}][sectionSequence]`, data.sectionSequence.toString());
      formData.append(`acceptors[${index}][sequence]`, (index + 1).toString());
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${body.id}`, formData, config);
};

const getContractAuditByIdAsync = async (id: string) => {
  return http.get(`${api}/${id}`);
};

const onPendingAsync = async (id: string, documentId: string, docIdAuditor: string, docIdDirector: string) => {
  const body = {
    documentId: documentId,
    documentAuditorId: docIdAuditor,
    documentDirectorId: docIdDirector,
  };

  return await http.put(`${api}/${id}/pending`, body)
};

const onReCallAsync = (id: string) => http.put(`${api}/${id}/recall`);

const onApproveAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };
  return await http.put(`${api}/${id}/approve`, body);
};

const onRejectAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };
  return await http.put(`${api}/${id}/reject`, body);
};

const getContractAuditListSelectedAsync = (documentDate: Date, contractStartDate: Date, contractEndDate: Date, id?: string) => {

  const params = {
    documentDate: documentDate,
    contractStartDate: contractStartDate,
    contractEndDate: contractEndDate,
    contractAuditId: id,
  };

  return http.get(`${api}/contract-agreement-list`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const saveRemarkSelectedAsync = async (id: string, auditListSelectedId: string, remark: string) => {
  const body = {
    remark,
  };
  return await http.put(`${api}/${id}/update-audit-selected-remark/${auditListSelectedId}`, body);
};

const uploadAttachmentAuditListSelectedAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/contract-audit-selected/${id}/attachment`, formData, config);
};

const removeAttachmentAuditListSelectedAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/contract-audit-selected/${id}/attachment/${attachmentId}`);

const getAttachmentAuditListSelectedByIdAsync = async (id: string) =>
  await http.get(`${api}/contract-audit-selected/${id}/attachment`);

const getDownloadAttachmentAuditListSelectedAsync = (id: string, attachmentId: string) => http.get(`${api}/contract-audit-selected/${id}/download-file/${attachmentId}`, {
  responseType: 'blob',
});

const uploadAttachmentAuditAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/${id}/attachment`, formData, config);
};

const removeAttachmentAuditAsync = async (id: string, attachmentId: string) => await http.delete(`${api}/${id}/attachment/${attachmentId}`);

const getAttachmentAuditByIdAsync = async (id: string) =>
  await http.get(`${api}/${id}/attachment`);

const getDownloadAttachmentSelectedAsync = (id: string, attachmentId: string) => http.get(`${api}/${id}/download-file/${attachmentId}`, {
  responseType: 'blob',
});

const getDocumentAsync = (id: string) => {
  return http.get(`${api}/${id}/document`);
};

const getDocumentAuditorAsync = (id: string) => {
  return http.get(`${api}/${id}/document-auditor-general`);
};

const getDocumentDirectorAsync = (id: string) => {
  return http.get(`${api}/${id}/document-director-general`);
};

const CM04Service = {
  getListContractAuditAsync,
  getContractAuditByIdAsync,
  getCountStatusAsync,
  onPendingAsync,
  onApproveAsync,
  onRejectAsync,
  getContractAuditListSelectedAsync,
  createContractAuditAsync,
  updateContractAuditAsync,
  saveRemarkSelectedAsync,
  uploadAttachmentAuditListSelectedAsync,
  removeAttachmentAuditListSelectedAsync,
  getAttachmentAuditListSelectedByIdAsync,
  onReCallAsync,
  getDownloadAttachmentAuditListSelectedAsync,
  uploadAttachmentAuditAsync,
  removeAttachmentAuditAsync,
  getAttachmentAuditByIdAsync,
  getDownloadAttachmentSelectedAsync,
  getDocumentAsync,
  getDocumentAuditorAsync,
  getDocumentDirectorAsync,
};

export default CM04Service;
