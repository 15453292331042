import {
  ContractReceiveCollateral,
  Modal,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import {
  StatusCMConsider,
  StatusCMReceiveCollateral,
  StatusCMRequestApproval,
  StatusCMReturnCollateral,
} from 'components/StatusCMReturnCollateral';
import { HistoryListResponseModel } from 'models/CM/CM06Model';
import {
  useEffect,
  useState,
} from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import CM06Service from 'services/CM/CM06Service';
import {
  fullDatetime,
  HttpStatusCode,
} from 'utils';

interface Props {
  show: boolean;
  onHide?: () => void;
  title?: string;
  id?: string;
  activities?: any[];
  status?: string;
}

export function HistoryModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [history, setHistory] = useState<HistoryListResponseModel>({} as HistoryListResponseModel);

  useEffect(() => {
    if (props.id && props.show) {
      getPurchaseOrderApprovalHistoryAsynce(page, size);
    }
  }, [props.id, props.show]);

  const getPurchaseOrderApprovalHistoryAsynce = async (page: number, size: number) => {

    const { data, status } = await CM06Service.getListHistory(props.id, page, size, props.status);

    if (status === HttpStatusCode.OK) {
      setHistory(data);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size='xl'
    >
      <Modal.Header>
        <Title
          text={props.title ? `ประวัติ ${props.title}` : 'ประวัติการดำเนินการ'}
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <ModalBT.Body className='p-0 ps-4'>
        <Table className='my-3'
          total={history.totalCounts}
          onChange={(size, page) => {
            getPurchaseOrderApprovalHistoryAsynce(page, size);
          }}
        >
          <thead>
          <tr>
            <th>วันที่/เวลา</th>
            <th>ผู้ดำเนินการ</th>
            <th>การดำเนินการ</th>
            <th>สถานะการดำเนินการ</th>
            <th>หมายเหตุ</th>

          </tr>
          </thead>
          <tbody>
          {
            (history && history.data) && (
              history.data?.map((data, index) => (
                <tr key={index}>
                  <td className='text-center'>{fullDatetime(data.date)}</td>
                  <td>{data.fullName}</td>
                  <td>{data.description}</td>
                  <td className='text-start'>
                    {
                      (props.status === ContractReceiveCollateral.Collateral) && (
                        <StatusCMReturnCollateral systemStatue={data.status}
                          systemName='cm-03' />
                      )
                    }
                    {
                      (props.status === ContractReceiveCollateral.Consider) && (
                        <StatusCMConsider systemStatue={data.status}
                          systemName='cm-03' />
                      )
                    }
                    {
                      (props.status === ContractReceiveCollateral.Request) && (
                        <StatusCMRequestApproval systemStatue={data.status}
                          systemName='cm-03' />
                      )
                    }
                    {
                      (props.status === ContractReceiveCollateral.Receive) && (
                        <StatusCMReceiveCollateral systemStatue={data.status}
                          systemName='cm-03' />
                      )
                    }
                  </td>
                  <td className='text-start text-wrap'
                    style={{ minWidth: 400, width: 400 }}>{data.remark}</td>
                </tr>
              ))
            )
          }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
