import {
  Breadcrumb,
  ProcessStatus,
} from 'components';
import StepProgress from 'components/StepProgress';
import { AppointmentDetailModel } from 'models/P/PP0201Model';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import PP0201Services from 'services/PP/PP0201Services';
import { HttpStatusCode } from 'utils';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

const BREADCRUMB_INFO = [
  {
    routerName: '/pp/pp0201', label: 'จัดทำคำสั่งแต่งตั้ง',
  },
  {
    routerName: '/pp/pp0201/Detail', label: 'เอกสารบันทึกข้อความแต่งตั้ง',
  },
];

interface stepData {
  title: JSX.Element;
  displayIndex: string;
  child: JSX.Element;
}

function PP0201Detail() {
  const { id } = useParams();
  const [appointment, setAppointment] = useState<AppointmentDetailModel>({} as AppointmentDetailModel);
  const [disabled, setDisabled] = useState(false);
  const [isHasMiddlePrice, setIsHasMiddlePrice] = useState(false);

  useEffect(() => {
    if (id) {
      getAppointmentByIdAsync(id);
    }
  }, [id]);

  const getAppointmentByIdAsync = async (id: string) => {
    const response = await PP0201Services.getPP0201ByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setAppointment(response.data);
      if (response.data.status === null
        || response.data.status === undefined
        || response.data.status === ''
        || response.data.status === ProcessStatus.DRAFT
        || response.data.status === ProcessStatus.REJECTED) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }

      if (response.data.planBudget < 100000) {
        setIsHasMiddlePrice(false);
      } else {
        setIsHasMiddlePrice(true);
      }
    }
  };

  const onSetAppointment = (data: AppointmentDetailModel) => {
    setAppointment(data);
    if (data.status === null
      || data.status === undefined
      || data.status === ''
      || data.status === ProcessStatus.DRAFT
      || data.status === ProcessStatus.REJECTED) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (data.planBudget < 100000) {
      setIsHasMiddlePrice(false);
      data.committeeMiddlePrices = [];
      data.dutyCommitteeMiddlePrices = [];
    } else {
      setIsHasMiddlePrice(true);
    }
  };

  const childRef = useRef<ProgressBarRefType>(null);
  const obj = [
    {
      title: <p>บันทึกข้อมูลผู้ประกอบการ</p>,
      displayIndex: '1',
      child: <Step1 onClickNext={() => clickNext()}
        onClickBack={() => backToIndex()}
        appointmentData={appointment}
        setForm={onSetAppointment}
        isDisabled={disabled} />,
    },
    {
      title: <p>คณะกรรมการจัดทำร่างขอบเขตของงาน</p>,
      displayIndex: '2',
      child: <Step2 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()}
        appointmentData={appointment}
        setForm={onSetAppointment}
        isDisabled={disabled} />,
    },
    {
      title: <p>คณะกรรมการกำหนดราคากลาง</p>,
      displayIndex: '3',
      child: <Step3 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()}
        appointmentData={appointment}
        setForm={onSetAppointment}
        isDisabled={disabled} />,
    },
    {
      title: <p>อำนาจอนุมัติ</p>,
      displayIndex: '4',
      child: <Step4 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()}
        appointmentData={appointment}
        setForm={onSetAppointment}
        isDisabled={disabled} />,
    },
    {
      title: <p>ตัวอย่าง<br />
        เอกสารบันทึกข้อความแต่งตั้ง
      </p>,
      displayIndex: '5',
      child: <Step5 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()}
        appointmentData={appointment}
        setForm={onSetAppointment} />,
    },
  ];

  const objLow = [
    {
      title: <p>บันทึกข้อมูลผู้ประกอบการ</p>,
      displayIndex: '1',
      child: <Step1 onClickNext={() => clickNext()}
        onClickBack={() => backToIndex()}
        appointmentData={appointment}
        setForm={onSetAppointment}
        isDisabled={disabled} />,
    },
    {
      title: <p>คณะกรรมการจัดทำร่างขอบเขตของงาน</p>,
      displayIndex: '2',
      child: <Step2 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()}
        appointmentData={appointment}
        setForm={onSetAppointment}
        isDisabled={disabled} />,
    },
    {
      title: <p>อำนาจอนุมัติ</p>,
      displayIndex: '3',
      child: <Step4 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()}
        appointmentData={appointment}
        setForm={onSetAppointment}
        isDisabled={disabled} />,
    },
    {
      title: <p>ตัวอย่าง
        เอกสารบันทึกข้อความแต่งตั้ง
      </p>,
      displayIndex: '4',
      child: <Step5 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()}
        appointmentData={appointment}
        setForm={onSetAppointment} />,
    },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();
  const backToIndex = () => {
    navigate('/pp/pp0201');
  };

  interface AppointmentRequestForm {
    appointMemorandumDate: Date;
    departmentId: string;
    projectName: string;
    subject: string;
    commiteeTorName: string;
    responsiblePerson: string;
    status: string;
  }

  return (
    <div className='document'>
      <h4 className='mt-2 mb-0 text-primary text-start'>เอกสารบันทึกข้อความแต่งตั้ง</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12}
          className='mb-2'>
          {
            isHasMiddlePrice ? (
              <StepProgress progressBarData={obj}
                ref={childRef} />
            ) : (
              <StepProgress progressBarData={objLow}
                ref={childRef} />
            )
          }
        </Col>
      </Row>
    </div>
  );
}

export default PP0201Detail;
