import {
  ButtonCustom,
  Card,
  ContractReceiveStatus,
  DatePicker,
  Table,
} from 'components';
import { Currency, Input, InputTextArea } from 'components/Controls';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import { StatusCMDelivery } from 'components/StatusCMDelivery';
import { StatusCMReceived } from 'components/StatusCMReceived';
import { IFile } from 'models';
import {
  AttachmentFileModel,
  FineAmountModel,
  UpdateReceiveRequestModel,
} from 'models/CM/CM03Models';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import CM03Service from 'services/CM/CM03Service';
import {
  HttpStatusCode,
  submitForm,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { generateUniqueId } from '../../../../utils/helper';
import { HistoryModal } from '../Modal/HistoryReceiveModal';
import UploadFile from '../UploadFileReceive';
import { Context } from './step';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  contractId: string;
}

const INFO = [
  { label: 'มีหลักประกัน', value: '1' },
  { label: 'ไม่มีหลักประกัน', value: '2' },
];

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function Step1({ onClickNext, onClickBack, contractId }: Props) {
  const { data, setData } = useContext(Context);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const { userId } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [showWaitForInspectorReceivedModal, setShowWaitForInspectorReceivedModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [fineAmountDetail, setFineAmountDetail] = useState<FineAmountModel[]>([]);
  const [amountReceiveFine, setAmountReceiveFine] = useState(0);

  useEffect(() => {
    if (data) {

      if (!data.status || data.status === ContractReceiveStatus.Draft || data.status === ContractReceiveStatus.InspectorRejected
        || data.status === ContractReceiveStatus.JorPorRejected || data.status === ContractReceiveStatus.Rejected) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }

      if (data.attachments) {
        handleSetFile(data.attachments);
      }

      if (data.receiveFineAmounts) {
        const fineAmountOther = data.receiveFineAmounts.reduce((a, b) => a += b.amount!, 0);
        setAmountReceiveFine(fineAmountOther);
        setFineAmountDetail(data.receiveFineAmounts)
      }
    }
  }, [data, userId]);

  const onSubmitAsync = async (waitForInspector: boolean) => {
    submitForm();

    if (!data.receivedDate) {
      toast.warn('กรุณากรอกวันที่ตรวจรับ');
      return;
    }

    if (!data.remark) {
      toast.warn('กรุณากรอกหมายเหตุการตรวจรับ');
      return;
    }

    if (!data.amount) {
      toast.warn('กรุณากรอกจำนวนเงิน ');
      return;
    }

    if (fineAmountDetail.filter((x) => x.detail === null || x.detail === '' || x.detail === undefined).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดค่าปรับอื่นๆ');
      return;
    }

    if (fineAmountDetail.filter((x) => x.amount === 0 || x.amount === undefined).length > 0) {
      toast.warn('กรุณาระบุจำนวนเงินค่าปรับอื่นๆ');
      return;
    }


    const docUpload: File[] = [];
    if (files) {
      files.forEach((i) => {
        if (i.file !== null) {
          docUpload.push(i.file);
        }
      });
      data.docNameforDelete = delFiles;
      data.files = [...docUpload];
    }

    if (data) {
      const createDataModel: UpdateReceiveRequestModel = {
        id: data.id,
        receivedDate: data.receivedDate,
        receivedNumber: data.receivedNumber,
        remark: data.remark,
        amount: data.amount,
        retentionAmount: data.retentionAmount,
        advanceAmount: data.advanceAmount,
        fineAmount: data.fineAmount,
        remainAmount: data.remainAmount,
        receiveFineAmounts: fineAmountDetail,
      };

      const res = await CM03Service.updateReceiveRequestAsync(createDataModel);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        if (waitForInspector) {
          const res = await CM03Service.waitForInspectorReceivedAsync(data.id);
          toast.success('ส่งคณะกรรมการตรวจรับรับทราบสำเร็จ');
          setShowWaitForInspectorReceivedModal(false);
        } else {
          toast.success('บันทึกข้อมูลสำเร็จ');
        }

        getContractManagementAsync(contractId, data.id);
        navigate(`${location.pathname}`);
      } else {
        toast.error(res.statusText);
      }
    }
  };

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await CM03Service.uploadAttachmentReceiveAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getAttachmentAsync(contractId, data.id);
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {
    if (data && data.id) {
      const res = await CM03Service.removeAttachmentReceiveAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getAttachmentAsync(contractId, data.id);
      }
    }
  }, [files, data.id]);

  const getAttachmentAsync = useCallback(async (contractId: string, receiveId: string) => {
    const { data, status } = await CM03Service.getContractManagementReceiveByIdAsync(contractId, receiveId);

    if (status === HttpStatusCode.OK) {

      const files: IFile[] = [];
      data.attachments?.forEach((item) => {
        files.push({
          id: item.id,
          name: item.fileName,
          file: null,
        });
      });

      setFiles(files);

      return;
    }
  }, []);

  const getContractManagementAsync = useCallback(async (contractId: string, receiveId: string) => {
    const { data, status } = await CM03Service.getContractManagementReceiveByIdAsync(contractId, receiveId);

    if (status === HttpStatusCode.OK) {
      setData(data);
      return;
    }
  }, []);

  const onChangeAmount = (amount: number, retentionAmount: number, advanceAmount: number, fineAmount: number, fineAmountData: FineAmountModel[]) => {

    const fineAmountOther = fineAmountData.reduce((a, b) => a += b.amount!, 0);
    const remainAmount = (amount || 0) - ((retentionAmount || 0) + (advanceAmount || 0) + (fineAmount || 0) + (Number(fineAmountOther) || 0));
    setAmountReceiveFine(fineAmountOther);
    setData({
      ...data, amount, retentionAmount, advanceAmount, fineAmount, remainAmount, receiveFineAmounts: fineAmountData
    });
  };

  const addDutyTermsTors = useCallback(async (sectionSequence: number) => {
    const newData: FineAmountModel = {
      sequence: sectionSequence,
    };

    setFineAmountDetail((fineAmountDetail) => [...fineAmountDetail, newData]);
  }, [fineAmountDetail]);

  const handlerOnChangeDetail = (value: string, index: number) => {
    const data = [...fineAmountDetail];
    data[index].detail = value;
    setFineAmountDetail(data);
  };

  const handlerOnChangeAmount = (value: number, index: number) => {
    const nweData = [...fineAmountDetail];
    nweData[index].amount = value;
    onChangeAmount(data.amount, data.retentionAmount, data.advanceAmount, data.fineAmount, nweData)
    setFineAmountDetail(nweData);
  };

  const handlerRemove = (i: number) => {
    const temp: FineAmountModel[] = [...fineAmountDetail];
    temp.splice(i, 1);
    onChangeAmount(data.amount, data.retentionAmount, data.advanceAmount, data.fineAmount, temp)
    setFineAmountDetail(temp);
  };

  return (
    <div className='document'>
      <Title text={`รายละเอียดการส่งมอบงาน งวดที่ ${data.sequence}`}
        extraElement={(
          <>
            <StatusCMReceived systemStatus={data.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </>
        )} />

      <Row className='mt-3 align-items-center'>
        <h5 className='fw-bold'>บันทึกตรวจรับ</h5>
        <Col sm={12} lg={3} className='mt-3'>
          <DatePicker label='วันที่ประชุมตรวจรับ'
            value={data.receivedDate}
            onChange={(val) => setData({ ...data, receivedDate: val })}
            disabled={isDisabled}
            rule={{ required: true }} />
        </Col>
        <Col sm={12} />
        <Col sm={12} lg={6}>
          <InputTextArea label='หมายเหตุการตรวจรับ'
            value={data.remark}
            onChange={(val) => setData({ ...data, remark: val })}
            disabled={isDisabled}
            rule={{ required: true }} />
        </Col>
      </Row>

      <div className='mt-3'>
        <h5 className='fw-bold'>รายละเอียดส่งมอบ</h5>
      </div>
      <Row className='mt-3'>
        <Col sm={12}>
          <Table total={2} hidePagination>
            <thead>
              <tr>
                <th className='text-start ps-3' style={{ width: 100 }}>งวด</th>
                <th className='text-center' style={{ width: 120 }}>วันที่ส่งมอบ</th>
                <th style={{ width: '80%' }}>รายละเอียดส่งมอบ</th>
                <th style={{ width: 150 }}>สถานะ</th>
              </tr>
            </thead>
            <tbody>
              {data.contractManagementDeliverys?.sort((a, b) => a.sequence - b.sequence).map((data, index) => (
                <tr className='text-start' key={generateUniqueId(index)}>
                  <td className='ps-3'>{data.phaseNumber}</td>
                  <td className='text-center'>{thaiFormatDateWithSlash(data.deliveryDate)}</td>
                  <td className='text-start'>
                    <ul>
                      <li>{data.detail}</li>
                    </ul>
                  </td>
                  <td><StatusCMDelivery systemStatue={data.status}
                    systemName='cm-03' />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className='mt-3 align-items-center'>
        <Col sm={12} md={4} lg={4} />
        <Col sm={12} md={4} lg={4}>
          <Currency label='จำนวนเงิน'
            value={data.amount}
            disabled={isDisabled}
            rule={{ required: true }}
            onChange={(val) => onChangeAmount(Number(val), data.retentionAmount, data.advanceAmount, data.fineAmount, fineAmountDetail)}
          />
        </Col>
        <Col sm={12} md={4} lg={4}>
          <Currency label='หักประกันผลงาน'
            value={data.retentionAmount}
            disabled={isDisabled}
            onChange={(val) => onChangeAmount(data.amount, Number(val), data.advanceAmount, data.fineAmount, fineAmountDetail)}
          />
        </Col>
        <Col sm={12} />
      </Row>
      <Row className='mt-3 align-items-center'>
        <Col sm={12} md={6} lg={4} />
        <Col sm={12} md={6} lg={4} />
        <Col sm={12} md={4} lg={4}>
          <Currency label='เงินล่วงหน้า'
            value={data.advanceAmount}
            disabled={isDisabled}
            onChange={(val) => onChangeAmount(data.amount, data.retentionAmount, Number(val), data.fineAmount, fineAmountDetail)}
          />
        </Col>
        <Col sm={12} />
      </Row>
      <Row className='mt-3 align-items-center'>
        <Col sm={12} md={6} lg={4} />
        <Col sm={12} md={6} lg={4} />
        <Col sm={12} md={4} lg={4}>
          <Currency label='ค่าปรับ'
            value={data.fineAmount}
            disabled={isDisabled}
            onChange={(val) => onChangeAmount(data.amount, data.retentionAmount, data.advanceAmount, Number(val), fineAmountDetail)}
          />
        </Col>
        <Col sm={12} />
      </Row>

      <div>
        <Row className='align-items-center mt-5'>
          <Col sm={4} />
          <Col sm={8}>
            <div className='text-end'>
              <Button
                variant='outline-primary'
                onClick={() => addDutyTermsTors(fineAmountDetail.length + 1)}
                disabled={isDisabled}
              >
                <FaPlus className='me-2' />เพิ่มข้อมูลค่าใช้จ่ายอื่นๆ
              </Button>
            </div>
            {
              fineAmountDetail.length > 0 && (
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: '50%' }}>รายละเอียดค่าใช้จ่ายอื่นๆ</th>
                      <th style={{ width: '45%' }}>จำนวนเงิน</th>
                      <th style={{ width: '5%' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fineAmountDetail.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr>
                          <td className='text-start'>
                            <Input
                              rule={{ required: true }}
                              type='text'
                              value={data.detail || ''}
                              disabled={isDisabled}
                              onChange={(val) => handlerOnChangeDetail(val, index)}
                            />
                          </td>
                          <td className='text-end'>
                            <Currency
                              rule={{ required: true }}
                              value={data.amount || 0}
                              disabled={isDisabled}
                              onChange={(val) => handlerOnChangeAmount(Number(val), index)}
                            />
                          </td>
                          <td>
                            <div className='d-flex justify-content-end'>
                              <Button
                                variant='danger'
                                className='d-flex align-items-center gap-2'
                                onClick={() => handlerRemove(index)}
                                disabled={isDisabled}
                              >
                                <FaTrashAlt />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {
                      fineAmountDetail.length === 0 ? (
                        <tr>
                          <td colSpan={3} className='text-center'>ไม่พบข้อมูลรายการค่าใช้จ่ายอื่นๆ</td>
                        </tr>
                      ) : <tr>
                      </tr>
                    }
                  </tbody>
                </Table>
              )
            }

          </Col>
        </Row>
      </div>
      <Row className='mt-5'>
        <Col sm={12} md={6} lg={4} />
        <Col sm={6} md={6} lg={4} >
        </Col>
        <Col sm={6} md={6} lg={4}>
          <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
            <h4 className='text-end mt-2 text-primary'>คงเหลือที่ต้องจ่าย</h4>
            <h4 className='text-end mt-2 text-primary'>{formatter.format(data.remainAmount)}</h4>
          </div>
        </Col>
      </Row>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5' />
        <Row className='justify-content-center'>
          <Col sm={12} md={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.id!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <ButtonCustom text='ย้อนกลับ' onClick={onClickBack} icon='arrowback' variant='outline-primary' />
        </div>
        <div className='d-flex gap-3 align-items-center'>
          {
            (!data.status || data.status === ContractReceiveStatus.Draft || data.status === ContractReceiveStatus.InspectorRejected || data.status === ContractReceiveStatus.JorPorRejected || data.status === ContractReceiveStatus.Rejected) && (
              <>
                <ButtonCustom text='บันทึก' variant='outline-primary' onClick={() => onSubmitAsync(false)} />
                <ButtonCustom text='ส่งคณะกรรมการตรวจรับรับทราบ' variant='primary' onClick={() => setShowWaitForInspectorReceivedModal(true)} />
              </>
            )
          }

        </div>

        {
          (data.status && data.status !== ContractReceiveStatus.Draft) ? (
            <div>
              <ButtonCustom text='ถัดไป' onClick={onClickNext} icon='arrowforward' variant='outline-primary' iconAlignRight />
            </div>
          ) : <div style={{ width: 145 }} />
        }

      </div>
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={data.id}
        activities={data.activities}
        title='การตรวจรับ'
        status={ContractReceiveStatus.Received}
      />
      <Modal
        show={showWaitForInspectorReceivedModal}
        size='lg'
        onHide={() => setShowWaitForInspectorReceivedModal(!showWaitForInspectorReceivedModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งคณะกรรมการตรวจรับรับทราบ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitForInspectorReceivedModal(!showWaitForInspectorReceivedModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div >
  );
}

export default Step1;
