import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  ButtonCustom,
  Card,
  Currency,
  InputTextArea,
  Radio,
  Selector,
  Table,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import { ContractAgreementWorkPhaseModel, PaymentModel, UpdateWorkPhaseDetailModel, UpdateWorkPhaseStep2Model } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';
import { PaymentTypeIdEnum, PeriodConditionIdEnum } from 'utils/constants/ShareValueEnum';
import { EditDeliveryModal } from 'pages/CM/CM02/Modal/EditDeliveryModal';
import { HttpStatusCode, fullDate } from 'utils';
import toast from 'utils/toast';
import CM02Service from 'services/CM/CM02Service';

const INFO = [
  {
    label: 'มีการจ่ายเงินล่วงหน้า',
    value: 'true',
  },
  {
    label: 'ไม่มีการจ่ายเงินล่วงหน้า',
    value: 'false',
  },
];

const BANKDEMO = [
  {
    label: 'กสิกร',
    value: 'kbank',
  },
];

interface IPaymentManagerProps {
  title?: string;
  contractTemplateCode: string;
  contractId: string;
  payment: PaymentModel;
  contractAgreementWorkPhaseData: ContractAgreementWorkPhaseModel[];
  periodTypeDDL: ItemModel[];
  periodConditionDDL: ItemModel[];
  paymentWithinTypeDDL: ItemModel[];
  advanceDeductionTypeDDL: ItemModel[];
  paymentTypeDDL: ItemModel[];
  onPaymentChange: (value: PaymentModel) => void;
  onSubmitContractAgreementWorkPhase: (models: ContractAgreementWorkPhaseModel[]) => void; // TODO: Change this type
  contractOfferTotalAmountVat: number;
  isDisabled?: boolean;
  isResponsible?: boolean;
}

const advanceInfo = [
  {
    label: 'จ่ายเงินล่วงหน้า',
    value: 'true',
  },
  {
    label: 'ไม่จ่ายเงินล่วงหน้า',
    value: 'false',
  },
];

const fields = [
  {
    code: 'CF01',
    // use CMS004
  },
  {
    code: 'CF02',
    paymentType: 'ประเภทการจ่ายเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
    hasAdvance: 'การชำระเงินให้แก่ผู้ซื้อมีการจ่ายเงินล่วงหน้าหรือไม่',

    advanceTitle: '6.1 เงินล่วงหน้า',
    advanceAmount: 'จ่ายเงินล่วงหน้าจำนวน',
    advanceInDay: 'จะจ่ายให้ภายใน',

    remainTitle: '6.2 เงินที่เหลือ',
    remainAmount: 'เงินที่เหลือ จำนวน',
  },
  {
    code: 'CF03',
    paymentType: 'ประเภทการจ่ายเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByWorkProgress,
    paymentWithin: 'ผู้จะซื้อตกลงจะชำระเงินค่าสิ่งของให้แก่ผู้จะขายภายใน',
    payment_within_label: 'นับถัดจากวันที่ผู้จะขายได้แสดงหลักฐานการรับมอบ',
  },
  {
    code: 'CF04',
    paymentType: 'ประเภทการจ่ายเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
    hasAdvance: 'การชำระเงินให้แก่ผู้ซื้อมีการจ่ายเงินล่วงหน้าหรือไม่',

    advanceTitle: '6.1 เงินล่วงหน้า',
    advanceAmount: 'จ่ายเงินล่วงหน้าจำนวน',
    advanceInDay: 'จะจ่ายให้ภายใน',
    defaultAdvanceInDayType: PeriodConditionIdEnum.นับตั้งแต่วันที่ลงนามในสัญญา,

    remainTitle: '6.2 เงินที่เหลือ',
    remainAmount: 'เงินที่เหลือ จำนวน',
  },
  {
    code: 'CF05',
    paymentType: 'ประเภทการจ่ายเงิน',
    defaultPaymentType: PaymentTypeIdEnum.PayByPhaseAssign,
    paymentTitle: 'งวดเงินและรายละเอียดการชำระเงิน',
  },
  {
    code: 'CF06',
    // use CMS004
  },
  {
    code: 'CF07',
    // use CMS004
  },
  {
    code: 'CF08',
    // use CMS004
  },
  {
    code: 'CF09',
    // use CMS004
  },
  {
    code: 'CF10',
    // use CMS004
  },
  {
    code: 'CF11',
    // use CMS004
  },
  {
    code: 'CF12',
    // use CMS004
  },
  {
    code: 'CF13',
    // use CMS004

  },
  {
    code: 'CF14',
    // use CMS004
  },
  {
    code: 'CF15',
    // use CMS004
  },
];

export function PaymentManager(props: IPaymentManagerProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [paymentData, setPaymentData] = useState<PaymentModel>(props.payment);
  const [isDisabledPayment, setIsDisabledPayment] = useState(props?.payment?.hasAdvance ?? true);
  const [contractAgreementWorkPhases, setContractAgreementWorkPhases] = useState<ContractAgreementWorkPhaseModel[]>(props.contractAgreementWorkPhaseData);
  const [showEditDeliveryModal, setShowEditDeliveryModal] = useState(false);

  const formatter = new Intl.NumberFormat('th-TH', {
    currency: 'THB',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    if (firstLoad) {
      defaultDDL();
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    if (paymentData) {
      props.onPaymentChange(paymentData);
    }
  }, [paymentData]);

  useEffect(() => {
    if (props.payment) {
      setIsDisabledPayment(!props.payment.hasAdvance);
    } else {
      setIsDisabledPayment(true);
    }
  }, [props.payment]);

  const defaultDDL = useCallback(() => {
    fields.filter(f => f.code === props.contractTemplateCode).forEach((data) => {
      setPaymentData({
        ...paymentData,
        type: (!paymentData?.type && data.defaultPaymentType) ? data.defaultPaymentType : paymentData?.type,
        advancePeriodConditionType: (!paymentData?.advancePeriodConditionType && data.defaultAdvanceInDayType) ? data.defaultAdvanceInDayType : paymentData?.advancePeriodConditionType,
      });
    });
  }, []);

  const onTextChange = useCallback((key: string, value: string) => {
    setPaymentData(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setPaymentData(prevState => ({
      ...prevState,
      [key]: value,
    }));

    setPaymentData(prevState => ({
      ...prevState,
      [key]: value,
    }));

  }, []);

  const onSubmitDeliverWorkPhaseModal = async (models: ContractAgreementWorkPhaseModel[]) => {
    setContractAgreementWorkPhases(models);
    props.onSubmitContractAgreementWorkPhase(models);
    await onSaveWorkPhase(models);
  };

  const onSaveWorkPhase = async (models: ContractAgreementWorkPhaseModel[]) => {
    const updateDetails: UpdateWorkPhaseDetailModel[] = models.map((data) => ({
      id: data.id,
      paidPhase: data.paidPhase,
      workPhase: data.workPhase,
      sequence: data.sequence,
      periodDay: data.periodDay,
      amount: data.amount,
      percent: data.percent,
      advancePaymentAmount: data.advancePaymentAmount,
      retentionAmount: data.retentionAmount,
      detail: data.detail,
      deliveryDueDate: data.deliveryDueDate,
    } as UpdateWorkPhaseDetailModel));

    const updateData: UpdateWorkPhaseStep2Model = {
      WorkPhases: updateDetails,
    };

    const { status } = await CM02Service.updateContractWorkPhaseStep2Async(props.contractId, updateData);
    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถบันทึกข้อมูลสัญญาได้');

      return;
    }

    toast.success('บันทึกข้อมูลการส่งมอบสำเร็จ');
  };

  const onChangePaymentActive = useCallback((val: string) => {
    setPaymentData(prevState => ({
      ...prevState,
      hasAdvance: val === 'true',
    }));

    if (val === 'false') {
      setIsDisabledPayment(true);
      const payment = {
        hasAdvance: false,
        advanceAmount: undefined,
        advanceInDay: undefined,
        collateralType: undefined,
        remainAmount: undefined,
        bankName: undefined,
        bankBranch: undefined,
        bankAccountName: undefined,
        bankAccountNumber: undefined,
      } as PaymentModel;

      setPaymentData(prevState => ({
        ...prevState,
        payment,
      }));
    } else {
      setIsDisabledPayment(false);
    }
  }, []);

  useEffect(() => {
    if (firstLoad) {
      if (props.payment) {
        setPaymentData(pre => ({ ...pre, ...props.payment }));
      } else {
        setPaymentData(pre => ({ ...pre, hasAdvance: false }));
      }
      setFirstLoad(false);
    }
  }, [props.payment, firstLoad]);

  const onSubmitWorkPhaseModal = async (models: ContractAgreementWorkPhaseModel[]) => {
    setContractAgreementWorkPhases(models);
  };

  return (
    <>
      <Card className='mt-3'>
        <Title text={`สัญญา${props.title ? props.title : 'การชำระเงิน'}`} className='fs-5 text-primary' />
        <Row className='mt-3'>
          <>
            {
              fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
                <>
                  {
                    data.paymentType && (
                      <>
                        {data.paymentTitle && (
                          <h5 className='mt-3'>{data.paymentTitle}</h5>
                        )}
                        <Row className='mt-03'>
                          <Col sm={5}
                            md={5}>
                            <Selector
                              label={data.paymentType}
                              items={props.paymentTypeDDL}
                              value={paymentData?.type}
                              onChange={(value) => onTextChange('type', value)}
                              disabled={true} />
                          </Col>
                        </Row>
                      </>
                    )
                  }
                  {
                    data.hasAdvance &&
                    (
                      <>
                        <h5 className='mt-3'>{data.advanceTitle}</h5>
                        <Row className='mt-03'>
                          <Col sm={8}
                            md={6}>
                            <Radio
                              dflex
                              label={data.hasAdvance}
                              items={advanceInfo}
                              value={paymentData ? paymentData.hasAdvance?.toString() : 'false'}
                              rule={{ required: true }}
                              onChange={(val) => onChangePaymentActive(val?.toString())}
                              disabled={props.isDisabled}
                              name='hasAdvance' />
                          </Col>
                        </Row>
                      </>
                    )
                  }
                  {
                    data.advanceAmount && (
                      <Row className='mt-3'>
                        <Col sm={8}
                          md={5}>
                          <Currency
                            label={data.advanceAmount}
                            placeholder={data.advanceAmount}
                            value={paymentData?.advanceAmount}
                            onChange={(value) => onNumberChange('advanceAmount', Number(value))}
                            disabled={props.isDisabled || isDisabledPayment} />
                        </Col>
                      </Row>
                    )
                  }
                  {
                    data.advanceInDay && (
                      <Row className='mt-3'>
                        <Col sm={8}
                          md={5}
                          className='d-flex'>
                          <InputNumber
                            textCenter
                            label={data.advanceInDay}
                            value={paymentData?.advanceInDay}
                            onChange={(value) => onNumberChange('advanceInDay', Number(value))}
                            disabled={props.isDisabled || isDisabledPayment} />
                          <label className='mt-5 ps-2'>วัน</label>
                        </Col>
                        <Col sm={8}
                          md={5}>
                          <Selector
                            className='no-label'
                            items={props.periodConditionDDL}
                            value={paymentData?.advancePeriodConditionType}
                            onChange={(value) => onTextChange('advancePeriodConditionType', value)}
                            disabled={props.isDisabled || isDisabledPayment} />
                        </Col>
                      </Row>
                    )
                  }
                  {/* {
                    data.advanceDedutionType && (
                      <Row>
                        <Col sm={8}
                          md={5}>
                          <Selector
                            label='การหักเงินล่วงหน้า'
                            items={props.advanceDeductionTypeDDL}
                            value={advancePayment?.advanceDedutionPhaseType}
                            onChange={(value) => onTextChange('advanceDedutionPhaseType', value)}
                            disabled={props.isDisabled || isDisabledPayment} />
                        </Col>
                      </Row>
                    )
                  } */}
                  {
                    data.remainAmount && (
                      <>
                        <h5 className='mt-3'>{data.remainTitle}</h5>
                        <Row className='mt-3'>
                          <Col sm={8}
                            md={5}>
                            <Currency
                              label={data.remainAmount}
                              placeholder={data.remainAmount}
                              value={paymentData?.remainAmount}
                              onChange={(value) => onNumberChange('remainAmount', Number(value))}
                              disabled={props.isDisabled || isDisabledPayment} />
                          </Col>
                          <Col sm={8}
                            md={3}>
                          </Col>
                        </Row>
                      </>
                    )
                  }
                  {
                    (data.paymentWithin && (paymentData && paymentData?.type === PaymentTypeIdEnum.PayByWorkProgress)) && (
                      <Row className='mt-3'>
                        <Col sm={8}
                          md={5}
                          className='d-flex'>
                          <InputNumber
                            textCenter
                            label={data.paymentWithin}
                            placeholder={data.paymentWithin}
                            value={paymentData?.paymentWithin}
                            onChange={(value) => onNumberChange('paymentWithin', Number(value))}
                            disabled={props.isDisabled} />
                          <label className='mt-5 ps-2'>วัน</label>
                        </Col>
                        <Col sm={8}
                          md={4}>
                          <label className='mt-5'>{data.payment_within_label}</label>
                        </Col>
                      </Row>
                    )
                  }
                  {
                    (data.paymentType && (paymentData && paymentData?.type === PaymentTypeIdEnum.PayByPhaseAssign)) && (
                      <>
                        <Row className='d-flex justify-content-between'>
                          <Col sm={5}
                            md={5}>
                            <p>โดยมีรายละเอียดการส่งมอบ ดังนี้</p>
                          </Col>
                          <Col sm={5}
                            md={5}
                            className='text-end'>
                            {props.isResponsible && (<ButtonCustom
                              text='แก้ไขรายการ'
                              onClick={() => setShowEditDeliveryModal(true)}
                              disabled={props.isDisabled} />)}
                          </Col>
                        </Row>
                        <Row>
                          <Table hidePagination>
                            <thead>
                              <tr className='text-center'>
                                {/* <th style={{ width: 20 }}>งวดเงิน</th> */}
                                <th style={{ width: 20 }}>งวดที่</th>
                                <th style={{ width: 50 }}>ระยะเวลา (วัน)</th>
                                <th style={{ width: 70 }}>วันที่ต้องส่งมอบ</th>
                                <th style={{ width: 20 }}>ร้อยละ</th>
                                <th style={{ width: 70 }}>จำนวนเงิน</th>
                                <th style={{ width: 70 }}>หักเงินล่วงหน้า</th>
                                <th style={{ width: 70 }}>หักเงินประกันผลงาน</th>
                                <th style={{ width: '50%' }}>รายเอียด</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                contractAgreementWorkPhases?.map((data, index) => (
                                  <tr className='text-center' key={index}>
                                    {/* <td>{data.paidPhase}</td> */}
                                    <td>{data.workPhase}</td>
                                    <td>{data.periodDay}</td>
                                    <td>{data.deliveryDueDate !== undefined ? fullDate(data.deliveryDueDate) : ''}</td>
                                    <td>{formatter.format(data.percent)}%</td>
                                    <td className='text-end'>{formatter.format(data.amount)}</td>
                                    <td className='text-end'>{formatter.format(data.advancePaymentAmount ?? 0)}</td>
                                    <td className='text-end'>{formatter.format(data.retentionAmount ?? 0)}</td>
                                    <td><InputTextArea value={data.detail}
                                      disabled />
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </Row>
                      </>
                    )
                  }
                </>
              )
            }
          </>
        </Row>
      </Card >
      <EditDeliveryModal
        show={showEditDeliveryModal}
        contractAgreementId={props.contractId}
        onHide={() => setShowEditDeliveryModal(false)}
        contractAmountVat={props.contractOfferTotalAmountVat}
        contractAgreementWorkPhases={contractAgreementWorkPhases}
        onSubmit={onSubmitDeliverWorkPhaseModal}
        isDisabled={props.isDisabled}
      />

      {/* <EditDeliveryModal
        show={showEditDeliveryModal}
        contractAgreementId={props.contractId}
        onHide={() => setShowEditDeliveryModal(false)}
        contractAmount={props.contractOfferTotalAmount}
        contractAgreementWorkPhases={contractAgreementWorkPhases}
        onSubmit={onSubmitDeliverWorkPhaseModal}
        isDisabled={props.isDisabled}
      /> */}
    </>
  );
}
