import { AnnouncingWinnerParamsSearch } from 'models/announcingWinner';
import qs from 'qs';
import http from 'utils/axios';

const getAnnoucingWinnerListAsync = async (page: number, size: number, criteriaData?: AnnouncingWinnerParamsSearch) => {
  const params = {
    page,
    size,
    ...criteriaData,
  };

  return await http.get('/announcingwinner', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

export default {
  getAnnoucingWinnerListAsync,
};