import {
  DepartmentResponse,
  ItemModel,
} from 'models';
import { LoaderFunctionArgs } from 'react-router';
import {
  acceptor,
  department,
  shareValue,
} from 'services';
import { PlanTypeText } from 'utils/constants/PlanEnum';

export const PL0307Loader = async () => {
  const response = await acceptor.GetListsAsync(1, 10);
  const departmentData = await department.getDepartmentsAsync();
  const departmentList: DepartmentResponse[] = departmentData.data;
  const departmentItems: ItemModel[] = departmentList.map((data) => ({ label: data.name, value: data.id ? data.id : '' }));
  const supplymethodType: ItemModel[] = await shareValue.GetDDL('SupplyMethodType');
  const supplymethodName: ItemModel[] = await shareValue.GetDDL('SupplyMethodName');

  return {
    acceptorData: response.data.data,
    acceptorTotalRecords: response.data.totalRecords,
    departmentItems,
    supplymethodTypeList: supplymethodType,
    supplymethodNameList: supplymethodName,
  };
};

export const PL0306DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { type, id } = params;
  const departmentData = await department.getDepartmentsAsync();
  const departmentList: DepartmentResponse[] = departmentData.data;
  const departmentItems: ItemModel[] = departmentList.map((data) => ({ label: data.name, value: data.id ? data.id : '' }));
  const supplymethodType: ItemModel[] = await shareValue.GetDDL('SupplyMethodType');
  const supplymethodName: ItemModel[] = await shareValue.GetDDL('SupplyMethodName');

  if (id) {
    if (type === PlanTypeText.ChangePlan) {
      return {
        departmentItems,
        supplymethodTypeList: supplymethodType,
        supplymethodNameList: supplymethodName,
      };
    }

    const response = await acceptor.getPlanDetailAsync(id);

    return {
      departmentItems,
      supplymethodTypeList: supplymethodType,
      supplymethodNameList: supplymethodName,
      planDetailData: response.data,
    };
  }
};
