import { ProcessStatus } from "components";
import { isNull } from "./helper";

function onCheckQueueSectionApprover(datas: any, userId: string) {

  const queueApprover = datas?.filter((a: { status: ProcessStatus; isDisabled: any; }) => a.status === ProcessStatus.PENDING && !a.isDisabled)
    .sort((a: { sectionSequence: any; sequence: any; }, b: { sectionSequence: any; sequence: any; }) => Number(`${a.sectionSequence}${a.sequence}`) - Number(`${b.sectionSequence}${b.sequence}`))[0];

  if (queueApprover) {
    return (isNull(queueApprover.delegateUserId, (queueApprover.approverUserId ? queueApprover.approverUserId : queueApprover.userId)) === userId);
  }

  return false;
}

export {
  onCheckQueueSectionApprover,
};
