import {
  DatePicker,
  Selector,
} from 'components';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import { IFile } from 'models/P/FileUploadModel';
import {
  AppointmentDetailModel,
  AppointmentDocumentModel,
} from 'models/P/PP0201Model';
import { AnnualPlanModel } from 'models/PL';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { MdArrowForward } from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';
import { useLoaderData } from 'react-router';
import { shareValue } from 'services';
import { PL0201Service } from 'services/PL';
import {
  fullDatetimeTH,
  HttpStatusCode,
} from 'utils';
import toast from 'utils/toast';
import { useForm } from 'utils/useForm';
import UploadFile from './UploadFile';

interface Props {
  setForm: (form: AppointmentDetailModel) => void;
  appointmentData: AppointmentDetailModel;
  onClickNext: () => void;
  onClickBack: () => void;
  isDisabled?: boolean;
}

interface FormInput {
  memorandumDate: string;
  planName: string;
  planBudget: number;
  budgetYear: number;
  financialAmount: number;
  byOrderFrom: string;
  subject: string;
}

type Loader = {
  departmentDDL: ItemModel[],
  supplyMethodDDL: ItemModel[],
  supplyMethodNameDDL: ItemModel[],
  planDDL: ItemModel[]
};

function Step1({ onClickNext, onClickBack, appointmentData, setForm, isDisabled = false }: Props) {
  const [appointMemorandumDate, setAppointMemorandumDate] = useState<Date>();
  const [expectingProcurementAt, setExpectingProcurementAt] = useState<Date>();
  const [files, setFiles] = useState<IFile[]>([]);
  const { departmentDDL, supplyMethodDDL, supplyMethodNameDDL, planDDL } = useLoaderData() as Loader;
  const [departmentId, setDepartmentId] = useState('');
  const [planId, setPlanId] = useState('');
  const [supplyMethodId, setSupplyMethodId] = useState('');
  const [supplyMethodTypeId, setSupplyMethodTypeId] = useState('');
  const [supplyMethodSpecialId, setSupplyMethodSpecialId] = useState('');
  const [annualPlan, setAnnualPlan] = useState<AnnualPlanModel>({} as AnnualPlanModel);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);

  useEffect(() => {
    if (appointmentData) {
      if (appointmentData.memorandumDate) {
        setAppointMemorandumDate(new Date(appointmentData.memorandumDate));
      }

      if (appointmentData.planId) {
        setPlanId(appointmentData.planId);
      }

      if (appointmentData.expectingProcurementAt) {
        setExpectingProcurementAt(appointmentData.expectingProcurementAt);
      }

      if (appointmentData.departmentId) {
        setDepartmentId(appointmentData.departmentId);
      }

      if (appointmentData.supplyMethod) {
        getSupplyMethodSpecialType(appointmentData.supplyMethod);
        setSupplyMethodId(appointmentData.supplyMethod);
      }

      if (appointmentData.supplyMethodType) {
        setSupplyMethodTypeId(appointmentData.supplyMethodType);
      }

      if (appointmentData.supplyMethodSpecialType) {
        setSupplyMethodSpecialId(appointmentData.supplyMethodSpecialType);
      }
    }
  }, [appointmentData]);

  const getPlanDetail = useCallback(async (planId: string) => {
    setPlanId(planId);

    const { data, status } = await PL0201Service.getPL0201ByIdAsync(planId);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบแผนประจำปี');
      return;
    }

    initFormData((pre) => ({
      ...pre,
      planName: data.planName,
      planBudget: data.planBudget,
    }));

    setDepartmentId(data.departmentId);
    setSupplyMethodId(data.supplyMethod);
    setSupplyMethodTypeId(data.supplyMethodName);
    // setSupplyMethodSpecialId(data.supplyMethod);
    setExpectingProcurementAt(data.expectingProcurementAt);
    setAnnualPlan(data);
  }, [planId]);

  const getSupplyMethodSpecialType = useCallback(async (supplyMethodId: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(supplyMethodId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setSupplyMethodSpecialDDL(data);
  }, [supplyMethodId]);

  const onChangeSupplyMethod = (value: string) => {
    getSupplyMethodSpecialType(value);
    setSupplyMethodId(value.toString());
  };

  const { handleSubmit, handleChange, data: formData, errors, initFormData } = useForm<FormInput>({
    initialValues: {
      memorandumDate: '',
      planName: '',
      planBudget: 0,
      financialAmount: 0,
      byOrderFrom: '',
      subject: '',
    },
    validations: {},
    onSubmit: async () => {
      let apmDate: Date | undefined;
      let expectDate: Date | undefined;
      const docUpload: File[] = [];

      files.forEach((item) => {
        if (item.file !== null) {
          docUpload.push(item.file);
        }
      });

      if (!departmentId || departmentId === '') {
        toast.error('กรุณากรอกข้อมูลฝ่าย/สำนัก');
        return;
      }

      if (!formData.budgetYear || formData.budgetYear === 0) {
        toast.error('กรุณากรอกข้อมูลปีงบประมาณ');
        return;
      }

      if (!formData.planName || formData.planName === '') {
        toast.error('กรุณากรอกข้อมูลชื่อโครงการ');
        return;
      }

      if (!supplyMethodId || supplyMethodId === '') {
        toast.error('กรุณากรอกข้อมูลวิธีจัดหา');
        return;
      }

      if (!supplyMethodTypeId || supplyMethodTypeId === '') {
        toast.error('กรุณากรอกข้อมูลวิธีจัดหา');
        return;
      }

      if (!supplyMethodSpecialId || supplyMethodSpecialId === '') {
        toast.error('กรุณากรอกข้อมูลวิธีจัดหา');
        return;
      }

      if (!appointMemorandumDate) {
        toast.error('กรุณากรอกข้อมูลวันที่เอกสารบันทึกข้อความแต่งตั้ง');
        return;
      }

      if (!formData.byOrderFrom || formData.byOrderFrom === '') {
        toast.error('กรุณากรอกข้อมูลตามคำสั่ง');
        return;
      }

      if (!formData.subject || formData.subject === '') {
        toast.error('กรุณากรอกข้อมูลเรื่อง');
        return;
      }

      if (!formData.planBudget || formData.planBudget <= 0) {
        toast.error('กรุณากรอกข้อมูลงบประมาณให้ครบถ้วน');
        return;
      }

      if (appointmentData.id &&
        ((!appointmentData.documents || appointmentData.documents.length === 0) && (!docUpload || docUpload.length === 0))) {
        toast.error('กรุณาแนบเอกสาร');
        return;
      }

      if (!appointmentData.id && (!docUpload || docUpload.length === 0)) {
        toast.error('กรุณาแนบเอกสาร');
        return;
      }

      if (appointMemorandumDate) {
        apmDate = new Date(appointMemorandumDate);
      }

      if (expectingProcurementAt) {
        expectDate = new Date(expectingProcurementAt);
      }

      const appData: AppointmentDetailModel = {
        id: appointmentData.id,
        planId,
        departmentId,
        budgetYear: formData.budgetYear,
        planName: formData.planName,
        supplyMethod: supplyMethodId,
        supplyMethodType: supplyMethodTypeId,
        supplyMethodSpecialType: supplyMethodSpecialId,
        expectingProcurementAt: expectDate!,
        memorandumDate: apmDate!,
        planBudget: formData.planBudget,
        financialAmount: appointmentData.financialAmount ? appointmentData.financialAmount : formData.planBudget,
        byOrderFrom: formData.byOrderFrom,
        subject: formData.subject,
        status: appointmentData.status,
        createdByUserFullName: '',
        createdInfoAt: new Date(),
        updateByUserFullName: '',
        updatedInfoAt: new Date(),
        appointApprover: appointmentData.appointApprover,
        appointPreProcurementDocuments: appointmentData.appointPreProcurementDocuments,
        committeeTors: appointmentData.committeeTors,
        dutyCommitteeTors: appointmentData.dutyCommitteeTors,
        committeeMiddlePrices: appointmentData.committeeMiddlePrices,
        dutyCommitteeMiddlePrices: appointmentData.dutyCommitteeMiddlePrices,
        approverAppointPreProcurements: appointmentData.approverAppointPreProcurements,
        documents: appointmentData.documents,
        files: docUpload,
        documentDeletes: delFiles,
      };
      if (appData) {
        setForm(appData);
        onClickNext();
      } else {
        toast.error('กรุณากรอกข้อมูลให้ครบถ้วน');
      }
    },
  });

  useEffect(() => {
    initFormData((pre) => ({
      ...pre,
      planBudget: appointmentData.planBudget,
      budgetYear: appointmentData.budgetYear,
      financialAmount: appointmentData.financialAmount,
      byOrderFrom: appointmentData.byOrderFrom,
      subject: appointmentData.subject,
      planName: appointmentData.planName,
    }));

    if (appointmentData && appointmentData.documents) {
      handleSetFile(appointmentData.documents);
    }
  }, [initFormData, appointmentData]);

  const handleSetFile = (documents: AppointmentDocumentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    if (docName) {
      setDelFiles((pre) => [...pre, docName]);
    }

    const newArray = [...files];

    newArray.splice(index, 1);

    setFiles(newArray);
  }, [files, appointmentData.id]);

  return (
    <Form onSubmit={handleSubmit} id="updateForm">
      <Card className="mt-3">
        <Card.Body>
          <Title text="ข้อมูลแผน" className="fs-5 text-primary" />
          <Row className="mt-3 align-items-center">
            <Col sm={12} md={6} xl={3}>
              <Form.Group className="mb-3">
                <Selector
                  label="ฝ่าย/สำนัก"
                  items={departmentDDL}
                  rule={{ required: true }}
                  value={`${departmentId}`}
                  onChange={(value) => (setDepartmentId(value.toString()))}
                  disabled={isDisabled}
                />
              </Form.Group>
            </Col>
            <Col sm={12} xl={6}>
              <Form.Group className="mb-3">
                <Selector
                  label="แผน"
                  items={planDDL}
                  value={`${planId}`}
                  onChange={(value) => (getPlanDetail(value.toString()))}
                  disabled={isDisabled}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Button variant="link" href={`/pl/pl01/detail-pl01/${planId}`} disabled={!planId}><TbListSearch
                className="fs-4"
              /> ไปยังแผน
              </Button>
            </Col>
            <Col sm={12} xl={9}>
              <Form.Group className="mb-3">
                <Form.Label>ชื่อโครงการ <span className="text-danger">*</span></Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={formData.planName || ''}
                    onChange={handleChange<string>('planName', (value) => value)}
                    disabled={isDisabled}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} xl={3}>
              <Form.Group className="mb-3">
                <Selector
                  label="วิธีการจัดหา"
                  items={supplyMethodDDL}
                  rule={{ required: true }}
                  value={`${supplyMethodId}`}
                  onChange={(value) => (onChangeSupplyMethod(value.toString()))}
                  disabled={isDisabled}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} xl={3}>
              <Form.Group className="mb-3">
                <Selector
                  className="no-label"
                  items={supplyMethodNameDDL}
                  rule={{ required: true }}
                  value={`${supplyMethodTypeId}`}
                  onChange={(value) => (setSupplyMethodTypeId(value.toString()))}
                  disabled={isDisabled}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} xl={3}>
              <Form.Group className="mb-3">
                <Selector
                  className="no-label"
                  items={supplyMethodSpecialDDL}
                  rule={{ required: true }}
                  value={`${supplyMethodSpecialId}`}
                  onChange={(value) => (setSupplyMethodSpecialId(value.toString()))}
                  disabled={isDisabled}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} xl={3}>
              <Form.Group className="mb-3">
                <Form.Label>งบประมาณโครงการ (บาท) <span className="text-danger">*</span></Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    name="planBudget"
                    value={formData.planBudget || ''}
                    type="number"
                    onChange={handleChange<string>('planBudget', (value) => value)}
                    disabled={isDisabled}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={12} md={6} xl={3}>
              <Form.Group className="mb-3">
                <Form.Label>ปีงบประมาณ <span className="text-danger">*</span></Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    name="budgetYear"
                    value={formData.budgetYear || ''}
                    type="number"
                    onChange={handleChange<string>('budgetYear', (value) => value)}
                    disabled={isDisabled}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={12} md={6} xl={3}>
              <Form.Group className="mb-3">
                <DatePicker
                  label="ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง"
                  rule={{ required: true }}
                  value={expectingProcurementAt}
                  onChange={setExpectingProcurementAt}
                  disabled={isDisabled}
                  monthYearOnly
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body>
          <Title text="ข้อมูลเอกสารบันทึกข้อความแต่งตั้ง" className="fs-5 text-primary" />
          <Row className="mt-3 align-items-center">
            <Col sm={12} md={4} xl={3}>
              <Form.Group className="mb-3">
                <DatePicker
                  label="วันที่เอกสารบันทึกข้อความแต่งตั้ง"
                  rule={{ required: true }}
                  value={appointMemorandumDate}
                  onChange={setAppointMemorandumDate}
                  disabled={isDisabled}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>ตามคำสั่ง <span className="text-danger">*</span></Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    value={formData.byOrderFrom || ''}
                    onChange={handleChange<string>('byOrderFrom', (value) => value)}
                    disabled={isDisabled}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>เรื่อง <span className="text-danger">*</span></Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    value={formData.subject || ''}
                    onChange={handleChange<string>('subject', (value) => value)}
                    disabled={isDisabled}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <p className="mb-0">เอกสารแนบ</p>
          <Card>
            <Card.Body>
              <Row className="justify-content-center">
                <Col sm={12} lg={8}>
                  <UploadFile
                    disabled={isDisabled}
                    files={files}
                    handleFileChange={handleFileChange}
                    removeItem={removeItemAsync}
                    appointmentId={appointmentData.id}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row className="mt-3">
            <Col sm={3}>
              <Form.Group className="mb-3">
                <Form.Label>สร้างโดย</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    value={appointmentData.createdByUserFullName}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group className="mb-3">
                <Form.Label>วันที่สร้าง</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    value={`${fullDatetimeTH(appointmentData.createdInfoAt)}`}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-end pt-3">
        <Button
          type="submit"
          form="updateForm"
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </Form>
  );
}

export default Step1;
