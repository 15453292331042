import { AppointmentOrderStatus } from 'components';
import StepProgress from 'components/StepProgress';
import { IFile } from 'models';
import { AppointCommiteeDetailResponseModel } from 'models/procurementAppointmentOrderModel';
import React, {
  createContext,
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import procurementAppointmentOrderServices from 'services/procurementAppointmentOrderServices';
import {
  HttpStatusCode,
  useAppContext,
} from 'utils';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type PR0202Context = {
  data: AppointCommiteeDetailResponseModel
  setData: Dispatch<React.SetStateAction<AppointCommiteeDetailResponseModel>>,
};

export const Context = createContext({} as PR0202Context);

function AddPR0204() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id } = useParams();
  const { jorPor05Id } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState<AppointCommiteeDetailResponseModel>({} as AppointCommiteeDetailResponseModel);
  const [files, setFiles] = useState<IFile[]>([]);
  const { userId } = useAppContext();
  const [defualtStep, setDefualtStep] = useState(1);

  useEffect(() => {
    getAppointmentApprovalDetailById(jorPor05Id, id);
  }, [jorPor05Id, id]);

  const getAppointmentApprovalDetailById = useCallback(async (jorPor05Id?: string, id?: string) => {
    const response = await procurementAppointmentOrderServices.getAppointmentOrderByIdAsync(jorPor05Id, id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
    }

    if (response.data.status === AppointmentOrderStatus.PENDING || response.data.status === AppointmentOrderStatus.APPROVED) {
      setDefualtStep(3);
    }
  }, [jorPor05Id, id]);

  const obj = [{
    title: <p>
      ข้อมูลคำสั่งแต่งตั้งคณะกรรมการ
      จัดซื้อจัดจ้างและคณะกรรมการรตรวจรับ
    </p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p>ลำดับเห็นชอบ/อนุมัติ</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  {
    title: <p>ตัวอย่างเอกสาร</p>,
    displayIndex: '3',
    child: <Step3 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/procurement-appointment-order');
  };

  return (
    <div className='document'>
      <h4 className='mt-2 mb-0 text-primary text-start'>จัดทำคำสั่งแต่งตั้งคณะกรรมการจัดซื้อจัดจ้างและคณะกรรมการตรวจรับ</h4>
      <Row>
        <Col xs={12} className='mb-2'>
          <Context.Provider value={{ data, setData }}>
            <StepProgress progressBarData={obj} ref={childRef} step={defualtStep} />
          </Context.Provider>
          {' '}
          <></>
        </Col>
      </Row>
    </div>
  );
}

export default AddPR0204;
