import {
  Card,
  Image,
} from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import { FaTrash } from 'react-icons/fa';
import { IFile } from '../../../models/P/FileUploadModel';
import PL01Services from '../../../services/PL/PL0201Service';
import { HttpStatusCode } from '../../../utils';

interface Props {
  planId: string;
  files: IFile[];
  handleFileChange: (files: File[]) => void;
  removeItem: (index: number, docId: string) => void;
  disabled?: boolean;
}

const fileTypes = ['PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PNG', 'JPG', 'JPEG', 'mp3'];

function UploadFile({ files, handleFileChange, removeItem, planId, disabled = false }: Props) {
  return (
    <>
      <div className='file-upload'>
        <FileUploader
          disabled={disabled}
          multiple
          handleChange={handleFileChange}
          name='file'
          types={fileTypes}
          hoverTitle='-'
        />
      </div>
      {
        Array.from(files)
          .map((f, index) => (
            <RenderFile key={f.id}
              planId={planId}
              file={f}
              removeItem={() => removeItem(index, f.id)}
              disabled={disabled} />))
      }
    </>
  );
}

function RenderFile(props: { planId: string, file: IFile, removeItem: () => void, disabled: boolean }) {
  const handleOpenFileAsync = async (planId: string, file: IFile) => {
    if (!file.file) {
      const { data, status } = await PL01Services.getAnnualPlanDocumentAsync(planId, file.id);

      if (status !== HttpStatusCode.OK) {
        return;
      }

      const fileUrl = URL.createObjectURL(data);
      window.open(fileUrl, '_blank');

      return;
    }

    const fileUrl = URL.createObjectURL(file.file);
    window.open(fileUrl, '_blank');
  };

  const getIcon = (fileName: string) => {
    const fileExtension = fileName.split('.')
      .pop()
      ?.toLowerCase();

    if (fileExtension === 'doc' || fileExtension === 'docx' || fileExtension === 'odt') {
      return '/icons/icon-word.svg';
    }
    if (fileExtension === 'pdf') {
      return '/icons/icon-pdf.svg';
    }
    if (fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'ods') {
      return '/icons/XSL.svg';
    }
    return 'FcDocument';
  };

  return (
    <Card className='my-2 pointer'>
      <Card.Body className='px-2 py-1'>
        <div className='d-flex justify-content-between align-items-center'>
          <div
            className='d-flex justify-content-between align-items-center'
            onClick={() => handleOpenFileAsync(props.planId, props.file)}
          >
            <Image src={getIcon(props.file.name)}
              alt=''
              width={50} />
            <div className='ps-2'>
              <p className='mb-0'>{props.file.name}</p>
              <p className='mb-0 text-muted'><small>{props.file.file?.size ?? ''}</small></p>
            </div>
          </div>
          {!props.disabled ? <FaTrash className='text-muted'
            onClick={() => props.removeItem()} /> : <></>}
        </div>
      </Card.Body>
    </Card>
  );
}

export default UploadFile;
