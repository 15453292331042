import { Input, Table } from 'components';
import { InputNumber } from 'components/Controls/InputNumber';
import { MedianPriceStaffModel } from 'models/PP/MedianPriceModel';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { ValidatePattern } from 'utils';

interface IMedianPriceStaffProps {
  medianPriceId: string;
  medianPriceStaffs: MedianPriceStaffModel[];
  onMedianPriceStaffs: (value: MedianPriceStaffModel[]) => void;
  isDisabled?: boolean;
}

export function MedianPriceStaff(props: IMedianPriceStaffProps) {
  const [medianPriceStaffs, setMedianPriceStaffs] = useState(props.medianPriceStaffs);

  useEffect(() => {
    props.onMedianPriceStaffs(medianPriceStaffs);
  }, [medianPriceStaffs]);

  useEffect(() => {
    if (props.medianPriceStaffs) {
      setMedianPriceStaffs(props.medianPriceStaffs);
    }
  }, [props.medianPriceStaffs]);

  const addStaff = useCallback(async () => {
    const newData: MedianPriceStaffModel = {
      id: undefined,
      medianPriceId: props.medianPriceId,
      description: '',
      count: 0,
      sequence: medianPriceStaffs.length + 1,
    };

    setMedianPriceStaffs((medianPriceStaffs) => [...medianPriceStaffs, newData]);
  }, [medianPriceStaffs]);

  const handlerOnChangeDesc = (value: string, index: number) => {
    const data = [...medianPriceStaffs];
    data[index].description = value;
    setMedianPriceStaffs(data);
  };

  const handlerOnChangeCount = (value: number | undefined, index: number) => {
    const data = [...medianPriceStaffs];
    data[index].count = Number(value);
    setMedianPriceStaffs(data);
  };

  const removeStaff = (i: number) => {
    const temp: MedianPriceStaffModel[] = [...medianPriceStaffs];
    temp.splice(i, 1);
    setMedianPriceStaffs(temp);
  };

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between'>
        <p className='mb-0'>รายการบุคลากร</p>
        <Button
          variant='outline-primary'
          onClick={addStaff}
          disabled={props.isDisabled}
        >
          <FaPlus className='me-1' />เพิ่มรายการ
        </Button>
      </div>
      <Row className='align-items-center'>
        <Col sm={12}>
          <Table total={1}
            hidePagination>
            <thead>
              <tr>
                <th style={{ width: 20 }}>ลำดับ</th>
                <th>รายการ</th>
                <th style={{ width: 300 }}>จำนวน (คน)</th>
                <th style={{ width: 20 }} />
              </tr>
            </thead>
            <tbody>
              {medianPriceStaffs?.sort((a, b) => a.sequence - b.sequence)
                .map((data, index) => (
                  <tr key={index}>
                    <td className='text-center'>{index + 1}</td>
                    <td>
                      <Input
                        value={data.description}
                        onChange={(val) => handlerOnChangeDesc(val, index)}
                        rule={{ required: true }}
                        disabled={props.isDisabled}
                      />
                    </td>
                    <td>
                      <InputNumber
                        value={data.count}
                        onChange={(val) => handlerOnChangeCount(val, index)}
                        rule={{ required: true, pattern: ValidatePattern.NUMBER }}
                        disabled={props.isDisabled}
                      />
                    </td>
                    <td className='text-end'>
                      <Button
                        onClick={() => removeStaff(index)}
                        variant='danger'
                        disabled={props.isDisabled}
                      >
                        <FaTrashAlt />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
