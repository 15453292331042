import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaFileAlt,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaPaperclip,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  ButtonCustom,
  Card,
  Check,
  Currency,
  Input,
  Modal,
  Selector,
  Table,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import {
  ContractAgreementDocumentArchivedModel,
  ContractAgreementDocumentModel,
  ContractAgreementModel,
} from '../../../../../../models/CM/CM02Models';
import { shareValue } from '../../../../../../services';
import CM02Service from '../../../../../../services/CM/CM02Service';
import { HttpStatusCode } from '../../../../../../utils';
import { SharedvalueGroup, SubGroup } from '../../../../../../utils/constants/ContractEnum';
import toast from '../../../../../../utils/toast';
import { UploadDocumentModal } from '../../../Modal/UploadDocumentModal';
import { ContractAppendixOtherIdEnum } from 'utils/constants/ShareValueEnum';
import { InputNumber } from 'components/Controls/InputNumber';

const fileIcon = (name: string) => {
  switch (true) {
    case name.includes('.pdf'):
      return (<FaFilePdf />);
    case name.includes('.doc') || name.includes('.docx'):
      return (<FaFileWord />);
    case name.includes('.xls') || name.includes('.xlsx'):
      return (<FaFileExcel />);
    case name.includes('.csv'):
      return (<FaFileCsv />);
    case name.includes('.png') || name.includes('jpg') || name.includes('jpeg'):
      return (<FaFileImage />);
    default:
      return (<FaFileAlt />);
  }
};

interface IContractAttachmentProps {
  title: string;
  contractId: string;
  context: ContractAgreementModel;
  isDisabled?: boolean;
  isResponsible?: boolean;
  onDocumentChange: (data: ContractAgreementDocumentModel[]) => void;
}

export function ContractAttachment(props: IContractAttachmentProps) {
  const [contractAgreementDocuments, setContractAgreementDocuments] = useState<ContractAgreementDocumentModel[]>([]);
  const [contractDocumentId, setContractDocumentId] = useState<string>('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState<string>('');
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [contractAttachmentDDL, setContractAttachmentDDL] = useState<ItemModel[]>([]);

  useEffect(() => {
    if (props.context.documents &&
      props.context.documents.length > 0) {
      setContractAgreementDocuments(props.context.documents);
      props.onDocumentChange(props.context.documents);
    }

    onGetContractAttachmentType();
  }, [props.context]);

  const onGetContractAttachmentType = async () => {
    const itemForMap: ItemModel[] = [];
    const contractTemplate = (props.context.subContractTemplate && props.context.subContractTemplate.length > 0) ? props.context.subContractTemplate : props.context.contractTemplate;

    const response = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractAppendix, contractTemplate, SubGroup.ContractAppendixOther);
    const performanceBondReturn: ItemModel[] = response.data;

    performanceBondReturn.forEach((data) => itemForMap.push({
      label: data.label,
      value: data.value ?? '',
      value2: data.value2,
    }));
    setContractAttachmentDDL(itemForMap);

    if (firstLoad && props.context.documents.length === 0) {
      defaultValue(performanceBondReturn);
      setFirstLoad(false);
    }
  };

  const defaultValue = useCallback((items: ItemModel[]) => {
    const documents = [] as ContractAgreementDocumentModel[];

    if (contractAgreementDocuments && contractAgreementDocuments.length === 0) {
      items.filter(i => i.value !== ContractAppendixOtherIdEnum.อื่นๆระบุ).forEach((data, index) => {
        const newData: ContractAgreementDocumentModel = {
          id: undefined,
          contractAgreementId: props.contractId,
          sequence: index,
          type: data.value,
          otherType: '',
          referenceRemark: '',
          pageCount: 0,
          qualityCertificate: false,
          attachments: [],
        };

        setContractAgreementDocuments((contractAgreementDocuments) => [...contractAgreementDocuments, newData]);
        documents.push(newData);
      });

      if (documents) {
        props.onDocumentChange(documents);
      }
    }
  }, []);

  const getDocumentContractAttachmentByIdAsync = async (id: string, docId: string) => {
    const {
      data,
      status,
    } = await CM02Service.getDocumentAttachmentByDocIdAsync(id, docId);
    if (status === HttpStatusCode.OK) {
      setContractAgreementDocuments([]);
      const attachments = data.attachments as ContractAgreementDocumentArchivedModel[];
      const newData = [...contractAgreementDocuments];
      const index = contractAgreementDocuments.findIndex(x => x.id === docId);
      newData[index].attachments = attachments;

      setContractAgreementDocuments(newData);
      props.onDocumentChange(newData);
    }
  };

  const onOpenUploadDocumentModal = (id: string) => {
    setContractDocumentId(id);
    setShowUploadModal(true);
  };

  const onCloseUploadModal = () => {
    setShowUploadModal(!showUploadModal);
    getDocumentContractAttachmentByIdAsync(props.contractId, contractDocumentId);
  };

  const onDownloadFileDocument = async (contractDocId: string, fileId: string) => {
    if (contractDocId && fileId) {
      const res = await CM02Service.getAttachmentDocumentAsync(
        contractDocId,
        fileId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank');
      }
    }
  };

  const onDeleteFileAsync = async () => {
    setShowDeleteFileModal(false);
    if (contractDocumentId && deleteFileId) {
      const res = await CM02Service.removeFileDocumentAsync(
        contractDocumentId,
        deleteFileId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getDocumentContractAttachmentByIdAsync(props.contractId, contractDocumentId);
      }
    }
  };

  const onDeleteFile = (id: string, attachmentId: string) => {
    setContractDocumentId(id);
    setDeleteFileId(attachmentId);
    setShowDeleteFileModal(true);
  };

  const addDocument = (() => {
    const newData: ContractAgreementDocumentModel = {
      id: undefined,
      contractAgreementId: '',
      sequence: 0,
      type: '',
      referenceRemark: '',
      pageCount: 0,
      qualityCertificate: false,
      attachments: [],
    };

    props.onDocumentChange([...contractAgreementDocuments, newData]);
    setContractAgreementDocuments((contractAgreementDocuments) => [...contractAgreementDocuments, newData]);
  });

  const removeDocument = (i: number) => {
    const newData = [...contractAgreementDocuments];

    newData.splice(i, 1);
    setContractAgreementDocuments(newData);
    props.onDocumentChange(newData);
  };

  const handlerOnChangeDocType = (value: string, index: number) => {
    const data = [...contractAgreementDocuments];
    data[index].type = value;
    setContractAgreementDocuments(data);
    props.onDocumentChange(data);
  };

  const handlerOnCheckQualityCertificate = (value: boolean, index: number) => {
    const data = [...contractAgreementDocuments];
    data[index].qualityCertificate = value;
    setContractAgreementDocuments(data);
    props.onDocumentChange(data);
  };

  const handlerOnChangeRefRemark = (value: string, index: number) => {
    const data = [...contractAgreementDocuments];
    data[index].referenceRemark = value;
    setContractAgreementDocuments(data);
    props.onDocumentChange(data);
  };

  const handlerOnChangePageCount = (value: number, index: number) => {
    const data = [...contractAgreementDocuments];
    data[index].pageCount = value;
    setContractAgreementDocuments(data);
    props.onDocumentChange(data);
  };

  const handlerOnChangeOtherType = (value: string, index: number) => {
    const data = [...contractAgreementDocuments];
    data[index].otherType = value;
    setContractAgreementDocuments(data);
    props.onDocumentChange(data);
  };

  return (
    <>
      <Card className='mt-3'>
        <Title text={`สัญญา${props.title}`}
          className='fs-5 text-primary' />
        <Row className='align-items-center'>
          <Col sm={6}
            md={6}>
            <p className='ms-5'>เอกสารแนบท้ายสัญญาดังต่อไปนี้ ให้ถือเป็นส่วนหนึ่งของสัญญานี้</p>
          </Col>
          <Col sm={6}
            md={6}
            className='text-end'>
            {props.isResponsible && (<ButtonCustom
              icon='add'
              text='เพิ่มรายการ'
              onClick={() => addDocument()}
              disabled={props.isDisabled} />)}
          </Col>
        </Row>

        <Row>
          <Table hidePagination>
            <thead>
              <tr className='text-center'>
                <th style={{ width: '50%' }}>ชื่อเอกสาร</th>
                <th style={{ width: '20%' }}>ข้อความอ้างอิง</th>
                <th style={{ width: '10%' }}>จำนวน ( หน้า )</th>
                <th style={{ width: '10%' }}>เอกสารแนบ</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {contractAgreementDocuments.map((data, i) => (
                <Fragment key={data.id}>
                  <tr className='text-center'>
                    <td className='text-start d-flex'>
                      <div style={{ width: `${data.type !== ContractAppendixOtherIdEnum.อื่นๆระบุ ? '100%' : '30%'}` }}>
                        <Selector items={contractAttachmentDDL}
                          value={data.type}
                          onChange={(val) => handlerOnChangeDocType(val, i)}
                          disabled={props.isDisabled} />
                      </div>
                      {
                        data.type === ContractAppendixOtherIdEnum.อื่นๆระบุ &&
                        (
                          <div style={{ width: '70%', marginTop: '1px' }}>
                            <Input type='text'
                              className='ps-1'
                              value={data.otherType}
                              onChange={(val) => handlerOnChangeOtherType(val, i)}
                              rule={{ required: true }}
                              disabled={props.isDisabled} />
                          </div>
                        )
                      }
                    </td>
                    <td>
                      <Input type='text'
                        value={data.referenceRemark}
                        onChange={(val) => handlerOnChangeRefRemark(val, i)}
                        disabled={props.isDisabled} />
                    </td>
                    <td>
                      <InputNumber value={data.pageCount}
                        onChange={(val) => handlerOnChangePageCount(Number(val), i)}
                        disabled={props.isDisabled} />
                    </td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <Button
                          variant='outline-primary'
                          className='d-flex align-items-center gap-2'
                          onClick={() => onOpenUploadDocumentModal(data.id!)}
                          disabled={data.id === undefined}
                        >
                          แนบเอกสาร <FaPaperclip />
                        </Button>
                      </div>
                    </td>
                    <td>
                      {
                        !props.isDisabled && props.isResponsible
                          ? (
                            <div className='d-flex justify-content-end'>
                              <Button
                                disabled={props.isDisabled}
                                variant='danger'
                                onClick={() => removeDocument(i)}>
                                <FaTrashAlt />
                              </Button>
                            </div>
                          ) : <></>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {
                        data?.attachments.map((att, j) => (
                          <div className='upload-file'
                            key={j}>
                            <div className='file-list'>
                              <div className='file'>
                                <div className='file-icon'>
                                  {fileIcon(att.fileName)}
                                </div>
                                <div className='detail'>
                                  <a onClick={() => onDownloadFileDocument(data.id!, att.id)}
                                    className='filename'>
                                    {att.fileName}
                                  </a>
                                </div>
                                <div className='trash'>
                                  <Button
                                    disabled={props.isDisabled}
                                    variant='danger'
                                    onClick={() => onDeleteFile(data.id!, att.id)}>
                                    <FaTrashAlt />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        </Row>
      </Card>

      <UploadDocumentModal
        show={showUploadModal}
        onHide={() => setShowUploadModal(!showUploadModal)}
        contractDocumentId={contractDocumentId}
        onUploaded={onCloseUploadModal}
      />

      <Modal
        show={showDeleteFileModal}
        size='lg'
        onHide={() => setShowDeleteFileModal(!showDeleteFileModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการยืนยันลบไฟล์หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDeleteFileModal(!showDeleteFileModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onDeleteFileAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>

  );
}
