import { AssignParams } from 'models';
import http from 'utils/axios';

const getAssignListAsync = async (page: number, size: number, criteriaData?: AssignParams) => {
  const params = {
    page,
    size,
    ...criteriaData,
  };

  return await http.get('/assign', { params });
};

const getAssignDetailAsync = async (id: string) => await http.get(`/assign/${id}`);

const updateAssignAsync = async (planId: string, assignId: string[]) => {
  const body = assignId.map((data) => data);

  return await http.put(`/assign/${planId}`, body);
};

export default {
  getAssignListAsync,
  updateAssignAsync,
  getAssignDetailAsync,
};