import Cookie from 'cookie-universal';
import { UserContactModel } from 'models/UserContactModels';
import http from 'utils/axios';

const qs = require('qs');

const api = '/userContact';
const cookies = Cookie();
const getFullName = cookies.get('fullName') as string;

const createUserContactAsync = (body: UserContactModel) => {
    return http.post(`${api}`, body);
};

const updateUserContactAsync = (id: string, body: UserContactModel) => {
    return http.put(`${api}/${id}`, body);
};

const getUserContactDetailAsync = async (id: string) => await http.get(`${api}/${id}`);

export default {
    createUserContactAsync,
    updateUserContactAsync,
    getUserContactDetailAsync,
}