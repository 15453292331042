import http from 'utils/axios';

const getAuditLogListAsync = async (
  size: number,
  page: number,
  startDate?: Date,
  endDate?: Date,
  username?: string,
  programName?: string,
) => await http.get('/auditlog', {
  params: {
    size,
    page,
    startDate,
    endDate,
    username,
    programName,
  },
});

export default {
  getAuditLogListAsync,
};