import {
  Card,
  Input,
  InputTextArea,
  Radio,
  Selector,
  UploadFile,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
  Table,
} from 'react-bootstrap';
import {
  MdAdd,
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { TbListSearch } from 'react-icons/tb';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const INFO = [
  { label: 'ไทย', value: '1' },
  { label: 'ต่างชาติ', value: '2' },
];

const INFOTYPE = [
  { label: 'นิติบุคคล', value: '1' },
  { label: 'บุคคลธรรมดา', value: '2' },
];

function Step1({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลหนังสือเชิญชวนผู้ประกอบการ" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="เลขที่เอกสาร PR" value="เลขที่เอกสาร PR" disabled />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} md={4}>
            <Selector label="โครงการ" disabled />
          </Col>
          <Col sm={12} md={3}>
            <Button variant="link"><TbListSearch className="fs-4" /> ไปยังแผน</Button>
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="งบประมาณโครงการ (บาท)" value="1,000,000.00" disabled textEnd rule={{ required: true }} />
          </Col>
          <Col sm={12} md={4}>
            <Input label="วงเงิน" value="1,000,000.00" disabled textEnd rule={{ required: true }} />
          </Col>
          <Col sm={12} md={4} className="pt-4">
            <Input value="เจาะจง" disabled />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="กำหนด ข้อมูลรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง (จพ.006)" className="fs-5 text-primary" />
        <Row>
          <Col sm={12}>
            <Selector label="รูปแบบเอกสาร จพ.006" />
          </Col>
          <Col sm={12}>
            <Input label="เรื่อง" placeholder="เรื่อง" />
          </Col>
          <Col sm={12}>
            <p>ผู้ได้รับการคัดเลือก</p>
            <Button
              className="me-2 px-3"
              onClick={() => setShow(true)}
              variant="light"
            >
              <MdAdd className="me-2 pb-1 fs-5" />
              เพิ่มข้อมูล
            </Button>
          </Col>
          <Col sm={12} className="mt-3">
            {showTable
              && (
                <Table>
                  <thead>
                    <tr>
                      <th style={{ minWidth: 20 }}>ลำดับ</th>
                      <th style={{ minWidth: 150 }}>เลขประจำตัวผู้เสียภาษี</th>
                      <th style={{ minWidth: 150 }}>ประเภทผู้ประกอบการ</th>
                      <th style={{ minWidth: 150 }}>ชื่อสถานประกอบการ</th>
                      <th style={{ minWidth: 150 }}>อีเมล</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">1</td>
                      <td className="text-center">8765432119861</td>
                      <td className="text-center">บริษัทจำกัด</td>
                      <td className="text-center">เครื่องไฟฟ้าไทย</td>
                      <td className="text-center">egpmail@gmail.com</td>
                    </tr>
                  </tbody>
                </Table>
              )}
          </Col>
          <Col sm={12} className="mt-3">
            <Input label="เสนอราคาเป็นเงิน" placeholder="1,974,599.97" textEnd />
          </Col>
          <Col sm={12}>
            <InputTextArea label="การพิจารณา" />
          </Col>
          <Col sm={12}>
            <InputTextArea label="ขออนุมัติ" />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          className="me-2 px-3"
          onClick={onClickBack}
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
      <Modal
        show={show}
        size="xl"
        onHide={() => setShow(!show)}
      >
        {ContentModal()}
        <ModalBT.Footer className="justify-content-center mt-3">
          <Button
            className="me-2 px-3"
            variant="light"
          >
            ย้อนกลับ
          </Button>
          <Button
            onClick={() => (setShow(!show), setShowTable(true))}
            className="me-2 px-3"
          >
            บันทึก
          </Button>
        </ModalBT.Footer>
      </Modal>
    </div>
  );
}

export default Step1;

function ContentModal() {
  return (
    <>
      <Title text="ข้อมูลผู้ประกอบการ" className="fs-5 text-primary" />
      <Row className="mt-3">
        <Col sm={12} lg={6}>
          <Input label="เลขประจำตัวผู้เสียภาษี" placeholder="เลขประจำตัวผู้เสียภาษี" textSearch rule={{ required: true }} />
        </Col>
        <Col sm={12}>
          <Radio
            className="mt-3"
            label="สัญชาติของผู้ประกอบการ"
            inline
            items={INFO}
            name='name1'
            rule={{ required: true }}
          />
        </Col>
        <Col sm={12}>
          <Radio
            className="mt-3"
            label="ประเภทผู้ประกอบการ"
            inline
            items={INFOTYPE}
            name='name2'
            rule={{ required: true }}
          />
        </Col>
        <Col sm={12} lg={6}>
          <Selector label="ประเภทผู้ประกอบการ" placeholder="ประเภทผู้ประกอบการ" rule={{ required: true }} />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="ชื่อสถานประกอบการ" placeholder="ชื่อสถานประกอบการ" rule={{ required: true }} />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="ชื่ออาคาร/ชื่อสถานที่" placeholder="ชื่ออาคาร/ชื่อสถานที่" rule={{ required: true }} />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="ห้องเลขที่" placeholder="ห้องเลขที่" />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="ชั้นที่" placeholder="ชั้นที่" />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="ชื่อหมู่บ้าน" placeholder="ชื่อหมู่บ้าน" />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="เลขที่" placeholder="เลขที่" />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="หมู่ที่" placeholder="หมู่ที่" />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="ซอย" placeholder="ซอย" />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="ถนน" placeholder="ถนน" />
        </Col>
        <Col sm={12} lg={6}>
          <Selector label="จังหวัด" placeholder="จังหวัด" rule={{ required: true }} />
        </Col>
        <Col sm={12} lg={6}>
          <Selector label="อำเภอ/เขต" placeholder="อำเภอ/เขต" rule={{ required: true }} />
        </Col>
        <Col sm={12} lg={6}>
          <Selector label="ตำบล/แขวง" placeholder="ตำบล/แขวง" rule={{ required: true }} />
        </Col>
        <Col sm={12} lg={6}>
          <Selector label="รหัสไปรษณีย์" placeholder="รหัสไปรษณีย์" rule={{ required: true }} />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="หมายเลขโทรศัพท์สำหรับติดต่อ" placeholder="098-765-4321" rule={{ required: true }} />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="หมายเลขโทรสาร" placeholder="หมายเลขโทรสาร" />
        </Col>
        <Col sm={12} lg={6}>
          <Input label="อีเมล" placeholder="อีเมล" rule={{ required: true }} />
        </Col>
      </Row>
      <Title text="อัปโหลดเอกสาร" className="fs-5 text-primary" />
      <Row className="justify-content-center">
        <Col sm={12} md={6}>
          <UploadFile />
        </Col>
      </Row>
    </>
  );
}
