export enum PaymentTypeIdEnum {
  PayByWorkProgress = '0eed885d-12c8-3366-ba87-1a3e2f65dbdc', // จ่ายตามความก้าวหน้าของงาน
  PayByPhaseAssign = '59dc7cf8-bba7-dc09-ea73-746a1ec73a86', // จ่ายตามงวดที่กำหนด
}

export enum PeriodConditionIdEnum {
  นับถัดจากวันลงนามในสัญญา = '120cc467-bec6-afe2-439a-153932ba88d0', // นับถัดจากวันลงนามในสัญญา
  นับถัดจากวันที่กำหนดในสัญญา = '05524de3-326c-8cf8-f88f-6431ae3768bb', // นับถัดจากวันที่กำหนดในสัญญา
  นับถัดจากวันที่ได้รับหนังสือแจ้งให้เริ่มงาน = '4ba16353-bcb8-021b-993a-5da537bf1e39', //นับถัดจากวันที่ได้รับหนังสือแจ้งให้เริ่มงาน
  นับตั้งแต่วันที่ลงนามในสัญญา = '417e6efb-bff3-405f-ba6c-2b5609dec8d3', //นับตั้งแต่วันที่ลงนามในสัญญา
  ตามระยะเวลาที่ระบุ = 'bcd9fd07-8b4c-eea6-e417-af9d18254055', // ตามระยะเวลาที่ระบุ
}

export enum WarrantyConditionIdEnum {
  นับตั้งแต่วันที่ได้รับมอบ = 'b2e8be20-f667-74a6-aacd-5777b9124cec', // นับตั้งแต่วันที่ได้รับมอบ
  นับถัดจากวันที่ได้รับมอบ = '8f86e195-249c-4d3e-85bc-ba7b9e2353f1', // นับถัดจากวันที่ได้รับมอบ
}

export enum PeriodTypeIdEnum {
  วัน = '8374eeab-002a-42bd-afb3-6023c49445d0',
  เดือน = '486eb370-d80f-4de5-bd27-b8427c6caf42',
  ปี = 'f029b1f5-0ea9-44af-bce0-4b715f0d0320',
}

export enum ContractAppendixOtherIdEnum {
  อื่นๆระบุ = '1093d2cb-1558-4100-acd0-5d6875156fac',
}

export enum FineTypeOtherIdEnum {
  อื่นๆระบุ = '5fa8c61c-32e2-4b0e-9615-f2a3ed803941',
}

export enum ContractStartDatePeriodConditionIdEnum {
  นับตั้งแต่วันที่ลงนามในสัญญา = '5ca25e42-2237-4449-a15d-579141d42205',
  นับถัดจากวันลงนามในสัญญา = 'f04800ed-5ecc-4ebf-818b-76f7fb9fb61c',
  ตามระยะเวลาที่ระบุ = '41c67a81-3444-44eb-b5cb-13daedbee579',
}

export enum ContractStartDatePeriodConditionValue2Enum {
  สัญญาการรักษาข้อมูลที่เป็นความลับ = 'CMPDPA001',
  สัญญาการประมวลผลข้อมูลส่วนบุคคล = 'CMPDPA002',
}

export enum WebLinkIdEnum {
  COI = '43c91073-4739-4163-886b-e62a4705046f',
  Watchlist = 'c83f695a-b2f4-4b0a-a1df-9953f2041d31',
  EGP = '5de34a29-41d1-441e-a17e-17883e47f34e',
}