import {
  Card,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Radio,
  Selector,
  Table,
  TorStatus,
  UploadFile,
  ArrowCollapse,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { HistoryModal } from 'components/Modal';
import {
  ItemModel,
  Trader,
} from 'models';
import {
  ApproveContractModel,
  ContractModel,
  ContractPaidPhaseModel,
  InstallmentModel,
} from 'models/CM/CreateContracModels';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaHistory,
  FaPlus,
  FaSave,
  FaTrashAlt,
  FaChevronCircleDown
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Context } from '.';
import {
  dataHistory,
  installment,
} from './data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const INFO = [
  { label: 'ละเว้น', value: '1' },
  { label: 'ไม่ละเว้น', value: '2' },
];

const PeriodType = [
  { label: 'วัน', value: 'วัน' },
  { label: 'เดือน', value: 'เดือน' },
  { label: 'ปี', value: 'ปี' },
];

const deliveryType = [
  { label: 'นับตั้งแต่วันลงนามในสัญญา', value: '1' },
  { label: 'นับถัดจากวันลงนามในสัญญา', value: '2' },
  { label: 'ตามหนังสือแจ้งยืนยัน', value: '3' },
  { label: 'ระบุวันที่ปฏิบัติงาน', value: '4' },
];

const warrantyType = [
  { label: 'ถัดจากวันสิ้นสุด', value: '1' },
];

const guaranteeType = [
  { label: 'ไม่มีหลักประกัน', value: '1' },
  { label: 'เงินสด', value: '2' },
  { label: 'หนังสือค้ำประกันของธนาคารภายในประเทศ', value: '3' },
  { label: 'เช็คหรือดราฟท์ที่ธนาคารเซ็นสั่งจ่าย', value: '4' },
  { label: 'หนังสือค้ำประกันของบริษัทเงินทุน', value: '5' },
  { label: 'พันธบัตรรัฐบาลไทย', value: '6' },
  { label: 'หนังสือค้ำประกันอิเล็กทรอนิกส์ของธนาคารภายในประเทศ', value: '7' },
];

const guaranteeDateType = [
  { label: 'ตามวันที่', value: '1' },
];

const supplyMethodDDL = [
  { label: 'ซื้อ', value: '1' },
  { label: 'จ้าง', value: '2' },
  { label: 'เช่า', value: '3' },
];

function Step3({ onClickNext, onClickBack }: Props) {
  const [showHistory, setShowHistory] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [showSearchNameModal, setModalSearchNameModal] = useState(false);
  const [approveContract, setapproveContract] = useState<ApproveContractModel[]>([]);
  const [posotionOnBoardItems, setPosotionOnBoardItems] = useState<ItemModel[]>([]);
  const [installmentData, setInstallmentData] = useState<InstallmentModel[]>([]);
  const { dataContext, setDataContext } = useContext(Context);
  const [data, setData] = useState<ContractModel>({} as ContractModel);
  const [isDisabled, setisDisabled] = useState(false);
  const [paidPhaseData, setPaidPhaseData] = useState<ContractPaidPhaseModel[]>([]);
  const [trader, setTrader] = useState<Trader>({} as Trader);
  const navigate = useNavigate();
  const goToPlan = (id: string) => {
    navigate(`/procurement-plan-summary/detail/${id}`);
  };

  const goToParcels = (id: string) => {
    navigate(`/create-parcels-contractMangement`);
  };

  useEffect(() => {
    if (dataContext) {
      setInstallmentData(installment);
      setData(dataContext);

      if (dataContext.trader) {
        setTrader(dataContext.trader);
      }
    }

  }, [dataContext]);

  const removeInstallment = (i: number) => {
    const temp: InstallmentModel[] = [...installmentData];
    temp.splice(i, 1);
    setInstallmentData(temp);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title text='ข้อมูลโครงการ'
          className='fs-5 text-primary' />
        <div className='d-flex align-items-center'>
          <p className='mb-0 me-4'>เลขที่โครงการ </p>
          <Button onClick={() => goToPlan('')}
            variant='link'>T0001</Button>
        </div>
        <div className='d-flex align-items-center'>
          <p className='mb-0 me-4'>ชื่อโครงการ </p>
          <p className='mb-0 ms-4'>จ้างที่ปรึกษา </p>
        </div>
      </Card>
      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <Title text='ข้อมูลใบสั่ง/สัญญา'
            className='fs-5 text-primary' />
          <div className='d-flex gap-2'>
            <TorStatus
              value='Draft' />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'>
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            xl={6}>
            <InputTextArea label='ชื่อสัญญา'
              value={data.contractName}
              disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='เลขที่สัญญา PO'
              placeholder='เลขที่สัญญา PO'
              value={data.poNumber}
              disabled />
          </Col>
          {/* <Col sm={12} md={3}>
            <DatePicker label='วันที่ลงนาม' placeholder='วันที่ลงนาม' disabled value={data.contractDate} />
          </Col> */}
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label='เลขที่สัญญา จพ.(สบส.)'
              disabled
              value={data.contractNo} />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <Selector
              label='ประเภทสัญญา'
              placeholder='กรุณาเลือก'
              items={supplyMethodDDL}
              value='1'
              rule={{ required: true }}
              disabled={false}
            />
          </Col>
        </Row>

        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={4}
            xl={3}>
            <Currency
              label='วงเงินตามสัญญา'
              placeholder='วงเงินตามสัญญา'
              value={data.contractBudget}
              disabled
            />
          </Col>
          <Col sm={12}
            md={3}>
            <Input label='หมายเลขกล่องที่จัดเก็บ'
              placeholder='หมายเลขกล่องที่จัดเก็บ'
              value='0001' />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={2}>
            <Input label='กำหนดส่งมอบภายใน'
              placeholder='กำหนดส่งมอบภายใน'
              value={data.delivered}
              textCenter
              rule={{ required: true }} />
          </Col>
          <Col sm={12}
            md={1}>
            {/* <Selector className="no-label" placeholder='วัน' disabled /> */}
            <Form.Group className='mb-3'>
              <InputGroup hasValidation
                className='no-label'>
                <Form.Select
                  className='dark'
                  value={data.deliveredPeriodType}
                  disabled={false}
                //onChange={(event) => onChangePosition(event.target as HTMLSelectElement, index)}
                >
                  {
                    PeriodType.map((item) => (
                      <option key={item.value}
                        value={item.value}>{item.label}</option>
                    ))
                  }
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={12}
            md={3}>
            <Form.Group className='mb-3'>
              <InputGroup hasValidation
                className='no-label'>
                <Form.Select
                  className='dark'
                  value={data.startWorkingType}
                  disabled={false}
                //onChange={(event) => onChangePosition(event.target as HTMLSelectElement, index)}
                >
                  {
                    deliveryType.map((item) => (
                      <option key={item.value}
                        value={item.value}>{item.label}</option>
                    ))
                  }
                </Form.Select>
              </InputGroup>
            </Form.Group>
            {/* <Selector className="no-label" placeholder='นับถัดจากวันที่ลงนามใมสัญญา' /> */}
          </Col>
          <Col sm={12}
            md={3}>
            <Input label='ครบกำหนดส่งมอบวันที่'
              placeholder='ครบกำหนดส่งมอบวันที่'
              value='10/11/2023'
              textCenter
              rule={{ required: true }}
              disabled />
            {/* <DatePicker label='วันที่ลงนาม'
              rule={{ required: true }}
              placeholder='วันที่ลงนาม'
              value={data.deliveredDate}
              onChange={val => setData({ ...data, deliveredDate: val })}
              disabled={isDisabled} /> */}
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={2}>
            <Input label='ระยะเวลารับประกัน'
              placeholder='ระยะเวลารับประกัน'
              value={data.warrantyPeriod}
              textCenter
              rule={{ required: true }} />
          </Col>
          <Col sm={12}
            md={1}>
            {/* <Selector className="no-label" placeholder='วัน' /> */}
            <Form.Group className='mb-3'>
              <InputGroup hasValidation
                className='no-label'>
                <Form.Select
                  className='dark'
                  value={data.warrantyPeriodType}
                  disabled={false}
                //onChange={(event) => onChangePosition(event.target as HTMLSelectElement, index)}
                >
                  {
                    PeriodType.map((item) => (
                      <option key={item.value}
                        value={item.value}>{item.label}</option>
                    ))
                  }
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={12}
            md={3}>
            {/* <Selector label='เงื่อนไขการรับประกัน' placeholder='ถัดจากวันสิ้นสุด' rule={{ required: true }} /> */}
            <Form.Group className='mb-3'>
              {/* <Form.Label>เงื่อนไขการรับประกัน</Form.Label> */}
              <InputGroup hasValidation
                className='no-label'>
                <Form.Select
                  className='dark'
                  value={data.startGuaranteeType}
                  disabled={false}
                //onChange={(event) => onChangePosition(event.target as HTMLSelectElement, index)}
                >
                  {
                    warrantyType.map((item) => (
                      <option key={item.value}
                        value={item.value}>{item.label}</option>
                    ))
                  }
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={12}
            md={3}>
            <Input label='ครบกำหนดรับประกันวันที่'
              placeholder='ครบกำหนดรับประกันวันที่'
              disabled
              value='10/12/2023'
              textCenter
              rule={{ required: true }} />
            {/* <DatePicker label='วันที่ลงนาม'
              rule={{ required: true }}
              placeholder='วันที่ลงนาม'
              value={data.warrantyPeriodDate}
              onChange={val => setData({ ...data, warrantyPeriodDate: val })}
              disabled={isDisabled} /> */}
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='บันทึกรับหลักประกัน'
          className='fs-5 text-primary' />
        <Row>
          <Col sm={12}>
            <Radio
              className='mt-3'
              inline
              dflex
              value={1}
              items={INFO}
              label='จำนวนเงินหลักประกัน'
              name='name1'
              rule={{ required: true }}
            />
          </Col>
          <Col sm={12}
            xl={3}>
            {/* <Selector label='ประเภทหลักประกันสัญญา' placeholder='เงินโอน' rule={{ required: true }} /> */}
            <Form.Group className='mb-3'>
              <Form.Label>ประเภทหลักประกันสัญญา</Form.Label>
              <InputGroup hasValidation>
                <Form.Select
                  className='dark'
                  value=''
                  disabled={false}
                //onChange={(event) => onChangePosition(event.target as HTMLSelectElement, index)}
                >
                  {
                    guaranteeType.map((item) => (
                      <option key={item.value}
                        value={item.value}>{item.label}</option>
                    ))
                  }
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={12}
            xl={3}>
            <Input label='จำนวนเงินหลักประกัน'
              placeholder='จำนวนเงินหลักประกัน'
              rule={{ required: true }} />
          </Col>
          <Col sm={12}
            xl={3}>
            <Input label='ร้อยละของราคาค่าจ้างตามสัญญา'
              placeholder='ร้อยละของราคาค่าจ้างตามสัญญา'
              rule={{ required: true }} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            xl={3}>
            <Input label='เลขที่หลักประกัน'
              placeholder='เลขที่หลักประกัน' />
          </Col>
          <Col sm={12}
            xl={3}>
            <DatePicker label='เลขที่หลักประกันลงวันที่' />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            xl={3}>
            {/* <Selector label='ประเภทวันทำสัญญา' placeholder='ตามวันที่' /> */}
            <Form.Group className='mb-3'>
              <Form.Label>ประเภทวันทำสัญญา</Form.Label>
              <InputGroup hasValidation>
                <Form.Select
                  className='dark'
                  value=''
                  disabled={false}
                //onChange={(event) => onChangePosition(event.target as HTMLSelectElement, index)}
                >
                  {
                    guaranteeDateType.map((item) => (
                      <option key={item.value}
                        value={item.value}>{item.label}</option>
                    ))
                  }
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={12}
            xl={3}>
            <DatePicker label='วันที่'
              rule={{ required: true }} />
          </Col>
          <Col sm={12}
            xl={3}>
            <DatePicker label='ถึงวันที่' />
          </Col>
          <Col sm={12}
            xl={8}>
            <InputTextArea label='หมายเหตุ' />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='งวด'
              className='fs-5 text-primary' />
            {openCollapse ? (
              <ArrowCollapse onClick={() => setOpenCollapse(!openCollapse)} openCollapse={openCollapse} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse(true)} openCollapse={openCollapse} />)}
          </div>
          {/* <Button
            variant="outline-primary"
            onClick={() => goToParcels('')}>
            <FaPlus />เพิ่มงวด
          </Button> */}
        </div>
        <Collapse in={openCollapse}>
          <div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}
                md={4}
                xl={3}>
                <Input label='จำนวนเงินรวม'
                  textEnd
                  rule={{ required: true }}
                  value='1,750,000.00'
                  disabled />
              </Col>
              <Col sm={12}
                md={3}>
                <Input label='จำนวน (%)'
                  placeholder='จำนวน (%)'
                  textEnd
                  value='100%'
                  disabled
                  rule={{ required: true }} />

                {/* <DatePicker label='ครบกำหนดส่งมอบวันที่' disabled /> */}
              </Col>
            </Row>

            <Row className='mt-3'>
              <Col sm={12}
                md={12}
                className='d-flex justify-content-end'>
                <Button className='text-end'
                  variant='outline-primary'
                  onClick={() => goToParcels('')}>
                  <FaPlus className='me-1' />เพิ่มงวด
                </Button>
              </Col>
            </Row>

            <Table total={1}
              className='mt-3'
              hidePagination>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: 10 }}>งวดที่</th>
                  <th style={{ width: 200 }}>จำนวน (%)</th>
                  <th style={{ width: 200 }}>จำนวนเงิน</th>
                  <th style={{ width: 200 }}>จำนวนวัน</th>
                  <th style={{ width: 200 }}>วันที่ต้องส่งมอบ</th>
                  <th style={{ width: 200 }}>จำนวน (%) <br />ที่ส่งมอบ</th>
                  <th style={{ width: 200 }}>จำนวนเงิน <br />ที่ส่งมอบ</th>
                  <th style={{ width: 200 }}>รายละเอียดส่งมอบ</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {installmentData.map((data, index) => (
                  <tr className='text-center'
                    key={index}>
                    <td>{index + 1}</td>
                    <td>{data.percent}</td>
                    <td className='text-end'>{data.amount}</td>
                    <td>{data.day}</td>
                    <td>{data.deliveryDate}</td>
                    <td>{data.percentDelivery}</td>
                    <td className='text-end'>{data.amountDelivery}</td>
                    <td className='text-start'>
                      {data.details1} <br />
                      {data.details2}
                    </td>
                    <td className='text-end'>
                      <div className='d-flex justify-content-center'>
                        <Button
                          onClick={() => goToParcels(data.id)}
                        >บันทึกผล
                        </Button>
                      </div>
                    </td>
                    <td className='text-end'>
                      <Button
                        variant='danger'
                        className='d-flex align-items-center gap-2'
                        onClick={() => removeInstallment(index)}
                        disabled={false}
                      >
                        <FaTrashAlt />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between gap-3 pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <div>
          <Button
            variant='success'
            className='me-2 px-3'
          >
            <FaSave className='me-2 pb-1 fs-5' />
            บันทึก
          </Button>
          <Button
            className='me-2 px-3'
          >
            ส่งบริหารสัญญา
          </Button>
        </div>
        <div />
      </div>
      <HistoryModal
        title='ประวัติการใช้งานประกาศผู้ชนะการเสนอราคา'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        data={dataHistory}
      />
    </div>
  );
}

export default Step3;
