import {
  ButtonCustom,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Modal,
  Table,
} from 'components';
import { InputNumber } from 'components/Controls/InputNumber';
import { ContractAgreementWorkPhaseModel } from 'models/CM/CM02Models';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { MdSave } from 'react-icons/md';
import { ValidatePattern, fullDate } from 'utils';
import toast from 'utils/toast';

interface Props {
  show: boolean;
  onHide?: () => void;
  contractAgreementId: string;
  contractAgreementWorkPhases: ContractAgreementWorkPhaseModel[];
  contractAmountVat: number;
  onSubmit: (saveDatas: ContractAgreementWorkPhaseModel[]) => void;
  isDisabled?: boolean;
}

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function EditDeliveryModal({ show, onHide, contractAgreementWorkPhases, onSubmit, contractAmountVat, contractAgreementId, isDisabled }: Props) {
  const [editContractAgreementWorkPhases, setEditContractAgreementWorkPhases] = useState<ContractAgreementWorkPhaseModel[]>([]);

  useEffect(() => {
    if (contractAgreementWorkPhases) {

      setEditContractAgreementWorkPhases(contractAgreementWorkPhases);

      contractAgreementWorkPhases?.forEach((item, i) => {
        handlerOnChangePercent(i, item.percent, contractAgreementWorkPhases);
      })
    }
  }, [contractAgreementWorkPhases]);

  const onSave = () => {

    if (!contractAmountVat) {
      toast.warn('กรุณาระบุวงเงินตามสัญญา');
      return;
    }

    if (editContractAgreementWorkPhases.reduce((a, v) => a += v.percent!, 0) < 100 || editContractAgreementWorkPhases.reduce((a, v) => a += v.percent!, 0) > 100) {
      toast.warn('กรุณาระบุจำนวนเงิน (%) รวมเท่ากับ 100%');
      return;
    }

    if (editContractAgreementWorkPhases.filter(x => x.amount === 0 || x.amount === null || x.amount === undefined || Number.isNaN(x.amount) || x.percent === 0 || x.percent === null || x.percent === undefined || Number.isNaN(x.percent)).length > 0) {
      toast.warn('กรุณากรอกข้อมูลมูลค่าและเปอร์เซนต์ให้ครบถ้วน');
      return;
    }

    if (editContractAgreementWorkPhases.filter(x => (!x.periodDay || x.periodDay === 0)).length > 0) {
      toast.warn('กรุณากรอกระยะเวลาส่งมอบ');
      return;
    }

    onSubmit(editContractAgreementWorkPhases);
    onHide!();
  };

  const handlerOnChangeDueDate = (index: number, value?: Date) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].deliveryDueDate = value;
    setEditContractAgreementWorkPhases(data);
  };

  const handlerOnChangePaidPhase = (index: number, value?: number) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].paidPhase = value;

    setEditContractAgreementWorkPhases(data);
  };

  const handlerOnChangeWorkPhase = (index: number, value?: number) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].workPhase = value;

    setEditContractAgreementWorkPhases(data);
  };

  const handlerOnChangePeriodDay = (index: number, value: number) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].periodDay = value;

    setEditContractAgreementWorkPhases(data);
  };

  const handlerOnChangeAdvancePaymentAmount = (index: number, value?: number) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].advancePaymentAmount = value;

    setEditContractAgreementWorkPhases(data);
  };

  const handlerOnChangeRetentionAmount = (index: number, value?: number) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].retentionAmount = value;

    setEditContractAgreementWorkPhases(data);
  };

  const handlerOnChangeDetail = (index: number, value?: string) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].detail = value ?? '';

    setEditContractAgreementWorkPhases(data);
  };

  const handlerOnChangeAmount = (index: number, value: number) => {
    const data = [...editContractAgreementWorkPhases];
    data[index].amount = value;
    data[index].percent = contractAmountVat ? (value / contractAmountVat) * 100 : data[index].percent;
    setEditContractAgreementWorkPhases(data);
  };

  const handlerOnChangePercent = (index: number, value: number, contractAgreementWorkPhases?: ContractAgreementWorkPhaseModel[]) => {
    let data = [...editContractAgreementWorkPhases];

    if (data.length === 0 && contractAgreementWorkPhases) {
      data = contractAgreementWorkPhases;
    }

    data[index].percent = value;
    data[index].amount = contractAmountVat ? (value / 100) * contractAmountVat : data[index].amount;
    setEditContractAgreementWorkPhases(data);
  };

  const addWorkphase = () => {

    const maxValueSequence = editContractAgreementWorkPhases.length > 0 ? Math.max.apply(null,
      editContractAgreementWorkPhases.map((x) => {
        return x.sequence;
      })) : 0;

    const newData: ContractAgreementWorkPhaseModel = {
      id: undefined,
      contractAgreementId: contractAgreementId,
      sequence: maxValueSequence + 1,
      paidPhase: maxValueSequence + 1,
      workPhase: maxValueSequence + 1,
      periodDay: 0,
      amount: 0,
      percent: 0,
      detail: '',
      deliveryDueDate: undefined,
      advancePaymentAmount: undefined,
      retentionAmount: undefined,
    };

    setEditContractAgreementWorkPhases((editContractAgreementWorkPhases) => [...editContractAgreementWorkPhases, newData]);
  };

  const removeWorkphase = (i: number) => {
    const temp: ContractAgreementWorkPhaseModel[] = [...editContractAgreementWorkPhases];
    temp.splice(i, 1);
    setEditContractAgreementWorkPhases(temp);
  };

  return (
    <Modal
      show={show}
      size='xl'
      onHide={() => (onHide ? onHide() : null)}
      className="fix-width-1800"
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title className='text-primary'>รายละเอียดส่งมอบ</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4 pt-2 pb-2' style={{ minHeight: 500 }}>
            <Row className='mt-3'>
              <Col sm={12}
                md={6}>
                <Currency label='วงเงินตามสัญญา'
                  placeholder='วงเงินตามสัญญา'
                  value={contractAmountVat}
                  rule={{ required: true }}
                  disabled />
              </Col>
              <Col sm={6}
                md={6}>
                <div className='d-flex justify-content-end'>
                  <ButtonCustom
                    className='no-label'
                    onClick={addWorkphase}
                    disabled={isDisabled}
                    text='เพิ่มรายการ' />
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                md={12}
                style={{ fontSize: '13px' }}>
                <div className='bg-light rounded bg-opacity-25 p-3 pb-1'>
                  <p>*หมายเหตุ: วันที่ส่งมอบ จะถูกคำนวนจากวันที่เริ่มต้นสัญญา บวกกับระยะเวลาส่งมอบ (วัน) </p>
                </div>
              </Col>
            </Row>
            <Col sm={12}>
              <Table hidePagination>
                <thead>
                  <tr className='text-center'>
                    {/* <th style={{ minWidth: 50 }}>งวดเงิน</th> */}
                    <th style={{ minWidth: 50 }}>งวดที่</th>
                    <th style={{ minWidth: 50 }}>ระยะเวลาส่งมอบ (วัน)</th>
                    <th style={{ minWidth: 200 }}>วันที่ส่งมอบ</th>
                    <th style={{ minWidth: 200 }}>มูลค่า</th>
                    <th style={{ minWidth: 200 }}>(%)</th>
                    <th style={{ minWidth: 200 }}>หักเงินล่วงหน้า (บาท)</th>
                    <th style={{ minWidth: 200 }}>หักเงินประกันผลงาน (บาท)</th>
                    <th style={{ minWidth: 400 }}>รายละเอียดการส่งมอบ</th>
                    <th style={{ minWidth: 0 }}></th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {
                    editContractAgreementWorkPhases?.map((data, index) => (
                      <tr>
                        {/* <td>
                          <InputNumber
                            textCenter
                            value={data?.paidPhase}
                            onChange={(val) => handlerOnChangePaidPhase(index, Number(val))}
                            disabled={isDisabled} />
                        </td> */}
                        <td>
                          <InputNumber
                            textCenter
                            value={data?.workPhase}
                            onChange={(val) => handlerOnChangeWorkPhase(index, Number(val))}
                            disabled={isDisabled} />
                        </td>
                        <td>
                          <InputNumber
                            textCenter
                            value={data?.periodDay}
                            onChange={(val) => handlerOnChangePeriodDay(index, Number(val))}
                            disabled={isDisabled} />
                        </td>
                        <td>
                          <DatePicker
                            value={data.deliveryDueDate}
                            onChangeHasNullable={(value) => handlerOnChangeDueDate(index, value)}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Currency value={data.amount}
                            onChange={(val) => handlerOnChangeAmount(index, Number(val))}
                            disabled={isDisabled} />
                        </td>
                        <td>
                          <Currency value={data.percent}
                            onChange={(val) => handlerOnChangePercent(index, Number(val))}
                            rule={{ pattern: ValidatePattern.PERCENTAGE }}
                            disabled={isDisabled} />
                        </td>
                        <td>
                          <Currency value={data.advancePaymentAmount}
                            onChange={(val) => handlerOnChangeAdvancePaymentAmount(index, Number(val))}
                            disabled={isDisabled} />
                        </td>
                        <td>
                          <Currency value={data.retentionAmount}
                            onChange={(val) => handlerOnChangeRetentionAmount(index, Number(val))}
                            disabled={isDisabled} />
                        </td>
                        <td>
                          <InputTextArea
                            value={data.detail}
                            onChange={(val) => handlerOnChangeDetail(index, val)}
                            disabled={isDisabled}
                          />
                        </td>
                        <td>
                          <Button
                            onClick={() => removeWorkphase(index)}
                            variant='danger'
                            disabled={isDisabled}>
                            <FaTrashAlt />
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Col>

          </ModalBT.Body>
          <ModalBT.Footer className='justify-content-center'>
            <Button
              variant='outline-primary'
              onClick={() => (onHide ? onHide() : null)}
              className='me-2 px-3'
            >
              ยกเลิก
            </Button>
            <Button
              variant='primary'
              className='me-2 px-3'
              onClick={onSave}
              disabled={isDisabled}
            >
              <MdSave className='mx-2 pb-1 fs-4' />
              บันทึก
            </Button>
          </ModalBT.Footer>
        </>
      )}
    />
  );
}
