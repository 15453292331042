import { useState } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { Modal } from './Modal';

export function RemoveModal() {
  const [show, setShow] = useState<boolean>(false);

  document.addEventListener('onShowRemoveModal', () => setShow(true));

  const action = (confirm: boolean) => {
    setShow(false);

    document.dispatchEvent(new CustomEvent('onShowRemoveModalClosed', { detail: { result: confirm } }));
  };

  return (
    <Modal
      show={show}
    >
      <Row>
        <Col>
          <p>ต้องการลบข้อมูลออกจากระบบหรือไม่?</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex flex-row gap-2 justify-content-end">
            <Button variant="light" type="button" onClick={() => action(false)}>ยกเลิก</Button>
            <Button variant="danger" type="button" onClick={() => action(true)}>ลบข้อมูล</Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}
