export enum ValidatePattern {
  EMAIL = 'email',
  PHONENUMBER = 'PhoneNumber',
  TAXNUMBER = 'TaxNumber',
  NUMBER = 'number',
  PERCENTAGE = 'percentage'
}

export enum MaxNumber {
  INTEGER = 100000000000,
  PERCENT = 100
}
