import Form from 'react-bootstrap/Form';

interface Props {
  label?: string;
  textReadonly: JSX.Element | string;
  className?: string;
}

export function Readonly({
  label = '',
  textReadonly,
  className = '',
}: Props) {
  return (
    <div className={`info-detail ${className}`}>
      <Form.Label className='fs-7'>{label}</Form.Label>
      <Form.Label>{textReadonly}</Form.Label>
    </div>
  );
}
