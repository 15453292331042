import {
  Modal,
  Status,
  StatusType,
  Table,
} from 'components';

import Title from 'components/Controls/Title';
import { AppointmentOrderHistoryListResponseModel } from 'models/procurementAppointmentOrderModel';
import {
  useEffect,
  useState,
} from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import procurementAppointmentOrderServices from 'services/procurementAppointmentOrderServices';
import {
  fullDatetime,
  HttpStatusCode,
} from 'utils';

interface Props {
  show: boolean;
  onHide?: () => void;
  title?: string;
  id?: string;
  appointNumber?: string;
}

export function HistoryModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [history, setHistory] = useState<AppointmentOrderHistoryListResponseModel>({} as AppointmentOrderHistoryListResponseModel);

  useEffect(() => {
    if (props.id && props.show) {
      getAppointHistoryAsynce(page, size);
    }
  }, [props.id, props.show]);

  const getAppointHistoryAsynce = async (page: number, size: number) => {
    const response = await procurementAppointmentOrderServices.getListHistoryAsync(page, size, props.id);

    if (response.status === HttpStatusCode.OK) {
      setHistory(response.data);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size='xl'
    >
      <Modal.Header>
        <Title
          text='ประวัติการใช้งานจัดทำคำสั่งแต่งตั้งคณะกรรมการจัดซื้อจัดจ้างและคณะกรรมการตรวจรับ'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <ModalBT.Body className='p-0 ps-4'>
        <Table className='my-3'
          total={history.totalCount}
          onChange={(size, page) => {
            getAppointHistoryAsynce(page, size);
          }}>
          <thead>
            <tr>
              <th>วันที่/เวลา</th>
              <th>ผู้ดำเนินการ</th>
              <th>การดำเนินการ</th>
              <th>สถานะการดำเนินการ</th>
              <th>หมายเหตุ</th>

            </tr>
          </thead>
          <tbody>
            {
              (history && history.data) && (
                history.data.map((data, index) => (
                  <tr key={index}>
                    <td className='text-center'>{fullDatetime(data.createdInfo_At)}</td>
                    <td>{data.createdInfo_ByUserFullName}</td>
                    <td>
                      {data.positionName}
                      {' '}
                      {data.description}
                    </td>
                    <td className='text-start'><Status value={data.status} type={StatusType.APPOINTMENTORDER} /></td>
                    <td className='text-start text-wrap' style={{ minWidth: 400, width: 400 }}>{data.remark}</td>
                  </tr>
                ))
              )
            }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
