import {
  ConfirmModal,
  Loading,
  RemoveModal,
  Sidebar,
} from 'components';
import Cookie from 'cookie-universal';
import {
  useEffect,
  useState,
} from 'react';
import {
  Outlet,
  useNavigate,
} from 'react-router-dom';
import { account } from 'services';
import {
  AppContext,
  AppContextType,
  HttpStatusCode,
} from 'utils';
import Footer from '../Footer';
import Navbar from '../Navbar';

export default function Layout() {
  const [sidebarIsCollapsed, setSidebarIsCollapsed] = useState<boolean>(false);
  const navigate = useNavigate();
  const cookies = Cookie();
  const accessToken = cookies.get('access-token');
  const [userProfile, setUserProfile] = useState<AppContextType>({} as AppContextType);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [isMobileCollapsed, setIsMobileCollapsed] = useState(false);

  document.addEventListener('onSidebarIsCollapsed', (event) => {
    const customEvent = event as CustomEvent;

    setSidebarIsCollapsed(customEvent.detail);
  });

  useEffect(() => {
    if (!accessToken) {
      navigate('/login');
    } else {
      getUserProfileAsync();
    }
  }, []);

  const checkAuthenticated = () => {
    if (accessToken) {
      return true;
    }

    return navigate('/login');
  };

  const getUserProfileAsync = async () => {
    const res = await account.getUserAsync();

    if (res.status === HttpStatusCode.OK) {
      setUserProfile({
        ...res.data,
        userId: res.data.id,
      });
    }
  };

  const valueContext = {
    ...userProfile,
  };

  return (
    <AppContext.Provider value={valueContext}>
      {checkAuthenticated()
        ? (
          <>
            <div className={`layout ${hideSidebar ? '' : 'isCollapsed'} ${isMobileCollapsed ? '' : 'isCollapsedMobile'}`}>
              {/* <Sidebar onClick={() => setHideSidebar(false)}
                setIsMobileCollapsed={() => setIsMobileCollapsed(false)} /> */}
              <Sidebar />
              <div className='content-layout'>
                <Navbar onClick={() => setHideSidebar(!hideSidebar)} />
                <div className='layout-body'>
                  <Outlet />
                </div>
                <Footer />
              </div>
            </div>
            <RemoveModal />
            <ConfirmModal />
            <Loading />
          </>
        )
        : null}
    </AppContext.Provider>
  );
}
