import { useMemo } from 'react';
import { MdCircle } from 'react-icons/md';

interface Props {
  systemStatue: string;
  outline?: boolean;
  text?: string;
  systemName: string;
}


function Gettext(system: string, systemStatue: string) {
  switch (system) {
    case 'cm-03':
      if (systemStatue === 'Pending') {
        return 'รอดำเนินการเบิกจ่าย'
      }

      if (systemStatue === 'Paid') {
        return 'เบิกจ่ายแล้ว';
      }

      break;
    default:
      return 'รอเห็นชอบ'
  }
}


export function StatusCMPaidPhase({ systemStatue, outline, text, systemName }: Props) {
  const status = useMemo(() => {
    if (systemStatue === 'Pending') {
      return {
        color: 'bg-yellow text-yellow '
      };
    }

    if (systemStatue === 'Paid') {
      return {
        color: 'bg-success text-success '
      };
    }

    return { color: 'bg-dark text-muted' };
  }, [systemStatue]);

  return (
    <>
      {status ?
        <div className={`d-flex align-items-center px-3 py-1 bg-opacity-10 rounded-pill ${status.color}`}> <MdCircle className="me-2 circle" /> {systemName ? Gettext(systemName, systemStatue) : 'text noting'}</div> : null}
    </>
  );
}
