import {
  InputTextArea,
  TitleModal,
} from 'components';
import { Button } from 'react-bootstrap';
import { Modal } from './Modal';

interface Props {
  show: boolean;
  onHide?: () => void;
  title?: string;
  status?: 'reject' | 'general' | 'comment' | string;
  setRemark?: () => void;
  remark?: string | undefined;
  textInBtn?: string;
  required?: boolean;
  onClick?: () => void;
}

function ButtonModal(status: string) {
  if (status === 'reject') {
    return 'danger';
  }

  return 'success';
}

export function ModalReply(props: Props) {
  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size='lg'
      className={`${props.status} px-3`}
    >
      <Modal.Header>
        <TitleModal
          text={props.title}
          status={props.status ? props.status : 'comment'}
        />
      </Modal.Header>
      <InputTextArea
        label='เหตุผล'
        rule={{ required: props.required }}
        onChange={props.setRemark}
        value={props.remark}
        name='remark'
        rows={7}
      />

      <div className='d-flex gap-2 justify-content-between align-items-center'>
        <Button variant='light'
          className='d-black w-100'
          onClick={props.onHide}>ยกเลิก
        </Button>
        <Button variant={ButtonModal(props.status ? props.status : 'comment')}
          className='d-black w-100'
          onClick={props.onClick}>{props.textInBtn}
        </Button>
      </div>
    </Modal>
  );
}
