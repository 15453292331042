import {
  Approver,
  ApproverStatus,
  ButtonCustom,
  Card,
  ContractReceiveStatus,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { Modal, SearchBuNameModal } from 'components/Modal';
import { StatusCMReceived } from 'components/StatusCMReceived';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import {
  AcceptorModel,
  AttachmentFileModel,
  updateAcceptorModel,
} from 'models/CM/CM03Models';
import { sectionApproverModel } from 'models/delegateModel';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaRedo, FaTrashAlt } from 'react-icons/fa';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import { account, shareValue } from 'services';
import CM03Service from 'services/CM/CM03Service';
import {
  fullDatetime,
  fullDatetimeTH,
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import Collabora, { CollaboraRef } from '../../../../components/Document/Collabora';
import { ApproveModal } from '../Modal/ApproveModal';
import { EditModal } from '../Modal/EditModal';
import { HistoryModal } from '../Modal/HistoryReceiveModal';
import UploadFile from '../UploadFileReceive';
import { Context } from './step';
import { JorPorCode } from 'constant/basicInformation';
import { AcceptorStatus } from 'pages/PR/PR0101/Detail';
import { isNull } from 'utils/helper';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  contractId: string;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

function Step4({ onClickNext, onClickBack, contractId }: Props) {
  const navigate = useNavigate();
  const { data, setData } = useContext(Context);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [receiveAcceptorsData, setReceiveAcceptorsData] = useState<AcceptorModel[]>([]);
  const [isApprover, setIsApprover] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [showUserModals, setShowUserModals] = useState<boolean[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const { userId, departmentCode } = useAppContext();
  const location = useLocation();
  const [showWaitForApproveModal, setShowWaitForApproveModal] = useState(false);
  const collaboraRef = useRef<CollaboraRef>(null);
  const [docId, setDocId] = useState('');
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [showDefaultApproverModal, setShowDefaultApproverModal] = useState<boolean>(false);
  const [showReCall, setShowReCall] = useState(false);

  const handlerSetDefaultApproverAsync = async () => {
    onGetDefaultApprover(true);
    setShowDefaultApproverModal(false);
  }

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  useEffect(() => {
    if (data) {
      if (data.status === ContractReceiveStatus.JorPorApproved || data.status === ContractReceiveStatus.Rejected) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }

      if (data.contractManagementReceiveAcceptors && data.contractManagementReceiveAcceptors.length > 0) {
        setReceiveAcceptorsData(data.contractManagementReceiveAcceptors);

        const queueApprover = onCheckQueueApprover(data.contractManagementReceiveAcceptors);
        setIsQueueApprover(queueApprover);
        const isApprover = onCheckApprover(data.contractManagementReceiveAcceptors);
        setIsApprover(isApprover);
        onCheckApproverStatus();

        const sectionDataGroup: sectionGroup[] = [];
        data.contractManagementReceiveAcceptors?.sort((a, b) => a.sectionSequence! - b.sectionSequence!).forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.level,
            sectionSequence: item.sectionSequence,
            inRefCode: item.sectionInRefCode,
            sectionNameTh: item.sectionName,
          } as sectionGroup);
        });

        const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex((d) => d.inRefCode === data.inRefCode) === index);

        setSections(uniqueNames);
      } else {
        onGetDefaultApprover(false);
      }

      if (data.attachments) {
        handleSetFile(data.attachments);
      }
    }
  }, [data, userId]);

  const isRecall = useMemo(() => {
    return data.contractManagementReceiveAcceptors?.filter(a => a.status === AcceptorStatus.APPROVE).length === 0;
  }, [data, userId])

  const getDocument = useCallback(async (id: string) => {
    const { data, status } = await CM03Service.getDocumentInspectionAsync(id);
    if (status === HttpStatusCode.OK) {
      setDocId(data);
    }
  }, [setDocId]);

  useEffect(() => {
    if (data.id) {
      getDocument(data.id);
    }
  }, [data.id, getDocument]);

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = Math.max.apply(null,
      receiveAcceptorsData?.map((x) => x.sectionSequence!));

    const valueSequence = receiveAcceptorsData?.filter((a) => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  const onCheckApprover = (datas: AcceptorModel[]) => {
    const unApproved = datas?.filter((d) => d.status === ContractReceiveStatus.Pending
      && !d.isAvailable);

    if (unApproved?.length === 1) {
      return true;
    }

    return false;
  };

  const onCheckQueueApprover = (datas: AcceptorModel[]) => {
    if (datas && data.status === ContractReceiveStatus.WaitForApprove) {
      const minSection = Math.min(...datas?.filter((d) => d.status === ContractReceiveStatus.Pending && !d.isAvailable).map((o) => o.sectionSequence!));

      const sectionDatas = datas?.filter((a) => a.status === ContractReceiveStatus.Pending && a.sectionSequence === minSection && !a.isAvailable).sort((a, b) => a.sequence - b.sequence);

      if (sectionDatas && sectionDatas.length > 0) {
        return isNull(sectionDatas[0].delegateUserId, sectionDatas[0].userId) === userId;
      }
      return false;
    }
    return false;
  };

  const onGetDefaultApprover = async (isSetNew: boolean) => {

    const response = await account
      .getDefaultApproverByTopLeve(PROCESSTYPE.ApproveReceiveProcess, data.offerTotalAmount, undefined, data.ignorePurchaseOnTable159, data.departmentId);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: AcceptorModel[] = [];
      if (isSetNew || (data.contractManagementReceiveAcceptors === undefined
        || data.contractManagementReceiveAcceptors === null
        || data.contractManagementReceiveAcceptors.length === 0)) {
        newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            inRefCode: item.inRefCode,
            sectionNameTh: item.sectionName,
            sectionId: item.sectionId,
          } as sectionGroup);

          approver.push({
            sectionSequence: item.sectionSequence,
            sectionName: item.sectionName,
            sequence: item.sequence,
            userId: item.userId,
            fullName: item.fullName,
            positionName: item.positionName,
            departmentName: item.departmentName,
            sectionInRefCode: item.inRefCode,
            isAvailable: false,
            level: item.sectionLevel,
            delegateUserId: item.delegateUserId,
            delegateEmployeeCode: item.delegateEmployeeCode,
            delegateFullName: item.delegateFullName,
            delegateDepartmentName: item.delegateDepartmentName,
            delegatePositionName: item.delegatePositionName,
            status: AcceptorStatus.DRAFT,
          } as AcceptorModel);
        });

        const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex((d) => d.inRefCode === data.inRefCode) === index);

        let inx = 0;
        let sectionSeq = 0;

        approver?.sort((a, b) => a.sectionSequence! - b.sectionSequence!).forEach((item, index) => {
          if (sectionSeq !== item.sectionSequence) {
            inx = 1;
            sectionSeq = item.sectionSequence!;
          }

          approver[index].sequence = inx;

          inx++;
        });

        setReceiveAcceptorsData(approver);
        setSections(uniqueNames);

      } else {
        data.contractManagementReceiveAcceptors.forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.level,
            sectionSequence: item.sectionSequence,
            inRefCode: item.sectionInRefCode,
            sectionNameTh: item.sectionName,
            sectionId: '',
          } as sectionGroup);
        });

        const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);
        setSections(uniqueNames);
      }
    }
  };

  const onSelectEmpApprover = (empId: string, name: string, position: string, department: string, index: number, sectionSequence?: number, sectionName?: string, inRefCode?: string, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
    delegatePositionName?: string) => {
    if (receiveAcceptorsData?.filter((x) => x.sectionInRefCode === inRefCode
      && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
      return;
    }

    const newData: AcceptorModel[] = receiveAcceptorsData || [];

    const acceptorBySection = receiveAcceptorsData.filter((x) => x.sectionSequence === sectionSequence);

    const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
      acceptorBySection.map((x) => {
        return x.sequence;
      })) : 0;

    newData.push({
      sectionSequence: sectionSequence!,
      sectionName: sectionName!,
      sequence: maxValueSequence + 1,
      userId: empId,
      fullName: name,
      positionName: position,
      departmentName: department,
      sectionInRefCode: inRefCode!,
      isAvailable: false,
      availableRemark: '',
      status: AcceptorStatus.DRAFT,
      seqKey: `${sectionSequence?.toString()}${(receiveAcceptorsData?.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
      delegateFullName: delegateFullName,
      delegateDepartmentName: delegateDepartmentName,
      delegateEmployeeCode: delegateEmployeeCode,
      delegatePositionName: delegatePositionName,
      delegateUserId: delegateUserId,
    } as AcceptorModel);

    setReceiveAcceptorsData(newData);
  };

  const onOpenModal = (index: number) => {
    setShowUserModals((showUserModals) => {
      const newModal = [...showUserModals];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideModal = (index: number) => {
    setShowUserModals((showUserModals) => {
      const newModal = [...showUserModals];
      newModal[index] = false;
      return newModal;
    });
  };

  const removeApprover = (seqKey: string) => {
    const temp: AcceptorModel[] = [...receiveAcceptorsData];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    setReceiveAcceptorsData(temp);
  };

  const onApproved = async (remark: string) => {
    const response = await CM03Service.acceptorsApprovedAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.CREATED) {
      toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
      getContractManagementAsync(contractId, data.id);
      getDocument(data.id);
    }

    setShowApproveModal(false);
  };

  const onReject = async (remark: string) => {
    submitForm();

    if (!remark) {
      toast.warn('กรุณากรอกหมายเหตุ');
      return;
    }

    const response = await CM03Service.acceptorsRejectAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.CREATED) {
      toast.success('ส่งกลับแก้ไขสำเร็จ');
      getContractManagementAsync(contractId, data.id);
      setShowRejectModal(false);
      getDocument(data.id);
    }
  };

  const getContractManagementAsync = useCallback(async (contractId: string, receiveId: string) => {
    const { data, status } = await CM03Service.getContractManagementReceiveByIdAsync(contractId, receiveId);

    if (status === HttpStatusCode.OK) {
      setData(data);
      return;
    }
  }, []);

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await CM03Service.uploadAttachmentReceiveAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getAttachmentAsync(contractId, data.id);
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {
    if (data && data.id) {
      const res = await CM03Service.removeAttachmentReceiveAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getAttachmentAsync(contractId, data.id);
      }
    }
  }, [files, data.id]);

  const getAttachmentAsync = useCallback(async (contractId: string, receiveId: string) => {
    const { data, status } = await CM03Service.getContractManagementReceiveByIdAsync(contractId, receiveId);

    if (status === HttpStatusCode.OK) {

      const files: IFile[] = [];
      data.attachments?.forEach((item) => {
        files.push({
          id: item.id,
          name: item.fileName,
          file: null,
        });
      });

      setFiles(files);

      return;
    }
  }, []);

  const onSubmitAsync = async (isWaitingForApproved: boolean) => {
    submitForm();
    collaboraRef.current?.clickSave();
    setTimeout(async () => {

      if (receiveAcceptorsData && receiveAcceptorsData.length > 0) {
        const updateDataModel: updateAcceptorModel = {
          contractManagementReceiveAcceptors: receiveAcceptorsData,
        };

        const res = await CM03Service.updateAcceptorAsync(data.id, updateDataModel, docId);

        if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
          if (isWaitingForApproved) {
            const response = await CM03Service.waitForApproveReceivedAsync(data.id);
            toast.success('ส่งผู้มีอำนาจเห็นชอบ/อนุมัติ');
            setShowWaitForApproveModal(false);
          } else {
            toast.success('บันทึกข้อมูลสำเร็จ');
          }

          getDocument(data.id);
          getContractManagementAsync(contractId, data.id);
        } else {
          toast.error(res.statusText);
        }
      }
    }, 500);
  };

  const reCallAsync = async () => {
    if (data.id) {
      const response = await CM03Service.onReCallAsync(data.id);

      if (response.status === HttpStatusCode.OK) {
        toast.success('เรียกคืนแก้ไขสำเร็จ');
        getContractManagementAsync(contractId, data.id);
      } else {
        toast.error("เรียกคืนแก้ไขไม่สำเร็จ");
      }
    }

    setShowReCall(false);
  };

  return (
    <div className='document'>
      <Title text='ผู้มีอำนาจเห็นชอบ/อนุมัติ'
        extraElement={(
          <>
            <StatusCMReceived systemStatus={data.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </>
        )} />

      <Row className='mt-4 align-items-center'>
        <div>
          {(data.status === ContractReceiveStatus.Draft || data.status === ContractReceiveStatus.Rejected
            || data.status === ContractReceiveStatus.JorPorApproved
          )
            ? (
              <Button
                className='d-flex align-items-center gap-2'
                variant='outline-primary'
                onClick={() => {
                  setShowDefaultApproverModal(true);
                }}
              >
                <FaRedo />กำหนดผู้มีอำนาจเห็นชอบเริ่มต้น
              </Button>
            ) : null}
        </div>
        {
          sections.length > 0 ? (
            sections
              .sort((a, b) => a.sectionSequence - b.sectionSequence)
              .map((section, i) => (
                <Col sm={12} key={i}>
                  {
                    (!isDisabled) &&
                    (
                      <div className='d-flex justify-content-between'>
                        <h5 className='fw-bold'>
                          {/* {section.sectionName} */}
                        </h5>
                        <ButtonCustom
                          text='เพิ่มรายชื่อ'
                          icon='add'
                          variant='outline-primary'
                          onClick={() => onOpenModal(i)} />
                        <SearchBuNameModal
                          total={userTotal}
                          show={showUserModals[i]}
                          onHide={() => onHideModal(i)}
                          onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode,
                            delegateDepartmentName, delegatePositionName) => onSelectEmpApprover(id, name, position!, department!, receiveAcceptorsData.length + 1, section.sectionSequence, section.sectionName, section.inRefCode, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName)}
                          data={users}
                          onSearch={onSearchUserAsync}
                          onPageChange={onPageChangeAsync}
                          departmentCode={departmentCode}
                          departmentDisabled={departmentCode !== JorPorCode.CODE}
                        />
                      </div>
                    )
                  }
                  <Table notResponse hidePagination className='mt-3 step-bar'>
                    <thead>
                      <tr>
                        <th style={{ width: 20 }}>ลำดับ</th>
                        <th className='text-start' style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
                        <th className='text-start' style={{ width: '30%' }}>ผู้ปฏิบัติงานแทน</th>
                        <th style={{ width: 100 }}>สถานะ</th>
                        <th style={{ width: 100 }}>วันที่เห็นชอบ</th>
                        <th style={{ width: 100 }} />
                      </tr>
                    </thead>
                    <tbody>
                      {receiveAcceptorsData?.filter((x) => x.sectionInRefCode === section.inRefCode)
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((data, index) => (
                          <>
                            <tr>
                              <td className='text-center'>{index + 1}</td>
                              <td className='text-left'>
                                {data.fullName}
                                <p className='m-0 department'>
                                  {data.positionName}
                                  {/* {data.departmentName &&
                                    <>
                                      <span className='mx-1'>|</span> {data.departmentName}
                                    </>
                                  } */}
                                </p>
                              </td>
                              <td className='text-left'>
                                {data.delegateFullName
                                  && (
                                    <>
                                      {data.delegateFullName}
                                      <p className='m-0 department'>
                                        {data.delegatePositionName}
                                        {/* {data.delegateDepartmentName &&
                                        <>
                                          <span className='mx-1'>|</span> {data.delegateDepartmentName}
                                        </>
                                      } */}
                                      </p>
                                    </>
                                  )}
                              </td>
                              <td className='text-center'>
                                {isSectionSequence === data.sectionSequence && isApproverStatus === index + 1 ? <Approver value={data.status} outline /> : <ApproverStatus value={data.status} outline />}
                              </td>
                              <td className='text-center'>
                                {fullDatetimeTH(data.acceptDate!)}
                              </td>
                              <td className='text-end'>
                                <Button
                                  onClick={() => removeApprover(`${data.sectionSequence}${data.sequence}`)}
                                  variant='danger'
                                  disabled={isDisabled}
                                >
                                  <FaTrashAlt />
                                </Button>
                              </td>
                            </tr>

                            <tr>
                              <td />
                              <td colSpan={6} className='p-0'>
                                <ul className='step-bar-list'>
                                  {data.isAvailable
                                    && (
                                      <li>
                                        <div className='description'>
                                          <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                          <p className='mb-0' style={{ color: 'gray' }}>
                                            {data.availableRemark}
                                          </p>
                                        </div>
                                      </li>
                                    )}
                                  {data.acceptRemark
                                    && (
                                      <li>
                                        <div className='description'>
                                          <p className='mb-0 text-primary'>หมายเหตุ{isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ? 'อนุมัติ' : 'เห็นชอบ'}
                                          </p>
                                          <p className='mb-0 text-wrap' style={{ color: 'gray' }}>
                                            <span className='text-muted me-2'>[{data.acceptDate ? fullDatetime(data.acceptDate) : ''}]</span>
                                            {data.acceptRemark}
                                          </p>
                                        </div>
                                      </li>
                                    )}
                                  {data.rejectRemark
                                    && (
                                      <li>
                                        <div className='description'>
                                          <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                          <p className='mb-0 text-wrap' style={{ color: 'gray' }}>
                                            <span className='text-muted me-2'>[{data.rejectDate ? fullDatetime(data.rejectDate) : ''}]</span>
                                            {data.rejectRemark}
                                          </p>
                                        </div>
                                      </li>
                                    )}
                                </ul>
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </Table>
                </Col>
              ))
          ) : (
            <Col>
              <span className='text-primary'>ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
            </Col>
          )
        }
      </Row>

      <Card className='mt-3'>
        <Title
          text='เอกสารผลการตรวจรับ'
          className='fs-5 text-primary'
        />
        <div className='mt-3'>
          <Collabora
            docId={docId}
            docName='doc-02'
            ref={collaboraRef}
            readonly={false} />
        </div>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5' />
        <Row className='justify-content-center'>
          <Col sm={12} md={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.id!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <ButtonCustom text='ย้อนกลับ' onClick={onClickBack} icon='arrowback' variant='outline-primary' />
        </div>
        <div className='d-flex gap-3 align-items-center'>

          {
            (data.status === ContractReceiveStatus.JorPorApproved || data.status === ContractReceiveStatus.Rejected) && (
              <>
                <ButtonCustom text='บันทึก' variant='outline-primary' onClick={() => onSubmitAsync(false)} />
                <ButtonCustom text='ส่งผู้มีอำนาจเห็นชอบ/อนุมัติ' onClick={() => setShowWaitForApproveModal(true)} />
              </>
            )
          }
          {
            (data.status === ContractReceiveStatus.WaitForApprove && isRecall) && (
              <ButtonCustom text='เรียกคืนแก้ไข' icon="undo" variant='danger' onClick={() => setShowReCall(true)} />
            )
          }
          {
            (isQueueApprover && data.status === ContractReceiveStatus.WaitForApprove) && (
              <>
                <ButtonCustom text='ส่งกลับแก้ไข' icon="undo" variant='danger' onClick={() => setShowRejectModal(true)} />
                <ButtonCustom icon="checked" text={isApprover ? 'อนุมัติ' : 'เห็นชอบ'} variant='success' onClick={() => setShowApproveModal(true)} />
              </>
            )
          }

        </div>
        <div style={{ width: 145 }} />
      </div>

      <EditModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(!showRejectModal)}
        onRemark={onReject}
      />

      <ApproveModal
        show={showApproveModal}
        onRemark={onApproved}
        click={() => setShowApproveModal(!showApproveModal)}
        isApprover={isApprover}
      />

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={data.id}
        activities={data.activities}
        title='การตรวจรับ'
        status={ContractReceiveStatus.Received}
      />

      <Modal
        show={showWaitForApproveModal}
        size='lg'
        onHide={() => setShowWaitForApproveModal(!showWaitForApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งผู้มีอำนาจเห็นชอบ/อนุมัติ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitForApproveModal(!showWaitForApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showDefaultApproverModal}
        size='lg'
        onHide={() => setShowDefaultApproverModal(!showDefaultApproverModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการกำหนดผู้มีอำนาจเห็นชอบเริ่มต้นใหม่หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDefaultApproverModal(!showDefaultApproverModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerSetDefaultApproverAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showReCall}
        size='lg'
        onHide={() => setShowReCall(!showReCall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowReCall(!showReCall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={reCallAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step4;
