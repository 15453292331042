import { Menu } from 'models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Image,
  Offcanvas,
  Placeholder,
} from 'react-bootstrap';
import {
  FaAngleDown,
  FaAngleUp,
} from 'react-icons/fa';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { account } from 'services';
import { HttpStatusCode } from 'utils';
import { generateUniqueId } from '../utils/helper';

interface Props {
  onClick?: () => void;
  setIsMobileCollapsed?: () => void;
}

export function Sidebar({ onClick, setIsMobileCollapsed }: Props) {
  const [currentPath, setCurrentPath] = useState<string>();
  const [menus, setMenus] = useState<Menu[]>();
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(true);
  const [offCanvasCollapsed, setOffCanvasCollapsed] = useState(true);
  const [widthScreen, setWidthScreen] = useState<number>(0);

  const breakPoint = 992;

  useEffect(() => {
    const currentPath = location.pathname;

    setCurrentPath(currentPath);
    setWidthScreen(window.innerWidth);

    getMenusAsync(currentPath);

    if (window.innerWidth >= breakPoint) {
      setSidebarCollapsed(false);
    }

    checkWidthScreen(window.innerWidth);

    window.onresize = function (event) {
      const widthScreen = (event.target as Window).innerWidth;

      setWidthScreen(widthScreen);

      checkWidthScreen(widthScreen);
    };
  }, []);

  const getMenusAsync = async (currentPath: string) => {
    const res = await account.getMenusAsync();

    if (res.status === HttpStatusCode.OK) {
      const programs = res.data.programs as Menu[];
      const menus = programs.map((m) => levelMenu(m, 1, currentPath));

      setMenus([...menus]);
    }
  };

  const levelMenu = (menu: Menu, level: number, currentPath: string) => {
    menu.collapsed = true;
    menu.level = level;

    if (menu.children && menu.children.length > 0) {
      menu.children.forEach((m) => levelMenu(m, level + 1, currentPath));
    }

    const found = menu?.children?.find(menu => {
      return currentPath.includes(menu.path);
    });

    if (found) {
      menu.collapsed = !found.collapsed;
    }

    return menu;
  };

  const checkWidthScreen = useCallback((widthScreen: number) => {
    if (widthScreen < breakPoint) {
      onSidebarCollapsed();
    }
  }, []);

  const handlerMenuOnClick = useCallback((onMenuClick: Menu) => {
    if (onMenuClick.children && onMenuClick.children.length) {
      const updatedArray = menus?.map((menu) => {
        if (menu.path === onMenuClick.path) {
          return { ...menu, collapsed: !menu.collapsed };
        }

        return { ...menu, collapsed: true };
      });

      setMenus([...updatedArray || []]);
      return;
    }

    setCurrentPath(onMenuClick.path);
    navigate(onMenuClick.path);
  }, [menus]);

  const createMenus = useCallback((menus: Menu[] | undefined, level: number) => {
    if (!menus) {
      return (
        <>
          <div className='placeholder-content'>
            <Placeholder animation='glow'>
              <Placeholder xs={12} />
            </Placeholder>
          </div>
          <div className='placeholder-content'>
            <Placeholder animation='glow'>
              <Placeholder xs={12} />
            </Placeholder>
          </div>
          <div className='placeholder-content'>
            <Placeholder animation='glow'>
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        </>
      );
    }

    const checkActive = (menu: Menu) => {
      const isActive = currentPath?.includes(menu.path);

      if (level === 1) {
        return `${isActive || !menu.collapsed ? 'active' : ''}`;
      }

      if (level === 2 && menu.children && menu.children.length > 0) {
        return 'active';
      }

      return `${isActive ? 'active' : ''}`;
    };

    const subMenu = (menu: Menu) => {
      if (menu.children && menu.children.length > 0) {
        return (
          <ul className={`sub-menu ${!menu.collapsed ? 'collapsed' : ''}`}>
            {
              createMenus(menu.children, level + 1)
            }
          </ul>
        );
      }
    };

    function onClickCollapsed(menuLevel: number) {
      if (menuLevel !== 1) {
        onClick && onClick();
        setIsMobileCollapsed && setIsMobileCollapsed();
      }
    }

    const onSpanClick = (menu: Menu, menuLevel: number) => {
      onClickCollapsed(menuLevel);
      handlerMenuOnClick(menu);
    };

    return (
      <>
        {menus?.map((menu, i) => (
          <li key={generateUniqueId(i)}
            className={`menu ${menu.children && menu.children.length > 0 ? 'children' : 'main'}`}>
            <span className={`frame level-${level} ${checkActive(menu)} `}
              onClick={() => onSpanClick(menu, level)}>
              {
                label(menu.label, menu.collapsed, menu.children && menu.children.length > 0)
              }
            </span>
            {
              subMenu(menu)
            }
          </li>
        ))}
      </>
    );
  }, [menus, currentPath]);

  const label = useCallback((text: string, collapsed?: boolean, haveChildren?: boolean) => (
    <>
      {text} {haveChildren ? collapsed ? <FaAngleDown /> : <FaAngleUp /> : null}
    </>
  ), [menus]);

  const onSidebarCollapsed = useCallback(() => {
    document.dispatchEvent(new CustomEvent('onSidebarIsCollapsed', { detail: true }));

    setSidebarCollapsed(true);
  }, [sidebarCollapsed]);

  return (
    <>
      <div className={`sidebar ${sidebarCollapsed ? 'collapsed' : ''}`}>
        <ul>
          <li className='img-brand'><Image src='/images/logo-207x108.png'
            className='logo-brand'
            onClick={() => navigate('/worklist')} /></li>
          {createMenus(menus, 1)}
        </ul>
      </div>

      <Offcanvas show={!offCanvasCollapsed}
        onHide={() => setOffCanvasCollapsed(!offCanvasCollapsed)}>
        <Offcanvas.Header>
          <Image src='/images/logo-orange.png' />
        </Offcanvas.Header>
        <Offcanvas.Body className='sidebar'>
          {createMenus(menus, 1)}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
