import StepProgress from 'components/StepProgress';
import { TorDetailModel } from 'models/PP/TorModels';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PreProcurementTorServices from 'services/PP/PreProcurementTorServices';
import { HttpStatusCode } from 'utils';
import Step1 from './step1';
import Step3 from './step3';
import MainStep2Tor from './Step2Components/MainStep2';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type TorContext = {
  data: TorDetailModel
  setData: Dispatch<React.SetStateAction<TorDetailModel>>,
  readonly: boolean,
  setReadonly: Dispatch<React.SetStateAction<boolean>>,
};

enum CriteriaStatus {
  Draft = 'Draft',
  DraftTor = 'DraftTor',
  WaitingForCommitteeApprove = 'WaitingForCommitteeApprove',
  WaitingForDirectorJorPorAssign = 'WaitingForDirectorJorPorAssign',
  WaitingForAssignResponsible = 'WaitingForAssignResponsible',
  DraftSectionApprover = 'DraftSectionApprover',
  WaitingForApprove = 'WaitingForApprove',
  Approved = 'Approved',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
}

export const Context = createContext({} as TorContext);

export default function TorDraftDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id } = useParams();
  const [data, setData] = useState<TorDetailModel>({} as TorDetailModel);
  const [readonly, setReadonly] = useState<boolean>(false);
  const [defaultStep, setDefaultStep] = useState(1);

  useEffect(() => {
    if (id) {
      getTorByIdAsynce(id);
    }
  }, [id]);

  const getTorByIdAsynce = async (id: string) => {
    const response = await PreProcurementTorServices.getTorByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
      setStepByStep(response.data.step)
      // setStepByStatus(response.data.status);
    }
  };

  const setStepByStep = (step: number) => {
    switch (step) {
      case 1:
        setDefaultStep(1);
        break;
      case 2:
        setDefaultStep(2);
        break;
      case 3:
        setDefaultStep(3);
        break;
      default: setDefaultStep(1)
        break;
    }
  };

  const setStepByStatus = (status: string) => {
    switch (status) {
      case CriteriaStatus.Draft:
        setDefaultStep(1);
        break;
      case CriteriaStatus.DraftTor:
        setDefaultStep(1);
        break;
      case CriteriaStatus.WaitingForCommitteeApprove:
        setDefaultStep(3);
        break;
      case CriteriaStatus.WaitingForDirectorJorPorAssign:
        setDefaultStep(3);
        break;
      case CriteriaStatus.WaitingForAssignResponsible:
        setDefaultStep(3);
        break;
      case CriteriaStatus.DraftSectionApprover:
        setDefaultStep(3);
        break;
      case CriteriaStatus.WaitingForApprove:
        setDefaultStep(3);
        break;
      case CriteriaStatus.Approved:
        setDefaultStep(3);
        break;
      case CriteriaStatus.Rejected:
        setDefaultStep(1);
        break;
      case CriteriaStatus.Canceled:
        setDefaultStep(1);
        break;
      default:
        break;
    }
  };

  const obj = [
    {
      title: <p>ข้อมูลร่างขอบเขต
        ของงาน
      </p>,
      displayIndex: '1',
      child: <Step1 onClickNext={() => clickNext()}
        onClickBack={() => backToIndex()} />
    },
    {
      title: <p>ข้อมูลขอบเขตของงาน (เพิ่มเติม)</p>,
      displayIndex: '2',
      child: <MainStep2Tor
        templateCode={data.torTemplateCode}
        onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />
    },
    {
      title: <p>บุคคล/คณะกรรมการเห็นชอบ</p>,
      displayIndex: '3',
      child: <Step3 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />
    },
    // {
    //   title: <p>ลำดับเห็นชอบ/อนุมัติ</p>,
    //   displayIndex: '4',
    //   child: <Step4 onClickNext={() => clickNext()}
    //     onClickBack={() => clickBack()} />
    // },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  // const backToIndex = () => {
  //   navigate('/preprocurement-tor')
  // }

  const backToIndex = () => {
    if (data.isChange) {
      navigate('/edit-preprocurement-tor');
    } else if (data.isCancel) {
      navigate('/cancel-preprocurement-tor');
    } else {
      navigate('/preprocurement-tor');
    }
  };
  return (
    <div className="document">
      {
        (data && data.isActive && data.isCancel) && (

          <h4 className="mt-2 mb-0 text-primary text-start">ขอยกเลิกร่างขอบเขตของงาน (TOR)</h4>
        )
      }
      {
        (data && data.isActive && data.isChange) && (
          <h4 className="mt-2 mb-0 text-primary text-start">ขอแก้ไขร่างขอบเขตของงาน (TOR)</h4>
        )
      }
      {
        (data && data.isActive && !data.isChange && !data.isCancel) && (

          <h4 className="mt-2 mb-0 text-primary text-start">จัดทำร่างขอบเขตของงาน (TOR)</h4>
        )
      }
      <Row>
        <Col xs={12}
          className="mb-2 mt-3">
          <Context.Provider value={{ data, setData, readonly, setReadonly }}>
            <StepProgress progressBarData={obj}
              ref={childRef}
              step={defaultStep} />
          </Context.Provider>
        </Col>
      </Row>
    </div>
  );
}
