import {
  AppointmentStatus,
  InputTextArea,
  Status,
  StatusType,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import {
  HistoryModal,
  Modal,
} from 'components/Modal';
import { AppointmentApprovalResponseStep1 } from 'models/PP/PP0301Models';
import {
  useContext,
  useState,
} from 'react';
import {
  Button,
  Card,
  Modal as ModalBT,
} from 'react-bootstrap';
import { BsArrowReturnLeft } from 'react-icons/bs';
import {
  FaAlignLeft,
  FaHistory,
  FaUndo,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import PP0301Service from 'services/PP/PP0301Service';
import {
  fullDatetime,
  HttpStatusCode,
} from 'utils';
import toast from 'utils/toast';
import { Context } from './index';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step4({ onClickBack }: Props) {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const context = useContext(Context);
  const [remark, setRemark] = useState('');
  const [, setAppointmentApprovalResponseStep1Data] = useState<AppointmentApprovalResponseStep1>({} as AppointmentApprovalResponseStep1);

  const dataHistory = [
    {
      no: '1',
      versionNo: 'V1.1.2.005',
      nameEdit: 'สมหญิง รักษ์เมืองไทย',
      dayEdit: '31/12/2021 12:22',
    },
    {
      no: '2',
      versionNo: 'V1.1.2.005',
      nameEdit: 'สมหญิง รักษ์เมืองไทย',
      dayEdit: '31/12/2021 12:22',
    },
  ];

  const onGetProcurementAsync = async (id: string) => {
    const response = await PP0301Service.getAppointmentApprovalDetailAsync(id);

    if (response.status !== HttpStatusCode.OK) {
      return;
    }

    setAppointmentApprovalResponseStep1Data(response.data);
    context.appointmentApprovalData = response.data;
  };

  const updateStatusToApprovedAsync = async () => {
    const response = await PP0301Service.approvedAppointmentApprovalAsync(context.appointmentApprovalData.id!);

    if (response.data === 'Approver Not Found') {
      return toast.error('ไม่สามารถอนุมัติได้เนื่องจากไม่มีสิทธิ์');
    }

    if (response.status === 200) {
      setShowModal(false);
      toast.success('อนุมัติสำเร็จ');
      onGetProcurementAsync(context.appointmentApprovalData.id);
    }
  };

  const updateStatusToRejectedAsync = async () => {
    if (remark === null || remark === '') {
      return toast.error('กรุณากรอกเหตุผลส่งกลับแก้ไข');
    }

    const response = await PP0301Service.rejectedAppointmentApprovalAsync(context.appointmentApprovalData.id!, remark);

    if (response.data === 'Approver Not Found') {
      return toast.error('ไม่สามารถส่งแก่ไขได้เนื่องจากไม่มีสิทธิ์');
    }

    if (response.status === 200) {
      setShow(false);
      toast.success('ส่งกลับแก้ไขสำเร็จ');
      onGetProcurementAsync(context.appointmentApprovalData.id);
    }
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Card.Body>
          <Title text='สถานะการอนุมัติ'
            className='fs-5 text-primary' />
          <Table>
            <thead>
              <tr>
                <th style={{ minWidth: 10 }}>ลำดับ</th>
                <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
                <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
                <th style={{ minWidth: 35 }}>สถานะ</th>
                <th style={{ minWidth: 50 }}>วันที่อนุมัติ</th>
              </tr>
            </thead>
            <tbody>
              {context.appointmentApprovalData.approverAppointPreProcurements?.map((data, index) => (
                <tr key={index}>
                  <td className='text-center'>{index + 1}</td>
                  <td className='text-center'>{data.approveFullName}</td>
                  <td className='text-center'>{data.position}</td>
                  <td className='text-center'>{Status({ value: data.status, type: StatusType.APPOINTMENT })}</td>
                  <td className='text-center'>{fullDatetime(data.approvedDateAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card className='mt-3'>
        <Card.Body>
          <div className='d-flex justify-content-between my-3'>
            <Title text='ตัวอย่างเอกสารบันทึกข้อความแต่งตั้ง'
              className='fs-5 text-primary' />
            <Button
              className='px-3'
              onClick={() => setShowHistoryModal(true)}
              variant='outline-primary'
            >
              <FaHistory className='me-3' />ประวัติการแก้ไข
            </Button>
          </div>
        </Card.Body>
      </Card>
      <div className='d-flex justify-content-center gap-3 pt-3'>
        <Button
          onClick={onClickBack}
          className='me-2 px-3'
          variant='outline-primary'
        >
          <MdArrowBack className='me-2 pb-1 fs-4' />
          ย้อนกลับ
        </Button>
        {
          (context.appointmentApprovalData.status === AppointmentStatus.PENDING) && (
            <Button
              variant='danger'
              onClick={() => setShow(true)}
              className='me-2 px-3'
            >
              <FaUndo className='mx-2 pb-1 fs-4' />
              ส่งกลับแก้ไข
            </Button>
          )
        }
        {
          (context.appointmentApprovalData.status === AppointmentStatus.PENDING) && (
            <Button
              onClick={() => setShowModal(true)}
              className='me-2 px-3'
              variant='primary'
            >
              <FaAlignLeft className='me-2 pb-1 fs-5' />
              ส่งอนุมัติ
            </Button>
          )
        }
      </div>
      <Modal
        show={showModal}
        size='lg'
        onHide={() => setShowModal(!showModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>อนมุัติ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการอนุมัติบันทึกการแต่งตั้งหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowModal(!showModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={updateStatusToApprovedAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={show}
        size='lg'
        onHide={() => setShow(!show)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea
                label='เหตุผล'
                rule={{ required: true }}
                value={remark}
                onChange={(value) => setRemark(value)}
              />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-center'>
              <Button variant='outline-primary'
                onClick={() => setShow(!show)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={updateStatusToRejectedAsync}
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        data={dataHistory}
      />
    </div>
  );
}

export default Step4;
