import StepProgress from 'components/StepProgress';
import {
  Dispatch,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import Step1 from './step1';
import Step2 from './step2';
import { ContractAuditModel } from 'models/CM/CM04Models';
import CM04Service from 'services/CM/CM04Service';
import { HttpStatusCode } from 'utils';
import { ContractAuditStatus } from 'components';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type MedianPriceContext = {
  data: ContractAuditModel
  setData: Dispatch<React.SetStateAction<ContractAuditModel>>,
};

export const Context = createContext({} as MedianPriceContext);

export default function CreateContractMangementDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id } = useParams();
  const [data, setData] = useState<ContractAuditModel>({} as ContractAuditModel);
  const [defaultStep, setDefaultStep] = useState(1);

  useEffect(() => {
    if (id) {
      getContractAuditByIdAsynce(id);
    }
  }, [id]);

  const getContractAuditByIdAsynce = async (id: string) => {
    const response = await CM04Service.getContractAuditByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
      setStepByStatus(response.data.status);
    }
  };

  const setStepByStatus = (status: string) => {
    switch (status) {
      case ContractAuditStatus.Draft:
        setDefaultStep(1);
        break;
      case ContractAuditStatus.Rejected:
        setDefaultStep(1);
        break;
      case ContractAuditStatus.Pending:
        setDefaultStep(2);
        break;
      case ContractAuditStatus.Approved:
        setDefaultStep(2);
        break;
      default:
        break;
    }
  };

  const obj = [{
    title: <p>รายงานสำนักงานการตรวจเงินแผ่นดินและกรมสรรพากร</p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} />,
  },
  {
    title: <p> ผู้มีอำนาจเห็นชอบ/อนุมัติ </p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} />,
  },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/cm/cm04');
  };

  return (
    <div className="document">
      <Row>
        <Col xs={12} className="mb-2">
          <Context.Provider value={{ data, setData }}>
            <StepProgress progressBarData={obj}
              ref={childRef}
              step={defaultStep} />
          </Context.Provider>
        </Col>
      </Row>
    </div>
  );
}
