import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  DatePicker,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ContractAgreementModel, EmploymentAgreementModel } from '../../../../../../models/CM/CM02Models';

interface IProps {
  title?: string;
  contractTemplateCode?: string;
  employmentAgreement: EmploymentAgreementModel;
  onEmploymentAgreementChange: (value: EmploymentAgreementModel) => void;
  isDisabled?: boolean;
  contractData: ContractAgreementModel;
}

const fields = [
  {
    code: 'CF01',
    startDate: 'ผู้รับจ้างต้องเริ่มทำงานที่รับจ้าง ภายในวันที่',
    dueDate: 'ผู้รับจ้างต้องทำงานให้เสร็จบริบูรณ์ ภายในวันที่',
  },
  {
    code: 'CF02',
  },
  {
    code: 'CF03',
  },
  {
    code: 'CF04',
  },
  {
    code: 'CF05',
  },
  {
    code: 'CF06',
  },
  {
    code: 'CF07',
  },
  {
    code: 'CF08',
  },
  {
    code: 'CF09',
  },
  {
    code: 'CF10',
  },
  {
    code: 'CF11',
  },
  {
    code: 'CF12',
  },
  {
    code: 'CF13',
  },
  {
    code: 'CF14',
  },
  {
    code: 'CF15',
  },
];

export function ProcessingTime2(props: IProps) {
  const [employmentAgreement, setEmploymentAgreement] = useState<EmploymentAgreementModel>(props.employmentAgreement);

  useEffect(() => {
    if (employmentAgreement) {
      props.onEmploymentAgreementChange(employmentAgreement);
    }
  }, [employmentAgreement]);

  const onNumberChange = useCallback((key: string, value: number) => {
    setEmploymentAgreement(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <>
      <Card className='mt-3'>
        <Title text={`สัญญา${props.title}`}
          className='fs-5 text-primary' />
        <Row className='mt-3'>
          <>
            {
              fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
                <div>

                  {
                    (data.startDate || data.dueDate) && (
                      <Row className='mt-3'>
                        {
                          (data.startDate) &&
                          (
                            <Col sm={4}>
                              <DatePicker
                                label={data.startDate}
                                value={props.contractData.deliveryPeriod.startDate}
                                onChangeHasNullable={(value) => setEmploymentAgreement({ ...employmentAgreement, startDate: value })}
                                disabled={true}
                              />
                            </Col>
                          )
                        }
                        {
                          (data.dueDate) &&
                          (
                            <Col sm={4}>
                              <DatePicker
                                label={data.dueDate}
                                value={props.contractData.deliveryPeriod.dueDate}
                                onChangeHasNullable={(value) => setEmploymentAgreement({ ...employmentAgreement, dueDate: value })}
                                disabled={true}
                              />
                            </Col>
                          )
                        }
                      </Row>
                    )
                  }
                </div>
              )}
          </>
        </Row>
      </Card>
    </>
  );
}