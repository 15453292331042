import {
  AppointmentStatus,
  DatePicker,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import {
  AppointmentListResponseModel,
  AppointmentModel,
  SearchPP0201Model,
} from 'models/P/PP0201Model';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { useLoaderData } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PP0201Services from 'services/PP/PP0201Services';
import {
  HttpStatusCode,
  thaiFormatDateWithSlash,
} from 'utils';
import { useForm } from '../../../utils/useForm';

interface ItemModel {
  label: string;
  value: string;
}

type Loader = {
  departmentDDL: ItemModel[],
};

export default function PP0201() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [formSearch, setFormSearch] = useState<SearchPP0201Model>({} as SearchPP0201Model);
  const [appointmentData, setAppointmentData] = useState<AppointmentListResponseModel>({} as AppointmentListResponseModel);
  const { departmentDDL } = useLoaderData() as Loader;

  const searchAppointmentList = useCallback(async (searchModel: SearchPP0201Model) => {
    if (!searchModel.page) {
      searchModel.page = page;
    }

    if (!searchModel.size) {
      searchModel.size = size;
    }

    const { data, status } = await PP0201Services.getListPP0201Async(searchModel);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    setAppointmentData(data);
  }, []);

  useEffect(() => {
    searchAppointmentList({ page, size });
  }, [page, searchAppointmentList, size]);

  useEffect(() => {
    searchAppointmentList(formSearch);
  }, [formSearch]);

  return (
    <div className="m01">
      <PP0201Header />
      <Criteria
        setForm={setFormSearch}
        departmentList={departmentDDL}
        page={page}
        size={size}
      />
      <DataTable
        appointmentData={appointmentData.data}
        total={appointmentData.totalCount}
        setPage={setPage}
        setSize={setSize}
      />
    </div>
  );
}

function PP0201Header() {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">จัดทำคำสั่งแต่งตั้ง</h4>
        <Button onClick={() => navigate('detail')}><MdAdd className="me-2 fs-5" />จัดทำคำสั่ง</Button>
      </div>
      <hr />
    </>
  );
}

interface FormCriteriaInput {
  appointMemorandumDate: Date;
  departmentId: string;
  projectName: string;
  subject: string;
  committeeTorName: string;
  responsiblePerson: string;
  status: string;
}

interface CriteriaProps {
  setForm: (form: SearchPP0201Model) => void;
  departmentList: ItemModel[];
  page: number;
  size: number;
}

function Criteria(props: CriteriaProps) {
  const [appointMemorandumDate, setAppointMemorandumDate] = useState<Date>();
  const [departmentId, setDepartmentId] = useState('');
  const [status, setStatus] = useState('');

  const statusOptions: ItemModel[] = [
    { value: AppointmentStatus.DRAFT, label: 'ร่างคำสั่งแต่งตั้ง' },
    { value: AppointmentStatus.PENDING, label: 'รออนุมัติ' },
    { value: AppointmentStatus.APPROVED, label: 'อนุมัติ' },
    { value: AppointmentStatus.REJECTED, label: 'ส่งกลับแก้ไข' },
  ];

  const onClear = useCallback(async () => {
    setAppointMemorandumDate(undefined);
    setDepartmentId('');
    setStatus('');
    handleClear();
  }, []);

  const { handleSubmit, handleChange, data: formData, handleClear } = useForm<FormCriteriaInput>({
    initialValues: {
      appointMemorandumDate: undefined,
      departmentId: undefined,
      projectName: undefined,
      subject: undefined,
      committeeTorName: undefined,
      responsiblePerson: undefined,
      status: undefined,
    },
    validations: {},
    onSubmit: async () => {
      let apmDate: Date | undefined;

      if (appointMemorandumDate) {
        apmDate = new Date(appointMemorandumDate);
      }

      const searchModel: SearchPP0201Model = {
        page: props.page,
        size: props.size,
        appointMemorandumDate: apmDate,
        departmentId,
        projectName: formData.projectName,
        subject: formData.subject,
        committeeTorName: formData.committeeTorName,
        responsiblePerson: formData.responsiblePerson,
        status,
      };

      props.setForm(searchModel);
    },
  });

  return (
    <Form onSubmit={handleSubmit} id="formPL01">
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <DatePicker
              label="วันที่เอกสารบันทึกข้อความแต่งตั้ง"
              value={appointMemorandumDate}
              onChange={setAppointMemorandumDate}
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Selector
              label="ฝ่าย/สำนัก"
              items={props.departmentList}
              value={`${departmentId}`}
              onChange={(value) => (setDepartmentId(value.toString()))}
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>ชื่อโครงการ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="ชื่อโครงการ"
                value={formData.projectName || ''}
                onChange={handleChange<string>('projectName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>เรื่อง</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="เรื่อง"
                value={formData.subject || ''}
                onChange={handleChange<string>('subject', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>คณะกรรมการจัดทำร่างขอบเขตของงาน</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="คณะกรรมการจัดทำร่างขอบเขตของงาน"
                value={formData.committeeTorName || ''}
                onChange={handleChange<string>('committeeTorName', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Form.Label>ผู้จัดทำ</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="ผู้จัดทำ"
                value={formData.responsiblePerson || ''}
                onChange={handleChange<string>('responsiblePerson', (value) => value)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Form.Group className="mb-3">
            <Selector
              label="สถานะ"
              items={statusOptions}
              value={`${status}`}
              onChange={(value) => (setStatus(value.toString()))}
            />
          </Form.Group>
        </Col>

        <div className="d-flex gap-2">
          <Button form="formPL01" type="submit" variant="primary" className="d-flex align-items-center gap-2">
            <FaSearch />ค้นหา
          </Button>
          <Button variant="outline-primary" onClick={onClear} className="d-flex align-items-center gap-2">
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
    </Form>
  );
}

interface DataTableProps {
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  total: number;
  appointmentData: AppointmentModel[];
}

function DataTable(props: DataTableProps) {
  const navigate = useNavigate();
  const goToPage = (id: string) => {
    navigate(`/pp/pp0201/detail/${id}`);
  };

  return (
    <Table
      className="mt-4"
      total={props.total}
      onChange={(s, p) => {
        props.setPage(p);
        props.setSize(s);
      }}
    >
      <thead>
        <tr className="text-center">
          <th>ฝ่าย/สำนัก</th>
          <th>ชื่อโครงการ</th>
          <th>ชื่อเรื่อง</th>
          <th>วิธีการจัดหา</th>
          <th>วันที่เอกสารบันทึกข้อความแต่งตั้ง</th>
          <th>ผู้จัดทำ</th>
          <th>สถานะ</th>
          <th>รายละเอียด</th>
        </tr>
      </thead>
      <tbody>
        {
          props.appointmentData?.map((item, index) => (
            <tr key={index}>
              <td className="text-center">{item.department}</td>
              <td>{item.planName}</td>
              <td>{item.subject}</td>
              <td className="text-center"><p>{item.supplyMethod}</p></td>
              <td className="text-center">{thaiFormatDateWithSlash(item.memorandumDate)}</td>
              <td>{item.createdInfo_ByUserFullName}</td>
              <td className="text-center">{Status({ value: item.status, type: StatusType.APPOINTMENT })}</td>
              <td className="text-center">
                <Button variant="link" onClick={() => goToPage(item.id)}>ดูรายละเอียด</Button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
}
