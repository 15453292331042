import { GetAppointmentApprovalListRequest } from 'models/PP/PP0301Models';
import http from 'utils/axios';

const qs = require('qs');

const getAppointmentApprovalListAsync = async (searchData: GetAppointmentApprovalListRequest) => {
  const params = {
    page: searchData.page,
    size: searchData.size,
    appointMemorandumDate: searchData.apointMemorandumDate,
    departmentId: searchData.departmentId,
    projectName: searchData.projectName,
    subject: searchData.subject,
    commiteeTorName: searchData.commiteeTorName,
    responsiblePerson: searchData.responsiblePerson,
    status: searchData.status,
  };

  return http.get('/appointmentapproval/pagination', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getAppointmentApprovalDetailAsync = async (id: string) => await http.get(`/appointmentapproval/${id}`);

const approvedAppointmentApprovalAsync = async (id: string) => await http.put(`/appointmentapproval/approved/${id}`);

const rejectedAppointmentApprovalAsync = async (id: string, remark: string) => {
  const body = {
    remark,
  };
  return await http.put(`/appointmentapproval/rejected/${id}`, body);
};

export default {
  getAppointmentApprovalListAsync,
  getAppointmentApprovalDetailAsync,
  approvedAppointmentApprovalAsync,
  rejectedAppointmentApprovalAsync,
};