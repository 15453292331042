import {
  Card,
  HistoryModal,
  InputTextArea,
  Modal,
  ProcessStatus,
  Status,
  StatusType,
  Table,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { useState } from 'react';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { BsArrowReturnLeft } from 'react-icons/bs';
import {
  FaAlignLeft,
  FaHistory,
  FaUndo,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { generateUniqueId } from '../../../../utils/helper';
import { dataHistory } from '../data';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function GetStatus(i: number) {
  switch (i) {
    case 1:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.PENDING} />;
    case 2:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.APPROVED} />;
    case 3:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.REJECTED} />;
    default:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.DRAFT} />;
  }
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="สถานะการอนุมัติ" className="fs-5 text-primary" />
        <Table className="mt-3">
          <thead>
            <tr className="text-center">
              <th>ลำดับ</th>
              <th>รหัสพนักงาน</th>
              <th>ชื่อ-นามสกุล</th>
              <th>ตำแหน่ง</th>
              <th>สถานะ</th>
              <th>วันที่อนุมัติ</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 5 }).map((_, index) => (
              <tr className="text-center" key={generateUniqueId(index)}>
                <td>1</td>
                <td>5900010</td>
                <td>สมศรี แดนไทย</td>
                <td>รักษาการผู้อำนวยการสำนักงบประมาณ</td>
                <td>{GetStatus(index)}</td>
                <td>28-11-2566 16:24:22</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className="mt-3">
        <div className="d-flex justify-content-between my-3">
          <Title text="ตัวอย่างเอกสารหนังสือลงนามสัญญา" className="fs-5 text-primary" />
          <Button
            className="px-3"
            onClick={() => setShow(true)}
            variant="light"
          >
            <FaHistory className="me-3" />ประวัติการแก้ไข
          </Button>
        </div>
      </Card>

      <div className="d-flex justify-content-center gap-3 pt-3">
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <ButtonCustom
          variant="danger"
          onClick={() => setShowEdit(true)}
          icon="undo"
          text="ส่งกลับแก้ไข"
        />
        <ButtonCustom
          text="ลงนาม"
        />
      </div>

      <HistoryModal
        show={show}
        onHide={() => setShow(!show)}
        data={dataHistory}
      />

      <Modal
        show={showEdit}
        size="lg"
        onHide={() => setShowEdit(!showEdit)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              <InputTextArea label="หมายเหตุ" rule={{ required: true }} />
            </ModalBT.Body>
            <ModalBT.Footer className="justify-content-center">
              <Button
                variant="light"
                onClick={() => setShowEdit(!showEdit)}
                className="me-2 px-3"
              >
                ยกเลิก
              </Button>
              <Button
                variant="danger"
                className="me-2 px-3"
              >
                <FaUndo className="mx-2 pb-1 fs-4" />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step2;
