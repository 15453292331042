import Cookie from 'cookie-universal';
import {
  CreatePL0202Model,
  PL0202Criteria,
} from 'models/PL';
import http from 'utils/axios';

const cookies = Cookie();

const getFullName = cookies.get('fullName') as string;

const getInYearPlanListAsync = async (
  page: number,
  size: number,
  criteria?: PL0202Criteria,
) => {
  const params = {
    page,
    size,
    ...criteria,
  };
  return await http.get('/inyearplan', { params });
};

const createInYearPlanAsync = async (body: CreatePL0202Model) => {
  const formData = new FormData();
  formData.append('departmentId', body.departmentId);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodName', body.supplyMethodName);
  formData.append('planName', body.planName);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString());
  formData.append('planAnnouncementDate', body.planAnnouncementDate ? new Date(body.planAnnouncementDate).toISOString() : body.planAnnouncementDate);
  formData.append('planEgpNumber', body.planEgpNumber);
  formData.append('createdByFullName', getFullName);
  formData.append('type', body.type);

  if (body.userIds !== undefined && body.userIds.length > 0) {
    body.userIds.forEach((userId) => {
      formData.append('userIds', userId);
    });
  }

  if (body.documents.length !== 0) {
    body.documents.forEach((file) => {
      formData.append('documents', file);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post('/inyearplan', formData, config);
};

const updateInYearPlanAsync = async (id: string, body: CreatePL0202Model) => {
  const formData = new FormData();
  formData.append('departmentId', body.departmentId);
  formData.append('supplyMethod', body.supplyMethod);
  formData.append('supplyMethodName', body.supplyMethodName);
  formData.append('planName', body.planName);
  formData.append('planBudget', body.planBudget.toString());
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt).toISOString());
  formData.append('planAnnouncementDate', body.planAnnouncementDate ? new Date(body.planAnnouncementDate).toISOString() : body.planAnnouncementDate);
  formData.append('planEgpNumber', body.planEgpNumber);
  formData.append('updatedByUserFullName', cookies.get('fullName') || '');
  formData.append('type', body.type);

  if (body.userIds !== undefined && body.userIds.length !== 0) {
    body.userIds.forEach((userId) => {
      formData.append('userIds', userId);
    });
  }

  if (body.documents.length !== 0) {
    body.documents.forEach((file) => {
      formData.append('documents', file);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return await http.put(`/inyearplan/${id}`, formData, config);
};

const getDetailInYearPlanAsync = async (id: string | undefined) => await http.get(`/inyearplan/${id}`);

const deleteInYearPlan = async (id: string) => await http.delete(`annualplan/${id}`);

export default {
  getInYearPlanListAsync,
  createInYearPlanAsync,
  getDetailInYearPlanAsync,
  updateInYearPlanAsync,
  deleteInYearPlan,
};
