import {
  Card,
  Currency,
  Input,
  Modal,
  SearchBuNameModal,
  Table,
  ArrowCollapse,
  Selector,
} from 'components';
import Title from 'components/Controls/Title';
import { PurchaseOrderApprovalStatus } from 'components/PurchaseOrderApprovalStatus';
import { JorPorCode } from 'constant/basicInformation';
import {
  AssigneesLevel,
  PurchaseOrderApprovalStatusConstant,
} from 'constant/PurchaseOrderApprovalConstant';
import {
  IFile,
  ItemModel,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
} from 'models';
import {
  AcceptorsModel,
  AssigneesModel,
  CommitteeModel,
  ContractRecordCommitteeModel,
  ContractRecordEntrepreneursModel,
  ContractRecordEntrepreneursRequestModel,
  documentsModel,
} from 'models/PR/PurchaseOrderApprovalModels';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaHistory,
  FaPlus,
  FaSave,
  FaTrashAlt,
  FaChevronCircleDown,
} from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router';
import { shareValue } from 'services';
import PurchaseOrderApprovalServices from 'services/PR/PurchaseOrderApprovalServices';
import {
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { HistoryModal } from './HistoryModal';
import UploadFile from './UploadFile';
import { AssignLevel } from 'utils/constants/PlanEnum';
import { CommitteeType } from 'pages/PR/PR0101/Detail';

type Loader = {
  supplyMethodDDL: ItemModel[],
  supplyMethodTypeDDL: ItemModel[],
  departmentDDL: ItemModel[],
  budgetYearDDL: ItemModel[],
  positionOnBoardInSpectionDDL: ItemModel[],
  positionOnBoardSupervisorDDL: ItemModel[],
  positionOnBoardMADDL: ItemModel[],
  positionOnBoardProcurementDDL: ItemModel[],
};

function ContractRecordCommittee() {
  const { positionOnBoardInSpectionDDL } = useLoaderData() as Loader;
  const [showHistory, setShowHistory] = useState(false);
  const [showUserCommittee, setShowUserCommittee] = useState(false);
  const [showUserAssigneeLevel1, setShowUserAssigneeLevel1] = useState(false);
  const { userId, positionCode, departmentCode } = useAppContext();
  const [supplyMethod, setSupplyMethod] = useState('');
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);
  const [isDisabled, setisDisabled] = useState(false);
  const [committees, setCommittees] = useState<CommitteeModel[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<ContractRecordEntrepreneursModel>({} as ContractRecordEntrepreneursModel);
  const [poNumberData, setPoNumberData] = useState('');
  const [offerTotalAmountData, setofferTotalAmountData] = useState(0);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [isDisabledRemove, setIsDisabledRemove] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const goToPage = (jorPor06Id?: string, basicInformationId?: string, id?: string) => {
    if (jorPor06Id) {
      navigate(`/pr/pr0206/detail/${jorPor06Id}/id/${id}`);
    } else if (basicInformationId) {

      navigate(`/pr/pr0206/detail/basicInformation/${basicInformationId}/id/${id}`);
    } else {
      navigate(`/pr/pr0206/detail/id/${id}`);
    }
  };

  useEffect(() => {
    if (id) {

      getDataAsync(id);

    }
  }, [id]);

  useEffect(() => {
    if (data) {

      if (data.contractRecordEntrepreneurs?.committees) {
        setCommittees(data.contractRecordEntrepreneurs?.committees);
      }

      if (data.contractRecordEntrepreneurs?.status !== PurchaseOrderApprovalStatusConstant.Assigned) {
        setisDisabled(false);
      } else {
        setisDisabled(true);
      }

      if (data.contractRecordEntrepreneurs?.poNumber) {
        setPoNumberData(data.contractRecordEntrepreneurs.poNumber);
      }

      if (data.contractRecordEntrepreneurs?.offerTotalAmount) {
        setofferTotalAmountData(data.contractRecordEntrepreneurs.offerTotalAmount);
      }

      if (data.contractRecordEntrepreneurs?.attachmentFiles) {
        handleSetFile(data.contractRecordEntrepreneurs.attachmentFiles);
      }

    }
  }, [data, userId]);

  const getDataAsync = async (id?: string) => {

    const res = await PurchaseOrderApprovalServices.getEntrepreneursByIdAsync(id);
    if (res.status === HttpStatusCode.OK) {
      setData(res.data);
    }
  };

  const onSelectEmpCommitteeData = (empId: string, name: string, position: string, department: string, index: number) => {
    if (committees && committees.filter((x) => x.userId === empId).length > 0) {

      toast.warn('ไม่สามารถเลือกรายชื่อผู้ตรวจรับพัสดุ/คณะกรรมการตรวจรับซ้ำได้');
    } else {
      const newData: CommitteeModel = {
        id: undefined,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        sequence: committees.length + 1,
        committeeType: data.contractRecordEntrepreneurs?.receiveCommitteeId,
        departmentId: undefined,
        positionId: undefined,
        positionOnBoardId: undefined,
      };
      setCommittees((committee) => [...committee, newData]);
    }
  };

  const onSubmitAsync = async (isAssignToAssignees: boolean) => {
    submitForm();

    if (committees && committees.length === 0) {
      toast.warn('กรุณาระบุรายชื่อผู้ตรวจรับพัสดุ/คณะกรรมการตรวจรับ');
      return;
    }

    if (committees && committees.filter((x) => x.positionOnBoardId === undefined || x.positionOnBoardId === null || x.positionOnBoardId === '').length > 0) {
      toast.warn('กรุณาระบุตำแหน่งในบุคคล/คณะกรรมการ');
      return;
    }

    committees?.forEach((item, i) => {
      item.sequence = i + 1
    });

    if (data && data.contractRecordEntrepreneurs?.id && committees.length > 0) {
      const updateDataModel: ContractRecordCommitteeModel = {
        contractRecordFinancialId: data.contractRecordEntrepreneurs?.contractRecordFinancialId,
        id: data.contractRecordEntrepreneurs.id,
        contractRecordId: data.contractRecordEntrepreneurs?.contractRecordId,
        committees: committees,
      };

      const res = await PurchaseOrderApprovalServices.updateEntrepreneursCommitteeAsync(updateDataModel);

      if (res.status === HttpStatusCode.OK) {

        toast.success('บันทึกข้อมูลสำเร็จ');
        getDataAsync(id);
        // navigate(`/pr/pr0206/purchase-order-approval-committee/${id}`);
        goToPage(data.contractRecordEntrepreneurs.jorPor06Id, data.contractRecordEntrepreneurs.basicInformationId, data.contractRecordEntrepreneurs.contractRecordId)
      } else {
        toast.error(res.statusText);
      }
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.contractRecordEntrepreneurs.contractRecordId) {
      const res = await PurchaseOrderApprovalServices.uploadPurchaseOrderApprovalAttachmentAsync(
        data.contractRecordEntrepreneurs.contractRecordId,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getDataAsync(data.contractRecordEntrepreneurs?.id);
      }
    }
  };

  const handleSetFile = (documents: documentsModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {

    if (data && data.contractRecordEntrepreneurs.contractRecordId) {
      const res = await PurchaseOrderApprovalServices.removeFileAsync(
        data.contractRecordEntrepreneurs.contractRecordId,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getDataAsync(data.contractRecordEntrepreneurs?.id);
      }
    }

  }, [files, data.contractRecordEntrepreneurs?.id]);

  const onPositionOnBoardChange = (userId: string, value: string) => {
    const index = committees.findIndex(c => c.userId === userId);

    if (index >= 0) {

      committees[index].positionOnBoardId = value;
      setCommittees(committees);
    }
  };

  const removeUser = (index: number) => {
    const temp: CommitteeModel[] = [...committees];
    temp.splice(index, 1);
    setCommittees(temp);
  };


  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <Title
              text='มอบหมายผู้รับผิดชอบสัญญา'
              className='fs-5 text-primary'
            />
          </div>
          <div className='d-flex gap-2'>
            <PurchaseOrderApprovalStatus value={data.contractRecordEntrepreneurs?.status} />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'>
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={3}>
            <Input
              label='เลขที่ใบสั่ง/สัญญา'
              placeholder='เลขที่ใบสั่ง/สัญญา'
              value={data.contractRecordEntrepreneurs?.contractNumber}
              disabled
              variant='primary'
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={3}>
            <Input
              label='เลขที่ผู้เสียภาษี'
              placeholder='เลขที่ผู้เสียภาษี'
              value={data.contractRecordEntrepreneurs?.entrepreneursTaxId}
              disabled
              variant='primary'
            />
          </Col>
          <Col sm={12}
            lg={3}>
            <Input
              label='ชื่อสถานประกอบการ'
              placeholder='ชื่อสถานประกอบการ'
              value={data.contractRecordEntrepreneurs?.entrepreneursName}
              disabled
              variant='primary'
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            lg={3}>
            <Currency
              label='วงเงิน'
              placeholder='วงเงิน'
              value={offerTotalAmountData}
              onChange={(val) => setofferTotalAmountData(Number(val))}
              disabled
            />
          </Col>
          <Col sm={12}
            lg={3}>
            <Input
              label='เลขที่ PO'
              placeholder='เลขที่ PO'
              value={poNumberData}
              variant='primary'
              onChange={(val) => setPoNumberData(val)}
              disabled
            />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title
          text='รายชื่อผู้ตรวจรับพัสดุ/คณะกรรมการตรวจรับ'
          className='fs-5 text-primary' />
        <>
          <div className='d-flex justify-content-end my-3'>
            <Button
              variant='outline-primary'
              onClick={() => setShowUserCommittee(true)}
              className='d-flex align-items-center gap-2'>
              <FaPlus />เพิ่มรายชื่อ
            </Button>
          </div>
        </>
        <Table hidePagination>
          <thead>
            <tr>
              <th style={{ width: '5%' }}>ลำดับ</th>
              <th style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
              <th style={{ width: '30%' }}>ตำแหน่ง</th>
              <th style={{ width: '30%' }}>ตำแหน่งในบุคคล/คณะกรรมการ</th>
              <th style={{ width: '5%' }} />
            </tr>
          </thead>
          <tbody>
            {committees?.map((data, i) => (
              <tr key={data.id}>
                <td className='text-center'>{i + 1}</td>
                <td className='text-start'>{data.fullName}</td>
                <td className='text-start'>{data.positionName}</td>
                <td>
                  <Selector
                    items={positionOnBoardInSpectionDDL}
                    value={data.positionOnBoardId}
                    onChange={val => onPositionOnBoardChange(data.userId, val)}
                    rule={{ required: true }}
                    disabled={isDisabled} />
                </td>
                <td>
                  <div className='d-flex justify-content-end'>
                    <Button
                      variant='danger'
                      className='d-flex justify-content-center align-items-center'
                      onClick={() => removeUser(i)}
                      disabled={isDisabled}>
                      <FaTrashAlt />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.contractRecordEntrepreneurs?.contractRecordId!}
            />
          </Col>
        </Row>
      </Card>
      <HistoryModal
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={data.contractRecordEntrepreneurs?.contractRecordId}
      />
      <div className='d-flex justify-content-between pt-3'>
        <Button
          className='me-2 px-3'
          onClick={() => goToPage(data.contractRecordEntrepreneurs.jorPor06Id, data.contractRecordEntrepreneurs.basicInformationId, data.contractRecordEntrepreneurs.contractRecordId)}
          variant='outline-primary'>
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div>
          {(data.contractRecordEntrepreneurs?.status !== PurchaseOrderApprovalStatusConstant.Assigned) && (
            <Button
              className='me-2 px-3'
              variant='success'
              onClick={() => onSubmitAsync(false)}
            >
              <FaSave className='me-2 pb-1 fs-5' />
              บันทึก
            </Button>
          )}
        </div>
        <div style={{ width: 145 }} />
      </div>

      <SearchBuNameModal
        total={userTotal}
        show={showUserCommittee}
        onHide={() => setShowUserCommittee(!showUserCommittee)}
        onSelect={(id, name, department, position) => onSelectEmpCommitteeData(id, name, position!, department!, committees.length + 1)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
    </div>
  );
}

export default ContractRecordCommittee;
