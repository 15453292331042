import { Badge } from 'react-bootstrap';

interface BadgeProps {
  status: boolean;
}

export function BadgeStatus(props: BadgeProps) {
  if (props.status) {
    return <Badge className="w-50" bg="success">ใช้งาน</Badge>;
  }

  return <Badge className="w-50" bg="danger">ไม่ใช้งาน</Badge>;
}