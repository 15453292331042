import StepProgress from 'components/StepProgress';
import { EntrepreneursModel } from 'models/PR/JorPor06Models';
import {
  entrepreneursListModel,
  PurchaseOrderApprovalModel,
} from 'models/PR/PurchaseOrderApprovalModels';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { jorPor06 } from 'services';
import PurchaseOrderApprovalServices from 'services/PR/PurchaseOrderApprovalServices';
import { HttpStatusCode } from 'utils';
import Step1 from './step1';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type PurchaseOrderApprovalContext = {
  data: PurchaseOrderApprovalModel
  setData: Dispatch<React.SetStateAction<PurchaseOrderApprovalModel>>,
};

export const Context = createContext({} as PurchaseOrderApprovalContext);

function AddPR0204() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id, jorPor06Id, basicInformationId } = useParams();
  const [data, setData] = useState<PurchaseOrderApprovalModel>({} as PurchaseOrderApprovalModel);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (jorPor06Id) {
      getPurchaseOrderApprovalByIdAsynce(jorPor06Id, undefined, id);
      getEntrepreneursWinnerListAsync(jorPor06Id);
    } else if (basicInformationId) {
      getPurchaseOrderApprovalByIdAsynce(undefined, basicInformationId, id);
    } else {
      getContractRecordByIdAsync(id);
    }
  }, [id, jorPor06Id]);

  const getContractRecordByIdAsync = async (id?: string) => {
    const response = await PurchaseOrderApprovalServices.getContractRecordByIdAsync(id);
    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
    }
  };

  const getPurchaseOrderApprovalByIdAsynce = async (jorPor06Id?: string, basicInformationId?: string, id?: string) => {
    const response = await PurchaseOrderApprovalServices.getPurchaseOrderApprovalByIdAsync(jorPor06Id, basicInformationId, id);
    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
    }
  };

  const [entrepreneursWinnesData, setEntrepreneursWinnesData] = useState<EntrepreneursModel>({} as EntrepreneursModel);

  const getEntrepreneursWinnerListAsync = async (jorPor06Id?: string) => {
    const res = await jorPor06.getEntrepreneursWinnerAsync(jorPor06Id!);

    if (res.status === HttpStatusCode.OK) {
      setEntrepreneursWinnesData(res.data);
    }
  };

  useEffect(() => {
    if (firstLoad && data.contractRecord?.financials && data.contractRecord?.financials.filter((x) => x.entrepreneursList.length).length === 0) {
      data.contractRecord?.financials?.forEach((temp, i) => {
        const newData: entrepreneursListModel[] = [];
        entrepreneursWinnesData.entrepreneurWinners?.forEach((item, index) => {

          newData.push({
            id: undefined,
            sequence: newData.length + 1,
            contractRecordFinancialId: '',
            inviteEntrepreneursId: item.inviteEntrepreneursId,
            entrepreneursTaxId: item.taxPayerIdentificationNo,
            entrepreneursName: item.establishmentName,
            offerTotalAmount: item.parcels.reduce((a, b) => a += b.confirmPriceTotal, 0),
            poNumber: undefined,
            contractNumber: '',
            status: undefined,
          } as entrepreneursListModel);
        });

        temp.entrepreneursList = newData;

      });

      setData(pre => {

        const newObj = { ...pre.contractRecord, financials: data.contractRecord.financials };
        if (!newObj) {
          return pre;
        }

        const newObj2 = { ...pre, contractRecord: newObj } as PurchaseOrderApprovalModel;
        if (!newObj2) {
          return pre;
        }

        return newObj2;
      });

      setFirstLoad(false);

    }

  }, [data]);

  const obj = [
    {
      title: <p>บันทึกทะเบียนเลขที่สัญญา</p>,
      displayIndex: '1',
      child: <Step1 onClickNext={() => clickNext()}
        onClickBack={() => backToIndex()} />,
    },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/pr/pr0206');
  };

  return (
    <div className='document'>
      <h4 className='mt-2 mb-0 text-primary text-start'>อนุมัติใบสั่งซื้อ/จ้าง/เช่า และแจ้งทำสัญญา</h4>
      <Row>
        <Col xs={12}
          className='mb-2 mt-3'>
          <Context.Provider value={{ data, setData }}>
            {/* <Step1 onClickNext={() => clickNext()} onClickBack={() => backToIndex()} /> */}
            <StepProgress progressBarData={obj}
              ref={childRef} />
          </Context.Provider> <></>
        </Col>
      </Row>
    </div>
  );
}

export default AddPR0204;
