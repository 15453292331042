import { Table } from 'components';
import Title from 'components/Controls/Title';
import { UserAccountModal } from 'components/Modal/UserAccountModal';
import { ItemModel } from 'models';
import {
  AppointmentDetailModel,
  committeeModel,
  dutyTermsModel,
} from 'models/P/PP0201Model';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Card,
  Form,
  InputGroup,
} from 'react-bootstrap';
import {
  FaPlus,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { shareValue } from 'services';
import { HttpStatusCode } from 'utils';
import toast from 'utils/toast';
import { useForm } from 'utils/useForm';

interface Props {
  setForm: (form: AppointmentDetailModel) => void;
  appointmentData: AppointmentDetailModel;
  onClickNext: () => void;
  onClickBack: () => void;
  isDisabled?: boolean;
}

interface FormInput {
  committeeTermsOfReferences: [];
  dutyTermsOfReferences: [];
}

function Step3({ onClickNext, onClickBack, appointmentData, setForm, isDisabled = false }: Props) {
  const [show, setShow] = useState(false);
  const [committees, setCommittees] = useState<committeeModel[]>([]);
  const [dutyTerms, setDutyTerms] = useState<dutyTermsModel[]>([]);
  const [posotionOnBoardItems, setPosotionOnBoardItems] = useState<ItemModel[]>([]);

  useEffect(() => {
    getPositionOnBoardAsync();
  }, []);

  useEffect(() => {
    if (appointmentData && appointmentData.committeeMiddlePrices) {
      setCommittees(appointmentData.committeeMiddlePrices);
    }

    if (appointmentData && appointmentData.dutyCommitteeMiddlePrices) {
      setDutyTerms(appointmentData.dutyCommitteeMiddlePrices);
    }
  }, [appointmentData]);

  const { handleSubmit, handleChange, data: formData, errors, initFormData } = useForm<FormInput>({
    initialValues: {
      committeeTermsOfReferences: [],
      dutyTermsOfReferences: [],
    },
    validations: {},
    onSubmit: async () => {
      if (!committees || committees.length === 0) {
        toast.error('กรุณาเลือกคณะกรรมการกำหนดราคากลาง');
        return;
      }

      if (!dutyTerms || dutyTerms.length === 0) {
        toast.error('กรุณาเลือกอำนาจเจ้าหน้าที่');
        return;
      }

      const appData: AppointmentDetailModel = {
        id: appointmentData.id,
        planId: appointmentData.planId,
        departmentId: appointmentData.departmentId,
        budgetYear: appointmentData.budgetYear,
        planName: appointmentData.planName,
        supplyMethod: appointmentData.supplyMethod,
        supplyMethodType: appointmentData.supplyMethodType,
        supplyMethodSpecialType: appointmentData.supplyMethodSpecialType,
        expectingProcurementAt: appointmentData.expectingProcurementAt!,
        memorandumDate: appointmentData.memorandumDate,
        planBudget: appointmentData.planBudget,
        financialAmount: appointmentData.financialAmount,
        byOrderFrom: appointmentData.byOrderFrom,
        subject: appointmentData.subject,
        status: appointmentData.status,
        createdByUserFullName: '',
        createdInfoAt: new Date(),
        updateByUserFullName: '',
        updatedInfoAt: new Date(),
        appointApprover: appointmentData.appointApprover,
        appointPreProcurementDocuments: appointmentData.appointPreProcurementDocuments,
        committeeTors: appointmentData.committeeTors,
        dutyCommitteeTors: appointmentData.dutyCommitteeTors,
        committeeMiddlePrices: committees,
        dutyCommitteeMiddlePrices: dutyTerms,
        approverAppointPreProcurements: appointmentData.approverAppointPreProcurements,
        documents: appointmentData.documents,
        files: appointmentData.files,
        documentDeletes: appointmentData.documentDeletes,
      };
      setForm(appData);
      onClickNext();
    },
  });

  const addDutyTerms = useCallback(() => {
    const newData: dutyTermsModel = {
      id: undefined,
      duty: '',
    };

    setDutyTerms((dutyTerms) => [...dutyTerms, newData]);
  }, [dutyTerms]);

  const removeDutyTerms = (i: number) => {
    const temp: dutyTermsModel[] = [...dutyTerms];
    temp.splice(i, 1);
    setDutyTerms(temp);
  };

  const handlerOnChange = (event: HTMLInputElement, index: number) => {
    const { value } = event;
    const data = [...dutyTerms];
    data[index].duty = value;
    setDutyTerms(data);
  };

  const onChangePosition = (event: HTMLSelectElement, index: number) => {
    const { value } = event;
    const data = [...committees];
    data[index].positionOnBoardId = value;
    data[index].positionOnBoardName = posotionOnBoardItems.filter((x) => x.value === value)[0].label;
    setCommittees(data);
  };

  const onSelectEmp = (empId: string, name: string, position: string) => {
    if (committees && committees.filter((x) => x.committeeUserId === empId).length > 0) {
      alert('ไม่สามารถเลือกคณะกรรมการซ้ำ');
    } else {
      const newData: committeeModel = {
        id: undefined,
        appointPreProcurementId: appointmentData.id,
        committeeUserId: empId,
        committeeUserName: name,
        positionOnBoardId: posotionOnBoardItems[0].value,
        positionOnBoardName: posotionOnBoardItems[0].label,
        position,
      };

      setCommittees((committees) => [...committees, newData]);
    }
  };

  const removeCommittees = (i: number) => {
    const temp: committeeModel[] = [...committees];
    temp.splice(i, 1);
    setCommittees(temp);
  };

  const getPositionOnBoardAsync = async () => {
    const { data, status } = await shareValue.getPositionOnBoardListAsync();

    if (status === HttpStatusCode.OK) {
      const positionOnBoards: ItemModel[] = data;
      setPosotionOnBoardItems(positionOnBoards);
    }
  };

  return (
    <div className='document'>
      <Form onSubmit={handleSubmit}
        id='updateForm3'>
        <Card className='mt-3'>
          <Card.Body>
            <Title text='คณะกรรมการกำหนดราคากลาง'
              className='fs-5 text-primary' />
            <div className='d-flex justify-content-between my-3'>
              <p>ข้อมูล</p>
              <Button
                variant='outline-primary'
                onClick={() => setShow(true)}
                disabled={isDisabled}
              >
                <FaPlus />เพิ่มข้อมูล
              </Button>
            </div>
            <Table>
              <thead>
              <tr>
                <th style={{ minWidth: 100 }}>ลำดับ</th>
                <th style={{ minWidth: 250 }}>ชื่อ-นามสกุล</th>
                <th style={{ minWidth: 300 }}>ประเภทกรรมการ</th>
                <th style={{ minWidth: 250 }}>ตำแหน่งในคณะกรรมการ</th>
                <th style={{ minWidth: 125 }} />
              </tr>
              </thead>
              <tbody>
              {
                committees.map((data, index) => (
                  <tr key={index}>
                    <td className='text-center'>{index + 1}</td>
                    <td className='text-center'>{data.committeeUserName}</td>
                    <td className='text-center'>{data.position}</td>
                    <td>
                      <Form.Group className='mb-3'>
                        <InputGroup hasValidation>
                          <Form.Select
                            className='dark mt-1'
                            value={data.positionOnBoardId || ''}
                            disabled={isDisabled}
                            onChange={(event) => onChangePosition(event.target as HTMLSelectElement, index)}
                          >
                            <option value=''
                              disabled>เลือก
                            </option>
                            {
                              posotionOnBoardItems.map((item) => (
                                <option key={item.value}
                                  value={item.value}>{item.label}</option>
                              ))
                            }
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                    </td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <Button
                          variant='danger'
                          className='d-flex align-items-center gap-2'
                          onClick={() => removeCommittees(index)}
                          disabled={isDisabled}
                        >
                          <FaTrashAlt />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Card className='mt-3'>
          <Card.Body>
            <Title text='อำนาจหน้าที่'
              className='fs-5 text-primary' />
            <div className='d-flex justify-content-between my-3'>
              <p>ข้อมูล</p>
              <Button
                variant='outline-primary'
                onClick={addDutyTerms}
                disabled={isDisabled}
              >
                <FaPlus />เพิ่มข้อมูล
              </Button>
            </div>
            <Table total={1}>
              <thead>
              <tr>
                <th style={{ minWidth: 20 }}>ลำดับ</th>
                <th style={{ minWidth: 150 }}>รายละเอียด</th>
                <th style={{ minWidth: 25 }} />
              </tr>
              </thead>
              <tbody>
              {
                dutyTerms.map((data, index) => (
                  <tr key={index}>
                    <td className='text-center'>{index + 1}</td>
                    <td className='text-center'>
                      <Form.Group className='mb-3'>
                        <InputGroup>
                          <Form.Control
                            type='text'
                            value={data.duty || ''}
                            disabled={isDisabled}
                            onChange={(event) => handlerOnChange(event.target as HTMLInputElement, index)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <Button
                          variant='danger'
                          className='d-flex align-items-center gap-2'
                          onClick={() => removeDutyTerms(index)}
                          disabled={isDisabled}
                        >
                          <FaTrashAlt />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <div className='d-flex justify-content-between pt-3'>
          <Button
            onClick={onClickBack}
            className='me-2 px-3'
            variant='outline-primary'
          >
            <MdArrowBack className='me-2 pb-1 fs-5' />
            ย้อนกลับ
          </Button>
          <Button
            type='submit'
            form='updateForm3'
            className='me-2 px-3'
          >
            ถัดไป
            <MdArrowForward className='ms-2 pb-1 fs-5' />
          </Button>
        </div>
      </Form>
      <UserAccountModal
        show={show}
        onHide={() => setShow(!show)}
        onSelectItem={onSelectEmp}
      />
    </div>
  );
}

export default Step3;
