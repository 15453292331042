import { Rule } from 'models';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  label?: string;
  type?: string;
  placeholder?: string;
  rule?: Rule;
  value?: string | undefined;
  onChange?: (value: string) => void;
  name?: string;
  className?: string;
  rows?: number;
  disabled?: boolean;
}

export function InputTextArea(props: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [value, setValue] = useState<string | undefined>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  document.addEventListener('onFormSubmit', (data) => {
    const event = data as CustomEvent;

    if (event.detail.hasNameOnly) {
      if (props.name) {
        validate(value);
      }
    } else {
      validate(value);
    }
  });

  document.addEventListener('onClearError', () => {
    setErrorMessage('');
  });

  const getRequired = () => {
    if (props.rule?.required) {
      return <span className="text-danger">*</span>;
    }

    return null;
  };

  const getType = () => {
    if (props.type) {
      return props.type;
    }

    return 'text';
  };

  const validate = (value: string | undefined) => {
    if (!checkRequired(value)) {
      return;
    }

    if (!checkMinLength(value)) {
      return;
    }

    if (!checkMaxLength(value)) {
      return;
    }

    if (!checkMinValue(value)) {
      return;
    }

    if (!checkMaxValue(value)) {
      return;
    }

    setErrorMessage('');
  };

  const checkRequired = (value: string | undefined) => {
    if (props.rule?.required && !value) {
      setErrorMessage('กรุณากรอกข้อมูล');

      return false;
    }

    return true;
  };

  const checkMinLength = (value: string | undefined) => {
    if (props.rule?.minLength && value && value.length < props.rule.minLength) {
      setErrorMessage(`กรุณระบุอย่างน้อย ${props.rule.minLength} ตัวอักษร`);

      return false;
    }

    return true;
  };

  const checkMaxLength = (value: string | undefined) => {
    if (props.rule?.maxLength && value && value.length > props.rule.maxLength) {
      setErrorMessage(`กรุณระบุไม่เกิน ${props.rule.minLength} ตัวอักษร`);

      return false;
    }

    return true;
  };

  const checkMinValue = (value: string | undefined) => {
    if (props.rule?.minValue && Number(value) < props.rule.minValue) {
      setErrorMessage(`กรุณระบุอย่างน้อย ${props.rule.minLength}`);

      return false;
    }

    return true;
  };

  const checkMaxValue = (value: string | undefined) => {
    if (props.rule?.maxValue && Number(value) > props.rule.maxValue) {
      setErrorMessage(`กรุณระบุไม่เกิน ${props.rule.minLength}`);

      return false;
    }

    return true;
  };

  const getErrorMessage = useMemo(() => {
    if (errorMessage) {
      return <Form.Text className="text-danger">{errorMessage}</Form.Text>;
    }

    return null;
  }, [errorMessage]);

  const handlerOnChange = (event: HTMLInputElement) => {
    if (props.onChange) {
      props.onChange(event.value);
    }

    setValue(event.value);

    validate(event.value.trim());
  };

  return (
    <Form.Group className={`w-100 ${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
      {props.label ? <Form.Label>{props.label} {getRequired()}</Form.Label> : null}
      <Form.Control
        as="textarea"
        rows={props.rows ?? 3}
        className={`${getErrorMessage ? 'is-invalid' : ''}`}
        value={value ?? ''}
        type={getType()}
        placeholder={props.placeholder}
        onChange={(event) => handlerOnChange(event.target as HTMLInputElement)}
        disabled={props.disabled}
      />
      {getErrorMessage}
    </Form.Group>
  );
}
