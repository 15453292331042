import { useEffect, useMemo, useState } from 'react';
import { MdCircle } from 'react-icons/md';

interface Props {
  systemStatue: string;
  outline?: boolean;
  text?: string;
  systemName: string;
}

const STATUS_CM03 = [
  {
    systemStatue: 'WaitingForApprove',
    color: '#FBBD01',
    label: 'รอเห็นชอบ',
  }, {
    systemStatue: 'WaitForApprove',
    color: '#FBBD01',
    label: 'รอเห็นชอบ',
  },
  {
    systemStatue: 'Pending',
    color: '#FBBD01',
    label: 'รอเห็นชอบ',
  },
  {
    systemStatue: 'WaitForInspectorReceived',
    color: '#FBBD01',
    label: 'รอเห็นชอบ',
  },
  {
    systemStatue: 'Invited',
    color: '#1BA345',
    label: 'เห็นชอบ/อนุมัติ',
  },
  {
    systemStatue: 'Approve',
    color: '#1BA345',
    label: 'เห็นชอบ/อนุมัติ',
  },
  {
    systemStatue: 'Approved',
    color: '#1BA345',
    label: 'เห็นชอบ/อนุมัติ',
  },
  {
    systemStatue: 'Reject',
    color: '#DE3E44',
    label: 'ส่งกลับแก้ไข',
  },
  {
    systemStatue: 'Rejected',
    color: '#DE3E44',
    label: 'ส่งกลับแก้ไข',
  },
  {
    systemStatue: 'InspectorRejected',
    color: '#DE3E44',
    label: 'ส่งกลับแก้ไข',
  },
  {
    systemStatue: 'JorPorRejected',
    color: '#DE3E44',
    label: 'ส่งกลับแก้ไข',
  },
  {
    systemStatue: 'Warning',
    color: '#FBBD01',
    label: 'ส่งมอบ',
  },
  {
    systemStatue: 'Draft',
    color: '#BEBEBE',
    label: 'ร่างสัญญา',
  },
  {
    systemStatue: 'Approved01',
    color: '#1BA345',
    label: 'ตรวจรับ',
  },
  {
    systemStatue: 'Receive',
    color: '#1BA345',
    label: 'ตรวจรับ',
  },
  {
    systemStatue: 'Paid',
    color: '#1BA345',
    label: 'เบิกจ่ายแล้ว',
  },
  {
    systemStatue: 'None',
    color: '#BEBEBE',
    label: 'ยังไม่ได้ดำเนินการ',
  },
  {
    systemStatue: 'ContractAgreement',
    color: '#BEBEBE',
    label: 'ร่างสัญญา',
  },
  {
    systemStatue: 'ContractManagement',
    color: '#1BA345',
    label: 'บริหารสัญญา',
  },
  {
    systemStatue: 'ContractWarranty',
    color: '#1BA345',
    label: 'รับประกันสัญญา',
  },
  {
    systemStatue: 'ContractCompleted',
    color: '#1BA345',
    label: 'สิ้นสุดสัญญา',
  },
  {
    systemStatue: 'ContractCancelled',
    color: '#1BA345',
    label: 'ยกเลิกสัญญา',
  },
  {
    systemStatue: 'Cancel',
    color: '#1BA345',
    label: 'ยกเลิกสัญญา',
  },
  {
    systemStatue: 'InspectorReceived',
    color: '#1BA345',
    label: 'รับทราบ',
  },
  {
    systemStatue: 'WaitForJorPorApprove',
    color: '#FBBD01',
    label: 'รอเจ้าหน้าที่พัสดุให้ความเห็น',
  },
  {
    systemStatue: 'JorPorApproved',
    color: '#1BA345',
    label: 'เจ้าหน้าที่พัสดุให้ความเห็น',
  },
  {
    systemStatue: 'Delivered',
    color: '#1BA345',
    label: 'ส่งมอบแล้ว',
  },
];

const STATUS_CM04 = [
  {
    systemStatue: 'Draft',
    color: '#BEBEBE',
    label: 'แบบร่าง',
  },
  {
    systemStatue: 'Approved',
    color: '#1BA345',
    label: 'เห็นชอบ/อนุมัติ',
  },
  {
    systemStatue: 'Rejected',
    color: '#DE3E44',
    label: 'ส่งกลับแก้ไข',
  },
  {
    systemStatue: 'Pending',
    color: '#FBBD01',
    label: 'รอเห็นชอบ/อนุมัติ',
  },

];

export function CMDetailStatus(props: Props) {
  const [status, setStatus] = useState<{ systemStatue: string, color: string, label: string }>();

  useEffect(() => {
    if (props.systemStatue && props.systemName === 'cm-03') {
      const newStatus = STATUS_CM03.find((s) => s.systemStatue === props.systemStatue);

      if (newStatus) {
        setStatus(newStatus);
      }
    }
    if (props.systemStatue && props.systemName === 'cm-04') {
      const newStatus = STATUS_CM04.find((s) => s.systemStatue === props.systemStatue);

      if (newStatus) {
        setStatus(newStatus);
      }
    }
  }, [props.systemStatue]);
  return (
    <>
      {props.systemStatue
        ? (
          <div className='directive-status'
            style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

function Gettext(system: string, systemStatue: string) {
  switch (system) {
    case 'cm-03':
      if (systemStatue === 'Pending'
        || systemStatue === 'WaitingForApprove'
        || systemStatue === 'WaitForInspectorReceived'
        || systemStatue === 'WaitForApprove') {
        return 'รอเห็นชอบ';
      }
      if (systemStatue === 'Invited' || systemStatue === 'Approve' || systemStatue === 'Approved') {
        return 'เห็นชอบ/อนุมัติ';
      }
      if (systemStatue === 'Reject' || systemStatue === 'Rejected' || systemStatue === 'InspectorRejected' || systemStatue === 'JorPorRejected') {
        return 'ส่งกลับแก้ไข';
      }
      if (systemStatue === 'Warning') {
        return 'ส่งมอบ';
      }
      if (systemStatue === 'Draft') {
        return 'ร่างสัญญา';
      }
      if (systemStatue === 'Approved01') {
        return 'ตรวจรับ';
      }
      if (systemStatue === 'Receive') {
        return 'ตรวจรับ';
      }
      if (systemStatue === 'Paid') {
        return 'เบิกจ่ายแล้ว';
      }
      if (systemStatue === 'None') {
        return 'ยังไม่ได้ดำเนินการ';
      }
      if (systemStatue === 'ContractAgreement') {
        return 'ร่างสัญญา';
      }
      if (systemStatue === 'ContractManagement') {
        return 'บริหารสัญญา';
      }
      if (systemStatue === 'ContractWarranty') {
        return 'รับประกันสัญญา';
      }
      if (systemStatue === 'ContractCompleted') {
        return 'สิ้นสุดสัญญา';
      }
      if (systemStatue === 'ContractCancelled'
        || systemStatue === 'Cancel') {
        return 'ยกเลิกสัญญา';
      } if (systemStatue === 'InspectorReceived') {
        return 'รับทราบ';
      }
      if (systemStatue === 'WaitForJorPorApprove') {
        return 'รอเจ้าหน้าที่พัสดุให้ความเห็น';
      }
      if (systemStatue === 'JorPorApproved') {
        return 'เจ้าหน้าที่พัสดุให้ความเห็น';
      }
      if (systemStatue === 'Delivered') {
        return 'ส่งมอบแล้ว';
      }
      break;
    case 'cm-04':
      if (systemStatue === 'Pending') {
        return 'รอเห็นชอบ/อนุมัติ';
      }
      if (systemStatue === 'Draft') {
        return 'แบบร่าง';
      }
      if (systemStatue === 'Approved') {
        return 'เห็นชอบ/อนุมัติ';
      }
      if (systemStatue === 'Rejected') {
        return 'ส่งกลับแก้ไข';
      }
      break;
    default:
      return 'รอเห็นชอบ';
  }
}

export function StatusCM({
  systemStatue, outline, text, systemName,
}: Props) {
  const status = useMemo(() => {
    if (systemStatue === 'Pending') {
      return {
        color: 'bg-warning bg-opacity-10 text-warning rounded',
      };
    }
    if (systemStatue === 'Paid'
      || systemStatue === 'Receive'
      || systemStatue === 'Approve'
      || systemStatue === 'Approved'
      || systemStatue === 'Approved01'
      || systemStatue === 'Invited'
      || systemStatue === 'InspectorReceived'
      || systemStatue === 'JorPorApproved') {
      return {
        color: 'bg-success text-success',
      };
    }
    if (systemStatue === 'Reject'
      || systemStatue === 'Rejected'
      || systemStatue === 'InspectorRejected'
      || systemStatue === 'JorPorRejected'
      || systemStatue === 'Cancel') {
      return {
        color: 'bg-danger text-danger',
      };
    }
    if (systemStatue === 'Warning'
      || systemStatue === 'WaitForInspectorReceived'
      || systemStatue === 'WaitForJorPorApprove'
      || systemStatue === 'WaitForApprove'
      || systemStatue === 'Delivered') {
      return {
        color: 'bg-yellow text-yellow',
      };
    }
    if (systemStatue === 'Draft'
      || systemStatue === 'ContractAgreement') {
      return {
        color: 'bg-dark text-muted',
      };
    }
    if (systemStatue === 'WaitingForApprove') {
      return {
        color: 'bg-yellow text-yellow',
      };
    }
    return { color: 'bg-dark text-muted' };
  }, [systemStatue]);

  return (
    <>
      {status
        ? <div className={`d-flex align-items-center px-3 py-1 bg-opacity-10 rounded-pill ${status.color}`}> <MdCircle className='me-2 circle' /> {systemName ? Gettext(systemName, systemStatue) : 'text noting'}</div> : null}
    </>
  );
}