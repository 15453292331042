import { Card, Input, Table } from 'components';
import Title from 'components/Controls/Title';
import { TorImpedimentModel } from 'models/PP/TorModels';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Row,
} from 'react-bootstrap';
import { FaChevronCircleDown, FaChevronCircleUp, FaPlus, FaTrashAlt } from 'react-icons/fa';
import { ValidatePattern } from 'utils';
import { Context } from '../../step';
import { InputNumber } from 'components/Controls/InputNumber';

interface IImpedimentProps {
  torId: string;
  impediments: TorImpedimentModel[];
  onImpedimentsChange: (value: TorImpedimentModel[]) => void;
  isDisabled?: boolean;
}

export function Impediment(props: IImpedimentProps) {
  const { readonly } = useContext(Context);
  const [impediments, setImpediments] = useState<TorImpedimentModel[]>(props.impediments);
  const [firstLoad, setFirstLoad] = useState(true);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    props.onImpedimentsChange(impediments);
  }, [impediments]);

  useEffect(() => {
    if (props.impediments && firstLoad) {
      setImpediments(props.impediments);
      setFirstLoad(false);
    }
  }, [props.impediments, firstLoad]);

  const addImpediment = useCallback(async () => {
    const newData: TorImpedimentModel = {
      id: undefined,
      termOfRefId: props.torId,
      description: '',
      impedimentValue: 0,
      sequence: impediments.length + 1,
    };

    setImpediments((impediments) => [...impediments, newData]);
  }, [impediments]);

  const handlerOnChangeImpedimentDesc = (value: string, index: number) => {
    const data = [...impediments];
    data[index].description = value;
    setImpediments(data);
  };

  const handlerOnChangeimpedimentValue = (value: number, index: number) => {
    const data = [...impediments];
    data[index].impedimentValue = value;
    setImpediments(data);
  };

  const removeImpediment = (i: number) => {
    const temp: TorImpedimentModel[] = [...impediments];
    temp.splice(i, 1);
    setImpediments(temp);
  };

  return (
    <Card className='mt-3'>
      <div className='d-flex align-items-center'>
        <Title
          text='การกำหนดตัวถ่วง (IT)'
          className='fs-5 text-primary'
        />
        {
          collapsed
            ? (
              <FaChevronCircleDown
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(false)}
              />
            ) : (
              <FaChevronCircleUp
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(true)}
              />
            )
        }
      </div>
      <Collapse in={!collapsed} className='ps-40'>
        <div>
          <div className='d-flex align-items-center justify-content-between'>
            <p className='mb-0'>รายการตัวถ่วง</p>
            <Button
              variant='outline-primary'
              onClick={addImpediment}
              disabled={props.isDisabled || readonly}
            >
              <FaPlus className='me-1' />เพิ่มรายการ
            </Button>
          </div>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}>
              <Table total={1}
                hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 20 }}>ลำดับ</th>
                    <th style={{ width: 200 }}>รายการ</th>
                    <th style={{ width: 200 }}>ค่าตัวถ่วง</th>
                    <th style={{ width: 20 }} />
                  </tr>
                </thead>
                <tbody>
                  {impediments?.sort((a, b) => a.sequence - b.sequence)
                    .map((data, index) => (
                      <tr key={index}>
                        <td className='text-center'>{index + 1}</td>
                        <td>
                          <Input
                            value={data.description}
                            onChange={(val) => handlerOnChangeImpedimentDesc(val, index)}
                            rule={{ required: true }}
                            disabled={props.isDisabled || readonly}
                          />
                        </td>
                        <td>
                          <InputNumber
                            value={data.impedimentValue}
                            onChange={(val) => handlerOnChangeimpedimentValue(Number(val), index)}
                            rule={{ required: true, pattern: ValidatePattern.NUMBER }}
                            disabled={props.isDisabled || readonly}
                          />
                        </td>
                        <td className='text-end'>
                          <Button
                            onClick={() => removeImpediment(index)}
                            variant='danger'
                            disabled={props.isDisabled || readonly}
                          >
                            <FaTrashAlt />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card>
  );
}
