import {
  ApproverStatus,
  Card,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { HistoryModal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { FaPaperPlane, FaRegCheckCircle, FaUndo } from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
  MdHistory,
  MdSegment,
} from 'react-icons/md';
import Collabora from '../../../../components/Document/Collabora';
import { dataHistory } from '../data';
import { ApproveModal } from '../Modal/ApproveModal';
import { EditModal } from '../Modal/EditModal';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [docId, setDocId] = useState('');
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [approveReason, setApproveReason] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <Row>
          <Title text="สถานะการเห็นชอบ/อนุมัติ" className="fs-5 text-primary" />
          <Col sm={12}>
            <Table total={2}>
              <thead>
                <tr>
                  <th style={{ minWidth: 10 }}>ลำดับ</th>
                  <th style={{ minWidth: 50 }}>ชื่อ-นามสกุล</th>
                  <th style={{ minWidth: 50 }}>ตำแหน่ง</th>
                  <th style={{ minWidth: 50 }}>สถานะ</th>
                  <th style={{ minWidth: 50 }}>วันที่เห็นชอบ/อนุมัติา</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 3 }).map((_, index) => (
                  <tr className="text-center" key={index}>
                    <td>{index + 1}</td>
                    <td className="text-start">สมศรี แดนไทย</td>
                    <td className="text-start">หัวหน้าส่วนบริหารสัญญา</td>
                    <td><ApproverStatus value='Approve' /></td>
                    <td><p className='text-primary mb-0'>28-05-2566 </p> <p className='text-muted mb-0'>16:24:22</p></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title
          text='ตัวอย่างเอกสารเชิญชวนมาทำสัญญา'
          className='fs-5 text-primary'
          extraElement={(
            <Button variant="light" onClick={() => { setShowHistoryModal(true); }}>
              <MdHistory /> ประวัติการแก้ไข
            </Button>
          )}
        />
        <div className='mt-3'>
          <Collabora
            docId={docId}
            docName='doc-01'
            readonly={false} />
        </div>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <div>
          <Button
            onClick={() => setShowEditModal(true)}
            variant='danger'
          >
            <FaUndo />ส่งกลับแก้ไข
          </Button>
          <Button
            onClick={() => setApproveReason(true)}
            className="me-2 px-3 ms-2"
          >
            <FaRegCheckCircle className='me-2 pb-1 fs-5' />
            เห็นชอบ
          </Button>
          <Button
            onClick={() => setApproveReason(true)}
            className="me-2 px-3 ms-2"
            variant='success'
          >
            <FaPaperPlane className='me-2 pb-1 fs-5' />
            อนุมัติและส่งอีเมล
          </Button>
        </div>

        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        data={dataHistory}
        title="ประวัติ"
      />
      <EditModal
        show={showEditModal}
        onHide={() => setShowEditModal(!showEditModal)}
      />
      <ApproveModal
        show={approveReason}
        click={() => setApproveReason(!approveReason)}
      />
    </div>
  );
}

export default Step2;
