import { ContractReceiveCollateralModel } from 'models/CM/CM06Model';
import {
  createContext,
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import CM06Service from 'services/CM/CM06Service';
import { HttpStatusCode } from 'utils';
import toast from 'utils/toast';
import Step1 from './step1';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type ContractContext = {
  data: ContractReceiveCollateralModel
  setData: Dispatch<React.SetStateAction<ContractReceiveCollateralModel>>,
};

export const Context = createContext({} as ContractContext);

function CreateContractMangementDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { id } = useParams();
  const [data, setData] = useState<ContractReceiveCollateralModel>({} as ContractReceiveCollateralModel);
  const [defaultStep, setDefaultStep] = useState(1);

  useEffect(() => {
    if (id) {
      getSaveReturnCollateralAsync(id);
    }
  }, [id]);

  const getSaveReturnCollateralAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.geContractReceiveCollateralByIdAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    setData(data);
    // setStepByStatus(data.status);
  }, []);

  // const obj = [{
  //   title: <p>บันทึกผลการพิจารณาคืนหลักประกันสัญญา</p>,
  //   displayIndex: '1',
  //   child: <Step1 onClickNext={() => clickNext()} onClickBack={() => backToDetail()} collateralId={collateralId!} />,
  // },
  // {
  //   title: <p> คณะกรรมการตรวจรับเห็นชอบ </p>,
  //   displayIndex: '2',
  //   child: <Step2 onClickNext={() => clickNext()} onClickBack={() => clickBack()} collateralId={collateralId!} />,
  // },
  // {
  //   title: <p>ผอ.จพ.มอบหมาย</p>,
  //   displayIndex: '3',
  //   child: <Step3 onClickNext={() => clickNext()} onClickBack={() => clickBack()} collateralId={collateralId!} />,
  // },
  // ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/CM06');
  };

  const backToDetail = () => {
    navigate(`/cm/cm06/detail/${data.contractReturnCollateralId}`);
  };

  return (
    <div className="document">
      <Row>
        <Col xs={12} className="mb-2">
          <Context.Provider value={{ data, setData }}>
            {/* <StepProgress progressBarData={obj} ref={childRef} /> */}
            <Step1 onClickNext={() => clickNext()} onClickBack={() => backToDetail()} contractReturnCollateralId={data.contractReturnCollateralId!} />
          </Context.Provider> <></>
        </Col>
      </Row>
    </div>
  );
}

export default CreateContractMangementDetail;
