import {
  DepartmentResponse,
  ItemModel,
} from 'models';
import { LoaderFunctionArgs } from 'react-router';
import {
  department,
  shareValue,
} from 'services';
import { SharedvalueId, SubGroup } from 'utils/constants/ContractEnum';

const PurchaseOrderApprovalLoder = async ({ params }: LoaderFunctionArgs) => {
  const departmentItems = await GetDDL('Department');
  const supplyMethodItems = await GetDDL('SupplyMethod');
  const supplyMethodTypeItems = await GetDDL('SupplyMethodType');
  const entrepreneurTypeItems = await GetDDL('EntrepreneurType');
  const budgetYearItems = await GetDDL('BudgetYear');
  const ContractType = await GetDDL('ContractType');
  const receivceCommitteeItems = await GetDDL('ReceivceCommittee');
  const positionOnBoardInSpection = await GetDDL('PositionOnBoard', SubGroup.PositionOnBoardInSpection);
  const positionOnBoardProcurement = await GetDDL('PositionOnBoard', SubGroup.PositionOnBoardProcurement);
  const positionOnBoardMA = await GetDDL('PositionOnBoard', SubGroup.PositionOnBoardMA);
  const positionOnBoardSupervisor = await GetDDL('PositionOnBoard', SubGroup.PositionOnBoardSupervisor);
  const jorPorDirectorPosition = await shareValue.getSingleSharedValueAsync(SharedvalueId.jorPorDirectorPositionId);

  return {
    departmentDDL: departmentItems,
    supplyMethodDDL: supplyMethodItems,
    supplyMethodTypeDDL: supplyMethodTypeItems,
    entrepreneurTypeDDL: entrepreneurTypeItems,
    budgetYearDDL: budgetYearItems,
    contractTypeDDL: ContractType,
    receivceCommitteeTypeDDL: receivceCommitteeItems,
    positionOnBoardInSpectionDDL: positionOnBoardInSpection,
    positionOnBoardSupervisorDDL: positionOnBoardSupervisor,
    positionOnBoardMADDL: positionOnBoardMA,
    positionOnBoardProcurementDDL: positionOnBoardProcurement,
    jorPorDirectorPosition: jorPorDirectorPosition,
  };
};

const GetDDL = async (Type: string, SubGroup?: string) => {
  const itemForMap: ItemModel[] = [];

  switch (Type) {
    case 'Department': {
      const response = await department.getDepartmentsAsync();
      const departmentData: DepartmentResponse[] = response.data;
      departmentData.forEach((data) => itemForMap.push({
        label: data.name,
        value: data.id ?? '',
      }));
      break;
    }
    case 'SupplyMethod': {
      const response = await shareValue.getSupplyMethodListAsync();
      const supplyMethodData: ItemModel[] = response.data;
      supplyMethodData.forEach((data) => itemForMap.push({
        label: data.label,
        value: data.value ?? '',
      }));
      break;
    }
    case 'SupplyMethodType': {
      const response = await shareValue.getSupplyMethodTypeListAsync();
      const supplyMethodTypeData: ItemModel[] = response.data;
      supplyMethodTypeData.forEach((data) => itemForMap.push({
        label: data.label,
        value: data.value ?? '',
      }));
      break;
    }
    case 'EntrepreneurType': {
      const response = await shareValue.GetTraderTypeAsync();
      const entrepreneurTypeData: ItemModel[] = response.data;
      entrepreneurTypeData.forEach((data) => itemForMap.push({
        label: data.label,
        value: data.value ?? '',
      }));
      break;
    }
    case 'BudgetYear': {
      const date = new Date();
      let year = (date.getUTCFullYear() + 543) - 10;

      const items = Array.from({ length: 20 })
        .map((i) => {
          year += 1;

          return {
            label: year.toString(),
            value: year.toString(),
          };
        });

      items.forEach((data) => itemForMap.push({
        label: data.label,
        value: data.value ?? '',
      }));

      break;
    }
    case 'ContractType': {
      const response = await shareValue.getContractTypeListAsync();
      const contractTypeData: ItemModel[] = response.data;
      contractTypeData.forEach((data) => itemForMap.push({
        label: data.label,
        value: data.value ?? '',
      }));
      break;
    }
    case 'PositionOnBoard': {
      const response = await shareValue.getPositionOnBoardListAsync('', SubGroup);
      const positionOnBoardData: ItemModel[] = response.data;
      positionOnBoardData.forEach((data) => itemForMap.push({
        label: data.label,
        value: data.value ?? '',
      }));
      break;
    }
    default: {
      const response = await shareValue.getListSharedValueAsync(Type);
      const dataList: ItemModel[] = response.data;
      dataList.forEach((data) =>
        itemForMap.push({
          label: data.label,
          value: data.value ?? ''
        })
      );
      break;
    }
  }

  return itemForMap;
};

export { PurchaseOrderApprovalLoder };
