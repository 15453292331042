import {
  Card,
  InputTextArea,
  Modal,
  Radio,
} from 'components';
import Title from 'components/Controls/Title';
import { TraderModalTypeConstant } from 'constant/ProcumentInvitationStatus';
import {
  IFile,
  ItemModel,
} from 'models';
import {
  AttachmentModel,
  EntrepreneurModel,
} from 'models/CM/CM02Models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaSave } from 'react-icons/fa';
import { shareValue, trader } from 'services';
import CM02Service from 'services/CM/CM02Service';
import { HttpStatusCode } from 'utils';
import toast from 'utils/toast';
import UploadFile from './UploadFile';
import { WebLinkIdEnum } from 'utils/constants/ShareValueEnum';

interface Props {
  show: boolean;
  modalType: TraderModalTypeConstant;
  onHide?: () => void;
  title?: string;
  traderModal: EntrepreneurModel;
  entrepreneurTypeDDL: ItemModel[];
  disabled?: boolean;
  isResponsible?: boolean;
  contractId: string;
  onSubmit: (traderId: string, modalType: string, remark: string, isAccept?: boolean, files?: IFile[]) => void;
}

const coiUrl = process.env.REACT_APP_COI_URL;
const watchlistUrl = process.env.REACT_APP_WATCH_LIST_URL;

export function CoiModalStep4(props: Props) {
  const [isAccept, setIsAccept] = useState<boolean | undefined>(undefined);
  const [remark, setRemark] = useState<string | undefined>(undefined);
  const [traderData, setTraderData] = useState<EntrepreneurModel>({} as EntrepreneurModel);
  const [entrepreneurTypes, setEntrepreneurTypes] = useState<ItemModel[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [webLink, setWebLink] = useState<string | undefined>(undefined);

  const acceptItems = [
    {
      label: 'ผ่าน',
      value: 'true',
    },
    {
      label: 'ไม่ผ่าน',
      value: 'false',
    },
  ];

  const nationalityLists = [
    {
      nationality: 'th',
      value: 'ไทย',
    },
    {
      nationality: 'en',
      value: 'อังกฤษ',
    },
  ];

  const ENTREPRENEURTYPE = [
    {
      label: 'นิติบุคคล',
      value: 'JuristicPerson',
    },
    {
      label: 'บุคคลธรรมดา',
      value: 'Individual',
    },
  ];

  useEffect(() => {
    if (props.traderModal) {
      // getTraderByIdAsynce(props.traderModal.traderId);
      setTraderData(props.traderModal);
      handleSetFile([]);
      if (props.modalType === TraderModalTypeConstant.COI) {
        setIsAccept((props.traderModal.coiResult !== undefined && props.traderModal.coiResult !== null) ? props.traderModal.coiResult : undefined);
        setRemark(props.traderModal.coiRemark);

        getDocumentByIdAsync(props.modalType);
        getParameterWebLink(WebLinkIdEnum.COI);
      } else if (props.modalType === TraderModalTypeConstant.WATCHLIST) {
        setIsAccept((props.traderModal.watchListResult !== undefined && props.traderModal.watchListResult !== null) ? props.traderModal.watchListResult : undefined);
        setRemark(props.traderModal.watchListRemark);

        getDocumentByIdAsync(props.modalType);
        getParameterWebLink(WebLinkIdEnum.Watchlist);
      } else {
        setIsAccept((props.traderModal.egpResult !== undefined && props.traderModal.egpResult !== null) ? props.traderModal.egpResult : undefined);
        setRemark(props.traderModal.egpRemark);

        getDocumentByIdAsync(props.modalType);
        getParameterWebLink(WebLinkIdEnum.EGP);
      }
    }

    if (props.entrepreneurTypeDDL) {
      setEntrepreneurTypes(props.entrepreneurTypeDDL);
    }
  }, [props.traderModal, props.entrepreneurTypeDDL, props.show]);

  const getTraderByIdAsynce = async (id: string) => {
    const response = await trader.getTraderAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setTraderData(response.data);
    }
  };

  const getParameterWebLink = async (id: string) => {
    const { data, status } = await shareValue.getSingleSharedValueAsync(id);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setWebLink(data.sharedValue.value);
  }

  const getDocumentByIdAsync = async (documentType: string) => {

    const response = await CM02Service.getDocumentByTypeAsync(props.contractId, documentType);

    if (response.status === HttpStatusCode.OK) {
      const attachments = response.data.attachments as AttachmentModel[];
      handleSetDocFile(attachments);
    }
  };

  const onSave = () => {
    if (isAccept !== undefined && !isAccept && (!remark || remark?.length === 0)) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }
    props.onSubmit!(props.traderModal.traderId, props.modalType, remark!, isAccept, files);
    setIsAccept(undefined);
    setRemark(undefined);
    setFiles([]);
    props.onHide!();
  };

  const onCancel = () => {
    setIsAccept(undefined);
    setRemark(undefined);
    setFiles([]);
    props.onHide!();
  };

  const onChangeAccept = (val: string) => {
    setIsAccept(val === 'true');
  };

  const handleSetFile = (documents: AttachmentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id!,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleSetDocFile = (documents: AttachmentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id!,
        name: document.fileName,
        file: null,
      };
      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    const newUpdFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };
      setFiles((pre) => [...pre, newFile]);
      newUpdFiles.push(file);
    }

    if (newUpdFiles && newUpdFiles.length > 0 && props.contractId) {
      const res = await CM02Service.uploadAttachmentEntrepreneurTypeAsync(props.contractId, props.modalType, newUpdFiles);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('อัปโหลดเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('อัปโหลดเอกสารแนบสำเร็จ');
      await getDocumentByIdAsync(props.modalType);
    }
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    const delId = newArray.filter((i) => i.name === docName)[0].id;
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName && delId && props.contractId) {
      const res = await CM02Service.removeFileAsync(props.contractId, delId);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('ลบเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('ลบเอกสารแนบสำเร็จ');
      await getDocumentByIdAsync(props.modalType);
    }
  }, [files, props.contractId]);

  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size='xl'
    >
      <Modal.Header>
        <Title
          text={props.title}
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <ModalBT.Body className='p-0 ps-4'>
        {
          (traderData && entrepreneurTypes) && (
            <Card className='mt-4 mb-4'>
              <Title
                text='ตรวจสอบคู่ค้า'
                className='text-primary'
              />
              <Row className='mt-4'>
                <Col sm={4}>
                  <span>สัญชาติของผู้ประกอบการ : {nationalityLists.filter(n => n.nationality === traderData.nationality).length > 0 ? nationalityLists.filter(n => n.nationality === traderData.nationality)[0].value : traderData.nationality}</span>
                </Col>
                <Col sm={4}>
                  <span>ประเภท : {ENTREPRENEURTYPE.filter(x => x.value === traderData.traderType).length > 0 ? ENTREPRENEURTYPE.filter(x => x.value === traderData.traderType)[0].label : ''}</span>
                </Col>
              </Row>
              <Row className='mt-4'>
                <Col sm={4}>
                  <span>เลขประจำตัวผู้เสียภาษี : {traderData.taxId}</span>
                </Col>
                <Col sm={4}>
                  <span>ประเภทของผู้ประกอบการ : {entrepreneurTypes.filter(x => x.label === traderData.type).length > 0 ? entrepreneurTypes.filter(x => x.label === traderData.type)[0].label : ''}</span>
                </Col>
                <Col sm={4}>
                  <span>ชื่อสถานประกอบการ : {traderData.name}</span>
                </Col>
              </Row>
              <Row className='mt-4'>
                <Col sm={4}>
                  <span>หมายเลขโทรศัพท์สำหรับติดต่อ : {traderData.tel}</span>
                </Col>
                <Col sm={4}>
                  <span>อีเมล : {traderData.email}</span>
                </Col>
              </Row>
            </Card>
          )
        }
        <Card className='mt-4 mb-4'>
          <Title
            text={`ข้อมูล ${props.title}`}
            className='text-primary'
          />
          <Row className='mt-4'>
            <Col sm={12}>
              <a href={webLink}
                target='_blank'
                className='btn-link'
                rel='noopener noreferrer'>
                {webLink}
              </a>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col sm={12}>
              {
                isAccept !== undefined ? (
                  <Radio
                    inline
                    dflex
                    items={acceptItems}
                    name='accept'
                    rule={{ required: true }}
                    value={isAccept.toString()}
                    onChange={(val) => (onChangeAccept(val.toString()))}
                    disabled={props.disabled}
                  />
                ) : (
                  <Radio
                    inline
                    dflex
                    items={acceptItems}
                    name='accept'
                    rule={{ required: true }}
                    value={undefined}
                    onChange={(val) => (onChangeAccept(val.toString()))}
                    disabled={props.disabled}

                  />
                )
              }
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col sm={12}>
              <InputTextArea
                label='หมายเหตุ'
                placeholder='หมายเหตุ'
                value={remark}
                onChange={val => setRemark(val)}
                rule={{ required: isAccept ? !isAccept : false }}
                disabled={props.disabled} />
            </Col>
          </Row>
        </Card>
        <Card className='mt-4 mb-4'>
          <Title text='เอกสารแนบ'
            className='fs-5 text-primary' />
          <Row className='justify-content-center'>
            <Col sm={12}
              lg={6}>
              <UploadFile
                files={files}
                handleFileChange={handleFileChange}
                removeItem={removeItemAsync}
                inviteEntreprenurId={props.traderModal.id!}
              />
            </Col>
          </Row>
        </Card>
      </ModalBT.Body>
      <ModalBT.Footer>
        {props.isResponsible && (
          <>
            <Button
              variant='outline-primary'
              onClick={onCancel}
              className='me-2 px-3'
            >
              ยกเลิก
            </Button>
            <Button
              className='me-2 px-3'
              variant='primary'
              onClick={() => onSave()}
              disabled={props.disabled}
            >
              <FaSave className='mx-2 pb-1 fs-4' />
              บันทึก
            </Button>
          </>
        )}
      </ModalBT.Footer>
    </Modal>
  );
}
