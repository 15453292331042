import { Table } from 'components';
import Title from 'components/Controls/Title';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Card,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

interface BoardProps {
  order: number;
  code: string;
  fullName: string;
  boardType: string;
  boardPosition: string;
}

const boardsData: BoardProps[] = [
  {
    order: 1,
    code: '5900010',
    fullName: 'สมชาย ใจดี',
    boardType: 'ส่วนวางแผนและผลิตสื่อโฆษณาฝ่ายสื่อสารองค์กร',
    boardPosition: 'ประธานกรรมการ',
  },
  {
    order: 2,
    code: '5900011',
    fullName: 'สมศรี ใจงาม',
    boardType: 'ส่วนวางแผนและผลิตสื่อโฆษณาฝ่ายสื่อสารองค์กร',
    boardPosition: 'กรรมการ',
  },
  {
    order: 3,
    code: '5900012',
    fullName: 'สมนาม ใจมุ่ง',
    boardType: 'ส่วนวางแผนและผลิตสื่อโฆษณาฝ่ายสื่อสารองค์กร',
    boardPosition: 'เลขานุการ',
  },
];

export default function Step3({ onClickNext, onClickBack }: Props) {
  const [boardDataRows, setBoardDataRows] = useState<BoardProps[]>([]);

  useEffect(() => {
    if (boardDataRows.length > 0) {
      return;
    }

    setBoardDataRows(boardsData);
  }, [boardDataRows]);

  return (
    <>
      <Card className='mt-3'>
        <Card.Body>
          <Title text='คณะกรรมการกำหนดราคากลาง'
            className='fs-5 text-primary' />
          <Table className='mt-4'
            hidePagination>
            <thead>
            <tr>
              <th>ลำดับ</th>
              <th>รหัสพนักงาน</th>
              <th>ชื่อ-นามสกุล</th>
              <th>ประเภทกรรมการ</th>
              <th>ตำแหน่งในคณะกรรมการ</th>
            </tr>
            </thead>
            <tbody>
            {
              boardDataRows.map((rowsData, index) => (
                <tr key={`${rowsData.code}-${rowsData.order}`}>
                  <td className='text-center'>{rowsData.order}</td>
                  <td className='text-center'>
                    {rowsData.code}
                  </td>
                  <td className='text-center'>
                    {rowsData.fullName}
                  </td>
                  <td>
                    {rowsData.boardType}
                  </td>
                  <td>
                    {rowsData.boardPosition}
                  </td>
                </tr>
              ))
            }
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <div className='d-flex justify-content-between pt-5 gap-3'>
        <Button onClick={onClickBack}
          variant='light'><MdArrowBack /> ย้อนกลับ</Button>
        <Button
          onClick={onClickNext}
          variant='primary'
        >
          บันทึกและแสดงตัวอย่างเอกสาร <MdArrowForward />
        </Button>
      </div>
    </>
  );
}
