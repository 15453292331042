export enum StatusName {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  ANNOUNCEMENT = 'Announcement',
  ALL = 'All',
}

export enum Status {
  Draft,
  Pending,
  Approved,
  Rejected,
  Announcement,
}

export enum PlanStatus {
  AnnualPlan,
  InYearPlan,
  ChangePlan,
  CancelPlan,
}

export enum PlanStatusName {
  AnnualPlan = 'จัดทำแผนรวม',
  InYearPlan = 'จัดทำแผนระหว่างปี',
  ChangePlan = 'เปลี่ยนแปลงแผน',
  CancelPlan = 'ยกเลิกแผน'
}

export const enum PlanStatusText {
  AnnualPlan = 'AnnualPlan',
  InYearPlan = 'InYearPlan',
  ChangePlan = 'ChangePlan',
  CancelPlan = 'CancelPlan',
}

export enum StatusRecordDocument {
  Assigned = 'Assigned',
  DraftRecordDocument = 'DraftRecordDocument',
  WaitingAcceptor = 'WaitingAcceptor',
  Accepted = 'Accepted',
}