import {
  InputTextArea,
  Modal,
} from 'components';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

interface Props {
  show: boolean;
  click: () => void;
}

export function ApproveModal({ show, click }: Props) {
  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => click()}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>ให้ความเห็นชอบ</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className="p-0 ps-4">
            <InputTextArea
              label="ความเห็น"
            />
          </ModalBT.Body>
          <ModalBT.Footer className="justify-content-center">
            <Button
              variant="light"
              onClick={() => click()}
            >
              ยกเลิก
            </Button>
            <Button
              variant="success text-white"
            >
              <FaCheck className="me-2" /> ให้ความเห็น
            </Button>
          </ModalBT.Footer>
        </>
      )}
    />
  );
}
