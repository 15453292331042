const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const THCurrency = (value: number | string) => {
  if (!value) {
    return '';
  }
  return formatter.format(Number(value));
};