import { Breadcrumb } from 'components/Breadcrumb';
import StepProgress, { ProgressBarRefType } from 'components/StepProgress';
import {
  IFile,
  ItemModel,
} from 'models';
import { InYearPlanDetails } from 'models/PL';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useLoaderData,
  useParams,
} from 'react-router';
import Step1 from './Step1';
import Step2 from './Step2';

const BREADCRUMB_INFO = [
  {
    routerName: '/pl/pl0202', label: 'จัดทำแผนระหว่างปี',
  },
  {
    routerName: '', label: 'รายละเอียดแผนระหว่างปี',
  },
];

type PL0202Context = {
  data: InYearPlanDetails,
  setData: Dispatch<React.SetStateAction<InYearPlanDetails>>,
  positionLists: ItemModel[],
  setFiles: Dispatch<React.SetStateAction<IFile[]>>,
  files: IFile[],
};

type Loader = {
  positionList: ItemModel[],
  planData: InYearPlanDetails,
};

export const Context = createContext({} as PL0202Context);

export default function PL0202Detail() {
  const { id } = useParams();
  const progressBarRef = useRef({} as ProgressBarRefType);
  const [data, setData] = useState<InYearPlanDetails>({} as InYearPlanDetails);
  const [positionLists, setPositionLists] = useState<ItemModel[]>([]);
  const [isMount, setMount] = useState<boolean>(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const { positionList, planData } = useLoaderData() as Loader;

  useEffect(() => {
    setData(planData);
    setMount(true);
    setPositionLists(positionList);
  }, []);

  const stepData = [
    {
      title: <p>ข้อมูลแผน</p>,
      displayIndex: '1',
      child: <Step1
        back={() => progressBarRef.current.clickBack()}
        next={() => progressBarRef.current.clickNext()}
      />,
    },
    {
      title: <p>อำนาจอนุมัติ</p>,
      displayIndex: '2',
      child: <Step2
        back={() => progressBarRef.current.clickBack()}
      />,
    },
  ];

  return (
    isMount
      ? (
        <>
          <div>
            <h4 className="mt-2 mb-0 text-primary text-start">รายละเอียดแผนระหว่างปี</h4>
            <Breadcrumb data={BREADCRUMB_INFO} />
          </div>
          <Context.Provider value={{ data, setData, positionLists, setFiles, files }}>
            <StepProgress
              progressBarData={stepData}
              ref={progressBarRef}
            />
          </Context.Provider>
        </>
      ) : <></>
  );
}
