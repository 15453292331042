import {
  ButtonCustom,
  Card,
  ContractReceiveCollateral,
  ContractReturnCollateralConsiderStatus,
  Modal,
  ReturnCollateralStatus,
  SearchBuNameModal,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { UserAssigneesModal } from 'components/Modal/UserAssigneesModal';
import { StatusCMConsider } from 'components/StatusCMReturnCollateral';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import {
  AcceptorModel,
  AttachmentFileModel,
  ContractReturnCollateralResultModel,
  updateAssignsModel,
} from 'models/CM/CM06Model';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import CM06Service from 'services/CM/CM06Service';
import {
  fullDatetimeTH,
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { Context } from '.';
import { HistoryModal } from '../../Modal/HistoryModal';
import UploadFile from '../../Modal/UploadFileReturnCollateral';
import { shareValue } from 'services';
import { JorPorCode } from 'constant/basicInformation';
import { isNull } from 'utils/helper';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  dataCollateral: ContractReturnCollateralResultModel;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

enum level {
  level_0 = 0,
  level_1 = 1,
}

type Acceptor = {
  id?: string;
  userId: string;
  employeeCode: string;
  fullName: string;
  department: string;
  position: string;
  isAvailable: boolean;
  availableRemark?: string;
  acceptDate?: Date;
  acceptRemark?: string;
  rejectDate?: Date;
  rejectRemark?: string;
  status: string;
  sequence: number;
  level: number;
  delegateUserId: string;
  delegateFullName: string;
  delegateEmployeeCode: string;
  delegatePositionName: string;
  delegateDepartmentName: string;
  delegateDateRange: string;
}

function Step3({ onClickNext, onClickBack, dataCollateral }: Props) {
  const navigate = useNavigate();
  const { data, setData } = useContext(Context);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showReceiveAssignsLeve0, setShowReceiveAssignsLeve0] = useState(false);
  const [showReceiveAssignsLeve1, setShowReceiveAssignsLeve1] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const { userId, departmentCode } = useAppContext();
  const [assignsDataLevel0, setAssignsDataLevel0] = useState<AcceptorModel[]>([]);
  const [assignsDataLevel1, setAssignsDataLevel1] = useState<AcceptorModel[]>([]);
  const location = useLocation();
  const [showWaitForJorPorApproveModal, setShowWaitForJorPorApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [isDisabledLevel1, setIsDisabledLevel1] = useState(false);
  const [isDisabledLevel0, setIsDisabledLevel0,] = useState(false);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const getReturnCollateralAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.getContractReturnCollateralConsiderByIdAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    setData(data);
  }, []);

  const getConsiderDefaultAssigneeAsync = async () => {

    const res = await CM06Service.geContractConsiderDefaultAssigneeAsync();

    const newDatas = res.data as Acceptor;
    const assignsDataList: AcceptorModel[] = [];
    assignsDataList.push({
      id: undefined,
      departmentName: newDatas.department,
      fullName: newDatas.fullName,
      level: level.level_0,
      positionName: newDatas.position,
      sequence: newDatas.sequence,
      userId: newDatas.userId,
      employeeCode: newDatas.employeeCode,
      isAvailable: newDatas.isAvailable,
      status: ReturnCollateralStatus.DRAFT,
      sectionInRefCode: '',
      sectionName: '',
      sectionSequence: undefined,
      delegateDepartmentName: newDatas.delegateDepartmentName,
      delegateEmployeeCode: newDatas.delegateEmployeeCode,
      delegateFullName: newDatas.delegateFullName,
      delegatePositionName: newDatas.delegatePositionName,
      delegateUserId: newDatas.delegateUserId,
      seqKey: undefined,
      acceptDate: undefined,
      acceptRemark: '',
      availableRemark: '',
      rejectDate: undefined,
      rejectRemark: '',
      ContractReturnCollateralConsiderId: '',
    } as AcceptorModel);

    if (assignsDataList && assignsDataList.filter(a => a.level === level.level_0 && isNull(a.delegateUserId, a.userId) === userId).length > 0) {
      setIsDisabledLevel0(true)
    }

    setAssignsDataLevel0(assignsDataList);
  };

  useEffect(() => {

    if (data) {

      if (data.id) {
        getFilesAsync(data.id);
      }

      if (data.status === ReturnCollateralStatus.DRAFT) {

        setIsDisabled(false);
      } else {

        setIsDisabled(true);
      }

      if (data.assignee && data.assignee.length > 0) {
        setAssignsDataLevel0(data.assignee?.filter((x) => x.level === level.level_0));
        setAssignsDataLevel1(data.assignee?.filter((x) => x.level === level.level_1));

        const queueApprover = onCheckQueueApprover(data.assignee.filter((x) => x.level === level.level_1));
        setIsQueueApprover(queueApprover);

        if (data.assignee && data.assignee.filter(a => a.level === level.level_0 && isNull(a.delegateUserId, a.userId) === userId).length > 0) {

          setIsDisabledLevel0(true)
        }

        if (data.assignee && data.assignee.filter((a) => a.level === level.level_1 && isNull(a.delegateUserId, a.userId) === userId).length > 0) {

          setIsDisabledLevel1(true)
        }

      } else {
        getConsiderDefaultAssigneeAsync();
      }

      if (data.attachments) {
        handleSetFile(data.attachments);
      }
    }
  }, [data, userId]);


  useEffect(() => {
    if (assignsDataLevel0 && assignsDataLevel0.length > 0) {
      if (assignsDataLevel0 && assignsDataLevel0.filter(a => a.level === level.level_0 && isNull(a.delegateUserId, a.userId) === userId).length > 0) {
        setIsDisabledLevel0(true)
        setIsDisabledLevel1(true)
      }
    }
  }, [assignsDataLevel0]);

  const onCheckQueueApprover = (datas: AcceptorModel[]) => {

    if (datas && data.status === ReturnCollateralStatus.WAITFORJORPORAPPROVE) {

      const sectionDatas = datas?.filter(a => a.status === ReturnCollateralStatus.PENDING && !a.isAvailable)
        .sort((a, b) => a.sequence - b.sequence);

      if (sectionDatas && sectionDatas.length > 0) {
        return sectionDatas[0].userId === userId;
      }
      return false;
    }

    return false;
  };

  const handlerRemoveAssignsDataLevel0 = (index: number) => {
    const temp: AcceptorModel[] = [...assignsDataLevel0];
    temp.splice(index, 1);
    setAssignsDataLevel0(temp);
  };

  const handlerRemoveAssignsDataLevel1 = (index: number) => {
    const temp: AcceptorModel[] = [...assignsDataLevel1];
    temp.splice(index, 1);
    setAssignsDataLevel1(temp);
  };

  const onApproved = async (remark: string) => {

    const response = await CM06Service.returnCollateralCommitteesApprovedAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.CREATED) {
      toast.success('ให้ความเห็นสำเร็จ');
      getReturnCollateralAsync(data.id);
    }

    setShowApproveModal(false);
  };

  const onReject = async (remark: string) => {

    submitForm();

    if (!remark) {
      toast.warn('กรุณากรอกหมายเหตุ');
      return;
    }

    const response = await CM06Service.returnCollateralJorPorApprovedAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.CREATED) {
      toast.success('ส่งกลับแก้ไขสำเร็จ');
      getReturnCollateralAsync(data.id);
      setShowRejectModal(false);
    }

  };

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const onSelectEmpLevel0 = (empId: string, name: string, position: string, department: string, index: number, level: number) => {
    if (assignsDataLevel0 && assignsDataLevel0.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกเจ้าหน้าที่ซ้ำ');
    } else {
      const newData: AcceptorModel = {
        id: undefined,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        status: ReturnCollateralStatus.DRAFT,
        sequence: assignsDataLevel0.length + 1,
        level: level,
        employeeCode: '',
        isAvailable: false,
        sectionInRefCode: '',
        sectionName: '',
        sectionSequence: undefined,
        delegateDepartmentName: '',
        delegateEmployeeCode: '',
        delegateFullName: '',
        delegatePositionName: '',
        delegateUserId: '',
        seqKey: undefined,
        acceptDate: undefined,
        acceptRemark: '',
        availableRemark: '',
        rejectDate: undefined,
        rejectRemark: '',
        ContractReturnCollateralConsiderId: '',
      };

      setAssignsDataLevel0((assignsDataLevel0) => [...assignsDataLevel0, newData]);
    }
  };

  const onSelectEmpLevel1 = (empId: string, name: string, position: string, department: string, index: number, level: number) => {
    if (assignsDataLevel1 && assignsDataLevel1.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกเจ้าหน้าที่ซ้ำ');
    } else {
      const newData: AcceptorModel = {
        id: undefined,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        status: ReturnCollateralStatus.DRAFT,
        sequence: assignsDataLevel1.length + 1,
        level: level,
        employeeCode: '',
        isAvailable: false,
        sectionInRefCode: '',
        sectionName: '',
        sectionSequence: undefined,
        delegateDepartmentName: '',
        delegateEmployeeCode: '',
        delegateFullName: '',
        delegatePositionName: '',
        delegateUserId: '',
        seqKey: undefined,
        acceptDate: undefined,
        acceptRemark: '',
        availableRemark: '',
        rejectDate: undefined,
        rejectRemark: '',
        ContractReturnCollateralConsiderId: '',
      };

      setAssignsDataLevel1((assignsDataLevel1) => [...assignsDataLevel1, newData]);
    }
  };

  const onSubmitAsync = async (isAssignJorPor: boolean) => {

    submitForm();

    if ((!assignsDataLevel0 || assignsDataLevel0.length === 0)) {
      toast.warn('กรุณากรอกข้อมูล ผอ.จพ. มอบหมาย');
      return;
    }

    if ((!assignsDataLevel1 || assignsDataLevel1.length === 0)) {
      toast.warn('กรุณากรอกข้อมูลเจ้าหน้าที่พัสดุให้ความเห็น');
      return;
    }

    const assignsDataList: AcceptorModel[] = [];

    assignsDataLevel0?.forEach((item, index) => {
      assignsDataList.push({
        id: item.id,
        departmentName: item.departmentName,
        fullName: item.fullName,
        level: item.level,
        positionName: item.positionName,
        sequence: item.sequence,
        userId: item.userId,
        employeeCode: item.employeeCode,
        isAvailable: item.isAvailable,
        sectionInRefCode: item.sectionInRefCode,
        sectionName: item.sectionName,
        sectionSequence: item.sectionSequence,
        status: item.status,
      } as AcceptorModel);
    });

    assignsDataLevel1?.forEach((item, index) => {
      assignsDataList.push({
        id: item.id,
        departmentName: item.departmentName,
        fullName: item.fullName,
        level: item.level,
        positionName: item.positionName,
        sequence: item.sequence,
        userId: item.userId,
        employeeCode: item.employeeCode,
        isAvailable: item.isAvailable,
        sectionInRefCode: item.sectionInRefCode,
        sectionName: item.sectionName,
        sectionSequence: item.sectionSequence,
        status: item.status,
      } as AcceptorModel);
    });

    if (data && data.id && assignsDataList.length > 0) {

      const updateDataModel: updateAssignsModel = {
        saveAssignee: isAssignJorPor,
        assignees: assignsDataList,
      };

      const res = await CM06Service.updateReturnCollateralAssignsAsync(data.id, updateDataModel);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {

        if (isAssignJorPor) {

          // const res = await CM06Service.waitForJorPorApproveReturnCollateralAsync(data.id);
          toast.success('มอบหมายสำเร็จ');
          setShowWaitForJorPorApproveModal(false);
        } else {
          toast.success('บันทึกข้อมูลสำเร็จ');
        }

        getReturnCollateralAsync(data.id);

      } else {
        toast.error(res.statusText);
      }
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await CM06Service.uploadAttachmentConsiderAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getFilesAsync(data.id);
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {

    if (data && data.id) {
      const res = await CM06Service.removeAttachmentconsiderAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getFilesAsync(data.id);

      }
    }

  }, [files, data.id]);

  const getFilesAsync = useCallback(async (id: string) => {
    const { data, status } = await CM06Service.getAttachmentConsiderAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    handleSetFile(data.attachments);

  }, []);

  return (
    <div className='document'>
      <Title text='เจ้าหน้าที่พัสดุให้ความเห็น'
        extraElement={
          <>
            <StatusCMConsider systemStatue={data.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </>
        } />

      <Row className='mt-4 align-items-center'>
        <div className='d-flex justify-content-between'>
          <h5 className='fw-bold'>ผอ.จพ. มอบหมาย</h5>
          <ButtonCustom
            text='เพิ่มรายชื่อ'
            icon='add'
            variant='outline-primary'
            disabled={!isDisabledLevel0}
            onClick={() => setShowReceiveAssignsLeve0(true)} />
        </div>
        <Col sm={12}>
          <Table
            hidePagination
            className='mt-1 step-bar'
            notResponse
          >
            <thead>
              <tr>
                <th className='text-start ps-3'
                  style={{ width: 100 }}>ลำดับ
                </th>
                <th className='text-start'
                  style={{ width: '50%' }}>ชื่อ-นามสกุล
                </th>
                <th className='text-start'
                  style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                </th>
                {/* <th>ผู้ปฏิบัติหน้าที่แทน</th> */}
                <th style={{ width: 120 }}>วันที่มอบหมาย</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {assignsDataLevel0?.sort((a, b) => a.sequence - b.sequence)
                .map((data, index) => (
                  <>
                    <tr>
                      <td className='text-center'>{index + 1}</td>
                      <td className='text-left'>
                        {data.fullName}
                        <p className='m-0 department'>
                          {data.positionName}
                        </p>
                      </td>
                      <td className='text-left'>
                        {data.delegateFullName &&
                          <>
                            {data.delegateFullName}
                            <p className='m-0 department'>
                              {data.delegatePositionName}
                            </p>
                          </>
                        }
                      </td>
                      <td className='text-center'>
                        {fullDatetimeTH(data.acceptDate!)}
                      </td>
                      <td className='text-end'>
                        {
                          isDisabledLevel0 && (
                            <Button
                              onClick={() => handlerRemoveAssignsDataLevel0(index)}
                              variant='danger'
                            >
                              <FaTrash />
                            </Button>
                          )
                        }
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className='mt-4 align-items-center'>
        <div className='d-flex justify-content-between'>
          <h5 className='fw-bold'>มอบหมายงานผู้รับผิดชอบ</h5>
          <ButtonCustom
            text='เพิ่มรายชื่อ'
            icon='add'
            variant='outline-primary'
            disabled={!isDisabledLevel1}
            onClick={() => setShowReceiveAssignsLeve1(true)} />
        </div>
        <Col sm={12}>
          <Table
            hidePagination
            className='mt-1 step-bar'
            notResponse
          >
            <thead>
              <tr>
                <th className='text-start ps-3'
                  style={{ width: 100 }}>ลำดับ
                </th>
                <th className='text-start'
                  style={{ width: '50%' }}>ชื่อ-นามสกุล
                </th>
                <th className='text-start'
                  style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                </th>
                <th style={{ width: 120 }}>วันที่มอบหมาย</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {assignsDataLevel1?.sort((a, b) => a.sequence - b.sequence)
                .map((data, index) => (
                  <>
                    <tr>
                      <td className='text-center'>{index + 1}</td>
                      <td className='text-left'>
                        {data.fullName}
                        <p className='m-0 department'>
                          {data.positionName}
                          {/* {
                            data.departmentName &&
                            <>
                              <span className='mx-1'>|</span> {data.departmentName}
                            </>
                          } */}
                        </p>
                      </td>
                      <td className='text-left'>
                        {data.delegateFullName &&
                          <>
                            {data.delegateFullName}
                            <p className='m-0 department'>
                              {data.delegatePositionName}
                            </p>
                          </>
                        }
                      </td>
                      <td className='text-center'>
                        {fullDatetimeTH(data.acceptDate!)}
                      </td>
                      <td className='text-end'>
                        {isDisabledLevel1 && (
                          <Button
                            onClick={() => handlerRemoveAssignsDataLevel1(index)}
                            variant='danger'
                          // disabled={isDisabled}
                          >
                            <FaTrash />
                          </Button>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td />
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5' />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFile
              disabled={!data.id}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.id!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <ButtonCustom text='ย้อนกลับ'
            onClick={onClickBack}
            icon='arrowback'
            variant='outline-primary' />
        </div>
        <div className='d-flex gap-3 align-items-center'>
          {
            (isDisabledLevel0 || isDisabledLevel1) && (
              <ButtonCustom text='บันทึก'
                variant='outline-primary'
                onClick={() => onSubmitAsync(false)} />
            )
          }
          {
            (data.status !== ContractReturnCollateralConsiderStatus.JORPORASSIGNED && (isDisabledLevel0 || isDisabledLevel1)) && (
              <ButtonCustom text='มอบหมายงาน'
                onClick={() => setShowWaitForJorPorApproveModal(true)} />
            )
          }
        </div>
        <div />
      </div>

      <Modal
        show={showWaitForJorPorApproveModal}
        size='lg'
        onHide={() => setShowWaitForJorPorApproveModal(!showWaitForJorPorApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการมอบหมายงาน หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitForJorPorApproveModal(!showWaitForJorPorApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={data.id}
        title='คืนหลักประกันสัญญา'
        status={ContractReceiveCollateral.Consider}
      />

      {/* <UserAssigneesModal
        show={showReceiveAssignsLeve0}
        onHide={() => setShowReceiveAssignsLeve0(!showReceiveAssignsLeve0)}
        onSelectItem={onSelectEmpLevel0}
        level={level.level_0}
      /> */}

      {/* <UserAssigneesModal
        show={showReceiveAssignsLeve1}
        onHide={() => setShowReceiveAssignsLeve1(!showReceiveAssignsLeve1)}
        onSelectItem={onSelectEmpLevel1}
        level={level.level_1}
      /> */}

      <SearchBuNameModal
        total={userTotal}
        show={showReceiveAssignsLeve0}
        onHide={() => setShowReceiveAssignsLeve0(!showReceiveAssignsLeve0)}
        onSelect={(id, name, department, position) => onSelectEmpLevel0(id, name, position!, department!, assignsDataLevel0.length + 1, level.level_0)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <SearchBuNameModal
        total={userTotal}
        show={showReceiveAssignsLeve1}
        onHide={() => setShowReceiveAssignsLeve1(!showReceiveAssignsLeve1)}
        onSelect={(id, name, department, position) => onSelectEmpLevel1(id, name, position!, department!, assignsDataLevel0.length + 1, level.level_1)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
    </div>
  );
}

export default Step3;
