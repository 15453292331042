import { useMemo } from 'react';
import { MdCircle } from 'react-icons/md';

interface Props {
  systemStatus: string;
  outline?: boolean;
  text?: string;
  systemName: string;
}
interface Type {
  [key: string]: string;
}

const cmStatus: Type = {
    Pending: 'รอเห็นชอบ',
    Approve: 'เห็นชอบ',
    Approved: 'ผู้มีอำนาจเห็นชอบ/อนุมัติ',
    Rejected: 'ผู้มีอำนาจส่งกลับแก้ไข',
    Reject: 'ส่งกลับแก้ไข',
    Draft: 'รอดำเนินการตรวจรับ',
    WaitForInspectorReceived: 'รอคณะกรรมการตรวจรับรับทราบ',
    InspectorReceived: 'คณะกรรมการตรวจรับรับทราบ',
    InspectorRejected: 'คณะกรรมการตรวจรับส่งกลับแก้ไข',
    WaitForJorPorApprove: 'รอเจ้าหน้าที่พัสดุให้ความเห็น',
    JorPorApproved: 'เจ้าหน้าที่พัสดุให้ความเห็น',
    JorPorRejected: 'เจ้าหน้าที่พัสดุส่งกลับแก้ไข',
    WaitForApprove: 'รอผู้มีอำนาจเห็นชอบ/อนุมัติ',
    Cancel: 'ยกเลิกตรวจรับ',
    Default: 'ร่าง',
};

const cmStatusColor: Type = {
    Pending: 'bg-warning bg-opacity-10 text-warning rounded',
    Approve: 'bg-success text-success ',
    Approved: 'bg-success text-success ',
    isApprove: 'bg-success text-success ',
    Rejected: 'bg-danger text-danger',
    InspectorRejected: 'bg-danger text-danger',
    JorPorRejected: 'bg-danger text-danger',
    Cancel: 'bg-danger text-danger',
    Reject: 'bg-danger text-danger',
    WaitForInspectorReceived: 'bg-yellow text-yellow ',
    WaitForJorPorApprove: 'bg-yellow text-yellow ',
    WaitForApprove: 'bg-yellow text-yellow ',
    JorPorApproved: 'bg-yellow text-yellow ',
    InspectorReceived: 'bg-yellow text-yellow ',
    Draft: 'bg-dark text-muted',
    ContractAgreement: 'bg-dark text-muted',
    Default: 'bg-dark text-muted',
};

export function StatusCMReceived({ systemStatus, outline, text, systemName }: Props) {
  const statusColor = useMemo(() => {
    if (systemStatus === '' || systemStatus === null || systemStatus === undefined) {
      return cmStatusColor.Default;
    }

    return cmStatusColor[systemStatus];
  }, [systemStatus]);

  function getStatus(system: string, systemStatue: string) {
    if (systemStatus === '' || systemStatus === null || systemStatus === undefined) {
      return cmStatus.Default;
    }

    return cmStatus[systemStatus];
  }

  return (
    <>
      {statusColor ?
        <div className={`d-flex align-items-center px-3 py-1 bg-opacity-10 rounded-pill ${statusColor}`}>
          <MdCircle className='me-2 circle' /> {systemName ? getStatus(systemName, systemStatus) : 'text noting'}
        </div> : null}
    </>
  );
}
