import {
  Card,
  Check,
  Currency,
  Input,
  InputTextArea,
  InvitedContractStatus,
  Radio,
  ButtonCustom,
  CMDetailStatus,
  Readonly,
  SearchBuNameModal,
  Status,
  StatusType,
  FileValue,
} from 'components';
import Title from 'components/Controls/Title';
import {
  IFile,
  ItemModel,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
} from 'models';
import {
  AttachmentsModel,
  CreateInvitedContractRequestModel,
  DeliveryPeriodModel,
  EntrepreneurModel,
  InviteCollateralModel,
  WarrantyModel,
  contractAgreementModel,
  contractNumberDataModel,
} from 'models/CM/CM01Models';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { FaHistory, FaPlus, FaSave } from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
  MdSave,
} from 'react-icons/md';
import {
  useLoaderData,
  useNavigate,
} from 'react-router';
import { shareValue } from 'services';
import CM01Service from 'services/CM/CM01Service';
import {
  HttpStatusCode,
  submitForm,
  THCurrency,
  useAppContext,
  ValidatePattern,
} from 'utils';
import { SharedvalueGroup } from 'utils/constants/ContractEnum';
import { dataValidation } from 'utils/helper';
import toast from 'utils/toast';
import { Context } from '.';
import { HistoryModal } from '../Modal/HistoryModal';
import { ModalEditPO } from '../Modal/ModalEditPO';
import UploadFile from '../UploadFile';
import { InputNumber } from 'components/Controls/InputNumber';
import { TbListSearch } from 'react-icons/tb';
import userContactService from 'services/userContactService';
import { UserContactModel } from 'models/UserContactModels';
import { SendMailToEntrepreneurAsyncModal } from 'components/Modal/SendMailToVendorModal';
import { ModalEditContractNumber } from '../Modal/ModalEditContractNumber';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const InviteCollateralDetail = [
  {
    label: 'มีหลักประกัน',
    value: 'true',
  },
  {
    label: 'ไม่มีหลักประกัน',
    value: 'false',
  },
];

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

type Loader = {
  departmentDDL: ItemModel[],
  supplyMethodDDL: ItemModel[],
  supplyMethodTypeDDL: ItemModel[],
  contractTypeDDL: ItemModel[],
  periodConditionDDL: ItemModel[],
  warrantyConditionDDL: ItemModel[],
  periodTypeDDL: ItemModel[],
};

enum fine {
  fineRemark = 'อัตราร้อยละ 0.2 ของราคาสิ่งของที่ยังไม่ได้รับมอบ',
}

function Step1({
  onClickNext,
  onClickBack,
}: Props) {
  // const [editPO, setEditPO] = useState(false);
  const {
    data,
    setData,
  } = useContext(Context);
  const {
    departmentDDL,
    supplyMethodDDL,
    supplyMethodTypeDDL,
    periodConditionDDL,
    warrantyConditionDDL,
    periodTypeDDL,
  } = useLoaderData() as Loader;
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);
  const [contractManagementTypeDDL, setContractManagementTypeDDL] = useState<ItemModel[]>([]);
  const [contractFormatDDl, setContractFormatDDlL] = useState<ItemModel[]>([]);
  const [supplyMethod, setSupplyMethod] = useState('');
  const [supplyMethodType, setSupplyMethodType] = useState('');
  const [supplyMethodSpecialType, setSupplyMethodSpecialType] = useState('');
  const [contractManagementType, setContractManagementType] = useState('');
  const { userId, departmentCode } = useAppContext();
  const [files, setFiles] = useState<IFile[]>([]);
  const [delFiles, setDelFiles] = useState<string[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const navigate = useNavigate();
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [userContactTel, setUserContactTel] = useState('');
  const [contractAgreementData, setContractAgreementData] = useState<contractAgreementModel[]>([]);
  const [isNextStep, setIsNextStep] = useState(false);
  const [editPO, setEditPO] = useState<boolean[]>([]);
  const [caId, setCaId] = useState('');
  const [oldPo, setOldPo] = useState('');
  const [oldContractNumber, setOldContractNumber] = useState('');
  const [editContractNumber, setEditContractNumber] = useState<boolean[]>([]);
  const [showUserModals, setShowUserModals] = useState<boolean[]>([]);
  const [userContactData, setUserContactData] = useState<UserContactModel[]>([]);
  const [showSendEmail, setshowSendEmail] = useState<boolean[]>([]);

  const onOpenSendEmailModal = (index: number) => {
    setshowSendEmail((showSendEmail) => {
      const newModal = [...showSendEmail];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideSendEmailModal = (index: number) => {
    setshowSendEmail((showSendEmail) => {
      const newModal = [...showSendEmail];
      newModal[index] = false;
      return newModal;
    });
  };

  const goToPage = (planNumber: string) => {
    window.open(`/web/collect-attachment/${planNumber}`, '_blank');
  };

  const goToTorPage = (torId: string) => {
    window.open(`/web/preprocurement-tor/detail/${torId}`, '_blank');
  };

  const goToDocumentPage = (contractAgreementId: string, taxId: string, contractAgreementsId: string) => {
    window.open(`/web/contract-invitation-sign/documentTemplate/${contractAgreementId}/${taxId}/${contractAgreementsId}`, '_blank');
  };

  useEffect(() => {
    if (data.contractAgreements && data.contractAgreements.filter(x => x.id === null || x.id === undefined).length > 0) {
      setIsNextStep(false);
    } else {
      setIsNextStep(true);
    }
  }, [contractAgreementData]);

  useEffect(() => {
    if (data) {
      setIsDisabled(data.status !== InvitedContractStatus.DRAFT
        && data.status !== InvitedContractStatus.REJECTED
        && data.status !== InvitedContractStatus.NONE);

      setIsSendMail(data.status === InvitedContractStatus.INVITED);

      dataValidation(data.supplyMethodId, data.supplyMethodId, setSupplyMethod);
      dataValidation(data.supplyMethodTypeId, data.supplyMethodTypeId, setSupplyMethodType);
      dataValidation(data.supplyMethodSpecialTypeId, data.supplyMethodSpecialTypeId, setSupplyMethodSpecialType);
      dataValidation(!data.deliveryPeriod, data.deliveryPeriod, (value) => {
        data.deliveryPeriod = value;
      });
      dataValidation(!data.warranty, data.warranty, (value) => {
        data.warranty = value;
      });
      dataValidation(!data.fineRemark, data.fineRemark, (value) => {
        data.fineRemark = value;
      });

      dataValidation(data.attachments, data.attachments, handleSetFile);

      if (data.contactUserTel) {
        setUserContactTel(data.contactUserTel);
      }

      if (data.contractAgreements) {
        setContractAgreementData(data.contractAgreements);

        if (data.contractAgreements.filter(x => x.id === null || x.id === undefined).length > 0) {
          setIsNextStep(false);
        } else {
          setIsNextStep(true);
        }

        data.contractAgreements.forEach((item, i) => {
          onSumCollateral(item.inviteCollateral?.percent!, item.offerTotalAmountVat, i, data.contractAgreements);
        })

      }
    }
  }, [data, userId]);

  const onSumCollateral = (percent: number, totalPriceAmount: number, index: number, contractAgreements: contractAgreementModel[]) => {
    if ((totalPriceAmount !== undefined || totalPriceAmount !== null || totalPriceAmount > 0) && (percent !== undefined || percent !== null || percent > 0)) {

      const val = totalPriceAmount * (percent / 100)

      const temp = contractAgreements;
      temp[index].inviteCollateral.amount = val;
      temp[index].inviteCollateral.percent = percent;
      setContractAgreementData(temp);
      return;
    }
  };

  useEffect(() => {

    if (data.contractAgreements) {

      data.contractAgreements.forEach((item, i) => {
        getUserContactDetailAsync(data.contractAgreements[i].contactUserId);
      })

    }
  }, [data.contractAgreements])

  const onChangeCollateral = (val: string, index: number) => {
    const data = [...contractAgreementData];
    if (val === 'true') {
      data[index].inviteCollateral.active = true;
    } else {
      data[index].inviteCollateral.active = false;
    }
    setContractAgreementData(data);
  };

  const handleSumCollateralAmount = (percent: number, totalPriceAmount: number, index: number) => {
    if ((totalPriceAmount !== undefined || totalPriceAmount !== null || totalPriceAmount > 0) && (percent !== undefined || percent !== null || percent > 0)) {
      const val = totalPriceAmount * (percent / 100)

      const data = [...contractAgreementData];

      data[index].inviteCollateral.amount = val;
      data[index].inviteCollateral.percent = percent;
      setContractAgreementData(data);
    }
  };

  const handleSumCollateralPercent = (amount: number, totalPrice: number, index: number) => {
    if ((amount !== undefined || amount !== null || amount > 0) && (totalPrice !== undefined || totalPrice !== null || totalPrice > 0)) {

      const val = (amount / totalPrice) * 100;
      let valAmount = amount;
      if (val > 100) {
        toast.warn('ร้อยละของราคาค่าจ้างตามสัญญาต้องไม่เกิน 100');
        valAmount = totalPrice * (100 / 100)
      }

      const data = [...contractAgreementData];
      data[index].inviteCollateral.amount = valAmount;
      data[index].inviteCollateral.percent = val;
      setContractAgreementData(data);
    }
  };

  const handleSetFile = (documents: AttachmentsModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const getInvitedContractAsync = async (id: string, taxId: string) => {
    const response = await CM01Service.getInvitedContractByIdAsync(id, taxId);
    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
      navigate(`/contract-invitation-sign/detail/${id}/${taxId}`);
      setContractAgreementData(response.data.contractAgreements);
    }
  };

  const getSupplyMethodSpecialType = useCallback(async (supplyMethodId: string) => {
    const {
      data,
      status,
    } = await shareValue.getSupplyMethodListAsync(supplyMethodId);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    setSupplyMethodSpecialDDL(data);
  }, [supplyMethod]);

  const getContractManagementType = useCallback(async (supplyMethodTypeId: string) => {
    const {
      data,
      status,
    } = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractManagementType);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    const newData = data.filter((x: { value2: string; }) => x.value2 === supplyMethodTypeId);

    setContractManagementTypeDDL(data);
    setContractManagementType(newData[0]?.value);
  }, [data.supplyMethodTypeId]);

  const getContractFormat = useCallback(async (supplyMethodTypeId: string) => {
    const {
      data,
      status,
    } = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractFormat, supplyMethodTypeId);

    if (status !== HttpStatusCode.OK) {
      return;
    }

    setContractFormatDDlL(data);
  }, [contractManagementType]);

  useEffect(() => {
    getSupplyMethodSpecialType(supplyMethod);
    getContractManagementType(data.supplyMethodTypeId);
    getContractFormat(contractManagementType);
  }, [supplyMethod, data.supplyMethodTypeId, contractManagementType]);

  const handleAddDocUploadFile = (inputFiles: IFile[]) => {
    const docUpload: File[] = [];

    inputFiles.forEach((i) => {
      if (i.file !== null) {
        docUpload.push(i.file);
      }
    });

    return docUpload;
  };

  const onSubmitAsync = async (tabIndex: number) => {
    submitForm();

    if ((!contractAgreementData[tabIndex].entrepreneur.email)) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
      return;
    }

    if (contractAgreementData[tabIndex].inviteCollateral.active && (!contractAgreementData[tabIndex].inviteCollateral.amount && !contractAgreementData[tabIndex].inviteCollateral.percent)) {
      toast.warn('กรุณากรอกข้อมูลหลักประกันสัญญามากกว่า 0');
      return;
    }

    if ((!contractAgreementData[tabIndex].contactUserTel)) {
      toast.warn('กรุณากรอกข้อมูลติดต่อผู้จัดทำสัญญา');
      return;
    }

    let docUpload: File[] = [];

    if (files.length > 0) {
      docUpload = handleAddDocUploadFile(files);
      data.docNameforDelete = delFiles;
      data.files = [...docUpload];
    }

    if (data) {
      const createDataModel: CreateInvitedContractRequestModel = {
        planNumber: data.planNumber,
        planName: data.planName,
        financialAmount: data.financialAmount,
        departmentId: data.departmentId,
        supplyMethodId: data.supplyMethodId,
        supplyMethodTypeId: data.supplyMethodTypeId,
        supplyMethodSpecialTypeId: data.supplyMethodSpecialTypeId,
        invitedEntrepreneurId: contractAgreementData[tabIndex].invitedEntrepreneurId,
        contractRecordEntrepreneurId: contractAgreementData[tabIndex].contractRecordEntrepreneurId,
        contractRecordId: data.contractRecordId,
        entrepreneurId: data.entrepreneurId,
        entrepreneurTaxId: contractAgreementData[tabIndex].entrepreneur.taxId,
        entrepreneurName: contractAgreementData[tabIndex].entrepreneur.name,
        entrepreneurEmail: contractAgreementData[tabIndex].entrepreneur.email,
        contractName: contractAgreementData[tabIndex].contractName,
        contractNumber: contractAgreementData[tabIndex].contractNumber,
        poNumber: contractAgreementData[tabIndex].poNumber,
        offerTotalAmountVat: contractAgreementData[tabIndex].offerTotalAmountVat,
        contractType: contractManagementType,
        contractTemplate: contractAgreementData[tabIndex].contractTemplate,
        deliveryPeriod: contractAgreementData[tabIndex].deliveryPeriod,
        warranty: contractAgreementData[tabIndex].warranty,
        fineRemark: data.fineRemark,
        inviteCollateral: contractAgreementData[tabIndex].inviteCollateral,
        contactUserId: contractAgreementData[tabIndex].contactUserId!,
        id: data.id,
        contractAgreementId: contractAgreementData[tabIndex].id,
      };

      const createUserContactData: UserContactModel = {
        userId: contractAgreementData[tabIndex].contactUserId,
        employeeCode: contractAgreementData[tabIndex].contactUserEmployeeCode,
        firstName: contractAgreementData[tabIndex].contactUserName,
        lastName: contractAgreementData[tabIndex].contactUserLastName,
        tel: contractAgreementData[tabIndex].contactUserTel,
      }

      if (data.id && contractAgreementData[tabIndex].id) {
        const res = await CM01Service.updateInvitedContractRequestAsync(createDataModel)

        if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.CREATED) {

          toast.error(res.statusText);
          return;
        }

        toast.success('บันทึกข้อมูลสำเร็จ');
      } else if (data.id) {
        const res = await CM01Service.createContractAgreementRequestAsync(createDataModel)

        if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.CREATED) {

          toast.error(res.statusText);
          return;
        }

        toast.success('บันทึกข้อมูลสำเร็จ');
      } else {
        const res = await CM01Service.createInvitedContractRequestAsync(createDataModel);

        if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.CREATED) {

          toast.error(res.statusText);
          return;
        }

        const newId: string = res.data;
        if (newId && docUpload) {
          await CM01Service.uploadAttachmentAsync(newId, docUpload);
        }

        toast.success('บันทึกข้อมูลสำเร็จ');
        navigate(`/contract-invitation-sign/detail/${newId}/${data.taxId}`);
      }

      if (userContactData.filter(x => x.userId === contractAgreementData[tabIndex].contactUserId).length > 0) {
        const response = await userContactService.updateUserContactAsync(contractAgreementData[tabIndex].contactId, createUserContactData);
      } else {
        const response = await userContactService.createUserContactAsync(createUserContactData);
      }

      getUserContactDetailAsync(contractAgreementData[tabIndex].contactUserId!, tabIndex);
      await getInvitedContractAsync(data.id, data.taxId);

    }
  };

  const getUserContactDetailAsync = async (userId: string, index?: number) => {
    const response = await userContactService.getUserContactDetailAsync(userId);
    if (response.status === HttpStatusCode.OK) {
      handlerOnChangeContact(response.data);
      if (index !== undefined) {
        handlerOnChangeContactTal(response.data, index)
      }
    }
  };

  const handlerOnChangeContact = (ContactData: any) => {
    const newData: UserContactModel[] = userContactData || [];
    newData.push({
      userId: ContactData.userId,
      employeeCode: ContactData.employeeCode,
      firstName: ContactData.firstName,
      id: ContactData.id,
      lastName: ContactData.lastName,
      tel: ContactData.tel,
    } as UserContactModel);
    setUserContactData(newData);
  };

  const handleFileChange = async (inputFiles: File[]) => {
    for (let i = 0; i < inputFiles.length; i++) {
      const file = inputFiles[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (!data || !data.id) {
      return;
    }

    const res = await CM01Service.uploadAttachmentAsync(
      data.id,
      inputFiles,
    );

    if (res.status === HttpStatusCode.OK) {
      handleSetFile(res.data);
      toast.success('อัปโหลดไฟล์สำเร็จ');
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {
    if (!data || !data.id) {
      return;
    }

    const res = await CM01Service.removeFileAsync(
      data.id,
      docId,
    );

    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
      toast.success('ลบไฟล์สำเร็จ');
      await getDocumentContractByIdAsync(data.id, data.taxId);
    }
  }, [files, data.id]);

  const getDocumentContractByIdAsync = async (id: string, taxId: string) => {
    const { data, status } = await CM01Service.getInvitedContractByIdAsync(id, taxId);

    if (status === HttpStatusCode.OK) {
      const attachments = data.attachments as AttachmentsModel[];

      if (attachments && attachments.length > 0) {
        const newFiles: IFile[] = attachments?.map((data, index) => ({
          id: data.id!,
          name: data.fileName,
          file: null,
        } as IFile));

        setFiles(newFiles);
      } else {
        setFiles([]);
      }
    }
  };

  const onChangePo = (newPoNumber: string, index: number) => {
    if (newPoNumber) {
      const data = [...contractAgreementData];
      data[index].poNumber = newPoNumber;
      setContractAgreementData(data);
    }
  };

  const onChangeContractNumber = (newNumber: string, index: number) => {
    if (newNumber) {
      const data = [...contractAgreementData];
      data[index].contractNumber = newNumber;
      setContractAgreementData(data);
    }
  };

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const onSelectEmpSigned = (empId: string, name: string, index: number) => {
    const data = [...contractAgreementData];
    data[index].contactUserId = empId;
    data[index].contactUserName = name;
    setContractAgreementData(data);
    getUserContactDetailAsync(empId, index);
  };

  const handlerOnChangeContactTal = (ContactData: any, index: number) => {
    const data = [...contractAgreementData];
    data[index].contactUserTel = ContactData.tel;
    setContractAgreementData(data);
  };

  const handlerOnChangeContractName = (value: string, index: number) => {
    const data = [...contractAgreementData];
    data[index].contractName = value;
    setContractAgreementData(data);
  };

  const handlerOnChangeContractEmail = (value: string, index: number) => {
    const data = [...contractAgreementData];
    data[index].entrepreneur.email = value;
    setContractAgreementData(data);
  };

  const handlerOnChangeContactUserTel = (value: string, index: number) => {
    const data = [...contractAgreementData];
    data[index].contactUserTel = value;
    setContractAgreementData(data);
  };

  const onHidePoModal = (index: number) => {
    setCaId('');
    setOldPo('');
    setEditPO((editPO) => {
      const newModal = [...editPO];
      newModal[index] = false;
      return newModal;
    });
  };

  const onOpenPoModal = (index: number, caId: string, oldPo: string) => {
    setCaId(caId);
    setOldPo(oldPo);
    setEditPO((editPO) => {
      const newModal = [...editPO];
      newModal[index] = true;
      return newModal;
    });
  };

  const onOpenContractNumberModal = (index: number, caId: string, oldContractNumber: string) => {
    setCaId(caId);
    setOldContractNumber(oldContractNumber);
    setEditContractNumber((editContractNumber) => {
      const newModal = [...editContractNumber];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideContractNumberModal = (index: number) => {
    setCaId('');
    setOldContractNumber('');
    setEditContractNumber((editContractNumber) => {
      const newModal = [...editContractNumber];
      newModal[index] = false;
      return newModal;
    });
  };

  const onHideContactModal = (index: number) => {
    setShowUserModals((showUserModals) => {
      const newModal = [...showUserModals];
      newModal[index] = false;
      return newModal;
    });
  };

  const onOpenContactModal = (index: number) => {
    setShowUserModals((showUserModals) => {
      const newModal = [...showUserModals];
      newModal[index] = true;
      return newModal;
    });
  };

  const onSubmitSendEmail = async (id: string, email: string) => {

    if ((!email)) {
      toast.warn('กรูณาระบุ E-mail');
      return;
    }

    const res = await CM01Service.sendEmailToEntrepreneurAsync(id, email);

    if (res.status === HttpStatusCode.OK) {

      toast.success('ส่งอีเมลสำเร็จ');
    } else {
      toast.error(res.statusText);
    }
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title text='ข้อมูลโครงการ'
          className='fs-5'
          extraElement={(
            <div className='d-flex gap-2'>
              <CMDetailStatus systemStatue={data.status}
                systemName='cm-03' />
              <Button
                onClick={() => setShowHistoryModal(!showHistoryModal)}
                variant='outline-primary'>
                <FaHistory className='me-1' />
                ประวัติการใช้งาน
              </Button>
            </div>
          )} />
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={6}>
            <Readonly label='เลขที่รายการจัดซื้อจัดจ้าง'
              textReadonly={data.planNumber} />
          </Col>
          <Col sm={12}
            md={6}>
            <Readonly label='เลขที่จัดทำร่างขอบเขตงาน'
              textReadonly={
                <Button
                  variant="link"
                  onClick={() => goToTorPage(data.torId)}
                  className='p-0 m-0'>
                  {data.torNo}
                </Button>
              } />
          </Col>
        </Row>
        <Row>
          <Col sm={12}
            md={6}>
            <Readonly label='โครงการ'
              textReadonly={data.planName} />
          </Col>
          <Col sm={12}
            md={3}>
            <Readonly label='งบประมาณโครงการ (บาท)'
              textReadonly={formatter.format(data.financialAmount)} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12}
            md={3}
            xl={3}>
            <Readonly label='วิธีจัดหา'
              textReadonly={data.supplyMethodName} />
          </Col>
          <Col sm={12}
            md={3}>
            <Readonly textReadonly={data.supplyMethodTypeName} />
          </Col>
          <Col sm={12}
            md={3}>
            <Readonly textReadonly={data.supplyMethodSpecialTypeName} />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='ข้อมูลสัญญา'
          className='fs-5' />
        <Row className='justify-content-center mt-3'>
          <Col className='tab-border-active'>
            <Tabs
              defaultActiveKey='1'
              id='justify-tab-example'
              className='mb-2'
              justify
            // onClick={() => onChangeTapIndex(tapIndex)}
            >
              {
                contractAgreementData?.map((item, index) => (
                  <Tab
                    eventKey={index + 1}
                    title={<p><Status value={item.id ? 'Invited' : 'None'}
                      showValue={item.contractNumber}
                      type={StatusType.INVITEDCONTRACT} /> </p>}
                  >
                    <div className='mt-3'>
                      <div className='d-flex gap-2 justify-content-between'>
                        <h5>ข้อมูลสัญญา</h5>
                        {
                          item.id && (
                            <Button
                              variant='outline-primary'
                              onClick={() => goToDocumentPage(data.id, data.taxId, item.id)}>
                              ไปยังเอกสารเชิญชวนทำสัญญา
                            </Button>
                          )
                        }
                      </div>
                      <Row className='align-items-center mt-3'>
                        <Col sm={12}
                          md={6}>
                          <Input label='คู่ค้า'
                            placeholder='คู่ค้า'
                            disabled
                            value={item.entrepreneur.name} />
                        </Col>
                        <Col sm={12}
                          md={3}>
                          <Input label='Email (คู่ค้า)'
                            placeholder='Email (คู่ค้า)'
                            rule={{ required: true }}
                            value={item.entrepreneur.email}
                            onChange={(val) => handlerOnChangeContractEmail(val, index)}
                            disabled={isDisabled}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}
                          md={6}>
                          <InputTextArea label='ชื่อสัญญา'
                            placeholder='ชื่อสัญญา'
                            value={item.contractName}
                            // onChange={(val) => setContractName(val)}
                            onChange={(val) => handlerOnChangeContractName(val, index)}
                            disabled={isDisabled}
                          />
                        </Col>
                      </Row>
                      <Row className='align-items-center'>
                        <Col sm={12}
                          lg={3}>
                          <Input label='เลขที่สัญญา PO'
                            placeholder='เลขที่สัญญา PO'
                            disabled
                            textCenter
                            value={item.poNumber} />
                        </Col>
                        <Col sm={2}
                          className='pt-4'>
                          {(item.id) && (
                            <Button style={{ width: '120%' }}
                              className='me-2 px-3'
                              variant='outline-primary'
                              onClick={() => onOpenPoModal(index, item.id, item.poNumber)}
                              disabled={isDisabled}
                            >
                              แก้ไขเลขที่ PO
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <Row className='align-items-center'>
                        <Col sm={12}
                          lg={3}>
                          <Input label='เลขที่สัญญา จพ.(สบส.)'
                            placeholder='เลขที่สัญญา จพ.(สบส.)'
                            disabled
                            textCenter
                            value={item.contractNumber} />
                        </Col>
                        <Col sm={2}
                          className='pt-4'>
                          {(item.id) && (
                            <Button style={{ width: '120%' }}
                              className='me-2 px-3'
                              variant='outline-primary'
                              onClick={() => onOpenContractNumberModal(index, item.id, item.contractNumber)}
                              disabled={isDisabled}
                            >
                              แก้ไขเลขที่สัญญา จพ.(สบส.)
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <Row className='align-items-center'>
                        <Col sm={12}
                          lg={3}>
                          <Currency
                            label='ราคารวมที่ตกลง'
                            placeholder='ราคารวมที่ตกลง'
                            disabled
                            value={item.offerTotalAmountVat} />
                        </Col>
                      </Row>
                      <h5 className='mt-3'>ข้อมูลหลักประกันสัญญา</h5>
                      <Row className='align-items-center mt-3'>
                        <Col sm={12}
                          className='mb-2'>
                          <Radio
                            inline
                            dflex
                            items={InviteCollateralDetail}
                            rule={{ required: true }}
                            value={item.inviteCollateral !== undefined && item.inviteCollateral !== null ? item.inviteCollateral?.active.toString() : 'false'}
                            onChange={(val) => (onChangeCollateral(val.toString(), index))}
                            disabled={isDisabled}
                            name={(index + 1).toString()}
                          />
                        </Col>
                      </Row>
                      {
                        (item.inviteCollateral.active) && (
                          <Row>
                            <Col sm={12}
                              lg={3}>
                              <Currency
                                label='ร้อยละของราคาค่าจ้างตามสัญญา'
                                rule={{ required: item.inviteCollateral.active, pattern: ValidatePattern.PERCENTAGE }}
                                value={item?.inviteCollateral?.percent!}
                                onChange={(val) => (handleSumCollateralAmount(Number(val), item.offerTotalAmountVat, index))}
                                disabled={isDisabled}
                                maxValue={100}
                              />
                            </Col>
                            <Col sm={12}
                              lg={3}>
                              <Currency
                                label='จำนวนเงินหลักประกัน'
                                rule={{ required: item.inviteCollateral.active }}
                                value={item?.inviteCollateral?.amount!}
                                onChange={(val) => (handleSumCollateralPercent(Number(val), item.offerTotalAmountVat, index))}
                                disabled={isDisabled}
                              />
                            </Col>
                          </Row>
                        )
                      }
                      <h5 className='mt-3'>ข้อมูลติดต่อผู้จัดทำสัญญา</h5>
                      <Row className='align-items-center mt-3'>
                        <Col sm={12}
                          lg={3}>
                          <Input label='ผู้ติดต่อ'
                            value={item.contactUserName}
                            disabled={true}
                          />
                        </Col>
                        <Col sm={12}
                          lg={3}>
                          <Input label='โทรศัพท์'
                            placeholder='โทรศัพท์'
                            rule={{ required: true }}
                            value={item.contactUserTel}
                            onChange={(val) => handlerOnChangeContactUserTel(val, index)}
                            disabled={isDisabled}
                          />
                        </Col>
                        <Col sm={2}
                          className='pt-4'>
                          <Button
                            variant='outline-primary'
                            disabled={isDisabled}
                            className='me-2 px-3'
                            onClick={() => onOpenContactModal(index)}
                          > เปลี่ยนแปลงผู้ติดต่อ
                          </Button>
                        </Col>
                      </Row>
                      <ModalEditPO
                        show={editPO[index]}
                        onHide={() => onHidePoModal(index)}
                        id={caId}
                        oldPoNumber={oldPo}
                        onChangePo={(newPoNumber) => onChangePo(newPoNumber, index)}
                      />
                      <ModalEditContractNumber
                        show={editContractNumber[index]}
                        onHide={() => onHideContractNumberModal(index)}
                        id={caId}
                        oldNumber={oldContractNumber}
                        onChange={(newNumber) => onChangeContractNumber(newNumber, index)}
                      />
                      <SearchBuNameModal
                        total={userTotal}
                        show={showUserModals[index]}
                        onHide={() => onHideContactModal(index)}
                        onSelect={(id, name) => onSelectEmpSigned(id, name, index)}
                        data={users}
                        onSearch={onSearchUserAsync}
                        onPageChange={onPageChangeAsync}
                        departmentCode={departmentCode}
                      />
                      <div className='d-flex justify-content-center pt-5'>
                        {
                          (item.isResponsible && (data.status === InvitedContractStatus.DRAFT
                            || data.status === InvitedContractStatus.REJECTED
                            || data.status === InvitedContractStatus.NONE)) && (
                            <ButtonCustom
                              onClick={() => onSubmitAsync(index)}
                              text='บันทึก' />
                          )
                        }
                        <ButtonCustom
                          icon='send-mail'
                          variant='outline-primary'
                          text='ส่งเมลให้ทางบริษัทมาทำสัญญา'
                          disabled={!isSendMail}
                          onClick={() => onOpenSendEmailModal(index)}
                        />
                        <SendMailToEntrepreneurAsyncModal
                          title='ส่งเมลเชิญชวนทำสัญญา'
                          show={showSendEmail[index]}
                          onHide={() => onHideSendEmailModal(index)}
                          entrepreneurEmail={item.entrepreneur.email}
                          entrepreneurName={item.entrepreneur.name}
                          onData={(email) => onSubmitSendEmail(item.id, email)}
                        />
                      </div>
                    </div>
                  </Tab>
                ))
              }
            </Tabs>
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5'
          // lineBorder
          extraElement={(
            <div className='d-flex gap-2'>
              <Button variant="link" onClick={() => goToPage(data.planNumber)}>
                <TbListSearch className="fs-4" /> ไปยังเอกสารแนบทั้งหมด
              </Button>
            </div>
          )} />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.id!}
            />
          </Col>
        </Row>
      </Card>
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={data.id}
      />
      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          text='กลับหน้าแรก'
          onClick={onClickBack}
        />
        {/* {
          (data.status === InvitedContractStatus.DRAFT
            || data.status === InvitedContractStatus.REJECTED
            || data.status === InvitedContractStatus.NONE) && (
            <ButtonCustom
              onClick={onSubmitAsync}
              text='บันทึก' />
          )
        } */}
        {
          (data.status !== InvitedContractStatus.NONE)
            ? (
              <ButtonCustom
                onClick={onClickNext}
                text='ถัดไป' />
            )
            : <div style={{ width: '190px' }} />
        }
      </div>
      {/* <ModalEditPO
        show={editPO}
        onHide={() => setEditPO(!editPO)}
        id={data.id}
        oldPoNumber={data.poNumber}
        onChangePo={onChangePo}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showUserModals}
        onHide={() => setShowUserModals(!showUserModals)}
        onSelect={(id, name, department, position) => onSelectEmpSigned(id, name, position!, department!)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
      /> */}
    </div >
  );
}

export default Step1;
