import {
  InputTextArea,
  Modal,
} from 'components';
import { useState } from 'react';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { FaUndo } from 'react-icons/fa';

interface Props {
  show: boolean;
  onHide: () => void;
  onRemark?: (remark: string) => void;
}

export function EditModal(props: Props) {
  const [remark, setRemark] = useState('');

  const onSubmit = (remark: string) => {
    props.onRemark!(remark);
    props.onHide!();
    setRemark('');
  };

  return (
    <Modal
      show={props.show}
      size='lg'
      onHide={() => props.onHide()}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4'>
            <InputTextArea label='หมายเหตุ'
              rule={{ required: true }}
              value={remark}
              onChange={(value) => setRemark(value)} />
          </ModalBT.Body>
          <ModalBT.Footer className='justify-content-center'>
            <Button
              variant='light'
              onClick={() => props.onHide()}
              className='me-2 px-3'
            >
              ยกเลิก
            </Button>
            <Button
              variant='danger'
              className='me-2 px-3'
              onClick={() => onSubmit(remark)}
            >
              <FaUndo className='mx-2 pb-1 fs-4' />
              ส่งกลับแก้ไข
            </Button>
          </ModalBT.Footer>
        </>
      )}
    />
  );
}
