import { UserListModel } from 'models/accountModel';
import { DelegateRequestPaginationModel, DelegateResponseModel } from '../models/delegateModel';
import http from '../utils/axios';

const qs = require('qs');

const createDelegateAsync = async (ownerUserId: string, start: Date, end: Date, annotation: string, delegateUsers: UserListModel[]) => {
  const body = {
    ownerUserId,
    start,
    end,
    annotation,
    delegateUsers
  };

  return await http.post('/delegate', body);
};

const updateDelegateAsync = async (id: string, ownerUserId: string, start: Date, end: Date, annotation: string, delegateUsers: UserListModel[]) => {
  const body = {
    ownerUserId,
    start,
    end,
    annotation,
    delegateUsers
  };

  return await http.put(`/delegate/${id}`, body);
};

// const getDelegateAsync = async (ownerUserId: string, startDate: string, endDate: string) => {
//   const body = {
//     startDate,
//     endDate,
//   };
//   return await http.get(`/delegate/${ownerUserId}`, body);
// };

const getDelegateAsync = async (ownerUserId: string, startDate: string, endDate: string) => {

  const params = {
    startDate,
    endDate,
  };

  return await http.get(`/delegate/${ownerUserId}`, { params });
};

const getListDelegateAsync = async (
  page = 1,
  size = 10,
  departmentId?: string,
  ownerUserName?: string,
  ownerPosition?: string,
  delegateUserName?: string,
  delegatePosition?: string,
  start?: string,
  end?: string
) => {
  const params = {
    departmentId,
    ownerUserName,
    ownerPosition,
    delegateUserName,
    delegatePosition,
    start,
    end,
    page,
    size
  };
  return await http.get<DelegateRequestPaginationModel>('/delegate', { params });
};

const getDelegateReportAsync = async (departmentId?: string, ownerUserName?: string, ownerPosition?: string, delegateUserName?: string, delegatePosition?: string, start?: string, end?: string) => {
  const params = {
    departmentId,
    ownerUserName,
    ownerPosition,
    delegateUserName,
    delegatePosition,
    start,
    end
  };
  return await http.get('/delegate/report', {
    params,
    paramsSerializer: (params) => qs.stringify(params),
    responseType: 'blob'
  });
};

const deleteDelegateAsync = async (delegateId: string) => await http.delete(`/delegate/${delegateId}`);

export default {
  createDelegateAsync,
  getDelegateAsync,
  getListDelegateAsync,
  deleteDelegateAsync,
  updateDelegateAsync,
  getDelegateReportAsync
};
