import { RecordContract } from 'models';
import http from 'utils/axios';

const getListAsync = async (
  size: number,
  page: number,
  isPermission: boolean,
  traderTaxId?: string,
  traderName?: string,
  torNumber?: string,
  torName?: string,
  startDate?: Date,
  endDate?: Date
) => {
  return await http.get('/recordcontract', { params: { size, page, isPermission, traderTaxId, traderName, torNumber, torName, startDate, endDate } });
};

const getDetailAsync = async (id: string) => {
  return await http.get(`/recordcontract/${id}`);
};

const updateAsync = async (data: RecordContract) => {
  return await http.put(`/recordcontract/${data.id}`, data);
};

export default {
  getListAsync,
  getDetailAsync,
  updateAsync,
}

