import {
  useEffect,
  useState,
} from 'react';
import Badge from 'react-bootstrap/Badge';
import { StatusEditType } from './Status';

interface Props {
  value: string;
  editType?: StatusEditType;
}

const STATUS = [
  { value: 'Draft', color: '#B7B7B7', label: 'แบบร่าง' },
  { value: 'Pending', color: '#FBBD01', label: 'รอเห็นชอบ/อนุมัติ' },
  { value: 'Approved', color: '#3CB371', label: 'เห็นชอบ/อนุมัติ' },
  { value: 'Rejected', color: '#E94F45', label: 'ส่งกลับแก้ไข' },
  { value: 'Cancelled', color: '#E94F45', label: 'ยกเลิก' },
  { value: 'Success', color: '#3CB371', label: 'มอบหมายแล้ว' },
];

const STATUS_CANCEL = [
  { value: 'Draft', color: '#B7B7B7', label: 'แบบร่างขอยกเลิก' },
  { value: 'Pending', color: '#FBBD01', label: 'รอเห็นชอบ/อนุมัติขอยกเลิก' },
  { value: 'Approved', color: '#3CB371', label: 'เห็นชอบ/อนุมัติขอยกเลิก' },
  { value: 'Rejected', color: '#E94F45', label: 'ส่งกลับแก้ไข' },
  { value: 'Cancelled', color: '#E94F45', label: 'ยกเลิก' },
  { value: 'Success', color: '#3CB371', label: 'มอบหมายแล้ว' },
];

const STATUS_EDIT = [
  { value: 'Draft', color: '#B7B7B7', label: 'แบบร่างขอแก้ไข' },
  { value: 'Pending', color: '#FBBD01', label: 'รอเห็นชอบ/อนุมัติขอแก้ไข' },
  { value: 'Approved', color: '#3CB371', label: 'เห็นชอบ/อนุมัติขอแก้ไข' },
  { value: 'Rejected', color: '#E94F45', label: 'ส่งกลับแก้ไข' },
  { value: 'Cancelled', color: '#E94F45', label: 'ยกเลิก' },
  { value: 'Success', color: '#3CB371', label: 'มอบหมายแล้ว' },
];

const STATUS_DETAIL = [
  { value: 'Draft', color: 'secondary', label: 'ร่างเห็นชอบ/อนุมัติ' },
  { value: 'Pending', color: 'warning', label: 'รอเห็นชอบ/อนุมัติ' },
  { value: 'Approved', color: 'success', label: 'เห็นชอบ/อนุมัติ' },
  { value: 'Rejected', color: 'danger', label: 'ส่งกลับแก้ไข' },
];

const STATUS_DETAIL_APPROVED = [
  { value: 'Draft', color: 'secondary', label: 'ร่างเห็นชอบ/อนุมัติ' },
  { value: 'Pending', color: 'warning', label: 'รอเห็นชอบ/อนุมัติ' },
  { value: 'Approved', color: 'success', label: 'อนุมัติ' },
  { value: 'Rejected', color: 'danger', label: 'ส่งกลับแก้ไข' },
];

function DirectiveStatus(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      let status = STATUS.find((s) => s.value === props.value);

      if (status) {

        if (props.editType) {
          status = {
            [StatusEditType.IsChanged]: STATUS_EDIT.find((s) => s.value === props.value),
            [StatusEditType.IsCancel]: STATUS_CANCEL.find((s) => s.value === props.value),
          }[props.editType];
        }

        setStatus(status);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <div className="directive-status" style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

function DirectiveStatusDetail(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS_DETAIL.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color} className="text-white">
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}


function DirectiveStatusDetailApproved(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = STATUS_DETAIL_APPROVED.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color} className="text-white">
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}

export { DirectiveStatusDetail, DirectiveStatus, DirectiveStatusDetailApproved };
