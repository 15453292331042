import { Table } from 'components';
import Title from 'components/Controls/Title';
import { fullDatetime } from 'utils';
import { generateUniqueId } from '../../utils/helper';
import { Modal } from './Modal';

interface Props {
  show: boolean;
  onHide?: () => void;
  data: any[];
  title?: string;
}

export function HistoryModal(props: Props) {
  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size='xl'
    >
      <Modal.Header>
        <Title
          text={props.title ?? 'ประวัติการใช้งานจัดซื้อจัดจ้าง'}
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Table hidePagination>
        <thead>
          <tr>
            <th style={{ minWidth: 150 }}>วันที่/เวลา</th>
            <th style={{ minWidth: 150 }}>ผู้ดำเนินการ</th>
            <th style={{ minWidth: 150 }}>การดำเนินการ</th>
            <th style={{ minWidth: 150 }}>สถานะการดำเนินการ</th>
            <th style={{ minWidth: 150 }}>หมายเหตุ</th>
          </tr>
        </thead>
        <tbody>
          {props?.data?.map((a, i) => (
            <tr key={generateUniqueId(i)}>
              <td className='text-center'>{fullDatetime(a.activityDate)}</td>
              <td>{a.fullNameAction}</td>
              <td>{a.activity}</td>
              <td>{a.action}</td>
              <td>{a.remark}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  );
}
