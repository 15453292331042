import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAppContext } from 'utils';
import { PlanChangeOrAmendType } from './Status';

interface Props {
  value: string;
  planType?: string;
}

const ANNOUNCEMENTSTATUS = [
  {
    value: 'Draft',
    color: '#BEBEBE',
    label: 'แบบร่าง',
  },
  {
    value: 'AssignJorPor',
    color: '#5946ff',
    label: 'ผอ.จพ. มอบหมาย',
  },
  {
    value: 'WaitingAccept',
    color: '#FBBD01',
    label: 'รอให้ความเห็นชอบ',
  },
  {
    value: 'Accepted',
    color: '#228B22',
    label: 'เห็นชอบ',
  },
  {
    value: 'Reject',
    color: '#FF0000',
    label: 'ส่งกลับแก้ไข',
  },
];

export function PlanStatus(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();
  const { positionCode } = useAppContext();

  const isMD = useMemo(() => {
    return positionCode === 'MD';
  }, [positionCode]);

  const statusList = useMemo(() => [
    {
      value: 'DraftPlan',
      color: '#b7b7b7',
      label:
        props.planType === PlanChangeOrAmendType.IsChanged
          ? 'แบบร่างขอเปลี่ยนแปลง'
          : props.planType === PlanChangeOrAmendType.IsCancel
            ? 'แบบร่างขอยกเลิก'
            : 'แบบร่าง',
    },
    {
      value: 'RejectPlan',
      color: '#dc3546',
      label: 'ส่งกลับแก้ไข',
    },
    {
      value: 'WaitingApprovePlan',
      color: '#FBBD01',
      label: isMD ? 'รอเห็นชอบ/รออนุมัติ' : 'รอเห็นชอบ',
    },
    {
      value: 'ApprovePlan',
      color: '#3db371',
      label: isMD ? 'อนุมัติ' : 'เห็นชอบ',
    },
    {
      value: 'Assigned',
      color: '#ffa954',
      label: 'ผอ.จพ.มอบหมาย',
    },
    {
      value: 'DraftRecordDocument',
      color: '#ff850c',
      label: 'จัดทำร่างเอกสาร',
    },
    {
      value: 'WaitingAcceptor',
      color: '#FBBD01',
      label: isMD ? 'รอเห็นชอบ/รออนุมัติ' : 'รอเห็นชอบ',
    },
    {
      value: 'ApproveAcceptor',
      color: '#3db371',
      label: isMD ? 'เห็นชอบ/อนุมัติ' : 'เห็นชอบ',
    },
    {
      value: 'Announcement',
      color: '#FF8C00',
      label: 'เผยแพร่ประกาศแผน',
    },

    {
      value: 'Draft',
      color: '#b7b7b7',
      label: 'ร่างขออนุมัติเผยแพร่แผน',
    },
    {
      value: 'AssignJorPor',
      color: '#ffa954',
      label: 'มอบหมายงาน จพ.',
    },
    {
      value: 'WaitingAccept',
      color: '#FBBD01',
      label: isMD ? 'รอเห็นชอบ/รออนุมัติ' : 'รอเห็นชอบ',
    },
    {
      value: 'Accepted',
      color: '#3db371',
      label: isMD ? 'เห็นชอบ/อนุมัติ' : 'เห็นชอบ',
    },
    {
      value: 'Reject',
      color: '#ff5c00',
      label: 'ส่งกลับแก้ไข',
    },
    {
      value: 'CancelPlan',
      color: '#dc3546',
      label: 'ยกเลิกรายการจัดซื้อจัดจ้าง',
    },
  ], [isMD]);

  useEffect(() => {
    if (props.value) {
      const status = statusList.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props]);

  return (
    <>
      {props.value
        ? (
          <div className='plan-status'
            style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

export function AnnouncementPlanStatus(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const status = ANNOUNCEMENTSTATUS.find((s) => s.value === props.value);

      if (status) {
        setStatus(status);
      }
    }
  }, [props]);

  return (
    <>
      {props.value
        ? (
          <div className='plan-status'
            style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}
