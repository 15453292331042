import {
  Col,
  Row,
} from 'react-bootstrap';
import { TORDetail } from '..';

export default function TorDetail() {
  return (
    <>
      <Row>
        <Col>
          {/* <Breadcrumb data={breadcrumbInfo} /> */}
        </Col>
      </Row>
      <TORDetail />
    </>
  );
}
