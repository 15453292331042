import { PlanType, ProgressBarRefType } from 'components';
import StepProgress from 'components/StepProgress';
import { PlanProcurement } from 'models';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { planProcurement as planProcurementService } from 'services';
import {
  HttpStatusCode,
  useAppContext,
} from 'utils';
import { PlanStatus as PlanStatusCons, PlanTypeText } from 'utils/constants/PlanEnum';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';

type PlanProcurementDetailContext = {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  planProcurement: PlanProcurement;
  setPlanProcurement: Dispatch<SetStateAction<PlanProcurement>>;
  checkOverLimitPrice: () => void;
  overLimitPrice: boolean | undefined;
  reGetDetailAsync: (id: string) => Promise<PlanProcurement>;
  limitPrice: number;
  readonly: boolean;
  isCancelled: boolean;
};

export const Context = createContext({} as PlanProcurementDetailContext);

const LIMIT_PRICE = 500000;

type Loader = {
  planProcurement: PlanProcurement;
}

export default function Procurement() {
  const { userId, tel, departmentId } = useAppContext();
  const loader = useLoaderData() as Loader;
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [stepStatus, setStepStatus] = useState<number>(0);
  const stepRef = useRef<ProgressBarRefType>({} as ProgressBarRefType);
  const [planProcurement, setPlanProcurement] = useState<PlanProcurement>({} as PlanProcurement);
  const [overLimitPrice, setOverLimitPrice] = useState<boolean>();
  const location = useLocation();

  // find step
  useEffect(() => {
    if (userId && Object.keys(planProcurement).length) {
      findStepFromStatus(planProcurement.status);

      if (planProcurement.budget > LIMIT_PRICE) {
        switch (planProcurement.status) {
          case PlanStatusCons.DraftPlan:
            setCurrentStep(1);
            return;
          case PlanStatusCons.RejectPlan:
            setCurrentStep(1);
            return;
          case PlanStatusCons.WaitingApprovePlan:
            setCurrentStep(2);
            return;
          case PlanStatusCons.Assigned:
            setCurrentStep(3);
            return;
          case PlanStatusCons.DraftRecordDocument:
            setCurrentStep(4);
            return;
          case PlanStatusCons.WaitingAcceptor:
            setCurrentStep(5);
            return;
          case PlanStatusCons.Announcement:
            setCurrentStep(5);
            return;
          case PlanStatusCons.CancelPlan:
            setCurrentStep(5);
            return;
        }
      } else {
        switch (planProcurement.status) {
          case PlanStatusCons.DraftPlan:
            setCurrentStep(1);
            return;
          case PlanStatusCons.RejectPlan:
            setCurrentStep(1);
            return;
          case PlanStatusCons.WaitingApprovePlan:
            setCurrentStep(2);
            return;
          case PlanStatusCons.ApprovePlan:
            setCurrentStep(3);
            return;
          case PlanStatusCons.Announcement:
            setCurrentStep(3);
            return;
          case PlanStatusCons.CancelPlan:
            setCurrentStep(3);
            return;
        }
      }
    }
  }, [userId, planProcurement, currentStep]);

  useEffect(() => {
    if (loader.planProcurement) {
      setPlanProcurement(loader.planProcurement);

      if (loader.planProcurement.budget > LIMIT_PRICE) {
        setOverLimitPrice(true);

        return;
      }

      setOverLimitPrice(false);
    } else {
      const date = new Date();
      const budgetYear = (date.getUTCFullYear() + 543);
      // if (date.getMonth() + 1 >= 10) {
      //   budgetYear = (date.getUTCFullYear() + 544);
      // }

      setPlanProcurement({
        ...planProcurement,
        status: PlanStatusCons.DraftPlan,
        budgetYear,
        tel,
        departmentId,
      });
    }
  }, [loader, tel, overLimitPrice]);

  const findStepFromStatus = (status: string) => {
    if (planProcurement.budget > LIMIT_PRICE) {
      switch (status) {
        case PlanStatusCons.DraftPlan:
          setStepStatus(1);
          return;
        case PlanStatusCons.RejectPlan:
          setStepStatus(1);
          return;
        case PlanStatusCons.WaitingApprovePlan:
          setStepStatus(2);
          return;
        case PlanStatusCons.Assigned:
          setStepStatus(3);
          return;
        case PlanStatusCons.DraftRecordDocument:
          setStepStatus(4);
          return;
        case PlanStatusCons.WaitingAcceptor:
          setStepStatus(5);
          return;
        case PlanStatusCons.Announcement:
          setStepStatus(5);
          return;
        case PlanStatusCons.CancelPlan:
          setCurrentStep(5);
          return;
      }
    } else {
      switch (status) {
        case PlanStatusCons.DraftPlan:
          setStepStatus(1);
          return;
        case PlanStatusCons.RejectPlan:
          setStepStatus(1);
          return;
        case PlanStatusCons.WaitingApprovePlan:
          setStepStatus(2);
          return;
        case PlanStatusCons.ApprovePlan:
          setStepStatus(3);
          return;
        case PlanStatusCons.Announcement:
          setStepStatus(3);
          return;
        case PlanStatusCons.CancelPlan:
          setCurrentStep(3);
          return;
      }
    }
  };

  const defaultSteps = [
    {
      title: <p>รายการจัดซื้อจัดจ้าง</p>,
      displayIndex: '1',
    },
    {
      title: <p>ขั้นตอนถัดไป</p>,
      displayIndex: '?',
    },
  ];

  const threeSteps = [
    {
      title: <p>รายการจัดซื้อจัดจ้าง</p>,
      displayIndex: '1',
    },
    {
      title: <p>ผู้อำนวยการฝ่ายเห็นชอบ</p>,
      displayIndex: '2',
    },
    {
      title: <p>ผอ.จพ. รับทราบ</p>,
      displayIndex: '3',
    },
  ];

  const fiveSteps = [
    {
      title: <p>รายการจัดซื้อจัดจ้าง</p>,
      displayIndex: '1',
    },
    {
      title: <p>ผู้อำนวยการฝ่ายเห็นชอบ</p>,
      displayIndex: '2',
    },
    {
      title: <p>ผอ.จพ. เห็นชอบ</p>,
      displayIndex: '3',
    },
    {
      title: <p>ผู้มีอำนาจเห็นชอบ/อนุมัติ</p>,
      displayIndex: '4',
    },
    {
      title: <p>จัดทำเอกสาร/เห็นชอบ/อนุมัติ</p>,
      displayIndex: '5',
    },
  ];

  const backToIndex = () => {
    if (planProcurement.isRefCancel) {
      navigate('/procurement-plan-cancel');
    } else if (planProcurement.isRefChange) {
      navigate('/procurement-plan-adjust');
    } else if (planProcurement.type === PlanType.AnnualPlan) {
      navigate('/procurement-plan-summary');
    } else {
      navigate('/procurement-plan-inyear');
    }
  };

  const checkOverLimitPrice = () => {
    if (planProcurement.budget > LIMIT_PRICE) {
      setOverLimitPrice(true);

      return;
    }

    setOverLimitPrice(false);
  };

  const progressBar = useCallback(() => {
    if (overLimitPrice && currentStep) {
      return (
        <StepProgress
          progressBarData={fiveSteps}
          ref={stepRef}
          step={stepStatus}
        />
      );
    }
    if (overLimitPrice === false) {
      return (
        <StepProgress
          progressBarData={threeSteps}
          ref={stepRef}
          step={stepStatus}
        />
      );
    }
    return (
      <StepProgress
        progressBarData={defaultSteps}
        ref={stepRef}
        step={stepStatus}
      />
    );
  }, [overLimitPrice, currentStep, stepStatus]);

  const reGetDetailAsync = async (id: string) => {
    const data = await getPlanProcurementAsync(id);

    return data as PlanProcurement;
  };

  const getPlanProcurementAsync = async (id: string) => {
    const res = await planProcurementService
      .getDetailAsync(id);

    if (res.status === HttpStatusCode.OK) {
      setPlanProcurement(res.data.planProcurement);
      findStepFromStatus(res.data.planProcurement.status);

      return res.data.planProcurement;
    }

    return null;
  };

  const readonly = useMemo(() =>
    planProcurement.status === PlanStatusCons.WaitingApprovePlan
    || planProcurement.status === PlanStatusCons.ApprovePlan
    || planProcurement.status === PlanStatusCons.WaitingAcceptor
    || planProcurement.status === PlanStatusCons.ApproveAcceptor,
    [planProcurement]);

  const isCancelled = useMemo(() => planProcurement.isRefCancel, [planProcurement]);

  const contextValue = {
    currentStep,
    setCurrentStep,
    planProcurement,
    setPlanProcurement,
    checkOverLimitPrice,
    overLimitPrice,
    reGetDetailAsync,
    limitPrice: LIMIT_PRICE,
    readonly,
    isCancelled,
  };

  const programName = useMemo(() => {
    const path = location.pathname;

    if (path.includes('procurement-plan-inyear')) {
      return 'รายการจัดซื้อจัดจ้าง (ระหว่างปี)';
    }

    if (path.includes('procurement-plan-adjust/annual')) {
      return 'เปลี่ยนแปลงรายการจัดซื้อจัดจ้าง (รวมปี)';
    }

    if (path.includes('procurement-plan-adjust/inyear')) {
      return 'เปลี่ยนแปลงรายการจัดซื้อจัดจ้าง (ระหว่างปี)';
    }

    if (path.includes('procurement-plan-cancel/annual')) {
      return 'ยกเลิกรายการจัดซื้อจัดจ้าง (รวมปี)';
    }

    if (path.includes('procurement-plan-cancel/inyear')) {
      return 'ยกเลิกรายการจัดซื้อจัดจ้าง (ระหว่างปี)';
    }
  }, []);

  return (
    <Context.Provider value={contextValue}>
      <h4 className='text-primary'>{programName}</h4>
      {progressBar()}
      {currentStep >= 1
        ? (
          <Step1
            key={1}
            onClickBack={() => backToIndex()}
          />
        ) : null}
      {currentStep >= 2
        ? (
          <Step2
            key={2}
            onClickBack={() => backToIndex()}
          />
        ) : null}
      {currentStep >= 3
        ? (
          <Step3
            key={3}
            onClickBack={() => backToIndex()}
          />
        ) : null}
      {currentStep >= 4
        ? (
          <Step4
            key={4}
            onClickBack={() => backToIndex()}
          />
        ) : null}
      {currentStep === 5
        ? (
          <Step5
            key={5}
            onClickBack={() => backToIndex()}
          />
        ) : null}
    </Context.Provider>
  );
}
