import { DepartmentResponse, ItemModel } from 'models';
import { LoaderFunctionArgs } from 'react-router';
import { department, PP0205Service, shareValue, unitOfMeasure } from 'services';
import { SharedvalueGroup, SharedvalueId, SubGroup } from 'utils/constants/ContractEnum';

const PL0205Loader = async () => {
  const [departmentItems, supplyMethod] = await Promise.all([await department.getDepartmentsAsync(), shareValue.GetDDL('SupplyMethod')]);

  const departmentData: DepartmentResponse[] = departmentItems.data;
  const departmentList: ItemModel[] = departmentData.map((data) => ({
    label: data.name,
    value: data.id
  }));
  const SupplyMethodAll = [
    {
      label: 'ทั้งหมด',
      value: 'All'
    },
    ...supplyMethod
  ];

  return {
    departmentItems: departmentList,
    supplyMethodDDL: SupplyMethodAll
  };
};

const GetUnitDDL = async (Type: string) => {
  const itemForMap: ItemModel[] = [];

  switch (Type) {
    case 'UnitOfMeasure': {
      const response = await unitOfMeasure.GetUnitOfMeasureAsync();
      const unitOfMeasureData: ItemModel[] = response.data;
      unitOfMeasureData.forEach((data) => itemForMap.push({ label: data.label, value: data.value ?? '' }));
      break;
    }
  }

  return itemForMap;
};

const GetGLAccountDDL = async () => {
  const glAccountitem: ItemModel[] = [];

  const response = await shareValue.geGLAccountSelectionAsync();
  const glAccountDataL: ItemModel[] = response.data;
  glAccountDataL.forEach((data) => glAccountitem.push({ label: data.label, value: data.value ?? '' }));

  return glAccountitem;
};

const PL0205DetailLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id, basicInfoId } = params;

  const unitOfMeasureItems = await GetUnitDDL('UnitOfMeasure');

  // if (id) {
  const [supplyMethod, supplyMethodType, positionOnBoardList, positionList, departmentList, criteriaConsideration, periodUnitDDL, glAccountitem, positionOnBoardProcurementList, positionOnBoardInSpectionList, positionOnBoardMAList, positionOnBoardSupervisorList, deliveryCountUnitItems, MedianPriceConsiderInformation, MaintenancePeriodType, jorPorDirectorPosition] = await Promise.all([
    shareValue.GetDDL('SupplyMethod'),
    shareValue.GetDDL('SupplyMethodType'),
    shareValue.getPositionOnBoardListAsync(),
    shareValue.GetDDL('Position'),
    shareValue.GetDDL('Department'),
    // PP0205Service.getBasicInfoDetailAsync(id, basicInfoId),
    shareValue.GetDDL('CriteriaConsideration'),
    shareValue.GetDDL(SharedvalueGroup.PeriodType),
    GetGLAccountDDL(),
    shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardProcurement),
    shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardInSpection),
    shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardMA),
    shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardSupervisor),
    shareValue.getListSharedValueAsync(SharedvalueGroup.DeliveryCountUnit),
    shareValue.GetDDL(SharedvalueGroup.MedianPriceConsiderInformation),
    shareValue.GetDDL(SharedvalueGroup.MaintenancePeriodType),
    shareValue.getSingleSharedValueAsync(SharedvalueId.jorPorDirectorPositionId),
  ]);

  let result = {};

  if (id || basicInfoId) {

    const basicInfoDetail = await GetBasicInfoDetailAsync(id, basicInfoId);

    result = {
      supplyMethodItems: supplyMethod,
      supplyMethodTypeItems: supplyMethodType,
      positionOnBoardItems: positionOnBoardList.data,
      postionItems: positionList,
      departmentItems: departmentList,
      torDetail: basicInfoDetail != null ? basicInfoDetail.data.data.tor : undefined,
      basicInfoDetail: basicInfoDetail != null ? basicInfoDetail.data.data.basicInformation : undefined,
      isAcceptorLoader: basicInfoDetail != null ? basicInfoDetail.data.data.isAcceptor : undefined,
      isQueueLoader: basicInfoDetail != null ? basicInfoDetail.data.data.isQueue : undefined,
      isCreatorLoader: basicInfoDetail != null ? basicInfoDetail.data.data.isCreator : undefined,
      acceptorIdLoader: basicInfoDetail != null ? basicInfoDetail.data.data.acceptorId : undefined,
      medianPriceConsiderationSelectLoader: basicInfoDetail != null ? basicInfoDetail.data.data.medianPriceConsiderationSelect : undefined,
      criteriaConsiderations: criteriaConsideration,
      periodUnitDDL: periodUnitDDL,
      unitOfMeasureDDL: unitOfMeasureItems,
      glAccountDDL: glAccountitem,
      positionOnBoardProcurementDDL: positionOnBoardProcurementList.data,
      positionOnBoardInSpectionDDL: positionOnBoardInSpectionList.data,
      positionOnBoardMADDL: positionOnBoardMAList.data,
      positionOnBoardSupervisorDDL: positionOnBoardSupervisorList.data,
      deliveryCountUnitDDL: deliveryCountUnitItems.data,
      MedianPriceConsiderInformationDDL: MedianPriceConsiderInformation,
      maintenancePeriodTypeDDL: MaintenancePeriodType,
      jorPorDirectorPosition: jorPorDirectorPosition.data,
    };
  } else {

    result = {
      supplyMethodItems: supplyMethod,
      supplyMethodTypeItems: supplyMethodType,
      positionOnBoardItems: positionOnBoardList.data,
      postionItems: positionList,
      departmentItems: departmentList,
      criteriaConsiderations: criteriaConsideration,
      periodUnitDDL: periodUnitDDL,
      unitOfMeasureDDL: unitOfMeasureItems,
      glAccountDDL: glAccountitem,
      positionOnBoardProcurementDDL: positionOnBoardProcurementList.data,
      positionOnBoardInSpectionDDL: positionOnBoardInSpectionList.data,
      positionOnBoardMADDL: positionOnBoardMAList.data,
      positionOnBoardSupervisorDDL: positionOnBoardSupervisorList.data,
      deliveryCountUnitDDL: deliveryCountUnitItems.data,
      MedianPriceConsiderInformationDDL: MedianPriceConsiderInformation,
      maintenancePeriodTypeDDL: MaintenancePeriodType,
      jorPorDirectorPosition: jorPorDirectorPosition.data,
    };
  }

  return result;

  // }
};

const GetBasicInfoDetailAsync = async (id?: string, basicInfoId?: string,) => {

  let res: any = {};

  if (id || basicInfoId) {
    res = PP0205Service.getBasicInfoDetailAsync(id, basicInfoId);
  }

  return res;
};

export { PL0205Loader, PL0205DetailLoader };
