import {
  AnnouncementProcessStatus,
  Breadcrumb,
  ProcessStatus,
  Modal,
} from 'components';
import StepProgress from 'components/StepProgress';
import {
  AcceptorBasicInformation,
  AcceptorModel,
  Activities,
  Assigns,
  BasicInfoCreate,
  Committees,
  CommitteeUser,
} from 'models/PP';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Modal as ModalBT, } from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { PP0205Service, account } from 'services';
import {
  downloadFile,
  HttpStatusCode,
  ProcurementSection,
  showConfirmModalAsync,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import { JorporDirectorBeInformed, sharedValueResponse } from 'models';

type loader = {
  basicInfoDetail: BasicInfoCreate,
  torDetail: BasicInfoCreate,
  isAcceptorLoader: boolean,
  isQueueLoader: boolean,
  isCreatorLoader: boolean,
  acceptorIdLoader: string,
  medianPriceConsiderationSelectLoader: string,
  jorPorDirectorPosition: sharedValueResponse,
};

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

interface CreateContext {
  basicInfoCreate: BasicInfoCreate;
  activitiesData: activity[];
  disable: boolean;
  isJorPorHeader: boolean;
  getBasicInfoDetailAsync: Function;
  downloadFileAsync: Function;
  removeFile: Function;
  isJorPor: boolean;
  setBasicInfoCreate: Dispatch<SetStateAction<BasicInfoCreate>>;
  handleOnRecallBasicInfo: Function;
  onRecall: Function;
  isUploadFile: boolean;
  isAcceptor: boolean;
  isQueue: boolean;
  isCreator: boolean;
  acceptorId: string;
  medianPriceConsiderationSelect: string;
}

interface activity {
  id: string;
  activityDate: Date;
  fullNameAction: string;
  activity: string;
  action: string;
  remark: string;
}

enum ModalType {
  PROCUREMENT = 'Procurement',
  INSPECT = 'Inspect',
  MA = 'MA',
  SUPERVISOR = 'Supervisor',
}

const BREADCRUMB_INFO = [
  {
    routerName: '/pp/pp0205/', label: 'การแจ้งข้อมูลเบื้องต้น (จพ.004)',
  },
  {
    routerName: '', label: 'รายละเอียดการแจ้งข้อมูลเบื้องต้น (จพ.004)',
  },
];

export const Context = createContext({} as CreateContext);

export default function JorPor004Detail() {
  const { basicInfoDetail, torDetail, isAcceptorLoader, isCreatorLoader, isQueueLoader, acceptorIdLoader, medianPriceConsiderationSelectLoader, jorPorDirectorPosition } = useLoaderData() as loader;
  // const [isAcceptor, setIsAcceptor] = useState<boolean>(isAcceptorLoader);
  // const [isCreator, setIsCreator] = useState<boolean>(isCreatorLoader);
  // const [isQueue, setIsQueue] = useState<boolean>(isQueueLoader);
  // const [acceptorId, setAcceptorId] = useState<string>(acceptorIdLoader);
  // const [medianPriceConsiderationSelect, setMedianPriceConsiderationSelect] = useState(medianPriceConsiderationSelectLoader);
  const [isAcceptor, setIsAcceptor] = useState<boolean>(false);
  const [isCreator, setIsCreator] = useState<boolean>(false);
  const [isQueue, setIsQueue] = useState<boolean>(false);
  const [acceptorId, setAcceptorId] = useState<string>('');
  const [medianPriceConsiderationSelect, setMedianPriceConsiderationSelect] = useState('');
  const { positionCode, departmentCode, userId } = useAppContext();
  const stepRef = useRef<ProgressBarRefType>({} as ProgressBarRefType);
  const [stepStatus, setStepStatus] = useState<number>(1);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [basicInfoCreate, setBasicInfoCreate] = useState<BasicInfoCreate>({} as BasicInfoCreate);
  const [activitiesData, setActivitiesData] = useState<activity[]>([]);
  const [disable, setDisable] = useState(false);
  const [isJorPorHeader, setJorPorHeader] = useState(false);
  const [isJorPor, setJorPor] = useState(false);
  const [isUploadFile, setUploadFile] = useState(true);
  const { id, basicInfoId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [jorporDirectorBeInformed, setJorporDirectorBeInformed] = useState<CommitteeUser[]>([]);
  const [showReCall, setShowReCall] = useState(false);

  useEffect(() => {
    setInitailValue()
  }, [torDetail, basicInfoDetail]);

  useEffect(() => {
    if (isAcceptorLoader) {
      setIsAcceptor(isAcceptorLoader)
    }

    if (isCreatorLoader) {
      setIsCreator(isCreatorLoader)
    }

    if (isQueueLoader) {
      setIsQueue(isQueueLoader)
    }

    if (acceptorIdLoader) {
      setAcceptorId(acceptorIdLoader)
    }

    if (medianPriceConsiderationSelectLoader) {
      setMedianPriceConsiderationSelect(medianPriceConsiderationSelectLoader)
    }

  }, [isAcceptorLoader, isCreatorLoader, isQueueLoader, acceptorIdLoader, medianPriceConsiderationSelectLoader]);

  useEffect(() => {
    if (basicInfoDetail
      && (basicInfoDetail.status === ProcessStatus.WAITING_ACCEPTOR || basicInfoDetail.status === ProcessStatus.ASSIGN_JORPOR)) {
      setStepStatus(2);
      setCurrentStep(2);

      return;
    }

    if (positionCode && basicInfoDetail) {
      checkUserIsJorPorHeader(positionCode);
    }

    if (departmentCode && basicInfoDetail) {
      checkUserJorPor(departmentCode, positionCode);
    }
  }, [positionCode, basicInfoDetail]);

  const getBasicInfoDetailAsync = async (id?: string, basicId?: string) => {
    if (id || basicId) {
      const { data, status } = await PP0205Service.getBasicInfoDetailAsync(id, basicId);

      if (status === HttpStatusCode.OK) {
        setIsAcceptor(data.data.isAcceptor);
        setIsCreator(data.data.isCreator);
        setIsQueue(data.data.isQueue);
        setAcceptorId(data.data.acceptorId);
        setMedianPriceConsiderationSelect(data.data.medianPriceConsiderationSelect);
        getDefaultUserAsync();
        setInitailValue(data.data.isCreator, data.data.basicInformation);
      }
    }
  };

  const getDefaultUserAsync = async () => {
    const [
      jorporDirectorBeInformedDefault,
    ] = await Promise.all([
      account.getDefaultDepartmentDirectorAgree(ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED),
    ]);

    const jorporDirectorBeInformed = jorporDirectorBeInformedDefault.data as JorporDirectorBeInformed[];
    setJorporDirectorBeInformed(jorporDirectorBeInformed);
  };

  const onRecall = async () => {
    setShowReCall(true);
  };

  const handleOnRecallBasicInfo = async () => {
    // if (!await showConfirmModalAsync('ต้องการเรียกคืนแก้ไขข้อมูล?')) {
    //   return;
    // }

    if (basicInfoId) {
      const response = await PP0205Service.updateBasicInfoAsync(basicInfoCreate, basicInfoId, AnnouncementProcessStatus.DRAFT);

      if (response.status === HttpStatusCode.ACCEPTED) {
        toast.success('เรียกคืนแก้ไขสำเร็จ');
        getBasicInfoDetailAsync(id, basicInfoId);
        navigate(`${location.pathname}`);
        setShowReCall(false);
        return;
      }
    }
  };

  const steps = [
    {
      title: <p>ข้อมูลการแจ้งข้อมูลเบื้องต้น
        (จพ.004)
      </p>,
      displayIndex: '1',
    },
    {
      title: <p>ผู้มีอำนาจเห็นชอบและ
        Assign งาน
      </p>,
      displayIndex: '2',
    },
    // {
    //   title: <p>ตัวอย่างเอกสาร
    //     การแจ้งข้อมูลเบื้องต้น (จพ.004)
    //   </p>,
    //   displayIndex: '3',
    // },
  ];

  const progressBar = useCallback(() => (
    <StepProgress
      progressBarData={steps}
      ref={stepRef}
      step={stepStatus}
    />
  ), [currentStep, stepStatus]);

  const downloadFileAsync = async (index: number, documentId?: string) => {
    if (basicInfoCreate.documentList && basicInfoCreate.documentList.length > 0) {
      if (documentId && basicInfoId) {
        const res = await PP0205Service
          .downloadFileAsync(basicInfoId, documentId);

        if (res.status === HttpStatusCode.OK) {
          const file = basicInfoCreate.documentList[index];

          downloadFile(res.data);
        }
      } else {
        const file = basicInfoCreate.documentList[index];

        if (file.file) {
          downloadFile(file.file);
        }
      }
    }
  };

  const removeFile = async (i: number, docId: string | undefined) => {
    if (basicInfoCreate.documentList && basicInfoCreate.documentList.length > 0) {
      if (docId && basicInfoId) {
        const res = await PP0205Service.removeFileAsync(basicInfoId, docId);

        if (res.status === HttpStatusCode.NO_CONTENT) {
          toast.success('ลบไฟล์สำเร็จ');

          getBasicInfoDetailAsync(basicInfoId);
        }
      }

      basicInfoCreate.documentList.splice(i, 1);

      setBasicInfoCreate({
        ...basicInfoCreate,
        documentList: [...basicInfoCreate.documentList],
      });
    }
  };

  const contextValue: CreateContext = {
    basicInfoCreate,
    activitiesData,
    disable,
    isJorPorHeader,
    getBasicInfoDetailAsync,
    downloadFileAsync,
    removeFile,
    isJorPor,
    setBasicInfoCreate,
    handleOnRecallBasicInfo,
    onRecall,
    isUploadFile,
    isAcceptor,
    isQueue,
    isCreator,
    acceptorId,
    medianPriceConsiderationSelect,
  };

  const clickNextAndBack = (step: number) => {
    if (basicInfoDetail) {
      if (basicInfoDetail.status !== ProcessStatus.DRAFT
        && basicInfoDetail.status !== ProcessStatus.REJECT) {
        setCurrentStep(step);
        setStepStatus(step);

        return;
      }
    }

    setCurrentStep(step);
    setStepStatus(step);
  };

  const backToIndex = () => {
    navigate('/pp/pp0205/');
  };

  const setInitailValue = (creator?: boolean, newData?: BasicInfoCreate) => {
    if (basicInfoDetail || newData) {
      const data: BasicInfoCreate = newData ? { ...newData } : { ...basicInfoDetail };

      if (data.activities && data.activities.length > 0) {
        convertActivityLog(data.activities);
      }

      data.torNumber = torDetail !== null ? torDetail?.torNumber : undefined;
      data.documentDate = torDetail != null ? torDetail.documentDate : data?.documentDate;
      data.correspondenceNumber = torDetail !== null ? torDetail!.correspondenceNumber : undefined;
      data.refPlanNumber = torDetail != null ? torDetail.refPlanNumber : data?.refPlanNumber;
      data.objectives = torDetail != null ? torDetail.objectives : data?.objectives;
      data.qualifications = torDetail != null ? torDetail.qualifications : undefined;
      data.financials = torDetail != null ? torDetail.financials : data?.financials;
      data.workPhases = torDetail != null ? torDetail.workPhases : data?.workPhases;
      data.fineRates = torDetail != null ? torDetail.fineRates : undefined;
      data.parcels = torDetail != null ? torDetail.parcels : data?.parcels;
      data.middlePriceSources = torDetail != null ? torDetail.middlePriceSources : undefined;
      data.budgetYear = torDetail != null ? torDetail.budgetYear : data?.budgetYear;
      data.deliveryPeriodSum = torDetail != null ? torDetail.deliveryPeriodSum : data?.deliveryPeriodSum;
      data.deliveryUnit = torDetail != null ? torDetail.deliveryUnit : data?.deliveryUnit;
      data.deliveryCountUnit = torDetail != null ? torDetail.deliveryCountUnit : data?.deliveryCountUnit;
      data.middlePrice = torDetail != null ? torDetail.middlePrice : undefined;
      data.middlePriceSetDate = torDetail != null ? torDetail.middlePriceSetDate : undefined;
      data.appointNumber = torDetail != null ? torDetail.appointNumber : data?.appointNumber;
      data.isMA = torDetail != null ? torDetail.isMA : data?.isMA;
      data.isSupervisor = torDetail != null ? torDetail.isSupervisor : data?.isSupervisor;
      data.paymentTermPeriods = torDetail != null ? torDetail.paymentTermPeriods : undefined;

      if (data.acceptors && data.acceptors.length > 0) {
        const assistantHeadOfDepartmentList = data.acceptors.filter(d => d.sectionCode === ProcurementSection.APPROVER)
          .sort((a, b) => a.sequence! - b.sequence!);

        data.acceptors = convertAcceptorArrayType(assistantHeadOfDepartmentList);
      }

      if (data.committees && data.committees.length > 0) {
        const precurement = data.committees.filter(d => d.committeeType === ModalType.PROCUREMENT);
        const inspects = data.committees.filter(d => d.committeeType === ModalType.INSPECT);
        const MA = data.committees.filter(d => d.committeeType === ModalType.MA);
        const supervisor = data.committees.filter(d => d.committeeType === ModalType.SUPERVISOR);

        data.committeeProcurementUser = convertCommitteeType(precurement);
        data.committeeInspectsUser = convertCommitteeType(inspects);
        data.committeeMAUser = convertCommitteeType(MA);
        data.committeeSupervisorUser = convertCommitteeType(supervisor);
      }

      if (data.assigns) {
        data.assignsLists = convertAssingsType(data.assigns);
      }

      if (data.documents && data.documents.length > 0) {
        const documentLists = data.documents.map(d => ({ id: d.id, name: d.fileName }));

        data.documentList = documentLists;
      }

      if (data.status === ProcessStatus.WAITING_ACCEPTOR
        || data.status === ProcessStatus.ASSIGN_JORPOR
        || data.status === ProcessStatus.ACCEPTED) {
        setDisable(true);
      } else {
        setDisable(false);
      }

      if (isAcceptor || isCreator) {
        setUploadFile(false);
      }

      setBasicInfoCreate(data);

      return;
    }

    if (torDetail) {
      const data: BasicInfoCreate = {
        ...torDetail,
      };

      data.status = 'noImplement';

      setBasicInfoCreate(data);

      return;
    }
  };

  const convertAcceptorArrayType = (data: AcceptorBasicInformation[]) => {
    if (data.length > 0) {
      const dataFilter: AcceptorModel[] = data.map(d => ({
        id: d.id,
        sectionCode: d.sectionCode!,
        userId: d.userId,
        fullName: d.fullName!,
        departmentName: d.departmentName!,
        positionName: d.positionName!,
        status: d.status,
        at: d.at,
        isAvailable: d.isAvailable,
        isAvailableRemark: d.availableRemark,
        acceptDate: d.approvedDateAt,
        acceptRemark: d.approvedRemark,
        rejectDate: d.rejectedDateAt,
        rejectRemark: d.rejectedRemark,
        sequence: d.sequence,
        sectionType: d.sectionType,
        sectionSequence: d.sectionSequence,
        sectionName: d.sectionName,
        delegateUserId: d.delegateUserId,
        delegateEmployeeCode: d.delegateEmployeeCode,
        delegateFullName: d.delegateFullName,
        delegateDepartmentName: d.delegateDepartmentName,
        delegatePositionName: d.delegatePositionName,
        delegateRangeDate: d.delegateRangeDate
      }));

      return dataFilter;
    }
  };

  const convertCommitteeType = (data: Committees[]) => {
    if (data.length > 0) {
      const dataFilter: CommitteeUser[] = data.map(d => ({
        id: d.id,
        committeeType: d.committeeType,
        userId: d.userId!,
        fullName: d.fullName,
        positionName: d.positionName,
        positionOnBoardId: d.positionOnBoardId,
      }));

      return dataFilter;
    }
  };

  const convertAssingsType = (data: Assigns[]) => {
    if (data.length > 0) {
      const dataFilter: CommitteeUser[] = data.map(d => ({
        id: d.id,
        userId: d.userId!,
        fullName: d.fullName,
        positionName: d.positionName,
        departmentName: d.departmentName,
        delegateUserId: d.delegateUserId,
        delegateEmployeeCode: d.delegateEmployeeCode,
        delegateFullName: d.delegateFullName,
        delegateDepartmentName: d.delegateDepartmentName,
        delegatePositionName: d.delegatePositionName,
        delegateRangeDate: d.delegateRangeDate,
        level: d.level,
        sectionCode: d.sectionCode,
      }));

      return dataFilter;
    }
  };

  const convertActivityLog = (data: Activities[]) => {
    const dataFormat: activity[] = data.map(data => ({
      id: data.id,
      action: data.action,
      activity: data.description,
      activityDate: data.date,
      fullNameAction: data.fullName,
      remark: data.remark,
    }));

    setActivitiesData(dataFormat);
  };

  const checkUserIsJorPorHeader = (code: string) => {
    if (code === jorPorDirectorPosition.sharedValue.value) {
      setJorPorHeader(true);
      setCurrentStep(2);
      setStepStatus(2);

      return;
    }
  };

  const checkUserJorPor = (code: string, positionId: string) => {

    if (basicInfoDetail && basicInfoDetail.status === ProcessStatus.ACCEPTED) {
      setJorPor(true);
      setCurrentStep(2);
      setStepStatus(2);

      return;
    }

    if (code === jorPorDirectorPosition.sharedValue.value && (basicInfoDetail && basicInfoDetail.status === ProcessStatus.ASSIGN_JORPOR) && positionId !== jorPorDirectorPosition.sharedValue.value) {
      setJorPor(true);
      setCurrentStep(2);
      setStepStatus(2);

      return;
    }
  };

  return (
    <div className='document'>
      <Context.Provider value={contextValue}>
        <h4 className='mt-2 text-primary'>
          <Button variant='link'
            onClick={backToIndex}>
            <MdOutlineArrowBack className='fs-4 text-primary mb-1' />
          </Button>
          การแจ้งข้อมูลเบื้องต้น (จพ.004)
        </h4>
        <Breadcrumb data={BREADCRUMB_INFO} />
        {progressBar()}
        {currentStep === 1
          ? (
            <Step1
              key={1}
              onClickBack={() => backToIndex()}
              onClickNext={() => clickNextAndBack(2)}
            />
          ) : null}
        {currentStep === 2
          ? (
            <Step2
              key={2}
              onClickBack={() => clickNextAndBack(1)}
              onClickNext={() => clickNextAndBack(3)}
            />
          ) : null}
        {/* {currentStep === 3
          ? (
            <Step3
              key={3}
              onClickBack={() => clickNextAndBack(2)}
            />
          ) : null} */}
      </Context.Provider>

      <Modal
        show={showReCall}
        size='lg'
        onHide={() => setShowReCall(!showReCall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowReCall(!showReCall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handleOnRecallBasicInfo()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}
