import { Breadcrumb } from 'components';
import StepProgress from 'components/StepProgress';
import {
  ChangePlanRequestCreate,
  ChangePlanRequestDetailResponse,
} from 'models';
import {
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useParams } from 'react-router';
import { changePlanRequest } from 'services';
import { HttpStatusCode } from 'utils';
import Approve from './Approve';
import PlanInformation from './PlanInformation';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

export const CreateDataContext = createContext({} as ChangePlanRequestCreate);

export default function PL0203Detail() {
  const [createData, setCreateData] = useState<ChangePlanRequestCreate>({} as ChangePlanRequestCreate);
  const [changePlanRequestDetailData, setChangePlanRequestDetailData] = useState<ChangePlanRequestDetailResponse>({} as ChangePlanRequestDetailResponse);
  const [isMounted, setIsMounted] = useState(false);
  const { id } = useParams();
  const childRef = useRef<ProgressBarRefType>(null);

  const BREADCRUMB_INFO = [
    {
      routerName: '/pl/pl0203', label: 'ขอเปลี่ยนแปลงแผนการจัดซื้อจัดจ้าง',
    },
    {
      routerName: '', label: 'รายละเอียดขอเปลี่ยนแปลงแผนการจัดซื้อจัดจ้าง',
    },
  ];

  const stepper = [{
    title: <p>ข้อมูลแผน</p>,
    displayIndex: '1',
    child: <PlanInformation onClickNext={() => clickNext()} sendCreateData={(value) => setCreateData(value)} changeRequestDatail={changePlanRequestDetailData} />,
  },
  {
    title: <p>อำนาจอนุมัติ</p>,
    displayIndex: '2',
    child: <Approve onClickBack={() => clickBack()} changeRequestDatail={changePlanRequestDetailData} />,
  }];

  const getChangePlanRequestDetailAsync = async (id: string) => {
    setIsMounted(false);

    const response = await changePlanRequest.getChangePlanRequestDetailAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setChangePlanRequestDetailData(response.data);
      setIsMounted(true);
    }
  };

  useEffect(() => {
    if (id) {
      getChangePlanRequestDetailAsync(id);
    }
  }, [id]);

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  return (
    <div className="document">
      <h4 className="mt-2 mb-0 text-primary text-start">รายละเอียดขอเปลี่ยนแปลงแผนการจัดซื้อจัดจ้าง</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12} className="mb-2">
          {isMounted && (
          <CreateDataContext.Provider value={createData}>
            <StepProgress progressBarData={stepper} ref={childRef} />
          </CreateDataContext.Provider>
          )}
        </Col>
      </Row>
    </div>
  );
}
