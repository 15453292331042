import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Currency,
  Input,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import { IndemnityModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';

interface IProps {
  indemnity: IndemnityModel;
  onPenaltyEnforcementChange: (value: IndemnityModel) => void;
  periodTypeDDL: ItemModel[];
  isDisabled?: boolean;
}

export function IndemnityDetail(props: IProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [indemnity, setIndemnity] = useState<IndemnityModel>(props.indemnity);

  useEffect(() => {
    if (props.indemnity && firstLoad) {
      setIndemnity(props.indemnity);
      setFirstLoad(false);
    }
  }, [props.indemnity, firstLoad]);

  useEffect(() => {
    props.onPenaltyEnforcementChange(indemnity);
  }, [indemnity]);

  const onTextChange = useCallback((key: string, value: string) => {
    setIndemnity((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setIndemnity((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  return (
    <Card className='mt-3'>
      <Title text='การบังคับค่าปรับ ค่าเสียหาย และค่าใช้จ่าย' className='fs-5 text-primary' />
      <Row className='mt-3'>
        <Col sm={3} md={3}>
          <Currency
            label='คู่ค้าจะชำระค่าปรับในอัตราร้อยละ'
            rule={{ required: true }}
            value={indemnity?.finePercent}
            onChange={(value) => onNumberChange('finePercent', Number(value))}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={2} md={2}>
          <Selector
            label='ค่าปรับราย'
            items={props.periodTypeDDL}
            rule={{ required: true }}
            value={indemnity?.fineUnit}
            onChange={(value) => onTextChange('fineUnit', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row>
        <Col sm={3} md={3}>
          <InputNumber
            label='คู่ค้าจะชดใช้ค่าปรับ ค่าเสียหาย และค่าใช้จ่าย ภายใน'
            value={indemnity?.periodDay}
            onChange={(value) => onNumberChange('periodDay', Number(value))}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={2} md={2}>
          <Selector
            className='no-label'
            items={props.periodTypeDDL}
            value={indemnity?.periodUnit}
            onChange={(value) => onTextChange('periodUnit', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row>
        <label className='mb-3'>หากบังคับจากค่าสิ่งของที่ต้องชำระหรือหลักประกันแล้วยังไม่เพียงพอ จะชำระส่วนที่เหลือภายใน</label>
        <Col sm={3} md={3}>
          <InputNumber
            value={indemnity?.remainPeriodDay}
            onChange={(value) => onNumberChange('remainPeriodDay', Number(value))}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={2} md={2}>
          <Selector
            items={props.periodTypeDDL}
            value={indemnity?.remainPeriodUnit}
            onChange={(value) => onTextChange('remainPeriodUnit', value)}
            disabled={props.isDisabled} />
        </Col>
      </Row>
    </Card>
  );
}
