import {
  announcingWinner,
  shareValue,
} from 'services';

export const announcingWinnerLoader = async () => {
  const [department, AnnouncingWinnerData, SupplyMethod] = await Promise.all([
    shareValue.GetDDL('Department'),
    announcingWinner.getAnnoucingWinnerListAsync(1, 10),
    shareValue.GetDDL('SupplyMethod'),
  ]);

  const SupplyMethodAll = [{ label: 'ทั้งหมด', value: 'All' }, ...SupplyMethod];

  return {
    departmentDDL: department,
    announcingWinnerLists: AnnouncingWinnerData.data.data,
    announcingWinnerListsTotal: AnnouncingWinnerData.data.totalRecords,
    supplyMethodDDL: SupplyMethodAll,
  };
};

export const announcingWinnerDetailLoader = async () => {
  const [department, SupplyMethod, SupplyMethodType] = await Promise.all([
    shareValue.GetDDL('Department'),
    shareValue.GetDDL('SupplyMethod'),
    shareValue.GetDDL('SupplyMethodType'),
  ]);

  const SupplyMethodAll = [{ label: 'ทั้งหมด', value: 'All' }, ...SupplyMethod];

  return {
    departmentDDL: department,
    supplyMethodDDL: SupplyMethodAll,
    supplyMethodTypeDDL: SupplyMethodType,
  };
};
