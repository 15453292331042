import {
  Card,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { SearchNameModal } from 'components/Modal';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { generateUniqueId } from '../../../../utils/helper';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="อำนาจอนุมัติ" className="fs-5 text-primary" />
        <Table total={1} className="mt-3">
          <thead>
            <tr>
              <th style={{ minWidth: 20 }}>ลำดับ</th>
              <th style={{ minWidth: 150 }}>รหัสพนักงาน</th>
              <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 150 }}>ตำแหน่ง</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 5 }).map((_, index) => (
              <tr className="text-center" key={generateUniqueId(index)}>
                <td>1</td>
                <td>5900010</td>
                <td>สมศรี แดนไทย</td>
                <td>รักษาการผู้อำนวยการสำนักงบประมาณ</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>

      <SearchNameModal
        show={show}
        onHide={() => setShow(!show)}
        data={[]}
      />
    </div>
  );
}

export default Step2;
