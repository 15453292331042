import {
  Card,
  DatePicker,
  Input,
  ListFile,
  Radio,
  Selector,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { StatusDeliver } from 'components/StatusDeliver';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

const INFO = [
  { label: 'ละเว้น', value: '1' },
  { label: 'ไม่ละเว้น', value: '2' },
];

function Step1({ onClickNext, onClickBack }: Props) {
  return (
    <div className="document">
      <Card className="mt-3">
        <Title text="ข้อมูลโครงการ" className="fs-5 text-primary" />
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={6}>
            <Input label="เลขที่เอกสาร PR" placeholder="เลขที่เอกสาร PR" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Input label="โครงการ" placeholder="โครงการ" disabled />
          </Col>
          <Col sm={12} md={3}>
            <Input label="งบประมาณโครงการ (บาท)" placeholder="1,000,000.00" disabled textEnd />
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={4} xl={3}>
            <Input label="วิธีจัดหา" value="80" disabled />
          </Col>
          <Col sm={12} md={4} className="pt-4">
            <Input value="เจาะจง" disabled />
          </Col>
          <Col sm={12} md={4}>
            <Input label="เลขที่ PR" value="1,000,000.00" disabled textCenter />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="ข้อมูลสัญญา" className="fs-5 text-primary" />
        <Row className="mt-3">
          <Col sm={12} md={6}>
            <Input label="คู่ค้า" placeholder="คู่ค้า" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Input label="ชื่อสัญญา" placeholder="ชื่อสัญญา" disabled />
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={3}>
            <Input label="เลขที่สัญญา จพ.(สบส.)" placeholder="เลขที่สัญญา จพ.(สบส.)" disabled textCenter />
          </Col>
          <Col sm={12} lg={3}>
            <DatePicker
              label="วันที่ลงนามในสัญญา"
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={3}>
            <Input label="วงเงินตามสัญญา" placeholder="วงเงินตามสัญญา" disabled textEnd />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} lg={3}>
            <Input label="กำหนดส่งมอบภายใน" placeholder="วงเงินตามสัญญา" disabled textEnd />
          </Col>
          <Col sm={12} lg={1} className="pt-4">
            <Selector disabled />
          </Col>
          <Col sm={12} lg={3}>
            <DatePicker label="ครบกำหนดส่งมอบวันที่" disabled />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col sm={12} lg={3}>
            <Input label="ระยะเวลารับประกัน" placeholder="ระยะเวลารับประกัน" disabled textEnd />
          </Col>
          <Col sm={12} lg={1} className="pt-4">
            <Selector disabled />
          </Col>
          <Col sm={12} lg={3}>
            <DatePicker label="ครบกำหนดรับประกันวันที่" disabled />
          </Col>
          <p>ค่าปรับกรณีส่งมอบเกินกำหนดโดยคิดค่าปรับเป็นรายวัน : <span>อัตราร้อยละ 0.2 ของราคาสิ่งของที่ยังไม่ได้รับมอบ</span></p>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="หลักประกัน" className="fs-5 text-primary" />
        <Row>
          <Col sm={12}>
            <Radio
              className="mt-3"
              inline
              items={INFO}
              label="จำนวนเงินหลักประกัน"
              rule={{ required: true }}
              name='name1'
            />
          </Col>
          <Col sm={12} lg={3}>
            <Selector label="ประเภทหลักประกันสัญญา" rule={{ required: true }} />
          </Col>
          <Col sm={12} lg={3}>
            <Input label="จำนวนเงินหลักประกัน" placeholder="จำนวนเงินหลักประกัน" rule={{ required: true }} textEnd />
          </Col>
          <Col sm={12} lg={3}>
            <Input label="ร้อยละของราคาค่าจ้างตามสัญญา" placeholder="ร้อยละของราคาค่าจ้างตามสัญญา" rule={{ required: true }} textEnd />
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={3}>
            <Selector label="ประเภทวันทำสัญญา" rule={{ required: true }} />
          </Col>
          <Col sm={12} lg={3}>
            <DatePicker label="วันที่" rule={{ required: true }} />
          </Col>
          <Col sm={12} lg={3}>
            <DatePicker label="ถึงวันที่" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={3}>
            <Selector label="ค่าปรับราย" rule={{ required: true }} />
          </Col>
          <Col sm={12} lg={3}>
            <Input label="ค่าปรับ (%)" rule={{ required: true }} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={3}>
            <Selector label="รูปแบบสัญญา" rule={{ required: true }} />
          </Col>
          <Col sm={12} lg={3}>
            <Selector label="เงื่อนไขการรับประกัน" rule={{ required: true }} />
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="การจ่ายเงิน" className="fs-5 text-primary" />
        <Row>
          <Col sm={12}>
            <Table>
              <thead>
                <tr className="text-center">
                  <th>งวดที่</th>
                  <th>เปอร์เซ็นต์ %</th>
                  <th>จำนวนเงิน</th>
                  <th>ระยะเวลา(วัน)</th>
                  <th>จำนวนเงินค่าปรับ</th>
                  <th>จำนวนเงินคงเหลือ</th>
                  <th>สถานะการส่งมอบ</th>
                  <th>หมายเหตุ</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>1</td>
                  <td>10</td>
                  <td>100,000.00</td>
                  <td>15</td>
                  <td> - </td>
                  <td>100,000.00</td>
                  <td><StatusDeliver Istatus="warning" /> </td>
                  <td>
                    <Button variant="outline-primary" className="px-3">ตรวจรับ</Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className="mt-3">
        <Title text="เอกสารแนบ" className="fs-5 text-primary" />
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <ListFile InameFile="อัตราค่าใช้จ่ายอื่นๆ ปี 65/2.doc" IsizeFile="123KB" />
          </Col>
        </Row>
      </Card>

      <div className="d-flex justify-content-between pt-3">
        <Button
          className="me-2 px-3"
          onClick={onClickBack}
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
    </div>
  );
}

export default Step1;
