import {
  onActionAnnoucementModel,
  PublicPlanProcurement,
} from 'models';
import { PlanProcurementAnnouncementCriteria } from 'models/planAnnouncement';
import qs from 'qs';
import http from 'utils/axios';
import {
  AcceptorStatus,
  AnnouncementStatus as AnnouncementCons,
  PlanStatus,
  PlanTypeText,
} from 'utils/constants/PlanEnum';

const getPlanListAsync = async (budgetYear: number, supplyMethod: string, planProcurementAnnouncementId?: string) => {
  const params = {
    budgetYear,
    supplyMethod,
    status: PlanStatus.ApprovePlan,
    budget: 500001,
    type: PlanTypeText.AnnualPlan,
    planProcurementAnnouncementId: planProcurementAnnouncementId
  };

  return await http.get('/planprocurement/planacceptor', { params });
};

const createAnnouncementAsync = async (data: PublicPlanProcurement) => {
  data.authority = [];
  data.planProcurementAnnouncementSelected = [];

  if (data.jorporDirectorAssign && data.jorporDirectorAssign.length) {
    data.jorporDirectorAssign.forEach((item) =>
      data.authority.push({
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: null,
        isAvailableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        level: item.level,
      })
    );
  }
  if (data.planProcurements && data.planProcurements.length) {
    data.planProcurements.forEach((item) => {
      data.planProcurementAnnouncementSelected.push({
        planId: item.id,
        egpNumber: item.egpNumber
      });
    });
  }

  return await http.post('/planProcurementannouncement/', data);
};

const updateAnnouncementAsync = async (data: PublicPlanProcurement) => {
  data.authority = [];
  data.planProcurementAnnouncementSelected = [];

  if (data.jorporDirectorAssign && data.jorporDirectorAssign.length) {
    data.jorporDirectorAssign.forEach((item) =>
      data.authority.push({
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: item.status,
        isAvailable: item.isAvailable,
        availableRemark: null,
        isAvailableRemark: item.isAvailableRemark,
        acceptDate: item.acceptDate,
        acceptRemark: item.acceptRemark,
        rejectDate: item.rejectDate,
        rejectRemark: item.rejectRemark,
        sequence: item.sequence,
        level: item.level,
      })
    );
  }

  if (data.sectionHeadJorpor && data.sectionHeadJorpor.length) {
    data.sectionHeadJorpor.forEach((item) =>
      data.authority.push({
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? AcceptorStatus.PENDING : item.status,
        isAvailable: item.isAvailable,
        availableRemark: null,
        isAvailableRemark: item.isAvailableRemark,
        acceptDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptDate,
        acceptRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptRemark,
        rejectDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectDate,
        rejectRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectRemark,
        sequence: item.sequence
      })
    );
  }

  if (data.assistantDirectorJorpor && data.assistantDirectorJorpor.length) {
    data.assistantDirectorJorpor.forEach((item) =>
      data.authority.push({
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? AcceptorStatus.PENDING : item.status,
        isAvailable: item.isAvailable,
        availableRemark: null,
        isAvailableRemark: item.isAvailableRemark,
        acceptDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptDate,
        acceptRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptRemark,
        rejectDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectDate,
        rejectRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectRemark,
        sequence: item.sequence
      })
    );
  }

  if (data.directorJorpor && data.directorJorpor.length) {
    data.directorJorpor.forEach((item) =>
      data.authority.push({
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? AcceptorStatus.PENDING : item.status,
        isAvailable: item.isAvailable,
        availableRemark: null,
        isAvailableRemark: item.isAvailableRemark,
        acceptDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptDate,
        acceptRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptRemark,
        rejectDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectDate,
        rejectRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectRemark,
        sequence: item.sequence
      })
    );
  }

  if (data.assistantMd && data.assistantMd.length) {
    data.assistantMd.forEach((item) =>
      data.authority.push({
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? AcceptorStatus.PENDING : item.status,
        isAvailable: item.isAvailable,
        availableRemark: null,
        isAvailableRemark: item.isAvailableRemark,
        acceptDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptDate,
        acceptRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptRemark,
        rejectDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectDate,
        rejectRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectRemark,
        sequence: item.sequence
      })
    );
  }

  if (data.deputyMd && data.deputyMd.length) {
    data.deputyMd.forEach((item) =>
      data.authority.push({
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? AcceptorStatus.PENDING : item.status,
        isAvailable: item.isAvailable,
        availableRemark: null,
        isAvailableRemark: item.isAvailableRemark,
        acceptDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptDate,
        acceptRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptRemark,
        rejectDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectDate,
        rejectRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectRemark,
        sequence: item.sequence
      })
    );
  }

  if (data.md && data.md.length) {
    data.md.forEach((item) =>
      data.authority.push({
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? AcceptorStatus.PENDING : item.status,
        isAvailable: item.isAvailable,
        availableRemark: null,
        isAvailableRemark: item.isAvailableRemark,
        acceptDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptDate,
        acceptRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptRemark,
        rejectDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectDate,
        rejectRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectRemark,
        sequence: item.sequence
      })
    );
  }

  if (data.approvers && data.approvers.length) {
    data.approvers.forEach((item) =>
      data.authority.push({
        sectionCode: item.sectionCode,
        userId: item.userId,
        fullName: item.fullName,
        departmentName: item.departmentName,
        positionName: item.positionName,
        status: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? AcceptorStatus.PENDING : item.status,
        isAvailable: item.isAvailable,
        availableRemark: null,
        isAvailableRemark: item.isAvailableRemark,
        acceptDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptDate!,
        acceptRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.acceptRemark,
        rejectDate: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectDate!,
        rejectRemark: data.status === AnnouncementCons.Draft || data.status === AnnouncementCons.AssignJorPor ? null : item.rejectRemark,
        sequence: item.sequence,
        sectionType: item.sectionType,
        sectionSequence: item.sectionSequence
      })
    );
  }

  if (data.planProcurements && data.planProcurements.length) {
    data.planProcurements.forEach((item) => {
      data.planProcurementAnnouncementSelected.push({
        planId: item.id,
        egpNumber: item.egpNumber
      });
    });
  }

  return await http.put(`/planProcurementannouncement/${data.id}`, data);
};

const getDetailDataAsync = async (id: string) => await http.get(`/planProcurementannouncement/${id}`);

const onActionAnnoucement = async (id: string, data: onActionAnnoucementModel) => await http.put(`/planProcurementannouncement/action/${id}`, data);

const GetBadgePublicPlanAsync = async (page: number, size: number, data?: PlanProcurementAnnouncementCriteria) => {
  const params = {
    page,
    size,
    ...data
  };
  return await http.get('/planProcurementannouncement/countlistbadge', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const rejectPlanProcurementAsync = async (id: string, planId: string, remark?: string) =>
  await http.put(`/planProcurementannouncement/${id}/rejectPlan/${planId}/`, {
    remark: remark || '',
  });

  const deletePlanProcurementSeletedAsync = async (id: string, planId: string) =>
  await http.delete(`/planProcurementannouncement/${id}/removePlan/${planId}/`);


export default {
  getPlanListAsync,
  createAnnouncementAsync,
  updateAnnouncementAsync,
  getDetailDataAsync,
  onActionAnnoucement,
  GetBadgePublicPlanAsync,
  rejectPlanProcurementAsync,
  deletePlanProcurementSeletedAsync,
};
