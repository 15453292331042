export enum JorPor06Status {
  Draft = 'Draft',
  WaitingAcceptor = 'WaitingAcceptor',
  Approved = 'Approved',
  Reject = 'Reject'
}

export enum JorPor06AcceptorStatus {
  Pending = 'Pending',
  Approve = 'Approve',
  Reject = 'Reject',
}

export enum PositionOnBoardIdEnum {
  Chairman = '2c61837a-f048-460f-8747-efb6d62823d1',
  Director = '75ae2194-cc83-4e7d-b4a5-ca3c8c34938f',
  Secretary = 'd2343b80-0999-45b4-bb25-dcce01ae0b9c',
  Procurement = '7086420b-eeee-4e28-bacb-acecb502e98b',
  Inspector = 'b912dda5-3a60-43fc-a723-59955c1cf0d0',
}