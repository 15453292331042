import {
  Modal,
  Table,
} from 'components';
import { EntrepreneursModel } from 'models/PR/JorPor06Models';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { jorPor06 } from 'services';
import {
  HttpStatusCode,
  THCurrency,
} from 'utils';

interface Props {
  show: boolean;
  onHide?: () => void;
  onSelectItem?: (inviteEntrepreneursId: string, establishmentName: string, traderTaxId: string, index: number, financialId: string, financialIndex: number, confirmPriceTotal: number) => void;
  sectionSequence?: number;
  jorpor06id?: string;
  financialId?: string;
  financialIndex: number;
}

export function EntrepreneursWinneModal(props: Props) {
  const [entrepreneursWinnes, setEntrepreneursWinnes] = useState<EntrepreneursModel>({} as EntrepreneursModel);

  useEffect(() => {
    if (props.jorpor06id) {
      getEntrepreneursWinnerListAsync();
    }
  }, [props.jorpor06id]);

  const onSelect = (inviteEntrepreneursId: string, establishmentName: string, taxpayerIdentificationNo: string, index: number, financialId: string, financialIndex: number, confirmPriceTotal: number) => {
    props.onSelectItem!(inviteEntrepreneursId, establishmentName, taxpayerIdentificationNo, index, financialId, financialIndex, confirmPriceTotal);
    props.onHide!();
  };

  const getEntrepreneursWinnerListAsync = async () => {
    const res = await jorPor06.getEntrepreneursWinnerAsync(props.jorpor06id!);

    if (res.status === HttpStatusCode.OK) {
      setEntrepreneursWinnes(res.data);
    }
  };

  return (
    <Modal
      show={props.show}
      size="xl"
      onHide={() => (props.onHide ? props.onHide() : null)}
    >
      <ModalBT.Header closeButton>
        <ModalBT.Title>ข้อมูลคู่ค้า</ModalBT.Title>
      </ModalBT.Header>
      <ModalBT.Body className="p-0 ps-4">

        <Table hidePagination
          className="my-3"
        >
          <thead>
            <tr>
              <th style={{ minWidth: 50 }}>ลำดับ</th>
              <th style={{ minWidth: 100 }}>เลขประจำตัวผู้เสียภาษีอากร/<br />เลขประจำตัวประชาชน</th>
              <th style={{ minWidth: 300 }}>ชื่อบริษัท/ชื่อ - นามสกุล</th>
              <th style={{ minWidth: 100 }}>ราคาที่ตกลง</th>
              <th style={{ minWidth: 100 }}>อีเมล</th>
              <th style={{ minWidth: 100 }}>เบอร์โทร</th>
              <th style={{ minWidth: 25 }} />
            </tr>
          </thead>
          <tbody>
            {
              (entrepreneursWinnes && entrepreneursWinnes.entrepreneurWinners) && (
                entrepreneursWinnes.entrepreneurWinners.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data.taxPayerIdentificationNo}</td>
                    <td className="text-wrap">{data.establishmentName}</td>
                    <td className="text-end"> {THCurrency(data.parcels.reduce((a, b) => a += b.confirmPriceTotal, 0))}</td>
                    <td>{data.email}</td>
                    <td>{data.tel}</td>
                    <td>
                      <Button variant="primary" className="px-3" onClick={() => onSelect(data.inviteEntrepreneursId, data.establishmentName, data.taxPayerIdentificationNo, index, props.financialId!, props.financialIndex, data.parcels.reduce((a, b) => a += b.confirmPriceTotal, 0))}>
                        เลือก
                      </Button>
                    </td>
                  </tr>
                ))
              )
            }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
