import { Breadcrumb } from 'components';
import StepProgress from 'components/StepProgress';
import { IFile } from 'models';
import {
  DocumentModel,
  PL0204DetailData,
} from 'models/PL/PL0204Models';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useLoaderData,
  useParams,
} from 'react-router';
import PL0204Data from './Step1';
import PL0204Approver from './Step2';

interface Stepper {
  title: JSX.Element;
  displayIndex: string;
  child: JSX.Element;
}

const BREADCRUMB_INFO = [
  {
    routerName: '/pl/pl0204', label: 'ขออนุมัติยกเลิกแผนการจัดซื้อจัดจ้าง',
  },
  {
    routerName: '', label: 'รายละเอียดขออนุมัติยกเลิกแผนการจัดซื้อจัดจ้าง',
  },
];

type Loader = {
  detail: PL0204DetailData,
};

type PL0202Context = {
  data: PL0204DetailData,
  setData: Dispatch<React.SetStateAction<PL0204DetailData>>,
  setFiles: Dispatch<React.SetStateAction<IFile[]>>,
  files: IFile[],
};

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

export const Context = createContext({} as PL0202Context);

export default function PL0204Detail() {
  const { id } = useParams();
  const { detail } = useLoaderData() as Loader;
  const [data, setData] = useState<PL0204DetailData>({ documentDelete: [] as string[] } as PL0204DetailData);
  const [files, setFiles] = useState<IFile[]>([]);
  const [isMount, setIsMount] = useState<boolean>(false);

  useEffect(() => {
    setData({ ...data, ...detail });
    if (detail.documentsInit.length > 0) handleSetFile(detail.documentsInit);
    setIsMount(true);
  }, [detail]);

  const childRef = useRef<ProgressBarRefType>(null);
  const [stepper] = useState<Stepper[]>([
    {
      title: <p>ข้อมูลแผน</p>,
      displayIndex: '1',
      child: <PL0204Data onClickNext={() => clickNext()} />,
    },
    {
      title: <p>สถานะการอนุมัติ</p>,
      displayIndex: '2',
      child: <PL0204Approver onClickBack={() => clickBack()} />,
    },
  ]);

  const handleSetFile = (documents: DocumentModel[]) => {
    documents.forEach((value, index) => {
      const document = documents[index];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    });
  };

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  return (
    !isMount ? <></>
      : (
        <div className='PL0204Detail'>
          <h4 className='mt-2 mb-0 text-primary text-start'>รายละเอียดขออนุมัติยกเลิกแผนการจัดซื้อจัดจ้าง</h4>
          <Breadcrumb data={BREADCRUMB_INFO} />

          <Row>
            <Col xs={12}
              className='mb-2'>
              <Context.Provider value={{ data, setData, setFiles, files }}>
                <StepProgress progressBarData={stepper}
                  ref={childRef} />
              </Context.Provider>
            </Col>
          </Row>

        </div>
      )
  );
}
