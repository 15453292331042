import {
  Breadcrumb,
  InputTextArea,
  Modal,
} from 'components';
import Title from 'components/Controls/Title';
import StepProgress from 'components/StepProgress';
import {
  ItemModel,
  JorPorO5,
  UserListSelectionResponse,
} from 'models';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  department,
  jorPor05,
  shareValue,
} from 'services';
import {
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import { SharedvalueGroup, SubGroup } from 'utils/constants/ContractEnum';

export interface CriteriaModal {
  fullName?: string;
  departmentId?: string;
  positionId?: string;
}

export interface DataModal {
  data: UserListSelectionResponse[];
  totalRecords: number;
}

export interface Pagination {
  size: number;
  page: number;
}

export interface UserResponse {
  id: string;
  no: number;
  name: string;
  department: string;
  position: string;
  status: string;
  UserDate: Date;
  isAvailable: boolean;
}

export interface UserSelected {
  userId: string;
  fullName: string;
  departmentName: string;
  positionName: string;
  positionInPersonId: string;
  status: string;
  acceptDate: Date;
  isAvailable: boolean;
}

export enum CommitteeType {
  PROCUREMENT = 'Procurement',
  INSPECT = 'Inspect',
  MA = 'MA',
  SUPERVISOR = 'Supervisor',
}

export enum Section {
  JORPOR_SUPERVISOR_USER = 'JorPorSupervisorUser',
  JORPOR_ASSISTANT_PRINCIPLE_USER = 'JorPorAssistantPrincipleUser',
  JORPOR_PRINCIPLE_USER = 'JorPorPrincipleUser',
}

export enum AcceptorStatus {
  PENDING = 'Pending',
  APPROVE = 'Approve',
  REJECT = 'Reject',
  DRAFT = 'Draft',
}

export interface ModalData {
  show: boolean;
  section?: Section;
  status?: AcceptorStatus;
  accecptorId?: string;
}

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

interface PR0101Context {
  data: JorPorO5;
  setData: Dispatch<SetStateAction<JorPorO5>>;
  departmentList: Department[];
  supplyMethodList: SupplyMethod[];
  supplyMethodTypeList: SupplyMethod[];
  supplyMethodSpecialTypeList: SupplyMethod[];
  selectionCriteriaList: ItemModel[];
  getSupplyMethodSpecialTypeAsync: (id: string) => void;
  isAmountOver: boolean;
  getDetailAsync: (id: string) => void;
  readonly: boolean;
}

interface SharedValue {
  label: string;
  value: string;
}

interface Department extends SharedValue {
}

interface SupplyMethod extends SharedValue {
}

interface PositionOnBoard extends SharedValue {
  sequence: number;
}

const BREADCRUMB_INFO = [
  {
    routerName: '/pr/pr0101/', label: 'จัดทำรายงานขอซื้อขอจ้าง (จพ.005)',
  },
  {
    routerName: '', label: 'รายละเอียดจัดทำรายงานขอซื้อขอจ้าง (จพ.005)',
  },
];

export enum Status {
  DRAFT = 'Draft',
  WAITING_ACCEPT = 'WaitingAccept',
  ACCEPTED = 'Accepted',
  REJECT = 'Reject',
}

export const Context = createContext({} as PR0101Context);

export default function PR0101Detail() {
  const { basicInformationId } = useParams();
  const [data, setData] = useState<JorPorO5>({} as JorPorO5);
  const [departmentList, setDepartmentList] = useState<Department[]>([]);
  const [supplyMethodList, setSupplyMethodList] = useState<SupplyMethod[]>([]);
  const [supplyMethodTypeList, setSupplyMethodTypeList] = useState<SupplyMethod[]>([]);
  const [selectionCriteriaList, setSelectionCriteriaList] = useState<ItemModel[]>([]);
  const [supplyMethodSpecialTypeList, setSupplyMethodSpecialTypeList] = useState<SupplyMethod[]>([]);
  const childRef = useRef<ProgressBarRefType>(null);
  const { userId } = useAppContext();
  const [step, setStep] = useState<number>(0);

  const stepElements = [
    {
      title: <p>ข้อมูลการแจ้งข้อมูลเบื้องต้น
        (จพ.005)
      </p>,
      displayIndex: '1',
      child: <Step1 onClickNext={() => clickNext()}
        onClickBack={() => backToIndex()} />,
    },
    {
      title: <p>ผู้มีอำนาจเห็นชอบและ
        Assign งาน
      </p>,
      displayIndex: '2',
      child: <Step2 onClickNext={() => clickNext()}
        onClickBack={() => clickBack()} />,
    },
    // {
    //   title: <p>ตัวอย่างเอกสาร
    //     การแจ้งข้อมูลเบื้องต้น (จพ.005)
    //   </p>,
    //   displayIndex: '3',
    //   child: <Step3 onClickBack={() => clickBack()} />,
    // },
  ];

  useEffect(() => {
    getDataAsync();
  }, []);

  useEffect(() => {
    if (Object.keys(data).length && userId) {
      if (data.status === Status.WAITING_ACCEPT &&
        data.acceptors?.some(a => a.userId === userId || a.delegateUserId === userId) || data.status === Status.ACCEPTED) {
        setStep(2);
      }
    }
  }, [data, userId]);

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/pr/pr0101');
  };

  const getDataAsync = async () => {
    const [departmentList, supplyMethodList, supplyMethodTypeList, selectionCriteria] = await Promise.all([
      department.getDepartmentsAsync(),
      shareValue.getSupplyMethodListAsync(),
      shareValue.getSupplyMethodTypeListAsync(),
      shareValue.GetDDL('CriteriaConsideration'),
    ]);

    setDepartmentList(departmentList.data?.map((d: { id: string, name: string }) => ({ value: d.id, label: d.name })));
    setSupplyMethodList(supplyMethodList.data);
    setSupplyMethodTypeList(supplyMethodTypeList.data);
    setSelectionCriteriaList(selectionCriteria);
    getDetailAsync();
  };

  const getDetailAsync = async () => {
    if (basicInformationId) {
      const res = await jorPor05.getDetailAsync(basicInformationId);
      if (res.status === HttpStatusCode.OK) {
        if (res.data.jorPor05) {
          return setData({
            ...res.data.refAndBasic,
            ...res.data.jorPor05,
          });
        }

        setData({
          ...res.data.refAndBasic,
          id: '',
          basicInformationId: res.data.refAndBasic.id,
          status: Status.DRAFT,
        });
      }
    }
  };

  const getSupplyMethodSpecialTypeAsync = async (id: string) => {
    const res = await shareValue.getSupplyMethodListAsync(id);

    setSupplyMethodSpecialTypeList(res.data);
  };

  const isAmountOver = useMemo(() => {
    return data.financialAmount > 100000;
  }, [data.financialAmount]);

  const readonly = useMemo(() =>
    data.status === Status.WAITING_ACCEPT
    || data.status === Status.ACCEPTED,
    [data]);

  const contextValue = {
    data,
    setData,
    departmentList,
    supplyMethodList,
    supplyMethodTypeList,
    supplyMethodSpecialTypeList,
    selectionCriteriaList,
    getSupplyMethodSpecialTypeAsync,
    isAmountOver,
    getDetailAsync,
    readonly,
  };

  return (
    <Context.Provider value={contextValue}>
      <h4 className='mt-2 text-primary'>
        <Button variant='link'
          onClick={backToIndex}>
          <MdOutlineArrowBack className='fs-4 text-primary mb-1' />
        </Button>
        การจัดทำรายงานขอซื้อขอจ้าง ( จพ.005 )
      </h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Row>
        <Col xs={12}
          className='mb-2'>
          <StepProgress
            progressBarData={stepElements}
            ref={childRef}
            step={step} />
        </Col>
      </Row>
    </Context.Provider>
  );
}

const STATUS_DETAIL = [
  {
    value: 'Draft',
    color: '#BEBEBE',
    label: 'แบบร่าง',
  },
  {
    value: 'WaitingAccept',
    color: '#FBBD01',
    label: 'อยู่ระหว่างเห็นชอบ/อนุมัติ',
  },
  {
    value: 'Accepted',
    color: '#1BA345',
    label: 'เห็นชอบ/อนุมัติ',
  },
  {
    value: 'Reject',
    color: '#DE3E44',
    label: 'ส่งกลับแก้ไข',
  },
  {
    value: 'None',
    color: '#BEBEBE',
    label: 'ยังไม่ดำเนินการ',
  },
];

export function JorPor05Status({ value }: { value: string }) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (value) {

      const status = STATUS_DETAIL.find((s) => s.value === value);

      if (status) {
        setStatus(status);
      }
    }
  }, [value]);
  return (
    <>
      {value
        ? (
          <div className='tor-status'
            style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

// export function JorPor05Status({ value }: { value: string }) {
//   const status = useMemo(() => {
//     switch (value) {
//       case Status.DRAFT:
//         return { text: 'แบบร่าง', color: 'secondary' };
//       case Status.WAITING_ACCEPT:
//         return { text: 'อยู่ระหว่างเห็นชอบ/อนุมัติ', color: 'warning' };
//       case Status.REJECT:
//         return { text: 'ส่งกลับแก้ไข', color: 'danger' };
//       case Status.ACCEPTED:
//         return { text: 'เห็นชอบ/อนุมัติ', color: 'success' };
//       default:
//         return { text: 'ยังไม่ดำเนินการ', color: 'secondary' };
//     }
//   }, [value]);

//   return (
//     <>
//       {
//         status ?
//           <Badge
//             bg={status.color}
//             className='d-flex justify-content-center align-items-center rounded-pill fs-6 fw-normal'>
//             {status.text}
//           </Badge> : null
//       }
//     </>
//   );
// }

export function ActionModal(props: {
  data: ModalData;
  onHide: () => void;
  onApprove: () => void;
}) {
  const { data, getDetailAsync } = useContext(Context);
  const [remark, setRemark] = useState<string>('');

  useEffect(() => {
    if (props.data.show) {
      setRemark('');
    }
  }, [props.data.show]);

  const onSubmitAsync = async () => {
    submitForm({}, true);

    if (props.data.status === AcceptorStatus.REJECT && !remark) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
      return;
    }

    if (props.data.section && props.data.status) {
      const res = await jorPor05.actionAsync(
        data.id,
        props.data.section,
        props.data.status,
        props.data.accecptorId,
        remark,
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        if (props.data.status === AcceptorStatus.APPROVE) {

          props.onApprove();

          toast.success('เห็นชอบ/อนุมัติสำเร็จ');
        } else {
          toast.success('ส่งกลับแก้ไขสำเร็จ');
        }

        await getDetailAsync(data.id);

        window.location.reload();

        props.onHide();
      }
    }
  };

  return (
    <Modal
      show={props.data.show}
      onHide={props.onHide}
      size='xl'>
      <Modal.Header>
        <Title
          text={props.data.status === AcceptorStatus.APPROVE ? 'เห็นชอบ' : 'ส่งกลับแก้ไข'}
          className='fs-5 text-primary' />
      </Modal.Header>
      <Row className='mt-3'>
        <Col>
          {props.data.status === AcceptorStatus.APPROVE ?
            <InputTextArea
              label='เหตุผล'
              onChange={setRemark}
              value={remark}
              name='remark' /> : <InputTextArea
              label='เหตุผล'
              rule={{ required: true }}
              onChange={setRemark}
              value={remark}
              name='remark' />
          }
        </Col>
      </Row>
      <div className='d-flex justify-content-end gap-2'>
        <Button
          variant='outline-primary'
          onClick={props.onHide}>
          ยกเลิก
        </Button>
        <Button
          variant='primary'
          onClick={onSubmitAsync}>
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
}
