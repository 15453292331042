import {
  Card,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
} from 'components';
import { DepartmentResponse } from 'models';
import {
  IFile,
  ItemModel,
} from 'models/systemUtility';
import UploadFile from 'pages/PL/PL0201/UploadFile';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaAngleRight,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';
import {
  department,
  shareValue,
} from 'services';
import { HttpStatusCode } from 'utils';
import { DetailContext } from './index';

interface Props {
  onNext: () => void;
}

export default function Step1(props: Props) {
  const [files, setFiles] = useState<IFile[]>([]);
  const detail = useContext(DetailContext);
  const [departmentItems, setDepartmentItems] = useState<ItemModel[]>([]);
  const [supplyMethodTypeItems, setSupplyMethodTypeItems] = useState<ItemModel[]>([]);
  const [supplyMethodNameItems, setSupplyMethodNameItems] = useState<ItemModel[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getDepartmentItemsAsync();
    getSupplyMethodTypeAsync();
    getSupplyMethodNameAsync();
  }, []);

  const getDepartmentItemsAsync = async () => {
    const response = await department.getDepartmentsAsync();

    if (response.status === HttpStatusCode.OK) {
      const departmentData: DepartmentResponse[] = response.data;
      const items: ItemModel[] = [];

      departmentData.forEach((data) => items.push({ label: data.name, value: data.id ? data.id : '' }));

      setDepartmentItems(items);
    }
  };

  const getSupplyMethodTypeAsync = async () => {
    const response = await shareValue.getSupplyMethodTypeListAsync();

    if (response.status === HttpStatusCode.OK) {
      const supplyMethodTypeItems: ItemModel[] = response.data;
      const items: ItemModel[] = [];

      supplyMethodTypeItems.forEach((data) => items.push({ label: data.label, value: data.value ? data.value : '' }));

      setSupplyMethodTypeItems(items);
    }
  };

  const getSupplyMethodNameAsync = async () => {
    const response = await shareValue.getSupplyMethodListAsync();

    if (response.status === HttpStatusCode.OK) {
      const supplyMethodItems: ItemModel[] = response.data;
      const items: ItemModel[] = [];

      supplyMethodItems.forEach((data) => items.push({ label: data.label, value: data.value ? data.value : '' }));

      setSupplyMethodNameItems(items);
    }
  };

  return (
    <Card>
      <div className="d-flex gap-2">
        <p>ข้อมูลแผน</p>
      </div>
      <Row>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Input
            label="เลขที่แผน"
            disabled
            value={detail.data.planNumber}
          />
        </Col>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Selector
            label="ฝ่าย/สำนัก"
            rule={{ required: true }}
            disabled
            value={detail.data.departmentId}
            items={departmentItems}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Selector
            label="วิธีการจัดหา"
            rule={{ required: true }}
            disabled
            value={detail.data.supplyMethod}
            items={supplyMethodTypeItems}
          />
        </Col>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Selector
            className="no-label"
            rule={{ required: true }}
            disabled
            value={detail.data.supplyMethodName}
            items={supplyMethodNameItems}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8} xl={6}>
          <InputTextArea
            label="ชื่อโครงการ"
            rule={{ required: true }}
            disabled
            value={detail.data.planName}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={6} lg={4} xl={3}>
          <Currency
            label="งบประมาณโครงการ (บาท)"
            rule={{ required: true }}
            disabled
            value={detail.data.planBudget}
          />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <DatePicker
            label="ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง"
            rule={{ required: true }}
            monthYearOnly
            disabled
            value={new Date(detail.data.expectingProcurementAt)}
          />
        </Col>
        <Col sm={6} md={4} lg={4} xl={3}>
          <DatePicker
            label="วันที่ประกาศแผน"
            disabled
            value={new Date(detail.data.planAnnouncementDate)}
          />
        </Col>
      </Row>
      <p className="mb-0">เอกสารแนบ</p>
      <Card>
        <div className="d-flex justify-content-center">
          <Row>
            <Col sm={12}>
              <UploadFile
                files={files}
                planId="test"
                handleFileChange={() => { }}
                removeItem={() => { }}
                disabled
              />
            </Col>
          </Row>
        </div>
      </Card>
      <>
        <Row className="criteria">
          <Col sm={12} xl={3}>
            <Input
              label="สร้างโดย"
              disabled
              value={detail.data.createdByFullName}
            />
          </Col>
          <Col sm={12} xl={3}>
            <DatePicker
              label="วันที่สร้าง"
              disabled
              value={new Date(detail.data.createdDate)}
            />
          </Col>
        </Row>
        <Row className="criteria">
          <Col sm={12} xl={3}>
            <Input
              label="ผู้ส่งอนุมัติ"
              disabled
              value={detail.data.updatedByUserFullName}
            />
          </Col>
          <Col sm={12} xl={3}>
            <DatePicker
              label="วันที่ส่งอนุมัติ"
              disabled
              value={new Date(detail.data.updatedDate)}
            />
          </Col>
        </Row>
      </>
      <div className="d-flex justify-content-between mt-4">
        <Button
          variant="outline-primary"
          className="d-flex align-items-center gap-2"
          onClick={() => navigate(-1)}
        >
          <FaAngleLeft />ย้อนกลับ
        </Button>
        <Button
          variant="primary"
          className="d-flex align-items-center gap-2"
          onClick={props.onNext}
        >
          ถัดไป<FaAngleRight />
        </Button>
      </div>
    </Card>
  );
}
