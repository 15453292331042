import { GetChangeRequestParamsModel } from 'models';
import http from 'utils/axios';

const getApproveListAsync = async (Page: number, Size: number, data?: GetChangeRequestParamsModel) => {
  const params = {
    Page,
    Size,
    ...data,
  };

  return await http.get('/approve', { params });
};

const getApproveChangePlanRequestAsync = async (id: string) => await http.get(`/approve/change-plan-request/${id}`);

const getApprovePlanAsync = async (id: string) => await http.get(`/approve/plan/${id}`);

const getApprovePlanCountStatusAsync = async () => await http.get('/approve/count-status');

const updateApproveStatus = async (id: string, planStatus: string, changePlanId?: string) => {
  const body = {
    approved: [
      {
        planId: changePlanId || id,
        planStatus,
      },
    ],
  };

  return await http.put('/approve', body);
};

const updateApproveStatusListAsync = async (list: { planId: string, planStatus: string }[]) => {
  const body = { approved: list };

  return await http.put('/approve', body);
};

const updateApproveRejectStatus = async (id: string, planStatus: string, remark: string, changePlanId?: string) => {
  const body = {
    id: changePlanId || id,
    planStatus,
    remark,
  };

  return await http.put(`/approve/reject/${id}`, body);
};

export default {
  getApproveListAsync,
  getApproveChangePlanRequestAsync,
  getApprovePlanAsync,
  updateApproveStatus,
  updateApproveRejectStatus,
  getApprovePlanCountStatusAsync,
  updateApproveStatusListAsync,
};
