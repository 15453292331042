import { FileValue, ProcessStatus } from 'components';
import { CriteriaPlanProcurementListModel } from 'models';
import { BasicInfoCreate, BasicInformationAction, BasicInfoSearchParams } from 'models/PP';
import qs from 'qs';
import http from 'utils/axios';
import { AnnouncementStatus } from 'utils/constants/PlanEnum';

const api = 'jorPor04';

const GetListAsync = async (page: number, size: number, criteria?: any) => {
  const params = {
    page,
    size,
    ...criteria
  };

  return await http.get(api, { params });
};

const getBasicInfoListAsync = async (page: number, size: number, criteria?: BasicInfoSearchParams, userId?: string) => {
  const params = {
    page,
    size,
    userId,
    isPermission: criteria?.isPermission,
    ...criteria
  };

  return await http.get('/basicinformation', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getBasicInfoDetailAsync = async (termOfRefId?: string, id?: string) => {

  let params = {};

  if (termOfRefId && id) {
    params = {
      termOfRefId,
      id,
    };
  } else if (termOfRefId) {
    params = {
      termOfRefId,
    };
  } else {
    params = {
      id,
    };
  }

  return await http.get(`/basicinformation/single`, { params });
};

// const createBasicInfoAsync = (body: BasicInfoCreate) => {
//   return http.post(`basicinformation`, body);
// };

const createBasicInfoAsync = async (data: BasicInfoCreate) => {
  let runNumber = 1;
  let committeeIndex = 0;
  let acceptorIndex = 0;

  const formData = new FormData();
  if (data.termOfRefId) {
    formData.append('termOfRefId', data.termOfRefId ?? '');
  }
  formData.append('documentNumber', data.documentNumber ? data.documentNumber : '');
  formData.append('prNumber', data.prNumber);
  formData.append('considerationDescription', data.considerationDescription);
  formData.append('considerationSelect', data.considerationSelect);
  formData.append('selectionCriteria', data.selectionCriteria);
  formData.append('status', ProcessStatus.DRAFT);
  formData.append('planName', data.planName);
  formData.append('financialAmount', data.financialAmount.toString());
  formData.append('supplyMethod', data.supplyMethod);
  formData.append('supplyMethodType', data.supplyMethodType);
  formData.append('supplyMethodSpecialType', data.supplyMethodSpecialType);
  formData.append('expectingProcurementAt', new Date(data.expectingProcurementAt as Date).toISOString());
  formData.append('departmentId', data.departmentId);
  formData.append('sourceAndReasons', data.sourceAndReasons);
  formData.append('budgetCode', data.budgetCode || '');
  formData.append('departmentsBudget', data.departmentsBudget || '');
  formData.append('accountNumber', data.accountNumber || '');
  formData.append('deliveryPeriodSum', data.deliveryPeriodSum ? data.deliveryPeriodSum.toString() : '');
  formData.append('deliveryUnit', data.deliveryUnit ? data.deliveryUnit : '');
  formData.append('deliveryCountUnit', data.deliveryCountUnit ? data.deliveryCountUnit : '');
  formData.append('refPlanNumber', data.refPlanNumber ?? '');
  formData.append('isMA', data.isMA ? 'true' : 'false');
  formData.append('isSupervisor', data.isSupervisor ? 'true' : 'false');
  formData.append('budgetYear', data.budgetYear?.toString() ?? '');

  if (data.appointNumber) {
    formData.append('appointNumber', data.appointNumber?.toString() ?? '');
  }

  if (data.documentDate) {
    formData.append('documentDate', new Date(data.documentDate as Date).toISOString());
  }

  if (data.committeeProcurementUser && data.committeeProcurementUser.length > 0) {
    data.committeeProcurementUser.forEach((d) => {
      formData.append(`committees[${committeeIndex}][committeeType]`, d.committeeType!);
      formData.append(`committees[${committeeIndex}][userId]`, d.userId);
      formData.append(`committees[${committeeIndex}][positionOnBoardId]`, d.positionOnBoardId!);
      formData.append(`committees[${committeeIndex}][positionName]`, d.positionName!);
      committeeIndex += 1;
    });
  }

  if (data.committeeInspectsUser && data.committeeInspectsUser.length > 0) {
    data.committeeInspectsUser.forEach((d) => {
      formData.append(`committees[${committeeIndex}][committeeType]`, d.committeeType!);
      formData.append(`committees[${committeeIndex}][userId]`, d.userId);
      formData.append(`committees[${committeeIndex}][positionOnBoardId]`, d.positionOnBoardId!);
      formData.append(`committees[${committeeIndex}][positionName]`, d.positionName!);
      committeeIndex += 1;
    });
  }
  if (data.committeeMAUser && data.committeeMAUser.length > 0) {
    data.committeeMAUser.forEach((d) => {
      formData.append(`committees[${committeeIndex}][committeeType]`, d.committeeType!);
      formData.append(`committees[${committeeIndex}][userId]`, d.userId);
      formData.append(`committees[${committeeIndex}][positionOnBoardId]`, d.positionOnBoardId!);
      formData.append(`committees[${committeeIndex}][positionName]`, d.positionName!);
      committeeIndex += 1;
    });
  }

  if (data.committeeSupervisorUser && data.committeeSupervisorUser.length > 0) {
    data.committeeSupervisorUser.forEach((d) => {
      formData.append(`committees[${committeeIndex}][committeeType]`, d.committeeType!);
      formData.append(`committees[${committeeIndex}][userId]`, d.userId);
      formData.append(`committees[${committeeIndex}][positionOnBoardId]`, d.positionOnBoardId!);
      formData.append(`committees[${committeeIndex}][positionName]`, d.positionName!);
      committeeIndex += 1;
    });
  }

  if (data.assistantHeadOfDepartment && data.assistantHeadOfDepartment.length > 0) {
    data.assistantHeadOfDepartment.forEach((d) => {
      formData.append(`acceptorBasicInformation[${acceptorIndex}][sectionCode]`, d.sectionCode!);
      formData.append(`acceptorBasicInformation[${acceptorIndex}][userId]`, d.userId);
      formData.append(`acceptorBasicInformation[${acceptorIndex}][sequence]`, runNumber.toString()!);

      runNumber += 1;
      acceptorIndex += 1;
    });
  }

  if (data.sectionHead && data.sectionHead.length > 0) {
    data.sectionHead.forEach((d) => {
      formData.append(`acceptorBasicInformation[${acceptorIndex}][sectionCode]`, d.sectionCode!);
      formData.append(`acceptorBasicInformation[${acceptorIndex}][userId]`, d.userId);
      formData.append(`acceptorBasicInformation[${acceptorIndex}][sequence]`, runNumber.toString()!);

      runNumber += 1;
      acceptorIndex += 1;
    });
  }

  if (data.assistantDepartmentDirector && data.assistantDepartmentDirector.length > 0) {
    data.assistantDepartmentDirector.forEach((d, i) => {
      formData.append(`acceptorBasicInformation[${acceptorIndex}][sectionCode]`, d.sectionCode!);
      formData.append(`acceptorBasicInformation[${acceptorIndex}][userId]`, d.userId);
      formData.append(`acceptorBasicInformation[${acceptorIndex}][sequence]`, runNumber.toString()!);

      runNumber += 1;
      acceptorIndex += 1;
    });
  }

  if (data.departmentDirector && data.departmentDirector.length > 0) {
    data.departmentDirector.forEach((d) => {
      formData.append(`acceptorBasicInformation[${acceptorIndex}][sectionCode]`, d.sectionCode!);
      formData.append(`acceptorBasicInformation[${acceptorIndex}][userId]`, d.userId);
      formData.append(`acceptorBasicInformation[${acceptorIndex}][sequence]`, runNumber.toString()!);

      runNumber += 1;
      acceptorIndex += 1;
    });
  }

  if (data.glAccounts && data.glAccounts.length > 0) {
    data.glAccounts.forEach((d) => {
      formData.append(`glAccounts[${acceptorIndex}][amount]`, d.amount ? d.amount.toString() : '');
      formData.append(`glAccounts[${acceptorIndex}][departmentCode]`, d.departmentCode ? d.departmentCode.toString() : '');
      formData.append(`glAccounts[${acceptorIndex}][glAccountId]`, d.glAccountId ? d.glAccountId.toString() : '');
      formData.append(`glAccounts[${acceptorIndex}][gLAccountName]`, d.gLAccountName ? d.gLAccountName.toString() : '');
      formData.append(`glAccounts[${acceptorIndex}][basicInformationId]`, d.basicInformationId ? d.basicInformationId.toString() : '');
      formData.append(`glAccounts[${acceptorIndex}][sequence]`, runNumber.toString()!);

      runNumber += 1;
      acceptorIndex += 1;
    });
  }

  if (data.objectives && data.objectives.length > 0) {
    data.objectives.forEach((d, index) => {
      formData.append(`objectives[${index}][description]`, d.description);
      formData.append(`objectives[${index}][sequence]`, d.sequence.toString());
    });
  }

  if (data.parcels && data.parcels.length > 0) {
    data.parcels.forEach((d, index) => {
      formData.append(`parcels[${index}][sequence]`, d.sequence.toString());
      formData.append(`parcels[${index}][description]`, d.description);
      formData.append(`parcels[${index}][quantity]`, d.quantity.toString());
      formData.append(`parcels[${index}][unit]`, d.unit);
      formData.append(`parcels[${index}][price]`, d.price.toString());
      formData.append(`parcels[${index}][total]`, d.total ? d.total.toString() : '');
    });
  }

  if (data.financials && data.financials.length > 0) {
    data.financials.forEach((d, index) => {
      formData.append(`financials[${index}][financialType]`, d.financialType);
      formData.append(`financials[${index}][amount]`, d.amount.toString());
      formData.append(`financials[${index}][description]`, d.description);
      formData.append(`financials[${index}][sequence]`, d.sequence.toString());
    });
  }

  if (data.workPhases && data.workPhases.length > 0) {
    data.workPhases.forEach((d, index) => {
      formData.append(`workPhases[${index}][sequence]`, d.sequence.toString());
      formData.append(`workPhases[${index}][phaseNumber]`, d.phaseNumber.toString());
      formData.append(`workPhases[${index}][periodDay]`, d.periodDay.toString());
      formData.append(`workPhases[${index}][amount]`, d.amount ? d.amount.toString() : '');
      formData.append(`workPhases[${index}][percent]`, d.percent ? d.percent.toString() : '');
      formData.append(`workPhases[${index}][detail]`, d.detail);
      formData.append(`workPhases[${index}][deliveryDueDate]`, d.deliveryDueDate ? new Date(d.deliveryDueDate).toISOString() : '');
    });
  }

  if (data.documentList && data.documentList.length > 0) {
    data.documentList?.forEach((d) => {
      formData.append('documents', d as File);
    });
  }

  return await http.post('/basicinformation', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const updateBasicInfoAsync = async (data: BasicInfoCreate, id: string, status: AnnouncementStatus | string) => {
  let runNumber = 1;

  data.committees = [];
  data.acceptorBasicInformation = [];
  data.assigns = [];

  if (data.committeeProcurementUser && data.committeeProcurementUser.length > 0) {
    data.committeeProcurementUser.forEach((item) =>
      data.committees.push({
        id: item.id,
        committeeType: item.committeeType,
        userId: item.userId,
        positionOnBoardId: item.positionOnBoardId,
        positionName: item.positionName
      })
    );
  }

  if (data.committeeInspectsUser && data.committeeInspectsUser.length > 0) {
    data.committeeInspectsUser.forEach((item) =>
      data.committees.push({
        id: item.id,
        committeeType: item.committeeType,
        userId: item.userId,
        positionOnBoardId: item.positionOnBoardId,
        positionName: item.positionName
      })
    );
  }

  if (data.committeeMAUser && data.committeeMAUser.length > 0) {
    data.committeeMAUser.forEach((item) =>
      data.committees.push({
        id: item.id,
        committeeType: item.committeeType,
        userId: item.userId,
        positionOnBoardId: item.positionOnBoardId,
        positionName: item.positionName
      })
    );
  }

  if (data.committeeSupervisorUser && data.committeeSupervisorUser.length > 0) {
    data.committeeSupervisorUser.forEach((item) =>
      data.committees.push({
        id: item.id,
        committeeType: item.committeeType,
        userId: item.userId,
        positionOnBoardId: item.positionOnBoardId,
        positionName: item.positionName
      })
    );
  }

  if (data.acceptors && data.acceptors.length > 0) {
    data.acceptors.forEach((item) => {
      data.acceptorBasicInformation.push({
        id: item.id,
        sectionCode: item.sectionCode,
        userId: item.userId,
        sequence: runNumber,
        sectionType: item.sectionType,
        sectionSequence: item.sectionSequence,
        positionName: item.positionName,
        departmentName: item.departmentName,
        delegateUserId: item.delegateUserId,
        delegatePositionName: item.delegatePositionName
      });

      runNumber += 1;
    });
  }

  if (data.assignsLists && data.assignsLists.length > 0) {
    data.assignsLists.forEach((item) => {
      data.assigns?.push({
        id: item.id,
        userId: item.userId,
        positionName: item.positionName,
        sectionCode: item.sectionCode,
        level: item.level
      });
    });
  }

  data.status = status;
  data.expectingProcurementAt = new Date(data.expectingProcurementAt as Date).toISOString();

  return await http.put(`/basicinformation/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const deleteBasicInformationAsync = async (id: string) =>
  await http.delete(`/basicinformation/${id}`);

const updateAssignsAsync = async (data: BasicInfoCreate, id: string) => {
  data.assigns = [];

  if (data.assignsLists && data.assignsLists.length > 0) {
    data.assignsLists.forEach((item) => {
      data.assigns?.push({
        id: item.id,
        userId: item.userId,
        positionName: item.positionName,
        level: item.level,
        sectionCode: item.sectionCode
      });
    });
  }

  return await http.put(`/basicinformation/assign/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const sendApproveAndRejectAsync = async (id: string, data: BasicInformationAction) => {
  const body = {
    ...data
  };

  return await http.put(`/basicinformation/action/${id}`, body);
};

const sendJorPorRejectAsync = async (id: string, remark?: string) => {
  const body = {
    remark
  };

  return await http.put(`/basicinformation/jorpor-reject/${id}`, body);
};

const uploadBasicInformationDocument = async (id: string, data: FileValue) => {
  const body = {
    file: data,
    fileName: data.name
  };

  return await http.put(`/basicinformation/${id}/uploadfile`, body, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const getStatusCount = async (userId: string, criteria?: BasicInfoSearchParams) => {
  const params = {
    userId,
    planNumber: criteria?.planNumber,
    jorpor04Number: criteria?.jorpor04Number,
    appointNumber: criteria?.appointNumber,
    departmentId: criteria?.departmentId,
    planName: criteria?.planName,
    expectingProcurementAt: criteria?.expectingProcurementAt,
    prNumber: criteria?.prNumber,
    isPermission: criteria?.isPermission,
  };

  return await http.get('basicinformation/countstatus', { params });
};

const getDocumentAsync = (id: string) => {
  const api = process.env.REACT_APP_API_URL;

  return http.get(`${api}/basicinformation/${id}/document`);
};

const downloadFileAsync = async (id: string, documentId: string) => await http.get(`/basicinformation/${id}/documents/${documentId}`, { responseType: 'blob' });

const removeFileAsync = async (id: string, documentId: string) => await http.delete(`/basicinformation/${id}/deletefile/${documentId}`);

const getListHistoryAsync = (page: number, size: number, basicInformationId: string | undefined) => {
  const params = {
    page,
    size,
    basicInformationId
  };

  return http.get('/basicinformation/history', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

const getPlanProcurementListAsync = async (page: number, pageSize: number, type?: string, departmentCode?: string, data?: CriteriaPlanProcurementListModel) => {
  const params = {
    page,
    pageSize,
    type,
    departmentCode,
    ...data
  };
  return await http.get('/basicinformation/planProcurement/pagination/', {
    params,
    paramsSerializer: (params) => qs.stringify(params)
  });
};

export default {
  GetListAsync,
  getBasicInfoListAsync,
  createBasicInfoAsync,
  getBasicInfoDetailAsync,
  updateBasicInfoAsync,
  sendApproveAndRejectAsync,
  uploadBasicInformationDocument,
  downloadFileAsync,
  removeFileAsync,
  getStatusCount,
  getDocumentAsync,
  getListHistoryAsync,
  updateAssignsAsync,
  getPlanProcurementListAsync,
  deleteBasicInformationAsync,
  sendJorPorRejectAsync,
};
