import {
  Input,
  Selector,
  Table,
} from 'components';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import {
  DepartmentListResponse,
  UserListSelectionParams,
  UserListSelectionResponse,
} from '../../models';
import { PositionListResponse } from '../../models/positionModel';
import {
  department,
  shareValue,
} from '../../services';
import { HttpStatusCode } from '../../utils';
import { calculateRowNumber } from '../../utils/constants/calculateRowNumber';
import toast from '../../utils/toast';
import { Modal } from './Modal';

interface Props {
  show: boolean;
  onHide?: () => void;
  data: UserListSelectionResponse[];
  total?: number;
  onSelectItem?: (id: string, name: string, section?: string) => void;
  onPageChange?: (size: number, page: number, params?: UserListSelectionParams) => void;
  onSearch?: (params: UserListSelectionParams) => void;
  title?: string;
  section?: string;
  departmentId?: string;
  departmentDisabled?: boolean
}

interface Options {
  value: string;
  label: string;
}

export function SearchNameModal(props: Props) {
  const [departments, setDepartments] = useState<Options[]>([]);
  const [departmentId, setDepartmentId] = useState<string>('');
  const [positions, setPositions] = useState<Options[]>([]);
  const [positionId, setPositionId] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  useEffect(() => {
    getDepartmentData();
    getPosition();
  }, []);

  useEffect(() => {
    if (props.show) {
      if (props.departmentId !== undefined) {
        setDepartmentId(props.departmentId);
        onSearch(props.departmentId, '', '');
      } else {
        setDepartmentId('');
        onSearch('', '', '');
      }
      setFullName('');
      setPositionId('');
    }
  }, [props.show, props.departmentId]);

  const getDepartmentData = async () => {
    const { data, status } = await department.getDepartmentsListAsync<DepartmentListResponse[]>();

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถดึงข้อมูล Department ได้');
      return;
    }

    const newData = data?.map((d) => ({ value: d.id, label: d.name }));

    setDepartments(newData);
  };

  const getPosition = async () => {
    const { data, status } = await shareValue.getPositionListSelectionAsync<PositionListResponse[]>();

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่สามารถดึงข้อมูล Position ได้');
      return;
    }

    setPositions(data);
  };

  const onSelect = (id: string, name: string) => {
    if (props.onSelectItem) {
      props.onSelectItem(id, name, props.section);
    }

    if (props.onHide) {
      props.onHide();
    }
  };

  const onSearch = useCallback((departmentId: string, positionId: string, fullName: string) => {
    if (props.onSearch) {
      setDepartmentId(departmentId);
      setPositionId(positionId);
      setFullName(fullName);

      props.onSearch({
        departmentId,
        positionId,
        fullName,
      });
    }
  }, [props]);

  const onPageSizeChange = useCallback((size: number, page: number) => {
    setSize(size);
    setPage(page);

    if (props.onPageChange) {
      props.onPageChange(size, page, {
        departmentId,
        positionId,
        fullName,
      });
    }
  }, [departmentId, fullName, positionId, props]);

  function clearInputSearch() {
    setDepartmentId('');
    setPositionId('');
    setFullName('');
  }

  return (
    <Modal
      show={props.show}
      size="xl"
      onHide={() => (props.onHide ? props.onHide() : null)}
    >
      <ModalBT.Header closeButton>
        <ModalBT.Title>รายชื่อ</ModalBT.Title>
      </ModalBT.Header>
      <ModalBT.Body className="p-0 ps-4">
        <Row className="mt-3 align-items-center">
          <Col sm={12}
            md={4}
            xl={3}>
            <Input label="ชื่อ-นามสกุล"
              value={fullName}
              onChange={setFullName} />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <Selector label="ฝ่าย/สำนัก"
              items={departments}
              value={departmentId}
              onChange={setDepartmentId}
              disabled={props.departmentDisabled !== undefined ? props.departmentDisabled : false} />
          </Col>
          <Col sm={12}
            md={4}
            xl={3}>
            <Selector label="ตำแหน่ง"
              items={positions}
              value={positionId}
              onChange={setPositionId} />
          </Col>
        </Row>
        <div className="d-flex gap-2">
          <Button
            variant="primary"
            className="px-3"
            onClick={() => {
              onSearch(departmentId, positionId, fullName);
            }}
          >
            <FaSearch className="me-2" />ค้นหา
          </Button>
          <Button
            className="px-4"
            variant="light"
            onClick={() => clearInputSearch()}
          >
            ล้าง
          </Button>
        </div>
        <Table className="my-3"
          onChange={onPageSizeChange}
          total={props.total}>
          <thead>
            <tr>
              <th style={{ minWidth: 20 }}>ลำดับ</th>
              <th style={{ minWidth: 50 }}>ชื่อ-นามสกุล</th>
              <th style={{ minWidth: 50 }}>ฝ่าย/สำนัก</th>
              <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
              <th style={{ minWidth: 25 }} />
            </tr>
          </thead>
          <tbody>
            {
              props.data.map((data, index) => (
                <tr key={index}>
                  <td className="text-center">{calculateRowNumber(index, page, size)}</td>
                  <td>{data.fullName}</td>
                  <td className="text-center">{data.departmentName}</td>
                  <td className="text-center">{data.positionName} </td>
                  <td>
                    <Button variant="primary"
                      className="px-3"
                      onClick={() => onSelect(data.id, data.fullName)}>
                      เลือก
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
