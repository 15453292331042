import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  Currency,
  Selector,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { ItemModel } from '../../../../../../models';
import { RentPaymentModel } from '../../../../../../models/CM/CM02Models';
import { InputNumber } from 'components/Controls/InputNumber';
import { valueContainerCSS } from 'react-select/dist/declarations/src/components/containers';

interface IRentPaymentProps {
  rentPayment: RentPaymentModel;
  onRentPaymentChange: (value: RentPaymentModel) => void;
  title: string;
  isDisabled?: boolean;
  contractTemplateCode: string;
  periodTypeDDL: ItemModel[];
}

const fields = [
  {
    code: 'CF01',
  },
  {
    code: 'CF02',
  },
  {
    code: 'CF03',
  },
  {
    code: 'CF04',
  },
  {
    code: 'CF05',
  },
  {
    code: 'CF06',
  },
  {
    code: 'CF07',
  },
  {
    code: 'CF08', // เช่ารถ
    rentAmount: 'ค่าเช่า (บาท)',
    perPeriod: 'ต่อ', // วัน เดือน ปี
  },
  {
    code: 'CF09',
  },
  {
    code: 'CF10',
  },
  {
    code: 'CF11', // เช่าเครื่องถ่ายเอกสาร
    rentAmount: 'ค่าเช่ารายเดือน เดือนละ/เครื่อง',
    rentQuantity: 'จำนวน',
    rentTotalAmount: 'ค่าเช่ารายเดือนรวม เดือนละ', // รวมค่าเช่า
    maximumQuantitySheetPerPeriod: 'ประเมินจากจำนวนสำเนาเอกสาร เดือนละ', //ประเมินจากจำนวนสำเนาเอกสาร เดือนละ 100 แผ่น
    minimumQuantitySheetPerPeriod: 'กรณีจำนวนถ่ายเอกสารไม่ถึง', //กรณีจำนวนถ่ายเอกสารไม่ถึง 80 แผ่น ต่อเดือน
    changeRentAmountPerSheet: 'เปลี่ยนเป็นคิดคำนวนอัตราสำเนาแผ่นละ'
  },
  {
    code: 'CF12',
  },
  {
    code: 'CF13',
  },
  {
    code: 'CF14',
  },
  {
    code: 'CF15',
  },
];

export function RentPayment(props: IRentPaymentProps) {
  const [rentPayment, setRentPayment] = useState<RentPaymentModel>(props.rentPayment);

  useEffect(() => {
    if (rentPayment) {
      props.onRentPaymentChange(rentPayment);
    }
  }, [rentPayment]);

  useEffect(() => {
    onChangeRentAmount();
  }, [rentPayment?.rentQuantity, rentPayment?.rentAmount]);

  const onChangeRentAmount = () => {
    if (rentPayment && rentPayment?.rentQuantity && rentPayment?.rentAmount) {
      setRentPayment(
        {
          ...rentPayment,
          rentTotalAmount: (rentPayment.rentAmount * rentPayment.rentQuantity),
        });
    }
  }

  const onNumberChange = useCallback((key: string, value: number) => {
    setRentPayment(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onTextChange = useCallback((key: string, value: string) => {
    setRentPayment(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <Card className='mt-3'>
      <Title text={`สัญญา${props.title ? props.title : 'ระยะเวลาการเช่า'}`} className='fs-5 text-primary' />
      <Row className='mt-3'>
        {
          fields.filter(f => f.code === props.contractTemplateCode).map((data) =>
            <>
              {
                data.code === 'CF08' &&
                (
                  <>
                    <Row>
                      <Col
                        md={3}>
                        <Currency
                          label={data.rentAmount}
                          placeholder={data.rentAmount}
                          value={rentPayment?.rentAmount}
                          onChange={(value) => onNumberChange('rentAmount', Number(value))}
                          disabled={props.isDisabled} />
                      </Col>
                      <Col
                        md={3}>
                        <Selector
                          label={data.perPeriod}
                          items={props.periodTypeDDL}
                          value={rentPayment?.periodUnit}
                          onChange={(value) => onTextChange('periodUnit', value)}
                          disabled={props.isDisabled} />
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                data.code === 'CF11' &&
                (
                  <>
                    <Row>
                      <Col sm={3}>
                        <Currency
                          label={data.rentAmount}
                          value={rentPayment?.rentAmount}
                          onChange={(value) => onNumberChange('rentAmount', Number(value))}
                          disabled={props.isDisabled} />
                      </Col>
                      <Col sm={3}>
                        <InputNumber
                          label={data.rentQuantity}
                          value={rentPayment?.rentQuantity}
                          onChange={(value) => onNumberChange('rentQuantity', Number(value))}
                          disabled={props.isDisabled} />
                      </Col>
                      <Col sm={3}>
                        <Currency
                          label={data.rentTotalAmount}
                          value={rentPayment?.rentTotalAmount}
                          onChange={(value) => setRentPayment({ ...rentPayment, rentTotalAmount: Number(value) })}
                          disabled={true} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <InputNumber
                          label={data.maximumQuantitySheetPerPeriod}
                          value={rentPayment?.maximumQuantitySheetPerPeriod}
                          onChange={(value) => onNumberChange('maximumQuantitySheetPerPeriod', Number(value))}
                          disabled={props.isDisabled} />
                      </Col>
                      <Col sm={3}>
                        <InputNumber
                          label={data.minimumQuantitySheetPerPeriod}
                          value={rentPayment?.minimumQuantitySheetPerPeriod}
                          onChange={(value) => onNumberChange('minimumQuantitySheetPerPeriod', Number(value))}
                          disabled={props.isDisabled} />
                      </Col>
                      <Col sm={3}>
                        <Currency
                          label={data.changeRentAmountPerSheet}
                          value={rentPayment?.changeRentAmountPerSheet}
                          onChange={(value) => onNumberChange('changeRentAmountPerSheet', Number(value))}
                          disabled={props.isDisabled} />
                      </Col>
                    </Row>
                  </>
                )
              }
            </>
          )
        }
      </Row>
    </Card>
  );
}
