import {
  useEffect,
  useState,
} from 'react';
import { Badge } from 'react-bootstrap';

interface Props {
  value: string;
}

const TRADER_STATUS = [
  {
    value: 'None',
    color: '#B7B7B7',
    label: 'รอดำเนินการ',
  },
  {
    value: 'Draft',
    color: '#B7B7B7',
    label: 'แบบร่าง',
  },
  {
    value: 'WaitingForAssign',
    color: '#FBBD01',
    label: 'รอมอบหมายงาน',
  },
  {
    value: 'Assigned',
    color: '#3CB371',
    label: 'มอบหมายงานแล้ว',
  },
  {
    value: 'Rejected',
    color: '#E94F45',
    label: 'ส่งกลับแก้ไข',
  },
  {
    value: 'WaitingForSign',
    color: '#FBBD01',
    label: 'รอลงนาม',
  },
  {
    value: 'Signed',
    color: '#3CB371',
    label: 'ลงนามแล้ว',
  },
  {
    value: 'Pending',
    color: '#FBBD01',
    label: 'รอลงนาม',
  },
  {
    value: 'Approve',
    color: '#3CB371',
    label: 'ลงนามแล้ว',
  },
  {
    value: 'Reject',
    color: '#E94F45',
    label: 'ส่งกลับแก้ไข',
  },
];

const STATUS_ASSIGNED = [
  {
    value: 'Draft',
    color: 'secondary',
    label: 'แบบร่าง',
  },
  {
    value: 'WaitingForAssign',
    color: 'warning',
    label: 'รอมอบหมายงาน',
  },
  {
    value: 'WaitingForSign',
    color: 'warning',
    label: 'รอลงนาม',
  },
  {
    value: 'Assigned',
    color: 'success',
    label: 'มอบหมายงานแล้ว',
  },
  {
    value: 'Rejected',
    color: 'danger',
    label: 'ส่งกลับแก้ไข',
  },
  {
    value: 'Signed',
    color: 'success',
    label: 'ลงนามแล้ว',
  },
  {
    value: 'Pending',
    color: 'warning',
    label: 'รอลงนาม',
  },
  {
    value: 'Approve',
    color: 'success',
    label: 'ลงนามแล้ว',
  },
  {
    value: 'Reject',
    color: 'danger',
    label: 'ส่งกลับแก้ไข',
  },
];

const ACCEPTORS_AGREE = [
  {
    value: 'Pending',
    color: 'warning',
    label: 'รอเห็นชอบ',
  },
  {
    value: 'Approve',
    color: 'success',
    label: 'เห็นชอบ',
  },
  {
    value: 'Reject',
    color: 'danger',
    label: 'ส่งกลับแก้ไข',
  },
];

const ACCEPTORS_APPROVAL = [
  {
    value: 'Pending',
    color: 'warning',
    label: 'รออนุมัติ',
  },
  {
    value: 'Approve',
    color: 'success',
    label: 'อนุมัติ',
  },
  {
    value: 'Reject',
    color: 'danger',
    label: 'ส่งกลับแก้ไข',
  },
];

function PurchaseOrderApprovalStatus(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const newStatus = TRADER_STATUS.find((s) => s.value === props.value);

      if (newStatus) {
        setStatus(newStatus);
      }
    }
  }, [props.value]);
  return (
    <>
      {props.value
        ? (
          <div className='directive-status'
            style={{ backgroundColor: status?.color }}>
            {status?.label}
          </div>
        ) : null}
    </>
  );
}

function PurchaseOrderApprovalAssignedStatus(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const newStatus = STATUS_ASSIGNED.find((s) => s.value === props.value);

      if (newStatus) {
        setStatus(newStatus);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color}
            className='text-white'>
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}

function AcceptorsApprovalStatus(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const newStatus = ACCEPTORS_APPROVAL.find((s) => s.value === props.value);

      if (newStatus) {
        setStatus(newStatus);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color}
            className='text-white'>
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}

function AcceptorsAgreeStatus(props: Props) {
  const [status, setStatus] = useState<{ value: string, color: string, label: string }>();

  useEffect(() => {
    if (props.value) {
      const newStatus = ACCEPTORS_AGREE.find((s) => s.value === props.value);

      if (newStatus) {
        setStatus(newStatus);
      }
    }
  }, [props.value]);

  return (
    <>
      {props.value
        ? (
          <Badge bg={status?.color}
            className='text-white'>
            {status?.label}
          </Badge>
        ) : null}
    </>
  );
}

export {
  PurchaseOrderApprovalStatus,
  PurchaseOrderApprovalAssignedStatus,
  AcceptorsAgreeStatus,
  AcceptorsApprovalStatus,
};
