import http from 'utils/axios';
import {
  ISectionCodeModel,
  ISectionModel,
  ISectionUpdateModel,
} from '../../models/SU/ISectionWithApproverModel';

const getListSectionAsync = async (sectionType?: string) => http.get<ISectionCodeModel>('sectioncode', { params: { sectionType } });

const getListSectionWithDefaultApproverAsync = async (sectionType?: string, departmentId?: string) => http.get<ISectionModel>('sectioncode/approvers', { params: { sectionType, departmentId } });

const getListSectionWithAmountAsync = async (sectionType?: string, departmentId?: string, financialLessThanEqualTo?: number) => http.get<ISectionModel>('sectioncode/approvers', { params: { sectionType, departmentId, financialLessThanEqualTo } });

const createSectionAsync = async (sectionId: string, defaultApproverId: string) => {
  const body = {
    defaultApproverId,
  };

  return http.post(`sectioncode/${sectionId}/approvers`, body);
};

const createListSectionAsync = async (departmentId: string, sectionType: string) => {
  const body = {
    departmentId,
    sectionType,
  };

  return http.post('sectioncode/create-list', body);
};

const deleteSectionAsync = async (sectionId: string, defaultApproverId: string) => http.delete(`sectioncode/${sectionId}/approvers/${defaultApproverId}`);

const updateMultipleSectionFinancialAmountAsync = async (request: ISectionUpdateModel[]) => {
  const body = {
    listFinancialRequest: request,
  };

  return http.put('sectioncode/update-list', body);
};
export default {
  getListSectionAsync,
  getListSectionWithDefaultApproverAsync,
  createSectionAsync,
  createListSectionAsync,
  deleteSectionAsync,
  updateMultipleSectionFinancialAmountAsync,
  getListSectionWithAmountAsync,
};
