import { ButtonCustom } from 'components';
import { StatusCMDelivery } from 'components/StatusCMDelivery';
import { StatusCMReceived } from 'components/StatusCMReceived';
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { useNavigate } from 'react-router';
import { ContractManagementReceiveModel } from '../../../../../models/CM/CM03Models';
import { thaiFormatDateWithSlash } from '../../../../../utils';
import { generateUniqueId } from '../../../../../utils/helper';

interface Props {
  index: number;
  data: ContractManagementReceiveModel;
  contractId: string;
}

export function TableExpandable2(props: Props) {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  function KeyboardArrow(showDetails: Boolean) {
    return (
      <>
        ดูรายเอียด{
          (showDetails) ?
            <MdKeyboardArrowUp /> :
            <MdKeyboardArrowDown />
        }
      </>
    );
  }

  const goToPage = (receiveId: string) => {
    navigate(`/contract-management/detail/${props.contractId}/inspection/${receiveId}`);
  };

  return (
    <>
      <tr className='text-center'
        key={props.index}>
        <td>{props.data.sequence}</td>
        <td>{thaiFormatDateWithSlash(props.data.receivedDate) ?? '--/--/----'}</td>
        <td>{props.data.receivedNumber}</td>
        <td>{props.data.remark}</td>
        <td>
          <div className='d-flex'>
            <StatusCMReceived systemStatus={props.data.status}
              systemName='cm-03' />
          </div>
        </td>
        <td className='text-end'>
          <ButtonCustom variant={props.data.status === 'Approved' ? 'outline-success' : 'outline-primary'}
            text='ไปยังรายการบันทึกตรวจรับ'
            icon='save-deliver'
            iconAlignRight
            onClick={() => goToPage(props.data.id)} />
        </td>
        <td>
          <ButtonCustom
            variant='light border-0'
            onClick={() => setExpanded(!expanded)}
            iconAlignRight
            text={KeyboardArrow(expanded)}
          />
        </td>
      </tr>
      {
        expanded && (
          <tr className='text-center data-info-full'>
            <td colSpan={8}>
              <Table>
                <thead>
                  <tr className='text-center'>
                    <th style={{ width: '10%' }}
                      className='text-muted'>งวดที่
                    </th>
                    <th style={{ width: '10%' }}
                      className='text-muted'>วันที่ส่งมอบ
                    </th>
                    <th style={{ width: '70%' }}
                      className='text-muted'>รายละเอียดส่งมอบ
                    </th>
                    <th style={{ width: 100 }}
                      className='text-muted'>สถานะ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.data.contractManagementDeliverys?.sort((a, b) => {
                    return a.sequence - b.sequence;
                  })
                    .map((cmd, index) => (
                      <tr key={generateUniqueId(cmd.id ?? '')}>
                        <td>{cmd.sequence}</td>
                        <td>{thaiFormatDateWithSlash(cmd.deliveryDate) ?? '--/--/----'}</td>
                        <td className='text-start'>
                          <ul className='inside'>
                            <li>{cmd.detail}</li>
                          </ul>
                        </td>
                        <td>
                          <div className='d-flex'>
                            <StatusCMDelivery systemStatue={cmd.status}
                              systemName='cm-03' />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </td>
          </tr>
        )
      }
    </>
  );
}
