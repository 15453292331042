import { Table } from 'components';
import Title from 'components/Controls/Title';
import { SearchNameModal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Card,
} from 'react-bootstrap';
import {
  FaPlus,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

function Step3({ onClickNext, onClickBack }: Props) {
  const [show, setShow] = useState(false);

  return (
    <div className="document">
      <Card className="mt-3">
        <Card.Body>
          <Title text="อำนาจอนุมัติ (ผู้่ลงนาม)" className="fs-5 text-primary" />
          <div className="d-flex justify-content-between my-3">
            <p>ข้อมูล</p>
            <Button
              variant="outline-primary"
              onClick={() => { setShow(true); }}
            >
              <FaPlus />เพิ่มข้อมูล
            </Button>
          </div>
          <Table total={1}>
            <thead>
              <tr>
                <th style={{ minWidth: 20 }}>ลำดับ</th>
                <th style={{ minWidth: 50 }}>รหัสพนักงาน</th>
                <th style={{ minWidth: 150 }}>ชื่อ-นามสกุล</th>
                <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
                <th style={{ minWidth: 25 }} />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">1</td>
                <td className="text-center">5900010</td>
                <td className="text-start">สมศรี แดนไทย</td>
                <td className="text-start">รักษาการผู้อำนวยการสำนักงบประมาณ</td>
                <td>
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="outline-primary"
                    >
                      <FaTrashAlt className="me-2" />ลบ
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-between pt-3">
        <Button
          onClick={onClickBack}
          className="me-2 px-3"
          variant="light"
        >
          <MdArrowBack className="me-2 pb-1 fs-5" />
          ย้อนกลับ
        </Button>
        <Button
          onClick={onClickNext}
          className="me-2 px-3"
        >
          ถัดไป
          <MdArrowForward className="ms-2 pb-1 fs-5" />
        </Button>
      </div>
      <SearchNameModal
        show={show}
        onHide={() => setShow(!show)}
        data={[]}
      />
    </div>
  );
}

export default Step3;
