import {
  DepartmentResponse,
  ItemModel,
  JorPor05Criteria,
} from 'models';
import {
  department,
  jorPor05,
  shareValue,
} from 'services';
import { SharedvalueGroup, SubGroup } from 'utils/constants/ContractEnum';

const GetGLAccountDDL = async () => {
  const glAccountitem: ItemModel[] = [];

  const response = await shareValue.geGLAccountSelectionAsync();
  const glAccountDataL: ItemModel[] = response.data;
  glAccountDataL.forEach((data) => glAccountitem.push({ label: data.label, value: data.value ?? '' }));

  return glAccountitem;
};

const PR0101Loader = async () => {
  const [departmentItems, basicInfoData, supplyMethod, positionOnBoardProcurementList, positionOnBoardInSpectionList, positionOnBoardMA, positionOnBoardSupervisor, medianPriceConsiderInformation, glAccountitem, periodType, biddingResultConsiderPeriodType] = await Promise.all([
    department.getDepartmentsAsync(),
    await jorPor05.getListDataAsync(10, 1, { isPermission: true } as JorPor05Criteria),
    shareValue.GetDDL('SupplyMethod'),
    shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardProcurement),
    shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardInSpection),
    shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardMA),
    shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardSupervisor),
    shareValue.GetDDL(SharedvalueGroup.MedianPriceConsiderInformation),
    GetGLAccountDDL(),
    shareValue.GetDDL(SharedvalueGroup.PeriodType),
    shareValue.GetDDL(SharedvalueGroup.BiddingResultConsiderPeriodType),
  ]);
  const departmentData: DepartmentResponse[] = departmentItems.data;
  const departmentList: ItemModel[] = departmentData.map(data => ({ label: data.name, value: data.id }));
  const SupplyMethodAll = [{ label: "ทั้งหมด", value: "All" }, ...supplyMethod];

  return {
    departmentItems: departmentList,
    basicInfoLists: basicInfoData.data.data,
    basicInfoListTotal: basicInfoData.data.totalRecords,
    supplyMethodDDL: SupplyMethodAll,
    positionOnBoardProcurementDDL: positionOnBoardProcurementList.data,
    positionOnBoardInSpectionDDL: positionOnBoardInSpectionList.data,
    positionOnBoardMADDL: positionOnBoardMA.data,
    positionOnBoardSupervisorDDL: positionOnBoardSupervisor.data,
    medianPriceConsiderInformationDDL: medianPriceConsiderInformation,
    glAccountDDL: glAccountitem,
    periodTypeDDL: periodType,
    biddingResultConsiderPeriodTypeDDL: biddingResultConsiderPeriodType
  };
};

export {
  PR0101Loader,
};
