import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  DatePicker,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { PeriodWorkingModel } from '../../../../../../models/CM/CM02Models';
import toast from '../../../../../../utils/toast';

interface IProps {
  manageScheduleAndCancelContract: PeriodWorkingModel;
  onManageScheduleAndCancelContractChange: (value: PeriodWorkingModel) => void;
  isDisabled?: boolean;
}

export function PeriodWorkingDetail(props: IProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [manageScheduleAndCancelContract, setManageScheduleAndCancelContract] = useState<PeriodWorkingModel>(props.manageScheduleAndCancelContract);

  useEffect(() => {
    props.onManageScheduleAndCancelContractChange(manageScheduleAndCancelContract);
  }, [manageScheduleAndCancelContract]);

  useEffect(() => {
    if (props.manageScheduleAndCancelContract && firstLoad) {
      setManageScheduleAndCancelContract(props.manageScheduleAndCancelContract);
      setFirstLoad(false);
    }
  }, [props.manageScheduleAndCancelContract, firstLoad]);

  const onTextChange = useCallback((key: string, value: string) => {
    setManageScheduleAndCancelContract(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onEndDateChange = useCallback((key: string, value: string) => {
    const endDate = new Date(value);
    const startDate = new Date(manageScheduleAndCancelContract.startWorkingDate);

    if (startDate > endDate) {
      toast.warn('วันที่สิ้นสุดต้องมากกว่าวันที่เริ่มต้น');
      return;
    }

    onTextChange(key, value);
  }, [manageScheduleAndCancelContract]);

  return (
    <Card className='mt-3'>
      <Title text='กำหนดเวลาแล้วเสร็จและการบอกเลิกสัญญา'
        className='fs-5 text-primary' />
      <Row>
        <label className='mb-3'>ร้อยละของเงินค่าจ้างที่จะหักในแต่ละงวดเพื่อชดใช้คืนเงินค่าจ้างล่วงหน้า</label>
        <Col sm={6}
          md={3}>
          <DatePicker
            label='คู่ค้าต้องเริ่มทำงานภายในวันที่'
            value={manageScheduleAndCancelContract?.startWorkingDate}
            onChange={(value) => onTextChange('startWorkingDate', value.toISOString())}
            rule={{ required: true }}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={6}
          md={3}>
          <DatePicker
            label='งานต้องแล้วเสร็จภายในวันที่'
            value={manageScheduleAndCancelContract?.endWorkingDate}
            onChange={(value) => onEndDateChange('endWorkingDate', value.toISOString())}
            rule={{ required: true }}
            disabled={props.isDisabled} />
        </Col>
      </Row>
    </Card>
  );
}
