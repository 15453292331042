import {
  ButtonCustom,
  Modal,
  Selector,
  Table,
} from 'components';
import {
  DatePicker,
  Input,
  InputTextArea,
} from 'components/Controls';
import { useState } from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import CardTotalProjects from './components/CardTotalProjects';
import LineChart from './components/Charts/LineChart';
import InputPassword from './components/Controls/InputPassword';
import Title from './components/Controls/Title';
import Headingform from './components/Headingform';
import StepProgress from './components/StepProgress';
import Pagination from 'components/Pagination';

const obj = [{
  title: <p>ข้อมูลเอกสาร <br /> บันทึกข้อความแต่งตั้ง</p>,
  displayIndex: '1',
  child: <Table />,
},
{
  title: <p>รายชื่อ <br />  คณะกรรมการราคากลาง</p>,
  displayIndex: '2',
  child: <Table />,
},
{
  title: <p>รายชื่อ <br />  คณะกรรมการร่างขอบเขตของงาน (TOR)</p>,
  displayIndex: '3',
  child: <p>yuyii 3</p>,
},
{
  title: <p>ตัวอย่าง <br /> เอกสารบันทึกข้อความแต่งตั้ง</p>,
  displayIndex: '4',
  child: <Table />,
},
{
  title: <p>เอกสารบันทึกข้อความแต่งตั้ง</p>,
  displayIndex: '5',
  child: <Table />,
},
];

function Demo() {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div>
      <Row className="pt-5">
        <Col xs={12} lg={6}>
          <LineChart />
        </Col>
        <Col xs={12} className="my-2">
          <StepProgress progressBarData={obj} />
        </Col>
        <Col xs={12} md={4} className="my-2">
          <CardTotalProjects
            label="จำนวนโครงการทั้งหมด"
            total={50000}
            unit="โครงการ"
          />
        </Col>

        <h2>From Components</h2>
        <Col xs={12} className="my-2">
          <Title text="เข้าสู่ระบบ" className="text-dark fs-5" />
        </Col>
        <Col xs={12} lg={3} className="my-2">
          <Input type="text" label="Input df" placeholder="ชื่อผู้ใช้งาน" />
        </Col>
        <Col xs={12} lg={3} className="my-2">
          <Input type="text" label="Input username" placeholder="ชื่อผู้ใช้งาน" />
        </Col>
        <Col xs={12} lg={3} className="my-2">
          <InputPassword type="password" label="Input Password" formCustom="password" placeholder="รหัสผ่าน" />
        </Col>
        <Col xs={12} lg={3} className="my-2">
          <Input type="email" label="Input Email" placeholder="อีเมล" />
        </Col>
        <Col xs={12} lg={3} className="my-2">
          <Selector
            label="Selector"
            placeholder="เลือกหน่วยงาน"
          />
        </Col>
        <Col xs={12} lg={3} className="my-2">
          <DatePicker
            label="Datepicker"
            placeholder="วันที่เอกสารบันทึกข้อความแต่งตั้ง"
          />
        </Col>
        <Col xs={12} lg={3} className="my-2">
          <InputTextArea label="Example textarea" placeholder="Example textarea" />
        </Col>
        <Col xs={12} className="my-2">
          <ButtonCustom className="me-2" text="เข้าสู่ระบบ" />
          <ButtonCustom className="me-2" variant="light" text="Download CSV" icon="download" />
          <ButtonCustom className="me-2" variant="light" text="Export" icon="export" />
          <ButtonCustom className="me-2" variant="light" text="Add" icon="add" />
          <ButtonCustom className="me-2" variant="light" text="Search" icon="search" />
          <ButtonCustom className="me-2" variant="light" text="แก้ไข" icon="edit" />
          <ButtonCustom className="me-2" variant="light" text="ย้อนกลับ" icon="arrowback" />
          <ButtonCustom className="me-2" variant="light" text="ถัดไป" icon="arrowforward" iconAlignRight />
        </Col>
        <Col xs={12} className="my-2">
          <Headingform
            title="เอกสารบันทึกข้อความแต่งตั้ง"
            textBtn="เพิ่มเอกสารบันทึกข้อความแต่งตั้ง"
            iconBtn="add"
          />
        </Col>
        <Col xs={12}><Pagination total={1} /></Col>
        <Col xs={12} className="my-3"><Table /></Col>
        <Col xs={12} className="d-flex gap-2">
          <Button onClick={() => setShowModal(true)}>
            Modal
          </Button>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header>
              <div>Modal</div>
            </Modal.Header>
          </Modal>
        </Col>
      </Row>
    </div>
  );
}

export default Demo;
