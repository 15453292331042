import {
  ApproverStatus,
  Card,
  Currency,
  DatePicker,
  FileValue,
  Input,
  InputTextArea,
  Modal,
  PlanStatus,
  SearchBuNameModal,
  Selector,
  Table,
  ButtonCustom,
  Radio,
  SupplyMethodEnum,
} from 'components';
import Title from 'components/Controls/Title';
import {
  CreatorLevel,
  DepartmentId,
  JorPorCode,
} from 'constant/basicInformation';
import {
  CommerceInformationModel,
  DepartmentDirectorAgree,
  JorporDirectorBeInformed,
  PlanProcurementCommerceInformationModel,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
} from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAngleLeft,
  FaCircle,
  FaEraser,
  FaHistory,
  FaPlus,
  FaRegCheckCircle,
  FaSave,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  account,
  planProcurement as planProcurementService,
  shareValue,
} from 'services';
import {
  downloadFile,
  fullDatetime,
  HttpStatusCode,
  ProcurementSection,
  submitForm,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';
import {
  AcceptorStatus,
  PlanStatus as PlanStatusCons,
} from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { generateUniqueId, isNull } from '../../../utils/helper';
import { UploadFile } from '../Procurement/UploadFile';
import { Context } from './Detail';
import { AccountModel } from 'models/accountModel';
import { SharedvalueGroup } from 'utils/constants/ContractEnum';

interface Props {
  onClickBack?: () => void;
}

type Loader = {
  departments: { value: string, label: string }[],
  positions: { value: string, label: string }[],
  supplyMethods: { value: string, label: string }[],
  supplyMethodTypes: { value: string, label: string }[],
}

type UserCriteria = {
  name: string;
  departmentId: string;
  positionId: string;
}

type UserResponse = {
  id: string;
  no: number;
  name: string;
  department: string;
  position: string;
  status: string;
  UserDate: Date;
  isAvailable: boolean;
}

export default function Step1(props: Props) {
  const loader = useLoaderData() as Loader;
  const { currentStep, planProcurement, setPlanProcurement, reGetDetail, checkOverLimitPrice, readonly } = useContext(Context);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [supplyMethodSpecialTypes, setSupplyMethodSpecialTypes] = useState<{ value: string, label: string }[]>([]);
  const [budgetYearItems, setBudgetYearItems] = useState<{ value: string; label: string }[]>([]);
  const { userId, departmentId, departmentCode, inRefLevel } = useAppContext();
  const [section, setSection] = useState<string>();
  const [remarkChangeOrCancelIsRequired, setRemarkChangeOrCancelIsRequired] = useState<boolean>(false);
  const [showPendingModal, setShowPendingModal] = useState<boolean>(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [commerceInformationData, setCommerceInformationData] = useState<CommerceInformationModel[]>([]);
  const [isconsider, setIsconsider] = useState('');
  const [planProcurementCommerceInformationData, setPlanProcurementCommerceInformationData] = useState<PlanProcurementCommerceInformationModel[]>([]);
  const [procurementCommerceData, setProcurementCommerceData] = useState<PlanProcurementCommerceInformationModel[]>([]);

  const onChangeCheckCommerceInfoResult = (commerceInformationId: string | number) => {
    const planProcurementCommerceInformationResult = [...planProcurementCommerceInformationData];

    planProcurementCommerceInformationResult.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        data.result = false;
      });

    const index = planProcurementCommerceInformationData?.findIndex(s => s.planProcurementCommerceInformationId === commerceInformationId);
    planProcurementCommerceInformationResult[index].result = true;

    setPlanProcurementCommerceInformationData(planProcurementCommerceInformationResult);
  };

  useEffect(() => {

    getPlanProcurementCommerceInformationAsync();

    if (planProcurement.planProcurementCommerceInformation) {

      if (planProcurement.planProcurementCommerceInformation.filter(x => x.result === true).length > 0) {

        const result = planProcurement.planProcurementCommerceInformation.filter(x => x.result === true)[0].planProcurementCommerceInformationId

        setIsconsider(result);
      }

      setPlanProcurementCommerceInformationData(planProcurement.planProcurementCommerceInformation);
    }

  }, [planProcurement.planProcurementCommerceInformation]);

  const setCommerceInformationBySharedValueAsync = async (data: any[], departmentId: string, isChangeDepartment: boolean) => {

    const commerceInformation: CommerceInformationModel[] = [];
    const planProcurementCommerceInformation: PlanProcurementCommerceInformationModel[] = [];

    data.filter(x => x.code === departmentId || x.code === undefined || x.code === null || x.code === '').sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {

        const newData: CommerceInformationModel = {
          label: data.label,
          value: data.value,
          result: false,
        };

        commerceInformation.push(newData);

        const newDataPlanProcurementCommerceInformation: PlanProcurementCommerceInformationModel = {
          planProcurementCommerceInformationLabel: data.label,
          planProcurementCommerceInformationId: data.value,
          result: false,
          departmentId: departmentId,
          sequence: index + 1,
          id: undefined
        };

        planProcurementCommerceInformation.push(newDataPlanProcurementCommerceInformation);

      });

    setCommerceInformationData(commerceInformation);

    if (!planProcurement.planProcurementCommerceInformation || isChangeDepartment) {
      setPlanProcurementCommerceInformationData(planProcurementCommerceInformation);
    }
  };

  const getPlanProcurementCommerceInformationAsync = async () => {
    const res = await shareValue.getListSharedValueAsync(SharedvalueGroup.ProcurementCommerce);
    if (res.status === HttpStatusCode.OK) {

      setProcurementCommerceData(res.data);

      if (planProcurement.departmentId === DepartmentId.BorPor || planProcurement.departmentId === DepartmentId.BorSorSor || planProcurement.departmentId === DepartmentId.NPA || planProcurement.departmentId === DepartmentId.SorOr) {
        setCommerceInformationBySharedValueAsync(res.data, planProcurement.departmentId, false);
      }

    }
  };

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const getUserProfileByIdAsync = async (userId: string) => {
    const { data, status } = await account.getUserByIdAsync(userId);

    if (status === HttpStatusCode.OK) {
      return data as AccountModel;
    }
  };


  const getUserDataAsync = async (userId: string) => {

    const userOwnerData = await getUserProfileByIdAsync(userId);
    if (!userOwnerData) {
      return;
    }

    const empty = ' ';

    if (section === ProcurementSection.DEPARTMENT_DIRECTOR_AGREE) {
      if (!planProcurement.departmentDirectorAgree) {
        planProcurement.departmentDirectorAgree = [];
      }

      setPlanProcurement({
        ...planProcurement,
        departmentDirectorAgree: [
          ...planProcurement.departmentDirectorAgree,
          {
            userId: userOwnerData.id,
            fullName: userOwnerData.firstName + empty + userOwnerData.lastName,
            departmentName: userOwnerData.departmentName,
            positionName: userOwnerData.positionName,
            sectionCode: ProcurementSection.DEPARTMENT_DIRECTOR_AGREE,
            sequence: planProcurement.departmentDirectorAgree.length + 1,
            status: AcceptorStatus.PENDING,
          } as DepartmentDirectorAgree,
        ],
      });
    }

    if (section === ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED) {
      if (!planProcurement.jorporDirectorBeInformed) {
        planProcurement.jorporDirectorBeInformed = [];
      }

      setPlanProcurement({
        ...planProcurement,
        jorporDirectorBeInformed: [
          ...planProcurement.jorporDirectorBeInformed,
          {
            userId: userOwnerData.id,
            fullName: userOwnerData.firstName + empty + userOwnerData.lastName,
            departmentName: userOwnerData.departmentName,
            positionName: userOwnerData.positionName,
            sectionCode: ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED,
            sequence: planProcurement.jorporDirectorBeInformed.length + 1,
            status: AcceptorStatus.PENDING,
          } as JorporDirectorBeInformed,
        ],
      });
    }
  };

  const isChangeAnnualPage = location.pathname.includes('procurement-plan-adjust/annual');
  const isCancelAnnualPage = location.pathname.includes('procurement-plan-cancel/annual');

  const canRemoveFile = useMemo(() => {
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  useEffect(() => {
    if (isChangeAnnualPage || isCancelAnnualPage) {
      setRemarkChangeOrCancelIsRequired(true);
    } else {
      setRemarkChangeOrCancelIsRequired(false);
    }
  }, []);

  // budget year item
  useEffect(() => {
    const date = new Date();
    let year = (date.getUTCFullYear() + 543) - 10;

    const items = Array.from({ length: 20 })
      .map((i) => {
        year += 1;

        return {
          label: year.toString(),
          value: year.toString(),
        };
      });

    setBudgetYearItems(items);
  }, []);

  useEffect(() => {
    if (planProcurement.supplyMethod) {
      supplyMethodOnChangeAsync(planProcurement.supplyMethod);
    } else {
      setSupplyMethodSpecialTypes([]);

      setPlanProcurement({
        ...planProcurement,
        supplyMethodSpecialType: '',
      });
    }
  }, [planProcurement.supplyMethod]);

  // get default user
  useEffect(() => {
    if (!planProcurement.id) {
      getDefaultUserAsync();
    }
  }, [planProcurement.id]);

  const getDefaultUserAsync = async () => {
    const [
      departmentDirectorAgreeDefault,
      jorporDirectorBeInformedDefault,
    ] = await Promise.all([
      account.getDefaultDepartmentDirectorAgree(ProcurementSection.DEPARTMENT_DIRECTOR_AGREE),
      account.getDefaultDepartmentDirectorAgree(ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED),
    ]);

    const departmentDirectorAgree = departmentDirectorAgreeDefault.data as DepartmentDirectorAgree[];
    const jorporDirectorBeInformed = jorporDirectorBeInformedDefault.data as JorporDirectorBeInformed[];

    setPlanProcurement({
      ...planProcurement,
      departmentDirectorAgree: [...departmentDirectorAgree?.map(d => ({ ...d, status: AcceptorStatus.PENDING }))],
      jorporDirectorBeInformed: [...jorporDirectorBeInformed],
    });
  };

  const handlerOnSubmitAsync = async (status?: string) => {
    submitForm(null, true);

    if (status === PlanStatusCons.WaitingApprovePlan) {
      if (!planProcurement.departmentDirectorAgree ||
        planProcurement.departmentDirectorAgree?.length === 0) {
        toast.warn('ต้องมีฝ่ายเห็นชอบอย่างน้อย 1 คน');

        return;
      }

      if (!planProcurement.jorporDirectorBeInformed ||
        planProcurement.jorporDirectorBeInformed?.length === 0) {
        toast.warn('ต้องมีผอ.จพ.รับทราบอย่างน้อย 1 คน');

        return;
      }

      if (planProcurement.departmentDirectorAgree.every(d => d.isAvailable)) {
        toast.warn('ต้องมีฝ่ายเห็นชอบที่สามารถปฏิบัติงานได้อย่างน้อย 1 คน');

        return;
      }
    }

    // if (planProcurement.supplyMethod === SupplyMethodEnum.MethodId80 && (planProcurement.departmentId === DepartmentId.BorPor || planProcurement.departmentId === DepartmentId.BorSorSor || planProcurement.departmentId === DepartmentId.NPA || planProcurement.departmentId === DepartmentId.SorOr) && planProcurementCommerceInformationData.filter(x => x.result === true).length === 0) {
    //   toast.warn('กรุณาระบุการจัดซื้อจัดจ้างและการบริหารพัสดุที่เกี่ยวกับการพาณิชย์โดยตรง');

    //   return;
    // }

    planProcurement.planProcurementCommerceInformation = planProcurementCommerceInformationData;

    const checkRequestField = planProcurement.departmentId
      && planProcurement.name?.trim()
      && planProcurement.supplyMethod
      && planProcurement.supplyMethodType
      && planProcurement.supplyMethodSpecialType
      && planProcurement.budget
      && !!planProcurement.expectingProcurementAt
      && (isChangeAnnualPage ? planProcurement.changeRemark?.trim() : true)
      && (isCancelAnnualPage ? planProcurement.cancelRemark?.trim() : true);

    if (!checkRequestField) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');

      return;
    }

    planProcurement.type = 'AnnualPlan';

    if (status) {
      planProcurement.status = status;
    }

    if (planProcurement.id) {
      const res = await planProcurementService
        .updateAsync(planProcurement, status === PlanStatusCons.WaitingApprovePlan);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.ACCEPTED) {
        checkOverLimitPrice();
        reGetDetail(planProcurement.id);
        toast.success('บันทึกสำเร็จ');
        setShowPendingModal(false);
      }
    } else {
      const res = await planProcurementService
        .createAsync(planProcurement);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        planProcurement.id = res.data.id;

        setPlanProcurement(planProcurement);

        navigate(`${location.pathname}/${res.data.id}`);

        checkOverLimitPrice();
        reGetDetail(planProcurement.id);
        toast.success('บันทึกสำเร็จ');
        setShowPendingModal(false);
      }
    }
  };

  const handlerOnChange = (key: string, value: string | number | Date | undefined) => {

    setPlanProcurement({
      ...planProcurement,
      [key]: value,
    });

    if (
      (value === SupplyMethodEnum.MethodId80 && ((planProcurement.departmentId === DepartmentId.BorPor || planProcurement.departmentId === DepartmentId.BorSorSor || planProcurement.departmentId === DepartmentId.NPA || planProcurement.departmentId === DepartmentId.SorOr))) ||
      (planProcurement.supplyMethod === SupplyMethodEnum.MethodId80 && ((value === DepartmentId.BorPor || value === DepartmentId.BorSorSor || value === DepartmentId.NPA || value === DepartmentId.SorOr)))) {

      const departmentId = key === 'departmentId' ? value : planProcurement.departmentId;

      setCommerceInformationBySharedValueAsync(procurementCommerceData, departmentId, key === 'departmentId');
    }
  };

  const userOnSelect = (
    userId: string,
    fullName: string,
    departmentName: string,
    positionName: string,
  ) => {
    if (section === ProcurementSection.DEPARTMENT_DIRECTOR_AGREE) {
      if (!planProcurement.departmentDirectorAgree) {
        planProcurement.departmentDirectorAgree = [];
      }

      const maxValueSequence = planProcurement.departmentDirectorAgree.length > 0 ? Math.max.apply(null,
        planProcurement.departmentDirectorAgree.map((x) => {
          return x.sequence;
        })) : 0;

      setPlanProcurement({
        ...planProcurement,
        departmentDirectorAgree: [
          ...planProcurement.departmentDirectorAgree,
          {
            userId,
            fullName,
            departmentName,
            positionName,
            sectionCode: ProcurementSection.DEPARTMENT_DIRECTOR_AGREE,
            sequence: maxValueSequence + 1,
            status: AcceptorStatus.PENDING,
          } as DepartmentDirectorAgree,
        ],
      });
    }

    if (section === ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED) {
      if (!planProcurement.jorporDirectorBeInformed) {
        planProcurement.jorporDirectorBeInformed = [];
      }

      const maxValueSequence = planProcurement.jorporDirectorBeInformed.length > 0 ? Math.max.apply(null,
        planProcurement.jorporDirectorBeInformed.map((x) => {
          return x.sequence;
        })) : 0;

      setPlanProcurement({
        ...planProcurement,
        jorporDirectorBeInformed: [
          ...planProcurement.jorporDirectorBeInformed,
          {
            userId,
            fullName,
            departmentName,
            positionName,
            sectionCode: ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED,
            sequence: maxValueSequence + 1,
            status: AcceptorStatus.PENDING,
          } as JorporDirectorBeInformed,
        ],
      });
    }
  };

  const supplyMethodOnChangeAsync = async (id: string) => {
    const res = await shareValue.getSupplyMethodListAsync(id);

    if (res.status === HttpStatusCode.OK) {
      setSupplyMethodSpecialTypes(res.data);
    }
  };

  const formTableOnChange = (
    section: string,
    index: number,
    key: string,
    value: string | boolean,
  ) => {
    if (section === ProcurementSection.DEPARTMENT_DIRECTOR_AGREE) {

      if (typeof (value) === 'boolean' && !value) {
        planProcurement.departmentDirectorAgree[index] = {
          ...planProcurement.departmentDirectorAgree[index],
          [key]: value,
          isAvailableRemark: '',
        };
      } else {
        planProcurement.departmentDirectorAgree[index] = {
          ...planProcurement.departmentDirectorAgree[index],
          [key]: value,
        };
      }

      setPlanProcurement({
        ...planProcurement,
        departmentDirectorAgree: planProcurement.departmentDirectorAgree,

      });
    }

    if (section === ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED) {
      planProcurement.jorporDirectorBeInformed[index] = {
        ...planProcurement.jorporDirectorBeInformed[index],
        [key]: value,
      };

      setPlanProcurement({
        ...planProcurement,
        jorporDirectorBeInformed: planProcurement.jorporDirectorBeInformed,
      });
    }
  };

  const uploadFileOnChangeAsync = async (file: File) => {
    if (planProcurement.id) {
      const res = await planProcurementService.uploadFileAsync(
        planProcurement.id,
        file,
      );

      if (res.status === HttpStatusCode.ACCEPTED) {
        setPlanProcurement({
          ...planProcurement,
          documents: [
            ...planProcurement.documents,
            {
              file,
            },
          ],
        });

        toast.success('อัปโหลดไฟล์สำเร็จ');

        reGetDetail(planProcurement.id);
      }
    } else {
      if (!planProcurement.documents) {
        planProcurement.documents = [];
      }

      setPlanProcurement({
        ...planProcurement,
        documents: [
          ...planProcurement.documents,
          {
            file,
          },
        ],
      });
    }
  };

  const files = useCallback(() => {
    const files = planProcurement.documents?.filter((d) => d.id && d.name);

    return files?.map((f) => ({
      id: f.id,
      name: f.name,
      attachmentBy: f.byUserId,
    })) as FileValue[];
  }, [planProcurement.documents]);

  const removeFileAsync = async (i: number, id: string | undefined) => {
    if (id) {
      const res = await planProcurementService.removeFileAsync(planProcurement.id, id);

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
      }
    }

    planProcurement.documents.splice(i, 1);

    setPlanProcurement({
      ...planProcurement,
      documents: [...planProcurement.documents],
    });
  };

  const downloadFileAsync = async (index: number, documentId?: string) => {
    if (documentId) {
      const res = await planProcurementService
        .downloadFileAsync(planProcurement.id, documentId);

      if (res.status === HttpStatusCode.OK) {
        const file = planProcurement.documents[index];

        downloadFile(res.data);
      }
    } else {
      const file = planProcurement.documents[index];

      if (file.file) {
        downloadFile(file.file);
      }
    }
  };

  const removeUser = (userId: string, section: ProcurementSection) => {
    if (section === ProcurementSection.DEPARTMENT_DIRECTOR_AGREE) {
      const index = planProcurement.departmentDirectorAgree
        .findIndex((d) => d.userId === userId);

      planProcurement.departmentDirectorAgree.splice(index, 1);

      setPlanProcurement({
        ...planProcurement,
        departmentDirectorAgree: [...planProcurement.departmentDirectorAgree],
      });
    }

    if (section === ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED) {
      const index = planProcurement.jorporDirectorBeInformed
        .findIndex((d) => d.userId === userId);

      planProcurement.jorporDirectorBeInformed.splice(index, 1);

      setPlanProcurement({
        ...planProcurement,
        jorporDirectorBeInformed: [...planProcurement.jorporDirectorBeInformed],
      });
    }
  };

  const canEdit = useMemo(() => {
    if (!Object.keys(planProcurement).length || currentStep !== 1 || !userId) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftPlan &&
      planProcurement.status !== PlanStatusCons.RejectPlan) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canAddApprover = useMemo(() => {
    if (!Object.keys(planProcurement).length || !userId || (currentStep !== 1 && currentStep !== 2)) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftPlan &&
      planProcurement.status !== PlanStatusCons.RejectPlan &&
      planProcurement.status !== PlanStatusCons.WaitingApprovePlan) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (planProcurement.status === PlanStatusCons.WaitingApprovePlan) {
      if (planProcurement.departmentDirectorAgree?.some(d =>
        !d.isAvailable && d.status === AcceptorStatus.PENDING && d.id)) {
        return false;
      }
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canEditFormApprover = useCallback((approverStatus: string) => {
    if (!Object.keys(planProcurement).length || !userId || (currentStep !== 1 && currentStep !== 2)) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftPlan &&
      planProcurement.status !== PlanStatusCons.RejectPlan &&
      planProcurement.status !== PlanStatusCons.WaitingApprovePlan) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (approverStatus !== AcceptorStatus.PENDING) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canRemoveApprover = useCallback((approverId: string) => {
    if (!Object.keys(planProcurement).length || !userId || (currentStep !== 1 && currentStep !== 2)) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftPlan &&
      planProcurement.status !== PlanStatusCons.RejectPlan &&
      planProcurement.status !== PlanStatusCons.WaitingApprovePlan) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (planProcurement.status === PlanStatusCons.WaitingApprovePlan &&
      approverId) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canAddJorPor = useMemo(() => {
    if (!Object.keys(planProcurement).length || !userId || currentStep !== 1) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftPlan &&
      planProcurement.status !== PlanStatusCons.RejectPlan) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canRemoveJorPor = useMemo(() => {
    if (!Object.keys(planProcurement).length || !userId || currentStep !== 1) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftPlan &&
      planProcurement.status !== PlanStatusCons.RejectPlan) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canSave = useMemo(() => {
    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    if ((planProcurement.jorporDirectorBeInformed !== undefined && planProcurement.jorporDirectorBeInformed.some(a => isNull(a.delegateUserId, a.userId) === userId))
      && (planProcurement.status === PlanStatusCons.DraftPlan
        || planProcurement.status === PlanStatusCons.RejectPlan
        || planProcurement.status === PlanStatusCons.Assigned)) {
      return true;
    }

    if (!Object.keys(planProcurement).length || !userId || currentStep !== 1) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftPlan &&
      planProcurement.status !== PlanStatusCons.RejectPlan) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const canSendApprove = useMemo(() => {
    if (!Object.keys(planProcurement).length || !userId || currentStep !== 1) {
      return false;
    }

    if (planProcurement.status !== PlanStatusCons.DraftPlan &&
      planProcurement.status !== PlanStatusCons.RejectPlan) {
      return false;
    }

    if (inRefLevel && Object.values(CreatorLevel)
      .filter(lv => lv.includes(inRefLevel)).length === 0) {
      return false;
    }

    //check same department and jorpor
    if (planProcurement.departmentId !== departmentId && departmentCode !== JorPorCode.CODE) {
      return false;
    }

    return true;
  }, [planProcurement, currentStep, userId]);

  const isChangedOrIsCanceled = (isCanceled: boolean, isChanged: boolean) => {
    if (isCanceled) {
      return (
        <div className='status'>
          <span>
            <FaCircle className='is-canceled' />อยู่ระหว่างขอยกเลิก
          </span>
        </div>
      );
    }

    if (isChanged) {
      return (
        <div className='status'>
          <span>
            <FaCircle className='is-changed' />อยู่ระหว่างขอแก้ไข
          </span>
        </div>
      );
    }

    return null;
  };

  const remarkChangeOrCancelValue = useMemo(() => {
    if (isChangeAnnualPage) {
      return planProcurement.changeRemark;
    }

    if (isCancelAnnualPage) {
      return planProcurement.cancelRemark;
    }
  }, [planProcurement]);

  const onRemarkChangeOrCancelValue = useCallback((value: string) => {
    if (isChangeAnnualPage) {
      setPlanProcurement({
        ...planProcurement,
        changeRemark: value,
      });

      return;
    }

    if (isCancelAnnualPage) {
      setPlanProcurement({
        ...planProcurement,
        cancelRemark: value,
      });

      return;
    }
  }, []);

  return (
    <>
      {/* Form */}
      <Card>
        <Title
          text='ข้อมูลรายการจัดซื้อจัดจ้าง'
          className='fs-5 text-primary'
          extraElement={(
            <div className='d-flex gap-2'>
              <div className='d-flex align-items-center'>
                {isChangedOrIsCanceled(planProcurement.isRefCancel, planProcurement.isRefChange)}
              </div>
              <PlanStatus
                value={planProcurement.status} />
              <Button
                className='d-flex align-items-center gap-2'
                onClick={() => setShowHistoryModal(!showHistoryModal)}
                variant='outline-primary'>
                <FaHistory className='me-1' />
                ประวัติการใช้งาน
              </Button>
            </div>
          )}
        />
        <Row className='mt-3'>
          <Col sm={6}
            lg={4}
            xxl={3}>
            <Input
              label='เลขที่รายการจัดซื้อจัดจ้าง'
              value={planProcurement.planNumber}
              disabled
              name='planNumber'
            />
          </Col>
          <Col sm={6}
            lg={4}
            xxl={3}>
            <Selector
              label='ฝ่าย/สำนัก'
              rule={{ required: true }}
              value={planProcurement.departmentId}
              onChange={(val) => handlerOnChange('departmentId', val)}
              items={loader.departments}
              disabled={
                (planProcurement.status !== PlanStatusCons.DraftPlan && planProcurement.status !== PlanStatusCons.RejectPlan)
                || departmentCode !== JorPorCode.CODE}
              name='departmentId'
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}
            lg={4}
            xxl={3}>
            <Selector
              label='วิธีการจัดหา'
              value={planProcurement.supplyMethod}
              onChange={(val) => handlerOnChange('supplyMethod', val)}
              items={loader.supplyMethods}
              rule={{ required: true }}
              disabled={!canEdit}
              name='supplyMethod'
            />
          </Col>
          <Col sm={4}
            lg={4}
            xxl={3}>
            <Selector
              className='no-label'
              value={planProcurement.supplyMethodType}
              onChange={(val) => handlerOnChange('supplyMethodType', val)}
              items={loader.supplyMethodTypes}
              rule={{ required: true }}
              disabled={!canEdit}
              name='supplyMethodType'
            />
          </Col>
          <Col sm={4}
            lg={4}
            xxl={3}>
            <Selector
              className='no-label'
              value={planProcurement.supplyMethodSpecialType}
              onChange={(val) => handlerOnChange('supplyMethodSpecialType', val)}
              rule={{ required: true }}
              items={supplyMethodSpecialTypes}
              disabled={!canEdit}
              name='supplyMethodSpecialType'
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}
            lg={4}
            xxl={3}>
            <Selector
              label='ปีงบประมาณ'
              rule={{ required: true }}
              disabled={true}
              value={planProcurement.budgetYear?.toString()}
              onChange={(val) => handlerOnChange('budgetYear', val)}
              items={budgetYearItems}
              name='budgetYear'
            />
          </Col>
        </Row>
        <Row>
          <Col lg={8}
            xxl={6}>
            <InputTextArea
              label='ชื่อโครงการ'
              rule={{ required: true }}
              value={planProcurement.name}
              onChange={(val) => handlerOnChange('name', val)}
              disabled={!canEdit}
              name='name'
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}
            lg={4}
            xxl={3}>
            <Currency
              label='วงเงิน (บาท)'
              rule={{ required: true }}
              value={planProcurement.budget}
              onChange={(val) => handlerOnChange('budget', val)}
              disabled={!canEdit}
              name='budget'
            />
          </Col>
          <Col sm={6}
            lg={4}
            xxl={3}>
            <DatePicker
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              rule={{ required: true }}
              value={planProcurement.expectingProcurementAt}
              onChange={(val) => handlerOnChange('expectingProcurementAt', val)}
              disabled={!canEdit}
              name='expectingProcurementAt'
              monthYearOnly
              addDefaultYear={1}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}
            lg={4}
            xxl={3}>
            <Input
              label='เบอร์โทร'
              rule={{ required: true }}
              value={planProcurement.tel}
              onChange={(val) => handlerOnChange('tel', val)}
              disabled={!canEdit}
              name='tel'
            />
          </Col>
        </Row>
        {/* <>
          {
            (planProcurement.supplyMethod === SupplyMethodEnum.MethodId80 && (planProcurement.departmentId === DepartmentId.BorPor || planProcurement.departmentId === DepartmentId.BorSorSor || planProcurement.departmentId === DepartmentId.NPA || planProcurement.departmentId === DepartmentId.SorOr)) && (
              <Row>
                <Col sm={12}
                  className='mt-2 ps-40'>
                  <b className='mb-0'>การจัดซื้อจัดจ้างและการบริหารพัสดุที่เกี่ยวกับการพาณิชย์โดยตรง
                    <b className='text-danger'> *</b>
                  </b>
                  <ul>
                    <Radio
                      name='result'
                      disabled={!canEdit}
                      items={commerceInformationData}
                      value={isconsider}
                      rule={{ required: true }}
                      onChange={(val) => onChangeCheckCommerceInfoResult(val)}
                    />
                  </ul>
                </Col>
              </Row>)
          }
        </> */}
        <>
          {planProcurement.isChanged || planProcurement.isCanceled ?
            <Row>
              <Col lg={8}
                xxl={6}>
                <InputTextArea
                  label='เหตุผล'
                  value={remarkChangeOrCancelValue}
                  disabled={!canEdit}
                  rule={{ required: remarkChangeOrCancelIsRequired }}
                  onChange={val => onRemarkChangeOrCancelValue(val)}
                  name='remark' />
              </Col>
            </Row> : null}
        </>
      </Card>

      {/* ส่งฝ่ายเห็นชอบ */}
      <Card className='mt-3'>
        <Title
          text='ส่งฝ่ายเห็นชอบ'
          className='fs-5 text-primary'
        />
        <>
          {canAddApprover ? (
            <div className='d-flex align-items-center justify-content-end'>
              <Button
                variant='outline-primary'
                className='d-flex align-items-center justify-content-end gap-2'
                onClick={() => {
                  setSection(ProcurementSection.DEPARTMENT_DIRECTOR_AGREE);
                  setShowUserModal(true);
                }}
              >
                <FaPlus />เพิ่มรายชื่อ
              </Button>
            </div>
          ) : null}
        </>
        <Table
          hidePagination
          className='mt-3 step-bar'
          notResponse
        >
          <thead>
            <tr>
              <th style={{ width: '5%' }}>ลำดับ</th>
              <th className='text-start'
                style={{ width: '30%' }}>ชื่อ-นามสกุล
              </th>
              <th className='text-start'
                style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
              </th>
              <th style={{ width: '10%' }}>สถานะ</th>
              <th style={{ width: '10%' }}>วันที่เห็นชอบ</th>
              <th style={{ minHeight: 100, width: '5%' }} />
            </tr>
          </thead>
          <tbody>
            {planProcurement.departmentDirectorAgree?.sort((a, b) => a.sequence - b.sequence).map((data, i) => (
              <>
                <tr key={data.sequence}>
                  <td className='text-center'>{i + 1}</td>
                  <td>
                    {data.fullName}
                    <p className='m-0 department'>
                      {data.positionName}
                    </p>
                  </td>
                  <td className='text-left'>
                    {data.delegateFullName &&
                      <>
                        {data.delegateFullName}
                        <p className='m-0 department'>
                          {data.delegatePositionName}
                        </p>
                      </>
                    }
                  </td>
                  <td className='text-center'>
                    <ApproverStatus value={data.status}
                      outline />
                  </td>
                  <td className='text-center'>
                    {data.status === AcceptorStatus.APPROVE ? fullDatetime(data.acceptDate) : ''}
                  </td>
                  <td className='text-end'>
                    {canRemoveApprover(data.id) &&
                      <Button
                        variant='danger'
                        onClick={() => removeUser(data.userId, ProcurementSection.DEPARTMENT_DIRECTOR_AGREE)}>
                        <FaTrashAlt />
                      </Button>
                    }
                  </td>
                </tr>
                {
                  data.isAvailableRemark || data.acceptRemark || data.rejectRemark ?
                    <tr className='border-bottom bg-opacity-10 bg-light'>
                      <td />
                      <td colSpan={5}
                        className='p-0'>
                        <ul className='step-bar-list'>
                          {data.isAvailableRemark &&
                            <li>
                              <div className='description'>
                                <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                <p className='mb-0'
                                  style={{ color: 'gray' }}>
                                  {data.isAvailableRemark}
                                </p>
                              </div>
                            </li>
                          }
                          {data.acceptRemark &&
                            <>
                              <li>
                                <div className='description'>
                                  <p className='mb-0 text-primary'>หมายเหตุเห็นชอบ</p>
                                  <p className='mb-0'
                                    style={{ color: 'gray' }}>
                                    <span className='text-muted me-2'>[{fullDatetime(data.acceptDate)} ]</span>
                                    {data.acceptRemark}
                                  </p>
                                </div>
                              </li>
                            </>
                          }
                          {data.rejectRemark &&
                            <li>
                              <div className='description'>
                                <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                <p className='mb-0'
                                  style={{ color: 'gray' }}>
                                  <span className='text-muted me-2'>[{fullDatetime(data.rejectDate)} ]</span>
                                  {data.rejectRemark}
                                </p>
                              </div>
                            </li>
                          }
                        </ul>
                      </td>
                    </tr>
                    : <tr className='border-bottom' />
                }
              </>
            ))}
          </tbody>
        </Table>
      </Card >

      {/* ส่งผอ.จพ.รับทราบ */}
      < Card className='mt-3' >
        {/* Title */}
        < Title
          text='ส่งผอ.จพ.รับทราบ'
          className='fs-5 text-primary'
        />
        <>
          {(canAddJorPor) ? (
            <div className='d-flex align-items-center justify-content-end'>
              <Button
                variant='outline-primary'
                className='d-flex align-items-center justify-content-end gap-2'
                onClick={() => {
                  setSection(ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED);
                  setShowUserModal(true);
                }}
              >
                <FaPlus />เพิ่มรายชื่อ
              </Button>
            </div>
          ) : null}
        </>
        {/* Data Table jorporDirectorBeInformed */}
        <Table
          hidePagination
          className='mt-3 step-bar'
          notResponse>
          <thead>
            <tr>
              <th style={{ width: '5%' }}>ลำดับ</th>
              <th className='text-start'
                style={{ width: '30%' }}>ชื่อ-นามสกุล
              </th>
              <th className='text-start'
                style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
              </th>
              <th style={{ minHeight: 100, width: '25%' }} />
            </tr>
          </thead>
          <tbody>
            {planProcurement.jorporDirectorBeInformed?.sort((a, b) => a.sequence - b.sequence).map((data, i) => (
              <tr key={data.id}>
                <td className='text-center'>{i + 1}</td>
                <td>
                  {data.fullName}
                  <p className='m-0 department'>
                    {data.positionName}
                  </p>
                </td>
                <td className='text-left'>
                  {data.delegateFullName &&
                    <>
                      {data.delegateFullName}
                      <p className='m-0 department'>
                        {data.delegatePositionName}
                      </p>
                    </>
                  }
                </td>
                <td className='text-end'>
                  {(canRemoveJorPor ||
                    ((planProcurement.jorporDirectorBeInformed !== undefined && planProcurement.jorporDirectorBeInformed.some(a => isNull(a.delegateUserId, a.userId) === userId))
                      && ((planProcurement.status === PlanStatusCons.DraftPlan
                        || planProcurement.status === PlanStatusCons.RejectPlan
                        || planProcurement.status === PlanStatusCons.Assigned)
                        && data.userId !== userId))) ?
                    <Button
                      variant='danger'
                      onClick={() => removeUser(data.userId, ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED)}
                    >
                      <FaTrashAlt />
                    </Button>
                    : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card >

      {/* Upload File */}
      {
        currentStep === 1
          ? (
            <Card className='mt-3'>
              <Title text='เอกสารแนบ'
                className='fs-5 text-primary' />
              <Row className='justify-content-center mt-3'>
                <Col sm={12}
                  lg={6}>
                  <UploadFile
                    onChange={uploadFileOnChangeAsync}
                    value={files()}
                    onRemove={removeFileAsync}
                    onDownload={downloadFileAsync}
                    canRemoveFile={canRemoveFile}
                    disabled={readonly}
                  />
                </Col>
              </Row>
            </Card>
          ) : null
      }

      {/* Button */}
      {
        currentStep === 1
          ? (
            <div className='d-flex justify-content-between mt-3'>
              <ButtonCustom
                onClick={props.onClickBack}
                text="กลับหน้าแรก"
              />
              <div className='d-flex gap-2'>
                {(canSave)
                  ? (
                    <ButtonCustom
                      onClick={() => handlerOnSubmitAsync()}
                      text="บันทึก"
                    />
                  ) : null}
                {canSendApprove
                  ? (
                    <ButtonCustom
                      icon='checked'
                      onClick={() => setShowPendingModal(true)}
                      text="ส่งอนุมัติเห็นชอบ"
                    />
                  ) : null}
              </div>
              <div style={{ width: 100 }} />
            </div>
          ) : null
      }

      {/* Modal */}
      {/* <SearchUserModal
        show={showUserModal}
        onHide={() => setShowUserModal(false)}
        onSelect={userOnSelect}
        section={section}
      /> */}
      <SearchBuNameModal
        total={userTotal}
        show={showUserModal}
        onHide={() => setShowUserModal(!showUserModal)}
        onSelect={(id, name, department, position) => userOnSelect(id, name, department!, position!)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        data={[]}
      />
      <Modal
        show={showPendingModal}
        size='lg'
        onHide={() => setShowPendingModal(!showPendingModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งอนุมัติ/เห็นชอบ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingModal(!showPendingModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerOnSubmitAsync(PlanStatusCons.WaitingApprovePlan)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}

function HistoryModal(props: {
  show: boolean;
  onHide: () => void;
  data: {}[];
}) {
  const { planProcurement } = useContext(Context);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='xl'
    >
      <Modal.Header>
        <Title
          text='ประวัติการใช้งาน'
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Table hidePagination>
        <thead>
          <tr>
            <th style={{ width: 150 }}>วันที่ดำเนินการ</th>
            <th style={{ width: 150 }}>ผู้ดำเนินการ</th>
            <th style={{ width: 150 }}>การดำเนินการ</th>
            <th style={{ width: 150 }}>สถานะการดำเนินการ</th>
            <th style={{ width: 250 }}>หมายเหตุ</th>
          </tr>
        </thead>
        <tbody>
          {planProcurement.activities?.map((a, i) => (
            <tr key={generateUniqueId(i)}>
              <td className='text-center'>
                {fullDatetime(a.activityDate)}
              </td>
              <td>{a.fullNameAction}</td>
              <td>{a.activity}</td>
              <td>{a.action}</td>
              <td>{a.remark}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  );
}

function SearchUserModal(props: {
  show: boolean;
  onHide: () => void;
  onSelect: (id: string, fullName: string, departmentName: string, positionName: string) => void;
  section: string | undefined;
}) {
  const { planProcurement } = useContext(Context);
  const loader = useLoaderData() as Loader;
  const [criteria, setCriteria] = useState<UserCriteria>({} as UserCriteria);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (props.show) {
      setSize(10);
      setPage(1);
      clearCriteria();
      getUserAsync(10, 1, {} as UserCriteria);
    }
  }, [props.show]);

  const section = useMemo(() => {
    return props.section;
  }, [props.section]);

  const handlerOnChange = (key: string, value: string) => {
    setCriteria({
      ...criteria,
      [key]: value,
    });
  };

  const onSelect = (id: string) => {
    if ((planProcurement.departmentDirectorAgree?.some((d) => d.userId === id) && section === ProcurementSection.DEPARTMENT_DIRECTOR_AGREE) ||
      (planProcurement.jorporDirectorBeInformed?.some((d) => d.userId === id) && section === ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED)) {
      toast.warn('ข้อมูลซ้ำ');

      return;
    }

    const user = users.find((a) => a.id === id);

    if (user) {
      props.onSelect(
        user.id,
        user.name,
        user.department,
        user.position,
      );

      props.onHide();
    }
  };

  const getUserAsync = async (size: number, page: number, criteria: UserCriteria) => {
    const res = await planProcurementService
      .getUsersAsync(size, page, criteria.name, criteria.departmentId, criteria.positionId);

    if (res.status === HttpStatusCode.OK) {
      setUsers(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }
  };

  const clearCriteria = () => {
    setCriteria({} as UserCriteria);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='xl'
    >
      <Modal.Header>
        <Title
          text={section === ProcurementSection.DEPARTMENT_DIRECTOR_AGREE ? 'รายชื่อฝ่ายเห็นชอบ' : 'รายชื่อผอ.จพ. เห็นชอบ'}
          className='fs-5 text-primary'
        />
      </Modal.Header>
      <Row>
        <Col sm={6}
          lg={4}>
          <Input
            label='ชื่อ-นามสกุล'
            value={criteria.name}
            onChange={(val) => handlerOnChange('name', val)}
          />
        </Col>
        <Col sm={6}
          lg={4}>
          <Selector
            label='ฝ่าย/สำนัก'
            value={criteria.departmentId}
            onChange={(val) => handlerOnChange('departmentId', val)}
            items={loader.departments}
          />
        </Col>
        <Col sm={6}
          lg={4}>
          <Selector
            label='ตำแหน่ง'
            value={criteria.positionId}
            onChange={(val) => handlerOnChange('positionId', val)}
            items={loader.positions}
          />
        </Col>
      </Row>
      <div className='d-flex gap-2'>
        <Button
          variant='primary'
          className='d-flex align-items-center gap-2'
          onClick={() => getUserAsync(size, page, criteria)}
        >
          <FaSearch />ค้นหา
        </Button>
        <Button
          variant='outline-primary'
          className='d-flex align-items-center gap-2'
          onClick={clearCriteria}
        >
          <FaEraser />ล้าง
        </Button>
      </div>
      <Table
        className='mt-3'
        total={totalRecords}
        onChange={(size, page) => {
          setSize(size);
          setPage(page);
          getUserAsync(size, page, criteria);
        }}
      >
        <thead>
          <tr>
            <th style={{ minWidth: 20 }}>ลำดับ</th>
            <th style={{ minWidth: 100 }}>ชื่อ-นามสกุล</th>
            <th style={{ minWidth: 100 }}>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 100 }}>ตำแหน่ง</th>
            <th style={{ minWidth: 25 }} />
          </tr>
        </thead>
        <tbody>
          {
            users.map((data, i) => (
              <tr key={data.id}>
                <td className='text-center'>{calculateRowNumber(i, page, size)}</td>
                <td>{data.name}</td>
                <td>{data.department}</td>
                <td>{data.position}</td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Button variant='primary'
                      onClick={() => onSelect(data.id)}>
                      เลือก
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Modal>
  );
}
