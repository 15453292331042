import axios from 'axios';
import Cookie from 'cookie-universal';
import { User } from 'models';
import { notificationModel } from 'models/notificationModels';
import {
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Dropdown,
  Nav,
  Navbar as NavbarBT,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  browserName,
  browserVersion,
} from 'react-device-detect';
import {
  FaRegEnvelope,
  FaRegEnvelopeOpen,
  FaSignOutAlt,
  FaUserAlt,
} from 'react-icons/fa';
import {
  MdOutlineDehaze,
  MdOutlineNotificationsNone,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { account } from 'services';
import notificationServices from 'services/notificationServices';
import {
  fullDatetime,
  fullDatetimeTH,
  HttpStatusCode,
  timeFromNow,
} from 'utils';
import toast from 'utils/toast';

interface Props {
  onClick?: () => void;
}

export default function Navbar({ onClick }: Props) {
  const [user, setUser] = useState<User>({} as User);
  const cookies = Cookie();
  const navigate = useNavigate();
  const [notificationList, setNotificationList] = useState<notificationModel[]>([]);
  const notiNumber = notificationList.filter(x => x.readAt === null);
  const dateNow = new Date();
  const [ip, setIP] = useState('');

  const getData = async () => {
    const res = await axios.get('https://api.ipify.org/?format=json');
    setIP(res.data.ip);
    cookies.set('ipAddress', res.data.ip);
  };

  useEffect(() => {
    getProfileAsync();
    getNotificationAsync();
    getData();
  }, []);

  const getProfileAsync = async () => {
    const res = await account.getUserAsync();

    if (res.status === HttpStatusCode.OK) {
      setUser(res.data);

      cookies.set('fullName', `${res.data.firstName} ${res.data.lastName}`);
    }
  };

  const signOut = () => {
    cookies.removeAll();
    toast.success('ออกจากระบบแล้ว');
    navigate('/login');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getNotificationAsync();
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  const getNotificationAsync = async () => {

    const res = await notificationServices.getNotificationByUserIdAsync();

    if (res.status === HttpStatusCode.OK) {
      setNotificationList(res.data);
    }
  };

  const goToPage = async (url: string, id: string) => {

    if (id) {
      const res = await notificationServices.updateReadNotificationAsync(id);
      if (res.status === HttpStatusCode.OK) {
        navigate(`${url}`);
      }
    }
  };

  const readNotificationByUser = async () => {
    const res = await notificationServices.readNotificationByUserAsync();
    if (res.status === HttpStatusCode.OK) {
      const row = [...notificationList];
      const modifiedRow = row.map(d => ({
        ...d,
        readAt: true,
      }));

      setNotificationList(modifiedRow);

      toast.success('ทำเครื่องหมายอ่านทั้งหมดเสร็จสิ้น');
    }
  };

  return (
    <NavbarBT>
      <MdOutlineDehaze className='fs-4 text-white toggler-menu d-block'
        onClick={onClick} />
      <NavbarBT.Collapse>
        <Nav className='ms-auto'>
          <Nav.Link className='d-none d-sm-flex align-items-center'>
            <div className='d-flex flex-column align-items-end'>
              <span className='d-flex align-items-center'>
                <FaUserAlt className='me-2' />{`${user.firstName} ${user.lastName}`}
              </span>
              <p className='d-none d-md-block'>
                วันที่ {fullDatetime(dateNow)} น.
                IP:{ip} {browserName} v.{browserVersion}
              </p>
            </div>
          </Nav.Link>
          <Nav.Link>

            <Dropdown>
              <Dropdown.Toggle id='dropdown-basic'
                className='noti'>
                <Badge bg='danger'
                  className='number'>
                  {notiNumber.length > 0 ? notiNumber.length : 0}
                </Badge>
                <MdOutlineNotificationsNone className={`fs-3 ${notiNumber.length > 0 ? 'bell' : ''}`} />
              </Dropdown.Toggle>

              <Dropdown.Menu className='noti-menu'>
                <div className='border-bottom py-1 px-3 d-flex justify-content-between align-items-center'>
                  <h6 className='fw-bold mb-0'>การแจ้งเตือน</h6>
                  <Button variant='link'
                    size='sm'
                    className='p-0 fs-7'
                    onClick={() => readNotificationByUser()}>
                    ทำเครื่องหมายอ่านทั้งหมด
                  </Button>
                </div>
                <div className='box-menu'>
                  <Tabs
                    className='border-bottom'
                    defaultActiveKey='1'>
                    <Tab eventKey='1'
                      title='ทั้งหมด'>
                      {notificationList?.map((data, index) =>
                        <Dropdown.Item href='#/action-1'
                          key={index}
                          onClick={() => goToPage(data.url, data.id)}
                          className={data.readAt ? '' : 'unread'}>
                          <div className='list-menu'>
                            <div className='unread-noti'>
                              <div className='d-flex justify-content-between'>
                                <div className='f-bolder d-flex align-items-center'>
                                  {
                                    data.readAt
                                      ? <FaRegEnvelopeOpen className='me-2' />
                                      : <FaRegEnvelope className='text-primary me-2' />
                                  }
                                  {data.title}
                                </div>
                                <div className='t-date'>
                                  {fullDatetimeTH(data.createdAt)}
                                </div>
                              </div>
                              <div>{data.message}{data.subMessage} </div>
                              <div className='t-time'>{timeFromNow(data.createdAt)}</div>
                            </div>
                          </div>
                        </Dropdown.Item>,
                      )}
                    </Tab>
                    <Tab eventKey='2'
                      title='ยังไม่ได้อ่าน'>
                      {notificationList?.map((data, index) =>
                        !data.readAt && (
                          <Dropdown.Item href='#/action-1'
                            key={index}
                            onClick={() => goToPage(data.url, data.id)}
                            className={data.readAt ? '' : 'unread'}>
                            <div className='list-menu'>
                              <div className='unread-noti'>
                                <div className='d-flex justify-content-between'>
                                  <div className='f-bolder d-flex align-items-center'>
                                    {
                                      data.readAt
                                        ? <FaRegEnvelopeOpen className='me-2' />
                                        : <FaRegEnvelope className='text-primary me-2' />
                                    }
                                    {data.title}
                                  </div>
                                  <div className='t-date'>
                                    {fullDatetimeTH(data.createdAt)}
                                  </div>
                                </div>
                                <div>{data.message}{data.subMessage} </div>
                                <div className='t-time'>{timeFromNow(data.createdAt)}</div>
                              </div>
                            </div>
                          </Dropdown.Item>
                        ),
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Link>
          <Nav.Link className='logout d-flex align-items-center'
            onClick={signOut}>
            <FaSignOutAlt className='me-2' />ออกจากระบบ
          </Nav.Link>
        </Nav>
      </NavbarBT.Collapse>
    </NavbarBT>
  );
}
