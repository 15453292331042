import {
  Breadcrumb,
  Card,
  Check,
  Input,
  Selector,
} from 'components';
import {
  BusinessUnitRequest,
  ItemModel,
  WorkGroupRequest,
} from 'models';
import {
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import businessUnit from 'services/businessUnit';
import workGroup from 'services/workGroup';
import {
  HttpStatusCode,
  submitForm,
} from 'utils';
import toast from 'utils/toast';

const BREADCRUMB_INFO = [
  {
    routerName: '/su/su02', label: 'สายงาน',
  },
  {
    routerName: '', label: 'กำหนดสายงาน',
  },
];

export default function SU02Detail() {
  const [businessData, setBusinessData] = useState<BusinessUnitRequest>({ active: true } as BusinessUnitRequest);
  const [workGroupItems, setWorkGroupItems] = useState<ItemModel[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const backToIndex = () => navigate('/su/su02');

  const getWorkGroupItemsAsync = async () => {
    const response = await workGroup.getWorkGroupListByActiveStatus(true);
    const workGroupData: WorkGroupRequest[] = response.data;
    const items: ItemModel[] = [];

    workGroupData.forEach((data) => items.push({ label: data.name, value: data.id ? data.id : '' }));

    setWorkGroupItems(items);
  };

  const getBusinessUnitDetailById = useCallback(async (id: string) => {
    const response = await businessUnit.getBusinessUnitDetailAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setBusinessData(response.data);
    }
  }, [id]);

  useEffect(() => {
    if (id !== undefined) {
      getBusinessUnitDetailById(id);
    }
    getWorkGroupItemsAsync();
  }, []);

  const handleOnSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();

    submitForm();
    if (businessData.code && businessData.name) {
      createOrUpdateSetGroupWorkAsync();
    }
  };

  const createOrUpdateSetGroupWorkAsync = async () => {
    const data = businessData;

    if (!id) {
      const response = await businessUnit.createBusinessUnitAsync(data);

      if (response.status === HttpStatusCode.CREATED) {
        backToIndex();
        toast.success('บันทึกข้อมูลสำเร็จ');
        return;
      }

      toast.error('รหัสสายงานซ้ำ');
      return;
    }

    const response = await businessUnit.updateBusinessUnitAsync(id, data);

    if (response.status === HttpStatusCode.ACCEPTED) {
      backToIndex();
      toast.success('แก้ไขข้อมูลสำเร็จ');
    }
  };

  return (
    <div className="m02">
      <Form onSubmit={handleOnSubmit}>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mt-2 text-primary">
            <Button variant="link" onClick={backToIndex}>
              <MdOutlineArrowBack className="fs-4 text-primary mb-1" />
            </Button>
            สายงาน
          </h4>
          <Button type="submit" variant='success'>บันทึก</Button>
        </div>
        <Breadcrumb data={BREADCRUMB_INFO} />
        <div className="mt-4">
          <Card>
            <div className="criteria">
              <Row>
                <Col md={6} lg={4}>
                  <Selector
                    value={businessData.workGroupId}
                    items={workGroupItems}
                    placeholder="เลือกกลุ่มงาน"
                    label="กลุ่มงาน"
                    onChange={(value) => setBusinessData({ ...businessData, workGroupId: value === '' ? undefined : value })}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} lg={4}>
                  <Input
                    disabled={id !== undefined}
                    label="รหัสสายงาน"
                    rule={{ required: true }}
                    value={businessData.code}
                    onChange={(value) => setBusinessData({ ...businessData, code: value })}
                  />
                </Col>
                <Col md={6} lg={4}>
                  <Input
                    label="ชื่อสายงาน"
                    rule={{ required: true }}
                    value={businessData.name}
                    onChange={(value) => setBusinessData({ ...businessData, name: value })}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mt-2">
                  <Check label="ใช้งาน" value={businessData.active} onChange={(value) => setBusinessData({ ...businessData, active: value })} />
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Form>
    </div>
  );
}
