import { useCallback } from 'react';
import {
  FaChevronCircleDown,
  FaChevronCircleUp,
} from 'react-icons/fa';

interface Props {
  onClick: () => void;
  openCollapse: boolean;
}

export function ArrowCollapse({ openCollapse, onClick }: Props) {
  const handlerOnClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    openCollapse ? (
      <FaChevronCircleDown
        onClick={() => handlerOnClick()}
        aria-controls='example-collapse-text'
        aria-expanded={openCollapse}
        className='text-primary fs-5 cursor-pointer' />
    ) : (
      <FaChevronCircleUp
        onClick={() => handlerOnClick()}
        aria-controls='example-collapse-text'
        aria-expanded={openCollapse}
        className='text-primary fs-5 cursor-pointer' />
    )
  );
}
