import { Breadcrumb } from 'components';
import StepProgress, { ProgressBarRefType } from 'components/StepProgress';
import { AssignDetailResponse } from 'models';
import {
  createContext,
  useRef,
} from 'react';
import { useLoaderData } from 'react-router';
import Step1 from './Step1';
import Step2 from './Step2';

type Loader = { assignDetail: AssignDetailResponse };

export const DetailContext = createContext({} as AssignDetailResponse);

export default function PL0305Detail() {
  const progressBarRef = useRef({} as ProgressBarRefType);
  const { assignDetail } = useLoaderData() as Loader;

  const BREADCRUMB_INFO = [
    {
      routerName: '/pl/pl0305', label: 'มอบหมายงาน',
    },
    {
      routerName: '', label: 'รายละเอียดแผน',
    },
  ];

  const stepData = [
    {
      title: <p>ข้อมูลแผน</p>,
      displayIndex: '1',
      child: <Step1 onNext={() => progressBarRef.current.clickNext()} />,
    },
    {
      title: <p>มอบหมายงาน</p>,
      displayIndex: '2',
      child: <Step2 onBack={() => progressBarRef.current.clickBack()} />,
    },
  ];

  return (
    <DetailContext.Provider value={assignDetail}>
      <div>
        <h4 className="mt-2 mb-0 text-primary text-start">รายละเอียดแผน</h4>
        <Breadcrumb data={BREADCRUMB_INFO} />
      </div>
      <div>
        <StepProgress
          progressBarData={stepData}
          ref={progressBarRef}
        />
      </div>
    </DetailContext.Provider>
  );
}
