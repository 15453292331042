export function submitForm<T>(data?: T, validateHasNameOnly?: boolean) {
  if (validateHasNameOnly) {
    document.dispatchEvent(new CustomEvent('onFormSubmit', { detail: { data, hasNameOnly: true } }));
  } else {
    document.dispatchEvent(new CustomEvent('onFormSubmit', { detail: { data, hasNameOnly: false } }));
  }
}

export function clearForm() {
  document.dispatchEvent(new CustomEvent('onClearError'));
}