import {
  ChangeEvent,
  useState,
} from 'react';
import { FormType } from '../constant/formType';
import regex from './regex';

interface Validation {
  type?: string;
  required?: {
    value: boolean;
    message: string;
  };
  pattern?: {
    value: string;
    message: string;
  };
  custom?: {
    isValid: (value: string) => boolean;
    message: string;
  };
}

function formatStringWithComma(str: string) {
  const num = parseInt(str, 10);
  return num.toLocaleString();
}

type ErrorRecord<T> = Partial<Record<keyof T, string>>;

type Validations<T extends {}> = Partial<Record<keyof T, Validation>>;

export const useForm = <T extends Record<keyof T, any> = {}>(options?: {
  validations?: Validations<T>;
  initialValues?: Partial<T>;
  onSubmit?: () => void;
}) => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T);
  const [errors, setErrors] = useState<ErrorRecord<T>>({});

  function validateInput(type: string, value: string) {
    switch (type) {
      case FormType.TEL:
        return (regex.validateNumber(value) || value === '');
      case FormType.NUMBER:
        return (regex.validateNumber(value) || value === '');
      case FormType.TEXT:
        return (regex.validateSpecialAlphabetWithSpace(value) || value === '');
      case FormType.PASSWORD:
        return (regex.validateSpecialAlphabet(value) || value === '');
      case FormType.SEARCH:
        return (regex.validateSpecialAlphabet(value) || value === '');
      default:
        return true;
    }
  }

  // Needs to extend unknown so we can add a generic to an arrow function
  const handleChange = <S extends unknown>(
    key: keyof T,
    sanitizeFn?: (value: string) => S,
  ) => (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;
    const validations = options?.validations;

    if (validations) {
      const typeDataSet = e.target.dataset.type ? e.target.dataset.type : e.target.type;
      const isNumber = typeDataSet === FormType.TEL || typeDataSet === FormType.NUMBER;
      const isCurrency = typeDataSet === FormType.CURRENCY;
      const isCheckbox = typeDataSet === FormType.CHECKBOX;

      if (isNumber) {
        validateInput(typeDataSet || '', e.target.value) && setData({ ...data, [key]: value });
      }

      if (isCurrency) {
        const inputValue = e.target.value.replace(/,/g, '');
        const formatValue = formatStringWithComma(inputValue);
        const newValue = sanitizeFn ? sanitizeFn(formatValue) : formatValue;

        validateInput(typeDataSet || '', inputValue) && setData({ ...data, [key]: newValue });
      }

      if (isCheckbox) {
        const inputValue = e.target.checked.toString();
        const newValue = sanitizeFn ? sanitizeFn(inputValue) : inputValue;

        validateInput(typeDataSet || '', inputValue) && setData({ ...data, [key]: newValue });
      }

      if (!isNumber && !isCurrency && !isCheckbox) {
        setData({ ...data, [key]: value });
      }

      const newErrors: ErrorRecord<T> = {};
      const valueChange = value;
      const valueOnChange = valueChange as string;
      const validation = validations[key];

      if (validation?.required?.value && !valueOnChange) {
        newErrors[key] = validation?.required?.message;

        setErrors({
          ...errors,
          [key]: validation?.required?.message,
        });

        return;
      }

      const pattern = validation?.pattern;

      if (pattern?.value && !RegExp(pattern.value)
        .test(valueOnChange)) {
        newErrors[key] = pattern.message;

        setErrors({
          ...errors,
          [key]: pattern.message,
        });

        return;
      }

      const custom = validation?.custom;

      if (custom?.isValid && !custom.isValid(valueOnChange)) {
        newErrors[key] = custom.message;

        setErrors({
          ...errors,
          [key]: custom.message,
        });

        return;
      }

      setErrors({
        ...errors,
        [key]: '',
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validations = options?.validations;

    if (validations) {
      let valid = true;
      const newErrors: ErrorRecord<T> = {};

      for (const key in validations) {
        const value = data[key];
        const validation = validations[key];
        const pattern = validation?.pattern;

        if (pattern?.value && !RegExp(pattern.value)
          .test(value)) {
          valid = false;
          newErrors[key] = pattern.message;
        }

        const custom = validation?.custom;

        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message;
        }

        if (validation?.required?.value && !value) {
          valid = false;
          newErrors[key] = validation?.required?.message;
        }
      }

      if (!valid) {
        setErrors(newErrors);
        return;
      }
    }

    setErrors({});

    if (options?.onSubmit) {
      options.onSubmit();
    }
  };

  const handleClear = () => {
    setData((options?.initialValues || {}) as T);
  };

  return {
    data,
    initFormData: setData,
    handleChange,
    handleSubmit,
    handleClear,
    errors,
  };
};
