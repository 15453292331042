// import { Modal } from "./Modal";
import {
  Modal,
  Table,
} from 'components';
import {
  DepartmentListResponse,
  Role,
} from 'models';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { trader } from 'services';
import { calculateRowNumber } from 'utils/constants/calculateRowNumber';
import { useForm } from 'utils/useForm';

interface Props {
  show: boolean;
  onHide?: () => void;
  onSelectItem?: (id: string, entrepreneurType: string, type: string, taxpayerIdentificationNo: string, establishmentName: string, email: string, index: number) => void;
  sectionSequence?: number;
}

interface FormInput {
  taxId: string;
  traderName: string;
}

export function TraderModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [traders, setTraders] = useState<TraderTable[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [departments, setDepartments] = useState<DepartmentListResponse[]>([]);
  const [departmentId, setDepartmentId] = useState('');
  const [username, setUserName] = useState('');
  const [totalRecord, setTotalRecords] = useState(0);

  type TraderTable = {
    id: string,
    entrepreneurType: string,
    type: string,
    taxpayerIdentificationNo: string,
    establishmentName: string,
    email: string,
  };

  const TYPE = [
    { label: 'นิติบุคคล', value: 'JuristicPerson' },
    { label: 'บุคคลธรรมดา', value: 'Individual' },
  ];

  useEffect(() => {
    getTraderListAsync(size, page);
  }, []);

  const onSelect = (id: string, entrepreneurType: string, type: string, taxpayerIdentificationNo: string, establishmentName: string, email: string, index: number) => {
    props.onSelectItem!(id, entrepreneurType, type, taxpayerIdentificationNo, establishmentName, email, index);
    props.onHide!();
  };

  const getTraderListAsync = async (size: number, page: number, type?: string, entrepreneurType?: string, taxpayerIdentificationNo?: string, establishmentName?: string) => {
    const res = await trader.getTraderListAsync(
      size,
      page,
      type,
      entrepreneurType,
      taxpayerIdentificationNo,
      establishmentName,
    );

    setTraders(res.data.data);
    setTotalRecords(res.data.totalRecords);
  };

  function clearFormSearch() {
    handleClear();
    setDepartmentId('');
  }

  const { handleSubmit, handleChange, data: formData, errors, initFormData, handleClear } = useForm<FormInput>({
    initialValues: {
      taxId: '',
      traderName: '',
    },
    validations: {},
    onSubmit: async () => {
      getTraderListAsync(size, page, undefined, undefined, formData.taxId, formData.traderName);
    },
  });

  const handleSetPagination = (page: number, size: number) => {
    getTraderListAsync(size, page);
  };

  const getType = (value: string) => {
    const type = TYPE.find((t) => t.value === value);

    if (type) {
      return type.label;
    }

    return null;
  };

  return (
    <Modal
      show={props.show}
      size="xl"
      className="fix-width-1800"
      onHide={() => (props.onHide ? props.onHide() : null)}
    >
      <ModalBT.Header closeButton>
        <ModalBT.Title>ข้อมูลคู่ค้า</ModalBT.Title>
      </ModalBT.Header>
      <ModalBT.Body className="p-0 ps-4">
        <Form onSubmit={handleSubmit} id="userModalForm">
          <Row className="mt-3 align-items-center">
            <Col sm={12} md={4} xl={4}>
              <Form.Label>เลขประจำตัวผู้เสียภาษี</Form.Label>
              <Form.Group className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={formData.taxId || ''}
                    onChange={handleChange<string>('taxId', (value) => value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={12} md={4} xl={4}>
              <Form.Label>ชื่อสถานประกอบการ</Form.Label>
              <Form.Group className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={formData.traderName || ''}
                    onChange={handleChange<string>('traderName', (value) => value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex gap-2">
            <Button variant="primary" className="px-3" type="submit" form="userModalForm">
              <FaSearch className="me-2" />ค้นหา
            </Button>
            <Button
              className="px-4"
              variant="light"
              onClick={clearFormSearch}
              type="submit"
              form="userModalForm"
            >
              ล้าง
            </Button>
          </div>
        </Form>
        <Table
          className="my-3"
          total={totalRecord}
          onChange={(size, page) => {
            handleSetPagination(page, size);
          }}
        >
          <thead>
            <tr>
              <th style={{ minWidth: 75 }}>ลำดับ</th>
              <th style={{ minWidth: 200 }}>ประเภท</th>
              <th style={{ minWidth: 200 }}>ประเภทผู้ประกอบการ</th>
              <th style={{ minWidth: 100 }}>เลขประจำตัวผู้เสียภาษีอากร/<br />เลขประจำตัวประชาชน</th>
              <th style={{ minWidth: 300 }}>ชื่อบริษัท/ชื่อ - นามสกุล</th>
              <th style={{ minWidth: 200 }}>อีเมล</th>
              <th style={{ minWidth: 25 }} />
            </tr>
          </thead>
          <tbody>
            {
              traders.map((data, index) => (
                <tr key={index}>
                  <td className="text-center">{calculateRowNumber(index, page, size)}</td>
                  <td>{getType(data.type)}</td>
                  <td>{data.entrepreneurType}</td>
                  <td className="text-center">{data.taxpayerIdentificationNo}</td>
                  <td className="text-wrap">{data.establishmentName}</td>
                  <td className="text-wrap">{data.email}</td>
                  <td>
                    <Button variant="primary" className="px-3" onClick={() => onSelect(data.id, data.entrepreneurType, data.type, data.taxpayerIdentificationNo, data.establishmentName, data.email, index)}>
                      เลือก
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
