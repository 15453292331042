import { Menu } from 'models';

export const MENUS: Menu[] = [
  {
    code: 'PL',
    label: 'Plan',
    path: '/pa',
    children: [
      {
        code: 'PL01',
        label: 'กำหนดค่าตั้งต้น',
        path: '/pa/pl01',
        children: [
          {
            code: 'PL0201',
            label: 'จัดทำแผนรวมประจำปี',
            path: '/pa/pl0201',
          },
          {
            code: 'PL0202',
            label: 'จัดทำแผนระหว่างปี',
            path: '/pa/pl0202',
          },
          {
            code: 'PL0203',
            label: 'ขอเปลี่ยนแปลงแผนการจัดซื้อจัดจ้าง',
            path: '/pa/pl0203',
          },
        ],
      },
      {
        code: 'PL02',
        label: 'กระบวนการทำงาน',
        path: '/pa/pl02',
        children: [
          {
            code: 'PL0201',
            label: 'จัดทำแผนรวมประจำปี',
            path: '/pa/pl0201',
          },
          {
            code: 'PL0202',
            label: 'จัดทำแผนระหว่างปี',
            path: '/pa/pl0202',
          },
          {
            code: 'PL0203',
            label: 'ขอเปลี่ยนแปลงแผนการจัดซื้อจัดจ้าง',
            path: '/pa/pl0203',
          },
        ],
      },
    ],
  },
];