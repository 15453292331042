import {
  Check,
  Input,
  Modal,
  ProcessStatus,
  Selector,
  Status,
  StatusType,
  Table,
} from 'components';
import { ItemModel } from 'models';
import {
  BasicInfoResponse,
  BasicInfoSearchParams,
} from 'models/PP';
import {
  createContext,
  Dispatch,
  FormEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { PP0205Service } from 'services';
import {
  GetMonthYearBC,
  HttpStatusCode,
  THCurrency,
  useAppContext,
} from 'utils';
import { generateUniqueId } from '../../../utils/helper';
import { MdAdd } from 'react-icons/md';
import toast from 'utils/toast';

type loader = { departmentItems: ItemModel[], basicInfoLists: BasicInfoResponse[], basicInfoListTotal: number, supplyMethodDDL: ItemModel[] };

interface CriteriaContext {
  page: number;
  size: number;
  totalRecords: number;
  criteria: BasicInfoSearchParams;
  basicInfoListData: BasicInfoResponse[];
  setPage: Dispatch<SetStateAction<number>>;
  setSize: Dispatch<SetStateAction<number>>;
  setCriteria: Dispatch<SetStateAction<BasicInfoSearchParams>>;
  setBasicInfoListData: Dispatch<SetStateAction<BasicInfoResponse[]>>;
}

export interface StatusModel {
  label: string;
  value: string;
  count: number;
}

interface CountStatus {
  allSupplyMethod: number,
  supplyMethod60: number,
  supplyMethod80: number,
  all: number,
  notYet: number,
  draft: number,
  waitingAcceptor: number,
  accepted: number,
  reject: number,
  assignJorPor: number,
}

const Context = createContext({} as CriteriaContext);

export default function JorPor004() {
  const { userId } = useAppContext();
  const [supplyMethod, setSupplyMethod] = useState<StatusModel[]>([
    { label: 'ทั้งหมด', value: 'All', count: 0 },
    { label: 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560', value: '342e4e0b-7682-48c0-b46a-97a066908c9a', count: 0 },
    { label: 'ข้อบังคับธนาคาร 80', value: 'a21603e4-338d-486c-85ba-5f1cac8f6ace', count: 0 },
  ]);
  const [planStatusNameArray, setPlanStatusNameArray] = useState<StatusModel[]>(
    [
      { label: 'ทั้งหมด', value: 'All', count: 0 },
      { label: 'ยังไม่ดำเนินการ', value: 'NotYet', count: 0 },
      { label: 'แบบร่าง', value: 'Draft', count: 0 },
      { label: 'รอเห็นชอบ', value: 'WaitingAccept', count: 0 },
      { label: 'เห็นชอบ', value: 'Accepted', count: 0 },
      { label: 'ส่งกลับแก้ไข', value: 'Reject', count: 0 },
      { label: 'มอบหมายสำเร็จ', value: 'AssignJorPor', count: 0 },
    ]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [onInit, setOnInit] = useState(true);
  const [criteria, setCriteria] = useState<BasicInfoSearchParams>({
    isPermission: true,
    supplyMethod: supplyMethod.map((i) => i.value),
    status: planStatusNameArray.map((i) => i.value),
  } as BasicInfoSearchParams);
  const [basicInfoListData, setBasicInfoListData] = useState<BasicInfoResponse[]>([]);

  const contextValue: CriteriaContext = {
    page,
    size,
    totalRecords,
    criteria,
    basicInfoListData,
    setPage,
    setSize,
    setCriteria,
    setBasicInfoListData,
  };

  useEffect(() => {
    if (userId) {
      getBasicInfoListsAsync(criteria);
      getStatusCountAsync(userId, criteria);
    }
  }, [page, size, userId]);

  const getStatusCountAsync = async (userId: string, criteriaSearch?: BasicInfoSearchParams) => {
    const { data, status } = await PP0205Service.getStatusCount(userId, criteriaSearch);

    if (status === HttpStatusCode.OK) {
      const countStatus: CountStatus = data;
      const supplyMethodResult = [
        { label: 'ทั้งหมด', value: 'All', count: countStatus.allSupplyMethod },
        { label: 'พ.ร.บ.จัดซื้อจัดจ้างฯ 2560', value: '342e4e0b-7682-48c0-b46a-97a066908c9a', count: countStatus.supplyMethod60 },
        { label: 'ข้อบังคับธนาคาร 80', value: 'a21603e4-338d-486c-85ba-5f1cac8f6ace', count: countStatus.supplyMethod80 },
      ];

      const planStatusNameArrayResult = [
        { label: 'ทั้งหมด', value: 'All', count: countStatus.all },
        { label: 'ยังไม่ดำเนินการ', value: 'NotYet', count: countStatus.notYet },
        { label: 'แบบร่าง', value: 'Draft', count: countStatus.draft },
        { label: 'รอเห็นชอบ', value: 'WaitingAccept', count: countStatus.waitingAcceptor },
        { label: 'เห็นชอบ', value: 'Accepted', count: countStatus.accepted },
        { label: 'ส่งกลับแก้ไข', value: 'Reject', count: countStatus.reject },
        { label: 'มอบหมายสำเร็จ', value: 'AssignJorPor', count: countStatus.assignJorPor },
      ];

      setSupplyMethod(supplyMethodResult);
      setPlanStatusNameArray(planStatusNameArrayResult);
      setOnInit(false);
    }
  };

  const getBasicInfoListsAsync = async (criteriaData?: BasicInfoSearchParams) => {
    const { data, status } = await PP0205Service.getBasicInfoListAsync(page, size, criteriaData, userId);

    if (status === HttpStatusCode.OK) {
      setBasicInfoListData(data.data);
      setTotalRecords(data.totalRecords);
    }
  };

  const onSearch = (data: BasicInfoSearchParams) => {
    getBasicInfoListsAsync(data);
    getStatusCountAsync(userId, data);
  };

  return (
    <Context.Provider value={contextValue}>
      <div className="m01">
        {!onInit && (
          <>
            <JorPor004Header />
            <Criteria onSearch={(data) => onSearch(data)} planStatusNameArray={planStatusNameArray} supplyMethodArray={supplyMethod} />
            <DataTable />
          </>
        )}
      </div>
    </Context.Provider>
  );
}

function JorPor004Header() {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">การแจ้งข้อมูลเบื้องต้น (จพ.004)</h4>
        {/* <Button onClick={() => navigate('detail')}><MdAdd className='me-2 fs-5' />สร้างการแจ้งข้อมูลเบื้องต้น (จพ.004)</Button> */}
      </div>
      <hr />
    </>
  );
}

function Criteria(props: { onSearch: (data: BasicInfoSearchParams) => void, planStatusNameArray: StatusModel[], supplyMethodArray: StatusModel[] }) {
  const { departmentItems, supplyMethodDDL } = useLoaderData() as loader;
  const { criteria, setCriteria } = useContext(Context);


  const handleChangeCriteria = (value: string | number | Date | boolean, prop: string) => {
    setCriteria({ ...criteria, [prop]: value });
  };

  const onSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();
    props.onSearch(criteria);
  };

  const onClearCriteria = () => {
    const criteriaData = {
      isPermission: true,
      supplyMethod: supplyMethodDDL.map((i) => i.value),
      status: props.planStatusNameArray.map((i) => i.value),
    } as BasicInfoSearchParams;
    setCriteria(criteriaData);
    props.onSearch(criteriaData);
  };

  const supplyMethodOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const supplyMethod = [...supplyMethodDDL.map((s) => s.value)];
        setCriteria({ ...criteria, supplyMethod });
        return;
      }
      setCriteria({ ...criteria, supplyMethod: [] });
      return;
    }
    if (result) {
      if ((criteria.supplyMethod.length + 1) === (supplyMethodDDL.length - 1)) {
        setCriteria({
          ...criteria,
          supplyMethod: [
            ...criteria.supplyMethod,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        supplyMethod: [
          ...criteria.supplyMethod,
          value,
        ],
      });
    } else {
      const supplyMethod = [...criteria.supplyMethod];
      const index = supplyMethod.findIndex((ps) => ps === value);

      if (index >= 0) {
        supplyMethod.splice(index, 1);
        const allIndex = supplyMethod.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          supplyMethod.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, supplyMethod });
      }
    }
  };

  const statusOnChange = (value: string, result: boolean) => {
    if (value === 'All') {
      if (result) {
        const status = [...props.planStatusNameArray.map((s) => s.value)];
        setCriteria({ ...criteria, status });
        return;
      }
      setCriteria({ ...criteria, status: [] });
      return;
    }
    if (result) {
      if ((criteria.status.length + 1) === (props.planStatusNameArray.length - 1)) {
        setCriteria({
          ...criteria,
          status: [
            ...criteria.status,
            value,
            'All',
          ],
        });
        return;
      }
      setCriteria({
        ...criteria,
        status: [
          ...criteria.status,
          value,
        ],
      });
    } else {
      const status = [...criteria.status];
      const index = status.findIndex((ps) => ps === value);

      if (index >= 0) {
        status.splice(index, 1);
        const allIndex = status.findIndex((ps) => ps === 'All');

        if (allIndex >= 0) {
          status.splice(allIndex, 1);
        }
        setCriteria({ ...criteria, status });
      }
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Row className="criteria align-items-center">
        <Col sm={12} md={4} xl={3}>
          <Input
            label="เลขที่บันทึกแต่งตั้ง"
            placeholder="เลขที่บันทึกแต่งตั้ง"
            value={criteria.appointNumber}
            onChange={(value) => handleChangeCriteria(value, 'appointNumber')} />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Input
            label="เลขที่รายการจัดซื้อจัดจ้าง"
            placeholder="เลขที่รายการจัดซื้อจัดจ้าง"
            value={criteria.planNumber}
            onChange={(value) => handleChangeCriteria(value, 'planNumber')} />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Input
            label="เลขที่รายงานขอซื้อขอจ้าง (จพ.004)"
            placeholder="เลขที่รายงานขอซื้อขอจ้าง (จพ.004)"
            value={criteria.jorpor04Number}
            onChange={(value) => handleChangeCriteria(value, 'jorpor04Number')} />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Selector items={departmentItems} placeholder="ฝ่าย/สำนัก" label="ฝ่าย/สำนัก" value={criteria.departmentId} onChange={(value) => handleChangeCriteria(value, 'departmentId')} />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Input label="ชื่อโครงการ" placeholder="ชื่อโครงการ" value={criteria.planName} onChange={(value) => handleChangeCriteria(value, 'planName')} />
        </Col>
        <Col sm={12} md={4} xl={3}>
          <Input label="เลขที่เอกสาร PR" placeholder="เลขที่เอกสาร PR" value={criteria.prNumber} onChange={(value) => handleChangeCriteria(value, 'prNumber')} />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="Type">
          วิธีจัดหา
          <div className="d-flex gap-2">
            {props.supplyMethodArray?.map((v, i) => (
              <div key={generateUniqueId(i)}>
                <Check
                  value={criteria.supplyMethod?.some((i) => i === v.value)}
                  onChange={(val) => supplyMethodOnChange(v.value, val)}
                  label={(
                    <>
                      {v.label}
                      {' '}
                      <Status type={StatusType.SUPPLYMETHOD} value={v.value} couting={v.count} badge />
                    </>
                  )}
                />
              </div>
            ))}
          </div>
        </Col>
        <Col md={6} className="Status">
          สถานะ
          <div className="d-flex gap-2 flex-wrap">
            {props.planStatusNameArray?.map((v, i) => (
              <div key={generateUniqueId(i)}>
                <Check
                  value={criteria.status?.some((i) => i === v.value)}
                  onChange={(val) => statusOnChange(v.value, val)}
                  label={(
                    <>
                      {v.label}
                      {' '}
                      <Status type={StatusType.JOR_POR_04_05_APPROVER} value={v.value} couting={v.count} badge />
                    </>
                  )} />
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4} xl={3}>
          <Check label="แสดงเฉพาะรายการที่ได้รับมอบหมาย" value={criteria.isPermission} onChange={(value) => handleChangeCriteria(value, 'isPermission')} />
        </Col>
      </Row>
      <Button type="submit" variant="primary" className="me-2">
        <FaSearch className="me-2" />
        ค้นหา
      </Button>
      <Button variant="outline-primary" type="button" onClick={onClearCriteria}>
        <FaEraser className="me-2" />
        ล้าง
      </Button>
    </Form>
  );
}

function DataTable() {
  const {
    basicInfoListData, setBasicInfoListData, totalRecords, setPage, setSize,
  } = useContext(Context);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const [indexId, setIndexId] = useState<number>(0);
  const { departmentId } = useAppContext();

  const goToPage = (id: string, basicInfoId?: string) => {
    if (id && basicInfoId) {
      navigate(`/pp/pp0205/detail/${id}/${basicInfoId}`);
      return;
    }

    if (id) {
      navigate(`/pp/pp0205/detail/${id}`);
      return;
    }

    if (basicInfoId) {
      navigate(`/pp/pp0205/detail/basicInfo/${basicInfoId}`);
    }

  };

  const openModalDelete = (index: number, id: string) => {
    setShowModal(true);
    setDeleteId(id);
    setIndexId(index);
  };

  const deleteAsync = async (index: number, id: string) => {
    if (id) {
      const response = await await PP0205Service.deleteBasicInformationAsync(id);
      if (response.status === HttpStatusCode.OK) {
        toast.success('ลบรายการสำเร็จ');
        const temp: BasicInfoResponse[] = [...basicInfoListData];
        temp.splice(index, 1);
        setBasicInfoListData(temp);
        setShowModal(false);
      } else {
        toast.error('เกิดข้อผิดพลาด');
      }
    } else {
      toast.error('เกิดข้อผิดพลาด');
    }

    setShowModal(false);
  };

  return (
    <>
      <Table className="mt-4" total={totalRecords} onChange={(size, page) => (setPage(page), setSize(size))}>
        <thead>
          <tr className="text-center">
            <th>เลขที่บันทึกแต่งตั้ง</th>
            <th>เลขที่รายการ<br />จัดซื้อจัดจ้าง</th>
            <th>เลขที่รายงาน<br />ขอซื้อขอจ้าง (จพ.004)</th>
            <th>สถานะ</th>
            <th>ฝ่าย/สำนัก</th>
            <th style={{ minWidth: 150, width: 500 }}>ชื่อโครงการ</th>
            <th>วงเงิน</th>
            <th>วิธีจัดหา</th>
            <th>
              ประมาณการช่วงเวลา
              <br />
              การจัดซื้อจัดจ้าง
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {basicInfoListData.map((data, index) => (
            <tr className="text-center">
              <td>
                <Button
                  variant="link"
                  onClick={() => goToPage(data.id, data.basicInfoId)}>
                  {data.appointNumber}
                </Button>
              </td>
              <td className='text-center'>
                <Button
                  variant="link"
                  onClick={() => goToPage(data.id, data.basicInfoId)}>
                  {data.planNumber}
                </Button></td>
              <td className="text-center">{data.jorpor04Number}</td>
              <td><Status type={StatusType.JOR_POR_04_05_APPROVER} value={data.status ?? ''} /></td>
              <td className="text-start">{data.departmentName}</td>
              <td className="text-start text-wrap">{data.planName}</td>
              <td className="text-end">{THCurrency(data.planBudget)}</td>
              <td>{data.supplyMethod}</td>
              <td>{GetMonthYearBC(data.expectingProcurementAt)}</td>
              <td className='text-center'>
                {(!data.id && (data.status === ProcessStatus.DRAFT || data.status === ProcessStatus.REJECT)) && (
                  <Button
                    disabled={departmentId !== data.departmentId}
                    variant='danger'
                    onClick={() => openModalDelete(index, data.basicInfoId)}>
                    <FaTrashAlt />
                  </Button>
                )
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        show={showModal}
        size='lg'
        onHide={() => setShowModal(!showModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ลบรายการ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการลบการแจ้งข้อมูลเบื้องต้น (จพ.004) หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-danger'
                onClick={() => setShowModal(!showModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  deleteAsync(indexId, deleteId);
                }}
              >
                ยืนยันลบข้อมูล
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}
