import { WorkGroupRequest } from 'models';
import http from 'utils/axios';

const createWorkGroupAsync = async (body: WorkGroupRequest) => await http.post('/workgroup', body);

const getWorkGroupListAsync = async (page: number, size: number, keyword?: string) => {
  const params = {
    page,
    size,
    keyword,
  };

  return await http.get('/workgroup', { params });
};

const getWorkGroupListByActiveStatus = async (activeStatus: boolean) => {
  const params = {
    activeStatus,
  };

  return await http.get('/workgroup/active-status', { params });
};

const getWorkGroupDetailAsync = async (id: string) => await http.get(`/workgroup/${id}`);

const updateWorkGroupAsync = async (id: string, body: WorkGroupRequest) => await http.put(`/workgroup/${id}`, body);

const deleteWorkGroupAsync = async (id: string) => await http.delete(`/workgroup/${id}`);

export default {
  createWorkGroupAsync,
  getWorkGroupListAsync,
  getWorkGroupDetailAsync,
  updateWorkGroupAsync,
  deleteWorkGroupAsync,
  getWorkGroupListByActiveStatus,
};