import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  Card,
  DatePicker,
  Input,
  Radio,
} from '../../../../../../components';
import Title from '../../../../../../components/Controls/Title';
import { EntrepreneurModel } from '../../../../../../models/CM/CM02Models';
import { LocationDataHandler } from './LocationDataHandler';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { ItemModel } from 'models';

interface IEntrepreneurDetailProps {
  data: EntrepreneurModel;
  onPartnerDataChange: (value: EntrepreneurModel) => void;
  isDisabled?: boolean;
  provincesDDL: ItemModel[];
}

const ENTREPRENEURTYPE = [
  {
    label: 'นิติบุคคล',
    value: 'JuristicPerson',
  },
  {
    label: 'บุคคลธรรมดา',
    value: 'Individual',
  },
];

export function EntrepreneurDetail(props: IEntrepreneurDetailProps) {
  const [partnerData, setPartnerData] = useState<EntrepreneurModel>(props.data);

  useEffect(() => {
    if (partnerData) {
      props.onPartnerDataChange(partnerData);
    }
  }, [partnerData]);

  const onTextChange = useCallback((key: string, value: string) => {
    setPartnerData(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onChangeSubDistrict = useCallback((id: string, label: string) => {
    setPartnerData(prevState => ({
      ...prevState,
      subDistrictCode: id,
      subDistrictName: label,
    }));
  }, []);

  const onChangeDistrict = useCallback((id: string, label: string) => {
    setPartnerData(prevState => ({
      ...prevState,
      districtCode: id,
      districtName: label,
    }));
  }, []);

  const onChangeProvince = useCallback((id: string, label: string) => {
    setPartnerData(prevState => ({
      ...prevState,
      provinceCode: id,
      provinceName: label,
    }));
  }, []);

  return (
    <Card className='mt-3'>
      <Title text='ข้อมูลคู่ค้า'
        className='fs-5 text-primary' />
      <Row className='mt-3 align-items-center'>
        <Col sm={8}
          md={8}>
          <Radio
            dflex
            items={ENTREPRENEURTYPE}
            value={partnerData?.traderType}
            onChange={(value) => onTextChange('traderType', value.toString())}
            disabled={props.isDisabled}
            name='name1' />
        </Col>
      </Row>
      <Row className='mt-3 align-items-center'>
        <Col sm={6}
          md={6}>
          <Input
            label='ชื่อคู่ค้า'
            value={partnerData?.name}
            onChange={(value) => onTextChange('name', value.toString())}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={6}
          md={6}>
          <Input
            type='number'
            label='เลขประจำตัวผู้เสียภาษี/เลขประจำตัวประชาชน'
            maxlength={13}
            value={partnerData?.taxId}
            onChange={(value) => onTextChange('taxId', value.toString())}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row>
        <Col sm={6}
          md={6}>
          <Input
            label='สถานที่จดทะเบียนนิติบุคคล'
            value={partnerData?.place}
            onChange={(value) => onTextChange('place', value.toString())}
            disabled={props.isDisabled}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={3}
          md={3}>
          <Input
            label='เลขที่'
            value={partnerData?.addressNo}
            onChange={(value) => onTextChange('addressNo', value.toString())}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={3}
          md={3}>
          <Input
            label='ถนน'
            value={partnerData?.addressStreet}
            onChange={(value) => onTextChange('addressStreet', value.toString())}
            disabled={props.isDisabled} />
        </Col>
      </Row>
      <Row>
        <LocationDataHandler
          provinceId={partnerData?.provinceCode}
          districtId={partnerData?.districtCode}
          subDistrictId={partnerData?.subDistrictCode}
          provincesDDL={props.provincesDDL}
          onProvinceChange={(id, label) => onChangeProvince(id, label)}
          onDistrictChange={(id, label) => onChangeDistrict(id, label)}
          onSubDistrictChange={(id, label) => onChangeSubDistrict(id, label)}
          isDisabled={props.isDisabled} />
      </Row>
      <Row className='mt-3 align-items-center'>
        <Col sm={4}
          md={4}>
          <DatePicker
            label='วันที่ หนังสือรับรองสำนักทะเบียนหุ้นส่วนบริษัท'
            value={partnerData?.certificateDate}
            onChange={(value) => onTextChange('certificateDate', value.toISOString())}
            disabled={props.isDisabled} />
        </Col>
        <Col sm={4}
          md={4}>
          <DatePicker
            label='วันที่ หนังสือมอบอำนาจ'
            value={partnerData?.authorizationDate}
            onChange={(value) => onTextChange('authorizationDate', value.toISOString())}
            disabled={props.isDisabled} />
        </Col>
      </Row>
    </Card>
  );
}
