import {
  Input,
  ProcessStatus,
  Selector,
  Status,
  StatusProject,
  StatusType,
  Table,
} from 'components';
import { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaSearch,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../../utils/useForm';

export default function PR0201() {
  return (
    <div className="m01">
      <PR0201Header />
      <Criteria />
      <DataTable />
    </div>
  );
}

function PR0201Header() {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-primary m-0">จัดทำคำสั่งแต่งตั้ง</h4>
      </div>
      <hr />
    </>
  );
}

function GetStatus(i: number) {
  switch (i) {
    case 1:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.PENDING} />;
    case 2:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.APPROVED} />;
    case 3:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.REJECTED} />;
    default:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.DRAFT} />;
  }
}

function GetStatusProject(i: number) {
  switch (i) {
    case 1:
      return <StatusProject IstatusProject="inprogress-plan" />;
    case 2:
      return <StatusProject IstatusProject="change" />;
    case 3:
      return <StatusProject IstatusProject="cancel-plan" />;
    case 4:
      return <StatusProject IstatusProject="inprogress" />;
    default:
      return <StatusProject IstatusProject="cancel" />;
  }
}

interface FormInput {
  code: string;
  department: string;
  name: string;
  budget: string;
  createBy: string;
  typeAll: string;
  type80: string;
  type60: string;
  statusAll: string;
  statusDraft: string;
  statusPending: string;
  statusApprove: string;
  statusReject: string;
}

function Criteria() {
  const [program, setProgram] = useState('');
  const { handleSubmit, data: formData, errors, handleClear } = useForm<FormInput>({
    validations: {

    },
    onSubmit: async () => { console.log(formData); },
  });

  return (
    <Form onSubmit={handleSubmit} id="formPL01">
      <Row className="criteria">
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="วันที่เอกสารบันทึกข้อความแต่งตั้ง" placeholder="วันที่เอกสารบันทึกข้อความแต่งตั้ง" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector label="ฝ่าย/สำนัก" value={program} onChange={(value) => setProgram(value.toString())} />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="ชื่อโครงการ" placeholder="ชื่อโครงการ" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="เรื่อง" placeholder="เรื่อง" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="คณะกรรมการจัดทำร่างขอบเขตของงาน" placeholder="คณะกรรมการจัดทำร่างขอบเขตของงาน" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Input label="ผู้จัดทำ" placeholder="ผู้จัดทำ" />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3}>
          <Selector label="สถานะ" value={program} onChange={(value) => setProgram(value.toString())} />
        </Col>

        <div className="d-flex gap-2">
          <Button form="formPL01" type="submit" variant="primary" className="d-flex align-items-center gap-2">
            <FaSearch />ค้นหา
          </Button>
          <Button variant="outline-primary" onClick={handleClear} className="d-flex align-items-center gap-2">
            <FaEraser />ล้าง
          </Button>
        </div>
      </Row>
    </Form>
  );
}

function DataTable() {
  const navigate = useNavigate();
  const goToPage = () => {
    navigate('/pr/pr0201/detail');
  };

  return (
    <Table className="mt-4">
      <thead>
        <tr className="text-center">
          <th>เลขที่แผน</th>
          <th>ฝ่าย/สำนัก</th>
          <th className="text-end">ชื่อแผน</th>
          <th>งบประมาณ (บาท)</th>
          <th>วิธีจัดหา</th>
          <th><p>ประมาณการช่วงเวลา<br />
            การจัดซื้อจัดจ้าง
          </p>
          </th>
          <th><p>วันที่<br />
            ประกาศแผน
          </p>
          </th>
          <th>สถานะโครงการ</th>
          <th>สถานะ</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 5 }).map((_, index) => (
          <tr className="text-center" key={index}>
            <td><Button variant="link" onClick={goToPage}>660001</Button> </td>
            <td className="text-start">บช. : ฝ่ายการบัญชี</td>
            <td className="text-start">จ้างพัฒนาระบบ S1</td>
            <td className="text-end">1,000,000.00</td>
            <td>60 : เจาะจง</td>
            <td>03/2566</td>
            <td>03/2566</td>
            <td>{GetStatusProject(index)}</td>
            <td>{GetStatus(index)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
