import {
  Breadcrumb,
  Input,
  ProcessStatus,
  Status,
  StatusType,
  Table,
} from 'components';
import { InputTextArea } from 'components/Controls/InputTextArea';
import Title from 'components/Controls/Title';
import { Modal } from 'components/Modal';
import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaAlignLeft,
  FaCheck,
  FaLongArrowAltLeft,
  FaUndo,
} from 'react-icons/fa';
import { FiCornerDownLeft } from 'react-icons/fi';
import { TbListSearch } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const BREADCRUMB_INFO = [
  {
    routerName: '/pr/pr0301', label: 'อนุมัติรายงานขอซื้อขอจ้าง ( จพ.005 )',
  },
  {
    routerName: '/pr/pr0301/detail', label: 'รายละเอียดอนุมัติรายงานขอซื้อขอจ้าง ( จพ.005 )',
  },
];

function GetStatus(i: number) {
  switch (i) {
    case 1:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.PENDING} />;
    case 2:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.APPROVED} />;
    case 3:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.REJECTED} />;
    default:
      return <Status type={StatusType.PROCESS} value={ProcessStatus.DRAFT} />;
  }
}

function DataTable() {
  return (
    <Table className="mt-4">
      <thead>
        <tr className="text-center">
          <th>ลำดับ</th>
          <th>รหัสพนักงาน</th>
          <th>ชื่อ-นามสกุล</th>
          <th>ตำแหน่ง</th>
          <th>สถานะ</th>
          <th>วันที่อนุมัติ</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 5 }).map((_, index) => (
          <tr className="text-center" key={index}>
            <td>{index}</td>
            <td>5900010</td>
            <td>สมศรี แดนไทย</td>
            <td>รักษาการผู้อำนวยการสำนักงบประมาณ</td>
            <td>{GetStatus(index)}</td>
            <td>28-11-2566 16:24:22</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function DetailPR0301() {
  const [showEdit, setShowEdit] = useState(false);
  const [showApprove, setShowApprove] = useState(false);

  const navigate = useNavigate();
  const backToIndex = () => {
    navigate('/pr/pr0301');
  };

  return (
    <div className="document">
      <h4 className="mt-2 mb-0 text-primary text-start">รายละเอียดอนุมัติรายงานขอซื้อขอจ้าง ( จพ.005 )</h4>
      <Breadcrumb data={BREADCRUMB_INFO} />
      <Card className="mt-3">
        <Card.Body>
          <Title text="ข้อมูลรายงานขอชื้่้อขอจ้าง (จพ.005)" className="fs-5 text-primary" />
          <Row className="mt-3 align-items-center">
            <Col sm={12} md={6}>
              <Input label="เลขที่เอกสาร PR" value="เลขที่เอกสาร PR" disabled />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col sm={12} md={6}>
              <Input label="โครงการ" value="059 / 2565 : โครงการ Digital Procurement ฝ่ายจัดหาและการพัสดุ" disabled />
            </Col>
            <Col sm={12} md={3}>
              <Button variant="link"><TbListSearch className="fs-4" /> ไปยังแผน</Button>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col sm={12} md={4} xl={3}>
              <Input label="งบประมาณโครงการ (บาท)" value="1,000,000.00" disabled textEnd />
            </Col>
            <Col sm={12} md={4} xl={3}>
              <Input label="วิธีจัดหา" value="80" disabled />
            </Col>
            <Col sm={12} md={4} xl={3} className="pt-3">
              <Input label="" value="เจาะจง" disabled />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Title text="สถานะการอนุมัติตามสายงาน" className="fs-5 text-primary" />
          <DataTable />
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Title text="เอกสารรายงานขอซื้อขอจ้าง (จพ.005)" className="fs-5 text-primary" />
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-center gap-4 mt-3">
        <Button
          onClick={() => backToIndex()}
          variant="light"
        >
          <FaLongArrowAltLeft className="me-2" />ย้อนกลับ
        </Button>
        <Button
          onClick={() => setShowEdit(true)}
          variant="danger"
        >
          <FaUndo className="me-2" />ส่งกลับแก้ไข
        </Button>
        <Button
          onClick={() => setShowApprove(true)}
        >
          <FaAlignLeft className="me-2" />อนุมัติ
        </Button>
      </div>

      <Modal
        show={showEdit}
        onHide={() => setShowEdit(false)}
        size="xl"
      >
        <ModalBT.Header closeButton>
          <ModalBT.Title>ให้ความเห็นชอบ</ModalBT.Title>
        </ModalBT.Header>
        <ModalBT.Body className="p-0 ps-4">
          <InputTextArea label="หมายเหตุ" rule={{ required: true }} />
        </ModalBT.Body>
        <ModalBT.Footer className="justify-content-center">
          <Button variant="light" onClick={() => setShowEdit(!showEdit)}>
            ยกเลิก
          </Button>
          <Button variant="danger">
            <FiCornerDownLeft className="me-2" />ส่งกลับแก้ไข
          </Button>
        </ModalBT.Footer>
      </Modal>

      <Modal
        show={showApprove}
        onHide={() => setShowApprove(false)}
        size="xl"
      >
        <ModalBT.Header closeButton>
          <ModalBT.Title>ให้ความเห็นชอบ</ModalBT.Title>
        </ModalBT.Header>
        <ModalBT.Body className="p-0 ps-4">
          <InputTextArea label="ความเห็น" />
        </ModalBT.Body>
        <ModalBT.Footer className="justify-content-center">
          <Button variant="light" onClick={() => setShowApprove(!showApprove)}>
            ยกเลิก
          </Button>
          <Button variant="success">
            <FaCheck className="me-2" /> ให้ความเห็น
          </Button>
        </ModalBT.Footer>
      </Modal>
    </div>
  );
}

export default DetailPR0301;
