import {
  InputTextArea,
  Modal,
} from 'components';
import {
  Button,
  Modal as ModalBT,
} from 'react-bootstrap';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { FaUndo } from 'react-icons/fa';

interface Props {
  show: boolean;
  onHide: () => void;
}

export function EditModal({ show, onHide }: Props) {
  return (
    <Modal
      show={show}
      size='lg'
      onHide={() => onHide()}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4'>
            <InputTextArea label='หมายเหตุ'
              rule={{ required: true }} />
          </ModalBT.Body>
          <ModalBT.Footer className='justify-content-center'>
            <Button
              variant='light'
              onClick={() => onHide()}
              className='me-2 px-3'
            >
              ยกเลิก
            </Button>
            <Button
              variant='danger'
              className='me-2 px-3'
            >
              <FaUndo className='mx-2 pb-1 fs-4' />
              ส่งกลับแก้ไข
            </Button>
          </ModalBT.Footer>
        </>
      )}
    />
  );
}
