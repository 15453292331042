import { ApexOptions } from 'apexcharts';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

interface Data {
  label: string;
  color: string;
  value: number[];
}

interface Props {
  categories: string[];
  data: Data[];
}

export function ColumnChart(props: Props) {
  const options = useMemo(() => {
    return {
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: props.categories,
      },
      colors: props.data.map(d => d.color),
      tooltip: {
        y: {
          formatter: (val: number) => `${val}`,
        },
      },
    } as ApexOptions;
  }, [props]);

  const series = useMemo(() => {
    return props.data.map(d => ({
      name: d.label,
      data: d.value,
    }))
  }, [props]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={350} />
  );
}
