import StepProgress from 'components/StepProgress';
import { InvitationDetailModel } from 'models/PR/InvitationModels';
import {
  createContext,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import InvitationServices from 'services/PR/InvitationServices';
import { HttpStatusCode } from 'utils';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

interface ProgressBarRefType {
  clickNext: () => void;
  clickBack: () => void;
}

type InvitationContext = {
  data: InvitationDetailModel
  setData: Dispatch<React.SetStateAction<InvitationDetailModel>>,
};

enum CriteriaStatus {
  Draft = 'Draft',
  DraftTor = 'DraftTor',
  WaitingForCommitteeApprove = 'WaitingForCommitteeApprove',
  DraftSectionApprover = 'DraftSectionApprover',
  WaitingForApprove = 'WaitingForApprove',
  Approved = 'Approved',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
}

export const Context = createContext({} as InvitationContext);

export default function InvitationDetail() {
  const childRef = useRef<ProgressBarRefType>(null);
  const { jorPor05Id, id } = useParams();
  const [data, setData] = useState<InvitationDetailModel>({} as InvitationDetailModel);
  const [defualtStep, setDefualtStep] = useState(1);

  useEffect(() => {
    if (jorPor05Id) {
      if (id) {
        getInvitationByIdAsynce(jorPor05Id, id);
      } else {
        getInvitationByIdAsynce(jorPor05Id, undefined);
      }
    }
  }, [jorPor05Id, id]);

  const getInvitationByIdAsynce = async (jorPor05Id: string, id?: string) => {
    const response = await InvitationServices.getInvitationByIdAsync(jorPor05Id, id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data.dto);
      setStepByStatus(response.data.dto.status);
    }
  };

  const setStepByStatus = (status: string) => {
    switch (status) {
      case CriteriaStatus.Draft:
        setDefualtStep(1);
        break;
      case CriteriaStatus.WaitingForApprove:
        setDefualtStep(2);
        break;
      case CriteriaStatus.Approved:
        setDefualtStep(2);
        break;
      case CriteriaStatus.Rejected:
        setDefualtStep(1);
        break;
      case CriteriaStatus.Canceled:
        setDefualtStep(1);
        break;
      default:
        break;
    }
  };

  const obj = [{
    title: <p>ข้อมูลการหนังสือเชิญชวน
      ผู้ประกอบการ
    </p>,
    displayIndex: '1',
    child: <Step1 onClickNext={() => clickNext()}
      onClickBack={() => backToIndex()} />,
  },
  {
    title: <p>เชิญชวนผู้ประกอบการ</p>,
    displayIndex: '2',
    child: <Step2 onClickNext={() => clickNext()}
      onClickBack={() => clickBack()} />,
  },
    // {
    //   title: <p>ตัวอย่างเอกสาร</p>,
    //   displayIndex: '3',
    //   child: <Step3 onClickNext={() => clickNext()}
    //     onClickBack={() => clickBack()} />,
    // },
  ];

  const clickNext = () => {
    childRef.current?.clickNext();
  };

  const clickBack = () => {
    childRef.current?.clickBack();
  };

  const navigate = useNavigate();

  const backToIndex = () => {
    navigate('/procurement-invitation-order');
  };

  return (
    <div className='document'>
      <h4 className='mt-2 mb-0 text-primary text-start'>จัดทำหนังสือเชิญชวนผู้ประกอบการ</h4>
      <Row>
        <Col xs={12}
          className='mb-2'>
          <Context.Provider value={{ data, setData }}>
            <StepProgress progressBarData={obj}
              ref={childRef}
              step={defualtStep} />
          </Context.Provider>
        </Col>
      </Row>
    </div>
  );
}
