import Cookie from 'cookie-universal';
import {
  InvitationDetailModel,
  SearchCriteriaModel,
} from 'models/PR/InvitationModels';
import http from 'utils/axios';

const qs = require('qs');

const cookies = Cookie();
const getFullName = cookies.get('fullName') as string;
const api = '/invite';

const getListInvitationAsync = (searchModel: SearchCriteriaModel) => {
  const params = {
    page: searchModel.page,
    size: searchModel.size,
    jorPor05Number: searchModel.jorPor05Number,
    torNumber: searchModel.torNumber,
    departmentId: searchModel.departmentId,
    planName: searchModel.planName,
    planNumber: searchModel.planNumber,
    traderName: searchModel.traderName,
    supplyMethod: searchModel.supplyMethodType,
    inviteStatus: searchModel.inviteStatus,
    isResponsible: searchModel.isResponsible,
    inviteNumber: searchModel.inviteNumber,
  };

  return http.get(`${api}/pagination`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountStatusAsync = (searchModel: SearchCriteriaModel) => {
  const params = {
    page: searchModel.page,
    size: searchModel.size,
    jorPor05Number: searchModel.jorPor05Number,
    torNumber: searchModel.torNumber,
    departmentId: searchModel.departmentId,
    planName: searchModel.planName,
    planNumber: searchModel.planNumber,
    traderName: searchModel.traderName,
    supplyMethod: searchModel.supplyMethodType,
    inviteStatus: searchModel.inviteStatus,
    isResponsible: searchModel.isResponsible,
    inviteNumber: searchModel.inviteNumber,
  };
  return http.get(`${api}/countStatus`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getCountSupplyMethodAsync = (searchModel: SearchCriteriaModel) => {
  const params = {
    page: searchModel.page,
    size: searchModel.size,
    jorPor05Number: searchModel.jorPor05Number,
    torNumber: searchModel.torNumber,
    departmentId: searchModel.departmentId,
    planName: searchModel.planName,
    planNumber: searchModel.planNumber,
    traderName: searchModel.traderName,
    supplyMethod: searchModel.supplyMethodType,
    inviteStatus: searchModel.inviteStatus,
    isResponsible: searchModel.isResponsible,
    inviteNumber: searchModel.inviteNumber,
  };
  return http.get(`${api}/countSupplyMethod`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getInvitationByIdAsync = (jorpor05Id: string, id?: string) => {
  if (id) {
    return http.get(`${api}/jorPor05/${jorpor05Id}/id/${id}`);
  }

  return http.get(`${api}/jorPor05/${jorpor05Id}/id`);
};

const createInvitationAsync = (body: InvitationDetailModel, files: File[]) => {
  const formData = new FormData();
  if (body.termOfRefId) {
    formData.append('termOfRefId', body.termOfRefId.toString());
  }
  formData.append('jorPor05Id', body.jorPor05Id);
  formData.append('planName', body.planName);
  formData.append('sourceAndReasons', body.sourceAndReasons);
  formData.append('financialAmount', body.financialAmount.toString());
  formData.append('departmentId', body.departmentId);
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt!).toISOString());
  formData.append('supplyMethod', body.supplyMethodId);
  formData.append('supplyMethodType', body.supplyMethodTypeId);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialTypeId);
  formData.append('submitProposalsStartDate', new Date(body.submitProposalsStartDate!).toISOString());
  formData.append('submitProposalsEndDate', new Date(body.submitProposalsEndDate!).toISOString());
  if (body.needToKnowWithinDate) {
    formData.append('needToKnowWithinDate', new Date(body.needToKnowWithinDate!).toISOString());
  }
  if (body.clarifyDetailViaDate) {
    formData.append('clarifyDetailViaDate', new Date(body.clarifyDetailViaDate!).toISOString());
  }

  if (files && files.length !== 0) {
    files.forEach((file) => {
      formData.append('Files', file);
    });
  }

  if (body.inviteEntrepreneurs && body.inviteEntrepreneurs.length > 0) {
    body.inviteEntrepreneurs.forEach((data, index) => {
      if (data.id) {
        formData.append(`inviteEntrepreneurs[${index}][id]`, data.id);
      }
      formData.append(`inviteEntrepreneurs[${index}][sequence]`, data.sequence.toString());
      formData.append(`inviteEntrepreneurs[${index}][TraderId]`, data.traderId);
      formData.append(`inviteEntrepreneurs[${index}][traderTaxId]`, data.traderTaxId);
      formData.append(`inviteEntrepreneurs[${index}][traderType]`, data.traderType);
      formData.append(`inviteEntrepreneurs[${index}][establishmentName]`, data.establishmentName);
      formData.append(`inviteEntrepreneurs[${index}][email]`, data.email);
      formData.append(`inviteEntrepreneurs[${index}][emailSent]`, data.emailSent ? 'true' : 'false');
      if (data.coiResult !== undefined && data.coiResult !== null) {
        formData.append(`inviteEntrepreneurs[${index}][coiResult]`, data.coiResult ? 'true' : 'false');
      }
      if (data.watchListResult !== undefined && data.watchListResult !== null) {
        formData.append(`inviteEntrepreneurs[${index}][watchListResult]`, data.watchListResult ? 'true' : 'false');
      }
      if (data.egpResult !== undefined && data.egpResult !== null) {
        formData.append(`inviteEntrepreneurs[${index}][egpResult]`, data.egpResult ? 'true' : 'false');
      }
      formData.append(`inviteEntrepreneurs[${index}][coiRemark]`, data.coiRemark);
      formData.append(`inviteEntrepreneurs[${index}][watchListRemark]`, data.watchListRemark);
      formData.append(`inviteEntrepreneurs[${index}][egpRemark]`, data.egpRemark);

      if (data.filesCoi !== undefined && data.filesCoi.length > 0) {
        data.filesCoi.forEach((file) => {
          formData.append(`inviteEntrepreneurs[${index}].filesCoi`, file);
        });
      }

      if (data.filesWatchList !== undefined && data.filesWatchList.length > 0) {
        data.filesWatchList.forEach((file) => {
          formData.append(`inviteEntrepreneurs[${index}].filesWatchList`, file);
        });
      }

      if (data.filesEgp !== undefined && data.filesEgp.length > 0) {
        data.filesEgp.forEach((file) => {
          formData.append(`inviteEntrepreneurs[${index}].filesEgp`, file);
        });
      }
    });
  }

  if (body.inviteAcceptors && body.inviteAcceptors.length > 0) {
    body.inviteAcceptors.forEach((data, index) => {
      if (data.id) {
        formData.append(`inviteAcceptors[${index}][id]`, data.id);
      }

      formData.append(`inviteAcceptors[${index}][UserId]`, data.userId);
      formData.append(`inviteAcceptors[${index}][PositionName]`, data.positionName ? data.positionName : '');
      formData.append(`inviteAcceptors[${index}][SectionType]`, data.sectionType ? data.sectionType : '');
      formData.append(`inviteAcceptors[${index}][SectionSequence]`, data.sectionSequence ? data.sectionSequence.toString() : '');
      formData.append(`inviteAcceptors[${index}][Sequence]`, data.sequence!.toString());
      // formData.append(`inviteAcceptors[${index}][UserId]`, data.userId);
      // formData.append(`inviteAcceptors[${index}][FullName]`, data.fullName);
      // formData.append(`inviteAcceptors[${index}][DepartmentName]`, data.departmentName);
      // formData.append(`inviteAcceptors[${index}][PositionName]`, data.positionName);
      // formData.append(`inviteAcceptors[${index}][Sequence]`, data.sequence!.toString());
      // formData.append(`inviteAcceptors[${index}][Status]`, data.status);
      // formData.append(`inviteAcceptors[${index}][ApprovedDateAt]`, data.approvedDateAt ? new Date(data.approvedDateAt).toISOString() : '');
      // formData.append(`inviteAcceptors[${index}][IsDisabled]`, data.isDisabled ? 'true' : 'false');
      // formData.append(`inviteAcceptors[${index}][Remark]`, data.remark === null ? '' : data.remark);
      // formData.append(`inviteAcceptors[${index}][IsAvailable]`, data.isDisabled ? 'true' : 'false');
      // formData.append(`inviteAcceptors[${index}][AvailableRemark]`, data.remark === null ? '' : data.remark);
    });
  }

  if (body.inviteCommittees && body.inviteCommittees.length > 0) {
    body.inviteCommittees.forEach((data, index) => {

      formData.append(`inviteCommittees[${index}][CommitteeType]`, data.committeeType);
      formData.append(`inviteCommittees[${index}][UserId]`, data.userId);
      formData.append(`inviteCommittees[${index}][PositionName]`, data.positionName ? data.positionName : '');
      formData.append(`inviteCommittees[${index}][PositionOnBoardId]`, data.positionOnBoardId ? data.positionOnBoardId.toString() : '');
      formData.append(`inviteCommittees[${index}][Sequence]`, data.sequence > 0 ? data.sequence.toString() : (index + 1).toString());
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(api, formData, config);
};

const updateInvitationAsync = (body: InvitationDetailModel, files: File[]) => {
  const formData = new FormData();
  if (body.termOfRefId) {
    formData.append('termOfRefId', body.termOfRefId.toString());
  }

  formData.append('jorPor05Id', body.jorPor05Id);
  formData.append('planName', body.planName);
  formData.append('sourceAndReasons', body.sourceAndReasons);
  formData.append('financialAmount', body.financialAmount.toString());
  formData.append('departmentId', body.departmentId);
  formData.append('expectingProcurementAt', new Date(body.expectingProcurementAt!).toISOString());
  formData.append('supplyMethod', body.supplyMethodId);
  formData.append('supplyMethodType', body.supplyMethodTypeId);
  formData.append('supplyMethodSpecialType', body.supplyMethodSpecialTypeId);
  formData.append('submitProposalsStartDate', new Date(body.submitProposalsStartDate!).toISOString());
  formData.append('submitProposalsEndDate', new Date(body.submitProposalsEndDate!).toISOString());

  if (body.documentId) {
    formData.append('documentId', body.documentId);
  }

  if (body.needToKnowWithinDate) {
    formData.append('needToKnowWithinDate', new Date(body.needToKnowWithinDate!).toISOString());
  }
  if (body.clarifyDetailViaDate) {
    formData.append('clarifyDetailViaDate', new Date(body.clarifyDetailViaDate!).toISOString());
  }

  if (files && files.length !== 0) {
    files.forEach((file) => {
      formData.append('Files', file);
    });
  }

  if (body.inviteEntrepreneurs && body.inviteEntrepreneurs.length > 0) {
    body.inviteEntrepreneurs.forEach((data, index) => {
      if (data.id) {
        formData.append(`inviteEntrepreneurs[${index}][id]`, data.id);
      }
      formData.append(`inviteEntrepreneurs[${index}][sequence]`, data.sequence.toString());
      formData.append(`inviteEntrepreneurs[${index}][TraderId]`, data.traderId);
      formData.append(`inviteEntrepreneurs[${index}][traderTaxId]`, data.traderTaxId);
      formData.append(`inviteEntrepreneurs[${index}][traderType]`, data.traderType);
      formData.append(`inviteEntrepreneurs[${index}][establishmentName]`, data.establishmentName);
      formData.append(`inviteEntrepreneurs[${index}][email]`, data.email);
      formData.append(`inviteEntrepreneurs[${index}][emailSent]`, data.emailSent ? 'true' : 'false');
      if (data.coiResult !== undefined && data.coiResult !== null) {
        formData.append(`inviteEntrepreneurs[${index}][coiResult]`, data.coiResult ? 'true' : 'false');
      }
      if (data.watchListResult !== undefined && data.watchListResult !== null) {
        formData.append(`inviteEntrepreneurs[${index}][watchListResult]`, data.watchListResult ? 'true' : 'false');
      }
      if (data.egpResult !== undefined && data.egpResult !== null) {
        formData.append(`inviteEntrepreneurs[${index}][egpResult]`, data.egpResult ? 'true' : 'false');
      }
      formData.append(`inviteEntrepreneurs[${index}][coiRemark]`, data.coiRemark === null ? '' : data.coiRemark);
      formData.append(`inviteEntrepreneurs[${index}][watchListRemark]`, data.watchListRemark === null ? '' : data.watchListRemark);
      formData.append(`inviteEntrepreneurs[${index}][egpRemark]`, data.egpRemark === null ? '' : data.egpRemark);

      if (data.filesCoi !== undefined && data.filesCoi.length > 0) {
        data.filesCoi.forEach((file) => {
          formData.append(`inviteEntrepreneurs[${index}].filesCoi`, file);
        });
      }

      if (data.filesWatchList !== undefined && data.filesWatchList.length > 0) {
        data.filesWatchList.forEach((file) => {
          formData.append(`inviteEntrepreneurs[${index}].filesWatchList`, file);
        });
      }

      if (data.filesEgp !== undefined && data.filesEgp.length > 0) {
        data.filesEgp.forEach((file) => {
          formData.append(`inviteEntrepreneurs[${index}].filesEgp`, file);
        });
      }
    });
  }

  if (body.inviteAcceptors && body.inviteAcceptors.length > 0) {
    body.inviteAcceptors.forEach((data, index) => {
      if (data.id) {
        formData.append(`inviteAcceptors[${index}][id]`, data.id);
      }
      formData.append(`inviteAcceptors[${index}][UserId]`, data.userId);
      formData.append(`inviteAcceptors[${index}][PositionName]`, data.positionName ? data.positionName : '');
      formData.append(`inviteAcceptors[${index}][SectionType]`, data.sectionType ? data.sectionType : '');
      formData.append(`inviteAcceptors[${index}][SectionSequence]`, data.sectionSequence ? data.sectionSequence.toString() : '');
      formData.append(`inviteAcceptors[${index}][Sequence]`, data.sequence!.toString());
      // formData.append(`inviteAcceptors[${index}][AcceptDate]`, data.acceptDate ? new Date(data.acceptDate).toISOString() : '');
      // formData.append(`inviteAcceptors[${index}][FullName]`, data.fullName);
      // formData.append(`inviteAcceptors[${index}][DepartmentName]`, data.departmentName);
      // formData.append(`inviteAcceptors[${index}][Status]`, data.status ? data.status : '');
      // formData.append(`inviteAcceptors[${index}][IsDisabled]`, data.isDisabled ? 'true' : 'false');
      // formData.append(`inviteAcceptors[${index}][Remark]`, data.remark === null ? '' : data.remark);
      // formData.append(`inviteAcceptors[${index}][IsAvailable]`, data.isDisabled ? 'true' : 'false');
      // formData.append(`inviteAcceptors[${index}][AvailableRemark]`, data.remark === null ? '' : data.remark);
    });
  }

  if (body.inviteCommittees && body.inviteCommittees.length > 0) {
    body.inviteCommittees.forEach((data, index) => {
      if (data.id) {
        formData.append(`inviteCommittees[${index}][Id]`, data.id);
      }
      formData.append(`inviteCommittees[${index}][CommitteeType]`, data.committeeType);
      formData.append(`inviteCommittees[${index}][UserId]`, data.userId);
      formData.append(`inviteCommittees[${index}][PositionName]`, data.positionName ? data.positionName : '');
      formData.append(`inviteCommittees[${index}][PositionOnBoardId]`, data.positionOnBoardId ? data.positionOnBoardId.toString() : '');
      formData.append(`inviteCommittees[${index}][Sequence]`, data.sequence > 0 ? data.sequence.toString() : (index + 1).toString());
      formData.append(`inviteCommittees[${index}][Remark]`, data.remark ? data.remark.toString() : '');
      formData.append(`inviteCommittees[${index}][IsDisabled]`, data.isDisabled ? 'true' : 'false');

    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/${body.id}`, formData, config);
};

const getInviteEntrepreneurDocumentAsync = (id: string, documentType: string) => {
  const params = {
    DocumentType: documentType,
  };

  return http.get(`${api}/entrepreneur/${id}/attachment`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const deleteInvitationDocumentByIdAsync = (id: string, docId: string) => http.delete(`${api}/${id}/documents/${docId}`);

const onPendingAsync = (id: string) => http.put(`${api}/pending/${id}`);

const onApproveAsync = (id: string, remark: string) => {
  const formData = new FormData();
  formData.append('Remark', remark);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return http.put(`${api}/approved/${id}`, formData, config);
};

const onRejectAsync = (id: string, remark: string) => http.put(`${api}/rejected/${id}`, { remark });

const onRecallAsync = (id: string) => http.put(`${api}/Recall/${id}`);

const onSentEmailAsync = (id: string, traderId: string) => http.post(`${api}/sendEmail/${id}/EntrepreneurId/${traderId}`);

const onAttachmentInvitationDocumentByIdAsync = (id: string, files: File[]) => {
  const formData = new FormData();

  if (files && files.length !== 0) {
    files.forEach((file) => {
      formData.append('Files', file);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.put(`${api}/attachment/${id}`, formData, config);
};

const getListHistoryAsync = (page: number, size: number, id: string | undefined) => {
  const params = {
    page,
    size,
    id: id,
  };
  return http.get(`${api}/history`, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  });
};

const getDocumentAsync = (id: string, isReadOnly: boolean) => {
  return http.get(`${api}/${id}/document/${isReadOnly}`);
};

const onAttachmentInviteEntrepreneurDocumentByIdAsync = (id: string, documentType: string, files: File[]) => {
  const formData = new FormData();

  if (files && files.length !== 0) {
    files.forEach((file) => {
      formData.append('Files', file);
    });
  }

  formData.append('DocumentType', documentType.toString());

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`${api}/entrepreneur/${id}/attachment`, formData, config);
};

const deleteInviteEntrepreneurDocumentByIdAsync = (id: string, docId: string) => http.delete(`${api}/entrepreneur/${id}/attachment/${docId}`);

const getInviteDocumentAsync = (documentId: string) =>
  http.get(`${api}/attachment/${documentId}`, {
    responseType: 'blob',
  });

export default {
  getListInvitationAsync,
  getInvitationByIdAsync,
  createInvitationAsync,
  updateInvitationAsync,
  getCountStatusAsync,
  getCountSupplyMethodAsync,
  deleteInvitationDocumentByIdAsync,
  onPendingAsync,
  onApproveAsync,
  onRejectAsync,
  getListHistoryAsync,
  getDocumentAsync,
  onAttachmentInvitationDocumentByIdAsync,
  onRecallAsync,
  onSentEmailAsync,
  onAttachmentInviteEntrepreneurDocumentByIdAsync,
  getInviteEntrepreneurDocumentAsync,
  deleteInviteEntrepreneurDocumentByIdAsync,
  getInviteDocumentAsync,
};
