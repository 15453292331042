import {
  Col,
  Container,
  Image,
  Row,
} from 'react-bootstrap';

export default function FaqPage() {
  return (
    <Container fluid="sm" className='faq pt-3'>
      <Row className='align-items-center'>
        <Col xs={12} className='mb-5'>
          <Image src="/images/logo-207x108.png" width={90} />
        </Col>
        <Col xs={12} md={6}>
          <p className='fs-2'>คำถามที่พบบ่อย/FAQs
          </p>
          <ul className='lh-lg ps-3'>
            <li><a href="#1" className='text-primary fs-5'>1. ข้อมูลสำคัญทางการเงิน</a></li>
            <li><a href="#2" className='text-primary fs-5'>2. งบการเงิน</a></li>
            <li><a href="#3" className='text-primary fs-5'>3. แผนยุทธศาสตร์</a></li>
            <li><a href="#4" className='text-primary fs-5'>4. แผนงาน โครงการ และงบประมาณ</a></li>
            <li><a href="#5" className='text-primary fs-5'>5. แผนแม่บทการจัดการความรู้</a></li>
            <li><a href="#6" className='text-primary fs-5'>6. แผนแม่บทการจัดการนวัตกรรม</a></li>
          </ul>
        </Col>
        <Col xs={6} className="d-none d-md-block">
          <Image src="/images/faq.png" className='w-100 d-block ms-auto' />
        </Col>
      </Row>
      <Col xs={12} id="1" >
        <p className='fs-6 text-primary mb-1'>1. ข้อมูลสำคัญทางการเงิน
        </p>
        <p className="ps-3 fw-lighter">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book. It has survived not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
          sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.
        </p>
      </Col>
      <Col xs={12} id="2" >
        <p className='fs-6 text-primary mb-1'>2. งบการเงิน
        </p>
        <p className="ps-3 fw-lighter">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book. It has survived not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
          sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.
        </p>
      </Col>
      <Col xs={12} id="3" >
        <p className='fs-6 text-primary mb-1'>3. แผนยุทธศาสตร์
        </p>
        <p className="ps-3 fw-lighter">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book. It has survived not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
          sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.
        </p>
      </Col>
      <Col xs={12} id="4" >
        <p className='fs-6 text-primary mb-1'>4. แผนงาน โครงการ และงบประมาณ
        </p>
        <p className="ps-3 fw-lighter">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book. It has survived not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
          sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.
        </p>
      </Col>
      <Col xs={12} id="5" >
        <p className='fs-6 text-primary mb-1'>5. แผนแม่บทการจัดการความรู้
        </p>
        <p className="ps-3 fw-lighter">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book. It has survived not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
          sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.
        </p>
      </Col>

      <Col xs={12} id="6" >
        <p className='fs-6 text-primary mb-1'>6. แผนแม่บทการจัดการนวัตกรรม
        </p>
        <p className="ps-3 fw-lighter">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book. It has survived not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
          sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.
        </p>
      </Col>
      <Col xs={12} >
        <p className="ps-3 fw-lighter">
          1) ผมเข้าใจว่าระบบ Digital Procurement ต้องเริ่มกระบวนงานตั้งแต่ขั้นตอนรายงานการจัดซื้อจัดจ้าง (รายปี/ระหว่างปี) ดังนั้นงานที่ปัจจุบันอยู่ระหว่างสัญญา เช่น งานจ้างบริการรปภ.(1 ตค.65-30 กย.68) ,งานเช่าที่จอดรถบมจ.ทิพย (1 เมย.66-31 มีค.67) และงานจ้าง MA ระบบต่างๆ เป็นต้น จะไม่สามารถใช้งานระบบได้ ถูกต้องใช่มั้ยครับ = ต้องทำแบบเดิมไปจนกว่าจะสิ้นสุดสัญญาก่อน
        </p>
        <p className="ps-3 fw-lighter">
          2) ขั้นตอนการแต่งตั้งคกก.TOR และราคากลาง เดิมบางงานมีการแต่งตั้งโดยตำแหน่ง เช่น ประธาน = ผช.ผอ.บสส.กำกับดูแลสจป. ,กรรมการ = หน.งานบริหารทั่วไป เป็นต้น จะไม่สามารถดำเนินการแบบเดิมได้ เนื่องจากการกรอกข้อมูลรายชื่อคกก.จะถูกดึงมาจากระบบ ERP&HR เพื่อให้ระบบใช้งานร่วมกัน ดังนั้นควรจะต้องแต่งตั้งตามรายชื่อไปก่อน เมื่อมีการแก้ไขเปลี่ยนแปลงผู้ดำรงตำแหน่งดังกล่าวค่อยมาขออนุมัติแก้ไขรายชื่อคกก.ในระบบ Digital Procurement ตามขั้นตอน ถูกต้องใช่มั้ยครับ
        </p>
        <p className="ps-3 fw-lighter">
          3) ขั้นตอนการแต่งตั้งคกก.TOR และราคากลาง กรณีที่มีการแต่งตั้งพนง.นอกฝ่าย (หรือในฝ่ายก็ตาม) ปกติจะต้องขออนุญาต/ขอให้ต้นสังกัดมอบหมายงานมาก่อน เป็นไปได้มั้ยครับที่ในระบบ Digital Procurement จะแจ้งเตือนให้ผู้มีรายชื่อในคกก.เป็นผู้ click ยืนยันรับทราบในระบบให้ครบทุกคน ก่อนที่ระบบจะส่งข้อมูลเสนอผู้มีอำนาจอนุมัติตามขั้นตอน
        </p>
      </Col>
      <Row>

      </Row>
    </Container>
  );
}
