import {
  Modal,
  Table,
} from 'components';
import { useState } from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import { generateUniqueId } from '../../../utils/helper';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
}

interface TORHistoryProps {
  version: string;
  username: string;
  updateDate: Date;
}

export default function ModalTORHistory({ show, setShow }: Props) {
  const [TORHistoryData, setTORHistoryData] = useState<TORHistoryProps[]>([
    {
      version: 'V1.1.001',
      username: 'สมศรี แดนไทย',
      updateDate: new Date(2022, 5, 15, 8, 55),
    },
    {
      version: 'V1.1.002',
      username: 'สมศรี แดนไทย',
      updateDate: new Date(2022, 5, 16, 9, 15),
    },
    {
      version: 'V1.2.001',
      username: 'สมศรี แดนไทย',
      updateDate: new Date(2022, 5, 17, 15, 46),
    },
  ]);

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => setShow(!show)}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>ประวัติการแก้ไข</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className="p-0 ps-4">
            <Table hidePagination>
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>Versions เอกสาร</th>
                  <th>ชื่อผู้แก้ไข</th>
                  <th>วันที่แก้ไข</th>
                </tr>
              </thead>
              <tbody>
                {
                    TORHistoryData.map((item, index) => (
                      <tr key={generateUniqueId(item.username)}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{item.version}</td>
                        <td>{item.username}</td>
                        <td className="text-center">
                          {
                            item.updateDate
                              .toLocaleString(
                                'en-GB',
                                { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' },
                              )
                              .replace(',', '')
                          }
                        </td>
                      </tr>
                    ))
                  }
              </tbody>
            </Table>
          </ModalBT.Body>
        </>
      )}
    />
  );
}
