import http from 'utils/axios';

export const getPlanListAsync = async (
  size: number,
  page: number,
  departmentId?: string,
  planName?: string,
  planBudget?: number,
  supplyMethod?: string,
  supplyMethodName?: string,
  organizer?: string,
  string?: string,
) => await http.get('/plan', {
  params: {
    size,
    page,
    departmentId,
    planName,
    planBudget,
    supplyMethod,
    supplyMethodName,
    organizer,
    string,
  },
});

export default {
  getPlanListAsync,
};