import {
  Card,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Selector,
  Table,
  TorStatus,
  UploadFile,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import { HistoryModal } from 'components/Modal';
import {
  BidPrice,
  COIAndWatchListModal,
  TraderListModal,
} from 'components/Modal/Procurement';
import { ItemModel } from 'models';
import { Entrepreneurs } from 'models/PR/JorPor06Models';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaCheck,
  FaCircle,
  FaHistory,
  FaSave,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import { useLoaderData } from 'react-router';
import { shareValue } from 'services';
import {
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import { AnnouncementStatus } from 'utils/constants/PlanEnum';
import toast from 'utils/toast';
import { Context } from '.';
import { TorEditCancelStatusConstant } from 'constant/PreProcumentTorStatus';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type Loader = {
  departments: ItemModel[];
  supplyMethods: ItemModel[];
  supplyMethodTypes: ItemModel[];
  vatTypeItems: ItemModel[];
  unitOfMeasureDDL: ItemModel[];
  periodUnitDDL: ItemModel[];
}

export default function Step1({ onClickNext, onClickBack }: Props) {
  const {
    departments, supplyMethods, supplyMethodTypes, vatTypeItems, unitOfMeasureDDL, periodUnitDDL
  } = useLoaderData() as Loader;
  const {
    jorpor06Detail,
    activityData,
    setJorpor06Detail,
    updateJorPor06Async,
    disabledField,
    downloadFileAsync,
    uploadFile,
    removeFile,
    recallAsync,
    recallShow,
  } = useContext(Context);
  const [showCoiWatchList, setShowCoiWatchList] = useState(false);
  const [showTraderModal, setShowTraderModal] = useState(false);
  const [showBidPriceModal, setShowBidPriceModal] = useState(false);
  const [isConsider, setIsConsider] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<string>('');
  const [titleModal, setTitleModal] = useState<string>();
  const [showHistory, setShowHistory] = useState(false);
  const [aLength, setALength] = useState<number>(3);
  const [supplyMethodSpecialType, setSupplyMethodSpecialType] = useState<ItemModel[]>([]);
  const [entrepreneurs, setEntrepreneurs] = useState<Entrepreneurs>({} as Entrepreneurs);
  const { userId } = useAppContext();

  useEffect(() => {
    if (jorpor06Detail.supplyMethod) {
      getSupplyMethodSpecialAsync();
    }
  }, []);

  const onShowCoiWatchList = (typeModal: string, title: string, data: Entrepreneurs) => {
    setShowCoiWatchList(true);
    setTypeModal(typeModal);
    setTitleModal(title);
    setEntrepreneurs(data);
  };

  const onShowBidPriceModal = (isConsider: boolean, data: Entrepreneurs) => {
    if (isConsider) {
      setIsConsider(true);
    }

    setEntrepreneurs(data);
    setShowBidPriceModal(true);
  };

  const getSupplyMethodSpecialAsync = async () => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(jorpor06Detail.supplyMethod);

    if (status === HttpStatusCode.OK) {
      setSupplyMethodSpecialType(data);

      return;
    }
  };

  const onClikNext = () => {
    if (!disabledField) {
      submitForm({
        titleDocument: jorpor06Detail.titleDocument,
      });

      if (jorpor06Detail.titleDocument) {
        onClickNext();

        return;
      }

      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');

      return;
    }

    onClickNext();
  };

  const onSave = () => {
    submitForm();

    if (jorpor06Detail.titleDocument) {
      updateJorPor06Async(AnnouncementStatus.Draft);

      return;
    }

    toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
  };

  const checkBooleanNullOrUndefined = (val?: boolean) => (val === null || false);

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <Title text='ข้อมูลโครงการ' className='fs-5 text-primary' />
          <div className='d-flex gap-2'>
            {
              jorpor06Detail.isChanging && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-warning' /></div>
                    {TorEditCancelStatusConstant.Editing}</span>
                </div>
              )
            }
            {
              jorpor06Detail.isCanceling && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-danger' /></div>
                    {TorEditCancelStatusConstant.Canceling}</span>
                </div>
              )
            }
            <TorStatus value={jorpor06Detail.status} />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'>
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <Input
              label='เลขที่รายงาน (จพ.006)'
              disabled
              value={jorpor06Detail.jorPor06Number} />
          </Col>
          <Col sm={12} md={4} xl={3}>
            <Input
              label='เลขที่รายงานขอซื้อขอจ้าง (จพ.005)'
              disabled
              value={jorpor06Detail.jorPor05Number} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4} xl={3}>
            <Input
              label='อ้างอิงแผนการจัดซื้อจัดจ้าง'
              disabled
              value={jorpor06Detail.planNumber} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <Selector label='ฝ่าย/สำนัก'
              items={departments}
              value={jorpor06Detail.departmentId}
              disabled />
          </Col>
          <Col sm={12} md={4} xl={3}>
            <Input
              label='ปีงบประมาณ'
              disabled
              value={jorpor06Detail.budgetYear} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} xl={3}>
            <Selector
              label='วิธีการจัดหา'
              items={supplyMethods}
              disabled
              value={jorpor06Detail.supplyMethod} />
          </Col>
          <Col sm={12} md={6} xl={3}>
            <Selector
              className='no-label'
              items={supplyMethodTypes}
              disabled
              value={jorpor06Detail.supplyMethodType} />
          </Col>
          <Col sm={12} md={6} xl={3}>
            <Selector
              items={supplyMethodSpecialType}
              className='no-label'
              disabled
              value={jorpor06Detail.supplyMethodSpecialType} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <Currency
              label='วงเงิน (บาท)'
              disabled
              value={jorpor06Detail.financialAmount} />
          </Col>
          <Col sm={12} md={4} xl={3}>
            <DatePicker
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              monthYearOnly
              disabled
              value={jorpor06Detail.expectingProcurementAt} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} md={4} xl={3}>
            <DatePicker label='วันที่เอกสารบันทึกร่างขอบเขตของงาน' disabled value={jorpor06Detail.memorandumDate} />
          </Col>
          <Col sm={12} md={4} xl={3}>
            <Input label='เลขที่เอกสารร่างขอบเขตของงาน' disabled value={jorpor06Detail.memorandumNo} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center'>
          <Col sm={12} xl={9} offset-xl={3}>
            <InputTextArea
              label='ชื่อโครงการ (เรื่อง)'
              disabled
              value={jorpor06Detail.planName} />
          </Col>
          <Col sm={12} xl={3}>
          </Col>
          <Col sm={3} xl={3} style={{ width: '26%' }}>
            <Input
              name='titleDocument'
              className='input-transparent'
              disabled={disabledField}
              label='หัวเรื่องเอกสาร'
              rule={{ required: true }}
              value='รายงานผลการพิจารณาและขออนุมัติสั่ง' />
          </Col>
          <Col sm={6} xl={5} style={{ width: '49%' }}>
            <Input
              name='titleDocument'
              className='no-label'
              disabled={disabledField}
              rule={{ required: true }}
              value={jorpor06Detail.titleDocument}
              onChange={(value) => setJorpor06Detail({ ...jorpor06Detail, titleDocument: value })} />
          </Col>
          <Col sm={12} xl={9}>
            <InputTextArea
              label='ที่มาและเหตุผล'
              disabled
              value={jorpor06Detail.sourceAndReasons} />
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex mb-4 align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Title text='ผู้ประกอบการที่เชิญชวน' className='fs-5 text-primary' />
          </div>
        </div>
        <Row className='gap-5'>
          <Col sm={12}>
            <Table hidePagination>
              <thead>
                <tr>
                  <th style={{ width: 10 }}>ลำดับ</th>
                  <th style={{ minWidth: 50 }}>เลขประจำตัวผู้เสียภาษี</th>
                  <th style={{ minWidth: 50 }}>ประเภทผู้ประกอบการ</th>
                  <th style={{ minWidth: 50 }}>ชื่อสถานประกอบการ</th>
                  <th style={{ minWidth: 150 }}>อีเมล</th>
                  <th style={{ minWidth: 50 }}>COI</th>
                  <th style={{ minWidth: 50 }}>Watchlist</th>
                  <th style={{ minWidth: 50 }}>e-GP</th>
                  <th style={{ minWidth: 50 }}>เสนอราคา</th>
                  <th style={{ minWidth: 50 }}>ผลพิจารณา</th>
                  <th style={{ minWidth: 80 }}>ผู้ชนะ</th>
                </tr>
              </thead>
              <tbody>
                {jorpor06Detail && jorpor06Detail.entrepreneurs.map((data, index) => (
                  <tr key={index}>
                    <td className='text-center'>{index + 1}</td>
                    <td>{data.taxPayerIdentificationNo}</td>
                    <td className='text-center'>
                      {data.type === 'JuristicPerson' ? 'นิติบุคคล' : data.type === 'Individual' ? 'บุคคลธรรมดา' : ''}
                    </td>
                    <td>{data.establishmentName}</td>
                    <td>
                      <Input disabled value={data.email} />
                    </td>
                    <td className='text-center'>
                      {
                        !checkBooleanNullOrUndefined(data.coi.result)
                          ? (
                            <Button
                              variant={data.coi.result ? 'success' : 'danger'}
                              onClick={() => onShowCoiWatchList('Coi', 'COI', data)}>
                              บันทึกผลแล้ว
                            </Button>
                          )
                          : (
                            <Button
                              onClick={() => onShowCoiWatchList('Coi', 'COI', data)}>
                              บันทึกผล
                            </Button>
                          )
                      }
                    </td>
                    <td className='text-center'>
                      {!checkBooleanNullOrUndefined(data.watchList.result) ? (
                        <Button
                          variant={data.watchList.result ? 'success' : 'danger'}
                          onClick={() => onShowCoiWatchList('WatchList', 'WatchList', data)}>
                          บันทึกผลแล้ว
                        </Button>
                      ) : (
                        <Button
                          onClick={() => onShowCoiWatchList('WatchList', 'WatchList', data)}>
                          บันทึกผล
                        </Button>
                      )}
                    </td>
                    <td className='text-center'>
                      {!checkBooleanNullOrUndefined(data.eGp.result) ? (
                        <Button
                          variant={data.eGp.result ? 'success' : 'danger'}
                          onClick={() => onShowCoiWatchList('Egp', 'e-GP', data)}>
                          บันทึกผลแล้ว
                        </Button>
                      ) : (
                        <Button
                          onClick={() => onShowCoiWatchList('Egp', 'e-GP', data)}>
                          บันทึกผล
                        </Button>
                      )}
                    </td>
                    <td className='text-center'>
                      {data.parcels.some((d) => d.vatTypeId) ? (
                        <Button
                          variant='success'
                          onClick={() => onShowBidPriceModal(false, data)}>
                          บันทึกเสนอราคาแล้ว
                        </Button>
                      ) : (
                        <Button
                          disabled={!(data.coi.result && data.watchList.result && data.eGp.result)}
                          onClick={() => onShowBidPriceModal(false, data)}>
                          บันทึกเสนอราคา
                        </Button>
                      )}
                    </td>
                    <td className='text-center'>
                      {data.consideration.winnerReason ? (
                        <Button
                          disabled={!data.parcels.every((p) => p.vatTypeId)}
                          variant='success'
                          onClick={() => onShowBidPriceModal(true, data)}>
                          บันทึกผลพิจารณาแล้ว
                        </Button>
                      ) : (
                        <Button
                          disabled={!data.parcels.every((p) => p.vatTypeId)}
                          onClick={() => onShowBidPriceModal(true, data)}>
                          บันทึกผลพิจารณา
                        </Button>
                      )}
                    </td>
                    <td className='text-success text-center'>
                      {data.consideration.isWinner ? <FaCheck /> : <></>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12} lg={6}>
            <UploadFile
              disabled={false}
              value={jorpor06Detail.documentList}
              onChange={(value) => uploadFile(value)}
              onRemove={(index, id) => removeFile(index, id)}
              onDownload={(index, id) => downloadFileAsync(index, id)}
              userId={userId} />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="กลับหน้าแรก"
        />
        {!disabledField && (
          <ButtonCustom
            onClick={onSave}
            text="บันทึก"
          />
        )}
        {recallShow && (
          <ButtonCustom
            icon='undo'
            variant='danger'
            onClick={() => recallAsync()}
            text="เรียกคืนแก้ไข"
          />
        )}
        {jorpor06Detail.entrepreneurs.some((e) => e.consideration.isWinner) ? (
          <ButtonCustom
            onClick={onClikNext}
            text="ถัดไป"
          />
        ) : <div />}
      </div>
      <HistoryModal
        title='ประวัติการใช้งานจัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้า (จพ.006)'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        data={activityData} />
      {showCoiWatchList && (
        <COIAndWatchListModal
          entrepreneurData={entrepreneurs}
          show={showCoiWatchList}
          onHide={() => setShowCoiWatchList(!showCoiWatchList)}
          type={typeModal}
          titleName={titleModal}
          enabledGetDataAfterSubmit={false} />
      )}
      <TraderListModal
        show={showTraderModal}
        onHide={() => setShowTraderModal(!showTraderModal)}
        onSubmit={(val) => setALength(aLength + val)} />
      {showBidPriceModal && (
        <BidPrice
          entrepreneurData={entrepreneurs}
          show={showBidPriceModal}
          onHide={() => setShowBidPriceModal(!showBidPriceModal)}
          type={isConsider}
          setType={setIsConsider}
          vatTypeItems={vatTypeItems}
          financialAmount={jorpor06Detail.financialAmount}
          medianPriceSourcesAmount={jorpor06Detail.medianPriceSourcesAmount}
          isTorMA={jorpor06Detail.isTorMA}
          unitOfMeasureItems={unitOfMeasureDDL}
          periodUnitItems={periodUnitDDL} />
      )}
    </div>
  );
}
